import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { IRegistrationForm } from '../../../../models/user/event-registration/registrationForm-result';

interface IInputFieldProps {
  item: IRegistrationForm;
  onChange: Function;
  type: string;
  className: string;
  optionName?: any;
  selectedValue?: any;
  disabled?: boolean;
  isSpectre?: boolean;
}

const InputField = ({
  item,
  onChange,
  type,
  className,
  optionName,
  selectedValue,
  disabled,
  isSpectre
}: IInputFieldProps) => {
  const [checkStatus, setCheckStatus] = useState(false);

  const handleChange = (e: any) => {
    if (item.fieldType === 'select' && !selectedValue) setCheckStatus(e.target.checked);
    onChange(e.target);
  };

  useEffect(() => {
    if (item.fieldType === 'select' && selectedValue) {
      // eslint-disable-next-line no-restricted-syntax
      for (const i in selectedValue) {
        if (optionName === selectedValue[i]) {
          setCheckStatus(true);
        }
      }
    }
    if (item.fieldType === 'terms-and-conditions') {
      setCheckStatus(selectedValue);
    }
  }, [optionName, selectedValue, item.fieldType]);

  return (
    <div className="flex items-start mb-1 column relative">
      <input
        type={type}
        className={className}
        value={selectedValue}
        checked={checkStatus}
        onChange={e => {
          handleChange(e);
        }}
        autoComplete="off"
        disabled={disabled}
      />
      {type === 'checkbox' && (
        <label
          className={classNames('text-sm font-normal font-sans ', {
            'text-white': isSpectre,
            'text-[#3C3C3C]': !isSpectre,
          })}
          htmlFor={item.id}
        >
          {optionName}
        </label>
      )}
    </div>
  );
};

export default InputField;
