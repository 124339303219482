/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { EventsServiceV2 } from 'src/services/events/events-v2';
import { toast } from 'react-toastify';
import { CustomSelectorComp } from '../../../components/selectors/custom-selector';
import { LOGIN_SIGNUP_RECOVERY } from '../../../constants';
import { IReactSelectOption } from '../../../models/react-select-option';

import { TextInput, Typography } from '../../../shared-components';
import { IAppState } from '../../../store';
import { getIndustries } from '../../../store/industries/actions';
import { emailRegex } from '../../../utils';

const countryList = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Åland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'AndorrA', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, The Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Cote D'Ivoire", value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic Of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea, Democratic People'S Republic of", value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: "Lao People'S Democratic Republic", value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'AN' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestinian Territory, Occupied', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'RWANDA', value: 'RW' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia and Montenegro', value: 'CS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
];

interface IContactUsForm {
  firstName: string;
  workEmail: string;
  companyName: string;
  phoneNumber: string;
  lastName: string;
  jobTitle: string;
  country: string;
  industry: string;
  yourMessage: string;
}

export const ContactUs = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [successfullyMessage, setSuccessfullyMessage] = useState('');

  const industries = useSelector((state: IAppState) => state.industries);

  const event = useSelector((state: IAppState) => state.website.event!);

  const [industryListState, setIndustryListState] = useState<IReactSelectOption[]>();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IContactUsForm>();

  useEffect(() => {
    dispatch(getIndustries(query));
  }, [dispatch, query]);

  useEffect(() => {
    const mapIndustriesToSelectOption = () => {
      const industyList: IReactSelectOption[] = [];

      // eslint-disable-next-line no-unused-expressions
      industries?.value?.data
        && industries?.value?.data.map((item: any, index: number) => industyList.push({
          key: `industry-${index}`,
          value: item.id,
          label: item.name
        }));
      setIndustryListState(industyList.filter(i => i.label));
    };
    mapIndustriesToSelectOption();
  }, [industries]);

  const onSelectCountry = (country: any) => {
    if (country.value) errors.country = undefined;
    setValue('country', country.value);
  };

  const onSelectIndustry = (industry: any) => {
    if (industry.label) errors.industry = undefined;
    setValue('industry', industry.label);
    dispatch(getIndustries());
  };

  const submitContactUsForm = async (data: any) => {
    const body = {
      ...data
    };
    try {
      await new EventsServiceV2().contactUS(event?.id!, body);
      // setSuccessfullyMessage(result?.message);
      setSuccessfullyMessage('Thank you, someone from our team will be in touch shortly.');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const onSubmit = (data: any) => {
    const payload: any = {
      eventName: event?.name!,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.workEmail,
      jobTitle: data?.jobTitle,
      organisation: data?.companyName,
      industry: data?.industry,
      country: data?.country,
      countryCode: data?.country,
      number: data?.phoneNumber,
      message: data?.yourMessage,
    };

    submitContactUsForm(payload);

    // axios.post('https://forms.hscollectedforms.net/collected-forms/submit/form', {
    //   collectedFormClasses: 'form-3',
    //   collectedFormId: 'wf-form-Sit-Down-Event-Website-Contact-Us',
    //   contactFields: {
    //     email: data.workEmail,
    //     firstName: data.firstName,
    //     lastName: data.lastName,
    //     phone: data.phoneNumber,
    //   },
    //   formSelectorClasses: '.form-3',
    //   formSelectorId: '#wf-form-Sit-Down-Event-Website-Contact-Us',
    //   formValues: {
    //     CompanyName: data.companyName,
    //     Industry: data.yourMessage,
    //     JobTitle: data.jobTitle,
    //     SelectCountry: data.country,
    //     SelectIndustry: data.industry,
    //   },
    //   labelToNameMap: {
    //     CompanyName: data.companyName,
    //     Industry: data.yourMessage,
    //     JobTitle: data.jobTitle,
    //     SelectCountry: data.country,
    //     SelectIndustry: data.industry,
    //   },
    //   pageTitle: 'Sit-Down | CDO | CIO | CISO',
    //   pageUrl: 'https://www.sit-down.com/sitdowns/cdociociso-may',
    //   portalId: 21288357,
    //   type: 'SCRAPED',
    //   utk: '53d7d0d0facf17a4961b9d70deac8f0c',
    //   uuid: '57afe7a3-9d71-4f8c-bdf5-bcaee878801b',
    //   version: 'collected-forms-embed-js-static-1.331',
    // }).then((res: any) => {
    //   setSuccessfullyMessage(res?.data?.successfull?.message);
    // });
  };

  return (
    <div id="Contact us" className="w-full">
      <div className="w-full bg-[#14161A] md:px-[40px] px-[20px] md:pt-[48px] pt-[20px] md:pb-[49px] pb-[29px] rounded-20">
        <div className="w-full">
          <p className="text-[35px] font-medium leading-[44px] text-white helvetica-neue-65-medium">
            Contact us
          </p>
          <p className="text-[20px] font-normal leading-[25px] text-white mb-[20px] mt-[5px] helvetica-neue-55-roman">
            Complete this form and a team member will contact you shortly
          </p>
          {successfullyMessage ? (
            <p className="max-w-[380px] text-[15px] text-[#707070] leading-[17px] mt-[16px] helvetica-neue-45-light">
              {successfullyMessage}
            </p>
          ) : (
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="md:flex justify-between gap-x-[16px]">
                <div className="w-full md:mb-0 mb-5">
                  <div className="w-full mb-[20px] relative">
                    <TextInput
                      textinputsize="medium"
                      caption="First name*"
                      labelclass="bg-[#14161A] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                      type="text"
                      classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                      {...register('firstName', {
                        required: { value: true, message: 'Your registered name is required!' }
                      })}
                    />
                  </div>
                  <div>
                    {errors.firstName && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.firstName?.message!.toString()}
                        classes="flex content-start mb-[12px] -mt-[18px]"
                      />
                    )}
                  </div>
                  <div className="w-full mb-[20px]">
                    <TextInput
                      textinputsize="medium"
                      caption="Work Email*"
                      labelclass="bg-[#14161A] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                      type="email"
                      classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                      {...register('workEmail', {
                        required: {
                          value: true,
                          message: 'Email is required',
                        },
                        pattern: {
                          value: emailRegex,
                          message: LOGIN_SIGNUP_RECOVERY.VALID_EMAILADDRESS,
                        },
                      })}
                    />
                  </div>
                  <div>
                    {errors.workEmail && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.workEmail?.message!.toString()}
                        classes="flex content-start mb-[12px] -mt-[18px]"
                      />
                    )}
                  </div>
                  <div className="w-full mb-[20px]">
                    <TextInput
                      textinputsize="medium"
                      caption="Company Name*"
                      labelclass="bg-[#14161A] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                      type="text"
                      classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                      {...register('companyName', {
                        required: { value: true, message: 'Your company name is required!' }
                      })}
                    />
                  </div>
                  <div>
                    {errors.companyName && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.companyName?.message!.toString()}
                        classes="flex content-start mb-[12px] -mt-[18px]"
                      />
                    )}
                  </div>
                  <div className="w-full mb-[0px]">
                    <TextInput
                      textinputsize="medium"
                      caption="Phone Number*"
                      labelclass="bg-[#14161A] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                      type="text"
                      classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                      {...register('phoneNumber', {
                        required: { value: true, message: 'Your phone number name is required!' }
                      })}
                    />
                  </div>
                  <div>
                    {errors.phoneNumber && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.phoneNumber?.message!.toString()}
                        classes="flex content-start mb-[0px] mt-[2px]"
                      />
                    )}
                  </div>
                </div>
                <div className="w-full md:mb-0 mb-5">
                  <div className="w-full mb-[20px]">
                    <TextInput
                      textinputsize="medium"
                      caption="Last name*"
                      labelclass="bg-[#14161A] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                      type="text"
                      classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                      {...register('lastName', {
                        required: { value: true, message: 'Your last name is required!' }
                      })}
                    />
                  </div>
                  <div>
                    {errors.lastName && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.lastName?.message!.toString()}
                        classes="flex content-start mb-[12px] -mt-[18px]"
                      />
                    )}
                  </div>
                  <div className="w-full mb-[20px]">
                    <TextInput
                      textinputsize="medium"
                      caption="Job Title*"
                      labelclass="bg-[#14161A] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                      type="text"
                      classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                      {...register('jobTitle', {
                        required: { value: true, message: 'Your job title is required!' }
                      })}
                    />
                  </div>
                  <div>
                    {errors.jobTitle && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.jobTitle?.message!.toString()}
                        classes="flex content-start mb-[12px] -mt-[18px]"
                      />
                    )}
                  </div>
                  <div className="w-full mb-[20px]">
                    <CustomSelectorComp
                      register={register}
                      fieldName="country"
                      options={countryList}
                      customStyle="helvetica-neue-35-thin contact-form-select"
                      placeholder="Select Country"
                      caption="Country*"
                      labelclass="bg-[#14161A] absolute left-[10px] -top-[8px] z-10 helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                      onChange={onSelectCountry}
                      errorMessage="Please select your country!"
                      isWebsiteDropdown
                    />
                  </div>
                  <div>
                    {errors.country && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.country?.message!.toString()}
                        classes="flex content-start mb-[12px] -mt-[18px]"
                      />
                    )}
                  </div>
                  <div className="w-full">
                    <CustomSelectorComp
                      register={register}
                      fieldName="industry"
                      customStyle="helvetica-neue-35-thin contact-form-select"
                      placeholder="Select Industry"
                      options={industryListState}
                      caption="Industry*"
                      onChange={onSelectIndustry}
                      labelclass="bg-[#14161A] absolute left-[10px] -top-[8px] z-10 helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                      errorMessage="Please select your industry!"
                      onSearch={setQuery}
                      isSearchable
                      isWebsiteDropdown
                    />
                  </div>
                  <div>
                    {errors.industry && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.industry?.message!.toString()}
                        classes="flex content-start mb-[0px] mt-[2px]"
                      />
                    )}
                  </div>
                </div>
                <div className="md:w-[150%] w-[100%]">
                  <div className="h-full">
                    <textarea
                      // eslint-disable-next-line max-len
                      className="helvetica-neue-45-light w-full h-full px-[12px] py-[8px] rounded-10 border-1 border-[#8f8f8f] bg-transparent resize-none leading-[17px] font-light outline-none placeholder:text-[#999999] placeholder:text-[13px] text-white text-[13px]"
                      placeholder="Type your message here"
                      {...register('yourMessage')}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between md:flex-row flex-col">
                <div className="max-w-[380px] text-[15px] text-[#707070] leading-[17px] mt-[16px] helvetica-neue-45-light">
                  By clicking the "Submit" button, you are agreeing to the Sit-Down Terms of Use and Privacy Policy.
                </div>
                <div className="mt-[16px]">
                  <button
                    type="submit"
                    className="helvetica-neue-55-roman rounded-[5px] inline-block text-[17px] font-normal bg-[#00ffcd] hover:bg-[#07dbb2] text-black w-[93px] h-[33px] tracking-wide"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
