import {
  FC,
  useState,
} from 'react';
import classNames from 'classnames';
import AlertModal from '../../../components/alert-modal/alert-modal';
import Report from '../../../components/report';
import { FlagService } from '../../../services/Flag';
import { IFlagDTO } from '../../../models/flag/flag';
import { DropDownMenu, IDropdownMenuItem } from '../../../components/dropdown-menu';
import NotIcon from '../../../assets/images/not-icon.svg';

interface ICompanyHeaderDropdownProps {
    userId: string;
}

export const UserProfileMore: FC<ICompanyHeaderDropdownProps> = ({
  userId,
}) => {
  const [showReportModal, setShowReportModal] = useState(false);
  const onCloseModal = () => setShowReportModal(false);

  const optionsToSelect: IDropdownMenuItem[] = [
    {
      id: 1,
      type: 'icon',
      text: 'Report',
      icon: NotIcon,
      onClickHandler: () => setShowReportModal(true),
    }
  ];

  return (
    <>
      {showReportModal && (
      <AlertModal
        className="w-576"
        isOpen={showReportModal}
        onClose={onCloseModal}
        closeIconClass=" right-[18px] top-[15px] px-0"
        isCloseIcon
      >
        <Report
          id={userId}
          type="user"
          onReport={async (id: string, type: string, reason: string) => {
            const flagService = new FlagService();
            const flagItem: IFlagDTO = {
              flaggableId: id,
              flaggableType: type,
              reason,
            };
            await flagService.flagItem(flagItem);
          }}
          onCloseReport={onCloseModal}
        />
      </AlertModal>
      )}

      <DropDownMenu
        options={optionsToSelect}
        containerClasses="flex justify-center items-center"
        menuCardClasses={classNames({
          'absolute w-193 opacity-100 bg-white -ml-[200px] mt-4 shadow-xs2 text-gray-13 rounded-15 p-2 z-10':
              true,
          hidden: false,
        })}
        titleAndSubtitleGroup="flex justify-left items-left hover:bg-gray-1 text-left p-2 rounded-5 text-gray-13 cursor-pointer"
        buttonStyles="flex items center"
        listTextStyles="text-sm1 text-blue-3 tracking-wide ml-3"
        listIconStyles=""
        textAndImageContainer="flex w-full h-full"
        fillColor="GRAY"
        fillColorActive
      />
    </>
  );
};
