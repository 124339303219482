import { Base64 } from "js-base64";

// export const toBase64 = (str: string) => Base64.btoa(str);

export const toBase64 = (str: string) => {
  try {
    return Base64.btoa(unescape(encodeURIComponent(str)));
    // return Base64.btoa(str);
  } catch (error) {
    console.log(error);
  }
};

export const getImage = (path: string) =>
  `${process.env.REACT_APP_DEFAULT_IMAGE_URL}/${path}`;

export const getMinifiedImage = (path: string) => {
  if (!path) return "";
  return `${process.env.REACT_APP_S3_MEDIA_BUCKET}/${path}`;
  return `${process.env.REACT_APP_DEFAULT_IMAGE_URL}/${toBase64(
    JSON.stringify({
      bucket: `${process.env.REACT_APP_DEFAULT_IMAGE_BUCKET}`,
      key: `${path}`,
      edits: {
        resize: {
          fit: "cover",
        },
      },
    })
  )}`;
};

export const getS3Path = (path: string) =>
  `${process.env.REACT_APP_S3_MEDIA_BUCKET}/${path}`;

export const getVideoHLSPath = (path: string) =>
  `${process.env.REACT_APP_DEFAULT_VIDEO_HLS_URL}/${path}`;
