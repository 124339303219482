import React, { FC } from 'react';
import classNames from 'classnames';

import { Footer } from './../Footer/footer';

import './advertisingCard.css';
import { Logo } from '../Logo';

export interface IAdvertisingCardProps {
  imageUrl: string;
  className?: string;
  alt?: string;
}

export const AdvertisingCard: FC<IAdvertisingCardProps> = ({ imageUrl, className = '', alt }) => {
  const handleClick = () => {
    window.open('https://business.panelist.com/', '_blank');
  };

  return (
    <div onClick={handleClick}>
      <div 
        className={classNames("h-auto p-1.5 mb-2 flex justify-center advertise-widget", {
          [`${className}`]: className,
        })}
        aria-label="advertising-target"
      >
        <Logo 
          src={imageUrl}
          alt={alt}
          className="rounded-10 w-full object-cover"
        />
      </div>
    </div>
  );
};
