import { FC } from 'react';
import classNames from 'classnames';
import coverPhoto from './../../../assets/images/default-profile-cover.svg';
import companyDefault from './../../../assets/images/company-default.svg';
import userDefault from './../../../assets/images/default-profile.svg';


export interface ILogoProps {
    src: string,
    className?: string,
    alt?: string,
    defaultLogo?: "company" | "cover" | "user"
}

export const Logo: FC<ILogoProps> = ({ src, className, alt, defaultLogo = "cover" }: ILogoProps) => {
    const onError = (event: any, src: string) => {
        event.target.src = src
        event.onerror = null
    }
    const defaultLogoIcon = (defaultLogo === "cover" ? coverPhoto : defaultLogo === "user" ? userDefault : companyDefault)
    return (
        <>
            <img
                className={classNames({
                    [`${className}`]: className
                })}
                alt={alt}
                src={src}
                onError={(evt) => onError(evt, defaultLogoIcon)}
                aria-label="logo-target"
            />
        </>
    );
}