import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ICompaniesResult } from '../../models/company/CompaniesResult';
import { ICompaniesState } from '../../models/company/CompaniesState ';
import { CompanyService } from '../../services';
import { CompaniesActionTypes } from './types';

export interface ICompaniesLoadingAction {
  type: CompaniesActionTypes.COMPANIES_LOADING;
  loading: boolean;
}

export interface ICompaniesSuccessAction {
  type: CompaniesActionTypes.COMPANIES_SUCCESS;
  data: ICompaniesResult;
}

export interface ICompaniesErrorAction {
  type: CompaniesActionTypes.COMPANIES_ERROR;
  errorMessage: string;
}

export type CompaniesActions =
  | ICompaniesLoadingAction
  | ICompaniesSuccessAction
  | ICompaniesErrorAction;

export const getCompanies: ActionCreator<
  ThunkAction<Promise<any>, ICompaniesState, null, ICompaniesSuccessAction>
> = (query: string) => {
  return async (dispatch: Dispatch) => {
    const companyService: CompanyService = new CompanyService();

    try {
      const result = await companyService.getCompaniesWithQuery(query);

      dispatch({
        type: CompaniesActionTypes.COMPANIES_SUCCESS,
        data: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: CompaniesActionTypes.COMPANIES_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: CompaniesActionTypes.COMPANIES_LOADING,
        loading: false
      });
    }
  };
};
