import { useState } from "react";
interface IHomeIconSvg {
  isActive?: boolean;
}
export const HomeIconSvg = ({ isActive }: IHomeIconSvg) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const colorBlue = "#0049EF";
  const defaultColor = "#7283A4";
  
  const fillColor = isHover || isActive ? colorBlue : defaultColor;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="29.216"
      viewBox="0 0 35 29.216"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <g
        id="Group_18807"
        data-name="Group 18807"
        transform="translate(0 0.001)"
      >
        <path
          id="Path_10811"
          data-name="Path 10811"
          d="M302.726,379.476a1.208,1.208,0,0,1-.973.493h-1.471a1.6,1.6,0,0,0-1.6,1.6v10.312a2.87,2.87,0,0,1-2.771,2.832h-4.557V381.976a1.436,1.436,0,0,0-1.478-1.4h-8.807a1.441,1.441,0,0,0-1.466,1.4v12.733h-4.57a2.87,2.87,0,0,1-2.771-2.832V381.564a1.6,1.6,0,0,0-1.6-1.6h-1.46a1.231,1.231,0,0,1-.739-2.217l16.271-12.019a1.238,1.238,0,0,1,1.466,0l16.271,12.019A1.213,1.213,0,0,1,302.726,379.476Z"
          transform="translate(-267.98 -365.494)"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};
