import { Fade } from '@mui/material';
import { Transition } from '@tailwindui/react';
import classNames from 'classnames';
import { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, ButtonWithTextAndImage } from '../../../shared-components/V2';
import { UserDropdownSvgIcon } from '../../../shared-components/V2/IconSvg';
import { getCompanySlug } from '../../../utils';
import { useCurrentUser, useUserDetails } from '../../../utils/hooks';
import useCloseMenuWithClickOutside from '../../../utils/hooks/closeMenuWithClickOutside';

const UserMenu = () => {
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const { showDropdown, handleDropdown } = useCloseMenuWithClickOutside(ref);
  const user = useCurrentUser();
  const { avatar, fullName, jobTitle, company, companyAdmin, companySlug } = useUserDetails(user);

  const handleUserMenuDropdown = () => {
    handleDropdown(showDropdown);
  };

  const onCompanyClick = () => {
    const companySlug = getCompanySlug(user);
    if (!companySlug) return;
    navigate(`/wall/company/${companySlug}`);
  }

  return (
    <div className="relative user-menu" ref={ref} onClick={() => handleUserMenuDropdown()}>
      <button
        className="menu focus:outline-none focus:shadow-solid relative">
        <Avatar src={avatar!} alt={fullName} size='XSMALL' />
        <UserDropdownSvgIcon className="absolute -right-2 bottom-0" />
      </button>
      {showDropdown &&
        <Fade in={true}>
          {/* <Transition
            show={showDropdown}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          > */}
          <div className="origin-top-right absolute md:right-0 -right-1 md:top-[41px] top-[35px] w-[242px] py-2 bg-white shadow-xs6 rounded-10 z-40">
            <div className="m-3 flex">
              <Avatar
                size='MEDIUM'
                src={avatar!}
                alt=""
              />
              <div className="flex flex-col ml-2">
                <div className="text-md0 font-medium capitalize cursor-pointer" onClick={() => navigate(`/wall/${user.slug}`)}>{fullName}</div>
                <div className="text-sm5 regular font-[400]">{jobTitle}</div>
                <div
                  className={
                    classNames(
                      'text-sm5 font-medium capitalize',
                      { 'cursor-pointer': companySlug }
                    )
                  }
                  onClick={onCompanyClick}>{company}</div>
              </div>
            </div>
            <div className="flex justify-center">
              <ButtonWithTextAndImage
                buttonClasses="bg-[#0049EF] hover:bg-[#2e6eff] px-6 w-[214px] h-[30px] py-1 rounded-5"
                imageUrl=""
                text="View profile"
                textClasses="text-white text-sm6 rounded-20 font-normal"
                onClick={() => navigate(`/wall/${user.slug}`)}
              />
            </div>
            <div className="account-section">
              <div className="text-sm5 m-3 text-blue-3 font-semibold">Account</div>
              <div className="text-sm5 m-3 text-blue-3 font-extralight">
                <div className="setting-privacy mb-1 hover:underline font-[400]">
                  <a href={'/settings/account-and-privacy/account-settings'}>
                    Settings & Privacy
                  </a>
                </div>
                <div className="billing-subscription mb-1 hover:underline font-[400]">
                  <a href={'/settings/account-and-privacy/account-settings#subscription'}>
                    {' '}
                    Billing & Subscription
                  </a>
                </div>
                <div className="help hover:underline font-[400]" >
                  <a href={'/help'}>Help</a>
                </div>
              </div>
            </div>
            <div className="account-manage border-y-1 border-blue-0">
              <div className="text-sm5 text-blue-3 font-semibold m-3">Manage</div>
              <div className="flex justify-center mb-[5px]">
                <a
                  href={`${process.env.REACT_APP_EVENT_PORTAL_URL}/events`}
                  target="_blank"
                  className="bg-gray-1 hover:bg-[#0049EF] hover:text-white w-[214px] h-[30px] py-1.5 text-blue-4 text-sm6 rounded-5 font-normal flex justify-center">
                  Event Portal
                </a>
              </div>
              <div className="flex justify-center mb-[5px]">
                <a
                  href={`${process.env.REACT_APP_EVENT_PORTAL_URL}/insights`}
                  target="_blank"
                  className="bg-gray-1 hover:bg-[#0049EF] hover:text-white w-[214px] h-[30px] py-1.5 text-blue-4 text-sm6 rounded-5 font-normal flex justify-center">
                  Insight Portal
                </a>
              </div>
            </div>
            {companyAdmin && companyAdmin.length > 0 && (
              <>
                <div className="account-company">
                  <div className="text-sm5 mx-3 my-2 text-blue-3 font-semibold">Company</div>
                  <div className="flex justify-left">
                    <div className="text-sm5 px-3 mt-1 text-blue-3 font-extralight md:max-h-[200px] max-h-[70px] overflow-y-auto w-full">
                      {companyAdmin?.map((company, index) => {
                        return (
                          <Link to={`/wall/company/${company.slug}`} key={`company-profile-${index}`}>
                            <div key={company.id} className="mb-1 hover:underline font-[400]">
                              {company.name}
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="border-t-1"></div>
              </>
            )}

            <div className="sign-out mt-3 border-blue-0">
              <Link to="/logout" className="logout-link hover:underline">
                <div className="text-sm5 m-3 text-blue-3 font-semibold">Sign out</div>
              </Link>
            </div>
          </div>
          {/* </Transition> */}
        </Fade>
      }
    </div>
  );
};

export default UserMenu;
