/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Stack, Dialog, CircularProgress, Typography, Button } from "@mui/material";
import "./Card.css";
import { useNavigate } from "react-router";
import FormCard from "./Card";
import LoginOrJoinForm from "./LoginOrJoinForm";
import { useDispatch, useSelector } from "react-redux";
import { checkUserEmail, resetUserEmail } from "src/store/checkUserEmail";
import { IUserProfile } from "src/models/user-profile";
import { IAppState } from "src/store";
import { ILoginOrSignUp } from "./form.type";
import { login, unsetUser } from "src/store/auth";
import { checkUserStatus } from "src/store/user-status";

import { resetError } from "src/store/auth";
import { eventRegistrationStep1 } from "src/store/user/signup/actions";
import {
  EventQuestionPageTypes,
  useSurveyContext,
} from "src/mui/providers/GeneralContext";
import ForgotPasswordForm, { ForgotPasswordSchema } from "./ForgotPasswordForm";
import {
  reSendCode,
  resetPassword,
  sendCode,
} from "src/store/user/forgotpassword/actions";
import { ForgotPasswordActionTypes } from "src/store/user/forgotpassword/types";
import PasswordProtectedForm from './ProtectedPasswordScreen';
import { delAuthToken } from "src/utils";
import { UserSignUpActionTypes } from "src/store/user/signup/types";
import { setRegistered } from "src/store/event-website";
import RegistrationUnderReview from "./RegistrationUnderReview";
import { UpcomingEventActionTypes } from "src/store/events/upcoming-event/types";
import EventRegistrationFormNew from "./EventRegistrationFormNew";
import Survey from "../../survey/Survey";
import useStyling from "src/mui/components/hook-form/useStyling";
import SignupForm, { ISignUp } from "./SignupForm";
import useEventIsPassed from "../../survey/useEventIsPassed";
import { getUpcomingEvent } from "src/store/events/upcoming-event/actions";
import { RegistrationFieldType } from "src/mui/types/registration";
import { isBusinessEmail } from "../../survey/constants";
import { ConfirmDialog } from "src/mui/components/custom-dialog";
import { setIsLoading } from "src/store/navBarSearch/actions";
import ErrorMessage from "src/components/error-message/errorMessage";
import { toast } from "react-toastify";

export const hideScrollbarY = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
} as const;

type Props = {
  user: IUserProfile | undefined;
  open: boolean;
  eventType: string;
  eventInvitationStatus: string;
  registered: boolean;
  eventId: string;
  setOpenForm: (value: boolean) => void;
  setUser?: (value: any) => void;
  lenovoJoiningUser?: string
};

interface SurveyRef {
  handleNextStep: Function;
  handlePrevPage: Function;
  handleNextRegStep: Function;
  handleSignupUser: Function;
  handleRequestMeeting: Function;
  handleContinueLogin: Function;
  handleForgotPassword: Function;
  handleContinueProtectedPassword: Function;
  handleSkipPage: Function
}

type pageItems = {
  bgcolor: string;
  logoColor: string;
  isMediaExpanded: boolean;
  mediaURL: string;
  titleDescPosition?: string;
}


function RegistrationStepForm({
  user,
  setUser,
  open,
  setOpenForm,
  eventType,
  eventInvitationStatus,
  registered,
  eventId,
  lenovoJoiningUser
}: Props) {

  const { getQuestionPages, eventProtectedPassword, showProtectedPasswordScreen, protectedPasswordError, registrationPages, signupRegQuestion, resetProtectedPasswordError, getSignUpRegQuestions } = useSurveyContext();
  const { primaryBackgroundColor, primaryFontColor, secondaryBackgroundColor, secondaryFontColor, headerColor,titleDescPosition} = useStyling(null, null)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [openUserAleardyExistPopup, setOpenUserAleardyExistPopup] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState<any>(false);
  const [signupUserPayload, setSignupUserPayload] = useState<any>();
  const [stepName, setStepName] = useState<string>("");
  const [currentRegistrationStep, setCurrentRegistrationStep] = useState<number>(0);

  const [stepDescription, setStepDescription] = useState<string>("");
  const [joiningUser, setJoiningUser] = useState<string>("");
  const [showForm, setShowForm] = useState<string>("");
  const [passwordProtectionCheck, setPasswordProtectionCheck] = useState(false);
  const [requiredSurveyQuestionsFilled, setRequiredQuestionsFilled] = useState<boolean>(false);
  const [requiredRegistrationQuestionsFilled, setRequiredRegistrationQuestionsFilled] = useState<boolean>(false);
  const [isSuveryorRegSkipable, setIsSuveryorRegSkipable] = useState<boolean>(false);
  const [loginEmail, setLoginEmail] = useState<string>("");
  const [stepHeader, setStepHeader] = useState<any>("");
  const { canAttendeesRequestMeeting , userExitedSurvey } = useEventIsPassed()
  const emailDefaultQuestion = signupRegQuestion && signupRegQuestion?.length && signupRegQuestion?.find((question: any) => (question.fieldType === RegistrationFieldType.EMAIL || question.fieldType === RegistrationFieldType.BUSINESS_EMAIL) && !question?.id);
  const isBusinessEmailValidationRequired = emailDefaultQuestion?.fieldType === RegistrationFieldType.BUSINESS_EMAIL;

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const { signupUser, upcomingEvent } = useSelector(
    (state: IAppState) => state
  );

  const isRegistered =
    upcomingEvent?.value?.data?.attendeeStatus?.isRegistered;

  const [pageItems, setPageItems] = useState({
    bgColor: primaryBackgroundColor,
    isMediaExpanded: false,
    primaryFontColor: primaryFontColor || null,
    media: "",
    secondaryFontColor: secondaryFontColor || null,
    secondaryBackgroundColor: secondaryBackgroundColor || null,
    currentPageId: "",
    headerColor: headerColor || null,
    lastSurveyorRegistrationPage: false,
    currentStep: 0,
    surveyorRegPagesIds: [],
    titleDescPosition,
  });

  // for survey page dialog
  const [currentSurveyStep, setCurrentSurveyStep] = useState<number>(0);
  const [surveySubPage, setSurveySubPage] = useState<string>("surveyForm")

  const surveyFormNextPageRef = useRef<SurveyRef>(null);

  const [page, setPage] = useState(0);

  const event = useSelector(
    (state: IAppState) =>
      state.website.event || state.upcomingEvent?.value?.data?.event
  );

  const checkUserEmailSelector = useSelector(
    (state: IAppState) => state.checkUserEmail
  );


  const { errorMessage: loginError, currentUser } = useSelector(
    (state: IAppState) => state.auth
  );

  const forgotPasswordEmail = useSelector(
    (state: IAppState) => state.forgotPasswordEmail
  );

  const { eventRegistration, errorMessage: signupError } = useSelector(
    (state: IAppState) => state.signupUser
  );

  const resetForm = () => {
    sessionStorage.removeItem("userName");
    setSignupUserPayload(null)
    changePage(0);
    dispatch({
      type: UserSignUpActionTypes.GO_TO_FIRST_STEP,
    });
    dispatch(resetUserEmail());
    resetProtectedPasswordError()
  };

  const handleUserAccountExist = () => {
    setJoiningUser(signupUserPayload?.payload?.email)
    setStepName("Login");
    setStepDescription(
      "The email you have entered is already registered on Panelist, enter your password to login"
    );
    setOpenUserAleardyExistPopup(false);
  }

  const signoutClickHandler = () => {
    delAuthToken();
    setSignupUserPayload(null)
    resetForm();
    sessionStorage.setItem("termAndCondition", "false");
    localStorage.clear();
    setJoiningUser("");
    setStepName("Login or Join Now");
    setStepDescription("");
    setShowForm("");
    dispatch(setRegistered(null));
    dispatch(unsetUser());

    dispatch({
      type: UpcomingEventActionTypes.RESET_UPCOMING_EVENT_LOADING,
      payload: {}
    });

    dispatch({
      type: UserSignUpActionTypes.RESET_SIGNUP_USER,
      payload: {}
    });

    if (setUser) setUser(undefined);
  };

  useEffect(() => {
    if (lenovoJoiningUser) {
      setJoiningUser(lenovoJoiningUser);
    }
  }, [lenovoJoiningUser]);

  // useEffect(() => {
  //   if (isLoader && currentUser && !loginError) {
  //     setIsLoader(false);
  //   } else if (isLoader && loginError) {
  //     setIsLoader(false);
  //   }
  // }, [currentUser, loginError, isLoader]);

  // useEffect(() => {
  //   if (isLoader && eventRegistration) {
  //     // setShowForm("registrationForm");
  //     // setShowForm('login')
  //   } else if (isLoader && signupError) {
  //     setIsLoader(false);
  //   }
  // }, [eventRegistration, signupError, isLoader]);
  useEffect(() => {
    if (eventRegistration) {
      getQuestionPages(EventQuestionPageTypes.REGISTRATION, eventId);
    } else if (showForm === "registrationForm" && !registrationPages.length) {
      getQuestionPages(EventQuestionPageTypes.REGISTRATION, eventId);
    }
  }, [eventId, eventRegistration]);

  useEffect(() => {
    if (open) {
      setIsModalOpen(true);
      if (user && !registered && !passwordProtectionCheck) setStepName('')
      else if (user && !registered) setStepName("Event Registration");
      // else if (user) setStepName("Register");
      else setStepName("Login or Join Now");
    } else {
      setIsModalOpen(false);
    }
  }, [open]);

  useEffect(() => {
    getSignUpRegQuestions(eventId)
    if (checkUserEmailSelector?.loading) return
    if (!checkUserEmailSelector?.loading && !!checkUserEmailSelector.value) {
      const userExists = checkUserEmailSelector.value?.result;

      // if (isRegistered || registered) return
      if (showForm === "signupRegistrationForm") {
        if (userExists) {
          setOpenUserAleardyExistPopup(true);
        }

        else {
          setIsLoading(true)
          setOpenUserAleardyExistPopup(false);
          setJoiningUser("")
          if (signupUserPayload?.payload) {
            sessionStorage.setItem("signupAnswers", JSON.stringify(signupUserPayload?.payload))
            dispatch(
              eventRegistrationStep1(
                signupUserPayload?.payload,
                signupUserPayload?.selectedCompanyStateToStore,
                signupUserPayload?.selectedJobTitleStateToStore,
                signupUserPayload?.selectedIndustryStateToStore,
                // setIsLoader
              )
            );
          }


        }
      }
      else {
        if (userExists) {
          setStepName("Login");
          setStepDescription(
            "The email you have entered is already registered on Panelist, enter your password to login"
          );
        } else {
          if (!isBusinessEmailValidationRequired || (isBusinessEmailValidationRequired && isBusinessEmail(loginEmail || joiningUser))) {
            setStepName("Register");
            setStepDescription(
              "By clicking Agree & Join, you agree to Panelist User Agreement, Privacy Policy and Cookie Policy"
            );
          }
        }
      }
    }
  }, [checkUserEmailSelector?.value, loginEmail]);

  const onSuccessPasswordResest = () => {
    setStepName("Login");
    setStepDescription(
      "Your password has been reset."
    );
  }

  const onSuccessResendCode = () => {
    if (windowSize?.current[0] > 600) {
      toast.dismiss();
      toast.success(
        <ErrorMessage
          title="Resend Code"
          message="Code is sent to your email."

        />
      );
    }

  }

  useEffect(() => {
    if (
      !forgotPasswordEmail?.loading &&
      !!forgotPasswordEmail.value &&
      forgotPasswordEmail.currentStep
    ) {
      const successfullySendCode = forgotPasswordEmail.value?.message;
      if (successfullySendCode && stepName?.includes("Forgot Password")) {
        setStepName("Reset Password");
        setStepDescription("Code is sent to your email.");
      }
      //  else if (
      //   forgotPasswordEmail.value?.message === "SUCCESS" &&
      //   stepName?.includes("Reset Password")
      // ) {
      //   setStepName("Login");
      //   setStepDescription(
      //     "Your password has been reset."
      //   );
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPasswordEmail.value]);

  useEffect(() => {
    if (user) {
      // invitation based event
      if (eventType === "invitation") {
        if (eventInvitationStatus === "accepted" || eventInvitationStatus === "pending") {
          setShowForm("surveyForm")
        }

        else if (eventInvitationStatus === "rejected" || eventInvitationStatus === "canceled") {
          setShowForm("rejected")
        }
        //todo: after josh call to show survey for all event whether user is registered or not
        // else if (eventInvitationStatus === "pending") {
        //   setShowForm("underReview")
        // }


        // else if ((isRegistered || registered) && eventInvitationStatus === null) {
        //   setShowForm("underReview")
        // }


        //  user not registered
        else if (isRegistered === 0) {
          if (showProtectedPasswordScreen && passwordProtectionCheck) {
            setShowForm("registrationForm")
            setSignupUserPayload(null) // clear the signup user data after creating the profile
          }
          else if (showProtectedPasswordScreen) {
            setShowForm('passwordProtected')
          } else {
            setShowForm("registrationForm")
            setSignupUserPayload(null)
          }
        }

      }
      //  public event
      else if (eventType === "open") {
        if (isRegistered || registered) {
          setShowForm("surveyForm")
        }
        else if (!isRegistered) {
          if (showProtectedPasswordScreen && passwordProtectionCheck) {
            setShowForm("registrationForm")
            setSignupUserPayload(null)
          }
          else if (showProtectedPasswordScreen) {
            setShowForm('passwordProtected')
          } else {
            setShowForm("registrationForm")
            setSignupUserPayload(null)
          }
        }
      }
    }

    else if (!user) {
      if (stepName?.includes("Login")) {
        setShowForm("login")
      }

      else if (stepName?.includes("Password")) {
        setShowForm("forgotPassowrd")
      }

      else if (stepName?.includes("Register")) {
        setShowForm("signupRegistrationForm")
      }
      else {
        setShowForm("loader")
      }
    }
  }, [user, isRegistered, stepName, registered, eventInvitationStatus, eventType, open, showProtectedPasswordScreen, passwordProtectionCheck]);

  const changeEmailRegistrationScreen = () => {
    dispatch({
      type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
      errorMessage: "",
    });
    setSurveySubPage("")
    setCurrentRegistrationStep(0);
    setCurrentSurveyStep(0)
    setStepName("Register");
    setStepDescription(
      "By clicking Agree & Join, you agree to Panelist User Agreement, Privacy Policy and Cookie Policy"
    );
    setStepDescription("");
  }

  const resetState = () => {

    dispatch({
      type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
      errorMessage: "",
    });

    // reset survey and registration pages
    setSurveySubPage("")
    setCurrentRegistrationStep(0);
    setCurrentSurveyStep(0)

    // reset email
    dispatch(resetUserEmail());

    // reset passwords
    dispatch({
      type: ForgotPasswordActionTypes.RESET_FORGOTPASSWORD,
      payload: null,
    });

    // reset errors
    dispatch(resetError());

    // reset description and title
    setStepName("");
    setStepDescription("");
    setJoiningUser("");

    // reset page items
    setPageItems({
      bgColor: primaryBackgroundColor,
      isMediaExpanded: event?.eventQuestionPage?.isMediaExpanded,
      media: event?.eventQuestionPage?.media,
      primaryFontColor: primaryFontColor,
      secondaryBackgroundColor: secondaryBackgroundColor,
      secondaryFontColor: secondaryFontColor,
      currentPageId: "",
      lastSurveyorRegistrationPage: false,
      currentStep: 0,
      surveyorRegPagesIds: [],
      headerColor,
      titleDescPosition
    })
  };

  // const eventPasswordApi = async (body: any) => {
  //   try {
  //     const result: any = await new EventsService().eventProtectedPassword(event?.id!, body);
  //     if (!result?.result) {
  //       setShowProtectedPasswordScreen(true)
  //       setProtectedPasswordError("Enter Correct Password!")
  //     }
  //     else if (result?.result) {
  //       setShowProtectedPasswordScreen(false)
  //       setProtectedPasswordError("")
  //     }

  //   } catch (error: any) {
  //     setShowProtectedPasswordScreen(true)
  //     setProtectedPasswordError(error?.message)
  //   }
  // }


  const onLoginSubmit = (data: ILoginOrSignUp) => {
    setLoginEmail(data?.email)
    if (checkUserEmailSelector.loading) return
    if (showProtectedPasswordScreen) {
      let body = {
        "passCode": data?.protectedPassword
      }
      // eventPasswordApi(body)
      eventProtectedPassword(body)
    }
    else {
      dispatch(resetError());
      if (!joiningUser || !checkUserEmailSelector.value?.result) {
        // dispatch(resetError());
        setJoiningUser(data.email);
        dispatch(checkUserEmail(data));
      } else if (checkUserEmailSelector.value?.result) {
        if (isLoader) return
        // setIsLoader(true);s
        dispatch(
          login(data.email, data.password!, navigate, "event", changePage, '', setIsLoader)
        );
        dispatch(checkUserStatus());
      }
    }



  };

  const onSignupSubmit = (data: ISignUp) => {

    if (checkUserEmailSelector?.loading) return
    const payload: any = { ...data };

    dispatch(checkUserEmail(payload));


    const selectedCompanyStateToStore = data.companyId;
    const selectedJobTitleStateToStore = data.jobTitle?.name || data.jobTitle;
    const selectedIndustryStateToStore = data.industryId;
    const selectedJobFunctionStateToStore = data.industryId;

    payload.companyId = data.companyId?.id || data.companyId;
    payload.industryId = data.industryId?.id;
    payload.jobFunctionId = data.jobFunctionId?.id;
    payload.jobTitle = data.jobTitle?.name || data.jobTitle;
    payload["city"] = data.location?.city || "";
    payload["country"] = data?.location?.country || "";
    payload.location = data.location?.name || "";
    setSignupUserPayload({ payload, selectedCompanyStateToStore, selectedJobTitleStateToStore, selectedIndustryStateToStore, selectedJobFunctionStateToStore })
    // sessionStorage.setItem("signupAnswers", JSON.stringify(payload))
    // dispatch(
    //   eventRegistrationStep1(
    //     payload,
    //     selectedCompanyStateToStore,
    //     selectedJobTitleStateToStore,
    //     selectedIndustryStateToStore
    //   )
    // );
  };

  const onForgotSubmit = (data: ForgotPasswordSchema) => {
    if (forgotPasswordEmail?.currentStep) {
      dispatch(resetPassword(data, data?.email, onSuccessPasswordResest));
    } else {
      dispatch(sendCode(data));
    }
  };

  const changePage = (page: number) => {
    setPage(page);
  };

  const showRegistrationUnderReview = () => {
    return (
      <RegistrationUnderReview setOpenForm={setOpenForm} />
    )
  }

  const showPasswordProtectedScreen = () => {
    return (
      <PasswordProtectedForm
        setStepHeader={setStepHeader}
        protectedPasswordError={protectedPasswordError}
        showProtectedPasswordScreen={showProtectedPasswordScreen}
        ref={surveyFormNextPageRef}
        loader={checkUserEmailSelector.loading || isLoader}
        errorMessage={loginError}
        loginUser={joiningUser}
        notYourEmail={() => {
          dispatch(resetUserEmail());
          setJoiningUser("");
          setStepName("");
          setStepDescription("");
        }}
        userExist={checkUserEmailSelector.value?.result}
        onSubmit={(data: ILoginOrSignUp) => { }}
        onSignup={() => {
          dispatch(resetError());
          setPasswordProtectionCheck(true);
        }
        }
        onForgot={() => {
          dispatch(resetError());
          setStepName("Forgot Password");
          setStepDescription(`Let's find your account`);
          // setJoiningUser("");
        }}
      />
    )
  }

  const showLoginin = () => {
    return (
      <LoginOrJoinForm
        // setRequiredRegistrationQuestionsFilled={setRequiredRegistrationQuestionsFilled}
        setStepHeader={setStepHeader}
        stepDescription={stepDescription}
        protectedPasswordError={protectedPasswordError}
        showProtectedPasswordScreen={showProtectedPasswordScreen}
        ref={surveyFormNextPageRef}
        loader={checkUserEmailSelector.loading || isLoader}
        errorMessage={loginError}
        loginUser={joiningUser}
        notYourEmail={() => {
          dispatch(resetUserEmail());
          setJoiningUser("");
          setStepName("Login or Join Now");
          setStepDescription("");
        }}
        userExist={checkUserEmailSelector.value?.result}
        onSubmit={(data: ILoginOrSignUp) => onLoginSubmit(data)}
        onSignup={() => {
          resetState();
          setStepName("Login or Join Now");
          setJoiningUser("");
        }}
        onForgot={() => {
          // resetState();
          dispatch(resetError());
          setStepName("Forgot Password");
          setStepDescription(`Let's find your account`);
          // setJoiningUser("");
        }}
      />
    )

  }

  const showForgotPassword = () => {
    return (

      <ForgotPasswordForm
        setStepHeader={setStepHeader}
        ref={surveyFormNextPageRef}
        loader={checkUserEmailSelector.loading || isLoader}
        errorMessage={forgotPasswordEmail?.errorMessage}
        userEmail={joiningUser}
        resetPassword={forgotPasswordEmail?.currentStep}
        onSubmit={(data: ForgotPasswordSchema) =>
          onForgotSubmit(data)
        }
        onResendCode={() => dispatch(reSendCode(joiningUser, onSuccessResendCode))}
      />
    )
  }

  const showSurveyForm = () => {
    const isSurveySubmitted = upcomingEvent?.value?.data?.attendeeStatus?.isSurveySubmitted;
    if(event?.type === "invitation" && eventInvitationStatus === "pending" && isSurveySubmitted && !userExitedSurvey) {
      setShowForm('underReview')
      return showRegistrationUnderReview()
    }
    return (
      <Survey
        setIsSuveryorRegSkipable={setIsSuveryorRegSkipable}
        setRequiredQuestionsFilled={setRequiredQuestionsFilled}
        setSurveySubPage={setSurveySubPage}
        surveySubPage={surveySubPage}
        setStepName={setStepName}
        setStepDescription={setStepDescription}
        ref={surveyFormNextPageRef}
        getCurrentSurveyPage={getCurrentPage}
        currentSurveyStep={currentSurveyStep}
        setCurrentSurveyStep={setCurrentSurveyStep}
        onLogout={() => {
          resetState();
          signoutClickHandler();
        }}
        onSurveyCompleted={() => {
          dispatch(getUpcomingEvent(eventId!));
        }}
        userId={user?.id!}
        open={open}
        setOpenSurveyForm={setOpenForm}
        title="Survey Form"
      />
    )
  }

  // useEffect(() => {
  //   dispatch(getUpcomingEvent(eventId!));
  // }, [eventId!])

  const switchFromRegForm = useCallback(() => {
    // if ((registered || isRegistered) && !signupUser?.loading) {
    //   if (eventType === "invitation") {
    //     setShowForm("underReview");
    //   } else setShowForm("surveyForm");
    // }
    // else {
    //   setShowForm("loader")
    // }
    dispatch(getUpcomingEvent(eventId!));

  }, [eventType, signupUser, isRegistered, registered, eventId])

  const showRegistrationForm = () => {
    return (
      <EventRegistrationFormNew
        setIsSuveryorRegSkipable={setIsSuveryorRegSkipable}
        setRequiredRegistrationQuestionsFilled={setRequiredRegistrationQuestionsFilled}
        registered={registered}
        ref={surveyFormNextPageRef}
        currentRegistrationStep={currentRegistrationStep}
        getCurrentRegistrationPage={getCurrentPage}
        setCurrentRegistrationStep={setCurrentRegistrationStep}
        user={user}
        setOpenForm={setOpenForm}
        registrationValue={user}
        openSurveyForm={() => {
          switchFromRegForm()
        }}
        onLogout={() => {
          // resetState();
          // signoutClickHandler();
          changeEmailRegistrationScreen()
        }}
      />
    )
  }

  const showSignUpRegistrationForm = () => {
    return (
      <SignupForm
        stepDescription={stepDescription}
        ref={surveyFormNextPageRef}
        loader={checkUserEmailSelector.loading || isLoader}
        errorMessage={signupError}
        newUserEmail={joiningUser}
        onSubmit={(data: ISignUp) => onSignupSubmit(data)}
      />
    )
  }

  const showLoader = () => {
    return (
      <Stack
        sx={{
          direction: "row",
          // height: "100%",
          height: "calc(100 % - 215px)",
          alignItems: "center",
          justifyContent: "center",
        }}
        spacing={2}
        rowGap={2}
      >
        <CircularProgress />
      </Stack>
    )
  }

  const showRejectRegistration = () => {
    return (
      <>
        <Typography sx={{ mt: 2, fontWeight: "600", color: secondaryFontColor }}>
          Rejected !
        </Typography>
        <Typography sx={{ mt: 2, fontWeight: "600", textAlign: "center", color: secondaryFontColor }}>
          {" "}
          Unfortunately your request to attend this event <br /> has not been accepted at this time.
        </Typography>
        <Typography sx={{ mt: 0.5 }}>
          {event?.rejectionMessage && <>{event?.rejectionMessage}</>}
        </Typography>
      </>
    )
  }

  const getPreviousStep = () => {
    if (!user && stepName?.includes("Reset Password")) {
      dispatch({
        type: ForgotPasswordActionTypes.RESET_FORGOTPASSWORD,
        payload: null,
      });
      setStepName("Forgot Password");
      setStepDescription(`Let's find your account`);
    } else if (!user && stepName?.includes("Forgot Password")) {
      setStepName("Login");
      setStepDescription(
        `The email you have entered is already registered on Panelist, enter your password to login`
      );
    }
    else if (!user && stepName?.includes("Register")) {
      resetState()
      setStepName("Login");
      setStepDescription(
        ``
      );
    }

    else if (showForm === "underReview") {
      console.log("prev of under review")
    }

    else if (showForm === "rejected") {
      console.log("prev of rejected")
    }

    else if (showForm === "registrationForm") {
      setCurrentRegistrationStep(currentRegistrationStep - 1)
    }

    else if (showForm === "surveyForm") {
      setCurrentSurveyStep(currentSurveyStep - 1)
      surveyFormNextPageRef?.current?.handlePrevPage()
    }
  }

  useEffect(() => {
    if (showForm === "underReview") {
      setStepName("Your registration is under review!")
      setStepDescription("We'll notify you once your registration has been accepted")
      setPageItems({
        bgColor: primaryBackgroundColor,
        isMediaExpanded: event?.eventQuestionPage?.isMediaExpanded,
        media: event?.eventQuestionPage?.media,
        primaryFontColor: primaryFontColor,
        secondaryBackgroundColor: secondaryBackgroundColor,
        currentPageId: "",
        lastSurveyorRegistrationPage: false,
        currentStep: 0,
        surveyorRegPagesIds: [],
        secondaryFontColor: secondaryFontColor,
        headerColor,
        titleDescPosition

      })
    }

    else if (showForm === "rejected") {
      setStepName("")
      setStepDescription("")
      setPageItems({
        bgColor: primaryBackgroundColor,
        isMediaExpanded: event?.eventQuestionPage?.isMediaExpanded,
        media: event?.eventQuestionPage?.media,
        primaryFontColor: primaryFontColor,
        secondaryBackgroundColor: secondaryBackgroundColor,
        currentPageId: "",
        lastSurveyorRegistrationPage: false,
        currentStep: 0,
        surveyorRegPagesIds: [],
        secondaryFontColor,
        headerColor,
        titleDescPosition
      })
    }

    else if (showForm === "login" || showForm == "forgotPassowrd" || showForm == "passwordProtected" || showForm == "signupRegistrationForm") {
      setPageItems({
        bgColor: primaryBackgroundColor,
        isMediaExpanded: event?.eventQuestionPage?.isMediaExpanded,
        media: event?.eventQuestionPage?.media,
        primaryFontColor: primaryFontColor,
        secondaryBackgroundColor: secondaryBackgroundColor,
        currentPageId: "",
        lastSurveyorRegistrationPage: false,
        currentStep: 0,
        surveyorRegPagesIds: [],
        secondaryFontColor,
        headerColor,
        titleDescPosition
      })
    }
  }, [showForm, event, surveySubPage])

  const getStep = useCallback(() => {
    let step = 0
    if (showForm === "login" || showForm === "forgotPassowrd" || showForm === "signupRegistrationForm" || showForm === "underReview" || showForm === "rejected") {
      step = 0
    }

    else if (showForm === "registrationForm") {
      step = currentRegistrationStep
    }

    else if (showForm === "surveyForm") {
      step = currentSurveyStep
    }

    return step
  }, [showForm, currentSurveyStep, currentRegistrationStep])

  const submitPage = useCallback(() => {
    if (showForm === "signupRegistrationForm") {
      surveyFormNextPageRef?.current?.handleSignupUser()
    }

    else if (showForm === 'passwordProtected') {
      surveyFormNextPageRef?.current?.handleContinueProtectedPassword()
    }

    else if (showForm === "underReview" || showForm === "rejected") {
      setOpenForm(false)
    }

    else if (showForm === "forgotPassowrd") {
      surveyFormNextPageRef?.current?.handleForgotPassword()
    }

    else if (showForm === "surveyForm") {
      if (surveySubPage === "attendeesListView") {
        // if (isPastEvent || !canAttendeesRequestMeeting) setOpenForm(false)
        setOpenForm(false)
      }

      else if (surveySubPage === "meetingListView") {
        surveyFormNextPageRef?.current?.handleRequestMeeting()
      }

      else if (surveySubPage === "surveyForm") {
        surveyFormNextPageRef?.current?.handleNextStep();
      }
    }

    else if (stepName.includes("Login") && showForm === "login") {
      surveyFormNextPageRef?.current?.handleContinueLogin()
    }

    else if (showForm === "registrationForm") {
      surveyFormNextPageRef?.current?.handleNextRegStep();
    }




  }, [showForm, surveySubPage, canAttendeesRequestMeeting, stepName])

  const getNextStep = useCallback(() => {
    if (showForm === "login" || showForm === "forgotPassowrd" || showForm === "signupRegistrationForm" || showForm === "underReview" || showForm === "rejected") {
      console.log("here is next steps for login")
    }

    else if (showForm === "registrationForm") {
      surveyFormNextPageRef?.current?.handleNextRegStep();
    }

    else if (showForm === "surveyForm") {
      surveyFormNextPageRef?.current?.handleNextStep();
    }
  }, [showForm, surveySubPage])

  const handleSkip = useCallback(() => {
    if (showForm === "surveyForm") {
      surveyFormNextPageRef?.current?.handleSkipPage()
    }

    else if (showForm === "registrationForm") {
      surveyFormNextPageRef?.current?.handleSkipPage()
    }
  }, [showForm])

  const getCurrentPage = useCallback((currentPage: any, surveyorRegPagesIds?: any) => {
    const pages = registrationPages?.map(item => item.id);
    if (showForm === "registrationForm") {

      setStepName(currentPage?.name)
      setStepDescription(currentPage?.description)
      setPageItems({
        bgColor: currentPage?.backgroundColor,
        isMediaExpanded: currentPage?.isMediaExpanded || false,
        media: currentPage?.media || "",
        primaryFontColor: currentPage?.fontColor || primaryFontColor,
        secondaryBackgroundColor: currentPage?.secondaryBackgroundColor || secondaryBackgroundColor,
        currentPageId: currentPage?.id,
        lastSurveyorRegistrationPage: currentPage?.id && currentRegistrationStep === surveyorRegPagesIds?.length - 1,
        currentStep: currentRegistrationStep,
        surveyorRegPagesIds: surveyorRegPagesIds,
        secondaryFontColor: currentPage?.fontColor,
        headerColor: currentPage?.headerColor || headerColor,
        titleDescPosition: currentPage?.titleDescPosition

      })
    }

    else if (showForm === "surveyForm") {
      setStepName(currentPage?.name)
      setStepDescription(currentPage?.description)
      if (surveySubPage === "surveyForm") {
        setPageItems({
          bgColor: currentPage?.backgroundColor || primaryBackgroundColor,
          isMediaExpanded: currentPage?.isMediaExpanded || false,
          media: currentPage?.media || "",
          primaryFontColor: currentPage?.fontColor || primaryFontColor,
          secondaryBackgroundColor: currentPage?.secondaryBackgroundColor || secondaryBackgroundColor,
          currentPageId: currentPage?.id,
          lastSurveyorRegistrationPage: currentPage?.id && currentSurveyStep === surveyorRegPagesIds?.length - 1,
          currentStep: currentSurveyStep,
          surveyorRegPagesIds,
          // secondaryFontColor: currentPage?.fontColor
          secondaryFontColor: currentPage?.fontColor,
          headerColor: currentPage?.headerColor || headerColor,
          titleDescPosition: currentPage?.titleDescPosition || titleDescPosition
        })
      }
      else {
        setPageItems({
          bgColor: primaryBackgroundColor,
          isMediaExpanded: true,
          media: currentPage?.media || "",
          // isMediaExpanded: event?.eventQuestionPage?.isMediaExpanded,
          // media: event?.eventQuestionPage?.media,
          primaryFontColor: primaryFontColor,
          secondaryBackgroundColor: secondaryBackgroundColor,
          currentPageId: "",
          lastSurveyorRegistrationPage: false,
          currentStep: 0,
          surveyorRegPagesIds: [],
          secondaryFontColor,
          headerColor,
          titleDescPosition

        })
      }
    }

    else {
      setPageItems({
        bgColor: primaryBackgroundColor,
        // isMediaExpanded: event?.eventQuestionPage?.isMediaExpanded,
        // media: event?.eventQuestionPage?.media,
        isMediaExpanded: false,
        media: "",
        primaryFontColor: primaryFontColor,
        secondaryBackgroundColor: secondaryBackgroundColor,
        currentPageId: "",
        lastSurveyorRegistrationPage: false,
        currentStep: 0,
        surveyorRegPagesIds: [],
        secondaryFontColor,
        headerColor,
        titleDescPosition
      })
    }
  }, [showForm, surveySubPage, currentRegistrationStep, event, currentSurveyStep])

  const checkRequiredFields = useCallback(() => {

    if (showForm === "registrationForm") {
      return requiredRegistrationQuestionsFilled
    }
    else if (showForm === "surveyForm") {
      return requiredSurveyQuestionsFilled
    }

    return true

  }, [currentRegistrationStep, registered, currentSurveyStep, requiredRegistrationQuestionsFilled, showForm, requiredSurveyQuestionsFilled])

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open && isModalOpen}
        sx={{
          '@media (max-width: 768px)': {
            margin: "15px",
            marginTop: "5px"
          },
          "& .MuiDialog-paper": {
            // ...hideScrollbarY,
            // minHeight: "400px",
            minWidth: "400px",
            borderRadius: "10px",
            // maxHeight: "100%",
            background: "none",
            '@media (max-width: 768px)': {
              minWidth: "100%",
              // maxHeight: "100%",
              // ...hideScrollbarY,
            },
            '@media (min-width: 1700px)': {
              maxWidth: "70vw",
            },
          },

          "& .MuiPaper-root": {
            borderRadius: "10px !important",
            "& .MuiCard-root": {
              background: "none",
              borderRadius: "10px !important"
            }
          }

        }}
      >

        <FormCard
          isSuveryorRegSkipable={isSuveryorRegSkipable}
          setPasswordProtectionCheck={setPasswordProtectionCheck}
          requiredFieldsFilled={checkRequiredFields()}
          submitPage={() => submitPage()}
          surveySubPage={surveySubPage}
          currentPage={showForm}
          step={getStep()}
          handleSkip={() => handleSkip()}
          onNextStep={() => { getNextStep() }}
          // onNextStep={() => { submitPage() }}
          onPreviousStep={() => { getPreviousStep() }}
          user={user}
          logoColor="#FFFFFF"
          stepName={stepName}
          onLogout={() => {
            resetState();
            signoutClickHandler();
          }}
          stepDescription={stepDescription}
          setOpenForm={(value) => {
            resetState();
            setOpenForm(value);
          }}
          key={1}
          pageItems={pageItems}
          mediaURL={pageItems?.media}
          isMediaExpanded={pageItems?.isMediaExpanded}
          isLoading={false}
          primaryFontColor={pageItems?.primaryFontColor}
          secondaryBackgroundColor={pageItems?.secondaryBackgroundColor}
          bgColor={pageItems?.bgColor}
          lastSurveyorRegistrationPage={pageItems?.lastSurveyorRegistrationPage}
          stepHeader={stepHeader}

        >
          {(showForm !== "registrationForm" && showForm !== "surveyForm") ?
            <Stack
              sx={{
                direction: "row",
                // minHeight: "100%",
                height: "100%",
                alignItems: "center", // on login align items start
                justifyContent: "center",
                // '@media (min-width: 768px)': {
                //   height: showForm === "signupRegistrationForm" ? "calc(100% - 0px)" : "100%",
                // },
                '@media (max-width: 768px)': {
                  // height: showForm === "login" || showForm === "forgotPassowrd" ? "calc(100% - 100px)" : null,
                },
              }}
              spacing={2}
              rowGap={2}
            >
              {showForm === 'passwordProtected' ?
                showPasswordProtectedScreen() :
                showForm === "login" ?
                  showLoginin() :
                  showForm === "forgotPassowrd" ?
                    showForgotPassword() :
                    showForm === "signupRegistrationForm" ?
                      showSignUpRegistrationForm() :
                      showForm === "underReview" ?
                        showRegistrationUnderReview() :
                        showForm === "rejected" ?
                          showRejectRegistration() :

                          showLoader()
              }
            </Stack> : showForm === "registrationForm" ?
              showRegistrationForm() :
              showForm === "surveyForm" ? surveySubPage==='underReviewAfterSurvey' ? showRegistrationUnderReview() : showSurveyForm() :
                showLoader()}
        </FormCard>
      </Dialog >
      {(showForm === "signupRegistrationForm" && openUserAleardyExistPopup) &&
        < ConfirmDialog
          open={openUserAleardyExistPopup}
          onClose={() => setOpenUserAleardyExistPopup(false)}
          title="Account Already Exist!"
          content={<>An account with your email address already exists in our system. Would you like to log in instead?</>}
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleUserAccountExist();
              }}
              sx={{ color: "#ffffff", background: "#0031ff", "&:hover": { background: "#0031ff" }, fontFamily: "Poppins-400", fontWeight: 600, fontSize: "14px", borderRadius: "8px" }}
            >
              Login
            </Button>

          }
        />}
    </>
  );
}

export default RegistrationStepForm;
