import React from "react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useMeetingManager,
  AudioInputControl,
  ContentShareControl,
  RosterAttendeeType,
  useRosterState,
  LocalVideo,
  RemoteVideo,
  useAttendeeStatus,
  useLocalVideo,
  useToggleLocalMute,
  useRemoteVideoTileState,
  useMeetingStatus,
  useLocalAudioOutput,
  VideoInputBackgroundBlurControl,
  ContentShare,
  ControlBar,
  MeetingStatus,
  useContentShareState,
  AudioOutputControl,
  Phone,
} from "amazon-chime-sdk-component-library-react";
import { IAppState } from "src/store";
import { setJoinExpoBoothRoom} from "src/store/liveEvent";
import { EventsService } from "src/services/events";
import { LoadingScreen } from "src/mui/components/loading-screen";
import { useCurrentUser } from "src/utils/hooks";
import VideoMeetingModal from "src/components/alert-modal/video-meeting-modal";
import { ChimeService } from "src/services/ChimeService";
import { MeetingVideoCallLayout } from "src/components/chime-video-call/meeting-video-call-layout";
import { MeetingUserCard } from "src/components/chime-video-call/meeting-user-card";
import "../../../../../../styles/1-1-meetings.css";
import { useSurveyContext } from "src/mui/providers/GeneralContext";
import { Loader } from "src/shared-components/Loader";
import { Typography } from "@mui/material";
import { UserService } from "src/services";
import { toast } from "react-toastify";

export const ExpoBoothMeetingRoomModal = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state: IAppState) => state);
  const {  joinExpoBoothRoom} = selector.liveEvent;
  const { isAudioOn, toggleAudio } = useLocalAudioOutput();
  const [meetingResponse, setMeetingResponse] = useState<any>();
  const [meetingStarted, setMeetingStarted] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [loading, setMeetingLoading] = useState<any>(false);
  const { isLocalUserSharing, sharingAttendeeId } = useContentShareState();
  const [error, setError] = useState<string>("");
  const meetingStatus = useMeetingStatus();
  const meetingManager = useMeetingManager();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const { tiles, attendeeIdToTileId } = useRemoteVideoTileState();
  const { roster } = useRosterState();
  const [videoTiles, setVideoTiles] = useState<{
    local: JSX.Element | null;
    remote: JSX.Element[];
  }>({ local: null, remote: [] });
  const user = useCurrentUser();
  const [isRemoteAttendee, setIsRemoteAttendee] = useState(false);
  const [hasRemoteUsers, setHasRemoteUsers] = useState(false);
  const [sharingAttendeeID, setSharingAttendeeID] = useState<any>("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const chimeAttendees: RosterAttendeeType[] = useMemo(
    () => Object.values(roster),
    [roster]
  );

  const { setScreenID, screenId } = useSurveyContext();

  // eslint-disable-next-line no-return-await
  const connectToChime = async (config: any) =>
    await new ChimeService()
      .loginToMeeting(config, meetingManager)
      .catch((err) => console.error("Chime service failed with error", err));

  const meeting = async () => {
    setMeetingLoading(true);
    setError("");
    try {
      const response = await new EventsService()
        .joinExpoBoothRoom(joinExpoBoothRoom?.eventId, joinExpoBoothRoom?.eventExpoBoothId)
        .then((r: any) => r.data);
      const data = response;
      setMeetingResponse(data);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    } catch (error) {
      setError(
        "There is some issue to connect you in the meeting. Please Try Again!"
      );
      toast.error( "There is some issue to connect you in the meeting. Please Try Again!");
      setMeetingLoading(false);
      leaveMeeting();
    }
  };
  useEffect(() => {
    if (!joinExpoBoothRoom) return;
    meeting();
    return () => {
      leaveMeeting();
    };
  }, []);

  useEffect(() => {
    if (!meetingStarted && meetingResponse) {
      {
        connectToChime({
          chimeMeeting: meetingResponse.meeting,
          attendeeData: meetingResponse.attendee,
        }).then(() => {
          setMeetingStarted(true);
          setMeetingLoading(false);
        });
      }
    }
  }, [meetingResponse, meetingStarted]);

  useEffect(() => {
    if (!isVideoEnabled && meetingStatus === MeetingStatus.Succeeded) {
      toggleVideo();
    }
    if (!isAudioOn && meetingStatus === MeetingStatus.Succeeded) {
      toggleAudio();
    }
  }, [meetingStatus]);

  useEffect(() => {

    const fetchAttendeeDetails = async () => {
      const tempChimeAttendees: any = chimeAttendees;
      for (let attendee of tempChimeAttendees) {
        const userService = new UserService();
        attendee.details = await userService.getUserProfileLite(attendee.externalUserId!).then(user => user.data).catch(()=>{ return undefined; });
      }
      return tempChimeAttendees.reverse();
    }

    fetchAttendeeDetails().then(setAttendees);
  }, [chimeAttendees])

  useEffect(() => {
    const remoteUsersExist = chimeAttendees.some(
      (attendee) => (attendee as any).externalUserId !== user.id
    );
    setHasRemoteUsers(remoteUsersExist);

    let localTile = null;
    const remoteTiles: JSX.Element[] = [];

    chimeAttendees.forEach((attendee: any) => {
      const meetingUser:any = attendees?.find((x:any)=>x?.details?.id ===  attendee.externalUserId)
      if (attendee.externalUserId === user.id) {
        localTile = (
          <CreateLocalVideoTileLayout
            isVideoEnabled={isVideoEnabled}
            attendee={{ ...attendee, ...meetingUser?.details }}
            key={attendee.chimeAttendeeId}
            hasRemoteUsers={remoteUsersExist}
            screenID={sharingAttendeeID}
          />
        );
      } else {
        remoteTiles.push(
          <CreateRemoteVideoTileLayout
            tileId={attendeeIdToTileId[attendee.chimeAttendeeId]}
            attendee={{ ...attendee, ...meetingUser?.details }}
            key={attendee.chimeAttendeeId}
          />
        );
      }
    });

    setVideoTiles({ remote: remoteTiles, local: localTile });
  }, [chimeAttendees,chimeAttendees?.length,attendees, tiles, muted, isVideoEnabled, user.id]);

  const leaveMeeting = async () => {
    await new EventsService()
      .leaveExpoBoothRoom(joinExpoBoothRoom?.eventId, joinExpoBoothRoom?.eventExpoBoothId)
      .then((r: any) => r.data)
      .catch(() => undefined)
      .finally(() => {
        if (isVideoEnabled) {
          toggleVideo();
        }
        if (isAudioOn) {
          toggleAudio();
        }
        if (meetingManager) meetingManager.leave();
        dispatch(setJoinExpoBoothRoom({ ...joinExpoBoothRoom, isRoomOpen: false }));
      });
    setIsRemoteAttendee(false);
  };

  useEffect(() => {
    setIsRemoteAttendee(true);
  }, [isLocalUserSharing]);

  useEffect(() => {
    return setScreenID(sharingAttendeeId);
  }, [sharingAttendeeId]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <VideoMeetingModal
      className="flex-col items-center justify-center overflow-auto z-[10000] bg-[#2f2f2f] relative"
      isOpen={joinExpoBoothRoom?.isRoomOpen}
      disableClose
      isAttendees={isRemoteAttendee}
    >
      <div>
        {loading && !meetingResponse && (
          <div className="flex flex-row w-[374px] h-[258px] flex-col items-center justify-center p-2">
            <LoadingScreen loadingColor='#0049EF' />
            {/* <Loader className=" text-[#ffffff]" key="loader" /> */}
          </div>
        )}
        {error && (
          <div className="flex flex-row items-center justify-center w-full h-full p-2">
            {error}
          </div>
        )}
        {meetingResponse && !loading && chimeAttendees.length > 0 && (
          <>
            <div className="flex flex-row items-center justify-center p-2 gap-2">
              {isLocalUserSharing ? (
                <div>
                  <ContentShare css={`{height: calc(100vh - 116px) !important; width: ${screenWidth - 440}px !important; border-radius: 6px;}`} />
                </div>
              ) : null}
              <MeetingVideoCallLayout
                tiles={videoTiles.remote}
                localTile={videoTiles.local}
                hasRemoteUsers={hasRemoteUsers}
              />
            </div>
            <div className="flex gap-3 justify-center items-center p-2">
              <ControlBar
                layout="undocked-horizontal"
                showLabels={false}
                className=""
                css="{ background-color: black !important; height: 2.625rem !important; } .ch-content { font-size: 10px; } .ch-icon {color: #ffffff}"
              >
                <AudioInputControl />
                <AudioOutputControl />
                <Phone
                  width="2rem"
                  className="cursor-pointer text-white bg-red-500 rounded-full p-1 shadow flex items-center justify-center"
                  onClick={leaveMeeting}
                />
                <VideoInputBackgroundBlurControl />
                <ContentShareControl />
                {/* <Phone
                  width="2rem"
                  className="cursor-pointer text-red"
                  onClick={leaveMeeting}
                /> */}
              </ControlBar>
            </div>
          </>
        )}
        {!loading && chimeAttendees.length === 0 && (
          <div className="flex min-w-[374px] min-h-[258px] flex-col items-center justify-center p-2">
            <Typography sx={{ color: '#ffffff' }} mb={2}>Waiting for connection!</Typography>
            <Loader className=" text-[#ffffff]" key="loader" />
          </div>
        )}
      </div>
    </VideoMeetingModal>
  );
};

/**
 * Create Remote video tile with user card of remote user video
 */
interface ICreateRemoteVideoTileLayout {
  tileId: number;
  attendee: any;
}
const CreateRemoteVideoTileLayout = React.memo(
  ({ tileId, attendee }: ICreateRemoteVideoTileLayout) => {
    const { videoEnabled } = useAttendeeStatus(attendee.chimeAttendeeId);

    return (
      <div className="relative w-full h-full flex flex-col overflow-hidden">
        {videoEnabled ? (
          <RemoteVideo tileId={tileId} />
        ) : (
          <MeetingUserCard user={attendee!} />
        )}
        <div className="w-full flex flex-col items-start justify-center h-[35px] bg-sky-900 opacity-75 absolute bottom-0 text-left px-2.5 text-white text-xs">
          <div className="leading-[14px] line-clamp-1">{attendee?.fullName}</div>
          <div className="font-semibold leading-[14px]">{attendee?.jobTitle}</div>
        </div>
      </div>
    );
  },
  (prevProp, nextProp) =>
    prevProp.tileId === nextProp.tileId &&
    prevProp.attendee?.id === nextProp.attendee?.id
);

/**
 * Create Local Video Tile with user card or camera video
 */
interface ICreateLocalVideoTileLayout {
  isVideoEnabled: boolean;
  attendee: any;
  hasRemoteUsers: boolean;
  screenID: string;
}

const CreateLocalVideoTileLayout = React.memo(
  ({
    isVideoEnabled,
    attendee,
    hasRemoteUsers,
    screenID,
  }: ICreateLocalVideoTileLayout) => {
    return (
      <div
        className={`relative w-full h-full flex flex-col overflow-hidden ${
          hasRemoteUsers && screenID === null ? "pip" : ""
        }`}
      >
        {isVideoEnabled ? (
          <LocalVideo />
        ) : (
          <MeetingUserCard hasRemoteUsers={hasRemoteUsers} user={attendee!} />
        )}
        <div className="flex flex-row w-full h-[35px] bg-sky-900 opacity-75 absolute bottom-0 text-left px-2.5 text-white text-xs justify-between">
          <div className="flex justify-center items-center leading-[35px]">(Me)</div>
        </div>
      </div>
    );
  },
  (
    prevProp: Readonly<ICreateLocalVideoTileLayout>,
    nextProp: Readonly<ICreateLocalVideoTileLayout>
  ) =>
    prevProp.isVideoEnabled === nextProp.isVideoEnabled &&
    prevProp.attendee?.id === nextProp.attendee?.id &&
    prevProp.hasRemoteUsers === nextProp.hasRemoteUsers
);
