/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import {
  useCallback, useEffect, useRef, useState
} from 'react';
import {
  Stack, Dialog, CircularProgress, Button
} from '@mui/material';
import 'src/mui/pages/event/reg-dialog-flow/Card.css';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserEmail, resetUserEmail } from 'src/store/checkUserEmail';
import { IUserProfile } from 'src/models/user-profile';
import { IAppState } from 'src/store';
import { login, unsetUser, resetError } from 'src/store/auth';
import { checkUserStatus } from 'src/store/user-status';

import { eventRegistrationStep1 } from 'src/store/user/signup/actions';
import {
  useSurveyContext,
} from 'src/mui/providers/GeneralContext';
import {
  reSendCode,
  resetPassword,
  sendCode,
} from 'src/store/user/forgotpassword/actions';
import { ForgotPasswordActionTypes } from 'src/store/user/forgotpassword/types';
import { delAuthToken } from 'src/utils';
import { UserSignUpActionTypes } from 'src/store/user/signup/types';
import { setRegistered } from 'src/store/event-website';
import { UpcomingEventActionTypes } from 'src/store/events/upcoming-event/types';
import useStyling from 'src/mui/components/hook-form/useStyling';
import useEventIsPassed from 'src/mui/pages/survey/useEventIsPassed';
import { RegistrationFieldType } from 'src/mui/types/registration';
import { isBusinessEmail } from 'src/mui/pages/survey/constants';
import { toast } from 'react-toastify';
import ErrorMessage from 'src/components/error-message/errorMessage';
import { ILoginOrSignUp } from 'src/mui/pages/event/reg-dialog-flow/form.type';
import { setIsLoading } from 'src/store/navBarSearch/actions';
import SignupForm, { ISignUp } from 'src/mui/pages/event/reg-dialog-flow/SignupForm';
import ForgotPasswordForm, { ForgotPasswordSchema } from 'src/mui/pages/event/reg-dialog-flow/ForgotPasswordForm';
import LoginOrJoinForm from 'src/mui/pages/event/reg-dialog-flow/LoginOrJoinForm';
import FormCard from 'src/mui/pages/event/reg-dialog-flow/Card';
import { ConfirmDialog } from 'src/mui/components/custom-dialog';
import FactorSurvey from './SurveyFormContainer';

export const hideScrollbarY = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

type Props = {
  user: IUserProfile | undefined;
  open: boolean;
  eventType: string;
  eventInvitationStatus: string;
  registered: boolean;
  eventId: string;
  setOpenForm: (value: boolean) => void;
  setUser?: (value: any) => void;
};

interface SurveyRef {
  handleNextStep: Function;
  handlePrevPage: Function;
  handleNextRegStep: Function;
  handleSignupUser: Function;
  handleRequestMeeting: Function;
  handleContinueLogin: Function;
  handleForgotPassword: Function;
  handleContinueProtectedPassword: Function;
  handleSkipPage: Function
}

type pageItems = {
  bgcolor: string;
  logoColor: string;
  isMediaExpanded: boolean;
  mediaURL: string;
  titleDescPosition?: string;
}

const SurveyForm = ({
  user,
  setUser,
  open,
  setOpenForm,
  eventType,
  eventInvitationStatus,
  registered,
  eventId,
}: Props) => {
  const {
    eventProtectedPassword, showProtectedPasswordScreen, protectedPasswordError, signupRegQuestion, resetProtectedPasswordError
  } = useSurveyContext();

  const {
    primaryBackgroundColor, primaryFontColor, secondaryBackgroundColor, secondaryFontColor, headerColor, titleDescPosition
  } = useStyling(null, null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [openUserAleardyExistPopup, setOpenUserAleardyExistPopup] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState<any>(false);
  const [signupUserPayload, setSignupUserPayload] = useState<any>();
  const [stepName, setStepName] = useState<string>('');
  const [currentRegistrationStep, setCurrentRegistrationStep] = useState<number>(0);

  const [stepDescription, setStepDescription] = useState<string>('');
  const [joiningUser, setJoiningUser] = useState<string>('');
  const [showForm, setShowForm] = useState<string>('');
  const [passwordProtectionCheck, setPasswordProtectionCheck] = useState(false);
  const [requiredSurveyQuestionsFilled, setRequiredQuestionsFilled] = useState<boolean>(false);
  const [requiredRegistrationQuestionsFilled, setRequiredRegistrationQuestionsFilled] = useState<boolean>(false);
  const [isSuveryorRegSkipable, setIsSuveryorRegSkipable] = useState<boolean>(false);
  const [loginEmail, setLoginEmail] = useState<string>('');
  const [stepHeader, setStepHeader] = useState<any>('');
  const { canAttendeesRequestMeeting } = useEventIsPassed();
  const emailDefaultQuestion = signupRegQuestion && signupRegQuestion?.length && signupRegQuestion?.find((question: any) => (question.fieldType === RegistrationFieldType.EMAIL || question.fieldType === RegistrationFieldType.BUSINESS_EMAIL) && !question?.id);
  const isBusinessEmailValidationRequired = emailDefaultQuestion?.fieldType === RegistrationFieldType.BUSINESS_EMAIL;

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const { upcomingEvent } = useSelector(
    (state: IAppState) => state
  );

  const isRegistered = upcomingEvent?.value?.data?.attendeeStatus?.isRegistered;

  const [pageItems, setPageItems] = useState({
    bgColor: primaryBackgroundColor,
    isMediaExpanded: false,
    primaryFontColor: primaryFontColor || null,
    media: '',
    secondaryFontColor: secondaryFontColor || null,
    secondaryBackgroundColor: secondaryBackgroundColor || null,
    currentPageId: '',
    headerColor: headerColor || null,
    lastSurveyorRegistrationPage: false,
    currentStep: 0,
    surveyorRegPagesIds: [],
    titleDescPosition,
  });

  // for survey page dialog
  const [currentSurveyStep, setCurrentSurveyStep] = useState<number>(0);
  const [surveySubPage, setSurveySubPage] = useState<string>('surveyForm');

  const surveyFormNextPageRef = useRef<SurveyRef>(null);

  const [page, setPage] = useState(0);

  const event = useSelector(
    (state: IAppState) => state.website.event || state.upcomingEvent?.value?.data?.event
  );

  const checkUserEmailSelector = useSelector(
    (state: IAppState) => state.checkUserEmail
  );

  const { errorMessage: loginError } = useSelector(
    (state: IAppState) => state.auth
  );

  const forgotPasswordEmail = useSelector(
    (state: IAppState) => state.forgotPasswordEmail
  );

  const { errorMessage: signupError } = useSelector(
    (state: IAppState) => state.signupUser
  );

  const resetForm = () => {
    sessionStorage.removeItem('userName');
    setSignupUserPayload(null);
    changePage(0);
    dispatch({
      type: UserSignUpActionTypes.GO_TO_FIRST_STEP,
    });
    dispatch(resetUserEmail());
    resetProtectedPasswordError();
  };

  const handleUserAccountExist = () => {
    setJoiningUser(signupUserPayload?.payload?.email);
    setStepName('Login');
    setStepDescription(
      'The email you have entered is already registered on Panelist, enter your password to login'
    );
    setOpenUserAleardyExistPopup(false);
  };

  const signoutClickHandler = () => {
    delAuthToken();
    setSignupUserPayload(null);
    resetForm();
    sessionStorage.setItem('termAndCondition', 'false');
    localStorage.clear();
    setJoiningUser('');
    setStepName('Login or Join Now');
    setStepDescription('');
    setShowForm('');
    dispatch(setRegistered(null));
    dispatch(unsetUser());

    dispatch({
      type: UpcomingEventActionTypes.RESET_UPCOMING_EVENT_LOADING,
      payload: {}
    });

    dispatch({
      type: UserSignUpActionTypes.RESET_SIGNUP_USER,
      payload: {}
    });

    if (setUser) setUser(undefined);
  };

  useEffect(() => {
    if (open) {
      setIsModalOpen(true);
      if (user) setStepName('Survey Registration');
      else setStepName('Login or Join Now');
    } else {
      setIsModalOpen(false);
    }
  }, [open]);

  useEffect(() => {
    if (checkUserEmailSelector?.loading) return;
    if (!checkUserEmailSelector?.loading && !!checkUserEmailSelector.value) {
      const userExists = checkUserEmailSelector.value?.result;
      if (showForm === 'signupRegistrationForm') {
        if (userExists) {
          setOpenUserAleardyExistPopup(true);
        } else {
          setIsLoading(true);
          setOpenUserAleardyExistPopup(false);
          setJoiningUser('');
          if (signupUserPayload?.payload) {
            // sessionStorage.setItem('signupAnswers', JSON.stringify(signupUserPayload?.payload));
            dispatch(
              eventRegistrationStep1(
                signupUserPayload?.payload,
                signupUserPayload?.selectedCompanyStateToStore,
                signupUserPayload?.selectedJobTitleStateToStore,
                signupUserPayload?.selectedIndustryStateToStore,
                signupUserPayload?.selectedJobFunctionIdStateTostore,
              )
            );
          }
        }
      } else if (userExists) {
        setStepName('Login');
        setStepDescription(
          'The email you have entered is already registered on Panelist, enter your password to login'
        );
      } else if (!isBusinessEmailValidationRequired || (isBusinessEmailValidationRequired && isBusinessEmail(loginEmail || joiningUser))) {
        setStepName('Register');
        setStepDescription(
          'By clicking Agree & Join, you agree to Panelist User Agreement, Privacy Policy and Cookie Policy'
        );
      }
    }
  }, [checkUserEmailSelector?.value, loginEmail]);

  const onSuccessPasswordResest = () => {
    setStepName('Login');
    setStepDescription(
      'Your password has been reset.'
    );
  };

  const onSuccessResendCode = () => {
    if (windowSize?.current[0] > 600) {
      toast.dismiss();
      toast.success(
        <ErrorMessage
          title="Resend Code"
          message="Code is sent to your email."

        />
      );
    }
  };

  useEffect(() => {
    if (
      !forgotPasswordEmail?.loading
      && !!forgotPasswordEmail.value
      && forgotPasswordEmail.currentStep
    ) {
      const successfullySendCode = forgotPasswordEmail.value?.message;
      if (successfullySendCode && stepName?.includes('Forgot Password')) {
        setStepName('Reset Password');
        setStepDescription('Code is sent to your email.');
      }
    }
  }, [forgotPasswordEmail.value]);

  useEffect(() => {
    if (user) {
      setShowForm('surveyForm');
    } else if (!user) {
      if (stepName?.includes('Login')) {
        setShowForm('login');
      } else if (stepName?.includes('Password')) {
        setShowForm('forgotPassowrd');
      } else if (stepName?.includes('Register')) {
        setShowForm('signupRegistrationForm');
      } else {
        setShowForm('loader');
      }
    }
  }, [user, isRegistered, stepName, registered, open]);

  const resetState = () => {
    dispatch({
      type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
      errorMessage: '',
    });
    setSurveySubPage('');
    setCurrentRegistrationStep(0);
    setCurrentSurveyStep(0);
    dispatch(resetUserEmail());
    dispatch({
      type: ForgotPasswordActionTypes.RESET_FORGOTPASSWORD,
      payload: null,
    });
    dispatch(resetError());
    setStepName('');
    setStepDescription('');
    setJoiningUser('');
    setPageItems({
      bgColor: primaryBackgroundColor,
      isMediaExpanded: event?.eventQuestionPage?.isMediaExpanded,
      media: event?.eventQuestionPage?.media,
      primaryFontColor,
      secondaryBackgroundColor,
      secondaryFontColor,
      currentPageId: '',
      lastSurveyorRegistrationPage: false,
      currentStep: 0,
      surveyorRegPagesIds: [],
      headerColor,
      titleDescPosition
    });
  };

  const onLoginSubmit = (data: ILoginOrSignUp) => {
    setLoginEmail(data?.email);
    if (checkUserEmailSelector.loading) return;
    if (showProtectedPasswordScreen) {
      const body = {
        passCode: data?.protectedPassword
      };
      eventProtectedPassword(body);
    } else {
      dispatch(resetError());
      if (!joiningUser || !checkUserEmailSelector.value?.result) {
        setJoiningUser(data.email);
        dispatch(checkUserEmail(data));
      } else if (checkUserEmailSelector.value?.result) {
        if (isLoader) return;
        dispatch(
          login(data.email, data.password!, navigate, 'event', changePage, '', setIsLoader)
        );
        dispatch(checkUserStatus());
      }
    }
  };

  const onSignupSubmit = (data: ISignUp) => {
    if (checkUserEmailSelector?.loading) return;
    const payload: any = { ...data };

    dispatch(checkUserEmail(payload));

    const selectedCompanyStateToStore = data.companyId;
    const selectedJobTitleStateToStore = data.jobTitle?.name || data.jobTitle;
    const selectedIndustryStateToStore = data.industryId;
    const selectedJobFunctionIdStateToStore = data.jobFunctionId;
    payload.companyId = data.companyId?.id || data.companyId;
    payload.industryId = data.industryId?.id;
    payload.jobFunctionId = data.jobFunctionId?.id;
    payload.jobTitle = data.jobTitle?.name || data.jobTitle;
    payload.city = data.location?.city || '';
    payload.country = data?.location?.country || '';
    payload.location = data.location?.name || '';
    setSignupUserPayload({
      payload, selectedCompanyStateToStore, selectedJobTitleStateToStore, selectedIndustryStateToStore, selectedJobFunctionIdStateToStore
    });
  };

  const onForgotSubmit = (data: ForgotPasswordSchema) => {
    if (forgotPasswordEmail?.currentStep) {
      dispatch(resetPassword(data, data?.email, onSuccessPasswordResest));
    } else {
      dispatch(sendCode(data));
    }
  };

  const changePage = (page: number) => {
    setPage(page);
  };
  const showLoginin = () => (
    <LoginOrJoinForm
      setStepHeader={setStepHeader}
      stepDescription={stepDescription}
      protectedPasswordError={protectedPasswordError}
      showProtectedPasswordScreen={showProtectedPasswordScreen}
      ref={surveyFormNextPageRef}
      loader={checkUserEmailSelector.loading || isLoader}
      errorMessage={loginError}
      loginUser={joiningUser}
      notYourEmail={() => {
        dispatch(resetUserEmail());
        setJoiningUser('');
        setStepName('Login or Join Now');
        setStepDescription('');
      }}
      userExist={checkUserEmailSelector.value?.result}
      onSubmit={(data: ILoginOrSignUp) => onLoginSubmit(data)}
      onSignup={() => {
        resetState();
        setStepName('Login or Join Now');
        setJoiningUser('');
      }}
      onForgot={() => {
        dispatch(resetError());
        setStepName('Forgot Password');
        setStepDescription('Let\'s find your account');
      }}
    />
  );

  const showForgotPassword = () => (

    <ForgotPasswordForm
      setStepHeader={setStepHeader}
      ref={surveyFormNextPageRef}
      loader={checkUserEmailSelector.loading || isLoader}
      errorMessage={forgotPasswordEmail?.errorMessage}
      userEmail={joiningUser}
      resetPassword={forgotPasswordEmail?.currentStep}
      onSubmit={(data: ForgotPasswordSchema) => onForgotSubmit(data)}
      onResendCode={() => dispatch(reSendCode(joiningUser, onSuccessResendCode))}
    />
  );

  const showSurveyForm = () => (
    <FactorSurvey
      setIsSuveryorRegSkipable={setIsSuveryorRegSkipable}
      setRequiredQuestionsFilled={setRequiredQuestionsFilled}
      setSurveySubPage={setSurveySubPage}
      surveySubPage={surveySubPage}
      setStepName={setStepName}
      setStepDescription={setStepDescription}
      ref={surveyFormNextPageRef}
      getCurrentSurveyPage={getCurrentPage}
      currentSurveyStep={currentSurveyStep}
      setCurrentSurveyStep={setCurrentSurveyStep}
      onLogout={() => {
        resetState();
        signoutClickHandler();
      }}
      onSurveyCompleted={() => {
        setOpenForm(false);
      }}
      userId={user?.id!}
      open={open}
      setOpenSurveyForm={setOpenForm}
      title="Survey Form"
    />
  );

  const showSignUpRegistrationForm = () => (
    <SignupForm
      stepDescription={stepDescription}
      ref={surveyFormNextPageRef}
      loader={checkUserEmailSelector.loading || isLoader}
      errorMessage={signupError}
      newUserEmail={joiningUser}
      onSubmit={(data: ISignUp) => onSignupSubmit(data)}
    />
  );

  const showLoader = () => (
    <Stack
      sx={{
        direction: 'row',
        // height: "100%",
        height: 'calc(100 % - 215px)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      spacing={2}
      rowGap={2}
    >
      <CircularProgress />
    </Stack>
  );
  const getPreviousStep = () => {
    if (!user && stepName?.includes('Reset Password')) {
      dispatch({
        type: ForgotPasswordActionTypes.RESET_FORGOTPASSWORD,
        payload: null,
      });
      setStepName('Forgot Password');
      setStepDescription('Let\'s find your account');
    } else if (!user && stepName?.includes('Forgot Password')) {
      setStepName('Login');
      setStepDescription(
        'The email you have entered is already registered on Panelist, enter your password to login'
      );
    } else if (!user && stepName?.includes('Register')) {
      resetState();
      setStepName('Login');
      setStepDescription(
        ''
      );
    } else if (showForm === 'surveyForm') {
      setCurrentSurveyStep(currentSurveyStep - 1);
      surveyFormNextPageRef?.current?.handlePrevPage();
    }
  };

  useEffect(() => {
    if (showForm === 'login' || showForm === 'forgotPassowrd' || showForm === 'passwordProtected' || showForm === 'signupRegistrationForm') {
      setPageItems({
        bgColor: primaryBackgroundColor,
        isMediaExpanded: event?.eventQuestionPage?.isMediaExpanded,
        media: event?.eventQuestionPage?.media,
        primaryFontColor,
        secondaryBackgroundColor,
        currentPageId: '',
        lastSurveyorRegistrationPage: false,
        currentStep: 0,
        surveyorRegPagesIds: [],
        secondaryFontColor,
        headerColor,
        titleDescPosition
      });
    }
  }, [showForm, event, surveySubPage]);

  const getStep = useCallback(() => {
    let step = 0;
    if (showForm === 'login' || showForm === 'forgotPassowrd' || showForm === 'signupRegistrationForm' || showForm === 'underReview' || showForm === 'rejected') {
      step = 0;
    } else if (showForm === 'registrationForm') {
      step = currentRegistrationStep;
    } else if (showForm === 'surveyForm') {
      step = currentSurveyStep;
    }

    return step;
  }, [showForm, currentSurveyStep, currentRegistrationStep]);

  const submitPage = useCallback(() => {
    if (showForm === 'signupRegistrationForm') {
      surveyFormNextPageRef?.current?.handleSignupUser();
    } else if (showForm === 'passwordProtected') {
      surveyFormNextPageRef?.current?.handleContinueProtectedPassword();
    } else if (showForm === 'forgotPassowrd') {
      surveyFormNextPageRef?.current?.handleForgotPassword();
    } else if (showForm === 'surveyForm') {
      if (surveySubPage === 'surveyForm') {
        surveyFormNextPageRef?.current?.handleNextStep();
      }
    } else if (stepName.includes('Login') && showForm === 'login') {
      surveyFormNextPageRef?.current?.handleContinueLogin();
    } else if (showForm === 'registrationForm') {
      surveyFormNextPageRef?.current?.handleNextRegStep();
    }
  }, [showForm, surveySubPage, canAttendeesRequestMeeting, stepName]);

  const getNextStep = useCallback(() => {
    if (showForm === 'surveyForm') {
      surveyFormNextPageRef?.current?.handleNextStep();
    }
  }, [showForm, surveySubPage]);

  const handleSkip = useCallback(() => {
    if (showForm === 'surveyForm') {
      surveyFormNextPageRef?.current?.handleSkipPage();
    } else if (showForm === 'registrationForm') {
      surveyFormNextPageRef?.current?.handleSkipPage();
    }
  }, [showForm]);

  const getCurrentPage = useCallback((currentPage: any, surveyorRegPagesIds?: any) => {
    if (showForm === 'surveyForm' && currentPage?.id) {
      setStepName(currentPage?.name);
      setStepDescription(currentPage?.description);
      if (surveySubPage === 'surveyForm') {
        setPageItems({
          bgColor: currentPage?.backgroundColor || primaryBackgroundColor,
          isMediaExpanded: currentPage?.isMediaExpanded || false,
          media: currentPage?.media || '',
          primaryFontColor: currentPage?.fontColor || primaryFontColor,
          secondaryBackgroundColor: currentPage?.secondaryBackgroundColor || secondaryBackgroundColor,
          currentPageId: currentPage?.id,
          lastSurveyorRegistrationPage: currentPage?.id && currentSurveyStep === surveyorRegPagesIds?.length - 1,
          currentStep: currentSurveyStep,
          surveyorRegPagesIds,
          secondaryFontColor: currentPage?.fontColor,
          headerColor: currentPage?.headerColor || headerColor,
          titleDescPosition: currentPage?.titleDescPosition || titleDescPosition
        });
      } else {
        setPageItems({
          bgColor: primaryBackgroundColor,
          isMediaExpanded: true,
          media: currentPage?.media || '',
          primaryFontColor,
          secondaryBackgroundColor,
          currentPageId: '',
          lastSurveyorRegistrationPage: false,
          currentStep: 0,
          surveyorRegPagesIds: [],
          secondaryFontColor,
          headerColor,
          titleDescPosition

        });
      }
    } else {
      setPageItems({
        bgColor: primaryBackgroundColor,
        isMediaExpanded: false,
        media: '',
        primaryFontColor,
        secondaryBackgroundColor,
        currentPageId: '',
        lastSurveyorRegistrationPage: false,
        currentStep: 0,
        surveyorRegPagesIds: [],
        secondaryFontColor,
        headerColor,
        titleDescPosition
      });
    }
  }, [showForm, surveySubPage, currentRegistrationStep, event, currentSurveyStep]);

  const checkRequiredFields = useCallback(() => {
    if (showForm === 'surveyForm') {
      return requiredSurveyQuestionsFilled;
    }

    return true;
  }, [currentRegistrationStep, registered, currentSurveyStep, requiredRegistrationQuestionsFilled, showForm, requiredSurveyQuestionsFilled]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open && isModalOpen}
        sx={{
          '@media (max-width: 768px)': {
            margin: '15px',
            marginTop: '5px'
          },
          '& .MuiDialog-paper': {
            minWidth: '400px',
            borderRadius: '10px',
            background: 'none',
            '@media (max-width: 768px)': {
              minWidth: '100%',
            },
            '@media (min-width: 1700px)': {
              maxWidth: '70vw',
            },
          },

          '& .MuiPaper-root': {
            borderRadius: '10px !important',
            '& .MuiCard-root': {
              background: 'none',
              borderRadius: '10px !important'
            }
          }
        }}
      >
        <FormCard
          isSuveryorRegSkipable={isSuveryorRegSkipable}
          setPasswordProtectionCheck={setPasswordProtectionCheck}
          requiredFieldsFilled={checkRequiredFields()}
          submitPage={() => submitPage()}
          surveySubPage={surveySubPage}
          currentPage={showForm}
          step={getStep()}
          handleSkip={() => handleSkip()}
          onNextStep={() => { getNextStep(); }}
          onPreviousStep={() => { getPreviousStep(); }}
          user={user}
          logoColor="#FFFFFF"
          stepName={stepName}
          onLogout={() => {
            resetState();
            signoutClickHandler();
          }}
          stepDescription={stepDescription}
          setOpenForm={(value: any) => {
            resetState();
            setOpenForm(value);
          }}
          key={1}
          pageItems={pageItems}
          mediaURL={pageItems?.media}
          isMediaExpanded={pageItems?.isMediaExpanded}
          isLoading={false}
          primaryFontColor={pageItems?.primaryFontColor}
          secondaryBackgroundColor={pageItems?.secondaryBackgroundColor}
          bgColor={pageItems?.bgColor}
          lastSurveyorRegistrationPage={pageItems?.lastSurveyorRegistrationPage}
          stepHeader={stepHeader}
        >
          {(showForm !== 'surveyForm')
            ? (
              <Stack
                sx={{
                  direction: 'row',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                spacing={2}
                rowGap={2}
              >
                {showForm === 'login'
                  ? showLoginin()
                  : showForm === 'forgotPassowrd'
                    ? showForgotPassword()
                    : showForm === 'signupRegistrationForm'
                      ? showSignUpRegistrationForm()

                      : showLoader()}
              </Stack>
            ) : showForm === 'surveyForm' ? showSurveyForm()
              : showLoader()}
        </FormCard>
      </Dialog>
      {(showForm === 'signupRegistrationForm' && openUserAleardyExistPopup)
        && (
        <ConfirmDialog
          open={openUserAleardyExistPopup}
          onClose={() => setOpenUserAleardyExistPopup(false)}
          title="Account Already Exist!"
          content={<>An account with your email address already exists in our system. Would you like to log in instead?</>}
          action={(
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleUserAccountExist();
              }}
              sx={{
                color: '#ffffff', background: '#0031ff', '&:hover': { background: '#0031ff' }, fontFamily: 'Poppins-400', fontWeight: 600, fontSize: '14px', borderRadius: '8px'
              }}
            >
              Login
            </Button>
          )}
        />
        )}
    </>
  );
};

export default SurveyForm;
