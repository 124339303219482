import breakoutsessions from '../../../assets/images/virtual/multiple-sessions.png';
import breakoutsessions2 from '../../../assets/images/virtual/multiple-sessions@2x.png';

export const BreakoutSessions = () => (
  <div className="bg-gray-1 -z-10">
    <div className="2xl:w-10/12 block lg:flex flex-row w-full mx-auto">
      <div className="left">
        <div className="mt-20 lg:ml-32 pl-6 mb-8">
          <div className="flex justify-center pb-24 mb-10">
            <img
              className=""
              srcSet={`${breakoutsessions} 300w, ${breakoutsessions2} 300w`}
              sizes="(max-width: 583px) 30vw, (max-width: 583px) 30vw, 850px"
              alt=""
              src={breakoutsessions2}
            />
          </div>
        </div>
      </div>

      <div className="-z-10 ">
        <div className="block lg:flex flex-col">
          <div className="ml-5 pl-6 lg:mt-36 pt-1 mb-12">
            <p className="text-lg text-dark-blue font-extralight lg:w-3/6">
              Multiple breakout sessions and focused discussion rooms
            </p>
            <p className="text-md text-gray-1 font-extralight mt-4 mr-40 text-left w-3/5 ">
              Engage your attendees and sponsors by having focused interactive discussion rooms,
              enable your attendees to switch between discussion rooms with complete visibility of
              event program at any time
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default BreakoutSessions;
