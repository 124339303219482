import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setJoinMeeting } from "src/store/liveEvent";

export const parseNotficationString = (
  str: string,
  slugs: any,
  isComment: boolean,
  editComment?: boolean,
  dispatch?: Function
) => {
  // const dispatch = useDispatch();
  const cond: string[] = str?.match(/@{{(.*?)}}/g) ?? [];
  const condLinks: string[] = cond.map((c) => c.replace(/@{{|}}/g, ""));

  const compiled: any[] = condLinks.map((cL) => {
    const parseList = cL.split("||");
    const eventAdminRequest: boolean =
      str.includes(" have requested to join ") ||
      str.includes("new registrations to your event");

    if (parseList?.[0] === "User") {
      if (editComment) return parseList?.[2];
      return (
        <Link
          key={cL}
          className="text-[#0049EF] font-[600] cursor-pointer hover:underline"
          to={
            isComment
              ? `/wall/${parseList?.[1]}`
              : `/wall/${slugs?.[parseList?.[1]]}`
          }
        >
          {parseList?.[2]}
        </Link>
      );
    } else if (parseList?.[0] === "Company") {
      return (
        <Link
          className="text-[#0049EF] font-[600] cursor-pointer hover:underline"
          to={`/wall/company/${slugs?.[parseList?.[1]]}`}
        >
          {parseList?.[2]}
        </Link>
      );
    } else if (parseList?.[0] === "Event") {
      if (eventAdminRequest) {
        return (
          <a
            className="text-[#0049EF] font-[600] cursor-pointer hover:underline"
            href={`${process.env.REACT_APP_EVENT_PORTAL_URL}/events/${parseList?.[1]}/people`}
            target="_blank"
          >
            {parseList?.[2]}
          </a>
        );
      } else {
        return (
          <Link
            className="text-[#0049EF] font-[600] cursor-pointer hover:underline"
            to={`/event/${slugs?.[parseList?.[1]]}/discussion`}
          >
            {parseList?.[2]}
          </Link>
        );
      }
    } else if (parseList?.[0] === "Post") {
      return (
        <Link
          className="text-[#0049EF] font-[600] cursor-pointer hover:underline"
          to={`/home?postId=${parseList?.[1]}`}
        >
          {parseList?.[2]}
        </Link>
      );
    } else if (parseList?.[0] === "HostEvent") {
      if (eventAdminRequest) {
        return (
          <a
            className="text-[#0049EF] font-[600] cursor-pointer hover:underline"
            href={`${process.env.REACT_APP_EVENT_PORTAL_URL}/events/${parseList?.[1]}/people`}
            target="_blank"
          >
            {parseList?.[2]}
          </a>
        );
      } else {
        return (
          <Link
            className="text-[#0049EF] font-[600] cursor-pointer hover:underline"
            to={`/event/${slugs?.[parseList?.[1]]}/discussion`}
          >
            {parseList?.[2]}
          </Link>
        );
      }
    } else if (parseList?.[0] === "Session") {
      return (
        <a
          className="text-[#0049EF] font-[600] cursor-pointer hover:underline"
          href="#"
          target="_blank"
          // href={`${process.env.REACT_APP_EVENT_PORTAL_URL}/events/${parseList?.[1]}/people`}
        >
          {parseList?.[2]}
        </a>
      );
    } else if (parseList?.[0] === "EventSessionMeeting") {
      return (
        <a
          className="text-[#0049EF] font-[600] cursor-pointer hover:underline"
          target="_blank"
          onClick={() => {
            if (parseList?.[2]?.toLocaleLowerCase().includes("join")) {
              dispatch?.(
                setJoinMeeting({ meetingId: parseList?.[1], isOpen: true })
              );
            }
          }}
        >
          {parseList?.[2]}
        </a>
      );
    }
  });

  let processString = str;
  let finalArr: any[] = [];
  cond.forEach((cid, indx) => {
    const tillIndex = processString?.indexOf(`${cid}`);
    if (tillIndex !== 0) {
      const text = processString?.slice(0, tillIndex);
      finalArr.push(text);
      processString = processString?.replace(text, "");
    }
    finalArr.push(compiled[indx]);
    processString = processString?.replace(cid, "");
  });
  if (editComment) {
    // Check for plain URLs in the remaining string
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
    const urlMatches = processString?.match(urlRegex) ?? [];

    if (urlMatches.length) {
      urlMatches.forEach((url) => {
        const urlIndex = processString.indexOf(url);
        if (urlIndex !== 0) {
          const text = processString.slice(0, urlIndex);
          finalArr.push(text);
          processString = processString.slice(urlIndex + url.length);
        } else {
          processString = processString.slice(url.length);
        }
        finalArr.push(url);
      });
    } else {
      finalArr.push(processString);
    }
  } else {
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
    const urlMatches = processString?.match(urlRegex) ?? [];

    if (urlMatches.length) {
      urlMatches.forEach((url) => {
        const urlIndex = processString.indexOf(url);
        if (urlIndex !== 0) {
          const text = processString.slice(0, urlIndex);
          finalArr.push(text);
          processString = processString.replace(text, "");
        }
        finalArr.push(
          <>
            <br />
            <a
              href={
                url.startsWith("https") ||
                url.startsWith("http") ||
                url.startsWith("www")
                  ? url
                  : `www://${url}`
              }
              className="text-blue-1 font-medium"
              target="_blank"
            >
              {url}
            </a>
            <br />
          </>
        );
        processString = processString.replace(url, "");
      });
    }
  }

  // Check for hashtags in the remaining string
  const hashtagRegex = /#[^\s#]+/g;
  const hashtagMatches = processString?.match(hashtagRegex) ?? [];

  if (hashtagMatches.length) {
    hashtagMatches.forEach((hashtag) => {
      const hashtagIndex = processString.indexOf(hashtag);
      if (hashtagIndex !== 0) {
        const text = processString.slice(0, hashtagIndex);
        finalArr.push(text);
        processString = processString.replace(text, "");
      }
      finalArr.push(<span className="text-blue-1 font-medium">{hashtag}</span>);
      processString = processString.replace(hashtag, "");
    });
  }

  if (!!processString?.length) {
    finalArr.push(processString);
  }

  return finalArr;
};
