/* eslint-disable no-console */
import { ExperienceBannerSection, ExperienceBenefits, FactorDayExperience } from './components';
import { ConferencesSection } from './FactorConferences';
import { RegisterSectionProps } from './types';

export const FactorExperience = ({ onRegister, setSelectedTab, videos }: RegisterSectionProps) => (
  <div className="w-full pt-[0px]">
    <ExperienceBannerSection setSelectedTab={setSelectedTab} videos={videos} />

    <div className="w-full max-w-[1154px] xl:px-0 mx-auto pt-[35px] px-[15px]">
      <ExperienceBenefits />
      <FactorDayExperience setSelectedTab={setSelectedTab} />
      <ConferencesSection onRegister={onRegister} />
    </div>

  </div>
);
