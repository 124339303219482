export interface AvatarDefaultSvgIconProps {
    fillColor?: string;
}
export const AvatarDefaultSvgIcon = ({ fillColor }: AvatarDefaultSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#bdcce8"
    return <svg xmlns="http://www.w3.org/2000/svg" width="107.163" height="159.116" viewBox="0 0 107.163 159.116">
    <g id="Group_29054" data-name="Group 29054" transform="translate(-321.325 -180.106)">
      <path id="Path_14632" data-name="Path 14632" d="M59.793,0A53.6,53.6,0,1,0,59.8,0" transform="translate(315.134 180.106)" fill={iconColor}/>
      <path id="Path_14633" data-name="Path 14633" d="M20.537,51.672a4.6,4.6,0,0,0,3.742-2.007,42.543,42.543,0,0,1,69.285,0,4.632,4.632,0,0,0,3.793,2.012H107.4a4.668,4.668,0,0,0,4.632-4.72,4.575,4.575,0,0,0-.571-2.208,60.078,60.078,0,0,0-105.142.093,4.632,4.632,0,0,0,4.117,6.825Z" transform="translate(315.561 287.545)" fill={iconColor}/>
    </g>
  </svg>
  
};