import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { IEvent } from '../../../../models/user-events';
import { IAppState } from '../../../../store';
// import { init } from '../../../../store/event-website';
// import { useCurrentUser } from '../../../../utils/hooks';
import EventDetails from '../../registration/event-signup/event-details';
import WebsiteEventQuickLinks from '../website-event-quicklinks';

export const EventRegistrationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const user = useCurrentUser();
  // const dispatch = useDispatch();
  const [isAttendeeForm, setAttendeeForm] = useState(false);

  const eventSlug = location?.pathname?.split('/')[2];

  const event: IEvent = useSelector((state: IAppState) => state.website.event!);

  // useEffect(() => {
  //   dispatch(init(eventSlug));
  // }, [eventSlug, dispatch, user]);

  useEffect(() => {
    const eventStep = sessionStorage.getItem('eventRegStep');

    if (eventStep === '2') {
      navigate(`/event/${eventSlug}/spectre/select-plan`);
    } else if (eventStep === '3') {
      navigate(`/event/${eventSlug}/spectre/successfully-registered`);
    } else if (eventStep === '1') {
      navigate(`/event/${eventSlug}/spectre/registration`);
    } else {
      navigate(`/event/${eventSlug}/spectre`);
    }
  }, [eventSlug, navigate]);

  const changePage = (page: number) => {
    if (page === 3) {
      navigate(`/event/${eventSlug}/spectre/select-plan`);
    }
    if (page === 2) {
      navigate(`/event/${eventSlug}/spectre`);
    }
  };

  const onBackEvent = () => {
    navigate(`/event/${eventSlug}/spectre`);
  };

  const addAttendee = () => {
    setAttendeeForm(true);
  };

  return (
    <div className="w-full">
      <div className="flex -mb-[30px] min-h-[100vh]">
        <div className="bg-[#2d2d2d] pt-[20px] pr-[40px] pb-[30px] pl-[40px] w-full md:max-w-[60%] flex items-center">
          <div className="w-full">
            <div
              className="w-[122px] h-[32px] rounded-full bg-white text-black text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer mb-[20px]"
              onClick={onBackEvent}
            >
              Back to event
            </div>
            <div className="text-white text-[45px] leading-[45px] helvetica-neue-55-roman">Sit-down</div>
            <div className="text-white text-[21px] leading-[25px] helvetica-neue-55-roman">CIO • CDO • CISO</div>
            <div className="text-white text-[20px] leading-[20px] helvetica-neue-45-light mt-[20px] mb-[10px]">Register</div>
            <div className="-ml-[30px] -mr-[30px]">
              <EventDetails
                changePage={changePage}
                backButtonVisible={false}
                isCloseButton
                isSpectre
                isSpectreForm
                onClose={addAttendee}
                eventId={event?.id}
                isAttendeeForm={isAttendeeForm}
              />
            </div>
          </div>
        </div>
        <div className="reg-bg md:w-[40%]" />
      </div>
      <WebsiteEventQuickLinks />
    </div>
  );
};
export default EventRegistrationForm;
