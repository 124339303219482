import { useState } from "react";

interface IEventsSvgIcon {
  isActive?: boolean;
  className?: string;
  disableHover?: boolean;
}
export const EventsSvgIcon = ({ isActive, className, disableHover }: IEventsSvgIcon) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const colorBlue = "#0049EF";
  const defaultColor = "#7283A4";
  const fillColor = !disableHover && (isHover || isActive) ? colorBlue : defaultColor;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.647"
      height="30.577"
      viewBox="0 0 32.647 30.577"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={className}
    >
      <g
        id="Group_19818"
        data-name="Group 19818"
        transform="translate(-8478 -5376.5)"
      >
        <g
          id="Group_18805"
          data-name="Group 18805"
          transform="translate(8478 5377)"
        >
          <rect
            id="Rectangle_7907"
            data-name="Rectangle 7907"
            width="17.377"
            height="19.115"
            rx="1"
            transform="translate(0 1.738)"
            fill={fillColor}
          />
          <circle
            id="Ellipse_1160"
            data-name="Ellipse 1160"
            cx="11.295"
            cy="11.295"
            r="11.295"
            transform="translate(9.557 0)"
            fill={fillColor}
            stroke="#fff"
            strokeWidth="1"
          />
          <path
            id="Path_14806"
            data-name="Path 14806"
            d="M2.513,6.446a.784.784,0,0,0,.635-.34,7.228,7.228,0,0,1,11.772,0,.787.787,0,0,0,.639.342h1.717a.8.8,0,0,0,.692-1.177A10.207,10.207,0,0,0,.1,5.28a.791.791,0,0,0,.7,1.166Z"
            transform="translate(11.819 23.63)"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  );
};
