import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getMinifiedImage } from "src/mui/utils/image";
import { CustomAvatar } from "src/mui/components/hook-form/custom-avatar";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "src/store";
import { useCurrentUser } from "src/utils/hooks";
import { getEventLeads } from "src/store/liveEvent";
import AudienceList from "./audience-list";
import { applyFilter } from "src/mui/utils/filter-list";
import SearchNotFound from "src/mui/components/search-not-found";
import { PeopleType } from "./Types";
import { styled } from "@mui/system";
import { getBrandingColors } from "../event/live/liveEventStyling";
import { ArrowDownSvgIcon } from "src/shared-components/V2/IconSvg";

type Props = {
  role: string;
  sponsorId?: string;
  columnWidth?: number;
  searchQuery?: string;
  viewType?: boolean;
};
function LiveEventLeads({
  role,
  sponsorId,
  columnWidth,
  searchQuery,
  viewType,
}: Props) {
  const dispatch = useDispatch();

  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const user = useCurrentUser();

  const { eventSponsorsLeads, sponsorLeadsSummary, tabsCount } = liveEvent;

  const [sponsorsLeadsOptions, setSponsorsLeadsOptions] = useState([]);
  const [dropdownLeads, setDropdownLeads] = useState("");
  const [selectedSponsorId, setSelectedSponsorId] = useState("");

  const filteredLeads: any[] = applyFilter({
    list: eventSponsorsLeads ?? [],
    query: searchQuery,
    type: "leads",
  });
  const notFound = !filteredLeads?.length && !!searchQuery;

  const getLeadsList = () => (
    <Stack spacing={3}>
      {!viewType ? (
        <Stack sx={{px: "12px"}}>
          <AudienceList
            title="Leads"
            columnWidth={columnWidth}
            subheader={`You have ${filteredLeads?.length} leads`}
            list={filteredLeads}
            viewType={viewType}
            sx={{
              m: 0,
            }}
          />
        </Stack>
      ) : (
        <Grid rowGap="26px" container sx={{ px: "12px" }}>
          <AudienceList
            title="Leads"
            columnWidth={columnWidth}
            subheader={`You have ${filteredLeads?.length} leads`}
            list={filteredLeads}
            viewType={viewType}
            sx={{
              m: 0,
            }}
          />
        </Grid>
      )}
    </Stack>
  );

  const getAllSponsorsLeadsCount = () => {
    return sponsorLeadsSummary
      ? Object.values<number>(sponsorLeadsSummary).reduce(
          (acc: number, value: number) => acc + value,
          0
        )
      : 0;
  };

  const handleSelectedDropdown = (e: any, option: any) => {
    setSelectedSponsorId("");
    if (!option) return;
    if (option.isSponsor) {
      setSelectedSponsorId(option.id);
    }
    setDropdownLeads(
      option.isSponsor ? `selectedSponsor${option.id}` : option.id
    );
  };

  const colors = getBrandingColors();

  const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: `${colors?.Secondary!|| "#ffffff"} !important`,
    color: `${colors?.Text!|| "#000000"} !important`,
  }));

  useEffect(() => {
    if (liveEvent) {
      dispatch(
        getEventLeads(
          liveEvent?.event?.id!,
          role!,
          user?.id!,
          selectedSponsorId || sponsorId,
          dropdownLeads
        )
      );
    }
  }, [dropdownLeads]);

  useEffect(() => {
    if (sponsorLeadsSummary && liveEvent?.event) {
      const totalLeads: number = tabsCount[PeopleType.EventLeads];
      // const totalLeads: number = getAllSponsorsLeadsCount();

      const hardcodedOptions = [
        {
          name: "All Leads",
          id: "allLeads",
          logo: null,
          count: totalLeads,
        },
        {
          name: "My Own Leads",
          id: "myOwnLeads",
          logo: null,
          count: null,
        },
      ];

      const sponsorOptions = liveEvent?.event?.eventSponsors.map((sponsor) => ({
        name: sponsor?.company?.name || null,
        id: sponsor?.id || null,
        logo: sponsor?.company?.logo || null,
        count: sponsorLeadsSummary[sponsor.company?.name] || 0,
        isSponsor: true,
      }));

      const finalOptions: any = [...hardcodedOptions, ...sponsorOptions];
      setSponsorsLeadsOptions(finalOptions);
    }
  }, [sponsorLeadsSummary, liveEvent?.event]);

  return (
    <Stack direction="column" spacing="22px">
      {role === "admin" && (
        <FormControl fullWidth>
          <Autocomplete
            id="grouped-demo"
            PaperComponent={(props) => <StyledPaper {...props} />}
            popupIcon={<ArrowDownSvgIcon fillColor={colors?.Text!|| "#ffffff"} />}
            sx={{
              "& .MuiInputLabel-root": {
                padding: "0 4px",
                left: "-4px",
                top: "-10px",
                fontWeight: "300",
              },
              "& .MuiInputBase-input::placeholder": {
                color: `${colors?.Text! || 'white'} !important`, 
                opacity: 1, 
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: `${colors?.Text! || 'white'} !important`,
              },
              "& .MuiAutocomplete-endAdornment": {
                color: `${colors?.Text! || 'white'} !important`, 
                top: "calc(50% - 11.5px) !important",
              },
        
              "& .MuiInputLabel-shrink": {
                top: "0 !important",
              },
              "& .MuiOutlinedInput-input": {
                padding: "0px 14px",
                height: "25px",
                borderRadius: "5px !important",
              },
              "& .MuiAutocomplete-input": {
                pl: "15px !important",
                pr: "35px !important",
                py: "6.5px !important",
                fontSize: '13px',
                background: colors?.Secondary!|| "#2F2F2F",
                color: `${colors?.Text! || 'white'} !important`,
              },
              "& .MuiOutlinedInput-root": {
                p: "0px !important",
                pl: "0px !important",
              },
              "& fieldset": { display: "none" },
            }}
            onChange={(e, selectedSponsor) =>
              handleSelectedDropdown(e, selectedSponsor)
            }
            options={sponsorsLeadsOptions || []}
            getOptionLabel={(option: any) => option?.name}
            renderInput={(params) => (
              <TextField {...params} placeholder="All Leads" className="!placeholder:text-white" />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box
                    sx={{
                      border: "1px solid #E8E8E8",
                      p: "0px",
                      width: 32,
                      height: 32,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "6px",
                    }}
                  >
                    <CustomAvatar
                      src={getMinifiedImage(option?.logo!)}
                      alt={`${option?.name}`}
                      name={option?.name}
                      sx={{
                        width: 32,
                        height: 32,
                        borderRadius: "6px",
                        "& .MuiAvatar-img": {
                          objectFit: "contain !important",
                        },
                      }}
                    />
                  </Box>
                  <Stack>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "500", fontSize: '12px', color: colors?.Text!|| "#ffffff"  }}>{option?.name}</span>
                    </Typography>
                    <Typography style={{ fontWeight: "400", fontSize: '11px', color: colors?.Text!|| "#ffffff" }} variant="body1">
                      {option?.id !== "myOwnLeads" &&
                        `${option?.count || 0} Leads`}
                    </Typography>
                  </Stack>
                </Stack>
              </li>
            )}
          />
        </FormControl>
      )}

      <Stack direction="column" rowGap="22px">
        {notFound ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 10,
            }}
          >
            <SearchNotFound query={searchQuery} sx={{ mt: 3 }} />
          </Box>
        ) : (
          getLeadsList()
        )}
      </Stack>
    </Stack>
  );
}

export default LiveEventLeads;
