import { useEffect, useState } from 'react';
import visa from '../../../assets/images/visa-logo.svg';
import AlertModal from '../../../components/alert-modal/alert-modal';
import { ICardDetails } from '../../../models/user/account-settings/account-settings';
import { UserService } from '../../../services';
import { useCurrentUser } from '../../../utils/hooks';
import AddCardDetails from './add-card-details';

export const PeymantDetails = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [cards, setCards] = useState<ICardDetails[]>([]);
  const [toggleModal, setToggleModal] = useState(false);
  const user = useCurrentUser();
  useEffect(() => {
    (async () => {
      const userService = new UserService();
      const result = await userService.getCards();
      setCards(result.data);
    })();
  }, []);

  const addCardHandler = () => {
    setToggleModal(!toggleModal);
  };

  return (
    <div className="max-w-[886.5px] ml-[40px] mt-[37px]" id="subscription">
      <div className="flex flex-col w-full">
        <span className="font-semibold text-[15px] leading-[21px] mb-5 text-[#0B1221]">Subscription & billing</span>
        {cards.map(card => (
          <div className="border-t border-[#707070] w-full pt-[20px]">
            <span className="text-[12px] font-medium leading-4 mb-2.5 text-[#3C3C3C] block">
              Payment details & billing methods
            </span>
            <div className="flex items-center text-base font-normal max-w-[779px]">
              <span className="flex-1 flex flex-row items-center whitespace-nowrap text-base font-light text-[#3C3C3C]">
                {card.brand === 'Visa' && <img className="h-4 pr-1" src={visa} alt="visa" />}
                <span>{`•••• •••• •••• ${card.last4}`}</span>
              </span>
              <span className="inline-flex justify-center hover:underline text-[15px] text-[#172746] font-semibold cursor-pointer" onClick={addCardHandler}>
                Manage payment methods
              </span>
            </div>
          </div>
        ))}
      </div>
      <AlertModal
        className="md:w-[700px] py-0"
        closeIconClass=" right-[21px] top-[21px] px-0"
        onClose={() => setToggleModal(!toggleModal)}
        isOpen={toggleModal}
        isCloseIcon
      >
        <AddCardDetails onClose={() => setToggleModal(!toggleModal)} currentUser={user} />
      </AlertModal>
    </div>
  );
};

export default PeymantDetails;
