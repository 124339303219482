import { Reducer } from 'redux';
import { IUploadImage } from '../../../models/fileupload/upload-image';
import { UploadPictureActions } from './actions';
import { PictureActionTypes } from './types';

const initialState: IUploadImage = {
  avatarUrl: '',
  coverUrl: ''
};

export const SetPhotoReducer: Reducer<
  IUploadImage,
  UploadPictureActions
> = (state = initialState, action) => {
  switch (action.type) {
    case PictureActionTypes.SET_AVATAR_PICTURE: {
      return {
        ...state,
        avatarUrl: action.payload.url
      };
    }

    case PictureActionTypes.SET_COVER_PICTURE: {
      return {
        ...state,
        coverUrl: action.payload.url
      };
    }

    case PictureActionTypes.PICTURE_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
