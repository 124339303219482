import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader } from 'src/shared-components/Loader';
import { IPostSpotLight } from 'src/models/post/IPostSpotlight';
// import { Loader } from '../../shared-components/Loader';
import { NoSpotlightEvents } from './no-spotlight-events';
import SpotlightCard from './spotlight-card';

interface ISpotlightProps {
    spotlightLists: IPostSpotLight[];
    fetchSpotlight: Function;
    fetchItems: any;
    hasMoreItems: any;
    gridSize?: 1 | 2 | 3 | 4;
    isOwnSpotlight?: boolean;
    getSpotlight: Function;
    onCreateSpotlight?: Function;
    isDisableCreate?: boolean;
}

export const Spotlight = ({
  spotlightLists, fetchSpotlight, fetchItems, hasMoreItems, gridSize = 3, isOwnSpotlight, getSpotlight, onCreateSpotlight, isDisableCreate
}: ISpotlightProps) => (
  spotlightLists?.length === 0 ? (
    <NoSpotlightEvents isButton={!isDisableCreate} onClickModal={onCreateSpotlight} createButtonClass="min-w-[286px] mt-[25px]" />
  ) : (
    <InfiniteScroll
      pageStart={0}
      loadMore={fetchItems}
      hasMore={hasMoreItems}
      loader={<Loader className="pt-5" key="loader" />}
    >
      <div className={`grid grid-cols-${gridSize} gap-[6px] mx-[12px] my-[14px] max-h-[432px] overflow-y-auto rounded-10`}>
        {spotlightLists.map((item: IPostSpotLight, index: number) => (
          <div
            className={classNames('xl:w-[145px] xl:h-[140px]', {
              'row-span-2 col-start-3 col-end-4 row-start-1 row-end-3 !rounded-t-[10px] col-span-1 xl:w-[145px] xl:h-[286px]': (index % (gridSize === 3 ? 6 : 5) === 0 && index > 1),
            })}
            key={`spotlight-${item.id}-${index}`}
          >
            <SpotlightCard
              indexSpotlight={index}
              id={item.id}
              content={item.content}
              createdAt={item.createdAt}
              format={item.format}
              source={item?.source}
              onClick={fetchSpotlight}
              gridSize={gridSize}
              post={item}
              isOwnSpotlight={isOwnSpotlight}
              getSpotlight={getSpotlight}
            />
          </div>
        ))}
      </div>
    </InfiniteScroll>
  )
);
