import classNames from 'classnames';
import { IEventSponsor } from '../../../models/event/event-sponsor';
import { Logo } from '../../../shared-components/V2/Logo';

interface IWebsiteEventPartnerList {
  sponsors: IEventSponsor[];
  roomQty?: number;
  isTitleLobby?: boolean;
  isOpen?: boolean;
}

const WebsiteEventPartnerList = ({
  sponsors, roomQty, isTitleLobby, isOpen
}: IWebsiteEventPartnerList) => (
  <div
    className={
      classNames(
        'pl-[230px]',
        {
          '!p-0': roomQty === 2,
          'pl-[100px]': isTitleLobby,
          '!pl-0 max-w-[215px] min-w-[215px]': isOpen
        }
      )
    }
  >
    <div className="">
      {
        !!sponsors && sponsors?.length > 0
        && <div className="text-[16px] text-[#141f26a6] font-medium h-[28px] mb-[22px] bold-lato-font">Partners</div>
      }
      {sponsors
        ? sponsors?.map((item: any) => (
          <div className="flex mb-2.5 last:mb-0">
            <div className="w-full">
              <div className="flex gap-[10px]">

                <Logo src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.company?.logo}`} className="w-[49px] h-[49px] object-contain bg-[#111] p-[2px] small-radius" />

                <div className="flex flex-col justify-start">
                  <div className="text-[13px] leading-[16px] text-[#151f26] font-semibold pt-[3px] bold-lato-font">
                    {item.company.name}
                  </div>
                  <div className="text-[11px] leading-[13px] text-[#151f26] font-normal normal-lato-font">
                    {item.sponsorship}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
        : null}
    </div>
  </div>
);

export default WebsiteEventPartnerList;
