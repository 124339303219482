import { Reducer } from 'redux';

import { UserProfileState } from '../../../models/UserProfileState';
import { UserProfileActions } from './actions';
import { UserProfileActionTypes } from './types';

const initialState: UserProfileState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const UserProfileReducer: Reducer<UserProfileState, UserProfileActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UserProfileActionTypes.USER_PROFILE_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case UserProfileActionTypes.USER_PROFILE_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case UserProfileActionTypes.USER_PROFILE_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    case UserProfileActionTypes.USER_PROFILE_CLEANUP: {
      return initialState;
    }

    default:
      return state;
  }
};
