import { Navbar, PageHeader, Footer } from '../../components';
import LoginOrJoinForm from './loginorjoin-form';

export const LoginOrJoin = () => (
  <>
    <Navbar />
    <div className="bg-[#001BF6] flex text-center h-[calc(100vh-112px)]">
      <div className="mx-auto">
        <div className="md:ml-px">
          <div className="flex w-full flex-wrap mt-28 ">
            <div className="flex flex-col text-left">
              <PageHeader isBlueTheme />
              <div className="mt-[4rem] text-xl font-medium text-white text-center ">
                Log in or Join now
              </div>
              <div className="flex justify-start mt-4">
                <LoginOrJoinForm isBlueTheme from="site" changePage={null} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer className="bg-black py-3" />
  </>
);

export default LoginOrJoin;
