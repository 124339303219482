import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ConnectSuggestionsResult } from '../../../models/ConnectSuggestionsResult';
import { ConnectSuggestionsState } from '../../../models/ConnectSuggestionsState';
import { UserService } from '../../../services';
import { ConnectSuggestionsActionTypes } from './types';

export interface IConnectSuggestionsLoadingAction {
  type: ConnectSuggestionsActionTypes.CONNECT_SUGGESTIONS_LOADING;
  loading: boolean;
}

export interface IConnectSuggestionsSuccessAction {
  type: ConnectSuggestionsActionTypes.CONNECT_SUGGESTIONS_SUCCESS;
  payload: ConnectSuggestionsResult;
}
export interface IConnectSuggestionsErrorAction {
  type: ConnectSuggestionsActionTypes.CONNECT_SUGGESTIONS_ERROR;
  errorMessage: string;
}

export type ConnectSuggestionsActions =
  | IConnectSuggestionsLoadingAction
  | IConnectSuggestionsSuccessAction
  | IConnectSuggestionsErrorAction;

export const getConnectSuggestions: ActionCreator<
  ThunkAction<Promise<any>, ConnectSuggestionsState, null, IConnectSuggestionsSuccessAction>
> = (page: number, limit: number) => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();

    try {
      const result = await userService.getConnectSuggestions(page, limit);

      // Generating full path so the logic is not in different parts of the UI.
      result?.data?.map((person) => {
        person.avatar = `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${person.avatar}`;
        person.cover = `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${person.cover}`;
      });

      dispatch({
        type: ConnectSuggestionsActionTypes.CONNECT_SUGGESTIONS_SUCCESS,
        payload: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: ConnectSuggestionsActionTypes.CONNECT_SUGGESTIONS_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: ConnectSuggestionsActionTypes.CONNECT_SUGGESTIONS_LOADING,
        loading: false
      });
    }
  };
};

export const setConnectSuggestion: ActionCreator<
  ThunkAction<Promise<any>, ConnectSuggestionsState, null, IConnectSuggestionsLoadingAction>
> = (connectionUserId: string) => {
  return async () => {
    const userService: UserService = new UserService();
    try {
      await userService.setConnectSuggestion(connectionUserId);
    } catch (error: any) { 
      console.error(error);
    }
  }
}


export const loadConnectSuggestions: ActionCreator<
  ThunkAction<any, ConnectSuggestionsState, null, IConnectSuggestionsLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: ConnectSuggestionsActionTypes.CONNECT_SUGGESTIONS_LOADING,
    loading: shouldLoad
  });
