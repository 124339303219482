import * as React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { getMinifiedImage } from "src/mui/utils/image";
import { useState } from "react";
import { Autocomplete, Card, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CompanyService } from "src/services";
import { CustomAvatar } from "./custom-avatar";

type OptionType = {
  id: string;
  name: string;
};

type PJobTitleProps = {
  name: string;
  label?: any;
  disabled?: boolean;
  defaultValue?: OptionType;
  placeholder?: any;
  sx?: any;
};


const fieldHelperText = {
  "& .MuiFormHelperText-root": { ml: "1px" }
}

const PJobTitleSelectLiveSearch = ({
  name,
  label,
  disabled = false,
  defaultValue,
  placeholder,
  sx
}: PJobTitleProps) => {
  const { control } = useFormContext();
  const [searchJobTitle, setSearchJobTitle] = useState("");
  const [jobTitleList, setJobTitleList] = useState<any[]>([]);
  const [openMenu, setOpenMenu] = useState(false);

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>, field: any
  ) => {
    const { value } = event.target;
    setSearchJobTitle(value);
    field.onChange(event.target.value && event.target.value.trim() !== "" ? { id: event.target.value, name: event.target.value } : null);
    if (value.trim() !== "") {
      try {
        const companyService: CompanyService = new CompanyService();
        const response: any = await companyService.getJobTitlesWithQuery(value);

        setJobTitleList([...response?.data]);
      } catch (error) {
        console.error(error);
      }
    } else {
      setJobTitleList([]);
    }
  };

  const handleBlur = (field: any) => {
    if (!jobTitleList?.length! || searchJobTitle === "") {
      setOpenMenu(false)
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Autocomplete
            {...field}
            // renderOption={(props, option: any) => (
            //   <li {...props} style={{ paddingLeft: '0px !important' }}>
            //     {companyDropdownOptions(option, { minWidth: '380px' }, true)}
            //   </li>
            // )}
            options={jobTitleList || []}
            open={openMenu && searchJobTitle !== "" && jobTitleList.length ? true : false}
            onOpen={() => setOpenMenu(true)}
            onClose={() => setOpenMenu(false)}
            onBlur={() => handleBlur(field)}
            getOptionLabel={(optLocation) => optLocation?.name || ""}
            disabled={disabled}
            filterOptions={(options) => options}
            onChange={(_, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                variant="outlined"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, field)}
                value={searchJobTitle}
                error={!!error}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null,
                }}
                sx={{
                  ...sx,
                  ...fieldHelperText,
                  "& .MuiAutocomplete-input": {
                    pl: "0px !important"
                  }
                }}
                helperText={error?.message ||

                  ""}
              />
            )}
            clearOnBlur
            clearOnEscape
          />
          <Card sx={sx ? sx : { width: "100%", height: 400 }} />
        </>
      )}
    />
  );
};

export default PJobTitleSelectLiveSearch;
