import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ICompanyFollowResult, ICompanyUnfollowResult } from '../../../models/company/company-follow';

import { ICompanyProfile } from '../../../models/company/company-profile';
import { ICompanyProfileState } from '../../../models/company/CompanyProfileState';
import { CompanyService } from '../../../services';
import { CompanyProfilePreviewActionTypes } from './types';

export interface ICompanyProfilePreviewLoadingAction {
  type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_LOADING;
  loading: boolean;
}

export interface ICompanyProfilePreviewSuccessAction {
  type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_SUCCESS;
  payload: ICompanyProfile;
}

export interface ICompanyProfilePreviewErrorAction {
  type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_ERROR;
  errorMessage: string;
}

export interface IFollowCompanyAction {
  type: CompanyProfilePreviewActionTypes.FOLLOW_COMPANY;
  result: ICompanyFollowResult;
}

export interface IUnfollowCompanyAction {
  type: CompanyProfilePreviewActionTypes.UNFOLLOW_COMPANY;
  result: ICompanyUnfollowResult;
}

export interface ICompanyProfilePreviewResetAction {
  type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_RESET;
}

export type CompanyProfilePreviewActions =
  | ICompanyProfilePreviewLoadingAction
  | ICompanyProfilePreviewSuccessAction
  | ICompanyProfilePreviewErrorAction
  | ICompanyProfilePreviewResetAction
  | IFollowCompanyAction
  | IUnfollowCompanyAction;

export const getCompanyProfilePreview: ActionCreator<
  ThunkAction<Promise<any>, ICompanyProfileState, null, ICompanyProfilePreviewSuccessAction>
> = (companyIdOrSlug: string) => {
  return async (dispatch: Dispatch) => {
    const companyService: CompanyService = new CompanyService();

    try {
      const result = await companyService.getCompanyProfile(companyIdOrSlug);

      dispatch({
        type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_SUCCESS,
        payload: result.data
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_LOADING,
        loading: false
      });
    }
  };
};

export const loadCompanyProfilePreview: ActionCreator<
  ThunkAction<any, ICompanyProfileState, null, ICompanyProfilePreviewLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_LOADING,
    loading: shouldLoad
  });

export const followCompany: ActionCreator<ThunkAction<Promise<any>, ICompanyProfileState, null, IFollowCompanyAction>> = (companyId: string, onSuccess?: any) => async (dispatch: Dispatch) => {
  const companyService: CompanyService = new CompanyService();

  try {
    const result = await companyService.followCompany(companyId);

    dispatch({
      type: CompanyProfilePreviewActionTypes.FOLLOW_COMPANY,
      result: result
    })
    if(onSuccess) onSuccess('Unfollow')
  } catch (error: any) {
    dispatch({
      type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_ERROR,
      errorMessage: error.message
    });
  } finally {
    dispatch({
      type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_LOADING,
      loading: false
    });
  }
}

export const unFollowCompany: ActionCreator<ThunkAction<Promise<any>, ICompanyProfileState, null, IUnfollowCompanyAction>> = (companyId: string, onSuccess?: any) => async (dispatch: Dispatch) => {
  const companyService: CompanyService = new CompanyService();

  try {
    const result = await companyService.unfollowCompany(companyId);

    dispatch({
      type: CompanyProfilePreviewActionTypes.UNFOLLOW_COMPANY,
      result: result
    })
    if(onSuccess) onSuccess('Follow')
  } catch (error: any) {
    dispatch({
      type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_ERROR,
      errorMessage: error.message
    });
  } finally {
    dispatch({
      type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_LOADING,
      loading: false
    });
  }
}

export const resetCompanyProfilePreview: ActionCreator<ThunkAction<Promise<any>, ICompanyProfileState, null, ICompanyProfilePreviewResetAction>> = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_RESET
  });
}