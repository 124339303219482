export interface ClockSvgIconProps {
    fillColor?: string;
}
export const ConnectionsSvgIcon = ({ fillColor }: ClockSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#203c6e"
    return <svg id="Group_12710" data-name="Group 12710" xmlns="http://www.w3.org/2000/svg" width="10" height="11.708" viewBox="0 0 10 11.708">
    <g id="Group_12705" data-name="Group 12705" transform="translate(0 6.606)">
      <g id="Group_11965" data-name="Group 11965">
        <g id="Group_11964" data-name="Group 11964">
          <path id="Path_10331" data-name="Path 10331" d="M30.918,197.658h.288a.586.586,0,0,0,.569-.511,3.529,3.529,0,0,1,6.982,0,.586.586,0,0,0,.569.511h.288a.571.571,0,0,0,.576-.633,4.965,4.965,0,0,0-9.847,0A.574.574,0,0,0,30.918,197.658Z" transform="translate(-30.272 -192.628)" fill={iconColor}/>
          <path id="Path_10332" data-name="Path 10332" d="M38.773,196.8h-.288a.65.65,0,0,1-.641-.568,3.455,3.455,0,0,0-6.838,0,.65.65,0,0,1-.641.568h-.288a.641.641,0,0,1-.489-.223.618.618,0,0,1-.151-.489,5.037,5.037,0,0,1,9.991,0,.636.636,0,0,1-.151.489A.723.723,0,0,1,38.773,196.8Zm-4.348-3.67a3.613,3.613,0,0,1,3.556,3.072.511.511,0,0,0,.5.446h.288a.529.529,0,0,0,.389-.173.468.468,0,0,0,.115-.381,4.891,4.891,0,0,0-9.7,0,.474.474,0,0,0,.122.381.5.5,0,0,0,.389.173h.288a.511.511,0,0,0,.5-.446A3.6,3.6,0,0,1,34.426,193.132Z" transform="translate(-29.433 -191.7)" fill={iconColor}/>
        </g>
      </g>
      <g id="Group_11966" data-name="Group 11966" transform="translate(2.183 2.137)">
        <path id="Path_10333" data-name="Path 10333" d="M60.77,224.7a2.812,2.812,0,0,1,5.442,0,.573.573,0,0,1-.561.705H61.332A.576.576,0,0,1,60.77,224.7Z" transform="translate(-60.681 -222.514)" fill={iconColor}/>
        <path id="Path_10334" data-name="Path 10334" d="M64.719,224.357H60.407a.644.644,0,0,1-.518-.259.636.636,0,0,1-.115-.54h0a2.878,2.878,0,0,1,5.571-.007.613.613,0,0,1-.115.54A.6.6,0,0,1,64.719,224.357Zm-4.808-.756a.486.486,0,0,0,.086.417.508.508,0,0,0,.41.2h4.312a.5.5,0,0,0,.41-.2.486.486,0,0,0,.086-.417,2.741,2.741,0,0,0-5.3,0Z" transform="translate(-59.757 -221.4)" fill={iconColor}/>
      </g>
    </g>
    <g id="Group_12706" data-name="Group 12706" transform="translate(1.848)">
      <ellipse id="Ellipse_638" data-name="Ellipse 638" cx="3.073" cy="3.073" rx="3.073" ry="3.073" transform="translate(0.072 0.072)" fill={iconColor}/>
      <path id="Path_10335" data-name="Path 10335" d="M58.245,106.189a3.144,3.144,0,1,1,3.145-3.144A3.148,3.148,0,0,1,58.245,106.189Zm0-6.152a3.008,3.008,0,1,0,3.009,3.008A3.011,3.011,0,0,0,58.245,100.037Z" transform="translate(-55.1 -99.9)" fill={iconColor}/>
    </g>
  </svg>  
};