import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IAddExperience, IExperience } from '../../../models/experience';
import { ExperienceItem } from './experience-item';
import { ReactComponent as PlusIcon } from '../../../assets/images/feather-plus.svg';
import AlertModal from '../../../components/alert-modal/alert-modal';
import EditExperience from './edit-experience';
import { getUserProfile } from '../../../store/user/user-profile/actions';
import { getOwnExperience } from '../../../store/user/own-experience/actions';
import { getOwnConnections } from '../../../store/user/own-connections/actions';
import { UserService } from '../../../services';

export const UserExperience = (props: {
  title: string;
  items: IExperience[];
  isOwnProfile: boolean;
  userId?: string;
  isAbleToAdd?: boolean;
  experience?: boolean;
  handleExperienceStatus?: Function;
}) => {
  const dispatch = useDispatch();
  const [openEditModal, setOpenEditModal] = useState(props.experience);
  const [showMore, setShowMore] = useState(false);
  const addExperienceClick = () => {
    setOpenEditModal(true);
  };

  useEffect(() => {
    setShowMore(false);
  }, [props.items]);

  return (
    <div className="bg-white create-post-card">
      <div className="flex flex-col">
        <div
          className="flex flex-row items-center justify-between w-full rounded-t-10 px-[20px] pt-[13px]"
        >
          <div className=" text-[15px] font-medium leading-[18px] text-[#000000]">{props.title}</div>
          <div onClick={addExperienceClick} className="cursor-pointer">
            {(props.isAbleToAdd && props.isOwnProfile) ? <PlusIcon /> : ''}
          </div>
        </div>
        <div className="mt-4 px-[18px]">
          {props.items?.slice(0, showMore ? props.items.length : 3)?.map((item: IExperience, index) => (
            <div key={`experience-${index}`} className="mb-4 last:mb-0">
              <ExperienceItem
                company={item.company}
                jobTitle={item.jobTitle}
                id={item.id}
                companyId={item.companyId}
                employmentType={item.employmentType}
                location={item.location}
                startDate={item.startDate}
                endDate={item.endDate}
                current={item.current}
                isOwnProfile={props.isOwnProfile}
                userId={props.userId}
              />
              {props.items?.length > 1 && index < props.items?.length - 1 && (
                <div className="border-b-1 border-[#CDDCF2] w-full" />
              )}
            </div>
          ))}
          {
            props.items?.length > 3 && !showMore
            && (
            <div
              className="flex justify-center text-[15px] text-[#285CB2] leading-[23px] cursor-pointer font-semibold tracking-wide py-[10px] hover:underline -mt-[17px]"
              onClick={() => setShowMore(true)}
            >
              See all
            </div>
            )
          }
        </div>
        {props.isOwnProfile && (
          <div className="mr-4" onClick={() => setOpenEditModal(true)}>
            {openEditModal && (
              <AlertModal
                isOpen={openEditModal}
                onClose={() => {
                  setOpenEditModal(false);
                  if (props.handleExperienceStatus) props.handleExperienceStatus(openEditModal);
                }}
                className="md:w-[482px] py-0"
                closeIconClass=" right-[18px] top-[15px] px-0"
                isCloseIcon
              >
                <EditExperience
                  onSave={async (data: IAddExperience) => {
                    const userSerivce = new UserService();
                    await userSerivce.addExperience(data);
                    if (props.isOwnProfile) {
                      dispatch(getUserProfile());
                      dispatch(getOwnExperience());
                      dispatch(getOwnConnections());
                    } else {
                      dispatch(getUserProfile(props.userId));
                      dispatch(getOwnExperience(props.userId));
                      dispatch(getOwnConnections(props.userId));
                    }
                  }}
                  onClose={() => {
                    setOpenEditModal(false);
                    if (props.handleExperienceStatus) props.handleExperienceStatus(openEditModal);
                  }}
                  title="Add your job experience"
                />
              </AlertModal>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserExperience;
