import growyourcommmunity from '../../../assets/images/overview/desktop.png';
import growyourcommmunity2 from '../../../assets/images/overview/desktop@2x.png';

export const GrowYourCommunity = () => (
  <div className="w-full bg-white xl:-mt-8 mb-28">
    <div className="flex container mx-auto mt-20 pt-5 mb-16 w-10/12">
      <div className="block md:flex justify-between ml-12 mr-12 md:ml-7 md:mr-8">
        <div>
          <img
            className="-ml-2"
            srcSet={`${growyourcommmunity} 300w, ${growyourcommmunity2} 1000w`}
            sizes="(max-width: 583px) 20vw, (max-width: 583px) 30vw, 1105px"
            alt=""
            src={growyourcommmunity2}
          />
        </div>
        <div className="block md:flex text-left lg:ml-0 pl-1 mt-52 mb-20 pt-4">
          <div className="flex flex-col md:ml-5">
            <p className="lg:text-xl3 font-extralight text-xl text-dark-blue">
              Grow your professional
              <br />
              {' '}
              community
            </p>

            <p className="text-lg font-extralight text-dark-blue mt-5 mr-40 text-left w-11/12">
              Reach millions of existing members, grow your professional community and bring them
              together.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default GrowYourCommunity;
