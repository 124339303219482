import AttendeesTabIcon from "./Icons/AttendeesTabIcon";
import SpeakersTabIcon from "./Icons/SpeakersTabIcon";
import SponsorsTabIcon from "./Icons/SponsorsTabIcon";
import MeetingsTabIcon from "./Icons/MeetingsTabIcon";
import LeadsTabIcon from "./Icons/LeadsTabIcon";
import { AudienceTabs, PeopleType } from "./Types";
import { getBrandingColors } from "../event/live/liveEventStyling";

export const Audience_OPTIONS = [
  {
    value: AudienceTabs.Attendees,
    name: PeopleType.Registrants,
    icon: (active: boolean) => (
      <AttendeesTabIcon
        isActive={active}
        fillBgColor={active ? getBrandingColors().ButtonTextColor : getBrandingColors().Accent}
        fillColor={active ? getBrandingColors().ButtonTextColor : getBrandingColors().Accent}
      />
    ),
  },
  {
    value: AudienceTabs.Speakers,
    name: PeopleType.Speakers,
    icon: (active: boolean) => (
      <SpeakersTabIcon
        isActive={active}
        fillBgColor={active ? getBrandingColors().ButtonTextColor : getBrandingColors().Accent}
        fillColor={active ? getBrandingColors().ButtonTextColor : getBrandingColors().Accent}
      />
    ),
  },
  {
    label: AudienceTabs.Sponsors,
    value: AudienceTabs.Sponsors,
    name: PeopleType.Sponsors,

    icon: (active: boolean) => (
      <SponsorsTabIcon
        fillBgColor={active ? getBrandingColors().ButtonTextColor : getBrandingColors().Accent}
        fillColor={active ? getBrandingColors().ButtonTextColor : getBrandingColors().Accent}
      />
    ),
  },
  {
    value: AudienceTabs.Meetings,
    name: PeopleType.ScheduledMeeting,
    icon: (active: boolean) => (
      <MeetingsTabIcon
        isActive={active}
        fillBgColor={active ? getBrandingColors().ButtonTextColor : getBrandingColors().Accent}
        fillColor={active ? getBrandingColors().ButtonTextColor : getBrandingColors().Accent}
      />
    ),
  },
  {
    value: AudienceTabs.Leads,
    name: PeopleType.EventLeads,
    icon: (active: boolean) => (
      <LeadsTabIcon
        isActive={active}
        fillBgColor={active ? getBrandingColors().ButtonTextColor : getBrandingColors().Accent}
        fillColor={active ? getBrandingColors().ButtonTextColor : getBrandingColors().Accent}
        starBorderColor={getBrandingColors().Secondary || "#2f2f2f"}
      />
    ),
  },
];
