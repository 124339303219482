import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormProvider from 'src/mui/components/hook-form/form-provider';
import PTextField from 'src/mui/components/hook-form/PTextField';
import { getFavBrandings, markBrandingAsFav } from 'src/store/liveEvent';
import { IAppState } from 'src/store';

type Props = {
  openAddFavPopup: boolean;
  setOpenAddFavPopup: (value: boolean) => void;
};

type FormValues = {
  name: string;
};

const AddFavourite: React.FC<Props> = ({ openAddFavPopup, setOpenAddFavPopup }) => {
  const dispatch = useDispatch();
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const { liveEventBranding, favBrandingsLoading } = liveEvent;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const NewEventSchema = Yup.object().shape({
    name: Yup.string().required('Required Field'),
  });

  const defaultValues: FormValues = useMemo(() => ({ name: '' }), []);

  const methods = useForm<FormValues>({
    resolver: yupResolver(NewEventSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = methods;

  const values = watch();

  const onSuccess = () => {
    dispatch(getFavBrandings(liveEvent?.event?.id!));
    setOpenAddFavPopup(false);
  };

  const handleAddToFav = () => {
    const body = { liveEventBrandingId: liveEventBranding?.id, name: values?.name };
    dispatch(markBrandingAsFav(liveEvent?.event?.id!, body, onSuccess));
  };

  const onSubmit = async (data: FormValues) => {
    console.log('Submitted data:', data);
  };

  return (
    <FormProvider methods={methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openAddFavPopup}
          sx={{ '& .MuiDialog-paper': { p: 4 } }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins-500',
              fontSize: '20px',
              lineHeight: '21px',
              mb: '22px',
              ml: '3px',
            }}
          >
            Add to Favorite Branding
          </Typography>
          <Stack spacing={2}>
            <PTextField name="name" />
          </Stack>

          <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2, justifyContent: 'flex-end' }}>
            <Button
              color="inherit"
              onClick={() => {
                setOpenAddFavPopup(false);
                reset();
              }}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>

            <LoadingButton
              type="submit"
              loading={favBrandingsLoading || isSubmitting}
              disabled={!values?.name}
              onClick={handleAddToFav}
              variant="contained"
            >
              Load & Save
            </LoadingButton>
          </Box>
        </Dialog>
      </form>
    </FormProvider>
  );
};

export default AddFavourite;
