const CookieIntro = () => (
  <div className="bg-gray-0 w-full">
    <div className="block mb-8 w-9/12 mx-auto">
      <div className="block lg:flex flex-col  mx-auto mt-7 text-dark-blue text-md">
        <div className="text-blue-1">Last Updated: 9 April 2021</div>
        <div className="mt-5">
          This Platform and corresponding website or applications (referred to in this Cookie Policy
          collectively as the “Platform”) is owned and operated by Panelist Pty Ltd. Panelist Pty
          Ltd will be referred to in this Cookie Policy as “we”, “us”, “our” and similar grammatical
          forms.
        </div>

        <div className="mt-5 leading-9 w-11/12">
          We use the following technologies:
          <ol>
            <li>● Cookies;</li>
            <li>● Pixels;</li>
            <li>● Local Storage;</li>
            <li>● Other similar technologies.</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
);

export default CookieIntro;
