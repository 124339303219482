import { Reducer } from 'redux';

import { IJobFunctionState } from '../../../models/company/JobFunctionsState';
import { JobFunctionActions } from './actions';
import { JobFunctionActionTypes } from './types';

const initialState: IJobFunctionState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const JobFunctionsReducer: Reducer<IJobFunctionState, JobFunctionActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case JobFunctionActionTypes.JOB_FUNCTIONS_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case JobFunctionActionTypes.JOB_FUNCTIONS_SUCCESS: {
      return {
        ...state,
        value: action.data
      };
    }

    case JobFunctionActionTypes.JOB_FUNCTIONS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
