// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import {
  Checkbox,
  FormLabel,
  FormGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";
import { ParseContent } from "src/utils/parse-html";

interface PCheckboxProps extends Omit<FormControlLabelProps, "control"> {
  name: string;
  helperText?: React.ReactNode;
}

export function PCheckbox({ name, helperText, ...other }: PCheckboxProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          {/* <FormControlLabel
            control={<Checkbox {...field} checked={!!field.value} />}
            {...other}
          /> */}
            <FormControlLabel
            {...other}
            control={<Checkbox {...field} checked={!!field.value} />}
            label={
              <ParseContent
                content={typeof other?.label === 'string' ? other?.label : String(other?.label)}
              />
            }
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

// ----------------------------------------------------------------------

interface PMultiCheckboxProps
  extends Omit<FormControlLabelProps, "control" | "label"> {
  name: string;
  options: { label: string; value: any }[];
  row?: boolean;
  label?: string;
  spacing?: number;
  helperText?: React.ReactNode;
  isTermAndConditionRequired?: boolean;
  eachFieldValidation?: any;
  headerColor?: string;
}

export function PMultiCheckbox({
  eachFieldValidation,
  isTermAndConditionRequired,
  row,
  name,
  label,
  options,
  spacing,
  helperText,
  headerColor,
  ...other
}: PMultiCheckboxProps) {
  const { control } = useFormContext();
  const getSelected = (selectedItems: string[], item: string) => {
    if (!selectedItems || !Array.isArray(selectedItems)) {
      return [item];
    }
    if (selectedItems.includes(item)) {
      return selectedItems.filter((val) => val !== item);
    }
    return [...selectedItems, item];
  };

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    url: string
  ) => {
    event.stopPropagation();
    window.open(url, "_blank");
  };

  const extractUrls = (text: string): string[] | null => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const matches = text.match(urlPattern);
    return matches || null;
  };

  const splitTextByUrls = (text: string, urls: string[]): (string | null)[] => {
    if (!urls) return [text];
    let splitText = text.split(/(https?:\/\/[^\s]+)/g);
    return splitText.filter((part) => part);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl
            sx={{
              "&.MuiFormControl-root": {
                width: "100% !important",
              },
            }}
            component="fieldset"
          >
            {label && (
              <FormLabel component="legend" sx={{ typography: "body2" }}>
                {label}
              </FormLabel>
            )}

            <FormGroup
              sx={{
                m: "10px 0px",
                "&.MuiFormControl-root": {
                  width: "100% !important",
                },
                ...(row && {
                  flexDirection: "row",
                }),
                "& .MuiFormControlLabel-root": {
                  width: "100%",
                  borderRadius: "5px",
                  marginLeft: "0px",
                  paddingRight: "20px",
                  alignItems:"flex-start",
                  "&:not(:last-of-type)": {
                    mb: spacing || "6px",
                  },
                  ...(row && {
                    mr: 0,
                    "&:not(:last-of-type)": {
                      mr: spacing || 2,
                    },
                  }),
                },
              }}
            >
              {options.map((option) => {
                const urls = extractUrls(option?.label);

                const displayParts = splitTextByUrls(option?.label, urls!);
                return (
                  <FormControlLabel
                    key={option.value}
                    sx={{
                      "& .css-b7w8of-MuiFormControlLabel-root": {
                        borderColor: "yellow",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={
                          typeof field?.value === "boolean"
                            ? field?.value
                            : field?.value?.includes(option.value) || false
                        }
                        onChange={() => {
                          const selectedValues = getSelected(
                            field.value,
                            option.value
                          );
                          field.onChange(selectedValues);
                        }}
                      />
                    }
                    // label={
                    //   <span>
                    //     {displayParts.map((part, index) => {
                    //       if (urls?.includes(part!)) {
                    //         return (
                    //           <a
                    //             key={index}
                    //             href="#"
                    //             onClick={(event) =>
                    //               handleLinkClick(event, part!)
                    //             }
                    //             style={{
                    //               textDecoration: "underline",
                    //               color: headerColor || "blue",
                    //               marginLeft: "5px",
                    //             }}
                    //           >
                    //             {part}
                    //           </a>
                    //         );
                    //       }
                    //       return <span key={index}>{part}</span>;
                    //     })}
                    //   </span>
                    // }
                    label={
                      <ParseContent
                        content={option?.label}
                        // className="truncate"
                      />
                    }
                    {...other}
                  />
                );
              })}
            </FormGroup>

            {(!!error || helperText) && (
              <FormHelperText error={!!error} sx={{ mx: 0 }}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}
