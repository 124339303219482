import { Box, useTheme, Stack, Divider } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { NavListProps, NavSectionMini } from "src/mui/components/nav-section";
import { ChatSvgIcon } from "./SvgIcons/ChatSvgIcon";
import { ExpoSvgIcon } from "./SvgIcons/ExpoSvgIcon";
import { LoungeSvgIcon } from "./SvgIcons/LoungeSvgIcon";
import { MatchSvgIcon } from "./SvgIcons/MatchSvgIcon";
import { NetworkSvgIcon } from "./SvgIcons/NetworkSvgIcon";
import { SessionSvgIcon } from "./SvgIcons/SessionSvgIcon";
import { AudienceSvgIcon } from "./SvgIcons/AudienceSvgIcon";
import { useCallback, useEffect } from "react";
import { ScheduleSvgIcon } from "./SvgIcons/ScheduleSvgIcon";
import { PollSvgIcon } from "./SvgIcons/PollSvgIcon";
import { MeetingsSvgIcon } from "./SvgIcons/MeetingsSvgIcon";
import { BrandingSvgIcon } from "./SvgIcons/BrandingSvgIcon";
import { ExitSvgIcon } from "./SvgIcons/ExitSvgIcon";
import UserMenu from "./user-menu";
import { getActivePage } from "../CommonFunctions";
import { useNavigate } from "react-router";
import { SponsorsSvgIcon } from "./SvgIcons/SponsorSvgIcon";
import { IAppState } from "src/store";
import { useSelector } from "react-redux";
import { SessionHandoutSvgIcon } from "./SvgIcons/SessionHandoutSvgIcon";
import { getBrandingColors } from '../event/live/liveEventStyling';
import { ScheduleSvgIconActive } from "./SvgIcons/ScheduleSvgIconActive";
import { AudienceSvgIconActive } from "./SvgIcons/AudienceSvgIconActive";
import { MatchSvgIconActive } from "./SvgIcons/MatchSvgIconActive";
import { ChatSvgIconActive } from "./SvgIcons/ChatSvgIconActive";
import { BrandingSvgIconActive } from "./SvgIcons/BrandingSvgIconActive";
import { SponsorSvgIconActive } from "./SvgIcons/SponsorSvgIconActive";
import { SessionHandoutSvgIconActive } from "./SvgIcons/SessionHandoutSvgIconActive";
import { ExitSvgIconActive } from "./SvgIcons/ExitSvgIconActive";
import { InsightsSvgIconActive } from "./SvgIcons/InsightsSvgIconActive";
import { PostSvgIconActive } from "./SvgIcons/PostSvgIconActive";

// ----------------------------------------------------------------------

const defaultConfig = {
  currentRole: "admin",
  itemRootHeight: 44,
  itemSubHeight: 36,
  itemPadding: "4px 0px 4px 0px",
  itemRadius: 0,
  hiddenLabel: false,
};

type SideBarProps = {
  activeList: string[];
  setActiveList: (data: any) => void;
  openDrawer: () => void;
  onSessionClick: (value: any) => void;
  onOpenSurvey: () => void;
  onOpenBranding?: () => void;
  event?: any;
  openBranding?: boolean;
  isHeaderShow?: boolean;
  panelPplacement?: string;
  isFocusedSessions?: boolean;
};

const defaultTabs = ["sessions"];

export const SidebarMenu = ({
  activeList,
  setActiveList,
  openDrawer,
  onSessionClick,
  onOpenSurvey,
  onOpenBranding,
  openBranding,
  event,
  isHeaderShow,
  panelPplacement,
  isFocusedSessions
}: SideBarProps) => {
  const checkActiveItems = (data: string) => activeList.includes(data);
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);

  const inPersonEvent = liveEvent?.event?.format === "hybrid" || liveEvent?.event?.format === "live";

  const { eventPolls, eventHandouts} = liveEvent;
  const theme = useTheme();
  const navigate = useNavigate();
  const generalComponentGetter = useCallback(
    (item: any) => {
      if (isFocusedSessions) {
        setActiveList(["sessions", item]);
      } else {
        const allActivePages = getActivePage(item, activeList, inPersonEvent);
        setActiveList(allActivePages);
      }
    },
    [activeList, setActiveList,isFocusedSessions]
  );

  const activeListHandler = (data: NavListProps) => {
    switch (data?.id) {
      case "sessions":
        break;
      case "survey":
        onOpenSurvey();
        break;
      case "admin":
        onOpenBranding && onOpenBranding();
        break;
      case "latestCheckedIn":
        console.log("latestCheckedIn")
        break;
      default:
        generalComponentGetter(data?.id);
        break;
    }
  };

  const NAV_ITEMS = [
    {
      subheader: "Menu",
      items: [
        // {
        //   id: "sessions",
        //   title: "Live Now",
        //   path: "#",
        //   icon: <SessionSvgIcon isActive={true} />,
        //   roles: ["admin"],
        // },
        {
          id: "agenda",
          title: "Schedule",
          path: "#",
          icon: <ScheduleSvgIconActive isActive={checkActiveItems("agenda")} />,
          roles: ["admin"],
          disabled: activeList.includes("sponsorDetails") || inPersonEvent,
        },
        {
          id: "audience",
          title: "Audience",
          path: "#",
          icon: <AudienceSvgIconActive isActive={checkActiveItems("audience")} />,
          roles: ["admin"],
          disabled: activeList.includes("sponsorDetails") || inPersonEvent,
        },
        // {
        //     id: 'lounge',
        //     title: 'Lunge',
        //     path: '#',
        //     icon: <LoungeSvgIcon isActive={checkActiveItems('lounge')} />,
        //     roles: ['admin'],
        // },
        {
          id: "match",
          title: "Match",
          path: "#",
          icon: <MatchSvgIconActive isActive={checkActiveItems("match")} />,
          roles: ["admin"],
          disabled: activeList.includes("sponsorDetails") || inPersonEvent,
        },
        // {
        //     id: 'expo',
        //     title: 'Expo',
        //     path: '#',
        //     icon: <ExpoSvgIcon isActive={checkActiveItems('expo')} />,
        //     roles: ['admin'],
        //     disabled: true,
        // },
        // {
        //     id: 'polls',
        //     title: 'Polls',
        //     path: '#',
        //     icon: <PollSvgIcon isActive={checkActiveItems('polls')} />,
        //     roles: ['admin'],
        //     disabled: activeList.includes("sponsorDetails")
        // },
        {
          id: "chat",
          title: "Chat",
          path: "#",
          icon: <ChatSvgIconActive isActive={checkActiveItems("chat")} />,
          roles: ["admin"],
          disabled: activeList.includes("sponsorDetails") || inPersonEvent,
        },
        {
          id: "sponsors",
          title: "Sponsors",
          path: "#",
          icon: <SponsorSvgIconActive isActive={checkActiveItems("sponsors")} />,
          roles: ["admin"],
          disabled: activeList.includes("sponsorDetails") || inPersonEvent,
        },
        // {
        //     id: 'meetings',
        //     title: 'Meetings',
        //     path: '#',
        //     icon: <MeetingsSvgIcon isActive={checkActiveItems('meetings')} />,
        //     roles: ['admin'],
        //     disabled: true
        // },
        // {
        //     id: 'survey',
        //     title: 'Survey',
        //     path: '#',
        //     icon: <ExpoSvgIcon isActive={checkActiveItems('survey')} />,
        //     roles: ['admin'],
        // },

      ],
    },
  ];

  const pollsItem = {
    id: "polls",
    title: "Polls",
    path: "#",
    icon: <PollSvgIcon isActive={checkActiveItems("polls")} />,
    roles: ["admin"],
    disabled: activeList.includes("sponsorDetails") || inPersonEvent,
  };

  const handoutItem = {
    id: "handouts",
    title: "Handouts",
    path: "#",
    icon: <SessionHandoutSvgIconActive isActive={checkActiveItems("handouts")} />,
    roles: ["admin"],
    disabled: activeList.includes("sponsorDetails") || inPersonEvent,
  };


  const NAV_ITEMS_IN_PERSON_EVENT = [
    {
      subheader: "Menu",
      items: [
        {
          id: "latestCheckedIn",
          title: "Audience",
          path: "#",
          icon: <AudienceSvgIconActive isActive={checkActiveItems("latestCheckedIn")} />,
          roles: ["admin"],
          disabled: activeList.includes("sponsorDetails") || !inPersonEvent,
        },
        {
          id: "posts",
          title: "Posts",
          path: "#",
          icon: <PostSvgIconActive isActive={checkActiveItems("posts")} />,
          roles: ["admin"],
          disabled: activeList.includes("sponsorDetails") || !inPersonEvent,
        },
        {
          id: "agenda",
          title: "Schedule",
          path: "#",
          icon: <ScheduleSvgIconActive isActive={checkActiveItems("agenda")} />,
          roles: ["admin"],
          disabled: activeList.includes("sponsorDetails") || !inPersonEvent,
        },
        {
          id: "audienceInsights",
          title: "Insights",
          path: "#",
          icon: <InsightsSvgIconActive isActive={checkActiveItems("audienceInsights")} />,
          roles: ["admin"],
          disabled: activeList.includes("sponsorDetails") || !inPersonEvent,
        },
        {
          id: "chat",
          title: "Chat",
          path: "#",
          icon: <ChatSvgIconActive isActive={checkActiveItems("chat")} />,
          roles: ["admin"],
          disabled: activeList.includes("sponsorDetails") || !inPersonEvent,
        },
      ],
    },
  ];

  const brandingItem =    {
    id: 'admin',
    title: 'Branding',
    path: '#',
    icon: <BrandingSvgIconActive isActive={openBranding} />,
    roles: ['admin'],
    disabled: activeList.includes("sponsorDetails")
};

  const onExitLiveEvent = () => {
    navigate(`/event/${event && event.slug}/discussion`);
  };

  if (eventPolls?.length) {
    NAV_ITEMS[0].items.splice(4, 0, pollsItem);
  }

  if (eventHandouts?.length) {
    NAV_ITEMS[0].items.splice(5, 0, handoutItem);
  }

  if(event?.isAdmin)
    NAV_ITEMS[0].items.push(brandingItem);
    NAV_ITEMS_IN_PERSON_EVENT[0].items.push(brandingItem);

   useEffect(()=>{
    if(inPersonEvent){
      setActiveList(["latestCheckedIn","posts","agenda","audienceInsights"])
    }
    else {
      setActiveList(["sessions"])
    }
    // setActiveList(["sessions"])
   },[liveEvent?.event?.format])

  return (
    <Box
      sx={{
        right: panelPplacement === "left" ? "auto" : 0,
        left: panelPplacement === "left" ? 0 : "auto",
        top: isHeaderShow ? "54px" : "0px",
        bottom: 0,
        height: isHeaderShow ? "calc(100vh - 54px)" : "calc(100vh - 0px)",
        width: "67px",
        zIndex: 9999,
        display: "flex",
        position: "fixed",
        alignItems: "self-start",
        // justifyContent: "space-between",
        bgcolor: getBrandingColors()?.Main || "#000000",
        flexDirection: "column",
        transition: "top 0.3s ease-in-out, height 0.4s ease-in-out",
        // bgcolor: theme.palette.primary.lighter
      }}
    >
      <Stack
        sx={{
          width: 1,
          alignItems: "center",
          mt: "15px",
          mb: "14px",
        }}
      >
        <UserMenu panelPplacement={panelPplacement} />
      </Stack>
      <Divider
        sx={{
          borderStyle: "solid",
          borderColor: getBrandingColors()?.MainBackgroundColor || getBrandingColors()?.Text,
          opacity: "60%",
          width: "40px",
          margin: "0 auto",
        }}
      />
      <NavSectionMini
        data={inPersonEvent ? NAV_ITEMS_IN_PERSON_EVENT   :NAV_ITEMS}
        config={defaultConfig}
        setIsActive={activeListHandler}
        onSessionClick={onSessionClick}
        activeItems={activeList}
        sx={{
          pt: "9px",
          width: "67px",
          rowGap: "8px",
          mr: 0,
          overflowY: "scroll",
          height: "calc(100vh - 132px)",
          "&:hover": {
            opacity: 1,
            color: "#0049EF",
          },
          "& .MuiListItemIcon-root": {
            display: "flex",
            justifyContent: "center",
          },
          // bgcolor: "#000000"
          // boxShadow: (theme: any) => theme.customShadows.z20,
        }}
      />
      <Stack
        sx={{
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          pb: "14px",
          cursor: "pointer",
        }}
        onClick={onExitLiveEvent}
      >
        <Stack>
          <ExitSvgIconActive />
        </Stack>
        <Stack>
          <ListItemText
            sx={{
              width: 1,
              flex: "unset",
            }}
            primary="Exit"
            primaryTypographyProps={{
              noWrap: true,
              fontSize: 9,
              fontFamily: "Poppins-500",
              lineHeight: "21px",
              color: theme.palette.mode === "dark" ? getBrandingColors()?.Accent || "#ffffff" : getBrandingColors()?.Accent || "#ffffff",
              textAlign: "center",
              textTransform: "capitalize",
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
