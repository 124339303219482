import { SVGProps } from 'react';

function NextIcon(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9.82" height="19.637" viewBox="0 0 9.82 19.637" style={style}>
            <path id="Path_4551" data-name="Path 4551" d="M.981,19.637A.982.982,0,0,1,.247,18L7.187,10.2a.565.565,0,0,0,0-.754L.247,1.634A.982.982,0,1,1,1.716.33L9.571,9.167a.978.978,0,0,1,0,1.3L1.716,19.307A.978.978,0,0,1,.981,19.637Z" transform="translate(0 0)" />
        </svg>

    );
}

export default NextIcon;