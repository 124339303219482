import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InteractiveModeratorSpeakerApproveUser, InteractiveModeratorSpeakerRemoveQueueUser } from '../../../../../store/interactive-event/moderator-speaker';
import { QueueParticipants } from '../../../../../store/interactive-event/types';
import { AttendeeAvatar } from '../AttendeeAvatar';

interface IQueueParticipants {
  participants: QueueParticipants
  getDefaultOptions?: Function
}
export const QueueParticipantsView = ({ participants, getDefaultOptions }: IQueueParticipants) => {
  const dispatch = useDispatch();
  const { slug, roomId } = useParams();
  return (
    <>
      {
        participants.invited.map((participant: any, index: number) => (
          <AttendeeAvatar
            item={participant}
            key={index}
            onApprove={() => { dispatch(InteractiveModeratorSpeakerApproveUser(slug!, roomId!, participant.id)); }}
            onReject={() => { dispatch(InteractiveModeratorSpeakerRemoveQueueUser(slug!, roomId!, participant.id)); }}
            options={[...getDefaultOptions!(participant)]}
          />
        ))
      }
      {
        participants.handRaised.map((participant: any, index: number) => (
          <AttendeeAvatar
            item={participant}
            key={index}
            onApprove={() => { dispatch(InteractiveModeratorSpeakerApproveUser(slug!, roomId!, participant.id)); }}
            onReject={() => { dispatch(InteractiveModeratorSpeakerRemoveQueueUser(slug!, roomId!, participant.id)); }}
            options={[...getDefaultOptions!(participant)]}
            hasRaisedHand
          />
        ))
      }
      {
        participants.speakers.map((participant: any, index: number) => (
          <AttendeeAvatar
            item={participant}
            key={index}
            onApprove={() => { dispatch(InteractiveModeratorSpeakerApproveUser(slug!, roomId!, participant.id)); }}
            onReject={() => { dispatch(InteractiveModeratorSpeakerRemoveQueueUser(slug!, roomId!, participant.id)); }}
            options={[...getDefaultOptions!(participant)]}
          />
        ))
      }
      {
        participants.virtualSpeakers.map((participant: any, index: number) => (
          <AttendeeAvatar
            item={participant}
            key={index}
            onApprove={() => { dispatch(InteractiveModeratorSpeakerApproveUser(slug!, roomId!, participant.id)); }}
            onReject={() => { dispatch(InteractiveModeratorSpeakerRemoveQueueUser(slug!, roomId!, participant.id)); }}
            options={[...getDefaultOptions!(participant)]}
          />
        ))
      }
      {
        participants.guests.map((participant: any, index: number) => (
          <AttendeeAvatar
            item={participant}
            key={index}
            onApprove={() => { dispatch(InteractiveModeratorSpeakerApproveUser(slug!, roomId!, participant.id)); }}
            onReject={() => { dispatch(InteractiveModeratorSpeakerRemoveQueueUser(slug!, roomId!, participant.id)); }}
            options={[...getDefaultOptions!(participant)]}
          />
        ))
      }

    </>
  );
};
