import { Dispatch } from '@reduxjs/toolkit';
import { interactiveEventSlice } from '..';
import { IAppState } from '../..';
import { _expoWebSocket } from "../../../utils/hooks";

// WebSocket Template
const sendWsMessage = (message: any) => {
  const webSocket = _expoWebSocket;
  return webSocket && webSocket.then((ws) => ws.send(JSON.stringify(message))).catch(console.error);
}

export const InteractiveModeratorCallJoin = (eventId: string, eventRoomId: string) => async (dispatch: Dispatch) => {
  sendWsMessage({
    action: 'interactive/moderator-join',
    data: { eventId, eventRoomId }
  });
  dispatch(interactiveEventSlice.actions.moderatorJoin({eventId, eventRoomId}));
}

export const InteractiveModeratorStartBroadCast = (eventId: string, eventRoomId: string) => async (dispatch: Dispatch) => {
   sendWsMessage({
      action: 'interactive/start-broadcast',
      data: { eventId, eventRoomId }
  });
}

