/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { PageTitle } from 'src/screens/event/factor-website/components/PageTitle';
import { FactorPartners } from '../../components/factor-partners';
import { VideoPlayer } from '../../components';

interface PartnersPageProps {
  videos: any[]
}

export const Partnerspage: React.FC<PartnersPageProps> = ({ videos }) => (
    <div>
      <div className="flex flex-col xl:flex-row items-center gap-x-[35px]">
        <div className="flex flex-col gap-y-4 xl:w-2/3 w-full">
          <PageTitle
            isSponsorTitle
            title="Event Partners & Sponsorship"
            description="Sponsorship and event partners at Factor Day enjoy top-tier visibility and engagement with key decision makers. This partnership ensures maximum value, fostering lasting industry connections and brand reputation."
          />
        </div>
        {videos && videos.length > 0 && (
        <div className="flex justify-center items-center w-full xl:w-1/3 xl:ml-auto xl:h-[255px] h-[345px] px-[15px] md:px-0 mt-5 md:mt-0 xl:mt-[20px]">
          <VideoPlayer singleVideoClass="partnerVidoe" videos={videos} playIconPosition="top-[50%] left-[50%] -translate-x-[30px] -translate-y-[30px]" />
        </div>
        )}
      </div>
      <FactorPartners />
    </div>
);
