import { IEvent } from '../../../models/event/event';
import { Box, Stack } from '@mui/material';
import { getBrandingColors } from './live/liveEventStyling';
import { CardHeader } from './live/card-header';
import MatchCarousel from '../match/match-carousel';
import { IUserProfile } from '../../../models/user-profile';

export function LiveMatchComponent(props: {
  gridColumn: string,
  gridRow: string,
  moduleDesignLines: boolean,
  height: string,
  boxStyles: { backgroundColor: string; pb: string; overflow: string; borderRadius: string; px: string; py: string; position: string },
  onClick: () => void,
  user: IUserProfile,
  event: IEvent | undefined,
  activeList: string[],
  columnCardHeight: string
}) {
  return <Box sx={{
    gridColumn: props.gridColumn,
    gridRow: props.gridRow,
  }}>
    <Stack
      sx={{
        p: '0px !important',
        height: props.height, ...props.boxStyles
      }}
    >
      <Stack sx={{
        px: '10px',
        pt: '12px',
      }}>
        <CardHeader
          title="Match"
          onClick={props.onClick}
        />
      </Stack>
      <MatchCarousel
        user={props.user}
        event={props.event}
        activeList={props.activeList}
        columnCardHeight={props.columnCardHeight}
      />
    </Stack>
  </Box>;
}
