const CopyrightContent = () => (
  <div className="bg-gray-0 w-full">
    <div className="block w-9/12 mx-auto">
      <hr className="my-10" />
      <div className="mt-3 text-gray-1 table-of-contents text-lg5">Counter-Notice:</div>

      <div className="mt-5">
        If you believe that a notice of copyright infringement has been improperly submitted
        against you, you may submit a Counter-Notice, pursuant to Sections 512(g)(2) and (3) of
        the Digital Millennium Copyright Act.
        <div className="mt-3">
          You may email us at
          {' '}
          <a
            className="text-blue-1 underline hover:no-underline"
            href="mailto:user-compliance@panelist.com"
          >
            user-compliance@panelist.com
          </a>
          {' '}
          or otherwise provide a written communication which contains:
        </div>
      </div>

      <div className="mt-5 leading-7 w-11/12 ml-10">
        <ul>
          <li>1. Your physical or electronic signature;</li>
          <li>2. Identification of the material removed or to which access has been disabled;</li>
          <li>
            3. A statement under penalty of perjury that you have a good faith belief that removal
            or disablement of the material was a mistake or that the material was misidentified;
          </li>
          <li>
            4. Your full name, your email address, your mailing address, and a statement that you
            consent to the jurisdiction of the Federal District court (i) in the judicial district
            where your address is located if the address is in the United States, or (ii) located
            in the Northern District of California (Santa Clara County), if your address is
            located outside the United States, and that you will accept service of process from
            the Complainant submitting the notice or his/her authorized agent.
          </li>
        </ul>
      </div>

      <div className="mt-5">
        Please submit your Counter-Notice to Panelist’s Copyright Agent via
        <a
          className="text-blue-1 underline hover:no-underline ml-2"
          href="mailto:user-compliance@panelist.com"
        >
          user-compliance@panelist.com
        </a>
        {' '}
        or mail to the address specified above.
      </div>
      <hr className="my-10" />
      <div className="mt-3 text-gray-1 table-of-contents text-lg5">
        Claims regarding content other than copyright infringement
      </div>
      <div className="my-5">
        For issues other than copyright infringement please visit our Help Center where you’ll
        find information on how to flag and report other types of content violations. Learn more:
      </div>
      <hr className="my-10" />
    </div>
  </div>
);

export default CopyrightContent;
