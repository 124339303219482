import { differenceInHours } from 'date-fns';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { dateAndTimeWithTz } from 'src/utils/date-timezone';
import { RegistrationProgressType } from './enum';

function useEventIsPassed() {
  const event = useSelector((state: IAppState) => state.website.event!);

  const { upcomingEvent, signupUser } = useSelector(
    (state: IAppState) => state
  );
  const { audienceStatus , surveyStatus } = useSelector(
    (state: IAppState) =>
      state.upcomingEvent?.value?.data?.attendeeStatus! ?? ""
  );

  const isRegistered =
  upcomingEvent?.value?.data?.attendeeStatus?.isRegistered;

const eventType = event?.type
const location = event?.locationName
const isLevnovoWebsiteTemplate = event?.websiteTemplate === "lenovo"
  const canAttendeesRequestMeeting = event?.canAttendeesRequestMeeting;
  
  const registrationUnderReview =
    event?.type === "invitation" && audienceStatus !== "accepted";
    const totalPages = [...(event?.eventQuestionPages || []), { id: "attendeesList", name: "attendees" }] || []

  // const totalPages =

  if (!isRegistered && signupUser?.eventRegistration !== null) {
    totalPages.shift();
}

const eventDateTimeWithTz = useMemo(() => {
    const startTime = dateAndTimeWithTz(event?.startTime, event?.timezone);
    const endTime = dateAndTimeWithTz(event?.endTime, event?.timezone);
    return {
        startTime,
        endTime
    };
}, [event?.startTime, event?.endTime, event?.timezone]);

const eventStartDate = eventDateTimeWithTz?.startTime?.dateLongFormat;

const userExitedSurvey = surveyStatus === RegistrationProgressType.SURVEY_FORM_EXITED;

const isPastEvent = useMemo(() => {
    var result = differenceInHours(
        new Date(event?.endTime),
        new Date()
    )

    if (result >= -24) return false
    return true;

}, [event])

  return {
    isPastEvent: isPastEvent || false,
    canAttendeesRequestMeeting,
    eventType,
    location,
    eventStartDate,
    isLevnovoWebsiteTemplate,
    totalPages,
    registrationUnderReview,
    userExitedSurvey
  };
}

export default useEventIsPassed;