import React from 'react';

interface IConnectionRequestsBadge {
  countConnectionRequests: number;
}
const ConnectionRequestsBadge = ({ countConnectionRequests }: IConnectionRequestsBadge) => {
  return (
    <>
      {countConnectionRequests && (
        <div className="absolute top-0 ml-7 z-10 w-[20px] h-[20px] rounded-full bg-[#FF0000] text-white  flex justify-center">
          <div className="text-s-10 leading-[20px] font-medium">{countConnectionRequests}</div>
        </div>
      )}
    </>
  );
};

export default ConnectionRequestsBadge;
