export interface IArrowDownSortSvgIcon {
    className?: string;
    fillColor?: string;
}
export const ArrowDownSortSvgIcon = ({ className, fillColor }: IArrowDownSortSvgIcon) => {
  const iconColor = fillColor ? fillColor : "#203c6e"
    return <svg xmlns="http://www.w3.org/2000/svg" width="11.037" height="6.962" viewBox="0 0 11.037 6.962" style={{ height: '7px' }}>
    <g id="Group_20985" data-name="Group 20985" transform="translate(-103.428 -4.945)">
      <line id="Line_1462" data-name="Line 1462" x1="4.104" y2="4.133" transform="translate(108.946 6.359)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2"/>
      <line id="Line_1463" data-name="Line 1463" x2="4.104" y2="4.133" transform="translate(104.842 6.359)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2"/>
    </g>
  </svg>  
};