import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import Connections from '../../../user/user-profile/connections';
import CompanyProfileCard from '../../company-profile/company-profile-card';

export const CompanyInfoAndConnections = () => {
  const { companyProfilePreview: companyProfile, companyEmployees } = useSelector((state: IAppState) => state);
  return (
    <>
      <div className="bg-white w-full create-post-card">
        <CompanyProfileCard
          logoUrl={companyProfile.value?.logo!}
          companyName={companyProfile.value?.name!}
          industryName={companyProfile.value?.industry?.name!}
          headquarter={companyProfile.value?.headquarter!}
          about={companyProfile.value?.description!}
          specialities={companyProfile.value?.companySpecialities!}
          size={companyProfile.value?.companySize?.name!}
          employeesOnPanelist={companyEmployees.value?.data.length!}
          website={companyProfile.value?.website!}
        />
      </div>
      <div className="w-full mt-4">
        <Connections connections={companyEmployees.value?.data ?? []} title="Employee" companyId={companyProfile.value?.id} totalEmployees={companyEmployees.value?.totalRow ?? 0} />
      </div>
    </>
  );
};
