import { Box, Stack } from '@mui/material';
import { getBrandingColors } from './liveEventStyling';
import { CardHeader } from './card-header';
import { Agenda } from '../../agenda/agenda';
import { IUpcomingEventState } from '../../../../models/event/UpcomingEventState';
import { IEvent } from "src/models/event/event";

export function LiveAgendaComponent(props: {
  gridColumn: string,
  gridRow: string,
  moduleDesignLines: boolean,
  height: string,
  boxStyles: { backgroundColor: string; pb: string; overflow: string; borderRadius: string; px: string; py: string; position: string },
  onClick: () => void,
  upcomingEvent: IUpcomingEventState,
  drawer: ReturnType<any>,
  event: IEvent | undefined
}) {
  return <Box sx={{
    gridColumn: props.gridColumn,
    gridRow: props.gridRow,
  }}>
    <Stack sx={{ height: props.height, ...props.boxStyles  }}>
      <CardHeader
        title="Schedule"
        onClick={props.onClick}
      />
      <Agenda
        eventList={props.upcomingEvent?.value?.data.event}
        onDrawerFalse={props.drawer.onFalse}
        eventId={props.event?.id}
      />
    </Stack>
  </Box>;
}
