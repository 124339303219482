import { Container, Stack } from '@mui/material';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { OverviewPage } from './overview';
import { IEvent } from 'src/models/user-events';
import { WhyAttend } from './why-attend';
import { SpeakerPage } from './speakers';
import { Agenda } from './agenda';

type LenovoPagesProps = {
  event?: IEvent;
}

const agendaTabStyles = {
  position: 'relative',
  "& > .MuiTabScrollButton-root": {
    position: 'absolute',
    right: '-32px',
    top: '0px',
    zIndex: 100,
    borderLeft: '1px solid #7283A4',
    height: '29px',
    width: '31px'
  },
  "& .MuiSvgIcon-root": {
    color: '#7283A4',
    width: '28px',
    height: '28px'
  },
  "& .MuiTabs-flexContainer": {
    columnGap: '0.5rem',
    width: '100%',
    justifyContent: 'center',
  },
  "&.MuiTabs-root": {
    minHeight: '40px',
    maxHeight: '40px',
    px: '1.25rem',
    flexDirection: "row",
    display: "flex",
    flex: 1,
  },
  "& .MuiTab-root": {
    padding: '0px',
    width: 'calc(25% - 66px)',
    minHeight: '40px',
    maxHeight: '40px',
    backgroundColor: 'white',
    border: '2px solid #4d144a',
    borderRadius: '0px',
    color: '#4d144a',
    px: '12px',
    fontFamily: 'Poppins-600',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    '@media (max-width: 768px)': {
      fontSize: '0.7rem',
      lineHeight: '14px',
      minWidth: 'calc(25% - 6px)',
      maxWidth: 'calc(25% - 6px)',
    },
  },
  "& .Mui-selected": {
    backgroundColor: '#4d144a',
    borderRadius: '0px',
    fontSize: '1.2rem',
    border: 'none',
    color: '#ffffff !important',
    lineHeight: '29px',
    '@media (max-width: 768px)': {
      fontSize: '0.7rem',
      lineHeight: '14px',
    },
  },
  "& .MuiTabs-indicator": {
    display: 'none'
  },
};

const lenovoPageTabs: any = [
  { name: 'overview', id: 'overview' },
  { name: 'why attend', id: 'why-attend' },
  { name: 'agenda', id: 'agenda' },
  { name: 'speakers', id: 'speakers' },
]

export const LenovoPages = ({ event }: LenovoPagesProps) => {
  const [value, setValue] = useState('overview');

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  };

  return (
    <Stack>
      <Stack sx={{ py: '1.5rem', display: "flex", flexDirection: "row", flex: 1 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={agendaTabStyles}
        >
          {lenovoPageTabs?.map((item: any) => {
            return (
              <Tab label={item?.name} value={item?.id} key={item?.id} />
            )
          })}
        </Tabs>
      </Stack>
      <Container
        maxWidth={false}
        sx={{
          width: 1,
          p: 0,
          pt: '1.5rem !important',
          pl: '12px !important',
          pr: '12px !important',
          '@media (min-width: 768px)': {
            maxWidth: '66.66666667%'
          },
          '@media (max-width: 768px)': {
            pl: '1.25rem !important',
            pr: '1.25rem !important',
          },
        }}
      >
        {
          value === 'overview' && (
            <OverviewPage event={event} />
          )
        }
        {
          value === 'why-attend' && (
            <WhyAttend event={event} />
          )
        }
        {
          value === 'speakers' && (

            <SpeakerPage event={event} />
          )
        }
        {
          value === 'agenda' && (
            <Agenda event={event} />
          )
        }
      </Container>

    </Stack>
  );
};
