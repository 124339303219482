import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ICompanyCoverPhotoState } from '../../../models/company/CoverPhotoState';
import { CoverPhotoActionTypes } from './types';

export interface ICoverPhotoSuccessAction {
  type: CoverPhotoActionTypes.SET_COMPANY_COVER_PHOTO;
  url: string;
}

export interface ICoverPhotoErrorAction {
  type: CoverPhotoActionTypes.COVER_COMPANY_PHOTO_ERROR;
  errorMessage: string;
}

export interface ICoverPhotoResetAction {
  type: CoverPhotoActionTypes.RESET_COVER_COMPANY_PHOTO;
}

export type CoverPhotoActions = ICoverPhotoSuccessAction | ICoverPhotoErrorAction | ICoverPhotoResetAction;

export const setCoverPhoto: ActionCreator<
  ThunkAction<Promise<any>, ICompanyCoverPhotoState, null, ICoverPhotoSuccessAction>
> = (url: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CoverPhotoActionTypes.SET_COMPANY_COVER_PHOTO,
      url
    });
  };
};

export const setCoverPhotoError: ActionCreator<
  ThunkAction<Promise<any>, ICompanyCoverPhotoState, null, ICoverPhotoSuccessAction>
> = (fileName: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CoverPhotoActionTypes.COVER_COMPANY_PHOTO_ERROR,
      errorMessage: `Failed to upload file ${fileName}`
    });
  };
};

export const resetCoverPhoto: ActionCreator<
  ThunkAction<Promise<any>, ICompanyCoverPhotoState, null, ICoverPhotoSuccessAction>
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CoverPhotoActionTypes.RESET_COVER_COMPANY_PHOTO,
      value: null
    });
  };
};
