/* eslint-disable react/no-unused-prop-types */
// video-footer.tsx => Footer and controls region for the Panelist video component
// Still very much WIP

// Core Imports
import './RangeInput.css';
import { useEffect, useState } from 'react';

// Icons
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
// import VolumeOffIcon from '@mui/icons-material/VolumeOff';
// import VolumeUpIcon from '@mui/icons-material/VolumeUp';
// import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
// import CropFreeIcon from '@mui/icons-material/CropFree';
// import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
// import AttendeesIcon from 'src/assets/images/participant.svg';
import MicrophoneIcon from 'src/assets/images/event-live/host-and-attendees/microphone.svg';
import CameraIcon from 'src/assets/images/event-live/host-and-attendees/camera.svg';
import ShareScreenIcon from 'src/assets/images/event-live/host-and-attendees/share.svg';
import ImageIcon from 'src/assets/images/event-live/host-and-attendees/image.svg';
import { IConnectStatus } from 'src/models/connection';
import Stack from '@mui/material/Stack';
import { bgGradient } from 'src/mui/theme/css';
import { Typography } from '@mui/material';
import { CropFullScreenIcon } from './icons/fullscreenicon';
import { VolumDownIcon } from './icons/volumdownicon';
import { VolumUpIcon } from './icons/volumupicon';

// Properties that can be accepted by the video footer, should be self-explanatory
type VideoFooterProps = {
  // Methods
  play(): void;
  pause(): void;
  seek(amount: number): void;
  setVolume(dur: number): void;
  setFullScreen(): void;
  setPip(): void;
  setScreenMode(mode: string): void;
  showParticipant(): void;

  // Properties
  isPlaying: boolean;
  duration: number;
  position: number;
  volume: number;
  fullScreen: boolean;
  errorURL: string;
  videoStatus: string;
  screenMode: 'small' | 'large' | 'hidden' | undefined;
  attendees?: React.ReactNode;
  speakerConnectStatuses?: IConnectStatus[];
  attendeeConnectStatuses?: IConnectStatus[];
  currentSession?: any;
};

const flexRow = { display: 'flex', justifyContent: 'end',alignItems: 'center', flexDirection: 'row' }
const playerButtons = { display: 'flex', justifyContent: 'space-between',alignItems: 'center', flexDirection: 'row', width: 1 }

// Render out the controls area
export const VideoFooter = (props: VideoFooterProps) => {
  const [oldVolume, setOldVolume] = useState(100);
  const [showAttendees, setShowAttendees] = useState<boolean>(false);

  // Effect to remember the last volume when the user unmutes
  useEffect(() => {
    if (props.volume !== 0) {
      setOldVolume(props.volume);
    }
  }, [props.volume]);

  useEffect(() => setShowAttendees(false), [props.screenMode]);

  const sessionSpeakerName = `${props?.currentSession?.eventSpeakers[0] ? props?.currentSession?.eventSpeakers[0]?.firstName : ''} ${props?.currentSession?.eventSpeakers[0] ? props?.currentSession?.eventSpeakers[0]?.lastName : ''}`;
  const sessionSpeakerTitle = `${props?.currentSession?.eventSpeakers[0] ? props?.currentSession?.eventSpeakers[0]?.jobTitle : ''}`
  return (
    <Stack 
    width={1}
    position='absolute'
    left='0px'
    bottom='0'
    zIndex={1}
    paddingX={0}
    paddingY={0}
      sx={{
        width: 'calc(100% - 0px)',
        ...bgGradient({
          direction: '0deg',
          startColor: '#000000bf',
          endColor: '#ffffff00',
        }),
      }}
    >
      <Stack sx={{ position: 'absolute', left: '12px', top: '-55px', alignItems: 'flex-start' }}>
        <Typography variant="caption" component="div" sx={{ mb: '4px', color: '#ffffff', fontSize: '13px', lineHeight: '15px', fontFamily: 'Poppins-400', textShadow: "1px 1px 4px black" }}>
          {sessionSpeakerName}
        </Typography>
        <Typography variant="caption" component="div" sx={{ mb: 0, color: '#ffffff', fontSize: '13px', lineHeight: '15px', fontFamily: 'Poppins-600', textShadow: "1px 1px 4px black" }}>
          {sessionSpeakerTitle}
        </Typography>
      </Stack>
    <Stack
      sx={{
        // width: 'calc(100% - 24px)',
      }}
    >
      {/* Seek Bar */}

      <input
        className="range__input"
        type="range"
        min="0"
        max={props.duration}
        value={props.position}
        style={{ backgroundSize: `${(props.position * 100) / props.duration}% 100%`, width: 'calc(100% - 24px)', marginLeft: '12px' }}
        // eslint-disable-next-line radix
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.seek(parseInt(e.target.value))}
      />

      {/* Play and Pause Controls */}

      {props.errorURL.length ? (
        <Stack 
          sx={{pb: '8px', pt: '9px', pl:'20px', pr: '27px', ...playerButtons}}
          // px={3}
          // py={3}
          gap={2}
        >
          <Stack>
            <PlayArrowIcon sx={{ color: '#edf2f5', fontSize: '35px' }} />
          </Stack>

          <Stack
            display='flex'
            justifyContent='end'
            alignItems='center'
            flexDirection='row'
            gap={2}
          >
            {/* <Stack>
              <VolumeOffIcon sx={{ color: '#edf2f5' }} />
            </Stack> */}

            {/* <FeaturedVideoOutlinedIcon sx={{ color: '#edf2f5' }} />

            <CropLandscapeIcon sx={{ color: '#edf2f5' }} /> */}
            <Stack sx={flexRow}>
              {props.volume === 0 ? (
                <Stack sx={{ cursor: 'pointer', mr: 1 }}>
                  <VolumDownIcon
                    onClickCapture={() => props.setVolume(oldVolume)}
                  />
                </Stack>
              ) : (
                <Stack sx={{ cursor: 'pointer', mr: 1 }}>
                  <VolumUpIcon
                    onClickCapture={() => props.setVolume(0)}
                  />
                </Stack>
              )}
              <input
                className="volume__range"
                type="range"
                min="0"
                max="100"
                value={props.volume}
                style={{ backgroundSize: `${props.volume}% 100%` }}
                // eslint-disable-next-line radix
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.setVolume(parseInt(e.target.value))}
              />
            </Stack>
            <CropFullScreenIcon />
          </Stack>
        </Stack>
      ) : (
        <Stack
          // px={3}
          // py={3}
          sx={{pb: '8px', pt: '9px', pl:'20px', pr: '27px', ...flexRow}}
        >
          <Stack
            sx={flexRow}
            gap={2}
            position='absolute'
            left={3}
          >
            {props.videoStatus !== 'sessions' && (
              <>
                <img className="w-4 cursor-pointer" src={MicrophoneIcon} alt="microphone" />
                <img className="w-7 cursor-pointer" src={CameraIcon} alt="camera" />
                <img className="w-7 cursor-pointer" src={ShareScreenIcon} alt="share" />
                <img className="w-7 h-full cursor-pointer" src={ImageIcon} alt="upload" />
              </>
            )}
          </Stack>

          {/* Volume Controls */}

          <Stack
            sx={playerButtons}
          >
            <Stack direction="row" sx={{ alignItems: 'center', gap: '20px' }}>
              {/* <Stack sx={{ alignItems: 'baseline' }}>
                <Typography variant="caption" component="div" sx={{ mb: 1, color: '#ffffff', fontSize: '13px', lineHeight: '15px', fontFamily: 'Poppins-400' }}>
                  {sessionSpeakerName}
                </Typography>
                <Typography variant="caption" component="div" sx={{ mb: 0, color: '#ffffff', fontSize: '13px', lineHeight: '15px', fontFamily: 'Poppins-600' }}>
                  {sessionSpeakerTitle}
                </Typography>
              </Stack> */}
              {props.videoStatus === 'sessions' ? (
              <Stack sx={{flexDirection: 'row', gap: '20px'}}>
                {props.isPlaying ? (
                  <PauseIcon
                    sx={{ color: '#ffffff', fontSize: '35px' }}
                    onClickCapture={() => props.pause()}
                  />
                ) : (
                  <PlayArrowIcon
                    sx={{ color: '#ffffff', fontSize: '35px' }}
                    onClickCapture={() => props.play()}
                  />
                )}
                <Stack sx={flexRow}>
                  {props.volume === 0 ? (
                    <Stack sx={{ cursor: 'pointer', mr: 1 }}>
                      <VolumDownIcon
                        onClickCapture={() => props.setVolume(oldVolume)}
                      />
                    </Stack>
                  ) : (
                    <Stack sx={{ cursor: 'pointer', mr: 1 }}>
                      <VolumUpIcon
                        onClickCapture={() => props.setVolume(0)}
                      />
                    </Stack>
                  )}
                  <input
                    className="volume__range"
                    type="range"
                    min="0"
                    max="100"
                    value={props.volume}
                    style={{ backgroundSize: `${props.volume}% 100%` }}
                    // eslint-disable-next-line radix
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.setVolume(parseInt(e.target.value))}
                  />
                </Stack>
              </Stack>
              ) : (
                <Stack style={{ height: 35 }} />
              )}
            </Stack>  
            <Stack
              sx={flexRow}
              gap={2}
            >
              {/* <Stack sx={flexRow}>
                {props.volume === 0 ? (
                  <Stack sx={{ cursor: 'pointer', mr: 1 }}>
                    <VolumDownIcon
                      onClickCapture={() => props.setVolume(oldVolume)}
                    />
                  </Stack>
                ) : (
                  <Stack sx={{ cursor: 'pointer', mr: 1 }}>
                    <VolumUpIcon
                      onClickCapture={() => props.setVolume(0)}
                    />
                  </Stack>
                )}
                <input
                  className="volume__range"
                  type="range"
                  min="0"
                  max="100"
                  value={props.volume}
                  style={{ backgroundSize: `${props.volume}% 100%` }}
                  // eslint-disable-next-line radix
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.setVolume(parseInt(e.target.value))}
                />
              </Stack> */}

              {/* Player Size Controls */}

              {/*
            <FeaturedVideoOutlinedIcon
              sx={{ color: '#fd8c26' }}
              onClickCapture={() => props.setScreenMode('small')}
            />
            <CropLandscapeIcon
              sx={{ color: '#7283a4' }}
              onClickCapture={() => props.setScreenMode('large')}
            />
            <CropFreeIcon sx={{ color: '#7283a4' }} onClickCapture={() => props.setFullScreen()} />
            {props.screenMode === "large" ?
              <img className="cursor-default fill-black" src={AttendeesIcon} alt="" onClickCapture={() => setShowAttendees(!showAttendees)} /> : <div className="bg-black" />
            }
            {showAttendees &&
              <div className="absolute border-2 drop-shadow-md bg-white w-80 max-h-96 -mt-96 overflow-y-scroll py-4 px-4">
                <div className="flex flex-row items-center mb-4">
                  <img src={AttendeesIcon} alt=""></img>
                  <div className="text-h2 text-blue-2 pl-1">Attendees</div>
                </div>
                <div className="grid grid-cols-3 gap-x-8 gap-y-2">
                  {props.attendees?.map((item, key) => (
                    <HostAttendeeItem
                      item={item}
                      connectStatus={props.connectStatuses?.find(cs => cs.id === item.id)}
                      key={key} />
                  ))}
                </div>
              </div>
              // <div className="absolute border-2 drop-shadow-md bg-white w-80 max-h-96 -mt-96 overflow-y-scroll">
              //   <EventAttendees connectStatus={props.connectStatuses} eventAttendees={props.attendees} />
              // </div>
            }
          </div>
        </div>
      )} */}

              {/* <FeaturedVideoOutlinedIcon
                sx={{ color: props.screenMode === 'small' ? '#fd8c26' : '#ffffff' }}
                onClickCapture={() => props.setScreenMode('small')}
              />
              <CropLandscapeIcon
                sx={{ color: props.screenMode === 'large' ? '#fd8c26' : '#ffffff' }}
                onClickCapture={() => props.setScreenMode('large')}
              /> */}
              <CropFullScreenIcon
                onClickCapture={() => props.setFullScreen()}
              />
              {/* {props.screenMode === 'large' ? (
                <Stack position='relative'>
                  <img
                    style={{
                      fill: 'black',
                      cursor: 'pointer'
                    }}

                    src={AttendeesIcon}
                    alt=""
                    onClickCapture={() => {
                      props.showParticipant();
                      setShowAttendees(!showAttendees);
                    }}
                  />
                  <span className="bg-yellow-500 absolute bottom-3 left-6 rounded-xl text-sm px-1">
                    {props.attendees}
                  </span>
                </Stack>
              ) : (
                <Stack bgcolor='black' />
              )} */}
              {/* {showAttendees && (
              <Stack>
                {props.attendees}
              </Stack>
              )} */}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
    </Stack>
  );
};

export default VideoFooter;
