import { Reducer } from 'redux';

import { IUpcomingEventState } from '../../../models/event/UpcomingEventState';
import { UpcomingEventActions } from './actions';
import { UpcomingEventActionTypes } from './types';

const initialState: IUpcomingEventState = {
  value: null,
  loading: false,
  errorMessage: '',
};

export const UpcomingEventReducer: Reducer<
  IUpcomingEventState,
  UpcomingEventActions
> = (state = initialState, action) => {
  switch (action.type) {
    case UpcomingEventActionTypes.RESET_UPCOMING_EVENT_LOADING: {
      return initialState;
    }

    case UpcomingEventActionTypes.UPCOMING_EVENT_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case UpcomingEventActionTypes.UPCOMING_EVENT_SUCCESS: {
      return {
        ...state,
        value: action.payload,
      };
    }

    case UpcomingEventActionTypes.UPCOMING_EVENT_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null,
      };
    }

    case UpcomingEventActionTypes.UPCOMING_EVENT_CLEANUP: {
      return initialState;
    }

    default:
      return state;
  }
};
