import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { getConnectionRequests } from '../../../../store/connection-requests';
import useCloseMenuWithClickOutside from '../../../../utils/hooks/closeMenuWithClickOutside';
import ConnectionRequestsBadge from './connection-request-badge';
import ConnectionRequestsMenu from './connection-requests-menu';

import { ConnectionSvgIcon } from '../../../../shared-components/V2/IconSvg';
import InfiniteScroll from 'react-infinite-scroller';
import { UserService } from 'src/services';
import { Skeleton } from '@mui/material';



const NavbarConnectionRequests = () => {
  const ref = useRef<any>();
  const { showDropdown, handleDropdown } = useCloseMenuWithClickOutside(ref);
  const dispatch = useDispatch();
  
  const [connectionRequests, setConnectionRequests] = useState<any[]>([]);

  // const connectionRequests = useSelector(
  //   (state: IAppState) => state.connectionRequests.connectionRequests
  // ); //from api

  const [totalPage, setTotalPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const hasMoreItems = nextPage <= totalPage;

  
  const loader = <Skeleton
    animation="wave"
    variant="rounded"
    width={'100%'}
    height={136}
    sx={{
      backgroundColor: 'transparent',
      boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)'
    }}
  />;

  // useEffect(() => {
  //   dispatch(getConnectionRequests());
  // }, [dispatch]);

  const handleConnectionRequestDropdown = () => {
    handleDropdown(showDropdown);
  };

  const fetchItems = useCallback(
    async () => {
      if (fetching) {
        return;
      }
      setFetching(true);

      try {
        const userService = new UserService();
        const response = await userService.getConnectRequests(
          nextPage
        );
        setTotalPage(response?.totalPage);
        if (response && nextPage <= response?.totalPage) {
          // setConnectionRequests((prevNotifications: any) => {
          //   const newData = prevNotifications ? prevNotifications?.data?.concat(response.data) : response.data;
          //   return { ...response, data: newData };
          // });

          setConnectionRequests(connectionRequests.concat(response.data))
          setNextPage(nextPage + 1);
        } else {
          setNextPage(nextPage);
          setFetching(true);
        }
        setIsLoading(false);
      } finally {
        setIsLoading(false);
        setFetching(false);
      }
    },
    [fetching, nextPage]);

    const fetchItems1 = (connectionId:string )=>{
      setConnectionRequests(connectionRequests.filter((req)=>req?.id !== connectionId));
    }

  useEffect(() => {
    setIsLoading(true);
    fetchItems();
  }, []);

  return (
    <div onClick={() => handleConnectionRequestDropdown()} ref={ref}>
      <ConnectionSvgIcon />
      {connectionRequests?.length > 0 && (
        <ConnectionRequestsBadge countConnectionRequests={connectionRequests?.length} />
      )}
      {showDropdown && (
           <InfiniteScroll
           pageStart={0}
           loadMore={fetchItems}
           hasMore={hasMoreItems}
           useWindow={false}
           loader={loader}
         >
          <ConnectionRequestsMenu connectionRequests={connectionRequests} fetchItems={(id:string)=>fetchItems1(id)} />
          </InfiniteScroll>
      )}
    </div>
  );
};
export default NavbarConnectionRequests;
