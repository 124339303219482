import { INotification } from '../../../../models/nav-bar/notifications/notification';
import { imageUrlPrefix, minifiedImage } from '../../../../utils';
import DefaultAvatar from '../../../../assets/images/avatar-default.svg';
import companyDefault from '../../../../assets/images/company-default.svg';
import { Link } from 'react-router-dom';
import { getDistanceBetweenTwoDateTime } from '../../../../utils/datetime';
import { Avatar } from '../../../../shared-components/V2';
import { parseNotficationString } from '../../../../utils/notification';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

interface INotificationItemProps {
  notification: INotification;
}

const NotificationItem = ({ notification }: INotificationItemProps) => {
  const dispatch = useDispatch();
  const getAvatar = () => {
    let avatar = '';
    if (notification.targetType === 'user') {
      avatar = notification?.targetUser?.avatar
        ? minifiedImage(notification?.targetUser?.avatar)
        : DefaultAvatar;
    } else if (notification?.targetType === 'company') {
      avatar = notification?.targetCompany?.logo
        ? minifiedImage(notification?.targetCompany?.logo)
        : companyDefault;
    } else if (notification?.targetType === 'event') {
      avatar = notification?.targetEvent?.company?.logo
        ? minifiedImage(notification?.targetEvent?.company?.logo)
        : companyDefault;
    }
    return avatar;
  };

  return (
    <div className="flex flex-row items-center w-full border-b border-blue-0 pb-2 pt-1 hover:bg-gray-1 px-1 last:border-b-0 hover:border-l-8 hover:border-blue-1 hover:border-b-blue-0 border-l-white border-l-8 pl-2 pr-1">
      <div className="">
        <Avatar
          src={getAvatar()}
          size='MEDIUM'
          alt=""
          className={classNames("", {
            '!object-contain bg-[#edf2f5]': notification?.targetType === 'company'
          })}
        />
      </div>
      <div className="text-sm4 text-gray-1 font-light text-left mt-1 w-[100%] ml-2 pr-[25px]">
        <div>
          <div className='text-left'>
            {
              parseNotficationString(notification.message, notification.slug, false, false, dispatch)
            }
          </div>
        </div>
        <p className="text-blue-1 text-s-10 font-medium">
          {getDistanceBetweenTwoDateTime(new Date(), Date.parse(notification.createdAt))}
        </p>
      </div>
    </div>
  );
};

export default NotificationItem;
