import { combineReducers } from 'redux';

import * as rtk from '@reduxjs/toolkit';

import { IAddressState } from '../models/address/AddressState';
import { CheckUserEmailState } from '../models/CheckUserEmailState';
import { ICompaniesState } from '../models/company/CompaniesState ';
import { ICompanyEmployeesState } from '../models/company/CompanyEmployeesState';
import { ICompanyEventsState } from '../models/company/CompanyEventsState';
import { ICompanyProfileState } from '../models/company/CompanyProfileState';
import { ICompanyResourcesState } from '../models/company/CompanyResourcesState';
import { ICompanySizesState } from '../models/company/CompanySizesState';
import { ICompanySpecialitiesState } from '../models/company/CompanySpecialitiesState';
import { ICompanyCoverPhotoState } from '../models/company/CoverPhotoState';
import { IIndustriesState } from '../models/company/IndustriesState';
import { IJobFunctionState } from '../models/company/JobFunctionsState';
import { IJobTitlesState } from '../models/company/JobTitlesState';
import { IProfilePictureState } from '../models/company/ProfilePictureState';
import { ConnectionsState } from '../models/ConnectionsState';
import { ConnectSuggestionsState } from '../models/ConnectSuggestionsState';
import { IEventAttendeeState } from '../models/event/event-attendee';
import { IInvitationState } from '../models/event/InvitationState';
import { ISimilarEventsState } from '../models/event/similar-events-state';
import { IUpcomingEventAgendaState } from '../models/event/UpcomingEventAgendaState';
import { IUpcomingEventSpeakersState } from '../models/event/UpcomingEventSpeakersState';
import { IUpcomingEventState } from '../models/event/UpcomingEventState';
import { IUploadImage } from '../models/fileupload/upload-image';
import { IUploadDocumentState } from '../models/fileupload/UploadDocumentState';
import { IUploadImageState } from '../models/fileupload/UploadImageState';
import { IContentFilterState } from '../models/library-content/ContentFilterState';
import { IContentState } from '../models/library-content/ContentState';
import { IRegionState } from '../models/library-content/RegionState';
import {
  IChatHeadState,
  IConversationState,
  IMessageState,
} from '../models/messages/message';
import { IModalState } from '../models/modal/ModalState';
import { ISearch } from '../models/nav-bar';
import { INotificationsState } from '../models/nav-bar/notifications/notifications-state';
import { OwnExperienceState } from '../models/OwnExperienceState';
import { IPostCreatedState } from '../models/post/PostCreatedState';
import { IPostDocumentState } from '../models/post/PostDocumentState';
import { IPostImageState } from '../models/post/PostImageState';
import { IPostsState } from '../models/post/PostsState';
import { IPostState } from '../models/post/PostState';
import { IPostUserActionState } from '../models/post/PostUserActionState';
import { RecommendedEventsState } from '../models/RecommendedEventsState';
import { SelectedPackageState } from '../models/SelectedPackageState';
import { ISignupProfileCoverPhotoState } from '../models/signup/SignupProfileCoverPhotoState';
import { ISignupProfilePictureState } from '../models/signup/SignupProfilePictureState';
import { IUserSignUpState } from '../models/signup/UserSignUpState';
import { IForgotPasswordState } from '../models/user/forgotpassword/forgotpasswordState';
import { UserEventsState } from '../models/UserEventsState';
import { UserProfileState } from '../models/UserProfileState';
import {
  IVideoCallState,
  videoCallSlice,
} from './1-1-video-call';
import { AddressReducer } from './address/reducer';
import {
  authSlice,
  AuthState,
} from './auth';
import { chatHeadsSlice } from './chat-heads';
import { checkUserEmailSlice } from './checkUserEmail';
import { CompaniesReducer } from './companies/reducer';
import { CompanyEmployeesReducer } from './company/company-employees/reducer';
import { CompanyEventsReducer } from './company/company-events/reducer';
import { CompanyPostsReducer } from './company/company-posts/reducer';
import { CompanyResourcesReducer } from './company/company-resources/reducer';
import { CompanySizesReducer } from './company/company-sizes/reducer';
import { CompanySpecialitiesReducer } from './company/company-specialities/reducer';
import { SetCoverPhotoReducer } from './company/cover-photo/reducer';
import { IndustriesReducer } from './company/industries/reducer';
import { JobFunctionsReducer } from './company/job-functions/reducer';
import { JobTitlesReducer } from './company/job-titles/reducer';
import { SetProfilePictureReducer } from './company/profile-picture/reducer';
import {
  CompanyProfilePreviewFromCacheReducer,
  ICompanyProfilePreviewFromCacheState,
} from './company/profile-preview-from-cache/reducer';
import { CompanyProfilePreviewReducer } from './company/profile-preview/reducer';
import {
  connectionRequestsSlice,
  ConnectionRequestsState,
} from './connection-requests';
import { SetContentFilterReducer } from './content-filter/reducer';
import {
  eventInviteeSlice,
  EventInviteeState,
} from './event-invitee';
import {
  websiteSlice,
  WebsiteState,
} from './event-website';
import { PostActionsReducer } from './events/event-post-actions/reducer';
import { SetPostDocumentReducer } from './events/event-post-document/reducer';
import { SetPostImageReducer } from './events/event-post-image/reducer';
import { InvitableUsersReducer } from './events/invitable-users/reducer';
import { InviteEventAttendeesReducer } from './events/invite-attendees/reducer';
import { similarEventsSlice } from './events/similar-events';
import { UpcomingEventAgendaReducer } from './events/upcoming-event-agenda/reducer';
import { CreateUpcomingEventPostReducer } from './events/upcoming-event-create-post/reducer';
import { UpcomingEventPostsReducer } from './events/upcoming-event-posts/reducer';
import { UpcomingEventReducer } from './events/upcoming-event/reducer';
import { UpcomingEventSpeakersReducer } from './events/upcominn-event-speakers/reducer';
import {
  ChimeState,
  expoSlice,
} from './expo';
import {
  fileUploadSlice,
  FileUploadState,
} from './fileupload';
import { DocumentUploadReducer } from './fileupload/document-upload/reducer';
import { ImageUploadReducer } from './fileupload/imageupload/reducer';
import {
  interestsSlice,
  InterestsState,
} from './interestsAndOffers';
import { ContentReducer } from './library-content/reducer';
import { liveEventSlice } from './liveEvent';
import { LiveEventState } from './liveEvent/types';
import {
  livePollSlice,
  LivePollState,
} from './livePoll';
import { ModalReducer } from './modal/reducer';
import { SearchBarReducer } from './navBarSearch/reducer';
import { newMessageSlice } from './newMessage';
import { notificationsSlice } from './notifications';
import { oneToOneConversationSlice } from './oneToOneConversation';
import { SetSelectedPackageReducer } from './packages/reducer';
import {
  pollSlice,
  PollState,
} from './poll';
import { PostReducer } from './posts/reducer';
import {
  privateChatSlice,
  PrivateChatState,
} from './privateChat';
import {
  publicLoungeSlice,
  PublicLoungeState,
} from './public-lounge';
import { RegionsReducer } from './regions/reducer';
import {
  ticketSlice,
  TicketState,
} from './ticket';
import {
  userStatusSlice,
  UserStatusState,
} from './user-status';
import { ConnectSuggestionsReducer } from './user/connect-suggestions/reducer';
import { ForgotPasswordReducer } from './user/forgotpassword/reducer';
import { SetPhotoReducer } from './user/images/reducer';
import { OwnConnectionsReducer } from './user/own-connections/reducer';
import { OwnExperienceReducer } from './user/own-experience/reducer';
import {
  commentSlice,
  CommentsState,
} from './user/post-comments';
import {
  UserPostsReducer,
  UserPostsState,
} from './user/posts/reducer';
import {
  IProfilePostsState,
  profilePostsSlice,
} from './user/profile-posts';
import { RecommendedEventsReducer } from './user/recommended-events/reducer';
import {
  userSearchSlice,
  UserSearchState,
} from './user/search-user';
import { SetSignupCoverPhotoReducer } from './user/signup/cover-photo/reducer';
import { SetSignupProfilePictureReducer } from './user/signup/profile-picture/reducer';
import { SingupReducer } from './user/signup/reducer';
import { UserEventsReducer } from './user/user-events/reducer';
import { UserProfileReducer } from './user/user-profile/reducer';
import { IInteractiveEventState } from './interactive-event/types';
import { interactiveEventSlice } from './interactive-event';
import { AllStoriesReducer, AllStoryState } from './user/story/reducer';
import { ViewStoryState ,ViewStoriesReducer} from './user/story/view-story/reducer';

export interface IAppState {
  auth: AuthState;
  userStatus: UserStatusState;
  website: WebsiteState;
  liveEvent: LiveEventState;
  interactiveEvent: IInteractiveEventState;
  privateChat: PrivateChatState;
  oneToOneConversation: IConversationState;
  newMessage: IMessageState;
  chatHeads: IChatHeadState;
  fileUpload: FileUploadState;
  fileUploadState: FileUploadState;
  userSearchResult: UserSearchState;
  checkUserEmail: CheckUserEmailState;
  signupUser: IUserSignUpState;
  forgotPasswordEmail: IForgotPasswordState;
  setSelectedPackage: SelectedPackageState;
  userProfile: UserProfileState;
  ownExperience: OwnExperienceState;
  connections: ConnectionsState;
  connectSuggestions: ConnectSuggestionsState;
  userEvents: UserEventsState;
  userPosts: UserPostsState;
  allStories: AllStoryState;
  viewStories: ViewStoryState;
  recommendedEvents: RecommendedEventsState;
  companySpecialities: ICompanySpecialitiesState;
  companyEmployees: ICompanyEmployeesState;
  companySizes: ICompanySizesState;
  industries: IIndustriesState;
  companies: ICompaniesState;
  jobFunctions: IJobFunctionState;
  jobTitles: IJobTitlesState;
  interests: InterestsState;
  signupProfilePicture: ISignupProfilePictureState;
  signupCoverPhoto: ISignupProfileCoverPhotoState;
  regions: IRegionState;
  companyProfilePreviewFromCache: ICompanyProfilePreviewFromCacheState;
  companyProfilePreview: ICompanyProfileState;
  companyCoverPhoto: ICompanyCoverPhotoState;
  companyProfilePicture: IProfilePictureState;
  imageUpload: IUploadImageState;
  companyEvents: ICompanyEventsState;
  companyResources: ICompanyResourcesState;
  posts: IPostsState;
  poll: PollState;
  libraryContent: IContentState;
  contentFilter: IContentFilterState;
  upcomingEvent: IUpcomingEventState;
  upcomingEventSpeakers: IUpcomingEventSpeakersState;
  upcomingEventAgenda: IUpcomingEventAgendaState;
  similarEvents: ISimilarEventsState;
  documentUpload: IUploadDocumentState;
  postImage: IPostImageState;
  postDocument: IPostDocumentState;
  upcomingEventPosts: IPostsState;
  createpost: IPostCreatedState;
  //postComments: ICommentsState;
  postComments: CommentsState;
  eventLocationAddress: IAddressState;
  isModalOpen: IModalState;
  postActions: IPostUserActionState;
  post: IPostState;
  inviteAttendees: IInvitationState;
  invitableUsers: IEventAttendeeState;
  uploadedImages: IUploadImage;
  searchBar: ISearch;
  chime: ChimeState;
  chatVideoCall: IVideoCallState;
  notifications: INotificationsState;
  connectionRequests: ConnectionRequestsState;
  eventInvitee: EventInviteeState;
  profilePosts: IProfilePostsState;
  livePoll: LivePollState;
  publicLounges: PublicLoungeState;
  eventTickets: TicketState;
  products?: any;
}

const rootReducer = combineReducers<IAppState>({
  auth: authSlice.reducer,
  userStatus: userStatusSlice.reducer,
  website: websiteSlice.reducer,
  liveEvent: liveEventSlice.reducer,
  interactiveEvent: interactiveEventSlice.reducer,
  privateChat: privateChatSlice.reducer,
  oneToOneConversation: oneToOneConversationSlice.reducer,
  newMessage: newMessageSlice.reducer,
  chatHeads: chatHeadsSlice.reducer,
  fileUpload: fileUploadSlice.reducer,
  fileUploadState: fileUploadSlice.reducer,
  userSearchResult: userSearchSlice.reducer,
  checkUserEmail: checkUserEmailSlice.reducer,
  signupUser: SingupReducer,
  forgotPasswordEmail: ForgotPasswordReducer,
  setSelectedPackage: SetSelectedPackageReducer,
  userProfile: UserProfileReducer,
  ownExperience: OwnExperienceReducer,
  connections: OwnConnectionsReducer,
  connectSuggestions: ConnectSuggestionsReducer,
  userEvents: UserEventsReducer,
  userPosts: UserPostsReducer,
  allStories:AllStoriesReducer,
  viewStories:ViewStoriesReducer,
  recommendedEvents: RecommendedEventsReducer,
  companySpecialities: CompanySpecialitiesReducer,
  companyEmployees: CompanyEmployeesReducer,
  companySizes: CompanySizesReducer,
  industries: IndustriesReducer,
  companies: CompaniesReducer,
  jobFunctions: JobFunctionsReducer,
  jobTitles: JobTitlesReducer,
  interests: interestsSlice.reducer,
  signupProfilePicture: SetSignupProfilePictureReducer,
  signupCoverPhoto: SetSignupCoverPhotoReducer,
  regions: RegionsReducer,
  companyProfilePreviewFromCache: CompanyProfilePreviewFromCacheReducer,
  companyProfilePreview: CompanyProfilePreviewReducer, //from DB
  companyCoverPhoto: SetCoverPhotoReducer,
  companyProfilePicture: SetProfilePictureReducer,
  imageUpload: ImageUploadReducer,
  companyEvents: CompanyEventsReducer,
  companyResources: CompanyResourcesReducer,
  posts: CompanyPostsReducer,
  poll: pollSlice.reducer,
  libraryContent: ContentReducer,
  contentFilter: SetContentFilterReducer,
  upcomingEvent: UpcomingEventReducer,
  upcomingEventSpeakers: UpcomingEventSpeakersReducer,
  upcomingEventAgenda: UpcomingEventAgendaReducer,
  similarEvents: similarEventsSlice.reducer,
  documentUpload: DocumentUploadReducer,
  postImage: SetPostImageReducer,
  postDocument: SetPostDocumentReducer,
  upcomingEventPosts: UpcomingEventPostsReducer,
  createpost: CreateUpcomingEventPostReducer,
  // postComments: CreateCommentReducer,
  postComments: commentSlice.reducer,
  eventLocationAddress: AddressReducer,
  isModalOpen: ModalReducer,
  postActions: PostActionsReducer,
  post: PostReducer,
  inviteAttendees: InviteEventAttendeesReducer,
  invitableUsers: InvitableUsersReducer,
  uploadedImages: SetPhotoReducer,
  searchBar: SearchBarReducer,
  chime: expoSlice.reducer,
  chatVideoCall: videoCallSlice.reducer,
  notifications: notificationsSlice.reducer,
  connectionRequests: connectionRequestsSlice.reducer,
  eventInvitee: eventInviteeSlice.reducer,
  profilePosts: profilePostsSlice.reducer,
  livePoll: livePollSlice.reducer,
  publicLounges: publicLoungeSlice.reducer,
  eventTickets: ticketSlice.reducer,
});

export const store = rtk.configureStore({
  reducer: rootReducer,
});
