import { useState } from "react";

export const NotificationSvgIcon = () => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const colorBlue = "#0049EF";
  const defaultColor = "#7283A4";
  const fillColor = isHover ? colorBlue : defaultColor;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className="cursor-pointer"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <g
        id="Group_17625"
        data-name="Group 17625"
        transform="translate(-1116.586 -8)"
      >
        <rect
          id="Rectangle_7696"
          data-name="Rectangle 7696"
          width="40"
          height="40"
          rx="15"
          transform="translate(1116.586 8)"
          fill="#e9eff2"
        />
        <g
          id="bell-2-1-svgrepo-com"
          transform="matrix(0.966, -0.259, 0.259, 0.966, 1114.373, 13.263)"
        >
          <path
            id="Path_14804"
            data-name="Path 14804"
            d="M18.981,7.088a8.743,8.743,0,0,1,7.225,8.786V21.2l1.525,1.927a2.551,2.551,0,0,1-1.85,4.16H9.673a2.551,2.551,0,0,1-1.85-4.16L9.347,21.2V15.874a8.743,8.743,0,0,1,7.225-8.786A7.194,7.194,0,0,1,18.981,7.088Z"
            transform="translate(0 0)"
            fill={fillColor}
          />
          <path
            id="Path_14805"
            data-name="Path 14805"
            d="M12.414,26.535a5.018,5.018,0,0,1-1-1.535h8.732a5.016,5.016,0,0,1-1,1.535,4.756,4.756,0,0,1-1.545,1.084,4.556,4.556,0,0,1-3.646,0A4.755,4.755,0,0,1,12.414,26.535Z"
            transform="translate(0 4.286)"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  );
};
