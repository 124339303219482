import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { IAppState } from '../store';
import { initialise, setRedirect, unsetRedirect } from '../store/auth';

export function AuthWrapper({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialise());
  }, [dispatch]);


  const location = useLocation();
  const auth = useSelector((state: IAppState) => state.auth);
  const { redirect: redirectTo } = auth;

  if (location.pathname === redirectTo) dispatch(unsetRedirect());

  return <>{children}</>;
}

export function RequireAuth() {
  const dispatch = useDispatch();
  const auth = useSelector((state: IAppState) => state.auth);
  const { currentUser: user, initialised } = auth;
  const location = useLocation();

  if (!initialised) {
    return null; // should be a loading screen
  }

  /* 
  On authenicated endpoints the user will be redirect to /loginorjoin page if not logged in.
  Before redirecting to autherize user. The path is saved in redux "auth" state.
  */

  if (!user) {
    dispatch(setRedirect('/'));
    // dispatch(setRedirect(`${location.pathname}${location.search}`));

    return <Navigate to="/loginorjoin" replace />;
  }

  return <Outlet />;
}


export function IsLoggedIn() {
  const auth = useSelector((state: IAppState) => state.auth);
  const { currentUser, initialised } = auth;

  return initialised && !!currentUser;
}

export function RedirectIfAuthenticated() {
  const auth = useSelector((state: IAppState) => state.auth);
  const { redirect: redirectTo } = auth;

  if (IsLoggedIn()) {
    // Check if its a redirected user. Otherwise navigate user to home page.
    if (redirectTo) {
      return <Navigate to={redirectTo} />;
    } else {
      return <Navigate to="/home" replace />;
    }
  }

  return <Outlet />;
}