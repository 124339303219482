/* eslint-disable max-len */
/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';
// import { SpotlightEvents } from '../../../../components/spotlight';

import classNames from 'classnames';
import { IPostSpotlightResult } from '../../../../models/post/IPostSpotlight';
import { CompanyService } from '../../../../services';
import { SpotlightEvents } from './spotlight';
import { NoProductList } from '../../company-profile/no-product-list';

interface ICompanySpotlightNewProps {
    id: string;
    isCompanySpotlight?: boolean;
    company?: any;
    posts?: any;
    specificSpotlight?: any;
    setSpecificSpotlight: any;
    setActiveTab: (value: string) => void;
}

export const CompanySpotlightNew = ({
  id, isCompanySpotlight, company, posts, specificSpotlight, setSpecificSpotlight, setActiveTab
}: ICompanySpotlightNewProps) => {
  const [searchText, setSearchText] = useState('');
  // const [specificSpotlight, setSpecificSpotlight] = useState<IPostSpotLight[]>([]);
  const [fetching, setFetching] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [nextPage, setNextPage] = useState(2);
  const hasMoreItems = nextPage <= totalPage;
  const companyService: CompanyService = new CompanyService();
  const PAGE_SIZE = 20;

  const getSpotlight = async () => {
    const result: IPostSpotlightResult = await companyService.getCompanySpotlight(id, PAGE_SIZE, 1, searchText);
    setTotalPage(result.totalPage);
    setSpecificSpotlight(result.data);
    setNextPage(2);
  };

  const getSearchText = (searchText: string) => {
    setSearchText(searchText);
  };

  useEffect(() => {
    getSpotlight();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const fetchItems = useCallback(
    async () => {
      if (fetching) {
        return;
      }
      setFetching(true);
      try {
        const result = await companyService.getCompanySpotlight(id, PAGE_SIZE, nextPage, searchText);
        setTotalPage(result.totalPage);
        if (result && nextPage <= result.totalPage) {
          setSpecificSpotlight(specificSpotlight.concat(result.data));
          setNextPage(nextPage + 1);
        } else {
          setFetching(true);
        }
      } finally {
        setFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [specificSpotlight, fetching, nextPage]
  );

  const handleCreateSpotlight = () => {
    setActiveTab('spotlight');
    window.scrollTo({
      top: 560,
      behavior: 'smooth'
    });
  };

  return (
    specificSpotlight.length > 0 ? (
      <div className={classNames('w-[100%] max-h-[462px] min-h-[462px] rounded-[20px] border-1 border-[#E6E6E6] bg-white overflow-hidden', {
        '!w-[100%]': posts?.value?.postsResult.data.length! === 0,
      })}
      >
        <SpotlightEvents
          isCompanySpotlight={isCompanySpotlight}
          company={company}
          getSpotlight={getSpotlight}
          fetchItems={fetchItems}
          specificSpotlight={specificSpotlight}
          hasMoreItems={hasMoreItems}
          getSearchText={getSearchText}
          isOwnSpotlight
        />
      </div>
    ) : <NoProductList text="No spotlight available at the moment." containerClass="bg-white create-post-card !rounded-[20px] h-[463px] w-[100%] flex items-center justify-center flex-col" onClickButton={handleCreateSpotlight} btnText="Create a Spotlight" />
  );
};
