/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Typography,
  Grid,
  Stack,
  IconButton,
  Card,
  CardMedia,
  Box,
  Tooltip,
  useMediaQuery,
  Button,
} from "@mui/material";
import "./Card.css";
import { getMinifiedImage, getS3Path } from "src/mui/utils/image";
import { IUserProfile } from "src/models/user-profile";
import Navbar from "./Navbar";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { useSelector } from "react-redux";
import { IAppState } from "src/store";
import BottomBanner from "./BottomBanner";
import RegisteredCodeFormNew from "./RegisteredCodeFormNew";
import useEventIsPassed from "../../survey/useEventIsPassed";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useSurveyContext } from "src/mui/providers/GeneralContext";
import PageStepper from "./Stepper";
import Crosscon from "./Crosscon";
import { ConfirmDialog } from "src/mui/components/custom-dialog";
import { EventQuestionPageTitlePositionTypes, RegistrationProgressType } from "../../survey/enum";
import { SurveyItemType, SurveyQuestionsForm } from "src/mui/types/survey";
import { getFieldLabel } from "./ConstantsandTypes";

type Props = {
  pageItems?: any;
  step: number;
  user?: IUserProfile | undefined;
  onNextStep: () => void;
  submitPage: () => void;
  onPreviousStep?: () => void;
  logoColor: string;
  bgColor: string;
  stepName: string;
  stepDescription: string;
  children: React.ReactNode;
  isLoading: boolean;
  mediaURL: string;
  isMediaExpanded?: boolean;
  pageLength?: number;
  questionLength?: number;
  setOpenForm: (value: boolean) => void;
  setPasswordProtectionCheck?: (value: boolean) => void;
  attendeeCard?: boolean;
  requiredFieldsFilled?: boolean;
  currentPage?: string;
  onLogout?: () => void;
  surveySubPage?: string;
  primaryFontColor: string | null;
  secondaryBackgroundColor: string | null;
  handleSkip?: () => void;
  isSuveryorRegSkipable?: boolean;
  lastSurveyorRegistrationPage?: boolean;
  stepHeader?: any;
};

export default function FormCard({
  pageItems,
  lastSurveyorRegistrationPage,
  isSuveryorRegSkipable,
  surveySubPage,
  currentPage,
  requiredFieldsFilled,
  step,
  handleSkip,
  setOpenForm,
  onNextStep,
  submitPage,
  onPreviousStep,
  setPasswordProtectionCheck,
  logoColor,
  bgColor,
  stepName,
  stepDescription,
  children,
  isLoading,
  mediaURL,
  isMediaExpanded,
  pageLength,
  questionLength,
  attendeeCard,
  onLogout,
  primaryFontColor,
  secondaryBackgroundColor,
  user,
  stepHeader,
}: Props) {
  const { resetProtectedPasswordError, submitSurveyLater, surveyQuestions } = useSurveyContext();
  const videoRef: React.RefObject<HTMLVideoElement> = useRef(null);
  const [tooltipTitle, setTooltipTitle] = useState("Play");
  const [muteVideo, setMuteVideo] = useState(false);
  const format = mediaURL?.split("/");

  const tabScreen = useMediaQuery("(min-width:1150px)");
  const event = useSelector((state: IAppState) => state.website.event!);

  const {
    isPastEvent,
    location,
    eventStartDate,
    canAttendeesRequestMeeting,
    isLevnovoWebsiteTemplate,
  } = useEventIsPassed();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const showVideo = useMemo(() => format && format[0] === "videos", [format]);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const { upcomingEvent } = useSelector((state: IAppState) => state);
  const videoOrImgHeight =
    event?.websiteTemplate === "lenovo"
      ? "calc(100vh - 298px)"
      : event?.websiteTemplate !== "lenovo" &&
        (stepDescription?.trim() === "" || !stepDescription)
      ? showVideo ? "calc(100vh - 386px)" : "calc(100vh - 386px)"
      : showVideo ? "calc(-491px + 100vh)" : stepDescription ? "calc(100vh - 491px)" : "calc(100vh - 515px)";

      // hook
  const isSurveySubmitted =
    upcomingEvent?.value?.data?.attendeeStatus?.isSurveySubmitted;
    
  const showLeftSideOnSurvey = () => {
    if (surveySubPage === "surveyForm"  && currentPage === "surveyForm" ) {
      // const formField =
      //   surveyQuestions &&
      //   surveyQuestions.find((field: SurveyQuestionsForm) => field?.fieldType === SurveyItemType.RADIO_BOX_ARRAY && field?.eventQuestionPageId === pageItems?.currentPageId);
      //   return formField ? false : true;
      return true;
    }
    return true;
  }

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video?.paused || video?.ended) {
      video.play();
      setTooltipTitle("Pause");
    } else {
      video?.pause();
      setTooltipTitle("Play");
    }
  };

  const playFromStart = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleClosePopup = () => {
    sessionStorage.setItem("termAndCondition", "false");
    resetProtectedPasswordError();
    setOpenForm(false);
  };

  const handleSurveyFillLater = () => {
    sessionStorage.setItem("termAndCondition", "false");
    submitSurveyLater({progressStatus:  RegistrationProgressType.SURVEY_FORM_FILL_LATER});
    resetProtectedPasswordError();
    setOpenForm(false);
  };

  const handleCloseOrConfirmPopup = () => {
    if (
      !isSuveryorRegSkipable &&
      currentPage === surveySubPage &&
      !isSurveySubmitted
    ) {
      setOpenConfirm(true);
    } else {
      handleClosePopup();
    }
  };

  const showPaginationHeader: any = useMemo(() => {
    if (currentPage === "registrationForm") {
      return true;
    } else if (currentPage === surveySubPage) {
      return true;
    } else if (currentPage === "signupRegistrationForm") {
      return true;
    } else if (
      currentPage === "surveyForm" &&
      canAttendeesRequestMeeting &&
      !isPastEvent
    ) {
      if (surveySubPage === "attendeesListView") return true;
      else return false;
    }

    return false;
  }, [
    currentPage,
    surveySubPage,
    canAttendeesRequestMeeting,
    isPastEvent,
    pageItems?.surveyorRegPagesIds,
  ]);


  const getPadding = useMemo(() => {
    if (surveySubPage === "meetingListView") {
      return "13px 0px 47px 0px";
    }

    return "0px 0px 50px";
  }, [currentPage, surveySubPage, showPaginationHeader]);

  const toggleFullscreen = () => {
    if (videoRef?.current?.requestFullscreen) {
      videoRef?.current?.requestFullscreen();
    } else if (videoRef?.current?.mozRequestFullScreen) {
      videoRef?.current?.mozRequestFullScreen();
    } else if (videoRef?.current?.webkitRequestFullscreen) {
      videoRef?.current?.webkitRequestFullscreen();
    } else if (videoRef?.current?.msRequestFullscreen) {
      videoRef?.current?.msRequestFullscreen();
    }
  };

  const showBackButton = useMemo(() => {
    if (
      stepName?.includes("Password") ||
      stepName?.includes("Register") ||
      surveySubPage === "meetingListView" ||
      (currentPage === surveySubPage && step !== 0) ||
      (currentPage === "registrationForm" && step !== 0)
    ) {
      return true;
    }
    return false;
  }, [surveySubPage, stepName, step, currentPage]);

  const showQRCode = useMemo(() => {
    return (
      surveySubPage && surveySubPage !== "surveyForm" && surveySubPage !== ""
    );
  }, [surveySubPage]);

  const showNextButton = useMemo(() => {
    if (surveySubPage === "qrCode" && isPastEvent) return false;
    else {
      if (
        stepName !== "Register" &&
        !stepName?.includes("Login") &&
        !stepName?.includes("Event Attendee Matches") &&
        !stepName?.includes("Password") &&
        currentPage !== "underReview" &&
        currentPage !== "rejected"
      ) {
        return true;
      } else if (currentPage === "passwordProtected") return true;
      return false;
    }
  }, [stepName, step, currentPage, surveySubPage]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const confirmationMessage = "Are you sure you want to leave this page?";
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    const shouldShowPopup =
      !isSuveryorRegSkipable &&
      currentPage === surveySubPage &&
      !isSurveySubmitted;

    if (shouldShowPopup) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      if (shouldShowPopup) {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      }
    };
  }, [isSuveryorRegSkipable, currentPage, surveySubPage, isSurveySubmitted]);

  useEffect(() => {
    const handleResize = () => {
      setInnerHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Card
        sx={{
          "& .MuiDialog-paper": {
            background: "none",
          },

          "& .MuiCard-root": {
            backgroundColor: "none",
            borderRadius: "0px !important",
          },
        }}
      >
        {/* {tabScreen && */}
        <IconButton
          onClick={() => {
            handleCloseOrConfirmPopup();
          }}
          sx={{
            zIndex: "100000000",
            position: "absolute",
            textAlignLast: "end",
            right: 8,
            top: 3,
            alignItems: "flex-end",
            justifyContent: "flex-end",
            p: "2px",
          }}
        >
          <Crosscon style={{ stroke: pageItems?.bgColor }} />
        </IconButton>
        <Grid
          container
          sx={{
            position: "relative",
          }}
        >
          {tabScreen && showLeftSideOnSurvey() && (
            <Grid
              item
              xs={4}
              sx={{
                // eslint-disable-next-line no-nested-ternary
                background:
                  format && format[0] === "images" && isMediaExpanded
                    ? `url(${getMinifiedImage(mediaURL)})`
                    : bgColor,
                backgroundColor: bgColor,
                zIndex: 10000,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: "relative",
                maxWidth: "560px !important",
                maxHeight: "calc(100vh - 79px)",
                overflow: "hidden",
                minWidth: "485px",
                display: "flex",
                flexDirection: pageItems?.titleDescPosition === EventQuestionPageTitlePositionTypes.TOP ?  "column-reverse" : "column",
                // justifyContent: !isMediaExpanded && !mediaURL ? 'space-between' : 'normal'
                justifyContent: 'space-between'
              }}
            >
              {showVideo && isMediaExpanded && (
                <>
                  <video
                    ref={videoRef}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    loop
                    muted={muteVideo}
                  >
                    <source src={getS3Path(mediaURL)} type="video/mp4" />
                    <source src={getS3Path(mediaURL)} type="video/webm" />
                    <track default kind="captions" />
                  </video>
                </>
              )}

              {event?.websiteTemplate !== "lenovo" && (
                <Box
                  sx={{
                    p: "25px 33px",
                    // top: event?.websiteTemplate !== "lenovo"  ? 18 : null,
                    bottom: pageItems?.titleDescPosition === EventQuestionPageTitlePositionTypes.TOP ? 10 : null,
                    top: pageItems?.titleDescPosition !== EventQuestionPageTitlePositionTypes.TOP ? event?.websiteTemplate !== "lenovo"  ? 18 : null : null,
                    // position:
                    //  ( (!isMediaExpanded && mediaURL) ||
                    //   (currentPage == "surveyForm" &&
                    //     currentPage != surveySubPage))
                    //     ? "static"
                    //     : "absolute",
                    position: isMediaExpanded && showVideo ? "absolute" : "unset",
                    zIndex: 1,
                  }}
                  display="flex"
                  flexDirection="column"
                >
                  <CardMedia
                    src={getMinifiedImage(event?.logo!)}
                    component={"img"}
                    sx={{ width: "250px" }}
                  />

                  {event?.websiteTemplate !== "lenovo" && location && (
                    <Typography
                      variant="body1"
                      sx={{
                        color: primaryFontColor || "white",
                        width: "400px",
                        mt: "5px",
                        fontFamily: "Poppins-400",
                      }}
                    >
                      {location}
                    </Typography>
                  )}

                  {event && event?.websiteTemplate !== "lenovo" && eventStartDate && (
                    <Typography
                      variant="body1"
                      sx={{
                        color: primaryFontColor || "white",
                        width: "400px",
                        fontFamily: "Poppins-400",
                      }}
                    >
                      {eventStartDate}
                    </Typography>
                  )}
                </Box>
              )}

              {showQRCode && <RegisteredCodeFormNew />}

              {!isMediaExpanded && mediaURL && (
                <Stack
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    top: "50%",
                    left: 0,
                    right: 0,
                    padding: "0px 10px 0px 10px",
                  }}
                >
                  {format &&
                    format[0] === "images" &&
                    !isMediaExpanded &&
                    mediaURL && (
                      <CardMedia
                        component="img"
                        sx={{
                          borderRadius: 0,
                          objectFit: "scale-down",
                          objectPosition: "50% 50%",
                          height: videoOrImgHeight,
                        }}
                        image={getMinifiedImage(mediaURL)}
                        alt={mediaURL}
                      />
                    )}

                  {showVideo && !isMediaExpanded && mediaURL && (
                    <CardMedia
                      component="video"
                      autoPlay
                      controls
                      loop
                      muted
                      src={getS3Path(mediaURL)}
                      sx={{
                        // width: "30%",
                        top: 0,
                        left: 0,
                        objectFit: "scale-down",
                        objectPosition: "50% 50%",
                        height: videoOrImgHeight,
                        borderRadius: "10px",
                        background: "black",
                      }}
                    />
                  )}
                </Stack>
              )}
              {showVideo && isMediaExpanded && mediaURL && (
                <div
                  style={{
                    position: "absolute",
                    textAlignLast: "end",
                    bottom: isMediaExpanded ? -6 : 220,
                    right: 5,
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    zIndex: 10,
                  }}
                >
                  <Tooltip title={tooltipTitle}>
                    <IconButton
                      onClick={handlePlayPause}
                      sx={{ color: "white" }}
                    >
                      {tooltipTitle === "Pause" ? (
                        <PauseIcon />
                      ) : (
                        <PlayArrowIcon />
                      )}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Full Screen">
                    <IconButton
                      onClick={toggleFullscreen}
                      sx={{ color: "white" }}
                    >
                      <FullscreenIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Play from Start">
                    <IconButton onClick={playFromStart} sx={{ color: "white" }}>
                      <RestartAltIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={!muteVideo ? "Mute" : "UnMute"}>
                    <IconButton
                      onClick={() => {
                        setMuteVideo(!muteVideo);
                      }}
                      sx={{ color: "white" }}
                    >
                      {muteVideo ? <VolumeOffIcon /> : <VolumeUpIcon />}
                    </IconButton>
                  </Tooltip>
                </div>
              )}

              {event?.websiteTemplate !== "lenovo" && (
                <Box
                  sx={{
                    p: 3,
                    bottom: pageItems?.titleDescPosition !== EventQuestionPageTitlePositionTypes.TOP ? 10 : null,
                    top: pageItems?.titleDescPosition === EventQuestionPageTitlePositionTypes.TOP ? 10 : null,
                    width: "100%",
                    position: isMediaExpanded && showVideo ? "absolute" : "",
                  }}
                  display="flex"
                  flexDirection="column"
                  gap={17}
                >
                  <Stack direction="column" display="flex">
                    <Typography
                      variant="h6"
                      noWrap
                      sx={{
                        // maxWidth: "450px",
                        fontSize: "27px",
                        fontFamily: "Poppins-400",
                        color: primaryFontColor,
                      }}
                    >
                      {event?.websiteTemplate !== "lenovo"
                        ? stepName
                        : event?.name!}
                    </Typography>
                    {/* StepRegistration */}
                    <Box
                      className="hide-scroll"
                      sx={{
                        overflowY: "scroll",
                        maxWidth: "100%",
                        maxHeight: !isMediaExpanded && mediaURL ? "105px" : event?.logo! ?  "calc(100vh - 409px)" : "calc(100vh - 278px)" ,
                        // minHeight: !isMediaExpanded && mediaURL ? !stepDescription ? "auto" : "105px" : event?.logo! ? isMediaExpanded ? "auto" : "calc(100vh - 409px)" : !isMediaExpanded && !mediaURL ? "auto" : !stepDescription ? "auto" : isMediaExpanded ? "auto" : "calc(100vh - 278px)" ,
                        // minHeight: !isMediaExpanded && mediaURL ?  "auto"  : event?.logo! ? isMediaExpanded ? "auto" : "calc(100vh - 409px)" : !isMediaExpanded && !mediaURL ? "auto" : !stepDescription ? "auto" : isMediaExpanded ? "auto" : "calc(100vh - 278px)" ,
                      }}
                    >
                      {/* {currentPage !== "signupRegistrationForm" && */}
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "14px",
                          fontFamily: "Poppins-400",
                          color: primaryFontColor,
                        }}
                      >
                        {event?.websiteTemplate !== "lenovo"
                          ?  getFieldLabel( false,stepDescription) 
                          : location}
                      </Typography>
                      {/* } */}
                    </Box>
                  </Stack>
                </Box>
              )}

              {event?.websiteTemplate === "lenovo" && (
                <Box
                  sx={{
                    p: "25px 33px",
                    bottom: 10,
                    // position:
                    //   (!isMediaExpanded && mediaURL) ||
                    //   (currentPage == "surveyForm" &&
                    //     currentPage != surveySubPage)
                    //     ? "static"
                    //     : "absolute",
                    zIndex: 1,
                  }}
                  display="flex"
                  flexDirection="column"
                >
                  <CardMedia
                    src={getMinifiedImage(event?.logo!)}
                    component={"img"}
                    sx={{ width: "250px" }}
                  />
                </Box>
              )}
            </Grid>
          )}

          <Grid
            item
            xs
            className="form-questions"
            sx={{
              position: tabScreen ? "relative" : "initial",
              backgroundColor: secondaryBackgroundColor || "white",
              overflow: surveySubPage === "checkout" ? "hidden" : null,
              pt: !tabScreen ? "45px" : null,
              "@media (max-width: 768px)": {
                pb: "60px",
                display:
                  currentPage === "login" ||
                  currentPage === "forgotPassowrd" ||
                  currentPage === "rejected"
                    ? "flex"
                    : "",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              },
            }}
          >
            {/* {!tabScreen ? */}
            {/* <Navbar pageItems={pageItems} stepName={stepName} onPreviousStep={onPreviousStep} onNextStep={onNextStep} isLoading={isLoading} setOpenForm={setOpenForm} surveySubPage={surveySubPage} showNextButton={showNextButton} showBackButton={showBackButton} requiredFieldsFilled={requiredFieldsFilled} bgColor={bgColor} step={step} currentPage={currentPage} stepHeader={stepHeader} isSuveryorRegSkipable={isSuveryorRegSkipable} /> : */}
            <PageStepper
              pageItems={pageItems}
              stepName={stepName}
              onPreviousStep={onPreviousStep}
              onNextStep={onNextStep}
              isLoading={isLoading}
              setOpenForm={setOpenForm}
              surveySubPage={surveySubPage}
              showNextButton={showNextButton}
              showBackButton={showBackButton}
              requiredFieldsFilled={requiredFieldsFilled}
              bgColor={bgColor}
              step={step}
              currentPage={currentPage}
              stepHeader={stepHeader}
            />
            {/* // } */}

            <Box
              sx={{
                padding: getPadding,
                overflowY:
                  surveySubPage === "attendeesListView" ? "unset" : "auto",
                height: "calc(100vh - 119px)",
                mt: surveySubPage === "attendeesListView" ? "55px"  : "40px",
                pr: "15px",

                "@media (max-width: 1150px)": {
                  padding: getPadding,
                  width:
                    currentPage === "forgotPassowrd" || currentPage === "login"
                      ? "85%"
                      : null,
                  mt: "0px",
                  pr: "15px",
                  marginLeft: "auto",
                  marginRight: "auto",
                },

                "@media (max-width: 768px)": {
                  padding: "0px",
                  height: "55vh",
                  width:
                    currentPage === "forgotPassowrd" || currentPage === "login"
                      ? "85%"
                      : null,
                  mt: "4px",
                  mb: "0px",
                  pr: "15px",
                },
              }}
            >
              {children}
            </Box>
            <BottomBanner
              pageItems={pageItems}
              lastSurveyorRegistrationPage={lastSurveyorRegistrationPage}
              isSuveryorRegSkipable={isSuveryorRegSkipable}
              handleSkip={handleSkip}
              stepName={stepName}
              submitPage={submitPage}
              onLogout={onLogout}
              user={user}
              onPreviousStep={onPreviousStep}
              onNextStep={onNextStep}
              isLoading={isLoading}
              setOpenForm={setOpenForm}
              surveySubPage={surveySubPage}
              showNextButton={showNextButton}
              showBackButton={showBackButton}
              requiredFieldsFilled={requiredFieldsFilled}
              bgColor={bgColor}
              step={step}
              currentPage={currentPage}
            />
          </Grid>
        </Grid>
      </Card>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Exit registration?"
        content={<>Your registration will not be completed.</>}
        action={
          <Button
            variant="contained"
            // color="error"
            onClick={handleCloseConfirm}
            sx={{
              color: pageItems?.headerColor,
              background: pageItems?.bgColor,
              "&:hover": { background: pageItems?.bgColor },
              fontFamily: "Poppins-400",
              fontWeight: 600,
              fontSize: "14px",
              borderRadius: "8px",
            }}
          >
            Continue
          </Button>
        }
        continueButton={
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              handleClosePopup();
            }}
            sx={{
              fontFamily: "Poppins-400",
              fontWeight: 600,
              fontSize: "14px",
              borderRadius: "8px",
              border: "1px solid rgba(145, 158, 171, 0.32)",
            }}
          >
            Exit Registration
          </Button>
        }
        surveyLaterButton={
          <Button
            variant="contained"
            onClick={() => {
              handleSurveyFillLater();
            }}
            sx={{
              color: pageItems?.headerColor,
              background: pageItems?.bgColor,
              "&:hover": { background: pageItems?.bgColor },
              fontFamily: "Poppins-400",
              fontWeight: 600,
              fontSize: "14px",
              borderRadius: "8px",
            }}
          >
            {" "}
            Fill Survey Later
          </Button>
        }
      />
    </>
  );
}
