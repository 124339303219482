/* eslint-disable quote-props */
// CompanyDetailsProps.tsx
import React from 'react';
import classNames from 'classnames';
import SponsorDefault from '../../images/sponsor-default-two.svg';
import { isNearToBlackColor } from '../factor-partner-details';

interface CompanyDetailsPropsProps {
  company?: any;
  hideWebsiteButton?: boolean;
  mb?: boolean;
  isBreakoutSession?: boolean;
  isBreakoutSession5?: boolean;
  isOpen?: boolean
}

const CompanyDetails: React.FC<CompanyDetailsPropsProps> = ({
  company,
  hideWebsiteButton,
  mb,
  isBreakoutSession,
  isBreakoutSession5,
  isOpen

}) => {
  const isCompanyLogo = company?.logo.includes('null');

  const goToWebSite = () => {
    if (!company?.website) return;

    // First remove /wall/ if it exists
    const cleanUrl = company?.website.replace('/wall/', '');

    // Check if the cleaned URL starts with http or //
    const hasProtocol = cleanUrl.startsWith('http') || cleanUrl.startsWith('//');

    // Add https:// if no protocol exists
    const finalUrl = hasProtocol ? cleanUrl : `https://${cleanUrl}`;

    window.open(finalUrl, '_blank')?.focus();
  };

  const logoDominantColor = company?.dominantColor;
  const backgroundColor = isNearToBlackColor(logoDominantColor)
    ? 'bg-white'
    : 'bg-[#14161A]';

  return (
    <div
      className={classNames('flex items-center justify-left w-full min-w-[285px] max-w-[285px]', {
        'pb-[0px]': !mb,
        '!pb-[19px]': isBreakoutSession,
        '!w-full !pb-0': isBreakoutSession5
      })}
    >
      <div
        className={classNames(
          'w-[70px] min-w-[70px] h-[70px] max-h-[70px] rounded-[10px] overflow-hidden',
          backgroundColor
        )}
      >
        <img
          className="w-full h-full object-contain rounded-[10px]"
          src={isCompanyLogo ? SponsorDefault : company?.logo}
          alt={company.name}
        />
      </div>
      <div className={classNames(
        'ml-[17px] flex flex-col',
        {
          '!w-full': isBreakoutSession5,
          hidden: isBreakoutSession && !isOpen
        }
      )}
      >
        <div className="text-white md:leading-[23px] leading-[19px] md:text-[17px] text-[14px] font-semibold helvetica-neue-48-medium text-left line-clamp-1 mb-1">
          {company.name}
        </div>
        <div className="text-white md:leading-[14px] leading-[13px] md:text-[13px] text-[12px] font-normal helvetica-neue-55-roman text-left line-clamp-1">
          {company.industry}
        </div>
        <div className={classNames(
          'flex sm:flex-row flex-col sm:items-center gap-[8px] mt-[10px]',
          {
            '!flex-wrap': isBreakoutSession5
          }
        )}
        >
          <button
            type="button"
            className={classNames(
              'text-black bg-[#F1B01A] leading-[21px] rounded-[3px] h-[21px] w-[136px] px-1 text-center text-[12px] helvetica-neue-34-light font-medium'
            )}
            onClick={() => window.open(company?.companyPage, '_blank')}
          >
            Company Page
          </button>
          {!hideWebsiteButton ? (
            <button
              type="button"
              className={classNames(
                'text-black bg-[#ffffff] leading-[21px] rounded-[3px] h-[21px] w-[93px] px-1 text-center text-[12px] helvetica-neue-34-light font-medium'
              )}
              onClick={goToWebSite}
            >
              Website
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
