import { HyperLink } from '../../../shared-components';

const WebsiteEventFooter = () => (
  <div className="z-50 inset-x-0 bottom-0 text-center my-2.5">
    <div className="md:max-w-[91%] max-w-[97%] mx-auto xl:flex xl:items-center xl:justify-between justify-center">
      <div className="flex items-left justify-center lg:justify-start xl:mb-0 mb-3">
        <div className="text-[15px] leading-5 font-[300] text-white helvetica-neue-35-thin-wide">
          Copyright ©
          {' '}
          {new Date().getFullYear().toString()}
          {' '}
          CXO Sit-Down Pty Ltd. All rights reserved.
        </div>
      </div>
      <div className="lg:flex-row flex-col font-normal flex items-center xl:justify-start gap-x-[5px] lg:gap-y-[5px] justify-center">
        <HyperLink
          classes="font-medium text-[15px] text-[#ffffff] hover:underline helvetica-neue-35-thin-wide"
          variant="FooterLink"
          text="Privacy Policy"
          href="https://www.sit-down.com/privacy-policy"
        />
        <div className="mx-5px text-[15px] text-white invisible lg:visible">|</div>
        <HyperLink
          classes="font-medium text-[15px] text-[#ffffff] hover:underline helvetica-neue-35-thin-wide"
          variant="FooterLink"
          text="Use of Cookies"
          href="https://www.sit-down.com/hybrid-events/sit-down-cdo-cio-ciso#"
        />
        <div className="mx-5px text-[15px] text-white invisible lg:visible">|</div>
        <HyperLink
          classes="font-medium text-[15px] text-[#ffffff] hover:underline helvetica-neue-35-thin-wide"
          variant="FooterLink"
          text="Sales & Refunds"
          href="https://www.sit-down.com/hybrid-events/sit-down-cdo-cio-ciso#"
        />
        <div className="mx-5px text-[15px] text-white invisible lg:visible">|</div>
        <HyperLink
          classes="font-medium text-[15px] text-[#ffffff] hover:underline helvetica-neue-35-thin-wide"
          variant="FooterLink"
          text="Terms & Conditions"
          href="https://www.sit-down.com/terms-conditions"
        />
      </div>
    </div>
  </div>
);

export default WebsiteEventFooter;
