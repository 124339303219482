import { FC } from 'react';
import { Link, LinkProps, To } from 'react-router-dom';

/**
 * often a link is rendered when there is no URL available. In this case maybe
 * we should not render a link - this is a ConditionalLink
 */
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type LinkWithoutToProps = Omit<LinkProps, 'to'>;
type ConditionalLinkProps = LinkWithoutToProps & {
  to: To | undefined;
};

export const ConditionalLink: FC<ConditionalLinkProps> = ({ to, children }) => {
  return to ? <Link to={to}>{children}</Link> : <>{children}</>;
};
