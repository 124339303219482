export enum SurveyItemType {
  TEXT = "text",
  EMAIL = "email",
  PHONE = "phone",
  DROPDOWN = "radio",
  MULTIPLE = "select",
  TERM_AND_CONDITION = "terms-and-conditions",
  INDUSTRY = "industry",
  JOBTITLE = "job-title",
  CHECKBOX = "checkbox",
  CUSTOM = "custom-fields",
  BUSINESS = "business-email",
  SLIDER = "step-slider",
  Radio = "Radio",
  TECHNOLOGY_INVESTMENT = "technology-investment",
  TARGET_DEPARTMENT = "target-department",
  ORGANIZATION_SIZE = "organization-size",
  Session = "session",
  Day = "day",
  Room = "room",
  SLIDER_ARRAY = 'step-slider-array',
  RADIO_BOX_ARRAY = "radio-box-array",
  CONSTANT_SUM_PERCENTAGE = 'constant-sum-percentage',
  Rank = "rank"
}

export enum RegoFormColors {
  primaryBackgroundColor = "#2D2D2D",
  primaryFontColor = "#FFFFFF",
  secondaryBackgroundColor = "#111111",
  secondaryFontColor = "rgba(220, 220, 220, 1)",
  fieldBorderColor = "rgba(255, 255, 255, 0.15)",
  focusColor = "rgba(0, 255, 205, 1)",
  labelBackGroundColor = "#111111",
  labelFontColor = "rgba(220, 220, 220, 1)",
}

export enum RegoFormDefaultColors {
  primaryBackgroundColor = "#749CFB",
  primaryFontColor = "#FFFFFF",
  // secondaryBackgroundColor = '#EDF2F5',
  secondaryBackgroundColor = "#FFFF",
  // secondaryFontColor = '#3C3C3C',
  secondaryFontColor = "black",
  // fieldBorderColor = '#CDDCF2',
  fieldBorderColor = "#90959B",
  // focusColor = '#2684ff',
  focusColor = "#1111",
  // labelBackGroundColor = '#EDF2F5',
  labelBackGroundColor = "#ffff",
  labelFontColor = "rgb(145, 158, 171)",
}

export enum RegoFormLenovoColors {
  primaryBackgroundColor = "#749CFB",
  primaryFontColor = "#FFFFFF",
  // secondaryBackgroundColor = '#EDF2F5',
  secondaryBackgroundColor = "#3C3C3C",
  // secondaryFontColor = '#3C3C3C',
  secondaryFontColor = "black",
  // fieldBorderColor = '#CDDCF2',
  fieldBorderColor = "#707070",
  // focusColor = '#2684ff',
  focusColor = "#707070",
  // labelBackGroundColor = '#EDF2F5',
  labelBackGroundColor = "#3C3C3C",
  labelFontColor = "#ffffff",
}

export enum SessionMeetingStatus {
  WAITING = "waiting",
  INPROGRESS = "inprogress",
  APPROVED = "approved",
  DENIED = "denied",
}

export enum EventWebsiteTemplateType {
  SPECTRE = "spectre",
  LENOVO = "lenovo",
  DEFAULT = "default",
  AFTER_HOURS = "afterHours",
  BOX = "box",
}

export type SurveyQuestionsForm = {
  id: string;
  eventId: string;
  eventQuestionPageId: string;
  eventRoomId: string | null;
  fieldType: string;
  fieldName: string;
  isRequired: boolean;
  prefix: string;
  ordering: number;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
  room: {
    id: string;
    name: string;
  } | null;
  surveyOptionFields: any[];
  surveyFlow: any[];
  surveyFlowAction: any[];
  shuffleOptions?:boolean;
  limitTo?:number

};

export type SurveyProps = {
  open: boolean;
  surveySubPage: string;
  setOpenSurveyForm: (value: boolean) => void;
  title: string;
  userId: string;
  onLogout: () => void;
  setCurrentSurveyStep: (value: number) => void;
  currentSurveyStep: number;
  getCurrentSurveyPage: (value: any, allPagesIds?: any) => void;
  setStepName: (value: string) => void;
  setStepDescription: (value: string) => void;
  setSurveySubPage: (value: string) => void;
  setRequiredQuestionsFilled: (value: boolean) => void;
  setIsSuveryorRegSkipable: (value: boolean) => void;
  onSurveyCompleted: VoidFunction;
};

export type Output = {
  [key: string]: {
    type: string;
    obj?: { [key: string]: SurveyQuestionsForm[] } | any[];
    tabs?: string[];
    question?: SurveyQuestionsForm;
  }[];
};

export type Page = {
  id: string;
  eventSurveyFields: SurveyQuestionsForm[];
};

export type MeetingRef = {
  handleSetMeetingSlot: Function;
  handleBackToAttendeesList: Function;
};

export interface Options {
  label: string;
  value: string;
}

export type SurveyQuestionsPage = {
  backgroundColor: string;
  createdAt: string;
  description: string;
  eventId: string;
  fontColor: string;
  eventRegistrationFields: any[];
  eventSurveyFields: any[];
  id: string;
  media: string;
  name: string;
  ordering: number;
  type: string;
  updatedAt: string;
  isMediaExpanded: boolean;
};

export type surveyPageDropdown = {
  id: string;
  label: string;
};

export type SurveyQuestionwithAnswer = {
  answer: string | any[] ;
  id: string;
  userSurveyField: {
    surveyFieldId: string;
    surveyField: {
      fieldType: string;
      fieldName: string;
    };
  };
  userSurveyFieldId?: string;
};

export type SurveyOptionField = {
  id?: string | null;
  surveyFieldId?: string;
  optionName: string | null;
};

export const jobFunctionsOptions = [
  { id: 'Accounting & Admin', name: 'Accounting & Admin' },
  { id: 'Sales', name: 'Sales' },
  { id: 'Back Office', name: 'Back Office' },
  { id: 'Human Resources', name: 'Human Resources' },
  { id: 'Procurement', name: 'Procurement' },
  { id: 'Marketing & PR', name: 'Marketing & PR' },
  { id: 'Product & Production', name: 'Product & Production' },
  { id: 'Research', name: 'Research' },
  { id: 'IT', name: 'IT' },
  { id: 'Data & Analytics', name: 'Data & Analytics' },
  { id: 'IT Security', name: 'IT Security' },
  { id: 'Service & CX', name: 'Service & CX' },
  { id: 'DevOps', name: 'DevOps' },
  { id: 'Finance', name: 'Finance' },
  { id: 'Legal', name: 'Legal' },
];

