/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from 'classnames';
import React, { ChangeEvent, useRef, useState } from 'react';
import { IDropDownMenuItems } from '../..';
import { ArrowDownSvgIcon } from '../../../../shared-components/V2/IconSvg';
import useCloseMenuWithClickOutside from '../../../../utils/hooks/closeMenuWithClickOutside';

interface IDropDownEventButtonProps {
  menuItems: IDropDownMenuItems[];
  title: string;
  IsReset?: boolean;
  iconUrl?: string;
  type?: string;
  inputPlaceholder?: string;
  showResultsClick?: Function;
  showResultInputSearch?: Function;
  className?: string;
  dropdownClass?: string;
  btnTextnClass?: string;
  buttonClass?: string;
  handleSelected?: Function;
  setIsShowFilter?: Function;
  containerClass?: string;
}

const DropDownEventButton = ({
  menuItems,
  title,
  IsReset,
  iconUrl,
  type,
  inputPlaceholder,
  showResultsClick,
  showResultInputSearch,
  className,
  dropdownClass,
  btnTextnClass,
  buttonClass,
  handleSelected,
  setIsShowFilter,
  containerClass
}: IDropDownEventButtonProps) => {
  const ref = useRef<any>();
  const [displayMenu, setDisplayMenu] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const [selectedItemCount, setSelectedItemCount] = useState(0);

  const { showDropdown, handleDropdown } = useCloseMenuWithClickOutside(ref);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, item: any) => {
    const { checked } = e.currentTarget;
    if (checked) {
      if (handleSelected) { handleSelected(true); }
      setSelectedItems([...selectedItems, item]);
      setSelectedItemCount(selectedItemCount + 1);
    } else {
      setSelectedItemCount(selectedItemCount - 1);
      setSelectedItems(selectedItems.filter(a => item.id !== a.id));
    }
  };

  const handleShowResult = (selectedItems: any) => {
    if (showResultsClick) showResultsClick(type, selectedItems);
  };

  const handleInputChange = (e: any) => {
    if (showResultInputSearch) showResultInputSearch(e.currentTarget.value);
  };

  const cancelFilter = () => {
    setSelectedItems([]);
    setSelectedItemCount(0);
    if (setIsShowFilter) setIsShowFilter(false);
    if (showResultsClick) showResultsClick(title, []);
  };

  const handleDropdownButton = () => {
    handleDropdown(showDropdown);
  };

  if (IsReset) {
    if (selectedItems.length > 0) {
      setSelectedItems([]);
      setSelectedItemCount(0);
    }
  }

  function getSelectedItemCount() {
    if (IsReset) {
      return '';
    }

    return (selectedItemCount === 0) ? '' : selectedItemCount;
  }

  return (
    <div
      className={classNames('relative', {
        [`${containerClass}`]: containerClass,
      })}
    >
      <button
        type="button"
        onClick={handleDropdownButton}
        // eslint-disable-next-line max-len
        className={classNames('flex flex-row justify-between items-center h-[30px] rounded-[5px] text-xs border-[#7283A4] border-1 border-solid pl-[14px] pr-[15px] focus:outline-none focus:shadow-solid', {
          'bg-[#0049EF] hover:bg-[#244E93]': getSelectedItemCount(),
          'hover:bg-[#DFE5EB]': !getSelectedItemCount(),
          [`${buttonClass}`]: buttonClass,
        })}
      >
        <div className="flex items-center w-full">
          <div className="fs-12 font-light" />
          <div className="flex w-full justify-between">
            <div
              className={classNames('mr-3 text-[13px] leading-[15px] font-medium ', {
                'text-white': getSelectedItemCount(),
                'text-[#7283A4]': !getSelectedItemCount(),
                [`${btnTextnClass}`]: btnTextnClass,
              })}
            >
              {title}
            </div>
          </div>
          <div
            className={classNames('ml-1 flex justify-center items-center bg-white rounded-full mr-1', {
              'w-[19px] min-w-[19px] min-h-[19px] h-[19px]': getSelectedItemCount(),
            })}
          >
            {
              !!getSelectedItemCount() && (
              <span className="text-[10px] font-normal leading-[13px] text-[#3C3C3C]">
                {getSelectedItemCount()}
              </span>
              )
            }
          </div>
          {iconUrl && <ArrowDownSvgIcon className={classNames('w-[19px]', { })} fillColor={getSelectedItemCount() ? '#ffffff' : '#7283A4'} /> }
        </div>
      </button>

      {showDropdown && (
        <div
          ref={ref}
          className={classNames(
            'bg-white opacity-100 z-50 absolute',
            { [`${dropdownClass}`]: dropdownClass },
          )}
        >
          {showResultInputSearch && (
            <div className="hidden">
              <button
                type="button"
                className="rounded-md border bg-close-icon hover:bg-close-hover bg-no-repeat border-transparent px-4 w-7 h-8 text-sm font-medium outline-none"
                onClick={handleDropdownButton}
              />
            </div>
          )}
          <div className={
            classNames(
              'overflow-scroll overflow-x-hidden event-dropdown-srcoll',
              { [`${className}`]: className },
            )
          }
          >
            <ul className="">
              {showResultInputSearch && (
                <input
                  name="search"
                  className="w-full h-[30px] px-[13.5px] mb-2.5 text-[12px] leading-[17px] rounded-10 font-light focus:outline-none block bg-[#EDF2F5] text-[#3C3C3C] placeholder:text-[#3C3C3C]"
                  placeholder={inputPlaceholder}
                  onChange={e => handleInputChange(e)}
                  autoComplete="off"
                />
              )}
              {menuItems?.map((item, index) => {
                if (type === 'checkbox') {
                  return (
                    <div className="flex items-center column relative mb-[14px] hover:underline" key={index}>
                      <span className="mt-[1px]">
                        <input
                          type="checkbox"
                          checked={selectedItems.some(a => a.id === item.id)}
                          onChange={e => handleChange(e, item)}
                          id={`check ${index}`}
                        />
                      </span>
                      <label
                        className="text-[12px] leading-[18px] font-normal text-[#3F4D67] pl-2 line-clamp-1 cursor-pointer"
                        htmlFor={`check ${index}`}
                      >
                        {item.name}
                      </label>
                    </div>
                  );
                } if (type === 'button') {
                  return (
                    <div className={classNames('rounded-[10px] hover:bg-[#EDF2F5] px-2 py-1', { 'bg-[#EDF2F5]': item.isSelected })}>
                      <button
                        type="button"
                        key={`item-${index}`}
                        onClick={() => {
                          item.onClick();
                          setDisplayMenu(!displayMenu);
                        }}
                        className="text-sm leading-5 text-[#172746] rounded-[5px] tracking-wide"
                      >
                        <li className="text-sm leading-5 text-[#172746] rounded-[5px] tracking-wide">{item.name}</li>
                      </button>
                      <br />
                    </div>
                  );
                }
                // eslint-disable-next-line react/jsx-no-useless-fragment
                return <></>;
              })}
            </ul>
          </div>
          {showResultsClick && (
            <>
              <div className="border-t-1 border-[#EDF2F5]" />
              <div className="flex flex-row justify-end m-[10px]">
                <div className="flex items-center font-semibold text-[13px] text-[#203C6E] justify-center mr-1 h-[30px] w-[88px] rounded-[5px] bg-[#EDF2F5] hover:bg-[#CEDCF2] tracking-wide">
                  <button type="button" onClick={() => cancelFilter()}>Clear</button>
                </div>
                <div className="flex items-center font-semibold text-[13px] text-white justify-center h-[30px] w-[76px] rounded-[5px] bg-[#0049EF] hover:bg-[#2e6eff]">
                  <button type="button" onClick={() => handleShowResult(selectedItems)}>Show</button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DropDownEventButton;
