import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { IEvent } from '../../../../models/user-events';
import { IAppState } from '../../../../store';
// import { init } from '../../../../store/event-website';
// import { useCurrentUser } from '../../../../utils/hooks';
import FinalMessageContent from '../../registration/event-components/final-message-content';
import WebsiteEventQuickLinks from '../website-event-quicklinks';

export const EventFinalMessage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch();
  // const user = useCurrentUser();

  const eventSlug = location?.pathname?.split('/')[2];

  useEffect(() => {
    const eventStep = sessionStorage.getItem('eventRegStep');
    if (eventStep === '3') {
      navigate(`/event/${eventSlug}/spectre/successfully-registered`);
    } else {
      navigate(`/event/${eventSlug}/spectre`);
    }
  }, [eventSlug, navigate]);

  // useEffect(() => {
  //   dispatch(init(eventSlug));
  // }, [eventSlug, dispatch, user]);

  const event: IEvent = useSelector((state: IAppState) => state.website.event!);

  const onBackEvent = () => {
    navigate(`/event/${eventSlug}/spectre`);
    sessionStorage.removeItem('eventRegStep');
  };
  return (
    <div className="w-full">
      <div className="flex -mb-[30px]">
        <div className="bg-[#2d2d2d] pt-[20px] pr-[40px] pb-[30px] pl-[40px] w-full md:max-w-[60%]">
          <div
            className="w-[122px] h-[32px] rounded-full bg-white text-black text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer mb-[40px]"
            onClick={onBackEvent}
          >
            Back to event
          </div>
          <div className="mt-[90px] mb-[100px]">
            {event?.id && <FinalMessageContent eventId={event?.id} isSpectreForm />}
          </div>
        </div>
        <div className="reg-bg md:w-[40%]" />
      </div>
      <WebsiteEventQuickLinks />
    </div>
  );
};
export default EventFinalMessage;
