import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/images/Panelist-Search-logo.svg';
import { getSearchResults, setIsLoading } from '../../../store/navBarSearch/actions';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { TextInput } from '../../../shared-components';

interface ISearchboxProps {
  setIsSuggestion: Function;
  setRecentSearchItems: Function;
  setSearch: Function;
  setIsSearchDropDown: Function;
  recentSearchItems: any;
}

interface IMessageInputForm {
  SearchText: string;
}

export const Searchbox = (props: ISearchboxProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, reset } =
    useForm<IMessageInputForm>();

  const [recentItems, setRecentItems] = useState<any[]>([]);


  const [value, setValue] = useState<string>('');

  const onChangeHandler = async (value: string) => {
    props.setIsSuggestion(false);
    props.setSearch(value);
    props.setIsSearchDropDown(true);
    dispatch(setIsLoading(true));
    dispatch(getSearchResults(value));
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (recentItems.length > 0) {
        navigate(`/search?search=${recentItems}`);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [recentItems]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (value.length > 0) {
        onChangeHandler(value);
      };
    }, 600);

    return () => clearTimeout(timer);
  }, [value]);

  const onFocusHandler = async (value: string) => {
    props.setIsSuggestion(true);
  };

  const onPressEnter = async (e: any) => {
    props.setIsSearchDropDown(false);
    if (e.key === 'Enter') {
      const { value } = e.target
      if (value.length > 0) {
        props.setRecentSearchItems([...props.recentSearchItems, value]);
        setRecentItems(value);
      }
      if (value.length <= 0) {
        navigate('/search?search=');
      }
      reset();
    }

  };

  const onSubmit = (data: IMessageInputForm) => {
    if (data.SearchText) {
      navigate(`/search?search=${data.SearchText}`);
    }
  };

  if (props?.recentSearchItems.length > 0) {
    const recentSearchList: any = JSON.parse(localStorage.getItem('recentSearchList')!);
    if (recentSearchList?.length > 0) {
      const totalRecentSearch = recentSearchList?.concat(props?.recentSearchItems);
      const recentSearch = Array.from(new Set(totalRecentSearch));
      localStorage.setItem('recentSearchList', JSON.stringify(recentSearch));
    } else {
      localStorage.setItem('recentSearchList', JSON.stringify(props?.recentSearchItems));
    }
  }

  return (
    <>
      <div className="flex flex-row relative">
        <div className="z-20 absolute left-[2.9px] top-[0px] md:h-[40px] h-[30px] flex items-center">
          <Link to="/">
            <Logo className="md:w-[35px] md:h-[35px] w-[25px] h-[25px] rounded-5" />
          </Link>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-gray-1 rounded-20 md:w-[287px] w-[240px] z-10 md:h-[40px] h-[30px]">
            <TextInput
              textinputsize="small"
              placeholder="Search"
              type="text"
              characterlength={120}
              // inputId="tagline"
              className="pl-12 py-1 w-full bg-gray-1 hover:bg-[#DFE5EB] active:bg-[#DFE5EB] text-md2 font-normal rounded-[8px] md:h-[40px] h-[30px] placeholder:text-[#626262] focus-visible:outline-none"
              {...register("SearchText")}
              onFocus={(e: { target: { value: string; }; }) => onFocusHandler && onFocusHandler(e.target.value)}
              onChange={(e: any) => setValue(e.target.value)}
              autoComplete="off"
              onKeyDown={(e: any) => onPressEnter(e)}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Searchbox;


