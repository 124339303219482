import { useState } from 'react';

import stepDown from '../../../assets/images/step-down.svg';
import stepUp from '../../../assets/images/step-up.svg';
import AlertModal from '../../../components/alert-modal/alert-modal';
import { ISelectedTicket } from '../../../models/tickets';
import { Tooltip } from '../../Tooltip';
import { TicketService } from 'src/services/Tickets';
import { Button } from '@mui/material';

export interface ITicketCardProps {
  id: string;
  currency: string;
  eventId: string;
  userId: string;
  title: string;
  price: number;
  description: string;
  availability?: string;
  quantity: number;
  ticketPolicy: string;
  inclusions: any;
  onIncrement?: Function;
  onDecrement?: Function;
  onBuyTicket?: (value: string) => void;
  purchasedTickets?: any;
  userTicketPurchased?: any
}
const TicketCardRego = ({
  id,
  currency,
  eventId,
  userId,
  title,
  price,
  description,
  availability,
  quantity,
  ticketPolicy,
  inclusions,
  onIncrement,
  onDecrement,
  onBuyTicket,
  purchasedTickets,
  userTicketPurchased
}: ITicketCardProps) => {
  const ticketService = new TicketService();
  const [numberOfTickets, setNumberOfTickets] = useState(quantity);
  const [showTicketPolicy, setShowTicketPolicy] = useState(false);

  return (
    // <div className="w-257 rounded-10 bg-white shadow-md">
    <div className="flex flex-col items-center px-[10px] py-[20px]">
      <div className="text-md2 text-gray-1 leading-5 font-bold">{title}</div>
      <div className="flex flex-row mt-3">
        <div className="text-sm1 text-gray-1 font-light leading-5">$</div>
        <div aria-label="price-target" className="text-lg2 text-blue-4 leading-4 font-bold">
          {!!price ? parseFloat(`${price / 100}`).toFixed(2) : 0} {currency}
        </div>
      </div>
      <div aria-label="description-target" className="text-sm2 text-gray-16 font-light leading-5 mt-3 text-center">
        {description}
      </div>
      <div aria-label="availability-target" className="text-sm2 text-gray-16 font-normal leading-5 w-162 mt-3 text-center">
        {availability}
      </div>
      <div className="flex flex-row mt-3">
        <div className="mr-1">
          <input
            type="text"
            disabled
            value={numberOfTickets}
            className="w-8 border-1 border-gray-2 rounded-10 text-center p-1"
            autoComplete="off"
          />
        </div>
        <div className="w-4 border-1 border-gray-2 rounded-10">
          <div className="flex flex-col">
            <div
              className="cursor-pointer"
              onClick={() => {
                if (!availability || parseInt(availability!) > numberOfTickets) {
                  let ticketsCount = numberOfTickets + 1;
                  setNumberOfTickets(ticketsCount);
                  let ticket: ISelectedTicket = {
                    ticketId: id,
                    ticketName: title,
                    eventId,
                    userId,
                    numberOfTickets: ticketsCount,
                    price,
                  };
                  onIncrement && onIncrement(ticket);
                }

              }}
              aria-label="increment-target"
            >
              <img src={stepUp} alt="" className="w-4 h-4" />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="1"
              viewBox="0 0 16 1"
            >
              <line
                id="Line_1394"
                data-name="Line 1394"
                x2="16"
                transform="translate(0 0.5)"
                fill="none"
                stroke="#d4d9de"
                strokeWidth="1"
              />
            </svg>
            <div
              className="cursor-pointer"
              onClick={() => {
                let ticketsCount = numberOfTickets - 1;

                if (ticketsCount > 0) setNumberOfTickets(ticketsCount);
                else setNumberOfTickets(0);
                onDecrement && onDecrement(id);
              }}
              aria-label="decrement-target"
            >
              <img src={stepDown} alt="" className="w-4 h-4" />
            </div>
          </div>
        </div>
      </div>
      {
        !!ticketPolicy && <>
          <Button sx={{ padding: "2px", color: "#285cb2" }} disabled={((purchasedTickets && purchasedTickets[id]) || userTicketPurchased) ? true : false} onClick={() => { if (onBuyTicket) onBuyTicket(id) }}>
            {((purchasedTickets && purchasedTickets[id]) || userTicketPurchased) ? "Purchased" :
              "Buy Now"}
          </Button>
          {/* <Button
            className='p-2 text-[#285CB2] font-semibold hover:underline cursor-pointer'
            size="small"
            text=   
           
          /> */}

          {/* <div className='p-2 text-[#285CB2] font-semibold hover:underline cursor-pointer' onClick={() => 


        { if(onBuyTicket)     onBuyTicket(id)}

            
            
            
            }>
       {   purchasedTickets[id] ? "Purchased"  :   
              "Buy Now"}
              
              </div> */}
          {/* <AlertModal isOpen={showTicketPolicy} onClose={() => { setShowTicketPolicy(false) }} className="w-1/5">
              <div className='p-2 font-semibold'>Ticket Policy</div>
              <div className='border-1 border-gray-2'></div>

              <div className='px-2 py-4 text-sm'>{ticketPolicy}</div>
            </AlertModal> */}
        </>
      }
      <div className="mt-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="237"
          height="0.5"
          viewBox="0 0 237 0.5"
        >
          <line
            id="Line_1392"
            data-name="Line 1392"
            x2="237"
            transform="translate(0 0.25)"
            fill="none"
            stroke="#9f9f9f"
            strokeWidth="0.5"
          />
        </svg>
      </div>
      <div className="flex flex-col p-2 pb-0 items-center">
        {(typeof inclusions === "string" ? inclusions?.split(',') : inclusions)?.map((item: any, index: number) => (
          <div
            className="text-sm1 leading-relaxed font-medium"
            key={`item-${index}`}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
    // </div>
  );
};

export default TicketCardRego;
