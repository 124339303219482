import { EventsNavbar, Navbar } from '../../../components/navbar';
import Footer from '../../home/footer';
import AttractAudience from './attract-audience';
import Banner from './banner';
import GetStarted from './get-started';
import GrowYourCommunity from './grow-your-community';
import HoldEvents from './hold-events';
import HostingRightEvent from './hosting-right-event';
import PanelistUsers from './panelist-users';
import ScaleYourAttendance from './scale-your-attendance';
import StayConnected from './stay-connected';
import UserExperience from './user-experience';

const menuItems = [
  {
    menuItemText: 'Overview',
    href: '/overview',
    isSelected: true
  },
  {
    menuItemText: 'Hybrid Events',
    href: '/hybrid-events',
    isSelected: false
  },
  {
    menuItemText: 'Virtual Events',
    href: '/virtual-events',
    isSelected: false
  },
  {
    menuItemText: 'Features & Packages',
    href: '/features-and-packages',
    isSelected: false
  }
];

export const Overview = () => (
  <>
    <Navbar />
    <div className="mx-auto">
      <EventsNavbar menuItems={menuItems} showCreateEventButton />
      <div className="w-full mx-auto">
        <div className="">
          <Banner />
          <GrowYourCommunity />
          <HoldEvents />
          <AttractAudience />
          <ScaleYourAttendance />
          <StayConnected />
          <UserExperience />
          <HostingRightEvent />
          <PanelistUsers />
          <GetStarted />
          <Footer />
          {' '}
          {/* note: Footer caused broken in mobile- need to fix */}
        </div>
      </div>
    </div>
  </>
);

export default Overview;
