/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/button-has-type */
import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { IEvent } from 'src/models/user-events';
import RegistrationStepForm from 'src/mui/pages/event/reg-dialog-flow';
import { IAppState } from 'src/store';
import { useCurrentUser } from 'src/utils/hooks';
import { isWithinEventWindow } from 'src/mui/components/hook-form/Common';
import { FACTORSTAB, FACTORSTABS, FACTORSTABSURVEY } from './constants';
import MenuIcon from './images/menu-icon.svg';

interface NavbarProps {
  selectedTab: string;
  setSelectedTab: (value: string) => void;
}

const FactorWebsiteNavbar: React.FC<NavbarProps> = ({
  selectedTab,
  setSelectedTab,
}) => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const user = useCurrentUser();

  // component states
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // redux state
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  // const isEventDateWithin60Days = moment(event?.startTime).diff(moment(), 'days') <= 80;

  const isEventDateWithin80Days = isWithinEventWindow({
    date: event?.startTime,
    status: event?.status
  });

  const survey: any = useSelector((state: IAppState) => state.website.survey!);

  const audienceStatus = useSelector(
    (state: IAppState) => state?.upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus
  );
  const { registered } = useSelector((state: IAppState) => state.website);
  const { upcomingEvent } = useSelector((state: IAppState) => state);
  const speakers = useSelector(
    (state: IAppState) => state.website?.event?.speakers
  );
  const partners = useSelector((state: IAppState) => state.website?.event?.eventSponsors!);

  // constants
  const isRegistered = upcomingEvent?.value?.data?.attendeeStatus?.isRegistered;

  // functions
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleRegistration = () => {
    if (!isRegistered) setShowModal(true);
    // if (!registered && event?.isTicketed && user) setOpenTicketModal(true);

    if (user && isRegistered) navigate(`/event/${event?.slug}/discussion`);
    if (
      user
      && !isRegistered
      && event?.type === 'invitation'
      && audienceStatus === 'accepted'
    ) {
      navigate(`/event/${event?.slug}/discussion`);
    }
  };
  const getTabs = () => {
    if (survey) return FACTORSTABSURVEY;
    const hasSessions = event?.rooms?.length && event?.rooms?.some((room:any) => room?.sessions?.length);
    const hasSpeakers = speakers?.length;
    const hasSponsors = partners?.length;

    const tabs = [...FACTORSTABS];

    if (hasSessions && !tabs.includes(FACTORSTAB.PROGRAM)) {
      tabs.splice(2, 0, FACTORSTAB.PROGRAM);
    }

    if (hasSpeakers && !tabs.includes(FACTORSTAB.SPEAKERS)) {
      const programIndex = tabs.indexOf(FACTORSTAB.PROGRAM);
      const insertIndex = programIndex !== -1 ? programIndex + 1 : 2;
      tabs.splice(insertIndex, 0, FACTORSTAB.SPEAKERS);
    }

    if (hasSponsors && !tabs.includes(FACTORSTAB.SPONSORS)) {
      const speakersIndex = tabs.indexOf(FACTORSTAB.SPEAKERS);
      const programIndex = tabs.indexOf(FACTORSTAB.PROGRAM);
      const insertIndex = speakersIndex !== -1 ? speakersIndex + 1 : (programIndex !== -1 ? programIndex + 1 : 3);
      tabs.splice(insertIndex, 0, FACTORSTAB.SPONSORS);
    }
    return tabs;
  };

  const getTabTitle = (tab: string) => {
    switch (tab) {
      case FACTORSTAB.SPEAKERS:
        return isEventDateWithin80Days ? FACTORSTAB.SPEAKERS : FACTORSTAB.AUDIENCES_2024;
      default:
        return tab;
    }
  };

  return (
    <div className="max-w-[1320px] px-[15px] flex mx-auto">
      <nav className="bg-[#14161A] relative w-full left-0 flex lg:flex-row md:flex-col gap-y-1 items-center justify-between py-[11px]">
        <div className="flex items-center">
          {/* Replace this part with your logo component */}
          <p
            onClick={() => {
              const queryParams = new URLSearchParams(location.search);
              queryParams.set('tab', 'Overview');
              navigate({
                pathname: location.pathname,
                search: queryParams.toString()
              });
            }}
            className="text-white text-[13px] font-medium cursor-pointer helvetica-neue-65-medium line-clamp-1"
          >
            {`${survey ? survey?.name : event?.name}`}
          </p>
        </div>
        <div className="hidden md:flex">
          {getTabs().map((item: any, index: number) => (
            <div
              key={index}
              className={classNames(
                'text-white px-[11px] !font-semibold rounded hover:bg-[#333333] text-[13px] helvetica-neue-45-light hover:underline whitespace-nowrap cursor-pointer',
                {
                  underline: item === selectedTab,
                }
              )}
              onClick={() => {
                const queryParams = new URLSearchParams(location.search);
                queryParams.set('tab', item);
                navigate({
                  pathname: location.pathname,
                  search: queryParams.toString()
                });
                setSelectedTab(item);
              }}
            >
              {getTabTitle(item)}
            </div>
          ))}
          {!survey && isRegistered ? (
            <>
              {' '}
              <button
                type="button"
                className={classNames(
                  'text-black bg-[#F1B01A] ml-[11px] rounded-[3px] h-[22px] px-[14px] text-center text-[12px] helvetica-neue-45-light'
                )}
                onClick={() => setShowModal(true)}
              >
                {event?.type === 'invitation' && audienceStatus === 'rejected'
                  ? 'Register Now'
                  : 'Portal'}
              </button>
            </>
          ) : (
            <button
              type="button"
              className={classNames(
                `${survey && 'hidden '}text-black bg-[#F1B01A] ml-[11px] !font-semibold rounded-[3px] h-[22px] px-[14px] text-center text-[13px] helvetica-neue-45-light`
              )}
              onClick={() => handleRegistration()}
            >
              Register Now
            </button>
          )}
        </div>
        <div className="md:hidden">
          <button onClick={toggleMobileMenu} className="text-white">
            <img
              className="w-[19px] h-[19px] md:w-[23px] md:h-[23px] mr-[6px]"
              src={MenuIcon}
              alt="menu-icon"
            />
          </button>
        </div>
        {isMobileMenuOpen && (
          <div className="absolute top-full font-semibold left-0 w-full bg-[#14161a] flex flex-col items-center py-[9px] md:hidden">
            {getTabs().map((item: any, index: number) => (
              <div
                key={index}
                className={classNames(
                  'text-white py-[6px] px-[12px] rounded hover:bg-gray-700 cursor-pointer',
                  {
                    underline: item === selectedTab,
                  }
                )}
                onClick={() => {
                  setSelectedTab(item);
                  setIsMobileMenuOpen(false); // Close the menu after selection
                }}
              >
                {getTabTitle(item)}
              </div>
            ))}

            {!survey && isRegistered ? (
              <>
                {' '}
                <button
                  type="button"
                  className={classNames(
                    'text-black bg-[#F1B01A] my-[8px] rounded-5 h-[21px] px-[18px] !font-semibold text-center text-[12px] helvetica-neue-45-light'
                  )}
                  onClick={() => setShowModal(true)}
                >
                  {event?.type === 'invitation' && audienceStatus === 'rejected'
                    ? 'Register Now'
                    : 'Portal'}
                </button>
              </>
            ) : (
              <button
                type="button"
                className={classNames(
                  'text-black bg-[#F1B01A] my-[8px] rounded-5 h-[21px] px-[18px] !font-semibold text-center text-[12px] helvetica-neue-45-light'
                )}
                onClick={() => handleRegistration()}
              >
                Register Now
              </button>
            )}
          </div>
        )}
        {showModal && (
          <RegistrationStepForm
            eventId={event?.id!}
            user={user}
            open={showModal}
            registered={registered!}
            eventInvitationStatus={audienceStatus!}
            eventType={event?.type!}
            setOpenForm={() => setShowModal(false)}
          />
        )}
      </nav>
    </div>
  );
};

export default FactorWebsiteNavbar;
