import { useState } from 'react';
import multitrackagenda from '../../../assets/images/feature/agenda.svg';
import attendeeexperience from '../../../assets/images/feature/attendance-experience.svg';
import eventinsights from '../../../assets/images/feature/insight.svg';
import expo from '../../../assets/images/feature/expo-sponsor.svg';
import interactiveworkshops from '../../../assets/images/feature/live-workshop.svg';

interface IEventsCarouselItem {
  title: string;
  description: string;
  imageUrl: string;
}

const carouselItems: IEventsCarouselItem[] = [
  {
    title: 'Multitrack Agenda',
    description:
      'Customise your event agenda with the main rooms, breakout rooms and interval sessions throughout your event',
    imageUrl: multitrackagenda
  },
  {
    title: 'A Seemless Attendee Experience',
    description:
      'Enable your attendees to switch between breakout rooms at any point during your event',
    imageUrl: attendeeexperience
  },
  {
    title: 'Interactive Live Workshops',
    description:
      'Run keynote presentations, panel discussions between your presenters and fully interactive workshops with your attendees.',
    imageUrl: interactiveworkshops
  },
  {
    title: 'Real-Time And Post-Event Insights',
    description:
      'Plan your post-event follow-ups with your attendees and stakeholders based on actionable insights and analytics in your dashboad.',
    imageUrl: eventinsights
  },
  {
    title: 'Expo',
    description:
      "Increase your sponsors' involvement by giving them a platform to engage with your attendees.",
    imageUrl: expo
  }
];

export const FeaturesCarousel = () => {
  const [selectedFeature, setSelectedFeature] = useState<IEventsCarouselItem>(carouselItems[0]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setSelectedFeature(carouselItems[index]);
  };

  return (
    <div className="flex flex-col items-center mt-14 pt-1">
      <div className="flex flex-col ">
        {/* <div className=''>{selectedFeature?.title}</div>
                <div className=''>{selectedFeature?.description}</div> */}
        <div className="mx-auto">
          <div className="text-lg3 font-extralight text-blue-2 text-center container mx-auto">
            {selectedFeature?.title}
          </div>
          <div className="text-md2 font-extralight text-gray-1 text-center mt-3 container mx-auto min-h-80">
            {selectedFeature?.description}
          </div>
          <img className="flex justify-center mx-auto" src={selectedFeature?.imageUrl} alt="" />
        </div>
      </div>
      <div className="flex flex-row text-xl8 font-extralight mt-5">
        {carouselItems.map((item, i) => (
          <button
            type="button"
            key={`tab-button-${i}`}
            className="mr-1"
            onClick={e => {
              handleClick(e, i);
            }}
          >
            {item.title === selectedFeature?.title ? '●' : '○'}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FeaturesCarousel;
