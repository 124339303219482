import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { CompanyService } from 'src/services';
import ProductLayout from './products-layout';
import { ProductsDropdown } from './ProductsDropdown';
import AllProducts from './AllProducts';

interface ProductTabProps {
  companyProducts: any[];
  companyId: string;
  getCompnayProductsData: Function;
  createProduct?: boolean;
}

export const ProductTab = ({
  companyProducts,
  companyId,
  getCompnayProductsData,
  createProduct
}: ProductTabProps) => {
  const [showProductLayout, setShowProductLayout] = useState<any>(false);
  const [companyProduct, setCompanyProduct] = useState<any>({}); const [isLoader, setIsLoader] = useState<boolean>(false);
  const [ProductId, setProductId] = useState<string | undefined>('');
  const handleAddClick = (value: string | undefined) => {
    setShowProductLayout(true);
    setProductId(value);
  };

  useEffect(() => {
    if (createProduct) {
      setShowProductLayout(true);
    } else {
      setShowProductLayout(false);
    }
  }, [createProduct]);

  useEffect(() => {
    const companyService = new CompanyService();
    const fetchProductData = async () => {
      if (ProductId) {
        try {
          const response = await companyService.getSepcificProduct(companyId, ProductId);
          if (response) {
            const productData = response?.data || {};
            setCompanyProduct(productData);
            setIsLoader(false);
          }
        } catch (error) {
          console.error('getSpecificCompnayProductData():', error);
        } finally {
          setIsLoader(false);
        }
      }
    };
    fetchProductData();
  }, [ProductId, companyId]);

  return (
    <Stack>
      {!showProductLayout ? (
        <AllProducts companyProducts={companyProducts} onAddClick={handleAddClick} companyId={companyId} getCompnayProductsData={getCompnayProductsData} />
      ) : (
        <Stack>
          { ProductId && <ProductsDropdown companyProducts={companyProducts} ProductId={ProductId} handleAddClick={handleAddClick} />}
          <ProductLayout
            companyProduct={companyProduct}
            companyId={companyId}
            ProductId={ProductId}
            setShowProductLayout={setShowProductLayout}
            getCompnayProductsData={getCompnayProductsData}
            setIsLoader={setIsLoader}
            isLoader={isLoader}
          />
        </Stack>
      )}
    </Stack>
  );
};
export default ProductTab;
