import { useEffect, useMemo, useRef, useState } from 'react';
import Picker from 'emoji-picker-react';
import { useForm } from 'react-hook-form';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { Transition } from '@headlessui/react';

import arrow from '../../../assets/images/arrow-down-icon.png';
import deleteIcon from '../../../assets/images/delete-comment-photo-icon.svg';
import { CommentSmileSvg } from '../../../shared-components/V2/IconSvg/CommentSmileSvg';
import { CommentFileSvg } from '../../../shared-components/V2/IconSvg/CommentFileSvg';
import { IAddCommentProps } from '../../../models/post/post';
import { Avatar } from '../../../shared-components/V2';
import { Logo } from '../../../shared-components/V2/Logo';
import { IAppState } from '../../../store';
import { uploadFile } from '../../../store/fileupload';
import {
  useCurrentUser,
  useUserDetails,
} from '../../../utils/hooks';
import useCloseMenuWithClickOutside from '../../../utils/hooks/closeMenuWithClickOutside';
import classNames from 'classnames';
import { UserDropdownSvgIcon } from '../../../shared-components/V2/IconSvg';
import _ from 'lodash';
import { UserSearchResult } from 'src/store/user/search-user';
import { UserService } from 'src/services';
import { useDebounce } from 'src/mui/hooks/use-debounce';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';
import { EmogiSvgIcon } from 'src/mui/assets/icons/EmogiSvgIcon';
import { UploadImageSvgIcon } from 'src/mui/assets/icons/UploadImageSvgIcon';

const pickerDefaultStyle = {
  position: "absolute",
  zIndex: "10",
  flex: "none",
  marginLeft: "-253px",
  top: "40px",
};

const pickerSpotlightStyle = {
  position: "absolute",
  zIndex: "10",
  flex: "none",
  marginLeft: "-253px",
  bottom: "40px",
};

interface ICommentForm {
  messageText: string;
  imageUrl: string;
  isBorderTop?: boolean;
}

const CreateComment = ({ user, company, onCommentPost, isBorderTop, setShowComments, isSpotlightDetailModal, isLivePage }: IAddCommentProps) => {
  const ref = useRef<any>();
  const colors = getBrandingColors();
  const {
    showDropdown: showEmojiDropdown,
    handleDropdown: handleEmojiDropdown,
  } = useCloseMenuWithClickOutside(ref);

  const { register, handleSubmit, setValue, getValues, watch } =
    useForm<ICommentForm>();
  const dispatch = useDispatch();

  const currentUser = useCurrentUser();
  const currentUserDetails = useUserDetails(currentUser);

  const [attachedFile, setAttachedFile] = useState<FormData | null>(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [canCommentAsCompany, setCanCommentAsCompany] = useState(false);
  const [commentingAsCompany, setCommentingAsCompany] = useState(false);

  const [mentionTags, setMentionTags] = useState<any>({});
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState<any[]>();
  const mentionRegex = /@\w+(?:\s+\w+)?/g;
  // const mentionRegex = /@(\w+)/g;

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { fileUpload, connections } = useSelector((state: IAppState) => state);
  const userService: UserService = new UserService();
  const debouncedInputValue = useDebounce(inputValue, 500);

  const getUsers = async (searchUser: string) => {
    const limit = 10;
    const result: UserSearchResult = await userService.getSearchUserResults(
      `${searchUser ?? ""}`,
      limit
    );
    setSuggestions([...result?.data?.users?.data]);
    setShowSuggestions(true);
  };

  const buttonRef = useRef<any>();
  const { showDropdown, handleDropdown } =
    useCloseMenuWithClickOutside(buttonRef);

  const handleCommentChange = (e: any) => {
    const newComment = e.target.value;
    setValue("messageText", newComment);
    const matches = newComment.match(mentionRegex);
    // const ownUserConnections = connections?.value?.data;
    if (matches) {
      // if (matches && ownUserConnections) {
        const mention = matches[0].substring(1);
        setInputValue(mention);
      // const filteredSuggestions = ownUserConnections.filter((user) =>
      //   user?.fullName.toLowerCase().includes(mention.toLowerCase())
      // );

      // setSuggestions(filteredSuggestions);
      // setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion: any) => {
    const currentComment = getValues("messageText");

    const mentionText = `${suggestion.firstName} ${suggestion.lastName}`;
    // const mentionText = `${suggestion.fullName}`;

    let newMentionTags = {
      [suggestion.id]: mentionText,
    };
    setMentionTags({ ...mentionTags, ...newMentionTags });
    const updatedComment = currentComment.replace(mentionRegex, mentionText);

    setValue("messageText", updatedComment);
    setShowSuggestions(false);
  };

  const onEmojiDropdown = () => {
    handleEmojiDropdown(showEmojiDropdown);
  };

  const handleFileSelected = (files: FileList) => {
    const data = new FormData();
    data.append("file", files[0]);
    setAttachedFile(data && data);
    const fileType = files[0].type.split("/")[0];

    if (fileType === "image") setSelectedFileType("image");
    else setSelectedFileType("document");

    dispatch(uploadFile(data, fileType === "image" ? "image" : "document"));
    if (!fileUpload.isUploading) {
      setSelectedFile(URL.createObjectURL(files[0]));
    } else {
      setSelectedFile("");
    }
  };

  const onInputPhotoClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    event.preventDefault();
    const fields = getValues();
    setValue("messageText", fields?.messageText + emojiObject.emoji);
  };

  const onSubmit = (data: ICommentForm) => {
    let newMessage = data.messageText;
    Object.keys(mentionTags).map((x: any) => {
      if (data.messageText.includes(mentionTags[x])) {
        const mentionText = `@{{User||${x}||${mentionTags[x]}}}`;
        newMessage = newMessage.replaceAll(mentionTags[x], mentionText);
      }
    });
    if (!commentingAsCompany) {
      onCommentPost(newMessage, attachedFile);
    } else {
      onCommentPost(newMessage, attachedFile, company?.id);
    }

    setValue("messageText", "");
    setSelectedFile("");
    setShowComments && setShowComments(true);
  };

  const handleMenuDropdown = () => {
    handleDropdown(showDropdown);
  };

  useEffect(() => {
    if (company) {
      const isAdmin =
        currentUserDetails.companyAdmin?.some(
          (adminCompany) => adminCompany.id === company.id
        ) || false;
      setCanCommentAsCompany(isAdmin);
    }
  }, [company]);

  useMemo(() => {
    if (debouncedInputValue) {
      getUsers(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
      input::placeholder {
        color: ${isLivePage ? colors?.Text : undefined};
      }
    `;
    document.head.appendChild(styleTag);
  
    // Clean up the style tag when the component is unmounted or isLivePage changes
    return () => {
      document.head.removeChild(styleTag);
    };
  }, [isLivePage]);

  if (!user && !company) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{ borderColor: isLivePage && isBorderTop ? colors?.Secondary : undefined }}
        className={classNames('w-full flex items-center', {
          'border-t-1 border-[#E2E2E2] pt-[10px]': isBorderTop,
          '!items-start': selectedFile !== "",
          '!pt-[16px]': isSpotlightDetailModal,
        })}
        
      >
        <div
          className={classNames('flex flex-row justify-between w-[38px] h-[38px] relative', {
            '!w-[36px] !min-w-[36px] !h-[36px] !min-h-[36px] !rounded-[14px]': isLivePage,
          })}
        >
          {!commentingAsCompany && (
            <Avatar
              size={isLivePage ? "XSMALL" : "SMALL"}
              src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${user.avatar}`}
              alt={`${user.firstName} ${user.lastName}`}
              className={classNames('!w-[38px] !h-[38px]', {
                '!w-[36px] !min-w-[36px] !h-[36px] !min-h-[36px] !rounded-[14px]': isLivePage,
              })}
            />
          )}
          {commentingAsCompany && (
            <Logo
              src={`${process.env.REACT_APP_IMAGE_URL_PREFIX
              }/${company?.logo!}`}
              className={classNames('w-[38px] h-[38px] object-contain rounded-xl', {
                '!w-[36px] !min-w-[36px] !h-[36px] !min-h-[36px]': isLivePage,
              })}
            />
          )}
          {canCommentAsCompany && (
            <div className="">
              <button
                type="button"
                className=" rounded-full items-center justify-center mt-4 absolute -right-[3px] bottom-0"
                aria-haspopup="true"
                aria-expanded={showDropdown}
                onClick={() => handleMenuDropdown()}
              >
                <UserDropdownSvgIcon className="" />
              </button>
              {showDropdown && (
                               <div ref={buttonRef} className="absolute z-10 w-[190px] min-w-[190px] -bottom-[1px] left-[25px]">
                  <Transition
                    show={showDropdown}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute origin-top-right rounded-md shadow-lg"
                  >
                    <div className="bg-white rounded-10 profile-avatar-shadow px-[6px] py-[6px]">
                      <div
                        className="text-left"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <ul className="w-full">
                          <li className="cursor-pointer rounded-[5px] flex items-center h-[27px] hover:bg-[#EDF2F5]">
                            <span
                              className="text-[#172746] text-[13px] leading-5 font-normal px-[7px] rounded-5"
                              onClick={() => setCommentingAsCompany(false)}
                            >{`${user.firstName} ${user.lastName}`}</span>
                          </li>
                          <li className="cursor-pointer rounded-[5px] flex items-center h-[27px] hover:bg-[#EDF2F5]">
                            <span
                              className="text-[#172746] text-[13px] leading-5 font-normal px-[7px] rounded-5"
                              onClick={() => setCommentingAsCompany(true)}
                            >
                              {company?.name}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Transition>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="w-full">
          <div className="flex flex-col">
            <div
              className={classNames("flex ml-[10px] relative", 
                {
                  "!ml-[5px]": isLivePage,
                }
                )}
            >
              <div
                className={classNames("w-full", 
                {
                  "rounded-[19px] bg-[#EDF2F5]": selectedFile !== "",
                }
                )}
              >
                {selectedFile !== "" && selectedFileType === "image" && (
                  <div className="flex flex-row w-[55px] h-[55px] rounded-10 relative mt-[15px] ml-[17px] mb-[5px]">
                    <div>
                      <img
                        src={selectedFile}
                        alt=""
                        className="w-[55px] h-[55px] rounded-10 object-cover"
                      />
                    </div>
                    <div
                      className="-ml-2 cursor-pointer absolute -right-[6px] -top-[6px]"
                      onClick={() => setSelectedFile("")}
                    >
                      <img src={deleteIcon} alt="Remove" className="w-4 h-4" />
                    </div>
                  </div>
                )}
                {selectedFile !== "" && selectedFileType === "document" && (
                  <a href={selectedFile}>{selectedFile}</a>
                )}
                <input
                  type="text"
                  placeholder="Add a comment"
                  style={{ backgroundColor: isLivePage ? colors?.Secondary : undefined, color: isLivePage ? colors?.Text : undefined  }}
                  className={classNames(
                    "w-full focus-visible:outline-0 focus-visible:bg-[#e6edf1] transition-all hover:bg-[#e6edf1] h-[38px] pl-[17px] pr-[90px] font-light text-[15px] rounded-[10px] bg-[#EDF2F5]",
                    {
                      "rounded-[19px] bg-[#EDF2F5] pb-[10px]": selectedFile !== "",
                      "!h-[36px] !text-[12px] !pl-[9px] rounded-[5px] opacity-1 font-normal": isLivePage
                    }
                  )}
                  {...register("messageText")}
                  onChange={handleCommentChange} // Handle changes
                  autoComplete="off"
                />
                {showSuggestions && suggestions?.length! > 0 && (
                  <ul
                    className={classNames(
                      "w-full md:max-w-[350px] bg-white z-20 origin-top-left absolute card-shadow py-[10px] px-2.5 border-b-1 border-gray-200 max-h-[220px] overflow-y-scroll",
                      {
                        "bottom-[45px]": isSpotlightDetailModal,
                      }
                    )}
                  >
                    {suggestions?.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        <div
                          className="h-[37px] flex items-center hover:bg-[#EDF2F5] m-[6px] rounded-10 cursor-pointer"
                          key={index}
                        >
                          <Avatar
                            src={`${
                              process.env.REACT_APP_IMAGE_URL_PREFIX
                            }/${suggestion?.avatar!}`}
                            size="XSMALL"
                          />
                          <div className="flex items-center w-[430px] ml-[11px] tracking-wide truncate">
                            <div className="inline-block text-[15px] leading-5 font-semibold text-[#172746]">
                              {suggestion?.firstName} {suggestion?.lastName}
                            </div>
                            <div className="ml-1 text-[13px] font-light leading-[15px] text-[#18161C]">
                              • {suggestion?.company?.name}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div
                className={classNames(
                  "flex flex-row items-center absolute md:right-[24px] right-[2px] h-[38px]",
                  {
                    "!h-[36px] !right-[10px] gap-[7px]": isLivePage,
                  }
                )}
              >
                <div className="flex" ref={ref}>
                  <button
                    type="button"
                    onClick={(e) => {
                      onEmojiDropdown();
                    }}
                    // onMouseLeave={() => handleEmojiDropdown(false)}
                  >
                    {isLivePage ? <EmogiSvgIcon /> : <CommentSmileSvg isLivePage={isLivePage} />}
                  </button>
                  {showEmojiDropdown && (
                    <Picker
                      pickerStyle={
                        isSpotlightDetailModal
                          ? pickerSpotlightStyle
                          : pickerDefaultStyle
                      }
                      onEmojiClick={onEmojiClick}
                    />
                  )}
                </div>
                <div className="py-0">
                  <span
                    className="cursor-pointer"
                    onClick={() => fileInputRef?.current?.click()}
                  >
                    {isLivePage ? <UploadImageSvgIcon /> : <CommentFileSvg isLivePage={isLivePage} />}
                  </span>
                </div>

                <input
                  type="file"
                  id="fileToUpload"
                  ref={fileInputRef}
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => handleFileSelected(e.target?.files!)}
                  onClick={onInputPhotoClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateComment;
