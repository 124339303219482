import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { IEventSponsor } from 'src/models/user-events';
import { isWithinEventWindow } from 'src/mui/components/hook-form/Common';
import { PartnerDetails } from '../factor-partner-details';

interface CategorizedPartners {
  diamond: IEventSponsor[];
  platinum: IEventSponsor[];
  gold: IEventSponsor[];
  silver: IEventSponsor[];
  bronze: IEventSponsor[];
  others: IEventSponsor[];
}

export const FactorPartners = () => {
  const partners = useSelector((state: IAppState) => state.website.event?.eventSponsors!);
  const event = useSelector((state: IAppState) => state.website.event!);

  const isEventDateWithin80Days = isWithinEventWindow({
    date: event?.startTime,
    status: event?.status
  });

  const categorizedPartners : CategorizedPartners = partners.reduce<CategorizedPartners>((acc, partner) => {
    const sponsorship = partner.sponsorship?.toLowerCase();
    switch (true) {
      case sponsorship?.includes('diamond'):
        acc.diamond.push(partner);
        break;
      case sponsorship?.includes('platinum'):
        acc.platinum.push(partner);
        break;
      case sponsorship?.includes('gold'):
        acc.gold.push(partner);
        break;
      case sponsorship?.includes('silver'):
        acc.silver.push(partner);
        break;
      case sponsorship?.includes('bronze'):
        acc.bronze.push(partner);
        break;
      default:
        acc.others.push(partner);
        break;
    }
    return acc;
  }, {
    diamond: [],
    platinum: [],
    gold: [],
    silver: [],
    bronze: [],
    others: []
  });

  const {
    diamond: diamondPartners,
    platinum: platinumPartners,
    gold: goldPartners,
    silver: silverPartners,
    bronze: bronzePartners,
    others: othersPartners
  } = categorizedPartners;

  const getOtherTitle = () => {
    if (diamondPartners.length || platinumPartners.length || goldPartners.length || silverPartners.length || bronzePartners.length) {
      return 'Others';
    } else {
      return '';
    }
  };

  return (
    <div className="px-[15px] md:px-0 mt-5 md:mt-0">
      <div className="text-white font-medium helvetica-neue-65-medium text-[22px] leading-[27px]">Innovation</div>
      <div className="text-white md:leading-[42px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium text-left">
        {' '}
        {isEventDateWithin80Days ? 'Event Partners' : 'Past Event Partners'}
      </div>

      <div className="mt-[38px]">
        { diamondPartners?.length
          ? (
            <>
              <div className="text-white leading-[25px] md:text-[23px] text-[18px] helvetica-neue-65-medium ">Diamond</div>
              <div className="flex flex-row flex-wrap gap-y-[30px] md:gap-x-[68px] gap-x-[30px] mt-[15px]">
                {diamondPartners?.map((partner) => (<PartnerDetails key={partner.id} partner={partner} />))}
              </div>
            </>
          ) : null}

        { platinumPartners?.length
          ? (
            <>

              <div className="text-white text-[22px] leading-[27px] font-medium helvetica-neue-65-medium mt-[46px]">Platinum</div>
              <div className="flex flex-row flex-wrap gap-y-[30px] md:gap-x-[68px] gap-x-[30px] mt-[15px]">
                { platinumPartners?.map((partner) => (<PartnerDetails key={partner.id} partner={partner} />)) }
              </div>
            </>
          ) : null}

        { goldPartners?.length
          ? (
            <>
              <div className="text-white text-[22px] leading-[27px] font-medium helvetica-neue-65-medium mt-[46px]">Gold</div>
              <div className="flex flex-row flex-wrap gap-y-[30px] md:gap-x-[68px] gap-x-[30px] mt-[15px]">
                { goldPartners?.map((partner) => (<PartnerDetails key={partner.id} partner={partner} />)) }
              </div>
            </>
          ) : null}

        { silverPartners?.length
          ? (
            <>
              {' '}
              <div className="text-white text-[22px] leading-[27px] font-medium helvetica-neue-65-medium mt-[46px]">Silver</div>
              <div className="flex flex-row flex-wrap gap-y-[30px] md:gap-x-[68px] gap-x-[30px] mt-[15px]">
                { silverPartners?.map((partner) => (<PartnerDetails key={partner.id} partner={partner} />)) }
              </div>
            </>
          ) : null}

        { bronzePartners?.length
          ? (
            <>
              {' '}
              <div className="text-white text-[22px] leading-[27px] font-medium helvetica-neue-65-medium mt-[46px]">Bronze</div>
              <div className="flex flex-row flex-wrap gap-y-[30px] md:gap-x-[68px] gap-x-[30px] mt-[15px]">
                { bronzePartners?.map((partner) => (<PartnerDetails key={partner.id} partner={partner} />)) }
              </div>
            </>
          ) : null}

        { othersPartners?.length
          ? (
            <>
              {' '}
              <div className="text-white text-[22px] leading-[27px] font-medium helvetica-neue-65-medium mt-[46px]">{getOtherTitle()}</div>
              <div className="flex flex-row flex-wrap gap-y-[30px] md:gap-x-[68px] gap-x-[30px] mt-[15px]">
                { othersPartners?.map((partner) => (<PartnerDetails key={partner.id} partner={partner} />)) }
              </div>
            </>
          ) : null}
      </div>
    </div>
  );
};
