import { SVGProps } from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
}

function ThreeDotsSvgIcon({ fillColor, fillBgColor, ...props }: Props) {
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="5" viewBox="0 0 21 5">
  <g id="Group_21488" data-name="Group 21488" transform="translate(-8611.5 -1475)">
    <circle id="Ellipse_1310" data-name="Ellipse 1310" cx="2.5" cy="2.5" r="2.5" transform="translate(8611.5 1475)" fill={defaultColor}/>
    <circle id="Ellipse_1311" data-name="Ellipse 1311" cx="2.5" cy="2.5" r="2.5" transform="translate(8619.5 1475)" fill={defaultColor}/>
    <circle id="Ellipse_1312" data-name="Ellipse 1312" cx="2.5" cy="2.5" r="2.5" transform="translate(8627.5 1475)" fill={defaultColor}/>
  </g>
</svg>

    );
}

export default ThreeDotsSvgIcon;