import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

export const Footer = () => {
  return (
    <Stack
      sx={{
        backgroundColor: '#e6e2e4',
        p: '3rem',
        mt: 'auto'
      }}
    >
      <Typography
        variant="body2"
        noWrap
        sx={{
          whiteSpace: 'initial',
          fontSize: '1.5rem',
          mb: '0.5rem',
          color: '#4e444e',
          fontFamily: 'Poppins-600',
          lineHeight: '30px',
          '@media (max-width: 768px)': { fontSize: 'calc(1.275rem + .3vw)' }
        }}
      >
        Unsubscribe | Privacy Policy
      </Typography>
      <Typography
        variant="body2"
        noWrap
        sx={{
          whiteSpace: 'initial',
          fontSize: '15px',
          mb: '0px',
          color: '#4e444e',
          fontFamily: 'Poppins-400',
          lineHeight: '24px'
        }}
      >
        © Lenovo {new Date().getFullYear()}. All rights reserved. Lenovo, the Lenovo logo, ThinkSystem and ThinkAgile are trademarks of Lenovo. Lenovo reserves the right to alter product offerings or specifications at any time without notice. Model pictures are for illustration purposes only. Lenovo is not responsible for typographic or photographic errors. Information advertised has no contractual effect. Lenovo may not offer the products, services or features discussed in this document in all countries. Promotions are for business customers only and are subject to availability. Promotions may be withdrawn or changed without prior notice.
      </Typography>
    </Stack>
  );
};
