// @mui
import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
// components
import Scrollbar from "src/mui/components/scrollbar";
import Lightbox, { useLightBox } from "src/mui/components/lightbox";

import { useMessagesScroll } from "./hooks";
import logo from "src/assets/images/nav-menu/no-message.svg";
import ChatMessageItem from "./chat-message-item";
import { IAttendee } from "src/models/events-live/attendee";
import { useEffect, useState } from "react";
// import { useCallback, useEffect, useRef, useState } from "react";
import { useWebSocket } from "src/utils/hooks";
import { useSelector } from "react-redux";
import { IAppState } from "src/store";
import { getBrandingColors } from '../../pages/event/live/liveEventStyling';
import { LiveMessages } from '../../../store/privateChat';

// ----------------------------------------------------------------------
const colors = getBrandingColors();
type Props = {
  participants: IAttendee[];
  onMessageReact: (messageId: string, type: string) => void;
  onMessageReply: (messageData: any) => void;
  onMessageUpdate: (messageData: any) => void;
  onMessageDelete: (messageId: string) => void;
  setActiveUserAvatar?: any;
  editMessage?: any;
  isGridView?: boolean;
  activeRoomId: string;
  id: string;
};

export default function ChatMessageList({
  participants,
  onMessageReact,
  onMessageReply,
  onMessageUpdate,
  onMessageDelete,
  editMessage,
  setActiveUserAvatar,
  isGridView,
  activeRoomId,
  id,
}: Props) {
  const { webSocket } = useWebSocket();
  const colors = getBrandingColors();
  const {
    Items:messages,
    LastEvaluatedKey: lastEvaluatedKey,
  } = useSelector((state: IAppState) => state.privateChat.eventChatMessages);


  const slides = messages
    .filter((message: any) => message.contentType === "image")
    .map((message: any) => ({ src: message.body }));

  const lightbox = useLightBox(slides);

  const { messagesEndRef, preserveScrollPosition } = useMessagesScroll(
    messages as any[],
    true
  );

  useEffect(() => {
    const handleScroll = () => {
      if (messagesEndRef.current) {
        const { scrollTop } = messagesEndRef.current;
        if (scrollTop === 0 && lastEvaluatedKey) {
          preserveScrollPosition();
          if (!activeRoomId || !lastEvaluatedKey?.messageId) return;
          webSocket.then((ws) => {
            ws.send(
              JSON.stringify({
                action: "live-messages",
                data: {
                  eventId: id,
                  eventRoomId: activeRoomId,
                  option: {
                    limit: 20,
                    sorted: false,
                    exclusiveStartKey: lastEvaluatedKey,
                  },
                },
              })
            );
          });
        }
      }
    };

    const currentRef = messagesEndRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [
    activeRoomId,
    webSocket,
    lastEvaluatedKey,
    preserveScrollPosition,
  ]);
;

  return (
    <>
      <Scrollbar ref={messagesEndRef} sx={{ height: 1 }}>
        <Box sx={{ pb: 3, pt: '9px' }}>
          {messages.map((message:any, index) => {
            const nextMessage:any = messages[index + 1]; // Get the next message
            const showAvatar = !nextMessage || message.senderId !== nextMessage.senderId;

            const PreMessage:any = messages[index - 1]; // Get the next message
            const showUsername = !PreMessage || message.senderId !== PreMessage.senderId;

            return (
              <ChatMessageItem
                key={message.id}
                onMessageReact={onMessageReact}
                onMessageReply={onMessageReply}
                onMessageUpdate={onMessageUpdate}
                onMessageDelete={onMessageDelete}
                messageData={message}
                editMessage={editMessage}
                participants={participants}
                onOpenLightbox={() => lightbox.onOpen(message.body)}
                setActiveUserAvatar={setActiveUserAvatar}
                showAvatar={showAvatar} // Pass calculated showAvatar here
                showUsername={showUsername}
              />
            );
          })}
          {messages.length === 0 && (
            <Stack sx={{ mt: isGridView ? "15px" : 5 }}>
              <Typography
                sx={{
                  color: isGridView ? colors?.Text || "#CECECE" : colors?.Text || "#ffffff",
                  fontSize: isGridView ? "14px" : "16px",
                  textAlign: "center",
                  mb: isGridView ? "30px" : 2,
                  fontFamily: "Poppins-600",
                }}
              >
                You don't have any messages yet
              </Typography>
              <img
                style={{
                  maxWidth: isGridView ? "45%" : "80%",
                  margin: "0 auto",
                }}
                src={logo}
              ></img>
            </Stack>
          )}
        </Box>
      </Scrollbar>

      <Lightbox
        index={lightbox.selected}
        slides={slides}
        open={lightbox.open}
        close={lightbox.onClose}
      />
    </>
  );
}
