/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { UserService } from '../../../../services';
import { Button } from '../../../../shared-components/V2';
import { eventRegistrationLastStep, signupBackStep, eventRegistrationStep2 } from '../../../../store/user/signup/actions';
import DynamicallyFieldRegistration from '../event-components/dynamically-field-registration';
import { IRegistrationFormResult } from '../../../../models/user/event-registration/registrationForm-result';
import { IAppState } from '../../../../store';
import { LOGIN_SIGNUP_RECOVERY, DEFAULT_REGISTRATION_FIELDS } from '../../../../constants';
import { IReactSelectOption } from '../../../../models/react-select-option';
import { useCurrentUser } from '../../../../utils/hooks';
import { emailRegex } from '../../../../utils';
import RegisterForm from '../../spectre-website/resgister-form/register-form';

interface ISignupProps {
  changePage: Function;
  backButtonVisible: boolean;
  eventId?: string;
  isCloseButton?: boolean;
  onClose?: Function;
  isSpectre?: boolean;
  isSpectreForm?: boolean;
  isAttendeeForm?: boolean;
}
export const EventDetails = ({
  changePage, backButtonVisible, eventId, isCloseButton, onClose, isSpectre, isSpectreForm, isAttendeeForm
}: ISignupProps) => {
  const { register } = useForm<any>();
  const dispatch = useDispatch();
  const { eventRegistration } = useSelector((state: IAppState) => state.signupUser);
  const eventJoining = useSelector((state: IAppState) => state.signupUser.eventJoining);
  const errorMessage = useSelector((state: IAppState) => state.signupUser.errorMessage);
  const [registrationFields, setRegistrationFields] = useState<IRegistrationFormResult>();
  const [dynamicallyFieldsValue, setDynamicallyFieldsValue] = useState<any>({});
  const [multiAnswerSelect, setMultiAnswerSelected] = useState([]);
  const [formIsValidState, setFormIsValidState] = useState(true);
  const [selectedState, setSelectedState] = useState<IReactSelectOption>();
  const [selectedCompany, setSelectedCompany] = useState<any>({});
  const [selectedIndustry, setSelectedIndustry] = useState<IReactSelectOption>();
  const [selectedJobTitle, setSelectedJobTitle] = useState<IReactSelectOption>();
  const [selectedValue, setSelectedValue] = useState<any>({});
  const [joinSkip, setJoinSkip] = useState(false);
  let formIsNotValid = false;

  const loggedInUser = useCurrentUser();

  const isValidEmail = (businessEmail: string = '') => !(businessEmail.includes('@gmail.')
    || businessEmail.includes('@yahoo.')
    || businessEmail.includes('@icloud.'))
    && businessEmail.match(emailRegex);

  const getDefault = (fieldType: string) => {
    let defaultValue;

    switch (fieldType) {
      case DEFAULT_REGISTRATION_FIELDS.FIRST_NAME:
        defaultValue = eventRegistration?.firstName
          ? eventRegistration?.firstName
          : loggedInUser?.firstName;
        break;
      case DEFAULT_REGISTRATION_FIELDS.LAST_NAME:
        defaultValue = eventRegistration?.lastName
          ? eventRegistration?.lastName
          : loggedInUser?.lastName;
        break;
      case DEFAULT_REGISTRATION_FIELDS.EMAIL:
        defaultValue = eventRegistration?.email
          ? eventRegistration?.email
          : loggedInUser?.email;
        break;
      case DEFAULT_REGISTRATION_FIELDS.JOB_TITLE:
        defaultValue = eventRegistration?.jobTitle?.label
          ? eventRegistration?.jobTitle?.label : (typeof eventRegistration?.jobTitle) === 'string' && eventRegistration?.jobTitle ? eventRegistration?.jobTitle
            : loggedInUser?.jobTitle;
        break;
      case DEFAULT_REGISTRATION_FIELDS.COMPANY:
        defaultValue = eventRegistration?.company?.label
          ? eventRegistration?.company?.label : eventRegistration?.company.value ? eventRegistration?.company.value
            : loggedInUser?.company?.name || 'NoCompany';
        break;
      case DEFAULT_REGISTRATION_FIELDS.INDUSTRY:
        defaultValue = eventRegistration?.industry?.label
          ? eventRegistration?.industry?.label
          : loggedInUser?.industry?.name || 'Industry';
        break;
      case DEFAULT_REGISTRATION_FIELDS.ADDRESS:
        defaultValue = eventRegistration?.location
          ? eventRegistration?.location
          : loggedInUser?.location;
        break;
      case DEFAULT_REGISTRATION_FIELDS.POSTAL_CODE:
        defaultValue = eventRegistration?.postCode
          ? eventRegistration?.postCode
          : loggedInUser?.postCode || '2000';
        break;
      default:
        break;
    }
    return defaultValue;
  };

  const checkFormValidation = () => {
    for (let i = 0; i < registrationFields?.data.length!; i++) {
      if (i < registrationFields?.data.length!) {
        if ((!registrationFields?.data[i].isDefault && registrationFields?.data[i].isRequired)
          || (registrationFields?.data[i].isDefault && !getDefault(registrationFields.data[i].fieldType)?.trim()?.length)) {
          // eslint-disable-next-line no-prototype-builtins
          if (!selectedValue.hasOwnProperty(registrationFields?.data[i].id)) {
            setFormIsValidState(false);
            formIsNotValid = true;
            return;
            // eslint-disable-next-line no-prototype-builtins
          } if (selectedValue.hasOwnProperty(registrationFields?.data[i].id)) {
            if (!selectedValue[registrationFields?.data[i].id]) {
              setFormIsValidState(false);
              formIsNotValid = true;
              return;
            }
            setFormIsValidState(true);
            formIsNotValid = false;
          }
        }
        if (registrationFields?.data[i].fieldType === 'business-email') {
          // eslint-disable-next-line no-prototype-builtins
          if (selectedValue.hasOwnProperty(registrationFields?.data[i].id)) {
            if (!isValidEmail(selectedValue[registrationFields?.data[i].id])) {
              setFormIsValidState(false);
              formIsNotValid = true;
              return;
            }
          }
        }
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const joinEvent = () => {
    checkFormValidation();

    if (!formIsNotValid) {
      let staticFieldsValue = selectedValue;

      registrationFields?.data.forEach(item => {
        if (item.isDefault && getDefault(item.fieldType)?.trim()?.length) {
          // eslint-disable-next-line default-case
          switch (item.fieldName) {
            case 'First name': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.firstName
                  ? eventRegistration?.firstName
                  : loggedInUser?.firstName
              };
              break;
            }
            case 'Last name': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.lastName
                  ? eventRegistration?.lastName
                  : loggedInUser?.lastName
              };
              break;
            }
            case 'Email': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.email
                  ? eventRegistration?.email
                  : loggedInUser?.email
              };
              break;
            }
            case 'Job title': {
              staticFieldsValue = {
                ...staticFieldsValue,
                // eslint-disable-next-line no-nested-ternary
                [`${item.id}`]: eventRegistration?.jobTitle?.label
                  ? eventRegistration?.jobTitle?.label : (typeof eventRegistration?.jobTitle) === 'string' && eventRegistration?.jobTitle ? eventRegistration?.jobTitle
                    : loggedInUser?.jobTitle
              };
              break;
            }
            case 'Organisation': {
              staticFieldsValue = {
                ...staticFieldsValue,
                // eslint-disable-next-line no-nested-ternary
                [`${item.id}`]: eventRegistration?.company?.label
                  ? eventRegistration?.company?.label : eventRegistration?.company.value ? eventRegistration?.company.value
                    : loggedInUser?.company?.name || 'NoCompany'
              };
              break;
            }
            case 'Industry': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.industry?.label
                  ? eventRegistration?.industry?.label
                  : loggedInUser?.industry?.name || 'Industry'
              };
              break;
            }
            case 'Country/region':
            case 'City, Country': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.location
                  ? eventRegistration?.location
                  : loggedInUser?.location
              };
              break;
            }
            case 'Postal Code': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.postCode
                  ? eventRegistration?.postCode
                  : loggedInUser?.postCode || '2000'
              };
              break;
            }
          }
        }
        return null;
      });

      setDynamicallyFieldsValue(staticFieldsValue);
      if (isSpectreForm) {
        sessionStorage.setItem('eventRegStep', '2');
        changePage(3);
      }
      dispatch(
        eventRegistrationStep2(
          eventId,
          staticFieldsValue,
          selectedValue,
          selectedState,
          selectedCompany,
          selectedIndustry,
          selectedJobTitle
        )
      );
    }
  };

  useEffect(() => {
    if (!eventId) return;
    new UserService()
      .getEventRegistrationForm(eventId!)
      .then(res => setRegistrationFields(res))
      .catch(error => toast.error(error));
  }, [eventId]);

  useEffect(() => {
    if (eventJoining) {
      const copyRegistrationFields = { ...registrationFields?.data };

      for (let i = 0; i <= registrationFields?.data.length!; i++) {
        const field = registrationFields?.data[i];
        if (field?.id && !field?.isDefault) {
          // eslint-disable-next-line no-prototype-builtins
          if (eventJoining.dynamicFields?.hasOwnProperty(field.id)) {
            copyRegistrationFields[i].value = eventJoining.dynamicFields[field.id];
          }
        }
      }

      const data: any = {};
      data.Fields = [];
      data.field = copyRegistrationFields;
      if (data && data.field.length > 0) setRegistrationFields(data);
    }
  }, [registrationFields, eventJoining]);

  useEffect(() => {
    if (joinSkip) {
      joinEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinSkip]);

  useEffect(() => {
    if (
      registrationFields?.data?.length! > 0
      && !registrationFields?.data.some(item => !item.isDefault) && !joinSkip
    ) {
      setJoinSkip(true);
    }
  }, [joinSkip, registrationFields, joinEvent]);

  // useEffect(() => {
  //   if (!registrationFields?.data?.length && !joinSkip) {
  //     setJoinSkip(true);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [registrationFields]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 5000 });
      if (errorMessage === LOGIN_SIGNUP_RECOVERY.ALREADY_REGISTERED) {
        dispatch(eventRegistrationLastStep);
      }
    }
  }, [errorMessage, dispatch]);

  useEffect(() => {
    setSelectedState(eventJoining?.selectedState);
  }, [eventJoining?.selectedState]);

  useEffect(() => {
    setSelectedCompany(eventJoining?.selectedCompany);
  }, [eventJoining?.selectedCompany]);

  useEffect(() => {
    setSelectedIndustry(eventJoining?.selectedIndustry);
  }, [eventJoining?.selectedIndustry]);

  useEffect(() => {
    setSelectedJobTitle(eventJoining?.selectedJobTitle);
  }, [eventJoining?.selectedJobTitle]);

  useEffect(() => {
    if (eventJoining) {
      setSelectedValue(eventJoining.dynamicFields);
    }
  }, [eventJoining]);

  const changeDynamicData = (fieldId: string, fieldType: string, answer: any, event: any) => {
    if (fieldType === 'select') {
      setMultiAnswerSelected(multiAnswerSelect.concat(answer));

      if (answer) {
        setSelectedValue({ ...selectedValue, [fieldId]: multiAnswerSelect.concat(answer) });
        setDynamicallyFieldsValue({
          ...dynamicallyFieldsValue,
          [`${fieldId}`]: multiAnswerSelect.concat(answer)
        });
      } else {
        setSelectedValue({ ...selectedValue, [fieldId]: '' });
        setDynamicallyFieldsValue({
          ...dynamicallyFieldsValue,
          [`${fieldId}`]: multiAnswerSelect.concat(answer)
        });
      }
    } else if (fieldType === 'terms-and-conditions') {
      if (answer) {
        setSelectedValue({ ...selectedValue, [fieldId]: answer });
        setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
      } else {
        setSelectedValue({ ...selectedValue, [fieldId]: answer });
        setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
      }
    } else if (fieldType === 'radio') {
      setSelectedState(event);
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    } else if (fieldType === 'phone') {
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    } else if (fieldType === 'job-title') {
      setSelectedJobTitle(event);
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    } else if (fieldType === 'company') {
      setSelectedCompany(event);
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    } else if (fieldType === 'industry') {
      setSelectedIndustry(event);
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    } else if (fieldType === 'business-email') {
      if (answer.includes('@gmail.') || answer.includes('@yahoo.') || answer.includes('@icloud.')) {
        setFormIsValidState(false);
        formIsNotValid = true;
      }
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    } else {
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    joinEvent();
  };

  const backClick = () => {
    changePage(2);
    dispatch(signupBackStep());
  };

  // if (joinSkip) {
  //   // eslint-disable-next-line react/jsx-no-useless-fragment
  //   return <></>;
  // }
  return (
    <form className="flex flex-col justify-between" onSubmit={e => onSubmit(e)}>
      <div
        className={classNames('flex flex-row mx-auto w-full md:max-w-[542px] capitalize px-[30px] mobile-p-x pt-[10px] max-h-[calc(100vh-394px)] fields-mobile-height overflow-y-auto hide-scroll', {
          'min-h-[calc(100vh-600px)]': registrationFields?.data?.length! < 5,
          'mb-6 !max-w-[100%] pt-6': isSpectre,
          '!max-h-full': isSpectreForm
        })}
      >
        <div
          className={classNames({
            ' flex flex-wrap gap-x-[15px]': isSpectre,
            'w-full': !isSpectre,
          })}
        >
          {registrationFields?.data.map((item, index) => {
            if (!item.isDefault || (item.isDefault && !getDefault(item.fieldType)?.trim()?.length)) {
              return (
                <div
                  key={index}
                  className={classNames('w-full', {
                    'w-[calc(50%-8px)]': (isSpectre && (item.fieldType === 'business-email' || item.fieldType === 'phone' || item.fieldType === 'text')),
                    hidden: !item.fieldName
                  })}
                >
                  <div>
                    <DynamicallyFieldRegistration
                      item={item}
                      register={register}
                      changeDynamicData={(
                        fieldId: string,
                        fieldType: string,
                        answer: [],
                        event: any
                      ) => changeDynamicData(fieldId, fieldType, answer, event)}
                      selectedState={selectedState!}
                      selectedCompany={selectedCompany}
                      selectedIndustry={selectedIndustry!}
                      selectedJobTitle={selectedJobTitle!}
                      selectedValue={selectedValue[item.id] ?? getDefault(item.fieldType)}
                      // disabled={getDefault(item.fieldName)}
                      isSpectre={isSpectre}
                      isSpectreForm={isSpectreForm}
                    />
                    {item.isRequired && !selectedValue[item.id] && !formIsValidState && (
                      <span className="text-red text-xs normal-case">
                        {`Required: ${item.fieldName}`}
                      </span>
                    )}
                    {selectedValue[item.id] && item.fieldType === 'business-email' && !isValidEmail(selectedValue[item.id]) && (
                      <span className="text-red text-xs normal-case">
                        {`Invalid: ${item.fieldName}`}
                      </span>
                    )}
                  </div>
                  {!isSpectre && <hr className="mt-[10px]" />}
                </div>
              );
            }
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
          })}
        </div>
      </div>
      {isAttendeeForm && (
        <div className="mt-2 px-[30px]">
          <div className="text-white text-[13px] leading-[20px] helvetica-neue-45-light mt-[20px] mb-[20px]">ANOTHER ATTENDEE</div>
          <RegisterForm />
        </div>
      )}
      <div
        className={classNames('flex', {
          'px-[30px] justify-end gap-x-[10px]': isSpectreForm,
          'border-t-1 border-[#d7d7d7] justify-between pt-[10px]': !isSpectreForm
        })}
      >
        {isCloseButton ? (
          <button
            hidden={backButtonVisible}
            type="button"
            className={classNames('text-sm1 font-semibold hover:underline w-24', {
              'text-white': isSpectre,
              'text-blue-2': !isSpectre,
              'px-[20px] !w-auto h-[32px] rounded-full bg-white !text-black text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer': isSpectreForm
            })}
            onClick={() => onClose && onClose()}
          >
            {isSpectreForm ? 'Add attendee' : 'Cancel'}
          </button>
        ) : (
          <button
            hidden={backButtonVisible}
            type="button"
            className="text-sm1 text-blue-2 font-semibold hover:underline w-24"
            onClick={backClick}
          >
            Back
          </button>
        )}
        <Button
          type="submit"
          text={isSpectreForm ? 'Next' : 'Continue'}
          size="x-small"
          className={classNames('w-110 mr-[24px] margin-r-15 bg-[#0049EF] text-white text-sm font-semibold hover:bg-blue-3', {
            'bg-[#0071e3]': isSpectre,
            'bg-blue': !isSpectre,
            'px-[20px] !w-auto h-[32px] !mr-0 rounded-full bg-[#0071e3] text-white text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer': isSpectreForm
          })}
        />
      </div>
    </form>
  );
};

export default EventDetails;
