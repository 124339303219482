import { useEffect, useState } from 'react';
import {
  GoogleMap, InfoWindow, Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { EventWebsiteTemplateType } from 'src/mui/types/survey';
import { IAppState } from '../../../store';
import markerIcon from '../../../assets/images/map-marker.svg';
// import { IEventLocationProps } from '../../../models/event/event-location';
import { getAddressFromGeocode } from '../../../store/address/actions';
import { IEvent } from '../../../models/user-events';
import { ParseContent } from './parse-html';

interface IWebsiteEventLocation {
  latitude: any;
  longitude?: any;
  address: string;
}

const WebsiteEventLocation = ({ latitude, longitude, address }: IWebsiteEventLocation) => {
  // TODO: Please handle the scenario where coordinates are null or invalid.
  // There should be default coordinates where the marker will be placed in this case

  const [showLocationInfo, setShowLocationInfo] = useState(false);
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}` });
  const { eventLocationAddress } = useSelector((state: IAppState) => state);
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const locations: any = useSelector((state: IAppState) => state.website.event!);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAddressFromGeocode(latitude, longitude));
  }, [dispatch, latitude, longitude]);

  const mapLocationStyles = {
    height: '220px',
    width: '100%',
    borderRadius: '10px',
  };

  const defaultLocation = {
    lat: +latitude!,
    lng: +longitude!
  };

  const markerLocationLatLng = {
    lat: +latitude!,
    lng: +longitude!
  };

  return (
    <div
      id="venue"
      className={classNames('w-full mb-[100px]', {
        hidden: !(locations.format === 'hybrid'),
      })}
    >
      <div className={`w-full flex md:flex-row flex-col justify-between ${event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS ? 'event-afterhours-box' : 'event-website-box'} p-[25px]`}>
        <div className="md:w-[473px] w-full">
          <div
            className="font-semibold text-[21px] text-[#0326E5] leading-[25px] flex justify-left mb-[19px]"
            style={{
              color: event?.colorStyle?.values?.heading,
            }}
          >
            Venue Instructions
          </div>
          <div className="rounded-10 bg-white p-[20px] h-[220px]">
            <div className="text-[18px] font-semibold leading-[25px] text-[#203C6E] mb-[24px]">
              {locations.locationName}
            </div>
            <div className="text-[15px] font-normal leading-[23px] text-[#707070] mb-[24px]">
              <ParseContent content={locations?.notes} />
            </div>
            <div className="text-[15px] font-normal leading-[23px] text-[#707070]">
              Address:
              {' '}
              {locations.location}
            </div>
            {/* <div className="text-[15px] font-normal leading-[23px] text-[#707070]">
              Phone:
              {' '}
              <span className="font-medium" style={{ color: event?.colorStyle?.values?.heading }}>(02) 9286 6000</span>
            </div> */}
          </div>
        </div>
        <div className="md:w-[585px] w-full mt-5 md:mt-0">
          <div
            className="font-semibold text-[#0326E5] text-[21px] leading-[25px] flex justify-left mb-[19px]"
            style={{
              color: event?.colorStyle?.values?.heading,
            }}
          >
            Location
          </div>
          <div className="block">
            <div className="w-full mx-auto rounded-10 mb-5">
              {
                isLoaded
                && (
                  <GoogleMap mapContainerStyle={mapLocationStyles} zoom={12} center={defaultLocation}>
                    <Marker
                      // key={locationProps?.title}
                      position={markerLocationLatLng}
                      icon={markerIcon}
                      onClick={() => setShowLocationInfo(true)}
                    />
                    {showLocationInfo && (
                      <InfoWindow position={markerLocationLatLng} onCloseClick={() => setShowLocationInfo(false)}>
                        <div className="flex flex-col p-1">
                          <div className="text-gray-5 text-md2">
                            <br />
                            Address:
                            {' '}
                            {address || eventLocationAddress.value?.results[0].formatted_address}
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                )
                // </LoadScript>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventLocation;
