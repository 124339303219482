import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SelectedPackageState } from '../../models/SelectedPackageState';
import { SetSelectedPackageTypes } from './types';

export interface ISetSelectedPackageAction {
  type: SetSelectedPackageTypes.SET_SELECTED_PACKAGE;
  selectedPackage: SelectedPackageState;
}

export type SetSelectedPackageAction = ISetSelectedPackageAction;

export const setSelectedPackageType: ActionCreator<
  ThunkAction<Promise<any>, SelectedPackageState, null, ISetSelectedPackageAction>
> = (packageType: string) => {
  return async (dispatch: Dispatch) => {
    const packageSelected: SelectedPackageState = {
      selectedPackage: packageType
    };

    dispatch({
      type: SetSelectedPackageTypes.SET_SELECTED_PACKAGE,
      selectedPackage: packageSelected
    });
  };
};
