/* eslint-disable max-len */
import { useState } from 'react';

interface ISpotlightSvgIcon {
  isActive?: boolean;
  className?: string;
  disableHover?: boolean;
}
export const SpotlightSvgIcon = ({ isActive, className, disableHover }: ISpotlightSvgIcon) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const colorWhite = '#ffffff';
  const defaultColor = '#7283A4';
  const fillColor = !disableHover && (isHover || isActive) ? colorWhite : defaultColor;

  return (
    <svg
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <path id="形状" d="M25.054,2.8l.368.046a5.322,5.322,0,0,1,2.987,1.044,4.429,4.429,0,0,1,.963,2.431l.081.556h0a16.872,16.872,0,0,1-.081,4.943,17.469,17.469,0,0,1-7.657,11.749,6.792,6.792,0,0,0-.023.819l.013.411a5.878,5.878,0,0,1-.129,1.872,3.8,3.8,0,0,1-2.162,2.03l-.448.211h0l-.577.255c-1.079.458-2.536.928-3.45.015a4.319,4.319,0,0,1-.957-2.148l-.067-.3a10.89,10.89,0,0,0-.289-1.116c-.072-.21-.149-.425-.233-.641a4.333,4.333,0,0,1-.293.324,5.6,5.6,0,0,1-1.852,1.088,20.4,20.4,0,0,1-2.123.686l-.359.1h0l-.688.171h0l-.623.144h0l-.746.159h0l-.466.091h0a1.455,1.455,0,0,1-1.7-1.7l.123-.622h0L4.892,24.4h0l.179-.734h0l.136-.519h0a20.374,20.374,0,0,1,.686-2.123,5.6,5.6,0,0,1,1.088-1.852l.116-.112h0L7,19.028c-.234-.091-.486-.175-.748-.257l-.4-.122a6.574,6.574,0,0,1-2.737-1.295c-.806-.806-.535-2.035-.145-3.055l.16-.4h0l.255-.577h0L3.6,12.88a3.8,3.8,0,0,1,2.03-2.162,5.082,5.082,0,0,1,1.558-.139l.317.011a9.185,9.185,0,0,0,1.227-.01A17.469,17.469,0,0,1,20.479,2.922,17.021,17.021,0,0,1,25.054,2.8ZM10.9,21.077a1.444,1.444,0,0,0-1.586-.087l-.159.108-.134.118-.18.229a6.052,6.052,0,0,0-.755,1.993l-.155.675c-.025.107-.049.21-.074.307l.276-.066h0l.6-.138a5.413,5.413,0,0,0,2.327-.96,1.442,1.442,0,0,0,.127-1.894l-.118-.136-.034-.033ZM21.261,11.016a2.885,2.885,0,1,0,0,4.08A2.885,2.885,0,0,0,21.261,11.016Z" transform="translate(-2.597 -2.697)" fill={fillColor} fillRule="evenodd" />
    </svg>
  );
};
