import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import InfiniteScroll from 'react-infinite-scroller';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router-dom';

import commentIcon from '../../assets/images/comment-icon.svg';
import noPostsImage from '../../assets/images/no-post-discussion.svg';
import shareIcon from '../../assets/images/share-icon.svg';
import { UserNavbar } from '../../components';
import { CardMainPost } from '../../components/posts';
import CreatePost from '../../components/posts/create-post';
import { WelcomePost } from '../../components/welcome-post';
import { ICreateComment } from '../../models/post/comment';
import { IPost } from '../../models/post/post';
import { PostService } from '../../services';
import { Loader } from '../../shared-components/Loader';
import { SortBy } from '../../shared-components/V2/SortBy';
import { IAppState } from '../../store';
import { editPost } from '../../store/events/event-post-actions/actions';
import {
  likeComment,
  unlikeComment,
} from '../../store/events/event-post-comment-like/actions';
import {
  likePost,
  unlikePost,
} from '../../store/events/upcoming-event-post-like/actions';
import { getUpcomingEventPosts } from '../../store/events/upcoming-event-posts/actions';
import { cleanupUpcomingEvent, getUpcomingEvent } from '../../store/events/upcoming-event/actions';
import { createPostComment } from '../../store/posts/comments/actions';
import { getTickets } from '../../store/ticket';
import {
  getUserPostComments,
  getUserPosts,
} from '../../store/user/posts/actions';
import { getUserProfile } from '../../store/user/user-profile/actions';
import EventDetails from './event-details';
import { EventHeader } from './event-header';
import EventLocation from './event-location';
import SimilarEvents from './similar-events';
import { getOwnConnections } from 'src/store/user/own-connections/actions';

export const PreEventLandingPage = () => {
  const [eventFeeds, setEventFeeds] = useState<IPost[]>([]); 
  const [nextPage, setNextPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [orderBy, setOrderBy] = useState<"Most recent" | "Top posts">(
    "Most recent"
  );
  const { slug: userId } = useParams();

  const postsService: PostService = new PostService();

  const params = useParams();
  const eventIdOrSlug: string = String(Object.values(params)[0]);

  const resetToDefaults = () => {
    setNextPage(1);
    setTotalPage(0);
    setFetching(false);
    setEventFeeds([]);
  };

  const dispatch = useDispatch();
  const { upcomingEvent, upcomingEventPosts, userProfile } = useSelector(
    (state: any) => state
  );
  const { auth } = useSelector((state: IAppState) => state);
  const { currentUser: user } = auth;
  const defaultAvatar = process.env.REACT_APP_NO_COMPANY_LOGO_URL;
  const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;

  useEffect(() => () => {
    dispatch(cleanupUpcomingEvent());
  }, []);

  useEffect(() => {
    if (upcomingEventPosts) setTotalPage(upcomingEventPosts.value?.totalPage!);
  }, [upcomingEventPosts]);

  useEffect(() => {
    setIsOwnProfile(user?.id === userId || user?.slug === userId);
  }, [userId, user]);

  useEffect(() => {
    if (isOwnProfile) {
      dispatch(getOwnConnections());
    } else dispatch(getOwnConnections(userId));
  }, [dispatch, isOwnProfile, userId]);

  useEffect(() => {
    if (upcomingEvent.value?.data.event.id) {
      dispatch(getTickets(upcomingEvent.value?.data.event.id!));
    }
  }, [dispatch, upcomingEvent.value?.data.event.id]);

  const fetchPosts = async () =>
    dispatch(getUpcomingEventPosts(eventIdOrSlug, 1, orderBy));

  const fetchPostComments = async (postId: string, page?: number) => dispatch(getUserPostComments(postId, page));

  const fetchItems = useCallback(async () => {
    if (fetching) {
      return;
    }

    setFetching(true);

    try {
      let sortBy: string = "";
      if (orderBy === "Most recent") sortBy = "&order[createdAt]=DESC";
      else sortBy = "&order[rank]=DESC";

      const { postsResult } = await postsService.getUpcomingEventPosts(
        eventIdOrSlug,
        nextPage,
        sortBy
      );
      setTotalPage(postsResult.totalPage);
      if (postsResult && nextPage <= postsResult.totalPage) {
        setEventFeeds(eventFeeds.concat(postsResult.data));
        setNextPage(nextPage + 1);
      } else {
        setNextPage(nextPage);
        setFetching(true);
      }
    } finally {
      setFetching(false);
    }
  }, [eventFeeds, fetching, nextPage]);

  const hasMoreItems = nextPage <= totalPage;

  const loader = <Loader className="pt-5" key="loader" />;

  const sortClick = async (sortType: "Most recent" | "Top posts") => {
    resetToDefaults();
    setOrderBy(sortType);
    await fetchPosts();
  };

  useEffect(() => {
    if (eventIdOrSlug) {
      resetToDefaults();
      dispatch(getUpcomingEvent(eventIdOrSlug));
      dispatch(getUpcomingEventPosts(eventIdOrSlug));
    }
    dispatch(getUserProfile());
  }, [dispatch, eventIdOrSlug]);

  return upcomingEvent ? (
    <div className="bg-gray-1">
      <UserNavbar />
      <div className="container mt-6 xl:max-w-none max-w-[100%] mx-auto xl:w-[1258px] md:w-[100%] w-full xl:px-0 px-4 lg:pb-0 pb-[50px]">
        <div className="lg:flex flex-row justify-center lg:gap-4">
          <div className="xl:w-[648px] lg:w-[57%] w-full">
            <div>
              <EventHeader
                slug={eventIdOrSlug}
                id={upcomingEvent.value?.data.event.id!}
                cover={upcomingEvent.value?.data.event.cover}
                logo={upcomingEvent.value?.data.event?.company?.logo}
                eventName={upcomingEvent.value?.data.event.name!}
                theme={upcomingEvent.value?.data.event.theme!}
                companyName={upcomingEvent.value?.data?.event?.company?.name!}
                industryName={
                  upcomingEvent.value?.data.event.industry?.name!
                }
                startDateTime={upcomingEvent.value?.data.event.startTime!}
                endDateTime={upcomingEvent.value?.data.event.endTime!}
                format={upcomingEvent.value?.data.event.format!}
                type={upcomingEvent.value?.data.event.type === "invitation" ? "Private" : "Public"}
                location={upcomingEvent.value?.data.event.locationName!}
                attendeeStatus={
                  upcomingEvent.value?.data.attendeeStatus.audienceStatus
                }
                isTicketed={upcomingEvent.value?.data?.event?.isTicketed}
                isCoverImageOverlayColor={upcomingEvent.value?.data?.event?.isCoverImageOverlayColor}
                coverImageOverlayOpacity={upcomingEvent.value?.data?.event?.coverImageOverlayOpacity}
                coverImageOverlayColour={upcomingEvent.value?.data?.event?.coverImageOverlayColour}
                isLobbyPage
                eventLogo={upcomingEvent.value?.data.event?.logo}
              />
            </div>
            <div className="mt-4">
              <EventDetails />
            </div>
            {upcomingEvent.value?.data.event?.format !== "virtual" && <div className="">
              <EventLocation
                latitude={upcomingEvent?.value ? upcomingEvent?.value?.data?.event?.latitude : ""}
                longitude={upcomingEvent?.value ? upcomingEvent?.value?.data.event.longitude : ""}
                title={upcomingEvent?.value?.data.event.name!}
                description={
                  upcomingEvent?.value?.data.event.theme!
                }
                address={
                  upcomingEvent?.value?.data.event.location!
                }
                notes={upcomingEvent?.value?.data.event.notes!}
                locationName={upcomingEvent?.value?.data.event.locationName}
              />
            </div>}
            <div className="mt-4">
              <SimilarEvents eventIdOrSlug={eventIdOrSlug} />
            </div>
          </div>
          <div className="flex flex-col xl:w-[594px] lg:w-[43%] w-full">
            {/* Put create post component over here */}
            <div className="">
              <CreatePost
                wallId={upcomingEvent.value?.data.event.id!}
                location="event"
                avatarUrl={
                  (userProfile.value?.avatar
                    ? `${imageUrlPrefix}/${userProfile.value?.avatar}`
                    : defaultAvatar) ?? ""
                }
                userDisplayName={userProfile.value?.fullName!}
                onAfterSave={() => resetToDefaults()}
              />
            </div>
            <div className="">
              <div className="flex items-center flex-row py-[4px] my-auto">
                <div className="w-full border-b-1 border-[#707070] opacity-[28%]" />
                <div className="">
                  <SortBy
                    sortClick={sortClick}
                    descOrderText="Most recent"
                    ascOrderText="Top posts"
                  />
                </div>
              </div>
              {(!upcomingEventPosts.value?.data ||
                upcomingEventPosts.value?.data.length === 0) && (
                  <WelcomePost
                    title="Create the first post"
                    imageSrc={noPostsImage}
                    subTitle="Get the conversation going by creating the first post on this event."
                  />
                )}
              <div className="overflow-visible">
                <InfiniteScroll
                  pageStart={1}
                  loadMore={fetchItems}
                  hasMore={hasMoreItems}
                  loader={loader}
                >
                  {eventFeeds &&
                    eventFeeds.map(
                      (post: IPost, index: number) =>
                        post && (
                          <CardMainPost
                            commentIcon={commentIcon}
                            shareIcon={shareIcon}
                            className="mb-[15px] w-full bg-white create-post-card"
                            key={post.id}
                            post={post}
                            getPostComments={(id: string, page: number = 1) => fetchPostComments(id, page)}
                            addPostComment={async (
                              postId: string,
                              data: any,
                              attachment: File
                            ) => {
                              const comment: ICreateComment = {
                                content: data,
                                parentId: "",
                                userMentioned: "",
                              };
                              await dispatch(
                                createPostComment(postId, comment, attachment)
                              );
                              // await fetchPostComments(postId);
                              await fetchPostComments(postId);
                              // await fetchPosts();
                              // await dispatch(getUserPosts());
                            }}
                            onCommentLike={async (
                              likeType: string,
                              commentId: string
                            ) => {
                              likeType
                                ? await dispatch(
                                  likeComment(commentId, likeType)
                                )
                                : await dispatch(unlikeComment(commentId));
                              // await dispatch(getUserPosts());
                            }}
                            onPostLike={async (
                              likeType: string,
                              postId: string
                            ) => {
                              // If you got like type then set the like on post else its remove like "undefined"
                              likeType
                                ? await dispatch(likePost(postId, likeType))
                                : await dispatch(unlikePost(postId));
                              // await dispatch(getUserPosts());
                            }}
                            onDelete={async () => {
                              const postService = new PostService();
                              await postService.deletePost(post.id);

                              resetToDefaults();
                              await fetchPosts();
                            }}
                            addReply={async (
                              postId: string,
                              replyText: string,
                              parentId: string,
                              attachment: any
                            ) => {
                              const comment: ICreateComment = {
                                content: replyText,
                                parentId,
                                userMentioned: '',
                              };
                              await dispatch(
                                createPostComment(postId, comment, attachment)
                              );
                              await fetchPostComments(postId);
                            }}
                            onEditPost={async (
                              postId: string,
                              content: string,
                              privacy: string
                            ) => {
                              await dispatch(
                                editPost(postId, content, privacy)
                              );
                              resetToDefaults();
                              await fetchPosts();
                            }}
                            onAfterShare={async () => { resetToDefaults(); await fetchPosts(); }}
                          />
                        )
                    )}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : <Loader isLoaderLogo className="h-[100vh]" key="loader" />;
};

export default PreEventLandingPage;
