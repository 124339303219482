import { getBrandingColors } from "src/mui/pages/event/live/liveEventStyling";

export const UploadImageSvgIcon = () => {
  const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return <svg xmlns="http://www.w3.org/2000/svg" width="20.073" height="19.944" viewBox="0 0 20.073 19.944">
    <g id="Icon_feather-image" data-name="Icon feather-image" transform="translate(0.35 0.35)">
      <path id="Path_583" data-name="Path 583" d="M6.638,4.5H21.605a2.138,2.138,0,0,1,2.138,2.138V21.606a2.138,2.138,0,0,1-2.138,2.138H6.638A2.138,2.138,0,0,1,4.5,21.606V6.638A2.138,2.138,0,0,1,6.638,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke={defaultColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.7"/>
      <path id="Path_584" data-name="Path 584" d="M12.517,11.508A1.008,1.008,0,1,1,11.508,10.5,1.008,1.008,0,0,1,12.517,11.508Z" transform="translate(-5.906 -5.908)" fill="none" stroke={defaultColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.7"/>
      <path id="Path_585" data-name="Path 585" d="M24.486,18.128,19.178,15,7.5,21.883" transform="translate(-5.242 -3.149)" fill="none" stroke={defaultColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.7"/>
    </g>
  </svg>
  
};