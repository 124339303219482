/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './deactivate-account.css';
import { Button } from 'src/shared-components/V2';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { UserService } from 'src/services';
import { useNavigate } from 'react-router-dom';
import BulbImage from '../../../assets/images/bulb-icon.png';
import { DeactivateUser } from './deactivate-user';

export const DeactivateAccount = () => {
  const navigate = useNavigate();
  const [isUserDeactiveModal, setIsUserDeactiveModal] = useState(false);
  const onUserDeactiveModal = () => {
    setIsUserDeactiveModal(!isUserDeactiveModal);
  };
  const onUserDeactive = async () => {
    const userService = new UserService();
    const result = await userService.userAccountDeactivate();
    if (result.message === 'SUCCESS') {
      toast.success(result.message);
      navigate('/logout');
    } else toast.error(result.message);
  };
  return (
    <div>
      <div className="mx-auto">
        <div className="">
          <div className="flex flex-col">
            <div className="text-center">
              <div className="flex justify-center capitalize p-title text-5xl py-16 font-medium">Deactivate Account</div>
            </div>
            <div className="section-bg w-full">
              <div className="block mb-8 w-9/12 mx-auto">
                <div className="block lg:flex flex-col  mx-auto mt-7 text-dark-blue text-md pt-56">
                  <div className="p-section-title">Panelist Pty Ltd (“Panelist”)</div>
                  <div className="mt-3 p-text">
                    We offer you choices about the information you share with us, the information we use and
                    the information we share, as described in the Privacy Policy below, our Cookie Policy and
                    User Agreement. This Privacy Policy applies to Panelist, Panelist branded apps and other
                    Panelist related services, including offsite services, and communications.
                  </div>
                </div>
              </div>
            </div>
            <div className="section-bg w-full">
              <div className="block w-9/12 mx-auto">
                <div className="p-section-title">Table of Contents</div>
                <div className="mt-3 font-normal text-md2 tracking-wide">
                  <div className="">
                    1.
                    <a href="#privacy" className="hover:underline link-color ml-2 p-text">
                      Privacy
                    </a>
                  </div>
                  <div className="mt-2">
                    2.
                    <a href="#your-personal-information" className="hover:underline link-color ml-2 p-text">
                      Your Personal Information
                    </a>
                  </div>
                  <div className="mt-2">
                    3.
                    <a href="#how-we-use-data" className="hover:underline link-color ml-2 p-text">
                      How we use data
                    </a>
                  </div>
                  <div className="mt-2">
                    4.
                    <a href="#gdpr" className="hover:underline link-color ml-2 p-text">
                      GDPR
                    </a>
                  </div>
                  <div className="mt-2">
                    5.
                    <a href="#security-information" className="hover:underline link-color ml-2 p-text">
                      Security of information
                    </a>
                  </div>
                  <div className="mt-2">
                    6.
                    <a href="#accessing-information" className="hover:underline link-color ml-2 p-text">
                      Accessing your information
                    </a>
                  </div>
                  <div className="mt-2">
                    7.
                    <a href="#compliants-changes" className="hover:underline link-color ml-2 p-text">
                      Complaints and changes
                    </a>
                  </div>
                  <div className="mt-2">
                    8.
                    <a href="#visit-our-websites-thirdparty" className="hover:underline link-color ml-2 p-text">
                      Visiting our website and 3rd party sites
                    </a>
                  </div>

                </div>
              </div>
            </div>
            <div className="section-bg w-full">
              <div className="block w-9/12 mx-auto">
                <div id="privacy" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title">1. We respect your privacy.</div>
                </div>
                <div className="font-normal text-gray-1 text-md2">
                  <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                    <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                    <div className="p-text">
                      We collect your information that enables you to use and interact with our products and
                      services and we store it securely.
                    </div>
                  </div>
                  <div className="mt-3 p-text">
                    (a) Panelist respects your right to privacy and is committed to safeguarding the privacy of
                    our customers and website visitors. This policy sets out how we collect and treat your
                    personal information.
                  </div>
                  <div className="mt-5 p-text">
                    (b) We adhere to the Australian Privacy Principles contained in the Privacy Act 1988 (Cth)
                    and to the extent applicable, the EU General Data Protection Regulation (GDPR).
                  </div>
                  <div className="mt-5 p-text">
                    (c) "Personal information" is information we hold which is identifiable as being about you.
                    This includes information such as your name, email address, identification number, or any
                    other type of information that can reasonably identify an individual, either directly or
                    indirectly.
                  </div>
                  <div className="mt-5 p-text">
                    (d) You may contact us via email at
                    <a
                      className="text-blue-1 underline hover:no-underline"
                      href="mailto:support@team.panelist.com"
                    >
                      support@team.panelist.com
                    </a>
                    for further information
                    about this Privacy Policy.
                  </div>
                </div>
                <hr className="my-10" />
                <div id="privacy" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title">2. What personal information is collected</div>
                </div>

                <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                  <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                  <div className="p-text">
                    We collect your information from places other than our website or mobile app, including synced calendars
                    or contacts.
                  </div>
                </div>

                <div className="mt-3 p-text">
                  (a) Panelist will, from time to time, receive and store personal information you submit to our
                  website or mobile app, provided to us directly or given to us in other forms.
                </div>
                <div className="mt-5 p-text">
                  (b) You may provide basic information such as your name, phone number, address and email
                  address to enable us to send you information, provide updates and process your product or
                  service order.
                </div>
                <div className="mt-5 p-text">
                  (c) We may collect additional information at other times, including but not limited to, when
                  you provide feedback, when you provide information about your personal or business affairs,
                  change your content or email preference, respond to surveys and/or promotions, provide
                  financial or credit card information, or communicate with our customer support.
                </div>
                <div className="mt-5 p-text">
                  (d) Additionally, we may also collect any other information you provide while interacting with
                  us.
                </div>
                <div className="mt-5 p-text">
                  (e) As we improve our services to you, we may need to collect new information. If we change
                  the way we collect information or change the information we collect, share or use your
                  information, we will notify you accordingly and modify this Privacy Policy.
                </div>
                <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                  <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                  <div className="p-text">
                    Your information is used only for the purpose of providing you with access to our products and services, and for continually improving our products and services.
                  </div>
                </div>
                <hr className="my-10" />
                <div id="how-we-use-data" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title">3. How we collect your personal information</div>
                </div>
                <div className="mt-7 p-text">
                  (a) Panelist collects personal information from you in a variety of ways, including when you
                  interact with us electronically or in person, when you access our website or mobile app and when we engage
                  in business activities with you. We may receive personal information from third parties. If
                  we do, we will protect it as set out in this Privacy Policy.
                </div>
                <div className="mt-3 p-text">
                  (b) By providing us with personal information, you consent to the supply of that information
                  subject to the terms of this Privacy Policy.
                </div>
                <hr className="my-10" />
                <div id="how-we-use-data" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title">4. How we use your personal information</div>
                </div>
                <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                  <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                  <div className="p-text">
                    We do not share your information unless it is for the purpose of providing our service to
                    you or required by law.
                  </div>
                </div>
                <div className="mt-5 p-text">
                  (a) Panelist may use personal information collected from you to provide you with information
                  about our products or services. We may also make you aware of new and additional products,
                  services and opportunities available to you.
                </div>
                <div className="mt-5 p-text">
                  (b) Panelist will use personal information only for the purposes that you consent to. This may
                  include to:
                </div>
                <div className="ml-10">
                  <div className="mt-5 p-text">
                    (i) provide you with products and services during the usual course of our business
                    activities;
                  </div>
                  <div className="mt-5 p-text">(ii) administer our business activities;</div>
                  <div className="mt-5 p-text">(iii) manage, research and develop our products and services;</div>
                  <div className="mt-5 p-text">(iv) provide you with information about our products and services;</div>
                  <div className="mt-5 p-text">
                    (v) communicate with you by a variety of measures including, but not limited to, by
                    telephone, email, sms or mail;
                  </div>
                  <div className="mt-5 p-text">(vi) investigate any complaints.</div>
                </div>

                <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                  <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                  <div className="p-text">
                    If you withhold your personal information, it may not be possible for us to provide you with
                    our products and services or for you to fully access our website or mobile app.
                  </div>
                </div>
                <div className="mt-5 p-text">
                  (c) We may disclose your personal information to comply with a legal requirement, such as a
                  law, regulation, court order, subpoena, warrant, legal proceedings or in response to a law
                  enforcement agency request.
                </div>
                <div className="mt-5 p-text">
                  (d) If there is a change of control in our business or a sale or transfer of business assets,
                  we reserve the right to transfer to the extent permissible at law our user databases, together
                  with any personal information and non-personal information contained in those databases.
                </div>
                <div className="mt-5 p-text">
                  (e) We use your data to respond to and investigate problems on our website, such as bugs, that
                  may occur on our website from time to time.
                </div>
                <hr className="my-10" />
                <div id="how-we-use-data" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title">5. Data Retention</div>
                </div>

                <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                  <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                  <div className="p-text">
                    We retain your data in order to improve our services and provide you with access to
                    services.
                  </div>
                </div>
                <div className="mt-5 p-text">
                  (a) We retain your data for as long as needed to provide a service to you. Even if you rarely
                  use our services over a period of time, we will retain your data and keep your profile open
                  until you decide to close your account. We will retain general marketing insights used to
                  improve the performance of our website. We will delete your data within 30 days of account
                  closure.
                </div>
                <hr className="my-10" />
                <div id="how-we-use-data" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title">6. No disclosure of your personal informationn</div>
                </div>

                <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                  <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                  <div className="p-text">
                    Your data will remain within the confines of our service and not be disclosed to a third party.
                  </div>
                </div>
                <hr className="my-10" />
                <div id="how-we-use-data" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title">7. General Data Protection Regulation (GDPR) for the European Union (EU)</div>
                </div>

                <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                  <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                  <div className="p-text">
                    We comply with GDPR.
                  </div>
                </div>
                <div className="mt-3 p-text">
                  (a) Panelist will comply with the principles of data protection set out in the GDPR for the
                  purpose of fairness, transparency and lawful data collection and use.
                </div>
                <div className="mt-5 p-text">
                  (b) We process your personal information as a Processor and/or to the extent that we are a
                  Controller as defined in the GDPR.
                </div>
                <div className="mt-5 p-text">
                  (c) We must establish a lawful basis for processing your personal information. The legal basis
                  for which we collect your personal information depends on the data that we collect and how we
                  use it.
                </div>
                <div className="mt-5 p-text">
                  (d) We will only collect your personal information with your express consent for a specific
                  purpose and any data collected will be to the extent necessary and not excessive for its
                  purpose. We will keep your data safe and secure.
                </div>
                <div className="mt-5 p-text">
                  (e) We will also process your personal information if it is necessary for our legitimate
                  interests, or to fulfil a contractual or legal obligation.
                </div>
                <div className="mt-5 p-text">
                  (f) We process your personal information if it is necessary to protect your life or in a
                  medical situation, it is necessary to carry out a public function, a task of public interest
                  or if the function has a clear basis in law.
                </div>
                <div className="mt-5 p-text">
                  (g) We do not collect or process any personal information from you that is considered
                  "Sensitive Personal Information" under the GDPR, such as personal information relating to your
                  sexual orientation or ethnic origin unless we have obtained your explicit consent, or if it is
                  being collected subject to and in accordance with the GDPR.
                </div>
                <div className="mt-5 p-text">
                  (h) You must not provide us with your personal information if you are under the age of 16
                  without the consent of your parent or someone who has parental authority for you. We do not
                  knowingly collect or process the personal information of children.
                </div>
                <hr className="my-10" />
                <div id="gdpr" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title mb-28p">8. Your rights under the GDPR</div>
                </div>
                <div className="mt-7 p-text">
                  (a) If you are an individual residing in the EU, you have certain rights as to how your personal information is obtained and used. Panelist complies with your rights under the GDPR as to how your personal information is used and controlled if you are an individual residing in the EU.
                </div>
                <div className="mt-5 p-text">
                  (b) Except as otherwise provided in the GDPR, you have the following rights:
                </div>
                <div className="ml-10">
                  <div className="mt-5 p-text">
                    (i) to be informed how your personal information is being used;
                  </div>
                  <div className="mt-5 p-text">
                    (ii) access your personal information (we will provide you with a free copy of it);
                  </div>
                  <div className="mt-5 p-text">
                    (iii) to correct your personal information if it is inaccurate or incomplete;
                  </div>
                  <div className="mt-5 p-text">
                    (iv) to delete your personal information (also known as "the right to be forgotten");
                  </div>
                  <div className="mt-5 p-text">
                    (v) to restrict processing of your personal information;
                  </div>
                  <div className="mt-5 p-text">
                    (vi) to retain and reuse your personal information for your own purposes;
                  </div>
                  <div className="mt-5 p-text">
                    (vii) to object to your personal information being used;
                  </div>
                  <div className="mt-5 p-text">
                    (viii) to object against automated decision making and profiling.
                  </div>
                </div>
                <div className="mt-5 p-text">
                  (c) Please contact us at any time to exercise your rights under the GDPR at the contact details in this Privacy Policy.
                </div>
                <div className="mt-5 p-text">
                  (d) We may ask you to verify your identity before acting on any of your requests.
                </div>
                <div className="mt-5 p-text">
                  (e) Residents in certain Countries or Regions may have additional rights under their laws.
                </div>
                <hr className="my-10 " />
                <div id="" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title mb-28p">9. Hosting and International Data Transfers</div>
                </div>
                <div className="mt-7 p-text">
                  (a) Information that we collect may from time to time be stored, processed in, or transferred
                  between parties or sites located in countries outside of Australia. These may include but are
                  not limited to Australia.
                </div>
                <div className="mt-5 p-text">
                  (b) We and our other group companies have offices and/or facilities in Australia. Transfers to
                  each of these countries will be protected by appropriate safeguards, these include one or more
                  of the following: the use of standard data protection clauses adopted or approved by the
                  European Commission which you can obtain from the European Commission Website; the use of
                  binding corporate rules, a copy of which you can obtain from Panelist's Data Protection
                  Officer.
                </div>
                <div className="mt-5 p-text">
                  (c) The hosting facilities for our website are situated in Australia. Transfers to each of
                  these Countries will be protected by appropriate safeguards, these include one or more of the
                  following: the use of standard data protection clauses adopted or approved by the European
                  Commission which you can obtain from the European Commission Website; the use of binding
                  corporate rules, a copy of which you can obtain from Panelist's Data Protection Officer.
                </div>
                <div className="mt-5 p-text">
                  (d) Our Suppliers and Contractors are situated in Australia. Transfers to each of these
                  Countries will be protected by appropriate safeguards, these include one or more of the
                  following: the use of standard data protection clauses adopted or approved by the European
                  Commission which you can obtain from the European Commission Website; the use of binding
                  corporate rules, a copy of which you can obtain from Panelist's Data Protection Officer.
                </div>
                <div className="mt-5 p-text">
                  (e) You acknowledge that personal data that you submit for publication through our website or
                  services may be available, via the internet, around the world. We cannot prevent the use (or
                  misuse) of such personal data by others.
                </div>
                <hr className="my-10" />
                <div id="security-information" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title">10. Security of your personal information</div>
                </div>

                <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                  <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                  <div className="p-text">
                    Although we are committed to protecting your information, it is ultimately provided to us at
                    your own risk.
                  </div>
                </div>
                <div className="ml-10">
                  <div className="mt-5 p-text">
                    (a) Panelist is committed to ensuring that the information you provide to us is secure. In
                    order to prevent unauthorised access or disclosure, we have put in place suitable physical,
                    electronic and managerial procedures to safeguard and secure information and protect it from
                    misuse, interference, loss and unauthorised access, modification and disclosure.
                  </div>
                  <div className="mt-5 p-text">
                    (b) Where we employ data processors to process personal information on our behalf, we only
                    do so on the basis that such data processors comply with the requirements under the GDPR and
                    that have adequate technical measures in place to protect personal information against
                    unauthorised use, loss and theft.
                  </div>
                  <div className="mt-5 p-text">
                    (c) The transmission and exchange of information is carried out at your own risk. We cannot
                    guarantee the security of any information that you transmit to us, or receive from us.
                    Although we take measures to safeguard against unauthorised disclosures of information, we
                    cannot assure you that personal information that we collect will not be disclosed in a
                    manner that is inconsistent with this Privacy Policy.
                  </div>
                </div>
                <hr className="my-10" />
                <div id="accessing-information" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title">11. Access to your personal information</div>
                </div>

                <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                  <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                  <div className="p-text">
                    You may request details of your personal information by emailing us.
                  </div>
                </div>
                <div className="mt-3 p-text">
                  (a) You may request details of personal information that we hold about you in accordance with
                  the provisions of the Privacy Act 1988 (Cth), and to the extent applicable the EU GDPR. If you
                  would like a copy of the information which we hold about you or believe that any information
                  we hold on you is inaccurate, out of date, incomplete, irrelevant or misleading, please email
                  us at
                  {' '}
                  <a className="text-blue-1 hover:underline" href="mailto:user-compliance@panelist.com">user-compliance@panelist.com</a>
                  .
                </div>
                <div className="mt-3 p-text">
                  (b) We reserve the right to refuse to provide you with information that we hold about you, in
                  certain circumstances set out in the Privacy Act or any other applicable law.
                </div>
                <hr className="my-10" />
                <div id="compliants-changes" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title">12. Complaints about privacy</div>
                </div>

                <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                  <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                  <div className="p-text">
                    We take complaints seriously and will respond to you in a timely manner.
                  </div>
                </div>
                <div className="mt-5 p-text">
                  If you have any complaints about our privacy practices, please feel free to send in details of
                  your complaints to
                  {' '}
                  <a className="text-blue-1 hover:underline" href="mailto:user-compliance@panelist.com">user-compliance@panelist.com</a>
                  . We take complaints very seriously and will respond shortly after
                  receiving written notice of your complaint.
                </div>
                <hr className="my-10 p-text" />
                <div id="" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title">13. Changes to Privacy Policy</div>
                </div>

                <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                  <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                  <div className="p-text">
                    It is up to you to check our website for changes to our policies.
                  </div>
                </div>
                <div className="mt-5 p-text">
                  (a) Please be aware that we may change this Privacy Policy in the future. We may modify this
                  Policy at any time, in our sole discretion and all modifications will be effective immediately
                  upon our posting of the modifications on our website or notice board. Please check back from
                  time to time to review our Privacy Policy.
                </div>
                <hr className="my-10" />
                <div id="visit-our-websites-thirdparty" className="block lg:flex flex-col  mx-auto mt-40p">
                  <div className="p-section-title">14. Website</div>
                </div>

                <div className="w-full flex item-start info-box p-4 rounded-5 mt-5">
                  <img className="info-box-bulb mr-3" src={BulbImage} alt="" />
                  <div className="p-text">
                    We may collect certain information about you in order to analyse how people interact with
                    our website.
                  </div>
                </div>
                <div className="p-text mt-28p link-color font-500">(a) When you visit our website</div>
                <div className="mt-3 p-text">
                  When you come to our website (panelist.com), we may collect certain information such as
                  browser type, operating system, website visited immediately before coming to our site, etc.
                  This information is used in an aggregated manner to analyse how people use our site, such that
                  we can improve our service.
                </div>

                <div className="p-text mt-28p link-color font-500">(b) Cookies</div>
                <div className="mt-3 p-text">
                  We may from time to time use cookies on our website. Cookies are very small files which a
                  website uses to identify you when you come back to the site and to store details about your
                  use of the site. Cookies are not malicious programs that access or damage your computer. Most
                  web browsers automatically accept cookies but you can choose to reject cookies by changing
                  your browser settings. However, this may prevent you from taking full advantage of our
                  website. Our website may from time to time use cookies to analyses website traffic and help us
                  provide a better website visitor experience. In addition, cookies may be used to serve
                  relevant ads to website visitors through third party services such as Google AdWords. These
                  ads may appear on this website or other websites you visit.
                </div>

                <div className="p-text mt-28p link-color font-500">(c) Third party sites</div>

                <div className="mt-3 p-text">
                  Our site may from time to time have links to other websites not owned or controlled by us.
                  These links are meant for your convenience only. Links to third party websites do not
                  constitute sponsorship or endorsement or approval of these websites. Please be aware that
                  Panelist is not responsible for the privacy practises of other such websites. We encourage our
                  users to be aware, when they leave our website, to read the privacy statements of each and
                  every website that collects personal identifiable information.
                </div>
                <div className="my-9">
                  <Button
                    text="Deactivate account"
                    className="h-[30px] bg-[#0049EF] hover:bg-[#2e6eff] rounded-[5px] text-white text-sm font-semibold leading-[20px] tracking-wide"
                    type="button"
                    onClick={onUserDeactiveModal}
                  />
                </div>
                {isUserDeactiveModal && (
                  <AlertModal isCloseIcon closeIconClass="right-[7px] top-[14px]" isOpen={isUserDeactiveModal} onClose={onUserDeactiveModal} className="w-[374px] bg-[#EDF2F5]">
                    <DeactivateUser
                      message="Are you sure you want to deactivate your account?"
                      onDelete={async () => {
                        (await onUserDeactive());
                        onUserDeactiveModal();
                      }}
                      onClose={onUserDeactiveModal}
                    />
                  </AlertModal>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeactivateAccount;
