import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import InfiniteScroll from 'react-infinite-scroller';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import { getOwnConnections } from 'src/store/user/own-connections/actions';
import commentIcon from '../../../../assets/images/comment-icon.svg';
import noPostsImage from '../../../../assets/images/no-post-discussion.svg';
import shareIcon from '../../../../assets/images/share-icon.svg';
import { CardMainPost } from '../../../../components/posts';
import CreatePost from '../../../../components/posts/create-post';
import { WelcomePost } from '../../../../components/welcome-post';
import { ICompanyProfileState } from '../../../../models/company/CompanyProfileState';
import { ICreatePoll } from '../../../../models/poll/poll';
import { ICreateComment } from '../../../../models/post/comment';
import { IPost } from '../../../../models/post/post';
import {
  CompanyService,
  PostService,
} from '../../../../services';
import { Loader } from '../../../../shared-components/Loader';
import { SortBy } from '../../../../shared-components/V2/SortBy';
import { IAppState } from '../../../../store';
import { getCompanyPosts } from '../../../../store/company/company-posts/actions';
import { editPost } from '../../../../store/events/event-post-actions/actions';
import { unlikeComment } from '../../../../store/events/event-post-comment-like/actions';
import {
  likePost,
  unlikePost,
} from '../../../../store/events/upcoming-event-post-like/actions';
import { createPoll } from '../../../../store/poll';
import {
  createPostComment,
  likeComment,
} from '../../../../store/posts/comments/actions';
import {
  getUserPostComments,
} from '../../../../store/user/posts/actions';

export const CompanyPosts = (props: { viewingAsAdmin: boolean }) => {
  const companyService: CompanyService = new CompanyService();

  const params = useParams();
  const { slug: userId } = useParams();
  const companyIdOrSlug = String(Object.values(params)[0]);

  const { companyProfilePreview, posts, auth } = useSelector(
    (state: IAppState) => state
  );
  const { currentUser: user } = auth;

  const dispatch = useDispatch();
  const [companyFeeds, setCompanyFeeds] = useState<IPost[]>([]);
  const [nextPage, setNextPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  // const [companyFeedsLoading, setCompanyFeedsLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const hasMoreItems = nextPage <= totalPage;
  const [companyProfile, setCompanyProfile] = useState<ICompanyProfileState>();
  const isOwner = companyProfile?.value?.isAdmin;
  const [orderBy, setOrderBy] = useState<'Most recent' | 'Top posts'>(
    'Most recent'
  );

  const resetDefaults = () => {
    setNextPage(1);
    setTotalPage(0);
    setFetching(false);
    setCompanyFeeds([]);
  };

  useEffect(() => {
    setIsOwnProfile(user?.id === userId || user?.slug === userId);
  }, [userId, user]);

  useEffect(() => {
    if (isOwnProfile) {
      dispatch(getOwnConnections());
    } else dispatch(getOwnConnections(userId));
  }, [dispatch, isOwnProfile, userId]);

  useEffect(() => {
    if (companyProfilePreview) setCompanyProfile(companyProfilePreview);
  }, [companyProfilePreview]);

  useEffect(() => {
    dispatch(getCompanyPosts(companyIdOrSlug));
  }, [dispatch, companyIdOrSlug]);

  useEffect(() => {
    if (posts) setTotalPage(posts.value?.totalPage!);
  }, [posts]);

  const fetchPosts = async () => dispatch(getCompanyPosts(companyIdOrSlug, 1, orderBy));

  const fetchItems = useCallback(
    async () => {
      if (fetching) {
        return;
      }

      setFetching(true);

      let sortBy: string = '';
      if (orderBy === 'Most recent') sortBy = '&order[createdAt]=DESC';
      else sortBy = '&order[rank]=DESC';

      try {
        const { postsResult } = await companyService.getCompanyPosts(
          companyIdOrSlug,
          nextPage,
          sortBy
        );
        setTotalPage(postsResult.totalPage);
        if (postsResult && nextPage <= postsResult.totalPage) {
          setCompanyFeeds(companyFeeds.concat(postsResult.data));
          setNextPage(nextPage + 1);
        } else {
          setNextPage(nextPage);
          setFetching(true);
        }
      } finally {
        setFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyFeeds, fetching, nextPage, companyIdOrSlug]
  );

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (companyIdOrSlug) fetchItems();

    resetDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyIdOrSlug]);

  // const loader = <Loader className="pt-5" key="loader" />;

  const sortClick = async (sortType: 'Most recent' | 'Top posts') => {
    resetDefaults();
    setOrderBy(sortType);
    await fetchPosts();
  };

  return (
    <div>
      {!!isOwner && (
        <CreatePost
          wallId={companyProfile?.value?.id!}
          location="company"
          avatarUrl={
            companyProfilePreview.value?.logo
              ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${companyProfile?.value?.logo}`
              : ''
          }
          userDisplayName={companyProfile?.value?.name!}
          onCreatePoll={(data: ICreatePoll) => dispatch(createPoll(data))}
          onAfterSave={() => resetDefaults()}
        />
      )}
      {
        (companyFeeds?.length > 0) && (
          <div className="flex items-center flex-row py-[4px] my-auto">
            <div className="w-full border-b-1 border-[#707070] opacity-[28%]" />
            <div className="">
              <SortBy
                sortClick={sortClick}
                descOrderText="Most recent"
                ascOrderText="Top posts"
              />
            </div>
          </div>
        )
      }
      <div className="overflow-visible">

        <InfiniteScroll
          pageStart={0}
          loadMore={fetchItems}
          hasMore={hasMoreItems}
          loader={<Loader className="pt-5" key="loader" />}
        >
          {/* No Post Welcome component */}
          {((!companyFeeds || companyFeeds.length === 0) && !fetching) && (
          <WelcomePost
            className={classNames({
              'mt-4': companyFeeds.length === 0 && isOwner,
            })}
            title={`Welcome to Panelist, ${companyProfile?.value?.name}!`}
            imageSrc={noPostsImage}
            subTitle="Connect with people you know and join events you're interested in."
          />
          )}
          {
             companyFeeds?.map((post: IPost, index: number) => (
               <div key={`event-${index}`} className="mb-4 rounded-[15px]">
                 <CardMainPost
                   post={post}
                   commentIcon={commentIcon}
                   shareIcon={shareIcon}
                   className="mb-4 w-full bg-white create-post-card"
                   key={`event-${index}`}
                   getPostComments={(id: string) => dispatch(getUserPostComments(id))}
                   addPostComment={async (
                     postId: string,
                     data: any,
                     attachment: any,
                     companyId?: string
                   ) => {
                     let comment: ICreateComment;
                     if (companyId) {
                       comment = {
                         content: data,
                         parentId: '',
                         companyId,
                         type: 'company',
                       };
                       await dispatch(
                         createPostComment(postId, comment, attachment, companyId)
                       );
                     } else {
                       comment = {
                         content: data,
                         parentId: '',
                         userMentioned: '',
                       };

                       await dispatch(
                         createPostComment(postId, comment, attachment)
                       );
                     }
                     await dispatch(getUserPostComments(postId));
                     await dispatch(getCompanyPosts(companyProfile?.value?.id));
                   }}
                   addReply={async (
                     postId: string,
                     replyText: string,
                     parentId: string,
                     attachment: any,
                     companyId?: string
                   ) => {
                     let comment: ICreateComment;
                     if (companyId) {
                       comment = {
                         content: replyText,
                         parentId,
                         companyId,
                         type: 'company',
                       };
                       await dispatch(
                         createPostComment(postId, comment, attachment, companyId)
                       );
                     } else {
                       comment = {
                         content: replyText,
                         parentId,
                         userMentioned: '',
                       };
                       await dispatch(
                         createPostComment(postId, comment, attachment)
                       );
                     }

                     await dispatch(getUserPostComments(postId));
                   }}
                   onCommentLike={async (likeType: string, commentId: string) => {
                     if (likeType) {
                       await dispatch(likeComment(commentId, likeType));
                     } else {
                       await dispatch(unlikeComment(commentId));
                     }
                     // await dispatch(getUserPosts());
                   }}
                   onPostLike={async (likeType: string, postId: string) => {
                     // If you got like type then set the like on post else its remove like 'undefined'
                     if (likeType) {
                       await dispatch(likePost(postId, likeType));
                     } else {
                       await dispatch(unlikePost(postId));
                     }
                     // await dispatch(getUserPosts());
                   }}
                   onDelete={async () => {
                     const postService = new PostService();
                     await postService.deletePost(post.id);

                     resetDefaults();
                     await fetchPosts();
                   }}
                   onEditPost={async (
                     postId: string,
                     content: string,
                     privacy: string
                   ) => {
                     await dispatch(editPost(postId, content, privacy));
                     resetDefaults();
                     await fetchPosts();
                   }}
                   onAfterShare={async () => { resetDefaults(); await fetchPosts(); }}
                   viewingAsCompanyAdmin={props.viewingAsAdmin}
                 />
               </div>
             ))
}
        </InfiniteScroll>

      </div>
    </div>
  );
};
