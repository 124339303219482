/* eslint-disable max-len */
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { PageTitle } from 'src/screens/event/factor-website/components/PageTitle';
import React from 'react';
import { isWithinEventWindow } from 'src/mui/components/hook-form/Common';
import { FactorExperts } from '../../components/factor-experts-speakers';
import { FactorIndustrySpeakers } from '../../components/factor-industry-speakers';

export const SpeakerPage = () => {
  const event = useSelector((state: IAppState) => state.website.event!);
  const isEventDateWithin80Days = isWithinEventWindow({
    date: event?.startTime,
    status: event?.status
  });
  const speakers = useSelector(
    (state: IAppState) => state.website.event!.speakers
  );
  const factorSpeakers = speakers.filter((speaker:any) => {
    const emailDomain = speaker?.email.split('@')[1];
    return emailDomain === 'team.panelist.com' || emailDomain === 'sit-down.com';
  });
  const industrySpeakers = speakers.filter((speaker:any) => {
    const emailDomain = speaker?.email.split('@')[1];
    return emailDomain !== 'team.panelist.com' && emailDomain !== 'sit-down.com';
  });

  return (
    <div>
      <PageTitle
        title={isEventDateWithin80Days ? 'Experts & Speakers' : 'Past Experts'}
        description="Your industry, profession, and role are continually evolving. To stay ahead, you need to uncover new trends and address challenges head-on. Factor’s CIO Day 2024 conference offers actionable insights, cutting-edge technologies, and valuable connections to help you navigate disruptions, leverage AI, and lead your organization with confidence."
      />
      { factorSpeakers?.length ? <FactorExperts factorSpeakers={factorSpeakers} isEventDateWithin80Days={isEventDateWithin80Days} /> : null}
      { industrySpeakers?.length ? <FactorIndustrySpeakers industrySpeakers={industrySpeakers} isEventDateWithin80Days={isEventDateWithin80Days} /> : null}
    </div>
  );
};
