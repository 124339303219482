import { useState, useCallback, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IChatParticipant } from "src/mui/types/chat";
import ChatMessageList from "../../../components/chat/chat-message-list";
import ChatMessageInput from "../../../components/chat/chat-message-input";
import ChatHeaderDetail from "src/mui/components/chat/chat-header-detail";
import { IAttendee } from "src/models/events-live/attendee";
import { useWebSocket } from "src/utils/hooks";
import { useDispatch } from "react-redux";
import { clearLiveMessages, handleFailedMessages } from "src/store/privateChat";
import { setActiveRoom as setActiveRoomStore } from "src/store/liveEvent";
import { LoadingScreen } from "src/mui/components/loading-screen";
import { Divider, Tooltip, useTheme } from "@mui/material";
import { getBrandingColors } from './liveEventStyling';
import ArrowIcon from "src/mui/assets/icons/TabsArrowIcons";
import { maxWidth } from "@mui/system";
// ----------------------------------------------------------------------
type ChatProps = {
  rooms: any;
  user?: any;
  id?: string;
  event?: any;
  attendees: IAttendee[];
  isGridView?: boolean;
  columnCardHeight?: string;
  totalParticipantsCount: number;
};


const ChatView = ({
  rooms,
  id,
  user,
  attendees,
  event,
  isGridView,
  columnCardHeight,
  totalParticipantsCount,
}: ChatProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [repliedMessage, setRepliedMessage] = useState<any>(null);
  const [editMessage, setEditMessage] = useState<any>(null);
  const [activeRoom, setActiveRoom] = useState<any>();
  const [activeUserAvatar, setActiveUserAvatar] = useState<any>();
  const [isOnline, setIsOnline] = useState<any>();
  // const { user } = useMockedUser();
  const colors = getBrandingColors();

  const agendaTabStyles = {
    position: 'relative',
    "& > .MuiTabScrollButton-root": {
        position: 'absolute',
        right: '-32px',
        top: '0px',
        zIndex: 100,
        height: '28px',
        width: '31px'
    },
    // "& > .MuiTabScrollButton-root.Mui-disabled:first-of-type": {
    //     display: 'none',
    // },
    "& .MuiSvgIcon-root": {
        color: '#ffffff',
        width: '28px',
        height: '28px'
    },
    "& .MuiTabs-flexContainer": {
        columnGap: '6px'
    },
    "&.MuiTabs-root": {
        minHeight: '28px',
        maxHeight: '28px',
        overflow: 'visible',
        maxWidth: 'calc(100% - 11px)',
    },
    "& .MuiTab-root": {
        padding: '0px',
        minWidth: '64px',
        minHeight: '28px',
        maxHeight: '28px',
        // backgroundColor: colors?.Secondary! || '#2F2F2F',
        border: `1px solid ${colors?.Secondary! || '#ffffffe3'}`,
        backgroundColor: colors?.Secondary! || '#ffffffe3',
        borderRadius: '5px !important',
        color: `${colors?.Accent!} !important` || '#ffffff !important',
        px: '12px',
        fontFamily: 'Poppins-500',
        fontWeight: 500,
        fontSize: '13px',
        margin: '0px !important',
        "& span": {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '300px'
        }
    },
    "& .Mui-selected": {
        backgroundColor: colors?.Accent! || '#03FFCD',
        border: `1px solid ${colors?.Accent! || '#03FFCD'}`,
        opacity: "1",
        borderRadius: '5px',
        fontSize: '13px',
        // border: 'none',
        color: `${colors?.ButtonTextColor!} !important` || '#000000 !important',
        lineHeight: '28px',
        fontFamily: 'Poppins-600',
    },
    "& .MuiTabs-indicator": {
        display: 'none'
    },
    ">.MuiTabScrollButton-root": {
        borderLeft: `1px solid ${colors?.Text! || '#ffffffe3'}`,
        borderRadius: 0,
        width: '15px',
        right: '-15px'
    },
    ">.MuiTabScrollButton-root:nth-of-type(1)": {
        right: 'auto',
        borderRight: `1px solid ${colors?.Text! || '#ffffffe3'}`,
        borderLeft: 'none',
        left: '-3px',
        background: colors?.Main!,
    }
};

  const smUp = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const cardProperties = {
    height: smUp
      ? "calc(100vh - 170px)"
      : columnCardHeight === "half-height"
      ? "calc(50vh - 70px)"
      : "calc(100vh - 70px)",
    pt: "8px",
    px: "3px",
  };

  const selectedConversationId = "2aeeb999-f6c2-4258-a642-353c51c5aa40";

  const [recipients, setRecipients] = useState<IChatParticipant[]>([]);

  const { webSocket, refreshConnection } = useWebSocket();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setActiveRoom(rooms.filter((item: any) => item.id === newValue)[0]);
  };

  const handleAddRecipients = useCallback((selected: IChatParticipant[]) => {
    setRecipients(selected);
  }, []);

  useEffect(() => {
    if (activeRoom) {
      dispatch(clearLiveMessages());
      dispatch(setActiveRoomStore(activeRoom));
    }
  }, [activeRoom]);

  useEffect(() => {
    if (event?.id) {
      setActiveRoom(event?.rooms?.[0]);
      setValue(event?.rooms?.[0]?.id);
    }
  }, [event?.id]);

  // Get chat-room chat
  useEffect(() => {
    if (!activeRoom?.id) return;
    webSocket.then((ws) =>
      ws.send(
        JSON.stringify({
          action: "live-messages",
          data: {
            eventId: id,
            eventRoomId: activeRoom?.id,
            option: {
              limit: 20,
              sorted: false,
              exclusiveStartKey: null,
            },
          },
        })
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoom?.id, webSocket]);

  enum ReactionType {
    LIVE_REACTION_CLAP_MESSAGE = "live-reaction-clap-message",
    LIVE_REACTION_LIKE_MESSAGE = "live-reaction-like-message",
    LIVE_REACTION_LOVE_MESSAGE = "live-reaction-love-message",
    LIVE_REACTION_HAHA_MESSAGE = "live-reaction-haha-message",
    LIVE_REACTION_SMILE_MESSAGE = "live-reaction-smile-message",
  }

  useEffect(() => {
    if (id) {
      webSocket.then((ws) =>
        ws.send(
          JSON.stringify({ action: "join-event", data: { eventId: event?.id } })
        )
      );
    }
  }, [id, webSocket]);

  // ononline version
  window.ononline = () => {
    setIsOnline(true);
    console.log("Now connected to network");
  };

  // onofflinee version
  window.onoffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    if (isOnline) {
      webSocket.then((ws) => {
        // console.log(ws.readyState)
        ws.send('{"action": "ping","data": { "data": "PONG"}}');
      });
    }
  }, [isOnline]);

  const addMessage = async (
    _sender: string,
    message: string,
    fileUrl: string,
    fileType: string
  ) => {
    if (activeRoom) {
      const ws = await webSocket;
      const action = repliedMessage
        ? "live-reply-message"
        : editMessage
        ? "live-update-message"
        : "live-send-message";

      const wsMessage = JSON.stringify({
        action,
        data: {
          eventId: id,
          eventRoomId: activeRoom.id,
          type: fileType || "text",
          message: message || fileUrl,
          metadata: "",
          replyToMessageId: repliedMessage?.messageId,
          messageId: editMessage?.messageId,
          source: fileUrl,
        },
      });
      setEditMessage(null);
      setRepliedMessage(null);
      if (ws.readyState !== ws.OPEN) {
        dispatch(handleFailedMessages(wsMessage));
        return refreshConnection({});
      }
      ws.send(wsMessage!);
    }
  };
  const handleMessageDelete = async (messageId: string) => {
    const ws = await webSocket;
    let wsMessage = "";
    wsMessage = JSON.stringify({
      action: "live-remove-message",
      data: {
        eventId: id,
        messageId,
      },
    });

    if (ws.readyState !== ws.OPEN) {
      dispatch(handleFailedMessages(wsMessage));
      return refreshConnection({});
    }
    ws.send(wsMessage!);
  };

  const onMessageReact = async (_sender: string, type: string) => {
    if (activeRoom) {
      const ws = await webSocket;
      let wsMessage = "";
      let action = "";
      switch (type) {
        case "clap":
          action = ReactionType.LIVE_REACTION_CLAP_MESSAGE;
          break;
        case "like":
          action = ReactionType.LIVE_REACTION_LIKE_MESSAGE;
          break;
        case "haha":
          action = ReactionType.LIVE_REACTION_HAHA_MESSAGE;
          break;
        case "smile":
          action = ReactionType.LIVE_REACTION_SMILE_MESSAGE;
          break;
        case "love":
          action = ReactionType.LIVE_REACTION_LOVE_MESSAGE;
          break;
        default:
          break;
      }
      wsMessage = JSON.stringify({
        action,
        data: {
          eventId: id,
          messageId: _sender,
        },
      });

      if (ws.readyState !== ws.OPEN) {
        dispatch(handleFailedMessages(wsMessage));
        return refreshConnection({});
      }
      ws.send(wsMessage!);
    }
  };

  const renderHead = (
    <>
      <Stack
        sx={{
          whiteSpace: "nowrap",
          alignItems: "center",
        }}
        direction={"row"}
      >
        {/* <Chip
            label="Chat Rooms"
            sx={{
                backgroundColor: '#EDF2F5',
                borderRadius: '5px',
                width: '105px',
                height: '29px',
                fontSize: '13px',
                // fontWeight: 'fontWeightMedium',
                color: '#203C6E',
                typography: 'subtitle3',
                fontFamily: 'Poppins-500'
            }}
        />
        <Stack sx={{ width: '1px', minWidth: '1px', height: '29px', backgroundColor: '#7283A4', mx: '5px' }} />  */}
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
          sx={agendaTabStyles}
          TabScrollButtonProps={{
              slots: {
                StartScrollButtonIcon: () => <ArrowIcon color={colors?.Text! || '#ffffff'} flipX />, // Left arrow with custom color
                EndScrollButtonIcon: () => <ArrowIcon color={colors?.Text! || '#ffffff'}  />, // Right arrow with custom color
              },
            }}
        >
          {rooms?.map((item: any) => {
            return (
              <Tab
                sx={{ marginRight: "0px !important", color: "red"}}
                label={
                    <Tooltip title={item?.name} arrow>
                        {item?.name}
                    </Tooltip>
                                                            }
                value={item?.id}
                key={item?.id}
              />
            );
          }
          )}
        </Tabs>
      </Stack>
      <Divider sx={{ borderColor: colors?.Secondary! ||  "#2f2f2f", my: "10px" }} />
      <ChatHeaderDetail
        participants={attendees}
        totalParticipantsCount={totalParticipantsCount}
      />
      <Divider sx={{ borderColor: colors?.Secondary! ||  "#2f2f2f", mt: "10px" }} />
    </>
  );

  const renderMessages = (
    <Stack
      sx={{
        width: 1,
        height: 1,
        overflow: "hidden",
      }}
    >
      <ChatMessageList
        id={id!}
        activeRoomId={activeRoom?.id}
        participants={attendees}
        onMessageReact={onMessageReact}
        onMessageReply={(data) => setRepliedMessage(data)}
        onMessageUpdate={(data) => setEditMessage(data)}
        onMessageDelete={(messageId) => handleMessageDelete(messageId)}
        editMessage={editMessage}
        setActiveUserAvatar={setActiveUserAvatar}
        isGridView={isGridView}
      />
      <Divider sx={{ borderColor: colors?.Secondary! || "#ccd2dc", mb: "11px" }} />
      <ChatMessageInput
        recipients={recipients}
        onAddRecipients={handleAddRecipients}
        onSendMessage={(
          id: string,
          msg: string,
          fileUrl: string,
          fileType: string
        ) => addMessage(user?.firstName, msg, fileUrl, fileType)}
        selectedConversationId={activeRoom?.id}
        disabled={!recipients.length && !selectedConversationId}
        activeUserAvatar={activeUserAvatar}
        repliedMessage={repliedMessage}
        editMessage={editMessage}
        onCancelReplyMessage={() => {
          setRepliedMessage(null);
          setEditMessage(null);
        }}
      />
    </Stack>
  );

  return (
    <Stack sx={cardProperties} direction="row">
      {event ? (
        <Stack
          sx={{
            width: 1,
            height: 1,
            overflow: "hidden",
          }}
        >
          {renderHead}
          <Stack
            direction="row"
            sx={{
              width: 1,
              height: 1,
              overflow: "hidden",
              // borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            {renderMessages}
          </Stack>
        </Stack>
      ) : (
        <LoadingScreen />
      )}
    </Stack>
  );
};

export default ChatView;
