import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import SearchIcon from '../../../assets/images/event-landing-pre-event/Icon-feather-search.svg';
import { IProfiles } from '../../../models/user/account-settings/account-settings';
import { UserService } from '../../../services';
import { Avatar, Button } from '../../../shared-components/V2';
import { getCompanyName, imageUrlPrefix } from '../../../utils';

const BlockedProfile = () => {
  const [filteredData, setFilteredData] = React.useState<IProfiles[]>([]);
  const [searchText, setSearchText] = React.useState<string>('');
  const [profiles, setProfiles] = React.useState<IProfiles[]>([]);

  const getBlockedProfile = async () => {
    const userService = new UserService();
    const settings = await userService.getBlockedProfile();
    setProfiles(settings.data);
    setFilteredData(settings.data);
  };

  useEffect(() => {
    getBlockedProfile();
  }, []);

  useEffect(() => {
    function getFilteredArray() {
      if (searchText.length === 0) {
        return profiles;
      }

      return profiles.filter((item: any) => item.blockedUser.firstName.toLowerCase().includes(searchText.toLowerCase()));
    }

    if (profiles) {
      setFilteredData(getFilteredArray());
    }
  }, [profiles, searchText]);

  const onUnblockHandler = async (blockUserId: string) => {
    const userService = new UserService();
    try {
      const result = await userService.unBlockProfile(blockUserId);
      toast.success(result.message);
      getBlockedProfile();
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  return (
    <div className="max-w-[886.5px] ml-[40px] mt-[20pxpx]">
      {/* blocked profiles content */}
      <div className="flex flex-col w-full" id="blocked-profiles">
        <span className="font-semibold text-[15px] leading-[21px] mt-5 mb-5 text-[#0B1221]">Blocking and Privacy</span>
        <div className="border-t border-[#707070] w-full">
          <span className="font-semibold text-[15px] leading-[21px] mt-5 mb-5 text-[#0B1221] block">Blocked profiles</span>
          <div className="bg-white px-[36px] py-[29px] rounded-20 profile-avatar-shadow ">
            <div className="flex flex-row w-full pb-3">
              <div
                className="bg-[#EDF2F5] px-4 py-1 rounded-10 h-[39px] w-full flex items-center z-10"
              >
                <div className="flex-shrink-0 z-20 py-3 mr-2.5">
                  <img className="w-4 h-4" src={SearchIcon} alt="" />
                </div>
                <input
                  type="text"
                  className="pr-2 w-full bg-[#EDF2F5] text-[18px] font-light text-[#3C3C3C] placeholder:text-[#3C3C3C] focus-visible:outline-none"
                  placeholder="Search"
                  onChange={e => setSearchText(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="max-h-72 block-section-scroll overflow-y-scroll">
              {filteredData.map(item => (
                <div key={item.userId} className="flex items-end justify-between pb-4">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <Avatar src={`${imageUrlPrefix}/${item.blockedUser.avatar}`} size="MEDIUM" />
                    </div>
                    <div className="ml-2.5 min-w-0">
                      <p className="text-[13px] font-semibold leading-[19px] text-[#203C6E]">{`${item.blockedUser.firstName} ${item.blockedUser.lastName}`}</p>
                      <p className="text-[12px] font-normal leading-[17px] text-[#3C3C3C]">
                        {item.blockedUser.jobTitle}
                      </p>
                      <p className="text-[12px] font-semibold leading-[17px] text-[#3C3C3C]">{getCompanyName(item.blockedUser)}</p>
                    </div>
                  </div>
                  <div className="inline-flex items-center">
                    <Button
                      text="Unblock"
                      type="button"
                      onClick={() => onUnblockHandler(item.blockUserId)}
                      className="inline-block w-[96px] h-[30px] bg-[#0049EF] hover:bg-[#244E93] tracking-wider text-white"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockedProfile;
