import {
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit';

import { IInterest } from '../../models/interest/interest';
import { IInterestsResult } from '../../models/interest/interests-result';
import { InterestService } from '../../services/interestService';

export type InterestsState = {
  interests: IInterest[];
  selectedOffers: IInterest[];
  selectedInterests: IInterest[];
  defaultOffers: IInterest[];
  defaultInterests: IInterest[];
  loading: boolean;
  errorMessage: string | null;
};

const initialState: InterestsState = {
  interests: [],
  selectedOffers: [],
  selectedInterests: [],
  defaultOffers: [],
  defaultInterests: [],
  loading: false,
  errorMessage: null,
};

export const interestsSlice = createSlice({
  name: "interests",
  initialState,
  reducers: {
    interestsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    interestsError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    interestsSuccess: (state, action: PayloadAction<IInterestsResult>) => {
      state.loading = false;
      state.interests = action.payload.data.map((interest) => ({
        ...interest,
        interested: false,
        offer: false,
      }));
    },
    selectedOffers: (state, action: PayloadAction<IInterest[]>) => {
      state.selectedOffers = action.payload;
    },
    selectedInterests: (state, action: PayloadAction<IInterest[]>) => {
      state.selectedInterests = action.payload;
    },
    setDefaultInterests: (state, action: PayloadAction<IInterest[]>) => {
      state.defaultInterests = action.payload;
      state.loading = false;
    },
    setDefaultOffers: (state, action: PayloadAction<IInterest[]>) => {
      state.defaultOffers = action.payload;
      state.loading = false;
    },
    removeFromDefaultInterests: (state, action: PayloadAction<string>) => {
      state.defaultInterests = state.defaultInterests.filter(
        (interest) => interest.id !== action.payload
      );
    },
    removeFromDefaultOffers: (state, action: PayloadAction<string>) => {
      state.defaultOffers = state.defaultOffers.filter(
        (offer) => offer.id !== action.payload
      );
    },
    toggleInterest: (state, action: PayloadAction<IInterest>) => {
      for (const item of state.interests) {
        if (action.payload.id === item.id) {
          item.interested = !item.interested;
        }
      }
    },
    toggleOffer: (state, action: PayloadAction<IInterest>) => {
      for (const item of state.interests) {
        if (action.payload.id === item.id) {
          item.offer = !item.offer;
        }
      }
    },
  },
});

export const {
  toggleInterest,
  interestsLoading,
  interestsSuccess,
  selectedOffers,
  selectedInterests,
  setDefaultInterests,
  setDefaultOffers,
  removeFromDefaultInterests,
  removeFromDefaultOffers,
  interestsError,
  toggleOffer,
} = interestsSlice.actions;

export const getInterests = (query: string) => async (dispatch: Dispatch) => {
  try {
    // dispatch(interestsLoading(true));
    const interestService = new InterestService();
    const result = await interestService.getInterestsWithQuery(query);
    dispatch(interestsSuccess(result));
  } catch (e: any) {
    dispatch(interestsError(e.message));
  }
};

export const selectedOffer =
  (selectedList: IInterest[]) => async (dispatch: Dispatch) => {
    try {
      dispatch(selectedOffers(selectedList));
    } catch (e: any) {
      dispatch(interestsError(e.message));
    }
  };

export const selectedInterest =
  (selectedList: IInterest[]) => async (dispatch: Dispatch) => {
    try {
      dispatch(selectedInterests(selectedList));
    } catch (e: any) {
      dispatch(interestsError(e.message));
    }
  };

export const getDefaultInterests =
  (defaultValuesFor: "Interests" | "Offers" | "Both") =>
    async (dispatch: Dispatch) => {
      let searchResult: IInterestsResult;
      let defaultInterests: IInterest[] = [];
      let query: string = "";
      const getRandom = () => {
        const alphabet = "abcdefghijklmnopqrstuvwxyz"
        const randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)]
        return randomCharacter;
      }
      const interestService = new InterestService();

      try {
        dispatch(interestsLoading(true));

        let queryStrings = [
          "artificial intelligence",
          "Blockchain",
          "Start-up advice",
          "Process Automation",
          "nft",
          "Cryptocurrency",
        ];

        while (defaultInterests.length < 5) {
          searchResult = await interestService.getInterestsWithQuery(
            getRandom()
          );
          if (defaultInterests.filter((value) => value.id === searchResult.data[0]?.id).length === 0) {
            defaultInterests.push({
              ...searchResult.data[0],
              interested: false,
              offer: false,
            });
          }
        }

        if (defaultValuesFor === "Interests")
          dispatch(setDefaultInterests(defaultInterests));
        if (defaultValuesFor === "Offers")
          dispatch(setDefaultOffers(defaultInterests));

        if (defaultValuesFor === "Both") {
          dispatch(setDefaultInterests(defaultInterests));
          dispatch(setDefaultOffers(defaultInterests));
        }
      } catch (e: any) {
        dispatch(interestsError(e.message));
      }
    };

export const removeDefaultInterest =
  (id: string) => async (dispatch: Dispatch) =>
    dispatch(removeFromDefaultInterests(id));

export const removeDefaultOffer = (id: string) => async (dispatch: Dispatch) =>
  dispatch(removeFromDefaultOffers(id));
