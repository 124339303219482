// @mui
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { usePopover } from 'src/mui/components/custom-popover';
import { Box, Divider, Typography } from '@mui/material';
// components
import { imageUrlPrefix } from 'src/utils';
import defaultCompanyAvatar from 'src/assets/images/company-default.svg';
import { getBrandingColors } from '../event/live/liveEventStyling';


// ----------------------------------------------------------------------

type AttendeeProps = {
    company: any;

}

export const SponsorCompanyInfo = ({ company }: AttendeeProps) => {
    const colors = getBrandingColors();
    return (

        <Stack sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
            <Box
                sx={{
                    border: `1px solid ${ colors.Main ||'#393c41'}`,
                    p: '0px',
                    width: 58,
                    height: 58,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '6px',
                }}
            >
                <Avatar src={company?.logo ? `${imageUrlPrefix}/${company?.logo}` : defaultCompanyAvatar} sx={{
                    width: 58,
                    height: 58,
                    borderRadius: '6px !important',
                    background: company?.logo ? 'none' : '#edf2f5',
                    '& .MuiAvatar-img': {
                        objectFit: 'contain !important',
                    },
                    '& .MuiAvatar-root': {
                        borderRadius: '6px !important'
                    }
                }} alt={company?.logo} />
            </Box>
            <Stack sx={{ alignItems: 'flex-start', justifyContent: "center", ml: "10px", mt: "2px" }}>
                <Typography className='ellipsis-one-line' variant="body2" sx={{ fontSize: '14px', lineHeight: '19px', color: colors.Text || 'white', fontFamily: 'Poppins-400', textAlign: 'center' }} >

                    {company?.name}
                </Typography>
                <Typography className='ellipsis-one-line' variant="body2" sx={{ fontSize: '14px', lineHeight: '19px', color: colors.Text || 'white', fontFamily: 'Poppins-400', textAlign: 'center' }} >
                    {company?.industry ? company?.industry?.name : null}
                </Typography>
                <Typography className='ellipsis-one-line' variant="body2" sx={{ fontSize: '14px', lineHeight: '19px', color: colors.Text || 'white', fontFamily: 'Poppins-400', textAlign: 'center' }} >
                    {company?.isFollowing!} Followers

                </Typography>
            </Stack>
        </Stack>
    );
}
