/* eslint-disable jsx-a11y/label-has-associated-control */
import * as Yup from "yup";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Stack,
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  useMediaQuery,
  Typography,
  Link
} from "@mui/material";
import FormProvider from "src/mui/components/hook-form/form-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PCityCountrySelect,
  PCompanySelect,
  PJobTitleSelect,
  RHFTextField,
} from "src/mui/components/hook-form";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "src/store";
import { useCurrentUser } from "src/utils/hooks";
import RegistrationStepForm from "../event/reg-dialog-flow";
import { resetError, unsetUser } from "src/store/auth";
import { checkUserEmail, resetUserEmail } from "src/store/checkUserEmail";
import { checkUserStatus } from "src/store/user-status";
import { ILoginOrSignUp } from "../event/reg-dialog-flow/form.type";
import { getUpcomingEvent } from "src/store/events/upcoming-event/actions";
import { getTickets } from "src/store/ticket";
import { EventQuestionPageTypes, useSurveyContext } from "src/mui/providers/GeneralContext";
import { setRegistered } from "src/store/event-website";
import { UpcomingEventActionTypes } from "src/store/events/upcoming-event/types";
import { UserSignUpActionTypes } from "src/store/user/signup/types";
import { delAuthToken, emailRegex } from "src/utils";
import { RegistrationFieldType } from "src/mui/types/registration";
import { forbiddenEmailDomains } from "../survey/constants";


const fieldStyle = {
  h: '38px',
  '& .MuiOutlinedInput-input': {
    p: '7.5px 14px',
    fontSize: '15px',
    color: '#595c5f'
  },
  '& .MuiOutlinedInput-input::placeholder': {
    color: '#595c5f'
  },
  'fieldset': {
    borderRadius: '0px'
  },
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    left: '46%',
    bottom: '-50px',
    backgroundColor: '#4d144a',
    color: '#ffffff',
    p: '13px',
    border: '1px solid #ff0000',
    borderRadius: '5px'
  }
}

// eslint-disable-next-line no-unused-vars
function SignupForm() {
  const dispatch = useDispatch();
  const user = useCurrentUser();

  const queryParams = new URLSearchParams(window.location.search);
  const eventFrom = queryParams.get('eventFrom');




  const tabScreen = useMediaQuery('(min-width:900px)');

  const [showModal, setShowModal] = useState(false);
  const [joiningUser, setJoiningUser] = useState<string>("");
  const [isLoader, setIsLoader] = useState<any>(false);

  const { getQuestionPages, setEventIdParam, signupRegQuestion, getSignUpRegQuestions } = useSurveyContext();

  const { errorMessage: loginError, currentUser } = useSelector(
    (state: IAppState) => state.auth
  );

  const { registered } = useSelector(
    (state: IAppState) => state.website
  );
  const event = useSelector((state: IAppState) => state.website.event!);
  const { upcomingEvent } = useSelector(
    (state: IAppState) => state
  );

  const audienceStatus = upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus;

  const emailDefaultQuestion = signupRegQuestion && signupRegQuestion?.length && signupRegQuestion?.find((question: any) => (question.fieldType === RegistrationFieldType.EMAIL || question.fieldType === RegistrationFieldType.BUSINESS_EMAIL) && !question?.id)

  const requiredEmailSchema = Yup.string()
    .required('Business email is required')
    .email('Invalid email')
    .test('not-included', 'Business email address only', (value) => {
      if (value) {
        // Check if any forbidden substring is present in the email
        const includesForbiddenSubstring = forbiddenEmailDomains.some((substring) =>
          value?.toLowerCase()?.includes(substring)
        );
        return !includesForbiddenSubstring; // Return false if any forbidden substring is found
      }
      return true; // Return true if the email is valid
    })
    .matches(emailRegex, 'Should be a valid business email');


  const SignupSchema = Yup.object().shape({
    email: emailDefaultQuestion?.fieldType === RegistrationFieldType.BUSINESS_EMAIL ? requiredEmailSchema : Yup.string()
      .email("Email must be valid")
      .required(`Email is required`),
  });

  const methods = useForm<any>({
    resolver: yupResolver(SignupSchema),
    // defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid, errors },
  } = methods;

  useEffect(() => {
    if (eventFrom === 'register') {
      setShowModal(true);
    }
  }, [eventFrom])

  useEffect(() => {
    if (user) {
      // dispatch(getUpcomingEvent(event.id));
      dispatch(getTickets(event.id));
    }
  }, [dispatch, event.id, user]);

  useEffect(() => {
    if (event?.id) {
      setEventIdParam(event?.id!);
      if (user) {
        getQuestionPages(EventQuestionPageTypes.REGISTRATION, event?.id!);
        getQuestionPages(EventQuestionPageTypes.SURVEY, event?.id!);
      }
    }
  }, [event?.id, user]);

  const onLoginSubmit = (data: ILoginOrSignUp) => {
    if (!isValid) return
    if (data.email) {
      setJoiningUser(data.email);
      setValue('email', data.email);
      dispatch(checkUserEmail(data));
      setShowModal(true);
    } else {
      dispatch(resetError());
      setIsLoader(true);
      // dispatch(
      //   login(data.email, data.password!, navigate, "event", changePage)
      // );
      dispatch(checkUserStatus());
    }
  };

  // const submitForm = (data: any) => {
  //   if (!isValid) return
  //   onSignupSubmit(data)
  // }

  const handleRegistration = () => {
    // if (!registered) setShowModal(true);
    // if (!registered && event?.isTicketed && user) setOpenTicketModal(true);

    // if (user && registered) navigate(`/event/${event?.slug}/discussion`);
    // if (
    //   user
    //   && registered === false
    //   && event?.type === 'invitation'
    //   && audienceStatus === 'accepted'
    // ) { navigate(`/event/${event?.slug}/discussion`); }
  };

  const resetForm = () => {
    dispatch(resetUserEmail());
    reset();
    setValue('email', '');
  };


  const signoutClickHandler = () => {
    delAuthToken();
    resetForm();
    sessionStorage.setItem("termAndCondition", "false");
    // setJoiningUser("");
    // setStepName("Login or Join Now");
    // setStepDescription("");
    // setShowForm("");
    dispatch(setRegistered(null));
    dispatch(unsetUser());

    dispatch({
      type: UpcomingEventActionTypes.RESET_UPCOMING_EVENT_LOADING,
      payload: {}
    });

    dispatch({
      type: UserSignUpActionTypes.RESET_SIGNUP_USER,
      payload: {}
    });

    // if (setUser) setUser(undefined);
  };

  const onLogout = () => {
    // resetState();
    signoutClickHandler();
  };


  useEffect(() => {
    if (event?.id) getSignUpRegQuestions(event?.id)
  }, [event?.id])

  return (
    <>
      <Stack sx={{
        width: tabScreen ? "100%" : "100%",
        // overflowY: 'scroll',
        height: '100%',
        paddingTop: "1.5rem"
      }}>
        <Typography
          variant="body2"
          noWrap
          sx={{
            whiteSpace: 'initial',
            fontSize: 'calc(1.3rem + .6vw)',
            mb: '0.5rem',
            color: '#4d144a',
            fontFamily: 'Poppins-600',
            lineHeight: '38px',

          }}
        >
          {user ?
            <>
              Do you need to register as someone else?
              <Link
                onClick={() => onLogout()}
                sx={{ cursor: "pointer", color: '#4d144a', ml: 1, textDecorationColor: "#4d144a" }}
              >
                Logout!
              </Link>

            </>


            : 'REGISTER NOW'}
        </Typography>
        {!user ? (
          <FormProvider methods={methods} onSubmit={handleSubmit(onLoginSubmit)}>
            <Stack
              sx={{
                direction: "row",
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
              spacing={2}
            >
              <RHFTextField
                name="email"
                placeholder={emailDefaultQuestion?.fieldType === RegistrationFieldType.BUSINESS_EMAIL ? "Business Email Address" : "Email Address"}
                autoComplete="off"
                sx={fieldStyle}
              />

              {/* {showSuccessMessage && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  Successfully Registered!
                </Alert>
              )} */}
              <button
                type="submit"
                // onClick={() => handleRegistration()}
                className="rounded-[0px] inline-block text-[21px] font-normal bg-[#4d144a] hover:bg-[#4d144a] text-white w-[195px] h-[52px] tracking-wide"
              >
                Register Now
              </button>
            </Stack>
          </FormProvider>
        ) : (
          <Stack
            sx={{
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
              gap: 2
            }}
          >


            {!registered ? <button
              onClick={() => setShowModal(true)}
              className="rounded-[0px] inline-block text-[21px] font-normal bg-[#4d144a] hover:bg-[#4d144a] text-white w-[165px] h-[52px] tracking-wide"
            >
              Register Now
            </button> :

              <button
                onClick={() => setShowModal(true)}
                className="rounded-[0px] inline-block text-[21px] font-normal bg-[#4d144a] hover:bg-[#4d144a] text-white w-[145px] h-[52px] tracking-wide"
              >
                Portal
              </button>}

            {/* <button
              onClick={() => onLogout()}
              className="rounded-[0px] inline-block text-[21px] font-normal bg-[#4d144a] hover:bg-[#4d144a] text-white w-[145px] h-[52px] tracking-wide"
            >
              Log out
            </button> */}
          </Stack>
        )}

      </Stack >
      {showModal && (
        <RegistrationStepForm
          eventId={event?.id!}
          user={user}
          open={showModal}
          registered={registered!}
          eventInvitationStatus={audienceStatus!}
          eventType={event?.type!}
          setOpenForm={() => { setShowModal(false); resetForm(); }}
          lenovoJoiningUser={joiningUser}
        />
      )
      }
    </>
  );
};

export default forwardRef(SignupForm);


