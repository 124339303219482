interface IMicSvgIcon {
    isMuted?: boolean;
}
export const MicSvgIcon = ({ isMuted }: IMicSvgIcon) => {

    return isMuted ?
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
            <path id="Path_11362" data-name="Path 11362" d="M11.5,0A11.5,11.5,0,1,1,0,11.5,11.5,11.5,0,0,1,11.5,0Z" fill="#edf2f5" />
            <path id="Path_10652" data-name="Path 10652" d="M-26.96-13.184A2.708,2.708,0,0,0-24.3-15.935v-3.44a2.708,2.708,0,0,0-2.662-2.752,2.708,2.708,0,0,0-2.662,2.752v3.44A2.708,2.708,0,0,0-26.96-13.184Z" transform="translate(38.5 27)" fill="#203c6e" />
            <path id="Path_10653" data-name="Path 10653" d="M-23.305-13.439a.677.677,0,0,0-.665-.688.677.677,0,0,0-.665.688A3.385,3.385,0,0,1-27.964-10a3.385,3.385,0,0,1-3.327-3.44.677.677,0,0,0-.665-.688.677.677,0,0,0-.665.688,4.767,4.767,0,0,0,3.993,4.761v1.431h-1.4a.6.6,0,0,0-.592.612v.151a.6.6,0,0,0,.592.612h4.139a.6.6,0,0,0,.592-.612v-.151a.6.6,0,0,0-.592-.612h-1.4V-8.678A4.767,4.767,0,0,0-23.305-13.439Z" transform="translate(39.5 24.5)" fill="#203c6e" />
            <g id="Rectangle_7507" data-name="Rectangle 7507" transform="translate(18 3) rotate(39)" fill="#ff0f0f" stroke="#e8ecef" strokeWidth="0.6">
                <rect width="1.658" height="21.006" stroke="none" />
                <rect x="0.3" y="0.3" width="1.058" height="20.406" fill="none" />
            </g>
        </svg>
        :
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
            <path id="Path_11362" data-name="Path 11362" d="M11.5,0A11.5,11.5,0,1,1,0,11.5,11.5,11.5,0,0,1,11.5,0Z" fill="#edf2f5" />
            <g id="Group_17575" data-name="Group 17575" transform="translate(7.061 4.643)">
                <path id="Path_10652" data-name="Path 10652" d="M-26.96-13.184A2.708,2.708,0,0,0-24.3-15.935v-3.44a2.708,2.708,0,0,0-2.662-2.752,2.708,2.708,0,0,0-2.662,2.752v3.44A2.708,2.708,0,0,0-26.96-13.184Z" transform="translate(31.618 22.127)" fill="#203c6e" />
                <path id="Path_10653" data-name="Path 10653" d="M-23.305-13.439a.677.677,0,0,0-.665-.688.677.677,0,0,0-.665.688A3.385,3.385,0,0,1-27.964-10a3.385,3.385,0,0,1-3.327-3.44.677.677,0,0,0-.665-.688.677.677,0,0,0-.665.688,4.767,4.767,0,0,0,3.993,4.761v1.431h-1.4a.6.6,0,0,0-.592.612v.151a.6.6,0,0,0,.592.612h4.139a.6.6,0,0,0,.592-.612v-.151a.6.6,0,0,0-.592-.612h-1.4V-8.678A4.767,4.767,0,0,0-23.305-13.439Z" transform="translate(32.622 19.63)" fill="#203c6e" />
            </g>
        </svg>

};


