import classNames from 'classnames';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { IAppState } from '../../store';
import { closeMessageWindow } from '../../store/newMessage';
import { closeConversation } from '../../store/oneToOneConversation';
import Messages from '../messages';
import ChatHeads from '../messages/chat-heads';
import ChatWindow from '../messages/chat-window';
import NewMessage from '../messages/new-message';

export const Chat = () => {
  const location = useLocation();
  const params = useParams();
  const {hostName,slug}=params
  const dispatch = useDispatch();
  const isLiveEventPage = window.location.pathname.endsWith('/live');
  const chatHeads = useSelector((state: IAppState) => state.chatHeads);
  const conversations = useSelector(
    (state: IAppState) => state.oneToOneConversation.openConversations
  );
  const event = useSelector(
    (state: IAppState) => state.website?.event
  );
  const { newMessage } = useSelector((state: IAppState) => state);
  const { currentUser: user, redirect } = useSelector(
    (state: IAppState) => state.auth
  );

  const locationPathArr = location?.pathname?.match(/\/(\w+)/ig);
  // Remove chat from event website, event registration, reg, registration, vipreg, loginorjoin, login and signup of Spectre website
  if ((locationPathArr?.length === 3 && locationPathArr?.[0]) === "/event"
    || (locationPathArr?.length === 4 && locationPathArr?.[3]) === "/reg"
    || (locationPathArr?.length === 4 && locationPathArr?.[3]) === "/registration"
    || (locationPathArr?.length === 4 && locationPathArr?.[3]) === "/vipreg"
    || (locationPathArr?.length === 4 && locationPathArr?.[3]) === "/loginorjoin"
    || (locationPathArr?.length === 4 && locationPathArr?.[3]) === "/login"
    || (locationPathArr?.length === 4 && locationPathArr?.[3]) === "/signup"
    || (locationPathArr?.length === 4 && locationPathArr?.[3]) === "/select"
    || (locationPathArr?.length === 4 && locationPathArr?.[3]) === "/successfully"
    || (locationPathArr?.length === 3 && locationPathArr?.[2]) === "/deactivate") return null;
  const lastPartOfUrl = location?.pathname?.match(/\/([^/]+)\/?$/);
  // Remove chat from /event/:eventId
  if ((locationPathArr?.length === 2 && locationPathArr?.[0]) === "/event") return null;

  if(locationPathArr?.length === 2 && decodeURIComponent(location?.pathname?.split("/")?.[1]) === event?.company?.name && decodeURIComponent(location?.pathname?.split("/")?.[2]) === event?.slug) return null;
  // Remove chat if url ends with /new e.g. /event/:eventId/new
  // if (lastPartOfUrl?.[1] === "new" || lastPartOfUrl?.[1] === "live") return null;
  return user && !redirect ? (
    <>
      <div
        className={classNames('bottom-0 hidden right-0 fixed overlay-hidden z-10 hide-on-event', {
          'hidden': (lastPartOfUrl?.[1] === "new" || lastPartOfUrl?.[1] === "live"),
          'md:block': !(lastPartOfUrl?.[1] === "new" || lastPartOfUrl?.[1] === "live"),
        })}
      >
      
        <div className="flex flex-col">
          <div className={classNames('flex flex-col-reverse mr-2 mb-4 items-end', {
            'hidden': !chatHeads?.chatHeads?.length
          })}>
            <ChatHeads chatHeads={chatHeads.chatHeads} />
          </div>
          <div className='flex-none w-288'>
            <div className='flex flex-col h-full'>
              <div>
                <Messages />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames('flex mr-2 bottom-0  fixed z-10 h-[48px]', {
          'right-0': (lastPartOfUrl?.[1] === "new" || lastPartOfUrl?.[1] === "live"),
          'right-72': !(lastPartOfUrl?.[1] === "new" || lastPartOfUrl?.[1] === "live"),
          "!right-[80px]":isLiveEventPage
        })}
      >
        {conversations.map((conversation, index) => (
          <ChatWindow
            conversationId={conversation.conversationId}
            userId={conversation.userId}
            avatar={conversation.avatar}
            title={conversation.username}
            onCloseHandler={() => {
              dispatch(closeConversation(conversation.conversationId));
            }}
          />
        ))}
        {newMessage.isOpen && (
          <NewMessage
            avatar={newMessage.avatar}
            title={newMessage.userDisplayName}
            messageText={newMessage.messageText}
            userId={newMessage.userId}
            onCloseHandler={() => dispatch(closeMessageWindow())}
          />
        )}
      </div>
    </>
  ) : null;
};
