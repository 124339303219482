import { FC } from 'react';

export type DeleteModalProps = {
  title?: string;
  subTitle?: string;
  onCancel?: Function;
  onOkay?: Function;
  leftButtonText?: string;
  rightButtonText?: string;
};

export const DeleteModalPopup: FC<DeleteModalProps> = (props) => {

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='text-[16px] leading-[24px] font-medium  mb-[16px] text-[#0B1221]'>{props?.title}</div>
      <div className='text-[16px] leading-[24px] font-normal  mb-[18px] text-[#0B1221] text-center'>{props?.subTitle}</div>
      <div className='flex items-center justify-center gap-2'>
        <button
          type="button"
          className="flex justify-center items-center px-[20px] bg-white text-[#203C6E] text-[13px] font-semibold tracking-wide hover:bg-[#CEDCF2] active:bg-[#CEDCF2] rounded-10 h-30"
          onClick={() => props?.onCancel && props?.onCancel()}
        >
          {props?.leftButtonText}
        </button>
        <button
          type="button"
          className="flex justify-center items-center px-[20px] bg-[#0049EF] text-white text-[13px] font-semibold tracking-wide hover:bg-[#244E93] active:bg-[#244E93] rounded-10 h-30"
          onClick={() => props?.onOkay && props?.onOkay()}
        >
          {props?.rightButtonText}
        </button>
      </div>
    </div>
  );
};

export default DeleteModalPopup;
