/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
// AccordionHeader.tsx
import React from 'react';
import classNames from 'classnames';
import PlusSvgIcon from './plus-svg-icon';
import MinusSvgIcon from './minus-svg-icon';

interface AccordionHeaderProps {
  isOpen: boolean;
  toggleAccordion: () => void;
  title: string;
}

const AccordionSubHeader: React.FC<AccordionHeaderProps> = ({
  isOpen,
  toggleAccordion,
  title,
}) => (
  <button
    onClick={toggleAccordion}
    className={classNames(
      'w-full transition-all m-0 pt-[20px] pb-[17px] pl-[22px] pr-[30px] flex justify-between items-start focus:outline-none bg-[#14161A] rounded-[8px]'
    )}
  >
    <div className="flex lg:flex-row flex-col gap-y-4 justify-between items-start">
      <div className="text-white md:leading-[26px] leading-[20px] md:text-[20px] text-[16px] helvetica-neue-65-medium font-medium text-left">
        {title}
      </div>
    </div>
    {isOpen ? <MinusSvgIcon /> : <PlusSvgIcon />}
  </button>
);

export default AccordionSubHeader;
