export interface ClockSvgIconProps {
    fillColor?: string;
}
export const ClockSvgIcon = ({ fillColor }: ClockSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#203c6e"
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
    <g id="Icon_feather-clock" data-name="Icon feather-clock" transform="translate(1 1)">
      <path id="Path_624" data-name="Path 624" d="M17,10a7,7,0,1,1-7-7A7,7,0,0,1,17,10Z" transform="translate(-3 -3)" fill="none" stroke={iconColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Path_625" data-name="Path 625" d="M18,9v2.531l2.018.844" transform="translate(-11.511 -4.457)" fill="none" stroke={iconColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg>
};