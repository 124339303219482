import classNames from "classnames";
import { Logo } from "../../shared-components/V2/Logo"

interface IWelcomePost {
    title?: string;
    imageSrc: string;
    subTitle?: string;
    children?: JSX.Element;
    className?: string;
}

export const WelcomePost = ({ title, imageSrc, subTitle, children, className }: IWelcomePost) => {
    return <div className={classNames("bg-white w-full create-post-card py-6 mb-2", {
        [`${className}`]: className
    })}>
        <div className='flex flex-col justify-center items-center text-center'>
            <div className='text-lg3 py-4'>{title}</div>
            <Logo src={imageSrc} alt='' />
            <div className='text-md w-3/5 py-4'>
                {subTitle}
            </div>
            {children}
        </div>
    </div>
}