/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-console */
import classNames from 'classnames';
import { getJobTitle, getSpeakerCompanyName, getSpeakerName } from 'src/utils';
import { useState } from 'react';
import AlertModal from 'src/components/alert-modal/alert-modal';
import userDerault from '../../images/avatar-default.svg';
import CustomModal from '../agenda-accordion/custom-modal';

interface Props {
    speaker:any;
}

// eslint-disable-next-line object-curly-spacing, no-unused-vars
export const SpeakerDetails = ({speaker}:Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleBioClicked = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const user = {
    name: `${speaker?.eventSpeakers[0]?.firstName} ${speaker?.eventSpeakers[0]?.lastName}`,
    position: speaker?.eventSpeakers[0]?.jobTitle,
    isSpeaker: !!speaker?.eventSpeakers[0]?.firstName,
    slug: `/wall/${speaker?.eventSpeakers[0]?.user?.slug}?tab=about`,
    photo: speaker?.eventSpeakers?.[0]?.photo ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${speaker?.eventSpeakers?.[0]?.photo}` : userDerault,
    bio: speaker?.eventSpeakers?.[0]?.bio,
    company: speaker?.eventSpeakers[0]?.company?.name,

  };

  return (
    <div className="md:w-[210px] w-full">
      <div className="md:w-[210px] w-full h-[250px] bg-[#333333] rounded-[10px] relative">
        <img className="object-cover object-top w-full h-full rounded-[10px]" src={user?.photo} />
        {user?.bio ? (
          <button
            type="button"
            className={classNames(
              'px-[13px] h-[26px] bg-[#F1B01A] rounded-5 flex items-center justify-center text-black text-[13px] font-medium helvetica-neue-40-roman mt-[7px] absolute right-2 bottom-2'
            )}
            onClick={handleBioClicked}
          >
            Bio
            {' '}
          </button>
        ) : null}
      </div>
      <div className="text-white font-medium helvetica-neue-65-medium text-[22px] leading-[28px] ellipsis-one-line mt-[10px] mb-[0px]">
        {getSpeakerName(speaker)}
      </div>
      <div className="text-white md:leading-[17px] leading-[14px] md:text-[14px] text-[10px] helvetica-neue-55-roman text-left ellipsis-two-line">
        {' '}
        {getJobTitle(speaker)}
      </div>
      <div className="text-white md:leading-[17px] leading-[14px] md:text-[14px] text-[10px] helvetica-neue-55-roman text-left ellipsis-one-line">
        {getSpeakerCompanyName(speaker)}
      </div>

      {speaker && isOpen ? (
        <AlertModal
          isOpen={isOpen}
          onClose={handleCloseModal}
          className="max-w-[890px] bg-[#14161A]"
          closeIconClass=" right-[18px] top-[15px] px-0"
          isCloseIcon
        >
          <CustomModal
            user={user}
            UserPicture={user?.photo}
            speaker={speaker}
          />
        </AlertModal>
      ) : null}
    </div>
  );
};
