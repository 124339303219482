export const StarIcon = ( ) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22.133" height="22.095" viewBox="0 0 22.133 22.095">
  <g id="Group_18206" data-name="Group 18206" transform="translate(0.501 0.512)">
    <path id="Path_14530" data-name="Path 14530" d="M9.532,1.091,8.647,5.327a1.372,1.372,0,0,1-.813.984L3.841,7.983a1.37,1.37,0,0,0-.152,2.453l3.757,2.153a1.371,1.371,0,0,1,.684,1.077l.356,4.311a1.37,1.37,0,0,0,2.286.9l3.205-2.9a1.373,1.373,0,0,1,1.235-.318l4.214.995a1.37,1.37,0,0,0,1.565-1.895l-1.774-3.95a1.372,1.372,0,0,1,.08-1.274l2.248-3.7a1.37,1.37,0,0,0-1.318-2.074l-4.3.466a1.372,1.372,0,0,1-1.186-.469L11.912.477a1.37,1.37,0,0,0-2.38.614" transform="translate(-0.638 0)" fill="#7283a4" stroke="#edf2f5" stroke-width="1"/>
    <path id="Path_14531" data-name="Path 14531" d="M4.113,11.2l-.447,2.135a1.371,1.371,0,0,1-.813.984l-2.012.842a1.371,1.371,0,0,0-.153,2.453L2.583,18.7a1.372,1.372,0,0,1,.684,1.076l.18,2.175a1.371,1.371,0,0,0,2.286.9l1.616-1.465a1.37,1.37,0,0,1,1.236-.318l2.123.5a1.37,1.37,0,0,0,1.566-1.895l-.894-1.99a1.37,1.37,0,0,1,.079-1.273l1.133-1.864a1.371,1.371,0,0,0-1.319-2.074L9.1,12.712a1.369,1.369,0,0,1-1.186-.469L6.493,10.59a1.37,1.37,0,0,0-2.38.614" transform="translate(0 -2.148)" fill="#7283a4" stroke="#edf2f5" stroke-width="1"/>
  </g>
</svg>

);

