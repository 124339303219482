import { Reducer } from 'redux';

import { IPostsState } from '../../../models/post/PostsState';
import { CompanyPostsActions } from './actions';
import { CompanyPostsActionTypes } from './types';

const initialState: IPostsState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const CompanyPostsReducer: Reducer<IPostsState, CompanyPostsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CompanyPostsActionTypes.COMPANY_POSTS_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case CompanyPostsActionTypes.COMPANY_POSTS_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case CompanyPostsActionTypes.COMPANY_POSTS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
