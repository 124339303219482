import { FC } from "react";
import { Disclosure } from "@headlessui/react";

// import arrowDown from '../../assets/images/website-event/arrow-down.svg';
// import arrowUp from '../../assets/images/website-event/arrow-up.svg';
import classNames from "classnames";
import { ArrowUpSvgIcon } from "../V2/IconSvg/ArrowUpSvgIcon/ArrowUpSvgIcon";
import { ArrowDownSvgIcon } from "../V2/IconSvg/ArrowDownSvgIcon/ArrowDownSvgIcon";
import { EventWebsiteTemplateType } from "src/mui/types/survey";

interface Props {
  tabBar?: JSX.Element | JSX.Element[];
  titleData?: JSX.Element | JSX.Element[];
  bodyData?: JSX.Element | JSX.Element[];
  styling?: string;
  roomQty?: number;
  borderColor?: string;
  isTitleLobby?: boolean;
  eventTemplate?: string | undefined;
}

const AccordionLayout: FC<Props> = ({
  tabBar,
  titleData,
  bodyData,
  styling,
  roomQty,
  borderColor,
  isTitleLobby,
  eventTemplate,
}: Props) => {
  return (
    <div className="w-full">
      <div>{tabBar}</div>
      <dl className="space-y-6 divide-y divide-gray-200">
        <Disclosure
          as="div"
          className={classNames("relative border-[#0326E5] border-l-2 flex", {
            "!border-none": isTitleLobby,
          })}
          style={{
            borderColor: borderColor,
          }}
        >
          {({ open }) => (
            <>
              <dt className="w-full inline-flex">
                <div
                  className={classNames("float-left w-[100%] flex", {
                    "hide-header-speackers": open,
                    "hide-body-section": !open,
                    "!w-full": roomQty === 2,
                  })}
                >
                  {titleData}
                </div>
                <div className={`float-right m-auto ${styling}`}>
                  <Disclosure.Button className="text-gray-400">
                    <span className="h-7 flex items-center">
                      {open ? (
                        <ArrowUpSvgIcon
                          fillColor={
                            eventTemplate ===
                            EventWebsiteTemplateType.AFTER_HOURS
                              ? "#FFFFFF"
                              : undefined
                          }
                        />
                      ) : (
                        <ArrowDownSvgIcon
                          fillColor={
                            eventTemplate ===
                            EventWebsiteTemplateType.AFTER_HOURS
                              ? "#FFFFFF"
                              : undefined
                          }
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </div>
              </dt>
              {/* <Disclosure.Panel
                                as="dd"
                                className={classNames("mx-auto mb-3 pb-4 pt-[5px]", {
                                    '!w-full pl-[20px]': roomQty === 2,
                                })}
                            >
                                {bodyData}
                            </Disclosure.Panel> */}
            </>
          )}
        </Disclosure>
      </dl>
    </div>
  );
};

export default AccordionLayout;
