import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { ISimilarEventsResult } from '../../../models/event/similar-events-result';
import { ISimilarEventsState } from '../../../models/event/similar-events-state';
import { EventsService } from '../../../services/events';

const initialState: ISimilarEventsState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const similarEventsSlice = createSlice({
  name: 'similarEventsSlice',
  initialState,
  reducers: {
    similarEventsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    similarEventsError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    similarEventsSuccess: (state, action: PayloadAction<ISimilarEventsResult>) => {
      state.loading = false;
      state.value = action.payload;
    }
  }
});

export const { similarEventsLoading, similarEventsSuccess, similarEventsError } =
  similarEventsSlice.actions;

export const getSimilarEvents = (eventIdOrSlug: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(similarEventsLoading(true));
    const eventService = new EventsService();
    const result = await eventService.getSimilarEvents(eventIdOrSlug);
    dispatch(similarEventsSuccess(result));
  } catch (e: any) {
    dispatch(similarEventsError(e.message));
  }
};
