import bulb from '../../../assets/images/compliance/bulb-icon.png';

const TableContentOne = () => (
  <>
    <div id="accept-terms" className="block lg:flex flex-col  mx-auto mt-7">
      <div className="mt-7 text-gray-1 table-of-contents text-lg5">
        1. Accepting These Terms
      </div>
    </div>
    <div className="font-normal text-gray-1 text-md2">
      <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-5">
        <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
        <div className="">
          You accept these terms when you register or access our Services. These terms are
          terminated when you close your account and stop using our Services. If you disagree with
          our Terms, do not register or access our Services.
          {' '}
        </div>
      </div>
      <div className="mt-3">
        a. You agree that by clicking “Join Now”, “Join Panelist”, “Sign Up” or similar,
        registering, accessing or using our services as a User,
        <span className="font-medium mr-1">
          you are agreeing to enter into a legally binding contract
        </span>
        with Panelist (even if you are using our Services on behalf of a company).
      </div>
      <div className="mt-5">
        b. If you do not agree to this contract the Terms, do not click “Join Now” (or similar) and
        do not access or otherwise use any of our Services.
      </div>
      <div className="mt-5">
        c. If you wish to terminate this contract, at any time you can do so by closing your account
        and no longer accessing or using our Services.
      </div>
    </div>
  </>
);

export default TableContentOne;
