/* eslint-disable jsx-a11y/label-has-associated-control */
import * as Yup from "yup";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Stack,
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  useMediaQuery,
  Typography,
  MenuItem,
  FormControl,
  FormLabel,
  Box,
  Divider
} from "@mui/material";
import FormProvider from "src/mui/components/hook-form/form-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PCheckbox,
  PCityCountrySelect,
  PCompanySelect,
  PCompanySelectLiveSearch,
  PJobTitleSelectLiveSearch,
  PMultiCheckbox,
  RHFSelect,
  RHFTextField,
} from "src/mui/components/hook-form";
import PIndustrySelect from "src/mui/components/hook-form/PIndustrySelect";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import useStyling from "src/mui/components/hook-form/useStyling";
import { getFieldLabel } from "./ConstantsandTypes";
import { EventsService } from "src/services/events";
import { useSurveyContext } from "src/mui/providers/GeneralContext";
import { RegistrationFieldType } from "src/mui/types/registration";
import { emailRegex } from "src/utils";
import { forbiddenEmailDomains } from "../../survey/constants";
import PhoneInput from "react-phone-input-2";
import classNames from "classnames";
import { Options } from "src/mui/types/survey";
import { IAppState } from "src/store";
import { useSelector } from "react-redux";
import 
LiveSearch from "src/mui/components/hook-form/PCompanySelectLiveSearch";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import LocationDropdown from "src/mui/components/hook-form/locationDropdown";
import Cleave from "cleave.js/react";
import { isCreditCard } from 'validator';
import PJobFunctionSelect from "src/mui/components/hook-form/PJobFunctionSelect";
import PHeadCountSelect from "src/screens/company/company-profile/PHeadCountSelect";

interface ISignupProps {
  newUserEmail: string;
  isSignUp?: boolean;
  errorMessage?: string;
  onSubmit: (data: ISignUp) => void;
  loader: boolean;
  stepDescription: string,
}
export interface ISignUp {
  firstName: string;
  lastName: string;
  email: string;
  reConfirmEmail: string;
  jobTitle: any;
  companyId: any;
  industryId: any;
  jobFunctionId: any;
  location: any;
  password: string;
  confirmPassword: string;
  postCode: string;
}

const textFieldFont = {
  // "& .MuiOutlinedInput-root": {
  //   fontFamily: 'Poppins-400'
  // },
}

const autoCompleteFont = {
  // "& .MuiAutocomplete-inputRoot": { fontFamily: 'Poppins-400' },
}

const cardExpiryDate = "expiry date"
const cardNumber = "credit card number"
const cardVerificationCode = "cvv/cvc code"

let questionSenariosVisibility: any = {}

function convertToDateFormat(input: string): Date | null {
  const month = parseInt(input?.substring(0, 2), 10);
  const year = parseInt(input?.substring(2), 10) + 2000; // Assuming 00 represents 2000
  if (isNaN(month) || isNaN(year) || month < 1 || month > 12) {
    return null; // Invalid input, return null
  }
  return new Date(year, month - 1); // Month is 0-indexed in JavaScript Date
}

function isDateInPast(date: Date): boolean {
  const currentDate = new Date(); // Current date and time
  return date.getTime() < currentDate.getTime(); // Compare millisecond representations
}

interface Condition {
  and?: boolean;
  or?: boolean;
}

// eslint-disable-next-line no-unused-vars
function SignupForm({
  stepDescription,
  loader,
  isSignUp,
  newUserEmail,
  onSubmit,
  errorMessage,
}: ISignupProps, ref: any) {

  const { signupRegQuestion } = useSurveyContext();

  const signupuser = useSelector(
    (state: IAppState) => state.signupUser)

  const emailDefaultQuestion = signupRegQuestion && signupRegQuestion?.length && signupRegQuestion?.find((question: any) => (question.fieldType === RegistrationFieldType.EMAIL || question.fieldType === RegistrationFieldType.BUSINESS_EMAIL) && !question?.id)


  const event = useSelector((state: IAppState) => state.website.event!);

  const { secondaryFontColor, styles, fieldBorderColor, focusColor, secondaryBackgroundColor, primaryBackgroundColor } = useStyling(null, null)
  const tabScreen = useMediaQuery('(min-width:900px)');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [profileLoader, setProfileLoader] = useState(false);
  const [nextButtonClicked, setNextButtonClicked] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<{ [id: string]: boolean }>({});
  const [termAndConditionAllPages, setTermAndConditionAllPages] = useState<any>([]);
  const [allPhoneNumbersCountryCode, setAllPhoneNumbersCountryCode] = useState<any>({});

  const [signUpQuestions, setSignUpQuestions] = useState<any>([]);

  const handleFocus = (id: string) => {
    setIsFocused({ [id]: true });
  };

  const handleBlur = (id: string) => {
    setIsFocused({
      [id]: false
    });
  };



  const requiredEmailSchema = Yup.string()
    .required('Business email is required')
    .email('Invalid email')
    .test('not-included', 'Business email address only', (value) => {
      if (value) {
        // Check if any forbidden substring is present in the email
        const includesForbiddenSubstring = forbiddenEmailDomains.some((substring) =>
          value?.toLowerCase()?.includes(substring)
        );
        return !includesForbiddenSubstring; // Return false if any forbidden substring is found
      }
      return true; // Return true if the email is valid
    })
    .matches(emailRegex, 'Should be a valid business email');

  const notRequiredEmailSchema = Yup.string()
    .notRequired()
    .email('Invalid email')
    .test('not-included', 'Business email address only', (value) => {
      if (value) {
        // Check if any forbidden substring is present in the email
        const includesForbiddenSubstring = forbiddenEmailDomains.some((substring) =>
          value?.toLowerCase()?.includes(substring)
        );
        return !includesForbiddenSubstring; // Return false if any forbidden substring is found
      }
      return true; // Return true if the email is valid
    })
    .matches(emailRegex, 'Should be a valid business email');

  const fieldsObject = useMemo(
    () => {
      const formFields: any = {
        email: emailDefaultQuestion?.fieldType === RegistrationFieldType.BUSINESS_EMAIL ? requiredEmailSchema : Yup.string()
          .email("Email must be valid")
          .required(`Email is required`),
        reConfirmEmail: Yup.string()
          .email("Email must be valid")
          .required(`Email is required`)
          .test('email-match', 'Emails do not match', function (value) {
            return value === this.parent.email;
          }),
        firstName: Yup.string()
          .required(`First Name is required`)
          .min(1, "Name must be at least 1 characters.")
          .test('no-spaces', 'First Name is required', function (value) {
            return typeof value === 'string' && value.trim() !== '';
          }),

        lastName: Yup.string()
          .required(`Last Name is required`)
          .min(1, "Name must be at least 1 characters.")
          .test('no-spaces', 'Last Name is required', function (value) {
            return typeof value === 'string' && value.trim() !== '';
          }),

        jobTitle: Yup.object().required(`Job Title is required`),
        companyId: Yup.object().required(`Company is required`),
        industryId: Yup.object().required(`Industry is required`),
        jobFunctionId: Yup.object().required(`Job Function is required`),
        location: Yup.mixed().required(`Location is required`),
        password: Yup.string().required(`Password is required`)
          .min(1, "Name must be at least 1 characters.")
          .test('no-spaces', 'Password is required', function (value) {
            return typeof value === 'string' && value.trim() !== '';
          }),
        confirmPassword: Yup.string()
          .required(`Confirm Password is required`)
          .oneOf([Yup.ref("password"), ""], "Passwords must match"),
        postCode: Yup.string().required(`Postcode is required`).min(1, "Name must be at least 1 characters.")
          .test('no-spaces', 'Postcode is required', function (value) {
            return typeof value === 'string' && value.trim() !== '';
          }),


      };

      if (signupRegQuestion?.length)
        signupRegQuestion.forEach((field: any) => {
          const { fieldType, isRequired, id, fieldName } = field;
          if (id) {
            switch (fieldType) {

              case RegistrationFieldType.BUSINESS_EMAIL:
                if (isRequired) {
                  formFields[fieldName] = requiredEmailSchema;
                } else {
                  formFields[fieldName] = notRequiredEmailSchema;
                }
                break;

              case RegistrationFieldType.PHONE_NUMBER:
                formFields[fieldName] = isRequired
                  ? Yup.string()
                    .required('Required Field')
                    .min(8, "Invalid Phone Number")
                    .test('no-spaces', 'Required Field', function (value) {
                      return typeof value === 'string' && value.trim() !== '';
                    })
                  // .test('isValidPhoneNumber', 'Invalid phone number', (value) => {
                  //   const phone = `+${value}`;
                  //   const phoneNumberObj = parsePhoneNumberFromString(phone, allPhoneNumbersCountryCode[fieldName]); // Adjust the default country code as needed
                  //   return phoneNumberObj && phoneNumberObj.isValid();
                  // })
                  : Yup.string().notRequired();
                break;

              case RegistrationFieldType.TERM_AND_CONDITION:
                if (isRequired) {
                  formFields[fieldName] = Yup.array().min(1, "At least one item is required")
                } else {
                  formFields[fieldName] = Yup.array().notRequired();
                }
                break;


              case RegistrationFieldType.JOB_FUNCTION:
              case RegistrationFieldType.INDUSTRY:
              case RegistrationFieldType.COMPANY:
              case RegistrationFieldType.JOB_TITLE:
              case RegistrationFieldType.ADDRESS:
              case RegistrationFieldType.ORGANIZATION_SIZE:

                if (isRequired) {
                  formFields[fieldName] = Yup.object().required(
                    `Required Field`
                  );
                } else {
                  formFields[fieldName] = Yup.object().notRequired();
                }
                break;

              default:
                if (isRequired) {
                  if (fieldName?.toLowerCase()?.includes(cardNumber)) {
                    formFields[fieldName] = Yup.string()
                      .required('Required Field')
                      .test('no-spaces', 'Invalid Value', function (value) {
                        return isCreditCard(value);
                      })
                  }

                  else if (fieldName?.toLowerCase()?.includes(cardExpiryDate)) {
                    formFields[fieldName] = Yup.string()
                      .required('Required Field')
                      .test('no-spaces', 'Expiration date cannot be in the past', function (value) {
                        const date = convertToDateFormat(value);
                        if (date) {
                          return !isDateInPast(date);
                        }
                        return false
                      })
                  }

                  else if (fieldName?.toLowerCase()?.includes(cardVerificationCode)) {
                    formFields[fieldName] = Yup.string().required(
                      `Required Field`
                    ).min(3, "Invalid value")
                      .max(3, "Invalid value")
                      .test('no-spaces', 'Required Field', function (value) {
                        return typeof value === 'string' && value.trim() !== '';
                      })
                  }

                  else {
                    formFields[fieldName] = Yup.string().required(
                      `Required Field`
                    ).min(1, "Name must be at least 1 characters.")
                      .test('no-spaces', 'Required Field', function (value) {
                        return typeof value === 'string' && value.trim() !== '';
                      })
                  }

                } else {
                  formFields[fieldName] = Yup.string().notRequired();
                }
                break;
            }
          }

        });


      return formFields;
    },
    // eslint-/exhaustive-depsdisable-next-line react-hooks
    [signupRegQuestion, allPhoneNumbersCountryCode]
  );

  const SignupSchema = Yup.object().shape(fieldsObject);


  const defaultValues: any = useMemo(
    () => {
      const formDefaultValues: any = {
        firstName: "",
        lastName: "",
        email: newUserEmail || "",
        reConfirmEmail: "",
        jobTitle: null,
        companyId: null,
        industryId: null,
        jobFunctionId: null,
        location: null,
        password: "",
        confirmPassword: "",
        postCode: "",
      };
      if (signupRegQuestion?.length) {

        signupRegQuestion.forEach((question: any) => {
          if (question?.id)
            formDefaultValues[question?.fieldName] = undefined;
        });

      }
      return formDefaultValues;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signupRegQuestion]
  );

  const methods = useForm<any>({
    resolver: yupResolver(SignupSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, isValid, errors },
  } = methods;
  const values = watch()

  useEffect(() => {
    let withoutTermsAndConditionQuestions = signupRegQuestion.filter((question) => question?.fieldType !== RegistrationFieldType.TERM_AND_CONDITION)

    const allTermsAndConditionQuestions = signupRegQuestion.filter((question) => question?.fieldType === RegistrationFieldType.TERM_AND_CONDITION)


    const phoneNumberQuestions = signupRegQuestion.filter((question) => question?.fieldType === RegistrationFieldType.PHONE_NUMBER);

    // phoneNumberQuestions?.forEach((question) => setValue(question?.fieldName, "+1()"))
    if (allTermsAndConditionQuestions.length) {
      allTermsAndConditionQuestions[0].registrationOptionFields = allTermsAndConditionQuestions
      withoutTermsAndConditionQuestions = [...withoutTermsAndConditionQuestions, allTermsAndConditionQuestions[0]]
    }
    setSignUpQuestions(withoutTermsAndConditionQuestions || [])
  }, [signupRegQuestion])



  const firstError = Object.keys(errors)[0]
  let el = document.querySelector(`[name="${firstError}"]`) || document.querySelector(`[id="${firstError}"]`);
  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "start"
    })
  }

  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  //functions
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSignupUser = () => {
    setNextButtonClicked(true)
    handleSubmit(submitForm)()
  }

  const getValidTermAndCondition = () => {
    const isValid = sessionStorage.getItem("termAndCondition")
    return isValid === "true"
  }

  const submitSignupForm = (data: any) => {
    // if (!isValid) return
    const payload: any = { ...data };

    let termAndConditionFields = {}
    const termsandconditionQuestion = signUpQuestions.find((question: any) => question?.fieldType === RegistrationFieldType.TERM_AND_CONDITION)
    if (termsandconditionQuestion) {

      // const result = findMatchingFieldId(termsandconditionQuestion.fieldName, data, termsandconditionQuestion);
      // delete data[page.fieldName]
      termAndConditionFields = { ...termAndConditionFields }
      data = { ...data, ...termAndConditionFields }
    }

    // const apiPayload = convertObjectToString(payload)

    onSubmit(data)
  }

  const submitForm = async (data: any) => {
    if (Object.keys(data).length && !Object.keys(data).every((x) => data[x] === undefined)) {
      if (termAndConditionAllPages?.length) {
        const isValidTermAndCondition = getValidTermAndCondition()
        if (isValidTermAndCondition) {
          submitSignupForm(data)
          sessionStorage.setItem("termAndCondition", "false")
        }
      }

      else {
        submitSignupForm(data)
      }
    }
  };

  const getUserProfileData = useCallback(async () => {

    const eventService = new EventsService();
    try {
      const { data: response } = await eventService
        .getProfileByEmail(newUserEmail);
      setValue("firstName", response?.firstName)
      setValue("lastName", response?.lastName)
      setValue("email", response?.email)
      setValue("jobTitle", response?.jobTitle ? { id: response?.jobTitle, name: response?.jobTitle } : null)
      setValue("companyId", response?.company && response?.company?.name?.toLowerCase() !== "n/a" ? response?.company : null)
      setValue("industryId", response?.industry && response?.industry?.name?.toLowerCase() !== "n/a" ? response?.industry : null)
      setValue("jobFunctionId", response?.jobFunction && response?.jobFunction?.name?.toLowerCase() !== "n/a" ? response?.jobFunction : null)
      setValue("location", response?.location ? { id: response?.location, name: response?.location } : null);
      setValue("postCode", response?.postCode || "");
      setProfileLoader(false)
    } catch (error) {
      setProfileLoader(false)
    }
  }, [newUserEmail])

  useEffect(() => {
    if (newUserEmail) {
      setProfileLoader(true)
      getUserProfileData()
    }
  }, [newUserEmail])

  const focusStyles = {
    outline: 'none', // Optionally, remove the default outline on focus
    boxShadow: `0 0 0 0.15em ${focusColor}`,
    border: `1px solid ${secondaryFontColor}`, // Change this to your desired focus border color
  };

  const convertToOrignalFieldName = (
    fieldName: string,
    linkColor: string = "#0131ff"
  ) => {
    let originalFieldName = fieldName
      .replace(/\&middot;/g, ".")
      .replace(/\&comma;/g, ",")
      .replace(/\&apos;/g, "'")
      .replace(/\&pipe;/g, "|");

    originalFieldName = originalFieldName.replace(
      /\{\{link:([^|]+)\|([^\}]+)\}\}/g,
      `<a href="$2" target="_blank" style="color: ${linkColor}; text-decoration: underline;">$1</a>`
    );
    return originalFieldName;
  };

  const checkPhoneFieldValidation = (values: any, id: string, isRequired: boolean) => {
    if (isRequired && nextButtonClicked) {
      if (values[id] && values[id] !== "") {
        return false
      }
      return true
    }

    return false
  }

  const handlePhoneNumberChange = (e: any, country: any, fieldName: string) => {
    // setPhoneNumber(e)
    setValue(fieldName, e)
    if (country) {
      setAllPhoneNumbersCountryCode({ ...allPhoneNumbersCountryCode, [fieldName]: country?.countryCode! })
    }
  }

  function findMatchingFieldId(id: string, data: any, page: any) {
    if (data.hasOwnProperty(id)) {
      const fieldValues = data[id];

      let termAndConditionChecked = {}
      for (const field of page.registrationOptionFields) {
        if (fieldValues?.includes(field?.fieldName)) {
          termAndConditionChecked = { ...termAndConditionChecked, [field?.fieldName]: true }
        }
        else if (!field?.isRequired) {
          termAndConditionChecked = { ...termAndConditionChecked, [field?.fieldName]: false }
        }
      }

      return termAndConditionChecked
    }
  }

  const checkTermFieldValidation = (val: any, question: any, isRequired: boolean) => {
    if (nextButtonClicked && val) {
      const result: any = findMatchingFieldId(question?.fieldName, { [question?.fieldName]: val }, question)
      const requiredIds = question.registrationOptionFields
        .filter((option: any) => option.isRequired)
        .map((option: any) => option.fieldName);

      const allRequiredIdsPresent = requiredIds.every((id: string) => Object.keys(result).includes(id))

      if (allRequiredIdsPresent) {
        sessionStorage.setItem("termAndCondition", "true")
        return true
      } else {
        sessionStorage.setItem("termAndCondition", "false")
        return false
      }
    }

  }

  const renderCreditCardField = (fieldName: string) => (
    <div>
      <div className="card-no cards">
        <Cleave
          placeholder="Enter credit card number"
          options={{
            creditCard: true,
            // creditCardStrictMode: false,
            // blocks:[44,4]
            onCreditCardTypeChanged: (type: string) => setValue(`${fieldName}-type`, type),
          }}
          onChange={(e: any) => setValue(fieldName, e.target.rawValue)}
          className="form-field"
          style={{
            border: `1px solid ${fieldBorderColor}`,
            backgroundColor: secondaryBackgroundColor,
            color: secondaryFontColor,
          }}
        />
        {values[`${fieldName}-type`] && values[`${fieldName}-type`]?.toLowerCase() !== "unknown" && (
          <p className="cardType" style={{
            color: secondaryFontColor,
          }} >{values[`${fieldName}-type`]}</p>
        )}
      </div>

      {errors && errors[fieldName] && <Typography id="event-name-input" gutterBottom sx={{ "&.MuiTypography-root": { color: "#FF5630", fontSize: "0.75rem", fontFamily: 'Poppins-400', ml: "1px", mt: "8px" } }}>
        {String(errors?.[fieldName]?.message) || "Required Field"}
      </Typography>}
    </div>

  );

  const renderExpiryDateField = (fieldName: string) => (
    <div>
      <div className="date-time">
        <Cleave
          placeholder="MM/YY"
          options={{ date: true, datePattern: ["m", "d"] }}
          onChange={(e: any) => setValue(fieldName, e.target.rawValue)}
          className="form-field"
          style={{
            border: `1px solid ${fieldBorderColor}`,
            backgroundColor: secondaryBackgroundColor,
            color: secondaryFontColor,
          }}
        />
      </div>

      {errors && errors[fieldName] && <Typography id="event-name-input" gutterBottom sx={{ "&.MuiTypography-root": { color: "#FF5630", fontSize: "0.75rem", fontFamily: 'Poppins-400', ml: "1px", mt: "8px" } }}>
        {String(errors?.[fieldName]?.message) || "Required Field"}
      </Typography>}
    </div>
  );

  const renderCVVField = (fieldName: string) => (
    <Stack direction="row" spacing="8px" alignItems="flex-start">
      <RHFTextField
        type='number'
        sx={{
          ...styles, "& .MuiOutlinedInput-root input::placeholder": {
            color: secondaryFontColor,
            opacity: 1
          },
          width: "80px",

          "& .MuiOutlinedInput-root": {
            width: "80px",
            borderRadius: '8px !important',
            fontFamily: 'Poppins-400',
            color: `${secondaryFontColor} !important`,
            paddingLeft: "12px !important",
          }
        }}
        key={fieldName}
        onChange={(e) => {
          if (e.target.value?.length <= 3)
            setValue(fieldName, e.target.value);
        }}
        value={values[fieldName]}
        name={fieldName}
      />
    </Stack>
  );

  const renderTextField = (fieldName: string) => (
    <RHFTextField
      placeholder={fieldName === "First name" || fieldName === "Last name" || fieldName === "Job title" || fieldName === "Postal Code" || fieldName === "Email" ? "" : "Type here…"}
      sx={{
        ...styles, ...textFieldFont, "& .MuiOutlinedInput-root input::placeholder": {
          color: secondaryFontColor,
          opacity: 1,
          fontFamily: "Poppins-400"
        },
      }}
      key={fieldName}
      onChange={(e) => {
        setValue(`${fieldName}`, e.target.value);
      }}
      value={values[fieldName]}
      name={fieldName}

    />

  );

  const evaluateConditions = (conditions: Condition[]): boolean => {
    let result: boolean | null = null;
    let andFlag = false;

    for (const condition of conditions) {
      if (condition.and !== undefined) {
        if (result === null) {
          result = condition.and;
        }
        //  else if (andFlag) {
        //     result = result && condition.and;
        // }
        else {
          result = result && condition.and;
        }
        andFlag = true;
      } else if (condition.or !== undefined) {
        if (result === null) {
          result = condition.or;
        }
        //  else if (andFlag) {
        //     result = result && condition.or;
        // }
        else {
          result = result || condition.or;
        }
        // andFlag = false;
      }
    }

    return result ?? true;
  };

  const getQuestionAction = (senariosResult: boolean, registrationFlowAction?: any[]) => {
    const onSuccess: any = registrationFlowAction?.find((reg: any) => reg?.type === "success")
    const onFail = registrationFlowAction?.find((reg: any) => reg?.type === "fail")

    if (!registrationFlowAction?.length) {
      return "show"
    }

    if (senariosResult) {
      return onSuccess?.action;
    }
    return onFail?.action;
  }

  const checkVisibility = (registrationFlow: any, fieldId: string, fieldName: string, registrationFlowAction: any) => {
    const results: any[] = [];
    if (registrationFlow?.length === 0) {
      if (!questionSenariosVisibility[fieldId]) {
        questionSenariosVisibility[fieldId] = {
          questionId: fieldId,
          currentStatus: "show",
          fieldName,
          senariosResult: true
        }
      }
    }

    registrationFlow?.forEach((scenario: any, index: number) => {
      const { action, condition, question, scenarioType, triggerOption, trigger } = scenario;

      switch (question?.fieldType) {
        case RegistrationFieldType.SELECT:
        case RegistrationFieldType.RADIO:
        case RegistrationFieldType.EMAIL:
        case RegistrationFieldType.BUSINESS_EMAIL:
        case RegistrationFieldType.TEXT:
        case RegistrationFieldType.FIRST_NAME:
        case RegistrationFieldType.LAST_NAME:
        case RegistrationFieldType.CHECKBOX:
        case RegistrationFieldType.POSTAL_CODE:
        case RegistrationFieldType.TERM_AND_CONDITION:
        case RegistrationFieldType.JOB_TITLE:
        case RegistrationFieldType.COMPANY:
        case RegistrationFieldType.PHONE_NUMBER:
        case RegistrationFieldType.ORGANIZATION_SIZE:

          if (!trigger) {
            const result = values[question?.fieldName] == triggerOption?.optionName;
            results?.push({
              [condition]: result, action: result ? action : "notMatched",
            });

          }
          else if (trigger) {
            const dependentQuestion = questionSenariosVisibility[question?.id]
            const result = trigger?.includes(dependentQuestion?.currentStatus) || (trigger === "hidden" && dependentQuestion?.currentStatus === "hide");
            results?.push({ [condition]: result, action: result ? action : "notMatched", trigger, questionId: question?.id, fieldName: question?.fieldName });
          }
          break;

        default: results?.push({ [condition]: false, action, });
      }
    });

    const senariosResult = evaluateConditions(results);
    questionSenariosVisibility[fieldId] = {
      questionId: fieldId,
      currentStatus: getQuestionAction(senariosResult, registrationFlowAction),
      fieldName,
      senariosResult: results?.length ? senariosResult : true
    };
    // if (results?.length && senariosResult === false) {
    //     setValue(fieldName, null)
    // }
    return questionSenariosVisibility[fieldId];
  }

  const displayDivider = () => <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />

  // display registration questions
  const registrationQuestion = useCallback(
    (question: any) => {
      let options: Options[] = [];
      if (question) {
        const { fieldType, id, fieldName, registrationOptionFields, isRequired, isDefault, registrationFlow, registrationFlowAction } = question;
        const label = convertToOrignalFieldName(fieldName)
        const currentQuestion: any = checkVisibility(registrationFlow, id, fieldName, registrationFlowAction);
        const questionAction = getQuestionAction(currentQuestion?.senariosResult, registrationFlowAction)
        const currentQuestionVisibility = questionAction === "hide" ? false : true
        if (!currentQuestionVisibility && values[fieldName] !== null) {
          setValue(fieldName, null)
        }
        switch (fieldType) {
          case RegistrationFieldType.COMPANY:
            return (
              currentQuestionVisibility &&
              <>
                <Box sx={{ width: "100%" }}>
                  <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontWeight: 500, mb: '5px', fontFamily: "Poppins-500" }}>
                    {getFieldLabel(isRequired, label)}
                  </Typography>
                  <PCompanySelectLiveSearch name={fieldName} sx={{
                    ...styles, ...autoCompleteFont, "& .MuiOutlinedInput-input": { height: '10px' }, "& .MuiAutocomplete-popupIndicator": { color: `${secondaryFontColor} !important` }, "& .MuiOutlinedInput-root input::placeholder": {
                      color: secondaryFontColor,
                      opacity: 1,
                      fontFamily: "Poppins-400"
                    },

                  }} />
                </Box>
                {displayDivider()}
              </>
            );

            case RegistrationFieldType.ORGANIZATION_SIZE:
              return (
                currentQuestionVisibility &&
                <>
                  <Box sx={{ width: "100%" }}>
                    <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontWeight: 500, mb: '5px', fontFamily: "Poppins-500" }}>
                      {getFieldLabel(isRequired, label)}
                    </Typography>
                    <PHeadCountSelect name={fieldName}
                      placeholder="Select an option"
                    sx={{
                      ...styles, ...autoCompleteFont, "& .MuiOutlinedInput-input": { height: '10px' }, "& .MuiAutocomplete-popupIndicator": { color: `${secondaryFontColor} !important` }, "& .MuiOutlinedInput-root input::placeholder": {
                        color: secondaryFontColor,
                        opacity: 1,
                        fontFamily: "Poppins-400"
                      },
  
                    }} />
                  </Box>
                  {displayDivider()}
                </>
              );

            

          case RegistrationFieldType.INDUSTRY:
            return (
              currentQuestionVisibility &&
              <>
                <Box sx={{ width: "100%" }}>
                  <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontWeight: 500, mb: '5px', fontFamily: "Poppins-500" }}>
                    {getFieldLabel(isRequired, label)}
                  </Typography>
                  <PIndustrySelect name={fieldName} placeholder={"Select an industry"} sx={{
                    ...styles, ...autoCompleteFont, "& .MuiOutlinedInput-input": { height: '10px' }, "& .MuiAutocomplete-popupIndicator": { color: `${secondaryFontColor} !important` }, "& .MuiOutlinedInput-root input::placeholder": {
                      color: secondaryFontColor,
                      opacity: 1,
                      fontFamily: "Poppins-400"
                    },
                    cursor: "pointer"
                  }} />
                </Box>
                {displayDivider()}
              </>
            );

            case RegistrationFieldType.JOB_FUNCTION:
            return (
              currentQuestionVisibility &&
              <>
                <Box sx={{ width: "100%" }}>
                  <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontWeight: 500, mb: '5px', fontFamily: "Poppins-500" }}>
                    {getFieldLabel(isRequired, label)}
                  </Typography>
                  <PJobFunctionSelect name={fieldName} placeholder={"Select Job Function"} sx={{
                    ...styles, ...autoCompleteFont, "& .MuiOutlinedInput-input": { height: '10px' }, "& .MuiAutocomplete-popupIndicator": { color: `${secondaryFontColor} !important` }, "& .MuiOutlinedInput-root input::placeholder": {
                      color: secondaryFontColor,
                      opacity: 1,
                      fontFamily: "Poppins-400"
                    },
                    cursor: "pointer"
                  }} />
                </Box>
                {displayDivider()}
              </>
            );

          case RegistrationFieldType.ADDRESS:
            return (
              currentQuestionVisibility &&
              <>
                <Box sx={{ width: "100%" }}>
                  <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontWeight: 500, mb: '5px', fontFamily: "Poppins-500" }}>
                    {getFieldLabel(isRequired, label)}
                  </Typography>

                  <LocationDropdown
                    name={fieldName}
                    placeholder={"Search for your city"}
                    sx={{
                      width: "100%", ...styles,

                      "& .MuiAutocomplete-input": { height: '10px !important' }, "& .MuiAutocomplete-popupIndicator": { color: `${secondaryFontColor} !important` }, "& .MuiOutlinedInput-root input::placeholder": {
                        color: secondaryFontColor,
                        opacity: 1,
                        fontFamily: 'Poppins-400'
                      },
                    }}
                    onSelectLocation={() => { }}
                  />
                </Box>
                {displayDivider()}
              </>
            );

          case RegistrationFieldType.JOB_TITLE:
            return (
              currentQuestionVisibility &&
              <>

                <Box sx={{ width: "100%" }}>
                  <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontWeight: 500, mb: '5px', fontFamily: "Poppins-500" }}>
                    {getFieldLabel(isRequired, label)}
                  </Typography>
                  <PJobTitleSelectLiveSearch name={fieldName} placeholder={"Select an option"} sx={{
                    ...styles, ...autoCompleteFont, "& .MuiOutlinedInput-input": { height: '10px' }, "& .MuiAutocomplete-popupIndicator": { color: `${secondaryFontColor} !important` }, "& .MuiOutlinedInput-root input::placeholder": {
                      color: secondaryFontColor,
                      opacity: 1,
                      fontFamily: "Poppins-400"
                    },
                  }} />
                </Box>
                {displayDivider()}
              </>
            );



          case RegistrationFieldType.PHONE_NUMBER:
            const isPhoneFiledDirty = checkPhoneFieldValidation(values, question?.fieldName, isRequired)
            return (
              currentQuestionVisibility &&
              <>
                <Box sx={{ width: "100%" }}>
                  <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontWeight: 500, mb: '5px', fontFamily: "Poppins-500" }}>
                    {getFieldLabel(isRequired, label)}
                  </Typography>
                  <Stack
                  id={fieldName}
                  sx={{
                    position: "relative"
                    , width: "100%"
                  }}>

                    <FormControl component="fieldset">

                      <PhoneInput
                        country={"us"}
                        // defaultCountry={"us"}
                        enableSearch
                        containerClass={classNames("arial-neue-35 w-full !h-[43px] !rounded-[4px] bg-transparent placeholder:text-[15px] text-[15px] text-white px-[0px] focus-visible:outline-[red] spectre-input-new-bottom", {

                        })}
                        value={values[fieldName]}
                        onChange={(e: any, country: any) => handlePhoneNumberChange(e, country, fieldName)}
                        inputProps={{
                          style: {
                            color: secondaryFontColor,
                            border: `1px solid ${errors && errors?.[fieldName] ? "red" : fieldBorderColor}`,
                            ...(isFocused[fieldName] && focusStyles),
                            borderRadius: "8px !important"
                          },
                        }}
                        onFocus={() => handleFocus(fieldName)}
                        onBlur={() => handleBlur(fieldName)}

                      />
                      {errors && errors[fieldName] && <Typography id="event-name-input" gutterBottom sx={{ "&.MuiTypography-root": { color: "#FF5630", fontSize: "0.75rem", fontWeight: 400, ml: "1px", mt: "8px",    mb:"0px !important" } }}>
                        {String(errors?.[fieldName]?.message) || "Required Field"}
                      </Typography>}
                    </FormControl>
                  </Stack >
                </Box>
                {displayDivider()}
              </>
            );


          case RegistrationFieldType.RADIO:
          case RegistrationFieldType.SELECT:
            return (
              currentQuestionVisibility &&
              <>
                <Box sx={{ width: "100%" }}>
                  <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontWeight: 500, mb: '5px', fontFamily: "Poppins-500" }}>
                    {getFieldLabel(isRequired, label)}
                  </Typography>
                  <div style={{ position: 'relative' }}>
                    <RHFSelect name={fieldName} key={id} sx={{
                      "& .MuiInputLabel-root": {
                        color: `${secondaryFontColor} !important`,
                      },
                      "& .MuiSelect-select": {
                        height: '43px !important',
                        display: 'flex',
                        alignItems: 'center'
                      },
                      "& .MuiFormHelperText-root": { ml: "1px" },
                      "& .MuiSelect-icon": {
                        color: `${secondaryFontColor} !important`,
                      },
                      ...styles,
                    }}>
                      {registrationOptionFields?.map((option: any) => (
                        <MenuItem key={option.id} value={option.optionName}>
                          {option.optionName}
                        </MenuItem>
                      ))}
                    </RHFSelect>

                    {!values[fieldName] && <Typography id="event-name-input" gutterBottom sx={{ color: secondaryFontColor, position: "absolute", top: "11px", left: "12px", fontFamily: "Poppins-400" ,fontSize:"14px"}}>
                      Select an option
                    </Typography>}
                  </div>
                </Box>
                {displayDivider()}
                
              </>
            );

          case RegistrationFieldType.TERM_AND_CONDITION:
            if (!registrationOptionFields?.length) return
            const isTermAndConditionRequired = checkTermFieldValidation(values[fieldName], question, isRequired)

            options = registrationOptionFields?.map((item: any) => {
              const checkboxLabel = convertToOrignalFieldName(item?.fieldName);
              return ({
                label: checkboxLabel,
                value: item?.fieldName,
              })
            });
            return (
              currentQuestionVisibility &&
              <>
                <Box sx={{ width: '100%' }}>
                  <Typography id="event-name-input" sx={{ "&.MuiTypography-root": { color: secondaryFontColor, fontSize: 16, fontFamily: 'Poppins-600', lineHeight: '23px', mb: '5px' } }}>
                    {getFieldLabel(isRequired, "Terms & Conditions")}
                  </Typography>
                  <PMultiCheckbox
                    eachFieldValidation={values[id]}
                    isTermAndConditionRequired={isTermAndConditionRequired && nextButtonClicked}
                    name={fieldName}
                    options={options}
                    sx={{
                      "& .MuiFormControlLabel-label": { color: secondaryFontColor ,  "&:hover":{
                        textDecoration:"underline"
                      }},
                      "& .MuiCheckbox-root.Mui-checked": { color: `${secondaryFontColor} !important` },
                      "& .MuiCheckbox-root": {
                        color: `${secondaryFontColor} !important`,
                        p: '0px !important',
                        pr: "5px !important"
                      },
                      "& .MuiFormHelperText-root": { ml: "1px" },
                    }}

                  />

                  {isTermAndConditionRequired === false && <Typography id="event-name-input" gutterBottom sx={{ "&.MuiTypography-root": { color: "#FF5630", fontSize: "0.75rem", fontWeight: 400, ml: "14px", mt: "8px" } }}>
                    Required Field
                  </Typography>}
                </Box>
                {displayDivider()}
              </>
            );



          case RegistrationFieldType.CHECKBOX:
            return (
              currentQuestionVisibility &&
              <>
                <Box sx={{ width: "100%" }}>
                  <PCheckbox name={fieldName} label={getFieldLabel(isRequired, label)} sx={{
                    "& .MuiFormHelperText-root": { ml: "1px" },
                    "& .MuiFormControlLabel-label": { color: secondaryFontColor ,   "&:hover":{
                      textDecoration:"underline"
                    } }, "& .MuiCheckbox-root": {
                      color: `${secondaryFontColor} !important`,
                      p: '0px !important',
                      pr: "5px !important"
                    }
                  }} />
                </Box>
                {displayDivider()}
              </>
            );

          case RegistrationFieldType.EMAIL:
            return (
              currentQuestionVisibility &&
              <>
                <Box sx={{ width: "100%" }}>
                  <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 16, fontWeight: 500, fontFamily: 'Poppins-600', mb: '5px' }}>
                    {getFieldLabel(isRequired, label)}
                  </Typography>
                  <RHFTextField
                    sx={{
                      ...styles, ...textFieldFont, borderColor: `${fieldBorderColor} !important`, color: `${secondaryFontColor}!important`, "& .Mui-disabled": {

                        WebkitTextFillColor: `${secondaryFontColor}!important`,
                        "fieldSet": {
                          borderColor: `${focusColor || fieldBorderColor} !important`
                        }
                      }
                    }}

                    disabled={isDefault}
                    key={id}
                    onChange={(e) => {
                      setValue(`${fieldName}`, e.target.value);
                    }}
                    value={values[question?.fieldName]}
                    name={fieldName}
                  />
                </Box>
                {displayDivider()}
              </>
            );

          default:
            return (
              currentQuestionVisibility &&
              <>
                <Box sx={{ width: "100%" }}>
                  <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 16, fontWeight: 500, fontFamily: 'Poppins-600', mb: '5px' }}>
                    {getFieldLabel(isRequired, label)}
                  </Typography>

                  {fieldName?.toLowerCase()?.includes(cardNumber) ? renderCreditCardField(fieldName) :
                    fieldName?.toLowerCase()?.includes(cardExpiryDate) ? renderExpiryDateField(fieldName) :
                      fieldName?.toLowerCase()?.includes(cardVerificationCode) ? renderCVVField(fieldName) :
                        renderTextField(fieldName)}
                </Box>
                {displayDivider()}
              </>
            );
        }
      }
      return true;
    },
    [values]
  );


  useImperativeHandle(ref, () => ({
    handleSignupUser,
  }));

  const storedData = sessionStorage.getItem("isProfileLoading");
  const isProfileLoading: boolean = storedData !== null ? JSON.parse(storedData) : false;

  return (
    <>
      {/* {loader || profileLoader || isProfileLoading ? ( */}
      {/* {loader || profileLoader ? (
        <Stack
          sx={{
            direction: "row",
            // minHeight: "100%",
            '@media (max-width: 768px)': {
              height: "68%"
            },
            '@media (min-width: 768px)': {
              height: "80%"
            },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : ( */}
      <>
        <Stack
          className={event?.websiteTemplate === "spectre" ? "custom-scroll-spectre" : "custom-scroll"}
          sx={{
            width: tabScreen ? "100%" : "100%",
            pl: '25px',
            pr: "15px",
            overflowY: 'scroll',
            '--scrollbar-color': primaryBackgroundColor,
            height: '100% !important',
            '@media (max-width: 768px)': {
              height: errorMessage ? 'calc(85vh - 330px)' : '',
            },
          }}

        >
          <FormProvider methods={methods} >
            <Stack
              sx={{
                direction: "row",
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "center",
                pt:"20px"
              }}
            // spacing="12px"
            >
              <Box sx={{ width: "100%" }}>
                <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontFamily: 'Poppins-500', lineHeight: '18px', mb: "5px" }}>
                  {getFieldLabel(true, "First name")}
                </Typography>
                <RHFTextField
                  name="firstName"
                  // placeholder={"Type here…"}
                  sx={{
                    ...styles, "& .MuiOutlinedInput-root input::placeholder": {
                      color: secondaryFontColor,
                      opacity: 1,
                    },
                  }}
                />
              </Box>

              <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />

              <Box sx={{ width: "100%" }}>
                <Typography id="event-name-input" gutterBottom sx={{ color: secondaryFontColor, fontSize: 14, fontFamily: 'Poppins-500', lineHeight: '18px', mb: "5px" }}>
                  {getFieldLabel(true, "Last name")}
                </Typography>
                <RHFTextField
                  name="lastName"
                  sx={{
                    ...styles, "& .MuiOutlinedInput-root input::placeholder": {
                      color: secondaryFontColor,
                      opacity: 1,
                    },
                  }}
                />
              </Box>

              <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />

              <Box sx={{ width: "100%" }}>
                <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontFamily: 'Poppins-500', lineHeight: '18px', mb: "5px" }}>
                  {getFieldLabel(true, emailDefaultQuestion?.fieldType === RegistrationFieldType.BUSINESS_EMAIL ? "Business Email Address" : "Email Address")}
                </Typography>
                <RHFTextField
                  name="email"
                  placeholder={"Type here…"}
                  // disabled
                  autoComplete="off"
                  sx={{
                    ...styles, borderColor: `${fieldBorderColor} !important`, color: `${secondaryFontColor}!important`, "& .Mui-disabled": {

                      WebkitTextFillColor: `${secondaryFontColor}!important`,
                      "fieldSet": {
                        borderColor: `${focusColor || fieldBorderColor} !important`
                      }
                    }
                  }}
                />
              </Box>

              <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />

              <Box sx={{ width: "100%" }}>
                <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontFamily: 'Poppins-500', lineHeight: '18px', mb: "5px" }}>
                  {getFieldLabel(true, "Re-Confirm Email Address")}
                </Typography>

                <RHFTextField
                  name="reConfirmEmail"
                  // placeholder={"Type here…"}
                  autoComplete="off"
                  sx={{
                    ...styles,
                    ...textFieldFont,
                    "& .MuiOutlinedInput-root input::placeholder": {
                      color: secondaryFontColor,
                      opacity: 1,
                      fontFamily: 'Poppins-400'
                    },
                  }}
                />
              </Box>

              <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />

              <Box sx={{ width: "100%" }}>
                <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontFamily: 'Poppins-500', lineHeight: '18px', mb: "5px" }}>
                  {getFieldLabel(true, "Job Title")}
                </Typography>
                <PJobTitleSelectLiveSearch name="jobTitle" sx={{
                  ...styles, "& .MuiOutlinedInput-input": { height: '10px' }, "& .MuiAutocomplete-popupIndicator": { color: `${secondaryFontColor} !important` }, "& .MuiOutlinedInput-root input::placeholder": {
                    color: secondaryFontColor,
                    opacity: 1,
                  },
                }} />
              </Box>

              <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />

              <Box sx={{ width: "100%" }}>
                <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontFamily: 'Poppins-500', lineHeight: '18px', mb: "5px" }}>
                  {getFieldLabel(true, "Organisation")}
                </Typography>

                <PCompanySelectLiveSearch name="companyId" sx={{
                  ...styles, "& .MuiOutlinedInput-input": { height: '10px' }, "& .MuiAutocomplete-popupIndicator": { color: `${secondaryFontColor} !important` }, "& .MuiOutlinedInput-root input::placeholder": {
                    color: secondaryFontColor,
                    opacity: 1,
                  },
                }} />
              </Box>

              <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />

              <Box sx={{ width: "100%" }}>
                <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontFamily: 'Poppins-500', lineHeight: '18px', mb: "5px" }}>
                  {getFieldLabel(true, "Industry")}
                </Typography>
                <PIndustrySelect name="industryId" placeholder={"Select an industry"} sx={{
                  ...styles,
                  "& .MuiOutlinedInput-input": { height: '10px', cursor: "pointer" },

                  "& .MuiAutocomplete-inputRoot": { fontFamily: 'Poppins-400', cursor: "pointer" }, "& .MuiAutocomplete-popupIndicator": { color: `${secondaryFontColor} !important` }, "& .MuiOutlinedInput-root input::placeholder": {
                    color: secondaryFontColor,
                    opacity: 1,
                    fontFamily: 'Poppins-400',
                  },

                }} />
              </Box>

              <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />

              <Box sx={{ width: "100%" }}>
                <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontFamily: 'Poppins-500', lineHeight: '18px', mb: "5px" }}>
                  {getFieldLabel(true, "Job Function")}
                </Typography>
                <PJobFunctionSelect name="jobFunctionId" placeholder={"Select Job Function"} sx={{
                  ...styles,
                  "& .MuiOutlinedInput-input": { height: '10px', cursor: "pointer" },

                  "& .MuiAutocomplete-inputRoot": { fontFamily: 'Poppins-400', cursor: "pointer" }, "& .MuiAutocomplete-popupIndicator": { color: `${secondaryFontColor} !important` }, "& .MuiOutlinedInput-root input::placeholder": {
                    color: secondaryFontColor,
                    opacity: 1,
                    fontFamily: 'Poppins-400',
                  },

                }} />
              </Box>

              <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />

              <Stack sx={{ width: "100%" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontFamily: 'Poppins-500', lineHeight: '18px', mb: "5px" }}>
                    {getFieldLabel(true, "City, Country")}
                  </Typography>
                  <LocationDropdown
                    name="location"
                    placeholder={"Search for your city"}
                    sx={{
                      width: "100%", ...styles,

                      "& .MuiAutocomplete-input": { height: '10px !important' }, "& .MuiAutocomplete-popupIndicator": { color: `${secondaryFontColor} !important` }, "& .MuiOutlinedInput-root input::placeholder": {
                        color: secondaryFontColor,
                        opacity: 1,
                        fontFamily: 'Poppins-400'
                      },
                    }}
                    onSelectLocation={() => { }}
                  />
                </Box>
              </Stack>

              <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />
              <Box sx={{ width: "100%" }}>
                <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontFamily: 'Poppins-500', lineHeight: '18px', mb: "5px" }}>
                  {getFieldLabel(true, "Post Code")}
                </Typography>
                <RHFTextField
                  name="postCode"
                  // placeholder={"Type here…"}
                  autoComplete="off"
                  // autoComplete="new-password"
                  sx={{
                    ...styles,
                    ...textFieldFont,
                    "& .MuiOutlinedInput-root input::placeholder": {
                      color: secondaryFontColor,
                      opacity: 1,
                      fontFamily: 'Poppins-400'
                    },
                  }}
                />
              </Box>
              <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />


              {signUpQuestions?.map((question: any) => {
                if (question?.id)
                  return (<>
                    {registrationQuestion(question)}
                    {/* <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} /> */}
                  </>
                  )

              })}

              <Box sx={{ width: "100%" }}>
                <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontFamily: 'Poppins-500', lineHeight: '18px', mb: "5px" }}>
                  {getFieldLabel(true, "Password")}
                </Typography>

                <RHFTextField
                  name="password"
                  sx={{
                    ...styles, ...textFieldFont, "& .MuiOutlinedInput-root input::placeholder": {
                      color: secondaryFontColor,
                      opacity: 1,
                      fontFamily: 'Poppins-400'
                    },
                  }}
                  autoComplete="new-password"

                  type={showPassword ? "text" : "password"}
                  // label={getFieldLabel(true, "Password")}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? (
                            <VisibilityOutlined
                              sx={{
                                color: secondaryFontColor || "#3D3E5C",
                              }}
                            />
                          ) : (
                            <VisibilityOffOutlined
                              sx={{
                                color: secondaryFontColor || "#3D3E5C",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

              </Box>

              <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />

              <Box sx={{ width: "100%" }}>
                <Typography id="event-name-input" sx={{ color: secondaryFontColor, fontSize: 14, fontFamily: 'Poppins-500', lineHeight: '18px', mb: "5px" }}>
                  {getFieldLabel(true, "Confirm Password")}
                </Typography>
                <RHFTextField
                  name="confirmPassword"
                  autoComplete="new-password"
                  sx={{
                    ...styles, ...textFieldFont, "& .MuiOutlinedInput-root input::placeholder": {
                      color: secondaryFontColor,
                      opacity: 1,
                      fontFamily: 'Poppins-400'
                    },
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                  // label={getFieldLabel(true, "Confirm Password")}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOutlined
                              sx={{
                                color: secondaryFontColor || "#3D3E5C",
                              }}
                            />
                          ) : (
                            <VisibilityOffOutlined
                              sx={{
                                color: secondaryFontColor || "#3D3E5C",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070", width: "100%" }} />

              <Typography
                variant="body1"
                sx={{
                  color: secondaryFontColor || "white", width: "100%", mt: "5px", mb: 2, fontFamily: "Poppins-600", fontSize: "14px", '@media (max-width: 768px)': {
                    mb: errorMessage ? "50px" : 2,
                  },
                }}
              >
                {stepDescription}
              </Typography>
            </Stack>
          </FormProvider>
        </Stack>
        {errorMessage && (
          <Stack
            direction="column"
            spacing={2}
            sx={{
              position: 'absolute',
              left: '10px',
              bottom: '5px',
              zIndex: '100000000',

              '@media (max-width: 768px)': {
                width: '100%',
                left: '0',
                bottom: '62px',
              },
            }}
          >

            <Alert severity="error"
              sx={{
                width: "100%", padding: '0 10px',
                //  textOverflow: "ellipsis",
                // overflow: "hidden",
                // maxWidth: "200px",
                // textWrap: "nowrap",
              }}>
              {errorMessage}
            </Alert>
          </Stack>
        )}
      </>
      {/* )} */}

    </>
  );
};

export default forwardRef(SignupForm);

