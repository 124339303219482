import { useState } from "react";
interface IRatingStart {
  isActive?: boolean;
}
export const RatingStartIcon = ({ isActive }: IRatingStart) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" style={{ height: '10px' }}>
  <path id="star-svgrepo-com" d="M7.781,3.534C7.211,2.511,6.926,2,6.5,2s-.711.511-1.281,1.534L5.071,3.8A1.651,1.651,0,0,1,4.7,4.33a1.538,1.538,0,0,1-.6.2L3.818,4.6c-1.107.25-1.66.376-1.792.8s.246.865,1,1.747l.2.228a1.656,1.656,0,0,1,.37.531,1.729,1.729,0,0,1,0,.657l-.03.3c-.114,1.177-.171,1.766.174,2.028s.863.023,1.9-.454l.268-.123a1.535,1.535,0,0,1,.6-.2,1.535,1.535,0,0,1,.6.2l.268.123c1.036.477,1.555.716,1.9.454s.288-.85.174-2.028l-.03-.3a1.727,1.727,0,0,1,0-.657,1.656,1.656,0,0,1,.37-.531l.2-.228c.755-.882,1.132-1.324,1-1.747s-.685-.549-1.792-.8L8.9,4.533a1.538,1.538,0,0,1-.6-.2A1.651,1.651,0,0,1,7.929,3.8Z" transform="translate(-2 -2)" fill="#4b4b4b"/>
</svg>

  );
};
