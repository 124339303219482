import SearchIcon from '../../assets/images/event-landing-pre-event/Icon-feather-search.svg';

interface IOfferInterestedSearchBoxProps {
  caption: string;
  children: JSX.Element;
}
export const OfferInterestedSearchBox = ({
  caption,
  children,
}: IOfferInterestedSearchBoxProps) => (
  <div className="text-left">
    <div className="font-medium text-[15px] leading-[23px] text-[#203C6E] mb-2.5">
      {caption}
      {' '}
      <span className="font-light">&#x28;Choose up to 5&#x29;</span>
    </div>
    <div className="flex relative">
      <img className="w-4 h-4 my-auto z-20 absolute left-[12px] top-[7px]" src={SearchIcon} alt="" />
      {children}
      {/* <input onChange={e => handleSearchOffer(e)} placeholder="Search for an offering, service or technology" className="w-80 text-sm py-1 pl-6 rounded-5" /> */}
    </div>
  </div>
);

export default OfferInterestedSearchBox;
