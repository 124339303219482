import { imageUrlPrefix } from '../../../../utils';
import DefaultAvatar from '../../../../assets/images/avatar-default.svg';
import { IConnectionRequests } from '../../../../models/nav-bar/connection-requests/connection-requests';
import { UserService } from '../../../../services';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar } from '../../../../shared-components/V2';
import { useDispatch } from 'react-redux';
import { getConnectionRequests } from '../../../../store/connection-requests';
import { toast } from 'react-toastify';

interface IConnectionRequestItemProps {
  connectionRequest: IConnectionRequests;
  fetchItems:(value:string)=>void
}

const ConnectionRequestItem = ({ connectionRequest,   fetchItems }: IConnectionRequestItemProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const acceptRequestHandler = () => {
    switch (connectionRequest.type) {
      case 'connection':
        new UserService().acceptConnectRequests(connectionRequest.id).then(() => {
          // dispatch(getConnectionRequests());
          fetchItems(connectionRequest.id);
          toast.success('Request Accepted!');
        });
        break;
      case 'meeting':
        new UserService().acceptOrRejectMeetings(connectionRequest.id, connectionRequest?.sender?.eventId!, { 'status': 'approved' }).then(() => {
          // dispatch(getConnectionRequests());
          fetchItems(connectionRequest.id);
          toast.success('Request Accepted!');
        });
        break;
    }
  };

  const rejectRequestHandler = () => {
    switch (connectionRequest.type) {
      case 'connection':
        new UserService().rejectConnectRequests(connectionRequest.id).then(() => {
          // dispatch(getConnectionRequests());
          fetchItems(connectionRequest.id);
          toast.success('Request Rejected!');
        });
        break;
      case 'meeting':
        new UserService().acceptOrRejectMeetings(connectionRequest.id, connectionRequest?.sender?.eventId!, { 'status': 'denied' }).then(() => {
          // dispatch(getConnectionRequests());
          fetchItems(connectionRequest.id);
          toast.success('Request Rejected!');
        });
        break;
    }

  };

  return (
    <div className="flex flex-row items-center w-full border-b border-blue-0 pb-2 pt-2 hover:bg-gray-1 px-1 last:border-b-0 hover:border-l-8 hover:border-blue-1 hover:border-b-blue-0 border-l-white border-l-8 pl-2 pr-[25px] justify-between">
      <div className="flex flex-row items-center">
        <Avatar
          src={`${imageUrlPrefix}/${connectionRequest.sender.avatar}`}
          alt="avatar"
          size="SMALL"
          className="border-1"
        />

        <div className="text-sm5 text-gray-1 ml-2 w-[285px] t-elipis-sm font-[400]">
          <div>
            <Link
              to={`/wall/${connectionRequest.sender?.slug}`}
              className="inline-block text-gray-1 font-medium capitalize">
              {connectionRequest.sender?.firstName} {connectionRequest.sender?.lastName}
            </Link>
          </div>
          <div>{connectionRequest.sender?.jobTitle}</div>
          {connectionRequest?.type === 'meeting' ? (
            <div className="text-sm5 font-normal text-[#3c3c3c] mt-0.5">
              <div className='inline cursor-pointer font-semibold' onClick={()=> navigate(`/wall/${connectionRequest?.sender?.slug}`)}>{connectionRequest.sender?.firstName} {connectionRequest.sender?.lastName}</div> <div className='inline'>Requested a meeting with you at</div> <div className='inline cursor-pointer font-semibold' onClick={()=> navigate(`/event/${connectionRequest.sender?.event?.slug}/discussion`)}>{connectionRequest?.sender?.event?.name}</div>
            </div>
          ) : (
            <div className="bg-[#0049EF] text-sm5 font-normal text-center text-white tracking-wide h-20 w-max p-2 rounded-[7px] mt-1 pt-[1px]">{connectionRequest?.type === 'meeting' ? 'Meeting Request' : 'Connection Request'}</div>
          )}
        </div>
      </div>


      <div className="flex flex-col justify-end gap-1">
        <button
          className="bg-[#0049EF] ml-5 text-sm5 font-normal hover:bg-blue-1 cursor-pointer text-white tracking-wide h-20 w-[64px] p-0 rounded-[7px]"
          onClick={acceptRequestHandler}>
          Accept
        </button>
        <button
          className="bg-gray-1 ml-5 text-sm5 font-normal hover:bg-gray-200 cursor-pointer text-black tracking-wide h-20 w-[64px] p-0 rounded-[7px] mt-1"
          onClick={rejectRequestHandler}>
          Decline
        </button>
      </div>
    </div>
  );
};

export default ConnectionRequestItem;
