export enum CompanySpecialitiesActionTypes {
  COMPANY_SPECIALITIES_LOADING = "COMPANY_SPECIALITIES_LOADING",
  COMPANY_SPECIALITIES_SUCCESS = "COMPANY_SPECIALITIES_SUCCESS",
  COMPANY_SPECIALITIES_ERROR = "COMPANY_SPECIALITIES_ERROR",
}

export enum CompanyInterestsActionTypes {
  COMPANY_INTERESTS_LOADING = "COMPANY_INTERESTS_LOADING",
  COMPANY_INTERESTS_SUCCESS = "COMPANY_INTERESTS_SUCCESS",
  COMPANY_INTERESTS_ERROR = "COMPANY_INTERESTS_ERROR",
}
