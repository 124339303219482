import { useSelector } from 'react-redux';
import classNames from 'classnames';
// import divider from '../../../assets/images/website-event/divider-wte.svg';
import { IAppState } from '../../../store';
import { Container, Stack } from '@mui/material';

const WhatToExpect = () => {
  const event = useSelector((state: IAppState) => state.website.event!);

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        maxWidth: '100%',
        p: 0,
        pt: '40px',
        pb: '40px',
        pl: '15px !important',
        pr: '15px !important',
        '@media (min-width: 768px)': { maxWidth: '750px' },
        '@media (min-width: 992px)': { maxWidth: '970px' },
        '@media (min-width: 1200px)': { maxWidth: '1170px' },
        '@media (min-width: 1280px)': { maxWidth: '1260px' },
      }}
    >
      <Stack
        flexDirection="column"
        sx={{
          // backgroundColor: 'rgba(255 255 255 / 20%)',
          // padding: '40px 50px 50px',
          borderRadius: '16px',
          overflow: 'hidden',
          position: 'relative',
          alignItems: 'center',
          // '@media (max-width: 1200px)': { padding: '40px 50px 350px', },
          // '@media (max-width: 768px)': { padding: '30px 20px 280px', },
        }}
      >
<div
      className={classNames({
        'w-full': event?.eventMetrics?.length > 0,
        hidden: !(event?.eventMetrics?.length > 0),
      })}
    >
      {/* <div
        className="font-semibold text-[#0326E5] text-[21px] leading-[25px] flex justify-center mb-[28px]"
        style={{
          color: event?.colorStyle?.values?.heading,
        }}
      >
        What to expect
      </div> */}
      <div className="flex flex-wrap md:flex-row flex-col justify-center md:gap-[74.5px] items-center">
        {event?.eventMetrics.map((item, idx) => (
          <div
            className={classNames('flex', {
              'md:gap-14 md:my-0 my-3': event?.eventMetrics.length > 1,
            })}
          >
            <div
              className={classNames({
                hidden: !(item.name && item.value),
              })}
            >
              <div className="flex flex-col justify-center items-center w-full font-semibold">
                <div
                  className="text-white md:leading-[85px] leading-[50px] text-center md:h-[80px] text-[45px] md:text-[57px] font-semibold md:mb-[7px] mb-3 bold-lato-font"
                  style={{
                    color: event?.colorStyle?.values?.backgroundButton,
                  }}
                >
                  {item.value}
                </div>
                <div
                  className="text-[14px] h-[20px] font-semibold text-[#ffffffa6] flex justify-center normal-lato-font"
                >
                  {item.name}
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'border-r-1 md:visible md:block hidden invisible md:border-white h-[106.72px] ml-[0px]',
                {
                  'border-r-0 hidden': idx === event?.eventMetrics.length - 1,
                }
              )}
              style={{ borderColor: event?.colorStyle?.values?.heading }}
            />
          </div>
        ))}
      </div>
    </div>
      </Stack>
    </Container>
    
  );
};

export default WhatToExpect;
