import { useEffect, useState } from 'react';
import { NoSpotlightEvents } from 'src/components/spotlight/no-spotlight-events';
import { ICompanyProfile } from '../../models/company/company-profile';
import { CompanyService } from '../../services/CompanyService';
import CompaniesListCard from './companiesListCard';
import Pagination from './pagination/pagination';

type ICompaniesProps = {
  searchText: string;
  onSeeAll: Function;
  infiniteScroll: boolean;
  filters?: string;
  companiesSearchResults: (value: any) => void;
};

export const Companies = (props: ICompaniesProps) => {
  const [companies, setCompanies] = useState<ICompanyProfile[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<ICompanyProfile[]>([]);
  const companyService: CompanyService = new CompanyService();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState<number>();

  const paginateFront = () => setCurrentPage(currentPage + 1);
  const paginateBack = () => setCurrentPage(currentPage - 1);

  const getCompanies = async () => {
    // TODO: API needs to send properly structured object back.
    const limit = props.infiniteScroll ? 10 : 5;
    const page = currentPage;
    const result: any = await companyService.getSearchCompanyResults(`${props.searchText}${props.filters ?? ''}`, limit, page);
    setCompanies([...result?.data?.companies?.data]);
    props?.companiesSearchResults([...result?.data?.companies?.data]);
    setTotalPage(result?.data?.companies?.totalPage);

    // const result: any = await companyService.getSearchCompanyResults(`${props.searchText}${props.filters ?? ''}`, limit).then(({ data = {} }: any) => data?.companies?.data);
    // if (result) setCompanies([...result]);
  };

  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchText, props.filters, props.infiniteScroll, currentPage]);

  const onClickCompany = (company: any) => {
    setSelectedCompanies([...selectedCompanies, company]);
  };

  if (selectedCompanies.length > 0) {
    const recentCompanyList: any = JSON.parse(localStorage.getItem('recentCompanyList')!);
    if (recentCompanyList?.length > 0) {
      const totalCompanies = recentCompanyList?.concat(selectedCompanies);
      const recentCompanies = totalCompanies?.filter((item: any, index: any) => index === totalCompanies?.findIndex((itemTwo: any) => itemTwo.id === item.id && itemTwo.id === item.id));
      localStorage.setItem('recentCompanyList', JSON.stringify(recentCompanies));
    } else {
      localStorage.setItem('recentCompanyList', JSON.stringify(selectedCompanies));
    }
  }

  return (
    <>
      {companies?.length > 0 ? (
        <div className="bg-white rounded-xl px-[20px] py-[20px] create-post-card">
          <span className="block text-md3 font-medium pb-0">Companies</span>
          <CompaniesListCard
            companies={companies}
            onFollow={async (slug: string) => {
              await companyService.followCompany(slug);
              await getCompanies();
            }}
            onUnfollow={async (slug: string) => {
              await companyService.unfollowCompany(slug);
              await getCompanies();
            }}
            onClickCompany={onClickCompany}
          />
          {
            !props.infiniteScroll && companies?.length > 0 && (
              <div onClick={() => props.onSeeAll()} className="text-[#285CB2] text-sm text-center font-semibold block pt-[20px] pb-0 hover:underline cursor-pointer">
                See all Companies results
              </div>
            )
          }
        </div>
      ) : null}
      {props.infiniteScroll && totalPage ? (
        <div className="create-post-card bg-white py-[14px] px-[20px]">
          <Pagination paginateFront={paginateFront} paginateBack={paginateBack} currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
        </div>
      ) : <NoSpotlightEvents />}
    </>
  );
};

export default Companies;
