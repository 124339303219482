import classNames from 'classnames';
import { Tab } from '@headlessui/react';

import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { IEvent } from 'src/models/event/event';
import { IRoom } from '../../../models/event/room';
import AccordionLayout from '../../../shared-components/Accordion/AccordionLayout';
import { AgendaTitle } from './agenda';

// TODO: Check if this file is used.
export const BreakoutAgendaBody = ({ room }: { room: IRoom }) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);

  return (
    <div key={room.id}>
      {
        room.sessions.map(session => (
          <div
            className={
              classNames(
                'w-full mb-[30px] last:mb-0'
              )
            }
          >
            <AccordionLayout
              styling="p-0 absolute right-0 top-0"
              titleData={(
                <AgendaTitle
                  event={event}
                  session={session}
                  eventOverview={session.summary}
                />
              )}
            />
          </div>
        ))
      }
    </div>
  );
};
export const BreakoutAgendaBodyLobby = ({ room, isLobbbyPage }: { room: IRoom, isLobbbyPage?: boolean; }) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  return (
    <div key={room.id}>
      {
        room.sessions.map(session => (
          <div
            className={
              classNames(
                'w-full mb-[30px] last:mb-0'
              )
            }
          >
            <AccordionLayout
              styling="p-0 absolute right-0 top-0"
              isTitleLobby
              titleData={(
                <AgendaTitle
                  event={event}
                  session={session}
                  eventOverview={session.summary}
                  isTitleLobby
                  isLobbbyPage={isLobbbyPage}
                />
              )}
            />
          </div>
        ))
      }
    </div>
  );
};
export const BreakoutAgendaTabs = ({
  room, roomType, selectedTab, onClick, isSliderTabs, tabsQty, backgroundColor, isLobbyRooms
}: { room: any; roomType: string; selectedTab: boolean; isSliderTabs?: boolean; tabsQty?: number; onClick: () => void; backgroundColor?: string; isLobbyRooms?: boolean; }) => (
  <Tab.Group>
    <Tab.List
      className={
        classNames(
          'my-0 mr-[6px]',
          {
            'w-[258px]': isSliderTabs,
            'w-[346px]': tabsQty === 3,
            '!w-[195px] rounded-[4px] bg-[#0049EF]': isLobbyRooms && tabsQty !== 1,
            '!w-auto rounded-[4px] bg-[#0049EF]': isLobbyRooms && tabsQty === 1,
            'bg-[#DFE5EB]': !selectedTab
          }
        )
      }
    >
      <Tab
        key={room.id}
        className={
          classNames(
            'focus:outline-none',
            {
              'w-[258px]': isSliderTabs,
              'w-[346px]': tabsQty === 3,
              '!w-[195px]': isLobbyRooms && tabsQty !== 1,
              '!w-auto': isLobbyRooms && tabsQty === 1,
            }
          )
        }
        onClick={onClick}
      >
        <div className={classNames('max-w-full mt-2', {
          'w-[258px]': isSliderTabs, 'w-[346px]': tabsQty === 3, '!mt-0': isLobbyRooms, '!w-auto': isLobbyRooms && tabsQty === 1,
        })}
        >
          <div className="block md:flex mx-auto">
            <div
              className={
                classNames(
                  'w-full h-[50px] rounded-[4px]',
                  {
                    '!h-auto': isLobbyRooms
                  }
                )
              }
              style={{
                background: backgroundColor,
                opacity: !selectedTab && !isLobbyRooms ? '0.5' : '1',
              }}
            >
              <div
                className={classNames(
                  'flex justify-start mx-5 my-3 w-[367px] truncate',
                  {
                    '!w-[220px] line-clamp-1 !block !mx-[14px] !my-[5px] text-left': isSliderTabs && tabsQty !== 1,
                    '!w-auto line-clamp-1 !block !mx-[14px] !my-[5px] text-left': isLobbyRooms && tabsQty === 1
                  }
                )}
              >
                <div className={classNames('text-[14px] h-[20px] font-semibold text-white', { 'text-white': selectedTab, 'text-[#203C6E]': isLobbyRooms && !selectedTab })}>
                  {roomType}
                </div>
                <div className={classNames('text-[14px] h-[20px] font-medium text-white', { 'text-white': selectedTab, 'ml-0': isSliderTabs, hidden: isLobbyRooms })}>
                  {room.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
    </Tab.List>
  </Tab.Group>
);
