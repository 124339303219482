import { CSSProperties, FC, HTMLAttributes } from 'react';

export interface ITypographyProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  variant:
    | 'H1'
    | 'H2'
    | 'H3'
    | 'StepTitle'
    | 'StepSubTitle'
    | 'SubTitle'
    | 'SmallTitle'
    | 'SmallSection'
    | 'MediumSection'
    | 'SectionTitle'
    | 'NameTitleSmall'
    | 'NameTitleXSmall'
    | 'CompanySubTitle'
    | 'CompanySubTitleTwo'
    | 'LocationSubTitle'
    | 'NameTitleMedium'
    | 'ContainerHeadingEvents'
    | 'ContainerHeading'
    | 'ContainerHeadingWithoutFontColor'
    | 'FormFieldCaption'
    | 'ValidationMessage';
  classes?: string;
  style?: CSSProperties;
}

export const Typography: FC<ITypographyProps> = ({ text, variant = 'H1', classes, style }) => {
  const variantMap = {
    H1: 'text-h1 text-blue-4',
    H2: 'text-h2 text-blue-4',
    H3: 'text-h3 font-light text-blue-4',
    StepTitle: 'text-xl font-medium text-blue-4',
    StepSubTitle: 'text-s-10 font-light text-gray-1',
    SubTitle: 'text-sm text-gray-1',
    SmallTitle: 'text-x-sm font-light text-blue-2',
    SmallSection: 'text-2x-sm text-blue-6',
    MediumSection: 'text-sm font-semibold text-blue-1',
    SectionTitle: 'text-xl font-medium text-blue-4',
    NameTitleSmall: 'text-h2 text-blue-2 font-semibold not-italic truncate',
    NameTitleXSmall: 'text-2x-sm text-blue-3 font-light',
    CompanySubTitle: 'text-sm5 text-blue-3 font-semibold truncate',
    CompanySubTitleTwo: 'text-h3 text-blue-3 font-semibold',
    LocationSubTitle: 'text-h3 text-gray-1',
    NameTitleMedium: 'text-md15 text-blue-2 font-semibold not-italic',
    ContainerHeadingEvents: 'text-md15 text-blue-3 not-italic',
    ContainerHeading: 'text-[14px] text-[#000000] font-normal leading-[20px]',
    ContainerHeadingWithoutFontColor: 'text-h2 font-normal leading-5',
    TextWithLinks: 'text-2x-sm font-medium text-gray-5',
    FormFieldCaption: '',
    ValidationMessage: 'text-sm5 font-normal text-red justify-center md:justify-start'
  };

  const extraClasses = variantMap[variant];

  return <div className={[classes, extraClasses].join(' ')} style={style}>{text!}</div>;
};
