import Background from '../../assets/images/event-live/event-live-bg.svg';

export const EventLiveSponsors = () => (
  <div
    className="w-full 2xl:h-1550 min-h-1182 lg:mt-32 2xl:mt-68"
    style={{
      background: `url(${Background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: ''
    }}
  >
    <div className="container mx-auto -mt-24 md:mt-1">
      <div className="justify-center block md:flex w-full md:w-10/12 lg:justify-between mx-auto ml-4 lg:ml-12 text-center">
        <div className="mt-20 md:mt-24 pt-4 w-4/5 md:w-2/5 mx-auto">
          <p className="mt-20 md:mt-0 lg:text-xl7a font-extralight text-xl text-blue-2">Vision</p>

          <p className="text-sm md:text-lg4 font-extralight text-blue-2 mt-3">
            Creating a more connected and prosperous community
          </p>
        </div>

        <div className="mt-20 md:mt-24 pt-4 w-4/5 md:w-2/5 mx-auto">
          <p className="lg:text-xl7a font-extralight text-xl text-blue-2">Mission</p>

          <p className="text-sm md:text-lg4 font-extralight text-blue-2 mt-3">
            Giving every individual a better chance in their growth and development
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default EventLiveSponsors;
