import React, { FC, HTMLAttributes } from 'react';
import SearchIcon from '../../assets/images/event-landing-pre-event/Icon-feather-search.svg';
import sortUp from '../../assets/images/sort-up-white.svg';
import sortDown from '../../assets/images/sort-down-white.svg';
import leftPrev from '../../assets/images/left-prev.svg';
import rightNext from '../../assets/images/right-next.svg';
import classNames from 'classnames';
import { formatDate } from '../../utils/datetime';

interface IRowsItems {
  chargeId: string;
  paymentAt: string;
  paymentType: string;
  amount: string;
  status: string;
}

interface IHeaderItems {
  name: string;
  label: string;
  icon: null | any;
  isSearchable: boolean;
  sortable: boolean;
  canSortASC: boolean;
  canSortDESC: boolean;
  width: string;
}

export interface IDataTableProps extends HTMLAttributes<HTMLInputElement> {
  rows: IRowsItems[];
  headers: IHeaderItems[];
  onPageChange: Function;
  onNextClick: Function;
  onBackClick: Function;
  totalRow: number;
  currentPage: number;
}

export const DataTable: FC<IDataTableProps> = ({ rows, headers, onPageChange, onNextClick, onBackClick, totalRow, currentPage }) => {
  const totalPages = Math.ceil(totalRow / 10);
  const pages = Array.from(Array(totalPages), (d, i) => i);

  return (
    <>
      <table className="table-auto w-full border-separate border-spacing-1.5 min-w-[866.5px] -ml-[6px]">
        <thead>
          <tr>
            {[...headers].map((item) => (
              <th
                key={item.name}
                className="bg-[#0049EF] h-[30px] rounded-[3px]"
                style={{ width: item.width || 'auto' }}>
                <div className="flex flex-row px-1 justify-between">
                  <div className="flex flex-row items-center w-full mr-1">
                    {item.icon && <span className="mr-1.5"><item.icon /></span>}
                    {item.isSearchable ? (
                      <div className="flex flex-row w-full">
                        <div className="px-2 w-full z-10 flex items-center bg-white rounded-[4px]">
                          <img className="mr-3" src={SearchIcon} alt="" />
                          <input
                            type="text"
                            className="h-[21px] w-full text-[13px] font-light text-[#3C3C3C] placeholder:text-[#3C3C3C] focus-visible:outline-none rounded"
                            placeholder={item.label}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    ) : (
                      <span className="text-white text-[14px] font-normal text-left">{item.label}</span>
                    )}
                  </div>
                  {item.sortable && (
                    <div className="flex flex-col items-center justify-center gap-1 w-6">
                      {item.canSortASC && (
                        <img className="w-2 h-2 cursor-pointer" src={sortUp} alt="ASC" />
                      )}
                      {item.canSortDESC && (
                        <img className="w-2 h-2 cursor-pointer" src={sortDown} alt="DESC" />
                      )}
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...rows].map((row) => (
            <tr key={row.chargeId}>
              <td className="bg-[#EDF2F5] rounded-[3px]">
                <span className="px-2 font-light text-[15px] text-black block">{formatDate(new Date(row.paymentAt))}</span>
              </td>
              <td className="bg-[#EDF2F5] rounded-[3px]">
                <span className="px-2 text-[16px] font-medium text-[#203C6E]">{row.chargeId}</span>
              </td>
              <td className="bg-[#EDF2F5] rounded-[3px]">
                <span className="px-2 text-[16px] font-medium text-[#203C6E]">{row.paymentType}</span>
              </td>
              <td className="bg-[#EDF2F5] rounded-[3px]">
                <span className="px-2 text-[16px] font-medium text-[#203C6E]">{row.amount}</span>
              </td>
              <td className="bg-[#EDF2F5] rounded-[3px]">
                <span className="px-2 text-[16px] font-medium text-[#203C6E]">{row.status}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-row pt-3 justify-between items-center">
        <span className="font-medium text-[#203C6E] text-[14px]">Showing 1-10 of {totalPages}</span>
        <div className="flex flex-row items-center">
          <span className="flex flex-row font-normal text-[#285CB2] tracking-wide text-md pr-4" onClick={() => onBackClick()}>
            <img className="pr-1" src={leftPrev} alt="left prev" />
            Back
          </span>
          {pages.map((item) => (
            <span key={item} className="font-medium text-[#172746] cursor-pointer text-base pr-4" onClick={() => onPageChange(item+1)}>
              <span className={classNames("w-[25px] h-[25px] font-regular flex rounded-full justify-center",{
              "bg-[#EDF2F5]": item+1 === currentPage
            })}> {item + 1} </span>
            </span>
          ))}
          <span className="flex flex-row font-normal text-[#285CB2] tracking-wide text-md" onClick={() => onNextClick()}>
            Next
            <img className="pl-1" src={rightNext} alt="right next" />
          </span>
        </div>
      </div>
    </>
  );
};
