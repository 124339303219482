import { ICreateComment } from '../../models/post/comment';
import { ICommentsResult } from '../../models/post/CommentsResult';
import { ICreatePost } from '../../models/post/create-post';
import { ILikeUnlikeCommentState } from '../../models/post/LikeUnlikeCommentState';
import { ILikeUnlikePostState } from '../../models/post/LikeUnlikePostState';
import {
  IPost,
  IPostComment,
} from '../../models/post/post';
import { IPostCreatedResult } from '../../models/post/PostCreatedResult';
import { IPostResult } from '../../models/post/PostResult';
import { IPostsResult } from '../../models/post/PostsResult';
import { IPostUserActionResult } from '../../models/post/PostUserActionResult';
import BaseService from '../BaseService';

export class PostService extends BaseService {
  async createEventPost(post: ICreatePost) {
    const extraOptions = this.getAuthToken();

    return this.post<IPostCreatedResult>('/posts', post, extraOptions);
  }

  async getCompanyPosts(companyIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    const postsResult = await this.get<IPostsResult>(
      `/companies/${companyIdOrSlug}/posts?page=1&limit=10&order[createdAt]=DESC`,
      extraOptions
    );

    // let updatedPosts: IPost[] = [];

    // const postsWithComments = postsResult.data.map(
    //   // eslint-disable-next-line
    //   async (post) => this.appendCommentsToPost(post)
    // );

    // updatedPosts = await Promise.all(postsWithComments);

    return { ...postsResult, data: postsResult.data };
  }

  async getUpcomingEventPosts(
    eventIdOrSlug: string,
    page: number = 1,
    orderBy: string = '&order[createdAt]=DESC'
  ) {
    const extraOptions = this.getAuthToken();

    const postsResult = await this.get<IPostsResult>(
      `/events/${eventIdOrSlug}/posts?page=${page}&limit=10${orderBy}`,
      extraOptions
    );

    // let updatedPosts: IPost[] = [];

    // const postsWithComments = postsResult.data.map(
    //   // eslint-disable-next-line
    //   async (post) => this.appendCommentsToPost(post)
    // );

    // updatedPosts = await Promise.all(postsWithComments);

    return { ...postsResult, data: postsResult.data, postsResult };
  }

  async appendCommentsToPost(post: IPost) {
    let comments: IPostComment[] = [];
    const commentsResult = await this.getPostComments(post.id);

    commentsResult.data.forEach(comment => comments.push({
      commentId: comment.id,
      avatarUrl: comment.commentedAsUser?.avatar
        ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${comment.commentedAsUser.avatar}`
        : `${process.env.REACT_APP_IMAGE_URL_PREFIX}/images/20211129/blank-profile-picture-g7b0695023640-udq004b.png`,
      name: `${comment.commentedAsUser?.firstName} ${comment.commentedAsUser?.lastName}`,
      jobTitle: post.postedAsUser?.jobTitle,
      companyName: post.postedAsUser?.company?.name,
      timeAgo: '',
      commentText: comment.content,
      reactionsCount:
          comment.totalClaps
          + comment.totalHahas
          + comment.totalLikes
          + comment.totalLoves
          + comment.totalSmiles,
      totalClaps: comment.totalClaps,
      totalHahas: comment.totalHahas,
      totalLikes: comment.totalLikes,
      totalLoves: comment.totalLoves,
      totalSmiles: comment.totalSmiles,
      totalComments: comments.length,
    }));

    // eslint-disable-next-line
    post.comments = comments;
    comments = [];

    return post;
  }

  async getPostComments(postId: string, page: number = 1) {
    const extraOptions = this.getAuthToken();
    return this.get<ICommentsResult>(
      `/posts/${postId}/comments?page=${page}&limit=5&order[createdAt]=DESC`,
      extraOptions
    );
  }

  async getCommentReplies(postId: string, commentId: string) {
    const extraOptions = this.getAuthToken();

    return this.get<ICommentsResult>(
      `/posts/${postId}/comments?page=1&limit=10&where[parentId]=${commentId}`,
      extraOptions
    );
  }

  async createComment(postId: string, data: ICreateComment) {
    const extraOptions = this.getAuthToken();

    return this.post<ICommentsResult>(
      `/posts/${postId}/comments`,
      data,
      extraOptions
    );
  }

  async editComment(commentId: string, data: ICreateComment) {
    const extraOptions = this.getAuthToken();

    return this.put<ICommentsResult>(
      `/comments/${commentId}`,
      data,
      extraOptions
    );
  }

  async likePost(postId: string, reaction: string) {
    const extraOptions = this.getAuthToken();

    return this.post<ILikeUnlikePostState>(
      `/posts/${postId}/likes?format=${reaction}`,
      {},
      extraOptions
    );
  }

  async unlikePost(postId: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<ILikeUnlikePostState>(
      `/posts/${postId}/likes`,
      extraOptions
    );
  }

  async likeComment(commentId: string, reaction: string) {
    const extraOptions = this.getAuthToken();

    return this.post<ILikeUnlikeCommentState>(
      `/comments/${commentId}/likes?format=${reaction}`,
      {},
      extraOptions
    );
  }

  async unlikeComment(commentId: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<ILikeUnlikeCommentState>(
      `/comments/${commentId}/likes`,
      extraOptions
    );
  }

  async deleteComment(commentId: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<any>(`/comments/${commentId}`, extraOptions);
  }

  async hidePost(postId: string) {
    const extraOptions = this.getAuthToken();

    return this.post<IPostUserActionResult>(
      `/posts/${postId}/hide`,
      {},
      extraOptions
    );
  }

  async unhidePost(postId: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<IPostUserActionResult>(
      `/posts/${postId}/hide`,
      extraOptions
    );
  }

  async reportPost(postId: string, message: string) {
    const extraOptions = this.getAuthToken();

    return this.post<IPostUserActionResult>(
      `/posts/${postId}/hide`,
      { message },
      extraOptions
    );
  }

  async unfollowPost(postId: string) {
    const extraOptions = this.getAuthToken();

    return this.post<IPostUserActionResult>(
      `/posts/${postId}/unfollow`,
      {},
      extraOptions
    );
  }

  async editPost(postId: string, content: string, privacy?: string) {
    const extraOptions = this.getAuthToken();

    return this.put<IPost>(
      `/posts/${postId}`,
      { content, privacy },
      extraOptions
    );
  }

  async deletePost(postId: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<IPostUserActionResult>(`/posts/${postId}`, extraOptions);
  }

  async getPost(postId: string) {
    const extraOptions = this.getAuthToken();

    const postResult = await this.get<IPostResult>(
      `/posts/${postId}`,
      extraOptions
    );

    const postWithComments = await this.appendCommentsToPost(postResult.data);

    return postWithComments;
  }

  async getPostLikes(postId: string, filter: string) {
    const extraOptions = this.getAuthToken();

    return this.get<any>(
      `/posts/${postId}/likes?page=1&limit=100${filter}`,
      extraOptions
    );
  }
}
