import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { HomeIconSvg, IconSvg, SpotLightSvgIcon } from './../IconSvg';
import { EventsLeftMenuActiveIcon } from '../IconSvg/EventsSvgIcon/EventsLeftMenuActiveIcon';
import { matchPath, useLocation } from 'react-router';
import { EventsLeftSvgIcon } from '../IconSvg/EventsSvgIcon/EventsLeftSvgIcon';

export interface INavMenuCardIem {
  text: string;
  url: string;
  icon: any
}

export interface INavMenuCardProps {
  cardItems: INavMenuCardIem[];
  className?: string;
  iconsClassName?: string;
  onNavClick?: (p: string | undefined) => void;
}

interface ActiveMenuProps {
  index: number;
  activeItem: boolean;
}

export const IconNavMenuCard: FC<INavMenuCardProps> = ({ 
  cardItems, 
  className = '', 
  iconsClassName,
  onNavClick 
}) => {
  const location = useLocation();
  const isHomeActive = !!matchPath('/home', location.pathname);
  const isEventActive = (!!matchPath('/events', location.pathname)
    || !!matchPath('/events/:param', location.pathname)
    || !!matchPath('/event/:eventidorslug/discussion', location.pathname)
    || !!matchPath('/:hostName/:slug/live', location.pathname)
    || !!matchPath('/event/:eventidorslug/moderator', location.pathname)
    || !!matchPath('/event/:eventidorslug/speaker', location.pathname));
  const isSpotlightActive = !!matchPath('/spotlight', location.pathname);
  const [activeMenu, setActiveMenu] = useState<ActiveMenuProps>({
    index: 0,
    activeItem: false
  });

  const curentMenuIcon = (icon: string) => {
    if (icon === 'home') {
      return <HomeIconSvg isActive={isHomeActive || activeMenu.index === 0 && activeMenu.activeItem} />
    }
    if (icon === 'event') {
      return <EventsLeftSvgIcon isActive={isEventActive || activeMenu.index === 1 && activeMenu.activeItem} />
    }
    if (icon === 'spotlight') {
      return <SpotLightSvgIcon isActive={isSpotlightActive || activeMenu.index === 2 && activeMenu.activeItem} />
    }

    return null;

  }
  return (
    <div 
      className={classNames("rounded-lg md:w-full", {
        [`${className}`]: className
      })}
    >
      <div className="mt-2">
        {cardItems &&
          cardItems.map((cardItem, i) => (
            <div 
              onClick={() => onNavClick!(cardItem.url)}
              key={i}
              className="cursor-pointer mb-[6px]"
              aria-label={`${"nav-target"}${i}`}
              onMouseEnter={() => setActiveMenu({index: i, activeItem: true})}
              onMouseLeave={() => setActiveMenu({index: i, activeItem: false})}
            >
              <div
                className={classNames("flex items-center rounded-[5px] hover:bg-[#E8EBED] px-[10px] h-51 relative", {
                  "bg-[#E8EBED]": cardItem.text === "Home",
                })}
              >
                {cardItem.text === "Home" || activeMenu.index === i && activeMenu.activeItem ? <div className=' absolute left-0 top-0'><EventsLeftMenuActiveIcon /></div> : null}
                <span 
                  className={classNames("mr-[6px] flex items-center justify-center", {
                    [`${iconsClassName}`]: iconsClassName,
                    "mt-[5px]": cardItem.text === "Events",
                    "-mt-[1px]": cardItem.text === "Spotlight",
                    "mt-[0px]": cardItem.text === "Home"
                  })}
                >
                    {curentMenuIcon(cardItem.icon)}
                </span>
                <span className=' text-[15px] leading-[15px] font-normal text-[#000000]'>{cardItem.text}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
