import { Box, Skeleton, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Plugin,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getBrandingColors } from "../live/liveEventStyling";
import { useSelector } from "react-redux";
import { IAppState } from "src/store";
import { EventsService } from "src/services/events";

ChartJS.register(ArcElement, Tooltip, Legend);

interface RoleCount {
  [key: string]: number;
}

interface RoleInfo {
  text: string;
  color: string;
  count: number;
}


const externalTooltipHandler = (context: any) => {
  let tooltipEl = document.getElementById("chartjs-tooltip");
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.color = "#fff";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.padding = "8px";
    tooltipEl.style.zIndex = "1000";
    document.body.appendChild(tooltipEl);
  }

  const { chart, tooltip } = context;

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b: any) => b.lines);

    let innerHtml = "<div>";
    titleLines.forEach((title: string) => {
      innerHtml += `<div style="font-weight:bold;margin-bottom:4px;">${title}</div>`;
    });
    bodyLines.forEach((body: string) => {
      innerHtml += `<div>${body}</div>`;
    });
    innerHtml += "</div>";

    tooltipEl.innerHTML = innerHtml;
  }

  const canvasRect = chart.canvas.getBoundingClientRect();
  const tooltipLeft = canvasRect.left + window.pageXOffset + tooltip.caretX;
  const tooltipTop = canvasRect.top + window.pageYOffset + tooltip.caretY;

  tooltipEl.style.opacity = "1";
  tooltipEl.style.left = tooltipLeft + "px";
  tooltipEl.style.top = tooltipTop + "px";
};

const ICTDecisionChart = () => {
  const chartRef = useRef<any>(null);
  const colors = getBrandingColors();
  const [ictRoleOverview, setIctRoleOverview] = useState({} as { question: string; state: RoleCount });
  const [isLoader, setisLoader] = useState(false);
  const [roleData, setRoleData] = useState<RoleInfo[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [apiData, setApiData] = useState<{ [key: string]: RoleCount }>({});

  const liveEvent = useSelector((state: IAppState) => state.liveEvent);

  const transformRoleData = (roleResponse: RoleCount): RoleInfo[] => {
    const accentColor = colors?.Accent || "#0F39D9";
    const bulletsColors = generateColorVariants(
      accentColor,
      Object.entries(roleResponse).length
    );
    return Object.entries(roleResponse).map(([text, count], index) => ({
      text,
      color: bulletsColors[index] || bulletsColors[0],
      count,
    }));
  };

  const generateColorVariants = (baseColor: string, count: number) => {
    let r: string, g: string, b: string, a: string;

    const rgbaMatch = baseColor.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*(\d?.?\d*)?\)/
    );

    if (rgbaMatch) {
      [, r, g, b, a] = rgbaMatch;
      a = a && a.trim() !== "" ? a : "1";
    } else if (/^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/.test(baseColor)) {
      const hex = baseColor.slice(1);
      if (hex.length === 3) {
        r = parseInt(hex[0] + hex[0], 16).toString();
        g = parseInt(hex[1] + hex[1], 16).toString();
        b = parseInt(hex[2] + hex[2], 16).toString();
      } else {
        r = parseInt(hex.substring(0, 2), 16).toString();
        g = parseInt(hex.substring(2, 4), 16).toString();
        b = parseInt(hex.substring(4, 6), 16).toString();
      }
      a = "1";
    } else {
      return Array(count).fill(baseColor);
    }

    const minOpacity = 0.2;
    let step = 0;
    if (count > 1) {
      step = (1 - minOpacity) / (count - 1);
    }

    return Array.from({ length: count }, (_, index) => {
      let opacity = 1 - index * step;
      if (opacity < minOpacity) {
        opacity = minOpacity;
      }
      return `rgba(${r}, ${g}, ${b}, ${opacity.toFixed(2)})`;
    });
  };

  const prepareChartData = (response: RoleCount) => {
    const total = Object.values(response).reduce((sum, count) => sum + count, 0);
    const percentages = Object.values(response).map((count) =>
      Number(((count / total) * 100).toFixed(1))
    );
    const accentColor = colors?.Accent || "#0F39D9";
    const backgroundColors = generateColorVariants(
      accentColor,
      Object.keys(response).length
    );
    return {
      labels: Object.keys(response),
      datasets: [
        {
          data: percentages,
          backgroundColor: backgroundColors,
          borderColor: Array(Object.keys(response).length).fill(
            colors?.Secondary || "#f6f6f6"
          ),
          borderWidth: 4,
          spacing: 0,
        },
      ],
    };
  };

  const fetchRoleOverview = useCallback(async (eventId: string) => {
    setisLoader(true);
    try {
      const eventService = new EventsService();
      const response = await eventService.getRandomSurveyStatistics(eventId);
      setisLoader(false);
      return response?.data;
    } catch (error) {
      console.error("Failed to fetch role overview:", error);
      setisLoader(false);
    }
  }, []);

  useEffect(() => {
    const eventId = liveEvent?.event?.id;
    if (!eventId) return;

    (async () => {
      const res = await fetchRoleOverview(eventId);
      if (res) {
        setApiData(res);
      }
    })();
  }, [liveEvent?.event?.id, fetchRoleOverview]);

  useEffect(() => {
    const keys = Object.keys(apiData);
    if (keys.length === 0) return;

    const currentKey = keys[currentQuestionIndex];
    setIctRoleOverview({
      question: currentKey,
      state: apiData[currentKey],
    });
    setRoleData(transformRoleData(apiData[currentKey]));
  }, [currentQuestionIndex, apiData]);

  useEffect(() => {
    const keys = Object.keys(apiData);
    if (keys.length === 0) return;

    const interval = setInterval(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % keys.length);
    }, 15000);
    return () => clearInterval(interval);
  }, [apiData]);

  const data = useMemo(() => {
    if (ictRoleOverview?.state) {
      return prepareChartData(ictRoleOverview.state);
    }
    return {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 4,
          spacing: 0,
        },
      ],
    };
  }, [ictRoleOverview?.state, colors]);

  const customLabelsPlugin: Plugin<"doughnut"> = {
    id: "customLabels",
    afterDraw(chart) {
      const { ctx, chartArea } = chart;
      const dataset = chart.data.datasets[0];
      ctx.save();
      ctx.font = "10px Poppins-500";
      ctx.fillStyle = colors?.Text || "#FF0000";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;
      const radius = (Math.min(chartArea.width, chartArea.height) / 2) * 0.75;

      let startAngle = -Math.PI / 2;
      dataset.data.forEach((value: any, index: number) => {
        const angle = (value / 100) * 2 * Math.PI;
        const midAngle = startAngle + angle / 2;
        const x = centerX + radius * Math.cos(midAngle);
        const y = centerY + radius * Math.sin(midAngle);
        ctx.fillText(`${value}%`, x, y);
        startAngle += angle;
      });
      ctx.restore();
    },
  };

  const options: any = {
    cutout: "48%",
    circumference: 360,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
      },
    },
    maintainAspectRatio: false,
    events: ["mousemove", "mouseout", "click", "touchstart", "touchmove"],
  };

  return isLoader ? (
    <Skeleton
      variant="rectangular"
      height="100%"
      sx={{ margin: "10px 0", borderRadius: "5px" }}
    />
  ) : (
    <Box
      sx={{
        bgcolor: colors?.Secondary || "#edecf3",
        borderRadius: "10px",
        px: "18px",
        py: "14px",
        mt: "14px",
        mb: "10px",
        width: "100%",
        height: "100%",
        overflow: "visible",
      }}
    >
      <Typography
        sx={{
          fontSize: "19px",
          fontFamily: "Poppins-500",
          fontWeight: 500,
          color: colors?.Text || "#100c7f",
          lineHeight: "25px",
          mb: "14px",
        }}
      >
        {ictRoleOverview?.question ||
          "What is your role in ICT decision-making within your organisation?"}
      </Typography>

      <Box
        sx={{
          display: "flex",
          mt: "14px",
          height: "calc(100% - 80px)",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "205px",
            height: "205px",
            ml: "14px",
          }}
        >
          <Doughnut
            ref={chartRef}
            data={data}
            options={options}
            plugins={[customLabelsPlugin]}
          />
        </Box>

        <Box sx={{ ml: "35px", maxHeight: "calc(100% - 15px)", overflowY: "auto" }}>
          {roleData?.map((item, index) => (
            <Box key={index} sx={{ display: "flex", alignItems: "start", mb: "11px" }}>
              <Box
                sx={{
                  width: 5,
                  height: 5,
                  minWidth: 5,
                  minHeight: 5,
                  bgcolor: item.color,
                  mr: "8px",
                  mt: "5px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Poppins-500",
                  fontWeight: 500,
                  color: colors?.Text || "#100c7f",
                  lineHeight: "17px",
                }}
              >
                {item.text.split("\n").map((line, i) => (
                  <span key={i}>
                    {line}
                    <br />
                  </span>
                ))}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ICTDecisionChart;