import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IProfilePictureState } from '../../../models/company/ProfilePictureState';
import { ProfilePictureActionTypes } from './types';

export interface IProfilePictureSuccessAction {
  type: ProfilePictureActionTypes.SET_COMPANY_PROFILE_PICTURE;
  url: string;
}

export interface IProfilePictureErrorAction {
  type: ProfilePictureActionTypes.PROFILE_COMPANY_PICTURE_ERROR;
  errorMessage: string;
}

export interface IProfilePictureResetAction {
  type: ProfilePictureActionTypes.RESET_PROFILE_COMPANY_PICTURE;
}

export type ProfilePictureActions = IProfilePictureSuccessAction | IProfilePictureErrorAction | IProfilePictureResetAction;

export const setProfilePicture: ActionCreator<
  ThunkAction<Promise<any>, IProfilePictureState, null, IProfilePictureSuccessAction>
> = (url: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ProfilePictureActionTypes.SET_COMPANY_PROFILE_PICTURE,
      url
    });
  };
};

export const setProfilePictureError: ActionCreator<
  ThunkAction<Promise<any>, IProfilePictureState, null, IProfilePictureSuccessAction>
> = (fileName: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ProfilePictureActionTypes.PROFILE_COMPANY_PICTURE_ERROR,
      errorMessage: `Failed to upload file ${fileName}`
    });
  };
};

export const resetProfilePicture: ActionCreator<
  ThunkAction<Promise<any>, IProfilePictureState, null, IProfilePictureSuccessAction>
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ProfilePictureActionTypes.RESET_PROFILE_COMPANY_PICTURE
    });
  };
};
