export const StarIcon = ({ fillColor }: { fillColor?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="39.037" height="39.011" viewBox="0 0 39.037 39.011">
    <defs>
      <filter id="Path_27131" x="2.798" y="0" width="36.239" height="36.715" filterUnits="userSpaceOnUse">
        <feOffset dy="3"/>
        <feGaussianBlur stdDeviation="3" result="blur"/>
        <feFlood flood-opacity="0.161"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
      <filter id="Path_27132" x="0" y="7.237" width="31.467" height="31.773" filterUnits="userSpaceOnUse">
        <feOffset dy="3"/>
        <feGaussianBlur stdDeviation="3" result="blur-2"/>
        <feFlood flood-opacity="0.161"/>
        <feComposite operator="in" in2="blur-2"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g id="Group_21210" data-name="Group 21210" transform="translate(9.501 6)">
      <g transform="matrix(1, 0, 0, 1, -9.5, -6)" filter="url(#Path_27131)">
        <path id="Path_27131-2" data-name="Path_27131" d="M9.355,1.061,8.494,5.182A1.334,1.334,0,0,1,7.7,6.14L3.818,7.766a1.333,1.333,0,0,0-.148,2.387l3.655,2.095a1.333,1.333,0,0,1,.666,1.047l.346,4.194a1.333,1.333,0,0,0,2.224.879l3.118-2.826a1.335,1.335,0,0,1,1.2-.31l4.1.968A1.333,1.333,0,0,0,20.5,14.357l-1.725-3.842a1.334,1.334,0,0,1,.077-1.239l2.187-3.6a1.333,1.333,0,0,0-1.283-2.017l-4.188.454a1.334,1.334,0,0,1-1.154-.456L11.67.464a1.333,1.333,0,0,0-2.315.6" transform="translate(8.8 6)" fill={fillColor || '#fff'}/>
      </g>
      <g transform="matrix(1, 0, 0, 1, -9.5, -6)" filter="url(#Path_27132)">
        <path id="Path_27132-2" data-name="Path_27132" d="M4,11.174l-.434,2.077a1.334,1.334,0,0,1-.791.957l-1.957.819a1.333,1.333,0,0,0-.149,2.387L2.512,18.47a1.334,1.334,0,0,1,.665,1.047l.175,2.115a1.334,1.334,0,0,0,2.224.878l1.572-1.425a1.333,1.333,0,0,1,1.2-.31l2.065.487a1.333,1.333,0,0,0,1.523-1.843l-.87-1.936a1.333,1.333,0,0,1,.077-1.238l1.1-1.814a1.334,1.334,0,0,0-1.283-2.018l-2.11.228A1.331,1.331,0,0,1,7.7,12.186L6.317,10.577a1.333,1.333,0,0,0-2.315.6" transform="translate(9.5 3.64)" fill={fillColor || '#fff'} stroke="#000" strokeWidth="1"/>
      </g>
    </g>
  </svg>
);

