import flexiblejourney from '../../../assets/images/hybrid/flexible-approach.png';
import flexiblejourney2 from '../../../assets/images/hybrid/flexible-approach@2x.png';
import interaction5 from '../../../assets/images/hybrid/interaction-combined.png';
import interaction5a from '../../../assets/images/hybrid/interaction-combined@2x.png';
import bghalf from '../../../assets/images/hybrid/bg-half.svg';
import turnconversation from '../../../assets/images/hybrid/turn-conversation.png';
import turnconversation2 from '../../../assets/images/hybrid/turn-conversation@2x.png';

export const FlexibleApproach = () => (
  <div className="bg-gray-1 lg:-mt-32">
    <div className="container mx-auto lg:mt-32 ">
      <div className="block lg:flex ">
        <div className="mt-16 pt-2 lg:ml-16 pl-1 ">
          <img
            className=""
            srcSet={`${flexiblejourney} 300w, ${flexiblejourney2} 1000w`}
            sizes="(max-width: 590px) 30vw, (max-width: 590px) 30vw, 508px"
            alt=""
            src={flexiblejourney2}
          />
        </div>

        <div className="block lg:flex pl-10 ml-2 mt-36 pt-2 ">
          <div className="">
            <p className="lg:text-xl3 font-extralight text-xl text-dark-blue">
              A flexible approach
            </p>

            <p className="mt-4 text-lg font-extralight text-dark-blue">
              Physically take the stage or go live from
              {' '}
              <br />
              {' '}
              anywhere in the world
            </p>
            <div className="mt-4">
              <p className="text-sm5  text-gray-1 text-left">
                *Load your slides into the platform and take over when it’s
                {' '}
                <br />
                your turn, your slides will automatically load and your phone
                {' '}
                <br />
                becomes your clicker.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="">
      <div
        className="w-full"
        style={{
          background: `url(${bghalf})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '1200px'
        }}
      >
        <div className="container mx-auto">
          <div className="mb-16">
            <div className="block lg:flex justify-between 2xl:justify-center">
              <div className="">
                <div className="items-start ml-16 pl-2 mt-40 pt-2 w-10/12">
                  <p className="lg:text-xl3 font-extralight text-xl text-dark-blue">
                    From interactions
                  </p>
                  <p className="lg:text-xl3 font-extralight text-xl text-dark-blue">
                    Into meaningful conversations
                  </p>
                  <p className="mt-4 text-lg font-extralight text-dark-blue">
                    A real-time interactive involvement for your attendees
                  </p>
                  <div className="mt-4">
                    <p className="text-sm5  text-gray-1 text-left">
                      *Enable your in-person and virtual attendees
                      {' '}
                      <br />
                      to interact with each other, speakers and
                      {' '}
                      <br />
                      sponsors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:-mt-16">
                <img
                  className="-ml-28"
                  srcSet={`${interaction5} 300w, ${interaction5a} 1000w`}
                  sizes="(max-width: 583px) 30vw, (max-width: 583px) 30vw, 610px"
                  alt=""
                  src={interaction5a}
                />
              </div>
            </div>
          </div>

          <div className="">
            <div className="block lg:flex ml-16 ">
              <div className="mt-18 pt-1">
                <img
                  className=""
                  srcSet={`${turnconversation} 300w, ${turnconversation2} 1000w`}
                  sizes="(max-width: 583px) 30vw, (max-width: 583px) 30vw, 500px"
                  alt=""
                  src={turnconversation2}
                />
              </div>

              <div className="block lg:flex flex-col">
                <div className="items-start ml-7  mt-24 pt-4 2xl:ml-16">
                  <p className="lg:text-xl3 font-extralight text-xl text-dark-blue">
                    Turn conversations
                    {' '}
                    <br />
                    into shared experiences
                  </p>

                  <p className="text-lg font-extralight text-dark-blue mt-4 ">
                    Let your attendees to be more than
                    {' '}
                    <br />
                    {' '}
                    just silent observers
                  </p>

                  <div className="mt-4">
                    <p className="text-md font-extralight text-gray-1 text-left">
                      Turn any session into an interactive roundtable
                      {' '}
                      <br />
                      instantly, use Panelist’s social lounge and all the
                      {' '}
                      <br />
                      tools available to you increase engagement and
                      {' '}
                      <br />
                      create a meaningful experiences for your
                      {' '}
                      <br />
                      attendees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FlexibleApproach;
