import { useEffect, useMemo, useState } from 'react';
import AlertModal from '../../components/alert-modal/alert-modal';
import { IPostSpotLight } from '../../models/post/IPostSpotlight';
import { IPost } from '../../models/post/post';
import { PostService } from '../../services';
import SearchIcon from '../../assets/images/event-landing-pre-event/Icon-feather-search.svg';
import { Button } from '../../shared-components/V2';

import SpotlightDetails from './spotlight-details';
import { Spotlight } from './spotlight';
import { useSearchParams } from 'react-router-dom';
import { DebounceInput } from '../../shared-components/DebounceInput';
import CreatePostModal from '../posts/create-post/create-post-modal';
import { useCurrentUser } from '../../utils/hooks';
import { imageUrlPrefix } from '../../utils';
import { Loader } from '../../shared-components/Loader';
import classNames from 'classnames';


interface ISpotlightEventsProps {
  getSpotlight: Function;
  fetchItems: Function;
  specificSpotlight: IPostSpotLight[];
  hasMoreItems: boolean;
  getSearchText: Function;
  disableCreate?: boolean;
  isCompanySpotlight?: boolean;
  company?: any;
  createButtonClass?: string;
  createButtonWidth?: string;
  isOwnSpotlight?: boolean;
}
export const SpotlightEvents = ({
  getSpotlight, fetchItems, specificSpotlight, hasMoreItems, getSearchText, disableCreate, isCompanySpotlight, company, createButtonClass, createButtonWidth, isOwnSpotlight
}: ISpotlightEventsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState('');
  const [showSpotlightEvent, setshowSpotlightEventEvent] = useState<IPost>();
  const [toggleModal, setToggleModal] = useState(false);

  const [_innerWidth, setInnerWidth] = useState(window.innerWidth);

  const loggedInUser = useCurrentUser();

  useEffect(() => {
    getSearchText(searchText);
    // eslint-disable-next-line
  }, [searchText]);

  const resetToDefaults = () => {
    getSpotlight();
    setToggleModal(!toggleModal);
  };

  const fetchSpotlight = async (id: string) => {
    const postService: PostService = new PostService();
    try {
      const selectedSpotlight = await postService.getPost(id);
      setshowSpotlightEventEvent(selectedSpotlight);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) fetchSpotlight(id);
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const spotlightGridSize = useMemo(() => {
    if (innerWidth <= 768) return 2
    return 4
  }, [innerWidth])

  const onCloseModal = () => setToggleModal(!toggleModal);

  return specificSpotlight ? (
    <div className="bg-gray-1 h-full w-full">
      <div className="lg:container lg:w-auto w-full mx-auto">
        <div className="md:flex flex-row justify-center">
          <div className="md:mb-8 w-full">
            <div className="flex sm:flex-row flex-col gap-y-2 items-center justify-center mb-4">
              {!disableCreate && <div 
                  className={classNames(
                    "flex flex-col sm:w-[286px] w-full sm:mr-2.5",
                    {
                      [`${createButtonWidth}`]: createButtonWidth,
                    }
                  )}
                >
                <Button
                  text="Create new content"
                  className={classNames(
                    "bg-[#0049EF] text-white !text-md2 !font-medium rounded-3xl h-[40px]",
                    {
                      [`${createButtonClass}`]: createButtonClass,
                    }
                  )}
                  type="button"
                  onClick={onCloseModal}
                />
                <AlertModal className="w-[500px]" onClose={onCloseModal} isOpen={toggleModal}>
                  <CreatePostModal
                    wallId={isCompanySpotlight ? company.id : loggedInUser.id}
                    location={isCompanySpotlight ? "company" : "profile"}
                    avatarUrl={isCompanySpotlight ? `${imageUrlPrefix}/${company.logo}` : `${imageUrlPrefix}/${loggedInUser.avatar}`}
                    userDisplayName={isCompanySpotlight ? company.name : loggedInUser.fullName}
                    onAfterSave={() => resetToDefaults()}
                    onClose={onCloseModal}
                    image
                    video
                    document
                    poll={false}
                    isSpotlightCreate
                    isCompanySpotlightCreate={isCompanySpotlight}
                  />
                </AlertModal>
              </div>
              }
              <div className='relative w-full'>
                <div className="absolute left-[14px] top-[11.5px]">
                  <img className="w-4 h-4" src={SearchIcon} alt="" />
                </div>
                <div className="bg-gray-1 w-full">
                  <DebounceInput
                    delay={400}
                    initValue=""
                    InputName="search"
                    type="text"
                    placeholder="Enter a keyword"
                    classNames="pl-[39px] py-1 w-full bg-white text-md font-extralight rounded-[5px] h-[40px] outline-none"
                    // eslint-disable-next-line no-console
                    onChangeText={(e: any) => setSearchText(e)}
                  />
                </div>
              </div>
            </div>
            <div className='pb-2'>
              <Spotlight
                spotlightLists={specificSpotlight}
                fetchSpotlight={fetchSpotlight}
                fetchItems={fetchItems}
                hasMoreItems={hasMoreItems}
                gridSize={spotlightGridSize}
                isOwnSpotlight={isOwnSpotlight}
                getSpotlight={getSpotlight}
                onCreateSpotlight={setToggleModal}
                isDisableCreate={disableCreate}
              />
            </div>
          </div>
        </div>
      </div>
      <AlertModal isOpen={!!showSpotlightEvent} onClose={() => setshowSpotlightEventEvent(undefined)} className="w-1200 md:w-1200 min-w-793 py-0 my-0 overflow-hidden">
        <SpotlightDetails isPostModal isSpotlightDetailModal showSpotlightEvent={showSpotlightEvent} getSpotlight={getSpotlight} fetchSpotlight={fetchSpotlight} />
      </AlertModal>
    </div>
  ) : <Loader isLoaderLogo className="h-[100vh]" key="loader" />;
};
