import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { VideoAndAudiencePanel } from './video-and-audience-panel';

const FullSizeBox = styled('div')({
  width: '100%',
  height: '100%',
});

type EventLiveVideoProps = {
  speakerRooms?: any
  setRoomID: (value: string) => void;
  isGridView?: boolean;
  calculateVideoGridHeight?:any
}


export const EventLiveVideo = ({ speakerRooms, setRoomID, isGridView ,calculateVideoGridHeight}: EventLiveVideoProps) => {
  const {
    event,
    booths,
    attendees,
    speakers,
    ads
  } = useSelector((state: IAppState) => ({ ...state.liveEvent, ...state.chime }));
  return (
    <FullSizeBox>
      <VideoAndAudiencePanel
        booths={booths || []}
        event={event!}
        speakers={speakers}
        attendees={attendees}
        setRoomID={setRoomID}
        isGridView={isGridView}
        calculateVideoGridHeight={calculateVideoGridHeight}
      />
    </FullSizeBox>
  )
};
