import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { IEvent } from '../../../models/user-events';
import { Loader } from '../../../shared-components/Loader';
import { Logo } from '../../../shared-components/V2/Logo';
import { IAppState } from '../../../store';
import EventRegistrationButton from '../registration/event-registration-button/event-registration-button';

interface IMenuItem {
  menuItemText: string;
  href: string;
  isSelected: boolean;
}

interface INavbarProps {
  menuItems: IMenuItem[];
  menuItemsVenue: IMenuItem[];
  // eslint-disable-next-line react/no-unused-prop-types
  showCreateEventButton: boolean;
}

export const WebsiteEventNavbar = (props: INavbarProps) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  return (
    <nav className="bg-white w-full z-40 relative h-[53px] flex items-center justify-center lg:px-0 px-[20px]">
      <div className="container max-w-[1317px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="flex items-center flex-row">
              <Link to="/">
                <Logo
                  src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event?.company?.logo}`}
                  className="w-[40px] h-[40px] object-contain post-company-logo p-1 !rounded-[7px]"
                />
              </Link>
              <div className="flex items-center pl-[10px] text-[15px] font-semibold leading-[25px] text-[#212121] line-clamp-1 md:max-w-[402px] max-w-[206px]">
                {event.name}
              </div>
            </div>
          </div>
          {event?.format === 'hybrid' ? (
            <div className="flex">
              <div className=" 2xl:visible xl:visible hidden lg:block md:hidden sm:hidden ">
                {props.menuItemsVenue
                  && props.menuItemsVenue.length > 0
                  && props.menuItemsVenue.map((menuItem, i) => (
                    <a
                      className={classNames(
                        'text-[14px] font-normal leading-4 text-[#3C3C3C] hover:underline',
                        {
                          'font-normal': menuItem.isSelected,
                          'mr-[38px]': i < props.menuItemsVenue.length - 1,
                          'mr-[40px]': i === props.menuItemsVenue.length - 1,
                          // hidden: event?.format === 'hybrid',
                        }
                      )}
                      href={menuItem.href}
                      key={`sub-nav-${i}`}
                    >
                      {menuItem.menuItemText}
                    </a>
                  ))}
              </div>
              {event ? (
                <EventRegistrationButton eventId={event.id} />
              ) : (
                <Loader className="pt-5" key="loader" />
              )}
            </div>
          ) : (
            <div className="flex">
              <div className=" 2xl:visible xl:visible hidden lg:block md:hidden sm:hidden ">
                {props.menuItems
                  && props.menuItems.length > 0
                  && props.menuItems.map((menuItem, i) => (
                    <a
                      className={classNames(
                        'text-[14px] font-normal leading-4 text-[#3C3C3C] hover:underline',
                        {
                          'font-normal': menuItem.isSelected,
                          'mr-[38px]': i < props.menuItems.length - 1,
                          'mr-[40px]': i === props.menuItems.length - 1,
                          // hidden: event?.format === 'hybrid',
                        }
                      )}
                      href={menuItem.href}
                      key={`sub-nav-${i}`}
                    >
                      {menuItem.menuItemText}
                    </a>
                  ))}
              </div>
              {event ? (
                <EventRegistrationButton eventId={event.id} />
              ) : (
                <Loader className="pt-5" key="loader" />
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};
