import { useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { IEditPost } from '../../../models/post/edit-post';
import { IEditPostProps } from '../../../models/post/edit-post-props';
import { Avatar } from '../../../shared-components/V2';
import { IAppState } from '../../../store';
import { getPoll } from '../../../store/poll';
import { imageUrlPrefix } from '../../../utils';
import { ConditionalLink } from '../../conditional-link';
import ViewPoll from '../../poll/view-poll';
import LockIcon from '../../../assets/images/post/lock.svg';
import connectionIcon from '../../../assets/images/event-landing-pre-event/connection.svg';
import publicIcon from '../../../assets/images/event-landing-pre-event/earth-globe.svg';
import lockIcon from '../../../assets/images/lock.svg';
import { PrivacyDropDown } from '../components/PrivacyOptions';
import SharedEvent from '../components/SharedEvent';
import { Loader } from '../../../shared-components/Loader';
import { UserService } from 'src/services';
import { useDebounce } from 'src/mui/hooks/use-debounce';
import { UserSearchResult } from 'src/store/user/search-user';
import classNames from 'classnames';
import { parseNotficationString } from 'src/utils/notification';

export const EditPost = (props: IEditPostProps) => {
  const { register, setValue, getValues, handleSubmit } = useForm<IEditPost>();
  const dispatch = useDispatch();
  const { poll } = useSelector((state: IAppState) => state);
  const [privacyOfPost, setPrivacyOfPost] = useState(() => {
    if (props.postedAsUser) {
      if (props.privacy === "only-me")
        return "Only me";
      else if (props.privacy === "connections") return "Connections";
      else if (props.privacy === "public") return "Public";
    } else if (props.postedAsCompany) {
      if (props.privacy === "followers") return "Followers";
      else if (props.privacy === "public") return "Public";
    }
  }
  );
  const [iconPrivacyOfPost, setIconPrivacyOfPost] = useState(() => {
    if (props.postedAsUser) {
      if (props.privacy === "only-me") return lockIcon;
      else if (props.privacy === "connections") return connectionIcon;
      else if (props.privacy === "public") return publicIcon;
    } else if (props.postedAsCompany) {
      if (props.privacy === "followers") return connectionIcon;
      else if (props.privacy === "public") return publicIcon;
    }
  });
  const [privacyOptionsFor] = useState(() => {
    if (props.location === "profile") {
      if (props.postedAsUser) return "User";
      else if (props.postedAsCompany) return "Company";
    } else {
      return "Company";
    }
  });
  const [displayPrivacyStatusMenu, setDisplayPrivacyStatusMenu] =
    useState(false);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>();
  const [inputValue, setInputValue] = useState("");

  const mentionRegex = /@\w+(?:\s+\w+)?/g;
  // const mentionRegex = /@(\w+)/g;  
  const contentMentionRegex = /@{{[^|]+?\|\|([^|]+?)\|\|([^}]+?)}}/g;
  const [mentionTags, setMentionTags] = useState<any>({});
  // const { connections } = useSelector((state: IAppState) => state);
  const userService: UserService = new UserService();
  const debouncedInputValue = useDebounce(inputValue, 500);

  const getUsers = async (searchUser: string) => {
    const limit = 10;
    const result: UserSearchResult = await userService.getSearchUserResults(
      `${searchUser ?? ""}`,
      limit
    );
    setSuggestions([...result?.data?.users?.data]);
    setShowSuggestions(true);
  };

  useEffect(() => {
    let match;
    while ((match = contentMentionRegex.exec(props.content)) !== null) {
      const matchId = match[1];
      const matchName = match[2];
      let newMentionTags = {
        [matchId]: matchName,
      };
      setMentionTags((prevState: any) => ({ ...prevState, ...newMentionTags }));
    }
   
    const htmlToString = (content: any) => {
      const cleanString = content?.replace(/<br\s*\/?>/gi, '\n');
      const cleanTags = cleanString?.replace(/<[^>]*>/g, '');
      const cleanSpaces = cleanTags.replace(/( |%20)/g, ' ');
      return cleanSpaces;
    }
 
    const getContent = htmlToString(props?.content);
    const parseComment = parseNotficationString(getContent, null, true, true, dispatch);
 
    setValue("content", parseComment.join(""));
  }, [setValue, props]);

  useEffect(() => {
    if (props.format === "poll") {
      dispatch(getPoll(props.source));
    }
  }, [props.format]);

  const handlePrivacyOfPost = (privacy: string, icon: string) => {
    setPrivacyOfPost(privacy);
    setIconPrivacyOfPost(icon);
    setDisplayPrivacyStatusMenu(!displayPrivacyStatusMenu);
  };

  const handlePostChange = (e: any) => {
    const newPost = e.target.value;
    setValue("content", newPost);

    const matches = newPost.match(mentionRegex);
    // const ownUserConnections = connections?.value?.data;
    if (matches) {
      // if (matches && ownUserConnections) {
      const mention = matches[0].substring(1);
      setInputValue(mention);
      // const filteredSuggestions = ownUserConnections.filter((user) =>
      //   user?.fullName.toLowerCase().includes(mention.toLowerCase())
      // );

      // setSuggestions(filteredSuggestions);
      // setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion: any) => {
    const currentPost = getValues("content");

    const mentionText = `${suggestion.firstName} ${suggestion.lastName}`;
    // const mentionText = `${suggestion.fullName}`;

    let newMentionTags = {
      [suggestion.id]: mentionText,
    };
    setMentionTags({ ...mentionTags, ...newMentionTags });
    const updatedPost = currentPost.replace(mentionRegex, mentionText);

    setValue("content", updatedPost);
    setShowSuggestions(false);
  };

  const onSubmit = async (data: IEditPost) => {
    setIsLoader(true);
    let newMessage = data.content;
    Object.keys(mentionTags).map((x: any) => {
      if (data.content.includes(mentionTags[x])) {
        const mentionText = `@{{User||${x}||${mentionTags[x]}}}`;
        newMessage = newMessage.replaceAll(mentionTags[x], mentionText);
      }
    });
    if (privacyOfPost === "Only me") data.privacy = "only-me";
    else if (privacyOfPost === "Connections") data.privacy = "connections";
    else if (privacyOfPost === "Public") data.privacy = "public";
    else if (privacyOfPost === "Followers") data.privacy = "followers";
    props.onEditPost &&
      (await props.onEditPost(props.postId, newMessage, data.privacy!));
  };

  useMemo(() => {
    if (debouncedInputValue) {
      getUsers(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  return (
    <div className="w-full relative">
      {isLoader && (
        <div className="absolute left-0 top-0 w-full h-[calc(100%--26px)] bg-gray-1 bg-opacity-80 flex items-center justify-center z-20 rounded-20 -mt-[14px]">
          <Loader className="pt-5" key="loader" />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-center text-[15px] leading-[23px] text-dark-blue font-medium mb-3 px-2">
          Edit post
        </div>
        <div className="w-full">
          <div className="border-b-[0.5px] border-[#C5C5C5]" />
        </div>
        <div className="flex flex-row items-center py-2.5 pb-0 px-[20px]">
          <div className="mr-[12.5px] !w-[40px] !h-[40px]">
            <ConditionalLink to={props.detailsHref}>
              <Avatar
                alt=""
                src={props.avatarUrl}
                size="MEDIUM"
                className="!object-contain !w-[40px] !h-[40px] !rounded-[15px]"
              />
            </ConditionalLink>
          </div>
          <div className="menu focus:outline-none focus:shadow-solid flex flex-row border-[#7283A4] border-1 sm:h-[31px] sm:py-0 py-[5.5px] sm:px-[20px] px-3 hover:bg-[#DFE5EB] justify-between items-center text-center rounded-[5px] text-[12px] text-[#7283A4] font-semibold bg-white line-clamp-1 leading-[30px]">
            {props.userDisplayName}
          </div>
          <div className="ml-2">
            <PrivacyDropDown
              displayPrivacyStatusMenu={displayPrivacyStatusMenu}
              setDisplayPrivacyStatusMenu={setDisplayPrivacyStatusMenu}
              iconPrivacyOfPost={iconPrivacyOfPost}
              privacyOfPost={privacyOfPost}
              privacyOptionsFor={privacyOptionsFor}
              handlePrivacyOfPost={handlePrivacyOfPost}
            />
          </div>
        </div>
        <div className="mt-[15px] px-[20px]">
          <textarea
            className={classNames('w-full text-[15px] leading-[17px] font-light outline-none placeholder:text-[#020202]', {
              'min-h-[200px]': props.format === "text",
            })}
            placeholder="Type here..."
            {...register("content")}
            onChange={handlePostChange} // Handle changes
          />
          {showSuggestions && suggestions?.length! > 0 && (
            <ul className="w-full md:max-w-[350px] bg-white z-20 origin-top-left absolute card-shadow py-[10px] px-2.5 border-b-1 border-gray-200 max-h-[220px] overflow-y-scroll top-[200px]">
              {suggestions?.map((suggestion, index) => (
                <li
                  key={suggestion.id}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <div
                    className="h-[37px] flex items-center hover:bg-[#EDF2F5] m-[6px] rounded-10 cursor-pointer"
                    key={index}
                  >
                    <Avatar
                      src={`${process.env.REACT_APP_IMAGE_URL_PREFIX
                        }/${suggestion?.avatar!}`}
                      size="XSMALL"
                    />
                    <div className="flex items-center w-[430px] ml-[11px] tracking-wide truncate">
                      <div className="inline-block text-[15px] leading-5 font-semibold text-[#172746]">
                        {suggestion?.firstName} {suggestion?.lastName}
                      </div>
                      <div className="ml-1 text-[13px] font-light leading-[15px] text-[#18161C]">
                        • {suggestion?.company?.name}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div
          className={classNames('mt-2 block px-[20px] max-h-[300px] overflow-scroll', {
            '!px-[0px]': props.format === "poll",
          })}
        >
          <div
            className={classNames('w-full mx-auto bg-gray-2 rounded-10 min-height overflow-hidden relative border-[0.5px] border-[#989898]', {
              '!bg-transparent border-none': props.format === "poll",
            })}
          >
            {props.format === "image" && (
              <img
                src={`${imageUrlPrefix}/${props.source}`}
                className="w-full max-h-400"
                alt=""
              />
            )}
            {props.format === "video" && (
              <video controls>
                <source
                  src={`${imageUrlPrefix}/${props.source}`}
                  type="video/mp4"
                />
              </video>
            )}
            {props.format === "document" && (
              <div className="w-full h-full bg-cover h-506">
                <iframe
                  title={props.postId}
                  className="bg-cover w-full h-506"
                  src={`${imageUrlPrefix}/${props.source}`}
                />
              </div>
            )}
            {props.format === "poll" && (
              <ViewPoll
                id={poll.value?.data?.id!}
                question={poll.value?.data?.question!}
                status={poll.value?.data?.status!}
                expireOption={poll.value?.data?.expireOption!}
                expiredAt={poll.value?.data?.expiredAt!}
                answers={poll.value?.data?.answers!}
                isInteractive={false}
              />
            )}
            {props.format === "event" && !!props.event && (
              <SharedEvent
                slug={props.event?.slug!}
                cover={props.event?.cover!}
                logo={props.event?.company?.logo!}
                name={props.event?.name!}
                companyName={props.event?.name!}
                startTime={props.event?.startTime!}
                endTime={props.event?.endTime!}
                eventFormat={props.event?.format!}
                eventRegions={props.event?.eventRegions}
              />
            )}
            {props.format === "shared_event" && !!props.shareEvent && (
              <SharedEvent
                slug={props.shareEvent?.slug!}
                cover={props.shareEvent?.cover!}
                logo={props.shareEvent?.company?.logo!}
                name={props.shareEvent?.name!}
                companyName={props.shareEvent?.name!}
                startTime={props.shareEvent?.startTime!}
                endTime={props.shareEvent?.endTime!}
                eventFormat={props.shareEvent?.format!}
                eventRegions={props.shareEvent?.eventRegions}
              />
            )}
            {props.format === "shared_event" &&
              !props.sharePost &&
              !props.shareEvent && (
                <div className="m-4 bg-gray-1 rounded-lg p-4 flex flex-row gap-2">
                  <div className="pt-1">
                    <img src={LockIcon} />
                  </div>
                  <div>
                    <div className="text-sm font-semibold">
                      This content isn’t available at the moment
                    </div>
                    <div className="text-xs font-normal">
                      When this happens, it’s usually because the owner only
                      shared it with a small group of people or changed who can
                      see it, or it’s been deleted.
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="text-right mt-2 px-[20px]">
          <div className="border-b-[0.5px] border-[#C5C5C5] mb-2.5" />
          <button
            className="rounded-[5px] inline-block font-[600] bg-[#EDF2F5] text-[#203C6E] hover:text-white text-sm w-full h-[31px] hover:bg-blue-gray-2 tracking-wide px-4"
            type="submit"
          >
            Save post
          </button>
        </div>
      </form>
    </div>
  );
};
