import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { CheckEmailModel } from '../../models/check-email';
import { CheckUserEmailResult } from '../../models/CheckUserEmailResult';
import { CheckUserEmailState } from '../../models/CheckUserEmailState';
import { UserService } from '../../services';

const initialState: CheckUserEmailState = {
  value: null,
  loading: false,
  errorMessage: '',
};

export const checkUserEmailSlice = createSlice({
  name: 'checkUserEmail',
  initialState,
  reducers: {
    checkUserEmailLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    checkUserEmailSuccess: (
      state,
      action: PayloadAction<CheckUserEmailResult>
    ) => {
      state.loading = false;
      state.value = action.payload;
    },
    checkUserEmailError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.value = null;
      state.errorMessage = action.payload;
    },
    resetUserEmail: () => {
      return initialState;
    },
  },
});

export const {
  checkUserEmailError,
  checkUserEmailSuccess,
  checkUserEmailLoading,
  resetUserEmail,
} = checkUserEmailSlice.actions;

export const checkUserEmail =
  (data: CheckEmailModel) => async (dispatch: Dispatch) => {
    try {
      dispatch(checkUserEmailLoading(true));
      const userService: UserService = new UserService();
      const result = await userService.checkEmail(data);
      dispatch(checkUserEmailSuccess(result));
    } catch (error: any) {
      console.error(error);
      if (error.statusCode === 500)
        dispatch(
          checkUserEmailError('Something went wrong. Please try again!')
        );
      else dispatch(checkUserEmailError(error));
    }
  };
