import { Active4CardsHalfHeight, CardActiveBoothHalfHeight, CardActiveBothFullHeight, CardFullHeight, CardHalfHeight, VideoActiveBothHalfHeight } from 'src/mui/pages/event/live/Constants';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';
import { position } from 'stylis';
export type ModuleDesignType = 'box' | 'lines' | string;
export interface Session {
  startTime: string;
  endTime: string;
}

export interface Room {
  sessions: Session[];
}

export interface ILiveViewProps {
  isHeaderShow: boolean;
}

export const calculateGridColSize = (activeList: any[], isActiveBooth: boolean, index: number) => {
  let colSizeIndex1 = "1/span 4";
  let colSizeIndex2 = null;
  let colSizeIndex3 = null;
  let colSizeIndex4 = null;
  let colSizeIndex5 = null;
  let colSizeIndex6 = null;
  let colSizeIndex7 = null;
  let colSizeIndex8 = null;

  if (activeList?.length === 1) {
    colSizeIndex1 = "1/span 4";
  }
  if (activeList?.length === 2) {
    colSizeIndex1 = "1/span 3";
    colSizeIndex2 = "4";
  }


  if (activeList?.length === 3) {
    colSizeIndex1 = "1/span 2";
    colSizeIndex2 = "3";
    colSizeIndex3 = "4";
  }

  if (activeList?.length === 3 && activeList?.filter(item => item !== "").length === 2) {
    colSizeIndex1 = "1/span 3";
    colSizeIndex2 = "4";
  }

  if (activeList?.length === 4) {
    colSizeIndex1 = "1/span 2";
    colSizeIndex2 = "3";
    colSizeIndex3 = "4";
    colSizeIndex4 = "1/span 2";
  }

  if (activeList?.length === 4 && activeList?.filter(item => item !== "").length === 2) {
    console.log("index inside::")
    colSizeIndex1 = "1/span 4";
    colSizeIndex2 = "2";
    colSizeIndex3 = "2";
    colSizeIndex4 = "1/span 4";
  }
  // if (activeList?.length === 4 && activeList?.filter(item => item !== "").length === 3) {
  //   console.log("index inside::")
  //   colSizeIndex1 = "1/span 3";
  //   colSizeIndex2 = "3";
  //   colSizeIndex3 = "4";
  //   colSizeIndex4 = "1/span 3";
  // }

  if (activeList?.length === 4 && activeList?.filter(item => item !== "").length === 3) {
    console.log("index inside::4")
    colSizeIndex1 = "1/span 3";
    colSizeIndex2 = "4";
    colSizeIndex3 = "4";
    colSizeIndex4 = "1/span 3";
  }

  if (activeList?.length === 5 && !isActiveBooth) {
    colSizeIndex1 = "1/span 2";
    colSizeIndex2 = "3";
    colSizeIndex3 = "4";
    colSizeIndex4 = "1/span 1";
    colSizeIndex5 = "2";
  }

  if (activeList?.length === 5 && isActiveBooth) {
    colSizeIndex1 = "1/span 2";
    colSizeIndex2 = "3";
    colSizeIndex3 = "4";
    colSizeIndex4 = "1/span 2";
    colSizeIndex5 = "3";
  }

  if (activeList?.length === 5 && activeList?.filter(item => item !== "").length === 2) {
    colSizeIndex1 = "1/span 4";
    colSizeIndex2 = "2";
    colSizeIndex3 = "2";
    colSizeIndex4 = "2";
    colSizeIndex5 = "1/span 4";
  }
  if (activeList?.length === 5 && activeList?.filter(item => item !== "").length === 3) {
    colSizeIndex1 = "1/span 3";
    colSizeIndex2 = "3";
    colSizeIndex3 = "4";
    colSizeIndex4 = "4";
    colSizeIndex5 = "1/span 3";
  }

  if (activeList?.length === 5 && activeList?.filter(item => item !== "").length === 4) {
    colSizeIndex1 = "1/span 2";
    colSizeIndex2 = "3";
    colSizeIndex3 = "4";
    colSizeIndex4 = "4";
    colSizeIndex5 = "1/span 2";
  }

  if (activeList?.length === 6) {
    colSizeIndex1 = "1/span 2";
    colSizeIndex2 = "3";
    colSizeIndex3 = "4";
    colSizeIndex4 = "1/span 1";
    colSizeIndex5 = "2";
    colSizeIndex6 = "3";
  }

  if (activeList?.length === 7) {
    colSizeIndex1 = "1/span 2";
    colSizeIndex2 = "3";
    colSizeIndex3 = "4";
    colSizeIndex4 = "1/span 1";
    colSizeIndex5 = "2";
    colSizeIndex6 = "3";
    colSizeIndex7 = "4";
  }
// // console.log("index:: ", colSizeIndex1,
//     colSizeIndex2,
//     colSizeIndex3,
//     colSizeIndex4,
//     colSizeIndex5,
//     colSizeIndex6,
//     colSizeIndex7,
//     colSizeIndex8)
  return {
    colSizeIndex1,
    colSizeIndex2,
    colSizeIndex3,
    colSizeIndex4,
    colSizeIndex5,
    colSizeIndex6,
    colSizeIndex7,
    colSizeIndex8,
  };
};

export const calculateGridRowSize = (activeList: any[], index: number) => {
  let rowSizeIndex1 = "1/span 4";
  let rowSizeIndex2 = null;
  let rowSizeIndex3 = null;
  let rowSizeIndex4 = null;
  let rowSizeIndex5 = null;
  let rowSizeIndex6 = null;
  let rowSizeIndex7 = null;
  let rowSizeIndex8 = null;

  if (activeList?.length === 1) {
    rowSizeIndex1 = "1/span 4";
  }
  if (activeList?.length === 2) {
    rowSizeIndex1 = "1/span 3";
    rowSizeIndex2 = "1/span 2";
  }

  if (activeList?.length === 3) {
    rowSizeIndex1 = "1/span 2";
    rowSizeIndex2 = "1/span 2";
    rowSizeIndex3 = "1/span 2";
  }

  if (activeList?.length === 4) {
    rowSizeIndex1 = "1/span 1";
    rowSizeIndex2 = "1/span 2";
    rowSizeIndex3 = "1/span 2";
    rowSizeIndex4 = "2/span 1";
  }

  if (activeList?.length === 5) {
    rowSizeIndex1 = "1/span 1";
    rowSizeIndex2 = "1/span 2";
    rowSizeIndex3 = "1/span 2";
    rowSizeIndex4 = "2/span 1";
    rowSizeIndex5 = "2/span 1";
  }

  if (activeList?.length === 6) {
    rowSizeIndex1 = "1/span 1";
    rowSizeIndex2 = "1/span 1";
    rowSizeIndex3 = "1/span 2";
    rowSizeIndex4 = "2/span 1";
    rowSizeIndex5 = "2/span 1";
    rowSizeIndex6 = "2/span 1";
  }

  if (activeList?.length === 7) {
    rowSizeIndex1 = "1/span 1";
    rowSizeIndex2 = "1/span 1";
    rowSizeIndex3 = "1/span 1";
    rowSizeIndex4 = "2/span 1";
    rowSizeIndex5 = "2/span 1";
    rowSizeIndex6 = "2/span 1";
    rowSizeIndex7 = "2/span 1";
  }

  return {
    rowSizeIndex1,
    rowSizeIndex2,
    rowSizeIndex3,
    rowSizeIndex4,
    rowSizeIndex5,
    rowSizeIndex6,
    rowSizeIndex7,
    rowSizeIndex8,
  };
};

export const calculateGridHeight = (activeList: any[], isActiveBooth: boolean, isCallActive: any, index: number) => {
    let gridHeightIndex1 = CardFullHeight;
    let gridHeightIndex2 = null;
    let gridHeightIndex3 = null;
    let gridHeightIndex4 = null;
    let gridHeightIndex5 = null;
    let gridHeightIndex6 = null;
    let gridHeightIndex7 = null;
    let gridHeightIndex8 = null;

    const fullHeight = isActiveBooth
      ? CardActiveBothFullHeight
      :  isCallActive ? CardActiveBothFullHeight : CardFullHeight;
    const halfHeight = isActiveBooth
      ? index === 0
        ? VideoActiveBothHalfHeight
        : CardActiveBoothHalfHeight
      : isCallActive ? 'calc(50vh - 10px)' : CardHalfHeight;
    const moreThen4CardshalfHeight = isActiveBooth
      ? CardActiveBoothHalfHeight
      : isCallActive ? 'calc(50vh - 92px)' : Active4CardsHalfHeight;

    if (activeList?.length === 1) {
      gridHeightIndex1 = fullHeight;
    }
    if (activeList?.length === 2) {
      gridHeightIndex1 = fullHeight;
      gridHeightIndex2 = fullHeight;
    }

    if (activeList?.length === 3) {
      gridHeightIndex1 = fullHeight;
      gridHeightIndex2 = fullHeight;
      gridHeightIndex3 = fullHeight;
    }

    if (activeList?.length === 4) {
      gridHeightIndex1 = halfHeight;
      gridHeightIndex2 = fullHeight;
      gridHeightIndex3 = fullHeight;
      gridHeightIndex4 = moreThen4CardshalfHeight;
    }

    if (activeList?.length === 5 && isActiveBooth) {
      gridHeightIndex1 = halfHeight;
      gridHeightIndex2 = 'calc(50vh - 10px)';
      gridHeightIndex3 = fullHeight;
      gridHeightIndex4 = moreThen4CardshalfHeight;
      gridHeightIndex5 = moreThen4CardshalfHeight;
    }

    if (activeList?.length === 5 && !isActiveBooth) {
      gridHeightIndex1 = halfHeight;
      gridHeightIndex2 = fullHeight;
      gridHeightIndex3 = fullHeight;
      gridHeightIndex4 = moreThen4CardshalfHeight;
      gridHeightIndex5 = moreThen4CardshalfHeight;
    }

    if (activeList?.length === 6) {
      gridHeightIndex1 = halfHeight;
      gridHeightIndex2 = halfHeight;
      gridHeightIndex3 = fullHeight;
      gridHeightIndex4 = moreThen4CardshalfHeight;
      gridHeightIndex5 = moreThen4CardshalfHeight;
      gridHeightIndex6 = moreThen4CardshalfHeight;
    }

    if (activeList?.length === 7) {
      gridHeightIndex1 = halfHeight;
      gridHeightIndex2 = halfHeight;
      gridHeightIndex3 = halfHeight;
      gridHeightIndex4 = moreThen4CardshalfHeight;
      gridHeightIndex5 = moreThen4CardshalfHeight;
      gridHeightIndex6 = moreThen4CardshalfHeight;
      gridHeightIndex7 = moreThen4CardshalfHeight;
    }

    return {
      gridHeightIndex1,
      gridHeightIndex2,
      gridHeightIndex3,
      gridHeightIndex4,
      gridHeightIndex5,
      gridHeightIndex6,
      gridHeightIndex7,
      gridHeightIndex8,
    };
  };

export const getColumnCardHeight = (activeList: any[] | undefined, index: number): string => {
  const length = activeList?.length || 0; // Default to 0 if activeList is undefined
  if ((length === 4 || length === 5 || length === 6) && (index === 1 || index === 2)) {
    return "full-height";
  }
  return length > 3 ? "half-height" : "full-height";
};

// export const moduleLineBox = {
//   position: 'relative',
//   "&:after": {
//     content: '""',
//     width: '1px',
//     height: 'calc(100% - 0px)',
//     position: 'absolute',
//     left: '-6px',
//     top: '0px',
//     backgroundColor: 'red',
//     maxHeight: 'calc(100% - 12px)'
//   },
// };

export const getBoxStyles = (
  isModuleDesignLines: boolean,
  brandingColors: { Main?: string } | undefined,
  paddingBottom: string = "0px"
) => {
  return {
    backgroundColor: isModuleDesignLines ? "transparent" : (brandingColors?.Main || "#000000"),
    borderRadius: isModuleDesignLines ? "0px" : "10px",
    overflow: "hidden",
    px: "10px",
    py: "12px",
    pb: paddingBottom,
    position: "relative",
    borderLeft: isModuleDesignLines ? `1px solid ${getBrandingColors().Text}` : 'none',
  };
};

export const isModuleDesign = (
  getBranding: () => { moduleDesign?: string } | undefined,
  targetDesign: string = 'lines',
  fallbackDesign: string = 'box'
): boolean => {
  const moduleDesign = getBranding()?.moduleDesign || fallbackDesign;
  return moduleDesign === targetDesign;
};

export const getEventSessions = (rooms: Room[] = []): Session[] => {
  const sessionsList: Session[] = [];
  rooms.forEach((room) => {
    room.sessions.forEach((session) => {
      sessionsList.push({
        startTime: session.startTime,
        endTime: session.endTime,
      });
    });
  });
  return sessionsList;
};

const isPastEvent = (date: string) => new Date(date) < new Date();
const isLiveEvent = (startDate: string, endDate: string) =>
  +new Date(startDate) - +new Date() <= 0 && new Date(endDate) > new Date();
const isFutureEvent = (date: string) => +new Date(date) - +new Date() > 0;

// Common function to determine event status
export const getEventStatus = (
  startDate: string | undefined,
  endDate: string | undefined,
  checkType: 'live' | 'future' | 'past'
) => {
  // Return false if start or end date is undefined
  if (!startDate || !endDate) {
    return false;
  }

  switch (checkType) {
    case 'live':
      return isLiveEvent(startDate, endDate);
    case 'future':
      return isFutureEvent(startDate);
    case 'past':
      return isPastEvent(endDate);
    default:
      return false;
  }
};
