import { useMemo } from "react";
import "./MobileToolBar.css";
import {
  Box,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { NavbarProps } from "./ConstantsandTypes";
import useStyling from "src/mui/components/hook-form/useStyling";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import useEventIsPassed from "../../survey/useEventIsPassed";
import { IAppState } from "src/store";
import { useSelector } from "react-redux";
import { IEvent } from "src/models/event/event";
import { EventQuestionPagePaginationTypes } from "../../survey/enum";

function PageStepper({
  pageItems,
  stepName,
  isLoading,
  onPreviousStep,
  onNextStep,
  setOpenForm,
  showBackButton,
  showNextButton,
  requiredFieldsFilled,
  surveySubPage,
  bgColor,
  step,
  currentPage,
  stepHeader,
}: NavbarProps) {
  const {
    secondaryFontColor,
    primaryBackgroundColor,
    focusColor,
    secondaryBackgroundColor,
    headerColor,
  } = useStyling(null, null);
  const { isLevnovoWebsiteTemplate, totalPages } = useEventIsPassed();

  const { upcomingEvent, signupUser } = useSelector(
    (state: IAppState) => state
  );
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);

  const showPagination = event?.eventQuestionPage?.pagination === EventQuestionPagePaginationTypes.SHOW;

  const isRegistered = upcomingEvent?.value?.data?.attendeeStatus?.isRegistered;

  const getStepandPageName = useMemo(() => {
    if (currentPage === surveySubPage) {
      const registrationPages = totalPages.filter(
        (page: any) => page?.type === "registration"
      );

      const surveyText = `${
        (registrationPages?.length || 0) + pageItems?.currentStep + 1
      }/${totalPages?.length} Survey`;
      return surveyText;
    } else if (currentPage === "registrationForm") {
      const registrationText = `${pageItems?.currentStep + 1}/${
        totalPages?.length
      } REGISTRATION`;
      return registrationText;
    } else if (currentPage === "surveyForm") {
      if (surveySubPage === "attendeesListView")
        return `${totalPages?.length}/${totalPages?.length} Attendees & Networking`;
    } else if (currentPage === "underReview") {
      return null;
    } else if (currentPage === "signupRegistrationForm") {
      return "Create your profile";
    }
    return stepHeader;
  }, [
    currentPage,
    surveySubPage,
    pageItems?.currentStep,
    totalPages,
    stepHeader,
  ]);

  const getActivePage = useMemo(() => {
    const registrationPages = totalPages.filter(
      (page: any) => page?.type === "registration"
    );
    const surveyPages = totalPages.filter(
      (page: any) => page?.type === "survey"
    );

    if (currentPage === surveySubPage) {
      return (registrationPages?.length || 0) + pageItems?.currentStep + 1;
    } else if (currentPage === "registrationForm") {
      return pageItems?.currentStep + 1;
    } else if (currentPage === "surveyForm") {
      if (
        surveySubPage === "attendeesListView" ||
        surveySubPage === "meetingListView"
      )
        return (
          (registrationPages?.length || 0) + 1 + (surveyPages?.length || 0)
        );
    }

    return pageItems?.currentStep || 0;
  }, [
    currentPage,
    surveySubPage,
    pageItems?.currentStep,
    totalPages,
    stepHeader,
    isRegistered,
    signupUser,
  ]);

  const showStepper = useMemo(() => {
    if (showPagination) {
      if (currentPage === "surveyForm" || currentPage === "registrationForm") {
        return true;
      }

      return false;
    }
    return false;
  }, [currentPage,showPagination]);

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
    bgColor: any;
  }>(({ theme, ownerState, bgColor }) => ({
    zIndex: 1,
    width: 14,
    height: 14,
    minWidth: 14,
    minHeight: 14,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    border: `2px solid ${bgColor}`,
    ...(ownerState.active && {
      backgroundColor: "transparent",
      border: `2px solid ${bgColor}`,
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundColor: bgColor,
      border: `2px solid ${bgColor}`,
    }),
  }));

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 14,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: focusColor,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: pageItems?.bgColor || primaryBackgroundColor,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        bgColor={pageItems?.bgColor || primaryBackgroundColor}
        className={className}
      >
        {/* {icons[String(props.icon)]} */}
      </ColorlibStepIconRoot>
    );
  }

  const background = pageItems?.headerColor || headerColor;

  return (
    <nav
      className="stepper-navbar"
      style={{
        backgroundColor: background,
        height: "40px",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", position: "relative" }}
      >
      {showPagination &&  <Typography
          variant="h6"
          noWrap
          sx={{
            maxWidth: "90%",
            color: pageItems?.bgColor || primaryBackgroundColor,
            fontSize: "12px !important",
            textTransform: "uppercase",
            paddingLeft: "24.6px",
            fontFamily: "Poppins-400",
            lineHeight: "23px",
            fontWeight: 400,
          }}
        >
          {getStepandPageName}
        </Typography>
}
        {totalPages?.length > 1 && showStepper ? (
          <Box
            sx={{
              width: totalPages?.length > 10 ? "70%" : "30%",
              paddingRight: "50px",
              right: 25,
              display: {
                xs: 'none',  
                sm: 'block', 
              }
            }}
          >
            <Stepper
              alternativeLabel
              activeStep={getActivePage}
              connector={<ColorlibConnector />}
              sx={{
                "& .MuiStepper-root": { alignItems: "center" },
                "& .MuiStep-root": { paddingX: "0px !important" },
                "& .MuiStepConnector-root": { top: "7px !important" },
                "& .MuiStepLabel-iconContainer": {
                  width: "12px !important",
                  height: "12px !important",
                },
                "& .css-div5js": {
                  width: "12px !important",
                  height: "12px !important",
                  backgroundColor: isLevnovoWebsiteTemplate
                    ? "#00FE63"
                    : "#00ffcd",
                },
                "& .css-1tqfnoz": {
                  width: "12px !important",
                  height: "12px !important",
                },
                "& .css-vppmk1": {
                  width: "12px !important",
                  height: "12px !important",
                },
              }}
            >
              {totalPages?.map((label: any) => (
                <Step key={label?.id}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {""}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        ) : null}
      </Stack>
    </nav>
  );
}

export default PageStepper;
