import { useState } from "react"

export interface IUserDropdownSvgIcon {
  className?: string;
}
export const UserDropdownSvgIcon = ({ className }: IUserDropdownSvgIcon) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const colorBlue = '#3062B5';
    const defaultColor = '#7283A4';

    return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"
    onMouseEnter={() => setIsHover(true)}
    onMouseLeave={() => setIsHover(false)}
  >
    <g id="Group_19485" data-name="Group 19485" transform="translate(-24.094 -16.047)">
      <g id="Ellipse_1029" data-name="Ellipse 1029" transform="translate(24.094 16.047)" fill="#edf2f5" stroke="#fff" strokeWidth="1">
        <circle cx="8.5" cy="8.5" r="8.5" stroke="none"/>
        <circle cx="8.5" cy="8.5" r="8" fill="none"/>
      </g>
      <g id="Arrow" transform="translate(26.094 22.038)">
        <path id="Polygon_9" data-name="Polygon 9" d="M5.767.789a1,1,0,0,1,1.466,0L11.44,5.32A1,1,0,0,1,10.707,7H2.293A1,1,0,0,1,1.56,5.32Z" transform="translate(13 7) rotate(180)" fill={isHover ? colorBlue : defaultColor}/>
      </g>
    </g>
  </svg>
};
