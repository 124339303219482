import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IRegionResult } from '../../models/library-content/RegionResult';
import { IRegionState } from '../../models/library-content/RegionState';
import { EventsService } from '../../services/events';
import { ContentService } from '../../services/library-content';
import { ContentRegionActionTypes } from './types';

export interface IRegionLoadingAction {
  type: ContentRegionActionTypes.REGIONS_LOADING;
  loading: boolean;
}

export interface IRegionSuccessAction {
  type: ContentRegionActionTypes.REGIONS_SUCCESS;
  data: IRegionResult;
}

export interface IRegionErrorAction {
  type: ContentRegionActionTypes.REGIONS_ERROR;
  errorMessage: string;
}

export type RegionActions = IRegionLoadingAction | IRegionSuccessAction | IRegionErrorAction;

export const getRegions: ActionCreator<
  ThunkAction<Promise<any>, IRegionState, null, IRegionSuccessAction>
> = () => {
  return async (dispatch: Dispatch) => {
    const contentService: ContentService = new ContentService();

    try {
      const result = await contentService.getRegions();

      dispatch({
        type: ContentRegionActionTypes.REGIONS_SUCCESS,
        data: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: ContentRegionActionTypes.REGIONS_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: ContentRegionActionTypes.REGIONS_LOADING,
        loading: false
      });
    }
  };
};

export const getEventRegions: ActionCreator<
  ThunkAction<Promise<any>, IRegionState, null, IRegionSuccessAction>
> = (search: string = '', limit: number) => {
  return async (dispatch: Dispatch) => {
    const eventSerivce = new EventsService();

    try {
      const result = await eventSerivce.getEventRegions(search, limit);

      const resultData: any[] = [];

      result.data.map((region) =>
        resultData.push({
          id: region.region,
          name: region.region
        })
      );

      dispatch({
        type: ContentRegionActionTypes.REGIONS_SUCCESS,
        data: { data: resultData }
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: ContentRegionActionTypes.REGIONS_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: ContentRegionActionTypes.REGIONS_LOADING,
        loading: false
      });
    }
  };
};

export const loadingRegions: ActionCreator<
  ThunkAction<any, IRegionState, null, IRegionLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: ContentRegionActionTypes.REGIONS_LOADING,
    loading: shouldLoad
  });
