import React from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface IChatSvgIcon {
  isActive?: boolean;
}

export const ChatSvgIconActive = ({ isActive }: IChatSvgIcon) => {
    const colors = getBrandingColors();
    const defaultColor = '#66686c';
    const iconColor = isActive ? (colors?.Accent || '#03FFCD') : (colors?.Accent || defaultColor);

    return isActive ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 24 24"
            width="27"
            height="27"
        >
            <path
                d="M8.7,18H3c-1.493,0-3-1.134-3-3.666V9.294A9.418,9.418,0,0,1,8.349.023a9,9,0,0,1,9.628,9.628A9.419,9.419,0,0,1,8.7,18ZM20,9.08h-.012c0,.237,0,.474-.012.712C19.59,15.2,14.647,19.778,9.084,19.981l0,.015A8,8,0,0,0,16,24h5a3,3,0,0,0,3-3V16A8,8,0,0,0,20,9.08Z"
                fill={iconColor}
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.816" height="27.818" viewBox="0 0 27.816 27.818">
  <path id="Subtraction_1" data-name="Subtraction 1" d="M25.027,28.319h-5.9a9.219,9.219,0,0,1-7.727-4.23,13.212,13.212,0,0,0,2.11-.257,7.3,7.3,0,0,0,5.616,2.626h5.9a1.432,1.432,0,0,0,1.43-1.43v-5.9a7.3,7.3,0,0,0-2.63-5.615,13.321,13.321,0,0,0,.262-2.11,9.218,9.218,0,0,1,4.228,7.726v5.9a3.294,3.294,0,0,1-3.289,3.29Zm-14.513-7.08H3.79c-1.637,0-3.29-1.26-3.29-4.075V11.219A10.877,10.877,0,0,1,10.124.527Q10.5.5,10.87.5A10.369,10.369,0,0,1,21.212,11.615a10.878,10.878,0,0,1-10.7,9.624Zm.341-18.878c-.2,0-.406.007-.606.022h-.012A8.948,8.948,0,0,0,2.36,11.217v5.946c0,1.387.535,2.216,1.43,2.216h6.728A8.95,8.95,0,0,0,19.357,11.5v-.012a8.467,8.467,0,0,0-2.075-6.21h.031l-.428-.428a8.468,8.468,0,0,0-6.024-2.493Z" transform="translate(-0.5 -0.501)"     fill={iconColor}/>
</svg>
    );
};




