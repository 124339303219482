import classNames from 'classnames';
import { forwardRef, useEffect, useState } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { IReactSelectOption } from '../../../models/react-select-option';
import { SelectDropdownComponent } from '../SelectDropdownComponent';
import { BulletCrossSvgIcon } from '../IconSvg';
import { Avatar } from '../Avatar';

import companyDefault from '../../../assets/images/company-default.svg';

interface IMultiSelectCustomDropdownProps {
  options: IReactSelectOption[] | undefined;
  isClearable?: boolean;
  isMulti: boolean;
  openMenuOnClick?: boolean;
  createOptionPosition?: "first" | "last";
  selectClassName?: string;
  createClassName?: string;
  isCreatable?: boolean;
  isSpecialities?: boolean;
  value: any;
  styles?: any;
  onInputChange?: Function;
  onChange?: (value: string) => void;
  onClick?: Function;
  noOptionText?: string
  noOptionsMessage?: string
  hideNoOptionText?: boolean
  isCompanydropdown?: boolean;
  isMultiSelect?: boolean;
}

const MultiSelectCustomDropdown = forwardRef<
  HTMLInputElement,
  IMultiSelectCustomDropdownProps
>(
  ({
    options,
    isClearable,
    isMulti,
    openMenuOnClick,
    createOptionPosition,
    selectClassName,
    createClassName,
    isCreatable,
    isSpecialities,
    value,
    styles,
    onInputChange,
    onChange,
    onClick,
    noOptionText,
    noOptionsMessage,
    hideNoOptionText,
    isCompanydropdown,
    isMultiSelect
  }: IMultiSelectCustomDropdownProps, ref) => {

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');  

    const handleSelectOption = (props: any) => {
      setInputValue("");
      onClick && onClick(props.data);
      setMenuIsOpen(false);
    }
    const Option = (props: any, ref: any) => {
      return (
        <div className='cursor-pointer' onClick={() => { handleSelectOption(props) }} >
          <SelectDropdownComponent
            name={props?.data?.name || props?.data?.label}
            avatar={props?.data?.avatar}
            designation={props?.data?.designation}
            industry={props?.data?.industry}
            className="w-[444px] p-1 mb-1"
            ref={ref}
            defaultAvatar={props.data.defaultAvatar}
            isCompanydropdown={isCompanydropdown}
          />
        </div>
      );
    };

    const CustomMultiValue = (props: any) => {
      const { data, innerProps, removeProps } = props;
      return (
        <div
          {...innerProps}
          className={classNames("h-[30px] rounded-[5px] bg-[#EDF2F5] hover:bg-[#E0E5E8] flex items-center pr-[3px] pl-[10px] transition-all", {
            "pl-[10px]": isCompanydropdown,
          })}
        >
          {/* {isCompanydropdown && <img src={ data?.avatar ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${data?.avatar}` : companyDefault} className=" border-1 border-[#6885aa] !rounded-[5px] w-[24px] h-[24px] min-w-[24px] object-contain mr-2" />} */}
          <span className='text-[15px] font-normal leading-[30px]'>{data.label}</span>
          <div className='cursor-pointer pl-[12px]' {...removeProps}><BulletCrossSvgIcon /></div>
        </div>
      );
    };

    const handleInputChange = (inputValue: any) => {
      setMenuIsOpen(inputValue.trim().length > 0);
    };

    const handleMenuClose = () => {
      setInputValue(''); // Clear input value after selecting an option
    };

    const customNoOptionsMessage = () => noOptionsMessage ? noOptionsMessage : "Please try another capability";

    const filteredOptions = () =>
      options && options?.filter(
        (option: IReactSelectOption) =>
          option?.label.toLowerCase().includes(inputValue.toLowerCase())
      ) || [];


    if (isCreatable) {
      return (
        <div className="relative">
          <Creatable
            className={classNames("dropdown-style tags-style")}
            classNamePrefix={classNames({
              "class-name-custom": !filteredOptions()?.length,
              [`${createClassName}`]: createClassName,
            })}
            options={options}
            onInputChange={(inputValue) => {
              setInputValue(inputValue);
              handleInputChange(inputValue);
              onInputChange && onInputChange(inputValue);
            }}
            isClearable={false}
            isMulti={isMulti}
            createOptionPosition={createOptionPosition}
            formatCreateLabel={() => null}
            components={{ Option, MultiValue: CustomMultiValue }}
            openMenuOnClick={openMenuOnClick}
            value={value}
            onChange={(e) => { setMenuIsOpen(false); onChange && onChange(e); }}
            closeMenuOnSelect
            noOptionsMessage={customNoOptionsMessage}
            menuIsOpen={menuIsOpen}
            styles={styles}
          />
          {!filteredOptions()?.length && inputValue?.length && !hideNoOptionText ?
            <div className='absolute top-[105%] bg-white rounded-[4px] shadow-[0_1px_7px_0px_rgba(57,54,54,0.38)] p-4 flex items-center justify-center w-[425px] z-30 text-center font-semibold'>
              No Result Found <br />
              {noOptionText || ""}
            </div>
            : null}
        </div>
      );
    } else {
      return (
        <Select
          options={options}
          isMulti={isMulti}
          onChange={(e) => onChange && onChange(e)}
          value={value}
          className={selectClassName}
          inputValue={inputValue}
          styles={styles}
          components={isMultiSelect ? { Option, MultiValue: CustomMultiValue } : { MultiValue: CustomMultiValue }}
          isClearable={false}
          closeMenuOnSelect
          menuIsOpen={menuIsOpen}
          onMenuClose={handleMenuClose}
          onInputChange={(inputValue) => {
            setInputValue(inputValue);
            handleInputChange(inputValue);
            onInputChange && onInputChange(inputValue);
          }}
          classNamePrefix={classNames({
            [`${createClassName}`]: createClassName
          })}
          noOptionsMessage={customNoOptionsMessage}
        />
      );
    }
  }
);

export default MultiSelectCustomDropdown;
