import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import SearchResultLayout from '../../layouts/search-result-layout';
import Companies from './companies';
import CompanyFilters from './filter/company-filters';
import PeopleFilters from './filter/people-filters';
import People from './people';
import { Footer } from '../../shared-components/V2/Footer/footer';
import { AdvertisingCard, Button } from '../../shared-components/V2';
import PanelistAd from '../../assets/images/panelist-ad.svg';

export const SearchResults = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [isShowEventFilter, setIsShowEventFilter] = useState<boolean>(false);
  const [searchByType, setSearchByType] = useState<string>('');
  const [companyfilters, setCompanyFilters] = useState<string>('');
  const [peoplefilters, setPeopleFilters] = useState<string>('');
  const { search } = useLocation();
  const searchValue = new URLSearchParams(search).get('search');
  const [searchText, setSearchText] = useState<string>(searchValue || '');
  const [companyPeopleResults, setCompanyPeopleResults] = useState<any>();

  const navigate = useNavigate();
  // eslint-disable-next-line no-console
  useEffect(() => {
    if (searchValue) {
      setSearchText(searchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMobileEventFilter = () => {
    setIsShowEventFilter(!isShowEventFilter);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const HeaderLinks = ({ searchByType }: any) => (
    <div className="flex justify-between flex-row sm:gap-y-0 gap-y-2">
      <div
        className={classNames({
          'flex items-center': (innerWidth < 1024),
        })}
      >
        <Button
          text="Companies"
          size="small"
          className={classNames('font-normal border-1 border-[#0049EF] rounded-full text-[12px] text-[#203C6E] hover:bg-[#DFE5EB] mx-1', {
            'bg-[#0049EF] !text-white': searchByType === 'company'
          })}
          onClick={() => setSearchByType('company')}
        />
        <Button
          text="People"
          size="small"
          className={classNames('font-normal border-1 border-[#0049EF] rounded-full text-[12px] text-[#203C6E] hover:bg-[#DFE5EB] mx-1', {
            'bg-[#0049EF] !text-white': searchByType === 'people'
          })}
          onClick={() => setSearchByType('people')}
        />
      </div>
      <div className="order-last flex items-center">
        <b
          className={classNames('text-md3 font-medium mr-1 text-[#203C6E]', {
            flex: (innerWidth > 1024),
            hidden: (innerWidth < 1024),
          })}
        >
          Also look for:
        </b>
        <Button
          text="Events"
          size="small"
          className="font-normal border-1 border-[#0049EF] rounded-full text-[12px] text-[#203C6E] hover:bg-[#DFE5EB] mx-1"
          onClick={() => navigate(`/events?search=${searchText}`)}
        />
        <Button
          text="Spotlight"
          size="small"
          className="font-normal border-1 border-[#0049EF] rounded-full text-[12px] text-[#203C6E] hover:bg-[#DFE5EB] mx-1"
          onClick={() => navigate(`/spotlight?search=${searchText}`)}
        />
      </div>
    </div>
  );

  return (
    <div>
      <SearchResultLayout
        showLeftPanel={searchByType}
        leftPanel={(
          <div
            className={classNames('create-post-card px-[20px] py-[14px] delay-400 duration-500 ease-in-out transition-all transform', {
              'h-0 px-0 !py-0 hidden': (innerWidth < 1024 && !isShowEventFilter),
              'flex p-0': isShowEventFilter
            })}
          >
            {searchByType === 'company' && (
              <CompanyFilters
                setCompanyFilters={setCompanyFilters}
                companyFilters={companyfilters}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            )}
            {searchByType === 'people' && (
              <PeopleFilters
                setPeopleFilters={setPeopleFilters}
                peopleFilters={peoplefilters}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            )}
          </div>
        )}
        rightPanel={(
          <>
            <AdvertisingCard
              imageUrl={PanelistAd}
            />
            <div className="pb-3">
              <Footer />
            </div>
          </>
        )}
      >
        <div className="w-full flex flex-col gap-4 sm:mb-0 mb-4">
          {(searchByType === 'company' || searchByType === 'people') && (
            <div
              className={classNames('w-full flex justify-end', {
                block: (innerWidth < 1024),
                hidden: (innerWidth > 1024),
              })}
            >
              <Button
                text={isShowEventFilter ? 'Hide Filters' : 'All Filters'}
                onClick={handleMobileEventFilter}
                size="small"
                className={classNames('rounded-[5px] text-sm font-[600] hover:bg-[#2e6eff] bg-[#0049EF] text-white max-w-[110px]', {
                  block: (innerWidth < 1024),
                  hidden: (innerWidth > 1024),
                  'mt-2': isShowEventFilter
                })}
              />
            </div>
          )}
          <HeaderLinks searchByType={searchByType} />
          {/* {(companyPeopleResults && !companyPeopleResults?.companies?.length && !companyPeopleResults?.people?.length) ? <div className="h-[calc(100vh-130px)] flex items-center"><NoEvents /></div>
            : ( */}
          {['', 'company'].includes(searchByType)
            && (
              <Companies
                searchText={searchText}
                onSeeAll={() => setSearchByType('company')}
                infiniteScroll={searchByType === 'company'}
                filters={companyfilters}
                companiesSearchResults={(companies:any) => setCompanyPeopleResults((prevState: any) => ({
                  ...prevState,
                  companies
                }))}
              />
            )}
          {['', 'people'].includes(searchByType)
            && (
              <People
                searchText={searchText}
                onSeeAll={() => setSearchByType('people')}
                infiniteScroll={searchByType === 'people'}
                filters={peoplefilters}
                peopleSearchResults={(people:any) => setCompanyPeopleResults((prevState: any) => ({
                  ...prevState,
                  people
                }))}
                companyPeopleResults={companyPeopleResults}
              />
            )}
          {/* )} */}
        </div>
      </SearchResultLayout>
    </div>
  );
};

export default SearchResults;
