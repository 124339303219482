// import 'react-toastify/dist/ReactToastify.css';
import { HyperLink } from '../../shared-components';
import {
  Navbar, PageHeader, PageTitle, Footer
} from '../../components';
import LoginForm from './login-form';

export const Login = () => (
  <>
    <Navbar />
    <div className="bg-[#001BF6] flex text-center h-[calc(100vh-112px)]">
      <div className="mx-auto">
        <div className="md:-ml-9">
          <div className="flex w-full flex-wrap mt-28">
            <div className="flex flex-col text-left">
              <PageHeader isBlueTheme />
              <PageTitle isBlueTheme titleText="Log in" />
              <LoginForm isBlueTheme from="site" changePage={null} />
              <div>
                <div className="flex justify-center mt-5 text-sm1 text-white">
                  New member? &nbsp;
                  <HyperLink
                    className="font-medium"
                    variant="Signup"
                    text="Sign Up"
                    textColor="#ffffff"
                    href="/join"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer className="bg-black py-3" />
  </>
);

export default Login;
