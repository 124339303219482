import './event-ticket.module.css';

import {
  useEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  IEventTicket,
  ISelectedTicket,
} from '../../../models/tickets';
import { Button } from '../../../shared-components/V2/Button';
import TicketCard from '../../../shared-components/V2/TicketCard/ticket-card';
import { IAppState } from '../../../store';
import {
  removeTicketFromCart,
  selectTicket,
  updateTickets,
} from '../../../store/ticket';
import { useCurrentUser } from '../../../utils/hooks';
import EventCard from './event-card';
import TicketBreadcrumb from './ticket-breadcrumb';
import TicketCart from './ticket-cart';
import TicketPayment from './ticket-payment';
import { TicketService } from '../../../services/Tickets';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutForm } from './checkout-form';
import OrderConfirmation from './order-confirmation';
import EventDetailsForm from '../../event/registration/event-signup/event-details-form';
import { eventRegistrationStep2 } from '../../../store/user/signup/actions';

interface IEventTicketProps {
  eventId: string;
  eventTitle: string;
  cover: string;
  logo: string;
  eventDate: string;
  location?: string;
  tickets: IEventTicket[];
  onSubmit: Function;
  onCancel: Function;
}

const EventTickets = ({
  eventId,
  eventTitle,
  cover,
  logo,
  eventDate,
  location,
  tickets,
  onSubmit,
  onCancel,
}: IEventTicketProps) => {
  const ticketService = new TicketService();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const { eventTickets } = useSelector((state: IAppState) => state);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [ticketSlides, setTicketSlides] = useState<any[]>([]);
  const [paymentStage, setPaymentStage] = useState(1);
  const [clientSecret, setClientSecret] = useState("");
  const [currentOrder, setCurrentOrder] = useState<any>();
  const [confirmedOrder, setConfirmedOrder] = useState<any>();
  const [eventQuestionsForm, setEventQuestionsForm] = useState<any>();
  const contentWrapper = useRef(null);

  useEffect(() => {
    if (eventTickets.selectedTickets.length > 0)
      setShowValidationMessage(false);
  }, [eventTickets.selectedTickets.length]);

  useEffect(() => {
    sortSlides(tickets, 3);
  }, [tickets]);

  const handleClick = (_: React.MouseEvent, index: number) => {
    setSelectedIndex(index);
  };

  {
    /* Sort array to show three card */
  }
  function sortSlides(arr: string | any[], slideSize: number) {
    const SlideArr = [];
    for (let i = 0; i < arr.length; i += slideSize) {
      const totalSlides = arr.slice(i, i + slideSize);
      SlideArr.push(totalSlides);
    }
    setTicketSlides(SlideArr);
  }

  const displayTickets = () => {

    {/* Tickets section */ }
    {/* Steps at the top */ }
    return (
      <div className="flex flex-col h-full">
        <TicketBreadcrumb selected="Tickets &amp; Registration" />
        {/* Ticket cards */}
        <div className="max-w-[847px] mb-5">
          <div
            className="flex flex-row overflow-x-auto no-scrollbar mr-4 ml-[20px] py-6 slides justify-center"
            ref={contentWrapper}
          >
            {ticketSlides.length > 0 &&
              ticketSlides.map((ticket, index) =>
                ticket.map(
                  (
                    item: {
                      id: string;
                      ticketName: string;
                      price: number;
                      ticketDescription: string;
                      ticketLimit: { toString: () => string | undefined };
                      inclusions: string[];
                      currency: string;
                      ticketPolicy: string;
                    },
                    index: number
                  ) => (
                    <div
                      id={`slide-${index}`}
                      key={`ticket-${index}`}
                      className="pl-3"
                    >
                      <div key={index} className="mr-3">
                        <TicketCard
                          id={item?.id}
                          eventId={eventId}
                          currency={item?.currency}
                          userId={user?.id}
                          ticketPolicy={item.ticketPolicy}
                          title={item?.ticketName}
                          price={item?.price}
                          description={item?.ticketDescription}
                          availability={item?.ticketLimit.toString()}
                          quantity={((eventTickets?.selectedTickets.filter(t => t?.ticketId === item.id)) as any)?.[0]?.numberOfTickets ?? 0}
                          inclusions={item?.inclusions}
                          onIncrement={(selectedTicket: ISelectedTicket) => {
                            setCurrentOrder(null);
                            dispatch(selectTicket(selectedTicket));
                          }}
                          onDecrement={(ticketId: string) => {
                            setCurrentOrder(null);
                            if (
                              eventTickets.selectedTickets.some(
                                (ticket) => ticket.ticketId === ticketId
                              )
                            ) {
                              let ticket = eventTickets.selectedTickets.find(
                                (ticket) => ticket.ticketId === ticketId
                              );

                              if (ticket?.numberOfTickets! > 1) {
                                // if there are more than one tickets, decrease the numnberOfTickets by 1
                                dispatch(updateTickets(ticketId));
                              } else {
                                // if there is only one ticket then remove it
                                dispatch(removeTicketFromCart(ticketId));
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  )
                )
              )}
          </div>
          <div className="flex flex-row justify-center items-center event-card-bulets">
            {ticketSlides &&
              ticketSlides.length > 0 &&
              ticketSlides.map((item: any, i: number) => (
                <a
                  key={`tab-button-${i}`}
                  className={classNames("mr-1 bullets", {
                    [`active`]: i == selectedIndex,
                  })}
                  href={`#slide-${i}`}
                  onClick={(e) => {
                    handleClick(e, i);
                  }}
                >
                  ○
                </a>
              ))}
          </div>
        </div>
        <div className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="844"
            height="0.5"
            viewBox="0 0 844 0.5"
          >
            <line
              id="Line_1393"
              data-name="Line 1393"
              x2="844"
              transform="translate(0 0.25)"
              fill="none"
              stroke="#cecece"
              strokeWidth="0.5"
            />
          </svg>
        </div>
        <div className="flex flex-row justify-between border-t-1 border-transparent">
          <div className="text-sm text-gray-1 p-4">
            You can enter discount and membership codes in the next step.
          </div>
          <div className="flex flex-row p-2">
            <div className="mr-2">
              <Button
                className="bg-gray-1 text-blue-3"
                size="small"
                text="Cancel"
                onClick={() => onCancel()}
              />
            </div>
            <div className="mr-3">
              <Button
                className="bg-blue text-white"
                size="small"
                text="Next"
                onClick={() => {
                  if (eventTickets.selectedTickets.length === 0) {
                    setShowValidationMessage(true);
                  } else {
                    setShowValidationMessage(false);
                    setPaymentStage(2);
                    // onSubmit();
                  }
                }}
              />
            </div>
          </div>
        </div>
        {showValidationMessage && (
          <div className="text-sm text-red-2 p-4">
            Please select atleast one ticket to proceed
          </div>
        )}
      </div>
    )
  }

  const displayPayment = () => {
    return <TicketPayment
      eventId={eventId}
      eventTitle={eventTitle}
      cover={cover!}
      logo={logo!}
      eventDate={eventDate}
      onPlaceOrder={(order: any, couponCode: string) => {
        ticketService.placeOrder({
          userTickets: order,
          couponCode
        }).then((order: any) => {
          setCurrentOrder(order);
          setClientSecret(order?.stripePaymentSecret);
          setPaymentStage(4);
          // setOpenOrderConfirmationModal(true);
        });
      }} // setOpenOrderConfirmationModal(true)}
      onCancel={() => { setPaymentStage(1) }}
    />
  }

  const stripePayment = () => {
    const appearance: any = {
      theme: "stripe"
    };
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE}`);
    const options = {
      clientSecret,
      appearance,
    };
    return (
      <div className='flex flex-col w-full'>
        <TicketBreadcrumb className="pl-[35px] pt-[29px] pb-[15px]" selected="Security" />
        <div className='w-full'>
          <div className='font-semibold font flex justify-center w-full p-4'>Payment</div>
          <div className='flex flex-col items-center border-t border-[#CECECE] justify-between w-full py-4'>
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm successCallback={async () => {
                await ticketService.getOrderSummary(currentOrder.orderId).then(({ data }) => setConfirmedOrder(data));
                submitEventQuestionForm();
                setPaymentStage(5);
              }} />
            </Elements>
          </div>
        </div>
        <div className='flex flex-row items-center border-t border-[#CECECE] justify-between h-[50px] mt-2 w-full'>
          <Button
            className="bg-gray-1 text-blue-3 flex flex-none m-6 items-center"
            size="small"
            text="Back"
            onClick={() => setPaymentStage(3)}
          />
        </div>

      </div>
    )
  }

  const orderSummary = () => {
    if (!confirmedOrder) { return; }

    return (<>
      <OrderConfirmation
        eventId={eventId}
        eventTitle={eventTitle}
        cover={cover!}
        logo={logo!}
        startTime={eventDate}
        endTime={eventDate}
        eventType={""}
        location={""}
        organiserEmail={"organiser@sit-down.com"}
        orderNumber={"12345"}
        orderDate={new Date(confirmedOrder.createdAt).toISOString()}
        totalAmount={(confirmedOrder.payment.amount / 100).toFixed(2).toString()}
        onClose={()=>{
          window.location.reload();
        }}
      />
    </>);
  }

  const displayEventQuestions = () => {
    // return null;
    return <div className='flex flex-col h-full'>
      <TicketBreadcrumb className="pl-[35px] pt-[29px] pb-[15px]" selected="Your profile" />
      <div className='w-full h-full'>
        <div className='flex flex-col p-4'>
          <div className='font-semibold flex justify-center'>Event details</div>
          <div className="flex justify-center text-sm font-light leading-4 text-[#18161C] mb-[9px]">
            This host needs the following details to complete your registeration
          </div>
        </div>
        <div className='flex flex-row items-center border-t border-[#CECECE] justify-between w-full'>
          <EventDetailsForm
            eventId={eventId}
            backButtonVisible={true}
            onError={(x: any) => {
              if (typeof x === 'string' && x === 'back') {
                setPaymentStage(1);
              } else if(typeof x === 'string' && x === 'skip') {
                setPaymentStage(3);
              }
            }}
            onSuccess={(...eventForm: any) => {
              setEventQuestionsForm(eventForm);
              // dispatch(eventRegistrationStep2(...eventForm));
              setPaymentStage(3);
            }}
          />
        </div>
      </div>
    </div>
  }

  const submitEventQuestionForm = () => {
    if (eventQuestionsForm) {
      dispatch(eventRegistrationStep2(...eventQuestionsForm));
    }
  }

  return (
    <div className="flex flex-row justify-between">
      {paymentStage === 1 && displayTickets()}
      {paymentStage === 2 && displayEventQuestions()}
      {paymentStage === 3 && displayPayment()}
      {paymentStage === 4 && stripePayment()}
      {paymentStage === 5 && orderSummary()}
      {/* Event card and shopping cart */}
      <div className="flex flex-col bg-white -ml-3 rounded-r-10 w-[350px]">
        {/* EventCard */}
        <div className="p-2">
          <EventCard
            title={eventTitle}
            cover={cover}
            logo={logo}
            eventDate={eventDate}
            location={location!}
          />
        </div>
        <div className="ml-[10px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="330"
            height="1"
            viewBox="0 0 330 0.5"
          >
            <line
              id="Line_1400"
              data-name="Line 1400"
              x2="345"
              transform="translate(0 0.25)"
              fill="none"
              stroke="#cecece"
              strokeWidth="0.5"
            />
          </svg>
        </div>
        {/* Shopping cart */}
        <div className="p-2">
          <TicketCart discount={currentOrder?.amount! / 100} />
        </div>
      </div>
    </div>
  );
};

export default EventTickets;
