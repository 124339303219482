import { Button } from '../../../../shared-components/V2/Button';

interface ISpeakerRoomsProps {
  rooms: any[];
  onClick: Function;
}

export const SpeakerRooms = ({ rooms, onClick }: ISpeakerRoomsProps) => (
  <div className="w-full h-full flex flex-col justify-center items-center rounded-xl overflow-hidden sm:px-0 px-1">
    { rooms?.length ? <div className="w-full md:mb-14 mb-4 text-white">Join a breakout room, you can come back to lobby</div>
      : <div className="w-full md:mb-14 mb-4 text-white text-sm">No breakout room available!</div> }
    <div className="flex flex-wrap gap-6 justify-center items-center md:max-h-fit max-h-[333px] md:overflow-auto overflow-y-scroll md:pb-0 pb-2">
      {
        rooms.length === 1 && onClick(rooms?.[0]?.id)
      }
      {
        rooms.map(room => (
          <div className="shadow-md rounded-lg bg-gray-100 w-[172px] h-[160px] flex flex-col justify-between items-center py-2 overflow-hidden">
            <div className="text-md font-semibold">Room</div>
            <div className="text-blue-900 font-semibold line-clamp-2">
              {room.name}
            </div>
            <div><Button text="Join" className="bg-orange" size="medium" onClick={() => onClick(room.id)} /></div>
          </div>
        ))
      }
    </div>
  </div>
);
