
import { Helmet } from "react-helmet"
import { LiveView } from "./live"
import { UserNavbar } from "src/components"
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";


export const LiveEvent = () => {
    const [isHeaderShow, setIsHeaderShow] = useState<boolean>(false);

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            const mouseY = event.clientY;
            if (mouseY < 10) {
                setIsHeaderShow(true);
            } 
            if (mouseY > 54 && isHeaderShow) {
                setIsHeaderShow(false);
            }
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isHeaderShow]);
    return (
        <>
            <Helmet>
                <title>Panelist | Live Event</title>
            </Helmet>
            <Stack sx={{
                position: 'absolute',
                left: 0,
                width: '100%',
                zIndex: 10000,
                transition: 'top 0.3s ease-in-out',
                top: isHeaderShow ? 0 : -100 
            }}>
                <UserNavbar />
            </Stack>
            <LiveView isHeaderShow={isHeaderShow} />
        </>
    )
}