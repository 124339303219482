import React from 'react';
import {
  Box,
  Button, Grid, Stack, Typography
} from '@mui/material';
import PMapFieldSelect, { OptionType } from '../PMapFieldSelect';

interface Props {
  headers: OptionType[];
  values:any;
}

export const MapFields = ({ headers, values }:Props) => {
  const leftMapFields = [
    'Company',
    'Slug',
    'Size',
    'Industry',
    'Head quarters',
    'Company website'
  ];

  const selectedIds = Object.values(values).map((item: any) => item?.id);
  const filteredHeaders = headers.filter(header => !selectedIds.includes(header?.id));
  return (
    <Stack>
      <Stack sx={{ mb: '30px' }}>
        <Typography className="requirment-title">
          Map the column headers in your CSV to Panelist's account fields
        </Typography>
        <Typography className="requirment-desc">
          Inaccurate mappings may affect our ability to process and match your accounts
        </Typography>
      </Stack>
      <Grid
        rowGap="26px"
        columnGap="0px"
        columnSpacing="26px"
        container
        sx={{ pl: '40px' }}
      >
        <Grid item xs={12} md={6} lg={6}>
          {leftMapFields.map((item, key) => (
            <Box
              key={key}
              sx={{
                display: 'flex', justifyContent: 'flex-start', mb: '20px', gap: '10px'
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: '150px',
                  borderRadius: '5px',
                  backgroundColor: '#ffffff',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    backgroundColor: '#0031FF',
                    '& .MuiTypography-root': {
                      color: '#ffffff',
                    },
                  },
                }}
              >
                <Typography
                  variant="body1"
                  className="mapfield-btn"
                >
                  {item}
                </Typography>
              </Button>
              <Stack sx={{ width: '220px' }}>
                <PMapFieldSelect name={item} placeholder="Select a column" className="dropdown-container mapfield-dropdown" options={filteredHeaders} />
              </Stack>
            </Box>
          ))}

        </Grid>
      </Grid>
    </Stack>
  );
};

export default MapFields;
