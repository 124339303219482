import { Link } from 'react-router-dom';

import { ICompanyEmployee } from '../../../models/company/company-employee';
import { Avatar } from '../../../shared-components/V2';

export const ConnectionItem = (props: { item: ICompanyEmployee }) => (
  <Link to={`/wall/${props.item.slug}`}>
    <div className="flex flex-col items-center">
      <div>
        <Avatar
          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${props.item.avatar}`}
          alt=""
          className="rounded-full"
          size="LARGE"
        />
      </div>
      <div className="text-h3 h-[17px] text-[#3C3C3C] font-[500] text-center mt-[5px] w-full line-clamp-1">
        {`${props.item?.firstName}`}
      </div>
      <div className="text-h3 h-[17px] text-[#3C3C3C] font-[700] text-center w-full line-clamp-1">
        {props.item?.company?.name}
      </div>
    </div>
  </Link>
);
