import { Link } from 'react-router-dom';
import { IEventSponsor } from '../../models/event/event-sponsor';
import { Logo } from '../../shared-components/V2/Logo';

export interface IEventPartnerProps {
  imageUrl: string;
  companyName: string;
}

export const PartnerItem = (props: IEventPartnerProps) => (
  <div className="flex flex-col items-center justify-center p-4">
    <Logo
      className="rounded-lg w-85 h-85 shadow-md p-2 align-middle mb-2 object-contain"
      src={props.imageUrl}
      alt={props.companyName}
    />
    <div className="text-md font-medium text-center">{props.companyName}</div>
  </div>
);

export const EventPartners = (props: { eventPartners: IEventSponsor[]; className?: string }) => {
  const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;
  return (
    <div className={`h-full py-8 mt-4 mb-4 rounded-lg bg-white shadow-md ${props.className}`}>
      <p className="flex flex-row justify-center align-center text-lg0 text-blue-2 mt-4">Event Partners</p>
      <div className="grid sm:grid-cols-5 grid-cols-2">
        {props.eventPartners
          && props.eventPartners.length > 0
          && props.eventPartners.map((sponsor, index) => (
            <div key={`sponsor-${index}`}>
              <Link to={`/wall/company/${sponsor?.company?.slug}`}>
                <PartnerItem
                  imageUrl={`${imageUrlPrefix}/${sponsor?.company?.logo}`}
                  companyName={sponsor?.company?.name}
                />
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default EventPartners;
