import classNames from 'classnames';
import React, { FC } from 'react';

interface IFormFieldWithCaption {
  caption: string;
  captionClassName?: string;
  className?: string;
  children: JSX.Element;
}

export const FormFieldWithCaption: FC<IFormFieldWithCaption> = ({
  caption,
  captionClassName,
  className,
  children,
}) => (
  <div className={`flex flex-col ${className}`}>
    <div
      className={classNames(
        'text-sm5 text-black',
        {
          [`${captionClassName}`]: captionClassName
        }
      )}
    >
      {caption}
    </div>
    {children}
  </div>
);

export default FormFieldWithCaption;
