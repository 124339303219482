import bulb from '../../../assets/images/compliance/bulb-icon.png';

const HostContentSevenEight = () => (
  <>
    <hr className="my-10" />
    <div id="our-content" className="mt-7 text-gray-1 text-lg5">
      8. Our Content
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        We have Content on the Platform too. Where we do, we own all Intellectual Property. Where
        there are errors or inaccuracies on the Platform, we can, without obligation, correct,
        change or update the Content.
      </div>
    </div>
    <div className="mt-3">
      a. This Agreement does not transfer to you any intellectual property owned by Panelist or
      third-parties, and all rights, titles, and interests in and to such property will remain (as
      between the parties) solely with Panelist.
    </div>
    <div className="mt-3">
      b. All trademarks, service marks, graphics and logos used in connection with our Platform or
      Services, are trademarks or registered trademarks of Panelist or Panelist licensors. Other
      trademarks, service marks, graphics and logos used in connection with our Platform or Services
      may be the trademarks of other third-parties.
    </div>
    <div className="mt-3">
      c. Your use of our Platform and Services grants you no right or license to reproduce or
      otherwise use any Platform or third-party trademarks.
    </div>
    <div className="mt-3">
      d. Occasionally there may be information on the Platform that contains typographical errors,
      inaccuracies or omissions that may relate to product descriptions, availability, promotions
      and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to
      change or update information or cancel orders if any information on the Platform or on any
      related Service is inaccurate at any time without prior notice (including after you have
      submitted your order). We undertake no obligation to update, amend or clarify information on
      the Platform including, without limitation, pricing information, except as required by law. No
      specified update or refresh date applied on the Platform should be taken to indicate that all
      information on the Platform or on any related Service has been modified or updated.
    </div>
    <hr className="my-10" />
    <div id="backup" className="mt-7 text-gray-1 text-lg5">
      9. Backup
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        Although there may be links to other websites, it does not mean we endorse or are affiliated
        with those websites in any way unless stated. As such, access and use of links and
        corresponding services is your responsibility. We hold no responsibility or liability for
        content on other websites.
      </div>
    </div>
    <div className="mt-3">
      a. Although this Platform may be linked to other websites, we are not, directly or indirectly,
      implying any approval, association, sponsorship, endorsement, or affiliation with any linked
      website, unless specifically stated herein. We are not responsible for examining or
      evaluating, and we do not warrant the offerings of, any businesses or individuals or the
      content of their websites. We do not assume any responsibility or liability for the actions,
      products, services, and content of any other third-parties. You should carefully review the
      legal statements and other conditions of use of any website which you access through a link
      from this Website. Your linking to any other off-site websites is at your own risk.
    </div>
    <hr className="my-10" />
    <div id="link-to-other-websites" className="mt-7 text-gray-1 text-lg5">
      10. Links to other websites
    </div>
  </>
);

export default HostContentSevenEight;
