/* eslint-disable */
import { Box, ClickAwayListener, Link, Paper, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
// import { UserService } from 'src/api/userService';
import PAutocomplete from './PAutocomplete';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { UserService } from 'src/services';
import { userDropdownOptions } from './Common';
import PUserAutocomplete from './PUserAutocomplete';

type OptionType = {
  email: string;
  firstName: string;
  lastName: string;
  id: string;
};

interface IPUserSelect {
  sx?: any;
  className?: string;
  label?: string;
  placeholder?: string;
  name: string;
  defaultValue?: OptionType[];
  multiSelect?: boolean | undefined;
  selectionRole?: string;
  togglePUserSelectOpen?: any;
  style?: any;
  filteredUserIds?: string[];
  onSuccess?: any;
  addNewRegistrant?: boolean;
  registrantsSelectedFilters?: any;
  addNewSpeaker?: boolean;
  onChange?: (data: any) => void;
  setUserIsSpeaker?: (data: boolean) => void;
}

function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const PUserSelect = ({
  sx,
  className,
  defaultValue,
  label,
  placeholder,
  name,
  multiSelect = true,
  selectionRole,
  togglePUserSelectOpen,
  style,
  filteredUserIds,
  onSuccess,
  addNewRegistrant,
  registrantsSelectedFilters,
  addNewSpeaker,
  onChange,
  setUserIsSpeaker,
}: IPUserSelect) => {
  const { enqueueSnackbar } = useSnackbar();

  const [users, setUsers] = useState<OptionType[]>([]);
  const [addForm, setAddForm] = useState({ open: false, userDetails: null });
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [addEmail, setEmailForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { setValue, getValues } = useFormContext();

  const debouncedInputValue = useDebounce(inputValue, 500);


  useMemo(() => {
    if (debouncedInputValue) {
      const userService = new UserService();
      // setOpen(true);
      // const result = await userService.checkEmail(data);
      userService.getAllUsers(debouncedInputValue).then(({ data }) => {
        const sortedUsers: OptionType[] = data;
        if (filteredUserIds?.length) {
          const filteredArray = sortedUsers.filter((user) => !filteredUserIds.includes(user.id));
          setUsers(filteredArray ?? []);
        } else {
          setUsers(sortedUsers ?? []);
        }
      });
    } else {
      setUsers([]);
    }
  }, [debouncedInputValue]);

  const filteredOptions = (options: OptionType[], state: any) => {
    options.filter(
      (option: OptionType) =>
        `${option.firstName} ${option.lastName}`
          .toLowerCase()
          .includes(state.inputValue.toLowerCase()) ||
        option.email.toLowerCase().includes(state.inputValue.toLowerCase()),
    );

    return options;
  };

  const onSetValue = (data: any) => {
    if (multiSelect) {
      setValue(name, [...getValues()[name], data], { shouldDirty: true, shouldValidate: true });
    } else {
      setValue(name, { ...data }, { shouldDirty: true });
    }
    if (onSuccess) onSuccess();
  };

  // useEffect(() => {
  //   if (getValues()[name]) setOpen(false);
  // }, [getValues()[name]]);

  useEffect(() => {
    if (togglePUserSelectOpen) togglePUserSelectOpen(open);
  }, [open]);

  // useEffect(() => {
  //   if(!getValues()[name]) setOpen(true);
  // }, [addForm?.open,getValues()[name]]);
  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          if (getValues()[name])  setOpen(false);
          if (!getValues()[name]) setInputValue('');
        }}
      >
        <Stack>
          <PUserAutocomplete
            // freeSolo
            label={label}
            id="add_user"
            key="add_user"
            placeholder={placeholder}
            sx={sx}
            className={className}
            open={open}
            name={name}
            inputValue={inputValue}
            onInputChange={(event, value) => {
              setOpen(true);
              setInputValue(value);
            }}
            // onChange={(value) => {
            //   if (onChange) onChange(value);
            // }}
            multiple={multiSelect}
            fullWidth
            {...(defaultValue && { defaultValue })}
            options={users}
            filterOptions={filteredOptions}
            noOptionsText={''}
            renderOption={(props, option: any) => {
              return (
                <li {...props} style={{ paddingLeft: '0px !important' }}>
                  {userDropdownOptions(option, style)}
                </li>
              );
            }}
            getOptionLabel={(option: any) => {
              let fullName = `${option.firstName} ${option.lastName}`;
              if (!fullName.trim().length) {
                return option.email;
              }

              if (option?.company?.name) {
                fullName += ` • ${option?.company?.name || option.companyId}`;
              }

              return fullName;
            }}
          />
          {isUserRegistered && (
            <Typography
              id="event-name-input"
              gutterBottom
              sx={{
                '&.MuiTypography-root': {
                  color: '#FF5630',
                  fontSize: '0.75rem',
                  fontWeight: 400,
                  ml: '14px',
                  mt: '8px',
                },
              }}
            >
              This user has already been registered to the event.
            </Typography>
          )}
        </Stack>
      </ClickAwayListener>
    </>
  );
};

export default PUserSelect;
