import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IUpcomingEventsSpeakerResult } from '../../../models/event/UpcomingEventSpeakersResult';
import { IUpcomingEventState } from '../../../models/event/UpcomingEventState';
import { EventsService } from '../../../services/events';
import { UpcomingEventSpeakersActionTypes } from './types';

export interface IUpcomingEventSpeakersLoadingAction {
  type: UpcomingEventSpeakersActionTypes.EVENT_SPEAKERS_LOADING;
  loading: boolean;
}

export interface IUpcomingEventSpeakersSuccessAction {
  type: UpcomingEventSpeakersActionTypes.EVENT_SPEAKERS_SUCCESS;
  payload: IUpcomingEventsSpeakerResult;
}
export interface IUpcomingEventSpeakersErrorAction {
  type: UpcomingEventSpeakersActionTypes.EVENT_SPEAKERS_ERROR;
  errorMessage: string;
}

export type UpcomingEventSpeakersActions =
  | IUpcomingEventSpeakersLoadingAction
  | IUpcomingEventSpeakersSuccessAction
  | IUpcomingEventSpeakersErrorAction;

export const getUpcomingEventSpeakers: ActionCreator<
  ThunkAction<Promise<any>, IUpcomingEventState, null, IUpcomingEventSpeakersSuccessAction>
> = (eventIdOrSlug: string) => {
  return async (dispatch: Dispatch) => {
    const eventsService: EventsService = new EventsService();

    try {
      const result = await eventsService.getUpcomingEventSpeakers(eventIdOrSlug);

      dispatch({
        type: UpcomingEventSpeakersActionTypes.EVENT_SPEAKERS_SUCCESS,
        payload: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: UpcomingEventSpeakersActionTypes.EVENT_SPEAKERS_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: UpcomingEventSpeakersActionTypes.EVENT_SPEAKERS_LOADING,
        loading: false
      });
    }
  };
};

export const loadUpcomingEvent: ActionCreator<
  ThunkAction<any, IUpcomingEventState, null, IUpcomingEventSpeakersLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: UpcomingEventSpeakersActionTypes.EVENT_SPEAKERS_LOADING,
    loading: shouldLoad
  });
