import { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/Add';
import ExpandIcon from '@mui/icons-material/Remove';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '../../../assets/images/event-landing-pre-event/Icon-feather-search.svg';
import crossIcons from '../../../assets/images/white-cross.png';

import { CompanyService } from '../../../services/CompanyService';
import { DebounceInput } from '../../../shared-components/DebounceInput';

type ICompanyFiltersProps = {
  // eslint-disable-next-line no-unused-vars
  setCompanyFilters: (value: string) => void;
  companyFilters: string;
  searchText: string | undefined;
  // eslint-disable-next-line no-unused-vars
  setSearchText: (query: string) => void
};
export const CompanyFilters = (props: ICompanyFiltersProps) => {
  const [selectedTab, setSelectedTab] = useState('');
  const [specialties, setSpecialties] = useState<any>([]);
  const [industries, setIndustries] = useState<any>([]);
  const [sizes, setSizes] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const comapnyService: CompanyService = new CompanyService();

  // filters
  const [specialtiesFilters, setSpecialtiesFilters] = useState<any>([]);
  const [industriesFilters, setIndustriesFilters] = useState<any>([]);
  const [sizesFilters, setSizesFilters] = useState<any>([]);
  const [regionsFilters, setRegionsFilters] = useState<any>([]);

  // exclusions
  const [specialtiesExclusions, setSpecialtiesExclusions] = useState<any>([]);
  const [industriesExclusions, setIndustriesExclusions] = useState<any>([]);
  const [sizesExclusions, setSizesExclusions] = useState<any>([]);
  const [regionsExclusions, setRegionsExclusions] = useState<any>([]);

  const getSpeacialties = async (query: string = '') => {
    const result: any = await comapnyService.getSpecialitiesWithQuery(query);
    setSpecialties([...result?.data]);
  };
  const getIndustries = async (query: string = '') => {
    const result: any = await comapnyService.getIndustriesWithQuery(query);
    setIndustries([...result?.data]);
  };
  const getCompanySizes = async (query: string = '') => {
    const result: any = await comapnyService.getCompanySizes(query);
    setSizes([...result?.data]);
  };
  const getCompanyRegions = async () => {
    const result: any = await comapnyService.getCompanyRegions();
    setRegions([...result?.data]);
  };

  const filterRegions = async (query: string = '') => {
    if (query === '') {
      await getCompanyRegions();
    } else {
      const filteredRegions = regions.filter((r: any) => r.headquarter?.toLowerCase().indexOf(query.toLowerCase()) > -1);
      setRegions([...filteredRegions]);
    }
  };

  useEffect(() => {
    // getCompanies();
    getSpeacialties();
    getIndustries();
    getCompanySizes();
    getCompanyRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSpecialtiesList = () => (
    <div className="max-h-44 overflow-auto">
      {specialties.map((item: any) => (
        <span
          key={item.id}
          className="block text-sm text-blue-800 hover:underline hover:cursor-pointer"
          onClick={() => {
            const isalreadyAdded = [...specialtiesFilters].filter(sp => sp.id === item.id);
            if (isalreadyAdded.length === 0) {
              props.setCompanyFilters(
                `${props.companyFilters}&where[companySpeciality]=${item.name}`
              );
              setSpecialtiesFilters([...specialtiesFilters, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getSpecialtiesExclusionsList = () => (
    <div className="max-h-44 overflow-auto">
      {specialtiesExclusions.map((item: any) => (
        <span
          key={item.id}
          className="block text-sm text-blue-800 hover:underline hover:cursor-pointer"
          onClick={() => {
            const isAlreadyExcluded = [...specialtiesExclusions].filter(
              i => i.id === item.id
            );
            if (isAlreadyExcluded.length === 0) {
              props.setCompanyFilters(
                `${props.companyFilters}&where[excludeCompanySpeciality]=${item.name}`
              );
              setIndustriesExclusions([...specialtiesExclusions, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getIndustriesList = () => (
    <div className="max-h-44 overflow-auto">
      {industries.map((item: any) => (
        <span
          key={item.id}
          className="block text-sm text-blue-800 hover:underline hover:cursor-pointer"
          onClick={() => {
            const isalreadyAdded = [...industriesFilters].filter(i => i.id === item.id);
            if (isalreadyAdded.length === 0) {
              props.setCompanyFilters(`${props.companyFilters}&where[industryId]=${item.id}`);
              setIndustriesFilters([...industriesFilters, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getIndustriesExclusionsList = () => (
    <div className="max-h-44 overflow-auto">
      {industriesExclusions.map((item: any) => (
        <span
          key={item.id}
          className="block text-sm text-blue-800 hover:underline hover:cursor-pointer"
          onClick={() => {
            const isAlreadyExcluded = [...industriesExclusions].filter(
              i => i.id === item.id
            );
            if (isAlreadyExcluded.length === 0) {
              props.setCompanyFilters(
                `${props.companyFilters}&where[excludeIndustryId]=${item.id}`
              );
              setIndustriesExclusions([...industriesExclusions, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getSizesList = () => (
    <div className="max-h-44 overflow-auto">
      {sizes.map((item: any) => (
        <span
          key={item.id}
          className="block text-sm text-blue-800 hover:underline hover:cursor-pointer"
          onClick={() => {
            const isalreadyAdded = [...sizesFilters].filter(s => s.id === item.id);
            if (isalreadyAdded.length === 0) {
              props.setCompanyFilters(`${props.companyFilters}&where[companySizeId]=${item.id}`);
              setSizesFilters([...sizesFilters, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getSizeExclusionsList = () => (
    <div className="max-h-44 overflow-auto">
      {sizesExclusions.map((item: any) => (
        <span
          key={item.id}
          className="block text-sm text-blue-800 hover:underline hover:cursor-pointer"
          onClick={() => {
            const isAlreadyExcluded = [...sizesExclusions].filter(
              i => i.id === item.id
            );
            if (isAlreadyExcluded.length === 0) {
              props.setCompanyFilters(
                `${props.companyFilters}&where[excludeCompanySizeId]=${item.id}`
              );
              setSizesExclusions([...sizesExclusions, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getRegionsList = () => (
    <div className="max-h-44 overflow-auto">
      {regions.map((item: any, index: number) => (
        <span
          key={index}
          className="block text-sm text-blue-800 hover:underline hover:cursor-pointer"
          onClick={() => {
            const isalreadyAdded = [...regionsFilters].filter(r => r.name === item.headquarter);
            if (isalreadyAdded.length === 0) {
              props.setCompanyFilters(
                `${props.companyFilters}&where[region]=${item.headquarter}`
              );
              setRegionsFilters([
                ...regionsFilters,
                {
                  name: item.headquarter
                }
              ]);
            }
          }}
        >
          {item.headquarter}
        </span>
      ))}
    </div>
  );

  const getRegionExclusionsList = () => (
    <div className="max-h-44 overflow-auto">
      {regionsExclusions.map((item: any) => (
        <span
          key={item.id}
          className="block text-sm text-blue-800 hover:underline hover:cursor-pointer"
          onClick={() => {
            const isAlreadyExcluded = [...regionsExclusions].filter(
              i => i.id === item.id
            );
            if (isAlreadyExcluded.length === 0) {
              props.setCompanyFilters(
                `${props.companyFilters}&where[excludeRegion]=${item.id}`
              );
              setRegionsExclusions([...regionsExclusions, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const removeSpecialtiesItemHandler = (item: any, excluding: boolean = false) => {
    const updatedItem = [...specialtiesFilters].filter(c => c.id !== item.id);
    setSpecialtiesFilters([...updatedItem]);
    props.setCompanyFilters(
      props.companyFilters.replace(`&where[companySpeciality]=${item.name}`, '')
    );

    if (excluding) {
      props.setCompanyFilters(
        props.companyFilters.replace(`&where[companySpeciality]=${item.name}`, `&where[excludeCompanySpeciality]=${item.name}`)
      );
      setSpecialtiesExclusions([...specialtiesExclusions, item]);
    }
  };

  const removeIndustriesItemHandler = (item: any, excluding: boolean = false) => {
    const updatedItem = [...industriesFilters].filter(c => c.id !== item.id);
    setIndustriesFilters([...updatedItem]);
    props.setCompanyFilters(props.companyFilters.replace(`&where[industryId]=${item.id}`, ''));

    if (excluding) {
      props.setCompanyFilters(props.companyFilters.replace(`&where[industryId]=${item.id}`, `&where[excludeIndustryId]=${item.id}`));
      setIndustriesExclusions([...industriesExclusions, item]);
    }
  };

  const removeSizesItemHandler = (item: any, excluding: boolean = false) => {
    const updatedItem = [...sizesFilters].filter(c => c.id !== item.id);
    setSizesFilters([...updatedItem]);
    props.setCompanyFilters(props.companyFilters.replace(`&where[companySizeId]=${item.id}`, ''));

    if (excluding) {
      props.setCompanyFilters(props.companyFilters.replace(`&where[companySizeId]=${item.id}`, `&where[excludeCompanySizeId]=${item.id}`));
      setSizesExclusions([...sizesExclusions, item]);
    }
  };

  const removeRegionsItemHandler = (item: any, excluding: boolean = false) => {
    const updatedItem = [...regionsFilters].filter(c => c.name !== item.name);
    setRegionsFilters([...updatedItem]);
    props.setCompanyFilters(props.companyFilters.replace(`&where[region]=${item.name}`, ''));

    if (excluding) {
      props.setCompanyFilters(props.companyFilters.replace(`&where[region]=${item.name}`, `&where[excludeRegion]=${item.name}`));
      setRegionsExclusions([...regionsExclusions, item.name]);
    }
  };

  const removeSpecialtiesExclusionsHandler = (item: any, revoking: boolean = false) => {
    const updatedItem = [...specialtiesExclusions].filter(c => c.id !== item.id);
    setSpecialtiesExclusions([...updatedItem]);
    props.setCompanyFilters(
      props.companyFilters.replace(`&where[excludeCompanySpeciality]=${item.name}`, '')
    );

    if (revoking) {
      props.setCompanyFilters(
        `${props.companyFilters}&where[companySpeciality]=${item.name}`
      );
      setSpecialtiesFilters([...specialtiesFilters, item]);
    }
  };

  const removeIndustriesExclusionsHandler = (item: any, revoking: boolean = false) => {
    const updatedItem = [...industriesExclusions].filter(c => c.id !== item.id);
    setIndustriesExclusions([...updatedItem]);
    props.setCompanyFilters(
      props.companyFilters.replace(`&where[excludeIndustryId]=${item.id}`, '')
    );

    if (revoking) {
      props.setCompanyFilters(
        `${props.companyFilters}&where[industryId]=${item.id}`
      );
      setIndustriesFilters([...industriesFilters, item]);
    }
  };

  const removeSizesExclusionsHandler = (item: any, revoking: boolean = false) => {
    const updatedItem = [...sizesExclusions].filter(c => c.id !== item.id);
    setSizesExclusions([...updatedItem]);
    props.setCompanyFilters(
      props.companyFilters.replace(`&where[excludeCompanySizeId]=${item.id}`, '')
    );

    if (revoking) {
      props.setCompanyFilters(
        `${props.companyFilters}&where[companySizeId]=${item.id}`
      );
      setSizesFilters([...sizesFilters, item]);
    }
  };

  const removeRegionsExclusionsHandler = (item: any, revoking: boolean = false) => {
    const updatedItem = [...regionsExclusions].filter(c => c.id !== item.id);
    setRegionsExclusions([...updatedItem]);
    props.setCompanyFilters(
      props.companyFilters.replace(`&where[excludeRegion]=${item.name}`, '')
    );

    if (revoking) {
      props.setCompanyFilters(
        `${props.companyFilters}&where[region]=${item.headquarter}`
      );
      setRegionsFilters([
        ...regionsFilters,
        {
          name: item.headquarter,
        },
      ]);
    }
  };

  const companyFiltertabs = [
    // {
    //   title: 'Company',
    //   list: getCompaniesList(),
    //   filters: [...companiesFilters],
    //   excludedList: getCompanyExclusionsList(),
    //   exclusions: [...companiesExclusions],
    //   removeItem: removeCompanyItemHandler,
    //   removeExclusion: removeCompanyExclusionsHandler,
    //   onFilterValueChanged: getCompanies
    // },
    {
      title: 'Specialties',
      list: getSpecialtiesList(),
      filters: [...specialtiesFilters],
      excludedList: getSpecialtiesExclusionsList(),
      exclusions: [...specialtiesExclusions],
      removeItem: removeSpecialtiesItemHandler,
      removeExclusion: removeSpecialtiesExclusionsHandler,
      onFilterValueChanged: getSpeacialties
    },
    {
      title: 'Industry',
      list: getIndustriesList(),
      filters: [...industriesFilters],
      excludedList: getIndustriesExclusionsList(),
      exclusions: [...industriesExclusions],
      removeItem: removeIndustriesItemHandler,
      removeExclusion: removeIndustriesExclusionsHandler,
      onFilterValueChanged: getIndustries
    },
    {
      title: 'Company Size',
      list: getSizesList(),
      filters: [...sizesFilters],
      excludedList: getSizeExclusionsList(),
      exclusions: [...sizesExclusions],
      removeItem: removeSizesItemHandler,
      removeExclusion: removeSizesExclusionsHandler,
      onFilterValueChanged: getCompanySizes
    },
    {
      title: 'Region',
      list: getRegionsList(),
      filters: [...regionsFilters],
      excludedList: getRegionExclusionsList(),
      exclusions: [...regionsExclusions],
      removeItem: removeRegionsItemHandler,
      removeExclusion: removeRegionsExclusionsHandler,
      onFilterValueChanged: filterRegions
    }
  ];

  const selectedTabHandler = (title: string) => {
    if (title === selectedTab) {
      setSelectedTab('');
      return;
    }
    setSelectedTab(title);
  };

  const resetFilterHandler = () => {
    setIndustriesFilters('');
    setSizesFilters('');
    setRegionsFilters('');
    setSpecialtiesFilters('');
    props.setCompanyFilters('');
    props.setSearchText('');
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-between pb-[15px]">
        <span className="text-[#203C6E] text-[15px] font-medium leading-5">Filter your search</span>
        <span className="text-[#285CB2] text-sm leading-5 hover:underline hover:cursor-pointer" onClick={() => resetFilterHandler()}>
          Clear all
        </span>
      </div>
      <div className="flex w-full pb-3 ">
        <div className="absolute pt-[13px] ml-[18px] ">
          <img className="w-4 h-4" src={SearchIcon} alt="" />
        </div>
        <div className="py-0.5 w-full" style={{ height: '39px' }}>
          <DebounceInput
            delay={400}
            initValue={props.searchText || ''}
            type="text"
            classNames="bg-white pl-[40px] pr-2 py-2 w-full h-[39px] text-sm rounded-2xl"
            onChangeText={(e: string) => props.setSearchText(e)}
            placeholder="Enter a keyword"
          />
        </div>
      </div>
      {companyFiltertabs.map((item, index) => (
        <div
          key={index}
          className={`mb-0 mt-0 pt-2 pb-2 ${index !== companyFiltertabs.length - 1 ? 'border-b-1 border-blue-100' : ''
          }`}
        >
          <div
            onClick={() => selectedTabHandler(item.title)}
            className="flex items-center justify-between cursor-pointer"
          >
            <span className="text-[14px] font-medium leading-5 text-[#285CB2]">{item.title}</span>
            <div>
              {selectedTab === item.title ? (
                <ExpandIcon className="text-sm text-blue-900" />
              ) : (
                <ExpandMoreIcon className="text-sm text-blue-900" />
              )}
            </div>
          </div>
          {item?.exclusions?.length > 0 && (
            <div className="flex flex-wrap gap-1">
              {item?.exclusions?.length > 0 && (
                <span className="block text-[13px] text-[#848484] w-full pb-1">
                  Excluded:
                </span>
              )}
              {item?.exclusions.map((exclusion: any) => (
                <span className="bg-orange-6 text-white text-xs rounded-xl px-3 flex items-center justify-between">
                  {exclusion.name}
                  <AddOutlinedIcon
                    className="text-sm text-white cursor-pointer pl-1"
                    onClick={() => item.removeExclusion(exclusion, true)}
                  />
                  <div
                    className="text-sm cursor-pointer pl-[25px]"
                    onClick={() => item.removeExclusion(exclusion)}
                  >
                    <img className="w-[12px] h-[12px] min-w-[12px] min-h-[12px]" src={crossIcons} alt="" />
                  </div>
                </span>
              ))}
            </div>
          )}
          <div className="flex flex-wrap gap-1">
            {item?.filters.length > 0 && (
              <span className="block text-[13px] text-[#848484] w-full pb-1">Added:</span>
            )}
            {item?.filters.map((filter: any) => (
              <span className="bg-[#0049EF] text-white text-[13px] rounded-[5px] px-[16px] h-[23px] flex items-center justify-between">
                <span className="line-clamp-1">{filter.name}</span>
                <div
                  className="text-sm cursor-pointer pl-[25px]"
                  onClick={() => item.removeItem(filter)}
                >
                  <img className="w-[12px] h-[12px] min-w-[12px] min-h-[12px]" src={crossIcons} alt="" />
                </div>
              </span>
            ))}
          </div>
          {selectedTab === item.title && (
            <div className="">
              {item.title !== 'Company Size' && (
                <div className="pb-1 w-full">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="text-sm focus:outline-none bg-transparent"
                    onChange={(e: any) => item.onFilterValueChanged(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              )}
              <div className="border-t-1 border-blue-100 py-2">{item.list}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CompanyFilters;
