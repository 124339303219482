import React, { useEffect, useState } from 'react';

import {
  format,
  parseISO,
} from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';

import connectionIcon from '../../../assets/images/event-landing-pre-event/connection.svg';
import publicIconDark from '../../../assets/images/event-landing-pre-event/earth-globe-dark.svg';
import lockIcon from '../../../assets/images/lock.svg';

import { Avatar, CardProfileConnect } from '../../../shared-components/V2';
import { Logo } from '../../../shared-components/V2/Logo';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '../../../utils/hooks';
import { setConnectSuggestion } from '../../../store/user/connect-suggestions/actions';
import { closeMessageWindow, openMessageWindow } from '../../../store/newMessage';
import { HoverableDiv } from '../../../shared-components/V2/HoverableDiv';
import { imageUrlPrefix } from '../../../utils';
import classNames from 'classnames';
import profileBackground from '../../../assets/images/avatar-default.svg';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';
import { EarthGlobalSvgIcon } from 'src/shared-components/V2/IconSvg/EarthGlobalSvgIcon/EarthGlobalSvgIcon';
import { LockPostSvgIcon } from 'src/shared-components/V2/IconSvg/LockPostSvgIcon/LockPostSvgIcon';
import { ConnectionPostSvgIcon } from 'src/shared-components/V2/IconSvg/ConnectionPostSvgIcon/ConnectionPostSvgIcon';

interface IPostByInfo {
  name: string;
  avatar: string;
  slug: string;
  companyName?: string;
  jobTitle?: string;
  createdAt?: string;
  byCompany?: boolean;
  privacy?: string;
  moreInfo?: any;
  companySlug?: string;
  isLivePage?: boolean;
}

export const PostByInfo = ({
  slug,
  name,
  avatar,
  companyName,
  jobTitle,
  createdAt,
  byCompany,
  privacy,
  moreInfo,
  companySlug,
  isLivePage
}: IPostByInfo) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const colors = getBrandingColors();
  const currentUser = useCurrentUser();
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showDetailsTimer, setShowDetailsTimer] = useState<any>();

  useEffect(() => {
    setIsOwnProfile(currentUser.id === moreInfo?.id!);
  }, [moreInfo?.id!, currentUser]);

  // const PrivacyType = () => {
  //   let iconUrl: string = '';
  //   if (privacy === 'public') iconUrl = publicIconDark;
  //   if (privacy === 'only-me') iconUrl = lockIcon;
  //   if (privacy === 'connections' || privacy === 'followers') iconUrl = connectionIcon;

  //   return (<img src={iconUrl} alt='' className='w-3 h-3 ml-1' />);
  // };

  const PrivacyType = () => {
    let IconComponent = null;
  
    if (isLivePage) {
      if (privacy === 'public') IconComponent = <div className="ml-1"><EarthGlobalSvgIcon fillColor={colors?.Text} /></div>;
      if (privacy === 'only-me') IconComponent = <div className="ml-1"><LockPostSvgIcon fillColor={colors?.Text} /></div>;
      if (privacy === 'connections' || privacy === 'followers') IconComponent = <div className="ml-1"><ConnectionPostSvgIcon fillColor={colors?.Text} /></div>;
    } else {
      let iconUrl = '';
      if (privacy === 'public') iconUrl = publicIconDark;
      if (privacy === 'only-me') iconUrl = lockIcon;
      if (privacy === 'connections' || privacy === 'followers') iconUrl = connectionIcon;
  
      IconComponent = <img src={iconUrl} alt="" className="w-3 h-3 ml-1" />;
    }
  
    return IconComponent;
  };

  const onClickConnect = async () => {
    await dispatch(setConnectSuggestion(moreInfo.id));
  };

  const onCompanyClick = () => {
    if (!companySlug) return;
    navigate(`/wall/company/${companySlug}`);
  }

  const onClickMessage = async () => {
    await dispatch(closeMessageWindow());
    await dispatch(
      openMessageWindow({
        avatar: moreInfo.avatar!,
        userDisplayName: `${moreInfo.firstName} ${moreInfo.lastName}`,
        userId: moreInfo.id
      })
    );
  };

  const handleCardProfile = () => {
    setShowDetailsTimer(setTimeout(function () {
      setShowDetails(true);
    }, 1000));
    return false;
  };

  const handleCloseCardProfile = () => {
    if (showDetailsTimer) clearTimeout(showDetailsTimer);
    setShowDetails(false);
    return false;
  };

  const postDateFormat = (createdAt: any) => {
    const timeFormat = format(parseISO(createdAt), "dd MMM yyyy 'at' hh:mm aaaa");
    const formateCorrection = timeFormat.replace('.', "").replace('.', "");
    return `${formateCorrection} •`;
  }

  return (
    <div
      className={classNames("flex items-center pl-[21px] pt-[14.9px] mb-[14px]",
        {
          'pl-[9px] pt-[9px] mb-[9px]': isLivePage
        }
      )}
    >
      {/* User avatar on Post */}
      <div>
        <Link to={slug}>
          {
            byCompany ?
              //  Hover over this div to hide/show <CardProfileConnect /> for company
              <HoverableDiv
                handleMouseOver={handleCardProfile}
                handleMouseOut={handleCloseCardProfile}
                children={
                  <>
                    <div
                      className={classNames("w-[49px] max-w-[49px] min-w-[49px] h-[49px] max-h-[49px] min-h-[49px] bg-white flex items-center justify-center rounded-[5px]",
                        {
                          '!bg-[#edf2f5]': avatar?.includes('null'),
                          'w-[37px] min-w-[37px] max-w-[37px] h-[37px] min-h-[37px] max-h-[37px]': isLivePage
                        }
                      )}
                    >
                      <Logo alt={name}
                        className={classNames("object-contain rounded-[5px] w-[47px] min-w-[47px] max-w-[47px] h-[47px] min-h-[47px] max-h-[47px]",
                          {
                            'bg-[#edf2f5]': avatar?.includes('null'),
                            'w-[35px] min-w-[35px] max-w-[35px] h-[35px] min-h-[35px] max-h-[35px]': isLivePage
                          }
                        )}
                        src={avatar}
                        defaultLogo="company"
                      />
                    </div>
                    {showDetails && <CardProfileConnect
                      id={moreInfo.id}
                      firstName={moreInfo.name}
                      lastName=""
                      slug={moreInfo.slug}
                      avatar={`${imageUrlPrefix}/${moreInfo.logo}`}
                      cover={`${imageUrlPrefix}/${moreInfo.cover}`}
                      jobTitle={moreInfo.industry?.name}
                      location={moreInfo.headquarter}
                      className="w-248 absolute z-10"
                      logoClass="!object-contain !rounded-[20px] !border-[4px] bg-white profile-avatar-shadow"
                    />}
                  </>
                }
              />
              :
              // Hover over this div to hide/show <CardProfileConnect /> for user
              <HoverableDiv
                handleMouseOver={handleCardProfile}
                handleMouseOut={handleCloseCardProfile}
                children={
                  <>
                    {moreInfo?.avatar ? isLivePage ? (
                      <Logo alt={name}
                        className={classNames("object-contain rounded-[15px] w-[37px] min-w-[37px] max-w-[37px] h-[37px] min-h-[37px] max-h-[37px]",
                          {
                            'w-[37px] min-w-[37px] max-w-[37px] h-[37px] min-h-[37px] max-h-[37px]': isLivePage
                          }
                        )}
                        src={avatar}
                        defaultLogo="user"
                      />
                    ) : (
                     <Avatar
                      alt={name}
                      size='MEDIUM'
                      src={avatar}
                    />) :
                      <Avatar
                        alt={name}
                        size='MEDIUM'
                        src={profileBackground}
                      />
                    }
                    {showDetails && <CardProfileConnect
                      id={moreInfo.id}
                      firstName={moreInfo.firstName}
                      lastName={moreInfo.lastName}
                      slug={moreInfo.slug}
                      avatar={moreInfo?.avatar ? `${imageUrlPrefix}/${moreInfo?.avatar}` : ""}
                      cover={moreInfo?.cover ? `${imageUrlPrefix}/${moreInfo?.cover}` : ""}
                      jobTitle={moreInfo.jobTitle}
                      location={moreInfo.location}
                      className="w-248 absolute z-50"
                      onClickConnect={onClickConnect}
                      onClickMessage={onClickMessage}
                      isOwnProfile={isOwnProfile}
                    />}
                  </>
                }
              />
          }
        </Link>
      </div>
      {/* User details on a Post */}
      <div
        className={classNames("flex flex-col ml-3 h-[48px]",
          {
            'ml-[8px] h-auto': isLivePage
          }
        )}
      >
        <div className='capitalize leading-[16px]'>
          <Link style={{ color: isLivePage ? colors?.Text : '' }}
          className={classNames("text-[13px] font-medium text-[#000000] capitalize leading-[17px] flex hover:underline",
            {
              'text-[10px] leading-[14px]': isLivePage
            }
          )}
          to={slug}>
            {name}
          </Link>
        </div>
        {companyName && companyName !== '' && companyName !== undefined && jobTitle ? (
          <div className='flex leading-[17px]'>
            <span style={{ color: isLivePage ? colors?.Text : '' }}
              className={classNames("text-[12px] font-normal text-[#000000] leading-[18px]",
                {
                  'text-[9px] leading-[11px]': isLivePage
                }
              )}
              >
              {`${jobTitle} `}
              <span
                className={classNames("hover:underline",
                  {
                    'cursor-pointer': companySlug,
                  }
                )}
                onClick={onCompanyClick}
              >
                {companyName.trim() ? `at ${companyName}` : ''}
              </span>
            </span>
          </div>
        ) : null}
        {!!createdAt && (
          <div className='flex items-center flex-row'>
            <span style={{ color: isLivePage ? colors?.Text : '', opacity: isLivePage ? 0.8 : 1 }}
               className={classNames("text-[11px] font-normal text-[#3C3C3C] leading-[16px]",
                {
                  'text-[8px] leading-[10px]': isLivePage
                }
              )}
               >{postDateFormat(createdAt!)}</span>
            <PrivacyType />
          </div>
        )}
      </div>
    </div>
  );
};
