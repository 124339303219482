import classNames from "classnames";
import { Avatar } from "../../shared-components/V2";
import "./cancel-notification.css";
import { getBrandingColors } from "src/mui/pages/event/live/liveEventStyling";

interface ICancelNotification {
  title: string;
  avatar?: string;
  closeModal?: Function;
  className?: string;
}

export const CancelNotification = (props: ICancelNotification) => {
  const colors = getBrandingColors();
  return (
    <div
      className={classNames(
        "flex fixed top-0 left-0 w-full h-full justify-center item-center",
        {
          [`${props.className}`]: props.className,
        }
      )}
    >
      <div
        className={classNames("bg-white py-4 px-11 rounded-[10px] m-auto", {})}
        style={{ width: 400, border: `1px solid ${colors?.Accent}`, backgroundColor:  colors?.Main || "#ffffff"}}
      >
         <span
          className={classNames("block text-lg text-center", {})}
          style={{
            color: colors?.Text || "#000000"
          }}
          >{props.title}</span>
        {!!props.avatar && (
          <div className="flex flex-col items-center justify-center pb-4 text-blue-800 mt-6">
            <div className="flex-shrink-0 cancel-animation">
              <Avatar src={props.avatar} size="LARGE" />
            </div>
          </div>
        )}
        <div className="pt-4 flex flex-row items-center justify-center">
          <button
            type="button"
            className={classNames(` hover:bg-opacity-50 py-1 px-6 rounded-[5px] mx-2 mt-4`, {})}
            style={{
              color: colors?.Text || "#000000",
              backgroundColor: colors?.Secondary || "#b61010",
            }}
            onClick={() => {
              props.closeModal!();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
