/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
// Import PropTypes
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import {
  Autoplay, EffectFade, Navigation, Pagination
} from 'swiper'; // Import Swiper modules
import { ReactComponent as ArrowLeftIcon } from 'src/assets/images/arrowLeft.svg'; // Adjust path as needed
import { ReactComponent as ArrowRightIcon } from 'src/assets/images/arrowRight.svg'; // Adjust path as needed
import InsightItem from './insight-Item';
import FactorEventOverview from './factor-event-overview';

interface InsightSectionProps {
  speakers: any[];
}

const CustomPrevButton = () => (
  <div className="custom-prev-button absolute md:top-[50%] top-[40%] -mt-[17px] z-10 md:-left-[17px] -left-[10px] xl:-left-[58px] cursor-pointer">
    <ArrowLeftIcon />
  </div>
);

const CustomNextButton = () => (
  <div className="custom-next-button absolute md:top-[50%] top-[40%] -mt-[17px] z-10 md:-right-[17px] -right-[10px] xl:-right-[58px] cursor-pointer">
    <ArrowRightIcon />
  </div>
);

const InsightSection: React.FC<InsightSectionProps> = ({ speakers }) => {
  const swiperRefrence = useRef<any>(null);
  const [inerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    swiperRefrence?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefrence?.current?.swiper?.autoplay?.start();
  };

  const slidesPreviewCount = inerWidth <= 768 ? 'auto' : 1;
  const slidesGap = inerWidth <= 768 ? 10 : 55;
  const isTrueMobileEffects = inerWidth <= 768;
  const slideMaxWidth = inerWidth <= 768 ? 'max-w-[283px]' : 'max-w-full';

  return (
    <div className="w-full" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <FactorEventOverview />
      <div className="md:mb-0 md:mx-0 mx-[15px] mb-[53px] relative">
        <div className="text-white md:leading-[42px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium text-left md:mb-[32px] mb-[23px] flex justify-center md:hidden">Inspiration and insight from:</div>
        <Swiper
          modules={[Pagination, Autoplay, EffectFade, Navigation]}
          navigation={{ prevEl: '.custom-prev-button', nextEl: '.custom-next-button' }}
          pagination={{ dynamicBullets: false, clickable: true }}
          ref={swiperRefrence}
          autoplay={{ delay: 5000, disableOnInteraction: true }}
          className="factorSwiper inspirationSlider"
          spaceBetween={slidesGap}
          slidesPerView={slidesPreviewCount}
          effect="coverflow"
          grabCursor={isTrueMobileEffects}
          centeredSlides={isTrueMobileEffects}
        >
          {speakers.map((item: any, index: number) => (
            <SwiperSlide className={slideMaxWidth} key={index}>
              <InsightItem speaker={item} />
            </SwiperSlide>
          ))}
        </Swiper>
        <CustomPrevButton />
        <CustomNextButton />
      </div>
    </div>
  );
};

export default InsightSection;
