import bannerImage2 from '../../../assets/images/virtual/virtual-banner@2x.png';
import divider from '../../../assets/images/overview/divider.svg';
import logo from '../../../assets/images/overview/panelist-logo-overview.svg';
import polyicon from '../../../assets/images/polygon-icon.svg';
import { ButtonWithTextAndImage } from '../../../shared-components/V2';

export const Banner = () => (
  <div className="w-full">
    <div className="block md:flex">
      <div className="w-screen">
        <img src={bannerImage2} alt="" className="object-cover w-full" />
        <div className="container mx-auto">
          <div className="2xl:ml-5 lg:-mt-288 xl:-mt-392 flex justify-center lg:justify-end mt-10 lg::ml-16 lg:pl-4 lg:pt-4">
            <div className="block">
              <div className="md:flex md:flex-row justify-end mr-16 pr-5">
                <div className="">
                  <img src={logo} alt="" />
                </div>
                <div className="text-xl2 text-dark-blue font-light ml-4 pl-2 pt-1">
                  <img src={divider} alt="" />
                </div>
                <div className="text-lg0  text-dark-blue font-light ml-7 pl-1">
                  Connecting Professionals
                  {' '}
                  <br />
                  {' '}
                  around the world.
                </div>
              </div>
              <div className="md:flex justify-end mr-24 pr-7">
                <div className="text-left lg:text-lg font-extralight text-blue-3 mt-7">
                  Create your events and reach out to your
                  {' '}
                  <br />
                  audience on the world’s most comprehensive
                  {' '}
                  <br />
                  hybrid and virtual events platform.
                </div>
              </div>
              <div className="md:flex justify-end w-8/12 ml-16 2xl:ml-40 2xl:pl-2">
                <div className="mt-6 xl:mr-52">
                  <ButtonWithTextAndImage
                    buttonClasses="bg-transparent hover:bg-gray-7 active:bg-gray-7 border-1 border-blue-3 w-242 h-43 rounded-full"
                    imageUrl={polyicon}
                    text="Create an event"
                    textClasses="font-extralight text-sm3 ml-1 pl-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Banner;
