import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { IAppState } from "../..";
import { IForgotPasswordModel } from "../../../models/user/forgotpassword/forgotpassword";
import { IForgotPasswordResult } from "../../../models/user/forgotpassword/forgotpassword-result";
import { IForgotPasswordState } from "../../../models/user/forgotpassword/forgotpasswordState";
import { UserService } from "../../../services";
import { ForgotPasswordActionTypes } from "./types";

export interface IForgotPasswordSuccessAction {
  type: ForgotPasswordActionTypes.FORGOTPASSWORD_SUCCESS;
  payload: IForgotPasswordResult;
}

export interface ResetIForgotPasswordSuccessAction {
  type: ForgotPasswordActionTypes.RESET_FORGOTPASSWORD;
  payload: null;
}

export interface IForgotPasswordErrorAction {
  type: ForgotPasswordActionTypes.FORGOTPASSWORD_ERROR;
  errorMessage: string;
}

export interface IForgotPasswordProgressAction {
  type: ForgotPasswordActionTypes.FORGOTPASSWORD_PROGRESS;
  loading: true;
}
export interface IForgotPasswordSetEmailAction {
  type: ForgotPasswordActionTypes.SET_EMAIL;
  payload: { email: string };
}

export interface IForgotPasswordGoToNextStepAction {
  type: ForgotPasswordActionTypes.GO_TO_NEXT_STEP;
}

export type ForgotPasswordActions =
  | IForgotPasswordSuccessAction
  | IForgotPasswordErrorAction
  | IForgotPasswordProgressAction
  | IForgotPasswordSetEmailAction
  | ResetIForgotPasswordSuccessAction
  | IForgotPasswordGoToNextStepAction;

export const sendCode: ActionCreator<
  ThunkAction<
    Promise<any>,
    IForgotPasswordState,
    null,
    IForgotPasswordSuccessAction
  >
> = (data: IForgotPasswordModel) => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();

    try {
      let result = await userService.forgotPassword(data);
      dispatch({
        type: ForgotPasswordActionTypes.SET_EMAIL,
        payload: data,
      });
      dispatch({
        type: ForgotPasswordActionTypes.GO_TO_NEXT_STEP,
      });
      dispatch({
        type: ForgotPasswordActionTypes.FORGOTPASSWORD_SUCCESS,
        payload: result,
      });
    } catch (error: any) {
      console.error(error);

      if (error.errorCode === "401") {
        dispatch({
          type: ForgotPasswordActionTypes.FORGOTPASSWORD_ERROR,
          errorMessage: error.message,
        });
      } else if (error.statusCode === 500) {
        dispatch({
          type: ForgotPasswordActionTypes.FORGOTPASSWORD_ERROR,
          errorMessage: "Something went wrong. Please try again!",
        });
      } else {
        dispatch({
          type: ForgotPasswordActionTypes.FORGOTPASSWORD_ERROR,
          errorMessage: error.message,
        });
      }
    } finally {
      dispatch({
        type: ForgotPasswordActionTypes.FORGOTPASSWORD_PROGRESS,
        loading: false,
      });
    }
  };
};

export const reSendCode: ActionCreator<
  ThunkAction<Promise<any>, IAppState, null, IForgotPasswordSuccessAction>
> = (userEmail: string, onSuccessResendCode: any) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const email = userEmail || getState().forgotPasswordEmail.email;
    if (!email) return;
    const userService: UserService = new UserService();
    let data: IForgotPasswordModel = { email: "", code: "", newPassword: "" };
    data.email = email;

    try {
      let result = await userService.forgotPassword(data);

      if (onSuccessResendCode) onSuccessResendCode();

      dispatch({
        type: ForgotPasswordActionTypes.FORGOTPASSWORD_SUCCESS,
        payload: result,
      });
    } catch (error: any) {
      console.error(error);

      if (error.errorCode === "401") {
        dispatch({
          type: ForgotPasswordActionTypes.FORGOTPASSWORD_ERROR,
          errorMessage: error.message,
        });
      } else {
        dispatch({
          type: ForgotPasswordActionTypes.FORGOTPASSWORD_ERROR,
          errorMessage: error.message,
        });
      }
    } finally {
      dispatch({
        type: ForgotPasswordActionTypes.FORGOTPASSWORD_PROGRESS,
        loading: false,
      });
    }
  };
};

export const resetPassword: ActionCreator<
  ThunkAction<Promise<any>, IAppState, null, IForgotPasswordSuccessAction>
> = (
  data: IForgotPasswordModel,
  userEmail: string,
  onSuccessPasswordResest: any
) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const email = userEmail || getState().forgotPasswordEmail.email;
    if (!email) return;
    const userService: UserService = new UserService();
    data.email = email;
    try {
      let result = await userService.resetPassword(data);
      if (onSuccessPasswordResest) onSuccessPasswordResest("Login");
      dispatch({
        type: ForgotPasswordActionTypes.FORGOTPASSWORD_SUCCESS,
        payload: result,
      });

      dispatch({
        type: ForgotPasswordActionTypes.GO_TO_NEXT_STEP,
      });
    } catch (error: any) {
      console.error(error);

      if (error.errorCode === "401") {
        dispatch({
          type: ForgotPasswordActionTypes.FORGOTPASSWORD_ERROR,
          errorMessage: error.message,
        });
      } else if (error.statusCode === 500) {
        dispatch({
          type: ForgotPasswordActionTypes.FORGOTPASSWORD_ERROR,
          errorMessage: "Something went wrong. Please try again!",
        });
      } else {
        dispatch({
          type: ForgotPasswordActionTypes.FORGOTPASSWORD_ERROR,
          errorMessage: error.message,
        });
      }
    } finally {
      dispatch({
        type: ForgotPasswordActionTypes.FORGOTPASSWORD_PROGRESS,
        loading: false,
      });
    }
  };
};
