import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { openMessageWindow } from '../../store/newMessage';
import { toggleUserOnlineStatus } from '../../store/user-status';
import MesssagesTab from './messages-tab';

export interface IChatListProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const ChatList = (props: IChatListProps) => {
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={classNames('inset-0 transform ease-in-out', {
          'transition-opacity opacity-100 duration-500 translate-x-0 w-288 h-560': props.isOpen,
          'transition-all delay-500 opacity-0 translate-x-full fixed': !props.isOpen
        })}>
        <div
          className={classNames(
            'w-288 max-w-lg right-0 absolute h-560 bottom-0 shadow-xs3 delay-400 duration-500 ease-in-out transition-all transform',
            {
              'translate-y-0': props.isOpen,
              'translate-y-full': !props.isOpen
            }
          )}>
          <div className="relative w-288 max-w-lg flex flex-col space-y-6 overflow-y-scroll h-560">
            <div className="fixed z-50 right-0 -top-[9px] w-288 bg-white shadow-md text-gray-2 !rounded-b-none create-post-card">
              <MesssagesTab
                handleNewMessageClick={() =>
                  dispatch(openMessageWindow({ avatar: '', userDisplayName: 'New Message' }))
                }
                handleShowMessagesClick={() => props.setIsOpen(false)}
                handleUserStatusToggle={() => dispatch(toggleUserOnlineStatus())}
              />
            </div>
            <div className="bg-white h-full">{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatList;
