/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UserNavbar } from 'src/components';
import { IAppState } from 'src/store';
import { getCompanyEmployees } from 'src/store/company/company-employees/actions';
import { getCompanyProfilePreview } from 'src/store/company/profile-preview/actions';
import { getContent } from 'src/store/library-content/actions';
import { Divider } from '@mui/material';
import { CompanyService } from 'src/services';
import { Loader } from 'src/shared-components/Loader';
import ProductInfo from './product-info';
import ProductInformation from './product-information';
import ProductRatings from './product-ratings';
import ProductSuggestions from './product-suggestions';
import ProdcutScreenshots from './product-screenshots';

export const CompanyProdcutDetails = () => {
  const dispatch = useDispatch();
  const { companyidorslug, productId } = useParams();

  const { companyProfilePreview } = useSelector(
    (state: IAppState) => state
  );

  // const otherProducts = companyProfilePreview.value?.products?.filter((item: any) => item.id !== productId);
  const otherProducts = companyProfilePreview.value?.products;

  // component states
  const [productDetailsLoading, setProductDetailsLoading] = useState(false);
  const [productDetails, setProductDetails] = useState<any>(null);

  useEffect(() => {
    dispatch(getCompanyProfilePreview(companyidorslug));
    dispatch(getCompanyEmployees(companyidorslug));
  }, [dispatch, companyidorslug]);

  useEffect(() => {
    dispatch(getContent(`where[companyId]=${companyProfilePreview.value?.id}`));
  }, [dispatch, companyProfilePreview.value?.id]);

  useEffect(() => {
    const companyService = new CompanyService();
    const fetchProductData = async () => {
      if (productId && companyProfilePreview.value?.id) {
        setProductDetailsLoading(true);

        try {
          const response = await companyService.getSepcificProduct(companyProfilePreview.value?.id, productId);
          if (response) {
            const productData = response?.data || {};
            setProductDetails(productData);
            setProductDetailsLoading(false);
          }
        } catch (error) {
          setProductDetailsLoading(false);
          console.error('getSpecificCompnayProductData():', error);
        } finally {
          setProductDetailsLoading(false);
        }
      }
    };
    fetchProductData();
  }, [productId, companyProfilePreview]);

  return (
    <div className="bg-gray-1 h-full">
      <UserNavbar />
      {productDetailsLoading && (
      <div className="absolute left-0 top-0 w-full h-[calc(100%--26px)] bg-gray-1 bg-opacity-80 flex items-center justify-center z-20 rounded-20 -mt-[14px]">
        <Loader className="pt-5" key="loader" />
      </div>
      )}
      <div className="lg:container mx-auto lg:w-[1087px] w-full lg:px-0 px-4 md:pb-0 pb-[60px] mt-[30px] mb-[65px]">
        <ProductInfo product={productDetails} />
        <Divider sx={{ my: '20px', color: '#707070' }} />
        {productDetails?.desktopUrls?.length || productDetails?.phoneUrls?.length || productDetails?.tabletUrls?.length ? (
          <>
            <ProdcutScreenshots product={productDetails} />
            <Divider sx={{ my: '20px', color: '#707070' }} />
          </>
        ) : null}
        <ProductInformation product={productDetails} productOwnBy={companyProfilePreview.value?.name || ''} />
        <Divider sx={{ my: '20px', color: '#707070' }} />
        <ProductRatings product={productDetails} />
        <Divider sx={{ my: '20px', color: '#707070' }} />
        { otherProducts?.length ? (
          <ProductSuggestions
            otherProducts={otherProducts}
            productOwnBy={companyProfilePreview.value?.name || ''}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CompanyProdcutDetails;
