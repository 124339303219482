import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Checkbox } from '@mui/material';
import { UserService } from '../../../services';
import { NOTIFICATIONS } from '../../../constants';
import { INotification } from '../../../models/user/account-settings/account-settings';

export const NotificationSettings = () => {
  const [notificationsList, setNotificationsList] = useState<INotification[] | []>([]);

  useEffect(() => {
    (async () => {
      const userService = new UserService();
      const notifications = await userService.getAccountNotifications();
      setNotificationsList([...notifications.data]);
    })();
  }, []);

  const onSaveHandler = async (e: any, item: any) => {
    const currentFields = notificationsList;
    const changedItem = currentFields.filter(f => f.activity === item);
    switch (e.target.name) {
      case 'email':
        changedItem[0].email = e.target.checked;
        break;
      case 'notification':
        changedItem[0].inAppNotification = e.target.checked;
        changedItem[0].pushNotification = e.target.checked;
        break;
      default:
        break;
    }
    changedItem[0].permit = changedItem[0].inAppNotification || changedItem[0].email;
    const data: any = {};
    data.notificationSettings = [];
    data.notificationSettings = currentFields;

    const userService = new UserService();
    try {
      const result = await userService.editAccountNotifications(data);
      toast.success(result.message);
    } catch (e: any) {
      toast.error(e);
    }
  };

  return (
    <div className="max-w-[886.5px] ml-[40px] mt-[5px]" id="notifications">
      <div className="flex flex-col w-full">
        <span className="font-semibold text-[15px] leading-[21px] mb-5 text-[#0B1221]">Notifications</span>
        <div className="border-t border-[#707070] w-full pt-[20px]">
          <div className="flex items-center mb-[15px]">
            <div className="w-4/6 text-[16px] font-light">Type : </div>
            <div className="text-[16px] font-light w-1/6 text-center">Email</div>
            <div className="text-[16px] font-light w-1/6 text-center">Notifications</div>
            <div />
          </div>
          {notificationsList.map((item: INotification) => (
            <div key={item.id} className="flex items-center h-[23px] mb-[15px] last:mb-0">
              <div className=" text-[16px] font-light leading-6 tracking-wide text-[#172746] w-4/6">
                {NOTIFICATIONS[item.activity]}
              </div>
              <div className="w-1/6 flex justify-center">
                <Checkbox defaultChecked={item.email} name="email" style={{ color: 'rgb(0 73 239 / var(--tw-bg-opacity))' }} onChange={e => onSaveHandler(e, item.activity)} />
              </div>
              <div className="w-1/6 flex justify-center">
                <Checkbox
                  defaultChecked={item.inAppNotification}
                  style={{ color: 'rgb(0 73 239 / var(--tw-bg-opacity))' }}
                  name="notification"
                  onChange={e => onSaveHandler(e, item.activity)}
                  className="w-1/6"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
