// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import documentIcon from "src/assets/images/attached-document.svg";
// components
import Iconify from "src/mui/components/iconify";
//
import { useGetMessage } from "./hooks";
import { useCurrentUser } from "src/utils/hooks";
import { IAttendee } from "src/models/events-live/attendee";
import { FunctionComponent, SVGProps, useEffect, useState } from "react";
import { ReactComponent as LikedSvg } from "src/assets/images/post-reactions/like-emoji.svg";
import { ReactComponent as LoveSVG } from "../../../assets/images/post-reactions/love-emoji.svg";
import { ReactComponent as SmileSVG } from "../../../assets/images/post-reactions/smile-emoji.svg";
import { ReactComponent as WellDoneSVG } from "../../../assets/images/post-reactions/well-done-emoji.svg";
import { ReactComponent as HahaSVG } from "../../../assets/images/post-reactions/haha-emoji.svg";
import ReactionButtonsChat from "./reaction-buttons-chat";
import { minifiedImage } from "src/utils";
import { getBrandingColors } from '../../pages/event/live/liveEventStyling';

// ----------------------------------------------------------------------

type Props = {
  messageData: any;
  participants: IAttendee[];
  onOpenLightbox: (value: string) => void;
  onMessageReact: (messageId: string, type: string) => void;
  onMessageReply: (message: any) => void;
  onMessageUpdate: (message: any) => void;
  onMessageDelete: (messageId: string) => void;
  setActiveUserAvatar?: any;
  editMessage?: any;
  showAvatar?: boolean;
  showUsername?: boolean;
};

export default function ChatMessageItem({
  messageData,
  participants,
  onOpenLightbox,
  onMessageReact,
  onMessageReply,
  onMessageUpdate,
  onMessageDelete,
  setActiveUserAvatar,
  editMessage,
  showAvatar,
  showUsername
}: Props) {
  const user = useCurrentUser();
  const colors = getBrandingColors();

  const { me, senderDetails, hasImage, myActiveUser } = useGetMessage({
    messageData,
    participants,
    currentUserId: user?.id,
  });

  const iconMap = {
    likes: LikedSvg,
    hahas: HahaSVG,
    loves: LoveSVG,
    claps: WellDoneSVG,
    smiles: SmileSVG,
  };

  const iconS = ["likes", "hahas", "loves", "claps", "smiles"];

  type ReactionType = "likes" | "hahas" | "loves" | "claps" | "smiles";
  type IconMapType = {
    [key in ReactionType]: FunctionComponent<
      SVGProps<SVGSVGElement> & { title?: string | undefined }
    >;
  };

  const reactions = (
    Object.keys(messageData?.reactions!) as ReactionType[]
  ).map((type) => ({
    type: type.slice(0, -1),
    count: messageData?.reactions![type].length,
    Icon: iconMap[type],
    users: messageData?.reactions![type],
  }));

  useEffect(() => {
    if (myActiveUser) {
      setActiveUserAvatar(myActiveUser.user);
    }
  }, [myActiveUser]);

  type ReactionTypeIcon = typeof iconS[number];
  type IconMapTypeIcon = {
    [key in ReactionType]: FunctionComponent<
      SVGProps<SVGSVGElement> & { title?: string | undefined }
    >;
  };

  // Ensure iconMap matches IconMapType
  const typedIconMap: any = iconMap;

  const { firstName } = senderDetails;
  const renderInfo = (
    <Typography
      noWrap
      variant="caption"
      sx={{
        color: colors?.Text || "#ffffff",
        fontSize: "9px",
        fontFamily: "Poppins-500",
        lineHeight: "13px",
        mb: "-9px",
        ...(!me && {
          mr: "auto",
          mb: "2px",
        }),
        ...(me && {
          mr: "auto",
          mb: "2px",
        }),
      }}
    >
      {`${messageData?.user?.firstName} ${messageData?.user?.lastName}`} &nbsp;
      {/* {formatDistanceToNowStrict(new Date(createdAt), {
        addSuffix: true,
      })} */}
    </Typography>
  );
  function adjustOpacity(color: string, opacityFactor: number) {
    let r, g, b, a = 1;
  
    if (color.startsWith("#")) {
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);
    } else if (color.startsWith("rgba")) {
      const rgbaMatch = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d\.]*)\)/);
      if (rgbaMatch) {
        r = parseInt(rgbaMatch[1]);
        g = parseInt(rgbaMatch[2]);
        b = parseInt(rgbaMatch[3]);
        a = rgbaMatch[4] ? parseFloat(rgbaMatch[4]) : 1; 
      } else {
        return color; 
      }
    } else {
      return color; 
    }
  
    const newAlpha = Math.max(0, Math.min(1, a * opacityFactor));
  
    return `rgba(${r}, ${g}, ${b}, ${newAlpha})`;
  }
  

  const renderBody = (payload: any, isReply?: boolean) => {
    const { message, type } = payload;
    return (
      <>
        <Stack
          key={message.messageId}
          sx={{
            // fontSize: "13px !important",
            // fontFamily: "Poppins-400",
            // lineHeight: "18px",
            "&:hover": {
              "& .message-actions": {
                opacity: 1,
                visibility: 'visible',
              },
            },
            color: colors?.ButtonTextColor! || "#ffffff",
            overflowWrap: 'break-word',
            p: "9px",
            minWidth: 48,
            maxWidth: 250,
            borderRadius: "5px 5px 5px 0px",
            typography: "body2",
            bgcolor: `${!isReply ? colors?.Accent || "#2F2F2F" : colors?.Accent}`,
            // zIndex: 1,
            fontSize: "12px !important",
              fontFamily: "Poppins-400",
              lineHeight: "17px",
            ...(isReply && {
              position: "relative",
              fontSize: "12px !important",
              fontFamily: "Poppins-400",
              lineHeight: "17px",
              // bottom: "-8px",
              zIndex: "0",
              left: "0px",
              bgcolor: `${!isReply ? colors?.Accent ? adjustOpacity(colors.Accent, 0.6) : "rgba(47, 47, 47, 0.6)" : colors?.Accent ? adjustOpacity(colors.Accent, 0.6) : "rgba(47, 47, 47, 0.6)"}`,
            }),
            ...(me && {
              borderRadius: "5px 5px 5px 0px",
              fontSize: "12px !important",
              fontFamily: "Poppins-400",
              lineHeight: "17px",
            }),
            ...(me && isReply && {
              mb: '-8px',
              pb: '20px'
            }),
          }}
        >
          {type === "image" ? (
            <Box>
              {payload.source !== message
                ? message?.replace(/<\/?[^>]+(>|$)/g, "")
                : null}
              <Box
                component="img"
                alt="attachment"
                src={minifiedImage(payload?.source || message)}
                onClick={() => onOpenLightbox(message)}
                sx={{
                  height: 120,
                  borderRadius: 1.5,
                  cursor: "pointer",
                  objectFit: "contain",
                  "&:hover": {
                    opacity: 0.9,
                  },
                }}
              />
            </Box>
          ) : type === "document" ? (
            <>
              {payload.source !== message
                ? message?.replace(/<\/?[^>]+(>|$)/g, "")
                : null}

              <a
                href={
                  `${process.env.REACT_APP_S3_MEDIA_BUCKET}/${payload?.source}` ||
                  `${process.env.REACT_APP_S3_MEDIA_BUCKET}/${payload?.message}`
                }
              >
                <div className="p-2">
                  <div className="flex flex-col items-center rounded-20 p-2 w-[167px]">
                    <div>
                      <img
                        src={documentIcon}
                        alt="attachment"
                        className="w-16 h-16"
                      />
                    </div>
                    <div
                      className="text-sm font-light p-2"
                      style={{ overflowWrap: "anywhere" }}
                    >
                      {payload?.source
                        ? payload?.source.split("/").pop() ||
                          message.split("/").pop()
                        : ""}
                    </div>
                  </div>
                </div>
              </a>
            </>
          ) : (
            message?.replace(/<\/?[^>]+(>|$)/g, "")
          )}
          {renderActions}
        </Stack>

      </>
    );
  };

  const renderActions = (
    <>
      <Stack
        direction="row"
        className="message-actions"
        sx={{
          opacity: 0,
          visibility: 'hidden',
          border: `1px solid ${colors?.Main}`,
          marginBottom: "20px",
          borderRadius: "30px",
          background: colors?.Secondary || "rgb(98, 98, 98)",
          position: "absolute",
          zIndex: 1000,
          transition: (theme) =>
            theme.transitions.create(["opacity"], {
              duration: theme.transitions.duration.shorter,
            }),
            left: "0px",
            top: "60%",
            right: "unset",
          ...(me && {
            left: "0px",
            top: "60%",
            right: "unset",
          }),
        }}
      >
        {iconS.map((item) => {
          const IconComponent = typedIconMap[item];
          return (
            <IconButton
              size="small"
              sx={{p: '3px'}}
              onClick={() => {
                onMessageReact(messageData?.messageId, item.slice(0, -1));
              }}
            >
              <IconComponent width={27} height={27} key={item} />
            </IconButton>
          );
        })}
        <IconButton
          size="small"
          sx={{p: '3px'}}
          onClick={() => {
            if (!editMessage) onMessageReply(messageData);
          }}
        >
          <Iconify color={"#ffffff"} icon="solar:reply-bold" width={25} height={25} />
        </IconButton>

        {user?.id === messageData?.senderId && (
          <>
            <IconButton
              sx={{p: '3px'}}
              size="small"
              onClick={() => {
                if (!editMessage) onMessageUpdate(messageData);
              }}
            >
              <Iconify color={"#ffffff"} icon="eva:edit-fill" width={25} height={25} />
            </IconButton>
            <IconButton
              sx={{p: '3px'}}
              size="small"
              onClick={() => {
                if (!editMessage) onMessageDelete(messageData?.messageId);
              }}
            >
              <Iconify
                color={"#ffffff"}
                icon="solar:trash-bin-trash-bold"
                width={25} height={25}
              />
            </IconButton>
          </>
        )}
      </Stack>
    </>
  );

  return (
    <Stack
      className="parent"
      direction="row"
      sx={{ alignItems: "end", width: "100% !important", mb: showAvatar ? '14px' : '0px' }}
    >
      {showAvatar == true?
        <Avatar
          alt={firstName}
          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${messageData?.user
            ?.avatar!}`}
          sx={{ width: 36, minWidth: 36, height: 36, mr: "0px", borderRadius: "14px" }}
        />
        :
        <div style={{ width: 36, minWidth: 36, height: 36, borderRadius: "14px" }} />
      }

      <Stack sx={{ width: "100% !important", marginTop: "5px", ml:'5px' }}>
        {showUsername && renderInfo}
        <Stack
          direction="row"
          alignItems="center"
          minWidth={"100% !important"}
          sx={{
            position: "relative",
            minWidth: "100% !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
              position: "relative",
            }}
          >
            {messageData.replyToMessage &&
            Object.keys(messageData.replyToMessage)?.length ? (
              <Stack sx={{ mb: '-8px' }} className="repliedMessage">
                {renderBody(messageData.replyToMessage, true)}
              </Stack>
            ) : null}
            {renderBody(messageData)}
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                zIndex: "10",
                bottom: "-11px",
              }}
            >
              {reactions.map(
                ({ type, count, Icon, users }) =>
                  count > 0 && (
                    <ReactionButtonsChat
                      key={type}
                      type={type}
                      count={count}
                      Icon={Icon}
                      users={users}
                      userId={user.id}
                      onClick={() =>
                        onMessageReact(messageData?.messageId, type)
                      }
                    />
                  )
              )}
            </Box>
          </Box>

        </Stack>
      </Stack>
    </Stack>
  );
}
