import { SVGProps } from 'react';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

function CtrlPanelLeftIcon(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props;
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
        <svg id="Page-1" xmlns="http://www.w3.org/2000/svg" width="52.389" height="37.277" viewBox="0 0 52.389 37.277">
            <g id="Dribbble-Light-Preview">
                <g id="icons">
                    <path id="grid-_1526_" data-name="grid-[#1526]"
                          d="M166.878,65.852h3.878a3.788,3.788,0,0,1,3.878,3.693v3.693a3.788,3.788,0,0,1-3.878,3.693h-3.878A3.788,3.788,0,0,1,163,73.239V69.546a3.788,3.788,0,0,1,3.878-3.693m13.572,0h3.878a3.788,3.788,0,0,1,3.878,3.693v3.693a3.788,3.788,0,0,1-3.878,3.693H180.45a3.788,3.788,0,0,1-3.878-3.693V69.546a3.788,3.788,0,0,1,3.878-3.693m13.572,0H197.9a3.788,3.788,0,0,1,3.878,3.693v3.693a3.788,3.788,0,0,1-3.878,3.693h-3.878a3.788,3.788,0,0,1-3.878-3.693V69.546a3.788,3.788,0,0,1,3.878-3.693M166.878,52.926h3.878a3.788,3.788,0,0,1,3.878,3.693v3.693a3.788,3.788,0,0,1-3.878,3.693h-3.878A3.788,3.788,0,0,1,163,60.313V56.619a3.788,3.788,0,0,1,3.878-3.693m13.572,0h3.878a3.788,3.788,0,0,1,3.878,3.693v3.693a3.788,3.788,0,0,1-3.878,3.693H180.45a3.788,3.788,0,0,1-3.878-3.693V56.619a3.788,3.788,0,0,1,3.878-3.693m13.572,0H197.9a3.788,3.788,0,0,1,3.878,3.693v3.693a3.788,3.788,0,0,1-3.878,3.693h-3.878a3.788,3.788,0,0,1-3.878-3.693V56.619a3.788,3.788,0,0,1,3.878-3.693M166.878,40h3.878a3.788,3.788,0,0,1,3.878,3.693v3.693a3.788,3.788,0,0,1-3.878,3.693h-3.878A3.788,3.788,0,0,1,163,47.386V43.693A3.788,3.788,0,0,1,166.878,40m13.572,0h3.878a3.788,3.788,0,0,1,3.878,3.693v3.693a3.788,3.788,0,0,1-3.878,3.693H180.45a3.788,3.788,0,0,1-3.878-3.693V43.693A3.788,3.788,0,0,1,180.45,40m9.695,3.693v3.693a3.788,3.788,0,0,0,3.878,3.693H197.9a3.788,3.788,0,0,0,3.878-3.693V43.693A3.788,3.788,0,0,0,197.9,40h-3.878a3.788,3.788,0,0,0-3.878,3.693"
                          transform="translate(-149.39 -40)" fill={defaultColor} fill-rule="evenodd"/>
                    <rect id="Rectangle_8435" data-name="Rectangle 8435" width="11" height="37" rx="3"
                          transform="translate(0 0.277)" fill={defaultColor}/>
                </g>
            </g>
        </svg>


    );
}

export default CtrlPanelLeftIcon;
