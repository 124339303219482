import { ICommentedConnection } from "../../models/post/post";

interface ICommentedConnectionsProps {
    connections: ICommentedConnection[];
    comment?: string;
    onUserClick: Function;
}

export const CommentedConnections = ({ connections, comment, onUserClick }: ICommentedConnectionsProps) => {
    return (
        <>
            {connections?.map(conn => (
                <div className="w-full border-blue p-2 border-b">
                    <span key={conn.id} className="text-sm h-20">
                        <span onClick={() => onUserClick(conn.slug)} className="font-bold text-blue-1 cursor-pointer hover-span px-1">{`${conn.firstName} ${conn.lastName}`}</span>
                        <span className="text-gray-1 font-light">{comment}</span>
                    </span>
                </div>
            ))}
        </>
    );
}