import mobileapp from '../../../assets/images/hybrid/mobile-app.png';
import mobileapp2 from '../../../assets/images/hybrid/mobile-app@2x.png';
import mobilebg from '../../../assets/images/hybrid/mobile-bg.svg';

export const MobileApp = () => (
  <div>
    <div
      className="pb-2 w-full"
      style={{
        background: `url(${mobilebg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '487px'
      }}
    >
      <div className="block lg:flex container mx-auto w-9/12 2xl:w-full 2xl:justify-center">
        <div className="-ml-9 mt-8 pt-1">
          <img
            className=""
            srcSet={`${mobileapp} 300w, ${mobileapp2} 1000w`}
            sizes="(max-width: 583px) 20vw, (max-width: 583px) 30vw, 500px"
            alt=""
            src={mobileapp2}
          />
        </div>

        <div className="ml-56 pl-3">
          <div className="">
            <div className="-ml-6">
              <div className="lg:text-xl3 font-extralight lg:mt-24 pt-1 text-dark-blue">
                Dedicated mobile app
              </div>
              <div className="text-lg font-extralight text-dark-blue mt-4">
                A dedicated mobile app for your event
              </div>
              <p className="text-md font-extralight text-gray-1 mt-3 ">
                Bring it all together with a mobile app to manage
                {' '}
                <br />
                on-site and virtual attendees.
              </p>
            </div>

            <ul className="list-disc font-extralight text-md text-gray-1 mt-3 leading-none">
              <li>Video calls between attendees</li>
              <li>Meeting scheduling</li>
              <li>Story sharing</li>
              <li>Polling</li>
              <li>Q&amp;A</li>
              <li>On-site check ins</li>
              <li>Notification system</li>
              <li>1:1 messaging</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MobileApp;
