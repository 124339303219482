import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';
import { UserService } from '../../../services';
import { PRIVACY_SETTINGS } from '../../../constants';
import { IPrivacy } from '../../../models/user/account-settings/account-settings';

export const PrivacySettings = () => {
  const [privacyList, setPrivacyList] = React.useState<IPrivacy[] | []>([]);

  useEffect(() => {
    (async () => {
      const userService = new UserService();
      const settings = await userService.getAccountPrivcaySettings();
      setPrivacyList([...settings.data]);
    })();
  }, []);

  const onSaveHandler = async (e: any, can: any) => {
    const currentFields = privacyList;
    const changedItem = currentFields.filter(f => f.can === can);
    changedItem[0].permit = e.target.innerText.toLowerCase();
    const data: any = {};
    data.privacySettings = [];
    data.privacySettings = currentFields;
    const userService = new UserService();
    try {
      const result = await userService.editAccountPrivcaySettings(data);
      toast.success(result.message);
    } catch (e: any) {
      toast.error(e);
    }
  };

  return (
    <div className="max-w-[886.5px] ml-[40px] mt-[37px]">
      <div className="flex flex-col w-full" id="privacy-settings">
        <span className="font-semibold text-[15px] leading-[21px] mb-5 text-[#0B1221]">Privacy settings</span>
        <div className="border-t border-[#707070] w-full pt-[20px]">
          {privacyList.map((item: IPrivacy) => (
            <div key={item.id} className="flex items-center pb-[20px] last:pb-0">
              <span className="flex-1 flex flex-col tracking-wide text-[#172746] text-base font-normal">
                {PRIVACY_SETTINGS[item.can]}
              </span>
              <FormControl className="inline-flex justify-center w-[410px]">
                <Select
                  className="privacy-select-field"
                  id="privacy-setting-select"
                  defaultValue={item.permit}
                >
                  {item.defaultValues.map((val: string) => (
                    <MenuItem className="capitalize" key={val} value={val} onClick={e => onSaveHandler(e, item.can)}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrivacySettings;
