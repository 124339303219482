import { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';
import { IEvent } from 'src/models/user-events';
import { getMinifiedImage } from 'src/mui/utils/image';
// import { init } from '../../../store/event-website';
import { IAppState } from '../../../store';
// import { useCurrentUser } from '../../../utils/hooks';

// import { WebsiteEventPreview } from './website-event-preview';
import { WebsiteEventMainNavbar } from './website-event-navbar';
import { Loader } from '../../../shared-components/Loader';

import './index.css';
import { WebsiteEventPreviewSpectre } from './website-event-preview-spectre';

const menuItems = [
  {
    menuItemText: 'Banner',
    href: '#Banner',
    isSelected: true,
  },
  {
    menuItemText: 'Speakers',
    href: '#Speakers',
    isSelected: false,
  },
  {
    menuItemText: 'Agenda',
    href: '#Agenda',
    isSelected: false,
  },
  {
    menuItemText: 'General',
    href: '#General',
    isSelected: false,
  },
  {
    menuItemText: 'Partners',
    href: '#Partners',
    isSelected: false,
  },
  {
    menuItemText: 'Contact us',
    href: '#Contact us',
    isSelected: false,
  },
];
export const WebsiteEventPageSpectre = () => {
  // const params = useParams();

  // const eventId: string = params?.slug!;
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  // const user = useCurrentUser();
  // const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState('Banner');

  // useEffect(() => {
  //   dispatch(init(eventId));
  // }, [eventId, dispatch]);

  useEffect(() => {
    const link = document.querySelector('link[rel="icon"]');
    if (link) {
      link?.setAttribute('href', `${getMinifiedImage(event?.company?.logo!)}`);
      link.setAttribute('sizes', '16x16'); // Specify the favicon size (e.g., 16x16 pixels)
    }
  }, [event]);

  const loaded = useSelector((state: IAppState) => state.website.loaded);

  return loaded ? (
    <div
      className="bg-[#000000]"
    >
      <Helmet>
        <title>{event?.name}</title>
      </Helmet>
      <WebsiteEventMainNavbar setActiveMenu={setActiveMenu} activeMenu={activeMenu} menuItems={menuItems} showCreateEventButton />
      <WebsiteEventPreviewSpectre />
    </div>
  ) : <Loader isLoaderLogo className="h-[100vh]" key="loader" />;
};

export default WebsiteEventPageSpectre;
