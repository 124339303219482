import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IUserProfile } from '../../models/user-profile';
import { Avatar } from '../../shared-components/V2';
import { NoEvents } from '../events/event-tab/components/no-events';
import { IAppState } from '../../store';

type IPeopleListCardProps = {
  people: IUserProfile[];
  onMessage?: Function;
  onConnect?: Function;
  onClick: Function;
  isDisabledConnect?: boolean;
  onClickPeople?: ((c: any) => void) | undefined;
};

export const PeopleListCard = (props: IPeopleListCardProps) => {
  const navigate = useNavigate();
  const { currentUser: user } = useSelector((state: IAppState) => state.auth);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props?.people?.length > 0
        ? props.people.map((item: IUserProfile) => (
          <div
            key={`search-people-${item.id}`}
            className="flex sm:flex-row flex-col items-center justify-between border-b-1 border-[#CDDCF2] py-0 pb-3 sm:pb-0 bg-white"
          >
            <div className="flex items-center pb-[20px] pt-[20px] text-blue-800 w-full">
              <div
                className="flex-shrink-0 cursor-pointer"
                onClick={() => {
                  setTimeout(() => {
                    navigate(`/wall/${item.slug}`);
                  }, 1000);
                  props?.onClickPeople?.(item);
                }}
              >
                <Avatar
                  size="MEDIUM"
                  src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item.avatar}`}
                  alt=""
                />
              </div>
              <div onClick={() => props?.onClickPeople?.(item)} className="flex flex-col items-start pl-4">
                <span className="text-[14px] leading-5 font-semibold text-[#172746] cursor-pointer" onClick={() => props.onClick(item)}>
                  {`${item.firstName} ${item.lastName}`}
                </span>
                <span className="text-[12px] leading-[18px] font-medium text-[#172746] cursor-pointer">{item.jobTitle}</span>
                <span className="text-[12px] leading-[18px] font-NORMAL text-[#172746] cursor-pointe">{item.location}</span>
              </div>
            </div>
            <div
              className={classNames('flex gap-3', {
                hidden: item?.id === user?.id
              })}
            >
              <button
                type="button"
                className="bg-gray-200 text-blue text-sm font-semibold px-3 py-1 rounded-[5px]"
                onClick={() => props.onMessage && props.onMessage(item)}
              >
                Message
              </button>
              {
                !item.connectionId && !props.isDisabledConnect && (
                  <button
                    type="button"
                    className={classNames('bg-[#0049EF] hover:bg-[#2e6eff] text-white text-sm font-semibold px-3 py-1 rounded-[5px]', {
                      'bg-[#0049EF]': item.sentRequest === 1
                    })}
                    disabled={item.sentRequest === 1}
                    onClick={() => props.onConnect && props.onConnect(item.id)}
                  >
                    {item.sentRequest === 1 ? 'Pending' : 'Connect'}
                  </button>
                )
              }
            </div>
          </div>
        )) : <NoEvents />}
    </>
  );
};

export default PeopleListCard;
