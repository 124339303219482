import { HyperLink } from '../../../shared-components';

const FactorEventFooter = () => (
  <div className="z-50 inset-x-0 bottom-0 text-center justify-items-start mt-[8px] mb-[20px]">
    <div className="md:max-w-[99%] max-w-[99%] justify-items-start mx-auto lg:flex lg:items-center lg:justify-between justify-start">
      <div className="flex items-left justify-center lg:justify-start lg:mb-0 mb-[9px]">
        <div className="text-[15px] text-white leading-[20px] !font-medium helvetica-neue-65-medium">
          Copyright ©
          {' '}
          {new Date().getFullYear().toString()}
          {' '}
          Factor. All rights
          reserved.
        </div>
      </div>
      <div className="font-normal flex flex-row flex-wrap items-start justify-items-start gap-x-[4px] lg:gap-y-[4px] justify-start">
        <HyperLink
          // classes="font-normal text-[16px] leading-[20px] text-[#ffffff] hover:underline font-normal helvetica-neue-45-light"
          variant="FooterLink"
          text="Privacy Policy"
          href="https://www.factorr.com/privacy-policy"
          target="_blank"
        />
        <div className="mx-5px text-[11px] text-white">|</div>
        <HyperLink
          // className="font-normal text-[16px] leading-[20px] text-white hover:underline helvetica-neue-45-light"
          variant="FooterLink"
          text="Use of Cookies"
          href="https://www.factorr.com/privacy-policy"
          target="_blank"
        />
        <div className="mx-5px text-[11px] text-white">|</div>
        <HyperLink
          // classes="font-normal text-[16px] leading-[20px] text-[#ffffff] hover:underline font-normal helvetica-neue-45-light"
          variant="FooterLink"
          text="Sales & Refunds"
          href="https://www.factorr.com/privacy-policy"
          target="_blank"
        />
        <div className="mx-[5px] text-[11px] text-white">|</div>
        <HyperLink
          // classes="font-normal text-[16px] leading-[20px] text-[#ffffff] hover:underline font-normal helvetica-neue-45-light"
          variant="FooterLink"
          text="Terms & Conditions"
          href="https://www.factorr.com/terms-conditions"
          target="_blank"
        />
      </div>
    </div>
  </div>
);

export default FactorEventFooter;
