import { Typography, Stack, Button } from "@mui/material";
import offer from "../../../../assets/images/signup/tick-done.svg";
import useStyling from "src/mui/components/hook-form/useStyling";

type Props = {
  setOpenForm?: (value: boolean) => void
}

function RegistrationUnderReview({ setOpenForm }: Props) {
  const { secondaryFontColor } = useStyling(null, null)
  return (
    <Stack
      sx={{
        direction: "row",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "90%",
        '@media (max-width: 768px)': {
          margin: "35px",

        },

      }}
    >
      <>
        <img
          src={offer}
          className="flex justify-center mobile-tick"
          alt="Close"
          style={{ height: "70px", width: "70px" }}
        />
        <Typography sx={{ mt: 2, color: secondaryFontColor, fontFamily: "Poppins-600" }}>Done!</Typography>
        <Typography sx={{ mt: 2, color: secondaryFontColor, fontFamily: "Poppins-500" }}>
          {" "}
          Your registration is under review!
        </Typography>
        <Typography sx={{ mt: 0.5, color: secondaryFontColor, textAlign: "center", fontFamily: "Poppins-500" }}>
          We'll notify you once your registration has been accepted!
        </Typography>
        {/* <Button
          variant="contained"
          sx={{
            mt: 4,
            display: "flex",
            direction: "row",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            bgcolor: "text.primary",
            color: secondaryFontColor || "light",
            "&:hover": {
              bgcolor: "text.primary",
              boxShadow: "none",
            },
          }}
          onClick={() => { if (setOpenForm) setOpenForm(false) }}
        >
          Close
        </Button> */}
      </>
    </Stack>
  );
}

export default RegistrationUnderReview;
