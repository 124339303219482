import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

interface ITimer {
  initialSeconds: number,
  onTimeout: ()=>void,
  isPlaying?: boolean,
  size?: number,
  children: JSX.Element
}
const Timer = (props: ITimer) => {
  const {
    initialSeconds = 0, onTimeout, size = 144, isPlaying = true
  } = props;
  const colors = getBrandingColors();
  const progressColor: any = colors?.Accent ? colors?.Accent : "#004777";
  return (
    <>
      <CountdownCircleTimer
        isPlaying={isPlaying}
        duration={(initialSeconds)}
        colors={progressColor}
        isSmoothColorTransition
        size={size}
        onComplete={onTimeout}
      />
      <div className="absolute">{props.children}</div>
    </>
  );
};

export default Timer;
