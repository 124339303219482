import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

import arrowDownIcon from '../../../../assets/images/website-event/arrow-down.svg';
import lockIcon from '../../../../assets/images/lock.svg';
import publicIcon from '../../../../assets/images/event-landing-pre-event/earth-globe.svg';
import connectionIcon from '../../../../assets/images/event-landing-pre-event/connection.svg';
import { POST_ACTIONS } from '../../../../constants';
import { IEditPost } from '../../../../models/post/edit-post';
import { IUserProfile } from '../../../../models/user-profile';
import { IEvent } from '../../../../models/event/event';
import { createUpcomingEventPost } from '../../../../store/events/upcoming-event-create-post/actions';

import { Typography } from '../../../../shared-components';

import { imageUrlPrefix } from '../../../../utils';
import { Avatar } from '../../../../shared-components/V2';
import { EventCoverAndLogo } from '../../../../components/event-cover-and-logo';

interface IShareEvent {
  event: IEvent;
  user: IUserProfile;
  onCloseShare?: () => void;
}
const ShareEvent = ({ event, user, onCloseShare }: IShareEvent) => {
  const [isDisplayName, hasDisplayName] = useState(false);
  const [displayPrivacyStatusMenu, setDisplayPrivacyStatusMenu] = useState(false);
  const [privacyOfPost, setPrivacyOfPost] = useState<string>('Connections');
  const [iconPrivacyOfPost, setIconPrivacyOfPost] = useState<string>(connectionIcon);
  const [name, setName] = useState<string>(user.fullName);

  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm<IEditPost>();

  // eslint-disable-next-line no-shadow
  const handleNameOfPost = (name: string) => {
    setName(name);
    hasDisplayName(!isDisplayName);
  };

  const handlePrivacyOfPost = (privacy: string, icon: string) => {
    setPrivacyOfPost(privacy);
    setIconPrivacyOfPost(icon);
    setDisplayPrivacyStatusMenu(!displayPrivacyStatusMenu);
  };

  const handleSharePost = (data: IEditPost) => {
    const dataToSubmit = {
      ...data,
      format: 'shared_event',
      location: 'profile',
      privacy: privacyOfPost.toLowerCase() === 'only me' ? 'only-me' : privacyOfPost.toLowerCase(),
      source: event.id,
      wallId: user.id
    };

    dispatch(createUpcomingEventPost(dataToSubmit));
    toast.success(POST_ACTIONS.SHARE_POST_SUCCESS);
    onCloseShare!();
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(handleSharePost)}>
        <div className="text-[#0B1221] font-medium text-[18px] leading-[26px] border-b-1 border-[#CDDCF2] pb-[9px] mx-[18px]">Share</div>
        {/* <div className="border-1" /> */}
        <div className="flex flex-col px-4 py-2">
          <div className="flex flex-row mt-2">
            <div className="mr-2">
              <Avatar
                src={`${imageUrlPrefix}/${user.avatar}`}
                size="MEDIUM"
              />
            </div>
            <div className="mr-2 mt-2">
              <button
                type="button"
                className="menu focus:outline-none focus:shadow-solid flex flex-row bg-gray-200 justify-between items-center px-3.5 text-center rounded-[5px] p-2 text-xs"
                onClick={() => hasDisplayName(!isDisplayName)}
              >
                {name}
                <img src={arrowDownIcon} className="ml-2" alt="" />
              </button>
              {isDisplayName && (
                <div className="bg-white rounded-5 shadow-xs2 text-gray-13 mt-1 w-auto absolute hover:bg-gray-1 mx-2">
                  <ul className="px-4 py-2 text-base">
                    <li className="flex flex-row text-xs">
                      <button type="button" onClick={() => handleNameOfPost(user.fullName)}>
                        {name}
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="mt-2">
              <button
                type="button"
                className="flex flex-row justify-between items-center px-3.5 bg-gray-200 text-center rounded-[5px] p-2 text-xs"
                onClick={() => setDisplayPrivacyStatusMenu(!displayPrivacyStatusMenu)}
              >
                <img src={iconPrivacyOfPost} alt="" className="w-4 h-4 mr-2" />
                {' '}
                {privacyOfPost}
                <img src={arrowDownIcon} className="ml-2" alt="" />
              </button>
              {displayPrivacyStatusMenu && (
                <div className="bg-white border-2 border-gray-1 rounded-md mt-1 ml-1 w-auto absolute z-30">
                  <ul className="px-2 py-2 text-base">
                    <li className="flex flex-row text-xs">
                      <img src={lockIcon} alt="" className="w-4 h-4 mr-2 mt-1" />
                      <button type="button" onClick={() => handlePrivacyOfPost('Only Me', lockIcon)}>
                        Only Me
                      </button>
                    </li>
                    <li className="flex flex-row text-xs">
                      <img src={publicIcon} alt="" className="w-4 h-4 mr-2 mt-1" />
                      <button type="button" onClick={() => handlePrivacyOfPost('Public', publicIcon)}>
                        Public
                      </button>
                    </li>
                    <li className="flex flex-row text-xs">
                      <img src={connectionIcon} alt="" className="w-4 h-4 mr-2 mt-1" />
                      <button type="button" onClick={() => handlePrivacyOfPost('Connections', connectionIcon)}>
                        Connections
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="mb-4">
            <textarea
              rows={4}
              className="w-full px-2 my-2 text-md font-light overflow outline-none"
              placeholder="Type here..."
              {...register('content')}
            />
          </div>
          {/* TODO: Move this code to shared components. Design is duplicated in Home, Events and share post. */}
          <div className="w-full shadow-lg p-1 rounded-lg border border-slate-300 text-xs">
            <div className="relative rounded overflow-hidden">
              <Link to={`/event/${event.slug}/discussion`}>
                <EventCoverAndLogo
                  cover={`${event.cover}`}
                  logo={`${event?.company?.logo}`}
                  logoClassName="mt-4"
                />
                <div className="absolute w-full py-2.5 bottom-0 inset-x-0 bg-blue-400 text-black text-left px-6 bg-white">
                  <Typography variant="ContainerHeading" text={event.name} />
                  <Typography
                    variant="SmallTitle"
                    text={`
                  ${event?.eventRegions?.map((loc: any) => (loc.name))}
                   • ${event?.company?.name}`}
                    classes="capitalize"
                  />
                  <Typography variant="SmallTitle" text={`${format(parseISO(event.startTime), 'dd MMM yyyy')} ● ${format(parseISO(event.endTime), 'HH:mm aaaa')}`} />
                  <Typography variant="SmallTitle" text={`${event.format} event`} classes="capitalize" />
                </div>
              </Link>
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <button type="submit" className="rounded-[5px]  inline-block text-sm font-semibold h-8 px-6 py-1.5 bg-[#0049EF] hover:bg-[#2e6eff] text-white w-36">
              Share Post
            </button>
          </div>
        </div>

      </form>
    </div>
  );
};

export default ShareEvent;
