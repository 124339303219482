
import notFound from '../../assets/images/spotlight-img.svg';

export const EventNotFound = () => {
  return (
    <div className="h-[100vh] flex items-start w-full mx-auto">
      <div className="h-[400px] m-auto">
        <img src={notFound} alt="" className="mx-auto pt-[45px]" />
        <div className="text-center text-lg">
        This event is no longer valid. Please contact the administrator.
          <br />
        </div>
      </div>
    </div>
  );
};
