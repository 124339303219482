import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';

Chart.register(ArcElement);

export interface ProgressBarProps {
  data: {
      datasets: any[]
  };
  height?: string;
  width?: string;
}
export const ProgressBar = ({ data, height, width }:ProgressBarProps) => <Doughnut data={data} style={{ height, width }} />;
