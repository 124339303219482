import './styles.css';

import { useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as HahaSVG } from '../../../assets/images/post-reactions/haha-emoji.svg';
import { ReactComponent as LikeSVG } from '../../../assets/images/post-reactions/like-emoji.svg';
import { ReactComponent as LoveSVG } from '../../../assets/images/post-reactions/love-emoji.svg';
import { ReactComponent as SmileSVG } from '../../../assets/images/post-reactions/smile-emoji.svg';
import { ReactComponent as WellDoneSVG } from '../../../assets/images/post-reactions/well-done-emoji.svg';
import { useBoolean } from '../hooks';
import ActionsSVG from './actions-svg';
import emojisMap from './emojiMap';
import { TEmoji } from './types';
// import likeEmoji from 'src/assets/images/post-reactions/like-emoji.svg';
// import clapEmoji from 'src/assets/images/post-reactions/well-done-emoji.svg';
import { useParams } from 'react-router-dom';
import { Typography } from '../../../shared-components';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';
import { LikeSvgIcon } from 'src/shared-components/V2/IconSvg/LikeSvgIcon/LikeSvgIcon';

interface IActionsReactProps {
  defaultSelected?: TEmoji | { (): TEmoji };
  text?: string;
  onClick?: Function
  className?: string;
  textClasses?: string;
  smallSize?: boolean;
  isCompanyNew?: boolean;
  isLivePage?: boolean;
}

export const ActionsReact = ({
  defaultSelected = 'default',
  text,
  onClick = () => { },
  className,
  textClasses,
  smallSize,
  isCompanyNew,
  isLivePage
}: IActionsReactProps) => {
 const iconSize = smallSize ? 25 : 40;
  const colors = getBrandingColors();
  const params = useParams();
  const [selected, setSelected] = useState<TEmoji>(defaultSelected);
  const [isOpen, setOpenTrue, setOpenFalse] = useBoolean();
  const [isEntered, setEnteredTrue, setEnteredFalse] = useBoolean();
  const [isExited, setExitedTrue, setExitedFalse] = useBoolean();
  
  const renderIcon = () => {
    if (defaultSelected !== 'none') {
      if (selected === defaultSelected && isLivePage) {
        return <LikeSvgIcon fillColor={colors?.Text}/>;
      }
      const Icon = emojisMap[selected].icon;
      return <Icon
        className={classNames("w-5 h-5", {
          "!w-[14px] !h-[14px]": isLivePage
        })}
        />;
    }
  };

  // Called when user click on any like icon from popup
  const onClickHandler = (reaction: TEmoji) => {
    setSelected(reaction);
    onClick(reaction);
  }

  const renderReactions = () => (
    <div className={classNames("absolute left-5 flex justify-center items-start h-49", {
      [`${className}`]: className,
      "-mt-18": !className?.includes('-mt-'),
      "-mt-[3.8rem] left-[23px]": isLivePage
    })}>
      <div
        className={classNames(
          `bg-white shadow rounded-full grid grid-flow-col px-2 gap-2 animate-mount h-49 ${smallSize ? 'pt-3' : 'py-1'}`,
          {
            'animate-mount': isEntered,
            'animate-unmount': isExited
          }
        )}
        style={{zIndex: "10"}}
      >
        {/*  hover:scale-125 is not working in the tailwind.css*/}
        <ActionsSVG
          text="Like"
          id="like"
          svg={<LikeSVG className="animate-emoji animate-rotate hover:scale-125" width={iconSize} height={iconSize} />}
          handleClick={onClickHandler}
        />
         <ActionsSVG
          text="Love"
          id="love"
          svg={<LoveSVG className="animate-emoji animate-grow hover:scale-125" width={iconSize} height={iconSize} />}
          handleClick={onClickHandler}
        />
        <ActionsSVG
          text="Celebrate"
          id="clap"
          svg={<WellDoneSVG className="animate-emoji animate-rotate hover:scale-125" width={iconSize} height={iconSize} />}
          handleClick={onClickHandler}
        />
        <ActionsSVG
          text="Haha"
          id="haha"
          svg={<HahaSVG className="animate-emoji animate-rotate hover:scale-125" width={iconSize} height={iconSize} />}
          handleClick={onClickHandler}
        />
         <ActionsSVG
          text="Smile"
          id="smile"
          svg={<SmileSVG className="animate-emoji animate-rotate hover:scale-125" width={iconSize} height={iconSize} />}
          handleClick={onClickHandler}
        />
      </div>
    </div>
  );

  const renderWhenSelected = () => (
    <>
      <div className="flex items-center"
        onClick={() => {
          if (smallSize) return;
          if (selected === "default") {
            // Set to Like : default
            onClick('like');
            setSelected("like")
          } else {
            // Unset the reaction
            onClick(undefined);
            setSelected("default")
          }
        }}>
        {!text && renderIcon()}
        <span
          style={{ color: isLivePage ? colors?.Text || 'inherit' : 'inherit' }}
          className={classNames('text-[14px]  font-medium leading-[14px]', {
            'text-red': emojisMap[selected].color === 'red',
            'text-orange-2': emojisMap[selected].color === 'yellow',
            'text-blue-3': emojisMap[selected].color === 'blue' && !isLivePage,
            'pl-2': !text,
            // 'text-[#656565]': !isLivePage,
            '!text-[9px] !ml-[4px] pl-0 !leading-[8px]': isLivePage,
            [`${textClasses}`]: textClasses

          })}
          
        >
          {
            smallSize ? renderIcon() :
              !!text && !selected ? <Typography style={{ color: isLivePage ? colors?.Text || 'inherit' : 'inherit' }} classes="p-2" variant="H3" text={text} /> : `${emojisMap[selected].name}`
          }
        </span>
      </div>
    </>
  );

  return (
    <div className="rounded">
      <button
        onMouseEnter={() => {
          setOpenTrue();
          setEnteredTrue();
        }}
        onMouseLeave={() => {
          setEnteredFalse();
          setExitedTrue();
          setTimeout(() => {
            setOpenFalse();
            setExitedFalse();
          }, 200);
        }}
        className={classNames({
          'flex items-center justify-center gap-2 rounded md:px-2 px-0 w-auto lg:w-[160px] h-[31px]': !text,
          'bg-[#EDF2F5] rounded-[5px]': isOpen && !text,
          'no-underline hover:underline cursor-pointer': !!text,
          "!w-[90px]": isCompanyNew,
          "!w-auto !bg-opacity-[0.1] !pl-[5px] !pr-[0px] !h-[13px] gap-[4px]": isLivePage,

        })}
      >
        {selected && renderWhenSelected()}
        {isOpen && renderReactions()}
      </button>
    </div>
  );
};

export default ActionsReact;
