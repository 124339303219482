import {
  createSlice,
  Dispatch,
  PayloadAction,
  current,
} from "@reduxjs/toolkit";

import { IRoom } from "../../models/event/room";
import {
  acceptConnectionRequest,
  newConnectionRequest,
  rejectConnectionRequest,
} from "../connection-requests";
import {
  addNewAttendee,
  removeAttendee,
  setActiveRoom,
  setAttendeeStatus,
  setBroadcastResponse,
  setLiveEventResponse,
  setNewAttendees,
} from "../liveEvent";
import { newLivePoll } from "../livePoll";
type Conversations = any;
type StartAndStopTyping = any;
type NewMessages = any;

type UnseenMessage = {
  conversationId: string;
  total: number;
};
type UserNotify = {
  unseenMessages: UnseenMessage[];
  userId: string;
};

type UserNotification = {
  id: string;
  userId: string;
  isSeen: boolean;
};
type ConversationData = {
  conversationId: string;
  latestMessage: string;
  latestMessageDetails: any;
  participantProfiles: any[];
  participants: string[];
  replyDate: number;
  type: string;
};

type MessagesWithDetail = {
  action: string;
  conversationId: string;
  data: any;
  user: any;
};

type OneToOneMessages = {
  [x: string]: {
    data: any;
    user: any;
    count: any;
    lastEvaluateKey: any;
    fetchMore?: boolean;
    isNewMessage?: boolean;
  };
};

type MessageId = string;

type LiveMessage = {
  eventRoomId: string;
  messageId: string;
  user: {
    firstName: string;
    lastName: string;
    avatar: string;
  };
  message: string;
  sender: string;
  type: string;
};
export type LiveMessages = {
  Items: Message[];
  LastEvaluatedKey: LastEvaluatedKey;
  Count: number;
  ScannedCount: number;
};

export type Message = {
  messageId: string;
  sender: string;
  message: string;
  senderId: string;
  avatar?: string;
  type?: string;
};

export type LastEvaluatedKey = {
  eventId: string;
  messageId: string;
  replyDate: number;
};

type CheckUserOnlineResult = {
  active: boolean;
  userId: string;
};

type CheckUserOnlineResponse = {
  checkedResult: CheckUserOnlineResult[];
};

type UpdatedConversationResponse = {
  conversationId: string;
  latestMessage: string;
  latestMessageDetails: any;
  participantProfiles: any[];
  replyDate: number;
  type: string;
};

type BroadcastResponse = {
  action: string;
  instance: any;
  message: string;
};

type LiveEventResponse = {
  eventId: string;
  eventSessionId?: string;
  raiseHand: boolean;
  totalAttendee: number;
  user: any;
  userId: string;
};

export type PrivateChatState = {
  conversations: Conversations | null;
  isTyping: StartAndStopTyping | null;
  userNotify: UserNotify | null;
  userNotification: UserNotification | null;
  newMessages: NewMessages[];
  updateMessage: Message | null;
  oneToOneMessages: OneToOneMessages | null;
  messagesWithDetail: MessagesWithDetail[];
  focusMessage: MessageId;
  activeRoom: IRoom | undefined;
  eventChatMessages: LiveMessages;
  checkConversationsResponse: ConversationData | null;
  checkUserOnline: CheckUserOnlineResponse;
  updatedConversation: UpdatedConversationResponse | null;
  failedMessages: NewMessages[];
};

const initialState: PrivateChatState = {
  conversations: null,
  isTyping: {},
  userNotify: null,
  userNotification: null,
  updateMessage: null,
  newMessages: [],
  oneToOneMessages: null,
  messagesWithDetail: [],
  focusMessage: "",
  activeRoom: undefined,
  eventChatMessages: {
    Items: [],
    LastEvaluatedKey: {} as LastEvaluatedKey,
    Count: 0,
    ScannedCount: 0,
  },
  checkConversationsResponse: null,
  checkUserOnline: { checkedResult: [] },
  updatedConversation: null,
  failedMessages: [],
};

export const privateChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setConversations: (state, action: PayloadAction<Conversations>) => {
      if (!action.payload.totalPages || action.payload.currentPage === 0) {
        state.conversations = action.payload;
      } else {
        const { Items, ...rest } = action.payload;
        state.conversations = {
          Items: state.conversations.Items.concat(Items),
          ...rest,
        };
      }
    },
    setStartAndStopTyping: (
      state,
      action: PayloadAction<StartAndStopTyping>
    ) => {
      if (action.payload.action === "receive-start-typing") {
        state.isTyping = {
          id: action.payload.data.conversationId,
          status: true,
        };
      }
      if (action.payload.action === "receive-stop-typing") {
        state.isTyping = {
          id: action.payload.data.conversationId,
          status: false,
        };
      }
    },
    seenMessage: (state, action: PayloadAction<any>) => {
      const conversationId = action.payload?.conversationId! as string;
      const messageId = action.payload?.messageId! as string;
      const existingData = state.oneToOneMessages?.[conversationId]?.data || [];
      const updatedData = existingData.map((item: any) =>
        item.messageId === messageId ? { ...item, ...action.payload } : item
      );
      const payload = {
        data: updatedData,
        count: state.oneToOneMessages?.[conversationId]?.count!!,
        lastEvaluateKey:
          state.oneToOneMessages?.[conversationId]?.lastEvaluateKey!!,
        user: state.oneToOneMessages?.[conversationId]?.user!!,
        fetchMore: false,
      };

      state.oneToOneMessages = {
        ...state.oneToOneMessages,
        [conversationId]: payload,
      };
    },
    setUserNotify: (state, action: PayloadAction<UserNotify>) => {
      state.userNotify = action.payload;
    },
    setNotification: (state, action: PayloadAction<UserNotification>) => {
      state.userNotification = action.payload;
    },
    setNewMessages: (state, action: PayloadAction<NewMessages>) => {
      // state.newMessages.push(action.payload);
      const conversationId = action.payload?.conversationId! as string;
      const item = action.payload;

      const existingData = state.oneToOneMessages?.[conversationId]?.data || [];

      const payload = {
        data: [...existingData, item],
        count: state.oneToOneMessages?.[conversationId]?.count!!,
        lastEvaluateKey:
          state.oneToOneMessages?.[conversationId]?.lastEvaluateKey!!,
        user: state.oneToOneMessages?.[conversationId]?.user!!,
        fetchMore: false,
        isNewMessage: true,
      };

      // Update the state with the new payload
      state.oneToOneMessages = {
        ...state.oneToOneMessages,
        [conversationId]: payload,
      };
    },
    setMessagesWithDetail: (
      state,
      action: PayloadAction<MessagesWithDetail>
    ) => {
      const conversationId = action.payload.conversationId! as string;
      const items = action.payload.data?.Items?.reverse() || [];

      const existingData = state.oneToOneMessages?.[conversationId]?.data || [];

      const payload = {
        data: [...items, ...existingData],
        count: action.payload.data?.Count,
        lastEvaluateKey: action.payload.data?.LastEvaluatedKey,
        user: action.payload.user,
        fetchMore: existingData?.length ? false : true,
      };

      // Update the state with the new payload
      state.oneToOneMessages = {
        ...state.oneToOneMessages,
        [conversationId]: payload,
      };
    },
    setFocusMessage: (state, action: PayloadAction<MessageId>) => {
      state.focusMessage = action.payload;
    },

    handleDeleteLiveMessage: (state, action: PayloadAction<any>) => {
      state.eventChatMessages.Items = state.eventChatMessages.Items?.filter(
        (x) => x?.messageId !== action.payload.messageId
      );
    },
    handleDeletePrivateMessage: (state, action: PayloadAction<any>) => {
      const conversationId = action.payload?.data?.Items[0]
        .conversationId! as string;
      const existingData = state.oneToOneMessages?.[conversationId]?.data || [];

      const updatedData = existingData?.filter(
        (item: any) => item?.messageId !== action.payload.messageId
      );

      const payload = {
        data: updatedData,
        count: updatedData.length,
        lastEvaluateKey:
          state.oneToOneMessages?.[conversationId].lastEvaluateKey,
        user: state.oneToOneMessages?.[conversationId].user,
        fetchMore: state.oneToOneMessages?.[conversationId].fetchMore,
      };

      state.oneToOneMessages = {
        ...state.oneToOneMessages,
        [conversationId]: payload,
      };
    },
    removeConversation: (state, action: PayloadAction<{ conversationId: string }>) => {
      const conversationId = action.payload?.conversationId;
      const existingItems = state?.conversations?.Items || [];
      
      const updatedItems = existingItems.filter(
        (conversation: any) => conversation.conversationId !== conversationId
      );

      state.conversations = {
          ...state.conversations,
          Items: updatedItems,
      };
  },
    handleLiveMessage: (state, action: PayloadAction<any>) => {
      let temp = current(state).eventChatMessages;
      let found = false;
      if (action.payload.eventRoomId === state.activeRoom?.id) {
        const newChat = temp.Items.map((message) => {
          if (message.messageId === action.payload.messageId) {
            message = { ...message, ...action.payload };
            found = true;
          }
          return message;
        });
        if (found) state.eventChatMessages.Items = newChat;
        if (!found) {
          const msg: any = {
            messageId: action?.payload?.messageId,
            sender: `${action.payload.user?.firstName} ${action.payload.user?.lastName}`,
            message: action.payload.message,
            source: action.payload.source,
            senderId: action.payload.sender,
            avatar: action.payload.user?.avatar,
            type: action.payload.type,
            ...action.payload,
          };
          state.eventChatMessages.Items.push(msg);
        }
      }
    },
    clearLiveMessages: (state) => {
      state.eventChatMessages.Items = [];
      state.updateMessage = null;
    },
    handleUpdateLiveMessage: (state, action: PayloadAction<Message>) => {
      state.eventChatMessages.Items = state.eventChatMessages.Items?.map(
        (item) =>
          item.messageId === action.payload.messageId
            ? { ...item, ...action.payload }
            : item
      );
    },
    handleUpdateReactionMessage: (state, action: PayloadAction<any>) => {
      const conversationId = action.payload?.conversationId! as string;
      const messageId = action.payload?.messageId! as string;
      const existingData = state.oneToOneMessages?.[conversationId]?.data || [];
      const updatedData = existingData.map((item: any) =>
        item.messageId === messageId ? { ...item, ...action.payload } : item
      );
      const payload = {
        data: updatedData,
        count: state.oneToOneMessages?.[conversationId].count,
        lastEvaluateKey:
          state.oneToOneMessages?.[conversationId].lastEvaluateKey,
        user: state.oneToOneMessages?.[conversationId].user,
        fetchMore: false,
      };

      state.oneToOneMessages = {
        ...state.oneToOneMessages,
        [conversationId]: payload,
      };
    },
    handleUpdatePrivateMessage: (state, action: PayloadAction<any>) => {
      const conversationId = action.payload?.conversationId! as string;
      const messageId = action.payload?.messageId! as string;
      const newMessageContent = action.payload?.message! as string;
      const existingData = state.oneToOneMessages?.[conversationId]?.data || [];

      const updatedData = existingData.map((message: any) =>
        message.messageId === messageId
          ? { ...message, message: newMessageContent }
          : message
      );

      const payload = {
        data: updatedData,
        count: state.oneToOneMessages?.[conversationId].count,
        lastEvaluateKey:
          state.oneToOneMessages?.[conversationId].lastEvaluateKey,
        user: state.oneToOneMessages?.[conversationId].user,
        fetchMore: state.oneToOneMessages?.[conversationId].fetchMore,
      };

      state.oneToOneMessages = {
        ...state.oneToOneMessages,
        [conversationId]: payload,
      };
    },
    handleLiveMessages: (state, action: PayloadAction<LiveMessages>) => {
      const items = action.payload.Items;
      state.eventChatMessages.Count = action.payload.Count;
      state.eventChatMessages.ScannedCount = action.payload.ScannedCount;
      items?.forEach((item: any) => {
        const msg: Message = {
          messageId: item?.messageId,
          sender: `${item.user?.firstName} ${item.user?.lastName}`,
          message: item.message,
          senderId: item.sender,
          avatar: item.user?.avatar,
          type: item.type,
          ...item,
        };
        state.eventChatMessages.Items.unshift(msg);
      });
      state.eventChatMessages.LastEvaluatedKey =
        action.payload?.LastEvaluatedKey ?? null;

      if (!items?.length) {
        state.eventChatMessages.LastEvaluatedKey =
          null as unknown as LastEvaluatedKey;
      }
    },
    setCheckConversationResponse: (
      state,
      action: PayloadAction<ConversationData>
    ) => {
      state.checkConversationsResponse = action.payload;
      if (action.payload.conversationId) {
        const conversation = state.userNotify?.unseenMessages.find(
          (a) => a.conversationId === action.payload.conversationId
        );
        if (conversation) {
          //this method runs at first time and when we see our messages,
          //so we want to change all messages to seen status if It's not first load
          if (conversation.total != 1) conversation.total = 0;
        }
      }
    },
    setCheckUserOnlineResponse: (
      state,
      action: PayloadAction<CheckUserOnlineResponse>
    ) => {
      const statusIndex = state.checkUserOnline?.checkedResult.findIndex(
        (result: any) =>
          result.userId === action.payload.checkedResult[0].userId
      );

      if (~statusIndex) {
        state.checkUserOnline.checkedResult[statusIndex] =
          action.payload.checkedResult[0];
      } else {
        state.checkUserOnline.checkedResult.push(
          action.payload.checkedResult[0]
        );
      }
    },
    setUserOnlineResponse: (
      state,
      action: PayloadAction<{ userId: string }>
    ) => {
      const statusIndex = state.checkUserOnline?.checkedResult.findIndex(
        (result: any) => result.userId === action.payload.userId
      );

      if (~statusIndex) {
        state.checkUserOnline.checkedResult[statusIndex].active = true;
      }
    },

    setUserOfflineResponse: (
      state,
      action: PayloadAction<{ userId: string }>
    ) => {
      const statusIndex = state.checkUserOnline?.checkedResult.findIndex(
        (result: any) => result.userId === action.payload.userId
      );

      if (~statusIndex) {
        state.checkUserOnline.checkedResult[statusIndex].active = false;
      }
    },
    handleUpdatedConversation: (
      state,
      action: PayloadAction<UpdatedConversationResponse>
    ) => {
      const selectedConversation = state.messagesWithDetail.find(
        (conversation) =>
          conversation.conversationId === action.payload.conversationId
      );
      if (selectedConversation) {
        selectedConversation?.data.Items.push({
          conversationId: action.payload.conversationId,
          message: action.payload.latestMessage,
          messageId: action.payload.latestMessageDetails.messageId,
          metadata: action.payload.latestMessageDetails.metadata,
          reactions: action.payload.latestMessageDetails.reactions,
          replyDate: action.payload.latestMessageDetails.replyDate,
          replyToMessage: action.payload.latestMessageDetails.replyToMessage,
          searchMessage: action.payload.latestMessageDetails.searchMessage,
          sender: action.payload.latestMessageDetails.sender,
          type: action.payload.latestMessageDetails.type,
        });
      }
    },
    handleFailedMessages: (state, action: PayloadAction<NewMessages>) => {
      state.failedMessages.push(action.payload);
    },
    emptyFailedMessages: (state) => {
      state.failedMessages = [];
    },
  },
  extraReducers: {
    [setActiveRoom.type]: (state, action: PayloadAction<IRoom>) => {
      state.activeRoom = action.payload;
    },
  },
});

export const {
  setConversations,
  setStartAndStopTyping,
  setUserNotify,
  setNotification,
  setNewMessages,
  setMessagesWithDetail,
  setFocusMessage,
  handleLiveMessage,
  handleUpdateLiveMessage,
  handleUpdatePrivateMessage,
  handleLiveMessages,
  setCheckConversationResponse,
  setCheckUserOnlineResponse,
  setUserOnlineResponse,
  setUserOfflineResponse,
  clearLiveMessages,
  handleUpdatedConversation,
  handleFailedMessages,
  emptyFailedMessages,
  handleDeleteLiveMessage,
  handleDeletePrivateMessage,
  handleUpdateReactionMessage,
  seenMessage,
  removeConversation
} = privateChatSlice.actions;

export const handleMessage = (message: any) => async (dispatch: Dispatch) => {
  if (message.action === "conversations")
    dispatch(setConversations(message.data));
  if (
    message.action === "receive-start-typing" ||
    message.action === "receive-stop-typing"
  )
    dispatch(setStartAndStopTyping(message));
  if (message.action === "user-notify") dispatch(setUserNotify(message.data));
  if (message.action === "notification")
    dispatch(setNotification(message.data.instance));
  if (message.action === "new-message") dispatch(setNewMessages(message.data));
  if (message.action === "messages") {
    dispatch(setMessagesWithDetail(message));
  }
  if (message.action === "live-update-message")
    dispatch(handleUpdateLiveMessage(message.data));
  // reaction-private-message
  if (message.action === "reaction-private-message")
    dispatch(handleUpdateReactionMessage(message.data));
  if (message.action === "update-message")
    dispatch(handleUpdatePrivateMessage(message.data));
  if (message.action === "live-remove-message")
    dispatch(handleDeleteLiveMessage(message.data));
  if (message.action === "remove-message")
    dispatch(handleDeletePrivateMessage(message.data));

  if (message.action === "live-message")
    dispatch(handleLiveMessage(message.data));
  if (message.action === "live-messages")
    dispatch(handleLiveMessages(message.data));

  if (
    message.action === "check-conversation" ||
    message.action === "init-conversation"
  )
    dispatch(setCheckConversationResponse(message.data));
  if (message.action === "check-user-online")
    dispatch(setCheckUserOnlineResponse(message.data));
  if (message.action === "online")
    dispatch(setUserOnlineResponse(message.data));
  if (message.action === "offline")
    dispatch(setUserOfflineResponse(message.data));
  if (message.action === "updated-conversation")
    dispatch(handleUpdatedConversation(message.data));
  if (message.action === "request-connection")
    dispatch(newConnectionRequest(message.data));
  if (message.action === "reject-request-connection")
    dispatch(rejectConnectionRequest(message.data));
  if (message.action === "accept-request-connection")
    dispatch(acceptConnectionRequest(message.data));

  // handling response received from join-event. This is happening when the live event starts
  if (message.action === "live-event") {
    dispatch(setLiveEventResponse(message.data));
    dispatch(setNewAttendees(message.data));
  }
  if (message.action === "broadcast")
    dispatch(setBroadcastResponse(message.data));
  if (message.action === "poll-appeared") {
    dispatch(newLivePoll(message.data));
  }

  if (message.action === "edit-audience") {
    dispatch(setAttendeeStatus(message.data));
  }
  if (message.action === "new-audience") {
    dispatch(addNewAttendee(message.data));
  }
  if (message.action === "remove-audience") {
    dispatch(removeAttendee(message.data));
  }
  if (message.action === "seen-message") {
    dispatch(seenMessage(message.data))
  }
  if (message.action === "remove-conversation") {
    dispatch(removeConversation(message.data))
  }
};
