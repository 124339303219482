import { Reducer } from 'redux';

import { IJobTitlesState } from '../../../models/company/JobTitlesState';
import { JobTitlesActions } from './actions';
import { JobTitlesActionTypes } from './types';

const initialState: IJobTitlesState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const JobTitlesReducer: Reducer<IJobTitlesState, JobTitlesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case JobTitlesActionTypes.JOB_TITLES_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case JobTitlesActionTypes.JOB_TITLES_SUCCESS: {
      return {
        ...state,
        value: action.data
      };
    }

    case JobTitlesActionTypes.JOB_TITLES_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
