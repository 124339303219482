import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
    isActive?: boolean;
}

function AttendeesTabIcon({ fillColor, fillBgColor, isActive, ...props }: Props) {
        return isActive?
          (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="users_active" data-name="users active"
                      d="M7.5,13A4.5,4.5,0,1,1,12,8.5,4.5,4.5,0,0,1,7.5,13ZM14,24H1a1,1,0,0,1-1-1v-.5a7.5,7.5,0,0,1,15,0V23A1,1,0,0,1,14,24ZM17.5,9A4.5,4.5,0,1,1,22,4.5,4.5,4.5,0,0,1,17.5,9Zm-1.421,2.021a6.825,6.825,0,0,0-4.67,2.831A9.537,9.537,0,0,1,16.323,19H23a1,1,0,0,0,1-1v-.038a7.008,7.008,0,0,0-7.921-6.941Z"
                      fill={fillColor}/>
            </svg>
          ) :
         (
          <svg xmlns="http://www.w3.org/2000/svg" width="28.6" height="24" viewBox="0 0 28.6 24">
              <g id="Group_21255" data-name="Group 21255" transform="translate(0)">
                  <g id="Group_19632" data-name="Group 19632" transform="translate(11.695)">
                      <path id="Path_14744" data-name="Path 14744" d="M13.737,0A7.533,7.533,0,1,1,6.2,7.534,7.534,7.534,0,0,1,13.737,0" transform="translate(-6.203 0)" fill={fillColor} />
                      <path id="Path_14745" data-name="Path 14745" d="M7.843,19.122a.647.647,0,0,0,.526-.282,5.982,5.982,0,0,1,9.742,0,.65.65,0,0,0,.533.283h1.412a.657.657,0,0,0,.653-.663.643.643,0,0,0-.08-.311,8.448,8.448,0,0,0-14.784.013.654.654,0,0,0,.576.96Z" transform="translate(-5.769 3.825)" fill={fillColor} />
                  </g>
                  <path id="Subtraction_6" data-name="Subtraction 6" d="M2.073,23.371H.651a.655.655,0,0,1-.576-.961,8.448,8.448,0,0,1,5.884-4.232,8.193,8.193,0,0,1,6.9,1.82,9.953,9.953,0,0,0-1.55,1.969c-.114-.1-.237-.191-.365-.282A5.982,5.982,0,0,0,2.6,23.094a.644.644,0,0,1-.526.282Zm5.46-7.306a7.533,7.533,0,1,1,5.486-12.7,7.528,7.528,0,0,0,0,10.332,7.561,7.561,0,0,1-5.485,2.369Z" transform="translate(0.001 -1)" fill={fillColor} />
              </g>
          </svg>
         )
}

export default AttendeesTabIcon;
