import { Divider, Stack, Tab, Tabs, Tooltip, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react";
import { makeRoomsTree } from "src/utils/rooms";
import { IRoom } from "src/models/event/room";
import { BreakoutSessionBody, CurrentOrNextBreakoutSessionBody } from "./session-card";
import { UserService } from "src/services";
import { useDispatch, useSelector } from "react-redux";
import { getUpcomingEvent } from "src/store/events/upcoming-event/actions";
import { IAppState } from "src/store";
import { getBrandingColors, subscribeToColorChanges } from '../event/live/liveEventStyling';
import ArrowIcon from "src/mui/assets/icons/TabsArrowIcons";
import { Opacity } from "@mui/icons-material";
interface IEventAgendaProps {
    eventList: any;
    onDrawerFalse: () => void;
    isLobbbyPage?: boolean;
    eventId: string | undefined
}

export const Agenda = ({ eventList, eventId, onDrawerFalse }: IEventAgendaProps) => {
    const roomTree = useMemo(() => makeRoomsTree(eventList?.rooms)
        , [eventList?.rooms])
    const dispatch = useDispatch();
    const [selectedTabRooms, setSelectedTabRooms] = useState(roomTree[0]);
    const [selectedBreakoutRoom, setSelectedBreakoutRoom] = useState<any>();
    const handleChangeTreeNode = (event: React.SyntheticEvent, newValue: number) => setSelectedTabRooms(roomTree?.filter((item: any) => item.date === newValue)[0]);

    useEffect(() => {
        const unsubscribe = subscribeToColorChanges(() => {
            getBrandingColors();
        });
        return unsubscribe;
    }, []);

    const colors = getBrandingColors();

    const agendaTabStyles = {
        position: 'relative',
        "& > .MuiTabScrollButton-root": {
            position: 'absolute',
            right: '-32px',
            top: '0px',
            zIndex: 100,
            height: '29px',
            width: '31px'
        },
        // "& > .MuiTabScrollButton-root.Mui-disabled:first-of-type": {
        //     display: 'none',
        // },
        "& .MuiSvgIcon-root": {
            color: '#ffffff',
            width: '28px',
            height: '28px'
        },
        "& .MuiTabs-flexContainer": {
            columnGap: '5px'
        },
        "&.MuiTabs-root": {
            minHeight: '28px',
            maxHeight: '28px',
            overflow: 'visible',
            maxWidth: 'calc(100% - 11px)',
        },
        "& .MuiTab-root": {
            padding: '0px',
            minWidth: '61px',
            minHeight: '28px',
            maxHeight: '28px',
            // backgroundColor: colors?.Secondary! || '#2F2F2F',
            border: `1px solid ${colors?.Secondary! || '#ffffffe3'}`,
            lineHeight: '28px',
            backgroundColor: colors?.Secondary! || '#ffffffe3',
            borderRadius: '5px !important',
            color: `${colors?.Accent!} !important` || '#ffffff !important',
            px: '12px',
            fontFamily: 'Poppins-500',
            fontWeight: 500,
            fontSize: '13px',
            margin: '0px !important'
        },
        "& .Mui-selected": {
            backgroundColor: colors?.Accent! || '#03FFCD',
            border: `1px solid ${colors?.Accent! || '#ffffffe3'}`,
            opacity: "1",
            borderRadius: '5px',
            fontSize: '13px',
            // border: 'none',
            color: `${colors?.ButtonTextColor!} !important` || '#000000 !important',
            lineHeight: '28px',
            fontFamily: 'Poppins-600',
        },
        "& .MuiTabs-indicator": {
            display: 'none'
        },
        ">.MuiTabScrollButton-root": {
            borderLeft: `1px solid ${colors?.Text! || '#ffffffe3'}`,
            borderRadius: 0,
            width: '15px',
            right: '-15px',
            height: '28px'
        },
        ">.MuiTabScrollButton-root:nth-of-type(1)": {
            right: 'auto',
            borderRight: `1px solid ${colors?.Text! || '#ffffffe3'}`,
            borderLeft: 'none',
            left: '-3px',
            background: colors?.Main!,
            height: '28px'
        }
    };

    const currentSession = useSelector((state: IAppState) => state.liveEvent.session?.current);

    const groupBy = (arr: any, property: string) => {
        return arr?.reduce((memo: any, x: any) => {
            if (!memo[x[property]]) { memo[x[property]] = []; }
            memo[x[property]].push(x);
            return memo;
        }, {}) || {};
    };

    const handleChangeToggle = async (session: string | undefined, status: boolean) => {
        const userService = new UserService();
        await userService.updateAgendaNotifications({
            activity: "attending-event-update",
            permit: status,
            eventId: eventId,
            sessionId: session
        });
        dispatch(getUpcomingEvent(eventId!));
        return status;
    };

    const renderRoomTree = (
        <Stack direction="row" sx={{ overflow: 'hidden' }}>
            <Tabs
                value={selectedTabRooms?.date}
                onChange={handleChangeTreeNode}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs example"
                sx={agendaTabStyles}
                TabScrollButtonProps={{
                    slots: {
                      StartScrollButtonIcon: () => <ArrowIcon color={colors?.Text! || '#ffffff'} flipX />, // Left arrow with custom color
                      EndScrollButtonIcon: () => <ArrowIcon color={colors?.Text! || '#ffffff'}  />, // Right arrow with custom color
                    },
                  }}
            >
                {roomTree?.map((treeNode: any, index: any) => {
                    return (
                        <Tab label={`Day ${index + 1}`} value={treeNode.date} key={treeNode?.date} />
                    )
                })}
            </Tabs>
        </Stack>
    );

    const renderTreeChildren = <>
        {
            Object.keys(groupBy(selectedTabRooms?.roomsList, 'verticalOrdering'))
                ?.map(key => {
                    let rooms = groupBy(selectedTabRooms?.roomsList, 'verticalOrdering')?.[key];

                    const currentRoomIndex = rooms.findIndex((room: any) =>
                        room.sessions.findIndex((session: any) => session.id === currentSession?.id) !== -1
                    );
                    const currentRoom = rooms?.[currentRoomIndex];
                    const currentSessionIndex = currentRoom?.sessions.findIndex((session: any) => session.id === currentSession?.id);
                    let nextSession: any = null
                    if (currentSessionIndex !== -1 && currentRoomIndex !== -1) {
                        const nextSessionIndex = currentSessionIndex + 1;
                        nextSession = currentRoom?.sessions?.[nextSessionIndex];
                        rooms[currentRoomIndex] = {
                            ...(currentRoom || {}),
                            sessions: currentRoom?.sessions?.filter((session: any) => session?.id !== currentSession?.id && session.id !== nextSession?.id)
                        };
                    }

                    const currentRoomKeys = rooms.map((room: any, index: any) => {
                        let item = `${index}-${room.id}-${room.verticalOrdering}`
                        return item;
                    })

                    return (
                        <>
                            {eventList?.rooms?.length ? <Divider sx={{ borderColor: colors?.Secondary! || '#707070', mt: '10px', mb: "10px", opacity: colors?.Secondary! ? '95%' : "50%" }} />: null}
                            <Tabs
                                value={currentRoomKeys.includes(selectedBreakoutRoom) ? selectedBreakoutRoom : `0-${rooms[0].id}-${rooms[0].verticalOrdering}`}
                                onChange={(e: any, value: any) => setSelectedBreakoutRoom(value)}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable auto tabs example"
                                sx={agendaTabStyles}
                                TabScrollButtonProps={{
                                    slots: {
                                    StartScrollButtonIcon: () => <ArrowIcon color={colors?.Text! || '#ffffffe3'} flipX />, // Left arrow with custom color
                                    EndScrollButtonIcon: () => <ArrowIcon color={colors?.Text! || '#ffffffe3'}  />, // Right arrow with custom color
                                    },
                                }}
                            >
                                {rooms.map((room: IRoom, idx: any) => (
                                    <Tab
                                        key={`${idx}-${room.id}`}
                                        label={
                                            <Tooltip title={`Breakout Session ${idx + 1}`} arrow>
                                                <span>Breakout Session {idx + 1}</span>
                                            </Tooltip>
                                            }
                                        value={`${idx}-${room.id}-${room.verticalOrdering}`}
                                    />
                                ))}
                            </Tabs>
                            {rooms?.length > 0 && !rooms?.sessions?.length ? <Divider sx={{ borderColor: colors?.Secondary! || '#707070', mt: '10px', mb: "10px", opacity: colors?.Secondary! ? '95%' : "50%" }} />: null}
                            <Stack sx={{ pb: 0 }}>

                                {currentSession && selectedBreakoutRoom === `${currentRoomIndex}-${currentRoom?.id}-${currentRoom?.verticalOrdering}` && <><Typography variant="body2" sx={{ fontSize: '15px', mb: '10px', lineHeight: '21px', fontFamily: 'Poppins-500', color: colors?.Text! || "#03FFCD" }}>
                                    Playing now
                                </Typography>
                                    <CurrentOrNextBreakoutSessionBody key={currentRoom.id} day={eventList?.rooms} session={currentSession} onChangeToggle={handleChangeToggle} /></>
                                }

                                {nextSession && selectedBreakoutRoom === `${currentRoomIndex}-${currentRoom?.id}-${currentRoom?.verticalOrdering}` && <>
                                    <Typography variant="body2" sx={{ fontSize: '15px', my: '10px', lineHeight: '21px', fontFamily: 'Poppins-500', color: colors?.Text! || "#03FFCD" }}>
                                        Next
                                    </Typography>
                                    <CurrentOrNextBreakoutSessionBody key={nextSession?.id} day={eventList?.rooms} session={nextSession} onChangeToggle={handleChangeToggle} /></>
                                }
                                {/* {((currentSession || nextSession) && selectedBreakoutRoom === `${currentRoomIndex}-${currentRoom?.id}-${currentRoom?.verticalOrdering}`) && (
                                    <Divider sx={{ borderColor: colors?.Secondary! || '#707070', mt: '10px', mb: "9px", opacity: colors?.Secondary! ? '95%' : "50%" }} />
                                )} */}

                                {
                                    rooms?.map(
                                        (room: IRoom, idx: any) => (selectedBreakoutRoom === `${idx}-${room.id}-${room.verticalOrdering}` ? (
                                            <>
                                                <BreakoutSessionBody key={room.id} day={eventList?.rooms} room={room} onChangeToggle={handleChangeToggle} />

                                            </>

                                        ) : (selectedBreakoutRoom === `${idx}-${room.id}-${room.verticalOrdering}`)
                                            || (idx === 0 && (selectedBreakoutRoom === `0-${room.id}-${room.verticalOrdering}`
                                                || !selectedBreakoutRoom
                                                || !selectedBreakoutRoom.endsWith(`${room.verticalOrdering}`))) ? (
                                            <BreakoutSessionBody key={room.id} day={eventList?.rooms} room={room} onChangeToggle={handleChangeToggle} />
                                        ) : null)
                                    )
                                }
                            </Stack >
                        </>
                    );
                })
        }
    </>

    useEffect(() => {
        if (selectedTabRooms && !selectedBreakoutRoom) {
            setSelectedBreakoutRoom(
                `0-${selectedTabRooms?.roomsList[0]?.id}-${selectedTabRooms?.roomsList[0]?.verticalOrdering}`
            );
        }
    }, [selectedTabRooms, selectedBreakoutRoom]);
    return (
        <Stack
            sx={{
                mt: '14px',
                pb: '14px',
                height: 'calc(100vh - 57px)',
                overflowY: 'scroll',
            }}
        >
            {renderRoomTree}
            {renderTreeChildren}
        </Stack>
    )
}
