import { Dispatch } from '@reduxjs/toolkit';
import { interactiveEventSlice } from '..';
import { _expoWebSocket } from "../../../utils/hooks";

// WebSocket Template
const sendWsMessage = (message: any) => {
  const webSocket = _expoWebSocket;
  return webSocket && webSocket.then((ws) => ws.send(JSON.stringify(message))).catch(console.error);
}

export const InteractiveSpeakerJoin = (eventId: string, eventRoomId: string) => async (dispatch: Dispatch) => {
  sendWsMessage({
    action: 'interactive/speaker-join',
    data: { eventId, eventRoomId }
  });
  dispatch(interactiveEventSlice.actions.speakerJoin({eventId, eventRoomId}));
}

export const InteractiveSpeakerReadyToGoLive = (eventId: string, eventRoomId: string) => async (dispatch: Dispatch) => {
  sendWsMessage({
    action: 'interactive/ready-to-go-live',
    data: { eventId, eventRoomId }
  });
  dispatch(interactiveEventSlice.actions.speakerReadyToGoLive());
}