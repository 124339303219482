import { CardMedia, Container, Divider, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';

import WebsiteEventQuickLinks from './website-event-quicklinks';
import { FooterTopSection } from './footer-top';
import { FooterBottomSection } from './footer-bottom';

export const FooterSection = ( ) => {
  return (
      <Stack
        id="contactus"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 1,
          maxWidth: '100%',
          backgroundColor: '#151f26',
          p: 0,
        }}
      >
      <Stack sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)', width: '100%' }}><FooterTopSection /></Stack>
      <WebsiteEventQuickLinks />
      <FooterBottomSection />
    </Stack>
  );
};
