import React, { FC, HTMLAttributes } from 'react';

export interface IHyperLinkProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  href?: string;
  variant: 'NavLink' | 'SecondaryAction' | 'FooterLink' | 'ForgotPassword' | 'Signup' | "Link";
  backgroundColor?: string;
  textColor?: string;
  children?: React.ReactNode;
  classes?: string;
  type?: 'submit' | 'button';
  target?: '_blank' | '_self';
}
export const HyperLink: FC<IHyperLinkProps> = ({
  text,
  href,
  variant,
  backgroundColor,
  textColor,
  classes,
  type = 'button',
  target = '_self'
}) => {
  const variantMap = {
    NavLink: 'text-navlink font-semibold text-blue-3',
    SecondaryAction: {
      width: '74px',
      height: '31px',
      padding: '6px 20px',
      borderRadius: '20px',
      backgroundColor: '#edf2f5'
    },
    FooterLink: 'text-h3 text-white',
    ForgotPassword: '',
    Signup: 'text-sm font-semibold text-blue-1 hover:underline',
    Link:'text-sm font-semibold text-blue-1 hover:underline'
  };
  const variantClass = variantMap[variant];
  const className = [classes, 'rounded-xl inline-block text-sm', `${variantClass}`].join(' ');


  const handleClick = () => {
    if (href) {
      if (target === '_blank') {
        window.open(href, target);
      } else {
        window.location.href = href;
      }
    }
  };

  return (
    <button
      className={className}
      style={{ background: `${backgroundColor}`, color: textColor }}
      type={type}
      onClick={handleClick}>
      {text}
    </button>
  );
};
