// video-expo.tsx => Expo entry point, showing the booths and pagination

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import classNames from 'classnames';
import { IExpoBooth } from 'src/models/expo';
import ExpoBooth from './expo-booth/expo-booth';
import ExpoBoothCard from './expo-booth-card';
import { EventsService } from 'src/services/events';
import { IAssignedManagers, IExpoHandouts } from 'src/models/events-live/expo';
import { IAppState } from 'src/store';

type ExpoSponsorsProps = {
  expoData: IExpoBooth[];
  screenMode: 'small' | 'large' | 'hidden' | undefined;
};

export const ExpoSponsors = (props: ExpoSponsorsProps) => {
  const eventExpo = useSelector((state: IAppState) => state.liveEvent.event?.eventExpo);
  const [showBooth, setShowBooth] = useState<boolean>(false);
  const [currentBooth, setCurrentBooth] = useState<IExpoBooth>();
  const [boothManagers, setBoothManagers] = useState<IAssignedManagers[]>();
  const [handouts, setSetHandouts] = useState<IExpoHandouts[]>();
  // Variables for pagination
  const [firstItemKey, setFirstItemKey] = useState(0);
  const [lastItemKey, setLastItemKey] = useState(props.screenMode === 'small' ? 6 : 8);
  const itemsCount = props.screenMode === 'small' ? 6 : 8;

  useEffect(() => {
    // TODO: Get list of managers with the required data (Name, avatar, status-available,busy?)
    // eslint-disable-next-line no-unused-expressions
    currentBooth && new EventsService().getExpoManagers(currentBooth?.eventId!, currentBooth?.eventSponsorId!, currentBooth?.id!)
      .then(({ data }) => {
        setSetHandouts(data?.expoHandouts);
        setBoothManagers(data?.assignedManagers);
      });
  }, [currentBooth]);

  // Shows next 8 items
  const handleNextItems = () => {
    if (lastItemKey < props.expoData.length) {
      setFirstItemKey(firstItemKey + itemsCount);
      setLastItemKey(lastItemKey + itemsCount);
    }
  };

  // Shows previous 8 items
  const handlePrevItems = () => {
    if (firstItemKey > 0) {
      setFirstItemKey(firstItemKey - itemsCount);
      setLastItemKey(lastItemKey - itemsCount);
    }
  };

  const showBoothDetailHandler = (booth: IExpoBooth) => {
    setCurrentBooth(booth);
    setShowBooth(true);
  };

  const exitBoothHandler = () => {
    setShowBooth(false);
    setCurrentBooth(undefined);
    setSetHandouts(undefined);
  };
  return (
    <div
      className="w-full h-full"
    >
      {showBooth ? (
        <ExpoBooth
          boothManagers={boothManagers!}
          handouts={handouts!}
          exitBoothHandler={exitBoothHandler}
          booth={currentBooth!}
        />
      ) : (
        <div
          className={classNames('h-full w-full text-center justify-center py-4', {
            grid: !showBooth
          })}
          style={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url("${`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${eventExpo?.background}`}")`
          }}
        >
          {/* <div className="opacity-100"><div className="absolute inset-0 bg-black bg-opacity-25"></div></div> */}
          <p className="text-white text-lg18 font-bold mb-2">Expo</p>
          <span className="text-gray-100 text-md1 md:px-0 px-3">
            {props.expoData.length
              ? 'Pick a booth and you can start a conversation with the exhibitor.'
              : 'There are no exhibitors at the moment.'}
          </span>
          <div
            className={classNames('my-8 grid gap-y-12 gap-x-6 sm:justify-self-auto justify-self-center', {
              'sm:grid-cols-3 grid-cols-2': props.screenMode === 'small',
              'grid-cols-4': props.screenMode !== 'small',
              'grid-cols-1': props.expoData.length === 1
            })}
          >
            {props.expoData.map(
              (item, key) => key >= firstItemKey
                && key < lastItemKey && (
                  <ExpoBoothCard key={key} booth={item} setShowBooth={showBoothDetailHandler} />
              )
            )}
          </div>

          {/* Pagination Controls */}

          <div className="flex justify-center items-center">
            <PlayArrowIcon
              sx={{ color: 'white', transform: 'rotate(180deg)' }}
              onClick={() => handlePrevItems()}
            />
            <span className="text-white text-md1">
              {lastItemKey >= props.expoData.length ? props.expoData.length : lastItemKey}
              /
              {props.expoData.length}
            </span>
            <PlayArrowIcon sx={{ color: 'white' }} onClick={() => handleNextItems()} />
          </div>
        </div>
      )}
    </div>
  );
};
