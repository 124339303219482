import { Reducer } from 'redux';

import { ConnectSuggestionsState } from '../../../models/ConnectSuggestionsState';
import { ConnectSuggestionsActions } from './actions';
import { ConnectSuggestionsActionTypes } from './types';

const initialState: ConnectSuggestionsState = {
  value: null,
  loading: false,
  page: 0,
  totalPage: 0,
  errorMessage: ''
};

export const ConnectSuggestionsReducer: Reducer<
  ConnectSuggestionsState,
  ConnectSuggestionsActions
> = (state = initialState, action) => {
  switch (action.type) {
    case ConnectSuggestionsActionTypes.CONNECT_SUGGESTIONS_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case ConnectSuggestionsActionTypes.CONNECT_SUGGESTIONS_SUCCESS: {
      return {
        ...state,
          value: action.payload,
          page: action.payload.page,
          totalPage: action.payload.totalPage
        };
    }
    case ConnectSuggestionsActionTypes.CONNECT_SUGGESTIONS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null,
        page: 0,
        totalPage: 0
      };
    }

    default:
      return state;
  }
};
