// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//
import { ConfirmDialogProps } from './types';

// ----------------------------------------------------------------------

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  continueButton,
  surveyLaterButton,
  ...other
}: ConfirmDialogProps) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other} sx={{ "& .MuiDialog-paper": { borderRadius: "16px" } }}>
      <DialogTitle sx={{ pb: 2, fontFamily: "Poppins-700", lineHeight: 1.55556, fontSize: "1.06rem", fontWeight: 600, padding: "24px 24px 16px" }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2', fontFamily: "Poppins-400", fontSize: "14px", fontWeight: 500, padding: "0px 24px" }}> {content} </DialogContent>}

      <DialogActions sx={{ padding: "24px" }}>
        {surveyLaterButton}
        {action}
        {continueButton ?
          continueButton
          : <Button variant="outlined" color="inherit" onClick={onClose} sx={{ fontFamily: "Poppins-400", fontWeight: 600, fontSize: "14px", borderRadius: "8px", border: "1px solid rgba(145, 158, 171, 0.32)" }}>
            Cancel
          </Button>}
      </DialogActions>
    </Dialog>
  );
}
