import classNames from "classnames";
import React, { FC } from "react";
import "../../styles/1-1-meetings.css"
import { useSurveyContext } from "src/mui/providers/GeneralContext";
import { useContentShareState } from "amazon-chime-sdk-component-library-react";

interface IMeetingVideoCallLayout {
    tiles: JSX.Element[];
    activeTileId?: number | null;
    localTile?: JSX.Element | null;
    hasRemoteUsers?: boolean
  }
  
  interface ITile {
    tile: JSX.Element;
    activeTileId: number;
    isLocalTile?: boolean;
  }

export const MeetingVideoCallLayout: FC<IMeetingVideoCallLayout> = React.memo(({ tiles, activeTileId, localTile, hasRemoteUsers }: IMeetingVideoCallLayout) => {

  const { screenId } = useSurveyContext();
  const { isLocalUserSharing, sharingAttendeeId } = useContentShareState();

  const getGridClasses = (tileCount: number) => {
    if (tileCount === 0) return "grid-cols-1 grid-rows-1";
    if (tileCount === 1) return "grid-cols-1 grid-rows-1";
    if (tileCount === 2) return "grid-cols-2 grid-rows-1";
    if (tileCount === 3) return "grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-2";
    if (tileCount === 4) return "grid-cols-2 grid-rows-2";
    if (tileCount <= 8) return "grid-cols-4 grid-rows-2";
    return "grid-cols-4 auto-rows-fr";
  };

    const Tile: FC<ITile> = React.memo(({ tile, activeTileId, isLocalTile }: ITile) => {
    // const getTileDimensions = (tileCount: number) => {
    //   if (tileCount === 1) return "w-full h-full"; 
    //   if (tileCount === 2) return "w-1/2 h-full"; 
    //   if (tileCount === 3) return "w-full h-1/2 md:w-1/2 md:h-1/2"; 
    //   if (tileCount === 4) return "w-1/2 h-1/2"; 
    //   if (tileCount <= 8) return "w-1/4 h-1/2"; 
    //   return "w-1/4 h-auto"; 
    // };

    // const tileCount = tiles.length + (localTile ? 1 : 0); 
        return (
          <div
            className={classNames(
              // `w-full h-full rounded-xl overflow-hidden object-contain border-2 ${
              //   hasRemoteUsers && isLocalTile && screenId === null ? "pip" : ""
              // }`,
              `w-full h-full rounded-[6px] overflow-hidden object-contain border-2`,
              {
                "border-4 border-yellow-500": activeTileId && activeTileId === tile?.props?.tileId,
              }
            )}
          >
            {tile}
          </div>
        );
      }, (prevProps, nextProps) => prevProps.activeTileId !== nextProps.activeTileId);

      const tileCount = tiles.length + (localTile ? 1 : 0);
    return <div className={`grid gap-2 justify-center items-center h-[calc(100vh-116px)] ${isLocalUserSharing ? 'w-[440px]' : 'w-[calc(100vw-49px)]'} ${getGridClasses(tileCount)}`}>
        {localTile && <Tile activeTileId={activeTileId!} tile={localTile} isLocalTile />}
        {
            tiles.map(tile => <Tile activeTileId={activeTileId!} tile={tile} />)
        }
    </div>
}, (prevProps, nextProps) => (prevProps.activeTileId === nextProps.activeTileId) && (prevProps.tiles === nextProps.tiles));
