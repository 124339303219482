/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';
import { Divider } from '@mui/material';
import { useState } from 'react';
import { Avatar } from '../../shared-components/V2';
import { NoEvents } from '../events/event-tab/components/no-events';
import { IAppState } from '../../store';

type IPeopleListFeedbackProps = {
  people: any[];
  onMessage?: Function;
  onConnect?: Function;
  onClick: Function;
  // isDisabledConnect?: boolean;
  onClickPeople?: ((c: any) => void) | undefined;
};

export const PeopleListFeedback = (props: IPeopleListFeedbackProps) => {
  const navigate = useNavigate();
  const colors = getBrandingColors();
  const [requestStatus, setRequestStatus] = useState<{ [key: string]: boolean }>({});

  const handleConnect = async (itemId: string) => {
    if (props.onConnect) {
      await props.onConnect(itemId);
    }
    setRequestStatus((prev) => ({
      ...prev,
      [itemId]: true, // Mark only the selected button as "Pending"
    }));
  };
  const { currentUser: user } = useSelector((state: IAppState) => state.auth);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props?.people?.length > 0
        ? props.people.map((item: any) => (
          <>
            <div
              key={`search-feedback-${item.id}`}
              className={classNames('flex sm:flex-row flex-col items-center justify-between py-0 pb-3 sm:pb-0', {
                hidden: item?.id === user?.id
              })}
            >
              <div className="flex items-center pb-[16px] pt-[16px] w-full">
                <div
                  className="flex-shrink-0 cursor-pointer"
                  onClick={() => {
                    setTimeout(() => {
                      navigate(`/wall/${item.slug}`);
                    }, 1000);
                    props?.onClickPeople?.(item);
                  }}
                >
                  <Avatar
                    size="MEDIUM"
                    src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item.avatar}`}
                    alt=""
                  />
                </div>
                <div onClick={() => props?.onClickPeople?.(item)} className="flex flex-col items-start pl-4">
                  <span
                    style={{
                      color: colors.Text || 'white',
                      fontWeight: 600,
                      fontFamily: 'Poppins-600'
                    }}
                    className="text-[14px] leading-5 cursor-pointer"
                    onClick={() => props.onClick(item)}
                  >
                    {`${item.firstName} ${item.lastName}`}
                  </span>
                  <span
                    style={{
                      color: colors.Text || 'white',
                      fontWeight: 500,
                      fontFamily: 'Poppins-500'
                    }}
                    className="text-[12px] leading-[18px] cursor-pointer"
                  >
                    {item.jobtitle}
                  </span>
                  <span
                    style={{
                      color: colors.Text || 'white',
                      fontWeight: 400,
                      fontFamily: 'Poppins-400'
                    }}
                    className="text-[12px] leading-[18px] cursor-pointe"
                  >
                    {item.location}
                  </span>
                </div>
              </div>
              <div
                className={classNames('flex gap-3 w-[175px] min-w-[175px]', {
                  hidden: item?.id === user?.id
                })}
              >
                <button
                  type="button"
                  className="text-sm px-3 py-1 rounded-[5px]"
                  onClick={() => props.onMessage && props.onMessage(item)}
                  style={{
                    background: colors?.Accent || '#EDF2F5',
                    color: colors?.ButtonTextColor || '#000000',
                    fontWeight: 500,
                    fontFamily: 'Poppins-500'
                  }}
                >
                  Message
                </button>
                {!item.connectionId && (

                <button
                  type="button"
                  className={classNames(' text-sm px-3 py-1 rounded-[5px]', {
                    // 'bg-[#0049EF]': item.sentRequest === 1
                  })}
                  disabled={item.sentRequest === 1 || requestStatus[item.id]}
                  onClick={async () => {
                    if (props.onConnect) {
                      await props.onConnect(item.id);
                    }
                    handleConnect(item.id);
                  }}
                  style={{
                    background: colors?.Secondary || '#0049EF',
                    color: colors?.Text || '#ffffff',
                    fontWeight: 500,
                    fontFamily: 'Poppins-500'
                  }}
                >
                  {requestStatus[item.id] || item.sentRequest === 1
                    ? 'Pending'
                    : 'Connect'}
                </button>
                )}
              </div>
            </div>
            <Divider sx={{
              mx: '0px', borderColor: colors?.Text || 'white', opacity: '40%', display: item?.id === user?.id ? 'none' : 'block'
            }}
            />
          </>
        )) : <NoEvents />}
    </>
  );
};
