import { FC } from 'react';
import classNames from "classnames";
import { Logo } from "../Logo"

import "./welcomePost.css";

export interface IWelcomePostProps {
    title?: string;
    imageSrc: string;
    subTitle?: string;
    children?: JSX.Element;
    className?: string;
    innerClassName?: string;
    subTitleClass?: string;
}

export const WelcomePost: FC<IWelcomePostProps> = ({ title, imageSrc, subTitle, children, className, innerClassName, subTitleClass }: IWelcomePostProps) => {
    return (
    
    <div 
        className={classNames("bg-white card-shadow w-full mb-2 pb-[36px] pt-[14px] welcome-post", {
            [`${className}`]: className
        })}
    >
        <div
            className={classNames("flex flex-col justify-center items-center text-center md:w-250px] w-full md:px-0 px-4 mx-auto", {
                [`${innerClassName}`]: innerClassName
            })}
        >
            <div className='text-[20px] leading-[27px] text-[#0B1221] font-medium mb-[28px]'>{title}</div>
            <Logo src={imageSrc} alt={title} />
            <div
                className={classNames("font-16 font-normal text-height-25 mt-5", {
                    [`${subTitleClass}`]: subTitleClass
                })}
            >
                {subTitle}
            </div>
            {children}
        </div>
    </div>
    );
}