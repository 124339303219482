import { Container, Stack, } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import RssFeedOutlinedIcon from '@mui/icons-material/RssFeedOutlined';
import classNames from 'classnames';

const socialIconLinks = [
    { icon: YouTubeIcon, link: 'https://www.youtube.com/user/box' },
    { icon: RssFeedOutlinedIcon, link: 'https://blog.box.com/' },
    { icon: TwitterIcon, link: 'https://twitter.com/box' },
    { icon: FacebookIcon, link: 'https://www.facebook.com/box' },
  ];

export const FooterTopSection = ( ) => {
  return (
    <Container
        maxWidth={false}
        sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        maxWidth: '100%',
        p: 0,
        pl: '15px !important',
        pr: '15px !important',
        '@media (min-width: 768px)': { maxWidth: '100%', pl: '2.125rem', pr: '2.125rem' },
        '@media (min-width: 992px)': { maxWidth: '100%', pl: '2.125rem', pr: '2.125rem' },
        '@media (min-width: 1200px)': { maxWidth: '100%', pl: '2.125rem', pr: '2.125rem' },
        '@media (min-width: 1440px)': { maxWidth: '74.4680851064%' },
        }}
    >
      <Stack
        sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            '@media (max-width: 768px)': { justifyContent: 'center', },

        }}
      >
        {socialIconLinks?.map((item, index) => (
          <a
            key={index}
            rel="noreferrer"
            target="_blank"
            href={item.link}
            className={classNames('hover:underline cursor-pointer w-[100px] h-[100px] border-r border-[rgba(255,255,255,0.1)] flex items-center justify-center footer-social-hover', {
                'border-l border-[rgba(255,255,255,0.1)]': index === 0,
              })}
          >
            <item.icon />
          </a>
        ))}
      </Stack>
    </Container>
  );
};
