import { Reducer } from 'redux';

import { IIndustriesState } from '../../../models/company/IndustriesState';
import { IndustriesActions } from './actions';
import { IndustriesActionTypes } from './types';

const initialState: IIndustriesState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const IndustriesReducer: Reducer<IIndustriesState, IndustriesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case IndustriesActionTypes.INDUSTRIES_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case IndustriesActionTypes.INDUSTRIES_SUCCESS: {
      return {
        ...state,
        value: action.data
      };
    }

    case IndustriesActionTypes.INDUSTRIES_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
