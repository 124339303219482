/* eslint-disable react/no-unused-prop-types */
import React, { FC } from 'react';
import classNames from 'classnames';
import { Avatar, Button } from '../../../../shared-components/V2';
// import { ThreeDotIcon } from '../../../shared-components/V2/IconSvg';
import { MicSvgIcon } from '../../../../shared-components/V2/IconSvg/Mic/micSvgIcon';
import tickSvg from '../../../../assets/images/event-live/host-and-attendees/tick-icon.svg';
import crossSvg from '../../../../assets/images/event-live/host-and-attendees/cross-icon.svg';
import handSvg from '../../../../assets/images/event-live/host-and-attendees/hand-icon.svg';
import { DropDownMenu, IDropdownMenuItem } from '../../../../components/dropdown-menu';

type HostAttendeeItemProps = {
  item?: any;
  hasMic?: boolean;
  isMute?: boolean;
  hasRaisedHand?: boolean;
  onApprove?: Function;
  onReject?: Function;
  status?: 'Moderator' | 'Speaker';
  options?: IDropdownMenuItem[];
};

export const AttendeeAvatar: FC<HostAttendeeItemProps> = React.memo((props: HostAttendeeItemProps) => (
  <>
    {/* Static Elements */}
    <div
      className="relative flex flex-col items-center overflow-visible h-full w-full"
    >
      <div className="relative">
        {!props.onReject && props.hasMic && <div className="absolute -left-2.5 z-10 cursor-pointer"><MicSvgIcon /></div>}
        {!!props.onReject && <div className="absolute -left-2.5 z-10 cursor-pointer" onClick={() => props.onReject!()}><img src={crossSvg} alt="" /></div>}
        {!!props.onApprove && <div className="absolute -right-2 z-10 -top-1 cursor-pointer" onClick={() => props.onApprove!()}><img src={tickSvg} alt="" /></div>}
        <Avatar
          alt=""
          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${props.item.avatar}`}
          size="LARGE"
        />
        {/* Raise Hand */}
        {props.hasRaisedHand && <img className="absolute top-0" src={handSvg} alt="" />}
        <div className="absolute flex flex-row -left-2.5 -bottom-0.5 h-[15px]">
          <div className="text-xs">
            <Button
              text={props.status!}
              size="x-small"
              className={classNames('text-[8px] text-gray-700 h-[15px] w-[52px] cursor-default', {
                'bg-orange': !!props.status
              })}
            />

          </div>
          {/* <div className="ml-2 -mt-2.5 cursor-pointer"><ThreeDotIcon isActive fillColor="GRAY" /></div> */}
          {props.options && props.options.length > 0
              && (
              <DropDownMenu
                options={props.options!}
                containerClasses="flex justify-center items-center relative "
                menuCardClasses="absolute top-1 opacity-100 bg-white -ml-24 mt-4 shadow-xs2 text-gray-13 rounded-15 p-1 z-10 px-1.5"
                titleAndSubtitleGroup="flex justify-left items-center hover:bg-gray-1 text-center p-1 rounded-5 text-gray-13 cursor-pointer"
                buttonStyles="flex items center w-30 h-30 ml-2 -mt-2"
                listTextStyles="text-xs text-blue-3 tracking-wide"
                listIconStyles=""
                textAndImageContainer="flex w-full h-full items-center gap-2"
                fillColor="GRAY"
                fillColorActive
              />
              )}
        </div>
      </div>

      <div className="text-xs text-center w-[88px] overflow-hidden">
        <span>{props.item.firstName}</span>
        <br />
        <div className="text-xs font-semibold line-clamp-1">{props.item?.company?.name}</div>
      </div>
    </div>
  </>
));
