import React from "react";

export interface PollsSvgIconProps {
    fillColor?: string;
    arrowColor?: string;
}
export const PollsSvgIcon = ({ fillColor, arrowColor }: PollsSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#203c6e"
    return <svg xmlns="http://www.w3.org/2000/svg" width="22.072" height="21.591" viewBox="0 0 22.072 21.591">
    <g id="Group_21263" data-name="Group 21263" transform="translate(-6207 -6571)">
      <path id="survey-svgrepo-com" d="M3.374,3H18.5a1.4,1.4,0,0,1,1.374,1.417V5.838A1.4,1.4,0,0,1,18.5,7.255H3.374A1.4,1.4,0,0,1,2,5.838V4.417A1.4,1.4,0,0,1,3.374,3ZM3.34,9.383h8.383a1.387,1.387,0,0,1,1.34,1.417v1.421a1.387,1.387,0,0,1-1.34,1.417H3.34A1.387,1.387,0,0,1,2,12.221V10.8A1.387,1.387,0,0,1,3.34,9.383Zm.021,6.383h7.9a6.868,6.868,0,0,0-.426,2.128,7.281,7.281,0,0,0,.2,2.128H3.361A1.4,1.4,0,0,1,2,18.6V17.183H2a1.4,1.4,0,0,1,1.362-1.417Z" transform="translate(6205.001 6568)" fill="#fff"/>
      <g id="clock-circle-svgrepo-com" transform="translate(6215 6578.519)">
        <path id="Path_27134" data-name="Path 27134" d="M14.072,8.036A6.036,6.036,0,1,1,8.036,2,6.036,6.036,0,0,1,14.072,8.036Z" fill="#fff"/>
        <path id="Path_27135" data-name="Path 27135" d="M11.7,7.25a.453.453,0,0,1,.453.453V9.93l1.376,1.376a.453.453,0,0,1-.64.64l-1.509-1.509a.453.453,0,0,1-.133-.32V7.7A.453.453,0,0,1,11.7,7.25Z" transform="translate(-3.667 -2.081)" fillRule="evenodd"/>
      </g>
    </g>
  </svg>
  
};