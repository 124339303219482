import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Autocomplete, Avatar, Box, Stack
} from '@mui/material';
// import excelimg from '../../../../assets/images/Excel-file-icon.png';
import productsDropdownIcon from '../../../../assets/images/productsDropdownIcon.svg';

interface ProductsDropdownProps {
  handleAddClick: (id?: string | undefined) => void;
  companyProducts: any[];
  ProductId?: string;
}

export const ProductsDropdown = ({
  handleAddClick,
  companyProducts,
  ProductId,
}: ProductsDropdownProps) => {
  const [selectedProduct, setSelectedProduct] = useState<any>(null);

  useEffect(() => {
    // Find the product by ProductId or fallback to the first product in the list
    const defaultProduct = companyProducts.find((product) => product?.id === ProductId)
      || companyProducts[0];
    setSelectedProduct(defaultProduct);
  }, [ProductId, companyProducts]);

  const handleChangeFilters = (event: any, newValue: any) => {
    setSelectedProduct(newValue);
    handleAddClick(newValue?.id);
  };

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '5px',
        backgroundColor: 'white',
        width: '292px',
        mb: '24px',
      }}
    >
      <Box
        sx={{
          width: '31px',
          height: '31px',
          borderRadius: '3px',
          border: '2px solid #EDF2F5',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiAvatar-root': {
            borderRadius: '5px'
          }
        }}
      >
        <Avatar
          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${selectedProduct?.icon}`}
          sx={{
            objectFit: 'contain',
            width: '31px',
            height: '31px',
          }}
        />
      </Box>
      <Autocomplete
        id="grouped-sponsor-speaker"
        renderTags={(value) => (
          <Box
            sx={{
              // borderRadius: '50px',
              color: 'black',
              backgroundColor: 'transparent',
              width: '30px',
              height: '30px',
              display: 'none',
              alignItems: 'center',
              justifyContent: 'center',
              ml: '9px !important',
            }}
          >
            {value?.length}
          </Box>
        )}
        sx={{
          overflow: 'hidden',
          height: 40,
          width: '100%',
          borderRadius: '10px',
          alignItems: 'center',
          backgroundColor: 'white !important',
          '& .MuiAutocomplete-inputRoot': {
            flexWrap: 'nowrap !important',
            backgroundColor: 'transparent',
          },
          '& .MuiOutlinedInput-root': {
            height: 40,
          },
          '& .MuiAutocomplete-input': {
            alignContent: 'center',
          },
          '& .MuiOutlinedInput-root fieldset': {
            border: 'none',
          },
          '& input#grouped-sponsor-speaker::placeholder': {
            fontSize: '18px',
            fontFamily: 'Poppins-500',
            color: '#000000 !important',
            fontWeight: 500,
            lineHeight: '24px',
          },
          '& .MuiAutocomplete-listbox': {
            width: '292px !important',
          },
        }}
        popupIcon={(
          <Avatar
            src={productsDropdownIcon}
            sx={{
              width: '13px',
              height: '7px',
              lineHeight: '40px',
            }}
          />
        )}
        value={selectedProduct}
        onChange={handleChangeFilters}
        options={companyProducts}
        autoHighlight
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Box
            component="li"
            style={{
              backgroundColor: 'white',
              color: '#0B1221',
              padding: '11px 0px 13px 0px',
              borderBottom: '1px solid rgba(112, 112, 112, 28%)',
              margin: '0px 16px 0px 16px',
              fontSize: '14px',
              lineHeight: '19px',
              fontFamily: 'Poppins-500',
              fontWeight: 500,
            }}
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Box
              sx={{
                width: '25px',
                height: '25px',
                borderRadius: '3px',
                border: '2px solid #EDF2F5',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '7px',
                '& .MuiAvatar-root': {
                  borderRadius: '5px'
                }
              }}
            >
              <Avatar
                src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${option?.icon}`}
                sx={{
                  width: '25px',
                  height: '25px',
                }}
              />
            </Box>
            {option?.name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select Product"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            sx={{
              color: '#0B1221',
              fontSize: '14px',
              lineHeight: '19px',
              fontFamily: 'Poppins-500',
              padding: '0px !important',
              '& .MuiAutocomplete-input': {
                padding: '0px !important',
              },
              '& .MuiInputBase-input::placeholder': {
                color: '#0B1221',
                opacity: 1,
                fontSize: '18px',
                fontFamily: 'Poppins-600',
                fontWeight: 600,
                lineHeight: '24px',
                padding: '0px !important',
              },
              '& .css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root': {
                padding: '0px !important',
              },
            }}
          />
        )}
      />
    </Stack>
  );
};

export default ProductsDropdown;
