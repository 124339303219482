import { Reducer } from 'redux';

import { ICompanyEmployeesState } from '../../../models/company/CompanyEmployeesState';
import { CompanyEmployeesActions } from './actions';
import { CompanyEmployeesActionTypes } from './types';

const initialState: ICompanyEmployeesState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const CompanyEmployeesReducer: Reducer<ICompanyEmployeesState, CompanyEmployeesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CompanyEmployeesActionTypes.COMPANY_EMPLOYEES_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case CompanyEmployeesActionTypes.COMPANY_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case CompanyEmployeesActionTypes.COMPANY_EMPLOYEES_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
