import { getTimeZone } from './timezones';

export const dateAndTimeWithTz = (date: string, timeZone: string) => {
  const correctTimeZone = getTimeZone(timeZone)?.[0]?.utc?.[0]
    ? getTimeZone(timeZone)?.[0]?.utc?.[0]
    : timeZone;
  return {
    date: new Date(date).toLocaleString('en-AU', {
      timeZone: correctTimeZone,
      dateStyle: 'medium',
    }),

    dateLongFormat: new Date(date).toLocaleString('en-US', {
      timeZone: correctTimeZone,
      dateStyle: 'long',
    }),

    time: new Date(date).toLocaleString('en-AU', {
      timeZone: correctTimeZone,
      timeStyle: 'short',
    }),
    dateAndTime: new Date(date).toLocaleString('en-AU', {
      timeZone: correctTimeZone,
      dateStyle: 'medium',
      timeStyle: 'short',
    }),
  };
};

export const formatDateRange = (start: Date, end: Date) => {
  const startDay = start.getDate();
  const startMonth = start.toLocaleString('en-US', { month: 'long' });
  const startYear = start.getFullYear();

  const endDay = end.getDate();
  const endMonth = end.toLocaleString('en-US', { month: 'long' });
  const endYear = end.getFullYear();

  if (startDay === endDay && startMonth === endMonth && startYear === endYear) {
    // Same day
    return `${startDay} ${startMonth} ${startYear}`;
  } else if (startMonth === endMonth && startYear === endYear) {
    // Same month and year
    return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
  } else if (startYear === endYear) {
    // Same year, different months
    return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
  } else {
    // Different years
    return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
  }
};

export const formatDayMonth = (date: string | Date): string => {
  if (!date) return "";
  const parsedDate = new Date(date);
  const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "long" };
  const formattedDate = parsedDate.toLocaleDateString("en-GB", options);

  // Extracting day and adding ordinal suffix
  const day = parsedDate.getDate();
  const suffix =
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th";

  return `${day}${suffix} ${formattedDate.split(" ")[1]}`;
};

export const formatFullDateTime = (date: string | Date, includeTime: boolean = false): string => {
  if (!date) return "";
  const parsedDate = new Date(date);

  // Format: Day with ordinal suffix (e.g., "18th")
  const day = parsedDate.getDate();
  const suffix =
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th";

  // Extract full date components
  const formattedDate = parsedDate.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  // Extract time (12-hour format with AM/PM)
  const formattedTime = parsedDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const [dayPart, monthPart, yearPart] = formattedDate.split(" ");
  const formattedFullDate = `${day}${suffix} ${monthPart} ${yearPart}`;

  return includeTime ? `${formattedFullDate} • ${formattedTime}` : formattedFullDate;
};
