import React, {
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { ReactComponent as CameraIcon } from '../../../assets/images/camera-icon.svg';
import coverPhotoDefault from '../../../assets/images/default-cover-photo.svg';
import { Avatar } from '../../../shared-components/V2';
import { Logo } from '../../../shared-components/V2/Logo';
import { imageUrlPrefix } from '../../../utils';
import AlertModal from '../../../components/alert-modal/alert-modal';
import UserProfileCrop from '../user-profile-crop/user-profile-crop';
import UserCoverCrop from '../user-profile-crop/user-cover-crop';

interface ICoverPhotoAndAvatarProps {
  coverPhotoUrl: string;
  avatarUrl: string;
  isOwnProfile: boolean;
  isModalCrop?: boolean;
  onUploadCoverPhoto: Function;
  onUploadAvatar: Function;
  widthLogo?: string;
  relative?: string;
  absolute?: string;
  editButtonClass?: string;
  cameraIconPosition?: string;
  distancButtons?: string;
  cropSize?: string;
  cropButtonBackground?: string;
  drapDropPosition?: string;
  avatarSize?: 'XXSMALL' | 'XSMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'XLARGE' | 'XXLARGE' | 'PROFILEMATCH' | 'EDITPROFILE';
}
const CoverPhotoAndAvatar = ({
  coverPhotoUrl,
  avatarUrl,
  isOwnProfile,
  isModalCrop,
  onUploadCoverPhoto,
  onUploadAvatar,
  widthLogo = 'xl:w-[886px] w-full h-[156px]',
  relative = '',
  absolute = 'ml-[6px] md:-mt-32 -mt-[58px]',
  editButtonClass = 'absolute right-[9px] bottom-[39px]',
  cameraIconPosition,
  distancButtons = '',
  cropSize,
  cropButtonBackground,
  drapDropPosition,
  avatarSize
}: ICoverPhotoAndAvatarProps) => {
  const coverPhotoRef = useRef<HTMLInputElement | null>(null);
  const [isCoverPhotoAttached, setIsCoverPhotoAttached] = useState(false);
  const [selectedCoverPhotoFile, setSelectedCoverPhotoFile] = useState('');
  const [selectedProfilePhotoFile, setSelectedProfilePhotoFile] = useState('');
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [selectedCoverPhotoUrl, setSelectedCoverPhotoUrl] = useState('');

  const profilePictureRef = useRef<HTMLInputElement | null>(null);

  const handleCoverPhotoSelected = (files: FileList) => {
    const data = new FormData();
    data.append('file', files[0]);
    const fileUrl = URL.createObjectURL(files[0]);

    setIsCoverPhotoAttached(true);
    setSelectedCoverPhotoUrl(fileUrl);
  };

  const handleUploadCoverPhoto = () => {
    if (onUploadCoverPhoto) {
      const data = new FormData(document.forms[0]);
      data.append('file', selectedCoverPhotoFile);
      onUploadCoverPhoto(data).then(() => {
        setIsCoverPhotoAttached(false);
        setSelectedCoverPhotoUrl('');
      });
    }
  };

  const handleProfilePictureSelected = (files: FileList) => {
    setIsProfileModal(true);
    const reader = new FileReader();
    reader.addEventListener('load', () => setSelectedProfilePhotoFile(reader.result?.toString() || ''));
    reader.readAsDataURL(files[0]);
  };

  const onInputProfileCoverClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const onCloseModal = () => {
    setIsProfileModal(false);
  };

  const onSaveCropProfile = (profile : any) => {
    const data = new FormData(document.forms[0]);
    data.append('file', profile);
    onUploadAvatar(data).then(() => setIsProfileModal(false));
  };

  const onCropCoverPicture = (coverPhoto : any) => {
    setSelectedCoverPhotoFile(coverPhoto);
  };

  return (
    <>
      <div className={`${relative} xl:mx-auto relative`}>
        {selectedCoverPhotoUrl !== '' && (
          <div
            className={classNames('flex items-center flex-row justify-end bg-black bg-opacity-[65%] px-[10px] py-1 xl:w-[886px] w-full h-[47px] rounded-t-[10px] absolute z-10 profile-mobile-crop', {
              [`${cropButtonBackground}`]: cropButtonBackground
            })}
          >
            <div className="">
              <button
                type="button"
                className="rounded-5 inline-block text-sm font-semibold h-[30px] bg-[#EDF2F5] text-[#203C6E] w-[88px] mr-[10px] hover:bg-[#CEDCF2] tracking-wide"
                onClick={() => {
                  setIsCoverPhotoAttached(false);
                  setSelectedCoverPhotoUrl('');
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="rounded-5 inline-block text-sm font-semibold h-[30px] bg-[#0049EF] hover:bg-[#244E93] text-white w-[74px] tracking-wide"
                onClick={() => {
                  handleUploadCoverPhoto();
                }}
              >
                Save
              </button>
            </div>
          </div>
        )}
        {selectedCoverPhotoUrl !== '' && (
          <div
            // eslint-disable-next-line max-len
            className={classNames('text-[12px] leading-[18px] text-[white] flex items-center justify-center bg-black bg-opacity-[65%] h-[33px] w-[215px] rounded-[10px] absolute top-[69px] left-[50%] -ml-[107.5px] z-10', {
              [`${drapDropPosition}`]: drapDropPosition
            })}
          >
            Drag up and down to reposition
          </div>
        )}
        {isCoverPhotoAttached && (
          <div className={classNames('bg-white rounded-[10px] object-cover profile-mobile-crop', widthLogo)}>
            <UserCoverCrop
              imgUrl={selectedCoverPhotoUrl}
              onCropCover={onCropCoverPicture}
              isModalCrop={isModalCrop}
              className={classNames('xl:w-[886px] w-full h-[156px] profile-mobile-crop', {
                [`${cropSize}`]: cropSize
              })}
            />
          </div>
        )}
        {!isCoverPhotoAttached && (
          <Logo
            src={`${imageUrlPrefix}/${coverPhotoUrl}` ?? coverPhotoDefault}
            alt=""
            className={classNames('rounded-[10px] object-cover', widthLogo)}
          />
        )}
      </div>
      <div className={`${absolute}`}>
        <div className="">
          <div className={`flex flex-row ${distancButtons} justify-between mt-[8px]`}>
            <div className="flex flex-row relative z-10">
              <Avatar
                src={`${imageUrlPrefix}/${avatarUrl}`}
                size={avatarSize}
                className="border-[3px] border-white bg-[#edf2f5]"
              />
              {isOwnProfile && (
                <div
                  className={classNames(
                    'absolute -right-[10px] -bottom-[10px] z-10 cursor-pointer',
                    {
                      widthLogo,
                      [`${cameraIconPosition}`]: cameraIconPosition
                    }
                  )}
                  onClick={() => profilePictureRef?.current?.click()}
                >
                  <CameraIcon />
                  <input
                    type="file"
                    id="profilePicture"
                    ref={profilePictureRef}
                    className="hidden"
                    accept="image/*"
                    onChange={e => handleProfilePictureSelected(e.target?.files!)}
                    onClick={onInputProfileCoverClick}
                  />
                </div>
              )}
              {
                isOwnProfile && isProfileModal
                && (
                  <AlertModal isOpen={isProfileModal} onClose={onCloseModal} className="w-[459px]">
                    <UserProfileCrop title="" imgUrl={selectedProfilePhotoFile} onCloseModal={onCloseModal} onCropProfile={onSaveCropProfile} />
                  </AlertModal>
                )
              }
            </div>
            {isOwnProfile && selectedCoverPhotoUrl === '' && (
              <div className="items-end relative">
                <div className="w-full flex flex-col justify-between p-2">
                  <div
                    className={
                        `${editButtonClass} rounded-[5px] font-[500] bg-[#EDF2F5] hover:bg-[#CEDCF2] h-[35px] w-[136px] text-[#203C6E] text-sm5 flex items-center justify-center cursor-pointer`
                    }
                    onClick={() => coverPhotoRef?.current?.click()}
                  >
                    Edit Cover Photo
                  </div>
                  <input
                    type="file"
                    id="coverPhoto"
                    ref={coverPhotoRef}
                    className="hidden"
                    accept="image/*"
                    onChange={e => handleCoverPhotoSelected(e.target?.files!)}
                    onClick={onInputProfileCoverClick}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverPhotoAndAvatar;
