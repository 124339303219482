/* eslint-disable jsx-a11y/alt-text */
import {
  useEffect,
  useState,
} from 'react';

import { useForm } from 'react-hook-form';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { IInterest } from '../../../models/interest/interest';
import { UserService } from '../../../services';
import { FilUploadService } from '../../../services/FileUpload';
import { Button } from '../../../shared-components/V2';
import { IAppState } from '../../../store';
import { initialise } from '../../../store/auth';
import {
  signupStep5,
} from '../../../store/user/signup/actions';
import { useCurrentUser } from '../../../utils/hooks';
import CoverPhotoAndAvatar from '../../user/user-profile/cover-photo-and-avatar';
import InterestedFormNEW from './interested-form-new';

import UsersGroup from '../../../assets/images/users-group.png';
import { ReactComponent as ScoreProgress } from '../../../assets/images/score-progress.svg';
import { InterestType } from '../../user';

export interface ISignUp {
  country: string;
  city: string;
  location: string;
  postCode: string;
}

interface ICompleteProfileProps {
  buttonText: string;
  from: string;
  isMainProfileMatch?: boolean;
  isFilterHieght?: boolean;
}
export const CompleteYourProfileFormNew = ({
  buttonText,
  from,
  isMainProfileMatch,
  isFilterHieght
}: ICompleteProfileProps) => {
  const [coverImage, setCoverImage] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [interestedListState, setInterestedListState] = useState<IInterest[]>(
    []
  );
  const [offeredListState, setOfferedListState] = useState<IInterest[]>([]);

  const [userInterests, setUserInterests] = useState<InterestType>({
    offers: [],
    interests: []
  });

  const { handleSubmit } = useForm<ISignUp>();
  const { selectedInterests, selectedOffers } = useSelector(
    (state: IAppState) => state.interests
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const user = useCurrentUser();

  useEffect(() => {
    dispatch(initialise());
  }, [dispatch]);

  useEffect(() => {
    setOfferedListState(selectedOffers);
  }, [selectedOffers]);

  useEffect(() => {
    setInterestedListState(selectedInterests);
  }, [selectedInterests]);

  const onUploadCoverPhoto = async (data: FormData) => {
    const userService = new UserService();
    const fileUploadService = new FilUploadService();
    const uploadedFile = await fileUploadService.uploadImage(data);
    setCoverImage(uploadedFile.data.Key);
    await userService.editOwnProfile({ cover: uploadedFile.data.key });
  };

  const onUploadAvatar = async (data: FormData) => {
    const userService = new UserService();
    const fileUploadService = new FilUploadService();
    const uploadedFile = await fileUploadService.uploadImage(data);
    setProfileImage(uploadedFile.data.Key);
    await userService.editOwnProfile({ avatar: uploadedFile.data.key });
  };

  const saveofferInterestedItems = () => {
    let data: any;
    // eslint-disable-next-line prefer-const
    data = {};
    data.offerListInterestedList = {};
    data.offerIds = { interestIds: [] };
    data.interestIds = { interestIds: [] };

    userInterests.offers?.forEach(offer => data.offerIds.interestIds.push(offer.id));
    userInterests.interests?.forEach(interest => data.interestIds.interestIds.push(interest.id));

    data.offerListInterestedList.offerList = offeredListState;
    data.offerListInterestedList.interestedList = interestedListState;
    dispatch(signupStep5(data));
  };
  const onSubmit = () => {
    saveofferInterestedItems();
    if (from === 'site' || from === 'setting') {
      navigate(`/wall/${user?.slug}`);
    }
  };

  // const backClick = () => {
  //   dispatch(signupBackStep());
  // };

  const skipClick = (e: any) => {
    e.preventDefault();
    navigate(`/wall/${user?.slug}`);
  };

  return (
    <form className="mt-[32px] mb-2.5 mobile-mt-10" onSubmit={handleSubmit(onSubmit)}>
      <div
        className={classNames('flex flex-row justify-center pr-[38px] pl-[45px] profile-main-div', {
          'pl-[38px]': isMainProfileMatch,
        })}
      >
        <div
          className={classNames('w-[356px] relative mobile-w-full', {
            'w-[376px] h-[539px] rounded-20 profile-match-shadow p-2.5': isMainProfileMatch,
          })}
        >
          <CoverPhotoAndAvatar
            coverPhotoUrl={coverImage}
            avatarUrl={profileImage}
            isOwnProfile
            onUploadCoverPhoto={onUploadCoverPhoto}
            onUploadAvatar={onUploadAvatar}
            widthLogo="w-[356px] h-[104px]"
            relative="relative"
            absolute="absolute -mt-[67px] ml-[18px] w-[356px] mobile-distance-btn"
            editButtonClass="mr-[16px] mt-[0.5rem]"
            distancButtons="w-[356px] mobile-distance-btn"
            avatarSize="PROFILEMATCH"
            cropSize="!w-[356px] !h-[104px]"
            cropButtonBackground="!w-[356px]"
            drapDropPosition="hidden"
            isModalCrop
          />
          <div
            className={classNames('w-[254px] flex items-center justify-center mt-[45px] mx-auto', {
              'mt-[76px] w-[338px]': isMainProfileMatch,
            })}
          >
            <img src={UsersGroup} />
          </div>
          <div
            className={classNames('w-full flex items-center justify-center mt-[7px] max-w-[291px] max-h-[139px] mx-auto', {
              'mt-[15px]': isMainProfileMatch,
            })}
          >
            <ScoreProgress />
          </div>
        </div>
        <div
          className={classNames('border-l border-[#C6C6C6] ml-[40px] pl-[26px] complete-profile-form', {
            'ml-[30px] border-[#d5d5d5]': isMainProfileMatch,
          })}
        >
          <InterestedFormNEW from="site" isMainProfileMatch interests={userInterests} setUserInterests={setUserInterests} page={null} widthStyle="md:w-[340px]" isFilterHieght={isFilterHieght} />
        </div>
      </div>
      <div
        className={classNames('flex items-center border-t border-[#CECECE] mt-[14px] pt-[10px] pr-[18px] pl-[30px]', {
          '!pr-[38px] pl-[58px] !pt-0 !mt-[30px] !border-transparent': isMainProfileMatch,
        })}
      >
        {/* <button
          type="button"
          className="text-sm1 text-[#172746] font-semibold hover:underline h-[30px]"
          onClick={backClick}
        >
          Back
        </button> */}
        <div className="flex justify-end ml-auto">
          {isMainProfileMatch && (
            <button
              type="button"
              className="text-sm1 text-[#172746] font-semibold bg-white mr-3 rounded-20 h-[30px] w-16"
              onClick={skipClick}
            >
              Skip
            </button>
          )}
          <Button
            type="submit"
            text={buttonText}
            size="x-small"
            className="bg-[#0049EF] text-white text-sm1 font-semibold hover:bg-[#244E93] px-[20px] h-[30px]"
            data-test-id="signup-button-3"
          />
        </div>
      </div>
    </form>
  );
};

export default CompleteYourProfileFormNew;
