import classNames from "classnames";
import { ReactNode } from "react";

export const ChatTooltip = ({
  message,
  children,
  className,
  position,
  containerClass,
}: {
  message: string;
  children: ReactNode;
  className?: string;
  position?: string;
  containerClass?: string;
}) => {
  return (
    <div
      className={classNames("relative flex flex-col items-end group", {
        [`${containerClass}`]: containerClass,
      })}
    >
      <div className="relative">
        {children}
        {message.length > 0 && (
          <div
            className={classNames(
              "flex flex-col items-center hidden group-hover:flex",
              {
                [`${position}`]: position,
              }
            )}
          >
            <span
              className={classNames(
                "relative z-100 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md",
                {
                  [`${className}`]: className,
                }
              )}
            >
              {message}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
