import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { IEditCompanyProfile } from "../../../models/company/company-profile-edit";
import { ICompanyProfileUpdateResult } from "../../../models/company/CompanyProfileUpdateResult";
import { ICompanyProfileUpdateState } from "../../../models/company/CompanyProfileUpdateState";
import { CompanyService } from "../../../services";
import { UpdateCompanyProfileActionTypes } from "./types";
import { toast } from "react-toastify";

export interface ICompanyProfileUpdateInProgress {
  type: UpdateCompanyProfileActionTypes.UPDATE_COMPANY_PROFILE_IN_PROGRESS;
  inProgress: boolean;
}

export interface ICompanyProfileUpdateSuccess {
  type: UpdateCompanyProfileActionTypes.UPDATE_COMPANY_PROFILE_SUCCESS;
  data: ICompanyProfileUpdateResult;
}

export interface ICompanyProfileUpdateError {
  type: UpdateCompanyProfileActionTypes.UPDATE_COMPANY_PROFILE_ERROR;
  errorMessage: string;
}

export type CompanyProfileUpdateActions =
  | ICompanyProfileUpdateInProgress
  | ICompanyProfileUpdateSuccess
  | ICompanyProfileUpdateError;

export const updateProfile: ActionCreator<
  ThunkAction<
    Promise<any>,
    ICompanyProfileUpdateState,
    null,
    ICompanyProfileUpdateSuccess
  >
> = (
  companyIdOrSlug: string,
  data: IEditCompanyProfile,
  adminsSubmit,
  onUpdateSuccess?: any,
  onError?: (p: any) => void,
) => {
  return async (dispatch: Dispatch) => {
    const companyService: CompanyService = new CompanyService();

    try {
      data.admins = adminsSubmit;
      const result = await companyService.updateCompanyProfile(
        companyIdOrSlug,
        data
      );

      const companyAdmins = { admins: adminsSubmit };
      await companyService.editCompanyAdmins(companyIdOrSlug, companyAdmins);
      dispatch({
        type: UpdateCompanyProfileActionTypes.UPDATE_COMPANY_PROFILE_SUCCESS,
        payload: result,
        isLoggedIn: true,
      });
      if (onUpdateSuccess) onUpdateSuccess(result);
    } catch (error: any) {
      if (onError) onError(error);
      console.error(error);
      dispatch({
        type: UpdateCompanyProfileActionTypes.UPDATE_COMPANY_PROFILE_ERROR,
        errorMessage: error.message,
      });
      toast.error(error?.response?.data?.message ?? error?.message);
    } finally {
      dispatch({
        type: UpdateCompanyProfileActionTypes.UPDATE_COMPANY_PROFILE_IN_PROGRESS,
        inProgress: false,
      });
    }
  };
};
