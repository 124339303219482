import { parseISO } from "date-fns";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Avatar } from "../../shared-components/V2";
import { IAppState } from "../../store";
import { getDistanceBetweenTwoDateTime } from "../../utils/datetime";

export const WebsocketNotifications = () => {
    const navigate = useNavigate();
    const notifications = useSelector((state: IAppState) => state.notifications); //comes from ws

    const userNotificationTemplate = (notification: any, betweenText: string, linkText: string) => {
        return (
            <div className='flex flex-row gap-4 h-full'>
                <div>
                    <Link to={`/wall/${notification?.targetUser?.slug}`}>
                        <Avatar src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${notification?.targetUser?.avatar}`} size='MEDIUM' />
                    </Link>
                </div>
                <div className='flex flex-col justify-center'>
                    <div className="">
                        <p>
                            <span onClick={() => navigate(`/wall/${notification?.targetUser?.slug}`)} className='no-underline hover:underline decoration-blue-400 text-blue-400'>{`${notification?.targetUser?.firstName} ${notification?.targetUser?.lastName}`}</span>
                            {` ${betweenText} `}
                            <span onClick={() => navigate(`/home?postId=${JSON.parse(notification?.metadata)?.[1]?.objectId}`)} className='no-underline hover:underline decoration-blue-400 text-blue-400'>{linkText}</span>
                        </p>
                    </div>
                    <div className='mt-1'>
                        <div className='text-xs text-blue-400'>{getDistanceBetweenTwoDateTime(new Date(), Date.parse(notification?.createdAt), false)?.replace("less than a minute", "Just now")}</div>
                    </div>
                </div>
            </div>

        );
    }
    useEffect(() => {
        const wsNotification = notifications.data?.[0];
        if (!wsNotification) return;
        const notificationDate: any = parseISO(wsNotification.createdAt);
        const secondsAgo: number = (Date.now() - notificationDate) / 1000;

        if (secondsAgo < 5)
        {
            if (wsNotification && wsNotification.actionType.startsWith("view-post")) {
                if (wsNotification.objectType === "Like") {
                    if (wsNotification.targetType === "user") {
                        toast(<>{userNotificationTemplate(wsNotification, 'has liked on', 'your post.')}</>);
                    }
                } else if (wsNotification.objectType === "Post") {
                    if (wsNotification.targetType === "user") {
                        toast(<>{userNotificationTemplate(wsNotification, 'has commented on', 'your post.')}</>);
                    }
                }
            }
            if (wsNotification && wsNotification.actionType.startsWith("view-profile")) {
                if (wsNotification.objectType === "ConnectionRequest") {
                    if (wsNotification.targetType === "user") {
                        toast(<>{userNotificationTemplate(wsNotification, 'invited you to connect', '')}</>)
                    }
                }
            }
        }

    }, [notifications.data?.[0]])
    return <div></div>
}