import React from 'react';
import { getBrandingColors } from '../event/live/liveEventStyling';

function MinimizeIcon() {
    const colors = getBrandingColors();
    const defaultFillColor = '#000000';
    const defaultBgColor = '#edf2f5';

    return (
        <svg id="Component_423_5" data-name="Component 423 – 5" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 22.5 22.5">
            <g id="Group_18541" data-name="Group 18541">
                <rect 
                    id="Rectangle_1" 
                    data-name="Rectangle 1" 
                    width="22.5" 
                    height="22.5" 
                    rx="11.25" 
                    fill={colors?.Text || defaultBgColor} 
                />
            </g>
            <rect 
                id="Rectangle_8284" 
                data-name="Rectangle 8284" 
                width="13" 
                height="4.105" 
                rx="2.053" 
                transform="translate(4.75 9.197)" 
                fill={colors?.Main || defaultFillColor}
            />
        </svg>
    );
}

export default MinimizeIcon;