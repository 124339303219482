export type IColors = {
  Main: string | undefined;
  Secondary: string | undefined;
  Accent: string | undefined;
  Text: string | undefined;
  ButtonTextColor: string | undefined;
  MainBackgroundColor: string | undefined;
};

export type IBranding = {
id: string | undefined;
eventId: string | undefined;
controlPanel:string | undefined;
moduleDesign:string | undefined;
layout: string | undefined;
media: string | undefined;
colors : IColors;
};

let branding: IBranding = {
  id: undefined,
  eventId: undefined,
  controlPanel: undefined,
  moduleDesign: undefined,
  layout: undefined,
  media: undefined,
  colors: {
    Main: undefined,
    Secondary: undefined,
    Accent: undefined,
    Text: undefined,
    ButtonTextColor: undefined,
    MainBackgroundColor: undefined,
  }
};


const listeners: (() => void)[] = [];

export const getBrandingColors = () => branding?.colors;
export const getBranding = () => branding;

export const setColor = (key: keyof IColors, value: string) => {
  branding.colors = { ...branding.colors, [key]: value };
  listeners.forEach((callback) => callback());
};

export const setBranding = (key: keyof IBranding, value: string) => {
  branding = { ...branding, [key]: value };
  listeners.forEach((callback) => callback());
};

export const subscribeToColorChanges = (callback: () => void) => {
  listeners.push(callback);
  return () => {
    const index = listeners.indexOf(callback);
    if (index > -1) listeners.splice(index, 1);
  };
};
