import React, {
  useRef,
  useState
} from 'react';

import Picker from 'emoji-picker-react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
// import { format, parseISO } from 'date-fns';

import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import { IAppState } from '../../../store';
import { uploadFile } from '../../../store/fileupload';

// import AttachFileIcon from '@mui/icons-material/AttachFile';
// import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import deleteIcon from '../../../assets/images/delete-icon.svg';
import { Avatar } from '../../../shared-components/V2';

type ChatControlsProps = {
  // eslint-disable-next-line no-unused-vars
  sendMessage: (msg: string, fileUrl: string, fileType: string) => void;
  avatar: string;
};

const ChatControls = (props: ChatControlsProps) => {
  const [text, setText] = useState<string>('');
  const [showEmoji, setShowEmoji] = useState<boolean>(false);
  const filleInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedFileType, setSelectedFileType] = useState('');
  const dispatch = useDispatch();
  const {
    fileUpload,
    // liveEvent
  } = useSelector((state: IAppState) => state);

  // Disable or Enable message input
  // const endDate: string = liveEvent.event?.endTime!;
  // const convertDateFormat = endDate && format(parseISO(endDate), 'dd MMM yyyy');
  // const sessionEndTime = new Date(convertDateFormat);
  // const currentDate = new Date();
  // const differences = sessionEndTime.getDate() - currentDate.getDate();

  const onEmojiClick = (event: any, emojiObject: any) => {
    setText(text + emojiObject.emoji);
    setShowEmoji(false);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && text.length > 0) {
      if (!fileUpload.isUploading) {
        props.sendMessage(text, fileUpload.data.Location!, selectedFileType);
        setText('');
        setSelectedFile('');
      }
    }
  };

  const handleFileSelected = (files: FileList) => {
    const data = new FormData();
    data.append('file', files[0]);
    const fileType = files[0].type.split('/')[0];

    if (fileType === 'image') setSelectedFileType('image');
    else setSelectedFileType('document');

    dispatch(uploadFile(data, fileType === 'image' ? 'image' : 'document'));
    if (!fileUpload.isUploading) {
      setSelectedFile(URL.createObjectURL(files[0]));
    }
  };

  return (
    <div className="flex flex-row gap-3 py-0 items-center relative bottom-0">
      <Avatar src={props.avatar} alt="Avatar" size="SMALL" />
      <div className="w-full flex flex-col">
        <div className="relative w-full h-full bg-gray-1 rounded-3xl">
          <input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
            value={text}
            className="bg-gray-1 rounded-3xl text-h2 pl-6 pr-32 py-2 w-full placeholder-gray-400"
            type="text"
            placeholder="Type here..."
            onKeyDown={handleKeyDown}
            // disabled={differences < 1}
            autoComplete="off"
          />

          <button type="button" onClick={() => filleInputRef?.current?.click()}>
            <InsertPhotoOutlinedIcon
              sx={{
                color: '#203c6e', position: 'absolute', top: '6px', right: '12px'
              }}
            />
          </button>
          <button type="button" onClick={() => setShowEmoji(!showEmoji)}>
            <InsertEmoticonIcon
              sx={{
                color: '#203c6e', position: 'absolute', top: '6px', right: '40px'
              }}
            />
          </button>

          <input
            type="file"
            id="fileToUpload"
            ref={filleInputRef}
            className="hidden"
            onChange={e => handleFileSelected(e.target?.files!)}
          />
        </div>

        {showEmoji && (
          <Picker
            pickerStyle={{
              position: 'absolute',
              zIndex: '1000',
              flex: 'none',
              right: '0px',
              bottom: '60px'
            }}
            onEmojiClick={onEmojiClick}
          />
        )}
        {selectedFile !== '' && selectedFileType === 'image' && (
          <div className="flex flex-row">
            <div>
              <img src={selectedFile} alt="" className="w-[100px] h-[100px]" />
            </div>
            <div className="-ml-2 cursor-pointer" onClick={() => setSelectedFile('')}>
              <img src={deleteIcon} alt="Remove" className="w-4 h-4" />
            </div>
          </div>
        )}
        {selectedFile !== '' && selectedFileType === 'document' && (
          <a href={selectedFile}>{selectedFile}</a>
        )}
      </div>
    </div>
  );
};

export default ChatControls;
