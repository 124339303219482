import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IEventInvitees } from "../../models/event-invitee";
import { EventsService } from "../../services/events";

export type EventInviteeState = {
    eventInvitee: IEventInvitees[];
}

const initialState: EventInviteeState = {
    eventInvitee: []
}

export const eventInviteeSlice = createSlice({
    name: 'eventInvitee',
    initialState,
    reducers: {
        setInvitedUser: (state, action: PayloadAction<EventInviteeState>) => {
            state.eventInvitee = action.payload.eventInvitee;
        }
    }
});

export const { setInvitedUser } = eventInviteeSlice.actions;

export const getEventInvitees = (slug: string) => async (dispatch: Dispatch) => {
    try {
        const eventInvitees = await new EventsService().getEventInvitees(slug);
        const eventInvitee = eventInvitees.data;
        dispatch(
            setInvitedUser({
                eventInvitee
            }),
        )
    }catch(error) {
        console.log(error)
    }
}