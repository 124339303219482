import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { useEffect } from 'react';
import { initSurvey } from 'src/store/event-website';
import { Loader } from 'src/shared-components/Loader';
import { BoxWebsite } from 'src/mui/pages/box-website';
import FactorEventLandingPage from '../event/factor-website';
import { WebsiteEventNavbar } from '../event/website/website-event-navbar';
import WebsiteEventFooter from '../event/website/website-event-footer';
import { DefaultBannerSurveys } from './DefaultBannerSurveys';

const menuItemsVenue = [
  {
    menuItemText: 'Home',
    href: '#banner',
    isSelected: true,
  },
  {
    menuItemText: 'Contact us',
    href: '#contact-us',
    isSelected: false,
  },
];

const menuItems = [
  {
    menuItemText: 'Home',
    href: '#banner',
    isSelected: true,
  },
  {
    menuItemText: 'Contact us',
    href: '#contact-us',
    isSelected: false,
  },
];

export const SurveyWebsiteView = () => {
  const params = useParams();

  const surveyId = params['*'];
  const dispatch = useDispatch();

  const loaded = useSelector((state: IAppState) => state.website.loaded);
  const survey: any = useSelector((state: IAppState) => state.website.survey!);

  useEffect(() => {
    if (!surveyId) return;
    dispatch(initSurvey(surveyId));
  }, [surveyId, dispatch]);
  if (!loaded || !survey) return <Loader isLoaderLogo className="h-[100vh]" key="loader" />;

  const renderDefaultOrAfterHours = () => (
    <div
      className="bg-gray-1"
      style={{
        background: survey?.colorStyle?.values?.background,
      }}
    >
      <WebsiteEventNavbar
        menuItems={menuItems}
        menuItemsVenue={menuItemsVenue}
        showCreateEventButton={false}
        isSurvey
      />

      <DefaultBannerSurveys />
      <div className="max-w-[1124px] mx-auto px-[20px] xl:px-0">
        <div
          className="font-semibold text-[#0326E5] text-[21px] leading-[25px] mb-[15px] pb-[16px] border-b-1 border-[#CDDCF2]"
          style={{
            color: survey?.colorStyle?.values?.heading,
          }}
        />
      </div>
      <WebsiteEventFooter />
    </div>
  );

  switch (survey.websiteTemplate) {
    case 'default':
    case 'afterHours':
      return renderDefaultOrAfterHours();
    case 'factor':
      return <FactorEventLandingPage survey={survey} />;
    case 'box':
      return <BoxWebsite />;
    default:
      return <div>Unsupported website template</div>;
  }
};
