import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ILinkIButtonProps extends React.ComponentProps<'button'> {
  text: string;
  backgroundColor: string;
  textColor: string;
  fontSize?: string;
  border?: string;
  width: string | number;
  height: string | number;
  href: string;
  classes?: string;
  replace?: boolean;
}

export const LinkButton: FC<ILinkIButtonProps> = ({
  text,
  backgroundColor,
  textColor,
  border,
  fontSize,
  width,
  height,
  href,
  classes,
  type = 'submit',
  replace = false,
  ...rest
}) => {
  const navigate = useNavigate();
  return (
    <>
      <button
        type={type}
        onClick={() => replace ? window.open(href, '_blank') : navigate(href)}
        className={[classes, 'text-center rounded-xl p-1'].join(' ')}
        style={{
          width,
          height,
          border,
          background: backgroundColor,
          color: textColor,
          fontFamily: 'Poppins',
          fontSize: fontSize ?? '13px',
          borderRadius: '20px'
        }}
        {...rest}>
        {text}
      </button>
    </>
  );
};
