import { ContactNavbar } from '../../components/navbar';
import Footer from '../home/footer';
import { Navbar } from '../../components';
import ContactForm from './contact-form';

export const ContactUs = () => (
  <div>
    <Navbar />
    <ContactNavbar />
    <ContactForm />
    <Footer />
  </div>
);
