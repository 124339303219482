// import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useCurrentUser, useUserDetails } from '../../../../utils/hooks';
// import { UserService } from '../../../../services';
import { mergeArrays } from '../../../../utils';
import { ArrowDownSvgIcon } from '../../../../shared-components/V2/IconSvg';
import { Avatar } from '../../../../shared-components/V2';

interface Props {
  userProfile?: any;
  userId?: string;
  isOwnProfileNot?: boolean;
  interests?: any;
  offers?: any;
}

export const UserHighlights = ({
  userProfile,
  userId,
  isOwnProfileNot,
  interests,
  offers,
}: Props) => {
  const user = useCurrentUser();
  const { avatar } = useUserDetails(user);
  // const [interests, setInterests] = useState([]);
  // const [offers, setOffers] = useState([]);

  // const getInterestMatch = useCallback(async (id: string) => {
  //   const userService = new UserService();
  //   const result = await userService.getMatchedInterestResults(id).then(({ data }) => data.map((x: any) => ({ ...x, type: 'interest' })) ?? []);
  //   setInterests(result);
  // }, []);

  // const getOfferMatch = useCallback(async (id: string) => {
  //   const userService = new UserService();
  //   const result = await userService.getMatchedOfferingResults(id).then(({ data }) => data.map((x: any) => ({ ...x, type: 'offer' })) ?? []);
  //   setOffers(result);
  // }, []);

  // useEffect(() => {
  //   if (userId) {
  //     getInterestMatch(userId);
  //     getOfferMatch(userId);
  //   } else {
  //     getInterestMatch(id!);
  //     getOfferMatch(id!);
  //   }
  // }, [getInterestMatch, userId, id, getOfferMatch]);

  return (
    <div
      className={classNames('md:w-[380px] w-full m-auto justify-center', {
        '': isOwnProfileNot,
      })}
    >
      {!!userId && !!(interests.length || offers.length) && (
        <div
          className={classNames('flex items-center justify-center mb-5 mt-5', {
            '': isOwnProfileNot,
          })}
        >
          <div className="flex -space-x-1 overflow-hidden mr-1">
            {isOwnProfileNot && (
              <Avatar
                className="ring-2 ring-white"
                src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${userProfile
                  ?.value?.avatar!}`}
                alt=""
                size="XSMALL"
              />
            )}
            <Avatar
              className="ring-2 ring-white"
              src={avatar}
              alt=""
              size="XSMALL"
            />
          </div>
          <div className="text-[15px] leading-[18px] text-[#172746] font-medium">
            Highlights
          </div>
        </div>
      )}
      <div
        className={classNames('md:w-[380px] mt-[20px] md:px-0 px-2', {
          '!-ml-0': isOwnProfileNot,
        })}
      >
        {mergeArrays(interests, offers).map((highlight: any, index) => (highlight?.type === 'interest' ? (
          <div
            className="flex justify-between items-center mb-2.5"
            key={`${highlight?.interest?.name}-${index}`}
          >
            <div className="bg-[#EDF2F5] text-sm font-medium leading-5 text-[#203C6E] py-2 px-2.5 rounded-5 sm:text-center text-left">
              {highlight?.interest?.name}
            </div>
            <div className="flex items-center">
              <div className="mr-6">
                <ArrowDownSvgIcon fillColor="#D3D3D3" className="rotate-90" />
              </div>
              <div className="text-sm font-medium leading-5 text-[#203C6E]">
                Help
                {' '}
                {isOwnProfileNot ? userProfile?.value?.firstName : 'me'}
                {' '}
                with
              </div>
            </div>
          </div>
        ) : (
          <div
            className="flex justify-between items-center mb-2.5"
            key={`${highlight?.interest?.name}-${index}`}
          >
            <div className="flex items-center">
              <div className="text-sm font-medium leading-5 text-[#203C6E]">
                Ask
                {' '}
                {isOwnProfileNot ? userProfile?.value?.firstName : 'me'}
                {' '}
                about
              </div>
              <div className="ml-6">
                <ArrowDownSvgIcon
                  fillColor="#D3D3D3"
                  className="-rotate-90"
                />
              </div>
            </div>
            <div className="bg-[#424D62] text-sm font-medium leading-5 text-white py-2 px-2.5 rounded-5 sm:text-center text-right">
              {highlight?.interest?.name}
            </div>
          </div>
        )))}
      </div>
    </div>
  );
};
