import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/login/panelisticon.svg';
import { ReactComponent as LogoWhite } from '../../assets/images/homepage/footer-logo.svg';
import classNames from 'classnames';


interface IPageHeader {
  isBlueTheme?: boolean,
}

export const PageHeader = ({ isBlueTheme }: IPageHeader) => {
  return (
    <>
      <div className="flex items-start justify-center md:justify-start">
        <Link to="/">
          {isBlueTheme ? <LogoWhite /> : <Logo />}
        </Link>

      </div>
      <div className="flex justify-center lg:justify-start mt-[7px]" >
        <div
          className={classNames(
              'text-[#3C3C3C] text-[13px] font-normal leading-5',
              {'!text-white': isBlueTheme }
            )
          }
        >
          Connecting professionals around the world
        </div>
      </div>
    </>
  )
  
};

export default PageHeader;
