export const extractUrls = (inputString: string, lower = false) => {
  const regexp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?!&//=]*)/gi;

  if (typeof inputString !== "string") {
    throw new TypeError(
      `The inputString argument should be a string, got ${typeof inputString}`
    );
  }

  if (inputString) {
    let urls = inputString.match(regexp);
    if (urls) {
      return lower ? urls.map((item) => item.toLowerCase()) : urls;
    } else {
      return [];
    }
  } else {
    return [];
  }
};
