import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TextInput, Typography } from '../../shared-components';
import { Button } from '../../shared-components/V2';
import { emailRegex } from '../../utils';
import { Navbar, Footer } from '../../components';
import { sendCode } from '../../store/user/forgotpassword/actions';
import { LOGIN_SIGNUP_RECOVERY } from '../../constants';
import { IAppState } from '../../store';
import ResetPassword from './resetpassword';

interface IForgotPassword {
  email: string;
}

export const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IForgotPassword>();

  const dispatch = useDispatch();
  const selector = useSelector((state: IAppState) => state.forgotPasswordEmail);

  const onSubmit = async (data: any) => {
    dispatch(sendCode(data));
  };

  if (selector.currentStep === 1) return <ResetPassword />;

  return (
    <>
      <Navbar />
      <div className="bg-[#001BF6] flex text-center h-[calc(100vh-112px)]">
        <div className="mx-auto">
          <div className="lg:-ml-10">
            <div className="flex w-full flex-wrap mt-28">
              <div className="flex flex-col ml-1">
                <div className="" />
                <div className="flex items-start justify-start text-lg0 leading-8 font-normal text-white">
                  Forgot password
                </div>

                <div className="text-left mt-2">
                  <div className="h2 font-normal text-white">Let's find your account</div>
                  <div className="font-normal text-white text-[13px] mt-12 pt-2">
                    Please type in your email address
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className="flex justify-center lg:justify-start pt-1">
                      <div className="text-left">
                        <TextInput
                          className="w-336 h-8 text-sm1 font-extralight text-[#666666] placeholder:text-[#666666] px-2.5 rounded-10"
                          textinputsize="medium"
                          type="email"
                          placeholder="Enter your email"
                          caption="Email address*"
                          {...register('email', {
                            required: {
                              value: true,
                              message: LOGIN_SIGNUP_RECOVERY.EMAILADDRESS_ISREQUIRED
                            },
                            pattern: {
                              value: emailRegex,
                              message: LOGIN_SIGNUP_RECOVERY.VALID_EMAILADDRESS
                            }
                          })}
                          isBlueTheme
                        />
                      </div>
                    </div>
                    <div>
                      {errors.email && (
                        <Typography
                          variant="ValidationMessage"
                          text={errors.email?.message!.toString()}
                          classes="flex content-start mt-1 email-validation-message"
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-end mt-6">
                      <Link
                        to="/loginorjoin"
                        className="text-sm1 flex items-center text-white font-semibold h-30 mr-6 hover:underline"
                      >
                        Cancel
                      </Link>
                      <Button
                        data-test-id="forgot-password-button"
                        type="submit"
                        text="Submit"
                        className="bg-white text-[#3855eb] text-sm1 font-semibold hover:bg-[#2000CD] hover:text-white !rounded-[8px]"
                        size="medium"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className="bg-black py-3" />
    </>
  );
};

export default ForgotPassword;
