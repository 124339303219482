import { useState } from "react"
import { getBrandingColors } from "src/mui/pages/event/live/liveEventStyling";

interface CommentFileSvgProps {
  isLivePage?: boolean;
}

export const CommentFileSvg: React.FC<CommentFileSvgProps> = ({ isLivePage }) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const colors = getBrandingColors();
    const islivePagebg = colors?.Secondary; 
    const liveEmojiColor = isLivePage ? colors?.Text : "#203c6e";
    return <svg xmlns="http://www.w3.org/2000/svg" width={isLivePage ? "26" : "32"} height={isLivePage ? "26" : "32"} viewBox="0 0 32 32"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
    >
    <g id="Group_19803" data-name="Group 19803" transform="translate(-523.5 -1073)">
      <circle id="Ellipse_1271" data-name="Ellipse 1271" cx="16" cy="16" r="16" transform="translate(523.5 1073)" fill={ isHover ? "#fff" : isLivePage ? islivePagebg :"#EDF2F5"}/>
      <g id="Group_19797" data-name="Group 19797" transform="translate(17.07 1068.669)">
        <path id="Path_583" data-name="Path 583" d="M6.54,4.5H20.82a2.04,2.04,0,0,1,2.04,2.04V20.82a2.04,2.04,0,0,1-2.04,2.04H6.54A2.04,2.04,0,0,1,4.5,20.82V6.54A2.04,2.04,0,0,1,6.54,4.5Z" transform="translate(508.707 6.65)" fill="none" stroke={liveEmojiColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <path id="Path_584" data-name="Path 584" d="M12.424,11.462a.962.962,0,1,1-.962-.962A.962.962,0,0,1,12.424,11.462Z" transform="translate(507.09 5.031)" fill="none" stroke={liveEmojiColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <path id="Path_585" data-name="Path 585" d="M23.706,17.985,18.641,15,7.5,21.566" transform="translate(507.861 7.457)" fill="none" stroke={liveEmojiColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      </g>
    </g>
  </svg>
  
  
}