import {
  useEffect,
  useState,
} from 'react';

import { useSelector } from 'react-redux';

import shoppingCartIcon from '../../../assets/images/shopping-cart.svg';
import { ISelectedTicket } from '../../../models/tickets';
import { IAppState } from '../../../store';

const TicketCart = ({discount}: any) => {
  const { eventTickets } = useSelector((state: IAppState) => state);
  const [tickets, setTickets] = useState<ISelectedTicket[]>([]);

  useEffect(() => {
    setTickets(eventTickets.selectedTickets);
  }, [eventTickets.selectedTickets]);

  const ticketPrice: number = (tickets.reduce(function (acc, ticket) {
    return acc + ticket.numberOfTickets * ticket.price;
  }, 0)/100);
  return (
    <div className="overflow-y-auto">
      {eventTickets.selectedTickets.length === 0 && (
        <div className="md:flex md:justify-center mt-2 flex items-center justify-center h-[170px]">
          <img src={shoppingCartIcon} alt="" />
        </div>
      )}
      {tickets.length > 0 && (
        <div className="flex flex-col">
          <div className="text-sm text-[#172746] font-medium leading-[14px] mb-5">
            Order summary
          </div>
          <div>
            {tickets.map((ticket, index) => (
              <div
                key={`ticket-${index}`}
                className="flex flex-row justify-between w-full mb-5"
              >
                <div className="text-sm text-[#172746] leading-5">
                  {ticket.numberOfTickets} x {ticket.ticketName} ticket
                </div>
                <div className="text-sm text-[#172746] leading-5">
                  {(ticket.numberOfTickets * parseFloat(`${ticket.price/100}`))}
                </div>
              </div>
            ))}
          </div>
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="330"
              height="1"
              viewBox="0 0 330 0.5"
            >
              <line
                id="Line_1400"
                data-name="Line 1400"
                x2="330"
                transform="translate(0 0.25)"
                fill="none"
                stroke="#cecece"
                strokeWidth="0.5"
              />
            </svg>
          </div>
          <div className="flex flex-row justify-between mt-5">
            <div className="text-sm text-[#172746] leading-5">Subtotal</div>
            <div className="text-sm text-[#172746] leading-5">
              $
              {ticketPrice}
            </div>
          </div>
          {
            !!discount && discount < ticketPrice && <div className="flex flex-row justify-between mt-5">
            <div className="text-sm text-[#172746] leading-5">Discount</div>
            <div className="text-sm text-[#172746] leading-5">
              - $
              {ticketPrice - discount}
            </div>
          </div>
          }
          <div className="mt-2.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="330"
              height="1"
              viewBox="0 0 330 0.5"
            >
              <line
                id="Line_1400"
                data-name="Line 1400"
                x2="345"
                transform="translate(0 0.25)"
                fill="none"
                stroke="#cecece"
                strokeWidth="0.5"
              />
            </svg>
          </div>
          <div className="flex flex-row justify-between mt-2.5">
            <div className="text-sm text-[#172746] leading-5 font-bold">
              Total
            </div>
            <div className="text-sm text-[#172746] leading-5 font-bold">
              $ {(!!discount && discount < ticketPrice) ? discount : ticketPrice}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketCart;
