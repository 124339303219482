import { useState } from 'react';
import { toast } from 'react-toastify';

export default function useCopyToClipboard() {
  const [isCopied, setIsCopied] = useState(false);
  function handleCopy(text: string) {
    if (typeof text === 'string' || typeof text === 'number') {
      navigator.clipboard.writeText(text);
      setIsCopied(true);
    } else {
      setIsCopied(false);
      toast.error(`Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`);
    }
  }

  return { isCopied, handleCopy };
}
