interface IMessageBadgeProps {
  countUnseenMessages: number;
  classes: string;
}

export const MessageBadge = ({ countUnseenMessages, classes }: IMessageBadgeProps) => {
  return (
    <>
      {countUnseenMessages > 0 && (
        <div
          className={`${classes} w-[20px] h-[20px] rounded-full bg-[#FF0000] text-white  flex justify-center messages-badge`}>
          <div className="text-s-10 leading-[20px] font-medium ">{countUnseenMessages}</div>
        </div>
      )}
    </>
  );
};

export default MessageBadge;
