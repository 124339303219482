import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { IEvent } from '../../../models/event/event';
import { IAppState } from '../../../store';
import { Banner } from './banner';
import { Container } from '@mui/material';
import { LenovoPages } from './lenovo-pages';
import { Partners } from './partners';
import { Footer } from './footer';
import SignupForm from './SignupForm';
import { useParams } from 'react-router-dom';
import { useCurrentUser } from 'src/utils/hooks';
import { useEffect } from 'react';
import { getMinifiedImage } from 'src/mui/utils/image';
import { init } from 'src/store/event-website';
import { Loader } from 'src/shared-components/Loader';

export const LenovoWebsite = () => {
  const params = useParams();

  const eventId: string = params?.slug!;
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const user = useCurrentUser();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(init(eventId));
  // }, [eventId, dispatch, user]);

  useEffect(() => {
    const link = document.querySelector('link[rel="icon"]');
    if (link) {
      link?.setAttribute('href', `${getMinifiedImage(event?.company?.logo!)}`);
      link.setAttribute('sizes', '16x16'); // Specify the favicon size (e.g., 16x16 pixels)
    }
  }, [event]);

  const loaded = useSelector((state: IAppState) => state.website.loaded);

  return loaded ? (
    <>
      <Helmet>
        <title>{event?.name}</title>
      </Helmet>
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          width: 1,
          p: 0,
          pl: '0px !important',
          pr: '0px !important',
          borderLeft: '1px solid #eee',
          borderRight: '1px solid #eee',
        }}
      >
        <Banner coverUrl={event?.cover!} />
        <LenovoPages event={event} />
        <Container
          maxWidth={false}
          sx={{
            width: 1,
            p: 0,
            pl: '12px !important',
            pr: '12px !important',
            '@media (min-width: 768px)': {
              maxWidth: '66.66666667%',
            },
            '@media (max-width: 768px)': {
              pl: '1.25rem !important',
              pr: '1.25rem !important',
            },
            flexGrow: 1,
          }}
        >
          <SignupForm />
        </Container>
        <Partners />

        <Footer />
      </Container>
    </>
  ) : <Loader isLoaderLogo className="h-[100vh]" key="loader" />;
};
