import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IUpcomingEventResult } from '../../../models/event/UpcomingEventResult';
import { IUpcomingEventState } from '../../../models/event/UpcomingEventState';
import { EventsService } from '../../../services/events';
import { UpcomingEventActionTypes } from './types';

export interface IUpcomingEventLoadingAction {
  type: UpcomingEventActionTypes.UPCOMING_EVENT_LOADING;
  loading: true;
}

export interface IResetUpcomingEvent {
  type: UpcomingEventActionTypes.RESET_UPCOMING_EVENT_LOADING;
  loading: boolean;
}

export interface IUpcomingEventSuccessAction {
  type: UpcomingEventActionTypes.UPCOMING_EVENT_SUCCESS;
  payload: IUpcomingEventResult;
}
export interface IUpcomingEventErrorAction {
  type: UpcomingEventActionTypes.UPCOMING_EVENT_ERROR;
  errorMessage: string;
}

export interface IUpcomingEventCleanUpAction {
  type: UpcomingEventActionTypes.UPCOMING_EVENT_CLEANUP;
}

export type UpcomingEventActions =
  | IUpcomingEventLoadingAction
  | IUpcomingEventSuccessAction
  | IUpcomingEventErrorAction
  | IResetUpcomingEvent
  | IUpcomingEventCleanUpAction;

export const getUpcomingEvent: ActionCreator<
  ThunkAction<
    Promise<any>,
    IUpcomingEventState,
    null,
    IUpcomingEventSuccessAction
  >
> = (eventIdOrSlug: string) => {
  return async (dispatch: Dispatch) => {
    const eventsService: EventsService = new EventsService();

    try {
      // loadUpcomingEvent(true);
      dispatch({
        type: UpcomingEventActionTypes.UPCOMING_EVENT_LOADING,
        loading: true,
      });
      const result = await eventsService.getUpcomingEventDetails(eventIdOrSlug);
      dispatch({
        type: UpcomingEventActionTypes.UPCOMING_EVENT_LOADING,
        loading: false,
      });
      // loadUpcomingEvent(false);
      dispatch({
        type: UpcomingEventActionTypes.UPCOMING_EVENT_SUCCESS,
        payload: result,
      });
    } catch (error: any) {
      // console.error(error);
      dispatch({
        type: UpcomingEventActionTypes.UPCOMING_EVENT_ERROR,
        errorMessage: error?.message,
      });
      // dispatch({
      //   type: UpcomingEventActionTypes.UPCOMING_EVENT_LOADING,
      //   loading: false,
      // });
    } finally {
      // dispatch({
      //   type: UpcomingEventActionTypes.UPCOMING_EVENT_LOADING,
      //   loading: false,
      // });
    }
  };
};

export const loadUpcomingEvent: ActionCreator<
  ThunkAction<any, IUpcomingEventState, null, IUpcomingEventLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: UpcomingEventActionTypes.UPCOMING_EVENT_LOADING,
    loading: shouldLoad,
  });

export const cleanupUpcomingEvent: ActionCreator<
  ThunkAction<any, IUpcomingEventState, null, IUpcomingEventLoadingAction>
> = () => (dispatch: Dispatch) =>
  dispatch({
    type: UpcomingEventActionTypes.UPCOMING_EVENT_CLEANUP,
  });
