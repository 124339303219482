import { UserNavbar } from '../../../components';
import ListAccountCompanies from './ListAccountCompanies';

export const CompaniesListLayout = () => (
  <div className="bg-[#EDF2F5] pb-[90px]">
    <UserNavbar />
    <div className="lg:container mx-auto lg:w-[1258px] w-full lg:px-0 px-4 md:pb-0 pb-[60px] mt-[30px] relative">
      {/* <div className="text-[#000000] text-[25px] leading-[33px] font-medium mb-[25px]">Companies List</div> */}
      <div className="xl:w-full lg:w-full w-full">
        <ListAccountCompanies />
      </div>
    </div>
  </div>
);

export default CompaniesListLayout;
