/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import axios from 'axios';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CustomSelectorComp } from '../../../../components/selectors/custom-selector';
import { LOGIN_SIGNUP_RECOVERY } from '../../../../constants';
import { TextInput } from '../../../../shared-components';
import { Button, Typography } from '../../../../shared-components/V2';
import { emailRegex } from '../../../../utils';

const meetTimeOneVlues = [
  { label: 'Select Time', value: 'WP' },
  { label: '10.20 AM', value: '10.20 AM' },
  { label: '10.40 AM', value: '10.40 AM' },
];

const meetTimeTwoVlues = [
  { label: 'Select Time', value: 'WP' },
  { label: '12.40 PM', value: '12.40 PM' },
  { label: '10.00 AM', value: '10.00 AM' },
];

interface IComplimentaryPassForm {
  registeredName: string;
  businessEmail: string;
  meetTime1: string;
  meetTime2: string;
  agree?: string;
}

export const ComplimentaryPassForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IComplimentaryPassForm>();
  const navigate = useNavigate();
  const location = useLocation();

  const eventSlug = location?.pathname?.split('/')[2];

  const meetTimeOne = (time: any) => {
    if (time.value) errors.meetTime1 = undefined;
    setValue('meetTime1', time.value);
  };

  const meetTimeTwo = (time: any) => {
    if (time.value) errors.meetTime2 = undefined;
    setValue('meetTime2', time.value);
  };

  const onSubmit = (data: any) => {
    axios.post('https://ea41f9f8-6143-4c97-9ec3-95b0c12ecaf1.mock.pstmn.io/complimentary', {
      registered_name: data.registeredName,
      business_email: data.businessEmail,
      meet_time_one: data.meetTime1,
      meet_time_two: data.meetTime2,
      agree: data.agree
    })
      .then(() => {
        toast.success('Successfully submit');
        navigate(`/event/${eventSlug}/spectre/successfully-registered`);
        sessionStorage.setItem('eventRegStep', '3');
      });
  };

  return (
    <div className="w-full mt-[15px]">
      <div className="w-full">
        <div className="w-full">
          <div className="text-white text-left text-[15px] leading-[20px] helvetica-neue-55-roman mb-[10px]">
            Please select time in order to proceed :
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="md:flex justify-between gap-x-[16px]">
              <div className="w-full md:mb-0 mb-5">
                <div className="w-full mb-[10px] relative">
                  <TextInput
                    textinputsize="medium"
                    caption="Confirm your registered name*"
                    labelclass="bg-[#2d2d2d] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                    type="text"
                    classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                    {...register('registeredName', {
                      required: { value: true, message: 'Your registered name is required!' }
                    })}
                  />
                </div>
                <div>
                  {errors.registeredName && (
                    <Typography
                      variant="ValidationMessage"
                      text={errors.registeredName?.message!.toString()}
                      classes="flex content-start mb-2.5 -mt-[12px]"
                    />
                  )}
                </div>
                <div className="w-full mb-[10px]">
                  <TextInput
                    textinputsize="medium"
                    caption="Confirm your business email*"
                    labelclass="bg-[#2d2d2d] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                    type="email"
                    placeholder="@companyname.com"
                    classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                    {...register('businessEmail', {
                      required: {
                        value: true,
                        message: 'Email is required',
                      },
                      pattern: {
                        value: emailRegex,
                        message: LOGIN_SIGNUP_RECOVERY.VALID_EMAILADDRESS,
                      },
                    })}
                  />
                </div>
                <div>
                  {errors.businessEmail && (
                    <Typography
                      variant="ValidationMessage"
                      text={errors.businessEmail?.message!.toString()}
                      classes="flex content-start mb-2.5 -mt-[12px]"
                    />
                  )}
                </div>
                <div className="w-full mb-[10px]">
                  <CustomSelectorComp
                    options={meetTimeOneVlues}
                    customStyle="helvetica-neue-35-thin contact-form-select"
                    placeholder="Select Time"
                    caption="Meeting time 1:"
                    labelclass="bg-[#2d2d2d] absolute left-[10px] -top-[8px] z-10 helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                    onChange={meetTimeOne}
                    register={register}
                    fieldName="meetTime1"
                    errorMessage="Please select meeeing time 1"
                  />
                </div>
                <div>
                  {errors.meetTime1 && (
                    <Typography
                      variant="ValidationMessage"
                      text={errors.meetTime1?.message!.toString()}
                      classes="flex content-start mb-2.5 -mt-[12px]"
                    />
                  )}
                </div>
                <div className="w-full mb-[10px]">
                  <CustomSelectorComp
                    options={meetTimeTwoVlues}
                    customStyle="helvetica-neue-35-thin contact-form-select"
                    placeholder="Select Time"
                    caption="Meeting time 2:"
                    labelclass="bg-[#2d2d2d] absolute left-[10px] -top-[8px] z-10 helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                    onChange={meetTimeTwo}
                    register={register}
                    fieldName="meetTime2"
                    errorMessage="Please select meeeing time 2"
                  />
                </div>
                <div>
                  {errors.meetTime2 && (
                    <Typography
                      variant="ValidationMessage"
                      text={errors.meetTime2?.message!.toString()}
                      classes="flex content-start mb-2.5 -mt-[12px]"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="flex row items-start gap-1 mb-2.5">
                <div className="flex items-center mb-1 column relative">
                  <input
                    type="checkbox"
                    autoComplete="off"
                    {...register('agree', {
                      required: { value: true, message: 'Please checked the given field!' }
                    })}
                  />
                  <label
                    className={classNames(
                      'text-[14px] leading-[17px] text-white ml-[8px] helvetica-neue-45-light'
                    )}
                  >
                    I understand that I will be charged for the catering fees of
                    AUD300+GST, if I do not show up without a prior notice of at
                    least one week. ‍(With this option please note your card will
                    not be charged today)
                  </label>
                </div>
              </div>
              <div>
                {errors.agree && (
                  <Typography
                    variant="ValidationMessage"
                    text={errors.agree?.message!.toString()}
                    classes="flex content-start mb-2.5 -mt-[12px]"
                  />
                )}
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                type="submit"
                size="small"
                text="Confirm and Submit"
                className="bg-[#0071e3] !text-[13px] !font-normal hover:bg-[#007fff] text-white tracking-wide h-[32px] w-[157px] p-0 helvetica-neue-55-roman"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ComplimentaryPassForm;
