export type RegistrationForm = {
  id: string;
  eventId: string;
  eventQuestionPageId: string;
  fieldType: string;
  fieldName: string;
  isRequired: boolean;
  prefix: string;
  ordering: number;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
  registrationOptionFields: any[];
  registrationFlow?: any[];
  registrationFlowAction?: any[];
};

export type UserRegistrationAnswer = {
  answer: string;
  id: string;
  userRegistrationField: {
    registrationFieldId: string;
    registrationField: {
      fieldType: string;
      fieldName: string;
    };
  };
  userRegistrationFieldId: string;
};

export enum RegistrationFieldType {
  TEXT = "text",
  SELECT = "select",
  RADIO = "radio",
  TERM_AND_CONDITION = "terms-and-conditions",
  PHONE_NUMBER = "phone",
  EMAIL = "email",
  ADDRESS = "address",
  FIRST_NAME = "first-name",
  LAST_NAME = "last-name",
  FULL_NAME = "full-name",
  JOB_TITLE = "job-title",
  JOB_FUNCTION = "job-function",
  COMPANY = "company",
  INDUSTRY = "industry",
  POSTAL_CODE = "postal-code",
  CHECKBOX = "checkbox",
  BUSINESS_EMAIL = "business-email",
  TICKET = "ticket",
  ORGANIZATION_SIZE = 'organization-size',

}

export enum ConditionType {
  AND = "and",
  OR = "or",
}

export enum ScenarioType {
  QUESTION = "question",
  PAGE = "page",
  SESSION = "session",
  DAY = "day",
}
