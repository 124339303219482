// @mui
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CustomPopover, { usePopover } from "src/mui/components/custom-popover";
import { Box, Button, MenuItem, Tooltip, Typography } from "@mui/material";

import { imageUrlPrefix } from "src/utils";
import { useDispatch, useSelector } from "react-redux";
import { closeMessageWindow, openMessageWindow } from "src/store/newMessage";
import MenuPopoverIcon from "./Icons/MenuPopoverIcon";
import { useEffect, useState } from "react";
import { useCurrentUser } from "src/utils/hooks";
import { EventsService } from "src/services/events";
import { IAppState } from "src/store";
import { toast } from "react-toastify";
import moment from "moment";
import { getUTCTimeZone } from "src/mui/assets/data/timezones";
import { setJoinMeeting } from "src/store/liveEvent";
import { callInvite } from "src/store/1-1-video-call/actions";
import { getBrandingColors } from '../event/live/liveEventStyling';
import defaultAvatar from 'src/assets/images/avatar-default.svg';
import MessageSvgIcon from "./Icons/MessageSvgIcon";
import ThreeDotsSvgIcon from "./Icons/ThreeDotsSvgIcon";
import MeetingDynamicSvgIcon from "./Icons/MeetingDynamicSvgIcon";
import ConnectionSvgIcon from "./Icons/ConnectionSvgIcon";
import { AvatarDefaultSmallSvgIcon } from "src/shared-components/V2/IconSvg/AvatarDefaultSmallSvgIcon/AvatarDefaultSmallSvgIcon";

type AttendeeProps = {
  attendee: any;
  isSpeaker?: boolean;
  textColor?: string;
  meetingTime?: string;
  columnWidth?: number;
  title?: string;
  inviteAsConnection?: boolean;
  viewType?: boolean;
  isSponsor?: boolean;
  isMeeting?: boolean;
  showCallButton?: boolean;
  meeting?: any;
  boothId?: string;
  showMeetLater?: boolean;
  isWhiteIcons?: boolean;
  requestCall?: (attendeeId: string) => void;
  setMeetingAttendee?: (data: any) => void;
  setOpenFeedbackPopup: any;
  isTeam?: boolean;
};

export const
HostAndAttendee = ({
  attendee: attendeeProp,
  isSpeaker,
  textColor,
  meetingTime,
  columnWidth,
  title,
  inviteAsConnection,
  viewType,
  isSponsor,
  isMeeting,
  showCallButton,
  meeting,
  showMeetLater,
  isWhiteIcons,
  requestCall,
  setMeetingAttendee,
  setOpenFeedbackPopup,
  isTeam
}: AttendeeProps) => {
  const popover = usePopover();
  const popoverTwo = usePopover();
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const colors = getBrandingColors();

  const [attendee, setAttendee] = useState<any>({});
  const [isListView, setIsListView] = useState<boolean>(viewType!!);
  const { event } = useSelector((state: IAppState) => state.liveEvent);

  const [currentTime, setCurrentTime] = useState<any>();
  useEffect(() => {
    if (event) setCurrentTime(moment().tz(getUTCTimeZone(event?.timezone!)!));
  }, [event]);

  const startTime = moment(meeting?.startTime).tz(
    getUTCTimeZone(event?.timezone!)!
  );
  const endTime = moment(meeting?.endTime).tz(
    getUTCTimeZone(event?.timezone!)!
  );
  const isMeetingActive = currentTime?.isBetween(
    startTime,
    endTime,
    null,
    "[]"
  );

  const sortingFilterOptions = [
    { value: "Stop camera", label: "Stop camera" },
    { value: "Load slides", label: "Load slides" },
    { value: "Remove from session", label: "Remove from session" },
    { value: "Invite as a connection", label: "Invite as a connection" },
    { value: "Message", label: "Message" },
  ];

  useEffect(() => {
    setAttendee(attendeeProp);
  }, [attendeeProp]);

  const handleProfile = () => {
    popover.onClose();
    if (attendee) {
      window.open(
        `/wall/${
          attendee?.slug || attendee?.leadId || attendee?.userId || attendee?.id
        }`
      );
    }
  };

  const handleMessage = async () => {
    popover.onClose();
    await dispatch(closeMessageWindow());
    await dispatch(
      openMessageWindow({
        avatar: attendee?.avatar! || attendee?.leadAvatar!,
        userDisplayName: `${
          attendee?.firstName ||
          attendee?.["First name"] ||
          attendee?.["leadFirst name"]
        } ${
          attendee?.lastName ||
          attendee?.["Last name"] ||
          attendee?.["leadLast name"]
        }`,
        userId: attendee?.leadId || attendee.id || attendee?.userId,
      })
    );
    setOpenFeedbackPopup(false);
  };

  const MINUTE_MS = 60000;
  useEffect(() => {
    if (!event) return;
    const interval = setInterval(() => {
      setCurrentTime(moment().tz(getUTCTimeZone(event?.timezone!)!));
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [event]);

  const getUserData = () => {
    const isLoggedInUser = user?.id === attendee?.leadId;
    const userData: any = {};
    if (title === "Leads") {
      if (isLoggedInUser) {
        userData.avatar = attendee?.userAvatar;
        userData.fullName = attendee?.userFullName;
        userData.firstName = attendee?.firstName || attendee?.["First name"];
        userData.organisation = attendee?.userOrganisation;
        userData.jobTitle = attendee?.jobTitle || attendee?.["Job title"];
      } else {
        userData.avatar = attendee?.leadAvatar;
        userData.firstName = attendee?.firstName || attendee?.["First name"];
        userData.fullName = attendee?.leadFullName;
        userData.organisation = attendee?.leadOrganisation;
        userData.jobTitle = attendee?.jobTitle || attendee?.["Job title"];
      }
    } else {
      userData.avatar = attendee?.avatar;
      userData.fullName = `${
        (attendee?.firstName ||
          attendee?.["First name"] ||
          attendee?.["leadFirst name"]) ??
        ""
      } ${
        (attendee?.lastName ||
          attendee?.["Last name"] ||
          attendee?.["leadLast name"]) ??
        ""
      }`;
      userData.firstName = attendee?.firstName || attendee?.["First name"];
      userData.organisation = attendee?.company?.name
        ? attendee?.company?.name
        : attendee?.eventSpeakers
        ? attendee?.eventSpeakers[0]?.company?.name
        : attendee["Organisation"] || "";
      userData.jobTitle = attendee?.jobTitle || attendee?.["Job title"];
    }
    return userData;
  };
  const { avatar, fullName, firstName, organisation, jobTitle } = getUserData();

  const getConnectionStatus = (data: any) => {
    let currentStatus = "Connect";
    if (data?.connectionStatus === "Connected") {
      currentStatus = "Connected";
    } else if (data?.connectionStatus === "Not Connected") {
      currentStatus = "Connect";
    } else if (data?.connectionStatus === "Pending") {
      if (data?.hasRequest) {
        currentStatus = "Approve";
      }

      // if (data?.sentRequest) {
      else currentStatus = "Sent";
      // }
    }
    return currentStatus;
  };

  const approveConnectionRequest = async (
    body: any,
    connectionRequestId: string
  ) => {
    const eventService = new EventsService();
    try {
      const res: any = await eventService.approveConnectionRequest(
        event?.id!,
        body,
        connectionRequestId
      );
      setAttendee({
        ...attendee,
        connectionStatus: "Connected",
      });

    } catch (error: any) {
      if (error.statusCode === 500) {
        toast.error("Something went wrong. Please try again!");
      } else toast.error(error.message);
    }
  };

  const handleApproveRequest = async (data: any) => {
    const body = {};

    const connectionRequestId = data?.connectionRequestId;
    approveConnectionRequest(body, connectionRequestId!);
  };

  const handleConnectMeeting = async (data: any) => {
    const body = {
      connectionUserId: data?.userId,
    };

    const eventService = new EventsService();
    try {
      const res: any = await eventService.connectWithSuggests(event?.id!, body);

      if (res?.message === "SUCCESS")
        setAttendee({
          ...attendee,
          connectionStatus: "Pending",
        });

    } catch (error: any) {
      if (error.statusCode === 500) {
        toast.error("Something went wrong. Please try again!");
      } else toast.error(error.message);
    }
  };

  const requestConnectButton = (data: any) => {
    const connectionStatus = getConnectionStatus(data);

    const status: any = {
      Connect: "Invite as Connection",
      Pending: "Connection Request Sent",
      Sent: "Connection Request Sent",
      Approve: "Approve Connection Request",
    };
    return (
      <MenuItem
        onClick={() => {
          if (connectionStatus === "Connect") {
            handleConnectMeeting(data);
          } else if (connectionStatus === "Approve") {
            handleApproveRequest(data);
          }
        }}
        sx={{ m: "6px", mb: 0, pl: "6px", color: colors?.Text, fontSize: '13px', fontWeight: 500, fontFamily: 'Poppins-500', "&:hover":{backgroundColor: colors?.Main} }}
      >
        {/* <Stack sx={{ mr: "4px", width: '26px' }}>
          {isWhiteIcons ? <ConnectWhiteIcon /> : <ConnectSVG />}
        </Stack> */}
        {status[connectionStatus] || "Invite as Connection"}
      </MenuItem>
    );
  };

  const renderListView = () => {
    return (
      <>
        <Stack
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            // marginBottom: "13px",
          }}
          key={attendee?.id}
        >
          <Box
            sx={{
              width: "100%",
              height: "87px",
              backgroundColor: `${isSponsor ? colors?.Main! : colors?.Secondary}`,
              color: colors?.Text,

              borderRadius: "10px",
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            { avatar ? (<Avatar
              src={avatar ? `${imageUrlPrefix}/${avatar}` : defaultAvatar}
              sx={{
                width: "55px",
                height: "55px",
                borderRadius: "22px",
                marginLeft: "9px",
                "&.MuiAvatar-root, & .MuiAvatar-img": {
                  minHeight: "55px",
                  maxHeight: "55px",
                  minWidth: "55px",
                },
              }}
              alt={attendee?.avatar}
            />) : (
              <Stack sx={{
                width: "55px",
                height: "55px",
                borderRadius: '22px',
                marginLeft: "9px",
                backgroundColor: 'transparent !important',
                maxWidth: "55px",
                maxHeight: "55px",
                border: `1px solid ${colors?.Text || '#000000'}`,
              }}
              >
                <AvatarDefaultSmallSvgIcon fillColor={colors?.Text} style={{ width: "55px", height: "55px" }} />
              </Stack>
            )}

            {attendee?.checkedInAt !== undefined &&
              attendee?.checkedInAt !== null && (
                <div
                  style={{
                    position: "absolute",
                    width: "21px",
                    height: "21px",
                    background: "#03ffcd",
                    left: "5px",
                    top: 15,
                    borderRadius: "50%",
                    border: "2px solid white",
                  }}
                />
              )}

            {isSpeaker && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: "8px",
                  lineHeight: "11px",
                  color: colors?.Text || "#000000",
                  fontFamily: "Poppins-600",
                  flexWrap: "nowrap",
                  backgroundColor: colors?.Secondary || "#EDF2F5",
                  border: `1px solid ${colors?.Main || "#ffffff"}`,
                  borderRadius: "3px",
                  p: "1px 5px",
                  position: "absolute",
                  left: "14px",
                  bottom: "16px",
                }}
              >
                Speaker
              </Typography>
            )}

            {meetingTime && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: "8px",
                  lineHeight: "11px",
                  color: "#000000",
                  fontFamily: "Poppins-600",
                  flexWrap: "nowrap",
                  minWidth: "44px",
                  textAlign: "center",
                  backgroundColor: "#03FFCD",
                  borderRadius: "3px",
                  p: "2px 5px",
                  position: "absolute",
                  left: "16px",
                  bottom: "14px",
                }}
              >
                {meetingTime}
              </Typography>
            )}
            <Box
              sx={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "40%",
              }}
            >
              <Typography
                className="ellipsis-one-line"
                variant="body2"
                sx={{
                  fontSize: "13px",
                  lineHeight: "22px",
                  color: colors?.Text || "white",
                  fontFamily: "Poppins-600",
                  maxWidth: "100%",
                  marginLeft: "7px",
                }}
              >
                {fullName}
              </Typography>

              <Typography
                className="ellipsis-one-line"
                variant="body2"
                sx={{
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: colors?.Text || "white",
                  fontFamily: "Poppins-400",
                  maxWidth: "100%",
                  marginLeft: "7px",
                }}
              >
                {jobTitle}
              </Typography>

              <Typography
                className="ellipsis-one-line"
                variant="body2"
                sx={{
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: colors?.Text || "white",
                  fontFamily: "Poppins-400",
                  maxWidth: "100%",
                  marginLeft: "7px",
                }}
              >
                {organisation}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                right: "8px",
                top: "11px",
              }}
            >
              {/* Box 1 */}
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: `${isSponsor ? colors?.Secondary || "#2f2f2f" : colors?.Main}`,
                    margin: "2px",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleMessage}
                >
                  <MessageSvgIcon />
                </Box>
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: `${isSponsor ? colors?.Secondary || "#2f2f2f" : colors?.Main}`,
                    margin: "2px",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={popover.onOpen}
                >
                  <ThreeDotsSvgIcon />
                </Box>
                <CustomPopover
                  open={popover.open}
                  onClose={popover.onClose}
                  // arrow="right-top"
                  sx={{
                    backgroundColor: colors?.Secondary, width: '250px',
                    mt: '8px',
                    ml: '12px',
                    "& .MuiMenuItem-root svg": {
                      marginRight: '10px'
                    },
                    "> span": {
                      backgroundColor: colors?.Secondary, // Arrow color
                    },
                  }}
                >
                  <MenuItem onClick={handleProfile} sx={{ m: "6px", fontSize: '13px', fontWeight: 500, fontFamily: 'Poppins-500', mb: 0, pl: "6px", color: colors?.Text, backgroundColor: "transparent", "&:hover": {backgroundColor: colors?.Main} }}>
                    {/* <Stack sx={{ mr: "4px", width: '26px' }}>
                      {isWhiteIcons ? <ConnectWhiteIcon /> : <ConnectSVG />}
                    </Stack> */}
                    Profile
                  </MenuItem>

                  {attendee?.connectionStatus !== "Connected" &&
                    inviteAsConnection &&
                    requestConnectButton(attendee)}

                  <MenuItem onClick={handleMessage} sx={{ m: "6px", fontSize: '13px', fontWeight: 500, fontFamily: 'Poppins-500', mt: 0, pl: "6px", color: colors?.Text, backgroundColor: "transparent", "&:hover": {backgroundColor: colors?.Main}  }}>
                    {/* <Stack sx={{ mr: "4px", width: '26px' }}>
                      {isWhiteIcons ? <MessageSVGWhite /> : <MessageSVG />}
                    </Stack> */}
                    Message
                  </MenuItem>
                </CustomPopover>
              </Box>
              {/* Box 1 end */}
              {/* Box 2 start */}
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: `${isSponsor ? colors?.Secondary || "#2f2f2f" : colors?.Main}`,
                    margin: "2px",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (setMeetingAttendee) setMeetingAttendee(attendee);
                  }}
                >
                  <MeetingDynamicSvgIcon />
                </Box>
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: `${isSponsor ? colors?.Secondary || "#2f2f2f" : colors?.Main}`,
                    margin: "2px",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={popoverTwo.onOpen}
                >
                  <ConnectionSvgIcon />
                </Box>
                <CustomPopover
                  open={popoverTwo.open}
                  onClose={popoverTwo.onClose}
                  // arrow="right-top"
                  sx={{
                    backgroundColor: colors?.Secondary, width: '250px',
                    mt: '8px',
                    "& .MuiMenuItem-root ": {
                      margin: '0px'
                    },
                    "& .MuiMenuItem-root svg": {
                      marginRight: '10px'
                    },
                    "> span": {
                      backgroundColor: colors?.Secondary, // Arrow color
                    },
                  }}
                >
                  {attendee?.connectionStatus !== "Connected" && requestConnectButton(attendee)}
                </CustomPopover>
              </Box>
              {/* Box 2 end */}
            </Box>
          </Box>
        </Stack>
      </>
    );
  };

  const getMeetingButton = () => (
    <Button
        sx={{
          borderRadius: "5px",
          height: "27px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "10px",
          lineHeight: "15px",
          fontFamily: "Poppins-600",
          background: colors?.Accent || "#ffffff",
          mt: "9px",
          textTransform: "none",
          width: "76px",
          padding: "0px",
          fontWeight: 600,
          color: colors?.ButtonTextColor || '#000000',
          "&:hover": { background: colors?.Accent || "#EDF2F5", opacity: '0.5' },
        }}
        onClick={() => {
          dispatch(
            callInvite(attendee.id, {
              id: attendee.id,
              firstName: attendee.firstName,
              avatar:`${imageUrlPrefix}/${attendee.avatar}`,
              cover: "",
              email: "",
              jobTitle: "",
              lastName: "",
            })
          );
          if (requestCall) requestCall(attendee.id);
        }}
      >
        Call now
      </Button>
  );

  const getMeetLater = () => (
    <Button
        sx={{
          borderRadius: "5px",
          height: "27px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "10px",
          lineHeight: "15px",
          fontFamily: "Poppins-600",
          background: colors?.Accent || "#ffffff",
          mt: "4px",
          textTransform: "none",
          width: "76px",
          padding: "0px",
          fontWeight: 600,
          color: colors?.ButtonTextColor || '#000000',
          "&:hover": { background: colors?.Accent || "#EDF2F5", opacity: '0.5' },
        }}
        onClick={() => {
          if (setMeetingAttendee) setMeetingAttendee(attendee);
        }}
      >
        Meet Later
      </Button>
  );

  const openMessageBox = () => (
    <Button
        sx={{
          borderRadius: "5px",
          height: "27px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "10px",
          lineHeight: "15px",
          fontFamily: "Poppins-600",
          background: colors?.Accent || "#ffffff",
          mt: "4px",
          textTransform: "none",
          width: "76px",
          padding: "0px",
          fontWeight: 600,
          color: colors?.ButtonTextColor || '#000000',
          "&:hover": { background: colors?.Accent || "#EDF2F5", opacity: '0.5' },
        }}
        onClick={handleMessage}
      >
        Message
      </Button>
  );



  const renderGridView = () => {
    return (
      <>
        <Stack
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
          key={attendee?.id}
        >
          <Stack
            sx={{
              position: "relative",
              mb:  showMeetLater ? "9px" : "5px",
              width: columnWidth ? "86px" : isTeam ? "76px" : "86px",
              height: columnWidth ? "86px" : isTeam ? "76px" : "86px",
            }}
          >
            {avatar ? (
              <Avatar
                src={avatar ? `${imageUrlPrefix}/${avatar}` : defaultAvatar}
                sx={{
                  width: columnWidth ? "86px" : isTeam ? "76px" : "86px",
                  height: columnWidth ? "86px" : isTeam ? "76px" : "86px",
                  borderRadius: showCallButton ? "30px" : "35px",
                  "&.MuiAvatar-root, & .MuiAvatar-img": {
                    minHeight: columnWidth ? "86px" : isTeam ? "76px" : "86px",
                    minWidth: columnWidth ? "86px" : isTeam ? "76px" : "86px",
                  },
                }}
                alt={attendee?.avatar}
              />
            ) : (
              <Stack sx={{
                width: "86px",
                height: "86px",
                borderRadius: '35px',
                backgroundColor: 'transparent !important',
                maxWidth: "86px",
                maxHeight: "86px",
                border: `1px solid ${colors?.Text || '#000000'}`,
              }}
              >
                <AvatarDefaultSmallSvgIcon fillColor={colors?.Text} style={{ width: "86px", height: "86px" }} />
              </Stack>
            )}

            {attendee?.checkedInAt !== undefined &&
              attendee?.checkedInAt !== null && (
                <div
                  style={{
                    position: "absolute",
                    width: "21px",
                    height: "21px",
                    background: "#03ffcd",
                    left: "0px",
                    top: 0,
                    borderRadius: "50%",
                    border: "2px solid white",
                  }}
                />
              )}
            {isMeetingActive &&
              (meeting?.userId === user?.id ||
                meeting?.attendeeId == user?.id) && (
                <div
                  style={{
                    position: "absolute",
                    width: "30px",
                    color: colors?.Text || "#ffffff",
                    height: "13px",
                    background: colors?.Secondary || "#000000",
                    left: "30px",
                    top: "7px",
                    borderRadius: "10px",
                    fontSize: "9px",
                    paddingLeft: "3px",
                    paddingRight: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    dispatch(
                      setJoinMeeting({ meetingId: meeting?.id, isOpen: true })
                    )
                  }
                >
                  Join
                </div>
              )}
            <IconButton
              sx={{
                width: "23px",
                height: "23px",
                p: 0,
                backgroundColor: "#EDF2F5",
                position: "absolute",
                right: "0px",
                bottom: meetingTime ? "none" : "0px",
                color: "#203C6E",
              }}
              color={popover.open ? "inherit" : "default"}
              onClick={popover.onOpen}
            >
              <MenuPopoverIcon />
            </IconButton>
            {isSpeaker && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: "8px",
                  lineHeight: "11px",
                  color: colors?.Text || "#000000",
                  fontFamily: "Poppins-600",
                  flexWrap: "nowrap",
                  backgroundColor: colors?.Secondary || "#EDF2F5",
                  borderRadius: "3px",
                  p: "2px 6px",
                  position: "absolute",
                  left: "0px",
                  bottom: "0px",
                }}
              >
                Speaker
              </Typography>
            )}

            {meetingTime && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  lineHeight: "13px",
                  color: colors?.Text || "#000000",
                  fontFamily: "Poppins-600",
                  flexWrap: "nowrap",
                  backgroundColor: colors?.Secondary || "#03FFCD",
                  borderRadius: "3px",
                  p: "2px 5px",
                  position: "absolute",
                  left: "0px",
                  bottom: "0px",
                  width: "100% !important",
                  textAlign: "center",
                }}
              >
                {meetingTime}
              </Typography>
            )}
          </Stack>

          <Stack
            sx={{ alignItems: "center", marginTop: "0px", maxWidth: columnWidth ? "90px" : "80px" }}
          >
            <Tooltip title={firstName}>
              <Typography
                className="ellipsis-one-line"
                variant="body2"
                sx={{
                  fontSize: showMeetLater ? "12px" : "12px",
                  lineHeight: showMeetLater ? "19px" : "17px",
                  color: colors?.Text || "white",
                  fontFamily: "Poppins-400",
                  maxWidth: "100%",
                  textAlign: "center",
                }}
              >
                {firstName}
              </Typography>
            </Tooltip>
            {!showCallButton ? (
              <Typography
                className="ellipsis-one-line"
                variant="body2"
                sx={{
                  fontSize: "12px",
                  lineHeight: "17px",
                  color: colors?.Text || "white",
                  fontFamily: "Poppins-400",
                  maxWidth: "100%",
                  textAlign: "center",
                }}
              >
                {organisation}
              </Typography>
            ) : (
              user?.id !== attendee?.id?

              (
                <>
                  {getMeetingButton()}
                  {showMeetLater && !isTeam && getMeetLater()}
                  {isTeam && openMessageBox()}
                </>
              ):null
            )}
          </Stack>
        </Stack>
        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          // arrow="right-top"
          sx={{
            backgroundColor: colors?.Secondary, width: '250px',
            mt: '8px',
            ml: '16px',
            "& .MuiMenuItem-root svg": {
              marginRight: '10px'
            },
            "> span": {
              backgroundColor: colors?.Secondary, // Arrow color
            },
          }}
        >

          <MenuItem onClick={handleProfile} sx={{ m: "6px", fontSize: '13px', fontWeight: 500, fontFamily: 'Poppins-500', mb: 0, pl: "6px", color: colors?.Text, backgroundColor: "transparent", "&:hover": {backgroundColor: colors?.Main} }}>
            {/* <Stack sx={{ mr: "4px" }}>
            {isWhiteIcons ? <ConnectWhiteIcon /> : <ConnectSVG />}
            </Stack> */}
            Profile
          </MenuItem>

          {attendee?.connectionStatus !== "Connected" &&
            // inviteAsConnection &&
            requestConnectButton(attendee)}
          {
            !isTeam && (
            <MenuItem onClick={handleMessage} sx={{ m: "6px", fontSize: '13px', fontWeight: 500, fontFamily: 'Poppins-500', mt: 0, pl: "6px", color: colors?.Text, backgroundColor: "transparent", "&:hover": {backgroundColor: colors?.Main}  }}>
              {/* <Stack sx={{ mr: "4px" }}>
              {isWhiteIcons ? <MessageSVGWhite /> : <MessageSVG />}
              </Stack> */}
              Message
            </MenuItem>
            )
          }

          {
            isTeam && (
            <MenuItem
              onClick={() => {
                if (setMeetingAttendee) setMeetingAttendee(attendee);
              }}
              sx={{ m: "6px", fontSize: '13px', fontWeight: 500, fontFamily: 'Poppins-500', mt: 0, pl: "6px", color: colors?.Text, backgroundColor: "transparent", "&:hover": {backgroundColor: colors?.Main}  }}>
              Meet Later
            </MenuItem>
            )
          }

        </CustomPopover>
      </>
    );
  };

  return <>{!isListView && !isMeeting ? renderListView() : renderGridView()}</>;
};
