import { useState } from 'react';
import { companyMatchDropdownOptions } from 'src/mui/components/hook-form/Common';
import PCompanyAutocomplete from 'src/mui/components/hook-form/PCompanyAutocomplete';

export type OptionType = {
  id: string;
  name: string;
};

type PMapFieldSelectProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  multiple?: boolean;
  defaultValue?: OptionType;
  sx?: any;
  Icon?: any;
  placeholder?: any;
  className?: string;
  options?: OptionType[];
  isBestMatch?: boolean;
};

const PMapFieldSelect = ({
  Icon,
  name,
  sx,
  label,
  disabled = false,
  multiple = false,
  defaultValue,
  placeholder,
  className,
  options,
  isBestMatch
}: PMapFieldSelectProps) => {
  const [inputValue, setInputValue] = useState('');
  return (
    isBestMatch ? (
      <PCompanyAutocomplete
        sx={sx}
        className={className}
        popupIcon={Icon}
        label={label}
        placeholder={placeholder}
        name={name}
        multiple={multiple}
        disabled={disabled}
        fullWidth
        inputValue={inputValue}
        onInputChange={(event, value) => {
          // sessionStorage.setItem('CreateNewCompany', inputValue);
          setInputValue(value);
        }}
    // onlySelectFromDropdown
        clearOnBlur
        filterSelectedOptions
        {...(defaultValue && { defaultValue })}
        options={options ?? []}
        getOptionLabel={(option: OptionType | string) => (option as OptionType).name ?? option}
        renderOption={(props, option: any) => (
          <li {...props} style={{ paddingLeft: '0px !important', paddingBottom: ' !important' }}>
            {companyMatchDropdownOptions(option, { minWidth: '250px' }, true)}
          </li>
        )}
      />
    ) : (
      <PCompanyAutocomplete
        sx={sx}
        className={className}
        popupIcon={Icon}
        label={label}
        placeholder={placeholder}
        name={name}
        multiple={multiple}
        disabled={disabled}
        fullWidth
    // onlySelectFromDropdown
        clearOnBlur
        filterSelectedOptions
        {...(defaultValue && { defaultValue })}
        options={options ?? []}
        getOptionLabel={(option: OptionType | string) => (option as OptionType).name ?? option}
      />
    )
  );
};

export default PMapFieldSelect;
