import { TextFieldProps } from '@mui/material';
// import { RHFTextField } from './RHFTextField';
import React from 'react';
import RHFTextField from './rhf-text-field';

type Props = TextFieldProps & {
  name: string;
};

export default function PTextField({ name, helperText, ...other }: Props) {
  return <RHFTextField name={name} helperText={helperText} {...other} />;
}
