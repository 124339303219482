import { useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import WebsiteEventTabs from './website-event-day-tabs';
import { BreakoutAgendaTabs, BreakoutAgendaBodyLobby } from './breakout-agenda';

import { makeRoomsTreeLobby } from '../../../utils/rooms-lobby';
import { IRoom } from '../../../models/event/room';
import { IEvent } from '../../../models/user-events';
import { IAppState } from '../../../store';

interface IEventAgendaProps {
  eventList?: IEvent;
}
const WebsiteEventAgendaLobby = (props: IEventAgendaProps) => {
  const ref = useRef(null);
  const roomTree = makeRoomsTreeLobby(props?.eventList?.rooms);
  const [selectedTabRooms, setSelectedTabRooms] = useState(roomTree[0]);
  const [selectedBreakoutRoom, setSelectedBreakoutRoom] = useState('');

  const event = useSelector((state: IAppState) => state.website.event!);

  const onClickTab = (data: any) => {
    setSelectedTabRooms(data);
  };

  // eslint-disable-next-line arrow-body-style
  const groupBy = (arr: any, property: string) => {
    // eslint-disable-next-line prefer-arrow-callback
    return arr.reduce((memo: any, x: any) => {
      // eslint-disable-next-line no-param-reassign
      if (!memo[x[property]]) { memo[x[property]] = []; }
      memo[x[property]].push(x);
      return memo;
    }, {});
  };

  return (
    <div
      id="Agenda"
      className={classNames('w-full mt-[0px]', {
        hidden: !(roomTree && roomTree.length > 0),
      })}
    >
      <div className="block md:flex mx-auto">
        <div className="w-full">
          <div className="mx-[0px] mt-[0px]">
            <div>
              <div className="flex items-center md:flex-row flex-col gap-y-[12px] gap-x-[28px] max-w-[745px] mb-[20px] mx-auto justify-center">
                {roomTree && roomTree.length > 1 && roomTree?.map((treeNode: any, index: any) => {
                  const parsedDate = parseISO(treeNode?.date);
                  const formattedDate = format(parsedDate, 'MMMM dd');
                  return (
                    treeNode?.roomsList[0]?.sessions?.length > 0 && (
                      <WebsiteEventTabs
                        tabName={`Day ${index + 1}`}
                        date={formattedDate}
                        onClick={onClickTab}
                        selectedTab={treeNode}
                        isSelectTab={treeNode.date === selectedTabRooms.date}
                        isLobbyDayTabs="!rounded-[15px]"
                      />
                      )
                  )
                  })}
              </div>
              {selectedTabRooms?.roomsList && selectedTabRooms?.roomsList?.length > 0 && (
                <div className="relative">
                  {
                    Object.keys(groupBy(selectedTabRooms?.roomsList, 'verticalOrdering'))
                      ?.map(key => {
                        const rooms = groupBy(selectedTabRooms?.roomsList, 'verticalOrdering')?.[key];
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        // const ref = useRef<HTMLDivElement>(null);
                        return (
                          <>
                            <div
                              className={classNames(
                                'w-full',
                                {
                                  hidden: rooms.length === 1,
                                }
                              )}
                            >
                              {rooms && rooms.length > 0 && (
                                <div
                                  ref={ref}
                                  className="flex items-center md:flex-row flex-col gap-y-[12px] gap-x-[28px] mb-[20px] mx-auto justify-center p-[20px] bg-[#ffffff21] rounded-[12px] max-w-[1050px]"
                                >
                                  {
                                  rooms && rooms.length > 1 && rooms.map(
                                    (room: IRoom, idx: any) => (
                                      room?.sessions.length > 0 && (
                                      <BreakoutAgendaTabs
                                        key={`${idx}-${room.id}`}
                                        room={room}
                                        roomType={`Breakout Session ${idx + 1}`}
                                        selectedTab={
                                          (selectedBreakoutRoom === `${idx}-${room.id}-${room.verticalOrdering}`)
                                          || (idx === 0
                                            && (selectedBreakoutRoom === `0-${room.id}-${room.verticalOrdering}`
                                              || !selectedBreakoutRoom
                                              || !selectedBreakoutRoom.endsWith(`${room.verticalOrdering}`)))
                                        }
                                        // backgroundColor={event?.colorStyle?.values?.backgroundButton}
                                        onClick={() => {
                                          setSelectedBreakoutRoom(`${idx}-${room.id}-${room.verticalOrdering}`);
                                        }}
                                      />
                                      )
                                    )
                                  )
                                }
                                </div>
                              )}
                            </div>
                            {rooms && rooms.length > 0 && rooms.map(
                              // eslint-disable-next-line no-nested-ternary
                              (room: IRoom, idx: any) => (selectedBreakoutRoom === `${idx}-${room.id}-${room.verticalOrdering}` ? (
                                <div
                                  className={classNames(
                                    'pr-[0px] pb-[0px]',
                                    {
                                      hidden: rooms[0].sessions.length === 0,
                                    }
                                  )}
                                >
                                  <BreakoutAgendaBodyLobby key={room.id} room={room} event={event} />
                                </div>
                              ) : (selectedBreakoutRoom === `${idx}-${room.id}-${room.verticalOrdering}`)
                                || (idx === 0 && (selectedBreakoutRoom === `0-${room.id}-${room.verticalOrdering}`
                                  || !selectedBreakoutRoom
                                  || !selectedBreakoutRoom.endsWith(`${room.verticalOrdering}`))) ? (
                                    <div
                                      className={classNames(
                                        'pr-[0px] pb-[0px]',
                                        {
                                          hidden: rooms[0].sessions.length === 0,
                                        }
                                      )}
                                    >
                                      <BreakoutAgendaBodyLobby key={idx} room={room} event={event} />
                                    </div>
                                ) : null)
                            )}
                          </>
                        );
                      })
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventAgendaLobby;
