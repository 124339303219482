import { FC, useState, useEffect, forwardRef, FocusEventHandler } from "react"

interface Props {
  initValue: string | undefined;
  onChangeText: (value: string) => void;
  onFocusText?: (value: string) => void;
  delay?: number;
  classNames?: string;
  placeholder?: string;
  type?: string;
  InputName?: string;
  style?: object;
}

export const DebounceInput = forwardRef<HTMLInputElement, Props>(
  (props: Props, ref) => {

  const [value, setValue] = useState<string>(props.initValue || '')
  useEffect(() => {
    // cleared input
    if (value.toString().length === 0) {
      return props.onChangeText(value)
    }

    const timeout = setTimeout(() => {
      props.onChangeText(value)

    }, props.delay)
    return () => clearTimeout(timeout)
  }, [value])

  useEffect(() => {
    if(props.initValue === ''){
      setValue('')
    }
  }, [props.initValue])

  return (
    <input
      name={props.InputName}
      className={`${props.classNames}`}
      type={props.type}
      value={value}
      ref={ref}
      {...props}
      placeholder={props.placeholder}
      style={props.style}
      autoComplete="off"
      onFocus={(e) => props.onFocusText && props.onFocusText(e.target.value)}
      onChange={(e) => setValue(e.target.value)}
    />
  )
}
)
