import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './convas-utils';

interface IUserCoverCropProps {
  imgUrl: string;
  onCropCover: Function;
  className?: string;
  isModalCrop?: boolean;
}

const UserCoverCrop = ({
  imgUrl,
  onCropCover,
  className,
  isModalCrop,
}: IUserCoverCropProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  function dataURItoBlob(dataURI: any) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString: any;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = decodeURI(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const onCropComplete = useCallback(async (_croppedArea: any, croppedAreaPixels: any) => {
    try {
      const croppedImage = await getCroppedImg(
        imgUrl,
        croppedAreaPixels,
      );
      const blob = dataURItoBlob(croppedImage);
      onCropCover(blob);
    } catch (e) {
      console.error(e);
    }
  }, [imgUrl, onCropCover]);

  return (
    <div
      className={classNames('w-full relative profile-cover-crop bg-white', {
        [`${className}`]: className
      })}
    >
      <Cropper
        image={imgUrl}
        crop={crop}
        zoom={zoom}
        aspect={isModalCrop ? 10 / 2.2 : 3.6}
        showGrid={false}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={onCropComplete}
        objectFit="horizontal-cover"
      />
    </div>
  );
};

export default UserCoverCrop;
