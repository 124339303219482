import { useEffect, useRef, useState } from 'react';
import { Transition } from '@tailwindui/react';
import classNames from 'classnames';
import { ThreeDotIcon } from '../IconSvg';
import React from 'react';

export interface IDropdownMenuItem {
  id: string | number;
  icon?: string;
  type?: string;
  text: string;
  subText?: string;
  url?: string;
  onClickHandler: () => void;
}

export interface IDropdownMenuProps {
  icon?: string;
  textClass?: string;
  url?: string;
  text?: string;
  subtext?: string;
  imageUrl?: string;
  options: IDropdownMenuItem[];
  menuCardClasses?: string;
  containerClasses?: string;
  buttonStyles?: string;
  buttonTitleStyles?: string;
  buttonTitle?: string;
  titleAndSubtitleGroup?: string;
  listTextStyles?: string;
  listSubtextStyles?: string;
  listIconStyles?: string;
  textAndImageContainer?: string;
  setScrolling?: any;
  fillColor?: "GRAY" | "WHITE";
  setIsDropdownOpen?: (isOpen: boolean) => void;
}

export const DropDownMenu = (props: IDropdownMenuProps) => {
  const ref = React.useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const handleClick = () => {
    setShowMenu(!showMenu);
  }

  useEffect(() => {
    props?.setIsDropdownOpen && props?.setIsDropdownOpen(showMenu);
  }, [showMenu]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!showMenu) {
        return;
      }

      if (event.key === 'Escape') {
        setShowMenu(false);
      }
    };

    document.addEventListener('keyup', handleEscape);
    return () => document.removeEventListener('keyup', handleEscape);
  }, [showMenu]);

  return (
    <div ref={ref} className={props.containerClasses}>
      <button
        onClick={handleClick}
        className={props.buttonStyles}
      >
        {props.buttonTitle && <span aria-label="dropdown-menu-target" className={props.buttonTitleStyles}>{props.buttonTitle}</span>}
        <ThreeDotIcon isActive={false} fillColor={props.fillColor ?? 'GRAY'} />
      </button>
      <Transition 
        show={showMenu} 
        enter="transition-opacity duration-75 z-10"
        enterFrom="opacity-0 z-10"
        enterTo="opacity-100 z-10"
        leave="transition-opacity duration-150 z-10"
        leaveFrom="opacity-100 z-10"
        leaveTo="opacity-0 z-10"
      >
        <div className={props.menuCardClasses}>
          {props.options &&
            props.options.map((option) => (
              <div
                key={option.id}
                className={props.titleAndSubtitleGroup}
                onClick={option.onClickHandler}
              >
                <div className={classNames(props.textAndImageContainer)}>
                  {option.icon && <div><img className={props.listIconStyles} src={option.icon} alt="" /></div>}
                  <div className={props.listTextStyles}>{option.text}</div>
                </div>
                {option.subText && (
                  <div>
                    <div className={props.listSubtextStyles}>{option.subText}</div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </Transition>
    </div>
  );
};
