import { IRoom } from '../../../models/event/room';
import { Stack, Switch, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import AvatarGroup, { avatarGroupClasses } from '@mui/material/AvatarGroup';
import { styled, useTheme } from '@mui/material/styles';
import { format, parseISO } from 'date-fns';
import { IEventSession } from 'src/models/event/event-session';
import { useBoolean } from 'src/mui/hooks/use-boolean';
import { ParseContent } from 'src/utils/parse-html';
import WebsiteEventSpeakerList from 'src/screens/event/website//website-event-day-speakerlist';
import WebsiteEventPartnerList from 'src/screens/event/website/website-event-day-partnerlist';
import { useDispatch, useSelector } from 'react-redux';
import { rewatchSession, setActiveRoom, setActiveSession } from 'src/store/liveEvent';
import { useEffect, useState } from 'react';
import profileBackground from '../../../assets/images/avatar-default.svg';
import { getBrandingColors, subscribeToColorChanges } from '../event/live/liveEventStyling';
import { colors } from 'react-select/dist/declarations/src/theme';
import { IAppState } from 'src/store';
import { AvatarDefaultSvgIcon } from 'src/shared-components/V2/IconSvg/AvatarDefaultSvgIcon/AvatarDefaultSvgIcon';
import { AvatarDefaultSmallSvgIcon } from 'src/shared-components/V2/IconSvg/AvatarDefaultSmallSvgIcon/AvatarDefaultSmallSvgIcon';


interface Props {
  session?: IEventSession;
  titleData?: JSX.Element | JSX.Element[];
  bodyData?: JSX.Element | JSX.Element[];
  styling?: string;
  roomQty?: number;
  borderColor?: string;
  isTitleLobby?: boolean;
  day?: any;
  onDrawerFalse?: () => void;
  onChangeToggle(session: string | undefined, status: boolean): any
}

const defaultAvatarStyles = {
  width: 60,
  height: 60,
  borderRadius: '24px',
  backgroundColor: '#edf2f5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}


export const MuiSwitchLarge = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 28,
  padding: 0,
  
  "& .MuiSwitch-switchBase": {
    padding: "1.5px 0px 2px 2px !important",
    width: '24px !important',
    height: '85% !important',
    margin: "2px !important",
    opacity: 1,
    transform: "translate(-4.7px, 0px)",
    
    "&.Mui-checked": {
      transform: "translate(19px, 0px)",
      "& .MuiSwitch-thumb": {
        background: `${getBrandingColors().Accent || '#6a6a6a'} !important`,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: '24px !important',
    height: '23px !important',
    backgroundColor: `${getBrandingColors().Secondary || '#6a6a6a'} !important`,
  },
  "& .MuiSwitch-track": {
    borderRadius: '40px !important',
    background: `${getBrandingColors().Main || '#000000'} !important`,
    width: '50px !important',
    height: '27px !important',
    border: `1px solid ${getBrandingColors().Secondary || '#6a6a6a'}`,
  },
}));

export const BreakoutSessionBody = ({ room, onChangeToggle, day, onDrawerFalse }: { room: IRoom, onChangeToggle(session: string | undefined, status: boolean): any, day: any, onDrawerFalse?: () => void }) => {
  const colors = getBrandingColors();
  const cardProperties = {
    p: '10px 8px 10px 8px', borderRadius: '10px', my: '0px', minHeight: '142px', backgroundColor: colors?.Main || '#2F2F2F', border: `1px solid ${colors?.Secondary || '#2F2F2F'}`
  };
  return (
    <Stack key={room.id} sx={{ height: 'max-content', width: '100%', gap: '14px' }}>
      {room?.sessions?.map(session => (
        <Stack sx={cardProperties} key={session?.id}>
          <AccordionLayout session={session} onChangeToggle={onChangeToggle} day={day} onDrawerFalse={onDrawerFalse} />
        </Stack>
      ))
      }
    </Stack>
  )
};

export const  CurrentOrNextBreakoutSessionBody = ({ session, onChangeToggle, day, onDrawerFalse }: { session: any, onChangeToggle(session: string | undefined, status: boolean): any, day: any, onDrawerFalse?: () => void }) => {
  const colors = getBrandingColors();
  const cardProperties = {
    p: '10px 8px 10px 8px', borderRadius: '10px', my: '0px', minHeight: '142px', backgroundColor: colors?.Main || '#2F2F2F', border: `1px solid ${colors?.Secondary || '#2F2F2F'}`
  };
  return (
    <Stack key={session.id} sx={{ height: 'max-content', width: '100%' }}>
      <Stack sx={cardProperties} key={session?.id}>
        <AccordionLayout session={session} onChangeToggle={onChangeToggle} day={day} onDrawerFalse={onDrawerFalse} />
      </Stack>
    </Stack>
  )
};

const BadgeReturner = ({ sx, text, color, ...others }: any) => (
  <Stack {...others} sx={{
    ...sx,
    backgroundColor: color?.Secondary! || '#ffffff',
    // border: `1px solid ${color?.Accent! || '#ffffff'}`,
    color: color?.Text! || '#000000',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '11px',
    fontFamily: 'Poppins-600',
    fontWeight: 600
  }}>
    {text}
  </Stack>
)

const AccordionLayout = ({ session, day, onDrawerFalse, onChangeToggle }: Props) => {
  const theme = useTheme();
  const showMore = useBoolean();
  const dispatch = useDispatch();
  const [readMeCheck, setReadMeCheck] = useState(session?.isNotificationEnabled);
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);

  const inPersonEvent = liveEvent?.event?.format === "hybrid" || liveEvent?.event?.format === "live";
  const onRewatchClick = (session: IEventSession, sessionRoom: IRoom) => {
    dispatch(rewatchSession(session.id));
    dispatch(setActiveRoom(sessionRoom));
    dispatch(setActiveSession(session));
    onDrawerFalse && onDrawerFalse();
  };
  useEffect(() => {
    const unsubscribe = subscribeToColorChanges(() => {
      getBrandingColors();
    });
    return unsubscribe;
  }, []);

  const colors = getBrandingColors();

  const isShowMoreButton: boolean = !!(
    session?.eventSpeakers?.length ||
    session?.eventSponsors?.length ||
    session?.summary
  );

  const isPastEvent = (Date.parse(session?.endTime!) < Date.now())
  return (
    <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }}>
      <Stack sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', pr: '15px' }}>
        {
          session && session?.eventSpeakers[0]?.firstName
            ? (<Stack sx={{ position: 'relative' }}>
              <AvatarGroup
                max={1}
                variant="square"
                sx={session?.eventSpeakers?.length ? {
                  [`& .${avatarGroupClasses.avatar}`]: {
                    width: 57,
                    height: 57,
                    borderRadius: '24px',
                    border: 'none',
                    backgroundColor: 'transparent !important'
                  },
                  
                } : null}

              >
                {session?.eventSpeakers?.slice(0, 1).map((participant) => (
                  <Avatar key={participant.id} alt={participant.firstName} src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${participant.photo}`} />
                ))}
              </AvatarGroup>
              {session?.eventSpeakers?.length && (
                <Stack
                  sx={{
                    position: 'absolute',
                    width: '57px',
                    height: '17px',
                    borderRadius: '3px',
                    backgroundColor: colors?.Accent || '#ffffff',
                    fontSize: '9px',
                    fontFamily: 'poppins-500',
                    fontWeight: 500,
                    lineHeight: '18px',
                    color: colors?.Main! || '#000000' ,
                    textAlign: 'center',
                    left: '0px',
                    bottom: '-6px',
                    textTransform: 'capitalize'
                  }}
                >
                  {session?.type}
                </Stack>
              )}
            </Stack>
            ) : (
              <Stack sx={{ position: 'relative' }}>
                {/* {session?.eventSpeakers.length === 0 && <Avatar alt="user default" src={profileBackground} sx={defaultAvatarStyles} />} */}
                {session?.eventSpeakers.length === 0 && <Stack sx={{
                  width: 57,
                  height: 57,
                  borderRadius: '24px',
                  backgroundColor: 'transparent !important',
                  maxWidth: 57,
                    maxHeight: 57,
                    border: `1px solid ${colors?.Text || '#000000'}`,
                }}>
                  <AvatarDefaultSmallSvgIcon fillColor={colors?.Text} />
                  </Stack>}
                {session?.eventSpeakers?.length === 0 && (
                  <Stack
                    sx={{
                      position: 'absolute',
                      width: '60px',
                      height: '18px',
                      borderRadius: '3px',
                      backgroundColor: colors?.Accent || '#ffffff',
                      fontSize: '9px',
                      fontFamily: 'poppins-500',
                      fontWeight: 500,
                      lineHeight: '18px',
                      color: colors?.Main! || '#000000' ,
                      textAlign: 'center',
                      left: '0px',
                      bottom: '-12px',
                      textTransform: 'capitalize'
                    }}
                  >
                    {session?.type}
                  </Stack>
                )}
              </Stack>
            )
        }
        <Stack sx={{ marginLeft: '10px' }}>
          <Stack sx={{ mb: '0px' }}>
            <Typography variant="subtitle1" lineHeight="16px" color={colors?.Text} sx={{ fontFamily: 'Poppins-600', mb: '0px', fontSize: '12px', opacity: '0.7' }}>
              {`${session?.eventSpeakers?.length ? session?.eventSpeakers[0]?.firstName : ''} ${session?.eventSpeakers?.length ? session?.eventSpeakers[0]?.lastName : ''}`} | {format(parseISO(session?.startTime!), 'HH:mm')}
              {' '}
              -
              {format(parseISO(session?.endTime!), 'HH:mm')}
            </Typography>
          </Stack>
          <Stack sx={{ mb: '0px' }}>
            <Typography className='ellipsis-three-line' variant="body2" sx={{ mt: 0, fontFamily: 'Poppins-600', color: colors?.Text, fontSize: '12px', lineHeight: '16px', "& span": { color: '#CECECE !important', lineHeight: '17px' } }} >
              <ParseContent content={session?.title} />
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {showMore.value &&
        <>
          <Typography variant="body2" sx={{ mt: '16px', fontFamily: 'Poppins-400', fontSize: "12px", color: colors?.Text!, lineHeight: '16px', "& span": { color: colors?.Text!, lineHeight: '17px', fontSize: "13px", } }} >
            <ParseContent content={session?.summary} />
          </Typography>
         { (session?.eventSpeakers?.length  ||  session?.eventSponsors!?.length) ? <Stack spacing="5px" sx={{ display: 'flex', flexDirection: 'column', mt: "16px" }}>
            {session?.eventSpeakers?.length ? <WebsiteEventSpeakerList isLive speakers={session?.eventSpeakers!} /> : null}
            {session?.eventSponsors!?.length  ?  <WebsiteEventPartnerList isLive sponsors={session?.eventSponsors!} /> : null }
          </Stack> : null
          }
        </>
      }
      
      <Stack sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'row', mt: showMore.value ? "10px" :  '22px', justifyContent: isShowMoreButton ? 'space-between' : 'flex-end', gap: !inPersonEvent ? '6px' : '', mb: 0 }}>
        {isShowMoreButton ? <BadgeReturner sx={{ width: '100%', height: '28px', lineHeight: '28px' }} text={showMore.value ? `- See less` : `+ See more`} onClick={() => showMore.onToggle()} color={colors} /> : null}
        <Stack>
          {
            isPastEvent && !inPersonEvent && (
              <Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', mt: "0px", justifyContent: 'end', gap: '2%' }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => onRewatchClick(session!, day)}
                  sx={{
                    maxWidth: '110px',
                    backgroundColor: colors?.Main!,
                    border: `1px solid ${colors?.Accent! || '#ffffff'}`,
                    color: colors?.Accent! || '#000000',
                    paddingX: 2,
                    height: '28px',
                    mt: 0,
                    fontSize: 11,
                    borderRadius: '5px !important',
                    fontFamily: 'Poppins-600',
                    fontWeight: 600,
                    lineHeight: '28px',
                    textTransform: 'capitalize',
                    "&:hover": { background: colors?.Secondary || "#EDF2F5", opacity: '0.5' },
                  }}
                >
                  Rewatch
                </Button>
              </Stack>
            )
          }
        </Stack>
        {!isPastEvent && (
          <Stack sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '8px', color: getBrandingColors()?.Text || '#ffffff', mb: '1px', fontFamily: 'Poppins-600', lineHeight: "11px" }}>Remind me</Typography>
            <MuiSwitchLarge checked={readMeCheck} color='default'
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={async (e: any) => {
                const response = await onChangeToggle(session?.id, e.target.checked)
                setReadMeCheck(response)
              }} />
          </Stack>
        )}
      </Stack>
    </Stack >
  );
}
