import React from 'react';

import {
  format,
  parseISO
} from 'date-fns';
import { Link } from 'react-router-dom';

import { Typography } from '../../../shared-components';
import { EventCoverAndLogo } from '../../event-cover-and-logo';
import classNames from 'classnames';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';
const colors = getBrandingColors();

interface ISharedEventProps {
  slug: string;
  cover: string;
  logo: string;
  name: string;
  companyName: string;
  startTime: any;
  endTime: any;
  eventFormat: string;
  eventRegions?: any;
  isCompanyNew?: boolean;
  isLivePage?: boolean;
}

export const SharedEvent = ({
  slug,
  cover,
  logo,
  name,
  companyName,
  startTime,
  endTime,
  eventFormat,
  eventRegions,
  isCompanyNew,
  isLivePage
}: ISharedEventProps) => {
  return (
  <div className="w-full text-xs">
    <div className={classNames("relative overflow-hidden", {
      'h-[146px]':isCompanyNew,
    })}>
      <Link to={`/event/${slug}/discussion`}>
        <EventCoverAndLogo
          cover={`${cover}`}
          logo={`${logo}`}
          logoClassName='left-5 bg-red'
          isCompanyNew={isCompanyNew}
        />
        <div className={classNames("w-full bottom-0 inset-x-0 bg-[#EDF2F5] text-left px-[18px] py-2.5",{
          'absolute': isCompanyNew,
          ' bg-opacity-[0.1]': isLivePage
        })}
        // style={{ backgroundColor: isLivePage ? colors?.Secondary : '' }}
        >
          <Typography
            variant="ContainerHeading"
            text={name}
            classes={classNames(
              "!text-[13px] !leading-[17px]  !font-semibold mb-[2px]",
              { "line-clamp-1": isCompanyNew, "text-[#172746]": !isLivePage }
            )}
            style={{
              color: isLivePage ? colors?.Text : undefined,
            }}
          />

          <Typography
            variant="SmallTitle"
            style={{
              color: isLivePage ? colors?.Text : undefined,
            }}
            text={`
      ${eventRegions?.map((loc: any) => loc.name)}
        ${companyName ? `• ${companyName}` : ""}`}
            classes={classNames("capitalize font-[400]",{
              "line-clamp-1": isCompanyNew,
            })}
            
          />
          {!!startTime && !!endTime && <div className={classNames("visible",{
            "hidden": isCompanyNew,
          })}>
          <Typography
            variant="SmallTitle"
            style={{
              color: isLivePage ? colors?.Text : undefined,
            }}
            text={`${format(parseISO(startTime), 'dd MMM yyyy')} ● ${format(
              parseISO(endTime),
              'HH:mm aaaa'
            )}`}
            classes="font-[400]"
            
          />
          </div> 
          }
          <div className={classNames("visible",{
            "hidden": isCompanyNew,
          })}>
          <Typography style={{
              color: isLivePage ? colors?.Text : undefined,
            }} variant="SmallTitle" text={`${eventFormat} event`} classes="capitalize font-[600] text-[#3C3C3C]" />
          </div>
        </div>
      </Link>
    </div>
  </div>
)};

export default SharedEvent;
