/* eslint-disable max-len */
import { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Logo } from 'src/shared-components/V2/Logo';
import { format, parseISO } from 'date-fns';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { IEvent } from '../../../models/user-events';
import { IAppState } from '../../../store';
import AddAgendaIcon from './AddAgendaIcon';
import { SessionModal } from './website-event-card-';

interface IEventAgendaProps {
  eventList?: IEvent;
}
const WebsiteEventAgendaLobbySpectre = (props: IEventAgendaProps) => {
  const [isSessionModal, setIsSessionModal] = useState(false);
  const [selectedSessoin, setSelectedSessoin] = useState<any>({});

  const event = useSelector((state: IAppState) => state.website.event!);

  const getEventAgenda = (room: any) => {
    if (room?.sessions?.length) {
      return room?.sessions?.map((session: any, index: number) => {
        const firstName = session?.eventSpeakers[0]?.firstName;
        const lastName = session?.eventSpeakers[0]?.lastName;
        const jobTitle = session?.eventSpeakers[0]?.jobTitle;
        const photo = session?.eventSpeakers[0]?.photo;
        const companyName = session?.eventSpeakers[0]?.company?.name;
        const sponsorship = session?.eventSponsors[0]?.sponsorship;
        const companyLogo = session?.eventSponsors[0]?.company?.logo;
        const sponsorCompanyName = session?.eventSponsors[0]?.company?.name;

        return (
          <div className="relative flex-1 w-[340px] min-w-[340px] h-[165px] bg-[#14161A] rounded-md" key={`agenda-${index}`} onClick={() => { setSelectedSessoin(session); setIsSessionModal(true); }}>
            <div className="p-[15px] flex flex-col justify-between h-[165px]">
              <div>
                <div className="text-[9px] font-[500] leading-[10px] text-white helvetica-neue-55-roman uppercase mb-[3px]">{session.type}</div>
                <div className="text-[11px] font-[500] leading-[12px] text-white helvetica-neue-55-roman mb-[15px]">
                  {format(parseISO(session?.startTime), 'hh:mm a')}
                  {' '}
                  -
                  {format(parseISO(session?.endTime), 'hh:mm a')}
                </div>
              </div>

              <div className="absolute right-[10px] top-[10px] cursor-pointer">
                <AddAgendaIcon />
              </div>

              <div className="text-[18px] leading-[22px] text-white helvetica-neue-55-roman max-w-[307px] ellipsis-two-line">
                {session?.title}
              </div>

              <div className="w-auto mt-[15px] min-w-[220px]">
                <div className="text-left flex">
                  <div className="relative">
                    {(photo || companyLogo) && (
                      <div className="w-[36px] min-w-[36px] h-[36px] min-h-[36px] rounded-[6px] overflow-hidden mr-[5px] flex items-center justify-center">
                        <Logo
                          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${firstName ? photo : companyLogo}`}
                          className={classNames('w-full h-full medium', {
                            'object-cover bg-[#2d2d2d]': firstName,
                            'object-contain bg-[#2d2d2d]': !firstName
                          })}
                        />
                      </div>
                    )}

                    {session?.eventSpeakers?.slice(1).length ? (
                      <div className="flex justify-center items-center w-[18px] h-[18px] bg-white text-[10px] leading-[18px] text-black font-[500] line-clamp-1 absolute bottom-[2px] right-[7px] rounded-[5px] helvetica-neue-55-roman text-center">
                        +
                        {session?.eventSpeakers?.slice(1).length || 0}
                      </div>
                    ) : null}

                  </div>

                  <div className="flex flex-col justify-center">
                    <div className="text-[14px] leading-[17px] text-white font-[500] line-clamp-1 helvetica-neue-55-roman">
                      {firstName ? `${firstName} ${lastName}` : sponsorCompanyName}
                    </div>

                    {session?.eventSpeakers?.slice(1).length

                      ? (
                        <div className="text-[12px] leading-[17px] text-white font-[600] line-clamp-1 helvetica-neue-55-roman">
                          And
                          {' '}
                          {session?.eventSpeakers?.slice(1).length}
                          {' '}
                          others
                        </div>
                      )
                      : (
                        <div className="text-[14px] leading-[17px] text-white font-[500] line-clamp-1 helvetica-neue-55-roman">
                          {firstName ? jobTitle : ''}
                          {' '}

                          {firstName ? `• ${companyName}` : sponsorship}
                        </div>
                      )}
                  </div>
                </div>
              </div>

            </div>
          </div>
        );
      });
    }
    return null;
  };

  return (
    <div
      id="Agenda"
      className={classNames('w-full mt-[40px]')}
    >
      <div className="block md:flex mx-auto">
        <div className="w-full">
          <div
            className="text-[20px] text-white font-[500] leading-[22px] helvetica-neue-65-medium mb-[15px]"
          >
            AGENDA
          </div>
          <div className="mx-[0px] mt-[0px] flex flex-row gap-[14px] flex-wrap">
            {props?.eventList?.rooms?.map((room) => getEventAgenda(room))}

            <AlertModal
              isOpen={isSessionModal}
              isCloseIcon
              isCloseGreen
              closeIconClass="right-[0px] top-[8px] "
              onClose={() => setIsSessionModal(false)}
              className="w-[1031px] !py-[20px] p-[20px] !pb-[30px] my-0 overflow-hidden bg-[#14161A] max-h-[530px] overflow-y-scroll !rounded-10"
            >
              <SessionModal
                session={selectedSessoin}
                event={event}
              />
            </AlertModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventAgendaLobbySpectre;
