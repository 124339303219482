import { Reducer } from 'redux';

import { IPost } from '../../../models/post/post';
import { UserPostsActions } from './actions';
import { UserPostActionTypes } from './types';

type Nullable<T> = T | null;

export interface UserPostsState {
  value: Nullable<IPost[]> | [];
  loading: boolean;
  errorMessage: string;
}

const initialState: UserPostsState = {
  value: null,
  loading: false,
  errorMessage: '',
};

export const UserPostsReducer: Reducer<UserPostsState, UserPostsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UserPostActionTypes.USER_POSTS_LOADING: {
      return {
        ...state,
        loading: state.loading,
      };
    }
    case UserPostActionTypes.USER_POSTS_SUCCESS: {
      return {
        ...state,
        value: action.payload,
      };
    }

    case UserPostActionTypes.USER_POSTS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null,
      };
    }

    default:
      return state;
  }
};
