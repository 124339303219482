/* eslint-disable no-console */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NoEvents } from 'src/screens/events/event-tab/components/no-events';
import { Logo } from 'src/shared-components/V2/Logo';
import defaultLogo from '../../../assets/images/company-default.svg';

type ICompanyEventListCardProps = {
  events: any[];
  onClick: (event: any) => void;
};

export const CompanyEventListCard = (props: ICompanyEventListCardProps) => {
  const navigate = useNavigate();
  const darkenColor = (rgbArray: number[], factor = 0.6) => {
    const [r, g, b] = rgbArray || [255, 255, 255]; // Default to white if no color
    const darken = (value: number) => Math.max(0, Math.floor(value * factor)); // Darkens by a factor
    return `rgb(${darken(r)}, ${darken(g)}, ${darken(b)})`;
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props?.events?.length > 0 ? (
        props.events.map((item: any) => (
          <div
            key={`search-event-${item.id}`}
            className="flex sm:flex-row flex-col items-center justify-between border-b-1 border-[#CDDCF2] py-0 pb-3 sm:pb-0 bg-white"
          >
            <div className="flex items-center pb-[20px] pt-[20px] text-blue-800 w-full">
              <div
                className="flex-shrink-0 cursor-pointer small-radius"
                style={{
                  backgroundColor: item?.event?.coverDominantColor
                    ? darkenColor(item?.event?.coverDominantColor)
                    : '#edf2f5',
                }}
              >
                <Logo
                  alt="event-logo"
                  className="w-[44px] max-w-[44px] min-w-[44px] h-[44px] max-h-[44px] min-h-[44px] object-contain bg-center small-radius"
                  src={
                    item?.event?.logo
                      ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.event?.logo}`
                      : defaultLogo
                  }
                  defaultLogo="company"
                />
              </div>
              <div
                className="flex flex-col items-start pl-4"
                onClick={() => {
                  props?.onClick(item);
                  if (item?.event?.slug) {
                    navigate(`/event/${item?.event?.slug}/discussion`);
                  }
                }}
              >
                <span className="text-[14px] leading-5 font-semibold text-[#172746] cursor-pointer">
                  {`${item?.event?.name || ''}`}
                </span>
                <span className="text-[12px] leading-[18px] font-medium text-[#172746] cursor-pointer">
                  {item?.event?.format === 'live'
                    ? 'In-Person'
                    : item?.event?.format || ''}
                </span>
                {/* <span className="text-[12px] leading-[18px] font-NORMAL text-[#172746] cursor-pointe">{item.location}</span> */}
              </div>
            </div>
          </div>
        ))
      ) : (
        <NoEvents />
      )}
    </>
  );
};

export default CompanyEventListCard;
