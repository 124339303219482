/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelNotification } from 'src/components/cancel-notification';
import { setJoinMeeting } from 'src/store/liveEvent';
import { ReactionProvider } from 'src/components/chime-video-call/reaction-context';
import { IAppState } from '../../store';
import {
  callAnswer,
  callCancel,
  callDecline,
  callHangUp,
  callCancelledClose,
} from '../../store/1-1-video-call/actions';
import { ChimeVideoCall } from '../../components/chime-video-call';
import AlertModal from '../../components/alert-modal/alert-modal';
import { CallingNotification } from '../../components/call-notification';
import { IVideoCallState } from '../../store/1-1-video-call';
import { VideoControlls } from '../../components/chime-video-call/video-controlls';

export const VideoCall = () => {
  const chatVideoCall: IVideoCallState = useSelector(
    (state: IAppState) => state.chatVideoCall
  );
  const isCallingFromLivePage = window.location.pathname.endsWith('/live');
  const dispatch = useDispatch();
  const [isOnCall, setIsOnCall] = useState(false);
  const [isConnectingCall, setIsConnectingCall] = useState(false);
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const selector = useSelector((state: IAppState) => state);
  const { joinMeeting } = selector.liveEvent;
  useEffect(() => {
    if (chatVideoCall?.userBusy) return;
    setIsOnCall(
      !!chatVideoCall.chimeInvitation
        || !!chatVideoCall.aParty
        || !!chatVideoCall.bParty
    );
    setIsConnectingCall(
      !chatVideoCall.chimeInvitation
        && (!!chatVideoCall.aParty || !!chatVideoCall.bParty)
    );

    if (
      isCallAccepted
      && !chatVideoCall.aParty
      && !chatVideoCall.bParty
      && !chatVideoCall.chimeInvitation
    ) {
      setIsCallAccepted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatVideoCall]);

  useEffect(() => {
    if (isOnCall && isConnectingCall) {
      dispatch(setJoinMeeting({ ...joinMeeting, isOpen: false }));
    }
  }, [isOnCall, isConnectingCall]);

  const incomingNotification = () => (
    <CallingNotification
      title="Incoming Call..."
      acceptBtnText={isCallAccepted ? 'Connecting..' : 'Accept'}
      disableAcceptBtn={isCallAccepted}
      onAcceptCall={() => {
        setIsCallAccepted(true);
        dispatch(callAnswer());
      }}
      onRejectCall={() => dispatch(callDecline())}
      avatar={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${chatVideoCall?.bParty
        ?.aPartyInfo?.avatar!}`}
      timer={30}
      onTimeout={() => dispatch(callDecline())}
      className="z-20"
    />
  );

  const cancelledNotification = () => (
    <CancelNotification
      title="User is Busy..."
      closeModal={() => dispatch(callCancelledClose())}
      avatar={chatVideoCall?.userBusy?.caller?.avatar}
      className="z-20"
    />
  );

  const outgoingNotification = () => (
    <CallingNotification
      title="Calling..."
      timer={30}
      avatar={chatVideoCall?.aParty?.aPartyInfo?.avatar!}
      onTimeout={() => dispatch(callCancel())}
      onRejectCall={() => dispatch(callCancel())}
      className="z-20"
    />
  );

  const callingNotification = () => (chatVideoCall.aParty ? outgoingNotification() : incomingNotification());

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {chatVideoCall?.userBusy ? (
        cancelledNotification()
      ) : isOnCall && isConnectingCall ? (
        callingNotification()
      ) : !isCallingFromLivePage ? (
        <AlertModal
          className="flex-col items-center justify-center overflow-auto"
          isOpen={isOnCall}
          onClose={() => {}}
          disableClose
        >
          <div className="">
            <ReactionProvider chatVideoCall={chatVideoCall}>
              <ChimeVideoCall
                onCallHangup={() => {
                  dispatch(callHangUp());
                }}
                overrideChimeConfig={chatVideoCall.chimeInvitation}
              />
            </ReactionProvider>
            <VideoControlls
              onCallHangup={() => {
                dispatch(callHangUp());
              }}
            />
          </div>
        </AlertModal>
      ) : null}
    </>
  );
};
