import { FC, useState } from 'react';
import classNames from 'classnames';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { ArrowDownSvgIcon } from 'src/shared-components/V2/IconSvg';
import ActionOfCardEvent1 from './ActionOfCardEvent1';
import { IEvent } from '../../../../models/event/event';
import { IUserProfile } from '../../../../models/user-profile';
import { Typography } from '../../../../shared-components';
import { DropDownEventButton } from '../../../../shared-components/V2/DropDownEventButton';
import { Button } from '../../../../shared-components/V2';
import { Logo } from '../../../../shared-components/V2/Logo';
import { EventsService } from '../../../../services/events';
import AlertModal from '../../../../components/alert-modal/alert-modal';

import './cardEventsInterested.css';
import { getCompanyName, minifiedImage } from '../../../../utils';

export interface ICardEventsInterestedItems {
  coverUrl: string;
  avatarUrl: string;
  title: string;
  subTitle: string;
  startTime: string;
  endTime: string;
  regions: string;
  eventType: string;
  eventLink: string;
  isDropdown: string;
  event: IEvent;
  user: IUserProfile;
  dropdownIcon?: string;
  className?: string;
  param?: string;
}

export const CardEventsInterested: FC<ICardEventsInterestedItems> = ({
  coverUrl,
  avatarUrl,
  title,
  subTitle,
  startTime,
  endTime,
  regions,
  eventType,
  eventLink,
  isDropdown,
  event,
  user,
  dropdownIcon = '/images/Polygon-dropdown.svg',
  className,
  param
}) => {
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleAlert, setToggleAlert] = useState(false);
  const navigate = useNavigate();

  const isLive = (startTime: any, endTime: any) => {
    if (!startTime || !endTime) return false;
    const currentDate: number = parseISO((new Date()).toISOString()).getTime();
    const sDate: number = parseISO(startTime).getTime();
    const eDate: number = parseISO(endTime).getTime();

    return (sDate <= currentDate) && (currentDate <= eDate);
  };

  const join = () => {
    navigate(`/event/${event && event.slug}/discussion?from=live-register`);
  };

  const interested = () => {
    new EventsService().eventInterested(event.id).then(res => {
      toast.success(res.message);
    });
  };

  const handleJoin = () => {
    new EventsService().getEventsAttendeeStatus(event.id).then(res => {
      const eventAttendeeStatus = res.data.audienceStatus;
      if (eventAttendeeStatus === 'accepted') navigate(`/${getCompanyName(event)}/${event && event.slug}/live`);
      else if (eventAttendeeStatus === 'pending') {
        setToggleAlert(true);
        setToggleModal(true);
      } else {
        setToggleAlert(false);
        setToggleModal(true);
      }
    });
  };

  const handleRegister = () => {
    navigate(`/event/${event && event.slug}/discussion?from=live-register`);
  };

  const onCloseModal = () => setToggleModal(false);

  return (
    <div
      className={classNames('flex-col relative event-card-single', {
        [`${className}`]: className,
      })}
    >
      <div className="text-sm5">
        <div>
          <Link to={eventLink}>
            <div
              style={{
                backgroundImage: `url("${minifiedImage(coverUrl)}")`
              }}
              // eslint-disable-next-line max-len
              className={classNames('event-card-single-cover relative flex items-end justify-start cursor-pointer mx-auto bg-cover bg-no-repeat bg-center rounded-t-10 px-[20px] py-2.5 bg-gray-50 mb-2.5')}
            >
              {avatarUrl !== '' ? (
                <div className={classNames('w-16 h-16 flex items-center justify-center p-[1px] bg-white bg-center small-radius')}>
                  <Logo
                    alt="avatar"
                    className="w-[62px] h-[62px] min-w-[62px] min-h-[62px] object-contain bg-center small-radius-4"
                    src={minifiedImage(avatarUrl)}
                    defaultLogo="company"
                  />
                </div>
              ) : (
                <div className={classNames('w-16 h-16 flex items-center justify-center bg-[#edf2f5] bg-center small-radius')}>
                  <Logo
                    alt="avatar"
                    className="w-[56px] h-[56px] object-contain bg-center small-radius"
                    src={minifiedImage(avatarUrl)}
                    defaultLogo="company"
                  />
                </div>
              )}
              {
                isLive(startTime, endTime) && (
                  <span className="w-14 h-20 flex items-center justify-center absolute right-3 top-3 rounded-full font-semibold bg-orange-3 text-x-sm text-center px-[14px] text-blue-3">
                    LIVE
                  </span>
                )
              }
            </div>
          </Link>
        </div>

        <div className="px-[20px] pb-[15px]">
          <div className="text-left event-card-single-text">
            <Link to={eventLink}>
              <Typography variant="SubTitle" text={title} classes="font-semibold !text-[#172746] leading-[17px] line-clamp-2" />
            </Link>
            <div className="flex flex-row">
              <Typography
                variant="LocationSubTitle"
                text={subTitle}
                classes="!text-[12px] !font-light !text-[#3C3C3C] !leading-[14px]"
              />
            </div>
            <div className="text-[12px] font-light text-[#3C3C3C] leading-[17px] line-clamp-1">
              {regions}
            </div>
            <div className="text-[12px] font-normal text-[#203C6E] leading-[18px] line-clamp-1">
              {startTime && format(parseISO(startTime), 'dd MMM yyyy • hh:mm a')}
            </div>
            <div className="text-[12px] font-normal text-[#172746] leading-[18px] line-clamp-1 capitalize">
              {eventType === 'live' ? 'In-Person' : eventType}
            </div>
          </div>
          <div className="flex items-center justify-between text-center w-full static">
            {
              ((isDropdown === 'true') && (!param))
              && (
                <div>
                  <DropDownEventButton
                    text="RSVP"
                    // iconRight={dropdownIcon}
                    iconRight={<ArrowDownSvgIcon className="ml-[10px]" fillColor="#7283A4" />}
                    classes="h-[30px]"
                    menuItems={[
                      {
                        id: '1', name: 'Register', url: '', onClick: join
                      },
                      {
                        id: '2', name: 'Interested', url: '', onClick: interested
                      }
                    ]}
                  />
                </div>
              )
            }
            {
              ((isDropdown === 'true') && (param === 'pending-invitations'))
              && (
                <div>
                  <DropDownEventButton
                    text="RSVP"
                    iconRight={dropdownIcon}
                    classes="h-[30px]"
                    menuItems={[
                      {
                        id: '1', name: 'Accept', url: '', onClick: join
                      },
                      {
                        id: '2', name: 'Decline', url: '', onClick: interested
                      }
                    ]}
                  />
                </div>
              )
            }
            {
              (((isDropdown === 'true') && (param === 'upcoming-events')) || ((isDropdown === 'true') && (param === 'past-events')))
              && (
                <Link to={eventLink}>
                  <Button
                    text="Details"
                    className="w-[102px] h-[30px] bg-[#0049EF] hover:bg-[#2e6eff] rounded-[5px] text-white text-sm font-semibold leading-[20px] tracking-wide"
                    type="button"
                  />
                </Link>
              )
            }
            {
              ((((isDropdown === 'true') && (param === 'my-hosted-events')) || ((isDropdown === 'true') && (param === 'my-interested-events'))))
              && (
                <div>
                  <Button
                    text="Manage"
                    className="w-[102px] h-[30px] bg-[#0049EF] hover:bg-[#2e6eff] rounded-[5px] text-white text-sm font-semibold leading-[20px] tracking-wide"
                    type="button"
                    onClick={() => window.open(`${process.env.REACT_APP_EVENT_PORTAL_URL}/events/${event.id}/details`)}
                  />
                </div>
              )
            }
            {
              isDropdown === 'false'
              && (
                <div>
                  <Button
                    text="Register now"
                    className="w-[124px] h-[30px] bg-[#0049EF] hover:bg-[#2e6eff] rounded-[5px] text-white text-sm font-semibold leading-[20px] tracking-wide"
                    type="button"
                    onClick={() => handleJoin()}
                  />
                </div>
              )
            }
            {toggleModal && (
              <AlertModal
                isOpen={toggleModal}
                onClose={onCloseModal}
                className="w-[500px]"
              >
                <>
                  <div className="text-blue-4 font-semibold text-md1 px-3 border-b-1 border-gray-16 p-3">
                    Event registration
                  </div>
                  <div className="text-blue-4 text-md1 p-3 text-center">
                    {
                      toggleAlert
                        ? 'We’ll notify you once your registration has been accepted.'
                        : 'You need to register to attend this live event!'
                    }

                  </div>
                  {toggleAlert && (
                    <div className="text-center flex justify-center">
                      <div className="flex items-center mt-3">
                        <button
                          type="button"
                          onClick={onCloseModal}
                          className="bg-gray-200 mt-2 w-32 h-8 text-sm mr-7 px-6 py-1.5 rounded-[5px] text-blue-3 font-semibold center justify-center whitespace-no-wrap"
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  )}
                  {!toggleAlert && (
                    <div className="text-center flex justify-center">
                      <div className="flex items-center mt-3">
                        <button
                          type="button"
                          onClick={handleRegister}
                          className="rounded-[5px] inline-block text-sm font-[600] h-30 text-white bg-[#0049EF] hover:bg-[#2e6eff] px-4 mr-2"
                        >
                          Register now
                        </button>
                        <button
                          type="button"
                          onClick={onCloseModal}
                          className="bg-gray-1 hover:bg-gray-7 active:bg-gray-7 h-30 rounded-[5px] tracking-wide text-blue-2 text-sm1 font-semibold px-4"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </>
              </AlertModal>
            )}
            <div className="mt-2">
              <ActionOfCardEvent1 event={event} user={user} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
