import {
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import VideoExpoPanel from './video-and-expo-panel';
import { IExpoBooth } from 'src/models/expo';
import { IAttendee } from 'src/models/events-live/attendee';
import { IEventSpeakersData } from 'src/models/event/event-speakers-data';
import { useExpoWebSocket, useWebSocket } from 'src/utils/hooks';
import { IAppState } from 'src/store';
import { getPolls } from 'src/store/livePoll';
import { setNextSession } from 'src/store/liveEvent';
import { IEvent } from 'src/models/event/event';
import { Stack } from '@mui/material';
import { color } from 'framer-motion';
import { bgGradient } from 'src/mui/theme/css';

type VideoAndAudiencePanelProps = {
  booths: IExpoBooth[];
  event: IEvent;
  attendees?: IAttendee[];
  speakers?: IEventSpeakersData[];
  setRoomID: (value: string) => void;
  isGridView?: boolean;
  calculateVideoGridHeight?:any
};

export const VideoAndAudiencePanel = ({ booths, event, attendees, speakers, setRoomID, isGridView ,calculateVideoGridHeight}: VideoAndAudiencePanelProps) => {
  const dispatch = useDispatch();
  const { webSocket } = useWebSocket();
  const expoWebsocket = useExpoWebSocket();
 
  const [screenMode, setScreenMode] = useState<'small' | 'large' | 'hidden' | undefined>('small');
  const currentSession = useSelector((state: IAppState) => state.liveEvent.session?.current);
  const onDemandRooms = useSelector((state: IAppState) => state.liveEvent.onDemandRooms);
  const [videoUrl, setVideoUrl] = useState<string | undefined>();

  const playbackFinished = () => {
    dispatch(setNextSession());
  };

  useEffect(() => {
    if (onDemandRooms && currentSession) {
      const roomSession = onDemandRooms?.map(room => room.sessions).flat();
      const session = roomSession.find(session => session.id === currentSession?.id);
      setVideoUrl(session?.eventSessionVideos[0]?.videoUrl ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${session?.eventSessionVideos[0]?.videoUrl}` : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSession]);

  useEffect(() => {
    webSocket.then(ws => {
      ws.send(
        JSON.stringify({
          action: 'poll-appeared'
        })
      );
    });
  }, [webSocket]);

  useEffect(() => {
    dispatch(getPolls(event?.id, currentSession?.id!));
  }, [dispatch, currentSession?.id, event?.id]);
  return (
    <Container sx={{ p: '0px !important', mr: '68px' }} maxWidth={false} >
      <Stack
        // alignItems="center"
        direction="row"
        sx={{
          ...bgGradient({
            direction: '135deg',
            startColor: `${color}.darker`,
            endColor:`${color}.darker`,
          }),
          p: 0,
          borderRadius: 1,
          textAlign: 'center',
          color: `${color}.darker`,
          backgroundColor: 'common.white',
          height: calculateVideoGridHeight ,
          boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        }}
        component={Card}
      >
        <VideoExpoPanel
          title={event?.name}
          url={videoUrl || event?.rooms?.[0]?.primaryHlsUrl}
          logoURL={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event?.company?.logo}`}
          expoData={booths}
          screenMode={screenMode}
          startTime={event?.startTime}
          endTime={event?.endTime}
          setScreenMode={(mode: 'small' | 'large' | 'hidden' | undefined) => setScreenMode(mode)}
          attendees={attendees}
          setPlayBackVideo={playbackFinished}
          setRoomID={setRoomID}
          isGridView={isGridView}
        />
      </Stack>
    </Container>
  )
};
