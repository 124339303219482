import attractaudience from '../../../assets/images/overview/attract-audience2.svg';
import attractscreens from '../../../assets/images/overview/attract-screens.png';
import attractscreens2 from '../../../assets/images/overview/attract-screens@2x.png';

export const AttractAudience = () => (
  <div>
    <div
      className="w-full"
      style={{
        background: `url(${attractaudience})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '680px'
      }}
    >
      <div className="container mx-auto text-center">
        <div className="lg:text-xl3 font-extralight text-xl text-dark-blue pt-3">
          Attract the right audience
        </div>
      </div>

      <div className="2xl:container 2xl:mx-auto block md:flex w-11/12 mt-12 pt-1 ">
        <div className="2xl:pl-6 ml-32 w-4/12 pl-5">
          <div className="lg:text-xl3 font-extralight text-xl text-dark-blue">
            Promote your event to your audience
          </div>
          <ul className="list-disc font-extralight text-dark-blue text-md1 mt-4 ml-6">
            <li>Event website</li>
            <li>Pre-event discussion page</li>
            <li>Dedicated live event platform</li>
          </ul>
          <p className="text-lg font-extralight text-dark-blue mt-5 mr-40 text-left w-11/12 mt-4">
            Generate native landing pages with unique URLs to promote your event to your audience
          </p>
        </div>
        <div className="mt-3 ml-32 2xl:ml-48 ">
          <img
            className="-ml-2"
            srcSet={`${attractscreens} 300w, ${attractscreens2} 1000w`}
            sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 500px"
            alt=""
            src={attractscreens2}
          />
        </div>
      </div>
    </div>
  </div>
);

export default AttractAudience;
