/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Loader } from 'src/shared-components/Loader';
import { UserNavbar } from 'src/components';
import { CardEventsInterestedContainerNew } from 'src/shared-components/V2/CardEventsInterestedContainer/CardEventsInterestedContainer-new';
import classNames from 'classnames';
import { IPostSpotLight } from 'src/models/post/IPostSpotlight';
import { UserService } from 'src/services';
import CompanyEventLayout from '../../../layouts/company-event-layout';
import CompanyLayout from '../../../layouts/company-layout';
import CompanySpotlightLayout from '../../../layouts/company-spotlight-layout';
import { IAppState } from '../../../store';
import { getCompanyEmployees } from '../../../store/company/company-employees/actions';
import { getCompanyPosts } from '../../../store/company/company-posts/actions';
import {
  getCompanyProfilePreview,
  resetCompanyProfilePreview,
} from '../../../store/company/profile-preview/actions';
import { getContent } from '../../../store/library-content/actions';
import { useCurrentUser, useUserDetails } from '../../../utils/hooks';
import { EventFilters } from '../../search-results/filter/events-filters';
import { MiniSpotlight } from '../../spotlight/mini-spotlight';
import CompanyEvents from './company-events';
import CompanyHeader from './company-header';
import { CompanyInfoAndConnections } from './company-info-and-connections';
import { CompanyPosts } from './company-posts';
import { CompanySpotlightTab } from './company-spotlight';
import { getRecommendedEvents } from '../../../store/user/recommended-events/actions';
import { CompanySpotlightNew } from './company-spotlight-new';
import SwiperEvents from './events/SwiperEvents';
import ConnectionsNew from './connections-new';
import Products from './products';
import { CompanyProductTab } from './company-product-tab';
import { NoProductList } from '../company-profile/no-product-list';

export const CompanyViewAsVisitorNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyidorslug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isloader, setIsloader] = useState(false);
  const [specificSpotlight, setSpecificSpotlight] = useState<IPostSpotLight[]>([]);
  const { companyProfilePreview, companyEmployees, posts } = useSelector(
    (state: IAppState) => state
  );
  const [companyUpcomingevents, setCompanyUpcomingEvents] = useState<any[]>();
  const recommendedEvents = useSelector(
    (state: IAppState) => state.recommendedEvents
  );

  const user = useCurrentUser();
  const { companyAdmin } = useUserDetails(user);

  const [activeTab, setActiveTab] = useState('overview');
  const [filter, setFilter] = useState<string>('');
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) setActiveTab(tab);

    dispatch(getCompanyProfilePreview(companyidorslug));
    dispatch(getCompanyPosts(companyidorslug));
    dispatch(getCompanyEmployees(companyidorslug));
    dispatch(getRecommendedEvents());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, companyidorslug]);

  useEffect(() => {
    const fetchData = async (id: string) => {
      const userService: UserService = new UserService();
      try {
        const result = await userService.getCompanyUpcomingEvents(id);
        setCompanyUpcomingEvents(result?.data);
      } catch (error: any) {
        console.error(error);
      }
    };
    if (companyProfilePreview?.value?.id!) fetchData(companyProfilePreview?.value?.id!);
  }, [companyProfilePreview?.value?.id]);

  useEffect(() => {
    setIsloader(true);
    const timer = setTimeout(() => {
      setIsloader(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, [companyidorslug, activeTab]);

  useEffect(() => {
    dispatch(getContent(`where[companyId]=${companyProfilePreview.value?.id}`));
  }, [dispatch, companyProfilePreview.value?.id]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return function cleanup() {
      dispatch(resetCompanyProfilePreview());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickEvent = (url: string | undefined) => {
    navigate(`${url}`);
  };

  if (!companyProfilePreview) return null;

  const getActiveTab = (activeTab: string) => {
    if (
      ['overview', 'post', 'spotlight', 'event', 'products'].includes(activeTab)
    ) {
      setActiveTab(activeTab);
      setSearchParams({ tab: activeTab }, { replace: true });
    }
  };

  const handleCreateEventClick = () => {
    const url = `${process.env.REACT_APP_EVENT_PORTAL_URL}/events?createEvent=true`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleCreateProduct = () => {
    const url = `/wall/company/${companyidorslug}/edit?createProduct=true`;
    window.location.href = url;
  };

  const handleCreatePost = () => {
    setActiveTab('post');
    window.scrollTo({
      top: 560,
      behavior: 'smooth'
    });
  };

  return (
    <>
      {/* <div className=" text-lg text-black">here is the new temp</div> */}
      <div className="bg-gray-1 h-full">
        <UserNavbar />
        <div className="lg:container mx-auto lg:w-[1258px] w-full lg:px-0 px-4 md:pb-0 pb-[60px] mt-[30px]">
          {companyProfilePreview.value?.id! && !isloader ? (
            <CompanyHeader
              id={companyProfilePreview.value?.id!}
              cover={companyProfilePreview.value?.cover!}
              logo={companyProfilePreview.value?.logo!}
              name={companyProfilePreview.value?.name!}
              isVerified
              showPosts
              showTechShows
              showEvents
              showOverview
              showProducts
              isFollowing={companyProfilePreview.value?.isFollowing!}
              getActiveTab={getActiveTab}
              activeTab={activeTab}
            />
          ) : <Loader isLoaderLogo className="h-[100vh]" key="loader" />}
          {activeTab === 'overview' && companyProfilePreview?.value && !isloader && (
            <div className="w-full mb-[80px]">
              <div
                className="w-[100%] flex gap-x-[11px] gap-y-[11px] py-[12px]"
              >
                {companyUpcomingevents?.length! > 0 ? (
                  <div
                    className={classNames('w-[527px] min-w-[527px] h-auto', {
                      // hidden: companyUpcomingevents?.length! === 0,
                      // '!w-full': (companyProfilePreview?.value?.products?.length! === 0 && companyEmployees.value?.data!.length! > 0) || (companyProfilePreview?.value?.products?.length! > 0 && companyEmployees.value?.data!.length! === 0) || (companyProfilePreview?.value?.products?.length! === 0 && companyEmployees.value?.data!.length! === 0)
                    })}
                  >
                    <CardEventsInterestedContainerNew
                      events={companyUpcomingevents!}
                      name="Upcoming events"
                      className="p-[20px] h-[100%]"
                      dropdownIcon="/images/Polygon-dropdown.svg"
                      onClickEvent={onClickEvent}
                    />
                  </div>
                ) : <NoProductList text="No events available at the moment." containerClass="bg-white create-post-card !rounded-[20px] h-[484px] w-[527px] min-w-[527px] flex items-center justify-center flex-col" onClickButton={handleCreateEventClick} btnText="Create a Event" />}

                {companyProfilePreview?.value?.products?.length! > 0 ? (
                  <div
                    className={classNames('w-[370px] h-auto', {
                    })}
                  >
                    <Products
                      connections={companyProfilePreview?.value?.products ?? []}
                      title="Products"
                      companyId={companyProfilePreview.value?.id}
                      totalEmployees={companyEmployees.value?.totalRow ?? 0}
                      companySlug={companyProfilePreview.value?.slug}
                    />
                  </div>
                ) : <NoProductList text="No products available at the moment." containerClass="bg-white create-post-card !rounded-[20px] h-[484px] w-[370px] flex items-center justify-center flex-col" onClickButton={handleCreateProduct} btnText="Create a Product" />}

                {companyEmployees.value?.data!.length! > 0 ? (
                  <div
                    className={classNames('w-[30%] max-w-[338px] h-auto', {
                    })}
                  >
                    <ConnectionsNew
                      connections={companyEmployees.value?.data ?? []}
                      title="Team"
                      companyId={companyProfilePreview.value?.id}
                      totalEmployees={companyEmployees.value?.totalRow ?? 0}
                    />
                  </div>
                ) : <NoProductList text="No team available at the moment." containerClass="bg-white create-post-card !rounded-[20px] h-[484px] w-[30%] max-w-[338px] flex items-center justify-center flex-col" />}
              </div>
              <div className="flex gap-[10px]">
                {posts?.value?.postsResult.data.length! > 0 ? (
                  <div className={classNames('!w-[100%] min-w-[776px] max-w-[776px] bg-white  border border-[#E6E6E6] rounded-[20px] pl-[20px] py-[19px] pr-0 overflow-hidden', {
                  })}
                  >
                    <h3 className="text-[20px] font-normal leading-[27px] text-black mb-[18px]">Top posts</h3>
                    <div className="w-[100%]">
                      <SwiperEvents
                        posts={posts}
                        companyProfilePreview={companyProfilePreview}
                        viewingAsAdmin={undefined}
                      />
                    </div>
                  </div>
                ) : <NoProductList text="No posts available at the moment." containerClass="bg-white create-post-card !rounded-[20px] h-[463px] !w-[100%] min-w-[776px] max-w-[776px] flex items-center justify-center flex-col" onClickButton={handleCreatePost} btnText="Create a Post" />}
                <CompanySpotlightNew
                  posts={posts}
                  id={companyProfilePreview.value?.id!}
                  specificSpotlight={specificSpotlight}
                  setSpecificSpotlight={setSpecificSpotlight}
                  setActiveTab={setActiveTab}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {companyProfilePreview?.value && !isloader ? (
        <div>
          {activeTab === 'post' && companyProfilePreview?.value && (
            <div className="-mt-[35px]">
              <CompanyLayout
                hero={undefined}
                leftPanel={<CompanyInfoAndConnections />}
                rightPanel={(
                  <>
                    {recommendedEvents.value?.data?.length! > 0 && (
                    <CardEventsInterestedContainerNew
                      events={recommendedEvents.value?.data!}
                      name="Events you might be interested in"
                      className="p-4 mb-4"
                      dropdownIcon="/images/Polygon-dropdown.svg"
                      onClickEvent={onClickEvent}
                    />
                  )}
                    <MiniSpotlight
                      companyId={companyProfilePreview.value?.id!}
                      slug={companyProfilePreview.value?.slug!}
                    />
                  </>
              )}
                isHideNavbar
              >
                <CompanyPosts
                  viewingAsAdmin={
                  companyAdmin?.some(
                    (company) => company.id === companyidorslug
                      || company.slug === companyidorslug
                  ) || false
                }
                />
              </CompanyLayout>
            </div>
          )}
          {activeTab === 'spotlight' && companyProfilePreview?.value && (
            <CompanySpotlightLayout
              hero={undefined}
              isHideNavbar
            >
              <CompanySpotlightTab
                isCompanySpotlight
                company={companyProfilePreview?.value}
                id={companyProfilePreview.value?.id!}
              />
            </CompanySpotlightLayout>
          )}
          {activeTab === 'event' && companyProfilePreview?.value && (
            <CompanyEventLayout
              hero={undefined}
              leftPanel={(
                <EventFilters
                  companyFilters={filter}
                  searchText=""
                  setCompanyEventsFilters={(eventFilter: string) => {
                    setFilter(`${eventFilter}`);
                  }}
                  setSearchText={(searchString: string) => {
                    setSearch(searchString);
                  }}
                />
              )}
              isHideNavbar
            >
              <CompanyEvents
                companyId={companyProfilePreview.value?.id!}
                filterQuery={filter}
                search={search}
              />
            </CompanyEventLayout>
          )}
          {activeTab === 'products' && companyProfilePreview?.value && (
          <CompanySpotlightLayout
            hero={undefined}
            isHideNavbar
          >
            <CompanyProductTab
              company={companyProfilePreview?.value}
            />
          </CompanySpotlightLayout>
          )}
        </div>
      ) : (
        null
      )}
    </>
  );
};

export default CompanyViewAsVisitorNew;
