import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IInteractiveEventState } from './types';

export type { IInteractiveEventState } from './types';

const initialState: IInteractiveEventState = {
    chimeInvitation: undefined,
    participants: undefined,
    queue: undefined,
    redirect: undefined,
    error: undefined,
    eventId: undefined,
    eventRoomId: undefined,
    ready: false,
    kicked: false,
    inviteToJoin: false,
    forceMute: false
};

const formatParticipants = (participants: any[]) => {
    const moderators = participants.filter((participant: any) => (participant.ieType === "moderator")).map((x: any) => x.user);
    const speakers = participants.filter((participant: any) => (participant.ieType === "speaker")).map((x: any) => x.user);
    const virtualSpeakers = participants.filter((participant: any) => (participant.ieType === "virtual-speaker")).map((x: any) => x.user);
    const guests = participants.filter((participant: any) => (participant.ieType === "guest")).map((x: any) => x.user);
    return {
        moderators,
        speakers,
        virtualSpeakers,
        guests
    }
}

const formatQueueParticipants = (participants: any[]) => {
    const speakers = participants.filter((participant: any) => (participant.ieType === "speaker")).map((x: any) => x.user);
    const handRaised = participants.filter((participant: any) => (participant.ieType === "hand-raised")).map((x: any) => x.user);
    const invited = participants.filter((participant: any) => (participant.ieType === "invited")).map((x: any) => x.user);
    const virtualSpeakers = participants.filter((participant: any) => (participant.ieType === "virtual-speaker")).map((x: any) => x.user);
    const guests = participants.filter((participant: any) => (participant.ieType === "guest")).map((x: any) => x.user);
    return {
        speakers,
        virtualSpeakers,
        handRaised,
        invited,
        guests
    }
}

export const interactiveEventSlice = createSlice({
    name: 'interactiveEvent',
    initialState,
    reducers: {
        unsetInteractiveEvent: () => {
            return {
                ...initialState
            };
        },

        /**
         * 
         * Moderator View
         * 
         */
        moderatorJoin: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.eventId = action.payload.eventId;
            state.eventRoomId = action.payload.eventRoomId;
            state.kicked = false;
        },
        moderatorJoining: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.chimeInvitation = action.payload;
        },
        moderatorJoinDenied: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.error = action.payload.message;
        },

        /**
         * 
         * Speaker view
         * 
         */
        speakerJoin: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.eventId = action.payload.eventId;
            state.eventRoomId = action.payload.eventRoomId;
            // Speaker ready
            state.ready = false;
            state.kicked = false;
        },
        speakerJoinSuccess: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.chimeInvitation = action.payload;
        },
        speakerReadyToGoLive: (state: IInteractiveEventState) => {
            state.ready = false;
        },
        speakerLive: (state: IInteractiveEventState) => {
            state.ready = true;
        },
        speakerJoiningDenied: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.error = action.payload.message;
        },

        /**
         * 
         * 
         * Moderator & Speaker
         * 
         */
        moderatorSpeakerActiveList: () => { },
        moderatorSpeakerActiveListing: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.participants = formatParticipants(action.payload.participants);
        },
        moderatorSpeakerActiveListingDenied: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.error = action.payload.message;
        },
        moderatorSpeakerQueueList: () => { },
        moderatorSpeakerQueueListing: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.queue = formatQueueParticipants(action.payload.participants);
        },
        moderatorSpeakerQueueListingDenied: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.error = action.payload.message;
        },
        moderatorSpeakerApproveUser: () => { },
        moderatorSpeakerUserApproved: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            // TODO: UI needs unknown at this stage.
        },
        moderatorSpeakerApproveUserDenied: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.error = action.payload.message;
        },
        moderatorSpeakerRemoveQueueUser: () => { },
        moderatorSpeakerKickUser: () => { },
        moderatorSpeakerInviteAttendee: () => { },
        moderatorSpeakerMuteAll: () => { },
        moderatorSpeakerMuteUser: () => { },
        moderatorSpeakerForceToMute: (state: IInteractiveEventState) => {
            state.forceMute = true;
        },

        /**
         * 
         * Attendee View
         * 
         */
        attendeeJoin: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.eventId = action.payload.eventId;
            state.eventRoomId = action.payload.eventRoomId;
        },
        attendeeRaisehand: () => { },
        attendeeRaisehandSuccess: () => { },
        attendeeInviteToJoin: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.chimeInvitation = action.payload; 
            state.inviteToJoin = true;
        },
        resetAttendeeInviteToJoin:(state: IInteractiveEventState)=> {
            state.chimeInvitation = undefined;
            state.inviteToJoin = false;
        },
        resetAttendeeForceToMute: (state: IInteractiveEventState) => {
            state.forceMute = false;
        },
        attendeeInviteToJoinReject: () => { },
        attendeeReadyToGoLive: () => { },
        attendeeAddUserToCall: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            state.chimeInvitation = action.payload;
        },
        attendeeUserRemovedFromQueue: (state: IInteractiveEventState, action: PayloadAction<any>) => {
            //  state.error = action.payload.message;
            state.redirect = `/event/${state.eventId}/${state.eventRoomId}/session`;

        },
        attendeeToDo: (state: IInteractiveEventState, action: PayloadAction<any>) => {

        },

        /**
         * 
         * Speaker & Attendee
         * 
         */
        speakerAttendeeKickedFromCall: (state: IInteractiveEventState) => {
            state.kicked = true;
        },
        speakerAttendeeUserRemovedFromQueue: (state: IInteractiveEventState) => {
            //  state.error = action.payload.message;
            state.redirect = `/event/${state.eventId}/live`;
            //TODO: NEED TO GET EVENT NAME AND EVENT HOST NAME FROM socket named 'interactive/user-removed' and use redirect url like this /:hostName/:eventName/live
        },


    }
});

export const {
    unsetInteractiveEvent,
    /**
     * Moderator view
     */
    moderatorJoin,
    moderatorJoining,
    moderatorJoinDenied,

    /**
     * Speaker view
     */
    speakerJoin,
    speakerJoinSuccess,
    speakerReadyToGoLive,
    speakerLive,
    speakerJoiningDenied,

    /**
     * Moderator and speaker
     */
    moderatorSpeakerActiveList,
    moderatorSpeakerActiveListing,
    moderatorSpeakerActiveListingDenied,
    moderatorSpeakerQueueList,
    moderatorSpeakerQueueListing,
    moderatorSpeakerQueueListingDenied,
    moderatorSpeakerApproveUser,
    moderatorSpeakerUserApproved,
    moderatorSpeakerApproveUserDenied,
    moderatorSpeakerRemoveQueueUser,
    moderatorSpeakerKickUser,
    moderatorSpeakerInviteAttendee,
    moderatorSpeakerMuteAll,
    moderatorSpeakerMuteUser,
    moderatorSpeakerForceToMute,


    /**
     * Attendee view
     */
    attendeeJoin,
    attendeeRaisehand,
    attendeeRaisehandSuccess,
    attendeeInviteToJoin,
    resetAttendeeInviteToJoin,
    attendeeInviteToJoinReject,
    attendeeReadyToGoLive,
    attendeeAddUserToCall,
    attendeeUserRemovedFromQueue,

    /**
     * Speaker and Attendee
     */
    speakerAttendeeKickedFromCall,
    speakerAttendeeUserRemovedFromQueue

} = interactiveEventSlice.actions;

// Read message and store Date into Redux states
export const handleInteractiveEvent = (message: any) => async (dispatch: Dispatch) => {
    /**
     * 
     * Moderator View
     * 
     */
    if (message.action === 'interactive/moderator-joining') dispatch(moderatorJoining(message?.data));
    if (message.action === 'interactive/moderator-joining/denied') dispatch(moderatorJoinDenied(message?.data));

    /**
     * 
     * Speaker View
     * 
     */
    if (message.action === 'interactive/speaker-join/success') dispatch(speakerJoinSuccess(message?.data));
    if (message.action === 'interactive/speaker-live') dispatch(speakerLive());
    if (message.action === 'interactive/speaker-joining/denied') dispatch(speakerJoiningDenied(message?.data));

    /**
     * 
     * Moderator and Speaker
     * 
     */
    if (message.action === 'interactive/active-listing') dispatch(moderatorSpeakerActiveListing(message?.data));
    if (message.action === 'interactive/refresh-active-list') dispatch(moderatorSpeakerActiveListing(message?.data));
    if (message.action === 'interactive/active-listing/denied') dispatch(moderatorSpeakerActiveListing(message?.data));

    if (message.action === 'interactive/queue-listing') dispatch(moderatorSpeakerQueueListing(message?.data));
    if (message.action === 'interactive/refresh-queue-list') dispatch(moderatorSpeakerQueueListing(message));
    if (message.action === 'interactive/queue-listing/denied') dispatch(moderatorSpeakerQueueListingDenied(message?.data));

    if (message.action === 'interactive/user-approved') dispatch(moderatorSpeakerUserApproved(message?.data));
    if (message.action === 'interactive/approve-user/denied') dispatch(moderatorSpeakerApproveUserDenied(message?.data));
    if (message.action === 'interactive/forced-to-mute') dispatch(moderatorSpeakerForceToMute());

    /**
     * 
     * Attendee View
     * 
     */
    if (message.action === 'interactive/raise-hand/success') dispatch(attendeeRaisehandSuccess());

    if (message.action === 'interactive/invite-to-join') dispatch(attendeeInviteToJoin(message?.data));
    if (message.action === 'interactive/invite-to-join/reject') dispatch(attendeeInviteToJoinReject());

    if (message.action === 'interactive/add-user-to-call') dispatch(attendeeAddUserToCall(message?.data));

    /**
     * 
     * Speaker and Attendee
     * 
     */
    if (message.action === 'interactive/user-removed-from-call') dispatch(speakerAttendeeKickedFromCall());
    if (message.action === 'interactive/user-removed') dispatch(speakerAttendeeUserRemovedFromQueue());

};


