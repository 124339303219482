import React, { FC, useState } from 'react';
import { format, parseISO } from 'date-fns';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ArrowRightIcon from "../../../assets/images/arrow-right.svg";
import ArrowLeftIcon from "../../../assets/images/arrow-left.svg";
import defaultImage from '../../../assets/images/event-slider-default-cover.png';

import { EventsService } from '../../../services/events';
import { IEvent } from '../../../models/user-events';
import { DropDownEventButton } from '../DropDownEventButton';
import { GroupUserIcons } from '../GroupUserIcons';

import './cardEventsInterestedContainer.css'
import { Logo } from '../Logo';
import { minifiedImage, toBase64 } from '../../../utils';
import { ArrowDownSvgIcon } from '../IconSvg';
import moment from "moment";
import { getUTCTimeZone } from 'src/mui/assets/data/timezones';

export interface ICardEventsInterestedItems {
  events: IEvent[];
  name: string,
  dropdownIcon?: string;
  className?: string;
  onClickEvent?: Function;
}

export const CardEventsInterestedContainerNew: FC<ICardEventsInterestedItems> = ({
  events,
  name,
  dropdownIcon = '/images/Polygon-dropdown.svg',
  className,
  onClickEvent
}) => {

  const [selectedEvent, setSelectedEvent] = useState(events && events[0]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showArrows, setShowArrows] = useState(false);

  const handleClick = (_: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setSelectedEvent(events && events[index]);
    setSelectedIndex(index);
  };

  const slideNext = (_: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setSelectedEvent(events && events[index]);
    setSelectedIndex(index);
  };
  
  const navigate = useNavigate();

  const join = () => {
    navigate(`/event/${selectedEvent && selectedEvent?.slug}/discussion?from=live-register`);
  };

  const interested = () => {
    new EventsService().eventInterested(selectedEvent && selectedEvent?.id).then(res => {
      toast.success(res.message);
    });
  };

  const formatEventDate = (event: any) => {
    if (!event || !event.startTime) return '';
  
    const date = parseISO(event.startTime);
    
    // Format the date with lowercase AM/PM
    const formattedDate = format(date, "dd MMM yyyy '•' hh:mm a");
  
    // Capitalize AM/PM
    const capitalizedDate = formattedDate.replace(/\b(am|pm)\b/g, match => match.toUpperCase());
    
    return capitalizedDate;
  };
  
  if (!selectedEvent) return null;

  return (
    <div 
      className={classNames("flex-col relative card-shadow !rounded-[20px] event-card-widget bg-white", {
        [`${className}`]: className,
      })}
      onMouseEnter={() => setShowArrows(true)}
      onMouseLeave={() => setShowArrows(false)}
    >
      <div className="text-[20px] leading-[27px] text-black font-normal mb-[10px]">{name}</div>
      <div className="text-sm5 w-full h-[385px] border border-[#44444419] rounded-[20px] mb-[15px]">  
        <div>
          <div
            style={{
              backgroundImage: selectedEvent?.cover ? `url(${minifiedImage(`${selectedEvent?.cover}`)}` : `url(${defaultImage})`
            }}
            className={classNames("flex items-end justify-start cursor-pointer mx-auto bg-cover bg-no-repeat bg-center rounded-t-[20px] w-full px-3 py-3 bg-gray-50 mb-[15px] h-[215px]")}
            onClick={() => onClickEvent!(`/event/${selectedEvent && selectedEvent?.slug}/discussion`)}
          >
            <div
              className={classNames("w-[66px] max-w-[66px] min-w-[66px] h-[66px] max-h-[66px] min-h-[66px] flex items-center justify-center bg-white bg-center small-radius", {
                'bg-[#edf2f5]': !selectedEvent?.company?.logo,
              })}
            >
              <Logo
                alt="avatar"
                className="w-[64px] max-w-[64px] min-w-[64px] h-[64px] max-h-[64px] min-h-[64px] object-contain bg-center small-radius"
                src={minifiedImage(`${selectedEvent?.company?.logo}`)}
                defaultLogo="company"
              />
            </div>
          </div>
        </div>


        <div className="px-[20px]">
          <div className="text-left event-card-widget-text">
            <div className='cursor-pointer' 
              onClick={() => onClickEvent!(`/event/${selectedEvent && selectedEvent?.slug}/discussion`)}
            >
              <div className="mb-[9px] text-[15px] leading-[20px] text-[#000000] font-medium">{selectedEvent && selectedEvent?.name!}</div>
            </div>
            <div className="flex flex-row">
              <div className="mb-1 text-[12px] leading-[16px] text-[#000000] font-normal">{selectedEvent && selectedEvent?.eventRegions?.length && selectedEvent?.eventRegions[0].name!} {selectedEvent && selectedEvent?.company && selectedEvent?.company.name! ? '•' : null} {selectedEvent && selectedEvent?.company && selectedEvent?.company.name!}</div>
            </div>
            <div className="flex flex-row">
              <div className="mb-1 text-[12px] leading-[16px] text-[#000000] font-normal">{formatEventDate(selectedEvent)}</div>
            </div>
            <div
              className={classNames("flex flex-row mb-[11px]", {
                '!mb-[1px]': selectedEvent?.relatedAttendees?.length,
              })}
            >
              <div className="text-[12px] leading-[16px] text-[#000000] font-normal capitalize">{selectedEvent && selectedEvent?.format!} {selectedEvent && selectedEvent?.locationName! ? '•' : null} {selectedEvent && selectedEvent?.locationName!}</div>
            </div>
            {selectedEvent?.relatedAttendees?.length ? (
              <div className='mb-1'>
              <GroupUserIcons 
                users={selectedEvent?.relatedAttendees}
                userQty={2}
              />
            </div>
            ) : null}
            
          </div>
          <div className="text-center mb-[23px]">
            <div className="flex justify-between text-center w-full static">
              <div>
                <DropDownEventButton
                  text="RSVP"
                  iconRight={<ArrowDownSvgIcon className="!ml-[27px]" fillColor="#000000" />}
                  className='my-2'
                  classes='!border-[#000000] !text-[#000000] !font-semibold !px-[12px]'
                  titleclasses='!text-[#000000]'
                  menuItems={[
                    {
                      id: '1', name: 'Join', url: '', onClick: join
                    },
                    {
                      id: '2', name: 'Interested', url: '', onClick: interested
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="flex flex-row justify-center items-center event-card-bulets">
          {events && events?.length > 0 &&
            events.map((item, i) => (
                <button
                  key={`tab-button-${i}`}
                  className={classNames("mr-1 bullets", {
                    [`active`]: i == selectedIndex,
                  })}
                  onClick={(e) => {
                    handleClick(e, i);
                  }}>
                  {selectedIndex === i ? '●' : '○'}
                </button>
            ))}
          <button
            className={classNames("mr-1 absolute -right-[6px] top-[23%]")}
            disabled={events?.length === selectedIndex + 1}
            onClick={(e) => {
              slideNext(e, selectedIndex + 1);
            }}>
            <img
              className={classNames("", {
                hidden: !showArrows,
              })}
              src={ArrowRightIcon}
            />
          </button>
          <button
            className={classNames("mr-1 absolute -left-[1px] top-[23%]")}
            disabled={selectedIndex === 0}
            onClick={(e) => {
              slideNext(e, selectedIndex - 1);
            }}>
            <img
              className={classNames("", {
                hidden: !showArrows,
              })}
              src={ArrowLeftIcon}
            />
          </button>
        </div>
    </div>
  );
};
