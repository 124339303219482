import { useState } from "react";
interface IActiveMenuBorderIconSvg {
  isActive?: boolean;
}
export const ActiveMenuBorderIconSvg = ({ isActive }: IActiveMenuBorderIconSvg) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="115" height="5" viewBox="0 0 115 5">
      <path id="Rectangle_8256" data-name="Rectangle 8256" d="M4,0H111a4,4,0,0,1,4,4V5a0,0,0,0,1,0,0H0A0,0,0,0,1,0,5V4A4,4,0,0,1,4,0Z" fill="#144ee6"/>
    </svg>
  );
};
