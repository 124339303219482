// video-header.tsx => Header for the video player component

import './RangeInput.css';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { bgGradient } from 'src/mui/theme/css';
import { ParseContent } from 'src/utils/parse-html';
import { Button } from '@mui/material';
import WebsiteIcon from '../../reg-dialog-flow/WebsiteIcon';
import { getBrandingColors } from '../../live/liveEventStyling';
type VideoHeaderProps = {
  title: string;
  overview?: any;
  logoURL: string;
  eventSlug: string;
  videoStatus: 'expo' | 'sessions' | 'msessions' | 'ssessions';
  className?: string;
  hasModerator?:boolean;
  hasSpeaker?:boolean;
  disableExpo?:boolean;
  hostName?: string;
  setVideoStatus(stat: string): void;
  showBackButton?: boolean;
  setVideoStatus(stat: string): void;
  setRoomID?: (value: any) => void;
};

const flexRowItesm = { display: 'flex', alignItems: 'center', flexDirection: 'row', }
const flexIcons = { display: 'flex', alignItems: 'flex-start', flexDirection: 'col', justifyContent: 'start', margin: '0px !important', cursor: 'pointer' }

export const VideoHeader = (props: VideoHeaderProps) => {
  const colors = getBrandingColors();

  const onBackSessionsClick = () => {
    if (props?.setRoomID) props?.setRoomID(null);
    const url = window.location.origin + window.location.pathname;
    window.history.pushState({}, '', url);
  };

  return (
  <Stack
    display='flex'
    justifyContent='space-between'
    alignItems='center'
    width={1}
    flexDirection='row'
    position='absolute'
    left='0'
    top='0'
    zIndex={1}
    paddingX={3}
    paddingY={2}
    sx={{
      ...bgGradient({
        direction: '180deg',
        startColor: '#00000063',
        endColor: '#ffffff00',
      }),
    }}
  >
    {props.title && (
      <Stack spacing={1} sx={flexRowItesm}>
        {props?.showBackButton ? (
          <Button
            sx={{
              borderRadius: "5px",
              width: 36,
              height: 36,
              minWidth: 36,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "13px",
              lineHeight: "17px",
              fontFamily: "Poppins-500",
              background:  colors?.Secondary || "#EDF2F5",
              textTransform: "none",
              padding: "0px",
              fontWeight: 500,
              color: colors?.Text || "#203C6E",
              transform: "scaleX(-1)",
              "&:hover": {
                background: colors?.Secondary || "#0049EF",
                opacity: '0.5'
              },
            }}
            onClick={() => onBackSessionsClick()}
          >
            <WebsiteIcon style={{ fill: colors?.Text || "#203c6e"}} />
          </Button>
        ) : null}
      <Stack spacing={0} sx={flexRowItesm}>
        <Avatar src={`${props.logoURL}`} sx={{ width: 36, height: 36, mr: 1, borderRadius: '5px' }} alt={props.logoURL} />
        <Stack spacing={0} sx={flexIcons}>
          <Typography color={colors.Text || "white"} fontSize="15px" fontWeight="500" fontFamily='Poppins-500' margin='0px !important'>{props?.title}</Typography>
          <Typography color={colors.Text || "white"} fontSize="12px" fontWeight="400" fontFamily='Poppins-400' margin='0px !important' textAlign='left'><ParseContent className="max-w-[400px] line-clamp-1" content={props?.overview ?? ''} /></Typography>
        </Stack>
      </Stack>
      </Stack>
    )}
  </Stack>
)};

export default VideoHeader;
