import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { parseISO } from "date-fns";
import { IEvent } from "../../models/event/event";
import { IEventOnDemandRoom } from "../../models/event/event-ondemand";
import { IEventSession } from "../../models/event/event-session";
import { IRoom } from "../../models/event/room";

import { UserService } from "../../services";
import { EventsService } from "../../services/events";
import { ExpoService } from "../../services/ExpoService";
import {
  BroadcastResponse,
  LiveEvent,
  LiveEventResponse,
  LiveEventState,
  LiveEventValues,
} from "./types";
import { PeopleType } from "src/mui/pages/audience/Types";
import { toast } from "react-toastify";

const initialState: LiveEventState = {
  isLive: false,
  activeRoom: undefined,
  event: undefined,
  eventModerators: [],
  booths: [],
  resources: [],
  attendees: [],
  connectionStatuses: [],
  speakers: [],
  error: undefined,
  session: undefined,
  onDemandRooms: [],
  ads: [],
  meetings: [],
  eventSponsorsLeads: [],
  eventSponsors: [],
  tabsCount: {},
  meetingCount: [],
  meetingsSummary: {},
  eventPolls: [],
  sponsorLeadsSummary: {},
  isMeetingLoading: false,
  joinMeeting: {},
  joinExpoBoothRoom:{},
  eventHandouts: [],
  liveEventBranding:undefined,
  favBrandings: undefined,
  favBrandingsLoading:false,
};

const flatSessions = (event: IEvent) =>
  event?.rooms.map(({ sessions }: any): IEventSession[] => sessions)?.flat();

export const liveEventSlice = createSlice({
  name: "liveEvent",
  initialState,
  reducers: {
    setValues: (state, action: PayloadAction<LiveEventValues>) => {
      state.event = action.payload.event;
      state.liveEventBranding = action?.payload?.event?.liveEventBranding;
      state.booths = action.payload.booths;
      state.resources = action.payload.resources;
      state.attendees = action.payload.attendees;
      state.speakers = action.payload.speakers;
      state.connectionStatuses = action.payload.connectionStatuses;
      state.onDemandRooms = action.payload.onDemandRooms;
      state.eventModerators = action.payload.eventModerators;
      state.ads = action.payload.ads;

      const _flatSessions: IEventSession[] = flatSessions(state.event!);
      let currentEventIndex = _flatSessions?.findIndex(
        (session: IEventSession) =>
          parseISO(session.startTime).getTime() <= Date.now() &&
          Date.now() < parseISO(session.endTime).getTime()
      );

      if (currentEventIndex === -1) {
        if (parseISO(_flatSessions?.[0]?.startTime).getTime() < Date.now()) {
          currentEventIndex = 0;
        }
        state.isLive = false;
      } else {
        state.isLive = true;
      }

      const updatedSession: any = {
        current: _flatSessions[currentEventIndex],
        next: _flatSessions[currentEventIndex + 1],
        sessions: _flatSessions,
      };

      state.session = updatedSession;
    },
    updateCurrentSessionInformation: (state) => {
      const _flatSessions: IEventSession[] = flatSessions(state.event!);
      let currentEventIndex = _flatSessions?.findIndex(
        (session: IEventSession) =>
          parseISO(session.startTime).getTime() <= Date.now() &&
          Date.now() < parseISO(session.endTime).getTime()
      );

      if (currentEventIndex === -1) {
        if (parseISO(_flatSessions?.[0]?.startTime).getTime() < Date.now()) {
          currentEventIndex = 0;
        }
        state.isLive = false;
      } else {
        state.isLive = true;
      }

      const updatedSession: any = {
        current: _flatSessions[currentEventIndex],
        next: _flatSessions[currentEventIndex + 1],
        sessions: _flatSessions,
      };

      state.session = updatedSession;
    },
    setActiveRoom: (state, action: PayloadAction<IRoom>) => {
      state.activeRoom = action.payload;
    },
    setNewAttendees: (state, action: PayloadAction<LiveEvent>) => {
      if (!action.payload.user) return;
      if (state.attendees.some((item) => item.id || item.userId === action.payload.user.id))
        return;
      state.attendees.push(action.payload.user);
    },
    setAttendeeStatus: (state, action: PayloadAction<any>) => {
    const updatedAttendees =  state.attendees.map((item) => {
        if (item?.userId === action?.payload?.instance?.userId && state?.event?.id == action?.payload?.instance?.eventId) {
          return { ...item, checkedInAt: action?.payload?.instance?.checkedInAt }
        }
        return { ...item }
      });
      return {
        ...state,
        attendees:updatedAttendees,
      }
    },
    removeAttendee: (state, action: PayloadAction<any>) => {
      const updatedAttendees =  state.attendees.filter((item) => item?.userId !== action?.payload?.instance?.userId && state?.event?.id === action?.payload?.instance?.eventId );
      return {
        ...state,
        attendees:updatedAttendees,
      }
    },
    addNewAttendee: (state, action: PayloadAction<any>) => {
      if (state.attendees.some((item) => item?.userId === action?.payload?.instance?.userId && state?.event?.id == action?.payload?.instance?.eventId))
        return;
      state.attendees.push(action?.payload?.instance);
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setBroadcastResponse: (state, action: PayloadAction<BroadcastResponse>) => {
      if (action.payload?.instance?.id === state.event?.id) {
        if (action.payload.action === "event-started") {
          state.isLive = true;
        } else if (action.payload.action === "event-ended") {
          state.isLive = false;
        }
      }
    },
    setLiveEventResponse: (state, action: PayloadAction<LiveEventResponse>) => {
      // state.liveEvent = action.payload;
    },
    rewatchSession: (state, action: PayloadAction<string>) => {
      const newSessionIndex =
        state.session?.sessions.findIndex(
          (session) => session.id === action.payload
        ) || 0;
      const updatedSession: any = {
        current: state.session?.sessions[newSessionIndex],
        next: state.session?.sessions[newSessionIndex + 1],
        sessions: state.session?.sessions,
      };
      state.session = updatedSession;
    },
    setActiveSession: (state, action: PayloadAction<IEventSession>) => {
      // state.activeSession = action.payload;
    },
    setNextSession: (state) => {
      const currentSessionIndex: number =
        state.session?.sessions.findIndex(
          (session) => session.id === state.session?.current.id
        ) || 0;
      const newSessionIndex = currentSessionIndex + 1;
      const updatedSession: any = {
        current: state.session?.sessions[newSessionIndex],
        next: state.session?.sessions[newSessionIndex + 1],
        sessions: state.session?.sessions,
      };
      state.session = updatedSession;
    },
    resetLiveEvent: () => {
      return { ...initialState };
    },
    setMeetings: (state, action: PayloadAction<any[]>) => {
      state.meetings = action?.payload;
    },
    setEventBranding: (state, action: PayloadAction<any[]>) => {
      state.liveEventBranding = action?.payload;
    },

    setFavBrandings: (state, action: PayloadAction<any>) => {
      state.favBrandings = action?.payload;
      state.favBrandingsLoading = false;
    },

    setFavBrandingsLoading: (state, action: PayloadAction<any>) => {
      state.favBrandingsLoading  = action?.payload;
    },

    setEventSponsors: (state, action: PayloadAction<any[]>) => {
      state.eventSponsors = action?.payload;
    },
    setTabsCount: (state, action: PayloadAction<any[]>) => {
      state.tabsCount = action?.payload;
    },
    setMeetingCount: (state, action: PayloadAction<any>) => {
      state.meetingCount = {
        ...state.meetingCount,
        [action?.payload.status]: action?.payload?.count,
      };
    },

    setMeetingsSummary: (state, action: PayloadAction<any[]>) => {
      state.meetingsSummary = action?.payload;
    },

    setSponsorsLeadsSummary: (state, action: PayloadAction<any[]>) => {
      state.sponsorLeadsSummary = action?.payload;
    },

    setEventSponsorLeads: (state, action: PayloadAction<any[]>) => {
      state.eventSponsorsLeads = action?.payload;
    },

    setEventPolls: (state, action: PayloadAction<any[]>) => {
      state.eventPolls = action?.payload;
    },
    setMeetingsLoading: (state, action: PayloadAction<boolean>) => {
      state.isMeetingLoading = action?.payload;
    },
    setJoinMeeting: (state, action: PayloadAction<any>) => {
      state.joinMeeting = action?.payload;
    },
    setJoinExpoBoothRoom: (state, action: PayloadAction<any>) => {
      state.joinExpoBoothRoom = action?.payload;
    },
    setEventHandouts: (state, action: PayloadAction<any[]>) => {
      state.eventHandouts = action?.payload;
    },
  },
});

export const {
  setNewAttendees,
  setActiveRoom,
  setValues,
  setError,
  setLiveEventResponse,
  setBroadcastResponse,
  rewatchSession,
  setActiveSession,
  setNextSession,
  resetLiveEvent,
  updateCurrentSessionInformation,
  setMeetings,
  setEventBranding,
  setFavBrandings,
  setFavBrandingsLoading,
  setEventSponsors,
  setTabsCount,
  setMeetingCount,
  setMeetingsSummary,
  setEventPolls,
  setSponsorsLeadsSummary,
  setEventSponsorLeads,
  setMeetingsLoading,
  setJoinMeeting,
  setJoinExpoBoothRoom,
  setAttendeeStatus,
  addNewAttendee,
  removeAttendee,
  setEventHandouts
} = liveEventSlice.actions;

export const init = (slug: string, liveEventPage?: boolean) => async (dispatch: Dispatch) => {
  try {
    const eventResult = await new EventsService().getUpcomingEventDetails(
      slug!
    );

    const event = eventResult.data.event;

    if (event) {
      event?.rooms?.map((room) => (room.isInteractive = false));
    }

    const attendees = await new EventsService()
      .getShortAttendees(event?.id, liveEventPage,
        event?.registrantsVisibility
      )
      .then((r) => r.data);

    const speakers = await new EventsService()
      .getUpcomingEventSpeakers(event.id)
      .then((r) => r.data);

    const booths = await new ExpoService()
      .getExpoBooths(event?.id)
      .then((r) => r.data);
    const resources = await new EventsService()
      .getEventResources(event?.id)
      .then((r) => r.data);

    const eventModerators = await new EventsService()
      .getEventModerators(event.id)
      .then((r) => r.data)
      .catch(() => []);

    const attendeeIds = attendees.map((attendee: any) => attendee.userId || attendee.id);
    const speakerIds = speakers.map((speaker) => speaker.id);
    const connectionStatuses = await new UserService()
      .getConnectStatuses([...speakerIds, ...attendeeIds], 500)
      .then((r) => r.data);
    const onDemandRooms: IEventOnDemandRoom[] =
      event.status === "completed" ||  event.status === "archived"
        ? await new EventsService().getEventOnDemand(slug).then((r) => r.data)
        : [];

    const ads: any[] = await new EventsService()
      .getEventAds(slug)
      .then(({ data }: any) => data);

    await dispatch(
      setValues({
        event,
        booths,
        resources,
        attendees,
        eventModerators,
        connectionStatuses,
        speakers,
        onDemandRooms,
        ads,
      })
    );
  } catch (err: any) {
    dispatch(setError(err.message));
  }
};

export const getEventBranding =
  (liveEventId: string) => async (dispatch: Dispatch) => {
    try {
      const eventBranding = await new EventsService()
        .getEventBranding(liveEventId)
        .then((r) => r.data);

      await dispatch(setEventBranding(eventBranding));
    } catch (error) {
      console.log("here is the error in polls", error);
    }
  };

  export const createEventBranding =
  (liveEventId: string,body:any) => async (dispatch: Dispatch) => {
    try {
      const eventBranding = await new EventsService()
        .createEventBranding(liveEventId,body)
        .then((r) => r.data);
      await dispatch(setEventBranding(eventBranding));
    } catch (error) {
      console.log("here is the error in polls", error);
    }
  };

  export const updateEventBranding =
  (liveEventId: string,brandingId:string,body:any) => async (dispatch: Dispatch) => {
    try {
      const eventBranding = await new EventsService()
        .updateEventBranding(liveEventId,brandingId,body)
        .then((r) => r.data);
      await dispatch(setEventBranding(eventBranding));
    } catch (error) {
      console.log("here is the error in polls", error);
    }
  };

  export const markBrandingAsFav =
    (liveEventId: string, body: any, onSuccess?: () => void) => async (dispatch: Dispatch) => {
      try {
        dispatch(setFavBrandingsLoading(true));
        const response = await new EventsService().markBrandingAsFav(liveEventId, body);
        if (response?.data?.id) {
          if (onSuccess) {
            onSuccess();
          }
          toast.success(`${response?.data?.name}: Branding added to favorites successfully!`, {
            autoClose: 500, 
          });
        }

        dispatch(setFavBrandingsLoading(false)); 
      } catch (error: any) {
        dispatch(setFavBrandingsLoading(false)); 
        toast.error(error?.message || 'Failed to add branding to favorites.');
      }
  };

  export const deleteBrandingAsFav = (
    liveEventId: string,
    favoriteId: any,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    try {
      dispatch(setFavBrandingsLoading(true));
      const response = await new EventsService().deleteBrandingAsFav(liveEventId, favoriteId);
      if (response?.message === "SUCCESS" && onSuccess) {
        toast.success('Branding successfully removed from favorites!', {
          autoClose: 500, 
        });
        onSuccess();
      }
    } catch (error: any) {
      toast.error(error?.message || "Failed to delete favorite branding");
    } finally {
      dispatch(setFavBrandingsLoading(false));
    }
  };

export const getFavBrandings =
  (liveEventId: string) => async (dispatch: Dispatch) => {
    try {
      const favBrandings = await new EventsService()
        .getFavBrandings(liveEventId)
        .then((r) => {
          r?.data.forEach((i:any)=>{
            i.label = i.id
          })
          return r.data;
        });
      await dispatch(setFavBrandings(favBrandings));
    } catch (error) {
      console.log("here is the error in polls", error);
    }
  };

export const getMeetings =
  (
    liveEventId: string,
    role: string,
    userId: string,
    meetingStatus: string,
    sponsorId?: string,
    specificMeetings?: string
  ) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch(setMeetingsLoading(true));
        const meetings = await new EventsService()
          .getMeetings(
            liveEventId,
            role,
            userId,
            meetingStatus,
            sponsorId,
            specificMeetings
          )
          .then((r) => r.data);
        await dispatch(setMeetings(meetings));
        dispatch(setMeetingsLoading(false));
      } catch (error) {
        dispatch(setMeetingsLoading(false));
      }
    };

export const getEventLeads =
  (
    liveEventId: string,
    role: string,
    userId: string,
    sponsorId?: string,
    specificMeetings?: string
  ) =>
    async (dispatch: Dispatch) => {
      try {
        const eventSponsorLeads = await new EventsService()
          .getEventLeads(liveEventId, role, userId, sponsorId, specificMeetings)
          .then((r) => r.data);
        await dispatch(setEventSponsorLeads(eventSponsorLeads));
      } catch (error) {
        console.log("here is the error in leads", error);
      }
    };

export const getMeetingsSummary =
  (liveEventId: string) => async (dispatch: Dispatch) => {
    try {
      const meetingsSummary = await new EventsService()
        .getMeetingsSummary(liveEventId)
        .then((r) => r.data);
      await dispatch(setMeetingsSummary(meetingsSummary));
    } catch (error) {
      console.log("here is the error in meeting summary", error);
    }
  };

export const getSponsorsLeadsSummary =
  (liveEventId: string) => async (dispatch: Dispatch) => {
    try {
      const sponsorsLeadsSummary = await new EventsService()
        .getSponsorsLeadsSummary(liveEventId)
        .then((r) => r.data);
      await dispatch(setSponsorsLeadsSummary(sponsorsLeadsSummary));
    } catch (error) {
      console.log("here is the error in leads summary", error);
    }
  };

export const getTabsCount =
  (liveEventId: string, tabs: string, role: string, sponsorId?: string) =>
    async (dispatch: Dispatch) => {
      try {
        let whereCondition = "";
        switch (role) {
          case "admin":
            whereCondition = `isMobileHost=true`;
            break;
          case "sponsor":
            whereCondition = `sponsorId=${sponsorId}`;
            break;
          default:
            whereCondition = `isAttendee=true`;
            break;
        }
        const tabsCount = await new EventsService()
          .getTabsCount(liveEventId, tabs, whereCondition)
          .then((r) => r.data);
        await dispatch(setTabsCount(tabsCount));
      } catch (error) {
        console.log("here is the error in tabs count", error);
      }
    };

export const getMeetingsTabCount =
  (
    liveEventId: string,
    status: string,
    role: string,
    specificMeetings: string,
    sponsorId?: string
  ) =>
    async (dispatch: Dispatch) => {
      try {
        let whereCondition = "";
        switch (role) {
          case "admin":
            whereCondition = `isMobileHost=true`;
            break;
          case "sponsor":
            whereCondition = `sponsorId=${sponsorId}`;
            break;
          default:
            whereCondition = `isAttendee=true`;
            break;
        }
        whereCondition = whereCondition + `&meetingStatus=${status}`;
        if (specificMeetings) {
          if (specificMeetings === "allSponsorsMeetings") {
            whereCondition += "&isSponsor=true";
          }
          if (specificMeetings === "othersMeetings") {
            whereCondition += "&notSponsor=true";
          }

          if (specificMeetings.includes("selectedSponsor")) {
            whereCondition += `&sponsorId=${sponsorId}`;
          }
        }
        const tabsCount = await new EventsService()
          .getTabsCount(liveEventId, PeopleType.ScheduledMeeting, whereCondition)
          .then((r) => r.data);
        await dispatch(
          setMeetingCount({
            status,
            count: tabsCount[PeopleType.ScheduledMeeting],
          })
        );
      } catch (error) {
        console.log("here is the error in meeting count", error);
      }
    };

export const getEventSponsors =
  (liveEventId: string) => async (dispatch: Dispatch) => {
    try {
      const eventSponsors = await new EventsService()
        .getEventSponsors(liveEventId)
        .then((r) => r.data);
      await dispatch(setEventSponsors(eventSponsors));
    } catch (error) {
      console.log("here is the error in sponsor", error);
    }
  };

export const updateMeetingStatus =
  (
    eventIdOrSlug: string,
    meetingId: string,
    body: any,
    role: string,
    userId: string,
    selectedMeetingButton: string,
    sponsorId?: string,
    specificMeetings?: string
  ) =>
    async (dispatch: Dispatch) => {
      try {
        await new EventsService().updateMeetingStatus(
          eventIdOrSlug,
          meetingId,
          body
        );

        const meetings = await new EventsService()
          .getMeetings(
            eventIdOrSlug,
            role,
            userId,
            selectedMeetingButton,
            sponsorId,
            specificMeetings
          )
          .then((r) => r.data);
        await dispatch(setMeetings(meetings));
      } catch (error) {
        console.log("here is the error in meeting", error);
      }
    };

export const setActiveSessionValue =
  (session: IEventSession) => async (dispatch: Dispatch) => {
    await dispatch(setActiveSession(session));
  };

export const getEventPolls =
  (liveEventId: string) => async (dispatch: Dispatch) => {
    try {
      const eventPolls = await new EventsService()
        .getEventPolls(liveEventId)
        .then((r) => r.data);
      await dispatch(setEventPolls(eventPolls));
    } catch (error) {
      console.log("here is the error in polls", error);
    }
  };

export const getEventHandouts =
  (liveEventId: string) => async (dispatch: Dispatch) => {
    try {
      const eventHandouts = await new EventsService()
        .getEventHandouts(liveEventId)
        .then((r) => r.data);
      await dispatch(setEventHandouts(eventHandouts));
    } catch (error) {
      console.log("here is the error in polls", error);
    }
  };

export const createVoteForPollAns =
  (
    alreadyVotedForAns: number,
    liveEventId: string,
    sessionId: string,
    questionId: string,
    body: any
  ) =>
    async (dispatch: Dispatch) => {
      try {
        if (alreadyVotedForAns)
          await new EventsService().removeVoteForPollAns(
            liveEventId,
            sessionId,
            questionId,
            body
          );
        else
          await new EventsService().createVoteForPollAns(
            liveEventId,
            sessionId,
            questionId,
            body
          );

        // Get Event  Polls
        const eventPolls = await new EventsService()
          .getEventPolls(liveEventId)
          .then((r) => r.data);
        await dispatch(setEventPolls(eventPolls));
      } catch (error) {
        console.log("here is the error in polls", error);
      }
    };
