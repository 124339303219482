import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { openMessageWindow } from '../../store/newMessage';
import { toggleUserOnlineStatus } from '../../store/user-status';
import ChatList from './chat-list';
import ChatListWrapper from './chat-list-wrapper';
import MessagesTab from './messages-tab';
import { useWebSocket } from 'src/utils/hooks';

export const Messages = () => {
  const dispatch = useDispatch();
  const { webSocket } = useWebSocket();

  const [isOpen, setIsOpen] = useState(false);

   useEffect(() => {
    if(!isOpen) return;
    webSocket.then((ws) =>
      ws.send(
        JSON.stringify({
          action: 'conversations',
          data: { limit: 25, first: 20, exclusiveStartKey: null }
        })
      )
    );
  }, [webSocket, isOpen]);

  return (
    <div className='flex flex-col'>
      {!isOpen && (
        <div className='w-288 bg-white shadow-md text-gray-2 !rounded-b-none create-post-card'>
          <MessagesTab
            handleNewMessageClick={() =>
              dispatch(
                openMessageWindow({
                  avatar: '',
                  userDisplayName: 'New Message',
                })
              )
            }
            handleShowMessagesClick={() => setIsOpen(true)}
            handleUserStatusToggle={() => dispatch(toggleUserOnlineStatus())}
          />
        </div>
      )}
      <div className=''>
        <ChatList isOpen={isOpen} setIsOpen={setIsOpen}>
          <div className='flex flex-col mt-6'>
            <ChatListWrapper />
          </div>
        </ChatList>
      </div>
    </div>
  );
};

export default Messages;
