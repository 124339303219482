import classNames from "classnames";
import { Logo } from "../../shared-components/V2/Logo";
import { minifiedImage, toBase64 } from "../../utils";
import { getMinifiedImage } from "src/mui/utils/image";
import { CardMedia } from "@mui/material";
import { IAppState } from "src/store";
import { useSelector } from "react-redux";
import { IEvent } from "src/models/user-events";

interface IEventCoverAndLogo {
  cover?: string;
  logo?: string;
  coverClassName?: string;
  logoClassName?: string;
  isCoverImageOverlayColor?: boolean;
  coverImageOverlayOpacity?: number;
  coverImageOverlayColour?: string;
  logoStyle?: string;
  eventLogo?: string;
  isCompanyNew?: boolean; 
}
export const EventCoverAndLogo = ({
  cover,
  logo,
  coverClassName,
  logoClassName,
  isCoverImageOverlayColor,
  coverImageOverlayOpacity,
  coverImageOverlayColour,
  logoStyle,
  eventLogo,
  isCompanyNew
}: IEventCoverAndLogo) => {

  const event: IEvent = useSelector((state: IAppState) => state.website.event!);

  return (
    <div className={classNames("relative h-full", {
      "!h-[99px]": isCompanyNew,
    })}>
      <Logo
        className={classNames("object-cover w-full h-[220px]", {
          [`${coverClassName}`]: coverClassName,
          "!h-[146px]": isCompanyNew
        })}
        src={minifiedImage(`${cover}`)}
      />
      {
        isCoverImageOverlayColor &&
        <div
          style={{
            backgroundColor: coverImageOverlayColour
              ? coverImageOverlayColour
              : "none",
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
            opacity: coverImageOverlayOpacity
              ? `${coverImageOverlayOpacity}%`
              : "0%",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
          }}
        />
      }

      <div>
        <CardMedia
          src={getMinifiedImage(event?.logo!)}
          component={'img'}
          sx={{
            // display: { md: "block", lg: "block", xl: "block", sm: 'block', xs: "none" },
            position: "absolute",
            left: "10%",
            top: "15%",
            width: "100%",
            maxWidth: "30%"
          }}
        />

      </div>

      <div
        className={classNames(
          "absolute w-[66px] max-w-[66px] min-w-[66px] h-[66px] max-h-[66px] min-h-[66px] bg-center small-radius bottom-5 flex items-center justify-center",
          {
            [`${logoClassName}`]: logoClassName,
            'bg-[#edf2f5]': logo?.includes('null'),
            'bg-white': logo,
          }
        )}
      >
        <Logo
          className={classNames("w-[64px] max-w-[64px] min-w-[64px] h-[64px] max-h-[64px] min-h-[64px] object-contain !rounded-[5px]", {
            [`${logoStyle}`]: logoStyle,
          })}
          defaultLogo="company"
          src={minifiedImage(`${logo}`)}
        />
      </div>
    </div>
  );
};
