import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IPost } from '../../models/post/post';
import { IPostState } from '../../models/post/PostState';
import { PostService } from '../../services/Post';
import { PostActionTypes } from './types';

export interface IPostLoadingAction {
  type: PostActionTypes.POST_LOADING;
  isLoading: boolean;
}

export interface IPostSuccessAction {
  type: PostActionTypes.POST_SUCCESS;
  payload: IPost;
}

export interface IPostErrorAction {
  type: PostActionTypes.POST_ERROR;
  errorMessage: string;
}

export type PostActions = IPostLoadingAction | IPostSuccessAction | IPostErrorAction;

export const getPost: ActionCreator<
  ThunkAction<Promise<any>, IPostState, null, IPostSuccessAction>
> = (postId: string) => {
  return async (dispatch: Dispatch) => {
    const postService: PostService = new PostService();

    try {
      const result = await postService.getPost(postId);

      dispatch({
        type: PostActionTypes.POST_SUCCESS,
        payload: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: PostActionTypes.POST_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: PostActionTypes.POST_LOADING,
        loading: false
      });
    }
  };
};

export const loadPost: ActionCreator<ThunkAction<any, IPostState, null, IPostLoadingAction>> =
  (shouldLoad: boolean) => (dispatch: Dispatch) =>
    dispatch({
      type: PostActionTypes.POST_LOADING,
      loading: shouldLoad
    });
