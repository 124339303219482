import {
  createSlice,
  Dispatch,
  PayloadAction
} from '@reduxjs/toolkit';

export type UserStatusState = {
  isUserOnline: boolean;
};

const initialState: UserStatusState = {
  isUserOnline: false
};

export const userStatusSlice = createSlice({
  name: 'user-status',
  initialState,
  reducers: {
    getUserStatus: (state) => {
      const status = localStorage.getItem('isUserOnline') === 'true';
      state.isUserOnline = status;
    },
    setUserStatus: (state, action: PayloadAction<boolean>) => {
      state.isUserOnline = action.payload;
    }
  }
});

export const { getUserStatus, setUserStatus } = userStatusSlice.actions;

export const checkUserStatus = () => async (dispatch: Dispatch) => dispatch(getUserStatus());

export const toggleUserOnlineStatus = () => async (dispatch: Dispatch) => {
  const currentStatus = localStorage.getItem('isUserOnline') !== 'true';
  localStorage.setItem('isUserOnline', currentStatus.toString());
  dispatch(setUserStatus(currentStatus));
};
