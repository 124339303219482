import {
  createSlice,
  Dispatch,
  PayloadAction
} from '@reduxjs/toolkit';

import { FilUploadService } from '../../services/FileUpload';

export type FileUploadResult = {
  ETag: string;
  Location: string;
  key: string;
  Key: string;
  Bucket: string;
  VersionId: string;
};

export type FileUploadState = {
  isUploading: boolean;
  data: FileUploadResult;
  errorMessage: string;
  progress?: number
};

const initialState: FileUploadState = {
  isUploading: false,
  progress: 0,
  data: {
    ETag: '',
    Location: '',
    key: '',
    Key: '',
    Bucket: '',
    VersionId: ''
  },
  errorMessage: ''
};

export const fileUploadSlice = createSlice({
  name: 'FileUploadSlice',
  initialState,
  reducers: {
    setIsUploading: (state, action: PayloadAction<boolean>) => {
      state.isUploading = action.payload;
    },
    setUploadProgress: (state, action: PayloadAction<number>) => { 
      state.progress = action.payload;
    },
    uploadFileSuccess: (state, action: PayloadAction<FileUploadResult>) => {
      state.data = action.payload;
      state.isUploading = false;
      state.progress = 0;
    },
    uploadFileFailure: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.isUploading = false; 
      state.progress = 0; 
    },
    resetUploadFile: (state) => {
      state.isUploading = initialState.isUploading;
      state.data = initialState.data;
      state.errorMessage = initialState.errorMessage;
      state.progress = initialState.progress;
    }
  }
});

export const { setIsUploading, setUploadProgress, uploadFileSuccess, uploadFileFailure, resetUploadFile } = fileUploadSlice.actions;

export const uploadFile = (data: any, fileType: string) => async (dispatch: Dispatch) => {
  try {
    const fileUploadService = new FilUploadService();
    dispatch(setIsUploading(true));
    const config = {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch(setUploadProgress(progress));
      }
    };

    let result;
    if (fileType === 'image') {
      result = await fileUploadService.uploadImage(data, config);
    } else if (fileType === 'document') {
      result = await fileUploadService.uploadDocument(data, config);
    } else if (fileType === 'video') {
      result = await fileUploadService.uploadVideo(data, config);
    }
    if(result){
      dispatch(uploadFileSuccess(result?.data));
    }
  } catch (err: any) {
    dispatch(uploadFileFailure(err.message));
  }
};

