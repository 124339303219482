import { LinkButton } from '../../../shared-components';
import Background from '../../../assets/images/virtual/create-and-host-events2.svg';

import you from '../../../assets/images/virtual/you-icon.svg';
import yourattendees from '../../../assets/images/virtual/attendees-icon.svg';
import yoursponsors from '../../../assets/images/virtual/sponsors-icon.svg';

export const AttendeesAndSponsors = () => (
  <div
    className="w-full 2xl:h-1550 min-h-1182"
    style={{
      background: `url(${Background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: ''
    }}
  >
    <div className="container mx-auto text-lg3 text-gray-1 font-extralight text-center mt-20 pt-3 lg:w-2/6 leading-snug">
      Gain full control and visibility of your event with a visual real-time dashboard for all your
      stakeholders, partners and sponsors.
    </div>
    <div className="container mx-auto mt-2">
      <div className="block lg:flex flex-row lg:mb-101 2xl:mb-24">
        <div className="block lg:flex flex-row mt-8 mb-8 w-11/12 mx-auto">
          <div className="block lg:flex flex-col  mx-auto">
            <img className="mx-auto" src={you} alt="" style={{ width: '', height: '' }} />
            <div className="text-dark-blue text-center">
              <div className="text-xl6 text-dark-blue font-normal text-center">You</div>
              <div className="text-md text-dark-blue font-extralight">
                Organise and manage easier
              </div>
              <div className="text-gray-1 font-extralight text-sm1 w-8/12  mt-2 mx-auto">
                Communicate with attendees, speakers and sponsors through your dedicated dashboards.
                Update programs, create and allocate sponsorship packages, speaking slots, one on
                one meetings.
              </div>
            </div>
          </div>
          <div className="block lg:flex flex-col mx-auto">
            <img
              className="mx-auto -mt-1"
              src={yourattendees}
              alt=""
              style={{ width: '', height: '' }}
            />
            <div className="text-dark-blue text-center">
              <div className="text-xl6 text-dark-blue font-normal text-center">Your attendees</div>
              <div className="text-md text-dark-blue font-extralight">Enhance engagement</div>
              <div className="text-gray-1 font-extralight text-sm1 text-center w-8/12  mt-2 mx-auto">
                Enable your audience to connect to a larger community in their industry, interact
                with peers and solution providers and gain access to a wide range of content.
              </div>
            </div>
          </div>
          <div className="flex flex-col  mx-auto">
            <img
              className="mx-auto -mt-1"
              src={yoursponsors}
              alt=""
              style={{ width: '', height: '' }}
            />
            <div className="text-xl6 text-dark-blue font-normal text-center">Your Sponsors</div>
            <div className="text-md text-dark-blue font-extralight text-center">
              Expand your Reach
            </div>
            <div className="text-gray-1 font-extralight text-sm1 text-center w-8/12  mt-2 mx-auto">
              Enable your sponsors to upload their content, update their speaking slots, gain access
              to the attendees list or arrange one on one meetings depending on their involvement.
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center lg:mt-[27.9em] lg:pt-[10px] 2xl:ml-20">
        <LinkButton
          text="Learn more about Event Pricing"
          backgroundColor=""
          textColor="#fff"
          classes="bg-blue ml-5 text-sm2 font-extralight hover:bg-blue-3"
          width="255px"
          height="30px"
          href="/host-events"
        />
      </div>
    </div>
  </div>
);

export default AttendeesAndSponsors;
