/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-imports-ts */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import commentIcon from '../../../assets/images/comment-icon.svg';
import microsoftLogoSmall from '../../../assets/images/microsoft-icon.svg';
import noPostsImage from '../../../assets/images/no-post-discussion.svg';
import shareIcon from '../../../assets/images/share-icon.svg';
import noAboutInterestIcon from '../../../assets/images/no-about-interests.svg';
import { CardMainPost } from '../../../components/posts';
import CreatePost from '../../../components/posts/create-post';
import UserProfileLayout from '../../../layouts/user-profile-layout';
import { IExperience } from '../../../models/experience';
import { ICreatePoll } from '../../../models/poll/poll';
import { ICreateComment } from '../../../models/post/comment';
import { IPost } from '../../../models/post/post';
import { PostService, UserService } from '../../../services';
import { FilUploadService } from '../../../services/FileUpload';
import { Loader } from '../../../shared-components/Loader';
import { IAppState } from '../../../store';
import { unlikeComment } from '../../../store/events/event-post-comment-like/actions';
import {
  likePost,
  unlikePost,
} from '../../../store/events/upcoming-event-post-like/actions';
import { createPoll } from '../../../store/poll';
import {
  createPostComment,
  likeComment,
} from '../../../store/posts/comments/actions';
import { getConnectSuggestions } from '../../../store/user/connect-suggestions/actions';
import { cleanupOwnConnections, getOwnConnections } from '../../../store/user/own-connections/actions';
import { cleanupOwnExperiences, getOwnExperience } from '../../../store/user/own-experience/actions';
import {
  getUserPostComments,
  getUserPosts,
} from '../../../store/user/posts/actions';
import {
  discardPosts,
  getOtherUserProfilePosts,
  getOwnProfilePosts,
} from '../../../store/user/profile-posts';
import { getRecommendedEvents } from '../../../store/user/recommended-events/actions';
import { getUserEvents } from '../../../store/user/user-events/actions';
import { cleanUserProfile, getUserProfile } from '../../../store/user/user-profile/actions';
import { imageUrlPrefix } from '../../../utils';
import { useCurrentUser } from '../../../utils/hooks';
import Connections from './connections';
import PeopleInYourNetwork from './people-in-your-network';
import ProfileCoverView from './profile-cover-view';
import UserExperience from './user-experience';
import { editPost } from '../../../store/events/event-post-actions/actions';

import { UserProfileMatching } from '../user-profile-matching';
import { CardEventsInterestedContainer } from '../../../shared-components/V2/CardEventsInterestedContainer';
import { UserSpotlightTab } from './user-spotlight';
import UserProfileSpotlightLayout from '../../../layouts/user-profile-spotlight-layout';
import { WelcomePost } from '../../../shared-components/V2/WelcomePost';
import Footer from '../../../shared-components/V2/Footer/footer';
import { Button } from '../../../shared-components/V2';
import AlertModal from '../../../components/alert-modal/alert-modal';
import InterestedFormNEW from '../../signup/components/interested-form-new';
import { UserHighlights } from '../user-profile-matching/user-highlights';
import { signupStep5 } from '../../../store/user/signup/actions';

export interface InterestType {
  offers: any[],
  interests: any[]
}

export const UserProfile = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get('tab'));
  const [introStatus, setIntroStatus] = useState(false);
  const [experienceStatus, setExperienceStatus] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userService = new UserService();
  const [userInterests, setUserInterests] = useState<InterestType>({
    offers: [],
    interests: []
  });

  const { slug: userId } = useParams();
  const currentUser = useCurrentUser();

  const [pastExperiences, setPastExperiences] = useState<IExperience[]>([]);
  const [currentExperiences, setCurrentExperiences] = useState<IExperience[]>(
    []
  );

  const [userPosts, setUserPosts] = useState<IPost[]>([]);
  const [nextPage, setNextPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [updateInterestsModal, setUpdateInterestsModal] = useState(false);
  const [interests, setInterests] = useState([]);
  const [offers, setOffers] = useState([]);

  const {
    userProfile,
    profilePosts,
    ownExperience,
    connections,
    recommendedEvents,
    connectSuggestions,
  } = useSelector((state: IAppState) => state);

  useEffect(() => {
    // intro and experience comes from setting: user-account-sidebar.tsx
    if (searchParams.get('intro')) {
      setActiveTab('about');
      setIntroStatus(true);
      setExperienceStatus(false);
    }
    if (searchParams.get('experience')) {
      setActiveTab('about');
      setIntroStatus(false);
      setExperienceStatus(true);
    }
    if (!searchParams.get('tab')) {
      setActiveTab('about');
      setSearchParams({ tab: 'about' }, { replace: true });
    } else if (searchParams.get('tab')) {
      setActiveTab(searchParams.get('tab'));
    }
  }, [activeTab, navigate, searchParams, setSearchParams]);

  useEffect(() => {
    setIsOwnProfile(currentUser.id === userId || currentUser.slug === userId);
  }, [userId, currentUser]);

  useEffect(() => {
    // Reset
    setUserPosts([]);
    setNextPage(1);
    setTotalPage(0);
    setFetching(false);

    // Cleanup required if user is moving between connections.
    dispatch(cleanUserProfile());
    dispatch(cleanUserProfile());
    dispatch(cleanupOwnExperiences());
    dispatch(cleanupOwnConnections());
    if (isOwnProfile) {
      dispatch(getOwnConnections());
      dispatch(getOwnProfilePosts());
    } else {
      dispatch(getOwnConnections(userId));
      dispatch(getOtherUserProfilePosts(userId!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOwnProfile, userId, dispatch]);
  useEffect(() => {
    if (isOwnProfile) {
      dispatch(getUserProfile());
      dispatch(getOwnExperience());
      dispatch(getOwnConnections());
    } else {
      dispatch(getUserProfile(userId));
      dispatch(getOwnExperience(userId));
      dispatch(getOwnConnections(userId));
    }

    dispatch(getRecommendedEvents());
    dispatch(getUserEvents());
    dispatch(getConnectSuggestions());
  }, [dispatch, isOwnProfile, userId]);

  useEffect(() => {
    setPastExperiences(ownExperience.value?.false!);
    setCurrentExperiences(ownExperience.value?.true!);
  }, [ownExperience.value]);

  useEffect(() => {
    if (profilePosts.ownProfilePosts) {
      setTotalPage(profilePosts.ownProfilePosts.totalPage);
    }
  }, [profilePosts.ownProfilePosts]);

  useEffect(() => {
    if (profilePosts.otherUserProfilePosts) {
      setTotalPage(profilePosts.otherUserProfilePosts.totalPage);
    }
  }, [profilePosts.otherUserProfilePosts]);

  // Run cleanup
  useEffect(
    () => function cleanup() {
      dispatch(discardPosts());
      dispatch(cleanUserProfile());
      dispatch(cleanupOwnExperiences());
      dispatch(cleanupOwnConnections());
      setIsOwnProfile(false);
    },
    [dispatch]
  );

  useEffect(() => {
    setUserInterests(prevUserInterests => ({
      ...prevUserInterests,
      offers: offers?.map((item: any) => item?.interest)!,
      interests: interests?.map((item: any) => item?.interest)!
    }));
  }, [offers, interests]);

  const getInterestMatch = useCallback(async (id: string) => {
    const userService = new UserService();
    const result = await userService.getMatchedInterestResults(id).then(({ data }) => data.map((x: any) => ({ ...x, type: 'interest' })) ?? []);
    setInterests(result);
  }, []);

  const getOfferMatch = useCallback(async (id: string) => {
    const userService = new UserService();
    const result = await userService.getMatchedOfferingResults(id).then(({ data }) => data.map((x: any) => ({ ...x, type: 'offer' })) ?? []);
    setOffers(result);
  }, []);

  useEffect(() => {
    if (userProfile?.value?.id!) {
      getInterestMatch(userProfile?.value?.id!);
      getOfferMatch(userProfile?.value?.id!);
    }
  }, [getInterestMatch, userId, userProfile?.value?.id, getOfferMatch, updateInterestsModal]);

  const isConnected = !isOwnProfile
    && connections?.value?.data?.some(
      connection => connection.id === currentUser.id
    )!;

  const fetchItems = useCallback(async () => {
    if (fetching) {
      return;
    }

    setFetching(true);

    try {
      const userSerivce = new UserService();
      let result: any;
      if (isOwnProfile) result = await userSerivce.getOwnProfilePosts(nextPage);
      else {
        result = await userSerivce.getOtherUserProfilePosts(userId!, nextPage);
      }

      setTotalPage(result.totalPage);
      if (result && nextPage <= result.totalPage) {
        setUserPosts(userPosts.concat(result.data));
        setNextPage(nextPage + 1);
      } else {
        setFetching(true);
      }
    } finally {
      setFetching(false);
    }
  }, [userPosts, fetching, nextPage, isOwnProfile, userId]);

  const hasMoreItems = nextPage <= totalPage;

  const loader = <Loader className="pt-10" key="loader" />;

  const resetDefaults = () => {
    setNextPage(1);
    setTotalPage(0);
    setFetching(false);
    setUserPosts([]);
  };

  const onClickEvent = (url: string | undefined) => {
    navigate(`${url}`);
  };

  const handleIntroStatus = (openEditModal: boolean) => {
    setIntroStatus(!openEditModal);
  };
  const handleExperienceStatus = (openEditModal: boolean) => {
    setExperienceStatus(!openEditModal);
  };

  const saveofferInterestedItems = () => {
    let data: any;
    // eslint-disable-next-line prefer-const
    data = {};
    data.offerListInterestedList = {};
    data.offerIds = { interestIds: [] };
    data.interestIds = { interestIds: [] };

    userInterests.offers.forEach(offer => data.offerIds.interestIds.push(offer.id));
    userInterests.interests.forEach(interest => data.interestIds.interestIds.push(interest.id));

    dispatch(signupStep5(data));
    setTimeout(() => {
      // Adding a short delay
      getInterestMatch(userProfile?.value?.id!);
      getOfferMatch(userProfile?.value?.id!);
    }, 1500);
    setUpdateInterestsModal(false);
  };

  return (
    <>
      {userProfile.value && (activeTab === 'posts' || activeTab === 'about') ? (
        <UserProfileLayout
          hero={(
            <ProfileCoverView
              intro={introStatus}
              handleIntroStatus={handleIntroStatus}
              id={userProfile.value?.id!}
              name={userProfile?.value?.fullName!}
              coverPhotoUrl={userProfile?.value?.cover!}
              avatarUrl={userProfile?.value?.avatar!}
              jobTitle={userProfile?.value?.jobTitle!}
              companyLogoUrl={microsoftLogoSmall}
              location={userProfile?.value?.location!}
              aboutMe={userProfile?.value?.about!}
              companyId={userProfile?.value?.companyId!}
              company={userProfile?.value?.company!}
              industry={userProfile?.value?.industry!}
              companyName={userProfile?.value?.company?.name!}
              isOwnProfile={isOwnProfile}
              isConnected={isConnected}
              sentRequest={userProfile.value?.sentRequest!}
              hasRequest={userProfile.value?.hasRequest!}
              uploadCoverPhoto={async (data: FormData) => {
                const fileUploadService = new FilUploadService();

                const uploadedFile = await fileUploadService.uploadImage(data);

                await userService.editOwnProfile({ cover: uploadedFile.data.key });
                await dispatch(getUserProfile());
              }}
              uploadProfilePicture={async (data: FormData) => {
                const fileUploadService = new FilUploadService();

                const uploadedFile = await fileUploadService.uploadImage(data);

                await userService.editOwnProfile({ avatar: uploadedFile.data.key });
                await dispatch(getUserProfile());
              }}
              onEditProfile={async (data: any) => {
                await userService.editOwnProfile(data);
                await dispatch(getUserProfile());
              }}
              onAcceptConnection={async () => {
                const connectionRequest = userProfile.value?.sendConnectionRequests.find(
                  (request: any) => request.connectionUserId === currentUser.id
                );
                await userService.acceptConnectRequests(connectionRequest.id);
                await dispatch(getUserProfile(userId));
              }}
              onRejectConnection={async () => {
                const connectionRequest = userProfile.value?.sendConnectionRequests.find(
                  (request: any) => request.connectionUserId === currentUser.id
                );
                await userService.rejectConnectRequests(connectionRequest.id);
                await dispatch(getUserProfile(userId));
              }}
              onConnect={async () => {
                await userService.sendConnectionRequest({
                  connectionUserId: userProfile.value?.id!,
                });
                await dispatch(getUserProfile(userId));
              }}
              onWithDrawConnection={async () => {
                await dispatch(getUserProfile(userId));
              }}
              onRemoveConnection={async () => {
                await userService.removeConnection(
                  userProfile.value?.connectionId!
                );
                await dispatch(getOwnConnections(userId));
                await dispatch(getUserProfile(userId));
              }}
              onBlockConnection={async () => {
                await userService.blockConnection(userProfile.value?.id!);
                navigate('/home');
              }}
            />
          )}
          leftPanel={(
            <div className="flex flex-col w-full">
              <div className="flex flex-col">
                {/* Current experiences */}
                <UserExperience
                  title="Current experience"
                  items={currentExperiences}
                  isOwnProfile={isOwnProfile}
                  userId={userId}
                  isAbleToAdd
                  experience={experienceStatus}
                  handleExperienceStatus={handleExperienceStatus}
                />
                {/* Connections */}
                <div className="mt-4">
                  <Connections
                    connections={connections.value?.data!}
                    title="Connections"
                    userId={userProfile.value?.id}
                    totalEmployees={connections.value?.data?.length}
                  />
                </div>
                {/* Past experience */}
                {pastExperiences?.length > 0 && (
                  <UserExperience
                    title="Past experience"
                    items={pastExperiences}
                    isOwnProfile={isOwnProfile}
                    userId={userId}
                  />
                )}
              </div>
            </div>
          )}
          rightPanel={(
            <div className="flex flex-col xl:w-min w-full">
              {/* Events you might be interested in */}
              {recommendedEvents.value?.data
                && recommendedEvents.value?.data.length > 0 && (
                  <CardEventsInterestedContainer
                    events={recommendedEvents.value?.data}
                    name="Events you might be interested in"
                    className="px-2 py-2 mb-4"
                    dropdownIcon="/images/Polygon-dropdown.svg"
                    onClickEvent={onClickEvent}
                  />
              )}
              {/* People in your network */}
              <PeopleInYourNetwork
                people={connectSuggestions.value?.data!}
                onAddConnection={async (connectUserId: string) => {
                  await userService.sendConnectionRequest({
                    connectionUserId: connectUserId,
                  });
                  dispatch(getConnectSuggestions());
                }}
              />
              <Footer className="mt-4" />
            </div>
          )}
        >
          <>
            {isOwnProfile && activeTab === 'posts' && (
              <CreatePost
                wallId={currentUser.id}
                location="profile"
                avatarUrl={`${imageUrlPrefix}/${currentUser?.avatar!}`}
                userDisplayName={currentUser.fullName!}
                onCreatePoll={(data: ICreatePoll) => dispatch(createPoll(data))}
                modalWidth="w-[462px]"
              />
            )}
            {activeTab === 'posts'
              && (
                <div className="overflow-visible">
                  <InfiniteScroll
                    keyParams="user-posts"
                    key="user-posts"
                    pageStart={0}
                    loadMore={fetchItems}
                    hasMore={hasMoreItems}
                    loader={loader}
                  >
                    {(!userPosts || userPosts.length === 0) && (
                      <WelcomePost
                        key="Welcome-message"
                        className="mt-4"
                        imageSrc={noPostsImage}
                        subTitle="Nothing to see here yet"
                      />
                    )}
                    {userPosts
                      && userPosts.map(
                        (post, index) => post && (
                          <CardMainPost
                            commentIcon={commentIcon}
                            shareIcon={shareIcon}
                            className={classNames('mb-4 w-full bg-white card-shadow', {
                              'mt-4': isOwnProfile,
                            })}
                            key={post.id}
                            post={post}
                            getPostComments={(id: string) => dispatch(getUserPostComments(id))}
                            addPostComment={async (
                              postId: string,
                              data: any,
                              attachment: any
                            ) => {
                              const comment: ICreateComment = {
                                content: data,
                                parentId: '',
                                userMentioned: '',
                              };
                              await dispatch(
                                createPostComment(postId, comment, attachment)
                              );
                              await dispatch(getUserPostComments(postId));
                              if (isOwnProfile) await dispatch(getUserPosts());
                              else await dispatch(getOtherUserProfilePosts(userId!));
                            }}
                            addReply={async (
                              postId: string,
                              replyText: string,
                              parentId: string,
                              attachment: any
                            ) => {
                              const comment: ICreateComment = {
                                content: replyText,
                                parentId,
                                userMentioned: '',
                              };
                              await dispatch(
                                createPostComment(postId, comment, attachment)
                              );
                              await dispatch(getUserPostComments(postId));
                            }}
                            onCommentLike={async (
                              likeType: string,
                              commentId: string
                            ) => {
                              if (likeType) {
                                await dispatch(likeComment(commentId, likeType));
                              } else {
                                await dispatch(unlikeComment(commentId));
                              }
                              if (isOwnProfile) await dispatch(getUserPosts());
                              else await dispatch(getOtherUserProfilePosts(userId!));
                            }}
                            onPostLike={async (likeType: string, postId: string) => {
                              // If you got like type then set the like on post else its remove like 'undefined'
                              if (likeType) {
                                await dispatch(likePost(postId, likeType));
                              } else {
                                await dispatch(unlikePost(postId));
                              }
                              if (isOwnProfile) await dispatch(getUserPosts());
                              else await dispatch(getOtherUserProfilePosts(userId!));
                            }}
                            onDelete={async () => {
                              const postService = new PostService();
                              await postService.deletePost(post.id);
                              resetDefaults();
                            }}
                            onEditPost={async (
                              postId: string,
                              content: string,
                              privacy: string
                            ) => {
                              await dispatch(editPost(postId, content, privacy));
                              resetDefaults();
                            }}
                          />
                        )
                      )}
                  </InfiniteScroll>
                </div>
              )}
            {!isOwnProfile && activeTab === 'about' && (
              <div
                className="mb-4 pt-[50px] pb-[30px] w-full bg-white card-shadow"
              >
                <UserProfileMatching
                  userProfile={userProfile}
                  userId={userProfile?.value?.id}
                  isOwnProfileNot
                />
              </div>
            )}
            {isOwnProfile && activeTab === 'about' && (
              <div className="flex justify-center items-center mb-4 w-full">
                <WelcomePost
                  title="Keep your interests up to date to get matched with others"
                  imageSrc={noAboutInterestIcon}
                  innerClassName="w-full max-w-[380px] pt-4"
                >
                  <>
                    <Button
                      text="Update your interests"
                      className="w-[182px] h-[30px] bg-[#EDF2F5] hover:bg-[#CEDCF2] text-[13px] text-[#203C6E] font-semibold leading-[20px] tracking-wide"
                      onClick={() => setUpdateInterestsModal(true)}
                    />
                    <div className="-ml-[65]">
                      <UserHighlights userProfile={userProfile} userId={userId} interests={interests} offers={offers} />
                    </div>
                    <AlertModal
                      isOpen={updateInterestsModal}
                      onClose={() => setUpdateInterestsModal(false)}
                      closeIconClass=" right-[21px] top-[12px] px-0"
                      isCloseIcon
                      className="p-[16px] flex justify-center"
                    >
                      <div>
                        <InterestedFormNEW from="user" interests={userInterests} setUserInterests={setUserInterests} page={null} widthStyle="md:w-[450px] min-h-auto m-0" />
                        <div className="flex items-center justify-end mt-6 pb-2">
                          <Button
                            text="Cancel"
                            className="rounded-5 inline-block text-sm font-semibold h-[31px] bg-[#EDF2F5] text-[#203C6E] w-[88px] mr-[10px] hover:bg-[#CEDCF2] tracking-wide"
                            onClick={() => setUpdateInterestsModal(false)}
                          />
                          <Button
                            text="Save"
                            className="rounded-[5px] inline-block text-sm font-semibold h-[31px] bg-[#0049EF] hover:bg-[#2e6eff] text-white w-[74px] tracking-wide"
                            onClick={saveofferInterestedItems}
                          />
                        </div>
                      </div>
                    </AlertModal>
                  </>
                </WelcomePost>
              </div>
            )}
          </>
        </UserProfileLayout>
      ) : activeTab !== 'spotlight' && <Loader isLoaderLogo className="h-[100vh]" key="loader" />}
      {userProfile.value && activeTab === 'spotlight' && (
        <UserProfileSpotlightLayout
          hero={(
            <ProfileCoverView
              isSpotlightProfile
              intro={false}
              id={userProfile.value?.id!}
              name={userProfile?.value?.fullName!}
              coverPhotoUrl={userProfile?.value?.cover!}
              avatarUrl={userProfile?.value?.avatar!}
              jobTitle={userProfile?.value?.jobTitle!}
              companyLogoUrl={microsoftLogoSmall}
              location={userProfile?.value?.location!}
              aboutMe={userProfile?.value?.about!}
              companyId={userProfile?.value?.companyId!}
              company={userProfile?.value?.company!}
              industry={userProfile?.value?.industry!}
              companyName={userProfile?.value?.company?.name!}
              isOwnProfile={isOwnProfile}
              isConnected={isConnected}
              sentRequest={userProfile.value?.sentRequest!}
              hasRequest={userProfile.value?.hasRequest!}
              uploadCoverPhoto={async (data: FormData) => {
                const fileUploadService = new FilUploadService();

                const uploadedFile = await fileUploadService.uploadImage(data);

                await userService.editOwnProfile({ cover: uploadedFile.data.key });
                await dispatch(getUserProfile());
              }}
              uploadProfilePicture={async (data: FormData) => {
                const fileUploadService = new FilUploadService();

                const uploadedFile = await fileUploadService.uploadImage(data);

                await userService.editOwnProfile({ avatar: uploadedFile.data.key });
                await dispatch(getUserProfile());
              }}
              onEditProfile={async (data: any) => {
                await userService.editOwnProfile(data);
                await dispatch(getUserProfile());
              }}
              onAcceptConnection={async () => {
                const connectionRequest = userProfile.value?.sendConnectionRequests.find(
                  (request: any) => request.connectionUserId === currentUser.id
                );
                await userService.acceptConnectRequests(connectionRequest.id);
                await dispatch(getUserProfile(userId));
              }}
              onRejectConnection={async () => {
                const connectionRequest = userProfile.value?.sendConnectionRequests.find(
                  (request: any) => request.connectionUserId === currentUser.id
                );
                await userService.rejectConnectRequests(connectionRequest.id);
                await dispatch(getUserProfile(userId));
              }}
              onConnect={async () => {
                await userService.sendConnectionRequest({
                  connectionUserId: userProfile.value?.id!,
                });
                await dispatch(getUserProfile(userId));
              }}
              onWithDrawConnection={async () => {
                await dispatch(getUserProfile(userId));
              }}
              onRemoveConnection={async () => {
                await userService.removeConnection(
                  userProfile.value?.connectionId!
                );
                navigate('/home');
              }}
              onBlockConnection={async () => {
                await userService.blockConnection(userProfile.value?.id!);
                navigate('/home');
              }}
            />
          )}
          rightPanel={(
            <div className="flex flex-col h-[347px] overflow-y-auto no-scrollbar p-[1px]">
              {/* Events you might be interested in */}
              {recommendedEvents.value?.data
                && recommendedEvents.value?.data.length > 0 && (
                  <CardEventsInterestedContainer
                    events={recommendedEvents.value?.data}
                    name="Events you might be interested in"
                    className="px-2 py-2 mb-4"
                    dropdownIcon="/images/Polygon-dropdown.svg"
                    onClickEvent={onClickEvent}
                  />
              )}
              {/* People in your network */}
              <PeopleInYourNetwork
                people={connectSuggestions.value?.data!}
                onAddConnection={async (connectUserId: string) => {
                  await userService.sendConnectionRequest({
                    connectionUserId: connectUserId,
                  });
                  dispatch(getConnectSuggestions());
                }}
              />
            </div>
          )}
        >
          <UserSpotlightTab id={userProfile.value?.id!} />
        </UserProfileSpotlightLayout>
      )}
    </>
  );
};

export default UserProfile;
