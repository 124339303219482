import React, { FC, useRef, useState } from "react";
import { toast } from 'react-toastify';
import Stories from 'react-insta-stories';
import { useNavigate } from "react-router-dom";
import { Typography } from "../..";
import { CardProfileConnect } from "../CardProfileConnect";
import { ICardProfileConnectProps } from "../CardProfileConnect/CardProfileConnect";
import ArrowRightIcon from "../../../assets/images/story-arrow-right.svg";
import ArrowLeftIcon from "../../../assets/images/story-arrow-left.svg";
import MenuCopyLink from '../../../assets/images/event-landing-pre-event/copy-link-icon.svg';
import Trash from '../../../assets/images/trash.svg';
import classNames from "classnames";
import { getCompanyName, getCompanySlug } from "../../../utils";
import { StoryProfileConnect } from "../CardProfileConnect/StoryProfileConnect";
// import { useCurrentUser } from "src/utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "src/store";
import AlertModal from "src/components/alert-modal/alert-modal";
import ReactHlsPlayer from "@panelist/react-hls-player";
import { viewStories } from "src/store/user/story/view-story/actions";
import { ViewStoryUserIcons } from "./ViewStoryUserIcons";
import { DropDownMenu } from "../DropdownMenu";
import { DeletePost } from "src/components/posts/delete-post";
import { PostService } from "src/services";
import { EVENT } from "src/constants";
import { getAllStories } from "src/store/user/story/actions";
export interface ICardContainerProps {
  title: string;
  cards: ICardProfileConnectProps[];
  className: string;
  onClick?: Function;
  shouldDisplayCard?: boolean;
  loadMorePeople?: Function;
  allStoriesData?:any[];
  setAddNewStory:(value:string)=>void
}

const sideScroll = (
  element: HTMLDivElement,
  speed: number,
  distance: number,
  step: number
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

const storyContent = {
  width: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
  margin: 'auto',
  
}

export const StoryContainer: FC<ICardContainerProps> = ({
  title,
  cards,
  className = "",
  onClick = () => { },
  shouldDisplayCard,
  loadMorePeople = () => {},
  allStoriesData,
  setAddNewStory,
}) => {
  const dispatch = useDispatch();
  const getPostUrl = (postId: string) =>
    `${window.location.origin}/home?postId=${postId}`;
  const [showArrows, setShowArrows] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const contentWrapper = useRef(null);
  const [selectedStory,setSelectedStroy] = useState<any>({open:false,story:null});
  const [storyViews,setStoryViews] = useState<any>([]);
  const [toggleDeleteConfirmation, setToggleDeleteConfirmation] =
    useState<boolean>(false);
  const [currentIndexId, setCurrentIndexId] = useState('');
  const [currentCreatorId, setCurrentCreatorId] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { currentUser: user, redirect } = useSelector(
    (state: IAppState) => state.auth
  );

  // const isEmptyStory = cards.length === 0;
  // const isOwnUser = user?.id === userId

  const navigate = useNavigate();
  const onUserClick = (url: string | undefined) => {
    navigate(`${url}`);
  };

  const viewStory = (storyId:string,storyIndex:number) => {
   const story= cards?.find((c:any)=>c?.id===storyId);
   setCurrentIndex(storyIndex);
    setSelectedStroy({open:true,story});
  }

  const onCompanyClick = (url: string | undefined) => {
    if (!url) return;
    navigate(`${url}`);
  }

  const onAllStoriesEndHandler = () =>{
    if(currentIndex < cards?.length - 1)
      {
        setCurrentIndex(currentIndex + 1)
      }
      else {
        setSelectedStroy({open:false,story:null});
      }
   
  };

  const onCloseModal = () => {
    // setToggleReportPostModal(false);
    setToggleDeleteConfirmation(false);
    setSelectedStroy({open:false,story:null});
  };

  // if (!cards?.length) return null;

  const handleViewStory = (currentStoryIndex:number) =>{
    const storyId =  cards?.[currentIndex]?.userStories?.[currentStoryIndex]?.story?.id;
    const creatorId = cards?.[currentIndex]?.userStories?.[currentStoryIndex]?.story?.creator?.id;
    const views =  cards?.[currentIndex]?.userStories?.[currentStoryIndex]?.story?.views;
    setCurrentIndexId(storyId);
    setCurrentCreatorId(creatorId);
    setStoryViews(views?.map((v:any) => v?.viewer));
    dispatch(viewStories(storyId));
  };

  

  const ownPostOptions: any[] = [
    {
      id: "delete",
      type: "icon",
      text: "Delete",
      icon: Trash,
      onClickHandler: () => setToggleDeleteConfirmation(true),
    },
    // {
    //   id: "copy-link",
    //   type: "icon",
    //   text: "Copy Link",
    //   icon: MenuCopyLink,
    //   onClickHandler: () => {
    //     navigator.clipboard.writeText(getPostUrl(currentIndexId));
    //     toast.success(EVENT.COPY);
    //   },
    // },
    // {
    //   id: "edit",
    //   text: "Edit",
    //   onClickHandler: () => setToggleEditPostDialog(true),
    // },
    // {
    //   id: "share",
    //   text: "Share",
    //   onClickHandler: () => setToggleSharePostDialog(true),
    // },
  ];

  return (
    <div
      className={classNames("w-full relative mb-[14px]", {
        [`${className}`]: className,
        "bg-white card-shadow px-[20px]": shouldDisplayCard !== true,
      })}
      onMouseEnter={() => setShowArrows(true)}
      onMouseLeave={() => setShowArrows(false)}
    >
      <div className="flex flex-col">
        <div data-test-id="connect-suggestions-container">
          <Typography variant="ContainerHeading" text={title} />
        </div>
        <div className="flex flex-row relative">
          {cards.length > 2 ? (
            <img
              className={classNames("absolute -left-[19px] bottom-[18px] cursor-pointer z-50", {
                hidden: !showArrows,
              })}
              aria-label="left-arrow-target"
              onClick={() => sideScroll(contentWrapper.current!, 25, 200, -10)}
              src={ArrowLeftIcon}
            />
          ) : null}
          <div
            className={classNames("flex flex-row overflow-x-auto no-scrollbar", {
              // 'w-full': isEmptyStory,
            })}
            ref={contentWrapper}
          >
            <div
              key={`card-0`}
              onClick={() => setAddNewStory("story")}
              className={classNames({
                // 'w-full create-post-card bg-white hover:bg-[#DFE5EB] cursor-pointer transition-all overflow-hidden p-[11px] flex-row flex': isEmptyStory,
              })}
            >
              <StoryProfileConnect
                id={user?.id!}
                firstName={user?.firstName!}
                lastName={user?.lastName!}
                slug={user?.slug}
                avatar={user?.avatar}
                cover={user?.cover!}
                onClickConnect={(id:string)=>setAddNewStory("story")}
                onUserClick={onUserClick}
                className={classNames("w-[149px] h-[227px] border-1 border-[#dee1e3] mr-[10px] relative rounded-[12px] overflow-hidden", {
                  // '!w-[40px] !h-[40px] !rounded-[8px] flex bg-[#edf2f5] items-center justify-center': isEmptyStory,
                })}
                addNewStory
                isEmptyStory={false}
              />
            </div>
            {cards &&
              cards.length > 0 &&
              cards.map((card, i) => (
                <div key={`card-${i}`}>
                  <StoryProfileConnect
                    id={card?.id}
                    firstName={card?.creator?.firstName}
                    lastName={card?.creator?.lastName}
                    slug={card?.creator?.slug}
                    companySlug={getCompanySlug(card)}
                    avatar={card?.creator?.avatar}
                    cover={   card?.thumbnail ?  `${process.env.REACT_APP_DEFAULT_VIDEO_HLS_URL}/${card?.thumbnail}` : `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${card?.source}`}
                    jobTitle={card?.creator?.jobTitle}
                    location={getCompanyName(card)}
                    onClickConnect={(storyId:string)=>viewStory(storyId,i)!}
                    onCompanyClick={onCompanyClick}
                    mutualConnections={card?.mutualConnections}
                    onUserClick={onUserClick}
                    className="w-[149px] h-[227px] border-1 border-[#dee1e3] mr-[10px] relative rounded-[12px] overflow-hidden"
                  />
                </div>
              ))}
          </div>
          {cards.length > 2 ? (
            <img
              className={classNames("absolute -right-[19px] bottom-[18px] cursor-pointer", {
                hidden: !showArrows,
              })}
              aria-label="right-arrow-target"
              onClick={() => sideScroll(contentWrapper.current!, 25, 190, 10)}
              src={ArrowRightIcon}
              // onClick={() => loadMorePeople()}
            />
          ) : null}
        </div>
        <AlertModal isOpen={selectedStory?.open} onClose={onCloseModal} className="w-auto bg-transparent" closeIconClass="-right-[2px] top-[36px] !z-[10000]" isCloseWhite isStoryModal>
          <Stories
            stories={cards?.[currentIndex]?.userStories}
            defaultInterval={3000}
            width={432}
            height={768} 
            storyStyles={storyContent}
            loop={isDropdownOpen}
            keyboardNavigation={true}
            onStoryStart={(currentStoryIndex:number)=>{handleViewStory(currentStoryIndex)}}
            onStoryEnd={()=>{}}
            onAllStoriesEnd={onAllStoriesEndHandler}
            isPaused={isDropdownOpen}
          />
             <div className="flex gap-2 px-3 py-3 bg-black items-center">
              <ViewStoryUserIcons users={storyViews || []} userQty={3} baseUrl={process.env.REACT_APP_IMAGE_URL_PREFIX} className="text-sm font-medium text-blue-700" />
            </div>
            <div className="right-[47px] top-[36px] !z-[10000] absolute bg-[#edf2f5] rounded-full">
              {currentCreatorId === user?.id && (
                <DropDownMenu
                  options={ownPostOptions}
                  containerClasses="flex justify-center items-center relative"
                  menuCardClasses="absolute top-8 right-0 w-193 opacity-100 bg-white -ml-7 mt-2 shadow-md rounded-lg p-1 z-30 cursor-pointer"
                  titleAndSubtitleGroup="flex justify-left items-left ml-0 rounded-[5px] hover:bg-gray-1 leading-6 text-left p-1 cursor-pointer"
                  buttonStyles="flex items center w-30 h-30"
                  listTextStyles="block pl-2"
                  listIconStyles="pl-1"
                  textAndImageContainer="flex justify-left items-center w-48 pt-1"
                  setIsDropdownOpen={setIsDropdownOpen}
                />
              )}
            </div>
        </AlertModal>
        {toggleDeleteConfirmation && (
        <AlertModal isCloseIcon closeIconClass='right-[7px] top-[14px]' className="w-[374px] bg-[#EDF2F5]" isOpen={toggleDeleteConfirmation} onClose={onCloseModal}>
          <DeletePost
            postId={currentIndexId}
            message={"Are you sure you want to delete this story?"}
            onDelete={async (postId: string) => {
              const postService = new PostService();
              await postService.deletePost(postId);
              
              dispatch(getAllStories());
              onCloseModal();
            }}
            onClose={onCloseModal}
          />
        </AlertModal>
      )}
      </div>
    </div>
  );
};

export default StoryContainer;
