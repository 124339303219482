import classNames from 'classnames';

import { EVENT_ABOUT_CARD } from '../../../constants';
import { IAboutEventProps } from '../../../models/event/event-about';
import { ParseContent } from '../../../utils/parse-html';

interface IEventItemProps {
  title: string;
  detail: string;
}

const EventItem = (props: IEventItemProps) => (
  <div className='flex flex-col'>
    <div className='text-blue-1 text-md2 font-medium wide-tracking text-initialize'>
      {props.title}
    </div>
    <div className='text-[13px] font-normal text-[#172746] wide-tracking'>
      <ParseContent content={props.detail} />
    </div>
  </div>
);

export const AboutEvent = (props: IAboutEventProps) => {
  const aboutEventTitle = classNames(
    'text-blue-1 text-md2 font-medium wide-tracking text-initialize'
  );

  return (
    <div className='flex flex-col px-1 py-2'>
      <div className='mb-3'>
        <EventItem
          title={EVENT_ABOUT_CARD.EVENT_NAME}
          detail={props.eventName}
        />
      </div>
      <div className='mb-3'>
        {props.eventTheme && (
          <EventItem
            title={EVENT_ABOUT_CARD.EVENT_THEME}
            detail={props.eventTheme}
          />
        )}
      </div>
      <div className='mb-3'>
        {props.overview && (
          <EventItem
            title={EVENT_ABOUT_CARD.EVENT_OVERVIEW}
            detail={props.overview}
          />
        )}
      </div>
      <div className='mb-3'>
        {props.eventKeyDiscussionPoints &&
          props.eventKeyDiscussionPoints.length > 0 && (
            <div className='flex flex-col'>
              <div className={aboutEventTitle}>
                {EVENT_ABOUT_CARD.EVENT_KEY_DISCUSSION_POINTS}
              </div>
              <div className='text-xs'>
                <ul className='mr-4 pl-[18px]'>
                  {props.eventKeyDiscussionPoints &&
                    props.eventKeyDiscussionPoints.length > 0 &&
                    props.eventKeyDiscussionPoints.map((item, index) => (
                      <li className="sm:mb-0 mb-1" key={`job-title-${index}`}>
                        {item.key && (
                          <span key={`discussion-point-${index}`}>
                            {item.key}
                          </span>
                        )}
                        {item.summary && (
                          <div className=' text-[13px] leading-[17px] text-[#172746] wide-tracking'>
                            {' '}
                            <ParseContent content={item.summary} />
                          </div>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}
      </div>
      <div className='mb-1'>
        <div className='flex row sm:gap-[114px] gap-[20px]'>
          {props.attendingJobTitles && props.attendingJobTitles.length > 0 && (
            <div className='flex flex-col'>
              <div className={aboutEventTitle}>
                {EVENT_ABOUT_CARD.EVENT_ATTENDING_JOB_TITLES}
              </div>
              <div className='text-xs'>
                <ul>
                  {props.attendingJobTitles &&
                    props.attendingJobTitles.length > 0 &&
                    props.attendingJobTitles.map((item, index) => (
                      <li className='text-[13px] leading-[17px] text-[#172746] wide-tracking' key={`job-title-${index}`}>
                        {item.name && (
                          <span key={`job-title-${index}`}>• {item.name}</span>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}
          {props.attendingIndustries && props.attendingIndustries.length > 0 && (
            <div className='flex flex-col'>
              <div className={aboutEventTitle}>
                {EVENT_ABOUT_CARD.EVENT_ATTENDING_INSUSTRIES}
              </div>
              <div className='text-xs'>
                <ul>
                  {props.attendingIndustries &&
                    props.attendingIndustries.length > 0 &&
                    props.attendingIndustries.map((item, index) => (
                      <li className='text-[13px] leading-[17px] text-[#172746] wide-tracking' key={`industry-${index}`}>
                        {item.name && (
                          <span key={`industry-${index}`}>• {item.name}</span>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutEvent;
