import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser, useUserDetails } from '../../../../utils/hooks';
import { Logo } from '../../../../shared-components/V2/Logo';
import { Avatar, Button } from '../../../../shared-components/V2';
import { UserWorkIcon } from '../UserWorkIcon';
import { UserArrowIcon } from '../UserArrowIcon';
import { StarIcon } from '../StarIcon';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface Props {
  userProfile?: any;
  companies?: any[];
  events?: any[];
  interests?: any[];
}

export const UserMutuals = ({
  userProfile, companies, events, interests
}: Props) => {
  const user = useCurrentUser();
  const colors = getBrandingColors();
  const { avatar } = useUserDetails(user);
  const navigate = useNavigate();
  const [seeMoreEvent, setSeeMoreEvent] = useState(false);
  const [seeMoreCompany, setSeeMoreCompany] = useState(false);
  const [seeMoreInterests, setSeeMoreInterestst] = useState(false);
  return (
    <div className=" w-full px-[0px] m-auto justify-center mb-5 mt-2">
      {/* <div className="flex items-center justify-center mb-5">
        <div className="flex -space-x-1 overflow-hidden mr-1">
          <Avatar
            className="ring-2 ring-white"
            src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${userProfile?.value?.avatar}`}
            alt=""
            size="XSMALL"
          />
          <Avatar
            className="ring-2 ring-white"
            src={avatar}
            alt=""
            size="XSMALL"
          />
        </div>
        <div className="text-[15px] leading-[18px] text-[#172746] font-medium">Mutuals</div>
      </div> */}
      {
        !!events?.length && (
          <div style={{ backgroundColor: colors?.Main || '#000000' }} className="py-[10px] px-[10px] mb-[5px] rounded-5">
            <div className="flex">
              <div className="mr-2"><UserWorkIcon fillColor={colors?.Text} /></div>
              <div className="pt-1">
                <div className="text-[15px] font-medium leading-[9px] text-[#ffffff] mb-[12px] font-[Poppins-500]" style={{color: colors?.Text || '#ffffff'}}>
                  You both were at:
                </div>
                {
                  events && (seeMoreEvent ? events : events.slice(0, 3))?.map(event => (
                    <div className="text-[11px] font-normal text-[#ffffff] mb-0 cursor-pointer font-[Poppins-400]" onClick={() => navigate(`/event/${event.slug}/discussion`)}  style={{color: colors?.Text || '#ffffff'}}>
                      {event.name}
                    </div>
                  ))
                }
                {
                  events && events?.length > 3
                  && (
                  <Button
                    type="button"
                    text={seeMoreEvent ? 'See less' : 'See more'} 
                    size="x-small"
                    className={`mt-1 text-[#00FCED] h-[19px] text-[13px] font-semibold leading-[19px]`}
                    onClick={() => { setSeeMoreEvent(!seeMoreEvent); }}
                    style={{color: colors?.Accent || '#00FCED'}}
                  />
                  )
                }

              </div>
            </div>
          </div>
        )
      }
      {
        !!companies?.length && (
          <div style={{ backgroundColor: colors?.Main || '#000000' }} className="py-[10px] px-[10px] mb-[5px] rounded-5">
            <div className="flex">
              <div className="mr-0 -ml-[7px] -mt-[6px]"><UserArrowIcon fillColor={colors?.Text} /></div>
              <div className="pt-0">
                <div className="text-[15px] font-medium leading-[16px] mb-[14px] font-[Poppins-500]" style={{color: colors?.Text || '#ffffff'}}>
                  You both worked at:
                </div>
                {
                  companies && (seeMoreCompany ? companies : companies.slice(0, 1))?.map((company: any) => (
                    <div className="flex flex-row items-center mb-1.5">
                      <div className="pr-[5px]">
                        <Logo
                          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${company.logo}`}
                          alt={company.name}
                          defaultLogo="company"
                          className="w-[34px] h-[34px] rounded-[5px] bg-[#edf2f5]"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div style={{color: colors?.Text || '#ffffff'}} className="text-[13px] leading-[19px] font-semibold" >{company.name}</div>
                        <div style={{color: colors?.Text || '#ffffff'}} className="text-[13px] leading-[16px] text-ellipsis overflow-hidden font-normal" >
                          {company?.industry ? company?.industry?.name : company?.jobTitle}
                        </div>
                      </div>
                    </div>
                  ))
                }
                {
                  companies && companies.length > 1 && (
                  <Button
                    type="button"
                    text={seeMoreCompany ? 'See less' : 'See more'}
                    size="x-small"
                    className="mt-1 h-[19p] text-[13px] font-semibold leading-[19px]"
                    onClick={() => { setSeeMoreCompany(!seeMoreCompany); }}
                    style={{color: colors?.Accent || '#00FCED'}}
                  />
                  )
                }

              </div>
            </div>
          </div>
        )
      }
      {
        !!interests?.length && (
          <div style={{ backgroundColor: colors?.Main || '#000000' }} className="py-[10px] px-[10px]  rounded-5">
            <div className="flex">
              <div className="mr-0 -ml-[7px] -mt-[6px]"><StarIcon  fillColor={colors?.Text} /></div>
              <div className="pt-0">
                <div style={{color: colors?.Text || '#ffffff'}} className="text-[15px] font-medium leading-[16px] text-[#ffffff] mb-[12px] font-[Poppins-500]">
                  You both are interested in:
                </div>
                {
                  interests && (seeMoreInterests ? interests : interests.slice(0, 3))?.map(interest => <div  style={{color: colors?.Text || '#ffffff'}} className="text-[11px] font-normal mb-0 cursor-pointer font-[Poppins-400]">{interest?.interest?.name}</div>)
                }
                {
                  interests && interests.length > 3 && (
                  <Button
                    type="button"
                    text={seeMoreInterests ? 'See less' : 'See more'}
                    size="x-small"
                    className="mt-1 text-[#00FCED] h-[19p] text-[13px] font-semibold leading-[19px]"
                    onClick={() => { setSeeMoreInterestst(!seeMoreInterests); }}
                    style={{color: colors?.Accent || '#00FCED'}}
                  />
                  )
                }
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};
