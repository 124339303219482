import { format } from "date-fns";
import { Base64 } from "js-base64";
import { validate } from "uuid";
import differenceInMonths from "date-fns/differenceInMonths";

export * from "./auth";

export const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;
export const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // /.+@.+\..+/;
export const atLeast3charRegex = /(.*[a-z])/i;
export const digitRegex = /^[0-9]*$/;
export const passwordRegex = /.{8,}$/;

export const calculatePeriod = (fromDate: string, toDate: string | null) => {
  if (fromDate) {
    const from = new Date(fromDate);
    const fromFormatted = format(from, "MMM yyyy");
    let to = new Date(Date.now());
    let toFormatted = "";
    let difference = 0;

    if (toDate?.length === 0 || toDate === null) toFormatted = "Present";
    else {
      to = new Date(toDate.split("T")[0]);
      toFormatted = format(to, "MMM yyyy");
    }

    difference = differenceInMonths(to, from);
    difference++;

    let years = 0;
    if (difference >= 12) years = difference / 12;

    return `${fromFormatted} - ${toFormatted} ・ ${
      difference < 12
        ? difference + (difference <= 1 ? " month" : " months")
        : years.toFixed(0) + " years"
    }`;
  }
};

export const isFileImage = (file: any) => {
  return file && file["type"].split("/")[0] === "image";
};

export const displayPartOfMessage = (str: string, char: number) => {
  if (str.length > char) {
    return str.substring(0, char) + "...";
  } else {
    return str;
  }
};

export const mergeArrays = (arr1: any[], arr2: any[]): any[] => {
  if (!arr1.length) return arr2;
  if (!arr2.length) return arr1;
  return [arr1[0], arr2[0], ...mergeArrays(arr1.slice(1), arr2.slice(1))];
};

export const toBase64 = (str: string) => {
  try {
    return Base64.btoa(unescape(encodeURIComponent(str)))
    // return Base64.btoa(str);
  } catch (error) {
    console.log(str);
    console.log(error);
  }
};

export const minifiedImage = (path: string) => {
  return `${process.env.REACT_APP_DEFAULT_IMAGE_URL}/${toBase64(
    JSON.stringify({
      bucket: `${process.env.REACT_APP_DEFAULT_IMAGE_BUCKET}`,
      key: `${path}`,
      edits: {
        resize: {
          fit: "cover",
        },
      },
    })
  )}`;
};

export const shuffleArray = (arr: any[]) => {
  return arr.sort((a, b) => 0.5 - Math.random());
};

export const sortByKey = (arr: any[], key: string) => {
  return arr.sort((a, b) =>
    a?.[key].localeCompare(b?.[key], "en", { sensitivity: "base" })
  );
};

export const getCompanyName = (obj: any) => {
  if (obj?.company?.name) {
    return obj.company.name;
  } else if (!obj?.company && !validate(obj?.companyId)) {
    return obj?.companyId;
  }
};

export const getSpeakerName = (item: any) => {
  if (
    item?.eventSpeakers?.[0]?.firstName &&
    item?.eventSpeakers?.[0]?.lastName
  ) {
    return `${item?.eventSpeakers?.[0]?.firstName} ${item?.eventSpeakers?.[0]?.lastName}`;
  }

  return `${item?.firstName} ${item?.lastName}`;
};

export const getSpeakerCompanyName = (item: any) => {
  if (item?.eventSpeakers?.[0]?.company?.name) {
    return getCompanyName(item?.eventSpeakers?.[0]);
  }

  return getCompanyName(item);
};

export const getJobTitle = (item: any) => {
  if (item?.eventSpeakers?.[0]?.jobTitle) {
    return item?.eventSpeakers?.[0]?.jobTitle || "";
  }

  return item?.jobTitle || "";
};

export const getCompanySlug = (obj: any) => {
  if (obj?.company?.slug) {
    return obj.company.slug;
  } else if (!obj?.company && !validate(obj.companyId)) {
    return undefined;
  }
};

export const checkImageRatio = async (path: string) => {
  try {
    const response = await fetch(path);
    const blob = await response.blob();
    const image = new Image();

    image.src = URL.createObjectURL(blob);

    image.onload = () => {
      const ratio = image.naturalWidth / image.naturalHeight;
      return ratio;
    };
  } catch (error) {
    console.error("Error loading image:", error);
  }
};
