import {
  useEffect,
  useState,
} from 'react';

import {
  format,
  parseISO,
} from 'date-fns';
import { useForm } from 'react-hook-form';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import connectionIcon from '../../../assets/images/event-landing-pre-event/connection.svg';
import arrowDownIcon from '../../../assets/images/website-event/arrow-down.svg';
import { POST_ACTIONS } from '../../../constants';
import { IEditPost } from '../../../models/post/edit-post';
import { ISharePostProps } from '../../../models/post/share-post-props';
import { Avatar } from '../../../shared-components/V2';
import { Logo } from '../../../shared-components/V2/Logo';
import { IAppState } from '../../../store';
import { createUpcomingEventPost } from '../../../store/events/upcoming-event-create-post/actions';
import { getUpcomingEventPosts } from '../../../store/events/upcoming-event-posts/actions';
import { hideModal } from '../../../store/modal/actions';
import { getPoll } from '../../../store/poll';
import { getUserPosts } from '../../../store/user/posts/actions';
import { imageUrlPrefix } from '../../../utils';
import {
  useCurrentUser,
  useUserDetails,
} from '../../../utils/hooks';
import ViewPoll from '../../poll/view-poll';
import SharedEvent from '../components/SharedEvent';
import { PrivacyDropDown } from '../components/PrivacyOptions';
import PostContent from '../components/PostContent';
import classNames from 'classnames';
import ViewPollPost from 'src/components/poll/view-poll-post';
import { ArrowDownSvgIcon } from 'src/shared-components/V2/IconSvg';
// import ViewPollPost from 'src/components/poll/view-poll-post/index copy';

export const SharePost = (props: ISharePostProps) => {
  const { register, handleSubmit } = useForm<IEditPost>();
  const dispatch = useDispatch();
  const [isDisplayName, hasDisplayName] = useState(false);
  const [displayPrivacyStatusMenu, setDisplayPrivacyStatusMenu] =
    useState(false);
  const [privacyOfPost, setPrivacyOfPost] = useState<string>("Connections");
  const [iconPrivacyOfPost, setIconPrivacyOfPost] = useState<string>(connectionIcon);
  const [name, setName] = useState<string>(props.userDisplayName);

  const [postedAsAvatar, setPostedAsAvatar] = useState("");
  const [postedAsDisplayName, setPostedAsDisplayName] = useState("");
  const [postedAsProfileUrl, setPostedAsProfileUrl] = useState("");
  const [postedAsSubTitle, setPostedAsSubtitle] = useState("");

  const { poll } = useSelector((state: IAppState) => state);

  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [privacyOptionsFor, setPrivacyOptionsFor] = useState<
    "User" | "Company"
  >("User");

  const videoBackgroundUrl = { backgroundImage: `url(${process.env.REACT_APP_DEFAULT_VIDEO_HLS_URL}/${props?.post?.thumbnail})` };
  const mediaSource = `${imageUrlPrefix}/${props.originalPostSource}`;

  const handleNameOfPost = (_name: string, isCompany: boolean) => {
    setName(_name);
    hasDisplayName(!isDisplayName);
    if (isCompany) {
      setPrivacyOfPost("Followers");
      setIconPrivacyOfPost(connectionIcon);
    }
    else {
      setPrivacyOfPost("Connections");
      setIconPrivacyOfPost(connectionIcon);
    }
  };

  const handlePrivacyOfPost = (privacy: string, icon: string) => {
    setPrivacyOfPost(privacy);
    setIconPrivacyOfPost(icon);
    setDisplayPrivacyStatusMenu(!displayPrivacyStatusMenu);
  };

  const setPostUser = (avatar: string, name: string, slug: string, jobTitle: string) => {
    setPostedAsAvatar(
      avatar
    );
    setPostedAsDisplayName(
      name
    );
    setPostedAsProfileUrl(slug);
    setPostedAsSubtitle(jobTitle);
  }

  useEffect(() => {
    // if (props.originalPostFormat === "poll") {
    //   dispatch(getPoll(props.originalPostSource));
    // }
    let avatar: string = "";
    let name: string = "";
    let slug: string = "";
    let jobTitle: string = "";
    if (props?.originalPostFormat === "shared_event") {


      if (props.sharePost) {
        avatar = `${imageUrlPrefix}/${props.sharePost?.
          postedAsUser?.avatar}`
        name = `${props.sharePost?.
          postedAsUser?.firstName} ${props.sharePost?.
            postedAsUser?.lastName}`;
        slug = props.sharePost?.creator?.slug
        jobTitle = props.sharePost?.postedAsUser?.jobTitle
        setPostUser(avatar, name, slug, jobTitle)
      }

      else {
        avatar = `${imageUrlPrefix}/${props.originalPostedAsUser.avatar}`
        name = `${props.originalPostedAsUser.firstName} ${props.originalPostedAsUser.lastName}`
        slug = props.originalPostedAsUser.slug
        jobTitle = props.originalPostedAsUser.jobTitle
        setPostUser(avatar, name, slug, jobTitle)
      }
    }

    else if (props.originalPostedAsUser) {
      avatar = `${imageUrlPrefix}/${props.originalPostedAsUser.avatar}`
      name = `${props.originalPostedAsUser.firstName} ${props.originalPostedAsUser.lastName}`
      slug = props.originalPostedAsUser.slug
      jobTitle = props.originalPostedAsUser.jobTitle
      setPostUser(avatar, name, slug, jobTitle)
    }

    if (props.originalPostedAsCompany) {

      avatar = `${imageUrlPrefix}/${props?.originalPostedAsCompany?.logo}`
      name = `${props.originalPostedAsCompany?.name}`
      slug = props.originalPostedAsCompany?.slug
      jobTitle = props.originalPostedAsCompany?.industry?.name
      setPostUser(avatar, name, slug, jobTitle)
    }
  }, [props]);

  const PostedBy = () => {
    const user = useCurrentUser();
    const userDetails = useUserDetails(user);

    type PostedByOptionTypes = {
      id: string;
      name: string;
      isCompany: boolean;
    };

    const dropdownOptions: PostedByOptionTypes[] = [];
    dropdownOptions.push({
      id: user.id,
      name: props.userDisplayName,
      isCompany: false,
    });

    userDetails.companyAdmin?.map((company) =>
      dropdownOptions.push({
        id: company.id,
        name: company.name,
        isCompany: true,
      })
    );

    return (
      <div className="bg-white dropdown-box w-auto px-[5px] py-[5px] min-w-[210px] absolute z-20">
        <ul className="text-base">
          {dropdownOptions.map((option, index) => (
            <li
              className="sm:flex items-center justify-left text-[12px] leading-[18px] text-[#172746] hover:bg-[#EDF2F5] sm:h-[30px] sm:py-0 py-[3px] px-2 rounded-[5px] cursor-pointer font-normal tracking-wide line-clamp-1"
              key={option.id}
              onClick={() => {
                handleNameOfPost(option.name, option.isCompany);
                if (option.isCompany) {
                  setSelectedCompanyId(option.id);
                  setPrivacyOptionsFor("Company");
                } else {
                  setPrivacyOptionsFor("User");
                }
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const getSourceId = () => {
    if (props.originalPostFormat === "shared_event") {
      if (props?.sharePost) return props?.sharePost?.id;
      return props.postId;
    }
    else if (props.originalPostFormat === "event" &&
      props.post.type === "recommended") {
      return props.post?.event?.id;
    }
    if (props?.originalPostFormat === "poll" || props?.post?.sharePost?.format === "poll") {
      return props?.post?.sharePost?.id || props?.post?.poll?.id;  //if shared poll then shared post poll id
    }

    return props.postId;
  }

  const onSubmit = (data: IEditPost) => {
    let sourceValue: string = "";
    let postFormat: string = "";
    let wallId: string = "";
    let location: string = "";
    let companyId: string | null = null;
    let type: string | null = null;
    let dataToSubmit: any = null;

    if (
      props.originalPostFormat === "image" ||
      props.originalPostFormat === "document" ||
      props.originalPostFormat === "text" ||
      props.originalPostFormat === "post" ||
      props.originalPostFormat === "video"
    )
      postFormat = "post";

    if (props?.originalPostFormat === "poll" || props?.post?.sharePost?.format === "poll") postFormat = "poll";
    if (
      props.originalPostFormat === "shared_event" ||
      (props?.post?.type === "recommended" &&
        props.originalPostFormat == "event")
    )
      postFormat = "shared_event";

    if (selectedCompanyId !== "") {
      location = "company";
      wallId = selectedCompanyId;
      companyId = selectedCompanyId;
      type = "company";

      dataToSubmit = {
        ...data,
        companyId,
        format: postFormat,
        location,
        privacy: privacyOfPost.toLowerCase(),
        source: props.postId,
        userMentioned: "",
        wallId,
        type,
      };
    } else {
      location = "profile";
      wallId = props.userId;

      dataToSubmit = {
        ...data,
        format: postFormat,
        location,
        privacy: privacyOfPost.toLowerCase() === 'only me' ? 'only-me' : privacyOfPost.toLowerCase(),
        source: getSourceId(),
        userMentioned: "",
        wallId,
      };
    }

    dispatch(createUpcomingEventPost(dataToSubmit));
    // dispatch(getUpcomingEventPosts(props.userId));
    toast.success(POST_ACTIONS.SHARE_POST_SUCCESS);
    props.onAfterShare && props.onAfterShare();
    dispatch(hideModal());
    // dispatch(getUserPosts());
  };

  return (
    <div className="w-full">
      <div className="text-[#0B1221] font-medium text-[18px] leading-[26px] border-b-1 border-[#CDDCF2] pb-[9px] mx-[18px]">Share post</div>
      {/* <div className="border-b-[0.5px] border-[#C5C5C5]"></div> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col overflow-y-auto">
          <div className="flex flex-row items-center py-2.5 pb-0 px-[20px]">
            <div className="mr-[12.5px] !w-[40px] !h-[40px]">
              <Avatar
                src={props.avatarUrl}
                size="MEDIUM"
                className="!w-[40px] !h-[40px] !rounded-[15px]"
              />
            </div>

            <div className="mr-2 relative h-[31px]">
              <button
                type="button"
                className={classNames("menu focus:outline-none focus:shadow-solid flex flex-row border-[#7283A4] border-1 h-[31px] sm:px-[20px] px-3 hover:bg-[#DFE5EB] justify-between items-center sm:text-center text-left sm:min-w-fit min-w-[145px] sm:max-w-fit max-w-[145px] rounded-[5px] text-[12px] leading-[18px] text-[#7283A4] font-semibold", {
                  'bg-[#DFE5EB]': isDisplayName,
                  'bg-white': !isDisplayName,
                })}
                onClick={() => hasDisplayName(!isDisplayName)}
              >
                <span className="max-w-[185px] line-clamp-1 leading-[31px]">{name}</span>
                {/* <img src={arrowDownIcon} className="ml-2" alt="" /> */}
                <ArrowDownSvgIcon className="ml-[10px]" fillColor="#7283A4" />
              </button>
              {isDisplayName && <PostedBy />}
            </div>
            <div className="mt-[2]">
              <PrivacyDropDown
                displayPrivacyStatusMenu={displayPrivacyStatusMenu}
                setDisplayPrivacyStatusMenu={setDisplayPrivacyStatusMenu}
                iconPrivacyOfPost={iconPrivacyOfPost}
                privacyOfPost={privacyOfPost}
                privacyOptionsFor={privacyOptionsFor}
                handlePrivacyOfPost={handlePrivacyOfPost}
              />
            </div>
          </div>
          <div className="mt-[15px] px-[20px]">
            <textarea
              className="w-full text-[15px] leading-[17px] font-light outline-none placeholder:text-[#020202]"
              placeholder="Add a title/description to your post"
              {...register("content")}
            />
          </div>
          <div className="mt-2 block px-[20px]">
            <div
              // className="border-solid border-[0.5px] border-[#CDDCF2] rounded-10"

              className={classNames({
                'border-none': (props.originalPostFormat === "poll" || props?.post?.sharePost?.format === "poll"),
                'border-solid border-[0.5px] border-[#CDDCF2] rounded-10]': !(props.originalPostFormat === "poll" || props?.post?.sharePost?.format === "poll"),
              })}

            >
              <div
                className={classNames("flex flex-col")}
              >
                {(props.originalPostFormat === "poll" || props?.post?.sharePost?.format === "poll") ? null :
                  <>
                    <div className="flex items-center px-[17px] pt-[17px] mb-[8px]">
                      <div>
                        <Link to={postedAsProfileUrl}>
                          {props.originalPostedAsUser && (
                            <Avatar
                              src={postedAsAvatar}
                              size="MEDIUM"
                            />
                          )}
                          {props.originalPostedAsCompany && (
                            <Logo
                              src={postedAsAvatar}
                              className="w-[49px] h-[49px] object-contain"
                            />
                          )}
                        </Link>
                      </div>
                      <div className="flex flex-col ml-2">
                        <div className="capitalize leading-[16px]">
                          <span className="text-[13px] font-[600] text-[#172746] leading-[16px] flex hover:underline">{postedAsDisplayName}</span>
                        </div>
                        <div className="flex leading-[17px]">
                          <span className="text-[11px] font-[400] text-[#172746] leading-[17px]">{postedAsSubTitle}</span>
                        </div>
                        <div className="flex leading-[17px]">
                          <span className="text-[11px] font-[300] text-[#3C3C3C] leading-[14px]">
                            {
                              props.dateAndTime &&
                              format(
                                parseISO(props.dateAndTime),
                                "dd MMM yyyy 'at' hh:mm aaaa"
                              )
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="text-h3 font-light text-blue-4">
                        <PostContent content={props.originalPostFormat === "shared_event" ? props?.sharePost?.content : props?.originalPostContent} />
                      </div>
                    </div>

                  </>
                }
                {props.originalPostFormat === "image" && (
                  <img
                    className="w-full pt-2 max-h-[24rem] object-contain rounded-10"
                    src={mediaSource}
                    alt=""
                  />
                )}
                {props.originalPostFormat === "video" && (
                  <div className='w-full h-full relative overflow-hidden rounded-b-lg'>
                    <div
                      style={videoBackgroundUrl}
                      className='w-full h-full absolute left-0 top-0 z-0 bg-center scale-125 blur-lg'
                    />
                    <video
                      className="w-full pt-0 max-h-[24rem] object-contain relative"
                      controls>
                      <source
                        src={mediaSource}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                )}
                {props.originalPostFormat === "document" && (
                  <iframe
                    title={props.originalPostContent}
                    className="bg-cover w-full min-h-506 h-full"
                    src={`https://docs.google.com/viewerng/viewer?url=${props.originalPostSource}&embedded=true`}
                  />
                )}
                {props.originalPostFormat === "shared_event" && !!props.shareEvent && (
                  <SharedEvent
                    slug={props.shareEvent?.slug!}
                    cover={props.shareEvent?.cover!}
                    logo={props.shareEvent?.company?.logo!}
                    name={props.shareEvent?.name!}
                    companyName={props.shareEvent?.company?.name!}
                    startTime={props.shareEvent?.startTime!}
                    endTime={props.shareEvent?.endTime!}
                    eventFormat={props.shareEvent?.format!}
                    eventRegions={props.shareEvent?.eventRegions}
                    isLivePage={props.isLivePage}
                  />
                )}
                {props.originalPostFormat === "event" &&
                  props?.post?.type === "recommended" && (
                    <SharedEvent
                      slug={props.shareEvent?.slug!}
                      cover={props.shareEvent?.cover!}
                      logo={props.shareEvent?.company?.logo!}
                      name={props.shareEvent?.name!}
                      companyName={props.shareEvent?.company?.name!}
                      startTime={props.shareEvent?.startTime!}
                      endTime={props.shareEvent?.endTime!}
                      eventFormat={props.shareEvent?.format!}
                      eventRegions={props.shareEvent?.eventRegions}
                      isLivePage={props.isLivePage}
                    />
                  )}
                {props.originalPostFormat === "shared_event" && !!props.sharePost && (
                  <SharedEvent
                    slug={props.sharePost?.shareEvent?.slug!}
                    cover={props.sharePost?.shareEvent?.cover!}
                    logo={props.sharePost?.shareEvent?.company?.logo!}
                    name={props.sharePost?.shareEvent?.name!}
                    companyName={props.sharePost?.shareEvent?.company?.name!}
                    startTime={props.sharePost?.shareEvent?.startTime!}
                    endTime={props.sharePost?.shareEvent?.endTime!}
                    eventFormat={props.sharePost?.shareEvent?.format!}
                    eventRegions={props.sharePost?.shareEvent?.eventRegions}
                    isLivePage={props.isLivePage}
                  />
                )}
                {(props.originalPostFormat === "poll" || props?.post?.sharePost?.format === "poll") && (
                  <ViewPollPost
                    id={props?.post?.sharePost?.format === "poll" ? props?.post?.sharePost?.poll?.id : props?.post?.poll?.id}
                    question={props?.post?.sharePost?.format === "poll" ? props?.post?.sharePost?.poll?.question : props?.post?.poll?.question}
                    status={props?.post?.sharePost?.format === "poll" ? props?.post?.sharePost?.poll?.status : props?.post?.poll?.status}
                    expireOption={props?.post?.sharePost?.format === "poll" ? props?.post?.sharePost?.poll?.expireOption : props?.post?.poll?.expireOption}
                    expiredAt={props?.post?.sharePost?.format === "poll" ? props?.post?.sharePost?.poll?.expiredAt : props?.post?.poll?.expiredAt}
                    answers={props?.post?.sharePost?.format === "poll" ? props?.post?.sharePost?.poll?.answers : props?.post?.poll?.answers}
                    isInteractive={false}
                    location="sharePost"
                  />

                )}
              </div>
            </div>
          </div>
          <div className="text-right mt-2 px-[20px]">
            <div className="border-b-[0.5px] border-[#C5C5C5] mb-2.5"></div>
            <button
              className="bg-[#EDF2F5] text-[#203C6E] text-sm font-semibold w-full h-[30px] rounded-[5px] hover:bg-gray-2 tracking-wide"
              type="submit"
            >
              Share post
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
