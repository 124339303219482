import { useSelector } from 'react-redux';
import { LinkButton } from '../../../shared-components';
import { IAppState } from '../../../store';
import footerimage from '../../../assets/images/feature/footer-image@2x.jpg';

interface IFAQ {
  questionText: string;
  answerText: string;
}

const QandAItem = (prop: IFAQ) => (
  <div className="flex flex-col">
    <div className="text-sm4 text-blue-1 font-normal">{prop.questionText}</div>
    <div className="mt-4 text-sm4 font-extralight text-gray-1 w-11/12">{prop.answerText}</div>
  </div>
);

const FAQs = () => {
  const selectedPackage = useSelector((state: IAppState) => state.setSelectedPackage);

  return (
    <div
      className="w-full min-h-1630"
      style={{
        background: `url(${footerimage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: ''
      }}
    >
      <div className="flex flex-col items-center mt-14 pt-1" style={{ height: '1050px' }}>
        <div className="text-lg text-blue-4">Frequently Asked Questions</div>
        <div className="w-8/12 mx-auto mt-1">
          <div className="flex ml-5">
            <div className="mt-10 block">
              <div className="w-10/12">
                <QandAItem
                  questionText="What are my options if I’m planning more than one event?"
                  answerText={`You can run as many as you need as long as the combined
                                          duration of your events is accounted for when your purchase your package.`}
                />
              </div>

              <div className="w-11/12 mt-4">
                <QandAItem
                  questionText="How will you bill me?"
                  answerText="Depending on what option you choose we can accept payment via credit card
                                          or provide you with an invoice. Panelist payment terms are 7 days upon
                                          receipt of invoice. To discuss other options please contact a Panelist team member."
                />
              </div>

              <div className="w-11/12 mt-4">
                <QandAItem
                  questionText="Will my attendees or partners have to pay to attend my event?"
                  answerText="Panelist does not charge attendees, speakers, partners and sponsors of event.
                                            The only fee for running an event on panelist is the package you invest in
                                            as the host. You can choose to charge your attendees per ticket or as a
                                            subscription service when you create your event."
                />
              </div>
            </div>

            <div className="mt-10 block">
              <div className="w-full ">
                <QandAItem
                  questionText="Can I change my event package later?"
                  answerText="Yes! You can upgrade, downgrade your package anytime by going to your subscription plans in your settings."
                />
              </div>

              <div className="w-full mt-8">
                <QandAItem
                  questionText="What is your refund policy?"
                  answerText="Panelist does not offer refunds except in certain situations and jurisdictions, as noted in our refund policy."
                />
              </div>

              <div className="w-full mt-12 pt-1">
                <QandAItem
                  questionText="Can I cancel my subscription?"
                  answerText="Yes! You can cancel anytime from your settings page.  If you cancel,
                            you will lose your event manager features at the end of your billing cycle.
                            Be sure you use all your credits before the cancellation goes into effect"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-36 pt-1">
          {selectedPackage.selectedPackage === 'Professional' ? (
            <LinkButton
              text="▶︎ Create a free event"
              href="#"
              textColor="#fff"
              backgroundColor="#285cb2"
              classes="text-2xl"
              width="207px"
              height="29px"
            />
          ) : (
            selectedPackage.selectedPackage !== 'Custom' && (
              <LinkButton
                text={`▶︎ Build your package with "${selectedPackage.selectedPackage}"`}
                href="#"
                textColor="#fff"
                backgroundColor="#285cb2"
                classes="text-2xl"
                width="330px"
                height="29px"
              />
            )
          )}
          {selectedPackage.selectedPackage === 'Custom' && (
            <LinkButton
              text="Contact Sales"
              href="/contact-us"
              textColor="#fff"
              backgroundColor="#285cb2"
              classes="text-2xl"
              width="139px"
              height="29px"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
