import React, { FC } from "react";
import classNames from "classnames";
import profileBackground from "./../../../assets/images/avatar-default.svg";
import "./avatar.css";

export interface IAvatarProps {
  src: string | undefined;
  size?:
    | "XXSMALLCHAT"
    | "XXSMALL"
    | "XSMALL"
    | "SMALL"
    | "MEDIUM"
    | "LARGE"
    | "XLARGE"
    | "XXLARGE"
    | "PROFILEMATCH"
    | "EDITPROFILE";
  className?: string;
  alt?: string;
  status?: "ONLINE" | "OFFLINE";
}

// Use React Memo to avoid unnecessary rendering.
export const Avatar: FC<IAvatarProps> = React.memo(
  ({ src, size, className, alt, status }: IAvatarProps) => {
    let avatarSize;
    let avatarStatus;
    let avatarStatusPosition;

    switch (status) {
      case "ONLINE":
        avatarStatus = "border-2 border-white rounded-full bg-green z-2";
        break;
      case "OFFLINE":
        avatarStatus = "border-2 border-white rounded-full bg-gray-400 z-2";
        break;
      default:
        avatarStatus = "hidden";
    }

    switch (size) {
      case "XXSMALL":
        avatarSize = "xx-small";
        avatarStatusPosition = "bottom-0 right-0 h-2 w-2";
        break;
      case "XSMALL":
        avatarSize = "x-small";
        avatarStatusPosition = "bottom-0 right-0 h-2 w-2";
        break;
      case "SMALL":
        avatarSize = "small";
        avatarStatusPosition = "bottom-0 right-0 h-2 w-2";
        break;
      case "MEDIUM":
        avatarSize = "medium";
        avatarStatusPosition = "bottom-0 right-0 h-3 w-3 shadow-lg";
        break;
      case "LARGE":
        avatarSize = "large";
        avatarStatusPosition = "bottom-0 right-0 h-3 w-3 my-1";
        break;
      case "XLARGE":
        avatarSize = "x-large";
        avatarStatusPosition =
          "bottom-0 right-0 h-4 w-4 my-2.5 mx-2.5 shadow-lg";
        break;
      case "XXLARGE":
        avatarSize = "xx-large";
        avatarStatusPosition =
          "bottom-0 right-0 h-5 w-5 my-2.5 mx-2.5 shadow-lg";
        break;
      case "PROFILEMATCH":
        avatarSize = "profile-match";
        avatarStatusPosition =
          "bottom-0 right-0 h-5 w-5 my-2.5 mx-2.5 shadow-lg";
        break;
      case "EDITPROFILE":
        avatarSize = "edit-profile-avatar";
        avatarStatusPosition =
          "bottom-0 right-0 h-5 w-5 my-2.5 mx-2.5 shadow-lg";
        break;
      case "XXSMALLCHAT":
        avatarSize = "xx-small";
        avatarStatusPosition = "bottom-0 right-0 h-2 w-2";
        break;
      default:
        avatarSize = "large";
    }

    const onError = (event: any, src: string) => {
      event.target.src = src;
      event.onerror = null;
    };

    return (
      <div className="avatar">
        <div
          className={classNames({
            [`${avatarSize}`]: avatarSize,
            relative: status !== undefined,
          })}
        >
          <img
            className={classNames("inline-block object-cover", {
              [`${className}`]: className,
              [`${avatarSize}`]: avatarSize,
            })}
            src={src}
            alt={alt}
            onError={(evt) => onError(evt, profileBackground)}
            aria-label="avata-target"
          />
          {status && (
            <div
              className={classNames("absolute", {
                [`${avatarStatus}`]: avatarStatus,
                [`${avatarStatusPosition}`]: avatarStatusPosition,
              })}
            ></div>
          )}
        </div>
      </div>
    );
  }
);
