import { Reducer } from 'redux';

import { IInvitationState } from '../../../models/event/InvitationState';
import { InviteAttendeesActions } from './actions';
import { InviteAttendeeActionTypes } from './types';

const initialState: IInvitationState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const InviteEventAttendeesReducer: Reducer<IInvitationState, InviteAttendeesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case InviteAttendeeActionTypes.INVITATION_IN_PROGRESS: {
      return {
        ...state,
        loading: action.inProgress
      };
    }

    case InviteAttendeeActionTypes.INVITATION_SENT: {
      return {
        ...state,
        value: action.result
      };
    }

    case InviteAttendeeActionTypes.INVITATION_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    }

    default:
      return state;
  }
};
