import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { CardProfile } from '../../../shared-components';

import { PartnerItem } from '../../../components/event-partners';
import { IEventSession } from '../../../models/event/event-session';
import { IUpcomingEventAgendaResult } from '../../../models/event/UpcomingEventAgendaResult';
import { EventsService } from '../../../services/events';
import { ParseContent } from '../../../utils/parse-html';

/**
 * renders a slide of session data that can be used as an
 * interstital image during video playback. Renders a test pattern
 * image if no data is found or if the API call fails.
 */

interface ISessionProps {
  session: IEventSession;
}

interface IEventInterstitialProps {
  eventId: string;
  sessionId: string;
  events: EventsService;
}

function imageUrl(i: string) {
  return `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${i}`;
}
const TestPattern = () => (
  <span
    className="full-screen"
    role="img"
    aria-label="test pattern"
    style={{ backgroundImage: 'url("/test-pattern.jpg")' }}
  />
);

// eslint-disable-next-line consistent-return
function getSession(event: IUpcomingEventAgendaResult, sessionId: string) {
  // eslint-disable-next-line no-restricted-syntax
  for (const room of event.data) {
    // eslint-disable-next-line no-restricted-syntax
    for (const session of room.sessions) {
      if (session.id === sessionId) return session;
    }
  }
}

export const Session = ({ session }: ISessionProps) => (
  <div>
    <h1 className="text-h1">{session.title}</h1>
    <ParseContent content={session.summary} />
    <ul>
      {session.eventSessionSpeakers.map(speaker => {
        const s = speaker.eventSpeaker;
        const speakerName = `${s.firstName} ${s.lastName}`;
        return (
          <li>
            <CardProfile
              location={s.user.location}
              avatarUrl={imageUrl(s.photo)}
              imageUrl={imageUrl(s.user.cover!)}
              name={speakerName}
              occupation={s.jobTitle}
              key={s.id}
              className=""
              detailsHref={`/wall/${s.user.slug}`}
            />
          </li>
        );
      })}
    </ul>
    <ul>
      {session.eventSessionSponsors.map(sponsor => (
        <PartnerItem
          imageUrl={sponsor.logo!}
          companyName={sponsor.company.name}
          key={sponsor.id}
        />
      ))}
    </ul>
  </div>
);

export const EventInterstitial = ({ events, eventId, sessionId }: IEventInterstitialProps) => {
  const [session, setSession] = useState<IEventSession>();
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const event = await events.getUpcomingEventInterstitials(eventId);
        const session = event && getSession(event, sessionId);
        if (session) {
          setSession(session);
        } else {
          setNotFound(true);
        }
      } catch (e) {
        setNotFound(true);
      }
    })();
  }, [events, eventId, sessionId]);

  if (notFound) return <TestPattern />;

  if (!session) return null;

  return <Session session={session} />;
};

const Default = () => {
  const { eventId, sessionId } = useParams();
  return (
    <EventInterstitial events={new EventsService()} eventId={eventId!} sessionId={sessionId!} />
  );
};
export default Default;
