import { Reducer } from 'redux';

import { ICompanyProfileState } from '../../../models/company/CompanyProfileState';
import { CompanyProfilePreviewActions } from './actions';
import { CompanyProfilePreviewActionTypes } from './types';

const initialState: ICompanyProfileState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const CompanyProfilePreviewReducer: Reducer<
  ICompanyProfileState,
  CompanyProfilePreviewActions
> = (state = initialState, action) => {
  switch (action.type) {
    case CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    case CompanyProfilePreviewActionTypes.FOLLOW_COMPANY: {
      return {
        ...state,
        isFollowing: 1
      };
    }

    case CompanyProfilePreviewActionTypes.UNFOLLOW_COMPANY: {
      return {
        ...state,
        isFollowing: 0
      };
    }

    case CompanyProfilePreviewActionTypes.COMPANY_PROFILE_PREVIEW_RESET: {
      return initialState;
    }

    default:
      return state;
  }
};
