import {
  Box, Stack, Typography
} from '@mui/material';
import { SetStateAction, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import classNames from 'classnames';
import CompanyTabs from '../../company-profile/Products/add-product-icon/CompanyTabs';
import { ImagePreviewTab } from '../../company-profile/company-enum';
import SwiperNavButtons from '../events/SwiperNavButton';

interface Props {
  product: any
}

const ProdcutScreenshots = ({ product }:Props) => {
  const { desktopUrls, tabletUrls, phoneUrls } = product || {};
  const [currentIndex, setCurrentIndex] = useState(0);

  const [activeTab, setActiveTab] = useState<string>(ImagePreviewTab.DESKTOP);
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };
  const handleSlideChange = (swiper: { activeIndex: SetStateAction<number>; }) => {
    setCurrentIndex(swiper.activeIndex);
  };

  const renderImagePreviews = (images: string[], formKey: string) => (
    images.map((image, index) => (
      <SwiperSlide>
        <Box key={index} sx={{ position: 'relative' }}>
          <img
            src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${image}`}
            alt={`preview-${index}`}
            className={classNames(' rounded-[5px] object-cover', {
              'w-[105px] h-[175px]': formKey === 'phoneUrls',
              'w-[100px] h-[120px]': formKey === 'tabletUrls',
              'w-[203px] h-[139px]': formKey === 'desktopUrls',
            })}
          />
        </Box>
      </SwiperSlide>
    ))
  );
  return (
    <Stack>
      <Stack sx={{
        flexDirection: 'row', alignItems: 'baseline', maxWidth: '370px', zIndex: 50
      }}
      >
        <Typography sx={{
          color: '#000000', fontSize: '20px', fontWeight: 500, lineHeight: '28px', fontFamily: 'Poppins-500', mr: '28px'
        }}
        >
          Screenshots
        </Typography>
        <CompanyTabs showDesktopTab={desktopUrls?.length} showPhoneTab={phoneUrls?.length} showTabletTab={tabletUrls?.length} isScreenshots handleTabCahnge={handleTabChange} activeTab={activeTab} />
      </Stack>
      <Stack>
        <Swiper
          modules={[Pagination, Navigation]}
          slidesPerView="auto"
          spaceBetween={10}
          pagination={false}
          navigation={{
            nextEl: '.custom-next',
            prevEl: '.custom-prev',
          }}
          className="myPostSwiper screenshotSlider w-[100%]"
          onSlideChange={handleSlideChange}
        >
          {activeTab === ImagePreviewTab.DESKTOP && renderImagePreviews(desktopUrls || [], 'desktopUrls')}
          {activeTab === ImagePreviewTab.PHONE && renderImagePreviews(phoneUrls || [], 'phoneUrls')}
          {activeTab === ImagePreviewTab.TABLET && renderImagePreviews(tabletUrls || [], 'tabletUrls')}
          {activeTab === ImagePreviewTab.DESKTOP && desktopUrls?.length > 5 && (
          <SwiperNavButtons currentIndex={currentIndex} lastIndex={5} isScreenshots />
          )}
          {activeTab === ImagePreviewTab.PHONE && phoneUrls?.length > 9 && (
          <SwiperNavButtons currentIndex={currentIndex} lastIndex={10} isScreenshots />
          )}
          {activeTab === ImagePreviewTab.TABLET && tabletUrls?.length > 9 && (
          <SwiperNavButtons currentIndex={currentIndex} lastIndex={10} isScreenshots />
          )}
        </Swiper>
      </Stack>
    </Stack>
  );
};

export default ProdcutScreenshots;
