/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
// AccordionHeader.tsx
import React from 'react';
import classNames from 'classnames';
import PlusSvgIcon from './plus-svg-icon';
import UserDetails from './user-details';
import CompanyDetails from './company-details';
import MinusSvgIcon from './minus-svg-icon';
import userDefault from '../../images/avatar-default.svg';

interface AccordionHeaderProps {
  isOpen: boolean;
  toggleAccordion: () => void;
  UserPicture: string;
  user: {
    name: string;
    position: string;
    company: string;
    isSpeaker: boolean;
    slug: string;
  };
  sessionDetails: {
    time: string;
    title: string;
    description: string;
  };
  session?: any;
  eventSponsors?: any;
  speaker?: any;
  isByDrivers?: boolean;
  isBreakoutSession5?: boolean;
  isBreakoutSession?: boolean;
}

const AccordionHeader: React.FC<AccordionHeaderProps> = ({
  isOpen,
  toggleAccordion,
  UserPicture,
  user,
  sessionDetails,
  session,
  eventSponsors,
  speaker,
  isByDrivers,
  isBreakoutSession5,
  isBreakoutSession,
}) => {
  const sponsorDetails = {
    companyPage: `/wall/company/${eventSponsors[0]?.company?.slug}`,
    name: eventSponsors[0]?.company?.name,
    industry: eventSponsors[0]?.company?.industry?.name,
    logo: `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${
      eventSponsors[0]?.company?.logo || eventSponsors[0]?.logo}`,
    dominantColor: eventSponsors[0]?.logo ? eventSponsors[0]?.logoDominantColor : eventSponsors[0]?.company?.logoDominantColor
  };

  const showUserDetails = (isByDrivers && (UserPicture || (user?.name && session?.eventSpeakers[0]?.firstName) || sponsorDetails?.name)) || (!isByDrivers);

  return (
    <button
      onClick={toggleAccordion}
      className={classNames(
        'w-full h-full transition-all m-0 pt-[22px] pb-[22px] px-[22px] pr-[30px] flex justify-between items-start focus:outline-none bg-[#14161A] rounded-[10px]',
        {
          '!pb-[0px] transition-all !rounded-t-[10px] !rounded-b-[0px]': isOpen,
          '!mt-[10px]': isByDrivers,
          '!px-[22px] py-[18px] h-[137px]': isBreakoutSession5,
          '!h-full': isOpen
        }
      )}
    >
      <div className={classNames('flex lg:flex-row flex-col gap-y-[20px] justify-between items-start', {
        'lg:gap-x-[15.0001px]': !isBreakoutSession && user?.name! && !isByDrivers,
        'lg:gap-x-[15px]': !isBreakoutSession && UserPicture && !isByDrivers,
        'lg:flex-col': isBreakoutSession && isOpen && !isByDrivers && user?.name,
        '!gap-x-[15px] lg:gap-x-[15px]': isByDrivers,
      })}
      >
        {showUserDetails ? (

          <div
            className={classNames('', {
              'lg:gap-x-[29px]': isBreakoutSession,
              'max-w-[285px]': user?.name! && !isBreakoutSession && isOpen,
              hidden: isBreakoutSession5,
              '!w-[90px] !min-w-[90px]': !isOpen && isBreakoutSession && UserPicture,
              'w-[285px] min-w-[285px]': !isBreakoutSession && UserPicture && user?.name,
            })}
          >
            {user?.name && session?.eventSpeakers[0]?.firstName ? (
              <UserDetails
                speaker={speaker}
                userDefault={userDefault}
                UserPicture={UserPicture}
                user={user}
                totalSpeakers={session?.eventSpeakers?.length - 1}
                isBreakoutSession={isBreakoutSession}
                isOpen={isOpen}
              />
            ) : sponsorDetails?.name ? (
              <CompanyDetails
                isOpen={isOpen}
                isBreakoutSession={isBreakoutSession}
                hideWebsiteButton
                company={sponsorDetails}
              />
            ) : UserPicture
              ? (
                <div className={classNames(
                  'w-[70px] min-w-[70px] h-[85px] max-h-[85px] bg-[#333333] rounded-5 overflow-hidden',
                )}
                >
                  <img
                    className="w-full h-full object-cover"
                    src={UserPicture}
                    alt={user.name}
                  />
                </div>
              ) : null}
          </div>
        ) : null}
        <div className={classNames('flex flex-col', {
          'ml-[15px]': isBreakoutSession && isOpen && !user?.name && !isByDrivers && UserPicture,
        })}
        >
          <div className="text-white md:leading-[16px] leading-[19px] md:text-[15px] text-[14px] helvetica-neue-55-roman text-left capitalize mb-[2px]">
            {session?.type}
          </div>
          <div className={classNames(
            'text-white md:leading-[23px] leading-[19px] md:text-[15px] text-[14px] helvetica-neue-55-roman text-left mt-[0px] mb-1',
            {
              // 'mb-[6px]': isBreakoutSession5
            }
          )}
          >
            {sessionDetails.time}
          </div>
          <div
            className={classNames(
              'text-white md:leading-[26px] leading-[20px] md:text-[20px] text-[16px] helvetica-neue-65-medium font-medium text-left',
              {
                'line-clamp-1': !isOpen,
                'line-clamp-2': isBreakoutSession5
              }
            )}
          >
            {sessionDetails?.title}
          </div>
        </div>
      </div>
      {isOpen ? (
        <div className="min-w-[18px] min-h-[18px]">
          <MinusSvgIcon />
        </div>
      ) : (
        <div className="min-w-[18px] min-h-[18px]">
          <PlusSvgIcon />
        </div>
      ) }
    </button>
  );
};

export default AccordionHeader;
