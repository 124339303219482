import { Suspense } from "react";
import { SplashScreen } from "../components/loading-screen";
import { Outlet } from "react-router-dom";
// import { LiveEvent } from "../pages/event/live";
import { LiveEvent } from "src/mui/pages/event/live";
import { WebsiteView } from "../pages/event/website";
import {
  WebsiteEventPageNew,
  WebsiteEventPageSpectre,
  RegistrationFormTwo,
  VipRegistrationForm,
  EventRegistrationForm,
  UserLoginOrJoinForm,
  UserLoginForm,
  UserRegistrationForm,
  TicketForm,
  EventFinalMessage,
} from "../../screens/event";
import { RequireAuth } from "src/components/auth";

export const mainRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: "/:slug/live",
        element: <RequireAuth />,
        children: [
          {
            path: "",
            element: <LiveEvent />,
          },
        ],
      },

      { path: "/:slug", element: <WebsiteView /> },

      {
        path: "/:slug/new",
        element: <WebsiteEventPageNew />,
      },

      {
        path: "/:slug/spectre/reg",
        element: <RegistrationFormTwo />,
      },

      {
        path: "/:slug/spectre/registration",
        element: <EventRegistrationForm />,
      },

      {
        path: "/:slug/spectre/loginorjoin",
        element: <UserLoginOrJoinForm />,
      },

      {
        path: "/:slug/spectre/login",
        element: <UserLoginForm />,
      },

      {
        path: "/:slug/spectre/signup",
        element: <UserRegistrationForm />,
      },

      {
        path: "/:slug/spectre/select-plan",
        element: <TicketForm />,
      },

      {
        path: "/:slug/spectre/successfully-registered",
        element: <EventFinalMessage />,
      },
      // <Route path="/old/event/:slug/live" element={<LiveEvent />} />
    ],
  },
];
