import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../shared-components/V2';
import { IAppState } from '../../../store';
import { resendEmailVerification, setEmailVerification } from '../../../store/auth';
import NavigationBar from './navigation-bar';
import Searchbox from './searchbox';
import SearchDropDown from './searchDropDown';
import classNames from 'classnames';
import { useLocation, useParams } from 'react-router-dom';
import { memo, useState } from 'react';
import CreateMenu from './create-menu';
import UserMenu from './user-menu';

export const UserNavbar = () => {
  const { currentUser: {isEmailVerified} = {} , hideEmailVerification } = useSelector((state: IAppState) => state?.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const { param } = useParams<{ param: string }>();
  const locationPathArr = location?.pathname?.match(/\/(\w+)/ig);
  const [isSuggestion, setIsSuggestion] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [isSearchDropDown, setIsSearchDropDown] = useState(false);
  const [recentSearchItems, setRecentSearchItems] = useState<any[]>([]);

  return (
  <>
    {!isEmailVerified && !hideEmailVerification &&
    <div className='relative bg-red-200 h-12 flex justify-center items-center text-red text-sm font-light z-10'>
      <span>
        Need to confirm your email address? Check your account to verify the email address or
      </span>
      <span 
        className='hover:underline font-medium underline cursor-pointer px-1'
        onClick={()=>{ dispatch(resendEmailVerification() )}}
      >
        request a new confirmation link.
      </span>
      <Button text='Dismiss' onClick={()=>{dispatch(setEmailVerification(true))}} />
    </div>}
    <nav  
      className={classNames('bg-white shadow-md sticky top-0 z-50 transition-all', {
        '!shadow-none': (!isEmailVerified && !hideEmailVerification)
        || (param === 'past-events')
        || ((locationPathArr?.length === 1 && locationPathArr?.[0]) === '/events')
        || ((locationPathArr?.length === 2 && locationPathArr?.[1]) === '/upcoming')
        || (location?.pathname === '/events/my-hosted-events')
      })}
    >
      <div className="container max-w-[100%] mx-auto md:px-[20px] px-3 w-[1258px] xl:px-0">
        <div className="flex flex-wrap space-between justify-center">
          {/* <!-- Website Logo --> */}
          <div className="lg:flex flex justify-between items-center w-full md:h-[54px] mx-auto 2xs:h-auto 2xs:block 2xs:pb-1">
            <div className="md:w-[287px] w-[240px] lg:mx-0 md:mt-0 md:mb-0 mt-1 mb-1">
              <Searchbox setIsSuggestion={setIsSuggestion} setSearch={setSearch} setRecentSearchItems={setRecentSearchItems} recentSearchItems={recentSearchItems} setIsSearchDropDown={setIsSearchDropDown} />
              <SearchDropDown setIsSuggestion={setIsSuggestion} isSuggestion={isSuggestion} search={search!} setRecentSearchItems={setRecentSearchItems} isSearchDropDown={isSearchDropDown} />
            </div>
            <div className="flex md:hidden ml-3">
              <div className="md:px-2 mr-1.5 md:mx-1 flex md:hidden items-center">
                <UserMenu />
              </div>
              <div className="px-2 mx-1 mr-0 pr-0 flex md:hidden items-center">
                <CreateMenu />
              </div>
            </div>
            {/* This div meant to take remaining space, not working in Tailwind: */}
            <div className="flex-grow-3 flex-shrink-3 w-full absolute"></div>
            <div className="flex-row fixed bottom-0 w-full -ml-3 md:-ml-0 bg-white md:relative md:bg-transparent z-20 px-[20px] md:px-0 pt-1 md:pt-0 b-nav-shadow md:w-full lg:w-[calc(100%-287px)] xl:w-auto md:pb-0 pb-2">
              <NavigationBar />
            </div>
          </div>
        </div>
      </div>
    </nav>
    
  </>
);
  }
export default memo(UserNavbar);
