import { LinkButton } from '../../shared-components';

interface IMenuItem {
  menuItemText: string;
  href: string;
  isSelected: boolean;
}
interface INavbarProps {
  menuItems: IMenuItem[];
  showCreateEventButton: boolean;
}

export const EventsNavbar = (props: INavbarProps) => (
  <nav className="bg-white px-8 pt-2 shadow-md border-solid border-1 z-40">
    <div className="-mb-px block md:flex justify-end lg:mr-28 lg:pr-1">
      {props.menuItems &&
        props.menuItems.length > 0 &&
        props.menuItems.map((menuItem, i) => (
          <a
            className={`no-underline ${
              menuItem.isSelected ? ' text-blue-1 font-semibold' : ' text-blue-2 '
            } ${
              menuItem.isSelected ? ' border-b-2 border-blue-1 ' : ' border-transparent '
            } text-navlink tracking-wide text-xs py-3 ${
              i < props.menuItems.length - 1 ? ' mr-8' : ''
            }`}
            href={menuItem.href}
            key={`sub-nav-${i}`}
          >
            {menuItem.menuItemText}
          </a>
        ))}
      {props.showCreateEventButton && (
        <div className="ml-4 mt-1">
          <LinkButton
            text="▶︎ Create an event"
            href="#"
            textColor="#172746"
            backgroundColor="transparent"
            width="166px"
            height="30px"
            classes="border-2 border-gray-600"
          />
        </div>
      )}
    </div>
  </nav>
);

export default EventsNavbar;
