import { useCallback, useEffect, useState } from 'react';
import { SpotlightEvents } from '../../../../components/spotlight';

import { IPostSpotLight, IPostSpotlightResult } from '../../../../models/post/IPostSpotlight';
import { CompanyService } from '../../../../services';

interface ICompanySpotlightTabProps {
    id: string;
    isCompanySpotlight?: boolean;
    company?: any;
}

export const CompanySpotlightTab = ({ id, isCompanySpotlight, company }: ICompanySpotlightTabProps) => {
  const [searchText, setSearchText] = useState('');
  const [specificSpotlight, setSpecificSpotlight] = useState<IPostSpotLight[]>([]);
  const [fetching, setFetching] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [nextPage, setNextPage] = useState(2);
  const hasMoreItems = nextPage <= totalPage;
  const companyService: CompanyService = new CompanyService();
  const PAGE_SIZE = 20;

  const getSpotlight = async () => {
    const result: IPostSpotlightResult = await companyService.getCompanySpotlight(id, PAGE_SIZE, 1, searchText);
    setTotalPage(result.totalPage);
    setSpecificSpotlight(result.data);
    setNextPage(2);
  };

  const getSearchText = (searchText: string) => {
    setSearchText(searchText);
  };

  useEffect(() => {
    getSpotlight();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const fetchItems = useCallback(
    async () => {
      if (fetching) {
        return;
      }
      setFetching(true);
      try {
        const result = await companyService.getCompanySpotlight(id, PAGE_SIZE, nextPage, searchText);
        setTotalPage(result.totalPage);
        if (result && nextPage <= result.totalPage) {
          setSpecificSpotlight(specificSpotlight.concat(result.data));
          setNextPage(nextPage + 1);
        } else {
          setFetching(true);
        }
      } finally {
        setFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [specificSpotlight, fetching, nextPage]
  );

  return (
    <SpotlightEvents
      isCompanySpotlight={isCompanySpotlight}
      company={company}
      getSpotlight={getSpotlight}
      fetchItems={fetchItems}
      specificSpotlight={specificSpotlight}
      hasMoreItems={hasMoreItems}
      getSearchText={getSearchText}
      isOwnSpotlight
    />
  );
};
