import { Dispatch } from '@reduxjs/toolkit';
import { expoSlice } from '.';
import { IAppState } from '..';
import { _expoWebSocket } from "../../utils/hooks";

// WebSocket Template
const sendWsMessage = (message: any) => {
  const webSocket = _expoWebSocket;
  return webSocket && webSocket.then((ws) => ws.send(JSON.stringify(message))).catch(console.error);
}

export const expoGuestCalling = (boothId: string) => async (dispatch: Dispatch) => {
  sendWsMessage({
    action: 'guest-calling',
    data: { boothId }
  });
  dispatch(expoSlice.actions.expoGuestCalling());
}

export const expoGuestCancel = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  const { chime } = getState();
  sendWsMessage({
    action: 'guest-cancel',
    data: { callId: chime.guestCall?.callId }
  });
  dispatch(expoSlice.actions.expoGuestCancel());

  // Reset Redux
  dispatch(expoSlice.actions.unsetExpoCall());
}

export const expoManagerAccept = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  const { chime } = getState();
  sendWsMessage({
    action: 'manager-accept',
    data: { callId: chime.expoCall?.callId }
  });
  dispatch(expoSlice.actions.expoManagerAccept());
}

export const expoManagerReject = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  const { chime } = getState();
  sendWsMessage({
    action: 'manager-reject',
    data: { callId: chime.expoCall?.callId }
  });
  dispatch(expoSlice.actions.expoManagerReject());

  // Assuming manager will never receive more than 1 call.
  // Reset Redux
  dispatch(expoSlice.actions.unsetExpoCall());
}

export const expoEndMeeting = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  const { chime } = getState();
  sendWsMessage({
    action: 'end-meeting',
    data: { callId: chime.expoCall?.callId || chime.guestCall?.callId }
  });
  dispatch(expoSlice.actions.expoEndMeeting());
  // Reset Redux
  dispatch(expoSlice.actions.unsetExpoCall());
}

export const expoReset = () => async (dispatch: Dispatch) => {
  dispatch(expoSlice.actions.unsetExpoCall());
}