import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IAppState } from 'src/store';
import { UserService } from 'src/services';
import ErrorMessage from 'src/components/error-message/errorMessage';
import { TextInput, Typography } from '../../shared-components';
import { Button } from '../../shared-components/V2';
import {
  reSendCode,
} from '../../store/user/forgotpassword/actions';
import { Navbar, Footer } from '../../components';
import { LOGIN_SIGNUP_RECOVERY } from '../../constants';

interface IResetPassword {
  email: string;
  code: string;
  newPassword: string;
  confirmNewPassword: string;
}
export const ResetPassword = () => {
  const { search } = useLocation();
  const resetPasswordResponse = useSelector((state: IAppState) => state.forgotPasswordEmail);
  const emailValue = new URLSearchParams(search).get('email');
  let email: string | null;
  if (emailValue) {
    const url = decodeURIComponent(emailValue);
    email = emailValue ? atob(url) : null;
    window.history.replaceState(null, document.title, window.location.pathname);
    if (email) sessionStorage.setItem('userName', email);
  }
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IResetPassword>();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const reSendResetPasswordCode = () => {
    dispatch(reSendCode(email));
  };

  const onSubmit = async (data: any) => {
    // dispatch(resetPassword(data, email));
    try {
      const resetPassPayload = { ...data };
      resetPassPayload.email = resetPasswordResponse.email;
      const userService: UserService = new UserService();
      await userService.resetPassword(resetPassPayload);
      navigate('/login');
    } catch (error) {
      toast.error(
        <ErrorMessage
          title="Invalid Code"
          message={LOGIN_SIGNUP_RECOVERY.INVALID_CODE}
          linkText=""
          link="/forgotPassword"
        />
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className="bg-[#001BF6] flex text-center h-[calc(100vh-112px)]">
        <div className="mx-auto">
          <div className="">
            <div className="flex w-11/12 lg:w-full flex-wrap mt-28 pt-1 mx-auto ">
              <div className="flex flex-col -mt-1">
                <div className="" />
                <div className="flex items-start justify-center lg:justify-start text-lg5 font-normal text-white">
                  Reset Password
                </div>

                <div className="text-center lg:text-left mt-1">
                  <div className="h2 font-normal text-white ">
                    We have sent a verification code to your email address
                  </div>
                  <div className="font-normal text-white text-sm5 mt-2">
                    Check your spam if you have not received it in the
                    {' '}
                    <br />
                    next few minutes
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className="flex justify-center lg:justify-start mt-5">
                      <div className="text-left ">
                        <div className="flex text-sm">
                          <div className="text-sm mr-2 text-white">Didn't receive?</div>
                          <button
                            type="button"
                            onClick={reSendResetPasswordCode}
                            className="text-white font-medium hover:underline"
                          >
                            Resend
                          </button>
                        </div>
                        <TextInput
                          className="w-150 h-8 text-sm1 font-extralight text-gray-12 px-2.5 rounded-10"
                          textinputsize="small"
                          type="text"
                          caption=""
                          placeholder="Enter code"
                          {...register('code', {
                            required: {
                              value: true,
                              message: LOGIN_SIGNUP_RECOVERY.CODE_ISREQUIRED,
                            },
                          })}
                          isBlueTheme
                        />
                      </div>
                    </div>
                    <div>
                      {errors.code && (
                        <Typography
                          variant="ValidationMessage"
                          text={errors.code?.message!.toString()}
                          classes="flex content-start email-validation-message"
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-center lg:justify-start mt-7">
                      <div className="text-left">
                        <TextInput
                          className="w-240 md:w-336 h-8 text-sm1 font-extralight text-gray-12 px-2.5 rounded-10"
                          textinputsize="medium"
                          type="password"
                          placeholder="Enter your password"
                          caption="New Password*"
                          {...register('newPassword', {
                            required: {
                              value: true,
                              message:
                                LOGIN_SIGNUP_RECOVERY.NEWPASSWORD_ISREQUIRED,
                            },
                          })}
                          isBlueTheme
                        />
                      </div>
                    </div>
                    <div>
                      {errors.newPassword && (
                        <Typography
                          variant="ValidationMessage"
                          text={errors.newPassword?.message!.toString()}
                          classes="flex password-validation-message"
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-center lg:justify-start mt-1 pt-1">
                      <div className="text-left">
                        <TextInput
                          className="w-240 md:w-336 h-8 text-sm1 font-extralight text-gray-12 px-2.5 rounded-10"
                          textinputsize="medium"
                          type="password"
                          placeholder="Enter your password"
                          caption="Confirm new password*"
                          {...register('confirmNewPassword', {
                            required: {
                              value: true,
                              message:
                                LOGIN_SIGNUP_RECOVERY.CONFIRMNEWPASSWORD_ISREQUIRED,
                            },
                            validate: value => value === getValues('newPassword')
                              || LOGIN_SIGNUP_RECOVERY.MATCHPASSWORD,
                          })}
                          isBlueTheme
                        />
                      </div>
                    </div>
                    <div>
                      {errors.confirmNewPassword && (
                        <Typography
                          variant="ValidationMessage"
                          text={errors.confirmNewPassword?.message!.toString()}
                          classes="flex password-validation-message"
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-center mt-5 lg:justify-end lg:w-9/12 lg:ml-2">
                      <Button
                        type="submit"
                        text="Submit"
                        size="x-small"
                        className="w-110 bg-white text-[#3855eb] text-sm font-semibold hover:bg-[#2000CD] hover:text-white"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className="bg-black py-3" />
    </>
  );
};

export default ResetPassword;
