import React, { useState } from 'react';

import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import classNames from 'classnames';

type ParseContentProps = { content?: string, className?: string, fullText?: boolean; };

export const ParseContent: React.FC<ParseContentProps> = ({ content = '', className, fullText }) => {
  const [showMoreOverview, setShowMoreOverview] = useState(false);
  const contentLength: Number = content?.length || 0;
  return (
    <div>
      <div
        className={classNames({
          [`${className}`]: className,
          'line-clamp-5': !fullText && contentLength > 500,
          'line-clamp-none': showMoreOverview,
        })}
      >
        {content && parse(DOMPurify.sanitize(content))}
      </div>
      {!showMoreOverview && !fullText && contentLength > 500 && (
        <div className="flex flex-row mt-2">
          <div
            className="text-[13px] text-[#3C3C3C] font-bold cursor-pointer"
            onClick={() => setShowMoreOverview(true)}
          >
            Show more
          </div>
        </div>
      )}
      {showMoreOverview && !fullText && (
        <div className="flex flex-row mt-2">
          <div
            className="text-[13px] text-[#3C3C3C] font-bold cursor-pointer"
            onClick={() => setShowMoreOverview(false)}
          >
            Show less
          </div>
        </div>
      )}
    </div>
  );
};
