/* eslint-disable max-len */
import React from 'react';
import {
  Box, Button, Stack, Typography
} from '@mui/material';
import {
  PanelistButtons, otherAttributesButtons, REQUIREMENTS_OTHER_ATTRIBUTES, REQUIREMENTS_PREFERRED_FIELDS, REQUIREMENTS_SAVE_FILE
} from '../company-enum';

export const Requirements = () => {
  const handleDownload = () => {
    const csvFile = `${window.location.origin}/sample-csv-file/Sample.csv`;
    const link = document.createElement('a');
    link.href = csvFile;
    link.download = 'Sample.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderRequirements = (count: string, title:string, details:string, showButton: boolean, buttonList?: string[]) => (
    <Stack sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row' }}>
      <Stack className="countText">
        {count}
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography className="requirment-title">
          {title}
        </Typography>
        <Typography className="requirment-desc">
          {details}
          {' '}
          {!showButton && (
            <Button
              onClick={handleDownload}
              variant="text"
              className="requirment-download-btn"
            >
              {REQUIREMENTS_SAVE_FILE.LINK}
            </Button>
          )}
        </Typography>

        {showButton ? (
          <Box sx={{
            backgroundColor: '#e7e7e7', borderRadius: '5px', padding: '14px', mt: '15px'
          }}
          >
            {buttonList && buttonList.map((item: string) => (
              <Button
                // onClick={() => handleButtonClick(item.name)}
                variant="contained"
                sx={{
                  borderRadius: '5px',
                  backgroundColor: '#ffffff',
                  mr: '10px',
                  mb: '10px',
                  '&:hover': {
                    backgroundColor: '#0031FF',
                    '& .MuiTypography-root': {
                      color: '#ffffff',
                    },
                  },
                }}
              >
                <Typography
                  variant="body1"
                  className="requirment-btn"
                >
                  {item}
                </Typography>
              </Button>
            ))}
          </Box>
        ) : null}

      </Stack>
    </Stack>
  );
  return (
    <>
      <Stack rowGap="25px">
        {renderRequirements(REQUIREMENTS_SAVE_FILE.COUNT, REQUIREMENTS_SAVE_FILE.TITLE, REQUIREMENTS_SAVE_FILE.DESCRIPTION, false)}
        {renderRequirements(REQUIREMENTS_PREFERRED_FIELDS.COUNT, REQUIREMENTS_PREFERRED_FIELDS.TITLE, REQUIREMENTS_PREFERRED_FIELDS.DESCRIPTION, true, PanelistButtons)}
        {renderRequirements(REQUIREMENTS_OTHER_ATTRIBUTES.COUNT, REQUIREMENTS_OTHER_ATTRIBUTES.TITLE, REQUIREMENTS_OTHER_ATTRIBUTES.DESCRIPTION, true, otherAttributesButtons)}
      </Stack>
      <Stack sx={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '10px',
        marginLeft: '40px',
        marginTop: '10px'
      }}
      >
        <Typography
          sx={{
            marginRight: '5px'
          }}
          variant="body1"
          className="requirment-desc"
        >
          * Required
        </Typography>
        <Typography
          variant="body1"
          className="requirment-desc"
        >
          ** Recommended
        </Typography>
      </Stack>
    </>
  );
};

export default Requirements;
