import createeventprofessional2 from '../../../assets/images/feature/professional@2x.png';
import createeventteam from '../../../assets/images/feature/team@2x.png';
import createeventcustom from '../../../assets/images/feature/custom@2x.png';

interface PackageProps {
  packageType: string;
  packagePrice: string;
  imageUrl: string;
  mode: string;
  modeDescription: string;
  duration: string;
  durationDescription: string;
  registrantsCount: string;
  registrantsDescription: string;
}

export const packages: PackageProps[] = [
  {
    packageType: 'Professional',
    packagePrice: 'Free',
    imageUrl: createeventprofessional2,
    mode: 'Virtual',
    modeDescription: 'Virtual Mobile App & Web',
    duration: 'Duration',
    durationDescription: '1 hour per month',
    registrantsCount: 'Up to 50 Registrants',
    registrantsDescription: 'Increase your event size up to 500 registrants'
  },
  {
    packageType: 'Team',
    packagePrice: 'From $235/mo',
    imageUrl: createeventteam,
    mode: 'Virtual or Hybrid',
    modeDescription: 'Onsite Mobile App & Check in Management',
    duration: 'Duration',
    durationDescription: 'Upto 150 hours per month',
    registrantsCount: 'Up to 500 Registrants',
    registrantsDescription: 'Increase your event size up to 500 registrants'
  },
  {
    packageType: 'Corporate',
    packagePrice: 'From $1000/mo',
    imageUrl: createeventteam,
    mode: 'Virtual or Hybrid',
    modeDescription: 'Onsite Mobile App & Check in Management',
    duration: 'Duration',
    durationDescription: 'Upto 150 hours per month',
    registrantsCount: 'Up to 1000 Registrants',
    registrantsDescription: 'Increase your event size up to 1000 registrants'
  },
  {
    packageType: 'Enterprise',
    packagePrice: 'From $1980/mo',
    imageUrl: createeventteam,
    mode: 'Virtual or Hybrid',
    modeDescription: 'Onsite Mobile App & Check in Management',
    duration: 'Duration',
    durationDescription: 'Upto 150 hours per month',
    registrantsCount: 'Up to 3000 Registrants',
    registrantsDescription: 'Increase your event size up to 3000 registrants'
  },
  {
    packageType: 'Custom',
    packagePrice: '',
    imageUrl: createeventcustom,
    mode: 'Virtual or Hybrid',
    modeDescription: 'Onsite Mobile App & Check in Management',
    duration: 'Duration',
    durationDescription: 'Unlimited',
    registrantsCount: 'Up to 50000 Registrants',
    registrantsDescription: 'Increase your event size up to 50000 registrants'
  }
];
