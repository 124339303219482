import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Logo } from '../../../shared-components/V2/Logo';
import GroupIcon from '../../../assets/images/spotlight/group.svg';
import { CompanyService } from '../../../services';
import { IPostSpotLight, IPostSpotlightResult } from '../../../models/post/IPostSpotlight';
import { Loader } from '../../../shared-components/Loader';
import { EventsService } from '../../../services/events';

interface IMiniSpotlight {
  companyId: string;
  eventId?: string;
  slug: string;
}

export const MiniSpotlight = ({ slug, companyId, eventId }: IMiniSpotlight) => {
  const [spotlights, setSpotlight] = useState<IPostSpotLight[]>([]);
  const companyService: CompanyService = new CompanyService();
  const eventService: EventsService = new EventsService();

  const [props, setProps] = useState({
    slug,
    companyId,
    eventId
  });
  const startPage: number = 1;
  const [nextPage, setNextPage] = useState(startPage);
  const [totalPage, setTotalPage] = useState(startPage);
  const [fetching, setFetching] = useState(false);

  const hasMoreItems = nextPage <= totalPage;
  const PAGE_SIZE = 20;

  const getCompanySpotlight = async () => {
    let result: IPostSpotlightResult;
    if (props.eventId) {
      result = await eventService.getEventSpotlight(props.eventId, PAGE_SIZE, nextPage, '');
    } else if (props.companyId) {
      result = await companyService.getCompanySpotlight(props.companyId, PAGE_SIZE, nextPage, '');
    }
    return result!;
  };

  const fetchSpotlight = () => {
    if (companyId) {
      const result = getCompanySpotlight();
      return result;
    }
    return {};
  };

  const fetchItems = useCallback(
    async () => {
      if (fetching) {
        return;
      }
      setFetching(true);
      try {
        const result: any = await fetchSpotlight();

        setTotalPage(result?.totalPage);
        if (result && nextPage !== result.page && nextPage <= result?.totalPage) {
          setSpotlight(spotlights.concat(result.data) ?? []);
          setNextPage(nextPage + 1);
        } else {
          setFetching(true);
        }
      } finally {
        setFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [spotlights, fetching, nextPage]
  );

  useEffect(() => {
    if (companyId || eventId) {
      setProps({
        slug,
        companyId,
        eventId
      });
      getCompanySpotlight().then(({ data, totalPage }) => {
        setSpotlight(data);
        setTotalPage(totalPage);
        setNextPage(nextPage + 1);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, companyId, eventId]);

  const onClickSpotlight = (id: string, slug: string, url: string) => {
    if (url?.startsWith('http')) {
      window.open(`${url}`, '_blank');
    }
    window.open(`/wall/company/${slug}?tab=spotlight&id=${id}`, '_blank');
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {spotlights.length > 0 ? (
        <div className="card-shadow p-2 rounded-xl">
          <div className="w-full h-[300px] rounded-xl overflow-scroll no-scrollbar overscroll-y-contain">
            <InfiniteScroll
              className="flex flex-wrap gap-1 h-max"
              pageStart={startPage}
              loadMore={fetchItems}
              hasMore={hasMoreItems}
              loader={<Loader className="pt-5" key="loader" />}
              useWindow={false}
            >
              {!spotlights.length && <div className="text-sm text-gray-500 w-full flex justify-center items-center">No spotlight</div>}
              {
                spotlights.map(spot => (
                  <div
                    className="h-fit w-full object-cover overflow-hidden relative cursor-pointer"
                    onClick={() => onClickSpotlight(spot?.id, spot?.postedAsCompany?.slug ?? slug, spot.content)}
                  >
                    {spot.format === 'image' && (
                      <Logo
                        className="h-fit w-full object-cover"
                        src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${spot.source}`}
                        alt={spot.content}
                      />
                    )}
                    {spot.format === 'video' && (
                      <video className="h-[94px] w-[96.8px] object-cover" controls={false}>
                        <source
                          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${spot.source}`}
                        />
                        <track kind="captions" />
                      </video>
                    )}
                    {spot.format === 'document' && spot.source && (
                      <iframe
                        title={spot.content}
                        className="bg-cover h-[94px] w-[96.8px]"
                        src={`https://docs.google.com/viewerng/viewer?url=${process.env.REACT_APP_IMAGE_URL_PREFIX}/${spot.source}&embedded=true`}
                      />
                    )}
                    <div className="absolute bottom-2 left-2 items-center justify-between">
                      <img src={GroupIcon} alt="group" className="cursor-pointer" width={14} height={14} />
                    </div>
                  </div>
                ))
              }
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
