export enum AudienceTabs {
  Attendees = "Attendees",
  Speakers = "Speakers",
  Sponsors = "Sponsors",
  Meetings = "Meetings",
  Leads = "Leads",
}

export enum PeopleType {
  Registrants = 'registrants',
  LiveAttendees = 'live attendees',
  NotLiveAttendees = 'no show',
  OnDemandAttendees = 'on demand attendees',
  Sponsors = 'sponsors',
  Speakers = 'speakers',
  EventModerators = 'moderators',
  ScheduledMeeting = 'scheduled meetings',
  EventLeads = 'captured leads',
}

export enum ScheduledMeetingsButton {
  ConfirmMeetings = "approved",
  PendingMeetings = "waiting",
}

export enum MeetingStatus {
  WAITING = "waiting",
  INPROGRESS = "inprogress",
  APPROVED = "approved",
  DENIED = "denied",
}
