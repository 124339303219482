import { Box } from "@mui/material";
import UserCard from "./user-card";
import { imageUrlPrefix } from "src/utils";
import UserMatchProgress from "./user-match-progress";
import { UserAbout } from "./user-about";

import defaultCover from "../../../assets/images/default-cover-new.png";
import defaultUserAvatar from "../../../assets/images/avatar-default.svg";
import {
  MatchCardsFullHeight,
  MatchCardsHalfHeight,
} from "../event/live/Constants";

type UserMatchProps = {
  item: any;
  user: any;
  activeList?: any;
  columnCardHeight: string;
};

export const UserMatch = ({
  item,
  user,
  activeList,
  columnCardHeight,
}: UserMatchProps) => {
  return (
    <Box
      sx={{
        p: 0,
        width: 1,
        maxHeight:
          columnCardHeight === "half-height"
            ? MatchCardsHalfHeight
            : MatchCardsFullHeight,
        height:
          columnCardHeight === "half-height"
            ? MatchCardsHalfHeight
            : MatchCardsFullHeight,
        overflowY: "scroll",
        borderRadius: "5px",
        paddingBottom: "90px",
      }}
    >
      <UserCard
        user={{
          id: item?.id,
          name: `${item?.firstName} ${item?.lastName}`,
          role: "",
          coverUrl: item?.cover
            ? `${imageUrlPrefix}/${item?.cover}`
            : defaultCover,
          avatarUrl: item?.avatar ? `${imageUrlPrefix}/${item?.avatar}` : null,
        }}
        jobTitle={item?.jobTitle}
        location={item?.location}
        item={item}
      />
      <UserMatchProgress user={user} item={item} />
      <UserAbout user={user} item={item} />
      {/* <UserHightlights /> */}
    </Box>
  );
};
