import { SVGProps } from 'react';

function DragAndDropIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Group_14414"
      data-name="Group 14414"
      xmlns="http://www.w3.org/2000/svg"
      width="10.248"
      height="20.58"
      viewBox="0 0 10.248 20.58"
      {...props}
    >
      <g id="Group_14413" data-name="Group 14413">
        <g id="Group_14412" data-name="Group 14412">
          <circle id="Ellipse_840" data-name="Ellipse 840" cx="1.812" cy="1.812" r="1.812" />
          <circle
            id="Ellipse_841"
            data-name="Ellipse 841"
            cx="1.812"
            cy="1.812"
            r="1.812"
            transform="translate(6.624 0)"
          />
          <circle
            id="Ellipse_842"
            data-name="Ellipse 842"
            cx="1.812"
            cy="1.812"
            r="1.812"
            transform="translate(0 8.478)"
          />
          <circle
            id="Ellipse_843"
            data-name="Ellipse 843"
            cx="1.812"
            cy="1.812"
            r="1.812"
            transform="translate(6.624 8.478)"
          />
          <circle
            id="Ellipse_844"
            data-name="Ellipse 844"
            cx="1.812"
            cy="1.812"
            r="1.812"
            transform="translate(0 16.956)"
          />
          <circle
            id="Ellipse_845"
            data-name="Ellipse 845"
            cx="1.812"
            cy="1.812"
            r="1.812"
            transform="translate(6.624 16.956)"
          />
        </g>
      </g>
    </svg>
  );
}

export default DragAndDropIcon;
