/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useState } from 'react';
import classNames from 'classnames';
import AlertModal from 'src/components/alert-modal/alert-modal';
import CustomModal from './custom-modal';

interface UserDetailsProps {
  UserPicture: string;
  user: {
    name: string;
    position: string;
    company: string;
    isSpeaker: boolean;
    slug: string;
  };
  speaker?: any;
  totalSpeakers?: number;
  hideBioBtn?: boolean;
  isBreakoutSession5?: boolean
  isBreakoutSession?: boolean;
  isOpen?: boolean;
  userDefault?: any;
}

const UserDetails: React.FC<UserDetailsProps> = ({
  UserPicture,
  user,
  speaker,
  totalSpeakers,
  hideBioBtn,
  isBreakoutSession5,
  isBreakoutSession,
  isOpen,
  userDefault
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  // const [first, setfirst] = useState(second)

  const handleBioClicked = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  const isUserProfile = !UserPicture.includes('null') ? UserPicture : userDefault;

  return (
    <div className={classNames('flex w-full max-w-[285px]', {
      'items-center': speaker?.bio,
      'items-start': !speaker?.bio,
      '!w-full': isBreakoutSession5,
      '!w-[90px] !min-w-[90px]': isBreakoutSession && !isOpen
    })}
    >
      <div className="relative">
        <div className="w-[70px] min-w-[70px] h-[85px] max-h-[85px] bg-[#333333] rounded-5 overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={isUserProfile}
            alt={user.name}
          />
        </div>
        {totalSpeakers ? (
          <div className="h-[29px] rounded-[5px] p-[6px] bg-white absolute bottom-[5px] right-[5px]">
            <div className="text-black text-center leading-[17px] text-[17px] font-medium helvetica-neue-39-roman uppercase">{`+${totalSpeakers}`}</div>
          </div>
        ) : null}
      </div>
      <div className={classNames('ml-[15px] flex flex-col', {
        'pt-[3px]': !speaker?.bio,
        hidden: isBreakoutSession && !isOpen
      })}
      >
        <div className="text-white md:leading-[21px] leading-[20px] md:text-[20px] text-[16px] helvetica-neue-65-medium font-semibold text-left sm:line-clamp-1 mb-[6px] sm:max-w-none max-w-[80%]">
          {user.name}
        </div>
        <div className="text-white md:leading-[16px] leading-[14px] md:text-[13px] text-[12px] helvetica-neue-55-roman text-left sm:line-clamp-1 sm:max-w-none max-w-[80%]">
          {user.position}
        </div>
        <div className="text-white md:leading-[16px] leading-[14px] md:text-[13px] text-[12px] helvetica-neue-55-roman text-left sm:line-clamp-1 sm:max-w-none max-w-[80%]">
          {user.company}
        </div>
        {((user?.isSpeaker && speaker?.bio) || !user?.isSpeaker)
        && (hideBioBtn ? null : (
          <button
            type="button"
            onClick={handleBioClicked}
            className={classNames(
              'text-black bg-[#F1B01A] w-[104px] md:leading-[16px] leading-[13px] md:text-[13px] text-[10px] mt-1 rounded-[3px] h-[21px] px-[18px] text-center helvetica-neue-55-medium font-medium'
            )}
          >
            {user?.isSpeaker ? 'Bio' : 'Website'}
          </button>
        ))}
      </div>
      {speaker && isOpenModal ? (
        <AlertModal
          isOpen={isOpenModal}
          onClose={handleCloseModal}
          className="max-w-[890px] bg-[#14161A]"
          closeIconClass=" right-[18px] top-[15px] px-0"
          isCloseIcon
        >
          <CustomModal
            user={user}
            UserPicture={UserPicture}
            speaker={speaker}
          />
        </AlertModal>
      ) : null}
    </div>
  );
};

export default UserDetails;
