import { Reducer } from 'redux';

// import { IPost } from '../../../models/post/post';
import { viewStoriesActions } from './actions';
import {StoryViewersActionTypes } from './types';

type Nullable<T> = T | null;

export interface ViewStoryState {
  value: Nullable<any> ;
  loading: boolean;
  errorMessage: string;
}

const initialState: ViewStoryState = {
  value: null,
  loading: false,
  errorMessage: '',
};

export const ViewStoriesReducer: Reducer<ViewStoryState, viewStoriesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case StoryViewersActionTypes.STORY_VIEWERS_LOADING: {
      return {
        ...state,
        loading: state.loading,
      };
    }
    case StoryViewersActionTypes.STORY_VIEWERS_SUCCESS: {
      return {
        ...state,
        value: action.payload,
      };
    }

    case StoryViewersActionTypes.STORY_VIEWERS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null,
      };
    }

    default:
      return state;
  }
};
