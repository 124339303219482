import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IReactSelectOption } from "../../models/react-select-option";
import { IAppState } from "../../store";
import { getCompanies } from "../../store/companies/actions";
import { UseFormRegister } from "react-hook-form";
import { IReactSelectOptionCompany } from "../../models/company/company-react-select-option";
import noCompany from "../../assets/images/signup/noCompany.svg";
import { Logo } from "../../shared-components/V2/Logo";
import { useOnClickOutside } from "usehooks-ts";
import classNames from "classnames";
import { LOGIN_SIGNUP_RECOVERY } from "../../constants";
import React from "react";

interface CompanySelectorProps {
  handleCompanySelected: Function;
  register: UseFormRegister<any>;
  selectedCompanyStateToStore: any;
  placeholder?: string;
  className?: string;
}

export const CompanySelectorNew = ({
  handleCompanySelected,
  register,
  selectedCompanyStateToStore,
  placeholder,
  className
}: CompanySelectorProps) => {
  const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;

  const dispatch = useDispatch();
  const companies = useSelector((state: IAppState) => state.companies);

  const ref = React.useRef(null);

  // const [companyListState, setCompanyListState] = useState<IReactSelectOptionCompany[]>([]);
  const [query, setQuery] = useState('');
  const [getJobSearchTitle, setGetJobSearchTitle] = useState<IReactSelectOptionCompany[]>();

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (selectedCompanyStateToStore) {
      setQuery(selectedCompanyStateToStore.label);
    }
  }, [selectedCompanyStateToStore]);

  // useEffect(() => {
  //   if(companyListState && companyListState.length > 0) {
  //     // setShowMenu(true);
  //     // setGetJobSearchTitle(companyListState);
  //   } else {
  //     setShowMenu(false);
  //     setGetJobSearchTitle([]);
  //   }
  // }, [companyListState]);

  useEffect(() => {
    const mapCompaniesToSelectOption = () => {
      const companyList: IReactSelectOptionCompany[] = []; 

      companies?.value?.data &&
        companies?.value?.data.map((item: any, index: number) =>
          companyList.push({
            key: `company-${index}`,
            value: item.id,
            label: item.name,
            logo: item.logo,
            industry: item?.industry?.name ? item?.industry?.name : ''
          })
        );
        setGetJobSearchTitle(companyList);
        // setShowMenu(true);

      // setCompanyListState(companyList);
    };
    mapCompaniesToSelectOption();
  }, [companies]);

  const onInputChange = (value: string) => {
    const selectionValue = {
      value
    }
    handleCompanySelected(selectionValue);
    setQuery(value);
    if (value?.length > 0) {
      dispatch(getCompanies(value));
      setShowMenu(true);
    }
    else {
      setShowMenu(false);
    }
  }

  const handleClickOutside = () => {
    setShowMenu(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const onFocusInput = (value: any) => {
    setQuery(value);
  };

  const onSelectJobTitle = (value: any) => {
    if (value) {
      handleCompanySelected(value);
      setShowMenu(false);
    }
  };

  return (
    <div ref={ref} className="relative">
      <input
        id="companySelector"
        autoCorrect="off"
        autoComplete="new-password"
        spellCheck="false"
        type="text"
        placeholder={placeholder?.trim()?.length ? placeholder : "Select or enter your company"}
        className={classNames("w-336 h-[30px] text-sm1 font-extralight text-gray-12 px-2.5 rounded-10 mobile-input-width", {
          [`${className}`]: className,
        })}
        {...register('companyId', {
          required: { value: true, message: LOGIN_SIGNUP_RECOVERY.COMPANY_ISREQUIRED }
        })}
        value={query}
        onChange={(e: any) => onInputChange(e.target.value)}
        onFocus={(e: any) => onFocusInput(e.target.value)}
        onMouseDown={(e: any) => {
          e.stopPropagation();
          e.target.focus();
        }}
      />
      {showMenu && getJobSearchTitle && getJobSearchTitle?.length! > 0 && (
        <div className="bg-white rounded-10 py-2 px-1 shadow-md absolute left-0 top-[32px] w-full min-w-[344px] max-h-[300px] overflow-scroll no-scrollbar z-20 mobile-min-width">
          <ul>
            {showMenu &&
              getJobSearchTitle &&
              getJobSearchTitle?.length! > 0 &&
              getJobSearchTitle?.map((item, index) => {
                return (
                  <li
                    className="h-[40px] mx-[6px] text-[15px] mt-[1px] font-normal text-[#18161C] rounded-10 hover:bg-[#EDF2F5] cursor-pointer flex items-center justify-start pl-[0px]"
                    onClick={() => onSelectJobTitle(item)}
                    key={index}
                  >
                    <div className="flex row h-[39px] w-full overflow-hidden !px-[8px] !py-[4px] selected-field-company">
                      <div className="!p-0 hide-text">
                        {item.logo && (
                          <div className="flex justify-center items-center w-[29px] h-[29px] bg-white profile-avatar-shadow rounded-[5px] px-auto mr-[8px]">
                            <Logo
                              src={`${imageUrlPrefix}/${item.logo}`}
                              className="flex justify-center w-[29px] h-[29px] bg-gray-1 rounded-[5px] object-cover"
                              alt={item?.industry}
                              defaultLogo="company"
                            />
                          </div>
                        )}
                        {!item.logo && (
                          <div className="flex justify-center items-center w-[29px] h-[29px] bg-white profile-avatar-shadow rounded-[5px] px-auto mr-[11px]">
                            <Logo
                              src={`${noCompany}`}
                              className="flex justify-center w-[29px] h-[29px] rounded-[5px] p-[3px] bg-gray-1 object-contain"
                              alt={item?.industry}
                              defaultLogo="company"
                            />
                          </div>
                        )}
                      </div>
                      <div className="truncate flex items-center w-[262px]">
                        {item.label && (
                          <div className="inline-block text-[15px] leading-[20px] font-semibold text-[#172746] selected-label-company">
                            {item.label}
                          </div>
                        )}

                        <div className="mx-2 truncate text-[13px] font-light leading-[16px] text-[#18161C] flex-none hide-text">
                          • Company
                        </div>
                        {/* <div className="truncate text-[13px] font-light leading-[16px] text-[#18161C]">
                  {company.industry}
                </div> */}
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
};
