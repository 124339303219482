import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { signupStep1 } from '../../store/user/signup/actions';
import { IAppState } from '../../store';
import { LinkButton, TextInput, Typography } from '../../shared-components';
import { emailRegex, passwordRegex } from '../../utils';
import {
  Navbar, PageHeader, Footer
} from '../../components';
import CreateProfile1 from './createprofile1';
import { LOGIN_SIGNUP_RECOVERY } from '../../constants';
import CompleteYourProfileNew from './completeyourprofile-new';

interface ISignUp {
  email: string;
  password: string;
  confirmPassword: string;
}

export const Signup = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<ISignUp>();

  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY;
  const userName: string | null = sessionStorage.getItem('userName');

  useEffect(() => {
    if (userName) setValue('email', userName!.toString());
  }, [setValue, userName]);

  const [stateRecaptcha, setStateRecaptcha] = useState({ isVerified: false });
  const dispatch = useDispatch();
  const selector = useSelector((state: IAppState) => state.signupUser);

  const onChangeRecaptcha = (value: any) => {
    if (value) {
      setStateRecaptcha({ isVerified: true });
    }
  };
  const onSubmit = (data: any) => {
    if (stateRecaptcha.isVerified) dispatch(signupStep1(data));
    else toast.error(LOGIN_SIGNUP_RECOVERY.RECAPTCHA_ERROR);
  };

  if (selector.currentStep === 1) return <CreateProfile1 userName={userName} />;
  if (selector.currentStep === 2) return <CompleteYourProfileNew />;

  return (
    <>
      <Navbar />
      <div className="bg-[#001BF6] flex text-center h-[calc(100vh-112px)] overflow-auto">
        <div className="mx-auto">
          <div className="md:-ml-8 mb-10 md:mb-0">
            <div className="flex w-full flex-wrap mt-28 ">
              <div className="flex flex-col">
                <PageHeader isBlueTheme />
                <div className="text-[22px] leading-8 font-medium text-white text-left mt-[5px] m-text-center">Join now</div>
                <div className="mt-1 -ml-1">
                  <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-center mt-3">
                      <div className="text-left">
                        <TextInput
                          className="w-240 md:w-336 h-8 text-sm1 font-normal text-gray-12 px-2.5 rounded-10"
                          textinputsize="medium"
                          type="text"
                          caption="Email address*"
                          placeholder="Enter your email"
                          {...register('email', {
                            required: {
                              value: true,
                              message: LOGIN_SIGNUP_RECOVERY.EMAILADDRESS_ISREQUIRED
                            },
                            pattern: {
                              value: emailRegex,
                              message: LOGIN_SIGNUP_RECOVERY.VALID_EMAILADDRESS
                            }
                          })}
                          id="emailAddress"
                          // autoComplete="off"
                          isBlueTheme
                          borderRadius="8px"
                        />
                      </div>
                    </div>
                    <div>
                      {errors.email && (
                        <Typography
                          variant="ValidationMessage"
                          text={errors.email?.message!.toString()}
                          classes="flex content-start email-validation-message"
                        />
                      )}
                    </div>
                    <div className="flex justify-center mt-2">
                      <div className="text-left">
                        <TextInput
                          className="w-240 md:w-336 h-8 text-sm1 font-normal text-gray-12 px-2.5 rounded-10"
                          textinputsize="medium"
                          type="password"
                          caption="Password*"
                          {...register('password', {
                            required: {
                              value: true,
                              message: LOGIN_SIGNUP_RECOVERY.PASSWORD_ISREQUIRED
                            },
                            pattern: {
                              value: passwordRegex,
                              message: LOGIN_SIGNUP_RECOVERY.VALIDPASSWORD
                            }
                          })}
                          id="password"
                          autoComplete="new-password"
                          isBlueTheme
                          borderRadius="8px"
                        />
                      </div>
                    </div>
                    <div>
                      {errors.password && (
                        <Typography
                          variant="ValidationMessage"
                          text={errors.password?.message!.toString()}
                          classes="md:ml-1 flex password-validation-message"
                        />
                      )}
                    </div>
                    <div className="flex justify-center mt-2">
                      <div className="text-left">
                        <TextInput
                          className="w-240 md:w-336 h-8 text-sm1 font-normal text-gray-12 px-2.5 rounded-10"
                          textinputsize="medium"
                          type="password"
                          caption="Confirm password*"
                          {...register('confirmPassword', {
                            required: {
                              value: true,
                              message: LOGIN_SIGNUP_RECOVERY.CONFIRMPASSWORD_ISREQUIRED
                            },
                            validate: value => value === getValues('password') || LOGIN_SIGNUP_RECOVERY.MATCHPASSWORD
                          })}
                          id="confirmPassword"
                          autoComplete="new-password"
                          isBlueTheme
                          borderRadius="8px"
                        />
                      </div>
                    </div>
                    <div>
                      {errors.confirmPassword && (
                        <Typography
                          variant="ValidationMessage"
                          text={errors.confirmPassword?.message!.toString()}
                          classes="md:ml-1 flex password-validation-message lg:justify-start justify-center"
                        />
                      )}
                    </div>
                    <br />
                    <div className="w-full flex justify-center test-recaptcha-checkbox-checkmark">
                      <ReCAPTCHA sitekey={recaptchaSiteKey!} onChange={onChangeRecaptcha} />
                    </div>
                    <div>
                      <div className="flex justify-center mt-4">
                        <LinkButton
                          text="Agree &amp; Join"
                          backgroundColor=""
                          textColor="#3855EB"
                          classes="bg-white text-white text-sm1 font-semibold !rounded-[8px]"
                          width="121px"
                          height="30px"
                          href="/join"
                          data-test-id="signup-button-1"
                        />
                      </div>
                    </div>
                    <div className="mb-5">
                      <div className="flex flex-col sm:flex-row justify-center mt-3 text-x-sm text-white">
                        By clicking Agree & Join, you agree to Panelist
                        <Link
                          className="ml-1 hover:underline text-white font-medium"
                          to="/user-agreement"
                        >
                          User Agreement
                        </Link>
                      </div>
                      <div className="flex justify-center mt-1 text-white text-x-sm">
                        <Link
                          className="mr-1 hover:underline text-white font-medium"
                          to="/privacy-policy.html"
                        >
                          Privacy Policy
                        </Link>
                        {' '}
                        and
                        {' '}
                        <Link
                          className="ml-1 hover:underline text-white font-medium"
                          to="/privacy-policy.html"
                        >
                          Cookie Policy
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className="bg-black py-3" />
    </>
  );
};

export default Signup;
