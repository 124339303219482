import { SVGProps } from 'react';

function PreviewModalCloseIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            id="Close_button"
            data-name="Close button"
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            {...props}
        >
            <circle id="Ellipse_1038" data-name="Ellipse 1038" cx="14" cy="14" r="14" fill="#edf2f5" />
            <path
                id="Path_580"
                data-name="Path 580"
                d="M.6,0,0,15.037"
                transform="translate(18.933 8.643) rotate(45)"
                fill="none"
                stroke="#7283a4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
            <path
                id="Path_581"
                data-name="Path 581"
                d="M0,.6,15.037,0"
                transform="translate(8.724 8.643) rotate(45)"
                fill="none"
                stroke="#7283a4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
        </svg>
    );
}

export default PreviewModalCloseIcon;
