import classNames from 'classnames';

interface WebsiteEventBarProps {
  title: string;
  roomType: string;
  roomQty?: number;
  backgroundColor?: string;
}

const EventTitleBar = ({
  title,
  roomType,
  roomQty,
  backgroundColor,
}: WebsiteEventBarProps) => (
  <div className="max-w-full mt-[10px]">
    <div className="block md:flex mx-auto">
      <div
        className="w-full bg-[#0049EF] h-49 rounded-10 flex justify-start items-center"
        style={{
          backgroundColor,
        }}
      >
        <div
          className={classNames(
            'mx-[30px] w-[367px] flex items-center truncate gap-2 text-white text-[14px]',
            {
              '!block': roomQty === 2,
            }
          )}
        >
          <div className="font-semibold">{roomType}</div>
          <div className="font-medium">{title}</div>
        </div>
      </div>
    </div>
  </div>
);

export default EventTitleBar;
