import classNames from "classnames";
import { ReactComponent as CloseIcon } from "./../../assets/images/modal-close-icon-new.svg";
import { ReactComponent as CloseIconWhite } from "./../../assets/images/modal-close-icon-white.svg";
import { ReactComponent as CloseIconGreen } from "./../../assets/images/modal-close-green-icon.svg";

import { Dialog, Transition } from "@headlessui/react";
import { useEffect, useState } from "react";

interface Props {
  isOpen?: boolean;
  bgColor?: string;
  children: any;
  onClose?: () => void;
  className?: string;
  closeIconClass?: string;
  disableClose?: boolean;
  isCloseIcon?: boolean;
  isCloseWhite?: boolean;
  isCloseGreen?: boolean;
  isStoryModal?: boolean;
  isAttendees: boolean
}
const VideoMeetingModal = ({
  children,
  onClose,
  isOpen = true,
  bgColor,
  className,
  disableClose,
  isCloseIcon,
  closeIconClass,
  isCloseWhite,
  isCloseGreen,
  isStoryModal,
  isAttendees
}: Props) => {

  const [isSharing, setIsSharing] = useState<boolean>(false)

  useEffect(() => {
    return setIsSharing(isAttendees);
  }, [isAttendees])

  return (
    <>
      <Transition
        appear
        show={isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog as="div" className="relative z-[10000]" onClose={()=>onClose &&onClose()}>
          <div className="opacity-100">
            <div
              className={classNames("fixed inset-0 bg-black bg-opacity-[85%]", {
                "bg-opacity-[90%]": isStoryModal,
              })}
            ></div>
          </div>
          <div className="fixed inset-0 overflow-y-auto">
            <div
              className={
                "flex min-h-full items-center justify-center p-4 text-center"
              }
            >
              <Dialog.Panel
                className={classNames(
                  `pb-[8px] ${isSharing && "w-min"} ransform rounded-[8px] text-left align-middle shadow-xl transition-all outline-none`,
                  {
                    "bg-white": !bgColor,
                    [`bg-[${bgColor}]`]: bgColor,
                    [`${className}`]: className,
                  }
                )}
              >
                {!!onClose && (
                  <div
                    className={classNames("absolute right-0 top-2 px-2 z-10", {
                      hidden: disableClose,
                      [`${closeIconClass}`]: closeIconClass,
                    })}
                  >
                    {isCloseIcon ? (
                      <span className="cursor-pointer" onClick={onClose}>
                        {isCloseWhite ? (
                          <CloseIconWhite />
                        ) : isCloseGreen ? (
                          <CloseIconGreen />
                        ) : (
                          <CloseIcon />
                        )}
                      </span>
                    ) : (
                      <button
                        type="button"
                        className="rounded-md border bg-close-icon hover:bg-close-hover bg-no-repeat border-transparent px-4 w-7 h-8 text-sm font-medium outline-none"
                        onClick={onClose}
                      ></button>
                    )}
                  </div>
                )}
                {children}
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default VideoMeetingModal;
