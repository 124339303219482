/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import CompleteYourProfileFormNew from '../../../signup/components/completeyourprofile-form-new';

interface ISignupProps {
  page?: number;
  changePage?: Function;
  from?: string
  isEventRegister?: boolean;
}
// eslint-disable-next-line no-unused-vars
export const EventCompleteProfile = ({
 page,
 changePage,
 from = 'event',
 isEventRegister,
}: ISignupProps) => (
  <div className="flex flex-col w-[858px] h-[576px] mobile-w-full mobile-h-full">
    {isEventRegister && (
      <div className="flex items-center justify-between w-[391px] mt-[28px] mb-[4px] mx-auto width-auto custom-gap-x">
        <div className="text-[13px] text-[#3C3C3C] font-bold">Your profile</div>
        <svg className="h-line" xmlns="http:www.w3.org/2000/svg" width="141" height="1" viewBox="0 0 141 1">
          <line id="Line_1398" data-name="Line 1398" x2="140" transform="translate(0.5 0.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1" />
        </svg>
        <div className="text-[13px] text-[#3C3C3C] font-[500]">Confirmation</div>
      </div>
    )}
    <CompleteYourProfileFormNew isFilterHieght buttonText="Continue to check out" from={from} />
  </div>
);

export default EventCompleteProfile;
