import classNames from 'classnames';
import { IEventSponsor } from '../../../models/event/event-sponsor';
import { Logo } from '../../../shared-components/V2/Logo';

interface IWebsiteEventPartnerList {
  sponsors: IEventSponsor[];
  roomQty?: number;
  isTitleLobby?: boolean;
  isOpen?: boolean;
}

const WebsiteEventPartnerList = ({
  sponsors, roomQty, isTitleLobby, isOpen
}: IWebsiteEventPartnerList) => (
  <div
    className={
      classNames(
        '',
        {
          '!p-0': roomQty === 2,
          'pl-[100px]': isTitleLobby,
          '!pl-0 max-w-[215px] min-w-[215px]': isOpen
        }
      )
    }
  >
    <div className="">
      {/* {
        !!sponsors && sponsors?.length > 0
        && <div className="text-[14px] text-white font-medium h-[20px] mb-2.5">Partners</div>
      } */}
      {sponsors
        ? sponsors?.map((item: any) => (
          <div className="flex mb-2.5 last:mb-0">
            <div className="w-full">
              <div className="flex gap-[10px]">

                <Logo src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.company?.logo}`} className="w-[60px] h-[60px] object-contain bg-[#ffffff] p-[1px] rounded-[5px]" />

                {/* <div className="flex flex-col justify-start">
                  <div className="text-[15px] leading-[20px] text-white font-medium line-clamp-1 helvetica-neue-45-light">
                    {item.company.name}
                  </div>
                  <div className="text-[15px] leading-[20px] text-white font-medium line-clamp-1 helvetica-neue-45-light">
                    {item.sponsorship}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ))
        : null}
    </div>
  </div>
);

export default WebsiteEventPartnerList;
