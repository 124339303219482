// Import Ad and Resources Component, WIP
import { Badge, BadgeProps, CardMedia, IconButton, Popper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import Carousel, { CarouselArrows, useCarousel } from 'src/mui/components/carousel';
import Iconify from 'src/mui/components/iconify/iconify';
import Fade from '@mui/material/Fade';
import { Icon } from '@iconify/react';
import { EventsService } from 'src/services/events';
import { AdsSvgIcon } from 'src/mui/assets/icons/AdsSvgIcon';
import { getMinifiedImage } from 'src/mui/utils/image';

const arrowStyle = { width: 26, height: 26, backgroundColor: '#e9eff2', borderRadius: 100 };
const mediaStyle = { maxWidth: '100%', height: 194, width: 1, margin: 'auto', objectFit: 'contain', borderRadius: '0 0 10px 10px', };

type AdsAndResourcesProps = {
    ads: any[];
    sessionTime: any;
    // resources: IEventResource[];
    eventId?: string;
    setIsHandoutsOpen: (value: boolean) => void;
    setIsPollsOpen: (value: boolean) => void;
    setIsAdsOpen: (value: boolean) => void;
    isAdsOpen: boolean;
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: 'lightGray',
        right: 15,
        top: 10,
        borderRadius: 0,
        overflow: 'hidden',
    },
}));


export const AdsAndResources = (props: AdsAndResourcesProps) => {
    const [ads, setAds] = useState<any[]>();
    const [open, setOpen] = useState(false);
    const [showCross, setShowCross] = useState(false);
    const buttonRef = useRef<any>(null)
    const popoverRef = useRef<HTMLDivElement | null>(null);
    const [showAds, setShowAds] = useState<any>(null);
    const eventService: EventsService = new EventsService();

    const handleOpeAds = (event: any) => {
        setShowAds(event?.currentTarget);
        props.setIsAdsOpen(!props.isAdsOpen);
        props.setIsHandoutsOpen(false);
        props.setIsPollsOpen(false);
    };

    const handleCloseAds = () => {
        props.setIsAdsOpen(false);
        setShowAds(null);
    };

    // const rotateRight = (arr: any, reverse?: boolean) => {
    //     if (reverse) arr.unshift(arr.pop());
    //     else arr.push(arr.shift());
    //     return arr;
    // };

    const rotateRight = (arr: any[], reverse?: boolean) => {
        const newArray = [...arr]; // Create a copy of the array
        if (reverse) newArray.unshift(newArray.pop());
        else newArray.push(newArray.shift());
        return newArray;
    };

    function addMinutes(date: Date, minutes: number) {
        return new Date(date.getTime() + minutes * 60000);
    }

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (!open) {
    //             buttonRef?.current?.click();
    //         }
    //     }, 120000);
    //     return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
        let interval: any;
        if (props.isAdsOpen) {
            interval = setTimeout(() => {
                // setOpen(false);
                setAds(rotateRight(ads!));
            }, 4000);
        }
        return () => clearTimeout(interval)
    }, [props.isAdsOpen])

    useEffect(() => {
        if (props?.ads) {
            setAds(props.ads);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.ads]);

    const carousel = useCarousel({
        adaptiveHeight: true,
        speed: 800,
        fade: true,
        infinite: true,
        pauseOnHover: false,
    });

    const onClickAdd = async (eventId: string, id: string, url: string) => {
        if (eventId && id) {
            await eventService.clickOnAdd(eventId, id).then((res) => {
                if (res.message === "SUCCESS") window.open(url, '_blank');
            })
        }
    };

    return (
        <Stack sx={{ position: 'relative' }}>
            <Stack onClick={handleOpeAds} ref={buttonRef}>
                {props.isAdsOpen ? (
                    <Stack
                        sx={{
                            width: '40px', height: '40px', minHeight: '40px', borderRadius: '15px', alignItems: 'center', justifyContent: 'center', zIndex: 1000
                        }}>
                        <AdsSvgIcon />
                    </Stack>
                ) : (
                    <Stack
                        sx={{
                            width: '40px', height: '40px', minHeight: '40px', borderRadius: '15px', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255, 255, 255, 0.15)',
                            backdropFilter: 'blur(40px)', zIndex: 1000
                        }}>
                        <AdsSvgIcon />
                    </Stack>
                )}

            </Stack>
            {props.isAdsOpen ? (
                <Stack
                    sx={{
                        p: '6px',
                        backgroundColor: 'rgba(255, 255, 255, 0.15)',
                        backdropFilter: 'blur(30px)',
                        width: '203px',
                        height: '203px',
                        top: 0,
                        right: 0,
                        borderRadius: '15px',
                        zIndex: 100,
                        position: 'absolute',
                    }}
                >
                    {/* <div className=' w-full h-full backdrop-blur-[80px] absolute left-0 top-0 rounded-10 overflow-hidden'/> */}
                    <Popper
                        id={'Ads-picker'}
                        open={props.isAdsOpen}
                        placement={'top-start'}
                        anchorEl={showAds}
                        disablePortal
                        transition
                        sx={{ borderRadius: '9px', transform: 'translate(-4.5px, 4.5px) !important', overflow: 'hidden' }}
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={400}>
                                <Stack sx={{ width: '194px', height: '194px', position: 'relative', maxHeight: 194, maxWidth: 194, borderRadius: '9px', overFlow: 'hidden' }}
                                    onMouseEnter={() => setShowCross(true)}
                                    onMouseLeave={() => setShowCross(false)}
                                >
                                    <Stack>
                                        {ads?.length! > 1 && <CarouselArrows
                                            onNext={carousel.onNext}
                                            onPrev={carousel.onPrev}
                                            sx={{
                                                position: 'absolute',
                                                top: '56%',
                                                left: 0,
                                                right: 0,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                zIndex: 10
                                            }}
                                            leftButtonProps={{
                                                sx: {
                                                    p: 0.5,
                                                    mt: -2.5,
                                                    pr: 0.5,
                                                    left: 0,
                                                    '& svg': arrowStyle,
                                                },
                                            }}
                                            rightButtonProps={{
                                                sx: {
                                                    p: 0.5,
                                                    mt: -2.5,
                                                    pl: 0.5,
                                                    right: 0,
                                                    '& svg': arrowStyle,
                                                },
                                            }}
                                        />
                                        }
                                    </Stack>
                                    {ads?.length! > 0 && (
                                        <Carousel
                                            ref={carousel.carouselRef}
                                            {...carousel.carouselSettings}
                                        >
                                            {ads?.map((ad: any) => (
                                                <StyledBadge
                                                    // badgeContent={showCross ? <Typography onClick={() => {
                                                    //     setOpen(false);
                                                    //     setShowCross(false);
                                                    // }}>x</Typography> : 'Ad'}
                                                    key={carousel.currentIndex} anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}>
                                                    <Stack
                                                        onClick={() => onClickAdd(props.eventId!, ads[carousel.currentIndex]?.id, ads[carousel.currentIndex]?.url)}
                                                    >
                                                        {/* <a href={ad?.url} target="_blank" rel="noopener noreferrer"> */}
                                                        <CardMedia sx={mediaStyle} component='img' image={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${ad?.image}`} />
                                                        {/* </a> */}
                                                    </Stack>
                                                </StyledBadge>
                                            ))}
                                        </Carousel>
                                    )}
                                </Stack>
                            </Fade>
                        )
                        }
                    </Popper >
                </Stack>
            ) : null}

        </Stack>
    );
};
