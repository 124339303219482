import 'react-toastify/dist/ReactToastify.css';

import { useEffect, useRef } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import ErrorMessage from '../../components/error-message/errorMessage';
import { LOGIN_SIGNUP_RECOVERY } from '../../constants';
import { HyperLink, TextInput, Typography } from '../../shared-components';
import { Button } from '../../shared-components/V2';
import { IAppState } from '../../store';
import { login } from '../../store/auth';
import { checkUserStatus } from '../../store/user-status';
import { emailRegex } from '../../utils';
import { resetUserEmail } from '../../store/checkUserEmail';

interface ILogin {
  username: string;
  password: string;
}

interface ILoginProps {
  from: string;
  changePage: any;
  isEventRegister?: boolean;
  isSpectreForm?: boolean;
  isBlueTheme?: boolean;
}

export const LoginForm = ({
  from,
  changePage,
  isEventRegister,
  isSpectreForm,
  isBlueTheme,
}: ILoginProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ILogin>();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const redirectTo = queryParams.get('login');

  const user = sessionStorage.getItem('userName');

  // const [email] = useState(user);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const dispatch = useDispatch();
  const { errorMessage } = useSelector((state: IAppState) => state.auth);

  useEffect(() => {
    if (user !== '') setValue('username', user!);
  }, [setValue, user]);

  // useEffect(() => {
  //   if (errorMessage) {
  //     toast.error(errorMessage);
  //   }
  // }, [errorMessage]);

  useEffect(() => {
    if (user && windowSize?.current[0] > 600) {
      toast.success(
        <ErrorMessage
          title={LOGIN_SIGNUP_RECOVERY.LOGIN_TO_CONTINUE}
          message={LOGIN_SIGNUP_RECOVERY.LOGIN_SUCCESSFULL}
          linkText="Reset password"
          link="/forgotPassword"
        />
      );
    }
  }, [user, windowSize]);

  // TODO: Disabled as this feature is not fully implemented in React.
  // const handleOneTimeLink = async () => {
  //   const userService: UserService = new UserService();
  //   let data: any;
  //   // eslint-disable-next-line prefer-const
  //   data = {};
  //   data.email = {};
  //   data.email = email;

  //   try {
  //     await userService.oneTimeLink(data);
  //   } catch (error: any) {
  //     toast.error(error);
  //   }
  // };

  const resetForm = () => {
    sessionStorage.removeItem('userName');
    changePage(0);
    dispatch(resetUserEmail());
  };

  const onSubmit = ({ username, password }: ILogin) => {
    dispatch(login(username, password, navigate, from, changePage, redirectTo!));
    dispatch(checkUserStatus());
  };

  return (
    <form
      style={{
        display: isEventRegister ? 'flex' : 'block',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      className={classNames('login-form-height', {
        'h-[396px]': isEventRegister,
        'h-[317px]': isSpectreForm,
      })}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-[336px] mx-auto mobile-w-full">
        <div className="text-left mt-4">
          <TextInput
            className={classNames('w-full h-[30px] text-sm1 font-light text-[#666666] px-3 rounded-10', {
              // eslint-disable-next-line max-len
              'helvetica-neue-35-thin !h-[35px] !rounded-10 !bg-transparent !border-1 !border-[#8f8f8f] !placeholder:text-[15px] !placeholder:text-white !text-white text-[15px] px-[15px] focus-visible:outline-none': isSpectreForm,
              '!text-black': isBlueTheme
            })}
            textinputsize="medium"
            type="text"
            caption="Email Address*"
            disabled
            placeholder="Enter your email"
            {...register('username', {
              required: { value: true, message: 'Email address is required' },
              pattern: {
                value: emailRegex,
                message: 'Email is invalid',
              },
            })}
            id="emailAddress"
            labelclass={classNames({
              'bg-[#2d2d2d] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white': isSpectreForm,
            })}
            classes={classNames({
              // eslint-disable-next-line max-len
              'helvetica-neue-35-thin !w-full !h-[35px] !rounded-10 !bg-transparent !border-1 !border-[#8f8f8f] !placeholder:text-[15px] !placeholder:text-white text-white text-[15px] px-[15px] focus-visible:outline-none': isSpectreForm,
            })}
            isBlueTheme={isBlueTheme}
          />
        </div>
        <div>
          {errors.username && (
            <Typography
              variant="ValidationMessage"
              text={errors.username?.message!.toString()}
              classes="flex content-start email-validation-message"
            />
          )}
        </div>

        <div
          className={classNames('mt-[10px]', {
            'mt-[20px]': isSpectreForm,
          })}
        >
          <div className="text-left">
            <TextInput
              className={classNames('w-full h-[30px] text-sm1 font-light text-[#666666] px-3 rounded-10', {
                // eslint-disable-next-line max-len
                'helvetica-neue-35-thin !h-[35px] !rounded-10 !bg-transparent !border-1 !border-[#8f8f8f] !placeholder:text-[15px] !placeholder:text-white !text-white text-[15px] px-[15px] focus-visible:outline-none': isSpectreForm,
              })}
              textinputsize="medium"
              type="password"
              caption="Password*"
              autoFocus
              {...register('password', {
                required: { value: true, message: 'Password is required' },
              })}
              id="password"
              labelclass={classNames({
                'bg-[#2d2d2d] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white': isSpectreForm,
              })}
              classes={classNames({
                // eslint-disable-next-line max-len
                'helvetica-neue-35-thin !w-full !h-[35px] !rounded-10 !bg-transparent !border-1 !border-[#8f8f8f] !placeholder:text-[15px] !placeholder:text-white text-white text-[15px] px-[15px] focus-visible:outline-none': isSpectreForm,
              })}
              isBlueTheme={isBlueTheme}
            />
          </div>
        </div>
        <div>
          {errors.password && (
            <Typography
              variant="ValidationMessage"
              text={errors.password?.message!.toString()}
              classes="flex password-validation-message"
            />
          )}
        </div>
        {errorMessage ? (
          <>
            <div className="text-red text-center md:text-left text-sm5 font-light">
              {LOGIN_SIGNUP_RECOVERY.LOGIN_ERROR}
              <br />
              {/* <a
                className="underline font-medium text-sm5"
                data-test-id="oneTimeLink"
                onClick={handleOneTimeLink}
                href={`/one-time-link?email=${email}`}
              >
                {' '}
                sign in with a one time link
              </a> */}
            </div>
            <div className="flex justify-center md:justify-start">
              <HyperLink
                variant="ForgotPassword"
                text="Reset Password"
                textColor="#285cb2"
                href="/forgotpassword"
              />
            </div>
          </>
        ) : (
          <div className="flex justify-center lg:justify-start hover:underline mt-[3px]">
            <HyperLink
              classes={classNames('font-medium text-[11px]', {
                'helvetica-neue-55-roman': isSpectreForm,
              })}
              variant="ForgotPassword"
              text="Forgot your password?"
              // eslint-disable-next-line no-nested-ternary
              textColor={isSpectreForm ? '#0071e3' : isBlueTheme ? '#ffffff' : '#285cb2'}
              href="/forgotpassword"
            />
          </div>
        )}
        {isEventRegister && (
          <>
            <div
              className={classNames('text-[13px] tracking-wide leading-5 flex items-center justify-center mt-[30px]', {
                'helvetica-neue-55-roman text-white': isSpectreForm,
                'text-[#172746]': !isSpectreForm,
              })}
            >
              New member?
              <span
                className={classNames('font-semibold text-[13px] leading-5 cursor-pointer ml-[5px] hover:underline', {
                  'helvetica-neue-55-roman text-[#0071e3]': isSpectreForm,
                  'text-[#285CB2]': !isSpectreForm,
                })}
                onClick={resetForm}
              >
                {' '}
                Sign Up
                {' '}
              </span>
            </div>
            {/* <div className="text-[13px] text-[#3C3C3C] leading-5 flex text-center font-medium mt-[22px]">
              The email you have entered is already registered on Panelist,
              enter your password to login
            </div> */}
          </>
        )}
      </div>
      {isEventRegister && !isSpectreForm ? (
        <div className="flex justify-between border-t-[0.5px] pt-[10px] px-[16px] border-[#CECECE]">
          <button
            type="button"
            className="bg-[#EDF2F5] hover:bg-white px-[18px] h-[30px] font-[600] tracking-tight rounded-[15px] text-[13px] leading-[18px] text-[#172746]"
            onClick={resetForm}
          >
            Back
          </button>
          <Button
            type="submit"
            size="small"
            text="Login"
            className="bg-[#0049EF] text-sm2 font-semibold hover:bg-[#244E93] text-white tracking-wide h-30 w-90 p-0 "
            data-test-id="login-button"
          />
        </div>
      ) : !isSpectreForm && (
        <div className="flex justify-center mt-5">
          <Button
            type="submit"
            size="small"
            text="Login"
            className={classNames('bg-[#0049EF] text-sm2 font-semibold hover:bg-[#244E93] text-white tracking-wide h-30 w-90 p-0 ', {
              '!text-[#3855EB] !bg-white': isBlueTheme,
            })}
            data-test-id="login-button"
          />
        </div>
      )}
      {isSpectreForm && (
        <div className="flex justify-between pt-[10px]">
          <div
            className="px-[20px] h-[32px] rounded-full bg-white text-black text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer"
            onClick={resetForm}
          >
            Back
          </div>
          <Button
            type="submit"
            text="Login"
            size="x-small"
            className={classNames('px-[20px] h-[32px] rounded-full bg-[#0071e3] text-white text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer')}
          />
        </div>
      )}
    </form>
  );
};
export default LoginForm;
