import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Menu,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { _bankingContacts } from "src/mui/_mock";
import AudienceList from "./audience-list";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SearchNotFound from "src/mui/components/search-not-found";
import { useDispatch, useSelector } from "react-redux";
import { IEvent } from "src/models/user-events";
import { IAttendee } from "src/models/events-live/attendee";
import {
  Participants,
  QueueParticipants,
} from "src/store/interactive-event/types";
import { AudienceTabs } from "./Types";
import SearchIcon from "./Icons/SearchIcon";
import GridViewIcon from "./Icons/GridViewIcon";
import { IAppState } from "src/store";
import { useCurrentUser } from "src/utils/hooks";
import {
  getEventSponsors,
  getTabsCount,
  getMeetingsSummary,
  getSponsorsLeadsSummary,
} from "src/store/liveEvent";
import { HostAndAttendee } from "./attendee";
import { SponsorCompanyInfo } from "./SponsorCompanyInfo";
import LiveMeetings from "./LiveMeeting";
import SortByIcon from "./SortByIcon";
import LiveEventLeads from "./LiveEventLeads";
import { applyFilter, applySort } from "src/mui/utils/filter-list";
import { Audience_OPTIONS } from "./audienceOption";
import { getBrandingColors, subscribeToColorChanges } from '../event/live/liveEventStyling';

type AudienceProps = {
  event: IEvent;
  stage: Participants;
  attendees?: IAttendee[];
  queue?: QueueParticipants;
  classNames?: string;
  onMuteClick?: Function;
  isGridView?: boolean;
  columnCardHeight?: string;
  columnWidth?: number;
  isWhiteIcons?: boolean;
};
const colors = getBrandingColors();

export const  Audience = ({
  event,
  attendees,
  stage,
  isGridView,
  columnCardHeight,
  columnWidth,
  isWhiteIcons
}: AudienceProps) => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);

  const { eventSponsors, tabsCount, joinMeeting } = liveEvent;
  const isAdmin = event?.isAdmin;
  const isSponsor = !isAdmin && event?.isSponsor;
  const isSpeaker = !isSponsor && event?.isSpeaker;
  const sponsorId = isSponsor
    ? event?.eventSponsors?.find((sponsor) =>
        sponsor?.users?.some((sponsorUser) => sponsorUser.id === user.id)
      )?.id
    : undefined;
  const sponsorVisibility = isSponsor
    ? event?.eventSponsors?.find((sponsor) =>
        sponsor?.users?.some((sponsorUser) => sponsorUser.id === user.id)
      )?.visibility
    : undefined;

  const isAttendee = !isSpeaker;
  const [sortBy, setSortBy] = useState(null);

  const sortingFilterOptions = [
    { value: "First name,ASC", label: "Name(A-Z)" },
    { value: "First name,DESC", label: "Name(Z-A)" },
  ];


  const getUserEventRole = () => {
    if (isAdmin) return "admin";
    if (isSponsor) return "sponsor";
    if (isSpeaker) return "speaker";
    if (isAttendee) return "attendee";
  };
  const role = getUserEventRole();

  const audienceOption = Audience_OPTIONS.map((tab) => {
    switch (tab.value) {
      case AudienceTabs.Attendees: {
        if (
          getUserEventRole() === "sponsor" &&
          !sponsorVisibility?.attendees
        ) {
          return null;
        }
        return tab;
      }
      case AudienceTabs.Meetings: {
        if (
          getUserEventRole() === "sponsor" &&
          !sponsorVisibility?.meetings_report
        ) {
          return null;
        }
        return tab;
      }
      default:
        return tab;
    }
  }).filter(Boolean);
  const [selectedAudienceTab, setSelectedAudienceTab] = useState<AudienceTabs>(
    audienceOption[0]!?.value
  );

  const [inputSearch, setInputSearch] = useState("");
  const [viewType, setViewType] = useState<boolean>(true);
  const handleSearchFriends = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputSearch(event.target.value);
    },
    []
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sortedAttendees = applySort({
    list: attendees ?? [],
    sortByKey: sortBy,
    type: "attendees",
  });

  const sortedSpeakers = applySort({
    list: stage?.speakers ?? [],
    sortByKey: sortBy,
    type: "speakers",
  });
  const sortedSponsors = applySort({
    list: eventSponsors ?? [],
    sortByKey: "",
    type: "sponsors",
  });

  const filteredAttendees = applyFilter({
    list: sortedAttendees ?? [],
    query: inputSearch,
    type: "attendees",
  });

  const filteredSpeakers = applyFilter({
    list: sortedSpeakers ?? [],
    query: inputSearch,
    type: "speakers",
  });
  const filteredSponsors: any[] = applyFilter({
    list: eventSponsors ?? [],
    query: inputSearch,
    type: "sponsors",
  });

  const notFound =
    !filteredAttendees.length &&
    !filteredSpeakers.length &&
    !filteredSponsors.length &&
    !!inputSearch;

  const handleFilterPeopleType = (
    _evt: React.SyntheticEvent<Element, Event>,
    newValue: AudienceTabs
  ) => {
    setSelectedAudienceTab(newValue);
  };

  const LiveEventAttendees = () => {
    return (
      <>
        {stage && stage?.speakers?.length > 0 && (
          <AudienceList
            columnWidth={columnWidth}
            title="Stage"
            subheader={`You have ${stage?.speakers?.length} speakers`}
            list={filteredSpeakers}
            isSpeaker
            viewType={viewType}
            isWhiteIcons={isWhiteIcons}
            sx={{
              mb: "14px",
            }}
          />
        )}

        {attendees && attendees?.length > 0 && (
          <AudienceList
            columnWidth={columnWidth}
            title="Attendees"
            subheader={`You have ${attendees?.length} attendees`}
            list={filteredAttendees}
            viewType={viewType}
            isWhiteIcons={isWhiteIcons}
            sx={{
              m: 0,
            }}
            inviteAsConnection
          />
        )}
      </>
    );
  };

  const LiveEventSpeakers = () => {
    return (
      <>
        {stage && stage?.speakers?.length > 0 && (
          <AudienceList
            columnWidth={columnWidth}
            title="Stage"
            subheader={`You have ${stage?.speakers?.length} speakers`}
            list={filteredSpeakers}
            viewType={viewType}
            isWhiteIcons={isWhiteIcons}
            isSpeaker
            sx={{
              mb: "14px",
            }}
          />
        )}
      </>
    );
  };

  const LiveEventSponsors = () => (
    <Stack gap="10px">
      {filteredSponsors?.map((sponsor) => (
        <Card
          sx={{
            width: "100%",
            p: "10px",
            background: "transparent",
            borderRadius: "10px",
            border: `1px solid ${colors?.Secondary! || "#2F2F2F"}`,
            boxShadow:"none"
          }}
        >
          {/* card heading */}
          <Stack direction="column" spacing="10px">
            <SponsorCompanyInfo company={sponsor?.company} />
            <Divider
              sx={{ borderColor: colors?.Secondary! || "#7283A4", width: "100%", opacity: "100%" }}
            />

            {!viewType ? (
              <Stack
                spacing="13px"
                sx={{ pb: "10px" }}
              >
                {sponsor?.users.map((sponsorUser: any) => (
                  <Stack>
                    <HostAndAttendee
                      attendee={sponsorUser}
                      columnWidth={columnWidth}
                      viewType={viewType}
                      isSponsor={true}
                      isWhiteIcons={isWhiteIcons}
                     setOpenFeedbackPopup={()=>{}}/>
                  </Stack>
                ))}
              </Stack>
            ) : (
              <Stack sx={{ pb: "10px" }}>
                <Grid
                  rowGap="26px"
                  columnGap="0px"
                  columnSpacing="26px"
                  container
                  sx={{}}
                >
                  {sponsor?.users.map((sponsorUser: any) => (
                    <Grid item xs={12} md={4} lg={4}>
                      <HostAndAttendee
                        attendee={sponsorUser}
                        columnWidth={columnWidth}
                        viewType={viewType}
                        isSponsor={true}
                        isWhiteIcons={isWhiteIcons}
                       setOpenFeedbackPopup={()=>{}}/>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            )}
          </Stack>
        </Card>
      ))}
    </Stack>
  );

  //  selected tab ui
  const getSelectedTabScreen = () => {
    switch (selectedAudienceTab) {
      case AudienceTabs.Attendees:
        return LiveEventAttendees();

      case AudienceTabs.Speakers:
        return LiveEventSpeakers();

      case AudienceTabs.Meetings:
        return (
          <LiveMeetings
            role={role!}
            sponsorId={sponsorId}
            columnWidth={columnWidth}
            searchQuery={inputSearch}
          />
        );

      case AudienceTabs.Leads:
        return (
          <LiveEventLeads
            role={role!}
            sponsorId={sponsorId}
            columnWidth={columnWidth}
            searchQuery={inputSearch}
            viewType={viewType}
          />
        );

      case AudienceTabs.Sponsors:
        return LiveEventSponsors();

      default:
        return "something goes here";
    }
  };
  const handleSortList = (selectedOption: any) => {
    setSortBy(selectedOption?.value);
  };

  useEffect(() => {
    if (liveEvent?.event?.id!) {
      if (selectedAudienceTab === AudienceTabs.Sponsors)
        dispatch(getEventSponsors(liveEvent?.event?.id!));

      if (selectedAudienceTab === AudienceTabs.Meetings && role === "admin") {
        dispatch(getMeetingsSummary(liveEvent?.event?.id!));
      }

      if (selectedAudienceTab === AudienceTabs.Leads && role === "admin")
        dispatch(getSponsorsLeadsSummary(liveEvent?.event?.id!));
    }
  }, [selectedAudienceTab]);
  useEffect(() => {
    const unsubscribe = subscribeToColorChanges(() => {
      getBrandingColors();
    });
    return unsubscribe;
  }, []);

  const colors = getBrandingColors();

  return (
    <Stack
      spacing={0}
      sx={{
        maxHeight:
          columnCardHeight === "half-height"
            ? "calc(50vh - 57px)"
            : "calc(100vh - 57px)",
        overflowY: isGridView ? "scroll" : " none",
      }}
    >
      <Tabs
        value={selectedAudienceTab}
        onChange={handleFilterPeopleType}
        sx={{
          px: 0,
          mt: "10px",
          bgcolor: "transparent",
          minHeight: "56px",
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTabScrollButton-root": {
            position: "absolute",
            top: "30px",
            zIndex: 100000,
            width: "20px",
            height: "20px",
          },
        }}
        variant="scrollable"
        scrollButtons={false}
      >
        {audienceOption.map((tab, index) => (
          <Tab
            key={index}
            sx={{
              "&.Mui-selected": {
                backgroundColor: colors?.Accent! || "#03FFCD !important",
                opacity: "1",
                color: colors?.ButtonTextColor! || "#000000 !important",
                "& h6": {
                  color: colors?.ButtonTextColor! || "#000000 !important",
                  fontFamily: "Poppins-500",
                  fontWeight: 500,
                },
              },
              "& .Mui-selected .tab-title": {
                color: colors?.ButtonTextColor! || "white",
              },
              "& .Mui-selected .tab-subtitle": {
                color: colors?.ButtonTextColor! || "white",
              },
              backgroundColor: colors?.Secondary! || "#2F2F2F",
              color: colors?.ButtonTextColor! || "white",
              width: "58px",
              minWidth: "58px",
              height: "56px",
              minHeight: "56px",
              borderRadius: "5px",
              justifyContent: "space-between",
              padding: "13px 0 5.5px",
              "&.MuiTab-root:not(:last-of-type)": {
                mr: "5px !important",
              },
            }}
            label={
              <Stack alignItems="flex-start" mt="3px">
                <Box
                  sx={{
                    position: "absolute",
                    top: "7px",
                    left: "8px",
                    borderRadius: "5px",
                    width: "25px",
                    height: "15px",
                    background: "#FF0000",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    fontSize="10px"
                    className="tab-title"
                    fontFamily="Poppins-600"
                    fontWeight={600}
                    lineHeight="16px"
                  >
                    {tabsCount ? tabsCount[tab!.name] : 0}
                  </Typography>
                </Box>

                <Typography
                  variant="subtitle2"
                  fontSize="10px"
                  className="tab-subtitle"
                  lineHeight="15px"
                  fontFamily="Poppins-500"
                  textTransform="capitalize"
                  color={colors?.Text || "#ffffff"}
                  fontWeight={500}
                >
                  {tab!.label || tab!.value}
                </Typography>
              </Stack>
            }
            value={tab!.value}
            icon={tab!.icon(tab!.value === selectedAudienceTab)}
            iconPosition="top"
          />
        ))}
      </Tabs>

      <TextField
        value={inputSearch}
        onChange={handleSearchFriends}
        placeholder="Name, title, company"
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{mr: '6px', pt: '2px'}} position="start">
              <SearchIcon fillColor={colors?.Text! || "#ffffff"} />
            </InputAdornment>
          ),
        }}
        sx={{
          mt: "5px",
          mb: "10px",
          fontSize: "15px",
          fontFamily: "Poppins-400",
          backgroundColor: colors?.Secondary! || "#2F2F2F",
          borderRadius: "5px !important",
          color: colors?.Text! || 'white',
          "& .MuiInputLabel-root": {
            padding: "0 4px",
            left: "-4px",
            top: "-10px",
            fontWeight: "400",
            color: colors?.Text! || "white !important",
          },
          "& .MuiInputLabel-shrink": {
            top: "0 !important",
            color: colors?.Text! || "white !important",
          },
          "& .MuiOutlinedInput-input": {
            padding: "0px 11px 0px 0px !important",
            height: "36px",
            lineHeight: "36px",
            fontFamily: "Poppins-400",
            color: colors?.Text! || "white !important",
            "& input::placeholder": {
              color: colors?.Text! || "white !important",
              fontFamily: "Poppins-400",
              fontSize: "14px",
              opacity: 1,
            },
          },
          "& .MuiOutlinedInput-root input::placeholder": {
            color: colors?.Text! || "white !important",
            opacity: 1,
          },
          "& .MuiOutlinedInput-root": {
            fontFamily: "Poppins-400",
            color: colors?.Text! || "white !important",
            pl: "10px !important",
          },
          "& fieldset": { display: "none" },
        }}
      />
      {selectedAudienceTab !== "Meetings" && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="12px"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton sx={{ p: '0' }}>
              <GridViewIcon />
            </IconButton>

            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
                fontFamily: "Poppins-500",
                ml: "5px",
                flexWrap: "nowrap",
                color: colors?.Text || "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => setViewType(!viewType)}
            >
              {viewType ? "Grid View" : "List View"}
            </Typography>
          </Box>
          <Stack direction="row" alignItems="center" sx={{position:"relative"}}>
            <Typography fontSize={"14px"} fontWeight={500} mr={'3px'} color={colors?.Text || "#ffffff"}>
              Sort by
            </Typography>
            <span style={{ cursor: "pointer" }} onClick={handleClick}>
              <SortByIcon />
            </span>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                marginTop:"15px",
                "& .MuiPaper-root": {
                  backgroundColor: colors?.Secondary,
                  color: colors?.Text,
                },
                "& .MuiMenu-list": {
                  padding: 0
                },
              }}
            >
              {sortingFilterOptions.map((item: any) => (
                <MenuItem sx={{ "&:hover": {backgroundColor: colors?.Main,} }} onClick={() => handleSortList(item)}>
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        </Stack>
      )}

      {notFound ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 10,
          }}
        >
          <SearchNotFound query={inputSearch} sx={{ mt: 3 }} />
        </Box>
      ) : (
        <Stack
          sx={{
            height: "calc(100vh - 129px)",
            overflowY: "scroll",
          }}
        >
          {getSelectedTabScreen()}
        </Stack>
      )}
    </Stack>
  );
};
