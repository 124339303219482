import { ConnectSuggestion } from '../../../models/connect-suggestions';
import PeopleInYourNetworkItem from './people-in-your-network-item';

export const PeopleInYourNetwork = (props: {
  people: ConnectSuggestion[];
  onAddConnection: Function;
}) => (
  <div className="bg-[#EDF2F5] create-post-card sm:w-315 w-full px-[16px] py-[14px] pb-0">
    <div className="text-[15px] font-normal leading-[18px] text-[#000000] mb-2.5">People in your network</div>
    <div className="max-h-[410px] overflow-y-auto">
      {props.people
        && props.people.map((person, index) => (
          <div key={`persion-${index}`} className="mb-4">
            <PeopleInYourNetworkItem
              id={person.id}
              name={`${person.firstName} ${person.lastName}`}
              slug={person.slug}
              jobTitle={person.jobTitle}
              companyName={person?.company?.name}
              avatarImage={person.avatar}
              onAddConnection={() => props.onAddConnection(person.id)}
            />
          </div>
        ))}
    </div>
  </div>
);

export default PeopleInYourNetwork;
