import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IVideoCallState, PartyInfo } from "./types";

export type { IVideoCallState } from "./types";

const startX = Math.random() * 40 + 30;

const initialState: IVideoCallState = {
  chimeInvitation: undefined,
  aParty: undefined,
  bParty: undefined,
  error: undefined,
  userBusy: undefined,
  senderPartyReactionInfo:undefined,
  recieverPartyReactionInfo:undefined,
  newAudienceData: undefined
};

const animationRandomValues = {
  x: startX,
  y: 0, // Start from the bottom (0%)
  scale: 1,
  opacity: 1,
  phase: Math.random() * 3 * Math.PI,
}

// ###########################################################################################
// NOTE: This file gets WebSocket events from Server and updates the redux state accordingly
// All the UI dispatch interaction is handled in action.ts file.
// ###########################################################################################

// Reducers
export const videoCallSlice = createSlice({
  name: "chat-video-call",
  initialState,
  reducers: {
    unsetVideoCall: () => {
      return {
        ...initialState,
      };
    },
    callInvite: (state: IVideoCallState, action: PayloadAction<PartyInfo>) => {
      state.aParty = {
        ...state.aParty!,
        aPartyInfo: action.payload,
      };
    },

    sendReaction: (state: IVideoCallState, action: PayloadAction<any>) => {
      state.senderPartyReactionInfo = {...action?.payload,...animationRandomValues};
    },

    removeReaction: (state: IVideoCallState, action: PayloadAction<any>) => {
      state.senderPartyReactionInfo = action?.payload;
      state.recieverPartyReactionInfo = action?.payload;
    },

    receiveReaction: (state: IVideoCallState, action: PayloadAction<any>) => {
      state.recieverPartyReactionInfo = { ...state.aParty,...state.bParty, ...action.payload , ...animationRandomValues};
    },

    callRinging: (state: IVideoCallState, action: PayloadAction<any>) => {
      state.aParty = { ...state.aParty, ...action.payload };
    },

    newAudience: (state: IVideoCallState, action: PayloadAction<any>) => {
      state.newAudienceData = { ...state.newAudienceData, ...action.payload };
    },


    callCancel: (state: IVideoCallState, action: PayloadAction<any>) => {
      if (state.aParty?.callId === action.payload.callId) {
        return {
          ...initialState,
        };
      } else if (state.bParty?.callId === action.payload.callId) {
        return {
          ...initialState,
        };
      }
    },
    callCancelled: (state: IVideoCallState, action: PayloadAction<any>) => {
      return {
        ...initialState,
        userBusy: {
          isBusy: true,
          caller:
            state.aParty?.aPartyInfo?.id === action?.payload?.bPartyId
              ? state.aParty?.aPartyInfo
              : state.bParty?.aPartyInfo,
        },
      };

      if (state.bParty?.callId === action.payload.callId) {
        return {
          ...initialState,
          userBusy: {
            isBusy: true,
            caller: state.bParty?.aPartyInfo,
          },
        };
      } else if (state.aParty?.callId === action.payload.callId) {
        return {
          ...initialState,
          userBusy: {
            isBusy: true,
            caller: state.aParty?.aPartyInfo,
          },
        };
      }
    },
    callRing: (state: IVideoCallState, action: PayloadAction<any>) => {
      if (state.bParty?.callId) {
        // Silently ignore the incoming calls
        // if the user is already in 1:1 video calls.
      } else {
        state.bParty = action.payload;
      }
    },
    callAnswer: () => {},
    callCancelledClose: (
      state: IVideoCallState,
      action: PayloadAction<any>
    ) => {
      state.userBusy = undefined;
    },
    callDecline: () => {},
    callConnect: (state: IVideoCallState, action: PayloadAction<any>) => {
      // Chime connection
      state.chimeInvitation = action.payload;
      if (state.aParty?.callId) {
        state.aParty.callId = action.payload.callId;
      }
      if (state.bParty?.callId) {
        state.bParty.callId = action.payload.callId;
      }
    },
    callHangUp: () => {},
    callDisconnect: (state: IVideoCallState, action: PayloadAction<any>) => {
      if (state.chimeInvitation?.callId === action.payload.callId) {
        return {
          ...initialState,
        };
      }
    },
    setChimeInvitation: (
      state: IVideoCallState,
      action: PayloadAction<any>
    ) => {
      state.chimeInvitation = action.payload;
      if (state.aParty?.callId) {
        state.aParty.callId = action.payload.callId;
      }
      if (state.bParty?.callId) {
        state.bParty.callId = action.payload.callId;
      }
    },
  },
});

const {
  callInvite,
  callRing,
  callRinging,
  newAudience,
  callConnect,
  callCancel,
  callDecline,
  callHangUp,
  callCancelled,
  callDisconnect,
  setChimeInvitation,
  receiveReaction
} = videoCallSlice.actions;

// Read message and store Date into Redux states
export const handleVideoCall = (message: any) => async (dispatch: Dispatch) => {
  console.log('message123321', message);
  if (message.action === "call-ringing") dispatch(callRinging(message?.data));
  if (message.action === "edit-audience") dispatch(newAudience(message?.data));
  if (message.action === "live-receive-reaction") dispatch(receiveReaction(message?.data));
  if (message.action === "call-cancel") dispatch(callCancel(message?.data));
  if (message.action === "call-cancelled")
    dispatch(callCancelled(message?.data));
  if (message.action === "call-ring") dispatch(callRing(message?.data));
  if (message.action === "call-connect") dispatch(callConnect(message?.data));
  if (message.action === "call-disconnect")
    dispatch(callDisconnect(message?.data));
  if (message.action === "chime-invitation")
    dispatch(setChimeInvitation(message?.data));
};
