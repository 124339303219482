
export const getActivePage = (item: string, activeList: string[], inPersonEvent?: boolean) => {
    let updatedActiveList: string[];

    if (activeList.includes(item)) {
        updatedActiveList = activeList.filter((currentItem) => currentItem !== item);
    } else {
        updatedActiveList = [...activeList, item];
    }

    if(inPersonEvent){
        const inPersonPriorityIndex = ['latestCheckedIn', 'posts', 'agenda', 'audienceInsights'];
        return cleanAndPadListForInPerson(updatedActiveList, inPersonPriorityIndex, item);
    }

    const audienceIndex = updatedActiveList.indexOf('audience');

    if (updatedActiveList.length >= 2 && audienceIndex !== -1) {
        updatedActiveList.splice(audienceIndex, 1);
        updatedActiveList.splice(2, 0, 'audience');
    }

    const chatIndex = updatedActiveList.indexOf('chat');
    if (updatedActiveList.length >= 4 && chatIndex !== -1 && chatIndex !== 3) {
        updatedActiveList.splice(chatIndex, 1);
        updatedActiveList.splice(3, 0, 'chat');

        const audienceIndex = updatedActiveList.indexOf('audience');
        if (audienceIndex !== 2) {
            updatedActiveList.splice(audienceIndex, 1);
            updatedActiveList.splice(2, 0, 'audience');
        }

    }
    const priorityIndex = ["sessions", "chat", "match", "sponsors", "agenda"];
    return cleanAndPadList(updatedActiveList, priorityIndex, item);
    // return inPersonEvent? orderForInPersonList(updatedActiveList, inPersonPriorityIndex, item) : cleanAndPadList(updatedActiveList, priorityIndex, item);
};




function cleanAndPadListForInPerson(arr:any, priority:any, lastItem:any) {
    arr = arr.filter((item:any) => item != "audience");
    if(arr.filter((item:any) => item != "").length === 0){
        arr = ["latestCheckedIn"]
    }
    console.log("result now vbefore:: ", arr)
    let result = new Array(priority.length).fill("");
    arr.forEach((item:any) => {
        if (item !== "") {
            const index = priority.indexOf(item);
            if (index !== -1) {
                result[index] = item;
            } else {
                result.push(item);
            }
        }
    });
    while (result[result.length - 1] === "") {
        result.pop();
    }
    const lastPriorityIndex = result.lastIndexOf(
      (item:any) => priority.includes(item) && item !== ""
    );

    if (lastPriorityIndex !== -1) {
        const nonPriorityStart = result.findIndex(
          (item, index) => index > lastPriorityIndex && item !== ""
        );

        result = result.slice(0, lastPriorityIndex + 1).concat(
          result.slice(nonPriorityStart)
        );
    }
    console.log("result now vbefore:: v2 ", result)
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'chat', '', 'audienceInsights', 'posts']) ||
      JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'posts', '', 'audienceInsights', 'chat'])) {
        result = ['latestCheckedIn', 'chat', 'posts', 'audienceInsights'];
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'chat', 'agenda', 'audienceInsights', 'posts'])) {
        result = ['latestCheckedIn', (lastItem === 'audienceInsights' || lastItem === 'posts')? 'chat' : lastItem, 'posts', 'audienceInsights'];
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'posts', 'agenda', '', 'chat'])) {
        result = ['latestCheckedIn', 'posts', 'chat', 'agenda'];
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'posts', 'agenda', 'audienceInsights', 'chat'])) {
        result = ['latestCheckedIn', 'posts', lastItem == "audienceInsights"? 'agenda' : lastItem, 'audienceInsights'];
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', '', 'agenda', 'audienceInsights', 'chat'])) {
        result = ['latestCheckedIn', 'chat', 'agenda', 'audienceInsights'];
    }
    if (result[result.length] !== "audienceInsights" || result[result.length - 1] !== "agenda") {
        result = result.filter((item:any) => item !== "");
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'posts', 'audienceInsights'])) {
        result = ['latestCheckedIn', 'posts', '', 'audienceInsights'];
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'chat', 'audienceInsights'])) {
        result = ['latestCheckedIn', 'chat', '', 'audienceInsights'];
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'posts', 'agenda'])) {
        result = ['latestCheckedIn', 'posts', '', 'agenda'];
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'audienceInsights'])) {
        result = ['latestCheckedIn', '', '', 'audienceInsights'];
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'agenda', 'audienceInsights'])) {
        result = ['latestCheckedIn', 'agenda', '', 'audienceInsights'];
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', '', '', 'audienceInsights', 'chat'])) {
        result = ['latestCheckedIn', 'chat', '', 'audienceInsights'];
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'audienceInsights', 'chat'])) {
        result = ['latestCheckedIn', 'chat', '', 'audienceInsights'];
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'agenda', 'posts']) ||
      JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'posts', '', 'agenda'])) {
        result = ['latestCheckedIn', 'agenda', 'posts'];
    }
    if (JSON.stringify(result) === JSON.stringify(['latestCheckedIn', 'posts', 'posts', 'audienceInsights'])) {
        result = ['latestCheckedIn', 'chat', 'posts', 'audienceInsights']
    }
    console.log("result now::", result)
    return result;
}

function cleanAndPadList(arr:any, priority:any, lastItem:any) {
    let result = new Array(priority.length).fill("");
    arr.forEach((item:any) => {
        if (item !== "") {
            const index = priority.indexOf(item);
            if (index !== -1) {
                result[index] = item;
            } else {
                result.push(item);
            }
        }
    });
    while (result[result.length - 1] === "") {
        result.pop();
    }
    const lastPriorityIndex = result.lastIndexOf(
      (item:any) => priority.includes(item) && item !== ""
    );

    if (lastPriorityIndex !== -1) {
        const nonPriorityStart = result.findIndex(
          (item, index) => index > lastPriorityIndex && item !== ""
        );

        result = result.slice(0, lastPriorityIndex + 1).concat(
          result.slice(nonPriorityStart)
        );
    }
    console.log("result now::", result)
    return fillNonPriorityItems(result, priority, lastItem);
}


function fillNonPriorityItems(arr:any, priority:any, lastItem:any) {
    const nonPriorityItems:any[] = [];
    const result = arr.map((item:any) => {
        if (item !== "" && !priority.includes(item)) {
            nonPriorityItems.push(item);
            return "";
        }
        return item;
    });
    while (result[result.length - 1] === "") {
        result.pop();
    }
    result.reverse();
    for (let i = 0; i < result.length; i++) {
        if (result[i] === "" && nonPriorityItems.length > 0) {
            result[i] = nonPriorityItems.shift();
        }
    }
    result.reverse();
    let finalResult = result.concat(nonPriorityItems);
    console.log("result 33::", finalResult  )
    while (finalResult[finalResult.length - 1] === "") {
        finalResult.pop();
    }

    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', '', '', 'sponsors', 'agenda'])) {
        finalResult = ['sessions', '', 'agenda', 'sponsors'];
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', '', '', 'audience', 'agenda'])) {
        finalResult = ['sessions', '', 'audience', '', 'agenda'];
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', '', '', 'handouts', 'agenda'])) {
        finalResult = ['sessions', '', 'handouts', '', 'agenda'];
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', 'chat', '', '', 'agenda'])) {
        finalResult = ['sessions', '', 'chat', '', 'agenda'];
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', 'chat', '', 'audience', 'agenda'])) {
        finalResult = ['sessions', 'chat', 'audience', '', 'agenda'];
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', '', 'match', 'sponsors', 'agenda'])) {
        finalResult = ['sessions', 'agenda', 'match', 'sponsors'];
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', '', 'audience', 'sponsors', 'agenda'])) {
        finalResult = ['sessions', 'agenda', 'audience', 'sponsors'];
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', '', 'handouts', 'sponsors', 'agenda'])) {
        finalResult = ['sessions', 'agenda', 'handouts', 'sponsors'];
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', '', 'handouts', 'audience', 'agenda'])) {
        finalResult = ['sessions', 'audience', 'handouts', '', 'agenda'];
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', '', 'match', 'audience', 'agenda'])) {
        finalResult = ['sessions', 'audience', 'match', '', 'agenda'];
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', '', 'match', 'handouts', 'agenda'])) {
        finalResult = ['sessions', 'handouts', 'match', '', 'agenda'];
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', 'chat', 'audience', 'sponsors', 'agenda'])) {
        finalResult = ['sessions', 'chat', 'audience', 'sponsors'];
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', 'chat', '', 'sponsors'])) {
        finalResult = ['sessions', '', 'chat', 'sponsors'];
    }
    let removedItem="";
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', 'handouts', 'match', 'sponsors', 'agenda'])) {
        finalResult = ['sessions', (lastItem == "sponsors" || lastItem == "match") ? 'handouts' : lastItem, 'match', 'sponsors'];
        removedItem = 'handouts';
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', 'audience', 'match', 'sponsors', 'agenda'])) {
        finalResult = ['sessions', (lastItem == "sponsors" || lastItem == "match") ? 'audience' : lastItem, 'match', 'sponsors'];
        removedItem = 'audience';
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', 'handouts', 'match', 'sponsors', 'audience'])) {
        finalResult = ['sessions', (lastItem == "sponsors" || lastItem == "match") ? 'handouts' : lastItem, 'match', 'sponsors'];
        removedItem = 'handouts';
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', 'audience', 'match', 'sponsors', 'handouts'])) {
        finalResult = ['sessions', (lastItem == "sponsors" || lastItem == "match") ? 'audience' : lastItem, 'match', 'sponsors'];
        removedItem = 'audience';
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', 'chat', 'match', 'sponsors', 'audience'])) {
        finalResult = ['sessions', (lastItem == "sponsors" || lastItem == "match") ? 'chat' : lastItem, 'match', 'sponsors'];
        removedItem = 'chat';
    }
    if (JSON.stringify(finalResult) === JSON.stringify(['sessions', 'chat', 'match', 'audience', 'agenda'])) {
        finalResult = ['sessions', (lastItem == "agenda" || lastItem == "match" || lastItem == "sponsors") ? 'agenda' : lastItem, 'match', lastItem === "sponsors"? lastItem :'agenda'];
        removedItem = 'chat';
    }

    // ['sessions', 'chat', 'match', 'audience', 'agenda']

    let finalResult2 = finalResult;
    if(removedItem !== "" && finalResult?.filter((item:any) => item !== "").length >= 4){
        handleAgenda(finalResult, "agenda", removedItem);
    }


    while (finalResult2.filter((item:any) => item !== "").length > 4) {
        let priorityList:string[] = ["sessions", "match", "sponsors"];
        priorityList.unshift(lastItem);
        const indexToRemove = finalResult2.findIndex(
          (item:any) => item !== "" && !priorityList.includes(item)
        );

        if (indexToRemove !== -1) {
            console.log("if")
            finalResult2.splice(indexToRemove, 1);
        } else {
            console.log("else")
            finalResult2.pop();
        }
    }

    if(finalResult2.includes("sponsors")) {
        finalResult2 = finalResult2.filter((item:any) => item !== "sponsors");
        finalResult2.push("sponsors");
    }

    if(finalResult2.includes("agenda") && !(finalResult2.includes("sponsors"))) {
        finalResult2 = finalResult2.filter((item:any) => item !== "agenda");
        finalResult2.push("agenda");
    }

    if(finalResult2.includes("agenda") && finalResult?.filter((item:any) => item !== "").length == 2){
        console.log("target!", finalResult2)
        finalResult2 = finalResult2.filter((item:any) => item !== '');
    }

    if(finalResult2.includes("agenda") && !finalResult2.includes("sponsors") && finalResult?.filter((item:any) => item !== "").length == 3){
        finalResult2 = finalResult2.filter((item:any) => item !== '');
    }

    if(!finalResult2.includes("agenda") && !finalResult2.includes("sponsors") && finalResult?.filter((item:any) => item !== "").length == 4){
        const priorityIndex = ["sessions", "match", "audience"];
        // @ts-ignore
        function sortArray(arr:any) {
            return [...priorityIndex.filter(item => arr.includes(item)), ...arr.filter((item:any) => !priorityIndex.includes(item))];
        }
        finalResult2 = sortArray(finalResult2);
    }

    return handleAudienceNetworkTab(finalResult2, lastItem, removedItem);
}

function handleAgenda(array:any, lastItem:any, removedItem:any) {
    console.log("handleAgenda 1", array)
    if (lastItem === "agenda") {
        const agendaCount = array.filter((item:any) => item === "agenda").length;

        if (agendaCount === 2) {
            const firstIndex = array.indexOf("agenda");
            if (firstIndex !== -1) {
                array[firstIndex] = removedItem; // Replace with your desired value
            }
            console.log("handleAgenda 11", array)
        } else {
            if (!array.includes("agenda")) {
                array.push("agenda");
            }
        }
    }
    console.log("handleAgenda 2", array)

    return array;
}

function handleAudienceNetworkTab(array:any, lastItem:any, removedItem:any) {
    console.log("handleAudienceNetworkTab 1", array)
    if(!array.includes("sponsors") && !array.includes("agenda") && array.includes("audience")) {
        console.log("handleAudienceNetworkTab 2", array);
        const index = array.indexOf('audience');
        array.splice(index, 1); // Remove 'audience' from its current position
        array.splice(1, 0, 'audience'); // Insert 'audience' at the second index
    }

    console.log("handleAudienceNetworkTab 3", array)

    return array;
}

