import { Dispatch } from '@reduxjs/toolkit';
import { interactiveEventSlice } from '..';
import { _expoWebSocket } from "../../../utils/hooks";

// WebSocket Template
const sendWsMessage = (message: any) => {
  const webSocket = _expoWebSocket;
  return webSocket && webSocket.then((ws) => ws.send(JSON.stringify(message))).catch(console.error);
}

export const InteractiveAttendeeJoin = (eventId: string, eventRoomId: string) => async (dispatch: Dispatch) => {
  sendWsMessage({
    action: 'interactive/attendee-join',
    data: { eventId, eventRoomId }
  });
  dispatch(interactiveEventSlice.actions.attendeeJoin({eventId, eventRoomId}));
}

export const InteractiveAttendeeRaiseHand = (eventId: string, eventRoomId: string) => async (dispatch: Dispatch) => {
  sendWsMessage({
    action: 'interactive/raise-hand',
    data: { eventId, eventRoomId }
  });
  dispatch(interactiveEventSlice.actions.attendeeRaisehand());
}

export const InteractiveAttendeeReadToGoLive = (eventId: string, eventRoomId: string, userId: string) => async (dispatch: Dispatch) => {
  sendWsMessage({
    action: 'interactive/ready-to-go-live',
    data: { userId, eventId, eventRoomId }
  });
  dispatch(interactiveEventSlice.actions.attendeeReadyToGoLive());
}

export const InteractiveResetAttendeeInviteToJoin = () => async (dispatch: Dispatch) => {
  dispatch(interactiveEventSlice.actions.resetAttendeeInviteToJoin())
}

export const InteractiveResetForceMute = () => async (dispatch: Dispatch) => {
  dispatch(interactiveEventSlice.actions.resetAttendeeForceToMute())
}

