import { CardMedia, Divider, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

// Components
import Image from 'src/mui/components/image';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { IEvent } from 'src/models/event/event';
import {
  format,
  parseISO,
} from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

// Images
// import BannerImage from "./Images/BannerImage.jpg"
import PrimaryBanner from "./Images/PrimaryBanner.jpg"
import { getMinifiedImage } from 'src/mui/utils/image';
import SecondaryBanner from "./Images/SecondaryBanner.jpg"
// import DateImage from "./Images/DateImage.png"
import TimeImage from "./Images/TimeImage.png"
import LocationImage from "./Images/LocationImage.png"
import LocationSvg from './Images/LocationSvg';
import DateSvg from './Images/DateSvg';
import TimeSvg from './Images/TimeSvg';
import LocationLenovo from "./../../../assets/images/location-lenovo.png"
import ClockLenovo from "./../../../assets/images/clock-lenovo.png"
import ClanderLenovo from "./../../../assets/images/Clander-lenovo.png"
import mobileCoverLenovo from "./Images/mobileCoverLenovo.jpg"


type BannerProps = {
  coverUrl: string;
}

export const Banner = ({ coverUrl }: BannerProps) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);

  const [_innerWidth, setInnerWidth] = useState(window.innerWidth);

  const startTime = event?.startTime;
  const endTime = event?.endTime;

  if (!startTime) {
    return null;
  }
  if (!endTime) {
    return null;
  }

  const formattedTime = parseISO(startTime);
  const formattedEndTime = parseISO(endTime);

  const getFormattedTime = format(formattedTime, 'hh:mm a');
  const getFormattedEndTime = format(formattedEndTime, 'hh:mm a');

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const iconsSize = useMemo(() => {
    if (innerWidth > 1370) return 55
    if (innerWidth > 1200) return 45
    if (innerWidth > 992) return 35
    if (innerWidth > 768) return 30
    if (innerWidth > 600) return 20
    return 30
  }, [innerWidth])

  const iconsFontSize = useMemo(() => {
    if (innerWidth > 1370) return "15px"
    if (innerWidth > 1200) return "13px"
    if (innerWidth > 992) return "11px"
    if (innerWidth > 768) return "9px"
    if (innerWidth > 600) return "7px"
    return "10px"
  }, [innerWidth])

  const positionLeft = useMemo(() => {
    if (innerWidth > 1500) return "95px"
    if (innerWidth > 1370) return "80px"
    if (innerWidth > 1200) return "65px"
    if (innerWidth > 992) return "54px"
    if (innerWidth > 768) return "40px"
    if (innerWidth > 600) return "30px"
    return "43px"
  }, [innerWidth])

  const positionBottom = useMemo(() => {
    if (innerWidth > 1500) return "25px"
    if (innerWidth > 1370) return "15px"
    if (innerWidth > 1200) return "15px"
    if (innerWidth > 992) return "11px"
    if (innerWidth > 768) return "11px"
    if (innerWidth > 600) return "8px"
    return "20px"
  }, [innerWidth])


  const locationMaxWidth = useMemo(() => {
    if (innerWidth > 2600) return "720px"
    if (innerWidth > 2000) return "400px"
    if (innerWidth > 1800) return "330px"
    if (innerWidth > 1500) return "200px"
    if (innerWidth > 1370) return "190px"
    if (innerWidth > 1200) return "180px"
    if (innerWidth > 992) return "150px"
    if (innerWidth > 768) return "125px"
    if (innerWidth > 600) return "80px"
    return "230px"
  }, [innerWidth])


  return (
    <Stack sx={{ position: "relative" }}>
      <Image
        src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${coverUrl}`}
        alt={coverUrl}
        sx={{
          display: { md: "block", lg: "block", xl: "block", sm: 'block', xs: "none" },
        }}
      />



      <Image
        src={mobileCoverLenovo}
        alt={mobileCoverLenovo}
        sx={{
          display: { md: "none", lg: "none", xl: "none", sm: 'none', xs: "block" },
        }}
      />

      <CardMedia
        src={getMinifiedImage(event?.logo!)}
        component={'img'}
        sx={{

          position: "absolute",
          left: positionLeft,
          top: "10%",
          width: "100%",
          maxWidth: isSmallScreen ? "35%" : "25%"
        }}
      />


      <Stack direction="row" alignItems="center" sx={{ position: "absolute", left: positionLeft, bottom: positionBottom, color: "#4D134A" }}>
        <Stack direction="row" spacing="6px" justifyContent="center" alignItems="center">
          <img src={ClanderLenovo} style={{ height: iconsSize, width: iconsSize, stroke: "#4D144A" }} />
          <Typography
            variant="body2"
            noWrap
            sx={{
              // whiteSpace: 'initial',
              fontSize: iconsFontSize,
              // mb: '1rem',
              color: '#4d144a',
              fontFamily: 'Poppins-700'
            }}
          >
            {event?.startTime &&
              format(
                parseISO(event?.startTime),
                "d MMM yyyy"
              )}
          </Typography>
        </Stack>

        <Divider orientation="vertical" sx={{ mx: 1, height: iconsSize, border: "1px solid #550D4B", '@media (max-width: 768px)': { mx: '6px' } }} />

        <Stack direction="row" spacing="6px" justifyContent="center" alignItems="center">
          {/* <TimeSvg style={{ height: iconsSize, width: iconsSize, stroke: "#4D144A" }} /> */}
          <img src={ClockLenovo} style={{ height: iconsSize, width: iconsSize, stroke: "#4D144A" }} />

          <Typography
            variant="body2"
            noWrap
            sx={{
              // whiteSpace: 'initial',
              fontSize: iconsFontSize,
              // mb: '1rem',
              color: '#4d144a',
              fontFamily: 'Poppins-700'
            }}
          >
            {getFormattedTime} - {getFormattedEndTime}
          </Typography>
        </Stack>

        <Divider orientation="vertical" sx={{ mx: 1, height: iconsSize, border: "1px solid #4D134A", '@media (max-width: 768px)': { mx: '6px' } }} />

        <Stack direction="row" spacing="6px" justifyContent="center" alignItems="center">
          {/* <LocationSvg style={{ height: iconsSize, width: iconsSize, fill: "#4D144A" }} /> */}
          <img src={LocationLenovo} style={{ height: iconsSize, width: iconsSize, stroke: "#4D144A" }} />

          <Tooltip title={event?.locationName! || ""}>
            <Typography
              variant="body2"
              noWrap
              sx={{
                whiteSpace: 'inherit',
                fontSize: iconsFontSize,
                // mb: '1rem',
                color: '#4d144a',
                fontFamily: 'Poppins-700',
                // overflow: "hidden",
                // textOverflow: "ellipsis",
                maxWidth: locationMaxWidth
              }}
              className='ellipsis-two-line'
            >
              {event?.locationName! || "here is the lenovo location of the current event happening"}
            </Typography>
          </Tooltip>
        </Stack>
      </Stack>

    </Stack>
  );
};
