import { Reducer } from 'redux';

import { IPostImageState } from '../../../models/post/PostImageState';
import { EventPostImageActions } from './actions';
import { EventPostImageActionTypes } from './types';

const initialState: IPostImageState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const SetPostImageReducer: Reducer<IPostImageState, EventPostImageActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case EventPostImageActionTypes.SET_POST_IMAGE: {
      return {
        ...state,
        value: action.url
      };
    }

    case EventPostImageActionTypes.POST_IMAGE_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
