import {
  Box,
  Stack,
  TextField
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { CompanyItemCard } from './CompanyItemCard';
import { NoCompanyItems } from './no-company-items';

type Props = {
  companyList: any;
};

const CompanyAccountListModal = ({
  companyList,
}: Props) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredCompanyList = companyList.filter((item: any) => item.name.toLowerCase().includes(searchQuery));

  const onGotoCompany = (slug: string) => {
    navigate(`/wall/company/${slug}`);
  };

  return (
    <Stack sx={{ p: '20px' }}>
      <Stack sx={{
        mb: '15px', pb: '10px', fontWeight: 500, borderBottom: '1px solid #d1d5db'
      }}
      >
        Companies List
        {' '}
        {`(${companyList?.length})`}
      </Stack>
      <Box sx={{ mb: '15px' }}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Search by company name"
          onChange={handleSearchChange}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '31px !important',
              padding: '0',
              backgroundColor: 'white',
              borderRadius: '5px',
              fontSize: '13px',
              // color: '#666666'
            },
            '& .MuiOutlinedInput-input': {
              height: '31px !important',
              padding: '0 10px !important',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#cddcf2 !important'
            }
          }}
        />
      </Box>
      <Stack sx={{ maxHeight: '385px', overflowY: 'auto' }}>
        {filteredCompanyList.map((item: any) => (
          <CompanyItemCard
            avatarClass="p-[0px] w-[40px] rounded-[4px] h-[40px] object-contain bg-[#edf2f5]"
            avatarDefaultClass="p-[3px] w-[40px] rounded-[4px] h-[40px] object-contain bg-[#edf2f5]"
            containerClass="mb-[15px] border-b-1 border-[#CDDCF2] py-0 pb-3"
            avatarUrl={item?.logo}
            title={item?.name}
            subTitle={item?.industry?.name}
            duration={item?.logo}
            location={item?.headquarte}
            detailsHref={item?.slug}
            onCompanyClick={onGotoCompany}
          />
        ))}
        {!filteredCompanyList.length ? <NoCompanyItems /> : null}
      </Stack>
    </Stack>
  );
};

export default CompanyAccountListModal;
