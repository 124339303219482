import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JOIN_TO_EVENT } from "src/constants";
import { CalendarList } from "src/screens/event/registration/event-components/calendar-list";
import { GotoList } from "src/screens/event/registration/event-components/goto-list";
import { QrService } from "src/services/QrService";
import { IAppState } from "src/store";
import { getUpcomingEvent } from "src/store/events/upcoming-event/actions";
import { useCurrentUser } from "src/utils/hooks";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Template } from "./pdf-template";
import RegistrationUnderReview from "./RegistrationUnderReview";
import useStyling from "src/mui/components/hook-form/useStyling";
import "./MobileToolBar.css"

interface RegisteredCodeFormProps {
  eventId?: string;
  gotoHide?: boolean;
}
export const RegisteredCodeForm = ({
  eventId,
  gotoHide,
}: RegisteredCodeFormProps) => {
  const dispatch = useDispatch();
  const { secondaryFontColor, secondaryBackgroundColor, primaryBackgroundColor } = useStyling(null, null)
  const user = useCurrentUser();
  const [qrImage, setQrImage] = useState("");
  const [loadingQrImage, setLoadingQrImage] = useState(false);
  const event = useSelector(
    (state: IAppState) =>
      state.website.event || state.upcomingEvent?.value?.data?.event
  );
  const { audienceStatus } = useSelector(
    (state: IAppState) =>
      state.upcomingEvent?.value?.data?.attendeeStatus! ?? ""
  );

  useEffect(() => {
    if (event?.id && user?.id && audienceStatus === "accepted") {
      setLoadingQrImage(true)
      const qrService = new QrService();
      qrService
        .getQrCode(event.id, user.id)
        .then((res: string) => { setQrImage(res); setLoadingQrImage(false) }).catch((error) => setLoadingQrImage(false));
    }
  }, [event, user, audienceStatus]);

  // useEffect(() => {
  //   if (event?.id) {
  //     dispatch(getUpcomingEvent(event?.id));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [event?.id]);



  // if (!event) return null;

  return (
    <Stack
      sx={{
        direction: "row",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
      spacing={2}
      rowGap={4}
    >
      <Box>
        {((user && audienceStatus === "accepted" && event?.type === "invitation") || (user && event?.type === "open")) && (
          <>
            <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
              <Box sx={{ width: "200px", height: "200px", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {loadingQrImage ? <CircularProgress color="info" /> : <img src={qrImage} alt="Close" />}
              </Box>

              <Typography sx={{ width: "400px", textAlign: "center", color: secondaryFontColor }}>
                {event?.confirmationMessage && (
                  <>{event?.confirmationMessage}</>
                )}
              </Typography>
              <Stack sx={{
                display: "flex", gap: 2, flexDirection: "row", alignItems: "center", justifyContent: "center",
                '@media (max-width: 768px)': {
                  // height: "650px",
                  flexDirection: "column"
                  // height: "100%"
                  // minHeight: "100%",
                },
              }} >
                <>
                  {!gotoHide && (
                    <GotoList
                      fillColor="white"
                      titleClass="text-[13px] font-semibold text-white tracking-wide mr-[10px]"
                      btnClassName="text-white bg-[`#0049EF] rounded-10 h-[30px] px-[15px] flex items-center"
                      // buttonTheme={{ backgrroundColor: `${primaryBackgroundColor} !important` }}
                      title="Go to"
                      dropdownClass="w-[113px]"
                    />
                  )}

                  <CalendarList
                    fillColor="white"
                    titleClass="text-[13px] font-semibold text-white tracking-wide mr-[10px]"
                    btnClassName="text-white bg-[#0049EF] rounded-10 h-[30px] px-[15px] flex items-center"
                    title="Add to calendar"
                  />

                  <PDFDownloadLink
                    document={
                      <Template
                        pdfData={{
                          qrImage,
                          message: JOIN_TO_EVENT.REGISTERED,
                          organizerMessage: event?.confirmationMessage,
                          eventName: event?.name,
                          location: event?.eventRegions[0]?.name,
                          dateTime: `${`${event?.startTime} ${event?.timezone}`}`,
                        }}
                      />
                    }
                    fileName={`${event?.name}.pdf`}
                  >
                    {({ loading }) =>
                      loading ? (
                        "Loading pdf..."
                      ) : (
                        <button
                          type="button"
                          className="text-white bg-[#0049EF] rounded-10 h-[30px] px-[15px] flex items-center"
                          style={{ backgroundColor: primaryBackgroundColor }}
                        >
                          <span className="text-[13px] font-semibold text-white tracking-wide]">
                            Download PDF
                          </span>
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                </>
              </Stack>
            </Stack>
          </>
        )}
        {user &&
          event?.type === "invitation" &&
          audienceStatus !== "accepted" && (
            <>
              <RegistrationUnderReview />
            </>
          )}
      </Box>
    </Stack >
  );
};

export default RegisteredCodeForm;
