import { FC, useState, useEffect } from "react"

import './rangeSlider.css';

export interface RangeSliderProps {
  name?: string;
  min: string;
  max: string;
  initilaValue: number;
  onChange?: (value: number) => void;
}

export const RangeSlider: FC<RangeSliderProps> = ({ name, min, max, initilaValue, onChange }) => {
  const [value, setValue] = useState<number>(initilaValue || 0);

  useEffect(() => {
    const target: any = document.getElementById(`myRange-${name}`);
    target.style.backgroundSize = (value - Number(min)) * 100 / (Number(max) - Number(min)) + '% 100%';
    
    const timeout = setTimeout(() => {
      onChange && onChange(value);
    })
    return () => clearTimeout(timeout)
  }, [value])

  return (
    <div className="w-full flex items-center bg-gray-1 py-3 px-4 rounded-full range-slider">
      <span className="font-semibold text-sm6 text-blue-3 mr-4">{name}</span>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        className="slider"
        id={`myRange-${name}`}
        aria-label="range-slider-target"
        onChange={(e:any) => setValue(e.target.value)}
      />
    </div>
  );
};
