/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button, Stack, Tooltip, Typography
} from '@mui/material';
import { CompanyService } from 'src/services';
import { toast } from 'react-toastify';
import { Loader } from 'src/shared-components/Loader';
import PMapFieldSelect from '../PMapFieldSelect';

interface Props {
  values:any;
  setValue:any
}

export const BestMatch = ({ values, setValue }:Props) => {
  const [companiesBestMatch, setCompaniesBestMatch] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetchCompaniesBestMatch = async () => {
    const companyService: CompanyService = new CompanyService();
    setIsLoading(true);
    try {
      const companiesResponse: any = await companyService.postCompanieBestMatch(values?.csvFromatedData);
      const data:any = companiesResponse?.data;
      const bestMatchOptions: any = {};

      data?.forEach((item:any) => {
        const matchedCompanies = item?.matchedCompanies;
        const options: any[] = [];

        matchedCompanies?.forEach?.((matchCompany: any) => {
          if (matchCompany && Object.keys(matchCompany)?.length) {
            options.push({
              id: matchCompany?.id,
              name: matchCompany?.name,
              website: matchCompany?.website,
              logo: matchCompany?.logo,
            });
          }
        });

        bestMatchOptions[item?.name] = options;
        if (options?.length) {
          setValue(`${item?.name}-companyName`, options?.[0] || {
            id: item?.id,
            name: item?.name,
            website: item?.website,
            logo: item?.logo,
          });
        }
      });
      setIsLoading(false);

      setCompaniesBestMatch(bestMatchOptions);
    } catch (error:any) {
      setIsLoading(false);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchCompaniesBestMatch();
  }, []);

  return (
    <Stack>
      <Stack sx={{ mb: '30px' }}>
        <Typography className="requirment-title">
          Best Match Suggestions
        </Typography>
      </Stack>
      <Stack
        className="custom-scroll"
        sx={{
          pl: '40px',
          overflowY: 'auto',
          overflowX: 'visible',
          maxHeight: 'calc(100vh - 430px)',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#0031FF',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#385cf4',
          },
        }}
      >
        {
          isLoading ? <Loader /> : !isLoading && !companiesBestMatch ? 'Something went wrong! try again'
            : companiesBestMatch ? Object.keys(companiesBestMatch)?.map((key:any) => (
              <Box
                key={key}
                sx={{
                  display: 'flex', justifyContent: 'flex-start', mb: '20px', gap: '10px'
                }}
              >
                <Tooltip title={key}>
                  <Button
                    variant="contained"
                    sx={{
                      width: '250px',
                      borderRadius: '5px',
                      backgroundColor: '#ffffff',
                      justifyContent: 'flex-start',
                      '&:hover': {
                        backgroundColor: '#0031FF',
                        '& .MuiTypography-root': {
                          color: '#ffffff',
                        },
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      className="mapfield-btn ellipsis-one-line"
                    >
                      {key}
                    </Typography>
                  </Button>
                </Tooltip>
                <Stack sx={{ width: 'calc(100% - 420px)' }}>
                  <PMapFieldSelect
                    name={`${key}-companyName`}
                    placeholder="Select Company"
                    className="dropdown-container mapfield-dropdown best-match-drop"
                    options={companiesBestMatch?.[key]}
                    isBestMatch
                  />
                </Stack>
              </Box>
            )) : null
}
      </Stack>
    </Stack>
  );
};

export default BestMatch;
