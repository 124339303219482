import { useDispatch } from 'react-redux';
import { IEventSession } from '../../../models/event/event-session';

import { IRoom } from '../../../models/event/room';
import { Avatar } from '../../../shared-components/V2';

import {
  rewatchSession, setActiveRoom, setActiveSession,
} from '../../../store/liveEvent';
import { getFormattedTime } from '../../../utils/datetime';
import { ParseContent } from '../../../utils/parse-html';

type SessionsProps = {
  days: IRoom[];
}

const Sessions = (props: SessionsProps) => {
  const renderSessionSpeaker = (speaker: any, count: number) => {
    const {
      firstName, lastName, company, jobTitle, photo
    } = speaker;
    return (
      <div key={count} className="flex h-16 py-2 my-2">
        <div>
          <Avatar alt="avatar" className="h-full rounded-full border-1 border-blue-200" src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${photo}`} />
        </div>
        <div className="flex-1 flex-col">
          <p>
            {firstName}
            {' '}
            {lastName}
          </p>
          <p className="text-sm">
            {jobTitle}
            {' '}
            at
            {' '}
            {company.name}
          </p>
        </div>
      </div>
    );
  };

  const renderDayJSX = (day: IRoom, onRewatchClick: Function) => (
    <>
      <h1 className="px-8 py-2 bg-blue text-white text-md rounded-xl mb-2 ">{day.name}</h1>
      {day.sessions.map((session: IEventSession, index: number) => (
        <div key={index} className="bg-gray-200 p-4 mb-2 rounded-xl">
          <p className="font-bold text-md">
            {getFormattedTime(session.startTime)}
            {' '}
            -
            {getFormattedTime(session.endTime)}
          </p>
          <p className="font-bold text-xs">{session.title}</p>
          <p className="mt-4 mb-4 text-md"><ParseContent content={session.summary} /></p>

          {session?.eventSpeakers?.length > 0
            && <p className="text-xs">Session Speakers</p>}

          {session?.eventSpeakers?.map((item: any, count: number) => (
            <>
              {renderSessionSpeaker(item, count)}
            </>
          ))}
          {
            (Date.parse(session.endTime) < Date.now()) && (
            <button
              type="button"
              className="bg-yellow-500 text-dark-blue text-sm py-2 px-4 rounded-15 font-semibold float-right -mt-8"
              onClick={() => onRewatchClick(session, day)}
            >
              Rewatch
            </button>
            )
          }
        </div>
      ))}
    </>
  );

  const dispatch = useDispatch();

  const onRewatchClick = (session: IEventSession, sessionRoom: IRoom) => {
    dispatch(rewatchSession(session.id));
    dispatch(setActiveRoom(sessionRoom));
    dispatch(setActiveSession(session));
  };

  return (
    <div className="p-4 h-[600px] overflow-y-scroll">
      {props.days.map((item: IRoom, count: number) => (
        <div key={count}>{renderDayJSX(item, onRewatchClick)}</div>
      ))}
    </div>
  );
};

export default Sessions;
