import { SVGProps } from 'react';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

function ModuleBoxLinesIcon(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props;
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29.5" height="19.5" viewBox="0 0 29.5 19.5">
            <g id="Group_21488" data-name="Group 21488" transform="translate(-9 -450.928)">
                <path id="Path_27162" data-name="Path 27162" d="M0,0V19.5" transform="translate(30.5 450.928)" fill="none" stroke={defaultColor} stroke-width="1"/>
                <rect id="Rectangle_8461" data-name="Rectangle 8461" width="19" height="19" rx="2" transform="translate(9 451.428)" fill={defaultColor} opacity="0.243"/>
                <rect id="Rectangle_8462" data-name="Rectangle 8462" width="5.5" height="19" rx="2" transform="translate(33 451.428)" fill={defaultColor} opacity="0.144"/>
            </g>
        </svg>
    );
}

export default ModuleBoxLinesIcon;
