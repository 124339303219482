// PlusSvgIcon.tsx
import React from 'react';

interface MinusSvgIconProps {
  className?: string;
  style?: React.CSSProperties;
}

const MinusSvgIcon: React.FC<MinusSvgIconProps> = ({ className, style }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" className={className} style={style}>
    <g id="Group_21875" data-name="Group 21875" transform="translate(0 0)">
      <line id="Line_1740" data-name="Line 1740" x2="24" transform="translate(0 12)" fill="none" stroke="#fff" strokeWidth="3" />
    </g>
  </svg>
);

export default MinusSvgIcon;
