import { FC } from 'react';
import { Avatar } from '../Avatar';
import { Button } from '../Button';

import classNames from 'classnames';
import { Logo } from '../Logo';
import { ReactComponent as AddStoryIcon } from '../../../assets/images/add-new-story-icon.svg';
import userDefaultAvatar from '../../../assets/images/avatar-default.svg';

export interface ICardProfileConnectProps {
  id: string;
  firstName: string;
  lastName: string;
  slug?: string
  avatar?: string;
  cover: string;
  jobTitle?: string;
  companySlug?: string | undefined;
  [location: string]: any
  onClickConnect?: Function;
  onClickMessage?: Function;
  onUserClick?: Function;
  onCompanyClick?: Function;
  className?: string;
  isOwnProfile?: boolean;
  logoClass?: string;
  mutualConnections?:number;
  addNewStory?:boolean
  isEmptyStory?: boolean;
}

export const StoryProfileConnect: FC<ICardProfileConnectProps> = ({
  id,
  firstName,
  lastName,
  slug,
  companySlug,
  avatar,
  cover,
  jobTitle,
  location,
  onClickConnect,
  onClickMessage,
  onUserClick,
  onCompanyClick,
  className,
  isOwnProfile,
  logoClass,
  mutualConnections,
  addNewStory,
  isEmptyStory
}) => {
  return (
    <>
    
    
    <div
      onClick={() => onClickConnect!(id)}
      className={classNames("bg-white",
        {[`${className}`]: className},
      )}
    >
      { addNewStory ? (
          <Logo
            className={classNames("rounded-[8px 8px 0px 0px] object-cover h-[183px] w-full", {
              hidden: isEmptyStory,
              'bg-[#edf2f5]': !avatar,
            })}
            src={avatar ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${avatar}` : userDefaultAvatar} alt="avatar" 
          />
        ) : <Logo className="object-cover h-[100%] w-full" src={cover} alt="cover" />
      }
      { !addNewStory && <div
        className='absolute top-[10px] left-[10px] z-30'
        onClick={() => onUserClick!(`/wall/${slug}`)}
        aria-label="card-profile-avatar"
      >
        <Avatar
          alt={`${firstName} ${lastName}`}
          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${avatar}`}
          className={classNames("border-[3px] border-[#3C14E6] !rounded-[13px] !w-[40px] !h-[40px]", {[`${logoClass}`]: logoClass})}
          // size="LARGE"
        />
      </div>}

   {addNewStory  ?
    <div
      className={classNames("text-center flex flex-col items-center justify-center cursor-pointer -mt-[15px]", {
        '!-mt-[0px]': isEmptyStory,
      })}
    >
      <div className="mb-0"><AddStoryIcon /></div>
      {!isEmptyStory && (
        <span
          className="cursor-pointer"
          // onClick={() => onUserClick!(`/wall/${slug}`)}
          aria-label="card-profile-name"
        >
          <div className="line-clamp-1 text-[13px] leading-[19px] font-[500] text-black capitalize mt-[5px]">Add Story</div>
        </span>
      )}
      
   </div> :   
    <div className="absolute bottom-[12px] left-[10px] z-20">
      <span

        className="cursor-pointer"
        onClick={() => onUserClick!(`/wall/${slug}`)}
        aria-label="card-profile-name"
      >
        <div className="line-clamp-1 text-[13px] h-[19px] font-[500] text-white capitalize">{`${firstName} ${lastName}`}</div>
      </span>

    </div>}
    {!addNewStory && (
      <div className="h-full w-full absolute left-0 top-0">
        <div className="h-full w-full bg-gradient-to-t from-black to-transparent" />
      </div>
    )}
    
      {/* <div className="text-center pb-2">
        {!isOwnProfile && onClickConnect && <Button
          className="text-white h-[31px] w-[98px] bg-[#0049EF] hover:bg-[#2e6eff]"
          type="button"
          size="small"
          text="Connect"
          onClick={() => onClickConnect!(id)}
        />}
        {!isOwnProfile && onClickMessage && <Button
          className="ml-3 bg-gray-200 text-blue"
          type="button"
          size="small"
          text="Message"
          onClick={() => onClickMessage(id)}
        />}
      </div> */}
    </div>
    {isEmptyStory && (
      <div className='flex flex-col'>
        <div className='cursor-pointer text-[15px] text-[#000000] font-semibold capitalize line-clamp-1 leading-[21px]'>Create Story</div>
        <div className='cursor-pointer text-[14px] font-normal text-[#3C3C3C] leading-[20px] flex line-clamp-1'>Share your photos, videos, and unforgettable moments.</div>
      </div>
    )}
    
    </>
  );
};
