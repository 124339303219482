import { useSurveyContext } from "src/mui/providers/GeneralContext";
import PAutocomplete from './PAutocomplete';
import { useEffect } from "react";

type OptionType = {
  id: string;
  name: string;
};

type PJobFunctionProps = {
  name: string;
  label?: any;
  disabled?: boolean;
  defaultValue?: OptionType;
  placeholder?: any;
  multiple?: boolean;
  sx?: any;
  className?: string;
};

const PJobFunctionSelect = ({ name, label, disabled = false, defaultValue, placeholder, multiple = false, sx ,className}: PJobFunctionProps) => {
  const { jobFunctions, getJobFunctions } = useSurveyContext();
  useEffect(() => {
    getJobFunctions();
  }, []);
  return (
    <PAutocomplete
      onlySelectFromDropdown
      className={className}
      sx={sx}
      label={label}
      placeholder={placeholder}
      name={name}
      multiple={multiple}
      disabled={disabled}
      clearOnBlur
      fullWidth
      loading={jobFunctions === undefined}
      filterSelectedOptions
      {...(defaultValue && { defaultValue })}
      options={jobFunctions ?? []}
      getOptionLabel={(option: any | string) => (option as OptionType).name ?? option}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export default PJobFunctionSelect;
