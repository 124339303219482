/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
// Accordion.tsx
import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import classNames from 'classnames';
import AccordionHeader from './accordion-header';
import AccordionBody from './accordion-body';
// import UserDetails from './user-details';

interface SessionAccordionProps {
  title?: string;
  // children?: React.ReactNode;
  session?:any;
  isBreakoutSession?: boolean
  isBreakoutSession5?: boolean
}

const SessionAccordion: React.FC<SessionAccordionProps> = ({
  session, title, isBreakoutSession, isBreakoutSession5
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  // eslint-disable-next-line no-console
  const firstName = session?.eventSpeakers[0]?.firstName;
  const lastName = session?.eventSpeakers[0]?.lastName;
  const jobTitle = session?.eventSpeakers[0]?.jobTitle;
  const photo = session?.eventSpeakers[0]?.photo;
  const companyName = session?.eventSpeakers[0]?.company?.name;
  const sponsorship = session?.eventSponsors[0]?.sponsorship;
  const companyLogo = session?.eventSponsors[0]?.company?.logo;
  const sponsorCompanyName = session?.eventSponsors[0]?.company?.name;
  const industry = session?.eventSponsors[0]?.industry?.name;
  const userSlug = session?.eventSpeakers[0]?.user?.slug;
  const companyPage = session?.eventSponsors[0]?.company?.slug;

  const user = {
    name: firstName ? `${firstName} ${lastName}` : sponsorCompanyName,
    position: firstName ? jobTitle : industry,
    company: companyName,
    isSpeaker: !!firstName,
    slug: firstName ? `/wall/${userSlug}?tab=about` : `/wall/company/${companyPage}`
  };
  const sessionDetails = {
    time: `${format(parseISO(session?.startTime), 'hh:mm a')} - ${format(parseISO(session?.endTime), 'hh:mm a')}`,
    title: session?.title,
    description: session?.summary,
  };

  const getHeaderPicture = () => {
    if (firstName || companyLogo) {
      const picture = firstName ? photo : companyLogo;
      return `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${picture}`;
    }
    return session?.media;
  };

  const isEmptyPhoto = (session?.eventSpeakers?.length || session?.eventSponsors?.length);

  return (
    <div className={classNames(
      'w-full rounded-[8px] bg-[#14161A] mb-[10px]',
      {
        '!mb-0': isBreakoutSession5
      }
    )}
    >
      <AccordionHeader
        isOpen={isOpen}
        toggleAccordion={toggleAccordion}
        UserPicture={getHeaderPicture()}
        user={user}
        sessionDetails={sessionDetails}
        session={session}
        eventSponsors={session?.eventSponsors || []}
        speaker={session?.eventSpeakers[0]}
        isBreakoutSession5={isBreakoutSession5}
        isBreakoutSession={isBreakoutSession}
      />
      <div className={`overflow-hidden transition-all duration-300 ${isOpen ? 'rounded-b-[10px]' : 'max-h-0'}`}>
        <AccordionBody isBreakoutSession5={isBreakoutSession5} isBreakoutSession={isBreakoutSession} isOpen={isOpen} eventSpeakers={session?.eventSpeakers?.slice(1) || []} sessionDetails={sessionDetails} eventSponsors={session?.eventSponsors || []} isEmptyPhoto={isEmptyPhoto} />
        {/* {isBreakoutSession && isOpen && session?.eventSpeakers[0] ? (
          <div className="px-[23px] pb-[35px] bg-red-600">
            <UserDetails
              speaker={session?.eventSpeakers[0]}
              UserPicture={getHeaderPicture()}
              user={user}
              totalSpeakers={session?.eventSpeakers?.length}
              isBreakoutSession={isBreakoutSession}
              isOpen={isOpen}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};

export default SessionAccordion;
