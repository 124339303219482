import {
  useEffect,
  useState,
} from 'react';

import { useForm } from 'react-hook-form';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { JobFunctionSelector } from 'src/components/selectors/job-functions-selector';
import { CitySelector } from '../../../components/selectors/city-selector';
import { CompanySelectorNew } from '../../../components/selectors/company-selector-new';
import { IndustrySelector } from '../../../components/selectors/industry-selector';
import { JobTitleSelectorNew } from '../../../components/selectors/jobTitle-selector-new';
import { LOGIN_SIGNUP_RECOVERY } from '../../../constants';
import FormFieldWithCaption from '../../../layouts/form-field-with-caption';
import { IReactSelectOptionCompany } from '../../../models/company/company-react-select-option';
import { Typography } from '../../../shared-components';
import { IAppState } from '../../../store';
import { getCompanyProfilePreview } from '../../../store/company/profile-preview/actions';
import CoverPhotoAndAvatar from './cover-photo-and-avatar';

interface IEditUserProfileProps {
  coverPhoto: string;
  avatar: string;
  firstName: string;
  lastName: string;
  companyId: string;
  companyName: string;
  jobTitle: string;
  location: string;
  aboutMe: string;
  onUploadCoverPhoto: Function;
  onUploadAvatar: Function;
  onClose: Function;
  onSave: Function;
}

interface IEditUserProfile {
  coverPhoto: string;
  avatar: string;
  firstName: string;
  lastName: string;
  companyId: string;
  companyName: string;
  jobTitle: string;
  location: string;
  postCode: string;
  aboutMe: string;
  industryId: string;
  jobFunctionId: string;
}

const EditUserProfile = (props: IEditUserProfileProps) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IEditUserProfile>();

  const [citySelected, setCitySelected] = useState<any>(null);

  const [selectedCompanyStateToStore, setSelectedCompanyStateToStore] = useState<IReactSelectOptionCompany>();
  const [selectedIndustryStateToStore, setSelectedIndustryStateToStore] = useState<any>();
  const [selectedJobTitleStateToStore, setSelectedJobTitleStateToStore] = useState<any>();
  const [selectedJobFunctionStateToStore, setSelectedJobFunctionStateToStore] = useState<any>();

  const selector = useSelector((state: IAppState) => state);
  const company = selector.companyProfilePreview;
  const user = selector.userProfile;
  const { cityCountryPostCode } = selector.signupUser;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyProfilePreview(props.companyId));
  }, [dispatch, props.companyId, props.jobTitle]);

  useEffect(() => {
    if (company?.value) {
      setSelectedCompanyStateToStore({
        industry: company.value?.industry?.name!,
        key: company.value?.id!,
        label: company.value?.name!,
        logo: company.value?.logo!,
        value: company.value?.id!,
      });
    } else if (props.companyName) {
      setSelectedCompanyStateToStore({
        industry: '',
        key: props.companyId,
        label: props.companyName,
        logo: '',
        value: props.companyId,
      });
    }
  }, [company, props.companyId, props.companyName, setValue]);

  useEffect(() => {
    if (user) {
      setSelectedIndustryStateToStore({
        key: user.value?.industryId!,
        label: user.value?.industry?.name!,
        value: user.value?.industryId!,
      });
      setSelectedJobFunctionStateToStore({
        key: user.value?.jobFunctionId!,
        label: user.value?.jobFunction?.name!,
        value: user.value?.jobFunctionId!,
      });
    }
  }, [user, setValue]);

  useEffect(() => {
    setSelectedJobTitleStateToStore({ label: props.jobTitle });
  }, [props.jobTitle, setValue]);

  useEffect(() => {
    if (cityCountryPostCode) {
      setCitySelected(cityCountryPostCode.location!);
    }
  }, [cityCountryPostCode]);

  useEffect(() => {
    if (user) {
      setValue('companyId', user?.value?.companyId!);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (props.companyName) {
      setValue('companyName', props.companyName);
    }
    setValue('coverPhoto', props.coverPhoto);
    setValue('avatar', props.avatar);
    setValue('firstName', props.firstName);
    setValue('lastName', props.lastName);
    setValue('jobTitle', props.jobTitle);
    setValue('location', props.location);
    setValue('postCode', user?.value?.postCode!);
    setValue('industryId', user?.value?.industryId!);
    setValue('jobFunctionId', user?.value?.jobFunctionId!);
    setValue('aboutMe', props.aboutMe);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompanySelected = (selectedCompany: any) => {
    setValue('companyId', selectedCompany?.value);
    setValue('companyName', selectedCompany?.label);
    setSelectedCompanyStateToStore(selectedCompany);
  };

  const handleIndustrySelected = (selectedIndustry: any) => {
    setValue('industryId', selectedIndustry?.value);
    setSelectedIndustryStateToStore(selectedIndustry);
  };

  const handleJobFunctionSelected = (selectedJobFunction: any) => {
    setValue('jobFunctionId', selectedJobFunction?.value);
    setSelectedJobFunctionStateToStore(selectedJobFunction);
  };

  const handleJobTitleSelected = (selectedJobTitle: any) => {
    setValue('jobTitle', selectedJobTitle?.label);
    setSelectedJobTitleStateToStore(selectedJobTitle);
  };

  const onSubmit = (data: IEditUserProfile) => {
    const countryCity = data.location.split(',');

    const dataToSubmit: any = {
      about: data.aboutMe ?? '',
      city: countryCity[0],
      companyId: selectedCompanyStateToStore?.value,
      country: countryCity.length === 2 ? countryCity[1] : countryCity[2],
      firstName: data.firstName,
      lastName: data.lastName,
      jobTitle: selectedJobTitleStateToStore?.label,
      location: data.location,
      postCode: data.postCode,
      industryId: selectedIndustryStateToStore?.value,
      jobFunctionId: selectedJobFunctionStateToStore?.value

    };

    props.onSave(dataToSubmit);
    props.onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full">
        <div className="flex flex-col p-[16px]">
          <div className="text-[#0B1221] font-medium text-[18px] leading-[26px] border-b-1 border-[#CDDCF2] pb-[9px]">
            Edit your intro
          </div>
          {/* Cover photo and avatar */}
          <div className="mt-[13px]">
            <CoverPhotoAndAvatar
              coverPhotoUrl={props.coverPhoto}
              avatarUrl={props.avatar}
              isOwnProfile
              onUploadCoverPhoto={props.onUploadCoverPhoto}
              onUploadAvatar={props.onUploadAvatar}
              editButtonClass="-mt-[2px]"
              widthLogo="h-[99px] w-[450px]"
              avatarSize="EDITPROFILE"
              absolute="-mt-[46px] ml-[24px]"
              distancButtons="!mt-0"
              cameraIconPosition="max-w-[32px] flex bottom-[6px] h-[34px]"
              cropSize="!w-[450px] !h-[99px]"
              cropButtonBackground="!w-[450px] !h-[34px]"
              drapDropPosition="!left-0 !ml-0 !top-[0px] !h-[32px] !bg-transparent"
              isModalCrop
            />
          </div>
          {/* First name and last name */}
          <div className="mt-[19px]">
            <div className="flex flex-row gap-[25px]">
              <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="First name*">
                <>
                  <div className="mt-[1px] w-full">
                    <input
                      type="text"
                      className="w-full h-[31px] text-[13px] leading-[15px] !font-normal text-[#3C3C3C] px-[12px] border-1 border-[#DDDDDD] rounded-5 focus-visible:outline-none"
                      {...register('firstName', {
                        required: {
                          value: true,
                          message: 'First name is required',
                        },
                      })}
                      autoComplete="off"
                      autoCapitalize="off"
                    />
                  </div>
                  <div>
                    {errors.firstName && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.firstName?.message!}
                        classes="flex content-start mt-1"
                      />
                    )}
                  </div>
                </>
              </FormFieldWithCaption>
              <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Last name*">
                <>
                  <div className="mt-[1px] w-full">
                    <input
                      type="text"
                      className="w-full h-[31px] text-[13px] leading-[15px] !font-normal text-[#3C3C3C] px-[12px] border-1 border-[#DDDDDD] rounded-5 focus-visible:outline-none"
                      {...register('lastName', {
                        required: {
                          value: true,
                          message: 'Last name is required',
                        },
                      })}
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    {errors.lastName && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.lastName?.message!}
                        classes="flex content-start mt-1"
                      />
                    )}
                  </div>
                </>
              </FormFieldWithCaption>
            </div>
          </div>
          {/* Company name and headline */}
          <div className="mt-[11px]">
            <div className="flex flex-row gap-[25px]">
              <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Company name*">
                <>
                  <div className="md:w-[212.5px] w-full flex h-[31px] text-[13px] leading-[15px] !font-normal text-[#3C3C3C] rounded-[10px] edit-company-selector mt-[1px]">
                    <CompanySelectorNew
                      handleCompanySelected={handleCompanySelected}
                      register={register}
                      selectedCompanyStateToStore={selectedCompanyStateToStore}
                      placeholder=" "
                      className="!w-[212.5px] h-[31px] text-[13px] leading-[15px] !font-normal text-[#3C3C3C] px-[12px] border-1 border-[#DDDDDD] rounded-5 focus-visible:outline-none"
                    />
                  </div>
                  <div>
                    {errors.companyId && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.companyId?.message!}
                        classes="flex content-start mt-1"
                      />
                    )}
                  </div>
                </>
              </FormFieldWithCaption>
              <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Position*">
                <>
                  <div className="md:w-[212.5px] w-full flex h-[31px] text-[13px] leading-[15px] !font-normal text-[#3C3C3C] rounded-[10px] edit-company-selector">
                    <JobTitleSelectorNew
                      handleJobTitleSelected={handleJobTitleSelected}
                      register={register}
                      selectedJobTitleStateToStore={selectedJobTitleStateToStore && selectedJobTitleStateToStore.label}
                      placeholder="Enter your job title"
                      isBorderLess
                      className="!w-[212.5px] h-[31px] text-[13px] leading-[15px] !font-normal text-[#3C3C3C] px-[12px] border-1 border-[#DDDDDD] rounded-5 focus-visible:outline-none"
                    />
                  </div>
                  <div>
                    {errors.jobTitle && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.jobTitle?.message!}
                        classes="flex content-start mt-1"
                      />
                    )}
                  </div>
                </>
              </FormFieldWithCaption>
            </div>
          </div>
          {/* Industry */}
          <div className="mt-[11px]">
            <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Industry*">
              <>
                <div className="flex h-[31px] text-[13px] leading-[15px] !font-normal text-[#3C3C3C] rounded-[5px] edit-company-selector mt-[1px]">
                  <IndustrySelector
                    onChange={handleIndustrySelected}
                    register={register}
                    selectedIndustryStateToStore={selectedIndustryStateToStore}
                  />
                </div>
                <div>
                  {errors.industryId && (
                    <Typography
                      variant="ValidationMessage"
                      text={errors.industryId?.message!}
                      classes="flex content-start mt-1"
                    />
                  )}
                </div>
              </>
            </FormFieldWithCaption>
          </div>
          {/* Job Function Start */}
          <div className="mt-[11px]">
            <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Job Function*">
              <>
                <div className="flex h-[31px] text-[13px] leading-[15px] !font-normal text-[#3C3C3C] rounded-[5px] edit-company-selector mt-[1px]">
                  <JobFunctionSelector
                    onChange={handleJobFunctionSelected}
                    register={register}
                    selectedJobFunctionStateToStore={selectedJobFunctionStateToStore}
                  />
                </div>
                <div>
                  {errors.jobFunctionId && (
                    <Typography
                      variant="ValidationMessage"
                      text={errors.jobFunctionId?.message!}
                      classes="flex content-start mt-1"
                    />
                  )}
                </div>
              </>
            </FormFieldWithCaption>
          </div>
          {/* Job Function End */}
          {/* Location */}
          <div className="mt-[11px]">
            <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Location*">
              <>
                <div className="w-full h-[31px] border-1 border-[#DDDDDD] rounded-5 mt-[1px]">
                  <CitySelector
                    setValue={setValue}
                    citySelected={citySelected}
                    register={register}
                    placeholder="Enter your city"
                    classes="text-[13px] w-full !h-[29px] leading-[15px] !font-normal text-[#3C3C3C] px-[12px] rounded-[10px] focus-visible:outline-none"
                  />
                </div>
                <div>
                  {errors.location && (
                    <Typography
                      variant="ValidationMessage"
                      text={errors.location?.message!}
                      classes="flex content-start mt-1"
                    />
                  )}
                </div>
              </>
            </FormFieldWithCaption>
          </div>
          <div className="mt-[11px]">
            <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Post code*">
              <>
                <div className="mt-[1px] w-full">
                  <input
                    type="text"
                    className="w-full h-[31px] text-[13px] leading-[15px] !font-normal text-[#3C3C3C] px-[12px] border-1 border-[#DDDDDD] rounded-5 focus-visible:outline-none"
                    {...register('postCode', {
                      required: {
                        value: true,
                        message: LOGIN_SIGNUP_RECOVERY.POSTCODE_ISREQUIRED
                      },
                      // pattern: {
                      //   value: digitRegex,
                      //   message: LOGIN_SIGNUP_RECOVERY.VALID_POSTCODE
                      // }
                    })}
                    autoComplete="off"
                  />
                </div>
                <div>
                  {errors.postCode && (
                    <Typography
                      variant="ValidationMessage"
                      text={errors.postCode?.message!}
                      classes="flex content-start mt-1"
                    />
                  )}
                </div>
              </>
            </FormFieldWithCaption>
          </div>
          {/* About me */}
          <div className="mt-[11px]">
            <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="About me">
              <div className="mt-[1px]">
                <textarea
                  className="w-full h-[69px] text-[13px] leading-[15px] !font-normal text-[#3C3C3C] px-[12px] pt-[7px] border-1 border-[#DDDDDD] rounded-5 focus-visible:outline-none"
                  {...register('aboutMe')}
                />
              </div>
            </FormFieldWithCaption>
          </div>
          {/* Buttons */}
          <div className="mt-[14px]">
            <div className="flex flex-row justify-end pt-0">
              <div>
                <button
                  type="button"
                  className="rounded-5 inline-block text-sm font-semibold h-[31px] bg-[#EDF2F5] text-[#203C6E] w-[88px] mr-[10px] hover:bg-[#CEDCF2] tracking-wide"
                  onClick={() => props.onClose()}
                >
                  Cancel
                </button>
              </div>
              <div className="">
                <button
                  type="submit"
                  className="rounded-[5px] inline-block text-sm font-semibold h-[31px] bg-[#0049EF] hover:bg-[#2e6eff] text-white w-[74px] tracking-wide"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditUserProfile;
