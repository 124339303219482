import {
  ActionCreator,
  Dispatch,
} from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ICreatePost } from '../../../models/post/create-post';
import { IPostCreatedResult } from '../../../models/post/PostCreatedResult';
import { IPostCreatedState } from '../../../models/post/PostCreatedState';
import { FilUploadService } from '../../../services/FileUpload';
import { PostService } from '../../../services/Post';
import { CreateUpcomingEventPostActionTypes } from './types';

export interface ICreateUpcomingEventPostInProgress {
  type: CreateUpcomingEventPostActionTypes.CREATE_POST_IN_PROGRESS;
  inProgress: boolean;
}

export interface ICreateUpcomingEventPostSuccess {
  type: CreateUpcomingEventPostActionTypes.CREATE_POST_SUCCESS;
  data: IPostCreatedResult;
}

export interface ICreateUpcomingEventPostError {
  type: CreateUpcomingEventPostActionTypes.CREATE_POST_ERROR;
  errorMessage: string;
}

export type CreateUpcomingEventPostActions =
  | ICreateUpcomingEventPostInProgress
  | ICreateUpcomingEventPostSuccess
  | ICreateUpcomingEventPostError;

export const createUpcomingEventPost: ActionCreator<
  ThunkAction<
    Promise<any>,
    IPostCreatedState,
    null,
    ICreateUpcomingEventPostSuccess
  >
> = (data: ICreatePost, attachment?: File, attachmentType?: string) => {
  return async (dispatch: Dispatch) => {
    try {
      let uploadedFile;

      if (attachment) {
        const fileUploadService = new FilUploadService();

        let fileUploadResult;

        if (attachmentType === 'image') { fileUploadResult = await fileUploadService.uploadImage(attachment);}
        if (attachmentType === 'video') { fileUploadResult = await fileUploadService.uploadVideo(attachment);}
        if (attachmentType === 'document') { fileUploadResult = await fileUploadService.uploadDocument(attachment);}

        uploadedFile = fileUploadResult?.data?.Key;
      }

      const postService = new PostService();

      if (uploadedFile) {
        data.source = uploadedFile;
      }

      const result = await postService.createEventPost(data);

      dispatch({
        type: CreateUpcomingEventPostActionTypes.CREATE_POST_SUCCESS,
        data: result,
      });
    } catch (error: any) {
      console.error(error);

      dispatch({
        type: CreateUpcomingEventPostActionTypes.CREATE_POST_ERROR,
        errorMessage: error.message,
      });
    } finally {
      dispatch({
        type: CreateUpcomingEventPostActionTypes.CREATE_POST_IN_PROGRESS,
        inProgress: false,
      });
    }
  };
};
