/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
import { MeetingStatus, useMeetingManager, useMeetingStatus } from 'amazon-chime-sdk-component-library-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { VideoControlls } from '../../../../components/chime-video-call/video-controlls';
import { Button } from '../../../../shared-components/V2';
import { IAppState } from '../../../../store';
import { InteractiveSpeakerReadyToGoLive } from '../../../../store/interactive-event/speaker/actions';
import { useCurrentUser } from '../../../../utils/hooks';
import { LiveCall } from '../live-call';
import { VideoSession } from '../video-session';
import { getCompanyName } from 'src/utils';

export const GuestView = () => {
    const { slug, roomId, role } = useParams();
    const [isLive, setIsLive] = useState(false);
    const [isChimeLoaded, setChimeLoaded] = useState(false);
    const [isReady, setIsReady] = useState(false);


    const user = useCurrentUser();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const meetingManager = useMeetingManager();
    const meetingStatus = useMeetingStatus();

    const liveEvent = useSelector((state: IAppState) => state.liveEvent);
    const interactiveEvent = useSelector((state: IAppState) => state.interactiveEvent);
    const { kicked, ready: readyToJoinCall } = interactiveEvent;


    useEffect(() => {
        meetingManager.subscribeToAudioVideo((e: any) => {
            if (e && !isChimeLoaded) {
                setChimeLoaded(true);
            }
        });
    }, []);

    useEffect(() => {
        if (readyToJoinCall && meetingManager) {
            meetingManager.start();
        }
    }, [readyToJoinCall])

    useEffect(() => {
        if (kicked && meetingManager && role !== 'moderator') {
            meetingManager.leave().then(() => navigate(`/${getCompanyName(liveEvent.event)}/${liveEvent.event?.slug}/live`));
        }
    }, [kicked]);

    useEffect(() => {
        if (meetingManager && meetingStatus === MeetingStatus.Succeeded) {
            setIsLive(true);
        } else {
            setIsLive(false);
        }
    }, [meetingManager, meetingStatus]);

    return (
        <>
            {isReady && !isLive && !readyToJoinCall ? <VideoSession /> : <LiveCall />}
            {
                !isLive && <div className="absolute bottom-0 w-full flex justify-center">
                    <div className='relative w-full h-[61px] flex flex-row items-center justify-center bottom-0 bg-gradient-to-t from-slate-300'>
                        <Button
                            disabled={!isChimeLoaded || isReady}
                            text={isReady ? "Moderator will let you in soon!" : "Go Live"}
                            className="bg-orange"
                            onClick={() => {
                                dispatch(InteractiveSpeakerReadyToGoLive(slug!, roomId!))
                                setIsReady(true);
                            }}
                        />
                    </div>
                </div>
            }
            {
                isLive && <div className="absolute bottom-0 w-full flex justify-center">
                    <VideoControlls
                        onCallHangup={() => navigate(`/${getCompanyName(liveEvent.event)}/${slug}/live`)}
                    />
                </div>
            }
        </>
    );
};
