import bulb from '../../../assets/images/compliance/bulb-icon.png';

const TableContentTwelvetoSixteen = () => (
  <>
    <hr className="my-10" />
    <div id="contacting-you" className="mt-7 text-gray-1 text-lg5">
      12. Contacting You
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        You give us permission to contact you, through our Platform or other contact information you
        have provided to us.
      </div>
    </div>
    <div className="mt-3">
      a. You accept that Panelist is allowed to send notices and messages to you through our
      Platform, apps, and contact information. If your contact information is out of date, you may
      miss out on important notices.
    </div>
    <div className="mt-3">
      b. By submitting suggestions or other feedback regarding our Platform and associated services
      to Panelist, you agree that Panelist can use and share (but does not have to) such feedback
      for any purpose without compensation to you.
    </div>

    <hr className="my-10" />
    <div id="privacy" className="mt-7 text-gray-1 text-lg5">
      13. Privacy
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        Please refer to our Privacy Policy, Cookie Policy and your settings to see how we may
        access, store and process your information and data.
      </div>
    </div>
    <div className="mt-3">
      a. You agree that we may access, store, process and use any information and personal data that
      you provide in accordance with, the terms of the Privacy Policy, Cookie Policy and your
      choices (including settings).
    </div>
    <hr className="my-10" />
    <div id="applicable-law" className="mt-7 text-gray-1 text-lg5">
      14. Applicable Law
    </div>
    <div className="mt-3">
      a. These Terms will be governed by the laws of New South Wales, Australia. If there is a
      dispute between you and the Platform that results in litigation then you must submit to the
      jurisdiction of the courts of New South Wales, Australia. In the event that any of these Terms
      are found to be unenforceable under New South Wales Law, this will not affect the remainder of
      these Terms.
    </div>
    <hr className="my-10" />
    <div id="general-provisions" className="mt-7 text-gray-1 text-lg5">
      15. General Provisions
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        Here are some of our Terms important clauses which explain how our Terms operate
      </div>
    </div>
    <div className="mt-3">
      a. Except as they may be supplemented by additional terms and conditions, policies, guidelines
      or standards, these Terms constitute the entire Agreement between the Platform and you
      pertaining to the subject matter hereof, and supersede any and all prior oral or written
      understandings or agreements between the Platform and you in relation to the access to and use
      of the Platform.
    </div>
    <div className="mt-3">
      b. No joint venture, partnership, employment, or agency relationship exists between you and
      the Platform as a result of these Terms or your use of the Platform.
    </div>
    <div className="mt-3">
      c. These Terms do not and are not intended to confer any rights or remedies upon any person
      other than the parties.
    </div>
    <div className="mt-3">
      d. If any provision of these Terms is held to be invalid or unenforceable, such provision will
      be struck and will not affect the validity and enforceability of the remaining provisions.
    </div>
    <div className="mt-3">
      e. The Platform’s failure to enforce any right or provision in these Terms will not constitute
      a waiver of such right or provision unless acknowledged and agreed to by us in writing.
    </div>
    <div className="mt-3">
      f. Except as expressly set forth in these Terms, the exercise by either party of any of its
      remedies under these Terms will be without prejudice to its other remedies under these Terms
      or otherwise permitted under law.
    </div>
    <div className="mt-3">
      g. You may not assign, transfer or delegate this Agreement and Your rights and obligations
      hereunder without the Platform’s prior written consent.
    </div>
    <div className="mt-3">
      h. Your right to terminate this Agreement at any time remains unaffected.
    </div>
    <div className="mt-3">
      i. If You have any questions about these Terms please email us: support@panelist.com
    </div>
    <hr className="my-10" />
    <div id="variations" className="mt-7 text-gray-1 text-lg5">
      16. Variations
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        We do sometimes update our User Terms, and you are confirmed to have agreed to our updated
        Terms upon continuing to use the Platform.
      </div>
    </div>
    <div className="mt-3">
      a. We reserve the right to modify these Terms or its policies relating to the Platform or
      Services at any time, effective upon posting of an updated version of these Terms on the
      Platform. When we do, we will revise the updated date at the bottom of this page. Continued
      use of the Platform after any such changes shall constitute your consent to such changes.
    </div>
    <hr className="my-10" />
  </>
);

export default TableContentTwelvetoSixteen;
