import golive from '../../../assets/images/virtual/go-live.png';
import golive2 from '../../../assets/images/virtual/go-live@2x.png';
import prerecord from '../../../assets/images/virtual/pre-record.png';
import prerecord2 from '../../../assets/images/virtual/pre-record@2x.png';
import divider from '../../../assets/images/overview/line.png';

export const PrerecordOrLive = () => (
  <div className="block lg:flex flex-row mb-20 lg:mb-0 2xl:mb-0">
    <div className="bg-white w-full lg:w-1/2 pb-16">
      <div className="lg:flex">
        <div className="mb-8 mt-10">
          <div className="ml-36 2xl:ml-64  2xl:pl-6 pl-1 text-gray-1 font-extralight">
            <p className="text-lg3 font-normal text-dark-blue">Go live</p>
            <p className="text-md mt-3 lg:mr-40 text-left w-3/5">
              Go to your audience live from anywhere in the world with your speakers in different
              locations, each coming live at the time of their presentation.
            </p>
          </div>
          <img
            className="ml-36 2xl:ml-64 2xl:pl-6 mt-6"
            srcSet={`${golive} 300w, ${golive2} 1000w`}
            sizes="(max-width: 583px) 30vw, (max-width: 583px) 30vw, 500px"
            alt=""
            src={golive2}
          />
        </div>

        <div className="">
          <img src={divider} alt="" className="lg:mt-44 h-0 md:h-284" />
        </div>
      </div>
    </div>
    <div className="w-0 divide  mt-12 z-50 -ml-3">
      <p className="ml-1 mt-11 text-lg2 font-extralight text-gray-1">or</p>
    </div>
    <div className="bg-gray-3 w-full lg:w-1/2 -z-10 ">
      <div className="mb-8 mt-10">
        <div className="ml-24 pl-11 text-gray-1 font-extralight">
          <p className="text-lg3 font-normal text-dark-blue pt-10 lg:pt-0">Pre-record</p>
          <p className="text-md mt-2 pt-1 lg:mr-40 text-left w-3/5">
            Would rather review and approve your event footage before streaming? Record your
            presentation, review it, schedule and stream it as live during your event.
          </p>
        </div>
        <img
          className="ml-24 pl-10 mt-6 pb-10"
          srcSet={`${prerecord} 300w, ${prerecord2} 1000w`}
          sizes="(max-width: 583px) 30vw, (max-width: 583px) 30vw, 500px"
          alt=""
          src={prerecord2}
        />
      </div>
    </div>
  </div>
);

export default PrerecordOrLive;
