import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import EventRegistration from 'src/mui/pages/event/website/event-registration';
import { getTimeZone } from 'src/utils/timezones';
import { useMemo } from 'react';
import { dateAndTimeWithTz } from 'src/utils/date-timezone';
import { EventWebsiteTemplateType } from 'src/mui/types/survey';
import classNames from 'classnames';
import { IAppState } from '../../../store';

import { Logo } from '../../../shared-components/V2/Logo';
// import EventRegistrationButton from '../registration/event-registration-button/event-registration-button';
import { Loader } from '../../../shared-components/Loader';

import companyDefault from '../../../assets/images/company-default.svg';

const WebsiteEventQuickLinks = () => {
  const event = useSelector((state: IAppState) => state.website.event!);

  const quickLinks = event.eventLinks.find(l => l.key === 'link');
  const socialLinks = event.eventLinks.find(l => l.key === 'social');
  const contactLinks = event.eventLinks.find(l => l.key === 'contact');

  const getTimeZoneName = (zone: string) => {
    const shortTimeZone = getTimeZone(zone)?.[0]?.abbr ? getTimeZone(zone)?.[0]?.abbr : zone;
    return shortTimeZone;
  };

  const eventDateTimeWithTz = useMemo(() => {
    const startTime = dateAndTimeWithTz(event?.startTime, event?.timezone);
    const endTime = dateAndTimeWithTz(event?.endTime, event?.timezone);
    return {
      startTime,
      endTime
    };
  }, [event?.startTime, event?.endTime, event?.timezone]);

  return (
    <div id="contact-us" className="w-full mb-[80px] mt-[36px]">
      <div className="flex mx-auto">
        <div className={`w-full ${event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS ? 'event-afterhours-box' : 'event-website-box'} pb-[54px]`}>
          <div className="w-full flex flex-col justify-center items-center">
            {event?.company?.logo ? (
              <div className="-mt-[46px] mb-4 rounded-[6px] bg-white p-[1px] w-[92px] max-w-[92px] min-w-[92px] h-[92px] max-h-[92px] min-h-[92px] flex items-center justify-center">
                <Logo
                  src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event?.company?.logo}`}
                  alt=""
                  className="w-[90px] max-w-[90px] min-w-[90px] h-[90px] max-h-[90px] min-h-[90px] object-contain rounded-[6px]"
                />
              </div>
            ) : (
              <div className="-mt-[46px] mb-4 !rounded-[6px] bg-[#edf2f5] create-post-card p-[1px] w-[92px] h-[92px] flex items-center justify-center">
                <img className="w-[83px] h-[83px] object-contain p-1 medium-radius" src={companyDefault} alt="" />
              </div>
            )}
            <div className="text-center mb-[31.5px]">
              <div
                className="font-semibold text-[#0326E5] text-[21px] leading-[25px] flex justify-center"
                style={{
                  color: event?.colorStyle?.values?.heading,
                }}
              >
                {event.name}
              </div>
              <div
                className="text-[12px] h-[17px] text-[#0326E5] font-semibold mb-[2px]"
                style={{
                  color: event?.colorStyle?.values?.heading,
                }}
              >
                {event.locationName}
              </div>
              <div
                className="text-[12px] md:h-[17px] text-[#0326E5] font-light break-words px-3 md:px-0 md:mb-0 mb-2"
                style={{
                  color: event?.colorStyle?.values?.heading,
                }}
              >
                {eventDateTimeWithTz.startTime?.date}
                <span className="mx-[3px]">•</span>
                <span className="uppercase">
                  {eventDateTimeWithTz.startTime.time}
                </span>
                -
                <span className="uppercase mr-[3px]">{eventDateTimeWithTz.endTime.time}</span>
                {getTimeZoneName(event?.timezone)}
              </div>
              {/* <div
                className="text-[12px] md:h-[17px] text-[#0326E5] font-light break-words px-3 md:px-0 md:mb-0 mb-2"
                style={{
                  color: event?.colorStyle?.values?.heading,
                }}
              >
                {format(parseISO(event.startTime), 'dd MMM yyyy')}
                {' '}
                &bull;
                {' '}
                {format(parseISO(event.startTime), 'HH:mm aaaa')}
                {' '}
                -
                {' '}
                {format(parseISO(event.endTime), 'HH:mm aaaa')}
                {' '}
                {event.timezone}
              </div> */}
              <div className="flex justify-center pt-[18.5px]">
                {event ? (
                  <EventRegistration isBanner eventId={event.id} />
                ) : (
                  <Loader className="pt-5" key="loader" />
                )}
              </div>
            </div>

            {/* second row    */}
            <div className="w-full md:px-[262px] px-[80px] flex md:flex-wrap md:flex-row flex-col justify-center items-start md:gap-x-[100px] gap-y-[24px]">
              {quickLinks?.metadata.find(q => q.isShow) && (
                <div className="flex flex-col">
                  {quickLinks?.metadata?.length! > 0 && (
                    <div className={classNames('text-[15px] md:h-[21px] w-full font-semibold mb-[5px]', {
                      'text-[#0B1221]':
                    event?.websiteTemplate
                    !== EventWebsiteTemplateType.AFTER_HOURS,
                      'text-[#FFFFFF]':
                    event?.websiteTemplate
                    === EventWebsiteTemplateType.AFTER_HOURS,
                    })}
                    >
                      Quick Links
                    </div>
                  )}
                  {quickLinks?.metadata.map(
                    link => link.isShow && (
                      <a
                        href={`${link.url}`}
                        className="text-[13px] font-bold h-[19px] text-[#3C3C3C] hover:underline"
                      >
                        {link.title}
                      </a>
                    )
                  )}
                </div>
              )}
              {contactLinks?.metadata.find(q => q.isShow) && (
                <div className="flex flex-col">
                  {contactLinks?.metadata && (
                    <div className={classNames('text-[15px] md:h-[21px] w-full font-semibold mb-[5px]', {
                      'text-[#0B1221]':
                    event?.websiteTemplate
                    !== EventWebsiteTemplateType.AFTER_HOURS,
                      'text-[#FFFFFF]':
                    event?.websiteTemplate
                    === EventWebsiteTemplateType.AFTER_HOURS,
                    })}
                    >
                      Contact us
                    </div>
                  )}
                  {contactLinks?.metadata.map(
                    link => link.isShow && (
                      <div className="text-[13px] text-[#3C3C3C] font-light h-[19px]">
                        {link.title}
                        <span className="h-[19px] ml-[9px] hover:underline cursor-pointer">
                          {link.body}
                        </span>
                      </div>
                    )
                  )}
                </div>
              )}
              {socialLinks?.metadata && (
                <div className="flex flex-col">
                  {socialLinks?.metadata && (
                    <div className={classNames(
                      'text-[15px] md:h-[21px] w-full font-semibold mb-[5px]',
                      {
                        'text-[#0B1221]':
                      event?.websiteTemplate
                      !== EventWebsiteTemplateType.AFTER_HOURS,
                        'text-[#FFFFFF]':
                      event?.websiteTemplate
                      === EventWebsiteTemplateType.AFTER_HOURS,
                      }
                    )}
                    >
                      Social links
                    </div>
                  )}
                  <Link
                    to="/home"
                    className={classNames(
                      'text-[13px] font-semibold h-[19px] mb-[3px] hover:underline',
                      {
                        'text-[#203C6E]':
                      event?.websiteTemplate
                      !== EventWebsiteTemplateType.AFTER_HOURS,
                        'text-[#FFFFFF]':
                      event?.websiteTemplate
                      === EventWebsiteTemplateType.AFTER_HOURS,
                      }
                    )}
                  >
                    Panelist
                  </Link>
                  {socialLinks?.metadata.find(q => q.isShow)
                    && socialLinks?.metadata.map(
                      link => link.isShow && (
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`${link.url}`}
                          className="flex flex-row text-[13px] font-semibold h-[19px] text-[#203C6E] mb-[3px] hover:underline"
                        >
                          {link.icon && (
                            <img src={link.icon} className="w-5 h-5" alt="" />
                          )}
                          {link.title}
                        </a>
                      )
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventQuickLinks;
