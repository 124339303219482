import discoverhybrid from '../../../assets/images/hybrid/discover-panelist.png';
import discoverhybrid2 from '../../../assets/images/hybrid/discover-panelist@2x.png';
import polyicon from '../../../assets/images/polygon-icon.svg';
import { ButtonWithTextAndImage } from '../../../shared-components/V2';

export const DiscoverHybrid = () => (
  <div className="w-full bg-white xl:mt-2 mb-2 ">
    <div className="container mx-auto mt-20 mb-16 w-10/12">
      <div className="block md:flex justify-between ml-7 mr-12 pr-5 md:mr-8">
        <div className="block md:flex text-left lg:ml-0 pl-1 mb-20 w-11/12">
          <div className="">
            <p className="lg:text-xl3 font-extralight text-xl text-dark-blue">
              Discover Panelist Hybrid
            </p>

            <p className="text-lg font-extralight text-dark-blue mt-4 text-left w-11/12">
              Fully hybrid events to drive record
              {' '}
              <br />
              {' '}
              engagement for physical attendees and
              {' '}
              <br />
              {' '}
              near in-person experiences for virtual attendees.
            </p>
            <div className="mt-6">
              <ButtonWithTextAndImage
                buttonClasses="bg-transparent hover:bg-gray-7 active:bg-gray-7 border-1 border-blue-3 w-242 h-43 rounded-full"
                imageUrl={polyicon}
                text="Create an event"
                textClasses="font-extralight text-sm3 ml-1 pl-1"
              />
            </div>
          </div>
        </div>
        <div className="-ml-14  -mt-11 z-10">
          <img
            className="-ml-8"
            srcSet={`${discoverhybrid} 300w, ${discoverhybrid2} 1000w`}
            sizes="(max-width: 614px) 30vw, (max-width: 614px) 30vw, 1000px"
            alt=""
            src={discoverhybrid2}
          />
        </div>
      </div>
    </div>
  </div>
);

export default DiscoverHybrid;
