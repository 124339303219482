import { Reducer } from 'redux';

import { IProfilePictureState } from '../../../models/company/ProfilePictureState';
import { ProfilePictureActions } from './actions';
import { ProfilePictureActionTypes } from './types';

const initialState: IProfilePictureState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const SetProfilePictureReducer: Reducer<IProfilePictureState, ProfilePictureActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ProfilePictureActionTypes.SET_COMPANY_PROFILE_PICTURE: {
      return {
        ...state,
        value: action.url
      };
    }

    case ProfilePictureActionTypes.PROFILE_COMPANY_PICTURE_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }
    
    case ProfilePictureActionTypes.RESET_PROFILE_COMPANY_PICTURE: {
      return {
        ...state,
        value: null
      };
    }

    default:
      return state;
  }
};
