import Footer from '../home/footer';
import { Navbar } from '../../components';
import EventLiveSponsors from './event-live-sponsors';
import Banner from './banner';

export const EventLiveDiscussion = () => (
  <div>
    <Navbar />
    <Banner />
    <EventLiveSponsors />
    <Footer />
  </div>
);

export default EventLiveDiscussion;
