import React from "react";

export interface BulletCrossSvgIconProps {
    fillColor?: string;
}
export const BulletCrossSvgIcon = ({ fillColor }: BulletCrossSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#bdcce8"
    return <svg id="Component_679_148" data-name="Component 679 – 148" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path id="Path_14809" data-name="Path 14809" d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" fill="#6885aa"/>
    <line id="Line_1440" data-name="Line 1440" x1="10.4" y2="10.4" transform="translate(6.8 6.8)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
    <line id="Line_1441" data-name="Line 1441" x2="10.4" y2="10.4" transform="translate(6.8 6.8)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
  </svg>
  
};