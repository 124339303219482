import { useState } from "react";

interface IEventsLeftSvgIcon {
  isActive?: boolean;
  className?: string;
  disableHover?: boolean;
}
export const EventsLeftSvgIcon = ({ isActive, className, disableHover }: IEventsLeftSvgIcon) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const colorBlue = "#0049EF";
  const defaultColor = "#7283A4";
  const fillColor = !disableHover && (isHover || isActive) ? colorBlue : defaultColor;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.736" height="30.478" viewBox="0 0 25.736 30.478" onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className={className}>
    <g id="Group_21016" data-name="Group 21016" transform="translate(-69.633 -251.827)">
      <path id="Path_10373" data-name="Path 10373" d="M130.8,211.716H108.88a1.19,1.19,0,0,1-1.188-1.078l-.713-6.922a1.185,1.185,0,0,1,1.188-1.317h23.348a1.2,1.2,0,0,1,1.188,1.317l-.713,6.922A1.19,1.19,0,0,1,130.8,211.716Z" transform="translate(-37.34 70.588)" fill={fillColor}/>
      <rect id="Rectangle_7907" data-name="Rectangle 7907" width="12.868" height="14.155" rx="1" transform="translate(69.633 253.514)" fill={fillColor}/>
      <path id="Path_27106" data-name="Path 27106" d="M8.364,0A8.364,8.364,0,1,1,0,8.364,8.364,8.364,0,0,1,8.364,0Z" transform="translate(76.711 252.227)" fill={fillColor} stroke="#edf2f5" strokeWidth="0.8"/>
      <path id="Path_14614" data-name="Path 14614" d="M2.509,6.436a.783.783,0,0,0,.634-.34A7.217,7.217,0,0,1,14.9,6.1a.786.786,0,0,0,.638.341h1.714a.793.793,0,0,0,.691-1.175A10.191,10.191,0,0,0,.1,5.272.79.79,0,0,0,.793,6.436Z" transform="translate(76.066 268.953)" fill={fillColor} stroke="#edf2f5" strokeWidth="0.7"/>
      <path id="Path_14615" data-name="Path 14615" d="M11.985,5.175C8.95.638,3.806.334.5,4.5q-.259.324-.5.679Z" transform="translate(79.104 270.607)" fill={fillColor}/>
    </g>
    </svg>
  );
};
