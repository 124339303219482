import { useState } from "react"
import { getBrandingColors } from "src/mui/pages/event/live/liveEventStyling";

export interface IThreeDotSvg {
    fillColor?: "WHITE" | "GRAY";
    onClick?: Function;
    isActive?: boolean;
    isLivePage?: boolean;
}
export const ThreeDotSvg = ({ fillColor, onClick, isActive, isLivePage }: IThreeDotSvg) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const colors = getBrandingColors();
    const color = fillColor === "GRAY" ? '#EDF2F5' : '#ffffff';
    const isLivePageColor = isLivePage ? colors?.Secondary : "#0b1221"

    return <svg
    id="Options" xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31"
    onClick={() => onClick && onClick()}
    onMouseEnter={() => setIsHover(true)}
    onMouseLeave={() => setIsHover(false)}
>
  {
    (isActive || isHover) && <path id="Path_11350" data-name="Path 11350" d="M15.5,0A15.5,15.5,0,1,1,0,15.5,15.5,15.5,0,0,1,15.5,0Z" fill={color}/>
  }
  <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.5" cy="1.5" r="1.5" transform="translate(8 17) rotate(-90)" fill={isLivePageColor}/>
  <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.5" cy="1.5" r="1.5" transform="translate(14 17) rotate(-90)" fill={isLivePageColor}/>
  <circle id="Ellipse_5" data-name="Ellipse 5" cx="1.5" cy="1.5" r="1.5" transform="translate(20 17) rotate(-90)" fill={isLivePageColor}/>
</svg>
}