import { IEvent } from '../../../../models/event/event';
import { Participants } from '../../../../store/interactive-event/types';
import { IAttendee } from '../../../../models/events-live/attendee';
import { Box, Stack } from '@mui/material';
import { getBrandingColors } from './liveEventStyling';
import { CardHeader } from './card-header';
import { Audience } from '../../audience/audience';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';

export function LiveAudienceComponent(props: {
  ref: React.RefObject<HTMLDivElement>,
  gridColumn: string,
  gridRow: string,
  moduleDesignLines: boolean,
  height: string,
  boxStyles: { backgroundColor: string; pb: string; overflow: string; borderRadius: string; px: string; py: string; position: string },
  onClick: () => void,
  event: IEvent | undefined,
  stage: Participants | undefined,
  attendees: IAttendee[],
  strings: string[],
  columnCardHeight: string,
  role: any,
  onMuteClick: () => (dispatch: Dispatch<AnyAction>) => Promise<void>,
  columnWidth: number | null
}) {
  return <Box
    ref={props.ref}
    sx={{
      gridColumn: props.gridColumn,
      gridRow: props.gridRow,
    }}
  >
    <Stack sx={{ height: props.height, ...props.boxStyles }}>
      <CardHeader
        title="Networking"
        onClick={props.onClick}
      />
      <Audience
        event={props.event!}
        stage={props.stage!}
        attendees={props.attendees}
        isGridView={props.strings?.length > 3}
        columnCardHeight={props.columnCardHeight}
        {...((props.role === 'moderator' || props.role === 'speaker') && {
          onMuteClick: props.onMuteClick,
        })}
        columnWidth={props.columnWidth!}
        isWhiteIcons
      />
    </Stack>
  </Box>;
}
