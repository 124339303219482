const CommunityIntro = () => (
  <div className="bg-gray-0 w-full">
    <div className="block mb-8 w-9/12 mx-auto">
      <div className="block lg:flex flex-col  mx-auto mt-7 text-dark-blue text-md">
        <div className="mt-7 text-gray-1 table-of-contents text-lg5">
          Panelist Pty Ltd (“Panelist”)
        </div>
        <div className="mt-3">
          Thank you for using Panelist, the platform that helps you be a part of a global event
          community. The content that you contribute to Panelist should contribute to the Panelist
          community in a constructive way. This means that you should only use the platform for the
          purpose of hosting and attending mutually helpful events that don’t waste the community’s
          time or breach ethical guidelines. Our community is a place where everyone is respectful,
          honest and inclusive. These Community Guidelines continually evolve to ensure our members
          have a positive experience.
        </div>
      </div>
    </div>
  </div>
);

export default CommunityIntro;
