import bulb from '../../../assets/images/compliance/bulb-icon.png';

const TableContentSix = () => (
  <>
    <hr className="my-10" />

    <div id="your-content" className="mt-7 text-gray-1 text-lg5">
      6. Your Content
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        You own your content and are responsible for the content you add to the platform both
        privately and publicly. Whilst you own your Content, we can monitor it. Unless you grant
        specific permission, we cannot use your content for marketing or commercial purposes.
        However, we can use your content to provide our Services to you.
      </div>
    </div>
    <div className="mt-3">
      a. You are solely responsible for all audio, code, video, images, information, data, text,
      software, music, sound, photographs, graphics, messages or other materials that you upload,
      post, publish or display or email or otherwise use via the Platform (‘Your Content’).
    </div>
    <div className="mt-3">
      b. We do not own Your Content that you submit on the Platform in the course of using the
      Platform. You shall have sole responsibility for the accuracy, quality, integrity, legality,
      reliability, appropriateness, and intellectual property ownership or right to use of Your
      Content.
    </div>
    <div className="mt-3">
      c. We may monitor Your Content on the Platform submitted or created using our Platform. Unless
      specifically permitted by you, your use of the Platform does not grant us the license to use,
      reproduce, adapt, modify, publish or distribute Your Content or stored in your user account
      for commercial, marketing or any similar purpose. But you grant us a non-exclusive license to
      access, copy, distribute, store, transmit, reformat, display and perform Your Content of your
      user account solely as required for the purpose of providing the services associated with the
      Platform to you.
    </div>
    <div className="mt-3">
      d. Without limiting any of those representations or warranties, we have the right, though not
      the obligation, to, in our own sole discretion, refuse or remove Your Content that, in our
      reasonable opinion, violates any of our policies or is in any way harmful or objectionable.
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        We reserve the right to, in our discretion, refuse or remove your content. We, however, are
        not required to do so.
      </div>
    </div>
    <div className="mt-3">
      e. By using Panelist and its Platform, you are granting Panelist and our affiliates the
      following non-exclusive license;
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        This Clause outlines how we may use your Content, in line with our Non-Exclusive License.
      </div>
    </div>
    <div className="mt-3 ml-10">
      (i) A worldwide, transferable and sublicensable right to use, copy, modify, distribute,
      publish and process, information and content that you provide through our Platform and the
      services of others, without any further consent, notice and/or compensation to you or others.
      These rights are limited in the following ways:
    </div>
    <div className="mt-3 ml-20">
      1. You can end this license for specific content by deleting such content from the Platform,
      or generally by closing your account, except (a) to the extent you shared it with others as
      part of the Platform and they copied, re-shared it or stored it and (b) for the reasonable
      time it takes to remove from backup and other systems.
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">How you can end this License.</div>
    </div>
    <div className="ml-20 mt-3">
      2. We will not include Your Content in advertisements for the products and services of third
      parties to others without your separate consent (including sponsored content). However, we
      have the right, without payment to you or others, to serve ads near Your Content and
      information, and your social actions may be visible and included with ads, as noted in the
      Privacy Policy. If you use a Platform feature, we may mention that with your name or photo to
      promote that feature within our Platform, subject to your settings.
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">We cannot use your Content for commercial or marketing purposes.</div>
    </div>
    <div className="ml-20 mt-3">
      3. We will get your consent if we want to give others the right to publish Your Content beyond
      the Platform. However, if you choose to share your post as "public", we will enable a feature
      that allows other Members to embed that public post onto third-party services, and we enable
      search engines to make that public content findable through their services.
      {' '}
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        If you choose to share your Content publicly, it will be possible for other Members to have
        that content on a third-party service or search engine.
      </div>
    </div>
    <div className="ml-20 mt-3">
      4. While we may edit and make format changes to Your Content (such as translating or
      transcribing it, modifying the size, layout or file type or removing metadata), we will not
      modify the meaning of your expression.
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        We may make cosmetic changes to your Content, but it won’t change the meaning of your
        Content.
      </div>
    </div>
    <div className="ml-20 mt-3">
      f. In addition to other terms as set forth in Terms, you are prohibited from using the
      Platform or Content (a) for any unlawful purpose; (b) to solicit others to perform or
      participate in any unlawful acts; (c) to violate any international, federal, or state
      regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our
      intellectual property rights or the intellectual property rights of others; (e) to harass,
      abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender,
      sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to
      submit false or misleading information; (g) to upload or transmit viruses or any other type of
      malicious code that will or may be used in any way that will affect the functionality or
      operation of the Platform or of any related website, other websites, or the Internet; (h) to
      collect or track the personal information of others; (i) to spam, phish, pharm, pretext,
      spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or
      circumvent the security features of Platform or any related website, other websites, or the
      Internet. We reserve the right to terminate your use of the Platform or any related website
      for violating any of the prohibited uses.
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        You cannot use our Services or Content for any unlawful, obscene or immoral purpose or to
        interfere with our security features. In such circumstances, we have the right to terminate
        your use and access to our Services or related Services.
      </div>
    </div>
    <div className="ml-20 mt-3">
      g. By using the Platform, you may encounter content or information that might be inaccurate,
      incomplete, delayed, misleading, illegal, offensive or otherwise harmful. Panelist generally
      does not review content provided by our Hosts or Users or others. You agree that we are not
      responsible for others’ (including other Users) content or information. We cannot always
      prevent this misuse of our Platform, and you agree that we are not responsible for any such
      misuse. You also acknowledge the risk that you or your organisation may be mistakenly
      associated with content about others when we let connections and followers know you or your
      organization.
    </div>
    <div className="ml-20 mt-3">
      h. Our Platform allows messaging and sharing of information in many ways, such as your
      profile, articles, group posts, links to news articles, job postings, and messages.
      Information and content that you share or post may be seen by other Users (including off of
      the Platform). Where we have made settings available, we will honor the choices you make about
      who can see content or information (e.g., message content to your addressees, sharing content
      only to Panelist connections, restricting your profile visibility from search engines, or
      opting not to notify others of your Panelist profile update). For job searching activities, we
      default to not notifying your connections network or the public. So, if you apply for a job
      through our Service or opt to signal that you are interested in a job, our default is to share
      it only with the job poster.
    </div>
    <div className="ml-20 mt-3">
      i. You agree that you are solely responsible for your interactions with any other user in
      connection with the Platform. Panelist will have no liability or responsibility with respect
      thereto. Panelist reserves the right, but has no obligation, to become involved in any way
      with disputes between you and any other user of the Platform.
    </div>
  </>
);

export default TableContentSix;
