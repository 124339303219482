// video-player.tsx => Core HLS stream player for the Panelist app
/* eslint-disable jsx-a11y/media-has-caption */
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import ReactHlsPlayer from '@panelist/react-hls-player';

import { CountDownTimer } from '../../../../../components/countdown-timer/countdown-timer';
import ViewPoll from '../../../../../components/poll/view-poll';

// Explicitly declare interface for HTMLVideoElement to
// circumvent cross-browser issues in TypeScript
// CAN POSSIBLY DEPRECATE SHORTLY
declare global {
  // eslint-disable-next-line no-unused-vars
  interface HTMLVideoElement {
    webkitRequestFullscreen?(): void;
    msRequestFullscreen?(): void;
  }
}

// Interface to enable access to the seek method through forward refs
// Will replace with something better later if needed
interface VideoControls {
  seek(amount: number): void;
}

// Props that can be received by this component
type VideoPlayerProps = {
  // Methods
  setIsPlaying(play: boolean): void;
  setDuration(dur: number): void;
  setPosition(pos: number): void;
  setVolume(vol: number): void;
  setNextVideo?: () => void;

  // Properties
  url: string;
  isFullScreen: boolean;
  isPip: boolean;
  isPlaying: boolean;
  error: string;
  volume: number;
  screenMode: 'small' | 'large' | 'hidden' | undefined;
  startTime: any;
  endTime: any;
  polls?: any;
};

export const VideoPlayer = forwardRef<VideoControls, VideoPlayerProps>(
  (props, ref) => {
    // Ref to the HLS player, needed due to the API
    const playerRef = useRef<HTMLVideoElement>(null);
    // Hack
    const [ready, setReady] = useState<boolean>(false);
    // Effect to manage playing and pausing the video based upon props
    const { isPlaying } = props;

    // Video player height
    const playerHeight = 36.8;
    useEffect(() => {
      if (isPlaying && playerRef.current) {
        playerRef.current.play();
      } else if (!isPlaying && playerRef.current) {
        playerRef.current.pause();
      }
    }, [isPlaying]);

    // Effect to manage the playback volume
    const { volume, setIsPlaying } = props;

    useEffect(() => {
      if (playerRef.current) {
        playerRef.current.volume = volume / 100;
        setIsPlaying(!playerRef.current.paused);
      }
    }, [volume, setIsPlaying]);

    // Full Screen
    const { isFullScreen } = props;

    useEffect(() => {
      if (isFullScreen) {
        if (playerRef.current && playerRef.current.requestFullscreen) {
          playerRef.current.requestFullscreen();
        } else if (
          playerRef.current
          && playerRef.current.webkitRequestFullscreen
        ) {
          /* Safari */
          playerRef.current.webkitRequestFullscreen();
        } else if (playerRef.current && playerRef.current.msRequestFullscreen) {
          /* IE11 */
          playerRef.current.msRequestFullscreen();
        }
      } else if (document?.fullscreenElement) {
        document?.exitFullscreen();
      }
    }, [isFullScreen]);

    // PiP
    // Appears to work in all browsers
    const { isPip } = props;

    useEffect(() => {
      if (isPip) {
        if (playerRef.current && playerRef.current.requestPictureInPicture) {
          playerRef.current.requestPictureInPicture();
        }
      } else if (document?.pictureInPictureElement) {
        document?.exitPictureInPicture();
      }
    }, [isPip]);

    // Due to poor APIs, need to attach event listeners to the various aspects of the HTML5 video player in order
    // to establish the duration and position of the video, and then propagate this back to the parent component
    // Will replace later if needed

    // Exhaustive deps issue - eslint avoid for now

    useLayoutEffect(() => {
      playerRef.current?.addEventListener('durationchange', () => {
        props.setDuration(playerRef.current?.duration || 0);
        setReady(true);
      });
      playerRef.current?.addEventListener('timeupdate', () => {
        props.setPosition(playerRef.current?.currentTime || 0);
      });
      // eslint-disable-next-line
    }, []);

    // Seek method implementation, needs to be called directly by parent, hence useImperativeHandle and Refs
    useImperativeHandle(ref, () => ({
      seek(amount: number) {
        if (playerRef.current) {
          playerRef.current.currentTime = amount ?? null;
        }
      },
    }));

    const displayPoll = () => (
      <div className="absolute w-96 top-20 right-36">
        {props.polls.livePolls?.id! && (
          <ViewPoll
            id={props.polls.livePolls.id}
            question={props.polls.livePolls.question}
            status={props.polls.livePolls.status}
            expireOption={1}
            expiredAt=""
            answers={props.polls.livePolls.answers}
            isInteractive
            type="live"
            eventId={props.polls.livePolls.eventId}
            sessionId={props.polls.livePolls.eventSessionId}
          />
        )}
      </div>
    );

    const isPastEvent = (date: string) => new Date(date) < new Date();
    const isLiveEvent = (startDate: string, endDate: string) => +new Date(startDate) - +new Date() <= 0 && new Date(endDate) > new Date();
    const isFutureEvent = (date: string) => +new Date(date) - +new Date() > 0;

    return (
      <div
        className="relative"
        style={
          props.screenMode === 'small'
            ? { height: `${playerHeight}rem` }
            : { height: 'fit-content' }
        }
      >
        {!props.error.length
          && props.volume === 0
          && ready
          && (isPastEvent(props.endTime)
            || isLiveEvent(props.startTime, props.endTime)) && (
            <div
              className="animate-pulse absolute h-12 w-full bg-red-600 flex items-center justify-center text-white"
              onClick={() => props.setVolume(100)}
            >
              Audio Muted, Click Here To Hear The Stream
            </div>
        )}
        {props.error && (
          <div className="flex bg-black h-[240px]">
            <span className="text-blue-200 text-lg1 font-bold m-auto text-center">
              {props.error}
            </span>
          </div>
        )}
        {isLiveEvent(props.startTime, props.endTime) && (
          <div>
            <ReactHlsPlayer
              autoPlay
              id="video"
              playerRef={playerRef}
              style={{
                width: '100%',
                height: `${playerHeight}`,
                backgroundColor: 'black',
              }}
              src={props.url}
              controls={false}
              muted={props.volume === 0}
              onClick={() => props.setIsPlaying(!props.isPlaying)}
            />
            {displayPoll()}
          </div>
        )}
        {isFutureEvent(props.startTime) && (
          <div
            style={{
              width: '100%',
              height: `${playerHeight}`,
              backgroundColor: 'black',
            }}
          >
            <CountDownTimer
              screenMode={props.screenMode}
              startTime={props.startTime}
            />
          </div>
        )}
        {isPastEvent(props.endTime) && (
          <div className="relative">
            <video
              src={props.url}
              autoPlay
              ref={playerRef}
              className={`w-full h-[${playerHeight}rem] bg-black pb-16`}
              controls={false}
              muted={props.volume === 0}
              onClick={() => props.setIsPlaying(!props.isPlaying)}
              onEnded={() => props.setNextVideo!()}
            />
            {displayPoll()}
          </div>
        )}
      </div>
    );
  }
);

export default VideoPlayer;
