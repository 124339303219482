import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ILikeUnlikeCommentState } from '../../../models/post/LikeUnlikeCommentState';
import { PostService } from '../../../services/Post';
import { LikeCommentActionTypes } from './types';

export interface ILikeCommentInProgressAction {
  type: LikeCommentActionTypes.IN_PROGRESS;
  inProgress: boolean;
}

export interface ILikeCommentSuccessAction {
  type: LikeCommentActionTypes.LIKE_POST;
  message: string;
}

export interface IUnlikeCommentSuccessAction {
  type: LikeCommentActionTypes.UNLIKE_POST;
  message: string;
}

export interface ILikeCommentErrorAction {
  type: LikeCommentActionTypes.ERROR;
  errorMessage: string;
}

export type LikeCommentActions =
  | ILikeCommentSuccessAction
  | IUnlikeCommentSuccessAction
  | ILikeCommentErrorAction;

export const likeComment: ActionCreator<
  ThunkAction<Promise<any>, ILikeUnlikeCommentState, null, ILikeCommentSuccessAction>
> = (postId: string, reaction: string) => {
  return async (dispatch: Dispatch) => {
    const postService = new PostService();
    try {
      const result = await postService.likeComment(postId, reaction.toLowerCase());

      dispatch({
        type: LikeCommentActionTypes.LIKE_POST,
        message: result.message
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: LikeCommentActionTypes.ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: LikeCommentActionTypes.IN_PROGRESS,
        loading: false
      });
    }
  };
};

export const unlikeComment: ActionCreator<
  ThunkAction<Promise<any>, ILikeUnlikeCommentState, null, ILikeCommentSuccessAction>
> = (postId: string) => {
  return async (dispatch: Dispatch) => {
    const postService = new PostService();
    try {
      const result = await postService.unlikeComment(postId);

      dispatch({
        type: LikeCommentActionTypes.UNLIKE_POST,
        message: result.message
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: LikeCommentActionTypes.ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: LikeCommentActionTypes.IN_PROGRESS,
        loading: false
      });
    }
  };
};

export const setInProgress: ActionCreator<
  ThunkAction<any, ILikeUnlikeCommentState, null, ILikeCommentInProgressAction>
> = (isInProgress: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: LikeCommentActionTypes.IN_PROGRESS,
    loading: isInProgress
  });
