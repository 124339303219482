import { useState } from "react";

export interface IDeleteChatSvgIcon {
  fillColor?: "GRAY" | "BLACK" | "WHITE";
  onClick?: Function;
}
export const DeleteChatSVGIcon = ({
  fillColor,
  onClick,
}: IDeleteChatSvgIcon) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const color = fillColor === "GRAY" ? "#edf2f5" :
              fillColor === "BLACK" ? "#000000" :
              "#ffffff";

  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
      focusable="true"
      aria-hidden="true"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      data-testid="DeleteIcon"
      onClick={() => onClick!()}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isHover && (
        <circle
          id="Ellipse_1123"
          data-name="Ellipse 1123"
          cx="14"
          cy="14"
          r="14"
          fill={color}
        />
      )}
      <path
        d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
        transform="translate(2.313 2.969) scale(1, 0.90)"
        fill="#7283A4"
      />
    </svg>
  );
};
