/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import FormProvider from 'src/mui/components/hook-form/form-provider';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { CompanyService } from 'src/services';
import { useEffect } from 'react';
import { Loader } from 'src/shared-components/Loader';
import { Button } from 'src/shared-components/V2';
import ProductSalesRepresentative from './Product-sales-representatives';
import CompanyProductForm from './company-product-form';

interface IProductLayoutProps {
  companyId: string
  ProductId: string | undefined;
  setShowProductLayout: any;
  getCompnayProductsData: Function;
  companyProduct?: any;
  setIsLoader: Function;
  isLoader: boolean;
}

export const ProductLayout = ({
  companyId, ProductId, setShowProductLayout, getCompnayProductsData, companyProduct, setIsLoader, isLoader
}: IProductLayoutProps) => {
  const methods = useForm<any>();
  const { setValue, handleSubmit, formState: { isSubmitting } } = methods;

  useEffect(() => {
    if (ProductId && companyProduct) {
      // setIsLoader(true);
      setValue('productName', companyProduct?.name || '');
      setValue('tagLine', companyProduct?.tagline || '');
      setValue('promotionalText', companyProduct?.promotionalText || '');
      setValue('description', companyProduct?.description || '');
      setValue('keywords', companyProduct?.keywords || []);
      setValue('integrations', companyProduct?.integrations || []);
      setValue('supportUrl', companyProduct?.supportUrl || '');
      setValue('marketingUrl', companyProduct?.marketingUrl || '');
      setValue('desktopUrls', companyProduct?.desktopUrls || []);
      setValue('phoneUrls', companyProduct?.phoneUrls || []);
      setValue('tabletUrls', companyProduct?.tabletUrls || []);
      setValue('copyrightName', companyProduct?.copyRightEntityName || '');
      setValue('capabilities', companyProduct?.capabilities || []);
      setValue('functions', companyProduct?.targetDepartments || []);
      setValue('timeline', companyProduct?.timeline || 0);
      setValue('industries', companyProduct?.industries || []);
      setValue('targetPersona', companyProduct?.targetPersona || []);
      setValue('icon', companyProduct?.icon || '');
      setValue('companySpecialities', companyProduct?.capabilities || []);
      setValue('representatives', companyProduct?.respresentitives || []);
    }
  }, [ProductId, companyId, companyProduct, setValue]);

  const onSubmit = async (data: any) => {
    setIsLoader(true);
    const compnayService = new CompanyService();
    const payload = {
      name: data?.productName,
      tagline: data?.tagLine,
      promotionalText: data?.promotionalText,
      description: data?.description,
      keywords: data?.keywords || [],
      integrations: data?.integrations || [],
      supportUrl: data?.supportUrl,
      marketingUrl: data?.marketingUrl,
      desktopUrls: data?.desktopUrls,
      phoneUrls: data?.phoneUrls,
      tabletUrls: data?.tabletUrls,
      targetPersona: data?.targetPersona,
      copyRightEntityName: data?.copyrightName,
      timeline: data?.timeline,
      industries: (data?.industries || []).map((industry: { id: string; name: string }) => industry?.id),
      targetDepartments: data?.functions,
      icon: data?.icon,
      capabilities: (data?.capabilities || []).map((capability: { id: string; name: string }) => capability?.id),
      status: 'published',
      respresentitives: (data?.representatives || []).map((item: { id: string }) => item?.id),
    };
    try {
      if (ProductId) {
        await compnayService.editCompanyProduct(companyId, ProductId, payload);
        setIsLoader(false);
        setShowProductLayout(false);
        getCompnayProductsData();
      } else {
        await compnayService.createCompanyProduct(companyId, payload);
        setShowProductLayout(false);
        getCompnayProductsData();
      }
    } catch (error) {
      setIsLoader(false);
      console.error('Save or Update Company Product:', error);
    } finally {
      setIsLoader(false);
    }
  };

  const onProductSubmit = () => methods.handleSubmit(onSubmit)();

  return (
    <Stack sx={{ flexDirection: 'row', position: 'relative' }}>
      {isLoader && (
      <div className="absolute left-0 top-0 w-full h-[calc(100%--26px)] bg-gray-1 bg-opacity-80 flex items-center justify-center z-20 rounded-20 -mt-[14px]">
        <Loader className="pt-5" key="loader" />
      </div>
      )}
      <Stack sx={{ width: '246px', minWidth: '246px' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '16px', fontWeight: 600, lineHeight: '21px', mb: '18px', fontFamily: 'Poppins-600'
          }}
        >
          General

        </Typography>
        <Stack sx={{ ml: '10px' }}>
          <Typography
            color="text.primary"
            variant="subtitle2"
            sx={{
              fontSize: '13px', fontWeight: 400, lineHeight: '17px', mb: '10px', fontFamily: 'Poppins-400'
            }}
          >
            <Link to="#logo-and-photo">Logo & Photos</Link>
          </Typography>
          <Typography
            color="text.primary"
            variant="subtitle2"
            sx={{
              fontSize: '13px', fontWeight: 400, lineHeight: '17px', mb: '10px', fontFamily: 'Poppins-400'
            }}
          >
            <Link to="#product-info">Product information</Link>
          </Typography>
          <Typography
            color="text.primary"
            variant="subtitle2"
            sx={{
              fontSize: '13px', fontWeight: 400, lineHeight: '17px', mb: '10px', fontFamily: 'Poppins-400'
            }}
          >
            <Link to="#target-demographic">Target & Demographic</Link>
          </Typography>
          <Typography
            color="text.primary"
            variant="subtitle2"
            sx={{
              fontSize: '13px', fontWeight: 400, lineHeight: '17px', mb: '10px', fontFamily: 'Poppins-400'
            }}
          >
            <Link to="#product-team">Product Team</Link>
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <CompanyProductForm />
          <ProductSalesRepresentative />
          <Stack direction="row" justifyContent="flex-end" gap="5px" sx={{ alignItems: 'flex-end', mt: '20px' }}>
            <Button
              size="x-large"
              onClick={() => setShowProductLayout(false)}
              text="Cancel"
              className="hover:bg-[#C4CDD5] bg-[#EDF2F5]  h-[35px] w-[88px] text-center text-[#000000] text-[14px] font-semibold rounded-[5px] flex items-center justify-center cursor-pointer"
            />
            <LoadingButton
              onClick={onProductSubmit}
              variant="contained"
              // disabled={isVerify}
              loading={isLoader || isSubmitting}
              sx={{
                width: '168px', height: '35px', color: 'white', borderRadius: '5px', backgroundColor: '#0049EF', px: '24px', py: '6px', boxShadow: 'none'
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: '14px', lineHeight: '35px', fontFamily: 'Poppins-500', textTransform: 'capitalize', fontWeight: 500
                }}
              >
                Save &amp; Publish
              </Typography>
            </LoadingButton>
          </Stack>

        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default ProductLayout;
