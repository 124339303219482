import { IPublicLoungeResult } from '../../models/public-lounge/public-lounge';
import BaseService from '../BaseService';

export class PublicLoungeService extends BaseService {
  async getPublicLounges() {
    const extraOptions = this.getAuthToken();

    return this.get<IPublicLoungeResult>('/public-lounges', extraOptions);
  }
}
