import {
  FC,
  useState,
} from 'react';
import classNames from 'classnames';

import {
  useDispatch, useSelector
} from 'react-redux';
import { toast } from 'react-toastify';
import { ReactComponent as ForwardSVG } from '../../../../assets/images/company/forward.svg';
import { ReactComponent as RequestSVG } from '../../../../assets/images/company/request.svg';
import { ReactComponent as ReportSVG } from '../../../../assets/images/company/report.svg';
import { ReactComponent as CopyLinkSVG } from '../../../../assets/images/company/copy-link.svg';
// import { ReactComponent as ShareSVG } from '../../../../assets/images/company/share.svg';

import AlertModal from '../../../../components/alert-modal/alert-modal';
import Report from '../../../../components/report';
import useFullUrl from '../../../../utils/hooks/fullUrl';
import useCopyToClipboard from '../../../../utils/hooks/copyToClipboard';
import {
  DropDownMenu,
  IDropdownMenuItem,
} from '../../../../components/dropdown-menu';
import { IFlagDTO } from '../../../../models/flag/flag';
import { FlagService } from '../../../../services/Flag';
import { UserService } from '../../../../services';
import { openMessageWindow } from '../../../../store/newMessage';
import ShareCompany from './company-share';
import { IAppState } from '../../../../store';
// import ShareCompany from './company-share';

interface ICompanyHeaderDropdownProps {
  company: any
  companyId: string;
  isOwner: boolean;
}

export const CompanyHeaderDropdown: FC<ICompanyHeaderDropdownProps> = ({
  companyId,
  isOwner,
  company
}) => {
  const user: any = useSelector((state: IAppState) => state.auth.currentUser);
  const dispatch = useDispatch();
  const getFullUrl = useFullUrl();
  const { handleCopy } = useCopyToClipboard();
  const [showReportModal, setShowReportModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const onCloseModal = () => setShowReportModal(false);

  // Forward event to message
  const sendAMessage = () => {
    const fullUrl = getFullUrl(`/wall/company/${companyId}?tab=post`);
    dispatch(
      openMessageWindow({
        avatar: '',
        userDisplayName: 'New Message',
        messageText: fullUrl,
      })
    );
  };

  // Share event post
  const onShareCompany = () => {
    setShowShareModal(!showShareModal);
  };

  // Copy link to clipboard
  const copyToClipboard = () => {
    const fullUrl = getFullUrl(`/wall/company/${companyId}?tab=post`);
    handleCopy(fullUrl);
    toast.success('Link copied to clipboard');
  };

  const optionsToSelect: IDropdownMenuItem[] = [
    {
      id: 1,
      type: 'icon',
      text: 'Forward',
      icon: <ForwardSVG />,
      onClickHandler: () => sendAMessage(),
    },
    // {
    //   id: 2,
    //   type: 'icon',
    //   text: 'Share',
    //   icon: <ShareSVG />,
    //   onClickHandler: () => onShareCompany(),
    // },
    {
      id: 3,
      type: 'icon',
      text: 'Copy Link',
      icon: <CopyLinkSVG />,
      onClickHandler: () => copyToClipboard(),
    },
    {
      id: 4,
      type: 'icon',
      text: 'Report',
      icon: <ReportSVG />,
      onClickHandler: () => setShowReportModal(true),
    },
    {
      id: 5,
      type: 'icon',
      text: 'Request admin access',
      icon: <RequestSVG />,
      onClickHandler: () => {
        const userService = new UserService();
        userService.requestAdminAccess(companyId).finally(() => {
          toast.success('Access requested');
        });
      },
    },
  ];

  const ownerOptionsToSelect: IDropdownMenuItem[] = [
    {
      id: 1,
      type: 'icon',
      text: 'Forward',
      icon: <ForwardSVG />,
      onClickHandler: () => sendAMessage(),
    },
    // {
    //   id: 2,
    //   type: 'icon',
    //   text: 'Share',
    //   icon: <ShareSVG />,
    //   onClickHandler: () => onShareCompany(),
    // },
    {
      id: 3,
      type: 'icon',
      text: 'Copy Link',
      icon: <CopyLinkSVG />,
      onClickHandler: () => copyToClipboard(),
    },
  ];

  return (
    <>
      {showReportModal && (
        <AlertModal
          className="w-576"
          isOpen={showReportModal}
          onClose={onCloseModal}
          closeIconClass=" right-[18px] top-[15px] px-0"
          isCloseIcon
        >
          <Report
            id={companyId}
            type="company"
            onReport={async (id: string, type: string, reason: string) => {
              const flagService = new FlagService();
              const flagItem: IFlagDTO = {
                flaggableId: id,
                flaggableType: type,
                reason,
              };
              await flagService.flagItem(flagItem);
            }}
            onCloseReport={onCloseModal}
          />
        </AlertModal>
      )}
      {showShareModal && (
        <AlertModal className="w-[500px]" isOpen={showShareModal} onClose={onShareCompany}>
          <ShareCompany onCloseShare={onShareCompany} company={company} user={user} />
        </AlertModal>
      )}
      <DropDownMenu
        options={isOwner ? ownerOptionsToSelect : optionsToSelect}
        containerClasses="flex justify-center items-center"
        menuCardClasses={classNames({
          'absolute w-208 opacity-100 bg-white -ml-[210px] mt-4 shadow-xs2 text-gray-13 rounded-15 p-2 z-10':
            true,
          hidden: false,
        })}
        titleAndSubtitleGroup="flex justify-left items-left hover:bg-gray-1 text-left p-2 rounded-5 text-gray-13 cursor-pointer"
        buttonStyles="flex items center ml-1"
        listTextStyles="text-sm1 text-blue-3 tracking-wide ml-3"
        listIconStyles=""
        textAndImageContainer="flex w-full h-full"
        fillColor="GRAY"
        fillColorActive
        isCompanyPage
      />
    </>
  );
};
