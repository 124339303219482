import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '..';
import { Avatar } from '../V2/Avatar';
import { Logo } from '../V2/Logo';

export interface ICardProfileProps {
  imageUrl: string;
  avatarUrl: string;
  name: string;
  occupation: string;
  location: string;
  className?: string;
  detailsHref: string;
  status?: 'ONLINE' | 'OFFLINE';
}

export const CardProfile: FC<ICardProfileProps> = ({
  imageUrl,
  avatarUrl,
  name,
  occupation,
  location,
  className = '',
  detailsHref: detailsUrl,
  status
}) => (
  <div className={classNames("shadow-md rounded-lg md:w-full bg-white", {
    [`${className}`]: className
  })}>
    <Logo className="rounded-t-lg w-full h-28 object-cover" src={imageUrl} alt="" />
    <Avatar
      alt={name}
      src={avatarUrl}
      className="-mt-12 ml-3 w-24 border-gray border-1"
      size="LARGE"
      status={status}
    />
    <div className="p-2 bg-white rounded-b-lg">
      <div className="ml-3 text-left">
        <Link to={detailsUrl}>
          <Typography variant="NameTitleMedium" text={name} />
        </Link>
        <Typography variant="SubTitle" text={occupation} />
        <Typography variant="H3" text={location} />
      </div>
    </div>
  </div>
);
