import { useTheme } from "@mui/material";
import { useState } from "react"
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface IProfileArrowSvgIcon {
  isActive?: boolean;
}
export const ProfileArrowSvgIcon = ({ isActive }: IProfileArrowSvgIcon) => {
  const theme = useTheme();
  const [isHover, setIsHover] = useState<boolean>(false);
  const activeColor = getBrandingColors()?.Accent || '#03FFCD';
  const mainColor =  getBrandingColors()?.Main || '#ffffff';
    const defaultColor = '#ffffff';
  const fillColor = true ? activeColor : defaultColor;

  return <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" onMouseEnter={() => setIsHover(true)}
  onMouseLeave={() => setIsHover(false)}>
  <g id="Group_21488" data-name="Group 21488" transform="translate(-20.817 -16.604)">
    <circle id="Ellipse_1029" data-name="Ellipse 1029" cx="8.5" cy="8.5" r="8.5" transform="translate(20.817 16.604)" fill={fillColor}/>
    <path id="Polygon_9" data-name="Polygon 9" d="M5.721.968a1,1,0,0,1,1.558,0L11.69,6.449a1,1,0,0,1-.779,1.627H2.089A1,1,0,0,1,1.31,6.449Z" transform="translate(35.817 30.173) rotate(180)" fill={mainColor}/>
  </g>
</svg>
};




