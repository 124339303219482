/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';
import { AccordionParseContent } from 'src/utils/parse-html/AccordionParseContent';
import CompanyDetails from './company-details';
import UserDetails from './user-details';

interface AccordionBodyProps {
  isOpen: boolean;
  eventSpeakers:any[],
  sessionDetails: {
    time: string;
    title: string;
    description:string
  },
  eventSponsors:any[];
  isBreakoutSession?: boolean,
  isBreakoutSession5?: boolean,
  isByDrivers?: boolean,
  isEmptyPhoto?: boolean;

}

const AccordionBody: React.FC<AccordionBodyProps> = ({
  // eslint-disable-next-line no-unused-vars
  isOpen,
  eventSpeakers,
  sessionDetails,
  eventSponsors,
  isBreakoutSession,
  isBreakoutSession5,
  isByDrivers,
  isEmptyPhoto
}) => (
  <div className={classNames('px-[23px] pb-[35px] bg-[#14161A] pt-[10px]', {
    '!pt-[10px]': isBreakoutSession5,
    '!pt-[0px]': isBreakoutSession,
  })}
  >
    <div
      className={classNames('flex lg:flex-row flex-col-reverse justify-start items-start', {
        '!flex-col-reverse': isBreakoutSession,
        '!gap-[15px]': !isBreakoutSession,
        'md:ml-[300px]': !isBreakoutSession && !eventSpeakers?.length && isEmptyPhoto,
        '!ml-0': !isBreakoutSession && !isEmptyPhoto,
        '!flex-col-reverse gap-[5px]': isBreakoutSession5,
      })}
    >
      {eventSpeakers?.length && !isBreakoutSession ? (
        <div
          className={classNames('flex flex-col', {
            'w-[285px] min-w-[285px]': eventSpeakers?.length || isByDrivers,
            'w-full -mt-[7px]': isBreakoutSession5,
          })}
        >
          { eventSpeakers?.map((speaker:any) => {
            const firstName = speaker?.firstName;
            const lastName = speaker?.lastName;
            const companyName = speaker?.company?.name;
            const jobTitle = speaker?.jobTitle;
            const photo = speaker?.photo;
            const userSlug = speaker?.user?.slug;

            const user = {
              name: `${firstName} ${lastName}`,
              position: jobTitle,
              company: companyName,
              isSpeaker: !!firstName,
              slug: `/wall/${userSlug}?tab=about`
            };

            return (
              <div
                className={classNames('mt-[19px]', {
                })}
                key={speaker?.id}
              >
                <UserDetails isBreakoutSession5={isBreakoutSession5} UserPicture={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${photo}`} user={user} speaker={speaker} />
              </div>
            );
          }) }

        </div>
      ) : null}
      <div
        className={classNames('flex flex-col', {
          '!ml-0 !mt-[15px]': isBreakoutSession,
          '!ml-0 w-full': isBreakoutSession5
        })}
      >
        { sessionDetails?.description
        && (
        <div className={classNames('text-white md:leading-[23px] leading-[19px] md:text-[17px] text-[14px] pt-[0px] pb-[23px] font-normal helvetica-neue-55-roman text-left sm:max-w-none max-w-[80%]', {
          '!pb-0 ': !eventSpeakers?.length
        })}
        >
          <AccordionParseContent content={sessionDetails?.description} />
        </div>
        )}

        {eventSponsors?.length > 0 && <div className="text-white md:leading-[23px] leading-[19px] md:text-[17px] text-[14px] font-medium helvetica-neue-34-light text-left pb-[11px]">Powered by:</div>}
        { eventSponsors?.map((sponsor:any) => {
          const sponsorDetails = {
            name: sponsor?.company?.name,
            industry: sponsor?.company?.industry?.name,
            logo: `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${sponsor?.logo || sponsor?.company?.logo}`,
            companyPage: `/wall/company/${sponsor?.company?.slug}`,
            website: `/wall/${sponsor?.company?.website}`,
            dominantColor: sponsor?.logo ? sponsor?.logoDominantColor : sponsor?.company?.logoDominantColor
          };
          return <div className="mb-[6px]"><CompanyDetails isBreakoutSession5={isBreakoutSession5} isBreakoutSession={isBreakoutSession} company={sponsorDetails} /></div>;
        }) }
        {isBreakoutSession && !isByDrivers ? (
          <div
            className={classNames('flex flex-col', {
              'w-[285px] min-w-[285px]': eventSpeakers?.length || isByDrivers,
              'w-full -mt-[7px]': isBreakoutSession5,
            })}
          >
            { eventSpeakers?.map((speaker:any) => {
              const firstName = speaker?.firstName;
              const lastName = speaker?.lastName;
              const companyName = speaker?.company?.name;
              const jobTitle = speaker?.jobTitle;
              const photo = speaker?.photo;
              const userSlug = speaker?.user?.slug;

              const user = {
                name: `${firstName} ${lastName}`,
                position: jobTitle,
                company: companyName,
                isSpeaker: !!firstName,
                slug: `/wall/${userSlug}?tab=about`
              };

              return (
                <div
                  className={classNames('mt-[19px]', {
                  })}
                  key={speaker?.id}
                >
                  <UserDetails isBreakoutSession5={isBreakoutSession5} UserPicture={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${photo}`} user={user} speaker={speaker} />
                </div>
              );
            }) }

          </div>
        ) : null }
      </div>
    </div>
  </div>
);

export default AccordionBody;
