import React, { FC } from "react";
import { IUserProfile } from "../../models/user-profile";
import { Logo } from "../../shared-components/V2/Logo";
import { useSurveyContext } from "src/mui/providers/GeneralContext";

interface IMeetingUserCard {
    user: IUserProfile;
    hasRemoteUsers?: boolean
}
export const MeetingUserCard:FC<IMeetingUserCard> = React.memo(({ user, hasRemoteUsers }: IMeetingUserCard) => {
  const { screenId } = useSurveyContext();
    return <div className={`flex flex-col items-center ${hasRemoteUsers && (screenId === null) && ""} ${screenId ? "w-[30vw] h-[280px]" : "w-full h-full"} rounded-xl bg-black overflow-hidden`}>
      <Logo src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${user?.avatar}`} className="w-full h-full object-contain" />
    </div>
  })