import { Button, Stack } from '@mui/material';
import MicOffIcon from '../../../assets/icons/both-icons/mic-off-icon.svg';
import MicOnIcon from '../../../assets/icons/both-icons/mic-icon.svg';
import VideoOnIcon from '../../../assets/icons/both-icons/video-icon.svg';
import VideoOffIcon from '../../../assets/icons/both-icons/video-off-icon.svg';
import ImageIcon from '../../../assets/icons/both-icons/image-icon.svg';
import { CSSTransition } from 'react-transition-group';
import { reactions } from './Constants';
import EmojiIcon from '../../../assets/icons/both-icons/imoji-icon.svg';
import { ContentShareControl, ControlBar, Phone } from 'amazon-chime-sdk-component-library-react';
import { IColors } from './liveEventStyling';

export function LiveVideoCallControls(props: {
  onClick: () => void,
  muted: boolean,
  onClick1: () => Promise<void>,
  videoEnabled: boolean,
  onClick2: () => void,
  in: boolean,
  colors: IColors | undefined,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
  callbackfn: (emoji: any, index: any) => JSX.Element,
  onMouseEnter1: () => void,
  onClick3: () => void
}) {
  return <Stack
    direction="row"
    sx={{
      position: 'absolute',
      bottom: 0,
      left: 10,
      gap: '4px',
      height: '70px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingRight: '80px',
    }}
  >
    <Button
      onClick={props.onClick}
      sx={{
        borderRadius: '2px',
        color: '#ffffff',
        height: '38px',
        width: '43px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '13px',
        fontFamily: 'Poppins-600',
        background: '#262626',
        textTransform: 'none',
        padding: '0px',
        minWidth: 'auto',
        fontWeight: 600,
        '&:hover': {
          background: '#262626',
          opacity: 0.8,
        },
      }}
    >
      <img
        src={props.muted ? MicOffIcon : MicOnIcon}
        alt={props.muted ? 'MicIconOff' : 'MicIconOn'}
      />
    </Button>

    <Button
      onClick={props.onClick1}
      sx={{
        borderRadius: '2px',
        color: '#ffffff',
        height: '38px',
        width: '43px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '13px',
        fontFamily: 'Poppins-600',
        background: '#262626',
        textTransform: 'none',
        padding: '0px',
        minWidth: 'auto',
        fontWeight: 600,
        '&:hover': {
          background: '#262626',
          opacity: 0.8,
        },
        '& div': {
          padding: 0,
          backgroundColor: 'red',
        },
      }}
    >
      <img
        src={props.videoEnabled ? VideoOnIcon : VideoOffIcon}
        alt={props.videoEnabled ? 'On' : 'Off'}
      />
    </Button>

    <Button
      onClick={props.onClick2}
      sx={{
        borderRadius: '2px',
        color: '#ffffff',
        height: '38px',
        width: '43px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '13px',
        fontFamily: 'Poppins-600',
        background: '#262626',
        textTransform: 'none',
        padding: '0px',
        minWidth: 'auto',
        fontWeight: 600,
        '&:hover': {
          background: '#262626',
          opacity: 0.8,
        },
      }}
    >
      <img src={ImageIcon} alt="ImageIcon"/>
    </Button>
    <Stack sx={{ position: 'relative' }}>
      <CSSTransition
        in={props.in}
        timeout={400}
        classNames={{
          enter: 'opacity-0',
          enterActive:
            'opacity-100 transition-opacity duration-400 ease-in-out',
          exit: 'opacity-100',
          exitActive:
            'opacity-0 transition-opacity duration-400 ease-in-out',
        }}
        unmountOnExit
      >
        <Stack
          sx={{
            position: 'absolute',
            top: '-37px',
            left: '50%',
            ml: '-90px',
            backgroundColor: props?.colors?.Accent || '#000000',
            height: '37px',
            borderRadius: '37px',
            width: '180px',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '4px',
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        >
          {reactions?.map(props.callbackfn)}
        </Stack>
      </CSSTransition>
      <Button
        onMouseEnter={props.onMouseEnter1}
        onMouseLeave={props.onMouseLeave}
        sx={{
          borderRadius: '2px',
          color: '#ffffff',
          height: '38px',
          width: '43px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '13px',
          fontFamily: 'Poppins-600',
          background: '#262626',
          textTransform: 'none',
          padding: '0px',
          minWidth: 'auto',
          fontWeight: 600,
          '&:hover': {
            background: '#262626',
            opacity: 0.8,
          },
        }}
      >
        <img src={EmojiIcon} alt="EmojiIcon"/>
      </Button>
    </Stack>

    <ControlBar
      layout="undocked-horizontal"
      showLabels={false}
      className=""
      css="{ background-color: inherit; height: 38px !important; gap: 4px; } .ch-control-bar-item-iconButton .ch-icon {height: 32px !important; width: 32px !important; color: #ffffff;} .ch-content { font-size: 10px; } .control-bar-item {margin: 0; height: 38px; width: 43px; grid-template-columns: 1fr; grid-template-rows: 1fr;} .ch-control-bar-item-iconButton { margin: 4px -6px 0 0; height: 38px; width: 43px; } .ch-control-bar-item-iconButton:hover { margin: 4px -6px 0 0; height: 38px; width: 43px; background-color: transparent } .ch-control-bar-item-iconButton:focus { border: none; box-shadow: none } .ch-control-bar-item-caret {display: none;}"
    >
      {/* <AudioInputControl />
                  <VideoInputBackgroundBlurControl /> */}

      <Button
        // onClick={toggleVideo}
        sx={{
          borderRadius: '2px',
          color: '#ffffff',
          height: '38px',
          width: '43px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '13px',
          fontFamily: 'Poppins-600',
          background: '#262626',
          textTransform: 'none',
          padding: '0px',
          minWidth: 'auto',
          fontWeight: 600,
          '&:hover': {
            background: '#262626',
            opacity: 0.8,
          },
        }}
      >
        <ContentShareControl/>
      </Button>
      <Button
        // onClick={toggleVideo}
        sx={{
          borderRadius: '2px',
          color: '#ffffff',
          height: '38px',
          width: '43px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '13px',
          fontFamily: 'Poppins-600',
          background: '#262626',
          textTransform: 'none',
          padding: '0px',
          minWidth: 'auto',
          fontWeight: 600,
          '&:hover': {
            background: '#262626',
            opacity: 0.8,
          },
        }}
      >
        <Phone
          width="1.3rem"
          className="cursor-pointer text-white bg-red-500 rounded-full p-1 shadow flex items-center justify-center"
          onClick={props.onClick3}
        />
      </Button>
    </ControlBar>
  </Stack>;
}
