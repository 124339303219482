import classNames from 'classnames';
import React, { FC } from "react";
export interface INavMenuCardProps {
  cardItemText?: string;
  className?: string;
}

export const NavMenuCard: FC<INavMenuCardProps> = ({ cardItemText, className }) => {
  return (
    <div
      className={classNames('rounded-[5px] hover:bg-[#E8EBED] h-[50px] px-2.5 text-[#000000] flex items-center', {
        [`${className}`]: className
      })}
    >
      {cardItemText!}
    </div>
  );
};
