/* eslint-disable max-len */
import React from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import userDerault from '../images/avatar-default.svg';

interface InsightItemProps {
  speaker: any;
}

const InsightItem: React.FC<InsightItemProps> = ({ speaker }) => {
  const {
    sessionTitle,
    sessionSummary,
    firstName,
    lastName,
    // bio,
    jobTitle,
    photo,
    company: { name: companyName },
  } = speaker;
  // const fullBio = bio && parse(DOMPurify.sanitize(bio));
  const summary = sessionSummary && parse(DOMPurify.sanitize(sessionSummary));

  return (
    <div className="w-full md:max-w-full max-w-[283px]">
      <div className="flex lg:flex-row md:flex-col flex-col-reverse">
        <div className="lg:w-[calc(100%-406px)] w-full">
          <div className="text-white md:leading-[42px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium text-left md:mb-[32px] mb-[23px] md:flex hidden">Inspiration and insight from:</div>
          <div className="bg-[#14161A] md:min-h-[372px] min-h-[350px] md:max-h-[372px] max-h-[350px] md:rounded-10 rounded-b-[10px] xl:px-[109px] md:px-[29px] px-[19px] xl:pt-[47px] pt-[26px] pb-[28px] lg:-mr-[36px] lg:relative z-10">
            <div className="text-white leading-[25px] md:text-[23px] text-[18px] helvetica-neue-65-medium !font-semibold text-left mb-[18px] md:line-clamp-1 line-clamp-2">{sessionTitle}</div>
            <div className="max-w-[458px] text-white md:leading-[23px] leading-[19px] md:text-[17px] text-[14px] mb-[40px]  helvetica-neue-55-roman line-clamp-5">{summary}</div>
            <div className="w-[23px] h-[5px] mb-[30px] bg-[#D6244D] border-red-500" />
            <div className="text-white md:leading-[21px] leading-[20px] md:text-[20px] text-[16px] helvetica-neue-65-medium font-semibold text-left mb-[4px]">
              {firstName}
              {' '}
              {lastName}
            </div>
            <div className="text-white md:leading-[17px] leading-[14px] md:text-[14px] text-[10px] helvetica-neue-55-roman text-left">{jobTitle}</div>
            <div className="text-white md:leading-[17px] leading-[14px] md:text-[14px] text-[10px] helvetica-neue-55-roman text-left">{companyName}</div>
          </div>
        </div>
        <div className="lg:w-[406px] lg:h-[515px] bg-[#333333] w-full md:h-[400px] h-[355px] flex lg:justify-end justify-center lg:mt-0 -mt-[17px] md:rounded-10 rounded-t-[10px] overflow-hidden"><img className="h-full w-full object-cover" src={photo ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${photo}` : userDerault} alt="" /></div>
      </div>
    </div>
  );
};

export default InsightItem;
