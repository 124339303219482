/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
// import { useNavigate } from 'react-router';
// import { useSelector } from 'react-redux';
// import { IAppState } from 'src/store';
import { UserNavbar } from '../../../components';
import CompanyAccounts from '.';

export const CompanyAccountsLayout = () => {
  // const { companyProfilePreview } = useSelector((state: IAppState) => state);
  // const navigate = useNavigate();
  // const backToEdit = (slug: any) => {
  //   if (slug) {
  //     navigate(`/wall/company/${companyProfilePreview.value?.slug}/edit`);
  //   }
  // };
  return (
    <div className="bg-[#EDF2F5] pb-[90px]">
      <UserNavbar />
      <div className="lg:container mx-auto lg:w-[1258px] w-full lg:px-0 px-4 md:pb-0 pb-[60px] mt-[30px] relative">
        <div className="w-[100%] flex justify-between items-center gap-[] mb-[25px]">
          <p className="text-[#000000] text-[25px] leading-[33px] font-medium">Accounts List</p>
          {/* <button
            type="button"
            className="rounded-5 px-[10px] h-[35px] w-[160px] bg-[#0049EF] hover:bg-dark-blue-3 text-sm leading-5 text-white font-semibold"
            onClick={backToEdit}
          >
            Back to Edit
          </button> */}
        </div>
        <div className="xl:w-full lg:w-full w-full">
          <CompanyAccounts />
        </div>
      </div>
    </div>
  );
};

export default CompanyAccountsLayout;
