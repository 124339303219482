import { useLocation, useNavigate } from 'react-router-dom';
import LoginForm from '../../../login/login-form';
import WebsiteEventQuickLinks from '../website-event-quicklinks';

export const UserLoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const eventSlug = location?.pathname?.split('/')[2];

  const changePage = (page: number) => {
    if (page === 3) {
      sessionStorage.setItem('eventRegStep', '3');
      navigate(`/event/${eventSlug}/spectre/successfully-registered`);
    }
    if (page === 0) {
      navigate(`/event/${eventSlug}/spectre/loginorjoin`);
    }
  };

  const onBackEvent = () => {
    navigate(`/event/${eventSlug}/spectre`);
  };
  return (
    <div className="w-full">
      <div className="flex -mb-[30px] h-[100vh]">
        <div className="bg-[#2d2d2d] pt-[20px] pr-[40px] pb-[30px] pl-[40px] w-full md:max-w-[60%] flex items-center">
          <div className="w-full">
            <div
              className="w-[122px] h-[32px] rounded-full bg-white text-black text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer mb-[40px]"
              onClick={onBackEvent}
            >
              Back to event
            </div>
            <div className="mt-[100px]">
              <div className="text-white text-center text-[25px] leading-[30px] helvetica-neue-65-medium mb-[20px]">Log in</div>
              <div className="text-white text-center text-[13px] leading-[20px] helvetica-neue-55-roman mb-[40px]">
                The email you have entered is already registered on Panelist,
                enter your password to login
              </div>
              <div className="mx-auto w-full">
                <LoginForm from="event" isSpectreForm isEventRegister changePage={changePage} />
              </div>
            </div>
          </div>
        </div>
        <div className="reg-bg md:w-[40%]" />
      </div>
      <WebsiteEventQuickLinks />
    </div>
  );
};
export default UserLoginForm;
