/* eslint-disable max-len */
import { useState } from 'react';

interface IPostSvgIcon {
  isActive?: boolean;
  className?: string;
  disableHover?: boolean;
}
export const PostSvgIcon = ({ isActive, className, disableHover }: IPostSvgIcon) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const colorWhite = '#ffffff';
  const defaultColor = '#7283A4';
  const fillColor = !disableHover && (isHover || isActive) ? colorWhite : defaultColor;

  return (
    <svg
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24.099"
      height="27.001"
      viewBox="0 0 24.099 27.001"
    >
      <g id="Group_18887" data-name="Group 18887" transform="translate(-2868 7516.625)">
        <path id="Path_14634" data-name="Path 14634" d="M1.271,0H19.059A1.271,1.271,0,0,1,20.33,1.271V21.918a1.271,1.271,0,0,1-1.271,1.271H1.271A1.271,1.271,0,0,1,0,21.918V1.271A1.271,1.271,0,0,1,1.271,0Z" transform="translate(2870.583 -7515.561) rotate(-3)" fill={fillColor} />
        <path id="Subtraction_5" data-name="Subtraction 5" d="M19.059,30.814H1.271A1.271,1.271,0,0,1,0,29.543V8.9A1.271,1.271,0,0,1,1.271,7.625h.114L2.46,28.155a1.271,1.271,0,0,0,1.268,1.2c.023,0,.045,0,.068,0l16.534-.867v1.052A1.271,1.271,0,0,1,19.059,30.814Z" transform="translate(2868 -7520.438)" fill={fillColor} />
      </g>
    </svg>
  );
};
