export const HoldEvents = () => (
  <div className="mt-8 w-full h-full bg-gray-3 lg:-mt-5">
    <div className="block ml-32 2xl:mx-auto 2xl:container">
      <div className="block md:flex 2xl:ml-32 w-11/12">
        <div className="text-left  mt-2 pt-3">
          <div className="md:ml-5">
            <p className="lg:text-xl3 font-extralight text-xl text-dark-blue mt-10 md:mt-6">
              Hold your events at the
              <br />
              {' '}
              right time, in the right place
            </p>

            <p className="text-lg font-extralight text-dark-blue mt-5 ">
              Professionals are most engaged when contacted
              {' '}
              <br />
              in the right setting and in the comfort of a familiar
              {' '}
              <br />
              and trusted environment.
            </p>
          </div>
        </div>

        <div className="bg-blue-0 text-left 2xl:ml-48 ml-7 -mt-20 p-5 pt-3 rounded-3xl lg:w-576 lg:h-412 mb-16">
          <div className="md:ml-5 pl-1 ">
            <div className="lg:text-xl3 font-extralight text-xl text-dark-blue md:mt-12 lg:mb-20">
              A platform everyone's familiar with
            </div>
            <div className="block text-center md:text-left lg:flex  -mt-6">
              <div className="5x">
                <div className="text-3xl font-normal text-xl text-dark-blue mt-10">5x</div>
                <div className="lg:text-md3 mt-10 font-normal">More</div>
                <div className="lg:text-md3 font-normal">attendance </div>
              </div>

              <div className="8x ml-16 pl-4">
                <div className="text-3xl font-normal text-xl text-dark-blue mt-10">8x</div>
                <div className="lg:text-md3 mt-10 font-normal">More real-time</div>
                <div className="lg:text-md3 font-normal">engagement </div>
              </div>

              <div className="12x ml-16 pl-4">
                <div className="text-3xl font-normal text-xl text-dark-blue mt-10">12x</div>
                <div className="lg:text-md3 mt-10 font-normal">More post-event</div>
                <div className="lg:text-md3 font-normal">engagement </div>
              </div>
            </div>

            <div className="text-lg font-extralight text-dark-blue mt-5 text-left mb-16 pb-3">
              Than video-conferencing and broadcasting tools
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HoldEvents;
