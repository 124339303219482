/* eslint-disable no-underscore-dangle */
import RoomSwitcherTab from './room-switcher-tab';
import { IRoom } from '../../../models/event/room';
import { IAttendee } from '../../../models/events-live/attendee';
import { GroupUserIcons } from '../../../shared-components/V2/GroupUserIcons';

type RoomSwitcherProps = {
  rooms: IRoom[];
  attendees: IAttendee[];
  activeRoom: IRoom;
  onClick: Function;
};
const RoomSwitcher = (props: RoomSwitcherProps) => (
  <>
    <div className="px-2 py-2 border-blue-0 border-b-2">
      <div className="flex gap-2" style={{ lineHeight: '100%' }}>
        <div className="grid px-2 py-1 bg-gray-1 rounded-md">
          <span className="text-blue-4 text-h2 text-center">Discussion</span>
          <span className="text-blue-4 text-h2 text-center">Rooms</span>
        </div>
        <div className="flex gap-1 bg-gray-0 rounded-r-md border-l-2 border-blue-2 px-1 py-1 overflow-x-scroll">
          {props.rooms?.map((room, key) => (
            <RoomSwitcherTab key={key} room={room} isActive={props.activeRoom.id === room.id} onClick={(newRoom:IRoom) => props.onClick(newRoom)} />
          ))}
        </div>
      </div>
    </div>
    <div className="flex gap-2 px-3 py-3 border-blue-0 border-b-2 items-center">
      <GroupUserIcons users={props.attendees} userQty={3} baseUrl={process.env.REACT_APP_IMAGE_URL_PREFIX} className="text-sm font-medium text-blue-700" />
    </div>
  </>
);

export default RoomSwitcher;
