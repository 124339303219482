import { useContentShareState } from "amazon-chime-sdk-component-library-react";
import classNames from "classnames";
import React, { FC } from "react";

interface IVideoCallLayout {
    tiles: JSX.Element[];
    activeTileId?: number | null;
    isCallingFromLivePage?: boolean;
}

interface ITile {
    tile: JSX.Element;
    activeTileId: number;
    isCallingFromLivePage?: boolean;
}
export const VideoCallLayout: FC<IVideoCallLayout> = React.memo(({ tiles, activeTileId, isCallingFromLivePage }: IVideoCallLayout) => {
    const { sharingAttendeeId } = useContentShareState();
    const isScreenShared: boolean = !!sharingAttendeeId && sharingAttendeeId.trim() !== '';
    const Tile: FC<ITile> = React.memo(({ tile, activeTileId, isCallingFromLivePage }: ITile) => {
        return <div className={classNames("w-[351px] h-[207px] rounded-[6px] overflow-hidden object-contain border-2", {
            "border-4 border-yellow-500": activeTileId && activeTileId === tile?.props?.tileId,
            "!w-full !h-full": isCallingFromLivePage && !isScreenShared,
            "!h-[calc(34vh-99px)] 2xl:!h-[210px] !w-full": isCallingFromLivePage && isScreenShared,
        })}>{tile}</div>
    }, (prevProps, nextProps) => prevProps.activeTileId !== nextProps.activeTileId)
    return <div
                className={classNames("flex flex-wrap gap-2 justify-center items-center h-full w-full", {
                    "!flex-row !flex-nowrap": isCallingFromLivePage && !isScreenShared,
                    "!flex-wrap !h-auto": isCallingFromLivePage && isScreenShared,
                })}
            >
        {
            tiles.map(tile => <Tile activeTileId={activeTileId!} tile={tile} isCallingFromLivePage={isCallingFromLivePage} />)
        }
    </div>
}, (prevProps, nextProps) => (prevProps.activeTileId === nextProps.activeTileId) && (prevProps.tiles === nextProps.tiles));
