import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { EventWebsiteTemplateType } from 'src/mui/types/survey';
import { IAppState } from '../../../store';
import { Logo } from '../../../shared-components/V2/Logo';
import { IEvent } from '../../../models/event/event';
import companyDefault from '../../../assets/images/company-default.svg';
import { PartnerPrivacyLink } from './enum';

const WebsiteEventPartner = () => {
  const partners = useSelector((state: IAppState) => state.website.event!);
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);

  if (!partners?.eventSponsors?.length) return null;
  const isOraclePartnerOfEvent = !!partners?.eventSponsors?.find((p) => p?.company?.name?.toLocaleLowerCase()?.includes('oracle'));
  const isOracleEvent = event?.slug.includes('oracle') || isOraclePartnerOfEvent || false;

  return (
    <div
      id="partners"
      className={classNames('w-full mb-[15px]', {
        hidden: !(partners.eventSponsors && partners.eventSponsors.length > 0),
        'mb-[100px]': !(partners.format === 'hybrid'),
      })}
    >
      <div
        className={`w-full ${
          event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS
            ? 'event-afterhours-box'
            : 'event-website-box'
        } pt-[22.5px] pb-[36.5px]`}
      >
        <div
          className={classNames(
            'font-semibold text-[21px] leading-[25px] flex justify-center mb-[28px]',
            {
              'text-[#0326E5]':
                event?.websiteTemplate !== EventWebsiteTemplateType.AFTER_HOURS,
              'text-[#FFFFFF]':
                event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
            }
          )}
          style={{
            color: event?.colorStyle?.values?.heading,
          }}
        >
          {/* https://panelist.com/Factor/gotomarketstrategieswebinar */}
          { event?.id === 'a79c1adc-1422-44b2-aeab-29a01bb6cb1e'
            ? 'Hosted by' : 'Event partners' }
        </div>
        <div className="flex flex-wrap max-w-[730px] mx-auto">
          <div className="flex flex-wrap justify-center items-center md:gap-x-[108px] gap-x-[80px] md:gap-y-[26px] gap-y-[26px] w-full">
            {partners.eventSponsors.map((item) => {
              const key = item.company.name?.toLocaleLowerCase() as keyof typeof PartnerPrivacyLink;

              return (
                <div
                  key={item.id}
                  className="w-[100px] flex flex-col items-center justify-center"
                >
                  {item.company?.logo || item.logo ? (
                    <div className="mb-[18px] flex items-center justify-center rounded-[6px] bg-white w-[85px] h-[85px]">
                      <Logo
                        src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${
                          item.logo ?? item.company?.logo
                        }`}
                        className="w-[83px] h-[83px] object-contain rounded-[6px]"
                      />
                    </div>
                  ) : (
                    <div className="bg-[#edf2f5] w-[85px] h-[85px] rounded-[6px] flex items-center justify-center">
                      <img
                        className="w-[83px] h-[83px] object-contain rounded-[6px]"
                        src={companyDefault}
                        alt=""
                      />
                    </div>
                  )}
                  <div className="h-[51px]">
                    <div
                      className={classNames(
                        'text-[12px] font-semibold  md:line-clamp-1 line-clamp-1 text-center',
                        {
                          'text-[#0B1221]':
                          event?.websiteTemplate
                          !== EventWebsiteTemplateType.AFTER_HOURS,
                          'text-[#FFFFFF]':
                          event?.websiteTemplate
                          === EventWebsiteTemplateType.AFTER_HOURS,
                        }
                      )}
                    >
                      {item.company.name}
                    </div>
                    <div
                      className={classNames(
                        'text-[11px] font-light line-clamp-2 text-center',
                        {
                          'text-[#3C3C3C]':
                          event?.websiteTemplate
                          !== EventWebsiteTemplateType.AFTER_HOURS,
                          'text-[#FFFFFF]':
                          event?.websiteTemplate
                          === EventWebsiteTemplateType.AFTER_HOURS,
                        }
                      )}
                    >
                      {item.sponsorship}
                    </div>
                    {isOracleEvent && key && PartnerPrivacyLink[key] && (
                    <a
                      className="rounded-xl inline-block text-blue-1 font-medium text-[15px] hover:underline helvetica-neue-35-thin-wide"
                      href={PartnerPrivacyLink[key]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventPartner;
