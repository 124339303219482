import { FC } from 'react';
import { UserNavbar } from '../components';

interface IEventsLiveLayoutProps {
  hero?: JSX.Element;
  leftPanel: JSX.Element;
  rightPanel: JSX.Element;
  footer: JSX.Element;
  children: JSX.Element;
}
export const EventsLiveLayout: FC<IEventsLiveLayoutProps> = ({
  hero,
  leftPanel,
  rightPanel,
  children,
  footer
}) => (
  <div className="bg-gray-1 h-full">
    <UserNavbar />
    <div>
      <div className="container mt-6 xl:max-w-none max-w-[100%] mx-auto xl:w-[1258px] md:w-[100%] w-full xl:px-0 px-4 lg:pb-0 pb-[50px]">
        {hero}
        <div className="flex lg:flex-row flex-col gap-5">
          <div className="flex flex-col lg:w-[285px] w-full md:m-0 m-auto">{leftPanel}</div>
          <div className="mb-8 lg:w-[620px] w-full md:m-0 m-auto">{children}</div>
          <div className="flex flex-col w-[315px] lg:m-0 m-auto">{rightPanel}</div>
        </div>
        {footer}
      </div>
    </div>
  </div>
);

export default EventsLiveLayout;
