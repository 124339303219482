import { LinkButton } from '../../../shared-components';
import compareplans from '../../../assets/images/feature/compareplan.svg';

export const ComparePackages = () => (
  <div className="container flex justify-center mx-auto">
    <div className="mt-4 flex flex-col items-center">
      <div className="text-lg text-gray-5 mt-5">Compare</div>
      <div className="text-md text-gray-4 font-light">Compare features by plan</div>
      <div className="mt-4 w-1124 2xl:w-full">
        <img src={compareplans} alt="Panelist" />
      </div>
      <div className="z-20 absolute ml-2" style={{ marginTop: '584px', marginLeft: '935px' }}>
        <LinkButton
          text="Contact Us"
          href="/contact-us"
          backgroundColor=""
          classes="bg-orange text-sm2 font-extralight hover:bg-orange-2"
          textColor="#172746"
          width="146px"
          height="30px"
        />
      </div>
    </div>
  </div>
);

export default ComparePackages;
