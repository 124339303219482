import classNames from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';

// import { POSITION } from 'react-toastify/dist/utils';
import { IconPng } from '../IconPng/IconPng';
import { Typography } from '../Typography/Typography';

export interface ITextInputProps extends HTMLAttributes<HTMLInputElement> {
  caption?: string;
  text?: string;
  value?: string;
  type: string;
  textinputsize: 'small' | 'medium' | 'large' | 'extraLarge' | 'withIcon';
  classes?: string;
  borderRadius?: string;
  backgroundColor?: string;
  imageUrl?: string[];
  iconWidth?: number;
  iconHeight?: number;
  styles?: string;
  autoFocus?: boolean;
  characterlength?: number;
  inputId?: string;
  disabled?: boolean;
  autoComplete?: string;
  labelclass?: string;
  isBlueTheme?: boolean;
  wrapClass?: string;
}

export const TextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  (props: ITextInputProps, ref) => {
    const sizeMap = {
      small: 'h-[31px] w-240 px-2.5',
      medium: 'h-[31px] w-full min-w-[80px] px-2.5',
      large: 'h-[31px] w-96 px-2.5',
      extraLarge: 'h-10 w-447 px-2.5',
      withIcon: 'h-10 md:w-438 px-2.5'
    };
    return (
      <div className={props.wrapClass} style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
        <span>
          <label
            style={{
              fontFamily: 'Poppins', 
              fontSize: '13px',
              fontWeight: 500,
              color: props.isBlueTheme ? '#ffffff' : '#3c3c3c',
              lineHeight: '20px',
              margin: ''
            }}
            htmlFor={props.id}
            className={classNames({
              [`${props.labelclass}`]: props.labelclass,
            })}
          >
            {props.caption}
          </label>
        </span>
        <input
          autoFocus={props.autoFocus}
          className={[props.classes, 'rounded-10 text-sm1', `${sizeMap[props.textinputsize]}`].join(
            ' '
          )}
          style={{
            border: '',
            borderColor: '',
            borderRadius: props.borderRadius,
            backgroundColor: props.backgroundColor
          }}
          ref={ref}
          value={props.value}
          maxLength={props.characterlength}
          id={props.inputId}
          disabled={props.disabled}
          autoComplete={props.autoComplete}
          {...props}

        />
        <div className={props.styles}>
          <Typography variant="FormFieldCaption" text={props.text || ''} />
          {props.imageUrl?.map((image) => {
            return (
              <IconPng
                imageUrl={image}
                iconWidth={props.iconWidth!}
                iconHeight={props.iconHeight!}
              />
            );
          })}
        </div>
      </div>
    );
  }
);
