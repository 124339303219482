import { Box, Typography, Stack } from '@mui/material';
import { getBrandingColors } from '../live/liveEventStyling';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { useSurveyContext } from 'src/mui/providers/GeneralContext';
import { useEffect, useState } from 'react';
import { NoEvents } from 'src/screens/events/event-tab/components/no-events';
import { AvatarDefaultSvgIcon } from 'src/shared-components/V2/IconSvg/AvatarDefaultSvgIcon/AvatarDefaultSvgIcon';

const PersonDetails = () => {
  const colors = getBrandingColors();
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const newAudience: any = useSelector(
    (state: IAppState) => state.chatVideoCall?.newAudienceData
  );
  const { getLatestAttendeeCheckedIn, latestAttendeeCheckedIn, setLatestAttendeeCheckedIn } = useSurveyContext();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (latestAttendeeCheckedIn && latestAttendeeCheckedIn.length > 0) {
      setCurrentIndex(0);
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % latestAttendeeCheckedIn.length);
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [latestAttendeeCheckedIn]);

  useEffect(() => {
    const latestAttendeeId =
      newAudience?.instance?.checkedInAt !== null ? newAudience?.instance?.userId : null;
    if (liveEvent?.event?.id) {
      getLatestAttendeeCheckedIn(liveEvent.event.id, latestAttendeeId);
    }
  }, [liveEvent?.event?.id, newAudience]);

  if (!latestAttendeeCheckedIn || latestAttendeeCheckedIn.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <NoEvents isThemeColor text='No attendee checked in yet.' />
      </Box>
    );
  }

  const currentAttendee = latestAttendeeCheckedIn[currentIndex];

  return (
    <Box sx={{ display: 'flex', gap: '19px', height: '100%', mt: '13px', mb: '10px' }}>
      {currentAttendee?.avatar ? (
        <Box
          component="img"
          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${currentAttendee?.avatar}`}
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: 288,
            maxHeight: '100%',
            borderRadius: '10px',
            objectFit: 'cover',
            backgroundColor: '#edf2f5',
          }}
        />
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: 288,
            maxHeight: '100%',
            borderRadius: '10px',
            objectFit: 'cover',
            backgroundColor: 'transparent',
            display: 'flex',
            border: `1px solid ${colors?.Text || '#000000'}`,
            justifyContent: 'center',
            alignItems: 'center',
            "& svg": {
              width: '50%'
            }
          }}
        >
          <AvatarDefaultSvgIcon fillColor={colors?.Text} />
        </Box>
      )}

      <Box sx={{ flex: 1, minWidth: '265px' }}>
        <Typography
          sx={{
            fontSize: '29px',
            fontWeight: 500,
            fontFamily: 'Poppins-500',
            color: colors?.Text || '#100c7f',
            lineHeight: '41px',
            mb: '10px',
          }}
        >
          Just checked in
        </Typography>

        <Typography
          sx={{
            fontSize: '29px',
            fontWeight: 500,
            fontFamily: 'Poppins-500',
            lineHeight: '41px',
            mb: '5px',
            color: colors?.Text || '#100c7f',
          }}
        >
          {currentAttendee?.fullName}
        </Typography>

        <Typography
          sx={{
            fontSize: '19px',
            color: colors?.Text || '#100c7f',
            fontFamily: 'Poppins-400',
            fontWeight: 400,
            lineHeight: '24px',
            mb: '19px',
          }}
        >
          {currentAttendee?.jobTitle}
          <br />
          {currentAttendee?.company?.name}
        </Typography>

        <Typography
          sx={{
            fontSize: '14px',
            fontFamily: 'Poppins-500',
            fontWeight: 500,
            color: colors?.Text || '#100c7f',
            mb: '10px',
          }}
        >
          Interests:
        </Typography>

        <Stack direction="row" spacing={2} sx={{ flexWrap: 'wrap', gap: '5px' }}>
          {currentAttendee?.interests?.length > 0
            ? currentAttendee.interests.map((interest: string, index: number) => (
                <Box
                  key={index}
                  sx={{
                    bgcolor: colors?.Secondary || '#edecf3',
                    borderRadius: '4px',
                    width: 'fit-content',
                    height: '33px',
                    display: 'flex',
                    alignItems: 'center',
                    px: '14px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontFamily: 'Poppins-500',
                      fontWeight: 500,
                      color: colors?.Text || '#000000',
                      lineHeight: '33px',
                    }}
                  >
                    {interest}
                  </Typography>
                </Box>
              ))
            : <Typography
            sx={{
              fontSize: '12px',
              fontFamily: 'Poppins-500',
              fontWeight: 500,
              color: colors?.Text || '#000000',
              lineHeight: '33px',
            }}
          >
            No interests available
          </Typography>}
        </Stack>
      </Box>
    </Box>
  );
};

export default PersonDetails;