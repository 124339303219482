import { Avatar, Fade, Stack } from '@mui/material';
import { Transition } from '@tailwindui/react';
import classNames from 'classnames';
import { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonWithTextAndImage } from '../../../shared-components/V2';
import { UserDropdownSvgIcon } from '../../../shared-components/V2/IconSvg';
import { getCompanySlug } from '../../../utils';
import { useCurrentUser, useUserDetails } from '../../../utils/hooks';
import useCloseMenuWithClickOutside from '../../../utils/hooks/closeMenuWithClickOutside';
import { ProfileArrowSvgIcon } from './SvgIcons/ProfileArrowSvgIcon';
import { getBrandingColors } from '../event/live/liveEventStyling';


type UserMenu = {
  panelPplacement?: string;
};

const UserMenu = ({ panelPplacement }: UserMenu) => {
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const { showDropdown, handleDropdown } = useCloseMenuWithClickOutside(ref);
  const user = useCurrentUser();
  const { avatar, fullName, jobTitle, company, companyAdmin, companySlug } = useUserDetails(user);

  const handleUserMenuDropdown = () => {
    handleDropdown(showDropdown);
  };

  const onCompanyClick = () => {
    const companySlug = getCompanySlug(user);
    if (!companySlug) return;
    navigate(`/wall/company/${companySlug}`);
  }

  return (
    <div className="relative user-menu" ref={ref} onClick={() => handleUserMenuDropdown()}>
      <button
        className="menu focus:outline-none focus:shadow-solid relative">
        {/* <Avatar src={avatar!} alt={fullName} size='XSMALL' /> */}
        <Avatar
          alt={fullName}
          src={avatar!}
          sx={{
            width: 33,
            height: 33,
            zIndex: 11,
            borderRadius: '14px',
            mx: 'auto',
            // border: '2px solide #DDE6E9'
          }}
        />
        <Stack sx={{ position: 'absolute', right: '-3px', bottom: '0px', zIndex: 100 }}>
          <ProfileArrowSvgIcon />
        </Stack>
      </button>
      {showDropdown &&
        <Fade in={true}>
          {/* <Transition
            show={showDropdown}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          > */}
          <div
            className={classNames("origin-top-right absolute md:right-0 -right-1 md:top-[41px] top-[35px] w-[242px] py-2 bg-white shadow-xs6 rounded-10 z-40", {
              '!left-0': panelPplacement === "left",
            })}
               style={{ backgroundColor: getBrandingColors()?.Secondary!, color: getBrandingColors()?.Text!}}>
            <div className="m-3 flex">
              <Avatar
                alt={fullName}
                src={avatar!}
                sx={{
                  width: 49,
                  height: 49,
                  zIndex: 11,
                  borderRadius: '19px',
                  // mx: 'auto',
                  // border: '2px solide #DDE6E9'
                }}
              />
              <div className="flex flex-col ml-2">
                <div className="text-[15px] font-semibold capitalize cursor-pointer leading-[16px] mb-[4px]" onClick={() => navigate(`/wall/${user.slug}`)}>{fullName}</div>
                <div className="text-[12px] font-normal leading-[13px] line-clamp-1 mb-[2px]">{jobTitle}</div>
                <div
                  className={
                    classNames(
                      'text-[12px] font-normal leading-[13px] line-clamp-1',
                      { 'cursor-pointer': companySlug }
                    )
                  }
                  onClick={onCompanyClick}>{company}</div>
              </div>
            </div>
            <div className="flex justify-center">
              <ButtonWithTextAndImage
                buttonClasses="px-6 w-[214px] h-[30px] py-1 rounded-5"
                imageUrl=""
                text="View profile"
                textClasses="text-sm6 rounded-20 font-normal"
                onClick={() => navigate(`/wall/${user.slug}`)}
                isLivePage
              />
            </div>
            <div className="account-section">
              <div className="text-[13px] mt-[16px] ml-3 text-[#172746] font-semibold leading-[19px]"
                   style={{ color: getBrandingColors()?.Text!}}>Account</div>
              <div className="text-[12px] m-3 mt-[5px] text-blue-3 font-extralight">
                <div className="setting-privacy mb-1 hover:underline font-[400]"
                     style={{ color: getBrandingColors()?.Text!}}>
                  <a href={'/settings/account-and-privacy/account-settings'}>
                    Settings & Privacy
                  </a>
                </div>
                <div className="billing-subscription mb-1 hover:underline font-[400]"
                     style={{ color: getBrandingColors()?.Text!}}>
                  <a href={'/settings/account-and-privacy/account-settings#subscription'}>
                    {' '}
                    Billing & Subscription
                  </a>
                </div>
                <div className="help hover:underline font-[400]" style={{ color: getBrandingColors()?.Text!}}>
                  <a href={'/help'}>Help</a>
                </div>
              </div>
            </div>
            <div className="account-manage border-y-1 border-blue-0">
              <div className="text-[13px] my-[10px] ml-3 font-semibold leading-[19px]"
                   style={{ color: getBrandingColors()?.Text!}}>Manage</div>
              <div className="flex justify-center mb-3">
                <a
                  href={`${process.env.REACT_APP_EVENT_PORTAL_URL}/events`}
                  target="_blank"
                  className="w-[214px] h-[30px] py-1.5 text-blue-4 text-sm6 rounded-5 font-normal flex justify-center"
                  style={{
                    backgroundColor: getBrandingColors()?.Accent,
                    color: getBrandingColors()?.ButtonTextColor
                  }}
                >
                  Event Manager
                </a>
              </div>
            </div>
            {companyAdmin && companyAdmin.length > 0 && (
              <>
                <div className="account-company pb-[10px]">
                  <div className="text-[13px] mt-[10px] ml-3 text-[#172746] font-semibold leading-[19px]"
                       style={{ color: getBrandingColors()?.Text! }}>Company
                  </div>
                  <div className="flex justify-left">
                    <div className="text-sm5 px-3 mt-1 text-blue-3 font-extralight md:max-h-[200px] max-h-[70px] overflow-y-auto w-full">
                      {companyAdmin?.map((company, index) => {
                        return (
                          <Link to={`/wall/company/${company.slug}`} key={`company-profile-${index}`}>
                            <div key={company.id} className="mb-1 hover:underline font-[400]"
                                 style={{ color: getBrandingColors()?.Text!}}>
                              {company.name}
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="border-t-1"></div>
              </>
            )}

            <div className="sign-out mt-[8px] mb-[4px] border-blue-0">
              <Link to="/logout" className="logout-link hover:underline">
                <div style={{ color: getBrandingColors()?.Accent! }} className="text-[13px] ml-3 text-[#172746] font-semibold leading-[19px]">Sign out</div>
              </Link>
            </div>
          </div>
          {/* </Transition> */}
        </Fade>
      }
    </div>
  );
};

export default UserMenu;
