import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { UserEventsResult } from '../../../models/UserEventsResult';
import { UserEventsState } from '../../../models/UserEventsState';
import { UserService } from '../../../services';
import { UserEventsActionTypes } from './types';

export interface IUserEventsLoadingAction {
  type: UserEventsActionTypes.USER_EVENTS_LOADING;
  loading: boolean;
}

export interface IUserEventsSuccessAction {
  type: UserEventsActionTypes.USER_EVENTS_SUCCESS;
  payload: UserEventsResult;
}
export interface IUserEventsErrorAction {
  type: UserEventsActionTypes.USER_EVENTS_ERROR;
  errorMessage: string;
}

export type UserEventsActions =
  | IUserEventsLoadingAction
  | IUserEventsSuccessAction
  | IUserEventsErrorAction;

export const getUserEvents: ActionCreator<
  ThunkAction<Promise<any>, UserEventsState, null, IUserEventsSuccessAction>
> = () => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();

    try {
      const result: UserEventsResult = await userService.getOwnEvents();
      if (result?.data) {
        result.data.forEach(event => {
          event.cover = `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event.cover}`;
          return event;
        })
      }
      dispatch({
        type: UserEventsActionTypes.USER_EVENTS_SUCCESS,
        payload: result.data
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: UserEventsActionTypes.USER_EVENTS_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: UserEventsActionTypes.USER_EVENTS_LOADING,
        loading: false
      });
    }
  };
};

export const loadUserEvents: ActionCreator<
  ThunkAction<any, UserEventsState, null, IUserEventsLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: UserEventsActionTypes.USER_EVENTS_LOADING,
    loading: shouldLoad
  });
