import classNames from 'classnames';

interface IWebsiteEventTabs {
  onClick: Function,
  tabName?: string;
  date?: string;
  selectedTab: any;
  isSelectTab: boolean;
  backgroundColor? : string;
  isLobbyDayTabs?: string;
}

const WebsiteEventTabs = ({
  onClick, tabName, date, selectedTab, isSelectTab, backgroundColor, isLobbyDayTabs
}: IWebsiteEventTabs) => (
  <div
    key={tabName}
    className={classNames('md:w-[179px] w-full h-[31px] rounded-10 text-[15px] font-semibold text-white bg-[#0049EF] leading-[17px] pl-[15px] flex items-center justify-start cursor-pointer', {
      [`${isLobbyDayTabs}`]: isLobbyDayTabs,
      '!bg-[#DFE5EB] !text-[#203C6E]': !isSelectTab && isLobbyDayTabs && isLobbyDayTabs?.length > 0,
    })}
    style={{
      background: backgroundColor,
      opacity: !isSelectTab ? '0.5' : '1',
    }}
    onClick={() => onClick(selectedTab)}
  >
    {tabName}
    {' | '}
    {date}
  </div>
);

export default WebsiteEventTabs;
