export  enum RegistrationProgressType {
    REGISTRATION_FORM_COMPLETED = "registrationFormCompleted",
    SURVEY_FORM_COMPLETED = "surveyFormCompleted",
    SURVEY_FORM_FILL_LATER = "surveyFormFillLater",
    SURVEY_FORM_EXITED = "surveyFormExited"
}

export const radioBoxColors = [
    '#09efd0',
    '#0009079',
    '#ffff00',
    '#779u899',
    '#09efd0',
    '#0009079',
    '#ffff00',
    '#779u899',
    '#09efd0',
    '#0009079',
    '#ffff00',
    '#779u899',
    '#09efd0',
    '#0009079',
    '#ffff00',
    '#779u899',
    '#09efd0',
    '#0009079',
    '#ffff00',
    '#779u899',
]
export enum EventQuestionPageTitlePositionTypes {
    BOTTOM = 'bottom',
    TOP = 'top',
  }

  export enum EventQuestionPagePaginationTypes {
    SHOW = 'show',
    HIDE = 'hide',
  }