import create from '../../../assets/images/virtual/create-icon.svg';
import promote from '../../../assets/images/virtual/promote-icon.svg';
import expand from '../../../assets/images/virtual/expand-icon.svg';

export const CreateAndPromote = () => (
  <div className="bg-gray-0 mt-32 w-full">
    <div className="block lg:flex flex-row mb-8 w-11/12 mx-auto">
      <div className="block lg:flex flex-col  mx-auto mt-7">
        <img className="mx-auto" src={create} alt="" style={{ width: '80px', height: '80px' }} />
        <div className="text-dark-blue text-center">
          <div className="text-xl6 -mt-1">Create</div>
          <div className="text-md ">Create and Host</div>
          <div className="text-gray-1 text-sm1 w-3/5  mt-2 mx-auto">
            Host your event with your own live platform and event website with all the audience
            engagement functionalities you will need.
          </div>
        </div>
      </div>
      <div className="block lg:flex flex-col mx-auto mt-7 ml-3">
        <img className="mx-auto" src={promote} alt="" style={{ width: '80px', height: '80px' }} />
        <div className="text-dark-blue text-center">
          <div className="text-xl6 -mt-1">Promote & Manage</div>
          <div className="text-md">Streamlined Management</div>
          <div className="text-gray-1 text-sm1 text-center w-3/5  mt-2 mx-auto">
            Manage all aspects of your event, from sponsor management to content and attendee
            communications before, during and after your event.
          </div>
        </div>
      </div>
      <div className="block lg:flex flex-col  mx-auto mt-6 -ml-3">
        <img className="mx-auto" src={expand} alt="" style={{ width: '80px', height: '80px' }} />
        <div className="text-2xl text-center">Expand</div>
        <div className="text-md text-center">Expand your Reach</div>
        <div className="text-gray-1 text-sm1 text-center w-3/5  mt-2 mx-auto">
          Reach out to large audience, promote your events to thousands of Panelist members, invite
          by job title, job function, location and industry.
        </div>
      </div>
    </div>
  </div>
);

export default CreateAndPromote;
