import { useSelector } from 'react-redux';
import {
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { IAppState } from '../../../store';
import MinusSvgIcon from './components/agenda-accordion/minus-svg-icon';
import PlusSvgIcon from './components/agenda-accordion/plus-svg-icon';

export const WorkForSection = () => {
  const targetAudiences: any = useSelector(
    (state: IAppState) => state.website.targetAudiences!
  );

  const [expanded, setExpanded] = useState<string | false>(false);
  const [sortedCompanySizes, setSortedCompanySizes] = useState<any[]>([]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getCategories = (data: any) => {
    const categories = [];

    if (data?.jobTitles && data?.jobTitles?.length > 0) {
      categories.push('Roles');
    }

    if (data?.industries && data?.industries?.length > 0) {
      categories.push('Industries');
    }

    if (data?.companySizes && data?.companySizes?.length > 0) {
      categories.push('Organisation Size');
    }

    if (data?.jobFunctions && data?.jobFunctions?.length > 0) {
      categories.push('Departments');
    }
    return categories;
  };

  const getTargetKey = (key: any | undefined | null) => {
    if (key === 'Roles') return 'jobTitles';
    if (key === 'Industries') return 'industries';
    if (key === 'Organisation Size') return 'companySizes';
    if (key === 'Departments') return 'jobFunctions';
    return '';
  };

  const sortNumericRanges = (a: { name: string }, b: { name: string }) => {
    const rangePattern = /^\d+-\d+$/;
    const plusPattern = /^\d+\+$/;

    const isRangeA = rangePattern.test(a.name);
    const isRangeB = rangePattern.test(b.name);

    const isPlusA = plusPattern.test(a.name);
    const isPlusB = plusPattern.test(b.name);

    if (isRangeA && isRangeB) {
      const [startA, endA] = a.name.split('-').map(Number);
      const [startB, endB] = b.name.split('-').map(Number);

      if (startA === startB) {
        return endA - endB;
      }
      return startA - startB;
    }

    if (isRangeA && isPlusB) {
      return -1;
    }

    if (isPlusA && isRangeB) {
      return 1;
    }

    return 0;
  };

  useEffect(() => {
    getCategories(targetAudiences);
    if (targetAudiences?.companySizes) {
      setSortedCompanySizes(
        [...targetAudiences.companySizes].sort(sortNumericRanges).map((item: any) => (
          <AccordionDetails
            key={item.name}
            sx={{ backgroundColor: 'black', color: 'white', p: '0px' }}
          >
            <Typography
              className="helvetica-neue-55-roman"
              sx={{
                fontSize: '17px',
                lineHeight: '23px',
                py: '4px',
                px: '8px',
              }}
            >
              {item.name}
            </Typography>
          </AccordionDetails>
        ))
      );
    }
  }, [targetAudiences]);

  return (
    <div className="flex flex-col md:mt-[150px] md:mb-[150px] mt-[60px] mb-[60px]">
      <div className="text-white font-medium helvetica-neue-65-medium text-[22px] leading-[27px]">
        Audience
      </div>
      <div className="text-white md:leading-[42px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium text-left">
        Who's in the room
      </div>
      <div className="mt-[23px] flex flex-col">
        {getCategories(targetAudiences)?.map((accordion: any, index: number) => (
          <Accordion
            key={index}
            expanded={expanded === accordion}
            onChange={handleChange(accordion)}
            sx={{
              backgroundColor: 'black',
              color: 'white',
              boxShadow: 'none !important',
              margin: '0px 0px 0px !important',
              '& .MuiAccordion-region': {
                pt: '15px',
                pb: '15px',
                backgroundColor: 'black'
              }
            }}
          >
            <AccordionSummary
              sx={{
                backgroundColor: 'black',
                color: 'white',
                pl: '0px',
                borderBottom: '3px solid #D6244D',
                minHeight: 'auto !important',
                borderTop: index === 0 ? '3px solid #D6244D' : null,
                '& .MuiAccordionSummary-content': {
                  margin: '14px 0px',
                },
                '& .Mui-expanded': {
                  margin: '11px 0px !important',
                },
              }}
              expandIcon={expanded === accordion ? <MinusSvgIcon className="w-[18px]" /> : <PlusSvgIcon className="w-[18px]" />}
            >
              <Typography
                className="helvetica-neue-65-medium "
                sx={{
                  fontSize: '23px',
                  lineHeight: '25px',
                  fontWeight: 500,
                  textTransform: 'capitalize',
                }}
              >
                {accordion}
              </Typography>
            </AccordionSummary>
            {accordion === 'Organisation Size'
              ? sortedCompanySizes
              : targetAudiences[getTargetKey(accordion)]?.map((item: any) => (
                <AccordionDetails
                  key={item.name}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    p: '0px',
                  }}
                >
                  <Typography
                    className="helvetica-neue-65-medium"
                    sx={{
                      fontSize: '17px',
                      lineHeight: '23px',
                      py: '6px',
                      px: '8px',
                      fontFamily: 'unset'
                    }}
                  >
                    {item.name}
                  </Typography>
                </AccordionDetails>
              ))}
          </Accordion>
        ))}
      </div>
    </div>
  );
};
