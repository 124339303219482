import {
  ActionCreator,
  Dispatch,
} from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IPostsResult } from '../../../models/post/PostsResult';
import { IPostsState } from '../../../models/post/PostsState';
import { CompanyService } from '../../../services';
import { CompanyPostsActionTypes } from './types';

export interface ICompanyPostsLoadingAction {
  type: CompanyPostsActionTypes.COMPANY_POSTS_LOADING;
  loading: boolean;
}

export interface ICompanyPostsSuccessAction {
  type: CompanyPostsActionTypes.COMPANY_POSTS_SUCCESS;
  payload: IPostsResult;
}

export interface ICompanyPostsErrorAction {
  type: CompanyPostsActionTypes.COMPANY_POSTS_ERROR;
  errorMessage: string;
}

export type CompanyPostsActions =
  | ICompanyPostsLoadingAction
  | ICompanyPostsSuccessAction
  | ICompanyPostsErrorAction;

export const getCompanyPosts: ActionCreator<
  ThunkAction<Promise<any>, IPostsState, null, ICompanyPostsSuccessAction>
> = (
  companyIdOrSlug: string,
  page: number = 1,
  orderBy: string = "Most recent"
) => {
  return async (dispatch: Dispatch) => {
    const companyService: CompanyService = new CompanyService();
    let sortBy: string = "";
    if (orderBy === "Most recent") sortBy = "&order[createdAt]=DESC";
    else sortBy = "&order[rank]=DESC";

    try {
      const result = await companyService.getCompanyPosts(
        companyIdOrSlug,
        page,
        sortBy
      );

      dispatch({
        type: CompanyPostsActionTypes.COMPANY_POSTS_SUCCESS,
        payload: result,
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: CompanyPostsActionTypes.COMPANY_POSTS_ERROR,
        errorMessage: error.message,
      });
    } finally {
      dispatch({
        type: CompanyPostsActionTypes.COMPANY_POSTS_LOADING,
        loading: false,
      });
    }
  };
};

export const loadCompanyProfilePreview: ActionCreator<
  ThunkAction<any, IPostsState, null, ICompanyPostsLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: CompanyPostsActionTypes.COMPANY_POSTS_LOADING,
    loading: shouldLoad,
  });
