import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IDropdownMenuItem } from 'src/components/dropdown-menu';
import { InteractiveModeratorSpeakerKickUser } from 'src/store/interactive-event/moderator-speaker';
import { Participants } from 'src/store/interactive-event/types';
import { useCurrentUser } from 'src/utils/hooks';
import { AttendeeAvatar } from '../AttendeeAvatar';

interface IStageParticipantsView {
  participants: Participants
  getDefaultOptions?: Function
}
export const StageParticipantsView = ({ participants, getDefaultOptions }: IStageParticipantsView) => {
  const { slug, roomId, role } = useParams();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  return (
    <>
      {
        participants.moderators.map((participant: any, index: number) => {
          const dotOptions: IDropdownMenuItem[] = [];
          /**
           * Can't kick moderator out.
           */
          // if (role !== 'session' && user.id !== participant.id) {
          //   dotOptions.push(
          //     {
          //       id: 'kick-moderator',
          //       text: 'Kick',
          //       onClickHandler: () => {
          //         dispatch(InteractiveModeratorSpeakerKickUser(slug!, roomId!, participant?.id));
          //       }
          //     }
          //   );
          // }
          return (
            <AttendeeAvatar
              item={participant}
              key={index}
              status="Moderator"
              options={[...getDefaultOptions!(participant), ...dotOptions]}
            />
          );
        })
      }
      {
        participants.speakers.map((participant: any, index: number) => {
          const dotOptions: IDropdownMenuItem[] = [];
          if (!!role && role !== 'session' && user.id !== participant.id) {
            dotOptions.push(
              {
                id: 'kick-speaker',
                text: 'Kick',
                onClickHandler: () => {
                  dispatch(InteractiveModeratorSpeakerKickUser(slug!, roomId!, participant?.id));
                }
              }
            );
          }
          return (
            <AttendeeAvatar
              item={participant}
              key={index}
              status="Speaker"
              options={[...getDefaultOptions!(participant), ...dotOptions]}
            />
          );
        })
      }
      {
        participants.guests.map((participant: any, index: number) => {
          const dotOptions: IDropdownMenuItem[] = [];
          if (!!role && role !== 'session' && user.id !== participant.id) {
            dotOptions.push(
              {
                id: 'kick-guest',
                text: 'Kick',
                onClickHandler: () => {
                  dispatch(InteractiveModeratorSpeakerKickUser(slug!, roomId!, participant?.id));
                }
              }
            );
          }
          return (
            <AttendeeAvatar
              item={participant}
              key={index}
              options={[...getDefaultOptions!(participant), ...dotOptions]}
            />
          );
        })
      }
      {
        participants.virtualSpeakers.map((participant: any, index: number) => {
          const dotOptions: IDropdownMenuItem[] = [];
          if (!!role && role !== 'session' && user.id !== participant.id) {
            dotOptions.push(
              {
                id: 'kick-speaker',
                text: 'Kick',
                onClickHandler: () => {
                  dispatch(InteractiveModeratorSpeakerKickUser(slug!, roomId!, participant?.id));
                }
              }
            );
          }
          return (
            <AttendeeAvatar
              item={participant}
              key={index}
              options={[...getDefaultOptions!(participant), ...dotOptions]}
            />
          );
        })
      }

    </>
  );
};
