/* eslint-disable no-console */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { CompanyService } from 'src/services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { IAppState } from 'src/store';
import { useSelector } from 'react-redux';
import { Loader } from 'src/shared-components/Loader';
import { UploadAccountStepName, UploadAccountSteps } from './company-enum';
import { OptionType } from './PMapFieldSelect';
import Requirements from './UploadAccountList/Requirements';
import UploadFile from './UploadAccountList/UploadFile';
import MapFields from './UploadAccountList/MapFields';
import NameList from './UploadAccountList/NameList';
import BestMatch from './UploadAccountList/BestMatch';

export const UploadAccounts = () => {
  const navigate = useNavigate();
  const companyService: CompanyService = new CompanyService();

  const {
    companyProfilePreview,
  } = useSelector((state: IAppState) => state);
  const [activeStep, setActiveStep] = useState<string>(
    UploadAccountStepName.REQUIREMENTS
  );
  const [headers, setHeaders] = useState<OptionType[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [uploadFile, setUploadFile] = useState<File | string>('');
  // const [accountFileID, setAccountFileID] = useState<any>('');
  const methods = useForm<any>({});
  const companyID = companyProfilePreview?.value?.id!;

  const {
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const values = watch();
  console.log('errors', errors);
  const transformCsvData = () => {
    const formatedData = data?.map((row: any) => ({
      name: row?.[values?.Company?.name],
      companySize: row?.[values?.Size?.name],
      industry: row?.[values?.Industry?.name],
      headquarter: row?.[values?.['Head quarters']?.name],
      website: row?.[values?.['Company website']?.name],
      slug: row?.[values?.Slug?.name],
    }))?.filter(row => row.name);

    setValue('csvFromatedData', formatedData);
  };

  // const accountFileApi = async () => {
  //   try {
  //     const bodyDataFile = {
  //       name: values?.accountListName,
  //       description: values?.accountListDescription,
  //       source: values?.source,
  //     };

  //     const accountFilesResponse: any = await companyService.postAccountFiles(companyID, bodyDataFile);

  //     setAccountFileID(accountFilesResponse?.data?.id);
  //     setActiveStep(UploadAccountStepName.BEST_MATCH);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  const onSubmit = async (data: any) => {
    if (!data?.accountListName || !data?.source || !data?.csvFromatedData) {
      toast.error('Required fields are missing.');
      setIsLoader(false);
      return;
    }

    try {
      // const csvFromatedData = data?.csvFromatedDta;
      const companiesBestMatchData:any = [];

      Object.keys(data)?.forEach((item:string) => {
        const isCompanyName = item?.includes('companyName');
        if (isCompanyName) {
          const companyName = item?.split('-')?.[0];
          companiesBestMatchData?.push({
            name: data?.[item]?.name || companyName,
            website: data?.[item]?.website,

          });
        }
      });
      const bodyDataFile = {
        name: data.accountListName,
        description: data.accountListDescription,
        source: data.source,
      };
      const accountFilesResponse: any = await companyService.postAccountFiles(companyID, bodyDataFile);
      const fieldID = accountFilesResponse?.data?.id;
      const companiesResponse: any = await companyService.postCompaniesImport(fieldID, companiesBestMatchData);
      if (companiesResponse?.data && fieldID) {
        navigate(`/wall/company/${companyID}/accounts-list?creating=true`);
      }
    } catch (error: any) {
      toast.error(error?.message || 'Something went wrong. Please try again.');
    } finally {
      setIsLoader(false);
    }
  };

  const handleNext = () => {
    switch (activeStep) {
      case UploadAccountStepName.REQUIREMENTS:
        setActiveStep(UploadAccountStepName.UPLOAD_CSV);
        break;
      case UploadAccountStepName.NAME_LIST:
        // accountFileApi();
        // setActiveStep(UploadAccountStepName.BEST_MATCH);
        methods.handleSubmit(onSubmit)();
        break;
      case UploadAccountStepName.UPLOAD_CSV:
        setActiveStep(UploadAccountStepName.MAP_FIELDS);
        break;
      case UploadAccountStepName.MAP_FIELDS:
        transformCsvData();
        setActiveStep(UploadAccountStepName.BEST_MATCH);
        setUploadFile(values?.uploadFile);
        break;

      case UploadAccountStepName.BEST_MATCH:
        // setIsLoader(true);
        // methods.handleSubmit(onSubmit)();
        setActiveStep(UploadAccountStepName.NAME_LIST);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    const findActiveStepIndex = UploadAccountSteps.findIndex(
      (step) => step === activeStep
    );
    setActiveStep(UploadAccountSteps[findActiveStepIndex - 1]);
  };

  const getActiveStepContent = () => {
    switch (activeStep) {
      case UploadAccountStepName.REQUIREMENTS:
        return <Requirements />;

      case UploadAccountStepName.UPLOAD_CSV:
        return (
          <UploadFile
            setHeaders={setHeaders}
            setUploadFile={setUploadFile}
            setData={setData}
            setActiveStep={setActiveStep}
            setValue={setValue}
          />
        );
      case UploadAccountStepName.BEST_MATCH:
        return <BestMatch values={values} setValue={setValue} />;
      case UploadAccountStepName.MAP_FIELDS:
        return <MapFields headers={headers} values={values} />;
      case UploadAccountStepName.NAME_LIST:
        return <NameList />;

      default:
        return 'Something goes here!';
    }
  };
  const getActiveStep = () => {
    switch (activeStep) {
      case UploadAccountStepName.REQUIREMENTS:
        return 0;
      case UploadAccountStepName.UPLOAD_CSV:
        return 1;
      case UploadAccountStepName.MAP_FIELDS:
        return 2;
      case UploadAccountStepName.BEST_MATCH:
        return 3;
      case UploadAccountStepName.NAME_LIST:
        return 4;

      default:
        return 0;
    }
  };

  const isDisableButton = () => {
    switch (activeStep) {
      case UploadAccountStepName.REQUIREMENTS:
        return false;

      case UploadAccountStepName.UPLOAD_CSV:
        return !values?.source;

      case UploadAccountStepName.BEST_MATCH:
        return false;

      case UploadAccountStepName.MAP_FIELDS:
        return !values?.Company;
      case UploadAccountStepName.NAME_LIST:
        return !values?.accountListName;

      default:
        return true;
    }
  };

  return (
    <div className="w-full px-[20px] pb-[17px] pt-[17px] relative">
      {isLoader && (
        <div className="absolute left-0 top-0 w-full h-[100%] bg-gray-1 bg-opacity-80 flex items-center justify-center z-20 rounded-20">
          <Loader className="pt-5" key="loader" />
        </div>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                color="text.primary"
                variant="subtitle2"
                sx={{
                  fontSize: '18px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  mb: '25px',
                  fontFamily: 'Poppins-600',
                }}
              >
                Upload Account List
              </Typography>
            </Stack>

            <Box sx={{ width: '100%' }}>
              <Stepper
                sx={{
                  '& .MuiStepLabel-label': { mt: '7px !important' },
                  '& .MuiSvgIcon-root.MuiStepIcon-root.Mui-active': {
                    backgroundColor: 'transparent',
                    border: '2px solid #0031FF',
                    color: 'transparent',
                    borderRadius: '50%',
                    '& text': { display: 'none' },
                  },
                  '& .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed': {
                    color: '#0031FF',
                  },
                  '& .MuiSvgIcon-root.MuiStepIcon-root.Mui-disabled': {
                    backgroundColor: 'transparent !important',
                    border: '2px solid #0031FF',
                    borderRadius: '50%',
                    color: 'transparent !important',
                  },
                  '& .MuiStepLabel-root.Mui-disabled': {
                    '& text': { display: 'none' },
                  }
                }}
                activeStep={getActiveStep()}
                alternativeLabel
              >
                {UploadAccountSteps.map((label) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <>
                <Stack
                  sx={{
                    mt: '20px',
                    pt: '20px',
                    borderTop: '1px solid #bdbdbd',
                  }}
                >
                  {getActiveStepContent()}
                </Stack>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    // disabled={activeStep === UploadAccountStepName.REQUIREMENTS || activeStep !== UploadAccountStepName.UPLOAD_CSV}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button
                    disabled={isDisableButton()}
                    sx={{
                      borderRadius: '5px',
                      backgroundColor: '#0031FF',
                      fontFamily: 'Poppins-500',
                      px: '20px',
                      // mb: '10px',
                      color: '#ffffff',
                      '&:hover': {
                        backgroundColor: '#0031FF',
                        '& .MuiTypography-root': {
                          color: '#ffffff',
                        },
                      },
                      '&.Mui-disabled': {
                        background: '#375dff',
                        color: '#ffffff',
                        cursor: 'no-drop'
                      }
                    }}
                    onClick={handleNext}
                  >
                    {activeStep === UploadAccountStepName.NAME_LIST
                      ? 'Finish'
                      : 'Continue'}
                  </Button>
                </Box>
              </>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    </div>
  );
};

export default UploadAccounts;
