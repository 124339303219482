import { useState } from 'react';

interface IViewEyeSvgIcon {
    fillColor?: string;
    fillBgColor?: string;
}

export const ViewEyeSvgIcon = ({ fillColor, fillBgColor }: IViewEyeSvgIcon) => {
  const defaultColor = '#ffffff';
  const defaultBgColor = '#ffffff';
  
  const iconColor = fillColor || defaultColor;
  const bgColor = fillBgColor || defaultBgColor;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
      <g id="Group_20597" data-name="Group 20597" transform="translate(-334.721 -291.77)">
        <rect 
          id="Rectangle_8172" 
          data-name="Rectangle 8172" 
          width="45" 
          height="45" 
          rx="15" 
          transform="translate(334.721 291.77)" 
          fill={bgColor}
        />
        <g id="Group_20598" data-name="Group 20598" transform="translate(351.104 305.556)">
          <rect id="Rectangle_8170" data-name="Rectangle 8170" width="8.155" height="1.333" rx="0.666" transform="translate(2.039 5.449)" fill="#ffffff"/>
          <rect id="Rectangle_8171" data-name="Rectangle 8171" width="8.154" height="1.333" rx="0.666" transform="translate(6.783 2.039) rotate(90)" fill="#ffffff"/>
        </g>
        <g id="Component_434_1" data-name="Component 434 – 1" transform="translate(344.721 301.89)">
          <rect id="Rectangle_8052" data-name="Rectangle 8052" width="25" height="25" rx="12.5"/>
          <path 
            id="show-svgrepo-com" 
            d="M71.478,122.355a7.253,7.253,0,0,1-4.35-1.4A9.72,9.72,0,0,1,64,117.177a9.72,9.72,0,0,1,3.128-3.775,7.253,7.253,0,0,1,4.35-1.4,7.168,7.168,0,0,1,4.314,1.474,10.09,10.09,0,0,1,3.164,3.7,10.4,10.4,0,0,1-3.164,3.739A7.285,7.285,0,0,1,71.478,122.355Zm0-1.726a3.437,3.437,0,1,0-2.445-1.007A3.329,3.329,0,0,0,71.478,120.629Zm0-1.438a1.908,1.908,0,0,1-1.4-.575,1.968,1.968,0,0,1-.611-1.438,2.092,2.092,0,0,1,2.013-2.013,1.968,1.968,0,0,1,1.438.611,1.908,1.908,0,0,1,.575,1.4,1.966,1.966,0,0,1-2.013,2.013Z" 
            transform="translate(-59.098 -104.798)" 
            fill={iconColor}
          />
        </g>
      </g>
    </svg>
  );
};