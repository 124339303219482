interface SvgIconProps {
    fillColor?: string;
}
export const PoweredByIcon = ({ fillColor }: SvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#272f51"
  const textColor = fillColor ? fillColor : "#3c3c3c"
    return <svg xmlns="http://www.w3.org/2000/svg" width="100.087" height="12.98" viewBox="0 0 100.087 12.98">
    <g id="Group_17587" data-name="Group 17587" transform="translate(-149 -6.846)">
      <text id="Powered_by" data-name="Powered by" transform="translate(149 15.846)" fill={textColor} font-size="9" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Powered by</tspan></text>
      <g id="Logotype-ColourPositive" transform="translate(210.983 8.537)">
        <path id="Path_10785" data-name="Path 10785" d="M293.975,7.08a.517.517,0,0,0-.52.514v7.341a.514.514,0,0,0,.253.464.524.524,0,0,0,.532,0,.514.514,0,0,0,.253-.464V7.594A.516.516,0,0,0,293.975,7.08Z" transform="translate(-266.846 -7.08)" fill={iconColor}/>
        <path id="Path_10786" data-name="Path 10786" d="M314.865,31.24a.522.522,0,0,0-.368.152.513.513,0,0,0-.151.366v5.166a.514.514,0,0,0,.253.464.524.524,0,0,0,.532,0,.514.514,0,0,0,.253-.464V31.758a.513.513,0,0,0-.151-.366A.522.522,0,0,0,314.865,31.24Z" transform="translate(-285.842 -29.069)" fill={iconColor}/>
        <path id="Path_10787" data-name="Path 10787" d="M333.879,31.867c0-.5.453-.859,1.221-.859a1.259,1.259,0,0,1,1.287.742.515.515,0,0,0,.888.156.505.505,0,0,0,.071-.516,2.2,2.2,0,0,0-2.212-1.251c-1.392,0-2.294.78-2.294,1.73,0,2.308,3.7,1.5,3.7,2.885,0,.509-.453.916-1.278.916a1.4,1.4,0,0,1-1.4-.749.5.5,0,0,0-.463-.3h-.022a.515.515,0,0,0-.436.246.506.506,0,0,0-.017.5,2.534,2.534,0,0,0,2.351,1.179c1.381,0,2.282-.769,2.282-1.787C337.508,32.535,333.879,33.339,333.879,31.867Z" transform="translate(-302.66 -28.068)" fill={iconColor}/>
        <path id="Path_10788" data-name="Path 10788" d="M392.788,16.062a.426.426,0,0,0,.428-.421h0a.426.426,0,0,0-.428-.424h-1.2v-1.05a.514.514,0,0,0-.253-.464.524.524,0,0,0-.532,0,.514.514,0,0,0-.253.464v5.548c0,1.244.627,1.7,1.735,1.7h.5a.441.441,0,0,0,.4-.212.433.433,0,0,0,0-.447.441.441,0,0,0-.4-.212h-.325c-.639,0-.868-.215-.868-.826V16.062Z" transform="translate(-355.132 -13.042)" fill={iconColor}/>
        <path id="Path_10789" data-name="Path 10789" d="M76.437,30.807a3.191,3.191,0,0,0-2.938,2.4,3.158,3.158,0,0,0,1.463,3.485,3.22,3.22,0,0,0,3.79-.372v.2a.525.525,0,0,0,1.049,0V33.972a3.1,3.1,0,0,0-.164-1A3.2,3.2,0,0,0,76.437,30.807Zm.166,5.3a2.13,2.13,0,1,1,2.148-2.13A2.14,2.14,0,0,1,76.6,36.1Z" transform="translate(-66.749 -28.67)" fill={iconColor}/>
        <path id="Path_10790" data-name="Path 10790" d="M152.482,30.8a2.813,2.813,0,0,0-1.982.813,2.763,2.763,0,0,0-.82,1.964v2.946a.519.519,0,0,0,1.037,0V33.577a1.745,1.745,0,0,1,.87-1.547,1.777,1.777,0,0,1,1.787,0,1.745,1.745,0,0,1,.87,1.547v2.946a.512.512,0,0,0,.152.364.521.521,0,0,0,.367.15h0a.516.516,0,0,0,.519-.514V33.577A2.788,2.788,0,0,0,152.482,30.8Z" transform="translate(-136.108 -28.668)" fill={iconColor}/>
        <path id="Path_10791" data-name="Path 10791" d="M3.2,30.8a3.211,3.211,0,0,0-2.262.928A3.154,3.154,0,0,0,0,33.97v5.489a.525.525,0,0,0,1.049,0v-3.14a3.22,3.22,0,0,0,3.836.344A3.157,3.157,0,0,0,6.273,33.1,3.194,3.194,0,0,0,3.2,30.8Zm0,5.3A2.13,2.13,0,1,1,5.35,33.97a2.12,2.12,0,0,1-.63,1.507A2.159,2.159,0,0,1,3.2,36.1Z" transform="translate(0 -28.668)" fill={iconColor}/>
        <path id="Path_10792" data-name="Path 10792" d="M220.388,30.8a3.194,3.194,0,0,0-3.162,2.685,3.266,3.266,0,0,0-.017.838,3.09,3.09,0,0,0,.216.838,3.211,3.211,0,0,0,5.407.854.517.517,0,0,0,.072-.554.525.525,0,0,0-.476-.3.516.516,0,0,0-.4.18,2.16,2.16,0,0,1-3.426-.18,2.08,2.08,0,0,1-.338-.838h4.961a.355.355,0,0,0,.356-.353,3.269,3.269,0,0,0-.036-.484A3.193,3.193,0,0,0,220.388,30.8ZM218.3,33.485a2.153,2.153,0,0,1,4.184,0Z" transform="translate(-197.496 -28.668)" fill={iconColor}/>
      </g>
    </g>
  </svg>
  
  
};