import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import {
  IEventAttendeeResult,
  IEventAttendeeState
} from '../../../models/event/event-attendee';
import { EventsService } from '../../../services/events';
import { InvitableUsersActionTypes } from './types';

export interface IInvitableUsersLoadingAction {
  type: InvitableUsersActionTypes.INVITABLE_USERS_LOADING;
  loading: boolean;
}

export interface IInvitableUsersSuccessAction {
  type: InvitableUsersActionTypes.INVITABLE_USERS_SUCCESS;
  result: IEventAttendeeResult;
}

export interface IInvitableUsersErrorAction {
  type: InvitableUsersActionTypes.INVITABLE_USERS_ERROR;
  errorMessage: string;
}

export type InvitableUsersActions =
  | IInvitableUsersLoadingAction
  | IInvitableUsersSuccessAction
  | IInvitableUsersErrorAction;

export const resetUnInvitedConnections: ActionCreator<
  ThunkAction<Promise<any>, IEventAttendeeState, null, IInvitableUsersSuccessAction>
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: InvitableUsersActionTypes.INVITABLE_USERS_SUCCESS,
      result: []
    });
  }
}

export const getUnInvitedConnections: ActionCreator<
  ThunkAction<Promise<any>, IEventAttendeeState, null, IInvitableUsersSuccessAction>
> = (
  eventId: string,
  search: string = '',
  jobTitleIds: string[] = [],
  jobFunctionIds: string[] = [],
  regions: string[]
) => {
  return async (dispatch: Dispatch) => {
    const eventsService: EventsService = new EventsService();

    try {
      const result = await eventsService.getInvitableUsers(
        eventId,
        search,
        jobTitleIds,
        jobFunctionIds,
        regions
      );

      dispatch({
        type: InvitableUsersActionTypes.INVITABLE_USERS_SUCCESS,
        result: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: InvitableUsersActionTypes.INVITABLE_USERS_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: InvitableUsersActionTypes.INVITABLE_USERS_LOADING,
        loading: false
      });
    }
  };
};

export const loadInvitableUsers: ActionCreator<
  ThunkAction<any, IEventAttendeeState, null, IInvitableUsersLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: InvitableUsersActionTypes.INVITABLE_USERS_LOADING,
    loading: shouldLoad
  });
