import React, { FC } from 'react';
import { UserNavbar } from '../components';

interface ISearchResultLayoutProps {
  rightPanel: JSX.Element;
  leftPanel?: JSX.Element;
  showLeftPanel: string;
  children: JSX.Element;
}
export const SearchResultLayout: FC<ISearchResultLayoutProps> = ({
  leftPanel,
  rightPanel,
  children,
  showLeftPanel
}) => (
  <div className="bg-gray-1 h-full">
    <UserNavbar />
    <div>
      <div className="container mx-auto mt-6 px-3 xl:px-0 max-w-[100%] xl:max-w-[1258px]">
        <div className="flex lg:flex-row flex-col justify-center w-full mx-auto">
          {showLeftPanel && (
            <div className="flex flex-col lg:w-[286px] w-full min-w-[286px]">
              {leftPanel}
            </div>
          )}
          <div
            className={`${showLeftPanel ? 'lg:ml-[33px] sm:mb-8 max-w-[100%] w-full' : 'mb-8 max-w-[100%] w-full'
            }`}
          >
            {children}
          </div>
          <div
            className={`${showLeftPanel ? 'flex flex-col md:ml-4 md:w-315 min-w-[315px]' : 'flex flex-col ml-6 w-315 min-w-[315px]'
            }`}
          >
            {rightPanel}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SearchResultLayout;
