import axios from "src/mui/utils/axios";
import axiosV2Instance from "src/mui/utils/axios-v2";
import Cookies from "universal-cookie";
import { UserTokens } from "../models/user-login-result";

const tokenName = process.env.REACT_APP_TOKEN as string;
const refreshTokenName = process.env.REACT_APP_REFRESH_TOKEN as string;
const janusTokenName = process.env.REACT_APP_JANUS_TOKEN as string;
export const getAuthToken = (key: string = tokenName) => {
  try {
    const cookies = new Cookies();
    const cookie = cookies.getAll();
    if (cookie) return cookie[key];
  } catch (e) {
    console.error("cant get auth", e);
  }
};

export const getAuthRefreshToken = (key: string = refreshTokenName) => {
  return getAuthToken(key);
};

export const setAuthToken = (result: UserTokens | undefined) => {
  try {

  const in15Hours = new Date(new Date().getTime() + 1000 * 60 * 60 * 15);
  const in30Days = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30);
  const optsAuthToken = {
    expires: in15Hours,
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    path: "/",
    sameSite: 'none' as boolean | 'none' | 'lax' | 'strict',
    secure: true
  };

  const optsRefreshToken = {
    expires: in30Days,
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    path: "/",
    sameSite: 'none' as boolean | 'none' | 'lax' | 'strict',
    secure: true
  };
  const cookies = new Cookies();
  const token = result?.token;
  const refreshToken = result?.refreshToken;
  const janusToken = result?.janusToken;
  axios.defaults.headers.common.Authorization = `${token}`;
  axiosV2Instance.defaults.headers.common.Authorization = `${token}`;
  cookies.set(janusTokenName, janusToken, optsRefreshToken);
  cookies.set(tokenName, token, optsAuthToken);
  cookies.set(refreshTokenName, refreshToken, optsRefreshToken);
}catch(error) {
  console.log(error)
}
};

export const delAuthToken = () => {
  const opts = {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    path: "/",
    sameSite: 'none' as boolean | 'none' | 'lax' | 'strict',
    secure: true,
  };
  const cookies = new Cookies();
  cookies.remove(tokenName, opts);
  cookies.remove(refreshTokenName, opts);
  cookies.remove(janusTokenName, opts);
};
