import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReactSelectOption } from '../../models/react-select-option';
import { IAppState } from '../../store';
import { getIndustries } from '../../store/industries/actions';
import { UseFormRegister } from 'react-hook-form';
import { LOGIN_SIGNUP_RECOVERY } from '../../constants';
import Select from 'react-select';
import classNames from 'classnames';
import { getJobFunction } from 'src/store/company/job-functions/actions';

interface JobFunctionsSelectorProps {
  onChange: Function;
  register: UseFormRegister<any>;
  selectedJobFunctionStateToStore: any;
  classes?: string;
  isBorderLess?: boolean;
  isSpectre?: boolean;
  customStyleCss?: string;
}

const customStyles = {
  option: (provided: any, state: { isSelected: any; }) => ({
    ...provided,
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    width: '100%',
    border: '1px solid #8f8f8f',
    height: '35px',
    borderRadius: '10px',
    display: 'flex',
    color: 'white',
  }),
  singleValue: (provided: any, state: { isDisabled: any; }) => {
    const opacity = state.isDisabled ? 1 : 1;
    const transition = 'opacity 300ms';
    const color = 'white';
    const fontSize = '15px';

    return { ...provided, opacity, transition, color, fontSize };
  }
}

const defaultStyle = {
  singleValue: (provided: any, state: { isDisabled: any; }) => {
    const opacity = state.isDisabled ? 1 : 1;
    const transition = 'opacity 300ms';
    const color = '#3C3C3C';
    const fontSize = '13px';

    return { ...provided, opacity, transition, color, fontSize };
  }
}

export const JobFunctionSelector = ({
  onChange,
  register,
  selectedJobFunctionStateToStore,
  classes,
  isBorderLess,
  isSpectre,
  customStyleCss
}: JobFunctionsSelectorProps) => {
  const dispatch = useDispatch();
  const jobFunctions = useSelector((state: IAppState) => state.jobFunctions);

  const [jobFunctionsListState, setjobFunctionsListState] = useState<IReactSelectOption[]>();
  const [query, setQuery] = useState('');

  useEffect(() => {
    dispatch(getJobFunction(query));
  }, [query, dispatch]);

  useEffect(() => {
    const mapIndustriesToSelectOption = () => {
      const jobFunctionList: IReactSelectOption[] = [];

      jobFunctions?.value?.data &&
      jobFunctions?.value?.data.map((item: any, index: number) =>
            jobFunctionList.push({
            key: `job-function-${index}`,
            value: item.id,
            label: item.name
          })
        );
        setjobFunctionsListState(jobFunctionList.filter((i) => i.label));
    };
    mapIndustriesToSelectOption();
  }, [jobFunctions]);

  const onChangeLocal = (event: any) => {
    onChange(event);
  };

  return (
    <Select
      id="jobFunctionSelector"
      className={classNames('dropdown-style', {
        [`${customStyleCss}`]: customStyleCss,
        "hide-border": isBorderLess,
        "show-arrow": !isSpectre,
      })}
      classNamePrefix={classNames({
        "custom-class": isSpectre,
      })}
      placeholder={"Select your job function"}
      options={jobFunctionsListState}
      onInputChange={(a) => setQuery(a)}
      isSearchable={true}
      openMenuOnClick={true}
      {...register('jobFunctionId', {
        required: { value: true, message: LOGIN_SIGNUP_RECOVERY.JOBFUNCTION_ISREQUIRED }
      })}
      value={selectedJobFunctionStateToStore}
      onChange={(e: any) => onChangeLocal(e)}
      styles={isSpectre ? customStyles : defaultStyle}
    />
  );
};
