import { Suspense } from "react";
import { SplashScreen } from "../components/loading-screen";
import { Outlet } from "react-router-dom";
import { SurveyWebsiteView } from "src/screens/surveys/SurveysWebsiteView";

export const surveyMainRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      { path: "/*", element: <SurveyWebsiteView />  },
    ],
  },
];
