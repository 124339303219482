// @mui
import Avatar from "@mui/material/Avatar";
import AvatarGroup, { avatarGroupClasses } from "@mui/material/AvatarGroup";
import { IAttendee } from "src/models/events-live/attendee";
import { Stack, Typography } from "@mui/material";
import { getBrandingColors } from '../../pages/event/live/liveEventStyling';

// ----------------------------------------------------------------------

type Props = {
  participants: IAttendee[];
  totalParticipantsCount:number
};

export default function ChatHeaderDetail({ participants,totalParticipantsCount }: Props) {
  const userQty = 2;
  const colors = getBrandingColors();
  const getAttendeesHeader = () => {
    let _names: string = "";
    const firstThreeAttendees =
      participants &&
      participants
        .slice(0, userQty)
        .map((user) => `${user.firstName || user["First name"]}`);
    _names = firstThreeAttendees!.join(", ");
    // const participantsLength = participants && participants!.length; //This contains all participants length
    if (totalParticipantsCount === userQty) {
      return <>{_names}</>;
    } else {
      return (
        <>
          {_names}{" "}
          {totalParticipantsCount! > userQty!
            ? ` + ${totalParticipantsCount! - userQty!} others`
            : ""}
        </>
      );
    }
  };

  const renderGroup = (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        px: "10px",
        py: "0px",
        gap: "6px",
        borderRadius: "5px",
        height: "28px",
      }}
    >
      <AvatarGroup
        max={4}
        sx={{
          [`& .${avatarGroupClasses.avatar}`]: {
            width: "13px",
            height: "13px",
            borderRadius: "6px",
            border: "1px solid #EDF2F5",
            fontSize: "5px",
            ml: "-5px",
          },
        }}
      >

        {participants?.slice(0,3)?.map((participant, index) => (
          <Avatar
            sx={{
              transform: (index === 0 && 'rotate(-22deg)') || (index === 1 && 'rotate(22deg)') || (index === 2 && 'rotate(122deg) !important') || '',
              mt: (index === 0 && '2px') || (index === 1 && '-4px') || (index === 2 && '7px !important') || '',
              ml: (index === 0 && '-9px') || (index === 1 && '5px') || (index === 2 && '-14px !important') || '',
              // display: (index === 3 && 'none')
            }}
            key={participant.id}
            alt={participant.firstName}
            src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${participant.avatar}`}
          />
        ))}
      </AvatarGroup>
      <Typography
        variant="body2"
        sx={{
          color: colors?.Text || "#ffffff",
          fontFamily: "Poppins-500",
          fontSize: "12px",
          lineHeight: "17px",
        }}
      >
        {getAttendeesHeader()}
      </Typography>
    </Stack>
  );

  return <>{renderGroup}</>;
}
