import oneononemeeting from '../../../assets/images/feature/scheduled@2x.png';
import expo from '../../../assets/images/feature/expo@2x.png';
import sociallouge from '../../../assets/images/feature/social@2x.png';
import communitycentre from '../../../assets/images/feature/community@2x.png';

export const PopularFeatures = () => (
  <div className="mt-8 container mx-auto flex flex-col items-center">
    <div className="mx-auto text-center mt-5">
      <div className="text-lg text-gray-5">Popular Features</div>
      <div className="text-md text-gray-4 font-light">
        Build your own event with customised features
      </div>
    </div>
    <div className="flex justify-center container mx-auto mt-9">
      <div className="flex flex-col mr-11 ">
        <div
          className="flex flex-row bg-gray-1 rounded-2xl pt-8 pb-2"
          style={{ width: '518px', height: '223px' }}
        >
          <div className="-ml-12 -mt-6">
            <img
              className=""
              srcSet={`${oneononemeeting} 300w, ${oneononemeeting} 1000w`}
              sizes="(max-width: 590px) 30vw, (max-width: 590px) 30vw, 508px"
              alt=""
              src={oneononemeeting}
            />
          </div>
          <div className="w-1/2 -ml-3">
            <div className="text-lg font-extralight">Scheduled 1:1 Meetings</div>
            <div className="mt-2 text-md font-extralight">
              Schedule meeting with attendees that you'd like to
              {' '}
              <br />
              {' '}
              connect with based on common
              interests and needs.
            </div>
          </div>
        </div>

        <div
          className="flex flex-row bg-gray-1 rounded-2xl pt-8 pb-2 mt-7"
          style={{ width: '518px', height: '223px' }}
        >
          <div className="-ml-12 -mt-6">
            <img
              className=""
              srcSet={`${expo} 300w, ${expo} 1000w`}
              sizes="(max-width: 590px) 30vw, (max-width: 590px) 30vw, 508px"
              alt=""
              src={expo}
            />
          </div>
          <div className="w-1/2 -ml-3">
            <div className="text-lg font-extralight">Expo</div>
            <div className="mt-2 text-md font-extralight">
              A hybrid exhibition function:
              <ul className="list-disc ml-5">
                <li>Take video calls from visitors</li>
                <li>Share your screen for demos</li>
                <li>Downloadable handouts</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col ">
        <div
          className="flex flex-row bg-gray-1 rounded-2xl pl-6 pr-0 pt-8 pb-2"
          style={{ width: '518px', height: '223px' }}
        >
          <div>
            <div className="text-lg font-extralight">
              Social Lounge &amp;
              {' '}
              <br />
              {' '}
              Speed Networking
            </div>
            <div className="mt-2 text-md font-extralight">
              A virtual space where the
              {' '}
              <br />
              {' '}
              attendee can sit at any table,
              {' '}
              <br />
              {' '}
              move across
              tables, and
              {' '}
              <br />
              {' '}
              interact with other attendees.
            </div>
          </div>
          <div className="-mr-24 -mt-6">
            <img
              className=""
              srcSet={`${sociallouge} 300w, ${sociallouge} 1000w`}
              sizes="(max-width: 590px) 30vw, (max-width: 590px) 30vw, 508px"
              alt=""
              src={sociallouge}
            />
          </div>
        </div>
        <div
          className="flex flex-row bg-gray-1 rounded-2xl pl-6 pr-0 pt-8 pb-2 mt-7"
          style={{ width: '518px', height: '223px' }}
        >
          <div>
            <div className="text-lg font-extralight">
              Community Centre &amp;
              {' '}
              <br />
              {' '}
              On-Demand Access
            </div>
            <div className="mt-2 text-md font-extralight">
              Create a community of
              {' '}
              <br />
              {' '}
              followers, prospects a with
              {' '}
              <br />
              {' '}
              your events, contents,
              services
              {' '}
              <br />
              {' '}
              and offerings.
            </div>
          </div>
          <div className="-mr-24 -mt-4">
            <img
              className="ml-4"
              srcSet={`${communitycentre} 300w, ${communitycentre} 1000w`}
              sizes="(max-width: 590px) 30vw, (max-width: 590px) 30vw, 508px"
              alt=""
              src={communitycentre}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PopularFeatures;
