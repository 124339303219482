// @mui
import Stack from "@mui/material/Stack";
import { CardProps } from "@mui/material/Card";
import { HostAndAttendee } from "./attendee";
import { Box, Dialog, Divider, Grid, IconButton, Typography } from "@mui/material";
import SpeakerGridIcon from "./Icons/SpeakerGridIcon";
import { getBrandingColors } from '../event/live/liveEventStyling';
import Crosscon from "../event/reg-dialog-flow/Crosscon";
import MeetingRequestList from "../Sponsors/MeetingRequestList";
import { useCurrentUser } from "src/utils/hooks";
import { useState } from "react";

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  isSpeaker?: boolean;
  list: any[];
  columnWidth?: number;
  inviteAsConnection?: boolean;
  viewType?: boolean;
  isWhiteIcons?: boolean;
}

export default function AudienceList({
  title,
  subheader,
  isSpeaker,
  list,
  columnWidth,
  inviteAsConnection,
  viewType,
  isWhiteIcons,
  ...other
}: Props) {
  const user = useCurrentUser();
  const colors = getBrandingColors();

  const [isMeetingScheduleModal, setIsMeetingScheduleModal] = useState(false);
  const [meetingAttendee, setMeetingAttendee] = useState<any>({});

  const handleRequestMeeting = (attendee:string) => {
    if (attendee) {
      setIsMeetingScheduleModal(true);
      setMeetingAttendee(attendee);
    }
  }
  return (
    <>
      <Stack
        {...other}
        sx={{
          position: "relative",
          mb: "14px",
          px: '8px'
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: "14px", overflowX: "hidden" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton sx={{ p: 0 }}>
              <SpeakerGridIcon />
            </IconButton>

            <Typography
              variant="body2"
              sx={{
                fontSize: "13px",
                lineHeight: "24px",
                fontFamily: "Poppins-500",
                ml: "10px",
                flexWrap: "nowrap",
                color: colors?.Text! || "#ffffff",
                minWidth: isSpeaker ? "84px" : "125px",
              }}
            >
              {`${title} (${list.length})`}
            </Typography>
          </Box>

          <Divider
            sx={{
              borderColor: "#707070",
              width: "100%",
              ml: "0px",
              opacity: "50%",
            }}
          />
        </Stack>

        {!viewType ? (
          <Stack
          spacing='13px'
          >
            {list.map((item, index) => (
              <Stack>
                <HostAndAttendee
                  attendee={item}
                  isSpeaker={isSpeaker}
                  columnWidth={columnWidth}
                  title={title}
                  inviteAsConnection={inviteAsConnection}
                  viewType={viewType}
                  setOpenFeedbackPopup={()=>{}}
                  isWhiteIcons={isWhiteIcons}
                  setMeetingAttendee={handleRequestMeeting}
                />
              </Stack>
            ))}
          </Stack>
        ) : (
          <Stack>
            <Grid
              rowGap="21px"
              columnGap="0px"
              columnSpacing="25px"
              container
              sx={{}}
            >
              {list.map((item, index) => (
                <Grid key={index} item xs={6} md={4} lg={4}>
                  <HostAndAttendee
                    attendee={item}
                    isSpeaker={isSpeaker}
                    columnWidth={columnWidth}
                    title={title}
                    inviteAsConnection={inviteAsConnection}
                    viewType={viewType}
                    setOpenFeedbackPopup={()=>{}}
                    isWhiteIcons={isWhiteIcons}
                    setMeetingAttendee={handleRequestMeeting}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}
      </Stack>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isMeetingScheduleModal}
        sx={{
          '@media (max-width: 768px)': {
            margin: "15px",
            marginTop: "5px"
          },
          "& .MuiDialog-paper": {
            minWidth: "400px",
            borderRadius: "10px",
            background: getBrandingColors()?.Main || "#2F2F2F",
            '@media (max-width: 768px)': {
              minWidth: "100%",
            },
            '@media (min-width: 1700px)': {
              maxWidth: "70vw",
            },
          },

          "& .MuiPaper-root": {
            borderRadius: "10px !important",
            "& .MuiCard-root": {
              background: "none",
              borderRadius: "10px !important"
            }
          }

        }}
      >

        <Stack sx={{
          flexDirection: "column",
          width: '100%',
          justifyContent: "space-between",
          position: 'relative',
          px: '18px',
          py: '15px',
          backgroundColor: getBrandingColors()?.Secondary || "#000000",
          mb: '30px'
        }}>

        <Typography
          className="ellipsis-one-line"
          variant="body2"
          sx={{
            fontSize: "16px",
            lineHeight: "20px",
            color: getBrandingColors()?.Text || "white",
            fontFamily: "Poppins-500",
            fontWeight: 500
          }}
        >
          Schedule a meeting
        </Typography>

        <IconButton
          onClick={() => {
            setIsMeetingScheduleModal(false);
          }}
          sx={{
            zIndex: "100000000",
            position: "absolute",
            textAlignLast: "end",
            right: 14,
            top: 8,
            alignItems: "flex-end",
            justifyContent: "flex-end",
            p: "2px",
          }}
        >
          <Crosscon style={{ stroke: "#ffffff" }} />
        </IconButton>
        </Stack>
        <MeetingRequestList
          personId={user?.id}
          meetingAttendee={meetingAttendee}
          setMeetingAttendee={setMeetingAttendee}
          setIsMeetingScheduleModal={setIsMeetingScheduleModal}
        />
      </Dialog>
    </>
  );
}
