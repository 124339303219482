import { Reducer } from 'redux';

import { ICompanySizesState } from '../../../models/company/CompanySizesState';
import { CompanySizesActions } from './actions';
import { CompanySizeActionTypes } from './types';

const initialState: ICompanySizesState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const CompanySizesReducer: Reducer<ICompanySizesState, CompanySizesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CompanySizeActionTypes.COMPANY_SIZES_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case CompanySizeActionTypes.COMPANY_SIZES_SUCCESS: {
      return {
        ...state,
        value: action.data
      };
    }

    case CompanySizeActionTypes.COMPANY_SIZES_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
