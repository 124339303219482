/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './index.css';
import {
  BackgroundBlurProvider,
  lightTheme,
  MeetingProvider,
} from 'amazon-chime-sdk-component-library-react';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { ConsoleLogger, LogLevel } from 'amazon-chime-sdk-js';
import App from './app/layout/App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

const { version } = require('../package.json');

const originalConsoleLog = console.log;
console.log = (...args) => {
  if (
    !args.some(
      (arg) => typeof arg === 'string'
        && (arg.includes('BackgroundBlurProcessor')
          || arg.includes('ChimeSDKReactComponent'))
    )
  ) {
    originalConsoleLog.apply(console, args);
  }
};

const originalConsoleInfo = console.info;
console.info = (...args) => {
  if (
    !args.some(
      (arg) => typeof arg === 'string'
        && (arg.includes('BackgroundBlurProcessor')
          || arg.includes('ChimeSDKReactComponent'))
    )
  ) {
    originalConsoleInfo.apply(console, args);
  }
};

if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY_DNS}`,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [window.location.origin],
        networkRequestHeaders: ['Cache-Control'],
        networkResponseHeaders: ['Referrer-Policy'],
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    ignoreErrors: [
      /AbortError/,
      /canceled/,
      /Request aborted/, // Target Axios aborted requests
      /ECONNABORTED/,
    ],
    beforeSend(event, hint) {
      const error = hint.originalException as {
        name?: string;
        message?: string;
        code?: string;
        isAxiosError?: boolean;
        path?: string;
        statusCode?: number;
      };

      // Suppress Axios "Request aborted" errors
      if (
        error?.isAxiosError
        && (error.code === 'ECONNABORTED' || error.message?.includes('aborted'))
      ) {
        return null;
      }

      if (
        (error?.statusCode === 404 && error.path?.includes('/auth/profile'))
        || error?.name === 'AbortError'
      ) {
        return null;
      }
      return event;
    },
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // Capture all sessions with errors
    tracesSampleRate: 1.0,
    release: `panelist-react@${version}`, // Link to package.json version
    environment: process.env.NODE_ENV || 'development',
    attachStacktrace: true, // Ensure stack traces are captured
    normalizeDepth: 5, // Depth for error object serialization
    autoSessionTracking: true, // Enable session tracking
  });
  window.addEventListener('unhandledrejection', (event) => {
    const { reason } = event;

    // Capture only Error instances and meaningful rejections
    if (reason instanceof Error || typeof reason === 'string') {
      Sentry.captureException(reason, {
        contexts: {
          unhandled_promise: {
            promise: event.promise.toString(),
          },
        },
      });
    }

    // Optional: Add custom logging
    console.error('Unhandled Promise Rejection:', reason);

    // Prevent browser's default error logging if desired
    event.preventDefault();
  });
}

const logger = new ConsoleLogger('ChimeSDK', LogLevel.OFF);

const ChimeBlur = (props: any) => (
  <BackgroundBlurProvider {...props} logger={logger} />
);

const ChimeMeetingProvider = (props: any) => (
  <MeetingProvider {...props} logger={logger} />
);

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<div>An error occurred!</div>}>
      <Provider store={store}>
        <ThemeProvider theme={lightTheme}>
          <ChimeBlur>
            <ChimeMeetingProvider>
              <BrowserRouter>
                <App />
                <ToastContainer
                  hideProgressBar
                  autoClose={10000}
                  position="bottom-left"
                  closeOnClick
                  draggable
                  pauseOnHover
                />
              </BrowserRouter>
            </ChimeMeetingProvider>
          </ChimeBlur>
        </ThemeProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();
