import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ILikeUnlikePostState } from '../../../models/post/LikeUnlikePostState';
import { PostService } from '../../../services/Post';
import { LikePostActionTypes } from './types';

export interface ILikePostInProgressAction {
  type: LikePostActionTypes.IN_PROGRESS;
  inProgress: boolean;
}

export interface ILikePostSuccessAction {
  type: LikePostActionTypes.LIKE_POST;
  message: string;
}

export interface IUnlikePostSuccessAction {
  type: LikePostActionTypes.UNLIKE_POST;
  message: string;
}

export interface ILikePostErrorAction {
  type: LikePostActionTypes.ERROR;
  errorMessage: string;
}

export type LikePostActions =
  | ILikePostSuccessAction
  | IUnlikePostSuccessAction
  | ILikePostErrorAction;

export const likePost: ActionCreator<
  ThunkAction<Promise<any>, ILikeUnlikePostState, null, ILikePostSuccessAction>
> = (postId: string, reaction: string) => {
  return async (dispatch: Dispatch) => {
    const postService = new PostService();
    try {
      const result = await postService.likePost(postId, reaction.toLowerCase());

      dispatch({
        type: LikePostActionTypes.LIKE_POST,
        message: result.message
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: LikePostActionTypes.ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: LikePostActionTypes.IN_PROGRESS,
        loading: false
      });
    }
  };
};

export const unlikePost: ActionCreator<
  ThunkAction<Promise<any>, ILikeUnlikePostState, null, ILikePostSuccessAction>
> = (postId: string) => {
  return async (dispatch: Dispatch) => {
    const postService = new PostService();
    try {
      const result = await postService.unlikePost(postId);

      dispatch({
        type: LikePostActionTypes.UNLIKE_POST,
        message: result.message
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: LikePostActionTypes.ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: LikePostActionTypes.IN_PROGRESS,
        loading: false
      });
    }
  };
};

export const setInProgress: ActionCreator<
  ThunkAction<any, ILikeUnlikePostState, null, ILikePostInProgressAction>
> = (isInProgress: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: LikePostActionTypes.IN_PROGRESS,
    loading: isInProgress
  });
