/* eslint-disable no-nested-ternary */
import * as Yup from "yup";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Stack,
  Button,
  Divider,
  Typography,
  Box,
  MenuItem,
  CircularProgress,
  FormControl,
  Grid,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import {
  SurveyItemType,
  SurveyQuestionsForm,
  Options,
  SurveyQuestionwithAnswer,
  SurveyProps,
  Page,
  Output,
  MeetingRef,
  jobFunctionsOptions,
} from "src/mui/types/survey";
import { useForm } from "react-hook-form";
import FormProvider from "src/mui/components/hook-form/form-provider";
import {
  PMultiCheckbox,
  PRadioGroup,
  PSlider,
  RHFSelect,
  RHFTextField,
} from "src/mui/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "./Card.css";
import MatchAttendeeList from "./MatchAttendeeList";
import MeetingRequestList from "./MeetingRequestList";
import { useSurveyContext } from "src/mui/providers/GeneralContext";
import { useSelector } from "react-redux";
import { IAppState } from "src/store";
import { toast } from "react-toastify";
import useStyling from "src/mui/components/hook-form/useStyling";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import classNames from "classnames";
import { emailRegex, forbiddenEmailDomains } from "./constants";
import { getFieldLabel } from "../event/reg-dialog-flow/ConstantsandTypes";
import { IEvent } from "src/models/event/event";
import useEventIsPassed from "./useEventIsPassed";
import PTargetDepartment from "src/screens/company/company-profile/PTargetDepartment";
import PHeadCountSelect from "src/screens/company/company-profile/PHeadCountSelect";
import moment from "moment-timezone";
import { getUTCTimeZone } from "src/mui/assets/data/timezones";
import { CustomAvatar } from "src/mui/components/hook-form/custom-avatar";
import { getMinifiedImage } from "src/mui/utils/image";
import { ReactComponent as UserDefault } from "../../../assets/images/default-profile.svg";
import { RegistrationProgressType } from "./enum";
import DragAndDropIcon from "./DragAndDropIcon";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import PastEvent from "./PastEvent";

interface Condition {
  and?: boolean;
  or?: boolean;
}

let questionSenariosVisibility: any = {};
let requiredConstantPerQuestionIds: any = {};

function Survey(
  {
    setSurveySubPage,
    surveySubPage,
    setStepName,
    setStepDescription,
    setCurrentSurveyStep,
    currentSurveyStep,
    getCurrentSurveyPage,
    open,
    userId,
    setRequiredQuestionsFilled,
    setIsSuveryorRegSkipable,
    onSurveyCompleted,
  }: SurveyProps,
  ref: any
) {
  const meetingListRef = useRef<MeetingRef>(null);
  // host context
  const {
    getSurveyQuestionsWithAnswers,
    modifySurveyAnswers,
    surveyPageLoader,
    surveyAnsLoader,
    surveyQuestionsWithAnswers,
    surveyPages,
    jobFunctions,
    surveyQuestions,
    getSurveyQuestions,
    getJobFunctions,
    submitSurveyLater,
  } = useSurveyContext();
  // redux state
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);

  const { companySizes } = useSelector((state: IAppState) => state);

  // component state
  const [allPagesQuestionsIds, setAllPagesQuestionsIds] = useState<any>([]);
  const [questionPerPage, setQuestionPerPage] = useState<any>({});
  const [currentPageFontColor, setCurrentPageFontColor] = useState<
    string | null
  >("");
  const [currentPageTitleDescPosition, setCurrentPageTitleDescPosition] =
    useState<string | null>("");
  const [currentPageBackgroundColor, setCurrentPageBackgroundColor] = useState<
    string | null
  >("");
  const [currentPagePrimaryBgColor, setCurrentPagePrimaryBgColor] = useState<
    string | null
  >("");
  const [currentPageHeaderColor, setCurrentPageHeaderColor] = useState<
    string | null
  >("");
  const [allPhoneNumbersCountryCode, setAllPhoneNumbersCountryCode] =
    useState<any>({});
  const [showMatchUsers, setShowMatchUsers] = useState<boolean>(false);
  const [exitSurveyLoading, setExitSurveyLoading] = useState<boolean>(false);
  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const [selectedRoom, setSelectedRoom] = useState<any>({ roomIndex: "" });
  const [meetingAttendee, setMeetingAttendee] = useState<any>({});
  const [requiredQuestionsIds, setRequiredQuestionsIds] = useState<any>({});
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const topRef = useRef<HTMLDivElement>(null);
  const isMobileScreen = useMediaQuery("(min-width:768px)");

  const scrollToTop = () => {
    if (topRef.current) topRef.current.scrollTop = 0;
  };

  const {
    styles,
    secondaryFontColor,
    fieldBorderColor,
    focusColor,
    secondaryBackgroundColor,
    headerColor,
    primaryBackgroundColor,
  } = useStyling(
    "survey",
    currentPageFontColor,
    currentPageBackgroundColor,
    currentPageHeaderColor,
    currentPageTitleDescPosition
  );
  const [textSliderValue, setTextSliderValue] = useState<any>([]);
  const [isFocused, setIsFocused] = useState<{ [id: string]: boolean }>({});
  const [nextButtonClicked, setNextButtonClicked] = useState<boolean>(false);
  const [termAndConditionAllPages, setTermAndConditionAllPages] = useState<any>(
    []
  );

  const {
    isLevnovoWebsiteTemplate,
    registrationUnderReview,
    userExitedSurvey,
    canAttendeesRequestMeeting
  } = useEventIsPassed();

  // redux state
  const { upcomingEvent } = useSelector((state: IAppState) => state);
  // hook
  const isSurveySubmitted =
    upcomingEvent?.value?.data?.attendeeStatus?.isSurveySubmitted;

  const handleFocus = (id: string) => {
    setIsFocused({ [id]: true });
  };

  const handleBlur = (id: string) => {
    setIsFocused({
      [id]: false,
    });
  };

  // default values
  const defaultValues: any = useMemo(
    () => {
      const formDefaultValues: any = {};
      if (surveyPages?.length && surveyQuestionsWithAnswers) {
        surveyPages.forEach((item: Page) => {
          const { eventSurveyFields } = item;
          eventSurveyFields.forEach((question: SurveyQuestionsForm) => {
            const value = surveyQuestionsWithAnswers.find(
              (answer: SurveyQuestionwithAnswer) =>
                answer?.userSurveyField?.surveyFieldId === question?.id
            );
            if (value) formDefaultValues[question?.id] = value?.answer;
            else formDefaultValues[question?.id] = undefined;
          });
        });
      }
      return formDefaultValues;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [surveyPages, surveyQuestionsWithAnswers]
  );

  const requiredEmailSchema = Yup.string()
    .required("Business email is required")
    .email("Invalid email")
    .test("not-included", "Business email address only", (value) => {
      if (value) {
        // Check if any forbidden substring is present in the email
        const includesForbiddenSubstring = forbiddenEmailDomains.some(
          (substring) => value?.toLowerCase()?.includes(substring)
        );
        return !includesForbiddenSubstring; // Return false if any forbidden substring is found
      }
      return true; // Return true if the email is valid
    })
    .matches(emailRegex, "Should be a valid business email");

  const notRequiredEmailSchema = Yup.string()
    .notRequired()
    .email("Invalid email")
    .test("not-included", "Business email address only", (value) => {
      if (value) {
        // Check if any forbidden substring is present in the email
        const includesForbiddenSubstring = forbiddenEmailDomains.some(
          (substring) => value?.toLowerCase()?.includes(substring)
        );
        return !includesForbiddenSubstring; // Return false if any forbidden substring is found
      }
      return true; // Return true if the email is valid
    })
    .matches(emailRegex, "Should be a valid business email");

  const checkForOtherAndSpecifyText = (item: string) =>
    item?.toLowerCase()?.includes("other") &&
    item?.toLowerCase()?.includes("specify");

  const createRadioBoxArrayValidationSchema = (
    questionId: string,
    optionIds: string[]
  ) => {
    return Yup.string()
      .required("Required Field")
      .test(
        "all-options-filled",
        "All options must be selected",
        function (value) {
          const { parent } = this;
          const optionsFilled = optionIds.every(
            (optionId) =>
              parent?.[optionId] &&
              parent?.[optionId]?.toString()?.trim() !== ""
          );
          return optionsFilled;
        }
      );
  };

  const validRange=(fieldValue:any)=>{
    const stringValue = fieldValue?.toString()?.trim();
    return (stringValue && stringValue !== "") || stringValue === "0" || fieldValue === 0 
  }

  const createConstantSumPerValidationSchema = (
    questionId: string,
    optionIds: string[]
  ) =>
    Yup.string()
      .required("Required Field")
      .test(
        "options-validation",
        function () {
          const { parent } = this;
          const optionsFilled = optionIds.every(optionId => validRange(parent?.[optionId]));
  
          if (!optionsFilled) {
            return this.createError({
              message: "Please fill out all ranges so that the total equals 100%",
            });
          }
  
          const sum = optionIds.reduce((total, optionId) => total + parseInt(parent?.[optionId] || 0, 10), 0);
  
          if (sum > 100) {
            return this.createError({
              message: "Total should equal to 100",
            });
          }
  
          requiredConstantPerQuestionIds[questionId] = optionIds.reduce(
            (acc, optionId) => ({
              ...acc,
              [optionId]: !validRange(parent?.[optionId]),
            }),
            {}
          );
  
          return true;
    });

  // const createConstantSumPerValidationSchema = (
  //   questionId: string,
  //   optionIds: string[]
  // ) =>
  //   Yup.string()
  //     .required("Required Field")
  //     .test(
  //       "all-options-filled",
  //       "Please fill out all ranges so that the total equals 100%",
  //       function (value) {
  //         const { parent } = this;
  //         const optionsFilled = optionIds.every((optionId) => {
  //           const fieldValue = parent?.[optionId];
  //           const stringValue = fieldValue?.toString()?.trim();
  //           return (
  //             (stringValue && stringValue !== "") ||
  //             stringValue === "0" ||
  //             fieldValue === 0
  //           );
  //         });
  //         if (optionsFilled) {
  //           requiredConstantPerQuestionIds = {
  //             ...requiredConstantPerQuestionIds,
  //             [questionId]: {},
  //           };
  //         } else {
  //           optionIds?.forEach((optionId) => {
  //             const fieldValue = parent?.[optionId];
  //             const stringValue = fieldValue?.toString()?.trim();
  //             if (
  //               !(
  //                 (stringValue && stringValue !== "") ||
  //                 stringValue === "0" ||
  //                 fieldValue === 0
  //               )
  //             ) {
  //               requiredConstantPerQuestionIds = {
  //                 ...requiredConstantPerQuestionIds,
  //                 [questionId]: {
  //                   ...requiredConstantPerQuestionIds[questionId],
  //                   [optionId]: true,
  //                 },
  //               };
  //             } else {
  //               requiredConstantPerQuestionIds = {
  //                 ...requiredConstantPerQuestionIds,
  //                 [questionId]: {
  //                   ...requiredConstantPerQuestionIds[questionId],
  //                   [optionId]: false,
  //                 },
  //               };
  //             }
  //           });
  //         }
  //         return optionsFilled;
  //       }
  //     );

  // form validations
  const fieldsObject = useMemo(() => {
    const formFields: any = {};

    if (surveyPages?.length) {
      surveyPages.forEach((item: Page) => {
        const { eventSurveyFields } = item;
        eventSurveyFields.forEach((field: SurveyQuestionsForm) => {
          const { isRequired, id, fieldType, limitTo, surveyOptionFields } =
            field;

          let schema;

          switch (fieldType) {
            case SurveyItemType.RADIO_BOX_ARRAY:
              if (isRequired) {
                const optionIds = surveyOptionFields?.map(
                  (option) => `${id}_${option.id}`
                );

                schema = createRadioBoxArrayValidationSchema(id, optionIds);

                surveyOptionFields?.forEach((option) => {
                  formFields[`${id}_${option.id}`] = Yup.string().required();
                });
              }
              break;

            case SurveyItemType.Rank:
              schema = Yup.array().notRequired();
              break;

            case SurveyItemType.CONSTANT_SUM_PERCENTAGE:
              const optionId = surveyOptionFields?.map(
                (option) => `${id}_${option.id}`
              );
              schema = createConstantSumPerValidationSchema(id, optionId);
              break;

            case SurveyItemType.BUSINESS:
              schema = isRequired
                ? requiredEmailSchema
                : notRequiredEmailSchema;
              break;

            case SurveyItemType.TARGET_DEPARTMENT:
            case SurveyItemType.ORGANIZATION_SIZE:
              schema = isRequired
                ? Yup.object().required("Required Field")
                : Yup.object().notRequired();
              break;

            case SurveyItemType.PHONE:
              schema = isRequired
                ? Yup.string()
                    .required("Required Field")
                    .min(8, "Invalid Phone Number")
                    .test("no-spaces", "Required Field", function (value) {
                      return typeof value === "string" && value.trim() !== "";
                    })
                : Yup.string().notRequired();
              break;

            case SurveyItemType.DROPDOWN:
              schema = isRequired
                ? Yup.string()
                    .required("Required Field")
                    .test("no-spaces", "Required Field", function (value) {
                      return typeof value === "string" && value.trim() !== "";
                    })
                    .test(
                      "other-field-empty",
                      "Required Field",
                      function (value) {
                        const { parent } = this;
                        if (
                          value &&
                          value.trim() !== "" &&
                          checkForOtherAndSpecifyText(value) &&
                          !parent[`otherId-${id}`]
                        ) {
                          return false;
                        }
                        return true;
                      }
                    )
                : Yup.string()
                    .notRequired()
                    .test(
                      "other-field-empty",
                      "Required Field",
                      function (value) {
                        const { parent } = this;
                        if (
                          value &&
                          value.trim() !== "" &&
                          checkForOtherAndSpecifyText(value) &&
                          !parent[`otherId-${id}`]
                        ) {
                          return false;
                        }
                        return true;
                      }
                    );
              break;

            case SurveyItemType.CHECKBOX:
              schema = Yup.array();
              if (isRequired) {
                schema = limitTo
                  ? schema.length(
                      limitTo,
                      `Exactly ${limitTo} item(s) are required`
                    )
                  : schema.min(1, "At least one item is required");
              } else {
                schema = limitTo
                  ? schema.length(
                      limitTo,
                      `Exactly ${limitTo} item(s) are required`
                    )
                  : schema.notRequired();
              }
              schema = schema.test(
                "other-field-empty",
                "Required Field",
                function (value) {
                  const { parent } = this;
                  if (
                    value?.some((item) => checkForOtherAndSpecifyText(item)) &&
                    !parent[`otherId-${id}`]
                  ) {
                    return false;
                  }
                  return true;
                }
              );
              break;

            case SurveyItemType.TERM_AND_CONDITION:
            case SurveyItemType.TECHNOLOGY_INVESTMENT:
            case SurveyItemType.Day:
            case SurveyItemType.Session:
            case SurveyItemType.Room:
              schema = isRequired
                ? Yup.array().min(1, "At least one item is required")
                : Yup.array().notRequired();
              break;

            default:
              schema = isRequired
                ? Yup.string()
                    .required("Required Field")
                    .min(1, "Name must be at least 1 characters.")
                    .test("no-spaces", "Required Field", function (value) {
                      return typeof value === "string" && value.trim() !== "";
                    })
                : Yup.string().notRequired();
              break;
          }

          formFields[id] = schema;
        });
      });
    }
    return formFields;
  }, [surveyPages, allPhoneNumbersCountryCode]);

  const handlePhoneNumberChange = (
    e: any,
    country: any,
    questionId: string
  ) => {
    // setPhoneNumber(e)
    setValue(questionId, e);
    if (country) {
      setAllPhoneNumbersCountryCode({
        ...allPhoneNumbersCountryCode,
        [questionId]: country?.countryCode!,
      });
    }
  };

  const NewEventSchema = Yup.object().shape(fieldsObject);

  // formik hook
  const methods = useForm<any>({
    mode: "all",
    resolver: yupResolver(NewEventSchema),
    defaultValues,
  });

  // form methods
  const {
    setValue,
    watch,
    reset,
    handleSubmit,
    trigger,
    // setError,
    formState: { isValid, errors },
  } = methods;
  const values = watch();

  const filterRadioBoxQuestion = (
    currentPageQuestions: any[],
    currentPageQuestionsIds: string[]
  ) => {
    return (
      currentPageQuestions?.filter(
        (question: any) =>
          currentPageQuestionsIds?.includes(question.id) &&
          question.fieldType === SurveyItemType.RADIO_BOX_ARRAY
      ) || []
    );
  };

  const addSurveyOptionIdsInPageQuestionIds = (
    currentPageQuestionsIds: string[],
    radioBoxQuestions: any[]
  ) => {
    let questionIds = currentPageQuestionsIds
      ? [...currentPageQuestionsIds]
      : [];
    radioBoxQuestions?.forEach((question: any) => {
      const questionIdIndex = questionIds.indexOf(question.id);
      const newIds: string[] = [];

      question.surveyOptionFields?.forEach((option: any) => {
        const optionId = `${question.id}_${option.id}`;
        if (!newIds.includes(optionId)) {
          newIds.push(optionId);
        }
      });

      questionIds = questionIds?.filter((q: any) => q !== question?.id);
      if (questionIdIndex !== -1) {
        questionIds.splice(questionIdIndex + 1, 0, ...newIds);
      } else {
        questionIds.push(...newIds);
      }

      questionIds.push(question.id);
    });
    return questionIds;
  };

  const findFirstUnAnsweredQuestion = (questionIds:any[]) =>questionIds.find((questionId) => !values[questionId]);

  const getCurrentPageQuestions = (currentPageId:string) =>{
    const currentPageQuestions = questionPerPage?.[currentPageId]?.flatMap((question: any) => {
      if (question?.type === "default") {
        return question?.obj || [];
      } else if (question?.type === "room") {
        const roomIds = Object.keys(question?.obj || {});
        return roomIds.flatMap((roomId: string) => {
          return question?.obj?.[roomId] || [];
        });
      }
      return [];
    });

    return currentPageQuestions || [];
  }

  const allPages = Object.keys(questionPerPage);
  const currentPageId = allPages[currentSurveyStep];

  const currentPageQuestionsIds = allPagesQuestionsIds[currentPageId];
  const currentPageQuestions =  getCurrentPageQuestions(currentPageId);

let questionIds : any=[];

const questionsToCheck = filterRadioBoxQuestion(
    currentPageQuestions,
    currentPageQuestionsIds
  );

  if (questionsToCheck?.length) {
    questionIds = addSurveyOptionIdsInPageQuestionIds(
      questionIds,
      questionsToCheck
    );
  }

  const notAnswerQuestionId : any[] = findFirstUnAnsweredQuestion(questionIds)

  // burak we don't need to run the above code if errors object key is greater then 1 

  // 

  const firstError = Object.keys(errors)?.[0] || notAnswerQuestionId ;
  let el = document.querySelector(`[name="${firstError}"]`);
  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  // CSS styles for focus state
  const focusStyles = {
    outline: "none",
    boxShadow: `0 0 0 0.15em ${focusColor}`,
    border: `1px solid ${currentPageFontColor || secondaryFontColor}`,
  };

  const checkPhoneFieldValidation = (
    values: any,
    id: string,
    isRequired: boolean
  ) => {
    if (isRequired && nextButtonClicked) {
      if (values[id] && values[id] !== "") {
        return false;
      }
      return true;
    }

    return false;
  };

  const checkTermFieldValidation = (
    val: any,
    question: any,
    isRequired: boolean
  ) => {
    let isValid;
    let result: any;
    if (nextButtonClicked && val) {
      result = findMatchingFieldId(
        question?.id,
        { [question?.id]: val },
        question
      );
      const requiredIds = question.surveyOptionFields
        .filter((option: any) => option.isRequired)
        .map((option: any) => option.id);

      const allRequiredIdsPresent = requiredIds.every((id: string) =>
        Object.keys(result)?.includes(id)
      );

      if (allRequiredIdsPresent) {
        // setTermAndConditionValid(true)
        sessionStorage.setItem("termAndCondition", "true");
        isValid = true;
      } else {
        // setTermAndConditionValid(false)
        sessionStorage.setItem("termAndCondition", "false");
        isValid = false;
      }
    } else {
      if (event.websiteTemplate === "lenovo" && isRequired && !val) {
        setValue(question.id, []);
      }
    }

    return isValid;
  };

  const handleSelectTechnology = (option: any, fieldId: string) => {
    const selectedTechnology = values?.[fieldId] || [];

    if (selectedTechnology?.includes(option?.optionName)) {
      setValue(
        fieldId,
        selectedTechnology?.filter((x: any) => x !== option?.optionName)
      );
    } else setValue(fieldId, [...selectedTechnology, option?.optionName]);
  };

  const evaluateConditions = (conditions: Condition[]): boolean => {
    let result: boolean | null = null;
    let andFlag = false;

    for (const condition of conditions) {
      if (condition.and !== undefined) {
        if (result === null) {
          result = condition.and;
        }
        //  else if (andFlag) {
        //     result = result && condition.and;
        // }
        else {
          result = result && condition.and;
        }
        andFlag = true;
      } else if (condition.or !== undefined) {
        if (result === null) {
          result = condition.or;
        }
        //  else if (andFlag) {
        //     result = result && condition.or;
        // }
        else {
          result = result || condition.or;
        }
        // andFlag = false;
      }
    }

    return result ?? true;
  };

  const getQuestionAction = (
    senariosResult: boolean,
    registrationFlowAction?: any[],
    fieldNames?: any,
    triggerId?: any,
    fieldName?: string
  ) => {
    const onSuccess: any = registrationFlowAction?.find(
      (reg: any) => reg?.type === "success"
    );
    const onFail = registrationFlowAction?.find(
      (reg: any) => reg?.type === "fail"
    );
    let fieldValues: any = [];
    fieldNames?.forEach((field: any) => {
      switch (field?.type) {
        case SurveyItemType.TEXT:
        case SurveyItemType.JOBTITLE:
        case SurveyItemType.BUSINESS:
        case SurveyItemType.DROPDOWN:
        case SurveyItemType.MULTIPLE:
        case SurveyItemType.PHONE:
          fieldValues.push(values[field?.id] == null);
          break;

        case SurveyItemType.ORGANIZATION_SIZE:
        case SurveyItemType.TARGET_DEPARTMENT:
          fieldValues.push(values[field?.id] == null);
          break;

        case SurveyItemType.CHECKBOX:
        case SurveyItemType.TECHNOLOGY_INVESTMENT:
        case SurveyItemType.Day:
        case SurveyItemType.Room:
        case SurveyItemType.Session:
          fieldValues.push(!values[field?.id]?.length);
          break;

        case SurveyItemType.SLIDER:
          fieldValues.push(values[field?.id] == null);
          break;
        default:
          fieldValues.push(false);
      }
    });

    if (!registrationFlowAction?.length) {
      return "show";
    } else if (
      fieldNames &&
      fieldValues.every((k: boolean) => k) &&
      triggerId.every((key: string) => !key) &&
      (onSuccess?.action == "show" || onSuccess?.action == "hide")
    )
      return "nullValue";
    else if (senariosResult) {
      return onSuccess?.action;
    }

    return onFail?.action;
  };

  const checkVisibility = (
    registrationFlow: any,
    fieldId: string,
    fieldName: string,
    registrationFlowAction: any,
    eventQuestionPageId: string
  ) => {
    const results: any[] = [];
    let fieldNames: any = [];
    let triggerId: any = [];
    if (registrationFlow?.length === 0) {
      if (!questionSenariosVisibility[fieldId]) {
        questionSenariosVisibility[fieldId] = {
          questionId: fieldId,
          currentStatus: "show",
          fieldName,
          senariosResult: true,
          pageId: eventQuestionPageId,
        };
      }
    }

    registrationFlow?.forEach((scenario: any, index: number) => {
      const {
        action,
        condition,
        question,
        scenarioType,
        triggerOption,
        trigger,
      } = scenario;
      fieldNames.push({ type: question?.fieldType, id: question?.id });
      triggerId.push(trigger);
      if (!trigger) {
        let result;
        switch (question?.fieldType) {
          case SurveyItemType.TEXT:
          case SurveyItemType.JOBTITLE:
          case SurveyItemType.BUSINESS:
          case SurveyItemType.DROPDOWN:
          case SurveyItemType.MULTIPLE:
          case SurveyItemType.PHONE:
            result = values[question?.id] == triggerOption?.optionName;
            break;

          case SurveyItemType.ORGANIZATION_SIZE:
          case SurveyItemType.TARGET_DEPARTMENT:
            result = values[question?.id]?.name == triggerOption?.optionName;
            break;
          case SurveyItemType.CHECKBOX:
          case SurveyItemType.TECHNOLOGY_INVESTMENT:
          case SurveyItemType.Day:
          case SurveyItemType.Room:
          case SurveyItemType.Session:
            result = values[question?.id]?.includes(triggerOption?.optionName);
            break;

          case SurveyItemType.SLIDER:
            result = values[question?.id] == triggerOption?.ordering;
            break;
          default:
            result = false;
        }
        results?.push({
          [condition]: result,
          action: result ? "matched" : "notMatched",
        });
      } else if (trigger) {
        const dependentQuestion = questionSenariosVisibility[question?.id];
        const result =
          trigger?.includes(dependentQuestion?.currentStatus) ||
          (trigger === "hidden" && dependentQuestion?.currentStatus === "hide");
        results?.push({
          [condition]: result,
          action: result ? action : "notMatched",
          trigger,
          questionId: question?.id,
          fieldName: question?.fieldName,
        });
      }
    });

    const senariosResult = evaluateConditions(results);

    questionSenariosVisibility[fieldId] = {
      questionId: fieldId,
      currentStatus: getQuestionAction(
        senariosResult,
        registrationFlowAction,
        fieldNames,
        triggerId,
        fieldName
      ),
      fieldName,
      senariosResult: results?.length ? senariosResult : true,
      pageId: eventQuestionPageId,
    };

    return questionSenariosVisibility[fieldId];
  };
  const displayDivider = (
    currentIndex: number,
    totalQuestionLength: number
  ) => (
    <Divider
      sx={{
        padding: "0px",
        margin: "0px",
        mt: "12px !important",
        mb: "8px !important",
        borderColor:
          event?.websiteTemplate === "spectre"
            ? "rgba(255, 255, 255, 0.15)"
            : "#707070",
      }}
    />
  );

  const resetFieldValue = (
    currentQuestionVisibility: boolean,
    fieldId: string
  ) => {
    if (!currentQuestionVisibility && values[fieldId] !== null) {
      setValue(fieldId, null);
    }
  };

  const displayRadioQuestion = (
    fieldName: string,
    id: string,
    radioOptions: any[],
    isRequired: boolean
  ) => {
    return (
      <Box>
        <Typography
          id="event-name-input"
          sx={{
            color: currentPageFontColor || secondaryFontColor,
            fontSize: 14,
            fontFamily: "Poppins-500",
            lineHeight: "18px",
            mb: "10px",
          }}
        >
          {getFieldLabel(isRequired, fieldName)}
        </Typography>
        <PRadioGroup
          row
          spacing={4}
          name={id}
          options={radioOptions}
          sx={{
            ...styles,
            "& .MuiFormHelperText-root": { ml: "1px" },
            "& .MuiRadio-root.Mui-checked": {
              color: `${currentPageFontColor || secondaryFontColor} !important`,
            },
            "& .MuiFormControlLabel-label": {
              color: `${currentPageFontColor || secondaryFontColor} !important`,
              fontSize: "14px !important",
              fontFamily: "Poppins-500 !important",
              lineHeight: "21px !important",
              "&:hover":{
                textDecoration:"underline"
              }
            },
            "& .MuiRadio-root": {
              color: `${currentPageFontColor || secondaryFontColor} !important`,
              p: "3px !important",
               pl: "5px !important",
            },
            "&.MuiFormGroup-root": {
              flexDirection: "column !important",
              gap: "0px",
            },
            "& .MuiFormControlLabel-root": {
              ml: "-6px !important",
            },
          }}
        />

        {values[id] && checkForOtherAndSpecifyText(values[id]) && (
          <RHFTextField
            sx={{
              color: currentPageFontColor || secondaryFontColor,
              fontSize: 16,
              fontFamily: "Poppins-500",
              lineHeight: "21px",
              ...styles,
              "& .MuiOutlinedInput-root input::placeholder": {
                color: currentPageFontColor || secondaryFontColor,
                opacity: 1,
              },
              mt: "12px",
              "& fieldset": {
                border:
                  errors[id]?.type === "other-field-empty" &&
                  "1px solid #FF5630 !important",
              },
            }}
            onChange={(e) => {
              setValue(`otherId-${id}`, e.target.value);
            }}
            value={values[`otherId-${id}`]}
            name={`otherId-${id}`}
            placeholder={"Type here…"}
          />
        )}
      </Box>
    );
  };

  const getStartEndTime = (startTime: any, endTime: any) => {
    const eventTimezone: any = getUTCTimeZone(event?.timezone);
    return ` ${moment(startTime)
      ?.tz(eventTimezone!)
      ?.format("HH:mm")} - ${moment(endTime)
      ?.tz(eventTimezone!)
      ?.format("HH:mm")}`;
  };

  const getSessionUserDetails = (session: any) => {
    let avatar = null;
    let name = "";
    let companyName: any = null;
    if (session?.eventSessionSpeakers?.length) {
      const speaker = session?.eventSessionSpeakers?.[0]?.eventSpeaker;
      avatar = speaker?.photo;
      name = `${speaker?.firstName} ${speaker?.lastName}`;
      companyName = speaker?.company?.name;
    } else if (session?.eventSessionSponsors?.length) {
      const sponsor = session?.eventSessionSponsors?.[0]?.eventSponsor;
      avatar = sponsor?.company?.logo;
      name = `${sponsor?.company?.name}`;
      companyName = null;
    }
    return { avatar, name, companyName };
  };

  const renderRoomOptions = (room: any, fieldId: string) => {
    const roomFieldId = values?.[fieldId] || [];
    return (
      <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={room.id}>
        <Stack
          sx={{
            flex: 1,
            background: roomFieldId.some((x: any) => x.id === room.id)
            ? currentPageHeaderColor || headerColor
            : currentPagePrimaryBgColor || primaryBackgroundColor,
            borderRadius: "5px",
            padding: "0px 8px",
            height: "43px",
            justifyContent: "center",
            cursor: "pointer",
            "&:hover":{
              background:
              currentPageHeaderColor || headerColor
            },
          }}
          onClick={() => {
            const exist = roomFieldId?.some((x: any) => x.id === room.id);
            if (exist) {
              setValue(
                fieldId,
                roomFieldId?.filter((x: any) => x.id !== room.id)
              );
            } else {
              setValue(fieldId, [
                ...roomFieldId,
                {
                  id: room.id,
                  optionName: room.name,
                },
              ]);
            }
          }}
        >
          <Tooltip title={room.name}>
            <Typography
              className="ellipsis-one-line"
              sx={{
                fontSize: "16px",
                fontWeight: "400 !important",
                color: currentPageFontColor || secondaryFontColor,
                lineHeight: "19px",
                wordBreak: "break-all",
              }}
            >
              {room.name}
            </Typography>
          </Tooltip>
        </Stack>
      </Grid>
    );
  };

  const renderDayOptions = (day: any, fieldId: string, index: number) => {
    const dayFieldId = values?.[fieldId] || [];
    return (
      <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={day.id}>
        <Stack
          sx={{
            flex: 1,
            background: dayFieldId?.some((x: any) => x.id === day.id)
            ? currentPageHeaderColor || headerColor
            : currentPagePrimaryBgColor || primaryBackgroundColor,
            borderRadius: "5px",
            padding: "0px 8px",
            height: "43px",
            justifyContent: "center",
            cursor: "pointer",
            "&:hover":{
              background:
              currentPageHeaderColor || headerColor
            },
          }}
          onClick={() => {
            const exist = dayFieldId?.some((x: any) => x.id === day.id);
            if (exist) {
              setValue(
                fieldId,
                dayFieldId?.filter((x: any) => x.id !== day.id)
              );
            } else {
              setValue(fieldId, [
                ...dayFieldId,
                {
                  id: day.id,
                  optionName: `Day ${index + 1}`,
                },
              ]);
            }
            // setChangeInOptionField(true);
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400 !important",
              color: currentPageFontColor || secondaryFontColor,
              lineHeight: "19px",
            }}
          >
            {/* Day {index + 1} */}
            {event?.id === '839c9658-39db-4595-b9ee-4868036f1fe5'
                ? (index === 0
                  ? 'Pre-Conference Dinner'
                  : index === 1
                    ? 'Conference Day'
                    : `Day ${index + 1}`
                )
                : `Day ${index + 1}`}
          </Typography>
          <Tooltip
            title={`${moment(day?.startTime).format("DD MMM YYYY")} ${getStartEndTime(
              day.startTime,
              day.endTime
            )}`}
          >
            <Typography
              className="ellipsis-one-line"
              sx={{
                fontSize: "14px",
                fontWeight: "400 !important",
                color: currentPageFontColor || secondaryFontColor,
                lineHeight: "19px",
                wordBreak: "break-all",
              }}
            >
              {moment(day?.startTime).format("DD MMM YYYY")}{" "}
              {getStartEndTime(day.startTime, day.endTime)}
            </Typography>
          </Tooltip>
        </Stack>
      </Grid>
    );
  };

  const renderSessionOptions = (session: any, fieldId: string) => {
    const { avatar, name, companyName } = getSessionUserDetails(session);
    const sessionFieldId = values?.[fieldId] || [];
    return (
      <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={session.id}>
        <Stack
          gap="10px"
          direction="row"
          sx={{
            flex: 1,
            border: sessionFieldId.some((x: any) => x.id === session.id)
              ? `1px solid  ${
                  currentPagePrimaryBgColor || primaryBackgroundColor
                }`
              : `1px solid ${currentPageFontColor}`,
            borderRadius: "10px",
            height: "110px",
            padding: "20px 18px 20px 14px",
            cursor: "pointer",
          }}
          onClick={() => {
            const exist = sessionFieldId?.some((x: any) => x.id === session.id);
            if (exist) {
              setValue(
                fieldId,
                sessionFieldId?.filter((x: any) => x.id !== session.id)
              );
            } else {
              setValue(fieldId, [
                ...sessionFieldId,
                {
                  id: session.id,
                  optionName: session.title,
                },
              ]);
            }
          }}
        >
          {avatar ? (
            <CustomAvatar
              sx={{ width: "41px", height: "41px", borderRadius: "15px" }}
              src={getMinifiedImage(avatar)}
              alt="abdul"
              name="abdul"
            />
          ) : (
            <Stack
              sx={{
                width: "41px",
                height: "41px",
                borderRadius: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#edf2f5",
                overflow: "hidden",
                minWidth: "41px",
              }}
            >
              <UserDefault />
            </Stack>
          )}
          <Stack>
            {(name || companyName) && (
              <Tooltip
                title={`${name} ${companyName ? `• ${companyName}` : ""}`}
              >
                <Typography
                  className="ellipsis-one-line"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19px",
                    wordBreak: "break-all",
                    color: currentPageFontColor || secondaryFontColor,
                  }}
                >
                  {name} {companyName ? `• ${companyName}` : ""}
                </Typography>
              </Tooltip>
            )}
            <Tooltip title={session?.title}>
              <Typography
                className="ellipsis-two-line"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400 !important",
                  color: currentPageFontColor || secondaryFontColor,
                  lineHeight: "21px",
                  wordBreak: "break-all",
                }}
              >
                {session?.title}
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>
      </Grid>
    );
  };
  const getShuffledOptions = (array: any) => {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }

    return shuffledArray;
  };

  const sumAllOptions = (
    surveyOptionFields: any,
    currentOption: any,
    questionId: string,
    currentValue: number
  ) => {
    let sumOptions = 0;
    surveyOptionFields?.forEach((option: any) => {
      let optionValue = 0;
      if (currentOption?.id === option?.id)
        optionValue = Number(currentValue) || 0;
      else optionValue = Number(values?.[`${questionId}_${option?.id}`]) || 0;
      sumOptions += optionValue;
    });
    return sumOptions;
  };

  const subtractExcessFromOthers = (
    surveyOptionFields: any,
    currentOption: any,
    questionId: string,
    excess: number
  ) => {
    const otherOptions = surveyOptionFields?.filter(
      (option: any) =>
        option?.id !== currentOption?.id &&
        Number(values?.[`${questionId}_${option?.id}`])
    );

    otherOptions.forEach((option: any) => {
      const newValue = Math.max(
        0,
        Number(values?.[`${questionId}_${option?.id}`]) -
          excess / otherOptions.length
      );
      const roundedValue = Number(newValue.toFixed(2));
      setValue(`${questionId}_${option?.id}`, roundedValue);
    });
  };

  const setZeroToOthersOptions = (
    surveyOptionFields: any,
    currentOption: any,
    questionId: string
  ) => {
    const otherOptions =
      surveyOptionFields?.filter(
        (option: any) => option?.id !== currentOption?.id
      ) || [];
    if (otherOptions) {
      otherOptions.forEach((option: any) => {
        setValue(`${questionId}_${option?.id}`, 0);
      });
    }
  };

  const handleConstantPer = (
    questionId: string,
    optionId: string,
    surveyOptionFields: any,
    currentOption: any,
    value: string
  ) => {
    const numValue = Math.min(100, Math.max(0, Number(value)));
    setValue(`${optionId}`, value?.toString()?.trim() === "" ? "" : numValue);

    // const totalSum = sumAllOptions(
    //   surveyOptionFields,
    //   currentOption,
    //   questionId,
    //   numValue
    // );
    // console.log("totalSum",totalSum,numValue)
    // if(totalSum > 100) {
    //   setError(optionId, {
    //     type: 'manual',
    //     message: 'Percentage should not be greater than 100',
    //   });
    // }
    // if (totalSum > 100 && numValue === 100) {
    //   setZeroToOthersOptions(surveyOptionFields, currentOption, questionId);
    // } else if (totalSum > 100) {
    //   const excess = totalSum - 100;
    //   subtractExcessFromOthers(
    //     surveyOptionFields,
    //     currentOption,
    //     questionId,
    //     excess
    //   );
    // }
  };

  const handleResetPercentage = (
    questionId: string,
    surveyOptionFields: any
  ) => {
    surveyOptionFields?.forEach((option: any) => {
      setValue(`${questionId}_${option?.id}`, 0);
    });
  };

  const onDragEnd = (result: any, id: string) => {
    if (!result.destination) {
      return;
    }

    const optionFields = values?.[id];

    const sourcePageIndex = result.source.index;
    const sourcePage = optionFields?.[sourcePageIndex];
    const updatedList = optionFields;
    const destinationPageIndex = result.destination.index;

    if (sourcePageIndex === destinationPageIndex) {
      return;
    }

    updatedList.splice(result.source.index, 1);
    updatedList.splice(result.destination.index, 0, sourcePage);
    setValue(id, updatedList);
  };

  const createHandleDragEnd = (id: string) => {
    return (result: any) => {
      if (!result.destination) {
        return;
      }
      onDragEnd(result, id);
    };
  };

  const renderMobileRadioButtonUI=(id:any,option:any,radioOptions:any[],index:number)=> (
      <Stack
        id={`${id}_${option?.id}`}
        key={index}
        sx={{
          width: innerWidth < 1500 ? "100%" : "auto",
          mt: "10px",
          p: "9px",
          borderRadius: innerWidth < 768 ? "4px" : "6px",
          flexDirection: "column",
          border: innerWidth < 768 ? `1px solid ${currentPagePrimaryBgColor || primaryBackgroundColor}` : "",
          "&:hover": { backgroundColor: `${currentPagePrimaryBgColor || primaryBackgroundColor}`, "& > .MuiTypography-root": {backgroundColor: '#14161a'} },
          "& fieldset": {
            width: innerWidth < 1500 ? "auto" : "100%",
          },
        }}
      >
        <Typography
          id="event-name-input"
          sx={{
              color: `${
                errors?.[`${id}_${option?.id}`]
                  ? "#FF5630"
                  : currentPageFontColor || secondaryFontColor
              } !important`,
            fontSize: 12,
            fontFamily: "Poppins-500",
            lineHeight: "16px",
            minWidth: innerWidth < 768 ? "100%" : (innerWidth < 1500 ? "276px" : "276px"),
            maxWidth: innerWidth < 768 ? "100%" : (innerWidth < 1500 ? "276px" : "276px"),
            mr: "5px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            backgroundColor: innerWidth < 768 ? `${currentPagePrimaryBgColor || primaryBackgroundColor}` : 'transparent',
            borderRadius: innerWidth < 768 ? "3px" : "0px",
            px: innerWidth < 768 ? "8px" : "0px",
            py: innerWidth < 768 ? "4px" : "0px",
            mb: innerWidth < 768 ? "12px" : "0px",
          }}
        >
          {option?.optionName}
        </Typography>

        <PRadioGroup
          hideHelperText
          sx={{
            ...styles,
            // "& .MuiFormHelperText-root": { ml: "1px" },
            "& .MuiRadio-root.Mui-checked": {
              color: `${
                currentPageFontColor || secondaryFontColor
              } !important`,
            },
            "& .MuiFormControlLabel-label": {
              color: `${
                currentPageFontColor || secondaryFontColor
              } !important`,
              fontSize: "12px !important",
              fontFamily: "Poppins-500 !important",
              ml: '7px',
              lineHeight: '16px'
              // lineHeight:
              //   index === 0
              //     ? "16px !important"
              //     : "0px !important",
              // visibility: index === 0 ? "visible" : "hidden",
              // minWidth: "135px",
              // maxWidth: "135px",
              // textAlign: "center",
              // overflow: "hidden",
              // textOverflow: "ellipsis",
              // display: "-webkit-box",
              // WebkitBoxOrient: "vertical",
              // WebkitLineClamp: 1,
            },
            "& .MuiRadio-root": {
              color: `${
                currentPageFontColor || secondaryFontColor
              } !important`,
              p: "0px !important",
              // pb: "3px !important",
            },
            // "&.MuiFormGroup-root": {
            //   flexDirection: "row !important",
            //   minWidth: innerWidth < 1500 ? "330px" : "auto",
            //   maxWidth: innerWidth < 1500 ? "500px" : "auto",
            //   flexWrap: "nowrap",
            //   justifyContent: "space-between",
            // },
            "& .MuiFormControlLabel-root": {
              ml: "0px !important",
              mb: '6px'
            },
          }}
          name={`${id}_${option?.id}`}
          options={radioOptions}
        />
      </Stack>
    )

  const renderDesktopRadioButtonUI=(id:any,option:any,radioOptions:any[],index:number)=> (
    <Stack
    id={`${id}_${option?.id}`}
    key={index}
    sx={{
      // width: innerWidth < 1500 ? "calc(100vw - 690px)" : "auto",
      width:  "calc(100% - 0px)" ,
      mt: "10px",
      p: "9px",
      borderRadius: "4px",
      flexDirection: "row",
      backgroundColor: index % 2 === 0 ? null : `${currentPagePrimaryBgColor || primaryBackgroundColor}`,
      alignItems: index === 0 ? "end" : "center",
      "&:hover": { backgroundColor: `${currentPagePrimaryBgColor || primaryBackgroundColor}` },
      "& fieldset": {
        // width: innerWidth < 1500 ? "auto" : "100%",
        width:  "auto" ,
      },
      justifyContent:"space-between"
    }}
  >
    <Typography
      id="event-name-input"
      sx={{
        color: `${
          errors?.[`${id}_${option?.id}`]
            ? "#FF5630"
            : currentPageFontColor || secondaryFontColor
        } !important`,
        fontSize: 12,
        fontFamily: "Poppins-500",
        lineHeight: "16px",
        // minWidth: innerWidth < 1500 ? "276px" : "276px",
        // maxWidth: innerWidth < 1500 ? "276px" : "276px",
        // minWidth: innerWidth < 1500 ? "505px" : "505px",
        // maxWidth: innerWidth < 1500 ? "calc(100% - 355px)" : "calc(100% - 355px)",
        maxWidth:  "calc(100% - 355px)",
        mb: index === 0 ? "7px" : "0px",
        mr: "10px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
      }}
    >
      {option?.optionName}
    </Typography>

    <PRadioGroup
      hideHelperText
      isRadioArray
      row
      // spacing={0.5}
      name={`${id}_${option?.id}`}
      options={radioOptions}
      sx={{
        ...styles,
        "& .MuiFormHelperText-root": { ml: "1px" },
        "& .MuiRadio-root.Mui-checked": {
          color: `${
            currentPageFontColor || secondaryFontColor
          } !important`,
        },
        "& .MuiFormControlLabel-label": {
          color: `${
            currentPageFontColor || secondaryFontColor
          } !important`,
          fontSize: "15px !important",
          fontFamily: "Poppins-600 !important",
          lineHeight:
            index === 0
              ? "16px !important"
              : "0px !important",
          visibility: index === 0 ? "visible" : "hidden",
          mb: index === 0 ? "8px" : "0px",
          // minWidth: "135px",
          // maxWidth: "135px",
          minWidth: "50px",
          maxWidth: "50px",
          textAlign: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
        },
        "& .MuiRadio-root": {
          color: `${
            currentPageFontColor || secondaryFontColor
          } !important`,
          // p: "5px !important",
          // // pb: "3px !important",
        },
        "&.MuiFormGroup-root": {
          flexDirection: "row !important",
          // minWidth: innerWidth < 1500 ? "330px" : "auto",
          // maxWidth: innerWidth < 1500 ? "500px" : "auto",
          flexWrap: "nowrap",
          // justifyContent: "space-between",
          columnGap:"20px",
          // gap:"0px !important"
        },
        "& .MuiFormControlLabel-root": {
          // ml: "-6px !important",
          mr: "0px !important",
          // flexDirection: "column-reverse",
          // alignItems:  "center",
          // justifyContent: "space-between",

        },
      }}
    />
  </Stack>
  );

  const surveyQuestion = useCallback(
    (
      question: SurveyQuestionsForm,
      index: number,
      totalQuestionLength: number
    ) => {
      let radioOptions: any = [];
      let sliderMarks: any = [];
      let optionValues: any = [];
      let options: Options[] = [];

      if (question) {
        const {
          fieldType,
          id,
          fieldName,
          surveyOptionFields,
          isRequired,
          surveyFlow,
          surveyFlowAction,
          eventQuestionPageId,
        } = question;
        const currentQuestion: any = checkVisibility(
          surveyFlow,
          id,
          fieldName,
          surveyFlowAction,
          eventQuestionPageId
        );
        const questionAction = currentQuestion?.currentStatus;
        const currentQuestionVisibility =
          questionAction === "hide" || questionAction === "nullValue"
            ? false
            : true;

        switch (fieldType) {
          case SurveyItemType.DROPDOWN:
            resetFieldValue(currentQuestionVisibility, id);
            radioOptions = [];
            surveyOptionFields.forEach((option: any) =>
              radioOptions.push({
                label: option.optionName,
                value: option.optionName,
              })
            );
            return (
              currentQuestionVisibility && (
                <>
                  {displayRadioQuestion(
                    fieldName,
                    id,
                    radioOptions,
                    isRequired
                  )}
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case SurveyItemType.Rank:
            return (
              <>
                <Typography
                  id="event-name-input"
                  sx={{
                    color: currentPageFontColor || secondaryFontColor,
                    fontSize: 14,
                    fontFamily: "Poppins-500",
                    lineHeight: "18px",
                    mb: "16px",
                  }}
                >
                  {getFieldLabel(isRequired, fieldName)}
                </Typography>
                <DragDropContext onDragEnd={createHandleDragEnd(id)}>
                  <Droppable droppableId="Option">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ width: "100%", zIndex: 99999 }}
                      >
                        {values?.[id]?.map((option: any, index: number) => (
                          <Draggable
                            key={option?.id}
                            draggableId={`option-${option?.id}`}
                            index={index}
                          >
                            {(dragProvided) => (
                               <Stack
                              //  sx={{ width: "11px" }}
                               {...dragProvided.dragHandleProps}
                             >
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                key={option?.id}
                                marginBottom="10px"
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                sx={{ gap: "10px" }}
                              >
                                  <DragAndDropIcon
                                    style={{
                                      width: "11px",
                                      height: "25px",
                                      fill:
                                        currentPageFontColor ||
                                        secondaryFontColor,
                                    }}
                                  />
                                <Typography
                                  id="event-name-input"
                                  sx={{
                                    color:
                                      currentPageFontColor ||
                                      secondaryFontColor,
                                    fontSize: 14,
                                    fontFamily: "Poppins-500",
                                    border: `1px solid ${fieldBorderColor}`,
                                    width: "100%",
                                    borderRadius: "5px",
                                    textAlign: "center",
                                    height: "43px",
                                    lineHeight: "43px",
                                    px: "12px",
                                  }}
                                  className="ellipsis-one-line"
                                >
                                  {option?.value}
                                </Typography>
                              </Stack>
                              </Stack>

                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {displayDivider(index, totalQuestionLength)}
              </>
              // )
            );

          case SurveyItemType.RADIO_BOX_ARRAY:
            sliderMarks = [];
            radioOptions = [];
            optionValues = surveyOptionFields[0]?.optionValues;
            surveyOptionFields &&
              optionValues?.forEach((option: any, index: number) => {
                radioOptions.push({
                  label: option.optionName,
                  value: option.optionName,
                });
              });
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        mb: "16px",
                      }}
                    >
                      {getFieldLabel(isRequired, fieldName)}
                    </Typography>
                    {surveyOptionFields?.map((option: any, index: number) => 
                      !isMobileScreen ? 
                        renderMobileRadioButtonUI(id,option,radioOptions,index)
                      : renderDesktopRadioButtonUI(id,option,radioOptions,index)
                    )}
                    {errors && errors[id] && (
                      <Typography
                        id="event-name-input"
                        gutterBottom
                        sx={{
                          "&.MuiTypography-root": {
                            color: "#FF5630",
                            fontSize: "0.75rem",
                            fontFamily: "Poppins-400",
                            ml: "1px",
                            mt: "8px",
                          },
                        }}
                      >
                        {String(errors?.[id]?.message) || "Required Field"}
                      </Typography>
                    )}
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case SurveyItemType.CONSTANT_SUM_PERCENTAGE:
            radioOptions = [];
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        id="event-name-input"
                        sx={{
                          color: currentPageFontColor || secondaryFontColor,
                          fontSize: 14,
                          fontFamily: "Poppins-500",
                          lineHeight: "18px",
                          mb: "16px",
                        }}
                      >
                        {getFieldLabel(isRequired, fieldName)}
                      </Typography>
                      <Button
                        sx={{
                          height: "35px",
                          color: currentPageFontColor || secondaryFontColor,
                          borderRadius: "5px",
                          fontSize: "12px",
                          backgroundColor: headerColor,
                          padding: "0px 8px 0px 0px",
                          "&:hover": {
                            backgroundColor: headerColor,
                            opacity: 0.9,
                          },
                        }}
                        onClick={() =>
                          handleResetPercentage(id, surveyOptionFields)
                        }
                      >
                        Reset
                      </Button>
                    </Stack>
                    {surveyOptionFields?.map((option, index) => (
                      <>
                        <Stack key={index}>
                          <Typography
                            id="event-name-input"
                            sx={{
                              color: `${
                                errors?.[id] &&
                                requiredConstantPerQuestionIds?.[id]?.[
                                  `${id}_${option?.id}`
                                ]
                                  ? "#FF5630"
                                  : currentPageFontColor || secondaryFontColor
                              } !important`,
                              fontSize: 14,
                              fontFamily: "Poppins-500",
                              lineHeight: "18px",
                              mb: "16px",
                            }}
                          >
                            {getFieldLabel(false, option?.optionName)}
                          </Typography>

                          {
                            <Box>
                              <Grid container spacing={1} alignItems="center">
                                <Grid item xs>
                                  <Grid item xs>
                                    <Stack
                                      sx={{
                                        backgroundColor:
                                          `${currentPagePrimaryBgColor || primaryBackgroundColor}`,
                                        height: "64px",
                                        borderRadius: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                        px: "15px",
                                        pt: "7px",
                                      }}
                                    >
                                      <PSlider
                                        name={`${id}_${option?.id}`}
                                        onChange={(e: any) =>
                                          handleConstantPer(
                                            id,
                                            `${id}_${option?.id}`,
                                            surveyOptionFields,
                                            option,
                                            e?.target?.value!
                                          )
                                        }
                                        size="medium"
                                        min={0}
                                        max={100}
                                        marks={[
                                          { label: "0", value: 0 },
                                          { label: "100", value: 100 },
                                        ]}
                                        aria-labelledby="size-input-slider"
                                        valueLabelDisplay="auto"
                                        sx={{
                                          "& .MuiFormHelperText-root": {
                                            ml: "1px",
                                          },
                                          "&.MuiSlider-root": {
                                            color: `${
                                              currentPageFontColor ||
                                              secondaryFontColor
                                            } !important`,
                                            width: "98.5% !important",
                                            marginX: "5px",
                                          },
                                          "& .MuiSlider-valueLabel": {
                                            display: "none !important",
                                          },
                                          "& .MuiSlider-markLabel": {
                                            color: isLevnovoWebsiteTemplate
                                              ? "#ffffff"
                                              : `${
                                                  currentPageFontColor ||
                                                  secondaryFontColor
                                                } !important`,
                                            textTransform: "capitalize",
                                            fontSize: 13,
                                            fontFamily: "poppins-500",
                                            opacity: isLevnovoWebsiteTemplate
                                              ? "100%"
                                              : "70%",
                                          },
                                          "& .MuiSlider-markLabel:first-child":
                                            {
                                              marginTop: "10px !important",
                                            },
                                          ".MuiSlider-rail": {
                                            height: "6px !important",
                                            backgroundColor: `${
                                              currentPageFontColor ||
                                              secondaryFontColor
                                            } !important`,
                                            opacity: "50%",
                                          },
                                          ".MuiSlider-track": {
                                            height: "6px !important",
                                            backgroundColor: `${
                                              currentPageFontColor ||
                                              secondaryFontColor
                                            } !important`,
                                            border: "0px !important",
                                          },
                                          ".MuiSlider-mark": {
                                            backgroundColor: "transparent",
                                          },
                                          ".MuiSlider-thumb": {
                                            backgroundColor: `${
                                              currentPageFontColor ||
                                              secondaryFontColor
                                            } !important`,
                                            border: 3,
                                            borderColor: `${
                                              currentPageFontColor ||
                                              secondaryFontColor
                                            } !important`,
                                            width: "18px !important",
                                            height: "18px !important",
                                          },
                                          ".MuiSlider-markLabel[data-index='0']":
                                            {
                                              transform:
                                                "translateX(0%) !important",
                                            },
                                          [`.MuiSlider-markLabel[data-index='${
                                            surveyOptionFields.length - 1
                                          }']`]: {
                                            transform:
                                              "translateX(-98%) !important",
                                          },
                                        }}
                                      />
                                    </Stack>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <RHFTextField
                                    name={`${id}_${option?.id}`}
                                    onWheel={(e) => e.preventDefault()}
                                    onChange={(e) =>
                                      handleConstantPer(
                                        id,
                                        `${id}_${option?.id}`,
                                        surveyOptionFields,
                                        option,
                                        e.target.value
                                      )
                                    }
                                    size="small"
                                    inputProps={{
                                      step: 100,
                                      min: 0,
                                      max: 100,
                                      type: "number",
                                      "aria-labelledby": "size-input-slider",
                                    }}
                                    sx={{
                                      color:
                                        currentPageFontColor ||
                                        secondaryFontColor,
                                      fontSize: 16,
                                      fontFamily: "Poppins-500",
                                      lineHeight: "21px",
                                      ...styles,
                                      "& .MuiOutlinedInput-root input::placeholder":
                                        {
                                          color:
                                            currentPageFontColor ||
                                            secondaryFontColor,
                                          opacity: 1,
                                        },
                                      "& fieldset": {
                                        border:
                                          errors[id]?.type ===
                                            "other-field-empty" &&
                                          "1px solid #FF5630 !important",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        height: "64px",
                                        padding: "10px",
                                        width: "50px",
                                      },
                                      "& .MuiOutlinedInput-input": {
                                        textAlign: "center",
                                        fontSize: "14px",
                                        fontFamily: "Poppins-500",
                                        fontWeight: 500,
                                        color:
                                          currentPageFontColor ||
                                          secondaryFontColor,
                                        lineHeight: "18px",
                                        padding: "0px",
                                      },
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          }
                        </Stack>
                        {displayDivider(index, totalQuestionLength)}
                      </>
                    ))}
                    {errors && errors[id] && (
                      <Typography
                        id="event-name-input"
                        gutterBottom
                        sx={{
                          "&.MuiTypography-root": {
                            color: "#FF5630",
                            fontSize: "0.75rem",
                            fontFamily: "Poppins-400",
                            ml: "1px",
                            mt: "8px",
                          },
                        }}
                      >
                        {String(errors?.[id]?.message) || "Required Field"}
                      </Typography>
                    )}
                  </Box>
                  {/* {displayDivider(index, totalQuestionLength)} */}
                </>
              )
            );
          case SurveyItemType.Day:
          case SurveyItemType.Room:
          case SurveyItemType.Session:
            if (!currentQuestionVisibility && values[id]?.length !== 0) {
              setValue(id, []);
            }
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, fieldName)}
                    </Typography>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{ flexWrap: "wrap" }}
                    >
                      {surveyOptionFields?.map(
                        ({ room, session, day }: any, index: number) => {
                          if (fieldType === SurveyItemType.Room && room)
                            return renderRoomOptions(room, id);
                          if (fieldType === SurveyItemType.Session && session)
                            return renderSessionOptions(session, id);
                          if (fieldType === SurveyItemType.Day && day)
                            return renderDayOptions(day, id, index);
                          return null;
                        }
                      )}
                    </Grid>
                    {errors && errors[id] && (
                          <Typography
                            id="event-name-input"
                            gutterBottom
                            sx={{
                              "&.MuiTypography-root": {
                                color: "#FF5630",
                                fontSize: "0.75rem",
                                fontFamily: "Poppins-400",
                                ml: "1px",
                                mt: "8px",
                                mb:"0px !important"
                              },
                            }}
                          >
                            {String(errors?.[id]?.message) || "Required Field"}
                          </Typography>
                        )}
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case SurveyItemType.SLIDER:
            sliderMarks = [];
            if (
              !currentQuestionVisibility &&
              values[id] !== surveyOptionFields[0]?.ordering
            ) {
              setValue(id, surveyOptionFields[0]?.ordering);
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            surveyOptionFields &&
              surveyOptionFields
                ?.sort((a, b) => a?.ordering - b?.ordering)
                ?.forEach((option: any, index: number) => {
                  sliderMarks.push({
                    value: option?.ordering,
                    label: option?.optionName.toString(),
                  });
                });
            return (
              currentQuestionVisibility && (
                <>
                  <Box
                    sx={{
                      "@media (max-width: 768px)": {
                        px: "6px",
                      },
                    }}
                    //px='5px'
                  >
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, fieldName)}
                    </Typography>
                    <Stack
                      sx={{
                        backgroundColor: `${currentPagePrimaryBgColor || primaryBackgroundColor}`,
                        height: "64px",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        px: "15px",
                        pt: "7px",
                      }}
                    >
                      <PSlider
                        name={id}
                        size="medium"
                        min={surveyOptionFields[0]?.ordering}
                        max={
                          surveyOptionFields[surveyOptionFields.length - 1]
                            ?.ordering
                        }
                        marks={sliderMarks}
                        aria-labelledby="size-input-slider"
                        valueLabelDisplay="auto"
                        sx={{
                          "& .MuiFormHelperText-root": { ml: "1px" },
                          "&.MuiSlider-root": {
                            color: `${
                              currentPageFontColor || secondaryFontColor
                            } !important`,
                            width: "98.5% !important",
                            marginX: "5px",
                          },
                          "& .MuiSlider-valueLabel": {
                            display: "none !important",
                          },
                          "& .MuiSlider-markLabel": {
                            color: isLevnovoWebsiteTemplate
                              ? "#ffffff"
                              : `${
                                  currentPageFontColor || secondaryFontColor
                                } !important`,
                            textTransform: "capitalize",
                            fontSize: 13,
                            fontFamily: "poppins-500",
                            opacity: isLevnovoWebsiteTemplate ? "100%" : "70%",
                          },
                          "& .MuiSlider-markLabel:first-child": {
                            marginTop: "10px !important",
                          },
                          ".MuiSlider-rail": {
                            height: "6px !important",
                            backgroundColor: `${
                              currentPageFontColor || secondaryFontColor
                            } !important`,
                            opacity: "50%",
                          },
                          ".MuiSlider-track": {
                            height: "6px !important",
                            backgroundColor: `${
                              currentPageFontColor || secondaryFontColor
                            } !important`,
                            border: "0px !important",
                          },
                          ".MuiSlider-mark": {
                            backgroundColor: "transparent",
                          },
                          ".MuiSlider-thumb": {
                            backgroundColor: `${
                              currentPageFontColor || secondaryFontColor
                            } !important`,
                            border: 3,
                            borderColor: `${
                              currentPageFontColor || secondaryFontColor
                            } !important`,
                            width: "18px !important",
                            height: "18px !important",
                          },
                          ".MuiSlider-markLabel[data-index='0']": {
                            transform: "translateX(0%) !important",
                          },
                          [`.MuiSlider-markLabel[data-index='${
                            surveyOptionFields.length - 1
                          }']`]: {
                            transform: "translateX(-98%) !important",
                          },
                        }}
                      />
                    </Stack>
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case SurveyItemType.SLIDER_ARRAY:
            sliderMarks = [];
            optionValues = surveyOptionFields[0]?.optionValues;
            if (!currentQuestionVisibility) {
              surveyOptionFields
                ?.sort((a, b) => a?.ordering - b?.ordering)
                ?.forEach((option, index) => {
                  if (
                    values[`${id}_${option?.id}`] !==
                    option?.optionValues?.[0]?.ordering
                  ) {
                    setValue(
                      `${id}_${option?.optionName}`,
                      option?.optionValues[0]?.ordering
                    );
                  }
                });
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            surveyOptionFields &&
              surveyOptionFields[0]?.optionValues?.forEach(
                (option: any, index: number) => {
                  sliderMarks.push({
                    value: option?.ordering,
                    label: option?.optionName.toString(),
                  });
                }
              );
            return (
              currentQuestionVisibility && (
                <>
                  <Box
                    sx={{
                      "@media (max-width: 768px)": {
                        px: "6px",
                      },
                    }}
                    //px='5px'
                  >
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, fieldName)}
                    </Typography>
                    {surveyOptionFields?.map((option, index) => (
                      <Stack key={index} sx={{ mt: "10px" }}>
                        <Typography
                          id="event-name-input"
                          sx={{
                            color: currentPageFontColor || secondaryFontColor,
                            fontSize: 14,
                            fontFamily: "Poppins-500",
                            lineHeight: "18px",
                            mb: "5px",
                          }}
                        >
                          {option?.optionName}
                        </Typography>
                        <Stack
                          sx={{
                            backgroundColor: `${currentPagePrimaryBgColor || primaryBackgroundColor}`,
                            height: "64px",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            px: "15px",
                            pt: "7px",
                            flex: 1,
                          }}
                        >
                          <PSlider
                            name={`${id}_${option?.id}`}
                            size="medium"
                            min={optionValues?.[0]?.ordering}
                            max={
                              optionValues[optionValues?.length - 1]?.ordering
                            }
                            marks={sliderMarks}
                            aria-labelledby="size-input-slider"
                            valueLabelDisplay="auto"
                            sx={{
                              "& .MuiFormHelperText-root": { ml: "1px" },
                              "&.MuiSlider-root": {
                                color: `${
                                  currentPageFontColor || secondaryFontColor
                                } !important`,
                                width: "98.5% !important",
                                marginX: "5px",
                              },
                              "& .MuiSlider-valueLabel": {
                                display: "none !important",
                              },
                              "& .MuiSlider-markLabel": {
                                color: isLevnovoWebsiteTemplate
                                  ? "#ffffff"
                                  : `${
                                      currentPageFontColor || secondaryFontColor
                                    } !important`,
                                textTransform: "capitalize",
                                fontSize: 13,
                                fontFamily: "poppins-500",
                                opacity: isLevnovoWebsiteTemplate
                                  ? "100%"
                                  : "70%",
                              },
                              "& .MuiSlider-markLabel:first-child": {
                                marginTop: "10px !important",
                              },
                              ".MuiSlider-rail": {
                                height: "6px !important",
                                backgroundColor: `${
                                  currentPageFontColor || secondaryFontColor
                                } !important`,
                                opacity: "50%",
                              },
                              ".MuiSlider-track": {
                                height: "6px !important",
                                backgroundColor: `${
                                  currentPageFontColor || secondaryFontColor
                                } !important`,
                                border: "0px !important",
                              },
                              ".MuiSlider-mark": {
                                backgroundColor: "transparent",
                              },
                              ".MuiSlider-thumb": {
                                backgroundColor: `${
                                  currentPageFontColor || secondaryFontColor
                                } !important`,
                                border: 3,
                                borderColor: `${
                                  currentPageFontColor || secondaryFontColor
                                } !important`,
                                width: "18px !important",
                                height: "18px !important",
                              },
                              ".MuiSlider-markLabel[data-index='0']": {
                                transform: "translateX(0%) !important",
                              },
                              [`.MuiSlider-markLabel[data-index='${
                                optionValues.length - 1
                              }']`]: {
                                transform: "translateX(-98%) !important",
                              },
                            }}
                          />
                        </Stack>
                      </Stack>
                    ))}
                  </Box>
                </>
              )
            );

          case SurveyItemType.MULTIPLE:
            resetFieldValue(currentQuestionVisibility, id);
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, fieldName)}
                    </Typography>
                    <div style={{ position: "relative" }}>
                      <RHFSelect
                        name={id}
                        // label={getFieldLabel(isRequired, fieldName)}
                        key={id}
                        sx={{
                          "& .MuiFormHelperText-root": { ml: "1px" },
                          "& .MuiInputLabel-root": {
                            color: `${
                              currentPageFontColor || secondaryFontColor
                            } !important`,
                          },
                          "& .MuiSelect-select": {
                            height: "43px !important",
                            display: "flex",
                            alignItems: "center",
                          },
                          "& .MuiSelect-icon": {
                            color: `${
                              currentPageFontColor || secondaryFontColor
                            } !important`,
                          },
                          "& .MuiFormControlLabel-label": {
                            fontSize: 16,
                            fontFamily: "Poppins-500",
                            lineHeight: "21px",
                          },
                          ...styles,
                        }}
                      >
                        {surveyOptionFields &&
                          surveyOptionFields?.map((option: any) => (
                            <MenuItem key={option.id} value={option.optionName}>
                              {option.optionName}
                            </MenuItem>
                          ))}
                      </RHFSelect>

                      {!values[id] && (
                        <Typography
                          id="event-name-input"
                          gutterBottom
                          sx={{
                            color: currentPageFontColor || secondaryFontColor,
                            position: "absolute",
                            top: "11px",
                            left: "12px",
                            fontFamily: "Poppins-400",
                            fontSize: "14px",
                          }}
                        >
                          Select an option
                        </Typography>
                      )}
                    </div>
                  </Box>
                  {/* {!values[id]?.toLowerCase()?.includes("other") && displayDivider(index, totalQuestionLength)} */}

                  {/* {values[id] && values[id]?.toLowerCase()?.includes("other") &&
                                    <>
                                        <RHFTextField
                                            sx={{
                                                color: currentPageFontColor || secondaryFontColor, fontSize: 16, fontFamily: 'Poppins-500', lineHeight: '21px', ...styles,
                                                "& .MuiOutlinedInput-root input::placeholder": {
                                                    color: currentPageFontColor || secondaryFontColor,
                                                    opacity: 1,
                                                },
                                                mt: "12px"

                                            }}

                                            onChange={(e) => {
                                                setValue(`otherId-${id}`, e.target.value);
                                            }}
                                            value={values[`otherId-${id}`]}
                                            name={`otherId-${id}`}
                                            placeholder={"Type here…"}
                                        />
                                        </>
                                    } */}
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case SurveyItemType.TERM_AND_CONDITION:
            if (!surveyOptionFields?.length) return;
            const isTermAndConditionRequired = checkTermFieldValidation(
              values[question?.id],
              question,
              isRequired
            );

            options = surveyOptionFields?.map((item) => ({
              label: item?.fieldName,
              value: item?.fieldName,
            }));
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        "&.MuiTypography-root": {
                          color: currentPageFontColor || secondaryFontColor,
                          fontSize: 14,
                          fontFamily: "Poppins-500",
                          lineHeight: "18px",
                          mb: "5px",
                        },
                      }}
                    >
                      {getFieldLabel(isRequired, "Terms & Conditions")}
                    </Typography>
                    <PMultiCheckbox
                      eachFieldValidation={values[id]}
                      isTermAndConditionRequired={
                        isTermAndConditionRequired && nextButtonClicked
                      }
                      name={id}
                      options={options}
                      sx={{
                        "& .MuiFormHelperText-root": { ml: "1px" },
                        "& .MuiFormControlLabel-label": {
                          color: currentPageFontColor || secondaryFontColor,
                          fontSize: 14,
                          fontFamily: "Poppins-500",
                          lineHeight: "21px",
                          "&:hover":{
                            textDecoration:"underline"
                          }
                        },
                        "& .MuiCheckbox-root.Mui-checked": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                        },
                        "& .MuiCheckbox-root": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                          p: "0px !important",
                          pr: "5px !important",
                          // pt: "5px !important"
                        },
                      }}
                    />

                    {isTermAndConditionRequired === false && (
                      <Typography
                        id="event-name-input"
                        gutterBottom
                        sx={{
                          "&.MuiTypography-root": {
                            color: "#FF5630",
                            fontSize: "0.75rem",
                            fontWeight: 400,
                            ml: "1px",
                            mt: "8px",
                          },
                        }}
                      >
                        Required Field
                      </Typography>
                    )}
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case SurveyItemType.CHECKBOX:
            if (!currentQuestionVisibility && values[id]?.length !== 0) {
              setValue(id, []);
            }
            options = surveyOptionFields?.map((item) => ({
              label: item?.optionName,
              value: item?.optionName,
            }));
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        "&.MuiTypography-root": {
                          color: currentPageFontColor || secondaryFontColor,
                          fontSize: 14,
                          fontFamily: "Poppins-500",
                          lineHeight: "18px",
                          mb: "5px",
                        },
                      }}
                    >
                      {getFieldLabel(isRequired, fieldName)}
                    </Typography>
                    <PMultiCheckbox
                      name={id}
                      options={options}
                      sx={{
                        "& .MuiFormHelperText-root": { ml: "1px" },
                        "& .MuiFormControlLabel-label": {
                          color: currentPageFontColor || secondaryFontColor,
                          fontSize: 14,
                          fontFamily: "Poppins-500",
                          lineHeight: "21px",
                          "&:hover":{
                            textDecoration:"underline"
                          }

                        },
                        "& .MuiCheckbox-root.Mui-checked": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                        },
                        "& .MuiCheckbox-root": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                          p: "0px !important",
                          pr: "5px !important",
                        },
                      }}
                    />
                  </Box>
                  {!values[id]?.some((item: string) =>
                    checkForOtherAndSpecifyText(item)
                  ) && displayDivider(index, totalQuestionLength)}

                  {values[id]?.some((item: string) =>
                    checkForOtherAndSpecifyText(item)
                  ) && (
                    <>
                      <RHFTextField
                        sx={{
                          color: currentPageFontColor || secondaryFontColor,
                          fontSize: 16,
                          fontFamily: "Poppins-500",
                          lineHeight: "21px",
                          ...styles,
                          "& .MuiOutlinedInput-root input::placeholder": {
                            color: currentPageFontColor || secondaryFontColor,
                            opacity: 1,
                          },
                          mt: "12px",
                          "& fieldset": {
                            border:
                              errors[id]?.type === "other-field-empty" &&
                              "1px solid #FF5630 !important",
                          },
                        }}
                        onChange={(e) => {
                          setValue(`otherId-${id}`, e.target.value);
                        }}
                        value={values[`otherId-${id}`]}
                        name={`otherId-${id}`}
                        placeholder={"Type here…"}
                      />
                      {displayDivider(index, totalQuestionLength)}
                    </>
                  )}
                </>
              )
            );

          case SurveyItemType.PHONE:
            const isPhoneFiledDirty = checkPhoneFieldValidation(
              values,
              question?.id,
              isRequired
            );
            resetFieldValue(currentQuestionVisibility, id);
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        "&.MuiTypography-root": {
                          color: currentPageFontColor || secondaryFontColor,
                          fontSize: 14,
                          fontFamily: "Poppins-500",
                          lineHeight: "18px",
                          mb: "5px",
                        },
                      }}
                    >
                      {getFieldLabel(isRequired, fieldName)}
                    </Typography>

                    <Stack sx={{ position: "relative" }}>
                      <FormControl component="fieldset">
                        <PhoneInput
                          country={"us"}
                          enableSearch
                          containerClass={classNames(
                            " w-full !h-[43px] !rounded-[4px] bg-transparent placeholder:text-[15px] text-[15px] text-white px-[0px]",
                            {
                              "spectre-input-new-bottom": index >= 4,
                              "spectre-input-new": index < 4,
                            }
                          )}
                          value={values[id]}
                          onChange={(e: any, country: any) =>
                            handlePhoneNumberChange(e, country, id)
                          }
                          inputProps={{
                            style: {
                              color: currentPageFontColor || secondaryFontColor,
                              fontSize: 16,
                              fontFamily: "Poppins-400",
                              lineHeight: "21px",
                              border: `1px solid ${
                                errors && errors?.[id]
                                  ? "red"
                                  : fieldBorderColor
                              }`,
                              ...(isFocused[id] && focusStyles),
                              borderRadius: "8px !important",
                            },
                          }}
                          onFocus={() => handleFocus(id)}
                          onBlur={() => handleBlur(id)}
                        />
                        {errors && errors[id] && (
                          <Typography
                            id="event-name-input"
                            gutterBottom
                            sx={{
                              "&.MuiTypography-root": {
                                color: "#FF5630",
                                fontSize: "0.75rem",
                                fontFamily: "Poppins-400",
                                ml: "1px",
                                mt: "8px",
                                mb:"0px !important"
                              },
                            }}
                          >
                            {String(errors?.[id]?.message) || "Required Field"}
                          </Typography>
                        )}
                      </FormControl>
                    </Stack>
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case SurveyItemType.TARGET_DEPARTMENT:
            resetFieldValue(currentQuestionVisibility, id);
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, fieldName)}
                    </Typography>

                    <PTargetDepartment
                      placeholder="Select an option"
                      name={id}
                      key={id}
                      sx={{
                        ...styles,
                        "& .MuiOutlinedInput-input": {
                          height: "10px",
                          cursor: "pointer",
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                        },
                        "& .MuiOutlinedInput-root input::placeholder": {
                          color: currentPageFontColor || secondaryFontColor,
                          opacity: 1,
                        },
                      }}
                    />
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case SurveyItemType.ORGANIZATION_SIZE:
            resetFieldValue(currentQuestionVisibility, id);
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, fieldName)}
                    </Typography>

                    <PHeadCountSelect
                      name={id}
                      key={id}
                      placeholder="Select an option"
                      sx={{
                        ...styles,
                        "& .MuiOutlinedInput-input": {
                          height: "10px",
                          cursor: "pointer",
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                        },
                        "& .MuiOutlinedInput-root input::placeholder": {
                          color: currentPageFontColor || secondaryFontColor,
                          opacity: 1,
                        },
                      }}
                    />
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case SurveyItemType.TECHNOLOGY_INVESTMENT:
            if (!currentQuestionVisibility && values[id]?.length !== 0) {
              setValue(id, []);
            }
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, fieldName)}
                    </Typography>

                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{ flexWrap: "wrap" }}
                    >
                      {surveyOptionFields.map((option, index) => {
                        const selectedOption = values?.[id]?.includes(
                          option?.optionName
                        );
                        return (
                          <Grid
                            item
                            key={index}
                            xs={12}
                            sm={6}
                            md={4}
                            lg={4}
                            xl={4}
                            onClick={() => handleSelectTechnology(option, id)}
                          >
                            <Stack
                              sx={{
                                height: "43px",
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                background: selectedOption
                                  ? currentPageHeaderColor || headerColor
                                  : currentPagePrimaryBgColor || primaryBackgroundColor,
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  color:  currentPageFontColor || secondaryFontColor,
                                  padding: "0px 8px",
                                  "&:hover":{
                                    background:
                                    currentPageHeaderColor || headerColor
                                  },
                                // border: selectedOption
                                //   ? "none"
                                //   : `1px solid ${currentPageFontColor}`,
                              }}
                            >
                              <Tooltip title={option?.optionName}  placement="top">
                                <div className="ellipsis-one-line" style={{maxWidth:"100%",fontSize:"14px"}}>
                                  {option?.optionName}
                                </div>
                              </Tooltip>
                            </Stack>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {errors && errors[id] && (
                          <Typography
                            id="event-name-input"
                            gutterBottom
                            sx={{
                              "&.MuiTypography-root": {
                                color: "#FF5630",
                                fontSize: "0.75rem",
                                fontFamily: "Poppins-400",
                                ml: "1px",
                                mt: "8px",
                              },
                            }}
                          >
                            {String(errors?.[id]?.message) || "Required Field"}
                          </Typography>
                        )}
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case SurveyItemType.TEXT:
          case SurveyItemType.JOBTITLE:
          case SurveyItemType.BUSINESS:
            resetFieldValue(currentQuestionVisibility, id);
            return (
              currentQuestionVisibility &&
              id === currentQuestion?.questionId && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, fieldName)}
                    </Typography>

                    <RHFTextField
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 16,
                        fontFamily: "Poppins-500",
                        lineHeight: "21px",
                        ...styles,
                        "& .MuiOutlinedInput-root input::placeholder": {
                          color: currentPageFontColor || secondaryFontColor,
                          opacity: 1,
                        },
                      }}
                      onChange={(e) => {
                        setValue(`${id}`, e.target.value);
                      }}
                      value={values[question?.id]}
                      name={id}
                      placeholder={"Type here…"}
                    />
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );
          default:
            return null;
        }
      }
      return true;
    },
    [
      values,
      errors,
      firstError,
      surveyQuestionsWithAnswers,
      selectedRoom,
      surveyPages,
      jobFunctions,
    ]
  );

  function convertObjectToString(payload: any) {
    Object.keys(payload).forEach((field) => {
      if (
        payload[field] ||
        typeof payload[field] === "number" ||
        typeof payload[field] === "boolean"
      ) {
        if (typeof payload[field] === "object")
          return convertObjectToString(payload[field]);
        payload[field] = `${payload[field]}`;
      } else delete payload[field];
      return true;
    });

    return payload;
  }

  function findMatchingFieldId(id: string, data: any, page: any) {
    if (data.hasOwnProperty(id)) {
      const fieldValues = data[id];

      let termAndConditionChecked = {};
      for (const field of page.surveyOptionFields) {
        if (fieldValues?.includes(field?.fieldName)) {
          termAndConditionChecked = {
            ...termAndConditionChecked,
            [field?.id]: true,
          };
        } else if (!field?.isRequired) {
          termAndConditionChecked = {
            ...termAndConditionChecked,
            [field?.id]: false,
          };
        }
      }
      return termAndConditionChecked;
    }
  }
  const verifyUnderReviewPage = () => {
    if (registrationUnderReview && isSurveySubmitted && !userExitedSurvey) {
      setSurveySubPage("underReviewAfterSurvey");
    } else if (!surveyPages?.length || surveyPages?.every((x)=>!x?.eventSurveyFields?.length) || isSurveySubmitted) {
      setSurveySubPage("attendeesListView");
    } else {
      setSurveySubPage("surveyForm");
    }
  };

  const submitSurveyForm = async (data: any) => {
    let termAndConditionFields = {};
    for (const page of termAndConditionAllPages) {
      const result = findMatchingFieldId(page.id, data, page);
      delete data[page.id];
      termAndConditionFields = { ...termAndConditionFields, ...result };
    }

    let payload = convertObjectToString({ ...data, ...termAndConditionFields });

    textSliderValue.forEach((item: any) => {
      const questionId = item?.id;
      const fieldValue = payload[questionId];

      const { surveyOptionFields } = item?.question;
      if (Array.isArray(surveyOptionFields)) {
        surveyOptionFields.forEach((option) => {
          if (option?.ordering?.toString() === fieldValue?.toString()) {
            payload[questionId] = option.optionName;
          }
        });
      }
    });

    Object.keys(payload).forEach((id: string) => {
      const formField =
        surveyQuestions &&
        surveyQuestions.find((field: SurveyQuestionsForm) => field?.id === id);

      if (formField) {
        if (
          formField?.fieldType === SurveyItemType.TARGET_DEPARTMENT ||
          formField?.fieldType === SurveyItemType.ORGANIZATION_SIZE
        ) {
          payload[formField.id] = data[id]?.name;
        }

        if (formField?.fieldType === SurveyItemType.CHECKBOX) {
          if (
            payload[formField?.id]?.some((item: string) =>
              checkForOtherAndSpecifyText(item)
            )
          ) {
            const checkboxOptions = payload[formField.id];

            const updatedOptions = checkboxOptions.map((option: string) =>
              option?.toLowerCase()?.includes("other")
                ? payload[`otherId-${formField?.id}`]
                : option
            );

            payload[formField.id] = updatedOptions?.filter(Boolean);
          }

          delete payload[`otherId-${formField?.id}`];
        }

        if (formField?.fieldType === SurveyItemType.DROPDOWN) {
          if (
            payload[formField?.id] &&
            checkForOtherAndSpecifyText(payload[formField?.id])
          ) {
            payload[formField.id] = payload[`otherId-${formField?.id}`];
          }

          delete payload[`otherId-${formField?.id}`];
        }

        if (formField?.fieldType === SurveyItemType.SLIDER_ARRAY) {
          const sldierOptionsAnswers: any[] = [];
          formField?.surveyOptionFields?.forEach(
            (sliderOption: any, index: number) => {
              const optionId = `${formField?.id}_${sliderOption?.id}`;
              const options = sliderOption?.optionValues;
              const selectedOption =
                payload[optionId] !== undefined && payload[optionId] !== null
                  ? options?.find(
                      (x: any) =>
                        x?.ordering?.toString() ===
                        payload[optionId]?.toString()
                    )
                  : null;
              if (selectedOption) {
                sldierOptionsAnswers?.push({
                  id: sliderOption?.id,
                  value: selectedOption?.optionName,
                });
              }
              delete payload[optionId];
            }
          );
          payload[formField?.id] = sldierOptionsAnswers;
        }

        if (formField?.fieldType === SurveyItemType.Rank) {
          const updatedRankList: any[] = payload[formField?.id] || [];

          payload[formField?.id] = updatedRankList?.map((item, index) => ({
            value: item?.value,
            order: index?.toString(),
          }));
        }

        if (formField?.fieldType === SurveyItemType.CONSTANT_SUM_PERCENTAGE) {
          const sldierOptionsAnswers: any[] = [];
          formField?.surveyOptionFields?.forEach((sliderOption: any) => {
            const optionId = `${formField?.id}_${sliderOption?.id}`;
            sldierOptionsAnswers?.push({
              name: sliderOption?.optionName,
              value: payload?.[optionId] || 0,
            });
            delete payload[optionId];
          });
          payload[formField?.id] = sldierOptionsAnswers;
        }

        if (formField?.fieldType === SurveyItemType.RADIO_BOX_ARRAY) {
          const radioArrayOptionsAnswers: any[] = [];
          formField?.surveyOptionFields?.forEach(
            (radioOption: any, index: number) => {
              const optionId = `${formField?.id}_${radioOption?.id}`;
              const options = radioOption?.optionValues;
              const selectedOption =
                payload[optionId] !== undefined && payload[optionId] !== null
                  ? options?.find(
                      (x: any) =>
                        x?.optionName?.toString() ===
                        payload[optionId]?.toString()
                    )
                  : null;
              if (selectedOption) {
                radioArrayOptionsAnswers?.push({
                  id: radioOption?.id,
                  value: selectedOption?.optionName,
                });
              }
              delete payload[optionId];
            }
          );
          payload[formField?.id] = radioArrayOptionsAnswers;
        }
      }
    });

    const submitSurvey = async () => modifySurveyAnswers(userId, payload);

    const exitSurveyApi = async () => {
      try {
        setExitSurveyLoading(true);
        await submitSurveyLater({
          progressStatus: RegistrationProgressType.SURVEY_FORM_EXITED,
        });
        setExitSurveyLoading(false);
      } catch (error) {
        setExitSurveyLoading(false);
      }
    };
    setSubmitLoading(true);
    if (userId !== "") {
      try {
        await submitSurvey();
        // localStorage.removeItem("surveyAnswers");
        const founExitSruveyAction = findQuestionByStatus(
          questionSenariosVisibility
        );
        if (founExitSruveyAction) await exitSurveyApi();
        onSurveyCompleted();
        setShowMatchUsers(true);
        verifyUnderReviewPage();
        // setSurveySubPage("attendeesListView")
        setSubmitLoading(false);
        setTextSliderValue([]);
        setIsSuveryorRegSkipable(false);
      } catch (error: any) {
        if (error.statusCode === 500 || error.statusCode === 400) {
          toast.error("Something went wrong. Please try again!");
        } else if (
          error?.response?.data?.statusCode === 400 ||
          error?.response?.data?.statusCode === 500
        ) {
          toast.error("Something went wrong. Please try again!");
        } else toast.error(error?.response?.data?.message || error?.message);
        setSubmitLoading(false);
        const pageIds: string[] = Object.keys(questionPerPage);
        setCurrentSurveyStep(pageIds?.length - 1);
      }
    }
  };

  const onSubmit = async (data: any) => {
    if (Object.keys(data).every((x) => data[x] === null)) {
      setShowMatchUsers(true);
      // setSurveySubPage("attendeesListView")
      verifyUnderReviewPage();
    } else {
      if (termAndConditionAllPages?.length) {
        const isValidTermAndCondition = getValidTermAndCondition();
        if (isValidTermAndCondition) {
          submitSurveyForm(data);
          sessionStorage.setItem("termAndCondition", "false");
        }
      } else {
        submitSurveyForm(data);
      }
    }
  };

  const onPartialSubmit = async (data: any,currentPageQuestionsIds:any[]) => {
      if (termAndConditionAllPages?.length) {
        const isValidTermAndCondition = getValidTermAndCondition();
        if (isValidTermAndCondition) {
          await submitPartialSurveyForm(data,currentPageQuestionsIds);
          // sessionStorage.setItem("termAndCondition", "false");
        }
      } else {
        
        await submitPartialSurveyForm(data,currentPageQuestionsIds);
      }
      await submitSurveyLater({progressStatus:  RegistrationProgressType.SURVEY_FORM_FILL_LATER});
  };

  const submitPartialSurveyForm = async (data: any,currentPageQuestionsIds:any[]) => {
    let termAndConditionFields = {};
    for (const page of termAndConditionAllPages) {
      const result = findMatchingFieldId(page.id, data, page);
      delete data[page.id];
      termAndConditionFields = { ...termAndConditionFields, ...result };
    }

    let payload = convertObjectToString({ ...data, ...termAndConditionFields });

    textSliderValue.forEach((item: any) => {
      const questionId = item?.id;
      const fieldValue = payload[questionId];

      const { surveyOptionFields } = item?.question;
      if (Array.isArray(surveyOptionFields)) {
        surveyOptionFields.forEach((option) => {
          if (option?.ordering?.toString() === fieldValue?.toString()) {
            payload[questionId] = option.optionName;
          }
        });
      }
    });

    Object.keys(payload).forEach((id: string) => {
      const formField =
        surveyQuestions &&
        surveyQuestions.find((field: SurveyQuestionsForm) => field?.id === id);

      if (formField) {
        if (
          formField?.fieldType === SurveyItemType.TARGET_DEPARTMENT ||
          formField?.fieldType === SurveyItemType.ORGANIZATION_SIZE
        ) {
          payload[formField.id] = data[id]?.name;
        }

        if (formField?.fieldType === SurveyItemType.CHECKBOX) {
          if (
            payload[formField?.id]?.some((item: string) =>
              checkForOtherAndSpecifyText(item)
            )
          ) {
            const checkboxOptions = payload[formField.id];

            const updatedOptions = checkboxOptions.map((option: string) =>
              option?.toLowerCase()?.includes("other")
                ? payload[`otherId-${formField?.id}`]
                : option
            );

            payload[formField.id] = updatedOptions?.filter(Boolean);
          }

          delete payload[`otherId-${formField?.id}`];
        }

        if (formField?.fieldType === SurveyItemType.DROPDOWN) {
          if (
            payload[formField?.id] &&
            checkForOtherAndSpecifyText(payload[formField?.id])
          ) {
            payload[formField.id] = payload[`otherId-${formField?.id}`];
          }

          delete payload[`otherId-${formField?.id}`];
        }

        if (formField?.fieldType === SurveyItemType.SLIDER_ARRAY) {
          const sldierOptionsAnswers: any[] = [];
          formField?.surveyOptionFields?.forEach(
            (sliderOption: any, index: number) => {
              const optionId = `${formField?.id}_${sliderOption?.id}`;
              const options = sliderOption?.optionValues;
              const selectedOption =
                payload[optionId] !== undefined && payload[optionId] !== null
                  ? options?.find(
                      (x: any) =>
                        x?.ordering?.toString() ===
                        payload[optionId]?.toString()
                    )
                  : null;
              if (selectedOption) {
                sldierOptionsAnswers?.push({
                  id: sliderOption?.id,
                  value: selectedOption?.optionName,
                });
              }
              delete payload[optionId];
            }
          );
          payload[formField?.id] = sldierOptionsAnswers;
        }

        if (formField?.fieldType === SurveyItemType.Rank) {
          const updatedRankList: any[] = payload[formField?.id] || [];

          payload[formField?.id] = updatedRankList?.map((item, index) => ({
            value: item?.value,
            order: index?.toString(),
          }));
        }

        if (formField?.fieldType === SurveyItemType.CONSTANT_SUM_PERCENTAGE) {
          const sldierOptionsAnswers: any[] = [];
          formField?.surveyOptionFields?.forEach((sliderOption: any) => {
            const optionId = `${formField?.id}_${sliderOption?.id}`;
            sldierOptionsAnswers?.push({
              name: sliderOption?.optionName,
              value: payload?.[optionId] || 0,
            });
            delete payload[optionId];
          });
          payload[formField?.id] = sldierOptionsAnswers;
        }

        if (formField?.fieldType === SurveyItemType.RADIO_BOX_ARRAY) {
          const radioArrayOptionsAnswers: any[] = [];
          formField?.surveyOptionFields?.forEach(
            (radioOption: any, index: number) => {
              const optionId = `${formField?.id}_${radioOption?.id}`;
              const options = radioOption?.optionValues;
              const selectedOption =
                payload[optionId] !== undefined && payload[optionId] !== null
                  ? options?.find(
                      (x: any) =>
                        x?.optionName?.toString() ===
                        payload[optionId]?.toString()
                    )
                  : null;
              if (selectedOption) {
                radioArrayOptionsAnswers?.push({
                  id: radioOption?.id,
                  value: selectedOption?.optionName,
                });
              }
              delete payload[optionId];
            }
          );
          payload[formField?.id] = radioArrayOptionsAnswers;
        }
      }
    });

    let trasnformedPayload :any={}
      currentPageQuestionsIds?.forEach((questionId: string) => {
        trasnformedPayload[questionId] = payload[questionId];
      });

    const submitSurvey = async () => modifySurveyAnswers(userId, trasnformedPayload,"partialSubmit");

    // setSubmitLoading(true);
    if (userId !== "") {
      try {
        await submitSurvey();
        // setSubmitLoading(false);
      } catch (error: any) {
        if (error.statusCode === 500 || error.statusCode === 400) {
          toast.error("Something went wrong. Please try again!");
        } else if (
          error?.response?.data?.statusCode === 400 ||
          error?.response?.data?.statusCode === 500
        ) {
          toast.error("Something went wrong. Please try again!");
        } else toast.error(error?.response?.data?.message || error?.message);
        setSubmitLoading(false);
        const pageIds: string[] = Object.keys(questionPerPage);
        setCurrentSurveyStep(pageIds?.length - 1);
      }
    }
  };

 

  const handlePrevPage = useCallback(() => {
    if (surveySubPage === "attendeesListView") {
      setMeetingAttendee({});
    } else if (surveySubPage === "meetingListView") {
      meetingListRef?.current?.handleBackToAttendeesList();
    }
    scrollToTop();
  }, [surveySubPage]);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // methods
  useEffect(() => {
    const atleastOneQuestionperPage: boolean[] = [];
    const allSliderFields: any[] = [];
    let termAndConditionAnswers: any[] = [];
    let defaultValue: any = {};
    if (surveyPages?.length && surveyQuestionsWithAnswers && surveyPages?.some((x)=> x?.eventSurveyFields?.length)) {
      setShowMatchUsers(false);

      surveyPages.forEach((item: Page) => {
        const { eventSurveyFields } = item;
        termAndConditionAnswers = [];
        if (eventSurveyFields?.length) {
          atleastOneQuestionperPage.push(true);
        } else atleastOneQuestionperPage.push(false);

            // set fields values
      const updatedSurveyQuestionsWithAnswers = surveyQuestionsWithAnswers.reduce(
        (accumulator: SurveyQuestionwithAnswer[], currentItem: any) => {
          const { id, userSurveyField, sourceId, answer, surveyOptionFieldId, answerValue } =
            currentItem;
          const { surveyField, surveyFieldId } = userSurveyField;
          const agendaTypes = [
            SurveyItemType.Day,
            SurveyItemType.Room,
            SurveyItemType.Session,
          ];
          if (
            surveyField.fieldType === SurveyItemType.CHECKBOX ||
            surveyField.fieldType === SurveyItemType.SLIDER_ARRAY ||
            surveyField.fieldType === SurveyItemType.RADIO_BOX_ARRAY ||
            surveyField.fieldType === SurveyItemType.CONSTANT_SUM_PERCENTAGE ||
            surveyField.fieldType === SurveyItemType.Rank ||
            agendaTypes.includes(surveyField.fieldType)
          ) {
            const existingItem = accumulator.find(
              accItem => accItem.userSurveyField.surveyFieldId === surveyFieldId,
            );
            if (existingItem) {
              if (Array.isArray(existingItem.answer)) {
                if (agendaTypes.includes(surveyField.fieldType)) {
                  existingItem.answer = [
                    ...existingItem.answer,
                    {
                      id: sourceId,
                      optionName: answer,
                    },
                  ];
                } else if (
                  surveyField.fieldType === SurveyItemType.SLIDER_ARRAY ||
                  surveyField.fieldType === SurveyItemType.RADIO_BOX_ARRAY
                ) {
                  existingItem.answer = [
                    ...existingItem.answer,
                    {
                      id: surveyOptionFieldId,
                      answer,
                    },
                  ];
                } else if (surveyField.fieldType === SurveyItemType.CONSTANT_SUM_PERCENTAGE) {
                  existingItem.answer = [
                    ...existingItem.answer,
                    {
                      id: surveyOptionFieldId,
                      answer: answerValue,
                    },
                  ];
                } else if (surveyField.fieldType === SurveyItemType.Rank) {
                  existingItem.answer = [
                    ...existingItem.answer,
                    {
                      id: surveyOptionFieldId,
                      answer,
                      answerValue,
                    },
                  ];
                } else {
                  existingItem.answer.push(answer);
                }
              }
            } else {
              accumulator.push({
                answer: agendaTypes.includes(surveyField.fieldType)
                  ? [{ id: sourceId, optionName: answer }]
                  : surveyField.fieldType === SurveyItemType.SLIDER_ARRAY ||
                      surveyField.fieldType === SurveyItemType.RADIO_BOX_ARRAY
                    ? [{ id: surveyOptionFieldId, answer }]
                    : surveyField.fieldType === SurveyItemType.CONSTANT_SUM_PERCENTAGE
                      ? [{ id: surveyOptionFieldId, answer: answerValue }]
                      : surveyField.fieldType === SurveyItemType.Rank
                        ? [{ id: surveyOptionFieldId, answer, answerValue }]
                        : [answer],
                id,
                userSurveyField,
              });
            }
          } else {
            accumulator.push(currentItem);
          }
          return accumulator;
        },
        [],
      );

      const setQuestionValues = (question: SurveyQuestionsForm, commonQuestionAnswers: any) => {
        switch (question?.fieldType) {
          case SurveyItemType.SLIDER: {
            allSliderFields.push({
              id: question?.id,
              value: commonQuestionAnswers?.answer || null,
              name: question?.fieldName,
              question,
            });
            const defaultAnswer = question?.surveyOptionFields?.[0]?.ordering ?? null;
            const selectedOptionAnswer =
              question?.surveyOptionFields?.find(
                option => option?.optionName === commonQuestionAnswers?.answer,
              )?.ordering ?? defaultAnswer;
            defaultValue[question?.id] = selectedOptionAnswer
            break;
          }

          case SurveyItemType.Rank: {
            let rankOptions = [];

            if (commonQuestionAnswers?.answer) {
              const sortedOptions = commonQuestionAnswers?.answer?.sort((a: any, b: any) => {
                const valueA = Number(a?.answerValue);
                const valueB = Number(b?.answerValue);

                return valueA - valueB;
              });
              rankOptions =
                sortedOptions?.map((answer: any) => {
                  const selectedOptionAnswer = question?.surveyOptionFields.find(
                    (option: any) => answer?.id === option.id,
                  );

                  return {
                    id: selectedOptionAnswer?.id,
                    order: answer?.answerValue,
                    value: selectedOptionAnswer?.optionName,
                  };
                }) || [];
            } else {
              rankOptions =
                question?.surveyOptionFields?.map(option => ({
                  id: option?.id,
                  order: option?.ordering,
                  value: option?.optionName,
                })) || [];
            }
            // setAnswer(rankOptions);
            defaultValue[question?.id] = rankOptions;

            break;
          }

          case SurveyItemType.SLIDER_ARRAY: {
            defaultValue[question?.id] = '12';
            question?.surveyOptionFields?.forEach((sliderOption: any) => {
              const optionId = `${question?.id}_${sliderOption?.id}`;
              const options = sliderOption?.optionValues;
              const defaultAnswer = options?.[0]?.ordering ?? null;
              const currentOptionAns = commonQuestionAnswers?.answer.find(
                (q: any) => q.id === sliderOption?.id,
              );
              const matchedOptions =
                options &&
                options?.find((option: any) => currentOptionAns?.answer === option?.optionName);
              defaultValue[optionId] = matchedOptions?.ordering ?? defaultAnswer;
            });
            break;
          }

          case SurveyItemType.RADIO_BOX_ARRAY: {
            defaultValue[question?.id] = '123';
            question?.surveyOptionFields?.forEach((radioOption: any) => {
              const optionId = `${question?.id}_${radioOption?.id}`;
              const currentOptionAns = commonQuestionAnswers?.answer.find(
                (q: any) => q.id === radioOption?.id,
              );
              defaultValue[optionId] = currentOptionAns?.answer || null;

            });
            break;
          }

          case SurveyItemType.CONSTANT_SUM_PERCENTAGE: {
            defaultValue[question?.id] = '789';
            question?.surveyOptionFields?.forEach((sliderOption: any) => {
              const optionId = `${question?.id}_${sliderOption?.id}`;
              const currentOptionAns =
                commonQuestionAnswers &&
                commonQuestionAnswers?.answer &&
                commonQuestionAnswers?.answer?.find((q: any) => q.id === sliderOption?.id);
              defaultValue[optionId] = currentOptionAns?.answer ?? 0;
            });
            break;
          }

          case SurveyItemType.CHECKBOX:
          case SurveyItemType.Session:
          case SurveyItemType.Room:
          case SurveyItemType.Day:
            defaultValue[question?.id] = commonQuestionAnswers?.answer || [];

            
            break;
          case SurveyItemType.TERM_AND_CONDITION: {
            const defaultAnswers = question?.surveyOptionFields
              .filter(
                option =>
                  surveyQuestionsWithAnswers.find(
                    ans => ans?.userSurveyField?.surveyFieldId === option?.id,
                  )?.answer === '1',
              )
              .map(option => option?.fieldName);
            defaultValue[question?.id] = defaultAnswers;

            break;
          }
          case SurveyItemType.TARGET_DEPARTMENT: {
            const selectedJobFunction = jobFunctionsOptions?.find(
              job => job?.name === commonQuestionAnswers?.answer,
            );
            defaultValue[question?.id] = selectedJobFunction || null;

            break;
          }
          case SurveyItemType.ORGANIZATION_SIZE: {
            const selectedCount = companySizes?.value?.data?.find(
              count => count?.name === commonQuestionAnswers?.answer,
            );
            defaultValue[question?.id] = selectedCount || null;

            break;
          }
          case SurveyItemType.TECHNOLOGY_INVESTMENT: {
            const techInvestments = commonQuestionAnswers?.answer
              ?.split(',')
              ?.map((ans: any) => ans);
            defaultValue[question?.id] = techInvestments || [];

            break;
          }
          default:
            defaultValue[question?.id] = commonQuestionAnswers?.answer || null;

        }
      };

        eventSurveyFields?.forEach((question: SurveyQuestionsForm) => {
          const value: any = updatedSurveyQuestionsWithAnswers.find(
            (answer) => answer?.userSurveyField?.surveyFieldId === question?.id
          );
          setQuestionValues(question, value);
        });

        if (termAndConditionAllPages?.length) {
          const currentPageTerms = termAndConditionAllPages?.find(
            (termsQuestions: any) =>
              termsQuestions?.eventQuestionPageId === item?.id
          );
          if (currentPageTerms)
            defaultValue[currentPageTerms?.id] = termAndConditionAnswers;
        }
      });
    } else if (!surveyPages?.length || surveyPages?.every((x)=>!x?.eventSurveyFields?.length)) {
      setShowMatchUsers(true);
      verifyUnderReviewPage();
      // setSurveySubPage("attendeesListView");
    }
    reset(defaultValue)
    setTextSliderValue(allSliderFields);
  }, [surveyPages, event, userExitedSurvey,surveyQuestionsWithAnswers]);

  // get all page questions Id's in array
  useEffect(() => {
    const allFieldsIds: any = {};
    const onlyRequiredFieldsIds: any = {};

    Object.keys(questionPerPage).forEach(
      (pageId: string, pageIndex: number) => {
        if (questionPerPage[pageId].length) {
          questionPerPage[pageId]?.forEach(
            (questionObj: any, index: number) => {
              // for specific room question
              if (questionObj?.type === "room") {
                const roomsIds = Object.keys(questionObj?.obj);
                if (roomsIds?.length) {
                  setSelectedRoom((prevQuestions: any) => ({
                    ...prevQuestions,
                    [pageId]: {
                      ...prevQuestions[pageId],
                      [index]: roomsIds[0],
                    },
                  }));
                }

                roomsIds.forEach((roomId: string) => {
                  questionObj?.obj[roomId].forEach(
                    (question: SurveyQuestionsForm) => {
                      if (!allFieldsIds[pageId]) {
                        allFieldsIds[pageId] = [];
                      }
                      if (!onlyRequiredFieldsIds[pageId]) {
                        onlyRequiredFieldsIds[pageId] = [];
                      }
                      if (question.isRequired) {
                        onlyRequiredFieldsIds[pageId].push(question?.id);
                      }
                      allFieldsIds[pageId].push(question?.id);
                    }
                  );
                });
              }

              // for all rooms question
              else if (questionObj?.type === "default") {
                questionObj?.obj.forEach((question: SurveyQuestionsForm) => {
                  if (!allFieldsIds[pageId]) {
                    allFieldsIds[pageId] = [];
                  }
                  if (!onlyRequiredFieldsIds[pageId]) {
                    onlyRequiredFieldsIds[pageId] = [];
                  }
                  if (question.isRequired) {
                    onlyRequiredFieldsIds[pageId].push(question?.id);
                  }
                  allFieldsIds[pageId].push(question?.id);
                });
              }
            }
          );
        }

        // if  page is empty
        else {
          if (!allFieldsIds[pageId]) {
            allFieldsIds[pageId] = [];
          }

          if (!onlyRequiredFieldsIds[pageId]) {
            onlyRequiredFieldsIds[pageId] = [];
          }
        }
      }
    );
    setRequiredQuestionsIds(onlyRequiredFieldsIds);
    setAllPagesQuestionsIds(allFieldsIds);
  }, [questionPerPage]);

  useEffect(() => {
    if (open) {
      if (userId !== "" && !surveyPageLoader)
        getSurveyQuestionsWithAnswers(userId);
    }
  }, [open, userId, surveyPageLoader]);

  const getQuestionWithShuffledOptions = (question: SurveyQuestionsForm) => {
    const { shuffleOptions, surveyOptionFields } = question;
    if (shuffleOptions)
      return {
        ...question,
        surveyOptionFields: getShuffledOptions(surveyOptionFields),
      };
    else return question;
  };

  useEffect(() => {
    const output: Output = {};
    let termsAndConditionPerPage: any = [];
    // solution 2
    surveyPages?.forEach((item, pageIndex) => {
      const pageId = item.id;
      const { eventSurveyFields } = item;

      const pageContent: Output[string] = [];
      let currentRoomId: string | null = null;
      let currentRoomFields: SurveyQuestionsForm[] = [];
      let roomObj: { [key: string]: SurveyQuestionsForm[] } = {};
      let currentDefaultFields: any = [];
      let allTermsAndCondition: any = [];

      if (eventSurveyFields?.length) {
        eventSurveyFields.forEach((question: any, index: number) => {
          const questionWithShuffledOptions =
            getQuestionWithShuffledOptions(question);
          if (question?.room) {
            if (
              currentRoomId === question.room.id &&
              currentDefaultFields.length === 0
            ) {
              if (question?.fieldType !== SurveyItemType.TERM_AND_CONDITION)
                currentRoomFields.push(questionWithShuffledOptions);
              else allTermsAndCondition.push(questionWithShuffledOptions);
            } else {
              if (currentRoomFields.length > 0) {
                roomObj = { ...roomObj, [currentRoomId!]: currentRoomFields };
                if (
                  Object.keys(roomObj)?.includes(question?.room?.id) &&
                  currentRoomId !== question?.room?.id
                ) {
                  pageContent.push({
                    type: "room",
                    obj: roomObj,
                    tabs: [currentRoomId!],
                  });
                  roomObj = {};
                  currentRoomFields = [];
                } else {
                  currentRoomFields = [];
                }
              }

              if (currentDefaultFields.length > 0) {
                if (Object.keys(roomObj).length) {
                  pageContent.push({
                    type: "room",
                    obj: roomObj,
                    tabs: [currentRoomId!],
                  });
                  roomObj = {};
                  currentRoomFields = [];
                }

                pageContent.push({
                  type: "default",
                  obj: Object.assign([], currentDefaultFields),
                });
                currentDefaultFields = [];
              }

              currentRoomId = question.room.id;
              if (question?.fieldType !== SurveyItemType.TERM_AND_CONDITION)
                currentRoomFields.push(questionWithShuffledOptions);
              else allTermsAndCondition.push(questionWithShuffledOptions);
            }
          } else {
            if (question?.fieldType !== SurveyItemType.TERM_AND_CONDITION)
              currentDefaultFields.push(questionWithShuffledOptions);
            else allTermsAndCondition.push(questionWithShuffledOptions);
          }
        });

        if (currentRoomFields.length > 0) {
          roomObj = { ...roomObj, [currentRoomId!]: currentRoomFields };
          pageContent.push({
            type: "room",
            obj: roomObj,
            tabs: [currentRoomId!],
          });
        }

        if (currentDefaultFields.length > 0) {
          pageContent.push({
            type: "default",
            obj: Object.assign([], currentDefaultFields),
          });
        }
        if (allTermsAndCondition?.length) {
          allTermsAndCondition[0].surveyOptionFields = allTermsAndCondition;
          termsAndConditionPerPage.push(allTermsAndCondition[0]);

          pageContent.push({
            type: "default",
            obj: Object.assign([], [allTermsAndCondition[0]]),
          });
        }
        output[pageId] = pageContent;
      } else {
        output[pageId] = [];
      }
    });
    setTermAndConditionAllPages(termsAndConditionPerPage);
    setQuestionPerPage(output);
  }, [surveyPages]);

  const showAttendeeListView = () => {
    setSurveySubPage("attendeesListView");
    setStepName("Event Attendee Matches");
    setStepDescription("Connect and meet with other attendees");
    setCurrentSurveyStep(currentSurveyStep);

    return (
      <>
        {!isSubmitLoading && (
          <MatchAttendeeList
            userId={userId}
            handleRequestMeeting={(attendee) => {
              setSurveySubPage("meetingListView");
              setMeetingAttendee(attendee);
            }}
          />
        )}

        {isSubmitLoading && (
          <Stack
            sx={{
              direction: "row",
              // height: "100%",
              "@media (max-width: 768px)": {
                height: "68%",
              },
              "@media (min-width: 768px)": {
                height: "80%",
              },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="info" />
            <Typography variant="h6" sx={{ color: "text.secondary", px: 2 }}>
              Submitting Survey Answers
            </Typography>
          </Stack>
        )}
      </>
    );
  };

  const showMeetingListView = () => {
    setStepName("Event Attendee Matches");
    setStepDescription("Connect and meet with other attendees");
    setCurrentSurveyStep(0);
    setSurveySubPage("meetingListView");
    return (
      !isSubmitLoading && (
        <MeetingRequestList
          ref={meetingListRef}
          personId={userId}
          meetingAttendee={meetingAttendee}
          setMeetingAttendee={setMeetingAttendee}
        />
      )
    );
  };

  // on last step submit form
  const formSubmit = useCallback(() => {
    const pageIds: string[] = Object.keys(questionPerPage);
    if (pageIds?.length && currentSurveyStep === pageIds?.length) {
      onSubmit(values);
    }
  }, [currentSurveyStep, values, questionPerPage]);

  useEffect(() => {
    formSubmit();
  }, [currentSurveyStep, questionPerPage]);

  // click on next step

  useEffect(() => {
    const allPages = Object.keys(questionPerPage);
    const pageId = allPages[currentSurveyStep];
    if (pageId) {
      const isQuestionFilledPerPage: boolean[] = [];
      if (Object.keys(requiredQuestionsIds).length) {
        // if all fields are not required then skip survey page
        const allFieldsAreNotRequired = Object.values(
          requiredQuestionsIds
        ).every((value) => Array.isArray(value) && value.length === 0);
        setIsSuveryorRegSkipable(allFieldsAreNotRequired || false);

        if (requiredQuestionsIds[pageId]?.length) {
          requiredQuestionsIds[pageId].forEach((questionName: string) => {
            const isFilled =
              values[questionName] && values[questionName] !== "";
            if (isFilled) {
              isQuestionFilledPerPage.push(true);
            } else {
              isQuestionFilledPerPage.push(false);
            }
          });
        } else {
          isQuestionFilledPerPage.push(true);
        }
      }

      if (isQuestionFilledPerPage.every((filled: boolean) => filled)) {
        setRequiredQuestionsFilled(true);
      } else {
        setRequiredQuestionsFilled(false);
      }
    }
  }, [values]);

  const getValidTermAndCondition = () => {
    const isValid = sessionStorage.getItem("termAndCondition");
    return isValid === "true";
  };

  const getPageBodyDescription = (pageId: string) => {
    const page = surveyPages.find((page: any) => page.id === pageId);
    if (page) {
      return page?.bodyDescription;
    }
    return "";
  };

  const findQuestionByPageIdAndStatus = (
    questionSenariosVisibility: any,
    currentPageId: string
  ) => {
    const questions = Object.values(questionSenariosVisibility);
    const result = questions.find(
      (question: any) =>
        question.pageId === currentPageId && question.currentStatus === "exit"
    );

    return result;
  };
  const findQuestionByStatus = (questionSenariosVisibility: any) => {
    const questions = Object.values(questionSenariosVisibility);
    const result = questions.find(
      (question: any) => question.currentStatus === "exit"
    );

    return result;
  };

  const userExitSurvey = () => {
    const pageIds: string[] = Object.keys(questionPerPage);
    const currentPageId = pageIds[currentSurveyStep];
    const foundQuestion = findQuestionByPageIdAndStatus(
      questionSenariosVisibility,
      currentPageId
    );
    return foundQuestion;
  };

  const incrementInPage = (currentPage: number) => {
    setNextButtonClicked(false);
    const pageIds: string[] = Object.keys(questionPerPage);
    const foundQuestion = userExitSurvey();
    scrollToTop();

      const currentPageId = pageIds[currentSurveyStep];
      const currentPageQuestionsIds = allPagesQuestionsIds[currentPageId];
      if((currentSurveyStep || currentSurveyStep === 0) && currentSurveyStep !== pageIds?.length - 1 ) {
             onPartialSubmit(values,currentPageQuestionsIds)
        }

    if (foundQuestion) {
      setCurrentSurveyStep(pageIds?.length);
    } else setCurrentSurveyStep(currentPage + 1);
  };

  const handleNextStep = useCallback(async () => {
    if (surveySubPage === "surveyForm") {
      setNextButtonClicked(true);
      const pageId = getCurrentPage()?.id;
      if (pageId) {
        let isValid = false;
        const pageIds: string[] = Object.keys(questionPerPage);
        const currentPage = pageIds.findIndex((pageid) => pageid === pageId);
        const currentPageQuestionsIds = allPagesQuestionsIds[pageId];

        const currentPageQuestions =  getCurrentPageQuestions(currentPageId);

        const questionsToCheck = filterRadioBoxQuestion(
          currentPageQuestions,
          currentPageQuestionsIds
        );

        let questionIds: any = [...currentPageQuestionsIds] || [];
        if (questionsToCheck?.length) {
          questionIds = addSurveyOptionIdsInPageQuestionIds(
            questionIds,
            questionsToCheck
          );
        }
        // isValid = await trigger(allPagesQuestionsIds[pageId]);
        isValid = await trigger(questionIds);
        if (isValid) {
          if (
            termAndConditionAllPages.length &&
            termAndConditionAllPages?.some(
              (page: any) => page.eventQuestionPageId === pageId
            )
          ) {
            const isValidTermAndCondition = getValidTermAndCondition();
            if (isValidTermAndCondition) {
              // sessionStorage.setItem("termAndCondition", "false")
              incrementInPage(currentPage);
            }
          } else {
            incrementInPage(currentPage);
          }
        }
      }
    } else {
      setShowMatchUsers(true);
    }
  }, [currentSurveyStep, surveySubPage, allPagesQuestionsIds,values]);

  const handleRequestMeeting = () => {
    meetingListRef?.current?.handleSetMeetingSlot();
  };

  const handleSkipPage = async () => {
    const pageId = getCurrentPage()?.id;
    if (pageId) {
      let isValid = false;
      const pageIds: string[] = Object.keys(questionPerPage);
      isValid = await trigger(allPagesQuestionsIds[pageId]);
      if (isValid) {
        setCurrentSurveyStep(pageIds.length);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleNextStep,
    handlePrevPage,
    handleRequestMeeting,
    handleSkipPage,
  }));

  const getCurrentPage = useCallback(() => {
    const allPages = Object.keys(questionPerPage);
    const currentPageId = allPages[currentSurveyStep];
    if (currentPageId && surveySubPage === "surveyForm") {
      const findCurrentPage = surveyPages.find(
        (page) => page?.id === currentPageId
      );
      getCurrentSurveyPage(findCurrentPage, allPages);
      setCurrentPageFontColor(findCurrentPage?.fontColor);
      setCurrentPageTitleDescPosition(findCurrentPage?.titleDescPosition);
      setCurrentPageBackgroundColor(findCurrentPage?.secondaryBackgroundColor);
      setCurrentPagePrimaryBgColor(findCurrentPage?.backgroundColor);
      setCurrentPageHeaderColor(findCurrentPage?.headerColor);
      return findCurrentPage || {};
    } else if (!currentPageId && surveySubPage !== "surveyForm") {
      // const foundMedia = findMedia(surveyPages)
      getCurrentSurveyPage({
        media: null,
      });
    }
  }, [questionPerPage, surveySubPage, currentSurveyStep]);

  useEffect(() => {
    getCurrentPage();
  }, [currentSurveyStep, surveySubPage, questionPerPage]);

  useEffect(() => {
    if (showMatchUsers || isSurveySubmitted) {
      if (meetingAttendee?.userId) {
        setSurveySubPage("meetingListView");
      } else {
        verifyUnderReviewPage();
      }
    } else {
      setSurveySubPage("surveyForm");
    }
  }, [showMatchUsers, isSurveySubmitted, meetingAttendee]);


  useEffect(() => {
    getSurveyQuestions();
    getJobFunctions();
  }, []);

  return surveyPageLoader ||
    isSubmitLoading ||
    surveyAnsLoader ||
    exitSurveyLoading ||
    upcomingEvent?.loading ? (
    <Stack
      sx={{
        direction: "row",
        "@media (max-width: 768px)": {
          height: "68%",
        },
        "@media (min-width: 768px)": {
          height: "80%",
        },
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress color="info" />
    </Stack>
  ) : (
    <>
      {surveySubPage === "attendeesListView" ? (
        showAttendeeListView()
      ) : surveySubPage === "meetingListView" ? (
        showMeetingListView()
      ) :  surveyPages && (!surveyPages?.length || surveyPages?.every((x)=>!x?.eventSurveyFields?.length)) && !canAttendeesRequestMeeting ?
      <PastEvent attendeesListView={false} />
      :
      (
        <Stack
          ref={topRef}
          sx={{
            pl: "25px",
            pr: "15px",
            overflowY: "scroll",
            overflowX: "hidden",
            height: "100% !important",
            "--scrollbar-color": primaryBackgroundColor,
            "& form > div": {
              overflowX: "scroll",
            },
          }}
          className={
            event?.websiteTemplate === "spectre"
              ? "custom-scroll-spectre"
              : "custom-scroll"
          }
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!showMatchUsers &&
              Object.keys(questionPerPage).map(
                (page: any, _pageIndex: number) =>
                  currentSurveyStep === _pageIndex && (
                    <>
                      {!surveyAnsLoader && !questionPerPage?.[page]?.length ? (
                        getPageBodyDescription(page) ? (
                          <Typography
                            id="event-name-input"
                            sx={{
                              color: currentPageFontColor || secondaryFontColor,
                              fontSize: 14,
                              fontFamily: "Poppins-500",
                              lineHeight: "18px",
                              mb: "5px",
                            }}
                          >
                            {getFieldLabel(false, getPageBodyDescription(page))}
                          </Typography>
                        ) : (
                          <Stack
                            sx={{
                              direction: "row",
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                mt: 2,
                                color: currentPageFontColor,
                                fontFamily: "Poppins-600",
                              }}
                            >
                              No Questions on this page
                            </Typography>
                          </Stack>
                        )
                      ) : (
                        questionPerPage?.[page]?.map(
                          (questiontype: any, index: number) => {
                            switch (questiontype?.type) {
                              case "default":
                                return (
                                  <div
                                    key={index}
                                    style={{ paddingTop: "20px" }}
                                  >
                                    {index !== 0 && (
                                      <Divider
                                        sx={{
                                          boder: "1px",
                                          borderStyle: "dashed",
                                        }}
                                      />
                                    )}
                                    {/*  default questions */}
                                    {questiontype?.obj?.map(
                                      (question: any, index: number) => (
                                        <Stack
                                          direction="column"
                                          // spacing={1}
                                          sx={
                                            {
                                              // px: '30px', py: 1,
                                              // '@media (max-width: 768px)': {
                                              //     padding: "15px",
                                              //     paddingTop: "10px",
                                              // },
                                            }
                                          }
                                          key={question?.id}
                                        >
                                          {surveyQuestion(
                                            question,
                                            index,
                                            questionPerPage?.[page]?.length
                                          )}
                                          {/* <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : "#707070" }} /> */}
                                        </Stack>
                                      )
                                    )}
                                  </div>
                                );

                              case "room":
                                return (
                                  <div
                                    key={index}
                                    style={{ paddingTop: "20px" }}
                                  >
                                    <Stack
                                      direction="row"
                                      sx={{ p: 2 }}
                                      key={index}
                                    >
                                      {Object.keys(questiontype?.obj).map(
                                        (i: any) => (
                                          <Button
                                            key={i}
                                            style={{
                                              flexBasis: "100%",
                                              borderRadius: "5px",
                                              background:
                                                selectedRoom?.[page]?.[
                                                  index
                                                ] === i
                                                  ? 
                                                  // surveyPages[_pageIndex]
                                                  //     ?.backgroundColor
                                                  headerColor
                                                  : "white",
                                              color:
                                                selectedRoom?.[page]?.[
                                                  index
                                                ] === i
                                                  ? "white"
                                                  : "black",
                                            }}
                                            onClick={() => {
                                              if (page)
                                                setSelectedRoom(
                                                  (prevQuestions: any) => ({
                                                    ...prevQuestions,
                                                    [page]: {
                                                      ...prevQuestions[page],
                                                      [index]: i,
                                                    },
                                                  })
                                                );
                                            }}
                                          >
                                            {
                                              questiontype?.obj[i][0]?.room
                                                ?.name
                                            }
                                          </Button>
                                        )
                                      )}
                                    </Stack>

                                    {selectedRoom?.[page]?.[index] &&
                                      questiontype?.obj[
                                        selectedRoom?.[page]?.[index]
                                      ]?.map(
                                        (question: SurveyQuestionsForm) => (
                                          <Stack
                                            direction="column"
                                            // spacing={1}
                                            sx={
                                              {
                                                // px: '30px', py: 1,
                                                // '@media (max-width: 768px)': {
                                                //     padding: "15px",
                                                //     paddingTop: "10px",
                                                // },
                                              }
                                            }
                                            key={question?.id}
                                          >
                                            {surveyQuestion(
                                              question,
                                              index,
                                              questionPerPage?.[page]?.length
                                            )}
                                            {/* {questiontype?.obj[selectedRoom?.[page]?.[index]].length - 1 !== index ? <Divider sx={{ padding: "0px", margin: "0px", mt: "12px !important", mb: "8px !important", borderColor: event?.websiteTemplate === "spectre" ? "rgba(255, 255, 255, 0.15)" : null }} /> : null} */}
                                          </Stack>
                                        )
                                      )}
                                  </div>
                                );
                              default:
                                return null;
                            }
                            return false;
                          }
                        )
                      )}
                    </>
                  )
              )}
          </FormProvider>

        </Stack>
      )}
    </>
  );
}
export default forwardRef(Survey);
