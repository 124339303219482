import { useSurveyContext } from "src/mui/providers/GeneralContext";
import PCompanyAutocomplete from "./PCompanyAutocomplete";
type OptionType = {
  id: string;
  name: string;
};

type PIndustryProps = {
  name: string;
  label?: any;
  disabled?: boolean;
  defaultValue?: OptionType;
  placeholder?: any;
  multiple?: boolean;
  sx?: any;
  className?: string;
  defaultFormValue?: OptionType;
};

const PCompanyIndustrySelect = ({ name, label, disabled = false, defaultValue, placeholder, multiple = false, sx ,className, defaultFormValue}: PIndustryProps) => {
  const { allIndustries } = useSurveyContext();
  return (
    <PCompanyAutocomplete
      // onlySelectFromDropdown
      className={className}
      sx={sx}
      label={label}
      placeholder={placeholder}
      name={name}
      multiple={multiple}
      disabled={disabled}
      defaultFormValue={defaultFormValue}
      clearOnBlur
      fullWidth
      loading={allIndustries === undefined}
      filterSelectedOptions
      {...(defaultValue && { defaultValue })}
      options={allIndustries ?? []}
      getOptionLabel={(option: OptionType | string) => (option as OptionType).name ?? option}
      isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
    />
  );
};

export default PCompanyIndustrySelect;
