import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser, useUserDetails } from '../../../../utils/hooks';
import { StarIcon } from '../../../../shared-components/V2/IconSvg/StarIcon';
import { UserArrowIcon } from '../../../../shared-components/V2/IconSvg/UserArrowIcon';
import { UserWorkIcon } from '../../../../shared-components/V2/IconSvg/UserWorkIcon';
import { Logo } from '../../../../shared-components/V2/Logo';
import { Avatar, Button } from '../../../../shared-components/V2';

interface Props {
  userProfile?: any;
  companies?: any[];
  events?: any[];
  interests?: any[];
}

export const UserMutuals = ({
  userProfile, companies, events, interests
}: Props) => {
  const user = useCurrentUser();
  const { avatar } = useUserDetails(user);
  const navigate = useNavigate();
  const [seeMoreEvent, setSeeMoreEvent] = useState(false);
  const [seeMoreCompany, setSeeMoreCompany] = useState(false);
  const [seeMoreInterests, setSeeMoreInterestst] = useState(false);

  return (
    <div className="md:w-[380px] w-full md:px-0 px-3 m-auto justify-center mb-5 mt-4">
      <div className="flex items-center justify-center mb-5">
        <div className="flex -space-x-1 overflow-hidden mr-1">
          <Avatar
            className="ring-2 ring-white"
            src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${userProfile?.value?.avatar}`}
            alt=""
            size="XSMALL"
          />
          <Avatar
            className="ring-2 ring-white"
            src={avatar}
            alt=""
            size="XSMALL"
          />
        </div>
        <div className="text-[15px] leading-[18px] text-[#172746] font-medium">Mutuals</div>
      </div>
      {
        !!events?.length && (
          <div className="bg-[#EDF2F5] rounded-[5px] py-[17px] px-4 mb-2.5">
            <div className="flex">
              <div className="mr-2"><UserWorkIcon /></div>
              <div className="pt-0">
                <div className="text-md3 font-medium leading-5 text-[#172746] mb-[5px]">
                  You both were at:
                </div>
                {
                  events && (seeMoreEvent ? events : events.slice(0, 3))?.map(event => (
                    <div className="text-sm7 font-medium text-[#144EE6] mb-1 cursor-pointer" onClick={() => navigate(`/event/${event.slug}/discussion`)}>
                      {event.name}
                    </div>
                  ))
                }
                {
                  events && events?.length > 3
                  && (
                  <Button
                    type="button"
                    text={seeMoreEvent ? 'see less' : '... see more'}
                    size="x-small"
                    className="h-0 text-[#144EE6] text-x-sm"
                    onClick={() => { setSeeMoreEvent(!seeMoreEvent); }}
                  />
                  )
                }

              </div>
            </div>
          </div>
        )
      }
      {
        !!companies?.length && (
          <div className="bg-[#EDF2F5] rounded-[5px] py-[17px] px-4 mb-2.5">
            <div className="flex">
              <div className="mr-2"><UserArrowIcon /></div>
              <div className="pt-0">
                <div className="text-md3 font-medium leading-5 text-[#172746] mb-[10px]">
                  You both worked at:
                </div>
                {
                  companies && (seeMoreCompany ? companies : companies.slice(0, 1))?.map((company: any) => (
                    <div className="flex flex-row items-center">
                      <div className="pr-2.5">
                        <Logo
                          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${company.logo}`}
                          alt={company.name}
                          defaultLogo="company"
                          className="w-[35px] h-[35px] rounded-[5px] bg-[#edf2f5]"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="text-[15px] h-[21px] text-[#000000] font-semibold">{company.name}</div>
                        <div className="text-h3 text-[#000000] leading-[18px] text-ellipsis overflow-hidden font-normal">
                          {company?.industry?.name}
                        </div>
                      </div>
                    </div>
                  ))
                }
                {
                  companies && companies.length > 1 && (
                  <Button
                    type="button"
                    text={seeMoreCompany ? 'see less' : '... see more'}
                    size="x-small"
                    className="h-0 text-[#144EE6] text-x-sm"
                    onClick={() => { setSeeMoreCompany(!seeMoreCompany); }}
                  />
                  )
                }

              </div>
            </div>
          </div>
        )
      }
      {
        !!interests?.length && (
          <div className="bg-[#EDF2F5] rounded-[5px] py-[17px] px-4">
            <div className="flex">
              <div className="mr-2"><StarIcon /></div>
              <div className="pt-0">
                <div className="text-md3 font-medium leading-5 text-[#172746] mb-[5px]">
                  You both are interested in:
                </div>
                {
                  interests && (seeMoreInterests ? interests : interests.slice(0, 3))?.map(interest => <div className="text-sm7 font-normal text-[#000000] mb-1">{interest?.interest?.name}</div>)
                }
                {
                  interests && interests.length > 3 && (
                  <Button
                    type="button"
                    text={seeMoreInterests ? 'see less' : '... see more'}
                    size="x-small"
                    className="h-0 text-[#144EE6] text-x-sm"
                    onClick={() => { setSeeMoreInterestst(!seeMoreInterests); }}
                  />
                  )
                }
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};
