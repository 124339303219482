import React from 'react';

interface ICreateMenuItemBox {
  Icon: any;
  title: string;
  onClick?: Function;
}
const CreateMenuItemBox = ({ Icon, title, onClick }: ICreateMenuItemBox) => {
  return (
    <div 
      className="cursor-pointer bg-[#EDF2F5] rounded-[10px] flex flex-col items-center pb-[10.5px] pt-[11.9px] w-[88px] h-[85.75px]" 
      onClick={() => { onClick && onClick() }}
    >
      <div><Icon /></div>
      <div className="text-[13px] leading-5 font-normal text-[#172746] mt-2">{title}</div>
    </div>
  );
};

export default CreateMenuItemBox;
