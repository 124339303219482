import React, { FC } from 'react';
import { UserNavbar } from '../components';

interface ISecondLayoutProps {
  hero?: JSX.Element;
  leftPanel: JSX.Element;
  children: JSX.Element;
}
export const SecondLayout: FC<ISecondLayoutProps> = ({ hero, leftPanel, children }) => (
  <div className="bg-gray-1 h-full">
    <UserNavbar />
    <div>
      <div className="container xl:max-w-none max-w-[100%] mx-auto xl:w-[1258px] md:w-[100%] w-full md:pb-0 pb-[70px]">
        {hero}
        <div className="flex flex-row justify-center w-full mx-auto xl:px-0 md:px-[20px]">
          <div className="md:flex hidden flex-col xl:w-[285px] md:w-[25%]">{leftPanel}</div>
          <div className="mb-0 xl:w-auto md:w-[75%] w-full">{children}</div>
        </div>
      </div>
    </div>
  </div>
);

export default SecondLayout;
