import React from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface ISessionHandoutSvgIconActive {
  isActive?: boolean;
}

export const SessionHandoutSvgIconActive = ({ isActive }: ISessionHandoutSvgIconActive) => {
    const colors = getBrandingColors();

    return isActive ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24.808 24.808"
        >
            <path
                id="download-square-svgrepo-com"
                d="M2,14.4C2,8.557,2,5.633,3.817,3.817S8.557,2,14.4,2s8.771,0,10.587,1.817,1.817,4.74,1.817,10.587,0,8.771-1.817,10.587-4.74,1.817-10.587,1.817-8.771,0-10.587-1.817S2,20.251,2,14.4ZM14.4,7.272a.93.93,0,0,1,.93.93v6.437l2.133-2.133a.93.93,0,1,1,1.316,1.316l-3.721,3.721a.93.93,0,0,1-1.316,0l-3.721-3.721a.93.93,0,1,1,1.316-1.316l2.133,2.133V8.2A.93.93,0,0,1,14.4,7.272Zm-4.962,12.4a.93.93,0,1,0,0,1.861h9.923a.93.93,0,1,0,0-1.861Z"
                transform="translate(-2 -2)"
                fill={colors?.Accent || '#03FFCD'}
                fillRule="evenodd"
            />
        </svg>
    ) : (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25.808 25.808"
        >
            <path
                id="download-square-svgrepo-com"
                d="M2,14.4C2,8.557,2,5.633,3.817,3.817S8.557,2,14.4,2s8.771,0,10.587,1.817,1.817,4.74,1.817,10.587,0,8.771-1.817,10.587-4.74,1.817-10.587,1.817-8.771,0-10.587-1.817S2,20.251,2,14.4ZM14.4,7.272a.93.93,0,0,1,.93.93v6.437l2.133-2.133a.93.93,0,1,1,1.316,1.316l-3.721,3.721a.93.93,0,0,1-1.316,0l-3.721-3.721a.93.93,0,1,1,1.316-1.316l2.133,2.133V8.2A.93.93,0,0,1,14.4,7.272Zm-4.962,12.4a.93.93,0,1,0,0,1.861h9.923a.93.93,0,1,0,0-1.861Z"
                transform="translate(-1.5 -1.5)"
                fill="none"
                stroke={colors?.Accent}
                stroke-width="1.7"
                fillRule="evenodd"
            />
        </svg>
    );
};
