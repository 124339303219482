import React from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface IMatchSvgIcon {
  isActive?: boolean;
}

export const MatchSvgIconActive = ({ isActive }: IMatchSvgIcon) => {
    const colors = getBrandingColors();
    const defaultColor = '#66686c';
    const iconColor = isActive ? (colors?.Accent || '#03FFCD') : (colors?.Accent || defaultColor);

    return isActive ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 24 24"
            width="25"
            height="25"
        >
            <path
                d="M20,2c0-1.105,.895-2,2-2s2,.895,2,2-.895,2-2,2-2-.895-2-2ZM2,4c1.105,0,2-.895,2-2S3.105,0,2,0,0,.895,0,2,.895,4,2,4ZM22,24c1.105,0,2-.895,2-2s-.895-2-2-2-2,.895-2,2,.895,2,2,2Zm-20,0c1.105,0,2-.895,2-2s-.895-2-2-2-2,.895-2,2,.895,2,2,2ZM18,12c0-3.308-2.692-6-6-6s-6,2.692-6,6,2.692,6,6,6,6-2.692,6-6Zm1.699,2.146c-.75,2.687-2.867,4.803-5.553,5.553l1.02,1.023c.93,1.012,2.63,1.034,3.58,.045l2.021-2.021c1.001-.958,.957-2.637-.021-3.556l-1.047-1.044ZM4.301,9.854c.75-2.687,2.867-4.803,5.553-5.553l-1.02-1.023c-.929-1.018-2.626-1.015-3.58-.045l-2.021,2.021c-1.001,.958-.957,2.637,.021,3.556l1.047,1.044Zm15.398,0l1.023-1.021c1.011-.932,1.033-2.63,.045-3.58l-2.021-2.021c-.937-.993-2.642-.96-3.556,.021l-1.044,1.047c2.686,.75,4.802,2.866,5.552,5.553ZM4.301,14.146l-1.023,1.021c-1.011,.932-1.033,2.63-.045,3.58l2.021,2.021c.957,1,2.636,.958,3.556-.021l1.044-1.047c-2.686-.75-4.802-2.867-5.552-5.553Z"
                fill={iconColor}
            />
        </svg>
    ) : (
<svg xmlns="http://www.w3.org/2000/svg" width="26.45" height="26.455" viewBox="0 0 26.45 26.455">
  <path   fill={iconColor} id="Union_1" data-name="Union 1" d="M20.921,23.686a2.764,2.764,0,1,1,2.764,2.764A2.764,2.764,0,0,1,20.921,23.686Zm1.773,0a.991.991,0,1,0,.991-.991.991.991,0,0,0-.991.991ZM0,23.686A2.764,2.764,0,1,1,2.764,26.45,2.764,2.764,0,0,1,0,23.686Zm1.773,0a.991.991,0,1,0,.991-.991.991.991,0,0,0-.991.991Zm6.11-.457A2.478,2.478,0,0,1,6.118,22.5L3.949,20.332a2.507,2.507,0,0,1,.044-3.573L5.611,15.15a7.674,7.674,0,0,1,0-3.847L3.974,9.67a2.5,2.5,0,0,1-.023-3.551L6.119,3.951a2.489,2.489,0,0,1,1.765-.727h.033A2.52,2.52,0,0,1,9.692,4L11.3,5.61a7.729,7.729,0,0,1,3.846,0L16.78,3.973a2.519,2.519,0,0,1,1.769-.751h.019a2.482,2.482,0,0,1,1.763.728L22.5,6.119a2.506,2.506,0,0,1-.041,3.569L20.841,11.3a7.664,7.664,0,0,1,0,3.847l1.634,1.636a2.487,2.487,0,0,1,.756,1.766h0a2.433,2.433,0,0,1-.733,1.784L20.331,22.5a2.489,2.489,0,0,1-1.765.727h-.033a2.524,2.524,0,0,1-1.775-.77l-1.611-1.615a7.733,7.733,0,0,1-3.847,0L9.671,22.473a2.511,2.511,0,0,1-1.769.756Zm-2.656-5.2A.728.728,0,0,0,5.2,19.077l2.166,2.166a.722.722,0,0,0,.511.214H7.89a.721.721,0,0,0,.512-.222l.1-.108h.006l.989-.994a7.9,7.9,0,0,1-3.188-3.188Zm11.723,2.1,1.082,1.084a.74.74,0,0,0,.52.231h.015a.73.73,0,0,0,.514-.209l2.165-2.165a.722.722,0,0,0-.009-1.031h0l-1.1-1.093a7.916,7.916,0,0,1-3.186,3.19Zm-9.812-6.91a5.384,5.384,0,1,1,0,.007Zm9.81-6.911A7.893,7.893,0,0,1,20.137,9.5l1.085-1.084a.728.728,0,0,0,.022-1.048L19.077,5.2a.722.722,0,0,0-.511-.212H18.56a.73.73,0,0,0-.515.221l-.1.106h0ZM7.371,5.2,5.2,7.373A.723.723,0,0,0,5.214,8.4l.256.247-.005.006.848.845A7.916,7.916,0,0,1,9.5,6.315L8.415,5.226A.731.731,0,0,0,7.9,4.992H7.882a.728.728,0,0,0-.511.208ZM20.922,2.76a2.762,2.762,0,1,1,0,.005Zm2.766-.992a.992.992,0,1,0,.994.989.992.992,0,0,0-.994-.989v-.35h0ZM2.763,5.528A2.764,2.764,0,1,1,5.528,2.764,2.764,2.764,0,0,1,2.763,5.528Zm-.99-2.763a.991.991,0,1,0,.991-.992.991.991,0,0,0-.991.992Z" transform="translate(0 0.005)" />
</svg>


    );
};


