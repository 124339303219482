import {
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit';

import { IComment } from '../../../models/post/comment';
import { PostService } from '../../../services';

export type CommentsState = {
  comments: IComment[];
  replies: IComment[];
};

const initialState: CommentsState = {
  comments: [],
  replies: [],
};

export const commentSlice = createSlice({
  name: 'commentsSlice',
  initialState,
  reducers: {
    setPostComments: (state, action: PayloadAction<IComment[]>) => {
      state.comments = action.payload;
    },
    setCommentReplies: (state, action: PayloadAction<IComment[]>) => {
      state.replies = action.payload;
    },
  },
});

export const { setPostComments, setCommentReplies } = commentSlice.actions;

export const getPostComments =
  (postId: string) => async (dispatch: Dispatch) => {
    const postsService = new PostService();
    const result = await postsService.getPostComments(postId);

    dispatch(setPostComments(result.data));
  };

export const getCommentReplies =
  (postId: string, commentId: string) => async (dispatch: Dispatch) => {
    const postsService = new PostService();
    const result = await postsService.getCommentReplies(postId, commentId);

    dispatch(setCommentReplies(result.data));
  };
