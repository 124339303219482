  import { useEffect, useMemo } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { IAppState } from '../../../store';
  import { getSimilarEvents } from '../../../store/events/similar-events';
  import { CardEvent } from '../../events/event-tab/components/CardEvent';
  import { useCurrentUser } from '../../../utils/hooks';
  import { SIMILAR_EVENTS_PER_ROW } from '../../../constants';
  import useShowMoreCards from '../../../utils/hooks/useShowMoreCards';

  interface ISimilarEventsProps {
    eventIdOrSlug: string;
  }
  export const SimilarEvents = ({ eventIdOrSlug }: ISimilarEventsProps) => {
    const dispatch = useDispatch();

    const user = useCurrentUser();
    const similarEvents = useSelector((state: IAppState) => state.similarEvents);
    const cards = useMemo(
      () => similarEvents.value?.data || [],
      [similarEvents.value?.data]
    );

    const { moreCards, displayBtn, handleSeeMore } = useShowMoreCards(
      SIMILAR_EVENTS_PER_ROW,
      cards
    );
    useEffect(() => {
      if (eventIdOrSlug) {
        dispatch(getSimilarEvents(eventIdOrSlug));
      }
    }, [dispatch, eventIdOrSlug]);

    const handleSeeMoreButton = () => {
      handleSeeMore();
    };

    if (!similarEvents?.value?.data?.length) return null;

    return (
      <div className="h-336 w-full bg-white create-post-card mb-5">
        <div className="flex flex-col p-[19px]">
          <div className="text-sm7 text-blue-4 font-semibold tracking-wide ml-1 mt-1 mb-4">
            Similar Events 
          </div>
          <div className="flex flex-row gap-[15px] flex-wrap justify-start">
            {moreCards.map((event: any) => (
              <div key={`card-${event.id}`} className="rounded-xl sm:w-auto w-full">
                <CardEvent
                  user={user}
                  coverUrl={`${event.cover}`}
                  avatarUrl={`${event?.company?.logo}`}
                  title={event.name!}
                  subTitle=""
                  regions={
                    !!event.eventRegions?.length
                      ? `${event.eventRegions[0].name!} • ${event?.company?.name}`
                      : ` • ${event?.company?.name}`
                  }
                  eventType={event.format!}
                  eventLink={`/event/${event.slug}/discussion`}
                  detailsHref={''}
                  event={event}
                  containerClass="sm:!w-[193px] !w-full"
                  isSimilarEvents
                />
              </div>
            ))}
            {displayBtn && <button type="button" onClick={handleSeeMoreButton} className="bg-[#EDF2F5] hover:bg-[#DFE6EA] h-[31px] w-[904px] rounded-[5px] text-[#203C6E] text-sm1 font-semibold transition-all">Show more</button>}
          </div>
        </div>
      </div>
    );
  };

  export default SimilarEvents;
