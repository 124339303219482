import classNames from 'classnames';
import { IEventSponsor } from '../../../models/event/event-sponsor';
import { Logo } from '../../../shared-components/V2/Logo';

interface IWebsiteEventPartnerList {
  sponsors: IEventSponsor[];
  roomQty?: number;
  isTitleLobby?: boolean;
  isOpen?: boolean;
}

const WebsiteEventPartnerList = ({
  sponsors, roomQty, isTitleLobby, isOpen
}: IWebsiteEventPartnerList) => (
  <div
    className={
      classNames(
        'pl-[230px]',
        {
          '!p-0': roomQty === 2,
          'pl-[100px]': isTitleLobby,
          '!pl-0 max-w-[215px] min-w-[215px]': isOpen
        }
      )
    }
  >
    <div className="">
      {
        !!sponsors && sponsors?.length > 0
        && <div className="text-[14px] text-[#0B1221] font-medium h-[20px] mb-2.5">Partners</div>
      }
      {sponsors
        ? sponsors?.map((item: any) => (
          <div className="flex mb-2.5 last:mb-0">
            <div className="w-full">
              <div className="flex gap-[10px]">

                <Logo src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.logo ?? item?.company?.logo}`} className="w-[49px] h-[49px] object-contain post-company-logo bg-white p-1 small-radius" />

                <div className="flex flex-col justify-start">
                  <div className="text-[13px] leading-[16px] text-[#172746] font-semibold pt-[3px]">
                    {item.company.name}
                  </div>
                  <div className="text-[11px] leading-[13px] text-[#18161C] font-normal">
                    {item.sponsorship}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
        : null}
    </div>
  </div>
);

export default WebsiteEventPartnerList;
