import { useState } from "react"

export interface IPhoneCallSvgIcon {
    fillColor?: "WHITE" | "GRAY";
    onClick?: Function;
}
export const PhoneCallSvgIcon = ({ fillColor, onClick }: IPhoneCallSvgIcon) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const color = fillColor === "GRAY" ? '#edf2f5' : '#ffffff';

    return <svg id="Icon_awesome-video" data-name="Icon awesome-video" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
        onClick={() => onClick!()}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        {
            isHover && <circle id="Ellipse_1123" data-name="Ellipse 1123" cx="14" cy="14" r="14" fill={color} />
        }
        <g id="Component_56" data-name="Component 56" transform="translate(-0.78 0.587)">
        <path id="Icon_feather-phone-call-2" data-name="Icon feather-phone-call" d="M16.758,13.2v2.05a1.367,1.367,0,0,1-1.49,1.367,13.523,13.523,0,0,1-5.9-2.1,13.325,13.325,0,0,1-4.1-4.1,13.523,13.523,0,0,1-2.1-5.924A1.367,1.367,0,0,1,4.533,3h2.05A1.367,1.367,0,0,1,7.95,4.175,8.774,8.774,0,0,0,8.428,6.1a1.367,1.367,0,0,1-.307,1.442L7.253,8.4a10.933,10.933,0,0,0,4.1,4.1l.868-.868a1.367,1.367,0,0,1,1.442-.307,8.774,8.774,0,0,0,1.92.478A1.367,1.367,0,0,1,16.758,13.2Z" transform="translate(4.313 3.969)" fill="#7283A4"/>
        </g>
    </svg>
}

