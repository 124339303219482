import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';
import logo from '../../assets/images/homepage/footer-logo.svg';

export interface FooterLinkProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  href?: string;
  className?: string;
}

export const FooterLink: FC<FooterLinkProps> = ({ text, href, className }) => {
  return (
    <button
      className={classNames("hover:underline text-x-sm", {
        [`${className}`]: className
      })}
      type="button"
      onClick={() => (href ? (window.location.href = href) : undefined)}>
      {text}
    </button>
  );
};

interface FooterProps {
  className?: string;
};

export const Footer: FC<FooterProps> = ({ className }) => {

  return (
    <>
      <footer
        className={classNames({
          [`${className}`]: className
        })}>
        <div className="flex flex-wrap gap-1 whitespace-nowrap justify-center text-white text-base text-justify footer-mobile">
          {/* <FooterLink className="font-semibold" text="Panelist &copy; 2022" />
          <FooterLink href="/user-agreement" text="User Agreement" />
          <FooterLink href="/privacy-policy.html" text="Privacy Policy" />
          <FooterLink href="/cookie-policy" text="Cookie Policy" />
          <FooterLink href="/copyright-policy" text="Copyright Policy" />
          <FooterLink href="/community-guidelines" text="Community Guidelines" />
          <FooterLink href="/about" text="About Us" />
          <FooterLink href="/help" text="Help" /> */}
          <img className="h-7" src={logo} alt="" />
          <text>© Copyright {new Date().getFullYear()} Panelist Pty Ltd. All rights reserved.</text>
        </div>
      </footer>
    </>
  );
};

export default Footer;
