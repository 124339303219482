import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { IEvent } from '../../../models/event/event';
import { IAppState } from '../../../store';
import { Banner } from './banner';
import ContactUs from './contact-us';
import WebsiteEventQuickLinks from './website-event-quicklinks';
import WebsiteEventSpeaker from './website-event-speaker';
import SpectreTestimmonials from './testimonials-spectre';
import BrochureSection from './brochure-section';
import { EventGallery } from './event-gallery';
import WebsiteEventPartnerSpectre from './website-event-partner-spectre';
import General from './General';
import WebsiteEventAgendaLobbySpectre from './website-event-agenda-lobby-spectre';

export const WebsiteEventPreviewSpectre = () => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  return (
    <>
      <Helmet>
        <meta name="title" content={event.seoTitle} />
        <meta name="description" content={event.seoDescription} />
      </Helmet>
      <Banner />
      <div className="w-full mx-auto sm:px-[40px] px-[20px] mt-[42px]">
        <WebsiteEventSpeaker />
        <WebsiteEventAgendaLobbySpectre eventList={event} />
        <SpectreTestimmonials />
      </div>
      <div className="mt-[40px]">
        <BrochureSection />
        <EventGallery />
      </div>

      <div className="w-full mx-auto md:px-[40px] px-[20px]">
        <WebsiteEventPartnerSpectre />
        <General />
        <ContactUs />
      </div>
      <div className="w-full">
        <WebsiteEventQuickLinks />
      </div>
    </>
  );
};
