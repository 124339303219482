/* eslint-disable no-nested-ternary */
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Stack,
  Button,
  Divider,
  Typography,
  Box,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  SurveyItemType,
  SurveyQuestionsForm,
  SurveyQuestionwithAnswer,
} from "src/mui/types/survey";
import { useForm } from "react-hook-form";
import FormProvider from "src/mui/components/hook-form/form-provider";
import {
  PCheckbox,
  PRadioGroup,
  PSlider,
  RHFSelect,
  RHFTextField,
} from "src/mui/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "./Card.css";
import { useNavigate, useParams } from "react-router";
import NewEventCard from "./NewEventCard";
import MatchAttendeeList from "./MatchAttendeeList";
import MeetingRequestList from "./MeetingRequestList";
import { useSurveyContext } from "src/mui/providers/GeneralContext";
import RegisteredCodeForm from "../event/reg-dialog-flow/RegisteredCodeForm";
import { useDispatch, useSelector } from "react-redux";
import { resetUserEmail } from "src/store/checkUserEmail";
import { UserSignUpActionTypes } from "src/store/user/signup/types";
import { IAppState } from "src/store";
import { toast } from "react-toastify";
import useStyling from "src/mui/components/hook-form/useStyling";

type Props = {
  open: boolean;
  setOpenSurveyForm: (value: boolean) => void;
  title: string;
  userId: string;
  onLogout: () => void;

};

interface Page {
  id: string;
  eventSurveyFields: SurveyQuestionsForm[];
}

interface Output {
  [key: string]: {
    type: string;
    obj?: { [key: string]: SurveyQuestionsForm[] };
    tabs?: string[];
    question?: SurveyQuestionsForm;
  }[];
}


function SurveyAnswerForm({
  title,
  open,
  setOpenSurveyForm,
  userId,
  onLogout,
}: Props) {
  // host context
  const {
    getSurveyQuestionsWithAnswers,
    modifySurveyAnswers,
    surveyPageLoader,
    surveyAnsLoader,
    surveyQuestionsWithAnswers,
    surveyPages,
  } = useSurveyContext();

  const [currentPageFontColor, setCurrentPageFontColor] = useState<string | null>("");
  const [step, setStep] = useState<number>(0);
  const { styles, primaryBackgroundColor, primaryFontColor, secondaryBackgroundColor, secondaryFontColor } = useStyling("survey", currentPageFontColor)

  const [pageItems, setPageItems] = useState({
    // bgColor: "#749CFB",
    bgColor: primaryBackgroundColor,
    isMediaExpanded: false,
    primaryFontColor: primaryFontColor || null,
    media: "",
    secondaryBackgroundColor: secondaryBackgroundColor || null
  });

  const dispatch = useDispatch();

  // component state
  const navigate = useNavigate();
  const { eventId } = useParams();
  const { audienceStatus } = useSelector(
    (state: IAppState) =>
      state.upcomingEvent?.value?.data?.attendeeStatus! ?? ""
  );

  const event = useSelector(
    (state: IAppState) =>
      state.website.event || state.upcomingEvent?.value?.data?.event
  );
  const [allPagesQuestionsIds, setAllPagesQuestionsIds] = useState<any>([]);
  const [questionPerPage, setQuestionPerPage] = useState<any>([]);

  const [showMatchUsers, setShowMatchUsers] = useState<boolean>(false);
  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const [selectedRoom, setSelectedRoom] = useState<any>({ roomIndex: "" });
  const [meetingAttendee, setMeetingAttendee] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState<any>("");
  const [showForm, setShowForm] = useState<string>("");
  const [requiredQuestionsIds, setRequiredQuestionsIds] = useState<any>([]);
  const [requiredSurveyQuestionsFilled, setRequiredQuestionsFilled] = useState<boolean>(false);



  // default values
  const defaultValues = useMemo(
    () => {
      const formDefaultValues: any = {};
      if (surveyPages?.length && surveyQuestionsWithAnswers) {
        surveyPages.forEach((item: Page) => {
          const { eventSurveyFields } = item;
          eventSurveyFields.forEach((question: SurveyQuestionsForm) => {
            const value = surveyQuestionsWithAnswers.find(
              (answer: SurveyQuestionwithAnswer) =>
                answer?.userSurveyField?.surveyFieldId === question?.id
            );
            if (value) formDefaultValues[question?.id] = value?.answer;
            else formDefaultValues[question?.id] = undefined;
          });
        });
      }
      return formDefaultValues;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [surveyPages, surveyQuestionsWithAnswers]
  );

  // form validations
  const fieldsObject = useMemo(
    () => {
      const formFields: any = {};
      if (surveyPages?.length)
        surveyPages.forEach((item: Page) => {
          const { eventSurveyFields } = item;
          eventSurveyFields.forEach((field: SurveyQuestionsForm) => {
            const { isRequired, id, fieldName } = field;
            if (isRequired) {
              formFields[id] = Yup.string().required(
                `Required Field`
              );
            } else {
              formFields[id] = Yup.string().notRequired();
            }
          });
        });
      return formFields;
    },
    // eslint-/exhaustive-depsdisable-next-line react-hooks
    [surveyPages]
  );
  const NewEventSchema = Yup.object().shape(fieldsObject);

  // formik hook
  const methods = useForm<any>({
    resolver: yupResolver(NewEventSchema),
    defaultValues,
  });

  // form methods
  const { setValue, watch, handleSubmit, trigger, formState: { isValid } } = methods;
  const values = watch();

  useEffect(() => {
    if (surveyPages?.length) {
      setCurrentPageFontColor(surveyPages[step]?.fontColor)
    }
  }, [surveyPages, step])

  // display survey questions
  // const surveyQuestion = useCallback(
  //   (question: SurveyQuestionsForm) => {
  //     let radioOptions: any = [];
  //     let sliderMarks: any = [];

  //     if (question) {
  //       const { fieldType, id, fieldName, surveyOptionFields, isRequired } = question;

  //       switch (fieldType) {
  //         case SurveyItemType.DROPDOWN:
  //           radioOptions = [];
  //           question.surveyOptionFields.forEach((option: any) =>
  //             radioOptions.push({
  //               label: option.optionName,
  //               value: option.optionName,
  //             })
  //           );
  //           return (
  //             <Box>
  //               <Typography id="event-name-input" gutterBottom sx={{ color: secondaryFontColor }}>
  //                 {isRequired ? `${fieldName}*` : fieldName}
  //               </Typography>
  //               <PRadioGroup row spacing={4} name={id} options={radioOptions} />
  //             </Box>
  //           );

  //         case SurveyItemType.SLIDER:
  //           sliderMarks = [{ value: 0, label: "0" }];
  //           // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  //           surveyOptionFields &&
  //             surveyOptionFields?.forEach((option: any) => {
  //               sliderMarks.push({
  //                 value: option?.optionName.toString(),
  //                 label: option?.optionName.toString(),
  //               });
  //             });
  //           return (
  //             <Box>
  //               <Typography id="event-name-input" gutterBottom sx={{ color: secondaryFontColor }}>
  //                 {isRequired ? `${fieldName}*` : fieldName}
  //               </Typography>
  //               <Box className="slider">
  //                 <PSlider
  //                   name={id}
  //                   size="medium"
  //                   min={parseInt(surveyOptionFields[0]?.optionName, 10)}
  //                   max={parseInt(
  //                     surveyOptionFields[surveyOptionFields.length - 1]
  //                       ?.optionName,
  //                     10
  //                   )}
  //                   marks={sliderMarks}
  //                   aria-labelledby="size-input-slider"
  //                   valueLabelDisplay="auto"
  //                 />
  //               </Box>
  //             </Box>
  //           );

  //         case SurveyItemType.MULTIPLE:
  //           return (
  //             <Box>
  //               <RHFSelect name={id} label={isRequired ? `${fieldName}*` : fieldName} sx={{
  //                               "& .MuiInputLabel-root": {
  //                                   color: `${secondaryFontColor} !important`
  //                               }
  //                           }}>
  //                 {surveyOptionFields &&
  //                   surveyOptionFields?.map((option: any) => (
  //                     <MenuItem key={option.id} value={option.optionName}>
  //                       {option.optionName}
  //                     </MenuItem>
  //                   ))}
  //               </RHFSelect>
  //             </Box>
  //           );

  //         case SurveyItemType.CHECKBOX:
  //           case SurveyItemType.TERM_AND_CONDITION:
  //           return (
  //             <Box>
  //               <Typography id="event-name-input" gutterBottom sx={{ color: secondaryFontColor }}>
  //                 {isRequired ? `${fieldName}*` : fieldName}
  //               </Typography>
  //               <PCheckbox name={id} label={isRequired ? `${fieldName}*` : fieldName} sx={{ color: secondaryFontColor }}/>
  //             </Box>
  //           );

  //         // case (SurveyItemType.PHONE):
  //         //     return <Box>
  //         //         <PNumberField onChange={(e) => {
  //         //             setValue(`${id}`, e.target.value)
  //         //         }}
  //         //             value={values[question?.id]}
  //         //             name={id}
  //         //             label=      {isRequired? `${fieldName}*` : fieldName} />
  //         //     </Box>

  //         case SurveyItemType.TEXT:
  //         case SurveyItemType.JOBTITLE:
  //         case SurveyItemType.BUSINESS:
  //         case SurveyItemType.PHONE:
  //           return (
  //             <Box>
  //               <RHFTextField
  //                 onChange={(e) => {
  //                   setValue(`${id}`, e.target.value);
  //                 }}
  //                 value={values[question?.id]}
  //                 name={id}
  //                 label={isRequired ? `${fieldName}*` : fieldName}
  //                 sx={{ color: secondaryFontColor }}
  //               />
  //             </Box>
  //           );
  //         default:
  //           return null;
  //       }
  //     }
  //     return true;
  //   },
  //   [values, surveyQuestionsWithAnswers, selectedRoom, surveyPages]
  // );
  const surveyQuestion = useCallback(
    (question: SurveyQuestionsForm) => {
      let radioOptions: any = [];
      let sliderMarks: any = [];

      if (question) {
        const { fieldType, id, fieldName, surveyOptionFields, isRequired } = question;
        switch (fieldType) {
          case SurveyItemType.DROPDOWN:
            radioOptions = [];
            question.surveyOptionFields.forEach((option: any) =>
              radioOptions.push({
                label: option.optionName,
                value: option.optionName,
              })
            );
            return (
              <Box>
                <Typography id="event-name-input" gutterBottom sx={{ color: secondaryFontColor }}>
                  {isRequired ? `${fieldName}*` : fieldName}
                </Typography>
                <PRadioGroup row spacing={4} name={id} options={radioOptions} />
              </Box>
            );

          case SurveyItemType.SLIDER:
            sliderMarks = [{ value: 0, label: "0" }];
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            surveyOptionFields &&
              surveyOptionFields?.forEach((option: any) => {
                sliderMarks.push({
                  value: option?.optionName.toString(),
                  label: option?.optionName.toString(),
                });
              });
            return (
              <Box>
                <Typography id="event-name-input" gutterBottom sx={{ color: secondaryFontColor }}>
                  {isRequired ? `${fieldName}*` : fieldName}
                </Typography>
                <Box className="slider">
                  <PSlider
                    name={id}
                    size="medium"
                    min={parseInt(surveyOptionFields[0]?.optionName, 10)}
                    max={parseInt(
                      surveyOptionFields[surveyOptionFields.length - 1]
                        ?.optionName,
                      10
                    )}
                    marks={sliderMarks}
                    aria-labelledby="size-input-slider"
                    valueLabelDisplay="auto"
                  />
                </Box>
              </Box>
            );

          case SurveyItemType.MULTIPLE:
            return (
              <Box>
                <RHFSelect name={id} label={isRequired ? `${fieldName}*` : fieldName} sx={{
                  "& .MuiInputLabel-root": {
                    color: `${secondaryFontColor} !important`
                  },
                  ...styles
                }}>
                  {surveyOptionFields &&
                    surveyOptionFields?.map((option: any) => (
                      <MenuItem key={option.id} value={option.optionName}>
                        {option.optionName}
                      </MenuItem>
                    ))}
                </RHFSelect>
              </Box>
            );

          case SurveyItemType.CHECKBOX:
          case SurveyItemType.TERM_AND_CONDITION:
            return (
              <Box>
                <Typography id="event-name-input" gutterBottom sx={{ color: secondaryFontColor }}>
                  {isRequired ? `${fieldName}*` : fieldName}
                </Typography>
                <PCheckbox name={id} label={fieldName} sx={{ color: secondaryFontColor }} />
              </Box>
            );

          // case (SurveyItemType.PHONE):
          //     return <Box>
          //         <PNumberField onChange={(e) => {
          //             setValue(`${id}`, e.target.value)
          //         }}
          //             value={values[question?.id]}
          //             name={id}
          //             label={fieldName} />
          //     </Box>

          case SurveyItemType.TEXT:
          case SurveyItemType.JOBTITLE:
          case SurveyItemType.BUSINESS:
          case SurveyItemType.PHONE:
            return (
              <Box>
                <RHFTextField
                  sx={{ color: secondaryFontColor, ...styles }}
                  onChange={(e) => {
                    setValue(`${id}`, e.target.value);
                  }}
                  value={values[question?.id]}
                  name={id}
                  label={isRequired ? `${fieldName}*` : fieldName}
                />
              </Box>
            );
          default:
            return null;
        }
      }
      return true;
    },
    [values, surveyQuestionsWithAnswers, selectedRoom, surveyPages]
  );
  function convertObjectToString(payload: any) {
    Object.keys(payload).forEach((k) => {
      if (payload[k]) {
        if (typeof payload[k] === "object")
          return convertObjectToString(payload[k]);
        payload[k] = `${payload[k]}`;
      } else delete payload[k];
      return true;
    });

    return payload;
  }

  const onSubmit = async (data: any) => {
    if (Object.keys(data).every((x) => data[x] === null)) {
      setShowForm("checkout");
    } else {
      const payload = convertObjectToString(data);
      const submitSurvey = async () => modifySurveyAnswers(userId, payload);
      setSubmitLoading(true);

      if (userId !== "") {
        try {
          await submitSurvey();
          setSubmitLoading(false);
          setShowForm("checkout");
          // setShowMatchUsers(true);
          // setOpenSurveyForm(false);
        } catch (error: any) {
          toast.error(error.message);
          setSubmitLoading(false);
          const pageIds: string[] = Object.keys(questionPerPage);
          setStep(pageIds?.length - 1)
        }
      }
    }
  };

  // click on next step
  const handleNextStep = async (pageIndex: number) => {
    let isValid = false;
    const pageIds: string[] = Object.keys(questionPerPage);
    isValid = await trigger(allPagesQuestionsIds[pageIds[pageIndex]]);
    if (isValid) {
      setStep(step + 1);
    }
  };

  const resetForm = () => {
    sessionStorage.removeItem("userName");
    // changePage(0);
    dispatch({
      type: UserSignUpActionTypes.GO_TO_FIRST_STEP,
    });
    dispatch(resetUserEmail());
  };

  // methods
  // filter common and rooms questions
  useEffect(() => {
    const atleastOneQuestionperPage: boolean[] = [];
    if (surveyPages?.length && surveyQuestionsWithAnswers) {
      // set fields values
      surveyPages.forEach((item: Page) => {
        const { eventSurveyFields } = item;

        if (eventSurveyFields?.length) {
          atleastOneQuestionperPage.push(true);
        } else atleastOneQuestionperPage.push(false);

        eventSurveyFields?.forEach((question: SurveyQuestionsForm) => {
          const value = surveyQuestionsWithAnswers.find(
            (answer: SurveyQuestionwithAnswer) =>
              answer?.userSurveyField?.surveyFieldId === question?.id
          );
          if (value) setValue(question?.id, value?.answer);
          else setValue(question?.id, null);
        });
      });

      // if(atleastOneQuestionperPage.every((item:boolean) => item === false))
      // {
      //   setShowForm("checkout")
      // }
    } else if (!surveyPages?.length) {
      setShowForm("checkout");
    }
  }, [surveyPages, surveyQuestionsWithAnswers]);

  // on last step submit form
  useEffect(() => {
    const pageIds: string[] = Object.keys(questionPerPage);
    if (pageIds?.length && step === pageIds?.length) {
      onSubmit(values);
    }
  }, [step, questionPerPage]);

  // get all page questions Id's in array
  useEffect(() => {
    const allFieldsIds: any = {};
    const onlyRequiredFieldsIds: any = {};

    Object.keys(questionPerPage).forEach(
      (pageId: string, pageIndex: number) => {
        if (questionPerPage[pageId].length) {
          questionPerPage[pageId]?.forEach(
            (questionObj: any, index: number) => {
              // for specific room question
              if (questionObj?.type === "room") {
                const roomsIds = Object.keys(questionObj?.obj);
                if (roomsIds?.length) {
                  setSelectedRoom((prevQuestions: any) => ({
                    ...prevQuestions,
                    [pageId]: {
                      ...prevQuestions[pageId],
                      [index]: roomsIds[0],
                    },
                  }));
                }

                roomsIds.forEach((roomId: string) => {
                  questionObj?.obj[roomId].forEach(
                    (question: SurveyQuestionsForm) => {
                      if (!allFieldsIds[pageId]) {
                        allFieldsIds[pageId] = [];
                      }
                      if (!onlyRequiredFieldsIds[pageId]) {
                        onlyRequiredFieldsIds[pageId] = [];
                      }
                      if (question.isRequired) {
                        onlyRequiredFieldsIds[pageId].push(question?.id);
                      }
                      allFieldsIds[pageId].push(question?.id);
                    }
                  );
                });
              }

              // for all rooms question
              else if (questionObj?.type === "default") {
                questionObj?.obj.forEach((question: SurveyQuestionsForm) => {
                  if (!allFieldsIds[pageId]) {
                    allFieldsIds[pageId] = [];
                  }
                  if (!onlyRequiredFieldsIds[pageId]) {
                    onlyRequiredFieldsIds[pageId] = [];
                  }
                  if (question.isRequired) {
                    onlyRequiredFieldsIds[pageId].push(question?.id);
                  }
                  allFieldsIds[pageId].push(question?.id);
                });
              }
            }
          );
        }

        // if  page is empty
        else {
          if (!allFieldsIds[pageId]) {
            allFieldsIds[pageId] = [];
          }

          if (!onlyRequiredFieldsIds[pageId]) {
            onlyRequiredFieldsIds[pageId] = [];
          }
        }
      }
    );

    setRequiredQuestionsIds(onlyRequiredFieldsIds)
    setAllPagesQuestionsIds(allFieldsIds);
  }, [questionPerPage]);

  useEffect(() => {
    if (open) {
      setIsModalOpen("open");
      if (userId !== "" && !surveyPageLoader)
        getSurveyQuestionsWithAnswers(userId);
    } else {
      setIsModalOpen("closed");
    }
  }, [open, userId, surveyPageLoader]);

  useEffect(() => {
    const output: Output = {};
    // solution 2
    surveyPages.forEach((item, pageIndex) => {
      const pageId = item.id;
      const { eventSurveyFields } = item;

      const pageContent: Output[string] = [];
      let currentRoomId: string | null = null;
      let currentRoomFields: SurveyQuestionsForm[] = [];
      let roomObj: { [key: string]: SurveyQuestionsForm[] } = {};
      let currentDefaultFields: any = [];
      if (eventSurveyFields?.length) {
        eventSurveyFields.forEach((question: any, index: number) => {
          if (question?.room) {
            if (
              currentRoomId === question.room.id &&
              currentDefaultFields.length === 0
            ) {
              currentRoomFields.push(question);
            } else {
              if (currentRoomFields.length > 0) {
                roomObj = { ...roomObj, [currentRoomId!]: currentRoomFields };
                if (
                  Object.keys(roomObj).includes(question?.room?.id) &&
                  currentRoomId !== question?.room?.id
                ) {
                  pageContent.push({
                    type: "room",
                    obj: roomObj,
                    tabs: [currentRoomId!],
                  });
                  roomObj = {};
                  currentRoomFields = [];
                } else {
                  currentRoomFields = [];
                }
              }

              if (currentDefaultFields.length > 0) {
                if (Object.keys(roomObj).length) {
                  pageContent.push({
                    type: "room",
                    obj: roomObj,
                    tabs: [currentRoomId!],
                  });
                  roomObj = {};
                  currentRoomFields = [];
                }
                pageContent.push({
                  type: "default",
                  obj: Object.assign([], currentDefaultFields),
                });
                currentDefaultFields = [];
              }

              currentRoomId = question.room.id;
              currentRoomFields.push(question);
            }
          } else {
            currentDefaultFields.push(question);
          }
        });

        if (currentRoomFields.length > 0) {
          roomObj = { ...roomObj, [currentRoomId!]: currentRoomFields };
          pageContent.push({
            type: "room",
            obj: roomObj,
            tabs: [currentRoomId!],
          });
        }

        if (currentDefaultFields.length > 0) {
          pageContent.push({
            type: "default",
            obj: Object.assign([], currentDefaultFields),
          });
        }
        output[pageId] = pageContent;
      }
    });
    setQuestionPerPage(output);
  }, [surveyPages]);

  const showAttendeeListView = () => (
    <NewEventCard
      step={0}
      onLogout={() => {
        onLogout();
      }}
      onNextStep={() => handleNextStep(0)}
      onPreviousStep={() => setStep(step - 1)}
      logoColor="#FFFFFF"
      bgColor={primaryBackgroundColor}
      stepName="Event Attendee Matches"
      setOpenSurveyForm={setOpenSurveyForm}
      key={1}
      isLoading={isSubmitLoading}
      isMediaExpanded={false}
      mediaURL=""
      stepDescription="Connect and meet with other attendees"
      attendeeCard
      primaryFontColor={primaryFontColor}
      secondaryBackgroundColor={secondaryBackgroundColor}
    >
      {!isSubmitLoading && (
        <MatchAttendeeList
          userId={userId}
          handleRequestMeeting={(attendee) => {
            setMeetingAttendee(attendee);
          }}
        />
      )}
      {isSubmitLoading && (
        <Stack
          sx={{
            direction: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="info" />
          <Typography variant="h6" sx={{ color: "text.secondary", px: 2 }}>
            Submitting Survey Answers
          </Typography>
        </Stack>
      )}
    </NewEventCard>
  );

  const showMeetingListView = () => (
    <NewEventCard
      onLogout={() => {
        onLogout();
      }}
      currentPage={"meetingListView"}
      step={0}
      onNextStep={() => handleNextStep(0)}
      onPreviousStep={() => {
        setMeetingAttendee({});
        setStep(step - 1);
      }}
      logoColor="#FFFFFF"
      bgColor={primaryBackgroundColor}
      stepName="Event Attendee Matches"
      setOpenSurveyForm={setOpenSurveyForm}
      key={1}
      isLoading={isSubmitLoading}
      isMediaExpanded={false}
      mediaURL=""
      stepDescription="Connect and meet with other attendees"
      attendeeCard
      primaryFontColor={primaryFontColor}
      secondaryBackgroundColor={secondaryBackgroundColor}
    >
      {!isSubmitLoading && (
        <MeetingRequestList
          personId={userId}
          meetingAttendee={meetingAttendee}
          setMeetingAttendee={setMeetingAttendee}
        />
      )}
    </NewEventCard>
  );

  const showRegisteredCodeView = () => (
    <NewEventCard
      currentPage="qrCode"
      audienceStatus={audienceStatus}
      onLogout={() => {
        onLogout();
      }}
      step={0}
      onNextStep={() => setShowMatchUsers(true)}
      onPreviousStep={() => setStep(step - 1)}
      logoColor="#FFFFFF"
      bgColor={primaryBackgroundColor}
      stepName={
        event?.type === "invitation" && audienceStatus === "pending"
          ? "Your registration is under review"
          : "You are now registered for this event"
      }
      setOpenSurveyForm={setOpenSurveyForm}
      primaryFontColor={primaryFontColor}
      secondaryBackgroundColor={secondaryBackgroundColor}
      key={1}
      isLoading={isSubmitLoading}
      isMediaExpanded={false}
      mediaURL=""
      pageLength={1}
      stepDescription={
        event?.type === "invitation" && audienceStatus === "pending"
          ? "Your registration is under review"
          : "Go to Next Step to Connect and meet with other attendees"
      }
    >
      <RegisteredCodeForm eventId={eventId!} />
    </NewEventCard>
  );

  useEffect(() => {
    const allPages = Object.keys(questionPerPage)
    const pageId = allPages[step]
    if (pageId) {
      const questionFilledPerPage: any = []
      if (Object.keys(requiredQuestionsIds).length) {
        if (requiredQuestionsIds[pageId]?.length) {
          requiredQuestionsIds[pageId].forEach((questionName: string) => {
            const isFilled = values[questionName] !== undefined && values[questionName] !== "" && values[questionName] !== null
            if (isFilled) {
              questionFilledPerPage.push(true);
            }
            else {
              questionFilledPerPage.push(false);
            }
          })
        }

        else {
          questionFilledPerPage.push(true);
        }
      }

      if (questionFilledPerPage.every((filled: boolean) => filled)) {
        setRequiredQuestionsFilled(true)
      }
      else {
        setRequiredQuestionsFilled(false)
      }
    }
  }, [values])

  return surveyPageLoader ? (
    <Stack
      sx={{
        direction: "column",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress color="info" />
    </Stack>
  ) : (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {(showMatchUsers || isSubmitLoading) &&
        !meetingAttendee?.userId &&
        showAttendeeListView()}
      {showMatchUsers && meetingAttendee?.userId && showMeetingListView()}
      {/* {!showMatchUsers &&
        !showForm &&
        !Object.keys(questionPerPage)?.length &&
        showEmptyPageView()} */}
      {!showMatchUsers &&
        !showForm &&
        Object.keys(questionPerPage).map(
          (page: any, _pageIndex: number) =>
            step === _pageIndex && (
              <NewEventCard
                requiredFieldsFilled={requiredSurveyQuestionsFilled}
                currentPage="surveyForm"
                setOpenSurveyForm={setOpenSurveyForm}
                key={_pageIndex}
                step={step}
                isLoading={surveyAnsLoader}
                onNextStep={() => handleNextStep(_pageIndex)}
                onLogout={() => {
                  onLogout();
                }}
                onPreviousStep={() => setStep(step - 1)}
                logoColor="#FFFFFF"
                primaryFontColor={surveyPages[_pageIndex]?.fontColor}
                // secondaryBackgroundColor={surveyPages[_pageIndex]?.secondaryBackgroundColor}
                secondaryBackgroundColor={secondaryBackgroundColor}
                bgColor={surveyPages[_pageIndex]?.backgroundColor}
                isMediaExpanded={surveyPages[_pageIndex]?.isMediaExpanded}
                stepName={surveyPages[_pageIndex]?.name}
                mediaURL={surveyPages[_pageIndex]?.media}
                stepDescription={surveyPages[_pageIndex]?.description}
                pageLength={Object.keys(questionPerPage).length}
                questionLength={questionPerPage[page]?.length}
              >
                {!surveyAnsLoader ? (
                  !questionPerPage[page]?.length ? (
                    <Stack
                      sx={{
                        direction: "row",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button onClick={() => { }}>
                        No Questions on this page
                      </Button>
                    </Stack>
                  ) : (
                    questionPerPage[page]?.map(
                      (questiontype: any, index: number) => {
                        switch (questiontype?.type) {
                          case "default":
                            return (
                              <div key={index}>
                                {index !== 0 && (
                                  <Divider
                                    sx={{ boder: "1px", borderStyle: "dashed" }}
                                  />
                                )}
                                {/*  default questions */}
                                {questiontype?.obj?.map((question: any) => (
                                  <Stack
                                    direction="column"
                                    spacing={5}
                                    sx={{ p: 10, py: 2 }}
                                    key={question?.id}
                                  >
                                    {surveyQuestion(question)}
                                  </Stack>
                                ))}
                              </div>
                            );

                          case "room":
                            return (
                              <div key={index}>
                                <Stack
                                  direction="row"
                                  sx={{ p: 2 }}
                                  key={index}
                                >
                                  {Object.keys(questiontype?.obj).map(
                                    (i: any) => (
                                      <Button
                                        key={i}
                                        style={{
                                          flexBasis: "100%",
                                          borderRadius: "5px",
                                          background:
                                            selectedRoom?.[page]?.[index] === i
                                              ? surveyPages[_pageIndex]
                                                ?.backgroundColor
                                              : "white",
                                          color:
                                            selectedRoom?.[page]?.[index] === i
                                              ? "white"
                                              : "black",
                                        }}
                                        onClick={() => {
                                          if (page)
                                            setSelectedRoom(
                                              (prevQuestions: any) => ({
                                                ...prevQuestions,
                                                [page]: {
                                                  ...prevQuestions[page],
                                                  [index]: i,
                                                },
                                              })
                                            );
                                        }}
                                      >
                                        {questiontype?.obj[i][0]?.room?.name}
                                      </Button>
                                    )
                                  )}
                                </Stack>

                                {selectedRoom?.[page]?.[index] &&
                                  questiontype?.obj[
                                    selectedRoom?.[page]?.[index]
                                  ]?.map((question: SurveyQuestionsForm) => (
                                    <Stack
                                      direction="column"
                                      spacing={5}
                                      sx={{ p: 10, py: 2 }}
                                      key={question?.id}
                                    >
                                      {surveyQuestion(question)}
                                    </Stack>
                                  ))}
                              </div>
                            );
                          default:
                            return null;
                        }
                        return false;
                      }
                    )
                  )
                ) : (
                  <Stack
                    sx={{
                      direction: "column",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="info" />
                  </Stack>
                )}
              </NewEventCard>
            )
        )}

      {!showMatchUsers && showForm === "checkout" && showRegisteredCodeView()}
    </FormProvider>
  );
}

export default SurveyAnswerForm;
