import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { IPoll, IPollAnswer } from '../../models/poll/poll';
import { EventsService } from '../../services/events';

export type LivePollState = {
  errorMessage: string;
  livePolls: any;
  liveVoteResult: any;
  loading: boolean;
  isPollAppeared:boolean;
};

const initialState: LivePollState = {
  errorMessage: '',
  loading: false,
  livePolls: null,
  liveVoteResult: null,
  isPollAppeared:false,
};

type Answer = {
  id: string, 
  answer: string, 
  ordering: number, 
  percent: string, 
  voteHistories: [], 
  votes: number
};

export const livePollSlice = createSlice({
  name: 'livePoll',
  initialState,
  reducers: {
    setLivePoll: (state, action: PayloadAction<any>) => {

      let pollObj: IPoll = {id: "", question: "", status: "", expireOption: 0, expiredAt: "", answers: [], isInteractive: true}
      let pollObjAnswer: IPollAnswer[] = [];
      if (action.payload.answers) {
      action.payload.answers.forEach((element: any) => {
        let answer1:  IPollAnswer = {id: "", answer: "", ordering: 0, percent: "0.0000", voteHistories: [], votes: 0 ,isVoted:0};
        answer1.id = element.id;
        answer1.answer = element.answer;
        answer1.ordering = element.ordering;
        answer1.percent = "0.0000";
        answer1.voteHistories = [];
        answer1.votes = 0;
        answer1.isVoted=element?.isVoted || 0;
        pollObjAnswer.push(answer1);
      });
      pollObj = {...action.payload , answers: pollObjAnswer};
      }
      state.livePolls = pollObj;
    },

    newLivePoll: (state, action: PayloadAction<any>) => {
      // if (state.livePolls) {
      //   const existingPoll = state.livePolls.find(
      //     (req: any) => req.id === action.payload.instance.id
      //   );
      //   if (!existingPoll) {
      //     state.livePolls.push(action.payload.instance);
      //   }
      // }
      // else {
      let pollObj: IPoll;
      let pollObjAnswer: IPollAnswer[] = [];
   
      action.payload.instance.answers.forEach((element: any) => {
        let answer1:  IPollAnswer = {id: "", answer: "", ordering: 0, percent: "0.0000", voteHistories: [], votes: 0,isVoted:0};
        answer1.id = element.id;
        answer1.answer = element.answer;
        answer1.ordering = 0;
        answer1.percent = "0.0000";
        answer1.voteHistories = element?.answerVotes || [];
        answer1.isVoted=element?.isVoted || 0;
        answer1.votes = 0;
        pollObjAnswer.push(answer1);
      });
      pollObj = {...action.payload.instance , answers: pollObjAnswer};
      state.livePolls = pollObj;
      // }
      state.isPollAppeared=true;
    },

    setLivePollByAnswerResult: (state, action: PayloadAction<any>) => {
      let pollObj: IPoll = {id: "", question: "", status: "", expireOption: 0, expiredAt: "", answers: [], isInteractive: true}
      let pollObjAnswer: IPollAnswer[] = [];
      if (action.payload.answers) {
      action.payload.answers.forEach((element: any) => {
        let answer1:  IPollAnswer = {id: "", answer: "", ordering: 0, percent: "0.0000", voteHistories: [], votes: 0,isVoted:0};
        answer1.id = element.id;
        answer1.answer = element.answer;
        answer1.ordering = element.ordering;
        answer1.percent = element?.votePercent || element?.percent ;
        answer1.voteHistories = element?.answerVotes || [];
        answer1.isVoted=element?.isVoted || 0;
        answer1.votes = element.votes;
        pollObjAnswer.push(answer1);
      });
      pollObj = {...action.payload , answers: pollObjAnswer};
      }
      state.livePolls = pollObj;
    },
  
    setLiveVoteResult: (state, action: PayloadAction<any>) => {
      state.liveVoteResult = action.payload;
    },
  }
});

export const {
  setLivePoll,
  newLivePoll,
  setLivePollByAnswerResult,
  setLiveVoteResult,
} = livePollSlice.actions;

export const getPolls = (eventId: string, sessionId: string) => async (dispatch: Dispatch) => {
  if(!eventId || eventId==='undefined' || !sessionId || sessionId==='undefined') return;
  const eventService = new EventsService();
    const result = await eventService.getPollEvents(eventId, sessionId).then((res) => {
      dispatch(setLivePoll(res.data))
    }).catch((err) => {
      dispatch(setLivePoll([]))
    });
};

export const castLiveVote =
  (eventId: string, sessionId: string, pollId: string, answerId: string) => async (dispatch: Dispatch) => {
    const eventService = new EventsService();
    const result = await eventService.votePollEvents(eventId, sessionId, pollId, answerId);
    dispatch(setLiveVoteResult(result));
  };

  export const removeLiveVote =
  (eventId: string, sessionId: string, pollId: string, answerId: string) => async (dispatch: Dispatch) => {
    const eventService = new EventsService();
    const result = await eventService.removeVotePollEvents(eventId, sessionId, pollId, answerId);
    // dispatch(setLiveVoteResult(result));
  };

export const getPollsByAnswerResult = (eventId: string, sessionId: string, pollId: string) => async (dispatch: Dispatch) => {
    const eventService = new EventsService();
      const result = await eventService.getPollEventsByAnswerResult(eventId, sessionId, pollId);
      dispatch(setLivePollByAnswerResult(result.data));
};