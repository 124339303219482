import React from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

// Utility function to clean the HTML content
const cleanHtmlContent = (htmlContent: string) => {
  // Create a temporary DOM element to manipulate the content
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlContent;

  // Remove 'list-style: none' class from all 'ul' elements
  const ulElements = tempDiv.querySelectorAll('ul');
  const olElements = tempDiv.querySelectorAll('ol');
  ulElements.forEach((ul) => {
    ul.classList.remove('list-style-none');
    // Add custom CSS class
    ul.classList.add('parseCustom-ul');
  });
  olElements.forEach((ol) => {
    ol.classList.remove('list-style-none');
    // Add custom CSS class
    ol.classList.add('parseCustom-ol');
  });

  return tempDiv.innerHTML;
};

type ParseContentProps = { content?: string | null, className?: string };

export const AccordionParseContent: React.FC<ParseContentProps> = ({ content, className }) => {
  // Clean the content before parsing
  const cleanedContent = content ? cleanHtmlContent(DOMPurify.sanitize(content)) : '';

  return (
    <div className={className}>
      {cleanedContent && parse(cleanedContent)}
    </div>
  );
};
