export enum UserSignUpActionTypes {
  RESET_SIGNUP_USER = "reset_SIGNUP_USER",
  SIGNUP_USER_IN_PROGRESS = "SIGNUP_USER_IN_PROGRESS",
  SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS",
  SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE",
  SIGNUP_USER_ERROR = "SIGNUP_USER_ERROR",
  GO_TO_NEXT_STEP = "GO_TO_NEXT_STEP",
  GO_TO_BACK_STEP = "GO_TO_BACK_STEP",
  GO_TO_FIRST_STEP = "GO_TO_FIRST_STEP",
  GO_TO_LAST_STEP = "GO_TO_LAST_STEP",
  SET_EMAIL_PASSWORD = "SET_CURRENT_EMAIL_PASSWORD",
  SET_FIRSTNAME_LASTNAME = "ET_CURRENT_FIRSTNAME_LASTNAME",
  SET_CITY_COUNTRY_POSTCODE = "SET_CITY_COUNTRY_POSTCODE",
  SET_JOBTITLE_COMPANY_INDUSTRY = "SET_JOBTITLE_COMPANY_INDUSTRY",
  SET_OFFERLIST_INTERESTEDLIST = "SET_OFFERLIST_INTERESTEDLIST",
  SET_STATIC_REGISTRATION_FIELDS = "SET_STATIC_REGISTRATION_FIELDS",
  SET_DYNAMICALLY_REGISTRATION_FIELDS = "SET_DYNAMICALLY_REGISTRATION_FIELDS",
}
