import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  expoEndMeeting, expoGuestCancel, expoManagerAccept, expoManagerReject, expoReset
} from 'src/store/expo/actions';
import { IAppState } from 'src/store';
import { CallingNotification } from 'src/components/call-notification';
import { ChimeVideoCall } from 'src/components/chime-video-call';

interface IExpoVideoCall {
  children: JSX.Element
}

export const VideoCall = ({ children }: IExpoVideoCall) => {
  // Reading redux store
  const expoCall = useSelector((state: IAppState) => state.chime.expoCall);
  const expoGuestCall = useSelector((state: IAppState) => state.chime.guestCall);
  const expoChimeInvitation = useSelector((state: IAppState) => state.chime.chimeInvitation);
  const expoError = useSelector((state: IAppState) => state.chime.error);
  const dispatch = useDispatch();
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const CALL_TIMEOUT = 30;
  // const meetingStatus: MeetingStatus = useMeetingStatus();
  // Actions based on Video status
  // switch (MeetingStatus[meetingStatus]) {
  //   case 'Loading':
  //     break;
  //   case 'Succeeded':
  //     break;
  //   case 'Ended':
  //   case 'Left':
  //     break;
  //   default:
  // }

  // On Error show notification. "No Manager available"
  if (expoError?.callId) {
    return (
      <CallingNotification
        title={expoError.message}
        onRejectCall={() => { dispatch(expoReset()); }}
        rejectBtnText="Ok"
        className="absolute"
      />
    );
    // On Chime invitation call.
  } else if (expoChimeInvitation?.callId) {
    return <ChimeVideoCall onCallHangup={() => dispatch(expoEndMeeting())} />;

    // On guest calling
  } else if (expoGuestCall?.callId) {
    return (
      <CallingNotification
        title="Connecting to booth Manager"
        onRejectCall={() => { dispatch(expoGuestCancel()); }}
        rejectBtnText="Cancel"
        timer={CALL_TIMEOUT}
        onTimeout={() => { dispatch(expoGuestCancel()); }}
        avatar={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${expoCall?.guestUser.avatar!}`}
        className="absolute"
      />
    );
    //   Else: Expo Manager receive call
  } else if (expoCall?.callId) {
    return (
      <CallingNotification
        title={`${expoCall?.guestUser?.firstName} is visiting your booth `}
        acceptBtnText={`${isCallAccepted ? 'Waiting' : 'Accept'}`}
        timer={CALL_TIMEOUT}
        onTimeout={() => dispatch(expoManagerReject())}
        onAcceptCall={() => { setIsCallAccepted(true); dispatch(expoManagerAccept()); }}
        disableAcceptBtn={isCallAccepted}
        onRejectCall={() => { dispatch(expoManagerReject()); }}
        avatar={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${expoCall?.guestUser.avatar!}`}
        className="absolute"
      />
    );
  }
  // eslint-disable-next-line no-unused-expressions
  isCallAccepted && setIsCallAccepted(false);
  return children;
};
