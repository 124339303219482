// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';

// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  className?: string;
  label?: string;
  helperText?: React.ReactNode;
  placeholder?: string;
  onChange?: (data: any) => void;
  hideEndAdornment?: boolean;
  defaultFormValue?: any;
}

export default function PUserAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  className,
  label,
  helperText,
  placeholder,
  sx,
  onChange,
  hideEndAdornment,
  defaultFormValue,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          // onChange={(_, newValue) => field.onChange(newValue)}
          placeholder={placeholder}
          value={defaultFormValue || field?.value || []}
          onChange={(_, newValue) => {
            field.onChange(newValue); // Update the form control
            if (onChange) {
              onChange(newValue); // Call the parent component's onChange function
            }
          }}
          renderInput={(params) => (
            <TextField
              sx={sx}
              className={className}
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
              inputProps={{
                ...params.inputProps,
                endAdornment: hideEndAdornment ? null : params.InputProps.endAdornment,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                },
              }}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
