import {
  SetStateAction, useCallback, useEffect, useState
} from 'react';
import classNames from 'classnames';
import {
  useDispatch,
} from 'react-redux';
import { useForm } from 'react-hook-form';

import { Connection } from '../../../models/connection';
import { searchParticipant, UserSearchResult } from '../../../store/user/search-user';
import { IEditCompanyProfile } from '../../../models/company/company-profile-edit';
import { IReactSelectOption } from '../../../models/react-select-option';
import { IReactSelectCustomOption } from '../../../models/react-select-custom-option';
import { IUserProfile } from '../../../models/user-profile';
import { CompanyService, UserService } from '../../../services';
import { CardPeopleNetwork } from '../../../shared-components/V2';
import { getCompanyProfilePreview } from '../../../store/company/profile-preview/actions';
import MultiSelectCustomDropdown from '../../../shared-components/V2/MultiSelectCustomDropdown/MultiSelectCustomDropdown';

interface ICompanyAdminsDialogProps {
  // eslint-disable-next-line react/no-unused-prop-types
  companyEmployees: Connection[];
  companyID: string;
  onCloseAdminModal?: Function;
}

const style = {
  menu: (css: any) => ({
    ...css,
    border: 0,
    width: '100%',
  }),
  control: (css: any) => ({
    ...css,
    display: 'inline-flex',
    width: '100%',
    borderRadius: '10px'
  }),
  valueContainer: (css: any) => ({
    ...css,
    width: '100%',
  }),
};

export const CompanyAdminsDialog = (props: ICompanyAdminsDialogProps) => {
  const userService: UserService = new UserService();
  const companyService: CompanyService = new CompanyService();
  const { register } = useForm<IEditCompanyProfile>();
  const dispatch = useDispatch();
  const [searchUser, setSearchUser] = useState('');
  const [selectedCompanyAdmins, setSelectedCompanyAdmins] = useState<IReactSelectOption[]>([]);
  const [admins, setAdmins] = useState<IReactSelectOption[]>();
  const [people, setPeople] = useState<IUserProfile[]>([]);
  const [currentAdminsList, setCurrentAdminsList] = useState([]);
  const [currentAdminRequestsList, setCurrentAdminRequestsList] = useState([]);
  const [activeTab, setActiveTab] = useState('tab1');
  const [userConsent, setUserConsent] = useState<boolean>(false);
  // const {
  //   companyProfilePreview,
  // } = useSelector((state: IAppState) => state);

  useEffect(() => {
    dispatch(getCompanyProfilePreview(props.companyID));
  }, [dispatch, props.companyID]);

  useEffect(() => {
    dispatch(searchParticipant(searchUser));
  }, [dispatch, searchUser]);

  useEffect(() => {
    const usersData: IReactSelectCustomOption[] = [];
    if (people) {
      people.map((user, index) => usersData.push({
        key: `employee-option-${index}`,
        value: user.id,
        label: user.fullName ?? `${user.firstName} ${user.lastName}`,
        name: user.fullName ?? `${user.firstName} ${user.lastName}`,
        avatar: user.avatar,
        designation: user.jobTitle,
        email: user.email
      }));
    }
    setAdmins(usersData);
  }, [people]);

  const handleCompanyAdminSelected = useCallback((selectedOptions: any) => {
    const selectedObject: any = {
      key: selectedOptions.key,
      value: selectedOptions.value,
      label: selectedOptions.label,
      email: selectedOptions.email
    };

    const adminsSelectedArray = [...selectedCompanyAdmins || [], selectedObject];

    adminsSelectedArray.forEach(
      (option: IReactSelectOption) => selectedCompanyAdmins?.indexOf(option) === -1
        && setSelectedCompanyAdmins([...selectedCompanyAdmins, option])
    );
  }, [selectedCompanyAdmins]);

  const handleCompanyAdminSearch = useCallback((selectedOptions: any) => {
    setSelectedCompanyAdmins(selectedOptions);
  }, []);

  const getCurrentAdmins = async (id: string) => {
    const result: any = await companyService.getCurrentAdmins(id);
    setCurrentAdminsList(result.data);
  };

  const getCompanyAdminRequests = async (id: string) => {
    try {
      const result: any = await companyService.getCompanyAdminRequests(id);
      setCurrentAdminRequestsList(result.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const removeCompanyAdministrator = async (companyId: string, adminId: string) => {
    const result: any = await companyService.removeCompanyAdministrator(companyId, adminId);
    return result;
  };

  const rejectCompanyAdminRequest = async (companyId: string, requestId: string) => {
    await companyService.grantAdministratorPrivilege(companyId, requestId, {
      privilege: 'denied'
    });
  };

  const acceptCompanyAdminRequest = async (companyId: string, requestId: string) => {
    await companyService.grantAdministratorPrivilege(companyId, requestId, {
      privilege: 'granted'
    });
  };

  const getUsers = async () => {
    const limit = 250;
    const result: UserSearchResult = await userService.getSearchUserResults(`${searchUser ?? ''}`, limit);
    setPeople([...result?.data?.users?.data]);
  };

  useEffect(() => {
    getCurrentAdmins(props.companyID);
    getCompanyAdminRequests(props.companyID);
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchUser]);

  useEffect(() => {
    getCurrentAdmins(props.companyID);
    getCompanyAdminRequests(props?.companyID);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleTab = (avtiveTab: string) => {
    setActiveTab(avtiveTab);
  };

  const removeCompanyAdmin = (id: string | undefined) => {
    if (id) {
      removeCompanyAdministrator(props.companyID, id).then(() => {
        getCurrentAdmins(props.companyID);
      });
    }
  };

  const onSaveAdmins = () => {
    const adminList: any[] = [];

    currentAdminsList.map((admin: any) => adminList.push(admin?.user?.email));
    selectedCompanyAdmins.map((admin: any) => adminList.push(admin?.email));

    if (props.companyID) {
      companyService.editCompanyAdmins(props.companyID, {
        admins: adminList
      }).finally(() => {
        setSelectedCompanyAdmins([]);
        getCurrentAdmins(props.companyID);
        setUserConsent(false);
      });
    }
  };

  const getCompanyAdmin = () => {
    const admins: any[] = currentAdminsList.filter((admin: any) => admin.privilege === 'granted') ?? [];
    return admins;
  };

  return (
    <div className="flex flex-col sm:px-8 px-4 py-3">
      <div className="text-blue-4 text-[18px] font-semibold">
        Company Admins
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row mt-2">
          <div
            className={classNames('text-[#0B1221] text-sm3 cursor-pointer leading-[22px] mr-4', {
              'font-semibold': activeTab === 'tab1',
              'font-normal': activeTab !== 'tab1'
            })}
            onClick={() => handleTab('tab1')}
          >
            Current admins
          </div>
          <div
            className={classNames('text-[#0B1221] text-sm3 cursor-pointer leading-[22px]', {
              'font-semibold': activeTab === 'tab2',
              'font-normal': activeTab !== 'tab2'
            })}
            onClick={() => handleTab('tab2')}
          >
            <span className="flex items-center">
              Admin requests
              <span className="w-5 h-5 ml-1 rounded-full bg-[#FD8C26] text-sm font-semibold text-[#0B1221] flex items-center justify-center">{currentAdminRequestsList?.length ?? 0}</span>
            </span>
          </div>
        </div>
        <div className="mt-1">
          <div className=" border-b-1 border-[#707070] w-full opacity-[28%]" />
        </div>
        {
          activeTab === 'tab1' && (
            <div className="w-full">
              {currentAdminsList && currentAdminsList.length > 0
                && currentAdminsList.map((item: any) => (
                  (item.privilege === 'granted')
                  && (
                    <div className="mt-4 w-full">
                      <CardPeopleNetwork
                        containerClass="w-full h-57"
                        avatarUrl={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.user?.avatar}`}
                        avatarSize="MEDIUM"
                        title={item?.user?.fullName}
                        titleClass="text-[#172746] text-[16px] font-semiBold leading-5"
                        subTitle={item?.user?.jobTitle}
                        subTitleClass="text-[#3C3C3C] text-[14px] font-light leading-5"
                        detailsHref={item.id}
                        secondaryButton={`rounded-5 h-8 w-24 px-5 py-1.5 text-white font-semiBold text-sm ${getCompanyAdmin().length > 1 ? 'bg-[#0049EF] hover:bg-[#244E93]' : 'bg-gray-400'}`}
                        secondaryButtonText="Remove"
                        {...(getCompanyAdmin().length > 1 && {
                          onClickSecondary: removeCompanyAdmin
                        }
                        )}
                      />
                    </div>
                  )
                ))}
            </div>
          )
        }
        {
          activeTab === 'tab2' && (
            <div className="w-full">
              {currentAdminRequestsList?.map((item: any) => (
                <div className="mt-4 w-full">
                  <CardPeopleNetwork
                    containerClass="w-full h-57"
                    avatarUrl={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.user?.avatar}`}
                    avatarSize="MEDIUM"
                    title={`${item?.user?.fullName}`}
                    titleClass="text-[#172746] text-[16px] font-semiBold leading-5"
                    subTitle={item?.user?.jobTitle}
                    subTitleClass="text-[#3C3C3C] text-[14px] font-light leading-5"
                    secondaryButton="rounded-[5px] h-[30px] w-24 px-5 py-1.5 bg-[#EDF2F5] hover:bg-blue-0 text-[#203C6E] text-[13px] font-semibold"
                    secondaryButtonText="Decline"
                    primaryButton="rounded-[5px] h-[30px] w-24 px-5 py-1.5 bg-[#0049EF] hover:bg-[#2e6eff] text-white text-[13px] font-semibold mr-4"
                    primaryButtonText="Accept"
                    onClickSecondary={() => {
                      rejectCompanyAdminRequest(item?.company?.id, item?.id);
                      setTimeout(() => {
                        getCompanyAdminRequests(props?.companyID);
                        getCurrentAdmins(props.companyID);
                      }, 500);
                    }}
                    onClickPrimary={() => {
                      acceptCompanyAdminRequest(item?.company?.id, item?.id);
                      setTimeout(() => {
                        getCompanyAdminRequests(props?.companyID);
                        getCurrentAdmins(props.companyID);
                      }, 500);
                    }}
                  />
                </div>
              ))}
              {!currentAdminRequestsList.length
                && <div className="p-6 text-md font-semibold flex justify-center text-gray-400">No admin requests.</div>}
            </div>
          )
        }
        <div className="mt-1 mb-4">
          <div className=" border-b-1 border-[#707070] w-full opacity-[28%]" />
        </div>
        {activeTab === 'tab1' && (
          <>
            <div className="text-[#3C3C3C] text-[11px] leading-4 font-medium">
              Admins
            </div>
            <div className="mt-1 w-full">
              <MultiSelectCustomDropdown
                options={admins}
                onInputChange={(a: SetStateAction<string>) => {
                  setSearchUser(a);
                }}
                isClearable
                isMulti
                // createOptionPosition="last"
                openMenuOnClick
                {...register('companyAdmin')}
                noOptionsMessage="No result found"
                value={selectedCompanyAdmins}
                onClick={(e: any) => handleCompanyAdminSelected(e)}
                onChange={(e: any) => handleCompanyAdminSearch(e)}
                styles={style}
              />
            </div>
            <div className="text-[#203C6E] text-x-sm leading-5 font-light mt-1">
              Add a name or another admins email address if they are not a member on
              Panelist
            </div>
            <div className="flex flex-row mt-3">
              <div className="bg-white border-1 rounded-[5px] border-blue-0 w-[14px] h-[14px] flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-0">
                <input
                  checked={userConsent}
                  type="checkbox"
                  className="opacity-0 absolute"
                  onClick={evt => {
                    setUserConsent(evt.currentTarget.checked);
                  }}
                />
                <svg
                  className="fill-current hidden w-[12px] h-[12px] text-blue-3 pointer-events-none"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
              </div>
              <div className=" text-xs text-[#203C6E] font-light leading-4 w-full">
                I verify that I am an authorised representative of this organisation
                and have the right to act on its behalf in the creation and
                management of this page.
              </div>
            </div>
            <div className="my-4">
              <div className=" border-b-1 border-[#707070] w-full opacity-[28%]" />
            </div>
          </>
        )}
        {
          activeTab === 'tab1' && (
            <div className="flex justify-end gap-3">
              <button
                type="button"
                className="bg-[#EDF2F5] hover:bg-[#d8e0e5] w-[88px] px-2 h-[30px] rounded-5 text-sm leading-5 text-dark-blue font-semibold"
                onClick={() => props.onCloseAdminModal && props.onCloseAdminModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className={classNames('rounded-5 px-2 h-[30px] w-[142px] text-sm leading-5 text-white font-semibold', {
                  'bg-gray-400': !userConsent,
                  'bg-[#0031FF] hover:bg-dark-blue-3': userConsent
                })}
                onClick={onSaveAdmins}
                disabled={!userConsent}
              >
                Save & Publish
              </button>
            </div>
          )
        }
      </div>
    </div>
  );
};
