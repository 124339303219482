import {
  Box,
  CardMedia,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { fileThumb } from "src/mui/components/file-thumbnail";
import SessionHandOutDownloadIcon from "src/mui/pages/lenovo-website/Images/SessionHandOutDownloadIcon";
import { getBrandingColors } from '../event/live/liveEventStyling';

type HandoutProps = {
  expoHandouts: any;
  isSponsorHandouts?: boolean;
};

const mediaStyle = {
  maxWidth: "100%",
  height: "100%",
  width: "100%",
  my: "auto",
  margin: "auto",
  objectFit: "contain",
  border: "1px solid #262626",
  borderRadius: "2px",
};

const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;

export const SessionHandout = ({ expoHandouts, isSponsorHandouts }: HandoutProps) => {
  const [downloadingFileUrl, setDownloadingFileUrl] = useState<string | null>(null);
  const colors = getBrandingColors();
  const groupHandoutsBySession = (handouts: any[]) => {
    return handouts?.reduce((acc: any, handout: any) => {
      const sessionTitle = handout?.eventSession?.title;
      if (!acc[sessionTitle]) {
        acc[sessionTitle] = [];
      }
      acc[sessionTitle].push(handout);
      return acc;
    }, {});
  };

  const downloadHandout = async (url: string | undefined) => {

    if (url) {
      setDownloadingFileUrl(url);
      try {
        const response = await fetch(`${imageUrlPrefix}/${url}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const link = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = url.split("/").pop() || "download";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
        toast.success('File downloaded successfully!');
        setDownloadingFileUrl(null);
      } catch (error) {
        console.error("Download failed:", error);
        toast.error(`Download failed: ${error}`);
        setDownloadingFileUrl(null);
      }
    } else {
      console.error("Download URL is undefined");
      setDownloadingFileUrl(null);
    }
  };

  const groupedHandouts = groupHandoutsBySession(expoHandouts);

  const showSessionHandsOut = (handout: any, index: number) => {
    return (
      <>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          key={index}
          sx={{
            height: "43px",
            minHeight: "43px",
            borderRadius: "5px",
            backgroundColor: isSponsorHandouts ? '' : colors?.Secondary || "#2F2F2F",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingRight: "8.5px",
          }}
        >
          <Stack sx={{
            width: "85%"
          }} direction="row" alignItems="center">
            <Stack
              sx={{
                ml: "5px",
                mr: "7px",
                borderRadius: "2px",
                minWidth: "26px",
                height: "34px",
                maxWidth: '27px',
                overflow: "hidden",
              }}
            >
              {handout?.type === "image" ? (
                <CardMedia
                  sx={mediaStyle}
                  component="img"
                  image={`${imageUrlPrefix}/${handout?.url}`}
                />
              ) : handout?.type === "document" ? (
                <Box
                  component="img"
                  src={fileThumb("pdf")}
                  sx={{
                    width: 26,
                    height: 34,
                    flexShrink: 0,
                    objectFit: "contain",
                    border: "1px solid #262626",
                    borderRadius: "2px",
                  }}
                />
              ) : (
                <></>
              )}
            </Stack>

            <Tooltip title={handout?.name}>
              <Typography
                className="ellipsis-two-line"
                sx={{
                  pr: "10px",
                  fontSize: "12px",
                  fontFamily: "Poppins-500",
                  color: colors?.Text || "#ffffff",
                  lineHeight: "16px",
                }}
              >
                {handout?.name}
              </Typography>
            </Tooltip>
          </Stack>
          {downloadingFileUrl === handout?.url ? (
            <IconButton
            sx={{
              cursor: "pointer",
              p: 0
            }}
          >
            <CircularProgress color="info" size={20} />
          </IconButton>
          ) : (
            <IconButton
              sx={{
                cursor: "pointer",
                p: 0
              }}
              onClick={() => downloadHandout(handout?.url)}
            >
              <SessionHandOutDownloadIcon style={{ fill: colors?.Text || "#ffffff" }} />
            </IconButton>
          )}

        </Stack>

        {index !== expoHandouts?.length - 1 && (
          <Divider
            sx={{
              borderColor: "#ffffff",
              width: "100%",
              opacity: "19%",
              mt: "5px",
              mb: "5px",
            }}
          />
        )}
      </>
    );
  };

  const groupedHandoutsLength = groupedHandouts ? Object.keys(groupedHandouts).length : 0;

  const renderGroupedHandouts = () => {
    return groupedHandouts && Object.keys(groupedHandouts)?.map((sessionTitle) => (
      <Box key={sessionTitle} sx={{ width: "100%" }}>
        <Tooltip title={sessionTitle}>
          <Typography
            // variant="h6"
            sx={{
              color: colors?.Text || "#ffffff",
              mb: sessionTitle !== "undefined" ? '10px' : '',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'Poppins-500',
              lineHeight: '19px'
            }}
          >
            { sessionTitle !== "undefined" ? sessionTitle || "" : null}
          </Typography>
        </Tooltip>
        {groupedHandouts[sessionTitle].map((handout: any, index: any) =>
          <>
            {showSessionHandsOut(handout, index)}
            {/* <Divider
              sx={{
                borderColor: "#ffffff",
                width: "100%",
                opacity: "19%",
                mt: "5px",
                mb: "5px",
              }}
            /> */}
          </>
          
        )}
      </Box>
    ));
  };

  return (
    <Stack
      sx={{
        overflowY: "auto",
        flexFlow: "wrap",
        alignItems: "start",
        mt: "16px",
        marginRight: isSponsorHandouts ? groupedHandoutsLength > 8 ? "3px" : "0px" : "0px",
        paddingRight: isSponsorHandouts ? groupedHandoutsLength > 8 ? "14px" : "0px" : "0px",
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `${getBrandingColors().Accent}`,
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: `${getBrandingColors().Accent}`,
          opacity: 0.5,
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: colors?.Secondary,
        },
      }}
      className='branding-scroll'
    >
      {renderGroupedHandouts()}
    </Stack>
  );
};

export default SessionHandout;
