import { useRef, useState } from "react";
import {
  CardMedia,
  IconButton,
  Popper,
  Stack,
  Typography,
  Box,
  Tooltip,
  Divider,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import { SessionHandoutsIcon } from "src/mui/assets/icons/session-handouts";
import SessionHandOutDownloadIcon from "src/mui/pages/lenovo-website/Images/SessionHandOutDownloadIcon";
import { imageUrlPrefix } from "src/utils";
import { fileThumb } from "src/mui/components/file-thumbnail";
import { getBrandingColors } from '../liveEventStyling';

const mediaStyle = {
  maxWidth: "100%",
  height: "100%",
  width: "100%",
  my: "auto",
  margin: "auto",
  objectFit: "contain",
  border: "1px solid #262626",
  borderRadius: "2px",
};
interface IMiniSpotlight {
  companyId?: string;
  eventId?: string;
  slug?: string;
  sessionTime?: any;
  setIsHandoutsOpen: (value: boolean) => void;
  setIsPollsOpen: (value: boolean) => void;
  setIsAdsOpen: (value: boolean) => void;
  isHandoutsOpen: boolean;
  handouts: any;
}

export const SessionHandouts = ({
  setIsHandoutsOpen,
  setIsPollsOpen,
  setIsAdsOpen,
  isHandoutsOpen,
  handouts,
}: IMiniSpotlight) => {
  const [showCross, setShowCross] = useState(false);
  const buttonRef = useRef<any>(null);
  const [showSpotlights, setShowSpotlights] = useState<any>(null);

  const handleOpenSpotlights = (event: any) => {
    setShowSpotlights(event?.currentTarget);
    setIsHandoutsOpen(!isHandoutsOpen);
    setIsPollsOpen(false);
    setIsAdsOpen(false);
  };
  const downloadHandout = async (url: string | undefined) => {
    if (url) {
      try {
        const response = await fetch(`${imageUrlPrefix}/${url}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const link = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = url.split("/").pop() || "download";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error("Download failed:", error);
      }
    } else {
      console.error("Download URL is undefined");
    }
  };

  return (
    <Stack sx={{ position: "relative" }}>
      {isHandoutsOpen ? (
        <Stack
          onClick={handleOpenSpotlights}
          ref={buttonRef}
          sx={{ position: "absolute", right: 0, top: 0, zIndex: 1000 }}
        >
          <Stack
            sx={{
              width: "40px",
              height: "30px",
              minHeight: "40px",
              borderRadius: "15px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SessionHandoutsIcon />
          </Stack>
        </Stack>
      ) : (
        <Stack
          onClick={handleOpenSpotlights}
          ref={buttonRef}
          sx={{ position: "absolute", right: 0, top: 0, zIndex: 1000 }}
        >
          <Stack
            sx={{
              width: "40px",
              height: "30px",
              minHeight: "40px",
              borderRadius: "15px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.15)",
              backdropFilter: "blur(40px)",
            }}
          >
            <SessionHandoutsIcon />
          </Stack>
        </Stack>
      )}
      {isHandoutsOpen ? (
        <Stack
          sx={{
            p: "13px",
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            backdropFilter: "blur(30px)",
            width: "349px",
            minHeight: "262px",
            top: 0,
            right: 0,
            borderRadius: "10px",
            zIndex: 100,
            position: "absolute",
          }}
        >
          {/* <div className=' w-full h-full backdrop-blur-[80px] absolute left-0 top-0 rounded-10 overflow-hidden'/> */}
          <Popper
            id={"Ads-picker"}
            open={isHandoutsOpen}
            anchorEl={showSpotlights}
            placement={"top-start"}
            disablePortal
            transition
            sx={{
              borderRadius: "9px",
              transform: "translate(0px, 0px) !important",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={0}>
                <Stack
                  sx={{ width: "100%", height: "100%", position: "relative" }}
                  onMouseEnter={() => setShowCross(true)}
                  onMouseLeave={() => setShowCross(false)}
                >
                  <>
                    <div style={{ padding: "10px 12px" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Poppins-500",
                          color: "#ffffff",
                          lineHeight: "20px",
                        }}
                      >
                        Session handouts
                      </Typography>
                    </div>
                    <Stack
                      spacing="5px"
                      className="custom-video-popup-scroll"
                      sx={{
                        padding: "0px 15px 0px 12px",
                        marginRight: handouts.length <= 4 ? "0px" : "15px",
                        overflowY: "auto",
                        maxHeight: "calc(100vh - 689px)",
                        "--scrollbar-color": "#707070",
                      }}
                    >
                      {handouts?.map((handOut: any, index: any) => (
                        <>
                          <Stack
                            justifyContent="space-between"
                            alignItems="center"
                            key={index}
                            sx={{
                              height: "43px",
                              minHeight: "43px",
                              borderRadius: "5px",
                              backgroundColor: "#000000",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingRight: "8.5px",
                            }}
                          >
                            <Stack sx={{
                              width: "85%"
                            }} direction="row" alignItems="center">
                              <Stack
                                sx={{
                                  ml: "5px",
                                  mr: "7px",
                                  borderRadius: "2px",
                                  minWidth: "26px",
                                  height: "30px",
                                  overflow: "hidden",
                                }}
                              >
                                {handOut?.type === "image" ? (
                                  <CardMedia
                                    sx={mediaStyle}
                                    component="img"
                                    image={`${imageUrlPrefix}/${handOut?.url}`}
                                  />
                                ) : handOut?.type === "document" ? (
                                  <Box
                                    component="img"
                                    src={fileThumb("pdf")}
                                    sx={{
                                      width: 26,
                                      height: 30,
                                      flexShrink: 0,
                                    }}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Stack>

                              <Tooltip title={handOut?.name}>
                                <Typography
                                  className="ellipsis-two-line"
                                  sx={{
                                    pr: "10px",
                                    fontSize: "12px",
                                    fontFamily: "Poppins-500",
                                    color: "#ffffff",
                                    lineHeight: "16px",
                                  }}
                                >
                                  {handOut?.name}
                                </Typography>
                              </Tooltip>
                            </Stack>
                            <IconButton
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => downloadHandout(handOut?.url)}
                            >
                              <SessionHandOutDownloadIcon style={{ fill: getBrandingColors()?.Text || "#ffffff" }} />
                            </IconButton>
                          </Stack>
                          {index !== handouts?.length - 1 && (
                            <Divider
                              sx={{
                                padding: "0px",
                                margin: "0px",
                                borderColor: "white",
                                width: "100%",
                                opacity: 0.4,
                              }}
                            />
                          )}
                        </>
                      ))}
                    </Stack>
                  </>
                </Stack>
              </Fade>
            )}
          </Popper>
        </Stack>
      ) : null}
    </Stack>
  );
};
