import React, { FC } from "react";
import { IUserProfile } from "../../models/user-profile";
import { Logo } from "../../shared-components/V2/Logo";
import classNames from "classnames";

interface IUserCard {
    user: IUserProfile;
    isCallingFromLivePage?: boolean;
}
export const UserCard:FC<IUserCard> = React.memo(({ user, isCallingFromLivePage }: IUserCard) => {
    return <div
              className={classNames("flex flex-col items-center w-[351px] h-[207px] rounded-[4px] bg-black overflow-hidden", {
                '!w-full !h-full': isCallingFromLivePage
              })}
            >
      <Logo src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${user?.avatar}`} className={classNames("w-full h-full object-contain", {
                '!object-cover': isCallingFromLivePage
              })} />
    </div>
  })