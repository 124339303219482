import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { UserService } from '../../../services';
import { ProfileMatchScore } from './profile-match-score';
import { UserHighlights } from './user-highlights';
import { UserMutuals } from './user-mutuals';

interface Props {
  userProfile?: any;
  userId: string;
  isOwnProfileNot?: boolean;
}

export const UserProfileMatching = ({ userProfile, userId, isOwnProfileNot }: Props) => {
  const [profileMatchResult, setProfileMatchResult] = useState(Number);
  const [companies, setCompanies] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [interests, setInterests] = useState<any[]>([]);
  const [offers, setOffers] = useState([]);

  const getProfielMatch = useCallback(async (id: string) => {
    const userService = new UserService();
    const result = await userService.getProfielMatchResults(id);
    setProfileMatchResult(result?.data?.matchedCompatibilityScore);
  }, []);

  const getInterestMatch = useCallback(async (id: string) => {
    const userService = new UserService();
    const result = await userService.getMatchedInterestResults(id).then(({ data }) => data.map((x: any) => ({ ...x, type: 'interest' })) ?? []);
    setInterests(result);
  }, []);

  const getOfferingMatch = useCallback(async (id: string) => {
    const userService = new UserService();
    const result = await userService.getMatchedOfferingResults(id).then(({ data }) => data.map((x: any) => ({ ...x, type: 'offer' })) ?? []);
    setOffers(result);
  }, []);

  const getConnectionMatch = useCallback(async (id: string) => {
    const userService = new UserService();
    await userService.getMatchedConnectionResults(id);
  }, []);

  const getEventMatch = useCallback(async (id: string) => {
    const userService = new UserService();
    const events = await userService.getMatchedEventResults(id);
    setEvents(events?.data ?? []);
  }, []);

  const getCompanylMatch = useCallback(async (id: string) => {
    const userService = new UserService();
    const companies = await userService.getMatchedCompaniesResults(id);
    setCompanies(companies?.data ?? []);
  }, []);

  useEffect(() => {
    getProfielMatch(userId);
    getOfferingMatch(userId);
    getInterestMatch(userId);
    getConnectionMatch(userId);
    getEventMatch(userId);
    getCompanylMatch(userId);
  }, [getProfielMatch, getOfferingMatch, getInterestMatch, getConnectionMatch, getEventMatch, getCompanylMatch, userId]);

  return (
    <div>
      <ProfileMatchScore
        chartPercent={profileMatchResult}
        chartColor="#144EE6"
        userProfile={userProfile}
      />
      <UserHighlights isOwnProfileNot={isOwnProfileNot} userProfile={userProfile} userId={userId} interests={interests} offers={offers} />
      <UserMutuals userProfile={userProfile} companies={companies} events={events} interests={interests} />
    </div>
  );
};
