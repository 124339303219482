import { useBoolean } from '../hooks';
import { TEmoji } from './types';

interface IActionSvgProps {
  id: TEmoji;
  text: string;
  svg: React.ReactNode;
  handleClick: (id: TEmoji) => void;
}

export const ActionsSVG = ({
  id,
  text,
  svg,
  handleClick
}: IActionSvgProps) => {
  const [isTooltipActive, setToolTipActive, setToolTipInActive] = useBoolean();
  
  return (
    <div
      onClick={() => handleClick(id)}
      className="relative cursor-pointer h-6 w-full animation-mount delay"
    >
      {isTooltipActive && (
        <span className="bg-gray-5 rounded-xl px-1.5 text-white absolute text-2x-sm left-1/2 -translate-x-1/2 -mt-7 whitespace-nowrap animate-fadeIn">
          {text}
        </span>
      )}
      <div
        onMouseEnter={setToolTipActive}
        onMouseLeave={setToolTipInActive}
      >
        {svg}
      </div>
    </div>
  );
};
export default ActionsSVG;
