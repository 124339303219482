/* eslint-disable react/jsx-no-constructed-context-values */
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "../routers/hook";
import {
  SurveyQuestionsForm,
  SurveyQuestionwithAnswer,
} from "src/mui/types/survey";
import { EventsService } from "src/services/events";
import { EventsServiceV2 } from "src/services/events/events-v2";
import { CompanyService, UserService } from "src/services";
import { RegistrationForm } from "../types/registration";
import { useSelector } from "react-redux";
import { IAppState } from "src/store";

export type PaginationPayload = {
  data: any[];
  limit: number;
  page: number;
  totalPage: number;
  totalRow: number;
};

export enum EventQuestionPageTypes {
  SURVEY = "survey",
  REGISTRATION = "registration",
}

type companyOptionType = {
  name: string;
  label: string;
  id: string;
  defaultValue?: {
    id: string;
    name: string;
  };
};

type industryOptionType = {
  id: string;
  name: string;
};

type SurveyContextType = {
  surveyQuestionsWithAnswers: SurveyQuestionwithAnswer[];
  surveyQuestions: any[];
  latestAttendeeCheckedIn: any[];
  setLatestAttendeeCheckedIn: React.Dispatch<React.SetStateAction<any[]>>;
  surveyAnsLoader: boolean;
  registrationAnsLoader: boolean;
  registrationPages: any[];
  questionPageLoader: boolean;
  userRegistrationAnswers: any;
  surveyPages: any[];
  signupRegQuestion: any[];
  surveyPageLoader: boolean;
  event: any;
  attendees: PaginationPayload | undefined;
  attendeesWithMatch: PaginationPayload | undefined;
  sessionMeetings: PaginationPayload | undefined;
  allCompanies: companyOptionType[] | undefined;
  allIndustries: industryOptionType[];
  eventGallery: any[];
  surveyGallery: any[];
  jobTitles: any[];
  showProtectedPasswordScreen: boolean;
  protectedPasswordError: string;
  resetProtectedPasswordError: () => void;
  screenId: string;
  setScreenID: (id: any) => void;
  submitSurveyLater: (payload: any) => void;
  jobFunctions: any[];
  registrationForm: RegistrationForm[];
  eventSponsors: any[];
  agendaStyling: any;
  setAgendaStyling: (data: any) => void;
  contactUS: (data: any) => void;
  getAllCompanies: () => void;
  getAllIndustries: () => void;
  getJobTitles: () => void;
  getJobFunctions: (querry?: string) => void;
  getRegistrationForm: () => void;
  getEventSponsors: () => void;
  getQuestionPages: (type: string, eventId?: string) => void;
  getQuestionPagesInsights: (type: string, eventId?: string) => void;
  getSignUpRegQuestions: (eventId?: string) => void;
  requestMeeting: (data: any) => void;
  getEvent: () => void;
  getSessionMeetings: (userId?: string) => void;
  setEventIdParam: (id: string) => void;
  getSurveyQuestionsWithAnswers: (userId: string) => void;
  getInsightSurveyQuestionsWithAnswers: (
    surveyId: string,
    userId: string
  ) => void;
  getSurveyQuestions: () => void;
  getUserRegistrationAnswers: (userId: string) => void;
  modifySurveyAnswers: (
    userId: string,
    body: any,
    partialSubmit?: string
  ) => void;
  modifySurveyAnswersInsight: (
    userId: string,
    body: any,
    surveyId?: string
  ) => void;
  eventProtectedPassword: (body: any) => void;
  getAttendees: (
    page?: number,
    limit?: number,
    whereClause?: string,
    search?: string,
    orderBy?: string,
    order?: string
  ) => void;
  connectWithSuggests: (body: any) => void;
  approveConnectionRequest: (body: any, userId: string) => void;
  getSurveyGallery: (surveyId: string) => void;
  getSurveyQuestionsInsights: (surveyId: string) => void;
  getLatestAttendeeCheckedIn: (eventId: string, latestAttendeeId: any) => void;
};

const SurveyContext = createContext<SurveyContextType>({
  surveyPages: [],
  signupRegQuestion: [],
  registrationPages: [],
  surveyPageLoader: true,
  questionPageLoader: true,
  surveyQuestionsWithAnswers: [],
  surveyQuestions: [],
  latestAttendeeCheckedIn:[],
  setLatestAttendeeCheckedIn: () => {},
  surveyAnsLoader: true,
  registrationAnsLoader: true,
  userRegistrationAnswers: [],
  attendees: undefined,
  attendeesWithMatch: undefined,
  sessionMeetings: undefined,
  event: undefined,
  allCompanies: [],
  allIndustries: [],
  eventGallery: [],
  surveyGallery: [],
  jobTitles: [],
  showProtectedPasswordScreen: false,
  protectedPasswordError: "",
  resetProtectedPasswordError() {},
  screenId: "",
  setScreenID() {},
  submitSurveyLater() {},
  jobFunctions: [],
  registrationForm: [],
  eventSponsors: [],
  agendaStyling: undefined,
  getAllCompanies() {},
  eventProtectedPassword() {},
  getAllIndustries() {},
  getJobTitles() {},
  getJobFunctions() {},
  getAttendees() {},
  connectWithSuggests() {},
  approveConnectionRequest() {},
  setEventIdParam() {},
  getSessionMeetings() {},
  getEvent() {},
  getQuestionPages() {},
  getQuestionPagesInsights() {},
  getSignUpRegQuestions() {},
  modifySurveyAnswers() {},
  modifySurveyAnswersInsight() {},
  requestMeeting() {},
  getSurveyQuestionsWithAnswers() {},
  getInsightSurveyQuestionsWithAnswers() {},
  getSurveyQuestions() {},
  getUserRegistrationAnswers() {},
  getRegistrationForm() {},
  getEventSponsors() {},
  setAgendaStyling() {},
  contactUS() {},
  getSurveyGallery() {},
  getSurveyQuestionsInsights() {},
  getLatestAttendeeCheckedIn() {},
});

export const useSurveyContext = () => useContext(SurveyContext);

type SurveyContextProviderProps = {
  children: ReactNode;
};

export const SurveyContextProvider = ({
  children,
}: SurveyContextProviderProps) => {
  const { slug } = useParams();
  const [surveyPages, setSurveyPages] = useState<any[]>([]);
  const [signupRegQuestion, setSignupRegQuestion] = useState<any[]>([]);
  const [registrationPages, setRegistrationPages] = useState<any[]>([]);
  const [eventIdParam, setEventIdParam] = useState<string>();
  const [surveyPageLoader, setSurveyPageLoader] = useState<boolean>(true);
  const [questionPageLoader, setQuestionPageLoader] = useState<boolean>(true);
  const [eventGallery, setEventGallery] = useState([]);
  const [surveyGallery, setSurveyGallery] = useState([]);
  const [attendees, setAttendees] = useState<PaginationPayload>();
  const [attendeesWithMatch, setAttendeesWithMatch] =
    useState<PaginationPayload>();
  const [sessionMeetings, setSessionMeetings] = useState<PaginationPayload>();
  const [event, setEvent] = useState<Event>();
  const [surveyQuestionsWithAnswers, setSurveyQuestionsWithAnswers] = useState<
    SurveyQuestionwithAnswer[]
  >([]);

  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [latestAttendeeCheckedIn, setLatestAttendeeCheckedIn] = useState<any[]>([]);

  const [surveyAnsLoader, setSurveyAnsLoader] = useState<boolean>(true);
  const [userRegistrationAnswers, setUserRegistrationAnswers] = useState([]);
  const [registrationAnsLoader, setRegistrationAnsLoader] =
    useState<boolean>(true);
  const [allCompanies, setAllCompanies] = useState<companyOptionType[]>([]);
  const [allIndustries, setAllIndustries] = useState<industryOptionType[]>([]);
  const [jobFunctions, setJobFunctions] = useState<any[]>([]);
  const [jobTitles, setJobTitles] = useState<any[]>([]);
  const [registrationForm, setRegistrationForm] = useState<RegistrationForm[]>(
    []
  );
  const [eventSponsors, setEventSponsors] = useState<any[]>([]);
  const [showProtectedPasswordScreen, setShowProtectedPasswordScreen] =
    useState<boolean>(false);
  const [protectedPasswordError, setProtectedPasswordError] =
    useState<string>("");
  const [screenId, setScreenId] = useState<string>("");

  // const [registrationForm, setEvnetProtectedPassword] = useState<RegistrationForm[]>(
  //   []
  // );

  const _event = useSelector(
    (state: IAppState) =>
      state.website.event || state.upcomingEvent?.value?.data?.event
  );

  const resetProtectedPasswordError = () => {
    setProtectedPasswordError("");
  };

  const setScreenID = (id: any) => {
    setScreenId(id);
  };

  const [agendaStyling, setAgendaStyling] = useState({
    backgroundColor: "black",
    color: "white",
  });

  const companyService: CompanyService = new CompanyService();

  const init = useCallback(() => {
    if (!eventIdParam) return;
    // getAllCompanies();
    getAllIndustries();
    // getJobTitles();
    // getJobFunctions();
    if (eventIdParam) {
      getRegistrationForm();
    }
    if (!eventGallery.length && eventIdParam) {
      getEventGallery();
    }
  }, [eventIdParam]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (_event?.isPasswordProtected!) {
      setShowProtectedPasswordScreen(true);
    } else {
      setShowProtectedPasswordScreen(false);
    }
  }, [_event?.isPasswordProtected]);

  const getSurveyQuestionsWithAnswers = async (userId: string) => {
    setSurveyQuestionsWithAnswers([]);
    setSurveyAnsLoader(true);
    const eventService = new EventsService();

    try {
      const response = await eventService.getSurveyQuestionsWithAnswers(
        eventIdParam!,
        userId
      );

      setSurveyQuestionsWithAnswers(response?.data);
      setSurveyAnsLoader(false);
    } catch (error) {
      setSurveyAnsLoader(false);
    }
  };

  const getInsightSurveyQuestionsWithAnswers = async (
    surveyId: string,
    userId: string
  ) => {
    setSurveyQuestionsWithAnswers([]);
    setSurveyAnsLoader(true);
    const eventService = new EventsService();

    try {
      const response = await eventService.getInsightSurveyQuestionsWithAnswers(
        surveyId!,
        userId
      );

      setSurveyQuestionsWithAnswers(response?.data);
      setSurveyAnsLoader(false);
    } catch (error) {
      setSurveyAnsLoader(false);
    }
  };

  const getSurveyQuestions = async () => {
    const eventService = new EventsService();
    try {
      const response = await eventService.getSurveyQuestions(eventIdParam!);
      setSurveyQuestions(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSurveyQuestionsInsights = async (surveyId: string) => {
    const eventService = new EventsService();
    try {
      const response = await eventService.getSurveyQuestionsInsight(surveyId!);
      setSurveyQuestions(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLatestAttendeeCheckedIn = async (
    eventId: string,
    latestAttendeeId: any,
    page: number = 1,
    limit: number = 1
  ) => {
    const eventService = new EventsService();
    const userService = new UserService();
    try {
      const response = await eventService.getLatestAttendeeCheckedIn(eventId);
      const defaultUserId = response?.data?.userId;
  
      const userId = latestAttendeeId ? latestAttendeeId : defaultUserId;
      const userDetails = await userService.getUserProfile(userId);
      const mergedData = { ...response?.data, ...userDetails?.data };
  
      setLatestAttendeeCheckedIn((prev: any[]) => {
        const filtered = prev.filter(
          (attendee) => attendee.userId !== mergedData.userId
        );
        return [mergedData, ...filtered];
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCompanies = async (query: string = "") => {
    const result: any = await companyService.getAllCompanies();
    setAllCompanies([...result?.data]);
  };

  const getRegistrationForm = async () => {
    if (!eventIdParam || eventIdParam === "undefined") return;
    new UserService()
      .getEventRegistrationForm(eventIdParam!)
      .then(({ data }) => setRegistrationForm(data));
  };

  const getEventSponsors = async () => {
    new UserService()
      .getEventSponsors(eventIdParam!)
      .then(({ data }) => setEventSponsors(data));
  };

  const getAllIndustries = async (query: string = "") => {
    const result: any = await companyService.getIndustries();
    setAllIndustries([...result?.data]);
  };

  const getJobTitles = async (query: string = "") => {
    const result: any = await companyService.getJobTitlesWithQuery(query, 5000);
    setJobTitles([...result?.data]);
  };
  const getJobFunctions = async (query: string = "") => {
    const result: any = await companyService.getJobFunctions(query, 300);
    setJobFunctions([...result?.data]);
  };

  const getUserRegistrationAnswers = (userId: string) => {
    setUserRegistrationAnswers([]);
    setRegistrationAnsLoader(true);
    const eventV2Service = new EventsServiceV2();
    eventV2Service
      .getUserRegistrationAnswers(eventIdParam!, userId)
      .then(({ data: response }) => {
        setRegistrationAnsLoader(false);
        setUserRegistrationAnswers(response);
      })
      .catch((error) => {
        setRegistrationAnsLoader(false);
      });
  };

  const eventProtectedPassword = async (body: any) => {
    try {
      const result: any = await new EventsService().eventProtectedPassword(
        eventIdParam!,
        body
      );
      if (!result?.result) {
        setShowProtectedPasswordScreen(true);
        setProtectedPasswordError("Enter Correct Password!");
      } else if (result?.result) {
        setShowProtectedPasswordScreen(false);
        setProtectedPasswordError("");
      }
    } catch (error: any) {
      setShowProtectedPasswordScreen(true);

      if (error.statusCode === 500) {
        setProtectedPasswordError("Something went wrong. Please try again!");
      } else {
        setProtectedPasswordError(error?.message);
      }
    }

    // setAllCompanies([...result?.data]);
  };

  const modifySurveyAnswers = async (
    userId: string,
    body: any,
    partialSubmit?: string
  ) => {
    try {
      const eventService = new EventsService();
      const res: any = await eventService.modifySurveyAnswers(
        eventIdParam!,
        "",
        userId,
        body
      );
      // .then(({ data: response }: any) => {
      if (!partialSubmit) {
        localStorage.removeItem("surveyAnswers");
      }
      // setSurveyQuestionsWithAnswers(res?.data);
      setSurveyPageLoader(false);
      setSurveyAnsLoader(false);
      // });
    } catch (error: any) {
      throw error;
    }
  };

  const modifySurveyAnswersInsight = async (
    userId: string,
    body: any,
    surveyId?: string
  ) => {
    try {
      const eventService = new EventsService();
      const res: any = await eventService.modifySurveyAnswersInsights(
        surveyId!,
        "",
        userId,
        body
      );
      // .then(({ data: response }: any) => {
      // localStorage.removeItem("surveyAnswers");
      // setSurveyQuestionsWithAnswers(res?.data);
      setSurveyPageLoader(false);
      setSurveyAnsLoader(false);
      // });
    } catch (error: any) {
      throw error;
    }
  };

  const submitSurveyLater = async (payload: any) => {
    try {
      const eventService = new EventsService();
      return await eventService.updateEventAudienceProgress(
        eventIdParam!,
        payload
      );
    } catch (error: any) {
      console.log("error", error);
      throw error;
    }
  };

  const getAttendees = async (
    page?: number,
    limit?: number,
    whereClause?: string,
    search?: string,
    orderBy?: string,
    order?: string
  ) => {
    const eventService = new EventsServiceV2();
    eventService
      .getAttendees(
        eventIdParam!,
        whereClause,
        search,
        orderBy,
        page,
        limit,
        order
      )
      .then((response) => {
        if (whereClause?.includes("isMatch")) setAttendeesWithMatch(response);
        else setAttendees(response);
      });
  };

  const connectWithSuggests = async (body: any) => {
    const eventService = new EventsService();
    eventService.connectWithSuggests(eventIdParam!, body).then((response) => {
      return response;
    });
  };

  const approveConnectionRequest = async (body: any, userId: string) => {
    const eventService = new EventsService();
    eventService
      .approveConnectionRequest(eventIdParam!, body, userId)
      .then((response) => {
        return response;
      });
  };

  const requestMeeting = async (data: any) => {
    const eventService = new EventsServiceV2();

    await eventService.requestMeeting(eventIdParam!, data);
  };

  const getEvent = async () => {
    const eventService = new EventsService();
    eventService.getUpcomingEventDetails(slug!).then(({ data: response }) => {
      setEventIdParam(response?.event?.id);
    });
  };

  const getSessionMeetings = async (userId?: string) => {
    const eventService = new EventsServiceV2();
    eventService.getSessionMeetings(eventIdParam!, userId).then((response) => {
      setSessionMeetings(response);
    });
  };

  const contactUS = async (data: any) => {
    const eventService = new EventsServiceV2();
    try {
      const res = await eventService.contactUS(eventIdParam!, data);
    } catch (error) {
      console.log(error);
    }
  };

  const getQuestionPages = async (type: string, eventId?: string) => {
    const eventService = new EventsServiceV2();
    await eventService
      .getQuestionPages(eventId! || eventIdParam!, type)
      .then(({ data: response }) => {
        if (type === EventQuestionPageTypes.SURVEY) {
          setSurveyPageLoader(false);
          setSurveyPages(response);
        }
        if (type === EventQuestionPageTypes.REGISTRATION) {
          setQuestionPageLoader(false);
          response?.map((questionFields: any, index: number) => {
            let { eventRegistrationFields } = questionFields;
            eventRegistrationFields = eventRegistrationFields.map(
              (registrationField: any) => {
                let processedFieldName = registrationField?.fieldName;
                processedFieldName = processedFieldName.replace(
                  /<a href="([^"]+)"[^>]*>([^<]+)<\/a>/g,
                  "{{link:$2|$1}}"
                );
                processedFieldName = processedFieldName
                  .replace(/\./g, "&middot;")
                  .replace(/\,/g, "&comma;")
                  .replace(/\'/g, "&apos;")
                  .replace(/\|/g, "&pipe;");

                return {
                  ...registrationField,
                  fieldName: processedFieldName,
                };
              }
            );
            questionFields.eventRegistrationFields = eventRegistrationFields;
          });
          setRegistrationPages(response);
        }
      });
  };

  const getQuestionPagesInsights = async (type: string, eventId?: string) => {
    const eventService = new EventsServiceV2();
    await eventService
      .getQuestionPagesInsights(eventId! || eventIdParam!)
      .then(({ data: response }) => {
        if (type === EventQuestionPageTypes.SURVEY) {
          setSurveyPageLoader(false);
          setSurveyPages(response);
        }
        if (type === EventQuestionPageTypes.REGISTRATION) {
          setQuestionPageLoader(false);
          response?.map((questionFields: any, index: number) => {
            let { eventRegistrationFields } = questionFields;
            eventRegistrationFields = eventRegistrationFields.map(
              (registrationField: any) => {
                // return {
                //   ...registrationField,
                //   fieldName: registrationField?.fieldName.replace(/\./g, '&middot;').replace(/\,/g, '&comma;').replace(/\'/g, '&apos;').replace(/\|/g, '&pipe;')
                // }
                let processedFieldName = registrationField?.fieldName;

                // Replace special characters
                processedFieldName = processedFieldName
                  .replace(/\./g, "&middot;")
                  .replace(/\,/g, "&comma;")
                  .replace(/\'/g, "&apos;")
                  .replace(/\|/g, "&pipe;");

                // Replace <a> tags with a placeholder or formatted text
                processedFieldName = processedFieldName.replace(
                  /<a href="([^"]+)"[^>]*>([^<]+)<\/a>/g,
                  "{{link:$2|$1}}"
                );
                return {
                  ...registrationField,
                  fieldName: processedFieldName,
                };
              }
            );
            questionFields.eventRegistrationFields = eventRegistrationFields;
          });
          setRegistrationPages(response);
        }
      });
  };

  const getEventGallery = async () => {
    if(!eventIdParam) return
    const eventService = new EventsServiceV2();
    const response = await eventService.getEventGalleries(eventIdParam);
    setEventGallery(response?.data);
  };

  const getSurveyGallery = async (surveyId: string) => {
    if(!surveyId) return
    const eventService = new EventsServiceV2();
    const response = await eventService.getSurveyGalleries(surveyId);
    setSurveyGallery(response?.data);
  };

  const getSignUpRegQuestions = async (eventId?: string) => {
    try {
      const eventService = new EventsServiceV2();
      const response = await eventService.getSignUpRegQuestions(
        `${eventIdParam || eventId}`
      );
   const data =   response?.data.map(
        (registrationField: any) => {
          let processedFieldName = registrationField?.fieldName;
          processedFieldName = processedFieldName.replace(
            /<a href="([^"]+)"[^>]*>([^<]+)<\/a>/g,
            "{{link:$2|$1}}"
          );
          processedFieldName = processedFieldName
            .replace(/\./g, "&middot;")
            .replace(/\,/g, "&comma;")
            .replace(/\'/g, "&apos;")
            .replace(/\|/g, "&pipe;");

          return {
            ...registrationField,
            fieldName: processedFieldName,
          };
        }
      );
      setSignupRegQuestion(data);
    } catch (error) {
      console.log("here is the error");
    }
  };

  return (
    <SurveyContext.Provider
      value={{
        allIndustries,
        allCompanies,
        jobFunctions,
        jobTitles,
        showProtectedPasswordScreen,
        protectedPasswordError,
        screenId,
        registrationForm,
        eventSponsors,
        eventGallery,
        surveyGallery,
        attendees,
        attendeesWithMatch,
        sessionMeetings,
        event,
        surveyPages,
        signupRegQuestion,
        registrationPages,
        surveyPageLoader,
        questionPageLoader,
        surveyQuestionsWithAnswers,
        surveyQuestions,
        surveyAnsLoader,
        agendaStyling,
        setEventIdParam,
        getSurveyQuestionsWithAnswers,
        getInsightSurveyQuestionsWithAnswers,
        getSurveyQuestions,
        getUserRegistrationAnswers,
        userRegistrationAnswers,
        registrationAnsLoader,
        getQuestionPages,
        getQuestionPagesInsights,
        getSignUpRegQuestions,
        contactUS,
        modifySurveyAnswers,
        modifySurveyAnswersInsight,
        eventProtectedPassword,
        resetProtectedPasswordError,
        setScreenID,
        submitSurveyLater,
        getAttendees,
        connectWithSuggests,
        approveConnectionRequest,
        requestMeeting,
        getEvent,
        getSessionMeetings,
        getAllCompanies,
        getAllIndustries,
        getJobTitles,
        getJobFunctions,
        getRegistrationForm,
        setAgendaStyling,
        getEventSponsors,
        getSurveyGallery,
        getSurveyQuestionsInsights,
        getLatestAttendeeCheckedIn,
        latestAttendeeCheckedIn,
        setLatestAttendeeCheckedIn
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};
