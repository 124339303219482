/* eslint-disable no-nested-ternary */
// @mui
import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
  Checkbox,
  SxProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
// import './index.css';
// import TableSortIcons from 'src/assets/icons/TableSortIcons';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

// ----------------------------------------------------------------------

type Props = {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[] | undefined;
  rowCount: number;
  numSelected: number;
  showAllCheckbox?: boolean;
  // accountFilesData: any;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
//   meetingTableHeader?: boolean;
};

const TableHeadCustom = ({
  headLabel, order, orderBy, onSort, onSelectAllRows, rowCount, numSelected, showAllCheckbox = true, sx,
}: Props) => {
  const theme = useTheme();
  return (
    <TableHead sx={sx}>
      <TableRow>
        {showAllCheckbox && onSelectAllRows && rowCount > 0 && (
        <TableCell
          padding="checkbox"
          sx={{
            p: 0,
            pl: '2px',
            py: '2px',
            borderTopLeftRadius: '10px',
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.background.paper : null,
          }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSelectAllRows(event.target.checked)}
            sx={{
              '&.MuiCheckbox-root svg': {
                width: '23px',
                height: '23px',
              },
            }}
          />
        </TableCell>
        )}
        {headLabel?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              p: headCell?.label === 'Full Name' ? '2px 3px' : '8px 16px',
              // borderTopRightRadius: '10px',
              ...headCell.position,
              width: headCell.width,
              minWidth: headCell.minWidth,
            }}
          >
            <Tooltip title={headCell?.label || ''}>
              {onSort && !headCell?.hideSortIcon ? (
                <TableSortLabel
                  hideSortIcon
                  active={orderBy === headCell.id && !headCell?.hideSortIcon}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => onSort(headCell.id)}
                  className="custom-sort-icon"
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '160px', // Adjust as needed
                      fontSize: '14px',
                    }}
                  >
                    {headCell?.label}
                  </Typography>
                  {orderBy === headCell.id ? (
                    <Box sx={{ ...visuallyHidden }}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <TableSortLabel
                  hideSortIcon
                  className="custom-sort-icon"
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '160px', // Adjust as needed
                      fontSize: '14px',
                    }}
                  >
                    {headCell?.label}
                  </Typography>
                </TableSortLabel>
              )}
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadCustom;
