// PlusSvgIcon.tsx
import React from 'react';

interface PlusSvgIconProps {
  className?: string;
  style?: React.CSSProperties;
}

const PlusSvgIcon: React.FC<PlusSvgIconProps> = ({ className, style }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" className={className} style={style}>
    <g id="Group_21596" data-name="Group 21596" transform="translate(-3)">
      <line id="Line_1740" data-name="Line 1740" x2="24" transform="translate(3 12)" fill="none" stroke="#fff" strokeWidth="3" />
      <line id="Line_1741" data-name="Line 1741" y2="24" transform="translate(14.7)" fill="none" stroke="#fff" strokeWidth="3" />
    </g>
  </svg>
);

export default PlusSvgIcon;
