import React from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface IInsightsSvgIconActive {
  isActive?: boolean;
}

export const InsightsSvgIconActive = ({ isActive }: IInsightsSvgIconActive) => {
    const colors = getBrandingColors();
    const defaultColor = '#66686c';
    const iconColor = isActive ? (colors?.Accent || '#03FFCD') : (colors?.Accent || defaultColor);

    return isActive ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.902" height="24.858" viewBox="0 0 27.902 27.858">
  <g id="insights_active" data-name="insights active" transform="translate(-0.515 -6.4)">
    <path id="Path_30574" data-name="Path 30574" d="M22.512,59.893H16.965a2.354,2.354,0,0,1-2.354-2.354V51.976a3.531,3.531,0,0,0-1.372-2.8,3.379,3.379,0,0,0-2.92-.629A12.911,12.911,0,1,0,25.957,64.2a3.385,3.385,0,0,0-.627-2.925A3.6,3.6,0,0,0,22.512,59.893Z" transform="translate(0 -39.722)" fill={iconColor}/>
    <path id="Path_30575" data-name="Path 30575" d="M310.51,14.811a11.684,11.684,0,0,0-1.19-2.851,11.877,11.877,0,0,0-7.13-5.475,4.881,4.881,0,0,0-.64-.085,2.225,2.225,0,0,0-1.378.437,2.416,2.416,0,0,0-.929,1.388,2.36,2.36,0,0,0-.064.53v5.532a3.531,3.531,0,0,0,3.531,3.531h5.554a2.308,2.308,0,0,0,1.867-.942,2.4,2.4,0,0,0,.471-1.508A2.487,2.487,0,0,0,310.51,14.811Z" transform="translate(-282.186 0)" fill={iconColor}/>
  </g>
</svg>
    ) : (

<svg xmlns="http://www.w3.org/2000/svg" width="24.393" height="24.362" viewBox="0 0 27.393 27.362">
  <g id="Group_29055" data-name="Group 29055" transform="translate(-11126.249 3481.75)">
    <path id="Subtraction_4" data-name="Subtraction 4" d="M13.169,25.543c-.344,0-.695-.014-1.043-.042A12.661,12.661,0,0,1,10.11.594a3.133,3.133,0,0,1,2.706.584A3.3,3.3,0,0,1,14.09,3.786V9.348a2.608,2.608,0,0,0,2.6,2.6h5.547a3.329,3.329,0,0,1,2.623,1.283,3.154,3.154,0,0,1,.58,2.71,12.66,12.66,0,0,1-12.276,9.6Zm-2.3-23.185a1.285,1.285,0,0,0-.311.039,10.807,10.807,0,0,0,1.719,21.262c.288.024.586.036.886.036A10.808,10.808,0,0,0,23.645,15.5v-.016A1.294,1.294,0,0,0,23.4,14.37a1.47,1.47,0,0,0-1.156-.567H16.7a4.463,4.463,0,0,1-4.458-4.458V3.787a1.462,1.462,0,0,0-.566-1.144,1.278,1.278,0,0,0-.8-.285Z" transform="translate(11125.751 -3479.931)" fill={iconColor}/>
    <path id="Subtraction_3" data-name="Subtraction 3" d="M9.324,11.417H3.781A3.285,3.285,0,0,1,.5,8.136V2.6a2.115,2.115,0,0,1,.623-1.49A2.087,2.087,0,0,1,2.6.5h.022A2.213,2.213,0,0,1,3.2.576,11.626,11.626,0,0,1,8.351,3.569a11.659,11.659,0,0,1,2.9,4.842l.087.318a2.113,2.113,0,0,1-2.013,2.688ZM2.615,2.351H2.593a.319.319,0,0,0-.157.059l-.1.076.009,5.649A1.429,1.429,0,0,0,3.772,9.563h5.88l-.085-.315A9.781,9.781,0,0,0,2.712,2.36L2.68,2.351Z" transform="translate(11142.224 -3482.25)" fill={iconColor}/>
  </g>
</svg>

    );
};




