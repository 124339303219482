import classNames from 'classnames';
import React from 'react';

interface ISolutionItemBox {
  Icon: any;
  title: string;
  text: string;
  onClick?: Function;
}
const SolutionItemBox = ({ Icon, title, text, onClick }: ISolutionItemBox) => {
  return (
    <div 
      className="cursor-pointer inline-flex hover:bg-[#EDF2F5] rounded-[15px] w-full pl-[12px] pt-[12px] pr-[28px] h-[95.75px]" 
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick()
      }}
    >
      <div 
        className={classNames("w-[27.36px] min-w-[27.36px] h-[75px]", {
          'pt-[2px]': title === "Virtual Events",
          'pt-[1px]': title === "In-person Events",
          'pt-[5px]': title === "Audio Visual Solutions",
          'pt-[3px]': title === "Event Mobile App",
          'pt-[4px]': title === "Packages & Features",
        })}
      >
        <Icon />
      </div>
      <div className="h-[75px]">
        <div className="text-[15px] h-[21px] font-normal text-[#7283A4] mb-[1px]">{title}</div>
        <div className="text-[13px] leading-[17px] font-normal text-[#717171]">{text}</div>
      </div>
    </div>
  );
};

export default SolutionItemBox;
