export const UserArrowIcon = ({ fillColor }: { fillColor?: string }) => (
  <svg width="36.107" height="41.463" viewBox="0 0 36.107 41.463">
    <defs>
      <filter id="Path_27133" x="3.546" y="19.707" width="29.061" height="21.756" filterUnits="userSpaceOnUse">
        <feOffset dy={3} in="SourceAlpha" />
        <feGaussianBlur stdDeviation={3} result="blur" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Ellipse_1306" x="0" y="0" width="36.107" height="36.415" filterUnits="userSpaceOnUse">
        <feOffset dy={3} in="SourceAlpha" />
        <feGaussianBlur stdDeviation={3} result="blur-2" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_21488" data-name="Group 21488" transform="translate(-3591.707 -5247.417)">
      <g id="Group_21211" data-name="Group 21211" transform="translate(3601.381 5260.218)" style={{ isolation: 'isolate' }}>
        <g transform="matrix(1, 0, 0, 1, -9.67, -12.8)" filter="url(#Path_27133)">
          <path id="Path_27133-2" data-name="Path 27133" d="M135.167,179.156a4.212,4.212,0,0,1,7.127,0h1.967a5.949,5.949,0,0,0-11.061,0Z" transform="translate(-120.65 -149.69)" fill={fillColor || '#fff'} />
        </g>
        <g transform="matrix(1, 0, 0, 1, -9.67, -12.8)" filter="url(#Ellipse_1306)">
          <ellipse id="Ellipse_1306-2" data-name="Ellipse 1306" cx={9.053} cy={9.208} rx={9.053} ry={9.208} transform="translate(9 6)" fill={fillColor || '#fff'} />
        </g>
      </g>
      <path id="Union_16" data-name="Union 16" d="M2.109,11.433a1,1,0,0,1-1-1V4.858H.67a.5.5,0,0,1-.388-.814L3.338.275a.5.5,0,0,1,.777,0L7.171,4.044a.5.5,0,0,1-.388.814H6.344v5.575a1,1,0,0,1-1,1Z" transform="translate(3621.255 5271.54) rotate(90)" fill={fillColor || '#fff'} />
    </g>
  </svg>
);
