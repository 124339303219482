import before from '../../../assets/images/overview/before.png';
import before2 from '../../../assets/images/overview/before@2x.png';
import during from '../../../assets/images/overview/during.png';
import during2 from '../../../assets/images/overview/during@2x.png';
import after from '../../../assets/images/overview/after.png';
import after2 from '../../../assets/images/overview/after@2x.png';
import done from '../../../assets/images/overview/done.png';
import done2 from '../../../assets/images/overview/done@2x.png';
import divider from '../../../assets/images/overview/line.png';
import form from '../../../assets/images/overview/registration-form.png';
import form2 from '../../../assets/images/overview/registration-form@2x.png';
import brandawareness from '../../../assets/images/overview/brand-awareness.svg';

export const StayConnected = () => (
  <div>
    <div className="w-full">
      <div className="text-center block md:flex md:justify-center -mb-4">
        <div className="mt-4">
          <div className="lg:text-xl4 text-xl font-light text-dark-blue mt-10 md:mt-6w-2/3">
            Stay connected
          </div>
        </div>
      </div>
      <div className="ml-10 2xl:mx-auto 2xl:container lg:ml-32  w-10/12">
        <div className="2xl:ml-32 pl-5 block lg:flex lg:flex-row justify-between">
          <div className="lg:w-1/3 ml-1">
            <div className="lg:text-xl6 font-extralight text-xl text-dark-blue mt-10 md:mt-6w-2/3 -ml-2">
              Before
            </div>
            <ul className="list-disc text-md text-dark-blue mt-2 ml-4">
              <li className="font-extralight">Pre-event conversations</li>
              <li className="font-extralight">Posts, images, polls and weekly updates</li>
              <li className="font-extralight">Automated confirmations and reminders</li>
            </ul>
            <div className="mt-8">
              <img
                srcSet={`${before} 300w, ${before2} 1000w`}
                sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 500px"
                alt=""
                src={before2}
              />
            </div>
          </div>
          <div className="lg:w-1/3">
            <div className="lg:text-xl6 font-extralight text-xl text-dark-blue mt-10 md:mt-6w-2/3 -ml-2">
              During
            </div>
            <ul className="list-disc text-md text-dark-blue mt-2 ml-4 ">
              <li className="font-extralight">Live feed</li>
              <li className="font-extralight">Interactive live discussions</li>
              <li className="font-extralight">Dedicated and private meeting rooms</li>
              <li className="font-extralight">Scheduled or organic 1:1 video calls</li>
            </ul>
            <div className="mt-8">
              <img
                srcSet={`${during} 300w, ${during2} 1000w`}
                sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 500px"
                alt=""
                src={during2}
              />
            </div>
          </div>

          <div className="lg:w-1/3">
            <div className="lg:text-xl6 font-extralight text-xl text-dark-blue mt-10 md:mt-6w-2/3 -ml-2">
              After
            </div>
            <ul className="list-disc text-md text-dark-blue mt-2 ml-4 leading-6">
              <li className="font-extralight">Community Hub: stay connected on Panelist</li>
              <li className="font-extralight">Showcase event highlights</li>
              <li className="font-extralight">On-demand access to your event</li>
              <li className="font-extralight">Re-target your attendees for future events</li>
            </ul>
            <div className="mt-8">
              <img
                srcSet={`${after} 300w, ${after2} 1000w`}
                sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 500px"
                alt=""
                src={after2}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full"
        style={{
          background: `url(${brandawareness})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '621px'
        }}
      >
        <div className="2xl:mx-auto 2xl:container">
          <div className="mt-24 ml-10 lg:ml-32 pl-5 lg:flex lg:flex-row justify-between w-4/5">
            <div className="lg:w-3/6 ">
              <div className="text-xl6 font-extralight text-dark-blue">Brand Awareness</div>
              <p className="pt-4 text-lg font-extralight text-dark-blue ">
                Publisize your event and maximise registrations
                {' '}
                <br />
                and anyone who registers will have access to your event.
              </p>
            </div>
            <div className="ml-10 flex justify-center ">
              <div className="mt-16 text-lg  font-extralight text-dark-blue">or</div>
            </div>
            <div className="lg:w-3/6 ml-10">
              <div className="text-xl6 font-extralight text-dark-blue">Lead Generation</div>
              <p className="pt-4 text-lg font-extralight text-dark-blue">
                Gate your event with a fully customizable
                {' '}
                <br />
                registration form
              </p>
            </div>
          </div>

          <div className="mt-2 lg:flex container justify-center">
            <div className="block lg:ml-52 pl-2 w-4/5 lg:flex mx-auto justify-between">
              <div className="flex">
                <div className="ml-36">
                  <img
                    srcSet={`${done} 300w, ${done2} 1000w`}
                    sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 500px"
                    alt=""
                    src={done2}
                  />
                </div>
                <div className="ml-10 divide">
                  <img src={divider} alt="" className="" />
                </div>
              </div>

              <div className="flex justify-end ">
                <img
                  srcSet={`${form} 300w, ${form2} 1000w`}
                  sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 500px"
                  alt=""
                  src={form2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default StayConnected;
