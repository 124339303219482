import { SVGProps } from 'react';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

function LayoutGridIcon(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props;
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
        <svg id="Page-1" xmlns="http://www.w3.org/2000/svg" width="29.364" height="27.966" viewBox="0 0 29.364 27.966">
            <g id="Dribbble-Light-Preview">
                <g id="icons">
                    <path id="grid-_1526_" data-name="grid-[#1526]"
                          d="M189.428,59.576h-2.936a2.868,2.868,0,0,0-2.936,2.8v2.8a2.868,2.868,0,0,0,2.936,2.8h2.936a2.868,2.868,0,0,0,2.936-2.8v-2.8a2.868,2.868,0,0,0-2.936-2.8m-10.277,0h-2.936a2.868,2.868,0,0,0-2.936,2.8v2.8a2.868,2.868,0,0,0,2.936,2.8h2.936a2.868,2.868,0,0,0,2.936-2.8v-2.8a2.868,2.868,0,0,0-2.936-2.8m-10.277,0h-2.936a2.868,2.868,0,0,0-2.936,2.8v2.8a2.868,2.868,0,0,0,2.936,2.8h2.936a2.868,2.868,0,0,0,2.936-2.8v-2.8a2.868,2.868,0,0,0-2.936-2.8m20.555-9.788h-2.936a2.868,2.868,0,0,0-2.936,2.8v2.8a2.868,2.868,0,0,0,2.936,2.8h2.936a2.868,2.868,0,0,0,2.936-2.8v-2.8a2.868,2.868,0,0,0-2.936-2.8m-10.277,0h-2.936a2.868,2.868,0,0,0-2.936,2.8v2.8a2.868,2.868,0,0,0,2.936,2.8h2.936a2.868,2.868,0,0,0,2.936-2.8v-2.8a2.868,2.868,0,0,0-2.936-2.8m-10.277,0h-2.936a2.868,2.868,0,0,0-2.936,2.8v2.8a2.868,2.868,0,0,0,2.936,2.8h2.936a2.868,2.868,0,0,0,2.936-2.8v-2.8a2.868,2.868,0,0,0-2.936-2.8M189.428,40h-2.936a2.868,2.868,0,0,0-2.936,2.8v2.8a2.868,2.868,0,0,0,2.936,2.8h2.936a2.868,2.868,0,0,0,2.936-2.8V42.8a2.868,2.868,0,0,0-2.936-2.8M179.15,40h-2.936a2.868,2.868,0,0,0-2.936,2.8v2.8a2.868,2.868,0,0,0,2.936,2.8h2.936a2.868,2.868,0,0,0,2.936-2.8V42.8A2.868,2.868,0,0,0,179.15,40m-7.341,2.8v2.8a2.868,2.868,0,0,1-2.936,2.8h-2.936a2.868,2.868,0,0,1-2.936-2.8V42.8a2.868,2.868,0,0,1,2.936-2.8h2.936a2.868,2.868,0,0,1,2.936,2.8"
                          transform="translate(-163 -40)" fill={defaultColor} fill-rule="evenodd"/>
                </g>
            </g>
        </svg>

    );
}

export default LayoutGridIcon;
