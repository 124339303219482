import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import CommentEmoji from '../../../assets/images/post-reactions/comments-emoji.svg';
import hahaEmoji from '../../../assets/images/post-reactions/haha-emoji.svg';
import likeEmoji from '../../../assets/images/post-reactions/like-emoji.svg';
import loveEmoji from '../../../assets/images/post-reactions/love-emoji.svg';
import smileEmoji from '../../../assets/images/post-reactions/smile-emoji.svg';
import clapEmoji from '../../../assets/images/post-reactions/well-done-emoji.svg';
import { Typography } from '../../../shared-components';
import { HoverableDiv } from '../../../shared-components/V2/HoverableDiv';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../../alert-modal/alert-modal';
import PostLikeReactions from './PostLikeReactions';
import { PostService } from '../../../services';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

interface IPostReactionProps {
  post?: any;
  postId: string;
  totalClaps: number;
  totalHahas: number;
  totalLikes: number;
  totalLoves: number;
  totalSmiles: number;
  totalComments: number;
  isCompanyNew?: boolean;
  onClickComments: (id: any) => void;
  isLivePage?: boolean;
}

export const PostReactions = ({
  post,
  postId,
  totalClaps,
  totalHahas,
  totalLikes,
  totalLoves,
  totalSmiles,
  totalComments,
  isCompanyNew,
  onClickComments,
  isLivePage
}: IPostReactionProps) => {
  const navigate = useNavigate();
  const colors = getBrandingColors();
  const [showLike, setShowLike] = useState<boolean>(false);
  const [showComment, setShowComment] = useState<boolean>(false);
  const [isOpenReactions, setIsOpenReactions] =
    useState<boolean>(false);
  const [showPopoverTimer, setShowPopoverTimer] = useState<any>();
  const [totalLikeUsers, setTotalLikesUsers] = useState<any[]>([]);
  const [likedEmoji, setLikedEmoji] = useState<any[]>([]);
  const [postLikeType, setPostLikeType] = useState<string>('');
  const likeSum = totalClaps + totalHahas + totalLikes + totalLoves + totalSmiles;
  const isLike = totalClaps === 0 && totalHahas === 0 && totalLikes === 0 && totalLoves === 0 && totalSmiles === 0 && totalComments > 0;

  const getPostLikes = async (id: string, type: string) => {
    const postService = new PostService();
    const filters = (
      (type === 'All' && '')
      || (type === 'Likes' && '&where[format]=like')
      || (type === 'Loves' && '&where[format]=love')
      || (type === 'Claps' && '&where[format]=clap')
      || (type === 'Smiles' && '&where[format]=smile')
      || (type === 'Hahas' && '&where[format]=haha')
    );
    try {
      const getPostlikes = await postService.getPostLikes(
        id, filters || ''
      );
      setTotalLikesUsers(getPostlikes?.data);
    } catch (error) {
    }
  };

  // useEffect(() => {
  //   getPostLikes(post.id, postLikeType);
  // }, [post.id, postLikeType, isOpenReactions]);

  const handleLikePopover = () => {
    getPostLikes(post.id, postLikeType)
    setShowPopoverTimer(setTimeout(function () {
      setShowLike(true);
    }, 500));
    return false;
  };

  const handleCloseLikePopover = () => {
    if (showPopoverTimer) clearTimeout(showPopoverTimer);
    setShowLike(false);
    return false;
  };

  const handleCommentPopover = () => {
    setShowPopoverTimer(setTimeout(function () {
      setShowComment(true);
    }, 500));
    return false;
  };

  const handleCloseCommentPopover = () => {
    if (showPopoverTimer) clearTimeout(showPopoverTimer);
    setShowComment(false);
    return false;
  };

  const onCloseModal = () => {
    setIsOpenReactions(false);
    setPostLikeType('');
  };



  useEffect(() => {
    const totalLikesEmoji: any[] = [];
    if (totalLikes > 0) {
      totalLikesEmoji.push({
        totalLikes: totalLikes,
      });
    }
    if (totalClaps > 0) {
      totalLikesEmoji.push({
        totalClaps: totalClaps,
      });
    }
    if (totalHahas > 0) {
      totalLikesEmoji.push({
        totalHahas: totalHahas,
      });
    }
    if (totalLoves > 0) {
      totalLikesEmoji.push({
        totalLoves: totalLoves,
      });
    }
    if (totalSmiles > 0) {
      totalLikesEmoji.push({
        totalSmiles: totalSmiles,
      });
    }
    setLikedEmoji(totalLikesEmoji);
  }, [totalLikes, totalClaps, totalHahas, totalLoves, totalSmiles]);

  return <div
    key={post.id}
    className={classNames("flex items-center justify-between flex-row px-[18px] mt-[12px]", {
      "!h-[27.5px]": isCompanyNew,
      "!px-[12px] !mt-[9px]": isLivePage
    })}
    >
    <div
      className="flex -space-x-2 cursor-pointer"
      onMouseEnter={handleLikePopover}
      onMouseLeave={handleCloseLikePopover}
      onClick={() => setIsOpenReactions(true)}
    >
      {likedEmoji?.slice(0, 2).map((emoji, index) => (
        <>
          {emoji.totalLikes > 0 && <img className={classNames({
                  "w-[14px] h-[14px]": isLivePage,
                })} src={likeEmoji} alt="" key={`likes-${index}`} />}
          {emoji.totalClaps > 0 && <img className={classNames({
                  "w-[14px] h-[14px]": isLivePage,
                })} src={clapEmoji} alt="" key={`totalClaps-${index}`} />}
          {emoji.totalLoves > 0 && <img className={classNames({
                  "w-[14px] h-[14px]": isLivePage,
                })} src={loveEmoji} alt="" key={`totalLoves-${index}`} />}
          {emoji.totalHahas > 0 && <img className={classNames({
                  "w-[14px] h-[14px]": isLivePage,
                })} src={hahaEmoji} alt="" key={`totalHahas-${index}`} />}
          {emoji.totalSmiles > 0 && <img className={classNames({
                  "w-[14px] h-[14px]": isLivePage,
                })} src={smileEmoji} alt="" key={`totalSmiles-${index}`} />}
        </>
      ))}

      {totalComments > 0 && <img className={classNames({
                  "w-[14px] h-[14px]": isLivePage,
                })} src={CommentEmoji} alt="" />}
      {isOpenReactions && (
        <AlertModal onClose={onCloseModal} isOpen={isOpenReactions} className="md:w-[581px] w-full !py-0 !rounded-[12px]">
          <PostLikeReactions
            totalClaps={totalClaps}
            totalHahas={totalHahas}
            totalLikes={totalLikes}
            totalLoves={totalLoves}
            totalSmiles={totalSmiles}
            totalLikeUsers={totalLikeUsers}
            onClickTab={setPostLikeType}
            setIsOpenReactions={setIsOpenReactions}
          />
        </AlertModal>
      )}
      {!!likeSum && !isOpenReactions && (
        <HoverableDiv

          className="flex relative"
          children={
            <>
              <div className="flex">
                <Typography style={{ color: isLivePage ? colors?.Text : '' }}
                classes={classNames('pl-3 pr-1 flex items-center !font-medium text-[#3C3C3C] leading-[14px] cursor-pointer', {
                  "text-[9px] leading-[11px]": isLivePage,
                })}
                variant="H3" text={`${likeSum}`} />
              </div>
              {showLike && (
                <div className='absolute z-30 top-[28px] -left-[56px] w-[177px] max-h-[195px] bg-opacity-[87%] bg-[#172746] rounded-5 p-2 px-3 overflow-scroll no-scrollbar'>
                  {
                    totalLikeUsers.map((user: any, index: any) => (
                      <p
                        key={index}
                        style={{ color: isLivePage ? colors?.Text : '' }}
                        onClick={() => navigate(`/wall/${user?.slug}`)}
                        className={classNames('text-[11px] leading-[17px] text-white tracking-wide cursor-pointer hover:underline my-[3px]', {
                          "text-[9px] leading-[11px]": isLivePage,
                        })}
                      >
                        {`${user?.firstName} ${user?.lastName}`}
                      </p>
                    ))
                  }
                </div>
              )}
            </>
          }
        />
      )}
    </div>
    <div
      className={classNames("no-underline hover:underline cursor-pointer", {
        [`ml-1`]: isLike
      })}
      onClick={() => onClickComments(postId)}>
      <HoverableDiv
        handleMouseOver={handleCommentPopover}
        handleMouseOut={handleCloseCommentPopover}
        className="flex relative"
        children={
          <>
            <Typography
              style={{ color: isLivePage ? colors?.Text : undefined }}
              classes={classNames(
                "!font-medium leading-[14px]",
                {
                  "!text-[9px] !leading-[11px]": isLivePage
                },
                !isLivePage && "text-[#3C3C3C]"
              )}
              variant="H3"
              text={`${totalComments} comments`}
            />
            {showComment && post?.commentedConnections?.length > 0 && (
              <div className='absolute z-30 top-[15px] w-[163px] max-h-[195px] bg-opacity-[87%] bg-[#172746] rounded-5 p-2 px-[10px] overflow-scroll no-scrollbar'>
                {
                  post && post.commentedConnections.length > 0 && post.commentedConnections.map((item: any, index: number) => (
                    <p className={classNames("text-[11px] leading-[17px] text-white tracking-wide mb-[2px]", {
                      "!text-[9px] !leading-[11px]": isLivePage
                    })} key={index}>{item.firstName} {item.lastName}</p>
                  ))
                }
              </div>
            )}
          </>
        }
      />
    </div>
  </div>
}