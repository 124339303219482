import { Autocomplete, LoadScriptNext } from '@react-google-maps/api';
import { useOnClickOutside } from 'usehooks-ts';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

import './city-selector.css';
import React from 'react';

type Libraries = ("drawing" | "geometry" | "localContext" | "places" | "visualization")[];
const myLibraries: Libraries = ['places'];

export interface CitySelectorProps {
  setValue: UseFormSetValue<any>;
  citySelected: any;
  register?: UseFormRegister<any>;
  onChange?: Function;
  classes?: string;
  placeholder?: string;
  autoCompleteType?: string;
  setCitySelected?: any;
  onlyCityCounty?: boolean;
}

export const CitySelector = ({
  setValue,
  citySelected,
  register,
  onChange,
  classes,
  placeholder,
  autoCompleteType,
  onlyCityCounty,
}: CitySelectorProps) => {
  const ref = React.useRef(null);
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [searchBox, setSearchBox] = useState<any>();
  const [searchLocation, setSearchLocation] = useState<any>();
  const [isDirty, setIsDirty] = useState(false);

  const onPlaceSelect = () => {
    const searchPlace = searchBox.getPlace();
    if (searchPlace) {
      setIsDirty(false);
      if (onlyCityCounty) {
        let city: any;
        let country: any;
        const value = searchPlace?.address_components.map((x: any) => {
          if (x.types.includes("locality")) {
            city = x.long_name;
          }
          if (x.types.includes("administrative_area_level_1")) {
            city = `${city}, ${x.short_name}`;
          }
          if (x.types.includes("country")) country = x.long_name;
          return `${city}, ${country}`;
        });
        const address = value[value.length - 1];

        setValue("country", country);
        setValue("city", city);
        setValue("location", address, { shouldValidate: true });

      } else {
        const city = searchPlace?.address_components[0]?.long_name;
        const country =
          searchPlace.address_components[
            searchPlace.address_components.length - 1
          ].long_name;
        const address = searchPlace.formatted_address;
        setValue("country", country);
        setValue("city", city);
        setValue("location", address, { shouldValidate: true });
      }
    }
    if (onChange) {
      onChange(searchPlace);
    }
  }

  const handleClickOutside = () => {
    if (isDirty) {
      setValue('location', '', { shouldValidate: true });
    }
  }

  useOnClickOutside(ref, handleClickOutside);

  

  return <div ref={ref}>
    <LoadScriptNext googleMapsApiKey={apiKey ?? ''} libraries={myLibraries}>
      <Autocomplete
        onPlaceChanged={onPlaceSelect}
        onLoad={(ref) => setSearchBox(ref)}
        types={['(cities)']}
        fields={["vicinity", "address_components", "formatted_address"]}
        className="location-dropdown"
      >
        <input
          {...register!('location', { required: { value: true, message: 'Search and select your city' } })}
          id={'citySelector'}
          placeholder={placeholder?.trim()?.length ? placeholder : "Search and select your city"}
          defaultValue={citySelected}
          type={'text'}
          className={classNames('h-[31px] text-sm1 font-normal rounded-10 w-full px-2.5', {
            [`${classes}`]: classes,
          })}
          autoComplete={autoCompleteType}
          onChange={(e) => {
            setIsDirty(true);
            setSearchLocation(e.target.value);
          }}
          onBlur={handleClickOutside}
        />
      </Autocomplete>
    </LoadScriptNext>
  </div>
}