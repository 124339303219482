import SearchIcon from '../../../../assets/images/event-landing-pre-event/Icon-feather-search.svg';
import { IAttendee } from '../../../../models/events-live/attendee';

export interface IFilterAttributesListItem {
  id: string;
  firstName: string;
}

export interface IFilterConnectionsListProps {
  placeHolderText?: string;
  items: IAttendee[];
  setFormatsAttendees: any;
}

export const AttendeesSearch = ({
  items,
  setFormatsAttendees,
  placeHolderText
}: IFilterConnectionsListProps) => {
  const filterAttendees = (searchString: string) => {
    if (items && searchString?.trim() !== '') {
      const filterFormatsAttendees = items.filter((f: any) => {
        const fullName = `${f.firstName}  ${f.lastName}`;
        return fullName.toLowerCase().includes(searchString.toLowerCase());
      });
      setFormatsAttendees(filterFormatsAttendees);
    } else if (items && searchString?.trim() === '') {
      setFormatsAttendees(items);
    }
  };

  return (
    <div className="w-full pt-6">
      <div className="flex ml-8 mr-4 flex-row items-center justify-center">
        <div className="flex-shrink-0 relative py-3 ">
          <img className="w-4 h-4" src={SearchIcon} alt="" />
        </div>
        <div className="bg-gray-1 rounded-20 -ml-8 w-full h-[38px]">
          <input
            type="text"
            className="pl-10 pr-2  w-full bg-gray-1 text-md font-extralight rounded-20 h-[38px]"
            placeholder={placeHolderText}
            onChange={e => {
              filterAttendees(e.target.value);
            }}
            autoComplete="off"
          />
        </div>
      </div>
    </div>
  );
};
export default AttendeesSearch;
