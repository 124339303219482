import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { JobFunctionSelector } from 'src/components/selectors/job-functions-selector';
import { TextInput, Typography } from '../../shared-components';
import { Button } from '../../shared-components/V2';
// import { ProfileNavbar } from '../../components/navbar';
import { PageHeader, Footer, Navbar } from '../../components';
import {
  signupBackStep,
  signupShortRegister,
  signupUserExsistShortRegister,
} from '../../store/user/signup/actions';
import { atLeast3charRegex } from '../../utils';
import { IAppState } from '../../store';
import { LOGIN_SIGNUP_RECOVERY } from '../../constants';
import { checkUserEmail } from '../../store/checkUserEmail';
import { CitySelectorTwo } from '../../components/selectors/city-selector-two';
import { IndustrySelector } from '../../components/selectors/industry-selector';
import { IReactSelectOption } from '../../models/react-select-option';
import { IReactSelectOptionCompany } from '../../models/company/company-react-select-option';
import { JobTitleSelectorNew } from '../../components/selectors/jobTitle-selector-new';
import { CompanySelectorNew } from '../../components/selectors/company-selector-new';
import { UserService } from '../../services';

interface ISignUp {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  //
  country: string;
  city: string;
  location: string;
  postCode: string;
  //
  jobTitle: string;
  companyId: string;
  industryId: string;
  jobFunctionId: string;
}

interface IPropsCreateProfile {
  userName: string | null;
}
export const CreateProfile1 = ({ userName }: IPropsCreateProfile) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ISignUp>();

  const dispatch = useDispatch();

  const isExist = useSelector(
    (state: IAppState) => state.checkUserEmail.value?.result
  );
  const { eventRegistration, errorMessage } = useSelector(
    (state: IAppState) => state.signupUser
  );
  const [selectedJobTitleStateToStore, setSelectedJobTitleStateToStore] = useState<any>();
  const [selectedCompanyStateToStore, setSelectedCompanyStateToStore] = useState<IReactSelectOptionCompany>();
  const [selectedIndustryStateToStore, setSelectedIndustryStateToStore] = useState<IReactSelectOption>();
  const [selectedJobFunctionStateToStore, setSelectedJobFunctionStateToStore] = useState<IReactSelectOption>();

  const [citySelected, setCitySelected] = useState<any>(null);
  const userService = new UserService();

  useEffect(() => {
    if (userName) {
      userService
        .getProfileByEmail(userName as string)
        .then((r: any) => r.data)
        .then((user: any) => {
          setValue('firstName', user.firstName);
          setValue('lastName', user.lastName);
          setValue('email', user.email);
          setValue('postCode', user.postCode);
          setValue('city', user.city);
          setValue('country', user.country);
          setValue('location', user.location);
          setCitySelected(user.location);
          if (user?.jobTitle?.toLowerCase() !== 'n/a') {
            setValue('jobTitle', user?.jobTitle);
            setSelectedJobTitleStateToStore(user?.jobTitle!);
          }

          if (
            user?.company?.id.toLowerCase() !== 'n/a'
            && user?.company?.name.toLowerCase() !== 'n/a'
          ) {
            setValue('companyId', user?.company?.id);
            setSelectedCompanyStateToStore({
              key: user?.company?.id!,
              value: user?.company?.id!,
              label: user?.company?.name!,
              logo: user?.company?.logo,
              industry: '',
            });
          }
          if (
            user?.industry?.id.toLowerCase() !== 'n/a'
            && user?.industry?.name.toLowerCase() !== 'n/a'
          ) {
            setValue('industryId', user?.industry?.id);
            setSelectedIndustryStateToStore({
              key: user?.industry?.id!,
              value: user?.industry?.id!,
              label: user?.industry?.name!,
            });
          }
          if (
            user?.jobFunction?.id.toLowerCase() !== 'n/a'
            && user?.jobFunction?.name.toLowerCase() !== 'n/a'
          ) {
            setValue('jobFunctionId', user?.jobFunction?.id);
            setSelectedJobFunctionStateToStore({
              key: user?.jobFunction?.id!,
              value: user?.jobFunction?.id!,
              label: user?.jobFunction?.name!,
            });
          }
        })
        .catch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);
  useEffect(() => {
    if (eventRegistration) {
      setValue('firstName', eventRegistration.firstName);
      setValue('lastName', eventRegistration.lastName);
      setValue('email', eventRegistration.email);
    }
  }, [eventRegistration, setValue]);

  useEffect(() => {
    if (eventRegistration) {
      let cityCountry: {
        city: string;
        country: string;
        location: string;
      } | null;
      // eslint-disable-next-line prefer-const
      cityCountry = {
        city: eventRegistration.city,
        country: eventRegistration.country,
        location: eventRegistration.location,
      };
      setCitySelected(cityCountry.location);
    }
  }, [eventRegistration, setValue]);

  useEffect(() => {
    setValue('jobTitle', eventRegistration?.jobTitle?.value!);
    setSelectedJobTitleStateToStore(eventRegistration?.jobTitle!);
  }, [eventRegistration?.jobTitle, setValue]);

  useEffect(() => {
    setValue('companyId', eventRegistration?.company?.value!);
    setSelectedCompanyStateToStore(eventRegistration?.company!);
  }, [eventRegistration?.company, setValue]);

  useEffect(() => {
    setValue('industryId', eventRegistration?.industry);
    setSelectedIndustryStateToStore(eventRegistration?.industry!);
  }, [eventRegistration?.industry, setValue]);

  useEffect(() => {
    if (eventRegistration && eventRegistration.email) {
      dispatch(checkUserEmail(eventRegistration));
    }
  }, [eventRegistration, eventRegistration?.email, dispatch]);

  const handleJobTitleSelected = (selectedOptions: any) => {
    if (selectedOptions) errors.jobTitle = undefined;
    setValue('jobTitle', selectedOptions?.label);
    setSelectedJobTitleStateToStore(selectedOptions?.label);
  };

  const handleCompanySelected = (selectedOptions: any) => {
    if (selectedOptions) errors.companyId = undefined;
    setValue('companyId', selectedOptions?.value);
    setSelectedCompanyStateToStore(selectedOptions);
  };

  const handleIndustrySelected = (selectedOptions: any) => {
    if (selectedOptions) errors.industryId = undefined;
    setValue('industryId', selectedOptions?.value);
    setSelectedIndustryStateToStore(selectedOptions);
  };

  const handleJobFunctionSelected = (selectedOptions: any) => {
    if (selectedOptions) errors.jobFunctionId = undefined;
    setValue('jobFunctionId', selectedOptions?.value);
    setSelectedJobFunctionStateToStore(selectedOptions);
  };

  const handleLocationSelected = (selectedOptions: any) => {
    if (selectedOptions) {
      setCitySelected(selectedOptions.formatted_address);
    }
  };

  useEffect(() => {
    if (errorMessage) toast.error(errorMessage);
  }, [errorMessage]);

  const onSubmit = (data: any) => {
    if (isExist) {
      dispatch(
        signupUserExsistShortRegister(
          data,
          selectedCompanyStateToStore,
          selectedJobTitleStateToStore,
          selectedIndustryStateToStore,
          selectedJobFunctionStateToStore
        )
      );
    } else {
      dispatch(
        signupShortRegister(
          data,
          selectedCompanyStateToStore,
          selectedJobTitleStateToStore,
          selectedIndustryStateToStore,
          selectedJobFunctionStateToStore
        )
      );
    }
  };

  const backClick = () => {
    dispatch(signupBackStep());
  };

  return (
    <>
      <Navbar />
      <div className="bg-[#001BF6] flex text-center h-[calc(100vh-112px)] overflow-y-scroll pb-4">
        <div className="mx-auto">
          <div className="md:-ml-9">
            <div className="w-full mt-[70px] mb-[30px]">
              <div className="flex flex-col">
                <PageHeader isBlueTheme />
                <div className="text-[22px] leading-8 font-medium text-white text-left mt-[5px]">
                  Let’s create your profile
                </div>
                <div />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className="flex justify-center mt-5 pt-1">
                      <div className="text-left">
                        <TextInput
                          isBlueTheme
                          className="w-336 h-[30px] text-sm1 font-normal text-gray-12 px-2.5 rounded-10"
                          textinputsize="medium"
                          type="text"
                          caption="First name*"
                          {...register('firstName', {
                            required: {
                              value: true,
                              message:
                                LOGIN_SIGNUP_RECOVERY.FIRSTNAME_ISREQUIRED,
                            },
                            pattern: {
                              value: atLeast3charRegex,
                              message: LOGIN_SIGNUP_RECOVERY.VALID_FIRSTNAME,
                            },
                          })}
                          autoComplete="new-password"
                        />
                      </div>
                    </div>
                    <div>
                      {errors.firstName && (
                        <Typography
                          variant="ValidationMessage"
                          text={errors.firstName?.message!.toString()}
                          classes="flex content-start email-validation-message"
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-center mt-2">
                      <div className="text-left">
                        <TextInput
                          isBlueTheme
                          className="w-336 h-[30px] text-sm1 font-normal text-gray-12 px-2.5 rounded-10"
                          textinputsize="medium"
                          type="text"
                          caption="Last name*"
                          {...register('lastName', {
                            required: {
                              value: true,
                              message:
                                LOGIN_SIGNUP_RECOVERY.LASTNAME_ISREQUIRED,
                            },
                            pattern: {
                              value: atLeast3charRegex,
                              message: LOGIN_SIGNUP_RECOVERY.VALID_LASTNAME,
                            },
                          })}
                          autoComplete="new-password"
                        />
                      </div>
                    </div>
                    <div>
                      {errors.lastName && (
                        <Typography
                          variant="ValidationMessage"
                          text={errors.lastName?.message!.toString()}
                          classes="flex content-start email-validation-message"
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex justify-center mt-4">
                    <div className="text-left w-full">
                      <div className="flex flex-col">
                        <div className="text-sm1 text-white font-medium mt-1">
                          Current or most recent job title*
                        </div>
                        <div className="">
                          <JobTitleSelectorNew
                            handleJobTitleSelected={handleJobTitleSelected}
                            register={register}
                            selectedJobTitleStateToStore={
                              selectedJobTitleStateToStore
                            }
                            placeholder=""
                            isBorderLess
                            className="!font-normal"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {errors.jobTitle && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.jobTitle?.message!.toString()}
                        classes="flex email-validation-message mt-1"
                      />
                    )}
                  </div>
                  <div className="flex justify-center mt-3">
                    <div className="text-left w-full">
                      <div className="flex flex-col">
                        <div className="text-sm1 text-white font-medium mt-1">
                          Current or most recent company*
                        </div>
                        <div className="">
                          <CompanySelectorNew
                            handleCompanySelected={handleCompanySelected}
                            register={register}
                            selectedCompanyStateToStore={
                              selectedCompanyStateToStore
                            }
                            placeholder=" "
                            className="!font-normal"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {errors.companyId && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.companyId?.message!.toString()}
                        classes="flex content-start email-validation-message"
                      />
                    )}
                  </div>
                  <div className="flex justify-center mt-3">
                    <div className="text-left w-full">
                      <div className="flex flex-col">
                        <div className="text-sm1 text-white font-medium mt-1">
                          Industry*
                        </div>
                        <div className="">
                          <IndustrySelector
                            onChange={handleIndustrySelected}
                            register={register}
                            selectedIndustryStateToStore={
                              selectedIndustryStateToStore
                            }
                            isBorderLess
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {errors.industryId && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.industryId?.message!.toString()}
                        classes="flex content-start email-validation-message"
                      />
                    )}
                  </div>
                  <div className="flex justify-center mt-3">
                    <div className="text-left w-full">
                      <div className="flex flex-col">
                        <div className="text-sm1 text-white font-medium mt-1">
                          Job Functions*
                        </div>
                        <div className="">
                          <JobFunctionSelector
                            onChange={handleJobFunctionSelected}
                            register={register}
                            selectedJobFunctionStateToStore={
                              selectedJobFunctionStateToStore
                            }
                            isBorderLess
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {errors.jobFunctionId && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.jobFunctionId?.message!.toString()}
                        classes="flex content-start email-validation-message"
                      />
                    )}
                  </div>
                  <div>
                    <div className="flex justify-center mt-4">
                      <div className="text-left w-full">
                        <div className="flex flex-col">
                          <div className="text-sm1 text-white font-medium mt-1">
                            City, Country*
                          </div>
                          <div className="mt-1">
                            <CitySelectorTwo
                              setValue={setValue}
                              citySelected={citySelected}
                              setCitySelected={setCitySelected}
                              onChange={handleLocationSelected}
                              register={register}
                              placeholder=""
                              autoCompleteType="off"
                            />
                          </div>
                          <div>
                            {errors.location && (
                              <Typography
                                variant="ValidationMessage"
                                text={errors.location?.message!.toString()}
                                classes="flex content-start email-validation-message"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {errors.city && (
                        <Typography
                          variant="ValidationMessage"
                          text={errors.city?.message!.toString()}
                          classes="flex content-start email-validation-message"
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-center mt-4">
                      <div className="text-left">
                        <TextInput
                          isBlueTheme
                          className="w-336 h-[30px] text-sm1 font-normal text-gray-12 px-2.5 rounded-10"
                          textinputsize="medium"
                          type=""
                          caption="Post code*"
                          {...register('postCode', {
                            required: {
                              value: true,
                              message:
                                LOGIN_SIGNUP_RECOVERY.POSTCODE_ISREQUIRED,
                            },
                            // pattern: {
                            //   value: digitRegex,
                            //   message: LOGIN_SIGNUP_RECOVERY.VALID_POSTCODE,
                            // },
                          })}
                          autoComplete="new-password"
                        />
                      </div>
                    </div>
                    <div>
                      {errors.postCode && (
                        <Typography
                          variant="ValidationMessage"
                          text={errors.postCode?.message!}
                          classes="flex content-start email-validation-message"
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between mt-5">
                      <button
                        type="button"
                        className="text-sm1 text-white font-semibold hover:underline"
                        style={{
                          color: '#ffffff',
                          width: '80px',
                          height: '30px',
                        }}
                        onClick={backClick}
                      >
                        Back
                      </button>
                      <Button
                        type="submit"
                        text="Continue"
                        size="x-small"
                        className="w-110 bg-white text-[#3855eb] text-sm font-semibold hover:bg-[#2000CD] hover:text-white"
                        data-test-id="signup-button-2"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className="bg-black py-3" />
    </>
  );
};

export default CreateProfile1;
