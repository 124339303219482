import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReactSelectOption } from '../../models/react-select-option';
import { IAppState } from '../../store';
import { getJobTitles } from '../../store/job-titles/actions';
import { SingleValue } from 'react-select';
import { UseFormRegister } from 'react-hook-form';
import { LOGIN_SIGNUP_RECOVERY } from '../../constants';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';

interface JobTitleSelectorProps {
  onChange: Function;
  register: UseFormRegister<any>;
  selectedJobTitleStateToStore: IReactSelectOption | undefined;
  placeholder?: string;
  isBorderLess?: boolean;
  disabled?: boolean;
  isSpectre?: boolean;
}

const customStyles = {
  option: (provided: any, state: { isSelected: any; }) => ({
    ...provided,
    // borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: '100%',
    border: '1px solid #8f8f8f',
    height: '35px',
    borderRadius: '10px',
    display: 'flex',
    color: 'white',
  }),
  singleValue: (provided: any, state: { isDisabled: any; }) => {
    const opacity = state.isDisabled ? 1 : 1;
    const transition = 'opacity 300ms';
    const color = 'white';
    const fontSize = '15px';
    const fontWeight = '200'

    return { ...provided, opacity, transition, color, fontSize, fontWeight };
  }
}

const defaultStyle = {
  singleValue: (provided: any, state: { isDisabled: any; }) => {
    const opacity = state.isDisabled ? 1 : 1;
    const transition = 'opacity 300ms';
    const color = '#3C3C3C';
    const fontSize = '13px';

    return { ...provided, opacity, transition, color, fontSize };
  }
}

export const JobTitleSelector = ({
  onChange,
  register,
  selectedJobTitleStateToStore,
  placeholder,
  isBorderLess,
  disabled,
  isSpectre
}: JobTitleSelectorProps) => {
  const dispatch = useDispatch();
  const jobTitles = useSelector((state: IAppState) => state.jobTitles);

  const [jobTitlesListState, setjobTitlesListState] = useState<IReactSelectOption[]>();
  const [getJobSearchTitle, setGetJobSearchTitle] = useState<IReactSelectOption[]>();
  const [query, setQuery] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    dispatch(getJobTitles(query));
  }, [query, dispatch]);

  useEffect(() => {
    if (selectedJobTitleStateToStore === null) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
    if (getJobSearchTitle && getJobSearchTitle?.length > 0) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [selectedJobTitleStateToStore]);

  useEffect(() => {
    const mapJobTitlesToSelectOption = () => {
      const jobTitleList: IReactSelectOption[] = [];

      jobTitles?.value?.data &&
        jobTitles?.value?.data.map((item: any, index: number) =>
          jobTitleList.push({
            key: `jobTitle-${index}`,
            value: item.id,
            label: item.name
          })
        );
        setjobTitlesListState(jobTitleList);
    };
    mapJobTitlesToSelectOption();
  }, [jobTitles]);

  const onChangeLocal = (event: SingleValue<IReactSelectOption>) => {
    onChange(event);
  };

  const onInputChange = (value: string) => {
    setQuery(value);
    if (query.length > 1) {
      const findTitle = jobTitlesListState?.filter(item => item.label.toLowerCase()?.includes(query.toLowerCase()));  
      setGetJobSearchTitle(findTitle);
    } else {
      setGetJobSearchTitle([])
    }
  }

  const onCloseMenu = () => {
    setGetJobSearchTitle([])
  }

  const formatCreateLabel = (inputValue: any) => {
    setIsMenuOpen(inputValue.length > 1 ? true : false);
    return `${inputValue}`;
  }

  return (
    <CreatableSelect
      id="jobTitleSelector"
      className={`dropdown-style hide-arrow ${isBorderLess ? "job-title" : ""}`}
      classNamePrefix={classNames({
        "custom-class": isSpectre,
      })}
      menuIsOpen={isMenuOpen}
      isClearable
      placeholder={placeholder}
      options={getJobSearchTitle}
      onInputChange={(a) => onInputChange(a)}
      onMenuClose={onCloseMenu}
      formatCreateLabel={formatCreateLabel}
      openMenuOnClick={true}
      {...register('jobTitle', {
        required: { value: true, message: LOGIN_SIGNUP_RECOVERY.JOBTITLE_ISREQUIRED }
      })}
      value={selectedJobTitleStateToStore}
      onChange={(e) => onChangeLocal(e)}
      isDisabled={disabled}
      styles={isSpectre ? customStyles : defaultStyle}
    />
  );
};
