import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { IAppState } from '../../../store';
import { Logo } from '../../../shared-components/V2/Logo';
import { IEvent } from '../../../models/event/event';
import { getCompanyName } from '../../../utils';

const WebsiteEventSpeaker = () => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const speakers = useSelector(
    (state: IAppState) => state.website.event!.speakers
  );

  if (!speakers?.length) return null;

  return (
    <div
      id="speakers"
      className={classNames('w-full mb-4', {
        hidden: !(speakers && speakers.length > 0),
      })}
    >
      <div className="w-full event-website-box-new pt-[23px] pb-[42px]">
        <div
          className="font-semibold text-[#0326E5] text-[21px] leading-[25px] flex justify-center mb-[50px]"
          style={{
            color: event?.colorStyle?.values?.heading,
          }}
        >
          Event speakers
        </div>

        <div className="flex flex-wrap justify-center items-center md:gap-x-[34px] md:gap-y-[24px] gap-y-[15px] w-full px-[0px] md:px-[143px]">
          {speakers.map(item => (
            <div className="flex flex-col items-center w-[184px] h-[195px]">
              <Logo
                src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item.eventSpeakers?.[0]?.photo}`}
                className="w-[100px] h-[100px] object-cover rounded-[40px] mb-[9px] bg-white"
                defaultLogo="user"
              />
              <div className="text-[15px] leading-[21px] text-[#0B1221] font-semibold mb-1 text-center">
                {item.eventSpeakers?.[0]?.firstName}
                {' '}
                {item.eventSpeakers?.[0]?.lastName}
              </div>
              <div className="text-[13px] leading-[19px] text-[#3C3C3C] font-light mb-1 text-center line-clamp-2">
                {item.eventSpeakers?.[0]?.jobTitle}
              </div>
              <div className="text-[14px] leading-[20px] text-[#0B1221] font-medium text-center">
                {getCompanyName(item.eventSpeakers?.[0])}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventSpeaker;
