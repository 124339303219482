/* eslint-disable max-len */
/* eslint-disable no-console */
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import {
  Autoplay, EffectFade, Navigation, Pagination
} from 'swiper'; // Import Swiper modules

import { ReactComponent as ArrowLeftIcon } from 'src/assets/images/arrowLeft.svg'; // Adjust path as needed
import { ReactComponent as ArrowRightIcon } from 'src/assets/images/arrowRight.svg'; // Adjust path as needed
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { IEvent } from '../../../models/event/event';
import { IAppState } from '../../../store';

const CustomPrevButton = () => (
  <div className="custom-prev-button-topic absolute md:top-[50%] top-[40%] -mt-[17px] z-10 md:-left-[17px] -left-[10px] xl:-left-[58px] cursor-pointer">
    <ArrowLeftIcon />
  </div>
);

const CustomNextButton = () => (
  <div className="custom-next-button-topic absolute md:top-[50%] top-[40%] -mt-[17px] z-10 md:-right-[17px] -right-[10px] xl:-right-[58px] cursor-pointer">
    <ArrowRightIcon />
  </div>
);

export const TopicsSection = () => {
  const [inerWidth, setInnerWidth] = useState(window.innerWidth);
  const swiperRefTopic = useRef<any>(null);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  // const countWords = (text: string): number => {
  //   const wordsArray = text.trim().split(/\s+/).filter(word => word.length > 0);
  //   return wordsArray.length;
  // };

  const handleMouseEnter = () => {
    swiperRefTopic?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefTopic?.current?.swiper?.autoplay?.start();
  };
  const slidesPreviewCount = inerWidth <= 768 ? 'auto' : 1;
  const slidesGap = inerWidth <= 768 ? 10 : 55;
  const slideMaxWidth = inerWidth <= 768 ? 'max-w-[283px]' : 'max-w-full';
  const isOnMobile = inerWidth <= 768;

  return (
    <div className="flex flex-col md:mt-[150px] mt-[60px]">
      <div className="text-white md:leading-[42px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium">
        Topics at a glance
      </div>
      {isOnMobile && event?.eventKeyDiscussionPoints.length > 1 ? (
        <div className="w-full mt-[18px] relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Swiper
            modules={[Pagination, Autoplay, EffectFade, Navigation]}
            navigation={{ prevEl: '.custom-prev-button-topic', nextEl: '.custom-next-button-topic' }}
            pagination={{ dynamicBullets: false, clickable: true }}
            ref={swiperRefTopic}
            autoplay={{ delay: 5000, disableOnInteraction: true }}
            className="factorSwiper inspirationSlider"
            spaceBetween={slidesGap}
            slidesPerView={slidesPreviewCount}
            effect="coverflow"
            grabCursor={isOnMobile}
            centeredSlides={isOnMobile}
          >
            {event?.eventKeyDiscussionPoints?.map((item:any, index:number) => (
              <SwiperSlide className={slideMaxWidth} key={item}>
                <div key={item?.id} className="w-[100%] min-h-[282px] rounded-[10px] bg-[#14161A] p-[20px] md:p-[32px_35px_25px_32px]">
                  <div
                    className="flex flex-row items-center"
                  >
                    <div className="text-[56px] mt-[13px] leading-[56px] md:text-[90px] md:leading-[70px] text-[#F1B01A] font-bold helvetica-neue-65-medium">
                      {index + 1}
                    </div>
                    <div
                      className={classNames('text-white md:leading-[42px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium', {
                        'ml-[10px]': index !== 0,
                      })}
                    >
                      {item?.key}
                    </div>
                  </div>
                  <div className="mt:[5px] md:mt-[5px] text-white md:leading-[22px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman">
                    {item?.summary}
                    {' '}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <CustomPrevButton />
          <CustomNextButton />
        </div>
      ) : (
        <div className="mt-[18px] grid gap-[11px] grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
          {event?.eventKeyDiscussionPoints?.map((item:any, index:number) => (
            <div key={item?.id} className="grid-cols-4 w-[100%] md:min-h-[282px] rounded-[10px] bg-[#14161A] p-[20px] md:p-[32px_35px_25px_32px]">
              <div
                className="flex flex-row items-center"
              >
                <div className="text-[56px] mt-[13px] leading-[56px] md:text-[90px] md:leading-[70px] text-[#F1B01A] font-bold helvetica-neue-65-medium">
                  {index + 1}
                </div>
                <div
                  className={classNames('text-white md:leading-[42px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium', {
                    'ml-[10px]': index !== 0,
                  })}
                >
                  {item?.key}
                </div>
              </div>
              <div className="mt:[5px] md:mt-[5px] text-white md:leading-[22px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman">
                {item?.summary}
                {' '}
              </div>
            </div>
          ))}

        </div>
      )}

    </div>
  );
};
