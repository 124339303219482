import { Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { IEvent } from 'src/models/user-events';
import Image from 'src/mui/components/image';
import Avatar from '@mui/material/Avatar';
import SecondaryBanner from "./Images/SecondaryBanner.jpg"
import { Logo } from 'src/shared-components/V2/Logo';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { useState } from 'react';
import { SessionModal } from './website-event-card';
import { validate } from 'uuid';
import { getJobTitle, getSpeakerCompanyName, getSpeakerName } from 'src/utils';
// import { getCompanyName } from 'src/utils';

type SpeakerPageProps = {
  event?: IEvent;
}

const speakerList: any = [
  { name: 'overview', designation: 'overview', url: '' },
  { name: 'why attend', designation: 'why-attend', url: '' },
  { name: 'agenda', designation: 'agenda', url: '' },
  { name: 'speakers', designation: 'speakers', url: '' },
]

export const SpeakerPage = ({ event }: SpeakerPageProps) => {
  const [isSessionModal, setIsSessionModal] = useState(false);
  const [selectedSessoin, setSelectedSessoin] = useState<any>({});

  const onSessionClick = (session: any) => {
    if (setSelectedSessoin) setSelectedSessoin(session);
    if (setIsSessionModal) setIsSessionModal(true);
  };

  return (
    <Stack
      sx={{
        '@media (min-width: 768px)': {
          maxWidth: '85%',
          margin: 'auto'
        },
      }}
    >
      <Stack sx={{ mb: '1rem' }}>
        <Typography
          variant="body2"
          noWrap
          sx={{
            whiteSpace: 'initial',
            fontSize: '1.9rem',
            mb: '0.5rem',
            color: '#4d144a',
            fontFamily: 'Poppins-600',
            lineHeight: '30px'
          }}
        >
          FEATURED SPEAKERS
        </Typography>
        <Stack sx={{ flexFlow: 'wrap', justifyContent: 'space-between' }}>
          {event?.speakers && event?.speakers.map((item: any, index: any) => (
            <Stack sx={{ width: '100%', maxWidth: '350px', mb: '1rem', cursor: 'pointer' }} onClick={() => onSessionClick(item)}>
              <Stack
                zIndex={index}
                sx={{
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: '350px'
                }}
              >
                <Image
                  src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.avatar}`}
                  alt="speaker"
                />
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    whiteSpace: 'initial',
                    fontSize: '1rem',
                    mb: '0px',
                    color: '#4d144a',
                    fontFamily: 'Poppins-600',
                    lineHeight: "25px",
                    mt: '4px'
                  }}
                >
                  {getSpeakerName(item)}

                </Typography>
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    whiteSpace: 'initial',
                    fontSize: '1rem',
                    mb: '0',
                    color: '#4d144a',
                    fontFamily: 'Poppins-400',
                    textAlign: 'center'
                  }}
                >
                  {getJobTitle(item)}
                </Typography>

                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    whiteSpace: 'initial',
                    fontSize: '1rem',
                    mb: '0px',
                    color: '#4d144a',
                    fontFamily: 'Poppins-600',
                    lineHeight: "25px",
                    mt: '4px'
                  }}
                >
                  {getSpeakerCompanyName(item)}
                </Typography>
              </Stack >
            </Stack >
          ))}
        </Stack >

        < AlertModal
          isOpen={isSessionModal}
          isCloseIcon
          closeIconClass="right-[5px] top-[12px] "
          onClose={() => setIsSessionModal(false)}
          className="w-[1063px] !py-[20px] p-[20px] my-0 overflow-hidden bg-white max-h-[530px] overflow-y-scroll"
        >
          <SessionModal
            session={selectedSessoin}
            event={event}
          />
        </AlertModal >
      </Stack >

      <Image
        src={SecondaryBanner}
        alt={SecondaryBanner}
        sx={{
          display: { md: "none", lg: "none", xl: "none", sm: 'none', xs: "block" },
        }}
      />
    </Stack >
  );
};
