/* eslint-disable max-len */
import React, { useState } from 'react';
import { Stack } from '@mui/material';
import UploadBoxCustom from 'src/mui/components/upload/UploadBoxCustom';
import Papa from 'papaparse';
import { FilUploadService } from 'src/services/FileUpload';
import { toast } from 'react-toastify';
import { Loader } from 'src/shared-components/Loader';
import { OptionType } from '../PMapFieldSelect';
import { UploadAccountStepName } from '../company-enum';

interface Props {
  setHeaders: (headers: OptionType[]) => void;
  setUploadFile: (file: File | string) => void;
  setData: (data: any[]) => void;
  setActiveStep: (step: string) => void;
  setValue: any;
}

export const UploadFile = ({
  setHeaders, setUploadFile, setData, setActiveStep, setValue
}:Props) => {
  const [isLoader, setIsLoader] = useState<boolean>(false);

  const parseCSV = (file: File) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (result.data.length > 1000) {
          toast.error('The CSV file exceeds the maximum number of rows allowed. Please review the requirements and try again.');
          return;
        }
        const headers = result.meta.fields?.map((header) => ({
          id: header,
          name: header,
        }));
        setHeaders(headers || []);
        setData(result.data);
        setActiveStep(UploadAccountStepName.MAP_FIELDS);
      },

      error: (error) => {
        console.error('CSV Parsing Error:', error);
        toast.error('Error parsing CSV file.');
      },
    });
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    setIsLoader(true);
    const fileUploadService = new FilUploadService();
    const file = acceptedFiles?.[0];

    if (file) {
      if (file.type !== 'text/csv') {
        toast.error('Only .csv files are allowed. Please review the requirements and try again.');
        return;
      }
      const maxSizeInMB = 20;
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > maxSizeInMB) {
        toast.error('The file exceeds the maximum size of 20 MB. Please review the requirements and try again.');
        return;
      }
      if (file.type === 'text/csv') {
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response: any = await fileUploadService.uploadDocumentCSV(formData);
          setValue('source', response?.data?.Location);
          setValue('Company', null);
          setValue('Slug', null);
          setValue('Size', null);
          setValue('Industry', null);
          setValue('Head quarters', null);
          setValue('Company website', null);
          if (response?.data?.Location) setIsLoader(false);
        } catch (error) {
          setValue('source', null);
          setValue('Company', null);
          setValue('Slug', null);
          setValue('Size', null);
          setValue('Industry', null);
          setValue('Head quarters', null);
          setValue('Company website', null);
          toast.error(`Upload failed: ${error || 'Unknown error'}`);
        }
      }
      parseCSV(file);
      // Update the state with the uploaded file
      setUploadFile(file);
    }
  };

  return (
    <Stack rowGap="25px" sx={{ position: 'relative' }}>
      <UploadBoxCustom
        accept={{ 'text/csv': ['.csv'] }}
        onDrop={handleDrop}
      // loading={isHandoutLoading}
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          border: 'none',
          padding: '15px !important',
        }}
      />
      {isLoader && <Loader className="pt-0 !absolute rounded-10 left-0 top-0 w-full h-full bg-zinc-600 bg-opacity-25" key="loader" />}
    </Stack>
  );
};

export default UploadFile;
