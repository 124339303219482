import {
  Container,
  Stack,
} from '@mui/material';
import {
  useCallback,
  useEffect, useMemo, useRef, useState
} from 'react';
import { Autoplay, EffectFade, Pagination } from 'swiper';
import { format, parseISO } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from 'react-router';
// import { Loader } from '../../../shared-components/Loader';
import {
  IconButton,
  Tooltip,
} from '@mui/material';
import RegistrationStepForm from 'src/mui/pages/event/reg-dialog-flow';
import { getTickets } from 'src/store/ticket';
import { EventQuestionPageTypes, useSurveyContext } from 'src/mui/providers/GeneralContext';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { getMinifiedImage, getS3Path } from 'src/mui/utils/image';
import { IAppState } from '../../../store';
import { rgbToHex } from '../../../utils/color-transformation';
import { dateAndTimeWithTz } from '../../../utils/date-timezone';
import { ParseContent } from '../../../utils/parse-html';
import { getTimeZone } from '../../../utils/timezones';
import { Logo } from '../../../shared-components/V2/Logo';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { useCurrentUser } from '../../../utils/hooks';
import { getUpcomingEvent } from '../../../store/events/upcoming-event/actions';

export const hideScrollbarY = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

const EventGallery = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const swiperRefLocal = useRef<any>(null);

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };
  const { eventGallery } = useSurveyContext();

  const [showModal, setShowModal] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('Pause');
  const videoRef: React.RefObject<HTMLVideoElement> = useRef(null);
  const [muteVideo, setMuteVideo] = useState(true);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video?.paused || video?.ended) {
      video.play();
      setTooltipTitle('Pause');
    } else {
      video?.pause();
      setTooltipTitle('Play');
    }
  };

  useEffect(() => {
    videoRef?.current?.play();
  }, []);

  const handleQueryParams = useCallback((search: string) => {
    const getQueryParams = (search: string) => {
      const queryParams = new URLSearchParams(search);
      const params: Record<string, string> = {};
      const entries: [string, string][] = Array.from(queryParams.entries());
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of entries) {
        params[key] = value;
      }
      return params;
    };

    const queryParams = getQueryParams(search);
    const { eventFrom } = queryParams;
    if (eventFrom === 'register') {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    const delay = 500;
    const timer = setTimeout(() => {
      handleQueryParams(location.search);
    }, delay);
    return () => clearTimeout(timer);
  }, [location, handleQueryParams]);

  const event = useSelector((state: IAppState) => state.website.event!);

  const { getQuestionPages, setEventIdParam } = useSurveyContext();

  const { upcomingEvent } = useSelector(
    (state: IAppState) => state
  );
  // const audienceStatus = useSelector((state: IAppState) => state?.upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus);

  const audienceStatus = upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus;

  const { registered } = useSelector(
    (state: IAppState) => state.website
  );

  const isImage = (item: any) => item?.slice(0, 5) === 'image';

  useEffect(() => {
    if (user) {
      // dispatch(getUpcomingEvent(event.id));
      dispatch(getTickets(event.id));
    }
  }, [dispatch, event.id, user]);

  useEffect(() => {
    if (event?.id) {
      setEventIdParam(event?.id!);
      if (user) {
        getQuestionPages(EventQuestionPageTypes.REGISTRATION, event?.id!);
        getQuestionPages(EventQuestionPageTypes.SURVEY, event?.id!);
      }
    }
  }, [event?.id, user]);

  const checkLumination = () => {
    if (!event?.backgroundDominantColor?.length) return false;
    const luma = 0.2126 * event?.backgroundDominantColor[0]
      + 0.7152 * event?.backgroundDominantColor[1]
      + 0.0722 * event?.backgroundDominantColor[2];
    return luma <= 139.731;
  };

  const getBackgroungGradient = () => {
    if (!event?.backgroundDominantColor?.length) return '#0D4552';
    return `${rgbToHex(event?.backgroundDominantColor)}`;
  };

  const getTextColor = () => {
    if (checkLumination()) return '#FFFFFF';
    return '#ffffff';
  };

  const getTimeZoneName = (zone: string) => {
    const shortTimeZone = getTimeZone(zone)?.[0]?.abbr ? getTimeZone(zone)?.[0]?.abbr : zone;
    return shortTimeZone;
  };

  const eventDateTimeWithTz = useMemo(() => {
    const startTime = dateAndTimeWithTz(event?.startTime, event?.timezone);
    const endTime = dateAndTimeWithTz(event?.endTime, event?.timezone);
    return {
      startTime,
      endTime
    };
  }, [event?.startTime, event?.endTime, event?.timezone]);

  const eventDateTimeLocalTz = useMemo(() => {
    const startTime = format(parseISO(event?.startTime), 'hh:mm aa')?.replace('.', '')?.replace(' ', '')?.replace('.', '');
    const endTime = format(parseISO(event?.endTime), 'hh:mm aa')?.replace('.', '')?.replace(' ', '')?.replace('.', '');
    const timeZoneMatched = new Date(eventDateTimeWithTz.startTime.dateAndTime).getMinutes()

      === new Date(parseISO(event?.startTime)).getMinutes()

      && new Date(eventDateTimeWithTz.startTime.dateAndTime).getHours()

      === new Date(parseISO(event?.startTime)).getHours();

    return {
      startTime,
      endTime,
      timeZoneMatched
    };
  }, [event?.startTime, event?.endTime]);

  // const handleRegistration = () => {
  //   navigate(`/event/${event.slug}/spectre/vipreg`);
  // };

  const handleRegistration = () => {
    if (!registered) setShowModal(true);
    // if (!registered && event?.isTicketed && user) setOpenTicketModal(true);

    if (user && registered) navigate(`/event/${event?.slug}/discussion`);
    if (
      user
      && registered === false
      && event?.type === 'invitation'
      && audienceStatus === 'accepted'
    ) { navigate(`/event/${event?.slug}/discussion`); }
  };

  const onLoginToRegister = () => {
    window.open('https://members.sit-down.com/auth', '_blank');
  };

  return (
    <Container
      id="highlights"
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        maxWidth: '100%',
        p: 0,
        pt: '50px',
        pb: '50px',
        pl: '15px !important',
        pr: '15px !important',
        '@media (min-width: 768px)': { maxWidth: '750px' },
        '@media (min-width: 992px)': { maxWidth: '970px' },
        '@media (min-width: 1200px)': { maxWidth: '1170px' },
        '@media (min-width: 1280px)': { maxWidth: '1260px' },
      }}
    >
      <Stack
        flexDirection="column"
        sx={{
          backgroundColor: 'rgba(255 255 255 / 20%)',
          // padding: '40px 50px 50px',
          borderRadius: '16px',
          overflow: 'hidden',
          position: 'relative',
          alignItems: 'center',
          // '@media (max-width: 1200px)': { padding: '40px 50px 350px', },
          // '@media (max-width: 768px)': { padding: '30px 20px 280px', },
        }}
      >
        <div className="w-full overflow-x-clip">
      <div className="block md:flex">
        <div
          className="w-full bg-gradient-to-b px-[0px] xl:px-0 relative"
          style={{
            background: `linear-gradient(${getBackgroungGradient()}, transparent)`,
          }}
          onMouseEnter={handleMouseEnter} 
          onMouseLeave={handleMouseLeave}
        >
          <Swiper
            pagination={{
              // dynamicBullets: true,
              clickable: true
            }}
            ref={swiperRefLocal}
            modules={[Pagination, Autoplay, EffectFade]}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
              stopOnLastSlide: false,
              waitForTransition: false
            }}
            effect="fade"
            className="mySwiperBox"
          >
            {
              eventGallery.map(item => {
                if (isImage(item?.key)) {
                  return (
                    <SwiperSlide>
                      <img src={getMinifiedImage(item?.key)} alt="" />
                      {/* <div
                        style={{
                          zIndex: 10,
                          backgroundImage: 'linear-gradient(transparent 35%,#000)',
                          position: 'absolute',
                          top: '0%',
                          bottom: '0%',
                          left: '0%',
                          right: '0%',
                        }}
                      /> */}
                    </SwiperSlide>
                  );
                } else {
                  return (
                    <SwiperSlide>
                      <video ref={videoRef} style={{ width: '100%', height: '100%', objectFit: 'cover', }} loop muted={muteVideo} autoPlay>
                        {' '}
                        <source src={getS3Path(item?.key)} type="video/mp4" />
                        {' '}
                        <source src={getS3Path(item?.key)} type="video/webm" />
                        {' '}
                        <track default kind="captions" />
                        {' '}
                      </video>
                      <div
                        style={{
                          // left: 140,
                          position: 'absolute',
                          textAlignLast: 'end',
                          bottom: 100,
                          opacity: 0.8,
                          zIndex: 100000,
                          right: 20,
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Tooltip title={tooltipTitle}>
                          <IconButton onClick={handlePlayPause} sx={{ color: 'white' }}>
                            {tooltipTitle === 'Pause' ? <PauseIcon /> : <PlayArrowIcon />}
                          </IconButton>
                        </Tooltip>

                        <Tooltip title={!muteVideo ? 'mute' : 'unmute'}>
                          <IconButton onClick={() => setMuteVideo(!muteVideo)} sx={{ color: 'white' }}>
                            {muteVideo ? <VolumeOffIcon /> : <VolumeUpIcon />}
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          zIndex: 10,
                          backgroundImage: 'linear-gradient(transparent 35%,#000)',
                          position: 'absolute',
                          top: '0%',
                          bottom: '0%',
                          left: '0%',
                          right: '0%',
                        }}
                      />
                    </SwiperSlide>
                  );
                }
              })
            }
          </Swiper>
        </div>
      </div>
    </div>
      </Stack>
    </Container>  
  );
};

export default EventGallery;
