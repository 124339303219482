// video-header.tsx => Header for the video player component
import classNames from 'classnames';

import { ReactComponent as Sessions } from '../../../../assets/images/sessions.svg';
import { ReactComponent as MVSessions } from '../../../../assets/images/moderator-session-icon.svg';
import { ReactComponent as SVSessions } from '../../../../assets/images/speaker-session-icon.svg';
import { ReactComponent as Expo } from '../../../../assets/images/expo.svg';
import './RangeInput.css';
import { Logo } from '../../../../shared-components/V2/Logo';

// Props that can be received by this component
type VideoHeaderProps = {
  title: string;
  logoURL: string;
  eventSlug: string;
  videoStatus: 'expo' | 'sessions' | 'msessions' | 'ssessions';
  className?: string;
  hasModerator?:boolean;
  hasSpeaker?:boolean;
  disableExpo?:boolean;
  hostName?: string;
  // eslint-disable-next-line no-unused-vars
  setVideoStatus(stat: string): void;
};

// Renders out the header elements
export const VideoHeader = (props: VideoHeaderProps) => (
  <div className={classNames('sm:px-6 px-3 py-3 flex justify-between items-center bg-white', {
    [`${props.className}`]: props.className
  })}
  >
    <div className="flex items-center gap-2">
      <Logo src={`${props.logoURL}`} className="w-10 h-10 rounded-xl shadow-xl object-contain p-1" defaultLogo="company" />
      <span className="text-lg text-semibold">{props.title}</span>
    </div>
    <div className="flex gap-5">
      {
        props.hasModerator && (
        <div className="text-center">
          <div className="w-7 h-8 m-auto session">
            <MVSessions />
          </div>
          <span className="text-blue-3 lh-14 text-x-sm">Mod View</span>
        </div>
        )
      }
      {
        props.hasSpeaker && (
        <div className="text-center">
          <div className="w-7 h-8 m-auto session">
            <SVSessions />
          </div>
          <span className="text-blue-3 lh-14 text-x-sm">Speaker View</span>
        </div>
        )
      }
      <div className="text-center cursor-pointer" onClick={() => props.setVideoStatus('sessions')}>
        <div className={`w-7 h-8 m-auto ${props.videoStatus !== 'sessions' && 'sessions'}`}>
          <Sessions />
        </div>
        <span className="text-blue-3 lh-14 text-x-sm">Sessions</span>
      </div>
      {
        !props.disableExpo
        && (
        <div className="grid text-center cursor-pointer">
          <div className={`w-7 h-8 m-auto active ${props.videoStatus === 'expo' && 'expo'}`}>
            <a href={`/${props.hostName}/${props.eventSlug}/live?mode=expo`}>
              <Expo />
            </a>
          </div>
          <span className="text-blue-3 lh-14 text-x-sm">Expo</span>
        </div>
        )
      }

    </div>
  </div>
);

export default VideoHeader;
