import {
  IFlagDTO,
  IFlagResult,
} from '../../models/flag/flag';
import BaseService from '../BaseService';

export class FlagService extends BaseService {
  async flagItem(item: IFlagDTO) {
    const extraOptions = this.getAuthToken();

    return this.post<IFlagResult>('/flags', item, extraOptions);
  }
}
