import { differenceInMinutes } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { IPostSpotLight } from '../../models/post/IPostSpotlight';
import { Logo } from '../../shared-components/V2/Logo';
import { ParseContent } from '../../utils/parse-html';

type ISpotlightListProps = {
  title: string;
  spotlightList: IPostSpotLight[];
  id: string;
};

export const MySpotlightList = (props: ISpotlightListProps) => {
  const navigate = useNavigate();
  const seeAllSpotlight = () => {
    navigate(`/wall/${props.id}?tab=spotlight`);
  };
  return (
    <div className="w-full">
      {props?.spotlightList.length ? (
        <>
          <div className="flex flex-col pt-[15px] mb-[5px]">
            <span className="text-[15px] font-normal leading-[18px]  text-[#172746]">
              {props.title}
            </span>
          </div>
          {props?.spotlightList.slice(0, 3).map((item: any, index: any) => (
            <div
              key={index}
              className={`flex items-center cursor-pointer w-[285px] h-[75px] mb-[15px] ${
                index !== 0 ? 'border-t-1 border-[#cecece]' : ''
              }`}
            >
              <div className="flex items-center rounded-10 hover:bg-[#E8EBED] gap-x-[10px] cursor-pointer w-[285px] h-[75px] mt-[15px] pl-2.5 pr-[12px]">
                <div className="min-w-[62px] w-[62px] min-h-[54px] h-[54px] rounded-10 overflow-hidden">
                  {item?.format === 'image' && (
                    <Logo
                      className="object-cover min-w-[62px] w-[62px] min-h-[54px] h-[54px]"
                      src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.source}`}
                      alt={item.content}
                    />
                  )}
                  {item?.format === 'video' && (
                    <video
                      className="w-full object-cover h-full"
                      controls={false}
                    >
                      <source
                        src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.source}`}
                      />
                      <track kind="captions" />
                    </video>
                  )}
                  {item?.format === 'document' && item?.source && (
                    <iframe
                      title={item.content}
                      className="bg-cover w-full h-full"
                      src={`https://docs.google.com/viewerng/viewer?url=${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.source}&embedded=true`}
                    />
                  )}
                </div>
                {/* <img width="62" height="54" className="object-cover min-w-[62px] min-h-[54px]" src={item?.source} alt="avatar" /> */}
                <div className="flex flex-col">
                  <span className="text-[13px] font-semibold leading-[17px] text-[#172746]  mb-[2.1px] line-clamp-2">
                    <ParseContent
                      content={item?.content}
                      className="text-[13px] font-semibold leading-[17px] text-[#172746]  mb-[2.1px] line-clamp-2"
                    />
                  </span>
                  <span className="text-[11px] font-light leading-[14px] text-[#203C6E]">
                    {item?.format}
                    {' '}
                    {item?.format === 'video' && (
                      <span className="text-[11px] font-light leading-[14px] text-[#203C6E]">
                        {Math.floor(
                          differenceInMinutes(
                            new Date(),
                            new Date(item?.createdAt)
                          ) / 3600
                        )}
                        {' '}
                        min
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          ))}
          <button
            className="bg-white hover:bg-[#D8E9FF] text-[#3062B5] text-[13px] font-medium tracking-wide rounded-[5px] h-[31px] w-full see-all-button mt-[5px]"
            type="button"
            onClick={seeAllSpotlight}
          >
            See all
          </button>
        </>
      ) : null}
    </div>
  );
};

export default MySpotlightList;
