import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store';
import { useWebSocket } from '../../utils/hooks';
import useCloseMenuWithClickOutside from '../../utils/hooks/closeMenuWithClickOutside';
import MessageBadge from './message-badge';
import MessageMenu from './message-menu';
import { MessageSvgIcon } from '../../shared-components/V2/IconSvg';


const NavbarMessages = () => {
  const ref = useRef<any>();
  const { showDropdown, handleDropdown } = useCloseMenuWithClickOutside(ref);
  const userNotify = useSelector((state: IAppState) => state.privateChat.userNotify);
  const { webSocket } = useWebSocket();

  useEffect(() => {
    webSocket.then((ws) => {
      ws.send(
        JSON.stringify({
          action: 'user-notify'
        })
      );
    });
  }, [webSocket]);

  let totalUnseenMessages = 0;
  userNotify?.unseenMessages.map((a) => {
    totalUnseenMessages += a.total;
    return totalUnseenMessages;
  });

  const handleChatDropdown = () => {
    handleDropdown(showDropdown);
  };
  useEffect(() => {
    if(!showDropdown) return;
    webSocket.then((ws) =>
      ws.send(
        JSON.stringify({
          action: 'conversations',
          data: { limit: 25, first: 20, exclusiveStartKey: null }
        })
      )
    );
  }, [webSocket, showDropdown]);

  return (
    <div className="messages-nav-button" ref={ref}>
      <div onClick={() => handleChatDropdown()}>
        <MessageSvgIcon />
        <MessageBadge
          countUnseenMessages={totalUnseenMessages}
          classes={'absolute top-0 ml-7 z-10'}
        />
      </div>
      {showDropdown && <div>
        <MessageMenu handleChatDropdown={handleChatDropdown} />
      </div>}
    </div>
  );
};

export default NavbarMessages;
