import { Paper } from '@mui/material';
import { ChromePicker, ColorResult } from 'react-color';
import { Controller, useFormContext } from 'react-hook-form';

interface ColorPickerProps {
    name: string;
    visible: boolean;
    handleClose: () => void;
    color: string;
    handleChange: (newColor: ColorResult | undefined) => void;
    styles?: any
}

const PColourPicker = ({ name, visible, styles, handleChange, handleClose, color }: ColorPickerProps) => {
    const { control, setValue } = useFormContext();
    const onChange = (newColor: ColorResult | undefined) => {
        setValue(name, newColor?.hex || '');
        handleChange(newColor);
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <div>
                    {visible ? (
                        <Paper
                            style={{
                                position: 'absolute',
                                zIndex: '100',
                                overflow: 'visible',
                                ...styles
                            }}
                        >
                            <div
                                style={{
                                    position: 'fixed',
                                    top: '0px',
                                    right: '0px',
                                    bottom: '0px',
                                    left: '0px',
                                }}
                                onClick={handleClose}
                                role="button"
                                onKeyPress={() => { }}
                                tabIndex={0}
                            />
                            <ChromePicker {...field} color={color} onChange={onChange} />
                        </Paper>
                    ) : null}
                </div >
            )}
        />
    );
};
export default PColourPicker;
