import { IGeocodeDetailsResult } from '../../models/address/AddressResult';
import BaseService from '../BaseService';

export class ThirdPartyServices extends BaseService {
  async getAddressFromGeolocation(lat: string, lng: string) {
    const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const urlPrefix = process.env.REACT_APP_REVERSE_GEOCODING_URL;

    const getGeocodeDetailsUrl = `${urlPrefix}?latlng=${lat},${lng}&result_type=street_address&key=${googleAPIKey}`;

    const geocodeDetails = await this.get<IGeocodeDetailsResult>(
      getGeocodeDetailsUrl,
      undefined,
      true
    );

    return geocodeDetails;
  }
}
