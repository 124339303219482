// import { useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// import { init } from '../../../store/event-website';
import { IAppState } from '../../../store';
// import { useCurrentUser } from '../../../utils/hooks';

// import WebsiteEventFooter from '../new-website/website-event-footer';
import { WebsiteEventPreview } from './website-event-preview';
import { WebsiteEventNavbar } from './website-event-navbar';
import { IEvent } from '../../../models/event/event';
import { Loader } from '../../../shared-components/Loader';

const menuItemsVenue = [
  {
    menuItemText: 'Home',
    href: '#banner',
    isSelected: true,
  },
  {
    menuItemText: 'Event focus',
    href: '#event-focus',
    isSelected: false,
  },
  {
    menuItemText: 'Speakers',
    href: '#speakers',
    isSelected: false,
  },
  {
    menuItemText: 'Agenda',
    href: '#agenda',
    isSelected: false,
  },
  {
    menuItemText: 'Partners',
    href: '#partners',
    isSelected: false,
  },
  {
    menuItemText: 'Venue',
    href: '#venue',
    isSelected: false,
  },
  {
    menuItemText: 'Contact us',
    href: '#contact-us',
    isSelected: false,
  },
];

const menuItems = [
  {
    menuItemText: 'Home',
    href: '#banner',
    isSelected: true,
  },
  {
    menuItemText: 'Event focus',
    href: '#event-focus',
    isSelected: false,
  },
  {
    menuItemText: 'Speakers',
    href: '#speakers',
    isSelected: false,
  },
  {
    menuItemText: 'Agenda',
    href: '#agenda',
    isSelected: false,
  },
  {
    menuItemText: 'Partners',
    href: '#partners',
    isSelected: false,
  },
  {
    menuItemText: 'Contact us',
    href: '#contact-us',
    isSelected: false,
  },
];
export const WebsiteEventPageNew = () => {
  // const params = useParams();
  // const eventId: string = String(Object.values(params)[0]);
  // const user = useCurrentUser();
  // const dispatch = useDispatch();
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);

  // useEffect(() => {
  //   dispatch(init(eventId));
  // }, [eventId, dispatch, user]);

  const loaded = useSelector((state: IAppState) => state.website.loaded);

  return loaded ? (
    <div
      className="bg-gray-1"
      style={{
        background: event?.colorStyle?.values?.background,
      }}
    >
      {/* <WebsiteEventNavbarTop /> */}
      <WebsiteEventNavbar menuItems={menuItems} menuItemsVenue={menuItemsVenue} showCreateEventButton />
      <WebsiteEventPreview />
      {/* <WebsiteEventFooter /> */}
    </div>
  ) : <Loader isLoaderLogo className="h-[100vh]" key="loader" />;
};

export default WebsiteEventPageNew;
