import classNames from 'classnames';
import { IRoom } from '../../../models/event/room';

import AccordionLayout from '../../../shared-components/Accordion/AccordionLayout';
import { AgendaTitleBody } from './agenda';
import EventTitleBar from './event-title-bar';

const WebsiteEventDayDetail = ({
  room,
  roomType,
  roomQty,
  customColor,
}: {
  room: IRoom;
  roomType: string;
  roomQty?: number;
  customColor?: string;
}) => {
  if (!room?.sessions?.length) return null;
  return (
    <>
      <div className="w-full">
        <EventTitleBar
          title={room.name}
          roomType={roomType}
          roomQty={roomQty}
          backgroundColor={customColor}
        />
      </div>
      <div className="bg-white px-[20px] py-[20px] rounded-10 mt-[7px] h-[calc(100%-66px)]">
        {room.sessions.map((session, index) => (
          <div
            key={index}
            className={classNames('w-full mb-[30px] last:mb-0')}
          >
            <AccordionLayout
              borderColor={customColor}
              styling="p-0 absolute right-0 top-0"
              roomQty={roomQty}
              titleData={(
                <AgendaTitleBody
                  eventOverview={session.summary}
                  roomQty={roomQty}
                  session={session}
                />
              )}
            // bodyData={}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default WebsiteEventDayDetail;
