import { Reducer } from 'redux';

import { ISignupProfilePictureState } from '../../../../models/signup/SignupProfilePictureState';
import { ProfilePictureActions } from './actions';
import { ProfilePictureActionTypes } from './types';

const initialState: ISignupProfilePictureState = {
  url: ''
};

export const SetSignupProfilePictureReducer: Reducer<
  ISignupProfilePictureState,
  ProfilePictureActions
> = (state = initialState, action) => {
  switch (action.type) {
    case ProfilePictureActionTypes.SET_PROFILE_PICTURE: {
      return {
        ...state,
        url: action.payload.url
      };
    }

    case ProfilePictureActionTypes.PROFILE_PICTURE_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
