import { width, height } from "@mui/system";
import { id } from "date-fns/locale";
import { fill } from "lodash";
import { SVGProps, version } from "react";

function SessionHandOutDownloadIcon(props: SVGProps<SVGSVGElement>) {
  const { style, ...restProps } = props;
   const isFill =  style?.fill;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.169" height="23.833" viewBox="0 0 23.169 23.833" style={style}>
      <path id="download-square-svgrepo-com" d="M2,13.917C2,8.3,2,5.49,3.7,3.745S8.123,2,13.584,2s8.191,0,9.888,1.745,1.7,4.554,1.7,10.171,0,8.426-1.7,10.171-4.427,1.745-9.888,1.745-8.191,0-9.888-1.745S2,19.534,2,13.917ZM13.584,7.065a.882.882,0,0,1,.869.894v6.184l1.992-2.049a.852.852,0,0,1,1.229,0,.912.912,0,0,1,0,1.264L14.2,16.932a.852.852,0,0,1-1.229,0L9.495,13.357a.912.912,0,0,1,0-1.264.852.852,0,0,1,1.229,0l1.992,2.049V7.958A.882.882,0,0,1,13.584,7.065ZM8.951,18.981a.894.894,0,0,0,0,1.787h9.267a.894.894,0,0,0,0-1.787Z" transform="translate(-2 -2)" fill={isFill} fillRule="evenodd" />
    </svg>


  );
}

export default SessionHandOutDownloadIcon;
