import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAutocomplete } from 'src/mui/components/hook-form';
import { IAppState } from 'src/store';
import { getCompanySizes } from 'src/store/company/company-sizes/actions';

type OptionType = {
  id: string;
  name: string;
};

type PIndustryProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  multiple?: boolean;
  defaultValue?: OptionType;
  sx?: any,
  Icon?: any,
  placeholder?: string
  className?: string;
};

const PHeadCountSelect = ({
  Icon, name, sx, label, disabled = false, multiple = false, defaultValue, placeholder, className
}: PIndustryProps) => {
  const dispatch = useDispatch();

  const {
    companySizes,
  } = useSelector((state: IAppState) => state);

  useEffect(() => {
    dispatch(getCompanySizes());
  }, []);

  return (
    <PAutocomplete
      sx={sx}
      className={className}
      onlySelectFromDropdown
      clearOnBlur
      popupIcon={Icon}
      label={label}
      placeholder={placeholder}
      name={name}
      multiple={multiple}
      disabled={disabled}
      fullWidth
      filterSelectedOptions
      {...(defaultValue && { defaultValue })}
      options={companySizes?.value?.data || []}
      getOptionLabel={(option: OptionType | string) => (option as OptionType).name ?? option}
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
    />
  );
};

export default PHeadCountSelect;
