import { ConditionalLink } from '../conditional-link';

interface IErrorMessageProps {
  title?: string;
  message: string;
  linkText?: string;
  link?: string;
}
const ErrorMessage = ({ title, message, linkText, link }: IErrorMessageProps) => (
  <>
    <div className="flex items-start justify-start rounded-10 font-semibold text-gray-1 text-sm1">
      {title}
    </div>
    <div className="flex justify-start">
      <div className="text-gray-1 text-sm1 tracking-wide">
        {message}
        <span>
          <ConditionalLink className="text-sm1 text-blue-1 font-semibold hover:underline" to={link}>
            {linkText}
          </ConditionalLink>
        </span>
      </div>
    </div>
  </>
);

export default ErrorMessage;
