/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import RegistrationStepForm from 'src/mui/pages/event/reg-dialog-flow';
import { useCurrentUser } from 'src/utils/hooks';
import FactorDayImage from '../../images/factor-experience.png';
import ExpertAdvise from '../../images/expert-advise.png';
import PeerDriven from '../../images/peer-driven.png';
import TechnologyPartnerhip from '../../images/technology-partnership.png';
import PlusSvgIcon from '../agenda-accordion/plus-svg-icon';
import MinusSvgIcon from '../agenda-accordion/minus-svg-icon';

interface FactorDayExperienceSectionProps {
  setSelectedTab?: (value: string) => void | any;
}

const FactorDayExperience: React.FC<FactorDayExperienceSectionProps> = ({
  setSelectedTab,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useCurrentUser();

  const [expanded, setExpanded] = useState<string | false>(false);
  const [showModal, setShowModal] = useState(false);

  const { registered } = useSelector(
    (state: IAppState) => state.website
  );
  const { upcomingEvent } = useSelector(
    (state: IAppState) => state
  );

  const event = useSelector((state: IAppState) => state.website.event!);
  const isSurveySubmitted = upcomingEvent?.value?.data?.attendeeStatus?.isSurveySubmitted;
  const audienceStatus = upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus;
  const isRegistered = upcomingEvent?.value?.data?.attendeeStatus?.isRegistered;

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const localInsight: any = [
    {
      title: 'Local Surveys',
      list: ['Over 300 weekly surveys', 'Experience management based on surveys', 'Market reports based on surveys'],
    },
    {
      title: 'Industry benchmarking',
      list: ['Benchmark against other leaders', 'Cross industry content'],
    },
    {
      title: 'Success & failure rates',
      list: ['Understand the latest projects', 'Implementation use cases & outcomes', 'Provider capability surveys'],
    },
    {
      title: 'Local supplier capabilities',
      list: ['Start-ups and local providers', 'Technology hype discussions', '1:1 provider exploratory meetings'],
    },
  ];
  const expertAdvice: any = [
    {
      title: 'Elevator sessions',
      list: ['Concise presentations to showcase innovative ideas', 'Gain insights and feedback from industry experts', 'Opportunity to secure interest from potential investors and partners'],
    },
    {
      title: 'Track sessions',
      list: ['In-depth explorations of key industry topics', 'Interactive discussions led by subject matter experts', 'Tailored content tracks to suit diverse professional interests'],
    },
    {
      title: 'Talk to an Expert sessions',
      list: ['One-on-one consultations with seasoned analysts', 'Personalized analysis of market trends and data', 'Expert advice to inform strategic decisions and growth'],
    },
    {
      title: 'Factor 1:1 meetings',
      list: ['Private meetings for focused discussions', 'Customised insights based on specific business needs', 'Networking opportunities with influential industry players'],
    },
  ];
  const peerDriven: any = [
    {
      title: 'Business driver matching',
      list: ['Align business objectives with relevant topics', 'Identifying key drivers for growth and success', 'Community manager recommendations'],
    },
    {
      title: 'Enclosed community management',
      list: ['Exclusive access to a curated professional network', 'A private platform for sharing insights and experiences', 'Facilitated discussions and collaborative opportunities'],
    },
    {
      title: 'Tailored peer introductions',
      list: ['Personalised connections with industry peers', 'Strategic networking', 'Facilitated peer introductions'],
    },
    {
      title: 'Factor networking & membership app',
      list: ['Seamless access to pre-event networking', 'Centralised hub for member interactions', 'Advanced features to research priorities'],
    },
  ];
  const technologyPartnership: any = [
    {
      title: 'Vendor briefings',
      list: ['Updates from leading tech vendors', 'Insights into new products and services', 'Q&A sessions for detailed info'],
    },
    {
      title: 'Capability benchmarking',
      list: ['Compare against industry standards', 'Identify strengths and gaps', 'Data-driven insights for strategy'],
    },
    {
      title: 'Technology 1:1 meetings',
      list: ['Personalised tech expert discussions', 'Deep dive into specific solutions', 'Tailored advice for business challenges'],
    },
    {
      title: 'Elevator sessions',
      list: ['Quick, impactful presentations', 'Exposure to new ideas and solutions', 'Engage and provide immediate feedback'],
    },
  ];

  const handleProgramPage = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('tab', 'Program');
    navigate({
      pathname: location.pathname,
      search: queryParams.toString()
    });
    if (setSelectedTab) {
      setSelectedTab('Program');
    }
  };

  const handleRegistration = () => {
    setShowModal(true);
  };

  const showPortalButton = () => {
    if (event?.type === 'open') {
      if ((registered || isRegistered)) return true;
      return false;
    }

    if (event?.type === 'invitation') {
      if ((audienceStatus === 'accepted' || (audienceStatus === 'pending' && !isSurveySubmitted)) && (registered || isRegistered)) return true;
      return false;
    }
    return false;
  };

  return (
    <>
      <div className="bg-black text-white mb-4">

        <div className="text-white font-medium helvetica-neue-65-medium text-[22px] leading-[27px]">Factor Day Experience</div>
        <div className="text-white md:leading-[58px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium text-left mt-[0px] mb-[12px]">What you can expect</div>

        <div className="flex flex-col gap-y-[20px]">
          {/* Local Insights */}
          <section className="flex flex-col lg:flex-row gap-[34px] mt-[30px]">
            <div className="w-full lg:w-[770px] ">
              <img
                src={FactorDayImage}
                alt="Local Insights"
                className="object-cover rounded-10"
              />
            </div>
            <div className="w-full lg:w-1/2  pt-[35px] pb-[26px] lg:py-0">
              <h3 className="text-white md:leading-[56px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium">
                Local Insights
              </h3>
              <div className="text-white md:leading-[22px] mb-[20px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman">
                Our research is data-driven and locally focused, providing
                actionable insights tailored to regional needs, helping clients
                make informed decisions and stay ahead in their industries.
              </div>
              <div>
                {localInsight.map((accordian: any, index: number) => (
                  <Accordion
                    key={index}
                    sx={{
                      backgroundColor: 'black',
                      color: 'white',
                      boxShadow: 'none !important',
                      margin: '0px 0px 0px !important',
                    }}
                    expanded={expanded === accordian.title}
                    onChange={handleChange(accordian.title)}
                  >
                    <AccordionSummary
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        pl: '0px',
                        borderBottom: '3px solid #D6244D',
                        minHeight: 'auto !important',
                        borderTop: index === 0 ? '3px solid #D6244D' : null,
                        '& .MuiAccordionSummary-content': {
                          margin: '14px 0px',
                        },
                        '& .Mui-expanded': {
                          margin: '14px 0px !important',
                        },
                      }}
                      expandIcon={
                      expanded === accordian.title ? (
                        <MinusSvgIcon className="w-[18px]" />
                      ) : (
                        <PlusSvgIcon className="w-[18px]" />
                      )
                    }
                    >
                      <Typography
                        className="helvetica-neue-55-roman"
                        sx={{
                          fontSize: '18px',
                          lineHeight: '21px',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }}
                      >
                        {accordian.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ backgroundColor: 'black', color: 'white', p: '0px' }}
                    >
                      <div className="py-[18px]">
                        {accordian?.list.map((item: string, idx: number) => (
                          <Typography
                            key={idx}
                            className="helvetica-neue-55-roman"
                            sx={{
                              fontSize: '16px',
                              lineHeight: '30px',
                              textTransform: 'capitalize',
                              color: '#cacacc',
                              py: '0px',
                            }}
                          >
                            {item}
                          </Typography>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
              <button
                type="button"
                className={classNames(
                  'mt-[25px] sm:px-[35px] px-[22px] md:h-[40px] h-[40px] bg-transparent border-2 border-white rounded-5 flex items-center justify-center text-white md:text-[16px] text-[15px] leading-[40px] helvetica-neue-W05_85-heavy'
                )}
                onClick={handleProgramPage}
              >
                <div className="h-[40px]">
                  View Agenda
                </div>
              </button>
            </div>
          </section>

          {/* Expert Advice */}
          <section className="flex flex-col lg:flex-row gap-[34px] mt-[30px]">
            <div className="order-1 lg:order-2 w-full lg:w-[770px] ">
              <img
                src={ExpertAdvise}
                alt="Expert Advice"
                className="object-cover rounded-10"
              />
            </div>
            <div className="w-full lg:w-1/2  pt-[35px] pb-[26px] lg:py-0">
              <h3 className="text-white md:leading-[56px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium">
                Expert Advice
              </h3>
              <div className="text-white md:leading-[22px] mb-[20px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman">
                Engage with Factor local experts to delve into your most pressing
                challenges. We provide industry-driven solutions and tailored
                expert advice that drives effective solutions.
              </div>
              <div>
                {expertAdvice.map((accordian: any, index: number) => (
                  <Accordion
                    key={index}
                    expanded={expanded === accordian.title}
                    onChange={handleChange(accordian.title)}
                    sx={{
                      backgroundColor: 'black',
                      color: 'white',
                      boxShadow: 'none !important',
                      margin: '0px 0px 0px !important',
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        pl: '0px',
                        borderBottom: '3px solid #D6244D',
                        minHeight: 'auto !important',
                        borderTop: index === 0 ? '3px solid #D6244D' : null,
                        '& .MuiAccordionSummary-content': {
                          margin: '14px 0px',
                        },
                        '& .Mui-expanded': {
                          margin: '14px 0px !important',
                        },
                      }}
                      expandIcon={
                      expanded === accordian.title ? (
                        <MinusSvgIcon className="w-[18px]" />
                      ) : (
                        <PlusSvgIcon className="w-[18px]" />
                      )
                    }
                    >
                      <Typography
                        className="helvetica-neue-55-roman"
                        sx={{
                          fontSize: '18px',
                          lineHeight: '21px',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }}
                      >
                        {accordian.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ backgroundColor: 'black', color: 'white', p: '0px' }}
                    >
                      <div className="py-[18px]">
                        {accordian?.list.map((item: string, idx: number) => (
                          <Typography
                            key={idx}
                            className="helvetica-neue-55-roman"
                            sx={{
                              fontSize: '16px',
                              lineHeight: '30px',
                              textTransform: 'capitalize',
                              color: '#cacacc',
                              py: '0px',
                            }}
                          >
                            {item}
                          </Typography>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
              <button
                type="button"
                className={classNames(
                  'mt-[25px] sm:px-[35px] px-[22px] md:h-[40px] h-[40px] bg-transparent border-2 border-white rounded-5 flex items-center justify-center text-white md:text-[16px] text-[15px] leading-[40px] helvetica-neue-W05_85-heavy'
                )}
                onClick={() => {
                  if (setSelectedTab) {
                    const queryParams = new URLSearchParams(location.search);
                    queryParams.set('tab', 'Speakers');
                    navigate({
                      pathname: location.pathname,
                      search: queryParams.toString()
                    });
                    setSelectedTab('Speakers');
                  }
                }}
              >
                <div className="h-[40px]">
                  View Experts
                </div>
              </button>
            </div>
          </section>

          {/* Peer-Driven Networking */}
          <section className="flex flex-col lg:flex-row gap-[34px] mt-[30px]">
            <div className="w-full lg:w-[770px] ">
              <img
                src={PeerDriven}
                alt="Peer Driver"
                className="object-cover rounded-10"
              />
            </div>
            <div className="w-full lg:w-1/2  pt-[35px] pb-[26px] lg:py-0">
              <h3 className="text-white md:leading-[56px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium">
                Peer-Driven Networking
              </h3>
              <div className="text-white md:leading-[22px] mb-[20px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman">
                Personalised connections with peers that enhance your industry knowledge through collaborative purpose driven
                Networking sessions based on your objectives.
              </div>
              <div>
                {peerDriven.map((accordian: any, index: number) => (
                  <Accordion
                    key={index}
                    expanded={expanded === accordian.title}
                    onChange={handleChange(accordian.title)}
                    sx={{
                      backgroundColor: 'black',
                      color: 'white',
                      boxShadow: 'none !important',
                      margin: '0px 0px 0px !important',
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        pl: '0px',
                        borderBottom: '3px solid #D6244D',
                        minHeight: 'auto !important',
                        borderTop: index === 0 ? '3px solid #D6244D' : null,
                        '& .MuiAccordionSummary-content': {
                          margin: '14px 0px',
                        },
                        '& .Mui-expanded': {
                          margin: '14px 0px !important',
                        },
                      }}
                      expandIcon={
                      expanded === accordian.title ? (
                        <MinusSvgIcon className="w-[18px]" />
                      ) : (
                        <PlusSvgIcon className="w-[18px]" />
                      )
                    }
                    >
                      <Typography
                        className="helvetica-neue-55-roman"
                        sx={{
                          fontSize: '18px',
                          lineHeight: '21px',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }}
                      >
                        {accordian.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ backgroundColor: 'black', color: 'white', p: '0px' }}
                    >
                      <div className="py-[18px]">
                        {accordian?.list.map((item: string, idx: number) => (
                          <Typography
                            key={idx}
                            className="helvetica-neue-55-roman"
                            sx={{
                              fontSize: '16px',
                              lineHeight: '30px',
                              textTransform: 'capitalize',
                              color: '#cacacc',
                              py: '0px',
                            }}
                          >
                            {item}
                          </Typography>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
              <button
                type="button"
                className={classNames(
                  'mt-[25px] sm:px-[35px] px-[22px] md:h-[40px] h-[40px] bg-transparent border-2 border-white rounded-5 flex items-center justify-center text-white md:text-[16px] text-[15px] leading-[40px] helvetica-neue-W05_85-heavy'
                )}
                onClick={() => handleRegistration()}
              >
                <div className="h-[40px]">
                  { showPortalButton() ? 'Enter the Portal & Start Networking' : 'Register'}
                </div>
              </button>
            </div>
          </section>

          {/* Technology Partnerships */}
          <section className="flex flex-col lg:flex-row gap-[34px] mt-[30px]">
            <div className="w-full lg:w-1/2  pt-[35px] pb-[26px] lg:py-0">
              <h3 className="text-white md:leading-[56px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium">
                Technology Partnerships
              </h3>
              <div className="text-white md:leading-[22px] mb-[20px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman">
                Access insights into market innovation and problem-solving through curated technology partners, offering solutions that are specifically aligned with your needs and strategic goals.
              </div>
              <div>
                {technologyPartnership.map((accordian: any, index: number) => (
                  <Accordion
                    key={index}
                    expanded={expanded === accordian.title}
                    onChange={handleChange(accordian.title)}
                    sx={{
                      backgroundColor: 'black',
                      color: 'white',
                      boxShadow: 'none !important',
                      margin: '0px 0px 0px !important',
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        pl: '0px',
                        borderBottom: '3px solid #D6244D',
                        minHeight: 'auto !important',
                        borderTop: index === 0 ? '3px solid #D6244D' : null,
                        '& .MuiAccordionSummary-content': {
                          margin: '11px 0px',
                        },
                        '& .Mui-expanded': {
                          margin: '11px 0px !important',
                        },
                      }}
                      expandIcon={
                      expanded === accordian.title ? (
                        <MinusSvgIcon className="w-[18px]" />
                      ) : (
                        <PlusSvgIcon className="w-[18px]" />
                      )
                    }
                    >
                      <Typography
                        className="helvetica-neue-55-roman"
                        sx={{
                          fontSize: '18px',
                          lineHeight: '21px',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }}
                      >
                        {accordian.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ backgroundColor: 'black', color: 'white', p: '0px' }}
                    >

                      <div className="py-[18px]">
                        {accordian?.list.map((item: string, idx: number) => (
                          <Typography
                            key={idx}
                            className="helvetica-neue-55-roman"
                            sx={{
                              fontSize: '16px',
                              lineHeight: '30px',
                              textTransform: 'capitalize',
                              color: '#cacacc',
                              py: '0px',
                            }}
                          >
                            {item}
                          </Typography>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
              <button
                type="button"
                className={classNames(
                  'mt-[25px] sm:px-[35px] px-[22px] md:h-[40px] h-[40px] bg-transparent border-2 border-white rounded-5 flex items-center justify-center text-white md:text-[16px] text-[15px] leading-[40px] helvetica-neue-W05_85-heavy'
                )}
                onClick={() => {
                  if (setSelectedTab) {
                    const queryParams = new URLSearchParams(location.search);
                    queryParams.set('tab', 'Sponsors');
                    navigate({
                      pathname: location.pathname,
                      search: queryParams.toString()
                    });
                    setSelectedTab('Sponsors');
                  }
                }}
              >
                <div className="h-[40px]">
                  View Event Partners
                </div>
              </button>
            </div>
            <div className="order-1 lg:order-2 w-full lg:w-[770px]">
              <img
                src={TechnologyPartnerhip}
                alt="Technology Partnerships"
                className="object-cover rounded-10"
              />
            </div>
          </section>
        </div>
      </div>

      {
  showModal && (
    <RegistrationStepForm
      eventId={event?.id!}
      user={user}
      open={showModal}
      registered={registered!}
      eventInvitationStatus={audienceStatus!}
      eventType={event?.type!}
      setOpenForm={() => setShowModal(false)}
    />
  )
}
    </>
  );
};

export default FactorDayExperience;
