import WebsiteEventFooter from './website-event-footer';

import { ReactComponent as PinterestIcon } from '../../../assets/images/social-pinterest.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/images/social-facebook.svg';
import { ReactComponent as InstaIcon } from '../../../assets/images/social-instagram.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/images/social-linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/images/social-twitter.svg';
import PlayStore from '../../../assets/images/download-store/playstore.png';
import AppStore from '../../../assets/images/download-store/appstore.png';

const WebsiteEventQuickLinks = () => {
  const workWithSD = [
    { name: 'Insights', link: 'https://www.sit-down.com/privacy-policy' },
    { name: 'Sit-Downs', link: 'https://www.sit-down.com/about-us' },
    { name: 'Talkshows', link: 'https://www.sit-down.com/about-us' },
    { name: 'Leadership Programs', link: 'https://www.sit-down.com/about-us' },
  ];

  const goalsLinks = [
    { name: 'CIO', link: 'https://www.sit-down.com/sit-downs/cio' },
    { name: 'CDO', link: 'https://www.sit-down.com/sit-downs/cdo' },
    { name: 'CFO', link: 'https://www.sit-down.com/sit-downs/cfo' },
    { name: 'CSCO', link: 'https://www.sit-down.com/sit-downs/csco' },
    { name: 'CISO', link: 'https://www.sit-down.com/sit-downs/ciso' },
    { name: 'DevOps', link: 'https://www.sit-down.com/#:~:text=CISO-,DevOps,-Services' },
  ];

  const serviceLinks = [
    { name: 'Memberships', link: 'https://www.sit-down.com/privacy-policy' },
    { name: 'Partnerships', link: 'https://www.sit-down.com/about-us' },
  ];

  const RegionsLinks = [
    { name: 'Privacy', link: 'https://www.sit-down.com/privacy-policy' },
    { name: 'Vision & Mission', link: 'https://www.sit-down.com/about-us' },
    { name: 'User Agreement', link: 'https://www.sit-down.com/about-us' },
  ];

  const valuesLinks = [
    { name: 'Vision', link: 'https://www.sit-down.com/about-us' },
    { name: 'Contact', link: 'https://www.sit-down.com/contact-us' },
  ];

  const socialIconLinks = [
    { icon: PinterestIcon, link: 'https://panelist.com/wall/company/sit-down?_gl=1*o42wy1*_ga*NTY5NDQ5ODM4LjE2ODQ4NzYzODk.*_ga_EKBWM5S0V8*MTcxMDQxMjQ2Ny4zNy4wLjE3MTA0MTI0ODUuMC4wLjA.' },
    { icon: FacebookIcon, link: 'https://www.facebook.com/sitdowninsights' },
    { icon: InstaIcon, link: 'https://www.instagram.com/sitdowninsights/' },
    { icon: LinkedinIcon, link: 'https://www.linkedin.com/company/cxo-sitdown' },
    { icon: TwitterIcon, link: 'https://twitter.com/sitdownevent' },
  ];

  return (
    <div className="w-full mb-[0px] mt-[30px] md:px-0 px-[20px]">
      <div className="flex">
        <div className="w-full pt-[40px]">
          <div className="md:max-w-[91%] max-w-[97%] mx-auto ">
            <div className="w-full flex flex-col justify-center items-center">
              {/* second row    */}
              <div className="w-full grid grid-cols-2 md:flex md:gap-0 gap-[20px] justify-between items-start">

                <div className="flex flex-col">
                  <div className="text-[15px] leading-[17px] helvetica-neue-65-medium text-white block w-full font-medium mb-[14px]">
                    Products
                  </div>
                  {workWithSD?.map((item, index) => (
                    <a
                      href={`${item.link}`}
                      key={index}
                      className="text-[15px] leading-[23px] font-[400] text-white hover:underline helvetica-neue-35-thin-wide"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>

                <div className="flex flex-col">
                  <div className="text-[15px] leading-[17px] helvetica-neue-65-medium text-white block w-full font-medium mb-[14px]">
                    Sit-Downs
                  </div>
                  {goalsLinks?.map((item, index) => (
                    <a
                      href={`${item.link}`}
                      key={index}
                      className="text-[15px] leading-[23px] font-[400] text-white hover:underline helvetica-neue-35-thin-wide"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>

                <div className="flex flex-col md:mt-0 -mt-[48px]">
                  <div className="text-[15px] leading-[17px] helvetica-neue-65-medium text-white block w-full font-medium mb-[14px]">
                    Services
                  </div>
                  {serviceLinks?.map((item, index) => (
                    <a
                      href={`${item.link}`}
                      key={index}
                      className="text-[15px] leading-[23px] font-[400] text-white hover:underline helvetica-neue-35-thin-wide"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>

                <div className="flex flex-col">
                  <div className="text-[15px] leading-[17px] helvetica-neue-65-medium text-white block w-full font-medium mb-[14px]">
                    Values
                  </div>
                  {RegionsLinks?.map((item, index) => (
                    <a
                      href={`${item.link}`}
                      key={index}
                      className="text-[15px] leading-[23px] font-[400] text-white hover:underline helvetica-neue-35-thin-wide"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>

                <div className="flex flex-col md:mt-0 -mt-[74px]">
                  <div className="text-[15px] leading-[17px] helvetica-neue-65-medium text-white block w-full font-medium mb-[14px]">
                    About
                  </div>
                  {valuesLinks?.map((item, index) => (
                    <a
                      href={`${item.link}`}
                      key={index}
                      className="text-[15px] leading-[23px] font-[400] text-white hover:underline helvetica-neue-35-thin-wide"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>

                <div className="md:flex hidden flex-col">
                  <div className="text-[15px] leading-[17px] helvetica-neue-65-medium text-white block w-full font-medium mb-[16px]">
                    Download on:
                  </div>
                  <div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://apps.apple.com/hr/app/sit-down/id6449270855"
                      className="hover:underline cursor-pointer block mb-5"
                    >
                      <img className="max-w-[190px] border-1 border-white p-1 rounded-[5px]" src={AppStore} alt="App Store" />
                    </a>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.sitdown.app&hl=en_GB&gl=US"
                      className="hover:underline cursor-pointer"
                    >
                      <img className="max-w-[190px] border-1 border-white p-1 rounded-[5px]" src={PlayStore} alt="Play Store" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center md:hidden mt-6">
              <div className="text-[15px] text-center leading-[17px] helvetica-neue-65-medium text-white block w-full font-medium mb-[16px]">
                Download on:
              </div>
              <div>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://apps.apple.com/hr/app/sit-down/id6449270855"
                  className="hover:underline cursor-pointer block mb-5"
                >
                  <img className="max-w-[190px] border-1 border-white p-1 rounded-[5px]" src={AppStore} alt="App Store" />
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.sitdown.app&hl=en_GB&gl=US"
                  className="hover:underline cursor-pointer"
                >
                  <img className="max-w-[190px] border-1 border-white p-1 rounded-[5px]" src={PlayStore} alt="Play Store" />
                </a>
              </div>
            </div>
            <div className="flex items-center md:justify-end justify-center gap-x-[10px] pt-[53px] pb-[32px] border-b border-[#707070] footer-socials">
              {socialIconLinks?.map((item, index) => (
                <a
                  key={index}
                  rel="noreferrer"
                  target="_blank"
                  href={item.link}
                  className="w-[35px] h-[35px] cursor-pointer"
                >
                  <item.icon />
                </a>
              ))}
            </div>
          </div>
          <WebsiteEventFooter />
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventQuickLinks;
