/* eslint-disable jsx-a11y/media-has-caption */
import classNames from 'classnames';
import { Avatar } from '../../shared-components/V2';
import Timer from './timer';
import AudioRingTone from '../../assets/audio/bells_sms.mp3';
import './call-notification.css';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

interface ICallingNotification {
  title: string;
  timer?: number;
  onTimeout?: Function;
  avatar?: string;
  disableAcceptBtn?: boolean;
  disableRejectBtn?: boolean;
  acceptBtnText?: string;
  rejectBtnText?: string;
  onRejectCall?: Function;
  onAcceptCall?: Function;
  className?: string;
}

export const CallingNotification = (props: ICallingNotification) => {
const colors = getBrandingColors();
  return (
    <div
      className={classNames("flex fixed top-0 left-0 w-full h-full justify-center item-center", {
        [`${props.className}`]: props.className
      })}
    >
      <div
        className={classNames("bg-white py-4 px-11 rounded-[10px] m-auto", {})}
        style={{ width: 400, border: `1px solid ${colors?.Accent}`, backgroundColor:  colors?.Main || "#ffffff"}}
        >
        <span
          className={classNames("block text-lg text-center", {})}
          style={{
            color: colors?.Text || "#000000"
          }}
          >{props.title}</span>
        {
          !!props.avatar && (
            <div className="flex flex-col items-center justify-center pb-4 text-blue-800 mt-6">
              {props.timer ? (
                <div className='flex items-center justify-center'>
                  <>
                    <audio loop autoPlay>
                      <track />
                      <source src={AudioRingTone} type="audio/mp3" />
                    </audio>
                    <Timer initialSeconds={props.timer} onTimeout={() => props.onTimeout!()}>
                      <div className="flex-shrink-0 call-animation overflow-hidden">
                        <Avatar src={props.avatar} size="LARGE"/>
                      </div>
                    </Timer>
                  </>
                </div>
              ) : (
                <div className="flex-shrink-0 call-animation">
                  <Avatar src={props.avatar} size="LARGE" />
                </div>
              )}
            </div>)
        }
        <div className='pt-4 flex flex-row items-center justify-center'>
        {
            !!props.onAcceptCall && (
              <button
                type="button"
                disabled={!!props.disableAcceptBtn}
                className={classNames(' py-1 px-6 rounded-[5px] mx-2 mt-4', {
                  'bg-gray-500': !!props.disableAcceptBtn
                })}
                style={{
                  color: colors?.Text || "#ffffff",
                  backgroundColor: colors?.Accent || "#0049EF",
                }}
                onClick={() => {
                  props.onAcceptCall!();
                }}
              >
                {props.acceptBtnText || 'Accept'}
              </button>
            )
          }
          {
            !!props.onRejectCall && (
              <button
                type="button"
                disabled={!!props.disableRejectBtn}
                className={classNames(' py-1 px-6 rounded-[5px] mx-2 mt-4', {
                  'bg-gray-500': !!props.disableRejectBtn
                })}
                style={{
                  color: colors?.Text || "#ffffff",
                  backgroundColor: colors?.Secondary || "#b61010",
                }}
                onClick={() => {
                  props.onRejectCall!();
                }}
              >
                {props.rejectBtnText || 'Cancel'}
              </button>
            )
          }
          
        </div>
      </div>
    </div>
  );
}
