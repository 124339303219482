import { FC } from 'react';
import { Avatar } from '../Avatar';
import { Button } from '../Button';

import classNames from 'classnames';
import { Logo } from '../Logo';

export interface ICardProfileConnectProps {
  id: string;
  firstName: string;
  lastName: string;
  slug: string
  avatar: string;
  cover: string;
  jobTitle: string;
  companySlug?: string | undefined;
  [location: string]: any
  onClickConnect?: Function;
  onClickMessage?: Function;
  onUserClick?: Function;
  onCompanyClick?: Function;
  className?: string;
  isOwnProfile?: boolean;
  logoClass?: string;
  mutualConnections?:number
}

export const CardProfileConnect: FC<ICardProfileConnectProps> = ({
  id,
  firstName,
  lastName,
  slug,
  companySlug,
  avatar,
  cover,
  jobTitle,
  location,
  onClickConnect,
  onClickMessage,
  onUserClick,
  onCompanyClick,
  className,
  isOwnProfile,
  logoClass,
  mutualConnections
}) => {

  return (
    <div className={classNames("bg-white rounded-[12px]", {[`${className}`]: className})}>
      <Logo className="rounded-[12px] object-cover h-[59px] w-full" src={cover} alt="cover" />
      <div
        className='w-full flex justify-center -mt-[42px] mb-[10px] cursor-pointer'
        onClick={() => onUserClick!(`/wall/${slug}`)}
        aria-label="card-profile-avatar"
      >
        <Avatar
          alt={`${firstName} ${lastName}`}
          src={avatar}
          className={classNames("border-2 border-white", {[`${logoClass}`]: logoClass})}
          size="LARGE"
        />
      </div>

      <div className="text-center mb-2.5 px-[10px]">
        <span
          className="cursor-pointer"
          onClick={() => onUserClick!(`/wall/${slug}`)}
          aria-label="card-profile-name"
        >
          <div className="line-clamp-1 text-sm h-[19px] font-[600] text-[#172746] capitalize">{`${firstName} ${lastName}`}</div>
        </span>

        <span
          className="cursor-pointer"
          aria-label="card-profile-jobtitle"
        >
          <div className="line-clamp-1 text-[12px] h-[17px] text-[#172746] font-[400] mb-[1px]">{jobTitle}</div>
        </span>

        <span
          className="cursor-pointer"
          aria-label="card-profile-company"
        >
          <div onClick={() => companySlug && onCompanyClick!(`/wall/company/${companySlug}`)}
              className="line-clamp-1 text-sm h-[19px] font-[600] text-[#172746]">{location}</div>
        </span>

      {/* {mutualConnections ? <div className="line-clamp-1 text-[12px] h-[17px] text-[#172746] font-[400] mb-[2px]">{ `${mutualConnections} mutual connections`} </div> : <div className="line-clamp-1 text-[12px] h-[17px] text-[#172746] font-[400] mb-[2px]">{''}</div> } */}

      </div>

      <div className="text-center pb-2">
        {!isOwnProfile && onClickConnect && <Button
          className="text-white h-[31px] w-[98px] bg-[#0049EF] hover:bg-[#2e6eff] !rounded-5 tracking-wider"
          type="button"
          size="small"
          text="Connect"
          onClick={() => onClickConnect!(id)}
        />}
        {!isOwnProfile && onClickMessage && <Button
          className="ml-3 bg-gray-200 text-blue"
          type="button"
          size="small"
          text="Message"
          onClick={() => onClickMessage(id)}
        />}
      </div>
    </div>
  );
};
