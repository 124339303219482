import React, { FC } from 'react';
import { UserNavbar } from '../components';

interface ICompanyLayoutProps {
  hero?: JSX.Element;
  leftPanel: JSX.Element;
  rightPanel: JSX.Element;
  children: JSX.Element;
  isHideNavbar?: boolean;
}
export const CompanyLayout: FC<ICompanyLayoutProps> = ({
  hero,
  leftPanel,
  rightPanel,
  children,
  isHideNavbar
}) => (
  <div className="bg-gray-1 h-full">
    {!isHideNavbar && <UserNavbar />}
    <div>
      <div className="lg:container mx-auto lg:w-[1258px] w-full lg:px-0 px-4 md:pb-0 pb-[60px] mt-[35px]">
        <div className=" !rounded-t-[25px] !rounded-b-[20px] overflow-hidden mx-auto">
          {hero}
        </div>
        <div className="flex md:flex-row flex-col justify-center pt-4">
          <div className="flex flex-col pb-2 md:w-[303px] w-full">{leftPanel}</div>
          <div className="md:mb-8 lg:w-[594px] md:w-[67%] w-full lg:mr-6 lg:ml-6 md:ml-6">{children}</div>
          <div className="lg:flex flex-col w-[315px] hidden">{rightPanel}</div>
        </div>

      </div>
    </div>
  </div>
);

export default CompanyLayout;
