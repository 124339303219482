import { Reducer } from 'redux';

import { ICompanyEventsState } from '../../../models/company/CompanyEventsState';
import { CompanyEventsActions } from './actions';
import { CompanyEventsActionTypes } from './types';

const initialState: ICompanyEventsState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const CompanyEventsReducer: Reducer<ICompanyEventsState, CompanyEventsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CompanyEventsActionTypes.COMPANY_EVENTS_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case CompanyEventsActionTypes.COMPANY_EVENTS_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case CompanyEventsActionTypes.COMPANY_EVENTS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
