import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { IEditPost } from "src/models/post/edit-post";
import { getBrandingColors } from "src/mui/pages/event/live/liveEventStyling";
import { parseNotficationString } from "src/utils/notification";

interface IPostContentCompanyPostProps {
  content: string;
  format?: string;
  isCompanyNew?: boolean;
  isLivePage?: boolean;
}

export const PostContentCompanyPost = (props: IPostContentCompanyPostProps) => {
  const dispatch = useDispatch();
  const colors = getBrandingColors();
  const { register, setValue, getValues, handleSubmit } = useForm<IEditPost>();
  const [clamped, setClamped] = useState(true);
  let cleanString = props?.content?.replace(/<br\s*\/?>/gi, "\n");
  cleanString = cleanString?.replace(/<[^>]*>/g, "");
  const [showButton] = useState(cleanString?.length > 270);
  const isContentHTML = /<\/?[a-z][\s\S]*>/i.test(cleanString);
  const isLink = cleanString?.startsWith("http");
  const handleClick = () => setClamped(!clamped);

  return (
    <div
      className={classNames(
        "px-[20px]", {
          'px-[9px]': props?.isLivePage,
        }
      )}
    >
      <div
        className={classNames(
          "text-[13px] font-normal text-[#000000] leading-[17px] mb-2.5", {
            "h-[188px]": props.format === "text" && props.isCompanyNew,  // Height 292px when format is "text" and isCompanyNew is true
            "h-[200px]": props.format === "text" && !props.isCompanyNew,
            '!text-[11px] !leading-[16px] !mb-2': props?.isLivePage,
          }
        )}
        style={{
          color: props?.isLivePage ? colors?.Text : undefined,
        }}
      >
        <div
          className={classNames('', {
            "!line-clamp-3": props.format === "text",
            "line-clamp-1 h-[17px]": props.format !== "text",
          })}
        >
          {parseNotficationString(props?.content, null, true, false, dispatch)}
        </div>
        {showButton && !props.isCompanyNew&& (
          <button
            type="button"
            className="hover:underline hover:decoration-2 text-[13px] leading-[17px] text-[#172746] font-[700]"
            onClick={handleClick}
            style={{
              color: props?.isLivePage ? colors?.Accent : undefined, fontSize: props?.isLivePage ? '11px' : undefined,
            }}
          >
            see {clamped ? "more" : "less"}
          </button>
        )}
      </div>
    </div>
  );
};

export default PostContentCompanyPost;
