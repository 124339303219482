const CommunityContentTwo = () => (
  <>
    <hr className="my-10" />
    <div id="tell-us-if-something-wrong" className="mt-7 text-gray-1 text-lg5">
      Tell us if you see something wrong
    </div>

    <div className="mt-5">
      If you see something you believe may be contrary to our policies and guidelines, please report
      it to us as soon as you become aware of it. We will investigate genuine issues within 20 days.
    </div>
    <hr className="my-10" />
    <div id="abusing-our-community-guidelines" className="mt-7 text-gray-1 text-lg5">
      Abusing our community guidelines can result in action against your content or account
    </div>
    <div className="mt-3">
      Our policies apply to all members of this community. Depending on the severity of abuse, we
      may decide to limit or remove certain content you have posted. If you believe something was
      removed in error, you can contact us and let us know. Repeated or severe offenses can result
      in a temporary restriction or permanent ban from Panelist. If you believe action taken on your
      account was in error, you can contact us to appeal the action.
    </div>

    <div id="berespectful" className="mt-7 text-gray-1 text-lg5">
      Be respectful
      <div className="ml-10 text-md2">
        <div className="mt-3">● We require every member to behave respectfully on Panelist.</div>
        <div className="mt-3">
          ● We don’t allow content that attacks, denigrates, intimidates, dehumanises, incites or
          threatens hatred, violence, prejudicial or discriminatory action against individuals or
          groups because of their actual or perceived race, ethnicity, national origin, caste,
          gender, gender identity, sexual orientation, religious affiliation, or disability status.
          Hate groups are not permitted on Panelist. Do not use racial, religious, or other slurs
          that incite or promote hatred, or any other content intended to create division.
        </div>
        <div className="mt-3">
          ● We don’t allow content or activities that promote, organise, depict, or facilitate
          criminal activity. We also don’t allow content depicting or promoting instructional weapon
          making, drug abuse, and threats of theft.
        </div>
        <div className="mt-3">
          ● We do not condone and we prohibit any terrorist organisations or violent extremist
          groups on our platform. Individuals who affiliate with such organisations or groups to
          promote their activities are also strictly prohibited. Content that depicts terrorist
          activity, that is intended to recruit for terrorist organisations, or threatens, promotes,
          or supports terrorism in any manner is not tolerated.
        </div>
        <div className="mt-3">
          ● Panelist is not a dating website and does not tolerate unwanted expressions of
          attraction, desire, requests for a romantic relationship, marriage proposals, sexual
          advances or innuendo, or lewd remarks. Do not use Panelist to pursue romantic connections
          of any nature, or comment inappropriately on someone’s appearance. Nor should you share
          content that is sexually explicit in nature. Panelist does not condone or allow people who
          engage in or promote non-consensual sexually explicit content (e.g., revenge porn), escort
          services, prostitution, exploitation of children, or human trafficking.
        </div>
        <div className="mt-3">
          ● Do not share junk mail, spam, chain letters, phishing schemes, or any other scams are
          also prohibited.
        </div>
        <div className="mt-3">
          ● We don’t allow bullying or harassment. This includes abusive language, revealing others’
          personal or sensitive information (aka “doxing”), or incitement or participation with
          others in activity of this nature. Do not glorify or support violence or others who speak
          violently or promote violence.
        </div>
        <div className="mt-3">
          ● We don't allow content that is excessively gruesome or shocking such as content that is
          sadistic or gratuitously graphic. Do not share content or activities that promote or
          encourage suicide or any type of self-injury, harm, or self-mutilation. If you see signs
          that someone may be considering self-harm or suicide, please contact us immediately and
          notify the appropriate authority in your region if the situation is an emergency.
        </div>
      </div>
    </div>

    <div id="behonest" className="mt-7 text-gray-1 text-lg5">
      Be honest
      <div className="ml-10 text-md2">
        <div className="mt-3">
          ● We require you to use your true identity on Panelist, provide accurate information about
          yourself or your organisation, and only share information that is real and authentic.
        </div>
        <div className="mt-3">
          ● Do not share content in a way that you know is, or think may be, misleading or
          inaccurate, including misinformation or disinformation. Do not share content to interfere
          with or improperly influence an election or another civic process. We may prevent you from
          posting content from sites that are known to produce or contain misinformation. Do not
          share content that directly contradicts guidance from leading global health organisations
          and public health authorities. Do not post content that denies a well-documented
          historical event such as the Holocaust or slavery. Do not share false content or
          information, including news stories, that present untrue facts or events as though they
          are true or likely true. Do not post “deepfake” images or videos of others or otherwise
          post content that has been manipulated to deceive. Do not share content or endorse someone
          or something in exchange for personal benefit (including personal or family relationships,
          monetary payment, free products or services, or other value), without including a clear
          and conspicuous notice of the personal benefit you receive.
        </div>
        <div className="mt-3">
          ● We don’t allow fake profiles or entities. Do not post misleading or deceptive
          information about yourself, your business, your qualifications, work experience,
          affiliations, or achievements. Do not use an image of someone else, or any other image
          that is not your likeness, for your profile photo. Do not associate yourself on Panelist
          with a business or organisation that you are not actually associated with. Do not use or
          attempt to use another person's Panelist account or create a member profile for anyone
          other than yourself. And do not share your Panelist account with anyone else.
        </div>
        <div className="mt-3">
          ● We don’t allow untargeted, irrelevant, obviously unwanted, unauthorised, inappropriately
          commercial or promotional, or gratuitously repetitive messages or other similar content.
          Do not use Panelist to sensationalise or capitalise on tragic events for commercial
          purposes. Please make the effort to create original, professional, relevant, and
          interesting content. Respond authentically to others’ content and don’t agree with others
          ahead of time to like or re-share each other’s content.
        </div>
      </div>
    </div>
    <div id="beinclusive" className="mt-7 text-gray-1 text-lg5">
      Be inclusive
      <div className="text-md2 ml-10">
        <div className="mt-3">
          ● Embrace your fellow Panelists irrespective of race, gender, disability, medical or other
          needs
        </div>
        <div className="mt-3">
          ● Do everything possible to respect your fellow members, give them full access to your
          resources, and promote equal opportunity.
        </div>
        <div className="mt-3">● Do not discriminate against fellow community members.</div>
        <div className="mt-3">
          ● Engage your fellow community members in decision-making discussions.
        </div>
        <div className="mt-3">● Value the importance of diversity.</div>
        <div className="mt-3">
          ● Immediately report racist and other discriminating incidents to us.
        </div>
      </div>
    </div>
    <hr className="my-10" />
    <div id="respect-panelist-rights" className="mt-7 text-gray-1 text-lg5">
      Respect Panelist’s rights
      <div className="ml-10 text-md2">
        <div className="mt-5">
          ● Do not violate the rights of Panelist. For more information about what this means, see
          our User Agreement. You are not allowed to suggest you’re affiliated with or endorsed by
          Panelist when you’re not. Don’t violate our intellectual property rights, scrape our
          website, or interfere with the functionality of the service or disrupt the platform.
        </div>
        <div className="mt-3">
          ● You may access Panelist only in the manner that we expressly authorise. Do not access
          Panelist except through the interfaces intentionally provided by us, such as mobile
          applications and Panelist.com.
        </div>
        <div className="mt-3">
          ● Do not remove any copyright, trademark, or other proprietary rights notices contained in
          or on Panelist.
        </div>
        <div className="mt-3">
          ● You must respect our rules, agreements, and policies. You are not permitted to violate
          any additional terms concerning Panelist services that are provided when you sign up for
          or start using Panelist.
        </div>
      </div>
    </div>
  </>
);

export default CommunityContentTwo;
