/* eslint-disable max-len */
import {
  Avatar, Button, Rating, Stack, Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { ArrowDownSortSvgIcon } from 'src/shared-components/V2/IconSvg/ArrowDownSvgIcon/ArrowDownSortSvgIcon';
import { IAppState } from 'src/store';
import { ParseContent } from 'src/utils/parse-html';
import { useNavigate, useParams } from 'react-router';
import ConnectionsNew from '../connections-new';

interface Props {
  product: any,
}

export const ProductInfo = ({ product }:Props) => {
  const { companyidorslug } = useParams();
  const navigate = useNavigate();

  const { companyProfilePreview, companyEmployees } = useSelector(
    (state: IAppState) => state
  );
  const productOwnBy = companyProfilePreview?.value?.name || '';
  return (
    <Stack>
      <Stack sx={{ pb: '15px', mb: '20px', borderBottom: '1px solid #CACED0' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '20px', fontWeight: 500, lineHeight: '27px', fontFamily: 'Poppins-500', display: 'flex', alignItems: 'center', gap: '7px', cursor: 'pointer'
          }}
          onClick={() => navigate(`/wall/company/${companyidorslug}`)}
        >
          <div className="rotate-90"><ArrowDownSortSvgIcon /></div>
          {' '}
          {`${productOwnBy}’s product`}
        </Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: '20px' }}>
        <Stack sx={{ width: '100%' }}>
          <Stack sx={{
            flexDirection: 'row', gap: '10px', mb: '24px', mt: '20px'
          }}
          >
            <Avatar
              sx={{
                width: '263px', height: '263px', borderRadius: '20px', backgroundColor: '#ffffff', border: '2px solid #DDDDDD'
              }}
              src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${product?.icon}`}
            />
            <Stack>
              <Typography
                color="text.primary"
                variant="subtitle2"
                sx={{
                  fontSize: '30px', fontWeight: 600, lineHeight: '40px', fontFamily: 'Poppins-600', mb: '3px'
                }}
              >
                {product?.name}
              </Typography>
              <Typography
                color="text.primary"
                variant="subtitle2"
                sx={{
                  fontSize: '15px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Poppins-400',
                }}
              >
                <ParseContent
                  content={product?.promotionalText}
                  className="line-clamp-1 leading-[20px]"
                />
              </Typography>
              <Typography
                color="text.primary"
                variant="subtitle2"
                sx={{
                  fontSize: '15px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Poppins-400', mb: '10px'
                }}
              >
                {product?.tagline}
              </Typography>
              <Stack sx={{ mb: '15px' }}>
                <Typography
                  color="text.primary"
                  variant="subtitle2"
                  sx={{
                    fontSize: '15px', fontWeight: 600, lineHeight: '20px', fontFamily: 'Poppins-600', color: '#0131FF', cursor: 'pointer',
                  }}
                  onClick={() => navigate(`/wall/company/${companyidorslug}`)}
                >
                  {productOwnBy}
                </Typography>
              </Stack>
              <Stack sx={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', mb: '23px'
              }}
              >
                <Rating
                  readOnly
                  size="small"
                  precision={0.5}
                  name="reviews"
                  value={2}
                />
                <Typography
                  color="text.primary"
                  variant="subtitle2"
                  sx={{
                    fontSize: '10px', fontWeight: 400, lineHeight: '14px', fontFamily: 'Poppins-400'
                  }}
                >
                  5.0 • 3231 ratings
                </Typography>
              </Stack>
              <Stack sx={{ gap: '6px' }}>
                <Button sx={{
                  height: '41px', width: '190px', borderRadius: '5px', backgroundColor: '#0131FF', '&:hover': { backgroundColor: '#2f52ea' }
                }}
                >
                  <Typography
                    color="text.primary"
                    variant="subtitle2"
                    sx={{
                      fontSize: '15px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Poppins-500', textTransform: 'none', color: '#ffffff'
                    }}
                  >
                    Request a demo
                  </Typography>
                </Button>
                <Button
                  sx={{
                    height: '41px', width: '234px', borderRadius: '5px', backgroundColor: 'transparent', border: '1px solid #000', '&:hover': { backgroundColor: '#d6dce7' }
                  }}
                  onClick={() => navigate(`/wall/company/${companyidorslug}`)}
                >
                  <Typography
                    color="text.primary"
                    variant="subtitle2"
                    sx={{
                      fontSize: '15px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Poppins-500', textTransform: 'none', color: '#000000'
                    }}
                  >
                    View Product Website
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Typography sx={{
              color: '#000000', fontSize: '20px', lineHeight: '28px', fontFamily: 'Poppins-500', mb: '12px'
            }}
            >
              About:
            </Typography>
            <Typography sx={{
              color: '#000000', fontSize: '15px', lineHeight: '19px', fontFamily: 'Poppins-400', mt: '5px'
            }}
            >
              <ParseContent
                content={companyProfilePreview?.value?.description}
              />
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ minWidth: '338px', maxHeight: '483px' }}>
          {companyEmployees.value?.data!.length! > 0 && (
          <ConnectionsNew
            connections={companyEmployees.value?.data ?? []}
            title="Team"
            companyId={companyProfilePreview.value?.id}
            totalEmployees={companyEmployees.value?.totalRow ?? 0}
          />
                )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProductInfo;
