import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { GoogleMap, InfoWindow, LoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';

import markerIcon from '../../../assets/images/map-marker.svg';
import { IEventLocationProps } from '../../../models/event/event-location';
import { IAppState } from '../../../store';
import { getAddressFromGeocode } from '../../../store/address/actions';
import { ParseContent } from '../../../utils/parse-html';

export const EventLocation = (props: IEventLocationProps) => {
  const [showInfo, setShowInfo] = useState(true);

  const { eventLocationAddress } = useSelector((state: IAppState) => state);
  const dispatch = useDispatch();

  const mapStyles = {
    height: '276px',
    width: '100%',
    borderRadius: '10px'
  };

  const defaultCenter = {
    lat: +props.latitude + 0.000,
    lng: +props.longitude + 0.009,
  };

  const markerLatLng = {
    lat: +props.latitude,
    lng: +props.longitude
  };

  const infoMarkerLatLng = {
    lat: +props.latitude + 0.0016,
    lng: +props.longitude + 0.0103,
  };
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}` });

  useEffect(() => {
    if (props.latitude && props.longitude) {
      dispatch(getAddressFromGeocode(props.latitude, props.longitude));
    }
  }, [dispatch, props.latitude, props.longitude]);

  return (
    <div className="w-full bg-white create-post-card">
      <div className="">
        <div className="flex flex-col p-4">
          <div className="text-sm7 text-blue-4 font-semibold tracking-wide ml-1 mt-1 mb-2.5">Event Location</div>
          <div className='flex flex-col sm:flex-row'>
            <div className='rounded-15 m-1 ml-0 sm:w-[50%] w-full'>
              <div className="flex flex-col p-2 pl-1">
                <div className="text-[#203C6E] font-['Poppins'] text-[18px] leading-[25px] mb-[14px] font-semibold">{props.title}</div>
                <div className="text-[#203C6E] font-['Poppins'] text-[15px] leading-[20px] mb-[18px] font-semibold">{props.locationName}</div>
                <div className="w-full font-['Poppins']">
                  {
                    !!eventLocationAddress?.value?.results?.[0]?.formatted_address && (
                      <div className='text-[#707070] text-[15px] leading-[23px] mb-[18px] font-bold'>
                        Address: <p className='font-normal'>{props.address ? props.address : eventLocationAddress?.value?.results?.[0]?.formatted_address}</p>
                      </div>
                    )
                  }
                  {!!props.notes && <div className='text-[#707070] text-[15px] leading-[23px] mb-[14px] font-bold'>
                    Notes: <p className='font-normal'><ParseContent content={props.notes} /></p>
                  </div>}
                </div>
              </div>
            </div>
            <div className="rounded-15 sm:m-1 sm:w-[50%] w-full">
              {
                isLoaded &&
                <GoogleMap mapContainerClassName='event-location' mapContainerStyle={mapStyles} zoom={12} center={defaultCenter}>
                  <Marker
                    key={props.title}
                    position={markerLatLng}
                    icon={markerIcon}
                    onClick={() => setShowInfo(!showInfo)}
                  />
                </GoogleMap>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventLocation;
