import { Link, useNavigate } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import { useDispatch, useSelector } from 'react-redux';
import { monthName } from '../../../utils/datetime';
import { IAppState } from '../../../store';
import { Avatar } from '../../../shared-components/V2';
import { Logo } from '../../../shared-components/V2/Logo';
import { useEffect, useRef, useState } from 'react';
import { IUserProfile } from '../../../models/user-profile';
import { ICompanyProfile } from '../../../models/company/company-profile';
import { IEvent } from '../../../models/event/event';
import useCloseMenuWithClickOutside from '../../../utils/hooks/closeMenuWithClickOutside';
import { resetSearchResults } from '../../../store/navBarSearch/actions';
import classNames from 'classnames';
import { ArrowDownSvgIcon, SearchIcon } from '../../../shared-components/V2/IconSvg';
import { ReactComponent as ClockIcon } from '../../../assets/images/event-landing-pre-event/Icon-feather-clock.svg';

const searchFilters = [
  {name: 'Companies', path: '/search?search='},
  {name: 'People', path: '/search?search='},
  {name: 'Events', path: '/events'},
  {name: 'Spotlight', path: '/spotlight'}
];

interface ISearchDropDownProps {
  setIsSuggestion: Function;
  setRecentSearchItems: Function;
  isSuggestion: boolean;
  search: string;
  isSearchDropDown: boolean;
}

const SearchDropDown = (props: ISearchDropDownProps) => {
  const ref = useRef<any>();
  const refSuggestion = useRef(null);
  const { showDropdown, handleDropdown } = useCloseMenuWithClickOutside(ref);
  const { searchBar } = useSelector((state: IAppState) => state);
  const [searchedUsers, setSearchedUsers] = useState<IUserProfile[]>();
  const [searchedCompany, setSearchedCompany] = useState<ICompanyProfile[]>();
  const [searchedEvent, setSearchedEvent] = useState<IEvent[]>();
  const [isAllRecent, setIsAllRecent] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const recentSearchList: any = JSON.parse(localStorage.getItem('recentSearchList')!);
  const recentCompanyList: any = JSON.parse(localStorage.getItem('recentCompanyList')!);
  const recentPeopleList: any = JSON.parse(localStorage.getItem('recentPeopleList')!);

  

  const recentSearch = Array.from(new Set(recentSearchList));
  const recentCompanies = recentCompanyList?.filter( (item: any, index: any) => index === recentCompanyList?.findIndex( (itemTwo: any) => itemTwo.id === item.id && itemTwo.id === item.id));
  const recentPeoples = recentPeopleList?.filter( (item: any, index: any) => index === recentPeopleList?.findIndex( (itemTwo: any) => itemTwo.id === item.id && itemTwo.id === item.id));

  const companyRedirctHandler = (slug: string | undefined) => {
    dispatch(resetSearchResults());
    navigate(`/wall/company/${slug}`);
  };

  const eventRedirectHandler = (slug: string | undefined) => {
    dispatch(resetSearchResults());
    navigate(`/event/${slug}/discussion`);
  };

  const resourcesRedirectHandler = (id: string | undefined) => {
    dispatch(resetSearchResults());
    navigate(`events/library/${id}/view`);
  };

  const usersRedirectHandler = (slug: string | undefined) => {
    dispatch(resetSearchResults());
    navigate(`/wall/${slug}`);
  };

  // TODO: Check if direct search is needed
  // By Company, User, or Event
  // const redirectToAdvancedSearchHandler = (
  //   type: string | undefined,
  //   search: string | undefined
  // ) => {
  //   navigate(`/home/search?type=${type}&search=${search}`);
  // };

  useEffect(() => {
    if (searchBar?.searchInput !== '' && searchBar?.searchInput !== undefined)
      handleDropdown(false);
  }, [searchBar?.searchInput]);

  const handleClickOutside = () => {
    props.setIsSuggestion(false);
    setIsAllRecent(false);
  }

  useOnClickOutside(refSuggestion, handleClickOutside);

  useEffect(() => {
    const usr: IUserProfile[] = [];
    const cmp: ICompanyProfile[] = [];
    const evt: IEvent[] = [];
    const searchLimit: number = 5;

    for (let i = 0; i < searchLimit; i++) {
      // TODO: Optimise the code
      if (usr.length + cmp.length + evt.length < searchLimit && searchBar?.users[i])
        usr.push(searchBar?.users[i]);
      if (usr.length + cmp.length + evt.length < searchLimit && searchBar?.companies[i])
        cmp.push(searchBar?.companies[i]);
      if (usr.length + cmp.length + evt.length < searchLimit && searchBar?.events[i])
        evt.push(searchBar?.events[i]);
    }
    setSearchedUsers(usr);
    setSearchedCompany(cmp);
    setSearchedEvent(evt);
  }, [searchBar]);

  const onBackRecentSearch = () => {
    setIsAllRecent(false);
    props.setIsSuggestion(true);
  };

  const onClearRecentSearch = () => {
    localStorage.clear();
    props.setRecentSearchItems([]);
    setIsAllRecent(false);
  };

  const onClickSuggestion = (path: any) => {
    navigate(path);
  }

  const onClickRecentSuggestion = (path: any) => {
    navigate(`/search?search=${path}`);
  }

  return (
    <div>
      {searchBar?.isLoading && <p>Loading...</p>}
      {isAllRecent && (
        <div ref={refSuggestion} className="z-20 bg-white overflow-hidden overflow-y-scroll card-shadow !rounded-[10px] origin-top-left absolute mt-2 pt-1.5 md:w-[450px] w-[287px]">
          <div className="flex items-center justify-between border-b-1 border-gray-200">
            <div onClick={onBackRecentSearch} className="cursor-pointer truncate text-[14px] leading-[15px] text-black px-2.5 py-1 mb-1 font-medium flex items-center">
              <div
                className="cursor-pointer rotate-90 mr-2 w-[24px] h-[24px] hover:bg-slate-200 rounded-full flex items-center justify-center"
              >
                <ArrowDownSvgIcon />
              </div>
              {" "}
              Recent
            </div>
            <div
              className="flex justify-center mr-3 text-[14px] text-[#285CB2] leading-[15px] cursor-pointer font-semibold tracking-wide py-[4px] px-2 hover:underline hover:bg-gray-1 rounded-[7px]"
              onClick={onClearRecentSearch}
            >
              Clear
            </div>
          </div>
          <div
            className={classNames('w-full py-[10px] px-2.5 border-b-1 border-gray-200 max-h-[220px] overflow-y-scroll show-scroll-bar', {
              hidden: !recentCompanies,
            })}
          >
            {recentCompanies?.map((item: any, index: any) => (
              <div
                className="h-[37px] flex items-center hover:bg-[#EDF2F5] pl-[6px] mb-1 rounded-10 cursor-pointer last:mb-0 max-w-[429px]"
                key={index}
                onClick={() => companyRedirctHandler(item?.slug)}
              >
                <Logo
                  src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.logo}`}
                  className="w-[30px] h-[30px] object-contain rounded-[4px] bg-white"
                  defaultLogo="company"
                />
                <div className="flex items-center w-[430px] ml-[11px] tracking-wide truncate">
                  <div className="inline-block text-[15px] leading-5 font-semibold text-[#172746]">
                    {item?.name}
                  </div>
                  <div className="ml-1 text-[13px] font-light leading-[15px] text-[#18161C]">
                    • {item?.tagline}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className={classNames('w-full py-[10px] px-2.5 border-b-1 border-gray-200 max-h-[220px] overflow-y-scroll show-scroll-bar', {
              hidden: !recentPeoples,
            })}
          >
            {recentPeoples?.map((item: any, index: any) => (
              <div
                className="h-[37px] flex items-center hover:bg-[#EDF2F5] pl-[6px] mb-1 rounded-10 cursor-pointer"
                key={index}
                onClick={() => usersRedirectHandler(item?.slug)}
              >
                <Avatar
                  src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.avatar}`}
                  size="XSMALL"
                />

                <div className="flex items-center w-[430px] ml-[11px] tracking-wide truncate">
                  <div className="inline-block text-[15px] leading-5 font-semibold text-[#172746]">
                    {item?.firstName}{' '}{item?.lastName}
                  </div>
                  <div className="ml-1 text-[13px] font-light leading-[15px] text-[#18161C]">
                    • {item?.company?.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="w-full py-[10px] pb-[5px] mb-[5px] px-2.5 max-h-[210px] overflow-y-scroll">
            {recentSearch?.reverse().map((filter: any) => {
              return (
                <div onClick={() => onClickRecentSuggestion(filter)}>
                  <div className="h-[40px] flex items-center px-2.5 hover:bg-gray-1 rounded-10 cursor-pointer font-extralight text-sm1 text-blue-2 py-[10px]">
                    <ClockIcon />
                    <div className="search-query text-[15px] ml-2 font-medium">
                      {filter}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {props?.isSuggestion && !isAllRecent && (
        <div ref={refSuggestion} className="z-20 bg-white overflow-hidden overflow-y-scroll card-shadow !rounded-[10px] origin-top-left absolute mt-2 pt-1.5 md:max-w-[450px] md:w-[450px] w-[287px]">
          <div
            className={classNames('w-full py-[10px] pb-[5px] mb-[5px] px-2.5 border-b-1 border-gray-200', {
              '!border-b-0 !pb-0 !mb-0': (!recentCompanies || !recentPeoples ),
            })}
          >
            {(recentCompanies || recentPeoples || recentSearch?.length > 0) && (
              <div className="flex items-center justify-between">
                <div className="truncate text-[14px] leading-[15px] text-black px-2.5 py-1 mb-1 font-medium">
                  Recent
                </div>
                {((recentSearch?.length > 3) || (recentCompanies?.length > 3) || (recentPeoples?.length > 3)) ? (
                  <div
                    className="flex justify-center text-[14px] text-[#285CB2] leading-[15px] cursor-pointer font-semibold tracking-wide py-[4px] px-2 hover:underline hover:bg-gray-1 rounded-[7px]"
                    onClick={() => setIsAllRecent(true)}
                  >
                    See all
                  </div>
                ) : (
                  <div
                    className="flex justify-center text-[14px] text-[#285CB2] leading-[15px] cursor-pointer font-semibold tracking-wide py-[4px] px-2 hover:underline hover:bg-gray-1 rounded-[7px]"
                    onClick={() => onClearRecentSearch()}
                  >
                    Clear
                  </div>
                )}
              </div>
            )}
            <div
              className={classNames('grid gap-[10px] gap-y-6 grid-cols-5 py-2 justify-center', {
                hidden: !recentCompanies,
              })}
            >
              {recentCompanies?.slice(0,5).map((item: any, index: any) => (
                <div
                  key={index}
                  className="cursor-pointer px-2.5 py-2 hover:bg-gray-1 w-[75px] h-[105px] rounded-10"
                  onClick={() => companyRedirctHandler(item?.slug)}
                >
                  <div className="flex flex-col items-center w-[55px] h-[88px]">
                    <Logo
                      src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.logo}`}
                      className="w-[55px] h-[55px] object-contain rounded-[4px] mb-[5px] bg-white"
                      defaultLogo="company"
                    />
                    <div className="text-[12px] leading-[14px] text-[#0B1221] font-semibold text-center line-clamp-2">
                      {item?.name ?? item?.industry?.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>  
            <div
              className={classNames('grid gap-[10px] gap-y-6 grid-cols-5 py-2 justify-center', {
                hidden: !recentPeoples,
              })}
            >
              {recentPeoples?.slice(0,5).map((item: any, index: any) => (
                <div
                  key={index} 
                  className="cursor-pointer px-2.5 py-2 hover:bg-gray-1 w-[75px] h-[105px] rounded-10"
                  onClick={() => usersRedirectHandler(item?.slug)}
                >
                  <div className="flex flex-col items-center w-[55px] h-[88px]">
                    <Logo
                      src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.avatar}`}
                      className="w-[55px] h-[55px] object-cover rounded-[20px] mb-[5px] bg-white"
                      defaultLogo="user"
                    />
                    <div className="text-[12px] leading-[14px] text-[#0B1221] font-semibold text-center line-clamp-2">
                      {item?.firstName}{" "}{item?.lastName}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {recentSearch?.reverse().slice(0,3).map((filter: any) => {
              return (
                <div onClick={() => onClickRecentSuggestion(filter)}>
                  <div className="h-[40px] flex items-center px-2.5 hover:bg-gray-1 rounded-10 cursor-pointer font-extralight text-sm1 text-blue-2 py-[10px]">
                    <ClockIcon />
                    <div className="search-query text-[15px] ml-2 font-medium">
                      {filter}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full py-[10px] px-2.5">
            <div className="truncate text-[14px] leading-[15px] text-black px-2.5 mb-2 font-medium">
              Try Searching for
            </div>
            {searchFilters.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => onClickSuggestion(item.path)}
                >
                  <div className="h-[40px] flex items-center px-2.5 hover:bg-gray-1 rounded-10 cursor-pointer font-extralight text-sm1 text-blue-2 py-[10px]">
                    <SearchIcon />
                    <div className="search-query text-[15px] ml-2 font-medium">
                      Search for{" "}
                      {item?.name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>)}
      {showDropdown && searchBar?.searchInput !== '' && searchBar?.searchInput !== undefined && !isAllRecent && props.isSearchDropDown && (searchedUsers || searchedCompany || searchedEvent) &&  (
        <div ref={ref} className="z-20 bg-white overflow-hidden overflow-y-scroll card-shadow !rounded-[10px] origin-top-left absolute mt-2 py-1.5">
          <div className="md:w-[500px] w-[287px]">
            <div>
              {searchedUsers &&
                searchedUsers.map((user) => {
                  const redirectParam = user?.slug;
                  return (
                    <div
                      className="h-[37px] flex items-center hover:bg-[#EDF2F5] m-[6px] pl-[12px] rounded-10 cursor-pointer"
                      key={user.id}
                      onClick={() => usersRedirectHandler(redirectParam)}>
                      <Avatar
                        src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${user?.avatar!}`}
                        size="XSMALL"
                      />

                      <div className="flex items-center w-[430px] ml-[11px] tracking-wide truncate">
                        <div className="inline-block text-[15px] leading-5 font-semibold text-[#172746]">
                          {user?.firstName} {user?.lastName}
                        </div>
                        <div className="ml-1 text-[13px] font-light leading-[15px] text-[#18161C]">
                          • {user?.company?.name}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {searchedCompany &&
                searchedCompany.map((company) => {
                  const redirectParam = company?.slug;
                  return (
                    <div
                      className="rowListing flex  hover:bg-[#EDF2F5] rounded-10 cursor-pointer m-[6px] px-[12px] h-[37px]"
                      key={company.id}
                      onClick={() => companyRedirctHandler(redirectParam)}>
                      <div className="flex flex-row items-center w-full h-full" title={company?.name}>
                        <div className="flex justify-center items-center w-[29px] max-w-[29px] min-w-[29px] h-[29px] max-h-[29px] min-h-[20px] bg-white rounded-[3px] px-auto mr-[11px]">
                          <Logo
                            src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${company?.logo!}`}
                            className={classNames('flex justify-center w-[23px] h-[23px] bg-white object-contain rounded-[3px]', {
                              'w-[29px] max-w-[29px] min-w-[29px] h-[29px] max-h-[29px] min-h-[29px]': company?.logo,
                              'bg-[#edf2f5] w-[29px] h-[29px]': !company?.logo,
                            })}
                            alt={company?.name!}
                            defaultLogo='company'
                          />
                        </div>
                        <div className="truncate flex items-center w-[430px]">
                          <div className="inline-block text-[15px] leading-[19px] font-semibold text-[#172746]">
                            {company?.name}
                          </div>
                          <div className="mx-2 truncate text-[13px] font-light leading-[15px] text-[#18161C] flex-none">• Company •</div>
                          <div className="truncate text-[13px] font-light leading-[15px] text-[#18161C]">
                            {company?.industry?.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {searchedEvent &&
                searchedEvent.map((event) => {
                  const month = event?.startTime?.slice(5, 7);
                  const monthByName = monthName(month);
                  const formattedStartTime = `${event?.startTime?.slice(
                    8,
                    10
                  )} ${monthByName} ${event?.startTime?.slice(0, 4)}`;
                  const redirectParam = event?.slug;
                  return (
                    <div
                      className="h-[37px] flex items-center hover:bg-[#EDF2F5] m-[6px] pl-[12px] rounded-10 cursor-pointer"
                      key={event.id}
                      onClick={() => eventRedirectHandler(redirectParam)}>
                      <div className="flex flex-row" title={event?.name}>
                        <div className="flex justify-center items-center w-[29px] max-w-[29px] min-w-[29px] h-[29px] max-h-[29px] min-h-[20px] bg-white rounded-[3px] px-auto mr-[11px]">
                          <Logo
                            src={`${event?.logo}`}
                            className={classNames('flex justify-center bg-[#edf2f5]', {
                              'w-[20px] h-[20px]': event?.logo,
                              'bg-[#edf2f5] w-[29px] max-w-[29px] min-w-[29px] h-[29px] max-h-[29px] min-h-[20px] rounded-[5px]': !event?.logo,
                            })}
                            alt={event?.name}
                            defaultLogo='company'
                          />
                        </div>
                        <div className="flex items-center truncate">
                          <div className="inline-block text-[15px] leading-[19px] font-semibold text-[#172746]">{event?.name} </div>
                          <div className="mx-2 truncate text-[13px] font-light leading-[15px] text-[#18161C] flex-none">
                            • Event •
                          </div>
                          <div className="truncate text-[13px] font-light leading-[15px] text-[#18161C]">
                            {' '}
                            {formattedStartTime}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>  
            {/* TODO: Disabled showing resources as part of search */}
            {/* {searchBar?.resources &&
            searchBar?.resources.map((resource) => {
              const redirectParam = resource?.id;
              return (
                <div
                  className="h-14 w-full flex"
                  key={resource.id}
                  onClick={() => resourcesRedirectHandler(redirectParam)}>
                  <span className="font-bold">{resource?.title}</span>
                  <div className="font-extralight">• Resource •</div>
                  <div className="font-extralight">
                    {' '}
                    {resource?.readTime} read time • Type: {resource?.type}
                  </div>
                </div>
              );
            })} */}

            {/* {searchFilters.map((filter) => {
              const searchInput = searchBar?.searchInput;
              return (
                <div onClick={() => redirectToAdvancedSearchHandler(filter, searchInput)}>
                  <div className="h-[46px] flex px-2.5 hover:bg-gray-1 rounded-10 cursor-pointer font-extralight text-sm1 text-blue-2 mx-2 py-3">
                    <img src={searchIcon} className="w-[18px] h-[18px]" alt="search icon" />
                    <div className="search-query text-sm1 ml-2">
                      Search for <span className="capitalize">{searchBar?.searchInput} </span>in{' '}
                      {filter}
                    </div>
                  </div>
                </div>
              );
            })} */}

            <Link to={`/search?search=${searchBar?.searchInput}`}>
              <div className="w-full flex items-center justify-center py-[10.6px]  cursor-pointer border-t-1 border-[#CDDCF2] mt-2">
                <div className="search-all text-[15px] leading-[23px] text-[#285CB2] font-semibold tracking-wide cursor-pointer hover:underline">
                  See all results
                </div>
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchDropDown;
