export const emailRegex: RegExp =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // /.+@.+\..+/;

export const forbiddenEmailDomains: string[] = [
  "@gmail.",
  "@yahoo.",
  "@icloud.",
  "@hotmail.",
  "@outlook.",
  "@aol.",
  "@msn.",
  "@live.",
  "@mail.",
  "@zoho.",
  "@bigpond.com",
  "@yopmail.com",
  "@protonmail.com"
];

export const isBusinessEmail = (email: string) => {
  const atIndex = email?.indexOf("@");
  if (atIndex === -1) {
    return false;
  }
  const domain = email?.slice(atIndex);
  return !forbiddenEmailDomains?.some((forbiddenDomain) =>
    domain.includes(forbiddenDomain)
  );
};
