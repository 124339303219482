/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box, Typography, Stack,
  IconButton
} from '@mui/material';
import { UploadBox } from 'src/mui/components/upload';
import { FilUploadService } from 'src/services/FileUpload';
import { useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import ExportIcon from './ExportIcon';
import ImagePreviewConatiner from './ImagePreviewConatiner';

const ProductIcon = () => {
  const { setValue, watch } = useFormContext();
  const defaultIcon = watch('icon');
  const [icon, setIcon] = useState<string[]>([]);
  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles) {
        if (icon.length === 0 && acceptedFiles.length > 0) {
          const filesArray = Array.from(acceptedFiles);
          const data = new FormData();
          data.append('file', filesArray[0]);
          const fileUploadService = new FilUploadService();
          const uploadedFile = await fileUploadService.uploadImage(data);
          if (uploadedFile) {
            setIcon([uploadedFile?.data?.key]);
            setValue('icon', uploadedFile?.data?.key);
          }
        }
      }
    },
    [icon.length, setValue]
  );

  useEffect(() => {
    if (defaultIcon) {
      setIcon([defaultIcon]);
    }
  }, [defaultIcon]);

  const handleRemoveImage = () => {
    setIcon([]);
  };

  return (
    <Stack>
      <Stack sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', gap: '10px', py: '20px'
      }}
      >
        <Box sx={{
          width: '45px', height: '45px', backgroundColor: '#E9EFF2', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}
        >
          <UploadBox
            placeholder={<ExportIcon />}
            sx={{ backgroundColor: 'transparent', border: 'none' }}
            onDrop={handleDrop}
            disabled={icon.length > 0}
            accept={{ 'image/*': [] }}
          />
        </Box>
        <Stack>
          <Typography
            sx={{
              fontFamily: 'Poppins-600', fontWeight: '600', fontSize: '13px', lineHeight: '17px'
            }}
            gutterBottom
          >
            Product Icon
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins-200', fontWeight: '200', fontSize: '12px', lineHeight: '16px'
            }}
            gutterBottom
          >
            Upload an Icon
          </Typography>
        </Stack>
        <Stack sx={{
          width: '45px', height: '45px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', marginLeft: '5px'
        }}
        >
          {icon?.map((image: string, index: number) => (
            <Box key={index} sx={{ position: 'relative' }}>
              <img
                src={(`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${image}`)}
                alt={`preview-${index}`}
                className="w-[44px] h-[44px] rounded-[5px] object-cover"
              />
              <IconButton
                sx={{
                  position: 'absolute', top: -8, right: -8, backgroundColor: '#fff', padding: '2px'
                }}
                size="small"
                onClick={handleRemoveImage}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Stack>
      </Stack>

      <ImagePreviewConatiner />

    </Stack>
  );
};

export default ProductIcon;
