// types
import { IAttendee } from 'src/models/events-live/attendee';
import { IChatParticipant, IChatMessage } from 'src/mui/types/chat';

// ----------------------------------------------------------------------

type Props = {
  messageData: any;
  currentUserId: string;
  participants: IAttendee[];
};

export default function useGetMessage({ messageData, participants, currentUserId }: Props) {
  const paraticipantsList = participants.filter((item) => item.id !== currentUserId)
  const sender: any = paraticipantsList.find((participant) => participant.id === messageData.senderId);
  const senderDetails =
    messageData.senderId === currentUserId
      ? {
        type: 'me',
        myUser: messageData
      }
      : {
        avatarUrl: `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${sender?.avatar!}`,
        firstName: sender?.fullName,
      };

  const me = senderDetails.type === 'me';

  const hasImage = messageData?.type === 'image';

  const myActiveUser = senderDetails.myUser;

  return {
    hasImage,
    me,
    senderDetails,
    paraticipantsList,
    myActiveUser
  };
}
