import getstarted from '../../../assets/images/overview/get-started.jpg';
import { ButtonWithTextAndImage } from '../../../shared-components/V2';
import polyicon from '../../../assets/images/overview/button-poly.svg';

export const GetStarted = () => (
  <div className="block md:flex w-full bg-gray-3">
    <div className="md:w-1/2">
      <div
        className="w-full"
        style={{
          background: `url(${getstarted})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '456px'
        }}
      />
    </div>
    <div className="md:w-1/2 lg:mt-32 lg:pl-28">
      <div className="ml-2 -mt-3">
        <p className="lg:text-xl4 font-medium text-xl text-dark-blue mt-10 md:mt-6w-2/3">
          Get started with the Panelist
          {' '}
        </p>
        <div className="mt-8 pt-1 font-extralight">
          <ButtonWithTextAndImage
            buttonClasses="bg-dark-blue hover:bg-gray-7 active:bg-gray-7 border-1 border-blue-3 w-242 h-43 rounded-full"
            imageUrl={polyicon}
            text="Create an event"
            textClasses="font-extralight text-white text-sm3 ml-1 pl-1"
          />
        </div>
      </div>
    </div>
  </div>
);

export default GetStarted;
