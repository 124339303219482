import React, { FC, HTMLAttributes, useState } from 'react';

import classNames from 'classnames';
import { IconSvg } from './../IconSvg';

export interface IDropDownMenuItems {
  id: string;
  name: string;
  url?: string;
  onClick?: any;
  type?: string;
  isSelected?: boolean;
}

export interface IDropDropDownEventButtonProps extends HTMLAttributes<HTMLHyperlinkElementUtils> {
  menuItems: IDropDownMenuItems[];
  text: string;
  classes?: string;
  iconRight?: any;
  iconLeft?: string;
  onClickItem?: Function;
  titleclasses?: string;
}

export const DropDownEventButton: FC<IDropDropDownEventButtonProps> = ({
  text,
  classes,
  titleclasses,
  iconRight,
  iconLeft,
  menuItems,
}) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  return (
    <div className='relative inline-block'>
      <button
        onClick={() => iconRight && setDisplayMenu(!displayMenu)}
        className={classNames("flex flex-row justify-between items-center text-[12px] font-semibold h-8 pl-[1.25rem] pr-[1.25rem] border-1 border-[#7283A4] rounded-[5px] text-[#7283A4] hover:bg-[#DFE5EB]", {
          [`${classes}`]: classes,
        })}
        aria-label="dropdown-target"
      >
        {iconLeft && <span className={classNames('mr-2.5',{
          [`${titleclasses}`]: titleclasses
        })}><IconSvg imageUrl={iconLeft} /></span>}
        {text}
        {iconRight && iconRight}
      </button>
      {displayMenu && (
        <div className="bg-white absolute w-full post-company-logo z-10 rounded-[7px]">
          <ul className="p-[5px]">
            {menuItems?.map((item, index) => (
              <div onClick={() => item.onClick()} className="cursor-pointer" key={`dropdown-item-${index}`}>
                <li key={`item-${index}`} className="text-sm leading-5 text-[#172746] rounded-[5px] hover:bg-[#EDF2F5] px-1.5 py-1 text-left tracking-wide">
                  {item.name}
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
