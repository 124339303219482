import TableContentTwo from './table-content-two';
import TableContentOne from './table-content-one';
import TableContentThree from './table-content-three';
import TableContentFour from './table-content-four';
import TableContentFive from './table-content-five';
import TableContentSix from './table-content-six';
import TableContentSevenEight from './table-content-seven-eight';
import TableContentNinetoEleven from './table-content-ninetoeleven';
import TableContentTwelvetoSixteen from './table-content-twelvetosixteen';

const AcceptingTerms = () => (
  <div className="bg-gray-0 w-full">
    <div className="block mb-8 w-9/12 mx-auto text-md2 font-normal text-gray-1">
      <div className="font-normal text-gray-1 text-md2">
        <TableContentOne />
        <TableContentTwo />
        <TableContentThree />
        <TableContentFour />
        <TableContentFive />
        <TableContentSix />
        <TableContentSevenEight />
        <TableContentNinetoEleven />
        <TableContentTwelvetoSixteen />
      </div>
    </div>
  </div>
);

export default AcceptingTerms;
