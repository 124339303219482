import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IReactSelectOption } from "../../models/react-select-option";
import { IAppState } from "../../store";
import { getJobTitles } from "../../store/job-titles/actions";
import { UseFormRegister } from "react-hook-form";
import { useOnClickOutside } from 'usehooks-ts';
import classNames from "classnames";
import { LOGIN_SIGNUP_RECOVERY } from "../../constants";
import React from "react";

interface JobTitleSelectorNewProps {
  handleJobTitleSelected: Function;
  register: UseFormRegister<any>;
  selectedJobTitleStateToStore: any;
  placeholder?: string;
  isBorderLess?: boolean;
  className?: string;
}

export const JobTitleSelectorNew = ({
  handleJobTitleSelected,
  selectedJobTitleStateToStore,
  placeholder,
  className,
  register
}: JobTitleSelectorNewProps) => {
  const ref = React.useRef(null);
  const dispatch = useDispatch();
  const jobTitles = useSelector((state: IAppState) => state.jobTitles);

  const [jobTitlesListState, setjobTitlesListState] =
    useState<IReactSelectOption[]>();
  const [getJobSearchTitle, setGetJobSearchTitle] =
    useState<IReactSelectOption[]>();
  const [query, setQuery] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    if (selectedJobTitleStateToStore) {
      setQuery(selectedJobTitleStateToStore);
    }
  }, [selectedJobTitleStateToStore]);

  useEffect(() => {
    if(jobTitlesListState && jobTitlesListState.length > 0) {
      setShowMenu(true);
      setGetJobSearchTitle(jobTitlesListState);
    } else {
      setShowMenu(false);
      setGetJobSearchTitle([]);
    }
  }, [jobTitlesListState]);

  useEffect(() => {
    const mapJobTitlesToSelectOption = () => {
      const jobTitleList: IReactSelectOption[] = [];

      jobTitles?.value?.data &&
        jobTitles?.value?.data.map((item: any, index: number) =>
          jobTitleList.push({
            key: `jobTitle-${index}`,
            value: item.id,
            label: item.name,
          })
        );
      setjobTitlesListState(jobTitleList);
    };
    mapJobTitlesToSelectOption();
  }, [jobTitles]);

  const handleClickOutside = () => {
    setShowMenu(false);
  }

  useOnClickOutside(ref, handleClickOutside);

  const onInputChange = (value: string) => {
    const selectionValue = {
      label: value
    }
    handleJobTitleSelected(selectionValue);
    setQuery(value);
    if (value?.length > 0) {
      dispatch(getJobTitles(value));
    }
  };

  const onFocusInput = (value: any) => {
    setQuery(value);
  };

  const onSelectJobTitle = (value: any) => {
    if (value) {
      handleJobTitleSelected(value);
      setShowMenu(false);
    }
  };

  return (
    <>
      <div ref={ref} className="relative">
        <input
          id="jobTitleSelector"
          placeholder={placeholder?.trim()?.length ? placeholder : "Select or enter your job title"}
          autoCorrect="off"
          autoComplete="off"
          spellCheck="false"
          type="text"
          className={classNames("w-336 h-[30px] text-sm1 font-extralight text-gray-12 px-2.5 rounded-10 mobile-input-width", {
            [`${className}`]: className,
          })}
          value={query}
          {...register('jobTitle', {
            required: { value: true, message: LOGIN_SIGNUP_RECOVERY.JOBTITLE_ISREQUIRED }
          })}
          onChange={(e: any) => onInputChange(e.currentTarget.value)}
          onFocus={(e: any) => onFocusInput(e.target.value)}
          onMouseDown={(e: any) => {
            e.stopPropagation();
            e.target.focus();
          }}
        />
        {showMenu && getJobSearchTitle && getJobSearchTitle?.length! > 0 && (
        <div className="bg-white rounded-10 py-2 px-1 shadow-md absolute right-0 top-[32px] w-full min-w-[300px] max-h-[300px] overflow-scroll no-scrollbar z-20 mobile-min-width">
          <ul>
            {showMenu && getJobSearchTitle && getJobSearchTitle?.length! > 0 &&
              getJobSearchTitle?.map((item, index) => {
                return (
                  <li
                    className="h-[40px] mx-[6px] text-[15px] font-normal text-[#18161C] rounded-10 hover:bg-[#EDF2F5] cursor-pointer flex items-center justify-start pl-[6px]"
                    onClick={() => onSelectJobTitle(item)}
                    key={index}
                  >
                    {item.label}
                  </li>
                )
              })}
          </ul>
        </div>
        )}
      </div>
    </>
  );
};
