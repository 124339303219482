import * as Yup from "yup";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ILoginOrSignUp } from "./form.type";
import {
  Box,
  Stack,
  Button,
  Typography,
  Alert,
  CircularProgress,
  InputAdornment,
  IconButton,
  useMediaQuery
} from "@mui/material";
import FormProvider from "src/mui/components/hook-form/form-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { RHFTextField } from "src/mui/components/hook-form";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import useStyling from "src/mui/components/hook-form/useStyling";

export interface ForgotPasswordSchema {
  email?: string;
  code?: string;
  newPassword?: string;
  confirmPassword?: string;

}

interface IForgotPasswordProps {
  onSubmit: (data: ForgotPasswordSchema) => void;
  onResendCode: () => void;
  resetPassword?: number;
  userEmail: string;
  errorMessage?: string;
  loader: boolean;
  setStepHeader: any
}

function ForgotPasswordForm({
  onSubmit,
  loader,
  resetPassword,
  userEmail,
  errorMessage,
  onResendCode,
  setStepHeader
}: IForgotPasswordProps, ref: any) {

  const { secondaryFontColor, styles, headerColor } = useStyling(null, null)
  const tabScreen = useMediaQuery('(min-width:1150px)');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  let validationSchema: any = {};
  if (resetPassword) {
    validationSchema.code = Yup.string().required(`Code is required`);
    validationSchema.newPassword = Yup.string().required(
      `New Password is required`
    );
    validationSchema.confirmPassword = Yup.string()
      .required(`Confirm Password is required`)
      .oneOf([Yup.ref("newPassword"), ""], "Passwords must match");
  } else {
    validationSchema.email = Yup.string()
      .email("Email must be valid")
      .required(`Email is required`);
  }

  const ForgotSchema = Yup.object().shape(validationSchema);
  const defaultValues = useMemo(
    () => ({
      email: userEmail || "",
      code: "",
      newPassword: "",
      confirmPassword: "",
    }),
    [userEmail]
  );

  const methods = useForm<ForgotPasswordSchema>({
    resolver: yupResolver(ForgotSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    reset,
    watch,
  } = methods;
  const values = watch();

  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  //functions
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (!resetPassword) {
      setShowConfirmPassword(false);
      setShowPassword(false)
      reset()
    }
  }, [resetPassword])

  const handleForgotPassword = () => {
    handleSubmit(submitForm)()
  }

  const submitForm = (data: any) => {
    if (!isValid) return
    onSubmit(data)
  }

  useImperativeHandle(ref, () => ({
    handleForgotPassword,
  }));

  useEffect(() => {
    if (resetPassword) {
      setStepHeader("Reset your password")
    }
    else {
      setStepHeader("Let's find your account to reset your password")
    }
  }, [resetPassword])

  return (
    <>
      {loader ? (
        <Stack
          sx={{
            direction: "row",
            minHeight: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Stack
          sx={{
            width: tabScreen ? "60%" : "100%"
          }}
        >

          <FormProvider methods={methods}>
            {resetPassword ? <Stack>
              <Typography sx={{ color: secondaryFontColor, fontSize: "24px", fontFamily: "Poppins-400", lineHeight: "35px", }}>
                Reset Password
              </Typography>

              <Typography sx={{ color: secondaryFontColor, fontSize: "16px", fontFamily: "Poppins-400", }}>
                We have sent a verification code to your email address
              </Typography>

              <Typography sx={{ color: secondaryFontColor, fontSize: "12px", fontFamily: "Poppins-400", lineHeight: "17px", mt: "8px" }}>
                Check your spam if you have not received it in the
                next few minutes
              </Typography>

            </Stack> : null}

            <Stack
              sx={{
                direction: "row",
                // height: "100%",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
              spacing={2}
              rowGap={4}
            >
              {!resetPassword ? (
                <Box sx={{ width: "100%" }}>
                  <RHFTextField
                    // sx={{ width: "100%", ...styles }}
                    sx={{
                      width: "100%",
                      ...styles, borderColor: "rgba(255, 255, 255, 0.15) !important", color: "rgba(220, 220, 220, 1)!important", "& .Mui-disabled": {

                        WebkitTextFillColor: "rgba(220, 220, 220, 1)!important",
                        "fieldSet": {
                          borderColor: "rgba(255, 255, 255, 0.15) !important"
                        }
                      }
                    }}
                    name="email"
                    label="Email Address"
                    autoFocus
                    autoComplete="off"
                  />
                </Box>
              ) : null}

              {resetPassword ? (
                <Box sx={{ width: "100%" }}>



                  <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mt: "12px" }}>

                    <Typography sx={{ color: secondaryFontColor, fontSize: "13px", fontFamily: "Poppins-400" }}>
                      Didn't receive?
                    </Typography>
                    <Button
                      sx={{ color: headerColor || secondaryFontColor || "#3498db", mb: "2px", fontFamily: "Poppins-600" }}
                      onClick={onResendCode}
                    >
                      Resend
                    </Button>
                  </Stack>

                  <RHFTextField
                    sx={{ width: "100%", ...styles }}
                    name="code"
                    label="Code*"
                    autoFocus
                    autoComplete="off"
                  />
                </Box>
              ) : null}

              {resetPassword ? (
                <Box sx={{
                  width: "100%"
                }}>
                  <>

                    <Stack direction="column" spacing={2}>
                      <RHFTextField
                        name="newPassword"
                        type={showPassword ? "text" : "password"}
                        sx={{ width: "100%", ...styles }}
                        label="New Password*"
                        autoFocus
                        autoComplete="off"
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOutlined
                                    sx={{
                                      color: secondaryFontColor || "#3D3E5C",
                                    }}
                                  />
                                ) : (
                                  <VisibilityOffOutlined
                                    sx={{
                                      color: secondaryFontColor || "#3D3E5C",
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <RHFTextField
                        name="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        sx={{ width: "100%", ...styles }}
                        label="Confirm new Password*"
                        autoFocus
                        autoComplete="off"
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowConfirmPassword}
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOutlined
                                    sx={{
                                      color: secondaryFontColor || "#3D3E5C",
                                    }}
                                  />
                                ) : (
                                  <VisibilityOffOutlined
                                    sx={{
                                      color: secondaryFontColor || "#3D3E5C",
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>

                  </>
                </Box>
              ) : null}

              {errorMessage && (
                <Stack
                  spacing={2}
                  direction="column"
                  sx={{ width: "100%" }}
                >
                  <Alert severity="error" sx={{ width: "100%" }}>
                    <Typography color="error">{errorMessage}</Typography>
                  </Alert>
                </Stack>
              )}
            </Stack>
          </FormProvider>
        </Stack>
      )}
    </>
  );
};

export default forwardRef(ForgotPasswordForm);
