interface SponsorAdsIconProps {
  fillColor?: string;
}

export const SponsorAdsIcon = ({ fillColor }: SponsorAdsIconProps) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="32.598" height="25.798" viewBox="0 0 32.598 25.798">
    <g id="ad-svgrepo-com" transform="translate(1 1)">
      <path id="Path_27137" data-name="Path 27137" d="M6.4,5H30.2a3.4,3.4,0,0,1,3.4,3.4v17a3.4,3.4,0,0,1-3.4,3.4H6.4A3.4,3.4,0,0,1,3,25.4V8.4A3.4,3.4,0,0,1,6.4,5Z" transform="translate(-3 -5)" fill="none" stroke={fillColor || '#fff'} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_27138" data-name="Path 27138" d="M7,19.2V12.4A3.4,3.4,0,0,1,10.4,9h0a3.4,3.4,0,0,1,3.4,3.4v6.8" transform="translate(-0.2 -2.2)" fill="none" stroke={fillColor || '#fff'} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_27139" data-name="Path 27139" d="M17,9V19.2" transform="translate(6.798 -2.2)" fill="none" stroke={fillColor || '#fff'} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_27140" data-name="Path 27140" d="M19.1,12H16.55a2.55,2.55,0,0,0,0,5.1H19.1" transform="translate(4.699 -0.101)" fill="none" stroke={fillColor || '#fff'} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_27141" data-name="Path 27141" d="M7,13h6.8" transform="translate(-0.2 0.599)" fill="none" stroke={fillColor || '#fff'} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>
  
  };