import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import commentIcon from '../../assets/images/comment-icon.svg';
import shareIcon from '../../assets/images/share-icon.svg';
import { CardMainPost } from '../../components/posts';
import { PostAttachment } from '../../components/posts/components/PostAttachment';
import { ICreateComment } from '../../models/post/comment';
import { IPost } from '../../models/post/post';
import { unlikeComment } from '../../store/events/event-post-comment-like/actions';
import { likePost, unlikePost } from '../../store/events/upcoming-event-post-like/actions';
import { createPostComment, likeComment } from '../../store/posts/comments/actions';
import { getUserPostComments, getUserPosts } from '../../store/user/posts/actions';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IAppState } from 'src/store';
import { getOwnConnections } from 'src/store/user/own-connections/actions';

interface ISpotlightDetailsProps {
  showSpotlightEvent: IPost | undefined;
  getSpotlight: Function;
  fetchSpotlight: Function;
  isSpotlightDetailModal?: boolean;
  isPostModal?: boolean;
}
export const SpotlightDetails = ({ showSpotlightEvent, getSpotlight, fetchSpotlight, isSpotlightDetailModal, isPostModal }: ISpotlightDetailsProps) => {
  const dispatch = useDispatch();
  const { slug: userId } = useParams();
  
  const {auth} = useSelector((state: IAppState) => state);
  const { currentUser: user } = auth;
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  useEffect(() => {
    setIsOwnProfile(user?.id === userId || user?.slug === userId);
  }, [userId, user]);

  useEffect(() => {
    if (isOwnProfile) {
      dispatch(getOwnConnections());
    } else dispatch(getOwnConnections(userId));
  }, [dispatch, isOwnProfile, userId]);

  const onClickSpotlightModal = (event: any) => event.preventDefault();
  return (
    <div className="md:flex md:flex-1 py-0 my-0 h-[calc(100vh-34px)] md:h-680 md:max-h-[611px]">
      <PostAttachment
        format={showSpotlightEvent?.format!}
        source={showSpotlightEvent?.source!}
        content={showSpotlightEvent?.content}
        post={showSpotlightEvent}
        isSpotlightDetailModal={isSpotlightDetailModal}
        className={classNames("pt-0 md:w-full object-contain md:min-h-full min-h-[230px] md:max-h-full max-h-[230px]", {
          'max-w-[655px]': showSpotlightEvent?.format! === 'document',
        })}
      />
      <div className={classNames("overflow-y-auto overflow-x-hidden", {
        'md:w-5/12': ['image', 'video', 'document'].includes(showSpotlightEvent?.format!),
        'w-full': !['image', 'video', 'document'].includes(showSpotlightEvent?.format!)
      })}
        onClick={(e) => onClickSpotlightModal && onClickSpotlightModal(e)}
      >
        {
          showSpotlightEvent && (
            <CardMainPost
              post={showSpotlightEvent}
              commentIcon={commentIcon}
              shareIcon={shareIcon}
              getPostComments={(id: string, page: number = 1) => dispatch(getUserPostComments(id, page))}
              addPostComment={async (
                postId: string,
                data: any,
                attachment: any
              ) => {
                const comment: ICreateComment = {
                  content: data,
                  parentId: '',
                  userMentioned: '',
                };
                await dispatch(
                  createPostComment(postId, comment, attachment)
                );
                await dispatch(getUserPostComments(postId));
                await dispatch(getUserPosts());
              }}
              addReply={async (
                postId: string,
                replyText: string,
                parentId: string,
                attachment: any
              ) => {
                const comment: ICreateComment = {
                  content: replyText,
                  parentId,
                  userMentioned: '',
                };
                await dispatch(
                  createPostComment(postId, comment, attachment)
                );
                await dispatch(getUserPostComments(postId));
              }}
              onCommentLike={async (likeType: string, commentId: string) => {
                if (likeType) {
                  await dispatch(likeComment(commentId, likeType));
                } else {
                  await dispatch(unlikeComment(commentId));
                }
                await getSpotlight();
                fetchSpotlight(showSpotlightEvent.id);
              }}
              onPostLike={async (likeType: string, postId: string) => {
                // If you got like type then set the like on post else its remove like 'undefined'
                if (likeType) {
                  await dispatch(likePost(postId, likeType));
                } else {
                  await dispatch(unlikePost(postId));
                }
                await getSpotlight();
                fetchSpotlight(showSpotlightEvent.id);
              }}
              className={classNames("md:w-full", {
                'h-full': isSpotlightDetailModal,
              })}
              isSpotlight
              isPostModal={isPostModal}
              isSpotlightDetailModal={isSpotlightDetailModal}
            />
          )
        }
      </div>
    </div>
  );
};

export default SpotlightDetails;