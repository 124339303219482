import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Grid, Button, Typography } from '@mui/material';

interface TargetFunctionOption {
  id: string;
  name: string;
}

interface PButtonGridProps {
  name: string;
  options: TargetFunctionOption[];
  onChange?: (selectedOptions: string[]) => void; // Custom onChange callback
  selectedValues?: string[];
}

const PButtonGrid: React.FC<PButtonGridProps> = ({
  name,
  options,
  onChange,
  selectedValues = [],
}) => {
  const { control, setValue, watch } = useFormContext();
  const currentValues = watch(name) || [];

  const handleButtonClick = (value: string) => {
    const newValues = currentValues.includes(value)
      ? currentValues.filter((item: string) => item !== value)
      : [...currentValues, value];

    setValue(name, newValues);

    if (onChange) {
      onChange(newValues);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <Grid container spacing={'5px'}>
          {options.map((item) => (
            <Grid key={item.id} item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
              <Button
                onClick={() => handleButtonClick(item.name)}
                variant="contained"
                sx={{
                  height: '43px',
                  width: '100%',
                  borderRadius: '5px',
                  backgroundColor: currentValues.includes(item.name) ? '#0031FF' : '#EDF2F5',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: '#0031FF',
                    '& .MuiTypography-root': {
                      color: '#ffffff',
                    },
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '15px',
                    textTransform: 'none',
                    color: currentValues.includes(item.name) ? '#ffffff' : '#000000',
                    letterSpacing: '0.3px',
                    lineHeight: '16px',
                  }}
                >
                  {item.name}
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
      )}
    />
  );
};

export default PButtonGrid;
