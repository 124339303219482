import { IVideoCallState } from '../../../../store/1-1-video-call';
import { IEvent } from '../../../../models/event/event';
import { Box, Stack } from '@mui/material';
import { ReactionProvider } from '../../../../components/chime-video-call/reaction-context';
import { ChimeVideoCall } from '../../../../components/chime-video-call';
import { EventLiveVideo } from '../event-live-video/event-live-video';
import { InteractiveEventLive } from '../event-moderator';
import { SessionHandouts } from './session-handouts';
import { AdsAndResources } from './ads-popup';

export function LiveSessionComponent(props: {
  gridColumn: string,
  gridRow: string,
  height: string,
  boxStyles: { backgroundColor: string; pb: string; overflow: string; borderRadius: string; px: string; py: string; position: string },
  callActive: any | undefined | null,
  chatVideoCall: IVideoCallState,
  onCallHangup: () => void,
  interactiveEvent: boolean,
  setRoomID: (value: any) => void,
  strings: string[],
  slug: string | undefined,
  roomId: string | undefined,
  role: string | undefined,
  isHandoutsOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  isPollsOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  isAdsOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  pollsOpen: boolean,
  handouts: any[],
  event: IEvent | undefined,
  sessionTimes: any,
  handoutsOpen: boolean,
  livePolls: boolean,
  headerShow: boolean,
  ads: any[],
  adsOpen: boolean
}) {
  const isShowPolls = props?.livePolls;
  const isShowAds = props?.ads?.length > 0;
  const isShowHandouts = props?.handouts?.length > 0;
  // const isShowPolls = false;
  // const isShowAds = false;
  // const isShowHandouts = true;
  return <Box sx={{
    gridColumn: props.gridColumn,
    gridRow: props.gridRow
  }}>
    <Stack
      sx={{
        p: '0px !important',
        height: props.height,
        ...props.boxStyles,
        border: 'none'
      }}
    >
      <div className="h-full">
        {props.callActive ? (
          <ReactionProvider chatVideoCall={props.chatVideoCall}>

            <ChimeVideoCall
              onCallHangup={props.onCallHangup}
              overrideChimeConfig={props.callActive}/>
          </ReactionProvider>
        ) : (
          !props.interactiveEvent ? (
            <EventLiveVideo
              setRoomID={props.setRoomID}
              isGridView={props.strings?.length > 3}
              calculateVideoGridHeight={props.height}
            />
          ) : (
            <InteractiveEventLive
              slug={props.slug}
              roomId={props.roomId}
              role={props.role}
              isGridView={props.strings?.length > 3}
              setIsHandoutsOpen={props.isHandoutsOpen}
              setIsPollsOpen={props.isPollsOpen}
              setIsAdsOpen={props.isAdsOpen}
              isPollsOpen={props.pollsOpen}
              calculateVideoGridHeight={props.height}
              setRoomID={props.setRoomID}
              isShowAds={isShowAds}
              isShowHandouts={isShowHandouts}
            />
          )
        )}

      </div>
      {!props.callActive ? (
        <>
          <Stack
            sx={{
              position: 'absolute',
              top: isShowHandouts 
              ? (isShowPolls ? 62 : 15) 
              : isShowPolls 
                ? 110 
                : isShowAds 
                  ? 15 
                  : 110,
              right: 14,
              zIndex: '100',
              transition: 'top 0.3s ease-in-out',
              cursor: 'pointer'
            }}
          >
            {isShowHandouts && (
              <SessionHandouts
                handouts={props.handouts}
                companyId={props.event?.companyId!}
                slug={props.event?.company?.slug!}
                eventId={props.event?.id}
                sessionTime={props.sessionTimes[0]}
                setIsHandoutsOpen={props.isHandoutsOpen}
                setIsPollsOpen={props.isPollsOpen}
                setIsAdsOpen={props.isAdsOpen}
                isHandoutsOpen={props.handoutsOpen}
              />
            )}
          </Stack>

          <Stack
            sx={{
              position: 'absolute',
              top: isShowAds 
              ? (isShowPolls ? (isShowHandouts ? 110 : 62) : (isShowHandouts ? 62 : 15)) 
              : isShowPolls 
                ? (isShowHandouts ? 110 : 62) 
                : isShowHandouts 
                  ? 15 
                  : 110,
              right: 14,
              cursor: 'pointer',
              zIndex: '100',
              transition: 'top 0.3s ease-in-out',
            }}
          >
            {isShowAds && (
              <AdsAndResources
                ads={props.ads}
                sessionTime={props.sessionTimes[0]}
                eventId={props.event?.id}
                setIsHandoutsOpen={props.isHandoutsOpen}
                setIsPollsOpen={props.isPollsOpen}
                setIsAdsOpen={props.isAdsOpen}
                isAdsOpen={props.adsOpen}
              />
            )}
          </Stack>
        </>
      ) : null}
    </Stack>
  </Box>;
}
