import classNames from 'classnames';
import { Typography } from '../../shared-components';

interface IPageTitleProps {
  titleText: string;
  pageTitleClass?: string;
  isBlueTheme?: boolean;
}

export const PageTitle = (props: IPageTitleProps) => (
  <div className="flex justify-center">
    <div className="mt-[15px]">
      <Typography
        variant="SectionTitle"
        text={props.titleText}
        classes={classNames('leading-[33px]', {
          [`${props.pageTitleClass}`]: props.pageTitleClass,
          "!text-white": props.isBlueTheme,
        })}
      />
    </div>
  </div>
);

export default PageTitle;
