import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';

export interface FooterLinkProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  href?: string;
  className?: string;
}

export const FooterLink: FC<FooterLinkProps> = ({ text, href, className }) => {
  return (
    <a
      className={classNames("hover:underline text-[12px] leading-[14px] text-[#3C3C3C] font-normal", {
        [`${className}`]: className
      })}
      href={href}
      target="_blank"
    >
      {text}
    </a>
  );
};

export interface FooterProps {
  className?: string;
};

export const Footer: FC<FooterProps> = ({ className }) => {

  return (
    <>
      <footer
        className={classNames({
          [`${className}`]: className
        })}>
        <div className="flex flex-wrap gap-[9px] whitespace-nowrap justify-center text-justify">
          <FooterLink className="!font-semibold" text={`Panelist © Copyright ${new Date().getFullYear()}`} />
          <FooterLink href="/user-agreement" text="User Agreement" />
          <FooterLink href="/privacy-policy.html" text="Privacy Policy" />
          <FooterLink href="/cookie-policy" text="Cookie Policy" />
          <FooterLink href="/community-guidelines" text="Community Guidelines" />
          <FooterLink href="/about" text="About Us" />
          <FooterLink href="/copyright-policy" text="Copyright Policy" />
          <FooterLink href="https://help.panelist.com" text="Help" />
        </div>
      </footer>
    </>
  );
};

export default Footer;