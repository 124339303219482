import { useEffect, useState } from "react";

import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import defaultAvatar from "../../assets/images/avatar-default.svg";
import { Avatar } from "../../shared-components/V2";
import { TextInput } from "../../shared-components/TextInput";
import { IAppState } from "../../store";
import { searchParticipant } from "../../store/user/search-user";
import { useWebSocket } from "../../utils/hooks";
import { ConversationDetail } from "./conversation-detail";
import MessageInput from "./message-input";
import MessageWindowTitle from "./message-window-title";

const NewMessage = (props: {
  avatar: string;
  title: string;
  userId?: string;
  messageText?: string;
  ignoreImageUrlPrefix?: boolean;
  onCloseHandler: () => void;
}) => {
  const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;
  const { newMessage, privateChat, userSearchResult } = useSelector(
    (state: IAppState) => state
  );
  const checkConversationsResponse = privateChat.checkConversationsResponse;

  const [isOpen, setIsOpen] = useState(false);

  const [isUserSelected, setIsUserSelected] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserAvatar, setSelectedUserAvatar] = useState("");
  const [selectedUsername, setSelectedUsername] = useState("New message");
  const [renderUsersList, setRenderUsersList] = useState(false);

  const [editMessage, setEditMessage] = useState<any>(null);
  const [replyMessage, setReplyMessage] = useState<any>(null);
  const dispatch = useDispatch();
  const { webSocket } = useWebSocket();

  useEffect(() => {
    if (props.userId) {
      setIsUserSelected(true);
      setSelectedUserId(props.userId);
      props.avatar
        ? setSelectedUserAvatar(props.avatar)
        : setSelectedUserAvatar("");
      setSelectedUsername(props.title);
      checkConversation(props.userId);
    }
  }, []);

  const checkConversation = (userId: string) => {
    webSocket.then((ws) =>
      ws.send(
        JSON.stringify({
          action: "check-conversation",
          data: {
            participants: [userId],
          },
        })
      )
    );
  };

  const clearUnseenMessage = () => {
    if (checkConversationsResponse && isUserSelected)
      webSocket.then((ws) =>
        ws.send(
          JSON.stringify({
            action: "clear-unseen-message",
            data: {
              conversationId: checkConversationsResponse?.conversationId,
            },
          })
        )
      );
  };

  const handleInputChanged = (e: any) => {
    const inputEl = e.target as HTMLInputElement;
    const searchString = inputEl.value;
    if (searchString.trim().length >= 3) {
      setRenderUsersList(true);
      dispatch(searchParticipant(searchString));
    }
  };

  const handleUserSelected = (
    userId: string,
    avatar: string,
    username: string
  ) => {
    if (userId) {
      setIsUserSelected(true);
      setSelectedUserId(userId);
      avatar ? setSelectedUserAvatar(avatar) : setSelectedUserAvatar("");
      setSelectedUsername(username);
      checkConversation(userId);
    }
  };

  return (
    <div
      className={classNames(
        "mr-2 bg-white shadow-xs9 rounded-tl-xl rounded-tr-xl delay-400 duration-500 ease-in-out transition-all cursor-pointer",
        {
          " -translate-y-[26.5rem]": !isOpen,
          " translate-y-[0rem]": isOpen,
        }
      )}
    >
      <div
        className={classNames("transform ease-in-out", {
          "transition-opacity opacity-100 duration-500 translate-x-0":
            newMessage.isOpen,
          "transition-all delay-500 opacity-0 translate-x-full":
            !newMessage.isOpen,
        })}
      >
        <div
          className={classNames(
            "w-[330px] min-h-[422px] max-w-lg right-80 bottom-0 delay-400 duration-500 ease-in-out transition-all transform",
            {
              "translate-y-0": newMessage.isOpen,
              "translate-y-full": !newMessage.isOpen,
            }
          )}
        >
          <div className="relative max-w-lg h-[475px] flex flex-col">
            <div className="flex flex-col h-full">
              <div>
                <MessageWindowTitle
                  userId={selectedUserId}
                  avatar={
                    selectedUserAvatar !== ""
                      ? `${imageUrlPrefix}/${selectedUserAvatar}`
                      : defaultAvatar
                  }
                  conversationId={checkConversationsResponse?.conversationId!}
                  username={selectedUsername}
                  ignoreImageUrlPrefix={props.ignoreImageUrlPrefix}
                  onCloseHandler={props.onCloseHandler}
                  onMessageBoxTitleClick={() => setIsOpen(!isOpen)}
                />
              </div>
              {!isUserSelected && (
                <div className="bg-white shadow-xs7">
                  <TextInput
                    type="text"
                    textinputsize="small"
                    className="h-8 text-x-sm font-light text-gray-12 px-2.5 m-2 outline-none border-b-1 border-gray-1"
                    placeholder="Type a name"
                    onChange={(e) => handleInputChanged(e)}
                  />
                </div>
              )}
              <div>
                {userSearchResult.searchResult?.data.users?.data?.length! > 0 &&
                  !isUserSelected &&
                  renderUsersList && (
                    <div className="flex flex-col overflow-y-scroll px-2 py-2 bg-white h-[220px] overflow-hidden">
                      {userSearchResult.searchResult?.data.users?.data?.map(
                        (user, index) => (
                          <div
                            key={index}
                            className="flex flex-row mb-4 cursor-pointer"
                            onClick={() =>
                              handleUserSelected(
                                user.id,
                                user.avatar!,
                                `${user.firstName} ${user.lastName}`
                              )
                            }
                          >
                            <div className="">
                              <Avatar
                                src={`${imageUrlPrefix}/${user.avatar}`}
                                size="XSMALL"
                              />
                            </div>
                            <div className="py-1 px-2 text-blue-3 text-md3 font-normal">{`${user.firstName} ${user.lastName}`}</div>
                          </div>
                        )
                      )}
                    </div>
                  )}
              </div>
              <div className="bg-white h-full overflow-y-scroll">
                {checkConversationsResponse && isUserSelected && (
                  <ConversationDetail
                    conversationId={checkConversationsResponse?.conversationId}
                    editMessage={editMessage}
                    onMessageUpdate={setEditMessage}
                    onMessageReply={setReplyMessage}
                  />
                )}
              </div>
            </div>
            <div className="px-3 py-2 bottom-0 bg-white">
              <MessageInput
                recepientId={selectedUserId}
                conversationId={checkConversationsResponse?.conversationId!}
                messageText={props.messageText}
                onFocusHandler={() => clearUnseenMessage()}
                replyMessage={replyMessage}
                editMessage={editMessage}
                setEditMessage={setEditMessage}
                onCancelReplyMessage={() => {
                  setReplyMessage(null);
                  setEditMessage(null);
                }}
                setFile={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMessage;
