import { Reducer } from 'redux';

import { IUpcomingEventAgendaState } from '../../../models/event/UpcomingEventAgendaState';
import { UpcomingEventAgendaActions } from './actions';
import { UpcomingEventAgendaActionTypes } from './types';

const initialState: IUpcomingEventAgendaState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const UpcomingEventAgendaReducer: Reducer<
  IUpcomingEventAgendaState,
  UpcomingEventAgendaActions
> = (state = initialState, action) => {
  switch (action.type) {
    case UpcomingEventAgendaActionTypes.EVENT_AGENDA_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case UpcomingEventAgendaActionTypes.EVENT_AGENDA_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case UpcomingEventAgendaActionTypes.EVENT_AGENDA_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
