export interface AvatarDefaultSmallSvgIconProps {
  fillColor?: string;
  style?: React.CSSProperties; // Allow custom styles
}

export const AvatarDefaultSmallSvgIcon = ({ fillColor, style }: AvatarDefaultSmallSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#bdcce8"
    return <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" style={style}>
    <g id="Group_19588" data-name="Group 19588" transform="translate(-307.24 -171.463)">
      <rect id="Rectangle_7733" data-name="Rectangle 7733" width="57" height="57" rx="10" transform="translate(307.24 171.463)" fill="rgba(237,242,245,0)"/>
      <path id="Path_14632" data-name="Path 14632" d="M18.789,0A12.585,12.585,0,1,1,6.2,12.587,12.585,12.585,0,0,1,18.789,0" transform="translate(316.956 181.275)" fill={iconColor}/>
      <path id="Path_14633" data-name="Path 14633" d="M9.235,22.694a1.08,1.08,0,0,0,.879-.472,9.993,9.993,0,0,1,16.275,0,1.088,1.088,0,0,0,.891.473h2.359a1.1,1.1,0,0,0,1.088-1.109,1.075,1.075,0,0,0-.134-.519,14.112,14.112,0,0,0-24.7.022,1.088,1.088,0,0,0,.967,1.6Z" transform="translate(317.39 195.956)" fill={iconColor}/>
    </g>
  </svg>
  
};