import giveattendance from '../../../assets/images/virtual/giveattendance.png';
import giveattendance2 from '../../../assets/images/virtual/giveattendance@2x.png';

export const MakeMostOfTheirTime = () => (
  <div className="bg-gray-3 min-h-371">
    <div className="2xl:w-9/12 lg:flex flex-row mx-auto w-10/12">
      <div className="left w-3/6 pr-7">
        <div className="lg:flex flex-col">
          <div className="items-start ml-22 2xl:ml-0 mt-20 mb-12">
            <div className="mx-auto w-8/12">
              <div className="-ml-3 mr-4">
                <p className="text-lg font-extralight text-dark-blue mr-8">
                  Give your attendees a chance to make the most of their time at your event
                </p>
                <p className="text-md font-extralight text-gray-1 mt-5 text-left ">
                  Provide more value to your attendees with your on-demand material, info decks,
                  presentation slides and much more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="-ml-16 pl-3">
        <div className="-mt-10">
          <img
            className="z-50"
            srcSet={`${giveattendance} 300w, ${giveattendance2} 1000w`}
            sizes="(max-width: 700px) 30vw, (max-width: 700px) 30vw, 500px"
            alt=""
            src={giveattendance2}
          />
        </div>
      </div>
    </div>
  </div>
);

export default MakeMostOfTheirTime;
