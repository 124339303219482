import { useSelector } from 'react-redux';

import ToggleSwitch from '../../shared-components/ToggleSwitch/ToggleSwitch';
import { IAppState } from '../../store';

const MesssagesTab = (props: {
  handleNewMessageClick: () => void;
  handleShowMessagesClick: () => void;
  handleUserStatusToggle: () => void;
}) => {
  const { userStatus } = useSelector((state: IAppState) => state);

  return (
    <div className='flex px-2 py-2 justify-between'>
      <div
        className='flex flex-row cursor-pointer w-full'
        onClick={props.handleShowMessagesClick}
      >
        <div className='px-2 py-2'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='19'
            height='15.45'
            viewBox='0 0 23.199 18.623'
            className='margin-1px'
          >
            <g
              id='Icon_feather-mail'
              data-name='Icon feather-mail'
              transform='translate(0.75 0.75)'
            >
              <path
                id='Path_598'
                data-name='Path 598'
                d='M5.14,6H22.263A2.147,2.147,0,0,1,24.4,8.14V20.983a2.147,2.147,0,0,1-2.14,2.14H5.14A2.147,2.147,0,0,1,3,20.983V8.14A2.147,2.147,0,0,1,5.14,6Z'
                transform='translate(-3 -6)'
                fill='none'
                stroke='#172746'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
              ></path>
              <path
                id='Path_599'
                data-name='Path 599'
                d='M24.015,9,13.508,16.417,3,9'
                transform='translate(-2.611 -6.785)'
                fill='none'
                stroke='#172746'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
              ></path>
            </g>
          </svg>
        </div>
        <div className='ml-1 mt-1 text-[#172746]'>Messages</div>
      </div>
      <div className='flex flex-row'>
        {/* <div className='mt-1'>
          <ToggleSwitch
            onChangeHandler={props.handleUserStatusToggle}
            isChecked={userStatus.isUserOnline}
          />
        </div> */}
        <div
          className='px-2 py-1.5 cursor-pointer'
          onClick={props.handleNewMessageClick}
        >
          <svg
            id='btn-new-message'
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='22'
            viewBox='0 0 23 23'
          >
            <g
              id='Group_11535'
              data-name='Group 11535'
              transform='translate(-718 -21)'
            >
              <g
                id='Icon_feather-edit'
                data-name='Icon feather-edit'
                transform='translate(716 19.182)'
              >
                <path
                  id='Path_10316'
                  data-name='Path 10316'
                  d='M12.393,6H5.087A2.087,2.087,0,0,0,3,8.087V22.7a2.087,2.087,0,0,0,2.087,2.087H19.7A2.087,2.087,0,0,0,21.786,22.7V15.393'
                  transform='translate(0 -0.968)'
                  fill='none'
                  stroke='#172746'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                ></path>
                <path
                  id='Path_10317'
                  data-name='Path 10317'
                  d='M22.958,3.466A2.214,2.214,0,0,1,26.089,6.6l-9.915,9.915L12,17.556l1.044-4.175Z'
                  transform='translate(-2.738)'
                  fill='none'
                  stroke='#172746'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                ></path>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default MesssagesTab;
