import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { INotificationsResult } from '../../models/nav-bar/notifications/notifications-result';
import { INotificationsState } from '../../models/nav-bar/notifications/notifications-state';
import { UserService } from '../../services';

const initialState: INotificationsState = {
  errorMessage: '',
  data: [],
  unreadCount: 0,
  unseenCount: 0,
  page: 0,
  totalPage: 0,
  totalRow: 0,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    notificationsError: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    notificationsSuccess: (
      state,
      action: PayloadAction<INotificationsResult>
    ) => {
      state.data = state.data?.concat(action.payload.data);
      state.unreadCount = action.payload.unreadCount;
      state.unseenCount = action.payload.unseenCount;
      state.page = action.payload.page;
      state.totalPage = action.payload.totalPage;
      state.totalRow = action.payload.totalRow;
    },
  },
});

export const { notificationsError, notificationsSuccess } =
  notificationsSlice.actions;

export const getNotifications =
  (payload: any) => async (dispatch: Dispatch) => {
    try {
      const userService = new UserService();
      const result = await userService.getNotifications(payload?.page);
      dispatch(notificationsSuccess(result));
    } catch (e: any) {
      dispatch(notificationsError(e?.message));
    }
  };
