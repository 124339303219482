import React, { useEffect, useRef } from 'react';
import Carousel, {
  CarouselArrows,
  useCarousel,
} from 'src/mui/components/carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {
  CardMedia, IconButton, Stack, Tooltip
} from '@mui/material';
import classNames from 'classnames';

type PdfImageUrl = {
  order: number;
  key: string;
};

type ImageViewerProps = {
  pdfImageUrls: PdfImageUrl[];
  onScreenClick?: () => void;
  fullScreen?: boolean;
  className?: string;
  isSinglePage?: boolean;
  isSpotlightDetailModal?: boolean;
};

const PDFImageViewer: React.FC<ImageViewerProps> = ({
  pdfImageUrls,
  onScreenClick,
  fullScreen,
  className,
  isSinglePage,
  isSpotlightDetailModal
}) => {
  const fullSliderRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const sliders = document.querySelectorAll('.slick-slider');
    sliders.forEach((slider, index) => {
      // eslint-disable-next-line no-param-reassign
      slider.id = `slick-slider-${index}`;
    });
  }, []);

  const handleFullScreen = () => {
    const slider = fullSliderRef.current;
    if (!slider) return;

    if (slider.requestFullscreen) {
      if (slider.requestFullscreen) {
        slider.requestFullscreen();
      } else if (slider.webkitRequestFullscreen) { /* Safari */
        slider.webkitRequestFullscreen();
      } else if (slider.msRequestFullscreen) { /* IE11 */
        slider.msRequestFullscreen();
      }
    }

    // Assuming you want to full screen the whole slider
    const sliderRef = document.querySelector('.slick-slider');
    if (sliderRef) {
      const fullscreenChangeHandler = () => {
        const { fullscreenElement } = document;
        if (fullscreenElement === sliderRef) {
          // Add your custom class when the slider enters full-screen mode
          sliderRef.classList.add('pdf-image-full');
        } else {
          // Remove your custom class when the slider exits full-screen mode
          sliderRef.classList.remove('pdf-image-full');
        }
      };

      // Listen for the fullscreenchange event
      document.addEventListener('fullscreenchange', fullscreenChangeHandler);
    }
  };
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };
  const carousel = useCarousel({
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 500,
    pauseOnHover: false,
  });

  // Custom styles
  const sliderArrowStyle = {
    position: 'absolute',
    top: '60%',
    left: 10,
    right: 10,
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 10,
  };

  const arrowStyle = {
    width: 26,
    height: 26,
    backgroundColor: '#e9eff2',
    borderRadius: 100,
  };
  const mediaStyle = {
    maxWidth: '100%',
    maxHeight: fullScreen ? 'calc(100vh - 300px)' : '100%',
    width: 1,
    margin: 'auto',
    objectFit: 'contain',
    borderRadius: '0 0 10px 10px',
    // background: isSinglePage ? '#edf2f5' : 'transparent'
  };
  const fullScreenIconStyle = {
    color: 'black',
    position: 'absolute',
    right: '10px',
    bottom: '2px',
    zIndex: 10,
    padding: 0
  };

  const slidesCountStyle = {
    position: 'absolute',
    left: fullScreen ? '25px' : '10px',
    bottom: '0px',
    color: '#ffffff',
    fontSize: '13px',
    height: '28px',
    justifyContent: 'center',
    lineHeight: '29px'

  };

  return (
    <div
      ref={fullSliderRef}
      className={classNames('relative h-full', {
        'h-[calc(100%--105px)]': isSpotlightDetailModal,
      })}
      onClick={handleClick}
    >
      {pdfImageUrls.length > 0 && !isSinglePage ? (
        <Stack>
          <CarouselArrows
            onNext={carousel.onNext}
            onPrev={carousel.onPrev}
            sx={sliderArrowStyle}
            leftButtonProps={{
              sx: {
                p: 0.5,
                mt: -10.5,
                pr: 0.5,
                left: 0,
                '& svg': arrowStyle,
              },
            }}
            rightButtonProps={{
              sx: {
                p: 0.5,
                mt: -10.5,
                pl: 0.5,
                right: 0,
                '& svg': arrowStyle,
              },
            }}
          />
        </Stack>
      ) : null}

      <Carousel className={className} ref={carousel.carouselRef} {...carousel.carouselSettings}>
        {pdfImageUrls?.map((img, index) => (
          <div key={index}>
            {/* <img
              src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${img.key}`}
              style={{
                width: "100%",
                maxHeight: "80vh",
                objectFit: "contain",
                margin: "0 auto",
              }}
              alt={`Extracted page ${index + 1}`}
            /> */}
            <CardMedia
              sx={mediaStyle}
              component="img"
              image={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${img?.key}`}
            />
          </div>
        ))}
      </Carousel>
      {!isSinglePage && (
        <>
          <Stack sx={slidesCountStyle}>
            {carousel.currentIndex + 1}
            /
            {pdfImageUrls?.length}
          </Stack>
          <Tooltip title="Full Screen">
            <IconButton
              sx={fullScreenIconStyle}
              onClick={onScreenClick || handleFullScreen}
            >
              <FullscreenIcon sx={{ color: '#ffffff' }} />
            </IconButton>
          </Tooltip>
        </>
      )}

    </div>
  );
};

export default PDFImageViewer;
