// import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useCurrentUser, useUserDetails } from '../../../../utils/hooks';
// import { UserService } from '../../../../services';
import { mergeArrays } from '../../../../utils';
import { ArrowDownSvgIcon } from '../../../../shared-components/V2/IconSvg';
import { Avatar } from '../../../../shared-components/V2';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface Props {
  userProfile?: any;
  userId?: string;
  isOwnProfileNot?: boolean;
  interests?: any;
  offers?: any;
}

export const UserHighlightsMui = ({
  userProfile, userId, isOwnProfileNot, interests, offers
}: Props) => {
  const colors = getBrandingColors();
  const user = useCurrentUser();
  const { avatar } = useUserDetails(user);
  // const [interests, setInterests] = useState([]);
  // const [offers, setOffers] = useState([]);

  // const getInterestMatch = useCallback(async (id: string) => {
  //   const userService = new UserService();
  //   const result = await userService.getMatchedInterestResults(id).then(({ data }) => data.map((x: any) => ({ ...x, type: 'interest' })) ?? []);
  //   setInterests(result);
  // }, []);

  // const getOfferMatch = useCallback(async (id: string) => {
  //   const userService = new UserService();
  //   const result = await userService.getMatchedOfferingResults(id).then(({ data }) => data.map((x: any) => ({ ...x, type: 'offer' })) ?? []);
  //   setOffers(result);
  // }, []);

  // useEffect(() => {
  //   if (userId) {
  //     getInterestMatch(userId);
  //     getOfferMatch(userId);
  //   } else {
  //     getInterestMatch(id!);
  //     getOfferMatch(id!);
  //   }
  // }, [getInterestMatch, userId, id, getOfferMatch]);

  return (
    <div
      className={classNames('w-full m-auto justify-center mb-[8px]', {
        '': isOwnProfileNot
      })}
    >
      <div
        className={classNames('userProfile mt-[21px] md:px-0 px-2', {
          '!-ml-0': isOwnProfileNot
        })}
      >
        {
          mergeArrays(interests, offers).map((hightlight: any) => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {
              hightlight?.type === 'interest' ? (
                <div className="flex justify-between items-center mb-2">
                  <div 
                    className="text-[12px] font-medium leading-[14px] py-[4px] px-[12px] rounded-5"
                    style={{
                      backgroundColor: colors?.Accent || '#F2FFFB',
                      color: colors?.ButtonTextColor || '#203C6E'
                    }}
                  >
                    {hightlight?.interest?.name}
                  </div>
                  <div className="flex items-center">
                    <div className="mr-6"><ArrowDownSvgIcon fillColor={colors?.Accent} className="rotate-90 w-[11px]" /></div>
                    <div style={{ color: colors?.Text || '#ffffff' }} className="text-[12px] font-medium leading-5">
                      Help
                      {' '}
                      {userProfile}
                      {' '}
                      with
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex justify-between items-center mb-2.5">
                  <div className="flex items-center">
                    <div style={{ color: colors?.Text || '#ffffff' }} className="text-[12px] font-medium leading-5">
                      Ask
                      {' '}
                      {userProfile}
                      {' '}
                      about
                    </div>
                    <div className="ml-6"><ArrowDownSvgIcon fillColor={colors?.Accent} className="-rotate-90 w-[11px]" /></div>
                  </div>
                  <div 
                    className="text-[12px] font-medium leading-[14px] py-[4px] px-[12px] rounded-5"
                    style={{
                      backgroundColor: colors?.Accent || '#F2FFFB',
                      color: colors?.ButtonTextColor || '#203C6E'
                    }}
                  >
                    {hightlight?.interest?.name}
                  </div>
                </div>
              )
            }
            </>
          ))
        }
      </div>
    </div>
  );
};
