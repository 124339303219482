import { SvgIconProps } from '@mui/material/SvgIcon';

export const VolumDownIcon = (props: SvgIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.6" height="16.443" viewBox="0 0 19.6 16.443" {...props}>
  <g id="Group_21266" data-name="Group 21266" transform="translate(0.449 0)">
    <path id="Path_343" data-name="Path 343" d="M2.222,3.777A2.182,2.182,0,0,0,0,6v4.444a2.182,2.182,0,0,0,2.222,2.222H4.148l4.74,3.777V0L4.148,3.777ZM14.813,8.221A6.129,6.129,0,0,0,13.11,4a.733.733,0,0,0-1.037,1.037,4.658,4.658,0,0,1,0,6.37.716.716,0,0,0,0,1.037.716.716,0,0,0,1.037,0A6.129,6.129,0,0,0,14.813,8.221Zm-1.037-8A.8.8,0,0,0,12.74.37a.8.8,0,0,0,.148,1.037,8.518,8.518,0,0,1,0,13.628.8.8,0,0,0-.148,1.037.674.674,0,0,0,.593.3,1.053,1.053,0,0,0,.444-.148,10,10,0,0,0,0-16Z" transform="translate(0 0)" fill="#fff"/>
    <path id="Path_345" data-name="Path 345" d="M1178.311,830.9h21.311" transform="translate(-416.013 -1378.586) rotate(38)" fill="none" stroke="#b5b5b5" strokeLinecap="round" strokeWidth="2"/>
  </g>
</svg>

  );
};
