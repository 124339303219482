/* eslint-disable max-len */
import { ArrowRightSvgIcon } from 'src/shared-components/V2/IconSvg/ArrowRightSvgIcon/ArrowRightSvgIcon';
import { useState } from 'react';
import { LinkButton } from '../../shared-components';

interface IFrame1Props {
  url: string;
  name: string;
}
export const ProfessionalsBannerButton = ({ url, name }: IFrame1Props) => {
  const [ishover, setIshover] = useState<boolean>(false);
  return (
    <div
      className="relative 2xl:w-[526px] sm:w-[428px] w-full 2xl:hover:w-[555px] transition-all"
      onMouseEnter={() => setIshover(true)}
      onMouseLeave={() => setIshover(false)}
    >
      <LinkButton
        text={name}
        backgroundColor=""
        textColor=""
        classes="2xl:w-[526px] sm:w-[428px] w-full 2xl:hover:!w-[555px] text-black hover:text-white 2xl:h-[75px] h-[45px] bg-white !rounded-[5px] !text-[18px] 2xl:!text-[25px] font-medium hover:bg-black !text-left transition-all sm:pl-[30px] pl-[15px]"
        width=""
        height=""
        href={url}
        replace
      />
      <div className=" absolute sm:right-[30px] right-[12px] sm:top-[10px] 2xl:top-[25px] top-[11px]">
        <ArrowRightSvgIcon fillColor={ishover ? '#ffffff' : ''} />
      </div>
    </div>
  );
};

export default ProfessionalsBannerButton;
