import { useState, useEffect, useRef, useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import { appBarClasses } from '@mui/material/AppBar';
// routes
import { usePathname } from 'src/mui/routers/hook';
import { useActiveLink } from 'src/mui/routers/hook/use-active-link';
//
import { NavListProps, NavConfigProps } from '../types';
import NavItem from './nav-item';

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps;
  active: boolean;
  depth: number;
  hasChild: boolean;
  config: NavConfigProps;
  setIsActive?: (value: any) => void;
  onSessionClick: (value: any) => void;
};

export default function NavList({ data, depth, hasChild, config, active, setIsActive, onSessionClick }: NavListRootProps) {
  const navRef = useRef(null);

  const pathname = usePathname();

  // const active = useActiveLink(data.path, hasChild);

  const externalLink = data.path.includes('http');

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const appBarEl = Array.from(
      document.querySelectorAll(`.${appBarClasses.root}`)
    ) as Array<HTMLElement>;

    // Reset styles when hover
    const styles = () => {
      document.body.style.overflow = '';
      document.body.style.padding = '';
      // Apply for Window
      appBarEl.forEach((elem) => {
        elem.style.padding = '';
      });
    };

    if (open) {
      styles();
    } else {
      styles();
    }
  }, [open]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <NavItem
        ref={navRef}
        item={data}
        depth={depth}
        open={open}
        active={active}
        config={config}
        onClick={() => {
          if (!data?.disabled) {
            setIsActive && setIsActive(data);
            onSessionClick && onSessionClick(data);
          }

        }}
      // onMouseEnter={() => {
      //   setIsHover && setIsHover({data, active: true});
      //   handleOpen;
      // }}
      // onMouseLeave={() => {
      //   setIsHover && setIsHover({data, active: false});
      //   handleClose;
      // }}
      />
    </>
  );
};

// ----------------------------------------------------------------------

{/* type NavListSubProps = {
  data: NavListProps[];
  depth: number;
  config: NavConfigProps;
}; */}

{/* function NavSubList({ data, depth, config }: NavListSubProps) {
  return (
    <Stack spacing={0.5}>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </Stack>
  );
} */}
