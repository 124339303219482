export const DeletePost = (props: {
  postId: string;
  message: string;
  title?: string;
  onDelete: Function;
  onClose: Function;
}) => {
  return (
    <div className='w-full'>
      <div className='flex flex-col items-center'>
      <div className='text-md leading-7 font-medium text-[#0B1221] text-center mb-4'>{props.title}</div>
        <div className='text-md text-[#0B1221] text-center max-w-[310px]'>{props.message}</div>
        <div className='flex flex-row mx-auto mt-[18px] mb-[10px]'>
          <div className='mr-2'>
            <button
              className='bg-white w-[88px] h-[30px] rounded-[5px] text-[#203C6E] text-sm flex items-center justify-center hover:bg-[#CEDCF2] font-semibold tracking-wide disabled:bg-gray-1 disabled:text-white'
              onClick={() => props.onClose()}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className='bg-[#0049EF] hover:bg-[#2e6eff] w-[88px] h-[30px] rounded-[5px] text-white text-sm flex items-center justify-center font-semibold tracking-wide disabled:bg-gray-1 disabled:text-white'
              onClick={() => props.onDelete(props.postId)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
