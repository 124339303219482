import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { RangeSlider } from '../../../shared-components/V2/RangeSlider';
import { Button } from '../../../shared-components/V2/Button';
import { getCroppedImg } from './convas-utils';

import './user-profile-crop.css';

interface IUserProfileCropProps {
  imgUrl: string;
  onCloseModal: Function;
  onCropProfile: Function;
  title: string;
}

const CoverPhotoAndAvatar = ({
  imgUrl,
  onCloseModal,
  onCropProfile,
  title
}: IUserProfileCropProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((_croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  function dataURItoBlob(dataURI: any) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString: any;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = window.decodeURI(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imgUrl,
        croppedAreaPixels,
        rotation
      );
      const blob = dataURItoBlob(croppedImage);
      onCropProfile(blob);
    } catch (e) {
      console.error(e);
    }
  }, [imgUrl, croppedAreaPixels, rotation, onCropProfile]);

  const onChangeZoom = (value: any) => {
    setZoom(value);
  };

  const onChangeRotation = (value: any) => {
    setRotation(value);
  };

  const cropSize: any = {
    height: 339,
    width: 339,
  };

  const aspectRatio: any = 4;

  return (
    <div className="bg-white rounded-lg mt-0 px-6 pb-3">
      <div className="mb-6 py-4 border-b border-blue-100">
        <p className="font-semibold text-lg text-blue-2">
          {title}
        </p>
      </div>
      <div className="mb-4 relative h-[419px] rounded-15 crop-container">
        <Cropper
          image={imgUrl}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={aspectRatio}
          cropShape="rect"
          showGrid={false}
          cropSize={cropSize}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          minZoom={1}
          maxZoom={8}
        />
      </div>
      <div className="w-full mb-3">
        <RangeSlider
          name="Zoom"
          onChange={e => onChangeZoom(e)}
          min="1"
          max="30"
          initilaValue={1}
        />
      </div>
      <div className="w-full mb-6">
        <RangeSlider
          name="Rotate"
          onChange={onChangeRotation}
          min="0"
          max="180"
          initilaValue={0}
        />
      </div>
      <div className="flex items-center justify-end">
        <Button
          onClick={() => onCloseModal()}
          className="bg-gray-1 text-blue-2 h-[28px] mr-5"
          text="Cancel"
        />
        <Button
          className="bg-blue text-white h-[28px]"
          text="Save"
          onClick={showCroppedImage}
        />
      </div>
    </div>
  );
};

export default CoverPhotoAndAvatar;
