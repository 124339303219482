import { IContentResult } from '../../models/library-content/ContentResult';
import { IRegion } from '../../models/library-content/region';
import BaseService from '../BaseService';

export class ContentService extends BaseService {
  async getContent(queryParams?: any) {
    const extraOptions = this.getAuthToken();

    // eslint-disable-next-line
    if (queryParams === null || queryParams === '') queryParams = 'page=1&limit=10';

    const url = `/library/contents?${queryParams}&page=1&limit=10`;

    return this.get<IContentResult>(url, extraOptions);
  }

  async getSearchContentResults(searchEntry?: string) {
    const extraOptions = this.getAuthToken();

    const url = `/search/resource?page=1&limit=3&search=${searchEntry}`;

    return this.get<IContentResult>(url, extraOptions);
  }

  async getRegions() {
    const extraOptions = this.getAuthToken();

    return this.get<IRegion>('/library/contents/regions', extraOptions);
  }
}
