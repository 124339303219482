import { Reducer } from 'redux';
import { ICompanySpecialities } from '../../../models/company/company-specialities';
import { CompanyProfilePreviewFromCacheActions } from './actions';
import { CompanyProfilePreviewFromCacheActionTypes } from './types';

export interface ICompanyProfilePreviewFromCacheState {
  name: string;
  tagLine: string;
  specialist: ICompanySpecialities[];
  size: string;
  about: string;
  website:string;
  industry:string;
  headquarter: string;
  timeline: number;
  secondaryCapabilities: string[];
  interestIndustries: string[];
  personaInterest: string[];
  targetAccounts:string []
}
const initialState: ICompanyProfilePreviewFromCacheState = {
  name: '',
  tagLine: '',
  specialist: [],
  size: '',
  about:'',
  website:'',
  industry:'',
  headquarter:'',
  timeline: 1,
  secondaryCapabilities: [],
  interestIndustries: [],
  personaInterest: [],
  targetAccounts:[]
};

export const CompanyProfilePreviewFromCacheReducer: Reducer<
ICompanyProfilePreviewFromCacheState,
CompanyProfilePreviewFromCacheActions
> = (state = initialState, action) => {
  switch (action.type) {
    case CompanyProfilePreviewFromCacheActionTypes.SET_SPECIALIST_COMPANY_PREVIEW: {
      return {
        ...state,
        specialist: action.payload.specialist
      };
    }

    case CompanyProfilePreviewFromCacheActionTypes.SET_SIZE_COMPANY_PREVIEW: {
      return {
        ...state,
       size: action.payload.size
      };
    }

    case CompanyProfilePreviewFromCacheActionTypes.SET_NAME_COMPANY_PREVIEW: {
      return {
        ...state,
       name: action.payload.name
      };
    }

    case CompanyProfilePreviewFromCacheActionTypes.SET_TAGLINE_COMPANY_PREVIEW: {
      return {
        ...state,
        tagLine: action.payload.tagLine
      };
    }
    
    case CompanyProfilePreviewFromCacheActionTypes.SET_TIMELINE_COMPANY_PREVIEW: {
      return {
        ...state,
        timeline: action.payload.timeline
      };
    }
    case CompanyProfilePreviewFromCacheActionTypes.SET_SECONDARY_CAPABILITIES_COMPANY_PREVIEW: {
      return {
        ...state,
        secondaryCapabilities: action.payload.secondaryCapabilities
      };
    }
    case CompanyProfilePreviewFromCacheActionTypes.SET_INTEREST_INDUSTRIES_COMPANY_PREVIEW: {
      return {
        ...state,
        interestIndustries: action.payload.interestIndustries
      };
    }
    case CompanyProfilePreviewFromCacheActionTypes.SET_PERSONA_INTEREST_COMPANY_PREVIEW: {
      return {
        ...state,
        personaInterest: action.payload.personaInterest
      };
    }

    case CompanyProfilePreviewFromCacheActionTypes.SET_ABOUT_COMPANY_PREVIEW: {
      return {
        ...state,
       about: action.payload.about
      };
    }

    case CompanyProfilePreviewFromCacheActionTypes.SET_WEBSITE_COMPANY_PREVIEW: {
      return {
        ...state,
       website: action.payload.website
      };
    }

    case CompanyProfilePreviewFromCacheActionTypes.SET_INDUSTRY_COMPANY_PREVIEW: {
      return {
        ...state,
       industry: action.payload.industry
      };
    }

    case CompanyProfilePreviewFromCacheActionTypes.SET_HEADQUARTER_COMPANY_PREVIEW: {
      return {
        ...state,
        headquarter: action.payload.headquarter
      };
    }

    case CompanyProfilePreviewFromCacheActionTypes.SET_TARGET_ACCOUNTS_COMPANY_PREVIEW: {
      return {
        ...state,
        targetAccounts: action.payload.targetAccounts
      };
    }
    default:
      return state;
  }
};
