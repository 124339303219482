import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Typography } from '../Typography';
import { GroupUserIcons } from '../GroupUserIcons';
import { Logo } from '../Logo';

export interface IYourEvents {
    events: any[];
    baseUrl?: string;
    onConnectionsClick: (id: any) => void;
}

export const YourEvents = ({ events, baseUrl, onConnectionsClick }: IYourEvents) => {
    const navigate = useNavigate();

    if (!events) return null;

    return <div className="relative rounded-lg md:w-full mt-[10px] your-events">
        <Typography
            variant="NameTitleSmall"
            text={'Your events'}
            classes="!text-[15px] !font-[400] !text-[#000000] !leading-[21px] pl-[0px]"
        />
        {events.map((event: any, index: number) => event?.startTime && (
            <>
                <div className="my-2.5" key={`upcoming-events-${index}`}>
                    <div
                        className="hover:bg-[#E8EBED] rounded-10 cursor-pointer mb-2.5"
                    >
                        <div className="flex h-full px-2.5 py-2.5">
                            <div
                                className={'flex-none w-[46px] mr-[10px]'}
                                onClick={() => navigate(`/event/${event.slug}/discussion`)}
                            >
                                <Logo
                                    className="rounded-[10px] object-cover h-[39px] w-full"
                                    src={event.cover}
                                />
                                <Typography
                                    variant="SmallSection"
                                    text={
                                        !!event?.isHosting && 'Hosting' ||
                                        !!event?.isSponsoring && 'Sponsor' ||
                                        !!event?.isSpeaker && 'Speaker' ||
                                        'Attending'

                                    }
                                    classes="text-center leading-[16px] !text-[8px] !text-[#000000] !font-medium"
                                />
                            </div>
                            <div>
                                <div onClick={() => navigate(`/event/${event.slug}/discussion`)}>
                                    <Typography
                                        classes='text-[13px] font-semibold leading-[17px] text-[#000000] line-clamp-2'
                                        variant="SubTitle"
                                        text={event.name}
                                    />
                                </div>
                                <div key={`upcoming-event`} className='text-[11px] font-normal h-[16px] text-[#000000] mt-[4px] mb-[4px]'>
                                    {format(parseISO(event.startTime), "dd MMM yyyy '•' hh:mm aaaa")}
                                </div>
                                <div
                                    onClick={() => onConnectionsClick (event.relatedAttendees)}
                                >
                                    <GroupUserIcons
                                        users={event.relatedAttendees}
                                        baseUrl={baseUrl}
                                        userQty={1}
                                        containerClass="h-[18px]"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { (events?.length - 1) > index && <div className="w-full border-b-1 border-[#707070] opacity-[28%]" />}
            </>
        ))}
    </div>
}
