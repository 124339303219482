import React, { FC } from 'react';
import { UserNavbar } from '../components';

interface IUserProfileSpotlightLayoutProps {
  hero?: JSX.Element;
  children: JSX.Element;
  rightPanel: JSX.Element;
}
export const UserProfileSpotlightLayout: FC<IUserProfileSpotlightLayoutProps> = ({
  hero,
  children,
  rightPanel
}) => (
  <div className="bg-gray-1 h-full">
    <UserNavbar />
    <div>
      <div className="container mx-auto mt-6 px-6 xl:px-0 max-w-[100%] xl:max-w-[1258px]">
        <div className="md:flex flex-row justify-center w-full">
          <div className="flex flex-col pb-2">
            <div className="xl:w-[912px] w-full">{hero}</div>
          </div>
          <div className="xl:flex flex-col md:ml-[31px] hidden">{rightPanel}</div>
        </div>
        <div className="flex flex-row justify-center pt-2 w-full">
          <div className="md:mb-8 w-full">{children}</div>
        </div>
      </div>
    </div>
  </div>
);

export default UserProfileSpotlightLayout;
