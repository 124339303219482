import { useDispatch, useSelector } from 'react-redux';
import {
  useLocalVideo,
  useMeetingManager,
  LocalVideo,
  useRemoteVideoTileState,
  RemoteVideo,
  useRosterState,
  useAttendeeStatus,
  RosterAttendeeType,
  ContentShare,
  useContentShareState,
} from 'amazon-chime-sdk-component-library-react';
import React, { useEffect, useMemo, useState } from 'react';
import { IAppState } from '../../store';
import { ChimeService } from '../../services/ChimeService';
import { VideoCallLayout } from './video-call-layout';
import { useCurrentUser } from '../../utils/hooks';
import { UserService } from '../../services';
import { IUserProfile } from '../../models/user-profile';
import { UserCard } from './user-card';
import classNames from 'classnames';
import { IVideoCallState } from 'src/store/1-1-video-call';
import { ReactComponent as LikedSvg } from "src/assets/images/post-reactions/like-emoji.svg";
import { ReactComponent as LoveSVG } from "src/assets/images/post-reactions/love-emoji.svg";
import { ReactComponent as SmileSVG } from "src/assets/images/post-reactions/smile-emoji.svg";
import { ReactComponent as WellDoneSVG } from "src/assets/images/post-reactions/well-done-emoji.svg";
import { ReactComponent as HahaSVG } from "src/assets/images/post-reactions/haha-emoji.svg";
import { removeReaction } from 'src/store/1-1-video-call/actions';
import { useReactionContext } from './reaction-context';

interface IChimeVideoCall {
  onCallHangup: () => void,
  overrideChimeConfig?: any
}

type Reaction = {
  id: string | number;
  reaction: any;
  color: string;
  x: number;
  y: number;
  scale: number;
  opacity: number;
  sender:string
  newId?: any;
};

interface FloatingReactionProps {
  reactions:any,
  attendee:any
}

interface ICreateRemoteVideoTileLayout {
  tileId: number;
  attendee: DetailRosterAttendeeType;
  isCallingFromLivePage?: boolean;
}

interface ICreateLocalVideoTileLayout {
  isVideoEnabled: boolean;
  attendee: DetailRosterAttendeeType;
  isCallingFromLivePage?: boolean;
}

export type DetailRosterAttendeeType = RosterAttendeeType & {
  details?: IUserProfile;
}

export const ChimeVideoCall = ({ onCallHangup, overrideChimeConfig }: IChimeVideoCall) => {
  const dispatch = useDispatch();
  
  const { sharingAttendeeId } = useContentShareState();
  const meetingManager = useMeetingManager();
  const [meetingStarted, setMeetingStarted] = useState(false);
  const { isVideoEnabled } = useLocalVideo();
  const { tiles, attendeeIdToTileId } = useRemoteVideoTileState();
  const { roster } = useRosterState();
  const chimeAttendees: RosterAttendeeType[] = useMemo(() => Object.values(roster), [roster]);
  const [attendees, setAttendees] = useState<DetailRosterAttendeeType[]>([]);
  const user = useCurrentUser();
  const [videoTiles, setVideoTiles] = useState<any[]>([]);
  const isCallingFromLivePage = window.location.pathname.endsWith('/live');
  const isScreenShared: boolean = !!sharingAttendeeId && sharingAttendeeId.trim() !== '';

  const chatVideoCall: IVideoCallState = useSelector(
    (state: IAppState) => state.chatVideoCall
  );
  const chimeInvitation = useSelector((state: IAppState) => overrideChimeConfig || state.chime.chimeInvitation);
  const connectToChime = async (config: any) => await new ChimeService()
    .loginToMeeting(config, meetingManager)
    .catch(err => console.error('Chime service failed with error', err));

    const FloatingReaction = ({ reactions,attendee }: FloatingReactionProps) => {
      const icons: { [key: string]: React.ComponentType<any> } = {
        likes: LikedSvg,
        hahas: HahaSVG,
        loves: LoveSVG,
        claps: WellDoneSVG,
        smiles: SmileSVG,
      };
        
      return (
        <>
{reactions?.map(({ id, reaction, newId, x, y, scale, opacity, sender }: Reaction) => {
  const Icon = icons[reaction] || icons?.thumbsUp;

  return attendee.externalUserId === sender ||  attendee?.externalUserId === id? (
    <div
      key={`${newId}-id`}
      id={`${newId}-id`}
      className="absolute transition-transform"
      style={{
        left: `${x}%`,
        bottom: `${y}%`,
        transform: `scale(${scale})`,
        opacity,
      }}
    >
      <Icon className="w-8 h-8 text-sky-500" />
    </div>
  ) : null;
})}


        </>
      );
    };
    

  const ReactionOverlay = React.memo(({ attendee }: { attendee: any }) => {
    const {reactions} = useReactionContext();
   
    return (
      <div className="relative h-full z-50 w-[90px]">
        <FloatingReaction
          reactions={reactions}
          attendee={attendee}
        />
      </div>
    );
  });

  const CreateRemoteVideoTileLayout = React.memo(({ tileId, attendee, isCallingFromLivePage }: ICreateRemoteVideoTileLayout) => {
    const {
      videoEnabled
    } = useAttendeeStatus(attendee.chimeAttendeeId);

    return <div className="relative w-full h-full flex flex-col overflow-hidden">
      {videoEnabled ? <RemoteVideo tileId={tileId} /> : <UserCard user={attendee.details!} isCallingFromLivePage={isCallingFromLivePage} />}
      <div className=' absolute right-20 bottom-0 z-[10000] w-full h-full flex justify-end'>
        <ReactionOverlay
          attendee={attendee}
        />
      </div>
      
      <div
        className={classNames("w-[351px] h-[35px] bg-sky-900 opacity-75 absolute bottom-0 text-left px-2.5 text-white text-xs", {
          '!w-full': isCallingFromLivePage
        })}
      >
        <div>{attendee.details?.fullName}</div>
        <div className='font-semibold'>{attendee.details?.jobTitle}</div>
      </div>
    </div>
  }, (prevProp, nextProp) => (prevProp.tileId === nextProp.tileId) && (prevProp.attendee?.details?.id === nextProp.attendee?.details?.id))

  const CreateLocalVideoTileLayout = React.memo(({ isVideoEnabled, attendee, isCallingFromLivePage }: ICreateLocalVideoTileLayout) => {

    return <div className="relative w-full h-full flex flex-col overflow-hidden">
      {isVideoEnabled ? <LocalVideo /> : <UserCard user={attendee.details!} isCallingFromLivePage={isCallingFromLivePage} />}
                       
      <div className=' absolute right-20 bottom-0 z-[10000] w-full h-full flex justify-end'>
        <ReactionOverlay
          attendee={attendee}
        />
      </div>


      <div
        className={classNames("flex flex-row w-[351px] h-[35px] bg-sky-900 opacity-75 absolute bottom-0 text-left px-2.5 text-white text-xs justify-between", {
          '!w-full': isCallingFromLivePage
        })}
      >
          <div className='flex justify-center items-center'>(Me)</div>
      </div>
    </div>
  }, (prevProp, nextProp) => (prevProp.isVideoEnabled === nextProp.isVideoEnabled) && (prevProp.attendee?.details?.id === nextProp.attendee?.details?.id))

  useEffect(() => {
    const videoTileComponents = attendees.map(attendee => {
      if ((attendee as any).externalUserId === user.id) {
        return <CreateLocalVideoTileLayout
          isVideoEnabled={isVideoEnabled}
          attendee={{ ...attendee }}
          isCallingFromLivePage={isCallingFromLivePage}
        />
          
      } else {
        return <CreateRemoteVideoTileLayout
          tileId={attendeeIdToTileId[attendee.chimeAttendeeId]}
          attendee={{ ...attendee }}
          isCallingFromLivePage={isCallingFromLivePage}
        />
      }
    })

    setVideoTiles(videoTileComponents);
  }, [attendees, tiles, isVideoEnabled])

  useEffect(() => {
    const fetchAttendeeDetails = async () => {
      const tempChimeAttendees: DetailRosterAttendeeType[] = Object.assign(chimeAttendees);
      for (let attendee of tempChimeAttendees) {
        const userService = new UserService();
        attendee.details = await userService.getUserProfile(attendee.externalUserId!).then(user => user.data).catch(()=>{ return undefined; });
      }
      return tempChimeAttendees.reverse();
    }

    fetchAttendeeDetails().then(setAttendees);
  }, [chimeAttendees])

  useEffect(() => {
    if (!!chimeInvitation?.callId && !meetingStarted) {
      connectToChime(chimeInvitation).then(() => setMeetingStarted(true));
    }
  }, [chimeInvitation, meetingStarted]);

  useEffect(() => {
    if (chatVideoCall?.senderPartyReactionInfo || chatVideoCall?.recieverPartyReactionInfo) {
      setTimeout(()=>{  dispatch(removeReaction())},2000)
    }
  }, [chatVideoCall]);

  useEffect(() => {
    return () => { if (meetingManager) meetingManager.leave(); }
  }, [])

  return (
    <div
      className={classNames("flex flex-row items-center justify-center w-full h-full p-2", {
        '!justify-start': isScreenShared && isCallingFromLivePage
      })}
    >
      <div
        className={classNames("", {
          'w-[calc(100%-210px)] h-full rounded-5': isScreenShared && isCallingFromLivePage
        })}
      >
        <ContentShare css={`{ height: ${isScreenShared && isCallingFromLivePage ? "100%" : "620px "}!important; width: ${isScreenShared && isCallingFromLivePage ? "100%" : "850px "} !important;}`} />
      </div>
      <div
        className={classNames("w-[375px]", {
          '!w-full !h-full': isCallingFromLivePage,
          '!w-[200px] absolute right-[8px] top-[8px]': isScreenShared && isCallingFromLivePage
        })}
      >
        <VideoCallLayout
          tiles={videoTiles}
          isCallingFromLivePage={isCallingFromLivePage}
        />
      </div>
      <div className="reaction-controls">
</div>
    </div>
  );
};
