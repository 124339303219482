/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useOnClickOutside } from 'usehooks-ts';
// import { useNavigate } from 'react-router-dom';

// import { unsetUser } from 'src/store/auth';
// import AlertModal from '../../../../components/alert-modal/alert-modal';
// import { PageTitle } from '../../../../components/pagetitle/pagetitle';
import RegistrationStepForm from 'src/mui/pages/event/reg-dialog-flow';
import { unsetUser } from 'src/store/auth';
import { resetUserEmail } from 'src/store/checkUserEmail';
import { UserSignUpActionTypes } from 'src/store/user/signup/types';
import { UpcomingEventActionTypes } from 'src/store/events/upcoming-event/types';
import { setRegistered } from 'src/store/event-website';
import { useSurveyContext } from 'src/mui/providers/GeneralContext';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { PageTitle } from 'src/components';
import LoginOrJoinForm from 'src/screens/loginorjoin/loginorjoin-form';
import LoginForm from 'src/screens/login/login-form';
import EventTickets from 'src/screens/event-landing-pre-event/event-tickets';
import { Button } from 'src/shared-components/V2';
import { IUserProfile } from '../../../../models/user-profile';
// import { Button } from '../../../../shared-components/V2';
import { IAppState } from '../../../../store';
// import { getUpcomingEvent } from '../../../../store/events/upcoming-event/actions';
import { getTickets } from '../../../../store/ticket';
import { useCurrentUser } from '../../../../utils/hooks';
// import EventTickets from '../../../event-landing-pre-event/event-tickets';
// import LoginForm from '../../../login/login-form';
import { CalendarList } from '../event-components/calendar-list';
// import FinalMessageContent from '../event-components/final-message-content';
import { GotoList } from '../event-components/goto-list';
import FinalMessageContent from '../event-components/final-message-content';
import EventDetails from '../event-signup/event-details';
import EventCompleteProfile from '../event-signup/event-completeprofile';
import EventSignup from '../event-signup/event-signup';
// import { EventCompleteProfile } from '../event-signup/event-completeprofile';
// import EventDetails from '../event-signup/event-details';
// import { resetUserEmail } from '../../../../store/checkUserEmail';
import { delAuthToken } from '../../../../utils';
// import { UserSignUpActionTypes } from '../../../../store/user/signup/types';
// import LoginOrJoinForm from '../../../loginorjoin/loginorjoin-form';
// import { EventSignup } from '../event-signup/event-signup';

const EventRegistrationButton = (props: { eventId: string, isBanner?: boolean, isSpectre?: boolean, isMenuRegister?: boolean, }) => {
  const ref = useRef(null);
  const [page, setPage] = useState(0);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [logoutUser, setLogoutUser] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { eventTickets } = useSelector((state: IAppState) => state);

  const { checkUserEmail } = useSelector((state: IAppState) => state);
  const currentStep = useSelector(
    (state: IAppState) => state.signupUser.currentStep
  );
  const { event, registered } = useSelector(
    (state: IAppState) => state.website
  );

  const dispatch = useDispatch();

  const { upcomingEvent, signupUser } = useSelector((state: IAppState) => state);

  const isRegistered = upcomingEvent?.value?.data?.attendeeStatus?.isRegistered;

  const loggedInUser = useCurrentUser();
  const [user, setUser] = useState<IUserProfile>();
  // const navigate = useNavigate();
  const changePage = (page: number) => {
    // page = 1 is login
    // page = 2 is signup & join
    // page = 3 is join
    setPage(page);
  };

  const [openTicketModal, setOpenTicketModal] = useState(false);
  const eventInvitationStatus = upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus;
  const { resetProtectedPasswordError } = useSurveyContext();

  useEffect(() => {
    setUser(loggedInUser);
  }, [loggedInUser, signupUser?.eventRegistration]);

  useEffect(() => {
    if (user) {
      // dispatch(getUpcomingEvent(props.eventId));
      dispatch(getTickets(props.eventId));
    }
  }, [props.eventId, dispatch, user]);

  const closeModal = () => {
    setPage(0);
    setShowModal(false);
  };

  const closeModalUsingButton = () => {
    setPage(0);
    setShowModal(false);
    // window.location.reload();
  };

  const resetForm = () => {
    sessionStorage.removeItem('userName');
    changePage(0);
    dispatch({
      type: UserSignUpActionTypes.GO_TO_FIRST_STEP,
    });
    dispatch(resetUserEmail());
    dispatch(setRegistered(null));
    dispatch(unsetUser());

    dispatch({
      type: UpcomingEventActionTypes.RESET_UPCOMING_EVENT_LOADING,
      payload: {}
    });

    dispatch({
      type: UserSignUpActionTypes.RESET_SIGNUP_USER,
      payload: {}
    });
  };

  const signoutClickHandler = () => {
    delAuthToken();
    resetForm();
    resetProtectedPasswordError();
    setLogoutUser(false);
    setUser(undefined);
    dispatch(unsetUser());
  };

  const getUserEmail = () => sessionStorage.getItem('userName') || '';
  const handleRegistration = () => {
    if (!registered) setShowModal(true);
    // if (!isRegistered) setShowModal(true);
    //
    if (!registered && event?.isTicketed && user) setOpenTicketModal(true);

    else signoutClickHandler();
    // if (user && registered) navigate(`/event/${event?.slug}/discussion`);
    // if (user && registered === false && event?.type === 'invitation' && eventInvitationStatus === 'accepted') navigate(`/event/${event?.slug}/discussion`);
  };

  // const handleLobby = () => {
  //   if (user && registered === false && event?.type === 'invitation') navigate(`/event/${event?.slug}/discussion`);
  // };

  const pageDisplay = () => {
    switch (page) {
      case 0: {
        if (user && currentStep === 1) {
          return (
            <FinalMessageContent eventId={props.eventId} />
          );
        }
        if (user && currentStep === 0 && registered === false && eventInvitationStatus !== 'pending') {
          return (
            <div
              ref={ref}
              className={classNames('flex flex-col justify-between bg-[#EDF2F5] rounded-[20px] py-[10px]', {
                'bg-[#2d2d2d]': props.isSpectre,
              })}
            >
              <div className="flex items-center justify-between w-[391px] width-auto custom-gap-x mt-[18px] mb-[19px] mx-auto">
                <div
                  className={classNames('text-[13px] font-bold', {
                    'text-white': props.isSpectre,
                    'text-[#3C3C3C]': !props.isSpectre,
                  })}
                >
                  Your profile
                </div>
                <svg className="h-line" xmlns="http:www.w3.org/2000/svg" width="141" height="1" viewBox="0 0 141 1">
                  <line id="Line_1398" data-name="Line 1398" x2="140" transform="translate(0.5 0.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1" />
                </svg>
                <div
                  className={classNames('text-[13px] font-[500]', {
                    'text-white': props.isSpectre,
                    'text-[#3C3C3C]': !props.isSpectre,
                  })}
                >
                  Confirmation
                </div>
              </div>
              <div className="text-center block border-b-1 border-[#d7d7d7] pb-2 mobile-p-20">
                <div
                  className={classNames('text-[16px] leading-[25px]', {
                    'text-white': props.isSpectre,
                    'text-[#3C3C3C]': !props.isSpectre,
                  })}
                >
                  Event details
                </div>
                <div
                  className={classNames('text-[13px] leading-[17px]', {
                    'text-white': props.isSpectre,
                    'text-[#3C3C3C]': !props.isSpectre,
                  })}
                >
                  This host needs the following details to complete your registration
                </div>
                {
                  (!!user?.email || !!signupUser.eventRegistration?.email)
                  && (
                    <div
                      className={classNames('text-xs pt-3', {
                        'text-white': props.isSpectre,
                        'text-black': !props.isSpectre,
                      })}
                    >
                      Logged in as
                      <a className="font-semibold">{user?.email ?? signupUser?.eventRegistration?.email}</a>.
                      <a
                        className={classNames('cursor-pointer hover:underline font-semibold', {
                          'text-white': props.isSpectre,
                          'text-[#0326E5]': !props.isSpectre,
                        })}
                        onClick={() => setLogoutUser(true)}
                      >Not you?
                      </a>
                    </div>
                  )
                }
              </div>
              <EventDetails
                eventId={props.eventId}
                changePage={changePage}
                backButtonVisible={false}
                onClose={() => closeModal()}
                isCloseButton
                isSpectre={props.isSpectre}
              />
            </div>
          );
        }
        if (!user) {
          return (
            <div
              className={classNames('flex flex-col justify-between bg-[#EDF2F5] h-[576px] rounded-[20px] pt-[10px] m-h-login', {
                'bg-[#2d2d2d]': props.isSpectre,
              })}
            >
              <div className="mt-[184px] mobile-mt-login">
                <PageTitle
                  titleText="Login or Join Now"
                  pageTitleClass={classNames({
                    '!text-white': props.isSpectre,
                  })}
                />
                <div className="flex justify-center mt-[15px]">
                  <LoginOrJoinForm from="event" isEventRegister isSpectre={props.isSpectre} setShowModal={setShowModal} changePage={changePage} />
                </div>
              </div>
            </div>
          );
        }
        break;
      }
      case 1: {
        return (
          <div className="flex flex-col justify-between bg-[#EDF2F5] rounded-[20px] py-[10px]">
            <div className="flex items-center justify-between w-[391px] mt-[18px] mb-[12px] mx-auto width-auto custom-gap-x">
              <div className="text-[13px] text-[#3C3C3C] font-bold">Your profile</div>
              <svg className="h-line" xmlns="http:www.w3.org/2000/svg" width="141" height="1" viewBox="0 0 141 1">
                <line id="Line_1398" data-name="Line 1398" x2="140" transform="translate(0.5 0.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1" />
              </svg>
              <div className="text-[13px] text-[#3C3C3C] font-[500]">Confirmation</div>
            </div>
            <div className="text-center w-full pb-[10px] border-b-[0.5px] border-[#CECECE]">
              {checkUserEmail?.value && <div className="text-xs pt-3">Logging in as <a className="font-semibold">{getUserEmail()}</a>. <a className="cursor-pointer hover:underline text-[#0326E5] font-semibold" onClick={resetForm}>Not you?</a></div>}
              <div className="w-[448px] mx-auto flex justify-between items-end">
                <Button
                  type="button"
                  text="Register"
                  disabled={!!checkUserEmail?.value}
                  size="large"
                  onClick={() => changePage(2)}
                  className={classNames('bg-transparent border border-[#C4C4C4] text-[#000000] text-sm1 font-semibold tracking-wide !rounded-10 h-[30px] w-[219px] px-0', {
                    'hover:bg-[#2e6eff] hover:text-white': !checkUserEmail?.value,
                  })}

                />
                <div className="">
                  <div className="text-[#172746] text-[12px] leading-[15px] font-normal ">
                    Already a member on Panelist?
                  </div>
                  <Button
                    type="button"
                    text="Login"
                    size="large"
                    className="bg-[#0049EF] text-white text-sm1 font-semibold hover:bg-[#285CB2] tracking-wide !rounded-10 h-[30px] w-[219px] px-0"
                  />
                </div>
              </div>
            </div>
            <div className="text-[#0B1221] text-center mt-[22px] text-[22px] leading-[33px] font-semibold mobile-mt-10">
              Log in
            </div>
            <div className="text-[13px] text-[#3C3C3C] leading-5 flex justify-center font-medium mt-[22px] mobile-p-x mobile-mt-10">
              The email you have entered is already registered on Panelist,
              enter your password to login
            </div>
            <div className="mx-auto w-full mobile-p-x">
              <LoginForm from="event" isEventRegister changePage={changePage} />
            </div>
          </div>
        );
      }
      case 2: {
        if (currentStep === 0) {
          return (
            <div
              className={classNames('flex flex-col justify-between bg-[#EDF2F5] rounded-[20px] py-[10px]', {
                'bg-[#EDF2F5]': !props.isSpectre,
                'bg-[#2d2d2d]': props.isSpectre,
              })}
            >
              <div className="flex items-center justify-between w-[391px] mt-[18px] mb-[12px] mx-auto width-auto custom-gap-x">
                <div
                  className={classNames('text-[13px]  font-bold', {
                    'text-[#3C3C3C]': !props.isSpectre,
                    'text-white': props.isSpectre,
                  })}
                >
                  Your profile
                </div>
                <svg className="h-line" xmlns="http:www.w3.org/2000/svg" width="141" height="1" viewBox="0 0 141 1">
                  <line id="Line_1398" data-name="Line 1398" x2="140" transform="translate(0.5 0.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1" />
                </svg>
                <div
                  className={classNames({
                    'text-white': props.isSpectre,
                  })}
                >Confirmation
                </div>
              </div>
              <div className="w-full pb-[10px] border-b-[0.5px] border-[#CECECE]">
                {/* <div className="w-[448px] mx-auto flex justify-between items-end mobile-grid-center">
                  <Button
                    type="button"
                    text="Register"
                    size="large"
                    className={classNames('bg-[#0049EF] text-white text-sm1 font-semibold hover:bg-[#285CB2] tracking-wide !rounded-10 h-[30px] w-[219px] px-0', {
                      'bg-[#0071e3]': props.isSpectre,
                    })}
                  />
                  <div className="mobile-mt-10">
                    <div
                      className={classNames('text-[#172746] text-[12px] leading-[15px] font-normal', {
                        'text-white': props.isSpectre,
                      })}
                    >
                      Already a member on Panelist?
                    </div>
                    <Button
                      type="button"
                      text="Login"
                      size="large"
                      className={classNames('bg-transparent border border-[#C4C4C4] text-sm1 font-semibold hover:bg-[#2e6eff] tracking-wide !rounded-10 h-[30px] w-[219px] px-0', {
                        'text-white': props.isSpectre,
                        'text-[#000000]': !props.isSpectre,
                      })}
                      onClick={resetForm}
                    />
                  </div>
                </div> */}
              </div>
              <div className="">
                <EventSignup isSpectre={props.isSpectre} page={page} changePage={resetForm} isSignUp />
              </div>
            </div>
          );
        }
        if (currentStep === 1 && registered === null) {
          return (
            <div
              ref={ref}
              className={classNames('flex flex-col justify-between bg-[#EDF2F5] rounded-[20px] py-[10px]', {
                'bg-[#2d2d2d]': props.isSpectre,
              })}
            >
              <div className="flex items-center justify-between w-[391px] width-auto custom-gap-x mt-[18px] mb-[19px] mx-auto">
                <div
                  className={classNames('text-[13px] font-bold', {
                    'text-white': props.isSpectre,
                    'text-[#3C3C3C]': !props.isSpectre,
                  })}
                >
                  Your profile
                </div>
                <svg className="h-line" xmlns="http:www.w3.org/2000/svg" width="141" height="1" viewBox="0 0 141 1">
                  <line id="Line_1398" data-name="Line 1398" x2="140" transform="translate(0.5 0.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1" />
                </svg>
                <div
                  className={classNames('text-[13px] font-[500]', {
                    'text-white': props.isSpectre,
                    'text-[#3C3C3C]': !props.isSpectre,
                  })}
                >
                  Confirmation
                </div>
              </div>
              <div className="text-center block border-b-1 border-[#d7d7d7] pb-2 mobile-p-20">
                <div
                  className={classNames('text-[16px] leading-[25px]', {
                    'text-white': props.isSpectre,
                    'text-[#3C3C3C]': !props.isSpectre,
                  })}
                >
                  Event details
                </div>
                <div
                  className={classNames('text-[13px] leading-[17px]', {
                    'text-white': props.isSpectre,
                    'text-[#3C3C3C]': !props.isSpectre,
                  })}
                >
                  This host needs the following details to complete your registration
                </div>
                {
                  (!!user?.email || !!signupUser.eventRegistration?.email)
                  && (
                    <div
                      className={classNames('text-xs pt-3', {
                        'text-white': props.isSpectre,
                        'text-black': !props.isSpectre,
                      })}
                    >
                      Logged in as
                      <a className="font-semibold">{user?.email ?? signupUser?.eventRegistration?.email}</a>.
                      <a
                        className={classNames('cursor-pointer hover:underline font-semibold', {
                          'text-white': props.isSpectre,
                          'text-[#0326E5]': !props.isSpectre,
                        })}
                        onClick={() => setLogoutUser(true)}
                      >Not you?
                      </a>
                    </div>
                  )
                }
              </div>
              <EventDetails
                eventId={props.eventId}
                changePage={changePage}
                backButtonVisible={false}
                onClose={() => closeModal()}
                isCloseButton
                isSpectre={props.isSpectre}
              />
            </div>
          );
        }
        if (currentStep === 2) {
          return <EventCompleteProfile isEventRegister page={page} changePage={changePage} />;
        }
        if (currentStep === 3) {
          return (
            <FinalMessageContent eventId={props.eventId} />
          );
        }
        break;
      }
      case 3: {
        if (currentStep === 0) {
          if (registered === null) {
            return (
              <div className="flex flex-col justify-between bg-[#EDF2F5] rounded-[20px] py-[10px]">
                <div className="flex items-center justify-between w-[391px] mt-[18px] mb-[12px] mx-auto width-auto custom-gap-x">
                  <div className="text-[13px] text-[#3C3C3C] font-bold">Your profile</div>
                  <svg className="h-line" xmlns="http:www.w3.org/2000/svg" width="141" height="1" viewBox="0 0 141 1">
                    <line id="Line_1398" data-name="Line 1398" x2="140" transform="translate(0.5 0.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1" />
                  </svg>
                  <div className="text-[13px] text-[#3C3C3C] font-[500]">Confirmation</div>
                </div>
                <div className="text-center w-full pb-[10px] border-b-[0.5px] border-[#CECECE]">
                  {checkUserEmail?.value && <div className="text-xs pt-3">Logging in as <a className="font-semibold">{getUserEmail()}</a>. <a className="cursor-pointer hover:underline text-[#0326E5] font-semibold" onClick={resetForm}>Not you?</a></div>}
                  {/* <div className="w-[448px] mx-auto flex justify-between items-end">
                    <Button
                      type="reset"
                      text="Register"
                      size="large"
                      className="bg-transparent border border-[#C4C4C4] text-[#000000] hover:text-white text-sm1 font-semibold hover:bg-[#2e6eff] tracking-wide !rounded-10 h-[30px] w-[219px] px-0"
                    />
                    <div className="">
                      <div className="text-[#172746] text-[12px] leading-[15px] font-normal ">
                        Already a member on Panelist?
                      </div>
                      <Button
                        type="submit"
                        text="Login"
                        size="large"
                        className="bg-[#0049EF] text-white text-sm1 font-semibold hover:bg-[#285CB2] tracking-wide !rounded-10 h-[30px] w-[219px] px-0"
                      />
                    </div>
                  </div> */}
                </div>
                <div className="text-[#0B1221] text-center mt-[22px] text-[22px] leading-[33px] font-semibold mobile-mt-10">
                  Log in
                </div>
                <div className="text-[13px] text-[#3C3C3C] leading-5 flex justify-center font-medium mt-[22px] mobile-p-x mobile-mt-10">
                  The email you have entered is already registered on Panelist,
                  enter your password to login
                </div>
                <div className="mx-auto w-full mobile-p-x">
                  <LoginForm from="event" isEventRegister changePage={changePage} />
                </div>
              </div>
            );
          }
          if (registered || eventInvitationStatus === 'pending') {
            closeModal();
          } else if (registered === false) {
            // navigate(`/event/${event?.slug}/discussion`);
            return (
              <div className="flex flex-col justify-between bg-[#EDF2F5] rounded-[20px] py-[10px]">
                <div className="flex items-center justify-between w-[391px] width-auto custom-gap-x mt-[18px] mb-[19px] mx-auto">
                  <div className="text-[13px] text-[#3C3C3C] font-bold">Your profile</div>
                  <svg className="h-line" xmlns="http:www.w3.org/2000/svg" width="141" height="1" viewBox="0 0 141 1">
                    <line id="Line_1398" data-name="Line 1398" x2="140" transform="translate(0.5 0.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1" />
                  </svg>
                  <div className="text-[13px] text-[#3C3C3C] font-[500]">Confirmation</div>
                </div>
                <div className="text-center block border-b-1 border-[#d7d7d7] pb-2 mobile-p-20">
                  <div className="text-[16px] leading-[25px] text-[#3C3C3C]">Event details</div>
                  <div className="text-[13px] leading-[17px] text-[#3C3C3C]">This host needs the following details to complete your registration </div>
                  {
                    (!!user?.email || !!signupUser.eventRegistration?.email)
                    && (<div className="text-xs pt-3">Logged in as <a className="font-semibold">{user?.email ?? signupUser?.eventRegistration?.email}</a>. <a className="cursor-pointer hover:underline text-[#0326E5] font-semibold" onClick={() => setLogoutUser(true)}>Not you?</a></div>)
                  }
                </div>
                <EventDetails
                  eventId={props.eventId}
                  changePage={changePage}
                  backButtonVisible={false}
                  onClose={() => closeModal()}
                  isCloseButton
                />
              </div>
            );
          }
        } else if (currentStep === 1) {
          return <FinalMessageContent eventId={props.eventId} />;
        }
        break;
      }
      default: {
        break;
      }
    }
    return null;
  };

  const handleClickOutside = () => {
    setShowModal(true);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <>
      {!isRegistered
        && props.isBanner
        && event?.type === 'invitation'
        && eventInvitationStatus === 'pending' && (
          <div className="flex items-center gap-[10px] mt-[9px]">
            <div className="flex-shrink-0">
              <GotoList
                fillColor={event?.colorStyle?.values?.colorButton || 'white'}
                titleClass="text-[13px] font-semibold text-white tracking-wide mr-[10px]"
                btnClassName="text-white bg-[#0049EF] rounded-10 h-[30px] px-[15px] flex items-center"
                title="Go to"
                dropdownClass="w-[113px] rounded-10"
                buttonTheme={{
                  background: event?.colorStyle?.values?.backgroundButton,
                  color: event?.colorStyle?.values?.colorButton,
                }}
                isInvitation
              />
            </div>
            <div className="flex-shrink-0">
              <CalendarList
                fillColor={event?.colorStyle?.values?.colorButton || 'white'}
                titleClass="text-[13px] font-semibold text-white tracking-wide mr-[10px]"
                btnClassName="text-white bg-[#0049EF] rounded-10 h-[30px] px-[15px] flex items-center"
                title="Registration under review"
                buttonTheme={{
                  background: event?.colorStyle?.values?.backgroundButton,
                  color: event?.colorStyle?.values?.colorButton,
                }}
                isInvitation
              />
            </div>
          </div>
      )}
      {isRegistered && props.isBanner ? (
        <div className="flex items-center gap-[10px] mt-[9px]">
          <div className="flex-shrink-0">
            <GotoList
              fillColor={event?.colorStyle?.values?.colorButton || 'white'}
              titleClass="text-[13px] font-semibold text-white tracking-wide mr-[10px]"
              btnClassName="text-white bg-[#0049EF] rounded-10 h-[30px] px-[15px] flex items-center"
              title="Go to"
              dropdownClass="w-[113px] rounded-10"
              buttonTheme={{
                background: event?.colorStyle?.values?.backgroundButton,
                color: event?.colorStyle?.values?.colorButton,
              }}
            />
          </div>
          <div className="flex-shrink-0">
            <CalendarList
              fillColor={event?.colorStyle?.values?.colorButton || 'white'}
              titleClass="text-[13px] font-semibold text-white tracking-wide mr-[10px]"
              btnClassName="text-white bg-[#0049EF] rounded-10 h-[30px] px-[15px] flex items-center"
              title="Add to calendar"
              buttonTheme={{
                background: event?.colorStyle?.values?.backgroundButton,
                color: event?.colorStyle?.values?.colorButton,
              }}
            />
          </div>
        </div>
      ) : (
      // user && !isRegistered && (event?.type === 'invitation' && eventInvitationStatus === 'pending') ? ('') : (

      // )

        <button
          type="button"
          className={classNames('bg-[#0049EF] md:px-[18px] px-[12px] h-[29px] rounded-[5px] text-[12px] leading-[18px] text-white tracking-wide mobile-nav-btn', {
            'text-[16px] h-[34px] rounded-[5px] !px-[45px] hover:opacity-90 mobile-nav-banner-btn': props.isBanner,
            'rounded-[6px] w-[220px] h-[36px] text-[16px] hover:bg-opacity-30 helvetica-neue-65-medium': props.isSpectre,
            'rounded-[5px] font-semibold': !props.isSpectre,
            '!h-[30px] !w-[170px]': props.isMenuRegister,
          })}
          onClick={() => handleRegistration()}
          style={{
            background: props?.isSpectre ? 'white' : event?.colorStyle?.values?.backgroundButton,
            color: props?.isSpectre ? 'black' : event?.colorStyle?.values?.colorButton,
          }}
        >
          {isRegistered ? 'Logout' : 'Register Now'}
        </button>

      )}
      {/* {registered === false
        && !props.isBanner
        && (event?.type === 'invitation' && eventInvitationStatus === 'accepted')
        && (
          <button
            type="button"
            className="bg-[#0326E5] md:px-[18px] px-[12px] h-[29px] rounded-[15px] text-[12px] leading-[18px] text-white tracking-wide"
            onClick={() => handleLobby()}
            style={{
              background: event?.colorStyle?.values?.backgroundButton,
              color: event?.colorStyle?.values?.colorButton,
            }}
          >
            Event Lobby1
          </button>
        )} */}

      {showModal && !openTicketModal && (
        <RegistrationStepForm
          setUser={setUser}
          eventId={event?.id!}
          user={user}
          open={showModal}
          registered={registered!}
          eventInvitationStatus={eventInvitationStatus!}
          eventType={event?.type!}
          setOpenForm={() => setShowModal(false)}
        />
      )}
      <AlertModal
        isOpen={false}
        bgColor="#EDF2F5"
        className={classNames('md:w-auto mt-10 overflow-auto w-576 py-0 mobile-modal-position', {
          '!w-[858px] h-[576px] signUpScroll overflow-visible': !event?.isTicketed || !user,
          '!m-0 max-h-[calc(100vh-30px)]': !event?.isTicketed && user && currentStep === 0 && registered === false && eventInvitationStatus !== 'pending',
          'absolute top-[130px] !mt-0 max-h-[calc(100vh-214px)] lg:!w-[48vw] sm:!w-[80vw] !w-[80vw]':
            !event?.isTicketed && ((currentStep === 0 && eventInvitationStatus === undefined && page === 2)
              || (currentStep === 0 && eventInvitationStatus === null && page === 0 && registered === false && user)
              || (currentStep === 1 && eventInvitationStatus === undefined && page === 2 && registered === null)),
          '!w-[90vw] !bg-[#2d2d2d]': props.isSpectre && !event?.isTicketed && ((currentStep === 0 && eventInvitationStatus === undefined && page === 2)
            || (currentStep === 0 && eventInvitationStatus === null && page === 0 && registered === false && user)
            || (currentStep === 1 && eventInvitationStatus === undefined && page === 2 && registered === null))
        })}
        onClose={() => closeModalUsingButton()}
        disableClose
      >

        {(!event?.isTicketed || !user) && pageDisplay()}

        {event?.isTicketed && openTicketModal && (
          <EventTickets
            eventId={props.eventId}
            eventTitle={event?.name}
            eventDate={event?.startTime}
            tickets={eventTickets?.value}
            cover={event.cover!}
            logo={event.logo!}
            location={event?.location!}
            onSubmit={() => { }}
            onCancel={() => { setOpenTicketModal(false); setShowModal(false); }}
          />
        )}
      </AlertModal>
      {/* {logoutUser
        && (
          <AlertModal className=" w-[520px] bg-[#EDF2F5] rounded-20 !py-[18px]" isCloseIcon closeIconClass="right-[8px] top-[15px]" isOpen={logoutUser} onClose={() => setLogoutUser(false)}>
            <div className="flex flex-col justify-center items-center">
              <div className="text-[16px] leading-[24px] font-medium  mb-[16px] text-[#0B1221]">Sign out?</div>
              <div className="text-[16px] leading-[24px] font-normal  mb-[18px] text-[#0B1221] text-center">Are you sure you want to logout and register as a new user?</div>
              <div className="flex items-center justify-center gap-2">
                <Button type="button" text="Cancel" className="text-sm1 text-blue-2 font-semibold hover:underline w-24" onClick={() => setLogoutUser(false)} />
                <a className="rounded-10 inline-block text-sm font-[600] w-110 mr-[24px] bg-[#0049EF] text-white text-sm font-semibold hover:bg-blue-3 h-8 flex justify-center items-center" onClick={signoutClickHandler}>Sign out</a>
              </div>
            </div>
          </AlertModal>
        )} */}
    </>
  );
};

export default EventRegistrationButton;
