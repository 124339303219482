import DownloadIcon from '../../../../../../assets/images/download.svg';
import { IExpoHandouts } from '../../../../../../models/events-live/expo';

interface IHangoutsAndExitBoothLayout {
  handouts:IExpoHandouts[],
  exitBoothHandler: ()=>void
}

export const HangoutsAndExitBoothLayout = ({ handouts, exitBoothHandler }: IHangoutsAndExitBoothLayout) => (
  <div className="flex flex-row">
    {/* Handouts */}
    <div className="text-left w-full ml-4">
      {
          handouts && !!handouts.length && (
            <>
              <span className="text-white text-lg18 font-normal mb-2 block">HANDOUTS:</span>
              <div className="flex justify-between">
                <div className="flex flex-wrap items-center gap-4">
                  {
                    handouts?.map((handout: any, index: number) => (
                      <a
                        className="bg-white w-fit rounded-lg px-4 py-1 flex items-center"
                        style={{ width: 'fit-content' }}
                        key={`booth-handout-${index}`}
                        href={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${handout.url}`}
                        target="_blank"
                        download
                        rel="noreferrer"
                      >
                        <span
                          className="whitespace-nowrap overflow-hidden text-ellipsis"
                          style={{ maxWidth: 132, textOverflow: 'ellipsis' }}
                        >
                          {handout.name}
                        </span>
                        <img className="ml-4" src={DownloadIcon} alt="download" />
                      </a>
                    ))
                  }
                </div>
              </div>
            </>
          )
        }
    </div>

    {/* Exit booth */}
    <div className="justify-items-end flex-none mt-10 mr-2">
      <button
        type="button"
        className="bg-yellow-300 w-fit px-4 py-1 rounded-xl"
        style={{ width: 'fit-content' }}
        onClick={exitBoothHandler}
      >
        Exit booth
      </button>
    </div>
  </div>
);
