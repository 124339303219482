import createplatform from '../../../assets/images/virtual/create-platform.png';
import createplatform2 from '../../../assets/images/virtual/create-platform@2x.png';

export const CreateEventPlatform = () => (
  <div className="bg-gray-3">
    <div className="container mx-auto">
      <div className="block lg:flex justify-center">
        <div className="block lg:flex justify-between">
          <div className="flex justify-center -ml-5">
            <img
              className=""
              srcSet={`${createplatform} 300w, ${createplatform2} 1000w`}
              sizes="(max-width: 883px) 30vw, (max-width: 883px) 30vw, 820px"
              alt=""
              src={createplatform2}
            />
          </div>
          <div className="ml-20 xl:-ml-20 pl-1 xl:mt-12">
            <div className="block lg:flex flex-col text-left w-4/5 mt-4">
              <p className="lg:text-xl3 font-extralight text-dark-blue">
                Create your event platform and event website within minutes!
              </p>

              <p className="text-md text-gray-1 font-extralight mt-3">
                Easily plan and map out everything you need for a successful event:
              </p>
              <ul className="list-disc font-extralight text-md text-gray-1 mt-1 leading-none ml-6">
                <li>Event agenda</li>
                <li>Breakout rooms</li>
                <li>Assign speaking slots</li>
                <li>Sponsor and attendee interactions</li>
                <li>Q&amp;A</li>
                <li>Networking breaks</li>
                <li>Content sharing</li>
                <li>Expo</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CreateEventPlatform;
