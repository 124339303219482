import bulb from '../../../assets/images/compliance/bulb-icon.png';

const TableContentThree = () => (
  <>
    <hr className="my-10" />
    <div id="account-membership" className="mt-7 text-gray-1 text-lg5">
      3. Accounts and membership
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        We’re glad you’ve made an account with us. By doing so, it is your responsibility to
        ensure it is secure, and that all activities on the account comply with our Community
        Guidelines. You agree to use a strong password, and not to transfer any part of the
        account.
      </div>
    </div>
    <div className="mt-3">
      a. If you create an account on the Platform, you are responsible for maintaining the
      security of your account and you are fully responsible for all activities that occur under
      the account and any other actions taken in connection with it. Providing false contact
      information of any kind may result in the termination of your account.
    </div>
    <div className="mt-3">
      b. You agree to: (1) use a strong password and keep it confidential; (2) not transfer any
      part of your account (e.g., connections) and (3) follow the law and our Community
      Guidelines. You are responsible for anything that happens through your account unless you
      close it or report misuse.
    </div>
    <div className="mt-3">
      c. You must immediately notify us of any unauthorized uses of your account or any other
      breaches of security. We will not be liable for any acts or omissions by you, including
      any damages of any kind incurred as a result of such acts or omissions.
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        To avoiding breaching these Terms, you must immediately notify us of unauthorised access
        or use of your account.
      </div>
    </div>
    <div className="mt-3">
      d. We may suspend, disable, or delete your account (or any part thereof) if we determine
      that you have violated any provision of these Terms, any breach of Community Guidelines, or
      that your conduct or content would tend to damage Panelist’s reputation and goodwill.
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        We can suspend, disable or delete your account, where we believe you have breached our
        Terms, Community Guidelines or damaged our reputation.
        {' '}
      </div>
    </div>
    <div className="mt-3">
      e. If we delete your account for the foregoing reasons, you may not re-register for our
      Services. We may block your email address and Internet protocol address to prevent further
      registration.
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        Where your account has been deleted, you won’t be allowed to create another one.
      </div>
    </div>

  </>
);

export default TableContentThree;
