import { Reducer } from 'redux';

import { RecommendedEventsState } from '../../../models/RecommendedEventsState';
import { RecommendedEventsActions } from './actions';
import { RecommendedEventsActionTypes } from './types';

const initialState: RecommendedEventsState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const RecommendedEventsReducer: Reducer<RecommendedEventsState, RecommendedEventsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RecommendedEventsActionTypes.RECOMMENDED_EVENTS_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case RecommendedEventsActionTypes.RECOMMENDED_EVENTS_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case RecommendedEventsActionTypes.RECOMMENDED_EVENTS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
