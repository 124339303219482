import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { IPostSpotLight } from "../../models/post/IPostSpotlight";
import { Loader } from "../../shared-components/Loader";
import { NoSpotlightEvents } from "./no-spotlight-events";
import SpotlightCard from "./spotlight-card";

interface ISpotlightProps {
    spotlightLists: IPostSpotLight[];
    fetchSpotlight: Function;
    fetchItems: any;
    hasMoreItems: any;
    gridSize?: 1 | 2 | 3 | 4;
    isOwnSpotlight?: boolean;
    getSpotlight: Function;
    onCreateSpotlight?: Function;
    isDisableCreate?: boolean;
}

export const Spotlight = ({ spotlightLists, fetchSpotlight, fetchItems, hasMoreItems, gridSize = 3, isOwnSpotlight, getSpotlight, onCreateSpotlight, isDisableCreate }: ISpotlightProps) => {
    
    return (
        <>
        {spotlightLists.length === 0 ? (
              <NoSpotlightEvents isButton={!isDisableCreate} onClickModal={onCreateSpotlight} createButtonClass="min-w-[286px] mt-[25px]" />
            ) : (
                <div className="overflow-visible no-scrollbar">
                <InfiniteScroll
                    pageStart={0}
                    loadMore={fetchItems}
                    hasMore={hasMoreItems}
                    loader={<Loader className="pt-5" key="loader" />}
                >
                    <div className={`grid grid-cols-${gridSize} gap-4`}>
                        {spotlightLists.map((item: IPostSpotLight, index: number) => (
                            <div
                                className={classNames('xl:w-[303px] xl:h-[297px]',{
                                    'row-span-2 col-span-2 xl:w-[622px] xl:h-[610px]': (index % (gridSize === 3? 6 : 5) === 0 && index > 1),
                                })}
                                key={`spotlight-${item.id}-${index}`}
                            >
                                <SpotlightCard
                                    indexSpotlight={index}
                                    id={item.id}
                                    content={item.content}
                                    createdAt={item.createdAt}
                                    format={item.format}
                                    source={item?.source}
                                    onClick={fetchSpotlight}
                                    gridSize={gridSize}
                                    post={item}
                                    isOwnSpotlight={isOwnSpotlight}
                                    getSpotlight={getSpotlight}
                                />
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
            )} 
        </>
    );
};
