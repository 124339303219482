interface ArrowRightIconProps {
    fillColor?: string;
  }
  
  export const ArrowRightIcon = ({ fillColor }: ArrowRightIconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.697" height="24.199" viewBox="0 0 11.697 24.199">
  <g id="Group_21426" data-name="Group 21426" transform="translate(-2023.4 -3380.4)">
    <line id="Line_1618" data-name="Line 1618" x2="7.498" y2="10" transform="translate(2025.5 3382.5)" fill="none" stroke={fillColor || '#ffffff'} stroke-linecap="round" stroke-width="3"/>
    <line id="Line_1619" data-name="Line 1619" y1="10" x2="7.498" transform="translate(2025.5 3392.5)" fill="none" stroke={fillColor || '#ffffff'} stroke-linecap="round" stroke-width="3"/>
  </g>
</svg>
  );



  
