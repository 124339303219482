import React, { FC, useEffect, useState } from 'react';
import { EventsService } from '../../../../services/events';
import { IEvent } from '../../../../models/event/event';
import { CardEventsInterested } from './CardEventsInterested';
import { IUserProfile } from '../../../../models/user-profile';
import { EVENTS_PER_ROW } from '../../../../constants';
import useShowMoreCards from '../../../../utils/hooks/useShowMoreCards';

interface CardEventInterestedContainerProps {
  className: string;
  title: string;
  user: IUserProfile;
}
export const CardEventInterestedContainer: FC<CardEventInterestedContainerProps> = ({
  className,
  title,
  user
}: CardEventInterestedContainerProps) => {
  const [events, setEvents] = useState<IEvent[]>([]);
  const { moreCards, displayBtn, handleSeeMore } = useShowMoreCards(EVENTS_PER_ROW, events);

  useEffect(() => {
    new EventsService().getEventsUpcpming().then(res => {
      setEvents(res.data);
    });
  }, []);

  const handleSeeMoreButton = () => {
    handleSeeMore();
  };

  if (!events?.length) return null;

  return (
    <div className="flex flex-col">
      <div className="mt-4 mb-5 ml-5">
        <div className="text-[15px] leading-[18px] font-normal text-[#172746]">{title}</div>
      </div>
      <div className={`pl-[19px] pr-[19px] xl:pr-0 flex flex-row pb-4 last:m-0 xl:gap-x-0 gap-x-[14px] flex-wrap ${className}`}>
        {moreCards
          && moreCards.map((event, index) => (
            <div key={`card-${event.id}`} className="xl:mr-2.5 mb-[14px] xl:w-[218px] lg:w-[calc(33.333%-9.4px)] md:w-[calc(50%-9.4px)] w-full">
              <CardEventsInterested
                key={`card-event-interested-${index}`}
                coverUrl={`${event.cover}`}
                avatarUrl={`${event?.company?.logo ? event?.company?.logo : ''}`}
                title={event.name}
                startTime={event.startTime}
                endTime={event.endTime}
                subTitle=""
                regions={`${event.eventRegions.map((reg: any) => reg.name).join(', ')} • ${event?.company.name}`}
                eventType={event.format}
                eventLink={`/event/${event.slug}/discussion`}
                event={event}
                user={user}
                isDropdown="true"
              />
            </div>
          ))}
        {displayBtn && (
          <button
            type="button"
            onClick={handleSeeMoreButton}
            className="bg-[#EDF2F5] hover:bg-[#DFE6EA] h-[31px] w-[904px] rounded-[5px] text-[#203C6E] text-sm1 font-semibold transition-all"
          >
            Show more
          </button>
        )}
      </div>
    </div>
  );
};

export default CardEventInterestedContainer;
