import classNames from 'classnames';
import { useState } from 'react';

import deleteIcon from '../../../assets/images/delete-bin-icon.svg';
import EditIcon from '../../../assets/images/Icon-edit.svg';
import MenuReport from '../../../assets/images/event-landing-pre-event/report-alert-icon.svg';
import CommentEmoji from '../../../assets/images/post-reactions/comments-emoji.svg';
import hahaEmoji from '../../../assets/images/post-reactions/haha-emoji.svg';
import likeEmoji from '../../../assets/images/post-reactions/like-emoji.svg';
import loveEmoji from '../../../assets/images/post-reactions/love-emoji.svg';
import smileEmoji from '../../../assets/images/post-reactions/smile-emoji.svg';
import clapEmoji from '../../../assets/images/post-reactions/well-done-emoji.svg';
import { IFlagDTO } from '../../../models/flag/flag';
import { FlagService } from '../../../services/Flag';
import { Typography } from '../../../shared-components';
import { Avatar } from '../../../shared-components/V2';
import { Logo } from '../../../shared-components/V2/Logo';
import { imageUrlPrefix } from '../../../utils';
import { getDistanceBetweenTwoDateTime } from '../../../utils/datetime';
import {
  useCurrentUser,
  useUserDetails,
} from '../../../utils/hooks';
import { ParseContent } from '../../../utils/parse-html';
import AlertModal from '../../alert-modal/alert-modal';
import {
  DropDownMenu,
  IDropdownMenuItem,
} from '../../dropdown-menu';
import ActionsReact from '../../reactions-icons/components/actions';
import Report from '../../report';
import { DeletePost } from '../delete-post';
import { parseNotficationString } from 'src/utils/notification';
import { useDispatch } from 'react-redux';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

interface ICommentItemProps {
  commentId: string;
  avatar: string;
  username: string;
  createdAt: string;
  jobTitle: string;
  companyName: string;
  content: string;
  image?: string;
  totalClaps: number;
  totalHahas: number;
  totalLikes: number;
  totalLoves: number;
  totalSmiles: number;
  totalComments: number;
  likes: any[];
  commentedAsUser: any;
  commentedAsCompany: any;
  onCommentLike?: Function;
  onRepliesClick?: () => void;
  onCommentClick?: () => void;
  onDeleteComment: Function;
  isSubComment?: boolean;
  isLivePage?: boolean;
}

const CommentItem = ({
  commentId,
  avatar,
  username,
  createdAt,
  jobTitle,
  companyName,
  content,
  image,
  totalClaps,
  totalHahas,
  totalLikes,
  totalLoves,
  totalSmiles,
  totalComments,
  likes,
  commentedAsUser,
  commentedAsCompany,
  onCommentLike,
  onRepliesClick,
  onCommentClick,
  onDeleteComment,
  isSubComment,
  isLivePage
}: ICommentItemProps) => {
  const dispatch = useDispatch();
  const colors = getBrandingColors();
  const [toggleDeleteConfirmation, setToggleDeleteConfirmation] =
    useState<boolean>(false);
  const [toggleReportComment, setToggleReportComment] =
    useState<boolean>(false);

  const onCloseModal = () => {
    toggleDeleteConfirmation && setToggleDeleteConfirmation(false);
    toggleReportComment && setToggleReportComment(false);
  };

  const commentOptions: IDropdownMenuItem[] = [
    {
      id: "delete",
      text: "Delete",
      icon: deleteIcon,
      onClickHandler: () => setToggleDeleteConfirmation(true),
    },
    {
      id: "edit",
      type: "icon",
      text: "Edit",
      icon: EditIcon,
      onClickHandler: () => onCommentClick && onCommentClick(),
    },
  ];

  const othersCommentOptions: IDropdownMenuItem[] = [
    {
      id: "report",
      text: "Report",
      icon: MenuReport,
      onClickHandler: () => setToggleReportComment(true),
    },
  ];

  const currentUser = useCurrentUser();
  const { companyAdmin } = useUserDetails(currentUser);

  let isOwnCompany = companyAdmin?.some(
    (company) => company?.id === commentedAsCompany?.id
  );

  let isOwnComment =
    (commentedAsUser && currentUser.id === commentedAsUser.id) || isOwnCompany;

  return (
    <>
      {toggleDeleteConfirmation && (
        <AlertModal isCloseIcon closeIconClass='right-[7px] top-[14px]' className="w-[374px] bg-[#EDF2F5]" isOpen={toggleDeleteConfirmation} onClose={onCloseModal}>
          <DeletePost
            postId={commentId}
            title="Delete this comment?"
            message={"Are you sure you want to delete this comment?"}
            onDelete={async (id: string) => {
              onDeleteComment(id);
              onCloseModal();
            }}
            onClose={onCloseModal}
          />
        </AlertModal>
      )}
      {toggleReportComment && (
        <AlertModal
          className="w-576"
          isOpen={toggleReportComment}
          onClose={onCloseModal}
          closeIconClass=" right-[18px] top-[15px] px-0"
          isCloseIcon
        >
          <Report
            id={commentId}
            type="comment"
            onReport={async (id: string, type: string, reason: string) => {
              const flagService = new FlagService();
              const flagItem: IFlagDTO = {
                flaggableId: id,
                flaggableType: type,
                reason,
              };
              await flagService.flagItem(flagItem);
            }}
            onCloseReport={onCloseModal}
          />
        </AlertModal>
      )}
      <div className="flex flex-row">
        <div
          className={classNames('w-[38px] mr-3', {
            '!w-[30px] !mr-[4px]': isLivePage,
          })}
        >
          {commentedAsUser && (
            <Avatar
              size={isLivePage ? "XSMALL" : "SMALL"}
              src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${avatar}`}
              className={classNames('!w-[38px] !h-[38px]', {
                '!w-[30px] !h-[30px]': isLivePage,
              })}

            />
          )}
          {commentedAsCompany && (
            <Logo
              className={classNames('w-[38px] h-[38px] object-contain rounded-xl', {
                'w-[30px] h-[30px] !rounded-[12px]': isLivePage,
              })}
              src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${commentedAsCompany.logo}`}
            />
          )}
        </div>
        <div className="flex flex-col w-full">
          <div style={{ backgroundColor: isLivePage ? colors?.Secondary : undefined, padding: isLivePage ? "9px" : undefined }} className="flex flex-col rounded-[12px] bg-[#EDF2F5] px-4 py-2.5">
            <div
              className={classNames('flex items-baseline flex-row justify-between sm:h-5 mb-[1px]', {
                '!h-[19px] !mt-[-5px]': isLivePage,
              })}
            >
              <div style={{ color: isLivePage ? colors?.Text : undefined, fontSize: isLivePage ? "10px" : undefined, lineHeight: isLivePage ? '14px' : undefined, height: isLivePage ? '14px' : undefined }} className="text-h2 text-[#3C3C3C] font-medium leading-5 sm:h-[20px] cursor-pointer hover:underline">
                {commentedAsUser && username}
                {commentedAsCompany && commentedAsCompany.name}
              </div>
              <div className="flex flex-row items-center sm:h-[27px] -mr-[10px]">
                <div style={{ color: isLivePage ? colors?.Text : undefined, fontSize: isLivePage ? "10px" : undefined, lineHeight: isLivePage ? '14px' : undefined }} className="text-[12px] text-[#18161C] font-normal leading-[16px] -mr-[3px] -mt-[4px]">
                  {getDistanceBetweenTwoDateTime(
                    new Date(createdAt),
                    new Date()
                  )}
                </div>
                <div className="flex">
                  <DropDownMenu
                    options={
                      isOwnComment ? commentOptions : othersCommentOptions
                    }
                    containerClasses="flex justify-center items-center relative"
                    menuCardClasses="absolute z-40 top-8 opacity-100 bg-white -ml-28 shadow-md rounded-lg p-1 cursor-pointer"
                    titleAndSubtitleGroup="flex justify-left items-left hover:bg-gray-1 leading-6 text-left p-1 text-sm5 cursor-pointer rounded-5"
                    buttonStyles={classNames('flex items center w-30 h-30 ml-2', {
                      '!w-[20px] !h-[20px] !ml-[10px] -mt-[15px] !mr-[5px]': isLivePage,
                    })}
                    listTextStyles="block pl-2"
                    listIconStyles="p-1"
                    textAndImageContainer="flex justify-left items-center w-24"
                    fillColor="WHITE"
                  />
                </div>
              </div>
            </div>
            <div 
              className={classNames('text-[12px] text-[#18161C] font-light leading-[17px] mb-[3px]', {
                'mb-[8px]': isSubComment,
              })}
              style={{ color: isLivePage ? colors?.Text : undefined, fontSize: isLivePage ? "9px" : undefined, lineHeight: isLivePage ? '12px' : undefined }}
            >
              {commentedAsUser && `${jobTitle} at `} <span className="cursor-pointer hover:underline">{companyName}</span>
              {commentedAsCompany &&
                `${commentedAsCompany.industry?.name!} • ${commentedAsCompany
                  .companySize?.name!} employees`}
            </div>
            <div style={{ color: isLivePage ? colors?.Text : undefined, fontSize: isLivePage ? "11px" : undefined, lineHeight: isLivePage ? '16px' : undefined }} className="text-sm leading-[17px] text-[#3C3C3C] font-normal bg-blend-luminosity pb-[0px]">
              {/* <ParseContent content={content} /> */}
              {
                parseNotficationString(content, null, true, false, dispatch)
              }
            </div>
            {image && (
              <div className="mt-1">
                <img src={`${imageUrlPrefix}/${image}`} width="140px" alt="" />
              </div>
            )}
          </div>
          <div className="flex flex-row justify-between relative">
            <div className="flex flex-row mt-[1px]">
              <div
                className={classNames('text-h3 font-[500]  leading-[14px]', {
                  ' !leading-[9px]': isLivePage,
                  ' !text-[#172746]': !isLivePage,
                })}
              >
                <ActionsReact
                  className={classNames('-mt-16 text-h3 font-[500] leading-[14px]', {
                    'text-[#172746]': !isLivePage,
                  })}
                  textClasses={classNames('text-h3 font-[500] text-[#172746] leading-[14px] ml-[17px]', {
                    '!text-[9px] !leading-[9px]': isLivePage,
                  })}
                  text={"Like"}
                  onClick={(like: string) => onCommentLike!(like, commentId)}
                  defaultSelected={likes[0]?.format}
                  isLivePage={isLivePage}
                />
              </div>
              <div style={{ color: isLivePage ? colors?.Text : undefined, fontSize: isLivePage ? "9px" : undefined, lineHeight: isLivePage ? '17px' : undefined }} className="text-[20px] font-[400] text-[#172746] leading-[14px] ml-1 -mt-[5px]">.</div>
              <div
                className="text-h3 font-[500] text-[#172746] leading-[14px] ml-1 cursor-pointer"
                onClick={onRepliesClick}
                style={{ color: isLivePage ? colors?.Text : undefined, fontSize: isLivePage ? "9px" : undefined, lineHeight: isLivePage ? '12px' : undefined }}
              >
                Reply
              </div>
              {totalComments > 0 && (
                <>
                  <div style={{ color: isLivePage ? colors?.Text : undefined, fontSize: isLivePage ? "9px" : undefined, lineHeight: isLivePage ? '17px' : undefined }} className="text-[20px] font-[400] text-[#172746] leading-[14px] ml-1 -mt-[5px]">.</div>
                  <div
                    className="text-h3 font-[500] text-[#172746] leading-[14px] ml-1 cursor-pointer"
                    onClick={onRepliesClick}
                    style={{ color: isLivePage ? colors?.Text : undefined, fontSize: isLivePage ? "9px" : undefined, lineHeight: isLivePage ? '12px' : undefined }}
                  >
                    {totalComments} replies
                  </div>
                </>
              )}
            </div>

            <div className="flex flex-row items-center -mt-2 bg-white rounded-[40px] shadow absolute right-[3px] bottom-[2px]">
              <div className="flex -space-x-1">
                {totalClaps > 0 && <img className="" src={clapEmoji} alt="" />}
                {totalHahas > 0 && <img className="" src={hahaEmoji} alt="" />}
                {totalLikes > 0 && <img className="" src={likeEmoji} alt="" />}
                {totalLoves > 0 && <img className="" src={loveEmoji} alt="" />}
                {totalSmiles > 0 && (
                  <img className="" src={smileEmoji} alt="" />
                )}
                {totalComments > 0 && (
                  <img className="" src={CommentEmoji} alt="" />
                )}
              </div>
              {!!totalLikes && (
                <Typography
                  classes="mx-2 mt-0 !text-[14px] !font-normal !text-[#3C3C3C]"
                  variant="H3"
                  text={`${totalLikes}`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default CommentItem;
