/* eslint-disable no-nested-ternary */
import * as Yup from "yup";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  Stack,
  Button,
  Divider,
  MenuItem,
  CircularProgress,
  Typography,
  Box,
  FormControl,
  FormLabel,
  Dialog,
} from "@mui/material";
import { useForm } from "react-hook-form";
import FormProvider from "src/mui/components/hook-form/form-provider";
import {
  PCheckbox,
  PCityCountrySelect,
  PCompanySelect,
  PJobTitleSelectLiveSearch,
  PMultiCheckbox,
  RHFSelect,
  RHFTextField,
} from "src/mui/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "./Card.css";
import { useSurveyContext } from "src/mui/providers/GeneralContext";
import {
  RegistrationForm,
  RegistrationFieldType,
  ConditionType,
} from "src/mui/types/registration";
import PIndustrySelect from "src/mui/components/hook-form/PIndustrySelect";
import { eventRegistrationStep2 } from "src/store/user/signup/actions";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "src/store";
import useStyling from "src/mui/components/hook-form/useStyling";
import { emailRegex, forbiddenEmailDomains } from "../../survey/constants";
import PhoneInput from "react-phone-input-2";
import classNames from "classnames";
import { getFieldLabel } from "./ConstantsandTypes";
import { Options } from "src/mui/types/survey";
import PCompanySelectLiveSearch from "src/mui/components/hook-form/PCompanySelectLiveSearch";
import LocationDropdown from "src/mui/components/hook-form/locationDropdown";
import TicketCard from "src/shared-components/V2/TicketCard/ticket-card";
import { ISelectedTicket } from "src/models/tickets";
import {
  getTickets,
  removeTicketFromCart,
  selectTicket,
  updateTickets,
} from "src/store/ticket";
import TicketCardRego from "src/shared-components/V2/TicketCard/ticket-card-rego";
import { TicketService } from "src/services/Tickets";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "src/screens/event-landing-pre-event/event-tickets/checkout-form";
import Cleave from "cleave.js/react";
import { isCreditCard } from "validator";
import PJobFunctionSelect from "src/mui/components/hook-form/PJobFunctionSelect";
import PHeadCountSelect from "src/screens/company/company-profile/PHeadCountSelect";

type Props = {
  registrationValue: any;
  openSurveyForm: (value: boolean) => void;
  setOpenForm: (value: boolean) => void;
  setCurrentRegistrationStep: (value: number) => void;
  user?: any;
  getCurrentRegistrationPage: (value: any, allPagesIds?: any) => void;
  currentRegistrationStep: number;
  registered: boolean;
  setRequiredRegistrationQuestionsFilled: (value: boolean) => void;
  setIsSuveryorRegSkipable: (value: boolean) => void;
  onLogout?: VoidFunction;
};

interface Page {
  id: string;
  eventRegistrationFields: RegistrationForm[];
}

interface Condition {
  and?: boolean;
  or?: boolean;
}

interface Output {
  [key: string]: {
    type: string;
    obj?: { [key: string]: RegistrationForm[] };
    tabs?: string[];
    question?: RegistrationForm;
  }[];
}
let isSubmitted = false;

function convertToDateFormat(input: string): Date | null {
  const month = parseInt(input?.substring(0, 2), 10);
  const year = parseInt(input?.substring(2), 10) + 2000; // Assuming 00 represents 2000
  if (isNaN(month) || isNaN(year) || month < 1 || month > 12) {
    return null; // Invalid input, return null
  }
  return new Date(year, month - 1); // Month is 0-indexed in JavaScript Date
}

function isDateInPast(date: Date): boolean {
  const currentDate = new Date(); // Current date and time
  return date.getTime() < currentDate.getTime(); // Compare millisecond representations
}

const cardExpiryDate = "expiry date";
const cardNumber = "credit card number";
const cardVerificationCode = "cvv/cvc code";

let questionSenariosVisibility: any = {};

function EventRegistrationFormNew(
  {
    setOpenForm,
    getCurrentRegistrationPage,
    openSurveyForm,
    registrationValue,
    currentRegistrationStep,
    setCurrentRegistrationStep,
    registered,
    user,
    setRequiredRegistrationQuestionsFilled,
    setIsSuveryorRegSkipable,
    onLogout,
  }: Props,
  ref: any
) {
  const {
    registrationPages,
    questionPageLoader,
    registrationForm,
    signupRegQuestion,
  } = useSurveyContext();

  const ticketService = new TicketService();
  const [clientSecret, setClientSecret] = useState("");
  const [currentPageFontColor, setCurrentPageFontColor] = useState<
    string | null
  >("");
  const [ticketSlides, setTicketSlides] = useState<any[]>([]);
  const [currentPageBackgroundColor, setCurrentPageBackgroundColor] = useState<
    string | null
  >("");
  const [currentPageTitleDescPosition, setCurrentPageTitleDescPosition] =
    useState<string | null>("");
  const [currentPagePrimaryBgColor, setCurrentPagePrimaryBgColor] = useState<
    string | null
  >("");
  const [currentPageHeaderColor, setCurrentPageHeaderColor] = useState<
    string | null
  >("");
  const [purchasedTickets, setPurchasedTickets] = useState<any>();
  const [openStripeModal, setOpenStripeModal] = useState<any>({
    open: false,
    ticketId: null,
  });

  const [currentOrder, setCurrentOrder] = useState<any>();
  const [confirmedOrder, setConfirmedOrder] = useState<any>();
  const { eventTickets } = useSelector((state: IAppState) => state);
  const { eventRegistration, errorMessage: signupError } = useSelector(
    (state: IAppState) => state.signupUser
  );

  const {
    secondaryFontColor,
    styles,
    fieldBorderColor,
    focusColor,
    secondaryBackgroundColor,
    primaryBackgroundColor,
    headerColor,
  } = useStyling(
    "registration",
    currentPageFontColor,
    currentPageBackgroundColor,
    currentPageHeaderColor,
    currentPageTitleDescPosition
  );
  const { upcomingEvent, signupUser } = useSelector(
    (state: IAppState) => state
  );
  const isRegistered = upcomingEvent?.value?.data?.attendeeStatus?.isRegistered;

  const event = useSelector((state: IAppState) => state.website.event!);
  const { id: eventId } = event || null;
  const dispatch = useDispatch();
  const [allPagesQuestionsIds, setAllPagesQuestionsIds] = useState<any>([]);
  const [questionPerPage, setQuestionPerPage] = useState<any>({});
  const [showMatchUsers, setShowMatchUsers] = useState<boolean>(false);
  const [requiredQuestionsIds, setRequiredQuestionsIds] = useState<any>([]);
  const [textSliderValue, setTextSliderValue] = useState<any>([]);
  const [isFocused, setIsFocused] = useState<{ [id: string]: boolean }>({});
  const [nextButtonClicked, setNextButtonClicked] = useState<boolean>(false);
  const [termAndConditionAllPages, setTermAndConditionAllPages] = useState<any>(
    []
  );
  const [allPhoneNumbersCountryCode, setAllPhoneNumbersCountryCode] =
    useState<any>({});
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleFocus = (id: string) => {
    setIsFocused({ [id]: true });
  };

  const handleBlur = (id: string) => {
    setIsFocused({
      [id]: false,
    });
  };

  const handleClick = (_: React.MouseEvent, index: number) => {
    setSelectedIndex(index);
  };

  const convertToOrignalFieldName = (
    fieldName: string,
    linkColor: string = "#0131ff"
  ) => {
    let originalFieldName = fieldName
      .replace(/\&middot;/g, ".")
      .replace(/\&comma;/g, ",")
      .replace(/\&apos;/g, "'")
      .replace(/\&pipe;/g, "|");

    originalFieldName = originalFieldName.replace(
      /\{\{link:([^|]+)\|([^\}]+)\}\}/g,
      `<a href="$2" target="_blank" style="color: ${linkColor}; text-decoration: underline;">$1</a>`
    );
    return originalFieldName;
  };

  const focusStyles = {
    outline: "none",
    boxShadow: `0 0 0 0.15em ${focusColor}`,
    border: `1px solid ${currentPageFontColor || secondaryFontColor}`,
  };

  useEffect(() => {
    if (isRegistered || registered) {
      openSurveyForm(true);
    }
  }, [isRegistered, registered]);

  useEffect(() => {
    const pageIds: string[] = Object.keys(questionPerPage);
    const checkErrorAndLastPage =
      signupUser?.errorMessage &&
      signupUser?.errorMessage !== "" &&
      pageIds.length &&
      currentRegistrationStep === pageIds.length;
    if (checkErrorAndLastPage) {
      setCurrentRegistrationStep(pageIds.length - 1);
    }
  }, [signupUser?.errorMessage, currentRegistrationStep]);

  useEffect(() => {
    sortSlides(eventTickets?.value, 3);
  }, [eventTickets?.value]);

  const submitRegistrationForm = async (data: any) => {
    const payload: any = {};
    let termAndConditionFields = {};
    for (const page of termAndConditionAllPages) {
      const result = findMatchingFieldId(page.fieldName, data, page);
      // delete data[page.fieldName]
      termAndConditionFields = { ...termAndConditionFields, ...result };
    }
    data = { ...data, ...termAndConditionFields };
    Object.keys(data).forEach((fieldName: string) => {
      const formField =
        registrationForm &&
        registrationForm.find(
          (field: RegistrationForm) =>
            field.fieldName === convertToOrignalFieldName(fieldName)
        );

      if (formField) {
        if (
          formField?.fieldType === RegistrationFieldType.COMPANY ||
          formField?.fieldType === RegistrationFieldType.INDUSTRY ||
          formField?.fieldType === RegistrationFieldType.ORGANIZATION_SIZE ||
          formField?.fieldType === RegistrationFieldType.JOB_FUNCTION ||
          formField?.fieldType === RegistrationFieldType.JOB_TITLE ||
          formField?.fieldType === RegistrationFieldType.ADDRESS
        ) {
          payload[formField.id] = data[fieldName]?.name;
        } else if (
          formField.fieldType === RegistrationFieldType.RADIO ||
          formField.fieldType === RegistrationFieldType.SELECT
        ) {
          if (
            formField.registrationOptionFields.some(
              (option) => option.optionName === data[fieldName]
            )
          )
            payload[formField.id] =
              formField.fieldType === RegistrationFieldType.SELECT
                ? [data[fieldName]]
                : data[fieldName];
          else payload[formField.id] = undefined;
        } else if (
          formField?.fieldName?.toLowerCase()?.includes(cardExpiryDate)
        ) {
          const expirayDate = convertToDateFormat(data[fieldName]);
          if (expirayDate) {
            const formattedDate = expirayDate?.toISOString().slice(0, 10);
            payload[formField.id] = formattedDate;
          }
        } else {
          payload[formField.id] = data[fieldName];
        }
      }
    });

    const apiPayload = convertObjectToString(payload);
    dispatch(eventRegistrationStep2(eventId, apiPayload));
    isSubmitted = true;
  };

  const onSubmit = async (data: any) => {
    if (
      Object.keys(data).length &&
      !Object.keys(data).every((x) => data[x] === undefined)
    ) {
      if (termAndConditionAllPages?.length) {
        const isValidTermAndCondition = getValidTermAndCondition();
        if (isValidTermAndCondition) {
          submitRegistrationForm(data);
          sessionStorage.setItem("termAndCondition", "false");
        }
      } else {
        submitRegistrationForm(data);
      }
    }
  };
  // default values
  const defaultValues = useMemo(
    () => {
      const formDefaultValues: any = {};
      if (registrationPages?.length) {
        registrationPages.forEach((item: Page) => {
          const { eventRegistrationFields } = item;
          eventRegistrationFields.forEach((question: RegistrationForm) => {
            formDefaultValues[question?.fieldName] = undefined;
          });
        });
      }
      return formDefaultValues;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [registrationPages]
  );

  const requiredEmailSchema = Yup.string()
    .required("Business email is required")
    .email("Invalid email")
    .test("not-included", "Business email address only", (value) => {
      if (value) {
        const includesForbiddenSubstring = forbiddenEmailDomains.some(
          (substring) => value?.toLowerCase()?.includes(substring)
        );
        return !includesForbiddenSubstring;
      }
      return true; // Return true if the email is valid
    })
    .matches(emailRegex, "Should be a valid business email");

  const notRequiredEmailSchema = Yup.string()
    .notRequired()
    .email("Invalid email")
    .test("not-included", "Business email address only", (value) => {
      if (value) {
        const includesForbiddenSubstring = forbiddenEmailDomains.some(
          (substring) => value?.toLowerCase()?.includes(substring)
        );
        return !includesForbiddenSubstring;
      }
      return true;
    })
    .matches(emailRegex, "Should be a valid business email");

  // form validations
  const fieldsObject = useMemo(
    () => {
      const formFields: any = {};
      const allRequiredFileds: any = {};
      const RequiredFiledsPerPage: any = {};
      if (registrationPages?.length)
        registrationPages.forEach((item: Page) => {
          const { eventRegistrationFields } = item;
          eventRegistrationFields.forEach((field: RegistrationForm) => {
            const { fieldType, isRequired, id, fieldName } = field;
            switch (fieldType) {
              case RegistrationFieldType.BUSINESS_EMAIL:
                if (isRequired) {
                  formFields[fieldName] = requiredEmailSchema;
                } else {
                  formFields[fieldName] = notRequiredEmailSchema;
                }
                break;

              case RegistrationFieldType.TERM_AND_CONDITION:
                if (isRequired) {
                  formFields[fieldName] = Yup.array().min(
                    1,
                    "At least one item is required"
                  );
                } else {
                  formFields[fieldName] = Yup.array().notRequired();
                }
                break;

              case RegistrationFieldType.PHONE_NUMBER:
                formFields[fieldName] = isRequired
                  ? Yup.string()
                      .required("Required Field")
                      .min(8, "Invalid Phone Number")
                      .test("no-spaces", "Required Field", function (value) {
                        return typeof value === "string" && value.trim() !== "";
                      })
                  : // .test('isValidPhoneNumber', 'Invalid phone number', (value) => {
                    //     const phone = `+${value}`;
                    //     const phoneNumberObj = parsePhoneNumberFromString(phone, allPhoneNumbersCountryCode[fieldName]); // Adjust the default country code as needed
                    //     return phoneNumberObj && phoneNumberObj.isValid();
                    // })
                    Yup.string().notRequired();
                break;

              case RegistrationFieldType.INDUSTRY:
              case RegistrationFieldType.JOB_FUNCTION:
              case RegistrationFieldType.COMPANY:
              case RegistrationFieldType.JOB_TITLE:
              case RegistrationFieldType.ADDRESS:
              case RegistrationFieldType.ORGANIZATION_SIZE:
                if (isRequired) {
                  formFields[fieldName] =
                    Yup.object().required(`Required Field`);
                } else {
                  formFields[fieldName] = Yup.object().notRequired();
                }
                break;

              default:
                if (isRequired) {
                  if (fieldName?.toLowerCase()?.includes(cardNumber)) {
                    formFields[fieldName] = Yup.string()
                      .required("Required Field")
                      .test("no-spaces", "Invalid Value", function (value) {
                        return isCreditCard(value);
                      });
                  } else if (
                    fieldName?.toLowerCase()?.includes(cardExpiryDate)
                  ) {
                    formFields[fieldName] = Yup.string()
                      .required("Required Field")
                      .test(
                        "no-spaces",
                        "Expiration date cannot be in the past",
                        function (value) {
                          const date = convertToDateFormat(value);
                          if (date) {
                            return !isDateInPast(date);
                          }
                          return false;
                        }
                      );
                  } else if (
                    fieldName?.toLowerCase()?.includes(cardVerificationCode)
                  ) {
                    formFields[fieldName] = Yup.string()
                      .required(`Required Field`)
                      .min(3, "Invalid value")
                      .max(3, "Invalid value")
                      .test("no-spaces", "Required Field", function (value) {
                        return typeof value === "string" && value.trim() !== "";
                      });
                  } else {
                    formFields[fieldName] = Yup.string()
                      .required(`Required Field`)
                      .min(1, "Name must be at least 1 characters.")
                      .test("no-spaces", "Required Field", function (value) {
                        return typeof value === "string" && value.trim() !== "";
                      });
                  }
                } else {
                  formFields[fieldName] = Yup.string().notRequired();
                }
                break;
            }
          });
        });

      return formFields;
    },
    // eslint-/exhaustive-depsdisable-next-line react-hooks
    [registrationPages, allPhoneNumbersCountryCode]
  );
  const NewEventSchema = Yup.object().shape(fieldsObject);

  // formik hook
  const methods = useForm<any>({
    resolver: yupResolver(NewEventSchema),
    defaultValues,
  });

  // form methods
  const {
    setValue,
    reset,
    watch,
    setError,
    clearErrors,
    handleSubmit,
    trigger,
    formState: { isValid, errors },
  } = methods;
  const values = watch();

  const firstError = Object.keys(errors)[0]
  let el = document.querySelector(`[name="${firstError}"]`) || document.querySelector(`[id="${firstError}"]`);
  if (el) {
      el.scrollIntoView({
          behavior: "smooth",
          block: "center"
      })
  }

  function findMatchingFieldId(id: string, data: any, page: any) {
    if (data.hasOwnProperty(id)) {
      const fieldValues = data[id];
      let termAndConditionChecked = {};
      for (const field of page.registrationOptionFields) {
        if (
          fieldValues &&
          typeof fieldValues === "object" &&
          fieldValues?.includes(field?.fieldName)
        ) {
          termAndConditionChecked = {
            ...termAndConditionChecked,
            [field?.fieldName]: true,
          };
        } else if (!field?.isRequired) {
          termAndConditionChecked = {
            ...termAndConditionChecked,
            [field?.fieldName]: false,
          };
        }
      }
      return termAndConditionChecked;
    }
  }

  const checkTermFieldValidation = (
    val: any,
    question: any,
    isRequired: boolean
  ) => {
    if (nextButtonClicked && val) {
      const result: any = findMatchingFieldId(
        question?.fieldName,
        { [question?.fieldName]: val },
        question
      );
      const requiredIds = question.registrationOptionFields
        .filter((option: any) => option.isRequired)
        .map((option: any) => option.fieldName);

      const allRequiredIdsPresent = requiredIds.every((id: string) =>
        Object.keys(result)?.includes(id)
      );

      if (allRequiredIdsPresent) {
        sessionStorage.setItem("termAndCondition", "true");
        return true;
      } else {
        sessionStorage.setItem("termAndCondition", "false");
        return false;
      }
    }
  };

  const handlePhoneNumberChange = (e: any, country: any, fieldName: string) => {
    setValue(fieldName, e);
    if (country) {
      setAllPhoneNumbersCountryCode({
        ...allPhoneNumbersCountryCode,
        [fieldName]: country?.countryCode!,
      });
    }
  };

  const checkPhoneFieldValidation = (
    values: any,
    id: string,
    isRequired: boolean
  ) => {
    if (isRequired && nextButtonClicked) {
      if (values[id] && values[id] !== "") {
        return false;
      }
      return true;
    }

    return false;
  };

  function sortSlides(arr: string | any[], slideSize: number) {
    const SlideArr = [];
    for (let i = 0; i < arr.length; i += slideSize) {
      const totalSlides = arr.slice(i, i + slideSize);
      SlideArr.push(totalSlides);
    }
    setTicketSlides(SlideArr);
  }

  const displayTickets = () => {
    {
      /* Tickets section */
    }
    {
      /* Steps at the top */
    }
    // function setCurrentOrder(order: any) {
    //     throw new Error("Function not implemented.");
    // }

    return (
      <div className="flex flex-col h-full">
        {/* <TicketBreadcrumb selected="Tickets &amp; Registration" /> */}
        {/* Ticket cards */}
        <div className="max-w-[847px] mb-5">
          <div
            className="flex flex-row overflow-x-auto no-scrollbar mr-4 ml-[20px] py-6 slides justify-center"
            // ref={contentWrapper}
          >
            {ticketSlides.length > 0 &&
              ticketSlides.map((ticket, index) =>
                ticket.map(
                  (
                    item: {
                      id: string;
                      ticketName: string;
                      price: number;
                      ticketDescription: string;
                      ticketLimit: { toString: () => string | undefined };
                      inclusions: string[];
                      currency: string;
                      ticketPolicy: string;
                      userTicketPurchased?: number;
                    },
                    index: number
                  ) => (
                    <div
                      id={`slide-${index}`}
                      key={`ticket-${index}`}
                      className="pl-3"
                    >
                      <div key={index} className="mr-3">
                        <TicketCardRego
                          id={item?.id}
                          userTicketPurchased={item?.userTicketPurchased}
                          eventId={eventId}
                          currency={item?.currency}
                          userId={user?.id}
                          ticketPolicy={item.ticketPolicy}
                          title={item?.ticketName}
                          price={item?.price}
                          description={item?.ticketDescription}
                          availability={item?.ticketLimit.toString()}
                          quantity={
                            (
                              eventTickets?.selectedTickets.filter(
                                (t) => t?.ticketId === item.id
                              ) as any
                            )?.[0]?.numberOfTickets ?? 0
                          }
                          inclusions={item?.inclusions}
                          onIncrement={(selectedTicket: ISelectedTicket) => {
                            setCurrentOrder(null);
                            dispatch(selectTicket(selectedTicket));
                          }}
                          onDecrement={(ticketId: string) => {
                            setCurrentOrder(null);
                            if (
                              eventTickets.selectedTickets.some(
                                (ticket) => ticket.ticketId === ticketId
                              )
                            ) {
                              let ticket = eventTickets.selectedTickets.find(
                                (ticket) => ticket.ticketId === ticketId
                              );

                              if (ticket?.numberOfTickets! > 1) {
                                // if there are more than one tickets, decrease the numnberOfTickets by 1
                                dispatch(updateTickets(ticketId));
                              } else {
                                // if there is only one ticket then remove it
                                dispatch(removeTicketFromCart(ticketId));
                              }
                            }
                          }}
                          purchasedTickets={purchasedTickets}
                          onBuyTicket={(ticketId) => {
                            ticketService
                              .placeOrder({
                                // userTickets: [{tickets:ticketId}]
                                userTickets: [
                                  {
                                    eventTicketId: ticketId,
                                    email: values["Email"],
                                    firstName: values["First Name"],
                                    lastName: values["Last Name"],
                                  },
                                ],
                              })
                              .then((order: any) => {
                                setCurrentOrder(order);
                                setClientSecret(order?.stripePaymentSecret);
                                // setPaymentStage(4);
                                setOpenStripeModal({ open: true, ticketId });
                                // setOpenOrderConfirmationModal(true);
                              });
                          }}
                        />
                      </div>
                    </div>
                  )
                )
              )}
          </div>
          <div className="flex flex-row justify-center items-center event-card-bulets">
            {ticketSlides &&
              ticketSlides.length > 0 &&
              ticketSlides.map((item: any, i: number) => (
                <a
                  key={`tab-button-${i}`}
                  className={classNames("mr-1 bullets", {
                    [`active`]: i == selectedIndex,
                  })}
                  href={`#slide-${i}`}
                  onClick={(e) => {
                    handleClick(e, i);
                  }}
                >
                  ○
                </a>
              ))}
          </div>
        </div>
        <div className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="844"
            height="0.5"
            viewBox="0 0 844 0.5"
          >
            <line
              id="Line_1393"
              data-name="Line 1393"
              x2="844"
              transform="translate(0 0.25)"
              fill="none"
              stroke="#cecece"
              strokeWidth="0.5"
            />
          </svg>
        </div>
        <div className="flex flex-row justify-between border-t-1 border-transparent">
          <div className="text-sm text-gray-1 p-4">
            You can enter discount and membership codes in the next step.
          </div>
          {/* <div className="flex flex-row p-2">
                <div className="mr-2">
                  <Button
                    className="bg-gray-1 text-blue-3"
                    size="small"
                    text="Cancel"
                    onClick={() => onCancel()}
                  />
                </div>
                <div className="mr-3">
                  <Button
                    className="bg-blue text-white"
                    size="small"
                    text="Next"
                    onClick={() => {
                      if (eventTickets.selectedTickets.length === 0) {
                        setShowValidationMessage(true);
                      } else {
                        setShowValidationMessage(false);
                        setPaymentStage(2);
                        // onSubmit();
                      }
                    }}
                  />
                </div>
              </div> */}
        </div>
        {/* {showValidationMessage && (
              <div className="text-sm text-red-2 p-4">
                Please select atleast one ticket to proceed
              </div>
            )} */}
      </div>
    );
  };

  const stripePayment = () => {
    const appearance: any = {
      theme: "stripe",
    };
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE}`);
    const options = {
      clientSecret,
      appearance,
    };
    return (
      <div className="flex flex-col w-full">
        {/* <TicketBreadcrumb className="pl-[35px] pt-[29px] pb-[15px]" selected="Security" /> */}
        <div className="w-full">
          <div className="font-semibold font flex justify-center w-full p-4">
            Payment
          </div>
          <div className="flex flex-col items-center border-t border-[#CECECE] justify-between w-full py-4">
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                successCallback={async () => {
                  await ticketService
                    .getOrderSummary(currentOrder.orderId)
                    .then(({ data }) => setConfirmedOrder(data));
                  dispatch(getTickets(eventId));
                  setPurchasedTickets({
                    ...purchasedTickets,
                    [openStripeModal.ticketId]: true,
                  });
                  setOpenStripeModal({ open: false, ticketId: null });
                  // submitEventQuestionForm();
                  // setPaymentStage(5);
                }}
              />
            </Elements>
          </div>
        </div>
        {/* <div className='flex flex-row items-center border-t border-[#CECECE] justify-between h-[50px] mt-2 w-full'>
              <Button
                className="bg-gray-1 text-blue-3 flex flex-none m-6 items-center"
                size="small"
                text="Back"
                onClick={() => setPaymentStage(3)}
              />
            </div> */}
      </div>
    );
  };
  const renderCreditCardField = (fieldName: string) => (
    <div>
      <div className="card-no cards">
        <Cleave
          placeholder="Enter credit card number"
          options={{
            creditCard: true,
            // creditCardStrictMode: false,
            // blocks:[44,4]
            onCreditCardTypeChanged: (type: string) =>
              setValue(`${fieldName}-type`, type),
          }}
          onChange={(e: any) => setValue(fieldName, e.target.rawValue)}
          className="form-field"
          style={{
            border: `1px solid ${fieldBorderColor}`,
            backgroundColor: secondaryBackgroundColor,
            color: currentPageFontColor || secondaryFontColor,
          }}
        />
        {values[`${fieldName}-type`] &&
          values[`${fieldName}-type`]?.toLowerCase() !== "unknown" && (
            <p
              className="cardType"
              style={{
                color: currentPageFontColor || secondaryFontColor,
              }}
            >
              {values[`${fieldName}-type`]}
            </p>
          )}
      </div>

      {errors && errors[fieldName] && (
        <Typography
          id="event-name-input"
          gutterBottom
          sx={{
            "&.MuiTypography-root": {
              color: "#FF5630",
              fontSize: "0.75rem",
              fontFamily: "Poppins-400",
              ml: "1px",
              mt: "8px",
            },
          }}
        >
          {String(errors?.[fieldName]?.message) || "Required Field"}
        </Typography>
      )}
    </div>
  );

  const renderExpiryDateField = (fieldName: string) => (
    <div>
      <div className="date-time">
        <Cleave
          placeholder="MM/YY"
          options={{ date: true, datePattern: ["m", "d"] }}
          onChange={(e: any) => setValue(fieldName, e.target.rawValue)}
          className="form-field"
          style={{
            border: `1px solid ${fieldBorderColor}`,
            backgroundColor: secondaryBackgroundColor,
            color: currentPageFontColor || secondaryFontColor,
          }}
        />
      </div>

      {errors && errors[fieldName] && (
        <Typography
          id="event-name-input"
          gutterBottom
          sx={{
            "&.MuiTypography-root": {
              color: "#FF5630",
              fontSize: "0.75rem",
              fontFamily: "Poppins-400",
              ml: "1px",
              mt: "8px",
            },
          }}
        >
          {String(errors?.[fieldName]?.message) || "Required Field"}
        </Typography>
      )}
    </div>
  );

  const renderCVVField = (fieldName: string) => (
    <Stack direction="row" spacing="8px" alignItems="flex-start">
      <RHFTextField
        type="number"
        sx={{
          ...styles,
          "& .MuiOutlinedInput-root input::placeholder": {
            color: currentPageFontColor || secondaryFontColor,
            opacity: 1,
          },
          width: "80px",

          "& .MuiOutlinedInput-root": {
            width: "80px",
            borderRadius: "8px !important",
            fontFamily: "Poppins-400",
            color: `${currentPageFontColor || secondaryFontColor} !important`,
            paddingLeft: "12px !important",
          },
        }}
        key={fieldName}
        onChange={(e) => {
          if (e.target.value?.length <= 3) setValue(fieldName, e.target.value);
        }}
        value={values[fieldName]}
        name={fieldName}
      />
    </Stack>
  );

  const renderTextField = (fieldName: string) => (
    <RHFTextField
      placeholder={
        fieldName === "First name" ||
        fieldName === "Last name" ||
        fieldName === "Job title" ||
        fieldName === "Postal Code" ||
        fieldName === "Email"
          ? ""
          : "Type here…"
      }
      sx={{
        ...styles,
        "& .MuiOutlinedInput-root input::placeholder": {
          color: currentPageFontColor || secondaryFontColor,
          opacity: 1,
        },
      }}
      key={fieldName}
      onChange={(e) => {
        setValue(fieldName, e.target.value);
      }}
      value={values[fieldName]}
      name={fieldName}
    />
  );

  const evaluateConditions = (conditions: Condition[]): boolean => {
    let result: boolean | null = null;
    let andFlag = false;

    for (const condition of conditions) {
      if (condition.and !== undefined) {
        if (result === null) {
          result = condition.and;
        }
        //  else if (andFlag) {
        //     result = result && condition.and;
        // }
        else {
          result = result && condition.and;
        }
        andFlag = true;
      } else if (condition.or !== undefined) {
        if (result === null) {
          result = condition.or;
        }
        //  else if (andFlag) {
        //     result = result && condition.or;
        // }
        else {
          result = result || condition.or;
        }
        // andFlag = false;
      }
    }

    return result ?? true;
  };

  // if(CA || CB) return "hide"
  // if(CA = 1 || CB) return "show"
  const getQuestionAction = (
    senariosResult: boolean,
    registrationFlowAction?: any[],
    fieldNames?: any,
    triggerId?: any
  ) => {
    const onSuccess: any = registrationFlowAction?.find(
      (reg: any) => reg?.type === "success"
    );
    const onFail = registrationFlowAction?.find(
      (reg: any) => reg?.type === "fail"
    );

    if (!registrationFlowAction?.length) {
      return "show";
    } else if (
      fieldNames &&
      fieldNames.every((key: string) => values[key] === null) &&
      triggerId.every((key: string) => !key) &&
      (onSuccess?.action == "show" || onSuccess?.action == "hide")
    )
      return "nullValue";
    else if (senariosResult) {
      return onSuccess?.action;
    }
    return onFail?.action;
  };

  const checkVisibility = (
    registrationFlow: any,
    fieldId: string,
    fieldName: string,
    registrationFlowAction: any
  ) => {
    const results: any[] = [];
    let fieldNames: any = [];
    let triggerId: any = [];
    if (registrationFlow?.length === 0) {
      if (!questionSenariosVisibility[fieldId]) {
        questionSenariosVisibility[fieldId] = {
          questionId: fieldId,
          currentStatus: "show",
          fieldName,
          senariosResult: true,
        };
      }
    }

    registrationFlow?.forEach((scenario: any, index: number) => {
      const {
        action,
        condition,
        question,
        scenarioType,
        triggerOption,
        trigger,
      } = scenario;
      fieldNames.push(question?.fieldName);
      triggerId.push(trigger);
      // let fieldNames = []
      switch (question?.fieldType) {
        case RegistrationFieldType.SELECT:
        case RegistrationFieldType.RADIO:
        case RegistrationFieldType.ORGANIZATION_SIZE:
        case RegistrationFieldType.EMAIL:
        case RegistrationFieldType.BUSINESS_EMAIL:
        case RegistrationFieldType.TEXT:
        case RegistrationFieldType.FIRST_NAME:
        case RegistrationFieldType.LAST_NAME:
        case RegistrationFieldType.CHECKBOX:
        case RegistrationFieldType.POSTAL_CODE:
        case RegistrationFieldType.TERM_AND_CONDITION:
        case RegistrationFieldType.JOB_TITLE:
        case RegistrationFieldType.COMPANY:
        case RegistrationFieldType.PHONE_NUMBER:
          if (!trigger) {
            const result =
              values[question?.fieldName] == triggerOption?.optionName;
            results?.push({
              [condition]: result,
              action: result ? action : "notMatched",
            });
          } else if (trigger) {
            const dependentQuestion = questionSenariosVisibility[question?.id];
            const result =
              trigger?.includes(dependentQuestion?.currentStatus) ||
              (trigger === "hidden" &&
                dependentQuestion?.currentStatus === "hide");
            results?.push({
              [condition]: result,
              action: result ? action : "notMatched",
              trigger,
              questionId: question?.id,
              fieldName: question?.fieldName,
            });
          }
          break;

        default: {
          results?.push({ [condition]: false, action });
        }
      }
    });

    const senariosResult = evaluateConditions(results);
    questionSenariosVisibility[fieldId] = {
      questionId: fieldId,
      currentStatus: getQuestionAction(
        senariosResult,
        registrationFlowAction,
        fieldNames,
        triggerId
      ),
      fieldName,
      senariosResult: results?.length ? senariosResult : true,
    };
    // if (results?.length && senariosResult === false) {
    //     setValue(fieldName, null)
    // }
    return questionSenariosVisibility[fieldId];
  };
  const displayDivider = (currentIndex: number, totalQuestionLength: number) =>
    currentIndex < totalQuestionLength - 1 ? (
      <Divider
        sx={{
          padding: "0px",
          margin: "0px",
          mt: "12px !important",
          mb: "8px !important",
          borderColor:
            event?.websiteTemplate === "spectre"
              ? "rgba(255, 255, 255, 0.15)"
              : "#707070",
        }}
      />
    ) : null;

  // display registration questions
  const registrationQuestion = useCallback(
    (
      question: RegistrationForm,
      index: number,
      totalQuestionLength: number
    ) => {
      let options: Options[] = [];
      if (question) {
        const {
          fieldType,
          id,
          fieldName,
          registrationOptionFields,
          registrationFlow,
          isRequired,
          isDefault,
          registrationFlowAction,
        } = question;
        const label = convertToOrignalFieldName(fieldName);
        const currentQuestion: any = checkVisibility(
          registrationFlow,
          id,
          fieldName,
          registrationFlowAction
        );
        // const questionAction = getQuestionAction(currentQuestion?.senariosResult, registrationFlowAction)
        const questionAction = currentQuestion?.currentStatus;
        const currentQuestionVisibility =
          questionAction === "hide" || questionAction === "nullValue"
            ? false
            : true;
        if (!currentQuestionVisibility && values[fieldName] !== null) {
          setValue(fieldName, null);
        }
        switch (fieldType) {
          case RegistrationFieldType.COMPANY:
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        fontWeight: 500,
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, label)}
                    </Typography>

                    <PCompanySelectLiveSearch
                      name={fieldName}
                      key={id}
                      sx={{
                        ...styles,
                        "& .MuiOutlinedInput-input": { height: "10px" },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                        },
                        "& .MuiOutlinedInput-root input::placeholder": {
                          color: currentPageFontColor || secondaryFontColor,
                          opacity: 1,
                        },
                      }}
                    />
                  </Box>

                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case RegistrationFieldType.INDUSTRY:
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        fontWeight: 500,
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, label)}
                    </Typography>

                    <PIndustrySelect
                      name={fieldName}
                      placeholder={"Select an industry"}
                      key={id}
                      sx={{
                        ...styles,
                        "& .MuiOutlinedInput-input": {
                          height: "10px",
                          cursor: "pointer",
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                        },
                        "& .MuiOutlinedInput-root input::placeholder": {
                          color: currentPageFontColor || secondaryFontColor,
                          opacity: 1,
                        },
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case RegistrationFieldType.JOB_FUNCTION:
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        fontWeight: 500,
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, label)}
                    </Typography>

                    <PJobFunctionSelect
                      name={fieldName}
                      placeholder={"Select job function"}
                      key={id}
                      sx={{
                        ...styles,
                        "& .MuiOutlinedInput-input": {
                          height: "10px",
                          cursor: "pointer",
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                        },
                        "& .MuiOutlinedInput-root input::placeholder": {
                          color: currentPageFontColor || secondaryFontColor,
                          opacity: 1,
                        },
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case RegistrationFieldType.ADDRESS:
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        fontWeight: 500,
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, label)}
                    </Typography>

                    <LocationDropdown
                      name={fieldName}
                      placeholder={"Search for your city"}
                      sx={{
                        width: "100%",
                        ...styles,
                        "& .MuiAutocomplete-popupIndicator": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                        },
                        "& .MuiOutlinedInput-root input::placeholder": {
                          color: currentPageFontColor || secondaryFontColor,
                          opacity: 1,
                        },
                      }}
                      onSelectLocation={() => {}}
                    />
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case RegistrationFieldType.JOB_TITLE:
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        fontWeight: 500,
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, label)}
                    </Typography>

                    <PJobTitleSelectLiveSearch
                      name={fieldName}
                      sx={{
                        ...styles,
                        "& .MuiOutlinedInput-input": { height: "10px" },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                        },
                        "& .MuiOutlinedInput-root input::placeholder": {
                          color: currentPageFontColor || secondaryFontColor,
                          opacity: 1,
                        },
                      }}
                    />
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case RegistrationFieldType.PHONE_NUMBER:
            const isPhoneFiledDirty = checkPhoneFieldValidation(
              values,
              question?.fieldName,
              isRequired
            );
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        fontWeight: 500,
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, label)}
                    </Typography>
                    <Stack id={fieldName} sx={{ position: "relative" }}>
                      <FormControl  component="fieldset">
                        <PhoneInput
                      
                        country={"us"}
                          enableSearch
                          containerClass={classNames(
                            "arial-neue-35 w-full !h-[43px] !rounded-[7px] bg-transparent placeholder:text-[15px] text-[15px] text-white px-[0px] focus-visible:outline-[red]",
                            {
                              // 'spectre-input-new-bottom': totalQuestionLength - index <= 3,
                              // 'spectre-input-new': totalQuestionLength - index > 3,
                              "spectre-input-new-bottom": index >= 4,
                              "spectre-input-new": index < 4,
                            }
                          )}
                          value={values[fieldName]}
                          onChange={(e: any, country: any) =>
                            handlePhoneNumberChange(e, country, fieldName)
                          }
                          inputProps={{
                            style: {
                              color: currentPageFontColor || secondaryFontColor,
                              fontSize: 16,
                              fontFamily: "Poppins-400",
                              lineHeight: "21px",
                              border: `1px solid ${
                                errors && errors?.[fieldName]
                                  ? "red"
                                  : fieldBorderColor
                              }`,
                              ...(isFocused[fieldName] && focusStyles),
                              borderRadius: "8px !important",
                            },
                          }}
                          onFocus={() => handleFocus(fieldName)}
                          onBlur={() => handleBlur(fieldName)}
                        />
                        {errors && errors[fieldName] && (
                          <Typography
                            id="event-name-input"
                            gutterBottom
                            sx={{
                              "&.MuiTypography-root": {
                                color: "#FF5630",
                                fontSize: "0.75rem",
                                fontFamily: "Poppins-400",
                                ml: "1px",
                                mt: "8px",
                                mb:"0px !important"
                              },
                            }}
                          >
                            {String(errors?.[fieldName]?.message) ||
                              "Required Field"}
                          </Typography>
                        )}
                      </FormControl>
                    </Stack>
                  </Box>

                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case RegistrationFieldType.RADIO:
          case RegistrationFieldType.SELECT:
            return (
              currentQuestionVisibility &&
              id === currentQuestion?.questionId && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        fontWeight: 500,
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, label)}
                    </Typography>
                    <div style={{ position: "relative" }}>
                      <RHFSelect
                        name={fieldName}
                        placeholder="Select an option"
                        key={id}
                        sx={{
                          "& .MuiFormHelperText-root": { ml: "1px" },
                          "& .MuiInputLabel-root": {
                            color: `${
                              currentPageFontColor || secondaryFontColor
                            } !important`,
                          },
                          "& .MuiSelect-select": {
                            height: "43px !important",
                            display: "flex",
                            alignItems: "center",
                          },
                          "& .MuiSelect-icon": {
                            color: `${
                              currentPageFontColor || secondaryFontColor
                            } !important`,
                          },
                          "& .MuiFormControlLabel-label": {
                            fontSize: 16,
                            fontFamily: "Poppins-500",
                            lineHeight: "21px",
                          },

                          ...styles,
                        }}
                      >
                        {registrationOptionFields?.map((option: any) => (
                          <MenuItem key={option.id} value={option.optionName}>
                            {option.optionName}
                          </MenuItem>
                        ))}
                      </RHFSelect>

                      {!values[fieldName] && (
                        <Typography
                          id="event-name-input"
                          gutterBottom
                          sx={{
                            color: currentPageFontColor || secondaryFontColor,
                            position: "absolute",
                            top: "11px",
                            left: "12px",
                            fontFamily: "Poppins-400",
                            fontSize: "14px",
                          }}
                        >
                          Select an option
                        </Typography>
                      )}
                    </div>
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case RegistrationFieldType.ORGANIZATION_SIZE:
            return (
              currentQuestionVisibility &&
              id === currentQuestion?.questionId && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        fontWeight: 500,
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, label)}
                    </Typography>
                    <div style={{ position: "relative" }}>
                      <PHeadCountSelect
                        name={fieldName}
                        key={id}
                        placeholder="Select an option"
                        sx={{
                          ...styles,
                          "& .MuiOutlinedInput-input": {
                            height: "10px",
                            cursor: "pointer",
                          },
                          "& .MuiAutocomplete-popupIndicator": {
                            color: `${
                              currentPageFontColor || secondaryFontColor
                            } !important`,
                          },
                          "& .MuiOutlinedInput-root input::placeholder": {
                            color: currentPageFontColor || secondaryFontColor,
                            opacity: 1,
                          },
                        }}
                      />

                      {/* {!values[fieldName] && <Typography id="event-name-input" gutterBottom sx={{ color: currentPageFontColor || secondaryFontColor, position: "absolute", top: "11px", left: "12px", fontFamily: 'Poppins-400',fontSize:"14px" }}>
                                                    Select an option
                                                </Typography>} */}
                    </div>
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case RegistrationFieldType.TERM_AND_CONDITION:
            if (!registrationOptionFields?.length) return;
            const isTermAndConditionRequired = checkTermFieldValidation(
              values[fieldName],
              question,
              isRequired
            );

            options = registrationOptionFields?.map((item) => {
              const checkboxLabel = convertToOrignalFieldName(item?.fieldName);
              return {
                label: checkboxLabel,
                value: item?.fieldName,
              };
            });
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        "&.MuiTypography-root": {
                          color: currentPageFontColor || secondaryFontColor,
                          fontSize: 14,
                          fontFamily: "Poppins-500",
                          lineHeight: "18px",
                          fontWeight: 500,
                          mb: "5px",
                        },
                      }}
                    >
                      {getFieldLabel(isRequired, "Terms & Conditions")}
                    </Typography>
                    <PMultiCheckbox
                      eachFieldValidation={values[id]}
                      isTermAndConditionRequired={
                        isTermAndConditionRequired && nextButtonClicked
                      }
                      name={fieldName}
                      options={options}
                      sx={{
                        "& .MuiFormHelperText-root": { ml: "1px" },
                        "& .MuiFormControlLabel-label": {
                          color: currentPageFontColor || secondaryFontColor,
                          fontSize: 14,
                          fontFamily: "Poppins-500",
                          lineHeight: "21px",
                          "&:hover":{
                            textDecoration:"underline"
                          }
                        },
                        "& .MuiCheckbox-root.Mui-checked": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                        },
                        "& .MuiCheckbox-root": {
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          } !important`,
                          p: "0px !important",
                          pr: "5px !important",
                        },
                      }}
                      headerColor={headerColor}
                    />

                    {isTermAndConditionRequired === false && (
                      <Typography
                        id="event-name-input"
                        gutterBottom
                        sx={{
                          "&.MuiTypography-root": {
                            color: "#FF5630",
                            fontSize: "0.75rem",
                            fontFamily: "Poppins-400",
                            ml: "14px",
                            mt: "8px",
                          },
                        }}
                      >
                        Required Field
                      </Typography>
                    )}
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case RegistrationFieldType.CHECKBOX:
            return (
              currentQuestionVisibility && (
                <>
                  <PCheckbox
                    name={fieldName}
                    label={getFieldLabel(isRequired, label)}
                    sx={{
                      "& .MuiFormHelperText-root": { ml: "1px" },
                      "& .MuiFormControlLabel-label": {
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "21px",
                        "&:hover":{
                          textDecoration:"underline"
                        }
                      },
                      "&.MuiFormControlLabel-root": { ml: "0px !important" },
                      "& .MuiCheckbox-root.Mui-checked": {
                        color: `${
                          currentPageFontColor || secondaryFontColor
                        } !important`,
                      },
                      "& .MuiCheckbox-root": {
                        color: `${
                          currentPageFontColor || secondaryFontColor
                        } !important`,
                        p: "0px !important",
                        pr: "5px !important",
                      },
                    }}
                  />
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case RegistrationFieldType.EMAIL:
            return (
              currentQuestionVisibility && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        fontWeight: 500,
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, label)}
                    </Typography>
                    <div style={{ position: "relative" }}>
                      <RHFTextField
                        // placeholder={"Type here…"}
                        sx={{
                          ...styles,
                          borderColor: `${fieldBorderColor} !important`,
                          color: `${
                            currentPageFontColor || secondaryFontColor
                          }!important`,
                          "& .Mui-disabled": {
                            WebkitTextFillColor: `${
                              currentPageFontColor || secondaryFontColor
                            }!important`,
                            fieldSet: {
                              borderColor: `${
                                focusColor || fieldBorderColor
                              } !important`,
                            },
                            "& .MuiOutlinedInput-root input::placeholder": {
                              color: currentPageFontColor || secondaryFontColor,
                              opacity: 1,
                            },
                          },
                        }}
                        key={id}
                        onChange={(e) => {
                          setValue(`${fieldName}`, e.target.value);
                        }}
                        value={values[question?.fieldName]}
                        name={fieldName}
                      />
                    </div>
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );

          case RegistrationFieldType.TICKET:
            return (
              currentQuestionVisibility && (
                <>
                  {displayTickets()}
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );
          default:
            return (
              currentQuestionVisibility &&
              id === currentQuestion?.questionId && (
                <>
                  <Box>
                    <Typography
                      id="event-name-input"
                      sx={{
                        color: currentPageFontColor || secondaryFontColor,
                        fontSize: 14,
                        fontFamily: "Poppins-500",
                        lineHeight: "18px",
                        fontWeight: 500,
                        mb: "5px",
                      }}
                    >
                      {getFieldLabel(isRequired, label)}
                    </Typography>
                    {fieldName?.toLowerCase()?.includes(cardNumber)
                      ? renderCreditCardField(fieldName)
                      : fieldName?.toLowerCase()?.includes(cardExpiryDate)
                      ? renderExpiryDateField(fieldName)
                      : fieldName?.toLowerCase()?.includes(cardVerificationCode)
                      ? renderCVVField(fieldName)
                      : renderTextField(fieldName)}
                  </Box>
                  {displayDivider(index, totalQuestionLength)}
                </>
              )
            );
        }
      }
      return null;
    },
    [values, currentPageFontColor]
  );

  function convertObjectToString(payload: any) {
    Object.keys(payload).forEach((k) => {
      if (payload[k] || typeof payload[k] === "boolean") {
        if (typeof payload[k] === "object")
          return convertObjectToString(payload[k]);
        payload[k] = `${payload[k]}`;
      } else delete payload[k];
      return true;
    });

    return payload;
  }

  const getDefaultValues = () => {
    let defaultValue: any = {};

    const storedData = sessionStorage.getItem("signupAnswers");
    const signupAnswers =
      storedData && storedData !== "undefined" ? JSON.parse(storedData) : null;

    // const signupAnswers = JSON.parse(sessionStorage.getItem('signupAnswers')!) || null;

    const termsAndConditionQuestionInDefaultPage =
      signupRegQuestion?.length &&
      signupRegQuestion.find(
        (question: RegistrationForm) =>
          question?.fieldType === RegistrationFieldType.TERM_AND_CONDITION
      );

    registrationPages?.forEach((item: Page, index: number) => {
      const { eventRegistrationFields } = item;

      eventRegistrationFields?.forEach((question: RegistrationForm) => {
        switch (question?.fieldType) {
          case RegistrationFieldType.FIRST_NAME:
            if (question?.isDefault)
              defaultValue[question?.fieldName] =
                registrationValue["firstName"];
            break;
          case RegistrationFieldType.LAST_NAME: {
            if (question?.isDefault)
              defaultValue[question?.fieldName] = registrationValue["lastName"];
            break;
          }
          case RegistrationFieldType.EMAIL:
          case RegistrationFieldType.BUSINESS_EMAIL: {
            if (question?.isDefault)
              defaultValue[question?.fieldName] = registrationValue["email"];
            break;
          }
          case RegistrationFieldType.JOB_TITLE: {
            if (question?.isDefault)
              defaultValue[question?.fieldName] = registrationValue["jobTitle"]
                ? {
                    id: registrationValue["jobTitle"],
                    name: registrationValue["jobTitle"],
                  }
                : { id: null, name: "" };
            break;
          }
          case RegistrationFieldType.COMPANY: {
            if (question?.isDefault)
              defaultValue[question?.fieldName] = registrationValue["company"];
            break;
          }
          case RegistrationFieldType.INDUSTRY: {
            if (question?.isDefault)
              defaultValue[question?.fieldName] = registrationValue["industry"];
            break;
          }

          case RegistrationFieldType.JOB_FUNCTION: {
            if (question?.isDefault)
              defaultValue[question?.fieldName] =
                registrationValue["jobFunction"];
            break;
          }
          case RegistrationFieldType.ADDRESS: {
            if (question?.isDefault)
              defaultValue[question?.fieldName] = registrationValue["location"]
                ? {
                    id: registrationValue["location"],
                    name: registrationValue["location"],
                  }
                : { id: null, name: "" };
            break;
          }
          case RegistrationFieldType.POSTAL_CODE: {
            if (question?.isDefault)
              defaultValue[question?.fieldName] = registrationValue["postCode"];
            break;
          }

          case RegistrationFieldType.TERM_AND_CONDITION: {
            // defaultValue[question?.fieldName] = []
            defaultValue[question?.fieldName] =
              signupAnswers &&
              termsAndConditionQuestionInDefaultPage &&
              index === 0
                ? getDefaultTermsConditionValue(
                    signupAnswers[question?.fieldName],
                    question?.fieldName
                  )
                : [];
            break;
          }

          default:
            defaultValue[question?.fieldName] = signupAnswers
              ? signupAnswers[question?.fieldName] || null
              : null;
        }
      });
    });
    return defaultValue;
  };

  useEffect(() => {
    if (registrationPages?.length && registrationValue) {
      let defaultValue: any = {};
      if (registrationPages?.length && registrationValue) {
        defaultValue = getDefaultValues();
      }

      reset(defaultValue);
    }
  }, [registrationPages, registrationValue]);

  useEffect(() => {
    const allFieldsIds: any = {};
    const onlyRequiredFieldsIds: any = {};

    Object.keys(questionPerPage).forEach(
      (pageId: string, pageIndex: number) => {
        if (questionPerPage[pageId].length) {
          questionPerPage[pageId]?.forEach(
            (questionObj: any, index: number) => {
              questionObj?.obj.forEach((question: RegistrationForm) => {
                if (!allFieldsIds[pageId]) {
                  allFieldsIds[pageId] = [];
                }
                if (!onlyRequiredFieldsIds[pageId]) {
                  onlyRequiredFieldsIds[pageId] = [];
                }
                if (question.isRequired) {
                  onlyRequiredFieldsIds[pageId].push(question?.fieldName);
                }
                allFieldsIds[pageId].push(question?.fieldName);
              });
            }
          );
        }

        // if  page is empty
        else {
          if (!allFieldsIds[pageId]) {
            allFieldsIds[pageId] = [];
          }

          if (!onlyRequiredFieldsIds[pageId]) {
            onlyRequiredFieldsIds[pageId] = [];
          }
        }
      }
    );

    setRequiredQuestionsIds(onlyRequiredFieldsIds);
    setAllPagesQuestionsIds(allFieldsIds);
  }, [questionPerPage]);

  const getAllRegistrationPages = () => {
    // if (!isRegistered && signupUser?.eventRegistration !== null && !event?.eventQuestionPage?.isRequired) {
    if (!isRegistered && signupUser?.eventRegistration !== null) {
      return registrationPages?.slice(1);
    }

    return registrationPages;
  };

  const getDefaultTermsConditionValue = (
    fieldValue: any,
    fieldName: string
  ) => {
    if (!isRegistered && signupUser?.eventRegistration !== null) {
      return (
        (fieldValue &&
          typeof fieldValue === "object" &&
          fieldValue?.includes(fieldName)) ||
        false
      );
    }

    return fieldValue || [];
  };

  useEffect(() => {
    const output: Output = {};
    let termsAndConditionPerPage: any = [];

    if (registrationPages.length && registrationValue) {
      const allRegistrationPages = getAllRegistrationPages();
      // check if the comapny and industry exist then skip default question
      allRegistrationPages?.forEach((item, pageIndex) => {
        const pageId = item.id;
        const { eventRegistrationFields } = item;

        const pageContent: Output[string] = [];
        let currentRoomId: string | null = null;
        let currentRoomFields: RegistrationForm[] = [];
        let roomObj: { [key: string]: RegistrationForm[] } = {};
        let currentDefaultFields: any = [];
        let allTermsAndCondition: any = [];

        // if (eventRegistrationFields?.length) {
          eventRegistrationFields?.forEach((question: any, index: number) => {
            if (question?.room) {
              if (
                currentRoomId === question.room.id &&
                currentDefaultFields.length === 0
              ) {
                if (
                  question?.fieldType !==
                  RegistrationFieldType.TERM_AND_CONDITION
                )
                  currentRoomFields.push(question);
                else allTermsAndCondition.push(question);
              } else {
                if (currentRoomFields.length > 0) {
                  if (
                    question?.fieldType ===
                    RegistrationFieldType.TERM_AND_CONDITION
                  ) {
                    allTermsAndCondition[0].registrationOptionFields =
                      allTermsAndCondition;
                    termsAndConditionPerPage.push(allTermsAndCondition[0]);
                    currentRoomFields.push(allTermsAndCondition[0]);
                  }
                  roomObj = { ...roomObj, [currentRoomId!]: currentRoomFields };
                  if (
                    Object.keys(roomObj)?.includes(question?.room?.id) &&
                    currentRoomId !== question?.room?.id
                  ) {
                    pageContent.push({
                      type: "room",
                      obj: roomObj,
                      tabs: [currentRoomId!],
                    });
                    roomObj = {};
                    currentRoomFields = [];
                  } else {
                    currentRoomFields = [];
                  }
                }

                if (currentDefaultFields.length > 0) {
                  if (Object.keys(roomObj).length) {
                    pageContent.push({
                      type: "room",
                      obj: roomObj,
                      tabs: [currentRoomId!],
                    });
                    roomObj = {};
                    currentRoomFields = [];
                  }
                  if (
                    question?.fieldType ===
                    RegistrationFieldType.TERM_AND_CONDITION
                  ) {
                    allTermsAndCondition[0].registrationOptionFields =
                      allTermsAndCondition;
                    termsAndConditionPerPage.push(allTermsAndCondition[0]);
                    currentDefaultFields.push(allTermsAndCondition[0]);
                  }
                  pageContent.push({
                    type: "default",
                    obj: Object.assign([], currentDefaultFields),
                  });
                  currentDefaultFields = [];
                }

                currentRoomId = question.room.id;
                currentRoomFields.push(question);
              }
            } else {
              if (
                question?.fieldType !== RegistrationFieldType.TERM_AND_CONDITION
              )
                currentDefaultFields.push(question);
              else allTermsAndCondition.push(question);
            }
          });

          if (currentRoomFields.length > 0) {
            if (allTermsAndCondition.length > 0) {
              allTermsAndCondition[0].registrationOptionFields =
                allTermsAndCondition;
              termsAndConditionPerPage.push(allTermsAndCondition[0]);
              currentRoomFields.push(allTermsAndCondition[0]);
            }

            roomObj = { ...roomObj, [currentRoomId!]: currentRoomFields };
            pageContent.push({
              type: "room",
              obj: roomObj,
              tabs: [currentRoomId!],
            });
          }

          if (
            currentDefaultFields.length > 0 ||
            allTermsAndCondition.length > 0
          ) {
            if (allTermsAndCondition.length > 0) {
              allTermsAndCondition[0].registrationOptionFields =
                allTermsAndCondition;
              termsAndConditionPerPage.push(allTermsAndCondition[0]);
              currentDefaultFields.push(allTermsAndCondition[0]);
            }
            pageContent.push({
              type: "default",
              obj: Object.assign([], currentDefaultFields),
            });
          }
          output[pageId] = pageContent;
        // }
      });
      setTermAndConditionAllPages(termsAndConditionPerPage);
      setQuestionPerPage(output);
    }
  }, [registrationPages, registrationValue, isRegistered]);

  // check commpany and industry value should not be undefined

  const isCompanyAndIndustry = useCallback(() => {
    return registrationValue["companyId"] && registrationValue["industryId"];
  }, [registrationValue]);

  // on last step submit form
  const formSubmit = useCallback(() => {
    const pageIds: string[] = Object.keys(questionPerPage);

    if (
      pageIds?.length &&
      currentRegistrationStep === pageIds?.length &&
      !(
        signupUser?.loading ||
        questionPageLoader ||
        !upcomingEvent?.value ||
        registered === null
      )
    ) {
      onSubmit(values);
    }
  }, [currentRegistrationStep, questionPerPage, registrationForm]);

  const memoizedFormSubmit = useMemo(
    () => formSubmit,
    [currentRegistrationStep]
  );

  useEffect(() => {
    memoizedFormSubmit();
  }, [memoizedFormSubmit]);

  useEffect(() => {
    isSubmitted = false;
  }, []);

  useEffect(() => {
    const storedData = sessionStorage.getItem("signupAnswers");
    const signupAnswers =
      storedData && storedData !== "undefined" ? JSON.parse(storedData) : null;
    if (
      isSubmitted &&
      (!signupUser?.errorMessage || signupUser?.errorMessage !== "")
    )
      return;
    // if (registrationForm?.every((fieldValue) => fieldValue?.isDefault) && (!signupUser?.loading && !questionPageLoader)) {
    if (
      registrationPages?.length === 1 &&
      !signupUser?.loading &&
      !questionPageLoader
    ) {
      let defaultValues: any = {};
      if (
        Object.keys(values).every((x) => values[x] === undefined) &&
        (eventRegistration === null || signupAnswers)
      ) {
        defaultValues = getDefaultValues();
      } else {
        defaultValues = values;
      }
      if (Object.keys(defaultValues).length) {
        if (
          upcomingEvent?.value &&
          signupUser?.eventRegistration !== null &&
          !isRegistered &&
          isCompanyAndIndustry()
        ) {
          onSubmit(defaultValues);
        }
      }
    }
  }, [registrationPages, isRegistered, upcomingEvent]);

  useEffect(() => {
    const allPages = Object.keys(questionPerPage);
    const pageId = allPages[currentRegistrationStep];
    if (pageId) {
      const isQuestionFilledPerPage: boolean[] = [];
      if (Object.keys(requiredQuestionsIds).length) {
        // if all fields are not required then skip survey page
        const allFieldsAreNotRequired = Object.values(
          requiredQuestionsIds
        ).every((value) => Array.isArray(value) && value.length === 0);
        setIsSuveryorRegSkipable(allFieldsAreNotRequired || false);

        if (requiredQuestionsIds[pageId]?.length) {
          requiredQuestionsIds[pageId].forEach((questionName: string) => {
            const isFilled =
              values[questionName] && values[questionName] !== "";
            if (isFilled) {
              isQuestionFilledPerPage.push(true);
            } else {
              isQuestionFilledPerPage.push(false);
            }
          });
        } else {
          isQuestionFilledPerPage.push(true);
        }
      }

      if (isQuestionFilledPerPage.every((filled: boolean) => filled)) {
        setRequiredRegistrationQuestionsFilled(true);
      } else {
        setRequiredRegistrationQuestionsFilled(false);
      }
    }
  }, [values]);

  const getValidTermAndCondition = () => {
    const isValid = sessionStorage.getItem("termAndCondition");
    return isValid === "true";
  };

  const incrementInPage = (currentPage: number) => {
    setNextButtonClicked(false);
    setCurrentRegistrationStep(currentPage + 1);
  };

  const pageHandler = (currentPage: any, pageId: any) => {
    if (
      termAndConditionAllPages.length &&
      termAndConditionAllPages?.some(
        (page: any) => page.eventQuestionPageId === pageId
      )
    ) {
      const isValidTermAndCondition = getValidTermAndCondition();
      if (isValidTermAndCondition) {
        incrementInPage(currentPage);
      }
    } else {
      incrementInPage(currentPage);
    }
  };

  // click on next step
  const handleNextRegStep = useCallback(async () => {
    const pageId = getCurrentPage()?.id;
    setNextButtonClicked(true);

    if (pageId) {
      let isValid = false;
      const pageIds: string[] = Object.keys(questionPerPage);

      // const currentPagetickets = questionPerPage[pageId]?.[0]?.obj?.filter((x: any) => x?.fieldType === RegistrationFieldType?.TICKET)
      const currentPage = pageIds.findIndex((pageid) => pageid === pageId);
      isValid = await trigger(allPagesQuestionsIds[pageId]);
      if (isValid) {
        // if (currentPagetickets.length) {
        //     if (eventTickets?.value?.every((ticket: any) => ticket.userTicketPurchased > 0)) {
        //         pageHandler(currentPage, pageId)
        //     }
        // }
        // else {
        //     pageHandler(currentPage, pageId)
        // }
        pageHandler(currentPage, pageId);
      }
    }
  }, [currentRegistrationStep, allPagesQuestionsIds]);

  const handleSkipPage = async () => {
    const pageId = getCurrentPage()?.id;
    if (pageId) {
      let isValid = false;
      const pageIds: string[] = Object.keys(questionPerPage);
      isValid = await trigger(allPagesQuestionsIds[pageId]);
      if (isValid) {
        setCurrentRegistrationStep(pageIds.length);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleNextRegStep,
    handleSkipPage,
  }));

  const getCurrentPage = useCallback(() => {
    const allPages = Object.keys(questionPerPage);
    const currentPageId = allPages[currentRegistrationStep];

    if (currentPageId) {
      const findCurrentPage = registrationPages.find(
        (page) => page?.id === currentPageId
      );
      getCurrentRegistrationPage(findCurrentPage, allPages);
      setCurrentPageFontColor(findCurrentPage?.fontColor);
      setCurrentPageBackgroundColor(findCurrentPage?.secondaryBackgroundColor);
      setCurrentPageTitleDescPosition(findCurrentPage?.titleDescPosition);
      setCurrentPagePrimaryBgColor(findCurrentPage?.backgroundColor);
      setCurrentPageHeaderColor(findCurrentPage?.headerColor);
      return findCurrentPage || {};
    }
  }, [questionPerPage, currentRegistrationStep]);

  useEffect(() => {
    getCurrentPage();
  }, [currentRegistrationStep, questionPerPage]);
  return signupUser?.loading ||
    questionPageLoader ||
    upcomingEvent?.loading ||
    !upcomingEvent?.value ||
    registered === null ? (
    <Stack
      sx={{
        direction: "column",
        // height: "100%",
        height: "calc(100 % - 215px)",
        "@media (max-width: 768px)": {
          height: "68%",
        },
        "@media (min-width: 768px)": {
          height: "80%",
        },
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress color="info" />
    </Stack>
  ) : (
    <Stack
      sx={{
        pl: "25px",
        pr: "15px",
        pb: "2px",
        overflowY: "scroll",
        height: "100% !important",
        "--scrollbar-color":
          currentPagePrimaryBgColor || primaryBackgroundColor,
      }}
      className={
        event?.websiteTemplate === "spectre"
          ? "custom-scroll-spectre"
          : "custom-scroll"
      }
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!showMatchUsers && (
          <>
            {Object.keys(questionPerPage)?.map(
              (page: any, _pageIndex: number) =>
                currentRegistrationStep === _pageIndex && (
                  <>
                    {!questionPageLoader ? (
                      !questionPerPage[page]?.length ? (
                        <Stack
                          sx={{
                            direction: "row",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button onClick={() => {}}>
                            No Questions on this page
                          </Button>
                        </Stack>
                      ) : (
                        questionPerPage[page]?.map(
                          (questiontype: any, index: number) => {
                            return (
                              <div key={index} style={{ paddingTop: "20px" }}>
                                {index !== 0 && (
                                  <Divider
                                    sx={{ boder: "1px", borderStyle: "dashed" }}
                                  />
                                )}
                                {/*  default questions */}
                                {questiontype?.obj?.map(
                                  (question: any, index: number) => (
                                    <Stack
                                      direction="column"
                                      // spacing={1}
                                      key={question?.id}
                                    >
                                      {registrationQuestion(
                                        question,
                                        index,
                                        questiontype?.obj?.length
                                      )}
                                    </Stack>
                                  )
                                )}
                              </div>
                            );
                          }
                        )
                      )
                    ) : (
                      <Stack
                        sx={{
                          direction: "column",
                          height: "calc(100 % - 215px)",
                          "@media (max-width: 768px)": {
                            height: "68%",
                          },
                          "@media (min-width: 768px)": {
                            height: "80%",
                          },
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress color="info" />
                      </Stack>
                    )}
                  </>
                )
            )}
          </>
        )}
      </FormProvider>
      {openStripeModal?.open && openStripeModal?.ticketId && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={openStripeModal}
          onClose={() => setOpenStripeModal({ open: false, ticketId: null })}
        >
          {stripePayment()}
        </Dialog>
      )}
    </Stack>
  );
}

export default forwardRef(EventRegistrationFormNew);
