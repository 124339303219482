/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import * as Yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCompanyProfilePreview } from 'src/store/company/profile-preview/actions';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { updateProfile } from 'src/store/company/profile-update/actions';
import { createCompany } from 'src/store/company/profile-create';
import { toast } from 'react-toastify';
import { CompanyService } from 'src/services';
// import { uniqBy } from 'lodash';
import classNames from 'classnames';
import { Button } from '../../../shared-components/V2';
import { IAppState } from '../../../store';
import CompanyTabs from './CompanyTabs';
import BasicDetails from './company-basic-details';
import Capability from './company-capability';
import Targets from './company-targets';
import ComapnyAuthCheck from './company-auth-check';
import {
  CompanyTab, KEY_DIFFERENTIATORS_ANSWERS, MARKET_TRENDS_ANSWERS, PRIMARY_PAIN_POINT_ANSWERS, TECHNOLOGY_UNDERPINS_ANSWERS,
  capabilitySurveyFieldsName
} from './company-enum';
import ComapnyVerifyChanges from './company-verify-changes';
import ProductTab from './Products';

interface ICompanyDetailsProps {
  companyIdOrSlug: string;
  coverUrl: string;
  logoUrl: string;
  setIsCompanyUpdate: (p: boolean) => void;
  isCompanyUpdate: boolean;
}

type CapabilitySurveyFieldKeys = keyof typeof capabilitySurveyFieldsName;

const initialValues = {
  name: '',
  slug: '',
  tagline: '',
  targetAccounts: [],
  companySize: null,
  companySpecialities: [],
  description: '',
  headquarter: null,
  industry: null,
  interestIndustries: [],
  keyDifferentiators: [],
  keyDifferentiators_others: '',
  marketTrends: [],
  marketTrends_other: '',
  personaInterest: [],
  primaryPainPoint: [],
  primaryPainPoint_other: '',
  products: [],
  secondaryCapabilities: [],
  targetDepartments: [],
  targetHeadcounts: [],
  technologyUnderpins: [],
  technologyUnderpins_other: [],
  verify: false,
  website: '',
  admins: []
};

export const CompanyDetails = ({
  companyIdOrSlug,
  coverUrl,
  logoUrl,
  setIsCompanyUpdate,
  isCompanyUpdate,
}: ICompanyDetailsProps) => {
  const companyId = companyIdOrSlug;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const submitRef = useRef<HTMLFormElement>(null);
  const [activeTab, setActiveTab] = useState<string>(CompanyTab.BASICDETAILS);
  const [createProduct, setCreateProduct] = useState(false);
  const [currentTab, setCurrentTab] = useState<string | null>();
  const [showUpdateVerifyModal, setUpdateVerifyModal] = useState<boolean>(false);
  const [showChangesVerify, setShowChangesVerify] = useState<boolean>(false);
  const [companyProducts, setCompanyProducts] = useState<any[]>([]);

  const {
    companyProfilePreview,
  } = useSelector((state: IAppState) => state);
  const companyService: CompanyService = new CompanyService();

  const defaultValues = useMemo(
    () => {
      if (!companyId) return initialValues;
      const company = companyProfilePreview?.value;
      const capabilitySurvey = company?.capabilitySurvey || [];
      const processedCapabilitySurvey = (capabilitySurvey || [])?.reduce((acc, entry) => {
        Object.keys(entry).forEach(category => {
          const categoryData = entry[category];
          if (categoryData) {
            const values = Object.entries(categoryData)
              .filter(([key]) => key !== 'OTHERS')
              .map(([, value]) => value);
            const fieldName = capabilitySurveyFieldsName[category as CapabilitySurveyFieldKeys];
            if (fieldName) {
              acc[fieldName] = values;
              acc[`${fieldName}_other`] = categoryData.OTHERS || '';
            }
          }
        });
        return acc;
      }, {} as Record<string, any>);
      return {
        name: company?.name || null,
        tagline: company?.tagline || '',
        slug: company?.slug || '',
        description: company?.description || '',
        headquarter: company?.headquarter ? { id: company?.headquarter, name: company?.headquarter } : null,
        website: company?.website || '',
        companySpecialities: company?.companySpecialities || [],
        secondaryCapabilities: company?.secondaryCapabilities || [],
        timeline: company?.timeline || 3,
        personaInterest: company?.personaInterest || [],
        interestIndustries: company?.interestIndustries || [],
        targetAccounts: company?.targetAccounts || [],
        targetHeadcounts: company?.targetHeadcounts || [],
        targetDepartments: company?.targetDepartments || [],
        industry: company?.industry || null,
        companySize: company?.companySize || null,
        admins: company?.admins || [],
        verify: false,
        marketTrends: processedCapabilitySurvey?.marketTrends || [],
        marketTrends_other: processedCapabilitySurvey?.marketTrends_other,
        technologyUnderpins: processedCapabilitySurvey?.technologyUnderpins || [],
        technologyUnderpins_other: processedCapabilitySurvey?.technologyUnderpins_other,
        keyDifferentiators: processedCapabilitySurvey?.keyDifferentiators || [],
        keyDifferentiators_other: processedCapabilitySurvey?.keyDifferentiators_other,
        primaryPainPoint: processedCapabilitySurvey?.primaryPainPoint || [],
        primaryPainPoint_other: processedCapabilitySurvey?.primaryPainPoint_other,
        products: company?.products || [],
      };
    },
    [companyProfilePreview, companyId],
  );

  const validationSchema = useMemo(() => {
    switch (activeTab) {
      case CompanyTab.BASICDETAILS:
        return Yup.object().shape({
          name: Yup.string().required('Required Field'),
          slug: Yup.string()
            .matches(/^[A-Za-z0-9-]+$/, 'Only letters, numbers, and hyphens are allowed')
            .required('Required Field'),
          website: Yup.string().required('Required Field'),
          headquarter: Yup.object().required('Required Field'),
          companySize: Yup.object().required('Required Field'),
          industry: Yup.object().required('Required Field'),
        });

      case CompanyTab.CAPABILITIES:
        return Yup.object().shape({
        });

      default:
        return Yup.object().shape({
          // Default schema if needed
        });
    }
  }, [activeTab]);

  const methods = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    watch, formState: { isValid, isDirty }
  } = methods;
  const values = watch();

  const getEnumKeyFromValue = (enumObj: any, value: string): string | undefined => Object.keys(enumObj).find(key => enumObj[key] === value);

  const getCompanyCapabilitySurvey = () => {
    const marketTrendsKeys = values?.marketTrends.map((value: string) => getEnumKeyFromValue(MARKET_TRENDS_ANSWERS, value));
    const technologyUnderpinsKeys = values?.technologyUnderpins.map((value: string) => getEnumKeyFromValue(TECHNOLOGY_UNDERPINS_ANSWERS, value));
    const keyDifferentiatorsKeys = values?.keyDifferentiators.map((value: string) => getEnumKeyFromValue(KEY_DIFFERENTIATORS_ANSWERS, value));
    const primaryPainPointKeys = values?.primaryPainPoint.map((value: string) => getEnumKeyFromValue(PRIMARY_PAIN_POINT_ANSWERS, value));

    const capabilitySurvey = {
      MARKET_TRENDS: {
        ...Object.fromEntries(
          marketTrendsKeys.map((key: string) => [key, MARKET_TRENDS_ANSWERS[key as keyof typeof MARKET_TRENDS_ANSWERS]])
        ),
        OTHERS: values?.marketTrends_other,
      },
      TECHNOLOGY_UNDERPINS: {
        ...Object.fromEntries(
          technologyUnderpinsKeys.map((key: string) => [key, TECHNOLOGY_UNDERPINS_ANSWERS[key as keyof typeof TECHNOLOGY_UNDERPINS_ANSWERS]])
        ),
        OTHERS: values?.technologyUnderpins_other,
      },
      KEY_DIFFERENTIATORS: {
        ...Object.fromEntries(
          keyDifferentiatorsKeys.map((key: string) => [key, KEY_DIFFERENTIATORS_ANSWERS[key as keyof typeof KEY_DIFFERENTIATORS_ANSWERS]])
        ),
        OTHERS: values?.keyDifferentiators_other,
      },
      PRIMARY_PAIN_POINT: {
        ...Object.fromEntries(
          primaryPainPointKeys.map((key: string) => [key, PRIMARY_PAIN_POINT_ANSWERS[key as keyof typeof PRIMARY_PAIN_POINT_ANSWERS]])
        ),
        OTHERS: values?.primaryPainPoint_other,
      }
    };

    // eslint-disable-next-line no-unused-vars
    const capabilityQuestions = Object.entries(capabilitySurvey).filter(([key, value]) => Object.keys(value).length > 1 || value.OTHERS).map(([key, value]) => ({
      [key]: value
    }));

    return capabilityQuestions;
  };

  const onCreateSuccess = () => {
    toast.success('Company added');
    navigate(`/wall/company/${values?.slug}/edit`);
    setIsCompanyUpdate(false);
    setShowChangesVerify(false);
    setUpdateVerifyModal(false);
  };

  const onUpdateSuccess = async (result?:any) => {
    toast.success('Company updated');
    // navigate(`/wall/company/${values.slug}`);
    setIsCompanyUpdate(false);
    setShowChangesVerify(false);
    setUpdateVerifyModal(false);
    if (showChangesVerify && currentTab) setActiveTab(currentTab!);
    await dispatch(getCompanyProfilePreview(result?.data?.slug || companyId));
    if (result?.data?.slug !== companyId) navigate(`/wall/company/${result?.data?.slug}/edit`);
    setCurrentTab(null);
  };

  const onError = (error: any) => {
    // if (error?.response?.status === 400) {
    setUpdateVerifyModal(false);
    setShowChangesVerify(false);
    setIsCompanyUpdate(false);
    toast.error(error?.response?.data?.message);
    // }
  };

  const onSubmit = (data: any) => {
    setIsCompanyUpdate(true);
    const cover = coverUrl;
    const logo = logoUrl;
    const adminSelectedEmails = data?.admins?.map((item: any) => item.email);
    const capabilitySurvey = getCompanyCapabilitySurvey();
    const dataToSubmit: any = {
      name: data?.name,
      slug: data?.slug,
      tagline: data?.tagline,
      description: data?.description,
      companySizeId: data?.companySize?.id,
      industryId: data?.industry?.id,
      website: data?.website,
      cover,
      logo,
      city: data?.headquarter?.city,
      country: data?.headquarter?.country,
      headquarter: data.headquarter?.name,
      capabilitySurvey,
      specialities: data.companySpecialities.map((specialty: any) => specialty.name ?? specialty),
      secondaryCapabilities: data?.secondaryCapabilities.map(
        (capability: any) => capability.name ?? capability,
      ),
      interestIndustries: data?.interestIndustries?.map(
        (industry: any) => industry.name ?? industry,
      ),
      targetDepartments: data?.targetDepartments,
      targetHeadcounts: data?.targetHeadcounts,
      timeline: data?.timeline,
      targetAccounts: data?.targetAccounts?.map((account: any) => account?.id),
      personaInterest: data?.personaInterest

    };

    if (companyId) {
      dispatch(
        updateProfile(
          companyProfilePreview.value?.id,
          dataToSubmit,
          adminSelectedEmails,
          onUpdateSuccess,
          onError
        )
      );
    } else {
      dispatch(createCompany(dataToSubmit, adminSelectedEmails, onCreateSuccess, onError));
    }
  };

  const handleTabCahnge = (tab: string) => {
    if (!isDirty) {
      setActiveTab(tab);
      navigate({ search: '' });
    } else {
      setCurrentTab(tab);
      setShowChangesVerify(true);
    }
  };

  const handleDialogAction = (type: string) => {
    switch (type) {
      case 'stay':
        setShowChangesVerify(false);
        break;
      case 'save':
        methods.handleSubmit(onSubmit)();
        break;
      default:
        break;
    }
  };

  const getCompnayProductsData = useCallback(async () => {
    try {
      const response = await companyService.getCompanyProducts(
        companyProfilePreview.value?.id!,
        200,
        1
      );
      if (response) {
        setCompanyProducts(response?.data || []);
      }
    } catch (error) {
      console.error('getCompnayProductsData():', error);
    }
  }, [companyProfilePreview.value?.id]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const createProductParam = queryParams.get('createProduct');

    if (createProductParam === 'true') {
      setActiveTab(CompanyTab.PRODUCTS);
      setCreateProduct(true);
    } else {
      setActiveTab(CompanyTab.BASICDETAILS);
      setCreateProduct(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (companyId && activeTab !== CompanyTab.PRODUCTS) {
      methods.reset(defaultValues);
    } else if (activeTab !== CompanyTab.PRODUCTS) {
      methods.reset(initialValues);
      setActiveTab(CompanyTab.BASICDETAILS);
    }
  }, [defaultValues, methods, companyId, activeTab]);

  useEffect(() => {
    if (companyId) {
      const populateFormFields = async () => dispatch(getCompanyProfilePreview(companyId));
      populateFormFields();
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    const element = document.querySelector('.hide-on-event') as HTMLElement;
    if (element) {
      element.style.display = 'none';
    }
  }, []);

  useEffect(() => {
    if (companyProfilePreview.value?.id) {
      getCompnayProductsData();
    }
  }, [companyProfilePreview.value?.id, getCompnayProductsData]);

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="bg-white mx-auto create-post-card !rounded-t-[20px] !rounded-b-[20px] overflow-hidden mt-[10px]">

            <div className="flex flex-col p-[33px]">
              <CompanyTabs handleTabCahnge={handleTabCahnge} activeTab={activeTab} companyId={companyId!} />
              {activeTab === CompanyTab.BASICDETAILS && (
                <BasicDetails />
              )}

              {activeTab === CompanyTab.CAPABILITIES && (
                <Capability values={values} />
              )}

              {activeTab === CompanyTab.TARGETS && (
                <Targets />
              )}

              {activeTab === CompanyTab.PRODUCTS && (
                <ProductTab companyId={companyProfilePreview.value?.id!} companyProducts={companyProducts} getCompnayProductsData={getCompnayProductsData} createProduct={createProduct} />
              )}

              {/* Conditionally render the button based on activeTab and AllProducts visibility */}
              {activeTab !== CompanyTab.PRODUCTS && (
                <div className="flex justify-end mt-[20px]">
                  <Button
                    size="x-large"
                    onClick={() => setUpdateVerifyModal(true)}
                    text="Save &amp; Publish"
                    className="bg-[#0031FF] hover:bg-[#2e6eff] text-white tracking-wide h-[35px] w-[168px] !text-[14px] !font-medium"
                  />
                </div>
              )}
              {/* Button visibility based on the AllProducts state */}
            </div>

          </div>
          <div
            className={classNames('absolute top-[0px]', {
              'right-[0px]': activeTab === CompanyTab.PRODUCTS,
              'right-[180px]': activeTab !== CompanyTab.PRODUCTS
            })}
          >
            <Button
              size="x-large"
              onClick={() => navigate(`/wall/company/${companyProfilePreview?.value?.slug}`)}
              text="Go to Comapny Profile"
              className="hover:bg-[#C4CDD5] bg-[#ffffff]  h-[35px] w-[190px] text-center text-[#000000] text-[14px] font-semibold rounded-[5px] flex items-center justify-center cursor-pointer"

            />
          </div>
          <div className=" absolute right-0 top-[0px]">
            {activeTab !== CompanyTab.PRODUCTS && (
              <Button
                size="x-large"
                onClick={() => setUpdateVerifyModal(true)}
                text="Save &amp; Publish"
                className="bg-[#0031FF] text-white tracking-wide h-[35px] w-[168px] !text-[14px] !font-medium"
              />
            )}
          </div>
          <AlertModal
            className="w-[632px] !py-0 bg-[#F5F7FC]"
            closeIconClass="top-[18px] right-[11px]"
            isCloseIcon
            isOpen={showUpdateVerifyModal}
            onClose={() => setUpdateVerifyModal(false)}
          >
            <ComapnyAuthCheck
              submitRef={submitRef}
              closeModal={() => setUpdateVerifyModal(false)}
              isCompanyUpdate={isCompanyUpdate}
              isValid={isValid}
            />
          </AlertModal>
          <AlertModal
            className="w-[632px] !py-0 bg-[#F5F7FC]"
            closeIconClass="top-[18px] right-[11px]"
            isCloseIcon
            isOpen={showChangesVerify}
            onClose={() => setShowChangesVerify(false)}
          >
            <ComapnyVerifyChanges
              submitRef={submitRef}
              closeModal={() => setShowChangesVerify(false)}
              isCompanyUpdate={isCompanyUpdate}
              isValid={isValid}
              onStay={() => {
                handleDialogAction('stay');
              }}
              onSave={() => {
                handleDialogAction('save');
              }}
            />
          </AlertModal>
        </form>
      </FormProvider>
    </div>
  );
};

export default CompanyDetails;
