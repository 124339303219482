// import { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { IAppState } from '../../../../store';
import EventSignup from '../../registration/event-signup/event-signup';
import WebsiteEventQuickLinks from '../website-event-quicklinks';

export const UserRegistrationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const eventSlug = location?.pathname?.split('/')[2];

  useEffect(() => {
    const eventStep = sessionStorage.getItem('eventRegStep');
    if (eventStep === '1') {
      navigate(`/event/${eventSlug}/spectre/registration`);
    } else if (eventStep === '2') {
      navigate(`/event/${eventSlug}/spectre/select-plan`);
    } else if (eventStep === '0') {
      navigate(`/event/${eventSlug}/spectre/signup`);
    } else {
      navigate(`/event/${eventSlug}/spectre`);
    }
  }, [eventSlug, navigate]);

  const changePage = (page: number) => {
    if (page === 0) {
      navigate(`/event/${eventSlug}/spectre/loginorjoin`);
    }
    if (page === 1) {
      navigate(`/event/${eventSlug}/spectre/registration`);
    }
  };

  const onBackEvent = () => {
    navigate(`/event/${eventSlug}/spectre`);
  };

  return (
    <div className="w-full">
      <div className="flex -mb-[30px]">
        <div className="bg-[#2d2d2d] pt-[20px] pr-[40px] pb-[30px] pl-[40px] w-full md:max-w-[60%]">
          <div
            className="w-[122px] h-[32px] rounded-full bg-white text-black text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer mb-[40px]"
            onClick={onBackEvent}
          >
            Back to event
          </div>
          <div className="text-white text-[45px] leading-[45px] helvetica-neue-55-roman">Sit-down</div>
          <div className="text-white text-[21px] leading-[25px] helvetica-neue-55-roman">CIO • CDO • CISO</div>
          <div className="text-white text-[20px] leading-[20px] helvetica-neue-45-light mt-[20px] mb-[10px]">Sign Up</div>
          <div className="mt-[30px]">
            <EventSignup isSpectre isSpectreForm page={1} changePage={changePage} isSignUp />
          </div>
        </div>
        <div className="reg-bg md:w-[40%]" />
      </div>
      <WebsiteEventQuickLinks />
    </div>
  );
};
export default UserRegistrationForm;
