/* eslint-disable max-len */
import { Autoplay, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  useCallback,
  useEffect, useRef, useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { useNavigate } from 'react-router';
// import { Loader } from '../../../shared-components/Loader';
import {
  IconButton,
  Tooltip,
} from '@mui/material';
import RegistrationStepForm from 'src/mui/pages/event/reg-dialog-flow';
import { getTickets } from 'src/store/ticket';
import { EventQuestionPageTypes, useSurveyContext } from 'src/mui/providers/GeneralContext';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { getMinifiedImage, getS3Path } from 'src/mui/utils/image';
import { IAppState } from '../../../store';
import { rgbToHex } from '../../../utils/color-transformation';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { useCurrentUser } from '../../../utils/hooks';
// import { getUpcomingEvent } from '../../../store/events/upcoming-event/actions';
// import { CalendarList } from '../registration/event-components/calendar-list';
// import { GotoList } from '../registration/event-components/goto-list';

export const hideScrollbarY = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

export const EventGallery = () => {
  const location = useLocation();
  // const navigate = useNavigate();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const swiperRefLocal = useRef<any>(null);

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };
  const { eventGallery } = useSurveyContext();

  const [showModal, setShowModal] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('Pause');
  const videoRef: React.RefObject<HTMLVideoElement> = useRef(null);
  const [muteVideo, setMuteVideo] = useState(true);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video?.paused || video?.ended) {
      video.play();
      setTooltipTitle('Pause');
    } else {
      video?.pause();
      setTooltipTitle('Play');
    }
  };

  useEffect(() => {
    videoRef?.current?.play();
  }, []);

  const handleQueryParams = useCallback((search: string) => {
    const getQueryParams = (search: string) => {
      const queryParams = new URLSearchParams(search);
      const params: Record<string, string> = {};
      const entries: [string, string][] = Array.from(queryParams.entries());
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of entries) {
        params[key] = value;
      }
      return params;
    };

    const queryParams = getQueryParams(search);
    const { eventFrom } = queryParams;
    if (eventFrom === 'register') {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    const delay = 500;
    const timer = setTimeout(() => {
      handleQueryParams(location.search);
    }, delay);
    return () => clearTimeout(timer);
  }, [location, handleQueryParams]);

  const event = useSelector((state: IAppState) => state.website.event!);
  const { getQuestionPages, setEventIdParam } = useSurveyContext();

  const { upcomingEvent } = useSelector(
    (state: IAppState) => state
  );
  // const audienceStatus = useSelector((state: IAppState) => state?.upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus);

  const audienceStatus = upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus;

  const { registered } = useSelector(
    (state: IAppState) => state.website
  );

  const isImage = (item: any) => item?.slice(0, 5) === 'image';

  useEffect(() => {
    if (user) {
      // dispatch(getUpcomingEvent(event.id));
      dispatch(getTickets(event.id));
    }
  }, [dispatch, event.id, user]);

  useEffect(() => {
    if (event?.id) {
      setEventIdParam(event?.id!);
      if (user) {
        getQuestionPages(EventQuestionPageTypes.REGISTRATION, event?.id!);
        getQuestionPages(EventQuestionPageTypes.SURVEY, event?.id!);
      }
    }
  }, [event?.id, user]);

  const getBackgroungGradient = () => {
    if (!event?.backgroundDominantColor?.length) return '#0D4552';
    return `${rgbToHex(event?.backgroundDominantColor)}`;
  };

  // const handleRegistration = () => {
  //   navigate(`/event/${event.slug}/spectre/vipreg`);
  // };

  // const handleRegistration = () => {
  //   if (!registered) setShowModal(true);
  //   // if (!registered && event?.isTicketed && user) setOpenTicketModal(true);

  //   if (user && registered) navigate(`/event/${event?.slug}/discussion`);
  //   if (
  //     user
  //     && registered === false
  //     && event?.type === 'invitation'
  //     && audienceStatus === 'accepted'
  //   ) { navigate(`/event/${event?.slug}/discussion`); }
  // };

  // const onLoginToRegister = () => {
  //   window.open('https://members.sit-down.com/auth', '_blank');
  // };

  return (
    <div id="Banner" className="w-full overflow-x-clip">
      <div className="block md:flex">
        <div
          className="w-screen bg-gradient-to-b px-[0px] xl:px-0 relative"
          style={{
            background: `linear-gradient(${getBackgroungGradient()}, transparent)`,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Swiper
            pagination={false}
            ref={swiperRefLocal}
            modules={[Pagination, Autoplay, EffectFade]}
            autoplay={false}
            // autoplay={{
            //   delay: 3000,
            //   disableOnInteraction: true,
            //   pauseOnMouseEnter: true,
            //   stopOnLastSlide: false,
            //   waitForTransition: false
            // }}
            effect="fade"
            className="mySwiper"
          >
            {
              eventGallery.map((item, index) => {
                if (isImage(item?.key)) {
                  return (
                    <SwiperSlide key={`gallery-${index}`}>
                      <img src={getMinifiedImage(item?.key)} alt="" />
                      <div
                        style={{
                          zIndex: 10,
                          backgroundImage: 'linear-gradient(transparent 35%,#000)',
                          position: 'absolute',
                          top: '0%',
                          bottom: '0%',
                          left: '0%',
                          right: '0%',
                        }}
                      />
                    </SwiperSlide>
                  );
                } else {
                  return (
                    <SwiperSlide>
                      <video ref={videoRef} style={{ width: '100%', height: '100%', objectFit: 'cover', }} loop muted={muteVideo} autoPlay>
                        {' '}
                        <source src={getS3Path(item?.key)} type="video/mp4" />
                        {' '}
                        <source src={getS3Path(item?.key)} type="video/webm" />
                        {' '}
                        <track default kind="captions" />
                        {' '}
                      </video>
                      <div
                        style={{
                          // left: 140,
                          position: 'absolute',
                          textAlignLast: 'end',
                          bottom: 100,
                          opacity: 0.8,
                          zIndex: 100000,
                          right: 20,
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Tooltip title={tooltipTitle}>
                          <IconButton onClick={handlePlayPause} sx={{ color: 'white' }}>
                            {tooltipTitle === 'Pause' ? <PauseIcon /> : <PlayArrowIcon />}
                          </IconButton>
                        </Tooltip>

                        <Tooltip title={!muteVideo ? 'mute' : 'unmute'}>
                          <IconButton onClick={() => setMuteVideo(!muteVideo)} sx={{ color: 'white' }}>
                            {muteVideo ? <VolumeOffIcon /> : <VolumeUpIcon />}
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          zIndex: 10,
                          backgroundImage: 'linear-gradient(transparent 35%,#000)',
                          position: 'absolute',
                          top: '0%',
                          bottom: '0%',
                          left: '0%',
                          right: '0%',
                        }}
                      />
                    </SwiperSlide>
                  );
                }
              })
            }
          </Swiper>
          <div
            className="z-10 absolute left-0 bottom-0"
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                maxHeight: '420px',
              }}
            />
            <div className="w-full text-white flex flex-col items-left justify-end rounded-b-[0px] h-[100%] relative">
              <div className="w-full md:pl-[80px] md:pr-0 pr-[20px] pl-[20px] md:pb-[75px] pb-[50px] md:max-w-[560px] max-w-full">
                <div
                  className="flex justify-left items-center w-full sm:w-[454px] mb-[14px] md:px-0"
                >
                  <div
                    className="flex md:items-center items-start justify-left"
                  >
                    <div className="w-[11px] h-[18px] bg-[#88FDCC] mr-[7px]" />
                    <div className="text-[14px] leading-[15px] helvetica-neue-45-light">Sit-Down Events</div>
                  </div>
                </div>
                <div
                  className="flex md:items-center items-start justify-left mb-[14px]"
                >
                  <div className="text-[38px] leading-[42px] helvetica-neue-45-light max-w-[380px] capitalize">
                    Experience Monthly
                    Sit-Downs
                  </div>
                </div>
                <div
                  className="flex md:items-center items-start justify-left"
                >
                  Sit-Down is an invitation-only conference that brings together technology executives from the region’s most influential Enterprise and Government organisations.
                </div>

              </div>
            </div>
          </div>
          {/* <div className="mt-[20px] hidden helvetica-neue-45-light absolute sm:right-[55px] right-[10px] sm:bottom-[55px] bottom-2 z-10">
            <button
              type="button"
              // eslint-disable-next-line max-len
              className={classNames('px-[16px] leading-[18px] text-black bg-white hover:bg-[#333333] hover:text-white tracking-wide rounded-[6px] w-[242px] h-[36px] text-[16px] flex items-center justify-center helvetica-neue-65-normal')}
              onClick={() => onLoginToRegister()}
            >
              <strong className="mr-2.5 helvetica-neue-65-medium leading-[17px]">Member?</strong>
              <div className="w-[120px]">Login to register</div>
            </button>
          </div> */}
        </div>
      </div>
      {
        showModal && (
          <RegistrationStepForm
            eventId={event?.id!}
            user={user}
            open={showModal}
            registered={registered!}
            eventInvitationStatus={audienceStatus!}
            eventType={event?.type!}
            setOpenForm={() => setShowModal(false)}
          />
        )
      }
    </div>
  );
};
