/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resetProfilePicture } from 'src/store/company/profile-picture/actions';
import { resetCoverPhoto } from 'src/store/company/cover-photo/actions';
import { IAppState } from 'src/store';
import { UserNavbar } from '../../../components';
import { resetCompanyProfilePreview } from '../../../store/company/profile-preview/actions';
// import CompanyPagePreview from './company-page-preview';
import CompanyProfileCover from './company-profile-cover';

export const Company = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();

  const locationPathArr = location?.pathname?.match(/\/(\w+)/ig);
  const isEditCompany = locationPathArr && locationPathArr[3] === '/edit';

  const companyProfilePreview = useSelector((state: IAppState) => state.companyProfilePreview);
  const companyPageTitle = isEditCompany ? 'Edit company page' : 'Create company page';
  useEffect(() => {
    if (isEditCompany && params.companyidorslug && !companyProfilePreview.value?.isAdmin) {
      navigate(`/wall/company/${params.companyidorslug!}`);
    }
    return function cleanup() {
      dispatch(resetCompanyProfilePreview());
      dispatch(resetProfilePicture());
      dispatch(resetCoverPhoto());
    };
  }, []);

  return (
    <div className="bg-[#EDF2F5]">
      <UserNavbar />
      <div className="lg:container mx-auto lg:w-[1258px] w-full lg:px-0 px-4 pb-[60px] mt-[30px] relative">
        <div className="text-[#000000] text-[25px] leading-[33px] font-medium mb-[25px]">{companyPageTitle}</div>
        <div className="xl:w-full lg:w-full w-full">
          <CompanyProfileCover setIsLoader={setIsLoader} companyIdOrSlug={params.companyidorslug!} />
        </div>
      </div>
    </div>
  );
};

export default Company;
