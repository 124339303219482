import { useEffect, useState } from 'react';
import { ProgressBar } from './progress-bar';
import { Avatar } from '../../../../shared-components/V2';
import { useCurrentUser, useUserDetails } from '../../../../utils/hooks';

interface Props {
  chartPercent?: number;
  chartColor?: string;
  userProfile?: any;

}

export const ProfileMatchScore = ({ chartPercent, chartColor, userProfile }: Props) => {
  const [chartData, setChartData] = useState({
    datasets: [
      {
        data: [chartPercent, 100 - Number(chartPercent)],
        backgroundColor: [chartColor, '#EDF2F5'],
        borderWidth: 0,
        spacing: 0,
        borderJoinStyle: 'bevel',
        borderRadius: [0, 0],
        rotation: 180,
        cutout: 60,
      },
    ],
  });

  useEffect(() => {
    setChartData({
      datasets: [
        {
          data: [chartPercent, 100 - Number(chartPercent)],
          backgroundColor: [chartColor, '#EDF2F5'],
          borderWidth: 0,
          spacing: 0,
          borderJoinStyle: 'bevel',
          borderRadius: [0, 0],
          rotation: 180,
          cutout: 55,
        },
      ],
    });
  }, [chartColor, chartPercent]);

  const user = useCurrentUser();
  const { avatar } = useUserDetails(user);

  return (
    <div>
      <div className="md:flex flex-row items-center md:w-[380px] w-full m-auto justify-center mb-5">
        <div className="md:w-[105px] w-[200px] md:mx-0 mx-auto">
          <div className="flex flex-col items-center">
            <div>
              <Avatar src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${userProfile?.value?.avatar!}`} alt="" className="rounded-full" size="PROFILEMATCH" />
            </div>
            <div className="text-[15px] h-[21px] text-[#172746] font-semibold text-center w-full line-clamp-1">
              {userProfile?.value?.fullName!}
            </div>
          </div>
        </div>
        <div className="w-[170px] md:mx-3 mx-auto md:my-0 my-4 flex items-center justify-center relative">
          <ProgressBar data={chartData} />
          <div className="absolute text-center">
            {/* <span className="bg-[#144EE6] text-white rounded-5 font-semibold text-[7px] py-1 px-1 w-[80px] h-[20px]">Compatibility Score</span> */}
            <span className="bg-[#144EE6] text-white block rounded-5 font-semibold text-[12px] w-[56px] h-[22px] leading-[22px] mx-auto">Match</span>
            <div className="font-medium text-[34px] text-[#203C6E]">
              {chartPercent?.toFixed(0)}
              %
            </div>
          </div>
        </div>
        <div className="md:w-[105px] w-[200px] md:mx-0 mx-auto">
          <div className="flex flex-col items-center">
            <div>
              <Avatar src={avatar} alt="" className="rounded-full" size="PROFILEMATCH" />
            </div>
            <div className="text-[15px] h-[21px] text-[#172746] font-semibold text-center w-full line-clamp-1">
              You
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
