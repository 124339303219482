/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import ReactHlsPlayer from '@panelist/react-hls-player/dist';
import {
  AudioInputControl,
  MeetingStatus, PreviewVideo, useMeetingManager, useMeetingStatus, VideoInputBackgroundBlurControl, VideoTileGrid
} from 'amazon-chime-sdk-component-library-react';
import classNames from 'classnames';
import Draggable from 'react-draggable';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { Loader } from '../../../shared-components/Loader';
import { IAppState } from '../../../store';

export const LiveCall = () => {
  const { roomId } = useParams();

  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const currentRoom = liveEvent.event?.rooms.find(room => room.id === roomId);

  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();

  const [isLive, setIsLive] = useState(false);
  const [isChimeLoaded, setChimeLoaded] = useState(false);
  const videoPlayerRef = useRef<any>(null);

  useEffect(() => {
    meetingManager.subscribeToAudioVideo((e:any) => {
      if (e && !isChimeLoaded) {
        setChimeLoaded(true);
      }
    });
  }, []);

  useEffect(() => {
    if (meetingManager && meetingStatus === MeetingStatus.Succeeded) {
      setIsLive(true);
    } else {
      setIsLive(false);
    }
  }, [meetingManager, meetingStatus]);

  return (
    <>
      {isLive ? (
        <div className="w-full h-full">
          <VideoTileGrid layout="featured" className="w-full h-full" />
          <Draggable defaultClassName="">
            <div className="absolute right-0 top-[80px] cursor-move rounded-xl overflow-hidden border-gray-700 border-4">
              <div className="w-full h-full absolute flex justify-center items-center text-xs">
                <p className="bg-rose-800 rounded-xl p-2 opacity-90 text-white">Live - 15s delay</p>
              </div>
              <ReactHlsPlayer
                autoPlay
                playerRef={videoPlayerRef}
                id="video"
                style={{
                  width: '180px',
                  height: '120px',
                  backgroundColor: 'black',
                }}
                src={currentRoom?.primaryHlsUrl!}
                controls={false}
                muted
              />
            </div>
          </Draggable>
        </div>
      )
        : (
          <div className="flex flex-col h-full w-full justify-center items-center gap-4 bg-gray-300">
            <div className="text-md">
              You are waiting in Green Room !!!
            </div>
            <div className="rounded-xl overflow-hidden">
              <div className={classNames('h-full w-full flex flex-col gap-2 justify-center items-center bg-gray-300', {
                hidden: isChimeLoaded
              })}
              >
                <span className="text-xs">Initializing Audio/Video resouces</span>
                <Loader />
              </div>
              <PreviewVideo className="absolute" css="{height: 320px; width: 460px;}" />
            </div>
            <div className={classNames('flex flex-row', {
              hidden: !isChimeLoaded
            })}
            >
              <AudioInputControl />
              <VideoInputBackgroundBlurControl />
            </div>
            <span className="text-xs text-amber-700 font-semibold">Note: Video will be disabled by default on join.</span>
          </div>
        )}
      <div />
    </>
  );
};
