/* eslint-disable no-console */
import {
  Box,
  Card,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { TablePaginationCustom, useTable } from 'src/mui/components/table';
import { CompanyService } from 'src/services';
import { useNavigate, useParams } from 'react-router';
import { Loader } from 'src/shared-components/Loader';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { DeletePost } from 'src/components/posts/delete-post';
import { toast } from 'react-toastify';
import TableHeadCustom from './TableHeadCustom';
import CompaniesTableRow from './CompaniesTableRow';

const ListAccountCompanies = () => {
  const { accountId, companyId } = useParams();
  const navigate = useNavigate();
  const [accountFilesData, setAccountFilesData] = useState<any[]>([]);
  const [totalRow, setTotalRow] = useState<number>(0);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const {
    dense,
    page,
    rowsPerPage,
    onChangeDense,
    onChangePage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'startTime',
    defaultOrder: 'asc',
    defaultRowsPerPage: 10,
  });

  const fetchAccountFiles = useCallback(async (id: string) => {
    try {
      setIsLoader(true);
      const companyService = new CompanyService();
      const response: any = await companyService.getCompanyAccountFileList(
        id,
        page + 1,
        rowsPerPage
      );
      setAccountFilesData(response?.data);
      setTotalRow(response.totalRow);
      setIsLoader(false);
    } catch (err) {
      console.error('Failed to fetch account files:', err);
      setIsLoader(false);
    } finally {
      setIsLoader(false);
    }
  }, [page, rowsPerPage]);

  const handleStarClick = async (id: string, currentIsStarred?: boolean | null, notes?: string) => {
    try {
      const companyService = new CompanyService();
      let body = {};
      if (currentIsStarred !== undefined) {
        body = {
          isStarred: !currentIsStarred || false,
        };
      }
      if (notes) {
        body = {
          notes: notes || ''
        };
      }

      const response: any = await companyService.companyStar(id, body);

      if (response?.data && currentIsStarred !== undefined) {
        setAccountFilesData((prevData) => prevData.map((company) => (company.id === id ? { ...company, isStarred: !currentIsStarred } : company)));
      }
    } catch (err) {
      console.error('Failed to update star status:', err);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleDeleteCompany = async () => {
    try {
      const companyService = new CompanyService();
      if (selected?.length > 0) {
        const body = {
          ids: selected
        };
        const response = await companyService.removeBulkCompanies(body);
        if (response?.message) {
          setAccountFilesData((prevData) => prevData.filter((company) => !selected.includes(company.id)));
          setSelected([]);
        }
      }
    } catch (error:any) {
      toast.error(error?.message || 'Something went wrong. Please try again.');
      console.error('Failed to delete company:', error);
    }
    setDeleteModal(false);
  };

  useEffect(() => {
    if (accountId) {
      fetchAccountFiles(accountId);
    }
  }, [accountId, fetchAccountFiles]);

  const TABLE_HEAD = [
    { id: 'company', label: 'Company', align: 'left' },
    { id: 'notes', label: 'Notes', align: 'left' },
    { id: 'lead', label: 'Lead Recommendations', align: 'left' },
    { id: 'meet', label: 'Meet', align: 'left' },
  ];

  const showCompaniesAccountsList = (company: any) => {
    if (company?.company) {
      navigate(`/wall/company/${company?.company?.slug}`);
    } else {
      toast.error('Missing company slug!');
    }
  };
  const backToAccountList = (id: any) => {
    if (id) {
      navigate(`/wall/company/${companyId}/accounts-list`);
    }
  };

  const showEvent = (event?: any) => {
    if (event) {
      navigate(`/event/${event?.slug}/discussion`);
    }
  };

  const showLead = (lead: any) => {
    if (lead) {
      navigate(`/wall/${lead?.slug}?tab=about`);
    }
  };

  return !isLoader ? (
    <Stack sx={{ width: '100%' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Stack sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '20px'
        }}
        >
          <Typography sx={{
            fontFamily: 'Poppins-500', fontWeight: '500', fontSize: '25px', lineHeight: '33px'
          }}
          >
            Companies List
          </Typography>
          <Stack direction="row" alignItems="center" gap="10px">
            <button
              type="button"
              className="rounded-5 px-[10px] h-[35px] w-[160px] bg-[#0049EF] hover:bg-dark-blue-3 text-sm leading-5 text-white font-semibold"
              onClick={backToAccountList}
            >
              Back to Account List
            </button>

            {selected?.length > 0 && (
            <IconButton onClick={() => setDeleteModal(true)}>
              <DeleteIcon color="error" style={{ cursor: 'pointer', width: '30px', height: '30px' }} />
            </IconButton>
            )}

          </Stack>
        </Stack>
      </Box>
      <Stack>
        <Card>
          <Divider />
          <>
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Table sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  headLabel={TABLE_HEAD}
                  rowCount={accountFilesData?.length}
                  showAllCheckbox
                  numSelected={selected.length}
                  onSelectAllRows={(checked) => onSelectAllRows(
                    checked,
                    accountFilesData?.map((row) => row.id),
                  )}
                />

                <TableBody>
                  {accountFilesData.map((rowData) => (
                    <CompaniesTableRow
                      fetchAccountFiles={fetchAccountFiles}
                      onStarClick={handleStarClick}
                      onSelectRow={() => onSelectRow(rowData.id)}
                      accountData={rowData}
                      selected={selected.includes(rowData.id)}
                      selectedRows={selected}
                      onCompanyClick={showCompaniesAccountsList}
                      onAccountDelete={handleDeleteCompany}
                      onEventClick={showEvent}
                      onLeadClick={showLead}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationCustom
              count={totalRow}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </>
        </Card>
      </Stack>
      <AlertModal isCloseIcon closeIconClass="right-[7px] top-[14px]" isOpen={deleteModal} onClose={() => setDeleteModal(false)} className="w-[374px] bg-[#EDF2F5]">
        <DeletePost
          postId={Math.random().toString(36)}
          message="Are you sure you want to delete these accounts?"
          onDelete={() => {
            if (selected?.length > 0) {
              handleDeleteCompany();
            }
          }}
          onClose={() => setDeleteModal(false)}
        />
      </AlertModal>
    </Stack>
  ) : (
    <Loader isLoaderLogo className="h-[100vh]" key="loader" />
  );
};

export default ListAccountCompanies;
