import { ParseContent } from 'src/utils/parse-html';
import UserDetails from './user-details';
// eslint-disable-next-line react/no-unused-prop-types
const CustomModal = ({
  user,
  UserPicture,
  speaker,
}: {
  user: any;
  UserPicture: any;
  speaker: any;
}) => (
  <div className="w-full md:w-[526px] lg:w-[640px] xl:w-[890px]">
    <div className="flex justify-between">
      <div className="flex items-center w-[285px] min-w-[285px] pt-[30px] pl-[30px]">
        <UserDetails
          UserPicture={UserPicture}
          user={user}
        />
      </div>
    </div>
    <div className="pt-[22px] px-[30px] pb-[30px] text-white">
      <h3 className="text-white leading-[25px] md:text-[23px] text-[18px] helvetica-neue-65-medium !font-semibold ">
        {`About ${speaker.firstName}`}
      </h3>
      <div className="overflow-y-auto max-h-[285px] pt-5">
        <ParseContent className="text-white md:leading-[23px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman" content={speaker?.bio || speaker?.eventSpeakers?.[0]?.bio} />
      </div>
    </div>
  </div>
);

export default CustomModal;
