/* eslint-disable react/prop-types */
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';
import SpotlightImage from '../../../../assets/images/spotlight-img.svg';

interface NoEventsProps {
  isHideText?: boolean;
  text?: string;
  isThemeColor?: boolean;
}

export const NoEvents: React.FC<NoEventsProps> = ({
  isHideText = false,
  text = 'Nothing to see here yet.',
  isThemeColor = false
}) => {
  const colors = getBrandingColors();
  const textColor = isThemeColor ? colors?.Text : 'text-gray-600';

  return (
    <div className="w-full mx-auto">
      <div className="h-[400px] m-auto">
        <img src={SpotlightImage} alt="Spotlight" className="mx-auto pt-[45px]" />
        {!isHideText && (
          <div style={{ color: textColor }} className="text-center text-lg ">
            {text}
            <br />
          </div>
        )}
      </div>
    </div>
  );
};
