/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import CompanyDetails from './company-details';
import CompanyHeader from '../company-as-visitor/company-header';

interface ICompanyProfileCoverProps {
  companyIdOrSlug: string;
  setIsLoader: Function;
}
export const CompanyProfileCover = ({ companyIdOrSlug, setIsLoader }: ICompanyProfileCoverProps) => {
  const { companyProfilePreview, companyProfilePicture, companyCoverPhoto } = useSelector((state: IAppState) => state);
  const [profileImage, setProfileImage] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [isCompanyUpdate, setIsCompanyUpdate] = useState(false);

  useEffect(() => {
    if (companyIdOrSlug) {
      setCoverImage(companyCoverPhoto?.value! ? companyCoverPhoto?.value! : companyProfilePreview.value?.cover!);
      setProfileImage(companyProfilePicture?.value! ? companyProfilePicture?.value! : companyProfilePreview.value?.logo!);
    } else {
      setCoverImage(companyCoverPhoto?.value!);
      setProfileImage(companyProfilePicture?.value!);
    }
  }, [companyIdOrSlug, companyProfilePreview, companyProfilePicture?.value, companyCoverPhoto?.value]);

  return (
    <>
      <div
        className="w-full"
      >
        <CompanyHeader id={companyIdOrSlug} cover={coverImage} logo={profileImage} name={companyProfilePreview.value?.name!} isEditCoverPhoto updatePhotoOnSubmit />
      </div>
      <CompanyDetails companyIdOrSlug={companyIdOrSlug} coverUrl={coverImage} logoUrl={profileImage} setIsCompanyUpdate={setIsCompanyUpdate} isCompanyUpdate={isCompanyUpdate} />
    </>
  );
};

export default CompanyProfileCover;
