import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getBrandingColors } from "src/mui/pages/event/live/liveEventStyling";
import { parseNotficationString } from "src/utils/notification";

interface IPostContentProps {
  content: string;
  isLivePage?: boolean;
}

export const PostContent = (props: IPostContentProps) => {
  const dispatch = useDispatch();
  const [clamped, setClamped] = useState(true);
  const colors = getBrandingColors();
  let cleanString = props?.content?.replace(/<br\s*\/?>/gi, "\n");
  cleanString = cleanString?.replace(/<[^>]*>/g, "");
  const [showButton] = useState(cleanString?.length > 270);
  const handleClick = () => setClamped(!clamped);

  const getPostText = parseNotficationString(props?.content, null, true, false, dispatch);

  const renderFormattedContent = () => {
    if (Array.isArray(getPostText)) {
      return getPostText.map((item, index) => {
        if (typeof item === "string") {
          const processedText = item.split("\n").map((line, idx) => (
            <React.Fragment key={`${index}-${idx}`}>
              {line}
              <br />
            </React.Fragment>
          ));
          return <React.Fragment key={index}>{processedText}</React.Fragment>;
        }
        return <React.Fragment key={index}>{item}</React.Fragment>;
      });
    }
  
    return null;
  };

  return (
    <div
      className={classNames(
        "px-[20px]", {
          'px-[9px]': props?.isLivePage,
        }
      )}
    >
      <div
        className={classNames("text-[13px] font-normal text-[#000000] leading-[17px] mb-2.5",
          {
            '!text-[11px] !leading-[16px] !mb-2': props?.isLivePage,
          }
        )}
        style={{
          color: props?.isLivePage ? colors?.Text : undefined,
        }}
      >
        <div
          className={classNames({
            "line-clamp-3": clamped,
          })}
        >
        {renderFormattedContent()}
        </div>
        {showButton && (
          <button
            type="button"
            className="hover:underline hover:decoration-2 text-[13px] leading-[17px] text-[#0049EF] font-[600]"
            onClick={handleClick}
            style={{
              color: props?.isLivePage ? colors?.Accent : undefined, fontSize: props?.isLivePage ? '11px' : undefined,
            }}
          >
            See {clamped ? "more" : "less"}
          </button>
        )}
      </div>
    </div>
  );
};

export default PostContent;
