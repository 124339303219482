export interface ClockSvgIconProps {
    fillColor?: string;
}
export const LockSvgIcon = ({ fillColor }: ClockSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#203c6e"
    return <svg xmlns="http://www.w3.org/2000/svg" width="10.247" height="11.885" viewBox="0 0 10.247 11.885">
    <path id="XMLID_519_" d="M50.668,11.885h8.91a.615.615,0,0,0,.668-.54V5.222a.615.615,0,0,0-.668-.54H58.91V3.061C58.91,1.373,57.211,0,55.123,0s-3.787,1.373-3.787,3.061V4.682h-.668a.615.615,0,0,0-.668.54v6.123A.615.615,0,0,0,50.668,11.885Zm2-8.824a2.506,2.506,0,0,1,4.9,0V4.682h-4.9Z" transform="translate(-50)" fill={iconColor}/>
  </svg>
  
  
};