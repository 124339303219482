// import { Autocomplete, LoadScriptNext } from '@react-google-maps/api';
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { debounce } from '@mui/material/utils';

import './city-selector.css';
import React from 'react';
import { MAPBOX_API } from "src/mui/config-global";

export interface CitySelectorProps {
  setValue: UseFormSetValue<any>;
  citySelected?: any;
  register?: UseFormRegister<any>;
  onChange?: Function;
  classes?: string;
  placeholder?: string;
  autoCompleteType?: string;
  setCitySelected?: any;
  onlyCityCounty?: boolean;
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
  geometry: any;
  name: string
}

interface Location {
  id: string;
  name: string;
}

export const CitySelectorTwo = ({
  setValue,

}: CitySelectorProps) => {
  const autocompleteService = { current: null };

  const ref = React.useRef(null);
  const [locations, setLocations] = useState<Location[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  // const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);


  const fetchData = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions({ ...request, types: ['(cities)'] }, callback);
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;
    if (
      !autocompleteService.current &&
      (window as any).google &&
      (window as any).google.maps &&
      (window as any).google.maps.places
    ) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue?.trim() === '') {
      setOptions([]);
      return undefined;
    }

    fetchData({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        // if (value) newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetchData]);


  const handleSelectLocation = (
    value: any
  ) => {
    if (value) {
      setValue('city', value?.city);
      setValue('country', value?.country);
      setValue('location', value?.name);
    }
  };

  return <div ref={ref}>
    <Autocomplete
      freeSolo
      disableClearable
      options={options}
      getOptionLabel={(option: any) =>
        option?.description || ''
      }
      filterOptions={(options) => options}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: '31px !important',
              padding: '0',
              backgroundColor: 'white',
              borderRadius: '10px',
              fontSize: '13px',
              // color: '#666666'
            },
            "& .MuiOutlinedInput-input": {
              height: '31px !important',
              padding: '0 10px !important',
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: 'transparent !important'
            }
          }}
        />
      )}

      onChange={(_, newValue: any) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if (newValue) {
          new (window as any).google.maps.Geocoder().geocode(
            { address: newValue?.description },
            (results?: any) => {

              if (results?.length) {
                const addressComponents = results[0]?.address_components || [];
                let city = '';
                let country = '';

                // Filter address components to get the city and country
                addressComponents.forEach((component: any) => {
                  if (component.types.includes('locality')) {
                    city = component.long_name;
                  } else if (component.types.includes('country')) {
                    country = component.long_name;
                  }
                });

                const fieldValue = {
                  id: newValue?.place_id,
                  name: newValue?.description,
                  city,
                  country
                };
                setValue('city', fieldValue?.city);
                setValue('country', fieldValue?.country);
                setValue('location', fieldValue?.name);
              }
            }
          );
        }
        else {
          setValue('city', null);
          setValue('country', null);
          setValue('location', null);
        }

      }}
    />

  </div>
}