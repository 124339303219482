import React from "react";

export interface ConnectionPostSvgIconProps {
    fillColor?: string;
}
export const ConnectionPostSvgIcon = ({ fillColor }: ConnectionPostSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#3c3c3c"
    return <svg id="Component_332_2" data-name="Component 332 – 2" xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 15.354 17.977">
    <g id="Group_12710" data-name="Group 12710">
      <g id="Group_12705" data-name="Group 12705" transform="translate(0 10.143)">
        <g id="Group_11965" data-name="Group 11965">
          <g id="Group_11964" data-name="Group 11964">
            <path id="Path_10331" data-name="Path 10331" d="M31.229,200.312h.442a.9.9,0,0,0,.873-.784,5.418,5.418,0,0,1,10.72,0,.9.9,0,0,0,.873.784h.442a.876.876,0,0,0,.884-.972,7.623,7.623,0,0,0-15.119,0A.882.882,0,0,0,31.229,200.312Z" transform="translate(-30.237 -192.59)" fill={iconColor}/>
            <path id="Path_10332" data-name="Path 10332" d="M43.774,199.533h-.442a1,1,0,0,1-.984-.873,5.3,5.3,0,0,0-10.5,0,1,1,0,0,1-.984.873h-.442a.984.984,0,0,1-.752-.343.948.948,0,0,1-.232-.751,7.734,7.734,0,0,1,15.34,0,.977.977,0,0,1-.232.751A1.11,1.11,0,0,1,43.774,199.533ZM37.1,193.9a5.547,5.547,0,0,1,5.46,4.718.785.785,0,0,0,.774.685h.442a.812.812,0,0,0,.6-.265.719.719,0,0,0,.177-.586,7.51,7.51,0,0,0-14.9,0,.727.727,0,0,0,.188.586.773.773,0,0,0,.6.265h.442a.785.785,0,0,0,.774-.685A5.527,5.527,0,0,1,37.1,193.9Z" transform="translate(-29.433 -191.7)" fill={iconColor}/>
          </g>
        </g>
        <g id="Group_11966" data-name="Group 11966" transform="translate(3.351 3.281)">
          <path id="Path_10333" data-name="Path 10333" d="M60.78,225.826a4.318,4.318,0,0,1,8.355,0,.88.88,0,0,1-.862,1.083H61.642A.884.884,0,0,1,60.78,225.826Z" transform="translate(-60.642 -222.467)" fill={iconColor}/>
          <path id="Path_10334" data-name="Path 10334" d="M67.376,225.941h-6.62a.989.989,0,0,1-.8-.4.976.976,0,0,1-.177-.829h0a4.419,4.419,0,0,1,8.554-.011.941.941,0,0,1-.177.829A.922.922,0,0,1,67.376,225.941Zm-7.383-1.16a.746.746,0,0,0,.133.641.78.78,0,0,0,.63.309h6.62a.765.765,0,0,0,.63-.309.746.746,0,0,0,.133-.641,4.208,4.208,0,0,0-8.145,0Z" transform="translate(-59.757 -221.4)" fill={iconColor}/>
        </g>
      </g>
      <g id="Group_12706" data-name="Group 12706" transform="translate(2.837)">
        <ellipse id="Ellipse_638" data-name="Ellipse 638" cx="4.719" cy="4.718" rx="4.719" ry="4.718" transform="translate(0.111 0.11)" fill={iconColor}/>
        <path id="Path_10335" data-name="Path 10335" d="M59.93,109.556a4.828,4.828,0,1,1,4.83-4.828A4.834,4.834,0,0,1,59.93,109.556Zm0-9.446a4.618,4.618,0,1,0,4.62,4.618A4.624,4.624,0,0,0,59.93,100.11Z" transform="translate(-55.1 -99.9)" fill={iconColor}/>
      </g>
    </g>
  </svg>
  
};