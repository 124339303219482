/* eslint-disable max-len */
import { IBaseAPIPostMethodResult } from '../../models/base-apipostmethod-result';
import { IBaseResult } from '../../models/base-result';
import { IEventInviteesResult } from '../../models/event-invitee';
import { IAttendeeResult } from '../../models/events-live/attendee';
import { IExpoResult } from '../../models/events-live/expo';
import { IEventResourceResult } from '../../models/events-live/resources';
import { IPostsResult } from '../../models/post/PostsResult';
import { IEvent } from '../../models/event/event';
import { IEventAttendeeResult } from '../../models/event/event-attendee';
import { IEventAttendeeStatusResult } from '../../models/event/event-attendee-status';
import { IEventCalendarResult } from '../../models/event/event-calendar';
import { IEventHandoutsResult, IEventOnDemandResult } from '../../models/event/event-ondemand';
import { IEventRegionResult } from '../../models/event/event-region';
import { IInvitationResult } from '../../models/event/InvitationResult';
import { ISimilarEventsResult } from '../../models/event/similar-events-result';
import { IUpcomingEventAgendaResult } from '../../models/event/UpcomingEventAgendaResult';
import { IUpcomingEventResult } from '../../models/event/UpcomingEventResult';
import { IUpcomingEventsSpeakerResult } from '../../models/event/UpcomingEventSpeakersResult';
import { IUpcomingEventGuestResult } from '../../models/event/UpcommingEventGuestResult';
import { ITargetAudiencesResult } from '../../models/user-events';
import { IDateItems } from '../../screens/events';
import BaseService from '../BaseService';
import { IHeroEventResult } from '../../models/event/event-hero';
import { IPostSpotlightResult } from '../../models/post/IPostSpotlight';

export class EventsService extends BaseService {
  async getEvents() {
    const extraOptions = this.getAuthToken();

    return this.get<IBaseResult<IEvent>>('/events', extraOptions);
  }

  async getProfileByEmail(email: string) {
    // const extraOptions = this.getAuthToken();
    return this.get<any>(
      `/auth/profile/${email}`
      // extraOptions
    );
  }

  async getUTMbyShortUrl(url: string) {
    return this.get<any>(`/events/utm/${url}`);
  }

  async getUpcomingEventDetails(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.get<IUpcomingEventResult>(
      `/events/${eventIdOrSlug}`,
      extraOptions
    );

    if (result?.data?.event?.rooms) {
      result.data.event.rooms = result.data.event.rooms.map(room => {
        const sortedSessions = room.sessions.map(session => {
          const sortedSpeakers = session.eventSpeakers.sort((a, b) => a?.ordering! - b?.ordering!);
          return {
            ...session,
            eventSpeakers: sortedSpeakers
          };
        });
        return {
          ...room,
          sessions: sortedSessions
        };
      });
    }

    return result;
  }

  async getMeetingsSummary(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.getV2<any>(
      `/events/${eventIdOrSlug}/sponsors-meetings-summary?where[status]=approved&where[status]=waiting`,
      extraOptions
    );
    return result;
  }

  async getSponsorsLeadsSummary(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.getV2<any>(
      `/host/events/${eventIdOrSlug}/sponsor-leads-summary`,
      extraOptions
    );
    return result;
  }

  async getEventPolls(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.get<any>(
      `/events/${eventIdOrSlug}/polls`,
      extraOptions
    );
    return result;
  }

  async createVoteForPollAns(
    eventIdOrSlug: string,
    sessionId: string,
    questionId: string,
    body: any
  ) {
    const extraOptions = this.getAuthToken();
    const result = await this.post<any>(
      `/events/${eventIdOrSlug}/sessions/${sessionId}/polls/${questionId}/votes`,
      body,
      extraOptions
    );
    return result;
  }

  async joinVirtualMeeting(eventId: string, meetingId: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.get<any>(
      `/user/events/${eventId}/meeting/${meetingId}/join-meeting`,
      extraOptions
    );
    return result;
  }

  async leaveVirtualMeeting(eventId: string, meetingId: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.get<any>(
      `/user/events/${eventId}/meeting/${meetingId}/leave-meeting`,
      extraOptions
    );
    return result;
  }

  async joinExpoBoothRoom(eventId: string, eventExpoBoothId: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.get<any>(
      `/events/${eventId}/expo/${eventExpoBoothId}/join-room`,
      extraOptions
    );
    return result;
  }

  async leaveExpoBoothRoom(eventId: string, eventExpoBoothId: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.get<any>(
      `/events/${eventId}/expo/${eventExpoBoothId}/leave-room`,
      extraOptions
    );
    return result;
  }

  async removeVoteForPollAns(
    eventIdOrSlug: string,
    sessionId: string,
    questionId: string,
    body: any
  ) {
    const extraOptions = this.getAuthToken();
    const result = await this.put<any>(
      `/events/${eventIdOrSlug}/sessions/${sessionId}/polls/${questionId}/votes`,
      body,
      extraOptions
    );
    return result;
  }

  async connectWithSuggests(eventId: string, body: any) {
    const extraOptions = this.getAuthToken();
    return this.post('/users/me/connection-requests', body, extraOptions);
  }

  async approveConnectionRequest(eventId: string, body: any, userId: string) {
    const extraOptions = this.getAuthToken();
    return this.put(
      `/users/me/connection-requests/${userId}`,
      body,
      extraOptions
    );
  }

  async getEventSummary() {
    const extraOptions = this.getAuthToken();
    return this.get<any>('/events/summary', extraOptions);
  }

  async getEventAsGuest(eventIdOrSlug: string) {
    // eslint-disable-next-line
    return await this.get<IUpcomingEventGuestResult>(
      `/guest/events/${eventIdOrSlug}`
    );
  }

  async getUpcomingEventSpeakers(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<IUpcomingEventsSpeakerResult>(
      `/events/${eventIdOrSlug}/speakers?page=1&limit=30`,
      extraOptions
    );
  }

  async getUpcomingEventAttendees(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<IAttendeeResult>(
      `/events/${eventIdOrSlug}/attendees?page=1&limit=1000&where[status]=accepted`,
      extraOptions
    );
  }

  async getShortAttendees(
    eventIdOrSlug: string,
    liveEventPage?: boolean,
    registrantsVisibility?: any
  ) {
    const extraOptions = this.getAuthToken();
    let queryParams = '&where[status]=accepted';

    if (!registrantsVisibility) {
      queryParams += '&where[attendees]=true';
    }

    if (registrantsVisibility?.attendee) {
      queryParams += '&where[attendees]=true';
    }
    if (registrantsVisibility?.host) {
      queryParams += '&where[hosts]=true';
    }
    if (registrantsVisibility?.speaker) {
      queryParams += '&where[speakers]=true';
    }
    if (registrantsVisibility?.sponsor) {
      queryParams += '&where[sponsors]=true';
    }

    const request = liveEventPage
      ? `/events/${eventIdOrSlug}/attendees-with-checks?page=1&limit=1000${queryParams}`
      : `/events/${eventIdOrSlug}/light/attendees?page=1&limit=1000&where[status]=accepted`;
    const result = await this.getV2<any>(request, extraOptions);
    return result;
  }

  async getMeetingById(meetingId: string) {
    const extraOptions = this.getAuthToken();
    return this.getV2<any>(`/meeting/${meetingId}`, extraOptions);
  }

  async getExpoBoothMeetingById(eventExpoBoothId: string) {
    const extraOptions = this.getAuthToken();
    return this.getV2<any>(`/meeting/${eventExpoBoothId}`, extraOptions);
  }

  async getMeetings(
    eventIdOrSlug: string,
    role: string,
    userId: string,
    meetingStatus?: string,
    sponsorId?: string,
    specificMeetings?: string
  ) {
    const extraOptions = this.getAuthToken();
    let whereCondition = meetingStatus
      ? `&where[status]=${meetingStatus}`
      : '&where[status]=waiting&where[status]=approved';
    let endpoint = '';
    switch (role) {
      case 'admin': {
        endpoint = 'meetings';
        // whereCondition = whereCondition+'isMobileHost=true';
        break;
      }
      case 'sponsor': {
        endpoint = 'sponsor-meetings';
        whereCondition += `&sponsorId=${sponsorId}`;
        break;
      }
      default: {
        endpoint = 'user-meetings';
        whereCondition += `&userId=${userId}`;
        break;
      }
    }

    if (specificMeetings) {
      endpoint = 'meetings';
      if (specificMeetings === 'allSponsorsMeetings') {
        whereCondition += '&where[isSponsor]=true';
      }
      if (specificMeetings === 'othersMeetings') {
        whereCondition += '&where[isSpeaker]=true&where[isAttendee]=true';
      }

      if (specificMeetings.includes('selectedSponsor')) {
        whereCondition += `&where[sponsorId]=${sponsorId}`;
      }
    }
    return this.getV2<any>(
      `/events/${eventIdOrSlug}/${endpoint}?page=1&limit=100${whereCondition}`,
      extraOptions
    );
  }

  async getEventLeads(
    eventIdOrSlug: string,
    role: string,
    userId: string,
    sponsorId?: string,
    specificLeads?: string
  ) {
    const extraOptions = this.getAuthToken();
    let whereCondition = '';
    let endpoint = '';
    let leadsEndpoint = 'leads';

    switch (role) {
      case 'admin': {
        endpoint = 'host';
        // whereCondition = whereCondition+'isMobileHost=true';
        leadsEndpoint = 'mobile-leads';
        break;
      }
      case 'sponsor': {
        endpoint = 'sponsor';
        whereCondition += `&sponsorId=${sponsorId}`;
        break;
      }
      default: {
        endpoint = 'user';
        whereCondition += '&isAttendee=true';
        break;
      }
    }

    if (specificLeads) {
      if (specificLeads === 'myOwnLeads') {
        // endpoint = 'user';
        whereCondition += '&isAttendee=true';
      }

      if (specificLeads.includes('selectedSponsor')) {
        // endpoint = 'sponsor';
        leadsEndpoint = 'leads';
        whereCondition += `&sponsorId=${sponsorId}`;
      }
    }
    return this.getV2<any>(
      `/${endpoint}/events/${eventIdOrSlug}/${leadsEndpoint}?page=1&limit=100${whereCondition}`,
      extraOptions
    );
  }

  async getEventSponsors(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<any>(
      `/events/${eventIdOrSlug}/sponsors?page=1&limit=100`,
      extraOptions
    );
  }

  async getTabsCount(eventId: string, tabs: string, whereCondition?: string) {
    const extraOptions = this.getAuthToken();
    const whereQueryParams = whereCondition ? `&${whereCondition}` : '';

    return this.getV2<any>(
      `/events/${eventId}/people-overview?tabs=${tabs}${whereQueryParams}`,
      extraOptions
    );
  }

  async updateMeetingStatus(
    eventIdOrSlug: string,
    meetingId: string,
    body: any
  ) {
    const extraOptions = this.getAuthToken();
    // await axios.patch(`/events/${eventId}/meeting/${meetingId}/status`, body);
    return this.patchV2<any>(
      `/events/${eventIdOrSlug}/meeting/${meetingId}/status`,
      body,
      extraOptions
    );
  }

  async getUpcomingEventAgenda(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<IUpcomingEventAgendaResult>(
      `/events/${eventIdOrSlug}/agenda`,
      extraOptions
    );
  }

  async getExpoManagers(
    eventId: string,
    eventSponsorId: string,
    expoId: string
  ) {
    const extraOptions = this.getAuthToken();
    return this.get<IExpoResult>(
      `/host/events/${eventId}/sponsors/${eventSponsorId}/expo/${expoId}`,
      extraOptions
    );
  }

  async getEventResources(eventId: string) {
    return this.get<IEventResourceResult>(
      `/events/${eventId}/library/contents`,
      this.getAuthToken()
    );
  }

  async getUpcomingEventInterstitials(eventIdOrSlug: string) {
    return this.get<IUpcomingEventAgendaResult>(
      `/events/${eventIdOrSlug}/interstitials`
    );
  }

  async getUpcomingEventPosts(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<IPostsResult>(
      `/events/${eventIdOrSlug}/posts?page=1&limit=10`,
      extraOptions
    );
  }

  async getInvitableUsers(
    eventId: string,
    search: string = '',
    jobTitleIds: string[] = [],
    jobFunctionIds: string[] = [],
    regions: string[] = []
  ) {
    const extraOptions = this.getAuthToken();

    let url = `/events/${eventId}/uninvited-connections?page=1&limit=500`;

    if (search !== '') url.concat(`&search=${encodeURIComponent(search)}`);

    jobTitleIds.forEach((id) => {
      url = url.concat(`&where[jobTitleId]=${id}`);
    });
    jobFunctionIds.forEach((id) => {
      url = url.concat(`&where[jobFunctionId]=${id}`);
    });
    regions.forEach((region) => {
      url = url.concat(`&where[region]=${region}`);
    });

    return this.get<IEventAttendeeResult>(url, extraOptions);
  }

  async inviteAttendee(eventId: string, userId: string) {
    const extraOptions = this.getAuthToken();

    return this.post<IInvitationResult>(
      `/events/${eventId}/invite`,
      { inviteUserId: userId },
      extraOptions
    );
  }

  async getEventRegions(search: string = '', limit: number = 10) {
    const extraOptions = this.getAuthToken();

    return this.get<IEventRegionResult>(
      `/events/regions?page=1&limit=${limit}&search=${encodeURIComponent(search)}`,
      extraOptions
    );
  }

  async getGuestEventRegions(query: string) {
    return this.get<IEventRegionResult>(
      `/guest/events/regions?search=${encodeURIComponent(query)}`
    );
  }

  async requestMeeting(eventId: string, body: any) {
    return this.post(`/events/${eventId}/meetings`, body);
  }

  async getCalendarLink(eventIdOrSlug: string, type: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.get<IEventCalendarResult>(
      `/guest/events/${eventIdOrSlug}/calendar?service=${type}`,
      extraOptions
    );
    return result;
  }

  async getEventsHappeningNow() {
    const extraOptions = this.getAuthToken();

    // eslint-disable-next-line
    return await this.get<IBaseResult<IEvent>>(
      '/events?param=1&page=1&limit=400&where[stage]=live&where[status]=active',
      extraOptions
    );
  }

  async getCompanyEvents(companyId: string) {
    const extraOptions = this.getAuthToken();

    // eslint-disable-next-line
    return await this.get<IBaseResult<IEvent>>(
      `/events/sponsors/company/${companyId}`,
      extraOptions
    );
  }

  async assignedEvents(data: string[]) {
    const extraOptions = this.getAuthToken();

    return this.patch(
      '/events/sponsors/account-files',
      { data },
      extraOptions
    );
  }

  async getEventsUpcpming() {
    const extraOptions = this.getAuthToken();

    // eslint-disable-next-line
    return await this.get<IBaseResult<IEvent>>(
      '/events?param=1&page=1&limit=400&where[stage]=not-live&where[status]=active',
      extraOptions
    );
  }

  async getDiscoveringEvents(
    apiUrl?: string,
    search: string = '',
    format: string = '',
    dates: IDateItems[] = [],
    industryIds: string[] = [],
    companyIds: string[] = [],
    jobTitleIds: string[] = [],
    jobFunctionIds: string[] = [],
    regionIds: string[] = [],
    orderType: 'DESC' | 'ASC' = 'DESC',
    nextPage: number = 1,
    category: string = '',
    isCompanyEvent: string = ''
  ) {
    const extraOptions = this.getAuthToken();
    let url = apiUrl || `/events?page=${nextPage}&limit=100`;

    if (search !== '') url = url.concat(`&search=${encodeURIComponent(search)}`);

    if (format !== '') url = url.concat(`&where[format]=${format}`);

    industryIds.forEach((id) => {
      url = url.concat(`&where[industryId]=${id}`);
    });
    companyIds.forEach((id) => {
      url = url.concat(`&where[companyId]=${id}`);
    });
    jobTitleIds.forEach((id) => {
      url = url.concat(`&where[jobTitleId]=${id}`);
    });
    jobFunctionIds.forEach((id) => {
      url = url.concat(`&where[jobFunctionId]=${id}`);
    });
    regionIds.forEach((id) => {
      url = url.concat(`&where[region]=${id}`);
    });
    if (category?.length) {
      url = url.concat(`&where[eventCategoryShortName]=${category}`);
    }
    if (dates.length === 1) {
      dates.forEach((date) => {
        url = url.concat(
          `&where[fromDate]=${date.from}&where[toDate]=${date.to}`
        );
      });
    }
    if (nextPage) {
      url = url.concat(isCompanyEvent);
    } else if (dates.length > 1) {
      // eslint-disable-next-line
      for (const i in dates) {
        url = url.concat(
          `&where[dateRanges][${i}][fromDate]=${dates[i].from}&where[dateRanges][${i}][toDate]=${dates[i].to}`
        );
      }
    }
    url = url.concat(`&order[startTime]=${orderType}`);
    const result = await this.get<IBaseResult<IEvent>>(url, extraOptions);
    return result;
  }

  async getEventsAttendeeStatus(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    // eslint-disable-next-line
    return await this.get<IEventAttendeeStatusResult>(
      `/events/${eventIdOrSlug}/attendee-status`,
      extraOptions
    );
  }

  async eventReport(eventIdOrSlug: string, message: string) {
    const extraOptions = this.getAuthToken();

    return this.post<IBaseAPIPostMethodResult>(
      `/events/${eventIdOrSlug}/report`,
      { message },
      extraOptions
    );
  }

  async eventInterested(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    const result = await this.post<IBaseAPIPostMethodResult>(
      `/events/${eventIdOrSlug}/interest`,
      {},
      extraOptions
    );
    return result;
  }

  getTargetAudiences(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<ITargetAudiencesResult>(
      `/guest/events/${eventIdOrSlug}/target-audiences`,
      extraOptions
    );
  }

  userUtmAnalytics(eventIdOrSlug: string, payload: any) {
    const extraOptions = this.getAuthToken();
    return this.post<ITargetAudiencesResult>(
      `/events/${eventIdOrSlug}/utm-analytics`,
      payload,
      extraOptions
    );
  }

  guestUtmAnalytics(eventIdOrSlug: string, payload: any) {
    return this.post<any>(
      `/guest/events/${eventIdOrSlug}/utm-analytics`,
      payload
    );
  }

  async getSearchEventResults(searchEntry?: string) {
    const extraOptions = this.getAuthToken();
    const url = `/search/event?page=1&limit=3&search=${searchEntry ? encodeURIComponent(searchEntry) : ''}`;

    return this.get<IBaseResult<IEvent>>(url, extraOptions);
  }

  async getEventSpotlight(
    eventId: string,
    limit: number,
    page: number,
    query?: string
  ) {
    const extraOptions = this.getAuthToken();
    const searchString = query ? `&search=${encodeURIComponent(query)}` : '';
    const url = `/events/${eventId}/spotlight-posts?page=${page}&limit=${limit}&order[createdAt]=DESC${searchString}`;

    return this.get<IPostSpotlightResult>(url, extraOptions);
  }

  async getEventOnDemand(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.get<IEventOnDemandResult>(
      `/events/${eventIdOrSlug}/playback`,
      extraOptions
    );

    return result;
  }

  async getEventHandouts(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.get<IEventHandoutsResult>(
      `/events/${eventIdOrSlug}/handouts`,
      extraOptions
    );

    return result;
  }

  async getEventBranding(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.get<any>(
      `/user/live-event/${eventIdOrSlug}/branding`,
      extraOptions
    );

    return result;
  }

  async updateEventBranding(eventIdOrSlug: string, brandingId:string, body:any) {
    const extraOptions = this.getAuthToken();
    const result = await this.put<any>(
      `/user/live-event/${eventIdOrSlug}/branding/${brandingId}`,
      body,
      extraOptions
    );

    return result;
  }

  async markBrandingAsFav(eventIdOrSlug: string, body:any) {
    const extraOptions = this.getAuthToken();
    const result = await this.post<any>(
      `/user/live-event/${eventIdOrSlug}/branding/favorite`,
      body,
      extraOptions
    );

    return result;
  }

  async deleteBrandingAsFav(eventIdOrSlug: string, favoriteId:any) {
    const extraOptions = this.getAuthToken();
    const result = await this.delete<any>(
      `/user/live-event/${eventIdOrSlug}/branding/favorite/${favoriteId}`,
      extraOptions
    );

    return result;
  }

  async getFavBrandings(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();
    const result = await this.get<any>(
      `/user/live-event/${eventIdOrSlug}/branding/favorite`,
      extraOptions
    );

    return result;
  }

  async createEventBranding(eventIdOrSlug: string, body:any) {
    const extraOptions = this.getAuthToken();
    const result = await this.post<any>(
      `/user/live-event/${eventIdOrSlug}/branding`,
      body,
      extraOptions
    );

    return result;
  }

  getSimilarEvents(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<ISimilarEventsResult>(
      `/events/${eventIdOrSlug}/similar`,
      extraOptions
    );
  }

  async getEventInvitees(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<IEventInviteesResult>(
      `/host/events/${eventIdOrSlug}/invitations?page=1&limit=10`,
      extraOptions
    );
  }

  getHeroEvents() {
    const extraOptions = this.getAuthToken();

    return this.get<IHeroEventResult>('/events/heroes', extraOptions);
  }

  getPollEvents(eventId: string, sessionId: string) {
    const extraOptions = this.getAuthToken();

    return this.get<IHeroEventResult>(
      `/events/${eventId}/sessions/${sessionId}/polls`,
      extraOptions
    );
  }

  votePollEvents(
    eventId: string,
    sessionId: string,
    pollId: string,
    answerId: string
  ) {
    const extraOptions = this.getAuthToken();

    return this.post<IHeroEventResult>(
      `/events/${eventId}/sessions/${sessionId}/polls/${pollId}/votes`,
      { answerId },
      extraOptions
    );
  }

  removeVotePollEvents(
    eventId: string,
    sessionId: string,
    pollId: string,
    answerId: string
  ) {
    const extraOptions = this.getAuthToken();

    return this.put<IHeroEventResult>(
      `/events/${eventId}/sessions/${sessionId}/polls/${pollId}/votes`,
      { answerId },
      extraOptions
    );
  }

  getPollEventsByAnswerResult(
    eventId: string,
    sessionId: string,
    pollId: string
  ) {
    const extraOptions = this.getAuthToken();

    return this.get<IHeroEventResult>(
      `/events/${eventId}/sessions/${sessionId}/polls/${pollId}`,
      extraOptions
    );
  }

  getEventModerators(eventId: string) {
    const extraOptions = this.getAuthToken();

    return this.get<any>(`/host/events/${eventId}/moderators`, extraOptions);
  }

  getEventAds(slug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<any>(`/events/${slug}/ads`, extraOptions);
  }

  async getSurveyQuestionsWithAnswers(eventId: string, userId: string) {
    const extraOptions = this.getAuthToken();
    return this.get<any>(
      `/events/${eventId}/survey-form-with-answers?userId=${userId}`,
      extraOptions
    );
  }

  async getInsightSurveyQuestionsWithAnswers(surveyId: string, userId: string) {
    const extraOptions = this.getAuthToken();
    return this.get<any>(
      `/surveys/${surveyId}/survey-form-with-answers?userId=${userId}`,
      extraOptions
    );
  }

  async getSurveyQuestions(eventId: string) {
    const extraOptions = this.getAuthToken();
    return this.get<any>(`/events/${eventId}/survey-form`, extraOptions);
  }

  async getSurveyQuestionsInsight(surveyId: string) {
    const extraOptions = this.getAuthToken();
    return this.get<any>(`/surveys/${surveyId}/form`, extraOptions);
  }

  async getLatestAttendeeCheckedIn(eventId: string) {
    const extraOptions = this.getAuthToken();
    // return this.get<any>(`/events/${eventId}/attendees?page=${page}&limit=${limit}&isMatch=true&where[isPhysical]=true&order[createdAt]=DESC`, extraOptions);
    return this.get<any>(`/check-in/${eventId}/latest`, extraOptions);
  }

  async getRandomSurveyStatistics(eventId: string) {
    const extraOptions = this.getAuthToken();
    return this.get<any>(`/events/${eventId}/survey/random-statistics`, extraOptions);
  }

  async eventProtectedPassword(eventId: string, body: any) {
    return this.post<any>(`/guest/events/${eventId}/verify-code`, body);
  }

  async modifySurveyAnswers(
    eventId: string,
    role: string,
    userId: string,
    body: any
  ) {
    const extraOptions = this.getAuthToken();
    return this.put(
      `${role}/events/${eventId}/modify-survey?userId=${userId}`,
      body,
      extraOptions
    );
  }

  async modifySurveyAnswersInsights(
    eventId: string,
    role: string,
    userId: string,
    body: any
  ) {
    const extraOptions = this.getAuthToken();
    return this.put(
      `/surveys/${eventId}`,
      body,
      extraOptions
    );
  }

  async exitSurvey(
    eventId: string,
    userId: string,
    body: any
  ) {
    const extraOptions = this.getAuthToken();
    return this.post(
      `/events/${eventId}/modify-survey?userId=${userId}`,
      body,
      extraOptions
    );
  }

  async updateEventAudienceProgress(eventId: string, payload:any) {
    const extraOptions = this.getAuthToken();
    return this.patch(
      `/events/${eventId}/attendee/progress-status`,
      payload,
      extraOptions
    );
  }

  removeAttendee(eventId: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<any>(`/events/${eventId}/attendees`, extraOptions);
  }

  async getColorStyles() {
    const extraOptions = this.getAuthToken();

    return this.getV2<any>('/color-styles', extraOptions);
  }

  async clickOnSpotlight(eventId: string, id: string) {
    const extraOptions = this.getAuthToken();
    return this.post<any>(
      `/events/${eventId}/spotlight/${id}`,
      {},
      extraOptions
    );
  }

  async clickOnAdd(eventId: string, id: string) {
    const extraOptions = this.getAuthToken();
    return this.post<any>(`/events/${eventId}/ads/${id}`, {}, extraOptions);
  }

  async getSurveyAsGuest(surveyIdOrSlug: string) {
    // eslint-disable-next-line
    return await this.get<any>(
      `/guest/surveys/${surveyIdOrSlug}`
    );
  }
}
