import {
  Avatar, Box, Grid, IconButton, Stack, Typography
} from '@mui/material';
import React, { useState } from 'react';
import { CompanyService } from 'src/services';
import Delete from '@mui/icons-material/Delete';
import { Edit } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
// import { Loader } from 'src/shared-components/Loader';
import { Loader } from 'src/shared-components/Loader';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { DeletePost } from 'src/components/posts/delete-post';
import { useNavigate, useParams } from 'react-router';
import PublishedIcon from '../../../../assets/images/published-tick-icon.svg';
import DraftIcon from '../../../../assets/images/draft-icon.svg';
import { NoProductList } from '../no-product-list';

interface AllProductsProps {
  onAddClick?: (id?: string | undefined) => void;
  companyProducts: any[];
  companyId: string;
  getCompnayProductsData?: Function;
  hideAddOrEditIcon?:boolean;
}

const AllProducts: React.FC<AllProductsProps> = ({
  onAddClick, companyProducts, companyId, getCompnayProductsData, hideAddOrEditIcon
}) => {
  const navigate = useNavigate();
  const { companyidorslug } = useParams();

  const [hoveredProductId, setHoveredProductId] = useState<string | null>(null);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [deleteProduct, setDeleteProduct] = useState({ open: false, productId: null });
  const companyService: CompanyService = new CompanyService();

  const onDeleteCompanyProduct = async (productId: string) => {
    setIsLoader(true);
    try {
      const response = await companyService.deleteCompanyProduct(companyId, productId);
      if (response?.message === 'SUCCESS') {
        if (getCompnayProductsData) {
          getCompnayProductsData();
        }
      }
    } catch (error) {
      console.error('Error deleting company product:', error);
    } finally {
      setIsLoader(false);
    }
  };

  const handleEditClick = async (productId: string) => {
    setIsLoader(true);
    if (onAddClick) onAddClick(productId);
  };

  const onCloseDeleteProduct = () => {
    setDeleteProduct({ open: false, productId: null });
  };

  return (
    <>
      <Stack sx={{ position: 'relative' }}>
        {isLoader && (
        <div className="absolute left-0 top-0 w-full h-[calc(100%--26px)] bg-gray-1 bg-opacity-80 flex items-center justify-center z-20 rounded-20 -mt-[14px]">
          <Loader className="pt-5" key="loader" />
        </div>
        )}
        <Stack sx={{
          display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'row', marginBottom: '30px'
        }}
        >
          <Typography sx={{
            fontFamily: 'Poppins-600', fontWeight: '600', fontSize: '16px', lineHeight: '21px'
          }}
          >
            All Products
          </Typography>
          { !hideAddOrEditIcon && (
          <IconButton
            sx={{
              width: '21px', height: '21px', marginLeft: '16px', cursor: 'pointer', backgroundColor: 'black'
            }}
            onClick={() => { if (onAddClick) onAddClick(); }}
          >
            <AddIcon sx={{
              width: '21px', height: '21px', cursor: 'pointer', color: 'white'
            }}
            />
          </IconButton>
          )}
        </Stack>
        {companyProducts.length ? (
          <Grid
            rowGap="30px"
            columnSpacing="100px"
            container
          >
            {companyProducts.map((item, index) => (
              <Grid key={index} item xs={6} md={4} lg={3}>
                <Stack sx={{
                  display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start', columnGap: '10px'
                }}
                >
                  <Box
                    sx={{
                      width: '90px', height: '90px', border: '2px solid #EDF2F5', borderRadius: '10px', position: 'relative'
                    }}
                    onMouseEnter={() => setHoveredProductId(item?.id)}
                    onMouseLeave={() => setHoveredProductId(null)}
                  >
                    {hoveredProductId === item?.id && !hideAddOrEditIcon && (
                    <>
                      <IconButton
                        sx={{
                          position: 'absolute', right: '0px', top: '0px', zIndex: 1
                        }}
                        onClick={() => setDeleteProduct({ open: true, productId: item?.id })}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                      <IconButton
                        sx={{
                          position: 'absolute', left: '0px', top: '0px', zIndex: 1
                        }}
                        onClick={() => handleEditClick(item?.id)}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </>
                    )}

                    <Avatar
                      src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.icon}`}
                      alt={item?.name}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        borderRadius: '8px',
                        '& .MuiAvatar-img': {
                          objectFit: 'contain', borderRadius: '8px'
                        }
                      }}
                    />
                  </Box>
                  <Stack sx={{
                    width: '106px', display: 'flex', alignItems: 'start', gap: '6px'
                  }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Poppins-600', fontWeight: '600', fontSize: '16px !important', cursor: 'pointer'
                      }}
                      className="ellipsis-two-line"
                      onClick={() => navigate(`/wall/company/${companyidorslug}/prodcut/${item?.id}`)}
                    >
                      {item?.name}
                    </Typography>
                    <Typography sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '3px',
                      fontFamily: 'Poppins-500',
                      fontWeight: '500',
                      fontSize: '12px !important'
                    }}
                    >
                      <Avatar src={item.status ? PublishedIcon : DraftIcon} alt="Status Icon" sx={{ width: '17px', height: '17px', }} />
                      {item?.status === 'published' ? 'Published' : 'Draft'}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        ) : <NoProductList text="No products available at the moment." />}
      </Stack>
      { deleteProduct?.productId && (
      <AlertModal isCloseIcon closeIconClass="right-[7px] top-[14px]" isOpen={deleteProduct?.open} onClose={onCloseDeleteProduct} className="w-[374px] bg-[#EDF2F5]">
        <DeletePost
          postId={deleteProduct?.productId}
          message="Are you sure you want to delete this product?"
          onDelete={async () => {
            if (deleteProduct?.productId) {
              await onDeleteCompanyProduct(deleteProduct.productId);
              onCloseDeleteProduct();
            }
          }}
          onClose={onCloseDeleteProduct}
        />
      </AlertModal>
      )}
    </>
  );
};

export default AllProducts;
