/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { IEvent } from '../../../models/event/event';
import { IAttendee } from '../../../models/events-live/attendee';
import VideoHeader from '../video-and-audience-panel/video-and-expo-panel/video-header';
import HostAndAttendees from './host-and-attendees';
import { VideoControlls } from '../../../components/chime-video-call/video-controlls';
import { VideoSession } from './video-session';
import { Participants, QueueParticipants } from '../../../store/interactive-event/types';
import { ChimeLoader } from '../../../components/chime-video-call/chime-loader';
import { IAppState } from '../../../store';
import { ModeratorView } from './views/moderator-view';
import { SpeakerView } from './views/speaker-view';
import AlertModal from '../../../components/alert-modal/alert-modal';
import { Button } from '../../../shared-components/V2';
import { GuestView } from './views/guest-view';
import { InteractiveResetAttendeeInviteToJoin, InteractiveResetForceMute } from '../../../store/interactive-event/attendee/actions';
import { InteractiveModeratorSpeakerMuteAll } from '../../../store/interactive-event/moderator-speaker';
import { IRoom } from '../../../models/event/room';
import { getCompanyName, minifiedImage } from '../../../utils';

interface IHeroProps {
  event: IEvent;
  attendees: IAttendee[];
  stage: Participants;
  queue: QueueParticipants;
}

export const Hero = ({
  event, attendees, stage, queue
}: IHeroProps) => {
  const { slug, roomId, role } = useParams();
  const meetingManager = useMeetingManager();
  const [currentRoom, setCurrentRoom] = useState<IRoom>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chimeInvitation, inviteToJoin, forceMute } = useSelector((state: IAppState) => state.interactiveEvent);
  const { event: { rooms } = {} } = useSelector((state: IAppState) => state.liveEvent);
  const [hasReceivedGuestInvite, setHasReceivedGuestInvite] = useState(false);
  const [hasAcceptedGuestInvite, setHasAcceptedGuestInvite] = useState(false);
  const [visiblity, setVisibility] = useState<'invisible' | 'visible'>('invisible');
  const [screenMode, setScreenMode] = useState();

  useEffect(() => {
    if (hasReceivedGuestInvite !== inviteToJoin) {
      setHasReceivedGuestInvite(inviteToJoin);
    }
  }, [inviteToJoin]);
  useEffect(() => {
    if (rooms) {
      const room = rooms.find(r => r.id === roomId);
      setCurrentRoom(room);
    }
  }, [rooms]);

  useEffect(() => {
    if (forceMute && meetingManager) {
      meetingManager.audioVideo?.realtimeMuteLocalAudio();
      dispatch(InteractiveResetForceMute());
    }
  }, [forceMute]);

  return (
    <>
      <div className="lg:flex flex-row w-full h-full mx-auto justify-center gap-5 mb-6">
        <div
          className="relative w-full h-full flex flex-col rounded-xl overflow-hidden lg:mb-0 mb-4"
          onMouseEnter={() => setVisibility('visible')}
          onMouseLeave={() => setVisibility('invisible')}
        >
          <VideoHeader
            disableExpo={!event?.eventExpo?.expo}
            hasModerator={role === 'moderator'}
            hasSpeaker={role === 'speaker'}
            title={event?.name!}
            logoURL={minifiedImage(event?.company?.logo!)}
            eventSlug={event?.slug!}
            hostName={getCompanyName(event)}
            videoStatus={role === 'session' ? 'sessions' : 'expo'}
            setVideoStatus={(type: string) => type === 'sessions' && navigate(`/${getCompanyName(event)}/${event.slug}/live`)}
            className={`absolute w-full bg-transparent bg-gradient-to-b from-slate-400 text-white z-10 ${visiblity}`}
          />
          <div className="video-area w-full h-full bg-white">
            {
              (inviteToJoin && hasAcceptedGuestInvite) ? <ChimeLoader chimeConfig={chimeInvitation}><GuestView /></ChimeLoader> : (
                <>
                  {/* Video layout */}
                  {role === 'moderator'
                    && currentRoom?.isInteractive
                    && <ChimeLoader chimeConfig={chimeInvitation}><ModeratorView /></ChimeLoader>}
                  {role === 'speaker'
                    && currentRoom?.isInteractive
                    && <ChimeLoader chimeConfig={chimeInvitation}><SpeakerView /></ChimeLoader>}
                  {/* ********* */}
                  {/* Session Layout */}
                  {(role === 'session' || !currentRoom?.isInteractive) && (
                  <VideoSession
                    onChangeSize={setScreenMode}
                    attendees={(
                      <HostAndAttendees
                        event={event!}
                        attendees={attendees}
                        stage={stage}
                        queue={queue}
                        {...((role === 'moderator' || role === 'speaker') && { onMuteClick: () => dispatch(InteractiveModeratorSpeakerMuteAll(slug!, roomId!)) })}
                      />
                    )}
                  />
                  )}
                  {/* Session Controlls */}
                  {(role === 'session' && !currentRoom?.isInteractive) && <VideoControlls />}
                </>
              )
            }
          </div>
        </div>
        {
          screenMode === 'small'
          && (
            <div className="lg:w-[315px] w-full h-[588px] bg-white pb-2 flex flex-none rounded-xl shadow-md  overflow-y-scroll">
              {!!event && (
                <HostAndAttendees
                  event={event!}
                  attendees={attendees}
                  stage={stage}
                  queue={queue}
                  {...((role === 'moderator' || role === 'speaker') && { onMuteClick: () => dispatch(InteractiveModeratorSpeakerMuteAll(slug!, roomId!)) })}
                />
              )}
            </div>
          )
        }
      </div>
      <AlertModal isOpen={hasReceivedGuestInvite} onClose={() => { setHasReceivedGuestInvite(false); }}>
        <div className="flex flex-col justify-center items-center px-8 pt-8 gap-4">
          <div className="absolute top-3 flex justify-center text-dark-blue font-semibold mb-3 px-2">Invitation</div>
          <div className="absolute top-12 w-full border-1" />
          <div className="relative pt-4">
            <div>You are invited in to speak. Do you want to join the panel?</div>
            <div className="flex flex-row pt-4 justify-end gap-2">
              <Button
                size="medium"
                text="Reject"
                className="bg-gray-200"
                onClick={() => {
                  setHasReceivedGuestInvite(false);
                  dispatch(InteractiveResetAttendeeInviteToJoin());
                }}
              />
              <Button
                size="medium"
                text="Accept"
                className="bg-orange"
                onClick={() => {
                  setHasReceivedGuestInvite(false);
                  setHasAcceptedGuestInvite(true);
                  // navigate(`/event/${chimeInvitation?.eventId}/${chimeInvitation?.eventRoomId}/guest`);
                }}
              />
            </div>
          </div>
        </div>
      </AlertModal>
    </>
  );
};
