import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import downArrow from '../../../assets/images/website-event/arrow-down.svg';
import upArrow from '../../../assets/images/website-event/arrow-up.svg';

import { IAppState } from '../../../store';

const IndustryAudience = () => {
  const event = useSelector((state: IAppState) => state.website.event);
  const targetAudiences = useSelector(
    (state: IAppState) => state.website.targetAudiences
  );
  const [showMoreIndustries, setShowMoreIndustries] = useState(false);
  const [showMoreAudiences, setShowMoreAudiences] = useState(false);

  const industries = targetAudiences?.industries.slice(0, 4);
  const jobTitles = targetAudiences?.jobTitles.slice(0, 4);

  return (
    <div
      id="event-focus"
      className={classNames('w-full mb-[42px]', {
        hidden: event?.overview === null,
      })}
    >
      <div className="flex md:flex-row flex-col mx-auto gap-x-[26px] w-full mb-[]">
        {!!industries?.length && (
        <div className="w-full event-website-box-new md:mb-0 mb-[26px]">
          <div className="py-[23px] md:mx-[65px] mx-5">
            <div
              className="font-semibold text-[#0326E5] text-[21px] leading-[25px] mb-[15px] pb-[16px] border-b-1 border-[#CDDCF2]"
              style={{
                color: event?.colorStyle?.values?.heading,
              }}
            >
              Industries
            </div>
            <div className="">
              {targetAudiences?.industries
                && industries?.map(industry => (
                  <li
                    key={industry.id}
                    className="text-[15px] leading-[24px] font-medium text-[#3C3C3C]"
                  >
                    {industry?.name}
                  </li>
                ))}
              {!showMoreIndustries && targetAudiences?.industries?.length! > 4 && (
              <div className="flex flex-row mt-2">
                <div
                  className="text-[13px] text-[#3C3C3C] font-bold cursor-pointer"
                  onClick={() => setShowMoreIndustries(true)}
                >
                  Show more
                </div>
                <img src={downArrow} alt="" className="ml-1 mt-1" />
              </div>
              )}
            </div>
            {showMoreIndustries
              && targetAudiences?.industries?.length! > 4
              && targetAudiences?.industries
                ?.slice(4, targetAudiences?.industries?.length - 1)
                .map(industry => (
                  <li
                    key={industry.id}
                    className="text-[15px] leading-[24px] font-medium text-[#3C3C3C]"
                  >
                    {industry?.name}
                  </li>
                ))}
            {showMoreIndustries && (
            <div className="flex flex-row mt-2">
              <div
                className="text-[13px] text-[#3C3C3C] font-bold cursor-pointer"
                onClick={() => setShowMoreIndustries(false)}
              >
                Show less
              </div>
              <img src={upArrow} alt="" className="ml-1 mt-1" />
            </div>
            )}
          </div>
        </div>
        )}

        { !!jobTitles?.length && (
        <div className="w-full event-website-box-new">
          <div className="py-[23px] md:mx-[65px] mx-5">
            <div
              className="font-semibold text-[#0326E5] text-[21px] leading-[25px] mb-[15px] pb-[16px] border-b-1 border-[#CDDCF2]"
              style={{
                color: event?.colorStyle?.values?.heading,
              }}
            >
              Audience
            </div>
            <div className="">
              {targetAudiences?.jobTitles
                && jobTitles?.map(jobTitle => (
                  <li
                    key={jobTitle.id}
                    className="text-[15px] leading-[24px] font-medium text-[#3C3C3C]"
                  >
                    {jobTitle.name}
                  </li>
                ))}
              {!showMoreAudiences && targetAudiences?.jobTitles?.length! > 4 && (
              <div className="flex flex-row mt-2">
                <div
                  className="text-[13px] text-[#3C3C3C] font-bold cursor-pointer"
                  onClick={() => setShowMoreAudiences(true)}
                >
                  Show more
                </div>
                <img src={downArrow} alt="" className="ml-1 mt-1" />
              </div>
              )}
            </div>
            {showMoreAudiences
              && targetAudiences?.jobTitles?.length! > 4
              && targetAudiences?.jobTitles
                ?.slice(4, targetAudiences?.jobTitles?.length - 1)
                .map(jobTitle => (
                  <li
                    key={jobTitle.id}
                    className="text-[15px] leading-[24px] font-medium text-[#3C3C3C]"
                  >
                    {jobTitle.name}
                  </li>
                ))}
            {showMoreAudiences && (
            <div className="flex flex-row mt-2">
              <div
                className="text-[13px] text-[#3C3C3C] font-bold cursor-pointer"
                onClick={() => setShowMoreAudiences(false)}
              >
                Show less
              </div>
              <img src={upArrow} alt="" className="ml-1 mt-1" />
            </div>
            )}
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default IndustryAudience;
