import { IDropdownMenuItem } from '../../../components/dropdown-menu';
import dropdownIcon from '../../../assets/images/event-landing-pre-event/arrow-down-white.svg';
import DropDownMenu from '../../event-landing-pre-event/event-header/event-header-dropdown';

interface IConnectActionsProps {
  title: string;
  options: IDropdownMenuItem[];
}

export const ConnectActions = (props: IConnectActionsProps) => (
  <DropDownMenu
    options={props.options}
    containerClasses="flex justify-center items-center relative"
    menuCardClasses="absolute w-[210px] opacity-100 bg-white mt-6 md:-ml-[210px] -ml-[139px] shadow-xs2 text-gray-13 rounded-10 p-2 z-10 cursor-pointer top-[12px]"
    titleAndSubtitleGroup="flex justify-left items-left hover:bg-[#EDF2F5] text-left p-2 rounded-5 text-gray-13 cursor-pointer"
    buttonStyles="flex justify-center bg-[#144EE6] hover:bg-[#2e6eff] rounded-[5px] h-[31px] px-4 flex items-center"
    buttonTitleStyles="text-white text-sm1 font-semibold tracking-wide pr-2"
    buttonIcon={dropdownIcon}
    buttonTitle={props.title}
    buttonIconStyles="ml-1 mt-0"
    listTextStyles="block text-[13px] font-medium text-[#515357] px-3 h-[16px] flex items-center"
    listIconStyles="w-[16px] h-[16px]"
    textAndImageContainer="flex flex-row gap-2"
  />
);
