/* eslint-disable no-unused-vars */
import { Transition } from '@tailwindui/react';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { EventsService } from '../../../../services/events';
import { ArrowDownSvgIcon } from '../../../../shared-components/V2/IconSvg/ArrowDownSvgIcon/ArrowDownSvgIcon';
import { IAppState } from '../../../../store';

interface ICalendarList {
  title?: string;
  btnClassName?: string;
  fillColor?: string;
  titleClass?: string;
  buttonTheme?: any;
  isInvitation?: boolean;
  dropdownClass?: string;
  isFactor?: boolean;
  isRegoForm?: boolean;
}
export const CalendarList = ({
  title, btnClassName, fillColor, titleClass, buttonTheme, isInvitation, dropdownClass, isFactor, isRegoForm
}: ICalendarList) => {
  const [isOpen, setIsOpen] = useState(false);
  const container = useRef<any>(null);

  let event = useSelector((state: IAppState) => state.website.event!);
  const { upcomingEvent } = useSelector(
    (state: IAppState) => state
  );
  if (!event && upcomingEvent?.value?.data?.event) {
    event = upcomingEvent?.value?.data?.event;
  }
  const apiUrl = process.env.REACT_APP_API_URL;

  // Allow for outside click
  useEffect(() => {
    function handleOutsideClick(event: any) {
      if (!container?.current?.contains(event.target)) {
        if (!isOpen) return;
        setIsOpen(false);
      }
    }

    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [isOpen, container]);

  // Allow to use the `esc` key
  useEffect(() => {
    function handleEscape(event: any) {
      if (!isOpen) return;

      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    }

    document.addEventListener('keyup', handleEscape);
    return () => document.removeEventListener('keyup', handleEscape);
  }, [isOpen]);

  const setCalendar = async (e: any, type: string) => {
    e.preventDefault();
    const eventService: EventsService = new EventsService();
    const result = await eventService.getCalendarLink(event?.id, type);
    const { calendarLink } = result.data;
    window.open(calendarLink, '_blank');
  };

  const setCalIniCal = (e: any, type: string) => {
    e.preventDefault();
    const url = `${apiUrl}/guest/events/${event?.id}/calendar?service=${type}`;
    window.open(url, '_blank');
  };

  return (
    <div ref={container} className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className={classNames({
            'text-gray-400 rounded-full ml-[14px] items-center justify-center inline-flex': !btnClassName,
            [`${btnClassName}`]: btnClassName,
          })}
          style={buttonTheme}
          // style={{ backgroundColor: primaryBackgroundColor || '#0049EF' }}
          aria-haspopup="true"
          aria-expanded={isOpen}
          onClick={() => setIsOpen(v => !v)}
        >
          <span
            className={classNames({
              [`${titleClass}`]: titleClass,
            })}
            style={buttonTheme}
          >
            {title}
          </span>
          {' '}
          {!isInvitation && (
            <ArrowDownSvgIcon fillColor={fillColor} />
          )}
        </button>
      </div>

      <Transition
        show={!isInvitation ? isOpen : false}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={classNames('absolute z-50 sm:top-[24px] top-[15px] left-0 w-full mt-2 origin-top-right bg-white post-company-logo rounded-10', {
          [`${dropdownClass}`]: dropdownClass,
        })}
      >
        <div>
          <div
            className="p-1.5"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <Link
              className={classNames('h-[30px] flex items-center justify-start px-2.5 text-sm leading-[13px] text-[#172746] hover:rounded-5 hover:bg-[#EDF2F5] mb-[5.2px]', {
                '!text-white hover:bg-[#14161A]': isFactor || isRegoForm,
              })}
              to="/#"
              onClick={e => setCalendar(e, 'office365')}
            >
              Outlook
            </Link>

            <Link
              className={classNames('h-[30px] flex items-center justify-start px-2.5 text-sm leading-[13px] text-[#172746] hover:rounded-5 hover:bg-[#EDF2F5] mb-[5.2px]', {
                '!text-white hover:bg-[#14161A]': isFactor || isRegoForm,
              })}
              to="/#"
              onClick={e => setCalendar(e, 'google')}
            >
              Gmail
            </Link>

            <Link
              className={classNames('h-[30px] flex items-center justify-start px-2.5 text-sm leading-[13px] text-[#172746] hover:rounded-5 hover:bg-[#EDF2F5] mb-[5.2px]', {
                '!text-white hover:bg-[#14161A]': isFactor || isRegoForm,
              })}
              to="/#"
              onClick={e => setCalIniCal(e, 'ics')}
            >
              iCal
            </Link>
          </div>
        </div>
      </Transition>
    </div>
  );
};
