import FactorEventFooter from './factor-event-footer';

const FactorEventQuickLinks = () => {
  const aboutsLinks = [
    { name: 'Meet the team', link: 'https://www.factor-insights.com/team' },
    { name: 'Vision', link: 'https://www.factor-insights.com/vission-mission' },
    { name: 'Mission', link: 'https://www.factor-insights.com/vission-mission' },
    { name: 'Work at Factor ', link: 'https://www.factor-insights.com/life-at-factor' },
    { name: 'Contact us', link: 'https://www.factor-insights.com/contact-us' },
  ];

  const resources = [
    { name: 'Articles', link: 'https://www.factor-insights.com/leadership' },
    { name: 'eBooks', link: 'https://www.factor-insights.com/leadership' },
    { name: 'Sessions', link: 'https://www.factor-insights.com/leadership' },
    { name: 'Events', link: 'https://www.factor-insights.com/leadership' },
  ];

  const events = [
    { name: 'CIO', link: 'https://www.factor-insights.com/day-events' },
    { name: 'Data & AI', link: 'https://www.factor-insights.com/day-events' },
    { name: 'CFO', link: 'https://www.factor-insights.com/day-events' },
    { name: 'Security', link: 'https://www.factor-insights.com/day-events' },
    { name: 'Marketing', link: 'https://www.factor-insights.com/day-events' },
  ];

  const RegionsLinks = [
    { name: 'United Kingdom', link: 'https://www.sit-down.com/privacy-policy' },
    { name: 'Ireland', link: 'https://www.sit-down.com/about-us' },
    { name: 'Australia', link: 'https://www.sit-down.com/about-us' },
    { name: 'New Zealand', link: 'https://www.sit-down.com/about-us' },
    { name: 'Singapore', link: 'https://www.sit-down.com/about-us' },
    { name: 'Indonesia', link: 'https://www.sit-down.com/about-us' },
    { name: 'Vietnam', link: 'https://www.sit-down.com/about-us' },
    { name: 'Malaysia', link: 'https://www.sit-down.com/about-us' },
    { name: 'Philippines', link: 'https://www.sit-down.com/about-us' },
    { name: 'Thailand', link: 'https://www.sit-down.com/about-us' },
  ];

  const socialsLinks = [
    { name: 'Linkedin', link: 'https://www.linkedin.com/company/factor-insights' },
    { name: 'Panelist', link: 'https://panelist.com' },
    // { name: 'X', link: 'https://www.factor-insights.com/contact-us' },
    // { name: 'Facebook', link: 'https://www.factor-insights.com/contact-us' },
    { name: 'Instagram', link: 'https://www.instagram.com/factorinsights/' },
    { name: 'YouTube', link: 'https://www.youtube.com/@factorinsights' },
  ];

  return (
    <div className="w-full mb-[0px] mt-[113px] md:px-0 px-[15px]">
      <div className="flex">
        <div className="w-full pt-[45px]">
          <div className="md:max-w-[99%] max-w-[99%] mx-auto md:mb-[75px]">
            <div className="w-full flex flex-col justify-center items-center">
              {/* second row    */}
              <div className="w-full grid sm:grid-cols-2 grid-cols-2 md:flex md:gap-0 gap-[15px] justify-between items-start">

                <div className="flex flex-col">
                  <div className="text-[15px] leading-[20px] font-medium helvetica-neue-65-medium text-[#c4c4c4] block w-full mb-[8px]">
                    About
                  </div>
                  {aboutsLinks?.map((item, index) => (
                    <a
                      href={`${item.link}`}
                      key={index}
                      className="text-[16px] leading-[20.8px]  mb-[5px] tracking-[0.5px] text-white hover:underline font-normal helvetica-neue-45-light"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>

                <div className="flex flex-col">
                  <div className="text-[15px] leading-[20px] font-medium helvetica-neue-65-medium text-[#c4c4c4] block w-full mb-[8px]">
                    Resources
                  </div>
                  {resources?.map((item, index) => (
                    <a
                      href={`${item.link}`}
                      key={index}
                      className="text-[16px] leading-[20.8px] mb-[5px] tracking-[0.5px] text-white hover:underline font-normal helvetica-neue-45-light"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>

                <div className="flex-col hidden md:flex md:mt-0">
                  <div className="text-[15px] leading-[20px] font-medium helvetica-neue-65-medium text-[#c4c4c4] block w-full mb-[8px]">
                    Regions
                  </div>
                  {RegionsLinks?.map((item, index) => (
                    <a
                      href={`${item.link}`}
                      key={index}
                      className="text-[16px] leading-[20.8px]  mb-[5px] pointer-events-none cursor-not-allowed tracking-[0.5px] text-white hover:underline font-normal helvetica-neue-45-light"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>

                <div className="flex flex-col">
                  <div className="text-[15px] leading-[20px] font-medium helvetica-neue-65-medium text-[#c4c4c4] block w-full mb-[8px]">
                    Events
                  </div>
                  {events?.map((item, index) => (
                    <a
                      href={`${item.link}`}
                      key={index}
                      className="text-[16px] leading-[20.8px]  mb-[5px] tracking-[0.5px] text-white hover:underline font-normal helvetica-neue-45-light"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>

                <div className="flex flex-col md:mt-0">
                  <div className="text-[15px] leading-[20px] font-medium helvetica-neue-65-medium text-[#c4c4c4] block w-full mb-[8px]">
                    Social
                  </div>
                  {socialsLinks?.map((item, index) => (
                    <a
                      href={`${item.link}`}
                      key={index}
                      className="text-[16px] leading-[20.8px] mb-[5px] tracking-[0.5px] text-white hover:underline font-normal helvetica-neue-45-light"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>

                {/* <div className="md:flex hidden flex-col">
                  <div className="text-[13px] leading-[15px] font-bold helvetica-neue-65-medium text-[#C4C4C4] block w-full mb-[14px]">
                    Download ons:
                  </div>
                  <div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://apps.apple.com/hr/app/sit-down/id6449270855"
                      className="hover:underline cursor-pointer block mb-5"
                    >
                      <img className="max-w-[107px] border-1 border-white p-1 rounded-[5px]" src={AppStore} alt="App Store" />
                    </a>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.sitdown.app&hl=en_GB&gl=US"
                      className="hover:underline cursor-pointer"
                    >
                      <img className="max-w-[107px] border-1 border-white p-1 rounded-[5px]" src={PlayStore} alt="Play Store" />
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="flex flex-col items-center md:hidden mt-6">
              <div className="text-[14px] text-center leading-[34px] helvetica-neue-65-medium text-white block w-full font-medium mb-[12px]">
                Download ons:
              </div>
              <div>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://apps.apple.com/hr/app/sit-down/id6449270855"
                  className="hover:underline cursor-pointer block mb-5"
                >
                  <img className="max-w-[107px] border-1 border-white p-1 rounded-[5px]" src={AppStore} alt="App Store" />
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.sitdown.app&hl=en_GB&gl=US"
                  className="hover:underline cursor-pointer"
                >
                  <img className="max-w-[107px] border-1 border-white p-1 rounded-[5px]" src={PlayStore} alt="Play Store" />
                </a>
              </div>
            </div> */}
            {/* <div className="flex items-center md:justify-end justify-center gap-x-[10px] pt-[53px] pb-[32px] border-b border-[#707070] footer-socials">
              {socialIconLinks?.map((item, index) => (
                <a
                  key={index}
                  rel="noreferrer"
                  target="_blank"
                  href={item.link}
                  className="w-[35px] h-[35px] cursor-pointer"
                >
                  <item.icon />
                </a>
              ))}
            </div> */}
          </div>
          <FactorEventFooter />
        </div>
      </div>
    </div>
  );
};

export default FactorEventQuickLinks;
