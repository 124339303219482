import loungeAttendees from '../../../assets/images/lounge-attendees.svg';
import loungeEvents from '../../../assets/images/lounge-events.svg';
import { Button } from '../Button';
import { GroupUserIcons } from '../GroupUserIcons';
import { ThreeDotIcon } from '../IconSvg';

export interface ILoungeCardProps {
  title: string;
  description: string;
  moderators: any[];
  attendees: any[];
}

const LoungeCard = ({
  title,
  description,
  moderators,
  attendees,
}: ILoungeCardProps) => {
  return (
    <div className="bg-dark-blue-4 border-2 border-gray-4 p-4 rounded-20 w-[217px] h-[221px]">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <div aria-label="longue-title-target" className="text-white text-h3 font-light leading-7 line-clamp-2">
              {title}
            </div>
            <div aria-label="longue-description-target" className="text-white text-s-10 font-light leading-7 line-clamp-2">
              {description}
            </div>
          </div>
          <div>
            <ThreeDotIcon fillColor="GRAY" />
          </div>
        </div>
        <div className="border border-gray-17 mt-4"></div>
        <div className="flex flex-row mt-4 gap-4">
          <div className="flex flex-row gap-2">
            <div>
              <img src={loungeEvents} alt="" className="w-6 h-6" />
            </div>
            <div className="text-white text-sm4">{moderators.length}</div>
          </div>
          <div className="flex flex-row gap-2">
            <div className="">
              <img src={loungeAttendees} alt="" className="w-6 h-6" />
            </div>
            <div className="text-white text-sm4">{attendees.length}</div>
          </div>
        </div>
        <div className="mt-1">
          <GroupUserIcons users={attendees} className="text-white text-sm5" />
        </div>
        <div className="text-right">
          <Button
            className="bg-orange text-black"
            onClick={() => {}}
            size="small"
            text="Join"
          />
        </div>
        <div className="border border-gray-17 mt-3"></div>
        {attendees.length > 0 && (
          <div className="text-white text-sm4 mt-1">
            {`${attendees[0].firstName} ${attendees[0].lastName}`} &amp;{" "}
            {attendees.length - 1} others
          </div>
        )}
      </div>
    </div>
  );
};

export default LoungeCard;
