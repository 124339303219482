import 'swiper/css';

import Testimonial1 from '../../../assets/images/testimonialsSpectre/testimonial-1.svg';
import Testimonial2 from '../../../assets/images/testimonialsSpectre/testimonial-2.svg';
import Testimonial3 from '../../../assets/images/testimonialsSpectre/testimonial-3.svg';
import Testimonial4 from '../../../assets/images/testimonialsSpectre/testimonial-4.svg';
import Testimonial5 from '../../../assets/images/testimonialsSpectre/testimonial-5.svg';
import Testimonial6 from '../../../assets/images/testimonialsSpectre/testimonial-6.svg';
import Testimonial7 from '../../../assets/images/testimonialsSpectre/testimonial-7.svg';
import Testimonial8 from '../../../assets/images/testimonialsSpectre/testimonial-8.svg';
import Testimonial9 from '../../../assets/images/testimonialsSpectre/testimonial-9.svg';

// import SlideArrow from '../../../assets/images/testimonials/arrow.svg';

export const SpectreTestimmonials = () => (
  <div className="w-full">
    <div className="w-full bg-[#14161A] md:pl-[40px] pl-[20px] md:pr-[85px] pr-[20px] md:pt-[46px] pt-[26px] md:pb-[44px] pb-[24px] rounded-[6px] mt-[40px]">
      {/* <div className="w-full xl:flex 2xl:gap-x-[0px] xl:gap-x-[90px] mb-[60px]"> */}
      <div className="flex flex-col lg:flex-row justify-between gap-y-[40px]">
        <div className="w-full flex flex-col gap-[20px] md:pr-[30px]">
          <div className="2xl:w-[60%] xl:w-[86%] xl:mb-0 md:mb-5">
            <p className="md:text-[45px] text-[40px] font-normal leading-[55px] text-white helvetica-neue-55-roman">
              Consistently Connecting
            </p>
            <p className="md:text-[45px] text-[40px] font-normal leading-[55px] text-white helvetica-neue-55-roman tracking-[0.2px]">
              The Local Enterprise
            </p>
          </div>
          {/* <div className="2xl:w-[60%] xl:w-[86%]"> */}
          <div className="2xl:w-[60%] xl:w-[64%] xl:max-w-[64%]">
            <p className="text-[20px] font-medium leading-[25px] text-white helvetica-neue-45-light">
              Sit-Down is an invitation-only conference that brings together technology executives from the region’s most influential Enterprise and Government organisations.
            </p>
          </div>
        </div>

        <div className="md:w-[65%] w-full flex flex-wrap md:gap-y-0 gap-y-6">
          <div className="flex flex-row items-center justify-center md:flex-nowrap flex-wrap md:gap-0 gap-[20px]">
            <div className="w-[224px] h-[35px] flex items-center justify-center">
              <img className="w-[224px] h-[35px] object-contain grayscale transition-all" src={Testimonial1} alt="" />
            </div>

            <div className="w-[158px] h-[30px] flex items-center justify-center md:ml-[37px]">
              <img className="w-[158px] h-[30px] object-contain grayscale transition-all" src={Testimonial2} alt="" />
            </div>

            <div className="w-[105px] h-[45px] flex items-center justify-center md:ml-[49px] -mb-[21px]">
              <img className="w-[105px] h-[45px] object-contain grayscale transition-all" src={Testimonial3} alt="" />
            </div>
          </div>

          <div className=" flex flex-row items-center justify-center md:flex-nowrap flex-wrap md:gap-0 gap-[20px]">
            <div className="w-[130px] h-[31px] flex items-center justify-center">
              <img className="w-[130px] h-[31px] object-contain grayscale transition-all" src={Testimonial4} alt="" />
            </div>

            <div className="w-[130px] h-[50px] flex items-center justify-center md:ml-[48px]">
              <img className="w-[130px] h-[50px] object-contain grayscale transition-all" src={Testimonial5} alt="" />
            </div>

            <div className="w-[123px] h-[38px] flex items-center justify-center md:ml-[73px]">
              <img className="w-[123px] h-[38px] object-contain grayscale transition-all" src={Testimonial6} alt="" />
            </div>
          </div>

          <div className=" flex flex-row items-center justify-center md:flex-nowrap flex-wrap md:gap-0 gap-[20px]">
            <div className="w-[130px] h-[51px] flex items-center justify-center">
              <img className="w-[130px] h-[51px] object-contain grayscale transition-all" src={Testimonial7} alt="" />
            </div>

            <div className="w-[158px] h-[41px] flex items-center justify-center md:ml-[48px]">
              <img className="w-[158px] h-[41px] object-contain grayscale transition-all" src={Testimonial8} alt="" />
            </div>

            <div className="w-[193px] h-[41px] flex items-center justify-center md:ml-[48px]">
              <img className="w-[193px] h-[41px] object-contain grayscale transition-all" src={Testimonial9} alt="" />
            </div>
          </div>

        </div>

      </div>
      {/* <div className="relative">
        <div className="swiper-button image-swiper-button-next cursor-pointer absolute right-0 top-[18px] z-20">
          <img className="object-contain" src={SlideArrow} alt="" />
        </div>
        <div className="swiper-button image-swiper-button-prev cursor-pointer rotate-180 absolute left-0 top-[18px] z-20">
          <img className="object-contain" src={SlideArrow} alt="" />
        </div>
        <Swiper
          spaceBetween={30}
          slidesPerView={5}
          modules={[Navigation, A11y, Autoplay]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          loop
          centeredSlides
          width={400}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
            stopOnLastSlide: false,
            waitForTransition: false
          }}
          breakpoints={{
            // when window width is >= 300px
            300: {
              width: 300,
              slidesPerView: 2,
            },
            // when window width is >= 640px
            640: {
              width: 640,
              slidesPerView: 3,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 4,
            },
            // when window width is >= 1280px
            1280: {
              width: 1280,
              slidesPerView: 6,
              spaceBetween: 40,
            },
          }}
        >
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial1} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial2} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial3} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial4} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial5} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial4} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial5} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial6} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial7} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial4} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial5} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial6} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial4} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-[80px] flex items-center justify-center">
              <img className="w-full object-contain grayscale hover:grayscale-0 transition-all" src={Testimonial5} alt="" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div> */}
    </div>
  </div>
);
export default SpectreTestimmonials;
