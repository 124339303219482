/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import { Avatar, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useScrollToTop } from 'src/mui/hooks/use-scroll-to-top';

interface Props {
  otherProducts: any,
  productOwnBy:string,
}

export const ProductSuggestions = ({
  otherProducts, productOwnBy
}:Props) => {
  const { companyidorslug } = useParams();
  const navigate = useNavigate();
  useScrollToTop();
  const getProductDetail = (item: any, index: any) => (
    <Stack
      key={index}
      sx={{
        flexDirection: 'column', gap: '10px', mb: '24px', cursor: 'pointer'
      }}
      onClick={() => {
        navigate(`/wall/company/${companyidorslug}/prodcut/${item?.id}`);
      }}
    >
      <Avatar
        sx={{
          width: '201px', height: '201px', borderRadius: '20px', backgroundColor: '#ffffff', border: '2px solid #DDDDDD'
        }}
        src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.icon}`}
      />
      <Stack>
        <Typography
          color="text.primary"
          className="ellipsis-one-line"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Poppins-500', mb: '3px', maxWidth: '200px !important'
          }}
        >
          {item?.name}
        </Typography>
        <Stack direction="row" alignItems="center" gap="5px">
          <Typography sx={{
            fontSize: '15px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Poppins-400'
          }}
          >
            By
          </Typography>
          <Typography
            color="text.primary"
            variant="subtitle2"
            sx={{
              fontSize: '15px', fontWeight: 600, lineHeight: '20px', fontFamily: 'Poppins-600', color: '#0131FF', cursor: 'pointer'
            }}
            onClick={() => navigate(`/wall/company/${companyidorslug}`)}
          >
            {productOwnBy}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Stack>
      <Typography sx={{
        color: '#000000', fontSize: '20px', fontWeight: 500, lineHeight: '27px', fontFamily: 'Poppins-500'
      }}
      >
        You might also like
        {' '}
      </Typography>

      <Stack direction="row" sx={{ mt: '22px', flexFlow: 'wrap' }} gap="20px">
        { otherProducts?.map((item: any, index: any) => getProductDetail(item, index))}
      </Stack>
    </Stack>
  );
};

export default ProductSuggestions;
