import { useTheme } from "@mui/material";
import { useState } from "react"
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface IPollSvgIcon {
  isActive?: boolean;
}
export const PollSvgIcon = ({ isActive }: IPollSvgIcon) => {
  const theme = useTheme();
  const [isHover, setIsHover] = useState<boolean>(false);
  const activeColor = getBrandingColors()?.Accent || '#03FFCD';
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
  const fillColor = isHover || isActive ? activeColor : defaultColor;

  return <svg xmlns="http://www.w3.org/2000/svg" width="29.242" height="28.632" viewBox="0 0 29.242 28.632"
  onMouseEnter={() => setIsHover(true)}
  onMouseLeave={() => setIsHover(false)}>
    <path id="survey-svgrepo-com" d="M3.967,3H25.618a2,2,0,0,1,1.968,2.029V7.063a2,2,0,0,1-1.968,2.029H3.967A2,2,0,0,1,2,7.063V5.029A2,2,0,0,1,3.967,3Zm-.049,9.138h12a1.986,1.986,0,0,1,1.919,2.029V16.2A1.986,1.986,0,0,1,15.92,18.23h-12A1.986,1.986,0,0,1,2,16.2V14.167A1.986,1.986,0,0,1,3.919,12.138ZM24.54,18.23a6.7,6.7,0,1,1-6.7,6.7,6.7,6.7,0,0,1,6.7-6.7ZM28.8,23.055a.512.512,0,0,0,0-.609l-.682-.609a.469.469,0,0,0-.682,0L23.785,25.93l-1.657-1.657a.469.469,0,0,0-.682,0l-.682.609a.414.414,0,0,0,0,.609L23.1,27.782a.963.963,0,0,0,.682.292.883.883,0,0,0,.682-.292ZM3.949,21.276H15.256a9.832,9.832,0,0,0-.609,3.046,10.423,10.423,0,0,0,.292,3.046H3.949A2,2,0,0,1,2,25.339V23.3H2a2,2,0,0,1,1.949-2.029Z" transform="translate(-1.999 -3)" fill={fillColor}/>
  </svg>
};

