import { SpeakerDetails } from '../factor-speaker-details';

interface Props {
  industrySpeakers : any[],
  isEventDateWithin80Days:boolean;
}

export const FactorIndustrySpeakers = ({ industrySpeakers, isEventDateWithin80Days } : Props) => (
  <div className="mt-[100px] px-[15px] md:px-0">
    <div className="text-white md:leading-[45px] leading-[45px] md:text-[52px] text-[37px] font-bold helvetica-neue-65-medium">
      {isEventDateWithin80Days ? 'Industry Speakers' : 'Past Audiences'}
    </div>
    <div className="mt-[22px]">
      <div className="flex flex-row flex-wrap gap-y-[40px] xl:gap-x-[calc(100%-1050px)] lg:gap-x-[calc(100%-892px)] md:gap-x-[calc(100%-420px)] gap-x-0">
        { industrySpeakers?.map((speaker) => (<SpeakerDetails speaker={speaker} />)) }
      </div>
    </div>
  </div>
);
