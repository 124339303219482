import React from 'react';
import offer from '../../../assets/images/signup/tick-done.svg';
import { Button, Stack, Typography } from '@mui/material';
import useStyling from 'src/mui/components/hook-form/useStyling';
import warningInfoIcon from '../../../assets/images/company/warning.png';

type Props = {
  attendeesListView?: boolean
  userExitedSurvey?: boolean
  description?:string
}

function PastEvent({ attendeesListView , userExitedSurvey,description }: Props) {
  const { secondaryFontColor } = useStyling(null, null);
  return (
    <Stack
      sx={{
        direction: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        // width: '90%',
        '@media (max-width: 768px)': {
          margin: '35px',
        },

        '@media (min-width: 768px)': {
          height: '100%',
        },
      }}
    >
      <>
      { !userExitedSurvey && <img
          src={offer}
          className="flex justify-center mobile-tick"
          alt="Close"
          style={{ height: '70px', width: '70px' }}
        />
      }
    { !userExitedSurvey && <Typography sx={{ mt: 2, color: secondaryFontColor, fontFamily: 'Poppins-400' }}>Done!</Typography>}
        <Typography
          sx={{ mt: 2, color: secondaryFontColor, fontFamily: 'Poppins-400' }}
          dangerouslySetInnerHTML={{
            __html:  userExitedSurvey ? `${description}` :
            attendeesListView === false
              ? 'Your registration is now complete.<br/>For more Information contact your host.'
              : 'Your are Done!',
          }}
        />
      </>
    </Stack>
  );
}

export default PastEvent;
