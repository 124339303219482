/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { LOGIN_SIGNUP_RECOVERY } from '../../../../constants';
// import { CustomSelectorComp } from '../../../../components/selectors/custom-selector';
import { TextInput } from '../../../../shared-components';
import { emailRegex } from '../../../../utils';

interface IComplimentaryPassForm {
  firstName: string;
  lastName: string;
  businessEmail: string;
  Organisation: string;
  jobTitle: string;
  mobileNumber: string;
  attendees: string;
  vendor: string;
  senior: string;
}

// const interestedRooms = [
//   { label: 'Select Room', value: 'WP' },
//   { label: 'CDO', value: 'HB' },
//   { label: 'CIO', value: 'VT' },
//   { label: 'CISO', value: 'IP' },
// ];

export const RegisterForm = () => {
  const {
    register,
  } = useForm<IComplimentaryPassForm>();
  return (
    <div className="w-full mt-[15px]">
      <div className="w-full">
        <div className="w-full">
          <form className="w-full">
            <div className="md:flex justify-between gap-x-[16px]">
              <div className="w-full md:mb-0 mb-5">
                <div className="w-full mb-[20px] relative">
                  <TextInput
                    textinputsize="medium"
                    caption="First name*"
                    labelclass="bg-[#2d2d2d] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                    type="text"
                    classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                    {...register('firstName', {
                      required: { value: true, message: 'Your registered name is required!' }
                    })}
                  />
                </div>
                <div className="w-full mb-[20px]">
                  <TextInput
                    textinputsize="medium"
                    caption="Business email address*"
                    labelclass="bg-[#2d2d2d] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                    type="email"
                    classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                    {...register('businessEmail', {
                      required: {
                        value: true,
                        message: 'Email is required',
                      },
                      pattern: {
                        value: emailRegex,
                        message: LOGIN_SIGNUP_RECOVERY.VALID_EMAILADDRESS,
                      },
                    })}
                  />
                </div>
                <div className="w-full mb-[20px]">
                  <TextInput
                    textinputsize="medium"
                    caption="Organisation*"
                    labelclass="bg-[#2d2d2d] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                    type="text"
                    classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                    {...register('Organisation', {
                      required: { value: true, message: 'Your registered name is required!' }
                    })}
                  />
                </div>
              </div>
              <div className="w-full md:mb-0 mb-5">
                <div className="w-full mb-[20px]">
                  <TextInput
                    textinputsize="medium"
                    caption="Last name*"
                    labelclass="bg-[#2d2d2d] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                    type="text"
                    classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                    {...register('lastName', {
                      required: { value: true, message: 'Your registered name is required!' }
                    })}
                  />
                </div>
                <div className="w-full mb-[20px]">
                  <TextInput
                    textinputsize="medium"
                    caption="Job Title*"
                    labelclass="bg-[#2d2d2d] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                    type="text"
                    classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                    {...register('jobTitle', {
                      required: { value: true, message: 'Your registered name is required!' }
                    })}
                  />
                </div>
                <div className="w-full mb-[0px]">
                  <TextInput
                    textinputsize="medium"
                    caption="Mobile Number*"
                    labelclass="bg-[#2d2d2d] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white"
                    type="text"
                    classes="helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] !text-white text-[15px] px-[15px] focus-visible:outline-none"
                    {...register('mobileNumber', {
                      required: { value: true, message: 'Your registered name is required!' }
                    })}
                  />
                </div>
              </div>
            </div>
          </form>

          <div className="w-full">
            <div className="flex row items-start gap-1 mb-2.5">
              <div className="flex items-center mb-1 column relative">
                <input
                  type="checkbox"
                  autoComplete="off"
                  {...register('vendor', {
                    required: { value: true, message: 'Please checked the given field!' }
                  })}
                />
                <label className={classNames('text-[14px] leading-[17px] text-white ml-[8px] helvetica-neue-45-light')}>
                  I confirm I am not a vendor/service or solution provider in the IT/Data/Security space.
                </label>
              </div>
            </div>
            <div className="flex row items-start gap-1 mb-2.5">
              <div className="flex items-center mb-1 column relative">
                <input
                  type="checkbox"
                  autoComplete="off"
                  {...register('senior', {
                    required: { value: true, message: 'Please checked the given field!' }
                  })}
                />
                <label className={classNames('text-[14px] leading-[17px] text-white ml-[8px] helvetica-neue-45-light')}>
                  I confirm that I hold a senior role(Principal/Director/Head/VP/GM/Chief) in my organisation.
                </label>
              </div>
            </div>
            <div className="flex row items-start gap-1 mb-2.5">
              <div className="flex items-center mb-1 column relative">
                <input
                  type="checkbox"
                  autoComplete="off"
                  {...register('attendees', {
                    required: { value: true, message: 'Please checked the given field!' }
                  })}
                />
                <label className={classNames('text-[14px] leading-[17px] text-white ml-[8px] helvetica-neue-45-light')}>
                  I understand that 3 weeks before the event I will receive the attendees list(Title+Organisation) and Sit-Down will assist me in arranging 1:1 conversations with other end-users and technology providers.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegisterForm;
