import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// Layout
import { EventsLiveLayout } from '../../layouts/events-live-layout';
import { EventsService } from '../../services/events';
import { IAppState } from '../../store';
import { init, resetLiveEvent, updateCurrentSessionInformation } from '../../store/liveEvent';
import { MiniSpotlight } from '../spotlight/mini-spotlight';
import AdsAndResources from './ads-and-resources';
import Agenda from './agenda';
import Chat from './chat';
import { EventPartners } from './event-partners';
// Events Live Subcomponents
import VideoAndAudiencePanel from './video-and-audience-panel';

export const EventLive = () => {
  const { slug } = useParams();
  // Connect Statuses

  const user = useSelector((state: IAppState) => state.auth.currentUser);
  const {
    isLive,
    event,
    booths,
    attendees,
    speakers,
    ads
  } = useSelector((state: IAppState) => ({ ...state.liveEvent, ...state.chime }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * Redirect user to discussion page if not registered to the event.
   */
  useEffect(() => {
    if (event) {
      new EventsService().getEventsAttendeeStatus(event?.id!).then(res => {
        const eventAttendeeStatus = res.data.audienceStatus;
        if (eventAttendeeStatus !== 'accepted') navigate(`/event/${event && event.slug}/discussion`);
      }).catch(() => {
        // If unable to verify the status, redirect to Event discussion page.
        navigate(`/event/${event && event.slug}/discussion`);
      });
    }
  }, [event, navigate]);

  useEffect(() => {
    // If event is live we keep updating the current session information.
    const timerId = setInterval(() => {
      if (isLive) {
        dispatch(updateCurrentSessionInformation());
      } else if (!isLive) {
        clearInterval(timerId);
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [dispatch, isLive]);

  useEffect(() => {
    if (slug) dispatch(init(slug));
    return () => {
      dispatch(resetLiveEvent());
    };
  }, [slug, dispatch]);

  if (!event || !user) return null;

  return (
    <EventsLiveLayout
      hero={(
        <VideoAndAudiencePanel
          booths={booths || []}
          event={event}
          speakers={speakers}
          attendees={attendees}
        />
      )}
      footer={<EventPartners className="w-1/1" eventPartners={event.eventSponsors} />}
      leftPanel={(
        <Agenda
          rooms={event?.rooms.find((i: any) => i.id)!}
        />
      )}
      rightPanel={(
        <div className="h-full w-full">
          <AdsAndResources ads={ads} />
          <MiniSpotlight companyId={event?.companyId} slug={event?.company?.slug} eventId={event?.id} />
        </div>
)}
    >
      <Chat event={event} attendees={attendees} rooms={event.rooms} user={user} id={event.id} />
    </EventsLiveLayout>
  );
};
