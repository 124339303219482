import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  useRef
} from 'react';
import { Tooltip } from '@mui/material';
import { IAppState } from '../../../store';
import { Logo } from '../../../shared-components/V2/Logo';

const WebsiteEventPartnerSpectre = () => {
  const partners = useSelector((state: IAppState) => state.website.event!);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const openCompanyWindow = (siteUrl: string) => {
    const url = siteUrl;
    window.open(url, '_blank');
  };

  if (!partners?.eventSponsors?.length) return null;

  // Function to convert hexadecimal color code to RGB
  const hexToRgb = (hex: string): [number, number, number] => {
    const hexColor = hex.replace(/^#/, '');
    const bigint = parseInt(hexColor, 16);
    const r = (bigint >> 16) & 255; // eslint-disable-line no-bitwise
    const g = (bigint >> 8) & 255; // eslint-disable-line no-bitwise
    const b = bigint & 255; // eslint-disable-line no-bitwise
    return [r, g, b];
  };

  // Function to calculate luminance
  function calculateLuminance(color: [number, number, number]): number {
    const [r, g, b] = color.map(c => c / 255);
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }

  // Determine if a color is close to white
  function isCloseToWhite(color: [number, number, number]): boolean {
    const luminanceThreshold = 0.9; // Adjust this threshold as needed
    const luminance = calculateLuminance(color);
    return luminance >= luminanceThreshold;
  }

  const isNeartoWhiteColor = (color: string) => {
    if (color) {
      const rgbColor: [number, number, number] = hexToRgb(color);
      const isNearWhite: boolean = isCloseToWhite(rgbColor);
      return isNearWhite;
    }
    return false;
  };

  return (
    <div
      id="Partners"
      className={classNames('w-full] mb-[0px]', {
        hidden: !(partners.eventSponsors && partners.eventSponsors.length > 0),
      })}
    >
      <div className="w-full pt-[42px]">
        <div
          className="text-[20px] text-white font-[500] leading-[22px] helvetica-neue-65-medium"
        >
          PARTNERS
        </div>
        <div className="flex flex-wrap w-full mt-[40px]">
          <div className="flex flex-wrap md:justify-start justify-between items-center md:gap-x-[30px] gap-x-[30px] md:gap-y-[30px] gap-y-[30px] w-full">
            <canvas ref={canvasRef} style={{ display: 'none' }} />

            {partners.eventSponsors.map(item => (
              <div className="flex flex-col items-center">
                <div
                  key={item.id}
                  // className={classNames('w-[126px] h-[126px] bg-black rounded-10 flex flex-col items-center justify-center cursor-pointer')}
                  className={classNames('max-w-[120px] max-h-[120px] h-[120px] w-[120px] flex flex-col items-center justify-center cursor-pointer bg-white rounded-[6px]', {
                    'bg-[#2d2d2d]': isNeartoWhiteColor(item?.company?.logoDominantColor),
                  })}
                  onClick={() => openCompanyWindow(`/wall/company/${item?.company?.slug}`)}
                >
                  <Logo
                    // eslint-disable-next-line no-nested-ternary
                    src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.logo ? item?.logo : item?.company?.logo ? item.company?.logo : partners.company?.logo}`}
                    className={classNames('max-w-[114px] max-h-[114px] h-[114px] w-full object-contain transition-all rounded-[5px]', {
                      'max-w-[114px] h-[114px]': item.company?.logo || !item.company?.logo,
                      // 'max-w-[114px] h-[114px]': !item.company?.logo,
                    })}
                  />
                </div>
                <div
                  className="text-[20px] text-white font-[500] leading-[22px] helvetica-neue-65-medium mt-[18px]"
                >
                  {item?.company?.name || ''}
                </div>
                <Tooltip title={item?.sponsorship || ''}>
                  <div
                    className="text-[12px] text-white font-[400] leading-[22px] helvetica-neue-45-light max-w-[120px] mt-[2px] ellipsis-one-line"
                  >
                    {item?.sponsorship || ''}
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventPartnerSpectre;
