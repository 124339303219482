import React, { FC } from 'react';
import { UserNavbar } from '../components';

interface ICompanyEventLayoutProps {
  hero?: JSX.Element;
  leftPanel: JSX.Element;
  children: JSX.Element;
  isHideNavbar?: boolean;
}
export const CompanyEventLayout: FC<ICompanyEventLayoutProps> = ({
  hero,
  leftPanel,
  children,
  isHideNavbar
}) => (
  <div className="bg-gray-1 h-full">
    {!isHideNavbar && <UserNavbar />}
    <div>
      <div className="xl:container mx-auto xl:w-[1258px] w-full xl:px-0 px-4 md:pb-0 pb-[70px]">
        <div className="rounded-b-lg overflow-hidden">
          {hero}
        </div>
        <div className="flex md:flex-row flex-col pt-4 w-full">
          <div className="flex flex-col md:pb-2 pb-4 md:max-w-[303px] md:w-auto w-full">{leftPanel}</div>
          <div className="md:mb-8 md:pl-[31px] w-full">{children}</div>
        </div>

      </div>
    </div>
  </div>
);

export default CompanyEventLayout;
