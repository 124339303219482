/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
// import BannerBgImage from '../../images/banner-bg.png';
import BannerCaption from '../../banner/banner-caption';
import VideoPlayer from '../video-player';

interface ExperienceBannerSectionProps {
  selectedTab?: string;
  setSelectedTab?: (value: string) => void;
  videos?: any[];
  isHideExtraText?: boolean;
  playInLoop?:boolean
}

const ExperienceBannerSection: React.FC<ExperienceBannerSectionProps> = ({
  selectedTab,
  setSelectedTab,
  videos,
  isHideExtraText,
  playInLoop
}) => {
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const minHeight = useMemo(() => {
    if (window.innerWidth < 768) return isHideExtraText ? '500px' : '450px';
    return isHideExtraText ? '800px' : '750px';
  }, []);

  const maxHeight = minHeight;

  return (
    <div
      className="relative w-full flex items-end justify-start text-white"
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight,
        maxHeight,
        backgroundColor: 'black',
      }}
    >
      {videos && videos?.length > 0 && (
        <VideoPlayer isAutoPlay videos={videos} absolutePlayer playIconPosition="!-right-[16px] !top-[45px] !left-auto" classes="experienceVideo" singleVideoClass="experience-single-video" hideVideoControls playInLoop={playInLoop} minHeight={minHeight} />
      )}
      <div
        className={classNames('z-50 md:pb-[30px] pb-4 w-full flex flex-col h-[588px] justify-end', {
          'mb-[35px]': videos && videos?.length > 1,
          'md:pb-[180px]': isHideExtraText
        })}
        style={{
          backgroundImage: 'linear-gradient(to top, black 28%, transparent)',
          marginBottom: '-2px'
        }}
      >
        <div
          className={classNames('w-full max-w-[1154px] xl:px-0 mx-auto px-[22px]', {
            '!max-w-[1290px]': isHideExtraText,
          })}
        >
          <div className="w-full max-w-[550px]">
            <BannerCaption setSelectedTab={setSelectedTab} />
            {!isHideExtraText ? (
              <p className=" text-white md:leading-[23px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman md:max-w-[460px] mt-[15px]">
                Uncover the major ideas and trends defining the future of technology and business. Gain exclusive insights from Factor experts, engage with peers, and learn from top solution providers.
              </p>
            ) : null}

          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceBannerSection;
