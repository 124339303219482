import { Link } from 'react-router-dom';

import { IPeopleInYourNetworkProp } from '../../../models/people-in-your-network';
import { Avatar } from '../../../shared-components/V2';
import { ConnectSvgIcon } from '../../../shared-components/V2/IconSvg/ConnectSvgIcon/ConnectSvgIcon';

export const PeopleInYourNetworkItem = (props: IPeopleInYourNetworkProp) => (
  <div className="flex flex-row justify-between">
    <Link to={`/wall/${props.slug}`}>
      <div className="flex flex-row">
        <div className="mr-[10px]">
          <Avatar src={props.avatarImage} alt="" size="MEDIUM" />
        </div>
        <div className="flex flex-col">
          <div className="text-[13px] leading-4 text-[#000000] font-[600] line-clamp-1 overflow-hidden">{props.name}</div>
          <div className="text-[11px] font-normal leading-[17px] text-[#000000] line-clamp-1 overflow-hidden">
            {props.jobTitle}
          </div>
          <div className="text-[11px] font-normal leading-[17px] text-[#000000] line-clamp-1 overflow-hidden">
            {props.companyName}
          </div>
        </div>
      </div>
    </Link>
    <div
      className="cursor-pointer flex flex-none"
      onClick={() => props.onAddConnection && props.onAddConnection(props.id)}
    >
      <ConnectSvgIcon />
    </div>
  </div>
);

export default PeopleInYourNetworkItem;
