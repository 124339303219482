import {
  ActionCreator,
  Dispatch,
} from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IPost } from '../../../../models/post/post';
import {
  PostService,
  UserService,
} from '../../../../services';
import { ViewStoryState } from './reducer';
import {StoryViewersActionTypes} from './types';


export interface VStoryLoadingAction {
  type: StoryViewersActionTypes.STORY_VIEWERS_LOADING;
  loading: boolean;
}

export interface VStorySuccessAction {
  type: StoryViewersActionTypes.STORY_VIEWERS_SUCCESS;
  payload: any;
}
export interface VStoryErrorAction {
  type: StoryViewersActionTypes.STORY_VIEWERS_ERROR;
  errorMessage: string;
}

  export type viewStoriesActions =
  | VStoryLoadingAction
  | VStorySuccessAction
  | VStoryErrorAction;

export const viewStories: ActionCreator<
  ThunkAction<Promise<any>, ViewStoryState, null, VStorySuccessAction>
> = (postId: string) => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();

    try {
      const result = await userService.viewStory(postId);

      dispatch({
        type: StoryViewersActionTypes.STORY_VIEWERS_SUCCESS,
        payload: result,
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: StoryViewersActionTypes.STORY_VIEWERS_ERROR,
        errorMessage: error.message,
      });
    } finally {
      dispatch({
        type: StoryViewersActionTypes.STORY_VIEWERS_LOADING,
        loading: false,
      });
    }
  };
};

