/* eslint-disable no-unused-vars */
import { differenceInMinutes } from 'date-fns';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { IDropdownMenuItem } from 'src/components/dropdown-menu';
import { IPostSpotLight } from 'src/models/post/IPostSpotlight';

import { IAppState } from 'src/store';
import { IPost } from 'src/models/post/post';
import { useCurrentUser } from 'src/utils/hooks';
import { PostService, UserService } from 'src/services';
import { editPost, unfollowPost } from 'src/store/events/event-post-actions/actions';
import { EVENT, POST_ACTIONS } from 'src/constants';
import { Logo } from 'src/shared-components/V2/Logo';
import { ParseContent } from 'src/utils/parse-html/ParseContent';
import { openMessageWindow } from 'src/store/newMessage';
import PostActions from 'src/components/posts/components/PostActions';
import { EditPost } from 'src/components/posts/edit-post';
import Report from 'src/components/report';
import { FlagService } from 'src/services/Flag';
import { DeletePost } from 'src/components/posts/delete-post';
import { SharePost } from 'src/components/posts/share-post';
import { hideModal } from 'src/store/modal/actions';
import { IFlagDTO } from 'src/models/flag/flag';
import Edit from '../../../../../assets/images/Icon-edit.svg';
import PDFImageViewer from './pdf-image-viewer';
import Trash from '../../../../../assets/images/trash.svg';
import Hide from '../../../../../assets/images/Eye.svg';
import blockUser from '../../../../../assets/images/block-user.svg';
import UnfollowIcon from '../../../../../assets/images/unfollowicon.svg';
import Share from '../../../../../assets/images/event-landing-pre-event/share-arrow-icon.svg';
import MenuReport from '../../../../../assets/images/event-landing-pre-event/report-alert-icon.svg';
import MenuForward from '../../../../../assets/images/event-landing-pre-event/paper-airplane-icon.svg';
import MenuCopyLink from '../../../../../assets/images/event-landing-pre-event/copy-link-icon.svg';
import PlayIcon from '../../../../../assets/images/spotlight/play.svg';
import DocIcon from '../../../../../assets/images/spotlight/doc.svg';
import GroupIcon from '../../../../../assets/images/spotlight/group.svg';

type ISpotlightProps = {
  id: string;
  content: string;
  createdAt: string;
  format: string;
  source: string | null;
  onClick: Function;
  indexSpotlight: number;
  gridSize?: number;
  post: IPostSpotLight;
  isOwnSpotlight?: boolean;
  getSpotlight: Function;
};
export const SpotlightCard = (props: ISpotlightProps) => {
  const { pdfImageUrls } = props.post;
  const userService = new UserService();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const time = differenceInMinutes(new Date(), new Date(props.createdAt)) / 3600;
  const { isModalOpen, postActions } = useSelector((state: IAppState) => state);

  const [isPostHidden, setIsPostHidden] = useState<boolean>(false);
  const [selectedSpotlight, setSelectedSpotlight] = useState<IPost>();

  const [toggleReportPostModal, setToggleReportPostModal] = useState<boolean>(false);
  const [toggleDeleteConfirmation, setToggleDeleteConfirmation] = useState<boolean>(false);
  const [toggleEditPostDialog, setToggleEditPostDialog] = useState<boolean>(false);
  const [toggleSharePostDialog, setToggleSharePostDialog] = useState<boolean>(false);

  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const mediaSource = `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${props.source}`;
  const videoBackgroundUrl = {
    backgroundImage: `url(${process.env.REACT_APP_DEFAULT_VIDEO_HLS_URL}/${props?.post?.thumbnail})`,
  };
  const imageBackgroundUrl = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL_PREFIX}/${props.source})`,
  };

  useEffect(() => {
    setToggleReportPostModal(isModalOpen.isOpen);
    setToggleDeleteConfirmation(isModalOpen.isOpen);
    setToggleEditPostDialog(isModalOpen.isOpen);
    setToggleSharePostDialog(isModalOpen.isOpen);
  }, [dispatch, isModalOpen, postActions.reportedPostId]);

  const fetchSpotlight = async (id: string) => {
    const postService: PostService = new PostService();
    try {
      const selectedSpotlight = await postService.getPost(id);
      setSelectedSpotlight(selectedSpotlight);
    } catch (e) {
      console.error(e);
    }
  };

  const getPostUrl = (postId: string) => `${window.location.origin}/home?postId=${postId}`;

  const handleHidePost = async () => {
    const postService = new PostService();
    try {
      await postService.hidePost(props.post.id);
      setIsPostHidden(true);
    } catch (error) {
      setIsPostHidden(false);
    }
  };

  const handleUnfollowPost = () => {
    dispatch(unfollowPost(props.post.id));
    toast.success(POST_ACTIONS.POST_UNFOLLOWED);
  };

  const onBlockConnection = async () => {
    try {
      await userService.blockConnection(props.post.id);
      navigate('/home');
    } catch (e) {
      console.error(e);
    }
  };

  const onCloseModal = () => {
    setToggleReportPostModal(false);
    setToggleDeleteConfirmation(false);
    setToggleEditPostDialog(false);
    setToggleSharePostDialog(false);
  };

  const onSaveData = () => {
    props.getSpotlight();
    onCloseModal();
  };

  const editPostModal = () => {
    if (props.post.id) {
      fetchSpotlight(props.post.id);
      setToggleEditPostDialog(true);
    }
  };

  const sharePostModal = () => {
    if (props.post.id) {
      fetchSpotlight(props.post.id);
      setToggleSharePostDialog(true);
    }
  };

  const ownPostOptions: IDropdownMenuItem[] = [
    {
      id: 'edit',
      type: 'icon',
      text: 'Edit',
      icon: Edit,
      onClickHandler: () => editPostModal(),
    },
    {
      id: 'delete',
      type: 'icon',
      text: 'Delete',
      icon: Trash,
      onClickHandler: () => setToggleDeleteConfirmation(true),
    },
    {
      id: 'share',
      type: '',
      text: 'Share',
      icon: Share,
      onClickHandler: () => sharePostModal(),
    },
    {
      id: 'forward',
      type: 'icon',
      text: 'Forward',
      icon: MenuForward,
      onClickHandler: () => dispatch(
        openMessageWindow({
          avatar: '',
          userDisplayName: 'New Message',
          messageText: getPostUrl(props.post.id),
        })
      ),
    },
    {
      id: 'copy-link',
      type: 'icon',
      text: 'Copy Link',
      icon: MenuCopyLink,
      onClickHandler: () => {
        navigator.clipboard.writeText(getPostUrl(props.post.id));
        toast.success(EVENT.COPY);
      },
    },
  ];

  const otherUsersPostOptions: IDropdownMenuItem[] = [
    {
      id: 'hide',
      type: 'icon',
      text: 'Hide',
      icon: Hide,
      onClickHandler: () => handleHidePost(),
    },
    {
      id: 'report',
      type: 'icon',
      text: 'Report',
      icon: MenuReport,
      onClickHandler: () => setToggleReportPostModal(true),
    },
    {
      id: 'unfollow',
      type: 'icon',
      text: 'Unfollow',
      icon: UnfollowIcon,
      onClickHandler: () => handleUnfollowPost(),
    },
    {
      id: 'block',
      type: 'icon',
      text: 'Block',
      icon: blockUser,
      onClickHandler: () => onBlockConnection(),
    },
    {
      id: 'forward',
      type: 'icon',
      text: 'Forward',
      icon: MenuForward,
      onClickHandler: () => dispatch(
        openMessageWindow({
          avatar: '',
          userDisplayName: 'New Message',
          messageText: getPostUrl(props.post.id),
        })
      ),
    },
    {
      id: 'copy-link',
      type: 'icon',
      text: 'Copy Link',
      icon: MenuCopyLink,
      onClickHandler: () => {
        navigator.clipboard.writeText(getPostUrl(props.post.id));
        toast.success(EVENT.COPY);
      },
    },
  ];

  const handleUnHidePost = async () => {
    const postService = new PostService();
    try {
      await postService.unhidePost(props.post.id);
      setIsPostHidden(false);
    } catch (error) {
      setIsPostHidden(true);
    }
  };

  const renderHiddenPostBlock = () => isPostHidden && (
  <div
    className="px-4 py-4 flex flex-col bg-white items-center justify-center h-full"
  >
    <div className="text-sm text-blue-3 font-bold">Post hidden!</div>
    <div className="text-sm text-gray-1 text-center">
      {`${POST_ACTIONS.POST_HIDDEN} `}
      <span
        className="font-bold hover:underline cursor-pointer"
        onClick={() => handleUnHidePost()}
      >
        Undo
      </span>
    </div>
  </div>
  );

  const renderReportedPostBlock = () => postActions.reportedPostId === props.post.id && (
  <div className="px-4 py-4 text-sm text-blue-1">
    <ParseContent content={POST_ACTIONS.POST_REPORTED} />
  </div>
  );

  return (
    <div className={classNames('h-full grow relative')}>
      {renderHiddenPostBlock()}
      {renderReportedPostBlock()}
      {props.post
          && !isPostHidden
          && postActions.reportedPostId !== props.post.id && (
            <div className="absolute top-[19px] right-[9px] z-[-2]">
              <PostActions
                post={props.post}
                ownPostOptions={ownPostOptions}
                otherUsersPostOptions={otherUsersPostOptions}
                isOwnSpotlight={props.isOwnSpotlight}
              />
            </div>
      )}
      {toggleReportPostModal && (
      <AlertModal
        isOpen={toggleReportPostModal}
        onClose={onCloseModal}
        className="w-576"
        closeIconClass=" right-[18px] top-[15px] px-0"
        isCloseIcon
      >
        <Report
          id={props.post.id}
          type="post"
          onReport={async (id: string, type: string, reason: string) => {
            const flagService = new FlagService();
            const flagItem: IFlagDTO = {
              flaggableId: id,
              flaggableType: type,
              reason,
            };
            await flagService.flagItem(flagItem);
            dispatch(hideModal());
          }}
          onCloseReport={onCloseModal}
        />
      </AlertModal>
      )}
      {toggleDeleteConfirmation && (
      <AlertModal
        isCloseIcon
        closeIconClass="right-[7px] top-[14px]"
        isOpen={toggleDeleteConfirmation}
        onClose={onCloseModal}
        className="w-[374px] bg-[#EDF2F5]"
      >
        <DeletePost
          postId={props.post.id}
          message="Are you sure you want to delete this post?"
          onDelete={async () => {
            const postService = new PostService();
            await postService.deletePost(props.post.id);

            onSaveData();
          }}
          onClose={onCloseModal}
        />
      </AlertModal>
      )}
      {toggleEditPostDialog && selectedSpotlight && (
      <AlertModal
        isOpen={toggleEditPostDialog}
        onClose={onCloseModal}
        className="w-[500px]"
      >
        {selectedSpotlight.postedAsUser && (
        <EditPost
          event={selectedSpotlight.event}
          postId={selectedSpotlight.id}
          content={selectedSpotlight.content}
          source={selectedSpotlight.source}
          format={selectedSpotlight.format!}
          avatarUrl={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${selectedSpotlight.profileWall?.avatar}`}
          userDisplayName={`${selectedSpotlight?.profileWall
            ?.firstName!} ${selectedSpotlight?.profileWall?.lastName!}`}
          onEditPost={async (
            postId: string,
            content: string,
            privacy: string
          ) => {
            await dispatch(editPost(postId, content, privacy));
            onSaveData();
          }}
          location="profile"
          privacy={selectedSpotlight.privacy}
          postedAsUser
          postedAsCompany={false}
          shareEvent={selectedSpotlight.shareEvent}
          sharePost={selectedSpotlight.sharePost}
        />
        )}

        {selectedSpotlight.postedAsCompany && (
        <EditPost
          event={selectedSpotlight.event}
          postId={selectedSpotlight.id}
          content={selectedSpotlight.content}
          source={selectedSpotlight.source}
          format={selectedSpotlight.format!}
          avatarUrl={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${selectedSpotlight.postedAsCompany?.logo}`}
          userDisplayName={`${selectedSpotlight.postedAsCompany?.name}`}
          onEditPost={async (
            postId: string,
            content: string,
            privacy: string
          ) => {
            await dispatch(editPost(postId, content, privacy));
            onSaveData();
          }}
          location="profile"
          privacy={selectedSpotlight.privacy}
          postedAsUser={false}
          shareEvent={selectedSpotlight.shareEvent}
          sharePost={selectedSpotlight.sharePost}
          postedAsCompany
        />
        )}
      </AlertModal>
      )}
      {toggleSharePostDialog && selectedSpotlight && (
      <AlertModal
        isOpen={toggleSharePostDialog}
        onClose={onCloseModal}
        className="w-[500px]"
        closeIconClass=" right-[18px] top-[15px] px-0"
        isCloseIcon
      >
        {selectedSpotlight.postedAsUser && (
        <SharePost
          avatarUrl={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${currentUser.avatar}`}
          profileUrl={currentUser.slug!}
          userDisplayName={`${currentUser.firstName} ${currentUser.lastName}`}
          userId={currentUser.id!}
          jobTitle={currentUser.jobTitle}
          dateAndTime={selectedSpotlight.createdAt}
          content={selectedSpotlight.content}
          postId={selectedSpotlight.id}
          originalPostContent={selectedSpotlight.content}
          originalPostSource={selectedSpotlight.source}
          originalPostFormat={selectedSpotlight.format!}
          originalPostedAsUser={selectedSpotlight.postedAsUser}
          shareEvent={selectedSpotlight.shareEvent}
          onAfterShare={onSaveData}
          sharePost={selectedSpotlight.sharePost}
        />
        )}
        {selectedSpotlight.postedAsCompany && (
        <SharePost
          avatarUrl={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${currentUser.avatar}`}
          profileUrl={currentUser.slug!}
          userDisplayName={`${currentUser.firstName} ${currentUser.lastName}`}
          userId={currentUser.id!}
          jobTitle={`${selectedSpotlight.postedAsCompany?.industry?.name} ${selectedSpotlight.postedAsCompany?.companySize?.name} employees`}
          dateAndTime={selectedSpotlight.createdAt}
          content={selectedSpotlight.content}
          postId={selectedSpotlight.id}
          originalPostContent={selectedSpotlight.content}
          originalPostSource={selectedSpotlight.source}
          originalPostFormat={selectedSpotlight.format!}
          originalPostedAsCompany={selectedSpotlight.postedAsCompany}
          shareEvent={selectedSpotlight.shareEvent}
          onAfterShare={onSaveData}
        />
        )}
      </AlertModal>
      )}
      {!isPostHidden && postActions.reportedPostId !== props.post.id && (
      <div
        className={classNames(
          'bg-cover relative bg-black transparent overflow-hidden cursor-pointer h-full',
          {
            ' rounded-tl-0': props.indexSpotlight === 0,
            ' rounded-tr-0':
                  (props.indexSpotlight === 3 && props.gridSize === 3)
                  || (props.indexSpotlight === 6 && props.gridSize === 3)
                  || (props.indexSpotlight > 6 && props.gridSize === 7),
          }
        )}
        onClick={() => props.onClick(props.id)}
      >
        {props.format === 'image' && (
        <div className="feed-card">
          <div style={imageBackgroundUrl} className="feed-bg-blur" />
          <Logo
            className="spotlight-card-media"
            src={mediaSource}
            alt={props.content}
          />
        </div>
        )}
        {props.format === 'video' && (
        <div className="feed-card">
          <div style={videoBackgroundUrl} className="feed-bg-blur" />
          <video className="spotlight-card-media" controls={false}>
            <source src={mediaSource} />
            <track kind="captions" />
          </video>
        </div>
        )}
        {props?.format === 'document' && pdfImageUrls && pdfImageUrls.length > 0 && (
        <PDFImageViewer isSinglePage={pdfImageUrls.length === 1} className="pdf-image-viewer" pdfImageUrls={pdfImageUrls} onScreenClick={() => props.onClick(props.id)} />
        )}

        {props.format === 'document' && props.source && pdfImageUrls && pdfImageUrls.length === 0 && (
        <iframe
          title={props.content}
          className="bg-cover w-full h-full"
          src={`https://docs.google.com/viewerng/viewer?url=${process.env.REACT_APP_IMAGE_URL_PREFIX}/${props.source}&embedded=true`}
        />
        )}
        <div className="flex flex-col justify-between h-full absolute top-0 left-0 w-full">
          <div className="absolute bottom-0 w-[145px] m-h-[145px] p-4">
            <div className="flex items-center justify-between gap-[6px]">
              <img
                src={
                      // eslint-disable-next-line no-nested-ternary
                      props.format === 'document'
                        ? DocIcon
                        : props.format === 'video'
                          ? PlayIcon
                          : GroupIcon
                    }
                alt="group"
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default SpotlightCard;
