import {  useState } from 'react';
// @mui
import { Box, Button, colors, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, Stack, TextField, Typography, } from '@mui/material';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import SearchNotFound from '../../../components/search-not-found';
import { HostAndAttendee } from '../../audience/attendee';
import { getBrandingColors } from './liveEventStyling';
import Crosscon from '../reg-dialog-flow/Crosscon';
import { borderRadius } from '@mui/system';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { UserService } from 'src/services';
import { closeMessageWindow, openMessageWindow } from 'src/store/newMessage';
import { PeopleListFeedback } from 'src/screens/search-results/peopleListFeedback';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 55;

type Props = {
  assignee?: any[];
  open: boolean;
  onClose: VoidFunction;
  selectedSponsorDetails: any;
  setOpenFeedbackPopup: (val: boolean) => void;
};



export default function LeaveFeedbackDialog({
  selectedSponsorDetails,
  open,
  onClose,
  setOpenFeedbackPopup,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userService = new UserService();
  const [search, setSearch] = useState('');

  const handleSearchTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const getCompanyTeam = (company: any) => (
    <div
      // style={{ position: "relative", display: "flex", flexDirection: "row" }}
    >
      <PeopleListFeedback
        onClick={(user: any) => {
          navigate(`/wall/${user.slug}`);
        }}
        people={dataFiltered}
        onConnect={async (userId: string) => {
          await userService.sendConnectionRequest({
            connectionUserId: userId
          });
        }}
        onMessage={async (user: any) => {
          await dispatch(closeMessageWindow());
          await dispatch(
            openMessageWindow({
              avatar: user.avatar!,
              userDisplayName: `${user.firstName} ${user.lastName}`,
              userId: user.id
            })
          );
          setOpenFeedbackPopup(false)
        }}
      />
      {/* <Grid
        rowGap="21px"
        columnGap="0px"
        columnSpacing="21px"
        container
        direction="row"
      >

        {dataFiltered?.map((item: any, index: number) => (
          <Grid key={index} item xs={6} md={4} lg={4}>
            <HostAndAttendee
              boothId={company?.booth.id}
              attendee={item}
              title=""
              viewType={true}
              isMeeting={true}
              showCallButton={false}
              showMeetLater={false}
              setOpenFeedbackPopup={setOpenFeedbackPopup}
            />
          </Grid>
        ))}
      </Grid> */}
    </div>
  );

  const dataFiltered = applyFilter({
    inputData: selectedSponsorDetails?.assignedManagers || [], // Ensure it's an array or empty array
    query: search, // The search query
  });
    const isNotFound = !dataFiltered?.length && !!search;


  return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        sx={{
          '@media (max-width: 768px)': {
            margin: "15px",
            marginTop: "5px"
          },
          "& .MuiDialog-paper": {
            // ...hideScrollbarY,
            // minHeight: "400px",
            width: '581px',
            minWidth: "581px",
            borderRadius: "10px",
            // maxHeight: "100%",
            background: getBrandingColors()?.Main || "#2F2F2F",
            '@media (max-width: 768px)': {
              minWidth: "100%",
              // maxHeight: "100%",
              // ...hideScrollbarY,
            },
            '@media (min-width: 1700px)': {
              maxWidth: "581px",
            },
          },

          "& .MuiPaper-root": {
            borderRadius: "10px !important",
            "& .MuiCard-root": {
              background: "none",
              borderRadius: "10px !important"
            }
          }

        }}
      >
      <Stack sx={{
          flexDirection: "column",
          width: '100%',
          justifyContent: "space-between",
          position: 'relative',
          px: '18px',
          py: '15px',
          pb: '13px',
          mb: 0
        }}>

        <Typography
          className="ellipsis-one-line"
          variant="body2"
          sx={{
            fontSize: "16px",
            lineHeight: "20px",
            color: getBrandingColors()?.Text || "white",
            fontFamily: "Poppins-500",
            fontWeight: 500
          }}
        >
          Leave feedback 
        </Typography>

        <IconButton
          onClick={onClose}
          sx={{
            zIndex: "100000000",
            position: "absolute",
            textAlignLast: "end",
            right: 14,
            top: 8,
            alignItems: "flex-end",
            justifyContent: "flex-end",
            p: "2px",
          }}
        >
          <Crosscon style={{ stroke: getBrandingColors()?.Text || "#ffffff", width: '22px' }} />
        </IconButton>
        </Stack>
      <Divider sx={{ mx: '18px', borderColor: getBrandingColors()?.Text || "white", mb: '15px', opacity: '40%' }} />
      <Box sx={{ px: '18px', pb: '5px' }}>
        <TextField
          fullWidth
          value={search}
          onChange={handleSearchTeam}
          placeholder="Search..."
          sx={{
            '& .MuiInputBase-input': {
              color: getBrandingColors()?.Text,
              padding: '7.5px 14px 7.5px 0px'
            },
            '& .MuiOutlinedInput-root': {
              backgroundColor: getBrandingColors()?.Secondary,
              borderRadius: '4px'
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <DialogContent sx={{ p: 0 }}>
        {isNotFound ? (
          <SearchNotFound query={search} sx={{ mt: 3, mb: 10, color: getBrandingColors()?.Text }} />
        ) : (
          <Scrollbar
            sx={{
              px: '18px',
              height: ITEM_HEIGHT * 7,
            }}
          >
            {getCompanyTeam(selectedSponsorDetails)}
          </Scrollbar>
        )}
      </DialogContent>

      {/* <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" color="inherit" onClick={onClose} sx={{
          backgroundColor: getBrandingColors()?.Secondary,
          color: getBrandingColors()?.Text,
          border: 'none',
          borderRadius: '5px'
        }}>
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  query,
}: {
  inputData: { email: string; firstName: string; lastName: string; slug: string }[];
  query: string;
}) {
  if (!query) {
    return inputData;
  }

  const lowercasedQuery = query.toLowerCase();

  return inputData.filter((data) => {

    return (
      (data?.email && data.email.toLowerCase().includes(lowercasedQuery)) ||
      (data?.firstName && data.firstName.toLowerCase().includes(lowercasedQuery)) ||
      (data?.lastName && data.lastName.toLowerCase().includes(lowercasedQuery)) ||
      (data?.slug && data.slug.toLowerCase().includes(lowercasedQuery))
    );
  });
}

