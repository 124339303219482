import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IJobFunctionsResult } from '../../../models/company/JobFunctionsResult';
import { IJobFunctionState } from '../../../models/company/JobFunctionsState';
import { CompanyService } from '../../../services';
import { JobFunctionActionTypes } from './types';

export interface IJobFunctionLoadingAction {
  type: JobFunctionActionTypes.JOB_FUNCTIONS_LOADING;
  loading: boolean;
}

export interface IJobFunctionSuccessAction {
  type: JobFunctionActionTypes.JOB_FUNCTIONS_SUCCESS;
  data: IJobFunctionsResult;
}

export interface IJobFunctionErrorAction {
  type: JobFunctionActionTypes.JOB_FUNCTIONS_ERROR;
  errorMessage: string;
}

export type JobFunctionActions =
  | IJobFunctionLoadingAction
  | IJobFunctionSuccessAction
  | IJobFunctionErrorAction;

export const getJobFunction: ActionCreator<
  ThunkAction<Promise<any>, IJobFunctionState, null, IJobFunctionSuccessAction>
> = (search: string, limit: number) => {
  return async (dispatch: Dispatch) => {
    const companyService: CompanyService = new CompanyService();

    try {
      const result = await companyService.getJobFunctions(search, limit);

      dispatch({
        type: JobFunctionActionTypes.JOB_FUNCTIONS_SUCCESS,
        data: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: JobFunctionActionTypes.JOB_FUNCTIONS_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: JobFunctionActionTypes.JOB_FUNCTIONS_LOADING,
        loading: false
      });
    }
  };
};

export const loadCompanyProfilePreview: ActionCreator<
  ThunkAction<any, IJobFunctionState, null, IJobFunctionLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: JobFunctionActionTypes.JOB_FUNCTIONS_LOADING,
    loading: shouldLoad
  });
