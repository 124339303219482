const componentToHex = (c: number) => {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};

export const rgbToHex = (rgb: number[]) => {
  return (
    "#" +
    componentToHex(rgb[0]) +
    componentToHex(rgb[1]) +
    componentToHex(rgb[2])
  );
};

export const hexToRgba = (hex: string, opacity = 1): string => {
  // Remove the '#' if it exists
  const cleanedHex = hex.replace('#', '');

  // Parse the hex color into RGB
  const bigint = parseInt(cleanedHex, 16);
  const r = Math.floor(bigint / 65536) % 256;
  const g = Math.floor(bigint / 256) % 256;
  const b = bigint % 256;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
