import { SVGProps } from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
}

function ConnectionSvgIcon({ fillColor, fillBgColor, ...props }: Props) {
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
      <svg id="Group_21309" data-name="Group 21309" xmlns="http://www.w3.org/2000/svg" width="19" height="15" viewBox="0 0 19 15">
      <g id="Group_20607" data-name="Group 20607" transform="translate(0 5.658)">
        <g id="Group_20606" data-name="Group 20606" transform="translate(0 0)">
          <path id="Path_14881" data-name="Path 14881" d="M9.439,14.878a4.439,4.439,0,1,1,4.439-4.439A4.439,4.439,0,0,1,9.439,14.878Zm0-8.071a3.632,3.632,0,1,0,3.632,3.632A3.632,3.632,0,0,0,9.439,6.807Z" transform="translate(-5 -6)" fill={defaultColor}/>
        </g>
        <line id="Line_1612" data-name="Line 1612" x2="4.637" transform="translate(2.099 4.439)" fill="none" stroke={defaultColor} stroke-linecap="round" stroke-width="0.5"/>
        <line id="Line_1613" data-name="Line 1613" x2="4.637" transform="translate(4.418 2.121) rotate(90)" fill="none" stroke={defaultColor} stroke-linecap="round" stroke-width="0.5"/>
      </g>
      <g id="Group_20608" data-name="Group 20608" transform="translate(9.203 0)">
        <path id="Path_14744" data-name="Path 14744" d="M10.957,0A4.754,4.754,0,1,1,6.2,4.754,4.754,4.754,0,0,1,10.957,0" transform="translate(-6.203 0)" fill={defaultColor}/>
        <path id="Path_14745" data-name="Path 14745" d="M7.078,17.157a.408.408,0,0,0,.332-.178,3.775,3.775,0,0,1,6.148,0,.41.41,0,0,0,.336.179h.891a.414.414,0,0,0,.412-.419.406.406,0,0,0-.051-.2,5.331,5.331,0,0,0-9.33.008.413.413,0,0,0,.364.606Z" transform="translate(-5.769 -2.675)" fill={defaultColor}/>
      </g>
    </svg>
    
    );
}

export default ConnectionSvgIcon;