import './App.css';

import { Route, Routes } from 'react-router-dom';

// import { WebsiteEventPageNew } from 'src/screens/event';
import AppMui from 'src/mui/App';
import DeactivateAccount from 'src/screens/user/user-account-settings/deactivate-account';
import { SurveyContextProvider } from 'src/mui/providers/GeneralContext';
// import AppLiveMui from 'src/mui/AppLiveMui';
import { ShortUrl } from 'src/screens/shortUrl';
import CompanyViewAsVisitorNew from 'src/screens/company/company-as-visitor-new';
import CompanyProdcutDetails from 'src/screens/company/company-as-visitor-new/company-prodcut-details';
import CompanyAccountsLayout from 'src/screens/company/company-accounts/CompanyAccountsLayout';
import CompaniesListLayout from 'src/screens/company/company-accounts/CompaniesListLayout';
import SurveysMain from 'src/screens/surveys/SurveysMain';
import {
  AuthWrapper,
  RedirectIfAuthenticated,
  RequireAuth,
} from '../../components/auth';
import { Chat } from '../../components/chat';
import {
  Home, Login, LoginOrJoin, Signup
} from '../../screens';
import { EventLiveDiscussion } from '../../screens/about/about';
import {
  FeaturesAndPackages,
  HybridEvents,
  Overview,
  VirtualEvents,
} from '../../screens/brochure';
import { Company } from '../../screens/company/company-profile';
import {
  CommunityGuidelines,
  CookiePolicy,
  CopyrightPolicy,
  HostAgreement,
  UserAgreement,
} from '../../screens/compliance';
import { ContactUs } from '../../screens/contact-us';
// import {
//   WebsiteEventPageNew
// } from '../../screens/event';
import PreEventLandingPage from '../../screens/event-landing-pre-event';
import { EventLive } from '../../screens/event-live';
import { InteractiveEvent } from '../../screens/event-live/event-moderator';
import EventInterstitial from '../../screens/event/interstitials';
import { Events } from '../../screens/events';
import ForgotPassword from '../../screens/forgot-password/forgotpassword';
import ResetPassword from '../../screens/forgot-password/resetpassword';
import Logout from '../../screens/logout/logout';
import Lounge from '../../screens/lounge';
import { NotFound } from '../../screens/not-found';
import { OneTimeLink } from '../../screens/one-time-link/one-time-link';
import { PostDetail } from '../../screens/posts/post-detail';
import SearchResults from '../../screens/search-results';
import SpotlightPage from '../../screens/spotlight';
import { UserHome, UserProfile } from '../../screens/user';
import { VideoCall } from '../../screens/video-call';
import { UserAccountsSettings } from '../../screens/user/user-account-settings';
import { WebsocketNotifications } from '../../components/websocket-notifications';
import { Redirect } from '../../screens/redirect';

const App = () => (
  <AuthWrapper>
    <SurveyContextProvider>
      <div className="app">
        <div>
          <Routes>
            <Route element={<RedirectIfAuthenticated />}>
              <Route path="/" element={<Home />} />
              <Route path="/loginorjoin" element={<LoginOrJoin />} />
              <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="/redirect" element={<Redirect />} />
              <Route path="/home" element={<UserHome />} />
              <Route path="/spotlight" element={<SpotlightPage />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/search" element={<SearchResults />} />
              <Route path="/wall/:slug" element={<UserProfile />} />
              <Route
                path="/wall/company/:companyidorslug"
                element={<CompanyViewAsVisitorNew />}
              />
              <Route
                path="/wall/company/:companyidorslug/prodcut/:productId"
                element={<CompanyProdcutDetails />}
              />
              <Route
                path="/wall/company/:companyidorslug/edit"
                element={<Company />}
              />
              <Route
                path="/wall/company/:companyId/accounts-list"
                element={<CompanyAccountsLayout />}
              />
              <Route
                path="/wall/company/:companyId/accounts/:accountId"
                element={<CompaniesListLayout />}
              />
              <Route
                path="/wall/company/:companyidorslug/edit/new"
                element={<Company />}
              />
              <Route path="/wall/company/create" element={<Company />} />
              <Route
                path="/event/:eventidorslug/discussion"
                element={<PreEventLandingPage />}
              />
              <Route path="old/event/:slug/live" element={<EventLive />} />
              {/* <Route path="/old/event/:slug/live" element={<LiveEvent />  } /> */}
              <Route
                path="/event/:slug/:roomId/:role"
                element={<InteractiveEvent />}
              />
              <Route path="/events" element={<Events />} />
              <Route path="/events/:param" element={<Events />} />
              <Route path="/post/:postid" element={<PostDetail />} />
              <Route path="/lounge" element={<Lounge />} />
              <Route
                path="/settings/account-and-privacy/account-settings"
                element={<UserAccountsSettings />}
              />
              <Route
                path="/settings/account-and-privacy/deactivate-account"
                element={<DeactivateAccount />}
              />
              {/* <Route path="/:hostName/*" element={<AppLiveMui />} /> */}
            </Route>

            {/*
            Public path
          */}
            <Route path="/join" element={<Signup />} />
            <Route path="/m/:shortUrl" element={<ShortUrl />} />
            <Route path="/one-time-link" element={<OneTimeLink />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            {/* <Route
            path="/event/:eventIdOrSlug/new"
            element={<WebsiteEventPageNew />}
          /> */}

            <Route path="/overview" element={<Overview />} />
            <Route path="/hybrid-events" element={<HybridEvents />} />
            <Route path="/virtual-events" element={<VirtualEvents />} />
            <Route
              path="/features-and-packages"
              element={<FeaturesAndPackages />}
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about" element={<EventLiveDiscussion />} />
            <Route path="/user-agreement" element={<UserAgreement />} />
            <Route path="/host-agreement" element={<HostAgreement />} />
            <Route path="/copyright-policy" element={<CopyrightPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route
              path="/community-guidelines"
              element={<CommunityGuidelines />}
            />
            <Route
              path="/event/:eventId/session/:sessionId/interstitial"
              element={<EventInterstitial />}
            />
            <Route path="/event/*" element={<AppMui />} />
            {/* <Route path="/sit-down/*" element={<AppMui />} /> */}
            <Route path="/:hostName/*" element={<AppMui />} />
            <Route path="/survey/*" element={<SurveysMain />} />
            <Route element={<NotFound />} />
          </Routes>
        </div>
        {/* User Chat */}
        <Chat />
        {/* User Notifications */}
        {/* <UserNotification /> */}
        {/* Video Call */}
        <VideoCall />
        {/* Websocket notifications */}
        <WebsocketNotifications />
      </div>
    </SurveyContextProvider>
  </AuthWrapper>
);

export default App;
