import { Reducer } from 'redux';

import { IPostCreatedState } from '../../../models/post/PostCreatedState';
import { CreateUpcomingEventPostActions } from './actions';
import { CreateUpcomingEventPostActionTypes } from './types';

const initialState: IPostCreatedState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const CreateUpcomingEventPostReducer: Reducer<
  IPostCreatedState,
  CreateUpcomingEventPostActions
> = (state = initialState, action) => {
  switch (action.type) {
    case CreateUpcomingEventPostActionTypes.CREATE_POST_IN_PROGRESS: {
      return {
        ...state,
        inProgress: state.loading,
        errorMessage: ''
      };
    }
    case CreateUpcomingEventPostActionTypes.CREATE_POST_SUCCESS: {
      return {
        ...state,
        errorMessage: '',
        value: action.data
      };
    }

    case CreateUpcomingEventPostActionTypes.CREATE_POST_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
