import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ICreateComment } from '../../../models/post/comment';

import { ICommentProps, IUserProps } from '../../../models/post/post';
import { PostService } from '../../../services';
import { IAppState } from '../../../store';
import { getCommentReplies } from '../../../store/user/post-comments';
import { useCurrentUser } from '../../../utils/hooks';
import CreateComment from '../create-comment';
import EditCommentField from '../EditCommentField';
import CommentItem from './comment-item';
import classNames from 'classnames';

const ViewComment = ({
  id,
  postId,
  content,
  image,
  commentedAsUser,
  commentedAsCompany,
  createdAt,
  totalClaps,
  totalHahas,
  totalLikes,
  totalLoves,
  totalSmiles,
  totalComments,
  likes,
  onCommentLike,
  addReply,
  onAfterDeleteComment,
  onUpdatePost,
  isLivePage
}: ICommentProps) => {
  const [showReplySection, setShowReplySection] = useState(false);
  const [showEditComment, setShowEditComment] = useState<boolean>(false);
  const [showEditReply, setShowEditReply] = useState<string>('');
  const replies = useSelector((state: IAppState) => state.postComments.replies);

  const dispatch = useDispatch();

  const onRepliesClick = (commentId: string) => {
    setShowReplySection(!showReplySection);
    dispatch(getCommentReplies(postId, commentId));
  };

  const onCommentClick = () => {
    setShowEditComment(true);
  };

  const currentUser = useCurrentUser();

  const commentAsUser: IUserProps = {
    id: currentUser.id,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    avatar: currentUser.avatar!,
    cover: currentUser.cover!,
    location: currentUser.location,
    slug: currentUser.slug,
    jobTitle: currentUser.jobTitle,
    company: currentUser.company,
  };

  return (
    <div className="flex flex-col">
      {showEditComment ? (
        <EditCommentField
          onCancelEdit={setShowEditComment}
          user={commentAsUser}
          company={commentedAsCompany}
          content={content}
          onCommentPost={async (
            commentText: any,
            attachment: any,
            companyId?: string
          ) => {
            try {
              if (companyId) {
                const companyComment: ICreateComment = {
                  content: commentText,
                  parentId: id,
                  companyId,
                  type: 'company',
                };
                const postService = new PostService();
                await postService.editComment(id, companyComment);
              } else {
                const simpleComment: ICreateComment = {
                  content: commentText,
                };
                const postService = new PostService();
                await postService.editComment(id, simpleComment);
              }
              onUpdatePost!().then(() => {
                setShowEditComment(false);
              });
              return true;
            } catch (err) {
              console.error('Failed to edit comment with error', err);
              return false;
            }
          }}
        />
      ) : (
        <div>
          <CommentItem
            key={id}
            commentId={id}
            avatar={commentedAsUser?.avatar!}
            username={`${commentedAsUser?.firstName} ${commentedAsUser?.lastName}`}
            createdAt={createdAt}
            jobTitle={commentedAsUser?.jobTitle!}
            companyName={commentedAsUser?.company?.name!}
            content={content}
            image={image}
            totalClaps={totalClaps}
            totalHahas={totalHahas}
            totalLikes={totalLikes}
            totalLoves={totalLoves}
            totalSmiles={totalSmiles}
            totalComments={totalComments}
            likes={likes}
            commentedAsUser={commentedAsUser}
            commentedAsCompany={commentedAsCompany}
            onCommentLike={onCommentLike}
            onRepliesClick={() => onRepliesClick(id)}
            onCommentClick={() => onCommentClick()}
            onDeleteComment={async () => {
              const postService = new PostService();
              await postService.deleteComment(id);
              if (onAfterDeleteComment) await onAfterDeleteComment();
            }}
            isLivePage={isLivePage}
          />
        </div>
      )}
      {showReplySection && (
        <>
          {replies.length > 0 && (
            <div
             className={classNames('w-full pt-[18.9px]', {
                '!pt-[15px]': isLivePage,
              })}
            >
              {replies.map((reply, index) => (
                <div
                  className={classNames('sm:pl-[50px] pl-[30px] mb-[10px] last:mb-0', {
                    '!pl-[35px] ': isLivePage,
                  })}
                >
                  {showEditReply === reply.id ? (
                    <EditCommentField
                      onCancelEdit={setShowEditReply}
                      user={reply.commentedAsUser}
                      company={reply.commentedAsCompany}
                      content={reply.content}
                      onCommentPost={async (
                        commentText: any,
                        attachment: any,
                        companyId?: string
                      ) => {
                        try {
                          if (companyId) {
                            const companyComment: ICreateComment = {
                              content: commentText,
                              parentId: reply.id,
                              companyId,
                              type: 'company',
                            };
                            const postService = new PostService();
                            postService
                              .editComment(reply.id, companyComment)
                              .then(() => {
                                dispatch(getCommentReplies(postId, id));
                                setShowEditReply('');
                              });
                          } else {
                            const simpleComment: ICreateComment = {
                              content: commentText,
                            };
                            const postService = new PostService();
                            postService
                              .editComment(reply.id, simpleComment)
                              .then(() => {
                                dispatch(getCommentReplies(postId, id));
                                setShowEditReply('');
                              });
                          }
                          return true;
                        } catch (err) {
                          console.error(
                            'Failed to edit comment with error',
                            err
                          );
                          return false;
                        }
                      }}
                    />
                  ) : (
                    <CommentItem
                      key={reply.id}
                      commentId={reply.id}
                      avatar={reply.commentedAsUser?.avatar!}
                      username={`${reply.commentedAsUser?.firstName} ${reply.commentedAsUser?.lastName}`}
                      createdAt={reply.createdAt}
                      jobTitle={reply.commentedAsUser?.jobTitle!}
                      companyName={reply.commentedAsUser?.company?.name}
                      content={reply.content}
                      image={reply.image}
                      totalClaps={reply.totalClaps}
                      totalHahas={reply.totalHahas}
                      totalLikes={reply.totalLikes}
                      totalLoves={reply.totalLoves}
                      totalSmiles={reply.totalSmiles}
                      totalComments={reply.totalComments}
                      likes={reply.likes}
                      commentedAsUser={reply.commentedAsUser}
                      commentedAsCompany={reply.commentedAsCompany}
                      onCommentLike={onCommentLike}
                      onDeleteComment={async () => {
                        const postService = new PostService();
                        await postService.deleteComment(reply.id);
                        if (onAfterDeleteComment) await onAfterDeleteComment();
                      }}
                      onCommentClick={() => setShowEditReply(reply.id)}
                      isSubComment
                      isLivePage={isLivePage}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          <div className="pl-[50px] pb-[15px] pt-[14.7px]">
            <CreateComment
              isLivePage={isLivePage}
              user={commentAsUser}
              company={commentedAsCompany}
              onCommentPost={async (
                commentText: any,
                attachment: any,
                companyId?: string
              ) => {
                try {
                  if (companyId) {
                    if (addReply) {
                      await addReply(
                        postId,
                        commentText,
                        attachment,
                        companyId
                      );
                      dispatch(getCommentReplies(postId, id));
                    }
                  } else if (addReply) {
                    await addReply(postId, commentText, attachment);
                    dispatch(getCommentReplies(postId, id));
                  }
                  return true;
                } catch (err) {
                  console.error('Failed to add comment with error', err);
                  return false;
                }
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ViewComment;
