/* eslint-disable */
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IEvent } from 'src/models/event/event';
import { IAttendee } from 'src/models/events-live/attendee';
import { IEventResource } from 'src/models/events-live/resources';
import { IAppState } from 'src/store';
import { InteractiveAttendeeJoin } from 'src/store/interactive-event/attendee/actions';
import { InteractiveModeratorCallJoin, InteractiveModeratorStartBroadCast } from 'src/store/interactive-event/moderator/actions';
import { updateCurrentSessionInformation } from 'src/store/liveEvent';
import { unsetInteractiveEvent } from 'src/store/interactive-event';
import { useCurrentUser, useExpoWebSocket } from 'src/utils/hooks';
import { Hero } from './hero';
import { InteractiveModeratorSpeakerActiveList, InteractiveModeratorSpeakerQueueList, InteractiveModeratorSpeakerUserExit } from 'src/store/interactive-event/moderator-speaker';
import { InteractiveSpeakerJoin } from 'src/store/interactive-event/speaker/actions';
import { Participants, QueueParticipants } from 'src/store/interactive-event/types';
import { getCompanyName } from 'src/utils';

type InteractiveEventLiveProps = {
  slug: any;
  roomId: any;
  role: any;
  isGridView?: boolean;
  setIsHandoutsOpen: (value: boolean) => void;
  setIsPollsOpen: (value: boolean) => void;
  setIsAdsOpen: (value: boolean) => void;
  isPollsOpen: boolean;
  calculateVideoGridHeight?:any;
  setRoomID?: (value: any) => void;
  isShowAds?: boolean;
  isShowHandouts?: boolean;
}


export const InteractiveEventLive = memo(({ slug, roomId, role, isGridView, setIsHandoutsOpen, setIsPollsOpen, setIsAdsOpen, isPollsOpen , calculateVideoGridHeight, setRoomID, isShowAds, isShowHandouts}: InteractiveEventLiveProps) => {
  
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const expoWebsocket = useExpoWebSocket();


  const [event, setEvent] = useState<IEvent>();
  const [queue, setQueue] = useState<QueueParticipants>();
  const [attendees, setAttendees] = useState<IAttendee[]>([]);
  const [stage, setStage] = useState<Participants>();

  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const { eventModerators } = useSelector((state: IAppState) => state.liveEvent);
  const interactiveEvent = useSelector((state: IAppState) => state.interactiveEvent);

  useEffect(() => {
    if (event?.rooms?.length! > 0) {
      const rooms: any = {};
      const currentUserModerator: any = eventModerators.map(event => event?.user).find(mod => mod.id === user.id)
      const currentUserSpeaker: any = liveEvent.speakers.find(speaker => speaker.id === user.id);

      event?.rooms.map(room => room.sessions.map(session => session.eventSpeakers.map((speaker: any) => {
        if (speaker.userId === user?.id) {
          rooms[room.id] = room;
        }
        return speaker;
      })));
      if (!!currentUserModerator) {
        if (role !== 'moderator') {
          navigate(`/${getCompanyName(event)}/${slug}/live`);
        }
      } else if (!!currentUserSpeaker) {
        if (role !== 'speaker') {
          navigate(`/${getCompanyName(event)}/${slug}/live`);
        }
      } else if (role !== 'session') {
        navigate(`/${getCompanyName(event)}/${slug}/live`);
      }
    }
  }, [event, eventModerators]);

  useEffect(() => {
    return () => {
      dispatch(unsetInteractiveEvent())
      // dispatch(resetLiveEvent());
      if (role === 'speaker' || role === 'moderator' || role === 'session') {
        dispatch(InteractiveModeratorSpeakerUserExit(slug!, roomId!, user?.id!));
      }
      dispatch(unsetInteractiveEvent());
    };
  }, [slug, roomId, dispatch]);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (liveEvent?.isLive) {
        dispatch(updateCurrentSessionInformation());
      } else if (!liveEvent?.isLive) {
        clearInterval(timerId);
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [dispatch, liveEvent?.isLive]);

  
  useEffect(() => {
    if (slug && roomId) {
      if (role === "moderator") dispatch(InteractiveModeratorCallJoin(slug, roomId));
      if (role === "moderator") dispatch(InteractiveModeratorStartBroadCast(slug!, roomId!));
      if (role === "speaker") dispatch(InteractiveSpeakerJoin(slug, roomId));
      if (role === "session") dispatch(InteractiveAttendeeJoin(slug, roomId));

      dispatch(InteractiveModeratorSpeakerActiveList(slug, roomId))

      if (["speaker", "moderator"].includes(role!)) dispatch(InteractiveModeratorSpeakerQueueList(slug, roomId));
    }
  }, [slug, roomId]);


  useEffect(() => {
    const { participants } = interactiveEvent;
    if (!!participants) {
      const {
        event, attendees, resources, ads, speakers
      } = liveEvent;

      if (event?.rooms?.[0]?.isInteractive) {
        setStage(participants);
      } else {
        setStage({
          speakers: speakers, 
          moderators: [],
          virtualSpeakers: [],
          guests: [],
        });
      }
      setEvent(event);
      setAttendees(attendees);
      if (interactiveEvent.queue) setQueue(interactiveEvent.queue)
      setEvent(event);
    }
    if (!!interactiveEvent.redirect?.length) {
      navigate(interactiveEvent.redirect);
    }
  }, [liveEvent, interactiveEvent]);

  if (!event || !user) return null;
  
  return (
    <Hero
      event={event!}
      stage={stage!}
      attendees={attendees}
      queue={queue!}
      isGridView={isGridView}
      setIsHandoutsOpen={setIsHandoutsOpen}
      setIsPollsOpen={setIsPollsOpen}
      setIsAdsOpen={setIsAdsOpen}
      isPollsOpen={isPollsOpen}
      calculateVideoGridHeight={calculateVideoGridHeight}
      setRoomID={setRoomID!}
      isShowAds={isShowAds}
      isShowHandouts={isShowHandouts}
    />
  );
});
