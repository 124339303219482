import {
  Container,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  useCallback,
  useEffect, useRef, useState
} from 'react';
import { Autoplay, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSurveyContext } from 'src/mui/providers/GeneralContext';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { getMinifiedImage, getS3Path } from 'src/mui/utils/image';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { IAppState } from 'src/store';
import { rgbToHex } from 'src/utils/color-transformation';

export const hideScrollbarY = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

const BoxEventGallery = () => {
  const location = useLocation();
  const swiperRefLocal = useRef<any>(null);

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };
  const { surveyGallery } = useSurveyContext();

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [showModal, setShowModal] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('Pause');
  const videoRef: React.RefObject<HTMLVideoElement> = useRef(null);
  const [muteVideo, setMuteVideo] = useState(true);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video?.paused || video?.ended) {
      video.play();
      setTooltipTitle('Pause');
    } else {
      video?.pause();
      setTooltipTitle('Play');
    }
  };

  useEffect(() => {
    videoRef?.current?.play();
  }, []);

  const handleQueryParams = useCallback((search: string) => {
    const getQueryParams = (search: string) => {
      const queryParams = new URLSearchParams(search);
      const params: Record<string, string> = {};
      const entries: [string, string][] = Array.from(queryParams.entries());
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of entries) {
        params[key] = value;
      }
      return params;
    };

    const queryParams = getQueryParams(search);
    const { eventFrom } = queryParams;
    if (eventFrom === 'register') {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    const delay = 500;
    const timer = setTimeout(() => {
      handleQueryParams(location.search);
    }, delay);
    return () => clearTimeout(timer);
  }, [location, handleQueryParams]);

  const survey = useSelector((state: IAppState) => state.website.survey!);

  const isImage = (item: any) => item?.slice(0, 5) === 'image';

  const getBackgroungGradient = () => {
    if (!survey?.backgroundDominantColor?.length) return '#0D4552';
    return `${rgbToHex(survey?.backgroundDominantColor)}`;
  };

  return (
    <Container
      id="highlights"
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        maxWidth: '100%',
        p: 0,
        pt: '50px',
        pb: '50px',
        pl: '15px !important',
        pr: '15px !important',
        '@media (min-width: 768px)': { maxWidth: '750px' },
        '@media (min-width: 992px)': { maxWidth: '970px' },
        '@media (min-width: 1200px)': { maxWidth: '1170px' },
        '@media (min-width: 1280px)': { maxWidth: '1260px' },
      }}
    >
      <Stack
        flexDirection="column"
        sx={{
          backgroundColor: 'rgba(255 255 255 / 20%)',
          borderRadius: '16px',
          overflow: 'hidden',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <div className="w-full overflow-x-clip">
          <div className="block md:flex">
            <div
              className="w-full bg-gradient-to-b px-[0px] xl:px-0 relative"
              style={{
                background: `linear-gradient(${getBackgroungGradient()}, transparent)`,
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Swiper
                pagination={{
                  clickable: true
                }}
                ref={swiperRefLocal}
                modules={[Pagination, Autoplay, EffectFade]}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: true,
                  pauseOnMouseEnter: true,
                  stopOnLastSlide: false,
                  waitForTransition: false
                }}
                effect="fade"
                className="mySwiperBox"
              >
                {
                surveyGallery.map(item => {
                  if (isImage(item?.key)) {
                    return (
                      <SwiperSlide>
                        <img src={getMinifiedImage(item?.key)} style={{ objectFit: 'contain' }} alt="" />
                      </SwiperSlide>
                    );
                  } else {
                    return (
                      <SwiperSlide>
                        <video ref={videoRef} style={{ width: '100%', height: '100%', objectFit: 'cover', }} loop muted={muteVideo} autoPlay>
                          {' '}
                          <source src={getS3Path(item?.key)} type="video/mp4" />
                          {' '}
                          <source src={getS3Path(item?.key)} type="video/webm" />
                          {' '}
                          <track default kind="captions" />
                          {' '}
                        </video>
                        <div
                          style={{
                            position: 'absolute',
                            textAlignLast: 'end',
                            bottom: 100,
                            opacity: 0.8,
                            zIndex: 100000,
                            right: 20,
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Tooltip title={tooltipTitle}>
                            <IconButton onClick={handlePlayPause} sx={{ color: 'white' }}>
                              {tooltipTitle === 'Pause' ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                          </Tooltip>

                          <Tooltip title={!muteVideo ? 'mute' : 'unmute'}>
                            <IconButton onClick={() => setMuteVideo(!muteVideo)} sx={{ color: 'white' }}>
                              {muteVideo ? <VolumeOffIcon /> : <VolumeUpIcon />}
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div
                          style={{
                            zIndex: 10,
                            backgroundImage: 'linear-gradient(transparent 35%,#000)',
                            position: 'absolute',
                            top: '0%',
                            bottom: '0%',
                            left: '0%',
                            right: '0%',
                          }}
                        />
                      </SwiperSlide>
                    );
                  }
                })
              }
              </Swiper>
            </div>
          </div>
        </div>
      </Stack>
    </Container>
  );
};

export default BoxEventGallery;
