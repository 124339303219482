// @mui
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
// components
import { UserMatch } from "./user-match";
import { AcceptSvgIcon } from "../sidebar/SvgIcons/AcceptSvgIcon";
import { ViewEyeSvgIcon } from "../sidebar/SvgIcons/ViewEyeSvgIcon";
import { RejectSvgIcon } from "../sidebar/SvgIcons/RejectSvgIcon";
import { getBranding, getBrandingColors } from '../event/live/liveEventStyling';

type InterestItemProps = {
  item: any;
  user: any;
  eventId: any;
  onMatchAudience: (
    status: string,
    id: string,
    eventId: string
  ) => Promise<void>;
  activeList?: any;
  columnCardHeight?: any;
};

export function InterestItem({
  item,
  user,
  eventId,
  onMatchAudience,
  activeList,
  columnCardHeight,
}: InterestItemProps) {
  const colors = getBrandingColors();
  const isModuleBoxDesign: boolean = getBranding()?.moduleDesign === "lines";
  const handleProfile = (id: string) => {
    if (id) {
      window.open(`/wall/${id}`);
    }
  };

  return (
    <Stack
      sx={{
        position: "relative",
        // maxHeight: activeList.length > 3 ? 'calc(100vh - 617px)' : '100%',
      }}
    >
      <Stack sx={{ px: "10px" }}>
        <UserMatch
          item={item}
          user={user}
          activeList={activeList}
          columnCardHeight={columnCardHeight}
        />
      </Stack>

      <Stack
        spacing="5px"
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          p: "10px",
          backgroundColor: "transparent",
          backdropFilter: "blur(40px)",
          position: "absolute",
          left: isModuleBoxDesign ? "18px" : 0,
          bottom: 8,
          width: isModuleBoxDesign ? "calc(100% - 36px)" : 1,
          zIndex: 100,
        }}
      >
        <IconButton
          onClick={() => onMatchAudience("dislike", item.userId, eventId)}
          sx={{
            p: 0,
            "&:hover": {
              bgcolor: "transparent",
            },
          }}
        >
          <RejectSvgIcon fillColor={colors?.Main} fillBgColor={colors?.Text} />
        </IconButton>
        <IconButton
          onClick={() => handleProfile(item.userId)}
          sx={{
            p: 0,
            "&:hover": {
              bgcolor: "transparent",
            },
          }}
        >
          <ViewEyeSvgIcon fillColor={colors?.Main} fillBgColor={colors?.Text} />
        </IconButton>
        <IconButton
          onClick={() => onMatchAudience("like", item.userId, eventId)}
          sx={{
            p: 0,
            "&:hover": {
              bgcolor: "transparent",
            },
          }}
        >
          <AcceptSvgIcon fillColor={colors?.ButtonTextColor} fillBgColor={colors?.Accent} />
        </IconButton>
      </Stack>
    </Stack>
  );
}
