import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../../../components/alert-modal/alert-modal';

import { ICompanyEmployee } from '../../../models/company/company-employee';
import { IUserProfile } from '../../../models/user-profile';
import { CompanyService, UserService } from '../../../services';
import { SearchIcon } from '../../../shared-components/V2/IconSvg';
import { closeMessageWindow, openMessageWindow } from '../../../store/newMessage';
import PeopleListCard from '../../search-results/peopleListCard';
import { ConnectionItem } from './connection-item';

export const ConnectionsNew = (props: {
  connections: ICompanyEmployee[];
  title: string;
  userId?: string;
  companyId?: string;
  totalEmployees?: number;
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userService = new UserService();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filteredConnections, setFilteredConnections] = useState<IUserProfile[]>([]);
  const [search, setSearch] = useState<string>();
  const [showMoreConnections, setShowMoreConnections] = useState(false);
  const [userConnections, setUserConnections] = useState<any[]>();

  const updateUserConnections = async () => {
    if (props.companyId) {
      const companyService: CompanyService = new CompanyService();
      await companyService.getCompanyEmployees(props.companyId).then(({ data }) => setUserConnections(data ?? []));
    } else if (props.userId) {
      await userService.getUserConnections(props.userId).then(({ data }) => setUserConnections(data ?? []));
    }
  };

  useEffect(() => {
    if (userConnections?.length) {
      if (search) {
        const filteredList = userConnections.filter(c => `${c.firstName} ${c.lastName}`?.toLowerCase()?.includes(search));
        setFilteredConnections(filteredList);
      } else {
        setFilteredConnections(userConnections);
      }
    }
  }, [userConnections, search]);

  useEffect(() => {
    if (showMoreConnections) {
      // eslint-disable-next-line no-console
      updateUserConnections();
    } else {
      setUserConnections([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMoreConnections]);

  if (!props.connections?.length) return null;

  const getCompanyName = () => {
    const inputString = userConnections?.[0]?.company?.name;
    const words = inputString?.split(' ');
    return words?.map((word: string) => word?.charAt(0).toUpperCase() + word?.slice(1)).join(' ');
  };

  const getTitle = () => {
    if (props?.companyId) {
      return userConnections?.length ? `${getCompanyName()}'s employees (${props?.totalEmployees!})` : null;
    }
    return `Your connections (${props?.connections?.length || 0})`;
  };

  return (
    <div className="bg-white create-post-card !rounded-[20px] h-[100%] ">
      <div className="flex flex-col">
        <div
          className="flex flex-row items-center justify-between w-full rounded-t-10 px-[30px] pt-[13px]"
        >
          <div className=" text-[20px] font-normal !pl-[-10px]  leading-[27px] text-[#000000]">{props.title}</div>
          <div className="font-semibold text-[15px] text-[#000000] leading-[18px hidden">
            {!!props.connections?.length && props?.totalEmployees! > 500 ? '500+' : props?.totalEmployees!}
          </div>
        </div>
        <div className=" grid grid-cols-3 grid-rows-3 gap-x-[29px] gap-y-[16px] pt-[20px] px-[30px] pb-[14px] min-h-[393px] max-h-[393px] overflow-y-auto">
          {props.connections?.slice(0, 9).map((connection, index) => (
            <div className="h-[110px]" key={`connection-${index}`}>
              <ConnectionItem item={connection} />
            </div>
          ))}
        </div>
        <div
          onClick={() => setShowMoreConnections(true)}
          // eslint-disable-next-line max-len
          className="w-[calc(100%-24px)] mx-auto mb-[13px] flex justify-center text-[13px] text-black bg-[#EDF2F5] rounded-[5px] leading-[17px] cursor-pointer font-semibold tracking-wide py-[9px] hover:underline"
        >
          See all
        </div>
      </div>
      <AlertModal
        isOpen={showMoreConnections}
        onClose={() => {
          setShowMoreConnections(false);
          setSearch('');
        }}
        className="w-576 p-2 container"
      >
        <>
          <div className="text-md px-2 py-1 pb-4">{getTitle()}</div>
          <div className="px-2">
            <div className="px-3 py-1.5 flex items-end text-sm rounded-15 focus:outline-none w-[186px] h-[30px] border-1 border-blue-900 font-extralight text-gray-1 bg-gray-1">
              <SearchIcon />
              <input
                name="search"
                className="w-full ml-2 text-sm5 focus:outline-none block font-light text-gray-900 placeholder:text-gray-900 bg-transparent"
                placeholder="Search"
                onChange={e => { setSearch(e.target.value.toLowerCase()); }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="border-b-1 border-gray-300 my-2" />
          <div className="w-full h-420 overflow-auto px-4">
            <PeopleListCard
              onClick={(user: IUserProfile) => {
                setShowMoreConnections(false);
                setSearch('');
                navigate(`/wall/${user.slug}`);
              }}
              people={filteredConnections}
              onConnect={async (userId: string) => {
                await userService.sendConnectionRequest({
                  connectionUserId: userId
                });
                updateUserConnections();
              }}
              onMessage={async (user: ICompanyEmployee) => {
                await dispatch(closeMessageWindow());
                await dispatch(
                  openMessageWindow({
                    avatar: user.avatar,
                    userDisplayName: `${user.firstName} ${user.lastName}`,
                    userId: user.id
                  })
                );
                setShowMoreConnections(false);
                setSearch('');
              }}
              key="user-connections"
            />
          </div>
        </>

      </AlertModal>
    </div>
  );
};

export default ConnectionsNew;
