import { Box, Drawer, Stack } from '@mui/material';
import { CardHeader } from './live/card-header';
import BrandingPage from '../branding';
import { styled } from '@mui/material/styles';
import { getBrandingColors } from './live/liveEventStyling';
import { fontSize, fontWeight, lineHeight, padding } from '@mui/system';

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  width: `calc(100vw - 77px)`,
  marginRight: "10px",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginRight: -drawerWidth,
  ...(open && {
    width: `calc(100vw - 25vw - ${80}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export function LiveViewMain(props: {
  panelPplacement: any,
  strings: string[],
  callbackfn: (item: any, index: any) => (JSX.Element | null),
  open: boolean,
  onClose: () => void,
  boxStyles: { backgroundColor: string; pb: string; overflow: string; borderRadius: string; px: string; py: string; position: string },
  onClick: () => void,
  activeList: (value: (((prevState: string[]) => string[]) | string[])) => void
}) {
  const colors = getBrandingColors();
  return <Main sx={{
    padding: 0,
    paddingTop: '12px',
    paddingLeft: '10px',
    marginLeft: props.panelPplacement === 'left' ? '70px' : 0
  }}>
    <Stack
      sx={{
        height: '100%',
        gap: '12px',
        display: 'grid',
        gridTemplateColumns:
          'calc(50% - 363px) calc(50% - 333px) 330px 330px',
        gridTemplateRows: '1fr 1fr',
      }}
    >
      {props.strings.map(props.callbackfn)}
    </Stack>
    <Drawer
      // className="branding-drawer"
      anchor={props.panelPplacement === 'right' ? 'right' : 'left'}
      open={props.open}
      onClose={props.onClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'transparent',
          right: props.panelPplacement === 'right' ? '75px' : '',
          left: props.panelPplacement === 'right' ? '' : '75px',
          top: '10px',
          boxShadow: 'none',
          backdropFilter: 'unset'
        },
        '& .MuiModal-backdrop': {
          right: props.panelPplacement === 'right' ? '70px' : '',
          left: props.panelPplacement === 'right' ? '' : '70px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        right: props.panelPplacement === 'right' ? '70px' : '',
        left: props.panelPplacement === 'right' ? '' : '70px',
      }}
    >
      <Box sx={{
        width: '421px',
        maxWidth: '421px'
      }}>
        <Stack sx={{ height: 'calc(100vh - 20px)', background: `${colors.Main || "#000000"} !important`, border: 'none !important', p: '12px 13px 0px 20px !important', ...props.boxStyles }}>
          <CardHeader
            title="Branding"
            onClick={props.onClick}
            sx={{ fontSize: '19px', fontWeight: 600, lineHeight: '27px',  }}
          />
          <BrandingPage setActiveList={props.activeList} activeList={props.strings}/>
        </Stack>
      </Box>
    </Drawer>
  </Main>;
}
