export enum CompanyProfilePreviewFromCacheActionTypes {
  SET_SPECIALIST_COMPANY_PREVIEW = 'SET_SPECIALIST_COMPANY_PREVIEW',
  SET_SIZE_COMPANY_PREVIEW = 'SET_SIZE_COMPANY_PREVIEW',
  SET_NAME_COMPANY_PREVIEW = 'SET_NAME_COMPANY_PREVIEW',
  SET_TAGLINE_COMPANY_PREVIEW = 'SET_TAGLINE_COMPANY_PREVIEW',
  SET_ABOUT_COMPANY_PREVIEW = 'SET_ABOUT_COMPANY_PREVIEW',
  SET_WEBSITE_COMPANY_PREVIEW = 'SET_WEBSITE_COMPANY_PREVIEW',
  SET_INDUSTRY_COMPANY_PREVIEW = 'SET_INDUSTRY_COMPANY_PREVIEW',
  SET_HEADQUARTER_COMPANY_PREVIEW = 'SET_HEADQUARTER_COMPANY_PREVIEW',
  SET_TIMELINE_COMPANY_PREVIEW = 'SET_TIMELINE_COMPANY_PREVIEW',
  SET_INTEREST_INDUSTRIES_COMPANY_PREVIEW = 'SET_INTEREST_INDUSTRIES_COMPANY_PREVIEW',
  SET_PERSONA_INTEREST_COMPANY_PREVIEW = 'SET_PERSONA_INTEREST_COMPANY_PREVIEW',
  SET_SECONDARY_CAPABILITIES_COMPANY_PREVIEW = 'SET_SECONDARY_CAPABILITIES_COMPANY_PREVIEW',
  SET_TARGET_ACCOUNTS_COMPANY_PREVIEW = 'SET_TARGET_ACCOUNTS_COMPANY_PREVIEW',
}
