import React from 'react';

import {
  format,
  parseISO
} from 'date-fns';
import { Link } from 'react-router-dom';

import { Typography } from '../../../shared-components';
import { EventCoverAndLogo } from '../../event-cover-and-logo';
import { Logo } from '../../../shared-components/V2/Logo';
import { minifiedImage } from '../../../utils';
import classNames from 'classnames';

interface ISharedCompanyProps {
  company: any;
  isCompanyNew?: boolean;
}

export const SharedCompany = ({
  company,
  isCompanyNew,
}: ISharedCompanyProps) => (
  <div className="w-full text-xs">
    <div className={classNames("relative overflow-hidden", {
      'h-[271px]':isCompanyNew,
    })}>
      <Link to={`/wall/company/${company?.slug}`}>
        <EventCoverAndLogo 
          cover={`${company?.cover}`}
          logo={`${company?.logo}`}
        />
        <div className={classNames("w-full bottom-0 inset-x-0 bg-[#EDF2F5] text-left px-[18px] py-2.5",{
          'absolute': isCompanyNew
        })}>
          <Typography variant="ContainerHeading" text={company?.name} classes="!text-[13px] !leading-[17px] !text-[#172746] !font-semibold mb-[2px]" />
          <Typography variant="H3" text={`Employees ● ${company?.companySize}`} />
          <Typography variant="H3" text={company?.headquarter} />
        </div>
      </Link>
    </div>
  </div>
);

export default SharedCompany;
