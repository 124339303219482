import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { IEvent } from '../../../models/event/event';
import { IAppState } from '../../../store';
import { ConferencesSection } from './FactorConferences';
import InsightSection from './insight';
import { FactorPeopleReviewSection } from './components/factor-people-review';
import { FactorPartners } from './components/factor-partners';
import { RegisterSectionProps } from './types';
import { WorkForSection } from './WorkFor';
import { TopicsSection } from './Topics';
import { ExperienceBannerSection } from './components';

export const Overview = ({ onRegister, setSelectedTab, videos }: RegisterSectionProps) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const survey: any = useSelector((state: IAppState) => state.website.survey!);

  const partners = useSelector((state: IAppState) => state.website.event?.eventSponsors!);

  const [speakers, setSpeakers] = useState<any>([]);
  const getSessionSpeakers = useCallback(() => {
    const speakers :any = event?.rooms?.flatMap((room:any) => room.sessions.flatMap((session:any) => session.eventSpeakers.map((speaker:any) => ({
      ...speaker,
      sessionTitle: session.title,
      sessionSummary: session.summary
    }))));
    const uniqueSpeakers = speakers?.reduce((unique: any[], current: any) => {
      const isDuplicate = unique.some((item) => item.userId === current.userId);
      return isDuplicate ? unique : [...unique, current];
    }, []);
    setSpeakers(uniqueSpeakers);
  }, [event?.rooms]);

  useEffect(() => {
    getSessionSpeakers();
  }, [event?.rooms, getSessionSpeakers]);

  const factorMainVideo = [{
    url: 'https://d2ekq1e27cepdn.cloudfront.net/videos/20241028/bcedd27d-9c48-4ead-a960-1ba3f6cc05a1-3y00z9t/HLS/events30secs_1.m3u8',
    thumbnail: 'https://d2ekq1e27cepdn.cloudfront.net/videos/20241028/bcedd27d-9c48-4ead-a960-1ba3f6cc05a1-3y00z9t/HLS/events30secs_1.m3u8'
  }];
  return (
    <>
      <div className="w-full max-w-full xl:px-0 mx-auto pt-[0px] px-[0px] mb-[80px]">
        {/* <BannerSection setSelectedTab={setSelectedTab} survey={survey} /> */}
        <ExperienceBannerSection setSelectedTab={setSelectedTab} videos={factorMainVideo} isHideExtraText playInLoop />
      </div>
      {/* add these images only for B2B CMO Day | Sydney event | starttime startTime: 2025-02-26T21:30:00.000Z */}
      {(event?.slug === 'marketingdayfeb')
     && (
     <div className="w-full max-w-[1154px] xl:px-0 mx-auto pt-[35px]">
       <div className="text-white md:leading-[59px] leading-[45px] md:text-[52px] text-[37px] font-bold helvetica-neue-65-medium text-center mb-[10px]">Learn The Latest From The Best</div>

       <div className="w-full flex flex-col items-center justify-center px-3 md:px-0 rounded-[10px]">
         <img
           alt="img"
           className="object-cover w-full h-full rounded-[10px] md:min-w-[calc(100%+34px)] -mx-[18px]"
           src="https://panelist-prod-media.s3.ap-southeast-2.amazonaws.com/images/20241029/21920716-30dd-49a1-ab8f-5a4f0fe8b1da-o6007l1/factorbanner2.png"
         />
         <img
           alt="img"
           className="object-cover w-full h-full rounded-[10px] md:min-w-[calc(100%+23px)] -mx-[14px]"
           src="https://panelist-prod-media.s3.ap-southeast-2.amazonaws.com/images/20241029/de51e9ca-e707-4981-be95-8f8a2b5197b0-po00nsk/Fectorbanner.png"
         />
       </div>
     </div>
     )}

      {(event?.slug === 'ciodayfeb')
     && (
     <div className="w-full max-w-[1154px] xl:px-0 mx-auto pt-[35px]">
       <div className="text-white md:leading-[59px] leading-[45px] md:text-[52px] text-[37px] font-bold helvetica-neue-65-medium text-center mb-[10px]">Learn The Latest From The Best</div>

       <div className="w-full flex flex-col items-center justify-center px-3 md:px-0 rounded-[10px]">
         <img
           alt="img"
           className="object-cover w-full h-full rounded-[10px] md:min-w-[calc(100%+32px)] -mx-[18px]"
           src="https://panelist-prod-media.s3.ap-southeast-2.amazonaws.com/images/20241029/21920716-30dd-49a1-ab8f-5a4f0fe8b1da-o6007l1/factorbanner2.png"
         />

         <img
           alt="img"
           className="object-cover w-full h-full rounded-[10px] md:min-w-[calc(100%+11px)] -mx-[10px] mb-[220px]"
           src="https://panelist-prod-media.s3.ap-southeast-2.amazonaws.com/images/2024115/20e07147-da9f-43f0-b42a-6cf1919a952a-urg0xsv/CIOPhoto.png"
         />
       </div>
     </div>
     )}
      <div className="w-full max-w-[1154px] xl:px-0 mx-auto pt-[35px]">
        {!survey
          && <InsightSection speakers={speakers! || []} />}
      </div>
      <div className="w-full max-w-[1154px] xl:px-0 mx-auto pt-[35px] px-[15px]">
        <FactorPeopleReviewSection videos={videos} />
        <ConferencesSection onRegister={onRegister} />
        {!survey && <TopicsSection />}
        {!survey && <WorkForSection />}
        { partners?.length ? <FactorPartners /> : null}
      </div>
    </>
  );
};
