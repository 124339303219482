import classNames from 'classnames';
import React from 'react';

const features = [
  {
    title: 'Stay ahead',
    description:
      'Be the first to access exclusive, groundbreaking Factor research.',
    borderColor: 'border-red-500',
  },
  {
    title: 'Discover bold, original insights',
    description:
      'Listen to inspiring keynotes from visionary leaders and technology pioneers.',
    borderColor: 'border-blue-500',
  },
  {
    title: 'Collaborate to get ahead',
    description:
      'Tackle challenges, validate strategies and make informed decisions.',
    borderColor: 'border-green-500',
  },
  {
    title: 'Turn your vision into reality',
    description:
      'Address pressing challenges with solution providers at the interactive Factor Day.',
    borderColor: 'border-yellow-500',
  },
];

const ExperienceBenefits: React.FC = () => (
  <div className="mt-[35px] mb-[112px] text-white">
    <div className="max-w-full mx-auto">
      <h2 className="text-white font-medium helvetica-neue-65-medium text-[22px] leading-[27px]">Benefits</h2>
      <h2 className="text-white md:leading-[42px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium text-left">
        Dive into the Factor Day Experience
      </h2>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-[13px] mt-[8px]">
        {features.map((feature, index) => (
          <div key={index} className="bg-[#14161a] p-[30px] rounded-lg shadow-lg">
            <h3
              className={classNames('text-white leading-[25px] md:text-[22px] text-[18px] helvetica-neue-65-medium !font-semibold text-left  mb-[19px]', {
                'sm:max-w-[80px]': index === 0,
              })}
            >
              {feature.title}
            </h3>
            <div className={`w-[23px] h-[5px] mb-[19px] bg-[#D6244D] ${feature.borderColor}`} />
            <p className="text-white md:leading-[22px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default ExperienceBenefits;
