import seamlessexperience from '../../../assets/images/overview/seamless-experience.png';
import seamlessexperience2 from '../../../assets/images/overview/seamless-experience@2x.png';
import analysedapproach from '../../../assets/images/overview/analysed-approach.png';
import analysedapproach2 from '../../../assets/images/overview/analysed-approach@2x.png';

export const UserExperience = () => (
  <div className="w-full h-full 2xl:mx-auto 2xl:container">
    <div className="block md:flex md:flex-row justify-center lg:ml-32">
      <div className="w-4/5">
        <div className="mt-11 ml-1">
          <img
            srcSet={`${seamlessexperience} 300w, ${seamlessexperience2} 1000w`}
            sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 500px"
            alt=""
            src={seamlessexperience2}
          />
        </div>
        <div className="ml-5 pt-6">
          <div className="lg:text-xl6 font-extralight text-xl text-dark-blue mt-10 md:mt-6w-2/3">
            A seemless experience
          </div>
          <p className="text-lg font-extralight text-dark-blue w-5/6 mt-4 ">
            Panelist is designed for a no-friction,
            {' '}
            <br />
            high-engagaement attendee experience
          </p>
        </div>
      </div>
      <div className="w-4/5 lg:ml-32">
        <div className="mt-11">
          <img
            srcSet={`${analysedapproach} 300w, ${analysedapproach2} 1000w`}
            sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 500px"
            alt=""
            src={analysedapproach2}
          />
        </div>
        <div className="ml-1 pt-6">
          <div className="lg:text-xl6 font-extralight text-xl text-dark-blue mt-4 md:mt-6w-2/3">
            An analysed approach
          </div>
          <p className="text-lg font-extralight text-dark-blue w-5/6 mt-4 ">
            Panelist is designed for a no-friction,
            {' '}
            <br />
            high-engagaement attendee experience
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default UserExperience;
