import classNames from 'classnames';

import { IInterest } from '../../../models/interest/interest';

interface OfferInterestedProps {
  selectOffer?: Function;
  selectInterest?: Function;
  item: IInterest;
  displayOffer: boolean;
  displayInterest: boolean;
  selected?: boolean;
  disabled?: boolean;
}

const OfferInterestedListButtonNew = ({
  selectOffer,
  selectInterest,
  item,
  displayOffer,
  displayInterest,
  selected = false,
  disabled = false,
}: OfferInterestedProps) => {
  const setColorActiveOffer = () => {
    if (selectOffer && !disabled) selectOffer(item);
  };
  const setColorActiveInterest = () => {
    if (selectInterest && !disabled) selectInterest(item);
  };

  return (
    <div className="mb-1 flex">
      {displayOffer === true && (
        <span
          className={classNames('border flex border-solid border-[#203C6E] rounded-20 py-[5px] px-[15px] text-xs', {
            'bg-[#0049EF] text-white font-medium': selected,
            'bg-gray-1 text-[#203C6E] font-medium': !selected,
            'cursor-pointer': !disabled

          })}
          onClick={setColorActiveOffer}
        >
          {item.name}
          {' '}
          <span className="text-[18px] ml-[5px]">+</span>
        </span>
      )}
      {displayInterest === true && (
        <span
          className={classNames('border flex border-solid border-[#203C6E] rounded-20 py-1 px-2 text-xs', {
            'bg-[#0049EF] text-white font-medium': selected,
            'bg-gray-1 text-[#203C6E] font-medium': !selected,
            'cursor-pointer': !disabled
          })}
          onClick={setColorActiveInterest}
        >
          {item.name}
          {' '}
          <span className="text-[18px] ml-[5px]">+</span>
        </span>
      )}
    </div>
  );
};

export default OfferInterestedListButtonNew;
