import PersonIcon from '@mui/icons-material/Person';
import { IExpoBooth } from 'src/models/expo';
import { Logo } from 'src/shared-components/V2/Logo';

// Props for the Expo Item
type ExpoItemProps = {
  booth: IExpoBooth;
  // eslint-disable-next-line no-unused-vars
  setShowBooth: (booth: IExpoBooth) => void;
};

// Render the Expo item, Work In Progress
// Currently consuming the icon and photo attributes, all null
export const ExpoBoothCard = (props: ExpoItemProps) => (
  <div
    onClick={() => props.setShowBooth(props.booth)}
    className="bg-gray-700 sm:w-48 rounded-xl min-h-[150px] relative cursor-pointer"
    style={{ height: 'fit-content' }}
  >
    <Logo
      className="absolute w-10 h-10 rounded-xl top-3 left-3"
      src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${props.booth.eventSponsor?.company?.logo}`}
      alt="Logo"
    />

    {props.booth.photo ? (
      <img src={props.booth.photo} alt="Avatar" />
    ) : (
      <PersonIcon sx={{ color: 'white', fontSize: 150 }} />
    )}

    <div className="absolute bg-white rounded-lg sm:w-44 w-full sm:left-2 -bottom-4 flex justify-center align-center">
      <Logo
        className="w-16 h-16 object-contain rounded-xl p-2"
        src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${props.booth.eventSponsor?.company?.logo}`}
        alt="Logo-text"
      />
    </div>
  </div>
);

export default ExpoBoothCard;
