import classNames from 'classnames';
import { PanelistLogo } from '../V2/IconSvg/PanelistLogo/PanelistLogo';
import './loader.css'

interface ILoader {
    className?: string;
    innerClassName?: string;
    logoClassName?: string;
    isLoaderLogo?: boolean;
}
export const Loader = ({ className, innerClassName, isLoaderLogo, logoClassName }: ILoader) => {
    return <div className={classNames("flex items-center justify-center relative", {
        [`${className}`]: className
    })}>
        <div>
            {isLoaderLogo && (
                <div className={classNames("mb-2", {
                    [`${logoClassName}`]: logoClassName
                })}>
                    <PanelistLogo />
                </div>
            )}
            <div className={classNames("loader", {
                [`${innerClassName}`]: innerClassName
            })} />
        </div>
    </div>
}