import { Reducer } from 'redux';

import { IUploadImageState } from '../../../models/fileupload/UploadImageState';
import { ImageUploadActions } from './actions';
import { ImageUploadActionTypes } from './types';

const initialState: IUploadImageState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const ImageUploadReducer: Reducer<IUploadImageState, ImageUploadActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ImageUploadActionTypes.IMAGE_UPLOAD_IN_PROGRESS: {
      return {
        ...state,
        uploading: state.loading
      };
    }
    case ImageUploadActionTypes.IMAGE_UPLOAD_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case ImageUploadActionTypes.IMAGE_UPLOAD_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
