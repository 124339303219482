import { SVGProps } from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
}

function MessageSvgIcon({ fillColor, fillBgColor, ...props }: Props) {
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13.691" viewBox="0 0 15 13.691">
        <g id="Group_21312" data-name="Group 21312" transform="translate(0 0)">
            <path id="Path_11359" data-name="Path 11359" d="M142.176,134.819a.33.33,0,0,0-.436.313v2.38a.46.46,0,0,0,.316.436.452.452,0,0,0,.144.023.462.462,0,0,0,.371-.194l1.394-1.9a.33.33,0,0,0-.16-.508Z" transform="translate(-135.999 -124.28)" fill={defaultColor}/>
            <path id="Path_11360" data-name="Path 11360" d="M126.648,80.649a.46.46,0,0,0-.477-.033l-13.774,7.2a.46.46,0,0,0,.064.842l3.707,1.267a.219.219,0,0,0,.213-.041l6.115-5.234a.219.219,0,0,1,.311.306l-4.469,5.394a.219.219,0,0,0,.1.346l6.12,2.09a.425.425,0,0,0,.147.025.47.47,0,0,0,.239-.066.46.46,0,0,0,.215-.326l1.684-11.327a.46.46,0,0,0-.194-.442Z" transform="translate(-112.15 -80.565)" fill={defaultColor}/>
        </g>
        </svg>

    );
}

export default MessageSvgIcon;