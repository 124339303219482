import { SVGProps } from 'react';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

function UploadPlusIcon(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props;
    const defaultColor = getBrandingColors()?.Main || '#000000';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.334" height="26.182" viewBox="0 0 24.334 26.182">
            <g id="add-square-svgrepo-com" transform="translate(0.75 0.75)">
                <path id="Path_27142" data-name="Path 27142" d="M17.595,13.3H13.3m0,0H9m4.3,0V9m0,4.3v4.3"
                      transform="translate(-1.881 -0.956)" fill="none" stroke={defaultColor} stroke-linecap="round"
                      stroke-width="1.5"/>
                <path id="Path_27143" data-name="Path 27143"
                      d="M24.834,14.341c0,5.818,0,8.727-1.672,10.534s-4.363,1.807-9.745,1.807-8.073,0-9.745-1.807S2,20.159,2,14.341,2,5.615,3.672,3.807,8.035,2,13.417,2s8.073,0,9.745,1.807c1.112,1.2,1.484,2.89,1.609,5.6"
                      transform="translate(-2 -2)" fill="none" stroke={defaultColor} stroke-linecap="round"
                      stroke-width="1.5"/>
            </g>
        </svg>


    );
}

export default UploadPlusIcon;
