import { IBaseResult } from '../../models/base-result';
import { IEvent } from '../../models/event/event';
import BaseServiceV2 from '../BaseServiceV2';

export class EventsServiceV2 extends BaseServiceV2 {
  async getEvents() {
    const extraOptions = this.getAuthToken();

    return this.get<IBaseResult<IEvent>>('/events', extraOptions);
  }

  async getUserMeetings(
    eventId: string,
    whereCondition?: string,
    page: number = 1,
    limit: number = 100
  ) {
    const extraOptions = this.getAuthToken();
    const whereQueryParams = whereCondition ? `&${whereCondition}` : '';
    return this.get<IBaseResult<IEvent>>(
      `/events/${eventId}/user-meetings?page=${page}&limit=${limit}${whereQueryParams}`,
      extraOptions
    );
  }

  async updateMeeting(eventId: string, meetingId: string, body: any) {
    const extraOptions = this.getAuthToken();
    return this.put(
      `/events/${eventId}/meetings/${meetingId}`,
      body,
      extraOptions
    );
  }

  async getSessionMeetings(eventId: string, userId?: string) {
    const whereClause = userId ? `?${userId}` : '';
    const extraOptions = this.getAuthToken();
    return this.get<any>(
      `/events/${eventId}/session-meetings${whereClause}`,
      extraOptions
    );
  }

  async getMeetingById(eventId: string, meetingId: string) {
    const extraOptions = this.getAuthToken();
    return this.get<any>(
      `/events/${eventId}/meeting/${meetingId}`,
      extraOptions
    );
  }

  async contactUS(eventId: string, body: any) {
    return this.post('/contact-us', body);
  }

  async requestMeeting(eventId: string, body: any) {
    const extraOptions = this.getAuthToken();
    return this.post(`/events/${eventId}/meetings`, body, extraOptions);
  }

  async getAttendees(
    eventId: string,
    whereCondition?: string,
    search?: string,
    orderBy?: string,
    page: number = 1,
    limit: number = 10,
    order: string = 'DESC'
  ) {
    const whereQueryParams = whereCondition ? `&${whereCondition}` : '';
    const searchCondition = search ? `&search=${encodeURIComponent(search)}` : '';
    const orderClause = orderBy
      ? `&order[${orderBy}]=${order.toLocaleUpperCase()}`
      : '';
    const extraOptions = this.getAuthToken();
    return this.get<any>(
      `/events/${eventId}/attendees-with-checks?page=${page}&limit=${limit}${whereQueryParams}${searchCondition}${
        orderBy === 'name' ? '' : orderClause
      }`,
      extraOptions
    );

    // return this.get<any>(
    //   `/events/${eventId}/attendees?${whereQueryParams}${searchCondition}${
    //     orderBy === 'name' ? '' : orderClause
    //   }`,
    //   extraOptions
    // );
  }

  async getQuestionPages(eventId: string, type: string) {
    const extraOptions = this.getAuthToken();
    return this.get<any>(
      `/events/${eventId}/question-page/${type}`,
      extraOptions
    );
  }

  async getQuestionPagesInsights(eventId: string) {
    const extraOptions = this.getAuthToken();
    return this.getV1<any>(
      `/surveys/${eventId}/question-pages`,
      extraOptions
    );
  }

  async getUserRegistrationAnswers(eventId: string, userId?: string) {
    const extraOptions = this.getAuthToken();
    return this.get<any>(
      `/events/${eventId}/attendees/${userId}`,
      extraOptions
    );
  }

  async getEventGalleries(eventId: string) {
    const extraOptions = this.getAuthToken();
    return this.get<any>(`/guest/events/${eventId}/galleries?limit=200`, extraOptions);
  }

  async getSignUpRegQuestions(eventId: string) {
    return this.getV1<any>(`/auth/event-register/${eventId}`);
  }

  async getSurveyGalleries(surveyId: string) {
    const extraOptions = this.getAuthToken();
    return this.get<any>(`/surveys/${surveyId}/galleries?limit=200`, extraOptions);
  }
}
