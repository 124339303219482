import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';

import classNames from 'classnames';
import {
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  Menu,
  Transition,
} from '@headlessui/react';

import dropdownIcon from '../../../assets/images/arrow-down-icon.png';
import deleteBinIcon from '../../../assets/images/delete-bin-icon.svg';
import iconPolls from '../../../assets/images/icon-polls.svg';
import iconPollsGray from '../../../assets/images/icon-polls-gray.svg';
import { CREATE_POLL } from '../../../constants';
import {
  ICreatePoll,
  ICreatePollProps,
} from '../../../models/poll/poll';
import { Typography } from '../../../shared-components';
import { Button } from '../../../shared-components/V2';
import { createPoll, editPoll, getPoll } from '../../../store/poll';
import { ArrowDownSvgIcon } from 'src/shared-components/V2/IconSvg';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { DeletePoll } from '../delete-poll';

type PollValues = {
  question: string;
  expireOption: number;
  answers: any;
  // answers: { answer: string }[];
};

type AnswerValues = {
  index: number;
  length: number;
};

const CreatePoll = ({
  onClose,
  isOpenedDirectly,
  toggleCreatePostModal,
  isEditPoll,
  post
}: ICreatePollProps) => {

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      // answers: [
      //   {
      //     answer: '',
      //   },
      //   {
      //     answer: '',
      //   },
      // ],
      answers: post?.poll?.answers?.map((answer: any) => ({ answer: answer.answer })) || [
              {
          answer: '',
        },
        {
          answer: '',
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answers',
  });

  const dispatch = useDispatch();
  const [duration, setDuration] = useState(1);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [selectedDeletedIndex, setSelectedDeletedIndex] = useState<number| null>(null);
  const [questionCharactersCount, setQuestionCharactersCount] = useState(0);

  const onCloseModal=()=>{
    setOpenConfirmationPopup(false)
    setSelectedDeletedIndex(null)
  }
  const initialAnswerCharactersCount = Array.from({ length: 10 }, (_, index) => ({
    index,
    length: 0,
  }));

  const [answerCharactersCount, setAnswerCharactersCount] = useState<
    AnswerValues[]
  >(initialAnswerCharactersCount);

  useEffect(() => {
    if (isEditPoll && post) {
      // Populate form fields with values from the post being edited
      setValue('question', post?.poll?.question);
      // setValue('expireOption', post?.poll?.expireOption);
      setDuration(post?.poll?.expireOption);
      // post?.poll?.answers?.forEach((answer: any, index: any) => {
      //   return setValue(`answers[${index}].answer`, answer.answer); // Adjusted setValue usage
      // });
      const polls=  post?.poll?.answers?.map((answer: any, index: any) => {
          // return setValue(`answers[${index}].answer`, answer.answer); // Adjusted setValue usage
        return  {
          id:answer.id,
          answer:answer.answer,
          ordering:answer.ordering,
          votes:answer.votes
          }
        });
        setValue(`answers`,polls );
    }
  }, [isEditPoll, post, setValue]);

  const onEditSuccess=()=>{
    dispatch(getPoll(post?.poll?.id))
  }

  const onSubmit = async (data: PollValues) => {
    const answers = data.answers.map((answer: any, index: number) => ({
      ...answer,
      ordering: index,
    }));
    let dataToSave: ICreatePoll = {
      question: data.question,
      answers,
      expireOption: duration,
    };
    if (isEditPoll) {
      dispatch(editPoll(dataToSave , post?.poll?.id,onEditSuccess));
    } else {
      dispatch(createPoll(dataToSave));
    }
    
    if (isOpenedDirectly) {
      toggleCreatePostModal && toggleCreatePostModal();
    }

    onClose && onClose();
  };

  const cancelPoll = () => {
    onClose && onClose();
  };

  const watchanswers = watch('answers');


  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchanswers[index],
    };
  });

  return (
    <div className='w-full'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mx-auto'>
          <div className='flex flex-col w-full'>
            {/* <div className='text-[#203C6E] text-center font-medium text-[15px] leading-6 px-[20px] border-b-1 border-[#C5C5C5] pb-3'> */}
            <div className="flex justify-start text-[15px] leading-[23px] text-[#000000] font-medium mb-[13px] pl-[21px] border-b-1 border-[#EDF2F5] pb-[13px]">
              {isEditPoll ? 'Edit a poll' : 'Create a poll'}
            </div>
            <div className='flex flex-row justify-between mt-0 px-[20px]'>
              <div className='text-[13px] font-medium mb-[4px] text-[#000000] leading-[20px]'>Your question1 *</div>
            </div>
            <div className='px-[20px]'>
              <input
                type='text'
                className='w-full shadow-sm border-[2px] border-[#E5E5E5] h-[37px] rounded-[5px] px-[10px] text-sm2 focus:outline-none text-[13px] font-normal text-[#3C3C3C] leading-[20px]'
                {...register('question', {
                  required: {
                    value: true,
                    message: CREATE_POLL.QUESTION_REQUIRED,
                  },
                })}
                placeholder="E.g. How do you commute to work?"
                maxLength={140}
                onChange={(e: any) => {
                  setQuestionCharactersCount(e.target.value.length);
                }}
                autoComplete="off"
              />
              <div className='w-full text-right mt-[1px] text-[13px] font-normal text-[#ADADAD] leading-[20px]'>{`${questionCharactersCount}/140`}</div>
            </div>
            <div>
              {errors.question && (
                <Typography
                  variant='ValidationMessage'
                  text={errors.question?.message!.toString()}
                  classes='flex content-start mt-1'
                />
              )}
            </div>
            <div className='mt-1 px-[20px]'>
              {controlledFields.map((_field, index) => {
                return (
                  <div className='mt-0' key={`answer-${index}`}>
                    <div className=''>
                      <div className='flex justify-between'>
                        <div className='text-[13px] font-medium mb-[4px] text-[#000000] leading-[20px]'>Option {index + 1}*</div>
                      </div>
                      <div className='relative'>
                        <input
                          type='text'
                          className='w-full shadow-sm border-[2px] border-[#E5E5E5] h-[37px] rounded-[5px] px-[10px] text-sm2 focus:outline-none text-[13px] font-normal text-[#3C3C3C] leading-[20px]'
                          {...register(`answers.${index}.answer` as const)}
                          maxLength={30}
                          placeholder={`E.g. How do you commute to work example ${index + 1} ?`}
                          // onChange={(e: any) => {
                          //   let answers = [...answerCharactersCount];
                          //   let answer = { ...answers[index] };
                          //   answer.length = e.target.value.length;
                          //   answers[index] = answer;
                          //   setAnswerCharactersCount(answers);
                          // }}
                          autoComplete="off"
                        />
                        <div className='w-full text-right mt-[1px] text-[13px] font-normal text-[#ADADAD] leading-[20px]'>{`${answerCharactersCount[index].length}/30`}</div>
                        <div
                          className='ml-2 cursor-pointer absolute right-2 top-[9px]'
                          onClick={() => {
                            if(watchanswers[index].votes){
                              setSelectedDeletedIndex(index)
                              setOpenConfirmationPopup(true)
                            }else {
                              remove(index)
                            }
                          }}
                        >
                          <img src={deleteBinIcon} alt='Delete option' />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='mt-0 flex flex-row px-[20px]'>
              <div className=''>
                <button
                  disabled={controlledFields.length > 9}
                  type='button'
                  className={classNames('text-[16px] leading-[21px] tracking-wide', {
                    'text-gray-400': controlledFields.length > 9,
                    'text-[#144EE6]': controlledFields.length <= 9
                  })}
                  onClick={() =>
                    controlledFields.length <= 9 && append({ answer: '' })
                  }
                >
                  Add an option
                </button>
              </div>
              <div className='ml-[16px] mt-0.5'>
                <img src={ (controlledFields.length <= 9) ? iconPolls : iconPollsGray} alt='add option' />
              </div>
            </div>
            <div className='mt-4 flex flex-col px-[20px]'>
              <div className='text-[14px] font-medium  text-[#3C3C3C]'>Poll duration *</div>
              <div className='mt-1'>
                {/* Dropdown menu start */}
                <Menu as='div' className='text-left'>
                  <div className='w-full'>
                    <Menu.Button className='inline-flex items-center justify-between border-[1px] border-[#7283A4] h-[31px] rounded-[5px] shadow-sm px-4 py-2 bg-white text-sm font-medium text-[#7283A4] hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'>
                      <div>{`${duration} ${duration === 1 ? ' day' : ' days'
                        }`}</div>
                      <div>
                      <ArrowDownSvgIcon className="ml-[10px] mt-[1px]" fillColor="#7283A4" />

                        {/* <img
                          src={dropdownIcon}
                          alt=''
                          className='ml-1 mt-1.5'
                        /> */}
                      </div>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Menu.Items className='origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <div className='py-1'>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href='#'
                              className={classNames('block px-4 py-2 text-sm', {
                                'bg-gray-100 text-gray-900': active,
                                'text-gray-700': !active,
                              })}
                              onClick={() => setDuration(1)}
                            >
                              1 day
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href='#'
                              className={classNames('block px-4 py-2 text-sm', {
                                'bg-gray-100 text-gray-900': active,
                                'text-gray-700': !active,
                              })}
                              onClick={() => setDuration(3)}
                            >
                              3 days
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href='#'
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              )}
                              onClick={() => setDuration(7)}
                            >
                              7 days
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                {/* Dropdown menu end */}
              </div>
            </div>
            <div className='mt-4'>
              <div className='mr-4 ml-4 flex flex-row justify-end py-3 border-t-1 border-[#C5C5C5]'>
                {/* <div>
                  <Button
                    text="Cancel"
                    type='button'
                    className='rounded-5 inline-block text-sm font-semibold h-[31px] bg-[#EDF2F5] text-[#203C6E] w-[88px] mr-[10px] hover:bg-[#CEDCF2] tracking-wide'
                    onClick={cancelPoll}
                  />
                </div> */}
                {/* <div className=''>
                  <Button
                    text="Publish"
                    type='submit'
                    className='rounded-[10px] inline-block text-sm font-[600] text-white h-[31px] w-[98px] bg-[#0049EF] hover:bg-[#2e6eff] px-4'
                  />
                </div> */}
                <div className="flex flex-row mt-[6.5px] flex-1">
              <Button
                text="Post"
                className="bg-[#EDF2F5] text-[#000000] hover:text-white text-[15px] font-semibold w-full h-[40px] !rounded-[5px] hover:bg-blue-gray-2 tracking-wide"
                type="submit"
              />
            </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {openConfirmationPopup && (
        <AlertModal isCloseIcon closeIconClass='right-[7px] top-[14px]' isOpen={openConfirmationPopup} onClose={onCloseModal} className="w-[374px] bg-[#EDF2F5]">
          <DeletePoll
            message={"This poll answer already contains responses. Are you sure you want to delete it?"}
            onDelete={async () => {
              if(selectedDeletedIndex || selectedDeletedIndex===0)
                remove(selectedDeletedIndex)
              onCloseModal()
            }}
            onClose={onCloseModal}
          />
        </AlertModal>
      )}
    </div>
  );
};

export default CreatePoll;
