import {
  format,
  parseISO,
} from 'date-fns';
import { useEffect, useState } from 'react';

import { ReactComponent as ClockIcon } from '../../../assets/images/event-landing-pre-event/Icon-feather-clock.svg';
import { ReactComponent as PersonIcon } from '../../../assets/images/event-landing-pre-event/icon-feather-person.svg';

import greenTick from '../../../assets/images/tick-green.svg';
import { QrService } from '../../../services/QrService';
import { Button } from '../../../shared-components/V2/Button';
import { useCurrentUser } from '../../../utils/hooks';
import { CalendarList } from '../../event/registration/event-components/calendar-list';
import TicketBreadcrumb from './ticket-breadcrumb';

interface IOrderConfirmationProps {
  eventId: string;
  eventTitle: string;
  cover: string;
  logo: string;
  startTime: string;
  endTime: string;
  eventType: string;
  location: string;
  organiserEmail: string;
  orderNumber: string;
  orderDate: string;
  totalAmount: string;
  currency?: string;
  paymentMethod?: string;
  onClose?: Function;
}

const OrderConfirmation = ({
  eventId,
  eventTitle,
  cover,
  logo,
  startTime,
  endTime,
  eventType,
  location,
  organiserEmail,
  orderNumber,
  orderDate,
  totalAmount,
  currency,
  paymentMethod,
  onClose,
}: IOrderConfirmationProps) => {
  const qrService = new QrService();
  const user = useCurrentUser();

  const [qrImage, setQrImage] = useState("");

  useEffect(() => {
    qrService.getQrCode(eventId, user.id).then((res) => setQrImage(res));
  }, []);

  return (
    <div className="flex justify-between">
      <div className="inline-block max-w-[844px]">
        <TicketBreadcrumb className="pl-[35px] pt-[29px] pb-[10px]" selected="Registration confirmation" />
        <div className="flex items-center flex-row ml-[35px]">
          <div>
            <img src={greenTick} alt="" />
          </div>
          <div className="text-sm text-[#3C3C3C] font-bold ml-2.5">
            Thanks for your order
          </div>
          <div className="text-sm text-[#3C3C3C] ml-1">#{orderNumber}</div>
        </div>
        <div className="mt-2.5 mb-2.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="844.5"
            height="1.0"
            viewBox="0 0 844.5 0.5"
          >
            <line
              id="Line_1403"
              data-name="Line 1403"
              x2="844.5"
              transform="translate(0 0.25)"
              fill="none"
              stroke="#cecece"
              strokeWidth="0.5"
            />
          </svg>
        </div>
        <div className="ml-[35px] w-[800px]">
          <div className="text-sm text-[#3C3C3C] leading-5">You're going to</div>
          <div className="text-2xl leading-[45px] text-[#3C3C3C] mb-2.5">{eventTitle}</div>
          <div className="text-sm font-semibold leading-5 text-[#3C3C3C] mb-[5px]">Organizer message:</div>
          <div className="text-sm font-light text-[#222222] leading-[17px] mb-2.5">
            Thank you for your order! Please check your email to confirm you
            have received email confirmation. If you don’t see it, please email{" "}
            {organiserEmail} to confirm your registration.
          </div>
          <div className="flex items-center flex-row mb-[6px]">
            <div>
              <ClockIcon />
            </div>
            <div className="text-h3 leading-[12px] text-[#203C6E] ml-1.5">
              {format(parseISO(startTime), "d MMMM yyyy")} •{" "}
              {format(parseISO(startTime), "hh:mm a")} -{" "}
              {format(parseISO(endTime), "hh:mm a")} AEST
            </div>
          </div>
          <div className="flex items-center flex-row ">
            <div>
              <PersonIcon />
            </div>
            <div className="text-h3 leading-[12px] text-[#203C6E] ml-1.5">
              {eventType} | {location}
            </div>
          </div>
          <div className="flex justify-start">
            <img src={qrImage} alt="QR Code" />
          </div>
          <div className="text-sm text-[#393939] leading-[17px] mt-[21px]">
            <span className="text-sm font-semibold leading-5 text-[#3C3C3C] mb-[3px] block">Note:</span>
            You will shortly receive an email confirming your registration along
            with event details. Check your spam folder if you have not received
            an email in your inbox within the next 5 minutes.
            <br />
            <br />
            For multiple purchases, each attendee will receive a seperate email
            to complete the information required by the organizer.
          </div>
          <div className="text-sm font-semibold leading-5 text-[#3C3C3C] mb-[5px] mt-5">
            Order Summary
          </div>
          <div className="text-sm font-normal text-[#3C3C3C] leading-[20px]">
            Order #{orderNumber} - {format(parseISO(orderDate), "d MMMM yyyy")}{" "}
            <br />
            AUD$2,400.00 paid by PayPal <br />
            Payer: info@sit-down.com.au <br />
            Transaction ID: 6CK7519594432023R <br />
            Appears on your card statement as CXO SIT-Down Pty Ltd
          </div>
          <div className="flex flex-row items-center justify-between h-[50px] mt-2 -ml-[35px] -mr-[8px] border-t border-[#CECECE] px-[10px]">
            <Button
              className="bg-[#0049EF] hover:bg-[#244E93] h-[30px] text-white"
              size="small"
              text="Save as a PDF"
            />
            <div className="flex flex-row">
              <Button
                className="bg-[#0049EF] hover:bg-[#244E93] h-[30px] text-white ml-2"
                size="small"
                text="Event Lobby"
                onClick={()=>onClose!()}
              />
              <div className="">
                <CalendarList title='Add to Calendar' fillColor="#ffffff" btnClassName='bg-[#0049EF] hover:bg-[#244E93] h-[30px] text-white text-sm font-[600] ml-2 rounded-20 flex justify-center items-center px-4'/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col bg-white rounded-r-10 max-w-[350px]">
        <div className="p-2.5">
          <EventCard
            title={eventTitle}
            cover={cover}
            logo={logo}
            eventDate={startTime}
            location={location}
          />
        </div>
        <div className="ml-2.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="330"
            height="1"
            viewBox="0 0 330 0.5"
          >
            <line
              id="Line_1400"
              data-name="Line 1400"
              x2="330"
              transform="translate(0 0.25)"
              fill="none"
              stroke="#cecece"
              strokeWidth="0.5"
            />
          </svg>
        </div> */}
        {/* Shopping cart */}
        {/* <div className="p-2.5">
          <TicketCart />
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default OrderConfirmation;
