import classNames from 'classnames';
import {
  useEffect,
  useState
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

// Import Base Components
import ConnectAndMessage from '../../../components/connect-and-message';
import { IRoom } from '../../../models/event/room';
import { getConnectSuggestions, setConnectSuggestion } from '../../../store/user/connect-suggestions/actions';
// Datetime Stuff
import { getFormattedTime } from '../../../utils/datetime';
import { ParseContent } from '../../../utils/parse-html';
import Downloads from './downloads';

type AgendaProps = {
  rooms: IRoom;
};

export const Agenda = (props: AgendaProps) => {
  const [activeTab, setActiveTab] = useState<'CURRENT' | 'NEXT'>('CURRENT');
  const [activeSubTab, setActiveSubTab] = useState<'SESSION_SUMMARY' | 'SPEAKER_BIO'>('SESSION_SUMMARY');
  const { session, activeRoom } = useSelector((state: any) => state.liveEvent);
  const currentSession = useSelector((state: any) => state.liveEvent?.session?.current);
  const nextSession = useSelector((state: any) => state.liveEvent?.session?.next);
  const [activeTabSession, setActiveTabSession] = useState(currentSession);
  const connectionStatuses = useSelector((state: any) => state.liveEvent?.connectionStatuses);

  const dispatch = useDispatch();

  useEffect(() => {
    if (activeTab === 'CURRENT') {
      setActiveTabSession(currentSession);
    } else {
      setActiveTabSession(nextSession);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, activeTab, currentSession]);

  // eslint-disable-next-line no-trailing-spaces
  const onClickConnect = async (id: string) => {
    await dispatch(setConnectSuggestion(id));
    await dispatch(getConnectSuggestions());
  };

  return (
    <div className="grid gap-2 h-full">
      <div className="flex gap-1">
        <div
          className={classNames('px-4 py-2 w-1/2 text-center rounded-tl-xl text-sm font-bold cursor-pointer', {
            'bg-blue text-white': activeTab === 'CURRENT',
            'bg-white text-blue-2': activeTab === 'NEXT'
          })}
          onClick={() => setActiveTab('CURRENT')}
        >
          Current
        </div>
        <div
          className={classNames('px-4 py-2 w-1/2 text-center rounded-tr-xl text-sm font-bold cursor-pointer', {
            'bg-blue text-white': activeTab === 'NEXT',
            'bg-white text-blue-2': activeTab === 'CURRENT'
          })}
          onClick={() => setActiveTab('NEXT')}
        >
          Next
        </div>
      </div>
      <div className="rounded-b-xl bg-white overflow-y-auto" style={{ height: '600px' }}>
        <div className="bg-blue text-white px-4 py-2 text-sm">{activeTabSession?.title || 'Untitled'}</div>
        <div className="grid gap-3 px-4 py-4 bg-white">
          {activeTabSession?.eventSpeakers?.map((speaker: any, key: number) => (
            <ConnectAndMessage
              connectStatus={connectionStatuses?.find((cs:any) => cs.id === speaker.user.id)}
              key={key}
              userId={speaker.user.id}
              firstName={speaker.firstName}
              lastName={speaker.lastName}
              avatar={speaker.photo}
              jobTitle={speaker.jobTitle}
              companyName={speaker.company?.name}
              onClickConnect={() => onClickConnect(speaker.id)}
            />
          ))}
        </div>
        {
          activeTabSession !== undefined ? (
            <>
              <div className="grid gap-1 bg-white px-4 py-2">
                <div className="flex justify-between">
                  <span className="text-blue-1 text-sm">Panel</span>
                  <span className="text-blue-2 text-sm font-bold">
                    {getFormattedTime(activeTabSession?.startTime!)}
                    {' '}
                    -
                    {getFormattedTime(activeTabSession?.endTime!)}
                  </span>
                </div>
                <div className="text-grey-1 text-sm border-blue-0 border-b-1 pb-1">
                  <ParseContent content={`${activeTabSession?.summary ? activeTabSession?.summary : '<p>No summary</p>'}`} />
                </div>
              </div>
              <div className="px-4 bg-white">
                <div className="flex border-blue-0 border-b-1 gap-2">
                  <div
                    className={classNames('text-sm w-1/2', {
                      'text-blue-1 py-1 border-blue-2 border-b-2': activeSubTab === 'SESSION_SUMMARY',
                      'text-grey-1 py-1': activeSubTab === 'SPEAKER_BIO'
                    })}
                    onClick={() => setActiveSubTab('SESSION_SUMMARY')}
                  >
                    Session summary
                  </div>
                  <div
                    className={classNames('text-sm w-1/2', {
                      'text-blue-1 py-1 border-blue-2 border-b-2': activeSubTab === 'SPEAKER_BIO',
                      'text-grey-1 py-1': activeSubTab === 'SESSION_SUMMARY'
                    })}
                    onClick={() => setActiveSubTab('SPEAKER_BIO')}
                  >
                    Speaker Bio
                  </div>
                </div>
                {activeSubTab === 'SESSION_SUMMARY' ? (
                  <div
                    className="text-grey-1 text-sm pb-1 py-2"
                  >
                    <ParseContent content={activeTabSession?.summary ? activeTabSession?.summary : '<p>No Summary</p>'} />
                  </div>
                ) : (
                  <div className="text-grey-1 text-sm pb-1 py-2">
                    {activeRoom === props.rooms && activeTabSession?.eventSpeakers.map((item: any, key: any) => (
                      <div key={key} className="py-2">
                        <p className="font-bold">
                          {item?.firstName}
                          {' '}
                          {item?.lastName}
                        </p>
                        <p className="">{item?.bio ?? `${item?.firstName} hasn't provided a bio`}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {activeRoom === props.rooms && activeTabSession?.uploaded?.length && (
                <div className="px-4 bg-white">
                  <div className="text-blue-1 text-sm border-b-1 pb-1">Downloads</div>
                  <div className="grid py-1 gap-1 pb-4">
                    <Downloads downloads={session?.uploaded || []} />
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="text-blue-400 text-md text-center m-auto">No session found</div>
          )
        }
      </div>
    </div>
  );
};

export default Agenda;
