import { useEffect, useState } from 'react';
import { Autoplay, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { format, parseISO } from 'date-fns';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { EventsService } from '../../../../../services/events';
import { Logo } from '../../../../../shared-components/V2/Logo';
import { Button } from '../../../../../shared-components/V2';
import ActionOfCardEvent1 from '../ActionOfCardEvent1';
import { IAppState } from '../../../../../store';

import { IHeroEvent } from '../../../../../models/event/event-hero';
import { IEvent } from '../../../../../models/event/event';
import { ParseContent } from '../../../../../utils/parse-html';

import './index.css';

export const HeroEvents = () => {
  const user : any = useSelector((state: IAppState) => state.auth.currentUser);
  const [heroEvents, setHeroEvents] = useState<IHeroEvent[]>([]);
  const navigate = useNavigate();

  const getHeroEvents = () => new EventsService().getHeroEvents().then(({ data }) => setHeroEvents(data));

  useEffect(() => {
    getHeroEvents();
  }, []);

  if (!heroEvents?.length) return null;

  return (
    <div
      className="h-[347px] xl:w-[940px] w-full mb-4 rounded-[15px] overflow-hidden"
    >
      <Swiper
        pagination={{
          dynamicBullets: false,
          clickable: true,
        }}
        centeredSlides
        modules={[Pagination, Autoplay, EffectFade]}
        autoplay={{
          delay: 9000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
          stopOnLastSlide: false,
          waitForTransition: false
        }}
        className="eventHeroSlider"
      >
        {
          heroEvents?.map((event: IHeroEvent, index: number) => (
            <SwiperSlide>
              <div className="text-white h-[347px]" key={`event-hero-${index}`}>
                <div className="opacity-100">
                  <div className="absolute inset-0 bg-black bg-opacity-60" />
                </div>
                <div className="absolute w-full top-0 h-full sm:pt-[40px] pt-[50px] md:px-0 px-3">
                  <div
                    className={classNames('flex items-center justify-center', {
                      'h-[100px]': event?.logo,
                      'md:h-[57px] h-auto': !event?.logo
                    })}
                  >
                    {event?.logo ? (
                      <Logo
                        src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event?.logo}`}
                        className="w-full h-full max-w-[250px] object-contain"
                      />
                    ) : (
                      <Link
                        className="font-bold md:text-[41px] text-[30px] md:leading-[41px] leading-[35px] flex justify-center"
                        to={`/event/${event.slug}/discussion`}
                      >
                        {event.name}
                      </Link>
                    )}
                  </div>
                  <ParseContent className="text-sm leading-[18px] pt-2.5 pb-[20px] md:px-[6rem] px-4 font-normal md:max-w-[75%] max-w-[100%] md:m-auto text-center" content={event.theme ?? ''} />
                  {event.eventLocation && <div className="text-sm leading-[19px] font-normal">{event.eventLocation}</div>}
                  <div className="text-sm leading-[19px] font-normal capitalize text-center">
                    {format(parseISO(event.startTime), 'dd MMM yyyy')}
                    {' '}
                    &bull;
                    {' '}
                    {format(parseISO(event.startTime), 'HH:mm aaaa')}
                    {' '}
                    -
                    {' '}
                    {format(parseISO(event.endTime), 'HH:mm aaaa')}
                    {' '}
                    {event.timezone}
                  </div>
                  <div className="pt-[20px] flex items-center justify-center">
                    <Button
                      text={event?.eventAudiences?.status ? 'Discussion' : 'Register'}
                      size="medium"
                      className="bg-[#0049EF] hover:bg-[#2e6eff] rounded-[5px] h-[30px] w-[120px] text-[13px] !px-0 tracking-wide flex items-center justify-center"
                      onClick={() => navigate(`/event/${event.slug}/discussion`)}
                    />
                  </div>

                </div>
                <Link
                  className="absolute flex justify-center items-center left-5 bottom-5 md:h-[91px] md:w-[91px] h-[71px] w-[71px] p-[1px] bg-white medium-radius cursor-pointer"
                  to={`/wall/company/${event?.company.slug}`}
                >
                  <Logo
                    src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event?.company?.logo}`}
                    className={classNames('md:max-h-[89px] md:max-w-[89px] max-h-[69px] max-w-[69px] object-contain medium-radius-7', {
                      '!bg-[#edf2f5]': !event?.company?.logo,
                    })}
                    defaultLogo="company"
                  />
                </Link>
                <div className="absolute h-[27px] w-[27px] top-[14px] right-[14px] z-[10000]">
                  <ActionOfCardEvent1 event={event as unknown as IEvent} user={user} dropdownClassName="-ml-[0] -left-[177px]" />
                </div>
                <Logo
                  src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event.cover}`}
                  className="h-464 w-full object-cover"
                />
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
};
