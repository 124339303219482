/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { dateAndTimeWithTz, formatDateRange } from 'src/utils/date-timezone';
import { format, parseISO } from 'date-fns';
import { getTimeZone } from 'src/utils/timezones';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

interface Props {
  title: string;
  description: string;
  isSponsorTitle?: boolean;
}
// eslint-disable-next-line no-unused-vars
export const PageTitle = ({
  title,
  description,
  isSponsorTitle,
}: Props) => {
  const event = useSelector((state: IAppState) => state.website.event!);

  const eventDateTimeWithTz = useMemo(() => {
    const startTime = dateAndTimeWithTz(event?.startTime, event?.timezone);
    const endTime = dateAndTimeWithTz(event?.endTime, event?.timezone);
    const formattedDateRange = formatDateRange(
      new Date(event?.startTime),
      new Date(event?.endTime)
    );
    return {
      startTime,
      endTime,
      formattedDateRange,
    };
  }, [event?.startTime, event?.endTime, event?.timezone]);

  const eventOverview = event?.overview && parse(DOMPurify.sanitize(event?.overview));

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const eventDateTimeLocalTz = useMemo(() => {
    const startTime = format(parseISO(event?.startTime), 'hh:mm aa')
      ?.replace('.', '')
      ?.replace(' ', '')
      ?.replace('.', '');
    const endTime = format(parseISO(event?.endTime), 'hh:mm aa')
      ?.replace('.', '')
      ?.replace(' ', '')
      ?.replace('.', '');
    const timeZoneMatched = new Date(eventDateTimeWithTz.startTime.dateAndTime).getMinutes()
        === new Date(parseISO(event?.startTime)).getMinutes()
      && new Date(eventDateTimeWithTz.startTime.dateAndTime).getHours()
        === new Date(parseISO(event?.startTime)).getHours();

    return {
      startTime,
      endTime,
      timeZoneMatched,
    };
  }, [event?.startTime, event?.endTime, eventDateTimeWithTz?.startTime?.dateAndTime]);

  const getTimeZoneName = (zone: string) => {
    const shortTimeZone = getTimeZone(zone)?.[0]?.abbr
      ? getTimeZone(zone)?.[0]?.abbr
      : zone;
    return shortTimeZone;
  };

  const goToWebSite = () => {
    window.open('https://www.factor-insights.com/partnerships', '_blank')?.focus();
  };
  return (
    <div className="mt-[50px] lg:mb-[75px] px-[15px] md:px-0 mb:[40px] w-full">
      <div className="flex flex-col gap-y-[22px] gap-x-[41px] lg:flex-row justify-between items-center">
        <div
          className={classNames(
            'lg:w-[320px] w-full  border-l-[15px] h-max border-l-[#D6244D] p-l-[14px]',
            {
              'lg:!w-[400px] lg:min-w-[400px] w-full': isSponsorTitle,
            }
          )}
        >
          <div className="flex flex-col pl-[14px]">
            <div
              className={classNames(
                'text-white md:leading-[56px] xl:mb-[16px] mb-[12px] leading-[45px] md:text-[52px] text-[37px] font-bold helvetica-neue-65-medium text-left '
              )}
            >
              {title}
            </div>

            <div>
              <div
                className={classNames(
                  'text-white leading-[22px] md:text-[20px] text-[20px] md:text-left text-left items-center capitalize font-medium helvetica-neue-65-medium',
                  {
                    hidden: !eventDateTimeWithTz.startTime.date,
                  }
                )}
              >
                {eventDateTimeWithTz?.formattedDateRange}
              </div>
              <div className="uppercase text-white leading-[22px] md:text-[20px] text-[20px] md:text-left text-left items-center font-medium helvetica-neue-65-medium">
                <span className="uppercase text-white leading-[22px] md:text-[20px] text-[20px] md:text-left text-left items-center font-medium helvetica-neue-65-medium">
                  {eventDateTimeWithTz?.startTime?.time}
                </span>
                -
                <span className="uppercase text-white leading-[22px] md:text-[20px] text-[20px] md:text-left text-left items-center font-medium helvetica-neue-65-medium">
                  {eventDateTimeWithTz.endTime.time}
                </span>
              </div>
              <div className=" text-white leading-[22px] md:text-[20px] text-[20px] md:text-left text-left items-center font-medium helvetica-neue-65-medium">
                Time Zone :
                <span className=" text-white leading-[22px] md:text-[20px] text-[20px] md:text-left text-left items-center font-medium helvetica-neue-65-medium">
                  {' '}
                  {getTimeZoneName(event?.timezone)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="text-white md:leading-[22px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman max-w-[567px]">
          {isSponsorTitle
            ? 'Sponsorship and event partners at Factor Day enjoy top-tier visibility and engagement with key decision makers. This partnership ensures maximum value, fostering lasting industry connections and brand reputation.'
            : eventOverview}
          {isSponsorTitle && (
          <button
            type="button"
            className={classNames(
              // eslint-disable-next-line max-len
              'px-[35px] mt-[22px] float-left md:h-[40px] h-[40px] bg-transparent border-2 border-white rounded-5 flex items-center justify-center text-white md:text-[16px] text-[15px] leading-[40px] helvetica-neue-W05_85-heavy'
            )}
            onClick={goToWebSite}
          >
            <div className="h-[40px]">
              Explore Partnerships
              {' '}
            </div>

          </button>

          )}
        </div>
      </div>
    </div>
  );
};
