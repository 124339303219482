// video-player.tsx => Core HLS stream player for the Panelist app
/* eslint-disable jsx-a11y/media-has-caption */
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ReactHlsPlayer from '@panelist/react-hls-player';

import Stack from '@mui/material/Stack';
import { CountDownTimer } from './countdown-timer/countdown-timer';
import { PollsSvgIcon } from 'src/shared-components/V2/IconSvg';
import classNames from 'classnames';
import VideoPlayerPoll from 'src/components/poll/view-poll/video-player-poll';
import { PollQuestion } from 'src/mui/pages/Polls/PollQuestion';
import { IVideoCallState } from 'src/store/1-1-video-call';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';

// Explicitly declare interface for HTMLVideoElement to
// circumvent cross-browser issues in TypeScript
// CAN POSSIBLY DEPRECATE SHORTLY
declare global {
  // eslint-disable-next-line no-unused-vars
  interface HTMLVideoElement {
    webkitRequestFullscreen?(): void;
    msRequestFullscreen?(): void;
  }
}

// Interface to enable access to the seek method through forward refs
// Will replace with something better later if needed
interface VideoControls {
  seek(amount: number): void;
  isGridView?: boolean;
}

// Props that can be received by this component
type VideoPlayerProps = {
  // Methods
  setIsPlaying(play: boolean): void;
  setDuration(dur: number): void;
  setPosition(pos: number): void;
  setVolume(vol: number): void;
  setNextVideo?: () => void;

  // Properties
  url: string;
  isFullScreen: boolean;
  isPip: boolean;
  isPlaying: boolean;
  error: string;
  volume: number;
  screenMode: 'small' | 'large' | 'hidden' | undefined;
  startTime: any;
  endTime: any;
  polls?: any;
  isGridView?: boolean;
  setIsHandoutsOpen: (value: boolean) => void;
  setIsPollsOpen: (value: boolean) => void;
  setIsAdsOpen: (value: boolean) => void;
  isPollsOpen: boolean;
  isShowAds?: boolean;
  isShowHandouts?: boolean;
};

export const VideoPlayer = forwardRef<VideoControls, VideoPlayerProps>(
  (props, ref) => {
    // Ref to the HLS player, needed due to the API
    const playerRef = useRef<HTMLVideoElement>(null);
    // Hack
    const [ready, setReady] = useState<boolean>(false);
    // Effect to manage playing and pausing the video based upon props
    const { isPlaying } = props;
    const [beforeCallVideoPlaying, setBeforeCallVideoPlaying] = useState(false);
    const chatVideoCall: IVideoCallState = useSelector(
      (state: IAppState) => state.chatVideoCall
    );
    useEffect(() => {
      if (chatVideoCall?.aParty || chatVideoCall?.bParty || chatVideoCall?.chimeInvitation || chatVideoCall?.userBusy?.isBusy) {
        if(isPlaying){
          setBeforeCallVideoPlaying(isPlaying);
          setIsPlaying(false);
        }
      }
      else {
        if(beforeCallVideoPlaying && !isPlaying){
          setIsPlaying(true);
          setBeforeCallVideoPlaying(false)
        }
      } 
    }, [chatVideoCall]);

    // Video player height
    const playerHeight = props?.isGridView ? 'calc(50vh - 11px)' : '100vh';
    useEffect(() => {
      if (isPlaying && playerRef.current) {
        playerRef.current.play();
      } else if (!isPlaying && playerRef.current) {
        playerRef.current.pause();
      }
    }, [isPlaying]);

    // Effect to manage the playback volume
    const { volume, setIsPlaying } = props;

    useEffect(() => {
      if (playerRef.current) {
        playerRef.current.volume = volume / 100;
        setIsPlaying(!playerRef?.current?.paused);
      }
    }, [volume, setIsPlaying]);

    // Full Screen
    const { isFullScreen } = props;

    useEffect(() => {
      if (isFullScreen) {
        if (playerRef.current && playerRef.current.requestFullscreen) {
          playerRef.current.requestFullscreen();
        } else if (
          playerRef.current
          && playerRef.current.webkitRequestFullscreen
        ) {
          /* Safari */
          playerRef.current.webkitRequestFullscreen();
        } else if (playerRef.current && playerRef.current.msRequestFullscreen) {
          /* IE11 */
          playerRef.current.msRequestFullscreen();
        }
      } else if (document?.fullscreenElement) {
        document?.exitFullscreen();
      }
    }, [isFullScreen]);

    // PiP
    // Appears to work in all browsers
    const { isPip } = props;

    useEffect(() => {
      if (isPip) {
        if (playerRef.current && playerRef.current.requestPictureInPicture) {
          playerRef.current.requestPictureInPicture();
        }
      } else if (document?.pictureInPictureElement) {
        document?.exitPictureInPicture();
      }
    }, [isPip]);

    // Due to poor APIs, need to attach event listeners to the various aspects of the HTML5 video player in order
    // to establish the duration and position of the video, and then propagate this back to the parent component
    // Will replace later if needed

    // Exhaustive deps issue - eslint avoid for now

    useLayoutEffect(() => {
      playerRef.current?.addEventListener('durationchange', () => {
        props.setDuration(playerRef.current?.duration || 0);
        setReady(true);
      });
      playerRef.current?.addEventListener('timeupdate', () => {
        props.setPosition(playerRef.current?.currentTime || 0);
      });
      // eslint-disable-next-line
    }, []);

    // Seek method implementation, needs to be called directly by parent, hence useImperativeHandle and Refs
    useImperativeHandle(ref, () => ({
      seek(amount: number) {
        if (playerRef.current) {
          playerRef.current.currentTime = amount ?? null;
        }
      },
    }));


    const sortedPollQuestion = useMemo(() => {
      if (!props?.polls || !props?.polls?.livePolls ||  !props?.polls?.livePolls?.answers) return { answers: [] }; 
      return {
        ...props?.polls?.livePolls,
        answers: props?.polls?.livePolls?.answers ? [...props?.polls?.livePolls?.answers].sort((a, b) => a?.ordering - b?.ordering) : []
      };
    }, [props?.polls]);

    const displayPoll = () => (
      props?.polls?.livePolls?.id! && (
        <div
          className={classNames('absolute w-[346px] top-[15px] right-[14px] z-[1000] rounded-10 px-[13px] py-[13px]', {
            'bg-[rgba(255,255,255,0.15)] backdrop-blur-[30px]': props?.isPollsOpen
          })}
          style={{
            top: 
            props?.polls?.livePolls?.answers.length > 0
                  ? 15 
                  : props?.isShowAds 
                    ? (props?.isShowHandouts ? 62 : 15) 
                    : props?.isShowHandouts 
                      ? 15 
                      : 110,
          }}
        > 
          <div
            className={classNames('absolute right-[0px] top-[0px] cursor-pointer z-20 w-[40px] h-[40px] rounded-[15px] flex items-center justify-center', {
              'bg-[rgba(255,255,255,0.15)] backdrop-blur-[40px]': !props?.isPollsOpen
            })}
            onClick={() => props.setIsPollsOpen(!props?.isPollsOpen)}
          >
            <PollsSvgIcon />
          </div>
          {props?.polls?.livePolls?.id! && props?.isPollsOpen && (
            // <VideoPlayerPoll
            //   id={props.polls.livePolls.id}
            //   question={props.polls.livePolls.question}
            //   status={props.polls.livePolls.status}
            //   expireOption={1}
            //   expiredAt=""
            //   answers={props.polls.livePolls.answers}
            //   pollOnVideo
            //   isInteractive
            //   type="live"
            //   eventId={props.polls.livePolls.eventId}
            //   sessionId={props.polls.livePolls.eventSessionId}
            // />
            <PollQuestion pollQuestion={sortedPollQuestion} pollsOnVideo/>
          )}
        </div>
      )
    );

    const isPastEvent = (date: string) => new Date(date) < new Date();
    const isLiveEvent = (startDate: string, endDate: string) => +new Date(startDate) - +new Date() <= 0 && new Date(endDate) > new Date();
    const isFutureEvent = (date: string) => +new Date(date) - +new Date() > 0;
    return (
      <Stack
        position='relative'
        width={1}
        height={1}
        borderRadius='4px'
      >
        {!props?.error?.length
          && props?.volume === 0
          && ready
          && (isPastEvent(props?.endTime)
            || isLiveEvent(props?.startTime, props?.endTime)) && (
              <Stack
                position='absolute'
                width={1}
                onClick={() => props.setVolume(100)}
                sx={{
                  backgroundColor: '#ff0000b3',
                  height: '24px',
                  zIndex: 1000,
                  color: 'white',
                  left: 0,
                  bottom: '110px',
                  paddingX: 2
                }}
              >
                Audio Muted, Click Here To Hear The Stream
              </Stack>
        )}
        
        {props?.error && !isFutureEvent(props?.startTime) && (
          <Stack
            position='relative'
            width={1}
            sx={{
              backgroundColor: 'black',
              height: '100%',
              color: 'white',
              justifyContent: 'center',
              fontSize: '24px'
            }}
          >
            {props.error}
          </Stack>
        )}
        {isLiveEvent(props?.startTime, props?.endTime) && !props?.error && (
          <Stack
            position='relative'
            width={1}
          >
            <ReactHlsPlayer
              autoPlay
              id="video"
              playerRef={playerRef}
              style={{
                width: '100%',
                height: `${playerHeight}`,
                backgroundColor: 'black',
              }}
              src={props?.url}
              controls={false}
              muted={props?.volume === 0}
              onClick={() => props?.setIsPlaying(!props?.isPlaying)}
            />
            {displayPoll()}
            </Stack>
        )}
        {isFutureEvent(props.startTime) && (
          <Stack
            position='relative'
            width={1}
            height={1}
            sx={{
              backgroundColor: 'black',
            }}
          >
            <CountDownTimer
              screenMode={props?.screenMode}
              startTime={props?.startTime}
            />
          </Stack>
        )
        }
        {isPastEvent(props?.endTime) && !props?.error && (
          <Stack
            position='relative'
            width={1}
          >
            <video
              src={props?.url}
              autoPlay
              ref={playerRef}
              style={{ height:  props?.isGridView ? 'calc(50vh - 11px)' : 'calc(100vh - 22px)', width: '100%', backgroundColor: 'black' }}
              controls={false}
              muted={props?.volume === 0}
              onClick={() => props?.setIsPlaying(!props?.isPlaying)}
              onEnded={() => props?.setNextVideo!()}
            />
            {displayPoll()}
            </Stack>
        )}
      </Stack>
    );
  }
);

export default VideoPlayer;
