export interface IHoverableDivProps {
  handleMouseOver?: any;
  handleMouseOut?: any;
  className?: string;
  children: any;
}

export const HoverableDiv = ({ handleMouseOver, handleMouseOut, children, className }: IHoverableDivProps) => {
  return (
    <div className={className} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} aria-label="hover-child-target">
      {children}
    </div>
  );
};


