import bannerImage2 from '../../../assets/images/overview/overview-banner@2x.jpg';
import logo from '../../../assets/images/overview/panelist-logo-overview.svg';
import divider from '../../../assets/images/overview/divider.svg';
import polyicon from '../../../assets/images/polygon-icon.svg';
import { ButtonWithTextAndImage } from '../../../shared-components/V2';

export const Banner = () => (
  <div className="w-full">
    <div className="block md:flex">
      <div className="w-screen">
        <img src={bannerImage2} alt="" className="object-cover w-full" />
        <div className="container mx-auto">
          <div className="text-start 2xl:ml-5 lg:-mt-432 ml-16  pl-4 pt-4">
            <div className="flex flex-col text-start -ml-1">
              <div className="flex flex-row">
                <div className="mt-4">
                  <img src={logo} alt="" />
                </div>
                <div className="text-xl2 text-dark-blue font-light ml-4 pl-1 mt-5">
                  <img src={divider} alt="" />
                </div>
                <div className="text-xl1  text-dark-blue font-light ml-4 mt-4 pt-3">
                  Event Manager
                </div>
              </div>
              <div className="text-left lg:text-xl3 font-extralight text-xl text-blue-3 mt-10 md:mt-6-blue-4">
                Engage your audience in the world’s
                {' '}
                <br />
                {' '}
                business events community.
              </div>
              <div className="mt-4">
                <ButtonWithTextAndImage
                  buttonClasses="bg-orange hover:bg-gray-7 active:bg-gray-7 border-1 border-orange-2 w-242 h-43 rounded-full"
                  imageUrl={polyicon}
                  text="Create an event"
                  textClasses="text-dark-blue font-extralight text-sm3 ml-1 pl-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Banner;
