import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { ICompanyProfile } from "../../../models/company/company-profile";
import { IEditCompanyProfile } from "../../../models/company/company-profile-edit";
import { ICompanyProfileUpdateResult } from "../../../models/company/CompanyProfileUpdateResult";
import { CompanyService } from "../../../services";
import { toast } from "react-toastify";

export type ICompanyState = {
    company: ICompanyProfile | null;
    loading: boolean;
    errorMessage: string | null;
};

const initialState: ICompanyState = {
    company: null,
    loading: false,
    errorMessage: null
};
export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        createCompanyError: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.errorMessage = action.payload;
        },
        createCompanySuccess: (state, action: PayloadAction<ICompanyProfileUpdateResult>) => {
            state.loading = false;
            state.company = action.payload.data;
        },
    }
});

export const { createCompanySuccess, createCompanyError } =
    companySlice.actions;

export const createCompany = (data: IEditCompanyProfile, adminsSubmit?: any, onSuccess?: any,onError?:any) => async (dispatch: Dispatch) => {
    try {
        const companyService = new CompanyService();
        data.admins = adminsSubmit;
        const result = await companyService.createCompanyProfile(data);
        dispatch(createCompanySuccess(result));
        if (onSuccess) onSuccess();
    } catch (e: any) {
        if (onError) onError(e);
        dispatch(createCompanyError(e.message));
        toast.error(e.message);
    }
};