import * as Yup from "yup";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ILoginOrJoinProps } from "./form.type";
import {
  Box,
  Stack,
  Link,
  Typography,
  Alert,
  CircularProgress,
  InputAdornment,
  IconButton,
  useMediaQuery,
  Tooltip
} from "@mui/material";
import FormProvider from "src/mui/components/hook-form/form-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFTextField } from "src/mui/components/hook-form";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import { resetUserEmail } from "src/store/checkUserEmail";
import { useDispatch, useSelector } from "react-redux";
import useStyling from "src/mui/components/hook-form/useStyling";
import { IAppState } from "src/store";
import { useSurveyContext } from "src/mui/providers/GeneralContext";
import { RegistrationFieldType } from "src/mui/types/registration";
import { forbiddenEmailDomains, isBusinessEmail } from "../../survey/constants";
import { emailRegex } from "src/utils";
import { toast } from 'react-toastify';
import ErrorMessage from "src/components/error-message/errorMessage";
import { LOGIN_SIGNUP_RECOVERY } from "src/constants";

function LoginOrJoinForm({
  setStepHeader,
  stepDescription,
  protectedPasswordError,
  onSubmit,
  userExist,
  loginUser,
  onSignup,
  onForgot,
  errorMessage,
  loader,
  notYourEmail,
  showProtectedPasswordScreen
}: ILoginOrJoinProps, ref: any) {

  const dispatch = useDispatch()

  const mobileScreen = useMediaQuery('(max-width:768px)');

  const event = useSelector(
    (state: IAppState) =>
      state.website.event || state.upcomingEvent?.value?.data?.event
  );

  const { signupRegQuestion } = useSurveyContext();

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const emailDefaultQuestion = signupRegQuestion && signupRegQuestion?.length && signupRegQuestion?.find((question: any) => (question.fieldType === RegistrationFieldType.EMAIL || question.fieldType === RegistrationFieldType.BUSINESS_EMAIL) && !question?.id)
  const isBusinessEmailValidationRequired = emailDefaultQuestion?.fieldType === RegistrationFieldType.BUSINESS_EMAIL

  const tabScreen = useMediaQuery('(min-width:1150px)');

  const { secondaryFontColor, styles, fieldBorderColor, focusColor, primaryBackgroundColor, headerColor } = useStyling("login", event?.eventQuestionPage?.fontColor!)

  const [showPassword, setShowPassword] = useState(false);

  const requiredEmailSchema = Yup.string()
    .required('Business email is required')
    .email('Invalid email')
    .test('not-included', 'Business email address only', (value) => {
      if (value) {
        const includesForbiddenSubstring = forbiddenEmailDomains.some((substring) =>
          value?.toLowerCase()?.includes(substring)
        );
        return !includesForbiddenSubstring;
      }
      return true;
    })
    .matches(emailRegex, 'Should be a valid business email');

  const fieldsObject = useMemo(() => {
    let validation: any = {}
    if (!showProtectedPasswordScreen) {
      // validation["email"] = isBusinessEmailValidationRequired && userExist === false ? requiredEmailSchema : Yup.string().email('Invalid email').required('Email is required');
      validation["email"] = Yup.string().email('Invalid email').required('Email is required');

      validation["protectedPassword"] = Yup.string().notRequired();
    }
    else {
      validation["email"] = Yup.string().notRequired();
      validation["protectedPassword"] = Yup.string().required('Password is required');
    }
    return validation
  }, [showProtectedPasswordScreen, userExist, isBusinessEmailValidationRequired])


  const NewEventSchema = Yup.object().shape(fieldsObject);


  const defaultValues = useMemo(() => ({
    email: "",
    protectedPassword: ""
  }), []);


  const methods = useForm<any>({
    resolver: yupResolver(NewEventSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, errors },
    reset
  } = methods;
  const values = watch();
  //functions
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const getStepHeader = () => {
    return (
      <Box sx={{ ml: "5px" }}>
        <Typography sx={{
          color: primaryBackgroundColor, overflowWrap: 'anywhere',
          fontFamily: "Poppins-400",
          fontSize: "12px",
          '@media (max-width: 768px)': {
            color: secondaryFontColor,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "90%",
            overflow: "hidden",
          }

        }}>
          Logging in as <strong style={{ color: "#0031ff" }}>{loginUser}!</strong>

          <Link
            onClick={handleNotYourAccount}
            sx={{ color: primaryBackgroundColor, cursor: "pointer", ml: 1, fontFamily: "Poppins-600", "&.MuiLink-root": { textDecorationColor: primaryBackgroundColor } }}
          >
            Not you?
          </Link>
        </Typography>
      </Box >
    )
  }

  const handleSignUp = () => {
    if (onSignup) onSignup();
    setValue("email", "")
    dispatch(resetUserEmail());
    // 
    setShowPassword(false)
  }

  const handleNotYourAccount = () => {
    if (notYourEmail) notYourEmail();
    reset();
    setShowPassword(false)
    setStepHeader("Sign up or Login")
  }
  const handleContinueLogin = () => {
    handleSubmit(submitForm)()
  }

  const submitForm = (data: any) => {
    if (!isValid) return
    onSubmit(data)
  }

  useImperativeHandle(ref, () => ({
    handleContinueLogin,
  }));

  useEffect(() => {
    if (!showProtectedPasswordScreen) {
      if (stepDescription === "") {
        setValue("email", "")
      }
      setValue("protectedPassword", "")
    }
  }, [stepDescription, showProtectedPasswordScreen]);

  useEffect(() => {
    setValue("email", loginUser || "")
    if (userExist && loginUser) {
      setStepHeader(getStepHeader());
      if (userExist && windowSize?.current[0] > 600) {
        toast.dismiss();
        toast.success(
          <ErrorMessage
            title={LOGIN_SIGNUP_RECOVERY.LOGIN_TO_CONTINUE}
            message={LOGIN_SIGNUP_RECOVERY.LOGIN_SUCCESSFULL}
            linkText="Reset password"
            link="/forgotPassword"

          />
        );
      }
    }
    else {
      toast.dismiss();
      setStepHeader("Sign up or Login")
    }
  }, [loginUser, userExist, windowSize]);

  return (
    <>
      {loader ? (
        <Stack
          sx={{
            direction: "row",
            minHeight: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Stack sx={{ width: tabScreen ? "60%" : "100%" }}>
          <FormProvider methods={methods} >
            <Stack
              sx={{
                direction: "row",
                height: "100%",
                justifyContent: "center",
                alignItems: "flex-start",
                position: "relative",
              }}
              spacing={1}
              rowGap={1}
            >
              <Stack sx={{ width: "100%" }}>
                {!showProtectedPasswordScreen ?
                  <RHFTextField
                    name="email"
                    label={isBusinessEmailValidationRequired ? "Business Email Address" : "Email Address"}
                    sx={{
                      ...styles, borderColor: `${fieldBorderColor} !important`, color: `${secondaryFontColor}!important`, "& .Mui-disabled": {
                        WebkitTextFillColor: `${secondaryFontColor}!important`,
                        "fieldSet": {
                          borderColor: `${focusColor || fieldBorderColor} !important`
                        }
                      }
                    }}

                    autoFocus
                    disabled={Boolean(loginUser!) && userExist}
                    autoComplete="new-password"
                  /> :
                  <RHFTextField
                    name="protectedPassword"
                    label="Passcode"
                    sx={{ color: secondaryFontColor, ...styles }}
                    autoFocus
                    autoComplete="new-password"
                  />}
              </Stack>
              {
                (userExist === false && isBusinessEmailValidationRequired && !Object.keys(errors)?.length) ?
                  <Typography variant="caption" sx={{ color: `#d32f2f !important`, position: "absolute", bottom: "-10px", left: "5px" }}>Business Email Required</Typography>
                  : null
              }
              <Box sx={{ width: "100%", mt: 2 }}>
                {userExist && (
                  <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                    <RHFTextField
                      name="password"
                      type={showPassword ? "text" : "password"}
                      sx={{ width: "100%", ...styles }}
                      label="Password"
                      autoFocus
                      autoComplete="off"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <VisibilityOutlined
                                  sx={{
                                    color: secondaryFontColor || "#3D3E5C",
                                  }}
                                />
                              ) : (
                                <VisibilityOffOutlined
                                  sx={{
                                    color: secondaryFontColor || "#3D3E5C",
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box>

                      <Link
                        onClick={onForgot}
                        sx={{ color: secondaryFontColor, cursor: "pointer", textDecoration: "none", fontFamily: "Poppins-600" }}
                      >
                        Forgot password?
                      </Link>
                    </Box>
                    <Typography sx={{ color: secondaryFontColor, fontFamily: "Poppins-400" }}>
                      <Link
                        onClick={handleSignUp}
                        sx={{ color: secondaryFontColor, cursor: "pointer", textDecoration: "none", fontFamily: "Poppins-600" }}
                      >
                        Signup
                      </Link>
                    </Typography>

                    {mobileScreen && <Link
                      onClick={handleNotYourAccount}
                      sx={{ color: secondaryFontColor, cursor: "pointer", ml: 1, fontFamily: "Poppins-600", "&.MuiLink-root": { textDecorationColor: secondaryFontColor } }}
                    >
                      Not you?
                    </Link>}
                  </Stack>
                )}


                {((showProtectedPasswordScreen && protectedPasswordError !== "") || (errorMessage && loginUser)) && (
                  <Stack direction="column" spacing={2} sx={{ mt: "12px" }}>
                    <Alert severity="error" sx={{ width: "100%" }}>
                      {protectedPasswordError || errorMessage}
                    </Alert>
                  </Stack>
                )}
              </Box>
            </Stack>
          </FormProvider>
        </Stack>
      )}
    </>
  );
};
export default forwardRef(LoginOrJoinForm);
