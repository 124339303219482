import React, { FC } from 'react';
import classNames from 'classnames';

import { Avatar } from '../Avatar';
import { IconSvg } from '../IconSvg';
import { Logo } from '../Logo';
export interface ICardCompanyAdminsProps {
  containerClass?: string;
  avatarClass?: string;
  avatarUrl: string;
  title: string;
  subTitle: string;
  duration: string;
  location: string;
  detailsHref: string;
  icon?: string;
  primaryButton?: string;
  primaryButtonText?: string;
  secondaryButton?: string;
  secondaryButtonText?: string;
  isEdit?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onJobTitleClick?: Function;
  onCompanyClick?: Function;
  onCompanyEditClick?: Function;
  avatarDefaultClass?: string;
}

export const CardCompanyAdmins: FC<ICardCompanyAdminsProps> = ({
  containerClass,
  avatarClass,
  avatarUrl,
  title,
  subTitle,
  duration,
  location,
  detailsHref,
  icon,
  isEdit = false,
  onJobTitleClick,
  onCompanyClick,
  onCompanyEditClick,
  avatarDefaultClass
}) => {
  return (
    <div 
      className={classNames("flex items-start justify-between w-full", {
        [`${containerClass}`]: containerClass,
      })}
    >
      <div className="flex justify-between w-full">
        <div className="flex flex-row relative">
          <div className='flex-none cursor-pointer' onClick={() => onJobTitleClick && onJobTitleClick(detailsHref)}>
            {!avatarUrl?.includes('null') ? (
              <Logo src={avatarUrl} className={avatarClass} defaultLogo="company" />
            ) : (
              <Logo src={avatarUrl} className={avatarDefaultClass} defaultLogo="company" />
            )}
          </div>
          <div className="flex flex-col ml-5 w-[180px]">
            <div className="flex items-center justify-between relative">
              {
                title && 
                <span aria-label="card-company-target" onClick={() => onJobTitleClick && onJobTitleClick(detailsHref)} className="cursor-pointer text-[12px] font-[700] leading-4 text-[#000000] mb-[2px]">{title}</span>
              }
            </div>  
            {
              subTitle && 
              <span aria-label="card-job-title" onClick={() => onCompanyClick && onCompanyClick(detailsHref)} className="cursor-pointer text-[12px] font-[500] leading-[18px] text-[#000000] mb-[2px]">{subTitle}</span>
            }
            {
              duration && 
              <span className="text-[12px] text-[#3C3C3C] font-normal leading-[14px] mb-[2px]">{duration}</span>
            }
            {
              location && 
              <span className="text-[12px] text-[#3C3C3C] font-normal leading-[14px]">{location}</span>
            }
          </div>
        </div>
        {
          isEdit &&
          <span aria-label="card-company-edit-target" onClick={() => onCompanyEditClick && onCompanyEditClick()} className="cursor-pointer">
            <IconSvg imageUrl={icon ? icon : ""} />
          </span>
        }
      </div>
    </div>
  );
};