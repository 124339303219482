import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useWebSocket } from 'src/utils/hooks';
import { logout } from '../../store/auth';
import { resetUserEmail } from '../../store/checkUserEmail';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { webSocket } = useWebSocket();
  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
    dispatch(resetUserEmail());
    dispatch(logout());
    webSocket.then((ws) => {
      ws.close();
    });
    navigate('/');
  }, [dispatch, navigate, webSocket]);

  return null;
};

export default Logout;
