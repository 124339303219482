import {
  Box,
  Typography,
  Stack,
  Card,
  Button,
  CircularProgress,
  useMediaQuery,
  Autocomplete,
  TextField,
  Divider,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useCallback, useEffect, useState } from "react";
import { CustomAvatar } from "src/mui/components/hook-form/custom-avatar";
import useStyling from "src/mui/components/hook-form/useStyling";
import { useSurveyContext } from "src/mui/providers/GeneralContext";
import { getMinifiedImage } from "src/mui/utils/image";
import useEventIsPassed from "./useEventIsPassed";
import PastEvent from "./PastEvent";
import { IAppState } from "src/store";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { EventsService } from "src/services/events";
import { EventsServiceV2 } from "src/services/events/events-v2";
import { toast } from "react-toastify";

import { ReactComponent as UserDefault } from "../../../assets/images/default-profile.svg";
import ArrowIcon from "./ArrowIcon";

type Props = {
  userId: string;
  handleRequestMeeting(attendee: any): void;
};

const networkingFilterOptions = [
  { value: "First name,ASC", label: "Name(A-Z)" },
  { value: "First name,DESC", label: "Name(Z-A)" },
  { value: "Job title,ASC", label: "Role(A-Z)" },
  { value: "Job title,DESC", label: "Role(Z-A)" },
  { value: "Organisation,ASC", label: "Company(A-Z)" },
  { value: "Organisation,DESC", label: "Company(Z-A)" },
  { value: "match", label: "Match rate" },
]



const autoCompleteStyle = {
  overflow: "hidden",
  width: 227,
  ml: "25px",
  minWidth: 227,
  height: 32,
  borderRadius: '5px',
  alignItems: 'center',
  backgroundColor: "#ffffff",
  "& .MuiAutocomplete-popper": {
    width: "211px"
  },
  "& .MuiPaper-root": {
    "& .MuiAutocomplete-paper": {
      marginLeft: "-15px",
      marginTop: "-5px"
    }
  },

  '& .MuiAutocomplete-inputRoot': {
    flexWrap: 'nowrap !important',
  },
  '& .MuiOutlinedInput-root': {
    height: 32,
  },
  '& .MuiOutlinedInput-input': {
    alignContent: 'center'
  },
  '& .MuiOutlinedInput-root fieldset': {
    border: 'none'
  },
  '& input#Speaker-select-demo::placeholder': {
    fontSize: '15px',
    fontFamily: 'Poppins-500',
    color: '#000000'
  },
  '& .MuiAutocomplete-endAdornment': {
    right: '13px !important',
    top: 'calc(50% - 11.5px)'
  }
}

const buttonWidth = "110px";

const skeletonLoaderStyles = {
  backgroundColor: "#2D2D2D", opacity: 0.6
}
export default function MatchAttendeeList({
  userId,
  handleRequestMeeting,
}: Props) {
  const { isPastEvent, canAttendeesRequestMeeting, userExitedSurvey } = useEventIsPassed();
  const isMobileScreen = useMediaQuery("(min-width:768px)");
  const { secondaryBackgroundColor, secondaryFontColor, primaryBackgroundColor } = useStyling(
    null,
    null
  );

  const buttonStyles = {
    bgcolor: primaryBackgroundColor,
    height: "39px",
    padding: "0px 20px",
    color: "light",
    fontSize: "13px",
    borderRadius: "5px",
    fontFamily: "Poppins-500",
    textTransform: "capitalize",
    boxShadow: "none",
    "&:hover": {
      bgcolor: primaryBackgroundColor,
      boxShadow: "none",
    },
  }
  

  const event = useSelector((state: IAppState) => state.website.event!);
  const { upcomingEvent } = useSelector((state: IAppState) => state);
  const isSurveySubmitted =
  upcomingEvent?.value?.data?.attendeeStatus?.isSurveySubmitted;

  const { connectWithSuggests , surveyPages} = useSurveyContext();

  const [selectedFilter, setSelectedFilter] = useState<any>(networkingFilterOptions[0]);
  const [dataList, setDataList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalPage, setTotalPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [orderBy, setOrderBy] = useState('First name');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [fetching, setFetching] = useState(false);
  const matchLimit = 10;

  const hasMoreItems = nextPage <= totalPage;


  const loaders = () => {
    return (
      <Card
        sx={{
          p: 0,
          ml: "16px",
          mr: "15px",
          display: "flex",
          alignItems: "center",
          border: 0,
          backgroundColor: secondaryBackgroundColor || "white",
          borderRadius: "0px",
          boxShadow: "none",
          "& .MuiCard-root": {
            borderRadius: "0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            gap: 2,
            maxWidth: "100%",
            borderBottom: `1px solid rgba(${hexToRgb(secondaryFontColor)}, 0.3)`,
            paddingY: "20px",
          }}
        >
          <Stack
            direction={!isMobileScreen ? "column" : "row"}
            display="flex"
            spacing={1}
            justifyContent="space-between"
            rowGap="10px"
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{ maxWidth: "280px", minWidth: "280px" }}
            >
              <Stack
                direction="row"
                sx={{
                  position: "relative",
                  width: "60px",
                  height: "60px",
                  marginLeft: "5px",
                }}
              >
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="60px"
                  height="60px"
                  sx={{ borderRadius: "20px", ...skeletonLoaderStyles }}
                />
              </Stack>



              <Stack
                direction="column"
                spacing="3px"
                sx={{ pl: "10px" }}
              >
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="170px"
                  height="18px"
                  sx={{ borderRadius: "2px", ...skeletonLoaderStyles }}
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="170px"
                  height="18px"
                  sx={{ borderRadius: "2px", ...skeletonLoaderStyles }}
                />

                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="170px"
                  height="18px"
                  sx={{ borderRadius: "2px", ...skeletonLoaderStyles }}
                />
              </Stack>

            </Stack>
            <Box
              sx={{
                px: 0,
                alignItems: "center",
                display: "flex",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ mt: 0, flexFlow: "wrap", gap: "5px" }}
              >
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    borderRadius: "8px", width: isMobileScreen ? "170px" : null,
                    minWidth: "200px", height: "39px", ...skeletonLoaderStyles
                  }}
                />

                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    borderRadius: "8px", width: "110px",
                    minWidth: "110px", height: "39px", ...skeletonLoaderStyles
                  }}
                />

                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    borderRadius: "8px", width: "110px",
                    minWidth: "110px", height: "39px", ...skeletonLoaderStyles
                  }}
                />
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Card>
    )
  }

  const getLenovoAttendeeList = (attendees: any[]) => {
    const hardCodedSortingAttendee = [
      "scott tease",
      "sumir bhatia",
      "kumar mitra",
      "kavish shah",
      "benny wong",
      "justin ng",
    ];
    const customSort = (a: any, b: any) => {
      const indexA = hardCodedSortingAttendee.indexOf(
        a.fullName?.toLowerCase()
      );
      const indexB = hardCodedSortingAttendee.indexOf(
        b.fullName?.toLowerCase()
      );

      if (indexA === -1 && indexB === -1) {
        return 0;
      } else if (indexA === -1) {
        return 1;
      } else if (indexB === -1) {
        return -1;
      }

      return indexA - indexB;
    };

    const newResult = attendees?.filter(
      (x) => x.Organisation?.toLowerCase() == "lenovo"
    );
    const sortedUserList = newResult?.sort(customSort)?.filter(user => hardCodedSortingAttendee.includes(user?.fullName?.toLowerCase()));
    setDataList(sortedUserList);
  };

  // const fetchItems = useCallback(
  //   async () => {
  //     if (fetching) {
  //       return;
  //     }
  //     setFetching(true);
  //     setDataList([])
  //     let queryParams = `isMatch=true&userId=${userId}`;
  //     if (event?.canAttendeesRequestMeetingWithAttendee) {
  //       queryParams = queryParams + "&where[attendees]=true";
  //     }
  //     // ... (other conditions)

  //     try {
  //       let currentPage = nextPage;
  //       let shouldFetch = true;
  //       let accumulatedData: any = [];

  //       while (shouldFetch) {
  //         const response = await new EventsServiceV2().getAttendees(
  //           event?.id,
  //           queryParams,
  //           "",
  //           orderBy,
  //           currentPage,
  //           orderBy === "match" ? 500 : matchLimit,
  //           sortOrder
  //         );
  //         setIsLoading(false);
  //         if (response && currentPage <= response?.totalPage) {
  //           // const arrayUniqueByKey = Object.values(
  //           //   dataList
  //           //     .concat(response.data)
  //           //     .reduce((acc, obj) => ({ ...acc, [obj.userId]: obj }), {})
  //           // );
  //           accumulatedData = accumulatedData.concat(response.data);

  //           event?.websiteTemplate === "lenovo"
  //             ? getLenovoAttendeeList(accumulatedData)
  //             : setDataList(accumulatedData);

  //           currentPage += 1;
  //         } else {
  //           shouldFetch = false;
  //         }
  //       }
  //         setNextPage(currentPage);
  //     } finally {
  //       setIsLoading(false);
  //       setFetching(false);
  //     }
  //   },
  //   [dataList, fetching, nextPage, userId, event, matchLimit, sortOrder, orderBy] // Add necessary dependencies
  // );

  const fetchItems = useCallback(
    async () => {
      if (fetching) {
        return;
      }
      setFetching(true);
      let queryParams = `isMatch=true&userId=${userId}`;
      if (event?.canAttendeesRequestMeetingWithAttendee) {
        queryParams = queryParams + "&where[attendees]=true";
      }
      if (event?.canAttendeesRequestMeetingWithHost) {
        queryParams = queryParams + "&where[hosts]=true";
      }
      if (event?.canAttendeesRequestMeetingWithSpeaker) {
        queryParams = queryParams + "&where[speakers]=true";
      }
      if (event?.canAttendeesRequestMeetingWithSponsor) {
        queryParams = queryParams + "&where[sponsors]=true";
      }
      try {
        const response = await new EventsServiceV2().getAttendees(
          event?.id,
          queryParams,
          "",
          orderBy,
          nextPage,
          orderBy === "match" ? 500 : matchLimit,
          sortOrder
        );
        setTotalPage(response?.totalPage);
        if (response && nextPage <= response?.totalPage) {
          const arrayUniqueByKey = Object.values(
            dataList
              .concat(response.data)
              .reduce((acc, obj) => ({ ...acc, [obj.userId]: obj }), {})
          );

          // event?.websiteTemplate === "lenovo"
          //   ? getLenovoAttendeeList(arrayUniqueByKey)
          //   : setDataList(arrayUniqueByKey);

          setDataList(arrayUniqueByKey);

          setNextPage(nextPage + 1);
        } else {
          setNextPage(nextPage);
          setFetching(true);
        }
        setIsLoading(false);
      } finally {
        setIsLoading(false);
        setFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataList, fetching, nextPage]
  );

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      fetchItems();

    }
  }, [userId, sortOrder, orderBy]);

  const handleMessage = (data: any) => {
    window.open(`/wall/${data?.userId}`, "_blank");
  };

  const getAttendeeDetails = (data: any) => {
    return (
      <Box
        sx={{
          px: "10px",
          flexGrow: 1,
          minWidth: 0,
          maxWidth: "325px",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            color: secondaryFontColor,
            fontFamily: 'Poppins-600',
            fontSize: '16px',
            lineHeight: "30px",
          }}
        >
          {data["First name"]} {data["Last name"]}
        </Typography>

        <Stack
          direction="column"

          sx={{
            color: secondaryFontColor || "text.secondary", fontFamily: 'Poppins-400',
            fontSize: '15px'
          }}
        >
          <Typography
            variant="body2"
            component="span"
            sx={{
              m: 0, color: secondaryFontColor, fontFamily: 'Poppins-400',
              lineHeight: "17px",
              fontSize: '13px'
            }}
            noWrap
          >
            {data["Job title"]}
          </Typography>
          {/* <Typography
            variant="body2"
            component="span"
            sx={{ mt: "0 !important", color: secondaryFontColor }}
          >
            {data.Industry}
          </Typography> */}
          <Typography
            variant="body2"
            component="span"
            sx={{
              mt: "0 !important", color: secondaryFontColor, fontFamily: 'Poppins-500',
              lineHeight: "17px",
              fontSize: '13px'
            }}
          >
            {data.Organisation}
          </Typography>
        </Stack>
      </Box>
    );
  };

  const getConnectionStatus = (data: any) => {
    let currentStatus = "Connect";
    if (data?.connectionStatus === "Connected") {
      currentStatus = "Connected";
    } else if (data?.connectionStatus === "Not Connected") {
      currentStatus = "Connect";
    } else if (data?.connectionStatus === "Pending") {
      if (data?.hasRequest) {
        currentStatus = "Approve";
      }

      if (data?.sentRequest) {
        currentStatus = "Sent";
      }
    }
    return currentStatus;
  };

  const requestMeetingButton = (data: any) => {
    return (
      <Button
        fullWidth
        variant="contained"
        onClick={() => handleRequestMeeting(data)}
        sx={{
          width: isMobileScreen ? "170px" : null,
          minWidth: "200px",
          ...buttonStyles
        }}
      >
        Schedule a meeting
      </Button>
    );
  };

  const requestMessageButton = (data: any) => {
    return (
      <Button
        fullWidth
        variant="contained"
        onClick={() => handleMessage(data)}
        sx={{
          width: isMobileScreen ? buttonWidth : null,
          ...buttonStyles
        }}
      >
        Message
      </Button>
    );
  };

  const requestConnectButton = (data: any) => {
    const connectionStatus = getConnectionStatus(data);
    return (
      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          if (connectionStatus === "Connect") {
            handleConnectMeeting(data);
          } else if (connectionStatus === "Approve") {
            handleApproveRequest(data);
          }
        }}
        sx={{
          width: isMobileScreen ? buttonWidth : null,

          ...buttonStyles,
        }}
      >
        {connectionStatus}
      </Button>
    );
  };

  const handleConnectMeeting = async (data: any) => {
    const body = {
      connectionUserId: data?.userId,
    };
    const connectRequest = async () => connectWithSuggests(body);

    const eventService = new EventsService();
    try {
      const res: any = await eventService.connectWithSuggests(event?.id!, body);

      if (res?.message === "SUCCESS")
        setDataList((prevData) =>
          prevData?.map((item) =>
            item.userId === data.userId
              ? {
                ...item,
                connectionStatus: "Pending",
                hasRequest: 0,
                sentRequest: 1,
              }
              : item
          )
        );
    } catch (error: any) {
      if (error.statusCode === 500) {
        toast.error("Something went wrong. Please try again!");
      } else toast.error(error.message);
    }
  };

  const approveConnectionRequest = async (
    body: any,
    connectionRequestId: string
  ) => {
    const eventService = new EventsService();
    try {
      const res: any = await eventService.approveConnectionRequest(
        event?.id!,
        body,
        connectionRequestId
      );
      setDataList((prevData) =>
        prevData?.map((item) =>
          item.connectionRequestId === connectionRequestId
            ? {
              ...item,
              connectionStatus: "Connected",
              hasRequest: 0,
              sentRequest: 0,
            }
            : item
        )
      );
    } catch (error: any) {
      if (error.statusCode === 500) {
        toast.error("Something went wrong. Please try again!");
      } else toast.error(error.message);
    }
  };

  const handleApproveRequest = async (data: any) => {
    const body = {};

    const connectionRequestId = data?.connectionRequestId;
    approveConnectionRequest(body, connectionRequestId!);
  };

  const handleChangeFilters = async (option: any) => {
    if (!option) return;
    setDataList([]);
    setNextPage(1)
    if (option?.value === 'match') {
      setOrderBy('match')
      setSortOrder('DESC')
      setSelectedFilter(option)
      return
    }
    setOrderBy(option.value.split(',')[0])
    setSortOrder(option.value.split(',')[1])
    setSelectedFilter(option)
  }

  const hexToRgb = (hex: string) => {
    // Remove the # at the beginning
    if (hex) {
      hex = hex.replace("#", "");

      // Convert to RGB
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      return `${r}, ${g}, ${b}`;
    }

    return "112,112,112"

  };

  const showLoader = () => {
    return (
      <Stack
      sx={{
        direction: "column",
        '@media (max-width: 768px)': {
          height: "68%"
        },
        '@media (min-width: 768px)': {
          height: "80%"
        },
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: secondaryBackgroundColor || "white",
      }}
    >
      <CircularProgress color="info" />
    </Stack>
    );
  };

  useEffect(()=>{
    if(isSurveySubmitted)
    localStorage.removeItem("surveyAnswers");

  },[isSurveySubmitted])

  return (
        
        userExitedSurvey && isSurveySubmitted ? (
          <PastEvent attendeesListView={false}  userExitedSurvey={userExitedSurvey} description="Thank you very much, <br/> but at this time you don't fit the criteria for participation of this survey. "/>
        ):
        !canAttendeesRequestMeeting && isSurveySubmitted ? (
          <PastEvent attendeesListView={false} />
        ) : isPastEvent && isSurveySubmitted ? (
          <PastEvent />
        ) : (
          <>
            {isLoading || upcomingEvent?.loading ? (
         showLoader()
            ) : !isLoading && dataList && dataList?.length === 0 && (isSurveySubmitted || !surveyPages?.length || surveyPages?.every((x)=>!x?.eventSurveyFields?.length)) ? (
              <PastEvent attendeesListView={false} />
            ) :  isSurveySubmitted ?(
              <Stack>

                <Stack direction="row" justifyContent="space-between" sx={{ pl: "16px",'@media (max-width: 550px)': { flexDirection: "column" ,alignItems:"start",gap:"10px"}, }} alignItems="center">
                  <Typography sx={{ color: secondaryFontColor, lineHeight: '18px', fontSize: '15px', fontFamily: 'Poppins-500' }}>Meet, Connect & Message</Typography>

                  <Autocomplete
                    sx={{...autoCompleteStyle,'@media (max-width: 550px)': {ml :"0px"}}}
                    popupIcon={<ArrowIcon />}
                    value={selectedFilter}
                    options={networkingFilterOptions}
                    clearIcon={null}
                    onChange={(ev, newValue) => handleChangeFilters(newValue)}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (<Stack direction='row' alignItems='center' sx={{ pl: '15px' }}>
                      <Typography sx={{ color: '#000000', fontSize: '15px', fontFamily: 'Poppins-400', width: "50px" }}> Sort : </Typography>
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                        sx={{
                          color: '#000000', fontSize: '15px', fontFamily: 'Poppins-400', "& .MuiAutocomplete-input": {
                            color: '#000000', fontSize: '15px', fontFamily: 'Poppins-400',
                            minWidth: "115px !important"
                          }
                        }}
                      /></Stack>
                    )}
                  />

                </Stack>


                <Divider sx={{ padding: "0px", ml: "16px", mt: "10px !important", mb: "0px !important", borderColor: secondaryFontColor, pr: "15px", opacity: 0.3 }} />


                <Stack
                  sx={{
                    height: "100%",
                    overflowY: "scroll",
                    maxHeight: "calc(100vh - 212px)",
                    '@media (max-width: 768px)': { maxHeight: "calc(100vh - 393px)" },
                    ['--scrollbar-color' as string]: primaryBackgroundColor,
                  }}

                  className={event?.websiteTemplate === "spectre" ? "custom-scroll-spectre" : "custom-scroll"}
                >
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={fetchItems}
                    hasMore={hasMoreItems}
                    useWindow={false}
                    loader={loaders()}
                  >
                    {dataList?.map((data, index) => (
                      <Card
                        sx={{
                          p: 0,
                          ml: "16px",
                          mr: "15px",
                          display: "flex",
                          alignItems: "center",
                          border: 0,
                          backgroundColor: secondaryBackgroundColor || "white",
                          borderRadius: "0px !important",
                          boxShadow: "none",
                          "& .MuiCard-root": {
                            borderRadius: "0px",
                          },
                        }}
                        key={index}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            gap: 2,
                            maxWidth: "100%",
                            borderBottom: `1px solid rgba(${hexToRgb(secondaryFontColor)}, 0.3)`,
                            paddingY: "20px",
                          }}
                        >
                          <Stack
                            direction={!isMobileScreen ? "column" : "row"}
                            display="flex"
                            spacing={1}
                            justifyContent="space-between"
                            rowGap="10px"
                            pr="5px"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{
                                maxWidth: "280px", minWidth: "280px",
                                '@media (max-width: 768px)': {
                                  minWidth: "100%"
                                },
                              }}
                            >
                              <Stack
                                direction="row"
                                sx={{
                                  position: "relative",
                                  width: "60px",
                                  height: "60px",
                                  marginLeft: "5px",
                                }}
                              >
                                {getMinifiedImage(data?.avatar) ? (
                                  <CustomAvatar
                                    src={getMinifiedImage(data?.avatar)}
                                    alt={`${data["First name"]}`}
                                    name={`${data["First name"]}`}

                                    sx={{
                                      width: "60px",
                                      height: "60px",
                                      borderRadius: "20px",
                                      fontFamily: 'Poppins-500',
                                      fontSize: '15px'
                                    }}

                                  />
                                ) : (
                                  <Stack
                                    sx={{
                                      width: "60px",
                                      height: "60px",
                                      borderRadius: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor: "#edf2f5",
                                    }}
                                  >
                                    <UserDefault />
                                  </Stack>
                                )}
                                <Typography
                                  variant="h6"
                                  sx={{
                                    color: "#156DFF",
                                    padding:"2px 4px",
                                    // width: "28px",
                                    height: "28px",
                                    borderRadius: "5px",
                                    position: "absolute",
                                    backgroundColor: "#EDF2F5",
                                    left: "0px",
                                    bottom: "0px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontFamily: 'Poppins-600',
                                    fontSize: '10px'
                                    // fontSize: "1.23rem"
                                    //  px: 2
                                  }}
                                >
                                  <b>{data.match}%</b>
                                </Typography>
                              </Stack>
                              {getAttendeeDetails(data)}
                            </Stack>
                            <Box
                              sx={{
                                px: 0,
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                  mt: 0, flexFlow: "wrap", gap: "5px",
                                  '@media (max-width: 768px)': {
                                    flexFlow: "nowrap",
                                    flex: 1
                                  },
                                  '@media (max-width: 520px)': {
                                    flexFlow: "wrap",
                                    flex: 1
                                  },
                                }}
                              >
                                {requestMeetingButton(data)}
                                {data?.connectionStatus !== "Connected" &&
                                  requestConnectButton(data)}
                                {requestMessageButton(data)}
                              </Stack>
                            </Box>
                          </Stack>
                        </Box>
                      </Card>
                    ))}
                  </InfiniteScroll>
                </Stack>
              </Stack>

            ) : null}
          </>
        )
  );
}
