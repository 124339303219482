import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SpotlightEvents } from '../../../../components/spotlight';

import { IPostSpotLight, IPostSpotlightResult } from '../../../../models/post/IPostSpotlight';
import { UserService } from '../../../../services';
import { useCurrentUser } from '../../../../utils/hooks';

interface ISpotlightTabProps {
    id: string;
}

export const UserSpotlightTab = ({ id }: ISpotlightTabProps) => {
  const [searchText, setSearchText] = useState('');
  const [specificSpotlight, setSpecificSpotlight] = useState<IPostSpotLight[]>([]);
  const [fetching, setFetching] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [nextPage, setNextPage] = useState(2);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const hasMoreItems = nextPage <= totalPage;
  const userService: UserService = new UserService();
  const PAGE_SIZE = 100;

  const { slug: userId } = useParams();
  const currentUser = useCurrentUser();

  useEffect(() => {
    setIsOwnProfile(currentUser.id === userId || currentUser.slug === userId);
  }, [userId, currentUser]);

  const getSpotlight = async () => {
    const result: IPostSpotlightResult = await userService.getUserSpotlights(id, PAGE_SIZE, 0, searchText);
    setTotalPage(result.totalPage);
    setSpecificSpotlight(result.data);
  };

  const getSearchText = (searchText: string) => {
    setSearchText(searchText);
  };

  useEffect(() => {
    getSpotlight();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const fetchItems = useCallback(
    async () => {
      if (fetching) {
        return;
      }
      setFetching(true);
      try {
        const result = await userService.getUserSpotlights(id, PAGE_SIZE, nextPage, searchText);
        setTotalPage(result.totalPage);
        if (result && nextPage <= result.totalPage) {
          setSpecificSpotlight(specificSpotlight.concat(result.data));
          setNextPage(nextPage + 1);
        } else {
          setFetching(true);
        }
      } finally {
        setFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [specificSpotlight, fetching, nextPage]
  );

  return (
    <div className="w-full flex justify-center">
      <SpotlightEvents
        getSpotlight={getSpotlight}
        fetchItems={fetchItems}
        specificSpotlight={specificSpotlight}
        hasMoreItems={hasMoreItems}
        getSearchText={getSearchText}
        disableCreate={!isOwnProfile}
        createButtonWidth="md:min-w-[286px] min-w-[152px]"
        isOwnSpotlight
      />
    </div>
  );
};
