import { Reducer } from 'redux';

import { ICompanyResourcesState } from '../../../models/company/CompanyResourcesState';
import { CompanyResourcesActions } from './actions';
import { CompanyResourcesActionTypes } from './types';

const initialState: ICompanyResourcesState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const CompanyResourcesReducer: Reducer<ICompanyResourcesState, CompanyResourcesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CompanyResourcesActionTypes.COMPANY_RESOURCES_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case CompanyResourcesActionTypes.COMPANY_RESOURCES_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case CompanyResourcesActionTypes.COMPANY_RESOURCES_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
