import classNames from 'classnames';

interface ITicketBreadcrumbProps {
  selected: string;
  className?: string;
}

const TicketBreadcrumb = ({ selected, className }: ITicketBreadcrumbProps) => (
  <div 
    className={classNames("flex flex-row pl-[30px] py-6 items-center", {
      [`${className}`]: className,
    })}
  >
    <div
      className={classNames("text-sm leading-[20px] text-[#3C3C3C]", {
        "font-bold": selected === "Tickets & Registration",
      })}
    >
      Ticket &amp; Registration
    </div>
    <div className="ml-2 mr-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="1"
        viewBox="0 0 48 1"
      >
        <line
          id="Line_1395"
          data-name="Line 1395"
          x2="47"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </svg>
    </div>
    <div
      className={classNames("text-sm leading-[20px] text-[#3C3C3C]", {
        "font-bold": selected === "Your profile",
      })}
    >
      Your profile
    </div>
    <div className="ml-2 mr-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="1"
        viewBox="0 0 48 1"
      >
        <line
          id="Line_1395"
          data-name="Line 1395"
          x2="47"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </svg>
    </div>
    <div
      className={classNames("text-sm leading-[20px] text-[#3C3C3C]", {
        "font-bold": selected === "Your information",
      })}
    >
      Your information
    </div>
    <div className="ml-2 mr-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="1"
        viewBox="0 0 48 1"
      >
        <line
          id="Line_1395"
          data-name="Line 1395"
          x2="47"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </svg>
    </div>
    <div
      className={classNames("text-sm leading-[20px] text-[#3C3C3C]", {
        "font-bold": selected === "Security",
      })}
    >
      Security
    </div>
    <div className="ml-2 mr-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="1"
        viewBox="0 0 48 1"
      >
        <line
          id="Line_1395"
          data-name="Line 1395"
          x2="47"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </svg>
    </div>
    <div
      className={classNames("text-sm leading-[20px] text-[#3C3C3C]", {
        "font-bold": selected === "Registration confirmation",
      })}
    >
      Registration confirmation
    </div>
  </div>
);

export default TicketBreadcrumb;
