import {
  useEffect,
  useState,
} from 'react';

import { UseFormRegister } from 'react-hook-form';
import { SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';

import { IReactSelectOption } from '../../models/react-select-option';

interface YearSelectorProps {
  onChange: Function;
  register: UseFormRegister<any>;
  selectedYearStateToStore: IReactSelectOption | undefined;
}

export const YearSelector = ({
  onChange,
  register,
  selectedYearStateToStore,
}: YearSelectorProps) => {
  const [yearsListState, setYearsListState] = useState<IReactSelectOption[]>(
    []
  );

  const years: string[] = [];
  for (let i = new Date(Date.now()).getFullYear(); i >= 1961; i--)
    years.push(i.toString());

  useEffect(() => {
    const yearsList: IReactSelectOption[] = [];
    years.forEach((year) =>
      yearsList.push({
        key: year,
        value: year,
        label: year,
      })
    );
    setYearsListState(yearsList);
  }, []);

  const onChangeLocal = (event: SingleValue<IReactSelectOption>) => {
    onChange(event);
  };

  return (
    <Creatable
      id='yearSelector'
      className="dropdown-style"
      placeholder={'Enter a year'}
      options={yearsListState}
      isClearable={false}
      isSearchable={false}
      openMenuOnClick={true}
      {...register('jobTitle', {
        required: { value: true, message: 'Year is required' },
      })}
      value={selectedYearStateToStore}
      onChange={(e) => onChangeLocal(e)}
    />
  );
};
