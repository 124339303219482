import { SVGProps } from 'react';

function UnfollowIcon(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props;
     const isFill =  style?.fill;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="7.457" height="12.443" viewBox="0 0 7.457 12.443" style={style}>
            <path id="Path_27159" data-name="Path 27159" d="M18.277,5.338a1.2,1.2,0,0,0-.2-.162L13.25.353a1.205,1.205,0,0,0-1.7,1.7L15.7,6.211l-4.175,4.175a1.2,1.2,0,1,0,1.7,1.7l4.845-4.844a1.251,1.251,0,0,0,.2-1.909Z" transform="translate(-11.171 -0.001)" fill={isFill} fillRule="evenodd" />
        </svg>
  
  
    );
  }
  
  export default UnfollowIcon;