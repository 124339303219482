import { ReactComponent as HahaSVG } from '../../../assets/images/post-reactions/haha-emoji.svg';
import { ReactComponent as LikeInitialSvg } from '../../../assets/images/post-reactions/like-icon.svg';
// import { ReactComponent as LikedSvg } from '../../../assets/images/post-reactions/liked-emoji.svg';
import { ReactComponent as LikedSvg } from 'src/assets/images/post-reactions/like-emoji.svg';
import { ReactComponent as LoveSVG } from '../../../assets/images/post-reactions/love-emoji.svg';
import { ReactComponent as SmileSVG } from '../../../assets/images/post-reactions/smile-emoji.svg';
import { ReactComponent as WellDoneSVG } from '../../../assets/images/post-reactions/well-done-emoji.svg';

export const emojisMap = {
  none: {
    id: '',
    name: '',
    icon: LikeInitialSvg,
    color: ''
  },
  default: {
    id: 'default',
    name: 'Like',
    icon: LikeInitialSvg,
    color: 'gray'
  },
  like: {
    id: 'like',
    name: 'Like',
    icon: LikedSvg,
    color: 'blue'
  },
  love: {
    id: 'love',
    name: 'Love',
    icon: LoveSVG,
    color: 'red'
  },
  clap: {
    id: 'wellDone',
    name: 'Celebrate',
    icon: WellDoneSVG,
    color: 'yellow'
  },
  haha: {
    id: 'haha',
    name: 'Haha',
    icon: HahaSVG,
    color: 'yellow'
  },
  smile: {
    id: 'smile',
    name: 'Smile',
    icon: SmileSVG,
    color: 'yellow'
  }
} as const;

export default emojisMap;
