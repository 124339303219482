/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { RHFTextField } from 'src/mui/components/hook-form';
import './company-edit-page.css';
import PEditor from 'src/mui/components/hook-form/PEditor';
import PIndustrySelect from 'src/mui/components/hook-form/PIndustrySelect';
import { useSurveyContext } from 'src/mui/providers/GeneralContext';
import PUserSelect from 'src/mui/components/hook-form/PUserSelect';
import HeadquarterLocation from 'src/components/selectors/headquarter-location';
import PHeadCountSelect from './PHeadCountSelect';
import companyInfoIcon from '../../../assets/images/company/info-icon.svg';

export const BasicDetails = () => {
  const { getAllIndustries } = useSurveyContext();

  const renderTextFeild = (title:string, name:string, isRequired?:boolean) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
          { isRequired && <span className="text-[#FF0000]">*</span>}
        </Typography>
        <RHFTextField name={name} className="dropdown-container" />
      </Stack>
    </Stack>
  );

  const renderComapnyUrlField = (title:string, name:string, isRequired?:boolean) => (
    <Stack sx={{ }} direction="row" columnGap="10px">
      <Stack sx={{ width: '19px', height: '20px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>

      <Stack sx={{ width: '100%' }}>
        <Stack direction="row" columnGap={1} sx={{ alignItems: 'end' }}>
          <Stack>
            <Typography
              color="text.primary"
              variant="subtitle2"
              sx={{
                fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500', whiteSpace: 'nowrap'
              }}
            >
              {title}
              { isRequired && <span className="text-[#FF0000]">*</span>}
            </Typography>
            <Typography
              variant="body2"
              fontSize="12px"
              sx={{ mt: '7.5px', lineHeight: '17px' }}
            >
              www.panelist.com/
            </Typography>
          </Stack>
          <Stack sx={{ mb: '-10px', width: '100%' }}>
            <RHFTextField name={name} className="dropdown-container" />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );

  const renderIndustryFeild = (title:string, name:string, isRequired?:boolean) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
          { isRequired && <span className="text-[#FF0000]">*</span>}
        </Typography>
        <PIndustrySelect name={name} className="dropdown-container industry-dropdown" />
      </Stack>
    </Stack>
  );

  const renderSizeFeild = (title:string, name:string, isRequired?:boolean) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
          { isRequired && <span className="text-[#FF0000]">*</span>}
        </Typography>
        <PHeadCountSelect name={name} className="dropdown-container size-dropdown" />
      </Stack>
    </Stack>
  );

  const renderRichTextFeild = (title:string, name:string, isRequired?:boolean) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }} className="editor-container">
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
          { isRequired && <span className="text-[#FF0000]">*</span>}
        </Typography>
        {/* <RHFEditor name={name} /> */}
        {/* <RichTextEditor {...register('description')} value={companyDescription} onChange={handleAboutCompany} label="Description" /> */}
        <PEditor
          label="Description"
          simple
          // simpleExtras
          name={name}
        />
      </Stack>
    </Stack>
  );

  const renderAdminsFeild = (title:string, name:string, isRequired?:boolean) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
          { isRequired && <span className="text-[#FF0000]">*</span>}
        </Typography>
        {/* <PHeadCountSelect name={name} /> */}
        <PUserSelect name={name} className="dropdownRoot" style={{ maxWidth: '900px' }} />

      </Stack>
    </Stack>
  );

  const renderHeadquarter = (title:string, name:string, isRequired?:boolean) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
          { isRequired && <span className="text-[#FF0000]">*</span>}
        </Typography>
        <HeadquarterLocation name={name} className="text-field-container" />
      </Stack>
    </Stack>
  );

  useEffect(() => { getAllIndustries(); }, []);

  return (
    <Stack rowGap="15px">
      { renderTextFeild('Company Name', 'name', true)}
      { renderComapnyUrlField('Company URL', 'slug', true)}
      { renderTextFeild('Tagline', 'tagline')}
      { renderRichTextFeild('About', 'description')}
      { renderSizeFeild('Size', 'companySize', true)}
      { renderIndustryFeild('Industry', 'industry', true)}
      { renderTextFeild('Website', 'website', true)}
      { renderAdminsFeild('Company page admins', 'admins')}
      { renderHeadquarter('Headquarters', 'headquarter', true)}
    </Stack>
  );
};

export default BasicDetails;
