const CopyrightIntro = () => (
  <div className="bg-gray-0 w-full">
    <div className="block mb-8 w-9/12 mx-auto">
      <div className="block lg:flex flex-col  mx-auto mt-7 text-dark-blue text-md">
        <div className="text-blue-1">Effective June 16, 2022</div>
        <div className="mt-5 text-gray-1 table-of-contents text-lg5">
          Complaints regarding content posted on the Panelist website
        </div>
        <div className="mt-5">
          Panelist respects the intellectual property rights of others and desires to offer a
          platform which contains no content that violates those rights. Our User Agreement requires
          that information posted by Members be accurate, lawful and not in violation of the rights
          of third parties. To promote these objectives, Panelist provides a process for submission
          of complaints concerning content posted by our members. Our policy and procedures are
          described and/or referenced in the sections that follow.
        </div>

        <div className="mt-5">
          Please note that whether or not we disable access to or remove content, Panelist may make
          a good faith attempt to forward the written notification, including the complainant’s
          contact information, to the Member who posted the content and/or take other reasonable
          steps to notify the Member that Panelist has received notice of an alleged violation of
          intellectual property rights or other content violation. It is also our policy, in
          appropriate circumstances and in our discretion, to disable and/or terminate the accounts
          of Members, or groups as the case may be, who infringe or repeatedly infringe the rights
          of others or otherwise post unlawful content.
        </div>

        <div className="mt-5">
          Please note that any notice or counter-notice you submit must be truthful and must be
          submitted under penalty of perjury. A false notice or counter-notice may give rise to
          personal liability. You may therefore want to seek the advice of legal counsel before
          submitting a notice or a counter-notice.
        </div>
      </div>
      <hr className="my-10" />
      <div className="mt-5 text-gray-1 table-of-contents text-lg5">
        Claims regarding copyright infringement
      </div>

      <div className="mt-5 font-medium">Notice of Copyright Infringement:</div>

      <div className="mt-5">
        Panelist has implemented procedures for receiving written notification of claimed
        infringements. Panelist has also designated an agent to receive notices of claimed copyright
        infringement. If you believe in good faith that your copyright has been infringed, provide a
        written communication which contains:
      </div>

      <div className="mt-5 leading-9 w-11/12">
        <ul>
          <li>
            1. An electronic or physical signature of the person authorized to act on behalf of the
            owner of the copyright interest;
          </li>
          <li>2. A description of the copyrighted work that you claim has been infringed;</li>
          <li>
            3. A description specifying the location on our website of the material that you claim
            is infringing;
          </li>
          <li>4. Your email address and your mailing address and/or telephone number;</li>
          <li>
            5. A statement by you that you have a good faith belief that the disputed use is not
            authorized by the copyright owner, its agent, or the law; and
          </li>
          <li>
            6. A statement by you, made under penalty of perjury, that the information in your
            notice is accurate and that you are the copyright owner or authorized to act on the
            copyright owner’s behalf.
          </li>
        </ul>
      </div>

      <div className="mt-5">
        Please submit your notice to Panelist Corporation’s Copyright Agent as follows:
      </div>
      <div className="mt-3">
        <a
          className="text-blue-1 underline hover:no-underline"
          href="mailto:user-compliance@panelist.com"
        >
          user-compliance@panelist.com
        </a>
        .
      </div>
      <div className="mt-3">Or contact us by mail at:</div>
      <div className="ml-10">
        <div className="mt-5 font-medium">Panelist Pty Ltd</div>
        <div className="mt-1">ATTN: Copyright Agent</div>
        <div className="mt-1">Legal Department</div>
        <div className="mt-1">333 George St,</div>
        <div className="mt-1">Sydney, Australia 2000</div>
      </div>
    </div>
  </div>
);

export default CopyrightIntro;
