import { SVGProps } from 'react';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

function LayoutCustomIcon(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props;
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.195" height="19.479" viewBox="0 0 25.195 19.479">
            <rect id="Rectangle_8438" data-name="Rectangle 8438" width="13.828" height="13.106" rx="2"
                  transform="translate(11.367 6.373)" fill={defaultColor}/>
            <rect id="Rectangle_8439" data-name="Rectangle 8439" width="4.367" height="4.373" rx="1"
                  transform="translate(7)" fill={defaultColor}/>
            <rect id="Rectangle_8440" data-name="Rectangle 8440" width="8.367" height="7.373" rx="1"
                  transform="translate(0 9.975)" fill={defaultColor}/>
        </svg>

    );
}

export default LayoutCustomIcon;
