
import { useEffect } from "react";
import { useCurrentUser } from "src/utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { IEvent } from "src/models/event/event";
import { IAppState } from "src/store";
import { Helmet } from "react-helmet";
import EventOverview from "src/screens/event/website/event-overview";
import WhatToExpect from "src/screens/event/website/what-to-expect";
import WebsiteEventKeyDiscussion from "src/screens/event/website/website-event-key";
import WebsiteEventSpeaker from "src/screens/event/website/website-event-speaker";
import WebsiteEventAgenda from "src/screens/event/website/website-event-agenda";
import WebsiteEventPartner from "src/screens/event/website/website-event-partner";
import WebsiteEventQuickLinks from "src/screens/event/website/website-event-quicklinks";
import WebsiteEventLocation from "src/screens/event/website/website-event-location";
import WebsiteEventFooter from "src/screens/event/website/website-event-footer";
import { useParams } from "react-router";
import { init, checkRegistration, utmAnalytics } from "src/store/event-website";
import { Loader } from "src/shared-components/Loader";
import { WebsiteEventNavbar } from "src/screens/event/website/website-event-navbar";
import { Banner } from "./banner";
import { WebsiteEventPageSpectre } from "src/screens/event";
import { LenovoWebsite } from "../../lenovo-website";
import { BoxWebsite } from "../../box-website";
import { getUpcomingEvent } from "src/store/events/upcoming-event/actions";
import FactorEventLandingPage from "src/screens/event/factor-website";
import { EventNotFound } from "src/shared-components/EventNotFound";

const menuItemsVenue = [
  {
    menuItemText: "Home",
    href: "#banner",
    isSelected: true,
  },
  {
    menuItemText: "Event focus",
    href: "#event-focus",
    isSelected: false,
  },
  {
    menuItemText: "Audiences",
    href: "#speakers",
    isSelected: false,
  },
  {
    menuItemText: "Agenda",
    href: "#agenda",
    isSelected: false,
  },
  {
    menuItemText: "Partners",
    href: "#partners",
    isSelected: false,
  },
  {
    menuItemText: "Venue",
    href: "#venue",
    isSelected: false,
  },
  {
    menuItemText: "Contact us",
    href: "#contact-us",
    isSelected: false,
  },
];

const menuItems = [
  {
    menuItemText: "Home",
    href: "#banner",
    isSelected: true,
  },
  {
    menuItemText: "Event focus",
    href: "#event-focus",
    isSelected: false,
  },
  {
    menuItemText: "Audiences",
    href: "#speakers",
    isSelected: false,
  },
  {
    menuItemText: "Agenda",
    href: "#agenda",
    isSelected: false,
  },
  {
    menuItemText: "Partners",
    href: "#partners",
    isSelected: false,
  },
  {
    menuItemText: "Contact us",
    href: "#contact-us",
    isSelected: false,
  },
];

export const WebsiteView = () => {
  const params = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const utm_source = queryParams.get("utm_source");
  const utm_medium = queryParams.get("utm_medium");
  const utm_campaign = queryParams.get("utm_campaign");
  const eventId: string = params.slug!;
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const loaded = useSelector((state: IAppState) => state.website.loaded);
  const registered = useSelector((state: IAppState) => state.website.registered);
  const upcomingEvent = useSelector((state: IAppState) => state.upcomingEvent);

  const isRegistered = upcomingEvent?.value?.data?.attendeeStatus?.isRegistered;
  useEffect(() => {
    if (!eventId) return;
    dispatch(init(eventId));
  }, [eventId, dispatch]);

  useEffect(() => {
    if (!user || !eventId) return;
    dispatch(checkRegistration(eventId, user));
    dispatch(getUpcomingEvent(eventId));
  }, [eventId, user, dispatch]);

  const payload = {
    utm_source,
    utm_medium,
    utm_campaign,
  };

  useEffect(() => {
    if (event && loaded) {
      if (user) {
        if (registered) dispatch(utmAnalytics(event?.id, payload));
        if (registered === false || !isRegistered)
          dispatch(utmAnalytics(event?.id, payload, "guest"));
      } else {
        dispatch(utmAnalytics(event?.id, payload, "guest"));
      }
    }
  }, [event, loaded, user]);

  useEffect(() => {
    if (event) {
      if (registered) dispatch(utmAnalytics(event?.id, payload));
    }
  }, [registered]);

  if (!loaded && !event)
    return <Loader isLoaderLogo className="h-[100vh]" key="loader" />;
  if (loaded && !event)
    return <EventNotFound/>;;

  return (
    <>
      <Helmet>
        <meta name="title" content={event?.seoTitle} />
        <meta name="description" content={event?.seoDescription} />
      </Helmet>

      {(event.websiteTemplate === "lenovo" && <LenovoWebsite />) ||
        ((event.websiteTemplate === "default" || event.websiteTemplate === "afterHours") && (
          <div
            className="bg-gray-1"
            style={{
              background: event?.colorStyle?.values?.background,
            }}
          >
            <WebsiteEventNavbar
              menuItems={menuItems}
              menuItemsVenue={menuItemsVenue}
              showCreateEventButton
            />

            <Banner />
            <div className="max-w-[1124px] mx-auto px-[20px] xl:px-0">
              <div
                className="font-semibold text-[#0326E5] text-[21px] leading-[25px] mb-[15px] pb-[16px] border-b-1 border-[#CDDCF2]"
                style={{
                  color: event?.colorStyle?.values?.heading,
                }}
              ></div>
              <EventOverview />
              <WhatToExpect />
              <WebsiteEventKeyDiscussion />
              <WebsiteEventSpeaker />
              <WebsiteEventAgenda />
              <WebsiteEventPartner />
              <WebsiteEventLocation
                latitude={event?.latitude!}
                longitude={event?.longitude!}
                address={event?.location!}
              />
              <WebsiteEventQuickLinks />
            </div>
            <WebsiteEventFooter />
          </div>
        )) ||
        (event.websiteTemplate === "factor" && <FactorEventLandingPage />) ||
        (event.websiteTemplate === "spectre" && <WebsiteEventPageSpectre />) ||
        (event.websiteTemplate === "box" && <BoxWebsite />)}
    </>
  );
};
