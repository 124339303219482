/* eslint-disable jsx-a11y/label-has-associated-control */
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import {
  eventRegistrationStep1,
  eventRegistrationUserExsist
} from '../../../../store/user/signup/actions';
import { IAppState } from '../../../../store';
import { TextInput, Typography } from '../../../../shared-components';
import { Button } from '../../../../shared-components/V2';
import {
  atLeast3charRegex, emailRegex, passwordRegex
} from '../../../../utils';
import { LOGIN_SIGNUP_RECOVERY } from '../../../../constants';
import { IReactSelectOption } from '../../../../models/react-select-option';
import { IndustrySelector } from '../../../../components/selectors/industry-selector';
import { CitySelector } from '../../../../components/selectors/city-selector';
import { IReactSelectOptionCompany } from '../../../../models/company/company-react-select-option';
import { checkUserEmail } from '../../../../store/checkUserEmail';
import { JobTitleSelectorNew } from '../../../../components/selectors/jobTitle-selector-new';
import { CompanySelectorNew } from '../../../../components/selectors/company-selector-new';

interface ISignupProps {
  page: number;
  changePage: Function;
  isSignUp?: boolean;
  isSpectre?: boolean;
  isSpectreForm?: boolean;
}
export interface ISignUp {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  companyId: string;
  industryId: string;
  country: string;
  city: string;
  location: string;
  password: string;
  confirmPassword: string;
  postCode: string;

}
// eslint-disable-next-line no-unused-vars
export const EventSignup = ({
  isSignUp, changePage, isSpectre, isSpectreForm
}: ISignupProps) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<ISignUp>();

  // eslint-disable-next-line max-len
  const inputStyleCss = classNames('w-[438px] md:w-[438px] h-[30px] text-[13px] leading-4 font-light placeholder:text-[#666666] px-[12px] !rounded-10 border border-[#CDDCF2] focus-visible:outline-none mobile-input-width', {
    // eslint-disable-next-line max-len
    'helvetica-neue-35-thin !w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] placeholder:text-white text-white text-[15px] px-[15px] focus-visible:outline-none': isSpectre,
  });

  const labelClassCss = classNames({
    'bg-[#2d2d2d] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white': isSpectre,
  });

  // eslint-disable-next-line max-len
  const jobTitleCss = classNames({
    // eslint-disable-next-line max-len
    'helvetica-neue-35-thin !w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] placeholder:text-white text-white text-[15px] px-[15px] focus-visible:outline-none': isSpectre,
    'w-[438px] md:w-[438px] h-[30px] text-[13px] leading-4 font-light text-[#3f3f3f] px-[12px] !rounded-10 border border-[#CDDCF2] focus-visible:outline-none': !isSpectre,
  });

  const jobTitleLabelCss = classNames('text-[13px] font-[500] text-[#3c3c3c] leading-5', {
    'bg-[#2d2d2d] absolute z-10 left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white': isSpectre,
  });

  const userName: string | null = sessionStorage.getItem('userName');

  const dispatch = useDispatch();

  const { eventRegistration, errorMessage } = useSelector((state: IAppState) => state.signupUser);
  const isExist = useSelector((state: IAppState) => state.checkUserEmail.value?.result);

  const [selectedJobTitleStateToStore, setSelectedJobTitleStateToStore] = useState<IReactSelectOption>();
  const [selectedCompanyStateToStore, setSelectedCompanyStateToStore] = useState<IReactSelectOptionCompany>();
  const [selectedIndustryStateToStore, setSelectedIndustryStateToStore] = useState<IReactSelectOption>();
  const [citySelected, setCitySelected] = useState<any>(null);

  useEffect(() => {
    if (userName) setValue('email', userName!.toString());
  }, [userName, setValue]);

  useEffect(() => {
    if (eventRegistration) {
      setValue('firstName', eventRegistration?.firstName);
      setValue('lastName', eventRegistration?.lastName);
      setValue('email', eventRegistration?.email);
    }
  }, [eventRegistration, setValue]);

  useEffect(() => {
    if (eventRegistration) {
      let cityCountry: {
        city: string;
        country: string;
        location: string;
      } | null;
      // eslint-disable-next-line prefer-const
      cityCountry = {
        city: eventRegistration.city,
        country: eventRegistration.country,
        location: eventRegistration.location
      };
      setCitySelected(cityCountry.location);
    }
  }, [eventRegistration, setValue]);

  useEffect(() => {
    setValue('jobTitle', eventRegistration?.jobTitle?.value!);
    setSelectedJobTitleStateToStore(eventRegistration?.jobTitle!);
  }, [eventRegistration?.jobTitle, setValue]);

  useEffect(() => {
    setValue('companyId', eventRegistration?.company?.value!);
    setSelectedCompanyStateToStore(eventRegistration?.company!);
  }, [eventRegistration?.company, setValue]);

  useEffect(() => {
    setValue('industryId', eventRegistration?.industry);
    setSelectedIndustryStateToStore(eventRegistration?.industry!);
  }, [eventRegistration?.industry, setValue]);

  useEffect(() => {
    if (eventRegistration && eventRegistration.email) dispatch(checkUserEmail(eventRegistration));
  }, [eventRegistration, eventRegistration?.email, dispatch]);

  useEffect(() => {
    if (errorMessage) toast.error(errorMessage);
  }, [errorMessage]);

  const handleJobTitleSelected = (selectedOptions: any) => {
    if (selectedOptions) errors.jobTitle = undefined;
    setValue('jobTitle', selectedOptions?.label);
    setSelectedJobTitleStateToStore(selectedOptions?.label);
  };
  const handleCompanySelected = (selectedOptions: any) => {
    if (selectedOptions) errors.companyId = undefined;
    setValue('companyId', selectedOptions?.value);
    setSelectedCompanyStateToStore(selectedOptions);
  };
  const handleIndustrySelected = (selectedOptions: any) => {
    setValue('industryId', selectedOptions?.value);
    setSelectedIndustryStateToStore(selectedOptions);
  };

  const onSubmit = (data: any) => {
    if (isExist) {
      dispatch(
        eventRegistrationUserExsist(
          data,
          selectedCompanyStateToStore,
          selectedJobTitleStateToStore,
          selectedIndustryStateToStore
        )
      );
    } else {
      sessionStorage.setItem('eventRegStep', '1');
      changePage(1);
      dispatch(
        eventRegistrationStep1(
          data,
          selectedCompanyStateToStore,
          selectedJobTitleStateToStore,
          selectedIndustryStateToStore
        )
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={classNames({
          'w-[458px] mx-auto max-h-[calc(100vh-380px)] px-2.5 overflow-y-auto signUpScroll pb-[20px] mobile-signup-form': isSignUp,
          '!w-[90%]': isSpectre && isSignUp,
          '!max-h-full !w-[100%] !px-0': isSpectreForm
        })}
      >
        <div
          className={classNames('flex justify-start mt-2.5', {
            '!mt-[20px]': isSpectre
          })}
        >
          <div className="text-left mobile-w-full">
            <TextInput
              placeholder="Enter your first name"
              classes={inputStyleCss}
              labelclass={labelClassCss}
              textinputsize="medium"
              type="text"
              caption="First name*"
              {...register('firstName', {
                required: {
                  value: true,
                  message: LOGIN_SIGNUP_RECOVERY.FIRSTNAME_ISREQUIRED
                },
                pattern: {
                  value: atLeast3charRegex,
                  message: LOGIN_SIGNUP_RECOVERY.VALID_FIRSTNAME
                }
              })}
              autoComplete="new-password"
            />
          </div>
        </div>
        <div>
          {errors.firstName && (
            <Typography
              variant="ValidationMessage"
              text={errors.firstName?.message!.toString()}
              classes="flex content-start email-validation-message"
            />
          )}
        </div>
        <div
          className={classNames('flex justify-start mt-2.5', {
            '!mt-[20px]': isSpectre
          })}
        >
          <div className="text-left mobile-w-full">
            <TextInput
              placeholder="Enter your last name"
              classes={inputStyleCss}
              labelclass={labelClassCss}
              textinputsize="medium"
              type="text"
              caption="Last name*"
              {...register('lastName', {
                required: {
                  value: true,
                  message: LOGIN_SIGNUP_RECOVERY.LASTNAME_ISREQUIRED
                },
                pattern: {
                  value: atLeast3charRegex,
                  message: LOGIN_SIGNUP_RECOVERY.VALID_LASTNAME
                }
              })}
              autoComplete="new-password"
            />
          </div>
        </div>
        <div>
          {errors.lastName && (
            <Typography
              variant="ValidationMessage"
              text={errors.lastName?.message!.toString()}
              classes="flex content-start email-validation-message"
            />
          )}
        </div>

        <div
          className={classNames('flex justify-start mt-2.5', {
            '!mt-[20px]': isSpectre
          })}
        >
          <div className="text-left mobile-w-full">
            <TextInput
              classes={inputStyleCss}
              labelclass={labelClassCss}
              textinputsize="medium"
              type="text"
              caption="Email Address*"
              placeholder="Enter your email"
              {...register('email', {
                required: {
                  value: true,
                  message: LOGIN_SIGNUP_RECOVERY.EMAILADDRESS_ISREQUIRED
                },
                pattern: {
                  value: emailRegex,
                  message: LOGIN_SIGNUP_RECOVERY.VALID_EMAILADDRESS
                }
              })}
              id="emailAddress"
              autoComplete="new-password"
            />
          </div>
        </div>
        <div>
          {errors.email && (
            <Typography
              variant="ValidationMessage"
              text={errors.email?.message!.toString()}
              classes="flex content-start email-validation-message"
            />
          )}
        </div>
        <div
          className={classNames('flex flex-col relative justify-start mt-2.5', {
            '!mt-[20px]': isSpectre
          })}
        >
          <span>
            <label className={jobTitleLabelCss}>Job title*</label>
          </span>
          <div className="signup-dropdown">
            <JobTitleSelectorNew
              handleJobTitleSelected={handleJobTitleSelected}
              register={register}
              selectedJobTitleStateToStore={selectedJobTitleStateToStore}
              placeholder="Enter your job title"
              className={jobTitleCss}
            />
          </div>
        </div>
        <div>
          {errors.jobTitle && (
            <Typography
              variant="ValidationMessage"
              text={errors.jobTitle?.message!.toString()}
              classes="flex content-start email-validation-message"
            />
          )}
        </div>
        <div
          className={classNames('flex flex-col relative justify-start mt-2.5', {
            '!mt-[20px]': isSpectre
          })}
        >
          <span>
            <label className={jobTitleLabelCss}>Organisation*</label>
          </span>
          <div className="signup-dropdown">
            <CompanySelectorNew
              handleCompanySelected={handleCompanySelected}
              register={register}
              selectedCompanyStateToStore={selectedCompanyStateToStore}
              placeholder="Enter your Organisation"
              className={jobTitleCss}
            />
          </div>
        </div>
        <div>
          {errors.companyId && (
            <Typography
              variant="ValidationMessage"
              text={errors.companyId?.message!.toString()}
              classes="flex content-start email-validation-message"
            />
          )}
        </div>
        <div
          className={classNames('flex flex-col relative justify-start mt-2.5', {
            '!mt-[20px]': isSpectre
          })}
        >
          <span>
            <label className={jobTitleLabelCss}>Industry*</label>
          </span>
          <div className="signup-dropdown">
            <IndustrySelector
              onChange={handleIndustrySelected}
              register={register}
              selectedIndustryStateToStore={selectedIndustryStateToStore}
              classes="!w-full !h-[30px]"
              isSpectre={isSpectre}
              customStyleCss="helvetica-neue-35-thin contact-form-select"
            />
          </div>
        </div>
        <div>
          {errors.industryId && (
            <Typography
              variant="ValidationMessage"
              text={errors.industryId?.message!.toString()}
              classes="flex content-start email-validation-message"
            />
          )}
        </div>
        <div
          className={classNames('flex flex-col relative justify-start mt-2.5', {
            '!mt-[20px]': isSpectre
          })}
        >
          <span>
            <label className={jobTitleLabelCss}>City, Country*</label>
          </span>
          <CitySelector setValue={setValue} citySelected={citySelected} register={register} placeholder="Enter your city" classes={jobTitleCss} />
        </div>
        <div>
          {errors.location && (
            <Typography
              variant="ValidationMessage"
              text={errors.location?.message!.toString()}
              classes="flex content-start password-validation-message"
            />
          )}
        </div>
        <div
          className={classNames('flex flex-col relative justify-start mt-2.5', {
            '!mt-[20px]': isSpectre
          })}
        >
          <span>
            <label className={jobTitleLabelCss}>Post Code*</label>
          </span>
          <TextInput
            className={jobTitleCss}
            textinputsize="medium"
            type=""
            {...register('postCode', {
              required: {
                value: true,
                message: LOGIN_SIGNUP_RECOVERY.POSTCODE_ISREQUIRED
              },
              // pattern: {
              //   value: digitRegex,
              //   message: LOGIN_SIGNUP_RECOVERY.VALID_POSTCODE
              // }
            })}
            id="postCode"
            autoComplete="new-password"
          />
        </div>
        <div>
          {errors.postCode && (
            <Typography
              variant="ValidationMessage"
              text={errors.postCode?.message!.toString()}
              classes="flex content-start password-validation-message"
            />
          )}
        </div>
        <div
          className={classNames('flex justify-start mt-2.5', {
            '!mt-[20px]': isSpectre
          })}
        >
          <div className="text-left mobile-w-full">
            <TextInput
              placeholder="Enter your password"
              classes={inputStyleCss}
              labelclass={labelClassCss}
              textinputsize="medium"
              type="password"
              caption="Password*"
              {...register('password', {
                required: {
                  value: true,
                  message: LOGIN_SIGNUP_RECOVERY.PASSWORD_ISREQUIRED
                },
                pattern: {
                  value: passwordRegex,
                  message: LOGIN_SIGNUP_RECOVERY.VALIDPASSWORD
                }
              })}
              id="password"
              autoComplete="new-password"
            />
          </div>
        </div>
        <div>
          {errors.password && (
            <Typography
              variant="ValidationMessage"
              text={errors.password?.message!.toString()}
              classes="flex content-start password-validation-message"
            />
          )}
        </div>
        <div
          className={classNames('flex justify-start mt-2.5', {
            '!mt-[20px]': isSpectre
          })}
        >
          <div className="text-left mobile-w-full">
            <TextInput
              placeholder="Confirm your password"
              classes={inputStyleCss}
              labelclass={labelClassCss}
              textinputsize="medium"
              type="password"
              caption="Confirm password*"
              {...register('confirmPassword', {
                required: {
                  value: true,
                  message: LOGIN_SIGNUP_RECOVERY.CONFIRMPASSWORD_ISREQUIRED
                },
                validate: value => value === getValues('password') || LOGIN_SIGNUP_RECOVERY.MATCHPASSWORD
              })}
              id="confirmPassword"
              autoComplete="new-password"
            />
          </div>
        </div>
        <div>
          {errors.confirmPassword && (
            <Typography
              variant="ValidationMessage"
              text={errors.confirmPassword?.message!.toString()}
              classes="flex content-start password-validation-message"
            />
          )}
        </div>
      </div>
      <div
        className={classNames('flex items-center justify-between', {
          'px-0 mt-[30px]': isSpectreForm,
          'border-t-[0.5px] border-[#CECECE] pt-[10px] px-[13px]': !isSpectreForm
        })}
      >
        <div className="flex justify-center">
          <Button
            type="button"
            text="Back"
            size="large"
            className={classNames('bg-transparent text-sm1 font-semibold hover:text-[#285CB2] tracking-wide w-[136px] text-left h-[30px] !px-[10px]', {
              'text-white': isSpectre,
              'text-[#172746]': !isSpectre,
              '!px-[20px] !h-[32px] !w-auto rounded-full !bg-white !text-black text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer': isSpectreForm
            })}
            onClick={() => changePage(0)}
          />
        </div>
        {!isSpectreForm && (
          <div
            className={classNames('text-[9px] font-normal leading-[13px] text-[#18161C] text-center h-line', {
              '!text-white': isSpectre
            })}
          >
            <div>
              By clicking Agree &amp; Join, you agree to Panelist
              {' '}
              <a
                className={classNames('ml-1 text-[#285CB2] font-medium', {
                  '!text-[#0071e3]': isSpectre
                })}
                href="/user-agreement"
              >
                User Agreement,
                {' '}
              </a>
              <br />
              <span>
                <a
                  className={classNames('ml-1 text-[#285CB2] font-medium', {
                    '!text-[#0071e3]': isSpectre
                  })}
                  href="/privacy-policy"
                >
                  Privacy Policy
                </a>
                {' '}
                and
                {' '}
                <a
                  className={classNames('ml-1 text-[#285CB2] font-medium', {
                    '!text-[#0071e3]': isSpectre
                  })}
                  href="/privacy-policy"
                >
                  Cookie Policy
                </a>
              </span>
            </div>
          </div>
        )}
        <div className="flex justify-center">
          <Button
            type="submit"
            text="Agree &amp; Join"
            size="large"
            className={classNames('bg-[#0049EF] text-white text-sm1 font-semibold hover:bg-[#244E93] tracking-wide w-[136px] h-[30px] !px-0', {
              '!bg-[#0071e3]': isSpectre,
              'px-[20px] !h-[32px] rounded-full bg-[#0071e3] text-white text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer': isSpectreForm
            })}
          />
        </div>
      </div>
    </form>
  );
};

export default EventSignup;
