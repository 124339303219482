import { useEffect, useState } from 'react';

export default function useShowMoreCards(perRow: number, cards: any[]) {
  const [countMoreCards, setCountMoreCards] = useState(perRow);
  const [displayBtn, setDisplayBtn] = useState(false);
  const [moreCards, setMoreCards] = useState<any[]>([]);

  // Update displayed cards and button visibility
  useEffect(() => {
    const newCards = cards.slice(0, countMoreCards);
    setMoreCards(newCards);
    setDisplayBtn(countMoreCards < cards.length);
  }, [cards, countMoreCards]);

  // Reset count when perRow changes
  useEffect(() => {
    setCountMoreCards(perRow);
  }, [perRow]);

  const handleSeeMore = () => {
    setCountMoreCards(prev => prev + perRow);
  };

  return { moreCards, displayBtn, handleSeeMore };
}