import { EventsNavbar, Navbar } from '../../../components/navbar';
import Footer from '../../home/footer';
import Banner from './banner';
import { ComparePackages } from './compare-packages';
import EventBenefits from './event-benefits';
import FAQs from './faqs';
import FeaturesCarousel from './features-carousel';
import Packages from './packages';
import PopularFeatures from './popular-features';
import Testimonials from './testimonials';

const menuItems = [
  {
    menuItemText: 'Packages',
    href: '#packages',
    isSelected: false
  },
  {
    menuItemText: 'Compare',
    href: '#compare',
    isSelected: false
  },
  {
    menuItemText: 'Features',
    href: '#features',
    isSelected: false
  },
  {
    menuItemText: 'FAQ',
    href: '#faq',
    isSelected: false
  }
];

export const FeaturesAndPackages = () => (
  <>
    <Navbar />
    <EventsNavbar menuItems={menuItems} showCreateEventButton />
    <div className="mx-auto">
      <div className="mx-auto">
        <div className="flex flex-col">
          <Banner />
          <Packages />
          <ComparePackages />
          <PopularFeatures />
          <EventBenefits />
          <FeaturesCarousel />
          <Testimonials />
          <FAQs />
          {/* <FooterImage /> */}
          <Footer />
        </div>
      </div>
    </div>
  </>
);

export default FeaturesAndPackages;
