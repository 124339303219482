import { SVGProps } from 'react';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

function LoadFavPageIcon(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props; 
    const defaultColor = getBrandingColors()?.Main || 'rgba(42,65,87,0.24)';
    const textColor = getBrandingColors()?.Text || '#222';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26.933"
            height="21.546"
            viewBox="0 0 26.933 21.546"
        >
            <g id="load-list-alt-1-svgrepo-com" transform="translate(-2 -4)">
                <path
                    id="Path_14903"
                    data-name="Path 14903"
                    d="M2,4.673A.673.673,0,0,1,2.673,4H28.26a.673.673,0,1,1,0,1.347H26.427a.188.188,0,0,0-.188.188V21.423a.2.2,0,0,1-.059.143l-3.922,3.922a.2.2,0,0,1-.143.059H7.387a2.693,2.693,0,0,1-2.693-2.693V5.535a.188.188,0,0,0-.188-.188H2.673A.673.673,0,0,1,2,4.673Z"
                    fill={defaultColor}
                />
                <path
                    id="Path_14904"
                    data-name="Path 14904"
                    d="M12,17.407V10"
                    transform="translate(3.466 2.08)"
                    fill="none"
                    stroke={textColor}
                    strokeLinecap="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_14905"
                    data-name="Path 14905"
                    d="M9.5,12.179l3.24-2.592a.2.2,0,0,1,.252,0l3.24,2.592"
                    transform="translate(2.6 1.921)"
                    fill="none"
                    stroke={textColor}
                    strokeLinecap="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_14906"
                    data-name="Path 14906"
                    d="M2.5,4.5H28.086"
                    transform="translate(0.173 0.173)"
                    fill="none"
                    stroke={textColor}
                    strokeLinecap="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_14907"
                    data-name="Path 14907"
                    d="M17.406,21.327l3.922-3.922a.2.2,0,0,0,.059-.143V16.2a.2.2,0,0,0-.2-.2H17.347A1.347,1.347,0,0,0,16,17.347v3.838a.2.2,0,0,0,.2.2h1.061A.2.2,0,0,0,17.406,21.327Z"
                    transform="translate(4.853 4.16)"
                    fill={textColor}
                />
            </g>
        </svg>
    );
}

export default LoadFavPageIcon;
