import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import {
  IChatHead,
  IConversationState
} from '../../models/messages/message';

const initialState: IConversationState = {
  openConversations: []
  // allConversations: {}
};

export const oneToOneConversationSlice = createSlice({
  name: 'oneToOneConversation',
  initialState,
  reducers: {
    openConversation: (state, action: PayloadAction<IChatHead>) => {
      if (
        state.openConversations.find(
          (conversation) => conversation.conversationId === action.payload.conversationId
        )
      )
        return;
      if (state.openConversations.length >= 3) {
        state.openConversations.shift();
      }
      state.openConversations.push(action.payload);
    },
    closeConversation: (state, action: PayloadAction<string>) => {
      state.openConversations = state.openConversations.filter(
        (conversation) => conversation.conversationId !== action.payload
      );
    }
  }
});

export const { openConversation, closeConversation } = oneToOneConversationSlice.actions;
