export enum CreatePostCommentActionTypes {
    CREATE_COMMENT_IN_PROGRESS = 'CREATE_COMMENT_IN_PROGRESS',
    CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS',
    CREATE_COMMENT_ERROR = 'CREATE_COMMENT_ERROR'
}

export enum LikeCommentActionTypes {
    LIKE_IN_PROGRESS = 'LIKE_IN_PROGRESS',
    LIKE_COMMENT = 'LIKE_COMMENT',
    UNLIKE_COMMENT = 'UNLIKE_COMMENT',
    LIKE_COMMENT_ERROR = 'LIKE_COMMENT_ERROR'
}
