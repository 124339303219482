import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
// import { CompanyService } from 'src/api/companyService';
import { InterestService } from 'src/services/interestService';
import PUserAutocomplete from './PUserAutocomplete';

export type OptionType = {
  name: string;
  id: string;
};

interface IPUserSelect {
  sx?: any;
  label?: string;
  name: string;
  defaultValue?: OptionType[];
  defaultFormValue?: OptionType[];
  disabled?: boolean;
  multiple?: boolean;
  Icon?: any;
  placeholder?: string;
  clearOnBlur?: boolean;
  noOptionsText?: string;
  selectedData?: any;
  className?: string;
  onChange?: (value: OptionType[]) => void;
}

function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const PInterestSelect = ({
  defaultValue,
  label,
  name,
  sx,
  multiple,
  disabled = false,
  Icon,
  placeholder,
  clearOnBlur,
  noOptionsText,
  selectedData,
  className,
  onChange,
  defaultFormValue,
}: IPUserSelect) => {
  const [selectOption, setSelectOption] = useState<OptionType[]>([]);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 500);


  useMemo(() => {
    if (debouncedInputValue) {
      const interestService = new InterestService();
      interestService.getAllInterest(debouncedInputValue)?.then(({ data }) => {
        const sortedUsers: OptionType[] = data;
        const filteredOptions = selectedData
          ? sortedUsers?.filter(
            (option) =>
              !selectedData?.some((selected: any) =>
                selected?.name ? selected?.name === option?.name : selected === option?.name,
              ),
          ) : sortedUsers;
        setSelectOption(filteredOptions ?? []);
      });
    } else {
      setSelectOption([]);
    }
  }, [debouncedInputValue]);

  const filteredOptions = (options: OptionType[], state: any) =>
    options?.filter(
      (option: OptionType) =>
        `${option?.name}`?.toLowerCase()?.includes(state?.inputValue?.toLowerCase()) ||
        option?.name?.toLowerCase()?.includes(state?.inputValue?.toLowerCase()),
    );

  const handleSelectionChange = (value: any) => {
    setSelectOption(value);
    if (onChange) {
      onChange(value);
    }
  };

  useEffect(() => {
    if (defaultFormValue) {
      setSelectOption(defaultFormValue)
    }

  }, [defaultFormValue]);
  return (
    <PUserAutocomplete
      sx={sx}
      className={className}
      label={label}
      popupIcon={Icon}
      placeholder={placeholder}
      name={name}
      inputValue={inputValue}
      onInputChange={(event, value) => setInputValue(value)}
      multiple={multiple}
      freeSolo={clearOnBlur ? !clearOnBlur : true}
      fullWidth
      disabled={disabled}
      defaultFormValue={defaultFormValue}
      {...(defaultValue && { defaultValue })}
      options={selectOption}
      filterOptions={filteredOptions}
      onChange={handleSelectionChange}
      renderOption={(props, option: any) => (
        <Box component="li" {...props} sx={{ px: '8px !important' }}>
          {option?.name || option}
        </Box>
      )}
      getOptionLabel={(option: any) => option?.name || option}
      noOptionsText={noOptionsText}
    />
  );
};

export default PInterestSelect;
