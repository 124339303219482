import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import {
  IChatHead,
  IChatHeadState
} from '../../models/messages/message';

const initialState: IChatHeadState = {
  chatHeads: []
};

export const chatHeadsSlice = createSlice({
  name: 'chatHeads',
  initialState,
  reducers: {
    addToChatHeads: (state, action: PayloadAction<IChatHead>) => {
      if (!state.chatHeads.find((item) => item.conversationId === action.payload.conversationId))
        state.chatHeads.push(action.payload);
    },
    closeChatHead: (state, action: PayloadAction<string>) => {
      state.chatHeads = state.chatHeads.filter(
        (chatHead) => chatHead.conversationId !== action.payload
      );
    }
  }
});

export const { addToChatHeads, closeChatHead } = chatHeadsSlice.actions;
