import { SVGProps } from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
}

function GridViewIcon({ fillColor, fillBgColor, ...props }: Props) {
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
        <svg id="Page-1" xmlns="http://www.w3.org/2000/svg" width="14.971" height="14.258" viewBox="0 0 14.971 14.258">
            <g id="Dribbble-Light-Preview">
                <g id="icons">
                    <path id="grid-_1526_" data-name="grid-[#1526]" d="M176.474,49.98h-1.5a1.462,1.462,0,0,0-1.5,1.426v1.426a1.462,1.462,0,0,0,1.5,1.426h1.5a1.462,1.462,0,0,0,1.5-1.426V51.406a1.462,1.462,0,0,0-1.5-1.426m-5.24,0h-1.5a1.462,1.462,0,0,0-1.5,1.426v1.426a1.462,1.462,0,0,0,1.5,1.426h1.5a1.462,1.462,0,0,0,1.5-1.426V51.406a1.462,1.462,0,0,0-1.5-1.426m-5.24,0h-1.5a1.462,1.462,0,0,0-1.5,1.426v1.426a1.462,1.462,0,0,0,1.5,1.426h1.5a1.462,1.462,0,0,0,1.5-1.426V51.406a1.462,1.462,0,0,0-1.5-1.426m10.479-4.99h-1.5a1.462,1.462,0,0,0-1.5,1.426v1.426a1.462,1.462,0,0,0,1.5,1.426h1.5a1.462,1.462,0,0,0,1.5-1.426V46.416a1.462,1.462,0,0,0-1.5-1.426m-5.24,0h-1.5a1.462,1.462,0,0,0-1.5,1.426v1.426a1.462,1.462,0,0,0,1.5,1.426h1.5a1.462,1.462,0,0,0,1.5-1.426V46.416a1.462,1.462,0,0,0-1.5-1.426m-5.24,0h-1.5a1.462,1.462,0,0,0-1.5,1.426v1.426a1.462,1.462,0,0,0,1.5,1.426h1.5a1.462,1.462,0,0,0,1.5-1.426V46.416a1.462,1.462,0,0,0-1.5-1.426M176.474,40h-1.5a1.462,1.462,0,0,0-1.5,1.426v1.426a1.462,1.462,0,0,0,1.5,1.426h1.5a1.462,1.462,0,0,0,1.5-1.426V41.426a1.462,1.462,0,0,0-1.5-1.426m-5.24,0h-1.5a1.462,1.462,0,0,0-1.5,1.426v1.426a1.462,1.462,0,0,0,1.5,1.426h1.5a1.462,1.462,0,0,0,1.5-1.426V41.426a1.462,1.462,0,0,0-1.5-1.426m-3.743,1.426v1.426a1.462,1.462,0,0,1-1.5,1.426h-1.5a1.462,1.462,0,0,1-1.5-1.426V41.426A1.462,1.462,0,0,1,164.5,40h1.5a1.462,1.462,0,0,1,1.5,1.426" transform="translate(-163 -40)" fill={defaultColor} fillRule="evenodd" />
                </g>
            </g>
        </svg>
    );
}

export default GridViewIcon;