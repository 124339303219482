/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { ImageButton, TextInput, Typography } from '../../shared-components';
import arrowbutton from '../../assets/images/arrowbutton.svg';
import { IAppState } from '../../store';
import { emailRegex } from '../../utils';
import { LOGIN_SIGNUP_RECOVERY } from '../../constants';
import { checkUserEmail } from '../../store/checkUserEmail/index';
import { Button } from '../../shared-components/V2';

interface ILoginOrSignup {
  email: string;
}

interface ILoginOrJoinProps {
  from: string;
  changePage: any;
  isEventRegister?: boolean;
  setShowModal?: Function;
  isSpectre?: boolean;
  isSpectreForm?: boolean;
  isBlueTheme?: boolean;
}

export const LoginOrJoinForm = ({
  from, changePage, isEventRegister, setShowModal, isSpectre, isSpectreForm, isBlueTheme
}: ILoginOrJoinProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ILoginOrSignup>();

  const queryParams = new URLSearchParams(window.location.search);
  const redirectTo = queryParams.get('login');

  const getRedirectUrl = (url: string) => {
    if (redirectTo) {
      return `${url}?login=${redirectTo}`;
    }
    return `${url}`;
  };

  const dispatch = useDispatch();
  const selector = useSelector((state: IAppState) => state.checkUserEmail);
  const navigate = useNavigate();

  const onSubmit = (data: ILoginOrSignup) => {
    sessionStorage.setItem('userName', data.email);
    sessionStorage.setItem('eventRegStep', '0');
    dispatch(checkUserEmail(data));
  };

  // eslint-disable-next-line no-unused-vars
  const initialRender = useRef(true);
  useEffect(() => {
    if (!selector?.loading && !!selector.value) {
      const userExists = selector.value?.result;
      if (userExists && from === 'event') {
        changePage(1);
      } else if (userExists && from === 'site') {
        navigate(getRedirectUrl('/login'));
      } else if (!userExists && from === 'event') {
        changePage(2);
      } else if (!userExists && from === 'site') {
        navigate('/join');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector.value, from, changePage, navigate]);

  // eslint-disable-next-line no-unused-vars
  const setCursorInputPosition = (e: any, pos: any) => {
    e.setSelectionRange(pos, pos);
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-center h-[60.4px]">
        <div className="flex flex-col">
          <TextInput
            className={classNames('w-240 md:w-336 h-[30px] text-sm1 font-light text-[#666666] px-2.5 rounded-10 placeholder:text-[#666666] focus-visible:outline-none', {
              // eslint-disable-next-line max-len
              'helvetica-neue-35-thin !h-[35px] !rounded-10 !bg-transparent !border-1 !border-[#8f8f8f] !placeholder:text-[15px] !placeholder:text-white !text-white text-[15px] px-[15px] focus-visible:outline-none': isSpectre,
            })}
            textinputsize="medium"
            type="text"
            autoFocus
            caption="Email Address*"
            placeholder="Enter your email"
            {...register('email', {
              required: {
                value: true,
                message: LOGIN_SIGNUP_RECOVERY.EMAILADDRESS_ISREQUIRED
              },
              pattern: {
                value: emailRegex,
                message: LOGIN_SIGNUP_RECOVERY.VALID_EMAILADDRESS
              }
            })}
            autoComplete="off"
            labelclass={classNames({
              'bg-[#2d2d2d] absolute left-[10px] -top-[10px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white': isSpectre,
            })}
            classes={classNames({
              // eslint-disable-next-line max-len
              'helvetica-neue-35-thin !w-full !h-[35px] !rounded-10 !bg-transparent !border-1 !border-[#8f8f8f] !placeholder:text-[15px] !placeholder:text-white text-white text-[15px] px-[15px] focus-visible:outline-none': isSpectre,
            })}
            isBlueTheme={isBlueTheme}
          />
          <div>
            {errors.email && (
              <Typography
                variant="ValidationMessage"
                text={errors.email?.message!.toString()}
                classes="flex content-start mt-1"
              />
            )}
          </div>
        </div>
        <div
          className={classNames('mt-6 ml-2', {
            '!mt-[1px]': isSpectre,
          })}
        >
          <ImageButton imageUrl={arrowbutton} data-test-id="login-button" />
        </div>
      </div>
      {isEventRegister && !isSpectreForm && (
        <div className="flex justify-between border-t-[0.5px] pt-[10px] px-[16px] border-[#CECECE] mt-[206px] mobile-mt-login">
          <button
            type="button"
            className={classNames('bg-[#EDF2F5] hover:underline px-[18px] h-[30px] font-[600] tracking-tight rounded-[15px] text-[13px] leading-[18px] text-[#172746]', {
              '!bg-white': isSpectre,
            })}
            onClick={() => setShowModal && setShowModal(false)}
          >
            Cancel
          </button>
          <Button
            type="submit"
            text="Continue"
            size="x-small"
            className={classNames('w-110 bg-[#0049EF] text-white text-sm font-semibold hover:bg-[#244E93]', {
              '!bg-[#0071e3]': isSpectre,
            })}
          />
        </div>
      )}
      {isSpectreForm && (
        <div className="flex justify-between pt-[10px] mt-[100px]">
          <div
            className="px-[20px] h-[32px] rounded-full bg-white text-black text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer"
            onClick={() => setShowModal && setShowModal(false)}
          >
            Back
          </div>
          <Button
            type="submit"
            text="Continue"
            size="x-small"
            className={classNames('px-[20px] h-[32px] rounded-full bg-[#0071e3] text-white text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer')}
          />
        </div>
      )}
    </form>
  );
};

export default LoginOrJoinForm;
