/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
// Accordion.tsx
import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import AccordionHeader from './accordion-header';
import AccordionBody from './accordion-body';
import AccordionSubHeader from './accordion-sub-header';

interface SessionDriversAccordionProps {
  title?: string;
  session?: any;
}

const SessionDriversAccordion: React.FC<SessionDriversAccordionProps> = ({ session, title }) => {
  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({});
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);

  const toggleAccordion = (id: string) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const toggleHeader = () => {
    setIsHeaderOpen(!isHeaderOpen);
  };
  const sessionDetailsParse = (item: any) => ({
    time: `${format(parseISO(item?.startTime), 'hh:mm a')} - ${format(
      parseISO(item?.endTime),
      'hh:mm a'
    )}`,
    title: item?.title,
    description: item?.summary,
  });

  const userParse = (item: any) => {
    const company = item?.eventSpeakers[0]?.company?.name || item?.eventSponsors[0]?.company?.name;

    return {
      name: item?.eventSpeakers[0]?.firstName
        ? `${item?.eventSpeakers[0]?.firstName} ${item?.eventSpeakers[0]?.lastName}`
        : item?.eventSponsors[0]?.company?.name,
      position: item?.eventSpeakers[0]?.firstName
        ? item?.eventSpeakers[0]?.jobTitle
        : item?.eventSponsors[0]?.industry?.name,
      company,
      isSpeaker: !!item?.eventSpeakers[0]?.firstName,
      slug: item?.eventSpeakers[0]?.firstName
        ? `/wall/${item?.eventSpeakers[0]?.user?.slug}?tab=about`
        : `/wall/company/${item?.eventSponsors[0]?.company?.slug}`,
    };
  };
  const getHeaderPicture = (item:any) => {
    if (item?.eventSpeakers[0]?.firstName || item?.eventSponsors[0]?.company?.logo) {
      return `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${
        item?.eventSpeakers[0]?.firstName ? item?.eventSpeakers[0]?.photo : item?.eventSponsors[0]?.company?.logo
      }`;
    }

    return item?.media;
  };

  return (
    <div className="w-full rounded-10  mb-[10px]">
      <AccordionSubHeader
        isOpen={isHeaderOpen}
        toggleAccordion={toggleHeader}
        title={session?.key}
      />
      {isHeaderOpen && session?.sessions.length > 0
      && session?.sessions?.map((
        item:any
      ) => (
        <div className="transition-all" key={item?.id}>
          <AccordionHeader
            isOpen={!!isOpen[item?.id]}
            key={item?.id}
            toggleAccordion={() => toggleAccordion(item?.id)}
            // UserPicture={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${
            //   getHeaderPicture(item)
            // }`}
            UserPicture={getHeaderPicture(item)}
            user={userParse(item)}
            sessionDetails={sessionDetailsParse(item)}
            session={item}
            eventSponsors={item?.eventSponsors || []}
            speaker={item?.eventSpeakers[0]}
            isByDrivers
          />
          <div
            className={`overflow-hidden transition-all duration-300 ${
              isOpen[item?.id] ? 'rounded-b-[10px]' : 'max-h-0'
            }`}
          >
            <AccordionBody
              isOpen={!!isOpen[item?.id]}
              key={item?.id}
              eventSpeakers={item?.eventSpeakers?.slice(1) || []}
              sessionDetails={sessionDetailsParse(item)}
              eventSponsors={item?.eventSponsors || []}
              isByDrivers
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SessionDriversAccordion;
