import testimonialsbanner from '../../../assets/images/feature/banner-last@2x.jpg';
import ianavatar from '../../../assets/images/feature/ian-avatar.svg';
import joshavatar from '../../../assets/images/feature/josh-avatar.svg';

interface ITestimonial {
  text: string;
  avatar: string;
  name: string;
  position: string;
  companyName: string;
}

const testimonials: ITestimonial[] = [
  {
    // eslint-disable-next-line max-len
    text: '"Our event is all about spreading ideas and inspiring action within a hand-picked community. Panelist is a powerful tool that helped us build a tribe of change makers before, during and after the event.”',
    avatar: ianavatar,
    name: 'Ian Yun',
    position: 'Communications Manager',
    companyName: 'Mo Works Creative Agency'
  },
  {
    text: '“It was pretty clear that Panelist had the exact tools that we needed to ensure a smooth and engaging attendee experience.”',
    avatar: joshavatar,
    name: 'Josh Heid',
    position: 'Events Director',
    companyName: 'Sit-Down'
  }
];

const Testimonials = () => (
  <div className="flex flex-col mt-16 pt-4">
    <div className="text-sm text-blue-2 font-semibold text-center container mx-auto w-1/4">
      <div className="">
        See what companies ranging from small businesses to large enterprises have to say
        {' '}
        <br />
        {' '}
        about panelist.
      </div>
    </div>
    <div className="mt-4">
      <img className="w-full" src={testimonialsbanner} alt="" />
    </div>

    <div className="w-10/12 mx-auto">
      <div className="flex mt-16 justify-center">
        {testimonials.map((testimonial, i) => (
          <div
            className="flex flex-col bg-gray-1 mb-4 rounded-lg shadow-xs4pl-6 pr-1 py-3 w-350 ml-6"
            key={`testimonial-${i}`}
          >
            <div className="">
              <div className="text-sm4 text-gray-1 font-extralight min-h-80 lg:h-80 ">
                {testimonial.text}
              </div>
              <div className="flex flex-row mt-4">
                <div>
                  <img src={testimonial.avatar} alt="" />
                </div>
                <div className="flex flex-col ml-2">
                  <div className="text-navlink text-blue-3 font-semibold">{testimonial.name}</div>
                  <div className="text-sm4 text-gray-5a font-extralight">
                    {testimonial.position}
                  </div>
                  <div className="text-sm4 text-gray-5a font-extralight">
                    {testimonial.companyName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Testimonials;
