// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

// assets
import { AvatarShape } from 'src/mui/assets/illustrations';
import companyDefault from '../../../assets/images/company-default.svg';
// components
import Image from 'src/mui/components/image';
import { Stack } from '@mui/material';
import { getBrandingColors } from '../event/live/liveEventStyling';
import { AvatarDefaultSmallSvgIcon } from 'src/shared-components/V2/IconSvg/AvatarDefaultSmallSvgIcon/AvatarDefaultSmallSvgIcon';

// ----------------------------------------------------------------------

type Props = {
  user: any;
  jobTitle: string;
  location: string;
  item?: any;
};

export default function UserCard({ user, jobTitle, location, item }: Props) {
  const theme = useTheme();
  const colors = getBrandingColors();
  const { name, coverUrl, role, avatarUrl } = user;
  return (
    <Stack sx={{ textAlign: 'center', width: 1, }}>
      <Box sx={{ position: 'relative' }}>
        {avatarUrl ? (
          <Avatar
              src={avatarUrl}
              sx={{
                width: "136px",
                height: "136px",
                borderRadius: "50px",
                zIndex: 11,
            left: 0,
            right: 0,
            bottom: -68,
            mx: 'auto',
            position: 'absolute',
            border: '2px solid #DDE6E9',
                "&.MuiAvatar-root, & .MuiAvatar-img": {
                  minHeight: "136px",
                  maxHeight: "136px",
                  minWidth: "136px",
                  backgroundColor: '#C4CDD5',
                  color: '#ffffff'
                },
              }}
              // alt={name}
            />
        ) : (
                <Stack sx={{
                    width: "136px",
                    height: "136px",
                    borderRadius: "50px",
                    backgroundColor: 'transparent !important',
                    maxWidth: "136px",
                    maxHeight: "136px",
                    border: `2px solid ${colors?.Text || '#000000'}`,
                    zIndex: 11,
                    left: 0,
                    right: 0,
                    bottom: -68,
                    mx: 'auto',
                    position: 'absolute',
                  }}
                  >
                    <AvatarDefaultSmallSvgIcon fillColor={colors?.Text} style={{ width: "136px", height: "136px" }} />
                  </Stack>
        )}
            

        <Image
          src={coverUrl}
          alt={coverUrl}
          ratio="16/9"
          // overlay={alpha(theme.palette.grey[900], 0.48)}
          sx={{ maxHeight: '126px', borderRadius: '10px', width: 'calc(100% - 1px)' }}
        />
      </Box>
      <Stack sx={{ px: '0px' }}>
        <ListItemText
          sx={{ mt: '71px', mb: 1, "& .MuiTypography-root": {fontSize: '13px', lineHeight: '19px', color: colors?.Text || '#ffffff', fontFamily: 'Poppins-600', fontWeight: 600}  }}
          primary={name}
          secondary={role}
          primaryTypographyProps={{ typography: 'subtitle1' }}
          secondaryTypographyProps={{ component: 'span', mt: 0 }}
        />

        <Divider sx={{ borderStyle: 'solid', borderColor: colors.Secondary || '#7283A4', opacity: '20%' }} />

        <Box sx={{ py: 1, textAlign: 'left', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'row', display: 'flex' }}>
          <Stack sx={{ maxWidth: '200px' }}>
            <div>
              <Typography variant="subtitle2" component="div" sx={{ mb: 0.5, color: colors?.Text || '#ffffff', fontSize: '12px', lineHeight: '17px', fontFamily: 'Poppins-400', fontWeight: 400 }}>
                {jobTitle}
              </Typography>
            </div>

            <div>
              <Typography variant="caption" component="div" sx={{ mb: 0, color: colors?.Text || '#ffffff', fontSize: '11px', lineHeight: '15px', fontFamily: 'Poppins-400' }}>
                {location}
              </Typography>
            </div>
          </Stack>
          {item?.user?.company && (
            <Stack sx={{ alignItems: 'center', flexDirection: 'row' }}>

              <Avatar
                alt={item?.user?.company?.logo}
                src={item?.user?.company?.logo ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.user?.company?.logo}` : companyDefault}
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: '2px',
                  backgroundColor: item?.user?.company?.logo ? '#ffffff' : '#edf2f5',
                  '& .MuiAvatar-img': {
                    objectFit: 'contain'
                  }
                }}
              />
              <Typography className='ellipsis-one-line' variant="caption" component="div" sx={{ mb: 0, color: colors?.Text || '#ffffff', fontSize: '13px', lineHeight: '16px', fontFamily: 'Poppins-600', fontWeight: 600, maxWidth: '100px', ml: '4px' }}>
                {item?.user?.company?.name}
              </Typography>
            </Stack>
          )}
        </Box>
        <Divider sx={{ borderStyle: 'solid', borderColor: colors.Secondary || '#7283A4', opacity: '20%' }} />
      </Stack>
    </Stack>
  );
}
