import { ScheduledMeetingsButton } from "../pages/audience/Types";

export function applyFilter({
  list,
  query,
  type,
  selectedTab,
}: {
  list: any;
  query?: string;
  type: string;
  selectedTab?: string;
}) {
  if (query) {
    switch (type) {
      case "attendees":
        return list?.filter(
          (item: any) =>
            `${item["First name"]} ${item["Last name"]}`
              .toLowerCase()
              .includes(query?.toLowerCase()) ||
            `${item.Organisation}`
              .toLowerCase()
              .includes(query?.toLowerCase()) ||
            `${item["Job title"]}`
              .toLowerCase()
              .includes(query?.toLowerCase()) ||
            `${item["Email"]}`.toLowerCase().includes(query?.toLowerCase())
        );
      case "meetings": {
        const filteredList =
          selectedTab === ScheduledMeetingsButton.PendingMeetings
            ? list?.filter(
                (item: any) =>
                  `${item?.attendeeUser["First name"]} ${item.attendeeUser["Last name"]}`
                    .toLowerCase()
                    .includes(query?.toLowerCase()) ||
                  `${item?.attendeeUser?.Organisation}`
                    .toLowerCase()
                    .includes(query?.toLowerCase()) ||
                  `${item?.attendeeUser["Job title"]}`
                    .toLowerCase()
                    .includes(query?.toLowerCase()) ||
                  `${item?.attendeeUser["Email"]}`
                    .toLowerCase()
                    .includes(query?.toLowerCase()) ||
                  `${item?.user["First name"]} ${item.user["Last name"]}`
                    .toLowerCase()
                    .includes(query?.toLowerCase()) ||
                  `${item?.user?.Organisation}`
                    .toLowerCase()
                    .includes(query?.toLowerCase()) ||
                  `${item?.user["Job title"]}`
                    .toLowerCase()
                    .includes(query?.toLowerCase()) ||
                  `${item?.user["Email"]}`
                    .toLowerCase()
                    .includes(query?.toLowerCase())
              )
            : list?.filter(
                (item: any) =>
                  `${item?.attendeeUser["First name"]} ${item.attendeeUser["Last name"]}`
                    .toLowerCase()
                    .includes(query?.toLowerCase()) ||
                  `${item?.attendeeUser?.Organisation}`
                    .toLowerCase()
                    .includes(query?.toLowerCase()) ||
                  `${item?.attendeeUser["Job title"]}`
                    .toLowerCase()
                    .includes(query?.toLowerCase()) ||
                  `${item?.attendeeUser["Email"]}`
                    .toLowerCase()
                    .includes(query?.toLowerCase())
              );
        return filteredList;
      }
      case "speakers":
        return list?.filter(
          (item: any) =>
            `${item.firstName} ${item.lastName}`
              .toLowerCase()
              .includes(query?.toLowerCase()) ||
            `${item.jobTitle}`.toLowerCase().includes(query?.toLowerCase()) ||
            `${item?.company?.name}`
              .toLowerCase()
              .includes(query?.toLowerCase()) ||
            `${item?.email}`.toLowerCase().includes(query?.toLowerCase())
        );
      case "sponsors": {
        const companyResult = list?.filter((item: any) =>
          `${item.company?.name}`.toLowerCase().includes(query?.toLowerCase())
        );

        return companyResult.length
          ? companyResult
          : list
              ?.filter((item: any) => {
                const userResult = item.users.filter(
                  (user: any) =>
                    `${user.firstName} ${user.lastName}`
                      .toLowerCase()
                      .includes(query?.toLowerCase()) ||
                    `${user.jobTitle}`
                      .toLowerCase()
                      .includes(query?.toLowerCase()) ||
                    `${user?.company?.name}`
                      .toLowerCase()
                      .includes(query?.toLowerCase()) ||
                    `${user?.email}`
                      .toLowerCase()
                      .includes(query?.toLowerCase())
                );
                return userResult.length
                  ? { ...item, users: userResult }
                  : null;
              })
              .filter((v: any) => v);
      }
      case "leads":
        return list?.filter(
          (item: any) =>
            `${item["leadFirst name"]} ${item["leadLast name"]}`
              .toLowerCase()
              .includes(query?.toLowerCase()) ||
            `${item.leadOrganisation}`
              .toLowerCase()
              .includes(query?.toLowerCase()) ||
            `${item["leadJob title"]}`
              .toLowerCase()
              .includes(query?.toLowerCase()) ||
            `${item["leadEmail"]}`.toLowerCase().includes(query?.toLowerCase())
        );
      default:
        return list;
    }
  }
  return list;
}

export function applySort({
  list,
  type,
  sortByKey,
  selectedTab,
}: {
  list: any;
  type: string;
  sortByKey?: string | null;
  selectedTab?: string;
}) {
  const typeObject = (obj: any, name: string) => {
    if (!obj) return "";
    switch (type) {
      case "attendees": {
        if (name == "name") return obj["First name"];
        if (name == "jobTitle") return obj["Job title"];
        if (name == "company") return obj["Organisation"];
        return "";
      }
      case "speakers": {
        if (name == "name") return obj["firstName"];
        if (name == "jobTitle") return obj["jobTitle"];
        if (name == "company") return obj["company"]["name"];
        return "";
      }
      case "meetings": {
        if (name == "name") return obj?.attendeeUser["First name"];
        if (name == "jobTitle") return obj?.attendeeUser["Job title"];
        if (name == "company") return obj?.attendeeUser?.Organisation;
        return "";
      }
      // case "sponsors": {
      //   if (name == "name") return obj["First name"];
      //   if (name == "jobTitle") return obj["Job title"];
      //   if (name == "company") return obj["Organisation"];
      //   return "";
      // }
      case "leads": {
        if (name == "name") return obj["leadFirst name"];
        if (name == "jobTitle") return obj["leadJob title"];
        if (name == "company") return obj["leadOrganisation"];
        return "";
      }

      default:
        return "";
    }
  };
  if (sortByKey) {
    switch (sortByKey) {
      case "First name,ASC":
        return list && [...list]?.sort(
          (a: any, b: any) => typeObject(a, "name")?.localeCompare(typeObject(b, "name"))
        );
      case "First name,DESC":
        return  list && [...list]?.sort(
          (a: any, b: any) => typeObject(b, "name")?.localeCompare(typeObject(a, "name"))
        );
      case "Job title,ASC":
        return  list && [...list]?.sort(
          (a: any, b: any) =>
            typeObject(a, "jobTitle")?.localeCompare(typeObject(b, "jobTitle"))
        );
      case "Job title,DESC":
        return  list && [...list]?.sort(
          (a: any, b: any) =>
            typeObject(b, "jobTitle")?.localeCompare(typeObject(a, "jobTitle"))
        );
      case "Organisation,ASC":
        return  list && [...list]?.sort(
          (a: any, b: any) =>
            typeObject(a, "company")?.localeCompare(typeObject(b, "company"))
        );
      case "Organisation,DESC":
        return  list && [...list]?.sort(
          (a: any, b: any) =>
            typeObject(b, "company")?.localeCompare(typeObject(a, "company"))
        );
      default:
        break;
    }
  }
  return list;
}
