import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { HyperLink } from '../../shared-components';
import { Button } from '../../shared-components/V2';
import { Navbar, Footer } from '../../components';
import logo from '../../assets/images/login/panelisticon.svg';
import ScooterImage from '../../assets/images/homepage/scooter-image.svg';
import { UserService } from '../../services';

// eslint-disable-next-line no-unused-vars
export const OneTimeLink = (props: any) => {
  const queryParams = new URLSearchParams(window.location.search);

  const [email] = useState(queryParams.get('email'));
  const [hiddenEmail, setHiddenEmail] = useState('');

  useEffect(() => {
    const changeEmailFormat = () => {
      const firstLetterOfEmail = email?.substring(0, 1);
      const domainOfEmail = email?.substring(email.lastIndexOf('@'));
      setHiddenEmail(`${firstLetterOfEmail}*****${domainOfEmail}`);
    };
    changeEmailFormat();
  }, [email]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const userService: UserService = new UserService();
    let data: any;
    // eslint-disable-next-line prefer-const
    data = {};
    data.email = {};
    data.email = email;

    try {
      await userService.oneTimeLink(data);
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="bg-gray-1 flex text-center h-[95vh] xs:h-[89vh] w-full overflow-x-auto m-h-80">
        <div className="mx-auto">
          <div className="">
            <div className="w-full ">
              <div className="mt-28">
                <div className="flex items-start justify-start">
                  <Link to="/">
                    <img src={logo} alt="Panelist" />
                  </Link>
                </div>
                <div className="flex flex-col font-semibold mt-4 text-gray-1 text-sm1 text-left tracking-wide">
                  We’ve just emailed a one-time link to
                  {' '}
                  <br />
                  {hiddenEmail}
                  <div className="flex flex-col font-extralight mt-3 text-sm1 text-gray-1 text-center">
                    Click on the link to sign in instantly
                    {' '}
                    <br />
                    {' '}
                    to your Panelist account.
                  </div>
                </div>
                <div>
                  <div className="mt-9">
                    <img src={ScooterImage} alt="" className="mx-auto" />
                  </div>
                </div>
                <div />
                <div>
                  <form onSubmit={e => handleSubmit(e)}>
                    <div className="">
                      <div className="flex justify-end mt-9">
                        <Link
                          to="/loginorjoin"
                          className="text-sm1 text-blue-2 font-semibold text-blue h-30 mr-5 pt-1 hover:underline"
                        >
                          Back
                        </Link>
                        <Button
                          type="submit"
                          text="Resend"
                          size="x-small"
                          className="w-110 bg-blue text-white text-sm font-semibold hover:bg-blue-3"
                        />
                      </div>
                    </div>
                  </form>
                  <div className="flex justify-center mt-5 text-sm1 text-blue-3">
                    New member? &nbsp;
                    <HyperLink
                      className="font-medium"
                      variant="Signup"
                      text="Sign Up"
                      textColor="#285cb2"
                      href="/join"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OneTimeLink;
