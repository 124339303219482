import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { getVerticalRooms, groupAndSortRoomsByDays } from 'src/utils/rooms';
import WebsiteEventTabs from './website-event-day-tabs';
import { BreakoutAgendaTabs, BreakoutAgendaBodyLobby } from './breakout-agenda';

import { makeRoomsTreeLobby } from '../../../utils/rooms-lobby';
import { IRoom } from '../../../models/event/room';
import { IEvent } from '../../../models/user-events';
import { AgendaSliderSvgIcon } from '../../../shared-components/V2/IconSvg/AgendaSliderSvgIcon/AgendaSliderSvgIcon';

interface IEventAgendaProps {
  eventList?: IEvent;
  isLobbbyPage?: boolean;
}
const WebsiteEventAgendaLobby = (props: IEventAgendaProps) => {
  const ref = useRef(null);
  const [activeDay, setActiveDay] = useState<string | null>(null);
  const roomTree = makeRoomsTreeLobby(props?.eventList?.rooms);
  const [selectedBreakoutRoom, setSelectedBreakoutRoom] = useState('');

  const sortedGroupedRooms = groupAndSortRoomsByDays(props?.eventList?.rooms || []);
  const sortedDays = Object.keys(sortedGroupedRooms || {});
  const getSelectedDayRooms = activeDay && sortedGroupedRooms?.[activeDay];
  const groupVerticalRooms = getVerticalRooms(getSelectedDayRooms || []) || {};

  const [scrollRight, setScrollRight] = useState(true);
  const [scrollLeft, setScrollLeft] = useState(false);

  const scroll = (
    element: HTMLDivElement,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      // eslint-disable-next-line no-param-reassign
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) clearInterval(slideTimer);
    }, speed);
  };

  const onScrollRight = (ref: any) => {
    scroll(ref.current!, 50, 890, 80);
    setScrollLeft(!scrollLeft);
    setScrollRight(!scrollRight);
  };

  const onScrollLeft = (ref: any) => {
    scroll(ref.current!, 50, 890, -80);
    setScrollRight(!scrollRight);
    setScrollLeft(!scrollLeft);
  };

  const onClickTab = (data: any) => {
    setActiveDay(data);
  };

  useEffect(() => {
    if (sortedDays.length > 0 && !activeDay) {
      setActiveDay(sortedDays[0]);
    }
  }, [sortedDays, activeDay]);

  return (
    <div
      id="agenda"
      className={classNames('w-full mb-[55px]', {
        hidden: !(roomTree && roomTree.length > 0),
      })}
    >
      <div className="block md:flex mx-auto">
        <div className="w-full">
          <div className="mx-[6px] mt-[12px]">
            <div>
              <div className="flex items-start md:flex-row flex-col gap-[5px] border-b-1 border-[#CDDCF2] pb-[1px] scroll overscroll-contain">
                {sortedDays?.map((treeNode: any, index: any) => (
                  <WebsiteEventTabs
                    tabName={`Day ${index + 1}`}
                    date={treeNode}
                    onClick={onClickTab}
                    selectedTab={treeNode}
                    isSelectTab={treeNode === activeDay}
                    isLobbyDayTabs="!rounded-t-5 !rounded-b-none justify-center !pl-0"
                  />
                ))}
              </div>
              {/* {selectedTabRooms?.roomsList && selectedTabRooms?.roomsList?.length > 0 && ( */}
              {true && (
                <div className="relative">
                  {Object.entries(groupVerticalRooms)?.map(([roomKey, multipleRooms]: [string, any]) => (
                    <>
                      <div
                        className="relative flex flex-row bg-[#EDF2F5] rounded-5 p-1 mt-1 mb-[18px] max-w-[604px] w-full"
                        key={roomKey}
                      >
                        {/* TODO: Left and Right scroll button */}
                        <div
                          id="scrollLeft"
                          onClick={() => onScrollLeft(ref)}
                          className={classNames(
                            'absolute left-[0px] top-[0.8px] rounded-full shadow-md rotate-180 cursor-pointer z-50',
                            {
                              hidden: !scrollLeft || multipleRooms?.length <= 3,
                            }
                          )}
                        >
                          <AgendaSliderSvgIcon arrowColor="#203C6E" fillColor="#EDF2F5" />
                        </div>
                        <div
                          id="scrollRight"
                          onClick={() => onScrollRight(ref)}
                          className={classNames(
                            'absolute -right-[0px] top-[0.8px] rounded-full shadow-md cursor-pointer z-50 ',
                            {
                              hidden: !scrollRight || multipleRooms?.length <= 3,
                            }
                          )}
                        >
                          <AgendaSliderSvgIcon arrowColor="#203C6E" fillColor="#EDF2F5" />
                        </div>
                        <div
                          ref={ref}
                          className="relative flex flex-row overflow-auto no-scrollbar bg-[#EDF2F5] rounded-5 max-w-[604px] w-full"
                        >
                          {
                                multipleRooms?.map(
                                  (room: IRoom, idx: any) => (
                                    <BreakoutAgendaTabs
                                      key={`${idx}-${room.id}`}
                                      room={room}
                                      roomType={room?.name}
                                      tabsQty={multipleRooms?.length}
                                      isSliderTabs
                                      selectedTab={
                                        (selectedBreakoutRoom === `${idx}-${room.id}-${room.verticalOrdering}`)
                                        || (idx === 0
                                          && (selectedBreakoutRoom === `0-${room.id}-${room.verticalOrdering}`
                                            || !selectedBreakoutRoom
                                            || !selectedBreakoutRoom.endsWith(`${room.verticalOrdering}`)))
                                      }
                                      // backgroundColor={event?.colorStyle?.values?.backgroundButton}
                                      onClick={() => {
                                        setSelectedBreakoutRoom(`${idx}-${room.id}-${room.verticalOrdering}`);
                                      }}
                                      isLobbyRooms
                                    />
                                  )
                                )
                              }
                        </div>
                      </div>
                      <div className=" bg-[#CDDCF2] h-[1px] w-full absolute right-0 top-[42.8px]" />
                      {multipleRooms?.map(
                        // eslint-disable-next-line no-nested-ternary
                        (room: IRoom, idx: any) => (selectedBreakoutRoom === `${idx}-${room.id}-${room.verticalOrdering}` ? (
                          <div className=" rounded-10 bg-[#EDF2F5] mt-[8px] pr-[17px] pt-[15px] pb-[17px]">
                            <BreakoutAgendaBodyLobby key={room.id} room={room} />
                          </div>
                        ) : (selectedBreakoutRoom === `${idx}-${room.id}-${room.verticalOrdering}`)
                                || (idx === 0 && (selectedBreakoutRoom === `0-${room.id}-${room.verticalOrdering}`
                                  || !selectedBreakoutRoom
                                  || !selectedBreakoutRoom.endsWith(`${room.verticalOrdering}`))) ? (
                                    <div className=" rounded-10 bg-[#EDF2F5] mt-[8px] pr-[17px] pt-[15px] pb-[17px]">
                                      <BreakoutAgendaBodyLobby isLobbbyPage={props.isLobbbyPage} key={idx} room={room} />
                                    </div>
                          ) : null)
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventAgendaLobby;
