import { useState } from "react";

interface ISpotLightSvgIcon {
  isActive?: boolean;
  className?: string;
  disableHover?: boolean;
}
export const SpotLightSvgIcon = ({ isActive, className, disableHover }: ISpotLightSvgIcon) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const colorBlue = "#0049EF";
  const defaultColor = "#7283A4";
  const fillColor = !disableHover && (isHover || isActive) ? colorBlue : defaultColor;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.969"
      height="29.969"
      viewBox="0 0 29.969 29.969"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={className}
    >
      <path
        id="形状"
        d="M27.523,2.806l.408.051a5.907,5.907,0,0,1,3.316,1.159,4.916,4.916,0,0,1,1.069,2.7l.09.617h0a18.727,18.727,0,0,1-.09,5.486,19.39,19.39,0,0,1-8.5,13.04,7.538,7.538,0,0,0-.025.909l.015.456a6.524,6.524,0,0,1-.143,2.078,4.216,4.216,0,0,1-2.4,2.253l-.5.234h0l-.64.283c-1.2.508-2.815,1.03-3.829.016A4.794,4.794,0,0,1,15.236,29.7l-.075-.331a12.087,12.087,0,0,0-.321-1.239c-.08-.233-.165-.471-.258-.712a4.808,4.808,0,0,1-.326.36A6.211,6.211,0,0,1,12.2,28.989a22.646,22.646,0,0,1-2.356.761l-.4.106h0l-.764.19h0l-.692.159h0l-.828.176h0l-.517.1h0A1.615,1.615,0,0,1,4.761,28.6l.137-.69h0l.246-1.117h0l.2-.815h0l.151-.576h0a22.614,22.614,0,0,1,.761-2.356,6.21,6.21,0,0,1,1.208-2.056l.129-.124h0l-.1-.041c-.259-.1-.54-.194-.831-.285L6.214,20.4a7.3,7.3,0,0,1-3.038-1.437c-.895-.895-.594-2.259-.161-3.391l.177-.439h0l.283-.64h0l.234-.5a4.216,4.216,0,0,1,2.253-2.4,5.64,5.64,0,0,1,1.729-.154l.352.012a10.2,10.2,0,0,0,1.362-.011,19.389,19.389,0,0,1,13.04-8.5,18.893,18.893,0,0,1,5.078-.141ZM11.813,23.1a1.6,1.6,0,0,0-1.761-.1l-.176.12-.149.131-.2.254a6.718,6.718,0,0,0-.838,2.212l-.172.749c-.027.118-.055.233-.082.341l.306-.074h0l.669-.153a6.009,6.009,0,0,0,2.583-1.066,1.6,1.6,0,0,0,.141-2.1L12,23.263l-.038-.037Zm11.5-11.167a3.2,3.2,0,1,0,0,4.529A3.2,3.2,0,0,0,23.313,11.931Z"
        transform="translate(-2.597 -2.697)"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};
