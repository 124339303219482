import { EventsNavbar, Navbar } from '../../../components/navbar';
import Footer from '../../home/footer';
import GetStarted from '../overview/get-started';
import CheckInManagement from './check-in-mgmt';
import DiscoverHybrid from './discover-hybrid';
import FlexibleApproach from './flexible-approach';
import HybridJourney from './hybrid-journey';
import MobileApp from './mobile-app';

const menuItems = [
  {
    menuItemText: 'Overview',
    href: '/overview',
    isSelected: false
  },
  {
    menuItemText: 'Hybrid Events',
    href: '/hybrid-events',
    isSelected: true
  },
  {
    menuItemText: 'Virtual Events',
    href: '/virtual-events',
    isSelected: false
  },
  {
    menuItemText: 'Features & Packages',
    href: '/features-and-packages',
    isSelected: false
  }
];

export const HybridEvents = () => (
  <>
    <Navbar />
    <EventsNavbar menuItems={menuItems} showCreateEventButton />
    <div className="mx-auto">
      <div className="w-full mx-auto">
        <div className="flex flex-col">
          <DiscoverHybrid />
          <HybridJourney />
          <FlexibleApproach />
          {/* <MeaningfulConversations /> */}
          <CheckInManagement />
          <MobileApp />
          <GetStarted />
          <Footer />
        </div>
      </div>
    </div>
  </>
);

export default HybridEvents;
