import { useEffect } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import SecondLayout from '../../layouts/second-layout';
import { Button } from '../../shared-components/V2';
import LoungeCard from '../../shared-components/V2/LoungeCard/lounge-card';
import { IAppState } from '../../store';
import { getPublicLounges } from '../../store/public-lounge';

const Lounge = () => {
  const dispatch = useDispatch();
  const { publicLounges } = useSelector((state: IAppState) => state);

  useEffect(() => {
    dispatch(getPublicLounges());
  }, [dispatch]);

  return (
    <SecondLayout
      leftPanel={(
        <div className="w-max">
          <Button
            className="bg-blue-1 text-white font-light"
            onClick={() => {}}
            size="large"
            text="+ Start a table"
          />
        </div>
      )}
    >
      <div className="flex flex-col my-4 bg-white rounded-lg w-full shadow-md h-auto">
        <div className="flex flex-col">
          <div className="mt-4 ml-5">
            <div className="text-md3 text-blue-4">Lounge</div>
          </div>
          <div className="pt-4 mx-4 pl-1.5 flex flex-row flex-wrap w-[970px] pb-4">
            {publicLounges.value?.length > 0
              && publicLounges.value.map((lounge, index) => (
                <div className="mr-2 mb-2" key={`lounge-${index}`}>
                  <LoungeCard
                    title={lounge.name}
                    description={lounge.description}
                    moderators={lounge.publicLoungeModerators || []}
                    attendees={lounge.publicLoungeConnections || []}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </SecondLayout>
  );
};

export default Lounge;
