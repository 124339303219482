import { EventsNavbar, Navbar } from '../../../components/navbar';
import { Footer } from '../../home/footer';
import AttendeesAndSponsors from './attendees-and-sponsors';
import { Banner } from './banner';
import BreakoutSessions from './breakout-sessions';
import CreateAndPromote from './create-and-promote';
import CreateEventPlatform from './create-event-platform';
import MakeMostOfTheirTime from './make-most-of-their-time';
import MoreThanAWebinar from './more-than-a-webinar';
import PrerecordOrLive from './prerecord-or-live';
import ZeroTrainingRequired from './zero-training-required';

const menuItems = [
  {
    menuItemText: 'Overview',
    href: '/overview',
    isSelected: false
  },
  {
    menuItemText: 'Hybrid Events',
    href: '/hybrid-events',
    isSelected: false
  },
  {
    menuItemText: 'Virtual Events',
    href: '/virtual-events',
    isSelected: true
  },
  {
    menuItemText: 'Features & Packages',
    href: '/features-and-packages',
    isSelected: false
  }
];

export const VirtualEvents = () => (
  <>
    <Navbar />
    <EventsNavbar menuItems={menuItems} showCreateEventButton />
    <div className="mx-auto">
      <div className="">
        <div className="flex flex-col">
          <Banner />
          <CreateAndPromote />
          <CreateEventPlatform />
          <ZeroTrainingRequired />
          <MoreThanAWebinar />
          <PrerecordOrLive />
          <BreakoutSessions />
          <MakeMostOfTheirTime />
          <AttendeesAndSponsors />
          <Footer />
        </div>
      </div>
    </div>
  </>
);

export default VirtualEvents;
