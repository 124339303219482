import {
  ActionCreator,
  Dispatch,
} from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IConnectionsResult } from '../../../models/connection';
import { ConnectionsState } from '../../../models/ConnectionsState';
import { UserService } from '../../../services';
import { OwnConnectionsActionTypes } from './types';

export interface IOwnConnectionsLoadingAction {
  type: OwnConnectionsActionTypes.OWN_CONNECTIONS_LOADING;
  loading: boolean;
}

export interface IOwnConnectionsSuccessAction {
  type: OwnConnectionsActionTypes.OWN_CONNECTIONS_SUCCESS;
  payload: IConnectionsResult;
}

export interface IOwnConnectionsErrorAction {
  type: OwnConnectionsActionTypes.OWN_CONNECTIONS_ERROR;
  errorMessage: string;
}

export interface IOwnConnectionsCleanupAction {
  type: OwnConnectionsActionTypes.OWN_CONNECTIONS_CLEANUP;
}

export type OwnConnectionsActions =
  | IOwnConnectionsLoadingAction
  | IOwnConnectionsSuccessAction
  | IOwnConnectionsErrorAction
  | IOwnConnectionsCleanupAction;

export const getOwnConnections: ActionCreator<
  ThunkAction<
    Promise<any>,
    ConnectionsState,
    null,
    IOwnConnectionsSuccessAction
  >
> = (userIdOrSlug: string = '', page: number = 1, limit: number = 100) => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();

    let result: IConnectionsResult | null = null;
    try {
      if (userIdOrSlug.trim() !== '')
        
        result = await userService.getUserConnections(userIdOrSlug, page, limit);
      else result = await userService.getOwnConnections();

      dispatch({
        type: OwnConnectionsActionTypes.OWN_CONNECTIONS_SUCCESS,
        payload: result,
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: OwnConnectionsActionTypes.OWN_CONNECTIONS_ERROR,
        errorMessage: error.message,
      });
    } finally {
      dispatch({
        type: OwnConnectionsActionTypes.OWN_CONNECTIONS_LOADING,
        loading: false,
      });
    }
  };
};

export const loadOwnConnections: ActionCreator<
  ThunkAction<any, ConnectionsState, null, IOwnConnectionsLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: OwnConnectionsActionTypes.OWN_CONNECTIONS_LOADING,
    loading: shouldLoad,
  });

  export const cleanupOwnConnections: ActionCreator<
  ThunkAction<any, ConnectionsState, null, IOwnConnectionsLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: OwnConnectionsActionTypes.OWN_CONNECTIONS_CLEANUP
  });
