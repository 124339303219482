import { HyperLink } from '../../shared-components';
import logo from '../../assets/images/homepage/logo-panelist.svg';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

interface IPageHeader {
  isBlueTheme?: boolean,
}

export const ProfileNavbar = ({ isBlueTheme }: IPageHeader) => {
  return (
    <nav
      className={classNames("bg-white z-40 relative", {
        '!bg-black': isBlueTheme,
      })}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 w-10/12">
        <div className="container mx-auto w-full max-w-[1550px] justify-between">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="/">
                <img src={logo} alt="Panelist" />
              </Link>
            </div>
          </div>
          <div className="flex">
            <HyperLink variant="NavLink" text="Log in" href="/login" className="sans" />
          </div>
        </div>
      </div>
    </nav>
 )
};

export default ProfileNavbar;
