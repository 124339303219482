/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable no-console */

import { useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import {
  Autoplay, EffectFade, Navigation, Pagination
} from 'swiper'; // Import Swiper modules

import { ReactComponent as ArrowLeftIcon } from 'src/assets/images/arrowLeft.svg'; // Adjust path as needed
import { ReactComponent as ArrowRightIcon } from 'src/assets/images/arrowRight.svg'; // Adjust path as needed
import { RegisterSectionProps } from './types';
import InsightCard from './images/factor-card-1.png';
import OptimizeCard from './images/factor-card-2.png';
import CollaborateCard from './images/factor-card-3.png';

const CustomPrevButton = () => (
  <div className="custom-prev-button-conference absolute md:top-[50%] top-[40%] -mt-[17px] z-10 md:-left-[17px] -left-[10px] xl:-left-[58px] cursor-pointer">
    <ArrowLeftIcon />
  </div>
);

const CustomNextButton = () => (
  <div className="custom-next-button-conference absolute md:top-[50%] top-[40%] -mt-[17px] z-10 md:-right-[17px] -right-[10px] xl:-right-[58px] cursor-pointer">
    <ArrowRightIcon />
  </div>
);

export const ConferencesSection = ({ onRegister }: RegisterSectionProps) => {
  const [email, setEmail] = useState<string>('');
  const [inerWidth, setInnerWidth] = useState(window.innerWidth);
  const swiperRefConfrence = useRef<any>(null);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const handleRegistration = () => {
    onRegister(email);
  };
  const factorArray = [
    { image: InsightCard, title: 'Regional insight you can trust', desc: 'Factor Day events are carefully planned with 6 months of research, ensuring they consistently provide deep, reliable insights tailored to address your region’s unique needs and challenges.' },
    { image: OptimizeCard, title: 'Share and collaborate', desc: 'Engage and collaborate with peers and other industry leaders, exchange plans under Chatham House rules for open and confidential discussions and use Factor Apps to keep in touch post event.' },
    { image: CollaborateCard, title: 'Optimise your planning', desc: 'Benchmark, validate and Improve your planning using our regional insights and peer feedback to enhance strategic decision-making and achieve optimal results.' },
  ];
  const handleMouseEnter = () => {
    swiperRefConfrence?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefConfrence?.current?.swiper?.autoplay?.start();
  };

  const slidesPreviewCount = inerWidth <= 768 ? 'auto' : 1;
  const slidesGap = inerWidth <= 768 ? 10 : 55;
  const slideMaxWidth = inerWidth <= 768 ? 'max-w-[283px]' : 'max-w-full';
  const isOnMobile = inerWidth <= 768;
  return (
    <div className="flex flex-col lg:mt-[113px] mt-[0px]">
      <div className="flex flex-col items-start gap-y-[15px] lg:flex-row justify-between  lg:items-center">
        <div className="text-white md:leading-[42px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium text-left">
          Why Factor Conferences?
        </div>

        <div className="flex flex-col w-full md:w-auto gap-y-[15px] md:flex-row gap-[8px]">
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your business email"
            className="rounded-[3px] text-[15px] leading-[23px] bg-[#14161A] text-white md:w-[370px] w-full h-[35px] tracking-wide pl-[14px] font-medium helvetica-neue-55-roman"
          />

          <button
            type="submit"
            onClick={() => handleRegistration()}
            className="rounded-[3px] inline-block text-[15px] bg-[#E73B4C] hover:bg-[#E73B4C] text-black sm:w-[258px] w-full h-[35px] tracking-wide font-medium helvetica-neue-55-roman"
          >
            Contact our team
          </button>
        </div>
      </div>
      <div className="mt-[15px] text-white md:leading-[23px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman">
        Your industry, profession, and role are continually evolving. To stay ahead, you need to uncover new trends and address challenges head-on. Factor’s Day events offers actionable insights, cutting-edge technologies, and valuable connections to help you navigate disruptions, leverage AI, and lead your organization with confidence.
      </div>
      {isOnMobile && factorArray.length > 1 ? (
        <div className="w-full mt-[18px] relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Swiper
            modules={[Pagination, Autoplay, EffectFade, Navigation]}
            navigation={{ prevEl: '.custom-prev-button-conference', nextEl: '.custom-next-button-conference' }}
            pagination={{ dynamicBullets: false, clickable: true }}
            ref={swiperRefConfrence}
            autoplay={{ delay: 5000, disableOnInteraction: true }}
            className="factorSwiper inspirationSlider"
            spaceBetween={slidesGap}
            slidesPerView={slidesPreviewCount}
            effect="coverflow"
            grabCursor={isOnMobile}
            centeredSlides={isOnMobile}
          >
            {factorArray?.map((card:any, item:any) => (
              <SwiperSlide className={slideMaxWidth} key={item}>
                <div className="w-[100%] md:min-h-[428px] rounded-[10px] bg-[#14161A] p-[11px_12px_17px_11px] md:p-[23px_23px_35px_23px]">
                  <div className="!rounded-[10px] w-[100%] h-[205px] flex items-center justify-center">
                    <img className="w-[100%] h-[205px] object-cover rounded-[8px]" src={card.image} alt="cards images" />
                  </div>
                  <div className="mt-[18px] text-white leading-[25px] md:text-[23px] text-[18px] helvetica-neue-65-medium !font-semibold text-left ellipsis-one-line">
                    {card.title}
                  </div>
                  <div className="mt-[11px] md:mt-[11px] text-white md:leading-[23px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman">
                    {card.desc}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <CustomPrevButton />
          <CustomNextButton />
        </div>

      ) : (
        <div className="mt-[18px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-[11px]">
          {factorArray.map((card:any, item:any) => (
            <div key={item} className="w-[100%] md:min-h-[428px] rounded-[10px] bg-[#14161A] p-[11px_12px_17px_11px] md:p-[23px_23px_35px_23px]">
              <div className="!rounded-[10px] w-[100%] h-[205px] flex items-center justify-center">
                <img className="w-[100%] h-[205px] object-cover rounded-[8px]" src={card.image} alt="cards images" />
              </div>
              <div className="mt-[18px] text-white leading-[25px] md:text-[23px] text-[18px] helvetica-neue-65-medium !font-semibold text-left ellipsis-one-line">
                {card.title}
              </div>
              <div className="mt-[11px] md:mt-[11px] text-white md:leading-[23px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman">
                {card.desc}
              </div>
            </div>
          ))}
        </div>
      )}

    </div>
  );
};
