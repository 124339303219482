/* eslint-disable react/no-unused-prop-types */
// video-footer.tsx => Footer and controls region for the Panelist video component
// Still very much WIP

// Core Imports
import './RangeInput.css';
import { useEffect, useState } from 'react';

// Icons
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import AttendeesIcon from '../../../../assets/images/participant.svg';
import { IConnectStatus } from '../../../../models/connection';
import MicrophoneIcon from '../../../../assets/images/event-live/host-and-attendees/microphone.svg';
import CameraIcon from '../../../../assets/images/event-live/host-and-attendees/camera.svg';
import ShareScreenIcon from '../../../../assets/images/event-live/host-and-attendees/share.svg';
import ImageIcon from '../../../../assets/images/event-live/host-and-attendees/image.svg';

// Properties that can be accepted by the video footer, should be self-explanatory
type VideoFooterProps = {
  // Methods
  play(): void;
  pause(): void;
  seek(amount: number): void;
  setVolume(dur: number): void;
  setFullScreen(): void;
  setPip(): void;
  setScreenMode(mode: string): void;
  showParticipant(): void;

  // Properties
  isPlaying: boolean;
  duration: number;
  position: number;
  volume: number;
  fullScreen: boolean;
  errorURL: string;
  videoStatus: string;
  screenMode: 'small' | 'large' | 'hidden' | undefined;
  attendees?: React.ReactNode;
  speakerConnectStatuses?: IConnectStatus[];
  attendeeConnectStatuses?: IConnectStatus[];
};

// Render out the controls area
export const VideoFooter = (props: VideoFooterProps) => {
  const [oldVolume, setOldVolume] = useState(100);
  const [showAttendees, setShowAttendees] = useState<boolean>(false);

  // Effect to remember the last volume when the user unmutes
  useEffect(() => {
    if (props.volume !== 0) {
      setOldVolume(props.volume);
    }
  }, [props.volume]);

  useEffect(() => setShowAttendees(false), [props.screenMode]);

  return (
    <div className="absolute w-full bottom-0 lg:shadow-md rounded-b-lg bg-white">
      {/* Seek Bar */}

      <input
        className="range__input"
        type="range"
        min="0"
        max={props.duration}
        value={props.position}
        style={{ backgroundSize: `${(props.position * 100) / props.duration}% 100%` }}
        // eslint-disable-next-line radix
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.seek(parseInt(e.target.value))}
      />

      {/* Play and Pause Controls */}

      {props.errorURL.length ? (
        <div className="px-6 pt-6 py-3 flex justify-between items-center disabled">
          <div className="flex gap-3 items-center">
            <PlayArrowIcon sx={{ color: '#edf2f5', fontSize: '35px' }} />
          </div>

          <div className="flex gap-7">
            <div className="flex items-center">
              <VolumeOffIcon sx={{ color: '#edf2f5' }} />
            </div>

            <FeaturedVideoOutlinedIcon sx={{ color: '#edf2f5' }} />

            <CropLandscapeIcon sx={{ color: '#edf2f5' }} />
            <CropFreeIcon sx={{ color: '#edf2f5' }} />
          </div>
        </div>
      ) : (
        <div className="px-6 pt-6 py-3 flex justify-between items-center active">
          {props.videoStatus === 'sessions' ? (
            <div className="flex gap-3 items-center">
              {props.isPlaying ? (
                <PauseIcon
                  sx={{ color: '#7283a4', fontSize: '35px' }}
                  onClickCapture={() => props.pause()}
                />
              ) : (
                <PlayArrowIcon
                  sx={{ color: '#7283a4', fontSize: '35px' }}
                  onClickCapture={() => props.play()}
                />
              )}
            </div>
          ) : (
            <div style={{ height: 35 }} />
          )}

          {/* Volume Controls */}

          <div className="flex justify-between items-center w-full">
            <div className="flex gap-7 items-center">
              {props.videoStatus !== 'sessions' && (
                <>
                  <img className="w-4 cursor-pointer" src={MicrophoneIcon} alt="microphone" />
                  <img className="w-7 cursor-pointer" src={CameraIcon} alt="camera" />
                  <img className="w-7 cursor-pointer" src={ShareScreenIcon} alt="share" />
                  <img className="w-7 h-full cursor-pointer" src={ImageIcon} alt="upload" />
                </>
              )}
            </div>
            <div className="flex justify-end gap-7 ">
              <div className="flex items-center">
                {props.volume === 0 ? (
                  <VolumeOffIcon
                    sx={{ color: '#7283a4' }}
                    onClickCapture={() => props.setVolume(oldVolume)}
                  />
                ) : (
                  <VolumeUpIcon
                    sx={{ color: '#7283a4' }}
                    onClickCapture={() => props.setVolume(0)}
                  />
                )}
                <input
                  className="volume__range"
                  type="range"
                  min="0"
                  max="100"
                  value={props.volume}
                  style={{ backgroundSize: `${props.volume}% 100%` }}
                  // eslint-disable-next-line radix
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.setVolume(parseInt(e.target.value))}
                />
              </div>

              {/* Player Size Controls */}

              {/*
            <FeaturedVideoOutlinedIcon
              sx={{ color: '#fd8c26' }}
              onClickCapture={() => props.setScreenMode('small')}
            />
            <CropLandscapeIcon
              sx={{ color: '#7283a4' }}
              onClickCapture={() => props.setScreenMode('large')}
            />
            <CropFreeIcon sx={{ color: '#7283a4' }} onClickCapture={() => props.setFullScreen()} />
            {props.screenMode === "large" ?
              <img className="cursor-default fill-black" src={AttendeesIcon} alt="" onClickCapture={() => setShowAttendees(!showAttendees)} /> : <div className="bg-black" />
            }
            {showAttendees &&
              <div className="absolute border-2 drop-shadow-md bg-white w-80 max-h-96 -mt-96 overflow-y-scroll py-4 px-4">
                <div className="flex flex-row items-center mb-4">
                  <img src={AttendeesIcon} alt=""></img>
                  <div className="text-h2 text-blue-2 pl-1">Attendees</div>
                </div>
                <div className="grid grid-cols-3 gap-x-8 gap-y-2">
                  {props.attendees?.map((item, key) => (
                    <HostAttendeeItem
                      item={item}
                      connectStatus={props.connectStatuses?.find(cs => cs.id === item.id)}
                      key={key} />
                  ))}
                </div>
              </div>
              // <div className="absolute border-2 drop-shadow-md bg-white w-80 max-h-96 -mt-96 overflow-y-scroll">
              //   <EventAttendees connectStatus={props.connectStatuses} eventAttendees={props.attendees} />
              // </div>
            }
          </div>
        </div>
      )} */}

              <FeaturedVideoOutlinedIcon
                sx={{ color: props.screenMode === 'small' ? '#fd8c26' : '#7283a4' }}
                onClickCapture={() => props.setScreenMode('small')}
              />
              <CropLandscapeIcon
                sx={{ color: props.screenMode === 'large' ? '#fd8c26' : '#7283a4' }}
                onClickCapture={() => props.setScreenMode('large')}
              />
              <CropFreeIcon
                sx={{ color: '#7283a4' }}
                onClickCapture={() => props.setFullScreen()}
              />
              {props.screenMode === 'large' ? (
                <div className="relative ">
                  <img
                    className="cursor-pointer fill-black"
                    src={AttendeesIcon}
                    alt=""
                    onClickCapture={() => {
                      props.showParticipant();
                      setShowAttendees(!showAttendees);
                    }}
                  />
                  {/* <span className="bg-yellow-500 absolute bottom-3 left-6 rounded-xl text-sm px-1">
                    {props.attendees}
                  </span> */}
                </div>
              ) : (
                <div className="bg-black" />
              )}
              {showAttendees && (
              <div className="absolute border-2 drop-shadow-md bg-white md:h-[580px] h-[422px] md:overflow-y-auto overflow-y-scroll bottom-12 rounded-lg">
                {props.attendees}
              </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoFooter;
