import React, { useState, useRef, useCallback } from 'react';
import ReactPlayer from 'react-player';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { Autoplay, EffectFade, Pagination } from 'swiper';

import classNames from 'classnames';
import PlayIcon from '../../images/playicon.svg';
import PauseIcon from '../../images/pauseiconsvg.svg';

interface VideoPlayerProps {
  videos: { url: string; thumbnail: string }[];
  absolutePlayer?: boolean;
  classes?: string;
  singleVideoClass?: string;
  playIconPosition?: string;
  hideVideoControls?: boolean;
  isAutoPlay?: boolean;
  playInLoop?: boolean;
  minHeight?: string;
  isOnMobile?: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videos,
  absolutePlayer,
  classes,
  singleVideoClass,
  playIconPosition,
  hideVideoControls,
  isAutoPlay,
  playInLoop,
  minHeight,
  isOnMobile,
}) => {
  const swiperRefLocal = useRef<any>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [startVideo, setStartVideo] = useState<boolean>(false);

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };

  const handlePlayPause = useCallback(() => {
    setPlaying((prev) => !prev);
  }, []);

  const handleSlideChange = useCallback((swiper: any) => {
    setCurrentIndex(swiper.realIndex);
    setPlaying(false); // Pause the video on slide change
  }, []);

  return (
    <div
      className={`${absolutePlayer ? 'absolute' : 'relative overflow-hidden'
      } w-full md:h-full rounded-[10px]`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      // style={{
      //   minHeight: minHeight || 'h-[300px]',
      //   maxHeight: minHeight || 'h-[300px]'
      // }}
    >
      {videos.length > 1 ? (
        <Swiper
          modules={[Pagination, Autoplay, EffectFade]}
          pagination={{
            dynamicBullets: false,
            clickable: true,
          }}
          ref={swiperRefLocal}
          onSlideChange={handleSlideChange}
          autoplay={{ delay: 5000 }}
          className={`factorSwiper ${classes}`}
        >
          {videos.map((video, index) => (
            <SwiperSlide key={index}>
              <div className="relative w-full h-full">
                <ReactPlayer
                  url={video.url}
                  playing={isAutoPlay ? true : currentIndex === index && playing}
                  onPause={() => setPlaying(false)}
                  onEnded={() => setPlaying(false)}
                  onPlay={() => setPlaying(true)}
                  onStart={() => setStartVideo(true)}
                  muted={isAutoPlay}
                  controls={!hideVideoControls}
                  width="100%"
                  height="100%"
                  className="rounded-[10px] object-cover"
                  light={isAutoPlay ? false : video.thumbnail}
                />
                {startVideo && (
                  <button
                    type="button"
                    onClick={handlePlayPause}
                    className={classNames(
                      'absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-[100]',
                      {
                        [`${playIconPosition}`]: playIconPosition,
                      }
                    )}
                  >
                    {playing && currentIndex === index ? (
                      <img
                        className="min-w-[60px]"
                        src={PauseIcon}
                        alt="play icon"
                      />
                    ) : (
                      <img
                        className="min-w-[60px]"
                        src={PlayIcon}
                        alt="play icon"
                      />
                    )}
                  </button>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div
          // style={{
          //   minHeight: minHeight || '300px',
          //   maxHeight: minHeight || '300px'
          // }}
          className={`relative w-full ${isOnMobile ? 'h-[300px] lg:h-full' : 'h-full'} `}
        >
          <ReactPlayer
            url={videos?.length > 0 ? videos[0]?.url : ''}
            playing={isAutoPlay || playing}
            muted={isAutoPlay}
            onPause={() => setPlaying(false)}
            onEnded={() => setPlaying(false)}
            onPlay={() => setPlaying(true)}
            onStart={() => setStartVideo(true)}
            controls={!hideVideoControls}
            playIcon={
              <img className="min-w-[40px]" src={PlayIcon} alt="play icon" />
            }
            width="100%"
            height={minHeight || '100%'}
            className={`rounded-[10px] object-cover ${singleVideoClass}`}
            light={isAutoPlay ? false : videos[0]?.thumbnail}
            loop={playInLoop}
          />
          {startVideo && !isAutoPlay && (
            <button
              type="button"
              onClick={handlePlayPause}
              className={classNames(
                'absolute top-[50%] left-[50%] -translate-x-[20px] -translate-y-[30px] z-[100]',
                {
                  [`${playIconPosition}`]: playIconPosition,
                }
              )}
            >
              {playing ? (
                <img className="min-w-[40px]" src={PauseIcon} alt="play icon" />
              ) : (
                <img className="min-w-[40px]" src={PlayIcon} alt="play icon" />
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
