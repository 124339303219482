import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import connectIcon from '../../../../assets/images/event-live/host-and-attendees/connect.svg';
import messageIcon from '../../../../assets/images/event-live/host-and-attendees/message.svg';
import AttendeesIcon from '../../../../assets/images/event-live/host-and-attendees/attendees-icon.svg';
import HostIcon from '../../../../assets/images/event-live/host-and-attendees/host-icon.svg';
import QueueIcon from '../../../../assets/images/event-live/host-and-attendees/queue-icon.svg';
import { IAttendee } from '../../../../models/events-live/attendee';
import { IEvent } from '../../../../models/event/event';
import AttendeesSearch from '../../video-and-audience-panel/host-and-attendees/host-and-attendees-search';
import { Participants, QueueParticipants } from '../../../../store/interactive-event/types';
import { QueueParticipantsView } from './Participants/queue';
import { StageParticipantsView } from './Participants/stage';
import { AttendeesParticipantsView } from './Participants/attendees';
import { IDropdownMenuItem } from '../../../../components/dropdown-menu';
import { closeMessageWindow, openMessageWindow } from '../../../../store/newMessage';

export type ScreenMode = 'small' | 'large' | 'hidden';
type HostAndAttendeesProps = {
  screenMode?: ScreenMode;
  // eslint-disable-next-line react/no-unused-prop-types
  event: IEvent;
  stage: Participants;
  attendees?: IAttendee[];
  queue?: QueueParticipants;
  classNames?: string;
  onMuteClick?: Function;
};

export const HostAndAttendees = (props: HostAndAttendeesProps) => {
  const [formatsAttendees, setFormatsAttendees] = useState(props.attendees);
  const stageSize = props.stage && [...props.stage.moderators, ...props.stage.speakers, ...props.stage.guests, ...props.stage.virtualSpeakers].length;
  const queueSize = props.queue && [...props.queue?.handRaised, ...props.queue?.invited, ...props.queue?.speakers, ...props.queue?.virtualSpeakers].length;
  const dispatch = useDispatch();

  const getDefaultOptions = (user: any): IDropdownMenuItem[] => [
    {
      id: 'profile',
      text: 'Profile',
      icon: connectIcon,
      onClickHandler: () => {
        if (user?.slug) {
          window.open(`/wall/${user.slug}`);
        }
      }
    },
    {
      id: 'message',
      text: 'Message',
      icon: messageIcon,
      onClickHandler: async () => {
        await dispatch(closeMessageWindow());
        await dispatch(
          openMessageWindow({
            avatar: user.avatar!,
            userDisplayName: `${user.firstName} ${user.lastName}`,
            userId: user.id
          })
        );
      }
    }
  ];

  return (
    <div className={classNames({
      'w-full': props.screenMode !== 'large',
      hidden: props.screenMode === 'large',
      [`${props.classNames}`]: props.classNames
    })}
    >
      <div className="w-full h-full bg-white pb-2">
        <AttendeesSearch
          placeHolderText="Search"
          setFormatsAttendees={setFormatsAttendees}
          items={props.attendees!}
        />
        {
          !!props.onMuteClick && props.event?.rooms?.[0]?.isInteractive
          && (
            <div
              className="text-xs font-semibold text-gray-500 w-full flex justify-end px-6 pt-4 hover:underline cursor-pointer"
              onClick={() => props.onMuteClick!()}
            >
              Mute All
            </div>
          )
        }
        <div className="my-4 mx-4">
          <div className="flex flex-row items-center mb-4 mx-2">
            <img src={HostIcon} alt="" />
            <div className="text-h2 font-semibold text-blue-2 pl-1 flex flex-none">{`Stage (${stageSize ?? 0})`}</div>
            <hr className="w-full border my-auto ml-2" />
          </div>
          <div className="grid md:grid-cols-3 grid-cols-2 gap-x-8 gap-y-2 mx-2">
            <StageParticipantsView participants={props.stage} getDefaultOptions={getDefaultOptions} />
          </div>
        </div>
        {
          !!props.queue && props.event?.rooms?.[0]?.isInteractive
          && (
            <div className="my-4 mx-4">
              <div className="flex flex-row items-center mb-4 mx-2">
                <img src={QueueIcon} alt="" />
                <div className="text-h2 font-semibold text-blue-2 pl-1 flex flex-none">{`Queue (${queueSize ?? 0})`}</div>
                <hr className="w-full border my-auto ml-2" />
              </div>
              <div className="grid md:grid-cols-3 grid-cols-2 gap-x-8 gap-y-2 mx-2">
                <QueueParticipantsView participants={props.queue} getDefaultOptions={getDefaultOptions} />
              </div>
            </div>
          )
        }
        <div className="mt-4 mx-4">
          <div className="flex flex-row items-center mb-4 mx-2">
            <img src={AttendeesIcon} alt="" />
            <div className="text-h2 font-semibold text-blue-2 pl-1 flex flex-none">{`Attendees (${props.attendees?.length ?? 0})`}</div>
            <hr className="w-full border my-auto ml-2" />
          </div>
          <div className="grid md:grid-cols-3 grid-cols-2 gap-y-2 mx-2">
            <AttendeesParticipantsView participants={formatsAttendees!} getDefaultOptions={getDefaultOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HostAndAttendees;
