import { Stack, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { useState } from 'react';

interface Props {
  product: any
  productOwnBy:string
}

export const ProductInformation = ({ product, productOwnBy }:Props) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const getSectionLabel = (label: string) => (
    <Typography sx={{
      color: '#8E8E8E', fontSize: '15px', fontWeight: 500, lineHeight: '17px', fontFamily: 'Poppins-500'
    }}
    >
      {label}
    </Typography>
  );

  const getSectionDescription = (description: string) => (
    <Typography sx={{
      color: '#000000', fontSize: '15px', lineHeight: '19px', fontFamily: 'Poppins-400', mt: '5px'
    }}
    >
      {description}
    </Typography>
  );

  const getProdcutCompatibility = () => {
    const compatibility = [
      product?.desktopUrls?.length && 'Desktop',
      product?.phoneUrls?.length && 'Mobile',
      product?.tabletUrls?.length && 'Tablet'
    ].filter(Boolean);

    return compatibility.join(', ');
  };
  const capabilities = product?.capabilities?.map((capability:any) => capability.name).join(', ') || '';
  const industries = product?.industries?.map((industry:any) => industry.name).join(', ') || '';
  const compatibility = getProdcutCompatibility();

  const sanitizedDescription = DOMPurify.sanitize(product?.description || '');
  const truncatedDescription = sanitizedDescription.length > 200 && !isDescriptionExpanded
    ? `${sanitizedDescription.substring(0, 200)}...`
    : sanitizedDescription;

  return (
    <Stack>
      <Typography sx={{
        color: '#000000', fontWeight: 500, fontSize: '20px', lineHeight: '27px', fontFamily: 'Poppins-500'
      }}
      >
        Information
      </Typography>

      <Stack direction="row" justifyContent="space-between" sx={{ mt: '13px' }}>
        <Stack direction="column" sx={{ width: '472px', maxWidth: '472px' }}>
          <Stack>
            {getSectionLabel('Product description')}
            <Typography sx={{
              color: '#000000', fontSize: '15px', fontWeight: 500, lineHeight: '17px', fontFamily: 'Poppins-400', mt: '5px'
            }}
            >
              {parse(truncatedDescription)}
              {/* {parse(DOMPurify.sanitize(product?.description))} */}
              {sanitizedDescription.length > 230 && (
                <button
                  type="button"
                  onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                  style={{ color: 'blue', cursor: 'pointer', paddingTop: '2px' }}
                >
                  {isDescriptionExpanded ? 'See Less' : 'See More'}
                </button>
              )}
            </Typography>
          </Stack>

          <Stack sx={{ mt: '20px' }}>
            {getSectionLabel('Product capabilities')}
            {getSectionDescription(capabilities)}
          </Stack>

          <Stack sx={{ mt: '20px' }}>
            {getSectionLabel('Integrations')}
            {getSectionDescription(product?.integrations?.join(', '))}
          </Stack>
        </Stack>

        <Stack direction="column" sx={{ width: '399px', maxWidth: '399px' }}>
          <Stack>
            {getSectionLabel('Seller')}
            {getSectionDescription(`${productOwnBy}`)}
          </Stack>

          <Stack sx={{ mt: '20px' }}>
            {getSectionLabel('Compatibility')}
            {getSectionDescription(compatibility)}
          </Stack>

          <Stack sx={{ mt: '20px' }}>
            {getSectionLabel('Teams & Functions')}
            {getSectionDescription(product?.targetDepartments?.join(', '))}
          </Stack>

          <Stack sx={{ mt: '20px' }}>
            {getSectionLabel('Industries')}
            {getSectionDescription(industries)}
          </Stack>

          <Stack sx={{ mt: '20px' }}>
            {getSectionLabel('Users & Roles who benefit from the product')}
            {getSectionDescription(product?.targetPersona?.join(', '))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProductInformation;
