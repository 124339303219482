/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as Yup from 'yup';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
    alpha,
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { imageUrlPrefix } from 'src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getEventSponsors } from 'src/store/liveEvent';
import { IAppState } from 'src/store';
import companyDefault from 'src/assets/images/company-default.svg';
import { getBrandingColors } from '../event/live/liveEventStyling';

type ProductsProps = {
    columnWidth: number | null;
    selectedSponsorDetails : any
};

export const Products = ({
    columnWidth,
    selectedSponsorDetails
}: ProductsProps) => {
    const dispatch = useDispatch();
  const colors = getBrandingColors();

    const columnRef = useRef<HTMLDivElement>(null);
    const [boxWidth, setBoxWidth] = useState<number | null>(null);

    const liveEvent = useSelector((state: IAppState) => state.liveEvent);
    const { eventSponsors } = liveEvent;

    useEffect(() => {
        if (columnRef.current) {
            const width = columnRef.current.offsetWidth;
            setBoxWidth(width);
        }
    }, [columnRef.current, columnWidth]);


    useEffect(() => {
        dispatch(getEventSponsors(liveEvent?.event?.id!));
        const handleResize = () => {
            if (columnRef.current) {
                const width = columnRef.current.offsetWidth - 27;
                setBoxWidth(width);
            }
        };

        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };


    }, []);

    const showSponsorCard = (product: any, companySlug: string, name: string) => {
        return (
            <Box ref={columnRef} sx={{ width: "100%", display: "flex", direction: "column", justifyContent: "center", pb: "9px" }}>

                <Stack sx={{ mt: "0px" }} alignItems="center">
                    <Avatar
                        src={product?.icon ? `${imageUrlPrefix}/${product?.icon}` : companyDefault}
                        sx={{
                            width: "82px",
                            height: "82px",
                            borderRadius: "5px",
                            border: '1px solid #F4F4F4',
                            backgroundColor: colors?.Secondary || '#ffffff',
                            "&.MuiAvatar-root, & .MuiAvatar-img": { minHeight: '82px',      borderRadius: "4px",   objectFit: "contain", maxHeight: '82px', minWidth: "82px",   background: !product?.logo? "#edf2f5" : ""  }
                        }}
                    />
                    <Typography className='ellipsis-one-line' variant="body2" sx={{ fontSize: '17px', lineHeight: '24px', color: colors?.Text || 'white', fontFamily: 'Poppins-500', maxWidth: '82px', textAlign: 'center', mt: '7px', fontWeight: 500 }} >
                        {product?.name}
                    </Typography>

                    <Button onClick={()=> window.open(`/wall/company/${name}/prodcut/${product?.id}`,"_blank")} sx={{ borderRadius: "5px", height: "27px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px", lineHeight: "27px", fontFamily: "Poppins-600", background: colors?.Accent || "#ffffff", color: colors?.ButtonTextColor || "#000000", mt: "1px", textTransform: "none", width: "76px", padding: "0px", fontWeight: 600,           "&:hover": { background: colors?.Accent || "#EDF2F5", opacity: '0.5' }, }}>
                        Learn more
                    </Button>

                </Stack>


            </Box >
        );
    }

    return (
        <Stack
            sx={{
                height: 'calc(100vh - 100px)',
                flexFlow: "wrap",
                mt: "1px",
                marginRight: "3px",

                overflowY: "auto",
                alignItems: "start",
                overflowX: 'hidden',
                paddingRight: '14px',
                '&::-webkit-scrollbar-thumb': {
                backgroundColor: `${getBrandingColors().Accent}`,
                borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: `${getBrandingColors().Accent}`,
                opacity: 0.5,
                },
                '&::-webkit-scrollbar-track': {
                backgroundColor: colors?.Secondary,
                },
            }}
            className='branding-scroll'
        >
            <Stack
                justifyContent="space-between"
                sx={{flexFlow:"wrap", gap: '18px', mt: '9px'}}
            >
                {selectedSponsorDetails?.company?.products?.map((product:any,index:number) => (
                    <Stack key={index}>
                        {showSponsorCard(product, selectedSponsorDetails?.company?.slug, selectedSponsorDetails?.company?.name)}
                    </Stack>
                ))
                }
            </Stack>

        </Stack>
    );
};

export default Products;
