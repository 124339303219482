import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLocation, useNavigate } from 'react-router';
import blockUser from '../../../assets/images/block-user.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit-icon.svg';
import RemoveUserIcon from '../../../assets/images/remove-user.svg';
import AlertModal from '../../../components/alert-modal/alert-modal';
import { IDropdownMenuItem } from '../../../components/dropdown-menu';
import { IProfileCoverProps } from '../../../models/profile-cover';
import { UserService } from '../../../services';
import { ButtonWithTextAndImage } from '../../../shared-components/V2';
import { Logo } from '../../../shared-components/V2/Logo';
import {
  closeMessageWindow,
  openMessageWindow,
} from '../../../store/newMessage';
import { imageUrlPrefix } from '../../../utils';
import { useCurrentUser } from '../../../utils/hooks';
import { ConnectActions } from '../components/connect-actions';
import { MutualConnections } from '../components/mutual-connections';
import { UserProfileMore } from '../components/user-profile-more';
import CoverPhotoAndAvatar from './cover-photo-and-avatar';
import EditUserProfile from './edit-user-profile';
import ProfileCoverTabs from './profile-cover-tabs';

export const ProfileCoverView = (props: IProfileCoverProps) => {
  const [openEditModal, setOpenEditModal] = useState(props.intro);
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = location.search?.includes('message');

  const onCompanyClick = () => {
    if (!props.company?.slug) return;
    navigate(`/wall/company/${props.company?.slug}`);
  };

  const handleMessageButton = async () => {
    await dispatch(closeMessageWindow());
    await dispatch(
      openMessageWindow({
        avatar: props.avatarUrl!,
        userDisplayName: `${props.name}`,
        userId: props.id,
      })
    );
  };

  useEffect(() => {
    if (params) {
      handleMessageButton();
    }
  }, [params]);

  const connectedUserActions: IDropdownMenuItem[] = [
    {
      id: 'remove',
      type: 'icon',
      text: 'Remove connection',
      icon: RemoveUserIcon,
      onClickHandler: () => props.onRemoveConnection && props.onRemoveConnection(props.id),
    },
    {
      id: 'block',
      type: 'icon',
      text: 'Block',
      icon: blockUser,
      onClickHandler: () => props.onBlockConnection && props.onBlockConnection(props.id),
    },
  ];

  return (
    <div
      className={classNames(
        'bg-white w-full md:h-[335px] h-fit create-post-card pt-[13px] px-[13px]',
        {
          'md:h-[338px] h-fit': props.isSpotlightProfile,
        }
      )}
    >
      <div className="flex flex-col">
        <CoverPhotoAndAvatar
          coverPhotoUrl={props.coverPhotoUrl}
          avatarUrl={props.avatarUrl}
          isOwnProfile={props.isOwnProfile}
          onUploadCoverPhoto={props.uploadCoverPhoto!}
          onUploadAvatar={props.uploadProfilePicture!}
          avatarSize="XXLARGE"
        />
        <div className="flex items-center sm:justify-end justify-start pr-[6px] w-full sm:-mt-4 sm:my-0 my-1.5 sm:ml-0 ml-1.5">
          <Logo
            src={`${imageUrlPrefix}/${props.company?.logo}`}
            alt=""
            className={classNames(
              'w-[24px] h-[24px] p-[0px] rounded-[4px] object-contain',
              {
                'bg-[#edf2f5]': !props.company?.logo,
              }
            )}
            defaultLogo="company"
          />
          <span
            className="flex items-center text-[15px] text-black leading-[18px] cursor-pointer font-semibold px-[7px] pr-0 line-clamp-1"
            onClick={onCompanyClick}
          >
            {props.company?.name}
          </span>
        </div>
        {currentUser.id !== props.id && (
          <div className="flex justify-end relative">
            <MutualConnections
              primaryId={currentUser.id}
              secondaryId={props.id}
              className="absolute text-sm font-light text-[#172746] h-[18px] sm:top-[23px] sm:right-[6px] -top-[60px]"
            />
          </div>
        )}
        <div className="md:flex flex-row justify-between">
          <div className="flex flex-col ml-[6px] mt-0">
            <div className="text-[22px] md:h-[31px] text-[#000000] mb-[0px] font-semibold capitalize">
              {props.name}
            </div>
            <div className="text-md15 font-[500] md:h-[21px] text-[#000000] mb-[0px]">
              {props.jobTitle}
            </div>
            <div className="text-md15 font-[400] md:h-[21px] text-[#3C3C3C] ">
              {props.location}
            </div>
          </div>
          {!props.isOwnProfile && (
            <div className="flex items-center flex-row md:-mb-3 md:mt-8 mt-[40px] gap-x-[5px]">
              <div>
                {!props.isConnected
                  && props.sentRequest === 0
                  && props.hasRequest === 0 && (
                    <ButtonWithTextAndImage
                      buttonClasses="bg-[#144EE6] hover:bg-[#2e6eff]  mx-2 mr-0 my-1 w-110 h-30 rounded-5"
                      imageUrl=""
                      text="Connect"
                      textClasses="text-white text-sm1 font-semibold -ml-2 tracking-wide "
                      onClick={() => props.onConnect && props.onConnect(props.id)}
                    />
                )}
                {props.isConnected && (
                  <ConnectActions
                    title="Connected"
                    options={connectedUserActions}
                  />
                )}
                {!props.isConnected && props.sentRequest === 1 && (
                  <div
                    // eslint-disable-next-line max-len
                    className="cursor-pointer bg-[#144EE6] hover:bg-[#144EE6] active:bg-[#144EE6] text-center flex items-center justify-center w-[164px] h-30 rounded-5 text-white text-sm1 font-semibold -ml-2 tracking-wide"
                    onClick={async () => {
                      const user = new UserService();
                      await user
                        .withDrawConnection(props.id)
                        .then(() => props?.onWithDrawConnection!());
                    }}
                  >
                    Withdraw request
                  </div>
                )}
                {!props.isOwnProfile && props.hasRequest === 1 && (
                  <div className="flex flex-row gap-[5px]">
                    <ButtonWithTextAndImage
                      buttonClasses="bg-[#0049EF] hover:bg-[#244E93] active:bg-[#244E93]  w-110 h-30 rounded-[5px]"
                      imageUrl=""
                      text="Accept"
                      textClasses="text-white text-sm1 font-semibold -ml-2 tracking-wide "
                      onClick={() => props.onAcceptConnection && props.onAcceptConnection()}
                    />
                    <ButtonWithTextAndImage
                      buttonClasses="bg-[#144EE6] hover:[#CEDCF2] active:[#CEDCF2]  w-110 h-30 rounded-[5px]"
                      imageUrl=""
                      text="Reject"
                      textClasses="text-white text-sm1 font-semibold -ml-2 tracking-wide "
                      onClick={() => props.onRejectConnection && props.onRejectConnection()}
                    />
                  </div>
                )}
              </div>
              <div className="">
                <ButtonWithTextAndImage
                  buttonClasses="bg-[#EDF2F5] hover:[#CEDCF2] active:[#CEDCF2] my-1 w-110 h-30 rounded-[5px]"
                  imageUrl=""
                  text="Message"
                  textClasses="text-[#203C6E] text-[13px] font-semibold -ml-2 tracking-wide"
                  onClick={handleMessageButton}
                />
              </div>
              <div className="pr-[5px]">
                <UserProfileMore userId={props.id} />
              </div>
            </div>
          )}
        </div>
        <div
          className={classNames(
            'm-auto mt-[4px] mb-[2px] h-[0.2px] xl:w-[873.6px] w-[calc(100%-13px)] bg-[#e8e8e8]',
            {
              '!mt-[12px]': props.isSpotlightProfile || props.isOwnProfile,
              '!mt-[13px]': !props.isOwnProfile,
            }
          )}
        />
        <div className="flex flex-row justify-between items-center">
          <ProfileCoverTabs />
          {props.isOwnProfile && (
            <div
              className="mr-[6px] md:mt-[11px] mt-0 cursor-pointer"
              onClick={() => setOpenEditModal(true)}
            >
              <EditIcon />
              <AlertModal
                onClose={() => {
                  setOpenEditModal(false);
                  if (props.handleIntroStatus) { props.handleIntroStatus(openEditModal); }
                }}
                isOpen={openEditModal}
                className="md:w-[482px] py-0 w-full"
                closeIconClass=" right-[21px] top-[14px] px-0"
                isCloseIcon
              >
                <EditUserProfile
                  coverPhoto={props.coverPhotoUrl}
                  avatar={props.avatarUrl}
                  firstName={props.name.split(' ')[0]}
                  lastName={props.name.split(' ')[1]}
                  companyId={props.companyId!}
                  companyName={props.company?.name}
                  jobTitle={props.jobTitle}
                  location={props.location}
                  aboutMe={props.aboutMe!}
                  onUploadCoverPhoto={props.uploadCoverPhoto!}
                  onUploadAvatar={props.uploadProfilePicture!}
                  onClose={() => {
                    setOpenEditModal(false);
                    if (props.handleIntroStatus) { props.handleIntroStatus(openEditModal); }
                  }}
                  onSave={(data: any) => props.onEditProfile!(data)}
                />
              </AlertModal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileCoverView;
