export interface ILikeSvgIconProps {
    fillColor?: string;
}
export const LikeSvgIcon = ({ fillColor }: ILikeSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#717171"
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 18.616 17.906">
    <g id="Component_917_1" data-name="Component 917 – 1" transform="translate(0.6 0.679)">
      <g id="Group_11496" data-name="Group 11496" transform="translate(4.517 1)">
        <path id="Path_10310" data-name="Path 10310" d="M111.7,91.806h-5.561a3.018,3.018,0,0,1-3.022-3.007V84.057a2.982,2.982,0,0,1,.543-1.72l3.992-5.7a1.918,1.918,0,0,1,2.41-.61,3.1,3.1,0,0,1,1.486,3.942l-.554,1.343h1.341a3.643,3.643,0,0,1,3.049,1.636,3.6,3.6,0,0,1,.3,3.434l-1.705,3.927A2.482,2.482,0,0,1,111.7,91.806Z" transform="translate(-103.115 -76.835)" fill="none" stroke={iconColor} strokeWidth="1.2"/>
      </g>
      <g id="Group_11498" data-name="Group 11498" transform="translate(0 3.996)">
        <path id="Path_10311" data-name="Path 10311" d="M80.226,147.877c-1.248,0-2.259-.553-2.259-1.236V136.482c0-.683,1.011-1.236,2.259-1.236s2.259.553,2.259,1.236v10.159C82.485,147.324,81.473,147.877,80.226,147.877Z" transform="translate(-77.967 -135.246)" fill="none" stroke={iconColor} strokeWidth="1.2"/>
      </g>
    </g>
  </svg>
  
  
};