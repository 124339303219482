import { IEventTicketsResult } from '../../models/tickets';
import BaseService from '../BaseService';

export class TicketService extends BaseService {
  async getTickets(eventId: string) {
    const extraOptions = this.getAuthToken();

    return this.get<IEventTicketsResult>(
      `/events/${eventId}/tickets`,
      extraOptions
    );
  }

  async placeOrder(order: any) {
    const extraOptions = this.getAuthToken();

    return this.post<any>(
      '/orders',
      order,
      extraOptions
    );
  }

  async getOrderSummary(orderId: string) {
    const extraOptions = this.getAuthToken();

    return this.get<any>(`/orders/${orderId}`, extraOptions);
  }
}
