import { HyperLink } from '../../../shared-components';

import poweredbyPanelist from '../../../assets/images/website-event/poweredbypanelist.svg';

const WebsiteEventFooter = () => (
  <div className="z-50 inset-x-0 bottom-0 text-center mt-8">
    <div className="md:max-w-[90%] max-w-[97%] mx-auto xl:flex xl:items-center xl:justify-between justify-center">
      <div className="flex items-center justify-center lg:justify-start xl:mb-0 mb-3">
        <div className="w-[140px] mx-auto">
          <img src={poweredbyPanelist} alt="gradient-background" className="object-cover w-full" />
        </div>
      </div>
      <div className="lg:flex-row flex-col flex items-center xl:justify-start gap-x-[40px] gap-y-[5px] justify-center">
        <HyperLink
          classes="font-normal text-[11px] text-[#3C3C3C] hover:underline"
          variant="FooterLink"
          text="User Agreement"
          href="/user-agreement"
        />
        <HyperLink
          classes="font-normal text-[11px] text-[#3C3C3C] hover:underline"
          variant="FooterLink"
          text="Privacy Policy"
          href="/privacy-policy.html"
        />
        <HyperLink
          classes="font-normal text-[11px] text-[#3C3C3C] hover:underline"
          variant="FooterLink"
          text="Cookie Policy"
          href="/cookie-policy"
        />
        <HyperLink
          classes="font-normal text-[11px] text-[#3C3C3C] hover:underline"
          variant="FooterLink"
          text="Copyright Policy"
          href="/copyright-policy"
        />
        <HyperLink
          classes="font-normal text-[11px] text-[#3C3C3C] hover:underline"
          variant="FooterLink"
          text="Community Guidelines"
          href="/community-guidelines"
        />
        <HyperLink classes="font-normal text-[11px] text-[#3C3C3C] hover:underline" variant="FooterLink" text="About Us" href="/about" />
        <HyperLink classes="font-normal text-[11px] text-[#3C3C3C] hover:underline" variant="FooterLink" text="Help" href="https://help.panelist.com" />
      </div>
    </div>
  </div>
);

export default WebsiteEventFooter;
