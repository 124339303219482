import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ICompanyProfile } from '../../models/company/company-profile';
import { Logo } from '../../shared-components/V2/Logo';
import { NoEvents } from '../events/event-tab/components/no-events';

type ICompaniesListCardProps = {
  companies: ICompanyProfile[];
  onFollow: Function;
  onUnfollow: Function;
  onClickCompany: (c: any) => void;
};

export const CompaniesListCard = (props: ICompaniesListCardProps) => {
  const navigate = useNavigate();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props?.companies?.length > 0
        ? props.companies.map((item: ICompanyProfile) => (
          <div
            key={item.id}
            className="flex items-center justify-between border-b-1 border-[#CDDCF2] py-0 bg-white"
          >
            <div className="flex items-center pb-[20px] pt-[20px] text-blue-800 w-full">
              <div
                className="flex-shrink-0 cursor-pointer"
                onClick={() => {
                  setTimeout(() => {
                    navigate(`/wall/company/${item.slug}`);
                  }, 1000);
                  props?.onClickCompany(item);
                }}
              >
                <Logo
                  className={classNames(' bg-white rounded-[4px] w-[49px] max-w-[49px] min-w-[49px] h-[49px] max-h-[49px] min-h-[49px] object-contain', {
                    'p-[1px]': item.logo,
                    'bg-[#edf2f5]': !item.logo,
                  })}
                  src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item.logo}`}
                  alt=""
                  defaultLogo="company"
                />
              </div>
              <div className="flex flex-col items-start pl-4">
                <span
                  className="text-[14px] leading-5 font-semibold text-[#172746] cursor-pointer"
                  onClick={() => {
                    setTimeout(() => {
                      navigate(`/wall/company/${item.slug}`);
                    }, 1000);
                    props?.onClickCompany(item);
                  }}
                >
                  {item.name}
                </span>
                { item?.industry?.name
                  ? (
                    <span className="text-xs text-gray-900">
                      {`${item?.industry?.name}${
                        item.headquarter ? ` • ${item.headquarter}` : ''
                      }`}
                    </span>
                  ) : ''}
                {
                  item?.companySize?.name ? (
                    <span className="text-xs text-gray-500">
                      {`${item?.companySize?.name} employees`}
                    </span>
                  ) : ''
                }

              </div>
            </div>
            {
              !item.isAdmin && (
              <div className="flex flex-col gap-3">
                <button
                  type="button"
                  className="bg-[#0049EF] hover:bg-[#2e6eff] text-white text-sm font-semibold rounded-10 px-3 py-1"
                  onClick={() => (item.isFollowing ? props.onUnfollow(item.slug) : props.onFollow(item.slug))}
                >
                  {item.isFollowing ? 'Unfollow' : 'Follow' }
                </button>
              </div>
              )
            }
          </div>
        )) : <NoEvents />}
    </>
  );
};

export default CompaniesListCard;
