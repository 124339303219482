import { LinkButton, HyperLink } from '../../shared-components';
import logo from '../../assets/images/homepage/logo-panelist-white.svg';
import { Link } from 'react-router-dom';

export const Navbar = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const redirectTo = queryParams.get('login');
  
  const getRedirectUrl = (url: string) => {
    if (redirectTo) {
      return `${url}?login=${redirectTo}`
    }
    return `${url}`
  }

  return (
    <>
      <nav className="bg-black z-40 relative">
        <div className="container mx-auto w-full xl:pl-[130px] xl:pr-[100px] pl-[30px] pr-[30px] max-w-[100%]">
          <div className="flex items-center justify-between h-[60px]">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Link to="/">
                  <img className="h-7" src={logo} alt="Panelist" />
                </Link>
              </div>
            </div>
            <div className="flex">
              <HyperLink
                variant="NavLink"
                text="Log in"
                href={getRedirectUrl("/loginorjoin")}

                classes="login-button !text-[15px] !text-white hover:underline font-semibold"
              />
              <LinkButton
                text="Join Now"
                backgroundColor=""
                textColor="#3855EB"
                classes="bg-white ml-[32px] w-[114px] h-[38px] !text-[15px] font-medium text-[#0B1221] hover:!text-white hover:bg-[#2000CD] leading-6 join-button !rounded-[8px] transition-all"
                width="90px"
                height="30px"
                // href="/loginorjoin"
                href={getRedirectUrl("/loginorjoin")}
              />
            </div>
          </div>
        </div>
      </nav>
    </>
  )

};

export default Navbar;
