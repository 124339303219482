import { Reducer } from 'redux';

import { ISignupProfileCoverPhotoState } from '../../../../models/signup/SignupProfileCoverPhotoState';
import { CoverPhotoActions } from './actions';
import { CoverPhotoActionTypes } from './types';

const initialState: ISignupProfileCoverPhotoState = {
  url: ''
};

export const SetSignupCoverPhotoReducer: Reducer<
  ISignupProfileCoverPhotoState,
  CoverPhotoActions
> = (state = initialState, action) => {
  switch (action.type) {
    case CoverPhotoActionTypes.SET_COVER_PHOTO: {
      return {
        ...state,
        url: action.payload.url
      };
    }

    case CoverPhotoActionTypes.COVER_PHOTO_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
