import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IAssignedManagers, IExpoHandouts } from '../../../../../../models/events-live/expo';
import { IExpoBooth } from '../../../../../../models/expo';
import { IAppState } from '../../../../../../store';
import {
  expoEndMeeting, expoGuestCancel, expoManagerReject
} from '../../../../../../store/expo/actions';
import { HangoutsAndExitBoothLayout } from './expo-handouts-exit-booth-layout';
import { ExpoManagersLayout } from './expo-managers-layout';

// Props for the Expo Booth
type ExpoBoothProps = {
  booth: IExpoBooth;
  boothManagers: IAssignedManagers[];
  handouts: IExpoHandouts[];
  // eslint-disable-next-line no-unused-vars
  exitBoothHandler: () => void;
};
export const ExpoBooth = (props: ExpoBoothProps) => {
  const expoChimeInvitation = useSelector((state: IAppState) => state.chime.chimeInvitation);
  const expoGuestCall = useSelector((state: IAppState) => state.chime.guestCall);
  const expoCall = useSelector((state: IAppState) => state.chime.expoCall);
  const dispatch = useDispatch();
  const meetingManager = useMeetingManager();

  useEffect(() => () => {
    if (meetingManager) meetingManager.leave();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exitBooth = () => {
    if (expoGuestCall?.callId) {
      // eslint-disable-next-line no-unused-expressions
      expoChimeInvitation?.callId ? dispatch(expoEndMeeting()) : dispatch(expoGuestCancel());
    } else if (expoCall?.callId) {
      // eslint-disable-next-line no-unused-expressions
      expoChimeInvitation?.callId ? dispatch(expoEndMeeting()) : dispatch(expoManagerReject());
    }
    if (meetingManager) meetingManager.leave();
    props.exitBoothHandler();
  };

  return (
    <div className="flex flex-col h-full py-4">
      <ExpoManagersLayout booth={props.booth} boothManagers={props.boothManagers} />
      <HangoutsAndExitBoothLayout handouts={props.handouts} exitBoothHandler={() => exitBooth()} />
    </div>
  );
};

export default ExpoBooth;
