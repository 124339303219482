/* eslint-disable */
import { Autocomplete,  TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const fieldHelperText = {
  "& .MuiFormHelperText-root": { ml: "1px" }
}

type OptionType = {
  id: string;
  name: string;
};

interface Props {
  sx?: any;
  label?: string;
  name: string;
  defaultValue?: OptionType[];
  multiSelect?: boolean | undefined;
  selectionRole?: string;
  togglePUserSelectOpen?: any;
  aLLFavouritePages?: any;
  onChange?:any
}

const PFavouritePageSelect = ({
  aLLFavouritePages,
  sx,
  label,
  name,
  onChange
}: Props) => {

  const { control } = useFormContext();

  return (
<Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          options={aLLFavouritePages}
          getOptionLabel={(option) => option?.name}
          onChange={(_, newValue) => {if(onChange) onChange(newValue); else field.onChange(newValue);}}
          renderInput={(params) => (
            <TextField
              sx={{
                ...sx,
                ...fieldHelperText,
                "& .MuiAutocomplete-input": {
                  pl: "0px !important"
                }
              }}
              label={label}
              error={!!error}
              helperText={error ? error?.message : ""}
              {...params}
            />
          )}
        />
      )}
    />

  );
};

export default PFavouritePageSelect;
