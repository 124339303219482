import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  IPoll,
  IPollAnswer,
} from '../../../models/poll/poll';
import { Typography } from '../../../shared-components';
import { castLiveVote, getPollsByAnswerResult } from '../../../store/livePoll';
import {
  castVote,
  getPoll,
  removeVote,
} from '../../../store/poll';
import { getDistanceBetweenTwoDateTime } from '../../../utils/datetime';
import { useCurrentUser } from '../../../utils/hooks';
import { IAppState } from 'src/store';
import { CREATE_POLL } from 'src/constants';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

const ViewPoll = (poll: IPoll) => {
  const colors = getBrandingColors();
  const pollErrorMessage = useSelector((state: IAppState) => state.poll.errorMessage);
  const votes = poll.answers?.reduce((prev, current) => {
    return prev + current.votes;
  }, 0);

  const dispatch = useDispatch();

  const currentUser = useCurrentUser();
  const selectedAnswer: IPollAnswer | null =
    poll.answers?.find((answer) =>
      answer?.voteHistories?.some((history) => history.userId === currentUser.id)
    ) ?? null;
  const isPollEnded = new Date(poll.expiredAt) < new Date();

  const voteStatus = () => {
    if (isPollEnded) {
      return "Poll closed"
    } else {
      let expireDate = poll.expiredAt;
      if (!expireDate) {
        expireDate = new Date().toString();
      }
      return `${getDistanceBetweenTwoDateTime(
        new Date(expireDate),
        new Date()
      )} left`;
    }
  }

  const onSuccessVote = () => {
    dispatch(getPoll(poll.id));
    if (pollErrorMessage !== "") {
      toast.error(pollErrorMessage);
    }
  }

  const onCastVote = (pollId: string, id: string) => {
    if (poll?.onCastVote) {
      poll?.onCastVote(pollId, id)
    }
    else {
      dispatch(castVote(pollId, id, onSuccessVote));
    }
  }
  return (
    <>
      <div
        className={classNames('flex justify-center border border-[#CDDCF2] rounded-lg mx-[20px]', {
          'border-none mx-[0px]': poll.isPollCreate,
          'border-none  !mr-[0px]': poll.pollOnVideo,
          '!mx-[10px]': poll?.isLivePage
        })}
        style={{
          borderColor: poll?.isLivePage ? colors?.Secondary : '',
        }}
      >
        <div
          // className='flex flex-col w-full rounded-lg px-[14px] py-[11px] z-10 relative overflow-hidden'
          className={classNames('flex flex-col w-full rounded-lg z-10 relative overflow-hidden', {
            'px-[2px] py-[0px]': poll.isPollCreate,
            'px-[14px] py-[11px]': !poll.isPollCreate,
          })}
        >
          <div className=' w-full h-full backdrop-blur-[80px] absolute left-0 top-0 rounded-lg overflow-hidden' />
          <div
            className={classNames('w-full relative', {
              '!h-[100px] min-h-[100px] max-h-[100px] overflow-y-auto': poll?.isCompanyNew,
            })}
          >
            {poll.isPollCreate ?

              //  <div className='flex justify-start font-medium text-[15px] leading-5 text-black mb-[14px] text-left'>
              //     {poll.question}
              //   </div>
              <>
                <div className='flex flex-col justify-between'>
                  <div className='text-[13px] font-medium mb-[4px] text-[#000000] leading-[20px]'>Your question *</div>
                </div>
                <div >
                  <input
                    type='text'
                    value={poll.question}
                    className='w-full shadow-sm border-[2px] border-[#E5E5E5] h-[37px] rounded-[5px] px-[10px] text-sm2 focus:outline-none text-[13px] font-normal text-[#3C3C3C] leading-[20px]'
                    disabled
                    placeholder="E.g. How do you commute to work?"
                    maxLength={140}
                    autoComplete="off" />
                  <div className='w-full text-right mt-[1px] text-[13px] font-normal text-[#ADADAD] leading-[20px]'>{`${poll?.question?.length}/140`}</div>
                </div></>
              :
              <div 
              className={classNames('flex justify-start font-medium text-[15px] leading-5 text-black mb-[14px] text-left', {
                '!text-white': poll?.pollOnVideo,
                '!mb-[10px]': poll?.isCompanyNew,
                '!text-[12px] !leading-4': poll?.isLivePage,
              })}
              style={{
                color: poll?.isLivePage ? colors?.Text : '',
              }}
              >
                {poll.question}
              </div>
            }
            <div className='relative flow-root'>
              {poll?.answers?.map((answer: IPollAnswer, index: number) => (
                poll.isPollCreate ?
                  <div
                    key={`answer-${index}`}
                    className={classNames('mt-2', {
                      '!mt-0': poll.isPollCreate,
                    })}
                  >
                    <div className=''>
                      <div className='flex justify-between'>
                        <div className='text-[13px] font-medium mb-[4px] text-[#000000] leading-[20px]'>Option {index + 1}*</div>
                      </div>
                      <div className='relative'>
                        <input
                          type='text'
                          // disabled
                          value={answer?.answer}
                          className='w-full border-[2px] border-[#E5E5E5] h-[37px] rounded-[5px] px-[10px] text-sm2 focus:outline-none text-[13px] font-normal text-[#3C3C3C] leading-[20px] pointer-events-none'
                          maxLength={30}
                          placeholder={`E.g. How do you commute to work example ${index + 1} ?`}
                          // onChange={(e: any) => {
                          //   let answers = [...answerCharactersCount];
                          //   let answer = { ...answers[index] };
                          //   answer.length = e.target.value.length;
                          //   answers[index] = answer;
                          //   setAnswerCharactersCount(answers);
                          // }}
                          autoComplete="off"
                        />
                        <div className='w-full text-right mt-[1px] text-[13px] font-normal text-[#ADADAD] leading-[20px]'>{`${answer?.answer?.length}/30`}</div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className='flex flex-col' key={answer.id}>
                    <div
                      style={{ background: poll.pollOnVideo   ? "" :  `linear-gradient(to right, ${poll?.isLivePage ? colors?.Accent : '#EDF2F5'} ${answer?.percent || answer?.votePercent}%, transparent ${answer?.percent || answer?.votePercent}%)`, width: ((selectedAnswer || isPollEnded) && !poll.pollOnVideo) ? 'calc(100% - 60px)' : '100%', borderLeftColor: poll?.isLivePage ? colors?.Accent : '' }}
                      className={classNames('flex h-[30px] text-sm1 text-black font-medium hover:text-black mb-[8px] transition-all', {
                        'cursor-pointer rounded-[8px] border border-[#0049ef] hover:outline-1 hover:!bg-[#e2f0fe]': !selectedAnswer && !isPollEnded &&  !poll.pollOnVideo,
                        'rounded-[5px]': selectedAnswer &&  !poll.pollOnVideo,
                        'border-l-2 border-[#EDF2F5] !rounded-none': answer?.percent === "0.0000" && selectedAnswer &&  !poll.pollOnVideo,
                        'border-l-2 border-[#EDF2F5] !rounded-[0px] border-r-0 border-t-0 border-b-0': answer?.percent === "0.0000" && isPollEnded &&  !poll.pollOnVideo,
                        'cursor-pointer !bg-[#2f2f2f] rounded-[5px] h-[30px]': poll.pollOnVideo && !answer.votes,
                        '!bg-[#03FFCD] rounded-[5px] h-[30px]': poll.pollOnVideo && answer.votes,
                        '!bg-green-200': !!answer.votes ,
                      })}
                      key={`poll-card-${index}`}
                      onClick={async () => {
                        if (poll.isInteractive && poll.type !== 'live') {
                          if (!selectedAnswer && !isPollEnded) {
                            onCastVote(poll.id, answer.id);
                          }
                        }
                        else if (poll.isInteractive && poll.type === 'live') {
                          if (!selectedAnswer && !isPollEnded) {
                            await dispatch(castLiveVote(poll.eventId!, poll.sessionId!, poll.id, answer.id));
                            await dispatch(getPollsByAnswerResult(poll.eventId!, poll.sessionId!, poll.id));
                          }
                        }
                      }}
                    >
                      <div
                        className={classNames('flex flex-row my-auto px-3 relative line-clamp-1 leading-[30px]', {
                          'text-[#0049ef] text-center w-full text-[14px] font-normal': !selectedAnswer && !isPollEnded,
                          'text-[#000000] text-left': isPollEnded,
                          '!text-[#ffffff]': poll.pollOnVideo && !answer.votes,
                          '!text-[#000000]': poll.pollOnVideo && answer.votes,
                        })}
                        style={{
                          color: poll?.isLivePage ? colors?.Text : '',
                        }}
                      >
                        <span>{answer.answer}</span>
                      </div>
                    </div>
                    {!!votes && poll.isInteractive && (
                      <div className='ml-auto pr-2 absolute right-0' key={`poll-percentage-${index}`}>
                        <Typography
                          classes='!leading-[30px] !font-normal'
                          variant='H3'
                          text={`${parseInt(answer?.percent || answer?.votePercent!)} %`}
                          style={{
                            color: poll?.isLivePage ? colors?.Text : '',
                          }}
                        />
                      </div>
                    )}
                  </div>
              ))}
            </div>
          </div>
          {(poll.isInteractive || poll.pollOnVideo) && (
            <div className='flex flex-row items-center justify-between relative mt-[6px]'>
              <div>
                <Typography
                  classes={classNames('!text-[12px] !font-medium !leading-[17px] text-black', {
                    '!text-[#ffffff]': poll.pollOnVideo ,
                  })}
                  variant='H3'
                  text={poll.type === 'live' ? `${votes} votes` : `${votes} votes . ${voteStatus()}`}
                  style={{
                    color: poll?.isLivePage ? colors?.Text : '',
                  }}
                />
              </div>
              {((!isPollEnded && selectedAnswer) || (poll.pollOnVideo && selectedAnswer)) && (
                <div>
                  <button                  
                    className={classNames('bg-white w-[58px] h-[23px] rounded-5 text-black text-[13px] font-semibold flex items-center justify-center')}
                    onClick={async () => {
                      await dispatch(removeVote(poll.id, selectedAnswer.id));
                      if (poll?.onRemoveVote) poll?.onRemoveVote(poll?.id)
                      // await dispatch(getUserPosts());
                      // await dispatch(getPoll(poll.id));
                    }}
                    style={{
                      color: poll?.isLivePage ? colors?.Text : '',
                    }}
                  >
                    Undo
                  </button>
                </div>
               )} 
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewPoll;
