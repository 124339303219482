import { useState } from 'react';

interface IOverviewSvgIcon {
  isActive?: boolean;
  className?: string;
  disableHover?: boolean;
}
export const OverviewSvgIcon = ({ isActive, className, disableHover }: IOverviewSvgIcon) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const colorWhite = '#ffffff';
  const defaultColor = '#7283A4';
  const fillColor = !disableHover && (isHover || isActive) ? colorWhite : defaultColor;

  return (
    <svg
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <g id="dashboard-svgrepo-com" transform="translate(-2 -2)">
        <rect id="Rectangle_8280" data-name="Rectangle 8280" width="12" height="15" rx="2" transform="translate(2 2)" fill={fillColor} />
        <rect id="Rectangle_8281" data-name="Rectangle 8281" width="12" height="9" rx="2" transform="translate(17 2)" fill={fillColor} />
        <rect id="Rectangle_8282" data-name="Rectangle 8282" width="12" height="10" rx="2" transform="translate(2 19)" fill={fillColor} />
        <rect id="Rectangle_8283" data-name="Rectangle 8283" width="12" height="15" rx="2" transform="translate(17 14)" fill={fillColor} />
      </g>
    </svg>
  );
};
