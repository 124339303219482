import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { UseFormRegister } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';
import { IndustrySelector } from '../../../../components/selectors/industry-selector';
import { JobTitleSelector } from '../../../../components/selectors/jobTitle-selector';
import { CompanySelector } from '../../../../components/selectors/company-selector';
import { ISignUp } from '../event-signup/event-signup';
import { IRegistrationForm } from '../../../../models/user/event-registration/registrationForm-result';
import { IReactSelectOption } from '../../../../models/react-select-option';
import InputField from './input-field';
import 'react-phone-input-2/lib/style.css';
import { ParseContent } from '../../website/parse-html';

interface IDynamicallyFieldRegistrationProps {
  item: IRegistrationForm;
  register: UseFormRegister<ISignUp>;
  changeDynamicData: Function;
  selectedState?: IReactSelectOption;
  selectedCompany: any;
  selectedIndustry: IReactSelectOption;
  selectedJobTitle: IReactSelectOption;
  selectedValue: any;
  disabled?: boolean;
  isSpectre?: boolean;
  isSpectreForm?: boolean;
}

const DynamicallyFieldRegistration = ({
  item,
  register,
  changeDynamicData,
  // eslint-disable-next-line no-unused-vars
  selectedState,
  selectedValue,
  selectedCompany,
  selectedIndustry,
  selectedJobTitle,
  disabled,
  isSpectre,
  isSpectreForm
}: IDynamicallyFieldRegistrationProps) => {
  const [dropDownOptionListState, setdropDownOptionListState] = useState<IReactSelectOption[]>();

  const dropdownItems = item.registrationOptionFields;

  useEffect(() => {
    const mapSelectOption = () => {
      const dropdownList: IReactSelectOption[] = [];

      // eslint-disable-next-line no-unused-expressions
      dropdownItems
        && dropdownItems.map((item: any, index: number) => dropdownList.push({
          key: `industry-${index}`,
          value: item.id,
          label: item.optionName
        }));
      setdropDownOptionListState(dropdownList);
    };
    mapSelectOption();
  }, [dropdownItems]);

  const handleChange = (answer: any, option?: string, event?: SingleValue<IReactSelectOption>) => {
    if (item.fieldType === 'select') {
      if (answer.checked) changeDynamicData(item.id, item.fieldType, option);
      else changeDynamicData(item.id, item.fieldType, '');
    } else if (item.fieldType === 'terms-and-conditions') {
      changeDynamicData(item.id, item.fieldType, answer.checked);
    } else if (
      item.fieldType === 'radio'
      || item.fieldType === 'job-title'
      || item.fieldType === 'company'
      || item.fieldType === 'industry'
    ) {
      changeDynamicData(item.id, item.fieldType, answer, event);
    } else if (item.fieldType === 'phone') {
      changeDynamicData(item.id, item.fieldType, answer);
    } else {
      changeDynamicData(item.id, item.fieldType, answer.value);
    }
  };

  let htmlField = null;

  switch (item.fieldType) {
    case 'first-name':
    case 'last-name':
    case 'full-name':
    case 'text':
    case 'email':
    case 'business-email':
    case 'address': {
      htmlField = (
        <div
          className={classNames({
            'relative mt-[19px]': isSpectre,
            'flex justify-start mt-[10px]': !isSpectre,
          })}
        >
          <div
            className={classNames({
              'text-left w-full': !isSpectre,
            })}
          >
            <span>
              <label
                className={classNames({
                  'bg-[#2d2d2d] absolute left-[10px] -top-[8px] z-10 helvetica-neue-55-roman !px-[5px] text-[11px] text-white': isSpectre,
                  'normal-case text-[13px] leading-[19px] text-[#3C3C3C] mb-[5px] font-medium block tracking-wide': !isSpectre,
                })}
                htmlFor={item.id}
              >
                {item.fieldName}
                {item.isRequired ? <span className={classNames({ 'text-red ml-[1px]': isSpectreForm })}>*</span> : ''}
              </label>
            </span>
            <InputField
              item={item}
              type="text"
              className={classNames({
                // eslint-disable-next-line max-len
                'helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] text-[15px] text-white px-[15px] focus-visible:outline-none': isSpectre,
                'w-full font-extralight text-gray-12 px-2.5 rounded-10 text-sm1 border h-[31px] border-[#CDDCF2]': !isSpectre,
              })}
              selectedValue={selectedValue}
              onChange={(e: any) => handleChange(e)}
              disabled={disabled}
            />
          </div>
        </div>
      );
      break;
    }
    case 'job-title': {
      htmlField = (
        <div className="relative">
          <div
            className={classNames({
              'bg-[#2d2d2d] absolute left-[10px] -top-[8px] z-10 helvetica-neue-55-roman !px-[5px] text-[11px] text-white': isSpectre,
              'normal-case text-[13px] leading-[19px] text-[#3C3C3C] mb-[5px] font-medium block tracking-wide': !isSpectre,
            })}
          >
            {item.fieldName}
            {item.isRequired ? <span className={classNames({ 'text-red ml-[1px]': isSpectreForm })}>*</span> : ''}
          </div>
          <div className="mt-[10px]">
            <JobTitleSelector
              onChange={(e: any) => handleChange(e?.label, '', e)}
              register={register}
              selectedJobTitleStateToStore={selectedJobTitle ?? { label: selectedValue }}
              placeholder="Enter your job title"
              disabled={disabled}
              isSpectre={isSpectreForm}
            />
          </div>
        </div>
      );
      break;
    }
    case 'company': {
      htmlField = (
        <>
          <div
            className={classNames('text-[13px] leading-[19px]', {
              'text-white mb-[5px] font-medium block': isSpectre,
              'text-[#3C3C3C] mb-[5px] font-medium block': !isSpectre,
            })}
          >
            {item.fieldName}
            {' '}
            {item.isRequired ? <span className={classNames({ 'text-red ml-[1px]': isSpectreForm })}>*</span> : ''}
          </div>
          <div className="">
            <CompanySelector
              onChange={(e: any) => handleChange(e?.label, '', e)}
              register={register}
              selectedCompanyStateToStore={selectedCompany ?? { label: selectedValue }}
            />
          </div>
        </>
      );
      break;
    }
    case 'industry': {
      htmlField = (
        <>
          <div
            className={classNames('text-[13px] leading-[19px]', {
              'text-white mb-[5px] font-medium block': isSpectre,
              'text-[#3C3C3C] mb-[5px] font-medium block': !isSpectre,
            })}
          >
            {item.fieldName}
            {' '}
            {item.isRequired ? <span className={classNames({ 'text-red ml-[1px]': isSpectreForm })}>*</span> : ''}
          </div>
          <div className="">
            <IndustrySelector
              onChange={(e: any) => handleChange(e, '', e)}
              register={register}
              selectedIndustryStateToStore={selectedIndustry ?? { label: selectedValue }}
            />
          </div>
        </>
      );
      break;
    }
    case 'radio': {
      htmlField = (
        <div
          className={classNames({
            'relative mt-[19px]': isSpectre,
          })}
        >
          <div
            className={classNames({
              'bg-[#2d2d2d] absolute left-[10px] -top-[8px] z-10 helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white': isSpectre,
              'text-[13px] leading-[19px] text-[#3C3C3C] mb-[5px] font-medium block mt-[10px]': !isSpectre,
            })}
          >
            {item.fieldName}
            {item.isRequired ? <span className={classNames({ 'text-red ml-[1px]': isSpectreForm })}>*</span> : ''}
          </div>
          <div className="">
            <Select
              isClearable
              options={dropDownOptionListState}
              onChange={e => {
                handleChange(e?.label, '', e);
              }}
              classNamePrefix={classNames('', {
                'custom-class reg-custom-field': isSpectre,
              })}
              className={classNames({
                'helvetica-neue-35-thin contact-form-select dropdown-style': isSpectre,
                'dropdown-style arrow-remove': !isSpectre,
              })}
            />
          </div>
        </div>
      );
      break;
    }
    case 'select': {
      htmlField = (
        <>
          <div
            className={classNames('text-[13px] leading-[19px] mb-[5px] font-medium block mt-[10px]', {
              ' text-white': isSpectre,
              ' text-[#3C3C3C]': !isSpectre,
            })}
          >
            {item.fieldName}
            {item.isRequired ? <span className={classNames({ 'text-red ml-[1px]': isSpectreForm })}>*</span> : ''}
          </div>
          <div className="">
            {item.registrationOptionFields?.map((a, index) => (
              <div key={index}>
                <InputField
                  item={item}
                  optionName={a.optionName}
                  type="checkbox"
                  selectedValue={selectedValue}
                  className={classNames('bg-transparent border border-[#203C6E] w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-0', {
                    ' text-white': isSpectre,
                  })}
                  onChange={(e: any) => handleChange(e, a.optionName)}
                  isSpectre={isSpectre}
                />
              </div>
            ))}
          </div>
        </>
      );
      break;
    }
    case 'terms-and-conditions': {
      htmlField = (
        <div className="flex row items-start gap-1 mt-2.5">
          <InputField
            item={item}
            type="checkbox"
            className="bg-white border border-blue-2  rounded w-4 h-4 mt-[1px] flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-0"
            selectedValue={selectedValue}
            onChange={(e: any) => handleChange(e)}
          />
          <label
            className={classNames('flex text-[13px] leading-[19px] mb-[0px] font-medium', {
              'text-white': isSpectre,
              'text-[#3C3C3C]': !isSpectre,
            })}
            htmlFor={item.id}
          >
            <ParseContent
              className={classNames('text-[13px] leading-[19px] mb-[1px] font-medium block', {
                'text-white': isSpectre,
                'text-[#3C3C3C]': !isSpectre,
              })}
              content={item.fieldName ?? ''}
            />
            {item.isRequired ? <span className={classNames({ 'text-red ml-[1px]': isSpectreForm })}>*</span> : ''}
          </label>
        </div>
      );
      break;
    }
    case 'phone': {
      htmlField = (
        <div
          className={classNames('flex justify-start', {
            'mt-[15px] mb-1': isSpectre,
            'mt-[10px]': !isSpectre,
          })}
        >
          <div
            className={classNames('text-left w-full', {
              'flex relative': isSpectre,
              'w-full': !isSpectre,
            })}
          >
            <label
              className={classNames({
                'bg-[#2d2d2d] z-[1] absolute left-[10px] -top-[9px] helvetica-neue-55-roman !px-[5px] !text-[11px] !text-white': isSpectre,
                'text-[13px] leading-[19px] text-[#3C3C3C] mb-[5px] font-medium block': !isSpectre,
              })}
              htmlFor={item.id}
            >
              {item.fieldName}
              {item.isRequired ? ' ' : ''}
            </label>
            <PhoneInput
              enableSearch
              containerClass={classNames('', {
                // eslint-disable-next-line max-len
                'helvetica-neue-35-thin w-full !h-[35px] !rounded-10 bg-transparent border-1 border-[#8f8f8f] placeholder:text-[15px] text-[15px] text-white px-[15px] focus-visible:outline-none phone-field-style phone-field-spectre': isSpectre,
                'phone-field-style': !isSpectre,
              })}
              value={selectedValue}
              onChange={(e: any) => handleChange(e)}
            />
          </div>
        </div>
      );
      break;
    }
    case 'postal-code': {
      htmlField = (
        <div className="flex justify-start mt-[10px]">
          <div className="text-left w-full">
            <span>
              <label className="normal-case text-[13px] leading-[19px] text-[#3C3C3C] mb-[5px] font-medium block tracking-wide" htmlFor={item.id}>
                {item.fieldName}
                {item.isRequired ? <span className={classNames({ 'text-red ml-[1px]': isSpectreForm })}>*</span> : ''}
              </label>
            </span>
            <InputField
              item={item}
              type="number"
              className="w-full font-extralight text-gray-12 px-2.5 rounded-10 text-sm1 border h-[31px] border-[#CDDCF2] text-number-filed"
              selectedValue={selectedValue}
              onChange={(e: any) => handleChange(e)}
              disabled={disabled}
            />
          </div>
        </div>
      );
      break;
    }
    default: {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
  }
  return htmlField;
};

export default DynamicallyFieldRegistration;
