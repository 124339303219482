import React, { FC, HTMLAttributes, useState } from 'react';

import ReactModal from 'react-modal';

// this code needs to be implemented to prevent an error
// Modal.setAppElement('#root)')

export interface IModalProps extends HTMLAttributes<HTMLButtonElement> {
  isOpen: boolean;
  onCloseModal: () => void;
  children: React.ReactNode;
  OpenModalbutton?: string;
  buttonImageUrl?: string;
  openModalText?: string;
  modalWidth?: string;
  modalHeight?: string;
  overlayColor?: string;
  primaryButton?: string;
  SecondaryButton?: string;
  primaryButtonText?: string;
  SecondaryButtonText?: string;
  modalImage?: string;
}

export const Modal: FC<IModalProps> = (props: IModalProps) => {
  const [showModal, setShowModal] = useState(props.isOpen);

  return (
    <div className="modal">
      <button className={props.OpenModalbutton} onClick={() => setShowModal(true)}>
        {props.buttonImageUrl ? <img src={props.buttonImageUrl} alt="" /> : props.openModalText}
      </button>
      <ReactModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={{
          overlay: {
            backgroundColor: props.overlayColor
          },
          content: {
            width: props.modalWidth,
            height: props.modalHeight,
            left: '50%',
            transform: 'translateX(-50%)'
          }
        }}
      >
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div>
              <h1>{props.title}</h1>
            </div>
            <div className="">
              <button className="rounded-md border bg-close-icon hover:bg-close-hover bg-no-repeat border-transparent px-4 w-7 h-8 text-sm font-medium outline-none" onClick={props.onCloseModal}></button>
            </div>
          </div>
          <div className="flex flex-col items-center text-center">
            {props.modalImage && <img alt="" className="w-20 =h-20 mt-6" src={props.modalImage} />}
          </div>
          {props.children && <div className="flex flex-col items-start">{props.children}</div>}
          <div className="flex justify-end">
            <div className="absolute bottom-2 -mr-2 space-x-2">
              {props.primaryButton && (
                <button className={props.primaryButton} onClick={() => setShowModal(false)}>
                  {props.primaryButtonText}
                </button>
              )}
              <button className={props.SecondaryButton} onClick={() => setShowModal(false)}>
                {props.SecondaryButtonText}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};
