import {
  ActionCreator,
  Dispatch,
} from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IPostsResult } from '../../../models/post/PostsResult';
import { IPostsState } from '../../../models/post/PostsState';
import { PostService } from '../../../services/Post';
import { UpcomingEventPostsActionTypes } from './types';

export interface IUpcomingEventPostsLoadingAction {
  type: UpcomingEventPostsActionTypes.UPCOMING_EVENT_POSTS_LOADING;
  loading: boolean;
}

export interface IUpcomingEventPostsSuccessAction {
  type: UpcomingEventPostsActionTypes.UPCOMING_EVENT_POSTS_SUCCESS;
  payload: IPostsResult;
}

export interface IUpcomingEventPostsErrorAction {
  type: UpcomingEventPostsActionTypes.UPCOMING_EVENT_POSTS_ERROR;
  errorMessage: string;
}

export type UpcomingEventPostsActions =
  | IUpcomingEventPostsLoadingAction
  | IUpcomingEventPostsSuccessAction
  | IUpcomingEventPostsErrorAction;

export const getUpcomingEventPosts: ActionCreator<
  ThunkAction<Promise<any>, IPostsState, null, IUpcomingEventPostsSuccessAction>
> = (
  eventIdOrSlug: string,
  page: number = 1,
  orderBy: string = "Most recent"
) => {
  return async (dispatch: Dispatch) => {
    const postsService: PostService = new PostService();

    try {
      let sortBy: string = "";
      if (orderBy === "Most recent") sortBy = "&order[createdAt]=DESC";
      else sortBy = "&order[rank]=DESC";

      const result = await postsService.getUpcomingEventPosts(
        eventIdOrSlug,
        page,
        sortBy
      );

      dispatch({
        type: UpcomingEventPostsActionTypes.UPCOMING_EVENT_POSTS_SUCCESS,
        payload: result,
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: UpcomingEventPostsActionTypes.UPCOMING_EVENT_POSTS_ERROR,
        errorMessage: error.message,
      });
    } finally {
      dispatch({
        type: UpcomingEventPostsActionTypes.UPCOMING_EVENT_POSTS_LOADING,
        loading: false,
      });
    }
  };
};

export const loadUpcomingEventPosts: ActionCreator<
  ThunkAction<any, IPostsState, null, IUpcomingEventPostsLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: UpcomingEventPostsActionTypes.UPCOMING_EVENT_POSTS_LOADING,
    loading: shouldLoad,
  });
