import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { getCompanyName } from 'src/utils';
import { Modal } from '../../../../shared-components';
import { IEvent } from '../../../../models/event/event';
import { EventsService } from '../../../../services/events';
import ActionOfCardEvent1 from './ActionOfCardEvent1';
import { IUserProfile } from '../../../../models/user-profile';
import { EventCoverAndLogo } from '../../../../components/event-cover-and-logo';
import { Button } from '../../../../shared-components/V2';

export interface ICardEventProps {
  coverUrl: string;
  avatarUrl: string;
  title: string;
  subTitle: string;
  regions: string;
  eventType: string;
  eventLink: string;
  detailsHref?: string;
  containerClass?: string;
  event: IEvent;
  user: IUserProfile;
  isSimilarEvents?: boolean;
}

export const CardEvent: FC<ICardEventProps> = ({
  coverUrl,
  avatarUrl,
  title,
  subTitle,
  regions,
  eventType,
  eventLink,
  // eslint-disable-next-line no-unused-vars
  detailsHref,
  containerClass,
  event,
  user,
  isSimilarEvents
}) => {
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleAlert, setToggleAlert] = useState(false);
  const navigate = useNavigate();

  const handleJoin = () => {
    new EventsService().getEventsAttendeeStatus(event.id).then(res => {
      const eventAttendeeStatus = res.data.audienceStatus;
      if (eventAttendeeStatus === 'accepted') navigate(`/${getCompanyName(event)}/${event.slug}/live`);
      else if (eventAttendeeStatus === 'pending') {
        setToggleAlert(true);
        setToggleModal(true);
      } else {
        setToggleAlert(false);
        setToggleModal(true);
      }
    });
  };

  const handleRegister = () => {
    navigate(`/event/${event.slug}/discussion?from=live-register`);
  };

  const onCloseModal = () => setToggleModal(false);

  return (
    <div
      className={classNames('create-post-card w-[245px] h-full', {
        [`${containerClass}`]: containerClass,
      })}
    >
      <div className="">
        <div>
          <Link to={eventLink} id="coverOFEvent">
            <EventCoverAndLogo
              cover={coverUrl}
              logo={avatarUrl}
              coverClassName="mx-auto bg-cover bg-center rounded-t-15 w-[263px] h-[102px] border-gray-0 bg-gray-50 drop-shadow-none"
              logoClassName={classNames('!w-[57px] !h-[57px] small-radius top-[26px] bg-[#edf2f5]', {
                '!ml-[10px]': isSimilarEvents,
              })}
              logoStyle="w-[49px] h-[49px] small-radius"
            />
          </Link>
        </div>
      </div>

      <div className="px-[14px] pt-2.5 pb-3 bg-white rounded-b-15 h-fit">
        <div className="text-left h-[80px]">
          {/* <span className="inline-block rounded-full font-regular bg-orange-3  text-sm5 w-[80px] h-[22px] text-center py-1 text-blue-3 mb-2">
            LIVE NOW
          </span> */}
          <div className="">
            <Link to={eventLink} data-test-id={`${eventLink}`}>
              <div className="text-[13px] line-clamp-2 capitalize font-semibold leading-[17px] text-[#172746]">
                {title}
              </div>
            </Link>
            <div className="text-sm5 line-clamp-1 capitalize font-normal text-[#3C3C3C]">
              {subTitle}
            </div>
            <div className="text-sm5 line-clamp-1 capitalize font-normal text-[#3C3C3C] leading-[18px]">
              {regions}
            </div>
            <div className="text-[12px] font-normal text-[#172746] leading-[18px] line-clamp-1">
              {eventType}
            </div>
            {/* <Typography variant="StepSubTitle" text={eventType} /> */}
          </div>
        </div>
        <div className="flex text-center justify-between pb-0 relative">
          <div>
            <Button
              text="Register now"
              className="w-[124px] text-white bg-[#0049EF] hover:bg-[#2e6eff] rounded-[5px] inline-block text-sm font-semibold h-8 "
              type="button"
              onClick={() => handleJoin()}
            />
          </div>
          {toggleModal && (
            <Modal
              isOpen={toggleModal}
              onCloseModal={onCloseModal}
              title={
                toggleAlert
                  ? 'We’ll notify you once your registration has been accepted.'
                  : 'You need to register to attend this live event!'
              }
              modalWidth="300px"
              modalHeight="150px"
              overlayColor="rgba(70, 70, 70, 0.5)"
            >
              <>
                {toggleAlert && (
                  <div className="text-center flex justify-center">
                    <div className="flex items-center mt-3">
                      <button
                        type="button"
                        onClick={onCloseModal}
                        className="bg-gray-200 mt-2 w-32 h-8 text-sm mr-7 px-6 py-1.5 rounded-full text-blue-3 font-semibold center justify-center whitespace-no-wrap"
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                )}
                {!toggleAlert && (
                  <div className="text-center flex justify-center">
                    <div className="flex items-center mt-3">
                      <button
                        type="button"
                        onClick={handleRegister}
                        className="rounded-[5px] inline-block text-sm font-[600] h-30 text-white bg-[#0049EF] hover:bg-[#2e6eff] px-4 mr-2"
                      >
                        Register now
                      </button>
                      <button
                        type="button"
                        onClick={onCloseModal}
                        className="bg-gray-1 hover:bg-gray-7 active:bg-gray-7 h-30 rounded-[5px] tracking-wide text-blue-2 text-sm1 font-semibold px-4"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </>
            </Modal>
          )}
          <div className=" absolute right-0 top-[7px]">
            <ActionOfCardEvent1 event={event} user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};
