import { format } from "date-fns";
import { groupBy } from "lodash";
// import { IRoom } from "../models/event/room";

export const makeRoomsTreeLobby = (rooms: any) => {
  const myRoomsArr: any = rooms;
  const filteredArr = groupBy(myRoomsArr, "eventDay");
  const roomsTreeArr: any = [];
  Object.keys(filteredArr).forEach((key, index) => 
    roomsTreeArr[index] = { date: format(new Date(key), "yyyy-MM-dd"), roomsList: filteredArr[key] }
  );
  return roomsTreeArr;
};
