import Cookies from 'universal-cookie';
import { Loader } from 'src/shared-components/Loader';
import { Navbar } from '../../components';
import ConnectingProfessionals from './connecting-professionals';
// import CreateAndHostEvents from './create-and-host-events';
// import EventAppComponent from './event-app';
import FieldsOfInterest from './fields-of-interest';
import { Footer } from './footer';
// import UpcomingEvents from './upcoming-events';
// import YourAudience from './your-audience';
import ContactUs from './ContactUs';
import TestimonialSection from './testimonial-sec';

export const Home = () => {
  const cookies = new Cookies();
  if (cookies.get(process.env.REACT_APP_TOKEN as string)) return <Loader isLoaderLogo className="h-[100vh]" key="loader" />;
  return (
    <>
      <Navbar />
      <div className="mx-auto bg-[#EDF2F5] overflow-hidden">
        <div className="mx-auto">
          <div className="flex flex-col">
            <ConnectingProfessionals />
            <FieldsOfInterest />
            {/* <UpcomingEvents />
          <CreateAndHostEvents />
          <EventAppComponent />
          <YourAudience /> */}
            <ContactUs />
            <TestimonialSection />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
