// import bannerImage2 from '../../../assets/images/event-live/event-live-banner@2x.png';
import bannerImage2 from '../../assets/images/event-live/event-live-banner@2x.png';

export const Banner = () => (
  <div className="w-full">
    <div className="block md:flex">
      <div className="w-screen">
        <img src={bannerImage2} alt="" className="object-cover w-full" />
        <div className="container mx-auto">
          <div className="2xl:ml-5 -ml-4 -mt-28 md:-mt-288 xl:-mt-392 ">
            <div className="block mx-auto w-11/12 ml-16 md:ml-32 lg:ml-16">
              <div className="md:flex justify-start  md:w-2/5">
                <div className="block md:flex text-left lg:ml-5 pl-1 md:mb-20 w-8/12">
                  <div className="">
                    <p className="lg:text-xl6 2xl:text-xl7a font-extralight text-lg text-blue-2">
                      About Panelist
                    </p>

                    <p className="lg:text-lg4 text-sm font-extralight text-blue-4 mt-5 text-left w-8/12 md:w-11/12">
                      Panelist. The world's most complete professional engagement platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Banner;
