import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { IChatListItemProps } from "../../models/messages/message";
import { Avatar } from "../../shared-components/V2";
import { IAppState } from "../../store";
import { addToChatHeads } from "../../store/chat-heads";
import { openConversation } from "../../store/oneToOneConversation";
import profileBackground from "../../assets/images/avatar-default.svg";
import { displayPartOfMessage, imageUrlPrefix } from "../../utils";
import { getDistanceBetweenTwoDateTime } from "../../utils/datetime";
import { useWebSocket } from "../../utils/hooks";
import { ParseContent } from "../../utils/parse-html";
import MessageBadge from "./message-badge";
import { IconButton } from "@mui/material";
import deleteIcon from "../../assets/images/trash.svg";

export const ChatListItem = (props: IChatListItemProps) => {
  const { conversation, currentUser, onClick } = props;
  const dispatch = useDispatch();

  const [avatar, setAvatar] = useState<string>(profileBackground);
  const [userFullName, setUserFullName] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [unseenMessagesCount, setUnseenMessagesCount] = useState<number>(0);
  const userNotify = useSelector(
    (state: IAppState) => state.privateChat.userNotify
  );

  useEffect(() => {
    const participantProfile = conversation.participantProfiles.find(
      (participant: any) => participant?.id !== currentUser
    );
    if (participantProfile?.avatar) {
      setAvatar(`${imageUrlPrefix}/${participantProfile?.avatar}`);
    }
    setUserFullName(
      `${participantProfile?.firstName} ${participantProfile?.lastName}`
    );
    setUserId(participantProfile?.id!);
  }, [currentUser, conversation.participantProfiles, avatar]);

  useEffect(() => {
    const unseenCount = userNotify?.unseenMessages.find(
      (a: any) => a.conversationId === conversation.conversationId
    )?.total;
    unseenCount
      ? setUnseenMessagesCount(unseenCount)
      : setUnseenMessagesCount(0);
  }, [userNotify, conversation.conversationId]);

  const ellipsify = (str: string) => {
    return displayPartOfMessage(str, 15);
  };

  const { privateChat } = useSelector((state: IAppState) => state);
  const userStatus = privateChat.checkUserOnline?.checkedResult;

  const [isActive, setIsActive] = useState(false);

  const { webSocket } = useWebSocket();

  const checkUserOnlineStatus = () => {
    webSocket.then((ws) =>
      ws.send(
        JSON.stringify({
          action: "check-user-online",
          data: {
            userIds: userId,
          },
        })
      )
    );
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (userId) {
      checkUserOnlineStatus();
      intervalId = setInterval(() => {
        checkUserOnlineStatus();
      }, 300000);
    }

    return () => clearInterval(intervalId);
  }, [userId, webSocket]);

  useEffect(() => {
    if (userStatus && userStatus.length > 0 && userStatus[0].userId) {
      const status = userStatus?.some(
        (user) => user.active === true && user.userId === userId
      );
      setIsActive(status);
    }
  }, [userStatus, userId]);

  const handleDeleteConversation = () => {
    webSocket.then((ws) => {
      if (ws.readyState === ws.OPEN) {
        ws.send(
          JSON.stringify({
            action: "remove-conversation",
            data: {
              conversationId: conversation?.conversationId,
            },
          })
        );
      }
    })
  };

  return (
    <div
      className="bg-white message-item flex flex-row items-center w-full border-b border-blue-0 pb-2 pt-1 hover:bg-gray-1 px-1 last:border-b-0 hover:border-l-8 hover:border-blue-1 hover:border-b-blue-0 border-l-white border-l-8 pl-2 pr-1 cursor-pointer"
      onClick={() => {
        const chatHeadItem = {
          conversationId: conversation.conversationId,
          avatar: avatar,
          username: userFullName,
          userId,
        };
        dispatch(addToChatHeads(chatHeadItem));

        dispatch(openConversation(chatHeadItem));
        onClick();
      }}
    >
      <div className="">
        <Avatar
          size="MEDIUM"
          src={avatar}
          alt=""
          status={isActive ? "ONLINE" : "OFFLINE"}
          // TODO: Set the status through WS
          // status='ONLINE'
        />
      </div>
      <div className="flex flex-col ml-2 w-full pr-[25px]">
        {/* <div className="flex w-full justify-between items-top"> */}
          <div className="text-sm7 font-medium text-gray-1">{userFullName}</div>
          {/* <IconButton
            style={{
              cursor: "pointer",
              textAlign: "right",
              width: "25px",
              height: "25px",
            }}
            onClick={() => handleDeleteConversation()}
          >
            <img src={deleteIcon} alt="Remove" className="w-4 h-4 mt-2 mr-1" />
          </IconButton> */}
        {/* </div> */}
        <div className="flex flex-row">
          <div className="text-sm1 text-gray-1 font-extralight font-[400]">
            <ParseContent
              content={ellipsify(conversation.latestMessage)}
              className="truncate"
            />
          </div>
          {unseenMessagesCount > 0 && (
            <MessageBadge
              countUnseenMessages={unseenMessagesCount}
              classes={"ml-1 mt-0.5"}
            />
          )}
        </div>
        <p className="text-blue-1 text-s-10 font-medium">
          {!!conversation?.latestMessageDetails &&
            getDistanceBetweenTwoDateTime(
              new Date(),
              conversation?.latestMessageDetails?.replyDate
            )}
        </p>
      </div>
    </div>
  );
};
