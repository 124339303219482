import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { IConnectStatus } from '../../models/connection';
import { Avatar } from '../../shared-components/V2';
import { closeMessageWindow, openMessageWindow } from '../../store/newMessage';
import { useCurrentUser } from '../../utils/hooks';

type ConnectAndMessageProps = {
  popover?: boolean;
  userId: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  jobTitle?: string;
  companyName?: string;
  connectStatus?: IConnectStatus;
  onClickConnect?: () => void;
};

const ConnectAndMessage = (props: ConnectAndMessageProps) => {
  const dispatch = useDispatch();
  const loggedInUser = useCurrentUser();

  const send = async () => {
    await dispatch(closeMessageWindow());
    await dispatch(
      openMessageWindow({
        avatar: props.avatar!,
        userDisplayName: `${props.firstName} ${props.lastName}`,
        userId: props.userId
      })
    );
  };

  const renderFirstButton = () => {
    if (props.connectStatus?.hasRequest === 1) {
      return (
        <button className="bg-blue px-6 w-28 py-2 text-white text-sm font-bold rounded-3xl">
          Accept
        </button>
      );
    } else if (props.connectStatus?.sentRequest === 1) {
      return (
        <button className="bg-blue px-6 w-28 py-2 text-white text-sm font-bold rounded-3xl">
          Pending
        </button>
      );
    } else if (!props.connectStatus || !props.connectStatus?.connectionId) {
      return (
        <button onClick={props.onClickConnect} className="bg-blue w-28 px-6 py-2 text-white text-sm font-bold rounded-3xl">
          Connect
        </button>
      );
    } else if (props.connectStatus?.sentRequest === 0 && props.connectStatus?.hasRequest === 0) {
      return (
        <></>
      )
    }
  };

  const renderSecondButton = () => {
    if (props.connectStatus?.hasRequest === 1) {
      return (
        <button className="bg-gray-1 px-6 w-28 py-2 text-blue-2 text-sm font-bold rounded-3xl">
          Reject
        </button>
      );
    } else if (props.connectStatus?.connectionId && props.connectStatus?.connectionId !== '') {
      return (
        <button
          className="bg-gray-1 px-6 py-2 w-28 text-blue-2 text-sm font-bold rounded-3xl"
          onClick={() => send()}>
          Message
        </button>
      );
    } else {
      return (
        <button
          className="bg-gray-1 px-6 py-2 text-blue-2 text-sm font-bold rounded-3xl"
          onClick={() => send()}>
          Message
        </button>
      );
    }
  };

  return (
    <div className="grid gap-2 w-full">
      <div className="flex gap-2 items-center">
        <Link to={`/wall/${props.userId}`}>
          <Avatar
            alt=""
            src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${props.avatar}`}
            size="MEDIUM"
          />
        </Link>
        <div className="grid">
          <span className="text-blue-2 text-sm font-bold">
            <Link to={`/wall/${props.userId}`}>
              {props.firstName} {props.lastName}
            </Link>
          </span>
          <span className="text-gray-1 text-h3">{props.jobTitle}</span>
          <span className="text-gray-1 text-h3">{props.companyName}</span>
        </div>
      </div>
      {loggedInUser.id !== props.userId && (
        <div className="flex justify-between align-center">
          {renderFirstButton()}
          {renderSecondButton()}
        </div>
      )}
    </div>
  );
};

export default ConnectAndMessage;
