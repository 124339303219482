import { Container } from '@mui/material';

export const FooterBottomSection = ( ) => {
  const socialIconLinks = [
    { name: 'Stiemap', link: 'https://www.box.com/resources/sitemap' },
    { name: 'Subscriptions', link: 'https://privacyportal.onetrust.com/ui/#/preferences/multipage/login/685042b8-d651-43f5-a7ce-ac0885d29588' },
    { name: 'Terms of Service', link: 'https://www.box.com/legal/termsofservice' },
    { name: 'Privacy Policy', link: 'https://www.box.com/legal/privacypolicy' },
    { name: 'Do Not Sell My Info', link: 'https://www.box.com/legal/regionalnotice' },
  ]
  return (
    <Container
        maxWidth={false}
        sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        maxWidth: '100%',
        p: 0,
        pl: '15px !important',
        pr: '15px !important',
        '@media (min-width: 768px)': { maxWidth: '100%', pl: '2.125rem', pr: '2.125rem' },
        '@media (min-width: 992px)': { maxWidth: '100%', pl: '2.125rem', pr: '2.125rem' },
        '@media (min-width: 1200px)': { maxWidth: '100%', pl: '2.125rem', pr: '2.125rem' },
        '@media (min-width: 1440px)': { maxWidth: '74.4680851064%' },
        }}
    >
      <div className="flex items-center flex-wrap gap-y-[20px] justify-start gap-x-[4.125rem] py-[2.875rem] border-t border-[rgba(255,255,255,0.1)]">
        <div className="text-[#8ea6b2] text-[13px]">©{new Date().getFullYear()} Box </div>
        {socialIconLinks?.map((item, index) => (
          <a
            key={index}
            rel="noreferrer"
            target="_blank"
            href={item.link}
            className="hover:underline cursor-pointer text-[#8ea6b2] text-[13px]"
          >
            {item.name}
          </a>
        ))}
      </div>
    </Container>
  );
};
