/* eslint-disable max-len */
import classNames from 'classnames';
import { useMemo } from 'react';
import { IEventSession } from '../../../models/event/event-session';
import { Logo } from '../../../shared-components/V2/Logo';
import { dateAndTimeWithTz } from '../../../utils/date-timezone';
import { getTimeZone } from '../../../utils/timezones';
import WebsiteEventPartnerList from './website-event-day-partnerlist';
import WebsiteEventSpeakerList from './website-event-day-speakerlist';
import { ParseContent } from './parse-html';
import Avatar from '@mui/material/Avatar';
import { getJobTitle, getSpeakerName } from 'src/utils';

interface IWebsiteEventCard {
  roomQty?: number;
  session: IEventSession;
  event?: any;
  setSelectedSessoin?: (value: any) => void,
  setIsSessionModal?: (value: boolean) => void,
}

interface ISessionModal {
  session: any;
  event?: any;
}

export const SessionModal = ({
  session, event
}: ISessionModal) => {

  const getSpeakerCompany = (item: any) => {
    if (
      item?.eventSpeakers?.[0]?.firstName &&
      item?.eventSpeakers?.[0]?.lastName
    ) {
      return item?.eventSpeakers?.[0]?.company
    }

    return item?.company
  };

  return (
    <div>
      <div className="flex items-center">
        <div className="w-[137px] h-[78px] mr-[20px]">
          <div className='feed-card' style={{ width: '100%', maxWidth: '137px', height: '78px' }}>
            <div
              style={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL_PREFIX}/${session?.eventSpeakers[0]?.photo ? session?.eventSpeakers[0]?.photo : event?.company?.logo})` }}
              className='feed-bg-blur'
            />
            <Avatar
              className="spotlight-card-media"
              alt={session?.firstName}
              src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${session?.eventSpeakers[0]?.photo ? session?.eventSpeakers[0]?.photo : event?.company?.logo}`}
              sx={{
                maxHeight: '78px',
                height: '78px',
                width: '100%',
                borderRadius: '0px',
                objectFit: 'contain',
                "& .MuiAvatar-img": {
                  objectFit: 'contain',
                  objectPosition: 'center'
                }
                // backgroundColor: '#edf3f2'
              }}
            />
          </div>
          {/* <Logo
            src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${session?.eventSpeakers[0]?.photo ? session?.eventSpeakers[0]?.photo : event?.company?.logo}`}
            className={classNames('w-full h-full object-cover bg-black rounded-5', {
              'p-4 !object-contain': !session?.eventSpeakers[0]?.photo
            })}
          /> */}
        </div>
        <div className="flex flex-col w-[30vw]">
          <div className="text-[20px] text-[#4d144a] mb-[3px] line-clamp-1 leading-[25px] font-semibold">
            <span className="text-[20px] text-[#4d144a]">

              {getSpeakerName(session)}
            </span>
          </div>
          <div className="text-[20px] text-[#4d144a] leading-[25px] mb-[0px] line-clamp-3"> {getJobTitle(session)}</div>
        </div>
      </div>
      {session?.eventSpeakers[0]?.bio && (
        <div className="mt-[35px] mb-2">
          <div className="text-[18px] text-[#212529] leading-[24px] mb-[10px]">
            <ParseContent content={session?.eventSpeakers[0]?.bio} fullText className="text-[18px] text-[#212529] leading-[22px] mb-[0px] helvetica-neue-45-light !max-h-[185px] !overflow-y-auto custom-scroll-default" />
          </div>
        </div>
      )}
      <div className="flex">
        {/* <div
          className={classNames({
            'mt-6': session?.eventSpeakers.length > 0,
            hidden: session?.eventSpeakers.length === 0
          })}
        >
          <WebsiteEventSpeakerList speakers={session?.eventSpeakers} />
        </div> */}
        <div
          className={classNames({
            'mt-6': !session?.eventSpeakers[0]?.bio,
          })}
        >
          <WebsiteEventPartnerList sponsors={getSpeakerCompany(session)} />
        </div>
      </div>
    </div>
  );
};

const WebsiteEventCard = ({
  roomQty, session, event, setSelectedSessoin, setIsSessionModal
}: IWebsiteEventCard) => {
  const numbers = [];
  const eventDateTimeWithTz = useMemo(() => {
    const startTime = dateAndTimeWithTz(session?.startTime, event?.timezone);
    const endTime = dateAndTimeWithTz(session?.endTime, event?.timezone);
    return {
      startTime,
      endTime
    };
  }, [event?.timezone, session?.endTime, session?.startTime]);

  const getTimeZoneName = (zone: string) => {
    const shortTimeZone = getTimeZone(zone)?.[0]?.abbr ? getTimeZone(zone)?.[0]?.abbr : zone;
    return shortTimeZone;
  };

  // Loop to generate the sequence
  for (let i = 1; i <= 100; i++) {
    numbers.push(i * 3);
  }

  const onSessionClick = (session: any) => {
    if (setSelectedSessoin) setSelectedSessoin(session);
    if (setIsSessionModal) setIsSessionModal(true);
  };

  return (
    <div
      className={classNames('flex items-center w-[440px] cursor-pointer', {
        'mb-[25px]': !numbers.includes(roomQty!)
      })}
      onClick={() => onSessionClick(session)}
    >
      <div className="w-[137px] min-w-[137px] h-[78px] rounded-5">
        <Logo
          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${session?.eventSpeakers[0]?.photo ? session?.eventSpeakers[0]?.photo : event?.company?.logo}`}
          className={classNames('w-full h-full object-cover bg-black rounded-5', {
            'p-4 !object-contain grayscale': !session?.eventSpeakers[0]?.photo
          })}
        />
      </div>
      <div className="text-[20px] px-[30px] font-[700] text-[#7c7c7c] helvetica-neue-65-medium">{roomQty}</div>
      <div className="relative xl:w-full h-[64px]">
        <div className="text-white text-[15px] tracking-tight leading-[15px] mb-[5px] line-clamp-1 helvetica-neue-55-roman">{session?.title}</div>
        <div className="text-white text-[14px] tracking-tight mb-[0px] line-clamp-1 helvetica-neue-55-roman leading-[15px]">
          <span className="text-white text-[14px] tracking-tight">
            {eventDateTimeWithTz?.startTime?.time}
          </span>
          -
          <span className="text-white text-[14px] mr-[3px] helvetica-neue-55-roman">
            {eventDateTimeWithTz?.endTime?.time}
          </span>
          {getTimeZoneName(event?.timezone)}
        </div>
        <div className="text-[#767676] text-[15px] tracking-tight mt-[9px] line-clamp-1 helvetica-neue-65-medium leading-[16px]">
          {session?.eventSpeakers[0]?.firstName}
          {' '}
          {session?.eventSpeakers[0]?.lastName}
        </div>
        {numbers.includes(roomQty!) ? null : <div className="h-[1px] bg-[#424242] w-full absolute left-0 -bottom-[20px] helvetica-neue-45-light" />}
      </div>
    </div>
  );
};

export default WebsiteEventCard;
