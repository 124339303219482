import { format } from "date-fns";
import { groupBy } from "lodash";
import { IRoom } from "../models/event/room";

type EventRoom = IRoom;
type EventRoomsList = EventRoom[];
type EventRoomsTreeNode = EventRoom | EventRoomsList;
export type EventRoomsTree = EventRoomsTreeNode[];

export type Room = {
  eventDay?: string; // Make `eventDay` optional to match the `IRoom` type
  [key: string]: any; // Allows additional properties
};

export const makeRoomsTree = (rooms: EventRoom[]) => {
  const myRoomsArr: any = rooms;
  const filteredArr = groupBy(myRoomsArr, "eventDay");
  const roomsTreeArr: any = [];
  Object.keys(filteredArr).forEach((key, index) => {
    if (key !== '0000-00-00' ) {
      roomsTreeArr[index] = { date: key && key !== "undefined" ? format(new Date(key ? key : ''), "yyyy-MM-dd") : '', roomsList: filteredArr[key] }
    }
  }
  );
  return roomsTreeArr;
};

const formatEventDate = (dateString: string): string => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(date);
};

export const groupAndSortRoomsByDays = (rooms: Room[] | undefined) => {
  if (!rooms || rooms.length === 0) return {};

  // Group rooms by `eventDay`
  const grouped = rooms.reduce((acc: Record<string, Room[]>, room) => {
    
    if (!room?.eventDayStartTime) return acc;
    const formattedDate = formatEventDate(room?.eventDayStartTime);
    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push(room);
    return acc;
  }, {});

  const sortedDays = Object.keys(grouped).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );

  const sortedGrouped = sortedDays.reduce((acc: Record<string, Room[]>, day) => {
    acc[day] = grouped[day];
    return acc;
  }, {});

  return sortedGrouped;
};

export const getVerticalRooms = (rooms: any) => rooms?.reduce((acc: any, room: any) => {
  const key = room.verticalOrdering;
  if (!acc[key]) {
    acc[key] = [];
  }
  acc[key].push(room);
  return acc;
}, {});

export const groupByTags = (data: any) => {
  const tagMap = {} as any;

  data?.forEach((item: any) => {
    item?.sessions?.forEach((session: any) => {
      session?.tags?.forEach((tag: any) => {
        if (!tagMap[tag]) {
          tagMap[tag] = { key: tag, sessions: [] };
        }
        tagMap[tag].sessions.push({ ...session, parentId: item.id });
      });
    });
  });

  return Object.values(tagMap);
};
