import { useDropzone } from 'react-dropzone';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { CircularProgress, Typography } from '@mui/material';
//
import { UploadProps } from './types';
import AddSpeakerIcon from 'src/shared-components/V2/IconSvg/AddSpeakerIcon';

// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({ theme }) => ({
  height: '400px !important',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  '&:hover': {
    opacity: 0.72,
  },
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(3),
  borderRadius: '8px !important',
  transition: theme.transitions.create('padding'),
  backgroundColor: '#f7f7f8 !important',
  border: `1px dashed #e3e5e8 !important`,
}));

// ----------------------------------------------------------------------

export default function UploadBoxCustom({
  placeholder,
  error,
  disabled,
  accept,
  sx,
  loading,
  file,
  hideAddIcon,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    disabled,
    accept,
    ...other,
  });
  const isError = isDragReject || error;
  return (
    <StyledDropZone
      {...(!hideAddIcon ? getRootProps() : null)}
      sx={{
        ...(isDragActive && {
          opacity: 0.72,
        }),
        ...(isError &&
          {
            // color: 'error.main',
            // bgcolor: 'error.lighter',
            // borderColor: 'error.light',
          }),
        ...(disabled && {
          opacity: 0.48,
          pointerEvents: 'none',
        }),
        ...sx,
      }}
    >
      <input {...getInputProps()} />
      {!hideAddIcon && !loading && (placeholder || <AddSpeakerIcon />)}
      {loading && <CircularProgress color="inherit" size={28} />}
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Drop files here or click
        <Typography
          variant="body2"
          component="span"
          sx={{
            mx: 0.5,
            color: 'primary.main',
            textDecoration: 'underline',
          }}
        >
          browse
        </Typography>
        through your machine
      </Typography>
    </StyledDropZone>
  );
}
