import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReactSelectOption } from '../../models/react-select-option';
import { IAppState } from '../../store';
import { getIndustries } from '../../store/industries/actions';
import { UseFormRegister } from 'react-hook-form';
import { LOGIN_SIGNUP_RECOVERY } from '../../constants';
import Select from 'react-select';
import classNames from 'classnames';

interface IndustrySelectorProps {
  onChange: Function;
  register: UseFormRegister<any>;
  selectedIndustryStateToStore: any;
  classes?: string;
  isBorderLess?: boolean;
  isSpectre?: boolean;
  customStyleCss?: string;
}

const customStyles = {
  option: (provided: any, state: { isSelected: any; }) => ({
    ...provided,
    // borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: '100%',
    border: '1px solid #8f8f8f',
    height: '35px',
    borderRadius: '10px',
    display: 'flex',
    color: 'white',
  }),
  singleValue: (provided: any, state: { isDisabled: any; }) => {
    const opacity = state.isDisabled ? 1 : 1;
    const transition = 'opacity 300ms';
    const color = 'white';
    const fontSize = '15px';

    return { ...provided, opacity, transition, color, fontSize };
  }
}

const defaultStyle = {
  singleValue: (provided: any, state: { isDisabled: any; }) => {
    const opacity = state.isDisabled ? 1 : 1;
    const transition = 'opacity 300ms';
    const color = '#3C3C3C';
    const fontSize = '13px';

    return { ...provided, opacity, transition, color, fontSize };
  }
}

export const IndustrySelector = ({
  onChange,
  register,
  selectedIndustryStateToStore,
  classes,
  isBorderLess,
  isSpectre,
  customStyleCss
}: IndustrySelectorProps) => {
  const dispatch = useDispatch();
  const industries = useSelector((state: IAppState) => state.industries);

  const [industryListState, setIndustryListState] = useState<IReactSelectOption[]>();
  const [query, setQuery] = useState('');

  useEffect(() => {
    dispatch(getIndustries(query));
  }, [query, dispatch]);

  useEffect(() => {
    const mapIndustriesToSelectOption = () => {
      const industyList: IReactSelectOption[] = [];

      industries?.value?.data &&
        industries?.value?.data.map((item: any, index: number) =>
          industyList.push({
            key: `industry-${index}`,
            value: item.id,
            label: item.name
          })
        );
      setIndustryListState(industyList.filter((i) => i.label));
    };
    mapIndustriesToSelectOption();
  }, [industries]);

  const onChangeLocal = (event: any) => {
    onChange(event);
  };

  return (
    <Select
      id="industrySelector"
      className={classNames('dropdown-style', {
        [`${customStyleCss}`]: customStyleCss,
        "hide-border": isBorderLess,
        "show-arrow": !isSpectre,
      })}
      classNamePrefix={classNames({
        "custom-class": isSpectre,
      })}
      // menuIsOpen = {true}
      placeholder={"Select your industry"}
      options={industryListState}
      onInputChange={(a) => setQuery(a)}
      isSearchable={true}
      openMenuOnClick={true}
      {...register('industryId', {
        required: { value: true, message: LOGIN_SIGNUP_RECOVERY.INDUSTRY_ISREQUIRED }
      })}
      value={selectedIndustryStateToStore}
      onChange={(e: any) => onChangeLocal(e)}
      styles={isSpectre ? customStyles : defaultStyle}
    />
  );
};
