import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
    isActive?: boolean;
}

function MeetingsTabIcon({ fillColor, fillBgColor, isActive, ...props }: Props) {
    return isActive? (
        <svg xmlns="http://www.w3.org/2000/svg" width="30.466" height="25.502" viewBox="0 0 30.466 25.502">
          <g id="Group_29050" data-name="Group 29050" transform="translate(-7336.262 -5930.87)">
            <path id="clock-three" d="M6.6,0a6.6,6.6,0,1,0,6.6,6.6A6.606,6.606,0,0,0,6.6,0ZM8.8,7.148H6.6a.55.55,0,0,1-.55-.55V3.3a.55.55,0,1,1,1.1,0V6.049H8.8a.55.55,0,1,1,0,1.1Z" transform="translate(7353.531 5931.909)" fill={fillColor}/>
            <path id="users_active" data-name="users active" d="M9.563,15.476A5.738,5.738,0,1,1,15.3,9.738a5.738,5.738,0,0,1-5.738,5.738ZM17.852,29.5H1.275A1.275,1.275,0,0,1,0,28.227V27.59a9.563,9.563,0,1,1,19.127,0v.638A1.275,1.275,0,0,1,17.852,29.5Z" transform="translate(7336.262 5926.87)" fill={fillColor}/>
          </g>
        </svg>
      )
        :(
      <svg xmlns="http://www.w3.org/2000/svg" width="30.567" height="25.703" viewBox="0 0 30.567 25.703">
          <g id="Group_29050" data-name="Group 29050" transform="translate(-7336.161 -5930.77)">
              <path id="users"
                    d="M9.563,15.476A5.738,5.738,0,1,1,15.3,9.738a5.738,5.738,0,0,1-5.738,5.738Zm0-8.926a3.188,3.188,0,1,0,3.188,3.188A3.188,3.188,0,0,0,9.563,6.55Zm9.563,21.677V27.59A9.563,9.563,0,0,0,0,27.59v.638a1.275,1.275,0,0,0,2.55,0V27.59a7.013,7.013,0,0,1,14.026,0v.638a1.275,1.275,0,1,0,2.55,0Z"
                    transform="translate(7336.261 5926.87)" fill={fillColor} stroke="#fff" stroke-width="0.2"/>
              <path id="clock-three" d="M6.6,0a6.6,6.6,0,1,0,6.6,6.6A6.606,6.606,0,0,0,6.6,0ZM8.8,7.148H6.6a.55.55,0,0,1-.55-.55V3.3a.55.55,0,1,1,1.1,0V6.049H8.8a.55.55,0,1,1,0,1.1Z"
                    transform="translate(7353.531 5931.909)" fill={fillColor}/>
          </g>
      </svg>

    // <svg xmlns="http://www.w3.org/2000/svg" width="30.323" height="23.059" viewBox="0 0 30.323 23.059">
    //     <g id="Group_21258" data-name="Group 21258" transform="translate(-282.073 -108.975)">
    //         <g id="Group_20607" data-name="Group 20607" transform="translate(282.073 116.666)">
    //             <path id="Path_14882" data-name="Path 14882" d="M17.3,17.4,15,15.1V11h1.28v3.572L18.2,16.5Z" transform="translate(-7.959 -6.516)" fill={fillColor}/>
    //             <g id="Group_20606" data-name="Group 20606" transform="translate(0 0)">
    //                 <path id="Path_14881" data-name="Path 14881"
    //                       d="M12.042,20.083a7.042,7.042,0,1,1,7.042-7.042A7.042,7.042,0,0,1,12.042,20.083Zm0-12.8A5.761,5.761,0,1,0,17.8,13.042,5.762,5.762,0,0,0,12.042,7.28Z"
    //                       transform="translate(-4.359 -4.716)" fill={fillColor} />
    //                     <rect id="Rectangle_8191" data-name="Rectangle 8191" width="3.246" height="1.28" transform="translate(0 2.304) rotate(-45.06)" fill={fillColor} />
    //                     <rect id="Rectangle_8192" data-name="Rectangle 8192" width="1.28" height="3.246" transform="translate(12.163 0.904) rotate(-44.94)" fill={fillColor} />
    //                 </g>
    //             </g>
    //             <g id="Group_20608" data-name="Group 20608" transform="translate(297.313 108.975)">
    //                 <path id="Path_14744" data-name="Path 14744" d="M13.745,0A7.541,7.541,0,1,1,6.2,7.542,7.542,7.542,0,0,1,13.745,0" transform="translate(-6.203 0)" fill={fillColor} />
    //                 <path id="Path_14745" data-name="Path 14745" d="M7.845,19.128a.647.647,0,0,0,.526-.282,5.988,5.988,0,0,1,9.753,0,.65.65,0,0,0,.534.284h1.413a.657.657,0,0,0,.653-.664.644.644,0,0,0-.08-.311,8.457,8.457,0,0,0-14.8.013.655.655,0,0,0,.577.961Z" transform="translate(-5.769 3.844)" fill={fillColor} />
    //             </g>
    //         </g>
    //     </svg>


    );
}

export default MeetingsTabIcon;
