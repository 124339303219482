import {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';

import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { CitySelector } from '../../../components/selectors/city-selector';
import { MonthSelector } from '../../../components/selectors/month-selector';
import { YearSelector } from '../../../components/selectors/year-selector';
import FormFieldWithCaption from '../../../layouts/form-field-with-caption';
import { IReactSelectOptionCompany } from '../../../models/company/company-react-select-option';
import { IReactSelectOption } from '../../../models/react-select-option';
import { Typography } from '../../../shared-components';
import { Button } from '../../../shared-components/V2';
import { IAppState } from '../../../store';
import { getCompanyProfilePreview } from '../../../store/company/profile-preview/actions';
import { LOGIN_SIGNUP_RECOVERY } from '../../../constants';
import { CompanySelectorNew } from '../../../components/selectors/company-selector-new';
import AlertModal from '../../../components/alert-modal/alert-modal';
import { UserService } from '../../../services';

interface IEditExperienceProps {
  jobTitle?: string;
  companyId?: string;
  companyName?: string;
  employmentType?: string;
  current?: boolean;
  startDate?: string;
  endDate?: string;
  location?: string;
  onSave: Function;
  onClose: Function;
  title?: string;
  id?: string;
}

interface ICreateOrEditExperience {
  companyId: string;
  companyName: string;
  current: boolean;
  employmentType: string;
  endDate?: string;
  startDate: string;
  jobTitleId: string;
  jobTitle: string;
  location: string;
  startYear: string;
  endYear: string;
}

const EditExperience = (props: IEditExperienceProps) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateOrEditExperience>();

  const [citySelected, setCitySelected] = useState<any>(null);
  const [selectedCompanyStateToStore, setSelectedCompanyStateToStore] = useState<IReactSelectOptionCompany>();
  const [selectedJobTitleStateToStore, setSelectedJobTitleStateToStore] = useState<any>();
  const [selectedStartMonthStateToStore, setSelectedStartMonthStateToStore] = useState<IReactSelectOption>();
  const [selectedEndMonthStateToStore, setSelectedEndMonthStateToStore] = useState<IReactSelectOption>();
  const [selectedStartYearStateToStore, setSelectedStartYearStateToStore] = useState<IReactSelectOption>();
  const [selectedEndYearStateToStore, setSelectedEndYearStateToStore] = useState<IReactSelectOption>();

  const [selectedEmploymentType, setSelectedEmploymentType] = useState('');
  const [isCurrent, setIsCurrent] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const selector = useSelector((state: IAppState) => state);
  const company = selector.companyProfilePreview;
  const { cityCountryPostCode } = selector.signupUser;

  const [showEndDate, setShowEndDate] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.companyId) {
      dispatch(getCompanyProfilePreview(props.companyId));
    }
  }, [dispatch, props.companyId]);

  useEffect(() => {
    setSelectedEmploymentType(props.employmentType!);
  }, [props.employmentType]);

  useEffect(() => {
    setSelectedJobTitleStateToStore({ label: props.jobTitle });
  }, [props.jobTitle]);

  useEffect(() => {
    if (props.companyId && company) {
      setSelectedCompanyStateToStore({
        industry: company.value?.industry?.name!,
        key: company.value?.id!,
        label: company.value?.name!,
        logo: company.value?.logo!,
        value: company.value?.id!,
      });
    }
  }, [company, setValue, props.companyId]);

  useEffect(() => {
    if (cityCountryPostCode) {
      setCitySelected(cityCountryPostCode.location!);
    }
  }, [cityCountryPostCode]);

  useEffect(() => {
    if (props.startDate) {
      setSelectedStartMonthStateToStore({
        key: format(new Date(props.startDate), 'MM'),
        value: format(new Date(props.startDate), 'MM'),
        label: format(new Date(props.startDate), 'MMMM'),
      });
      setSelectedStartYearStateToStore({
        key: format(new Date(props.startDate), 'yyyy'),
        value: format(new Date(props.startDate), 'yyyy'),
        label: format(new Date(props.startDate), 'yyyy'),
      });
    }
    if (props.endDate && !props.current) setShowEndDate(true);
    if (props.current === undefined) {
      setShowEndDate(true);
    }
    if (props.endDate) {
      setSelectedEndMonthStateToStore({
        key: format(new Date(props.endDate), 'MM'),
        value: format(new Date(props.endDate), 'MM'),
        label: format(new Date(props.endDate), 'MMMM'),
      });
      setSelectedEndYearStateToStore({
        key: format(new Date(props.endDate), 'yyyy'),
        value: format(new Date(props.endDate), 'yyyy'),
        label: format(new Date(props.endDate), 'yyyy'),
      });
    }
  }, [props, props.startDate, props.endDate]);

  useEffect(() => {
    if (props.companyId) {
      setValue('companyId', props.companyId);
      setValue('companyName', props.companyName!);
    }
    setValue('jobTitle', props.jobTitle!);
    setValue('employmentType', props.employmentType!);
    setValue('current', props.current!);
    setValue('location', props.location!);
    setValue('startDate', props.startDate!);
    setValue('endDate', props.endDate!);
    setIsCurrent(props.current!);
  }, [props, setValue]);

  const handleCompanySelected = (selectedOptions: any) => {
    setValue('companyId', selectedOptions?.value);
    setValue('companyName', selectedOptions?.label);
    setSelectedCompanyStateToStore(selectedOptions);
  };

  const handleJobTitleSelected = (selectedOptions: any) => {
    const { value } = selectedOptions.target;
    if (value) errors.jobTitle = undefined;
    setValue('jobTitle', value);
    setSelectedJobTitleStateToStore(value);
  };

  const handleStartMonthSelected = (selectedMonth: any) => {
    if (selectedMonth) errors.startDate = undefined;
    setValue('startDate', selectedMonth?.value);
    setSelectedStartMonthStateToStore(selectedMonth);
  };

  const handleEndMonthSelected = (selectedMonth: any) => {
    if (selectedMonth) errors.startDate = undefined;
    setValue('endDate', selectedMonth?.value);
    setSelectedEndMonthStateToStore(selectedMonth);
  };

  const handleStartYearSelected = (selectedYear: any) => {
    if (selectedYear) errors.startDate = undefined;
    setValue('startYear', selectedYear?.label);
    setSelectedStartYearStateToStore(selectedYear);
  };

  const handleEndYearSelected = (selectedYear: any) => {
    if (selectedYear) errors.startDate = undefined;
    setValue('endYear', selectedYear?.label);
    setSelectedEndYearStateToStore(selectedYear);
  };

  const onCurrentSelected = (e: any) => {
    const isSelected = e.target.checked;
    setIsCurrent(isSelected);
    setShowEndDate(!isSelected);
    setValue('current', isSelected);
  };

  const onEmploymentTypeSelected = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedEmploymentType(e.target.value);
  };

  const onSubmit = (data: ICreateOrEditExperience) => {
    const startYear = selectedStartYearStateToStore?.value ? selectedStartYearStateToStore.value : '2022';
    const startDate = selectedStartMonthStateToStore?.value ? selectedStartMonthStateToStore.value : '01';

    let dataToSubmit: any = {
      companyId: selectedCompanyStateToStore?.value,
      companyName: selectedCompanyStateToStore?.label,
      jobTitle: selectedJobTitleStateToStore,
      employmentType: selectedEmploymentType! ? selectedEmploymentType : 'full-time',
      current: data.current,
      location: data.location,
      startDate: `${startYear}/${startDate}/01`,
    };

    if (data.current) {
      dataToSubmit = { ...dataToSubmit, endDate: null };
    }

    if (!data.current) {
      const endYear = selectedEndYearStateToStore?.value ? selectedEndYearStateToStore.value : '2022';
      const endDate = selectedEndMonthStateToStore?.value ? selectedEndMonthStateToStore.value : '01';
      dataToSubmit = { ...dataToSubmit, endDate: `${endYear}/${endDate}/01` };
    }
    props.onSave(dataToSubmit);
    props.onClose();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full">
          <div className="flex flex-col p-[18px] pt-[16px]">
            <div className="text-[#0B1221] font-medium text-[18px] leading-[26px] border-b-1 border-[#CDDCF2] pb-[9px]">
              {props.title}
            </div>
            {/* Position and company */}
            <div className="mt-[19px]">
              <div className="mb-[20px]">
                <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Position*">
                  <>
                    <div className="mt-[1px] w-full">
                      <input
                        type="text"
                        className="w-full h-[31px] text-[13px] leading-[15px] font-light text-[#3C3C3C] px-[12px] border-1 border-[#dddddd] rounded-[5px] focus-visible:outline-none"
                        {...register('jobTitle', {
                          required: { value: true, message: LOGIN_SIGNUP_RECOVERY.JOBTITLE_ISREQUIRED }
                        })}
                        onChange={handleJobTitleSelected}
                        autoComplete="off"
                      />
                    </div>
                    <div>
                      {errors.jobTitle && (
                        <Typography
                          variant="ValidationMessage"
                          text={errors.jobTitle?.message!}
                          classes="flex content-start mt-1"
                        />
                      )}
                    </div>
                  </>
                </FormFieldWithCaption>
              </div>
              <div className="flex flex-row gap-[25px]">
                {/* Employment type */}
                <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Employment type*">
                  <div className="mt-[1px] border-1 border-[#dddddd] h-[31px] flex items-center rounded-[5px] w-full">
                    <select
                      className="md:w-full h-[31px] text-sm1 font-light rounded-10 w-full px-2.5"
                      onChange={(e: any) => onEmploymentTypeSelected(e)}
                      value={selectedEmploymentType}
                    >
                      <option value="full-time">Full-time</option>
                      <option value="part-time">Part-time</option>
                      <option value="self-employed">Self-employed</option>
                      <option value="freelance">Freelance</option>
                      <option value="contract">Contract</option>
                      <option value="internship">Internship</option>
                      <option value="apprenticeship">Apprenticeship</option>
                    </select>
                  </div>
                </FormFieldWithCaption>
                <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Company">
                  <div className="mt-[1px] rounded-[10px]">
                    <CompanySelectorNew
                      handleCompanySelected={handleCompanySelected}
                      register={register}
                      selectedCompanyStateToStore={selectedCompanyStateToStore}
                      placeholder=" "
                      className="!w-[212.5px] h-[31px] text-[13px] leading-[15px] font-light text-[#3C3C3C] px-[12px] border-1 border-[#dddddd] rounded-[5px] focus-visible:outline-none"
                    />
                  </div>
                </FormFieldWithCaption>
              </div>
              {/* current role */}
              <div className="mt-[20px]">
                <div className="flex items-center flex-row">
                  <div className="mt-[1.5px] mr-[5px]">
                    <input
                      type="checkbox"
                      checked={isCurrent}
                      {...register('current')}
                      onClick={(e: any) => onCurrentSelected(e)}
                    />
                  </div>
                  <div className="text-[13px] leading-[15px] font-medium text-[#3C3C3C]">
                    I still work there
                  </div>
                </div>
              </div>
              {/* Start date */}
              <div className="flex flex-row gap-[25px] mt-[20px]">
                <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Start month*">
                  <div className="rounded-[10px] mt-[1px]">
                    <MonthSelector
                      onChange={handleStartMonthSelected}
                      register={register}
                      selectedMonthStateToStore={selectedStartMonthStateToStore}
                    />
                  </div>
                </FormFieldWithCaption>
                <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Start year*">
                  <div className="rounded-[10px] mt-[1px]">
                    <YearSelector
                      onChange={handleStartYearSelected}
                      register={register}
                      selectedYearStateToStore={selectedStartYearStateToStore}
                    />
                  </div>
                </FormFieldWithCaption>
              </div>
              {/* End date */}
              {showEndDate && (
                <div className="flex flex-row gap-[25px]  mt-[20px]">
                  <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="End month*">
                    <div className="rounded-[10px] mt-[1px]">
                      <MonthSelector
                        onChange={handleEndMonthSelected}
                        register={register}
                        selectedMonthStateToStore={selectedEndMonthStateToStore}
                      />
                    </div>
                  </FormFieldWithCaption>
                  <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="End year*">
                    <div className="rounded-[10px]">
                      <YearSelector
                        onChange={handleEndYearSelected}
                        register={register}
                        selectedYearStateToStore={selectedEndYearStateToStore}
                      />
                    </div>
                  </FormFieldWithCaption>
                </div>
              )}
            </div>
            {/* Location */}
            <div className="mt-[20px]">
              <FormFieldWithCaption className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]" caption="Location*">
                <>
                  <div className="border-1 border-[#dddddd] rounded-[5px] mt-[1px]">
                    <CitySelector
                      classes="w-full rounded-[10px] h-[31px] text-[13px] leading-[15px] pl-[12px] text-[#3C3C3C] focus-visible:outline-none"
                      setValue={setValue}
                      citySelected={citySelected}
                      register={register}
                      placeholder="Enter your city"
                    />
                  </div>
                  <div>
                    {errors.location && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.location?.message!}
                        classes="flex content-start mt-1"
                      />
                    )}
                  </div>
                </>
              </FormFieldWithCaption>
            </div>
            {/* Buttons */}
            <div className="mt-4 flex flex-row w-full">
              <div className="justify-start pt-3">
                <Button
                  text="Delete"
                  type="button"
                  className="rounded-20 inline-block text-sm font-semibold h-[31px] bg-red text-[#203C6E] w-[88px] mr-[10px] hover:bg-[#CEDCF2] tracking-wide"
                  onClick={() => setDeleteConfirmation(true)}
                />
              </div>
              <div className="flex flex-row justify-end pt-3 w-full">
                <div>
                  <Button
                    text="Cancel"
                    type="button"
                    className="rounded-5 inline-block text-sm font-semibold h-[31px] bg-[#EDF2F5] text-[#203C6E] w-[88px] mr-[10px] hover:bg-[#CEDCF2] tracking-wide"
                    onClick={() => props.onClose()}
                  />
                </div>
                <div className="">
                  <Button
                    text="Save"
                    type="submit"
                    className="rounded-[5px] inline-block text-sm font-semibold h-[31px] bg-[#0049EF] hover:bg-[#2e6eff] text-white w-[74px] tracking-wide"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <AlertModal isOpen={deleteConfirmation} onClose={() => setDeleteConfirmation(false)} className="w-576">
        <div className="w-full">
          <div className="flex flex-col px-[18px]">
            <div className="text-[#0B1221] font-medium text-[15px] leading-[26px] border-b-1 border-[#CDDCF2] pb-[9px]">
              Delete experience
            </div>
            {/* First name and last name */}
            <div className="mt-4">
              <div className="flex flex-row gap-[25px]">
                Are you sure you want to delete your experience?
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end px-3 py-2 w-full">
            <div>
              <Button
                text="Cancel"
                type="button"
                className="rounded-5 inline-block text-sm font-semibold h-[31px] bg-[#EDF2F5] text-[#203C6E] w-[88px] mr-[10px] hover:bg-[#CEDCF2] tracking-wide"
                onClick={() => { setDeleteConfirmation(false); }}
              />
            </div>
            <div className="">
              <Button
                text="Delete"
                type="button"
                className="rounded-2xl inline-block text-sm font-semibold h-[31px] bg-[#0049EF] hover:bg-[#244E93] text-white tracking-wide"
                onClick={() => {
                  if (props.id) {
                    const userSerivce = new UserService();
                    userSerivce.deleteExperience(props.id).then(() => {
                      setDeleteConfirmation(false);
                      props.onClose();
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </AlertModal>
    </>
  );
};

export default EditExperience;
