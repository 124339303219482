import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IAssignedManagers } from 'src/models/events-live/expo';
import { IExpoBooth } from 'src/models/expo';
import { Avatar } from 'src/shared-components/V2';
import { expoGuestCalling } from 'src/store/expo/actions';

interface IExpoManagersLayout {
  booth: IExpoBooth,
  boothManagers: IAssignedManagers[]
}

export const ExpoManagersLayout = ({ booth, boothManagers }: IExpoManagersLayout) => {
  const dispatch = useDispatch();
  const [isCalling, setIsCalling] = useState(false);

  const onBoothManagersCall = (booth: IExpoBooth) => {
    dispatch(expoGuestCalling(booth.id));
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ExpoCallButton = ({ booth }: any) => (
    <div className="grid place-items-center h-full">
      <button
        type="button"
        disabled={isCalling}
        className={classNames('w-fit px-4 py-8 rounded-xl m-auto block', {
          'bg-yellow-300 ': !isCalling,
          'bg-gray-300': isCalling
        })}
        onClick={() => {
          setIsCalling(true);
          onBoothManagersCall(booth);
        }}
      >
        {isCalling ? 'Calling the exibitor' : 'Call the exhibitor'}
      </button>
    </div>
  );

  return (
    <div className="grid grid-cols-3 w-full h-full">
      {/* Expo Managers */}
      <div className="flex flex-col ml-4">
        {boothManagers && !!boothManagers.length && (
          <>
            <p className="text-white text-lg18 font-normal mb-2 text-left w-full">Expo Managers:</p>
            <div className="flex flex-wrap gap-4">
              {/* Loop */}
              {
                boothManagers?.map((manager: any, index: number) => (
                  <Avatar className="" src={`${manager.avatar}`} alt={`${manager.firstName} ${manager.lastName} `} size="MEDIUM" key={`booth-manager-${index}`} />
                ))
              }
            </div>
          </>
        )}
      </div>
      <ExpoCallButton booth={booth} />
      <div className="h-full" />
    </div>
  );
};
