/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/function-component-definition */
import { SetStateAction, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './slides.css';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
// import { IAppState } from 'src/store';
import { getCompanyPosts } from 'src/store/company/company-posts/actions';
import { IPost } from 'src/models/post/post';
import { CardMainPost } from 'src/components/posts';
import { getUserPostComments } from 'src/store/user/posts/actions';
import { ICreateComment } from 'src/models/post/comment';
import { createPostComment, likeComment } from 'src/store/posts/comments/actions';
import { PostService } from 'src/services';
import { editPost } from 'src/store/events/event-post-actions/actions';
import { unlikeComment } from 'src/store/events/event-post-comment-like/actions';
import { likePost, unlikePost } from 'src/store/events/upcoming-event-post-like/actions';
import shareIcon from '../../../../assets/images/share-icon.svg';
import commentIcon from '../../../../assets/images/comment-icon.svg';
import SwiperNavButtons from './SwiperNavButton';

export default function SwiperEvents(props: { viewingAsAdmin: boolean | undefined; companyProfilePreview: any, posts: any }) {
  const params = useParams();
  const { slug: userId } = useParams();
  const companyIdOrSlug = String(Object.values(params)[0]);

  const [currentIndex, setCurrentIndex] = useState(0);

  // const { companyProfilePreview, posts, auth } = useSelector(
  //   (state: IAppState) => state
  // );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyPosts(companyIdOrSlug));
  }, [dispatch, companyIdOrSlug]);
  function resetDefaults() {
    throw new Error('Function not implemented.');
  }

  function fetchPosts() {
    throw new Error('Function not implemented.');
  }

  const handleSlideChange = (swiper: { activeIndex: SetStateAction<number>; }) => {
    setCurrentIndex(swiper.activeIndex);
  };

  return (
    <div className="relative -mt-[57px]">
      <Swiper
        modules={[Pagination, Navigation]}
        slidesPerView="auto"
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        navigation={{
          nextEl: '.custom-next',
          prevEl: '.custom-prev',
        }}
        className="myPostSwiper w-[100%] !min-h-[100%]"
        onSlideChange={handleSlideChange}
      >

        {props?.posts?.value?.postsResult?.data?.map((post: IPost, index: number) => (
          !post.sharePost && !post.shareEvent && (
          <SwiperSlide key={post.id}>
            <div key={post.id} className="mb-[32px] rounded-[15px]">
              <CardMainPost
                isCompanyNew
                post={post}
                commentIcon={commentIcon}
                shareIcon={shareIcon}
                className="mb-4 w-full bg-white create-post-card"
                key={`event-${index}`}
                getPostComments={(id: string) => dispatch(getUserPostComments(id))}
                addPostComment={async (
                  postId: string,
                  data: any,
                  attachment: any,
                  companyId?: string
                ) => {
                  let comment: ICreateComment;
                  if (companyId) {
                    comment = {
                      content: data,
                      parentId: '',
                      companyId,
                      type: 'company',
                    };
                    await dispatch(
                      createPostComment(postId, comment, attachment, companyId)
                    );
                  } else {
                    comment = {
                      content: data,
                      parentId: '',
                      userMentioned: '',
                    };

                    await dispatch(
                      createPostComment(postId, comment, attachment)
                    );
                  }
                  await dispatch(getUserPostComments(postId));
                  await dispatch(getCompanyPosts(props?.companyProfilePreview?.value?.id));
                }}
                addReply={async (
                  postId: string,
                  replyText: string,
                  parentId: string,
                  attachment: any,
                  companyId?: string
                ) => {
                  let comment: ICreateComment;
                  if (companyId) {
                    comment = {
                      content: replyText,
                      parentId,
                      companyId,
                      type: 'company',
                    };
                    await dispatch(
                      createPostComment(postId, comment, attachment, companyId)
                    );
                  } else {
                    comment = {
                      content: replyText,
                      parentId,
                      userMentioned: '',
                    };
                    await dispatch(
                      createPostComment(postId, comment, attachment)
                    );
                  }

                  await dispatch(getUserPostComments(postId));
                }}
                onCommentLike={async (likeType: string, commentId: string) => {
                  if (likeType) {
                    await dispatch(likeComment(commentId, likeType));
                  } else {
                    await dispatch(unlikeComment(commentId));
                  }
                  // await dispatch(getUserPosts());
                }}
                onPostLike={async (likeType: string, postId: string) => {
                  // If you got like type then set the like on post else its remove like 'undefined'
                  if (likeType) {
                    await dispatch(likePost(postId, likeType));
                  } else {
                    await dispatch(unlikePost(postId));
                  }
                  // await dispatch(getUserPosts());
                }}
                onDelete={async () => {
                  const postService = new PostService();
                  await postService.deletePost(post.id);

                  resetDefaults();
                  await fetchPosts();
                }}
                onEditPost={async (
                  postId: string,
                  content: string,
                  privacy: string
                ) => {
                  await dispatch(editPost(postId, content, privacy));
                  resetDefaults();
                  await fetchPosts();
                }}
                onAfterShare={async () => { resetDefaults(); await fetchPosts(); }}
                viewingAsCompanyAdmin={props.viewingAsAdmin}
              />
            </div>
          </SwiperSlide>
          )
        ))}
        <SwiperNavButtons lastIndex={2} currentIndex={currentIndex} />
      </Swiper>
    </div>
  );
}
