import { forwardRef } from 'react';

interface IReportItemProps {
  title: string;
  description: string;
  options: string[];
  showOptions: boolean;
}

const ReportItem = forwardRef<any, IReportItemProps>(
  (props: IReportItemProps, ref) => {
    const { title, description, options, showOptions, ...rest } = props;
    return (
      <>
        <div className='text-md3 text-blue-4'>{title}</div>
        <div className='text-sm5 font-normal text-gray-15'>
          {description}
        </div>
        {showOptions && (
          <div className='sm:flex flex-row'>
            {options.length > 0 &&
              options.map((option: string, index: number) => (
                <div className='flex flex-row mr-4 sm:my-0 my-[2px]' key={`option-${index}`}>
                  <div>
                    <input className="cursor-pointer" autoComplete="off" type='radio' value={option} {...rest} ref={ref} />
                  </div>
                  <div className='ml-1 cursor-text'>{option}</div>
                </div>
              ))}
          </div>
        )}
      </>
    );
  }
);

export default ReportItem;
