import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { LoadingScreen } from 'src/mui/components/loading-screen';
import { useTheme } from '@mui/material';
import { getBrandingColors } from '../../live/liveEventStyling';
// import { Button } from 'src/shared-components/V2/Button';

interface ISpeakerRoomsProps {
  rooms: any[];
  onClick: Function;
  isGridView?: boolean;
}

const mainContainer = { width: 1, height: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }

export const SpeakerRooms = ({ rooms, onClick, isGridView }: ISpeakerRoomsProps) => {
  const theme = useTheme();
  const colors = getBrandingColors();
  return (
    <Stack sx={mainContainer} >
      {rooms?.length ? <Stack color={colors?.Text} mb={3}>Join a breakout room, you can come back to lobby</Stack>
        : <Stack color={colors?.Text} mb={3}>No breakout room available!</Stack>}
      <Stack display='flex' flexDirection='row' flexWrap='wrap' gap={1} justifyContent='center' alignItems='center' maxHeight={isGridView ? 'calc(100% - 194px)' : 'calc(100% - 195px)'} overflow='scroll' pb={2} px={4} >
        {
          rooms?.length === 1 && onClick(rooms?.[0]?.id)
        }
        {
          rooms?.length > 1 ? rooms?.map(room => (
            <Stack width='153px' height='128px' borderRadius='9px' bgcolor={colors?.Secondary} display='flex' flexDirection='column' justifyContent='space-between' overflow='hidden' py={2} >
              <Stack fontSize='14px' lineHeight='20px' color={colors?.Text} fontWeight={500} mb={2}>Room</Stack>
              <Stack
                fontSize='13px'
                lineHeight='19px'
                color={colors?.Text}
                fontWeight={500}
                fontFamily="Poppins-500"
                paddingX={1}
                maxHeight='47px'
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden'

                }}
              >
                {room?.name}
              </Stack>
              <Stack
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Button
                  fullWidth
                  color="inherit"
                  variant="soft"
                  sx={{
                    fontSize: '12px',
                    mt: '12px',
                    maxWidth: '80px',
                    backgroundColor: colors?.Accent || '#03FFCD',
                    color: colors?.ButtonTextColor || 'black',
                    width: "80px",
                    fontFamily: 'Poppins-500',
                    fontWeight: 500,
                    borderRadius: '3px',
                    lineHeight: '20px',
                    padding: 0,
                    "&:hover": {
                      backgroundColor: colors?.Accent || "#0049EF",
                      opacity: '0.5'
                    },
                  }}
                  onClick={() => onClick(room.id)}
                >
                  Join
                </Button>
              </Stack>
            </Stack>
          )) : <Stack sx={mainContainer}><LoadingScreen /></Stack>
        }
      </Stack>
    </Stack>
  )
};
