import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ICompanySizesResult } from '../../../models/company/CompanySizesResult';
import { ICompanySizesState } from '../../../models/company/CompanySizesState';
import { CompanyService } from '../../../services';
import { CompanySizeActionTypes } from './types';

export interface ICompanySizesLoadingAction {
  type: CompanySizeActionTypes.COMPANY_SIZES_LOADING;
  loading: boolean;
}

export interface ICompanySizesSuccessAction {
  type: CompanySizeActionTypes.COMPANY_SIZES_SUCCESS;
  data: ICompanySizesResult;
}

export interface ICompanySizesErrorAction {
  type: CompanySizeActionTypes.COMPANY_SIZES_ERROR;
  errorMessage: string;
}

export type CompanySizesActions =
  | ICompanySizesLoadingAction
  | ICompanySizesSuccessAction
  | ICompanySizesErrorAction;

export const getCompanySizes: ActionCreator<
  ThunkAction<Promise<any>, ICompanySizesState, null, ICompanySizesSuccessAction>
> = () => {
  return async (dispatch: Dispatch) => {
    const companyService: CompanyService = new CompanyService();

    try {
      const result = await companyService.getCompanySizes();

      dispatch({
        type: CompanySizeActionTypes.COMPANY_SIZES_SUCCESS,
        data: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: CompanySizeActionTypes.COMPANY_SIZES_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: CompanySizeActionTypes.COMPANY_SIZES_LOADING,
        loading: false
      });
    }
  };
};

export const loadCompanyProfilePreview: ActionCreator<
  ThunkAction<any, ICompanySizesState, null, ICompanySizesLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: CompanySizeActionTypes.COMPANY_SIZES_LOADING,
    loading: shouldLoad
  });
