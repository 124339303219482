import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { Button } from '../../../shared-components/V2';

interface Props {
  submitRef: any;
  closeModal: () => void;
  isCompanyUpdate: boolean;
  isValid?: boolean
}

// eslint-disable-next-line no-unused-vars
export const ComapnyAuthCheck = ({
  submitRef, closeModal, isCompanyUpdate, isValid
}: Props) => {
  const [isVerify, setIsVerify] = useState(true);

  const handleSave = (e: any) => {
    e.preventDefault();
    if (!isValid) closeModal();
    submitRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const onClickVerify = (e: any) => {
    setIsVerify(!e.target.checked);
  };

  return (
    <div className="w-full px-[20px] pb-[17px] pt-[17px]">
      <form onSubmit={handleSave}>
        <div className="text-[#0B1221] px-3 border-b-1 border-[#CDDCF2] text-center text-[16px] font-medium leading-[22px] pb-2">
          Authorization
        </div>
        <div className="flex justify-start h-[23pxpx] mt-3 mb-[18px]">
          <div className="bg-white border-2 rounded border-blue-0 w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 mt-[3px] focus-within:border-blue-0">
            <input
              type="checkbox"
              className="opacity-0 absolute left-[20px] top-[62px] h-[40px] w-[591px]"
              onChange={e => onClickVerify(e)}
            />
            <svg
              className="fill-current hidden w-2 h-2 text-blue-3 pointer-events-none"
              viewBox="0 0 20 20"
            >
              <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
            </svg>
          </div>
          <div className="text-sm text-[#0B1221] font-light leading-[24px] w-full">
            I verify that I am an authorised representative of this organisation
            and have the right to act on its behalf in the creation and
            management of this page.
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <Button
            type="button"
            onClick={closeModal}
            text="Cancel"
            className="rounded-[5px] inline-block text-sm font-semibold tracking-wide h-[30px] px-6 py-1.5 bg-[#EDF2F5] text-[#203C6E] w-28 mr-2 hover:bg-[#CEDCF2]"
          />
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isVerify}
            loading={isCompanyUpdate}
            sx={{
              height: '30px', color: 'white', borderRadius: '5px', backgroundColor: '#0049EF', px: '24px', py: '6px', boxShadow: 'none'
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '13px', lineHeight: '30px', fontFamily: 'Poppins-600', textTransform: 'capitalize'
              }}
            >
              Save &amp; Publish
            </Typography>
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};

export default ComapnyAuthCheck;
