import { PAutocomplete } from 'src/mui/components/hook-form';

type OptionType = {
  id: string;
  name: string;
};

type PJobFunctionsProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  multiple?: boolean;
  defaultValue?: OptionType;
  sx?: any;
  Icon?: any;
  placeholder?: any;
};

const PTargetDepartment = ({
  Icon,
  name,
  sx,
  label,
  disabled = false,
  multiple = false,
  defaultValue,
  placeholder,
}: PJobFunctionsProps) => {
  const jobFunctions = [
    { id: 'Accounting & Admin', name: 'Accounting & Admin' },
    { id: 'Sales', name: 'Sales' },
    { id: 'Back Office', name: 'Back Office' },
    { id: 'Human Resources', name: 'Human Resources' },
    { id: 'Procurement', name: 'Procurement' },
    { id: 'Marketing & PR', name: 'Marketing & PR' },
    { id: 'Product & Production', name: 'Product & Production' },
    { id: 'Research', name: 'Research' },
    { id: 'IT', name: 'IT' },
    { id: 'Data & Analytics', name: 'Data & Analytics' },
    { id: 'IT Security', name: 'IT Security' },
    { id: 'Service & CX', name: 'Service & CX' },
    { id: 'DevOps', name: 'DevOps' },
    { id: 'Finance', name: 'Finance' },
    { id: 'Legal', name: 'Legal' },
  ];

  return (
    <PAutocomplete
      sx={sx}
      popupIcon={Icon}
      label={label}
      placeholder={placeholder}
      name={name}
      multiple={multiple}
      disabled={disabled}
      fullWidth
      onlySelectFromDropdown
      clearOnBlur
      filterSelectedOptions
      {...(defaultValue && { defaultValue })}
      options={jobFunctions ?? []}
      getOptionLabel={(option: OptionType | string) => (option as OptionType).name ?? option}
    />
  );
};

export default PTargetDepartment;
