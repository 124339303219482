import { Reducer } from 'redux';

import { IModalState } from '../../models/modal/ModalState';
import { ModalActions } from './actions';
import { ModalActionTypes } from './types';

const initialState: IModalState = {
  isOpen: false
};

export const ModalReducer: Reducer<IModalState, ModalActions> = (state = initialState, action) => {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL: {
      return {
        ...state,
        isOpen: true
      };
    }

    case ModalActionTypes.HIDE_MODAL: {
      return {
        ...state,
        isOpen: false
      };
    }

    default:
      return state;
  }
};
