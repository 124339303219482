import { IEvent } from '../../../../models/event/event';
import { Box, Stack } from '@mui/material';
import { getBrandingColors } from './liveEventStyling';
import { CardHeader } from './card-header';
import { EventPolls } from '../../Polls/Polls';

export function LivePollsComponent(props: {
  gridColumn: string,
  gridRow: string,
  moduleDesignLines: boolean,
  height: string,
  boxStyles: { backgroundColor: string; pb: string; overflow: string; borderRadius: string; px: string; py: string; position: string },
  onClick: () => void,
  event: IEvent | undefined,
  strings: string[],
  columnCardHeight: string
}) {
  return <Box sx={{
    gridColumn: props.gridColumn,
    gridRow: props.gridRow,
  }}>
    <Stack sx={{ height: props.height, ...props.boxStyles }}>
      <CardHeader title="Polls" onClick={props.onClick}/>
      <EventPolls
        event={props.event!}
        isGridView={props.strings?.length > 3}
        columnCardHeight={props.columnCardHeight}
      />
    </Stack>
  </Box>;
}
