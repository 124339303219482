import { SVGProps } from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
}

function SpeakersTabIcon({ fillColor, fillBgColor, ...props }: Props) {
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.708" height="17.657" viewBox="0 0 14.708 17.657">
            <g id="Group_19474" data-name="Group 19474" transform="translate(-0.3)">
                <g id="Group_18834" data-name="Group 18834">
                    <path id="Path_14628" data-name="Path 14628" d="M14.026,16.685h-.566a5.83,5.83,0,0,1,.467.7.454.454,0,0,1-.395.671H11.744a3.407,3.407,0,0,0-5.6-.266c-.072.085-.14.174-.207.265h-1.8a.452.452,0,0,1-.4-.662,5.755,5.755,0,0,1,.466-.712H.683a.759.759,0,0,0-.082,0,.677.677,0,0,0-.6.749l.407,3.956a.68.68,0,0,0,.679.616H13.619a.68.68,0,0,0,.678-.616l.407-3.956c0-.022,0-.044,0-.066a.685.685,0,0,0-.682-.687" transform="translate(0.3 -4.353)" fill={defaultColor} />
                    <path id="Path_14629" data-name="Path 14629" d="M10.64,0A4.436,4.436,0,1,1,6.2,4.437,4.437,4.437,0,0,1,10.64,0" transform="translate(-1.417)" fill={defaultColor} />
                    <path id="Path_14630" data-name="Path 14630" d="M6.99,16.932a.381.381,0,0,0,.31-.166,3.523,3.523,0,0,1,5.737,0,.383.383,0,0,0,.314.167h.831a.387.387,0,0,0,.384-.391.379.379,0,0,0-.047-.183,4.975,4.975,0,0,0-8.706.008.385.385,0,0,0,.339.565Z" transform="translate(-0.983 -3.757)" fill={defaultColor} />
                </g>
            </g>
        </svg>



    );
}

export default SpeakersTabIcon;