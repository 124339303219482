import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { IEventSponsor } from '../models/event/event-sponsor';

import companyDefault from '../assets/images/company-default.svg';
export interface IEventPartnerProps {
  imageUrl: string;
  companyName: string;
  sponsorship?: string;
}

export const PartnerItem = (props: IEventPartnerProps) => {
  const onError = (event: any, src: string) => {
    event.target.src = src
    event.onerror = null
  }
  return (
    <div className="flex flex-col items-center">
      <div
        className={classNames("w-[54px] min-w-[54px] max-w-[54px] h-[54px] min-h-[54px] max-h-[54px] flex items-center justify-center rounded-[6px] overflow-hidden", {
          'bg-white': !props?.imageUrl?.includes('null'),
          'bg-[#edf2f5]': props?.imageUrl?.includes('null'),
        })}
      >
        {/* <Logo src={props.imageUrl} className='w-[54px] h-[54px] object-contain rounded-xl bg-white' defaultLogo='company' /> */}
        {props.imageUrl && <img src={props.imageUrl} className="object-contain w-[54px] min-w-[54px] max-w-[54px] h-[54px] min-h-[54px] max-h-[54px]" alt="" onError={(evt) => onError(evt, companyDefault)} />}

      </div>

      <div className="text-[14px] leading-[18px] text-[#0B1221] capitalize font-semibold mt-3 text-center line-clamp-1 max-w-[116.8px]">{props.companyName}</div>
      <div className="text-[11px] font-normal leading-[16px] line-clamp-1 max-w-[116.8px]">{props.sponsorship}</div>
    </div>
  )
};

export const EventPartners = (props: { eventPartners: IEventSponsor[] }) => {
  const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;
  const navigate = useNavigate();

  return (
    <div className="grid gap-[10px] gap-y-6 sm:grid-cols-5 grid-cols-2 py-2">
      {props.eventPartners &&
        props.eventPartners.length > 0 &&
        props.eventPartners.map((sponsor, index) => (
          <div className="cursor-pointer" key={`sponsor-${index}`} onClick={() => navigate(`/wall/company/${sponsor?.company?.slug}`)}>
            <PartnerItem
              imageUrl={`${imageUrlPrefix}/${sponsor?.logo || sponsor?.company?.logo}`}
              companyName={sponsor?.company?.name}
              sponsorship={sponsor?.sponsorship}
            />
          </div>
        ))}
    </div>
  );
};

export default EventPartners;
