/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { useEffect } from 'react';

import { ContactUsProps } from './types';
import './contact-us.css';
import ContactUsImage from './images/contact-us-picture.webp';

export const ContactUs = ({ prefillEmail, selectedTab }: ContactUsProps) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.formcrafts.com/embed.js';
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div id="contact-us" className="w-full">
      <div className="contact-app-wrapper virtual mem component">
        <h3 className="text-white md:leading-[42px] leading-[34px] md:text-[50px] text-[26px] font-bold helvetica-neue-65-medium text-left">Contact us</h3>
        <div className="contact-col-1">
          <img src={ContactUsImage} className="conatct-img hidden md:inline-block lg:inline-block" alt="contact-us" />
          <div className="contact-form-embed w-embed w-script">
            <div data-fc-key="7a856a8b" style={{ height: '523.38px' }} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
