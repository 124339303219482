import React, {
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import verifiedIcon from '../../../../assets/images/verified-icon.svg';
import { ICompanyHeader } from '../../../../models/company/company-header';
import { IAppState } from '../../../../store';
import {
  followCompany,
  getCompanyProfilePreview,
  unFollowCompany,
} from '../../../../store/company/profile-preview/actions';
import { CompanyPostSvgIcon, EventsSvgIcon, SpotLightSvgIcon } from '../../../../shared-components/V2/IconSvg';

export const CompanyHeaderInfo = (props: ICompanyHeader) => {
  const { companyProfilePreview } = useSelector((state: IAppState) => state);

  const dispatch = useDispatch();

  const [followButtonCaption, setFollowButtonCaption] = useState('');
  const [isOwner, setIsOwner] = useState(false);
  const [isAdminView, setIsAdminView] = useState(false);

  useEffect(() => {
    if (props.isFollowing === 0) setFollowButtonCaption('Follow');
    if (props.isFollowing === 1) setFollowButtonCaption('Unfollow');
  }, [props.isFollowing]);

  useEffect(() => {
    setIsOwner(Boolean(companyProfilePreview?.value?.isAdmin));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfilePreview]);

  const onPostsClick = () => {
    if (props.getActiveTab) props.getActiveTab('post');
  };

  const onSpotlightClick = () => {
    if (props.getActiveTab) props.getActiveTab('spotlight');
  };

  const onEventsClick = () => {
    if (props.getActiveTab) props.getActiveTab('event');
  };

  const onFollowClick = async () => {
    if (props.isFollowing === 1) {
      await dispatch(unFollowCompany(props.id));
    } else if (props.isFollowing === 0) {
      await dispatch(followCompany(props.id));
    }
    await dispatch(getCompanyProfilePreview(props.id));
  };

  const goToWebSite = () => {
    if (!companyProfilePreview.value?.website) return;

    const startWithHttp = companyProfilePreview.value?.website?.startsWith('http')
      || companyProfilePreview.value?.website?.startsWith('//');

    if (startWithHttp) {
      window.open(companyProfilePreview.value?.website, '_blank')?.focus();
    } else {
      window.open(`//${companyProfilePreview.value?.website}`, '_blank')?.focus();
    }
  };

  const onAdminClick = () => {
    setIsAdminView(false);
    setIsOwner(true);
  };

  const onMemberClick = () => {
    setIsAdminView(true);
    setIsOwner(false);
  };

  return (
    <>
      {props?.id && (
        <>
          <div className="mx-auto flex flex-row mt-[15px] relative">
            <div className="text-lg text-black font-semibold leading-[26px]">
              {props.name}
            </div>
            <div className="ml-0.5 -mt-[6px] absolute top-[3px] -right-[16px]">
              <img className="w-[15px]" src={verifiedIcon} alt="" />
            </div>

          </div>
          <div className="text-center text-md2 font-medium mt-2.5 leading-[21px] mb-[3px] sm:w-[50%] w-full sm:px-0 px-4 mx-auto">
            {companyProfilePreview.value?.tagline}
          </div>
          <div className="text-center text-sm7 mb-[3px]">
            {companyProfilePreview.value?.industry?.name}
          </div>
          <div className="flex justify-center mb-2.5 gap-1">
            <div className="text-center text-sm7">
              {companyProfilePreview.value?.headquarter}
            </div>
            <div className="text-center text-sm7">
              {companyProfilePreview.value?.headquarter && '•'}
            </div>
            <div className="text-center text-sm7">
              {/* {companyProfilePreview.value?.totalFollowing} */}
              1000+
              {' '}
              followers
            </div>
          </div>
        </>
      )}

      {!props.isEditCoverPhoto ? (
        <>
          <div className="flex mx-auto gap-3 md:px-0 px-3 flex-wrap">
            {!isOwner && isAdminView && (
              <button
                type="button"
                className="bg-[#0049EF] hover:bg-[#2e6eff] rounded-[5px] text-white px-[20px] h-[30px] text-[13px] font-semibold flex items-center"
                onClick={onAdminClick}
              >
                View as an admin
              </button>
            )}
            {isOwner ? (
              <>
                <button
                  type="button"
                  className="bg-[#0049EF] hover:bg-[#2e6eff] rounded-[5px] text-white px-[20px] h-[30px] text-[13px] font-semibold flex items-center"
                  onClick={onMemberClick}
                >
                  View as a member
                </button>
                <Link
                  to={`/wall/company/${companyProfilePreview.value?.slug}/edit`}
                  className="bg-[#EDF2F5] hover:bg-blue-0 px-[28px] h-[30px] rounded-[5px] text-[13px] font-semibold flex items-center"
                >
                  Edit page
                </Link>
              </>
            ) : (
              <>
                <button
                  type="button"
                  onClick={onFollowClick}
                  className="bg-[#0049EF] hover:bg-[#2e6eff] rounded-[5px] px-8 py-1.5 text-sm leading-5 text-white font-semibold"
                >
                  {followButtonCaption}
                </button>
                <button
                  type="button"
                  onClick={goToWebSite}
                  className="bg-[#EDF2F5] hover:bg-[#d8e0e5] w-[142px] px-4 py-1.5 rounded-[5px] text-sm leading-5 text-dark-blue font-semibold"
                >
                  Visit website
                </button>
              </>
            )}
          </div>
          <div className="flex flex-row mt-5 mb-[0px] mx-auto">
            {props.showPosts && (
              <div onClick={onPostsClick} className="flex flex-col items-center hover:cursor-pointer">
                <div className="flex justify-center">
                  {
                    props.activeTab === 'post'
                      ? <CompanyPostSvgIcon className="w-[34px] h-[37px]" isActive />
                      : <CompanyPostSvgIcon className="w-[34px] h-[37px]" />
                  }
                </div>
                <div
                  className={classNames('text-sm text-gray-17 mt-2', {
                    'border-b-2 border-y-indigo-900 text-dark-blue font-medium': props.activeTab === 'post',
                    'font-normal': props.activeTab !== 'post'
                  })}
                >
                  Posts
                </div>
              </div>
            )}
            {props.showTechShows && (
              <div onClick={onSpotlightClick} className="flex flex-col mx-10 items-center hover:cursor-pointer">
                <div className="flex justify-center">
                  {
                    props.activeTab === 'spotlight'
                      ? <SpotLightSvgIcon className="w-[34px] h-[37px]" isActive />
                      : <SpotLightSvgIcon className="w-[34px] h-[37px]" />
                  }
                </div>
                <div
                  className={classNames('text-sm text-gray-17 mt-2', {
                    'border-b-2 border-y-indigo-900 text-dark-blue font-medium': props.activeTab === 'spotlight',
                    'font-normal': props.activeTab !== 'spotlight'
                  })}
                >
                  Spotlight
                </div>
              </div>
            )}

            {props.showEvents && (
              <div onClick={onEventsClick} className="flex flex-col items-center hover:cursor-pointer">
                <div className="flex justify-center">
                  {
                    props.activeTab === 'event'
                      ? <EventsSvgIcon className="w-[34px] h-[37px]" isActive />
                      : <EventsSvgIcon className="w-[34px] h-[37px]" />
                  }
                </div>
                <div
                  className={classNames('text-sm text-gray-17 mt-2', {
                    'border-b-2 border-y-indigo-900 text-dark-blue font-medium': props.activeTab === 'event',
                    'font-normal': props.activeTab !== 'event'
                  })}
                >
                  Events
                </div>
              </div>
            )}
          </div>
        </>
      ) : null}

    </>
  );
};

export default CompanyHeaderInfo;
