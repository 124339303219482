import { Stack, Tooltip } from '@mui/material';
import {
  format,
  parseISO,
} from 'date-fns';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeRoomsTreeLobby } from 'src/utils/rooms-lobby';
// components
import Image from 'src/mui/components/image';
import SecondaryBanner from "./Images/SecondaryBanner.jpg"

type AgendaProps = {
  event?: any;
}

function createData(
  name: string,
  calories: string,
) {
  return { name, calories };
}

// const rows = [
//   createData('Guests Registration', '30 mins'),
//   createData('CIO Fireside Chat / Breakfast', '120 mins'),
//   createData('Guests Registration', '30 mins'),
//   createData('Opening', '5 mins'),
//   createData('Welcome Message', '10 mins'),
// ];

const headCellStyle = {
  backgroundColor: '#4d144a',
  color: '#ffffff',
  fontFamily: 'Poppins-600',
  fontSize: '1rem',
  p: '0.5rem',
}
const bodyCellStyle = {
  border: '1px solid #4d144a',
  p: '0.5rem',
  fontSize: '15px',
  fontFamily: 'Poppins-400',
  color: '#212529',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100px'
  // width: "100%"
}

export const Agenda = ({ event }: AgendaProps) => {
  const roomTree = makeRoomsTreeLobby(event?.rooms);
  return (
    <Stack>
      {roomTree.map((item: any, index: number) => {
        return (
          <Stack key={index} sx={{ mb: '1rem' }}>
            <Stack
              sx={{
                backgroundColor: '#4d144a',
                color: '#ffffff',
                alignItems: 'center',
                justifyContent: 'center',
                height: '44px',
                fontFamily: 'Poppins-500',
                fontSize: '1.2rem',
              }}
            >
              {item?.date &&
                format(
                  parseISO(item?.date),
                  "d MMMM yyyy"
                )}
            </Stack>
            {item?.roomsList.length > 0 && item?.roomsList.map((item: any, index: number) => (
              <>
                {item?.sessions.length > 0 && (
                  <Typography
                    variant="body2"
                    noWrap
                    sx={{
                      whiteSpace: 'initial',
                      fontSize: '1.9rem',
                      my: '1rem',
                      color: '#4d144a',
                      fontFamily: 'Poppins-600',
                      lineHeight: '30px'
                    }}
                  >
                    {item?.name}
                  </Typography>
                )}
                {item?.sessions.length > 0 && (
                  <Stack
                    sx={{
                      border: '2px solid #4d144a',
                      mb: '1rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '44px'
                    }}
                  >
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{
                        whiteSpace: 'initial',
                        fontSize: '1.2rem',
                        // my: '0.5rem',
                        color: '#4d144a',
                        fontFamily: 'Poppins-600',
                        lineHeight: '20px'
                      }}
                    >
                      {item?.eventDay &&
                        format(
                          parseISO(item?.eventDay),
                          "d MMMM yyyy"
                        )} | {item?.sessions.length > 0 &&
                          format(
                            parseISO(item?.sessions[0]?.startTime),
                            'hh:mm a'
                          )}
                    </Typography>
                  </Stack>
                )}

                {item?.sessions.length > 0 && (
                  <TableContainer>
                    <Table
                      // sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >

                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #dee2e6",
                              ...headCellStyle,
                              width: "75%"
                            }}
                          >
                            Agenda
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ ...headCellStyle }}
                          >
                            Duration
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item?.sessions &&
                          item?.sessions.map((item: any, index: number) => {
                            return item.type === "break" ? (
                              <TableRow key={index}>
                                <TableCell colSpan={2} sx={{
                                  "&.MuiTableCell-root": {
                                    padding: "1rem 0px",
                                    borderBottom: '1px solid #4d144a'
                                  }
                                }}>

                                  <Stack
                                    sx={{
                                      backgroundColor: '#4d144a',
                                      color: '#ffffff',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      height: '25px',
                                      // border: '1px solid #4d144a',
                                      // p: '0.5rem',
                                      fontSize: '15px',
                                      fontFamily: 'Poppins-400',
                                      // color: 'white',
                                      // overflow: 'hidden',
                                      // textOverflow: 'ellipsis',
                                      // whiteSpace: 'nowrap',
                                      // maxWidth: '100px'
                                    }}
                                  >

                                    {item?.title}

                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ) : (
                              <TableRow key={index}>
                                <TableCell sx={bodyCellStyle}>
                                  <Tooltip title={item?.title}>
                                    {item?.title}
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="center" sx={bodyCellStyle}>{`${item?.duration / 60} mins`}</TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </>
            ))}
          </Stack>
        )
      })}
      {/* <Stack
        sx={{
          backgroundColor: '#4d144a',
          color: '#ffffff',
          alignItems: 'center',
          justifyContent: 'center',
          height: '25px',
          fontSize: '15px',
          fontFamily: 'Poppins-400',
          mb: "1rem !important"
        }}
      >
        Networking, Solutions booth showcase
      </Stack> */}
      <Image
        src={SecondaryBanner}
        alt={SecondaryBanner}
        sx={{
          display: { md: "none", lg: "none", xl: "none", sm: 'none', xs: "block" },
        }}
      />
    </Stack>
  );
};
