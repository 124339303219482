import { Autoplay, EffectFade, Pagination } from 'swiper';
import { format, parseISO } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  useCallback,
  useEffect, useMemo, useRef, useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from 'react-router';
// import { Loader } from '../../../shared-components/Loader';
import {
  IconButton,
  Tooltip,
} from '@mui/material';
import RegistrationStepForm from 'src/mui/pages/event/reg-dialog-flow';
import { getTickets } from 'src/store/ticket';
import { EventQuestionPageTypes, useSurveyContext } from 'src/mui/providers/GeneralContext';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { getMinifiedImage, getS3Path } from 'src/mui/utils/image';
import { IAppState } from '../../../store';
import { rgbToHex } from '../../../utils/color-transformation';
import { dateAndTimeWithTz } from '../../../utils/date-timezone';
import { ParseContent } from '../../../utils/parse-html';
import { getTimeZone } from '../../../utils/timezones';
import { Logo } from '../../../shared-components/V2/Logo';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { useCurrentUser } from '../../../utils/hooks';
// import { getUpcomingEvent } from '../../../store/events/upcoming-event/actions';
import { CalendarList } from '../registration/event-components/calendar-list';
import { GotoList } from '../registration/event-components/goto-list';

export const hideScrollbarY = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

export const Banner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const swiperRefLocal = useRef<any>(null);

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };
  const { eventGallery } = useSurveyContext();

  const [showModal, setShowModal] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('Pause');
  const videoRef: React.RefObject<HTMLVideoElement> = useRef(null);
  const [muteVideo, setMuteVideo] = useState(true);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video?.paused || video?.ended) {
      video.play();
      setTooltipTitle('Pause');
    } else {
      video?.pause();
      setTooltipTitle('Play');
    }
  };

  useEffect(() => {
    videoRef?.current?.play();
  }, []);

  const handleQueryParams = useCallback((search: string) => {
    const getQueryParams = (search: string) => {
      const queryParams = new URLSearchParams(search);
      const params: Record<string, string> = {};
      const entries: [string, string][] = Array.from(queryParams.entries());
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of entries) {
        params[key] = value;
      }
      return params;
    };

    const queryParams = getQueryParams(search);
    const { eventFrom } = queryParams;
    if (eventFrom === 'register') {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    const delay = 500;
    const timer = setTimeout(() => {
      handleQueryParams(location.search);
    }, delay);
    return () => clearTimeout(timer);
  }, [location, handleQueryParams]);

  const event = useSelector((state: IAppState) => state.website.event!);

  const { getQuestionPages, setEventIdParam } = useSurveyContext();

  const { upcomingEvent } = useSelector(
    (state: IAppState) => state
  );
  // const audienceStatus = useSelector((state: IAppState) => state?.upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus);
  const isSurveySubmitted = upcomingEvent?.value?.data?.attendeeStatus?.isSurveySubmitted;
  const audienceStatus = upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus;
  const isRegistered = upcomingEvent?.value?.data?.attendeeStatus?.isRegistered;

  const { registered } = useSelector(
    (state: IAppState) => state.website
  );

  const isImage = (item: any) => item?.slice(0, 5) === 'image';

  useEffect(() => {
    if (user) {
      // dispatch(getUpcomingEvent(event.id));
      dispatch(getTickets(event.id));
    }
  }, [dispatch, event.id, user]);

  useEffect(() => {
    if (event?.id) {
      setEventIdParam(event?.id!);
      if (user) {
        getQuestionPages(EventQuestionPageTypes.REGISTRATION, event?.id!);
        getQuestionPages(EventQuestionPageTypes.SURVEY, event?.id!);
      }
    }
  }, [event?.id, user]);

  const checkLumination = () => {
    if (!event?.backgroundDominantColor?.length) return false;
    const luma = 0.2126 * event?.backgroundDominantColor[0]
      + 0.7152 * event?.backgroundDominantColor[1]
      + 0.0722 * event?.backgroundDominantColor[2];
    return luma <= 139.731;
  };

  const getBackgroungGradient = () => {
    if (!event?.backgroundDominantColor?.length) return '#0D4552';
    return `${rgbToHex(event?.backgroundDominantColor)}`;
  };

  const getTextColor = () => {
    if (checkLumination()) return '#FFFFFF';
    return '#ffffff';
  };

  const getTimeZoneName = (zone: string) => {
    const shortTimeZone = getTimeZone(zone)?.[0]?.abbr ? getTimeZone(zone)?.[0]?.abbr : zone;
    return shortTimeZone;
  };

  const eventDateTimeWithTz = useMemo(() => {
    const startTime = dateAndTimeWithTz(event?.startTime, event?.timezone);
    const endTime = dateAndTimeWithTz(event?.endTime, event?.timezone);
    return {
      startTime,
      endTime
    };
  }, [event?.startTime, event?.endTime, event?.timezone]);

  const eventDateTimeLocalTz = useMemo(() => {
    const startTime = format(parseISO(event?.startTime), 'hh:mm aa')?.replace('.', '')?.replace(' ', '')?.replace('.', '');
    const endTime = format(parseISO(event?.endTime), 'hh:mm aa')?.replace('.', '')?.replace(' ', '')?.replace('.', '');
    const timeZoneMatched = new Date(eventDateTimeWithTz.startTime.dateAndTime).getMinutes()

      === new Date(parseISO(event?.startTime)).getMinutes()

      && new Date(eventDateTimeWithTz.startTime.dateAndTime).getHours()

      === new Date(parseISO(event?.startTime)).getHours();

    return {
      startTime,
      endTime,
      timeZoneMatched
    };
  }, [event?.startTime, event?.endTime]);

  // const handleRegistration = () => {
  //   navigate(`/event/${event.slug}/spectre/vipreg`);
  // };

  const handleRegistration = () => {
    if (!registered) setShowModal(true);
    // if (!registered && event?.isTicketed && user) setOpenTicketModal(true);

    if (user && registered) navigate(`/event/${event?.slug}/discussion`);
    if (
      user
      && registered === false
      && event?.type === 'invitation'
      && audienceStatus === 'accepted'
    ) { navigate(`/event/${event?.slug}/discussion`); }
  };

  const onLoginToRegister = () => {
    window.open('https://members.sit-down.com/auth', '_blank');
  };

  const showPortalButton = () => {
    if (event?.type === 'open') {
      if ((registered || isRegistered)) return true;
      return false;
    }

    if (event?.type === 'invitation') {
      if ((audienceStatus === 'accepted' || (audienceStatus === 'pending' && !isSurveySubmitted)) && (registered || isRegistered)) return true;
      return false;
    }
    return false;
  };

  return (
    <div id="Banner" className="w-full overflow-x-clip">
      <div className="block md:flex">
        <div
          className="w-screen bg-gradient-to-b px-[0px] xl:px-0 relative"
          style={{
            background: `linear-gradient(${getBackgroungGradient()}, transparent)`,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Swiper
            modules={[Pagination, Autoplay, EffectFade]}
            pagination={{
              dynamicBullets: false,
              clickable: true,
            }}
            ref={swiperRefLocal}
            autoplay={{
              delay: 9000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
              // stopOnLastSlide: false,
              waitForTransition: false
            }}
            effect="fade"
            className="mySwiper"
          >
            {
              eventGallery.map((item, index) => {
                if (isImage(item?.key)) {
                  return (
                    <SwiperSlide key={`swiper-slide-banner-${index}`}>
                      <img src={getMinifiedImage(item?.key)} alt="" />
                      <div
                        style={{
                          zIndex: 10,
                          backgroundImage: 'linear-gradient(transparent 35%,#000)',
                          position: 'absolute',
                          top: '0%',
                          bottom: '0%',
                          left: '0%',
                          right: '0%',
                        }}
                      />
                    </SwiperSlide>
                  );
                } else {
                  return (
                    <SwiperSlide key={`swiper-slide-banner-${index}`}>
                      <video ref={videoRef} style={{ width: '100%', height: '100%', objectFit: 'cover', }} loop muted={muteVideo} autoPlay>
                        {' '}
                        <source src={getS3Path(item?.key)} type="video/mp4" />
                        {' '}
                        <source src={getS3Path(item?.key)} type="video/webm" />
                        {' '}
                        <track default kind="captions" />
                        {' '}
                      </video>
                      <div
                        style={{
                          // left: 140,
                          position: 'absolute',
                          textAlignLast: 'end',
                          bottom: 100,
                          opacity: 0.8,
                          zIndex: 100000,
                          right: 20,
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Tooltip title={tooltipTitle}>
                          <IconButton onClick={handlePlayPause} sx={{ color: 'white' }}>
                            {tooltipTitle === 'Pause' ? <PauseIcon /> : <PlayArrowIcon />}
                          </IconButton>
                        </Tooltip>

                        <Tooltip title={!muteVideo ? 'mute' : 'unmute'}>
                          <IconButton onClick={() => setMuteVideo(!muteVideo)} sx={{ color: 'white' }}>
                            {muteVideo ? <VolumeOffIcon /> : <VolumeUpIcon />}
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          zIndex: 10,
                          backgroundImage: 'linear-gradient(transparent 35%,#000)',
                          position: 'absolute',
                          top: '0%',
                          bottom: '0%',
                          left: '0%',
                          right: '0%',
                        }}
                      />
                    </SwiperSlide>
                  );
                }
              })
            }
          </Swiper>
          <div
            className="z-10 absolute left-0 bottom-[55px]"
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                maxHeight: '420px',
              }}
            />
            <div className="w-full text-white flex flex-col items-left justify-end rounded-b-[0px] h-[100%] relative">
              <div className="w-full md:pl-[40px] px-3">
                <div
                  className="flex justify-left items-center w-full sm:w-[454px] mb-5 md:px-0"
                >
                  {event?.logo && event?.logo !== 'removed' && (
                    <Logo
                      src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event.logo}`}
                      className="flex justify-center h-full max-h-52 object-contain object-left w-full"
                      alt={event?.name}
                      defaultLogo="company"
                    />
                  )}
                </div>
                {event.theme && (
                  <div
                    className="flex md:items-center items-start justify-left font-light mb-[8px] max-w-full helvetica-neue-45-light"
                    style={{
                      color: getTextColor(),
                    }}
                  >
                    <div className="text-[15px] leading-[15px] mr-1 helvetica-neue-45-light">Theme:</div>
                    <ParseContent content={event.theme} className="text-left text-[15px] leading-[17px] helvetica-neue-45-light" />
                  </div>
                )}
                {event?.format && (
                  <div
                    className="flex items-center justify-left font-light mb-[10px] max-w-full helvetica-neue-45-light"
                    style={{
                      color: getTextColor(),
                    }}
                  >
                    <div className="text-[15px] leading-[15px] mr-1 helvetica-neue-45-light">Format:</div>
                    <div className="text-left text-[15px] leading-[15px] capitalize helvetica-neue-45-light">{event.format === 'live' ? 'In-Person' : event.format}</div>
                  </div>
                )}
                {event?.location && (
                  <div
                    className={classNames('flex text-left justify-left items-start font-light mb-[10px] capitalize', {
                      hidden: !event?.location,
                    })}
                    style={{
                      color: getTextColor(),
                    }}
                  >
                    <div className="text-[15px] leading-[15px] mr-1 helvetica-neue-45-light">Location:</div>
                    <div className="text-left text-[15px] leading-[15px] helvetica-neue-45-light">{event?.locationName}</div>
                  </div>
                )}
                <div
                  className={classNames('text-[15px] leading-[15px] mb-[10px] flex text-left justify-left items-center font-bold capitalize helvetica-neue-45-light', {
                    hidden: !eventDateTimeWithTz.startTime.date,
                  })}
                  style={{
                    color: getTextColor(),
                  }}
                >
                  {eventDateTimeWithTz.startTime.dateLongFormat}
                  <span className="mx-[3px] leading-[15px]"> • </span>
                  <span className="uppercase helvetica-neue-45-light leading-[15px]">
                    {eventDateTimeWithTz?.startTime?.time}
                  </span>
                  -
                  <span className="text-[15px] leading-[15px] uppercase mr-[3px] helvetica-neue-45-light">{eventDateTimeWithTz.endTime.time}</span>
                  {getTimeZoneName(event?.timezone)}
                </div>
                {!eventDateTimeLocalTz.timeZoneMatched && (
                  <div
                    className={classNames('text-[15px] hidden leading-[15px] text-[#e4e2e2] md:flex text-left justify-left font-normal capitalize break-words helvetica-neue-45-light', {
                      hidden: !eventDateTimeLocalTz?.startTime,
                    })}
                  >
                    <span className="text-[15px] leading-[15px]">
                      {format(parseISO(event.startTime), 'MMMM dd, yyyy')}
                    </span>
                    <span className="mx-[3px] text-[15px] leading-[15px]"> • </span>
                    <span className="uppercase helvetica-neue-45-light text-[15px] leading-[15px]">
                      {eventDateTimeLocalTz.startTime}
                    </span>
                    -
                    <span className="uppercase mr-[3px] helvetica-neue-45-light text-[15px] leading-[15px]">{eventDateTimeLocalTz.endTime}</span>
                    <span>(Your Local Time)</span>
                  </div>
                )}
                <div className="flex justify-left mt-[20px] helvetica-neue-45-light">
                  {(registered === false || isRegistered) && isSurveySubmitted && event?.type === 'invitation' && audienceStatus === 'pending' ? (
                    <div className="flex sm:flex-row flex-col sm:items-center items-start gap-[10px] mt-[9px]">
                      <div className="flex-shrink-0">
                        <GotoList
                          fillColor="white"
                          titleClass="text-[16px] text-white tracking-wide mr-[10px] helvetica-neue-65-medium"
                          btnClassName="text-white bg-[#333333] rounded-5 h-[36px] px-[15px] flex items-center"
                          title="Go to"
                          dropdownClass="w-[113px]"
                          isInvitation
                        />
                      </div>
                      <div className="flex-shrink-0">
                        <CalendarList
                          fillColor="white"
                          titleClass="text-[16px] text-white tracking-wide mr-[0px] helvetica-neue-65-medium"
                          btnClassName="text-white bg-[#333333] rounded-5 h-[36px] px-[15px] flex items-center"
                          title="Registration under review"
                          isInvitation
                        />
                      </div>
                    </div>
                  ) : null}
                  {showPortalButton() ? (
                    <div className="flex sm:flex-row flex-col sm:items-center items-start gap-[10px] mt-[9px]">
                      <div className="flex-shrink-0">
                        <GotoList
                          fillColor="white"
                          titleClass="text-[16px] text-white tracking-wide mr-[10px] helvetica-neue-65-medium"
                          btnClassName="text-white bg-[#333333] rounded-5 h-[36px] px-[15px] flex items-center"
                          title="Go to"
                          dropdownClass="w-[113px]"
                        />
                      </div>
                      <div className="flex-shrink-0">
                        <CalendarList
                          fillColor="white"
                          titleClass="text-[16px] text-white tracking-wide mr-[10px] helvetica-neue-65-medium"
                          btnClassName="text-white bg-[#333333] rounded-5 h-[36px] px-[15px] flex items-center"
                          title="Add to calendar"
                        />
                      </div>
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          // eslint-disable-next-line max-len
                          className={classNames('px-[12px] leading-[18px] text-black bg-white hover:bg-[#333333] hover:text-white tracking-wide rounded-[6px] w-[220px] h-[36px] text-[16px] helvetica-neue-55-roman font-semibold flex items-center justify-center')}
                          onClick={() => setShowModal(true)}
                        >
                          Portal
                        </button>
                      </div>
                    </div>
                  ) : !registered
                  && audienceStatus !== 'pending'
                  && (
                    <div className="flex items-center gap-x-[15px]">
                      <button
                        type="button"
                        // eslint-disable-next-line max-len
                        className={classNames('px-[12px] leading-[18px] text-black bg-white hover:bg-[#333333] hover:text-white tracking-wide rounded-[6px] w-[220px] h-[36px] text-[16px] helvetica-neue-55-roman font-semibold flex items-center justify-center')}
                        onClick={() => handleRegistration()}
                      >
                        Register Now
                      </button>
                      <a href="#Contact us">
                        <button
                          type="button"
                          // eslint-disable-next-line max-len
                          className={classNames('px-[12px] leading-[18px] text-black bg-white hover:bg-[#333333] hover:text-white tracking-wide rounded-[6px] w-[220px] h-[36px] text-[16px] helvetica-neue-55-roman font-semibold flex items-center justify-center')}
                        >
                          Contact us
                        </button>
                      </a>
                    </div>

                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[20px] hidden helvetica-neue-45-light absolute sm:right-[55px] right-[10px] sm:bottom-[55px] bottom-2 z-10">
            <button
              type="button"
              // eslint-disable-next-line max-len
              className={classNames('px-[16px] leading-[18px] text-black bg-white hover:bg-[#333333] hover:text-white tracking-wide rounded-[6px] w-[242px] h-[36px] text-[16px] flex items-center justify-center helvetica-neue-65-normal')}
              onClick={() => onLoginToRegister()}
            >
              <strong className="mr-2.5 helvetica-neue-65-medium leading-[17px]">Member?</strong>
              <div className="w-[120px]">Login to register</div>
            </button>
          </div>
        </div>
      </div>
      {
        showModal && (
          <RegistrationStepForm
            eventId={event?.id!}
            user={user}
            open={showModal}
            registered={registered!}
            eventInvitationStatus={audienceStatus!}
            eventType={event?.type!}
            setOpenForm={() => setShowModal(false)}
          />
        )
      }
    </div>
  );
};
