import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentUser } from '../../utils/hooks';

export const Redirect = () => {
  const location = useLocation();
  const user = useCurrentUser();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect');
    if (redirect?.length && user) {
      const url = decodeURIComponent(redirect);
      const redirectUrl: any = redirect ? atob(url) : null;
      window.location.replace(redirectUrl);
    } else {
      window.location.replace('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};
