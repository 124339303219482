import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ISearch, ResourcesObject } from '../../models/nav-bar';
import { ICompanyProfile } from '../../models/company/company-profile';
import { IEvent } from '../../models/event/event';
import { IUserProfile } from '../../models/user-profile';
import { SearchActionTypes } from './types';
import { UserService } from '../../services';

export interface ISearchLoadingAction {
  type: SearchActionTypes.SEARCH_LOADING;
  payload: {
    isLoading: boolean;
  };
}

export interface ISearchSuccess {
  type: SearchActionTypes.SEARCH_SUCCESS;
  payload: {
    searchInput: string;
    companies: ICompanyProfile[];
    events: IEvent[];
    resources: ResourcesObject[];
    users: IUserProfile[];
  };
}

export interface ISearchError {
  type: SearchActionTypes.SEARCH_ERROR;
  payload: {
    isLoading: boolean;
    errorMessage: string;
  };
}

export interface ISearchFinal {
  type: SearchActionTypes.SEARCH_FINAL;
  payload: {
    isLoading: boolean;
  };
}

export type SearchActions = ISearchLoadingAction | ISearchSuccess | ISearchError | ISearchFinal;

export const setIsLoading: ActionCreator<
  ThunkAction<Promise<any>, ISearch, null, ISearchLoadingAction>
> = (loading: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SearchActionTypes.SEARCH_LOADING,
      payload: loading
    });
  };
};

export const resetSearchResults : ActionCreator<
ThunkAction<Promise<any>, ISearch, null, ISearchSuccess>
> = () => {
return async (dispatch: Dispatch) => {
  dispatch({
    type: SearchActionTypes.SEARCH_SUCCESS,
    payload: {
      searchInput: '',
      companies:[],
      events:[],
      resources: [],
      users: []
    }
  });
}}

export const getSearchResults: ActionCreator<
  ThunkAction<Promise<any>, ISearch, null, ISearchSuccess>
> = (search: string) => {
  return async (dispatch: Dispatch) => {
    let companies;
    let events;
    let resources;
    let users;
    const userService: UserService = new UserService();

    try {
      const result = await userService.navBarSearch(search);
      companies = result?.data?.companies?.data;
      events = result?.data?.events?.data;
      resources = result?.data?.resources?.data;
      users = result?.data?.users?.data;

      dispatch({
        type: SearchActionTypes.SEARCH_SUCCESS,
        payload: {
          searchInput: search,
          companies,
          events,
          resources,
          users
        }
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: SearchActionTypes.SEARCH_ERROR,
        payload: {
          isLoading: false,
          errorMessage: error.message
        }
      });
    } finally {
      dispatch({
        type: SearchActionTypes.SEARCH_FINAL,
        payload: {
          isLoading: false
        }
      });
    }
  };
};
