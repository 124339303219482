/* eslint-disable no-nested-ternary */
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useState } from 'react';
import { ReactComponent as Profile } from '../../../assets/images/sidebar/profile.svg';
import { ReactComponent as Account } from '../../../assets/images/sidebar/account.svg';
import { ReactComponent as Billing } from '../../../assets/images/sidebar/billing.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/images/sidebar/logout.svg';
import { IAppState } from '../../../store';
import AlertModal from '../../../components/alert-modal/alert-modal';
import EventCompleteProfile from '../../event/registration/event-signup/event-completeprofile';
import { resendEmailVerification } from '../../../store/auth';
import { useCurrentUser } from '../../../utils/hooks';

export const UserAccountSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser: { isEmailVerified } = {}, hideEmailVerification } = useSelector((state: IAppState) => state?.auth);
  const [openInterestModal, setOpenInterestModal] = useState(false);
  const handleVerification = () => {
    dispatch(resendEmailVerification());
  };
  const user = useCurrentUser();
  const handleInterest = () => {
    setOpenInterestModal(true);
  };

  const onDeleteAccount = (item: string) => {
    if (item === '#delete-account') {
      navigate('/settings/account-and-privacy/deactivate-account');
    }
  };

  const sidebarItems = [
    {
      label: 'My profile',
      img: Profile,
      borderTop: false,
      isParent: true,
      link: '#',
      isInternalLink: false,
      display: true
    },
    {
      label: 'Intro',
      link: `/wall/${user.slug}?intro=intro`,
      isInternalLink: false,
      display: true
    },
    {
      label: 'Experience',
      link: `/wall/${user.slug}?experience=experience`,
      isInternalLink: false,
      display: true
    },
    {
      label: 'Areas of interest',
      link: '#',
      isInternalLink: false,
      display: true,
      onActionClick: handleInterest,
    },
    {
      label: 'Account & privacy',
      img: Account,
      borderTop: true,
      isParent: true,
      link: '#',
      isInternalLink: false,
      display: true
    },
    {
      label: 'Account settings',
      link: '#account-settings',
      isInternalLink: true,
      display: true
    },
    {
      label: 'Notifications',
      link: '#notifications',
      isInternalLink: true,
      display: true
    },
    {
      label: 'Blocked profiles',
      link: '#blocked-profiles',
      isInternalLink: true,
      display: true
    },
    {
      label: 'Privacy settings',
      link: '#privacy-settings',
      isInternalLink: true,
      display: true
    },
    {
      label: 'Subscription & billing',
      img: Billing,
      borderTop: true,
      isParent: true,
      link: '/',
      isInternalLink: true,
      display: true
    },
    {
      label: 'Payment details',
      link: '#subscription',
      isInternalLink: true,
      display: true
    },
    {
      label: 'Subscription plan',
      link: '#subscription',
      isInternalLink: true,
      display: true
    },
    {
      label: 'Transaction history',
      link: '#transaction-history',
      isInternalLink: true,
      display: true
    },
    {
      label: 'Verify email now',
      borderTop: true,
      isParent: true,
      link: '#',
      isInternalLink: false,
      onActionClick: handleVerification,
      display: !isEmailVerified && !hideEmailVerification
    },
    {
      label: 'Help & support',
      img: null,
      borderTop: true,
      isParent: true,
      link: '#',
      isInternalLink: false,
      display: true
    },
    {
      label: 'Delete Account',
      link: '#delete-account',
      isInternalLink: true,
      display: true
    },
    {
      label: 'Log out',
      img: LogoutIcon,
      borderTop: !hideEmailVerification,
      isParent: true,
      link: '/logout',
      isInternalLink: false,
      // onActionClick: handleLogout,
      display: true
    }
  ];
  return (
    <aside className="flex w-full pt-8" aria-label="Sidebar">
      <div className="overflow-y-auto w-[203px] ml-[14px] mt-1">
        <ul>
          {sidebarItems.map(item => {
            if (item.display) {
              return (
                <li key={item.label} className={classNames({ 'mb-5': item.isParent, 'mb-2.5': !item.isParent })}>
                  {item.borderTop && <div className="border-t border-[#707070] mt-1.5 mb-4" />}
                  {item.isInternalLink ? (
                    item?.link === '#delete-account' ? (
                      <span
                        className={classNames({
                          'font-medium text-[15px] leading-5 text-[#172746]': item.isParent,
                          'font-normal text-sm leading-5 tracking-wider text-[#172746] cursor-pointer': !item.isParent,
                          'ml-0 ': item.img,
                          'ml-[26px]': !item.img
                        })}
                        onClick={() => onDeleteAccount(item?.link)}
                      >
                        {item.label}
                      </span>
                    ) : (
                      <a
                        href={item.link}
                        data-test-id="my-profile-link"
                        className={classNames('flex items-center', {
                          // 'pt-2 pb-3': item.isParent,
                          // 'pb-2': !item.isParent
                        })}
                        onClick={() => item.onActionClick}
                      >
                        {item.img && (
                        <span className="w-[25px] min-w-[25px]"><item.img /></span>
                        )}
                        <span
                          className={classNames({
                            'font-medium text-[15px] leading-5 text-[#172746] ': item.isParent,
                            'font-normal text-sm leading-5 tracking-wider text-[#172746]': !item.isParent,
                            'ml-0 ': item.img,
                            'ml-[26px]': !item.img
                          })}
                          onClick={() => onDeleteAccount(item?.link)}
                        >
                          {item.label}
                        </span>
                      </a>
                    )
                  ) : (
                    <Link
                      to={item.link}
                      data-test-id="my-profile-link"
                      className={classNames('flex items-center', {
                        // 'pt-2 pb-3 ': item.isParent,
                        // 'pb-2': !item.isParent
                      })}
                      onClick={() => { if (item.onActionClick) item.onActionClick(); }}
                    >
                      {item.img && (
                        <span className="w-[25px] min-w-[25px]"><item.img /></span>
                      )}
                      <span
                        className={classNames({
                          'font-medium text-[15px] leading-5 text-[#172746] ': item.isParent,
                          'font-normal text-sm leading-5 tracking-wider text-[#172746]': !item.isParent,
                          'ml-0': item.img,
                          'ml-[26px]': !item.img
                        })}
                      >
                        {item.label}
                      </span>
                    </Link>
                  )}
                </li>
              );
            } else return '';
          })}
        </ul>
      </div>
      <AlertModal
        onClose={() => setOpenInterestModal(false)}
        isOpen={openInterestModal}
        className="md:w-[1100px]"
      >
        <EventCompleteProfile from="setting" />
      </AlertModal>
    </aside>
  );
};

export default UserAccountSidebar;
