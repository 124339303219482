import {
  ActionCreator,
  Dispatch,
} from 'redux';
import { ThunkAction } from 'redux-thunk';

import {
  IOwnExperienceResult,
  OwnExperience,
} from '../../../models/own-experience';
import { OwnExperienceState } from '../../../models/OwnExperienceState';
import { UserService } from '../../../services';
import { OwnExperienceActionTypes } from './types';

export interface IOwnExperienceLoadingAction {
  type: OwnExperienceActionTypes.OWN_EXPERIENCE_LOADING;
  loading: boolean;
}

export interface IOwnExperienceSuccessAction {
  type: OwnExperienceActionTypes.OWN_EXPERIENCE_SUCCESS;
  payload: OwnExperience;
}

export interface IOwnExperienceErrorAction {
  type: OwnExperienceActionTypes.OWN_EXPERIENCE_ERROR;
  errorMessage: string;
}

export interface IOwnExperienceCleanupAction {
  type: OwnExperienceActionTypes.OWN_EXPERIENCE_CLEANUP;
}

export type OwnExperienceActions =
  | IOwnExperienceLoadingAction
  | IOwnExperienceSuccessAction
  | IOwnExperienceErrorAction
  | IOwnExperienceCleanupAction;

export const getOwnExperience: ActionCreator<
  ThunkAction<
    Promise<any>,
    OwnExperienceState,
    null,
    IOwnExperienceSuccessAction
  >
> = (userIdOrSlug: string = '') => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();
    let result: IOwnExperienceResult | null = null;

    try {
      if (userIdOrSlug.trim() !== '')
        result = await userService.getUserExperience(userIdOrSlug);
      else result = await userService.getOwnExperience();

      dispatch({
        type: OwnExperienceActionTypes.OWN_EXPERIENCE_SUCCESS,
        payload: result.data,
      });
    } catch (error: any) {
      error && console.error(error);
      dispatch({
        type: OwnExperienceActionTypes.OWN_EXPERIENCE_ERROR,
        errorMessage: error?.message,
      });
    } finally {
      dispatch({
        type: OwnExperienceActionTypes.OWN_EXPERIENCE_LOADING,
        loading: false,
      });
    }
  };
};

export const loadOwnExperiences: ActionCreator<
  ThunkAction<any, OwnExperienceState, null, IOwnExperienceLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: OwnExperienceActionTypes.OWN_EXPERIENCE_LOADING,
    loading: shouldLoad,
  });


  export const cleanupOwnExperiences: ActionCreator<
  ThunkAction<any, OwnExperienceState, null, IOwnExperienceLoadingAction>
> = () => (dispatch: Dispatch) =>
  dispatch({
    type: OwnExperienceActionTypes.OWN_EXPERIENCE_CLEANUP
  });
