import classNames from "classnames";
import { useSelector } from "react-redux";

import { IAppState } from "../../store";
import { useCurrentUser, useWebSocket } from "../../utils/hooks";
import { ConversationDetail } from "./conversation-detail";
import MessageInput from "./message-input";
import MessageWindowTitle from "./message-window-title";
import { useEffect, useState } from "react";

const ChatWindow = (props: {
  conversationId: string;
  userId: string;
  avatar: string;
  title: string;
  messageText?: string;
  onCloseHandler: () => void;
}) => {
  const { webSocket } = useWebSocket();
  const user = useCurrentUser();
  const { privateChat } = useSelector((state: IAppState) => state);
  const messages =
    privateChat?.oneToOneMessages?.[props.conversationId]?.data || [];
  const isNewMessage =
    privateChat?.oneToOneMessages?.[props.conversationId]?.isNewMessage || null;
  const [isOpen, setIsOpen] = useState(false);
  const [editMessage, setEditMessage] = useState<any>(null);
  const [replyMessage, setReplyMessage] = useState<any>(null);
  const [file, setFile] = useState<any>(null);

  const clearUnseenMessage = () => {
    webSocket.then((ws) =>
      ws.send(
        JSON.stringify({
          action: "clear-unseen-message",
          data: {
            conversationId: props.conversationId,
          },
        })
      )
    );
  };

  const seenMessage = () => {
    const lastMessage = messages.filter(
      (item: any) => item.sender !== user?.id
    );
    if (lastMessage) {
      webSocket.then((ws) =>
        ws.send(
          JSON.stringify({
            action: "seen-message",
            data: {
              messageId: lastMessage[lastMessage.length - 1]?.messageId,
            },
          })
        )
      );
    }
  };

  useEffect(() => {
    if (!isOpen) {
      seenMessage();
    }
  }, [isOpen, isNewMessage]);

  return (
    <div
      className={classNames(
        "mr-2 bg-white shadow-xs9 rounded-tl-xl rounded-tr-xl delay-400 duration-500 ease-in-out transition-all cursor-pointer",
        {
          " -translate-y-[26.5rem]": !isOpen,
          " translate-y-[0rem]": isOpen,
        }
      )}
    >
      <div className={"overflow-hidden inset-0"}>
        <div
          className={classNames(
            "w-[330px] max-w-lg right-80 h-full shadow-xs3 delay-400 duration-500 ease-in-out transition-all transform"
          )}
        >
          <div className="w-full flex flex-col h-full">
            <div className="flex flex-col">
              <div>
                <MessageWindowTitle
                  userId={props.userId}
                  avatar={props.avatar}
                  username={props.title}
                  conversationId={props?.conversationId!}
                  onCloseHandler={props.onCloseHandler}
                  onMessageBoxTitleClick={() => setIsOpen(!isOpen)}
                />
              </div>
              <div className="bg-white">
                <ConversationDetail
                  conversationId={props.conversationId}
                  editMessage={editMessage}
                  onMessageUpdate={setEditMessage}
                  onMessageReply={setReplyMessage}
                  isDocument={file}
                  isReply={replyMessage}
                />
                <div
                  className={`px-3 mx-auto mt-2 mb-1 h-[180px] ${
                    file && editMessage && replyMessage && "h-[180px]"
                  } ${file && "h-[180px]"} ${replyMessage && "h-[180px]"} ${
                    editMessage && "h-[90px] border-t-[0.5px] border-[#626262]"
                  }`}
                >
                  {/* need to be fix - currently timestamps just placeholder - maybe will be done by fahad */}
                  {/* <div className="flex justify-center font-semibold text-blue-2 text-x-sm text-center mx-auto">April 22, 2021</div> */}
                  <MessageInput
                    recepientId={""}
                    replyMessage={replyMessage}
                    conversationId={props.conversationId}
                    messageText={props.messageText}
                    userName={props.title}
                    editMessage={editMessage}
                    onFocusHandler={() => clearUnseenMessage()}
                    setEditMessage={setEditMessage}
                    onCancelReplyMessage={() => {
                      setReplyMessage(null);
                      setEditMessage(null);
                    }}
                    setFile={setFile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
