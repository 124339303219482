import React from 'react';
import { getBrandingColors } from '../event/live/liveEventStyling';

function SortByIcon() {
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.389" height="7.748" viewBox="0 0 11.389 7.748">
  <path id="Polygon_9" data-name="Polygon 9" d="M4.889,1.1A1,1,0,0,1,6.5,1.1l3.719,5.06a1,1,0,0,1-.806,1.592H1.976A1,1,0,0,1,1.17,6.156Z" transform="translate(11.389 7.748) rotate(180)" fill={defaultColor}/>
</svg>

    );
}

export default SortByIcon;