/* eslint-disable max-len */
import React from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { IEvent } from '../../../../models/event/event';
import { IAppState } from '../../../../store';

const FactorEventOverview: React.FC = () => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const eventOverview = event?.overview && parse(DOMPurify.sanitize(event?.overview));
  return (
    <div className="flex flex-col max-w-[1033px] px-[15px] mx-auto md:mb-[150px] mb-[55px]">
      <div className="text-white md:leading-[59px] leading-[45px] md:text-[52px] text-[37px] font-bold helvetica-neue-65-medium text-center mb-[5px]">{event.theme}</div>
      <div className="max-w-[776px] mx-auto text-white md:leading-[23px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman text-center">{eventOverview}</div>
    </div>
  );
};

export default FactorEventOverview;
