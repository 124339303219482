import React, { useState } from "react";
import { Autocomplete, TextField, SxProps } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { Theme } from "@mui/material/styles";
import { LoadScriptNext } from "@react-google-maps/api";

interface Location {
  id: string;
  city: string;
  region: string;
  country: string;
  name: string;
}

interface HeadquarterLocationProps {
  name: string;
  label?: string;
  onSelectLocation?: (location: Location) => void;
  disabled?: boolean;
  isLenovo?: boolean;
  helperText?: React.ReactNode;
  placeholder?: string;
  sx?: SxProps<Theme>;
  className?: string;
}

const libraries: ("places")[] = ["places"];

const HeadquarterLocation: React.FC<HeadquarterLocationProps> = ({
  name,
  label,
  onSelectLocation,
  disabled,
  isLenovo,
  placeholder,
  helperText,
  sx,
  className,
}) => {
  const { control } = useFormContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [locations, setLocations] = useState<Location[]>([]);

  const handleInputChange = (value: string) => {
    setSearchTerm(value);

    if (value.trim() !== "") {
      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["(cities)"],
        },
        async (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
            const placesService = new google.maps.places.PlacesService(document.createElement('div'));
            
            const detailedPlaces = await Promise.all(predictions.map(async (prediction) => {
              return new Promise<Location>((resolve) => {
                placesService.getDetails(
                  { placeId: prediction.place_id, fields: ['name', 'address_components'] },
                  (place, detailStatus) => {
                    if (detailStatus === google.maps.places.PlacesServiceStatus.OK && place) {
                      const city = place.address_components?.find(comp => comp.types.includes('locality'))?.long_name || '';
                      const region = place.address_components?.find(comp => comp.types.includes('administrative_area_level_1'))?.long_name || '';
                      const country = place.address_components?.find(comp => comp.types.includes('country'))?.long_name || '';
                      
                      resolve({
                        id: prediction.place_id,
                        city: city,
                        region: region,
                        country: country,
                        name: `${city}${region ? `, ${region}` : ''}${country ? `, ${country}` : ''}`
                      });
                    } else {
                      resolve({
                        id: prediction.place_id,
                        city: '',
                        region: '',
                        country: '',
                        name: prediction.description
                      });
                    }
                  }
                );
              });
            }));

            setLocations(detailedPlaces);
          }
        }
      );
    } else {
      setLocations([]);
    }
  };
  return (
    <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!} libraries={libraries}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            className={className}
            freeSolo
            options={locations}
            getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
            disabled={disabled}
            filterOptions={(x) => x}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                variant="outlined"
                onChange={(e) => handleInputChange(e.target.value)}
                value={searchTerm}
                error={!!error}
                helperText={error ? error?.message : helperText}
              />
            )}
            onChange={(_, newValue) => {
              field.onChange(newValue);
              if (onSelectLocation && typeof newValue === "object") {
                onSelectLocation(newValue as Location);
              }
            }}
            onInputChange={(_, newInputValue) => {
              setSearchTerm(newInputValue);
            }}
            clearOnBlur
            clearOnEscape
          />
        )}
      />
    </LoadScriptNext>
  );
};

export default HeadquarterLocation;