/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-imports-ts */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import {
  useNavigate,
  useParams,
  Link,
  useSearchParams,
  useLocation,
  matchPath,
} from 'react-router-dom';

import classNames from 'classnames';
import { getOwnConnections } from 'src/store/user/own-connections/actions';
import { getRecommendedEvents } from 'src/store/user/recommended-events/actions';
import { IEvent } from 'src/models/user-events';
import { ConnectSuggestion } from 'src/models/connect-suggestions';
import StoryContainer from 'src/shared-components/V2/CardContainer/StoryContainer';
import { EventsLeftSvgIcon } from 'src/shared-components/V2/IconSvg/EventsSvgIcon/EventsLeftSvgIcon';
import { getAllStories } from 'src/store/user/story/actions';
import { useWebSocket } from 'src/utils/hooks';
import { MeetingRoomModal } from 'src/mui/pages/event/live/join-meeting/meeting-room';
import profileBackground from '../../assets/images/avatar-default.svg';
import userHomeIcon from '../../assets/images/left-menu-icons/home.svg';
// import userLongueIcon from '../../assets/images/left-menu-icons/lounge.svg';
import userEventIcon from '../../assets/images/left-menu-icons/events.svg';
import userSpotlightIcon from '../../assets/images/left-menu-icons/spotlight.svg';
import commentIcon from '../../assets/images/comment-icon.svg';
import noPostsImage from '../../assets/images/no-post-new-discussion.svg';
import shareIcon from '../../assets/images/share-icon.svg';
import { CardMainPost } from '../../components/posts';
import CreatePost from '../../components/posts/create-post';
import { WelcomePost } from '../../shared-components/V2/WelcomePost';
import DefaultLayout from '../../layouts/default-layout';
import { ICreatePoll } from '../../models/poll/poll';
import { ICreateComment } from '../../models/post/comment';
import { IPost } from '../../models/post/post';
import { PostService, UserService } from '../../services';

import { CardContainer } from '../../shared-components/V2/CardContainer';
import { CardEventsInterestedContainer } from '../../shared-components/V2/CardEventsInterestedContainer';
import { IconNavMenuCard } from '../../shared-components/V2/IconMenuCard';
import { NavMenuCard } from '../../shared-components/V2/NavMenuCard';
import { CardProfile } from '../../shared-components/V2/CardProfile';
import { Loader } from '../../shared-components/Loader';
import { SortBy } from '../../shared-components/V2/SortBy';
import { YourEvents } from '../../shared-components/V2/YourEvents';
import { IAppState } from '../../store';
import { editPost } from '../../store/events/event-post-actions/actions';
import {
  likeComment,
  unlikeComment,
} from '../../store/events/event-post-comment-like/actions';
import {
  likePost,
  unlikePost,
} from '../../store/events/upcoming-event-post-like/actions';
import { createPoll } from '../../store/poll';
import { createPostComment } from '../../store/posts/comments/actions';
import {
  getConnectSuggestions,
  setConnectSuggestion,
} from '../../store/user/connect-suggestions/actions';
import {
  getUserPostComments,
  getUserPosts,
} from '../../store/user/posts/actions';
import { getUserEvents } from '../../store/user/user-events/actions';
import Footer from '../../shared-components/V2/Footer/footer';
import AlertModal from '../../components/alert-modal/alert-modal';
import SpotlightDetails from '../../components/spotlight/spotlight-details';
import { AdvertisingCard } from '../../shared-components/V2';
import PanelistAd from '../../assets/images/panelist-ad.svg';
import { getCompanySlug, shuffleArray, sortByKey } from '../../utils';
import { EventsService } from '../../services/events';
import {
  HomeIconSvg, SearchIcon, SpotLightSvgIcon
} from '../../shared-components/V2/IconSvg';
import { IUserProfile } from '../../models/user-profile';
import PeopleListCard from '../search-results/peopleListCard';
import { ICompanyEmployee } from '../../models/company/company-employee';
import { closeMessageWindow, openMessageWindow } from '../../store/newMessage';

const recommendationCall = false;

export const UserHome = () => {
  const dispatch = useDispatch();
  const { webSocket } = useWebSocket();
  const [searchParams, setSearchParams] = useSearchParams();
  const { slug: userId } = useParams();
  const selector = useSelector((state: IAppState) => state);
  const { joinMeeting } = useSelector((state: IAppState) => state.liveEvent);
  const { currentUser: user } = selector.auth;
  const peopleYouManyKnow = selector.connectSuggestions?.value?.data;
  const { page, totalPage } = selector.connectSuggestions;
  const feeds: any = selector.userPosts;
  const { allStories } = selector;

  const userUpcomingEvents: any = selector.userEvents;
  const { recommendedEvents } = selector;

  const [showSpotlightEvent, setshowSpotlightEventEvent] = useState<IPost>();
  const [userFeeds, setUserFeeds] = useState<IPost[]>([]);
  const [nextPage, setNextPage] = useState(1);
  const [peopleYouManyKnowData, setPeopleYouManyKnowData] = useState<ConnectSuggestion[] | undefined>([]);
  // const [nextPage, setNextPage] = useState(2);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  // const [totalPage, setTotalPage] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [allStoriesData, setAllStoriesData] = useState<any>();
  const [addNewStory, setAddNewStory] = useState<any>(null);
  const [eventSummary, setEventSummary] = useState<any>();
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState<'Recent' | 'Top'>(
    'Recent'
  );
  const [showMoreConnections, setShowMoreConnections] = useState(false);
  const [yourEventConnections, setYourEventConnections] = useState<
    any[] | undefined
  >();
  const [filteredConnections, setFilteredConnections] = useState<IUserProfile[]>();
  const [search, setSearch] = useState<string>();
  const resetToDefaults = () => {
    setNextPage(2);
    // setTotalPage(0);
    setFetching(false);
    setUserFeeds([]);
  };

  const userService: UserService = new UserService();

  const groupBy = (arr: any, property: string) =>
    // eslint-disable-next-line prefer-arrow-callback, implicit-arrow-linebreak
    arr.reduce((memo: any, x: any) => {
      // eslint-disable-next-line no-param-reassign
      if (!memo[x[property]]) { memo[x[property]] = []; }
      memo[x[property]].push(x);
      return memo;
    }, {});
  useEffect(() => {
    setIsOwnProfile(user?.id === userId || user?.slug === userId);
    webSocket.then((ws) => {
      ws.send('{"action": "ping","data": { "data": "PONG"}}');
    });
  }, [userId, user, webSocket]);

  useEffect(() => {
    setPeopleYouManyKnowData(peopleYouManyKnow);
  }, [peopleYouManyKnow]);

  useEffect(() => {
    if (isOwnProfile) {
      dispatch(getOwnConnections());
    } else dispatch(getOwnConnections(userId));
  }, [dispatch, isOwnProfile, userId]);

  useEffect(() => {
    dispatch(getAllStories());
    dispatch(getConnectSuggestions());
    dispatch(getUserEvents());
    dispatch(getUserPosts());
    dispatch(getRecommendedEvents());
  }, [dispatch]);

  const fetchSpotlight = async (id: string) => {
    const postService: PostService = new PostService();
    try {
      const selectedSpotlight = await postService.getPost(id);
      setshowSpotlightEventEvent(selectedSpotlight);
    } catch (e) {
      console.error(e);
    }
  };

  const mergeFeedsAndRecommendations = (
    feeds: IEvent[] = [],
    recommendation: IEvent[] = []
  ) => {
    const temp: any = recommendation.map((evt: any) => ({
      ...evt.post,
      type: 'recommended',
      recommended: { ...evt },
    }));
    const recommendedEvents = recommendation.map((r) => r.id);
    const newFeeds = feeds.filter((post) => !recommendedEvents.includes((post as IPost)?.event?.id));
    return shuffleArray([
      ...newFeeds,
      ...temp,
    ]);
  };

  // useEffect(() => {
  //   if (
  //     (feeds.value?.totalPage === 0 && feeds.value?.data?.length === 0 && !recommendationCall)

  //   ) {
  //     recommendationCall = true;
  //     dispatch(getRecommendedEvents());
  //   }
  // }, [feeds]);

  useEffect(() => {
    if (
      (feeds.value?.totalPage && feeds.value?.data)
      || !!recommendedEvents.value?.totalRow
    ) {
      resetToDefaults();
      // setTotalPage(feeds.value?.totalPage);
      const computedFeeds: IPost[] = feeds.value?.totalRow <= 10
        ? sortByKey(
          mergeFeedsAndRecommendations(
            feeds.value?.data,
            recommendedEvents?.value?.data
          ) as IPost[],
          'id'
        )
        : feeds?.value?.data;
      setUserFeeds(computedFeeds);
      // setUserFeeds(feeds.value?.data);
      setHasMoreItems(feeds?.value?.limit === feeds?.value?.totalRow);
      const postId = searchParams.get('postId');
      if (postId) fetchSpotlight(postId);
    }
    // if (feeds.value?.totalPage === 0 && feeds.value?.data?.length === 0 && recommendedEvents?.value?.data?.length) {
    //   const computedFeeds: IPost[] = mergeFeedsAndRecommendations(
    //     feeds.value?.data || [],
    //     recommendedEvents?.value?.data
    //   ) as IPost[];
    //   // eslint-disable-next-line no-unused-vars
    //   setUserFeeds((prevUserFeeds) => computedFeeds);
    // }

    if (feeds.value?.totalRow === 0) {
      setHasMoreItems(false);
    }
  }, [feeds, searchParams, recommendedEvents]);

  useEffect(() => {
    const postId = searchParams.get('postId');
    if (postId) {
      fetchSpotlight(postId);
    }
  }, [searchParams]);

  useEffect(() => {
    const allStoriesData :any = [];
    allStories?.value?.data?.forEach((story :any, index:number) => {
      const header = {
        heading: `${story?.creator?.firstName} ${story?.creator?.lastName}`,
        subheading: `${story?.creator?.firstName}`,
        profileImage: story?.creator?.avatar ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${story?.creator?.avatar}` : profileBackground
      };
      if (!story?.thumbnail) {
        allStoriesData.push({
          url: `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${story?.source}`,
          type: 'image',
          header,
          duration: 3000,
          id: story?.creator?.id,
          story,
          //  eslint-disable-next-line react/no-unstable-nested-components
          seeMore: ({ close }:any) => (
            story?.content ? (
              <div
                onClick={close}
                className="absolute left-0 bottom-0 p-[15px] w-full text-white"
                style={{ background: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))', paddingTop: '100px' }}
              >
                {story?.content}
              </div>
            ) : null
          ),
          // eslint-disable-next-line react/no-unstable-nested-components
          // content: ({ close }:any) => <div onClick={close}>Hello, click to close this.</div>,
        });
      } else if (story?.thumbnail) {
        allStoriesData.push({
          // url: `${process.env.REACT_APP_DEFAULT_VIDEO_HLS_URL}/${story?.hlsUrl1}`,
          url: `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${story?.source}`,
          type: 'video',
          header,
          // duration: 1500,
          id: story?.creator?.id,
          story,
          // eslint-disable-next-line react/no-unstable-nested-components
          seeMore: ({ close }:any) => (
            story?.content ? (
              <div
                onClick={close}
                className="absolute left-0 bottom-0 p-[15px] w-full text-white"
                style={{ background: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))', paddingTop: '100px' }}
              >
                {story?.content}
              </div>
            ) : null
          ),
        });
      }
    });
    const userGroupedStories = groupBy(allStoriesData, 'id');
    const useStories :any = [];
    Object.keys(userGroupedStories)?.forEach((userId :string) => {
      const story = userGroupedStories[userId]?.[0]?.story;
      useStories?.push({
        ...story,
        userStories: userGroupedStories[userId],
      });
    });

    setAllStoriesData(useStories);
  }, [allStories]);

  useEffect(() => {
    new EventsService().getEventSummary().then((res) => {
      setEventSummary(res?.data);
    });
  }, []);

  useEffect(() => {
    if (yourEventConnections) {
      if (search?.length) {
        const filteredList = yourEventConnections.filter((c: any) => `${c.firstName} ${c.lastName}`?.toLowerCase()?.includes(search));
        setFilteredConnections(filteredList);
      } else {
        setFilteredConnections(yourEventConnections);
      }
    }
  }, [search, yourEventConnections]);

  const cardItems = [
    {
      text: 'Pending invitations',
      url: '/events/pending-invitations',
      hide: !eventSummary?.pendingInvitations,
    },
    {
      text: 'My upcoming events',
      url: '/events/upcoming-events',
      hide: !eventSummary?.upcommingEvents,
    },
    {
      text: 'Past events',
      url: '/events/past-events',
      hide: !eventSummary?.pastEvents,
    },
    {
      text: "Events I'm hosting",
      url: '/events/my-hosted-events',
      hide: !eventSummary?.hostingEvents,
    },
    {
      text: 'Events I’m interested in',
      url: '/events/my-interested-events',
      hide: !eventSummary?.eventInterested,
    },
  ];

  const cardMenuItems = [
    { text: 'Home', url: '/home', icon: 'home' },
    // { text: 'Lounge', url: '/lounge', icon: userLongueIcon },
    { text: 'Events', url: '/events', icon: 'event' },
    { text: 'Spotlight', url: '/spotlight', icon: 'spotlight' },
  ];

  const onUserClick = (url: string | undefined) => {
    navigate(`${url}`);
  };

  const onCompanyClick = () => {
    const companySlug = getCompanySlug(user);
    if (!companySlug) return;
    navigate(`/wall/company/${companySlug}`);
  };

  const onNavClick = (url: string | undefined) => {
    navigate(`${url}`);
  };

  const onClickEvent = (url: string | undefined) => {
    navigate(`${url}`);
  };

  const fetchPosts = (orderPostBy?: string) => dispatch(getUserPosts(1, orderPostBy ?? orderBy));

  const fetchPostComments = async (postId: string, page?: number) => dispatch(getUserPostComments(postId, page));

  const fetchItems = useCallback(
    async () => {
      if (fetching) {
        return;
      }

      setFetching(true);

      let sortBy: string = '';
      if (orderBy === 'Recent') sortBy = '&order[createdAt]=DESC';
      else sortBy = '&order[rank]=DESC';

      try {
        const [posts, suggestions] = await Promise.all([
          userService.getAllPosts(nextPage, sortBy),
          userService.getPostsSuggestions(nextPage - 1, sortBy)
        ]);
        const feedData = [...suggestions.data, ...posts.data];
        if (posts && hasMoreItems) {
          setUserFeeds(userFeeds.concat(shuffleArray(feedData)));

          // setUserFeeds((prevState) => [...prevState, result.data, suggestions.data]);
          setHasMoreItems(posts.limit === posts.totalRow);
          setNextPage(nextPage + 1);
        } else {
          setFetching(true);
        }
      } finally {
        setFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userFeeds, fetching, nextPage]
  );

  // const hasMoreItems = nextPage <= 3;
  // const hasMoreItems = nextPage <= totalPage;

  const loader = <Loader className="pt-10" key="loader" />;

  const sortClick = (sortType: 'Recent' | 'Top') => {
    resetToDefaults();
    setOrderBy(sortType);
    fetchPosts(sortType);
  };

  const onConnectionsClicks = (connection: any) => {
    setYourEventConnections(connection);
    setShowMoreConnections(true);
  };
  // if (!userFeeds?.length) return <Loader isLoaderLogo className="h-[100vh]" key="loader" />;

  return eventSummary && user ? (
    <DefaultLayout
      leftPanel={(
        <div className="flex flex-col pt-6 max-h-[calc(100vh-54px)] overflow-scroll no-scrollbar">
          <div>
            <CardProfile
              avatar={
                user?.avatar
                  ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${user.avatar}`
                  : ''
              }
              name={`${user?.fullName}`}
              jobTitle={user?.jobTitle}
              company={user?.company?.name || user?.companyId}
              onUserClick={onUserClick}
              onCompanyClick={onCompanyClick}
              detailsHref={`/wall/${user?.slug}`}
              className="pb-6"
            />
          </div>
          <div className="w-full border-b-1 border-[#707070] opacity-[28%]" />
          <div>
            <IconNavMenuCard
              cardItems={cardMenuItems}
              onNavClick={onNavClick}
              className="ml-auto mt-0 pb-1"
              iconsClassName="w-[54px] -ml-[10px]"
            />
          </div>
          {userUpcomingEvents && !!userUpcomingEvents.value?.length && (
            <>
              <div className="w-full border-b-1 border-[#707070] opacity-[28%]" />
              <YourEvents
                events={userUpcomingEvents.value}
                baseUrl={process.env.REACT_APP_IMAGE_URL_PREFIX}
                onConnectionsClick={onConnectionsClicks}
              />
            </>
          )}
          <div className="w-full border-b-1 border-[#707070] opacity-[28%]" />
          <div className="my-events">
            <div className="rounded-lg md:w-full ml-auto mt-0 pb-2">
              <div className="mt-2">
                {eventSummary ? (
                  cardItems
                  && cardItems.map((cardItem, i) => (
                    <Link hidden={cardItem.hide} to={cardItem.url} key={i}>
                      <NavMenuCard
                        cardItemText={cardItem.text}
                        className="text-[13px] font-normal leading-[19px]"
                      />
                    </Link>
                  ))
                ) : (
                  <Loader className="pt-5" key="loader" />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      rightPanel={(
        <div className="max-h-[calc(100vh-54px)] overflow-y-scroll no-scrollbar pt-6 px-[2px]">
          {recommendedEvents?.value?.data?.length! > 0 && (
            <div className="">
              <CardEventsInterestedContainer
                events={recommendedEvents.value?.data!}
                name="Events you might be interested in"
                className="p-4 mb-[14px]"
                dropdownIcon="/images/Polygon-dropdown.svg"
                onClickEvent={onClickEvent}
              />
            </div>
          )}
          <AdvertisingCard imageUrl={PanelistAd} />
          <div className="pb-4">
            <Footer />
          </div>
        </div>
      )}
    >
      <div className="xl:w-[656px] lg:w-[50vw] md:w-[100vw] pb-8 px-3 pt-3 md:px-[31px] md:pt-6">
        {allStories && (
          <StoryContainer
            title=""
            cards={allStoriesData! || []}
            className="bg-transparent  rounded-lg w-full"
            onClick={async (id: string) => {
              await dispatch(setConnectSuggestion(id));
              await dispatch(getConnectSuggestions());
            }}
            loadMorePeople={async () => {
              const nextPage = page + 1;
              if (totalPage < nextPage) return;
              await dispatch(getConnectSuggestions(nextPage));
              setPeopleYouManyKnowData([...allStoriesData!, ...(allStoriesData! || [])]);
            }}
            shouldDisplayCard
            allStoriesData={allStoriesData}
            setAddNewStory={setAddNewStory}
          />
        )}

        <CreatePost
          wallId={user.id}
          location="profile"
          avatarUrl={
            user?.avatar
              ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${user.avatar}`
              : ''
          }
          userDisplayName={user.fullName!}
          onCreatePoll={(data: ICreatePoll) => dispatch(createPoll(data))}
          // onAfterSave={() => resetToDefaults()}  //todo: confirm the flow
          isHomeCreatePost
          addNewStory={addNewStory}
          setAddNewStory={setAddNewStory}
        />
        {/* <div className=" h-[1px] bg-[#CDDCF2] rounded-10 mt-[10px] mb-[8px]" /> */}
        <div className="flex items-center flex-row pt-[3px] pb-[3px] my-auto">
          <div className="w-full border-b-1 border-[#707070] opacity-[28%]" />
          <div>
            <SortBy
              sortClick={sortClick}
              descOrderText="Recent"
              ascOrderText="Top"
            />
          </div>
        </div>
        {peopleYouManyKnowData && (
          <CardContainer
            title="People you may know"
            cards={peopleYouManyKnowData}
            className="mt-0 bg-transparent mb-[2px] rounded-lg w-full"
            onClick={async (id: string) => {
              await dispatch(setConnectSuggestion(id));
              await dispatch(getConnectSuggestions());
            }}
            loadMorePeople={async () => {
              const nextPage = page + 1;
              if (totalPage < nextPage) return;
              await dispatch(getConnectSuggestions(nextPage));
              setPeopleYouManyKnowData([...peopleYouManyKnowData, ...(peopleYouManyKnow || [])]);
            }}
            shouldDisplayCard
          />
        )}

        <div className="overflow-visible">
          {!userFeeds?.length && !fetching ? (
            <Loader className="h-[100vh]" key="loader" />
          ) : (
            <InfiniteScroll
              pageStart={1}
              loadMore={fetchItems}
              hasMore={hasMoreItems}
              loader={loader}
            >
              {/* No Post Welcome component */}
              {(!userFeeds
                || userFeeds.length === 0
                || (!feeds?.value?.data?.length
                  && !recommendedEvents?.value?.data?.length)) && (
                  <WelcomePost
                    className="mt-4"
                    title={`Welcome to Panelist, ${user.firstName}!`}
                    imageSrc={noPostsImage}
                    subTitle="Connect with people you know and join events you're interested in."
                    subTitleClass="max-w-[282px] leading-[25px] text-black"
                  />
              )}
              {userFeeds
                && userFeeds.map(
                  (post: any, index: number) => post && (
                    <CardMainPost
                      commentIcon={commentIcon}
                      shareIcon={shareIcon}
                      className="mb-[14px] w-full bg-white create-post-card"
                      key={post.id}
                      post={post}
                      getPostComments={(id: string, page: number = 1) => fetchPostComments(id, page)}
                      addPostComment={async (
                        postId: string,
                        data: any,
                        attachment: any
                      ) => {
                        const comment: ICreateComment = {
                          content: data,
                          parentId: '',
                          userMentioned: '',
                        };
                        await dispatch(
                          createPostComment(postId, comment, attachment)
                        );
                        await fetchPostComments(postId);
                        // await fetchPosts();
                      }}
                      addReply={async (
                        postId: string,
                        replyText: string,
                        parentId: string,
                        attachment: any
                      ) => {
                        const comment: ICreateComment = {
                          content: replyText,
                          parentId,
                          userMentioned: '',
                        };
                        await dispatch(
                          createPostComment(postId, comment, attachment)
                        );
                        await fetchPostComments(postId);
                      }}
                      onCommentLike={async (
                        likeType: string,
                        commentId: string
                      ) => {
                        if (likeType) {
                          await dispatch(likeComment(commentId, likeType));
                        } else {
                          await dispatch(unlikeComment(commentId));
                        }
                        // await fetchPosts();
                      }}
                      onPostLike={async (
                        likeType: string,
                        postId: string
                      ) => {
                        // If you got like type then set the like on post else its remove like 'undefined'
                        if (likeType) {
                          await dispatch(likePost(postId, likeType));
                        } else {
                          await dispatch(unlikePost(postId));
                        }
                        // await fetchPosts();
                      }}
                      onDelete={async () => {
                        const postService = new PostService();
                        await postService.deletePost(post.id);

                        resetToDefaults();
                        await fetchPosts();
                      }}
                      onEditPost={async (
                        postId: string,
                        content: string,
                        privacy: string
                      ) => {
                        await dispatch(editPost(postId, content, privacy));
                        // resetToDefaults();
                        await fetchPosts();
                      }}
                      onAfterShare={async () => {
                        resetToDefaults();
                        await fetchPosts();
                      }}
                    />
                  )
                )}
            </InfiniteScroll>
          )}
        </div>
        <AlertModal
          isOpen={!!showSpotlightEvent}
          onClose={() => {
            setshowSpotlightEventEvent(undefined);
            searchParams.delete('postId');
            setSearchParams(searchParams, { replace: true });
          }}
          className={classNames('py-0 my-0 overflow-hidden mx-auto', {
            'w-[1124px]': ['image', 'video', 'document'].includes(
              showSpotlightEvent?.format!
            ),
            'w-[650px]': !['image', 'video', 'document'].includes(
              showSpotlightEvent?.format!
            ),
          })}
        >
          <SpotlightDetails
            isPostModal
            showSpotlightEvent={showSpotlightEvent}
            getSpotlight={fetchPosts}
            fetchSpotlight={fetchSpotlight}
            isSpotlightDetailModal
          />
        </AlertModal>
        <AlertModal
          isOpen={showMoreConnections}
          onClose={() => {
            setShowMoreConnections(false);
            setSearch('');
          }}
          className="w-576 p-2 container"
        >
          <>
            <div className="text-md px-2 py-1 pb-4">Mutual connections</div>
            <div className="px-2">
              <div className="px-3 py-1.5 flex items-end text-sm rounded-10 focus:outline-none w-[186px] h-[30px] border-1 border-blue-900 font-extralight text-gray-1 bg-gray-1">
                <SearchIcon />
                <input
                  name="search"
                  className="w-full ml-2 text-sm5 focus:outline-none block font-light text-gray-900 placeholder:text-gray-900 bg-transparent"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearch(e.target.value.toLowerCase());
                  }}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="border-b-1 border-gray-300 my-2" />
            <div className="w-full h-420 overflow-auto px-4">
              {filteredConnections && (
                <PeopleListCard
                  onClick={(user: IUserProfile) => {
                    setShowMoreConnections(false);
                    setSearch('');
                    navigate(`/wall/${user.slug ? user.slug : user.id}`);
                  }}
                  people={filteredConnections}
                  onMessage={async (user: ICompanyEmployee) => {
                    await dispatch(closeMessageWindow());
                    await dispatch(
                      openMessageWindow({
                        avatar: user.avatar,
                        userDisplayName: `${user.firstName} ${user.lastName}`,
                        userId: user.id,
                      })
                    );
                    setShowMoreConnections(false);
                    setSearch('');
                  }}
                  isDisabledConnect
                  key="user-connections"
                />
              )}
            </div>
          </>
        </AlertModal>
      </div>
    </DefaultLayout>
  ) : (
    <Loader isLoaderLogo className="h-[100vh]" key="loader" />
  );
};

export default UserHome;
