/* eslint-disable unused-imports/no-unused-imports-ts */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */
import {
  FC,
} from 'react';
import classNames from 'classnames';
import { DropDownMenu, IDropdownMenuItem } from 'src/components/dropdown-menu';
import { ReactComponent as EditSVG } from '../../../assets/images/company/edit.svg';
import { ReactComponent as DeleteSVG } from '../../../assets/images/company/trash.svg';

interface ICompanyHeaderDropdownProps {
  handleEdit: () => void;
  handleDelete: () => void;
}

const CompanyAccountDropdown: FC<ICompanyHeaderDropdownProps> = ({
  handleEdit,
  handleDelete,
}) => {
  const optionsToSelect: IDropdownMenuItem[] = [
    {
      id: 1,
      type: 'icon',
      text: 'Edit',
      icon: <EditSVG />,
      onClickHandler: () => handleEdit(),
    },
    {
      id: 'delete',
      type: 'icon',
      text: 'Delete',
      icon: <DeleteSVG />,
      onClickHandler: () => handleDelete(),
    },
  ];

  return (
    <DropDownMenu
      options={optionsToSelect}
      containerClasses="flex justify-center items-center"
      menuCardClasses={classNames({
        'absolute w-208 opacity-100 bg-white -ml-[210px] mt-4 shadow-xs2 text-gray-13 rounded-15 p-2 !z-50':
            true,
        hidden: false,
      })}
      titleAndSubtitleGroup="flex justify-left items-left hover:bg-gray-1 text-left p-2 rounded-5 text-gray-13 cursor-pointer"
      buttonStyles="flex items center ml-1"
      listTextStyles="text-sm1 text-blue-3 tracking-wide ml-3"
      listIconStyles=""
      textAndImageContainer="flex w-full h-full"
      fillColor="GRAY"
      fillColorActive
      isCompanyPage
    />
  );
};

export default CompanyAccountDropdown;
