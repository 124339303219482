import classNames from 'classnames';
import {
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { LOGIN_SIGNUP_RECOVERY } from '../../../constants';
import { IInterest } from '../../../models/interest/interest';
import { IAppState } from '../../../store';
import {
  getDefaultInterests,
  getInterests,
} from '../../../store/interestsAndOffers';
import { OfferInterestedSearchBox } from '../offer-interested-searchbox';
import OfferInterestedListButton from './offer-interested-list-button-new';
import { Loader } from '../../../shared-components/Loader';

interface IInterestedProps {
  from: string;
  page: number | null;
  interests?: {
    interests: any[],
    offers: any[]
  },
  widthStyle: string;
  isMainProfileMatch?: boolean;
  isFilterHieght?: boolean;
  setUserInterests?: (data: any) => void;
}
export interface IOfferInterestedModel {
  offerList: IInterest[];
  interestedList: IInterest[];
}

export interface ISignUp {
  offerListInterestedList: IOfferInterestedModel;
}

export const InterestedFormNEW = (_: IInterestedProps) => {
  const dispatch = useDispatch();
  const [offerListState, setOfferListState] = useState<IInterest[]>();
  const [interestListState, setInterestListState] = useState<IInterest[]>();
  // const [selectedInterest , setSelectedInterest] = useState([]);
  // const [userInterests, setUserInterests] = useState<InterestType>({
  //   offers: [],
  //   interests: []
  // });
  const [offerSearchBox, setOfferSearchBox] = useState(false);
  const [interestSearchBox, setInterestSearchBox] = useState(false);

  const {
    interests: interestsList,
    defaultInterests,
    defaultOffers,
    loading
  } = useSelector((state: IAppState) => state.interests);

  useEffect(() => {
    dispatch(getDefaultInterests('Both'));
  }, [dispatch]);

  useEffect(() => {
    setInterestListState(defaultInterests);
  }, [defaultInterests]);
  useEffect(() => {
    if (defaultOffers.length) {
      setOfferListState(defaultOffers);
    }
  }, [defaultOffers]);

  useEffect(() => {
    if (offerSearchBox) setOfferListState(interestsList);
  }, [interestsList, offerSearchBox]);

  useEffect(() => {
    if (interestSearchBox) setInterestListState(interestsList);
  }, [interestsList, interestSearchBox]);

  const selectToOffer = (offer: IInterest) => {
    const isAlreadySelected = _.interests?.offers?.find(o => o.id === offer.id);

    if (isAlreadySelected?.id) {
      if (_?.setUserInterests) _?.setUserInterests({ ..._.interests, offers: _.interests?.offers.filter(o => o.id !== offer.id) });
      return;
      // dispatch(selectedOffer(userInterests?.offers.filter(o => o.id !== offer.id)));
    }
    if (_?.setUserInterests) _?.setUserInterests({ ..._.interests, offers: [..._.interests?.offers!, offer] });
    // dispatch(selectedOffer([...userInterests?.offers, offer]));
  };

  const selectToInterest = (interest: IInterest) => {
    const isAlreadySelected = _.interests?.interests.find(i => i.id === interest.id);

    if (isAlreadySelected?.id) {
      if (_?.setUserInterests) _?.setUserInterests({ ..._.interests, interests: _.interests?.interests.filter(o => o.id !== interest.id) });
      return;
      // dispatch(selectedInterest(selectedInterests.filter(i => i.id !== interest.id)));
    }
    if (_?.setUserInterests) _?.setUserInterests({ ..._.interests, interests: [..._.interests?.interests!, interest] });
    // dispatch(selectedInterest([...selectedInterests, interest]));
  };

  const handleSearchOffer = (event: any) => {
    if (!event) setOfferListState([]);

    if (event.target.value !== '') {
      setOfferSearchBox(true);
      setInterestSearchBox(false);
      dispatch(getInterests(event.target.value));
    } else {
      const alreadySelected = _.interests?.offers?.some(offer => defaultOffers.includes(offer));

      let newDefaultValues = [];
      if (alreadySelected) {
        newDefaultValues = defaultOffers.filter(
          offer => _.interests?.offers?.includes(offer)
        );

        setOfferListState(newDefaultValues);
      } else setOfferListState(defaultOffers);
    }
  };
  const handleSearchInterest = (event: any) => {
    if (!event || event.target.value === '') {
      setOfferListState(defaultInterests);
    }

    if (event.target.value !== '') {
      setOfferSearchBox(false);
      setInterestSearchBox(true);
      dispatch(getInterests(event.target.value));
    } else {
      const alreadySelected = _.interests?.interests?.some(offer => defaultInterests.includes(offer));

      let newDefaultValues = [];
      if (alreadySelected) {
        newDefaultValues = defaultInterests.filter(
          interest => !_.interests?.interests?.includes(interest)
        );

        setInterestListState(newDefaultValues);
      } else setInterestListState(defaultOffers);
    }
  };

  const filteredOffersState = offerListState && offerListState.filter(el => !_.interests?.offers?.find(element => element.id === el.id));
  const filteredInterestedState = interestListState && interestListState.filter(el => !_.interests?.interests?.find(element => element.id === el.id));
  return (
    <div className={`w-full  ${_.widthStyle}`}>
      <div className="text-[#0B1221] font-medium text-[18px] leading-[26px] border-b-1 border-[#CDDCF2] pb-[9px] mb-[12px]">Get matched with others</div>
      <div className="text-[15px] leading-5 text-[#3C3C3C] text-left font-light w-full mb-[20px]">
        Let others know what you are interested in and what you can help with
      </div>
      <OfferInterestedSearchBox caption="I can help with ">
        <input
          onChange={e => handleSearchOffer(e)}
          placeholder={LOGIN_SIGNUP_RECOVERY.INTERESTED_OFFER_SEARCHBOX}
          className="text-[12px] font-light text-[#203C6E] py-1.5 px-3 pl-[36px] h-[30px] md:w-[340px] w-full rounded-10 placeholder:text-[#203C6E]"
          autoComplete="off"
        />
      </OfferInterestedSearchBox>
      <div className="border-b border-[#C6C6C6] my-2.5 w-full" />
      {!loading
        ? (
          <div
            className={classNames('overflow-y-auto mb-[20px] hide-scroll', {
              '!h-auto': _.isMainProfileMatch,
              '!h-[62px]': _.isFilterHieght
            })}
          >
            <div className="flex flex-wrap w-full py-0">
              {_.interests?.offers?.length! > 0
                && _.interests?.offers?.map(
                  (item: any, index: number) => (
                    <div className="mb-1 mr-1 h-[28px]" key={`offer-${index}`}>
                      <OfferInterestedListButton
                        displayInterest={false}
                        displayOffer
                        selectOffer={selectToOffer}
                        // selectInterest={selectToInterest}
                        item={item}
                        selected
                      />
                    </div>
                  )
                )}
              {
                filteredOffersState && filteredOffersState.length > 0
                && filteredOffersState?.map(
                  (item: any, index: number) => (
                    <div className="mb-1 mr-1 h-[28px]" key={`offer-filter-${index}`}>
                      <OfferInterestedListButton
                        displayInterest={false}
                        displayOffer
                        selectOffer={selectToOffer}
                        // selectInterest={selectToInterest}
                        item={item}
                        disabled={_.interests?.offers?.length! > 4}
                      />
                    </div>
                  )
                )
              }
            </div>
          </div>
        ) : <Loader className="pt-5 h-[30px]" key="loader" />}
      <OfferInterestedSearchBox caption="I am interested in ">
        <input
          onChange={e => handleSearchInterest(e)}
          placeholder={LOGIN_SIGNUP_RECOVERY.INTERESTED_OFFER_SEARCHBOX}
          className="text-[12px] font-light text-[#203C6E] py-1.5 px-3 pl-[36px] h-[30px] md:w-[340px] w-full rounded-10 placeholder:text-[#203C6E]"
          autoComplete="off"
        />
      </OfferInterestedSearchBox>
      <div className="border-b border-[#C6C6C6] my-2.5 w-full" />
      <div
        className={classNames(' overflow-y-auto hide-scroll', {
          '!h-auto': _.isMainProfileMatch,
          '!h-[59px]': _.isFilterHieght
        })}
      >
        {!loading
          ? (
            <div className="flex flex-wrap w-full py-0">
              {_.interests?.interests?.length! > 0
                && _.interests?.interests?.map(
                  (item: any, index: number) => (
                    <div className="mr-1" key={`offer-interested-${index}`}>
                      <OfferInterestedListButton
                        displayInterest
                        displayOffer={false}
                        selectInterest={selectToInterest}
                        item={item}
                        selected
                      />
                    </div>
                  )
                )}
              {filteredInterestedState && filteredInterestedState.length > 0
                && filteredInterestedState?.map(
                  (item: any, index: number) => (
                    <div className="mr-1" key={`interested-filter-${index}`}>
                      <OfferInterestedListButton
                        displayInterest
                        displayOffer={false}
                        // selectOffer={selectToOffer}
                        selectInterest={selectToInterest}
                        item={item}
                        disabled={_.interests?.interests?.length! > 4}
                      />
                    </div>
                  )
                )}
            </div>
          ) : <Loader className="pt-5 h-[30px]" key="loader" />}
      </div>
    </div>
  );
};
export default InterestedFormNEW;
