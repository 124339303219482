import { SVGProps } from 'react';

function WebsiteIcon(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props;
     const isFill =  style?.fill;
    return (
      
  <svg xmlns="http://www.w3.org/2000/svg" width="19.207" height="15" viewBox="0 0 19.207 15">
    <path id="Path_27158" data-name="Path 27158" d="M15,3l2,2H7A6,6,0,0,0,7,17H8.5V16H7A5,5,0,1,1,7,6H17L15,8h1.5L19,5.5,16.5,3Z" transform="translate(-0.5 -2.5)" fill={isFill} stroke={isFill} stroke-width="1"/>
    </svg>

  
    );
  }
  
  export default WebsiteIcon;