import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { IAppState } from '../../../store';
import { Logo } from '../../../shared-components/V2/Logo';
import { IEvent } from '../../../models/event/event';

const WebsiteEventPartner = () => {
  const partners = useSelector((state: IAppState) => state.website.event!);
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);

  if (!partners?.eventSponsors?.length) return null;

  return (
    <div
      id="partners"
      className={classNames('w-full mb-[15px]', {
        hidden: !(partners.eventSponsors && partners.eventSponsors.length > 0),
        'mb-[100px]': !(partners.format === 'hybrid'),
      })}
    >
      <div className="w-full event-website-box-new pt-[22.5px] pb-[36.5px]">
        <div
          className="font-semibold text-[#0326E5] text-[21px] leading-[25px] flex justify-center mb-[28px]"
          style={{
            color: event?.colorStyle?.values?.heading,
          }}
        >
          Event partners
        </div>
        <div className="flex flex-wrap max-w-[730px] mx-auto">
          <div className="flex flex-wrap justify-center items-center md:gap-x-[108px] gap-x-[80px] md:gap-y-[26px] gap-y-[26px] w-full">
            {partners.eventSponsors.map(item => (
              <div
                key={item.id}
                className="w-[100px] flex flex-col items-center justify-center"
              >
                <div className="mb-[18px] flex items-center justify-center profile-avatar-shadow medium-radius bg-white w-[85px] h-[85px]">
                  <Logo
                    src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item.logo ?? item.company?.logo}`}
                    className="w-[83px] h-[83px] object-contain p-1 medium-radius"
                  />
                </div>
                <div className="h-[51px]">
                  <div className="text-[12px] text-[#0B1221] font-semibold  md:line-clamp-1 line-clamp-1 text-center">
                    {item.company.name}
                  </div>
                  <div className="text-[11px] text-[#3C3C3C] font-light line-clamp-2 text-center">
                    {item.sponsorship}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventPartner;
