import { Transition } from '@tailwindui/react';
import React from 'react';
import {
  FC,
  Key,
  ReactChild,
  ReactFragment,
  ReactPortal,
  useEffect,
  useRef,
  useState
} from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { ConditionalLink } from '../../../components/conditional-link';

export interface ImenuItemsProps {
  icon?: string;
  textClass?: string;
  url?: string;
  text?: string;
  subtext?: string;
  imageUrl?: string;
  // I think maybe that the options should have the type of [] rather than using any
  // options: ImenuItemsProps[];
  options?: OptionType[];
  menuCardClasses?: string;
  containerClasses?: string;
  buttonStyles?: string;
  buttonTitleStyles?: string;
  buttonTitle?: string;
  buttonIcon?: string;
  buttonIconStyles?: string;
  titleAndSubtitleGroup?: string;
  listTextStyles?: string;
  listSubtextStyles?: string;
  listIconStyles?: string;
  textAndImageContainer?: string;
}

export type OptionType = {
  id: Key | null;
  url?: string;
  icon?: string;
  text?: boolean | ReactChild | ReactFragment | ReactPortal;
  subtext?: boolean | ReactChild | ReactFragment | ReactPortal;
  type?: string;
  onClickHandler: Function;
};

export const DropDownMenu: FC<ImenuItemsProps> = (props) => {
  const ref = React.useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const handleClick = () => setShowMenu((showMenu) => !showMenu);

  const handleClickOutside = () => setShowMenu(false);

  useOnClickOutside(ref, handleClickOutside);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!showMenu) {
        return;
      }

      if (event.key === 'Escape') {
        setShowMenu(false);
      }
    };

    document.addEventListener('keyup', handleEscape);
    return () => document.removeEventListener('keyup', handleEscape);
  }, [showMenu]);

  return (
    <div ref={ref} className={props.containerClasses}>
      <button
        onClick={handleClick}
        disabled={props.buttonIcon && props.buttonIcon ? showMenu : !showMenu}
        className={props.buttonStyles}>
        {props.buttonTitle && <span className={props.buttonTitleStyles}>{props.buttonTitle}</span>}
        {props.buttonIcon && (
          <img className={props.buttonIconStyles} src={props.buttonIcon} alt="" />
        )}
      </button>
      <Transition 
        show={showMenu}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={props.menuCardClasses}>
          {props.options &&
            props.options.map((option: OptionType, _idx: number) => {
              if (option.type && (option.type === 'icon')) {
                return (
                  <div key={option.id} className={props.titleAndSubtitleGroup} onClick={()=>{ option.onClickHandler(); setShowMenu(false); }}>
                    <img className={props.listIconStyles} src={option.icon} alt="" />
                    <div className={props.listTextStyles}>{option.text}</div>
                  </div>
                );
              } else {
                return (
                  <div key={option.id} className={props.titleAndSubtitleGroup} onClick={()=>{ option.onClickHandler(); setShowMenu(false); }}>
                    <ConditionalLink to={option.url} className={props.textAndImageContainer}>
                      {option.icon && (
                        <img className={props.listIconStyles} src={option.icon} alt="" />
                      )}
                      <div className={props.listTextStyles}>{option.text}</div>
                    </ConditionalLink>
                    <ConditionalLink to={option.url}>
                      <div className={props.listSubtextStyles}>{option.subtext}</div>
                    </ConditionalLink>
                  </div>
                );
              }
            })}
        </div>
      </Transition>
    </div>
  );
};

export default DropDownMenu;
