import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import { IMessageState } from '../../models/messages/message';

const initialState: IMessageState = {
  isOpen: false,
  avatar: '',
  userDisplayName: '',
  messageText: ''
};

type MessageUser = {
  avatar: string;
  userDisplayName: string;
  messageText?: string;
  userId?:string;
};

export const newMessageSlice = createSlice({
  name: 'newMessage',
  initialState,
  reducers: {
    openMessageWindow: (state, action: PayloadAction<MessageUser>) => {      
      state.avatar = action.payload.avatar;
      state.userDisplayName = action.payload.userDisplayName;
      state.messageText = action.payload.messageText;
      state.userId = action.payload.userId;
      state.isOpen = true;
    },
    closeMessageWindow: () => {
      return initialState;
    }
  }
});

export const { openMessageWindow, closeMessageWindow } = newMessageSlice.actions;
