import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import ActionOfCardEvent1 from './ActionOfCardEvent1';
import { IEvent } from '../../../../models/event/event';
import { IUserProfile } from '../../../../models/user-profile';
import DropDownEventButton from './DropDownEventButton';
import { EventsService } from '../../../../services/events';
import { EventCoverAndLogo } from '../../../../components/event-cover-and-logo';

export interface ICardEventInterestedProps {
  coverUrl: string;
  avatarUrl: string;
  title: string;
  subTitle: string;
  date: string;
  regions: string;
  eventType: string;
  eventLink: string;
  event: IEvent;
  user: IUserProfile;
  containerClass?: string;
  bannerClass?: string;
  logoPosition?: string;
}

export const CardEventInterested: FC<ICardEventInterestedProps> = ({
  coverUrl,
  avatarUrl,
  title,
  subTitle,
  date,
  regions,
  eventType,
  eventLink,
  event,
  user,
  containerClass,
  bannerClass,
  logoPosition
}) => {
  const navigate = useNavigate();

  const join = () => {
    navigate(`/event/${event.slug}/discussion?from=live-register`);
  };

  const interested = () => {
    new EventsService().eventInterested(event.id).then(res => {
      toast.success(res.message);
    });
  };

  return (
    // TODO: Move this code to shared components.Design is duplicated in Home, Events and share post.
    <div
      className={classNames('event-card-shadow rounded-[10px] w-[193px] h-[252px] card-event-interested', {
        [`${containerClass}`]: containerClass,
      })}
    >
      <div
        className={classNames('flex relative space-x-5 justify-end h-[102px] sm:w-[193px] w-full', {
          [`${bannerClass}`]: bannerClass,
        })}
      >
        <div>
          <Link to={eventLink}>
            <EventCoverAndLogo
              cover={coverUrl}
              logo={avatarUrl}
              coverClassName={classNames('mx-auto !drop-shadow-none bg-cover bg-center rounded-t-lg w-[193px] h-[102px]', {
                [`${bannerClass}`]: bannerClass,
              })}
              logoClassName={classNames('-mt-2', {
                [`${logoPosition}`]: logoPosition,
              })}
            />
            {/* <div
              style={{
                backgroundImage: `url("${coverUrl}")`
              }}
              className="mx-auto bg-cover bg-center rounded-t-lg w-[193px] h-[102px] border-gray-0 py-3 px-2"
            >
              <div className="w-[57px] h-[57px] bg-white bg-center rounded-10 shadow-xs2 py-1 mt-4">
                <img
                  alt="avatar"
                  className="w-[34px] h-[34px] mt-2 mx-auto bg-cover bg-center flex justify-center"
                  src={avatarUrl}
                />
              </div>
            </div> */}
          </Link>
        </div>
      </div>

      <div className="px-[20px] pt-[10px] pb-[15px] bg-white rounded-b-lg h-[150px]">
        <div className="text-left h-[87px]">
          <Link to={eventLink}>
            <div className="text-[13px] line-clamp-2 capitalize font-semibold leading-[17px] text-[#172746]">
              {title}
            </div>
            {/* <Typography variant="H2" text={title} /> */}
          </Link>
          <div className="text-sm5 line-clamp-1 capitalize font-normal text-[#3C3C3C]">
            {subTitle}
          </div>
          <div className="text-sm5 line-clamp-1 capitalize font-normal text-[#3C3C3C] leading-[18px]">
            {regions}
          </div>
          <div className="flex flex-row ">
            <div className="text-[12px] font-normal text-[#203C6E] leading-[18px] line-clamp-1">{new Date(date).toUTCString()}</div>
          </div>
          <div className="text-[12px] font-normal text-[#172746] leading-[18px] line-clamp-1">
            {
              // eslint-disable-next-line no-nested-ternary
              eventType === 'live' ? 'Live event' : eventType === 'hybrid' ? 'Hybrid event' : ''
            }
          </div>
        </div>
        <div className="flex justify-between text-center mt-2 w-full static relative ">
          <div>
            <DropDownEventButton
              menuItems={[
                {
                  id: '1', name: 'Join', url: '', onClick: join
                },
                {
                  id: '2', name: 'Interested', url: '', onClick: interested
                }
              ]}
              title="RSVP"
              iconUrl="/images/Polygon-dropdown.svg"
              type="button"
            />
          </div>
          <div className="flex justify-end absolute -right-[3px] top-[5.5px]">
            <ActionOfCardEvent1 event={event} user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};
