import { Reducer } from 'redux';

import { IContentState } from '../../models/library-content/ContentState';
import { ContentActions } from './actions';
import { ContentActionTypes } from './types';

const initialState: IContentState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const ContentReducer: Reducer<IContentState, ContentActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ContentActionTypes.CONTENT_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case ContentActionTypes.CONTENT_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case ContentActionTypes.CONTENT_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
