import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NoEvents } from 'src/screens/events/event-tab/components/no-events';
import { Avatar } from 'src/shared-components/V2';
import defaultLogo from '../../../assets/images/default-profile.svg';

type ICompanyLeadListCardProps = {
  leads: any[];
  onClick: (event: any) => void;
  company: string;
};

export const CompanyLeadListCard = (props: ICompanyLeadListCardProps) => {
  const navigate = useNavigate();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props?.leads?.length > 0 ? (
        props.leads?.map((item: any) => (
          <div
            key={`search-event-${item.id}`}
            className="flex sm:flex-row flex-col items-center justify-between border-b-1 border-[#CDDCF2] py-0 pb-3 sm:pb-0 bg-white"
          >
            <div className="flex items-center pb-[20px] pt-[20px] text-blue-800 w-full">
              <div className="flex-shrink-0 cursor-pointer">
                <Avatar
                  size="MEDIUM"
                  src={
                      item?.avatar
                        ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.avatar}`
                        : defaultLogo
                    }
                  alt=""
                />
              </div>
              <div
                className="flex flex-col items-start pl-4"
                onClick={() => {
                  props?.onClick(item);
                  if (item?.slug) {
                    navigate(`/wall/${item?.slug}?tab=about`);
                  }
                }}
              >
                <span className="text-[14px] leading-5 font-semibold text-[#172746] cursor-pointer">
                  {`${item?.firstName || ''} ${item?.lastName || ''}`}
                </span>
                <span className="text-[12px] leading-[18px] font-medium text-[#172746] cursor-pointer">
                  {item?.jobtitle || ''}
                </span>
                <span className="text-[12px] leading-[18px] font-NORMAL text-[#172746] cursor-pointe">
                  {props?.company}
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <NoEvents />
      )}
    </>
  );
};
