import bulb from '../../../assets/images/compliance/bulb-icon.png';

const HostContentFour = () => (
  <>
    <hr className="my-10" />
    <div id="mobile-services" className="mt-7 text-gray-1 text-lg5">
      4. Mobile Services
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        Where accessing our Services through a mobile device, charges, rates and fees may apply,
        depending on your wireless service carrier. Some carriers may also prohibit or restrict
        Use. Please ensure your communication details are up to date in case we need to contact
        you.
      </div>
    </div>
    <div className="mt-3">
      The Platform includes certain services that are available via a mobile device, including
      (i) the ability to upload content to the Platform via a mobile device, (ii) the ability to
      browse the Platform from a mobile device and (iii) the ability to access certain features
      through an application downloaded and installed on a mobile device (collectively, the
      “Mobile Services”). To the extent you access the Platform through a mobile device, your
      wireless service carrier’s standard charges, data rates and other fees may apply. In
      addition, downloading, installing, or using certain Mobile Services may be prohibited or
      restricted by your carrier, and not all Mobile Services may work with all carriers or
      devices. By using the Mobile Services, you agree that we may communicate with you regarding
      Panelist and other entities by SMS, MMS, text message or other electronic means to your
      mobile device and that certain information about your usage of the Mobile Services may be
      communicated to us. In the event you change or deactivate your mobile telephone number, you
      agree to promptly update your Panelist account information to ensure that your messages are
      not sent to the person that acquires your old number.
    </div>
  </>
);

export default HostContentFour;
