import { Reducer } from 'redux';

import { IRegionState } from '../../models/library-content/RegionState';
import { RegionActions } from './actions';
import { ContentRegionActionTypes } from './types';

const initialState: IRegionState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const RegionsReducer: Reducer<IRegionState, RegionActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ContentRegionActionTypes.REGIONS_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case ContentRegionActionTypes.REGIONS_SUCCESS: {
      return {
        ...state,
        value: action.data
      };
    }

    case ContentRegionActionTypes.REGIONS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
