import { NavigateFunction } from "react-router";

import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

import { IAppState } from "..";
import { IUserProfile } from "../../models/user-profile";
import { UserService } from "../../services";
import {
  delAuthToken,
  getAuthRefreshToken,
  getAuthToken,
  setAuthToken,
} from "../../utils";
import axios from "axios";

export type AuthState = {
  redirect?: string;
  initialised: boolean;
  currentUser?: IUserProfile;
  errorMessage?: string;
  isUserOnline: boolean;
  hideEmailVerification?: boolean;
};

const initialState: AuthState = {
  initialised: false,
  isUserOnline: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserProfile>) => {
      delete state["errorMessage"];
      state.initialised = true;
      state.currentUser = action.payload;
    },
    unsetUser: (state) => {
      delete state["currentUser"];
      state.initialised = false;
      state.isUserOnline = false;
    },
    setError: (state, action: PayloadAction<string>) => {
      delete state["currentUser"];
      state.errorMessage = action.payload;
    },
    resetError: (state) => {
      delete state["errorMessage"];
    },
    setInitialised: (state) => {
      state.initialised = true;
    },
    setRedirect: (state, action: PayloadAction<string>) => {
      state.redirect = action.payload;
    },
    setEmailVerification: (state, action: PayloadAction<boolean>) => {
      state.hideEmailVerification = action.payload;
    },
    unsetRedirect: (state) => {
      delete state["redirect"];
    },
  },
});

export const {
  setUser,
  unsetUser,
  setError,
  setInitialised,
  setRedirect,
  setEmailVerification,
  unsetRedirect,
  resetError,
} = authSlice.actions;

export const initialise = () => async (dispatch: Dispatch) => {
  if (!getAuthToken()) {
    if (getAuthRefreshToken()) {
      const userService = new UserService();
      const { data: result } = await userService.refreshToken(
        getAuthRefreshToken()
      );
      setAuthToken(result);
    } else {
      dispatch(setInitialised());
      return;
    }
  }
  new UserService()
    .getOwnProfile()
    .then((r) => r.data)
    .then((user) => dispatch(setUser(user)))
    .catch();

  /**
   * Added Axios intercepter, On 401 unautherised response below code will
   * try to get new token if refreshToken is still valid and will re-attempt
   **/
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const userService = new UserService();
      const config = error?.config;
      const requestURL = error?.request?.responseURL;
      const isValidBackend: boolean =
        requestURL && requestURL.startsWith(userService.getBaseUrl());
      if (
        error?.response?.status === 401 &&
        !config?.sent &&
        !config.url?.includes("refresh") &&
        isValidBackend
      ) {
        config.sent = true;
        try {
          const { data: result } = await userService.refreshToken(
            getAuthRefreshToken()
          );
          setAuthToken(result);
          if (result?.token) {
            config.headers = {
              ...config.headers,
              Authorization: `${result?.token}`,
            };
          }
        } catch (error: any) {
          localStorage.clear();
          sessionStorage.clear();
          delAuthToken();
          window.location.href = "/loginorjoin";
        }

        return axios.request(config);
      }
      return Promise.reject(error);
    }
  );
};

export const login =
  (
    username: string,
    password: string,
    navigate: NavigateFunction,
    from: string,
    changePage: any,
    redirectTo?: string,
    setIsLoader?: (value: boolean) => void
  ) =>
  async (dispatch: Dispatch, getState: () => IAppState) => {
    const userService = new UserService();
    try {
      if (setIsLoader) setIsLoader(true);
      const {data} = await userService
        .login({ username, password });
      setAuthToken(data);
      const currentUser = await userService.getOwnProfile(data.token);
      dispatch(setUser(currentUser?.data));
      if (from === "site") {
        if (redirectTo === "event-portal") {
          window.location.replace(
            `${process.env.REACT_APP_EVENT_PORTAL_URL}/events`
          );
        } else {
          navigate(getState().auth.redirect || "/home");
        }
      } else if (from === "event") changePage(3);
      if (setIsLoader) setIsLoader(false);
    } catch (e: any) {
      if (setIsLoader) setIsLoader(false);

      if (e?.hasOwnProperty("code") && e?.code?.toLowerCase()?.includes("auth") && e?.hasOwnProperty("message")) {
        dispatch(setError(e.message));
       }

     else if (e?.hasOwnProperty("statusCode") && e?.statusCode === 401) {
         dispatch(setError(e.message));
        }
        else {
          dispatch(setError("Something went wrong. Please try again!"));
        }
    }
    finally {
      if (setIsLoader) setIsLoader(false);
    }
  };

export const resendEmailVerification = () => async (dispatch: Dispatch) => {
  try {
    const userService = new UserService();
    await userService.resendEmailVerify();
  } catch (e) {
    console.error("Failed to send email verification with error", e);
  }
};

export const logout = () => async (dispatch: Dispatch) => {
  delAuthToken();
  dispatch(unsetUser());
};
