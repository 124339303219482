import { ICompanyProductResult, ICreateCompanyProduct } from 'src/models/post/company-post';
import { IBaseAPIPostMethodResult } from '../../models/base-apipostmethod-result';
import { ICompaniesResult } from '../../models/company/CompaniesResult';
import {
  ICompanyFollowResult,
  ICompanyUnfollowResult,
} from '../../models/company/company-follow';
import { IEditCompanyProfile } from '../../models/company/company-profile-edit';
import { ICompanyEmployeesResult } from '../../models/company/CompanyEmployeesResult';
import { ICompanyEventsResult } from '../../models/company/CompanyEventsResult';
import { ICompanyProfileUpdateResult } from '../../models/company/CompanyProfileUpdateResult';
import { ICompanyResourcesResult } from '../../models/company/CompanyResourcesResult';
import { ICompanySizesResult } from '../../models/company/CompanySizesResult';
import { ICompanySpecialitiesResult } from '../../models/company/CompanySpecialitiesResult';
import { IIndustriesResult } from '../../models/company/IndustriesResult';
import { IJobFunctionsResult } from '../../models/company/JobFunctionsResult';
import { IJobTitlesResult } from '../../models/company/JobTitlesResult';
import { ICommentsResult } from '../../models/post/CommentsResult';
import { IPostSpotlightResult } from '../../models/post/IPostSpotlight';
// import { IPost, IPostComment } from '../../models/post/post';
import { IPostsResult } from '../../models/post/PostsResult';
import BaseService from '../BaseService';

export class CompanyService extends BaseService {
  async getSpecialities() {
    const extraOptions = this.getAuthToken();

    return this.get<ICompanySpecialitiesResult>(
      '/company-specialities/short?page=1&search=',
      extraOptions
    );
  }

  async getSpecialitiesWithQuery(query: string) {
    const extraOptions = this.getAuthToken();

    return this.get<ICompanySpecialitiesResult>(
      `/company-specialities/short?page=1&limit=300&search=${encodeURIComponent(query)}`,
      extraOptions
    );
  }

  async getInterestsWithQuery(query: string) {
    const extraOptions = this.getAuthToken();

    return this.get<ICompanySpecialitiesResult>(
      `/interests/short?page=1&limit=300&search=${encodeURIComponent(query)}`,
      extraOptions
    );
  }

  async getCompaniesWithQuery(query: string) {
    // eslint-disable-next-line
    return await this.get<ICompaniesResult>(
      `/guest/companies/short?page=1&limit=10&search=${encodeURIComponent(query)}&where[isPage]=1`
    );
  }

  async getAllCompanies(search?: string) {
    return this.get<ICompaniesResult>(
      `/guest/companies/short?search=${search ? encodeURIComponent(search) : ''}`
    );
  }

  async getAllCompanyList(search?: string, page: number = 1, limit: number = 15) {
    try {
      const extraOptions = this.getAuthToken();
      const url = `/companies?order[isPage]=DESC&order[name]=ASC${
        search ? `&search=${encodeURIComponent(search)}` : ''
      }&page=${page}&limit=${limit}`;
      return this.get<any>(url, extraOptions);
    } catch (error) {
      console.error('An error occurred:', error);
      throw error;
    }
  }

  async getCompanyByIdOrName(search?: string) {
    return this.getV2<ICompaniesResult>(
      `/guest/companies?search=${search || ''}`
    );
  }

  async getCompanySizes(query: string = '') {
    const extraOptions = this.getAuthToken();

    return this.get<ICompanySizesResult>(
      `/company-sizes?page=1&limit=500&search=${encodeURIComponent(query)}`,
      extraOptions
    );
  }

  async getCompanyRegions() {
    const extraOptions = this.getAuthToken();

    return this.get<any>(
      '/companies/filters?attributes[]=headquarter',
      extraOptions
    );
  }

  async getIndustriesWithQuery(query: string) {
    return this.get<IIndustriesResult>(
      `/industries/short?page=1&limit=300&search=${encodeURIComponent(query)}`
    );
  }

  async getIndustries() {
    const extraOptions = this.getAuthToken();

    return this.get<IIndustriesResult>(
      '/industries/short?page=1&search=',
      extraOptions
    );
  }

  async getJobTitlesWithQuery(query: string = '', limit: number = 500) {
    return this.get<IJobTitlesResult>(
      `/job-titles/short?page=1&limit=${limit}&search=${encodeURIComponent(query)}`
    );
  }

  async getJobTitlesWithQueryAndLimit(limit: string, query: string) {
    return this.get<IJobTitlesResult>(
      `/job-titles/short?page=1&limit=${limit}&search=${encodeURIComponent(query)}`
    );
  }

  async getJobFunctions(search: string = '', limit: number = 10) {
    const extraOptions = this.getAuthToken();

    return this.get<IJobFunctionsResult>(
      `/job-functions/short?page=1&limit=${limit}&search=${encodeURIComponent(search)}`,
      extraOptions
    );
  }

  async getJobFunctionsWithQuery(query: string) {
    const extraOptions = this.getAuthToken();

    return this.get<IJobFunctionsResult>(
      `/job-functions/short?page=1&limit=300&search=${encodeURIComponent(query)}`,
      extraOptions
    );
  }

  async getCompanyProfile(companyIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<any>(`/companies/${companyIdOrSlug}`, extraOptions);
  }

  async getCompanyEmployees(companyIdOrSlug: string, page: number = 1, limit: number = 1000) {
    const extraOptions = this.getAuthToken();

    return this.get<ICompanyEmployeesResult>(
      `/companies/${companyIdOrSlug}/employees?page=${page}&limit=${limit}`,
      extraOptions
    );
  }

  async getCompanyEvents(
    companyIdOrSlug: string,
    orderBy: string = 'DESC',
    query: string = ''
  ) {
    const extraOptions = this.getAuthToken();

    return this.get<ICompanyEventsResult>(
      `/events/company?page=1&limit=500&companyId=${companyIdOrSlug}&where[isHosting]=1&where[isSponsoring]=1&order[createdAt]=${orderBy}${encodeURIComponent(query)}`,
      extraOptions
    );
  }

  async getCompanyResources(companyIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<ICompanyResourcesResult>(
      `/resources?page=1&limit=10&companyId=${companyIdOrSlug}`,
      extraOptions
    );
  }

  async getCompanyPosts(
    companyIdOrSlug: string,
    page: number = 1,
    orderBy: string = '&order[createdAt]=DESC'
  ) {
    const extraOptions = this.getAuthToken();

    const postsResult = await this.get<IPostsResult>(
      `/companies/${companyIdOrSlug}/posts?page=${page}&limit=10${orderBy}`,
      extraOptions
    );

    // let comments: IPostComment[] = [];
    // let updatedPosts: IPost[] = [];

    // const postsWithComments = postsResult.data.map(async (post) => {
    //   const commentsResult = await this.getPostComments(post.id);

    //   commentsResult.data.forEach((comment) => comments.push({
    //     commentId: comment.id,
    //     avatarUrl: comment.commentedAsUser?.avatar
    //       ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${comment.commentedAsUser.avatar}`
    //       : `${process.env.REACT_APP_IMAGE_URL_PREFIX}/images/20211129/blank-profile-picture-g7b0695023640-udq004b.png`,
    //     name: `${comment.commentedAsUser?.firstName} ${comment.commentedAsUser?.lastName}`,
    //     jobTitle: '',
    //     companyName: '',
    //     timeAgo: '',
    //     commentText: comment.content,
    //     reactionsCount:
    //         comment.totalClaps
    //         + comment.totalHahas
    //         + comment.totalLikes
    //         + comment.totalLoves
    //         + comment.totalSmiles,
    //     totalClaps: comment.totalClaps,
    //     totalHahas: comment.totalHahas,
    //     totalLikes: comment.totalLikes,
    //     totalLoves: comment.totalLoves,
    //     totalSmiles: comment.totalSmiles,
    //     totalComments: comments.length,
    //   }));

    //   // eslint-disable-next-line
    //   post.comments = comments;
    //   comments = [];

    //   return post;
    // });

    // updatedPosts = await Promise.all(postsWithComments);

    return { postsResult };
  }

  async getPostComments(postId: string) {
    const extraOptions = this.getAuthToken();

    return this.get<ICommentsResult>(
      `/posts/${postId}/comments?page=1&limit=10`,
      extraOptions
    );
  }

  async updateCompanyProfile(
    companyIdOrSlug: string,
    data: IEditCompanyProfile
  ) {
    const extraOptions = this.getAuthToken();

    return this.put<ICompanyProfileUpdateResult>(
      `/companies/${companyIdOrSlug}`,
      data,
      extraOptions
    );
  }

  async createCompanyProfile(data: IEditCompanyProfile) {
    const extraOptions = this.getAuthToken();

    return this.post<ICompanyProfileUpdateResult>(
      '/companies',
      data,
      extraOptions
    );
  }

  async editCompanyAdmins(companyIdOrSlug: string, data: {}) {
    const extraOptions = this.getAuthToken();

    return this.put<IBaseAPIPostMethodResult>(
      `/companies/${companyIdOrSlug}/admins`,
      data,
      extraOptions
    );
  }

  async getSearchCompanyResults(
    query: string,
    limit: number = 5,
    page: number = 1
  ) {
    const extraOptions = this.getAuthToken();
    const url = `/search/company?page=${page}&limit=${limit}&search=${encodeURIComponent(query)}`;

    return this.get<ICompanySpecialitiesResult>(url, extraOptions);
  }

  async getCompanyProducts(
    compnayId: string,
    limit: number = 5,
    page: number = 1
  ) {
    const extraOptions = this.getAuthToken();
    const url = `/admin/companies/${compnayId}/products?page=${page}&limit=${limit}`;

    return this.get<ICompanySpecialitiesResult>(url, extraOptions);
  }

  async createCompanyProduct(compnayId: string, data: ICreateCompanyProduct) {
    const extraOptions = this.getAuthToken();
    return this.post<ICompanyProductResult>(
      `/admin/companies/${compnayId}/products`,
      data,
      extraOptions
    );
  }

  async getFilteredCompanyResults(page: string, limit: string, query?: string) {
    const extraOptions = this.getAuthToken();
    const url = `/search/company?page=${page}&limit=${limit}&${query ? encodeURIComponent(query) : ''}`;

    return this.get<ICompanySpecialitiesResult>(url, extraOptions);
  }

  async followCompany(companyIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.post<ICompanyFollowResult>(
      `/companies/${companyIdOrSlug}/follow`,
      {},
      extraOptions
    );
  }

  async unfollowCompany(companyIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<ICompanyUnfollowResult>(
      `/companies/${companyIdOrSlug}/follow`,
      extraOptions
    );
  }

  async getCompanySpotlight(
    companyId: string,
    limit: number,
    page: number,
    query?: string
  ) {
    const extraOptions = this.getAuthToken();
    const searchString = query ? `&search=${encodeURIComponent(query)}` : '';
    const url = `/companies/${companyId}/spotlight-posts?page=${page}&limit=${limit}&order[createdAt]=DESC${searchString}`;

    return this.get<IPostSpotlightResult>(url, extraOptions);
  }

  async getCurrentAdmins(companyId: string) {
    const extraOptions = this.getAuthToken();
    const url = `/companies/${companyId}/company-admins`;

    return this.get<any>(url, extraOptions);
  }

  async getCompanyAdminRequests(companyId: string) {
    const extraOptions = this.getAuthToken();
    const url = `/companies/${companyId}/company-admin-requests`;

    return this.get<any>(url, extraOptions);
  }

  async removeCompanyAdministrator(companyId: string, companyAdminId: string) {
    const extraOptions = this.getAuthToken();
    const url = `/companies/${companyId}/company-admins/${companyAdminId}`;

    return this.delete<any>(url, extraOptions);
  }

  async grantAdministratorPrivilege(
    companyId: string,
    companyAdminId: string,
    data: any
  ) {
    const extraOptions = this.getAuthToken();

    return this.patch<any>(
      `/companies/${companyId}/company-admins/${companyAdminId}`,
      data,
      extraOptions
    );
  }

  async deleteCompanyProduct(companyId: string, productId: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<any>(
      `/admin/companies/${companyId}/products/${productId}`,
      {},
      extraOptions
    );
  }

  async editCompanyProduct(companyId: string, productId: string, data: ICreateCompanyProduct) {
    const extraOptions = this.getAuthToken();

    return this.patch<any>(
      `/admin/companies/${companyId}/products/${productId}`,
      data,
      extraOptions
    );
  }

  async getSepcificProduct(companyId: string, productId: string) {
    const extraOptions = this.getAuthToken();

    return this.get<any>(
      `/admin/companies/${companyId}/products/${productId}`,
      extraOptions
    );
  }

  async postCompaniesImport(companyAccountFileId: string, data: IEditCompanyProfile,) {
    const extraOptions = this.getAuthToken();

    return this.post<ICompanyProfileUpdateResult>(
      `/companies/${companyAccountFileId}/import`,
      data,
      extraOptions
    );
  }

  async postCompanieBestMatch(data: IEditCompanyProfile) {
    const extraOptions = this.getAuthToken();

    return this.post<ICompanyProfileUpdateResult>(
      '/companies/matches',
      data,
      extraOptions
    );
  }

  async postAccountFiles(companyId:string, data: any) {
    const extraOptions = this.getAuthToken();

    return this.post<ICompanyProfileUpdateResult>(
      `/companies/${companyId}/account-files`,
      data,
      extraOptions
    );
  }

  async patchCompanyAccountFileId(
    accountFileId: string,
    data: any
  ) {
    const extraOptions = this.getAuthToken();

    return this.patch<any>(
      `/companies/account-files/${accountFileId}`,
      data,
      extraOptions
    );
  }

  async getCompanyAccountFiles(page: number, limit: number, companyId:string) {
    const extraOptions = this.getAuthToken();
    const url = `/companies/${companyId}/account-files/list?page=${page}&limit=${limit}`;

    return this.get<ICompanySpecialitiesResult>(url, extraOptions);
  }

  async getCompanyAccountFileList(id: string, page: number, limit: number) {
    const extraOptions = this.getAuthToken();
    const url = `/companies/account-files/${id}/list?page=${page}&limit=${limit}`;
    return this.get<ICompanySpecialitiesResult>(url, extraOptions);
  }

  async companyStar(id: string, body:{isStarred?: boolean, notes?: string}) {
    const extraOptions = this.getAuthToken();
    return this.patch<any>(
      `/companies/companyFileTargetAccounts/${id}`,
      body,
      extraOptions
    );
  }

  async removeBulkCompanies(body: {ids: string[]}) {
    const extraOptions = this.getAuthToken();
    return this.delete<any>(
      '/companies/companyFileTargetAccounts/bulk',
      body,
      extraOptions
    );
  }

  async companyAccountDelete(companyIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<ICompanyUnfollowResult>(
      `/companies/account-files/${companyIdOrSlug}`,
      extraOptions
    );
  }
}
