import axios, { AxiosRequestConfig } from 'axios';
// import { UserLoginResult } from 'src/models/user-login-result';
// config
// import { UserService } from 'src/services';
import { delAuthToken, getAuthRefreshToken, setAuthToken } from 'src/utils';

// ----------------------------------------------------------------------
let isRefreshing = false;
let failedQueue: any[] = [];


const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL
});


axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // const userService = new UserService();
    const config = error?.config;
    const requestURL = error?.request?.responseURL;
    const isValidBackend: boolean =
      requestURL && requestURL.startsWith(process.env.REACT_APP_API_URL);
     const isLoginRequest = config?.url?.includes("/login");

    if (
      error?.response?.status === 401 &&
      !config?.sent &&
      !config.url?.includes("refresh") &&
      isValidBackend && 
      !isLoginRequest
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject, config });
        });
      }

      config.sent = true;
      isRefreshing = true;

      try {
        // const { data: result } = await userService.refreshToken(getAuthRefreshToken());
        const { data: refreshResult } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh`, { 
          refreshToken: getAuthRefreshToken() 
        });
      
        const result = refreshResult.data;
        setAuthToken(result);

        if (result?.token) {
          config.headers = {
            ...config.headers,
            Authorization: `${result?.token}`,
          };

          const retryRequest = axios.request(config);

          failedQueue.forEach((request: any) => {
            request.resolve(axios.request({ ...request.config, headers: { Authorization: result.token } }));
          });

          failedQueue = [];
          return retryRequest;
        }
      } catch (err) {
        localStorage.clear();
        sessionStorage.clear();
        axiosInstance.defaults.headers.common["Authorization"] = "";
        delAuthToken();
        window.location.href = "/loginorjoin";
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
)

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
