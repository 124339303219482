import morethanwebinar from '../../../assets/images/virtual/webinar.png';
import morethanwebinar2 from '../../../assets/images/virtual/webinar@2x.png';

export const MoreThanAWebinar = () => (
  <div className="block lg:flex flex-row -mt-8">
    <div className="bg-gray-1 w-full lg:w-1/2">
      <div className="mb-32 pb-3">
        <img
          className="ml-36 2xl:ml-60 2xl:pl-9 z-20"
          srcSet={`${morethanwebinar} 300w, ${morethanwebinar2} 1000w`}
          sizes="(max-width: 583px) 30vw, (max-width: 583px) 30vw, 500px"
          alt=""
          src={morethanwebinar2}
        />
      </div>
    </div>
    <div className="bg-gray-2 w-full lg:w-1/2 -z-10 ">
      <div className="text-left ml-10 w-5/6">
        <div className="block 2xl:ml-16 lg:ml-32 mt-32 pt-3 mb-12">
          <p className="text-xl3 font-extralight text-dark-blue w-10/12 -mt-9">
            Give them more than just a webinar
          </p>
          <p className="text-lg text-gray-1 font-extralight mt-3 w-4/5 pr-11">
            Let your attendees be more than just silent observers
          </p>
          <p className="text-md text-gray-1 font-extralight mt-3 w-3/4">
            Turn any session into an interactive roundtable instantly, use Panelist's social lounge
            and all the tools available to you increase engagement and create a meaningful
            experiences for your audiences.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default MoreThanAWebinar;
