import { LinkButton } from '../../shared-components';
import Logo from '../../assets/images/homepage/logo-panelist-blue.svg';

export const FieldsOfInterest = () => (
  <div className="w-full bg-white xl:h-[145px] overflow-hidden">
    <div className="flex lg:flex-row flex-col justify-between items-center w-full h-full container mx-auto max-w-full xl:pl-[150px] lg:pl-[30px] pl-0 relative">
      <div className="bg-black h-full w-full absolute -right-[92%] top-0 lg:block hidden" />
      <div className="xl:py-0 py-6 lg:pr-[40px] h-full flex items-center xl:justify-start justify-center">
        <img className="h-16" src={Logo} alt="Panelist" />
      </div>
      <div className="w-full xl:py-0 py-6 lg:px-[40px] px-[30px] sm:px-[60px] bg-black h-full flex items-center ">
        <div className="flex flex-col items-end">
          <div className="flex flex-col">
            <p className="text-[25px] lg:text-lg font-normal leading-[35px] text-white mb-2.5">
              Flexible tools for growth & collaboration
            </p>
            <div className="flex items-center gap-2.5 flex-wrap">
              <LinkButton
                text="Events"
                backgroundColor="#2000CD"
                textColor="#ffffff"
                classes="px-[24px] !rounded-[6px] font-medium tracking-wide"
                width="auto"
                height="30px"
                fontSize="13px"
                href="https://business.panelist.com/hybrid"
                replace
              />
              <LinkButton
                text="CMS & Community Hub"
                backgroundColor="#00CCB5"
                textColor="#ffffff"
                classes="px-[24px] !rounded-[6px] font-medium tracking-wide"
                width="auto"
                height="30px"
                fontSize="13px"
                href="https://business.panelist.com/hub-community"
                replace
              />
              <LinkButton
                text="Lead Generation & Engagement"
                backgroundColor="#6E00CC"
                textColor="#ffffff"
                classes="px-[24px] !rounded-[6px] font-medium tracking-wide"
                width="auto"
                height="30px"
                fontSize="13px"
                href="https://business.panelist.com/prospect-match"
                replace
              />
              <LinkButton
                text="Business Social Media"
                backgroundColor="#D63E78"
                textColor="#ffffff"
                classes="px-[24px] !rounded-[6px] font-medium tracking-wide"
                width="auto"
                height="30px"
                fontSize="13px"
                href="https://business.panelist.com/event-app"
                replace
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FieldsOfInterest;
