import { Link, matchPath, useLocation } from 'react-router-dom';

import NavbarMessages from '../../messages/navbar-messages';
import NavbarConnectionRequests from './connection-requests/navbar-connection-requests';
import CreateMenu from './create-menu';
import NavbarNotifications from './notifications/navbar-notifications';
import UserMenu from './user-menu';

import { HomeIconSvg , LoungeSvgIcon, EventsSvgIcon, SpotLightSvgIcon } from './../../../shared-components/V2/IconSvg';
import { ActiveMenuBorderIconSvg } from 'src/shared-components/V2/IconSvg/ActiveMenuBorderIconSvg';
import { useState } from 'react';

interface ActiveMenuProps {
  index: number;
  activeItem: boolean;
}

export const NavigationBar = () => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState<ActiveMenuProps>({
    index: 0,
    activeItem: false
  });
  const isHomeActive = !!matchPath('/home', location.pathname);
  const isEventActive = (!!matchPath('/events', location.pathname) ||
    !!matchPath('/events/:param', location.pathname) ||
    !!matchPath('/event/:eventidorslug/discussion', location.pathname) ||
    !!matchPath('/:hostName/:slug/live', location.pathname) ||
    !!matchPath('/event/:eventidorslug/moderator', location.pathname) ||
    !!matchPath('/event/:eventidorslug/speaker', location.pathname));
  const isSpotlightActive = !!matchPath('/spotlight', location.pathname);
  return ( 
    <div className="flex justify-between items-center xl:justify-start lg:justify-between md:justify-between lg:w-[100%] xl:w-auto">
      <div className="gap-2 flex items-center xl:mr-5 md:w-auto lg:w-[450px] xl:w-[594px]">
        <div className="flex flex-row items-center justify-between w-[40vw] md:w-[56%] ml-0 lg:mx-auto">
          <div 
            className="md:pr-2 md:pl-2 px-0 pr-[2px] pl-[0px] py-0.5 mx-1 relative"
            onMouseEnter={() => setActiveMenu({index: 0, activeItem: true})}
            onMouseLeave={() => setActiveMenu({index: 0, activeItem: false})}
          >
            <Link to="/home" data-test-id="home-link">
              <HomeIconSvg isActive={isHomeActive || activeMenu.index === 0 && activeMenu.activeItem} />
            </Link>
            {isHomeActive && (<div className="absolute -bottom-[10px] left-[50%] -ml-[57.5px]"><ActiveMenuBorderIconSvg isActive /></div>)}
            {activeMenu.index === 0 && activeMenu.activeItem && (<div className="absolute -bottom-[10px] left-[50%] -ml-[57.5px]"><ActiveMenuBorderIconSvg isActive /></div>)}
          </div>
          {/* <div className="px-2 py-0.5 mx-1">
            <Link to="/lounge" data-test-id="lounge-link">
              <LoungeSvgIcon isActive={!!matchPath('/lounge',location.pathname)} />
            </Link>
          </div> */}
          <div
            className="md:pr-2 md:pl-2 px-0 pr-[2px] pl-[0px] py-0.5 mx-1 relative"
            onMouseEnter={() => setActiveMenu({index: 1, activeItem: true})}
            onMouseLeave={() => setActiveMenu({index: 1, activeItem: false})}
          >
            <Link to="/events" data-test-id="events-link">
              <EventsSvgIcon isActive={isEventActive || activeMenu.index === 1 && activeMenu.activeItem} />
            </Link>
            {isEventActive && (<div className="absolute -bottom-[10px] left-[50%] -ml-[57.5px]"><ActiveMenuBorderIconSvg isActive /></div>)}
            {activeMenu.index === 1 && activeMenu.activeItem && (<div className="absolute -bottom-[10px] left-[50%] -ml-[57.5px]"><ActiveMenuBorderIconSvg isActive /></div>)}
          </div>
          <div
            className="md:pr-2 md:pl-2 px-0 pr-[2px] pl-[0px] py-0.5 mx-1 relative"
            onMouseEnter={() => setActiveMenu({index: 2, activeItem: true})}
            onMouseLeave={() => setActiveMenu({index: 2, activeItem: false})}
          >
            <Link to="/spotlight" data-test-id="spotlight-link">
              <SpotLightSvgIcon isActive={isSpotlightActive || activeMenu.index === 2 && activeMenu.activeItem} />
            </Link>
            {isSpotlightActive && (<div className="absolute -bottom-[10px] left-[50%] -ml-[57.5px]"><ActiveMenuBorderIconSvg isActive /></div>)}
            {activeMenu.index === 2 && activeMenu.activeItem && (<div className="absolute -bottom-[10px] left-[50%] -ml-[57.5px]"><ActiveMenuBorderIconSvg isActive /></div>)}
          </div>
        </div>
      </div>
      <div className=' w-[1px] h-[20px] bg-[#CDDCF2] md:hidden block mr-[11px] ml-1' />
      <div className="gap-2 md:w-[315px] -ml-1">
        <div className="flex flex-row md:items-center justify-between">
          <div className="md:mx-1 mr-3.5 relative">
            <NavbarConnectionRequests />
          </div>
          <div className="md:mx-1 mr-3.5 relative">
            <NavbarMessages />
          </div>
          <div className="md:mx-1 mr-2 relative">
            <NavbarNotifications />
          </div>
          <div className="md:px-2 mr-1.5 md:mx-1 hidden md:flex items-center">
            <UserMenu />
          </div>
          <div className="px-2 mx-1 hidden md:flex items-center">
            <CreateMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
