import classNames from 'classnames';
import { ReactNode } from 'react';

export const Tooltip = ({
  message,
  children,
  className,
  position
}: {
  message: string;
  children: ReactNode;
  className?: string;
  position?: string;
}) => {
  return (
    <div className='relative flex flex-col items-start group p-1'>
      {children}
      <div
        className={classNames('absolute bottom-0 right-0 flex flex-col items-center hidden mb-6 group-hover:flex', {
          [`${position}`]: position,
        })}
      >
        <span 
          className={classNames('relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md', {
          [`${className}`]: className,
          })}
        >
          {message}
        </span>
      </div>
    </div>
  );
};
