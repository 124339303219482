import classNames from 'classnames';
import { Tab } from '@headlessui/react';

import { useRef, useState } from 'react';
import AlertModal from '../../../components/alert-modal/alert-modal';
import { IRoom } from '../../../models/event/room';
import AccordionLayout from '../../../shared-components/Accordion/AccordionLayout';
import WebsiteEventCard, { SessionModal } from './website-event-card-';
import { AgendaTitle } from './agenda';

// TODO: Check if this file is used.
export const BreakoutAgendaBody = ({ room }: { room: IRoom }) => (
  <div key={room.id}>
    {
      room.sessions.map(session => (
        <div
          className={
            classNames(
              'w-full mb-[30px] last:mb-0'
            )
          }
        >
          <AccordionLayout
            styling="p-0 absolute right-0 top-0"
            titleData={(
              <AgendaTitle
                session={session}
                eventOverview={session.summary}
              />
            )}
          />
        </div>
      ))
    }
  </div>
);
export const BreakoutAgendaBodyLobby = ({ room, key, event }: { room: IRoom, key: string, event: any }) => {
  const contentWrapper = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [showArrows, setShowArrows] = useState(true);
  const [isSessionModal, setIsSessionModal] = useState(false);
  const [selectedSessoin, setSelectedSessoin] = useState<any>({});

  return (
    <>
      <div
        className="flex flex-row"
        onMouseEnter={() => setShowArrows(true)}
        onMouseLeave={() => setShowArrows(true)}
      >
        {room?.sessions.length > 0 && (
          <div
            className={classNames('w-full flex flex-col gap-y-[25px] overflow-y-auto', {
              // 'agenda-scroll-style': room?.sessions.length > 9,
              // 'no-scrollbar': room?.sessions.length <= 9,
            })}
            key={key}
            ref={contentWrapper}
          >
            {/* {
              room?.sessions?.map((session, index) => (
                <WebsiteEventCard
                  session={session}
                  roomQty={index + 1}
                  event={event}
                  setSelectedSessoin={setSelectedSessoin}
                  setIsSessionModal={setIsSessionModal}
                />
              ))
            } */}
            {
              room?.sessions?.map((session, index) => (
                <SessionModal
                  session={session}
                  event={event}
                />
              ))
            }
          </div>
        )}
      </div>
      <AlertModal
        isOpen={isSessionModal}
        isCloseIcon
        isCloseWhite
        closeIconClass="right-[5px] top-[12px] "
        onClose={() => setIsSessionModal(false)}
        className="w-[1063px] !py-[20px] p-[20px] my-0 overflow-hidden bg-[#333] max-h-[530px] overflow-y-scroll"
      >
        <SessionModal
          session={selectedSessoin}
          event={event}
        />
      </AlertModal>
    </>
  );
};
export const BreakoutAgendaTabs = ({
  room, roomType, selectedTab, onClick
}: { room: any; roomType: string; selectedTab: boolean; onClick: () => void; }) => (
  <Tab.Group>
    <Tab.List
      className={
        classNames(
          'h-[30px] text-[16px] font-[500] leading-[17px] px-[15px] flex items-center justify-center cursor-pointer w-full !rounded-[15px]',
          {
            'bg-white text-[#20313d]': selectedTab,
            'bg-transparent text-white border border-[rgba(255, 255, 255, 0.38)]': !selectedTab
          }
        )
      }
      onClick={onClick}
    >
      <Tab
        key={room.id}
        className={
          classNames(
            'focus:outline-none',
            // {
            //   'bg-black text-white': !selectedTab,
            //   'bg-white text-black': selectedTab
            // }
          )
        }
      >
        <div className={classNames('text-[16px] font-[500] leading-[17px] bold-lato-font', {})}>
          {room.name ? room.name : roomType}
        </div>
      </Tab>
    </Tab.List>
  </Tab.Group>
);
