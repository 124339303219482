import { useState } from 'react';

interface IRejectSvgIcon {
    fillColor?: string;
    fillBgColor?: string;
}

export const RejectSvgIcon = ({ fillColor, fillBgColor }: IRejectSvgIcon) => {
  const defaultColor = '#7283A4';
  const defaultBgColor = '#ffffff';
  
  const iconColor = fillColor || defaultColor;
  const bgColor = fillBgColor || defaultBgColor;

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="45" 
      height="45" 
      viewBox="0 0 45 45"
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_8200" data-name="Rectangle 8200" width="24" height="24" fill={iconColor}/>
        </clipPath>
      </defs>
      <g id="Group_20655" data-name="Group 20655" transform="translate(-334.721 -291.77)">
        <rect 
          id="Rectangle_8172" 
          data-name="Rectangle 8172" 
          width="45" 
          height="45" 
          rx="15" 
          transform="translate(334.721 291.77)" 
          fill={bgColor}
        />
        <path 
          id="Path_14886" 
          data-name="Path_14886" 
          d="M7,7,23.047,23.047M7,23.047,23.047,7" 
          transform="translate(342.697 299.746)" 
          fill="none" 
          stroke={iconColor} 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth="4"
        />
      </g>
    </svg>
  );
};