import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

export const ProfileCoverTabs = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab');

  const onChangeTabs = (name: any) => {
    navigate(`?tab=${name}`);
  };

  return (
    <div className="flex flex-row ml-[6px] mt-[10px] sm:mb-0 mb-2.5">
      <div
        onClick={() => onChangeTabs('about')}
        className={classNames('text-md15 leading-[18px] text-[#203C6E] mr-[34px] cursor-pointer', {
          'font-medium': activeTab === 'about',
          'font-normal': activeTab !== 'about',
        })}
      >
        About
      </div>
      <div
        onClick={() => onChangeTabs('posts')}
        className={classNames('text-md15 leading-[18px] text-[#203C6E] mr-[34px] cursor-pointer', {
          'font-medium': activeTab === 'posts',
          'font-normal': activeTab !== 'posts',
        })}
      >
        Posts
      </div>
      <div
        onClick={() => onChangeTabs('spotlight')}
        className={classNames('text-md15 leading-[18px] text-[#203C6E] mr-[30px] cursor-pointer', {
          'font-medium': activeTab === 'spotlight',
          'font-normal': activeTab !== 'spotlight',
        })}
      >
        Spotlight
      </div>
    </div>
  );
};

export default ProfileCoverTabs;
