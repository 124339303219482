import classNames from 'classnames';
import SpotlightImage from '../../assets/images/spotlight-img.svg';
import { Button } from '../../shared-components/V2';

interface INoSpotlightEventsProps {
  createButtonClass?: string;
  onClickModal?: Function;
  isButton?: boolean;
}
export const NoSpotlightEvents = ({ createButtonClass, onClickModal, isButton }: INoSpotlightEventsProps) => (
  <div className="w-full mx-auto">
    <div className="h-[706px] bg-white create-post-card m-auto">
      <img src={SpotlightImage} alt="" className="mx-auto pt-40" />
      <div className="text-center text-lg">
        Nothing to see here yet.
        {' '}
        <br />
      </div>
      {isButton && onClickModal && (
        <div className="flex items-center justify-center">
          <Button
            text="Create new content"
            className={classNames(
              "bg-[#0049EF] text-white !text-md2 !font-medium rounded-3xl h-[40px]",
              {
                [`${createButtonClass}`]: createButtonClass,
              }
            )}
            type="button"
            onClick={() => onClickModal(true)}
          />
        </div>
      )}
    </div>
  </div>
);
