import classNames from 'classnames';

const Ad = ({ ad }: any) => {
  const adClass = classNames({
    'flex-1 justify-center rounded-xl flex bg-white items-center text-x-sm text-gray-1': true
  });

  return (
    <div className="card-shadow p-4 bg-transparent relative">
      <div className="absolute right-5 top-5 bg-gray-100 opacity-50 rounded px-1 text-sm font-semibold">Ad</div>
      <a className={adClass} href={ad.url} target="_blank" rel="noopener noreferrer">
        <img className="rounded-md object-contain h-[250px] w-[300px] bg-gray-100" src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${ad.image}`} alt="" />
      </a>
    </div>
  );
};

export default Ad;
