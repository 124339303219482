import React from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface IScheduleSvgIcon {
  isActive?: boolean;
}

export const PostSvgIconActive = ({ isActive }: IScheduleSvgIcon) => {
    const colors = getBrandingColors();
    const defaultColor = '#66686c';
    const iconColor = isActive ? (colors?.Accent || '#03FFCD') : (colors?.Accent || defaultColor);

    return isActive ? (
       <svg xmlns="http://www.w3.org/2000/svg" width="29.54" height="24.62" viewBox="0 0 29.54 24.62">
  <path fill={iconColor} id="post" d="M.123,6.924A6.175,6.175,0,0,1,6.154,2H23.386a6.175,6.175,0,0,1,6.031,4.924ZM29.54,9.386V20.465a6.157,6.157,0,0,1-6.154,6.155H6.154A6.157,6.157,0,0,1,0,20.465V9.386ZM12.308,14.31a1.235,1.235,0,0,0-1.231-1.231H6.154a1.231,1.231,0,0,0,0,2.462H7.385v4.924a1.231,1.231,0,1,0,2.462,0V15.541h1.231A1.235,1.235,0,0,0,12.308,14.31Zm12.308,4.924A1.235,1.235,0,0,0,23.386,18H16a1.231,1.231,0,0,0,0,2.462h7.385A1.235,1.235,0,0,0,24.617,19.234Zm0-4.924a1.235,1.235,0,0,0-1.231-1.231H16a1.231,1.231,0,0,0,0,2.462h7.385A1.235,1.235,0,0,0,24.617,14.31Z" transform="translate(0 -2)"/>
</svg>

    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.551" height="18.793" viewBox="0 0 22.551 18.793">
        <path id="post_non_active" data-name="post non active" d="M17.853,2H4.7A4.7,4.7,0,0,0,0,6.7v9.4a4.7,4.7,0,0,0,4.7,4.7H17.853a4.7,4.7,0,0,0,4.7-4.7V6.7A4.7,4.7,0,0,0,17.853,2ZM4.7,3.879H17.853A2.824,2.824,0,0,1,20.671,6.7H1.879A2.824,2.824,0,0,1,4.7,3.879ZM17.853,18.917H4.7A2.824,2.824,0,0,1,1.879,16.1V8.578H20.671v7.517a2.824,2.824,0,0,1-2.819,2.818ZM9.4,11.4a.943.943,0,0,1-.94.94h-.94v3.759a.94.94,0,0,1-1.879,0V12.336H4.7a.94.94,0,1,1,0-1.879H8.457A.943.943,0,0,1,9.4,11.4Zm9.4,0a.943.943,0,0,1-.94.94h-5.64a.94.94,0,1,1,0-1.879h5.638a.943.943,0,0,1,.941.94Zm0,3.759a.943.943,0,0,1-.94.94h-5.64a.941.941,0,1,1,0-1.882h5.638a.943.943,0,0,1,.941.941Z" transform="translate(0 -2)" fill={iconColor}/>
      </svg>
      
      
    );
};





