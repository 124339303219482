import { Reducer } from 'redux';
import { ISearch } from '../../models/nav-bar';
import { SearchActions } from './actions';
import { SearchActionTypes } from './types';

const initialState: ISearch = {
  isLoading: false,
  searchInput: '',
  companies: [],
  events: [],
  resources: [],
  users: [],
  errorMessage: ''
};

export const SearchBarReducer: Reducer<ISearch, SearchActions> = (state = initialState, action) => {
  switch (action.type) {
    case SearchActionTypes.SEARCH_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    }

    case SearchActionTypes.SEARCH_SUCCESS: {
      return {
        ...state,
        searchInput: action.payload.searchInput,
        companies: action.payload.companies,
        events: action.payload.events,
        resources: action.payload.resources,
        users: action.payload.users
      };
    }

    case SearchActionTypes.SEARCH_ERROR: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
        errorMessage: action.payload.errorMessage
      };
    }

    case SearchActionTypes.SEARCH_FINAL: {
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    }

    default:
      return state;
  }
};
