import { useMemo } from "react";
import { useSelector } from "react-redux";
import { IEvent } from "src/models/event/event";
import { IColorStyle } from "src/models/user-events";
import { EventQuestionPageTitlePositionTypes } from "src/mui/pages/survey/enum";
import { IAppState } from "src/store";

function useStyling(
    currerntPage: string | null,
    currentPageFontColor: string | null,
    currentPageBackgroundColor?: string | null,
    currentPageHeaderColor?: string | null,
    currentPageTitleDescPosition?: string | null,
) {

    const event: IEvent = useSelector((state: IAppState) => state.website.event!);
    const colorStyles: IColorStyle[] = useSelector(
        (state: IAppState) => state.website.colorStyles!
    );
    const defaultColor = colorStyles?.find(
        (item: IColorStyle) =>
            item?.type === event?.websiteTemplate && item?.ordering === 0
    )?.values?.registration;
    const primaryBackgroundColor: string = useMemo(() => {
        return (
            event?.eventQuestionPage?.backgroundColor ||
            event?.colorStyle?.values?.registration?.backgroundColor ||
            defaultColor?.backgroundColor!
        );
    }, [event?.websiteTemplate]);

    let secondaryBackgroundColor: string = useMemo(() => {
        return (
            event?.eventQuestionPage?.secondaryBackgroundColor ||
            event?.colorStyle?.values?.registration?.secondaryBackgroundColor ||
            defaultColor?.secondaryBackgroundColor!
        );
    }, [event?.websiteTemplate]);

    let titleDescPosition: string = useMemo(() => {
        return (
            event?.eventQuestionPage?.titleDescPosition || EventQuestionPageTitlePositionTypes.BOTTOM
        );
    }, [event?.websiteTemplate]);

    let headerColor: string = useMemo(() => {
        return (
            event?.eventQuestionPage?.headerColor ||
            event?.colorStyle?.values?.registration?.headerColor ||
            defaultColor?.headerColor!
        );
    }, [event]);

    let secondaryFontColor: string = useMemo(() => {
        return (
            event?.eventQuestionPage?.fontColor ||
            event?.colorStyle?.values?.registration?.fontColor ||
            defaultColor?.fontColor!
        );
    }, [event]);

    const primaryFontColor: string = useMemo(() => {
        return (
            event?.eventQuestionPage?.fontColor ||
            event?.colorStyle?.values?.registration?.fontColor ||
            defaultColor?.fontColor!
        );
    }, [event]);

    const fieldBorderColor: string = useMemo(() => {
        return (
            event?.eventQuestionPage?.textFieldBorderColor ||
            event?.colorStyle?.values?.registration?.textFieldBorderColor ||
            defaultColor?.textFieldBorderColor!
        );
    }, [event]);
    const focusColor: string = useMemo(() => {
        return (
            event?.eventQuestionPage?.textFieldFocusColor ||
            event?.colorStyle?.values?.registration?.textFieldFocusColor ||
            defaultColor?.textFieldFocusColor!
        );
    }, [event]);
    let labelBackGroundColor: string = useMemo(() => {
        return (
            event?.eventQuestionPage?.secondaryBackgroundColor ||
            event?.colorStyle?.values?.registration?.secondaryBackgroundColor ||
            defaultColor?.secondaryBackgroundColor!
        );
        // if (event?.websiteTemplate === "lenovo") {
        //   return RegoFormLenovoColors.labelBackGroundColor;
        // }

        // if (event?.websiteTemplate === "default") {
        //   return RegoFormDefaultColors.labelBackGroundColor;
        // }
        // return RegoFormColors.labelBackGroundColor;
    }, [event?.websiteTemplate]);

    let labelFontColor: string = useMemo(() => {
        return (
            event?.eventQuestionPage?.fontColor ||
            event?.colorStyle?.values?.registration?.fontColor ||
            defaultColor?.fontColor!
        );
        // if (
        //   event?.eventQuestionPage?.fontColor &&
        //   event?.websiteTemplate === "lenovo"
        // ) {
        //   return (
        //     event?.eventQuestionPage?.fontColor ||
        //     RegoFormLenovoColors.labelFontColor
        //   );
        // } else {
        //   if (event?.websiteTemplate === "default") {
        //     return RegoFormDefaultColors.labelFontColor;
        //   }
        //   return RegoFormColors.labelFontColor;
        // }
    }, [event?.websiteTemplate]);

    const textFieldstyles = useMemo(() => {
        if (currerntPage === "survey" || currerntPage === "registration") {
            secondaryFontColor = currentPageFontColor || secondaryFontColor;
            secondaryBackgroundColor = currentPageBackgroundColor || secondaryBackgroundColor;
            headerColor = currentPageHeaderColor || headerColor;
            labelFontColor = currentPageFontColor || secondaryFontColor;
            labelBackGroundColor = currentPageBackgroundColor || labelBackGroundColor;
            titleDescPosition = currentPageTitleDescPosition || titleDescPosition;
        }
        if (secondaryFontColor)
            return (
                {
                    "& .MuiInputLabel-root": {
                        color: labelFontColor ? `${labelFontColor} !important` : null,
                        backgroundColor: labelBackGroundColor,
                        fontFamily: "Poppins-400",
                        // width: "94%",
                        padding: '0 4px',
                        left: '-4px',
                        top: '-5px',
                        fontWeight: '300',
                    },
                    "& .MuiInputLabel-shrink": {
                        top: '0 !important',
                    },
                    "& .MuiAutocomplete-input": {
                        height: '10px !important',
                        color: secondaryFontColor ? `${secondaryFontColor} !important` : null,
                        paddingLeft: "0px !important",
                        // paddingLeft: "12px !important",
                    },
                    "& .MuiAutocomplete-inputRoot": {
                        paddingLeft: "12px !important",
                        fontFamily: 'Poppins-400'
                    },

                    "& .MuiOutlinedInput-input": {
                        color: secondaryFontColor ? `${secondaryFontColor} !important` : null,
                        padding: '0px',
                        height: '43px',
                        borderRadius: ' 0px !important',
                        fontFamily: "Poppins-400",
                    },

                    "& .MuiOutlinedInput-root": {
                        borderRadius: '4px !important',
                        fontFamily: 'Poppins-400',
                        color: `${secondaryFontColor} !important`,
                        paddingLeft: "12px !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        bordeRadius: ' 4px',
                        // outline: '1px solid rgba(0, 255, 205, 1)',
                        boxShadow: `0 0 0 0.15em ${focusColor}`,
                    },
                    input: {
                        "&:-webkit-autofill": {
                            WebkitBoxShadow: `0 0 0 1000px ${secondaryBackgroundColor} inset`,
                            '-webkit-text-fill-color': `${secondaryFontColor}`
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        // styles for the border
                        borderColor: fieldBorderColor ? `${fieldBorderColor}` : null,
                        '&:hover': {
                            // styles for hover
                            borderColor: fieldBorderColor ? `${fieldBorderColor} !important` : null,
                        },
                        '&.Mui-focused': {
                            // styles for focus
                            borderColor: secondaryFontColor ? `${secondaryFontColor} !important` : null,
                            border: `0.5px solid ${secondaryFontColor} !important`,
                        },
                    },
                    '&:hover fieldset': {
                        borderColor: fieldBorderColor ? `${fieldBorderColor} !important` : null,
                        border: `0.5px solid ${secondaryFontColor} !important`,
                    },
                    '&:focus-within fieldset': {
                        borderColor: secondaryFontColor ? `${secondaryFontColor} !important` : null,
                        border: `0.5px solid ${secondaryFontColor} !important`,
                    },

                }
            )

        return null;
    }, [currerntPage, currentPageFontColor, currentPageBackgroundColor, secondaryFontColor, labelBackGroundColor]);

    const styles = textFieldstyles || {};
    return {
        styles,
        primaryBackgroundColor,
        primaryFontColor,
        secondaryBackgroundColor,
        secondaryFontColor,
        fieldBorderColor,
        focusColor,
        headerColor,
        titleDescPosition
    };
}

export default useStyling;
