import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IEditPostResult } from '../../../models/post/EditPostResult';
import { IPostUserActionState } from '../../../models/post/PostUserActionState';
import { PostService } from '../../../services/Post';
import { PostUserActionTypes } from './types';

export interface IPostUserActionInProgress {
  type: PostUserActionTypes.IN_PROGRESS;
  inProgress: boolean;
}

export interface IPostUserActionReportSuccess {
  type: PostUserActionTypes.REPORT_POST;
  message: string;
  reportedPostId: string;
}

export interface IPostUserActionUnfollowSuccess {
  type: PostUserActionTypes.UNFOLLOW_POST;
  message: string;
}

export interface IPostUserActionError {
  type: PostUserActionTypes.ERROR;
  errorMessage: string;
}

export interface IEditPost {
  type: PostUserActionTypes.EDIT_POST;
  result: IEditPostResult;
}

export interface IDeletePost {
  type: PostUserActionTypes.DELETE_POST;
  message: string;
}

export type PostUserActions =
  | IPostUserActionInProgress
  | IPostUserActionReportSuccess
  | IPostUserActionUnfollowSuccess
  | IPostUserActionError
  | IEditPost
  | IDeletePost;

const postService = new PostService();

export const reportPost: ActionCreator<
  ThunkAction<Promise<any>, IPostUserActionState, null, IPostUserActionReportSuccess>
> = (postId: string, reportMessage: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await postService.reportPost(postId, reportMessage);

      dispatch({
        type: PostUserActionTypes.REPORT_POST,
        message: result.message,
        reportedPostId: postId
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: PostUserActionTypes.ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: PostUserActionTypes.IN_PROGRESS,
        loading: false
      });
    }
  };
};

export const unfollowPost: ActionCreator<
  ThunkAction<Promise<any>, IPostUserActionState, null, IPostUserActionUnfollowSuccess>
> = (postId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await postService.unfollowPost(postId);

      dispatch({
        type: PostUserActionTypes.UNFOLLOW_POST,
        message: result.message
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: PostUserActionTypes.ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: PostUserActionTypes.IN_PROGRESS,
        loading: false
      });
    }
  };
};

export const editPost: ActionCreator<
  ThunkAction<Promise<any>, IPostUserActionState, null, IEditPost>
> = (postId: string, content: string, privacy?: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await postService.editPost(postId, content, privacy);

      dispatch({
        type: PostUserActionTypes.EDIT_POST,
        result: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: PostUserActionTypes.ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: PostUserActionTypes.IN_PROGRESS,
        loading: false
      });
    }
  };
};

export const deletePost: ActionCreator<
  ThunkAction<Promise<any>, IPostUserActionState, null, IPostUserActionUnfollowSuccess>
> = (postId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await postService.deletePost(postId);

      dispatch({
        type: PostUserActionTypes.DELETE_POST,
        message: result.message
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: PostUserActionTypes.ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: PostUserActionTypes.IN_PROGRESS,
        loading: false
      });
    }
  };
};

export const setInProgress: ActionCreator<
  ThunkAction<any, IPostUserActionState, null, IPostUserActionInProgress>
> = (isInProgress: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: PostUserActionTypes.IN_PROGRESS,
    loading: isInProgress
  });
