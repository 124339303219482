const HostTermsList = () => (
  <div className="bg-gray-0 w-full">
    <div className="block w-9/12 mx-auto">
      <div className="mt-7 text-gray-1 table-of-contents text-lg5">Table of Contents</div>
      <div className="mt-3 font-normal text-md2 tracking-wide">
        <div className="">
          1.
          <a href="#accept-terms" className="hover:underline text-blue-1 ml-2">
            Accept These Terms
          </a>
        </div>
        <div className="mt-2">
          2.
          <a href="#parties-of-terms" className="hover:underline text-blue-1 ml-2">
            Parties of These Terms
          </a>
        </div>
        <div className="mt-2">
          3.
          <a href="#account-membership" className="hover:underline text-blue-1 ml-2">
            Accounts and membership
          </a>
        </div>
        <div className="mt-2">
          4.
          <a href="#mobile-services" className="hover:underline text-blue-1 ml-2">
            Mobile Services
          </a>
        </div>
        <div className="mt-2">
          5.
          <a href="#billing-payments" className="hover:underline text-blue-1 ml-2">
            Billing and payments
          </a>
        </div>
        <div className="mt-2">
          6.
          <a href="#termination" className="hover:underline text-blue-1 ml-2">
            Termination
          </a>
        </div>
        <div className="mt-2">
          7.
          <a href="#your-content" className="hover:underline text-blue-1 ml-2">
            Your Content
          </a>
        </div>
        <div className="mt-2">
          8.
          <a href="#our-content" className="hover:underline text-blue-1 ml-2">
            Our Content
          </a>
        </div>
        <div className="mt-2">
          9.
          <a href="#backup" className="hover:underline text-blue-1 ml-2">
            Backup
          </a>
        </div>
        <div className="mt-2">
          10.
          <a href="#link-to-other-websites" className="hover:underline text-blue-1 ml-2">
            Links to other websites
          </a>
        </div>
        <div className="mt-2">
          11.
          <a href="#disclaimer-warranty" className="hover:underline text-blue-1 ml-2">
            Disclaimer of warranty
          </a>
        </div>
        <div className="mt-2">
          12.
          <a href="#liability-indemnity" className="hover:underline text-blue-1 ml-2">
            Liability and Indemnity
          </a>
        </div>
        <div className="mt-2">
          13.
          <a href="#contacting-you" className="hover:underline text-blue-1 ml-2">
            Contacting You
          </a>
        </div>
        <div className="mt-2">
          14.
          <a href="#privacy" className="hover:underline text-blue-1 ml-2">
            Privacy
          </a>
        </div>
        <div className="mt-2">
          15.
          <a href="#applicable-law" className="hover:underline text-blue-1 ml-2">
            Applicable Law
          </a>
        </div>
        <div className="mt-2">
          16.
          <a href="#general-provisions" className="hover:underline text-blue-1 ml-2">
            General Provisions
          </a>
        </div>
        <div className="mt-2">
          17.
          <a href="#variations" className="hover:underline text-blue-1 ml-2">
            Variations
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default HostTermsList;
