import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
}

function MenuPopoverIcon({ fillColor, fillBgColor, ...props }: Props) {
    return (
        <svg id="Component_324_45" data-name="Component 324 – 45" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
            <g id="Ask_to_unmute" data-name="Ask to unmute">
                <path id="Path_10672" data-name="Path 10672" d="M11.5,0A11.5,11.5,0,1,1,0,11.5,11.5,11.5,0,0,1,11.5,0Z" fill="#edf2f5" />
            </g>
            <circle id="Ellipse_1202" data-name="Ellipse 1202" cx="1.5" cy="1.5" r="1.5" transform="translate(5 10)" fill="#203c6e" />
            <circle id="Ellipse_1203" data-name="Ellipse 1203" cx="1.5" cy="1.5" r="1.5" transform="translate(10 10)" fill="#203c6e" />
            <path id="Path_27137" data-name="Path 27137" d="M1.5,0a1.5,1.5,0,0,1,0,3C.672,3,0,1.5,0,1.5S.672,0,1.5,0Z" transform="translate(15 10)" fill="#203c6e" />
        </svg>

    );
}

export default MenuPopoverIcon;