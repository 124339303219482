/* eslint-disable max-len */
import * as Main from '../../components/footer/footer';

import logo from '../../assets/images/homepage/footer-logo.svg';
import panelistlogo from '../../assets/images/homepage/panelist-link.svg';
import linkedInlogo from '../../assets/images/homepage/linkedIn-link.svg';
import playStore from '../../assets/images/homepage/play-store.png';
import appleStore from '../../assets/images/homepage/app-store.png';

export const Footer = () => {
  const onFooterClick = (href: string) => {
    window.open(href, '_blank');
  };
  return (
    <div className="bg-[#1C1E42] lg:px-[100px] px-[30px]">
      <div className="py-[40px]">
        <div className="w-full container mx-auto max-w-full">
          <div className="flex items-start justify-between xl:flex-row flex-col gap-y-7 xl:gap-y-0">
            <div className="md:pt-0 2xl:ml-0 text-white flex-row">
              <img src={logo} alt="" />
              <div className="flex flex-col mt-[23px] mb-[32px]">
                <text className="text-[16px] leading-[24px] whitespace-nowrap">
                  © Copyright
                  {' '}
                  {new Date().getFullYear()}
                </text>
                <text className="text-[16px] leading-[24px] whitespace-nowrap">Panelist Pty Ltd.</text>
                <text className="text-[16px] leading-[24px] whitespace-nowrap">All rights reserved.</text>
              </div>
              <div className="flex gap-[10px]">
                <a href="https://panelist.com">
                  <img className="w-10 h-10" src={panelistlogo} alt="" />
                </a>
                <a href="https://www.linkedin.com/company/panelist">
                  <img className="w-10 h-10" src={linkedInlogo} alt="" />
                </a>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-[16px] font-medium leading-[20px] text-white mb-[15px]">
                Explore
              </div>
              <div className="flex flex-col items-start">
                <button onClick={() => onFooterClick('https://panelist.com/spotlight')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">Library</button>
                <button onClick={() => onFooterClick('https://panelist.com/events')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">Events </button>
                <button onClick={() => onFooterClick('https://panelist.com/home')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">Network</button>
                <button onClick={() => onFooterClick('https://panelist.com/home')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left">Lounge</button>
              </div>
            </div>
            <div className="flex flex-col text-left">
              <div className="text-[16px] font-medium leading-[20px] text-white mb-[15px]">
                Business Solutions
              </div>
              <div className="flex flex-col items-start">
                <button onClick={() => onFooterClick('https://business.panelist.com/virtual')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">
                  Virtual events & Webinars
                </button>
                <button onClick={() => onFooterClick('https://business.panelist.com/hybrid')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">
                  In-person events
                </button>
                <button onClick={() => onFooterClick('https://business.panelist.com/hybrid')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">
                  Hybrid events
                </button>
                <button onClick={() => onFooterClick('https://business.panelist.com/event-app')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">
                  Mobile app
                </button>
                <button onClick={() => onFooterClick('https://business.panelist.com/prospect-match')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">
                  Event check-in solutions
                </button>
                <button onClick={() => onFooterClick('https://business.panelist.com/audio-visual')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">
                  Audio Visual Solutions
                </button>
                <button onClick={() => onFooterClick('https://business.panelist.com/hub-community')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">
                  Professional community
                </button>
                <button onClick={() => onFooterClick('https://business.panelist.com/event-app')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left">
                  Product demo platform
                </button>
              </div>
            </div>

            <div className="flex flex-col text-left">
              <div className="text-[16px] font-medium leading-[20px] text-white mb-[15px]">
                General
              </div>
              <div className="flex flex-col items-start">
                <button onClick={() => onFooterClick('https://business.panelist.com/contact-us')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">Contact us</button>
                <button onClick={() => onFooterClick('https://panelist.com/loginorjoin')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">Sign up </button>
                <button onClick={() => onFooterClick('https://business.panelist.com/about-us')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">About us</button>
                <button onClick={() => onFooterClick('https://help.panelist.com/')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">Help center</button>
                <button onClick={() => onFooterClick('https://business.panelist.com/branding')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left">Branding</button>
              </div>
            </div>
            <div className="flex flex-col text-left">
              <div className="text-[16px] font-medium leading-[20px] text-white mb-[15px]">
                Policy
              </div>
              <div className="flex flex-col items-start">
                <button onClick={() => onFooterClick('https://panelist.com/user-agreement')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">User Agreement</button>
                <button onClick={() => onFooterClick('https://panelist.com/privacy-policy.html')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">Privacy Policy </button>
                <button onClick={() => onFooterClick('https://panelist.com/cookie-policy')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">Cookie Policy</button>
                <button onClick={() => onFooterClick('https://panelist.com/copyright-policy')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left mb-[10px]">Copyright Policy</button>
                <button onClick={() => onFooterClick('https://panelist.com/community-guidelines')} type="button" className="hover:underline text-[14px] font-light leading-[16px] text-white text-left">Community Guidelines</button>
              </div>
            </div>
            <div className="flex flex-col text-left">
              <div className="text-[16px] font-medium leading-[20px] text-white mb-[15px]">
                Download
              </div>
              <div className="flex flex-col items-start">
                <a href="https://play.google.com/store/apps/details?id=com.panelist">
                  <img className="w-[210px] h-[63px] max-w-fit mb-[13px]" src={playStore} alt="" />
                </a>
                <a href="https://apps.apple.com/gb/app/panelist/id1618656782">
                  <img className="w-[210px] h-[63px] max-w-fit" src={appleStore} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Main.Footer className="h-[50px] flex items-center border-t-1 border-white justify-start !text-[20px]" />
      </div>
    </div>
  );
};

export default Footer;
