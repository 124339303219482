import { Avatar, Stack, Typography } from '@mui/material';
import { getMinifiedImage } from 'src/mui/utils/image';

export interface Props {
  name: string;
  logo: string;
}

const Owner = ({ name, logo }: Props) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Avatar
      alt={name}
      src={getMinifiedImage(logo)}
      sx={{
        height: '25px',
        width: '25px',
        borderRadius: '6px',
        backgroundColor: '#f4f6f8',
        '& .MuiAvatar-img': {
          objectFit: 'contain !important',
        },
      }}
    />
    <Typography variant="subtitle2" noWrap>
      {name}
    </Typography>
  </Stack>
);

export default Owner;
