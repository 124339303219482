import classNames from 'classnames';

/* eslint-disable max-len */
type IPaginationProps = {
  paginateFront: Function;
  paginateBack: Function;
  setCurrentPage: (value: any) => void;
  currentPage: number;
  totalPage: number;
};

export const Pagination = (props: IPaginationProps) => {
  const getTotal = Array.from({ length: props.totalPage }, (_, i) => i + 1);
  const startIndex = (
    (props.currentPage === 1 && props.currentPage - 1)
    || (props.currentPage === 2 && props.currentPage - 2)
    || (props.currentPage === 3 && props.currentPage - 3)
    || (props.currentPage === 4 && props.currentPage - 4)
    || (props.currentPage >= 5 && props.currentPage - 5)
  );
  const endIndex = (
    (props.currentPage === 1 && props.currentPage + 8)
    || (props.currentPage === 2 && props.currentPage + 7)
    || (props.currentPage === 3 && props.currentPage + 6)
    || (props.currentPage === 4 && props.currentPage + 5)
    || (props.currentPage >= 5 && props.currentPage + 4)
  );
  return (
    <div className="">
      <nav aria-label="Page navigation example" className="flex">
        <ul className="inline-flex sm:flex-row flex-col sm:gap-0 gap-1 items-center justify-between w-full">
          <li>
            <button
              type="button"
              className="flex items-center justify-center cursor-pointer h-[30px]"
              onClick={() => {
                props.paginateBack();
              }}
              disabled={props.currentPage <= 1}
            >
              <svg id="Group_11797" data-name="Group 11797" xmlns="http://www.w3.org/2000/svg" width="87.504" height="23" viewBox="0 0 87.504 23">
                <text id="Previous" transform="translate(51.504 17)" fill="#285cb2" fontSize="16" fontFamily="Poppins-Regular, Poppins" letterSpacing="0.03em"><tspan x="-35.128" y="0">Previous</tspan></text>
                <g id="Component_149_2821" data-name="Component 149 – 2821" transform="translate(9 3.004) rotate(90)">
                  <path id="Polygon_9" data-name="Polygon 9" d="M7.773.77a1,1,0,0,1,1.454,0l6.18,6.544A1,1,0,0,1,14.68,9H2.32a1,1,0,0,1-.727-1.687Z" transform="translate(17 9) rotate(180)" fill="#203c6e" />
                </g>
              </svg>
            </button>
          </li>
          <li className="flex items-center justify-center">
            {
              getTotal && getTotal.slice(startIndex || 0, endIndex || 0).map((item: any, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    props.setCurrentPage(item);
                  }}
                  className="mx-[2px]"
                >
                  <span
                    className={classNames('cursor-pointer w-[30px] h-[30px] min-w-[30px] min-h-[30px] hover:bg-[#EDF2F5] rounded-20 flex items-center justify-center text-[16px] text-[#172746] leading-6', {
                      'bg-[#EDF2F5]': item === props.currentPage
                    })}
                  >
                    {item}
                  </span>
                </div>
              ))
            }
          </li>
          <li>
            <button
              type="button"
              className="flex items-center justify-center cursor-pointer h-[30px]"
              onClick={() => {
                props.paginateFront();
              }}
              disabled={props.currentPage === props.totalPage}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="53" height="23" viewBox="0 0 53 23">
                <g id="Group_11796" data-name="Group 11796" transform="translate(1)">
                  <text id="Next" transform="translate(18 17)" fill="#285cb2" fontSize="16" fontFamily="Poppins-Regular, Poppins" letterSpacing="0.03em"><tspan x="-18.048" y="0">Next</tspan></text>
                  <g id="Component_149_2822" data-name="Component 149 – 2822" transform="translate(43 19.996) rotate(-90)">
                    <path id="Polygon_9" data-name="Polygon 9" d="M7.773.77a1,1,0,0,1,1.454,0l6.18,6.544A1,1,0,0,1,14.68,9H2.32a1,1,0,0,1-.727-1.687Z" transform="translate(17 9) rotate(180)" fill="#203c6e" />
                  </g>
                </g>
              </svg>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
