import bulb from '../../../assets/images/compliance/bulb-icon.png';

const TableContentTwo = () => (
  <>
    <hr className="my-10" />
    <div id="parties-of-terms" className="mt-7 text-gray-1 text-lg5">
      2. Parties of These Terms
    </div>

    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        We have a Privacy Policy, Cookie Policy, and Community Guidelines. You acknowledge you
        have read those documents before agreeing to these Terms.
      </div>
    </div>

    <div className="mt-5 text-gray-1 text-lg5 font-light">You as the User</div>
    <div className="mt-3">
      a. When these terms use the word “User”, we mean those persons who have been invited by
      Hosts to attend an Event using the Platform or for any other reason. Users, consumers and
      third parties using the Platform are all referred to in these terms as “Users”, “you”, or
      “your”.
    </div>
    <div className="mt-5">
      b. These terms apply not only to registered Users but also unregistered Users. All Users,
      including third parties, are subject of these Terms, as well as the Community Guidelines,
      Privacy Policy, Cookie Policy and Copyright Policy. You should also be aware that you may
      be subject of further terms, so you must ensure that you thoroughly review the Platform
      from time to time. By accepting these terms, you acknowledge that you have read the
      Community Guidelines, Privacy Policy and Cookie Policy.
    </div>
    <div className="mt-5">
      c. You’re eligible to enter into the Terms and you are at least our “Minimum Age.” The
      Platform is not for use by anyone under the age of 16. To use the Platform, you agree
      that: (1) you must be the Minimum Age or older; (2) you will only have one Panelist
      account, which must be in your real name; and (3) you are not already restricted by
      Panelist from using the Platform. Creating an account with false information is a
      violation of the Terms, including accounts registered on behalf of others or persons under
      the age of 16.
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-5">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        You must meet certain requirements to use our Services. Remember, using false
        information when creating your account or an account on behalf of someone else, will be
        a breach of these Terms.
      </div>
    </div>
    <div className="mt-5 text-gray-1 text-lg5 font-light">Panelist</div>
    <div className="mt-3">
      d. Panelist connects professionals around the world. It is a professional networking
      platform for events. Panelist provides a platform for professionals globally to create
      business profiles, and webpages related to their events, promote those pages and events to
      other users, visitors or browsers on the platform, and manage online or onsite ticketing
      and registration. Descriptions of other and more specific services can generally be found
      on the Site.
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">Our Business Aim</div>
    </div>
    <div className="mt-3">
      e. You acknowledge that Panelist is not the creator, organiser or owner of the events
      listed on the Platform. Rather, Panelist provides the Platform which allows persons to
      manage, promote and hold their Events (‘the Host’). The Host is solely responsible for
      ensuring that any page displaying their Event or their related Services meets all
      applicable local, state, national and other laws, rules and regulations, and that the
      goods and services described on their Event Page are delivered as described and in an
      accurate satisfactory manner.
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        We provide the ability for people to manage their Events. We are not responsible for the
        event; the Host is solely responsible for all social and legal obligations.
      </div>
    </div>
    <div className="mt-3">
      f. The Host of a paid event selects the payment processing method. Users must use whatever
      payment processing method the Host selects. If the Host selects a payment processing
      method that uses a third party to process the payment, then neither Panelist nor any of
      its payment processing partners processes the transaction. Rather, where you receive an
      invoice through the assistance of a Third-Party Payment Provider such as Stripe (‘TPPP’),
      you acknowledge that you have read over those TPPP terms and conditions thoroughly. The
      TPPP or their bank or other financial institution may require you to pay processing fees
      or other fees and charges. Any such fees and charges are your sole responsibility and are
      not included in the invoice, or other paid services unless otherwise specified. We accept
      no responsibility for your use of any TPPP. You agree to keep their information including
      their email address and payment details up to date so that the TPPP and we can process
      payment and contact you as needed in connection with the provision of the payment.
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        We are not responsible for organising payments for events on the Platform. The Host may
        choose to use a Third-Party Payment Method such as Stripe or Paypal, and where they do
        so, you agree you’ve also read their Terms and agree to the same.
      </div>
    </div>
  </>
);

export default TableContentTwo;
