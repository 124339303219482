export interface ILikeSvgIconProps {
    fillColor?: string;
}
export const ShareSvgIcon = ({ fillColor }: ILikeSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#717171"
    return <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 20.343 19.19">
    <g id="Component_65_1" data-name="Component 65 – 1" transform="translate(0.652 0.65)">
      <path id="Path_10823" data-name="Path 10823" d="M158.246,85.4c-1.718.008-1.351-3.6-1.216-4.627a10.965,10.965,0,0,1,2.173-5.2,9.711,9.711,0,0,1,6.455-3.653.968.968,0,0,0,.852-.955v-2.39a1.095,1.095,0,0,1,1.847-.766l7.178,7.142a1.1,1.1,0,0,1,0,1.529L168.357,83.7a1.1,1.1,0,0,1-1.85-.763V80.446c0-1.618-2.766-.3-3.4.065a9.069,9.069,0,0,0-2.61,2.371c-.354.46-.7.987-1.021,1.493-.264.413-.536.926-1.07,1.016A1.036,1.036,0,0,1,158.246,85.4Z" transform="translate(-156.902 -67.515)" fill="none" stroke={iconColor} stroke-miterlimit="10" strokeWidth="1.3"/>
    </g>
  </svg>
  
};