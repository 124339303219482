import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  Autoplay, EffectFade, Pagination
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getJobTitle, getSpeakerCompanyName, getSpeakerName } from 'src/utils';
import { IAppState } from '../../../store';
import { Logo } from '../../../shared-components/V2/Logo';

const WebsiteEventSpeaker = () => {
  const speakers = useSelector(
    (state: IAppState) => state.website.event!.speakers
  );

  if (!speakers?.length) return null;
  return (
    <div
      id="Speakers"
      className={classNames('w-full', {
        hidden: !(speakers && speakers.length > 0),
      })}
    >
      <div className="w-full">
        <div
          className="text-[20px] text-white font-[500] leading-[22px] helvetica-neue-65-medium"
        >
          AUDIENCE
        </div>
        <div className="flex flex-row mt-[15px]">
          <Swiper
            modules={[Pagination, Autoplay, EffectFade]}
            spaceBetween={10}
            breakpoints={{
              320: {
                slidesPerView: 1
              },
              480: {
                slidesPerView: 2
              },
              768: {
                slidesPerView: 3
              },
              1224: {
                slidesPerView: 4
              }
            }}
            pagination={{
              dynamicBullets: false,
              clickable: true,
            }}
            autoplay={{
              delay: 9000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
              // stopOnLastSlide: false,
              waitForTransition: false
            }}
            scrollbar={{ draggable: true }}
            className="spectreSpeakerSlider"
          >
            {speakers.map((item, index) => (
              <SwiperSlide key={`swiper-slide-${index}`}>
                <div className="flex flex-col justify-start relative">
                  <div className="flex items-center w-full min-w-full h-[263px] min-h-[263px]">
                    <Logo
                      src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item.eventSpeakers?.[0]?.photo}`}
                      className={classNames('w-full min-w-full h-[263px] min-h-[263px] rounded-[10px] grayscale-0 hover:grayscale-0 transition-all', {
                        'p-4 object-cover bg-[#edf2f5]': !item.eventSpeakers?.[0]?.photo,
                        'object-cover bg-[#111111]': item.eventSpeakers?.[0]?.photo,
                      })}
                      defaultLogo="user"
                    />
                  </div>
                  <div
                    style={{ backgroundImage: 'linear-gradient(to bottom, transparent, #000000)' }}
                    className="absolute left-0 bottom-0 pb-[15px] px-[15px] h-[131px] flex flex-col justify-end w-full"
                  >
                    <div className=" text-white text-[20px] mb-[5px] line-clamp-1 helvetica-neue-65-medium leading-[22px]">
                      {getSpeakerName(item)}
                    </div>
                    <div className="text-[15px] text-white leading-[17px] line-clamp-1 tracking-tight helvetica-neue-45-light">
                      {' '}
                      {getJobTitle(item)}
                      {' • '}
                      {getSpeakerCompanyName(item)}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventSpeaker;
