/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import React, { useEffect, useRef, useState } from 'react';
import { IAppState } from 'src/store';
import { IEvent } from 'src/models/user-events';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Divider } from '@mui/material';
import { getVerticalRooms, groupAndSortRoomsByDays, groupByTags } from 'src/utils/rooms';
import { RegisterSectionProps } from '../../types';
import SessionAccordion from './session-accordion';
import SessionDriversAccordion from './session-drivers-accordion';

const AgendaAccordion = ({ onRegister }: RegisterSectionProps) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);

  const [activeTab, setActiveTab] = useState(0);
  const [activeBreakoutRoom, setActiveBreakoutRoom] = useState<any>({});
  const [email, setEmail] = useState<string>('');
  const scrollRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
  const [showLeftArrows, setShowLeftArrows] = useState<{ [key: string]: boolean }>({});
  const [showRightArrows, setShowRightArrows] = useState<{ [key: string]: boolean }>({});
  const [activeDay, setActiveDay] = useState<string | null>(null);

  // Function to assign refs dynamically
  const getOrCreateRef = (roomKey: string): React.RefObject<HTMLDivElement> => {
    if (!scrollRefs.current[roomKey]) {
      scrollRefs.current[roomKey] = React.createRef();
    }
    return scrollRefs.current[roomKey];
  };

  const handleScroll = (roomKey: string) => {
    const scrollableDiv = getOrCreateRef(roomKey)?.current;

    if (scrollableDiv) {
      const isScrolledToStart = scrollableDiv.scrollLeft === 0;
      const isScrolledToEnd = scrollableDiv.scrollLeft + scrollableDiv.clientWidth >= scrollableDiv.scrollWidth;

      setShowLeftArrows((prev) => ({
        ...prev,
        [roomKey]: !isScrolledToStart,
      }));

      setShowRightArrows((prev) => ({
        ...prev,
        [roomKey]: !isScrolledToEnd,
      }));
    }
  };

  const sideScroll = (
    element: HTMLDivElement | null,
    speed: number = 10,
    distance: number,
    step: number
  ) => {
    if (!element) return;

    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const handleRegistration = () => {
    onRegister(email);
  };

  const groupedArray = groupByTags(event?.rooms || []);
  const sortedGroupedRooms = groupAndSortRoomsByDays(event?.rooms || []);
  const sortedDays = Object.keys(sortedGroupedRooms || {});
  const getSelectedDayRooms = activeDay && sortedGroupedRooms?.[activeDay];
  const groupVerticalRooms = getVerticalRooms(getSelectedDayRooms || []) || {};

  useEffect(() => {
    if (sortedDays.length > 0 && !activeDay) {
      setActiveDay(sortedDays[0]);
    }
  }, [sortedDays, activeDay]);

  useEffect(() => {
    let defaultActiveRooms: any = {};
    if (groupVerticalRooms && Object.values(groupVerticalRooms)?.length) {
      Object.entries(groupVerticalRooms)?.forEach(([roomKey, multipleRooms]: [string, any]) => {
        if (multipleRooms?.length > 2) {
          defaultActiveRooms = { ...defaultActiveRooms, [roomKey]: multipleRooms?.[0]?.id };
        }
      });
      setActiveBreakoutRoom(defaultActiveRooms);
    }
  }, [event?.rooms]);

  return (
    <div className="w-full mt-[40px]">
      {/* Tab Headers */}
      <div className="flex xl:flex-row flex-col justify-between mb-[30px] xl:gap-y-0 gap-y-[22px]">
        <div className="flex">
          <button
            className={`w-[190px] text-white md:leading-[20px] leading-[20px] md:text-[19px] text-[17px] pb-[15px] helvetica-neue-48-medium text-center ${activeTab === 0
              ? 'border-b-[3px] border-white text-white'
              : 'border-b-[3px] text-[#555555] border-[#555555]'
            }`}
            onClick={() => setActiveTab(0)}
          >
            By Sessions
          </button>
          <button
            className={`w-[190px] text-white md:leading-[20px] leading-[20px] md:text-[19px] pb-[15px] text-[17px] helvetica-neue-48-medium text-center ${activeTab === 1
              ? 'border-b-[3px] border-white text-white'
              : 'border-b-[3px] text-[#555555] border-[#555555]'
            }`}
            onClick={() => setActiveTab(1)}
          >
            By Drivers
          </button>
        </div>
        <div className="flex justify-end">
          <div className="flex flex-col gap-y-[14px] md:flex-row gap-[8px] sm:w-auto w-full">
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your business email"
              className="rounded-[3px] text-[15px] leading-[23px] bg-[#14161A] text-white sm:w-[370px] w-full h-[35px] tracking-wide pl-[14px] font-medium helvetica-neue-55-roman"
            />

            <button
              type="submit"
              onClick={() => handleRegistration()}
              className="rounded-[3px] inline-block text-[15px] bg-[#E73B4C] hover:bg-[#E73B4C] text-black sm:w-[258px] w-full h-[35px] tracking-wide font-medium helvetica-neue-55-roman"
            >
              Contact our team
            </button>
          </div>
        </div>
      </div>

      {/* Tab Days */}
      {activeTab === 0 && sortedDays.length > 1 ? (
        <div className="flex mb-[30px]">
          {sortedDays.map((day, index) => (
            <button
              key={day}
              className={`w-[190px] text-white text-center pb-[8px] md:text-[19px] text-[17px] helvetica-neue-48-medium flex items-center justify-center ${
                activeDay === day
                  ? 'border-b-[3px] border-white text-white'
                  : 'border-b-[3px] text-[#555555] border-[#555555]'
              }`}
              onClick={() => setActiveDay(day)}
            >

              {event?.id === '839c9658-39db-4595-b9ee-4868036f1fe5'
                ? (index === 0
                  ? 'Pre-Conference Dinner'
                  : index === 1
                    ? 'Conference Day'
                    : `Day ${index + 1}`
                )
                : `Day ${index + 1}`}
            </button>
          ))}
        </div>
      ) : null}

      {/* Tab Content */}
      <div className="w-full">
        {activeTab === 0 ? (
          <div className="w-full">
            {Object.entries(groupVerticalRooms)?.map(([roomKey, multipleRooms]: [string, any]) => (
              <div key={roomKey} className="w-full">
                {multipleRooms?.length >= 5 && (
                  <div className="relative mb-[10px]">
                    <button
                      onClick={() => sideScroll(getOrCreateRef(roomKey)?.current!, 25, 320, -10)}
                      className="absolute -left-5  xl:left-[-54px] top-32 z-10 text-white text-[24px] flex h-[35px] w-[35px] items-center justify-center rounded-[5px] bg-[#14161A]"
                    >
                      &lt;
                    </button>

                    <div
                      ref={getOrCreateRef(roomKey)}
                      className={classNames('w-full flex gap-x-[10px] relative overflow-hidden', {})}
                      onScroll={() => handleScroll(roomKey)}
                    >
                      {multipleRooms?.map((room: any) => (
                        <div
                          key={room.id}
                          className={classNames('w-[289px] flex-shrink-0 transition-transform duration-500 ease-in-out', {})}
                        >
                          {multipleRooms?.length && room.sessions?.length ? (
                            <>
                              <div className="text-center text-white mt-[11px] md:leading-[21px] leading-[20px] md:text-[20px] text-[16px] helvetica-neue-65-medium font-semibold sm:line-clamp-1 mb-[17px]">
                                {room.name}
                              </div>
                              <Divider sx={{ background: 'white', marginBottom: '17px' }} />
                            </>
                          ) : null}
                          <div className="w-full flex flex-col gap-[10px]">
                            {room.sessions?.map((session: any) => (
                              <SessionAccordion
                                key={session.id}
                                session={session}
                                isBreakoutSession={multipleRooms?.length === 2}
                                isBreakoutSession5
                              />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>

                    <button
                      onClick={() => sideScroll(getOrCreateRef(roomKey)?.current!, 25, 320, 10)}
                      className="absolute -right-5 xl:right-[-54px] top-32 text-white text-[24px] flex h-[35px] w-[35px] items-center justify-center rounded-[5px] bg-[#14161A]"
                    >
                      &gt;
                    </button>
                  </div>
                )}

                {multipleRooms?.length <= 2 && (
                  <div className={classNames('w-full flex gap-x-[10px] relative flex-wrap', {})}>
                    {multipleRooms?.map((room: any) => (
                      <div
                        key={room.id}
                        className={classNames('', {
                          'w-full': multipleRooms?.length === 1,
                          'w-[calc(50%-5px)]': multipleRooms?.length === 2,
                        })}
                      >
                        {multipleRooms?.length && room.sessions?.length ? (
                          <>
                            <div className="text-center text-white mt-[11px] md:leading-[21px] leading-[20px] md:text-[20px] text-[16px] helvetica-neue-65-medium font-semibold sm:line-clamp-1 mb-[17px]">
                              {room.name}
                            </div>
                            <Divider sx={{ background: 'white', marginBottom: '17px' }} />
                          </>
                        ) : null}
                        <div className="w-full">
                          {room.sessions?.map((session: any) => (
                            <SessionAccordion
                              key={session.id}
                              session={session}
                              isBreakoutSession={multipleRooms?.length === 2}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {multipleRooms?.length > 2 && multipleRooms?.length < 5 && (
                  <div className={classNames('w-full mt-2 border-b-[1px] border-[#555555] mb-[10px]', {})}>
                    <div className="flex justify-between border-b-[3px] border-[#555555] mb-[10px]">
                      {multipleRooms?.map((room: any) => (
                        <button
                          key={room.id}
                          className={`w-[190px] text-white md:leading-[21px] leading-[20px] md:text-[19px] text-[17px] pb-[15px] helvetica-neue-48-medium text-center -mb-[3px] ${room.id === activeBreakoutRoom?.[roomKey]
                            ? 'border-b-[3px] border-white text-white'
                            : 'border-b-[3px] text-[#555555] border-transparent'
                          }`}
                          onClick={() => setActiveBreakoutRoom({ ...activeBreakoutRoom, [roomKey]: room?.id })}
                        >
                          {room?.name}
                        </button>
                      ))}
                    </div>
                    {multipleRooms?.map((room: any) => (
                      room.id === activeBreakoutRoom?.[roomKey] ? (
                        <div key={room.id} className="w-full">
                          <div className="w-full">
                            {room.sessions?.map((session: any) => (
                              <SessionAccordion key={session.id} session={session} isBreakoutSession={multipleRooms?.length === 2} />
                            ))}
                          </div>
                        </div>
                      ) : null
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

        ) : (
          <div className="w-full">
            {groupedArray?.map((item: any) => (
              <SessionDriversAccordion key={item.id} session={item} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AgendaAccordion;
