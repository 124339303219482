/* eslint-disable max-len */
import { useState } from 'react';

interface IProductsSvgIcon {
  isActive?: boolean;
  className?: string;
  disableHover?: boolean;
}
export const ProductsSvgIcon = ({ isActive, className, disableHover }: IProductsSvgIcon) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const colorWhite = '#ffffff';
  const defaultColor = '#7283A4';
  const fillColor = !disableHover && (isHover || isActive) ? colorWhite : defaultColor;

  return (
    <svg
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="27.001"
      height="27"
      viewBox="0 0 27.001 27"
    >
      <g id="Group_21107" data-name="Group 21107" transform="translate(-802.255 -621.305)">
        <rect id="Rectangle_8276" data-name="Rectangle 8276" width="12.643" height="12.642" rx="4" transform="translate(816.613 621.305)" fill={fillColor} />
        <rect id="Rectangle_8277" data-name="Rectangle 8277" width="12.643" height="12.642" rx="4" transform="translate(802.255 635.663)" fill={fillColor} />
        <rect id="Rectangle_8278" data-name="Rectangle 8278" width="11.615" height="11.614" rx="4" transform="translate(817.641 636.691)" fill={fillColor} />
      </g>
    </svg>
  );
};
