import { SVGProps } from 'react';

function AddSpeakerIcon(props: SVGProps<SVGSVGElement>) {
  const { style, ...restProps } = props;
  return (
    <svg
      id="Group_21264"
      data-name="Group 21264"
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <rect
        id="Rectangle_8380"
        data-name="Rectangle 8380"
        width="60"
        height="60"
        rx="18"
        fill="#f0f0f0"
      />
      <g id="add-square-svgrepo-com" transform="translate(20 20)">
        <path
          id="Path_14810"
          data-name="Path 14810"
          d="M15,12H12m0,0H9m3,0V9m0,3v3"
          transform="translate(-2 -2)"
          fill="none"
          stroke="#212121"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_14811"
          data-name="Path 14811"
          d="M22,12c0,4.714,0,7.071-1.465,8.535S16.714,22,12,22s-7.071,0-8.536-1.465S2,16.714,2,12,2,4.929,3.464,3.464,7.286,2,12,2s7.071,0,8.535,1.464c.974.974,1.3,2.342,1.409,4.536"
          transform="translate(-2 -2)"
          fill="none"
          stroke="#212121"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}

export default AddSpeakerIcon;
