import axios from "axios";
// import { UserService } from "src/services";
import { delAuthToken, getAuthRefreshToken, setAuthToken } from "src/utils";

// ----------------------------------------------------------------------

let isRefreshing = false;
let failedQueue: any[] = [];

const axiosV2Instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_V2,
});

axiosV2Instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;
    const requestURL = error?.request?.responseURL;
    const isValidBackend: boolean =
      requestURL && requestURL.startsWith(process.env.REACT_APP_API_URL_V2);
    const isLoginRequest = config?.url?.includes("/login");

    if (
      error?.response?.status === 401 &&
      !config?.sent &&
      !config.url?.includes("refresh") &&
      isValidBackend &&
      !isLoginRequest
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject, config });
        });
      }

      config.sent = true;
      isRefreshing = true;

      try {
        const { data: refreshResult } = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/refresh`,
          {
            refreshToken: getAuthRefreshToken(),
          }
        );
        const result = refreshResult.data;
        setAuthToken(result);

        if (result?.token) {
          config.headers = {
            ...config.headers,
            Authorization: `${result?.token}`,
          };

          const retryRequest = axios.request(config);

          failedQueue.forEach((request: any) => {
            request.resolve(
              axios.request({
                ...request.config,
                headers: { Authorization: result.token },
              })
            );
          });

          failedQueue = [];
          return retryRequest;
        }
      } catch (err) {
        localStorage.clear();
        sessionStorage.clear();
        axiosV2Instance.defaults.headers.common["Authorization"] = "";
        delAuthToken();
        window.location.href = "/loginorjoin";
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default axiosV2Instance;
