import naplanevent from '../../../assets/images/overview/naplan-event.png';
import naplanevent2 from '../../../assets/images/overview/naplan-event@2x.png';
import iceconnectevent from '../../../assets/images/overview/ice-connect-event.png';
import iceconnectevent2 from '../../../assets/images/overview/ice-connect-event@2x.png';
import sitdownevent from '../../../assets/images/overview/sit-down-event.png';
import sitdownevent2 from '../../../assets/images/overview/sit-down-event@2x.png';
import consultevent from '../../../assets/images/overview/consult-event.png';
import consultevent2 from '../../../assets/images/overview/consult-event@2x.png';

export const HostingRightEvent = () => (
  <div className="bg-gray-1 mt-16 h-full">
    <div className="lg:flex 2xl:mx-auto 2xl:container">
      <div className="lg:flex flex-col justify-between ">
        <div className="block lg:flex flex-col items-start mt-9 lg:ml-36 pl-1">
          <p className="lg:text-xl5 font-medium text-xl text-blue-4 md:mt-6w-2/3">
            Are you hosting the right event on Panelist?
          </p>
          <p className="text-lg text-dark-blue font-extralight mt-6 text-left">
            Panelist Event Manager is engineered to give marketers the easiest and the most user
            friendly way to
            {' '}
            <br />
            host targeted virtual or full hybrid events with highly engaged professionals.
          </p>
          <p className="text-lg text-dark-blue font-extralight  mt-2 text-left">
            Here are some popular formats on Panelist:
          </p>
        </div>
        <div className="block lg:flex lg:flex-row lg:ml-36 mb-8 lg:w-4/5">
          <div className="mt-4 mb-12 lg:w-1/4">
            {/*   <img src={naplanevent} alt='' /> */}
            <img
              srcSet={`${naplanevent} 300w, ${naplanevent2} 1000w`}
              sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 500px"
              alt=""
              src={naplanevent2}
            />
            <div className="">
              <div className="lg:text-lg font-extralight text-xl text-blue-4 md:mt-6w-2/3 ml-2 mt-4 w-3/4">
                Signature Virtual Events
              </div>
              <p className="text-sm4 font-normal text-dark-blue ml-2 mt-4 w-9/12">
                Signature enterprise-wide events to re-enforce existing relationships and attract
                new customers
              </p>
            </div>
          </div>
          <div className="mt-4  lg:w-1/4">
            <img
              srcSet={`${iceconnectevent} 300w, ${iceconnectevent2} 1000w`}
              sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 500px"
              alt=""
              src={iceconnectevent2}
            />
            <div className="lg:text-lg font-extralight text-xl text-blue-4 md:mt-6w-2/3 ml-2 mt-4 w-10/12">
              Regular lead generation and community building
            </div>
            <p className="text-sm4 font-normal text-dark-blue ml-2 mt-4 w-9/12">
              Create a community centre and provide live and on-demand access to your events,
              resources and product demos.
            </p>
          </div>
          <div className="mt-4 lg:w-1/4">
            <img
              srcSet={`${sitdownevent} 300w, ${sitdownevent2} 1000w`}
              sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 500px"
              alt=""
              src={sitdownevent2}
            />

            <div className="lg:text-lg font-extralight text-xl text-blue-4 md:mt-6w-2/3 ml-2 mt-4 w-10/12">
              Annual events, conferences and expos
            </div>
            <p className="text-sm4 font-normal text-dark-blue ml-2 mt-4 w-9/12">
              Multiple day large scale industry trade shows with exhibition centres and social
              lounges.
            </p>
          </div>
          <div className="mt-4 lg:w-1/4">
            <img
              srcSet={`${consultevent} 300w, ${consultevent2} 1000w`}
              sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 500px"
              alt=""
              src={consultevent2}
            />

            <div className="lg:text-lg font-extralight text-xl text-blue-4 md:mt-6w-2/3 ml-2 mt-4 w-10/12">
              Events for targeted audiences
            </div>
            <p className="text-sm4 font-normal text-dark-blue ml-2 mt-4 w-9/12">
              Gather targeted audiences in workshops and foucsed discussion groups to share and
              exchange knowledge.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HostingRightEvent;
