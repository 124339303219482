// @mui
import Stack from "@mui/material/Stack";
import { CardProps } from "@mui/material/Card";
// components
import Carousel, { useCarousel } from "src/mui/components/carousel";
import { useState, useCallback, useEffect } from "react";
import { UserService } from "src/services";
import EmptyContent from "src/mui/components/empty-content";
import { LoadingScreen } from "src/mui/components/loading-screen";
import { InterestItem } from "./interest-item";
// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  user: any;
  event: any;
  activeList?: any;
  columnCardHeight?: any;
}

export default function MatchCarousel({
  title,
  subheader,
  user,
  event,
  activeList,
  columnCardHeight,
  ...other
}: Props) {

  const [interests, setInterests] = useState<any[]>([]);
  const [totalMatchUsers, setTotalMatchUsers] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const limit = 3;

  async function getOffers(userId: string): Promise<any[]> {
    const userService = new UserService();
    const { data } = await userService.getMatchedOfferingResults(userId);
    return data.map((x: any) => ({ ...x, type: "offer" })) ?? [];
  }

  async function getInterests(userId: string): Promise<any[]> {
    const userService = new UserService();
    const { data } = await userService.getMatchedInterestResults(userId);
    return data.map((x: any) => ({ ...x, type: "interest" })) ?? [];
  }

  async function getEvents(userId: string): Promise<any[]> {
    const userService = new UserService();
    const { data } = await userService.getMatchedEventResults(userId);
    return data ?? [];
  }

  async function getCompanies(userId: string): Promise<any[]> {
    const userService = new UserService();
    const { data } = await userService.getMatchedCompaniesResults(userId);
    return data ?? [];
  }

  async function getProfileMatch(userId: string): Promise<number> {
    const userService = new UserService();
    const { data } = await userService.getProfielMatchResults(userId);
    return data?.matchedCompatibilityScore ?? 0;
  }

  async function fetchInterestData(item: any) {
    try {
      const [offers, interests, events, companies, profileMatch] =
        await Promise.all([
          getOffers(item.userId),
          getInterests(item.userId),
          getEvents(item.userId),
          getCompanies(item.userId),
          getProfileMatch(item.userId),
        ]);

      return {
        ...item,
        offers,
        interests,
        events,
        companies,
        profileMatch,
        type: "interest",
      };
    } catch (error) {
      console.error(`Error fetching data for item ${item.userId}:`, error);
      return null;
    }
  }

  const getInterestMatch = useCallback(async () => {
    const userService = new UserService();
    const result = await userService.getMatchedInterestEventResults(
      event?.id,
      currentPage,
      limit
    );
    setTotalMatchUsers(result.totalRow);
    const interestData = await Promise.all(
      result?.data?.map(fetchInterestData)
    );
    setInterests(
      (prevInterests) =>
        [...prevInterests, ...interestData.filter(Boolean)] ?? []
    );

    setIsLoading(false);
  }, [currentPage]);

  const onMatchAudience = useCallback(
    async (status: string, id: string, eventId: string) => {
      const userService = new UserService();
      const data = {
        status: status,
        audienceId: id,
      };
      await userService.matchAudience(data, eventId).then(() => {
        const newAudienceInterestList = interests.filter(
          (interest) => interest.userId !== id
        );
        setInterests(newAudienceInterestList);
        setCurrentPage(currentPage + 1);
      });
    },
    [currentPage, interests]
  );

  const handleChangeCarousel = (current: number, next: number) => {
    if (
      current < next &&
      next + 2 === interests?.length &&
      interests?.length !== totalMatchUsers
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    getInterestMatch();
  }, [currentPage]);

  const carousel = useCarousel({
    adaptiveHeight: true,
    beforeChange: handleChangeCarousel,
  });

  if (isLoading) return <LoadingScreen />;
  if (!user) return <></>;

  return (
    <Stack
      sx={{
        position: "relative !important",
        p: "0px",
        mt: "19px",
        height: "100%",
        "& .slick-slider": {
          height: "100%",
        },
        // maxHeight: activeList.length > 3 ? 'calc(100vh - 617px)' : '100%',
      }}
    >
      {interests?.length > 0 ? (
        <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
          {interests?.map((item: any) => (
            <InterestItem
              key={item.id}
              item={item}
              user={user}
              eventId={event?.id}
              onMatchAudience={onMatchAudience}
              activeList={activeList}
              columnCardHeight={columnCardHeight}
            />
          ))}
        </Carousel>
      ) : (
        <EmptyContent
          filled
          title="No Data"
          imgUrl=""
          sx={{
            py: 10,
          }}
        />
      )}
    </Stack>
  );
}
