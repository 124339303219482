import scaleyourattendance from '../../../assets/images/overview/scale-attendance.png';
import scaleyourattendance2 from '../../../assets/images/overview/scale-attendance@2x.png';

export const ScaleYourAttendance = () => (
  <div className="bg-white w-full">
    <div className="container mx-auto">
      <div className="block lg:flex justify-center w-11/12 mx-auto md:ml-16 ml-2">
        <div className="mt-24 pt-2">
          <img
            srcSet={`${scaleyourattendance} 300w, ${scaleyourattendance2} 1000w`}
            sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 800px"
            alt=""
            src={scaleyourattendance2}
          />
        </div>
        <div className="2xl:ml-16 lg:mt-36 pt-4 ml-10 text-dark-blue ">
          <p className="lg:text-xl3 font-extralight text-dark-blue  text-xl  mt-10 md:mt-6w-2/3">
            Scale your attendance with Panelist community
          </p>
          <ul className="list-disc font-extralight text-md mt-4 ml-6 text-dark-blue ">
            <li>Marketing and paid discovery</li>
            <li>Organic discovery</li>
          </ul>
          <p className="text-lg font-extralight text-dark-blue mt-5 text-left mt-4">
            Use Panelist's targeting tools to bring the right professionals into your event with:
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ScaleYourAttendance;
