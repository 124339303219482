import hybridjourney from '../../../assets/images/hybrid/full-hybrid.png';
import hybridjourney2 from '../../../assets/images/hybrid/full-hybrid@2x.png';

export const HybridJourney = () => (
  <div className="bg-gray-3 w-full -mt-36 ">
    <div className="container mx-auto  pt-14 mt-20">
      <div className="lg:text-lg1 font-extralight text-dark-blue  mx-auto block lg:flex justify-center mb-11 pt-3">
        A fully hybrid journey
      </div>
      <div className="block lg:flex justify-center w-11/12 mx-auto md:ml-10 pl-8 pt-9">
        <div className="z-40">
          <img
            srcSet={`${hybridjourney} 300w, ${hybridjourney2} 1000w`}
            sizes="(max-width: 46rem) 20vw, (max-width: 46rem) 30vw, 1050px"
            alt=""
            src={hybridjourney2}
          />
        </div>
        <div className="2xl:ml-16 lg:mt-16 pt-5 ml-7 pl-1 text-dark-blue ">
          <p className="lg:text-xl3 font-extralight text-dark-blue  text-xl  md:mt-6w-2/3">
            A shared journey, built on connections
          </p>
          <p className="lg:text-lg font-extralight text-dark-blue text-xl  mt-4 md:mt-6w-2/3">
            A 360 view for your community
          </p>

          <p className="text-md font-extralight text-gray-1 text-left mt-3">
            Create genuine long term relationships by giving your audience a chance to be as much
            involved as your in-person attendees.
          </p>

          <div className="mt-3">
            <p className="text-sm5  text-gray-1 text-left ">
              *Hybrid social lounge, expo, Q&A and roundtables
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HybridJourney;
