import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IInvitationResult } from '../../../models/event/InvitationResult';
import { IInvitationState } from '../../../models/event/InvitationState';
import { EventsService } from '../../../services/events';
import { InviteAttendeeActionTypes } from './types';

export interface IInviteAttendeeInProgressAction {
  type: InviteAttendeeActionTypes.INVITATION_IN_PROGRESS;
  inProgress: boolean;
}

export interface IInviteAttendeeSucessAction {
  type: InviteAttendeeActionTypes.INVITATION_SENT;
  result: IInvitationResult;
}

export interface IInviteAttendeeErrorAction {
  type: InviteAttendeeActionTypes.INVITATION_ERROR;
  errorMessage: string;
}

export type InviteAttendeesActions =
  | IInviteAttendeeInProgressAction
  | IInviteAttendeeSucessAction
  | IInviteAttendeeErrorAction;

export const sendInvitations: ActionCreator<
  ThunkAction<Promise<any>, IInvitationState, null, IInviteAttendeeSucessAction>
> = (eventId: string, userId: string) => {
  return async (dispatch: Dispatch) => {
    const eventService = new EventsService();

    try {
      const result = await eventService.inviteAttendee(eventId, userId);

      dispatch({
        type: InviteAttendeeActionTypes.INVITATION_SENT,
        result: result
      });
    } catch (error: any) {
      console.error(error);

      if (error.statusCode === '400') {
        dispatch({
          type: InviteAttendeeActionTypes.INVITATION_ERROR,
          errorMessage: 'User has already been invited'
        });
      } else {
        dispatch({
          type: InviteAttendeeActionTypes.INVITATION_ERROR,
          errorMessage: error.message
        });
      }
    } finally {
      dispatch({
        type: InviteAttendeeActionTypes.INVITATION_IN_PROGRESS,
        inProgress: false
      });
    }
  };
};

export const setInProgress: ActionCreator<
  ThunkAction<any, IInvitationState, null, IInviteAttendeeInProgressAction>
> = (isInProgress: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: InviteAttendeeActionTypes.INVITATION_IN_PROGRESS,
    loading: isInProgress
  });
