import React, { FC } from 'react';

import { UserNavbar } from '../components';

interface IUserProfileLayoutProps {
  hero: JSX.Element;
  leftPanel: JSX.Element;
  rightPanel: JSX.Element;
  children: JSX.Element;
}

export const UserProfileLayout: FC<IUserProfileLayoutProps> = ({
  hero,
  leftPanel,
  rightPanel,
  children,
}) => (
  <div className="bg-gray-1 h-full">
    <UserNavbar />
    <div>
      <div className="container mx-auto mt-6 px-6 xl:px-0 max-w-[100%] xl:max-w-[1258px]">
        <div className="xl:flex flex-row justify-center">
          <div className="flex flex-col pb-2 xl:w-auto w-[100%]">
            <div className="xl:w-[912px]">{hero}</div>
            <div className="flex flex-row justify-between gap-x-4 xl:gap-x-0">
              <div className="mt-4 xl:w-[303px] md:w-[40%] md:flex hidden">{leftPanel}</div>
              <div className="mt-4 md:mb-8 xl:w-[594px] md:w-[60%] w-[100%]">{children}</div>
            </div>
          </div>
          <div className="flex xl:flex-col xl:ml-[31px] xl:w-[315px] w-full">{rightPanel}</div>
        </div>
      </div>
    </div>
  </div>
);

export default UserProfileLayout;
