import { SVGProps } from 'react';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

function LayoutFocusIcon(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props;
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.864" height="18.008" viewBox="0 0 27.864 18.008">
            <rect id="Rectangle_8436" data-name="Rectangle 8436" width="19" height="18.008" rx="2" fill={defaultColor}/>
            <rect id="Rectangle_8437" data-name="Rectangle 8437" width="8" height="18.008" rx="2"
                  transform="translate(19.864)" fill={defaultColor}/>
        </svg>

    );
}

export default LayoutFocusIcon;
