import { Reducer } from "redux";

import { IUserSignUpState } from "../../../models/signup/UserSignUpState";
import { SignUpUserActions } from "./actions";
import { UserSignUpActionTypes } from "./types";

const initialState: IUserSignUpState = {
  isLoggedIn: false,
  value: null,
  loading: false,
  errorMessage: "",
  currentStep: 0,
  emailPassword: null,
  firstNameLastName: null,
  cityCountryPostCode: null,
  jobTitleCompanyIndustry: null,
  offerListInterestedList: null,
  eventRegistration: null,
  eventJoining: null,
};

export const SingupReducer: Reducer<IUserSignUpState, SignUpUserActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UserSignUpActionTypes.RESET_SIGNUP_USER: {
      return initialState;
    }

    case UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS: {
      return {
        ...state,
        loading: action.loggingIn,
      };
    }

    case UserSignUpActionTypes.SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        value: action.payload,
        errorMessage:""
      };
    }

    case UserSignUpActionTypes.SIGNUP_USER_FAILURE: {
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: action.errorMessage,
        value: null,
      };
    }

    case UserSignUpActionTypes.SIGNUP_USER_ERROR: {
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: action.errorMessage,
        value: null,
      };
    }

    case UserSignUpActionTypes.GO_TO_NEXT_STEP: {
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    }

    case UserSignUpActionTypes.GO_TO_BACK_STEP: {
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    }

    case UserSignUpActionTypes.GO_TO_LAST_STEP: {
      return {
        ...state,
        currentStep: state.currentStep + 3,
      };
    }

    case UserSignUpActionTypes.GO_TO_FIRST_STEP: {
      return {
        ...state,
        currentStep: 0,
      };
    }

    case UserSignUpActionTypes.SET_EMAIL_PASSWORD: {
      return {
        ...state,
        emailPassword: action.payload,
      };
    }

    case UserSignUpActionTypes.SET_FIRSTNAME_LASTNAME: {
      return {
        ...state,
        firstNameLastName: action.payload,
      };
    }

    case UserSignUpActionTypes.SET_CITY_COUNTRY_POSTCODE: {
      return {
        ...state,
        cityCountryPostCode: action.payload,
      };
    }

    case UserSignUpActionTypes.SET_JOBTITLE_COMPANY_INDUSTRY: {
      return {
        ...state,
        jobTitleCompanyIndustry: action.payload,
      };
    }

    case UserSignUpActionTypes.SET_OFFERLIST_INTERESTEDLIST: {
      return {
        ...state,
        offerListInterestedList: action.payload,
      };
    }

    case UserSignUpActionTypes.SET_STATIC_REGISTRATION_FIELDS: {
      return {
        ...state,
        eventRegistration: action.payload,
      };
    }

    case UserSignUpActionTypes.SET_DYNAMICALLY_REGISTRATION_FIELDS: {
      return {
        ...state,
        eventJoining: action.payload,
      };
    }

    default:
      return state;
  }
};
