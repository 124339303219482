import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

type ParseContentProps = {
  content?: any | null;
  className?: string;
  isRequired?: boolean;
  maxLength?: number; 
  enableToggle?: boolean;
};

export const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className,
  isRequired,
  maxLength = 200,
  enableToggle = false
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const sanitizedContent = DOMPurify.sanitize(content);
  const parsedContent = parse(sanitizedContent);

  const shouldTruncate = enableToggle && content && !isExpanded && content.length > maxLength;
  const truncatedContent = shouldTruncate
    ? content.substring(0, maxLength) + '...'
    : content;

  return (
    <div className={className}>
      {parse(DOMPurify.sanitize(truncatedContent || content))}
      {shouldTruncate && enableToggle && (
        <button
          onClick={() => setIsExpanded(true)}
          style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
        >
          See More
        </button>
      )}
      {isExpanded && enableToggle && (
        <>
          {parsedContent}
          <button
            onClick={() => setIsExpanded(false)}
            style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
          >
            See Less
          </button>
        </>
      )}
      {isRequired && <span style={{ color: 'red', marginLeft: '5px' }}>*</span>}
    </div>
  );
};
