import { SVGProps } from 'react';

function Crosscon(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props;
    return (
        <svg id="Component_413_12" data-name="Component 413 – 12" xmlns="http://www.w3.org/2000/svg" width="30" height="30" style={style} viewBox="0 0 30 30">
            <g id="Ellipse_1203" data-name="Ellipse 1203" fill="none" strokeWidth="1" >
                <circle cx="15" cy="15" r="15" stroke="none" />
                <circle cx="15" cy="15" r="14.5" fill="none" />
            </g>
            <line id="Line_1440" data-name="Line 1440" x1="9.561" y2="9.561" transform="translate(10.22 10.22)" fill="none" strokeLinecap="round" strokeWidth="2" style={style} />
            <line id="Line_1441" data-name="Line 1441" x2="9.561" y2="9.561" transform="translate(10.22 10.22)" fill="none" strokeLinecap="round" strokeWidth="2" style={style} />
        </svg>


    );
}

export default Crosscon;