import { useState, useEffect } from 'react';
import classNames from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/Add';
import ExpandIcon from '@mui/icons-material/Remove';
// import RemoveIcon from '@mui/icons-material/Cancel';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '../../../assets/images/event-landing-pre-event/Icon-feather-search.svg';
import crossIcons from '../../../assets/images/white-cross.png';

import { CompanyService } from '../../../services/CompanyService';
import { DebounceInput } from '../../../shared-components/DebounceInput';

export interface IDropDownMenuItems {
  id: string;
  name: string;
  url?: string;
  onClick?: any;
  type?: string;
  isSelected?: boolean;
}

type ICompanyFiltersProps = {
  // eslint-disable-next-line no-unused-vars
  setCompanyEventsFilters: (value: string) => void;
  companyFilters: string;
  searchText: string | undefined;
  // eslint-disable-next-line no-unused-vars
  setSearchText: (query: string) => void
};
export const EventFilters = (props: ICompanyFiltersProps) => {
  const eventFormats = [
    { title: 'hybrid', name: 'Hybrid' },
    { title: 'live', name: 'Live' }
  ];
  const [selectedTab, setSelectedTab] = useState('');
  const [specialties, setSpecialties] = useState<any>([]);
  const [industries, setIndustries] = useState<any>([]);
  const [sizes, setSizes] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const comapnyService: CompanyService = new CompanyService();

  // filters
  const [specialtiesFilters, setSpecialtiesFilters] = useState<any>([]);
  const [eventFormatFilters, setEventFormatFilters] = useState<any>([]);
  const [industriesFilters, setIndustriesFilters] = useState<any>([]);
  const [sizesFilters, setSizesFilters] = useState<any>([]);
  const [regionsFilters, setRegionsFilters] = useState<any>([]);
  const [isFilterShow, setIsFilterShow] = useState<boolean>(false);

  // exclusions
  const [specialtiesExclusions, setSpecialtiesExclusions] = useState<any>([]);
  const [eventFormatExclusions, setEventFormatExclusions] = useState<any>([]);
  const [industriesExclusions, setIndustriesExclusions] = useState<any>([]);
  const [sizesExclusions, setSizesExclusions] = useState<any>([]);
  const [regionsExclusions, setRegionsExclusions] = useState<any>([]);

  const getSpeacialties = async (query: string = '') => {
    // const result: any = await comapnyService.getSpecialitiesWithQuery(query);
    new CompanyService().getJobFunctionsWithQuery(query).then(res => {
      // let jobFunctionList: IDropDownMenuItems[] = [];
      // res?.data
      //   && res?.data.map((item: any) => jobFunctionList.push({
      //     id: item.id,
      //     name: item.name,
      //     type: 'jobFunction',
      //   }));
      // jobFunctionList = jobFunctionList.filter(
      //   i => i.name !== null && i.name !== ''
      // );
      setSpecialties([...res?.data]);
    });
    // setSpecialties([...result?.data]);
  };
  const getIndustries = async (query: string = '') => {
    const result: any = await comapnyService.getIndustriesWithQuery(query);
    setIndustries([...result?.data]);
  };
  const getCompanySizes = async (query: string = '') => {
    const result: any = await comapnyService.getCompanySizes(query);
    setSizes([...result?.data]);
  };
  const getCompanyRegions = async () => {
    const result: any = await comapnyService.getCompanyRegions();
    setRegions([...result?.data]);
  };

  const filterRegions = async (query: string = '') => {
    if (query === '') {
      await getCompanyRegions();
    } else {
      const filteredRegions = regions.filter((r: any) => r.headquarter?.toLowerCase().indexOf(query.toLowerCase()) > -1);
      setRegions([...filteredRegions]);
    }
  };

  useEffect(() => {
    getSpeacialties();
    getIndustries();
    getCompanySizes();
    getCompanyRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEventFormat = () => (
    <div className="max-h-44 overflow-auto scroll-style">
      {eventFormats.map((item: any) => (
        <div key={item.title} className="flex items-center justify-between bg-transparent mb-[1px]">
          <div
            className="flex items-center w-full cursor-pointer"
            onClick={() => {
              const isalreadyAdded = [...eventFormatFilters].filter(c => c.title === item.title);
              if (isalreadyAdded.length === 0) {
                props.setCompanyEventsFilters(`${props.companyFilters}&where[format]=${item.title}`);
                setEventFormatFilters([...eventFormatFilters, item]);
              }
            }}
          >
            <div className="flex flex-col items-start">
              <span className="block text-[13px] leading-5 text-[#285CB2] hover:underline hover:cursor-pointer">
                {item.name}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const getEventFormatExclusionsList = () => (
    <div className="max-h-44 overflow-auto scroll-style">
      {eventFormatExclusions.map((item: any) => (
        <span
          key={item.id}
          className="block text-[13px] leading-5 text-[#285CB2] hover:underline hover:cursor-pointer mb-[1px] line-clamp-1"
          onClick={() => {
            const isAlreadyExcluded = [...eventFormatExclusions].filter(
              i => i.title === item.title
            );
            if (isAlreadyExcluded.length === 0) {
              props.setCompanyEventsFilters(
                `${props.companyFilters}&where[excludeFormat]=${item.title}`
              );
              setEventFormatExclusions([...eventFormatExclusions, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getSpecialtiesList = () => (
    <div className="max-h-44 overflow-auto scroll-style">
      {specialties.map((item: any) => (
        <span
          key={item.id}
          className="block text-[13px] leading-5 text-[#285CB2] hover:underline hover:cursor-pointer mb-[1px] line-clamp-1"
          onClick={() => {
            const isalreadyAdded = [...specialtiesFilters].filter(sp => sp.id === item.id);
            if (isalreadyAdded.length === 0) {
              props.setCompanyEventsFilters(
                `${props.companyFilters}&where[jobFunctionId]=${item.id}`
              );
              setSpecialtiesFilters([...specialtiesFilters, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getSpecialtiesExclusionsList = () => (
    <div className="max-h-44 overflow-auto scroll-style">
      {specialtiesExclusions.map((item: any) => (
        <span
          key={item.id}
          className="block text-[13px] leading-5 text-[#285CB2] hover:underline hover:cursor-pointer mb-[1px] line-clamp-1"
          onClick={() => {
            const isAlreadyExcluded = [...specialtiesExclusions].filter(
              i => i.id === item.id
            );
            if (isAlreadyExcluded.length === 0) {
              props.setCompanyEventsFilters(
                `${props.companyFilters}&where[excludeCompanySpeciality]=${item.name}`
              );
              setIndustriesExclusions([...specialtiesExclusions, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getIndustriesList = () => (
    <div className="max-h-44 overflow-auto scroll-style">
      {industries.map((item: any) => (
        <span
          key={item.id}
          className="block text-[13px] leading-5 text-[#285CB2] hover:underline hover:cursor-pointer mb-[1px] line-clamp-1"
          onClick={() => {
            const isalreadyAdded = [...industriesFilters].filter(i => i.id === item.id);
            if (isalreadyAdded.length === 0) {
              props.setCompanyEventsFilters(`${props.companyFilters}&where[industryId]=${item.id}`);
              setIndustriesFilters([...industriesFilters, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getIndustriesExclusionsList = () => (
    <div className="max-h-44 overflow-auto scroll-style">
      {industriesExclusions.map((item: any) => (
        <span
          key={item.id}
          className="block text-[13px] leading-5 text-[#285CB2] hover:underline hover:cursor-pointer mb-[1px] line-clamp-1"
          onClick={() => {
            const isAlreadyExcluded = [...industriesExclusions].filter(
              i => i.id === item.id
            );
            if (isAlreadyExcluded.length === 0) {
              props.setCompanyEventsFilters(
                `${props.companyFilters}&where[excludeIndustryId]=${item.id}`
              );
              setIndustriesExclusions([...industriesExclusions, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getSizesList = () => (
    <div className="max-h-44 overflow-auto scroll-style">
      {sizes.map((item: any) => (
        <span
          key={item.id}
          className="block text-[13px] leading-5 text-[#285CB2] hover:underline hover:cursor-pointer mb-[1px] line-clamp-1"
          onClick={() => {
            const isalreadyAdded = [...sizesFilters].filter(s => s.id === item.id);
            if (isalreadyAdded.length === 0) {
              props.setCompanyEventsFilters(`${props.companyFilters}&where[companySizeId]=${item.id}`);
              setSizesFilters([...sizesFilters, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getSizeExclusionsList = () => (
    <div className="max-h-44 overflow-auto scroll-style">
      {sizesExclusions.map((item: any) => (
        <span
          key={item.id}
          className="block text-[13px] leading-5 text-[#285CB2] hover:underline hover:cursor-pointer mb-[1px] line-clamp-1"
          onClick={() => {
            const isAlreadyExcluded = [...sizesExclusions].filter(
              i => i.id === item.id
            );
            if (isAlreadyExcluded.length === 0) {
              props.setCompanyEventsFilters(
                `${props.companyFilters}&where[excludeCompanySizeId]=${item.id}`
              );
              setSizesExclusions([...sizesExclusions, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const getRegionsList = () => (
    <div className="max-h-44 overflow-auto scroll-style">
      {regions.map((item: any, index: number) => (
        <span
          key={index}
          className="block text-[13px] leading-5 text-[#285CB2] hover:underline hover:cursor-pointer mb-[1px] line-clamp-1"
          onClick={() => {
            const isalreadyAdded = [...regionsFilters].filter(r => r.name === item.headquarter);
            if (isalreadyAdded.length === 0) {
              props.setCompanyEventsFilters(
                `${props.companyFilters}&where[region]=${item.headquarter}`
              );
              setRegionsFilters([
                ...regionsFilters,
                {
                  name: item.headquarter
                }
              ]);
            }
          }}
        >
          {item.headquarter}
        </span>
      ))}
    </div>
  );

  const getRegionExclusionsList = () => (
    <div className="max-h-44 overflow-auto scroll-style">
      {regionsExclusions.map((item: any) => (
        <span
          key={item.id}
          className="block text-[13px] leading-5 text-[#285CB2] hover:underline hover:cursor-pointer mb-[1px] line-clamp-1"
          onClick={() => {
            const isAlreadyExcluded = [...regionsExclusions].filter(
              i => i.id === item.id
            );
            if (isAlreadyExcluded.length === 0) {
              props.setCompanyEventsFilters(
                `${props.companyFilters}&where[excludeRegion]=${item.id}`
              );
              setRegionsExclusions([...regionsExclusions, item]);
            }
          }}
        >
          {item.name}
        </span>
      ))}
    </div>
  );

  const removeEventFormatItemHandler = (item: any, excluding: boolean = false) => {
    const updatedItem = [...eventFormatFilters].filter(c => c.title !== item.title);
    setEventFormatFilters([...updatedItem]);
    props.setCompanyEventsFilters(props.companyFilters.replace(`&where[format]=${item.title}`, ''));

    if (excluding) setEventFormatExclusions([...eventFormatExclusions, item]);
  };

  const removeSpecialtiesItemHandler = (item: any, excluding: boolean = false) => {
    const updatedItem = [...specialtiesFilters].filter(c => c.id !== item.id);
    setSpecialtiesFilters([...updatedItem]);

    if (excluding) {
      props.setCompanyEventsFilters(
        props.companyFilters.replace(`&where[companySpeciality]=${item.name}`, `&where[excludeCompanySpeciality]=${item.name}`)
      );
      setSpecialtiesExclusions([...specialtiesExclusions, item]);
    } else {
      props.setCompanyEventsFilters(
        props.companyFilters.replace(`&where[companySpeciality]=${item.name}`, '')
      );
    }
  };

  const removeIndustriesItemHandler = (item: any, excluding: boolean = false) => {
    const updatedItem = [...industriesFilters].filter(c => c.id !== item.id);
    setIndustriesFilters([...updatedItem]);

    if (excluding) {
      props.setCompanyEventsFilters(props.companyFilters.replace(`&where[industryId]=${item.id}`, `&where[excludeIndustryId]=${item.id}`));
      setIndustriesExclusions([...industriesExclusions, item]);
    } else {
      props.setCompanyEventsFilters(props.companyFilters.replace(`&where[industryId]=${item.id}`, ''));
    }
  };

  const removeSizesItemHandler = (item: any, excluding: boolean = false) => {
    const updatedItem = [...sizesFilters].filter(c => c.id !== item.id);
    setSizesFilters([...updatedItem]);

    if (excluding) {
      props.setCompanyEventsFilters(props.companyFilters.replace(`&where[companySizeId]=${item.id}`, `&where[excludeCompanySizeId]=${item.id}`));
      setSizesExclusions([...sizesExclusions, item]);
    } else {
      props.setCompanyEventsFilters(props.companyFilters.replace(`&where[companySizeId]=${item.id}`, ''));
    }
  };

  const removeRegionsItemHandler = (item: any, excluding: boolean = false) => {
    const updatedItem = [...regionsFilters].filter(c => c.name !== item.name);
    setRegionsFilters([...updatedItem]);

    if (excluding) {
      props.setCompanyEventsFilters(props.companyFilters.replace(`&where[region]=${item.name}`, `&where[excludeRegion]=${item.name}`));
      setRegionsExclusions([...regionsExclusions, item]);
    } else {
      props.setCompanyEventsFilters(props.companyFilters.replace(`&where[region]=${item.name}`, ''));
    }
  };

  const removeCompanyExclusionsHandler = (item: any, revoking: boolean = false) => {
    const updatedItem = [...eventFormatExclusions].filter(c => c.title !== item.title);
    setEventFormatExclusions([...updatedItem]);
    props.setCompanyEventsFilters(
      props.companyFilters.replace(`&where[excludeFormat]=${item.title}`, '')
    );

    if (revoking) {
      props.setCompanyEventsFilters(
        `${props.companyFilters}&where[format]=${item.title}`
      );
      setEventFormatFilters([...eventFormatFilters, item]);
    }
  };

  const removeSpecialtiesExclusionsHandler = (item: any, revoking: boolean = false) => {
    const updatedItem = [...specialtiesExclusions].filter(c => c.id !== item.id);
    setSpecialtiesExclusions([...updatedItem]);

    if (revoking) {
      props.setCompanyEventsFilters(
        props.companyFilters.replace(`&where[excludeCompanySpeciality]=${item.name}`, `&where[companySpeciality]=${item.name}`)
      );
      setSpecialtiesFilters([...specialtiesFilters, item]);
    } else {
      props.setCompanyEventsFilters(
        props.companyFilters.replace(`&where[excludeCompanySpeciality]=${item.name}`, '')
      );
    }
  };

  const removeIndustriesExclusionsHandler = (item: any, revoking: boolean = false) => {
    const updatedItem = [...industriesExclusions].filter(c => c.id !== item.id);
    setIndustriesExclusions([...updatedItem]);

    if (revoking) {
      props.setCompanyEventsFilters(
        props.companyFilters.replace(`&where[excludeIndustryId]=${item.id}`, `&where[industryId]=${item.id}`)
      );
      setIndustriesFilters([...industriesFilters, item]);
    } else {
      props.setCompanyEventsFilters(
        props.companyFilters.replace(`&where[excludeIndustryId]=${item.id}`, '')
      );
    }
  };

  const removeSizesExclusionsHandler = (item: any, revoking: boolean = false) => {
    const updatedItem = [...sizesExclusions].filter(c => c.id !== item.id);
    setSizesExclusions([...updatedItem]);

    if (revoking) {
      props.setCompanyEventsFilters(
        props.companyFilters.replace(`&where[excludeCompanySizeId]=${item.id}`, `&where[companySizeId]=${item.id}`)
      );
      setSizesFilters([...sizesFilters, item]);
    } else {
      props.setCompanyEventsFilters(
        props.companyFilters.replace(`&where[excludeCompanySizeId]=${item.id}`, '')
      );
    }
  };

  const removeRegionsExclusionsHandler = (item: any, revoking: boolean = false) => {
    const updatedItem = [...regionsExclusions].filter(c => c.name !== item.name);
    setRegionsExclusions([...updatedItem]);

    if (revoking) {
      props.setCompanyEventsFilters(
        props.companyFilters.replace(`&where[excludeRegion]=${item.name}`, `&where[region]=${item.name}`)
      );
      setRegionsFilters([
        ...regionsFilters,
        {
          name: item.name,
        },
      ]);
    } else {
      props.setCompanyEventsFilters(
        props.companyFilters.replace(`&where[excludeRegion]=${item.name}`, '')
      );
    }
  };

  const eventsFiltertabs = [
    {
      title: 'Event Format',
      list: getEventFormat(),
      filters: [...eventFormatFilters],
      excludedList: getEventFormatExclusionsList(),
      exclusions: [...eventFormatExclusions],
      removeItem: removeEventFormatItemHandler,
      removeExclusion: removeCompanyExclusionsHandler,
      onFilterValueChanged: () => {},
      disableExclusion: true
    },
    {
      title: 'Function',
      list: getSpecialtiesList(),
      filters: [...specialtiesFilters],
      excludedList: getSpecialtiesExclusionsList(),
      exclusions: [...specialtiesExclusions],
      removeItem: removeSpecialtiesItemHandler,
      removeExclusion: removeSpecialtiesExclusionsHandler,
      onFilterValueChanged: getSpeacialties,
      disableExclusion: true
    },
    {
      title: 'Event Industry',
      list: getIndustriesList(),
      filters: [...industriesFilters],
      excludedList: getIndustriesExclusionsList(),
      exclusions: [...industriesExclusions],
      removeItem: removeIndustriesItemHandler,
      removeExclusion: removeIndustriesExclusionsHandler,
      onFilterValueChanged: getIndustries,
      disableExclusion: true
    },
    {
      title: 'Event Size',
      list: getSizesList(),
      filters: [...sizesFilters],
      excludedList: getSizeExclusionsList(),
      exclusions: [...sizesExclusions],
      removeItem: removeSizesItemHandler,
      removeExclusion: removeSizesExclusionsHandler,
      onFilterValueChanged: getCompanySizes,
      disableExclusion: true
    },
    {
      title: 'Region',
      list: getRegionsList(),
      filters: [...regionsFilters],
      excludedList: getRegionExclusionsList(),
      exclusions: [...regionsExclusions],
      removeItem: removeRegionsItemHandler,
      removeExclusion: removeRegionsExclusionsHandler,
      onFilterValueChanged: filterRegions,
      disableExclusion: true
    }
  ];

  const selectedTabHandler = (title: string) => {
    if (title === selectedTab) {
      setSelectedTab('');
      return;
    }
    setSelectedTab(title);
  };

  const resetFilterHandler = () => {
    setEventFormatFilters('');
    setIndustriesFilters('');
    setSizesFilters('');
    setRegionsFilters('');
    setSpecialtiesFilters('');
    props.setCompanyEventsFilters('');
  };

  return (
    <div
      className={classNames('md:w-[286px] w-full create-post-card px-[20px] py-[14px] transition-all', {
        'h-[108px] overflow-hidden': isFilterShow,
      })}
    >
      <div className="flex items-center justify-between pb-[15px]">
        <span className="text-[#203C6E] text-[15px] font-medium leading-5">Filter your search</span>
        <div className="flex gap-3">
          <div
            className="text-[#285CB2] text-sm leading-5 hover:underline hover:cursor-pointer md:hidden block"
            onClick={() => setIsFilterShow(!isFilterShow)}
          >
            {isFilterShow ? 'See all filters' : 'Hide all filters'}
          </div>
          <span className="text-[#285CB2] text-sm leading-5 hover:underline hover:cursor-pointer" onClick={() => resetFilterHandler()}>
            Clear all
          </span>
        </div>
      </div>
      <div className="flex w-full pb-3 ">
        <div className="absolute pt-[13px] ml-[18px] ">
          <img className="w-4 h-4" src={SearchIcon} alt="" />
        </div>
        <div className="py-0.5 w-full" style={{ height: '39px' }}>
          <DebounceInput
            delay={400}
            initValue={props.searchText || ''}
            type="text"
            classNames="bg-white pl-[40px] pr-2 py-2 w-full h-[39px] text-sm rounded-2xl"
            onChangeText={(e: string) => props.setSearchText(e)}
            placeholder="Enter a keyword"
          />
        </div>
      </div>
      {eventsFiltertabs.map((item, index) => (
        <div
          key={index}
          className={`mb-0 mt-0 pt-2 pb-2 ${index !== eventsFiltertabs.length - 1 ? 'border-b-1 border-blue-100' : ''
          }`}
        >
          <div
            onClick={() => selectedTabHandler(item.title)}
            className="flex items-center justify-between cursor-pointer"
          >
            <span className="text-[14px] font-medium leading-5 text-[#285CB2]">{item.title}</span>
            <div>
              {selectedTab === item.title ? (
                <ExpandIcon className="text-sm text-blue-900" />
              ) : (
                <ExpandMoreIcon className="text-sm text-blue-900" />
              )}
            </div>
          </div>
          {item?.exclusions?.length > 0 && (
            <div className="flex flex-wrap gap-1 pb-3">
              {item?.exclusions?.length > 0 && (
                <span className="block text-[13px] text-[#848484] w-full pb-1">
                  Excluded:
                </span>
              )}
              {item?.exclusions.map((exclusion: any) => (
                <span className="bg-orange-6 text-white text-xs rounded-xl px-3 flex items-center justify-between">
                  {exclusion.name}
                  <AddOutlinedIcon
                    className="text-sm text-white cursor-pointer pl-1"
                    onClick={() => item.removeExclusion(exclusion, true)}
                  />
                  {/* <RemoveIcon
                    className="text-sm cursor-pointer pl-1"
                    onClick={() => item.removeExclusion(exclusion)}
                  /> */}
                  <div
                    className="text-sm cursor-pointer pl-[25px]"
                    onClick={() => item.removeExclusion(exclusion)}
                  >
                    <img src={crossIcons} alt="" />
                  </div>
                </span>
              ))}
            </div>
          )}
          {item?.filters.length > 0 && (
            <div className="flex flex-wrap gap-1 pb-3">
              {item?.filters.length > 0 && (
                <span className="block text-[13px] text-[#848484] w-full pb-1">Added:</span>
              )}
              {item?.filters.map((filter: any) => (
                <span className="bg-[#0049EF] text-white text-[13px] rounded-[14px] px-[16px] h-[23px] flex items-center justify-between">
                  <span className="line-clamp-1">{filter.name}</span>
                  { !item?.disableExclusion && (
                  <BlockOutlinedIcon
                    className="text-sm cursor-pointer pl-1"
                    onClick={() => item.removeItem(filter, true)}
                  />
                  )}
                  {/* <RemoveIcon
                    className="text-sm cursor-pointer pl-1"
                    onClick={() => item.removeItem(filter)}
                  /> */}
                  <div
                    className="text-sm cursor-pointer pl-[25px]"
                    onClick={() => item.removeItem(filter)}
                  >
                    <img src={crossIcons} alt="" />
                  </div>
                </span>
              ))}
            </div>
          )}
          {selectedTab === item.title && (
            <div className="">
              {(item.title !== 'Event Size' && item.title !== 'Event Format') && (
                <div className="pb-1 w-full">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="text-sm focus:outline-none bg-transparent"
                    onChange={(e: any) => item.onFilterValueChanged(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              )}
              <div className="border-t-1 border-blue-100 py-2">{item.list}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
