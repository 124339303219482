import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import closeIcon from '../../../../assets/images/nav-menu/close-button.svg';
import SearchIcon from '../../../../assets/images/event-landing-pre-event/Icon-feather-search.svg';
import { IEventAttendee } from '../../../../models/event/event-attendee';
import { Avatar } from '../../../../shared-components/V2';
import { IAppState } from '../../../../store';
import { getJobFunction } from '../../../../store/company/job-functions/actions';
import { getUnInvitedConnections, resetUnInvitedConnections } from '../../../../store/events/invitable-users/actions';
import { getJobTitles } from '../../../../store/job-titles/actions';
import { getEventRegions } from '../../../../store/regions/actions';
import { sendInvitations } from '../../../../store/events/invite-attendees/actions';
import { EVENT_INVITATION } from '../../../../constants';
import DropDownEventButton from './DropDownEventButton';
import { IDropDownMenuItems } from '../..';

interface IInviteConnections {
  eventId: string;
  onCloseModal?: () => void;
}

export const InviteConnections = (props: IInviteConnections) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUnInvitedConnections(props.eventId, ''));
    dispatch(getJobFunction(undefined, 1000));
    dispatch(getJobTitles(undefined, 1000));
    dispatch(getEventRegions(undefined, 1000));
    return () => {
      dispatch(resetUnInvitedConnections());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;
  const {
    invitableUsers, jobFunctions, jobTitles, regions
  } = useSelector((state: IAppState) => state);
  const { inviteAttendees } = useSelector((state: IAppState) => state);
  const [filterFunction, setFilterFunction] = useState<string[]>([]);
  const [filterJobTitle, setFilterJobTitle] = useState<string[]>([]);
  const [filterLoction, setFilterLocation] = useState<string[]>([]);
  const [connectionList, setConnectionList] = useState<IEventAttendee[]>();
  const [jobFunctionDropdown, setJobFunctionDropdown] = useState<IDropDownMenuItems[]>();
  const [jobTitlesDropdown, setJobTitlesDropdown] = useState<IDropDownMenuItems[]>();
  const [jobLocationDropdown, setJobLocationDropdown] = useState<IDropDownMenuItems[]>();
  const [toBeInvited, setToBeInvited] = useState<IEventAttendee[]>([]);

  useEffect(() => {
    dispatch(getUnInvitedConnections(
      props.eventId,
      '',
      filterJobTitle?.map((ob: any) => ob?.id),
      filterFunction?.map((ob: any) => ob?.id),
      filterLoction?.map((ob: any) => ob?.id)
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFunction, filterJobTitle, filterLoction]);

  useEffect(() => {
    setConnectionList(invitableUsers.value?.data);
  }, [invitableUsers]);

  useEffect(() => {
    setJobFunctionDropdown(jobFunctions.value?.data.map((job): IDropDownMenuItems => ({
      id: job.id!,
      name: job.name!,
      type: 'jobFunction'
    })));
    setJobTitlesDropdown(jobTitles.value?.data.map((job): IDropDownMenuItems => ({
      id: job.id!,
      name: job.name!,
      type: 'jobTitle'
    })));
    setJobLocationDropdown(regions.value?.data.map((job): IDropDownMenuItems => ({
      id: job.id!,
      name: job.name!,
      type: 'jobLocation'
    })));
  }, [jobFunctions, jobTitles, regions]);

  const filterAttendees = (searchString: string) => {
    const filteredAttendees = invitableUsers.value?.data?.filter((f: any) => (
      `${f.firstName} ${f.lastName}`.toLowerCase().includes(searchString.trim().toLowerCase())
    ));
    setConnectionList(filteredAttendees);
  };

  const onInviteUser = (user: IEventAttendee, isInvited: boolean) => {
    if (isInvited) {
      setToBeInvited([...toBeInvited, user]);
    } else {
      const index = toBeInvited?.find(o => o.id === user.id);
      if (index) {
        setToBeInvited([...toBeInvited].filter(obj => obj.id !== user.id));
      }
    }
  };

  const handleInviteClick = () => {
    toBeInvited.forEach(attendee => dispatch(sendInvitations(props.eventId, attendee.id)));

    if (inviteAttendees.errorMessage! !== '') {
      toast.error(inviteAttendees.errorMessage);
    } else {
      toast.success(EVENT_INVITATION.INVITATION_SENT_SUCCESSFULLY);
      dispatch(getUnInvitedConnections(props.eventId, ''));
    }
    props.onCloseModal!();
  };

  return (
    <>
      <div className="w-full">
        <div className="text-[#0B1221] font-medium text-[18px] leading-[26px] border-b-1 border-[#CDDCF2] pb-[9px] mx-[21px] mb-[18px]">Invite your connections to this event</div>
      </div>
      <div className="flex flex-col">
        <div className="px-[20px]">
          <div className="flex sm:flex-row flex-col gap-[10px]">
            <div className="relative">
              <div className="absolute left-[12px] top-0 z-20 py-2">
                <img className="w-[14px] h-[14px]" src={SearchIcon} alt="" />
              </div>
              <div
                className="bg-gray-1 rounded-20 sm:w-[170px] w-full z-10 h-[30px]"
                style={{ height: '' }}
              >
                <input
                  type="text"
                  className="rounded-[10px] text-sm5 font-extralight pl-[35px] pr-2 py-1 sm:w-[170px] w-full bg-gray-1 h-[30px]"
                  placeholder="Search"
                  onChange={e => filterAttendees(e.currentTarget.value)}
                  autoComplete="off"
                />
              </div>
            </div>
            <div>
              <DropDownEventButton
                menuItems={jobFunctionDropdown!}
                className="h-[280px]"
                title="Function"
                iconUrl="/images/Polygon-dropdown.svg"
                type="checkbox"
                btnTextnClass="!text-[12px] !font-light !m-0"
                buttonClass="!px-[12px] sm:w-[114px] w-full"
                dropdownClass="shadow-md rounded-10 p-4 pb-0"
                showResultsClick={(_status: string, selection: []) => setFilterFunction(selection)}
              />
            </div>
            <div>
              <DropDownEventButton
                menuItems={jobTitlesDropdown!}
                className="h-[280px]"
                title="Job Title"
                iconUrl="/images/Polygon-dropdown.svg"
                type="checkbox"
                IsReset={false}
                btnTextnClass="!text-[12px] !font-light !m-0"
                buttonClass="!px-[12px] sm:w-[115px] w-full"
                dropdownClass="shadow-md rounded-10 p-4 pb-0"
                showResultsClick={(_status: string, selection: []) => setFilterJobTitle(selection)}
              />
            </div>
            <div>
              <DropDownEventButton
                menuItems={jobLocationDropdown!}
                className="h-[280px]"
                title="Location"
                iconUrl="/images/Polygon-dropdown.svg"
                type="checkbox"
                btnTextnClass="!text-[12px] !font-light !m-0"
                buttonClass="!px-[12px] sm:w-[124px] w-full"
                IsReset={false}
                dropdownClass="shadow-md rounded-10 p-4 pb-0"
                showResultsClick={(_status: string, selection: []) => setFilterLocation(selection)}
              />
            </div>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col border-1 border-gray-9 mt-4 h-466 mx-[20px] rounded-10 sm:overflow-hidden overflow-y-scroll">
          <div className="sm:h-466 sm:w-303 w-full sm:overflow-auto no-scrollbar sm:border-r-1 border-gray-1">
            {
              connectionList?.map(user => (
                <div
                  key={user.id}
                  className="text-sm2 border-b-1 border-gray-1 px-2 py-2"
                >
                  <div className="flex flex-row relative">
                    <div className="bg-white rounded w-4 h-4 mt-4 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-0">
                      <input
                        type="checkbox"
                        className={classNames('absolute verification-checkbox accent-[#203c6e]', {
                          'cursor-pointer': !user.invited
                        })}
                        checked={
                          !!(user.invited === 1
                            || toBeInvited?.find(o => o.id === user.id))
                        }
                        disabled={!!user.invited}
                        onClick={e => onInviteUser(user, e.currentTarget.checked)}
                      />
                    </div>
                    <div className="flex flex-row">
                      <Avatar
                        size="MEDIUM"
                        src={`${imageUrlPrefix}/${user.avatar}`}
                      />
                      <div className="flex flex-col ml-2 w-full">
                        <div className="text-dark-blue text-sm4 font-semibold line-clamp-1 leading-[19px]">
                          {user.firstName}
                          {' '}
                          {user.lastName}
                        </div>
                        <div className="text-gray-600 text-x-sm line-clamp-2 leading-[14px]">
                          {user.jobTitle}
                          {' '}
                          at
                          {' '}
                          {user.company?.name}
                        </div>
                        <div className="text-gray-600 text-x-sm leading-tight line-clamp-1">{(user as any).company?.location}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="flex flex-col h-300 sm:w-315 w-full">
            <div className="flex justify-between row w-62 py-2 px-4 mb-2 border-b border-gray-1 text-sm1 font-extralight text-gray-10 sm:border-t-0 border-t-1 border-gray-1">
              <div className="">
                <span className="text-center inline-block pr-2">{toBeInvited.length}</span>
                <span>selected</span>
              </div>
              <div
                className="pl-10 cursor-pointer"
                onClick={() => {
                  setToBeInvited([]);
                }}
              >
                Unselect all
              </div>
            </div>
            {toBeInvited.map(attendee => (
              <div
                className="flex flex-row justify-between text-blue-3 text-sm4 font-semibold rounded-lg px-4 py-1"
                key={`invite-attendee-list-${attendee.id}`}
              >
                <div className="flex items-center">
                  <Avatar className="border-blue-1 border" size="XSMALL" src={`${imageUrlPrefix}/${attendee.avatar}`} />
                  <div className="m-auto ml-[6px] font-medium">
                    {attendee.firstName}
                    {' '}
                    {attendee.lastName}
                  </div>
                </div>
                {/*  eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img src={closeIcon} className="w-5 h-5 my-auto" onClick={() => onInviteUser(attendee, false)} alt="delete" />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-row mt-3 mx-auto float-right mr-[20px]">
          <div className="mr-2">
            <button
              type="button"
              className="bg-gray-1 hover:bg-blue hover:text-white rounded-[5px] text-blue-3 text-sm font-semibold px-4 py-1 disabled:bg-gray-1 disabled:text-white"
              onClick={props.onCloseModal}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="button"
              className="bg-[#0049EF] hover:bg-blue-3 rounded-[5px] text-white text-sm font-semibold px-4 py-1 disabled:bg-gray-1 disabled:text-white"
              onClick={() => handleInviteClick()}
            >
              Invite
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
