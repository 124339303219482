/* eslint-disable max-len */
import { useState } from 'react';

interface IEventsSvgIcon {
  isActive?: boolean;
  className?: string;
  disableHover?: boolean;
}
export const EventsSvgIcon = ({ isActive, className, disableHover }: IEventsSvgIcon) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const colorWhite = '#ffffff';
  const defaultColor = '#7283A4';
  const fillColor = !disableHover && (isHover || isActive) ? colorWhite : defaultColor;

  return (

    <svg
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="23.103"
      height="27.5"
      viewBox="0 0 23.103 27.5"
    >
      <g id="Group_21106" data-name="Group 21106" transform="translate(0 0.5)">
        <g id="Group_18805" data-name="Group 18805" transform="translate(0 0)">
          <path id="Path_10373" data-name="Path 10373" d="M128.365,210.763h-19.68a1.069,1.069,0,0,1-1.066-.967l-.64-6.214a1.064,1.064,0,0,1,1.066-1.182h20.96a1.075,1.075,0,0,1,1.066,1.182l-.64,6.214A1.069,1.069,0,0,1,128.365,210.763Z" transform="translate(-106.973 -183.763)" fill={fillColor} />
          <rect id="Rectangle_7907" data-name="Rectangle 7907" width="11.552" height="12.707" rx="1" transform="translate(0 1.155)" fill={fillColor} />
          <circle id="Ellipse_1160" data-name="Ellipse 1160" cx="7.509" cy="7.509" r="7.509" transform="translate(6.353)" fill={fillColor} stroke="#edf2f5" strokeWidth="1" />
        </g>
        <path id="Path_14614" data-name="Path 14614" d="M2.253,5.778a.7.7,0,0,0,.569-.3,6.479,6.479,0,0,1,10.551,0,.705.705,0,0,0,.573.306h1.539a.712.712,0,0,0,.62-1.055A9.149,9.149,0,0,0,.088,4.733.709.709,0,0,0,.712,5.778Z" transform="translate(5.776 15.017)" fill={fillColor} stroke="#edf2f5" strokeWidth="0.7" />
        <path id="Path_14615" data-name="Path 14615" d="M10.759,4.592C8.035.787,3.416.532.445,4.023Q.212,4.295,0,4.592Z" transform="translate(8.503 16.556)" fill={fillColor} />
      </g>
    </svg>

  );
};
