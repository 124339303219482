import { Reducer } from "redux";

import { ICompanySpecialitiesState } from "../../../models/company/CompanySpecialitiesState";
import { CompanySpecialitiesActions } from "./actions";
import {
  CompanyInterestsActionTypes,
  CompanySpecialitiesActionTypes,
} from "./types";

const initialState: ICompanySpecialitiesState = {
  value: null,
  loading: false,
  errorMessage: "",
  interestValue: null,
  interestLoading: false,
  interestErrorMessage: "",
};

export const CompanySpecialitiesReducer: Reducer<
  ICompanySpecialitiesState,
  CompanySpecialitiesActions
> = (state = initialState, action) => {
  switch (action.type) {
    case CompanySpecialitiesActionTypes.COMPANY_SPECIALITIES_LOADING: {
      return {
        ...state,
        loading: state.loading,
      };
    }
    case CompanySpecialitiesActionTypes.COMPANY_SPECIALITIES_SUCCESS: {
      return {
        ...state,
        value: action.data,
      };
    }

    case CompanySpecialitiesActionTypes.COMPANY_SPECIALITIES_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null,
      };
    }

    case CompanyInterestsActionTypes.COMPANY_INTERESTS_LOADING: {
      return {
        ...state,
        interestLoading: state.loading,
      };
    }
    case CompanyInterestsActionTypes.COMPANY_INTERESTS_SUCCESS: {
      return {
        ...state,
        interestValue: action.data,
      };
    }

    case CompanyInterestsActionTypes.COMPANY_INTERESTS_ERROR: {
      return {
        ...state,
        interestErrorMessage: action.errorMessage,
        interestValue: null,
      };
    }

    default:
      return state;
  }
};
