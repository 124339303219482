import SecondLayout from '../../../layouts/second-layout';
import AccountSettings from './account-settings';
import BlockedProfile from './blocked-profiles';
import NotificationSettings from './notification-settings';
import PeymantDetails from './payment-details';
import PrivacySettings from './privacy-settings';
import TransactionPurchaseHistory from './transaction-purchase-history';
import UserAccountSidebar from './user-account-sidebar';

export const UserAccountsSettings = () => (
  <div>
    <SecondLayout
      leftPanel={<UserAccountSidebar />}
    >
      <>
        <AccountSettings />
        <NotificationSettings />
        <BlockedProfile />
        <PrivacySettings />
        <PeymantDetails />
        <TransactionPurchaseHistory />
      </>
    </SecondLayout>
  </div>
);
