/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */
import classNames from 'classnames';

interface IWebsiteEventTabs {
  onClick: Function,
  tabName?: string;
  date?: string;
  selectedTab: any;
  isSelectTab: boolean;
  backgroundColor? : string;
  isLobbyDayTabs?: string;
}

const WebsiteEventTabs = ({
  onClick, tabName, date, selectedTab, isSelectTab, isLobbyDayTabs
}: IWebsiteEventTabs) => (
  <div key={tabName} className='flex justify-center flex-col mb-2.5'>
    <div
      className={classNames('h-[32px] min-w-[170px] text-[16px] font-[500] leading-[32px] flex items-center justify-center mb-2.5 bold-lato-font', {
        ' text-[#ffffff]': isSelectTab,
        'text-[#72848e]': !isSelectTab,
      })}
    >
      {date}
    </div>
    <div
      className={classNames('h-[30px] rounded-[15px] text-[16px] font-[500] leading-[17px] px-[15px] flex items-center justify-center cursor-pointer w-full bold-lato-font', {
        [`${isLobbyDayTabs}`]: isLobbyDayTabs,
        ' bg-white text-[#20313d]': isSelectTab,
        ' bg-transparent text-white border border-[rgba(255, 255, 255, 0.38)]': !isSelectTab,
      })}
      onClick={() => onClick(selectedTab)}
    >
      
      {tabName}
    </div>
  </div>
);

export default WebsiteEventTabs;
