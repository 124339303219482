export const UserArrowIcon = ( ) => (<svg xmlns="http://www.w3.org/2000/svg" width="24.907" height="25.327" viewBox="0 0 24.907 25.327">
<g id="Group_21039" data-name="Group 21039" transform="translate(-507.813 -1056.586)">
  <circle id="Ellipse_1301" data-name="Ellipse 1301" cx="7.886" cy="7.886" r="7.886" transform="translate(508.313 1057.086)" fill="#7283a4" stroke="#edf2f5" stroke-width="1"/>
  <path id="Path_27174" data-name="Path 27174" d="M1.755,4.5A.547.547,0,0,0,2.2,4.263a5.046,5.046,0,0,1,8.218,0,.549.549,0,0,0,.446.238h1.2a.555.555,0,0,0,.483-.821A7.126,7.126,0,0,0,.068,3.686.552.552,0,0,0,.554,4.5Z" transform="translate(509.892 1073.582)" fill="#7283a4"/>
  <path id="Union_16" data-name="Union 16" d="M2.565,16.53a1,1,0,0,1-1-1V7.03H.464a.5.5,0,0,1-.389-.814L4.96.194a.5.5,0,0,1,.777,0L10.62,6.215a.5.5,0,0,1-.388.814h-1.1v8.5a1,1,0,0,1-1,1Z" transform="translate(532.729 1071.18) rotate(90)" fill="#7283a4"/>
</g>
</svg>

);

