/* eslint-disable max-len */
import { Stack, Typography } from '@mui/material';
import React from 'react';
import './company-edit-page.css';
import PInterestSelect from 'src/mui/components/hook-form/PInterestSelect';
import { PMultiCheckbox, RHFTextField } from 'src/mui/components/hook-form';
import companyInfoIcon from '../../../assets/images/company/info-icon.svg';
import { COMPANY_QUESTIONS } from './company-enum';

interface Props {
values : any;
}

export const Capability = ({
  values,
}: Props) => {
  const {
    companySpecialities,
    secondaryCapabilities
  } = values;

  const renderCoreCapabilities = (title:string, name:string) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
        </Typography>
        <PInterestSelect
          name={name}
          className="dropdownRoot"
          multiple
          clearOnBlur
          noOptionsText="Please try another capability"
          selectedData={companySpecialities}
        />
      </Stack>
    </Stack>
  );

  const renderSecondaryCapabilities = (title:string, name:string) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
        </Typography>
        <PInterestSelect
          name={name}
          className="dropdownRoot"
          multiple
          clearOnBlur
          noOptionsText="Please try another capability"
          selectedData={secondaryCapabilities}
        />
      </Stack>
    </Stack>
  );

  const renderCapabilityOption = (question:any) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {question?.question}
        </Typography>
        <div className="w-full mt-[0px] checkbox-container">
          <PMultiCheckbox
            name={question?.fieldName}
            options={question?.options}
          />
        </div>
        <div className="flex items-start flex-col w-full">
          <div className="w-full flex items-center other-text-field-container">
            <div className="text-[13px] text-[#172746] font-normal leading-[17px] mr-[13px]">
              Other
            </div>
            <RHFTextField name={`${question?.fieldName}_other`} />
          </div>
        </div>
      </Stack>
    </Stack>
  );

  return (
    <Stack rowGap="15px">
      { renderCoreCapabilities('Core capabilities', 'companySpecialities')}
      { renderSecondaryCapabilities('Secondary capabilities', 'secondaryCapabilities')}

      {COMPANY_QUESTIONS?.map((question:any) => (
        renderCapabilityOption(question)
      )) }
    </Stack>
  );
};

export default Capability;
