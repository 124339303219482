import { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import classNames from "classnames";

interface ICheckoutForm {
    successCallback: Function
}

export const CheckoutForm = ({ successCallback }: ICheckoutForm) => {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [paymentInfoCompleted, setPaymentInfoCompleted] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }: any) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        await stripe.confirmPayment({
            elements,
            confirmParams: undefined!,
            redirect: 'if_required'
            // confirmParams: {
            //     // Make sure to change this to your payment completion page
            //     return_url: "/",
            // },
        })
            .then(() => successCallback())
            .catch(({ error }) => {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Otherwise, your customer will be redirected to
                // your `return_url`. For some payment methods like iDEAL, your customer will
                // be redirected to an intermediate site first to authorize the payment, then
                // redirected to the `return_url`.
                if (error?.type === "card_error" || error?.type === "validation_error") {
                    setMessage(error.message);
                } else if (error) {
                    setMessage("An unexpected error occurred.");
                }
            });

        setIsLoading(false);
    };

    const paymentElementOptions: any = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit} className="w-full flex flex-col justify-center items-center">
            <PaymentElement onChange={({ complete }) => setPaymentInfoCompleted(complete)} id="payment-element" options={paymentElementOptions} className="w-[450px]" />
            <button
                disabled={isLoading || !stripe || !elements || !paymentInfoCompleted}
                id="submit"
                className={classNames("w-[120px] rounded-xl p-2 mt-4", {
                    "bg-blue-1": paymentInfoCompleted,
                    "bg-gray-400": !paymentInfoCompleted
                })}
            >
                <span id="button-text">
                    {isLoading ? <div>Processing...</div> : "Pay now"}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}