//
import { CardMedia } from '@mui/material';
// import { getMinifiedImage, getS3Path } from 'src/utils/image';
import Image from '../image';
import { CustomFile } from './types';
import { getMinifiedImage, getS3Path } from 'src/mui/utils/image';
//
// import { CustomFile } from '../types';

// ----------------------------------------------------------------------

type Props = {
  file: CustomFile | string | null;
};

export default function SingleFilePreviewNew({ file }: Props) {
  if (!file) {
    return null;
  }
  const renderImage = (imgUrl?: string) => (
    <Image
      alt="file preview"
      src={imgUrl}
      sx={{
        top: 0,
        left: 0,
        zIndex: 8,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100%)',
        height: 'calc(100%)',
      }}
    />
  );
  const renderVideo = (imgUrl?: string) => (
    <CardMedia
      component="video"
      src={imgUrl}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 8,
        borderRadius: 1,
        width: 'calc(100%)',
        height: 'calc(100%)',
      }}
    />
  );

  const renderDocument = (imgUrl?: string) => (
    <CardMedia
      component="iframe"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 8,
        borderRadius: 1,
        width: 'calc(100%)',
        height: 'calc(100%)',
      }}
      src={imgUrl}
    />
  );
  if (typeof file === 'string') {
    const extension = file?.split('.').pop();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
        return renderImage(getMinifiedImage(file));
      case 'mp4':
        return renderVideo(getS3Path(file));
      case 'pdf':
        return renderDocument(getS3Path(file));
      case 'docx':
      case 'doc':
        return renderDocument(
          `https://view.officeapps.live.com/op/embed.aspx?src=${getS3Path(
            file,
          )}&width=190px&height=240px`,
        );
      default:
        return renderImage(getMinifiedImage(file));
    }
  } else {
    switch (file?.type) {
      case 'image/jpeg':
        return renderImage(file.preview);
      case 'video/mp4':
        return renderVideo(file.preview);
      case 'application/pdf':
        return renderDocument(file.preview);
      default:
        return renderImage(file.preview);
    }
  }
}
