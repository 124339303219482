import { useState } from "react";

export const MessageSvgIcon = () => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const colorBlue = "#0049EF";
  const defaultColor = "#7283A4";
  const fillColor = isHover ? colorBlue : defaultColor;

  return (
    <svg
      className="cursor-pointer"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <g
        id="Group_17623"
        data-name="Group 17623"
        transform="translate(-1038.229 -8)"
      >
        <rect
          id="Rectangle_7694"
          data-name="Rectangle 7694"
          width="40"
          height="40"
          rx="15"
          transform="translate(1038.229 8)"
          fill="#e9eff2"
        />
        <g
          id="Group_19821"
          data-name="Group 19821"
          transform="translate(0 0.5)"
        >
          <path
            id="形状"
            d="M22.044,10A11.951,11.951,0,0,1,34.088,21.886a11.8,11.8,0,0,1-4.537,9.3,12.584,12.584,0,0,0-.016,1.821,1.95,1.95,0,0,1-1.8,2.071,5.471,5.471,0,0,1-3.7-1.47,12.312,12.312,0,0,1-1.991.161,11.887,11.887,0,1,1,0-23.772Z"
            transform="translate(1036.186 4.96)"
            fill={fillColor}
            fillRule="evenodd"
          />
          <circle
            id="Ellipse_1289"
            data-name="Ellipse 1289"
            cx="2"
            cy="2"
            r="2"
            transform="translate(1050.186 24.96)"
            fill="#ffffff"
          />
          <circle
            id="Ellipse_1290"
            data-name="Ellipse 1290"
            cx="2"
            cy="2"
            r="2"
            transform="translate(1056.186 24.96)"
            fill="#ffffff"
          />
          <circle
            id="Ellipse_1291"
            data-name="Ellipse 1291"
            cx="2"
            cy="2"
            r="2"
            transform="translate(1062.186 24.96)"
            fill="#ffffff"
          />
        </g>
      </g>
    </svg>
  );
};
