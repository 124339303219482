import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import closeIcon from "../../assets/images/nav-menu/close-icon.svg";
import phoneIcon from "../../assets/images/nav-menu/phone-icon.svg";
import videoIcon from "../../assets/images/nav-menu/video-icon.svg";
import { Avatar } from "../../shared-components/V2";
import { IAppState } from "../../store";
import { callInvite } from "../../store/1-1-video-call/actions";
import { useWebSocket } from "../../utils/hooks";
import { VideoCallSvgIcon } from "../../shared-components/V2/IconSvg";
import { PhoneCallSvgIcon } from "../../shared-components/V2/IconSvg/PhoneCallSvgIcon/PhoneCallSvgIcon";
import { ChatBoxCloseSvgIcon } from "../../shared-components/V2/IconSvg/ChatBoxCloseSvgIcon/ChatBoxCloseSvgIcon";
import deleteIcon from "../../assets/images/trash.svg";
import { handleDeletePrivateMessage } from "src/store/privateChat";
import AlertModal from "../alert-modal/alert-modal";
import { DeletePost } from "../posts/delete-post";
import { DeleteConversation } from "./delete-conversation-modal";
import { closeChatHead } from "src/store/chat-heads";
import { DeleteChatSVGIcon } from "src/shared-components/V2/IconSvg/DeleteChatSvgIcon/DeleteChatSVGIcon";
import DeleteIcon from '@mui/icons-material/Delete';

const MessageWindowTitle = (props: {
  userId: string;
  avatar: string;
  username: string;
  conversationId: string;
  ignoreImageUrlPrefix?: boolean;
  onCloseHandler: () => void;
  onMessageBoxTitleClick?: () => void;
}) => {
  const dispatch = useDispatch();
  const { privateChat } = useSelector((state: IAppState) => state);
  const userStatus = privateChat.checkUserOnline?.checkedResult;
  const [toggleDeleteConfirmation, setToggleDeleteConfirmation] =
    useState<boolean>(false);
  const isActive = userStatus?.some(
    (user) => user.active === true && user.userId === props.userId
  );

  const { webSocket } = useWebSocket();

  // useEffect(() => {
  //   webSocket.then((ws) =>
  //     ws.send(
  //       JSON.stringify({
  //         action: 'check-user-online',
  //         data: {
  //           userIds: props.userId,
  //         },
  //       })
  //     )
  //   );
  // }, [props.userId, webSocket]);

  const establishOneToOneCall = (type: "audio" | "video") => {
    webSocket.then((ws) =>
      ws.send(
        JSON.stringify({
          action: "request-to-call",
          data: {
            receiverId: props.userId,
            type: type,
          },
        })
      )
    );
  };

  const handleDeleteConversation = () => {
    webSocket.then(
      (ws) =>
        ws.send(
          JSON.stringify({
            action: "remove-conversation",
            data: {
              conversationId: props?.conversationId,
            },
          })
        ),
      dispatch(props.onCloseHandler),
    );
    closeChatHeadesNotRendered(props?.conversationId)
  };

  const closeChatHeadesNotRendered = (conversationID: string) => {
    dispatch(closeChatHead(conversationID));
  }

  const onCloseModal = () => {
    toggleDeleteConfirmation && setToggleDeleteConfirmation(false);
  };

  return (
    <div className="bg-white rounded-t-xl">
      <div className="flex flex-row pl-3.5 pr-1 py-2 h-[48px] rounded-t-xl shadow-xs9">
        <div onClick={props.onMessageBoxTitleClick} className="flex flex-row">
          <div className="flex flex-row h-[25px]">
            <Avatar
              src={`${props.avatar}`}
              status={isActive ? "ONLINE" : "OFFLINE"}
              size="XSMALL"
            />
          </div>
          <div className="profile-status ml-2 text-blue-3 flex flex-col justify-center">
            <div className="text-sm5 font-semibold line-clamp-1 leading-[15px]">
              {props.username}
            </div>
            {isActive && (
              <div className="text-2x-sm font-medium profile-status mt-[2px] leading-[12px]">
                Active Now
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-row ml-auto">
          <div className="cursor-pointer mr-[1px]">
            <VideoCallSvgIcon
              fillColor="GRAY"
              onClick={() => {
                // TODO: We don't have access to full user information.
                // fetch the full information if required.
                // ATM the information is not required.
                dispatch(
                  callInvite(props.userId, {
                    id: props.userId,
                    firstName: props.username,
                    avatar: props.avatar,
                    cover: "",
                    email: "",
                    jobTitle: "",
                    lastName: "",
                  })
                );
                establishOneToOneCall("video");
              }}
            />
          </div>
          <div className="cursor-pointer mr-[1px]">
            <PhoneCallSvgIcon
              fillColor="GRAY"
              onClick={() => {
                // TODO: We don't have access to full user information.
                // fetch the full information if required.
                // ATM the information is not required.
                dispatch(
                  callInvite(props.userId, {
                    id: props.userId,
                    firstName: props.username,
                    avatar: props.avatar,
                    cover: "",
                    email: "",
                    jobTitle: "",
                    lastName: "",
                  })
                );
                establishOneToOneCall("audio");
              }}
            />
          </div>
          <div className="cursor-pointer mr-[1px]">
            <DeleteChatSVGIcon
              fillColor="GRAY"
              onClick={() => {
                setToggleDeleteConfirmation(true)
              }}
              />
            <AlertModal
              isCloseIcon
              closeIconClass="right-[7px] top-[14px]"
              isOpen={toggleDeleteConfirmation}
              onClose={onCloseModal}
              className="w-[374px] bg-[#EDF2F5]"
            >
              <DeleteConversation
                message={"Are you sure you want to delete this conversation?"}
                onDelete={async () => {
                  handleDeleteConversation();
                  onCloseModal();
                }}
                onClose={onCloseModal}
              />
            </AlertModal>
          </div>
          <div className="cursor-pointer mr-[1px]">
            <ChatBoxCloseSvgIcon
              fillColor="GRAY"
              onClick={() => dispatch(props.onCloseHandler)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageWindowTitle;
