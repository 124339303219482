import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IContentResult } from '../../models/library-content/ContentResult';
import { IContentState } from '../../models/library-content/ContentState';
import { ContentService } from '../../services/library-content';
import { ContentActionTypes } from './types';

export interface IContentLoadingAction {
  type: ContentActionTypes.CONTENT_LOADING;
  loading: boolean;
}

export interface IContentSuccessAction {
  type: ContentActionTypes.CONTENT_SUCCESS;
  payload: IContentResult;
}

export interface IContentErrorAction {
  type: ContentActionTypes.CONTENT_ERROR;
  errorMessage: string;
}

export type ContentActions = IContentLoadingAction | IContentSuccessAction | IContentErrorAction;

export const getContent: ActionCreator<
  ThunkAction<Promise<any>, IContentState, null, IContentSuccessAction>
> = (queryParams: {}) => {
  return async (dispatch: Dispatch) => {
    const contentService: ContentService = new ContentService();

    try {
      const result = await contentService.getContent(queryParams);

      dispatch({
        type: ContentActionTypes.CONTENT_SUCCESS,
        payload: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: ContentActionTypes.CONTENT_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: ContentActionTypes.CONTENT_LOADING,
        loading: false
      });
    }
  };
};

export const loadContent: ActionCreator<
  ThunkAction<any, IContentState, null, IContentLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: ContentActionTypes.CONTENT_LOADING,
    loading: shouldLoad
  });
