import AllProducts from '../../company-profile/Products/AllProducts';

interface Props {
    company: any;
}

export const CompanyProductTab = ({ company }: Props) => {
  const allProdcuts = company?.products || [];
  return (
    <div className="relative mb-4 w-full bg-white create-post-card p-[33px]">

      <AllProducts companyProducts={allProdcuts} companyId={company?.id} hideAddOrEditIcon />
    </div>

  );
};
