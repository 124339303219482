/* eslint-disable react/no-unused-prop-types */
import { NoEvents } from '../events/event-tab/components/no-events';
import { ProductItem } from '../company/company-as-visitor-new/product-item';

type IProductListCardProps = {
  productList: any[];
  companySlug: string;
};

export const ProductListCard = (props: IProductListCardProps) => (
  props.productList?.length > 0 ? (
    <div className="grid grid-cols-3 gap-x-[20px] gap-y-[10px] pt-[10px] px-[30px] pb-[6px] h-[385px] min-h-[385px] max-h-[385px] overflow-hidden">
      {props?.productList.map((product, index) => (
        <div className="" key={`product-${index}`}>
          <ProductItem item={product} companySlug={props?.companySlug} />
        </div>
      ))}
    </div>
  ) : <NoEvents />
);

export default ProductListCard;
