import documentIcon from '../../../assets/images/document-icon.svg';
import { Avatar } from '../../../shared-components/V2';
import { ParseContent } from '../../../utils/parse-html';

type ChatMessageProps = {
  sender: string;
  message: string;
  // eslint-disable-next-line react/no-unused-prop-types
  senderId: string;
  avatar?: string;
  mine: boolean;
  type: string;
};

const ChatMessage = (props: ChatMessageProps) => (
  <div
    className={`${
      props.mine ? 'self-end' : 'self-start'
    } text-xs flex flex-row items-center rounded-lg gap-2`}
  >
    {!props.mine && (
      <Avatar
        size="SMALL"
        alt="bubble-avatar"
        src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${props.avatar}`}
      />
    )}
    {props.type === 'text' && (
      <div
        className={`${
          props.mine ? 'bg-blue text-gray-200' : 'bg-gray-200 text-blue-2'
        } my-2 py-2 px-6 text-xs flex rounded-lg`}
      >
        <div className="flex flex-col">
          {!props.mine && <p>{props.sender}</p>}
          <ParseContent content={props.message} />
        </div>
      </div>
    )}
    {props.type === 'document' && (
      <a href={props.message}>
        <div className="flex flex-col items-center">
          <div>
            <img src={documentIcon} alt="attachment" />
          </div>
          <div>{props.message.substring(props.message.lastIndexOf('/') + 1)}</div>
        </div>
      </a>
    )}
    {props.type === 'image' && <img src={props.message} alt="" />}
  </div>
);

export default ChatMessage;
