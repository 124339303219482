import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { LoadingScreen } from 'src/mui/components/loading-screen';
interface ICountDownTimer {
  screenMode: 'small' | 'large' | 'hidden' | undefined;
  startTime: any;
}
export const CountDownTimer = ({ screenMode, startTime }: ICountDownTimer) => {
  const calculateTimeLeft = () => {
    let difference = +new Date(startTime) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());
  const isTimeLeft = (timeLeft?.days > 0 || timeLeft?.hours > 0 || timeLeft?.minutes > 0 || timeLeft?.seconds > 0)
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <>
      <Stack
        position='relative'
        width={1}
        height={1}
        sx={{
          backgroundColor: 'black',
        }}
      >
        {isTimeLeft ? (
          <Stack
            position='relative'
            width={1}
            height='calc(100vh - 70px)'
            justifyContent='center'
          >
            <Stack sx={{ color: 'white', fontSize: '22px', marginBottom: '3rem' }}>Going live in:</Stack>
            <Stack
              position='relative'
              width={1}
              justifyContent='center'
              sx={{
                flexDirection: {
                  xs: "column",
                  md: "row",
                }
              }}
            >
              <Stack
                position='relative'
                justifyContent='center'
                color='white'
                sx={{
                  marginX: {
                    md: "32px",
                  }
                }}
              >
                <Stack
                  sx={{
                    fontSize : {
                      xs: '35px',
                      md: '80px'
                    },
                    marginBottom: {
                      xs: '5px',
                      md: '1.5rem'
                    }
                  }}
                >
                    {timeLeft.days}
                </Stack>
                <Stack
                  sx={{
                    fontSize : {
                      xs: '20px',
                      md: '22px'
                    },
                    marginBottom: {
                      xs: '5px',
                      md: '.5rem'
                    }
                  }}
                >
                  Day{timeLeft.days > 1 ? 's' : ''}
                </Stack>
              </Stack>
              <Stack
                position='relative'
                justifyContent='center'
                color='white'
                sx={{
                  marginX: {
                    md: "32px",
                  }
                }}
              >
                <Stack
                  sx={{
                    fontSize : {
                      xs: '35px',
                      md: '80px'
                    },
                    marginBottom: {
                      xs: '5px',
                      md: '1.5rem'
                    }
                  }}
                >{timeLeft.hours}</Stack>
                <Stack
                  sx={{
                    fontSize : {
                      xs: '20px',
                      md: '22px'
                    },
                    marginBottom: {
                      xs: '5px',
                      md: '.5rem'
                    }
                  }}
                >Hour{timeLeft.hours > 1 ? 's' : ''}</Stack>
              </Stack>
              <Stack
                position='relative'
                justifyContent='center'
                color='white'
                sx={{
                  marginX: {
                    md: "32px",
                  }
                }}
              >
                <Stack
                  sx={{
                    fontSize : {
                      xs: '35px',
                      md: '80px'
                    },
                    marginBottom: {
                      xs: '5px',
                      md: '1.5rem'
                    }
                  }}
                >{timeLeft.minutes}</Stack>
                <Stack
                  sx={{
                    fontSize : {
                      xs: '20px',
                      md: '22px'
                    },
                    marginBottom: {
                      xs: '5px',
                      md: '.5rem'
                    }
                  }}
                >Minute{timeLeft.minutes > 1 ? 's' : ''}</Stack>
              </Stack>
              <Stack
                position='relative'
                justifyContent='center'
                color='white'
                sx={{
                  marginX: {
                    md: "32px",
                  }
                }}
              >
                <Stack
                  sx={{
                    fontSize : {
                      xs: '35px',
                      md: '80px'
                    },
                    marginBottom: {
                      xs: '5px',
                      md: '1.5rem'
                    }
                  }}
                >{timeLeft.seconds}</Stack>
                <Stack
                  sx={{
                    fontSize : {
                      xs: '20px',
                      md: '22px'
                    },
                    marginBottom: {
                      xs: '5px',
                      md: '.5rem'
                    }
                  }}
                >Seconds</Stack>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack
            position='relative'
            width={1}
            height={1}
            sx={{
              backgroundColor: 'black',
            }}
          >
            <LoadingScreen />
          </Stack>
        )}
        
      </Stack>
    </>
  );
};
