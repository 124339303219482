import { Divider, Stack } from '@mui/material';
import {
  format,
  parseISO,
} from 'date-fns';
import Typography from '@mui/material/Typography';
import { IEvent } from 'src/models/user-events';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// components
import Image from 'src/mui/components/image';
import SecondaryBanner from "./Images/SecondaryBanner.jpg"

type OverviewPageProps = {
  event?: IEvent;
}

const iconStyle = { width: '37px', height: '37px', color: '#ffffff', mr: '25px', '@media (max-width: 768px)': {mr: '15px'} }

export const OverviewPage = ({ event }: OverviewPageProps) => {
  const startTime = event?.startTime;
  const endTime = event?.endTime;

  if (!startTime) {
    return null;
  }
  if (!endTime) {
    return null;
  }

  const formattedTime = parseISO(startTime);
  const formattedEndTime = parseISO(endTime);

  const getFormattedTime = format(formattedTime, 'hh:mm a');
  const getFormattedEndTime = format(formattedEndTime, 'hh:mm a');
  return (
    <Stack sx={{ mb: '2.5rem' }}>
      <Typography
        variant="body2"
        noWrap
        sx={{
          whiteSpace: 'initial',
          fontSize: '1.2rem',
          mb: '1rem',
          color: '#4d144a',
          fontFamily: 'Poppins-400'
        }}
      >
        {event?.overview && parse(DOMPurify.sanitize(event?.overview))}
      </Typography>

      <Image
        src={SecondaryBanner}
        alt={SecondaryBanner}
        sx={{
          display: { md: "none", lg: "none", xl: "none", sm: 'none', xs: "block" },
        }}
      />
      <Stack
        sx={{
          backgroundColor: '#4d144a',
          p: '1.5rem 2rem',
          '@media (max-width: 768px)': {
            p: '1.5rem 1.5rem',
          },
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            pl: '10px',
            alignItems: 'center',
            '@media (max-width: 768px)': {
              flexDirection: 'column',
              pl: 0
            },
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              width: '50%',
              alignItems: 'center',
              '@media (max-width: 768px)': {
                width: '100%',
              },
            }}
          >
            <CalendarMonthIcon sx={iconStyle} />
            <Typography
              variant="body2"
              noWrap
              sx={{
                whiteSpace: 'initial',
                fontSize: '1.2rem',
                mb: '0px',
                color: '#ffffff',
                fontFamily: 'Poppins-600',
                '@media (max-width: 768px)': {
                  fontSize: '0.9rem',
                },
              }}
            >
              {event?.startTime &&
                format(
                  parseISO(event?.startTime),
                  "d MMMM yyyy"
                )}
            </Typography>
          </Stack>
          <Stack
            sx={{
              width: '1px',
              height: '32px',
              backgroundColor: '#ffffff',
              '@media (max-width: 768px)': {
                width: '100%',
                height: '1px',
                my: '1rem'
              },
            }}
          />
          <Stack
            sx={{
              flexDirection: 'row',
              width: '50%',
              alignItems: 'center',
              pl: '30px',
              '@media (max-width: 768px)': {
                width: '100%',
                pl: 0,
              },
            }}
          >
            <AccessTimeIcon sx={iconStyle} />
            <Stack sx={{ flexDirection: 'column' }}>
              <Typography
                variant="body2"
                noWrap
                sx={{
                  whiteSpace: 'initial',
                  fontSize: '1.2rem',
                  mb: '0px',
                  color: '#ffffff',
                  fontFamily: 'Poppins-600',
                  lineHeight: '22px',
                  '@media (max-width: 768px)': {
                    fontSize: '0.9rem',
                  },
                }}
              >
                {getFormattedTime} - {getFormattedEndTime} (local time)
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ borderStyle: 'solid', borderColor: '#ffffff', my: '1rem' }} />
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            pl: '10px',
            '@media (max-width: 768px)': {
              pl: 0,
            },
          }}
        >
          <LocationOnIcon sx={iconStyle} />
          <Typography
            variant="body2"
            noWrap
            sx={{
              whiteSpace: 'initial',
              fontSize: '1.2rem',
              mb: '0px',
              color: '#ffffff',
              fontFamily: 'Poppins-600',
              '@media (max-width: 768px)': {
                fontSize: '0.9rem',
              },
            }}
          >
            {event?.locationName! || ""}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
