import classNames from 'classnames';
import connectionIcon from '../../../assets/images/event-landing-pre-event/connection.svg';
import publicIcon from '../../../assets/images/event-landing-pre-event/earth-globe.svg';
import lockIcon from '../../../assets/images/lock.svg';
import { ArrowDownSvgIcon } from '../../../shared-components/V2/IconSvg';
import { ConnectionsSvgIcon } from '../../../shared-components/V2/IconSvg/ConnectionsSvgIcon/ConnectionsSvgIcon';
import { LockSvgIcon } from '../../../shared-components/V2/IconSvg/LockSvgIcon/LockSvgIcon';
import { PublicSvgIcon } from '../../../shared-components/V2/IconSvg/PublicSvgIcon/PublicSvgIcon';

type PrivacyOptionsType = {
  text: string;
  icon: string;
};

const privacyOptionsForPostAsUser: PrivacyOptionsType[] = [
  {
    text: 'Public',
    icon: publicIcon,
  },
  {
    text: 'Only me',
    icon: lockIcon,
  },
  {
    text: 'Connections',
    icon: connectionIcon,
  },
];

const privacyOptionsForPostAsCompany: PrivacyOptionsType[] = [
  {
    text: 'Public',
    icon: publicIcon,
  },
  {
    text: 'Followers',
    icon: connectionIcon,
  },
];

const PrivacyOption = (option: { icon: string; text: string, key: number, handlePrivacyOfPost: Function }) => (
  <li
    key={`privacy-${option.key}`}
    className="flex flex-row items-center text-[13px] h-[35px] leading-[20px] px-[10px] hover:bg-[#EDF2F5] rounded-[5px] mx-[5px] cursor-pointer"
    onClick={() => option.handlePrivacyOfPost(option.text, option.icon)}
  >
    <img src={option.icon} alt="" className="w-4 h-4 mr-2" />
    <span className='mt-[1px]'>{option.text}</span>
  </li>
);
interface IPrivacyOptionsProps {
  privacyOptionsFor: any;
  handlePrivacyOfPost: Function;
}
const PrivacyOptions = ({ privacyOptionsFor, handlePrivacyOfPost }: IPrivacyOptionsProps) => (
  <div className="bg-white dropdown-box py-[5px] absolute z-30 sm:w-[166px] w-[150px]">
    <ul className="text-base">
      {privacyOptionsFor === "User" &&
        privacyOptionsForPostAsUser.map((option, index) => (
          <PrivacyOption icon={option.icon} text={option.text} key={index} handlePrivacyOfPost={handlePrivacyOfPost} />
        ))}
      {privacyOptionsFor === "Company" &&
        privacyOptionsForPostAsCompany.map((option, index) => (
          <PrivacyOption icon={option.icon} text={option.text} key={index} handlePrivacyOfPost={handlePrivacyOfPost} />
        ))}
    </ul>
  </div>
);

interface IPrivacyDropDownProps {
  displayPrivacyStatusMenu: boolean;
  setDisplayPrivacyStatusMenu: any;
  iconPrivacyOfPost: any;
  privacyOfPost: any;
  privacyOptionsFor: any;
  handlePrivacyOfPost: Function;
}

const PrivacyDropDown = ({ displayPrivacyStatusMenu, setDisplayPrivacyStatusMenu, iconPrivacyOfPost, privacyOfPost, privacyOptionsFor, handlePrivacyOfPost }: IPrivacyDropDownProps) => {
  const lockIcon = iconPrivacyOfPost.includes("lock");
  const publicIcon = iconPrivacyOfPost.includes("earth-globe");
  const connectionIcon = iconPrivacyOfPost.includes("connection");
  return (
    <>
      <button
        type="button"
        // className="flex flex-row justify-between items-center px-3.5 bg-gray-200 text-center rounded-2xl p-2 text-xs"
        className={classNames("menu focus:outline-none focus:shadow-solid flex flex-row border-[#7283A4] border-1 h-[31px] sm:px-[20px] px-3 hover:bg-[#DFE5EB] justify-between items-center text-center rounded-[5px] text-[12px] leading-[18px] text-[#7283A4] font-semibold", {
          'bg-[#DFE5EB]': displayPrivacyStatusMenu,
          'bg-white': !displayPrivacyStatusMenu,
        })}
        onClick={() =>
          setDisplayPrivacyStatusMenu(!displayPrivacyStatusMenu)
        }
      >
        {lockIcon && <div className='mr-[10px]'><LockSvgIcon fillColor="#7283A4"/></div>}
        {publicIcon && <div className='mr-[10px]'><PublicSvgIcon fillColor="#7283A4"/></div>}
        {connectionIcon && <div className='mr-[10px]'><ConnectionsSvgIcon fillColor="#7283A4"/></div>}
        {!lockIcon && !publicIcon && !connectionIcon && (
          <img
            src={iconPrivacyOfPost}
            alt=""
            className="w-[12px] h-4 mr-[10px] grayscale"
          />
        )}
        {" "}
        <div className='max-w-[185px] line-clamp-1 leading-[31px]'>{privacyOfPost}</div>
        <ArrowDownSvgIcon className="ml-[10px]" fillColor="#7283A4" />
      </button>
      {displayPrivacyStatusMenu && <PrivacyOptions privacyOptionsFor={privacyOptionsFor} handlePrivacyOfPost={handlePrivacyOfPost} />}
    </>
  );
};

export { PrivacyDropDown };