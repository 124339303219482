import {
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { IAttendee } from '../../../models/events-live/attendee';
import { IExpoBooth } from '../../../models/expo';
import { IEvent } from '../../../models/event/event';
import { IEventSpeakersData } from '../../../models/event/event-speakers-data';
import { IAppState } from '../../../store';
import { useExpoWebSocket, useWebSocket } from '../../../utils/hooks';
import VideoExpoPanel from './video-and-expo-panel';
import { setNextSession } from '../../../store/liveEvent';
import { getPolls } from '../../../store/livePoll';
import HostAndAttendees from '../event-moderator/host-and-attendees';

type VideoAndAudiencePanelProps = {
  booths: IExpoBooth[];
  event: IEvent;
  attendees?: IAttendee[];
  speakers?: IEventSpeakersData[];
};

const VideoAndAudiencePanel = (props: VideoAndAudiencePanelProps) => {
  const dispatch = useDispatch();
  const { webSocket } = useWebSocket();
  const [screenMode, setScreenMode] = useState<'small' | 'large' | 'hidden' | undefined>('small');
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const expoWebsocket = useExpoWebSocket();
  const currentSession = useSelector((state: IAppState) => state.liveEvent.session?.current);
  const onDemandRooms = useSelector((state: IAppState) => state.liveEvent.onDemandRooms);
  const [videoUrl, setVideoUrl] = useState<string | undefined>();
  useEffect(() => {
    if (onDemandRooms && currentSession) {
      const roomSession = onDemandRooms?.map(room => room.sessions).flat();
      const session = roomSession.find(session => session.id === currentSession?.id);
      setVideoUrl(`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${session?.eventSessionVideos[0]?.videoUrl}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSession]);

  useEffect(() => {
    webSocket.then(ws => {
      ws.send(
        JSON.stringify({
          action: 'poll-appeared'
        })
      );
    });
  }, [webSocket]);

  useEffect(() => {
    dispatch(getPolls(props.event.id, currentSession?.id!));
  }, [dispatch, currentSession?.id, props.event.id]);

  const playbackFinished = () => {
    dispatch(setNextSession());
  };

  return (
    <div className="flex flex-col lg:flex-row justify-center gap-4 mb-5 align-center">
      <VideoExpoPanel
        title={props.event.name}
        url={videoUrl || props.event?.rooms?.[0]?.primaryHlsUrl}
        logoURL={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${props.event?.company?.logo}`}
        expoData={props.booths}
        screenMode={screenMode}
        startTime={props.event.startTime}
        endTime={props.event.endTime}
        setScreenMode={(mode: 'small' | 'large' | 'hidden' | undefined) => setScreenMode(mode)}
        attendees={props.attendees}
        setPlayBackVideo={playbackFinished}
      />
      {
        screenMode === 'small' && (
          <div className={classNames('lg:w-[315px] h-[620px] bg-white pb-2 flex flex-none rounded-xl shadow-md  overflow-y-scroll')}>
            <HostAndAttendees
              stage={{
                speakers: props.speakers!,
                guests: [],
                moderators: [],
                virtualSpeakers: []
              }}
              event={props.event}
              screenMode={screenMode}
              attendees={props.attendees}
              classNames="h-full"
            />
          </div>
        )
      }
    </div>
  );
};

export default VideoAndAudiencePanel;
