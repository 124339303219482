/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
import classNames from 'classnames';
import SponsorDefault from '../../images/sponsor-default.svg';

interface Props {
  partner: any;
}

const hexToRgb = (hex: string): [number, number, number] => {
  const hexColor = hex.replace(/^#/, '');
  const bigint = parseInt(hexColor, 16);
  const r = (bigint >> 16) & 255; // eslint-disable-line no-bitwise
  const g = (bigint >> 8) & 255; // eslint-disable-line no-bitwise
  const b = bigint & 255; // eslint-disable-line no-bitwise
  return [r, g, b];
};

const calculateLuminance = (color: [number, number, number]): number => {
  const [r, g, b] = color.map(c => c / 255);
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const isCloseToBlack = (color: [number, number, number]): boolean => {
  const luminanceThreshold = 0.1; // Adjust this threshold as needed
  const luminance = calculateLuminance(color);
  return luminance <= luminanceThreshold;
};

export const isNearToBlackColor = (color: string): boolean => {
  if (color) {
    const rgbColor: [number, number, number] = hexToRgb(color);
    const isNearBlack: boolean = isCloseToBlack(rgbColor);
    return isNearBlack;
  }
  return false;
};

export const PartnerDetails = ({ partner }: Props) => {
  const logoDominantColor = partner?.logo ? partner?.logoDominantColor : partner?.company?.logoDominantColor;
  const backgroundColor = isNearToBlackColor(logoDominantColor) ? 'bg-white' : 'bg-[#14161A]';

  return (
    <div className="w-[90px]">
      <div
        className={classNames('w-[90px] h-[90px] rounded-[5px] cursor-pointer', backgroundColor)}
        onClick={() => window.open(partner?.company?.website)}
      >
        <img
          className="object-contain w-full h-full rounded-[5px]"
          src={partner?.logo || partner.company?.logo ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${partner?.logo || partner.company?.logo}` : SponsorDefault}
        />
      </div>
      <div className="font-medium helvetica-neue-65-medium text-white text-[14px] leading-[18px] mt-[8px] text-center">
        {partner?.company?.name || ''}
      </div>
    </div>
  );
};

export default PartnerDetails;
