import * as React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { getMinifiedImage } from "src/mui/utils/image";
import { useState } from "react";
import { Autocomplete, Card, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CompanyService } from "src/services";
import { CustomAvatar } from "./custom-avatar";
import { useDebounce } from 'src/mui/hooks/use-debounce';
import companyDefault from '../../../assets/images/company-default.svg';


const fieldHelperText = {
  "& .MuiFormHelperText-root": { ml: "1px" }
}

type OptionType = {
  id: string;
  name: string;
};

type PCompanyProps = {
  name: string;
  label?: any;
  disabled?: boolean;
  defaultValue?: OptionType;
  placeholder?: any;
  sx?: any
};

const companyDropdownOptions = (option: any, style?: any, isFullName?: boolean) => (
  <Stack direction="row" alignItems="center">
    <div
      style={{
        width: '30px',
        height: '30px',
        marginRight: '11px',
        background: '#ffffff',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CustomAvatar
        src={option?.logo || option?.company?.logo ? getMinifiedImage(option?.logo || option?.company?.logo) : companyDefault}
        alt={`${option?.company?.name || option?.company || option?.name}`}
        name={option?.company?.name || option?.company || option?.name}
        sx={{
          width: '30px',
          height: '30px',
          maxWidth: '30px',
          maxHeight: '30px',
          borderRadius: '4px',
          backgroundColor: '#F0F0F0',
          '& .MuiAvatar-img': {
            objectFit: 'contain',
          },
        }}
      />
    </div>

    <Tooltip title={`${option?.company?.name || option?.company || option?.name}  •  company`}>
      <Typography
        variant="body1"
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          textWrap: 'nowrap',
          whiteSpace: 'nowrap',
          maxWidth: '330px',
          ...style,
        }}
      >
        <span style={{ fontFamily: 'Poppins-500', fontSize: '15px' }}>
          {option?.company?.name || option?.company || option?.name}
        </span>
        {!isFullName && (
          <>
            {' • '}
            <span style={{ fontFamily: 'Poppins-400', fontSize: '13px' }}>company</span>
          </>
        )}
        {option?.company?.industry ||
          option?.user?.company?.industry ||
          option?.industry?.name ? (
          <>
            {' • '}
            <span style={{ fontFamily: 'Poppins-400', fontSize: '13px' }}>
              {option?.company?.industry?.name ||
                option?.user?.company?.industry?.name ||
                option?.industry?.name}
            </span>
          </>
        ) : null}
        {option?.eventAdmins && (
          <span style={{ fontFamily: 'Poppins-500', fontSize: '15px' }}> {' • '} Host </span>
        )}
      </Typography>
    </Tooltip>
  </Stack>
);

export default function PCompanySelectLiveSearch({
  name,
  label,
  disabled = false,
  defaultValue,
  placeholder,
  sx
}: PCompanyProps) {
  const { control } = useFormContext();
  const [searchCompany, setSearchCompany] = useState("");
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [openMenu, setOpenMenu] = useState(false);

  const debouncedSearchCompany = useDebounce(searchCompany, 500);

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>, field: any
  ) => {
    const { value } = event.target;
    setSearchCompany(value);
    field.onChange(event.target.value && event.target.value.trim() !== "" ? { id: event.target.value, name: event.target.value } : null);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (debouncedSearchCompany.trim() !== "") {
        try {
          const companyService: CompanyService = new CompanyService();
          const response: any = await companyService.getCompanyByIdOrName(debouncedSearchCompany);

          setCompanyList([...response?.data]);
        } catch (error) {
          console.error(error);
        }
      } else {
        setCompanyList([]);
      }
    };

    fetchData();
  }, [debouncedSearchCompany]);

  const handleBlur = (field: any) => {
    if (!companyList?.length! || searchCompany === "") {
      setOpenMenu(false)
    }
  };


  return (

    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Autocomplete
            {...field}
            renderOption={(props, option: any) => (
              <li {...props} style={{ paddingLeft: '0px !important' }}>
                {companyDropdownOptions(option, { minWidth: '380px' }, true)}
              </li>
            )}
            options={companyList || []}
            open={openMenu && searchCompany !== "" && companyList.length ? true : false}
            onOpen={() => setOpenMenu(true)}
            onClose={() => setOpenMenu(false)}
            onBlur={() => handleBlur(field)}
            getOptionLabel={(optLocation) => optLocation?.name || ""}
            disabled={disabled}
            filterOptions={(options) => options}
            onChange={(_, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                variant="outlined"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, field)}
                value={searchCompany}
                error={!!error}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null,
                }}
                sx={{
                  ...sx,
                  ...fieldHelperText,
                  "& .MuiAutocomplete-input": {
                    pl: "0px !important"
                  }
                }}
                helperText={error?.message ||

                  ""}
              />
            )}
            clearOnBlur
            clearOnEscape
          />
          <Card sx={sx ? sx : { width: "100%", height: 400 }} />
        </>
      )}
    />
  );
}
