import { useState } from "react"

export const ConnectSvgIcon = () => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const colorBlue = '#3062B5';
    const defaultColor = '#7283A4';
    const fillColor = isHover ? colorBlue : defaultColor;

    return <svg
    id="Component_286_6" data-name="Component 286 – 6" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"
    onMouseEnter={() => setIsHover(true)}
    onMouseLeave={() => setIsHover(false)}
  >
    <g id="Component_156_330" data-name="Component 156 – 330">
      <rect id="Rectangle_1731" data-name="Rectangle 1731" width="40" height="40" rx="15" fill="#fff"/>
    </g>
    <circle id="Ellipse_1302" data-name="Ellipse 1302" cx="7.886" cy="7.886" r="7.886" transform="translate(12.114 8.449)" fill="#7283a4" stroke="#fff" strokeWidth="1"/>
    <path id="Path_27175" data-name="Path 27175" d="M1.755,4.5A.547.547,0,0,0,2.2,4.263a5.046,5.046,0,0,1,8.218,0,.549.549,0,0,0,.446.238h1.2a.555.555,0,0,0,.483-.821A7.126,7.126,0,0,0,.068,3.686.552.552,0,0,0,.554,4.5Z" transform="translate(13.693 24.945)" fill="#7283a4"/>
    <path id="Path_27176" data-name="Path 27176" d="M5.966,0A5.965,5.965,0,1,1,0,5.965,5.966,5.966,0,0,1,5.966,0Z" transform="translate(8.068 17.516)" fill="#7283a4" stroke="#fff" strokeWidth="1"/>
    <rect id="Rectangle_8264" data-name="Rectangle 8264" width="7.954" height="0.795" rx="0.398" transform="translate(10.057 23.084)" fill="#fff"/>
    <rect id="Rectangle_8265" data-name="Rectangle 8265" width="7.954" height="0.796" rx="0.398" transform="translate(14.432 19.504) rotate(90)" fill="#fff"/>
  </svg>

};
