/* eslint-disable max-len */
import classNames from 'classnames';
import PartnerLogo from '../../../assets/images/website-event/partner-logo.png';

export const BrochureSection = () => {
  const openURl = () => {
    window.open('https://www.sit-down.com/contact-us-download-brochure', '_blank');
  };
  return (
    <div className="w-full lg:flex-row flex-col flex items-center justify-center bg-[#14161A] md:h-[155px] lg:pl-[60px] md:pl-[40px] pl-[20px] pr-[20px] lg:gap-x-[170px] gap-5 md:py-0 py-6">
      <img src={PartnerLogo} alt="Panelist" />
      <div className="flex md:flex-row flex-col items-center justify-center lg:gap-x-[87px] gap-5">
        <div className="text-[20px] font-medium leading-[22px] text-white helvetica-neue-45-light md:text-left text-center">Get up to six services in one engagement with Sit-Down Partner.</div>
        <button
          type="button"
          className={classNames('px-[12px] border-2 border-white leading-[16px] text-white bg-transparent hover:bg-black hover:text-white tracking-wide rounded-[5px] w-[196px] h-[40px] text-[14px] helvetica-neue-55-roman font-semibold whitespace-pre')}
          onClick={openURl}
        >
          Request a Brochure
        </button>
      </div>
    </div>
  );
};
export default BrochureSection;
