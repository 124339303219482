/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
import { MeetingStatus, useMeetingManager, useMeetingStatus } from 'amazon-chime-sdk-component-library-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { VideoControlls } from '../../../../components/chime-video-call/video-controlls';
import { IAppState } from '../../../../store';
import { LiveCall } from '../live-call';
import { getCompanyName } from 'src/utils';

export const ModeratorView = () => {
  const { slug, roomId, role } = useParams();
  const [isLive, setIsLive] = useState(false);
  const [isChimeLoaded, setChimeLoaded] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();

  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const interactiveEvent = useSelector((state: IAppState) => state.interactiveEvent);
  const { kicked } = interactiveEvent;

  useEffect(() => {
    if (kicked && meetingManager && role !== 'moderator') {
      meetingManager.leave().then(() => navigate(`/${getCompanyName(liveEvent.event)}/${liveEvent.event?.slug}/live`));
    }
  }, [kicked]);

  useEffect(() => {
    meetingManager.subscribeToAudioVideo((e:any) => {
      if (e && !isChimeLoaded) {
        setChimeLoaded(true);
      }
    });
  }, []);

  useEffect(() => {
    if (meetingManager && meetingStatus === MeetingStatus.Succeeded) {
      setIsLive(true);
    } else {
      setIsLive(false);
    }
  }, [meetingManager, meetingStatus]);

  return (
    <>
      <LiveCall />
      <div className="absolute bottom-0 w-full flex justify-center">
        <VideoControlls
          onCallHangup={() => navigate(`/${getCompanyName(liveEvent.event)}/${slug}/live`)}
          isEvent
        />
      </div>
    </>
  );
};
