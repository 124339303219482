import {
  AudioInputControl, ContentShareControl, ControlBar, MeetingStatus, useMeetingManager, useMeetingStatus, VideoInputBackgroundBlurControl
} from 'amazon-chime-sdk-component-library-react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../shared-components/V2/Button';

interface IVideoControllsProps {
  onCallHangup?: Function;
  disableScreenSharing?: boolean;
  onChange?: (status: string) => void;
  isEvent?: boolean;
  onLive?: Function;
  goLiveDisabled?: boolean;
}
export const VideoControlls = ({ onCallHangup, disableScreenSharing, onChange, isEvent, onLive, goLiveDisabled }: IVideoControllsProps) => {
  const { role } = useParams();
  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();

  const [showControls, setShowControls] = useState(false);
  const [isChimeLoaded, setChimeLoaded] = useState(false);

  useEffect(() => {
    if (meetingStatus === MeetingStatus.Succeeded) {
      onChange && onChange("show");
      setShowControls(true);
    } else {
      onChange && onChange("hide");
      setShowControls(false);
    }
  }, [meetingStatus])

  useEffect(() => {
    if (meetingManager.meetingId) {
      setChimeLoaded(true);
    } else {
      meetingManager.subscribeToAudioVideo((e: any) => {
        if (e && !isChimeLoaded) {
          setChimeLoaded(true);
        }
      });
    }
  }, []);

  return (
    <>
      <div className={classNames("relative w-full h-[61px] flex flex-row items-center justify-center -bottom-4 bg-gradient-to-t from-slate-300", {
        hidden: !(showControls || isEvent)
      })}>
        {
          !showControls && isEvent &&
          <div className="">
            <Button
              disabled={!isChimeLoaded || (goLiveDisabled && role === 'speaker')}
              text="Go Live"
              className="bg-orange"
              onClick={() => {
                if (meetingManager) {
                  meetingManager.start().finally(() => onLive!());
                }
              }}
            />
          </div>
        }
        {
          showControls && (
            <>
              <div className="px-4">
                <ControlBar layout="undocked-horizontal" showLabels={false} className="" css="{ background-color: inherit; height: 2.625rem !important; } .ch-content { font-size: 10px; }">
                  <AudioInputControl />
                  <VideoInputBackgroundBlurControl />
                  {!disableScreenSharing && <ContentShareControl />}
                </ControlBar>
              </div>
              <div className="">
                <Button
                  text="End Session"
                  className="bg-orange"
                  onClick={() => {
                    if (meetingManager) meetingManager.leave().finally(() => onCallHangup!());
                  }}
                />
              </div>
            </>
          )}
      </div>
      <div />
    </>
  );
};
