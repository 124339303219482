import { useEffect, useRef } from 'react';
import { LIVE_EVENT } from '../../../constants';
import { IUserProfile } from '../../../models/user-profile';
import { Message } from '../../../store/privateChat';
import ChatMessage from './chat-msg';
import noMessage from '../../../assets/images/nav-menu/no-message.svg';

type ChatRoomProps = {
  messages: Message[];
  user: IUserProfile;
};

const ChatRoom = (props: ChatRoomProps) => {
  // Ensure messages are always scrolled to the bottom

  const scrollableChat = useRef(null);

  useEffect(() => {
    if (scrollableChat.current) {
      // eslint-disable-next-line dot-notation
      scrollableChat.current['scrollTop'] = scrollableChat.current['scrollHeight'];
    }
  }, [props.messages]);

  return (
    <div ref={scrollableChat} className="py-2 overflow-y-scroll h-447 flex flex-col">
      {props.messages.length === 0 ? (
        <div className="flex flex-col justify-center mx-auto w-full">
          <div className="flex justify-center">
            <div className="text-md text-blue-4 m-5 w-8/12 text-center">
              {LIVE_EVENT.EMPTY_MESSAGES}
            </div>
          </div>
          <div className="h-[247px] mx-auto">
            <img src={noMessage} className="w-[205px]" alt="No message available" />
          </div>
        </div>
      ) : (
        props.messages.map((item: Message, key) => (
          <ChatMessage
            key={`chat-message-${key}`}
            sender={item.sender}
            message={item.message}
            senderId={item.senderId}
            avatar={props.user.id === item.senderId ? props.user?.avatar : item?.avatar}
            mine={props.user.id === item.senderId}
            type={item.type!}
          />
        ))
      )}
    </div>
  );
};

export default ChatRoom;
