import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserService } from "../../../../services";
import { IAppState } from "../../../../store";
import { getNotifications } from "../../../../store/notifications";
import useCloseMenuWithClickOutside from "../../../../utils/hooks/closeMenuWithClickOutside";
import NotificationBadge from "./notification-badge";
import NotificationsMenu from "./notifications-menu";

import { NotificationSvgIcon } from "../../../../shared-components/V2/IconSvg";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { Skeleton } from "@mui/material";
import { JoinMeetingModal } from "src/mui/pages/event/live/join-meeting";
import { MeetingRoomModal } from "src/mui/pages/event/live/join-meeting/meeting-room";
import { ExpoBoothMeetingRoomModal } from "src/mui/pages/event/live/join-booth-room/booth-meeting-room";

const NavbarNotifications = () => {
  const ref = useRef<any>(null);
  const { currentUser: { isEmailVerified } = {}, hideEmailVerification } =
    useSelector((state: IAppState) => state?.auth);
  const { joinMeeting , joinExpoBoothRoom} = useSelector((state: IAppState) => state?.liveEvent);

  const { showDropdown, handleDropdown } = useCloseMenuWithClickOutside(ref);
  const [totalUnseen, setTotalUnseen] = useState(0);
  const dispatch = useDispatch();
  // const notifications = useSelector((state: IAppState) => state.notifications); //comes from api
  const userNotification = useSelector(
    (state: IAppState) => state.privateChat.userNotification
  ); //comes from ws

  const [totalPage, setTotalPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<any>();

  const hasMoreItems = nextPage <= totalPage;

  const loader = (
    <Skeleton
      animation="wave"
      variant="rounded"
      width={"100%"}
      height={136}
      sx={{
        backgroundColor: "transparent",
        boxShadow:
          "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
      }}
    />
  );

  // useEffect(() => {
  //   dispatch(getNotifications({ page: 1 }));
  // }, [dispatch, totalUnseen]);

  useEffect(() => {
    if (userNotification) {
      const unseenCount = notifications?.data?.find(
        (a: any) => a.id === userNotification?.id && !userNotification?.isSeen
      );
      setTotalUnseen(notifications?.unseenCount + (!unseenCount ? 1 : 0));
    } else {
      setTotalUnseen(notifications?.unseenCount);
    }

    if (userNotification && notifications?.data?.length) {
      setNotifications((prevNotifications: any) => {
        if (prevNotifications?.data) {
          const newData = prevNotifications?.data;
          if (
            !prevNotifications?.data.find(
              (notification: any) => notification.id === userNotification.id
            )
          ) {
            newData.unshift(userNotification);
            return { ...prevNotifications, data: newData };
          }
        }
        return prevNotifications;
      });
    }
  }, [userNotification, notifications]);

  const seenAllNotifications = () => {
    new UserService().seenAllNotifications().then((res) => {
      if (res.message === "SUCCESS") setTotalUnseen(0);
    });
  };
  const handleNotificationDropdown = () => {
    handleDropdown(showDropdown);
    seenAllNotifications();
  };

  const fetchItems = useCallback(async () => {
    if (fetching) {
      return;
    }
    setFetching(true);

    try {
      // await dispatch(getNotifications({ page: nextPage }));
      const userService = new UserService();
      const response = await userService.getNotifications(nextPage);
      setTotalPage(response?.totalPage);

      if (response && nextPage <= response?.totalPage) {
        // setNotifications({
        //   ...response,
        //   data: notifications?.data?.concat(response?.data)
        // });
        setNotifications((prevNotifications: any) => {
          const newData = prevNotifications
            ? prevNotifications.data.concat(response.data)
            : response.data;
          return { ...response, data: newData };
        });
        setNextPage(nextPage + 1);
      } else {
        setNextPage(nextPage);
        setFetching(true);
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setFetching(false);
    }
  }, [fetching, nextPage]);

  useEffect(() => {
    setIsLoading(true);
    fetchItems();
  }, []);

  return (
    <>
      {joinMeeting && joinMeeting?.isOpen && (
        <JoinMeetingModal meetingId={joinMeeting?.meetingId} />
      )}
      {joinMeeting && joinMeeting?.isRoomOpen && <MeetingRoomModal />}

      {/* {joinExpoBoothRoom && joinExpoBoothRoom?.isOpen && (
        <JoinExpoBoothMeetingModal eventExpoBoothId={joinExpoBoothRoom?.eventExpoBoothId} />
      )} */}
      {joinExpoBoothRoom && joinExpoBoothRoom?.isRoomOpen && <ExpoBoothMeetingRoomModal />}

      <div onClick={() => handleNotificationDropdown()} ref={ref}>
        <NotificationSvgIcon />
        {totalUnseen > 0 && (
          <NotificationBadge countUnseenNotifications={totalUnseen} />
        )}
        {showDropdown && (
          <div
            id="scrollableDiv"
            className={classNames(
              "bg-white rounded-lg md:w-[35%] w-[100vw] md:min-h-[42px] md:max-h-[100vh] max-h-[100vh] md:h-auto h-[100vh] overflow-auto z-30 shadow-xs6 fixed md:right-[10px] -right-[28px] translate-y-[-94.5%] md:translate-y-[0%]",
              {
                "md:top-[55px]": isEmailVerified || hideEmailVerification,
                "md:top-[104px]": !isEmailVerified && !hideEmailVerification,
              }
            )}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={fetchItems}
              hasMore={hasMoreItems}
              useWindow={false}
              loader={loader}
            >
              <NotificationsMenu notifications={notifications} />
            </InfiniteScroll>
          </div>
        )}
      </div>
    </>
  );
};
export default memo(NavbarNotifications);
