import { useSelector } from 'react-redux';
import { Logo } from 'src/shared-components/V2/Logo';
import { IAppState } from 'src/store';
import { rgbToHex } from 'src/utils/color-transformation';
import { Loader } from 'src/shared-components/Loader';
import { EventQuestionPageTypes, useSurveyContext } from 'src/mui/providers/GeneralContext';
import { useEffect } from 'react';
import { useCurrentUser } from 'src/utils/hooks';
import DefaultSurveyButton from './DefaultSurveyButton';

export const DefaultBannerSurveys = () => {
  const survey = useSelector((state: IAppState) => state.website.survey!);

  const { getQuestionPagesInsights, } = useSurveyContext();
  const user = useCurrentUser();

  const getBackgroungGradient = () => {
    if (!survey?.backgroundDominantColor?.length) return '#0D4552';
    return `${rgbToHex(survey?.backgroundDominantColor)}`;
  };

  useEffect(() => {
    if (survey?.id) {
      if (user) {
        getQuestionPagesInsights(EventQuestionPageTypes.SURVEY, survey?.id!);
      }
    }
  }, [survey?.id, user]);

  return (
    <div id="banner" className="w-full">
      <div className="block md:flex">
        <div
          className="w-screen bg-gradient-to-b px-[20px] xl:px-0"
          style={{
            background: `linear-gradient(${getBackgroungGradient()}, transparent)`,
          }}
        >
          <div
            className="website-event-cover z-10 relative overflow-hidden"
            style={{
              backgroundImage:
                survey?.cover
                && `url("${`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${survey?.cover}`}")`,
            }}
          >
            <div
              style={{
                backgroundColor: survey?.isCoverImageOverlayColor
                  ? survey?.coverImageOverlayColour
                  : 'none',
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                opacity: survey?.isCoverImageOverlayColor
                  ? `${survey?.coverImageOverlayOpacity}%`
                  : '0%',
              }}
            />
            <div className="w-full text-white flex flex-col items-center rounded-b-[20px] h-[100%] justify-center relative z-10 overflow-hidden">
              <div className=" md:max-w-[771px] max-w-full md:px-0 px-3">
                <div className="flex justify-center items-center w-full sm:w-[699px] h-[150px] mb-5 mx-auto md:px-0 px-7">
                  {survey?.logo && (
                    <Logo
                      src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${survey.logo}`}
                      className="flex justify-center h-full object-contain"
                      alt={survey?.name}
                      defaultLogo="company"
                    />
                  )}
                </div>
                <div className="flex justify-center mt-[20px]">
                  {survey ? (
                    <DefaultSurveyButton isBanner />
                  ) : (
                    <Loader className="pt-5" key="loader" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
