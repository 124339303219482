import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import closeIcon from '../../assets/images/nav-menu/close-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/chat-close.svg';
import { ReactComponent as CloseIconIcon } from '../../assets/images/close-icon.svg';
import { IChatHead } from '../../models/messages/message';
import { Avatar } from '../../shared-components/V2';
import { closeChatHead } from '../../store/chat-heads';
import { openConversation } from '../../store/oneToOneConversation';

export const ChatHeads = (props: { chatHeads: IChatHead[] }) => {
  const dispatch = useDispatch();
  const [conversationToClose, setConversationToClose] = useState('');
  const [showAdditionalChats, setShowAdditionalChats] = useState(false);

  const chatHeadsToRender = props.chatHeads.slice(0, 4);
  const chatHeadsNotRendered = props.chatHeads.slice(4);

  const closeChatHeadesNotRendered = (conversationID: string) => {
    dispatch(closeChatHead(conversationID));
  }

  const additionalChats = () => (
    <div
      className="bg-gray-5 opacity-100 text-white font-semibold rounded-15 relative min-h-[40px] w-[40px] h-[40px] flex justify-center items-center text-center cursor-pointer"
      onClick={() => setShowAdditionalChats(!showAdditionalChats)}>
      {`+${chatHeadsNotRendered.length}`}
    </div>
  );

  const additionalConversations = () => (
    <div className="absolute right-16 bottom-6 w-48 bg-white rounded-10 drodown-shadow p-1 mr-1">
      <ul className='text-base flex flex-col'>
        {chatHeadsNotRendered.map((chat, index) => (
          <div className="hover:bg-[#EDF2F5] h-[30px] rounded-5 flex items-center px-1.5">
            <li
              key={index}
              className="cursor-pointer"
              onClick={() => {
                dispatch(openConversation(chat));
              }}>
              <div className="flex flex-row items-center">
                <span className="w-[163px] text-[11px] text-[#172746] tracking-wide">
                  {chat.username}
                </span>
                <div
                  className="rounded-full"
                  onClick={() => closeChatHeadesNotRendered(chat.conversationId)}>
                  <CloseIconIcon className="flex justify-center mx-auto" />
                </div>
              </div>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="grid grid-cols-1 absolute bg-white rounded-15 shadow-xs1">
      <div className="flex flex-col rounded-10 h-full mx-2 my-2 gap-y-[6px]">
        {chatHeadsToRender.map((chatHead, index) => (
          <div 
            className="flex flex-row max-w-[40px]"
            onMouseEnter={() => setConversationToClose(chatHead.conversationId)}
            onMouseLeave={() => setConversationToClose('')}
          >
            {conversationToClose === chatHead.conversationId && (
              <div
                className="rounded-full absolute ml-[28px] z-10"
                onClick={() => dispatch(closeChatHead(conversationToClose))}>
                <CloseIcon className="h-[15px] w-[15px] flex justify-center mx-auto cursor-pointer" />
              </div>
            )}
            <div
              key={index}
              className="rounded-full bg-white relative"
              onClick={() => dispatch(openConversation(chatHead))}
            >
              <Avatar
                size={'SMALL'}
                className="cursor-pointer"
                src={chatHead.avatar}
                alt={chatHead.username}
              />
            </div>
          </div>
        ))}
        <div className="flex flex-row mx-auto">
          {showAdditionalChats && chatHeadsNotRendered.length > 0 && additionalConversations()}
          {chatHeadsNotRendered.length > 0 && additionalChats()}
        </div>
      </div>
    </div >
  );
};

export default ChatHeads;
