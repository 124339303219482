import {
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit';

import { IPostsResult } from '../../../models/post/PostsResult';
import { UserService } from '../../../services';

type Nullable<T> = T | null;

export interface IProfilePostsState {
  ownProfilePosts: Nullable<IPostsResult>;
  otherUserProfilePosts: Nullable<IPostsResult>;
  loading: boolean;
  errorMessage: string;
}

const initialState: IProfilePostsState = {
  ownProfilePosts: null,
  otherUserProfilePosts: null,
  loading: false,
  errorMessage: '',
};

export const profilePostsSlice = createSlice({
  name: 'profilePosts',
  initialState,
  reducers: {
    setOwnProfilePosts: (state, action: PayloadAction<IPostsResult | null>) => {
      state.ownProfilePosts = action.payload;
    },
    setOtherUserProfilePosts: (
      state,
      action: PayloadAction<IPostsResult | null>
    ) => {
      state.otherUserProfilePosts = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  setOwnProfilePosts,
  setOtherUserProfilePosts,
  setLoading,
  setErrorMessage,
} = profilePostsSlice.actions;

export const getOwnProfilePosts =
  (page: number = 1) =>
  async (dispatch: Dispatch) => {
    const userService = new UserService();

    try {
      const result = await userService.getOwnProfilePosts(page);
      dispatch(setOwnProfilePosts(result));
    } catch (error: any) {
      dispatch(setErrorMessage(error.message));
    }
  };

export const getOtherUserProfilePosts =
  (userIdOrSlug: string, page: number = 1) =>
  async (dispatch: Dispatch) => {
    const userService = new UserService();

    try {
      const result = await userService.getOtherUserProfilePosts(
        userIdOrSlug,
        page
      );
      dispatch(setOtherUserProfilePosts(result));
    } catch (error: any) {
      dispatch(setErrorMessage(error.message));
    }
  };

export const discardPosts = () => async (dispatch: Dispatch) => {
  dispatch(setOwnProfilePosts(null));
  dispatch(setOtherUserProfilePosts(null));
};
