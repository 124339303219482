import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import connectionIcon from "../../../assets/images/event-landing-pre-event/connection.svg";

import { ReactComponent as ImageIcon } from "../../../assets/images/post-attach-image-icon-new.svg";
import { ReactComponent as VideoIcon } from "../../../assets/images/post-attach-video-icon-new.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/images/post-attach-document-icon-new.svg";
import { ReactComponent as PollIcon } from "../../../assets/images/post-attach-poll-icon-new.svg";
import { ReactComponent as EventIcon } from "../../../assets/images/post-attach-event-icon.svg";

import { ArrowDownSvgIcon } from "../../../shared-components/V2/IconSvg";
import { ArrowDownSvgIconNew } from "../../../shared-components/V2/IconSvg";
import { IPollAnswer } from "../../../models/poll/poll";
import { ICreatePost } from "../../../models/post/create-post";
import { ICreatePostProps } from "../../../models/post/create-post-props";
import { Avatar, Button } from "../../../shared-components/V2";
import { IAppState } from "../../../store";
import { getCompanyPosts } from "../../../store/company/company-posts/actions";
import { createUpcomingEventPost } from "../../../store/events/upcoming-event-create-post/actions";
import { getUpcomingEventPosts } from "../../../store/events/upcoming-event-posts/actions";
import { hideModal } from "../../../store/modal/actions";
import { discardPoll } from "../../../store/poll";
import { getUserPosts } from "../../../store/user/posts/actions";
import { useCurrentUser, useUserDetails } from "../../../utils/hooks";
import AlertModal from "../../alert-modal/alert-modal";
import { ConditionalLink } from "../../conditional-link";
import CreatePoll from "../../poll/create-poll";
import ViewPoll from "../../poll/view-poll";
import { PrivacyDropDown } from "../components/PrivacyOptions";
import { Tooltip } from "../../../shared-components/Tooltip";
import { Loader } from "../../../shared-components/Loader";
import { UserService } from "src/services";
import { useDebounce } from "src/mui/hooks/use-debounce";
import { UserSearchResult } from "src/store/user/search-user";
import { getAllStories } from "src/store/user/story/actions";

export const CreatePostModal = (props: ICreatePostProps) => {
  const fileSizeLimit = parseInt(
    process.env.REACT_APP_FILE_UPLOAD_LIMIT_MB ?? "25"
  );
  const { register, handleSubmit, setValue, getValues } =
    useForm<ICreatePost>();
  const [hasFileSizeError, setHasFileSizeError] = useState<boolean>();
  const [isMediaUpload, setIsMediaUpload] = useState<boolean>(true);
  const [isLoader, setIsLoader] = useState<boolean>(false);

  const [isDisplayName, hasDisplayName] = useState(false);
  const [displayPrivacyStatusMenu, setDisplayPrivacyStatusMenu] =
    useState(false);
  const [privacyOfPost, setPrivacyOfPost] = useState<string>(
    props.location === "profile" ? "Connections" : "Followers"
  );
  const [iconPrivacyOfPost, setIconPrivacyOfPost] =
    useState<string>(connectionIcon);
  const [name, setName] = useState<string>(props.userDisplayName);

  let imageUploadRef = useRef<HTMLInputElement | null>(null);
  const videoUploadRef = useRef<HTMLInputElement | null>(null);
  const [selectedImageFileUrl, setSelectedImageFileUrl] = useState("");

  const documentUploadRef = useRef<HTMLInputElement | null>(null);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState("");

  const [isFileAttached, setIsFileAttached] = useState<boolean>(
    !!props.selectedFileUrl
  );
  const [selectedFile, setSelectedFile] = useState<FormData>();
  const [selectedFileType, setSelectedFileType] = useState("");
  // const { connections } = useSelector((state: IAppState) => state);
  const [mentionTags, setMentionTags] = useState<any>({});
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>();
  const [textAreaStyle, setTextAreaStyle] = useState<number>(22);
  const [inputValue, setInputValue] = useState("");
  // const mentionRegex = /@(\w+)/g;
  const mentionRegex = /@\w+(?:\s+\w+)?/g;
  const dispatch = useDispatch();
  const { postImage, postDocument, createpost, isModalOpen, poll } =
    useSelector((state: IAppState) => state);
  const pollCreated = poll.pollCreated;
  const pollCreatedQuestion = poll?.value?.data?.question;

  const [togglePollModal, setTogglePollModal] = useState(false);
  const onClosePollModal = () => setTogglePollModal(!togglePollModal);

  const [isPollCreated, setIsPollCreated] = useState(false);
  const [pollAnswers, setPollAnswers] = useState<IPollAnswer[]>([]);

  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [privacyOptionsFor, setPrivacyOptionsFor] = useState<
    "User" | "Company"
  >(props.location === "profile" ? "User" : "Company");

  const userService: UserService = new UserService();
  const debouncedInputValue = useDebounce(inputValue, 500);

  const getUsers = async (searchUser: string) => {
    const limit = 10;
    const result: UserSearchResult = await userService.getSearchUserResults(
      `${searchUser ?? ""}`,
      limit
    );
    setSuggestions([...result?.data?.users?.data]);
    setShowSuggestions(true);
  };

  useEffect(() => {
    setTogglePollModal(isModalOpen.isOpen);
  }, [isModalOpen]);

  useEffect(() => {
    if (props.selectedFile) setSelectedFile(props.selectedFile);

    if (props.selectedFileType) setSelectedFileType(props.selectedFileType);

    if (
      (props.selectedFileType === "video" ||
        props.selectedFileType === "image") &&
      props.selectedFileUrl
    )
      setSelectedImageFileUrl(props.selectedFileUrl);

    if (props.selectedFileType === "document" && props.selectedFileUrl)
      setSelectedDocumentUrl(props.selectedFileUrl);
  }, []);

  useEffect(() => {
    if (pollCreated) {
      setIsPollCreated(true);
      let answers: IPollAnswer[] = [];
      pollCreated?.answers?.map((answer) =>
        answers.push({
          id: answer.id,
          ordering: answer.ordering,
          votes: answer.votes,
          voteHistories: [],
          answer: answer.answer,
        })
      );
      setPollAnswers(answers);
    }
  }, [pollCreated]);

  // Running cleanup so if the user decides not to share the post after creating the poll the poll should not be visible when the create post modal opens next time
  useEffect(() => {
    return function cleanup() {
      dispatch(discardPoll());
    };
  }, []);

  useEffect(() => {
    if (createpost.errorMessage) toast.error(createpost.errorMessage);
  }, [createpost.errorMessage]);

  const onCloseModal = () => {
    setIsFileAttached(false);
    setSelectedFile(undefined);
    setSelectedFileType("");
    setSelectedDocumentUrl("");
    setSelectedImageFileUrl("");
  };

  const handleNameOfPost = (_name: string, isCompany: boolean) => {
    setName(_name);
    hasDisplayName(!isDisplayName);
    if (isCompany) setPrivacyOfPost("Followers");
    else setPrivacyOfPost("Connections");

    setIconPrivacyOfPost(connectionIcon);
  };

  const handlePrivacyOfPost = (privacy: string, icon: string) => {
    setPrivacyOfPost(privacy);
    setIconPrivacyOfPost(icon);
    setDisplayPrivacyStatusMenu(!displayPrivacyStatusMenu);
  };

  const handleImageSelected = (files: FileList) => {
    const file = files[0];
    const fileSize = file.size / 1024 ** 2;
    setIsMediaUpload(false);
    if (fileSize > fileSizeLimit) {
      setHasFileSizeError(true);
      setIsMediaUpload(true);
      return;
    }

    const data = new FormData();
    const fileUrl = URL.createObjectURL(file);
    const fileType = file.type.includes("video");

    if (fileType) {
      setSelectedFileType("video");
    } else {
      setSelectedFileType("image");
    }
    data.append("file", file, file.name);
    setSelectedFile(data);
    setSelectedImageFileUrl(fileUrl);
    setIsFileAttached(true);
  };

  const handleDocumentSelected = (files: FileList) => {
    const file = files[0];
    const fileSize = file.size / 1024 ** 2;
    if (
      fileSize > parseInt(process.env.REACT_APP_FILE_UPLOAD_LIMIT_MB ?? "25")
    ) {
      setHasFileSizeError(true);
      return;
    }

    const data = new FormData();
    data.append("file", file, file.name);
    const fileUrl = URL.createObjectURL(file);

    setSelectedFile(data);
    setSelectedDocumentUrl(fileUrl);
    setSelectedFileType("document");
    setIsFileAttached(true);
  };

  const PostedBy = () => {
    const user = useCurrentUser();
    const userDetails = useUserDetails(user);

    type PostedByOptionTypes = {
      id: string;
      name: string;
      isCompany: boolean;
    };

    const dropdownOptions: PostedByOptionTypes[] = [];
    dropdownOptions.push({
      id: user.id,
      name: props.userDisplayName,
      isCompany: false,
    });

    // if (props.location !== "event") {
      userDetails.companyAdmin?.map((company) =>
        dropdownOptions.push({
          id: company.id,
          name: company.name,
          isCompany: true,
        })
      );
    // }

    return (
      <div className="bg-white dropdown-box w-auto px-[5px] py-[5px] min-w-[210px] absolute z-50 max-h-[280px] overflow-y-auto">
        <ul className="text-base">
          {dropdownOptions.map((option, index) => (
            <li
              className="sm:flex items-center justify-left text-[12px] leading-[18px] text-[#172746] hover:bg-[#EDF2F5] sm:h-[30px] sm:py-0 py-[3px] px-2 rounded-[5px] cursor-pointer font-normal tracking-wide line-clamp-1"
              key={`option-${index}`}
              onClick={() => {
                handleNameOfPost(option.name, option.isCompany);
                if (option.isCompany) {
                  setSelectedCompanyId(option.id);
                  setPrivacyOptionsFor("Company");
                } else {
                  setPrivacyOptionsFor("User");
                }
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const onSubmit = async (data: ICreatePost) => {
    setIsLoader(true);

    let sourceValue: string = "";
    let postFormat: string = "";
    let wallId: string = "";
    let location: string = "";
    let companyId: string | null = null;
    let type: string | null = null;
    let dataToSubmit: any = null;

    let newPost = data.content;
    Object.keys(mentionTags).map((x: any) => {
      if (data.content.includes(mentionTags[x])) {
        const mentionText = `@{{User||${x}||${mentionTags[x]}}}`;
        newPost = newPost.replaceAll(mentionTags[x], mentionText);
      }
    });

    if (pollCreated) {
      sourceValue = pollCreated.id;
      postFormat = "poll";
    } else {
      if (selectedFileType === "") postFormat = "text";
      else postFormat = selectedFileType;

      if (selectedFileType === "image" || selectedFileType === "video")
        sourceValue = postImage.value || "";
      if (selectedFileType === "document")
        sourceValue = postDocument.value || "";
    }

    if (selectedCompanyId !== "" || props.location === "company") {
      if (selectedCompanyId !== "") {
        wallId = selectedCompanyId;
        companyId = selectedCompanyId;
      } else {
        wallId = props.wallId;
        companyId = props.wallId;
      }

      location = "company";
      type = "company";

      dataToSubmit = {
        ...data,
        content: newPost,
        companyId,
        format: props?.addNewStory === "story" ? "story" : postFormat,
        location,
        privacy: privacyOfPost.toLowerCase(),
        source: sourceValue,
        userMentioned: "",
        wallId,
        type,
      };
    } else {
      location = props.location;
      wallId = props.wallId;

      dataToSubmit = {
        ...data,
        content: newPost,
        format: props?.addNewStory === "story" ? "story" : postFormat,
        location,
        privacy:
          privacyOfPost.toLowerCase() === "only me"
            ? "only-me"
            : privacyOfPost.toLowerCase(),
        source: sourceValue,
        userMentioned: "",
        wallId,
      };
    }

    const createPost = async () =>
      dispatch(
        createUpcomingEventPost(dataToSubmit, selectedFile, selectedFileType)
      );
    await createPost();

    props.onAfterSave && props.onAfterSave();

    let updatePosts: Function;

    if (location === "profile") {
      updatePosts = async () => props?.addNewStory === "story" ?  dispatch(getAllStories())  :  dispatch(getUserPosts());
      await updatePosts();
    } else if (location === "company") {
      updatePosts = async () => dispatch(getCompanyPosts(companyId));
      await updatePosts();
    } else {
      updatePosts = async () => dispatch(getUpcomingEventPosts(props.wallId));
      await updatePosts();
    }

    dispatch(hideModal());
    onCloseModal();
    !!props.onClose && props.onClose();
  };

  const handlePostChange = (e: any) => {
    const newPost = e.target.value;
    if (newPost?.length >= 53)
      // setTextAreaStyle(17 + Math.trunc(newPost?.length / 36) * 17);
    if (newPost?.length === 0) setTextAreaStyle(17);
    setValue("content", newPost);
    const matches = newPost.match(mentionRegex);
    // const ownUserConnections = connections?.value?.data;
    if (matches) {
      // if (matches && ownUserConnections) {
      const mention = matches[0].substring(1);
      setInputValue(mention);
      // const filteredSuggestions = ownUserConnections.filter((user) =>
      //   user?.fullName.toLowerCase().includes(mention.toLowerCase())
      // );

      // setSuggestions(filteredSuggestions);
      // setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      setTextAreaStyle(textAreaStyle + 17);
    }
  };

  const handleSuggestionClick = (suggestion: any) => {
    const currentContent = getValues("content");

    const mentionText = `${suggestion.firstName} ${suggestion.lastName}`;
    // const mentionText = `${suggestion.fullName}`;

    let newMentionTags = {
      [suggestion.id]: mentionText,
    };
    setMentionTags({ ...mentionTags, ...newMentionTags });
    const updatedContent = currentContent.replace(mentionRegex, mentionText);
    setValue("content", updatedContent);
    setShowSuggestions(false);
  };


  useMemo(() => {
    if (debouncedInputValue) {
      getUsers(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    const element = document.getElementById('auto-focus-textarea') as HTMLTextAreaElement | null;
    if (element) {
      element.focus();
    }
  }, []);
  
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    const url = `${process.env.REACT_APP_EVENT_PORTAL_URL}/events?createEvent=true`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div
      className={classNames("w-full relative", {
        [`${props.modalWidth}`]: props.modalWidth,
      })}
    >
      {isLoader && (
        <div className="absolute left-0 top-0 w-full h-[calc(100%--26px)] bg-gray-1 bg-opacity-80 flex items-center justify-center z-20 rounded-20 -mt-[14px]">
          <Loader className="pt-5" key="loader" />
        </div>
      )}
      <div className="w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="text-[#0B1221] font-medium text-[18px] leading-[26px] border-b-1 border-[#CDDCF2] pb-[9px] mx-[18px]">
            {props?.addNewStory == "story" ? "Create a story" : "Create a post"}
            </div>
            {/* <div className="border-b-[0.5px] border-[#EDF2F5]" /> */}
            <div className="flex flex-row items-center pt-[16px] pb-0 px-[21px]">
              <div className="mr-[5px] !w-[31px] !h-[31px]">
                <ConditionalLink to={props.detailsHref}>
                  <Avatar
                    src={props.avatarUrl}
                    // size="MEDIUM"
                    className={classNames(
                      "!w-[31px] !h-[31px] !rounded-[9px]",
                      {
                        "!object-contain border border-[#edf2f5] p-1":
                          props.isCompanySpotlightCreate ||
                          props.location === "company",
                      }
                    )}
                  />
                </ConditionalLink>
              </div>
              {props.isCompanySpotlightCreate &&
                props.location === "company" && (
                  <button
                    type="button"
                    className={classNames(
                      "menu focus:outline-none focus:shadow-solid flex flex-row border-[#7283A4] border-1 h-[31px] px-[20px] hover:bg-[#DFE5EB] justify-between items-center text-center rounded-[5px] text-[12px] leading-[16px] text-[#7283A4] font-semibold mr-2",
                      {
                        "bg-[#DFE5EB]": isDisplayName,
                        "bg-white": !isDisplayName,
                      }
                    )}
                  >
                    <span className="max-w-[185px] line-clamp-1 leading-[31px]">{name}</span>
                  </button>
                )}
              {props.location !== "company" && (
                <div className="mr-[5px] relative h-[31px]">
                  <button
                    type="button"
                    onClick={() => hasDisplayName(!isDisplayName)}
                    className={classNames(
                      "menu focus:outline-none focus:shadow-solid flex flex-row border-[#7283A4] border-1 h-[31px] sm:px-[20px] px-3 hover:bg-[#DFE5EB] justify-between items-center sm:text-center text-left sm:min-w-fit min-w-[145px] sm:max-w-fit max-w-[145px] rounded-[5px] text-[12px] leading-[16px] text-[#7283A4] font-semibold",
                      {
                        "bg-[#DFE5EB]": isDisplayName,
                        "bg-white": !isDisplayName,
                      }
                    )}
                  >
                    <span className="max-w-[185px] line-clamp-1 leading-[31px]">{name}</span>
                    <ArrowDownSvgIcon className="ml-[10px]" fillColor="#7283A4" />
                  </button>
                  {isDisplayName && <PostedBy />}
                </div>
              )}
              <div className="">
                {props.location !== "event" && (
                  <PrivacyDropDown
                    displayPrivacyStatusMenu={displayPrivacyStatusMenu}
                    setDisplayPrivacyStatusMenu={setDisplayPrivacyStatusMenu}
                    iconPrivacyOfPost={iconPrivacyOfPost}
                    privacyOfPost={privacyOfPost}
                    privacyOptionsFor={privacyOptionsFor}
                    handlePrivacyOfPost={handlePrivacyOfPost}
                  />
                )}
              </div>
            </div>
            <div
              className={classNames("h-[290px] overflow-y-scroll", {
                "h-[320px]": props.isSpotlightCreate,
                "h-[350px]":  isPollCreated,
              })}
            >
              <div className="mt-[23px] px-4">
                <textarea
                  id="auto-focus-textarea"
                  className={classNames(
                    "w-full text-[15px] resize-none leading-[17px] font-normal outline-none placeholder:text-[#020202]",
                    {
                      "sm:h-auto h-auto":
                        selectedImageFileUrl !== "" ||
                        selectedDocumentUrl !== "" ||
                        isPollCreated,
                      "h-auto": !(
                        selectedImageFileUrl !== "" ||
                        selectedDocumentUrl !== "" ||
                        isPollCreated
                      ),
                      "!h-auto": props.isSpotlightCreate,
                    }
                  )}
                  style={{
                    height: `${textAreaStyle < 190 ? textAreaStyle : 190}px`,
                  }}
                  placeholder={
                    props.isSpotlightCreate
                      ? "Add a title/description to your post"
                      : "Type here..."
                  }
                  // onKeyDown={(e: any) => {
                  //   e.target.style.height = 'inherit';
                  //   e.target.style.height = `${e.target.scrollHeight}px`;
                  //   // In case you have a limitation
                  //   e.target.style.height = `${Math.min(e.target.scrollHeight, 282)}px`;
                  // }}
                  {...register("content")}
                  onChange={handlePostChange} // Handle changes.
                  onKeyDown={handleKeyDown}
                />
                {showSuggestions && suggestions?.length! > 0 && (
                  <ul className="w-full md:max-w-[350px] bg-white z-20 origin-top-left absolute card-shadow py-[10px] px-2.5 border-b-1 border-gray-200 max-h-[220px] overflow-y-scroll">
                    {suggestions?.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        <div
                          className="h-[37px] flex items-center hover:bg-[#EDF2F5] m-[6px] rounded-10 cursor-pointer"
                          key={index}
                        >
                          <Avatar
                            src={`${process.env.REACT_APP_IMAGE_URL_PREFIX
                              }/${suggestion?.avatar!}`}
                            size="XSMALL"
                          />
                          <div className="flex items-center w-[430px] ml-[11px] tracking-wide truncate">
                            <div className="inline-block text-[15px] leading-5 font-semibold text-[#172746]">
                              {suggestion?.firstName} {suggestion?.lastName}
                            </div>
                            <div className="ml-1 text-[13px] font-light leading-[15px] text-[#18161C]">
                              • {suggestion?.company?.name}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div
                className={classNames("relative m-4 !mt-[16px]", {
                  "!mt-[17px]":  isPollCreated,
                })}
              >
                {(selectedImageFileUrl !== "" ||
                  selectedDocumentUrl !== "" ||
                  isPollCreated) && (
                    <div className={isPollCreated?"":"p-[10px] rounded-[10px] border"}>
                      {!isPollCreated && (
                        <button
                          type="button"
                          className="z-20 absolute right-0 mx-4 my-2 rounded-md border bg-close-icon hover:bg-close-hover bg-no-repeat border-transparent px-4 w-7 h-8 text-sm font-medium outline-none"
                          onClick={() => {
                            setSelectedImageFileUrl("");
                            setIsFileAttached(false);
                            setSelectedFile(undefined);
                            setSelectedDocumentUrl("");
                            setSelectedFileType("");
                            setIsPollCreated(false);
                            setPollAnswers([]);
                            if (imageUploadRef?.current)
                              imageUploadRef.current.value = "";
                            if (videoUploadRef?.current)
                              videoUploadRef.current.value = "";
                            if (documentUploadRef?.current)
                              documentUploadRef.current.value = "";
                          }}
                        ></button>
                      )}
                      {selectedFileType === "image" && (
                        <>
                          <img
                            src={selectedImageFileUrl}
                            alt=""
                            className={classNames(
                              "rounded-lg object-contain w-full max-h-[24rem]",
                              {
                                "object-cover": props.isSpotlightCreate,
                              }
                            )}
                          />
                        </>
                      )}
                      {selectedFileType === "video" && (
                        <>
                          <video controls>
                            <source src={selectedImageFileUrl} type="video/mp4" />
                          </video>
                        </>
                      )}
                      {selectedDocumentUrl !== "" && (
                        <div className="w-full bg-cover h-506">
                          <iframe
                            title={props.wallId}
                            className="bg-cover w-full h-506"
                            src={`${selectedDocumentUrl}`}
                          />
                        </div>
                      )}
                      {isPollCreated && (
                        <>
                          <ViewPoll
                            id={pollCreated?.id!}
                            question={pollCreated?.question! ?? pollCreatedQuestion}
                            status={""}
                            expireOption={pollCreated?.expireOption!}
                            expiredAt={""}
                            answers={pollAnswers}
                            isInteractive={false}
                            isPollCreate
                          />
                        </>
                      )}
                    </div>
                  )}
              </div>
            </div>

            {/**
             *
             *  Buttons
             *
             * */}
             <div className="border-[#DDDDDD] rounded-5 h-[51px] border-1 mx-[18px] flex items-center justify-between pl-[20px] pr-[12px]">
<div className="text-[15px] font-medium text-[#646464]">{props?.addNewStory === "story" ? "Add to your story" : "Add to your post"}    </div>

<div
              className={classNames(
                // "flex flex-row justify-between mt-4 px-[38px] mr-4 ml-4 rounded-[5px] h-[51px] bg-gray-6",
                             "flex flex-row gap-x-[15px]",
                {
                  "!justify-center gap-x-[43px]": props.isSpotlightCreate,
                }
              )}
            >
              {props.image && (
                <div
                  className={classNames(
                    "flex items-center relative rounded-5",
                    {
                      "hover-box cursor-pointer":
                        !isFileAttached && !isPollCreated,
                    }
                  )}
                  onClick={() =>
                    !isFileAttached &&
                    !isPollCreated &&
                    imageUploadRef.current?.click()
                  }
                >
                  {isFileAttached || isPollCreated ? (
                    <span className="flex">
                      <ImageIcon />
                    </span>
                  ) : (
                    <Tooltip
                      message="Add a photo"
                      className="!bg-[#2E2E2E] !rounded-10 !w-[112px] !h-[39px] flex items-center justify-center text-[15px] font-light"
                      position="!bottom-[22px] !-right-[31px]"
                    >
                      <span className="flex">
                        <ImageIcon />
                      </span>
                    </Tooltip>
                  )}
                  <input
                    type="file"
                    id="imageFile"
                    name="imageFile"
                    ref={imageUploadRef}
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => handleImageSelected(e.target?.files!)}
                  ></input>
                </div>
              )}
              {props.video && (
                <div
                  className={classNames(
                    "flex items-center relative rounded-5",
                    {
                      "hover-box cursor-pointer":
                        !isFileAttached && !isPollCreated,
                    }
                  )}
                  onClick={() =>
                    !isFileAttached &&
                    !isPollCreated &&
                    videoUploadRef?.current?.click()
                  }
                >
                  {isFileAttached || isPollCreated ? (
                    <span className="flex">
                      <VideoIcon />
                    </span>
                  ) : (
                    <Tooltip
                      message="Add a video"
                      className="!bg-[#2E2E2E] !rounded-10 !w-[112px] !h-[39px] flex items-center justify-center text-[15px] font-light"
                      position="!bottom-[22px] !-right-[31px]"
                    >
                      <span className="flex">
                        <VideoIcon />
                      </span>
                    </Tooltip>
                  )}
                  <input
                    type="file"
                    id="videoFile"
                    name="videoFile"
                    ref={videoUploadRef}
                    className="hidden"
                    accept="video/*"
                    onChange={(e) => handleImageSelected(e.target?.files!)}
                  />
                </div>
              )}
              {props.document && props?.addNewStory !== "story" && (
                <div
                  className={classNames(
                    "flex items-center relative rounded-5",
                    {
                      "hover-box cursor-pointer":
                        !isFileAttached && !isPollCreated,
                    }
                  )}
                  onClick={() =>
                    !isFileAttached &&
                    !isPollCreated &&
                    documentUploadRef?.current?.click()
                  }
                >
                  {isFileAttached || isPollCreated ? (
                    <span className="flex">
                      <DocumentIcon />
                    </span>
                  ) : (
                    <Tooltip
                      message="Add a pdf"
                      className="!bg-[#2E2E2E] !rounded-10 !w-[112px] !h-[39px] flex items-center justify-center text-[15px] font-light"
                      position="!bottom-[22px] !-right-[31px]"
                    >
                      <span className="flex">
                        <DocumentIcon />
                      </span>
                    </Tooltip>
                  )}
                  <input
                    type="file"
                    id="documentFile"
                    name="documentFile"
                    ref={documentUploadRef}
                    className="hidden"
                    // accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain, application/pdf"
                    accept="application/pdf"
                    onChange={(e) => handleDocumentSelected(e.target?.files!)}
                  />
                </div>
              )}
                   {props.document && props?.addNewStory !== "story" && (
                <div
                  className={classNames(
                    "flex items-center relative rounded-5",
                    {
                      "hover-box cursor-pointer":
                        !isFileAttached && !isPollCreated,
                    }
                  )}
                  onClick={handleClick}
                >
                  {isFileAttached || isPollCreated ? (
                    <span className="flex">
                      <EventIcon />
                    </span>
                  ) : (
                    <Tooltip
                      message="Create a event"
                      className="!bg-[#2E2E2E] !rounded-10 !w-[112px] !h-[39px] flex items-center justify-center text-[15px] font-light"
                      position="!bottom-[22px] !-right-[31px]"
                    >
                      <span className="flex">
                        <EventIcon />
                      </span>
                    </Tooltip>
                  )}
                </div>
              )}
              {props.poll  && props?.addNewStory !== "story" && (
                <div
                  className={classNames(
                    "flex items-center relative rounded-5",
                    {
                      "hover-box cursor-pointer":
                        !isFileAttached && !isPollCreated,
                    }
                  )}
                  onClick={() => {
                    !isFileAttached &&
                      !isPollCreated &&
                      setTogglePollModal(true);
                  }}
                >
                  {isFileAttached || isPollCreated ? (
                    <span className="flex">
                      <PollIcon />
                    </span>
                  ) : (
                    <Tooltip
                      message="Start a poll"
                      className="!bg-[#2E2E2E] !rounded-10 !w-[112px] !h-[39px] flex items-center justify-center text-[15px] font-light"
                      position="!bottom-[22px] !-right-[31px]"
                    >
                      <span className="flex">
                        <PollIcon />
                      </span>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
             </div>
        
            <div className="border-[#C5C5C5] border-b-1 w-[calc(100%-36px)] mt-[5px] m-auto"></div>
            <div className="flex flex-row mt-[6.5px]">
              <Button
                text={props?.addNewStory === "story" ? "Add Story" : "Post"} 
                className="bg-[#EDF2F5] text-[#000000] hover:text-white text-[15px] font-semibold w-full h-[40px] !rounded-[5px] hover:bg-blue-gray-2 mx-[18px] mb-2 tracking-wide"
                type="submit"
                // disabled={props?.addNewStory ? isMediaUpload : false}
              />
            </div>
          </div>
        </form>
      </div>
      <div>
        {togglePollModal && (
          <>
            <AlertModal
              onClose={onClosePollModal}
              isOpen={togglePollModal}
              className="w-576"
            >
              <CreatePoll onClose={onClosePollModal} />
            </AlertModal>
          </>
        )}
        {hasFileSizeError && (
          <AlertModal
            onClose={() => setHasFileSizeError(false)}
            isOpen={hasFileSizeError}
            className="w-576"
          >
            <>
              <div className="w-full h-full my-10 flex justify-center">
                <div>File size must be less than {fileSizeLimit} mb</div>
              </div>
              <div className="mt-4">
                <div className="flex flex-row justify-end px-3 pt-3 border-t-1 border-gray-16">
                  <div className="">
                    <Button
                      text="Ok"
                      type="submit"
                      className="rounded-5 inline-block text-sm font-semibold h-8 px-6 py-1.5 bg-blue hover:bg-blue-3 text-white w-28"
                      onClick={() => setHasFileSizeError(false)}
                    />
                  </div>
                </div>
              </div>
            </>
          </AlertModal>
        )}
      </div>
    </div>
  );
};

export default CreatePostModal;
