/* eslint-disable */
// video.tsx => Video player component for HLS streams for Panelist
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import classNames from 'classnames';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { VideoControlls } from '../../../../components/chime-video-call/video-controlls';
import { IAttendee } from '../../../../models/events-live/attendee';
import { IExpoBooth } from '../../../../models/expo';
import { IAppState } from '../../../../store';
import { expoEndMeeting, expoGuestCancel, expoManagerReject } from '../../../../store/expo/actions';
import { useCurrentUser } from '../../../../utils/hooks';
import { SpeakerRooms } from '../rooms-popup';
import { ExpoSponsors } from './expo/expo-sponsors';
import { VideoCall } from './video-call';
import VideoFooter from './video-footer';
import VideoHeader from './video-header';
import { getCompanyName } from 'src/utils';

// Interface for the HLS.js Video component in order to call the seek method to skip around
interface VideoControls {
  seek(time: number): void;
}

// Props that can be received by this component, work in progress

type VideoProps = {
  url: string;
  title: string;
  logoURL: string;
  expoData: IExpoBooth[];
  screenMode?: 'small' | 'large' | 'hidden' | undefined;
  setScreenMode(mode: 'small' | 'large' | 'hidden' | undefined): void;
  startTime: any;
  endTime: any;
  attendees?: IAttendee[];
  setPlayBackVideo?: () => void;
};

export const VideoPanel = (props: VideoProps) => {
  // Ref to the HLS player component
  const videoPlayerRef = useRef<VideoControls>(null);
  const navigate = useNavigate();
  const user = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();

  // Various state variables
  const [isPlaying, setIsPlaying] = useState(false);
  const [isGuestCalling, setIsGuestCalling] = useState(false);
  const [duration, setDuration] = useState(0.0);
  const [position, setPosition] = useState(0.0);
  const [volume, setVolume] = useState(60);
  const [fullScreen, setFullScreen] = useState(false);
  const [pip, setPip] = useState(false);
  const [error, setError] = useState('');
  const [speakerRooms, setSpeakerRooms] = useState();
  const [isEventModerator, setIsEventModerator] = useState<boolean>(false);

  // State to determine which player view is shown
  const [videoStatus, setVideoStatus] = useState<'sessions' | 'expo'>('sessions');
  const livePolls = useSelector((state: IAppState) => state.livePoll);
  const { event, eventModerators } = useSelector((state: IAppState) => state.liveEvent);

  // Expo call 
  const meetingManager = useMeetingManager();
  const expoChimeInvitation = useSelector((state: IAppState) => state.chime.chimeInvitation);
  const expoGuestCall = useSelector((state: IAppState) => state.chime.guestCall);
  const expoCall = useSelector((state: IAppState) => state.chime.expoCall);

  const dispatch = useDispatch();

  useEffect(() => {
    const currentMode = searchParams.get('mode');
    if (currentMode && currentMode !== videoStatus) {
      if (currentMode === "sessions") {
        setVideoStatus("sessions");
        setSearchParams({});
      } else if (currentMode === "expo") {
        setVideoStatus("expo")
      }
    }
  }, [searchParams])

  useEffect(() => {
    const currentMode = searchParams.get('mode');
    if (currentMode !== videoStatus && videoStatus === "sessions") {
      setSearchParams({});
    }

  }, [videoStatus])

  // URL error handling
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(props.url);
        if (response.status !== 200) {
          setError('Not valid URL');
        } else {
          setError('');
        }
      } catch (err) {
        setError('Not valid URL');
      }
    })();
  }, [props.url]);
  useEffect(() => {
    const rooms: any = {};
    event?.rooms.map(room => room.sessions.map(session => session.eventSpeakers.map((speaker: any) => {
      if (speaker.userId === user?.id) {
        rooms[room.id] = room;
      }
      return speaker;
    })));
    setSpeakerRooms(rooms);

    const currentUserModerator: any = eventModerators.map(event => event?.user).find(mod => mod.id === user.id)
    setIsEventModerator(!!currentUserModerator);
  }, [event, eventModerators]);

  // Renders out the header, player and footer with associated props
  // eslint-disable-next-line no-console
  return (
    <div className={classNames('relative rounded-xl overflow-hidden', {
      'flex-1': props.screenMode === 'small',
      'w-full': props.screenMode !== 'small'
    })}>
      <VideoHeader
        title={props.title}
        disableExpo={!event?.eventExpo?.expo}
        logoURL={props.logoURL}
        videoStatus={videoStatus}
        eventSlug={event?.slug!}
        hostName={getCompanyName(event)}
        setVideoStatus={(stat: 'sessions' | 'expo') => setVideoStatus(stat)}
      />
      <div
        style={
          props.screenMode === 'small'
            ? {
              backgroundImage: 'linear-gradient(to bottom, #1f283e, #222d46)',
              height: '33.8rem',
            }
            : {
              backgroundImage: 'linear-gradient(to bottom, #1f283e, #222d46)',
              height: '40.75rem',
            }
        }
        className="h-full bg-slate-600 text-center justify-center relative"
      >
        <VideoCall>
          {videoStatus === 'sessions' ? (
            <SpeakerRooms            
              rooms={event?.rooms!} 
              onClick={(roomId: string) => {
                if(isEventModerator) {
                  navigate(`/event/${event?.id}/${roomId}/moderator`)
                } else if(speakerRooms?.[roomId]) {
                  navigate(`/event/${event?.id}/${roomId}/speaker`)
                } else {
                  navigate(`/event/${event?.id}/${roomId}/session`)
                }
                
              }} 
            />
          ) : (
            <ExpoSponsors
              expoData={props.expoData}
              screenMode={props.screenMode}
            />
          )}
        </VideoCall>
      </div>
      {/* Move the video controls and expo controls inside each component */}
      {/* Controlls shown for Expo Calls */}
      <VideoControlls
        disableScreenSharing={true}
        onChange={(status: string) => {
          if (status === "show") {
            setVideoStatus("expo");
          } else setVideoStatus("sessions")
        }}
        onCallHangup={() => {
          if (expoGuestCall?.callId) {
            // eslint-disable-next-line no-unused-expressions
            expoChimeInvitation?.callId ? dispatch(expoEndMeeting()) : dispatch(expoGuestCancel());
          } else if (expoCall?.callId) {
            // eslint-disable-next-line no-unused-expressions
            expoChimeInvitation?.callId ? dispatch(expoEndMeeting()) : dispatch(expoManagerReject());
          }
          if (meetingManager) meetingManager.leave();
        }} />
      {
        videoStatus === "sessions" &&
        <VideoFooter
          play={() => setIsPlaying(true)}
          pause={() => setIsPlaying(false)}
          seek={(time: number) => videoPlayerRef.current?.seek(time)}
          isPlaying={isPlaying}
          duration={duration}
          position={position}
          volume={volume}
          errorURL={error}
          videoStatus={videoStatus}
          setVolume={(vol: number) => setVolume(vol)}
          fullScreen={fullScreen}
          setFullScreen={() => setFullScreen(!fullScreen)}
          setPip={() => setPip(!pip)}
          screenMode={props.screenMode}
          setScreenMode={(mode: 'small' | 'large' | 'hidden') => props.setScreenMode(mode)}
          showParticipant={() => setIsGuestCalling(!isGuestCalling)}
        />
      }
    </div >
  );
};

export default VideoPanel;
