import { Reducer } from 'redux';

import { IUpcomingEventSpeakersState } from '../../../models/event/UpcomingEventSpeakersState';
import { UpcomingEventSpeakersActions } from './actions';
import { UpcomingEventSpeakersActionTypes } from './types';

const initialState: IUpcomingEventSpeakersState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const UpcomingEventSpeakersReducer: Reducer<
  IUpcomingEventSpeakersState,
  UpcomingEventSpeakersActions
> = (state = initialState, action) => {
  switch (action.type) {
    case UpcomingEventSpeakersActionTypes.EVENT_SPEAKERS_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case UpcomingEventSpeakersActionTypes.EVENT_SPEAKERS_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case UpcomingEventSpeakersActionTypes.EVENT_SPEAKERS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
