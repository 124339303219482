import { Stack, Container, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { IEvent } from 'src/models/event/event';

type OverviewProps = {
  title?: string;
  subTitle?: string;
}

export const OverviewSection = ({ title, subTitle }: OverviewProps) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  return (
    <Container
      id="overview"
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        maxWidth: '100%',
        p: 0,
        pt: '0px',
        pb: '10px',
        pl: '15px !important',
        pr: '15px !important',
        '@media (min-width: 768px)': { maxWidth: '750px' },
        '@media (min-width: 992px)': { maxWidth: '970px' },
        '@media (min-width: 1200px)': { maxWidth: '1170px' },
        '@media (min-width: 1280px)': { maxWidth: '1260px' },
      }}
    >
      <Stack
        flexDirection="column"
        sx={{
          px: '70px',
          '@media (max-width: 992px)': { px: '0px' },
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: '36px',
            mb: '30px',
            color: '#ffffff',
            lineHeight: '48px',
            textAlign: 'center'
          }}
          className='bold-lato-font'
        >
          {event?.theme}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: '20px',
            mb: '10px',
            color: '#ffffff',
            lineHeight: '32px',
            textAlign: 'center'
          }}
          className='normal-lato-font'
        >
          {event?.overview && parse(DOMPurify.sanitize(event?.overview))}
        </Typography>
      </Stack>
    </Container>
  );
};
