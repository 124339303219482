import { Container } from '@mui/material';

const WebsiteEventQuickLinks = () => {
  const companyLinksList = [
    { name: 'About Us', link: 'https://www.box.com/about-us' },
    { name: 'Leadership', link: 'https://www.box.com/about-us/leadership' },
    { name: 'ESG', link: 'https://www.box.com/about-us/esg' },
    { name: 'Press', link: 'https://www.box.com/about-us/press' },
    { name: 'Box Blog', link: 'https://blog.box.com/' },
    { name: 'Investor Relations', link: 'https://www.boxinvestorrelations.com/home/default.aspx' },
    { name: 'Box.org', link: 'https://www.box.org/' },
    { name: 'Careers', link: 'https://careers.box.com/' },
    { name: 'Partners', link: 'https://www.box.com/partners' },
    { name: 'Legal', link: 'https://www.box.com/legal' },
  ];

  const casesLeftLinks = [
    { name: 'Collaboration', link: 'https://www.box.com/collaboration' },
    { name: 'Cloud Storage', link: 'https://www.box.com/cloud-storage' },
    { name: 'Mobility', link: 'https://www.box.com/mobility' },
    { name: 'Security', link: 'https://www.box.com/security-compliance' },
    { name: 'Box for ECM', link: 'https://www.box.com/content-management/enterprise' },
    { name: 'Send Large Files Fast', link: 'https://www.box.com/send-large-files-fast' },
    { name: 'Cloud Content Management', link: 'https://www.box.com/cloud-content-management' },
  ];

  const casesReftLinks = [
    { name: 'Remote Work', link: 'https://www.box.com/remote' },
    { name: 'File Sharing', link: 'https://www.box.com/file-sharing' },
    { name: 'Document Management', link: 'https://www.box.com/collaboration/document-management' },
    { name: 'Project Management', link: 'https://www.box.com/project-management-software' },
    { name: 'FTP Alternative', link: 'https://www.box.com/cloud-storage/ftp-alternative' },
    { name: 'File Transfer', link: 'https://www.box.com/file-transfer' },
    { name: 'Cloud Backup', link: 'https://www.box.com/cloud-backup' },
  ];

  const serviceLinks = [
    { name: 'Product Support', link: 'https://www.box.com/customer-success/support-services' },
    { name: 'Training', link: 'https://box.csod.com/client/box/default.aspx' },
    { name: 'Community', link: 'https://support.box.com/hc/en-us/community/topics' },
    { name: 'Contact Us', link: 'https://www.box.com/about-us/contact-us' },
    { name: ' 1.877.729.4269', link: 'https://www.box.com/quote' },
  ];

  const resourcesLinks = [
    { name: 'GDPR Compliance', link: 'https://www.box.com/gdpr' },
    { name: 'GxP Validation', link: 'https://www.box.com/gxp' },
    { name: 'Demos', link: 'https://www.box.com/events/live-demos' },
    { name: 'Downloads', link: 'https://www.box.com/resources/downloads' },
    { name: 'Resource center', link: 'https://www.box.com/resources' },
  ];

  const sitesLinks = [
    { name: 'United States (English)', link: 'https://www.box.com/en-us/home' },
    { name: 'United Kingdom (English)', link: 'https://www.box.com/en-gb/home' },
    { name: 'India (English)', link: 'https://www.box.com/en-in/home' },
    { name: 'Netherlands (English)', link: 'https://www.box.com/en-nl/home' },
    { name: 'Sweden (English)', link: 'https://www.box.com/en-se/home' },
    { name: 'Australia (English)', link: 'https://www.box.com/en-au/home' },
    { name: 'Canada (English)', link: 'https://www.box.com/en-ca/home' },
    { name: 'France (Français)', link: 'https://www.box.com/fr-fr/home' },
    { name: 'Deutschland (Deutsch)', link: 'https://www.box.com/de-de/home' },
    { name: 'Italia (Italiano)', link: 'https://www.box.com/it-it/home' },
    { name: '日本 (日本語)', link: 'https://www.box.com/ja-jp/home' },
    { name: '한국 (한국어)', link: 'https://www.box.com/ko-kr/home' },
    { name: 'América Latina (Español)', link: 'https://www.box.com/es-419/home' },
  ];

  const miniLeftList = [
    { name: 'Home', link: 'https://www.box.com/home' },
    { name: 'Careers', link: 'https://careers.box.com/' },
    { name: 'Partners', link: 'https://www.box.com/partners/programs' },
    { name: 'Events', link: 'https://www.box.com/events' },
    { name: 'Pricing', link: 'https://www.box.com/pricing' },
    { name: 'Downloads', link: 'https://www.box.com/resources/downloads' },
    { name: 'Resources', link: 'https://www.box.com/resources' },
  ];

  const miniRightList = [
    { name: 'Service & Support', link: 'https://www.box.com/node/911' },
    { name: 'Training', link: 'https://box.csod.com/client/box/default.aspx' },
    { name: 'Tech Support', link: 'https://community.box.com/t5/Contact-Support/ct-p/BoxSupport' },
    { name: 'Contact Us', link: 'https://www.box.com/about-us/contact-us' },
    { name: 'Blogs', link: 'https://blog.box.com/' },
    { name: 'Community', link: 'https://community.box.com/t5/Box-Community/ct-p/English' },
    { name: 'Developers', link: 'https://developer.box.com/' },
  ];

  return (
    <Container
        maxWidth={false}
        sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        maxWidth: '100%',
        p: 0,
        pt: '2.5rem',
        pb: '2.5rem',
        pl: '15px !important',
        pr: '15px !important',
        '@media (min-width: 768px)': { maxWidth: '100%', pl: '2.125rem', pr: '2.125rem' },
        '@media (min-width: 992px)': { maxWidth: '100%', pl: '2.125rem', pr: '2.125rem' },
        '@media (min-width: 1200px)': { maxWidth: '100%', pl: '2.125rem', pr: '2.125rem' },
        '@media (min-width: 1440px)': { maxWidth: '74.4680851064%' },
        }}
    >
      <div className="w-full">
        <div className="flex">
          <div className="w-full">
            <div className="w-full">
              <div className="w-full lg:flex flex-col justify-center items-center hidden">
                {/* second row    */}
                <div className="w-full flex  justify-between items-start">
                  <div className="flex flex-col w-[14.2857142857%]">
                    <div className="text-[16px] text-white pb-[1.25rem] mb-[1rem] border-b border-[rgba(255,255,255,0.1)] bold-lato-font">
                      Company
                    </div>
                    {companyLinksList?.map((item, index) => (
                      <a
                        href={`${item.link}`}
                        key={index}
                        className="text-[14px] font-[400] text-[#8ea6b2] hover:underline leading-[28px] normal-lato-font"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>

                  <div className="flex flex-col w-[31.4285714286%]">
                    <div className="text-[16px] text-white pb-[1.25rem] mb-[1rem] border-b border-[rgba(255,255,255,0.1)] bold-lato-font">
                      Use Cases
                    </div>
                    <div className="flex flex-row gap-x-[9.0909090909%]">
                      <div className="flex flex-col">
                        {casesLeftLinks?.map((item, index) => (
                          <a
                            href={`${item.link}`}
                            key={index}
                            className="text-[14px] font-[400] text-[#8ea6b2] hover:underline leading-[28px] normal-lato-font"
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                      <div className="flex flex-col">
                        {casesReftLinks?.map((item, index) => (
                          <a
                            href={`${item.link}`}
                            key={index}
                            className="text-[14px] font-[400] text-[#8ea6b2] hover:underline leading-[28px] normal-lato-font"
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* <div className="flex flex-col">
                    <div className="text-[16px] text-white pb-[1.25rem] mb-[1rem] border-b border-[rgba(255,255,255,0.1)]">
                      Services
                    </div>
                    {serviceLinks?.map((item, index) => (
                      <a
                        href={`${item.link}`}
                        key={index}
                        className="text-[13px] font-[400] text-[#8ea6b2] hover:underline leading-[28px]"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div> */}

                  <div className="flex flex-col w-[14.2857142857%]">
                    <div className="text-[16px] text-white pb-[1.25rem] mb-[1rem] border-b border-[rgba(255,255,255,0.1)] bold-lato-font">
                      Service & Support
                    </div>
                    {serviceLinks?.map((item, index) => (
                      <a
                        href={`${item.link}`}
                        key={index}
                        className="text-[14px] font-[400] text-[#8ea6b2] hover:underline leading-[28px] normal-lato-font"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>

                  <div className="flex flex-col w-[14.2857142857%]">
                    <div className="text-[16px] text-white pb-[1.25rem] mb-[1rem] border-b border-[rgba(255,255,255,0.1)] bold-lato-font">
                      Resources
                    </div>
                    {resourcesLinks?.map((item, index) => (
                      <a
                        href={`${item.link}`}
                        key={index}
                        className="text-[14px] font-[400] text-[#8ea6b2] hover:underline leading-[28px] normal-lato-font"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>

                  <div className="flex flex-col w-[14.2857142857%]">
                    <div className="text-[16px] text-white pb-[1.25rem] mb-[1rem] border-b border-[rgba(255,255,255,0.1)] bold-lato-font">
                      International sites
                    </div>
                    {sitesLinks?.map((item, index) => (
                      <a
                        href={`${item.link}`}
                        key={index}
                        className="text-[14px] font-[400] text-[#8ea6b2] hover:underline leading-[28px] normal-lato-font"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-row justify-center items-center lg:hidden">
                <div className="flex flex-col w-[37%]">
                  {miniLeftList?.map((item, index) => (
                    <a
                      href={`${item.link}`}
                      key={index}
                      className="text-[13px] font-[400] text-[#8ea6b2] hover:underline leading-[17px] my-[0.875rem]"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="flex flex-col w-[37%]">
                  {miniRightList?.map((item, index) => (
                    <a
                      href={`${item.link}`}
                      key={index}
                      className="text-[14px] font-[400] text-[#8ea6b2] hover:underline leading-[17px] my-[0.875rem] normal-lato-font"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WebsiteEventQuickLinks;
