/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RegistrationStepForm from 'src/mui/pages/event/reg-dialog-flow';
// import { delAuthToken } from 'src/utils';
// import { UserSignUpActionTypes } from 'src/store/user/signup/types';
// import { resetUserEmail } from 'src/store/checkUserEmail';
// import { UpcomingEventActionTypes } from 'src/store/events/upcoming-event/types';
// import { setRegistered } from 'src/store/event-website';
// import { unsetUser } from 'src/store/auth';
// import { useSurveyContext } from 'src/mui/providers/GeneralContext';
import { IEvent } from '../../../models/user-events';
import { Logo } from '../../../shared-components/V2/Logo';
import { IAppState } from '../../../store';
// import { getUpcomingEvent } from '../../../store/events/upcoming-event/actions';
import { useCurrentUser } from '../../../utils/hooks';

export const hideScrollbarY = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

interface IMenuItem {
  menuItemText: string;
  href: string;
  isSelected: boolean;
}

interface INavbarProps {
  menuItems: IMenuItem[];
  // eslint-disable-next-line react/no-unused-prop-types
  showCreateEventButton: boolean;
  setActiveMenu: Function;
  activeMenu: string;
}

export const WebsiteEventMainNavbar = (props: INavbarProps) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const user = useCurrentUser();
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const audienceStatus = useSelector((state: IAppState) => state?.upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus);
  const { registered } = useSelector(
    (state: IAppState) => state.website
  );

  const { upcomingEvent } = useSelector(
    (state: IAppState) => state
  );

  const isRegistered = upcomingEvent?.value?.data?.attendeeStatus?.isRegistered;

  // const { resetProtectedPasswordError } = useSurveyContext();
  const getValidUrl = (url: string | undefined) => {
    try {
      const newUrl = new URL(url!);
      return newUrl;
    } catch (error) {
      return `http://${url}`;
    }
  };

  const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   if (user) {
  //     dispatch(getUpcomingEvent(event.id));
  //   }
  // }, [dispatch, event.id, user]);

  const clamp = (value: number) => Math.max(0, value);

  const isBetween = (value: number, floor: number, ceil: number) => value >= floor && value <= ceil;

  // const resetForm = () => {
  //   sessionStorage.removeItem('userName');
  //   // changePage(0);
  //   dispatch({
  //     type: UserSignUpActionTypes.GO_TO_FIRST_STEP,
  //   });
  //   dispatch(resetUserEmail());
  //   dispatch(setRegistered(null));
  //   dispatch(unsetUser());

  //   dispatch({
  //     type: UpcomingEventActionTypes.RESET_UPCOMING_EVENT_LOADING,
  //     payload: {}
  //   });

  //   dispatch({
  //     type: UserSignUpActionTypes.RESET_SIGNUP_USER,
  //     payload: {}
  //   });
  // };

  // const signoutClickHandler = () => {
  //   delAuthToken();
  //   resetForm();
  //   resetProtectedPasswordError();
  // };

  // hooks
  const useScrollspy = (ids: string[], offset: number = 0) => {
    const [activeId, setActiveId] = useState('');
    useLayoutEffect(() => {
      const listener = () => {
        const scroll = window.pageYOffset;
        const position = ids.map((id: any) => {
          const element = document.getElementById(id);
          if (!element) return { id, top: -1, bottom: -1 };
          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { id, top, bottom };
        }).find(({ top, bottom }) => isBetween(scroll, top, bottom));
        setActiveId(position?.id || '');
      };

      listener();

      window.addEventListener('resize', listener);
      window.addEventListener('scroll', listener);

      return () => {
        window.removeEventListener('resize', listener);
        window.removeEventListener('scroll', listener);
      };
    }, [ids, offset]);
    return activeId;
  };
  const ids = props.menuItems.map((item: IMenuItem) => item.menuItemText);
  const activeId = useScrollspy(ids, 100); // 54 is navigation height

  useEffect(() => {
    props.setActiveMenu(activeId);
  }, [activeId, props]);

  // const handleRegistration = () => {
  //   navigate(`/event/${event.slug}/spectre/vipreg`);
  // };

  const handleRegistration = () => {
    if (!isRegistered) setShowModal(true);
    // if (!registered && event?.isTicketed && user) setOpenTicketModal(true);

    if (user && isRegistered) navigate(`/event/${event?.slug}/discussion`);
    if (
      user
      && !isRegistered
      && event?.type === 'invitation'
      && audienceStatus === 'accepted'
    ) { navigate(`/event/${event?.slug}/discussion`); }
  };

  // const handleLobby = () => {
  //   if (user && registered === false && event?.type === 'invitation') navigate(`/event/${event?.slug}/discussion`);
  // };

  return (
    <nav className="bg-[#2d2d2d] w-full z-50 fixed left-0 top-0 flex items-center justify-center px-2.5 py-[5px]">
      <div className="container max-w-[1317px]">
        <div className="lg:flex items-center justify-between">
          <div className="flex items-center lg:justify-start justify-center lg:py-0 py-1">
            <div className="flex items-center flex-row">
              <a href={`${getValidUrl(event?.company?.website)}`} target="_blank" rel="noopener noreferrer">
                <Logo
                  src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event?.company?.logo}`}
                  className="w-[40px] h-[40px] object-contain p-1 !rounded-[7px]"
                />
              </a>
            </div>
          </div>
          {event && (
            <>
              <div className="lg:flex items-center lg:justify-start justify-center">
                <div className="md:border md:border-[#3a3a3a] flex items-center lg:justify-start justify-center h-[30px] rounded-[8px] mr-3">
                  {props.menuItems
                    && props.menuItems.length > 0
                    && props.menuItems.map((menuItem, i) => (
                      <React.Fragment key={`menu-item-${i}`}>
                        <a
                          className={classNames(
                            'text-[13px] font-medium h-[28px] md:w-[110px] md:px-2.5 px-2 rounded-[6px] text-[#ffffffb3] flex leading-[28px] justify-center helvetica-neue-65-medium',
                            {
                              'font-normal bg-[#3a3a3a]': menuItem.menuItemText === props.activeMenu,
                              'mr-[0px]': i === props.menuItems.length - 1,
                            }
                          )}
                          href={menuItem.href}
                          key={`sub-nav-${i}`}
                          onClick={() => props.setActiveMenu(menuItem.menuItemText)}
                        >
                          {menuItem.menuItemText === 'Banner' ? 'Home' : menuItem.menuItemText}
                        </a>
                        <div
                          key={`menu-${i}`}
                          className={classNames(
                            'w-[1px] h-[21px] border-r border-[#3a3a3a]',
                            {
                              hidden: (i === props.menuItems.length - 1),
                              'border-[#2d2d2d]': menuItem.menuItemText === props.activeMenu
                            }
                          )}
                        />
                      </React.Fragment>
                    ))}
                </div>
              </div>
              <div className="flex lg:justify-start justify-center lg:py-0 py-2">
                {
                  // event && registered === false && event?.type === 'invitation' && audienceStatus === 'pending' ? (
                  //   <button
                  //     type="button"
                  //     // eslint-disable-next-line max-len
                  //     className={classNames('px-[12px] leading-[18px] text-black bg-white hover:bg-black hover:text-white tracking-wide rounded-[6px] w-[170px] h-[28px] text-[14px] helvetica-neue-65-medium')}
                  //     onClick={() => handleLobby()}
                  //   >
                  //     Event Lobby
                  //   </button>
                  // )
                  //   :

                  isRegistered ? (
                    <>
                      {' '}
                      <button
                        type="button"
                        // eslint-disable-next-line max-len
                        className={classNames('px-[12px] leading-[18px] text-black bg-white hover:bg-black hover:text-white tracking-wide rounded-[6px] w-[170px] h-[28px] text-[14px] helvetica-neue-55-roman font-semibold')}
                        // onClick={() => setShowModal(true)}
                        // onClick={signoutClickHandler}
                        onClick={() => setShowModal(true)}
                      >
                        { event?.type === 'invitation' && audienceStatus === 'rejected' ? 'Register Now' : 'Portal'}
                      </button>
                    </>

                  )

                    : (
                      <button
                        type="button"
                        // eslint-disable-next-line max-len
                        className={classNames('px-[12px] leading-[18px] text-black bg-white hover:bg-black hover:text-white tracking-wide rounded-[6px] w-[170px] h-[28px] text-[14px] helvetica-neue-55-roman font-semibold')}
                        onClick={() => handleRegistration()}
                      >
                        Register Now
                      </button>
                    )
                }
              </div>
            </>
          )}
        </div>
        {showModal && (
          <RegistrationStepForm
            eventId={event?.id!}
            user={user}
            open={showModal}
            registered={registered!}
            eventInvitationStatus={audienceStatus!}
            eventType={event?.type!}
            setOpenForm={() => setShowModal(false)}
          />
        )}
      </div>
    </nav>
  );
};
