export interface AgendaSliderSvgIconProps {
    fillColor?: string;
    arrowColor?: string;
}
export const AgendaSliderSvgIcon = ({ fillColor, arrowColor }: AgendaSliderSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#203c6e"
    return <svg xmlns="http://www.w3.org/2000/svg" width="36.496" height="36.496" viewBox="0 0 36.496 36.496">
    <g id="Group_13591" data-name="Group 13591" transform="translate(-1180.254 -4089.031)">
      <path id="Path_10822" data-name="Path 10822" d="M18.248,0A18.248,18.248,0,1,1,0,18.248,18.248,18.248,0,0,1,18.248,0Z" transform="translate(1180.254 4089.031)" fill={iconColor}/>
      <g id="Component_149_2966" data-name="Component 149 – 2966" transform="translate(1193.339 4117.037) rotate(-90)">
        <path id="Polygon_9" data-name="Polygon 9" d="M8.673,1.219a1,1,0,0,1,1.655,0L17.94,12.438A1,1,0,0,1,17.113,14H1.887a1,1,0,0,1-.827-1.562Z" transform="translate(19 14) rotate(180)" fill={arrowColor ? arrowColor : "#fff"}/>
      </g>
    </g>
  </svg>
};