import React, { FC } from 'react';
import { UserNavbar } from '../components';

interface IDefaultLayoutProps {
  hero?: JSX.Element;
  leftPanel: JSX.Element;
  rightPanel: JSX.Element;
  children: JSX.Element;
  // footer: JSX.Element;
}
export const DefaultLayout: FC<IDefaultLayoutProps> = ({
  hero,
  leftPanel,
  rightPanel,
  children
  // footer
}) => (
  <div className="bg-gray-1 h-full">
    <UserNavbar />
    <div>
      <div className="container mx-auto max-w-[100%] xl:max-w-[1258px] lg:px-3 xl:px-0">
        {hero}
        <div className="md:flex flex-row relative md:overflow-hidden">
          <div className="lg:flex hidden flex-col md:w-[25%] xl:w-[285px] fixed">{leftPanel}</div>
          <div className="md:m-auto xl:ml-[285px] lg:ml-[calc(25%-3px)] md:ml-0">{children}</div>
          <div className="lg:flex hidden flex-col md:w-[25%] xl:w-[315px] md:ml-[calc(75%-16px)] xl:ml-[941px] fixed">{rightPanel}</div>
          {/* #mycodechg joe code changes
        <div className="flex lg:flex-row flex-col gap-4">
          <div className="flex flex-col lg:w-1/4 md:w-full w-11/12 md:m-0 m-auto">{leftPanel}</div>
          <div className="mb-8 lg:w-1/2 md:w-full w-11/12 md:m-0 m-auto">{children}</div>
          <div className="flex flex-col lg:w-1/4 md:w-full w-11/12 md:m-0 m-auto">{rightPanel}</div> */}
        </div>
        {/* {footer} */}
      </div>
    </div>
  </div>
);

export default DefaultLayout;
