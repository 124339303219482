import { Stack, Container, Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { IEvent } from 'src/models/event/event';
import classNames from 'classnames';
import { getSpeakerCompanyName } from 'src/utils';
import { Logo } from 'src/shared-components/V2/Logo';

type SpeakerProps = {
  title?: string;
  subTitle?: string;
}

export const SpeakerSection = ({ title, subTitle }: SpeakerProps) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const speakers = useSelector(
    (state: IAppState) => state.website.event!.speakers
  );

  if (!speakers?.length) return null;
  return (
    <Container
      id="speakers"
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        maxWidth: '100%',
        p: 0,
        pt: '50px',
        pb: '50px',
        pl: '15px !important',
        pr: '15px !important',
        '@media (min-width: 768px)': { maxWidth: '750px' },
        '@media (min-width: 992px)': { maxWidth: '970px' },
        '@media (min-width: 1200px)': { maxWidth: '1170px' },
        '@media (min-width: 1280px)': { maxWidth: '1260px' },
      }}
    >
      <Stack
        flexDirection="column"
        sx={{
          backgroundColor: 'rgba(255 255 255 / 20%)',
          padding: '40px 50px 50px',
          borderRadius: '16px',
          overflow: 'hidden',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <div
          className={classNames('w-full', {
            hidden: !(speakers && speakers.length > 0),
          })}
        >
          <div className="w-full">
            <Typography
              variant="body2"
              sx={{
                fontSize: '36px',
                mb: '40px',
                color: '#ffffff',
                lineHeight: '48px',
                textAlign: 'center',
              }}
              className='bold-lato-font'
            >
              Who's In the Room
            </Typography>
            <div className="flex flex-wrap justify-center items-center md:gap-x-[30px] md:gap-y-[40px] gap-y-[15px] w-full px-[0px] md:px-[0px]">
              {speakers.map(item => (
                <div className="flex flex-col items-center w-[260px] h-255px]">
                  <Logo
                    src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item.eventSpeakers?.[0]?.photo}`}
                    className="w-[170px] h-[170px] object-cover rounded-[40px] mb-[9px] bg-white"
                    defaultLogo="user"
                  />
                  <div className="text-[15px] leading-[21px] text-[#ffffff] font-semibold mb-1 text-center bold-lato-font">
                    {item.eventSpeakers?.[0]?.firstName}
                    {' '}
                    {item.eventSpeakers?.[0]?.lastName}
                  </div>
                  <div className="text-[13px] leading-[19px] text-[#ffffffa6] font-light mb-1 text-center line-clamp-2 normal-lato-font">
                    {item.eventSpeakers?.[0]?.jobTitle}
                  </div>
                  <div className="text-[14px] leading-[20px] text-[#ffffff] font-medium text-center bold-lato-font">
                    {getSpeakerCompanyName(item.eventSpeakers?.[0])}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Stack>
    </Container>
  );
};
