import { SVGProps } from 'react';

function RegoPageSaveFav(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props;
    return (
        <svg
            id="Icon-Set"
            xmlns="http://www.w3.org/2000/svg"
            width="20.574"
            height="20.574"
            viewBox="0 0 20.574 20.574"
            style={style}
        >
            <path
                id="save-floppy"
                d="M164.216,521.429a.643.643,0,0,0,.643-.643v-2.572a.643.643,0,0,0-1.286,0v2.572a.643.643,0,0,0,.643.643ZM171.288,533A1.286,1.286,0,0,1,170,534.288H154.572A1.286,1.286,0,0,1,153.286,533V517.572a1.286,1.286,0,0,1,1.286-1.286h1.286v6.429A1.286,1.286,0,0,0,157.144,524h10.287a1.286,1.286,0,0,0,1.286-1.286v-6.429H170a1.286,1.286,0,0,1,1.286,1.286V533Zm-14.145-16.717h10.287v5.786a.643.643,0,0,1-.643.643h-9a.643.643,0,0,1-.643-.643v-5.786ZM170,515H154.572A2.572,2.572,0,0,0,152,517.572V533a2.572,2.572,0,0,0,2.572,2.572H170A2.572,2.572,0,0,0,172.574,533V517.572A2.572,2.572,0,0,0,170,515Z"
                transform="translate(-152 -515)"
                fillRule="evenodd"
            />
        </svg>
    );
}

export default RegoPageSaveFav;
