import { Stack, Container, Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { IEvent } from 'src/models/event/event';
import WebsiteEventAgendaLobby from './website-event-agenda-lobby';

type AgendaProps = {
  title?: string;
  subTitle?: string;
}

export const AgendaSection = ({ title, subTitle }: AgendaProps) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  return (
    <Container
      id="agenda"
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        maxWidth: '100%',
        p: 0,
        pt: '50px',
        pb: '50px',
        pl: '15px !important',
        pr: '15px !important',
        '@media (min-width: 768px)': { maxWidth: '750px' },
        '@media (min-width: 992px)': { maxWidth: '970px' },
        '@media (min-width: 1200px)': { maxWidth: '1170px' },
        '@media (min-width: 1280px)': { maxWidth: '1260px' },
      }}
    >
      <Stack
        flexDirection="column"
        sx={{
          
        }}
      >
        {/* <Typography
          variant="body2"
          sx={{
            fontSize: '36px',
            mb: '40px',
            color: '#ffffff',
            lineHeight: '48px',
            fontFamily: 'Poppins-500',
            textAlign: 'center',
          }}
        >
          We missed you
        </Typography> */}
        <WebsiteEventAgendaLobby eventList={event} />
      </Stack>
      {/* <Stack
        flexDirection="column"
        sx={{
          backgroundColor: '#ffffff1a',
          padding: '40px 50px 50px',
          borderRadius: '16px',
          overflow: 'hidden',
          position: 'relative',
          alignItems: 'center',
          '@media (max-width: 1200px)': { padding: '40px 50px 350px', },
          '@media (max-width: 768px)': { padding: '30px 20px 280px', },
          '&:after': {
            content: '""',
            backgroundImage: 'url(https://assets.swoogo.com/uploads/full/3107757-653c0916ce8f7.png)',
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '280px',
            height: '100%',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
            '@media (max-width: 768px)': { width: '200px', maxHeight: '230px', },
          },
          '&:before': {
            content: '""',
            backgroundImage: 'url(https://assets.swoogo.com/uploads/full/3087752-6536a9da5ac79.png)',
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '280px',
            height: '100%',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
            '@media (max-width: 768px)': { width: '200px', maxHeight: '230px', },
          }
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: '36px',
            mb: '30px',
            color: '#ffffff',
            lineHeight: '48px',
            fontFamily: 'Poppins-500',
            textAlign: 'center',
            maxWidth: '505px'
          }}
        >
          Event overview
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: '20px',
            mb: '10px',
            color: '#b2bfcd',
            lineHeight: '32px',
            fontFamily: 'Poppins-400',
            textAlign: 'center',
            maxWidth: '505px'
          }}
        >
          Box CEO, Aaron Levie and Box CTO, Ben Kus, will take turns sharing insights on the future of AI and showcasing demos of Box AI in action. In addition, you’ll have the opportunity to network and learn from your peers as you fine-tune your strategy for the future of work.
        </Typography>
      </Stack> */}
    </Container>
  );
};
