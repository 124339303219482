/* eslint-disable no-console */
/* eslint-disable react/jsx-no-useless-fragment */
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IUserProfile } from 'src/mui/types/user';
import { Avatar } from 'src/shared-components/V2';
import { IAppState } from 'src/store';
import { NoEvents } from 'src/screens/events/event-tab/components/no-events';

type IPeopleListCardProps = {
  people: IUserProfile[];
  onClick: (user: IUserProfile) => void;
  onClickPeople?: (user: IUserProfile) => void;
  selectedIds: string[];
};

export const PeopleListCard = (props: IPeopleListCardProps) => {
  const navigate = useNavigate();
  const { currentUser: user } = useSelector((state: IAppState) => state.auth);

  return (
    <>
      {props?.people?.length > 0 ? (
        props.people.map((item: IUserProfile) => {
          const isAdded = props?.selectedIds.includes(item.id);
          return (
            <div
              key={`search-people-${item.id}`}
              className="flex sm:flex-row flex-col items-center justify-between border-b-1 border-[#CDDCF2] py-0 pb-3 sm:pb-0 bg-white"
            >
              <div className="flex items-center pb-[20px] pt-[20px] text-blue-800 w-full">
                <div
                  className="flex-shrink-0 cursor-pointer"
                  onClick={() => {
                    setTimeout(() => {
                      navigate(`/wall/${item.slug}`);
                    }, 1000);
                    props?.onClickPeople?.(item);
                  }}
                >
                  <Avatar
                    size="MEDIUM"
                    src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item.avatar}`}
                    alt={`${item.firstName} ${item.lastName}`}
                    className="!rounded-[5px]"
                  />
                </div>
                <div onClick={() => props?.onClickPeople?.(item)} className="flex flex-col items-start pl-4">
                  <span className="text-[14px] leading-5 font-semibold text-[#172746] cursor-pointer" onClick={() => props.onClick(item)}>
                    {`${item.firstName} ${item.lastName}`}
                  </span>
                  <span className="text-[12px] leading-[18px] font-medium text-[#172746] cursor-pointer">{item.jobTitle}</span>
                  <span className="text-[12px] leading-[18px] font-normal text-[#172746] cursor-pointer">{item.location}</span>
                </div>
              </div>
              <div
                className={classNames('flex gap-3', {
                  hidden: item?.id === user?.id
                })}
              >
                <button
                  type="button"
                  className={classNames(
                    'text-sm font-semibold px-[35px] py-[7px] rounded-[5px]',
                    isAdded ? 'bg-[#0049EF] text-white' : 'bg-[#EDF2F5] hover:bg-[#edf2f5b4] text-black'
                  )}
                  onClick={() => props?.onClick(item)}
                >
                  {isAdded ? 'Remove' : 'Add'}
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <NoEvents />
      )}
    </>
  );
};

export default PeopleListCard;
