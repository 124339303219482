import classNames from "classnames";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { useSelector } from "react-redux";

import amexLogo from "../../../assets/images/amex-logo.svg";
import mastercardLogo from "../../../assets/images/mastercard-logo.svg";
import paypalLogo from "../../../assets/images/paypal-logo.svg";
import visaLogo from "../../../assets/images/visa-logo.svg";
import { PageHeader, PageTitle } from "../../../components";
import { LOGIN_SIGNUP_RECOVERY } from "../../../constants";
import { Button } from "../../../shared-components/V2/Button";
import { IAppState } from "../../../store";
import { emailRegex } from "../../../utils";
import { useCurrentUser } from "../../../utils/hooks";
import FinalMessageContent from "../../event/registration/event-components/final-message-content";
import EventCompleteProfile from "../../event/registration/event-signup/event-completeprofile";
import EventDetails from "../../event/registration/event-signup/event-details";
import EventSignup from "../../event/registration/event-signup/event-signup";
import LoginForm from "../../login/login-form";
import LoginOrJoinForm from "../../loginorjoin/loginorjoin-form";
import EventCard from "./event-card";
import TicketBreadcrumb from "./ticket-breadcrumb";
import TicketCart from "./ticket-cart";

interface ITicketPaymentProps {
  eventId: string;
  eventTitle: string;
  cover: string;
  logo: string;
  eventDate: string;
  location?: string;
  onPlaceOrder: Function;
  onCancel: Function;
}
interface ILoginOrSignup {
  email: string;
}

const TicketPayment = ({
  eventId,
  eventTitle,
  cover,
  logo,
  eventDate,
  location,
  onPlaceOrder,
  onCancel,
}: ITicketPaymentProps) => {
  const user = useCurrentUser();

  const currentStep = useSelector(
    (state: IAppState) => state.signupUser.currentStep
  );
  const { registered } = useSelector((state: IAppState) => state.website);
  const { selectedTickets } = useSelector(
    (state: IAppState) => state.eventTickets
  );
  const [couponCode, setCouponCode] = useState<string>();
  const [allocatedTickets, setAllocatedTickets] = useState<any[]>([]);
  const {
    formState: { errors },
  } = useForm<any>();
  const [page, setPage] = useState(0);

  useEffect(() => {
    setAllocatedTickets(generateTicketList());
  }, []);

  const closeModal = () => {
    setPage(0);
  };

  const closeModalUsingButton = () => {
    setPage(0);
    window.location.reload();
  };

  const changePage = (page: number) => {
    // page = 1 is login
    // page = 2 is signup & join
    // page = 3 is join
    setPage(page);
  };

  const pageDisplay = () => {
    switch (page) {
      case 0: {
        if (user && currentStep === 0 && registered === false) {
          return (
            <EventDetails
              eventId={eventId}
              changePage={changePage}
              backButtonVisible
            />
          );
        }
        if (user && currentStep === 1) {
          return (
            <FinalMessageContent eventId={eventId}/>
          );
        }
        if (!user) {
          return (
            <div className="flex flex-col w-[450px]">
              <div className="mx-auto pl-2">
                <PageHeader />
              </div>
              <PageTitle titleText="Login or Join Now" />
              <div className="flex justify-start mt-5 pl-10">
                <LoginOrJoinForm from="event" changePage={changePage} />
              </div>
            </div>
          );
        }
        break;
      }
      case 1: {
        return (
          <div className="flex flex-col mx-auto w-[400px]">
            <div className="flex justify-center mt-1">
              <div className="text-gray-1 text-base">
                Login to Panelist to join this event
              </div>
            </div>
            <div className="mx-auto pl-2">
              <LoginForm from="event" changePage={changePage} />
            </div>
          </div>
        );
      }
      case 2: {
        if (currentStep === 0) {
          return (
            <div className="flex flex-col w-[400px]">
              <div className="flex justify-center mt-1">
                <div className="text-gray-1 text-base">
                  Let's set up your profile
                </div>
              </div>
              <div className="mx-auto pl-2">
                <EventSignup page={page} changePage={changePage} />
              </div>
            </div>
          );
        }
        if (currentStep === 1 && registered === null) {
          return (
            <EventDetails
              eventId={eventId}
              changePage={changePage}
              backButtonVisible={false}
            />
          );
        }
        if (currentStep === 2) {
          return <EventCompleteProfile page={page} changePage={changePage} />;
        }
        if (currentStep === 3) {
          return (
            <FinalMessageContent eventId={eventId} />
          );
        }
        break;
      }
      case 3: {
        if (currentStep === 0) {
          if (registered === null) {
            return (
              <div className="flex flex-col mx-auto">
                <div className="flex justify-center mt-1">
                  <div className="text-gray-1 text-base">
                    Login to Panelist to join this event
                  </div>
                </div>
                <div className="mx-auto pl-2">
                  <LoginForm from="event" changePage={changePage} />
                </div>
              </div>
            );
          }
          if (registered) {
            closeModal();
          } else if (registered === false) {
            return (
              <EventDetails
                eventId={eventId}
                changePage={changePage}
                backButtonVisible
              />
            );
          }
        } else if (currentStep === 1) {
          return <EventCompleteProfile page={page} changePage={changePage} />;
        } else if (currentStep === 2) {
          return (
            <FinalMessageContent eventId={eventId} />
          );
        }
        break;
      }
      default: {
        break;
      }
    }
    return null;
  };

  const generateTicketList = (): any[] => {
    const tickets: any[] = [];
    selectedTickets.map((ticket) => {
      for (let i = 0; i < ticket.numberOfTickets; i++) {
        tickets.push({
          eventTicketId: ticket.ticketId,
          email: user.email,
        });
      }
    });
    return tickets;
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    onPlaceOrder(allocatedTickets, couponCode);
  };

  const PaymentSection = () => (
    <>
      <form onSubmit={onSubmit}>
        <TicketBreadcrumb
          className="pl-[35px] pt-[29px] pb-[15px]"
          selected="Your profile"
        />
        <div className="max-w-[525px] text-center m-auto">
          <div className="text-s-10 font-light leading-4 text-[#18161C] mb-[9px]">
            By clicking “Place Order”, I accept the User{" "}
            <span className="text-[#285CB2] font-normal hover:underline">
              Agreement
            </span>{" "}
            and have read the{" "}
            <span className="text-[#285CB2] font-normal hover:underline">
              Privacy Policy
            </span>{" "}
            and{" "}
            <span className="text-[#285CB2] font-normal hover:underline">
              Cookie Policy.
            </span>{" "}
            I agree that Panelist may share my information with the event
            organizer.
          </div>
          <div className="text-[#18161C] text-[13px] font-light leading-5">
            <span className="font-medium">Checkout:</span> Time left 8:00
          </div>
        </div>
        <div className="mt-1.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="844.5"
            height="1"
            viewBox="0 0 844.5 0.5"
          >
            <line
              id="Line_1403"
              data-name="Line 1403"
              x2="844.5"
              transform="translate(0 0.25)"
              fill="none"
              stroke="#cecece"
              strokeWidth="0.5"
            />
          </svg>
        </div>
        <div className="overflow-auto h-[450px]">
          <div className="text-sm text-[#18161C] leading-5 font-medium ml-[35px] mt-2.5">
            Profile information
          </div>
          <div className="flex items-center ml-[35px] gap-2">
            <div className="text-sm font-normal leading-5 text-[#18161C]">
              Logged in as {user.email}
            </div>
            <div className="text-sm font-medium leading-5 text-[#285CB2]">
              {" "}
              Not you?
            </div>
          </div>
          <div className="text-sm leading-5 text-[#18161C] font-medium ml-[35px] mt-5 mb-2.5">
            Payment method
          </div>
          <div className="ml-[35px]">
            <div className="bg-white rounded-10 h-[138px] w-[780px] p-[18px]">
              <div className="text-md2 text-blue-2 font-semibold leading-3">
                Add a payment method
              </div>
              <div className="mt-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="747"
                  height="2"
                  viewBox="0 0 747 2"
                >
                  <line
                    id="Line_1209"
                    data-name="Line 1209"
                    x2="745"
                    transform="translate(1 1)"
                    fill="none"
                    stroke="#203c6e"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <div className="flex flex-row justify-between mt-4 w-[280px]">
                <div className="flex flex-row">
                  <input type="radio" checked />
                  <img src={visaLogo} alt="" className="ml-2" />
                  <img src={mastercardLogo} alt="" className="ml-2" />
                  <img src={amexLogo} alt="" className="ml-2" />
                </div>
                {/* <div className="flex flex-row">
                  <input type="radio" />
                  <img src={paypalLogo} alt="" className="ml-2" />
                </div> */}
              </div>
            </div>
          </div>
          <div className="text-sm leading-5 text-[#18161C] font-medium mt-4 ml-[35px]">
            Discount Code
            <input
              type="text"
              autoFocus
              className="w-1/5 p-1 rounded-lg m-2"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="text-sm leading-5 text-[#18161C] font-medium mt-4 ml-[35px]">
            Ticket allocation
          </div>
          <div className="text-h3 leading-[17px] text-[#3C3C3C] ml-[35px] mt-1">
            Allocate your tickets by adding your attendees name and details.
            Each attendee will receive a seperate email to complete their
            registration details
          </div>
          <div className="flex flex-col pl-9 text-xs pt-2">
            {generateTicketList()?.map((ticket, indx) => {
              return (
                <>
                  <div className="flex flex-row items-center border-t border-[#CECECE] justify-between px-8"></div>
                  <p className="py-2">
                    Ticket {indx + 1} | {ticket?.ticket?.ticketName}
                  </p>
                  <div className="flex flex-row gap-3 py-1">
                    <div className="w-1/5 px-1">First name*</div>
                    <div className="w-1/5 px-1">Last name*</div>
                    <div className="w-1/5 px-1">Email*</div>
                    <div className="w-1/5 px-1">Phone</div>
                    <div className="w-1/5 px-1">Company</div>
                  </div>
                  <div className="flex flex-row gap-3 pb-2">
                    <input
                      // placeholder='First name'
                      className={classNames(
                        "w-1/5 text-sm5 focus:outline-none block font-light text-[#3C3C3C] placeholder:text-[#3C3C3C] border border-1 px-1.5 py-0.5 rounded-lg",
                        {
                          "border-gray-100": !errors.firstName?.message,
                          "border-red-300": !!errors.firstName?.message,
                        }
                      )}
                      // {...register("firstName", {
                      //   required: {
                      //     value: true,
                      //     message: "First name is required",
                      //   },
                      // })}
                      value={allocatedTickets[indx]?.firstName}
                      onChange={(e) =>
                        (allocatedTickets[indx].firstName = e.target.value)
                      }
                      required
                      autoComplete="off"
                    />
                    <input
                      // placeholder='Last name'
                      className={classNames(
                        "w-1/5 text-sm5 focus:outline-none block font-light text-[#3C3C3C] placeholder:text-[#3C3C3C] border border-1 px-1.5 py-0.5 rounded-lg",
                        {
                          "border-gray-100": !errors.lastName?.message,
                          "border-red-300": !!errors.lastName?.message,
                        }
                      )}
                      // {...register("lastName", {
                      //   required: {
                      //     value: true,
                      //     message: "Last name is required",
                      //   },
                      // })}
                      value={allocatedTickets[indx]?.lastName}
                      onChange={(e) =>
                        (allocatedTickets[indx].lastName = e.target.value)
                      }
                      required
                      autoComplete="off"
                    />
                    <input
                      // name="email"
                      // placeholder='Email'
                      className={classNames(
                        "w-1/5 text-sm5 focus:outline-none block font-light text-[#3C3C3C] placeholder:text-[#3C3C3C] border border-1 px-1.5 py-0.5 rounded-lg",
                        {
                          "border-gray-100": !errors.email?.message,
                          "border-red-300": !!errors.email?.message,
                        }
                      )}
                      // {...register("email", {
                      //   required: {
                      //     value: true,
                      //     message: "Email is required",
                      //   },
                      //   pattern: {
                      //     value: emailRegex,
                      //     message: LOGIN_SIGNUP_RECOVERY.VALID_EMAILADDRESS,
                      //   },
                      // })}
                      onChange={(e) =>
                        (allocatedTickets[indx].email = e.target.value)
                      }
                      type="email"
                      required
                      autoComplete="off"
                    />
                    <input
                      // name="phone"
                      // placeholder='Phone'
                      className="w-1/5 text-sm5 focus:outline-none block font-light text-[#3C3C3C] placeholder:text-[#3C3C3C] border border-1 border-gray-100 px-1.5 py-0.5 rounded-lg"
                      // {...register("phone", {
                      //   required: {
                      //     value: false,
                      //     message: "Phone is optional",
                      //   },
                      // })}
                      value={allocatedTickets[indx]?.phone}
                      onChange={(e) =>
                        (allocatedTickets[indx].phone = e.target.value)
                      }
                      autoComplete="off"
                    />
                    <input
                      // name="company"
                      // placeholder='Company'
                      className="w-1/5 text-sm5 focus:outline-none block font-light text-[#3C3C3C] placeholder:text-[#3C3C3C] border border-1 border-gray-100 px-1.5 py-0.5 rounded-lg"
                      // {...register("company", {
                      //   required: {
                      //     value: false,
                      //     message: "Company is optional",
                      //   },
                      // })}
                      value={allocatedTickets[indx]?.company}
                      onChange={(e) =>
                        (allocatedTickets[indx].company = e.target.value)
                      }
                      autoComplete="off"
                    />
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <div className="flex flex-row items-center border-t border-[#CECECE] justify-between h-[50px] mt-2 px-8">
          <Button
            className="bg-gray-1 text-[#172746] tracking-wider"
            size="small"
            text="Back"
            onClick={() => onCancel()}
          />
          <Button
            className="bg-[#0049EF] rounded-20 text-white tracking-wider hover:bg-[#244E93]"
            size="small"
            text="Place order"
            type="submit"
            data-test-id="ticket-button"
            // onClick={() => onPlaceOrder()}
          />
        </div>
      </form>
    </>
  );

  return (
    <div className="flex justify-between">
      <div className="inline-block max-w-[844px]">
        <div className="flex flex-col h-full">
          {user && <PaymentSection />}
          {!user && pageDisplay()}
        </div>
      </div>
    </div>
  );
};

export default TicketPayment;
