import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReactSelectOption } from '../../models/react-select-option';
import { IAppState } from '../../store';
import { getCompanies } from '../../store/companies/actions';
import { SingleValue } from 'react-select';
import { UseFormRegister } from 'react-hook-form';
import { LOGIN_SIGNUP_RECOVERY } from '../../constants';
import Creatable from 'react-select/creatable';
import { IReactSelectOptionCompany } from '../../models/company/company-react-select-option';
import noCompany from '../../assets/images/signup/noCompany.svg';
import { Logo } from '../../shared-components/V2/Logo';

interface CompanySelectorProps {
  onChange: Function;
  register: UseFormRegister<any>;
  selectedCompanyStateToStore: IReactSelectOptionCompany | undefined;
  isBorderLess?: boolean;
  placeholder?: string;
}

export const CompanySelector = ({
  onChange,
  register,
  selectedCompanyStateToStore,
  isBorderLess,
  placeholder
}: CompanySelectorProps) => {
  const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;

  const dispatch = useDispatch();
  const companies = useSelector((state: IAppState) => state.companies);

  const [companyListState, setCompanyListState] = useState<IReactSelectOptionCompany[]>();
  const [query, setQuery] = useState('');
  const [getJobSearchTitle, setGetJobSearchTitle] = useState<IReactSelectOptionCompany[]>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    dispatch(getCompanies(query));
  }, [query, dispatch]);

  useEffect(() => {
    if (selectedCompanyStateToStore === null) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
    if (getJobSearchTitle && getJobSearchTitle?.length > 0) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [selectedCompanyStateToStore]);

  useEffect(() => {
    const mapCompaniesToSelectOption = () => {
      const companyList: IReactSelectOptionCompany[] = []; 

      companies?.value?.data &&
        companies?.value?.data.map((item: any, index: number) =>
          companyList.push({
            key: `company-${index}`,
            value: item.id,
            label: item.name,
            logo: item.logo,
            industry: item?.industry?.name ? item?.industry?.name : ''
          })
        );
      setCompanyListState(companyList);
    };
    mapCompaniesToSelectOption();
  }, [companies]);

  const onChangeLocal = (event: SingleValue<IReactSelectOption>) => {
    onChange(event);
  };

  const onInputChange = (value: string) => {
    setQuery(value);
    if (query.length > 1) {
      const findTitle = companyListState?.filter(item => item.label.toLowerCase()?.includes(query.toLowerCase()));  
      setGetJobSearchTitle(findTitle);
    } else {
      setGetJobSearchTitle([])
    }
  }

  const onCloseMenu = () => {
    setGetJobSearchTitle([])
  }

  const formatCreateLabel = (inputValue: any) => {
    setIsMenuOpen(inputValue.length > 1 ? true : false);
    return `${inputValue}`;
  }

  return (
    <div>
      <Creatable
        id="companySelector"
        className={`company-selector-style hide-arrow dropdown-style ${isBorderLess ? "job-title" : ""}`}
        onInputChange={(a) => onInputChange(a)}
        onMenuClose={onCloseMenu}
        formatCreateLabel={formatCreateLabel}
        isClearable={false}
        menuIsOpen={isMenuOpen}
        openMenuOnClick={true}
        createOptionPosition="first"
        placeholder={placeholder ? placeholder : "Enter your organization"}
        options={companyListState}
        {...register('companyId', {
          required: { value: true, message: LOGIN_SIGNUP_RECOVERY.COMPANY_ISREQUIRED }
        })}
        value={selectedCompanyStateToStore}
        onChange={(e) => onChangeLocal(e)}
        formatOptionLabel={(company) =>
          company && (
            <div className="flex row h-[39px] w-full overflow-hidden !px-[8px] !py-[4px] selected-field-company">
              <div className="!p-0 hide-text">
                {company.logo && (
                  <div className="flex justify-center items-center w-[29px] h-[29px] bg-white profile-avatar-shadow rounded-[5px] px-auto mr-[8px]">
                    <Logo
                      src={`${imageUrlPrefix}/${company.logo}`}
                      className="flex justify-center w-[29px] h-[29px] bg-gray-1 rounded-[5px] object-cover"
                      alt={company?.industry}
                      defaultLogo='company'
                    />
                  </div>
                )}
                {!company.logo && 
                  <div className="flex justify-center items-center w-[29px] h-[29px] bg-white profile-avatar-shadow rounded-[5px] px-auto mr-[11px]">
                    <Logo
                      src={`${noCompany}`}
                      className="flex justify-center w-[29px] h-[29px] rounded-[5px] p-[3px] bg-gray-1 object-contain"
                      alt={company?.industry}
                      defaultLogo='company'
                    />
                  </div>
                }
              </div>
              <div className="truncate flex items-center w-[262px]">
                {
                  company.label && (
                    <div className="inline-block text-[15px] leading-[20px] font-semibold text-[#172746] selected-label-company">
                      {company.label}
                    </div>
                  )
                }
                
                <div className="mx-2 truncate text-[13px] font-light leading-[16px] text-[#18161C] flex-none hide-text">• Company</div>
                {/* <div className="truncate text-[13px] font-light leading-[16px] text-[#18161C]">
                  {company.industry}
                </div> */}
              </div>
            </div>
          )
        }
      />
    </div>
  );
};
