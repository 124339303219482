import HostContentTwo from './host-content-two';
import HostContentOne from './host-content-one';
import HostContentThree from './host-content-three';
import HostContentFour from './host-content-four';
import HostContentFive from './host-content-five';
import HostContentSix from './host-content-six';
import HostContentSevenEight from './host-content-seven-eight';
import HostContentNinetoEleven from './host-content-ninetoeleven';
import HostContentTwelvetoSixteen from './host-content-twelvetosixteen';

const HostTerms = () => (
  <div className="bg-gray-0 w-full">
    <div className="block mb-8 w-9/12 mx-auto text-md2 font-normal text-gray-1">
      <div className="font-normal text-gray-1 text-md2">
        <HostContentOne />
        <HostContentTwo />
        <HostContentThree />
        <HostContentFour />
        <HostContentFive />
        <HostContentSix />
        <HostContentSevenEight />
        <HostContentNinetoEleven />
        <HostContentTwelvetoSixteen />
      </div>
    </div>
  </div>
);

export default HostTerms;
