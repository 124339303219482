/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router';
import MotionLazy from 'src/mui/components/animate/motion-lazy';
import { SplashScreen } from 'src/mui/components/loading-screen';
import { SettingsProvider } from 'src/mui/components/settings';
import { SurveyContextProvider } from 'src/mui/providers/GeneralContext';
import { SurveyMainRouter } from 'src/mui/routers/survey-main-router';
import ThemeProvider from 'src/mui/theme';

const SurveysMain: React.FC = () => {
  const params = useParams();

  return (
    <HelmetProvider>
      <Suspense fallback={<SplashScreen />}>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'blue', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <SurveyContextProvider>
              <MotionLazy>
                <SurveyMainRouter />
              </MotionLazy>
            </SurveyContextProvider>
          </ThemeProvider>
        </SettingsProvider>
      </Suspense>
    </HelmetProvider>
  );
};

export default SurveysMain;
