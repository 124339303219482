import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Loader } from '../../../shared-components/Loader';
// import { Logo } from '../../../shared-components/V2/Logo';

import { IAppState } from '../../../store';
import { rgbToHex } from '../../../utils/color-transformation';
import { dateAndTimeWithTz } from '../../../utils/date-timezone';
import { ParseContent } from '../../../utils/parse-html';
import { getTimeZone } from '../../../utils/timezones';
import EventRegistrationButton from '../registration/event-registration-button/event-registration-button';
import { ClockSvgIcon } from '../../../shared-components/V2/IconSvg/ClockSvgIcon/ClockSvgIcon';
import { LocationSvgIcon } from '../../../shared-components/V2/IconSvg/LocationSvgIcon/LocationSvgIcon';

export const Banner = () => {
  const event = useSelector((state: IAppState) => state.website.event!);

  const checkLumination = () => {
    if (!event?.backgroundDominantColor?.length) return false;
    const luma = 0.2126 * event?.backgroundDominantColor[0]
      + 0.7152 * event?.backgroundDominantColor[1]
      + 0.0722 * event?.backgroundDominantColor[2];
    return luma <= 139.731;
  };

  const getBackgroungGradient = () => {
    if (!event?.backgroundDominantColor?.length) return '#0D4552';
    return `${rgbToHex(event?.backgroundDominantColor)}`;
  };

  const getTextColor = () => {
    if (checkLumination()) return '#FFFFFF';
    return '#ffffff';
  };

  const getTimeZoneName = (zone: string) => {
    const shortTimeZone = getTimeZone(zone)?.[0]?.abbr
      ? getTimeZone(zone)?.[0]?.abbr
      : zone;
    return shortTimeZone;
  };

  const eventDateTimeWithTz = useMemo(() => {
    const startTime = dateAndTimeWithTz(event?.startTime, event?.timezone);
    const endTime = dateAndTimeWithTz(event?.endTime, event?.timezone);
    return {
      startTime,
      endTime,
    };
  }, [event?.startTime, event?.endTime, event?.timezone]);

  const eventDateTimeLocalTz = useMemo(() => {
    const startTime = format(parseISO(event?.startTime), 'hh:mm aa')
      ?.replace('.', '')
      ?.replace(' ', '')
      ?.replace('.', '');
    const endTime = format(parseISO(event?.endTime), 'hh:mm aa')
      ?.replace('.', '')
      ?.replace(' ', '')
      ?.replace('.', '');
    const timeZoneMatched = new Date(eventDateTimeWithTz.startTime.dateAndTime).getMinutes()
      === new Date(parseISO(event?.startTime)).getMinutes()
    && new Date(eventDateTimeWithTz.startTime.dateAndTime).getHours()
      === new Date(parseISO(event?.startTime)).getHours();
    return {
      startTime,
      endTime,
      timeZoneMatched
    };
  }, [event?.startTime, event?.endTime]);

  return (
    <div id="banner" className="w-full">
      <div className="block md:flex">
        <div
          className="w-screen bg-gradient-to-b px-[0px] xl:px-0"
          style={{
            background: `linear-gradient(${getBackgroungGradient()}, transparent)`,
          }}
        >
          <div
            className="website-event-cover-new relative overflow-hidden"
            style={{
              backgroundImage:
                event?.cover
                && `url("${`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event.cover}`}")`,
            }}
          >
            <div
              style={{
                backgroundColor: event?.isCoverImageOverlayColor
                  ? event?.coverImageOverlayColour
                  : 'none',
                width: '100%',
                height: '100%',
                borderRadius: '20px',
                position: 'absolute',
                left: 0,
                top: 0,
                opacity: event?.isCoverImageOverlayColor
                  ? `${event?.coverImageOverlayOpacity}%`
                  : '0%',
              }}
            />
            <div className="w-full text-white flex flex-col items-left bg-black bg-opacity-[60%] rounded-b-[0px] h-[100%] justify-center relative overflow-hidden">
              <div className="md:w-[73%] w-[90%] mx-auto md:px-0 px-3">
                {/* <div
                  className="flex justify-left items-center w-full sm:w-[699px] h-[150px] mb-5 md:px-0"
                // style={{
                //   background: event?.colorStyle?.values?.backgroundButton,
                // }}
                >
                  {event?.logo && event?.logo !== 'removed' && (
                    <Logo
                      src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event.logo}`}
                      className="flex justify-center h-full object-contain"
                      alt={event?.name}
                      defaultLogo="company"
                    />
                  )}
                </div> */}
                <div
                  className="flex justify-left font-[500] mb-[10px] max-w-[700px]"
                  style={{
                    color: getTextColor(),
                  }}
                >
                  <ParseContent
                    content={event.theme}
                    className="text-left text-[44px] leading-[54px] line-clamp-4"
                  />
                </div>
                <div
                  className="text-[24px] leading-7 flex text-left justify-left font-normal capitalize mb-[30px] tracking-wider line-clamp-2"
                  style={{
                    color: getTextColor(),
                  }}
                >
                  {event.locationName}
                </div>
                <div
                  className={classNames(
                    'text-[24px] flex text-left justify-left items-center font-bold capitalize',
                    {
                      hidden: !eventDateTimeWithTz.startTime.date,
                    }
                  )}
                  style={{
                    color: getTextColor(),
                  }}
                >
                  <span className="mr-[12px] w-[24px] h-[21px]">
                    <ClockSvgIcon
                      fillColor={event?.colorStyle?.values?.backgroundButton}
                    />
                  </span>
                  {eventDateTimeWithTz.startTime.date}
                  <span className="mx-[3px]">•</span>
                  <span className="uppercase">
                    {eventDateTimeWithTz?.startTime?.time}
                  </span>
                  -
                  <span className="uppercase mr-[3px]">
                    {eventDateTimeWithTz.endTime.time}
                  </span>
                  {getTimeZoneName(event?.timezone)}
                </div>
                {!eventDateTimeLocalTz.timeZoneMatched && (
                  <div
                    className={classNames(
                      'text-[20px] pl-[36px] mb-1 md:flex block text-left justify-left font-normal capitalize break-words',
                      {
                        hidden: !eventDateTimeLocalTz?.startTime,
                      }
                    )}
                    style={{
                      color: getTextColor(),
                    }}
                  >
                    <span>
                      {format(parseISO(event.startTime), 'dd MMM yyyy')}
                    </span>
                    <span className="mx-[3px]">•</span>
                    <span className="uppercase">
                      {eventDateTimeLocalTz.startTime}
                    </span>
                    -
                    <span className="uppercase mr-[3px]">
                      {eventDateTimeLocalTz.endTime}
                    </span>
                    <span>(Your Local Time)</span>
                  </div>
                )}

                <div
                  className={classNames(
                    'text-[24px] flex text-left justify-left items-center font-bold capitalize',
                    {
                      hidden: !event?.location,
                    }
                  )}
                  style={{
                    color: getTextColor(),
                  }}
                >
                  <span className="mr-[12px] w-[24px] h-[24px]">
                    <LocationSvgIcon
                      fillColor={event?.colorStyle?.values?.backgroundButton}
                    />
                  </span>
                  {event.location}
                </div>
                <div
                  className="text-[17px] leading-[28px] flex justify-left capitalize text-left line-clamp-3 mb-[25px] mt-[30px] md:max-w-[800px]"
                  style={{
                    color: getTextColor(),
                  }}
                >
                  <ParseContent content={event.overview} />
                </div>
                <div className="flex justify-left mb-[60px]">
                  {event ? (
                    <EventRegistrationButton isBanner eventId={event.id} />
                  ) : (
                    <Loader className="pt-5" key="loader" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
