import { Reducer } from 'redux';

import { IPostState } from '../../models/post/PostState';
import { PostActions } from './actions';
import { PostActionTypes } from './types';

const initialState: IPostState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const PostReducer: Reducer<IPostState, PostActions> = (state = initialState, action) => {
  switch (action.type) {
    case PostActionTypes.POST_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case PostActionTypes.POST_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case PostActionTypes.POST_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
