/* eslint-disable */
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import WebsiteEventDayDetail from './website-event-day-detail';
import WebsiteEventTabs from './website-event-day-tabs';
import { IAppState } from '../../../store';
import { makeRoomsTree } from '../../../utils/rooms';
import { IRoom } from '../../../models/event/room';
import { EventWebsiteTemplateType } from 'src/mui/types/survey';

const RowBreakout = ({ children }: any) => (
  <div className="w-full inline-flex flex-row overflow-x-auto gap-[6px]">
    {children}
  </div>
);

const Cell = ({ children }: any) => (
  <div className="m-0 mt-0 w-full">{children}</div>
);
const Row = ({ children }: any) => <div className="w-full">{children}</div>;

const WebsiteEventAgenda = () => {
  const event = useSelector((state: IAppState) => state.website.event!);
  const roomTree = makeRoomsTree(event.rooms);
  const [selectedTabRooms, setSelectedTabRooms] = useState(roomTree[0]);

  const onClickTab = (data: any) => {
    setSelectedTabRooms(data);
  };

  const groupBy = (arr: any, property: string) => {
    return arr.reduce((memo: any, x: any) => {
      if (!memo[x[property]]) { memo[x[property]] = []; }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  if(!roomTree?.length) return null;

  return (
    <div
      id="agenda"
      className={classNames('w-full mb-[55px]', {
        hidden: !(event.rooms && event.rooms.length > 0),
      })}
    >
      <div className="block md:flex mx-auto">
        <div className={`w-full ${event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS ? 'event-afterhours-box':'event-website-box'} pt-[23px] pb-[42px]`}
       >
          <div className="md:mx-[36px] mx-[15px] overscroll-contain">
            <div
              className="font-semibold text-[#0326E5] text-[21px] leading-[25px] flex justify-center mb-[50px]"
              style={{
                color: event?.colorStyle?.values?.heading,
              }}
            >
              Agenda
            </div>
            <div>
              <div className="flex items-start gap-[5px] md:flex-row flex-col">
                {roomTree && roomTree.length > 1 && roomTree.map((treeNode: any, index: any) => treeNode?.roomsList?.filter((room: any)=>room?.sessions?.length > 0) && (
                  <WebsiteEventTabs
                    tabName={`Day ${index + 1}`}
                    date={treeNode.date}
                    onClick={onClickTab}
                    selectedTab={treeNode}
                    isSelectTab={treeNode.date === selectedTabRooms?.date}
                    backgroundColor={event?.colorStyle?.values?.backgroundButton}
                  />
                ))}
              </div>
              {selectedTabRooms?.roomsList && (
                <>
                  {selectedTabRooms?.roomsList?.length > 0 && (
                    <>
                      {Object.keys(groupBy(selectedTabRooms?.roomsList, "verticalOrdering"))
                        .map((key, index) => {
                          const rooms = groupBy(selectedTabRooms?.roomsList, "verticalOrdering")?.[key];
                          return <RowBreakout key={index}>
                            {
                              rooms && rooms.length > 0 && rooms.map((room: IRoom, idx: string) => (
                                <Cell>
                                  <WebsiteEventDayDetail
                                    room={room}
                                    roomType={ rooms.length > 1 ? `Breakout Session ${idx + 1}` : ''}
                                    roomQty={2}
                                    customColor={
                                      event?.colorStyle?.values?.backgroundButton
                                    }
                                  />
                                </Cell>
                              )
                              )
                            }
                          </RowBreakout>
                        })
                      }
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventAgenda;
