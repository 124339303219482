import { Stack, Tooltip, Typography } from '@mui/material';
import { CustomAvatar } from './custom-avatar';
import { getMinifiedImage } from 'src/mui/utils/image';
import companyDefault from './../../../assets/images/company-default.svg';
import moment from 'moment-timezone';

interface EventWindowParams {
  date: string | Date;
  status?: string;
  daysThreshold?: number;
}

export const userDropdownOptions = (option: any, style?: any, dropdownType?: string) => (
  // <li {...props} style={{ paddingLeft: "0px !important" }}>
  <Stack direction="row" alignItems="center">
    <div
      style={{
        width: '30px',
        height: '30px',
        marginRight: '11px',
        background: '#ffffff',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {dropdownType === 'company' ? (
        <CustomAvatar
          // defaultCompanyAvatar
          src={getMinifiedImage(option?.logo || option?.company?.logo)}
          alt={`${option?.company?.name || option?.user?.company?.name}`}
          name={option?.company?.name || option?.user?.company?.name}
          sx={{
            width: '30px',
            height: '30px',
            maxWidth: '30px',
            maxHeight: '30px',
            borderRadius: dropdownType === 'company' ? '4px' : '12px',
            backgroundColor: dropdownType === 'company' ? '#F0F0F0' : null,
            '& .MuiAvatar-img': {
              objectFit: dropdownType === 'company' ? 'contain' : 'cover',
            },
          }}
        />
      ) : (
        <CustomAvatar
          src={getMinifiedImage(option?.avatar || option?.user?.avatar)}
          alt={`${option?.firstName || option?.user?.firstName}`}
          name={`${option?.firstName || option?.user?.firstName} ${
            option?.lastName || option?.user?.lastName
          }`}
          sx={{
            width: '30px',
            height: '30px',
            maxWidth: '30px',
            maxHeight: '30px',
            borderRadius: dropdownType === 'company' ? '4px' : '12px',
            backgroundColor: dropdownType === 'company' ? '#F0F0F0' : null,
            '& .MuiAvatar-img': {
              objectFit: dropdownType === 'company' ? 'contain' : 'cover',
            },
          }}
        />
      )}
    </div>
    {dropdownType === 'company' ? (
      <Tooltip
        title={`${option?.company?.name || option?.user?.company?.name}  •  Company  ${
          option?.company?.industry?.name ? `• ${option?.company?.industry?.name}` : ''
        }`}
      >
        <Typography
          variant="body1"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textWrap: 'nowrap',
            whiteSpace: 'nowrap',
            maxWidth: '330px',
            ...style,
          }}
        >
          <span style={{ fontFamily: 'Poppins-500', fontSize: '15px' }}>
            {option?.firstName || option?.user?.firstName
              ? `${option?.firstName || option?.user?.firstName} ${
                  option?.lastName || option?.user?.lastName
                }`
              : option?.company?.name || option?.user?.company?.name || ''}
          </span>
          <>
            {' • '}
            <span style={{ fontFamily: 'Poppins-400', fontSize: '13px' }}>Company</span>
          </>
          {option?.company?.industry?.name ? (
            <>
              {' • '}
              <span style={{ fontFamily: 'Poppins-400', fontSize: '13px' }}>
                {option?.company?.industry?.name}
              </span>
            </>
          ) : null}
        </Typography>
      </Tooltip>
    ) : (
      <Tooltip
        title={`${option?.firstName || option?.user?.firstName || ''} ${
          option?.lastName || option?.user?.lastName || ''
        }  •      ${option?.jobTitle || option?.user?.jobTitle || ''} •      ${
          option?.company?.name || option?.user?.company?.name || ''
        }`}
      >
        <Typography
          variant="body1"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textWrap: 'nowrap',
            whiteSpace: 'nowrap',
            maxWidth: '330px',
            ...style,
          }}
        >
          <span style={{ fontFamily: 'Poppins-500', fontSize: '15px' }}>
            {option?.firstName || option?.user?.firstName
              ? `${option?.firstName || option?.user?.firstName} ${
                  option?.lastName || option?.user?.lastName
                }`
              : option?.company?.name || option?.user?.company?.name || ''}
          </span>

          {option?.jobTitle || option?.user?.jobTitle ? (
            <>
              {' • '}
              <span style={{ fontFamily: 'Poppins-400', fontSize: '13px' }}>
                {option?.jobTitle || option?.user?.jobTitle}
              </span>
            </>
          ) : null}

          {option?.company?.name || option?.user?.company?.name ? (
            <>
              {' • '}
              <span style={{ fontFamily: 'Poppins-400', fontSize: '13px' }}>
                {option?.company?.name || option?.user?.company?.name}
              </span>
            </>
          ) : null}
        </Typography>
      </Tooltip>
    )}
  </Stack>
);

// </li>

export const companyDropdownOptions = (option: any, style?: any, isFullName?: boolean) => (
  <Stack direction="row" alignItems="center">
    <div
      style={{
        width: '30px',
        height: '30px',
        marginRight: '11px',
        background: '#ffffff',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CustomAvatar
        src={getMinifiedImage(option?.logo || option?.company?.logo)}
        alt={`${option?.company?.name || option?.name || ''}`}
        name={option?.company?.name || option?.name || ''}
        sx={{
          width: '30px',
          height: '30px',
          maxWidth: '30px',
          maxHeight: '30px',
          borderRadius: '4px',
          backgroundColor: '#F0F0F0',
          '& .MuiAvatar-img': {
            objectFit: 'contain',
          },
        }}
        // defaultCompanyAvatar
      />
    </div>

    <Tooltip
      title={`${option?.company?.name || option?.name}  •  company  •  ${
        option?.company?.industry?.name ||
        option?.user?.company?.industry?.name ||
        option?.industry?.name
      }`}
    >
      <Typography
        variant="body1"
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          textWrap: 'nowrap',
          whiteSpace: 'nowrap',
          maxWidth: '330px',
          ...style,
        }}
      >
        <span style={{ fontFamily: 'Poppins-500', fontSize: '15px' }}>
          {option?.company?.name || option?.name || ''}
        </span>
        {!isFullName && (
          <>
            {' • '}
            <span style={{ fontFamily: 'Poppins-400', fontSize: '13px' }}>company</span>
          </>
        )}
        <>
          {' • '}
          <span style={{ fontFamily: 'Poppins-400', fontSize: '13px' }}>Company</span>
        </>
        {option?.user?.company?.industry || option?.industry?.name ? (
          <>
            {' • '}
            <span style={{ fontFamily: 'Poppins-400', fontSize: '13px' }}>
              {option?.company?.industry?.name ||
                option?.user?.company?.industry?.name ||
                option?.industry?.name}
            </span>
          </>
        ) : null}
        {option?.eventAdmins && (
          <span style={{ fontFamily: 'Poppins-500', fontSize: '15px' }}> {' • '} Host </span>
        )}
      </Typography>
    </Tooltip>
  </Stack>
);

export const companyMatchDropdownOptions = (option: any, style?: any, isFullName?: boolean) => (
  <Stack direction="row" alignItems="center">
    <div
      style={{
        width: '30px',
        height: '30px',
        marginRight: '11px',
        background: '#ffffff',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CustomAvatar
        src={getMinifiedImage(option?.logo || option?.company?.logo) || companyDefault}
        alt={`${option?.company?.name || option?.name || ''}`}
        name={option?.company?.name || option?.name || ''}
        sx={{
          width: '30px',
          height: '30px',
          maxWidth: '30px',
          maxHeight: '30px',
          borderRadius: '4px',
          backgroundColor: '#F0F0F0',
          '& .MuiAvatar-img': {
            objectFit: 'contain',
          },
        }}
        // defaultCompanyAvatar
      />
    </div>

    <Tooltip
      title={`${option?.company?.name || option?.name}  •  company`}
    >
      <Typography
        variant="body1"
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          textWrap: 'nowrap',
          whiteSpace: 'nowrap',
          maxWidth: '250px',
          ...style,
        }}
      >
        <span style={{ fontFamily: 'Poppins-500', fontSize: '15px' }}>
          {option?.company?.name || option?.name || ''}
        </span>
        {!isFullName && (
          <>
            {' • '}
            <span style={{ fontFamily: 'Poppins-400', fontSize: '13px' }}>company</span>
          </>
        )}
        <>
          {' • '}
          <span style={{ fontFamily: 'Poppins-400', fontSize: '13px' }}>Company</span>
        </>
        {option?.user?.company?.industry || option?.industry?.name ? (
          <>
            {' • '}
            <span style={{ fontFamily: 'Poppins-400', fontSize: '13px' }}>
              {option?.company?.industry?.name ||
                option?.user?.company?.industry?.name ||
                option?.industry?.name}
            </span>
          </>
        ) : null}
        {option?.eventAdmins && (
          <span style={{ fontFamily: 'Poppins-500', fontSize: '15px' }}> {' • '} Host </span>
        )}
      </Typography>
    </Tooltip>
  </Stack>
);


export const isWithinEventWindow = ({
  date,
  status = 'completed',
  daysThreshold = 150
}: EventWindowParams): boolean => {
  if (!date) return false;
  
  try {
    // First check if event is active
    if (status === 'completed') return true;
    
    // Then check the date threshold
    const daysDifference = moment(date).diff(moment(), 'days');
    return daysDifference <= daysThreshold;
  } catch (error) {
    console.error('Error calculating event window:', error);
    return false;
  }
};