export const PanelistLogo = ( ) => {
    return <svg id="panelis" xmlns="http://www.w3.org/2000/svg" width="104.995" height="31.106" viewBox="0 0 104.995 31.106">
    <path id="Path_10785" data-name="Path 10785" d="M294.888,7.08A1.425,1.425,0,0,0,293.456,8.5V28.724a1.416,1.416,0,0,0,.7,1.278,1.443,1.443,0,0,0,1.466,0,1.416,1.416,0,0,0,.7-1.278V8.5a1.423,1.423,0,0,0-1.429-1.417Z" transform="translate(-220.133 -7.08)" fill="#272f51"/>
    <path id="Path_10786" data-name="Path 10786" d="M315.778,31.24a1.438,1.438,0,0,0-1.015.418,1.413,1.413,0,0,0-.417,1.008V46.9a1.416,1.416,0,0,0,.7,1.278,1.443,1.443,0,0,0,1.466,0,1.416,1.416,0,0,0,.7-1.278V32.666a1.412,1.412,0,0,0-.416-1.007,1.438,1.438,0,0,0-1.013-.419Z" transform="translate(-235.804 -25.257)" fill="#272f51"/>
    <path id="Path_10787" data-name="Path 10787" d="M335.7,34.9c0-1.369,1.249-2.367,3.363-2.367a3.468,3.468,0,0,1,3.545,2.046,1.419,1.419,0,0,0,2.448.43,1.392,1.392,0,0,0,.2-1.421c-.882-2.179-3.086-3.447-6.094-3.447-3.835,0-6.321,2.15-6.321,4.767,0,6.359,10.189,4.146,10.189,7.949,0,1.4-1.249,2.523-3.523,2.523-2,0-3.346-.844-3.85-2.063a1.375,1.375,0,0,0-1.277-.83h-.06a1.419,1.419,0,0,0-1.2.678,1.393,1.393,0,0,0-.047,1.37c1.037,1.981,3.411,3.249,6.479,3.249,3.805,0,6.289-2.12,6.289-4.923C345.7,36.74,335.7,38.954,335.7,34.9Z" transform="translate(-249.678 -24.429)" fill="#272f51"/>
    <path id="Path_10788" data-name="Path 10788" d="M396.725,20.33a1.174,1.174,0,0,0,1.179-1.159h0A1.174,1.174,0,0,0,396.725,18h-3.318V15.109a1.416,1.416,0,0,0-.7-1.278,1.443,1.443,0,0,0-1.466,0,1.416,1.416,0,0,0-.7,1.278V30.4c0,3.427,1.729,4.676,4.78,4.676h1.367a1.214,1.214,0,0,0,1.1-.584,1.192,1.192,0,0,0,0-1.232,1.214,1.214,0,0,0-1.1-.584H395.8c-1.761,0-2.391-.592-2.391-2.276V20.33Z" transform="translate(-292.965 -12.009)" fill="#272f51"/>
    <path id="Path_10789" data-name="Path 10789" d="M81.761,30.814a8.793,8.793,0,0,0-8.1,6.617,8.7,8.7,0,0,0,4.03,9.6,8.874,8.874,0,0,0,10.442-1.026v.55a1.447,1.447,0,0,0,2.891,0V39.536a8.531,8.531,0,0,0-.452-2.766A8.811,8.811,0,0,0,81.761,30.814Zm.457,14.591a5.869,5.869,0,1,1,5.919-5.868,5.9,5.9,0,0,1-5.919,5.868Z" transform="translate(-55.064 -24.928)" fill="#272f51"/>
    <path id="Path_10790" data-name="Path 10790" d="M157.4,30.8a7.751,7.751,0,0,0-5.461,2.24,7.613,7.613,0,0,0-2.26,5.412V46.57a1.429,1.429,0,0,0,2.858,0V38.452a4.808,4.808,0,0,1,2.4-4.262,4.9,4.9,0,0,1,4.923,0,4.808,4.808,0,0,1,2.4,4.262V46.57a1.41,1.41,0,0,0,.419,1,1.436,1.436,0,0,0,1.012.414h0a1.423,1.423,0,0,0,1.429-1.417V38.452A7.682,7.682,0,0,0,157.4,30.8Z" transform="translate(-112.281 -24.926)" fill="#272f51"/>
    <path id="Path_10791" data-name="Path 10791" d="M8.812,30.8A8.848,8.848,0,0,0,2.58,33.357,8.69,8.69,0,0,0,0,39.534V54.658a1.447,1.447,0,0,0,2.891,0V46.005a8.874,8.874,0,0,0,10.571.947,8.7,8.7,0,0,0,3.822-9.814A8.8,8.8,0,0,0,8.812,30.8Zm0,14.611a5.869,5.869,0,1,1,5.929-5.877A5.843,5.843,0,0,1,13,43.687,5.949,5.949,0,0,1,8.812,45.4Z" transform="translate(0 -24.926)" fill="#272f51"/>
    <path id="Path_10792" data-name="Path 10792" d="M226,30.8a8.8,8.8,0,0,0-8.712,7.4,9,9,0,0,0-.047,2.308,8.51,8.51,0,0,0,.595,2.308,8.847,8.847,0,0,0,14.9,2.353,1.424,1.424,0,0,0,.2-1.527,1.448,1.448,0,0,0-1.311-.826,1.422,1.422,0,0,0-1.089.5,5.951,5.951,0,0,1-9.44-.5,5.731,5.731,0,0,1-.932-2.308h13.67a.978.978,0,0,0,.982-.973,9,9,0,0,0-.1-1.335A8.8,8.8,0,0,0,226,30.8Zm-5.764,7.4a5.931,5.931,0,0,1,11.528,0Z" transform="translate(-162.924 -24.926)" fill="#272f51"/>
  </svg>
  
}

