import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { RecommendedEventsResult } from '../../../models/RecommendedEventsResult';
import { RecommendedEventsState } from '../../../models/RecommendedEventsState';
import { UserService } from '../../../services';
import { RecommendedEventsActionTypes } from './types';

export interface IRecommendedEventsLoadingAction {
  type: RecommendedEventsActionTypes.RECOMMENDED_EVENTS_LOADING;
  loading: boolean;
}

export interface IRecommendedEventsSuccessAction {
  type: RecommendedEventsActionTypes.RECOMMENDED_EVENTS_SUCCESS;
  payload: RecommendedEventsResult;
}
export interface IRecommendedEventsErrorAction {
  type: RecommendedEventsActionTypes.RECOMMENDED_EVENTS_ERROR;
  errorMessage: string;
}

export type RecommendedEventsActions =
  | IRecommendedEventsLoadingAction
  | IRecommendedEventsSuccessAction
  | IRecommendedEventsErrorAction;

export const getRecommendedEvents: ActionCreator<
  ThunkAction<Promise<any>, RecommendedEventsState, null, IRecommendedEventsSuccessAction>
> = () => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();

    try {
      const result = await userService.getRecommendedEvents();

      // Update the image location URLs
      result?.data?.map((event)=>{
        event.relatedAttendees?.map(attendee => attendee.avatar = `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${attendee.avatar}`)
        return event;
      })

      dispatch({
        type: RecommendedEventsActionTypes.RECOMMENDED_EVENTS_SUCCESS,
        payload: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: RecommendedEventsActionTypes.RECOMMENDED_EVENTS_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: RecommendedEventsActionTypes.RECOMMENDED_EVENTS_LOADING,
        loading: false
      });
    }
  };
};

export const loadRecommendedEvents: ActionCreator<
  ThunkAction<any, RecommendedEventsState, null, IRecommendedEventsLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: RecommendedEventsActionTypes.RECOMMENDED_EVENTS_LOADING,
    loading: shouldLoad
  });
