import { Reducer } from 'redux';

import { IContentFilterState } from '../../models/library-content/ContentFilterState';
import { ContentFilterActions } from './action';
import { ContentFilterActionTypes } from './types';

const initialState: IContentFilterState = {
  value: '',
  loading: false,
  errorMessage: ''
};

export const SetContentFilterReducer: Reducer<IContentFilterState, ContentFilterActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ContentFilterActionTypes.SET_FILTER_VALUE: {
      // let currentFilter = action.filterString
      return {
        ...state,
        value: action.filterString // state.value !== null ?  state.value.concat(action.filterString) : action.filterString
      };
    }

    case ContentFilterActionTypes.FILTER_VALUE_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
