import { IUploadImageResult } from '../../models/fileupload/UploadImageResult';
import BaseService from '../BaseService';

export class FilUploadService extends BaseService {
  async uploadImage(imageFile: any, config = {}) {
    const extraOptions = this.getAuthToken();

    return this.postFile<IUploadImageResult>('/upload/image', imageFile, { ...extraOptions, ...config });
  }

  async uploadDocument(documentFile: any, config = {}) {
    const extraOptions = this.getAuthToken();

    return this.postFile<IUploadImageResult>('/upload/document', documentFile, { ...extraOptions, ...config });
  }

  async uploadDocumentCSV(documentFile: any, config = {}) {
    const extraOptions = this.getAuthToken();

    return this.postFile<IUploadImageResult>('/upload/csv', documentFile, { ...extraOptions, ...config });
  }

  async uploadVideo(videoFile: any, config = {}) {
    const extraOptions = this.getAuthToken();

    return this.postFile<IUploadImageResult>('/upload/video', videoFile, { ...extraOptions, ...config });
  }
}
