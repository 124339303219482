import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Slider, SliderProps, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------

type Props = SliderProps & {
    name: string;
    helperText?: React.ReactNode;
};

export default function PSlider({ name, helperText, ...other }: Props) {
    const { control } = useFormContext();
    
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                return (
                    <div>
                        <Slider
                            {...field}
                            value={typeof field.value === 'number' ? field.value : 0} // Ensure value is defined as a number
                            onChange={(event, value) => field.onChange(value)} // Update the form state with the new slider value
                            {...other}
                        />

                        {(!!error || helperText) && (
                            <FormHelperText error={!!error}>
                                {error ? error.message : helperText}
                            </FormHelperText>
                        )}
                    </div>
                );
            }}
        />
    );
}
