import { ProfileNavbar } from '../../components/navbar';
import { Footer } from '../../components';
import CompleteYourProfileFormNew from './components/completeyourprofile-form-new';

export interface ISignUp {
  country: string;
  city: string;
  location: string;
  postCode: string;
}
export const CompleteYourProfileNew = () => (
  <>
    <ProfileNavbar />
    <div className="bg-gray-1 flex text-center h-[110vh] xs:h-[89vh] w-full overflow-x-auto min-h-600">
      <div className="mx-auto">
        <div className="md:-ml-11">
          <div className="w-full md:ml-px">
            <div className="mt-20">
              <CompleteYourProfileFormNew isMainProfileMatch buttonText="Go to my profile" from="site" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);

export default CompleteYourProfileNew;
