import { IRoom } from '../../../models/event/room';

type RoomSwitcherTabProps = {
  room: IRoom;
  onClick?: Function;
  isActive?: boolean;
};

const RoomSwitcherTab = (props: RoomSwitcherTabProps) => (
  <div
    onClick={() => props.onClick!(props.room)}
    className={`flex-none ${
      props.isActive ? 'bg-blue text-white' : 'bg-gray-200 text-blue-2'
    } rounded-xl py-2 px-3 text-h2 font-semibold mx-1`}
  >
    {props.room?.name}
  </div>
);
export default RoomSwitcherTab;
