/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import { LinkButton, TextInput } from '../../shared-components';

export const ContactForm = () => (
  <div className="w-full">
    <div className="mx-auto bg-gray-1 py-8">
      <div className="xl:w-10/12 xl:flex xl:flex-row mx-auto">
        <div className="block mx-6 2xl:ml-48 xl:ml-2 md:pl-6 xl:mt-52 pt-1 justify-center mt-5 xl:mb-0 mb-10">
          <div className="text-lg md:text-xl font-extralight text-blue-3">
            Contact our sales team today
            <br />
            to build the right event for you
          </div>
          <div className="text-md font-extralight text-blue-3 mt-4">Using Panelist?</div>
          <div className="mt-2">
            <Link
              to="/loginorjoin"
              className="text-md2 text-blue-6 navlink font-semibold mt-2 hover:underline"
            >
              Login
            </Link>
          </div>
          <a
            className="text-md2 text-blue-6 navlink font-semibold mt-3 hover:underline "
            href="mailto:user-compliance@panelist.com"
          >
            Contact Support
          </a>
        </div>
        <div className="lg:ml-auto mx-4">
          <div className="xl:w-535 xl:flex md:mr-4 flex-col bg-white rounded-xl px-6 pt-7 pb-5 -mt-2 w-full">
            <div className="xl:flex ">
              <div className="text-xl7 font-extralight text-blue-3 w-full xl:w-1/2 xl:mb-0 mb-10 pt-1">
                Contact Us
              </div>
              <div className="flex justify-center xl:justify-end xl:ml-2 md:pl-2 -mt-1 mx-auto">
                <LinkButton
                  text="Auto-fill with your Panelist account"
                  backgroundColor=""
                  textColor="#fff"
                  classes="bg-blue-1 md:ml-5 text-sm2 font-extralight hover:bg-blue-4"
                  width="283px"
                  height="30px"
                  href="/auto-fill"
                />
              </div>
            </div>
            <div className="text-md2 font-extralight text-blue-3 mt-4">
              Let us know how we can help you
            </div>
            <div className="md:flex md:flex-row mt-2">
              <div className="mr-2 w-full">
                <TextInput
                  textinputsize="medium"
                  caption="First name*"
                  type="text"
                  classes="w-full border border-gray-1"
                />
              </div>
              <div className="w-full">
                <TextInput
                  textinputsize="medium"
                  caption="Last name*"
                  type="text"
                  classes="w-full border border-gray-1"
                />
              </div>
            </div>
            <div className="mt-2">
              <TextInput
                textinputsize="medium"
                caption="Organization*"
                type="text"
                classes="w-full border border-gray-1"
              />
            </div>
            <div className="mt-2">
              <TextInput
                textinputsize="medium"
                caption="Your Role*"
                type="text"
                classes="w-full border border-gray-1"
              />
            </div>
            <div className="mt-2">
              <TextInput
                textinputsize="medium"
                caption="City, Country*"
                type="text"
                classes="w-full border border-gray-1"
              />
            </div>
            <div className="mt-2">
              <TextInput
                textinputsize="medium"
                caption="Email Address*"
                type="text"
                classes="w-full border border-gray-1"
              />
            </div>
            <div className="mt-2">
              <TextInput
                textinputsize="medium"
                caption="Phone Number*"
                type="text"
                classes="w-full border border-gray-1"
              />
            </div>
            <div className="mt-3">
              <div className="text-sm5 font-extralight text-justify">
                By clicking
                {' '}
                <span className="font-semibold">submit</span>
                {' '}
                you agree that we use the
                information you provide to contact you with relevant information to address your
                request. If you are a member, you can control what communication you receive in your
                settings. If you are a guest, you can unsubscribe from Panelist emails at anytime by
                clicking the unsubscribe link in the email. For more information visit our
                <Link className="text-blue-1 font-semibold ml-1" to="/privacy-policy.html">
                  Privacy Policy
                </Link>
                .
              </div>
            </div>
            <div className="text-right mt-2 mb-3 pb-1">
              <LinkButton
                text="Submit"
                backgroundColor=""
                textColor="#fff"
                classes="bg-blue-1 ml-5 text-sm2 font-extralight hover:bg-blue-4"
                width="98px"
                height="30px"
                href="/auto-fill"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ContactForm;
