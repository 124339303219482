/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { IEvent } from 'src/models/event/event';
import { IAttendee } from 'src/models/events-live/attendee';
import { VideoControlls } from 'src/components/chime-video-call/video-controlls';
import { Participants, QueueParticipants } from 'src/store/interactive-event/types';
import { ChimeLoader } from 'src/components/chime-video-call/chime-loader';
import { IAppState } from 'src/store';
import { InteractiveResetForceMute } from 'src/store/interactive-event/attendee/actions';
import { InteractiveModeratorSpeakerMuteAll } from 'src/store/interactive-event/moderator-speaker';
import { IRoom } from 'src/models/event/room';
import { getCompanyName, minifiedImage } from 'src/utils';
import VideoHeader from '../event-live-video/video-and-expo-panel/video-header';
import HostAndAttendees from '../event-live-video/host-and-attendees';
import { ModeratorView } from './views/moderator-view';
import { SpeakerView } from './views/speaker-view';
import { GuestView } from './views/guest-view';
import { VideoSession } from './video-session';
import { styled } from '@mui/system';
import Stack from '@mui/material/Stack';

const FullSizeBox = styled('div')({
  width: '100%',
  height: '100%',
});

interface IHeroProps {
  event: IEvent;
  attendees: IAttendee[];
  stage: Participants;
  queue: QueueParticipants;
  isGridView?: boolean;
  setIsHandoutsOpen: (value: boolean) => void;
  setIsPollsOpen: (value: boolean) => void;
  setIsAdsOpen: (value: boolean) => void;
  isPollsOpen: boolean;
  calculateVideoGridHeight?:any
  setRoomID?: (value: any) => void;
  isShowAds?: boolean;
  isShowHandouts?: boolean;
}

export const Hero = ({
  event, attendees, stage, queue, isGridView, setIsHandoutsOpen, setIsPollsOpen, setIsAdsOpen, isPollsOpen , calculateVideoGridHeight, setRoomID, isShowAds, isShowHandouts,
}: IHeroProps) => {
  const { slug, roomId, role } = useParams();
  const meetingManager = useMeetingManager();
  const [currentRoom, setCurrentRoom] = useState<IRoom>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [hasReceivedGuestInvite, setHasReceivedGuestInvite] = useState(false);
  const [hasAcceptedGuestInvite, setHasAcceptedGuestInvite] = useState(false);
  const [showPlayerControls, setShowPlayerControls] = useState(false);

  const { chimeInvitation, inviteToJoin, forceMute } = useSelector((state: IAppState) => state.interactiveEvent);
  const { event: { rooms } = {} } = useSelector((state: IAppState) => state.liveEvent);

  useEffect(() => {
    if (hasReceivedGuestInvite !== inviteToJoin) {
      setHasReceivedGuestInvite(inviteToJoin);
    }
  }, [inviteToJoin]);
  useEffect(() => {
    if (rooms) {
      const room = rooms.find(r => r.id === roomId);
      setCurrentRoom(room);
    }
  }, [rooms]);

  useEffect(() => {
    if (forceMute && meetingManager) {
      meetingManager.audioVideo?.realtimeMuteLocalAudio();
      dispatch(InteractiveResetForceMute());
    }
  }, [forceMute]);

  return (
    <>
    {
      event ? 
      <FullSizeBox>
        <Stack
          position='relative'
          sx={{
            p: 0,
            borderRadius: 1,
            textAlign: 'center',
            height: calculateVideoGridHeight ,
            overflow: 'hidden',
          }}
          onMouseEnter={() => setShowPlayerControls(true)}
          onMouseLeave={() => setShowPlayerControls(false)}
        >
          {showPlayerControls && (
            <VideoHeader
              disableExpo={!event?.eventExpo?.expo}
              hasModerator={role === 'moderator'}
              hasSpeaker={role === 'speaker'}
              title={event?.name!}
              overview={event?.overview}
              logoURL={minifiedImage(event?.company?.logo!)}
              eventSlug={event?.slug!}
              hostName={getCompanyName(event)}
              videoStatus={role === 'session' ? 'sessions' : 'expo'}
              setVideoStatus={(type: string) => type === 'sessions' && navigate(`/${getCompanyName(event)}/${event.slug}/live`)}
              className={`absolute w-full bg-transparent bg-gradient-to-b from-slate-400 text-white z-10`}
              setRoomID={setRoomID!}
              showBackButton
            />
          )}
          <Stack spacing={2} sx={{ position: 'relative', height: isGridView ? 'calc(50vh - 11px)' : 'calc(100vh - 22px)' }}>
            {
              (inviteToJoin && hasAcceptedGuestInvite) ? <ChimeLoader chimeConfig={chimeInvitation}><GuestView /></ChimeLoader> : (
                <>
                  {role === 'moderator'
                    && currentRoom?.isInteractive
                    && <ChimeLoader chimeConfig={chimeInvitation}><ModeratorView /></ChimeLoader>}
                  {role === 'speaker'
                    && currentRoom?.isInteractive
                    && <ChimeLoader chimeConfig={chimeInvitation}><SpeakerView /></ChimeLoader>}
                  {(role === 'session' || !currentRoom?.isInteractive) && (
                  <VideoSession
                    attendees={(
                      <HostAndAttendees
                        event={event!}
                        attendees={attendees}
                        stage={stage}
                        queue={queue}
                        {...((role === 'moderator' || role === 'speaker') && { onMuteClick: () => dispatch(InteractiveModeratorSpeakerMuteAll(slug!, roomId!)) })}
                      />
                    )}
                    isShowPlayerControls={showPlayerControls}
                    isGridView={isGridView}
                    setIsHandoutsOpen={setIsHandoutsOpen}
                    setIsPollsOpen={setIsPollsOpen}
                    setIsAdsOpen={setIsAdsOpen}
                    isPollsOpen={isPollsOpen}
                    isShowAds={isShowAds}
                    isShowHandouts={isShowHandouts}
                  />
                  )}
                  {(role === 'session' && !currentRoom?.isInteractive) && <VideoControlls />}
                </>
              )
            }
          </Stack>
        </Stack>
      </FullSizeBox>
      : <> </>
    }
      
    </>
  );
};
