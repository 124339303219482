/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
import React, { useState, useRef, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useLocation, useNavigate } from 'react-router';
import NavbarLogo from './images/navbar-logo.svg';
import DropdownArrow from './images/dropdown-arrow.svg';
import MenuIcon from './images/menu-icon.svg';

const FactorWebsiteUpperNavbar: React.FC = () => {
  const [showFactorDropdown, setShowFactorDropdown] = useState(false);
  const [showInvokedDropdown, setShowInvokedDropdown] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const factorDropdownRef = useRef<HTMLDivElement>(null);
  const invokedDropdownRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (e: MouseEvent) => {
    if (
      factorDropdownRef.current && !factorDropdownRef.current.contains(e.target as Node)
    ) {
      setShowFactorDropdown(false);
    }
    if (
      invokedDropdownRef.current && !invokedDropdownRef.current.contains(e.target as Node)
    ) {
      setShowInvokedDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <nav className="max-w-[1320px] px-[15px] lg:mx-auto w-full h-full relative max-h-[60px] top-0 left-0 bg-black text-white py-[14px] lg:py-[15px] xl:py-[18px] flex justify-center items-center">
      <div className="flex justify-start items-center gap-[5px] md:gap-[7px]">
        <img className="w-[25px] h-[25px] lg:w-[30px] lg:h-[30px] xl:w-[34px] xl:h-[34px] cursor-pointer" src={NavbarLogo} alt="logo" />
        <div ref={factorDropdownRef} className="relative">
          <button
            className="flex h-full max-h-[34px] items-center gap-x-[8px] px-[12px] py-[5px] lg:px-[14px] lg:py-[7px] xl:px-[14px] xl:py-[10px] bg-[#14161A] text-white text-[12px] lg:text-[16px] xl:text-[18px] font-semibold helvetica-neue-65-medium rounded-[3px] hover:bg-[#202329]"
            onClick={() => setShowFactorDropdown(!showFactorDropdown)}
          >
            <div className="flex items-center">
              <span className="helvetica-neue-55-roman h-[34px] sm:text-[17px] text-[15px] leading-[34px] font-bold">About Factor</span>
              <img className="w-[13px] lg:w-[15px] ml-[5px] mt-[2px]" src={DropdownArrow} alt="down-arrow" style={{ transform: showFactorDropdown ? 'rotate(180deg)' : '' }} />
            </div>
          </button>
          {showFactorDropdown && (
          <Transition
            show={showFactorDropdown}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="absolute z-[9999] border border-[#303136] mt-2 py-[10px] px-3 lg:px-3 rounded-[4px] shadow-lg bg-black drop-shadow-xl min-w-[245px]">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a target="_blank" href="https://www.factorr.com/vision-mission" className="block px-[10px] py-2 rounded-[5px] text-[14px] lg:text-[16px] helvetica-neue-65-medium text-white hover:bg-[#303136]" role="menuitem" rel="noreferrer">Mission & Vision</a>
                <a target="_blank" href="https://www.factorr.com/explore" className="block px-[10px] py-2 rounded-[5px] text-[14px] lg:text-[16px] helvetica-neue-65-medium text-white hover:bg-[#303136]" role="menuitem" rel="noreferrer">Resources</a>
                <a target="_blank" href="https://www.factorr.com/team" className="block px-[10px] py-2 rounded-[5px] text-[14px] lg:text-[16px] helvetica-neue-65-medium text-white hover:bg-[#303136]" role="menuitem" rel="noreferrer">The Factor Team</a>
                <a target="_blank" href="https://www.factorr.com/life-at-factor" className="block px-[10px] py-2 rounded-[5px] text-[14px] lg:text-[16px] helvetica-neue-65-medium text-white hover:bg-[#303136]" role="menuitem" rel="noreferrer">Life at Factor</a>
              </div>
            </div>
          </Transition>
          )}
        </div>
        <div ref={invokedDropdownRef} className="relative">
          <button
            className="flex h-[100%] max-h-[34px] items-center gap-x-[8px] px-[12px] py-[5px] lg:px-[14px] lg:py-[7px] xl:px-[14px] xl:py-[10px] bg-[#14161A] text-white text-[12px] lg:text-[16px] xl:text-[18px] font-semibold helvetica-neue-65-medium rounded-[3px] hover:bg-[#202329]"
            onClick={() => setShowInvokedDropdown(!showInvokedDropdown)}
          >
            <div className="flex items-center">
              <span className="helvetica-neue-55-roman h-[34px] sm:text-[17px] text-[15px] leading-[34px] font-bold">Get Involved</span>
              <img className="w-[13px] lg:w-[15px] ml-[5px] mt-[2px]" src={DropdownArrow} alt="down-arrow" style={{ transform: showInvokedDropdown ? 'rotate(180deg)' : '' }} />
            </div>
          </button>
          {showInvokedDropdown && (
          <Transition
            show={showInvokedDropdown}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="absolute z-[9999] border border-[#303136] mt-2 py-[10px] px-3 lg:px-3 rounded-[4px] shadow-lg bg-black drop-shadow-xl min-w-[245px]">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a target="_blank" href="https://www.factorr.com/memberships" className="block px-[10px] py-2 rounded-[5px] text-[11px] lg:text-[16px] helvetica-neue-65-medium text-white hover:bg-[#303136]" role="menuitem" rel="noreferrer">For End-Users</a>
                <a target="_blank" href="https://www.factorr.com/partnerships" className="block px-[10px] py-2 rounded-[5px] text-[11px] lg:text-[16px] helvetica-neue-65-medium text-white hover:bg-[#303136]" role="menuitem" rel="noreferrer">For Technology Vendors</a>
              </div>
            </div>
          </Transition>
          )}
        </div>

      </div>
      <div className="xl:flex justify-start left-auto right-auto mx-auto absolute items-center hidden">
        <a className="text-[17px] px-[6px] ml-[10px] py-2 font-bold helvetica-neue-55-roman hover:text-[#F2B01A]" href="https://www.factor-insights.com/research-advisory-overview" target="_blank" rel="noopener noreferrer" role="menuitem">Research & Advisory</a>
        <a className="text-[17px] px-[6px] ml-[10px] py-2 font-bold helvetica-neue-55-roman hover:text-[#F2B01A]" href="https://www.factor-insights.com/day-events" target="_blank" rel="noopener noreferrer" role="menuitem">Events</a>
        <a className="text-[17px] px-[6px] ml-[10px] py-2 font-bold helvetica-neue-55-roman hover:text-[#F2B01A]" href="https://www.factor-insights.com/factor-media" target="_blank" rel="noopener noreferrer" role="menuitem">Media</a>
        <a className="text-[17px] px-[6px] ml-[10px] py-2 font-bold helvetica-neue-55-roman hover:text-[#F2B01A]" href="https://www.factor-insights.com/leadership" target="_blank" rel="noopener noreferrer" role="menuitem">eLearning</a>
      </div>
      <div className="flex justify-start ml-auto items-center gap-[14px] lg:gap-[19px]">
        <a className="text-[8px] text-black lg:text-[10px] !font-normal helvetica-neue-55-roman px-[15px] py-[3.5px] lg:px-[14px] lg:py-[3.5px] bg-[#F1B01A] hover:bg-white rounded-[3px] hidden sm:block" href="https://www.factor-insights.com/explore" target="_blank" rel="noopener noreferrer">Explore Events</a>
        <a className="text-[12px] leading-[18px] lg:text-[17px] font-bold helvetica-neue-55-roman border-l-1 border-r-1 border-white px-[15px] hidden sm:block hover:text-[#F2B01A]" href="https://www.factor-insights.com/contact-us" target="_blank" rel="noopener noreferrer">Plans</a>
        <a
          onClick={() => {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set('tab', 'Contact us');
            navigate({
              pathname: location.pathname,
              search: queryParams.toString()
            });
          }}
          className="hidden sm:block leading-[18px] text-[12px] lg:text-[17px] font-bold helvetica-neue-55-roman hover:text-[#F2B01A] cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
        >
          My Account
        </a>
      </div>
      <button
        className="xl:hidden flex items-center gap-2 px-2 py-1 text-white text-[14px] font-semibold rounded-[5px] cursor-pointer"
        onClick={() => setShowMenu(!showMenu)}
      >
        <img className="w-[25px] h-[25px] md:w-[30px] md:h-[30px]" src={MenuIcon} alt="menu-icon" />
      </button>
      <div className={`w-full md:w-[30%] absolute top-[57px] z-[10000] md:z-auto lg:top-[68px] xl:top-[83px] bg-[#14161A] text-white right-0 ${showMenu ? 'flex' : 'hidden'} xl:hidden flex flex-col justify-center items-center gap-[25px] p-[20px]`}>
        <a className="text-[17px] font-semibold helvetica-neue-55-roman hover:text-[#F2B01A]" href="https://www.factor-insights.com/research-advisory-overview" target="_blank" rel="noopener noreferrer" role="menuitem">Research & Advisory</a>
        <a className="text-[17px] font-semibold helvetica-neue-55-roman hover:text-[#F2B01A]" href="https://www.factor-insights.com/day-events" target="_blank" rel="noopener noreferrer" role="menuitem">Events</a>
        <a className="text-[17px] font-semibold helvetica-neue-55-roman hover:text-[#F2B01A]" href="https://www.factor-insights.com/factor-media" target="_blank" rel="noopener noreferrer" role="menuitem">Media</a>
        <a className="text-[17px] font-semibold helvetica-neue-55-roman hover:text-[#F2B01A]" href="https://www.factor-insights.com/leadership" target="_blank" rel="noopener noreferrer" role="menuitem">eLearning</a>
        <a
          className="block sm:hidden text-[17px] font-semibold helvetica-neue-55-roman hover:text-[#F2B01A] cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set('tab', 'Contact us');
            navigate({
              pathname: location.pathname,
              search: queryParams.toString()
            });
          }}
        >
          My Account
        </a>
        <a className="text-[17px] font-semibold helvetica-neue-55-roman block sm:hidden hover:text-[#F2B01A]" href="https://www.factor-insights.com/contact-us" target="_blank" rel="noopener noreferrer">Plans</a>
        <a className="text-[12px] font-semibold helvetica-neue-45-light px-[7px] py-[5px] lg:px-[12px] lg:py-[5px] bg-[#F2B01A] rounded-[3px] block sm:hidden text-black" href="https://www.factor-insights.com/explore" target="_blank" rel="noopener noreferrer">Explore Events</a>
      </div>
    </nav>
  );
};

export default FactorWebsiteUpperNavbar;
