import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

import {
  ICastVoteResult,
  ICreatePoll,
  ICreatePollResult,
  IPollResult,
} from "../../models/poll/poll";
import { PollService } from "../../services/Poll";

export type PollState = {
  loading: boolean;
  value: IPollResult | null;
  errorMessage: string;
  pollCreated: ICreatePollResult | null;
  voteResult: ICastVoteResult | null;
  voteRemovedResult: ICastVoteResult | null;
  allPolls?: any;
};

const initialState: PollState = {
  loading: false,
  value: null,
  errorMessage: "",
  pollCreated: null,
  voteResult: null,
  voteRemovedResult: null,
  allPolls: null,
};

export const pollSlice = createSlice({
  name: "pollSlice",
  initialState,
  reducers: {
    setPollCreatedResult: (
      state,
      action: PayloadAction<ICreatePollResult | null>
    ) => {
      state.pollCreated = action.payload;
    },
    setPollResult: (state, action: PayloadAction<IPollResult>) => {
      state.value = action.payload;
    },

    setAllPollResult: (state, action: PayloadAction<IPollResult>) => {
      state.allPolls = {
        ...state.allPolls,
        [action?.payload?.data?.id]: action.payload?.data,
      };
    },
    setVoteResult: (state, action: PayloadAction<ICastVoteResult>) => {
      state.voteResult = action.payload;
    },
    setVoteRemovedResult: (state, action: PayloadAction<ICastVoteResult>) => {
      state.voteRemovedResult = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  setPollCreatedResult,
  setPollResult,
  setAllPollResult,
  setVoteResult,
  setVoteRemovedResult,
  setErrorMessage,
} = pollSlice.actions;

export const getPoll = (pollId: string) => async (dispatch: Dispatch) => {
  const pollService = new PollService();
  try {
    const result = await pollService.getPoll(pollId);

    dispatch(setPollResult(result));
    dispatch(setAllPollResult(result));
  } catch (error: any) {
    console.error(error);
    dispatch(setErrorMessage(error.message));
  }
};

export const getAllPolls = (pollId: string) => async (dispatch: Dispatch) => {
  const pollService = new PollService();
  try {
    const result = await pollService.getPoll(pollId);

    dispatch(setAllPollResult(result));
  } catch (error: any) {
    console.error(error);
    dispatch(setErrorMessage(error.message));
  }
};

export const createPoll = (poll: ICreatePoll) => async (dispatch: Dispatch) => {
  const pollService = new PollService();
  try {
    const result = await pollService.createPoll(poll);

    dispatch(setPollCreatedResult(result.data));
  } catch (error: any) {
    console.error(error);
    dispatch(setErrorMessage(error.message));
  }
};

export const editPoll =
  (poll: ICreatePoll, pollId: string, onEditSuccess?: () => void) =>
  async (dispatch: Dispatch) => {
    const pollService = new PollService();
    try {
      const result = await pollService.editPoll(poll, pollId);
      dispatch(setPollCreatedResult(result.data));
      // dispatch(setAllPollResult(result.data));
      if (onEditSuccess) {
        onEditSuccess();
      }
    } catch (error: any) {
      console.error(error);
      dispatch(setErrorMessage(error.message));
    }
  };

export const discardPoll = () => async (dispatch: Dispatch) =>
  dispatch(setPollCreatedResult(null));

export const castVote =
  (pollId: string, answerId: string, onSuccessVote?: VoidFunction) =>
  async (dispatch: Dispatch) => {
    const pollService = new PollService();
    try {
      const result = await pollService.castVote(pollId, answerId);

      dispatch(setVoteResult(result));
      if (onSuccessVote) onSuccessVote();
    } catch (error: any) {
      console.error(error);
      dispatch(setErrorMessage(error.message));
    }
  };

export const removeVote =
  (pollId: string, answerId: string) => async (dispatch: Dispatch) => {
    const pollService = new PollService();
    try {
      const result = await pollService.removeVote(pollId, answerId);

      dispatch(setVoteRemovedResult(result));
    } catch (error: any) {
      console.error(error);
      dispatch(setErrorMessage(error.message));
    }
  };
