import { Reducer } from 'redux';

import { ConnectionsState } from '../../../models/ConnectionsState';
import { OwnConnectionsActions } from './actions';
import { OwnConnectionsActionTypes } from './types';

const initialState: ConnectionsState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const OwnConnectionsReducer: Reducer<ConnectionsState, OwnConnectionsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case OwnConnectionsActionTypes.OWN_CONNECTIONS_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case OwnConnectionsActionTypes.OWN_CONNECTIONS_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case OwnConnectionsActionTypes.OWN_CONNECTIONS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    case OwnConnectionsActionTypes.OWN_CONNECTIONS_CLEANUP: {
      return initialState;
    }

    default:
      return state;
  }
};
