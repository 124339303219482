import classNames from 'classnames';
import { useCurrentUser } from '../../../utils/hooks';
import {
  DropDownMenu,
  IDropdownMenuItem,
} from '../../dropdown-menu';

interface IPostActionProps {
  post: any;
  ownPostOptions: IDropdownMenuItem[];
  otherUsersPostOptions: IDropdownMenuItem[];
  isOwnSpotlight?: boolean;
  isLivePage?: boolean;
}

export const PostActions = ({
  post,
  ownPostOptions,
  otherUsersPostOptions,
  isOwnSpotlight,
  isLivePage
}: IPostActionProps) => {
  const currentUser = useCurrentUser();

  return (
    <>
      <DropDownMenu
        post={post}
        options={
          isOwnSpotlight ? (
            currentUser.id === post.creatorId
            ? ownPostOptions
            : otherUsersPostOptions
          ) : (
            currentUser.id === post.creator.id
            ? ownPostOptions
            : otherUsersPostOptions
          )
        }
        fillColorActive={isOwnSpotlight}
        fillColor={isOwnSpotlight ? "WHITE" : "GRAY"}
        containerClasses='flex justify-center items-center relative'
        menuCardClasses='absolute top-2 w-[128px] opacity-100 bg-white -ml-32 mt-4 shadow-xs2 text-gray-13 rounded-15 p-2 z-20'
        titleAndSubtitleGroup='flex justify-left items-left hover:bg-gray-1 text-left p-2 rounded-5 text-gray-13 cursor-pointer'
        buttonStyles={classNames('flex items center w-30 h-30 ml-2 -mt-2', {
          "!w-[20px] !h-[20px] !mt-[-17px] !mr-[-3px]": isLivePage
        })}
        listTextStyles='text-[13px] text-[#515357] tracking-wide ml-3'
        listIconStyles='w-[17px]'
        listIconDiv='w-[18px]'
        textAndImageContainer='flex w-full h-full items-center'
        isLivePage={isLivePage}
      />
    </>
  );
};

export default PostActions;
