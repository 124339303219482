import { useState } from "react";

export const ConnectionSvgIcon = () => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const colorBlue = "#0049EF";
  const defaultColor = "#7283A4";
  const fillColor = isHover ? colorBlue : defaultColor;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className="cursor-pointer"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <g
        id="Group_17624"
        data-name="Group 17624"
        transform="translate(-965.229 -8)"
      >
        <rect
          id="Rectangle_7695"
          data-name="Rectangle 7695"
          width="40"
          height="40"
          rx="15"
          transform="translate(965.229 8)"
          fill="#e9eff2"
        />
        <g
          id="Group_19822"
          data-name="Group 19822"
          transform="translate(-7424.829 -5323.37)"
        >
          <path
            id="Subtraction_6"
            data-name="Subtraction 6"
            d="M1.462,16.282h-1A.462.462,0,0,1,.053,15.6a5.949,5.949,0,0,1,8.741-1.92s.255.171.43.321a4.5,4.5,0,0,0-1.251,1.286c-.081-.069-.167-.134-.257-.2a4.219,4.219,0,0,0-5.883.994.454.454,0,0,1-.371.2ZM5.312,11.13c-2.424,0,1.465,0,0,0Z"
            transform="translate(8397.915 5345.579)"
            fill={fillColor}
            stroke="#7283a4"
            strokeWidth="0.5"
          />
          <path
            id="Path_14795"
            data-name="Path 14795"
            d="M11.136,0A4.932,4.932,0,1,1,6.2,4.933,4.933,4.933,0,0,1,11.136,0"
            transform="translate(8391.712 5347.453)"
            fill={fillColor}
          />
          <path
            id="Subtraction_7"
            data-name="Subtraction 7"
            d="M23.732,25.274H22.181a.713.713,0,0,1-.587-.312,6.588,6.588,0,0,0-10.729,0,.715.715,0,0,1-.579.311H8.718a.721.721,0,0,1-.635-1.057A9.3,9.3,0,0,1,24.366,24.2a.724.724,0,0,1-.63,1.073Zm-7.438-8.68A8.225,8.225,0,0,1,11.3,14.922,5.624,5.624,0,0,0,8,8.1a8.3,8.3,0,1,1,8.3,8.492Z"
            transform="translate(8397.61 5338.733)"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  );
};
