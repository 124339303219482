import { Dispatch } from '@reduxjs/toolkit';
import { interactiveEventSlice } from '..';
import { _expoWebSocket } from "../../../utils/hooks";

// WebSocket Template
const sendWsMessage = (message: any) => {
    const webSocket = _expoWebSocket;
    return webSocket && webSocket.then((ws) => ws.send(JSON.stringify(message))).catch(console.error);
}

export const InteractiveModeratorSpeakerActiveList = (eventId: string, eventRoomId: string) => async (dispatch: Dispatch) => {
    sendWsMessage({
        action: 'interactive/active-list',
        data: { eventId, eventRoomId }
    });
    dispatch(interactiveEventSlice.actions.moderatorSpeakerActiveList());
}

export const InteractiveModeratorSpeakerQueueList = (eventId: string, eventRoomId: string) => async (dispatch: Dispatch) => {
    sendWsMessage({
        action: 'interactive/queue-list',
        data: { eventId, eventRoomId }
    });
    dispatch(interactiveEventSlice.actions.moderatorSpeakerQueueList());
}

export const InteractiveModeratorSpeakerApproveUser = (eventId: string, eventRoomId: string, userId: string) => async (dispatch: Dispatch) => {
    sendWsMessage({
        action: 'interactive/approve-user',
        data: { userId, eventId, eventRoomId }
    });
    dispatch(interactiveEventSlice.actions.moderatorSpeakerApproveUser());
}

export const InteractiveModeratorSpeakerRemoveQueueUser = (eventId: string, eventRoomId: string, userId: string) => async (dispatch: Dispatch) => {
    sendWsMessage({
        action: 'interactive/remove-queue-user',
        data: { userId, eventId, eventRoomId }
    });
    dispatch(interactiveEventSlice.actions.moderatorSpeakerRemoveQueueUser());
}

export const InteractiveModeratorSpeakerKickUser = (eventId: string, eventRoomId: string, userId: string) => async (dispatch: Dispatch) => {
    sendWsMessage({
        action: 'interactive/kick-user',
        data: { userId, eventId, eventRoomId }
    });
    dispatch(interactiveEventSlice.actions.moderatorSpeakerKickUser());
}

export const InteractiveModeratorSpeakerInviteAttendee = (eventId: string, eventRoomId: string, userId: string) => async (dispatch: Dispatch) => {
    sendWsMessage({
        action: 'interactive/invite-attendee',
        data: { userId, eventId, eventRoomId }
    });
    dispatch(interactiveEventSlice.actions.moderatorSpeakerInviteAttendee());
}

export const InteractiveModeratorSpeakerUserExit = (eventId: string, eventRoomId: string, userId: string) => async (dispatch: Dispatch) => {
    sendWsMessage({
        action: 'interactive/user-exit',
        data: { userId, eventId, eventRoomId }
    });
    dispatch(interactiveEventSlice.actions.moderatorSpeakerInviteAttendee());
}

export const InteractiveModeratorSpeakerMuteAll = (eventId: string, eventRoomId: string) => async (dispatch: Dispatch) => {
    sendWsMessage({
        action: 'interactive/mute-all',
        data: { eventId, eventRoomId }
    });
    dispatch(interactiveEventSlice.actions.moderatorSpeakerMuteAll());
}

export const InteractiveModeratorSpeakerMuteUser = (eventId: string, eventRoomId: string, userId: string) => async (dispatch: Dispatch) => {
    sendWsMessage({
        action: 'interactive/mute-all',
        data: { userId, eventId, eventRoomId }
    });
    dispatch(interactiveEventSlice.actions.moderatorSpeakerMuteUser());
}