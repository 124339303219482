/* eslint-disable max-len */
/* eslint-disable no-console */
import {
  Box,
  Button, IconButton, Stack
} from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { FilUploadService } from 'src/services/FileUpload';
import { UploadBox } from 'src/mui/components/upload';
import { useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import { ImagePreviewTab } from '../../company-enum';
import CompanyTabs from './CompanyTabs';

const ImagePreviewContainer = () => {
  const [activeTab, setActiveTab] = useState<string>(ImagePreviewTab.DESKTOP);
  const { setValue, getValues, watch } = useFormContext();

  const defaultPhoneImages = watch('phoneUrls');
  const defaultTabletImages = watch('tabletUrls');
  const defaultDesktopImages = watch('desktopUrls');

  const formValues = getValues();
  const fileUploadService = useMemo(() => new FilUploadService(), []);

  const [desktopImages, setDesktopImages] = useState<(File | string)[]>([]);
  const [phoneImages, setPhoneImages] = useState<(File | string)[]>([]);
  const [tabletImages, setTabletImages] = useState<(File | string)[]>([]);

  const [initialDesktopImages, setInitialDesktopImages] = useState<string[]>([]);
  const [initialPhoneImages, setInitialPhoneImages] = useState<string[]>([]);
  const [initialTabletImages, setInitialTabletImages] = useState<string[]>([]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const handleDrop = useCallback(
    async (acceptedFiles: File[], setImages: React.Dispatch<React.SetStateAction<(File | string)[]>>, setValueKey: string, currentUrls: string[]) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const filesArray = Array.from(acceptedFiles);
        const uploadedFilesPromises = filesArray.map(async (file) => {
          const data = new FormData();
          data.append('file', file);
          const uploadedFile = await fileUploadService.uploadImage(data);
          return uploadedFile?.data?.key;
        });
        const uploadedFileKeys = await Promise.all(uploadedFilesPromises);

        setImages((prevImages) => [...prevImages, ...filesArray]);
        setValue(setValueKey, [...(currentUrls || []), ...uploadedFileKeys]);
      }
    },
    [setValue, fileUploadService]
  );

  const handleDropDesktop = (files: File[]) => handleDrop(files, setDesktopImages, 'desktopUrls', formValues.desktopUrls);
  const handleDropPhone = (files: File[]) => handleDrop(files, setPhoneImages, 'phoneUrls', formValues.phoneUrls);
  const handleDropTablet = (files: File[]) => handleDrop(files, setTabletImages, 'tabletUrls', formValues.tabletUrls);

  const handleRemoveImage = (setImages: React.Dispatch<React.SetStateAction<(File | string)[]>>, index: number, initialImages: string[], setInitialImages: React.Dispatch<React.SetStateAction<string[]>>, formKey: string) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      if (typeof updatedImages[index] === 'string') {
        const removedImage = initialImages.find((img) => img === updatedImages[index]);
        if (removedImage) {
          setInitialImages((prev) => prev.filter((img) => img !== removedImage));
          const updatedFormValues = getValues()[formKey]?.filter((img: string) => img !== removedImage);
          setValue(formKey, updatedFormValues);
        }
      }
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  useEffect(() => {
    if (defaultDesktopImages || defaultTabletImages || defaultPhoneImages) {
      setDesktopImages(defaultDesktopImages || []);
      setPhoneImages(defaultPhoneImages || []);
      setTabletImages(defaultTabletImages || []);

      setInitialDesktopImages(defaultDesktopImages || []);
      setInitialPhoneImages(defaultPhoneImages || []);
      setInitialTabletImages(defaultTabletImages || []);
    }
  }, [defaultDesktopImages, defaultTabletImages, defaultPhoneImages]);

  const renderImagePreviews = (images: (File | string)[], initialImages: string[], setImages: React.Dispatch<React.SetStateAction<(File | string)[]>>, setInitialImages: React.Dispatch<React.SetStateAction<string[]>>, formKey: string) => (
    <Stack sx={{
      width: '100%',
      maxWidth: {
        xs: '100px', sm: '300px', md: '592px', lg: '892px'
      },
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
      gap: '5px'
    }}
    >
      {images.map((image, index) => (
        <Box key={index} sx={{ position: 'relative' }}>
          <img
            src={typeof image === 'string' ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${image}` : URL.createObjectURL(image)}
            alt={`preview-${index}`}
            className={classNames(' rounded-[5px] object-cover', {
              'w-full !min-w-[75px] h-[145px]': formKey === 'phoneUrls',
              'w-full !min-w-[70px] h-[90px]': formKey === 'tabletUrls',
              'w-full !min-w-[130px] h-[90px]': formKey === 'desktopUrls',
            })}
          />
          <IconButton
            sx={{
              position: 'absolute', top: -8, right: -8, backgroundColor: '#fff', padding: '2px'
            }}
            size="small"
            onClick={() => handleRemoveImage(setImages, index, initialImages, setInitialImages, formKey)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      ))}
    </Stack>
  );

  return (
    <Stack sx={{
      width: '100%', height: '307px', border: '1px solid #D3D3D3', borderRadius: '5px', padding: '25px', display: 'flex', justifyContent: 'space-between',
    }}
    >
      <Stack sx={{ display: 'flex' }}>
        <CompanyTabs handleTabCahnge={handleTabChange} activeTab={activeTab} />

        {activeTab === ImagePreviewTab.DESKTOP && renderImagePreviews(desktopImages, initialDesktopImages, setDesktopImages, setInitialDesktopImages, 'desktopUrls')}
        {activeTab === ImagePreviewTab.PHONE && renderImagePreviews(phoneImages, initialPhoneImages, setPhoneImages, setInitialPhoneImages, 'phoneUrls')}
        {activeTab === ImagePreviewTab.TABLET && renderImagePreviews(tabletImages, initialTabletImages, setTabletImages, setInitialTabletImages, 'tabletUrls')}
      </Stack>

      <Button
        sx={{
          width: '100%', height: '41px', backgroundColor: '#EDF2F5', borderRadius: '5px', color: 'black', fontFamily: 'Poppins-500', fontWeight: '500', fontSize: '15px', textTransform: 'capitalize', mt: activeTab === ImagePreviewTab.PHONE ? '10px' : '0px',
        }}
      >
        <UploadBox
          placeholder="+ Add a Photo"
          sx={{
            backgroundColor: 'transparent', border: 'none', width: '100%', color: 'black', fontFamily: 'Poppins-500', fontWeight: '500', fontSize: '15px', textTransform: 'capitalize'
          }}
          accept={{ 'image/*': [] }}
          onDrop={
            // eslint-disable-next-line no-nested-ternary
            activeTab === ImagePreviewTab.DESKTOP
              ? handleDropDesktop
              : activeTab === ImagePreviewTab.PHONE
                ? handleDropPhone
                : handleDropTablet
          }
        />
      </Button>
    </Stack>
  );
};

export default ImagePreviewContainer;
