import { useEffect, useState } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router-dom';

import { getOwnConnections } from 'src/store/user/own-connections/actions';
import commentIcon from '../../../assets/images/comment-icon.svg';
import shareIcon from '../../../assets/images/share-icon.svg';
import { UserNavbar } from '../../../components';
import { CardMainPost } from '../../../components/posts';
import { ICreateComment } from '../../../models/post/comment';
import { IAppState } from '../../../store';
import {
  likeComment,
  unlikeComment,
} from '../../../store/events/event-post-comment-like/actions';
import {
  likePost,
  unlikePost,
} from '../../../store/events/upcoming-event-post-like/actions';
import { getPost } from '../../../store/posts/actions';
import { createPostComment } from '../../../store/posts/comments/actions';
import {
  getUserPostComments,
  // getUserPosts,
} from '../../../store/user/posts/actions';

export const PostDetail = () => {
  const params = useParams();
  const postId: string = String(Object.values(params)[0]);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const { slug: userId } = useParams();

  const { post, auth } = useSelector((state: IAppState) => state);
  const { currentUser: user } = auth;
  const dispatch = useDispatch();

  useEffect(() => {
    setIsOwnProfile(user?.id === userId || user?.slug === userId);
  }, [userId, user]);

  useEffect(() => {
    if (isOwnProfile) {
      dispatch(getOwnConnections());
    } else dispatch(getOwnConnections(userId));
  }, [dispatch, isOwnProfile, userId]);

  useEffect(() => {
    dispatch(getPost(postId));
  }, [dispatch, postId]);

  return (
    <div className="bg-gray-1">
      <UserNavbar />
      <div className="w-793 h-[89vh] mx-auto">
        <div className="container mx-auto mt-6" />
        <div className="bg-white rounded-lg px-4 py-4 -ml-64" key={postId}>
          <CardMainPost
            commentIcon={commentIcon}
            shareIcon={shareIcon}
            className="mt-4 mb-4 w-full bg-white rounded-lg"
            post={post.value!}
            getPostComments={(id: string) => dispatch(getUserPostComments(id))}
            addPostComment={async (
              _postId: string,
              data: any,
              attachment: File
            ) => {
              const comment: ICreateComment = {
                content: data,
                parentId: '',
                userMentioned: '',
              };
              await dispatch(createPostComment(_postId, comment, attachment));
              await dispatch(getUserPostComments(_postId));
              // await dispatch(getUserPosts());
            }}
            onCommentLike={async (likeType: string, commentId: string) => {
              if (likeType) {
                await dispatch(likeComment(commentId, likeType));
              } else {
                await dispatch(unlikeComment(commentId));
              }
              // await dispatch(getUserPosts());
            }}
            onPostLike={async (likeType: string, _postId: string) => {
              // If you got like type then set the like on post else its remove like 'undefined'
              if (likeType) {
                await dispatch(likePost(_postId, likeType));
              } else {
                await dispatch(unlikePost(_postId));
              }
              // await dispatch(getUserPosts());
            }}
          />
        </div>
      </div>
    </div>
  );
};
