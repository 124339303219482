import { useState } from "react"

export interface IVideoCallSvgIcon {
    fillColor?: "WHITE" | "GRAY";
    onClick?: Function;
}
export const VideoCallSvgIcon = ({ fillColor, onClick }: IVideoCallSvgIcon) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const color = fillColor === "GRAY" ? '#edf2f5' : '#ffffff';

    return <svg id="Icon_awesome-video" data-name="Icon awesome-video" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
        onClick={() => onClick!()}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        {
            isHover && <circle id="Ellipse_1122" data-name="Ellipse 1122" cx="14" cy="14" r="14" fill={color}/>
        }
        <g id="Component_56" data-name="Component 56" transform="translate(-0.78 0.587)">
        <path id="Icon_awesome-video-2" data-name="Icon awesome-video" d="M10.894,4.5H1.549A1.549,1.549,0,0,0,0,6.049v9.345a1.549,1.549,0,0,0,1.549,1.549h9.345a1.549,1.549,0,0,0,1.549-1.549V6.049A1.549,1.549,0,0,0,10.894,4.5Zm5.05,1.222-3.5,2.447v5.1l3.5,2.449a1.039,1.039,0,0,0,1.633-.836V6.558A1.039,1.039,0,0,0,15.944,5.722Z" transform="translate(4.859 3.256)" fill="#7283A4"/>
        </g>
    </svg>
}


