import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { RegoFormColors } from 'src/mui/types/survey';
import useStyling from './useStyling';
import { ReactNode } from 'react';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  sx?: any;
  placeholder?: ReactNode;
};

export default function RHFTextField({ name, helperText, type, sx, placeholder, ...other }: Props) {
  const { control } = useFormContext();
  const { styles } = useStyling(null, null)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
        <TextField
          {...field}
          fullWidth
          type={type}
          placeholder={placeholder || ''}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}

          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
          sx={{ ...sx, "& .MuiFormHelperText-root": { ml: "1px" } }}
        />
      )}}
    />
  );
}
