import classNames from 'classnames';
import { IEventSessionSpeaker } from '../../../models/event/event-speaker';
import DefaultAvatar from '../../../assets/images/avatar-default.svg';
// import { Avatar } from '../../../shared-components/V2';

interface IWebsiteEventSpeakerList {
  speakers: IEventSessionSpeaker[];
  roomQty?: number;
  isOpen?: boolean;
}

const WebsiteEventSpeakerList = ({ speakers, roomQty, isOpen }: IWebsiteEventSpeakerList) => (
  <div
    className={
      classNames(
        {
          '!p-0 mb-[15px]': roomQty === 2,
          'max-w-[215px] min-w-[215px]': isOpen
        }
      )
    }
  >
    {/* {
      !!speakers && speakers?.length > 0
      && <div className="text-[14px] text-white font-medium h-[20px] mb-2.5">Speakers</div>
    } */}
    {!!speakers && speakers?.map((speaker: any) => {
      const speakerName = `${speaker.firstName} ${speaker.lastName}`;

      return (
        <div className="flex mb-2.5 last:mb-0">
          <div className="w-full mx-auto rounded-10">
            <div
              className={
                classNames(
                  'text-left flex ',
                  {
                    'mr-0': isOpen
                  }
                )
              }
            >
              {/* <Avatar src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${speaker.photo}`} size="LARGE" /> */}
              {speaker.photo ? (
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${speaker.photo}`}
                  alt={speaker.company?.name}
                  className="w-[60px] h-[60px] min-w-[60px] min-h-[60px] object-cover rounded-[6px]"
                />
              ) : (
                <img src={DefaultAvatar} alt={speaker.company?.name} className="w-[60px] h-[60px] min-w-[60px] min-h-[60px] object-cover rounded-[6px] bg-[#edf2f5]" />
              )}

              <div className="flex flex-col justify-center pl-[10px]">
                <div className="text-[15px] leading-[20px] text-white font-medium line-clamp-1 helvetica-neue-45-light">{speakerName}</div>
                <div className="text-[15px] leading-[20px] text-white font-normal line-clamp-1 helvetica-neue-45-light">
                  {speaker.jobTitle}
                </div>
                <div className="text-[15px] leading-[20px] text-white font-normal line-clamp-1 helvetica-neue-45-light">
                  {speaker.company?.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

export default WebsiteEventSpeakerList;
