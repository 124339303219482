import { Button, Stack } from '@mui/material';
import LeaveFeedbackDialog from './live/LeaveFeedbackDialog';
import { getBrandingColors } from './live/liveEventStyling';

export function VisitBoothFooter(props: {
  onClick: () => Promise<void>,
  colors: { Main: string | undefined; Secondary: string | undefined; Accent: string | undefined; Text: string | undefined },
  selectedSponsorBooth: any,
  onClick1: () => void,
  open: boolean,
  openFeedbackPopup: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  onClose: () => void,
  onClick2: () => void
}) {
  return <Stack
    direction="row"
    sx={{
      position: 'absolute',
      bottom: 0,
      right: 0,
      gap: '5px',
      height: '70px',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: '80px',
    }}
  >
    <Button
      onClick={props.onClick}
      sx={{
        borderRadius: '5px',
        color: getBrandingColors()?.ButtonTextColor || '#ffffff',
        height: '29px',
        lineHeight: '29px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        fontFamily: 'Poppins-600',
        background: props.colors?.Accent || '#0049EF',
        textTransform: 'none',
        // width: "231px",
        padding: '0px 20px',
        maxWidth: '310px',
        fontWeight: 600,
        '&:hover': {
          background: props.colors?.Accent || '#EDF2F5',
          opacity: '0.5'
        },
      }}
      className="ellipsis-one-line"
    >
      Join a live call with {props.selectedSponsorBooth?.company?.name}
    </Button>

    <Button
      onClick={props.onClick1}
      sx={{
        borderRadius: '5px',
        color: getBrandingColors()?.ButtonTextColor || '#203C6E',
        height: '29px',
        lineHeight: '29px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        fontFamily: 'Poppins-600',
        background: props.colors?.Secondary || '#EDF2F5',
        textTransform: 'none',
        width: '140px',
        padding: '0px',
        fontWeight: 600,
        '&:hover': {
          background: props.colors?.Secondary || '#EDF2F5',
          opacity: '0.5'
        },
      }}
    >
      Leave feedback
    </Button>

    <LeaveFeedbackDialog
      selectedSponsorDetails={props.selectedSponsorBooth}
      open={props.open}
      setOpenFeedbackPopup={props.openFeedbackPopup}
      onClose={props.onClose}
    />

    <Button
      onClick={props.onClick2}
      sx={{
        borderRadius: '5px',
        color: getBrandingColors()?.ButtonTextColor || '#203C6E',
        height: '29px',
        lineHeight: '29px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        fontFamily: 'Poppins-600',
        background: props.colors?.Secondary || '#EDF2F5',
        textTransform: 'none',
        width: '98px',
        padding: '0px',
        fontWeight: 600,
        '&:hover': {
          background: props.colors?.Secondary || '#EDF2F5',
          opacity: '0.5'
        },
      }}
    >
      Exit booth
    </Button>
  </Stack>;
}
