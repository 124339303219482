/* eslint-disable max-len */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const generalsQuestions = [
  {
    question: 'What can I expect from attending a Sit-Down?',
    answer: 'Attendees can look forward to engaging in a variety of formats including peer connect meetings, executive roundtable discussions, and keynotes and panels. Each session is moderated by a dedicated host, offering insights tailored to the focus areas of the participants, and provides a chance to network with fellow CxO leaders in a convivial setting over a full day. ',
  },

  {
    question: 'What is required to attend a Sit-Down?',
    answer: 'To attend a Sit-Down, participants are required to have a purchased ticket. At times, a qualifying call with us may be necessary to understand your objectives behind attendance. Additionally, always required is the completion of a survey that helps us highlight business and technology drivers across the local market. The insights from this survey are shared with participants during and post-event to enrich the overall experience.',
  },

  {
    question: 'How many attendees are there at each Sit-Down?',
    answer: 'Each Sit-Down welcomes around 100 CxO leaders, ensuring a diverse and dynamic environment for quality interactions and networking.',
  },

  {
    question: ' Who typically sponsors these sessions?',
    answer: 'Up to 10 carefully-vetted sponsors back each session, aligning their solutions with the focus areas of the attendees.',
  },
  {
    question: 'How is the discussion topic decided for each session?',
    answer: ' Topics are meticulously curated based on a research-driven approach, ensuring the content resonates with both the sponsors’ solutions and the specific areas of interest of our attendees.',
  },
  {
    question: 'Can I suggest topics or areas of interest for future Sit-Downs?',
    answer: 'Absolutely! We highly value the insights and preferences of our CxO attendees and welcome topic suggestions for future sessions.',
  },

  {
    question: 'How long is each Sit-Down?',
    answer: 'The Sit-Down is a full-day event consisting of 10-15 sessions, each lasting about 20 minutes, including a combination of peer connect meetings, executive roundtable discussions, and keynotes and panels.',
  },

  {
    question: ' Is there a fee for attending?',
    answer: 'Attendance is possible through one of the following:',
    option1: 'Tickets: £1,899+GST',
    option2: 'Annual membership: £8,399+GST, depending on the package purchased.',
  },

  {
    question: 'How can I ensure I get the most out of the Sit-Down?',
    answer: 'To maximise the experience, actively participate in discussions, share your perspectives, forge connections with fellow attendees, and take full advantage of the networking opportunities. The more you engage, the richer your experience.',
  },

];

export const General = () => (
  <div id="General" className="w-[100%] max-w-[723px] md:my-[135px] my-[50px] mx-auto">
    <div className="w-full">
      <p className="text-[15px] font-medium leading-[15px] text-white helvetica-neue-65-medium mb-[12px]">
        General
      </p>
      { generalsQuestions?.map((question, index) => (
        <Accordion
          key={`general-${index}`}
          sx={{
            display: 'flex',
            flexFlow: 'wrap',
            boxShadow: 'none',
            mb: '8px',
            '& .MuiAccordionSummary-root': {
              width: '100%',
            },
            '& .MuiPaper-root': {
              background: '#14161A',
              mb: '8px',
            },
            '& .MuiCollapse-root': {
              width: '100%'
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              background: '#14161A',
              '& .MuiAccordionSummary-expandIconWrapper': {
                color: 'white'
              },
            }}
          >
            <div className="text-[15px] font-normal text-white helvetica-neue-45-light">
              {question?.question}
            </div>

          </AccordionSummary>
          <AccordionDetails sx={{ background: '#14161A', width: '100%' }}>
            <div className="text-[15px] font-normal text-[#c1c1c1] helvetica-neue-45-light">
              {question?.answer}
            </div>

            {question?.option1 && (
            <div className="text-[15px] font-normal text-[#c1c1c1] helvetica-neue-45-light mt-[12px]">
              {question?.option1}
            </div>
            ) }

            {question?.option2 && (
            <div className="text-[15px] font-normal text-[#c1c1c1] helvetica-neue-45-light mt-[12px]">
              {question?.option2}
            </div>
            ) }
          </AccordionDetails>
        </Accordion>
      )) }
    </div>
  </div>
);
export default General;
