import axios from 'axios';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../../../shared-components/V2';
import WebsiteEventQuickLinks from '../website-event-quicklinks';
import ComplimentaryPassForm from './complimentary-pass-form';

export const TicketForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedPlan, setSelectedPlan] = useState('paid');
  const [ticketDetail, setTicketDetail] = useState<any>();

  const eventSlug = location?.pathname?.split('/')[2];

  useEffect(() => {
    axios.get('https://ea41f9f8-6143-4c97-9ec3-95b0c12ecaf1.mock.pstmn.io/ticket-plans')
      .then(response => {
        setTicketDetail(response?.data);
      });
  }, []);

  useEffect(() => {
    const eventStep = sessionStorage.getItem('eventRegStep');
    if (eventStep === '2') {
      navigate(`/event/${eventSlug}/spectre/select-plan`);
    } else if (eventStep === '3') {
      navigate(`/event/${eventSlug}/spectre/successfully-registered`);
    } else {
      navigate(`/event/${eventSlug}/spectre`);
    }
  }, [eventSlug, navigate, ticketDetail]);

  const nextPage = () => {
    window.location.replace('https://www.sit-down.com/sit-downs/cdociociso-may/reg');
    sessionStorage.removeItem('eventRegStep');
  };

  return (
    <div className="w-full">
      <div className="flex -mb-[30px] h-[100vh]">
        <div className="bg-[#2d2d2d] pt-[20px] pr-[40px] pb-[30px] pl-[40px] w-full md:max-w-[60%] flex items-center justify-center">
          <div className="w-full">
            <div className="text-white text-left text-[35px] leading-[40px] helvetica-neue-55-roman mb-[20px]">Select an option</div>
            <div className="flex gap-x-[10px]">
              {ticketDetail?.paid && (
                <div
                  className={classNames('w-[50%] rounded-5 p-[8px] text-center cursor-pointer', {
                    'bg-white': selectedPlan === 'paid',
                    'bg-black': selectedPlan === 'free',
                  })}
                  onClick={() => setSelectedPlan('paid')}
                >
                  <div
                    className={classNames('text-[16px] leading-[20px] mb-[10px] helvetica-neue-65-medium', {
                      'text-black': selectedPlan === 'paid',
                      'text-white': selectedPlan === 'free',
                    })}
                  >
                    {ticketDetail?.paid?.name}
                  </div>
                  <div
                    className={classNames('text-[18px] leading-[20px] mb-[14px] helvetica-neue-45-light', {
                      'text-black': selectedPlan === 'paid',
                      'text-white': selectedPlan === 'free',
                    })}
                  >
                    {ticketDetail?.paid?.price}
                  </div>
                  <div className="mb-[14px]">
                    <div
                      className={classNames('text-[12px] leading-[16px] helvetica-neue-65-medium', {
                        'text-black': selectedPlan === 'paid',
                        'text-white': selectedPlan === 'free',
                      })}
                    >
                      {ticketDetail?.paid?.optional?.title}
                    </div>
                    <div
                      className={classNames('text-[12px] leading-[16px] helvetica-neue-45-light', {
                        'text-black': selectedPlan === 'paid',
                        'text-white': selectedPlan === 'free',
                      })}
                    >
                      {ticketDetail?.paid?.optional?.text}
                    </div>
                  </div>
                  <div
                    className={classNames('text-[12px] leading-[16px] helvetica-neue-65-medium', {
                      'text-black': selectedPlan === 'paid',
                      'text-white': selectedPlan === 'free',
                    })}
                  >
                    {ticketDetail?.paid?.included?.title}
                  </div>
                  {ticketDetail?.paid?.included?.list.map((item: string, index: number) => (
                    <div
                      key={index}
                      className={classNames('text-[12px] leading-[16px] helvetica-neue-45-light', {
                        'text-black': selectedPlan === 'paid',
                        'text-white': selectedPlan === 'free',
                      })}
                    >
                      •
                      {' '}
                      {item}
                    </div>
                  ))}
                </div>
              )}
              {ticketDetail?.free && (
                <div
                  className={classNames('w-[50%] rounded-5 p-[8px] text-center cursor-pointer', {
                    'bg-white': selectedPlan === 'free',
                    'bg-black': selectedPlan === 'paid',
                  })}
                  onClick={() => setSelectedPlan('free')}
                >
                  <div
                    className={classNames('text-[16px] leading-[20px] mb-[10px] helvetica-neue-65-medium', {
                      'text-black': selectedPlan === 'free',
                      'text-white': selectedPlan === 'paid',
                    })}
                  >
                    {ticketDetail?.free.name}
                  </div>
                  <div className="mb-[12px]">
                    <div
                      className={classNames('text-[18px] leading-[20px] helvetica-neue-45-light', {
                        'text-black': selectedPlan === 'free',
                        'text-white': selectedPlan === 'paid',
                      })}
                    >
                      {ticketDetail?.free.price}
                    </div>
                    <div
                      className={classNames('text-[12px] leading-[16px] helvetica-neue-45-light', {
                        'text-black': selectedPlan === 'free',
                        'text-white': selectedPlan === 'paid',
                      })}
                    >
                      {ticketDetail?.free.message}
                    </div>
                  </div>
                  <div className="mb-[14px]">
                    <div
                      className={classNames('text-[12px] leading-[16px] helvetica-neue-65-medium', {
                        'text-black': selectedPlan === 'free',
                        'text-white': selectedPlan === 'paid',
                      })}
                    >
                      {ticketDetail?.free?.optional?.title}
                    </div>
                    <div
                      className={classNames('text-[12px] leading-[16px] helvetica-neue-45-light', {
                        'text-black': selectedPlan === 'free',
                        'text-white': selectedPlan === 'paid',
                      })}
                    >
                      {ticketDetail?.free?.optional?.text}
                    </div>
                  </div>
                  <div
                    className={classNames('text-[12px] leading-[16px] helvetica-neue-65-medium', {
                      'text-black': selectedPlan === 'free',
                      'text-white': selectedPlan === 'paid',
                    })}
                  >
                    {ticketDetail?.free?.included?.title}
                  </div>
                  {ticketDetail?.free?.included?.list.map((item: string, index: number) => (
                    <div
                      key={index}
                      className={classNames('text-[12px] leading-[16px] helvetica-neue-45-light', {
                        'text-black': selectedPlan === 'free',
                        'text-white': selectedPlan === 'paid',
                      })}
                    >
                      •
                      {' '}
                      {item}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {selectedPlan === 'free' && (
              <div className="w-full flex justify-end">
                <div className="w-[50%] pl-[5px]"><ComplimentaryPassForm /></div>
              </div>
            )}
            {selectedPlan !== 'free' && (
              <div className="flex justify-end mt-[20px]">
                <Button
                  type="submit"
                  size="small"
                  text="Next"
                  onClick={nextPage}
                  className="bg-[#0071e3] !text-[13px] !font-normal hover:bg-[#007fff] text-white tracking-wide h-[32px] w-[70px] p-0 helvetica-neue-55-roman"
                />
              </div>
            )}
          </div>
        </div>
        <div className="reg-bg md:w-[40%]" />
      </div>
      <WebsiteEventQuickLinks />
    </div>
  );
};
export default TicketForm;
