import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IModalState } from '../../models/modal/ModalState';
import { ModalActionTypes } from './types';

export interface IShowModalAction {
  type: ModalActionTypes.SHOW_MODAL;
  isOpen: boolean;
}

export interface IHideModalAction {
  type: ModalActionTypes.HIDE_MODAL;
  isOpen: boolean;
}

export type ModalActions = IShowModalAction | IHideModalAction;

export const showModal: ActionCreator<
  ThunkAction<Promise<any>, IModalState, null, IShowModalAction>
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL,
      isOpen: true
    });
  };
};

export const hideModal: ActionCreator<
  ThunkAction<Promise<any>, IModalState, null, IHideModalAction>
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ModalActionTypes.HIDE_MODAL,
      isOpen: false
    });
  };
};
