/* eslint-disable no-console */
import {
  useEffect,
  useState
} from 'react';

import {
  useDispatch,
  useSelector
} from 'react-redux';

import { IAttendee } from '../../../models/events-live/attendee';
import { IEvent } from '../../../models/event/event';
import { IRoom } from '../../../models/event/room';
import { IUserProfile } from '../../../models/user-profile';
import { IAppState } from '../../../store';
import { clearLiveMessages, handleFailedMessages } from '../../../store/privateChat';
import { useWebSocket } from '../../../utils/hooks';
import ChatControls from './chat-controls';
import ChatRoom from './chatroom';
import RoomSwitcher from './room-switcher';
import Sessions from './sessions';
import { setActiveRoom as setActiveRoomStore } from '../../../store/liveEvent';

type ChatProps = {
  rooms: IRoom[];
  user: IUserProfile;
  id: string;
  event: IEvent;
  attendees?: IAttendee[];
};

const Chat = (props: ChatProps) => {
  const [activeView, setActiveView] = useState<'chat' | 'sessions'>('chat');
  const [isOnline, setIsOnline] = useState<any>();
  const chatRooms = props.event.rooms;
  const [activeRoom, setActiveRoom] = useState(chatRooms[0]);
  const { Items: messages } = useSelector((state: IAppState) => state.privateChat.eventChatMessages);
  const dispatch = useDispatch();
  const { webSocket, refreshConnection } = useWebSocket();
  // eslint-disable-next-line no-console
  useEffect(() => {
    dispatch(clearLiveMessages());
    dispatch(setActiveRoomStore(activeRoom));
  }, [activeRoom, dispatch]);

  // Get chat-room chat
  useEffect(() => {
    if (!activeRoom?.id) return;
    webSocket.then(ws => ws.send(
      JSON.stringify({
        action: 'live-messages',
        data: {
          eventId: props.id,
          eventRoomId: activeRoom?.id,
          option: {
            limit: 5,
            sorted: false,
            exclusiveStartKey: null
          }
        }
      })
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoom, webSocket]);

  useEffect(() => {
    webSocket.then(ws => ws.send(JSON.stringify({ action: 'join-event', data: { eventId: props.id } })));
  }, [props.id, webSocket]);

  // ononline version
  window.ononline = () => {
    setIsOnline(true);
  };

  // onofflinee version
  window.onoffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    if (isOnline) {
      webSocket.then(ws => {
        ws.send('{"action": "ping","data": { "data": "PONG"}}');
      });
    }
  }, [isOnline, webSocket]);

  const addMessage = async (
    _sender: string,
    message: string,
    fileUrl: string,
    fileType: string
  ) => {
    if (activeRoom) {
      const ws = await webSocket;
      let wsMessage = '';
      if (fileUrl !== '') {
        if (fileType === 'image') {
          wsMessage = JSON.stringify({
            action: 'live-send-message',
            data: {
              eventId: props.id,
              eventRoomId: activeRoom.id,
              type: 'image',
              message: fileUrl,
              metadata: ''
            }
          });
        }

        if (fileType === 'document') {
          wsMessage = JSON.stringify({
            action: 'live-send-message',
            data: {
              eventId: props.id,
              eventRoomId: activeRoom.id,
              type: 'document',
              message: fileUrl,
              metadata: ''
            }
          });
        }
      }

      if (message !== '') {
        wsMessage = JSON.stringify({
          action: 'live-send-message',
          data: {
            eventId: props.id,
            eventRoomId: activeRoom.id,
            type: 'text',
            message,
            metadata: ''
          }
        });
      }
      if (ws.readyState !== ws.OPEN) {
        dispatch(handleFailedMessages(wsMessage));
        return refreshConnection({});
      }
      return ws.send(wsMessage!);
    }
    return message;
  };

  return (
    <div className="overflow-y-hidden h-full z-20 flex flex-col">
      <div className="flex h-9 mb-2">
        <div
          className={`${activeView === 'chat' ? 'bg-blue text-white ' : 'text-blue-2 bg-white'}  px-4 py-2  w-1/2 text-center rounded-tl-xl text-h2 font-bold flex items-center justify-center`}
          onClick={() => setActiveView('chat')}
        >
          Event discussions
        </div>
        <div
          className={`${activeView === 'sessions' ? 'bg-blue text-white ' : 'text-blue-2 bg-white'}  px-4 py-2  w-1/2 text-center rounded-tr-xl text-h2 font-bold flex items-center justify-center`}
          onClick={() => setActiveView('sessions')}
        >
          Sessions
        </div>
      </div>
      {activeView === 'chat' && (
        <div className="bg-white rounded-b-xl overflow-y-auto flex-1 h-full overflow-y-hidden flex flex-col">
          <RoomSwitcher
            attendees={props.attendees || []}
            rooms={props.rooms}
            activeRoom={activeRoom}
            onClick={(room: IRoom) => {
              setActiveRoom(room);
              dispatch(setActiveRoomStore(room));
            }}
          />
          <div className="px-4 flex-1">
            <ChatRoom user={props.user} messages={messages} />
            <ChatControls
              avatar={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${props.user.avatar}`}
              sendMessage={(msg: string, fileUrl: string, fileType: string) => addMessage(props.user.firstName, msg, fileUrl, fileType)}
            />
          </div>
        </div>
      )}
      {activeView === 'sessions' && (
        <div className="bg-white rounded-b-xl overflow-y-auto flex-1 h-full overflow-y-hidden flex flex-col">
          <Sessions days={props.rooms} />
        </div>
      )}
    </div>
  );
};

export default Chat;
