import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { EventWebsiteTemplateType } from 'src/mui/types/survey';
import { ArrowUpSvgIcon } from 'src/shared-components/V2/IconSvg/ArrowUpSvgIcon/ArrowUpSvgIcon';
import { ArrowDownSvgIcon } from 'src/shared-components/V2/IconSvg';
// import downArrow from '../../../assets/images/website-event/arrow-down.svg';
// import upArrow from '../../../assets/images/website-event/arrow-up.svg';

import { IAppState } from '../../../store';
import { ParseContent } from './parse-html';

const EventOverview = () => {
  const event = useSelector((state: IAppState) => state.website.event);
  const targetAudiences = useSelector(
    (state: IAppState) => state.website.targetAudiences
  );
  const [showMoreIndustries, setShowMoreIndustries] = useState(false);
  const [showMoreAudiences, setShowMoreAudiences] = useState(false);

  const industries = targetAudiences?.industries.slice(0, 4);
  const jobTitles = targetAudiences?.jobTitles.slice(0, 4);

  return (
    <div
      id="event-focus"
      className={classNames('w-full mb-[42px]', {
        hidden: event?.overview === null,
      })}
    >
      {event?.overview && (
        <div
          className={`w-full ${
            event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS
              ? 'event-afterhours-box'
              : 'event-website-box'
          } mb-[26px]`}
        >
          <div className="py-[23px] md:mx-[65px] mx-5">
            <div
              className={classNames(
                'font-semibold text-[21px] leading-[25px] mb-[15px] pb-[16px] border-b-1 border-[#CDDCF2]',
                {
                  'text-[#0326E5]':
                    event?.websiteTemplate
                    !== EventWebsiteTemplateType.AFTER_HOURS,
                  'text-[#FFFFFF]':
                    event?.websiteTemplate
                    === EventWebsiteTemplateType.AFTER_HOURS,
                }
              )}
              style={{
                color: event?.colorStyle?.values?.heading,
              }}
            >
              Event Overview
            </div>
            <div
              className={classNames('text-[13px] leading-[17px] font-normal', {
                'text-[#3C3C3C] list-style-bullets-website':
                  event?.websiteTemplate
                  !== EventWebsiteTemplateType.AFTER_HOURS,
                'text-[#FFFFFF]':
                  event?.websiteTemplate
                  === EventWebsiteTemplateType.AFTER_HOURS,
              })}
            >
              <ParseContent content={event?.overview} fullText className="list-style-bullets" />
            </div>
          </div>
        </div>
      )}

      <div className="flex md:flex-row flex-col mx-auto gap-x-[26px] w-full mb-[]">
        {!!industries?.length && (
          <div
            className={`w-full ${
              event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS
                ? 'event-afterhours-box'
                : 'event-website-box'
            } md:mb-0 mb-[26px]`}
          >
            <div className="py-[23px] md:mx-[65px] mx-5">
              <div
                className={classNames(
                  'font-semibold text-[21px] leading-[25px] mb-[15px] pb-[16px] border-b-1 border-[#CDDCF2]',
                  {
                    'text-[#0326E5]':
                      event?.websiteTemplate
                      !== EventWebsiteTemplateType.AFTER_HOURS,
                    'text-[#FFFFFF]':
                      event?.websiteTemplate
                      === EventWebsiteTemplateType.AFTER_HOURS,
                  }
                )}
                style={{
                  color: event?.colorStyle?.values?.heading,
                }}
              >
                Industries
              </div>
              <div className="">
                {targetAudiences?.industries
                  && industries?.map((industry) => (
                    <li
                      key={industry.id}
                      className={classNames(
                        'text-[15px] leading-[24px] font-medium',
                        {
                          'text-[#3C3C3C]':
                            event?.websiteTemplate
                            !== EventWebsiteTemplateType.AFTER_HOURS,
                          'text-[#FFFFFF]':
                            event?.websiteTemplate
                            === EventWebsiteTemplateType.AFTER_HOURS,
                        }
                      )}
                    >
                      {industry?.name}
                    </li>
                  ))}
                {!showMoreIndustries
                  && targetAudiences?.industries?.length! > 4 && (
                    <div className="flex flex-row mt-2">
                      <div
                        className={classNames(
                          'text-[13px] font-bold cursor-pointer',
                          {
                            'text-[#3C3C3C]':
                              event?.websiteTemplate
                              !== EventWebsiteTemplateType.AFTER_HOURS,
                            'text-[#FFFFFF]':
                              event?.websiteTemplate
                              === EventWebsiteTemplateType.AFTER_HOURS,
                          }
                        )}
                        onClick={() => setShowMoreIndustries(true)}
                      >
                        Show more
                      </div>
                      <div className="ml-1 mt-1">
                        <ArrowDownSvgIcon
                          fillColor={
                          event?.websiteTemplate
                          === EventWebsiteTemplateType.AFTER_HOURS
                            ? '#FFFFFF'
                            : undefined
                        }
                        />
                      </div>
                      {/* <img src={downArrow} alt="" className="ml-1 mt-1" /> */}
                    </div>
                )}
              </div>
              {showMoreIndustries
                && targetAudiences?.industries?.length! > 4
                && targetAudiences?.industries
                  ?.slice(4, targetAudiences?.industries?.length - 1)
                  .map((industry) => (
                    <li
                      key={industry.id}
                      className={classNames(
                        'text-[15px] leading-[24px] font-medium',
                        {
                          'text-[#3C3C3C]':
                            event?.websiteTemplate
                            !== EventWebsiteTemplateType.AFTER_HOURS,
                          'text-[#FFFFFF]':
                            event?.websiteTemplate
                            === EventWebsiteTemplateType.AFTER_HOURS,
                        }
                      )}
                    >
                      {industry?.name}
                    </li>
                  ))}
              {showMoreIndustries && (
                <div className="flex flex-row mt-2">
                  <div
                    className={classNames(
                      'text-[13px] font-bold cursor-pointer',
                      {
                        'text-[#3C3C3C]':
                          event?.websiteTemplate
                          !== EventWebsiteTemplateType.AFTER_HOURS,
                        'text-[#FFFFFF]':
                          event?.websiteTemplate
                          === EventWebsiteTemplateType.AFTER_HOURS,
                      }
                    )}
                    onClick={() => setShowMoreIndustries(false)}
                  >
                    Show less
                  </div>
                  <div className="ml-1 mt-1">
                    <ArrowUpSvgIcon
                      fillColor={
                        event?.websiteTemplate
                        === EventWebsiteTemplateType.AFTER_HOURS
                          ? '#FFFFFF'
                          : undefined
                      }
                    />
                  </div>
                  {/* <img src={upArrow} alt="" className="ml-1 mt-1" /> */}
                </div>
              )}
            </div>
          </div>
        )}

        {!!jobTitles?.length && (
          <div
            className={`w-full ${
              event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS
                ? 'event-afterhours-box'
                : 'event-website-box'
            }`}
          >
            <div className="py-[23px] md:mx-[65px] mx-5">
              <div
                className={classNames(
                  'font-semibold text-[21px] leading-[25px] mb-[15px] pb-[16px] border-b-1 border-[#CDDCF2]',
                  {
                    'text-[#0326E5]':
                      event?.websiteTemplate
                      !== EventWebsiteTemplateType.AFTER_HOURS,
                    'text-[#FFFFFF]':
                      event?.websiteTemplate
                      === EventWebsiteTemplateType.AFTER_HOURS,
                  }
                )}
                style={{
                  color: event?.colorStyle?.values?.heading,
                }}
              >
                Audience
              </div>
              <div className="">
                {targetAudiences?.jobTitles
                  && jobTitles?.map((jobTitle) => (
                    <li
                      key={jobTitle.id}
                      className={classNames(
                        'text-[15px] leading-[24px] font-medium',
                        {
                          'text-[#3C3C3C]':
                            event?.websiteTemplate
                            !== EventWebsiteTemplateType.AFTER_HOURS,
                          'text-[#FFFFFF]':
                            event?.websiteTemplate
                            === EventWebsiteTemplateType.AFTER_HOURS,
                        }
                      )}
                    >
                      {jobTitle.name}
                    </li>
                  ))}
                {!showMoreAudiences
                  && targetAudiences?.jobTitles?.length! > 4 && (
                    <div className="flex flex-row mt-2">
                      <div
                        className={classNames(
                          'text-[13px] font-bold cursor-pointer',
                          {
                            'text-[#3C3C3C]':
                              event?.websiteTemplate
                              !== EventWebsiteTemplateType.AFTER_HOURS,
                            'text-[#FFFFFF]':
                              event?.websiteTemplate
                              === EventWebsiteTemplateType.AFTER_HOURS,
                          }
                        )}
                        onClick={() => setShowMoreAudiences(true)}
                      >
                        Show more
                      </div>
                      <div className="ml-1 mt-1">
                        <ArrowDownSvgIcon
                          fillColor={
                          event?.websiteTemplate
                          === EventWebsiteTemplateType.AFTER_HOURS
                            ? '#FFFFFF'
                            : undefined
                        }
                        />
                      </div>
                      {/* <img src={downArrow} alt="" className="ml-1 mt-1" /> */}
                    </div>
                )}
              </div>
              {showMoreAudiences
                && targetAudiences?.jobTitles?.length! > 4
                && targetAudiences?.jobTitles
                  ?.slice(4, targetAudiences?.jobTitles?.length - 1)
                  .map((jobTitle) => (
                    <li
                      key={jobTitle.id}
                      className={classNames(
                        'text-[15px] leading-[24px] font-medium',
                        {
                          'text-[#3C3C3C]':
                            event?.websiteTemplate
                            !== EventWebsiteTemplateType.AFTER_HOURS,
                          'text-[#FFFFFF]':
                            event?.websiteTemplate
                            === EventWebsiteTemplateType.AFTER_HOURS,
                        }
                      )}
                    >
                      {jobTitle.name}
                    </li>
                  ))}
              {showMoreAudiences && (
                <div className="flex flex-row mt-2">
                  <div
                    className={classNames(
                      'text-[13px] font-bold cursor-pointer',
                      {
                        'text-[#3C3C3C]':
                          event?.websiteTemplate
                          !== EventWebsiteTemplateType.AFTER_HOURS,
                        'text-[#FFFFFF]':
                          event?.websiteTemplate
                          === EventWebsiteTemplateType.AFTER_HOURS,
                      }
                    )}
                    onClick={() => setShowMoreAudiences(false)}
                  >
                    Show less
                  </div>
                  <div className="ml-1 mt-1">
                    <ArrowUpSvgIcon
                      fillColor={
                        event?.websiteTemplate
                        === EventWebsiteTemplateType.AFTER_HOURS
                          ? '#FFFFFF'
                          : undefined
                      }
                    />
                  </div>
                  {/* <img src={upArrow} alt="" className="ml-1 mt-1" /> */}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventOverview;
