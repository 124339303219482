/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { IEvent } from 'src/models/user-events';
import { getMinifiedImage } from 'src/mui/utils/image';
import { useSurveyContext } from 'src/mui/providers/GeneralContext';
import { IAppState } from '../../../store';
import { Loader } from '../../../shared-components/Loader';
import './index.css';
import { FACTORSTAB } from './constants';
import FactorWebsiteUpperNavbar from './factor-website-upper-navbar';
import FactorWebsiteNavbar from './factor-website-navbar';
import { FactorEventDetails } from './factor-event-details';

export const FactorEventLandingPage = ({ survey }: {survey?: any}) => {
  // component state
  const [selectedTab, setSelectedTab] = useState<string>(FACTORSTAB.OVERVIEW);

  // redux state
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const { getSurveyGallery } = useSurveyContext();

  // methods
  useEffect(() => {
    if (!event) return;
    const link = document.querySelector('link[rel="icon"]');
    if (link) {
      link?.setAttribute('href', `${getMinifiedImage(event?.company?.logo!)}`);
      link.setAttribute('sizes', '16x16');
    }
  }, [event]);

  useEffect(() => {
    const element = document.querySelector('.hide-on-event') as HTMLElement;
    if (element) {
      element.style.display = 'none';
    }
  }, []);

  useEffect(() => {
    if (survey) getSurveyGallery(survey?.id);
  }, [survey]);

  const loaded = useSelector((state: IAppState) => state.website.loaded);

  return loaded ? (
    <div
      className="bg-[#000000]"
    >
      <Helmet>
        <title>{survey ? survey?.name : event?.name}</title>
      </Helmet>
      <FactorWebsiteUpperNavbar />
      <div className="w-full bg-[#14161A] sticky top-0 backdrop-blur-sm z-[1000]">
        <FactorWebsiteNavbar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </div>
      <FactorEventDetails selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
    </div>
  ) : <Loader isLoaderLogo className="h-[100vh]" key="loader" />;
};

export default FactorEventLandingPage;
