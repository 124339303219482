// @mui
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Divider, Stack } from "@mui/material";
import { imageUrlPrefix } from "src/utils";
import { ProgressBar } from "./progress-bar";
import { useState } from "react";
import { getBrandingColors } from '../event/live/liveEventStyling';
import defaultUserAvatar from "src/assets/images/avatar-default.svg";
import CircularLoader from "./CircularLoader";
import { AvatarDefaultSmallSvgIcon } from "src/shared-components/V2/IconSvg/AvatarDefaultSmallSvgIcon/AvatarDefaultSmallSvgIcon";

// ----------------------------------------------------------------------

type UserMatchProgressProps = {
  user: any;
  item: any;
};

export default function UserMatchProgress({
  user,
  item,
}: UserMatchProgressProps) {
  const colors = getBrandingColors();
  const [chartData, setChartData] = useState({
    datasets: [
      {
        data: [item?.profileMatch, 100 - Number(item?.profileMatch)],
        backgroundColor: [colors?.Accent || "#0049EF", colors?.Secondary || "#EDF2F5"],
        borderWidth: 0,
        spacing: 0,
        borderJoinStyle: "bevel",
        borderRadius: [0, 0],
        rotation: 180,
        cutout: 35,
      },
    ],
  });

  return (
    <Stack sx={{ textAlign: "center", width: 1, mt: "10px", px: "0px" }}>
      <Box
        display="flex"
        textAlign="left"
        alignItems="center"
        justifyContent="center"
        sx={{ py: 0, px: 0, columnGap: "30px" }}
      >
        <Box sx={{ position: "relative", width: 49 }}>
          {user?.avatar ? (
            <Avatar
              alt={user?.firstName}
              src={user?.avatar ? `${imageUrlPrefix}/${user?.avatar}` : defaultUserAvatar}
              sx={{
                width: 49,
                height: 49,
                borderRadius: "20px",
                mx: "auto",
              }}
            />
          ) : (
            <Stack sx={{
              width: 49,
              height: 49,
              borderRadius: '20px',
              backgroundColor: 'transparent !important',
              maxWidth: 49,
              maxHeight: 49,
              border: `1px solid ${colors?.Text || '#000000'}`,
            }}
            >
              <AvatarDefaultSmallSvgIcon fillColor={colors?.Text} style={{ width: '49px', height: '49px' }} />
            </Stack>
          )} 
          
          <Typography
            textAlign="center"
            variant="subtitle2"
            component="div"
            sx={{
              mt: 0.5,
              color:  colors?.Text || "#ffffff",
              fontSize: "10px",
              lineHeight: "12px",
              fontFamily: "Poppins-600",
            }}
          >
            You
          </Typography>
        </Box>

        <Box sx={{ position: "relative", mt: "0" }}>
        <CircularLoader isDouble percentage={item?.match} barColor={colors?.Accent || "#0049ef"} circleColor={colors?.Secondary || "#ffffff"} textColor={colors?.Text || "#ffffff"} matchText={colors?.ButtonTextColor || "#000000"} />
          {/* <Stack
            sx={{
              width: "105px",
              my: 0,
              mx: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ProgressBar data={chartData} />
            <Stack sx={{ position: "absolute", textAlign: "center" }}>
              <Stack
                sx={{
                  backgroundColor: colors?.Secondary || "#0049EF",
                  borderRadius: "5px",
                  fontWeight: 600,
                  fontSize: "10px",
                  lineHeight: "8px",
                  color: colors?.Text || "#ffffff",
                  py: "4px",
                  px: "4px",
                  width: "40px",
                  height: "15px",
                  fontFamily: "Poppins-600",
                }}
              >
                Match
              </Stack>
              <Stack
                sx={{
                  fontWeight: 500,
                  fontSize: "18px",
                  color: colors?.Text || "#ffffff",
                  fontFamily: "Poppins-600",
                }}
              >
                {item?.profileMatch?.toFixed(0)}%
              </Stack>
            </Stack>
          </Stack> */}
          {/* <BookingAvailable
            title=""
            chart={{
              series: [
                { label: 'Sold out', value: item?.match === 0 ? -1 : item?.match },
                { label: 'Available', value: item?.match === 0 ? 1 : item?.match },
              ],
            }}
          /> */}
        </Box>

        <Box sx={{ position: "relative", width: 49 }}>
          {item?.avatar ? (
            <Avatar
              src={item?.avatar ? `${imageUrlPrefix}/${item?.avatar}` : defaultUserAvatar}
              sx={{
                width: "49px",
                height: "49px",
                borderRadius: "20px",
                zIndex: 11,
                mx: "auto",
                "&.MuiAvatar-root, & .MuiAvatar-img": {
                  minHeight: "49px",
                  maxHeight: "49px",
                  minWidth: "49px",
                  backgroundColor: '#C4CDD5',
                  color: '#ffffff'
                },
              }}
              // alt={item?.firstName}
            />
          ) : (
<Stack sx={{
              width: 49,
              height: 49,
              borderRadius: '20px',
              backgroundColor: 'transparent !important',
              maxWidth: 49,
              maxHeight: 49,
              border: `1px solid ${colors?.Text || '#000000'}`,
            }}
            >
              <AvatarDefaultSmallSvgIcon fillColor={colors?.Text} style={{ width: '49px', height: '49px' }} />
            </Stack>
          )}
            
          <Typography
            className="ellipsis-one-line"
            textAlign="center"
            variant="subtitle2"
            component="div"
            sx={{
              mt: 0.5,
              color: colors?.Text || "#ffffff",
              fontSize: "10px",
              lineHeight: "12px",
              fontFamily: "Poppins-600",
              textAlign: "center",
            }}
          >
            {item?.firstName}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{
          borderStyle: "solid",
          mt: "10px",
          borderColor: colors.Secondary || '#7283A4',
          opacity: "20%",
        }}
      />
    </Stack>
  );
}
