import { useDispatch, useSelector } from 'react-redux';
import {
  IPoll,
} from '../../../models/poll/poll';

import ViewPoll from '../view-poll';
import { useEffect, useState } from 'react';
import { PollService } from 'src/services/Poll';
import { getAllPolls, getPoll } from 'src/store/poll';
import { IAppState } from 'src/store';

const ViewPollPost = (poll: IPoll) => {

  const dispatch = useDispatch();

  const [pollData, setPollData] = useState<any>();
  const { poll: pollDataById } = useSelector((state: IAppState) => state);

  const getPollById = async (pollId: string) => {
    const pollService = new PollService();
    try {
      const res: any = await pollService.getPoll(pollId);

      // setPollData({
      //   id: res?.data?.id!,
      //   question: res?.data?.question!,
      //   status: res?.data?.status!,
      //   expireOption: res?.data?.expireOption!,
      //   expiredAt: res?.data?.expiredAt!,
      //   answers: res?.data?.answers!,
      // })
    } catch (error: any) {
      console.error(error);
    }
  }

  const onCastVote = async (pollId: string, answerId: string) => {
    const pollService = new PollService();

    try {
      const result = await pollService.castVote(pollId, answerId);
      // await getPollById(pollId)
      dispatch(getPoll(pollId));
    } catch (error: any) {
      console.error(error);
    }
  }

  const onRemoveVote = async (pollId: string) => {
    try {
      // await getPollById(pollId)
      dispatch(getPoll(pollId));
    } catch (error: any) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (pollDataById?.allPolls && pollDataById?.allPolls?.[poll?.id]) {
      setPollData({
        id: pollDataById?.allPolls?.[poll?.id]?.id!,
        question: pollDataById?.allPolls?.[poll?.id]?.question!,
        status: pollDataById?.allPolls?.[poll?.id]?.status!,
        expireOption: pollDataById?.allPolls?.[poll?.id]?.expireOption!,
        expiredAt: pollDataById?.allPolls?.[poll?.id]?.expiredAt!,
        answers: pollDataById?.allPolls?.[poll?.id]?.answers!,
      })
    }
    else {
      setPollData({ ...poll })
    }
  }, [pollDataById, poll])

  return (
    <>
      <ViewPoll
        {...pollData!}
        isInteractive={poll?.isInteractive}
        onCastVote={(pollId: string, id: string) => onCastVote(pollId, id)}
        onRemoveVote={onRemoveVote}
        location={poll?.location}
        isCompanyNew={poll?.isCompanyNew}
        isLivePage={poll?.isLivePage}
      />
    </>
  );
};

export default ViewPollPost;
