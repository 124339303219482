// @mui
import { Box, IconButton, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import MenuIcon from '../../audience/MenuIcon';
import MinimizeIcon from '../../audience/MinimizeIcon';
import { useSurveyContext } from 'src/mui/providers/GeneralContext';
import { getBrandingColors } from './liveEventStyling';

type CardHeaderProps = {
  title: any;
  onClick: (p: any) => void;
  hideIcon?: boolean;
  sx?: any;
}

export const CardHeader = ({ title, onClick, hideIcon, sx }: CardHeaderProps) => {
  const { agendaStyling, setAgendaStyling } = useSurveyContext();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
        {/* <IconButton sx={{ p: 0 }}>
          <MenuIcon />
        </IconButton> */}

        <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '18px', fontFamily: 'Poppins-500', flexWrap: 'nowrap', color: getBrandingColors()?.Text || agendaStyling.color, ...sx }} >
          {title}
        </Typography>
      </Box>
      {!hideIcon && (
        <IconButton onClick={() => onClick(title)} sx={{ p: 0 }}>
          <MinimizeIcon />
        </IconButton>
      )}
    </Stack>
  );
}
