import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '../V2/Avatar';

export interface ICardCompanyAdminsProps {
  containerClass: string;
  avatarUrl: string;
  title: string;
  titleClass?: string;
  subTitle: string;
  subTitleClass?: string;
  location: string;
  locationClass: string;
  primaryButton?: string;
  primaryButtonText?: string;
  secondaryButton?: string;
  secondaryButtonText?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  detailsHref: string;
}

export const CardCompanyAdmins: FC<ICardCompanyAdminsProps> = ({
  containerClass,
  avatarUrl,
  title,
  titleClass,
  subTitle,
  subTitleClass,
  location,
  locationClass,
  primaryButton,
  primaryButtonText,
  secondaryButton,
  secondaryButtonText,
  detailsHref,

  onClick
}) => {
  return (
    <div className={containerClass}>
      <div className="">
        <div className="flex flex-wrap justify-start flex-row relative gap-2">
          <div className="mb-1 sm:mb-0">
            <Link to={detailsHref}>
              <Avatar src={avatarUrl} size='MEDIUM' />
            </Link>
          </div>
          <div className="flex flex-col">
            <Link to={detailsHref}>
              <div className={titleClass}>{title}</div>
            </Link>
            <div className={subTitleClass}>{subTitle}</div>
            <div className={locationClass}>{location}</div>
          </div>
          <div className="flex flex-row">
            <button className={primaryButton} onClick={onClick}>
              {primaryButtonText}
            </button>
            <button className={secondaryButton} onClick={onClick}>
              {secondaryButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
