/* eslint-disable no-unused-vars */
import {
  Box, LinearProgress, Stack, Typography
} from '@mui/material';
import { RatingStartIcon } from 'src/shared-components/V2/IconSvg/RatingStartIcon';

interface Props {
  product: any
}

export const ProductRatings = ({ product }:Props) => {
  const ratingByStart = (count: any, percentage: number) => (
    <Stack sx={{ flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
      <Stack sx={{
        flexDirection: 'row', gap: '3px', width: '58px', justifyContent: 'flex-end'
      }}
      >
        {count.map((_item: any) => <RatingStartIcon />)}
      </Stack>

      <Box sx={{ width: '179px' }}>
        <LinearProgress
          sx={{
            '&.MuiLinearProgress-root': {
              backgroundColor: '#A5A5A5',
              height: '2px',
              borderRadius: '10px'
            },
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#000000'
            }
          }}
          variant="determinate"
          value={percentage}
        />
      </Box>
    </Stack>

  );
  return (
    <Stack>
      <Typography sx={{
        color: '#000000', fontSize: '20px', fontWeight: 500, lineHeight: '27px', pb: '5px', fontFamily: 'Poppins-500'
      }}
      >
        Rating & Reviews
      </Typography>
      <Stack sx={{
        flexDirection: 'row', width: '100%', maxWidth: '775px', justifyContent: 'space-between'
      }}
      >
        <Stack sx={{ flexDirection: 'row', alignItems: 'end', gap: '16px' }}>
          <Typography sx={{
            color: '#4B4B4B', fontSize: '80px', lineHeight: '85px', pt: '30px', fontWeight: 700, fontFamily: 'Poppins-700'
          }}
          >
            4.9
          </Typography>
          <Typography sx={{
            color: '#4B4B4B', fontSize: '17px', lineHeight: '22px', fontWeight: 600, fontFamily: 'Poppins-600', mb: '10px'
          }}
          >
            out of 5
          </Typography>
        </Stack>
        <Stack sx={{ flexDirection: 'row', alignItems: 'end', gap: '16px' }}>
          <Typography sx={{
            color: '#4B4B4B', fontSize: '17px', lineHeight: '22px', fontWeight: 600, fontFamily: 'Poppins-600', mb: '10px'
          }}
          >
            32 Rating(s)
          </Typography>
          <Stack sx={{ gap: '5px', height: '85px' }}>
            {ratingByStart(['1', '2', '3', '4', '5'], 75)}
            {ratingByStart(['1', '2', '3', '4'], 45)}
            {ratingByStart(['1', '2', '3'], 20)}
            {ratingByStart(['1', '2'], 90)}
            {ratingByStart(['1'], 30)}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProductRatings;
