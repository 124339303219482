import React from 'react';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
// components
import Image from 'src/mui/components/image';
import SecondaryBanner from "./Images/SecondaryBanner.jpg"

type WhyAttendProps = {
  event?: any;
}

export const WhyAttend = ({ event }: WhyAttendProps) => {
  return (
    <Stack>
      <Stack sx={{ mb: '1rem' }}>
        {
          event?.eventMetrics?.map((item: any, index: number) => (
            <Stack key={index}>
              <Typography
                variant="body2"
                noWrap
                sx={{
                  whiteSpace: 'initial',
                  fontSize: '1.2rem',
                  mb: '0px',
                  color: '#4d144a',
                  fontFamily: 'Poppins-600'
                }}
              >
                {item?.value}
              </Typography>
              <Typography
                variant="body2"
                noWrap
                sx={{
                  whiteSpace: 'initial',
                  fontSize: '1.2rem',
                  mb: '1rem',
                  color: '#4d144a',
                  fontFamily: 'Poppins-400'
                }}
              >
                {item?.name}
              </Typography>
            </Stack>
          ))
        }
      </Stack>

      <Image
        src={SecondaryBanner}
        alt={SecondaryBanner}
        sx={{
          display: { md: "none", lg: "none", xl: "none", sm: 'none', xs: "block" },
        }}
      />
    </Stack>
  );
};
