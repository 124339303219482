import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSurveyContext } from 'src/mui/providers/GeneralContext';
import { getS3Path } from 'src/mui/utils/image';
import { useLocation } from 'react-router';
import { FACTORSTAB } from './constants';
import FactorEventQuickLinks from './factor-event-quicklinks';
import { FactorExperience } from './factor-experience';
import { Overview } from './Overview';
import { ProgramPage } from './pages/program-page';
import { SpeakerPage } from './pages/speaker-page/Speakerpage';
import { Partnerspage } from './pages/partners-page/Partnerspage';
import ContactUs from './contact-us';
import { FactorEventDetailsProps } from './types';

export const FactorEventDetails = ({
  selectedTab,
  setSelectedTab,
}: FactorEventDetailsProps) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');

  // eslint-disable-next-line no-console, no-undef
  const isContactUsBool = window.location.href.includes('Contact');

  const [email, setEmail] = useState<string>('');

  const handleEmailRegister = (newEmail: string) => {
    setEmail(newEmail);
    const contactUsElement = document.getElementById('contact-us');
    contactUsElement?.scrollIntoView({ behavior: 'smooth' });
  };
  const { eventGallery, surveyGallery } = useSurveyContext();
  const videoData = eventGallery
    ?.filter((item: any) => item.format === 'video')
    .map((item: any) => ({
      url: getS3Path(item?.key),
      thumbnail: `${process.env.REACT_APP_DEFAULT_VIDEO_HLS_URL}/${item?.thumbnail}`,
    }));

  const videoDataSurvey = surveyGallery
    ?.filter((item: any) => item.format === 'video')
    .map((item: any) => ({
      url: getS3Path(item?.key),
      thumbnail: `${process.env.REACT_APP_DEFAULT_VIDEO_HLS_URL}/${item?.thumbnail}`,
    }));

  const getSelectedSection = () => {
    switch (selectedTab) {
      case FACTORSTAB.OVERVIEW:
        return (
          <Overview
            onRegister={handleEmailRegister}
            setSelectedTab={setSelectedTab}
            videos={surveyGallery.length > 0 ? videoDataSurvey : videoData}
          />
        );

      case FACTORSTAB.EXPERIENCE:
        return (
          <FactorExperience
            onRegister={handleEmailRegister}
            setSelectedTab={setSelectedTab}
            videos={videoData}
          />
        );

      case FACTORSTAB.PROGRAM:
        return <ProgramPage onRegister={handleEmailRegister} />;

      case FACTORSTAB.SPEAKERS:
        return <SpeakerPage />;

      case FACTORSTAB.SPONSORS:
        return <Partnerspage videos={videoData} />;

      default:
        return '';
    }
  };

  useEffect(() => {
    setEmail('');
    window.scrollTo(0, 0);
  }, [selectedTab]);

  useEffect(() => {
    if (tab && setSelectedTab) {
      setSelectedTab(tab!);
    }
  }, [tab, setSelectedTab]);

  return (
    <div
      className={classNames('xl:px-0', {
        'px-[0px]': selectedTab === FACTORSTAB.EXPERIENCE,
      })}
    >
      <div
        className={classNames('w-full max-w-[1154px] mx-auto', {
          '!max-w-full':
            selectedTab === FACTORSTAB.EXPERIENCE
            || selectedTab === FACTORSTAB.OVERVIEW,
        })}
      >
        {getSelectedSection()}

        <div
          className={classNames(
            `${
              isContactUsBool
                ? 'md:mt-[30px] mt-[30px]'
                : 'md:mt-[170px] mt-[170px] px-[15px]'
            }`,
            {
              'md:mt-[150px] mt-[150px] md:mb-[150px] mb-[150px]':
                selectedTab === FACTORSTAB.SPEAKERS,
              'max-w-[1154px] mx-auto xl:px-0 px-[15px]':
                selectedTab === FACTORSTAB.EXPERIENCE
                || selectedTab === FACTORSTAB.OVERVIEW,
            }
          )}
        >
          <ContactUs prefillEmail={email} selectedTab={selectedTab} />
        </div>

        <div
          className={classNames('w-full', {
            'max-w-[1154px] mx-auto xl:px-0 px-[22px]':
              selectedTab === FACTORSTAB.EXPERIENCE
              || selectedTab === FACTORSTAB.OVERVIEW,
          })}
        >
          <FactorEventQuickLinks />
        </div>
      </div>
    </div>
  );
};
