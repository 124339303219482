import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import editIcon from '../../../assets/images/edit-pen.svg';
import AlertModal from '../../../components/alert-modal/alert-modal';
import {
  IAddExperience,
  IExperience,
} from '../../../models/experience';
import { UserService } from '../../../services';
import { CardCompanyAdmins } from '../../../shared-components/V2';
import { getOwnConnections } from '../../../store/user/own-connections/actions';
import { getOwnExperience } from '../../../store/user/own-experience/actions';
import { getUserProfile } from '../../../store/user/user-profile/actions';
import {
  calculatePeriod,
  imageUrlPrefix,
} from '../../../utils';
import EditExperience from './edit-experience';

export const ExperienceItem = (item: IExperience) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const period = calculatePeriod(item.startDate, item.current ? '' : item.endDate!);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickCompany = (url: any) => {
    navigate(`/wall/company/${url}`);
  };
  return (
    <div className="flex flex-row justify-between">
      <CardCompanyAdmins
        avatarUrl={`${imageUrlPrefix}/${item.company?.logo}`}
        title={item.jobTitle}
        subTitle={item.company?.name}
        duration={period!}
        location={item.location}
        detailsHref={item.company?.slug}
        avatarClass="p-[0px] w-[40px] rounded-[4px] h-[40px] object-contain bg-[#edf2f5]"
        avatarDefaultClass="p-[3px] w-[40px] rounded-[4px] h-[40px] object-contain bg-[#edf2f5]"
        containerClass="pl-0 pb-4"
        isEdit={item.isOwnProfile}
        icon={editIcon}
        onCompanyEditClick={() => setOpenEditModal(true)}
        onJobTitleClick={onClickCompany}
        onCompanyClick={onClickCompany}

      />
      {item.isOwnProfile && (
        <div className="" onClick={() => setOpenEditModal(true)}>
          {openEditModal && (
            <AlertModal
              isOpen={openEditModal}
              onClose={() => setOpenEditModal(false)}
              className="md:w-[482px] py-0"
              closeIconClass=" right-[21px] top-[14px] px-0"
              isCloseIcon
            >
              <EditExperience
                jobTitle={item?.jobTitle}
                companyId={item?.companyId}
                companyName={item?.company?.name}
                employmentType={item?.employmentType}
                current={item?.current}
                startDate={item.startDate}
                endDate={item.endDate!}
                location={item.location}
                id={item.id}
                onSave={async (data: IAddExperience) => {
                  const userSerivce = new UserService();
                  await userSerivce.editExperience(data, item.id);
                  // needs to get new data after save and close modal
                  if (item.isOwnProfile) {
                    dispatch(getUserProfile());
                    dispatch(getOwnExperience());
                    dispatch(getOwnConnections());
                  } else {
                    dispatch(getUserProfile(item.userId));
                    dispatch(getOwnExperience(item.userId));
                    dispatch(getOwnConnections(item.userId));
                  }
                }}
                onClose={() => {
                  setOpenEditModal(false);
                  if (item.isOwnProfile) {
                    dispatch(getUserProfile());
                    dispatch(getOwnExperience());
                    dispatch(getOwnConnections());
                  } else {
                    dispatch(getUserProfile(item.userId));
                    dispatch(getOwnExperience(item.userId));
                    dispatch(getOwnConnections(item.userId));
                  }
                }}
                title="Edit your job experience"
              />
            </AlertModal>
          )}
        </div>
      )}
    </div>
  );
};

export default ExperienceItem;
