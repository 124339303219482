import { IInterestsResult } from '../../models/interest/interests-result';
import BaseService from '../BaseService';

export class InterestService extends BaseService {
  async getInterestsWithQuery(query: string, limit: number = 6) {
    const extraOptions = this.getAuthToken();

    const result = this.get<IInterestsResult>(
      `/interests/short?page=1&limit=${limit}&search=${encodeURIComponent(query)}`,
      extraOptions
    );
    return result;
  }

  async getAllInterest(search?: string, page: number = 1, limit: number = 15) {
    try {
      const extraOptions = this.getAuthToken();
      const url = `/interests/short?&order[name]=ASC${
        search ? `&search=${encodeURIComponent(search)}` : ''
      }&page=${page}&limit=${limit}`;
      return this.get<any>(url, extraOptions);
    } catch (error) {
      console.error('An error occurred:', error);
      throw error;
    }
  }

  async getCurrentUserInterests() {
    const extraOptions = this.getAuthToken();
    const result = this.get<IInterestsResult>(
      '/users/me/user-interests',
      extraOptions
    );
    return result;
  }

  async getCurrentUserOfferings() {
    const extraOptions = this.getAuthToken();
    const result = this.get<IInterestsResult>(
      '/users/me/user-offerings ',
      extraOptions
    );
    return result;
  }
}
