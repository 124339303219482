import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { IAppState } from '../../../store';
import { ParseContent } from '../../../utils/parse-html';

const WebsiteEventKeyDiscussion = () => {
  const event = useSelector((state: IAppState) => state.website.event!);

  if (!event?.eventKeyDiscussionPoints?.length) return null;

  return (
    <div
      className={classNames('w-full', {
        hidden: event.eventKeyDiscussionPoints === null,
      })}
    >
      <div className="w-full event-website-box-new mb-[16px]">
        <div className="py-[23px] md:mx-[65px] mx-5">
          <div
            className="font-semibold text-[#0326E5] text-[21px] leading-[25px] mb-[15px] pb-[16px] border-b-1 border-[#CDDCF2]"
            style={{
              color: event?.colorStyle?.values?.heading,
            }}
          >
            Key discussion points
          </div>
          <div className="block md:flex mx-auto mt-5 md:mt-0">
            <div className="w-full mx-auto rounded-10 mb-5 ">
              <div className="flex md:flex-row flex-col">
                {event.eventKeyDiscussionPoints?.map((item, index) => (
                  <div className="md:w-1/4 mr-5 my-2 md:mt-0" key={index}>
                    <div
                      className={classNames({
                        'flex flex-row rounded-10': item.id.length > 1,
                        hidden: item.id.length === 0,
                      })}
                    >
                      <div className="">
                        <div className="font-medium text-[15px] text-[#3C3C3C] mb-[23px]">
                          {item.key}
                        </div>

                        <div className="text-[13px] text-[#3C3C3C] font-light">
                          <ul className="flex flex-col">
                            <li key={item.id}>
                              <ParseContent content={item.summary} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventKeyDiscussion;
