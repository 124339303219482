import React, { FC } from 'react';
import classNames from 'classnames';
import { Avatar } from '../Avatar';
import { IconSvg } from './../IconSvg';
export interface ICardPeopleNetworkProps {
  containerClass?: string;
  avatarUrl?: string;
  avatarSize: "XLARGE" | "XXSMALL" | "XSMALL" | "SMALL" | "MEDIUM" | "LARGE" | "XXLARGE" | "PROFILEMATCH" | undefined;
  title?: string;
  titleClass?: string;
  subTitle?: string;
  subTitleClass?: string;
  company?: string;
  companyClass?: string;
  detailsHref?: string;
  imageUrl?: string;
  primaryButton?: string;
  primaryButtonText?: string;
  secondaryButton?: string;
  secondaryButtonText?: string;
  onClickSecondary?: (p: string | undefined) => void;
  onClickPrimary?: (p: string | undefined) => void;
  onUserClick?: Function;
  onJobTitleClick?: Function;
  onCompanyClick?: Function;
  onAddPeopleClick?: Function;
}

export const CardPeopleNetwork: FC<ICardPeopleNetworkProps> = ({
  containerClass,
  avatarUrl,
  avatarSize,
  title,
  titleClass,
  subTitle,
  subTitleClass,
  company,
  companyClass,
  detailsHref,
  imageUrl,
  primaryButton,
  primaryButtonText,
  secondaryButton,
  secondaryButtonText,
  onUserClick,
  onJobTitleClick,
  onCompanyClick,
  onAddPeopleClick,
  onClickSecondary,
  onClickPrimary
}) => {
  return (
    <div  
      className={classNames("flex items-start justify-between", {
        [`${containerClass}`]: containerClass,
      })}
    >
      <div className="pr-5">
        <div className="flex flex-row relative">
          <div onClick={() => onUserClick && onUserClick()} className="cursor-pointer">
            <Avatar src={avatarUrl} size={avatarSize} />
          </div>
          <div className="flex flex-col ml-3">
            {
              title &&
              <span
                aria-label="card-user-name"
                onClick={() => onUserClick && onUserClick()}
                className={classNames("cursor-pointer opacity-100 hover:opacity-80 line-clamp-1", {
                  [`${titleClass}`]: titleClass,
                })}
              >
                {title}
              </span>
            }
            {
              subTitle &&
              <span
                aria-label="card-job-title"
                onClick={() => onJobTitleClick && onJobTitleClick()}
                className={classNames("cursor-pointer opacity-100 hover:opacity-80 line-clamp-1", {
                  [`${subTitleClass}`]: subTitleClass,
                })}
              >
                {subTitle}
              </span>
            }
            {
              company &&
              <span
                aria-label="card-company-name"
                onClick={() => onCompanyClick && onCompanyClick()}
                className={classNames("cursor-pointer opacity-100 hover:opacity-80", {
                  [`${companyClass}`]: companyClass,
                })}
              >
                {company}
              </span>
            }
          </div>
        </div>
      </div>
      {
        imageUrl &&
        <span onClick={() => onAddPeopleClick && onAddPeopleClick()} className="cursor-pointer opacity-100 hover:opacity-80">
          <IconSvg imageUrl={imageUrl} />
        </span>
      }
      <div className='flex items-center gap-2'>
        <button className={secondaryButton} onClick={() => onClickSecondary && onClickSecondary(detailsHref)}>
          {secondaryButtonText}
        </button>
        <button className={primaryButton} onClick={() => onClickPrimary && onClickPrimary(detailsHref)}>
          {primaryButtonText}
        </button>
      </div>
    </div>
  );
};
