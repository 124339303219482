import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { imageUrlPrefix } from "src/utils";
import { UserMutuals } from "./user-mutuals";
import { IAppState } from "src/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { UserHighlightsMui } from "../match/user-highlights";
import { getBrandingColors } from '../event/live/liveEventStyling';
import defaultUserAvatar from "src/assets/images/avatar-default.svg";
import { AvatarDefaultSmallSvgIcon } from "src/shared-components/V2/IconSvg/AvatarDefaultSmallSvgIcon/AvatarDefaultSmallSvgIcon";
type UserAboutProps = {
  user: any;
  item: any;
};

export const UserAbout = ({ user, item }: UserAboutProps) => {
  const { userProfile } = useSelector((state: IAppState) => state);
  const [seeMoreDesc, setSeeMoreDesc] = useState(false);
  const colors = getBrandingColors();

  useEffect(() => {
    if (item?.userId) {
      // getConnectionMatch(item?.userId);
    }
  }, [item?.userId]);
  return (
    <Card
      sx={{
        position: "relative !important",
        boxShadow: "none",
        mx: "0px",
        px: "14px",
        py: "11px",
        mt: "10px",
        backgroundColor: colors?.Secondary || "#2F2F2F",
        borderRadius: "5px",
      }}
    >
      <CardHeader
        sx={{
          pb: "5px",
          px: 0,
          pt: "0px",
          "& .MuiTypography-root": {
            fontSize: "13px",
            fontFamily: "Poppins-500",
            lineHeight: "19px",
            color:  colors?.Text || "#ffffff",
            fontWeight: 500,
            mt: "0px",
          },
        }}
        title={`About ${item?.firstName}`}
      />
      <Divider
        sx={{
          borderStyle: "solid",
          mb: "5px",
          borderColor: colors.Main || '#7283A4',
          opacity: "50%",
        }}
      />
      <Stack spacing={2} sx={{ pb: 0 }}>
        <Typography
          textAlign="left"
          variant="body2"
          sx={{
            fontSize: "13px",
            fontFamily: "Poppins-400",
            lineHeight: "18px",
            color:  colors?.Text || "#ffffff",
          }}
        >
          {item.about && (
            <div
              style={{
                textAlign: "left",
                fontSize: "13px",
                fontFamily: "Poppins-400",
                lineHeight: "17px",
                color:  colors?.Text || "#ffffff",
              }}
              dangerouslySetInnerHTML={{
                __html: `${item.about?.substring(0, 70)}${seeMoreDesc ? item.about?.substring(70) : null}`,
              }}
            />
          )}
          {item.about?.length! > 70 && (
            <Button
              variant="text"
              onClick={() => {
                setSeeMoreDesc(!seeMoreDesc);
              }}
              sx={{
                fontSize: "13px",
                fontFamily: "Poppins-500",
                fontWeight: 500,
                lineHeight: "17px",
                color:  colors?.Accent || "#00FCED",
                p: 0,
                textTransform: "math-auto",
              }}
            >
              {seeMoreDesc ? "See less" : " See more"}
            </Button>
          )}
          {/* </Box> */}
        </Typography>
      </Stack>
      {item?.about && (
        <Divider
          sx={{
            borderStyle: "solid",
            mb: "5px",
            mt: "11px",
            borderColor: colors.Main || '#7283A4',
            opacity: "50%",
          }}
        />
      )}
      <Box
        display="flex"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        sx={{ py: 1, pb: 0, flexDirection: "column" }}
      >
        <AvatarGroup max={4}>
          {
            user?.avatar ? (
              <Avatar
                sx={{
                  borderRadius: "11px",
                  width: "26px",
                  height: "26px",
                  borderWidth: "1px !important",
                  fontSize: "10px",
                  transform: "rotate(-8deg)",
                }}
                alt={user?.firstName}
                src={user?.avatar ? `${imageUrlPrefix}/${user?.avatar}` : defaultUserAvatar}
              />
            ) : (
            <Stack sx={{
              width: "26px",
              height: "26px",
              borderRadius: '11px',
              backgroundColor: 'transparent !important',
              maxWidth: "26px",
              maxHeight: "26px",
              transform: "rotate(-8deg)",
              border: `1px solid ${colors?.Text || '#000000'}`,
            }}
            >
              <AvatarDefaultSmallSvgIcon fillColor={colors?.Text} style={{ width: "26px", height: "26px" }} />
            </Stack>
            )
          }
          {item?.avatar ? (
            <Avatar
              sx={{
                borderRadius: "11px",
                width: "26px",
                height: "26px",
                borderWidth: "1px !important",
                fontSize: "10px",
                transform: "rotate(8deg)",
              }}
              alt={item?.firstName}
              src={item?.avatar ? `${imageUrlPrefix}/${item?.avatar}` : defaultUserAvatar}
            />
          ) : (
            <Stack sx={{
              width: "26px",
              height: "26px",
              borderRadius: '11px',
              backgroundColor: 'transparent !important',
              maxWidth: "26px",
              maxHeight: "26px",
              transform: "rotate(8deg)",
              border: `1px solid ${colors?.Text || '#000000'}`,
            }}
            >
              <AvatarDefaultSmallSvgIcon fillColor={colors?.Text} style={{ width: "26px", height: "26px" }} />
            </Stack>
          )}
          
        </AvatarGroup>
        <Typography
          textAlign="left"
          variant="body1"
          component="div"
          sx={{
            color: colors?.Text || "#ffffff",
            fontSize: "13px",
            fontFamily: "Poppins-500",
            fontWeight: 500,
            lineHeight: "19px",
            mt: "4px",
          }}
        >
          Highlights
        </Typography>
      </Box>
      
      <Stack>
        <UserHighlightsMui
          isOwnProfileNot={false}
          userProfile={item?.firstName}
          userId={item?.userId}
          interests={item?.interests}
          offers={item?.offers}
        />
      </Stack>
      <Stack>
        <UserMutuals
          userProfile={userProfile}
          companies={item?.companies}
          events={item?.events}
          interests={item?.interests}
        />
      </Stack>
    </Card>
  );
};
