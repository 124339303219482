import { useState } from 'react';

import classNames from 'classnames';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import MenuCopyLink from '../../../assets/images/event-landing-pre-event/copy-link-icon.svg';
import MenuArrowRight from '../../../assets/images/event-landing-pre-event/events-website-arrow-icon.svg';
import MenuForward from '../../../assets/images/event-landing-pre-event/paper-airplane-icon.svg';
import MenuReport from '../../../assets/images/event-landing-pre-event/report-alert-icon.svg';
import MenuShare from '../../../assets/images/event-landing-pre-event/share-arrow-icon.svg';
//Components
import AlertModal from '../../../components/alert-modal/alert-modal';
import {
  DropDownMenu,
  IDropdownMenuItem,
} from '../../../components/dropdown-menu';
import { Report } from '../../../components/report';
// Models & store
import { IEvent } from '../../../models/event/event';
import { IFlagDTO } from '../../../models/flag/flag';
import { IPostProps } from '../../../models/post/view-post-props';
import { IUserProfile } from '../../../models/user-profile';
import { FlagService } from '../../../services/Flag';
import { IAppState } from '../../../store';
import { openMessageWindow } from '../../../store/newMessage';
//Utils
import useCopyToClipboard from '../../../utils/hooks/copyToClipboard';
import useFullUrl from '../../../utils/hooks/fullUrl';
import ShareEvent from '../../events/event-tab/components/ShareEvent';

interface IFrame1Props {
  event: IEvent;
  postProps?: IPostProps;
  user: IUserProfile;
}
export const EventDropdown = ({ event, user }: IFrame1Props) => {
  const [isShareEvent, setIsShareEvent] = useState(false);
  const [isReportEvent, setIsReportEvent] = useState(false);
  const { newMessage } = useSelector((state: IAppState) => state);

  const dispatch = useDispatch();
  const getFullUrl = useFullUrl();
  const { isCopied, handleCopy } = useCopyToClipboard();

  // To Website Event
  const navigateToWebsiteEvent = () => {
    const utmUrl = event?.eventUtmUrl?.find((utm) => utm.type === 'panelist')?.url;
    window?.open(`/${event?.company?.name.split(' ').join('-')}/${event?.slug}${utmUrl?`?${utmUrl}`:''}`,'_blank');
  };

  //Forward event to message
  const forwardEvent = () => {
    const fullUrl = getFullUrl(`/event/${event.slug}/discussion`);
    dispatch(
      openMessageWindow({
        avatar: "",
        userDisplayName: "New Message",
        messageText: fullUrl,
      })
    );
  };

  //Share event post
  const shareEvent = () => {
    setIsShareEvent(!isShareEvent);
  };

  // Copy link to clipboard
  const copyLink = () => {
    const fullUrl = getFullUrl(`/event/${event.slug}/discussion`);
    handleCopy(fullUrl);
    if (isCopied) toast.success("Successfully");
  };

  //Report event
  const reportEvent = () => {
    setIsReportEvent(!isReportEvent);
  };

  const optionsToSelect: IDropdownMenuItem[] = [
    {
      id: 1,
      type: "icon",
      text: "Event's website",
      icon: MenuArrowRight,
      onClickHandler: () => navigateToWebsiteEvent(),
    },
    {
      id: 2,
      type: "icon",
      text: "Forward",
      icon: MenuForward,
      onClickHandler: () => forwardEvent(),
    },

    {
      id: 3,
      type: "icon",
      text: "Share",
      icon: MenuShare,
      onClickHandler: () => shareEvent(),
    },
    {
      id: 4,
      type: "icon",
      text: "Copy Link",
      icon: MenuCopyLink,
      onClickHandler: () => copyLink(),
    },
    {
      id: 5,
      type: "icon",
      text: "Report",
      icon: MenuReport,
      onClickHandler: () => reportEvent(),
    },
  ];

  return (
    <>
      <DropDownMenu
        options={optionsToSelect}
        containerClasses="flex justify-center items-center relative"
        menuCardClasses={classNames({
          "absolute top-3 w-[194px] opacity-100 bg-white -ml-7 mt-4 shadow-xs2 text-gray-13 rounded-15 p-2 z-30 lg:left-auto -left-[128px]":
            true,
          hidden: isShareEvent || isReportEvent || newMessage.isOpen,
        })}
        titleAndSubtitleGroup="flex justify-left items-left hover:bg-gray-1 text-left p-2 rounded-5 text-gray-13 cursor-pointer"
        buttonStyles="flex items center w-30 h-30 ml-2 -mt-2"
        listTextStyles="text-sm1 text-blue-3 tracking-wide ml-3"
        listIconStyles=""
        textAndImageContainer="flex"
      />
      {isShareEvent && (
        <AlertModal
          className="w-576"
          isOpen={isShareEvent}
          onClose={shareEvent}
          closeIconClass=" right-[18px] top-[15px] px-0"
          isCloseIcon
          children={
            <>
              <ShareEvent event={event} user={user} onCloseShare={shareEvent} />
            </>
          }
        />
      )}
      {isReportEvent && (
        <AlertModal
          className="w-576"
          isOpen={isReportEvent}
          onClose={reportEvent}
          closeIconClass=" right-[18px] top-[15px] px-0"
          isCloseIcon
        >
          <Report
            id={event.id}
            type="event"
            onReport={async (id: string, type: string, reason: string) => {
              const flagService = new FlagService();
              const flagItem: IFlagDTO = {
                flaggableId: id,
                flaggableType: type,
                reason,
              };
              await flagService.flagItem(flagItem);
              toast.success("Event reported");
            }}
            onCloseReport={reportEvent}
          />
        </AlertModal>
      )}
    </>
  );
};
export default EventDropdown;
