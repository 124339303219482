import { useSelector } from 'react-redux';
import prerecord from '../../../assets/images/feature/prerecord.svg';
import breakoutrooms from '../../../assets/images/feature/breakout-rooms.svg';
import selfserviceportal from '../../../assets/images/feature/selfservice.svg';
import multitrackagenda from '../../../assets/images/feature/multitrack-agenda.svg';
import leadgeneration from '../../../assets/images/feature/lead-generation.svg';
import analytics from '../../../assets/images/feature/analytics.svg';
import communitycentre from '../../../assets/images/feature/community-center.svg';
import polling from '../../../assets/images/feature/polling.svg';
import contentsyndication from '../../../assets/images/feature/content-syndication.svg';
import liveqanda from '../../../assets/images/feature/live.svg';
import virutalorhybridexpo from '../../../assets/images/feature/virtualexpo.svg';
import sociallouge from '../../../assets/images/feature/social-lounge.svg';
import { LinkButton } from '../../../shared-components';
import { IAppState } from '../../../store';

export const EventBenefits = () => {
  const selectedPackage = useSelector((state: IAppState) => state.setSelectedPackage);

  return (
    <div className="mt-7 container mx-auto flex flex-col items-center">
      <div className="text-lg text-gray-5 mt-6">Panelist Event Benefits</div>
      <div className="text-md text-gray-4 font-light">
        Panelist events get an average of 72% increased engagement
      </div>
      <div className="">
        <div className="md:flex flex-row mx-auto w-10/12">
          <div className="block mt-1 w-7/12 mx-auto">
            <div className="flex mt-7">
              <img src={prerecord} alt="" className="mr-4" />
              <div className="flex flex-col">
                <div className="text-sm1 text-blue-2 font-semibold">Pre-Record or Live</div>
                <div className="text-sm4 text-gray-1 mt-1 pt-1 font-extralight ">
                  Go live or pre-record any of your sessions in your agenda with an in app recording
                  functionality.
                </div>
              </div>
            </div>

            <div className="flex mt-6 pt-1">
              <img src={selfserviceportal} alt="" className="mr-2" />
              <div className="flex flex-col">
                <div className="text-sm1 text-blue-2 font-semibold">
                  Dedicated Self-Service Portals
                </div>
                <div className="text-sm4 text-gray-1 mt-1 pt-1 font-extralight ">
                  Create a seamless experience for your sponsors and speakers with self-service
                  dashboards where they can track their progress.
                </div>
              </div>
            </div>

            <div className="flex mt-3">
              <img src={leadgeneration} alt="" className="mr-2 pr-1" />
              <div className="flex flex-col">
                <div className="text-sm1 text-blue-2 font-semibold">
                  Lead Generation & 1:1 meetings
                </div>
                <div className="text-sm4 text-gray-1 mt-1 pt-1 font-extralight ">
                  Find the right people and connect, schedule meetings with them, keep in touch and
                  meet them at other events.
                </div>
              </div>
            </div>

            <div className="flex mt-1">
              <img src={communitycentre} alt="" className="mr-2" />
              <div className="flex flex-col">
                <div className="text-sm1 text-blue-2 font-semibold">Community Centre</div>
                <div className="text-sm4 text-gray-1 mt-1 pt-1 font-extralight ">
                  Strengthen your relationship with your audience with your resources available to
                  them on-demand.
                </div>
              </div>
            </div>

            <div className="flex mt-6">
              <img src={contentsyndication} alt="" className="mr-2 pr-1" />
              <div className="flex flex-col">
                <div className="text-sm1 text-blue-2 font-semibold">Content Syndication"</div>
                <div className="text-sm4 text-gray-1 mt-1 pt-1 font-extralight ">
                  Create your company profile, gain followers and syndicate your content for your
                  audience at any time.
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <img src={virutalorhybridexpo} alt="" className="mr-2 pr-2" />
              <div className="flex flex-col">
                <div className="text-sm1 text-blue-2 font-semibold">Virtual or Hybrid Expo"</div>
                <div className="text-sm4 text-gray-1 mt-1 pt-1 font-extralight ">
                  Enables your sponsors to discover how they can help their prospects with dedicated
                  exhibition booths.
                </div>
              </div>
            </div>
          </div>

          <div className="block mt-1 w-7/12 mx-auto ml-20">
            <div className="flex mt-7">
              <img src={breakoutrooms} alt="" className="mr-5" />
              <div className="flex flex-col">
                <div className="text-sm1 text-blue-2 font-semibold">Breakout Rooms</div>
                <div className="text-sm4 text-gray-1 mt-1 pt-1 font-extralight ">
                  Direct attendees into breakout rooms and engage them in focused discussions.
                </div>
              </div>
            </div>

            <div className="flex mt-7">
              <img src={multitrackagenda} alt="" className="mr-2" />
              <div className="flex flex-col">
                <div className="text-sm1 text-blue-2 font-semibold">Multitrack Agenda</div>
                <div className="text-sm4 text-gray-1 mt-1 pt-1 font-extralight ">
                  Zero in on your attendees’ interest areas with a multitrack agenda, or a main
                  room, pan your event any way you like.
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <img src={analytics} alt="" className="mr-2" />
              <div className="flex flex-col">
                <div className="text-sm1 text-blue-2 font-semibold">Analytics</div>
                <div className="text-sm4 text-gray-1 mt-1 pt-1 font-extralight ">
                  Real-time insights; monitor attendance, gauge engagement level and take the right
                  action for your follow ups.
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <img src={polling} alt="" className="mr-2" />
              <div className="flex flex-col">
                <div className="text-sm1 text-blue-2 font-semibold">Polling</div>
                <div className="text-sm4 text-gray-1 mt-1 pt-1 font-extralight ">
                  Find out more about your audience by directly engaging them in polls during your
                  event.
                </div>
              </div>
            </div>

            <div className="flex mt-6">
              <img src={liveqanda} alt="" className="mr-2" />
              <div className="flex flex-col">
                <div className="text-sm1 text-blue-2 font-semibold">Live Q&A</div>
                <div className="text-sm4 text-gray-1 mt-1 pt-1 font-extralight ">
                  Turn any session into a live Q&A with attendees at any point during the event.
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <img src={sociallouge} alt="" className="mr-2" />
              <div className="flex flex-col">
                <div className="text-sm1 text-blue-2 font-semibold">Social Lounge</div>
                <div className="text-sm4 text-gray-1 mt-1 pt-1 font-extralight ">
                  A virtual space where the attendee can sit at any table, move across tables, and
                  interact with other attendees.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-7">
        <svg xmlns="http://www.w3.org/2000/svg" width="1065" height="1" viewBox="0 0 1065 1">
          <line
            id="Line_841"
            data-name="Line 841"
            x2="1064"
            transform="translate(0.5 0.5)"
            fill="none"
            stroke="#8e8888"
            strokeLinecap="round"
            strokeWidth="1"
            opacity="0.371"
          />
        </svg>
      </div>
      <div className="mt-7">
        {selectedPackage.selectedPackage === 'Professional' ? (
          <LinkButton
            text="▶︎ Create a free event"
            href="#"
            textColor="#fff"
            backgroundColor="#285cb2"
            classes="text-2xl"
            width="207px"
            height="29px"
          />
        ) : (
          selectedPackage.selectedPackage !== 'Custom' && (
            <LinkButton
              text={`▶︎ Build your package with "${selectedPackage.selectedPackage}"`}
              href="#"
              textColor="#fff"
              backgroundColor="#285cb2"
              classes="text-2xl"
              width="330px"
              height="29px"
            />
          )
        )}
        {selectedPackage.selectedPackage === 'Custom' && (
          <LinkButton
            text="Contact Sales"
            href="/contact-us"
            textColor="#fff"
            backgroundColor="#285cb2"
            classes="text-2xl"
            width="139px"
            height="29px"
          />
        )}
      </div>
    </div>
  );
};

export default EventBenefits;
