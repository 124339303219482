const CommunityContentOne = () => (
  <>
    <div className="text-gray-1 text-lg5 mt-7">Foremostly:</div>
    <div className="font-normal text-gray-1 text-md2">
      <div className="mt-5">
        <div id="berespectful" className="text-blue-1 text-lg2 mt-5 hover:underline">
          Be Respectful
        </div>
        <div className="mt-2">Be civil and thoughtful in your communication and interactions</div>
      </div>
      <div className="mt-5">
        <div id="" className="text-blue-1 text-lg2 mt-5 hover:underline">
          Be Honest
        </div>
        <div className="mt-2">
          Share your real identity and be transparent about your intentions
        </div>
      </div>
      <div className="mt-5">
        <div className="text-blue-1 text-lg2 mt-5 hover:underline">
          Be Inclusive
        </div>
        <div className="mt-2">
          Embrace your fellow Panelists irrespective of race, gender, disability, medical or other
          needs
        </div>
      </div>
    </div>
  </>
);

export default CommunityContentOne;
