import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { IAppState } from "../..";
import { IUserSignUpModel } from "../../../models/signup/user-signup";
import { UserSignUpResult } from "../../../models/signup/user-signup-result";
import { IUserSignUpState } from "../../../models/signup/UserSignUpState";
import { UserService } from "../../../services";
import { UserSignUpActionTypes } from "./types";
import { IReactSelectOption } from "../../../models/react-select-option";
import { IUserEventSignUpModel } from "../../../models/signup/event-registration";
import { IReactSelectOptionCompany } from "../../../models/company/company-react-select-option";
import { toast } from "react-toastify";
import { setAuthToken } from "../../../utils";
import { setRegistered } from "src/store/event-website";
import { setUser } from "src/store/auth";

export interface IUserSignUpProgressAction {
  type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS;
  loggingIn: true;
}

export interface IUserSignUpReset {
  type: UserSignUpActionTypes.RESET_SIGNUP_USER;
  loggingIn: true;
}

export interface IUserSignUpSuccessAction {
  type: UserSignUpActionTypes.SIGNUP_USER_SUCCESS;
  payload: UserSignUpResult;
}

export interface IUserSignUpFailureAction {
  type: UserSignUpActionTypes.SIGNUP_USER_FAILURE;
  errorMessage: string;
}

export interface IUserSignUpErrorAction {
  type: UserSignUpActionTypes.SIGNUP_USER_ERROR;
  errorMessage: string;
}

export interface IUserGoToNextStepAction {
  type: UserSignUpActionTypes.GO_TO_NEXT_STEP;
}

export interface IUserGoToBackStepAction {
  type: UserSignUpActionTypes.GO_TO_BACK_STEP;
}

export interface IUserGoToLastStepAction {
  type: UserSignUpActionTypes.GO_TO_LAST_STEP;
}

export interface IUserGoToFirstStepAction {
  type: UserSignUpActionTypes.GO_TO_FIRST_STEP;
}

export interface IUserSetEmailPasswordAction {
  type: UserSignUpActionTypes.SET_EMAIL_PASSWORD;
  payload: { email: string; password: string };
}

export interface IUserSetFirstNameLastName {
  type: UserSignUpActionTypes.SET_FIRSTNAME_LASTNAME;
  payload: { firstName: string; lastName: string };
}

export interface IUserSetCityCountryPostCode {
  type: UserSignUpActionTypes.SET_CITY_COUNTRY_POSTCODE;
  payload: {
    city: string;
    country: string;
    location: string;
    postCode: string;
  };
}

export interface IUserSetJobTitleCompanyIndustry {
  type: UserSignUpActionTypes.SET_JOBTITLE_COMPANY_INDUSTRY;
  payload: {
    jobTitle: IReactSelectOption;
    company: IReactSelectOptionCompany;
    industry: IReactSelectOption;
  };
}

export interface IUserSetOfferListInterestedList {
  type: UserSignUpActionTypes.SET_OFFERLIST_INTERESTEDLIST;
  payload: {
    offerList: [];
    interestedList: [];
  };
}

export interface IUserSetStaticRegistrationFields {
  type: UserSignUpActionTypes.SET_STATIC_REGISTRATION_FIELDS;
  payload: {
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: IReactSelectOption;
    company: IReactSelectOptionCompany;
    industry: IReactSelectOption;
    city: string;
    country: string;
    location: string;
  };
}

export interface IUserSetDynamicallyRegistrationFields {
  type: UserSignUpActionTypes.SET_DYNAMICALLY_REGISTRATION_FIELDS;
  payload: {
    dynamicFields: any;
    selectedState: any;
    selectedCompany: any;
    selectedIndustry: any;
    selectedJobTitle: any;
  };
}

export type SignUpUserActions =
  | IUserSignUpProgressAction
  | IUserSignUpReset
  | IUserSignUpSuccessAction
  | IUserSignUpFailureAction
  | IUserSignUpErrorAction
  | IUserGoToNextStepAction
  | IUserGoToFirstStepAction
  | IUserGoToBackStepAction
  | IUserGoToLastStepAction
  | IUserSetEmailPasswordAction
  | IUserSetFirstNameLastName
  | IUserSetCityCountryPostCode
  | IUserSetJobTitleCompanyIndustry
  | IUserSetOfferListInterestedList
  | IUserSetStaticRegistrationFields
  | IUserSetDynamicallyRegistrationFields;

export const signupStep1: ActionCreator<
  ThunkAction<Promise<any>, IUserSignUpState, null, IUserSignUpSuccessAction>
> = (data: IUserSignUpModel) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UserSignUpActionTypes.SET_EMAIL_PASSWORD,
        payload: {
          email: data.email,
          password: data.password,
        },
      });
      dispatch({
        type: UserSignUpActionTypes.GO_TO_NEXT_STEP,
      });
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_SUCCESS,
        payload: null,
        isLoggedIn: false,
      });
    } catch (error: any) {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
        errorMessage: error.message,
        isLoggedIn: false,
      });
    } finally {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
      });
    }
  };
};

export const signupNextStep: ActionCreator<
  ThunkAction<Promise<any>, IUserSignUpState, null, IUserSignUpSuccessAction>
> = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UserSignUpActionTypes.GO_TO_NEXT_STEP,
      });
    } catch (error: any) {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
        errorMessage: error.message,
        isLoggedIn: false,
      });
    } finally {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
      });
    }
  };
};

export const signupBackStep: ActionCreator<
  ThunkAction<Promise<any>, IUserSignUpState, null, IUserSignUpSuccessAction>
> = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UserSignUpActionTypes.GO_TO_BACK_STEP,
      });
    } catch (error: any) {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
        errorMessage: error.message,
        isLoggedIn: false,
      });
    } finally {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
      });
    }
  };
};

export const signupUserExsist: ActionCreator<
  ThunkAction<Promise<any>, IAppState, null, IUserSignUpSuccessAction>
> = (data: IUserSignUpModel) => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();

    try {
      const result = await userService.registerStep2({
        ...data,
      });

      dispatch({
        type: UserSignUpActionTypes.SET_FIRSTNAME_LASTNAME,
        payload: {
          firstName: data.firstName,
          lastName: data.lastName,
        },
      });
      dispatch({
        type: UserSignUpActionTypes.GO_TO_NEXT_STEP,
      });
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_SUCCESS,
        payload: result,
        isLoggedIn: true,
      });
    } catch (error: any) {
      console.error(error);

      if (error.errorCode === "401") {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: error.message,
          isLoggedIn: false,
        });
      } else if (error.statusCode === 500) {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: "Something went wrong. Please try again!",
          isLoggedIn: false,
        });
      } else {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: error.message,
          isLoggedIn: false,
        });
      }
    } finally {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
        loggingIn: false,
      });
    }
  };
};

export const signupUserExsistShortRegister: ActionCreator<
  ThunkAction<Promise<any>, IAppState, null, IUserSignUpSuccessAction>
> = (
  data: IUserSignUpModel,
  selectedCompany: IReactSelectOption,
  selectedJobTitle: IReactSelectOption,
  selectedIndustry: IReactSelectOption
) => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();

    let updateValueInBackButtonOfSignup: boolean = false;
    try {
      //update FirstName-LastName
      const result1 = await userService.registerStep2({
        ...data,
      });
      //update City
      const result2 = await userService.registerStep3({
        ...data,
        postCode: "",
      });
      //update JobTitle-Company-Industry
      const result3 = await userService.registerStep4({
        ...data,
      });
      if (result1 && result2 && result3) updateValueInBackButtonOfSignup = true;

      dispatch({
        type: UserSignUpActionTypes.SET_STATIC_REGISTRATION_FIELDS,
        payload: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          jobTitle: selectedJobTitle,
          company: selectedCompany,
          industry: selectedIndustry,
          city: data.city,
          country: data.country,
          location: data.location,
        },
      });
      dispatch({
        type: UserSignUpActionTypes.GO_TO_NEXT_STEP,
      });
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_SUCCESS,
        payload: updateValueInBackButtonOfSignup,
        isLoggedIn: true,
      });
    } catch (error: any) {
      console.error(error);

      if (error) {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: error.message,
          isLoggedIn: false,
        });
      }
    } finally {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
        loggingIn: false,
      });
    }
  };
};

export const signupShortRegister: ActionCreator<
  ThunkAction<Promise<any>, IAppState, null, IUserSignUpSuccessAction>
> = (
  data: IUserSignUpModel,
  selectedCompany: IReactSelectOptionCompany,
  selectedJobTitle: any,
  selectedIndustry: IReactSelectOption
) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const emailPassword = getState().signupUser.emailPassword;
    if (!emailPassword) return;

    const userService: UserService = new UserService();

    try {
      const addUserResult = await userService.shortRegister({
        ...data,
        email: emailPassword.email,
        password: emailPassword.password,
      });
      if (addUserResult) {
        setAuthToken(addUserResult.data);

        dispatch({
          type: UserSignUpActionTypes.SET_STATIC_REGISTRATION_FIELDS,
          payload: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: emailPassword.email,
            jobTitle: selectedJobTitle,
            company: selectedCompany,
            industry: selectedIndustry,
            city: data.city,
            country: data.country,
            location: data.location,
          },
        });
        dispatch({
          type: UserSignUpActionTypes.GO_TO_NEXT_STEP,
        });
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_SUCCESS,
          payload: addUserResult,
          isLoggedIn: true,
        });
      }
    } catch (error: any) {
      console.error(error);

      if (error) {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: error.message,
          isLoggedIn: false,
        });
      }
    } finally {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
        loggingIn: false,
      });
    }
  };
};

export const signupStep5: ActionCreator<
  ThunkAction<Promise<any>, IAppState, null, IUserSignUpSuccessAction>
> = (data: IUserSignUpModel) => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();

    try {
      await userService.registerInterests(data);

      await userService.registerOffers(data);

      dispatch({
        type: UserSignUpActionTypes.SET_OFFERLIST_INTERESTEDLIST,
        payload: {
          offerList: data.offerListInterestedList.offerList,
          interestedList: data.offerListInterestedList.interestedList,
        },
      });
      dispatch({
        type: UserSignUpActionTypes.GO_TO_NEXT_STEP,
      });
    } catch (error: any) {
      console.error(error);

      if (error.errorCode === "401") {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: error.message,
          isLoggedIn: false,
        });
      } else {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: error.message,
          isLoggedIn: false,
        });
      }
    } finally {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
        loggingIn: false,
      });
    }
  };
};

export const eventRegistrationStep1: ActionCreator<
  ThunkAction<Promise<any>, IAppState, null, IUserSignUpSuccessAction>
> = (
  data: IUserSignUpModel,
  selectedCompany: IReactSelectOption,
  selectedJobTitle: IReactSelectOption,
  selectedIndustry: IReactSelectOption,
  selectedJobFunction: IReactSelectOption
  // setIsLoader: any
) => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();
    // sessionStorage.setItem("isProfileLoading", JSON.stringify(true));
    // if (setIsLoader) setIsLoader(true);
    try {
      const addUserResult = await userService.shortRegister(data);
      if (addUserResult) {
        setAuthToken(addUserResult.data);
        dispatch({
          type: UserSignUpActionTypes.SET_STATIC_REGISTRATION_FIELDS,
          payload: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            jobTitle: selectedJobTitle,
            company: selectedCompany,
            industry: selectedIndustry,
            jobFunction: selectedJobFunction,
            city: data.city,
            country: data.country,
            location: data.location,
            postCode: data.postCode,
          },
        });
        userService
          .getOwnProfile()
          .then((r) => r.data)
          .then((user) => dispatch(setUser(user)))
          .catch();
        dispatch({
          type: UserSignUpActionTypes.GO_TO_NEXT_STEP,
        });
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_SUCCESS,
          payload: addUserResult,
          isLoggedIn: true,
        });
        // sessionStorage.setItem("isProfileLoading", JSON.stringify(false));
        // if (setIsLoader) setIsLoader(false);
        sessionStorage.removeItem("termAndCondition");
      }
    } catch (error: any) {
      console.error(error);
      // if (setIsLoader) setIsLoader(false);
      // sessionStorage.setItem("isProfileLoading", JSON.stringify(false));
      if (error.errorCode === "401") {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: error.message,
          isLoggedIn: false,
        });
      } else if (error.statusCode === 500) {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: "Something went wrong. Please try again!",
          isLoggedIn: false,
        });
      } else {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: error.message,
          isLoggedIn: false,
        });
      }
    } finally {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
        loggingIn: false,
      });
    }
  };
};

export const eventRegistrationUserExsist: ActionCreator<
  ThunkAction<Promise<any>, IAppState, null, IUserSignUpSuccessAction>
> = (
  data: IUserSignUpModel,
  selectedCompany: IReactSelectOption,
  selectedJobTitle: IReactSelectOption,
  selectedIndustry: IReactSelectOption
) => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();
    let updateValueInBackButtonOfEventRegistration: boolean = false;
    try {
      //update FirstName-LastName
      const result1 = await userService.registerStep2({
        ...data,
      });
      //update City
      const result2 = await userService.registerStep3({
        ...data,
        postCode: "",
      });
      //update JobTitle-Company-Industry
      const result3 = await userService.registerStep4({
        ...data,
      });
      if (result1 && result2 && result3)
        updateValueInBackButtonOfEventRegistration = true;

      dispatch({
        type: UserSignUpActionTypes.SET_STATIC_REGISTRATION_FIELDS,
        payload: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          jobTitle: selectedJobTitle,
          company: selectedCompany,
          industry: selectedIndustry,
          city: data.city,
          country: data.country,
          location: data.location,
        },
      });
      dispatch({
        type: UserSignUpActionTypes.GO_TO_NEXT_STEP,
      });
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_SUCCESS,
        payload: updateValueInBackButtonOfEventRegistration,
        isLoggedIn: true,
      });
    } catch (error: any) {
      console.error(error);

      if (error.errorCode === "401") {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: error.message,
          isLoggedIn: false,
        });
      } else {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: error.message,
          isLoggedIn: false,
        });
      }
    } finally {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
        loggingIn: false,
      });
    }
  };
};

export const eventRegistrationLastStep: ActionCreator<
  ThunkAction<Promise<any>, IUserSignUpState, null, IUserSignUpSuccessAction>
> = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UserSignUpActionTypes.GO_TO_LAST_STEP,
      });
    } catch (error: any) {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
        errorMessage: error.message,
        isLoggedIn: false,
      });
    } finally {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
      });
    }
  };
};

export const eventRegistrationStep2: ActionCreator<
  ThunkAction<Promise<any>, IAppState, null, IUserSignUpSuccessAction>
> = (
  eventId: string,
  body: IUserEventSignUpModel,
  dynamicFields: any,
  selectedState,
  selectedCompany,
  selectedIndustry,
  selectedJobTitle
) => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();

    try {
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
        loggingIn: true,
      });
      const submitAllFieldsResult = await userService.submitRegister(
        eventId,
        body
      );
      
      if (submitAllFieldsResult) {
        dispatch({
          type: UserSignUpActionTypes.SET_DYNAMICALLY_REGISTRATION_FIELDS,
          payload: {
            dynamicFields: dynamicFields,
            selectedState: selectedState,
            selectedCompany: selectedCompany,
            selectedIndustry: selectedIndustry,
            selectedJobTitle: selectedJobTitle,
          },
        });

        dispatch({
          type: UserSignUpActionTypes.GO_TO_NEXT_STEP,
        });
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_SUCCESS,
          payload: submitAllFieldsResult,
          isLoggedIn: true,
        });
        dispatch(setRegistered(true));
        sessionStorage.removeItem("signupAnswers");
        localStorage.removeItem("surveyAnswers");
      }
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
        loggingIn: false,
      });
    } catch (error: any) {
      // toast.error(error?.message);
      console.error(error);
      dispatch({
        type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
        loggingIn: false,
      });

      if (error.errorCode === "401") {
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: error.message,
          isLoggedIn: false,
        });
      } else if (error.statusCode === 400) {
        if (error.message.includes("domain is not allowed")) {
          toast.error(error.message);
        } else {
          toast.error("Something went wrong. Please try again!");
        }

        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: "Something went wrong. Please try again!",
          isLoggedIn: false,
        });
      } else if (error.statusCode === 500) {
        toast.error("Something went wrong. Please try again!");
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: "Something went wrong. Please try again!",
          isLoggedIn: false,
        });
      } else if (error.code === "EVENT_ATTENDANCE_REQUEST_02") {
        toast.error(error.message, { autoClose: 5000 });
        dispatch({
          type: UserSignUpActionTypes.GO_TO_LAST_STEP,
          isLoggedIn: true,
        });
      } else {
        toast.error("Something went wrong. Please try again!");
        dispatch({
          type: UserSignUpActionTypes.SIGNUP_USER_ERROR,
          errorMessage: error.message,
          isLoggedIn: false,
        });
      }
    } finally {
      // dispatch({
      //   type: UserSignUpActionTypes.SIGNUP_USER_IN_PROGRESS,
      //   loggingIn: false,
      // });
    }
  };
};
