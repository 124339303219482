import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { IEvent } from '../../../models/event/event';
import { IAppState } from '../../../store';
import { Banner } from './banner';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useCurrentUser } from 'src/utils/hooks';
import { useEffect } from 'react';
import { getMinifiedImage } from 'src/mui/utils/image';
import { init } from 'src/store/event-website';
import { Loader } from 'src/shared-components/Loader';
import { HeaderSection } from './header';
import './index.css';
import { AgendaSection } from './agenda-section';
import { SpeakerSection } from './speaker';
import { OverviewSection } from './overview';
import { PartnersSection } from './partners';
import EventGallery from './event-gallery';
import { EventQuestionPageTypes, useSurveyContext } from 'src/mui/providers/GeneralContext';
import { FooterSection } from './footer';
import WhatToExpect from './what-to-expect';
import { BoxBannerSurveys } from 'src/screens/surveys/BoxBannerSurveys';
import BoxEventGallery from 'src/screens/surveys/BoxEventGallery';

export const BoxWebsite = () => {
  const params = useParams();

  const eventId: string = params?.slug!;
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const survey: any = useSelector((state: IAppState) => state.website.survey!);
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const { setEventIdParam, getQuestionPagesInsights, getSurveyGallery } = useSurveyContext();

  useEffect(() => {
    if (event?.id) setEventIdParam(event?.id);
  }, [event]);

  useEffect(() => {
    const link = document.querySelector('link[rel="icon"]');
    if (link) {
      link?.setAttribute('href', `${getMinifiedImage(survey?.logo! || event?.logo!)}`);
      link.setAttribute('sizes', '16x16');
    }
  }, [survey || event]);

  const loaded = useSelector((state: IAppState) => state.website.loaded);


  useEffect(() => {
    if (survey?.id) {
      if (user) {
        getQuestionPagesInsights(EventQuestionPageTypes.SURVEY, survey?.id!);
        getSurveyGallery(survey?.id!);
      }
    }
  }, [survey?.id, user]);

  return loaded ? (
    <>
      <Helmet>
        <title>{survey?.name || event?.name}</title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap" />
        <style>{`
          body {
            font-family: 'Lato', sans-serif;
            scroll-behavior: smooth;
          }
        `}</style>
      </Helmet>
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          width: 1,
          p: 0,
          pl: '0px !important',
          pr: '0px !important',
        }}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL_PREFIX}/${survey?.cover! || event?.cover!})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top center'
        }}
      >
        <HeaderSection />
        {survey ? <><BoxBannerSurveys /><BoxEventGallery /></> : <Banner coverUrl={event?.cover!} />}
        {!survey &&
          <><OverviewSection /><EventGallery /><WhatToExpect /><SpeakerSection /><AgendaSection /><PartnersSection /></>
        }
        
        <FooterSection />
      </Container>
    </>
  ) : <Loader isLoaderLogo className="h-[100vh]" key="loader" />;
};
