import React, { FC } from 'react';
import { UserNavbar } from '../components';

interface ICompanySpotlightLayoutProps {
  hero?: JSX.Element;
  children: JSX.Element;
  isHideNavbar?: boolean;
}
export const CompanySpotlightLayout: FC<ICompanySpotlightLayoutProps> = ({
  hero,
  children,
  isHideNavbar
}) => (
  <div className="bg-gray-1 h-full">
    {!isHideNavbar && <UserNavbar />}
    <div>
      <div className="lg:container mx-auto lg:w-[1258px] w-full lg:px-0 px-4 md:pb-0 pb-[60px]">
        <div className="rounded-b-lg overflow-hidden">
          {hero}
        </div>
        <div className="flex flex-row justify-center pt-4">
          <div className="md:mb-8 w-full">{children}</div>
        </div>
      </div>
    </div>
  </div>
);

export default CompanySpotlightLayout;
