import { useDispatch, useSelector } from 'react-redux';
import { NAV_MENU } from '../../constants';
import { IConversation } from '../../models/messages/conversation';
import { useEffect, useState } from 'react';
import { IAppState } from '../../store';
import { setFocusMessage } from '../../store/privateChat';
import { useCurrentUser, useWebSocket } from '../../utils/hooks';
import { ChatListItem } from './chat-list-item';
import logo from '../../assets/images/nav-menu/no-message.svg';

interface IChatListProps {
  handleChatDropdown?: Function;
}

const ChatListWrapper = ({ handleChatDropdown }: IChatListProps) => {
  const user = useCurrentUser();
  const dispatch = useDispatch();

  const [isOnline, setIsOnline] = useState<any>();
  const conversations = useSelector((state: IAppState) => state.privateChat.conversations);

  const { webSocket } = useWebSocket();

  const setFocusMessageHandler = (chosenMessage: string) => {
    dispatch(setFocusMessage(chosenMessage));
    if (handleChatDropdown) handleChatDropdown();
  };

  // useEffect(() => {
  //   webSocket.then((ws) =>
  //     ws.send(
  //       JSON.stringify({
  //         action: 'conversations',
  //         data: { limit: 25, first: 20, exclusiveStartKey: null }
  //       })
  //     )
  //   );
  // }, [webSocket]);

  // ononline version
  window.ononline = () => {
    setIsOnline(true);
  };

  // onofflinee version
  window.onoffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    if (isOnline) {
      webSocket.then(ws => {
        // console.log(ws.readyState)
        ws.send('{"action": "ping","data": { "data": "PONG"}}')
      });
    }
  }, [isOnline, webSocket]);

  return (
    <div className="flex flex-col mt-2 messages-container rounded-t-15 max-h-[calc(100vh-20px)] overflow-y-scroll md:max-h-full md:overflow-y-auto">
      {conversations &&
        conversations.Items.map((conversation: IConversation, index: number) => {
          return (
            <ChatListItem
              key={index}
              currentUser={user?.id!}
              conversation={conversation}
              onClick={() => setFocusMessageHandler(conversation.conversationId)}
            />
          );
        })}
      {(conversations?.Items.length === 0 || conversations === undefined) && (
        <div className="flex flex-col justify-center mx-auto w-full">
          <div className="flex justify-center">
            <div className="text-md text-blue-4 m-5 w-8/12 text-center">{NAV_MENU.NO_MESSAGES}</div>
          </div>
          <div className="h-[247px] mx-auto">
            <img src={logo} className="w-[205px]" alt="No message available" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatListWrapper;
