/* eslint-disable unused-imports/no-unused-imports-ts */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { JOIN_TO_EVENT } from '../../../../constants';
import { IAppState } from '../../../../store';
import { useCurrentUser } from '../../../../utils/hooks';
import { CalendarList } from './calendar-list';
import { GotoList } from './goto-list';
import offer from '../../../../assets/images/signup/tick-done.svg';
import { Template } from './pdf-template';
import { QrService } from '../../../../services/QrService';
import { getUpcomingEvent } from '../../../../store/events/upcoming-event/actions';

interface IFinalMessageContentProps {
  gotoHide?: boolean;
  eventId: string;
  isSpectreForm?: boolean;
}

const FinalMessageContent = ({ gotoHide, eventId, isSpectreForm }: IFinalMessageContentProps) => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const [qrImage, setQrImage] = useState('');
  const event = useSelector((state: IAppState) => state?.website?.event || state?.upcomingEvent?.value?.data?.event);
  const audienceStatus = useSelector((state: IAppState) => state?.upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus);

  useEffect(() => {
    if (event?.id && user?.id) {
      const qrService = new QrService();
      qrService.getQrCode(event?.id, user?.id).then((res: string) => setQrImage(res));
    }
  }, [event, user]);

  useEffect(() => {
    if (eventId) {
      dispatch(getUpcomingEvent(eventId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  if (!event) return null;
  return (
    <div
      className={classNames('mx-auto', {
        'h-full flex items-center pt-[20px]': user && event?.type === 'invitation'
      })}
    >
      <div className="w-[520px] mx-auto mobile-w-full">
        <div className="mx-auto pt-[40px] mobile-pt-15">
          <div className="flex justify-center">
            <img src={offer} className="flex justify-center mobile-tick" alt="Close" />
          </div>
          <div
            className={classNames('flex justify-center mt-[18px] mb-2 text-[22px] leading-[33px]', {
              'text-white': isSpectreForm,
              'text-[#3C3C3C]': !isSpectreForm,
            })}
          >
            Done!
          </div>
          {user && event.type === 'invitation' && audienceStatus !== 'accepted' && (
            <div className="flex flex-col items-center justify-center mb-6">
              <div
                className={classNames('text-[13px] leading-5 tracking-wide', {
                  'text-white': isSpectreForm,
                  'text-[#3C3C3C]': !isSpectreForm,
                })}
              >
                Your registration is under review!
              </div>
              <div
                className={classNames('text-[13px] leading-5 tracking-wide', {
                  'text-white': isSpectreForm,
                  'text-[#3C3C3C]': !isSpectreForm,
                })}
              >
                {JOIN_TO_EVENT.REGISTRATION_ACCEPTED_NOTIFICATION}
              </div>
            </div>
          )}
          {user && audienceStatus === 'accepted' && (
            <div className="flex justify-center">
              <div
                className={classNames('text-[13px] leading-5 tracking-wide', {
                  'text-white': isSpectreForm,
                  'text-[#3C3C3C]': !isSpectreForm,
                })}
              >
                {JOIN_TO_EVENT.REGISTERED}
              </div>
            </div>
          )}
        </div>
        {user && audienceStatus === 'accepted' && (
          <div className="flex justify-center mt-2">
            <img src={qrImage} className="flex justify-center mobile-qr" alt="Close" />
          </div>
        )}
        {isSpectreForm && user && audienceStatus === 'pending' && (
          <div className="flex justify-center mt-2">
            <img src={qrImage} className="flex justify-center" alt="Close" />
          </div>
        )}
        {
          event?.confirmationMessage && (
            <div
              className={classNames('text-center mt-2', {
                'pb-[60px]': user && event?.type === 'invitation'
              })}
            >
              <div className="text-[13px] font-semibold leading-5 text-[#3C3C3C]">Organiser’s message:</div>
              <div className="text-[13px] font-normal leading-5 text-[#3C3C3C] mobile-p-x organization-scroll">
                {event?.confirmationMessage}
              </div>
            </div>
          )
        }
        {user && audienceStatus === 'accepted' && (
          <div className="text-center flex justify-center mt-[30px] mb-[20px] mobile-mt-20">
            <div className="flex items-center gap-5 mobile-gap-10">
              {!gotoHide && (
                <div className="flex-shrink-0">
                  <GotoList
                    fillColor="white"
                    titleClass="text-[13px] font-semibold text-white tracking-wide mr-[10px]"
                    btnClassName={classNames('text-white bg-[#0049EF] rounded-10 h-[30px] px-[15px] flex items-center', {
                      'bg-[#0071e3]': isSpectreForm,
                      'bg-[#203C6E]': !isSpectreForm,
                    })}
                    title="Go to"
                    dropdownClass="w-[113px] rounded-10"
                  />
                </div>
              )}
              <div className="flex align-center">
                <div className="flex-shrink-0 mr-[10px]">
                  <CalendarList
                    fillColor="white"
                    titleClass="text-[13px] font-semibold text-white tracking-wide mr-[10px]"
                    btnClassName="text-white bg-[#0049EF] rounded-10 h-[30px] px-[15px] flex items-center"
                    title="Add to calendar"
                  />
                </div>
                <div className="flex-shrink-0">
                  <PDFDownloadLink
                    document={(
                      <Template pdfData={{
                        qrImage,
                        message: JOIN_TO_EVENT.REGISTERED,
                        organizerMessage: event?.confirmationMessage,
                        eventName: event?.name,
                        location: event?.eventRegions[0]?.name,
                        dateTime: `${`${event.startTime} ${event.timezone}`}`
                      }}
                      />
)}
                    fileName={`${event?.name}.pdf`}
                  >
                    {({ loading }) => (loading ? 'Loading pdf...'
                      : (
                        <button
                          type="button"
                          className="text-white bg-[#0049EF] rounded-10 h-[30px] px-[15px] flex items-center"
                        >
                          <span className="text-[13px] font-semibold text-white tracking-wide]">Download QR Pdf</span>
                        </button>
                      ))}
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(FinalMessageContent);
