export interface ILikeSvgIconProps {
    fillColor?: string;
}
export const CommentSvgIcon = ({ fillColor }: ILikeSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#717171"
    return <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 17.708 17.686">
    <g id="Component_64_1" data-name="Component 64 – 1" transform="translate(0.658 0.652)">
      <g id="Group_11492" data-name="Group 11492" transform="translate(0 0)">
        <g id="Group_15425" data-name="Group 15425" transform="translate(0 0)">
          <path id="Subtraction_3" data-name="Subtraction 3" d="M15.471,15.544a.537.537,0,0,1-.676.753s-1.536-.633-2.2-.913a.688.688,0,0,0-.6.037,7.047,7.047,0,0,1-3.1.947A8.364,8.364,0,0,1,.054,9.14a8.2,8.2,0,1,1,16.122.978,7.3,7.3,0,0,1-1.524,3.059.674.674,0,0,0-.068.74Z" transform="translate(0 0)" fill="none" stroke={iconColor} stroke-miterlimit="10" strokeWidth="1.3"/>
        </g>
      </g>
    </g>
  </svg>
  
};