// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { useState } from "react";

// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  className?: string;
  label?: string;
  helperText?: React.ReactNode;
  sx?: any,
  onlySelectFromDropdown?: boolean,
  placeholder?: any;
  hideEndAdornment?: boolean
}

export default function PAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  onlySelectFromDropdown,
  name,
  className,
  label,
  helperText,
  sx,
  placeholder,
  hideEndAdornment,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, "renderInput">) {
  const { control } = useFormContext();

  const [inputValue, setInputValue] = useState('');
  const [openMenu, setOpenMenu] = useState(false);

  const filteredOptions = other?.options?.filter((option: any) =>
    option?.name?.toLowerCase().includes(inputValue.toLowerCase())
  );

   const fieldHelperText = {
    "& .MuiFormHelperText-root": { ml: "1px" }
  }

  const handleBlur = (field: any) => {
    if (!filteredOptions.length && onlySelectFromDropdown) {
      setInputValue('');
      field.onChange(null);
      setOpenMenu(false)
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          open={openMenu && filteredOptions.length ? true : false}
          onOpen={() => setOpenMenu(true)}
          onClose={() => setOpenMenu(false)}
          onChange={(_, newValue) => field.onChange(newValue)}
          onBlur={() => handleBlur(field)}
          renderInput={(params) => (
            <TextField
              sx={{
                ...sx,
                ...fieldHelperText,
                "& .MuiAutocomplete-input": {
                  pl: "0px !important"
                }
              }}
              className={className}
              placeholder={placeholder}
              label={label}
              error={!!error}
              onChange={(event) => {
                // Update the field value with the custom input
                setInputValue(event?.target?.value)
                if (onlySelectFromDropdown !== true)
                  field.onChange(event.target.value && event.target.value?.trim()  !== "" ? { id: event.target.value, name: event.target.value } : null);
                else field.onChange(event.target.value?.trim() !== "" ? event.target.value : null);
              }}
              helperText={error ? error?.message : helperText}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: hideEndAdornment ? null : params.InputProps.endAdornment,
              }}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
