import {
  format,
  parseISO,
} from 'date-fns';

import { EventCoverAndLogo } from '../../../components/event-cover-and-logo';

interface IEventCardProps {
  title: string;
  cover: string;
  logo: string;
  eventDate: string;
  location: string;
}

const EventCard = ({
  title,
  cover,
  logo,
  eventDate,
  location,
}: IEventCardProps) => (
  <div className="rounded-10 flex flex-col bg-[#EDF2F5] ">
    <div className="">
      <EventCoverAndLogo
        cover={`${cover}`}
        logo={`${logo}`}
        coverClassName="mx-auto bg-cover !drop-shadow-none bg-center rounded-t-10 h-[127.5px] w-[330px]"
        logoClassName="top-[55px] left-[15px] w-[61px] !h-[61px] rounded-[5px]"
      />
    </div>
    <div className="py-2 px-[15px]">
      <div className="text-sm5 text-[#172746] font-semibold mb-[5px]">{title}</div>
      <div className="text-x-sm text-[#203C6E] font-light leading-[14px] mb-[5px]">
        {format(parseISO(eventDate), "d MMMM yyyy")} •{" "}
        {format(parseISO(eventDate), "hh:mm a")}{" "}
        {format(parseISO(eventDate), "zzzz")}
      </div>
      <div className="text-x-sm text-[#203C6E] leading-[14px] font-bold">{location}</div>
    </div>
  </div>
);

export default EventCard;
