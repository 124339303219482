import React from 'react';
import parse from 'html-react-parser';
import he from 'he';

type ParseContentProps = { content?: string | null, className?: string };

export const ParseContent: React.FC<ParseContentProps> = ({ content, className }) => {
  const decodeHtmlEntities = (htmlString: string): string => {
    return he.decode(htmlString);
  };

  const decodeURL = (url: string): string => {
    const decodedUrl = decodeURIComponent(url);
    // Remove HTML entities and replace %20 with spaces
    const cleanURL = decodedUrl.replace(/(&nbsp;|%20)/g, ' ');

    // Fix the line -work#:~:text=
    // const finalURL = cleanURL.replace(/-work#:~:text=/g, ' ');

    return cleanURL;
  };

  const modifyContent = (htmlString: string): string => {
    try {
      // Decode HTML entities
      let decodedContent = decodeHtmlEntities(htmlString);
      // Decode URL
      decodedContent = decodeURL(decodedContent);
  
      return decodedContent;
      
    } catch (error) {
        console.log("error",error)
        return htmlString
    }
  };

  return (
    <div className={className}>{content && parse(modifyContent(content))}</div>
  );
};
