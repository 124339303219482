import { Dispatch } from '@reduxjs/toolkit';
import { videoCallSlice } from '.';
import { IAppState } from '..';
import { IUserProfile } from '../../models/user-profile';
import { _webSocket } from "../../utils/hooks";
import { User } from './types';

// WebSocket Template
const sendWsMessage = (message: any) => {
  const webSocket = _webSocket;
  return webSocket && webSocket.then((ws) => ws.send(JSON.stringify(message))).catch(console.error);
}

export const callInvite = (bParty: string, user: User) => async (dispatch: Dispatch) => {
  sendWsMessage({
    action: 'call-invite',
    data: { bParty }
  });
  setTimeout(() => {
    dispatch(videoCallSlice.actions.callInvite(user));
  }, 100)
  
}

export const sendReaction = (bParty: string,reaction:string,user:any) => async (dispatch: Dispatch) => {
  sendWsMessage({
    action: 'live-send-reaction',
    data: { otherUserIds:[bParty],   reaction}
  });
  setTimeout(() => {
    dispatch(videoCallSlice.actions.sendReaction(user));
  }, 100)
  
}

export const removeReaction = () => async (dispatch: Dispatch) => {
  setTimeout(() => {
    dispatch(videoCallSlice.actions.removeReaction(null));
  }, 100)
  
}

export const callCancel = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  const { chatVideoCall } = getState();
  sendWsMessage({
    action: 'call-cancel',
    data: { callId: chatVideoCall.aParty?.callId }
  });
  dispatch(videoCallSlice.actions.callCancel({ callId: chatVideoCall.aParty?.callId }));

  // Reset Redux
//   dispatch(videoCallSlice.actions.unsetExpoCall());
}

export const callAnswer = () => async (dispatch: Dispatch, getState: () => IAppState) => {
    const { chatVideoCall } = getState();
    sendWsMessage({
      action: 'call-answer',
      data: { callId: chatVideoCall.bParty?.callId }
    });
    dispatch(videoCallSlice.actions.callAnswer());
  }
  

export const callDecline = () => async (dispatch: Dispatch, getState: () => IAppState) => {
    const { chatVideoCall } = getState();
    sendWsMessage({
      action: 'call-decline',
      data: { callId: chatVideoCall.bParty?.callId }
    });
    dispatch(videoCallSlice.actions.callDecline());
}

export const callCancelledClose = () => async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch(videoCallSlice.actions.callCancelledClose({}));
}

export const callHangUp = () => async (dispatch: Dispatch, getState: () => IAppState) => {
    const { chatVideoCall } = getState();
    sendWsMessage({
      action: 'call-hang-up',
      data: { callId: chatVideoCall.aParty?.callId || chatVideoCall.bParty?.callId  }
    });
    dispatch(videoCallSlice.actions.callHangUp());
}