import { EventsNavbar, Navbar } from '../../../components/navbar';
import CopyrightBanner from './copyright-banner';
import CopyrightIntro from './copyright-intro';
import CopyrightContent from './copyright-content';

const menuItems = [
  {
    menuItemText: 'User Agreement',
    href: '/user-agreement',
    isSelected: false
  },
  {
    menuItemText: 'Privacy Policy',
    href: '/privacy-policy.html',
    isSelected: false
  },
  {
    menuItemText: 'Cookie Policy',
    href: '/cookie-policy',
    isSelected: false
  },
  {
    menuItemText: 'Copyright Policy',
    href: '/copyright-policy',
    isSelected: true
  },
  {
    menuItemText: 'Community Guidelines',
    href: '/community-guidelines',
    isSelected: false
  },
  {
    menuItemText: 'Host Agreement',
    href: '/host-agreement',
    isSelected: false
  }
];
export const CopyrightPolicy = () => (
  <>
    <Navbar />
    <div className="">
      <EventsNavbar menuItems={menuItems} showCreateEventButton />
    </div>
    <div className="mx-auto">
      <div className="">
        <div className="flex flex-col">
          <CopyrightBanner />
          <CopyrightIntro />
          <CopyrightContent />
        </div>
      </div>
    </div>
  </>
);
