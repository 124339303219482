/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { minifiedImage } from 'src/utils';
import { Logo } from 'src/shared-components/V2/Logo';
import { format, parseISO } from 'date-fns';
import { Checkbox, FormControlLabel } from '@mui/material';

interface AssignListProps {
  search: string;
  events: any[];
  selected: any[];
  onAssignChange: (assignStructure: any) => void;
}

const AssignList: React.FC<AssignListProps> = ({
  search, events, selected, onAssignChange
}) => {
  const [isAddedList, setIsAddedList] = useState<{ [key: string]: boolean }>({});
  const [assignedEventIds, setAssignedEventIds] = useState<{ id: string; onlyMyTargetAccount: boolean }[]>([]);
  const [checkboxStates, setCheckboxStates] = useState<{ [key: string]: boolean }>({});

  const groupBy = (arr: any, property: string) => arr.reduce((memo: any, x: any) => {
    // eslint-disable-next-line no-param-reassign
    if (!memo[x[property]]) { memo[x[property]] = []; }
    memo[x[property]].push(x);
    return memo;
  }, {});

  useEffect(() => {
    const addedEventSponsors: { [key: string]: boolean } = {};
    const eventSponsorCheckboxStates: { [key: string]: boolean } = {};

    const hasUnassignedEventSponsors = selected?.some(sponsor => sponsor?.assignedEventSponsors?.length === 0);

    const selectedCompanyAccountFileIds = hasUnassignedEventSponsors
      ? []
      : selected?.flatMap(sponsor => sponsor?.assignedEventSponsors?.map((item: any) => item?.companyAccountFileId).filter(Boolean) || []);

    const selectedAssignedEventSponsors = hasUnassignedEventSponsors
      ? []
      : selected?.flatMap(sponsor => sponsor?.assignedEventSponsors || []);

    const groupedByEventSponsor = groupBy(selectedAssignedEventSponsors, 'eventSponsorId');
    Object.keys(groupedByEventSponsor)?.forEach((eventSponsorId) => {
      const relatedEventSponsors = groupedByEventSponsor?.[eventSponsorId];
      const areAllIdsIncluded = selectedCompanyAccountFileIds.every(id => relatedEventSponsors.some((eventSponsor: any) => eventSponsor.companyAccountFileId === id));
      addedEventSponsors[eventSponsorId] = areAllIdsIncluded;
    });

    const uniqueSelectedAccountIds = hasUnassignedEventSponsors
      ? []
      : selected?.flatMap(sponsor => sponsor?.assignedEventSponsors?.map((item: any) => item?.companyAccountFileId) || [])
        .filter((value: string, index: number, self: string[]) => self.indexOf(value) === index);

    const matchingAccounts: any[] = [];
    uniqueSelectedAccountIds.forEach((accountFileId: string) => {
      const relatedEvents = events?.flatMap(sponsor => sponsor?.eventSponsorAccountFiles?.map((item: any) => item) || []);
      const matchingEventSponsorAccounts = relatedEvents?.filter((eventSponsorAccount: any) => eventSponsorAccount.companyAccountFileId === accountFileId);
      if (matchingEventSponsorAccounts.length > 0) {
        matchingAccounts.push(...matchingEventSponsorAccounts);
      }
    });

    const groupedByTargetAccount = groupBy(matchingAccounts, 'eventSponsorId');
    Object.keys(groupedByTargetAccount)?.forEach((eventSponsorId) => {
      const relatedTargetAccounts = groupedByTargetAccount?.[eventSponsorId];
      const isAllTargetAccountsMatched = relatedTargetAccounts?.every((targetAccount: any) => targetAccount?.onlyMyTargetAccount);
      const areAllIdsIncluded = selectedCompanyAccountFileIds.every(id => relatedTargetAccounts.some((targetAccount: any) => targetAccount.companyAccountFileId === id));
      eventSponsorCheckboxStates[eventSponsorId] = isAllTargetAccountsMatched && areAllIdsIncluded;
    });

    setIsAddedList(addedEventSponsors);
    setCheckboxStates(eventSponsorCheckboxStates);

    const assignedEventData: any = Object.keys(addedEventSponsors)?.map((eventSponsorId: string) => {
      if (addedEventSponsors?.[eventSponsorId]) {
        return { id: eventSponsorId, onlyMyTargetAccount: eventSponsorCheckboxStates[eventSponsorId] || false };
      }
      return false;
    })?.filter(Boolean);

    setAssignedEventIds(assignedEventData);
  }, [selected, events]);

  const handleCheckboxChange = (eventSponsorId: string, isChecked: boolean) => {
    setCheckboxStates(prev => ({
      ...prev,
      [eventSponsorId]: isChecked,
    }));

    setAssignedEventIds(prev => {
      const updated = prev.map(item => (item.id === eventSponsorId
        ? { ...item, onlyMyTargetAccount: isChecked }
        : item));

      if (!updated.find(item => item.id === eventSponsorId)) {
        return [...updated, { id: eventSponsorId, onlyMyTargetAccount: isChecked }];
      }

      return updated;
    });
  };

  const handleAssignEvent = (event: any) => {
    setIsAddedList((prev) => ({ ...prev, [event?.id]: !prev[event?.id] }));

    setAssignedEventIds((prevIds) => {
      if (prevIds.some(eventItem => eventItem.id === event?.id)) {
        return prevIds.filter((eventItem) => eventItem.id !== event?.id);
      } else {
        return [...prevIds, { id: event?.id, onlyMyTargetAccount: checkboxStates[event?.id] || false }];
      }
    });
  };

  useEffect(() => {
    const uniqueAssignedEventIds = assignedEventIds.filter((event, index, self) => self.findIndex(e => e.id === event.id) === index);
    onAssignChange(uniqueAssignedEventIds);
  }, [assignedEventIds, onAssignChange]);

  const handleAssignClick = (event: any) => {
    handleAssignEvent(event);
  };

  const filteredEvents = events.filter(event => event?.event?.name?.toLowerCase().includes(search.toLowerCase()));

  const darkenColor = (rgbArray: number[], factor = 0.6) => {
    const [r, g, b] = rgbArray || [255, 255, 255];
    const darken = (value: number) => Math.max(0, Math.floor(value * factor));
    return `rgb(${darken(r)}, ${darken(g)}, ${darken(b)})`;
  };

  return (
    <>
      {filteredEvents.map((event) => (
        <div
          key={event?.eventId}
          className="flex items-center justify-between py-[10px] border-b-1 border-[#CDDCF2] gap-[10px] mx-2 cursor-pointer"
        >
          {event?.event?.logo ? (
            <div
              className={classNames('w-[49px] h-[49px] flex items-center justify-center p-[1px] bg-[#f4f6f8] bg-center rounded-[5px] ')}
              style={{
                backgroundColor: event?.event?.logoDominantColor
                  ? darkenColor(event?.company?.logoDominantColor)
                  : '#90959a',
              }}
            >
              <Logo
                alt="avatar"
                className="w-[49px] h-[49px] min-w-[49px] min-h-[49px] object-contain bg-center rounded-[5px]"
                src={minifiedImage(event?.event?.logo)}
                defaultLogo="company"
              />
            </div>
          )
            : (
              <div className={classNames('w-[49px] h-[49px] flex items-center justify-center p-[1px] bg-[#edf2f5] bg-center rounded-[5px]')}>
                {' '}
                <Logo
                  alt="avatar"
                  className="w-[49px] h-[49px] min-w-[49px] min-h-[49px] object-contain bg-center rounded-[5px]"
                  src={minifiedImage(event?.event?.logo)}
                  defaultLogo="company"
                />
              </div>
            )}

          <div className="w-full max-w-[420px] tracking-wide truncate">
            <div className="font-semibold !text-[#172746] leading-[20px] mb-[2px] max-w-[320px] overflow-hidden text-ellipsis">
              {event?.event?.name}
            </div>
            <div className="text-[12px] font-normal text-[#203C6E] leading-[16px] line-clamp-1">
              {event?.event?.startTime && format(parseISO(event?.event?.startTime), 'dd MMM yyyy • hh:mm a')}
            </div>
            <div className="text-[12px] font-normal text-[#172746] leading-[16px] line-clamp-1 capitalize">
              {event?.event?.location}
            </div>
            <div className="text-[12px] font-normal text-[#172746] leading-[16px] line-clamp-1 capitalize">
              {event?.event?.format === 'live' ? 'In-Person' : event?.event?.format}
            </div>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={checkboxStates[event.id] || false}
                  onChange={(e) => handleCheckboxChange(event.id, e.target.checked)}
                  disabled={!isAddedList[event.id]}
                  sx={{
                    '&.Mui-checked': {
                      color: '#0049EF',
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 16,
                    },
                    '&.MuiCheckbox-root': {
                      marginLeft: '10px !important',
                      pr: '5px',
                      pl: '0px',
                      py: '0px',
                    }
                  }}
                />
                )}
              label="Only My Target Account"
              sx={{
                '& .MuiFormControlLabel-root': {
                  marginTop: '-5px !important',
                  marginLeft: '-17px !important'
                },
                '& .MuiFormControlLabel-label': {
                  color: '#172746',
                  fontSize: '12px',
                  lineHeight: '18px',
                  fontFamily: 'Poppins-400',
                },
              }}
            />
          </div>

          <button
            type="button"
            className={classNames(
              'text-sm font-semibold px-[35px] py-[7px] rounded-[5px]',
              isAddedList[event.id] ? 'bg-[#0049EF] text-white' : 'bg-[#EDF2F5] hover:bg-[#edf2f5b4] text-black'
            )}
            onClick={() => handleAssignClick(event)}
          >
            {isAddedList[event.id] ? 'Unassign' : 'Assign'}
          </button>
        </div>
      ))}
    </>
  );
};

export default AssignList;
