/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import classNames from 'classnames';
import { EventWebsiteTemplateType } from 'src/mui/types/survey';
import { IEvent } from 'src/models/event/event';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { Stack } from '@mui/material';
import { AvatarDefaultSmallSvgIcon } from 'src/shared-components/V2/IconSvg/AvatarDefaultSmallSvgIcon/AvatarDefaultSmallSvgIcon';
import { IEventSessionSpeaker } from '../../../models/event/event-speaker';
import { Avatar } from '../../../shared-components/V2';
import { getBrandingColors } from '../../../mui/pages/event/live/liveEventStyling';

interface IWebsiteEventSpeakerList {
  speakers: IEventSessionSpeaker[];
  roomQty?: number;
  isLive?: boolean;
  isOpen?: boolean;
}

const WebsiteEventSpeakerList = ({
  speakers, roomQty, isOpen, isLive
}: IWebsiteEventSpeakerList) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const colors = getBrandingColors();
  return (
    <div
      className={
      classNames(
        {
          '!p-0 mb-[15px]': roomQty === 2,
          'max-w-[215px] min-w-[215px]': isOpen,
          'max-w-[100%]': isLive,
        }
      )
    }
    >
      {
      (!!speakers && speakers?.length && !isLive)
        ? (
          <div
            className={classNames('text-[14px] font-medium h-[20px] mb-2.5', {
              'text-white': isLive,
              'text-[#0B1221]': !isLive,
              'text-[#FFFFFF]':
            event?.websiteTemplate
            === EventWebsiteTemplateType.AFTER_HOURS,
            })}
          >
            Speakers
          </div>
        ) : null
    }
      {!!speakers && speakers?.map((speaker: any) => {
        const speakerName = `${speaker.firstName} ${speaker.lastName}`;

        return (
          <div
            className={classNames(
              'flex mb-2.5 last:mb-0',
              {
                'mb-[5px] last:mb-0': isLive,
                'mb-2.5 last:mb-0': !isLive,
              }
            )}
          >
            <div className="w-full mx-auto rounded-10">
              <div
                className={
                classNames(
                  'mr-10 text-left flex ',
                  {
                    'mr-0': isOpen
                  }
                )
              }
              >
                {isLive ? (!speaker.photo ? <Avatar src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${speaker.photo}`} size="MEDIUM" className="!rounded-[20px] !bg-[#edf2f5]" /> : (
                  <Stack sx={{
                    width: 49,
                    height: 49,
                    borderRadius: '20px',
                    backgroundColor: 'transparent !important',
                    maxWidth: 49,
                    maxHeight: 49,
                    border: `1px solid ${colors?.Secondary || '#000000'}`,
                  }}
                  >
                    <AvatarDefaultSmallSvgIcon fillColor={colors?.Text} style={{ width: '49px', height: '49px' }} />
                  </Stack>
                )) : (<Avatar src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${speaker.photo}`} size="MEDIUM" className="!rounded-[20px] !bg-[#edf2f5]" />)}
                <div
                  className={classNames(
                    'flex flex-col justify-center',
                    {
                      'pl-[5px]': isLive,
                      'pl-[10px]': !isLive,
                    }
                  )}

                >
                  <div
                    className={classNames('leading-[16px] font-semibold line-clamp-1', {
                      'text-white text-[12px]': isLive,
                      'text-[#0B1221] text-[13px]': !isLive,
                    })}
                    style={{
                      color: getBrandingColors()?.Text || 'ffffff',
                    }}
                  >
                    {speakerName}
                  </div>
                  <div
                    className={classNames(' leading-[13px] font-normal line-clamp-1', {
                      'text-white text-[12px]': isLive,
                      'text-[#0B1221] text-[11px]': !isLive,
                    })}
                    style={{
                      color: getBrandingColors()?.Text || 'ffffff',
                    }}
                  >
                    {speaker.jobTitle}
                  </div>
                  <div
                    className={classNames('leading-[13px] font-normal line-clamp-1', {
                      'text-white text-[12px] ': isLive,
                      'text-[#0B1221] text-[11px] ': !isLive,
                    })}
                    style={{
                      color: getBrandingColors()?.Text || 'ffffff',
                    }}
                  >
                    {speaker.company?.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WebsiteEventSpeakerList;
