import React from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  FormHelperText,
  RadioGroupProps,
  FormControlLabel,
} from '@mui/material';

// ----------------------------------------------------------------------

type Props = RadioGroupProps & {
  name: string;
  options: { label: string; value: any }[];
  label?: string;
  spacing?: number;
  helperText?: React.ReactNode;
  handleChange?: (value: string) => void,
  hideHelperText?: boolean;
  isRadioArray?:boolean
};

function PRadioGroup({
  row,
  name,
  label,
  options,
  spacing,
  helperText,
  handleChange,
  hideHelperText,
  isRadioArray,
  ...other
}: Props)  {
  const { control } = useFormContext();

  const labelledby = label ? `${name}-${label}` : '';
const MuiFormControlLabel =  {  "& .MuiFormControlLabel-root": {
  ml: "-6px !important",
  mr: "0px !important",
  flexDirection: "column-reverse",
  alignItems:  "center",
  justifyContent: "space-between",

}
}
  return (
    <Controller
      name={name}
      control={control}

      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" id={labelledby} sx={{ typography: 'body2' }}>
              {label} 
            </FormLabel>
          )}

          <RadioGroup  {...field}  aria-labelledby={labelledby} row={row} {...other} onChange={(e) => {
            if (handleChange) handleChange(e.target.value)
            field.onChange(e);
          }}>
            {options.map((option,index) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                onChange={(_, newValue) => {
                  field.onChange(newValue)
                }}
                control={<Radio />}
                label={option.label}
                sx={{
                  // background:"red",
                  ...(isRadioArray && {
                    ml: "-6px !important",
                    mr: "0px !important",
                    flexDirection: "column-reverse",
                    alignItems: 
                      index === 0 ? "center" : 
                      index === options.length - 1 ? "center" : 
                      "center",
                    justifyContent: "space-between",
                    "& .MuiFormControlLabel-label":{
                      textAlign:  index === 0 ? "center !important" : 
                      index === options.length - 1 ? "center !important" : 
                      "center !important",
                      // background:"red"
                    },
                    "& .MuiRadio-root":{
                      p: "5px !important",
                      pb: "3px !important",
                      // pl:  index === 0 ? "0px !important" :"5px !important",
                      // pr:  index === options.length - 1 ? "0px !important" :"5px !important",
                      
                    },
                  }),
                 
                  '&:not(:last-of-type)': {
                    mb: spacing || 0,
                  },
                  ...(row && {
                    mr: 0,
                    '&:not(:last-of-type)': {
                      mr: spacing || 2,
                    },
                  }),

          

                }}
              />
            ))}
          </RadioGroup>

          {(!!error || helperText) && !hideHelperText && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default React.memo(PRadioGroup);
