import { Reducer } from 'redux';

import { IPost } from '../../../models/post/post';
import { allStoriesActions } from './actions';
import {StoryActionTypes } from './types';

type Nullable<T> = T | null;


export interface AllStoryState {
  value: Nullable<any> | [];
  loading: boolean;
  errorMessage: string;
}

const initialState: AllStoryState = {
  value: null,
  loading: false,
  errorMessage: '',
};

export const AllStoriesReducer: Reducer<AllStoryState, allStoriesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case StoryActionTypes.ALL_STORY_LOADING: {
      return {
        ...state,
        loading: state.loading,
      };
    }
    case StoryActionTypes.ALL_STORY_SUCCESS: {
      return {
        ...state,
        value: action.payload,
      };
    }

    case StoryActionTypes.ALL_STORY_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null,
      };
    }

    default:
      return state;
  }
};
