import { IEvent } from '../../../../models/event/event';
import { IAttendee } from '../../../../models/events-live/attendee';
import { Box, Stack } from '@mui/material';
import { getBrandingColors } from './liveEventStyling';
import { CardHeader } from './card-header';
import ChatView from './chat-view';
import { IUserProfile } from '../../../../models/user-profile';

export function LiveChatComponent(props: {
  gridColumn: string,
  gridRow: string,
  moduleDesignLines: boolean,
  height: string,
  boxStyles: { backgroundColor: string; pb: string; overflow: string; borderRadius: string; px: string; py: string; position: string },
  onClick: () => void,
  event: IEvent | undefined,
  attendees: IAttendee[],
  user: IUserProfile,
  strings: string[],
  columnCardHeight: string,
  totalParticipantsCount: number
}) {
  return <Box sx={{
    gridColumn: props.gridColumn,
    gridRow: props.gridRow,
  }}>
    <Stack
      sx={{
        height: props.height, ...props.boxStyles,
        overflow: 'hidden'
      }}
    >
      <CardHeader title="Chat" onClick={props.onClick}/>
      <ChatView
        event={props.event}
        attendees={props.attendees}
        rooms={props.event?.rooms!}
        user={props.user}
        id={props.event?.id!}
        isGridView={props.strings?.length > 3}
        columnCardHeight={props.columnCardHeight}
        totalParticipantsCount={props.totalParticipantsCount}
      />
    </Stack>
  </Box>;
}
