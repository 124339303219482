const CommunityList = () => (
  <div className="bg-gray-0 w-full">
    <div className="block w-9/12 mx-auto">
      <div className="mt-3 text-gray-1 table-of-contents text-lg5">Table of Contents</div>
      <div className="mt-3 font-normal text-md2 tracking-wide">
        <div className="">
          1.
          <a href="#community-guidelines" className="hover:underline text-blue-1 ml-2">
            Community Guidelines
          </a>
        </div>
        <div className="mt-2">
          2.
          <a href="#berespectful" className="hover:underline text-blue-1 ml-2">
            Respect
          </a>
        </div>
        <div className="mt-2">
          3.
          <a href="#behonest" className="hover:underline text-blue-1 ml-2">
            Honesty
          </a>
        </div>
        <div className="mt-2">
          4.
          <a href="#beinclusive" className="hover:underline text-blue-1 ml-2">
            Diversity
          </a>
        </div>
        <div className="mt-2">
          5.
          <a href="#tell-us-if-something-wrong" className="hover:underline text-blue-1 ml-2">
            Tell us if you see something wrong
          </a>
        </div>
        <div className="mt-2">
          6.
          <a href="#abusing-our-community-guidelines" className="hover:underline text-blue-1 ml-2">
            Abusing our community guidelines can result in action against your content or account
          </a>
        </div>
        <div className="mt-2">
          7.
          <a href="#abusing-our-community-guidelines" className="hover:underline text-blue-1 ml-2">
            Respect the legal rights of other community members
          </a>
        </div>
        <div className="mt-2">
          8.
          <a href="#respect-panelist-rights" className="hover:underline text-blue-1 ml-2">
            Respect Panelist’s rights
          </a>
        </div>

      </div>
    </div>
  </div>
);

export default CommunityList;
