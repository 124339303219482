import { sub } from "date-fns";
import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
// @mui
import Stack from "@mui/material/Stack";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Picker from "emoji-picker-react";
import Popover from "@mui/material/Popover";
// routes
import { useRouter } from "src/mui/routers/hook";
import deleteIcon from "src/assets/images/delete-icon.svg";
import documentIcon from "src/assets/images/attached-document.svg";
// hooks

import { useMockedUser } from "src/mui/hooks/use-mocked-user";
// utils
import uuidv4 from "src/mui/utils/uuidv4";
// components
import Iconify from "../iconify/iconify";
// types
import { IChatParticipant } from "src/mui/types/chat";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "src/store/fileupload";
import { IAppState } from "src/store";
import { EmogiSvgIcon } from "src/mui/assets/icons/EmogiSvgIcon";
import { UploadImageSvgIcon } from "src/mui/assets/icons/UploadImageSvgIcon";
import Avatar from "@mui/material/Avatar";
import { getS3Path } from "src/mui/utils/image";
import { Box, TextareaAutosize } from "@mui/material";
import { useCurrentUser, useUserDetails } from "src/utils/hooks";
import MinimizeIcon from "src/mui/pages/audience/MinimizeIcon";
import { minifiedImage } from "src/utils";
import { getBrandingColors } from '../../pages/event/live/liveEventStyling';
import styled from "styled-components";

// ----------------------------------------------------------------------

type Props = {
  recipients: IChatParticipant[];
  onAddRecipients: (recipients: IChatParticipant[]) => void;
  onSendMessage: (
    _sender: string,
    message: string,
    fileUrl: string,
    fileType: string
  ) => void;
  disabled: boolean;
  selectedConversationId: string;
  activeUserAvatar?: any;
  repliedMessage?: any;
  editMessage?: any;
  onCancelReplyMessage?: () => void;
};

export default function ChatMessageInput({
  recipients,
  onAddRecipients,
  onSendMessage,
  disabled,
  selectedConversationId,
  activeUserAvatar,
  repliedMessage,
  editMessage,
  onCancelReplyMessage,
}: Props) {
  const router = useRouter();
  const colors = getBrandingColors();

  const [selectedFile, setSelectedFile] = useState("");
  const [showEmoji, setShowEmoji] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { data: file, isUploading } = useSelector(
    (state: IAppState) => state.fileUpload
  );
  const userCurrent = useCurrentUser();

  const { avatar } = useUserDetails(userCurrent);

  useEffect(() => {
    if (file?.key || (file as any)?.Key){
      setSelectedFile((file as any)?.Key);
    }
  }, [(file as any)?.Key]);

  const [selectedFileType, setSelectedFileType] = useState("");
  const dispatch = useDispatch();
  const {
    fileUpload,
    // liveEvent
  } = useSelector((state: IAppState) => state);

  const { user } = useMockedUser();

  const fileRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (editMessage?.message) {
      if (editMessage?.source) {
        setSelectedFile(editMessage?.source);
        setSelectedFileType(editMessage?.type);
      }
      setMessage(editMessage?.message);
    }
  }, [editMessage?.message]);

  const myContact = useMemo(
    () => ({
      id: user.id,
      role: user.role,
      email: user.email,
      address: user.address,
      name: user.displayName,
      lastActivity: new Date(),
      avatarUrl: user.photoURL,
      phoneNumber: user.phoneNumber,
      status: "online" as "online" | "offline" | "alway" | "busy",
    }),
    [user]
  );

  const messageData = useMemo(
    () => ({
      id: uuidv4(),
      attachments: [],
      body: message,
      contentType: "text",
      createdAt: sub(new Date(), { minutes: 1 }),
      senderId: myContact.id,
    }),
    [message, myContact.id]
  );

  const conversationData = useMemo(
    () => ({
      id: uuidv4(),
      messages: [messageData],
      participants: [...recipients, myContact],
      type: recipients.length > 1 ? "GROUP" : "ONE_TO_ONE",
      unreadCount: 0,
    }),
    [messageData, myContact, recipients]
  );

  const open = Boolean(showEmoji);
  const id = open ? "simple-popover" : undefined;

  const handleOpenEmoji = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowEmoji(event.currentTarget);
  };

  const handleCloseEmoji = () => {
    setShowEmoji(null);
    inputRef.current?.focus();
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    setMessage(message + emojiObject.emoji);
  };

  const handleAttach = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }, []);

  const handleFileSelected = (files: FileList) => {
    const data = new FormData();
    data.append("file", files[0]);
    const fileType = files[0]?.type?.split("/")[0];

    if (fileType === "image") setSelectedFileType("image");
    else setSelectedFileType("document");

    dispatch(uploadFile(data, fileType === "image" ? "image" : "document"));
    if (!fileUpload?.isUploading && (file as any)?.Key) {
      setSelectedFile(getS3Path((file as any)?.Key));
    }
    inputRef.current?.focus();
  };

  const handleChangeMessage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setMessage(event.target.value);
    },
    []
  );

  // const handleSendMessage = useCallback(
  //   async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //     try {
  //       if (event.key === "Enter") {
  //         if (message || selectedFile !== "") {
  //           if (selectedConversationId) {
  //             onSendMessage(
  //               selectedConversationId,
  //               message,
  //               selectedFileType && selectedFile ? selectedFile : "",
  //               selectedFileType && selectedFile ? selectedFileType : "text"
  //             );
  //           }
  //         }
  //         setMessage("");
  //         setSelectedFile("");
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   },
  //   [
  //     conversationData,
  //     message,
  //     messageData,
  //     onAddRecipients,
  //     router,
  //     selectedConversationId,
  //   ]
  // );

  const handleSendMessage = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      try {
        if (event.key === "Enter" && !event.shiftKey) {
          event.preventDefault(); // Prevents new line on Enter
  
          const trimmedMessage = message.trim(); // Trim spaces
          const hasFile = selectedFile !== "";
  
          if ((trimmedMessage || hasFile) && selectedConversationId) {
            onSendMessage(
              selectedConversationId,
              trimmedMessage,
              selectedFileType && hasFile ? selectedFile : "",
              selectedFileType && hasFile ? selectedFileType : "text"
            );
  
            // Clear only if a message or file was sent
            setMessage("");
            setSelectedFile("");
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      message, 
      selectedFile, 
      selectedFileType, 
      selectedConversationId, 
      onSendMessage
    ]
  );
  
  return (
    <Stack sx={{ flexDirection: "column", columnGap: "5px" }}>
      {repliedMessage && (
        <>
          <Stack
            sx={{
              fontSize: "13px",
              fontFamily: "Poppins-400",
              lineHeight: "18px",
              color: colors?.Text || "#ffffff",
              p: "5px",
              minWidth: 48,
              maxWidth: 250,
              borderRadius: "5px 5px 5px 0px",
              typography: "body2",
              bgcolor: "#626262",
              position: "relative",
              left: "50px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {repliedMessage?.type === "document" ? (
                <a href={message}>
                  <div className="p-2">
                    <div className="flex flex-row rounded-20 p-2 w-[167px]">
                      <div>
                        <img
                          src={documentIcon}
                          alt="attachment"
                          className="w-16 h-16"
                        />
                      </div>
                      <div className="text-sm font-light p-2">
                        {message.substring(message.lastIndexOf("/") + 1)}
                      </div>
                    </div>
                  </div>
                </a>
              ) : (
                repliedMessage?.message.replace(/<\/?[^>]+(>|$)/g, "")
              )}
              <IconButton onClick={onCancelReplyMessage} sx={{ p: 0 }}>
                <MinimizeIcon />
              </IconButton>
            </Box>
            {repliedMessage?.source && repliedMessage?.type !== "text" && (
              <Box
                component="img"
                alt="attachment"
                src={minifiedImage(repliedMessage?.source)}
                mt={1}
                sx={{
                  height: 100,
                  borderRadius: 1.5,
                  cursor: "pointer",
                  objectFit: "cover",
                  "&:hover": {
                    opacity: 0.9,
                  },
                }}
              />
            )}
          </Stack>
        </>
      )}
      {editMessage && (
        <>
          <Stack
            sx={{
              fontSize: "13px",
              flexDirection: "row",
              justifyContent: "space-between",
              fontFamily: "Poppins-400",
              lineHeight: "18px",
              color: colors?.Text || "#ffffff",
              p: "5px",
              minWidth: 48,
              maxWidth: 250,
              borderRadius: "5px 5px 5px 0px",
              typography: "body2",
              bgcolor: "#626262",
              position: "relative",
              left: "50px",
            }}
          >
            <div className="font-light">Edit Message</div>
            <IconButton
              onClick={() => {
                setMessage("");
                setSelectedFile("");
                setSelectedFileType("");
                if (onCancelReplyMessage) onCancelReplyMessage();
              }}
              sx={{ p: 0 }}
            >
              <MinimizeIcon />
            </IconButton>
          </Stack>
        </>
      )}
      <Stack sx={{ flexDirection: "row", columnGap: "5px" }}>
        <Avatar
          alt={activeUserAvatar?.firstName}
          src={avatar!}
          sx={{ width: 36, height: 36, borderRadius: "14px" }}
        />
        {/* <InputBase
          value={message}
          autoFocus
          onKeyUp={handleSendMessage}
          onChange={handleChangeMessage}
          inputRef={inputRef}
          placeholder="Type here…"
          disabled={disabled || isUploading}
          endAdornment={
            <Stack direction="row" sx={{ flexShrink: 0 }}>
              <IconButton
                sx={{ p: "1px", width: "21px", height: "21px", mr: "5px" }}
                onClick={handleOpenEmoji}
                aria-describedby={id}
              >
                <EmogiSvgIcon />
              </IconButton>
              <IconButton
                sx={{ p: "1px", width: "21px", height: "21px" }}
                onClick={handleAttach}
              >
                <UploadImageSvgIcon />
              </IconButton>
            </Stack>
          }
          sx={{
            height: 36,
            width: "calc(100% - 41px)",
            flexShrink: 0,
            backgroundColor: colors?.Secondary || "#2F2F2F",
            borderRadius: "5px",
            px: "10px",
            "& .MuiInputBase-input": {
              fontSize: "12px",
              fontFamily: "Poppins-400",
              color: colors?.Text || "#ffffff",
            },
            "& .MuiInputBase-input::placeholder": {
              color: colors?.Text || "#ffffff",
            },
          }}
        /> */}
        <Stack sx={{width: '100%', position: 'relative'}}>
        <Box
          sx={{
            width: "calc(100% - 0px)",
            backgroundColor: colors?.Secondary || "#2F2F2F",
            borderRadius: "5px",
            padding: "10px 10px 3px",
            fontSize: "12px",
            fontFamily: "Poppins-400",
            color: colors?.Text || "#ffffff",
            border: "none",
            overflowY: "auto",
            outline: "none",
            "& textarea::placeholder": { 
              color: colors?.Text || "#ffffff", // Change placeholder color
              opacity: 1 
            }
          }}
        >
          <TextareaAutosize
            value={message}
            onChange={handleChangeMessage}
            onKeyUp={handleSendMessage}
            minRows={1}
            maxRows={10}
            placeholder="Type here…"
            disabled={disabled || isUploading}
            style={{
              width: "100%",
              resize: "none",
              backgroundColor: "transparent", // Make background transparent so Box handles it
              border: "none",
              outline: "none",
              paddingRight: "62px"
            }}
          />
        </Box>
          <Stack
            direction="row"
            sx={{
              flexShrink: 0,
              position: "absolute",
              bottom: 8, // Adjust to align with the text area
              right: 10,
            }}
          >
            <IconButton
              sx={{ p: "1px", width: "21px", height: "21px", mr: "5px" }}
              onClick={handleOpenEmoji}
              aria-describedby={id}
            >
              <EmogiSvgIcon />
            </IconButton>
            <IconButton
              sx={{ p: "1px", width: "21px", height: "21px" }}
              onClick={handleAttach}
            >
              <UploadImageSvgIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Popover
        id={"emoji-picker"}
        open={open}
        anchorEl={showEmoji}
        onClose={handleCloseEmoji}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Picker
          pickerStyle={{
            zIndex: "1000",
          }}
          onEmojiClick={onEmojiClick}
        />
      </Popover>
      <input
        type="file"
        ref={fileRef}
        onChange={(e) => {
          handleFileSelected(e.target?.files!);
          e.target.value = "";
        }}
        style={{ display: "none" }}
      />

      {selectedFile !== "" && selectedFileType === "image" && (
        <Stack
          sx={{
            ml: "42px",
            mt: "-4px",
            backgroundColor: colors?.Secondary || "#2F2F2F",
            borderRadius: "0px 0px 5px 5px",
            p: 1,
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Stack sx={{ height: "100px", borderRadius: "5px 5px 5px 5px" }}>
              <img
                src={minifiedImage(selectedFile)}
                alt=""
                style={{ borderRadius: "5px" }}
                className="object-contain h-[100px]"
              />
            </Stack>
            <Stack>
              <IconButton
                onClick={() => setSelectedFile("")}
                sx={{ p: 0, ml: 1 }}
              >
                <MinimizeIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      )}
      {selectedFile !== "" && selectedFileType === "document" && (
        <Stack
          sx={{
            ml: "43px",
            mt: "-4px",
            backgroundColor: colors?.Secondary || "#2F2F2F",
            borderRadius: "0px 0px 5px 5px",
            p: 1,
          }}
        >
          <a href={minifiedImage(selectedFile)}>
            <div className="p-2">
              <div className="flex flex-row rounded-20 p-2 w-[167px]">
                <div className="testingImage">
                  <img
                    src={documentIcon}
                    alt="attachment"
                    className="w-16 h-16 testingImageAttachment"
                  />
                </div>
                <div className="text-sm font-light p-2">
                  {selectedFile.substring(selectedFile.lastIndexOf("/") + 1)}
                </div>
              </div>
            </div>
          </a>
        </Stack>
      )}
    </Stack>
  );
}
