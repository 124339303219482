
import { ReactComponent as CloseIcon } from '../../../../assets/images/modal-close-icon.svg';
import NotificationItem from './notification-item';
import noNotification from '../../../../assets/images/nav-menu/no-notification.svg';


type Props = {
  notifications: any;
}

const NotificationsMenu = ({ notifications }: Props) => {
  // const notifications = useSelector((state: IAppState) => state.notifications);

  return (
    <div className="bg-white">
      <div className="flex flex-col">
        <div className="text-md15 text-blue-3 w-full">
          <p className="text-blue-2 font-medium tracking-wider text-md2 ml-3 pl-1 mt-3">
            Notifications
          </p>
          <div
            // eslint-disable-next-line max-len
            className="absolute right-[20px] top-[10px] text-white text-[11px] font-medium bg-[#edf2f5] bg-opacity-80 w-[25px] h-[25px] rounded-full cursor-pointer md:hidden flex items-center justify-center"
          >
            <div>
              <span className="cursor-pointer">
                {' '}
                <CloseIcon />
                {' '}
              </span>
            </div>
          </div>
          <div className="pt-2 md:max-h-full max-h-[calc(100vh-37px)] overflow-y-scroll" id="scroll">
            {notifications &&
              notifications?.data.map((notification: any) => (
                <NotificationItem notification={notification} key={notification.id} />
              ))}
            {(notifications?.data?.length === 0 || notifications === undefined) && (
              // <div className="w-full">You don't have any messages yet</div>
              <div className="flex flex-col justify-center mx-auto w-full">
                <div className="flex justify-center">
                  <div className="text-md text-blue-4 m-5 w-10/12 text-center">
                    You don’t have any network requests for now!
                  </div>
                </div>
                <div className="h-[247px] mx-auto">
                  <img
                    src={noNotification}
                    className="w-[205px]"
                    alt="No network request available"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsMenu;
