import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { ChimeState } from './types';

export type { ChimeState } from './types';

const initialState: ChimeState = {
  chimeInvitation: undefined,
  guestCall: undefined,
  expoCall: undefined,
  error: undefined,
  callEnded: undefined
};

// ###########################################################################################
// NOTE: This file gets WebSocket events from Server and updates the redux state accordingly
// All the UI dispatch interaction is handled in action.ts file. 
// ###########################################################################################

// Reducers
export const expoSlice = createSlice({
  name: 'chime',
  initialState,
  reducers: {
    setChimeInvitation: (state: ChimeState, action: PayloadAction<any>) => {
      state.chimeInvitation = action.payload;
    },
    setExpoCall: (state: any, action: PayloadAction<any>) => {
      // Ignore call ifuser is already in a call. 
      if (state.expoCall) return state;

      state.expoCall = action.payload;
    },
    unsetExpoCall: () => {
      return {
        ...initialState
      };
    },
    setGuestRinging: (state: ChimeState, action: PayloadAction<any>) => {
      state.guestCall = action.payload;
    },
    setNoManager: (state: ChimeState, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setCancelCall: (state: ChimeState, action: PayloadAction<any>) => {
      if (state.expoCall?.callId === action.payload.callId) {
        return {
          ...initialState
        };
      }
    },
    setCallEnded: (state: ChimeState, action: PayloadAction<any>) => {
      if (state.chimeInvitation?.callId === action.payload.callId) {
        return {
          ...initialState
        };
      }
    },
    expoGuestCalling: () => { },
    expoGuestCancel: () => { },
    expoManagerAccept: () => { },
    expoManagerReject: () => { },
    expoEndMeeting: () => { },
  }
});

const { setChimeInvitation,
  setExpoCall,
  unsetExpoCall,
  setGuestRinging,
  setNoManager,
  setCancelCall,
  setCallEnded,
  expoGuestCalling,
  expoEndMeeting,
  expoGuestCancel,
  expoManagerAccept,
  expoManagerReject
} = expoSlice.actions;

// Read message and store Date into Redux states
export const handleExpoMessage = (message: any) => async (dispatch: Dispatch) => {
  if (message.action === 'chime-invitation') dispatch(setChimeInvitation(message?.data));
  if (message.action === 'expo-call') dispatch(setExpoCall(message?.data));
  if (message.action === 'guest-ringing') dispatch(setGuestRinging(message?.data));
  if (message.action === 'no-managers') dispatch(setNoManager(message?.data));
  if (message.action === 'call-cancel') dispatch(setCancelCall(message?.data));
  if (message.action === 'call-ended') dispatch(setCallEnded(message?.data));
};


