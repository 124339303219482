export interface ArrowUpSvgIconProps {
  fillColor?: string;
}
export const ArrowUpSvgIcon = ({ fillColor }: ArrowUpSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#203c6e";
  return (
    <svg
      id="Component_149_3029"
      data-name="Component 149 – 3029"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="8.471"
      viewBox="0 0 16 8.471"
    >
      <path
        id="Polygon_9"
        data-name="Polygon 9"
        d="M7.273.77a1,1,0,0,1,1.454,0l5.68,6.014a1,1,0,0,1-.727,1.687H2.32a1,1,0,0,1-.727-1.687Z"
        transform="translate(0)"
        fill={iconColor}
      />
    </svg>
  );
};
