import { SVGProps } from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
}

function MeetingDynamicSvgIcon({ fillColor, fillBgColor, ...props }: Props) {
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
      <g id="Group_21319" data-name="Group 21319" transform="translate(-282.073 -108.975)">
        <g id="Group_20607" data-name="Group 20607" transform="translate(282.073 113.823)">
          <path id="Path_14882" data-name="Path 14882" d="M16.449,15.036,15,13.587V11h.807v2.252l1.211,1.215Z" transform="translate(-10.561 -8.173)" fill={defaultColor}/>
          <g id="Group_20606" data-name="Group 20606" transform="translate(0 0)">
            <path id="Path_14881" data-name="Path 14881" d="M9.439,14.878a4.439,4.439,0,1,1,4.439-4.439A4.439,4.439,0,0,1,9.439,14.878Zm0-8.071a3.632,3.632,0,1,0,3.632,3.632A3.632,3.632,0,0,0,9.439,6.807Z" transform="translate(-4.596 -5.19)" fill={defaultColor}/>
            <rect id="Rectangle_8191" data-name="Rectangle 8191" width="2.046" height="0.807" transform="translate(0 1.452) rotate(-45.06)" fill={defaultColor}/>
            <rect id="Rectangle_8192" data-name="Rectangle 8192" width="0.807" height="2.046" transform="translate(7.667 0.57) rotate(-44.94)" fill={defaultColor}/>
          </g>
        </g>
        <g id="Group_20608" data-name="Group 20608" transform="translate(291.68 108.975)">
          <path id="Path_14744" data-name="Path 14744" d="M10.957,0A4.754,4.754,0,1,1,6.2,4.754,4.754,4.754,0,0,1,10.957,0" transform="translate(-6.203 0)" fill={defaultColor}/>
          <path id="Path_14745" data-name="Path 14745" d="M7.078,17.157a.408.408,0,0,0,.332-.178,3.775,3.775,0,0,1,6.148,0,.41.41,0,0,0,.336.179h.891a.414.414,0,0,0,.412-.419.406.406,0,0,0-.051-.2,5.331,5.331,0,0,0-9.33.008.413.413,0,0,0,.364.606Z" transform="translate(-5.769 -2.675)" fill={defaultColor}/>
        </g>
      </g>
    </svg>
    

    );
}

export default MeetingDynamicSvgIcon;