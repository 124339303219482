import React from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface IAudienceSvgIcon {
  isActive?: boolean;
}

export const AudienceSvgIconActive = ({ isActive }: IAudienceSvgIcon) => {
    const colors = getBrandingColors();
    const defaultColor = '#66686c';
    const iconColor = isActive ? (colors?.Accent || '#03FFCD') : (colors?.Accent || defaultColor);

    return isActive ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 24 24"
            width="25"
            height="24"
        >
            <path
                d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm6.5 11h-13a1 1 0 0 1 -1-1v-.5a7.5 7.5 0 0 1 15 0v.5a1 1 0 0 1 -1 1zm3.5-15a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm-1.421 2.021a6.825 6.825 0 0 0 -4.67 2.831 9.537 9.537 0 0 1 4.914 5.148h6.677a1 1 0 0 0 1-1v-.038a7.008 7.008 0 0 0 -7.921-6.941z"
                fill={iconColor}
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.2" height="24.2" viewBox="0 0 24.2 24.2">
  <path  fill={iconColor} id="Union_2" data-name="Union 2" d="M13,23.1v-.5a5.4,5.4,0,1,0-10.8,0v.5a1.1,1.1,0,1,1-2.2,0v-.5a7.6,7.6,0,1,1,15.2,0v.5a1.1,1.1,0,1,1-2.2,0Zm9-5a4.9,4.9,0,0,0-8.167-3.652,1.1,1.1,0,1,1-1.467-1.639A7.1,7.1,0,0,1,24.2,18.1a1.1,1.1,0,0,1-2.2,0ZM3,8.6a4.6,4.6,0,1,1,4.6,4.6A4.6,4.6,0,0,1,3,8.6Zm2.2,0A2.4,2.4,0,1,0,7.6,6.2,2.4,2.4,0,0,0,5.2,8.6Zm7.8-4a4.6,4.6,0,1,1,4.6,4.6A4.6,4.6,0,0,1,13,4.6Zm2.2,0a2.4,2.4,0,1,0,2.4-2.4,2.4,2.4,0,0,0-2.4,2.4Z" />
</svg>

    );
};


