import { Reducer } from "redux";
import {
  current
} from '@reduxjs/toolkit'
import { IForgotPasswordState } from "../../../models/user/forgotpassword/forgotpasswordState";
import { ForgotPasswordActions } from "./actions";
import { ForgotPasswordActionTypes } from "./types";

const initialState: IForgotPasswordState = {
  email: "",
  currentStep: 0,
  value: null,
  loading: false,
  errorMessage: "",
};

export const ForgotPasswordReducer: Reducer<
  IForgotPasswordState,
  ForgotPasswordActions
> = (state = initialState, action) => {
  switch (action.type) {
    case ForgotPasswordActionTypes.RESET_FORGOTPASSWORD: {
      return initialState;
    }

    case ForgotPasswordActionTypes.FORGOTPASSWORD_SUCCESS: {
      return {
        ...state,
        value: action.payload,
      };
    }

    case ForgotPasswordActionTypes.FORGOTPASSWORD_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null,
      };
    }

    case ForgotPasswordActionTypes.SET_EMAIL: {
      return {
        ...state,
        email: action.payload.email,
      };
    }

    case ForgotPasswordActionTypes.GO_TO_NEXT_STEP: {
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    }
    default:
      return state;
  }
};
