import classNames from 'classnames';
import { EventWebsiteTemplateType } from 'src/mui/types/survey';
import { IEvent } from 'src/models/event/event';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { IRoom } from '../../../models/event/room';

import AccordionLayout from '../../../shared-components/Accordion/AccordionLayout';
import { AgendaTitleBody } from './agenda';
import EventTitleBar from './event-title-bar';

const WebsiteEventDayDetail = ({
  room,
  roomType,
  roomQty,
  customColor,
}: {
  room: IRoom;
  roomType: string;
  roomQty?: number;
  customColor?: string;
}) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  if (!room?.sessions?.length) return null;
  return (
    <>
      <div className="w-full">
        <EventTitleBar
          title={room.name}
          roomType={roomType}
          roomQty={roomQty}
          backgroundColor={customColor}
        />
      </div>
      <div
        className={classNames(
          'px-[20px] py-[20px] rounded-10 mt-[7px] h-[calc(100%-66px)]',
          {
            'bg-white':
              event?.websiteTemplate !== EventWebsiteTemplateType.AFTER_HOURS,
            'bg-[#F5F8F91A]':
              event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
          }
        )}
      >
        {room.sessions.map((session, index) => (
          <div key={index} className={classNames('w-full mb-[30px] last:mb-0')}>
            <AccordionLayout
              borderColor={customColor}
              styling="p-0 absolute right-0 top-0"
              roomQty={roomQty}
              titleData={(
                <AgendaTitleBody
                  event={event}
                  eventOverview={session.summary}
                  roomQty={roomQty}
                  session={session}
                />
              )}
              eventTemplate={event?.websiteTemplate}
              // bodyData={}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default WebsiteEventDayDetail;
