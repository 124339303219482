/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { TextInput, Typography } from '../../../shared-components';
// import arrowbutton from '../../../assets/images/arrowbutton.svg';
import { IAppState } from '../../../store';
import { emailRegex } from '../../../utils';
import { LOGIN_SIGNUP_RECOVERY } from '../../../constants';
import { checkUserEmail } from '../../../store/checkUserEmail/index';
import { Button } from '../../../shared-components/V2';

interface ILoginOrSignup {
  email: string;
}

interface ILoginOrJoinProps {
  from: string;
  changePage: any;
}

export const LandingLoginOrJoinForm = ({
  from, changePage
}: ILoginOrJoinProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ILoginOrSignup>();

  const queryParams = new URLSearchParams(window.location.search);
  const redirectTo = queryParams.get('login');

  const getRedirectUrl = (url: string) => {
    if (redirectTo) {
      return `${url}?login=${redirectTo}`;
    }
    return `${url}`;
  };

  const dispatch = useDispatch();
  const selector = useSelector((state: IAppState) => state.checkUserEmail);
  const navigate = useNavigate();

  const onSubmit = (data: ILoginOrSignup) => {
    sessionStorage.setItem('userName', data.email);
    sessionStorage.setItem('eventRegStep', '0');
    dispatch(checkUserEmail(data));
  };

  // eslint-disable-next-line no-unused-vars
  const initialRender = useRef(true);
  useEffect(() => {
    if (!selector?.loading && !!selector.value) {
      const userExists = selector.value?.result;
      if (userExists && from === 'event') {
        changePage(1);
      } else if (userExists && from === 'site') {
        navigate(getRedirectUrl('/login'));
      } else if (!userExists && from === 'event') {
        changePage(2);
      } else if (!userExists && from === 'site') {
        navigate('/join');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector.value, from, changePage, navigate]);

  // eslint-disable-next-line no-unused-vars
  const setCursorInputPosition = (e: any, pos: any) => {
    e.setSelectionRange(pos, pos);
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-end">
        <div className="flex flex-col">
          <TextInput
            className={classNames('w-full sm:w-[420px] h-[45px] text-[19px] font-medium text-[#3C3C3C] rounded-10 placeholder:text-[#3C3C3C] focus-visible:outline-none px-[20px]')}
            textinputsize="medium"
            type="text"
            autoFocus
            placeholder="Email Address"
            {...register('email', {
              required: {
                value: true,
                message: LOGIN_SIGNUP_RECOVERY.EMAILADDRESS_ISREQUIRED
              },
              pattern: {
                value: emailRegex,
                message: LOGIN_SIGNUP_RECOVERY.VALID_EMAILADDRESS
              }
            })}
            autoComplete="off"
          />
          <div>
            {errors.email && (
              <Typography
                variant="ValidationMessage"
                text={errors.email?.message!.toString()}
                classes="flex content-start mt-1"
              />
            )}
          </div>
        </div>
        <div
          className={classNames('mt-[20px]')}
        >
          <Button
            data-test-id="login-button"
            type="submit"
            text="Continue"
            size="x-small"
            className={classNames('w-[117px] lg:w-[92px] h-[38px] lg:h-[30px] bg-white rounded-[6px] text-[#3855EB] text-[18px] lg:text-[14px] font-medium hover:bg-[#BDCCE8] transition-all')}
          />
        </div>
      </div>
    </form>
  );
};

export default LandingLoginOrJoinForm;
