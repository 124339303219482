import bulb from '../../../assets/images/compliance/bulb-icon.png';

const TableContentFive = () => (
  <>
    <hr className="my-10" />
    <div id="termination" className="mt-7 text-gray-1 text-lg5">
      5. Termination
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        If we terminate your account, you have 30 days to access your user data. Remember, it is
        your responsibility to keep backup copies of your User Data.
      </div>
    </div>
    <div className="mt-3">
      a. Panelist may suspend or terminate your access to and use of the Platform, in whole or in
      part, at any time and for any reason. You may terminate your account at any time upon notice
      to us. In the event of suspension or termination (other than cases where Panelist locks your
      account due to fraudulent activities or other potential harm to Panelist or third parties),
      Panelist will provide you with access to your User Data for at least 30 days following such
      termination. It is your responsibility to keep backup copies of the User Data.
    </div>
  </>
);

export default TableContentFive;
