import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NoSpotlightEvents } from 'src/components/spotlight/no-spotlight-events';
import { IUserProfile } from '../../models/user-profile';
import { UserService } from '../../services/UserService';
import { closeMessageWindow, openMessageWindow } from '../../store/newMessage';
import { setConnectSuggestion } from '../../store/user/connect-suggestions/actions';
import { UserSearchResult } from '../../store/user/search-user';
import PeopleListCard from './peopleListCard';
import Pagination from './pagination/pagination';

type IPeopleProps = {
  searchText: string;
  onSeeAll: Function;
  infiniteScroll?: boolean;
  filters?: string;
  peopleSearchResults:(value:any)=>void;
  companyPeopleResults?: any;
};

export const People = (props: IPeopleProps) => {
  const [people, setPeople] = useState<IUserProfile[]>([]);
  const [selectedPeople, setSelectedPeople] = useState<IUserProfile[]>([]);
  const userService: UserService = new UserService();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState<number>();

  const paginateFront = () => setCurrentPage(currentPage + 1);
  const paginateBack = () => setCurrentPage(currentPage - 1);

  const getUsers = async () => {
    const limit = props.infiniteScroll ? 10 : 5;
    const page = currentPage;
    const result: UserSearchResult = await userService.getSearchUserResults(`${props.searchText}${props.filters ?? ''}`, limit, page);
    setPeople([...result?.data?.users?.data]);
    props?.peopleSearchResults([...result?.data?.users?.data]);
    setTotalPage(result?.data?.users?.totalPage);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchText, props.filters, props.infiniteScroll, currentPage]);

  const onClickPeople = (people: any) => {
    setSelectedPeople([...selectedPeople, people]);
  };

  if (selectedPeople.length > 0) {
    const recentPeopleList: any = JSON.parse(localStorage.getItem('recentPeopleList')!);
    if (recentPeopleList?.length > 0) {
      const totalPeoples = recentPeopleList?.concat(selectedPeople);
      const recentPeoples = totalPeoples?.filter((item: any, index: any) => index === totalPeoples?.findIndex((itemTwo: any) => itemTwo.id === item.id && itemTwo.id === item.id));
      localStorage.setItem('recentPeopleList', JSON.stringify(recentPeoples));
    } else {
      localStorage.setItem('recentPeopleList', JSON.stringify(selectedPeople));
    }
  }

  return (
    <>
      {people?.length > 0 ? (
        <div className="bg-white rounded-xl px-[20px] py-[20px] create-post-card">
          <span className="block text-md3 font-medium pb-0">People</span>
          <PeopleListCard
            people={people}
            onClick={(user: IUserProfile) => navigate(`/wall/${user.slug}`)}
            onMessage={async (user: IUserProfile) => {
              await dispatch(closeMessageWindow());
              await dispatch(
                openMessageWindow({
                  avatar: user.avatar!,
                  userDisplayName: `${user.firstName} ${user.lastName}`,
                  userId: user.id
                })
              );
            }}
            onConnect={async (id: string) => {
              await dispatch(setConnectSuggestion(id));
              await getUsers();
            }}
            onClickPeople={onClickPeople}
          />
          {
            !props.infiniteScroll && people?.length > 0 && (
            <div onClick={() => props.onSeeAll()} className="text-[#285CB2] text-sm text-center font-semibold block pt-[20px] pb-0 hover:underline cursor-pointer">
              See all People results
            </div>
            )
          }
        </div>
      ) : (props?.companyPeopleResults && !props?.companyPeopleResults?.companies?.length && !props?.companyPeopleResults?.people?.length) && <NoSpotlightEvents />}
      {props.infiniteScroll && totalPage ? (
        <div className="create-post-card bg-white py-[14px] px-[20px]">
          <Pagination paginateFront={paginateFront} paginateBack={paginateBack} currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
        </div>
      ) : null}
    </>
  );
};

export default People;
