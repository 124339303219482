import { Transition } from '@tailwindui/react';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { EventsService } from '../../../../services/events';
// import useStyling from 'src/mui/components/hook-form/useStyling';
import { getCompanyName } from 'src/utils';
import { ArrowDownSvgIcon } from '../../../../shared-components/V2/IconSvg/ArrowDownSvgIcon/ArrowDownSvgIcon';
import { IAppState } from '../../../../store';
import { useCurrentUser } from '../../../../utils/hooks';

interface IGotoList {
  title?: string;
  btnClassName?: string;
  fillColor?: string;
  titleClass?: string;
  dropdownClass?: string;
  buttonTheme?: any;
  isInvitation?: boolean;
  isFactor?: boolean;
  isRegoForm?:boolean
}
export const GotoList = ({
  // eslint-disable-next-line no-unused-vars
  title, btnClassName, fillColor, titleClass, dropdownClass, buttonTheme, isInvitation, isFactor, isRegoForm
}: IGotoList) => {
  // const { primaryBackgroundColor } = useStyling(null, null);
  const [isOpen, setIsOpen] = useState(false);
  const container = useRef<any>(null);
  // const navigate = useNavigate();
  const user = useCurrentUser();

  const event = useSelector((state: IAppState) => state.website.event!);
  // const apiUrl = process.env.REACT_APP_API_URL;

  // Allow for outside click
  useEffect(() => {
    function handleOutsideClick(event: any) {
      if (!container?.current?.contains(event.target)) {
        if (!isOpen) return;
        setIsOpen(false);
      }
    }

    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [isOpen, container]);

  // Allow to use the `esc` key
  useEffect(() => {
    function handleEscape(event: any) {
      if (!isOpen) return;

      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    }

    document.addEventListener('keyup', handleEscape);
    return () => document.removeEventListener('keyup', handleEscape);
  }, [isOpen]);

  const onGotoPage = (page: string) => {
    if (page === 'profile') {
      // navigate(`/wall/${user?.slug}`);
      window.open(`/wall/${user?.slug}`, '_blank');
    }
    if (page === 'lobby') {
      // navigate(`/event/${event?.slug}/discussion`);
      window.open(`/event/${event?.slug}/discussion`, '_blank');
    }
    if (page === 'live') {
      // navigate(`/event/${event?.slug}/live`);
      window.open(`/${getCompanyName(event)}/${event?.slug}/live`, '_blank');
    }
  };

  return (
    <div ref={container} className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className={classNames({
            'text-gray-400 rounded-full ml-[14px] items-center justify-center inline-flex': !btnClassName,
            [`${btnClassName}`]: btnClassName,
          })}
          style={buttonTheme}
          // style={{ backgroundColor: primaryBackgroundColor || '#0049EF' }}
          aria-haspopup="true"
          aria-expanded={isOpen}
          onClick={() => setIsOpen(v => !v)}
        >
          <span
            className={classNames({
              [`${titleClass}`]: titleClass,
            })}
            style={buttonTheme}
          >
            {title}
          </span>
          {' '}
          <span>
            {' '}
            <ArrowDownSvgIcon fillColor={fillColor} />
          </span>

        </button>
      </div>

      <Transition
        show={isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={classNames('absolute z-50 top-[24px] left-0 w-full mt-2 origin-top-right bg-white post-company-logo rounded-10', {
          [`${dropdownClass}`]: dropdownClass,
        })}
      >
        <div>
          <div
            className="p-1.5"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div
              className={classNames('h-[30px] flex items-center justify-start px-2.5 text-sm leading-[13px] text-[#172746] hover:rounded-5 hover:bg-[#EDF2F5] mb-[5.2px] cursor-pointer', {
                '!text-white hover:bg-[#14161A]': isFactor || isRegoForm,
                // '!text-white hover:bg-[#14161A]': isRegoForm,
              })}
              onClick={() => onGotoPage('profile')}
            >
              My profile
            </div>

            <div
              className={classNames('h-[30px] flex items-center justify-start px-2.5 text-sm leading-[13px] text-[#172746] hover:rounded-5 hover:bg-[#EDF2F5] mb-[5.2px] cursor-pointer', {
                '!text-white hover:bg-[#14161A]': isFactor || isRegoForm,
              })}
              onClick={() => onGotoPage('lobby')}
            >
              Lobby
            </div>
            {!isInvitation && (
              <div
                className={classNames('h-[30px] flex items-center justify-start px-2.5 text-sm leading-[13px] text-[#172746] hover:rounded-5 hover:bg-[#EDF2F5] mb-[5.2px] cursor-pointer', {
                  '!text-white hover:bg-[#14161A]': isFactor || isRegoForm,
                })}
                onClick={() => onGotoPage('live')}
              >
                Live event
              </div>
            )}
          </div>
        </div>
      </Transition>
    </div>
  );
};
