import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { useDispatch } from "react-redux";
import { removeReaction } from "src/store/1-1-video-call/actions";

type ReactionType = {
  sender: string;
  reaction: string;
  y?: number; 
  x?: number;
  phase?: number; 
  scale?: number; 
  opacity?: number; 
  id:any
};

type ReactionContextType = {
  recieverPartyReactionInfo?: ReactionType;
  senderPartyReactionInfo?: ReactionType;
  reactions: ReactionType[];
  chatVideoCall?:any
};

const ReactionContext = createContext<ReactionContextType | null>(null);

interface ReactionProviderProps {
  children: ReactNode;
  chatVideoCall: Omit<ReactionContextType, "reactions">;
}

export const ReactionProvider: React.FC<ReactionProviderProps> = ({
  children,
  chatVideoCall,
}) => {
  const [reactions, setReactions] = useState<ReactionType[]>([]);
const dispatch=useDispatch();
  useEffect(() => {
    if (chatVideoCall) {
      setReactions((prevReactions) => [
        ...prevReactions,
        ...(chatVideoCall.senderPartyReactionInfo
          ? [
              {
                ...chatVideoCall.senderPartyReactionInfo,
                newId: Math.random(),
                y: 0,
                x: Math.random() * 100,
                phase: Math.random() * Math.PI * 2,
                scale: 1,
                opacity: 1,
              },
            ]
          : []),
        ...(chatVideoCall.recieverPartyReactionInfo
          ? [
              {
                ...chatVideoCall.recieverPartyReactionInfo,
                newId: Math.random(),
                y: 0,
                x: Math.random() * 100,
                phase: Math.random() * Math.PI * 2,
                scale: 1,
                opacity: 1,
              },
            ]
          : []),
      ]);
    }
  }, [chatVideoCall]);

  useEffect(() => {
    const interval = setInterval(() => {
      setReactions((prev) =>
        prev
          .map((reaction) => ({
            ...reaction,
            y: (reaction?.y || 0) + 0.75,
            x: (reaction?.x || 0) + Math.sin(reaction?.phase || 0) * 1,
            phase: (reaction?.phase || 0) + 0.1,
            scale: reaction?.y && reaction?.y > 50 ? (reaction?.scale || 1) - 0.02 : reaction?.scale,
            opacity: reaction?.y && reaction?.y > 50 ? (reaction?.opacity || 1) - 0.02 : reaction?.opacity,
          }))
          .filter((reaction) => reaction.opacity && reaction.opacity > 0)
      );
    }, 24);

    return () => {
      clearInterval(interval);
      dispatch(removeReaction());
    };
  }, []);

  return (
    <ReactionContext.Provider value={{ chatVideoCall, reactions }}>
      {children}
    </ReactionContext.Provider>
  );
};

export const useReactionContext = (): ReactionContextType => {
  const context = useContext(ReactionContext);

  if (!context) {
    throw new Error("useReactionContext must be used within a ReactionProvider");
  }

  return context;
};
