import { useSelector } from 'react-redux';
import { imageUrlPrefix } from '..';
import { IUserProfile } from '../../models/user-profile';
import { IAppState } from '../../store';
import DefaultAvatar from '../../assets/images/avatar-default.svg';

export * from './copyToClipboard';
export * from './fullUrl';
export * from './websockets';

export const useCurrentUser = () => {
  return useSelector((state: IAppState) => state.auth.currentUser as IUserProfile);
};

export const useUserDetails = (user: IUserProfile | undefined) => {
  if (!user) return {};
  const id = user.id;
  const avatar = user.avatar ? `${imageUrlPrefix}/${user.avatar}` : DefaultAvatar;
  const fullName = `${user.firstName} ${user.lastName}`;
  const jobTitle = user.jobTitle;
  const company = user?.company?.name ?? user?.companyId;
  const companySlug = user?.company?.slug ?? undefined;
  const companyAdmin = user.companyAdmins;
  return { id, avatar, fullName, jobTitle, company, companyAdmin, companySlug };
};
