import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { MeetingStatus, PeopleType, ScheduledMeetingsButton } from "./Types";
import { getMinifiedImage } from "src/mui/utils/image";
import { CustomAvatar } from "src/mui/components/hook-form/custom-avatar";
import moment from "moment-timezone";
import { getUTCTimeZone } from "src/mui/assets/data/timezones";
import { HostAndAttendee } from "./attendee";
import GridViewIcon from "./Icons/GridViewIcon";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "src/store";
import { useCurrentUser } from "src/utils/hooks";
import {
  getMeetings,
  getMeetingsTabCount,
  updateMeetingStatus,
} from "src/store/liveEvent";
import SearchNotFound from "src/mui/components/search-not-found";
import { applyFilter } from "src/mui/utils/filter-list";
import { getBrandingColors, subscribeToColorChanges } from "../event/live/liveEventStyling";
import { styled } from "@mui/system";
import { ArrowDownSvgIcon } from "src/shared-components/V2/IconSvg";

type Props = {
  role: string;
  sponsorId?: string;
  columnWidth?: number;
  searchQuery?: string;
};
function LiveMeetings({ role, sponsorId, columnWidth, searchQuery }: Props) {
  const dispatch = useDispatch();
  const colors = getBrandingColors();
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const user = useCurrentUser();

  const {
    meetings,
    tabsCount,
    meetingsSummary,
    meetingCount,
    isMeetingLoading,
  } = liveEvent;

  const [meetingSponsorsOptions, setMeetingSponsorsOptions] = useState<any[]>(
    []
  );
  const [dropdownMeeting, setDropdownMeeting] = useState("");
  const [selectedSponsorId, setSelectedSponsorId] = useState("");
  const [selectedMeetingButton, setSelectedMeetingButton] =
    useState<ScheduledMeetingsButton>(ScheduledMeetingsButton.ConfirmMeetings);

  const filteredMeetings: any[] = applyFilter({
    list: meetings ?? [],
    query: searchQuery,
    selectedTab: selectedMeetingButton,
    type: "meetings",
  });

  const notFound = !filteredMeetings?.length && !!searchQuery;

  const confirmMeetingLoader = (
    <Skeleton
      animation="wave"
      variant="rounded"
      width={"90%"}
      height={136}
      sx={{
        opacity: "50%",
        backgroundColor: "#2f2f2f",
        boxShadow:
          "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
      }}
    />
  );

  const pendingMeetingLoader = (
    <Skeleton
      animation="wave"
      variant="rounded"
      width={"100%"}
      height={136}
      sx={{
        opacity: "50%",
        backgroundColor: "#2f2f2f",
        boxShadow:
          "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
      }}
    />
  );

  const getSelectedMeetingButtonStyles = (meetingButton: string) => {
    let variant = "outlined";
    let color = colors?.Accent || "white";
    let backgroundColor = colors?.Main || '#000000';
    if (selectedMeetingButton === meetingButton) {
      variant = "contained";
      color = colors?.Main || "black";
      backgroundColor = colors?.Accent || "#03FFCD";
    }
    return { variant, color, backgroundColor };
  };

  const handleAcceptOrDeclineMeeting = (
    meetingId: string,
    meetingStatus: string
  ) => {
    const whereClause = user?.id ? `userId=${user?.id}` : "";
    const statusClause = `&where[status]=${meetingStatus}`;
    const conditionalWhere = whereClause.concat(statusClause);

    dispatch(
      updateMeetingStatus(
        liveEvent?.event?.id!,
        meetingId,
        { status: meetingStatus },
        role!,
        user?.id,
        selectedMeetingButton,
        sponsorId
      )
    );
  };

  const getMeetingTimeAndDate = (meetingStartTime: any) => {
    const time = moment(meetingStartTime!)
      .tz(getUTCTimeZone(liveEvent?.event?.timezone!)!)
      .format("hh:mm");
    // } { " "}  • { " "}
    const date = moment(meetingStartTime!)
      .tz(getUTCTimeZone(liveEvent?.event?.timezone!)!)
      .format("DD MMM");

    return `${time} • ${date}`;
  };

  const pendingMeetingsList = () =>
    !isMeetingLoading
      ? filteredMeetings?.map((meeting, index) => (
          <Card
            key={index}
            sx={{
              width: "100%",
              p: "10px",
              background: "#2F2F2F",
              borderRadius: "10px",
            }}
          >
            {/* card heading */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton>
                  <GridViewIcon />
                </IconButton>

                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "15px",
                    lineHeight: "20px",
                    fontFamily: "Poppins-500",
                    flexWrap: "nowrap",
                    color: "white",
                  }}
                >
                  Meeting request from :
                </Typography>
              </Box>
            </Stack>

            <Stack
              direction="row"
              columnGap="0px"
              justifyContent="space-around"
              sx={{ mt: "10px" }}
              alignItems="center"
            >
              {/*  user avatar and info */}
              <Stack sx={{ maxWidth: "80px" }}>
                <HostAndAttendee attendee={meeting?.user} isMeeting  setOpenFeedbackPopup={()=>{}}/>
              </Stack>

              {/* meeting time */}
              <Typography
                className="ellipsis-one-line"
                variant="body2"
                sx={{
                  fontSize: "12px",
                  lineHeight: "19px",
                  color: "white",
                  fontFamily: "Poppins-400",
                  textAlign: "center",
                  backgroundColor: "black",
                  p: 1,
                  borderRadius: "10px",
                }}
              >
                {getMeetingTimeAndDate(meeting?.startTime)}
              </Typography>

              {/*  attendeeUser avatar and info */}
              <Stack sx={{ maxWidth: "80px" }}>
                <HostAndAttendee attendee={meeting?.attendeeUser} isMeeting  setOpenFeedbackPopup={()=>{}}/>
              </Stack>
            </Stack>

            {/* decline and accept buttons */}
            <Stack
              direction="row"
              spacing="10px"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: "10px" }}
            >
              <Button
                variant="outlined"
                sx={{
                  height: "40px",
                  borderRadius: "10px",
                  border: `1px solid ${colors?.Accent || '#707070'}`,
                  backgroundColor: "black",
                }}
                fullWidth
              >
                <Typography
                  variant="body2"
                  className="field-label"
                  onClick={() =>
                    handleAcceptOrDeclineMeeting(
                      meeting?.id,
                      MeetingStatus.DENIED
                    )
                  }
                  sx={{
                    color: "white",
                    lineHeight: "16px",
                    fontSize: "13px",
                    fontFamily: "Poppins-500",
                  }}
                >
                  Decline
                </Typography>
              </Button>

              <Button
                variant="contained"
                fullWidth
                sx={{
                  height: "40px",
                  borderRadius: "10px",
                  backgroundColor: "#03FFCD",
                }}
                onClick={() =>
                  handleAcceptOrDeclineMeeting(
                    meeting?.id,
                    MeetingStatus.APPROVED
                  )
                }
              >
                <Typography
                  variant="body2"
                  className="field-label"
                  sx={{
                    color: "black",
                    lineHeight: "16px",
                    fontSize: "13px",
                    fontFamily: "Poppins-500",
                  }}
                >
                  Accept
                </Typography>
              </Button>
            </Stack>
          </Card>
        ))
      : pendingMeetingLoader;

  const IsLoggedInUserAlsoAttendee = (meeting: any) => {
    if (user?.id === meeting?.attendeeId) return meeting?.user;
    return meeting?.attendeeUser;
  };

  const confirmedMeetingsList = () => (
    <Stack>
      <Grid
        rowGap="26px"
        columnGap="0px"
        columnSpacing="26px"
        container
        sx={{}}
      >
        {filteredMeetings.map((meeting, index) => {
          const meetingHolder = IsLoggedInUserAlsoAttendee(meeting);
          const meetingTime = getMeetingTimeAndDate(meeting?.startTime);
          return (
            <Grid key={index} item xs={12} md={4} lg={4}>
              {!isMeetingLoading ? (
                <HostAndAttendee
                  columnWidth={columnWidth}
                  attendee={meetingHolder}
                  meetingTime={meetingTime}
                  meeting={{
                    id: meeting?.id,
                    userId:meeting?.userId,
                    attendeeId:meeting?.attendeeId,
                    startTime: meeting?.startTime,
                    endTime: meeting?.endTime,
                  }}
                  isMeeting={true}
                 setOpenFeedbackPopup={()=>{}}/>
              ) : (
                confirmMeetingLoader
              )}
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );

  const MeetingStatusButtons = () => {
    return (
      <Stack direction="row" spacing="5px" sx={{ px: "0px", mt: "0px" }}>
        <Button
          variant={
            selectedMeetingButton === ScheduledMeetingsButton.ConfirmMeetings
              ? "contained"
              : "outlined"
          }
          sx={{
            height: "28px",
            background: getSelectedMeetingButtonStyles(
              ScheduledMeetingsButton.ConfirmMeetings
            )?.backgroundColor,
            borderRadius: "5px",
            border: selectedMeetingButton !== ScheduledMeetingsButton.ConfirmMeetings ? `1px solid ${colors?.Accent || '#707070'}` : '',
            px: '8px'
          }}
          fullWidth
          onClick={() =>
            setSelectedMeetingButton(ScheduledMeetingsButton.ConfirmMeetings)
          }
        >
          <Typography
            variant="body2"
            className="field-label"
            sx={{
              color:
                getSelectedMeetingButtonStyles(
                  ScheduledMeetingsButton.ConfirmMeetings
                )?.color || "text.primary",
              lineHeight: "28px",
              fontSize: "11px",
              fontFamily: "Poppins-500",
              fontWeight: 500
            }}
          >
            Confirmed Meetings {meetingCount?.approved}
          </Typography>
        </Button>

        <Button
          sx={{
            height: "28px",
            background: getSelectedMeetingButtonStyles(
              ScheduledMeetingsButton.PendingMeetings
            )?.backgroundColor,
            borderRadius: "5px",
            border: selectedMeetingButton !== ScheduledMeetingsButton.PendingMeetings ? `1px solid ${colors?.Accent || '#707070'}` : '',
            px: '8px',
            maxWidth: '155px'
          }}
          variant={
            selectedMeetingButton === ScheduledMeetingsButton.PendingMeetings
              ? "contained"
              : "outlined"
          }
          fullWidth
          onClick={() =>
            setSelectedMeetingButton(ScheduledMeetingsButton.PendingMeetings)
          }
        >
          <Typography
            variant="body2"
            className="field-label"
            sx={{
              color:
                getSelectedMeetingButtonStyles(
                  ScheduledMeetingsButton.PendingMeetings
                )?.color || "text.primary",
              lineHeight: "28px",
              fontSize: "11px",
              fontFamily: "Poppins-500",
              fontWeight: 500
            }}
          >
            Pending Requests {meetingCount?.waiting}
          </Typography>
        </Button>
      </Stack>
    );
  };

  const handleSelectedDropdown = (e: any, option: any) => {
    setSelectedSponsorId("");
    if (!option) return;
    if (option.isSponsor) {
      setSelectedSponsorId(option.id);
    }
    setDropdownMeeting(
      option.isSponsor ? `selectedSponsor${option.id}` : option.id
    );
  };

  useEffect(() => {
    if (liveEvent) {
      dispatch(
        getMeetingsTabCount(
          liveEvent?.event?.id!,
          "approved",
          role!,
          dropdownMeeting,
          selectedSponsorId || sponsorId
        )
      );
      dispatch(
        getMeetingsTabCount(
          liveEvent?.event?.id!,
          "waiting",
          role!,
          dropdownMeeting,
          selectedSponsorId || sponsorId
        )
      );
      dispatch(
        getMeetings(
          liveEvent?.event?.id!,
          role!,
          user?.id!,
          selectedMeetingButton,
          selectedSponsorId || sponsorId,
          dropdownMeeting
        )
      );
    }
  }, [selectedMeetingButton, dropdownMeeting]);

  useEffect(() => {
    if (meetingsSummary && liveEvent?.event && tabsCount) {
      const hardcodedOptions = [
        {
          name: "All Meetings",
          id: "allMeetings",
          logo: null,
          count: meetingsSummary?.allMeetings,
        },
        {
          name: "All Sponsors Meetings",
          id: "allSponsorsMeetings",
          logo: null,
          count: meetingsSummary?.sponsorsMeetings,
        },
        {
          name: "Others Meetings",
          id: "othersMeetings",
          logo: null,
          count: meetingsSummary?.otherMeetings,
        },
      ];

      const sponsorOptions = liveEvent?.event?.eventSponsors.map((sponsor) => ({
        name: sponsor?.company?.name || null,
        id: sponsor?.id || null,
        logo: sponsor?.company?.logo || null,
        count: meetingsSummary?.sponsorsMeetingsSummary
          ? meetingsSummary?.sponsorsMeetingsSummary[sponsor.company?.name]
          : 0,
        isSponsor: true,
      }));
      const finalOptions: any = [...hardcodedOptions, ...sponsorOptions];
      setMeetingSponsorsOptions(finalOptions);
    }
  }, [meetingsSummary, tabsCount, liveEvent?.event]);

  useEffect(() => {
      const unsubscribe = subscribeToColorChanges(() => {
        getBrandingColors();
      });
      return unsubscribe;
    }, []);
  
    const StyledPaper = styled(Paper)(({ theme }) => ({
      backgroundColor: `${colors?.Secondary!|| "#ffffff"} !important`,
      color: `${colors?.Text!|| "#000000"} !important`,
    }));

  return (
    <Stack direction="column" spacing="10px">
      {role === "admin" && (
        <FormControl fullWidth>
          <Autocomplete
            id="grouped-demo"
            PaperComponent={(props) => <StyledPaper {...props} />}
            popupIcon={<ArrowDownSvgIcon fillColor={colors?.Text!|| "#ffffff"} />}
            sx={{
              "& .MuiInputLabel-root": {
                padding: "0 4px",
                left: "-4px",
                top: "-10px",
                fontWeight: "300",
              },
              "& .MuiInputBase-input::placeholder": {
                color: `${colors?.Text! || 'white'} !important`, 
                opacity: 1, 
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: `${colors?.Text! || 'white'} !important`,
              },
              "& .MuiAutocomplete-endAdornment": {
                color: `${colors?.Text! || 'white'} !important`, 
                top: "calc(50% - 11.5px) !important",
              },
        
              "& .MuiInputLabel-shrink": {
                top: "0 !important",
              },
              "& .MuiOutlinedInput-input": {
                padding: "0px 14px",
                height: "25px",
                borderRadius: "5px !important",
              },
              "& .MuiAutocomplete-input": {
                pl: "15px !important",
                pr: "35px !important",
                py: "6.5px !important",
                fontSize: '13px',
                background: colors?.Secondary!|| "#2F2F2F",
                color: `${colors?.Text! || 'white'} !important`,
              },
              "& .MuiOutlinedInput-root": {
                p: "0px !important",
                pl: "0px !important",
              },
              "& fieldset": { display: "none" },
            }}
            // open
            // popupIcon={<ArrowDownIcon />}
            // value={metric?.todoSponsor}
            // onChange={handleTodoSponsor}
            onChange={(e, selectedSponsor) =>
              handleSelectedDropdown(e, selectedSponsor)
            }
            options={meetingSponsorsOptions || []}
            getOptionLabel={(option: any) =>
              option?.name +
              " (" +
              meetingSponsorsOptions?.find(
                (meetingOption: any) => meetingOption?.id === option?.id
              )?.count +
              ") "
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="All Meetings" />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box
                    sx={{
                      border: "1px solid #E8E8E8",
                      p: "0px",
                      width: 32,
                      height: 32,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "6px",
                    }}
                  >
                    <CustomAvatar
                      src={getMinifiedImage(option?.logo!)}
                      alt={`${option?.name}`}
                      name={option?.name}
                      sx={{
                        width: 32,
                        height: 32,
                        borderRadius: "6px",
                        "& .MuiAvatar-img": {
                          objectFit: "contain !important",
                        },
                      }}
                    />
                  </Box>
                  <Stack>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "500", fontSize: '12px', color: colors?.Text!|| "#ffffff"  }}>{option?.name}</span>
                    </Typography>
                    <Typography style={{ fontWeight: "400", fontSize: '11px', color: colors?.Text!|| "#ffffff" }} variant="body1">
                      {option?.count || 0} Meetings
                    </Typography>
                  </Stack>
                </Stack>
                {/* <Divider */}
                {/* <Divider sx={{ borderColor: 'black', my: '10px', opacity: '50%' }} /> */}
              </li>
            )}
            // renderGroup={(params) => (
            //     <li key={params.key}>
            //         <GroupHeader>{params.group}</GroupHeader>
            //         <GroupItems>{params.children}</GroupItems>
            //     </li>
            // )}
          />
        </FormControl>
      )}
      {(!liveEvent?.event?.willHostApproveMeeting || role === "admin") &&
        MeetingStatusButtons()}

      <Stack direction="column" rowGap="10px">
        {notFound ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 10,
            }}
          >
            <SearchNotFound query={searchQuery} sx={{ mt: 3 }} />
          </Box>
        ) : selectedMeetingButton ===
          ScheduledMeetingsButton.PendingMeetings ? (
          pendingMeetingsList()
        ) : (
          confirmedMeetingsList()
        )}
      </Stack>
    </Stack>
  );
}
export default LiveMeetings;
