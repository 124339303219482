import { useState } from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface IAcceptSvgIcon {
    fillColor?: string;
    fillBgColor?: string;
}

export const AcceptSvgIcon = ({ fillColor, fillBgColor }: IAcceptSvgIcon) => {
  const defaultColor = '#7283A4';
  const defaultBgColor = '#03ffcd';
  
  const iconColor = fillColor || defaultColor;
  const bgColor = fillBgColor || defaultBgColor;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
      <g id="Group_20564" data-name="Group 20564" transform="translate(-379.721 -291.769)">
        <g id="Component_435" data-name="Component 435" transform="translate(375.721 290.769)">
          <rect
            id="Rectangle_8169"
            data-name="Rectangle 8169"
            width="45"
            height="45"
            rx="15"
            transform="translate(4 1)"
            fill={bgColor}
          />
        </g>
        <path
          id="tick-svgrepo-com"
          d="M4.892,16.281,11.61,23l15-15"
          transform="translate(386.469 298.769)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
      </g>
    </svg>
  );
};
