/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { imageUrlPrefix } from "src/utils";
import { useDispatch, useSelector } from "react-redux";
import { getEventSponsors } from "src/store/liveEvent";
import { IAppState } from "src/store";
import companyDefault from "src/assets/images/company-default.svg";
import UnfollowIcon from "../event/reg-dialog-flow/UnfollowIcon";
import { HostAndAttendee } from "../audience/attendee";
import Carousel, { useCarousel } from "src/mui/components/carousel";
import classNames from "classnames";

import { CoreCapabilityHeight } from "../event/live/Constants";

import {
  followCompany,
  getCompanyProfilePreview,
  unFollowCompany,
} from "src/store/company/profile-preview/actions";
import { useCurrentUser, useWebSocket } from "src/utils/hooks";
import { LoadingButton } from "@mui/lab";
import { useSurveyContext } from "src/mui/providers/GeneralContext";
import MeetingRequestList from "./MeetingRequestList";
import Crosscon from "../event/reg-dialog-flow/Crosscon";
import { getBrandingColors } from '../event/live/liveEventStyling';
import WebsiteIcon from "../event/reg-dialog-flow/WebsiteIcon";
import { ArrowRightIcon } from "src/mui/assets/icons/ArrowRightIcon";

type ProductsProps = {
  columnWidth: any;
  selectedSponsorDetails: any;
};

export const SponsorDetails = ({
  columnWidth,
  selectedSponsorDetails,
}: ProductsProps) => {
  const colors = getBrandingColors();
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const { getSessionMeetings, sessionMeetings, requestMeeting } =
      useSurveyContext();


  const contentWrapper = useRef(null);
  const carousel = useCarousel({
    adaptiveHeight: true,
  });

  const columnRef = useRef<HTMLDivElement>(null);
  const [boxWidth, setBoxWidth] = useState<number | null>(null);
  const [showArrows, setShowArrows] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [followButtonCaption, setFollowButtonCaption] = useState("");
  const [isMeetingScheduleModal, setIsMeetingScheduleModal] = useState(false);
  const [meetingAttendee, setMeetingAttendee] = useState<any>({});
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const { webSocket } = useWebSocket();

  useEffect(() => {
    if (selectedSponsorDetails?.company?.isFollowing === 1) {
      setFollowButtonCaption("Unfollow");
    }
    if (selectedSponsorDetails?.company?.isFollowing === 0) {
      setFollowButtonCaption("Follow");
    }
  }, [selectedSponsorDetails?.company?.isFollowing]);

  useEffect(() => {
    if (columnRef.current) {
      const width = columnRef.current.offsetWidth;
      setBoxWidth(width);
    }
  }, [columnRef.current, columnWidth]);

  useEffect(() => {
    dispatch(getEventSponsors(liveEvent?.event?.id!));
    const handleResize = () => {
      if (columnRef.current) {
        const width = columnRef.current.offsetWidth - 27;
        setBoxWidth(width);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const establishOneToOneCall = (userId:string) => {
    webSocket.then((ws) =>
      ws.send(
        JSON.stringify({
          action: 'request-to-call',
          data: {
            receiverId: userId,
            type: "audio"
          },
        })
      )
    );
  }

  const handleRequestMeeting = (attendee:string) => {
    if (attendee) {
      setIsMeetingScheduleModal(true);
      setMeetingAttendee(attendee);
    }
  }

  const onSuccess = (followStatus: string) => {
    setIsLoader(false)
    setFollowButtonCaption(followStatus);
  }

  const handleUnfollow = async (company: any) => {
    setIsLoader(true);
    if (followButtonCaption === "Unfollow") {
      await dispatch(unFollowCompany(company.id, onSuccess));
      setIsLoader(false)
    } else if (followButtonCaption === "Follow") {
      await dispatch(followCompany(company.id, onSuccess));
      setIsLoader(false)
    }
    await dispatch(getCompanyProfilePreview(company.id));
  };

  const handleWebsiteRedirect = (company: any) => {
    if (!company?.website) return;

    const startWithHttp =
      company?.website?.startsWith("http") ||
      company?.website?.startsWith("//");

    if (startWithHttp) {
      window.open(company?.website, "_blank")?.focus();
    } else {
      window.open(`//${company?.website}`, "_blank")?.focus();
    }
  };

  const sideScroll = (
    element: HTMLDivElement,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const showSponsorCard = (sponsor: any) => {
    return (
      <Box
        ref={columnRef}
        sx={{
          width: "100%",
          display: "flex",
          direction: "column",
          justifyContent: "center",
          pb: "9px",
        }}
      >
        <Stack sx={{ mt: "9px" }} alignItems="center">
          <Box
            sx={{
              width: "87px",
              height: "86px",
              background: colors?.Text || "#ffffff",
              objectFit: "contain",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
            }}
          >
            <Avatar
              src={
                sponsor?.company?.logo
                  ? `${imageUrlPrefix}/${sponsor?.company?.logo}`
                  : companyDefault
              }
              sx={{
                width: "87px",
                height: "86px",
                borderRadius: "0px",
                "&.MuiAvatar-root, & .MuiAvatar-img": {
                  minHeight: "87px",
                  maxHeight: "86px",
                  minWidth: "87px",
                  background: !sponsor?.company?.logo ? "#edf2f5" : "",
                },
              }}
            />
          </Box>

          <Stack sx={{ alignItems: "center", marginTop: "0px", mt: "8px" }}>
            <Typography
              className="ellipsis-one-line"
              variant="body2"
              sx={{
                fontSize: "18px",
                lineHeight: "17px",
                color: "white",
                fontFamily: "Poppins-600",
                maxWidth: "90%",
                textAlign: "center",
              }}
            >
              {sponsor?.company?.name}
            </Typography>
          </Stack>

          <Button
            sx={{
              borderRadius: "3px",
              height: "28px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "11px",
              lineHeight: "15px",
              fontFamily: "Poppins-500",
              background: colors?.ButtonTextColor || "#ffffff",
              mt: "4px",
              textTransform: "none",
              width: "80px",
              padding: "0px",
              fontWeight: 500,
              "&:hover": { background: colors?.Accent || "#EDF2F5", opacity: '0.5' },
            }}
          >
            Learn more
          </Button>
        </Stack>
      </Box>
    );
  };

  const getCompanyLogo = (company: any) => {
    return (
      <Box
        sx={{
          width: "78px",
          minWidth: "78px",
          height: "78px",
          background: "#ffffff",
          objectFit: "contain",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
        }}
      >
        <Avatar
          src={
            company?.logo
              ? `${imageUrlPrefix}/${company?.logo}`
              : companyDefault
          }
          sx={{
            width: "76px",
            height: "76px",
            borderRadius: "0px",
            "&.MuiAvatar-root, & .MuiAvatar-img": {
              minHeight: "76px",
              borderRadius: "4px",
              objectFit: "contain",
              maxHeight: "76px",
              minWidth: "76px",
            },
          }}
        />
      </Box>
    );
  };

  const getCompanyName = (company: any) => (
    <Stack>
      <Typography
        className="ellipsis-one-line"
        variant="body2"
        sx={{
          fontSize: "19px",
          lineHeight: "27px",
          color: colors?.Text || "white",
          fontFamily: "Poppins-600",
          fontWeight: 600,
          maxWidth: "90%",
        }}
      >
        {company?.name}
      </Typography>

      <Typography
        className="ellipsis-one-line"
        variant="body2"
        sx={{
          fontSize: "14px",
          lineHeight: "20px",
          color: colors?.Text || "white",
          fontFamily: "Poppins-400",
          maxWidth: "90%",
        }}
      >
        {company?.industry?.name}
      </Typography>

      <Stack direction="row" columnGap="5px">
      <LoadingButton
          startIcon={<UnfollowIcon style={{ fill: colors?.ButtonTextColor || "#ffffff"}} />}
          loading={isLoader}
          sx={{
            borderRadius: "5px",
            height: "29px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
            lineHeight: "17px",
            fontFamily: "Poppins-500",
            background: colors?.Accent || "#0049EF",
            mt: "3px",
            textTransform: "none",
            width: "103px",
            padding: "0px",
            fontWeight: 500,
            color: colors?.ButtonTextColor || "#ffffff",
            "&:hover": {
              background: colors?.Accent || "#0049EF",
              opacity: '0.5'
            },
          }}
          onClick={() => handleUnfollow(company)}
        >
          {followButtonCaption}
        </LoadingButton>

        <Button
          startIcon={<WebsiteIcon style={{ fill: colors?.ButtonTextColor || "#203c6e"}} />}
          sx={{
            borderRadius: "5px",
            height: "29px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
            lineHeight: "17px",
            fontFamily: "Poppins-500",
            background:  colors?.Secondary || "#EDF2F5",
            mt: "3px",
            textTransform: "none",
            width: "103px",
            padding: "0px",
            fontWeight: 500,
            color: colors?.ButtonTextColor || "#203C6E",
            "&:hover": {
              background: colors?.Secondary || "#0049EF",
              opacity: '0.5'
            },
          }}
          onClick={() => handleWebsiteRedirect(company)}
        >
          website
        </Button>
      </Stack>
    </Stack>
  );

  const getCompanyInfo = (company: any) => (
    <Stack direction="row" spacing="10px" alignItems="flex-end">
      {getCompanyLogo(company?.company)}
      {getCompanyName(company?.company)}
    </Stack>
  );
  const getCompanyTeam = (company: any) => (
    <div
      style={{ position: "relative", display: "flex", flexDirection: "row" }}
    >
      <Grid
        rowGap="18px"
        columnGap="0px"
        columnSpacing="18px"
        container
        direction="row"
        sx={{ overflowX: "auto", flexWrap: "nowrap", mr: "30px" }}
        ref={contentWrapper}
      >
        {company?.assignedManagers?.map((item: any, index: number) => (
          <Grid key={index} item xs={6} md={4} lg={4}>
            <HostAndAttendee
              boothId={company?.booth.id}
              attendee={item}
              showMeetLater
              title=""
              viewType={true}
              isMeeting={true}
              showCallButton={true}
              isTeam
              requestCall={establishOneToOneCall}
              setMeetingAttendee={handleRequestMeeting}
             setOpenFeedbackPopup={()=>{}}/>
          </Grid>
        ))}
      </Grid>
      {company?.assignedManagers?.length > 3 && (
        <div
          className={classNames(
            "absolute right-[2px] bottom-[calc(50%-10px)] cursor-pointer h-[22px]",
            {
              hidden: !showArrows,
            }
          )}
          aria-label="right-arrow-target"
          onClick={() => sideScroll(contentWrapper.current!, 25, 190, 10)}
        >
          <ArrowRightIcon fillColor={colors?.ButtonTextColor || "#ffffff"} />
        </div>
      )}
    </div>
  );

  const getCompanyCapabilities = (companySpecialities: any) => (
    <Stack
      direction="column"
      rowGap="5px"
      sx={{ height: 'calc(57vh - 168px)', overflowY: "auto" }}
    >
      {companySpecialities?.map((speciality: any) => {
        return (
          <Box
            key={speciality?.id}
            sx={{
              borderRadius: "5px",
              background: colors?.Secondary || "#2F2F2F",
              height: "29px",
              minHeight: "29px",
              display: "flex",
              alignItems: "center",
              px: "16px",
            }}
          >
            <Typography
              variant="body2"
              className="ellipsis-one-line"
              sx={{
                fontSize: "12px",
                color: colors?.Text || "white",
                fontFamily: "Poppins-400",
                maxWidth: "100%",
              }}
            >
              {speciality?.name}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );

  return (
    <>
      <Stack
        sx={{
          overflowY: "auto",
          height: "calc(100vh - 100px)",
          alignItems: "start",
          pr : selectedSponsorDetails?.company?.companySpecialities?.length > 10 ? '6px' : '0px',
          mt: "20px",
          '&::-webkit-scrollbar-thumb': {
          backgroundColor: `${getBrandingColors().Accent}`,
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: `${getBrandingColors().Accent}`,
          opacity: 0.5,
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: colors?.Secondary,
        },
        }}
        className='branding-scroll'
      >
        {getCompanyInfo(selectedSponsorDetails)}

        {selectedSponsorDetails?.assignedManagers?.length > 0 && (
          <Stack sx={{ mt: "24px", width: "100%" }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
                color: colors?.Text || "white",
                fontFamily: "Poppins-500",
                maxWidth: "90%",
              }}
            >
              Team
            </Typography>

            <Divider
              sx={{
                borderColor: colors?.Secondary! || "#ffffff",
                width: "100%",
                opacity: "100%",
                mt: "11px",
                mb: "10px",
              }}
            />
            {getCompanyTeam(selectedSponsorDetails)}
          </Stack>
        )}

        {selectedSponsorDetails?.company?.companySpecialities?.length ? (
          <Stack sx={{ mt: "20px", width: "100%", pr: selectedSponsorDetails?.company?.companySpecialities?.length > 10 ? "10px" : "0px" }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
                color: colors?.Text || "white",
                fontFamily: "Poppins-500",
                maxWidth: "90%",
              }}
            >
              Core Capabilities
            </Typography>

            <Divider
              sx={{
                borderColor: colors?.Secondary! || "#ffffff",
                width: "100%",
                opacity: "100%",
                mt: "11px",
                mb: "10px",
              }}
            />
            {getCompanyCapabilities(
              selectedSponsorDetails?.company?.companySpecialities
            )}
          </Stack>
        ) : null}
      </Stack>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isMeetingScheduleModal}
        sx={{
          '@media (max-width: 768px)': {
            margin: "15px",
            marginTop: "5px"
          },
          "& .MuiDialog-paper": {
            minWidth: "400px",
            borderRadius: "10px",
            background: getBrandingColors()?.Main || "#2F2F2F",
            '@media (max-width: 768px)': {
              minWidth: "100%",
            },
            '@media (min-width: 1700px)': {
              maxWidth: "70vw",
            },
          },

          "& .MuiPaper-root": {
            borderRadius: "10px !important",
            "& .MuiCard-root": {
              background: "none",
              borderRadius: "10px !important"
            }
          }

        }}
      >

        <Stack sx={{
          flexDirection: "column",
          width: '100%',
          justifyContent: "space-between",
          position: 'relative',
          px: '18px',
          py: '15px',
        }}>

        <Typography
          className="ellipsis-one-line"
          variant="body2"
          sx={{
            fontSize: "16px",
            lineHeight: "20px",
            color: getBrandingColors()?.Text || "white",
            fontFamily: "Poppins-500",
            fontWeight: 500
          }}
        >
          Schedule a meeting
        </Typography>

        <IconButton
          onClick={() => {
            setIsMeetingScheduleModal(false);
          }}
          sx={{
            zIndex: "100000000",
            position: "absolute",
            textAlignLast: "end",
            right: 14,
            top: 8,
            alignItems: "flex-end",
            justifyContent: "flex-end",
            p: "2px",
          }}
        >
          <Crosscon style={{ stroke: getBrandingColors()?.Text || "#ffffff", width: '22px' }} />
        </IconButton>
        </Stack>
        <Divider sx={{ mx: '18px', borderColor: getBrandingColors()?.Text || "white", mb: '15px', opacity: '40%' }} />
        <MeetingRequestList
          personId={user?.id}
          meetingAttendee={meetingAttendee}
          setMeetingAttendee={setMeetingAttendee}
          setIsMeetingScheduleModal={setIsMeetingScheduleModal}
        />
      </Dialog>
    </>
  );
};

export default SponsorDetails;
