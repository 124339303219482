import { IEvent } from 'src/models/user-events';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import classNames from 'classnames';
import { EventWebsiteTemplateType } from 'src/mui/types/survey';
import { HyperLink } from '../../../shared-components';
import { PoweredByIcon } from '../../../shared-components/V2/IconSvg/PoweredByPanelistIcon/PoweredByPanelistIcon';

const WebsiteEventFooter = () => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  return (
    <div
      className={classNames('z-50 inset-x-0 bottom-0 lg:h-[28px] text-center', {
        'bg-white': event?.websiteTemplate === EventWebsiteTemplateType.DEFAULT,
        'bg-black':
          event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
      })}
    >
      <div className="max-w-[1124px] mx-auto lg:flex items-center justify-between px-[28px] lg:h-[28px]">
        <div className="flex items-center justify-center lg:justify-start">
          <div className=" py-4 lg:mr-40 lg:pr-5">
            {/* <img
              src={poweredbyPanelist}
              alt="gradient-background"
              className="object-cover w-full"
            /> */}
            <PoweredByIcon fillColor={event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS ? '#FFFFFF' : undefined} />
          </div>
        </div>
        <div className="lg:flex-row flex-col flex items-center gap-x-[50px] gap-y-[5px]">
          <HyperLink
            classes={classNames('font-normal text-[9px] hover:underline whitespace-nowrap', {
              'text-[#3C3C3C]':
                event?.websiteTemplate === EventWebsiteTemplateType.DEFAULT,
              'text-[#FFFFFF]':
                event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
            })}
            variant="FooterLink"
            text="User Agreement"
            href="/user-agreement"
          />
          <HyperLink
            classes={classNames('font-normal text-[9px] hover:underline whitespace-nowrap', {
              'text-[#3C3C3C]':
                event?.websiteTemplate === EventWebsiteTemplateType.DEFAULT,
              'text-[#FFFFFF]':
                event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
            })}
            variant="FooterLink"
            text="Privacy Policy"
            href="/privacy-policy.html"
          />
          <HyperLink
            classes={classNames('font-normal text-[9px] hover:underline whitespace-nowrap ', {
              'text-[#3C3C3C]':
                event?.websiteTemplate === EventWebsiteTemplateType.DEFAULT,
              'text-[#FFFFFF]':
                event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
            })}
            variant="FooterLink"
            text="Cookie Policy"
            href="/cookie-policy"
          />
          <HyperLink
            classes={classNames('font-normal text-[9px] hover:underline whitespace-nowrap', {
              'text-[#3C3C3C]':
                event?.websiteTemplate === EventWebsiteTemplateType.DEFAULT,
              'text-[#FFFFFF]':
                event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
            })}
            variant="FooterLink"
            text="Copyright Policy"
            href="/copyright-policy"
          />
          <HyperLink
            classes={classNames('font-normal text-[9px] hover:underline whitespace-nowrap', {
              'text-[#3C3C3C]':
                event?.websiteTemplate === EventWebsiteTemplateType.DEFAULT,
              'text-[#FFFFFF]':
                event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
            })}
            variant="FooterLink"
            text="Community Guidelines"
            href="/community-guidelines"
          />
          <HyperLink
            classes={classNames('font-normal text-[9px] hover:underline whitespace-nowrap', {
              'text-[#3C3C3C]':
                event?.websiteTemplate === EventWebsiteTemplateType.DEFAULT,
              'text-[#FFFFFF]':
                event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
            })}
            variant="FooterLink"
            text="About Us"
            href="/about"
          />
          <HyperLink
            classes={classNames('font-normal text-[9px] hover:underline whitespace-nowrap', {
              'text-[#3C3C3C]':
                event?.websiteTemplate === EventWebsiteTemplateType.DEFAULT,
              'text-[#FFFFFF]':
                event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
            })}
            variant="FooterLink"
            text="Help"
            href="https://help.panelist.com"
          />
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventFooter;
