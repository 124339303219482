import {
  format,
  formatDistance
} from 'date-fns';

import { IEventSession } from '../../models/event/event-session';

// Get formatted start and end times for sessions
export const getFormattedTime = (dateStr: string): string => {
  const date = new Date(dateStr);
  return (
    date.getHours().toString().padStart(2, '0') +
    ':' +
    date.getMinutes().toString().padStart(2, '0')
  );
};

// Get current and next session indices as an array
export const getSessionIndices = (sessions: IEventSession[]): number[] => {
  let currentDateTime: Date = new Date();
  for (let ix: number = 0; ix < sessions.length; ix++) {
    let startDate: Date = new Date(sessions[ix].startTime);
    let endDate: Date = new Date(sessions[ix].endTime);
    if (startDate > currentDateTime && endDate < currentDateTime) {
      return [ix, ix < sessions.length - 1 ? ix + 1 : ix];
    }
  }
  return [0, sessions.length > 1 ? 1 : 0];
};

export const monthName = (monthNumber: string | undefined) => {
  let eventMonth;
  if (monthNumber === '01') {
    eventMonth = 'Jan';
  }
  if (monthNumber === '02') {
    eventMonth = 'Feb';
  }
  if (monthNumber === '03') {
    eventMonth = 'Mar';
  }
  if (monthNumber === '04') {
    eventMonth = 'Apr';
  }
  if (monthNumber === '05') {
    eventMonth = 'May';
  }
  if (monthNumber === '06') {
    eventMonth = 'Jun';
  }
  if (monthNumber === '07') {
    eventMonth = 'Jul';
  }
  if (monthNumber === '08') {
    eventMonth = 'Aug';
  }
  if (monthNumber === '09') {
    eventMonth = 'Sep';
  }
  if (monthNumber === '10') {
    eventMonth = 'Oct';
  }
  if (monthNumber === '11') {
    eventMonth = 'Nov';
  }
  if (monthNumber === '12') {
    eventMonth = 'Dec';
  }
  return eventMonth;
};

export const getCurrentDate = (separator = '') => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${
    date < 10 ? `0${date}` : `${date}`
  }`;
};

export const getFirstAndLastDayOfWeek = () => {
  let curr = new Date();
  let first = curr.getDate() - curr.getDay();
  let last = first + 6;

  let firstday = new Date(curr.setDate(first)).toUTCString();
  let lastday = new Date(curr.setDate(last)).toUTCString();

  let firstdayOfWeek = format(new Date(firstday), 'yyyy-MM-dd');
  let lastdayOfWeek = format(new Date(lastday), 'yyyy-MM-dd');

  return { firstdayOfWeek, lastdayOfWeek };
};

export const getDateOfNextDay = () => {
  let tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  let tomorrow = format(tomorrowDate, 'yyyy-MM-dd');

  return tomorrow;
};

export const getFirstAndLastDayOfNextWeek = () => {
  let curr = new Date();
  let first = curr.getDate() - curr.getDay();
  let firstNext = first + 6;
  let lastNext = firstNext + 6;

  let firstdayNext = new Date(curr.setDate(firstNext)).toUTCString();
  let lastdayNext = new Date(curr.setDate(lastNext)).toUTCString();

  let firstdayOfNextWeek = format(new Date(firstdayNext), 'yyyy-MM-dd');
  let lastdayOfNextWeek = format(new Date(lastdayNext), 'yyyy-MM-dd');

  return { firstdayOfNextWeek, lastdayOfNextWeek };
};

export const getFirstAndLastDayOfMonth = () => {
  let curr = new Date();
  let first = new Date(curr.getFullYear(), curr.getMonth(), 1);
  let last = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);

  let firstdayOfMonth = format(new Date(first), 'yyyy-MM-dd');
  let lastdayOfMonth = format(new Date(last), 'yyyy-MM-dd');

  return { firstdayOfMonth, lastdayOfMonth };
};

export const getFirstAndLastDayOfNextMonth = () => {
  const curr = new Date();

  let first = new Date(curr.getFullYear(), curr.getMonth() + 1, 1);
  let last = new Date(curr.getFullYear(), curr.getMonth() + 2, 0);

  let firstdayOfNextMonth = format(new Date(first), 'yyyy-MM-dd');
  let lastdayOfNextMonth = format(new Date(last), 'yyyy-MM-dd');

  return { firstdayOfNextMonth, lastdayOfNextMonth };
};

export const getDistanceBetweenTwoDateTime = (from: any, to: any, includeSeconds: boolean = true) => {
  return formatDistance(from, to, { includeSeconds: includeSeconds });
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date: any) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('/');
}