/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { SearchIcon } from 'src/shared-components/V2/IconSvg';
import { EventsService } from 'src/services/events';
import { toast } from 'react-toastify';
import AssignList from './AssignList';

interface AssignModalProps {
  isOpen: boolean;
  onClose: () => void;
  events:any[];
  selected: any[];
  fetchAccountFiles: any
}

const AssignModal: React.FC<AssignModalProps> = ({
  isOpen,
  onClose,
  events,
  selected,
  fetchAccountFiles
}) => {
  const [search, setSearch] = useState('');
  const [assignStructure, setAssignStructure] = useState<any[]>([]);
  const [isLoader, setIsLoader] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSaveAndAdd = async () => {
    if (selected?.length) {
      const requestBody: any = selected.map((item: any) => ({
        companyAccountFileId: item?.id,
        eventSponsors: assignStructure || []
      }));
      setIsLoader(true);

      try {
        await new EventsService().assignedEvents(requestBody);
        onClose();
        await fetchAccountFiles();
      } catch (error) {
        console.error('Error while assigning events:', error);
      } finally {
        setIsLoader(false);
        toast.success('Assigned Successfully!');
      }
    }
  };

  return (
    <AlertModal isOpen={isOpen} onClose={onClose} className="w-576 p-2 container">
      <>
        <div className="text-md px-2 py-1 pb-4">Assign lists to events</div>
        <div className="px-2">
          <div className="px-3 flex items-center text-sm rounded-[5px] focus:outline-none w-full h-[35px] border-1 border-b-[#DEDEDE] font-extralight text-gray-1 bg-white">
            <SearchIcon />
            <input
              name="search"
              className="w-full ml-2 text-sm5 focus:outline-none block font-light text-gray-900 placeholder:text-gray-900 bg-transparent"
              placeholder="Search for a name"
              value={search}
              onChange={handleSearchChange}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="w-full h-420 overflow-auto">
          <AssignList search={search} events={events} selected={selected} onAssignChange={setAssignStructure} />
        </div>
        <div className="flex justify-end mt-4 px-4 gap-4">
          <button className="bg-gray-200 text-blue text-sm font-semibold px-3 py-1 rounded-[5px]" onClick={onClose}>
            Cancel
          </button>
          <button
            onClick={handleSaveAndAdd}
            disabled={isLoader}
            className="bg-[#0049EF] hover:bg-[#2e6eff] text-white text-sm font-semibold px-3 py-1 rounded-[5px]"
          >
            {isLoader ? 'Saving...' : 'Save and Continue'}
          </button>
        </div>
      </>
    </AlertModal>
  );
};

export default AssignModal;
