/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IAppState } from '../../../../store';
import { rewatchSession, setNextSession } from '../../../../store/liveEvent';
import VideoFooter from '../../video-and-audience-panel/video-and-expo-panel/video-footer';
import VideoPlayer from '../../video-and-audience-panel/video-and-expo-panel/video/video-player';

// Interface for the HLS.js Video component in order to call the seek method to skip around
interface VideoControls {
  seek(time: number): void;
}

interface IVideoSessionProps {
  onChangeSize?: Function;
  attendees?: React.ReactNode;
}

export const VideoSession = (props: IVideoSessionProps) => {
  const { roomId } = useParams();

  const livePolls = useSelector((state: IAppState) => state.livePoll);
  const currentSession = useSelector((state: IAppState) => state.liveEvent.session?.current);
  const nextSession = useSelector((state: IAppState) => state.liveEvent.session?.next);
  const onDemandRooms = useSelector((state: IAppState) => state.liveEvent.onDemandRooms);
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const [videoUrl, setVideoUrl] = useState<string | undefined>();
  const dispatch = useDispatch();
  const currentRoom = liveEvent.event?.rooms.find(room => room.id === roomId);

  // Ref to the HLS player component
  const videoPlayerRef = useRef<VideoControls>(null);

  // Video Controlls
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0.0);
  const [position, setPosition] = useState(0.0);
  const [volume, setVolume] = useState(60);
  const [fullScreen, setFullScreen] = useState(false);
  const [pip, setPip] = useState(false);
  const [screenMode, setScreenMode] = useState<'small' | 'large' | 'hidden' | undefined>('small');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState('');
  const isFutureEvent = (date: string) => +new Date(date) - +new Date() > 0;

  useEffect(() => {
    if (props.onChangeSize) props.onChangeSize(screenMode);
  }, [props, screenMode]);

  const tobeSession = currentSession || nextSession;
  useEffect(() => {
    if (onDemandRooms && currentSession) {
      const roomSession = onDemandRooms?.map(room => room.sessions).flat();
      const session = roomSession.find(session => session.id === currentSession?.id);
      if (session?.eventSessionVideos[0]?.videoUrl !== undefined) {
        setVideoUrl(`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${session?.eventSessionVideos[0]?.videoUrl}`);
      } else {
        // find the next session with a video
        const nextSession = roomSession.find(session => session.eventSessionVideos[0]?.videoUrl !== undefined);
        if (nextSession) {
          dispatch(rewatchSession(nextSession.id));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSession]);

  return (
    <>
      <VideoPlayer
        ref={videoPlayerRef}
        url={videoUrl! || currentRoom?.primaryHlsUrl!}
        isFullScreen={fullScreen}
        isPip={pip}
        isPlaying={isPlaying}
        volume={volume}
        // video url is not available if its a future event
        error={!(videoUrl || currentRoom?.primaryHlsUrl) && !isFutureEvent(tobeSession?.startTime!) ? 'Video not available. Check back later.' : ''}
        screenMode={screenMode}
        startTime={tobeSession?.startTime}
        endTime={tobeSession?.endTime}
        setIsPlaying={(play: boolean) => setIsPlaying(play)}
        setDuration={(dur: number) => setDuration(dur)}
        setPosition={(pos: number) => setPosition(pos)}
        setVolume={(vol: number) => setVolume(vol)}
        setNextVideo={() => dispatch(setNextSession())}
        polls={livePolls}
      />
      <VideoFooter
        play={() => setIsPlaying(true)}
        pause={() => setIsPlaying(false)}
        seek={(time: number) => videoPlayerRef.current?.seek(time)}
        isPlaying={isPlaying}
        duration={duration}
        position={position}
        volume={volume}
        errorURL={error}
        videoStatus="sessions"
        setVolume={(vol: number) => setVolume(vol)}
        fullScreen={fullScreen}
        setFullScreen={() => setFullScreen(!fullScreen)}
        setPip={() => setPip(!pip)}
        screenMode={screenMode}
        setScreenMode={(x: any) => setScreenMode(x)}
        attendees={props.attendees}
        showParticipant={() => { }}
      />
    </>
  );
};
