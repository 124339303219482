import classNames from 'classnames';
import { Button } from 'src/shared-components/V2';
import SpotlightImage from '../../../assets/images/spotlight-img.svg';

interface INoProductListProps {
  createButtonClass?: string;
  containerClass?: string;
  onClickButton?: Function;
  text: string;
  btnText?: string;
}
export const NoProductList = ({
  createButtonClass, containerClass, onClickButton, text, btnText
}: INoProductListProps) => (
  <div
    className={classNames(
      'bg-white m-auto',
      {
        [`${containerClass}`]: containerClass,
      }
    )}
  >
    <img src={SpotlightImage} alt="" className="mx-auto max-w-[230px] mb-3" />
    <div className="text-center text-lg mb-5 max-w-[300px]">
      {text}
      {' '}
      <br />
    </div>
    { onClickButton && btnText ? (
      <div className="flex items-center justify-center">
        <Button
          text={btnText}
          className={classNames(
            'bg-[#0049EF] hover:bg-[#2e6eff] rounded-[5px] text-white px-[19px] h-[32px] text-[13px] font-semibold flex items-center',
            {
              [`${createButtonClass}`]: createButtonClass,
            }
          )}
          type="button"
          onClick={() => onClickButton()}
        />
      </div>
    ) : <div className="h-[32px]" />}
  </div>
);
