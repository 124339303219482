export interface IArrowDownSvgIcon {
    className?: string;
    fillColor?: string;
}
export const ArrowDownSvgIcon = ({ className, fillColor }: IArrowDownSvgIcon) => {
  const iconColor = fillColor ? fillColor : "#203c6e"
    return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16.22" height="8.587" viewBox="0 0 16.22 8.587">
    <g id="Component_56" data-name="Component 56" transform="translate(-0.78 0.587)">
      <path id="Polygon_9" data-name="Polygon 9" d="M7.383.77a1,1,0,0,1,1.454,0L14.627,6.9A1,1,0,0,1,13.9,8.587H2.32A1,1,0,0,1,1.593,6.9Z" transform="translate(17 8) rotate(180)" fill={iconColor}/>
    </g>
  </svg>  
};