import {
  ActionCreator,
  Dispatch,
} from 'redux';
import { ThunkAction } from 'redux-thunk';

import {
  IUserProfile,
  IUserProfileResult,
} from '../../../models/user-profile';
import { UserProfileState } from '../../../models/UserProfileState';
import { UserService } from '../../../services';
import { UserProfileActionTypes } from './types';

export interface IUserProfileLoadingAction {
  type: UserProfileActionTypes.USER_PROFILE_LOADING;
  loading: boolean;
}

export interface IUserProfileSuccessAction {
  type: UserProfileActionTypes.USER_PROFILE_SUCCESS;
  payload: IUserProfile;
}

export interface IUserProfileErrorAction {
  type: UserProfileActionTypes.USER_PROFILE_ERROR;
  errorMessage: string;
}

export interface IUserProfileCleanupAction {
  type: UserProfileActionTypes.USER_PROFILE_CLEANUP;
}

export type UserProfileActions =
  | IUserProfileLoadingAction
  | IUserProfileSuccessAction
  | IUserProfileErrorAction
  | IUserProfileCleanupAction;

export const getUserProfile: ActionCreator<
  ThunkAction<Promise<any>, UserProfileState, null, IUserProfileSuccessAction>
> = (userIdOrSlug: string = '') => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();
    let result: IUserProfileResult | null = null;

    try {
      if (userIdOrSlug.trim() !== '')
        result = await userService.getUserProfile(userIdOrSlug);
      else result = await userService.getOwnProfile();

      dispatch({
        type: UserProfileActionTypes.USER_PROFILE_SUCCESS,
        payload: result.data,
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: UserProfileActionTypes.USER_PROFILE_ERROR,
        errorMessage: error.message,
      });
    } finally {
      dispatch({
        type: UserProfileActionTypes.USER_PROFILE_LOADING,
        loading: false,
      });
    }
  };
};

export const editUserProfile: ActionCreator<
  ThunkAction<Promise<any>, UserProfileState, null, IUserProfileSuccessAction>
> = (dataObj) => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();

    try {
      const result = await userService.editOwnProfile(dataObj);

      dispatch({
        type: UserProfileActionTypes.USER_PROFILE_SUCCESS,
        payload: result.data,
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: UserProfileActionTypes.USER_PROFILE_ERROR,
        errorMessage: error.message,
      });
    } finally {
      dispatch({
        type: UserProfileActionTypes.USER_PROFILE_LOADING,
        loading: false,
      });
    }
  };
};

export const loadUserProfile: ActionCreator<
  ThunkAction<any, UserProfileState, null, IUserProfileLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: UserProfileActionTypes.USER_PROFILE_LOADING,
    loading: shouldLoad,
  });

  export const cleanUserProfile: ActionCreator<
  ThunkAction<any, UserProfileState, null, IUserProfileLoadingAction>
> = () => (dispatch: Dispatch) =>
  dispatch({
    type: UserProfileActionTypes.USER_PROFILE_CLEANUP
  });
