import { useEffect, useState } from 'react';

export default function useCloseMenuWithClickOutside(ref?: any) {
  const [showDropdown, setShowDropdown] = useState(false);
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    window.addEventListener('mousedown', handleOutsideClick);
    return () => window.removeEventListener('mousedown', handleOutsideClick);
  }, [ref]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!showDropdown) return;

      if (event.key === 'Escape') {
        setShowDropdown(false);
      }
    };

    document.addEventListener('keyup', handleEscape);
    return () => document.removeEventListener('keyup', handleEscape);
  }, [showDropdown]);

  function handleDropdown(show: boolean) {
    setShowDropdown(!show);
  }

  return { showDropdown, handleDropdown };
}
