import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
    starBorderColor?: any;
    isActive?: boolean;
}

function LeadsTabIcon({ fillColor, fillBgColor, starBorderColor, isActive, ...props }: Props) {
    return isActive? (
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="25.502" viewBox="0 0 31 25.502">
          <g id="Group_29053" data-name="Group 29053" transform="translate(-8021.862 -4852.349)">
              <g id="Group_29050" data-name="Group 29050" transform="translate(685.705 -1078.521)">
                  <path id="star"
                        d="M.757,7.126,2.78,8.6l-.768,2.378a1.834,1.834,0,0,0,2.831,2.042L6.82,11.568,8.8,13.021a1.833,1.833,0,0,0,2.831-2.04L10.861,8.6l2.022-1.477A1.833,1.833,0,0,0,11.8,3.813H9.32l-.754-2.35a1.833,1.833,0,0,0-3.491,0l-.754,2.35H1.839A1.833,1.833,0,0,0,.76,7.126Z"
                        transform="translate(7353.521 5931.719)" fill={fillColor}/>
                  <path id="users_active" data-name="users active"
                        d="M9.563,15.476A5.738,5.738,0,1,1,15.3,9.738a5.738,5.738,0,0,1-5.738,5.738ZM17.852,29.5H1.275A1.275,1.275,0,0,1,0,28.227V27.59a9.563,9.563,0,1,1,19.127,0v.638A1.275,1.275,0,0,1,17.852,29.5Z"
                        transform="translate(7336.156 5926.87)" fill={fillColor}/>
              </g>
          </g>
      </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" width="23.871" height="25.168" viewBox="0 0 23.871 25.168">
    //     <g id="Group_20610" data-name="Group 20610" transform="translate(-364.561 -108.975)">
    //         <g id="Group_20609" data-name="Group 20609" transform="translate(371.907 108.975)">
    //             <path id="Path_14744" data-name="Path 14744" d="M14.465,0A8.262,8.262,0,1,1,6.2,8.262,8.262,8.262,0,0,1,14.465,0" transform="translate(-6.203 0)" fill={fillColor}/>
    //             <path id="Path_14745" data-name="Path 14745"
    //                   d="M8.043,19.637a.709.709,0,0,0,.577-.309,6.56,6.56,0,0,1,10.685,0,.712.712,0,0,0,.585.311h1.548a.72.72,0,0,0,.716-.728.706.706,0,0,0-.088-.341,9.264,9.264,0,0,0-16.214.014.717.717,0,0,0,.632,1.053Z"
    //                   transform="translate(-5.769 5.529)" fill={fillColor}/>
    //         </g>
    //         <g id="Page-1" transform="translate(365.091 113.396) rotate(-2)">
    //             <g id="Dribbble-Light-Preview" transform="translate(0 0)">
    //                 <g id="icons">
    //                     <path id="star_favorite-_1499_" data-name="star_favorite-[#1499]" d="M9.9,6.883a1.059,1.059,0,0,0-.309.941l.382,2.208a1.069,1.069,0,0,1-1.556,1.121l-2-1.043a1.082,1.082,0,0,0-1,0l-2,1.043a1.069,1.069,0,0,1-1.556-1.121L2.25,7.824a1.059,1.059,0,0,0-.308-.941L.325,5.32A1.062,1.062,0,0,1,.919,3.505l2.235-.322A1.07,1.07,0,0,0,3.96,2.6l1-2.009a1.076,1.076,0,0,1,1.923,0l1,2.009a1.07,1.07,0,0,0,.807.582l2.235.322a1.062,1.062,0,0,1,.594,1.814Z" fill={fillColor} stroke={starBorderColor} strokeWidth="1" fillRule="evenodd" />
    //                     </g>
    //                 </g>
    //             </g>
    //         </g>
    //     </svg>
    ): (
      <svg xmlns="http://www.w3.org/2000/svg" width="30.995" height="25.703" viewBox="0 0 30.995 25.703">
        <g id="Group_29053" data-name="Group 29053" transform="translate(-8021.867 -4852.249)">
          <g id="Group_29050" data-name="Group 29050" transform="translate(685.705 -1078.521)">
            <path id="users"
                  d="M9.563,15.476A5.738,5.738,0,1,1,15.3,9.738a5.738,5.738,0,0,1-5.738,5.738Zm0-8.926a3.188,3.188,0,1,0,3.188,3.188A3.188,3.188,0,0,0,9.563,6.55Zm9.563,21.677V27.59A9.563,9.563,0,0,0,0,27.59v.638a1.275,1.275,0,0,0,2.55,0V27.59a7.013,7.013,0,0,1,14.026,0v.638a1.275,1.275,0,1,0,2.55,0Z"
                  transform="translate(7336.261 5926.87)" fill={fillColor} stroke="#fff" stroke-width="0.2"/>
            <path id="star"
                  d="M.757,7.126,2.78,8.6l-.768,2.378a1.834,1.834,0,0,0,2.831,2.042L6.82,11.568,8.8,13.021a1.833,1.833,0,0,0,2.831-2.04L10.861,8.6l2.022-1.477A1.833,1.833,0,0,0,11.8,3.813H9.32l-.754-2.35a1.833,1.833,0,0,0-3.491,0l-.754,2.35H1.839A1.833,1.833,0,0,0,.76,7.126Z"
                  transform="translate(7353.521 5931.719)" fill={fillColor}/>
          </g>
        </g>
      </svg>
    )
}

export default LeadsTabIcon;
