/* eslint-disable no-console */
/* eslint-disable max-len */
import React from 'react';
import {
  Typography, Stack,
} from '@mui/material';
import { RHFTextField } from 'src/mui/components/hook-form';
import PTextField from 'src/mui/components/hook-form/PTextField';

export const NameList = () => (

  <Stack sx={{ width: '100%' }}>
    <Typography
      color="text.primary"
      variant="subtitle2"
      sx={{
        fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
      }}
    >
      Name your account list
    </Typography>
    <RHFTextField placeholder="Name your account list" name="accountListName" className="dropdown-container" required />
    <Typography
      color="text.primary"
      variant="subtitle2"
      sx={{
        fontSize: '15px', fontWeight: 500, lineHeight: '20px', mt: '40px', mb: '8px', fontFamily: 'Poppins-500'
      }}
    >
      Enter an account list description (optional)
    </Typography>
    <PTextField
      name="accountListDescription"
      type="text"
      inputProps={{ maxLength: 250 }}
      rows={4}
      fullWidth
      multiline
      placeholder="E.g. account to follow up with by the end of Q4"
      sx={{ backgroundColor: 'white', }}
    />
  </Stack>

);

export default NameList;
