import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkButton } from '../../../shared-components';
import { IAppState } from '../../../store';
import { setSelectedPackageType } from '../../../store/packages/actions';
import { packages } from './package-data';

const packageTypes = ['Professional', 'Team', 'Corporate', 'Enterprise', 'Custom'];

export const Packages = () => {
  const [selectedPackageName, setSelectedPackageName] = useState('Professional');

  const packagesRef = useRef<any>({});

  const setIndex = (index: number) => {
    const tab = packagesRef.current[index];

    if (tab) {
      // focus() will call the state setter
      // to display the associated tabpanel
      tab.focus();
    }
  };

  const dispatch = useDispatch();
  const selectedPackage = useSelector((state: IAppState) => state.setSelectedPackage);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    e.preventDefault();
    setIndex(index);
    setSelectedPackageName(packagesRef.current[index].textContent);

    dispatch(setSelectedPackageType(packageTypes[index]));
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const PackagesNavbar = () => (
    <div
      className="flex flex-row justify-center z-10"
      style={{ borderBottom: '1px solid #E3E3E3' }}
    >
      {packageTypes.map((packageType, i) => (
        <div key={`package-${packageType}`}>
          <button
            type="button"
            className={`bg-white border-0 text-center text-md mr-16 ${packageType === selectedPackageName
              ? ' text-blue-1 mt-1 h-8 border-b-2 border-blue-1 z-20 '
              : ' text-blue-2 mt-2 mb-1 '}`}
            ref={e => {
              packagesRef.current[i] = e;
            }}
            onClick={e => {
              handleClick(e, i);
            }}
          >
            {packageType}
          </button>
        </div>
      ))}
    </div>
  );

  const packageSelected = packages.find(p => p.packageType === selectedPackageName);

  return (
    <div className="container mx-auto mt-32 pt-1">
      <PackagesNavbar />
      <div className="mt-12 flex flex-col items-center">
        {selectedPackageName !== 'Custom' && (
          <div>
            <LinkButton
              text="▶︎ Create an event"
              href="#"
              textColor="#172746"
              backgroundColor="transparent"
              width="168px"
              height="30px"
              classes="text-2xl border-2 border-blue-3"
              fontSize="14px"
            />
          </div>
        )}
        <div className="mt-2">
          <div className="text-md text-gray-blue-3 font-extralight">
            {packageSelected?.packagePrice}
          </div>
        </div>
        <div className="mx-auto">
          <div className="z-10 mt-2 lg:w-1076 2xl:w-10/12 mx-auto">
            <img className="" src={packageSelected?.imageUrl} alt="" />
          </div>
        </div>
        <div className="mt-2 flex flex-row justify-between">
          <div className="flex flex-col mr-24">
            <div className="text-md text-center font-medium">{packageSelected?.mode}</div>
            <div className="text-x-sm text-center font-light w-48">
              {packageSelected?.modeDescription}
            </div>
          </div>
          <div className="flex flex-col mr-24">
            <div className="text-md text-center font-medium">{packageSelected?.duration}</div>
            <div className="text-x-sm text-center font-light w-40">
              {packageSelected?.durationDescription}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-md text-center font-medium">
              {packageSelected?.registrantsCount}
            </div>
            <div className="text-x-sm text-center font-light w-64">
              {packageSelected?.registrantsDescription}
            </div>
          </div>
        </div>
        <div className="mt-4">
          {selectedPackage.selectedPackage === 'Professional' ? (
            <LinkButton
              text="▶︎ Create a free event"
              href="#"
              textColor="#fff"
              backgroundColor="#285cb2"
              classes="text-2xl"
              width="207px"
              height="29px"
            />
          ) : (
            packageSelected?.packageType !== 'Custom' && (
              <LinkButton
                text={`▶︎ Build your package with "${selectedPackage.selectedPackage}"`}
                href="#"
                textColor="#fff"
                backgroundColor="#285cb2"
                classes="text-2xl"
                width="330px"
                height="29px"
              />
            )
          )}
        </div>
        <div className={`${selectedPackageName === 'Custom' ? 'mt-16' : 'mt-6'}`}>
          <div className="flex flex-row">
            {packageTypes.map((packageType, i) => (
              <button
                type="button"
                key={`tab-button-${i}`}
                className="mr-1 text-xl8"
                onClick={e => {
                  handleClick(e, i);
                }}
              >
                {packageType === selectedPackageName ? '●' : '○'}
              </button>
            ))}
          </div>
        </div>
        {packageSelected?.packageType === 'Custom' && (
          <div className="z-20 absolute mt-72">
            <LinkButton
              text="Contact Sales"
              href="/contact-us"
              textColor="#fff"
              backgroundColor="#285cb2"
              classes="text-2xl"
              width="139px"
              height="29px"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Packages;
