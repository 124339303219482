import { Reducer } from 'redux';

import { UserEventsState } from '../../../models/UserEventsState';
import { UserEventsActions } from './actions';
import { UserEventsActionTypes } from './types';

const initialState: UserEventsState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const UserEventsReducer: Reducer<UserEventsState, UserEventsActions> = (
  state = initialState,
  action
) => {
  
  switch (action.type) {
    case UserEventsActionTypes.USER_EVENTS_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case UserEventsActionTypes.USER_EVENTS_SUCCESS: {
      
      return {
        ...state,
        value: action.payload
      };
    }

    case UserEventsActionTypes.USER_EVENTS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
