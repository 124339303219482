export const FACTORSTAB = {
  OVERVIEW: 'Overview',
  EXPERIENCE: 'Experience',
  PROGRAM: 'Program',
  SPEAKERS: 'Speakers',
  SPONSORS: 'Sponsors',
  CONTACTUS: 'Contact us',
  AUDIENCES_2024: '2024 Audiences'
};

export const FACTORSTABS = [FACTORSTAB.OVERVIEW, FACTORSTAB.EXPERIENCE, FACTORSTAB.CONTACTUS];

export const FACTORSTABSURVEY = [FACTORSTAB.OVERVIEW, FACTORSTAB.CONTACTUS];
