import { SvgIconProps } from '@mui/material/SvgIcon';

export const CropFullScreenIcon = (props: SvgIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.184" height="18.184" viewBox="0 0 18.184 18.184" {...props}>
  <g id="fullscreen" transform="translate(1.5 1.5)">
    <path id="Path_338" data-name="Path 338" d="M0,4.888V0H4.687" transform="translate(0 15.183) rotate(-90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3"/>
    <path id="Path_341" data-name="Path 341" d="M0,4.888V0H4.687" transform="translate(15.184 0) rotate(90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3"/>
    <path id="Path_339" data-name="Path 339" d="M1278,821.833v-4.888h4.687" transform="translate(-1277.9 -816.945)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3"/>
    <path id="Path_340" data-name="Path 340" d="M0,4.888V0H4.687" transform="translate(15.083 15.183) rotate(180)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3"/>
  </g>
</svg>

  );
};
