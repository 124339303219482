import classNames from 'classnames';
import { IEventSponsor } from '../../../models/event/event-sponsor';
import { Logo } from '../../../shared-components/V2/Logo';

interface IWebsiteEventPartnerList {
  sponsors: any;
}

const WebsiteEventPartnerList = ({
  sponsors
}: IWebsiteEventPartnerList) => (
  <div
    className={
      classNames(
        'pt-[10px]',
      )
    }
  >
    <div className="">
      {
        sponsors && <div className="text-[14px] text-[#4d144a] font-semibold h-[20px] mb-2.5">Company</div>
      }
      {sponsors && <div className="flex mb-2.5 last:mb-0">
          <div className="w-full">
            <div className="flex gap-[10px]">

              <Logo src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${sponsors?.logo}`} className="w-[49px] h-[49px] object-contain bg-[#111] p-[2px] small-radius" />

              <div className="flex flex-col justify-start">
                <div className="text-[13px] leading-[16px] text-[#4d144a] font-semibold pt-[3px]">
                  {sponsors?.name}
                </div>
                <div className="text-[11px] leading-[13px] text-[#4d144a] font-normal">
                  <a href={sponsors?.website}>{sponsors?.website}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
);

export default WebsiteEventPartnerList;
