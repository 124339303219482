import {
    Box,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { _bankingContacts } from "src/mui/_mock";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "src/store";
import {
    createVoteForPollAns,
} from "src/store/liveEvent";
import { getPolls, getPollsByAnswerResult } from "src/store/livePoll";
import { IPoll } from "src/models/poll/poll";

interface Props {
    pollQuestion:any;
    pollsOnVideo?:boolean
}

export const PollQuestion = ({ pollQuestion ,pollsOnVideo}: Props) => {
    const currentSession = useSelector((state: IAppState) => state.liveEvent.session?.current);

    const dispatch = useDispatch();
    // redux state
    const liveEvent = useSelector((state: IAppState) => state.liveEvent);

    const calculateTimeLeft = () => {
        const endTime = liveEvent?.event?.endTime;
        if (!endTime) {
            return null;
        }

        const difference = +new Date(endTime) - +new Date();

        if (difference <= 0) {
            return null;
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);

        if (days >= 1) {
            return days === 1 ? `${days} day` : `${days} days`;
        }

        if (hours >= 1) {
            return hours === 1 ? `${hours} hour` : `${hours} hours`;
        }

        if (minutes >= 1) {
            return minutes === 1 ? `${minutes} minute` : `${minutes} minutes`;
        }

        return null;
    };

    const handleCreateOrRemoveVote = async (alreadyVotedForAns: number, eventSessionId: string, answerId: string, QuestionId: string) => {
      await  dispatch(createVoteForPollAns(alreadyVotedForAns, liveEvent?.event?.id!, eventSessionId, QuestionId, { answerId }));
    //   await    dispatch(getPollsByAnswerResult(liveEvent?.event?.id!, eventSessionId!, QuestionId));
    await     dispatch(getPolls(liveEvent?.event?.id!, currentSession?.id!));



    }

    const isVotedForPollAnswer = (pollingAnswers: any) => {
        return pollingAnswers.find((answer: any) => answer.isVoted);
    }

    const displayPollQuestion = (pollQuestion: any, pollsOnVideo?: boolean) => {
        // Calculate total votes for the question
        const totalVotes = pollQuestion?.answers?.reduce((sum: number, ans: any) => sum + ans?.votes, 0);
        const isVotedForAnswer = isVotedForPollAnswer(pollQuestion?.answers);
        const timeLeft = calculateTimeLeft();
        return (
            <Box key={pollQuestion?.id} sx={{ cursor: 'pointer', boxShadow: 'none !important' }}>
                <Stack>
                    <Typography
                        sx={{
                            textTransform: 'capitalize',
                            transition: (theme) =>
                                theme.transitions.create('opacity', {
                                    duration: theme.transitions.duration.shortest,
                                }),
                            fontSize: '15px',
                            fontFamily: 'Poppins-500',
                            lineHeight: '20px',
                            color: 'white',
                            mb: '10px',
                            textAlign: 'left'
                        }}
                    >
                        {pollQuestion?.question}
                    </Typography>

                    <Stack
                        direction="column"
                        alignItems="flex-start"
                        justifyItems="center"
                        spacing="8px"
                    >
                        {pollQuestion?.answers?.map((ans: any, index: number) => {
                            return (
                                < div key={ans?.id
                                } style={{ width: '100%' }}>
                                    <Box
                                        sx={{
                                            borderRadius: '5px',
                                            background: ans?.isVoted ? "#03FFCD" : pollsOnVideo ? "#ffffff" : '#2F2F2F',
                                            px: '10px',
                                            height: '30px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent:"space-between"
                                        }}
                                        onClick={() => { if (!isVotedForAnswer) handleCreateOrRemoveVote(ans?.isVoted, pollQuestion?.eventSessionId, ans?.id, pollQuestion?.id) }}
                                    >
                                        <Tooltip title={ans?.answer}>
                                            <Typography
                                                sx={{
                                                    fontSize: '13px !important',
                                                    fontFamily: 'Poppins-500',
                                                    lineHeight: '20px',
                                                    color: ans?.isVoted ? "#000000" : pollsOnVideo ? '#000000' : '#ffffff',
                                                }}
                                                className="ellipsis-one-line"
                                            >
                                                {ans?.answer}
                                            </Typography>
                                        </Tooltip>
                                     {  isVotedForAnswer  && <Typography
                                            sx={{
                                                fontSize: '13px !important',
                                                fontFamily: 'Poppins-400',
                                                lineHeight: '20px',
                                                color: ans?.isVoted ? "#000000" : pollsOnVideo ? '#000000' : '#ffffff',
                                                fontWeight:400
                                            }}
                                        >
                                       {parseInt(ans?.percent || ans?.votePercent!)} %
                                        </Typography>}
                                    </Box>
                                </div>
                            )
                        })}
                    </Stack>
              {!pollsOnVideo &&  <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ mt: '7px' }}
                    >
                        <Typography
                            sx={{
                                fontSize: '12px !important',
                                fontFamily: 'Poppins-400',
                                lineHeight: '14px',
                                color: 'white',
                                mt: '0px',
                                mr: '10px'
                            }}
                        >
                            {totalVotes} Votes  { timeLeft ? `• ${timeLeft} Left` : null} 
                        </Typography>
                        {isVotedForAnswer && (
                            <IconButton
                                sx={{
                                    bgcolor: '#2F2F2F',
                                    borderRadius: '4px',
                                    p: '5px',
                                }}

                                onClick={() => handleCreateOrRemoveVote(isVotedForAnswer?.isVoted, pollQuestion?.eventSessionId, isVotedForAnswer?.id, pollQuestion?.id)}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '10px !important',
                                        fontFamily: 'Poppins-400',
                                        lineHeight: '12px',
                                        color: 'white',
                                    }}
                                >
                                    Undo
                                </Typography>
                            </IconButton>
                        )}
                    </Stack>}
                </Stack>
            </Box >
        );
    };

    return (
       <> {displayPollQuestion(pollQuestion, pollsOnVideo)}</>

    );
};

