import {
  createSlice,
  Dispatch,
  PayloadAction
} from '@reduxjs/toolkit';

import { IBaseResult } from '../../../models/base-result';
import { IUserProfile } from '../../../models/user-profile';
import { UserService } from '../../../services';

export type UsersResult = {
  users: IBaseResult<IUserProfile>;
};

export type UserSearchResult = {
  data: UsersResult;
};

export type UserSearchState = {
  loading: boolean;
  searchResult: UserSearchResult | null;
  errorMessage: string;
};

const initialState: UserSearchState = {
  loading: false,
  searchResult: null,
  errorMessage: ''
};

export const userSearchSlice = createSlice({
  name: 'userSearch',
  initialState,
  reducers: {
    userSearchLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUserSearchResult: (state, action: PayloadAction<UserSearchResult>) => {
      state.searchResult = action.payload;
    },
    userSearchError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.searchResult = null;
      state.errorMessage = action.payload;
    }
  }
});

export const { userSearchLoading, setUserSearchResult, userSearchError } = userSearchSlice.actions;

export const searchParticipant = (username: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(userSearchLoading(true));
    const userService = new UserService();
    const result = await userService.searchUser(username);
    dispatch(setUserSearchResult(result));
  } catch (error: any) {
    console.error(error);
    dispatch(userSearchError(error));
  } finally {
    dispatch(userSearchLoading(false));
  }
};
