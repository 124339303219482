import { memo } from 'react';
// @mui
import { Box, BoxProps, useTheme } from '@mui/material';

function PanelistEventManager({ color, ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" width="297" height="91.166" viewBox="0 0 297 91.166">
        <g id="Group_19684" data-name="Group 19684" transform="translate(-94 -46.31)">
          <text
            id="Event_Manager"
            data-name="Event Manager"
            transform="translate(276.653 132.476)"
            fill={(color as string) || (PRIMARY_MAIN as string)}
            fontSize="15"
            fontFamily="Poppins-Regular, Poppins"
          >
            <tspan x="0" y="0">
              Event Manager
            </tspan>
          </text>
          <g id="Logotype-ColourPositive" transform="translate(94 46.31)">
            <path
              id="Path_10785"
              data-name="Path 10785"
              d="M297.509,7.08a4.1,4.1,0,0,0-4.05,4.148V70.465a4.184,4.184,0,0,0,1.973,3.743,3.961,3.961,0,0,0,4.147,0,4.183,4.183,0,0,0,1.973-3.743V11.228A4.1,4.1,0,0,0,297.509,7.08Z"
              transform="translate(-86.049 -7.08)"
              fill={(color as string) || (PRIMARY_MAIN as string)}
            />
            <path
              id="Path_10786"
              data-name="Path 10786"
              d="M318.4,31.24a4,4,0,0,0-2.871,1.225,4.214,4.214,0,0,0-1.179,2.952V77.1a4.184,4.184,0,0,0,1.973,3.743,3.962,3.962,0,0,0,4.147,0,4.182,4.182,0,0,0,1.973-3.743V35.417a4.21,4.21,0,0,0-1.176-2.95A4,4,0,0,0,318.4,31.24Z"
              transform="translate(-92.175 -13.719)"
              fill={(color as string) || (PRIMARY_MAIN as string)}
            />
            <path
              id="Path_10787"
              data-name="Path 10787"
              d="M340.94,44.079c0-4.01,3.534-6.933,9.513-6.933,5.273,0,8.722,2.4,10.029,5.99a4.033,4.033,0,0,0,3.1,2.745,3.948,3.948,0,0,0,3.828-1.485,4.192,4.192,0,0,0,.552-4.161c-2.495-6.382-8.728-10.1-17.238-10.1-10.849,0-17.881,6.295-17.881,13.961,0,18.624,28.822,12.14,28.822,23.28,0,4.1-3.534,7.39-9.965,7.39-5.654,0-9.464-2.473-10.892-6.041a3.885,3.885,0,0,0-3.612-2.43h-.169a3.989,3.989,0,0,0-3.4,1.984,4.205,4.205,0,0,0-.132,4.013c2.933,5.8,9.647,9.515,18.326,9.515,10.764,0,17.79-6.208,17.79-14.418C369.224,49.467,340.94,55.951,340.94,44.079Z"
              transform="translate(-97.598 -13.416)"
              fill={(color as string) || (PRIMARY_MAIN as string)}
            />
            <path
              id="Path_10788"
              data-name="Path 10788"
              d="M408.027,33.248a3.38,3.38,0,0,0,3.336-3.394h0a3.381,3.381,0,0,0-3.336-3.423h-9.386V17.96a4.182,4.182,0,0,0-1.972-3.743,3.961,3.961,0,0,0-4.147,0,4.184,4.184,0,0,0-1.973,3.743V62.728c0,10.037,4.891,13.692,13.521,13.692h3.866a3.407,3.407,0,0,0,3.1-1.709,3.6,3.6,0,0,0,0-3.609,3.407,3.407,0,0,0-3.1-1.709H405.4c-4.982,0-6.764-1.733-6.764-6.665V33.248Z"
              transform="translate(-114.519 -8.88)"
              fill={(color as string) || (PRIMARY_MAIN as string)}
            />
            <path
              id="Path_10789"
              data-name="Path 10789"
              d="M97.043,30.837a25.1,25.1,0,0,0-22.9,19.377,25.863,25.863,0,0,0,11.4,28.121A24.436,24.436,0,0,0,115.08,75.33v1.61a4.089,4.089,0,0,0,8.177,0V56.379a25.786,25.786,0,0,0-1.279-8.1A24.954,24.954,0,0,0,97.043,30.837Zm1.293,42.73A17.194,17.194,0,1,1,115.08,56.382,16.973,16.973,0,0,1,98.336,73.567Z"
              transform="translate(-21.524 -13.598)"
              fill={(color as string) || (PRIMARY_MAIN as string)}
            />
            <path
              id="Path_10790"
              data-name="Path 10790"
              d="M171.519,30.8a21.547,21.547,0,0,0-15.447,6.559,22.7,22.7,0,0,0-6.392,15.85V76.982a4.044,4.044,0,1,0,8.086,0V53.209a14.189,14.189,0,0,1,6.78-12.482,13.446,13.446,0,0,1,13.926,0,14.189,14.189,0,0,1,6.78,12.482V76.982a4.2,4.2,0,0,0,1.187,2.936,3.991,3.991,0,0,0,2.863,1.212h0a4.1,4.1,0,0,0,4.043-4.148V53.209C193.352,40.835,183.578,30.8,171.519,30.8Z"
              transform="translate(-43.89 -13.598)"
              fill={(color as string) || (PRIMARY_MAIN as string)}
            />
            <path
              id="Path_10791"
              data-name="Path 10791"
              d="M24.928,30.8A24.6,24.6,0,0,0,7.3,38.288,25.907,25.907,0,0,0,0,56.379v44.29a4.141,4.141,0,0,0,4.089,4.025,4.141,4.141,0,0,0,4.089-4.025V75.329A24.436,24.436,0,0,0,38.08,78.1,25.891,25.891,0,0,0,48.892,49.361,25.03,25.03,0,0,0,24.928,30.8Zm0,42.788a17.194,17.194,0,0,1,.014-34.376A16.973,16.973,0,0,1,41.7,56.379,17.418,17.418,0,0,1,36.786,68.54a16.536,16.536,0,0,1-11.858,5.027Z"
              transform="translate(0 -13.598)"
              fill={(color as string) || (PRIMARY_MAIN as string)}
            />
            <path
              id="Path_10792"
              data-name="Path 10792"
              d="M242.118,30.8a25.17,25.17,0,0,0-24.645,21.67,27.267,27.267,0,0,0-.134,6.759,25.636,25.636,0,0,0,1.682,6.759A25.055,25.055,0,0,0,238.2,81.641a24.584,24.584,0,0,0,22.968-8.763,4.287,4.287,0,0,0,.564-4.472,4.086,4.086,0,0,0-3.709-2.418,3.965,3.965,0,0,0-3.081,1.45,16.492,16.492,0,0,1-26.7-1.45,17.12,17.12,0,0,1-2.636-6.759h38.667a2.815,2.815,0,0,0,2.778-2.85,27.281,27.281,0,0,0-.282-3.909A25.164,25.164,0,0,0,242.118,30.8ZM225.813,52.47a16.651,16.651,0,0,1,32.61,0Z"
              transform="translate(-63.686 -13.598)"
              fill={(color as string) || (PRIMARY_MAIN as string)}
            />
          </g>
        </g>
      </svg>
    </Box>
  );
}

export default memo(PanelistEventManager);
