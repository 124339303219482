import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

import { IEvent } from "../../models/event/event";
import { IColorStyle, ITargetAudiences } from "../../models/user-events";
import { IUserProfile } from "../../models/user-profile";
import { UserService } from "../../services";
import { EventsService } from "../../services/events";

export type WebsiteState = {
  event?: IEvent;
  targetAudiences?: ITargetAudiences;
  registered?: boolean | null;
  loaded: boolean;
  colorStyles?: IColorStyle[];
  survey?: any;
};

const initialState: WebsiteState = {
  registered: null,
  loaded: false,
};

export const websiteSlice = createSlice({
  name: "event-website",
  initialState,
  reducers: {
    setEvent: (state, action: PayloadAction<IEvent>) => {
      state.event = action.payload;
    },
    setColorStyle: (state, action: PayloadAction<IColorStyle[]>) => {
      state.colorStyles = action.payload;
    },
    setTargetAudiences: (state, action: PayloadAction<ITargetAudiences>) => {
      state.targetAudiences = action.payload;
    },
    setRegistered: (state, action: PayloadAction<boolean | null>) => {
      state.registered = action.payload;
    },
    setLoaded: (state) => {
      state.loaded = true;
    },
    setSurvey: (state, action: PayloadAction<any>) => {
      state.survey = action.payload;
    }
  },
});

export const { setEvent, setColorStyle,setTargetAudiences, setRegistered, setLoaded, setSurvey } =
  websiteSlice.actions;

export const init =
  (eventId: string) =>
  async (dispatch: Dispatch) => {
    const eventsService = new EventsService();
    try {
      const event = await eventsService.getEventAsGuest(eventId);
      if (event?.data?.rooms) {
        event.data.rooms = event.data.rooms.map(room => {
            const sortedSessions = room.sessions.map(session => {
                const sortedSpeakers = session.eventSpeakers.sort((a, b) => a?.ordering! - b?.ordering!);
                return {
                    ...session,
                    eventSpeakers: sortedSpeakers
                };
            });
            return {
                ...room,
                sessions: sortedSessions
            };
        });
    }
      dispatch(setEvent(event.data));
      const colorStyles =await eventsService.getColorStyles()
      dispatch(setColorStyle(colorStyles.data));
      const audiences = await eventsService.getTargetAudiences(eventId);
      dispatch(setTargetAudiences(audiences.data));
      dispatch(setLoaded());
    } catch (error) {
        console.log(error);
        dispatch(setLoaded());

      }
  };

  export const initSurvey =
  (surveyId: string) =>
  async (dispatch: Dispatch) => {
    const eventsService = new EventsService();
    const survey = await eventsService.getSurveyAsGuest(surveyId);
    dispatch(setSurvey(survey.data));
    const colorStyles =await eventsService.getColorStyles()
    dispatch(setColorStyle(colorStyles.data));

    dispatch(setLoaded());
  };

  export const checkRegistration =
  (eventId: string, user: IUserProfile | undefined) =>
  async (dispatch: Dispatch) => {
    if (user) {
      const joinData = await new UserService().getEventsJoin(eventId);
      if (joinData.data.some((eventInfo: any) => eventInfo.slug === eventId)) {
        dispatch(setRegistered(true));
      } else {
        dispatch(setRegistered(false));
      }
    }
    dispatch(setLoaded());
  };

export const utmAnalytics =
  (eventId: string, payload: any, guest?: string) =>
  async (dispatch: Dispatch) => {
    try {
      const eventService = new EventsService();
      if (guest) {
        await eventService.guestUtmAnalytics(eventId, payload);
      } else {
        await eventService.userUtmAnalytics(eventId, payload);
      }
    } catch (error) {
      console.error(error);
    }
  };
