/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { IEventSession } from '../../../models/event/event-session';
import { Logo } from '../../../shared-components/V2/Logo';
import { dateAndTimeWithTz } from '../../../utils/date-timezone';
import { getTimeZone } from '../../../utils/timezones';
import { ParseContent } from './parse-html';
import WebsiteEventSpeakerList from './website-event-day-speakerlist';
import WebsiteEventPartnerList from './website-event-day-partnerlist';
import { CardMedia, Stack } from '@mui/material';

import LocationImage from 'src/assets/images/box-webiste/location-blue.png';

interface IWebsiteEventCard {
  roomQty?: number;
  session: IEventSession;
  event?: any;
  setSelectedSessoin?: (value: any) => void,
  setIsSessionModal?: (value: boolean) => void,
}

interface ISessionModal {
  session: any;
  event?: any;
}

export const SessionModal = ({
  session, event
}: ISessionModal) => {

  const getTimeZoneName = (zone: string) => {
    const shortTimeZone = getTimeZone(zone)?.[0]?.abbr ? getTimeZone(zone)?.[0]?.abbr : zone;
    return shortTimeZone;
  };

  const eventDateTimeWithTz = useMemo(() => {
    const startTime = dateAndTimeWithTz(session?.startTime, session?.timezone);
    const endTime = dateAndTimeWithTz(session?.endTime, session?.timezone);
    return {
      startTime,
      endTime
    };
  }, [session?.startTime, session?.endTime, session?.timezone]);

  const eventDateTimeLocalTz = useMemo(() => {
    const startTime = format(parseISO(session?.startTime), 'hh:mm aa')?.replace('.', '')?.replace(' ', '')?.replace('.', '');
    const endTime = format(parseISO(session?.endTime), 'hh:mm aa')?.replace('.', '')?.replace(' ', '')?.replace('.', '');
    const timeZoneMatched = new Date(eventDateTimeWithTz.startTime.dateAndTime).getMinutes()

      === new Date(parseISO(session?.startTime)).getMinutes()

      && new Date(eventDateTimeWithTz.startTime.dateAndTime).getHours()

      === new Date(parseISO(session?.startTime)).getHours();

    return {
      startTime,
      endTime,
      timeZoneMatched
    };
  }, [session?.startTime, session?.endTime]);
  return (
    <div className="bg-white rounded-[12px] w-full mx-auto">
      <div className="flex items-center px-[55px] py-[20px] overflow-y-auto">
        {/* <div className="w-[137px] h-[78px] mr-[20px]">
          <Logo
            src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${session?.eventSpeakers[0]?.photo ? session?.eventSpeakers[0]?.photo : event?.company?.logo}`}
            className={classNames('w-full h-full object-cover bg-black rounded-5', {
              'p-4 !object-contain': !session?.eventSpeakers[0]?.photo
            })}
          />
        </div> */}
        <div className="flex flex-col w-full">
          {/* <div className="text-[13px] text-[#767676] mb-[3px] line-clamp-1 helvetica-neue-45-light leading-[14px]">
            <span className="text-[13px] text-[#767676] helvetica-neue-45-light">
              {eventDateTimeWithTz?.startTime?.time}
            </span>
            {' '}
            -
            {' '}
            <span className="text-[13px] text-[#767676] mr-[3px] helvetica-neue-45-light">
              {eventDateTimeWithTz?.endTime?.time}
            </span>
            {getTimeZoneName(event?.timezone)}
          </div> */}
          <div className="text-[28px] text-[#151f26] leading-[36px] mb-[20px] font-medium line-clamp-1 bold-lato-font">{session?.title}</div>
          {session?.summary &&
            (
              <div className="w-full">
                <div className="text-[16px] text-[#151f26] leading-[28px] mb-[10px] normal-lato-font">
                  <ParseContent content={session?.summary} fullText className="text-[16px] text-[#151f26] leading-[28px] mb-[10px] normal-lato-font" />
                </div>
              </div>
            )}

          <Stack direction="row" mb="30px">
            <CardMedia
              src={LocationImage}
              component={'img'}
              sx={{
                width: '18px',
                height: '22px',
                mr: '7px',
                objectFit: 'contain',
                '&.MuiCardMedia-root': {
                  objectFit: 'contain'
                }
              }}
            />
            <div>
              <div
                className={classNames('text-[16px] leading-[22px] text-[#141f26a6] normal-lato-font mb-[6px] flex text-left justify-left items-center font-bold capitalize flex-wrap', {
                  hidden: !eventDateTimeWithTz.startTime.date,
                })}
              >
                {eventDateTimeWithTz.startTime.dateLongFormat}
                <span className="mx-[3px] leading-[22px]"> • </span>
                <span className="uppercase leading-[22px]">
                  {eventDateTimeWithTz?.startTime?.time}
                </span>
                -
                <span className="text-[16px] leading-[22px] normal-lato-font uppercase mr-[3px]">{eventDateTimeWithTz.endTime.time}</span>
                {getTimeZoneName(session?.timezone)}
              </div>
              {!eventDateTimeLocalTz.timeZoneMatched && (
                <div
                  className={classNames('text-[15px] leading-[22px] text-[#141f26a6] md:flex text-left justify-left font-normal capitalize break-words flex-wrap', {
                    hidden: !eventDateTimeLocalTz?.startTime,
                  })}
                >
                  <span className="text-[16px] leading-[22px] normal-lato-font">
                    {format(parseISO(session.startTime), 'MMMM dd, yyyy')}
                  </span>
                  <span className="mx-[3px] text-[16px] leading-[22px] normal-lato-font"> • </span>
                  <span className="uppercase text-[16px] leading-[22px] normal-lato-font">
                    {eventDateTimeLocalTz.startTime}
                  </span>
                  -
                  <span className="uppercase mr-[3px] text-[16px] leading-[22px] normal-lato-font">{eventDateTimeLocalTz.endTime}</span>
                  <span>(Your Local Time)</span>
                </div>
              )}
              {event?.location && (
                <div
                  className={classNames('text-[15px] leading-[22px] text-[#141f26a6] md:flex text-left justify-left font-normal capitalize break-words flex-wrap', {
                  })}
                >
                  {event?.location}
                </div>
              )}
            </div>
            {/* <Typography
            variant="body2"
            sx={{
              fontSize: '20px',
              mb: '30px',
              color: '#141f26a6',
              lineHeight: '34px',
              flexFlow: 'wrap',
              display: 'flex'
            }}
            className='normal-lato-font'
          >
            {event.eventRegions.map((item, index) => (
              <div style={{flexWrap: 'nowrap', display: 'flex'}}>{item.name} {index !== event.eventRegions.length - 1 && <span style={{margin: '0 8px'}}>|</span>}</div>
            ))}
          </Typography> */}
          </Stack>
        </div>
      </div>

      <div className="flex px-[55px] pt-[20px] pb-[65px] bg-[#f2f2f291] rounded-b-[12px] overflow-y-auto">
        <div
          className={classNames({
            'mt-0': session?.eventSpeakers.length > 0,
            hidden: session?.eventSpeakers.length === 0
          })}
        >
          <WebsiteEventSpeakerList speakers={session?.eventSpeakers} />
        </div>
        <div
          className={classNames({
            'mt-0': session?.eventSponsors.length > 0,
            hidden: session?.eventSponsors.length === 0
          })}
        >
          <WebsiteEventPartnerList sponsors={session?.eventSponsors} />
        </div>
      </div>
    </div>
  );
};

const WebsiteEventCard = ({
  roomQty, session, event, setSelectedSessoin, setIsSessionModal
}: IWebsiteEventCard) => {
  const numbers = [];
  const [_innerWidth, setInnerWidth] = useState(window.innerWidth);
  const eventDateTimeWithTz = useMemo(() => {
    const startTime = dateAndTimeWithTz(session?.startTime, event?.timezone);
    const endTime = dateAndTimeWithTz(session?.endTime, event?.timezone);
    return {
      startTime,
      endTime
    };
  }, [event?.timezone, session?.endTime, session?.startTime]);

  // const getTimeZoneName = (zone: string) => {
  //   const shortTimeZone = getTimeZone(zone)?.[0]?.abbr ? getTimeZone(zone)?.[0]?.abbr : zone;
  //   return shortTimeZone;
  // };

  // Loop to generate the sequence
  for (let i = 1; i <= 100; i++) {
    numbers.push(i * 3);
  }

  const onSessionClick = (session: any) => {
    if (setSelectedSessoin) setSelectedSessoin(session);
    if (setIsSessionModal) setIsSessionModal(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const truncatedText = session?.title.length > 50 ? `${session?.title.substring(0, 50)}…` : session?.title;
  return (
    <div
      className={classNames('flex w-full bg-[#222222] h-[141px] rounded-10 cursor-pointer p-[20px]', {})}
      onClick={() => onSessionClick(session)}
    >
      <div className="w-[138px] min-w-[138px] h-[90px] rounded-10 relative mr-2.5">
        <Logo
          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${session?.eventSpeakers[0]?.photo ? session?.eventSpeakers[0]?.photo : event?.company?.logo}`}
          className={classNames('w-full h-full object-cover bg-black rounded-5', {
            // 'p-4 !object-contain grayscale': !session?.eventSpeakers[0]?.photo
          })}
        />
        <div className="text-[18px] flex items-center justify-center text-white helvetica-neue-65-medium w-[138px] h-[32px] rounded-5 bg-[#2D2D2D] absolute left-0 -bottom-[10px] uppercase">{session?.type}</div>
      </div>
      {/* <div className="text-[20px] px-[30px] font-[700] text-[#7c7c7c] helvetica-neue-65-medium">{roomQty}</div> */}
      <div className="relative xl:w-full">
        <div className="text-[#7C7C7C] text-[30px] tracking-tight mb-[7px] line-clamp-1 helvetica-neue-65-medium leading-[29px] uppercase">
          <span className="text-[#7C7C7C] text-[30px] tracking-tight">
            {eventDateTimeWithTz?.startTime?.time}
          </span>
          -
          <span className="text-[#7C7C7C] text-[30px] mr-[3px] helvetica-neue-65-medium">
            {eventDateTimeWithTz?.endTime?.time}
          </span>
          {/* {getTimeZoneName(event?.timezone)} */}
        </div>
        <div className="text-white text-[15px] tracking-tight leading-[22px] mb-[5px] helvetica-neue-65-medium line-clamp-2">
          {truncatedText}
          {' '}
          {session?.title.length > 50 ? <div className="text-[#00FFCD] inline">See more</div> : null}
        </div>
        <div className="text-[#8E8E8E] text-[15px] tracking-tight line-clamp-1 helvetica-neue-65-medium leading-[17px]">
          {session?.eventSpeakers[0]?.firstName}
          {' '}
          {session?.eventSpeakers[0]?.lastName}
          {session?.eventSpeakers[0]?.company?.name ? ' • ' : ''}
          {session?.eventSpeakers[0]?.company?.name}
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventCard;
