import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useOnClickOutside } from "usehooks-ts";
import { useNavigate } from "react-router-dom";

import { IUserProfile } from "../../../../models/user-profile";
import { IAppState } from "../../../../store";
import { getUpcomingEvent } from "../../../../store/events/upcoming-event/actions";
import { getTickets } from "../../../../store/ticket";
import { useCurrentUser } from "../../../../utils/hooks";
import { resetUserEmail } from "../../../../store/checkUserEmail";
import { delAuthToken } from "../../../../utils";
import { UserSignUpActionTypes } from "../../../../store/user/signup/types";
import { GotoList } from "src/screens/event/registration/event-components/goto-list";
import { CalendarList } from "src/screens/event/registration/event-components/calendar-list";
import RegistrationStepForm from "../reg-dialog-flow";
import SurveyDialog from "../../survey/SurveyDialog";
import {
  EventQuestionPageTypes,
  useSurveyContext,
} from "src/mui/providers/GeneralContext";
import { CircularProgress, Dialog, Stack } from "@mui/material";
import { setRegistered } from "src/store/event-website";
export const hideScrollbarY = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
} as const;

const EventRegistration = (props: { eventId: string; isBanner?: boolean, isBoxRegister?: boolean }) => {
  const ref = useRef(null);
  const [page, setPage] = useState(0);
  const [logoutUser, setLogoutUser] = useState(false);
  const [button, setButton] = useState({ button: "", text: "" });
  const [showModal, setShowModal] = useState(false);
  const { getQuestionPages, setEventIdParam, surveyPageLoader } =
    useSurveyContext();

  const { checkUserEmail } = useSelector((state: IAppState) => state);
  const currentStep = useSelector(
    (state: IAppState) => state.signupUser.currentStep
  );
  const { event, registered } = useSelector(
    (state: IAppState) => state.website
  );

  const dispatch = useDispatch();

  const { upcomingEvent, eventTickets, signupUser } = useSelector(
    (state: IAppState) => state
  );

  const loggedInUser = useCurrentUser();
  const [user, setUser] = useState<IUserProfile>();
  const [openForm, setOpenForm] = useState(false);

  const navigate = useNavigate();
  const changePage = (page: number) => {
    // page = 1 is login
    // page = 2 is signup & join
    // page = 3 is join
    setPage(page);
  };

  const [openTicketModal, setOpenTicketModal] = useState(false);

  const eventInvitationStatus =
    upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus;

  useEffect(() => {
    setUser(loggedInUser);
  }, [loggedInUser, signupUser?.eventRegistration]);

  useEffect(() => {
    if (user) {
      // dispatch(getUpcomingEvent(props.eventId));
      dispatch(getTickets(props.eventId));
    }
  }, [props.eventId, dispatch, user]);

  useEffect(() => {
    if (event?.id) {
      setEventIdParam(event?.id!);
      if (user) {
        getQuestionPages(EventQuestionPageTypes.REGISTRATION, event?.id!);
        getQuestionPages(EventQuestionPageTypes.SURVEY, event?.id!);
      }
    }
  }, [event?.id, user]);
  const closeModal = () => {
    setPage(0);
    setShowModal(false);
  };

  const closeModalUsingButton = () => {
    setPage(0);
    setShowModal(false);
    // window.location.reload();
  };

  const handleQueryParams = useCallback((search: string) => {
    const getQueryParams = (search: string) => {
      const queryParams = new URLSearchParams(search);
      const params: Record<string, string> = {};
      const entries: [string, string][] = Array.from(queryParams.entries());
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of entries) {
        params[key] = value;
      }
      return params;
    };

    const queryParams = getQueryParams(search);
    const { eventFrom } = queryParams;
    if (eventFrom === 'register') {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    const delay = 500;
    const timer = setTimeout(() => {
      handleQueryParams(location.search);
    }, delay);
    return () => clearTimeout(timer);
  }, [location, handleQueryParams]);

  const resetForm = () => {
    sessionStorage.removeItem("userName");
    changePage(0);
    dispatch({
      type: UserSignUpActionTypes.GO_TO_FIRST_STEP,
    });
    dispatch(resetUserEmail());
  };

  const signoutClickHandler = () => {
    delAuthToken();
    resetForm();
    setLogoutUser(false);
    setUser(undefined);
  };

  const getUserEmail = () => sessionStorage.getItem("userName") || "";
  const handleRegistration = () => {
    // if (!registered) 
    setShowModal(true);
    // if (!registered && event?.isTicketed && user) setOpenTicketModal(true);
    // if (user && registered) navigate(`/event/${event?.slug}/discussion`);
    // if (
    //   user &&
    //   registered === false &&
    //   event?.type === "invitation" &&
    //   eventInvitationStatus === "accepted"
    // )
    //   navigate(`/event/${event?.slug}/discussion`);
  };

  const handleLobby = () => {
    if (user && registered === false && event?.type === "invitation")
      navigate(`/event/${event?.slug}/discussion`);
  };

  const handleClickOutside = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const eventType = event?.type

    if (user) {
      // invitation based event

      if (eventType === "invitation") {
        if (eventInvitationStatus === "accepted") {
          setButton({ button: "surveyForm", text: "Portal" })
        }

        else if (eventInvitationStatus === "rejected" || eventInvitationStatus === "canceled") {
          setButton({ button: "Register Now", text: "Register Now" })
        }

        else if (eventInvitationStatus === "pending") {
          setButton({ button: "Register Now", text: "under Review" })
        }

        else if (registered && eventInvitationStatus === null) {
          setButton({ button: "Register Now", text: "under Review" })
        }

        // }
        //  user not registered
        else if (!registered) {
          setButton({ button: "Register Now", text: "Register Now" })
        }
      }
      //  public event
      else if (eventType === "open") {
        // if (registered) {
        if (registered) {
          setButton({ button: "surveyForm", text: "Portal" })
        }
        else if (!registered) {
          setButton({ button: "Register Now", text: "Register Now" })
        }

      }
    }

    else if (!user) {
      setButton({ button: "Register Now", text: "Register Now" })
    }
  }, [user, registered, eventInvitationStatus, event?.type, loggedInUser]);

  useOnClickOutside(ref, handleClickOutside);
  return (
    <>
      {
        button.text === "under Review"
          ? (
            <div className="flex flex-col sm:flex-row items-center gap-5 mt-[9px]">
              <div className="flex-shrink-0">
                {props.isBoxRegister ? (
                  <GotoList
                    fillColor={event?.colorStyle?.values?.colorButton || 'balck'}
                    titleClass="text-[16px] font-semibold text-black tracking-wide mr-[10px] bold-lato-font"
                    btnClassName="text-black bg-white rounded-[24px] h-[48px] px-[15px] flex items-center"
                    title="Go to"
                    dropdownClass="w-[113px] rounded-10 top-[41px]"
                    buttonTheme={{
                      background: event?.colorStyle?.values?.backgroundButton,
                      color: event?.colorStyle?.values?.colorButton,
                    }}
                    isInvitation
                  />
                ) : (
                  <GotoList
                    fillColor="white"
                    titleClass="text-[13px] font-semibold text-white tracking-wide mr-[10px]"
                    btnClassName="text-white bg-[#0049EF] rounded-[5px] h-[30px] px-[15px] flex items-center"
                    title="Go to"
                    dropdownClass="w-[113px] rounded-10"
                    buttonTheme={{
                      background: event?.colorStyle?.values?.backgroundButton,
                      color: event?.colorStyle?.values?.colorButton,
                    }}
                    isInvitation
                  />
                )}
                
              </div>
              <div className="flex-shrink-0">
                {props.isBoxRegister ? (
                  <CalendarList
                    fillColor="black"
                    titleClass="text-[16px] font-semibold text-black tracking-wide mr-[10px] bold-lato-font"
                    btnClassName="text-black bg-white rounded-[24px] h-[48px] px-[15px] flex items-center"
                    title="Registration under review"
                    buttonTheme={{
                      background: event?.colorStyle?.values?.backgroundButton,
                      color: event?.colorStyle?.values?.colorButton,
                    }}
                    isInvitation
                  />
                ) : (
                  <CalendarList
                    fillColor="white"
                    titleClass="text-[13px] font-semibold text-white tracking-wide mr-[10px]"
                    btnClassName="text-white bg-[#0049EF] rounded-[5px] h-[30px] px-[15px] flex items-center"
                    title="Registration under review"
                    buttonTheme={{
                      background: event?.colorStyle?.values?.backgroundButton,
                      color: event?.colorStyle?.values?.colorButton,
                    }}
                    isInvitation
                  />
                )}
                
              </div>
              <div className="flex-shrink-0">
                {props.isBoxRegister ? (
                  <button
                    type="button"
                    className="see-event-btn"
                    style={{
                      background: event?.colorStyle?.values?.backgroundButton,
                      color: event?.colorStyle?.values?.colorButton,
                    }}
                    aria-haspopup="true"
                    onClick={() => handleRegistration()}
                  >
                    Under Review
                  </button>
                ) : (
                  <button
                    type="button"
                    className="text-white bg-[#0049EF] rounded-[5px] h-[30px] px-[15px] flex items-center"
                    style={{
                      background: event?.colorStyle?.values?.backgroundButton,
                      color: event?.colorStyle?.values?.colorButton,
                    }}
                    aria-haspopup="true"
                    onClick={() => handleRegistration()}
                  >
                    <span
                      className="text-[13px] font-semibold text-white tracking-wide"
                    >
                      Under Review
                    </span>
                    {' '}
                  </button>
                )}
                
              </div>
            </div>
          ) :
          button.button === "surveyForm" ?

            <div className="flex flex-col sm:flex-row items-center gap-5 mt-[9px]">
              <div className="flex-shrink-0">
                {props.isBoxRegister ? (
                  <GotoList
                    fillColor={event?.colorStyle?.values?.colorButton || 'balck'}
                    titleClass="text-[16px] font-semibold text-black tracking-wide mr-[10px] bold-lato-font"
                    btnClassName="text-black bg-white rounded-[24px] h-[48px] px-[15px] flex items-center"
                    title="Go to"
                    dropdownClass="w-[113px] rounded-10 top-[41px]"
                    buttonTheme={{
                      background: event?.colorStyle?.values?.backgroundButton,
                      color: event?.colorStyle?.values?.colorButton,
                    }}
                  />
                ) : (
                  <GotoList
                    fillColor={event?.colorStyle?.values?.colorButton || 'white'}
                    titleClass="text-[13px] font-semibold text-white tracking-wide mr-[10px]"
                    btnClassName="text-white bg-[#0049EF] rounded-5 h-[30px] px-[15px] flex items-center"
                    title="Go to"
                    dropdownClass="w-[113px] rounded-10"
                    buttonTheme={{
                      background: event?.colorStyle?.values?.backgroundButton,
                      color: event?.colorStyle?.values?.colorButton,
                    }}
                  />
                )}
                
              </div>
              <div className="flex-shrink-0">
              {props.isBoxRegister ? (
                  <CalendarList
                    fillColor={event?.colorStyle?.values?.colorButton || 'black'}
                    titleClass="text-[16px] font-semibold text-black tracking-wide mr-[10px] bold-lato-font"
                    btnClassName="text-black bg-white rounded-[24px] h-[48px] px-[15px] flex items-center"
                    title="Add to calendar"
                    buttonTheme={{
                      background: event?.colorStyle?.values?.backgroundButton,
                      color: event?.colorStyle?.values?.colorButton,
                    }}
                  />
                ) : (
                  <CalendarList
                    fillColor={event?.colorStyle?.values?.colorButton || 'white'}
                    titleClass="text-[13px] font-semibold text-white tracking-wide mr-[10px]"
                    btnClassName="text-white bg-[#0049EF] rounded-5 h-[30px] px-[15px] flex items-center"
                    title="Add to calendar"
                    buttonTheme={{
                      background: event?.colorStyle?.values?.backgroundButton,
                      color: event?.colorStyle?.values?.colorButton,
                    }}
                  />
                )}
              </div>
              <div className="flex-shrink-0">

                <div className="flex-shrink-0">
                  {props.isBoxRegister ? (
                    <button
                      type="button"
                      className="see-event-btn"
                      aria-haspopup="true"
                      onClick={() => setShowModal(true)}
                    >
                      Portal
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="text-white bg-[#0049EF] rounded-5 h-[30px] px-[15px] flex items-center"
                      style={{
                        background: event?.colorStyle?.values?.backgroundButton,
                        color: event?.colorStyle?.values?.colorButton,
                      }}
                      aria-haspopup="true"
                      onClick={() => setShowModal(true)}
                    >
                      <span
                        className="text-[13px] font-semibold text-white tracking-wide"
                        style={{
                          background: event?.colorStyle?.values?.backgroundButton,
                          color: event?.colorStyle?.values?.colorButton,
                        }}
                      >
                      Portal
                      </span>
                      {' '}
                    </button>
                  )}
                  
                </div>
              </div>
            </div>
            :
            <div className="flex-shrink-0">
              {props.isBoxRegister ? (
                <button
                  type="button"
                  className="see-event-btn"
                  aria-haspopup="true"
                  onClick={() => handleRegistration()}
                >
                 {button?.text || "Register Now"}
                </button>
              ) : (
                <button
                  type="button"
                  className="text-white bg-[#0049EF] rounded-5 h-[30px] px-[15px] flex items-center"
                  style={{
                    background: event?.colorStyle?.values?.backgroundButton,
                    color: event?.colorStyle?.values?.colorButton,
                  }}
                  aria-haspopup="true"
                  onClick={() => handleRegistration()}
                >
                  <span
                    className="text-[13px] font-semibold text-white tracking-wide"
                    style={{
                      background: event?.colorStyle?.values?.backgroundButton,
                      color: event?.colorStyle?.values?.colorButton,
                    }}
                  >
                    {button?.text || "Register Now"}
                  </span>
                  {' '}
                </button>
              )}
              {/* <button
                  type="button"
                  className={classNames(
                    "bg-[#0049EF] md:px-[18px] px-[12px] h-[29px] rounded-[10px] text-[12px] leading-[18px] text-white tracking-wide mobile-nav-btn",
                    {
                      " text-[16px] h-[34px] rounded-[10px] !px-[45px] hover:opacity-90 mobile-nav-banner-btn":
                        props.isBanner,
                    }
                  )}
                  onClick={() => handleRegistration()}
                  style={{
                    background: event?.colorStyle?.values?.backgroundButton,
                    color: event?.colorStyle?.values?.colorButton,
                  }}
                >
     
                  {button?.text || "Register Now"}
                </button> */}
            </div>
      }

      {registered === false &&
        !props.isBanner &&
        event?.type === "invitation" &&
        eventInvitationStatus === "accepted" && (
          <button
            type="button"
            className="bg-[#0326E5] md:px-[18px] px-[12px] h-[29px] rounded-[5px] text-[12px] leading-[18px] text-white tracking-wide"
            onClick={() => handleLobby()}
            style={{
              background: event?.colorStyle?.values?.backgroundButton,
              color: event?.colorStyle?.values?.colorButton,
            }}
          >
            Event Lobby
          </button>
        )}

      {showModal && (
        <RegistrationStepForm
          setUser={setUser}
          eventId={event?.id!}
          user={user}
          open={showModal}
          registered={registered!}
          eventInvitationStatus={eventInvitationStatus!}
          eventType={event?.type!}
          setOpenForm={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default EventRegistration;
