import { FC } from 'react';
import classNames from 'classnames';
import { Avatar } from '../Avatar';

export interface ICardProfileProps {
  avatar: string;
  name: string;
  jobTitle: string;
  company: string;
  className?: string;
  status?: 'ONLINE' | 'OFFLINE';
  detailsHref?: string;
  onUserClick?: (p: string | undefined) => void;
  onJobTitleClick?: () => void;
  onCompanyClick?: () => void;
}

export const CardProfile: FC<ICardProfileProps> = ({
  avatar,
  name,
  jobTitle,
  company,
  className = '',
  status,
  detailsHref,
  onUserClick,
  onJobTitleClick,
  onCompanyClick
}) => (
  <div 
    className={classNames("w-full flex", {
      [`${className}`]: className
    })}
  >
    <div aria-label="card-profile-avatar" className="cursor-pointer" onClick={() => onUserClick!(detailsHref)}>
      <Avatar
        alt={name}
        src={avatar}
        className=""
        size="LARGE"
        status={status}
      />
    </div>

    <div className="flex items-center">
      <div className="ml-[15px] text-left">
        <div className="h-[21px]">
          <span 
            className="cursor-pointer text-[15px] text-[#000000] font-semibold capitalize line-clamp-1 leading-[21px]" 
            onClick={() => onUserClick!(detailsHref)}
            aria-label="card-profile-name"
          >
            {name}
          </span>
        </div>
        <div className="h-[20px]">
          <span 
            className="cursor-pointer text-[14px] font-normal text-[#3C3C3C] leading-[20px] flex line-clamp-1"
            onClick={() =>onJobTitleClick? onJobTitleClick!(): null}
            aria-label="card-profile-jobtitle"
          >
            {jobTitle}
          </span>
        </div>
        <div className="h-[21px]">
          <span 
            className="cursor-pointer text-[15px] text-[#000000] font-semibold capitalize line-clamp-1 leading-[21px]" 
            onClick={() => onCompanyClick!()}
            aria-label="card-profile-company"
          >
            {company}
          </span>
        </div>
      </div>
    </div>
  </div>
);
