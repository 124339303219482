import {
  ICastVoteResult,
  ICreatePoll,
  IPollCreated,
  IPollResult,
} from '../../models/poll/poll';
import BaseService from '../BaseService';

export class PollService extends BaseService {
  async createPoll(poll: ICreatePoll) {
    const extraOptions = this.getAuthToken();

    return this.post<IPollCreated>('/polls', poll, extraOptions);
  }

  async editPoll(poll: ICreatePoll, pollId:String) {
    const extraOptions = this.getAuthToken();
    return this.put<IPollCreated>(`/polls/${pollId}`, poll, extraOptions);
  }

  async getPoll(pollId: string) {
    const extraOptions = this.getAuthToken();

    return this.get<IPollResult>(`/polls/${pollId}`, extraOptions);
  }

  async castVote(pollId: string, answerId: string) {
    const extraOptions = this.getAuthToken();

    return this.post<ICastVoteResult>(
      `/polls/${pollId}/answers/${answerId}/votes`,
      {},
      extraOptions
    );
  }

  async removeVote(pollId: string, answerId: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<ICastVoteResult>(
      `/polls/${pollId}/answers/${answerId}/votes`,
      extraOptions
    );
  }
}
