import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { IConnectionRequests } from '../../models/nav-bar/connection-requests/connection-requests';
import { UserService } from '../../services';

export type ConnectionRequestsState = {
  errorMessage: string;
  connectionRequests: IConnectionRequests[];
  loading: boolean;
};

type ConnectionRequestMessage = {
  instance: IConnectionRequests;
  message: string;
  previousAction: string;
};

const initialState: ConnectionRequestsState = {
  errorMessage: '',
  loading: false,
  connectionRequests: []
};

export const connectionRequestsSlice = createSlice({
  name: 'connectionRequests',
  initialState,
  reducers: {
    connectionRequestsError: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setConnectionRequests: (state, action: PayloadAction<IConnectionRequests[]>) => {
      state.connectionRequests = action.payload;
    },
    newConnectionRequest: (state, action: PayloadAction<ConnectionRequestMessage>) => {
      const existingRequest = state.connectionRequests.find(
        (req) => req.id === action.payload.instance.id
      );
      if (!existingRequest) {
        state.connectionRequests.push(action.payload.instance);
      }
    },
    acceptConnectionRequest: (state, action: PayloadAction<ConnectionRequestMessage>) => {
      state.connectionRequests = state.connectionRequests.filter(
        (req) => action.payload.instance.userId !== req.userId
      );
    },
    rejectConnectionRequest: (state, action: PayloadAction<ConnectionRequestMessage>) => {
      state.connectionRequests = state.connectionRequests.filter(
        (req) => action.payload.instance.id !== req.id
      );
    }
  }
});

export const {
  connectionRequestsError,
  setConnectionRequests,
  newConnectionRequest,
  acceptConnectionRequest,
  rejectConnectionRequest
} = connectionRequestsSlice.actions;

export const getConnectionRequests = () => async (dispatch: Dispatch) => {
  try {
    const userService = new UserService();
    const result = await userService.getConnectRequests();
    dispatch(setConnectionRequests(result.data));
  } catch (e: any) {
    dispatch(connectionRequestsError(e.message));
  }
};