import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useEffect, useState } from "react";
import { ChimeService } from "../../services/ChimeService";


interface IChimeLoaderProps {
    chimeConfig?: any,
    children: JSX.Element;
}

export const ChimeLoader = ({chimeConfig, children}: IChimeLoaderProps) => {
    const [currentMeetingId, setCurrentMeetingId] = useState();
    const meetingManager = useMeetingManager();
    useEffect(() => {
        return () => { if (meetingManager) meetingManager.leave(); }
    }, []);
    const connectToChime = async (config: any) => await new ChimeService()
        .loginToMeeting(config, meetingManager, false)
        .catch(err => console.error('@@ Chime service failed with error', err, config));

    useEffect(() => {
        if (!!chimeConfig?.MeetingId && chimeConfig?.MeetingId !== currentMeetingId) {
            connectToChime(chimeConfig).then(() => setCurrentMeetingId(chimeConfig.MeetingId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chimeConfig, currentMeetingId]);

    return children;
}