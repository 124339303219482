import { Stack, Grid, CardMedia, Container } from '@mui/material';

import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { IEvent } from 'src/models/event/event';
import { getMinifiedImage } from 'src/mui/utils/image';

type HeaderProps = {
  coverUrl?: string;
}

const menuList = [
  {name: 'Overview', id: '#overview'},
  {name: 'Speakers', id: '#speakers'},
  {name: 'Agenda', id: '#agenda'},
  {name: 'Highlights', id: '#highlights'},
  {name: 'Partners', id: '#partners'},
  {name: 'Contact us', id: '#contactus'},
]

const menuListSurvey = [
  {name: 'Overview', id: '#overview'},
  {name: 'Contact us', id: '#contactus'},
]

export const HeaderSection = ({ coverUrl }: HeaderProps) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const survey: any = useSelector((state: IAppState) => state.website.survey!);

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        maxWidth: '100%',
        p: 0,
        pl: '15px !important',
        pr: '15px !important',
        '@media (min-width: 768px)': { maxWidth: '750px' },
        '@media (min-width: 992px)': { maxWidth: '970px' },
        '@media (min-width: 1200px)': { maxWidth: '1170px' },
        '@media (min-width: 1280px)': { maxWidth: '1260px' },
        '@media (max-width: 900px)': { pb: '30px' },
      }}
    >
     <Stack sx={{ position: "relative", px: '0px', height: '60px' }}>
        <Grid container sx={{ px: "0px" }}>
          <Grid item xs={12} md={2} lg={2}>
            <Stack
              sx={{
                position: "relative", alignItems: 'flex-start', justifyContent: 'center', height: '60px',
                '@media (max-width: 900px)': { alignItems: 'center' },
              }}
            >
              <CardMedia
                src={getMinifiedImage(survey?.logo! || event?.logo!)}
                component={'img'}
                sx={{
                  maxWidth: '60px',
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <Stack
            sx={{
              position: "relative", alignItems: 'center', justifyContent: 'flex-end', height: '60px', flexFlow: 'wrap', zIndex: 100, columnGap: '20px',
              '@media (max-width: 900px)': { justifyContent: 'center' },
              }}
            >
              { survey ? menuListSurvey.map((menu, index) => (
                <a
                  href={menu.id}
                  key={index}
                  className='box-menu-link'
                >
                  {menu.name}
                </a>
              ))
              : menuList.map((menu, index) => (
                <a
                  href={menu.id}
                  key={index}
                  className='box-menu-link'
                >
                  {menu.name}
                </a>
              ))
            }
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Container>
    
  );
};
