import { useSelector } from 'react-redux';
import classNames from 'classnames';
// import divider from '../../../assets/images/website-event/divider-wte.svg';
import { EventWebsiteTemplateType } from 'src/mui/types/survey';
import { IAppState } from '../../../store';

const WhatToExpect = () => {
  const event = useSelector((state: IAppState) => state.website.event!);

  return (
    <div
      className={classNames({
        'w-full mb-[52px]': event?.eventMetrics?.length > 0,
        hidden: !(event?.eventMetrics?.length > 0),
      })}
    >
      <div
        className="font-semibold text-[#0326E5] text-[21px] leading-[25px] flex justify-center mb-[28px]"
        style={{
          color: event?.colorStyle?.values?.heading,
        }}
      >
        What to expect
      </div>
      <div className="flex flex-wrap md:flex-row flex-col justify-center md:gap-[74.5px] items-center">
        {event?.eventMetrics.map((item, idx) => (
          <div
            className={classNames('flex', {
              'md:gap-14 md:my-0 my-3': event?.eventMetrics.length > 1,
            })}
          >
            <div
              className={classNames({
                hidden: !(item.name && item.value),
              })}
            >
              <div className="flex flex-col justify-center items-center w-full font-semibold">
                <div
                  className="text-[#0326E5] md:leading-[85px] leading-[50px] text-center md:h-[80px] text-[45px] md:text-[57px] font-semibold md:mb-[7px] mb-3"
                  style={{
                    color: event?.colorStyle?.values?.backgroundButton,
                  }}
                >
                  {item.value}
                </div>
                <div
                  className={classNames(
                    'text-[14px] h-[20px] font-semibold flex justify-center',
                    {
                      'text-[#0B1221]':
                        event?.websiteTemplate
                        !== EventWebsiteTemplateType.AFTER_HOURS,
                      'text-[#FFFFFF]':
                        event?.websiteTemplate
                        === EventWebsiteTemplateType.AFTER_HOURS,
                    }
                  )}
                >
                  {item.name}
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'border-r-1 md:visible md:block hidden invisible md:border-[#0326E5] h-[106.72px] ml-[0px]',
                {
                  'border-r-0 hidden': idx === event?.eventMetrics.length - 1,
                }
              )}
              style={{ borderColor: event?.colorStyle?.values?.heading }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatToExpect;
