import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IAttendee } from 'src/models/events-live/attendee';
import { IDropdownMenuItem } from 'src/components/dropdown-menu';
// import { InteractiveAttendeeRaiseHand } from '../../../../store/interactive-event/attendee/actions';
import { InteractiveModeratorSpeakerInviteAttendee } from 'src/store/interactive-event/moderator-speaker';
import { useCurrentUser } from 'src/utils/hooks';
import { AttendeeAvatar } from '../AttendeeAvatar';
import { IAppState } from 'src/store';

interface IAttendeesParticipantsView {
  participants: IAttendee[]
  getDefaultOptions?: Function
}
export const AttendeesParticipantsView = ({ participants, getDefaultOptions }: IAttendeesParticipantsView) => {
  const { slug, roomId, role } = useParams();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const isSpeaker = (id: string) => liveEvent.speakers.find(speaker => speaker.id === id);

  return (
    <>
      {participants.map((participant, key) => {
        const dotOptions: IDropdownMenuItem[] = [];
        // TODO: Feature disabled.
        // if (user.id === item.id && role === 'session') {
        //   dotOptions.push(
        //     {
        //       id: 'raise-hand',
        //       text: 'Raise Hand',
        //       onClickHandler: () => {
        //         dispatch(InteractiveAttendeeRaiseHand(slug!, roomId!));
        //       }
        //     }
        //   );
        // }

        if ((role === 'moderator' || role === 'speaker') && participant.id !== user.id && !isSpeaker(participant.id) && liveEvent?.event?.rooms?.[0]?.isInteractive) {
          dotOptions.push(
            {
              id: 'invite-connection',
              text: 'Invite',
              onClickHandler: () => {
                dispatch(InteractiveModeratorSpeakerInviteAttendee(slug!, roomId!, participant.id));
              }
            }
          );
        }
        return (
          <AttendeeAvatar
            item={participant}
            key={key}
            options={[...getDefaultOptions!(participant), ...dotOptions]}
          />
        );
      })}
    </>
  );
};
