import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
    isActive?: boolean;
}

function SpeakersTabIcon({ fillColor, fillBgColor,isActive, ...props }: Props) {
    return isActive? (
      <svg xmlns="http://www.w3.org/2000/svg" width="30.466" height="25.502" viewBox="0 0 30.466 25.502">
        <g id="Group_29051" data-name="Group 29051" transform="translate(-8021.966 -4852.349)">
          <g id="Group_29050" data-name="Group 29050" transform="translate(685.705 -1078.521)">
            <path id="circle-microphone" d="M6.6,0a6.6,6.6,0,1,0,6.6,6.6A6.606,6.606,0,0,0,6.6,0ZM5.5,3.849a1.1,1.1,0,1,1,2.2,0V6.6a1.1,1.1,0,0,1-2.2,0Zm1.65,6v.6a.55.55,0,0,1-1.1,0v-.6A3.3,3.3,0,0,1,3.3,6.6a.55.55,0,0,1,1.1,0,2.2,2.2,0,1,0,4.4,0,.55.55,0,0,1,1.1,0A3.3,3.3,0,0,1,7.148,9.852Z" transform="translate(7353.53 5931.909)" fill={fillColor}/>
            <path id="users_active" data-name="users active" d="M9.563,15.476A5.738,5.738,0,1,1,15.3,9.738a5.738,5.738,0,0,1-5.738,5.738ZM17.852,29.5H1.275A1.275,1.275,0,0,1,0,28.227V27.59a9.563,9.563,0,1,1,19.127,0v.638A1.275,1.275,0,0,1,17.852,29.5Z" transform="translate(7336.261 5926.87)" fill={fillColor}/>
          </g>
        </g>
      </svg>
    ):
      (
      <svg xmlns="http://www.w3.org/2000/svg" width="30.566" height="25.703" viewBox="0 0 30.566 25.703">
        <g id="Group_29051" data-name="Group 29051" transform="translate(-8021.866 -4852.249)">
          <g id="Group_29050" data-name="Group 29050" transform="translate(685.705 -1078.521)">
            <path id="users"
                  d="M9.563,15.476A5.738,5.738,0,1,1,15.3,9.738a5.738,5.738,0,0,1-5.738,5.738Zm0-8.926a3.188,3.188,0,1,0,3.188,3.188A3.188,3.188,0,0,0,9.563,6.55Zm9.563,21.677V27.59A9.563,9.563,0,0,0,0,27.59v.638a1.275,1.275,0,0,0,2.55,0V27.59a7.013,7.013,0,0,1,14.026,0v.638a1.275,1.275,0,1,0,2.55,0Z"
                  transform="translate(7336.261 5926.87)" fill={fillColor} stroke="#fff" stroke-width="0.2"/>
            <path id="circle-microphone"
                  d="M6.6,0a6.6,6.6,0,1,0,6.6,6.6A6.606,6.606,0,0,0,6.6,0ZM5.5,3.849a1.1,1.1,0,1,1,2.2,0V6.6a1.1,1.1,0,0,1-2.2,0Zm1.65,6v.6a.55.55,0,0,1-1.1,0v-.6A3.3,3.3,0,0,1,3.3,6.6a.55.55,0,0,1,1.1,0,2.2,2.2,0,1,0,4.4,0,.55.55,0,0,1,1.1,0A3.3,3.3,0,0,1,7.148,9.852Z"
                  transform="translate(7353.53 5931.909)" fill={fillColor}/>
          </g>
        </g>
      </svg>

      // <svg id="Group_21134" data-name="Group 21134" xmlns="http://www.w3.org/2000/svg" width="23.65" height="22.948" viewBox="0 0 23.65 22.948">
      //     <path id="Path_14627" data-name="Path 14627" d="M7.047,7.4A8.294,8.294,0,0,1,9.373,1.638H1.283A1.281,1.281,0,0,0,0,2.919V14.446a1.281,1.281,0,0,0,1.281,1.281H11.529a1.258,1.258,0,0,0,1-.51A8.321,8.321,0,0,1,7.047,7.4" transform="translate(-0.002 0.46)" fill={fillColor} />
      //     <path id="Path_14629" data-name="Path 14629" d="M13.93,0A7.727,7.727,0,1,1,6.2,7.727,7.727,7.727,0,0,1,13.93,0" transform="translate(1.992)" fill={fillColor} />
      //     <path id="Path_14630" data-name="Path 14630" d="M7.9,19.259a.663.663,0,0,0,.539-.289,6.135,6.135,0,0,1,9.993,0,.666.666,0,0,0,.547.291h1.448a.673.673,0,0,0,.669-.68.66.66,0,0,0-.082-.319,8.664,8.664,0,0,0-15.164.013.671.671,0,0,0,.591.984Z" transform="translate(2.426 3.688)" fill={fillColor} />
      // </svg>

    )
}

export default SpeakersTabIcon;
