/* eslint-disable max-len */
import { Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './company-edit-page.css';
import { PSlider, RHFAutocomplete } from 'src/mui/components/hook-form';
import { targetFunctionsOptions } from 'src/constants';
import { IAppState } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySizes } from 'src/store/company/company-sizes/actions';
import PButtonGrid from 'src/mui/components/hook-form/PButtonGrid';
import PCompanySelectNew from 'src/mui/components/hook-form/PCompanySelectNew';
import AlertModal from 'src/components/alert-modal/alert-modal';
import PCompanyIndustrySelect from 'src/mui/components/hook-form/PCompanyIndustrySelect';
import { Link } from 'react-router-dom';
import companyInfoIcon from '../../../assets/images/company/info-icon.svg';
import { timelineScale } from './company-enum';
import UploadAccounts from './company-upload-accounts';

export const Targets = () => {
  const {
    companySizes,
    companyProfilePreview
  } = useSelector((state: IAppState) => state);
  const dispatch = useDispatch();
  const [companySizeOptions, setCompanySizeOptions] = useState<any[]>([]);
  const [openUploadAccount, setOpenUploadAccount] = useState<boolean>(false);

  useEffect(() => {
    const populateCompanySizes = async () => dispatch(getCompanySizes());
    populateCompanySizes();
  }, [dispatch]);

  useEffect(() => {
    const data: any[] = [];
    companySizes?.value?.data.map((item) => data.push({
      name: item.name,
      id: item.id
    }));
    setCompanySizeOptions(data);
  }, [companySizes]);

  const renderSliderFeild = (title:string, name:string, isRequired?:boolean) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
          { isRequired && <span className="text-[#FF0000]">*</span>}
        </Typography>
        <Stack
          sx={{
            backgroundColor: '#F9FAFB', height: '64px', borderRadius: '5px', display: 'flex', justifyContent: 'center', px: '15px', pt: '7px'
          }}
          className="slider-container"
        >
          <PSlider
            size="medium"
            name={name}
            min={timelineScale[0].value}
            max={timelineScale[timelineScale.length - 1]?.value}
            step={null}
            marks={timelineScale}
            aria-labelledby="size-input-slider"
            valueLabelDisplay="auto"
          />
        </Stack>
      </Stack>
    </Stack>
  );

  const renderTnterestIndustries = (title:string, name:string) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
        </Typography>
        <PCompanyIndustrySelect name={name} className="dropdownRoot" multiple />
      </Stack>
    </Stack>
  );

  const renderTargetAccounts = (title:string, name:string) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: '8px' }}>
          <Typography
            color="text.primary"
            variant="subtitle2"
            sx={{
              fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
            }}
          >
            {title}
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap="15px">
            <Typography
              color="text.primary"
              variant="subtitle2"
              sx={{
                cursor: 'pointer', fontSize: '15px', fontWeight: 500, lineHeight: '20px', color: '#0131FF', fontFamily: 'Poppins-500'
              }}
              onClick={() => setOpenUploadAccount(true)}
            >
              +Upload a list of accounts
            </Typography>
            { companyProfilePreview?.value?.id && (
            <Link
              to={`/wall/company/${companyProfilePreview?.value?.id}/accounts-list`}
              target="_blank"
              className="bg-[#EDF2F5] hover:bg-blue-0 px-[17px] h-[32px] rounded-[5px] text-sm font-medium text-[#203C6E] flex items-center"
            >
              List Of  Accounts
            </Link>
            )}
          </Stack>
        </Stack>
        <PCompanySelectNew
          name={name}
          className="dropdownRoot"
          noOptionsMessage="Please try target account"
          multiple
        />
        {/* <PCompanySelectLiveSearch
          name={name}
        /> */}
      </Stack>
    </Stack>
  );

  const renderPersonaInterest = (title:string, name:string) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
        </Typography>
        <RHFAutocomplete
          name={name}
          multiple
          freeSolo
          options={[]}
          ChipProps={{ size: 'small' }}
          className="dropdownRoot"
        />
      </Stack>
    </Stack>
  );

  const renderButtonGrid = (title:string, name:string, options:any[]) => (
    <Stack direction="row" columnGap="10px" sx={{ width: '100%' }}>
      <Stack sx={{ width: '19px', height: '19px' }}>
        <img src={companyInfoIcon} alt="" style={{ width: '19px', height: '19px' }} />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
        </Typography>
        <Grid container spacing="5px" sx={{ mt: '0px' }}>
          <PButtonGrid
            name={name}
            options={options}
          />
        </Grid>
      </Stack>
    </Stack>
  );

  return (
    <Stack rowGap="15px">
      { renderSliderFeild('I aim for customers to make purchases in the above technologies within the next', 'timeline', true)}
      { renderTnterestIndustries('Target industries', 'interestIndustries')}
      { renderTargetAccounts('Target accounts', 'targetAccounts')}
      { renderPersonaInterest('Target persona (type keywords for job titles you target, i.e. Chief Information Officer, CIO)', 'personaInterest')}
      { renderButtonGrid('Target functions(departments)', 'targetDepartments', targetFunctionsOptions)}
      { renderButtonGrid('Target Headcounts', 'targetHeadcounts', companySizeOptions!)}

      { openUploadAccount && (
      <AlertModal
        className="w-[900px] !py-0 bg-[#F5F7FC]"
        closeIconClass="top-[18px] right-[11px]"
        isCloseIcon
        isOpen={openUploadAccount}
        onClose={() => setOpenUploadAccount(false)}
      >
        <UploadAccounts />
      </AlertModal>
      )}

    </Stack>
  );
};

export default Targets;
