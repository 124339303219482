import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { IAppState } from '../../../store';
import { ParseContent } from '../website/parse-html';
import { Logo } from '../../../shared-components/V2/Logo';

const EventOverview = () => {
  const event = useSelector((state: IAppState) => state.website.event);

  return (
    <div
      className={classNames('md:max-w-[90%] max-w-[97%] mx-auto px-[20px] xl:px-0 relative -mt-[100px] z-20', {
        hidden: event?.overview === null,
      })}
    >
      <div
        id="event-focus"
        className={classNames('w-full', {
          hidden: event?.overview === null,
        })}
      >
        {event?.overview && (
          <div className="w-full event-website-box-new">
            <div className="py-[50px] md:mx-[65px] mx-5 xl:flex md:items-center justify-between">
              <div className="text-[15px] leading-[22px] font-normal text-[#3C3C3C]">
                <ParseContent content={event?.overview} fullText />
              </div>
              <div className="xl:w-[450px] xl:min-w-[450px] xl xl:max-h-[300px] overflow-hidden xl:pl-[70px] xl:pt-[0] pt-4">
                <Logo
                  src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${
                    event && event.logo
                  }`}
                  className="flex justify-center h-full object-contain"
                  alt={event?.name}
                  defaultLogo="company"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventOverview;
