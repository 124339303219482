import classNames from 'classnames';
import { useNavigate } from 'react-router';
import userDefault from '../../../assets/images/company-default.svg';

export const ProductItem = (props: { item: any, companySlug: string}) => {
  const navigate = useNavigate();
  const avatarSrc = props.item?.icon
    ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${props.item?.icon}`
    : userDefault;
  return (
    <div
      onClick={() => navigate(`/wall/company/${props.companySlug}/prodcut/${props.item?.id}`)}
      className="flex flex-col items-center cursor-pointer"
    >
      <div className={classNames('h-[90px] w-[90px] flex items-center justify-center !rounded-[10px] border-2 border-[#EDF2F5] overflow-hidden', {
        'bg-[#edf2f5]': !props.item?.icon
      })}
      >
        <img
          src={avatarSrc}
          alt=""
          className="!h-[88px] !w-[88px] !max-h-[88px] !max-w-[88px] object-contain !rounded-[9px]"
        />
      </div>
      <div className="text-[13px] text-[#3C3C3C] font-[500] text-center mt-[5px] leading-[17px] line-clamp-2">
        {`${props.item?.name}`}
      </div>
    </div>
  );
};
