// Import Ad and Resources Component, WIP
import { useEffect, useState } from 'react';
import Ad from './ad';

type AdsAndResourcesProps = {
  ads: any[];
  // resources: IEventResource[];
};

export const AdsAndResources = (props: AdsAndResourcesProps) => {
  const [ads, setAds] = useState<any[]>();

  const rotateRight = (arr: any, reverse?: boolean) => {
    if (reverse) arr.unshift(arr.pop());
    else arr.push(arr.shift());
    return arr;
  };

  const reverseAds = () => {
    if (ads?.length) {
      const tmpAds: any[] = [...ads!];
      const rotatedAds = rotateRight(tmpAds, true);
      setAds(rotatedAds);
    }
  };

  useEffect(() => {
    if (props?.ads) {
      setAds(props.ads);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      reverseAds();
    }, 30000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ads?.[0]?.id]);

  return (
    <div>
      {!!ads?.length && (
        <div className="ads rounded-[14px] shadow-xs6 mb-5">
          <Ad ad={ads?.[0]} />
        </div>
      )}
    </div>
  );
};

export default AdsAndResources;
