import { CircularProgress, Dialog, Stack } from "@mui/material";
import SurveyAnswerForm from "./index";
import { useSurveyContext } from "src/mui/providers/GeneralContext";
import { delAuthToken } from "src/utils";
import { resetUserEmail } from "src/store/checkUserEmail";
import { useDispatch } from "react-redux";
import { setRegistered } from "src/store/event-website";
import { unsetUser } from "src/store/auth";
import { UpcomingEventActionTypes } from "src/store/events/upcoming-event/types";
import { UserSignUpActionTypes } from "src/store/user/signup/types";

type Props = {
  open: boolean;
  setOpenSurveyForm: (value: boolean) => void;
  title: string;
  userId: string;
  resetUser?: () => void
};

export const hideScrollbarY = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
} as const;

const SurveyDialog = ({ title, open, setOpenSurveyForm, userId, resetUser }: Props) => {
  const { surveyPageLoader } = useSurveyContext();
  const dispatch=useDispatch()

  const resetForm = () => {
    sessionStorage.removeItem("userName");
    dispatch({
      type: UserSignUpActionTypes.GO_TO_FIRST_STEP,
    });
    dispatch(resetUserEmail());
  };

  const signoutClickHandler = () => {
    delAuthToken();
    resetForm();
    dispatch(setRegistered(null));
    dispatch(unsetUser());

    dispatch({
      type: UpcomingEventActionTypes.RESET_UPCOMING_EVENT_LOADING,
      payload: {}
    });

    dispatch({
      type: UserSignUpActionTypes.RESET_SIGNUP_USER,
      payload: {}
    });

    if (resetUser) resetUser();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={
        surveyPageLoader
          ? (e) => {
            setOpenSurveyForm(false);
          }
          : undefined
      }
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          //...hideScrollbarY,
          minHeight: "400px",
          minWidth: "400px",
        },
      }}
    >
      {!surveyPageLoader ? (
        <SurveyAnswerForm
          onLogout={() => { resetForm(); signoutClickHandler(); }}
          userId={userId}
          open={open}
          setOpenSurveyForm={setOpenSurveyForm}
          title="Survey Form"
        />
      ) : (
        <Stack
          sx={{
            direction: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="info" />
        </Stack>
      )}
    </Dialog>
  );
};
export default SurveyDialog;
