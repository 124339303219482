import React from "react";

export interface EarthGlobalSvgIconProps {
    fillColor?: string;
}
export const EarthGlobalSvgIcon = ({ fillColor }: EarthGlobalSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#3c3c3c"
    return <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
    <path id="earth-svgrepo-com" d="M9.61,6.363c.262-.334.334-.524.214-1.429a1.825,1.825,0,0,1,.522-1.356A4.863,4.863,0,0,0,8.832,2.271a.885.885,0,0,1-.223.271c-.286.229-1.6-.253-2-.253s-.286.738-1.263.834c-.817.08-.767-.788-.729-1.075a4.844,4.844,0,0,0-2.872,5.4c.193-.039.972-.168,1.243.248.31.476.143.953.381,1.167.217.2.669.394.279,1.563A4.841,4.841,0,0,0,11.2,7.655C9.395,7.72,9.354,6.689,9.61,6.363ZM8.062,8.269a2.009,2.009,0,0,1-1.29,1.93s-.758.572-.925-1.143S4.679,8.269,4.6,6.482A2.353,2.353,0,0,1,5.632,4.148c.643-.381,1.024.858,1.644,1s1.691-.024,1.81.524S8.109,6.72,8.062,8.269ZM11.1,1.9A6.5,6.5,0,0,0,1.9,11.1,6.5,6.5,0,0,0,11.1,1.9ZM6.5,12.181A5.681,5.681,0,1,1,12.181,6.5,5.681,5.681,0,0,1,6.5,12.181Z" fill={iconColor}/>
  </svg>
  
  
};