import { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import EventPartners from '../../../components/event-partners';
import { IAppState } from '../../../store';
import { getUpcomingEvent } from '../../../store/events/upcoming-event/actions';
import { init } from '../../../store/event-website';
import AboutEvent from './about-event';
import EventAgenda from './event-agenda';
import { EventSpeakers } from './event-speakers';
import WebsiteEventAgendaLobby from '../../event/website/website-event-agenda-lobby';

type TabHeaderProps = {
  openTab: number;
  idx: string | number;
  onClick: (i: number) => void;
  name: string | number;
};
const TabHeader: FC<TabHeaderProps> = ({ openTab, idx, onClick, name }) => (
  <li className="flex-auto text-start cursor-pointer">
    <button
      className={
        openTab === idx
          ? 'border-b-[3px] border-[#285CB2]  text-[#203C6E] font-semibold sm:text-[15px] text-[12px] leading-[18px] pb-2'
          : 'text-[#203C6E] font-light sm:text-[15px] text-[12px] leading-[18px]'
      }
      onClick={(e) => {
        e.preventDefault();
        onClick(idx as number);
      }}>
      {name}
    </button>
  </li>
);

export const EventDetails = () => {
  const [openTab, setOpenTab] = useState(1);
  const tabNames = [
    [1, 'About'],
    [2, 'Speakers'],
    [3, 'Agenda'],
    [4, 'Partners']
  ];

  const params = useParams();
  const eventIdOrSlug: string = String(Object.values(params)[0]);

  const dispatch = useDispatch();
  const { upcomingEvent } = useSelector((state: IAppState) => state);
  const { targetAudiences } = useSelector((state: IAppState) => state.website);

  useEffect(() => {
    dispatch(getUpcomingEvent(eventIdOrSlug));
    // dispatch(init(eventIdOrSlug));
  }, [dispatch, eventIdOrSlug]);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full h-441 mb-4 bg-white create-post-card">
          <div className="mx-5">
            <ul className="flex list-none flex-row bg-white rounded-t-lg pt-4 sm:last:gap-x-auto xl:last:gap-x-[108px]">
              {tabNames.map(([idx, name]) => {
                return (
                  <TabHeader
                    name={name}
                    idx={idx}
                    openTab={openTab}
                    key={idx}
                    onClick={setOpenTab}
                  />
                );
              })}
            </ul>
          </div>

          <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded-b-[15px]">
            <div className="border-t-1 border-[#CDDCF2] mx-5"></div>
            <div className="px-4 py-5 overflow-x-auto h-362 no-scrollbar">
              <div className="">
                <div className={openTab === 1 ? 'block' : 'hidden'}>
                  <AboutEvent
                    eventName={upcomingEvent.value?.data.event.name!}
                    eventTheme={upcomingEvent.value?.data.event.theme!}
                    overview={upcomingEvent.value?.data.event.overview!}
                    attendingJobTitles={targetAudiences?.jobTitles}
                    attendingIndustries={targetAudiences?.industries}
                    eventKeyDiscussionPoints={
                      upcomingEvent.value?.data.event.eventKeyDiscussionPoints!
                    }
                  />
                </div>
                <div className={openTab === 2 ? 'block' : 'hidden'}>
                  <EventSpeakers eventIdOrSlug={upcomingEvent.value?.data.event.id!} />
                </div>
                <div className={openTab === 3 ? 'block' : 'hidden'}>
                  {/* <EventAgenda event={upcomingEvent.value?.data.event!} /> */}
                  {openTab === 3 && <WebsiteEventAgendaLobby isLobbbyPage eventList={upcomingEvent.value?.data.event!} />}
                </div>
                <div className={openTab === 4 ? 'block' : 'hidden'}>
                  <EventPartners eventPartners={upcomingEvent.value?.data.event.eventSponsors!} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b"></div>
      </div>
    </>
  );
};

export default EventDetails;
