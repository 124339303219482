/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import { Autoplay, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import LandingLoginOrJoinForm from '../loginorjoin/landing-page-loginorjoin/landing-loginorjoin-form';
import ProfessionalsBannerButton from './professionals-banner-button';
import BannerOne from '../../assets/images/landing-page/landing-one.png';
import BannerTwo from '../../assets/images/landing-page/landing-two.png';
import BannerThree from '../../assets/images/landing-page/landing-three.png';

export const ConnectingProfessionals = () => (
  <div className="lg:h-[870px] lg:pb-0 pb-12 bg-[#001BF6]">
    <div className="container w-full max-w-[100%] flex items-center 2xl:pl-[150px] 2xl:pr-[335px] xl:pl-[100px] xl:pr-[100px] pl-[30px] pr-[30px]">
      <div className="flex lg:flex-row flex-col w-full justify-between">
        <div className="">
          <div className="block sm:mt-[110px] mt-[60px] text-left mb-[30px]">
            <p className="text-[20px] xl:text-[18px] font-medium leading-[28px] text-white">Event Manager</p>
            <p className="text-[40px] xl:text-[40px] 2xl:text-[80px] font-semibold 2xl:leading-[85px] leading-[50px] text-white">The Perfect</p>
            <p className="text-[40px] xl:text-[40px] 2xl:text-[80px] font-semibold 2xl:leading-[85px] leading-[50px] text-white">Event Platform</p>
          </div>
          <div className="mb-2.5">
            <ProfessionalsBannerButton url="https://business.panelist.com/virtual" name="Run webinars & Virtual Events" />
          </div>
          <div className="mb-2.5">
            <ProfessionalsBannerButton url="https://business.panelist.com/hybrid" name="Host an Inperson & Hybrid Events" />
          </div>
          <div className="mb-2.5">
            <ProfessionalsBannerButton url="https://business.panelist.com/prospect-match" name="Prospecting & Matchmaking" />
          </div>
          <div className="mb-2.5">
            <ProfessionalsBannerButton url="https://business.panelist.com/hub-community" name="Community & Engagement" />
          </div>
        </div>
        <div className="flex flex-col md:items-end items-center">
          <div className="flex flex-col items-end">
            <div className="sm:mt-[115px] mt-[50px]">
              <div className="sm:text-[40px] text-[26px] font-medium text-white text-left sm:leading-[56px] lg:leading-9 mb-[20px]">
                Log in or Join now
              </div>
              <LandingLoginOrJoinForm from="site" changePage={null} />
            </div>
          </div>
          <div className="sm:w-[435px] mt-[44px]">
            <Swiper
              pagination={{
                dynamicBullets: false,
                clickable: true,
              }}
              centeredSlides
              modules={[Pagination, Autoplay, EffectFade]}
              autoplay={{
                delay: 9000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
                stopOnLastSlide: false,
                waitForTransition: false
              }}
              className="landingSwiper"
            >
              <SwiperSlide>
                <div className="flex flex-col items-center justify-center">
                  <div className="sm:h-[338px] h-[270px]"><img className="object-contain transition-all" src={BannerOne} alt="" /></div>
                  <div className="text-[30px] font-medium text-white leading-[40px] text-center">
                    Meet & Match With Other
                  </div>
                  <div className="text-[30px] font-medium text-white leading-[40px] text-center">
                    Professionals
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex flex-col items-center justify-center">
                  <div className="sm:h-[338px] h-[270px]"><img className="object-contain transition-all" src={BannerTwo} alt="" /></div>
                  <div className="text-[30px] font-medium text-white leading-[40px] text-center">
                    Host & Attend
                  </div>
                  <div className="text-[30px] font-medium text-white leading-[40px] text-center">
                    Events
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex flex-col items-center justify-center">
                  <div className="sm:h-[338px] h-[270px]"><img className="object-contain transition-all" src={BannerThree} alt="" /></div>
                  <div className="text-[30px] font-medium text-white leading-[40px] text-center">
                    Share & Access
                  </div>
                  <div className="text-[30px] font-medium text-white leading-[40px] text-center">
                    Content
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ConnectingProfessionals;
