import zerotraining from '../../../assets/images/virtual/zero-training.png';
import zerotraining2 from '../../../assets/images/virtual/zero-training@2x.png';

export const ZeroTrainingRequired = () => (
  <div className="lg:flex flex-row">
    <div className="bg-gray-1 w-full lg:w-1/2">
      <div className="lg:flex flex-col">
        <div className="flex flex-col items-start lg:ml-36 2xl:ml-64 2xl:pl-6 pl-2 mt-44 mb-12">
          <p className="lg:text-xl3 font-extralight text-dark-blue">Zero training required!</p>
          <p className="lg:text-lg font-extralight text-dark-blue lg:w-3/4 mt-5">
            Manage your attendees, speakers and sponsors all in one platform
          </p>
          <p className="text-md font-extralight text-gray-1 w-9/12 mt-5 ">
            Create amazing experiences for your customers
            {' '}
            <br />
            {' '}
            with a seemless and streamlined
            onboarding process for your attendees, speakers and sponsors. Keep your audience
            notified with updates , last minute changes, speaker and sponsor additions
            {' '}
            <br />
            {' '}
            and
            reminders all from one place.
          </p>
        </div>
      </div>
    </div>
    <div className="bg-gray-2 w-full lg:w-1/2">
      <div className="lg:mt-12 pt-2 lg:mb-32 lg:-ml-36 2xl:ml-24  mx-auto">
        <img
          className=""
          srcSet={`${zerotraining} 300w, ${zerotraining2} 1000w`}
          sizes="(max-width: 583px) 40vw, (max-width: 583px) 30vw, 500px"
          alt=""
          src={zerotraining2}
        />
      </div>
    </div>
  </div>
);

export default ZeroTrainingRequired;
