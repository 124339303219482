import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';

type Download = {
  filename: string;
};

type DownloadProps = {
  downloads: Download[];
};

const Downloads = (props: DownloadProps) => (
  <>
    {props.downloads.map((file: Download, i: any) => (
      <div
        className="flex justify-between items-center border-gray-200 border-2 rounded px-1"
        key={i}
      >
        <span className="text-gray-1 text-h3 font-bold">{file.filename}</span>
        <VerticalAlignBottomIcon sx={{ color: '#203c6e' }} />
      </div>
    ))}
  </>
);

export default Downloads;
