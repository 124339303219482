/* eslint-disable */
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import classNames from 'classnames';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { expoEndMeeting, expoGuestCancel, expoManagerReject } from 'src/store/expo/actions';
import { ExpoSponsors } from './expo/expo-sponsors';
import { VideoCall } from './video-call';
import VideoFooter from './video-footer';
import VideoHeader from './video-header';
import { VideoControlls } from 'src/components/chime-video-call/video-controlls';
import { IAttendee } from 'src/models/events-live/attendee';
import { IExpoBooth } from 'src/models/expo';
import { SpeakerRooms } from '../rooms-popup';
import { IAppState } from 'src/store';
import { useCurrentUser } from 'src/utils/hooks';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { LoadingScreen } from 'src/mui/components/loading-screen';
import { getCompanyName } from 'src/utils';
import { getBrandingColors } from '../../live/liveEventStyling';


interface VideoControls {
  seek(time: number): void;
}

type VideoProps = {
  url: string;
  title: string;
  logoURL: string;
  expoData: IExpoBooth[];
  screenMode?: 'small' | 'large' | 'hidden' | undefined;
  setScreenMode(mode: 'small' | 'large' | 'hidden' | undefined): void;
  startTime: any;
  endTime: any;
  attendees?: IAttendee[];
  setPlayBackVideo?: () => void;
  setRoomID: (value: string) => void;
  isGridView?: boolean;
};

export const VideoPanel = (props: VideoProps) => {
  const videoPlayerRef = useRef<VideoControls>(null);
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const meetingManager = useMeetingManager();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isGuestCalling, setIsGuestCalling] = useState(false);
  const [volume, setVolume] = useState(60);
  const [fullScreen, setFullScreen] = useState(false);
  const [pip, setPip] = useState(false);
  const [error, setError] = useState('');
  const [speakerRooms, setSpeakerRooms] = useState();
  const [isEventModerator, setIsEventModerator] = useState<boolean>(false);
  const [videoStatus, setVideoStatus] = useState<'sessions' | 'expo'>('sessions');

  const { event, eventModerators } = useSelector((state: IAppState) => state.liveEvent);
  const expoChimeInvitation = useSelector((state: IAppState) => state.chime.chimeInvitation);
  const expoGuestCall = useSelector((state: IAppState) => state.chime.guestCall);
  const expoCall = useSelector((state: IAppState) => state.chime.expoCall);

  const addEventIDPrarams = (eventId: string, id: string, role: string) => {
    const queryParam = `?eventId=${eventId}&id=${id}&role=${role}`;
    props.setRoomID(id);
    window.history.pushState({}, '', queryParam);
  };
  
  useEffect(() => {
    const currentMode = searchParams.get('mode');
    if (currentMode && currentMode !== videoStatus) {
      if (currentMode === "sessions") {
        setVideoStatus("sessions");
        setSearchParams({});
      } else if (currentMode === "expo") {
        setVideoStatus("expo")
      }
    }
  }, [searchParams])

  useEffect(() => {
    const currentMode = searchParams.get('mode');
    if (currentMode !== videoStatus && videoStatus === "sessions") {
      setSearchParams({});
    }

  }, [videoStatus])

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(props.url);
        if (response.status !== 200) {
          setError('Not valid URL');
        } else {
          setError('');
        }
      } catch (err) {
        setError('Not valid URL');
      }
    })();
  }, [props.url]);

  useEffect(() => {
    const rooms: any = {};
    event?.rooms.map(room => room.sessions.map(session => session.eventSpeakers.map((speaker: any) => {
      if (speaker.userId === user?.id) {
        rooms[room.id] = room;
      }
      return speaker;
    })));
    setSpeakerRooms(rooms);

    const currentUserModerator: any = eventModerators.map(event => event?.user).find(mod => mod.id === user.id)
    setIsEventModerator(!!currentUserModerator);
  }, [event, eventModerators]);

  return (
    <Stack
      position='relative'
      width={1}
    >
      { event ?
      <VideoHeader
        title={props.title}
        disableExpo={!event?.eventExpo?.expo}
        logoURL={props.logoURL}
        videoStatus={videoStatus}
        eventSlug={event?.slug!}
        hostName={getCompanyName(event)}
        setVideoStatus={(stat: 'sessions' | 'expo') => setVideoStatus(stat)}
        overview={event?.overview}
      /> :
      <> </>
      }
      <Stack
        sx={{
         
          backgroundColor: getBrandingColors()?.Main || '#000000',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          overflowY: 'scroll',
          height: props.isGridView ? 'calc(50vh - 11px)' : 'calc(100vh - 22px)'
        }}
      >
        <VideoCall>
          {videoStatus === 'sessions' ? (
            event?.rooms ?
            (<SpeakerRooms
              rooms={event?.rooms!}
              onClick={(roomId: string) => {
                if(isEventModerator) {
                  addEventIDPrarams(event?.id!, roomId, 'moderator');
                } else if(speakerRooms?.[roomId]) {
                  addEventIDPrarams(event?.id!, roomId, 'speaker');
                } else {
                  addEventIDPrarams(event?.id!, roomId, 'session');
                }

              }}
             isGridView={props.isGridView}
            />) : (
              <Stack sx={{width: 1}} direction="row">
                <LoadingScreen />
              </Stack>

            )
          ) : (
            <ExpoSponsors
              expoData={props.expoData}
              screenMode={props.screenMode}
            />
          )}
        </VideoCall>
      </Stack>
      
      <VideoControlls
        disableScreenSharing={true}
        onChange={(status: string) => {
          if (status === "show") {
            // TODO: need to figure out expo meeting
            // setVideoStatus("expo");
          } else setVideoStatus("sessions")
        }}
        onCallHangup={() => {
          if (expoGuestCall?.callId) {
            expoChimeInvitation?.callId ? dispatch(expoEndMeeting()) : dispatch(expoGuestCancel());
          } else if (expoCall?.callId) {
            expoChimeInvitation?.callId ? dispatch(expoEndMeeting()) : dispatch(expoManagerReject());
          }
          if (meetingManager) meetingManager.leave();
        }} />
      {
        videoStatus === "sessions" &&
        <VideoFooter
          play={() => setIsPlaying(true)}
          pause={() => setIsPlaying(false)}
          seek={(time: number) => videoPlayerRef.current?.seek(time)}
          isPlaying={isPlaying}
          duration={0.0}
          position={0.0}
          volume={volume}
          errorURL={error}
          videoStatus={videoStatus}
          setVolume={(vol: number) => setVolume(vol)}
          fullScreen={fullScreen}
          setFullScreen={() => setFullScreen(!fullScreen)}
          setPip={() => setPip(!pip)}
          screenMode={props.screenMode}
          setScreenMode={(mode: 'small' | 'large' | 'hidden') => props.setScreenMode(mode)}
          showParticipant={() => setIsGuestCalling(!isGuestCalling)}
        />
      }
    </Stack >
  );
};

export default VideoPanel;
