import { Box, Stack, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  getBranding,
  getBrandingColors,
  setBranding,
  setColor,
  subscribeToColorChanges,
} from "./liveEventStyling";
import { useBoolean } from "src/mui/hooks/use-boolean";
import {
  getEventHandouts,
  getEventPolls,
  getTabsCount,
  init,
  resetLiveEvent,
  setJoinExpoBoothRoom,
} from "src/store/liveEvent";

import { unsetInteractiveEvent } from "src/store/interactive-event";
import { InteractiveModeratorSpeakerMuteAll } from "src/store/interactive-event/moderator-speaker";
import { SidebarMenu } from "../../sidebar/sidebar";
import { useCurrentUser, useWebSocket } from "src/utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "src/store";
import { useNavigate, useParams } from "react-router";
import { IEvent } from "src/models/event/event";
import { Participants } from "src/store/interactive-event/types";
import { IAttendee } from "src/models/events-live/attendee";
import { getUpcomingEvent } from "src/store/events/upcoming-event/actions";
import { EventsService } from "src/services/events";
import { LoadingScreen } from "src/mui/components/loading-screen";
import {
  EventQuestionPageTypes,
  useSurveyContext,
} from "src/mui/providers/GeneralContext";
import SurveyDialog from "../../survey/SurveyDialog";
import { CardHeader } from "./card-header";
import { getActivePage } from "../../CommonFunctions";
import { Audience_OPTIONS } from "../../audience/audienceOption";
import SponsorPage from "../../Sponsors";
import Products from "../../Sponsors/Products";
import SponsorDetails from "../../Sponsors/SponsorDetails";
import SessionHandout from "../../Sponsors/SessionHandOut";
import SponsorAds from "../../Sponsors/SponsorAds";
import { iconMap, reactions } from "./Constants";
import { getPolls } from "src/store/livePoll";
import { callHangUp, sendReaction } from "src/store/1-1-video-call/actions";
import { IVideoCallState } from "src/store/1-1-video-call";
import {
  useLocalVideo,
  useMeetingManager,
  useToggleLocalMute,
} from "amazon-chime-sdk-component-library-react";
import { getMinifiedImage } from "src/mui/utils/image";
import {
  calculateGridColSize,
  calculateGridHeight,
  calculateGridRowSize,
  getBoxStyles,
  getColumnCardHeight,
  getEventSessions,
  getEventStatus,
  ILiveViewProps,
  isModuleDesign,
} from "src/utils/live-event";
import { VisitBoothFooter } from "../VisitBooothFooter";
import { LiveViewMain } from "../LiveViewMain";
import { LiveMatchComponent } from "../LiveMatchComponent";
import { LiveAgendaComponent } from "./LiveAgendaComponent";
import { LiveChatComponent } from "./LiveChatComponent";
import { LiveAudienceComponent } from "./LiveAudienceComponent";
import { LiveSessionComponent } from "./LiveSessionComponent";
import { LivePollsComponent } from "./LivePollsComponent";
import { LiveVideoCallControls } from "./LiveVideoCallControls";
import { NoEvents } from "src/screens/events/event-tab/components/no-events";
import PersonRole from "../person-role";
import PersonPosts from "../person-post";
import PersonDetails from "../person-details";

export const LiveView = ({ isHeaderShow }: ILiveViewProps) => {
  const { slug, roomId, role } = useParams();
  const columnRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const { webSocket } = useWebSocket();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const drawer = useBoolean();
  const meetingManager = useMeetingManager();
  const { getQuestionPages, setEventIdParam } = useSurveyContext();

  const [columnWidth, setColumnWidth] = useState<number | null>(null);
  const [activeList, setActiveList] = useState<string[]>([]);
  const [selectedSponsorBooth, setSelectedSponsorBooth] = useState<any>(null);
  const [event, setEvent] = useState<IEvent>();
  const [attendees, setAttendees] = useState<IAttendee[]>([]);
  const [totalParticipantsCount, setTotalParticipantsCount] =
    useState<number>(0);
  const [ads, setAds] = useState<any[]>([]);
  const [handouts, setHandouts] = useState<any[]>([]);
  const [stage, setStage] = useState<Participants>();
  const [sessionTimes, setSessionTimes] = useState<any>([]);
  const [currentSession, setCurrentSession] = useState({
    startTime: 0,
    endTime: 0,
  });
  const [roomID, setRoomID] = useState<any>();
  const [openSurvey, setOpenSurvey] = useState(false);
  const [openBranding, setOpenBranding] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<string>();
  const [selectedRoomId, setSelectedRoomId] = useState<string>();
  const [selectedEventRole, setSelectedEventRole] = useState<string>();
  const [isInteractiveEvent, setIsInteractiveEvent] = useState<boolean>(false);
  const [isEventRegistered, setIsEventRegistered] = useState<boolean>(false);
  const [isHandoutsOpen, setIsHandoutsOpen] = useState<boolean>(false);
  const [isPollsOpen, setIsPollsOpen] = useState<boolean>(false);
  const [isAdsOpen, setIsAdsOpen] = useState<boolean>(false);
  const [isShowReactions, setIsShowReactions] = useState(false);
  const [openFeedbackPopup, setOpenFeedbackPopup] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [colors, setColors] = useState(getBrandingColors());

  const chatVideoCall: IVideoCallState = useSelector(
    (state: IAppState) => state.chatVideoCall
  );
  const chimeInvitation = useSelector(
    (state: IAppState) => state.chatVideoCall.chimeInvitation
  );
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const currentSessionId = useSelector(
    (state: IAppState) => state.liveEvent.session?.current
  );
  const livePolls = useSelector((state: IAppState) => state.livePoll);
  const { upcomingEvent } = useSelector((state: IAppState) => state);
  const interactiveEvent = useSelector(
    (state: IAppState) => state.interactiveEvent
  );
  const nextSession = useSelector(
    (state: IAppState) => state.liveEvent.session?.next
  );

  const panelPplacement: any = getBranding()?.controlPanel;
  const isBackgroundImage = getBranding()?.media;
  const isFocusedSessions: boolean = getBranding()?.layout == "focus";

  const tobeSession = currentSessionId || nextSession;
  const isLivePolls = livePolls?.livePolls?.answers?.length > 0;
  const isCallActive = chatVideoCall.chimeInvitation;
  const { liveEventBranding, joinExpoBoothRoom } = liveEvent;
  const isActiveBooth = activeList.includes("sponsorDetails");
  const isModuleDesignLines = isModuleDesign(getBranding, "lines");
  const brandingColors = getBrandingColors();
  const inPersonEvent = liveEvent?.event?.format === "hybrid" || liveEvent?.event?.format === "live";
  const boxStyles = getBoxStyles(isModuleDesignLines, brandingColors, "0px");
  const adsBoxStyles = getBoxStyles(
    isModuleDesignLines,
    brandingColors,
    "10px"
  );

  const eventStarted = getEventStatus(
    tobeSession?.startTime,
    tobeSession?.endTime,
    "live"
  );
  const futureEvent = getEventStatus(
    tobeSession?.startTime,
    tobeSession?.endTime,
    "future"
  );
  const pastEvent = getEventStatus(
    tobeSession?.startTime,
    tobeSession?.endTime,
    "past"
  );

  const onSessionClick = (data: any) => {
    console.log("data", data);
  };

  const handleMinimizePage = (item: any) => {
    const allActivePages = getActivePage(item, activeList);

    setActiveList(allActivePages);
  };

  const handleVisitBooth = (sponsor: any) => {
    const { booths, ads } = liveEvent;
    const selectedBooth = booths?.find(
      (booth) => booth.eventSponsorId === sponsor?.id
    );
    const selectedSponsorAds =
      ads?.filter((ad) => ad?.eventSponsor?.id === sponsor?.id) || [];

    const activeArray = ["sessions", "sessionHandouts", "sponsorDetails", "products", "sponsorsAds"];

    // if (selectedBooth?.expoHandouts?.length)
    //   activeArray.splice(1, 0, "sessionHandouts");
    // if (sponsor?.company?.products?.length) activeArray.push("products");
    // if (selectedSponsorAds?.length) activeArray.push("sponsorsAds");

    setSelectedSponsorBooth({
      ...sponsor,
      booths,
      expoHandouts: selectedBooth?.expoHandouts,
      assignedManagers: selectedBooth?.assignedManagers,
      booth: selectedBooth,
    });

    sessionStorage.setItem("activeList", JSON.stringify(activeList));
    setActiveList(activeArray);
  };

  const sessionComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string
  ) => {
    return (
      <LiveSessionComponent
        gridColumn={ColumnGridColSize}
        gridRow={ColumnGridRowSize}
        height={columnGridHeight}
        boxStyles={boxStyles}
        callActive={isCallActive}
        chatVideoCall={chatVideoCall}
        onCallHangup={() => {
          dispatch(callHangUp());
        }}
        interactiveEvent={isInteractiveEvent}
        setRoomID={setRoomID}
        strings={activeList}
        slug={selectedEventId}
        roomId={selectedRoomId}
        role={selectedEventRole}
        isHandoutsOpen={setIsHandoutsOpen}
        isPollsOpen={setIsPollsOpen}
        isAdsOpen={setIsAdsOpen}
        pollsOpen={isPollsOpen}
        handouts={handouts}
        event={event}
        sessionTimes={sessionTimes}
        handoutsOpen={isHandoutsOpen}
        livePolls={isLivePolls}
        headerShow={isHeaderShow}
        ads={ads}
        adsOpen={isAdsOpen}
      />
    );
  };

  const personDetailsComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string,
    // columnCardHeight: string
  ) => (
    <Box
      sx={{
        gridColumn: ColumnGridColSize,
        gridRow: ColumnGridRowSize,
      }}
    >
      <Stack sx={{ height: columnGridHeight, ...boxStyles }}>
        <CardHeader
          title="Who’s in the room"
          onClick={() => console.log("minimize")}
        />
        <PersonDetails />
      </Stack>
    </Box>
  );

  const personRoleComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string,
    // columnCardHeight: string
  ) => (
    <Box
      sx={{
        gridColumn: ColumnGridColSize,
        gridRow: ColumnGridRowSize,
      }}
    >
      <Stack sx={{ height: columnGridHeight, ...boxStyles }}>
        <CardHeader
          title="Audience Insights"
          onClick={() => handleMinimizePage("audienceInsights")}
          // onClick={() => console.log("minimize")}
        />
        <PersonRole />
      </Stack>
    </Box>
  );

  const matchComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string,
    columnCardHeight: string
  ) => (
    <LiveMatchComponent
      gridColumn={ColumnGridColSize}
      gridRow={ColumnGridRowSize}
      moduleDesignLines={isModuleDesignLines}
      height={columnGridHeight}
      boxStyles={boxStyles}
      onClick={() => handleMinimizePage("match")}
      user={user}
      event={event}
      activeList={activeList}
      columnCardHeight={columnCardHeight}
    />
  );

  const agendaComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string
  ) => {
    return (
      <LiveAgendaComponent
        gridColumn={ColumnGridColSize}
        gridRow={ColumnGridRowSize}
        moduleDesignLines={isModuleDesignLines}
        height={columnGridHeight}
        boxStyles={boxStyles}
        onClick={() => handleMinimizePage("agenda")}
        upcomingEvent={upcomingEvent}
        drawer={drawer}
        event={event}
      />
    );
  };

  const chatComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string,
    columnCardHeight: string
  ) => (
    <LiveChatComponent
      gridColumn={ColumnGridColSize}
      gridRow={ColumnGridRowSize}
      moduleDesignLines={isModuleDesignLines}
      height={columnGridHeight}
      boxStyles={boxStyles}
      onClick={() => handleMinimizePage("chat")}
      event={event}
      attendees={attendees}
      user={user}
      strings={activeList}
      columnCardHeight={columnCardHeight}
      totalParticipantsCount={totalParticipantsCount}
    />
  );

  const personPostComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string,
    // columnCardHeight: string
  ) => (
    <Box
      sx={{
        gridColumn: ColumnGridColSize,
        gridRow: ColumnGridRowSize,
      }}
    >
      <Stack sx={{ height: columnGridHeight, ...boxStyles }}>
        <CardHeader
          title="Posts"
          onClick={() => handleMinimizePage("posts")}
          // onClick={() => console.log("minimize")}
        />
        <PersonPosts />
      </Stack>
    </Box>
  );

  const audienceComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string,
    columnCardHeight: string
  ) => {
    return (
      <LiveAudienceComponent
        ref={columnRef}
        gridColumn={ColumnGridColSize}
        gridRow={ColumnGridRowSize}
        moduleDesignLines={isModuleDesignLines}
        height={columnGridHeight}
        boxStyles={boxStyles}
        onClick={() => handleMinimizePage("audience")}
        event={event}
        stage={stage}
        attendees={attendees}
        strings={activeList}
        columnCardHeight={columnCardHeight}
        role={role}
        onMuteClick={() =>
          dispatch(InteractiveModeratorSpeakerMuteAll(slug!, roomId!))
        }
        columnWidth={columnWidth}
      />
    );
  };

  const pollsComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string,
    columnCardHeight: string
  ) => (
    <LivePollsComponent
      gridColumn={ColumnGridColSize}
      gridRow={ColumnGridRowSize}
      moduleDesignLines={isModuleDesignLines}
      height={columnGridHeight}
      boxStyles={boxStyles}
      onClick={() => handleMinimizePage("polls")}
      event={event}
      strings={activeList}
      columnCardHeight={columnCardHeight}
    />
  );

  const sponsorComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string,
    columnCardHeight: string
  ) => (
    <Box
      sx={{
        gridColumn: ColumnGridColSize,
        gridRow: ColumnGridRowSize,
      }}
    >
      <Stack sx={{ height: columnGridHeight, ...boxStyles }}>
        <CardHeader
          title="Sponsors"
          onClick={() => handleMinimizePage("sponsors")}
        />
        {/* <Stack
          style={{
            background:
              "linear-gradient(to top, white 0%, rgba(255, 255, 255, 0.2) 0%, transparent 109%)",
          }}
          sx={{
            height: "35px",
            width: "100%",
            position: "absolute",
            left: 0,
            bottom: 0,
          }}
        ></Stack> */}
        <SponsorPage
          columnWidth={columnWidth}
          handleVisitBooth={handleVisitBooth}
          columnCardHeight={columnCardHeight}
          activeList={activeList}
        />
      </Stack>
    </Box>
  );

  const productComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string
  ) => (
    <Box
      sx={{
        gridColumn: ColumnGridColSize,
        gridRow: ColumnGridRowSize,
      }}
    >
      <Stack sx={{ height: columnGridHeight, ...boxStyles, p: '12px 10px 0px 10px !important' }}>
        <CardHeader
          title="Products"
          onClick={() => handleMinimizePage("products")}
          hideIcon
        />
        {selectedSponsorBooth?.company?.products?.length ? (
          <Products
          columnWidth={columnWidth}
          selectedSponsorDetails={selectedSponsorBooth}
        />
      ) : <NoEvents />}
      </Stack>
    </Box>
  );

  const SponsorDetailsComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string
  ) => (
    <Box sx={{ gridColumn: ColumnGridColSize, gridRow: ColumnGridRowSize }}>
      <Stack sx={{ height: columnGridHeight, ...boxStyles }}>
        <CardHeader
          title={`Meet ${selectedSponsorBooth?.company?.name}`}
          onClick={() => handleMinimizePage("products")}
          hideIcon
          // sx={{ fontSize: '18px', lineHeight: "25px" }}
        />
        <SponsorDetails
          columnWidth={columnWidth}
          selectedSponsorDetails={selectedSponsorBooth}
        />
      </Stack>
    </Box>
  );

  const SponsorSessionHandoutComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string
  ) => (
    <Box sx={{ gridColumn: ColumnGridColSize, gridRow: ColumnGridRowSize }}>
      <Stack sx={{ height: columnGridHeight, p: '12px 10px 0px 10px !important', ...boxStyles }}>
        <CardHeader
          title="Session handouts"
          onClick={() => handleMinimizePage("sessionHandouts")}
          hideIcon
        />
        {selectedSponsorBooth?.expoHandouts?.length ? <SessionHandout expoHandouts={selectedSponsorBooth?.expoHandouts} isSponsorHandouts /> : <NoEvents />}
      </Stack>
    </Box>
  );

  const SponsorAdsComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string
  ) => {
    const { ads } = liveEvent;
    const selectedSponsorAds = ads?.filter(
      (ad: any) => ad?.eventSponsor?.id === selectedSponsorBooth?.id!
    );
    return (
      <Box sx={{ gridColumn: ColumnGridColSize, gridRow: ColumnGridRowSize }}>
        <Stack sx={{ height: columnGridHeight, ...adsBoxStyles }}>
          {selectedSponsorAds?.length ? (
          <SponsorAds
            columnWidth={columnWidth}
            selectedSponsorDetails={selectedSponsorBooth}
            sponsorAds={selectedSponsorAds || []}
          />
          ): <NoEvents />}
        </Stack>
      </Box>
    );
  };

  const HandoutsComponent = (
    ColumnGridColSize: string,
    ColumnGridRowSize: string,
    columnGridHeight: string
  ) => {
    const { eventHandouts } = liveEvent;
    return (
      <Box
        sx={{
          gridColumn: ColumnGridColSize,
          gridRow: ColumnGridRowSize,

        }}
      >
        <Stack sx={{ height: columnGridHeight, ...adsBoxStyles }}>
          <CardHeader
            title="Handouts"
            onClick={() => handleMinimizePage("handouts")}
            // hideIcon
          />
          <SessionHandout expoHandouts={eventHandouts} />
        </Stack>
      </Box>
    );
  };

  const getMeetingAndInitCall = async () => {
    try {
      dispatch(
        setJoinExpoBoothRoom({
          ...joinExpoBoothRoom,
          isOpen: false,
          isRoomOpen: true,
          eventId: liveEvent?.event?.id,
          eventExpoBoothId: selectedSponsorBooth?.booth?.id,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const addReaction = (type: string) => {
    dispatch(
      sendReaction(chimeInvitation?.otherPartyInfo?.id!, type, {
        id: user?.id,
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        fullName: user?.fullName,
        reaction: type,
      })
    );
  };

  useEffect(() => {
    const handleResize = () => {
      if (columnRef.current) {
        const width = columnRef.current.offsetWidth;
        setColumnWidth(width);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (columnRef.current && activeList?.includes("audience")) {
      const width = columnRef.current.offsetWidth;
      setColumnWidth(width);
    }
  }, [activeList]);

  useEffect(() => {
    if (
      livePolls?.isPollAppeared ||
      (livePolls?.livePolls && !livePolls.errorMessage)
    ) {
      setIsPollsOpen(true);
    }
  }, [livePolls]);

  useEffect(() => {
    if (event) {
      new EventsService()
        .getEventsAttendeeStatus(event?.id!)
        .then((res) => {
          const eventAttendeeStatus = res.data.audienceStatus;
          setTotalParticipantsCount(res.data.totalAttendees);
          if (eventAttendeeStatus !== "accepted") {
            navigate(`/event/${event && event.slug}/discussion`);
          }
          if (eventAttendeeStatus === "accepted") {
            setIsEventRegistered(true);
          }
        })
        .catch(() => {
          navigate(`/event/${event && event.slug}/discussion`);
        });
    }
  }, [event, navigate]);

  useEffect(() => {
    const liveEventPage = true;
    if (slug) {
      dispatch(init(slug, liveEventPage));
      dispatch(getUpcomingEvent(slug));
    }
    return () => {
      dispatch(unsetInteractiveEvent());
      dispatch(resetLiveEvent());
      dispatch(unsetInteractiveEvent());
    };
  }, [slug, dispatch]);

  useEffect(() => {
    setInterval(() => {
      if (new Date().valueOf < new Date(currentSession?.endTime)?.valueOf) {
        setCurrentSession((prevValue: any) =>
          sessionTimes.at(
            sessionTimes
              .map((e: any) => e.startTime)
              .indexOf(prevValue?.startTime)
          )
        );
      }
    }, 5000);
  });

  useEffect(() => {
    const { event, attendees, ads, speakers, eventHandouts } = liveEvent;
    setAttendees(attendees);
    setEvent(event);
    setEventIdParam(`${event?.id}`);
    const sessions = getEventSessions(event?.rooms);
    setSessionTimes(sessions);
    setStage({
      speakers: speakers,
      moderators: [],
      virtualSpeakers: [],
      guests: [],
    });
    setAds(
      ads?.filter((ad) => ad?.eventSessionId === currentSessionId?.id) || []
    );
    setHandouts(
      eventHandouts?.filter(
        (handout) => handout?.eventSessionId === currentSessionId?.id
      ) || []
    );
  }, [liveEvent, interactiveEvent, currentSessionId]);

  useEffect(() => {
    const handleQueryParams = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const eventId = queryParams.get("eventId");
      const id = queryParams.get("id");
      const role = queryParams.get("role");
      if (eventId && id && role) {
        setIsInteractiveEvent(true);
      } else {
        setIsInteractiveEvent(false);
      }
      setSelectedEventId(eventId!);
      setSelectedRoomId(id!);
      setSelectedEventRole(role!);
    };

    handleQueryParams();
  }, [window.location.search, roomID]);

  useEffect(() => {
    if (event) getQuestionPages(EventQuestionPageTypes.SURVEY);
  }, [event]);

  useEffect(() => {
    if (event && !Object.keys(liveEvent?.tabsCount).length) {
      const isAdmin = event?.isAdmin;
      const isSponsor = !isAdmin && event?.isSponsor;
      const isSpeaker = !isSponsor && event?.isSpeaker;
      const isAttendee = !isSpeaker;
      const sponsorId = isSponsor
        ? event?.eventSponsors?.find((sponsor) =>
            sponsor?.users?.some((sponsorUser) => sponsorUser.id === user.id)
          )?.id
        : undefined;
      const getUserEventRole = () => {
        if (isAdmin) return "admin";
        if (isSponsor) return "sponsor";
        if (isSpeaker) return "speaker";
        if (isAttendee) return "attendee";
      };

      const tabsList = Audience_OPTIONS?.map((tab) => tab.name).join(",");
      dispatch(
        getTabsCount(event?.id!, tabsList, getUserEventRole()!, sponsorId)
      );
    }
  }, [event, liveEvent]);

  useEffect(() => {
    const unsubscribe = subscribeToColorChanges(() => {
      setColors(getBrandingColors());
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (
      event &&
      futureEvent === false &&
      eventStarted &&
      event?.format === "virtual"
    ) {
      webSocket.then((ws) =>
        ws.send(
          JSON.stringify({ action: "join-event", data: { eventId: event?.id } })
        )
      );
    }
  }, [event, webSocket, eventStarted, futureEvent, pastEvent]);

  useEffect(() => {
    if (liveEvent?.event?.id!) {
      dispatch(getEventPolls(liveEvent?.event?.id!));
      dispatch(getEventHandouts(liveEvent?.event?.id!));
    }
  }, [liveEvent?.event?.id]);

  useEffect(() => {
    if (event?.id && currentSessionId?.id)
      dispatch(getPolls(event?.id, currentSessionId?.id!));
  }, [dispatch, currentSessionId?.id, event?.id]);

  useEffect(() => {
    if (liveEventBranding) {
      setColor(
        "Main",
        liveEventBranding?.typography?.main ||
          event?.liveEventBranding?.typography?.main ||
          "#000000"
      );
      setColor(
        "Accent",
        liveEventBranding?.typography?.Accent ||
          event?.liveEventBranding?.typography?.Accent ||
          "#00ffcd"
      );
      setColor(
        "Secondary",
        liveEventBranding?.typography?.Secondary ||
          event?.liveEventBranding?.typography?.Secondary ||
          "#2f2f2f"
      );
      setColor(
        "Text",
        liveEventBranding?.typography?.Text ||
          event?.liveEventBranding?.typography?.Text ||
          "#ffffff"
      );
      setColor('ButtonTextColor',
        liveEventBranding?.typography?.ButtonTextColor ||
          event?.liveEventBranding?.typography?.ButtonTextColor ||
          "#ffffff");

      setColor('MainBackgroundColor',
        liveEventBranding?.typography?.MainBackgroundColor ||
        event?.liveEventBranding?.typography?.MainBackgroundColor ||
        "#ffffff");
      setBranding(
        "media",
        liveEventBranding?.media || event?.liveEventBranding?.media
      );
      setBranding(
        "layout",
        liveEventBranding?.layout || event?.liveEventBranding?.layout || "grid"
      );
      setBranding(
        "moduleDesign",
        liveEventBranding?.moduleDesign ||
          event?.liveEventBranding?.moduleDesign ||
          "box"
      );
      setBranding(
        "controlPanel",
       liveEventBranding?.controlPanel || event?.liveEventBranding?.controlPanel || "right"
      );
    }
  }, [liveEventBranding]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isHovered && isShowReactions) {
      timer = setTimeout(() => {
        setIsShowReactions(false);
      }, 400);
    }
    return () => clearTimeout(timer);
  }, [isHovered, isShowReactions]);

  return isEventRegistered ? (
    <Stack
      height={"calc(100vh - 0px)"}
      sx={{
        backgroundColor: colors?.MainBackgroundColor || "#2F2F2F",
        overflow: 'hidden',
        backgroundImage: `url(${
          isBackgroundImage ? getMinifiedImage(isBackgroundImage) : ""
        })`,
      }}
    >
      <LiveViewMain
        panelPplacement={panelPplacement}
        strings={activeList}
        callbackfn={(item, index) => {
          const ColumnGridColSize: any = calculateGridColSize(
            activeList,
            isActiveBooth,
            index
          );
          const ColumnGridRowSize: any = calculateGridRowSize(
            activeList,
            index
          );
          const columnGridHeight: any = calculateGridHeight(
            activeList,
            isActiveBooth,
            isCallActive,
            index
          );
          const columnCardHeight = getColumnCardHeight(activeList, index);

          switch (item) {
            case "sessions":
              return sessionComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`]
              );

            case "latestCheckedIn":
              return personDetailsComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`]
              );

              case "audienceInsights":
                return personRoleComponent(
                  ColumnGridColSize[`colSizeIndex${index + 1}`],
                  ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                  columnGridHeight[`gridHeightIndex${index + 1}`]
                );

            case "match":
              return matchComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`],
                columnCardHeight
              );

            case "agenda":
              return agendaComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`]
              );

            case "chat":
              return chatComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`],
                columnCardHeight
              );
            case "audience":
              return audienceComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`],
                columnCardHeight
              );

              case "posts":
              return personPostComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`],
              );

            case "polls":
              return pollsComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`],
                columnCardHeight
              );

            // case "admin":
            //   return adminComponent(
            //     ColumnGridColSize[`colSizeIndex${index + 1}`],
            //     ColumnGridRowSize[`rowSizeIndex${index + 1}`],
            //     columnGridHeight[`gridHeightIndex${index + 1}`]
            //   );
            case "sponsors":
              return sponsorComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`],
                columnCardHeight
              );

            case "products":
              return productComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`]
              );

            case "sponsorDetails":
              return SponsorDetailsComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`]
              );

            case "sessionHandouts":
              return SponsorSessionHandoutComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`]
              );

            case "sponsorsAds":
              return SponsorAdsComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`]
              );

            case "handouts":
              return HandoutsComponent(
                ColumnGridColSize[`colSizeIndex${index + 1}`],
                ColumnGridRowSize[`rowSizeIndex${index + 1}`],
                columnGridHeight[`gridHeightIndex${index + 1}`]
              );

            default:
              return null;
          }
        }}
        open={openBranding}
        onClose={() => setOpenBranding(false)}
        boxStyles={boxStyles}
        onClick={() => setOpenBranding(!openBranding)}
        activeList={setActiveList}
      />

      <SidebarMenu
        activeList={activeList}
        setActiveList={setActiveList}
        onSessionClick={onSessionClick}
        onOpenSurvey={() => setOpenSurvey(true)}
        onOpenBranding={() => setOpenBranding(!openBranding)}
        openDrawer={drawer.onTrue}
        openBranding={openBranding}
        event={event}
        isHeaderShow={isHeaderShow}
        panelPplacement={panelPplacement}
        isFocusedSessions={isFocusedSessions}
      />

      {(isCallActive || isActiveBooth) && (
        <Stack
          direction="row"
          sx={{
            display: "flex",
            width: "100%",
            gap: "5px",
            height: "70px",
            background: "#000000",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "80px",
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        >
          {isCallActive && (
            <LiveVideoCallControls
              onClick={toggleMute}
              muted={muted}
              onClick1={toggleVideo}
              videoEnabled={isVideoEnabled}
              onClick2={() => {}}
              in={isShowReactions}
              colors={colors}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              callbackfn={(emoji, index) => {
                const Icon = iconMap[emoji];
                return (
                  <Tooltip title={emoji} placement="top" key={index}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => addReaction(emoji)}
                    >
                      <Icon className="w-8 h-8 text-sky-500" />
                    </div>
                  </Tooltip>
                );
              }}
              onMouseEnter1={() => {
                setIsShowReactions(true);
                setIsHovered(true);
              }}
              onClick3={() => {
                if (meetingManager)
                  meetingManager.leave().finally(() => dispatch(callHangUp()));
              }}
            />
          )}

          {isActiveBooth && (
            <VisitBoothFooter
              onClick={getMeetingAndInitCall}
              colors={colors}
              selectedSponsorBooth={selectedSponsorBooth}
              onClick1={() => {
                setOpenFeedbackPopup(true);
              }}
              open={openFeedbackPopup}
              openFeedbackPopup={setOpenFeedbackPopup}
              onClose={() => {
                setOpenFeedbackPopup(false);
              }}
              onClick2={() => {
                const liveActiveList = sessionStorage.getItem("activeList");
                const list =
                  liveActiveList && liveActiveList !== "undefined"
                    ? JSON.parse(liveActiveList)
                    : ["sessions"];
                sessionStorage.removeItem("activeList");
                setActiveList(list);
              }}
            />
          )}
        </Stack>
      )}
      {openSurvey && (
        <SurveyDialog
          userId={user?.id}
          open={openSurvey}
          setOpenSurveyForm={setOpenSurvey}
          title="Survey Form"
        />
      )}
    </Stack>
  ) : (
    <Stack
      height="calc(100vh - 54px)"
      alignItems="center"
      justifyContent="center"
    >
      <LoadingScreen />
    </Stack>
  );
};
