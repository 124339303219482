import { useState } from "react"
import { getBrandingColors } from "src/mui/pages/event/live/liveEventStyling";

interface CommentSmileSvgProps {
    isLivePage?: boolean;
  }

export const CommentSmileSvg: React.FC<CommentSmileSvgProps> = ({ isLivePage }) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const colors = getBrandingColors();
    const islivePagebg = colors?.Secondary; 
    const liveEmojiColor = isLivePage ? colors?.Text : "#203c6e";
    return <svg xmlns="http://www.w3.org/2000/svg" width={isLivePage ? "26" : "32"} height={isLivePage ? "26" : "32"} viewBox="0 0 32 32"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
    >
        <g id="Group_19802" data-name="Group 19802" transform="translate(-491 -1073)">
        <circle id="Ellipse_1270" data-name="Ellipse 1270" cx="16" cy="16" r="16" transform="translate(491 1073)" fill={ isHover ? "#fff" : isLivePage ? islivePagebg :"#EDF2F5"}/>
        <g id="Group_19798" data-name="Group 19798" transform="translate(14.07 1068.627)">
            <g id="ic_fluent_emoji_24_regular" transform="translate(482.842 10.13)">
            <path id="_-Color" data-name="🎨-Color" d="M8.7,8.662a.947.947,0,1,1-.955.947A.951.951,0,0,1,8.7,8.662Zm4.586,0a.947.947,0,1,1-.955.947A.951.951,0,0,1,13.288,8.662Z" transform="translate(-0.907 -0.979)" fill={liveEmojiColor}/>
            </g>
            <g id="Ellipse_1269" data-name="Ellipse 1269" transform="translate(482.93 10.373)" fill="none" stroke={liveEmojiColor} strokeWidth="1">
            <circle cx="10" cy="10" r="10" stroke="none"/>
            <circle cx="10" cy="10" r="9.5" fill="none"/>
            </g>
            <path id="Path_14658" data-name="Path 14658" d="M0,0A5.238,5.238,0,0,0,4.5,2,5.238,5.238,0,0,0,9,0" transform="translate(488.43 23.873)" fill="none" stroke={liveEmojiColor} strokeLinecap="round" strokeWidth="1"/>
        </g>
        </g>
    </svg>
}