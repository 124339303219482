/* eslint-disable max-len */

export enum COMPANY_QUESTION {
  MARKET_TRENDS = 'What market trends are influencing the demand for your product/service?',
  TECHNOLOGY_UNDERPINS = 'What technology underpins your product/service?',
  KEY_DIFFERENTIATORS = 'What key differentiators set your products apart from competitors? (Select all that apply)',
  PRIMARY_PAIN_POINT = 'What primary pain points do your solutions address for enterprise clients? (Select all that apply)',
}

export enum MARKET_TRENDS_ANSWERS {
  INCREASED_SECURITY = 'Increased focus on cybersecurity',
  REMOTE_WORK = 'Rise of remote work and collaboration tools',
  AI_ML = 'Growth in AI and machine learning applications',
  CLOUD_COMPUTING = 'Shift towards cloud computing',
  DIGITAL_TRANSFORMATION = 'Digital transformation initiatives',
  DATA_DRIVEN = 'Demand for data-driven decision-making',
  GREEN_IT = 'Sustainability and green IT'
}

export enum TECHNOLOGY_UNDERPINS_ANSWERS {
  CLOUD_BASED_PLATFORMS = 'Cloud-based platforms',
  ARTIFICIAL_INTELLIGENCE_AND_MACHINE_LEARNING = 'Artificial Intelligence and Machine Learning',
  GROWTH_IN_AI_AND_ML_APPLICATIONS = 'Growth in AI and machine learning applications',
  INTERNET_OF_THINGS = 'Internet of Things (IoT)',
  BLOCKCHAIN = 'Blockchain',
  BIG_DATA_AND_ANALYTICS = 'Big Data and Analytics',
  VIRTUAL_AND_AUGMENTED_REALITY = 'Virtual and Augmented Reality',
  EDGE_COMPUTING = 'Edge Computing'
}

export enum KEY_DIFFERENTIATORS_ANSWERS {
  ADVANCED_SECURITY_FEATURES = 'Advanced security features',
  SUPERIOR_INTEGRATION_CAPABILITIES = 'Superior integration capabilities',
  USER_FRIENDLY_INTERFACE = 'User-friendly interface',
  SCALABILITY = 'Scalability',
  COST_EFFECTIVENESS = 'Cost-effectiveness',
  CUSTOMISABILITY = 'Customisability',
  HIGH_PERFORMANCE_METRICS = 'High-performance metrics',
  INDUSTRY_SPECIFIC_SOLUTIONS = 'Industry-specific solutions',
  COMPREHENSIVE_SUPPORT_AND_SERVICES = 'Comprehensive support and services',
}

export enum PRIMARY_PAIN_POINT_ANSWERS {
  DATA_SECURITY_AND_COMPLIANCE = 'Data security and compliance',
  OPERATIONAL_EFFICIENCY = 'Operational efficiency',
  COST_REDUCTION = 'Cost reduction',
  SCALABILITY_AND_FLEXIBILITY = 'Scalability and flexibility',
  ENHANCED_CUSTOMER_EXPERIENCE = 'Enhanced customer experience',
  IMPROVED_DECISION_MAKING = 'Improved decision-making',
  STREAMLINED_WORKFLOWS = 'Streamlined workflows',
  INNOVATION_AND_COMPETITIVE_EDGE = 'Innovation and competitive edge',
  COMPREHENSIVE_SUPPORT_AND_SERVICES = 'Comprehensive support and services',
} // Adjust the import path

export enum capabilitySurveyFieldsName {
  MARKET_TRENDS = 'marketTrends',
  TECHNOLOGY_UNDERPINS = 'technologyUnderpins',
  KEY_DIFFERENTIATORS = 'keyDifferentiators',
  PRIMARY_PAIN_POINT = 'primaryPainPoint',
}

export const COMPANY_QUESTIONS = [
  {
    question: COMPANY_QUESTION.MARKET_TRENDS,
    fieldName: 'marketTrends',
    options: [
      { label: MARKET_TRENDS_ANSWERS.INCREASED_SECURITY, value: MARKET_TRENDS_ANSWERS.INCREASED_SECURITY },
      { label: MARKET_TRENDS_ANSWERS.REMOTE_WORK, value: MARKET_TRENDS_ANSWERS.REMOTE_WORK },
      { label: MARKET_TRENDS_ANSWERS.AI_ML, value: MARKET_TRENDS_ANSWERS.AI_ML },
      { label: MARKET_TRENDS_ANSWERS.CLOUD_COMPUTING, value: MARKET_TRENDS_ANSWERS.CLOUD_COMPUTING },
      { label: MARKET_TRENDS_ANSWERS.DIGITAL_TRANSFORMATION, value: MARKET_TRENDS_ANSWERS.DIGITAL_TRANSFORMATION },
      { label: MARKET_TRENDS_ANSWERS.DATA_DRIVEN, value: MARKET_TRENDS_ANSWERS.DATA_DRIVEN },
      { label: MARKET_TRENDS_ANSWERS.GREEN_IT, value: MARKET_TRENDS_ANSWERS.GREEN_IT }
    ]
  },
  {
    question: COMPANY_QUESTION.TECHNOLOGY_UNDERPINS,
    fieldName: 'technologyUnderpins',
    options: [
      { label: TECHNOLOGY_UNDERPINS_ANSWERS.CLOUD_BASED_PLATFORMS, value: TECHNOLOGY_UNDERPINS_ANSWERS.CLOUD_BASED_PLATFORMS },
      { label: TECHNOLOGY_UNDERPINS_ANSWERS.ARTIFICIAL_INTELLIGENCE_AND_MACHINE_LEARNING, value: TECHNOLOGY_UNDERPINS_ANSWERS.ARTIFICIAL_INTELLIGENCE_AND_MACHINE_LEARNING },
      { label: TECHNOLOGY_UNDERPINS_ANSWERS.GROWTH_IN_AI_AND_ML_APPLICATIONS, value: TECHNOLOGY_UNDERPINS_ANSWERS.GROWTH_IN_AI_AND_ML_APPLICATIONS },
      { label: TECHNOLOGY_UNDERPINS_ANSWERS.INTERNET_OF_THINGS, value: TECHNOLOGY_UNDERPINS_ANSWERS.INTERNET_OF_THINGS },
      { label: TECHNOLOGY_UNDERPINS_ANSWERS.BLOCKCHAIN, value: TECHNOLOGY_UNDERPINS_ANSWERS.BLOCKCHAIN },
      { label: TECHNOLOGY_UNDERPINS_ANSWERS.BIG_DATA_AND_ANALYTICS, value: TECHNOLOGY_UNDERPINS_ANSWERS.BIG_DATA_AND_ANALYTICS },
      { label: TECHNOLOGY_UNDERPINS_ANSWERS.VIRTUAL_AND_AUGMENTED_REALITY, value: TECHNOLOGY_UNDERPINS_ANSWERS.VIRTUAL_AND_AUGMENTED_REALITY },
      { label: TECHNOLOGY_UNDERPINS_ANSWERS.EDGE_COMPUTING, value: TECHNOLOGY_UNDERPINS_ANSWERS.EDGE_COMPUTING }
    ]
  },
  {
    question: COMPANY_QUESTION.KEY_DIFFERENTIATORS,
    fieldName: 'keyDifferentiators',
    options: [
      { label: KEY_DIFFERENTIATORS_ANSWERS.ADVANCED_SECURITY_FEATURES, value: KEY_DIFFERENTIATORS_ANSWERS.ADVANCED_SECURITY_FEATURES },
      { label: KEY_DIFFERENTIATORS_ANSWERS.SUPERIOR_INTEGRATION_CAPABILITIES, value: KEY_DIFFERENTIATORS_ANSWERS.SUPERIOR_INTEGRATION_CAPABILITIES },
      { label: KEY_DIFFERENTIATORS_ANSWERS.USER_FRIENDLY_INTERFACE, value: KEY_DIFFERENTIATORS_ANSWERS.USER_FRIENDLY_INTERFACE },
      { label: KEY_DIFFERENTIATORS_ANSWERS.SCALABILITY, value: KEY_DIFFERENTIATORS_ANSWERS.SCALABILITY },
      { label: KEY_DIFFERENTIATORS_ANSWERS.COST_EFFECTIVENESS, value: KEY_DIFFERENTIATORS_ANSWERS.COST_EFFECTIVENESS },
      { label: KEY_DIFFERENTIATORS_ANSWERS.CUSTOMISABILITY, value: KEY_DIFFERENTIATORS_ANSWERS.CUSTOMISABILITY },
      { label: KEY_DIFFERENTIATORS_ANSWERS.HIGH_PERFORMANCE_METRICS, value: KEY_DIFFERENTIATORS_ANSWERS.HIGH_PERFORMANCE_METRICS },
      { label: KEY_DIFFERENTIATORS_ANSWERS.INDUSTRY_SPECIFIC_SOLUTIONS, value: KEY_DIFFERENTIATORS_ANSWERS.INDUSTRY_SPECIFIC_SOLUTIONS },
      { label: KEY_DIFFERENTIATORS_ANSWERS.COMPREHENSIVE_SUPPORT_AND_SERVICES, value: KEY_DIFFERENTIATORS_ANSWERS.COMPREHENSIVE_SUPPORT_AND_SERVICES }
    ]
  },
  {
    question: COMPANY_QUESTION.PRIMARY_PAIN_POINT,
    fieldName: 'primaryPainPoint',
    options: [
      { label: PRIMARY_PAIN_POINT_ANSWERS.DATA_SECURITY_AND_COMPLIANCE, value: PRIMARY_PAIN_POINT_ANSWERS.DATA_SECURITY_AND_COMPLIANCE },
      { label: PRIMARY_PAIN_POINT_ANSWERS.OPERATIONAL_EFFICIENCY, value: PRIMARY_PAIN_POINT_ANSWERS.OPERATIONAL_EFFICIENCY },
      { label: PRIMARY_PAIN_POINT_ANSWERS.COST_REDUCTION, value: PRIMARY_PAIN_POINT_ANSWERS.COST_REDUCTION },
      { label: PRIMARY_PAIN_POINT_ANSWERS.SCALABILITY_AND_FLEXIBILITY, value: PRIMARY_PAIN_POINT_ANSWERS.SCALABILITY_AND_FLEXIBILITY },
      { label: PRIMARY_PAIN_POINT_ANSWERS.ENHANCED_CUSTOMER_EXPERIENCE, value: PRIMARY_PAIN_POINT_ANSWERS.ENHANCED_CUSTOMER_EXPERIENCE },
      { label: PRIMARY_PAIN_POINT_ANSWERS.IMPROVED_DECISION_MAKING, value: PRIMARY_PAIN_POINT_ANSWERS.IMPROVED_DECISION_MAKING },
      { label: PRIMARY_PAIN_POINT_ANSWERS.STREAMLINED_WORKFLOWS, value: PRIMARY_PAIN_POINT_ANSWERS.STREAMLINED_WORKFLOWS },
      { label: PRIMARY_PAIN_POINT_ANSWERS.INNOVATION_AND_COMPETITIVE_EDGE, value: PRIMARY_PAIN_POINT_ANSWERS.INNOVATION_AND_COMPETITIVE_EDGE },
      { label: PRIMARY_PAIN_POINT_ANSWERS.COMPREHENSIVE_SUPPORT_AND_SERVICES, value: PRIMARY_PAIN_POINT_ANSWERS.COMPREHENSIVE_SUPPORT_AND_SERVICES }
    ]
  }
];

export const fieldLabelStyle = 'text-[15px] text-[#000000] leading-[20px] font-medium';

export const timelineScale = [
  {
    value: 1,
    label: '1 months',
  },
  {
    value: 3,
    label: '3 months',
  },
  {
    value: 6,
    label: '6 months',
  },
  {
    value: 12,
    label: '12 months',
  },
  {
    value: 18,
    label: '18 months',
  },
];

export enum CompanyTab {
  BASICDETAILS = 'Basic details',
  CAPABILITIES = 'Capabilities',
  TARGETS = 'Targets',
  PRODUCTS = 'Products'
}

export enum ImagePreviewTab {
  DESKTOP = 'Desktop',
  PHONE = 'Phone',
  TABLET = 'Tablet',
}
export enum UploadAccountStepName {
  REQUIREMENTS = 'Requirements',
  UPLOAD_CSV = 'Upload CSV',
  MAP_FIELDS = 'Map fields',
  BEST_MATCH = 'Best Match',
  NAME_LIST = 'Name list',
}

export const UploadAccountSteps = [
  UploadAccountStepName.REQUIREMENTS,
  UploadAccountStepName.UPLOAD_CSV,
  UploadAccountStepName.MAP_FIELDS,
  UploadAccountStepName.BEST_MATCH,
  UploadAccountStepName.NAME_LIST,
];

export enum REQUIREMENTS_SAVE_FILE {
  COUNT = '1',
  TITLE = 'Save your file as a CSV (max file size of 20MB)',
  DESCRIPTION = 'Make sure your accounts are in CSV (comma-separated values) format and do not exceed 1,000 line items. If your file is larger than 20MB, please split your accounts into separate CSVs',
  LINK = 'Download example template here'
}

export enum REQUIREMENTS_PREFERRED_FIELDS {
  COUNT = '2',
  TITLE = 'If available, include one of the following two preferred fields',
  DESCRIPTION = 'Preferred fields allow you to manually force a match to a company on Panelist',
}

export enum REQUIREMENTS_OTHER_ATTRIBUTES {
  COUNT = '3',
  TITLE = 'Otherwise, include the account name and other attributes',
  DESCRIPTION = 'Additional fields help us process and match your accounts with greater accuracy',
}

export const PanelistButtons: string[] = ['Panelist Company ID', 'Panelist Company URL'];
export const otherAttributesButtons: string[] = ['Account Name *', 'Website URL **', 'Country', 'City', 'Street', 'Stock Symbol'];
