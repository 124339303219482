import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { CitySelector } from '../../../components/selectors/city-selector';
import FormFieldWithCaption from '../../../layouts/form-field-with-caption';
import { IUserProfile } from '../../../models/user-profile';
import { UserService } from '../../../services';
import { Typography } from '../../../shared-components/Typography';

interface IEditUserCard {
  firstName: string;
  lastName: string;
  location: string;
  cardNumber: string;
  expDate: string;
  cvc: string;
}
interface IAddCardDetailsProps {
  onClose: Function;
  currentUser: IUserProfile;
}
export const AddCardDetails = ({ onClose, currentUser }: IAddCardDetailsProps) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IEditUserCard>();
  const [citySelected] = useState<any>(null);

  useEffect(() => {
    setValue('firstName', currentUser.firstName);
    setValue('lastName', currentUser.lastName);
    setValue('location', currentUser.location);
  }, [currentUser, setValue]);

  const onSubmit = async (data: IEditUserCard) => {
    const countryCity = data.location.split(',');
    const expDate = data.expDate.split('/');
    const cardInfo: any = {};
    cardInfo.name = `${data.firstName} ${data.lastName}`;
    cardInfo.address_line1 = data.location;
    cardInfo.address_zip = '';
    cardInfo.address_country = countryCity.length === 2 ? countryCity[1] : countryCity[2];
    cardInfo.number = data.cardNumber.replace('-', '').replace('-', '').replace('-', '');
    cardInfo.cvc = data.cvc;
    cardInfo.exp_month = expDate[0].toLocaleLowerCase();
    cardInfo.exp_year = expDate[1].toLocaleLowerCase();
    const dataToSubmit: any = { card: {} };
    dataToSubmit.card = cardInfo;
    const userSerivce = new UserService();
    const token = await userSerivce.createCardToken(dataToSubmit);
    await userSerivce.createCard(token.id);
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full">
        <div className="flex flex-col p-[18px]">
          <div className="text-[#0B1221] font-medium text-[15px] leading-[26px] border-b-1 border-[#CDDCF2] pb-[9px]">
            Enter your credit card details.
          </div>
          {/* First name and last name */}
          <div className="mt-4">
            <div className="flex flex-row gap-[25px]">
              <FormFieldWithCaption caption="First name*" className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]">
                <>
                  <div className="mt-[1px]">
                    <input
                      type="text"
                      className="w-full h-[31px] text-[13px] leading-[15px] font-light text-[#3C3C3C] px-[12px] border-1 border-[#CDDCF2] rounded-[10px] focus-visible:outline-none"
                      {...register('firstName', {
                        required: {
                          value: true,
                          message: 'First name is required',
                        },
                      })}
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    {errors.firstName && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.firstName?.message!}
                        classes="flex content-start mt-1"
                      />
                    )}
                  </div>
                </>
              </FormFieldWithCaption>
              <FormFieldWithCaption caption="Last name*" className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]">
                <>
                  <div className="mt-[1px]">
                    <input
                      type="text"
                      className="w-full h-[31px] text-[13px] leading-[15px] font-light text-[#3C3C3C] px-[12px] border-1 border-[#CDDCF2] rounded-[10px] focus-visible:outline-none"
                      {...register('lastName', {
                        required: {
                          value: true,
                          message: 'Last name is required',
                        },
                      })}
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    {errors.firstName && (
                      <Typography
                        variant="ValidationMessage"
                        text={errors.lastName?.message!}
                        classes="flex content-start mt-1"
                      />
                    )}
                  </div>
                </>
              </FormFieldWithCaption>
            </div>
          </div>
          {/* Location */}
          <div className="mt-4">
            <FormFieldWithCaption caption="Address" className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]">
              <>
                <div className="border-1 border-[#CDDCF2] rounded-[10px] mt-[1px]">
                  <CitySelector
                    classes="w-full rounded-[10px] h-[31px] text-[13px] leading-[15px] pl-[12px] text-[#3C3C3C] focus-visible:outline-none"
                    setValue={setValue}
                    citySelected={citySelected}
                    register={register}
                    placeholder="Enter your city"
                  />
                </div>
                <div>
                  {errors.location && (
                    <Typography
                      variant="ValidationMessage"
                      text={errors.location?.message!}
                      classes="flex content-start mt-1"
                    />
                  )}
                </div>
              </>
            </FormFieldWithCaption>
          </div>
          {/* creadit card input */}
          <div className="mt-4">
            <FormFieldWithCaption caption="Card number*" className="w-full" captionClassName="text-[13px] font-medium leading-[15px] h-[19px] text-[#3C3C3C]">
              <div className="mt-1 rounded-10 text-sm1 flex gap-2">
                <div className="w-[50%] h-[31px] text-[13px] leading-[15px] font-light text-[#3C3C3C] px-[12px] pl-0 border-1 border-[#CDDCF2] rounded-[5px] flex items-center">
                  <select className="bg-[#CDDCF2] rounded-l-10 h-[31px] text-center">
                    <option value="volvo">Visa</option>
                    <option value="saab">Master</option>
                  </select>
                  <InputMask
                    id="thePhone"
                    type="text"
                    mask="9999-9999-9999-9999"
                    className="w-full h-[29px] text-[13px] leading-[15px] font-light text-[#3C3C3C] px-[12px] rounded-[5px] focus-visible:outline-none flex items-center"
                    placeholder="Card number"
                    {...register('cardNumber', {
                      required: {
                        value: true,
                        message: 'Card number is required',
                      },
                    })}
                  />
                </div>
                <InputMask
                  id="thePhone"
                  type="text"
                  mask="99/99"
                  className="w-[25%] h-[31px] text-[13px] leading-[15px] font-light text-[#3C3C3C] px-[12px] border-1 border-[#CDDCF2] rounded-[5px] focus-visible:outline-none flex items-center"
                  placeholder="MM / YY"
                  {...register('expDate', {
                    required: {
                      value: true,
                      message: 'expDate is required',
                    },
                  })}
                />
                <InputMask
                  id="thePhone"
                  type="text"
                  mask="999"
                  className="w-[25%] h-[31px] text-[13px] leading-[15px] font-light text-[#3C3C3C] px-[12px] border-1 border-[#CDDCF2] rounded-[5px] focus-visible:outline-none flex items-center"
                  placeholder="CVC"
                  {...register('cvc', {
                    required: {
                      value: true,
                      message: 'CVC is required',
                    },
                  })}
                />
              </div>
            </FormFieldWithCaption>
          </div>
          <div className="mt-4">
            <div className="flex flex-row justify-end pt-3">
              <div>
                <button
                  type="button"
                  className="rounded-5 inline-block text-sm font-semibold h-[31px] bg-[#EDF2F5] text-[#203C6E] w-[88px] mr-[10px] hover:bg-[#CEDCF2] tracking-wide px-4"
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
              </div>
              <div className="">
                <button
                  type="submit"
                  className="rounded-full text-sm font-semibold h-[31px] bg-[#0049EF] hover:bg-[#244E93] text-white w-[74px] tracking-wide px-4"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddCardDetails;
