import React, { FC } from 'react';

export interface IImageIButtonProps extends React.ComponentProps<'button'> {
  imageUrl: string;
}

export const ImageButton: FC<IImageIButtonProps> = ({ imageUrl, type = 'submit', ...rest }) => {
  return (
    <button className="inline-flex items-center w-30 h-30" {...rest} type={type}>
      <img src={imageUrl} alt="" />
    </button>
  );
};
