import React from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface IBrandingSvgIcon {
  isActive?: boolean;
}

export const BrandingSvgIconActive = ({ isActive }: IBrandingSvgIcon) => {
    const colors = getBrandingColors();
    const defaultColor = '#66686c';
    const iconColor = isActive ? (colors?.Accent || '#03FFCD') : (colors?.Accent || defaultColor);

    return isActive ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 24 24"
            width="25"
            height="25"
        >
            <path
                d="M23.305,16.25a1.888,1.888,0,0,1-1.377,1.177,1.912,1.912,0,0,1-1.769-.521l-.1-.1a3.567,3.567,0,0,0-6.089,2.553l.04,4.516-.924.077c-.331.028-.663.05-1,.05A12,12,0,0,1,3.745,3.371,11.885,11.885,0,0,1,12.5.007,12.155,12.155,0,0,1,24.08,11.7,11.924,11.924,0,0,1,23.305,16.25Zm-6.19-8.2A1.5,1.5,0,1,0,18.95,9.115,1.5,1.5,0,0,0,17.115,8.05Zm-5-3A1.5,1.5,0,1,0,13.95,6.115,1.5,1.5,0,0,0,12.115,5.05Zm-5,3A1.5,1.5,0,1,0,8.95,9.115,1.5,1.5,0,0,0,7.115,8.05Zm0,6A1.5,1.5,0,1,0,8.95,15.115,1.5,1.5,0,0,0,7.115,14.05Z"
                fill={iconColor}
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 28.325 28.57">
  <g id="_01_align_center" data-name="01 align center" transform="translate(-0.585 -0.255)">
    <path id="Subtraction_2" data-name="Subtraction 2" d="M-10575.128,3176.067h0a14.188,14.188,0,0,1-10.026-4.356,14.2,14.2,0,0,1-4.009-10.176,14.193,14.193,0,0,1,4.275-9.945,14.2,14.2,0,0,1,10.007-4.093h.25a14.44,14.44,0,0,1,9.607,4.273,14.433,14.433,0,0,1,4.178,9.65,14.12,14.12,0,0,1-.921,5.419,2.048,2.048,0,0,1-1.49,1.273,2.049,2.049,0,0,1-.443.048,2.079,2.079,0,0,1-1.47-.615l-.119-.12a4.551,4.551,0,0,0-3.234-1.34,4.548,4.548,0,0,0-1.768.356,4.552,4.552,0,0,0-2.8,4.255l.048,5.238-.886.074C-10574.314,3176.042-10574.723,3176.067-10575.128,3176.067Zm-.059-26.651a12.282,12.282,0,0,0-8.72,3.6,12.292,12.292,0,0,0-3.644,8.709,12.283,12.283,0,0,0,3.582,8.758,12.285,12.285,0,0,0,8.728,3.662h.253l-.029-3.431a6.573,6.573,0,0,1,1.9-4.651,6.463,6.463,0,0,1,4.594-1.9,6.459,6.459,0,0,1,4.592,1.9l.295.3.133-.369a12.2,12.2,0,0,0,.736-4.523,12.482,12.482,0,0,0-3.615-8.343,12.472,12.472,0,0,0-8.307-3.7h-.1v0h-.4Z" transform="translate(10589.75 -3147.242)" fill={iconColor}/>
    <g id="Group_29054" data-name="Group 29054" transform="translate(7.753 6.558)">
      <path id="Subtraction_3" data-name="Subtraction 3" d="M-10627.865,3122.667a1.32,1.32,0,0,1-1.318-1.318,1.32,1.32,0,0,1,1.318-1.319,1.318,1.318,0,0,1,1.315,1.319A1.318,1.318,0,0,1-10627.865,3122.667Z" transform="translate(10641.298 -3116.397)" fill={iconColor}/>
      <path id="Subtraction_4" data-name="Subtraction 4" d="M-10627.865,3122.667a1.32,1.32,0,0,1-1.318-1.318,1.32,1.32,0,0,1,1.318-1.319,1.318,1.318,0,0,1,1.315,1.319A1.318,1.318,0,0,1-10627.865,3122.667Z" transform="translate(10635.239 -3120.031)" fill={iconColor}/>
      <path id="Subtraction_5" data-name="Subtraction 5" d="M-10627.865,3122.667a1.32,1.32,0,0,1-1.318-1.318,1.32,1.32,0,0,1,1.318-1.319,1.318,1.318,0,0,1,1.315,1.319A1.318,1.318,0,0,1-10627.865,3122.667Z" transform="translate(10629.183 -3116.397)" fill={iconColor}/>
      <path id="Subtraction_6" data-name="Subtraction 6" d="M-10627.865,3122.667a1.32,1.32,0,0,1-1.318-1.318,1.32,1.32,0,0,1,1.318-1.319,1.318,1.318,0,0,1,1.315,1.319A1.318,1.318,0,0,1-10627.865,3122.667Z" transform="translate(10629.183 -3109.128)" fill={iconColor}/>
    </g>
  </g>
</svg>
    );
};
