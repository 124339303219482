import {
  ActionCreator,
  Dispatch,
} from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IPost } from '../../../models/post/post';
import {
  PostService,
  UserService,
} from '../../../services';
import { AllStoryState } from './reducer';
import {StoryActionTypes} from './types';


export interface IStoryLoadingAction {
  type: StoryActionTypes.ALL_STORY_LOADING;
  loading: boolean;
}

export interface IStorySuccessAction {
  type: StoryActionTypes.ALL_STORY_SUCCESS;
  payload: IPost[];
}
export interface IStoryErrorAction {
  type: StoryActionTypes.ALL_STORY_ERROR;
  errorMessage: string;
}

  export type allStoriesActions =
  | IStoryLoadingAction
  | IStorySuccessAction
  | IStoryErrorAction;

export const getAllStories: ActionCreator<
  ThunkAction<Promise<any>, AllStoryState, null, IStorySuccessAction>
> = (page: number = 1, orderBy: string = "Most recent") => {
  return async (dispatch: Dispatch) => {
    const userService: UserService = new UserService();

    let sortBy: string = "";
    if (orderBy === "Most recent") sortBy = "&order[createdAt]=DESC";
    else sortBy = "&order[rank]=DESC";

    try {
      const result = await userService.getAllStories(page, sortBy);

      dispatch({
        type: StoryActionTypes.ALL_STORY_SUCCESS,
        payload: result,
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: StoryActionTypes.ALL_STORY_ERROR,
        errorMessage: error.message,
      });
    } finally {
      dispatch({
        type: StoryActionTypes.ALL_STORY_LOADING,
        loading: false,
      });
    }
  };
};

