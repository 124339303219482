import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import MenuCopyLink from '../../../../assets/images/event-landing-pre-event/copy-link-icon.svg';
import MenuArrowRight from '../../../../assets/images/event-landing-pre-event/events-website-arrow-icon.svg';
import MenuMailIn from '../../../../assets/images/event-landing-pre-event/mail-in-icon.svg';
import MenuForward from '../../../../assets/images/event-landing-pre-event/paper-airplane-icon.svg';
import MenuReport from '../../../../assets/images/event-landing-pre-event/report-alert-icon.svg';
import MenuShare from '../../../../assets/images/event-landing-pre-event/share-arrow-icon.svg';

// Components
import AlertModal from '../../../../components/alert-modal/alert-modal';
import {
  DropDownMenu,
  IDropdownMenuItem,
} from '../../../../components/dropdown-menu';
import Report from '../../../../components/report';
import { EVENT } from '../../../../constants';
// Models & store
import { IEvent } from '../../../../models/event/event';
import { IFlagDTO } from '../../../../models/flag/flag';
import { IUserProfile } from '../../../../models/user-profile';
import { FlagService } from '../../../../services/Flag';
import { IAppState } from '../../../../store';
import { openMessageWindow } from '../../../../store/newMessage';
// Utils
import useCopyToClipboard from '../../../../utils/hooks/copyToClipboard';
import useFullUrl from '../../../../utils/hooks/fullUrl';
import { InviteConnections } from './InviteConnections';
import ShareEvent from './ShareEvent';

interface IFrame1Props {
  event: IEvent;
  user: IUserProfile;
  dropdownClassName?: string;
}
export const ActionOfCardEvent1 = ({
  event,
  user,
  dropdownClassName,
}: IFrame1Props) => {
  const [toggleModalInviteConnection, setToggleModalInviteConnection] = useState(false);
  const [toggleModalShareEvent, setToggleModalShareEvent] = useState(false);
  const [isReportPost, setIsReportPost] = useState(false);

  const { newMessage } = useSelector((state: IAppState) => state);

  const isModalOpen = useSelector((state: IAppState) => state.isModalOpen);

  const dispatch = useDispatch();
  const getFullUrl = useFullUrl();
  const { isCopied, handleCopy } = useCopyToClipboard();

  // To Website Event
  const toWebsiteEvent = () => {
    const utmUrl = event?.eventUtmUrl?.find(
      (utm) => utm.type === 'panelist'
    )?.url;
    window?.open(
      `/${event?.company?.name.split(' ').join('-')}/${event?.slug}${
        utmUrl ? `?${utmUrl}` : ''
      }`,
      '_blank'
    );
  };

  // Invite Connections
  useEffect(() => {
    setToggleModalInviteConnection(isModalOpen.isOpen);
  }, [dispatch, event.id, isModalOpen]);

  const onInviteConnection = () => {
    setToggleModalInviteConnection(!toggleModalInviteConnection);
  };

  // Forward event to message
  const sendAMessage = () => {
    const fullUrl = getFullUrl(`/event/${event.slug}/discussion`);
    dispatch(
      openMessageWindow({
        avatar: '',
        userDisplayName: 'New Message',
        messageText: fullUrl,
      })
    );
  };

  // Share event post
  const onShareEvent = () => {
    setToggleModalShareEvent(!toggleModalShareEvent);
  };

  // Copy link to clipboard
  const copyToClipboard = () => {
    const fullUrl = getFullUrl(`/event/${event.slug}/discussion`);
    handleCopy(fullUrl);
    if (isCopied) toast.success(EVENT.COPY);
  };

  // Report event
  const reportPost = () => {
    setIsReportPost(!isReportPost);
  };

  const optionsToSelect: IDropdownMenuItem[] = [
    {
      id: 1,
      type: 'icon',
      text: "Event's website",
      icon: MenuArrowRight,
      onClickHandler: () => toWebsiteEvent(),
    },
    {
      id: 2,
      type: 'icon',
      text: 'Invite connections',
      icon: MenuMailIn,
      onClickHandler: () => onInviteConnection(),
    },
    {
      id: 3,
      type: 'icon',
      text: 'Forward',
      icon: MenuForward,
      onClickHandler: () => sendAMessage(),
    },
    {
      id: 4,
      type: 'icon',
      text: 'Share',
      icon: MenuShare,
      onClickHandler: () => onShareEvent(),
    },
    {
      id: 5,
      type: 'icon',
      text: 'Copy Link',
      icon: MenuCopyLink,
      onClickHandler: () => copyToClipboard(),
    },
    {
      id: 6,
      type: 'icon',
      text: 'Report',
      icon: MenuReport,
      onClickHandler: () => reportPost(),
    },
  ];

  return (
    <>
      <DropDownMenu
        options={optionsToSelect}
        containerClasses="flex justify-center items-center mt-1 relative"
        menuCardClasses={classNames({
          'absolute w-208 opacity-100 bg-white right-[3px] mt-2.5 post-company-logo rounded-10 p-2 z-10':
            true,
          [`${dropdownClassName}`]: dropdownClassName,
          hidden:
            toggleModalInviteConnection
            || toggleModalShareEvent
            || isReportPost
            || newMessage.isOpen,
        })}
        titleAndSubtitleGroup="flex justify-left items-left hover:bg-gray-1 text-left p-2 rounded-5 text-gray-13 cursor-pointer"
        buttonStyles="flex items center w-30 h-30 ml-2 -mt-2"
        listTextStyles="text-sm1 text-blue-3 tracking-wide ml-3"
        listIconStyles=""
        textAndImageContainer="flex w-full h-full items-center"
        fillColorActive
      />
      {toggleModalInviteConnection && (
        <AlertModal
          className="w-576"
          isOpen={toggleModalInviteConnection}
          onClose={onInviteConnection}
          closeIconClass=" right-[18px] top-[15px] px-0"
          isCloseIcon
        >
          <InviteConnections
            eventId={event.id}
            onCloseModal={onInviteConnection}
          />
        </AlertModal>
      )}
      {toggleModalShareEvent && (
        <AlertModal
          className="w-576"
          isOpen={toggleModalShareEvent}
          onClose={onShareEvent}
          closeIconClass=" right-[18px] top-[15px] px-0"
          isCloseIcon
        >
          <ShareEvent event={event} user={user} onCloseShare={onShareEvent} />
        </AlertModal>
      )}
      {isReportPost && (
        <AlertModal
          className="w-576"
          isOpen={isReportPost}
          onClose={reportPost}
          closeIconClass=" right-[18px] top-[15px] px-0"
          isCloseIcon
        >
          <Report
            id={event.id}
            type="event"
            onReport={async (id: string, type: string, reason: string) => {
              const flagService = new FlagService();
              const flagItem: IFlagDTO = {
                flaggableId: id,
                flaggableType: type,
                reason,
              };
              await flagService.flagItem(flagItem);
            }}
            onCloseReport={reportPost}
          />
        </AlertModal>
      )}
    </>
  );
};
export default ActionOfCardEvent1;
