/* eslint-disable react/no-unused-prop-types */
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { IEventSession } from '../../../models/event/event-session';
import { Logo } from '../../../shared-components/V2/Logo';
import { ParseContent } from '../../../utils/parse-html';
import WebsiteEventPartnerList from './website-event-day-partnerlist';
import WebsiteEventSpeakerList from './website-event-day-speakerlist';

interface IAgendaBody {
  eventOverview: string;
  roomQty?: number;
}
export const AgendaBody = ({ eventOverview, roomQty }: IAgendaBody) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div
      className={
        classNames(
          'w-[723px]',
          {
            '!p-0': roomQty === 2
          }
        )
      }
    >
      <div className="text-[13px] font-light leading-5 text-[#3C3C3C] mb-5">
        {
          showMore ? <ParseContent content={eventOverview} /> : <ParseContent content={eventOverview?.substring(0, 350)} />
        }
        { eventOverview?.length > 350 && <button type="button" onClick={() => setShowMore(!showMore)}>{ showMore ? 'Show less' : 'Show more'}</button> }
      </div>
    </div>
  );
};

export const AgendaBodyLobby = ({ eventOverview, roomQty }: IAgendaBody) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div
      className={
        classNames(
          'w-full',
          {
            '!p-0': roomQty === 2
          }
        )
      }
    >
      <div className="text-[13px] font-light leading-5 text-[#3C3C3C] mb-5">
        {
          showMore ? <ParseContent content={eventOverview} /> : <ParseContent content={eventOverview?.substring(0, 350)} />
        }
        { eventOverview?.length > 350 && <button type="button" onClick={() => setShowMore(!showMore)}>{ showMore ? 'Show less' : 'Show more'}</button> }
      </div>
    </div>
  );
};
interface WebsiteEventAgendaProps {
  session: IEventSession;
  roomQty?: number;
  eventOverview?: string;
  isTitleLobby?: boolean;
}

export const AgendaTitle = ({
  session,
  roomQty,
  eventOverview,
  isTitleLobby
}: WebsiteEventAgendaProps) => (
  <div
    className={
      classNames(
        'w-full flex items-center',
        {
          'table-column': isTitleLobby
        }
      )
    }
  >
    <div className="flex">
      <div
        className={
          classNames(
            'pl-[19px] flex items-start md:flex-row flex-col close-collapse-with',
            {
              'md:!block': roomQty === 2,
              'pl-[16px]': isTitleLobby
            }
          )
        }
      >
        <span
          className={
            classNames(
              'text-[14px] leading-5 font-semibold text-[#0B1221] flex items-center',
              {
                'float-left mb-[6px]': roomQty === 2,
                'min-w-[85px] max-w-[84px]': roomQty !== 2,
                'text-[13px] leading-[16px] items-baseline flex-col': isTitleLobby
              }
            )
          }
        >
          {format(parseISO(session.startTime), 'HH:mm')}
          {' '}
          -
          {format(parseISO(session.endTime), 'HH:mm')}
          <div
            className={
              classNames(
                'text-[12px] leading-[17px] font-normal text-[#0B1221] ml-[0px] capitalize flex items-center',
                {
                  hidden: !isTitleLobby
                }
              )
            }
          >
            {session.type}
          </div>
        </span>
        <div
          className={
            classNames(
              'text-[14px] leading-5 font-normal text-[#0B1221] ml-[29px] capitalize flex items-center',
              {
                '!flex !-mt-[0px] float-left mb-[6px]': roomQty === 2,
                hidden: isTitleLobby
              }
            )
          }
        >
          {session.type}
        </div>
        <div
          className={
            classNames(
              'grid pl-[30px]',
              {
                '!pl-0 float-left w-full': roomQty === 2,
                'min-w-[723px] max-w-[723px]': !isTitleLobby && roomQty !== 2,
                '!min-w-[500x] !max-w-[500px] pl-[16px] mt-[2px]': isTitleLobby
              }
            )
          }
        >
          <span
            className={
              classNames(
                'text-[14px] leading-5 font-medium text-[#0B1221] flex items-center -mt-[2px]',
                {
                  '!leading-5': roomQty === 2,
                  'text-[13px] leading-5 font-medium': isTitleLobby
                }
              )
            }
          >
            {session.title}
          </span>
          <div className="hide-on-tab-open">
            <div className="flex ml-0">
              {
                session && session?.eventSpeakers[0]?.firstName
                && (
                  <div className="w-auto mt-2 min-w-[165px]">
                    <div className="text-left flex">
                      {
                        session && session?.eventSpeakers[0]?.firstName
                        && (
                          <div className="w-[49px] min-w-[49px] h-[49px] max-h-[49px] rounded-[20px] overflow-hidden mr-[10px] flex items-center justify-center">
                            <Logo src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${session?.eventSpeakers[0]?.photo!}`} className="w-full h-full object-cover medium" />
                          </div>
                        )
                      }
                      <div className="flex flex-col justify-center">
                        <div className="text-[13px] leading-[16px] text-[#172746] font-semibold line-clamp-1">
                          {session?.eventSpeakers[0]?.firstName}
                          {' '}
                          {session?.eventSpeakers[0]?.lastName}
                        </div>
                        <div className="text-[11px] leading-[13px] text-[#18161C] font-normal line-clamp-1">
                          {session?.eventSpeakers[0]?.jobTitle}
                        </div>
                        <div className="text-[11px] leading-[13px] text-[#18161C] font-normal line-clamp-1">
                          {session?.eventSpeakers[0]?.company.name}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              {
                session?.eventSponsors[0]?.company.name
                && (
                  <div
                    className={
                      classNames(
                        'flex ml-[182px] mt-2',
                        {
                          'ml-[30px] max-w-[190px]': isTitleLobby
                        }
                      )
                    }
                  >
                    <div className="w-full">
                      <div className="mr-10 text-left flex min-w-[200px]">
                        {
                          session?.eventSponsors[0]?.company?.logo
                          && (
                            // eslint-disable-next-line max-len
                            <div className="w-[49px] min-w-[49px] h-[49px] post-company-logo small-radius bg-white mr-[10px] p-1 flex items-center justify-center">
                              <Logo
                                src={`${
                                  process.env.REACT_APP_IMAGE_URL_PREFIX}/${session?.eventSponsors[0]?.logo ?? session?.eventSponsors[0]?.company?.logo}`}
                                className="w-full h-full object-contain x-small-radius"
                              />
                            </div>
                          )
                        }
                        <div className="flex flex-col justify-start">
                          <div className="text-[13px] leading-[16px] text-[#172746] font-semibold pt-[3px] line-clamp-1">
                            {session?.eventSponsors[0]?.company.name}
                          </div>
                          <div className="text-[11px] leading-[13px] text-[#18161C] font-normal line-clamp-1">
                            {session?.eventSponsors[0]?.sponsorship}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
          <div
            className={
              classNames(
                'hide-on-tab-close mt-2',
                {
                  hidden: isTitleLobby
                }
              )
            }
          >
            <AgendaBody eventOverview={eventOverview!} />
            <div
              className={
                classNames(
                  'flex items-start',
                  {
                    '!block': roomQty === 2
                  }
                )
              }
            >
              <WebsiteEventSpeakerList speakers={session.eventSpeakers} roomQty={roomQty} />
              <WebsiteEventPartnerList sponsors={session.eventSponsors} roomQty={roomQty} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className={
        classNames(
          'hide-on-tab-close mt-[20px] pl-[17px]',
          {
            hidden: !isTitleLobby
          }
        )
      }
    >
      <AgendaBodyLobby eventOverview={eventOverview!} />
      <div
        className={
          classNames(
            'flex items-start',
            {
              '!block': roomQty === 2
            }
          )
        }
      >
        <WebsiteEventSpeakerList speakers={session.eventSpeakers} roomQty={roomQty} />
        <WebsiteEventPartnerList isTitleLobby sponsors={session.eventSponsors} roomQty={roomQty} />
      </div>
    </div>
  </div>
);

interface IAgendaBodyTwo {
  eventOverview: string;
  roomQty?: number;
}
export const AgendaBodyTwo = ({ eventOverview, roomQty }: IAgendaBodyTwo) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div
      className={
        classNames(
          {
            '!p-0': roomQty === 2
          }
        )
      }
    >
      <div className="text-[13px] font-light leading-5 text-[#3C3C3C] mb-5">
        {
          showMore ? <ParseContent content={eventOverview} /> : <ParseContent content={eventOverview?.substring(0, 350)} />
        }
        { eventOverview?.length > 350 && <button type="button" onClick={() => setShowMore(!showMore)}>{ showMore ? 'Show less' : 'Show more'}</button> }
      </div>
    </div>
  );
};

interface AgendaTitleBodyProps {
  session: IEventSession;
  roomQty?: number;
  eventOverview?: string;
}

export const AgendaTitleBody = ({
  session,
  roomQty,
  eventOverview
}: AgendaTitleBodyProps) => (
  <div className="w-full flex items-center">
    <div className="flex">
      <div
        className={
          classNames(
            'pl-[19px] flex items-start md:flex-row flex-col md:!w-full ',
            {
              'md:!block': roomQty === 2
            }
          )
        }
      >
        <span
          className={
            classNames(
              'text-[14px] w-[101px] leading-5 font-semibold text-[#0B1221] flex items-center',
              {
                'float-left mb-[6px]': roomQty === 2,
                'min-w-[86px] max-w-[84px]': roomQty !== 2
              }
            )
          }
        >
          {format(parseISO(session.startTime), 'HH:mm')}
          {' '}
          -
          {format(parseISO(session.endTime), 'HH:mm')}
        </span>
        <div
          className={
            classNames(
              'text-[14px] w-[75px] leading-5 font-normal text-[#0B1221] md:ml-[29px] capitalize flex items-center',
              {
                '!flex !-mt-[0px] float-left mb-[6px]': roomQty === 2,
              }
            )
          }
        >
          {session.type}
        </div>
        <div
          className={
            classNames(
              'md:grid flex flex-col md:flex-row md:pl-[30px] md:w-[700px] float-left md:mt-[3px] md:ml-[30px]',
              {
                '!pl-0 float-left w-full': roomQty === 2,
                'min-w-[723px] max-w-[723px]': roomQty !== 2
              }
            )
          }
        >
          <span
            className={
              classNames(
                'text-[14px] leading-5 font-medium text-[#0B1221] flex items-center -mt-[2px]',
                {
                  '!leading-5': roomQty === 2
                }
              )
            }
          >
            {session.title}
          </span>
          <div className="hide-on-tab-open">
            <div className="flex ml-0 flex-col md:flex-row md:gap-x-[70px]">
              {
                session && session?.eventSpeakers[0]?.firstName
                && (
                  <div className="w-auto mt-2 max-w-[300px] min-w-[300px]">
                    <div className="text-left flex">
                      {
                        session && session?.eventSpeakers[0]?.firstName
                        // eslint-disable-next-line max-len
                        && <Logo src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${session && session?.eventSpeakers[0]?.photo!}`} className="w-[49px] h-[49px] object-contain rounded-[20px] bg-gray-1 mr-[10px]" />
                      }
                      <div className="flex flex-col justify-center">
                        <div className="text-[13px] leading-[16px] text-[#172746] font-semibold line-clamp-1">
                          {session && session?.eventSpeakers[0]?.firstName}
                          {' '}
                          {session && session?.eventSpeakers[0]?.lastName}
                        </div>
                        <div className="text-[11px] leading-[13px] text-[#18161C] font-normal line-clamp-1">
                          {session && session?.eventSpeakers[0]?.jobTitle}
                        </div>
                        <div className="text-[11px] leading-[13px] text-[#18161C] font-normal line-clamp-1">
                          {session && session?.eventSpeakers[0]?.company.name}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              {
                session && session?.eventSponsors[0]?.company.name
                && (
                  <div className="flex mt-2 max-w-[215px] min-w-[215px]">
                    <div className="w-full">
                      <div className="mr-10 text-left flex">
                        {
                          session && (session?.eventSponsors[0]?.logo || session?.eventSponsors[0]?.company?.logo)
                          && (
                            // eslint-disable-next-line max-len
                            <div className="w-[49px] min-w-[49px] max-h-[49px] h-[49px] post-company-logo small-radius bg-white mr-[10px] flex items-center justify-center">
                              <Logo
                                src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${session?.eventSponsors[0]?.company?.logo ?? session?.eventSponsors[0]?.company?.logo}`}
                                className="w-[47px] h-[47px] object-contain p-1 x-small-radius"
                              />
                            </div>
                          )
                        }
                        <div className="flex flex-col justify-start min-w-[200px]">
                          <div className="text-[13px] leading-[16px] text-[#172746] font-semibold pt-[3px] line-clamp-1">
                            {session && session?.eventSponsors[0]?.company.name}
                          </div>
                          <div className="text-[11px] leading-[13px] text-[#18161C] font-normal line-clamp-1">
                            {session && session?.eventSponsors[0]?.sponsorship}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
          <div className="hide-on-tab-close mt-2">
            <AgendaBodyTwo eventOverview={eventOverview!} />
            <div
              className={
                classNames(
                  'flex items-start md:flex-row flex-col md:gap-x-[125px]',
                  {
                    // '!block': roomQty === 2
                  }
                )
              }
            >
              <WebsiteEventSpeakerList isOpen speakers={session.eventSpeakers} roomQty={roomQty} />
              <WebsiteEventPartnerList isOpen sponsors={session.eventSponsors} roomQty={roomQty} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
