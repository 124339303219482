import classNames from 'classnames';
import { IEventSessionSpeaker } from '../../../models/event/event-speaker';
import { Avatar } from '../../../shared-components/V2';

interface IWebsiteEventSpeakerList {
  speakers: IEventSessionSpeaker[];
  roomQty?: number;
  isOpen?: boolean;
}

const WebsiteEventSpeakerList = ({ speakers, roomQty, isOpen }: IWebsiteEventSpeakerList) => (
  <div
    className={
      classNames(
        {
          '!p-0 mb-[15px]': roomQty === 2,
          'max-w-[215px] min-w-[215px]': isOpen
        }
      )
    }
  >
    {
      !!speakers && speakers?.length > 0
      && <div className="text-[14px] text-[#0B1221] font-medium h-[20px] mb-2.5">Speakers</div>
    }
    {!!speakers && speakers?.map((speaker: any) => {
      const speakerName = `${speaker.firstName} ${speaker.lastName}`;

      return (
        <div className="flex mb-2.5 last:mb-0">
          <div className="w-full mx-auto rounded-10">
            <div
              className={
                classNames(
                  'mr-10 text-left flex ',
                  {
                    'mr-0': isOpen
                  }
                )
              }
            >
              <Avatar src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${speaker.photo}`} size="MEDIUM" />
              <div className="flex flex-col justify-center pl-[10px]">
                <div className="text-[13px] leading-[16px] text-[#172746] font-semibold line-clamp-1">{speakerName}</div>
                <div className="text-[11px] leading-[13px] text-[#18161C] font-normal line-clamp-1">
                  {speaker.jobTitle}
                </div>
                <div className="text-[11px] leading-[13px] text-[#18161C] font-normal line-clamp-1">
                  {speaker.company?.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

export default WebsiteEventSpeakerList;
