import { useEffect, useState } from 'react';
import { EventsService } from 'src/services/events';
import { IEvent } from 'src/models/event/event';
import useShowMoreCards from 'src/utils/hooks/useShowMoreCards';
import { EVENTS_PER_ROW } from 'src/constants';
import { SortBy } from '../../../../shared-components/V2/SortBy';
import { useCurrentUser } from '../../../../utils/hooks';
import { CardEventInterested } from '../../../events/event-tab/components/CardEventInterested';
import { NoSpotlightEvents } from '../../../../components/spotlight/no-spotlight-events';

interface ICompanyEvents {
  companyId: string;
  filterQuery?: string;
  search?: string;
}

export const CompanyEvents = ({ companyId, filterQuery, search }: ICompanyEvents) => {
  const [orderType, setOrderType] = useState<'DESC' | 'ASC'>('DESC');
  const [events, setEvents] = useState<IEvent[]>([]);
  const { moreCards, displayBtn, handleSeeMore } = useShowMoreCards(EVENTS_PER_ROW, events);
  const [nextPage] = useState(1);

  const user = useCurrentUser();

  const getEvents = (
    companyId?: string,
    search?: string,
    orderType?: 'DESC' | 'ASC',
    filterQuery?: string,
    nextPage?: number,
  ) => {
    new EventsService()
      .getDiscoveringEvents(
        '',
        search,
        '',
        [],
        [],
        [companyId!],
        [],
        [],
        [],
        orderType,
        nextPage,
        '',
        filterQuery
      )
      .then((res: any) => {
        setEvents(res.data);
      });
  };

  useEffect(() => {
    getEvents(
      companyId,
      search,
      orderType,
      filterQuery,
      nextPage
    );
  }, [companyId, search, orderType, filterQuery, nextPage]);

  const sortClick = (sortType: 'Latest to oldest' | 'Oldest to Latest') => {
    if (sortType === 'Latest to oldest') setOrderType('DESC');
    else setOrderType('ASC');
  };

  const handleSeeMoreButton = () => {
    handleSeeMore();
  };
  return (
    <>
      {moreCards?.length === 0 && <NoSpotlightEvents />}
      {moreCards?.length > 0 && (
        <div className="pb-4 h-auto w-full bg-white create-post-card">
          <div className="pt-[18px] pb-[10px] mx-auto xl:w-[902.78px] w-full xl:px-0 px-4">
            <SortBy
              sortClick={sortClick}
              ascOrderText="Oldest to latest"
              descOrderText="Latest to oldest"
            />
          </div>
          {moreCards?.length > 0 && (
            <div className="flex sm:flex-row flex-col gap-[10px] flex-wrap justify-start mx-auto xl:w-[902.78px] w-full xl:px-0 px-4">
              {moreCards
                && moreCards.map(event => (
                  <div
                    key={`card-${event.id}`}
                    className=""
                  >
                    <CardEventInterested
                      coverUrl={`${event.cover}`}
                      avatarUrl={`${event?.company?.logo}`}
                      title={event.name}
                      date={event.startTime}
                      subTitle=""
                      regions={`${event?.timezone} • ${event?.company.name
                      }`}
                      eventType={event.format}
                      eventLink={`/event/${event.slug}/discussion`}
                      event={event}
                      user={user}
                      containerClass="sm:!w-[218px] !w-full"
                      bannerClass="sm:!w-[218px] !w-full"
                      logoPosition="left-[16px] bottom-[16px]"
                    />
                  </div>
                ))}
            </div>
          )}
          {displayBtn && (
            <button
              type="button"
              onClick={handleSeeMoreButton}
              className="bg-[#EDF2F5] hover:bg-[#DFE6EA] h-[31px] xl:w-[904px] w-full rounded-[10px] text-[#203C6E] text-sm1 font-semibold transition-all mx-auto mt-[14px] ml-[19px]"
            >
              Show more
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default CompanyEvents;
