import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UserService } from '../../../../services';
import { Button } from '../../../../shared-components/V2';
import DynamicallyFieldRegistration from '../event-components/dynamically-field-registration';
import { IRegistrationFormResult } from '../../../../models/user/event-registration/registrationForm-result';
import { IAppState } from '../../../../store';
import { IReactSelectOption } from '../../../../models/react-select-option';
import { useCurrentUser } from '../../../../utils/hooks';

interface ISignupProps {
  backButtonVisible: boolean;
  eventId: string;
  onError: Function;
  onSuccess: Function;
}
export const EventDetailsForm = ({
  backButtonVisible, eventId, onError, onSuccess
}: ISignupProps) => {
  const { register } = useForm<any>();
  const { eventRegistration } = useSelector((state: IAppState) => state.signupUser);
  const eventJoining = useSelector((state: IAppState) => state.signupUser.eventJoining);
  const errorMessage = useSelector((state: IAppState) => state.signupUser.errorMessage);
  const [registrationFields, setRegistrationFields] = useState<IRegistrationFormResult>();
  const [dynamicallyFieldsValue, setDynamicallyFieldsValue] = useState<any>({});
  const [multiAnswerSelect, setMultiAnswerSelected] = useState([]);
  const [formIsValidState, setFormIsValidState] = useState(true);
  const [selectedState, setSelectedState] = useState<IReactSelectOption>();
  const [selectedCompany, setSelectedCompany] = useState<any>({});
  const [selectedIndustry, setSelectedIndustry] = useState<IReactSelectOption>();
  const [selectedJobTitle, setSelectedJobTitle] = useState<IReactSelectOption>();
  const [selectedValue, setSelectedValue] = useState<any>({});
  const [joinSkip, setJoinSkip] = useState(false);
  let formIsNotValid = false;

  const loggedInUser = useCurrentUser();

  const checkFormValidation = () => {
    for (let i = 0; i < registrationFields?.data.length!; i++) {
      if (i < registrationFields?.data.length!) {
        if (!registrationFields?.data[i].isDefault && registrationFields?.data[i].isRequired) {
          // eslint-disable-next-line no-prototype-builtins
          if (!selectedValue.hasOwnProperty(registrationFields?.data[i].id)) {
            setFormIsValidState(false);
            formIsNotValid = true;
            return;
            // eslint-disable-next-line no-prototype-builtins
          } if (selectedValue.hasOwnProperty(registrationFields?.data[i].id)) {
            if (!selectedValue[registrationFields?.data[i].id]) {
              setFormIsValidState(false);
              formIsNotValid = true;
              return;
            }
            setFormIsValidState(true);
            formIsNotValid = false;
          }
        }
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const joinEvent = () => {
    checkFormValidation();
    if (!formIsNotValid) {
      let staticFieldsValue = selectedValue;

      registrationFields?.data.map(item => {
        if (item.isDefault) {
          // eslint-disable-next-line default-case
          switch (item.fieldName) {
            case 'First name': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.firstName
                  ? eventRegistration?.firstName
                  : loggedInUser.firstName
              };
              break;
            }
            case 'Last name': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.lastName
                  ? eventRegistration?.lastName
                  : loggedInUser.lastName
              };
              break;
            }
            case 'Email': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.email
                  ? eventRegistration?.email
                  : loggedInUser.email
              };
              break;
            }
            case 'Job title': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.jobTitle?.label
                  ? eventRegistration?.jobTitle?.label
                  : loggedInUser.jobTitle
              };
              break;
            }
            case 'Organisation': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.company?.label
                  ? eventRegistration?.company?.label
                  : loggedInUser.company?.name
              };
              break;
            }
            case 'Industry': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.industry
                  ? eventRegistration?.industry
                  : loggedInUser.industry?.name
              };
              break;
            }
            case 'Country/region':
            case 'City, Country': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.location
                  ? eventRegistration?.location
                  : loggedInUser.location
              };
              break;
            }
            case 'Postal Code': {
              staticFieldsValue = {
                ...staticFieldsValue,
                [`${item.id}`]: eventRegistration?.postCode
                  ? eventRegistration?.postCode
                  : loggedInUser?.postCode || '2000'
              };
              break;
            }
          }
        }

        return null;
      });
      setDynamicallyFieldsValue(staticFieldsValue);
      onSuccess(
        eventId,
        staticFieldsValue,
        selectedValue,
        selectedState,
        selectedCompany,
        selectedIndustry,
        selectedJobTitle
      );
    }
  };

  useEffect(() => {
    if (!eventId) return;
    new UserService()
      .getEventRegistrationForm(eventId!)
      .then(res => {
        if (!res?.data?.length) {
          onError('skip');
        }
        setRegistrationFields(res);
      })
      .catch(error => {
        toast.error(error);
        onError('skip');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  useEffect(() => {
    if (eventJoining) {
      const copyRegistrationFields = { ...registrationFields?.data };

      for (let i = 0; i <= registrationFields?.data.length!; i++) {
        const field = registrationFields?.data[i];
        if (field?.id && !field?.isDefault) {
          // eslint-disable-next-line no-prototype-builtins
          if (eventJoining.dynamicFields?.hasOwnProperty(field.id)) {
            copyRegistrationFields[i].value = eventJoining.dynamicFields[field.id];
          }
        }
      }

      const data: any = {};
      data.Fields = [];
      data.field = copyRegistrationFields;
      if (data && data.field.length > 0) setRegistrationFields(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationFields, eventJoining]);

  useEffect(() => {
    if (
      registrationFields?.data.length! > 0
      && !registrationFields?.data.some(item => !item.isDefault) && !joinSkip
    ) {
      setJoinSkip(true);
      joinEvent();
    }
  }, [joinSkip, registrationFields, joinEvent]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 5000 });
      onError(errorMessage);
    }
  }, [errorMessage, onError]);

  useEffect(() => {
    setSelectedState(eventJoining?.selectedState);
  }, [eventJoining?.selectedState]);

  useEffect(() => {
    setSelectedCompany(eventJoining?.selectedCompany);
  }, [eventJoining?.selectedCompany]);

  useEffect(() => {
    setSelectedIndustry(eventJoining?.selectedIndustry);
  }, [eventJoining?.selectedIndustry]);

  useEffect(() => {
    setSelectedJobTitle(eventJoining?.selectedJobTitle);
  }, [eventJoining?.selectedJobTitle]);

  useEffect(() => {
    if (eventJoining) {
      setSelectedValue(eventJoining.dynamicFields);
    }
  }, [eventJoining]);

  const changeDynamicData = (fieldId: string, fieldType: string, answer: any, event: any) => {
    if (fieldType === 'select') {
      setMultiAnswerSelected(multiAnswerSelect.concat(answer));

      if (answer) {
        setSelectedValue({ ...selectedValue, [fieldId]: multiAnswerSelect.concat(answer) });
        setDynamicallyFieldsValue({
          ...dynamicallyFieldsValue,
          [`${fieldId}`]: multiAnswerSelect.concat(answer)
        });
      } else {
        setSelectedValue({ ...selectedValue, [fieldId]: '' });
        setDynamicallyFieldsValue({
          ...dynamicallyFieldsValue,
          [`${fieldId}`]: multiAnswerSelect.concat(answer)
        });
      }
    } else if (fieldType === 'terms-and-conditions') {
      if (answer) {
        setSelectedValue({ ...selectedValue, [fieldId]: answer });
        setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
      } else {
        setSelectedValue({ ...selectedValue, [fieldId]: answer });
        setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
      }
    } else if (fieldType === 'radio') {
      setSelectedState(event);
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    } else if (fieldType === 'phone') {
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    } else if (fieldType === 'job-title') {
      setSelectedJobTitle(event);
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    } else if (fieldType === 'company') {
      setSelectedCompany(event);
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    } else if (fieldType === 'industry') {
      setSelectedIndustry(event);
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    } else {
      setSelectedValue({ ...selectedValue, [fieldId]: answer });
      setDynamicallyFieldsValue({ ...dynamicallyFieldsValue, [`${fieldId}`]: answer });
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    joinEvent();
  };

  const backClick = () => {
    onError('back');
  };

  if (joinSkip) {
    onSuccess('joinSkip');
    return null;
  }
  return (
    <form onSubmit={e => onSubmit(e)} className="h-full w-full">
      <div className="flex items-center justify-center">
        <div className="">
          {registrationFields?.data.map(item => {
            if (!item.isDefault) {
              return (
                <>
                  <div key={item.id}>
                    <DynamicallyFieldRegistration
                      item={item}
                      register={register}
                      changeDynamicData={(
                        fieldId: string,
                        fieldType: string,
                        answer: [],
                        event: any
                      ) => changeDynamicData(fieldId, fieldType, answer, event)}
                      selectedState={selectedState!}
                      selectedCompany={selectedCompany}
                      selectedIndustry={selectedIndustry!}
                      selectedJobTitle={selectedJobTitle!}
                      selectedValue={selectedValue[item.id]}
                    />
                    {item.isRequired && !selectedValue[item.id] && !formIsValidState && (
                      <span className="text-red gap-2 text-xs">
                        {`"${item.fieldName}" is required`}
                      </span>
                    )}
                  </div>
                  <hr className="mt-2" />
                </>
              );
            }
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
          })}
        </div>
      </div>
      <div className="flex justify-center mt-5 mb-5">
        <button
          hidden={!backButtonVisible}
          type="button"
          className="text-sm1 text-blue-2 font-semibold hover:underline w-24"
          onClick={backClick}
        >
          Back
        </button>
        <Button
          type="submit"
          text="Continue"
          size="x-small"
          className="w-110 bg-[#0049EF] text-white text-sm font-semibold hover:bg-blue-3"
        />
      </div>
    </form>
  );
};

export default EventDetailsForm;
