/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import {
  useEffect,
  useState,
} from 'react';

import { useSelector } from 'react-redux';
import {
  useParams, Link, useNavigate, useSearchParams, useLocation
} from 'react-router-dom';

import classNames from 'classnames';
import { useDebounce } from 'src/mui/hooks/use-debounce';
import { ReactComponent as CloseIcon } from '../../assets/images/modal-close-icon.svg';
import { SearchIcon } from '../../shared-components/V2/IconSvg';
import { EVENTS_PER_ROW, EVENT_CATEGORY } from '../../constants';
import SecondLayout from '../../layouts/second-layout';
import { IEvent } from '../../models/event/event';
import { CompanyService } from '../../services';
import { EventsService } from '../../services/events';
import { NavMenuCard } from '../../shared-components/V2/NavMenuCard';
import { Button } from '../../shared-components/V2';
import { SortBy } from '../../shared-components/V2/SortBy';
import { IAppState } from '../../store';
import {
  getCurrentDate,
  getDateOfNextDay,
  getFirstAndLastDayOfMonth,
  getFirstAndLastDayOfNextMonth,
  getFirstAndLastDayOfNextWeek,
  getFirstAndLastDayOfWeek,
} from '../../utils/datetime';
import useShowMoreCards from '../../utils/hooks/useShowMoreCards';
import { CardEventsInterested } from './event-tab/components/CardEventsInterested';
import CardEventInterestedContainer from './event-tab/components/CardEventInterestedContainer';

import CardEventInterestedLiveContainer from './event-tab/components/CardEventInterestedLiveContainer';
import DropDownEventButton from './event-tab/components/DropDownEventButton';
import { HeroEvents } from './event-tab/components/HeroEvents';
import { NoEvents } from './event-tab/components/no-events';
import { Loader } from '../../shared-components/Loader';

export interface IDropDownMenuItems {
  id: string;
  name: string;
  url?: string;
  onClick?: any;
  type?: string;
  isSelected?: boolean;
}

export interface IDateItems {
  from: string;
  to: string;
}

export const Events = () => {
  let menuItemsFormat: IDropDownMenuItems[];
  let menuItemsDate: IDropDownMenuItems[];

  const user = useSelector((state: IAppState) => state.auth.currentUser);
  const location = useLocation();

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [discoverEvents, setDiscoverEvents] = useState<IEvent[]>([]);
  const { currentUser: { isEmailVerified } = {}, hideEmailVerification } = useSelector((state: IAppState) => state?.auth);

  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get('category');
  const searchValue = searchParams.get('search');
  const [query, setQuery] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>(searchValue || '');
  const debouncedSearchInput = useDebounce(searchInput, 500);
  const [formatSelected, setFormatSelected] = useState<string>();
  const [formatHybridIsSelected, setFormatHybridIsSelected] = useState<boolean>(false);
  const [formatLiveIsSelected, setFormatLiveIsSelected] = useState<boolean>(false);
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [isShowEventFilter, setIsShowEventFilter] = useState<boolean>(false);
  const [isShowUpcomingFilter, setIsShowUpcomingFilter] = useState<boolean>(false);

  const [dateSelected, setDateSelected] = useState<IDateItems[]>([]);

  const [industry, setIndustry] = useState<IDropDownMenuItems[]>([]);
  const [industrySelected, setIndustrySelected] = useState<string[]>([]);

  const [company, setCompany] = useState<IDropDownMenuItems[]>([]);
  const [companySelected, setCompanySelected] = useState<string[]>([]);

  const [jobTitleSelected, setJobTitleSelected] = useState<string[]>([]);

  const [jobFunction, setJobFunction] = useState<IDropDownMenuItems[]>([]);
  const [jobFunctionSelected, setJobFunctionSelected] = useState<string[]>([]);

  const [region, setRegion] = useState<IDropDownMenuItems[]>([]);
  const [regionSelected, setRegionSelected] = useState<string[]>([]);

  const [eventSummary, setEventSummary] = useState<any>();
  const [upcommingTab, setUpcommingTab] = useState<string>('all');
  const [myHostedTab, setMyHostedTab] = useState<string>('all');

  const [orderType, setOrderType] = useState<'DESC' | 'ASC'>('ASC');
  const [nextPage] = useState(1);

  const [url, setUrl] = useState<string>();
  const [empty, setEmpty] = useState(false);
  const [showDiscoverSection, setShowDiscoverSection] = useState(false);
  const { param } = useParams<{ param: string }>();
  const navigate = useNavigate();

  const { moreCards, displayBtn, handleSeeMore } = useShowMoreCards(
    EVENTS_PER_ROW,
    discoverEvents
  );

  const locationPathArr = location?.pathname?.match(/\/(\w+)/ig);

  const getEvents = (
    urlParam?: string,
    searchInputParam?: string | undefined,
    formatSelectedParam?: string | undefined,
    dateSelectedParam?: IDateItems[],
    industrySelectedParam?: string[],
    companySelectedParam?: string[],
    jobTitleSelectedParam?: string[],
    jobFunctionSelectedParam?: string[],
    regionSelectedParam?: string[],
    orderTypeParam?: 'DESC' | 'ASC',
    nextPageParam?: number,
    category?: string,
  ) => {
    if (param && !urlParam) return;
    new EventsService()
      .getDiscoveringEvents(
        urlParam,
        searchInputParam,
        formatSelectedParam,
        dateSelectedParam,
        industrySelectedParam,
        companySelectedParam,
        jobTitleSelectedParam,
        jobFunctionSelectedParam,
        regionSelectedParam,
        orderTypeParam,
        nextPageParam,
        category,
        ''
      )
      .then(res => {
        setDiscoverEvents(res.data);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    new EventsService().getEventSummary().then(res => {
      setEventSummary(res?.data);
    });
  }, []);

  useEffect(() => {
    new CompanyService().getIndustriesWithQuery(query).then(res => {
      let industyList: IDropDownMenuItems[] = [];
      res?.data
        && res?.data.map((item: any) => industyList.push({ id: item.id, name: item.name, type: 'industry' }));
      industyList = industyList.filter(i => i.name !== null && i.name !== '');
      setIndustry(industyList);
    });
  }, [query]);

  useEffect(() => {
    new CompanyService().getCompaniesWithQuery(query).then(res => {
      let companyList: IDropDownMenuItems[] = [];
      res?.data
        && res?.data.map((item: any) => companyList.push({ id: item.id, name: item.name, type: 'company' }));
      companyList = companyList.filter(i => i.name !== null && i.name !== '');
      setCompany(companyList);
    });
  }, [query]);

  useEffect(() => {
    new CompanyService().getJobTitlesWithQuery(query).then(res => {
      let jobTitleList: IDropDownMenuItems[] = [];
      res?.data
        && res?.data.map((item: any) => jobTitleList.push({ id: item.id, name: item.name, type: 'jobTitle' }));
      jobTitleList = jobTitleList.filter(
        i => i.name !== null && i.name !== ''
      );
    });
  }, [query]);

  useEffect(() => {
    new CompanyService().getJobFunctionsWithQuery(query).then(res => {
      let jobFunctionList: IDropDownMenuItems[] = [];
      res?.data
        && res?.data.map((item: any) => jobFunctionList.push({
          id: item.id,
          name: item.name,
          type: 'jobFunction',
        }));
      jobFunctionList = jobFunctionList.filter(
        i => i.name !== null && i.name !== ''
      );
      setJobFunction(jobFunctionList);
    });
  }, [query]);

  useEffect(() => {
    new EventsService().getGuestEventRegions(query).then(res => {
      let regionList: IDropDownMenuItems[] = [];
      res?.data
        && res?.data.map((item: any, index: number) => regionList.push({
          id: `region-${index}`,
          name: item.region,
          type: 'region',
        }));
      regionList = regionList.filter(i => i.name !== null && i.name !== '');
      setRegion(regionList);
    });
  }, [query]);

  useEffect(() => {
    const myHostedStatus = ((myHostedTab === 'upcoming' && 'active') || (myHostedTab === 'past' && 'completed') || (myHostedTab === 'all' && ''));
    const myHostedFilter = myHostedStatus !== '' ? `&where[status]=${myHostedStatus}` : '&where[status]=active&where[status]=completed';
    const myUpcomingStatus = (
      (upcommingTab === 'attending' && '&where[isAttending]=1')
      || (upcommingTab === 'speaking' && '&where[isSpeaking]=1')
      || (upcommingTab === 'sponsoring' && '&where[isSponsoring]=1')
      || (upcommingTab === 'all' && '')
    );
    if (param) {
      if (param === 'pending-invitations') {
        setUrl(`/events/invited?&page=${nextPage}&limit=100`);
        setOrderType('ASC');
      } else if (param === 'upcoming-events') {
        setUrl(
          `/events/user?&page=${nextPage}&limit=100&where[stage]=not-live&where[status]=active${myUpcomingStatus}`
        );
        setOrderType('ASC');
      } else if (param === 'past-events') {
        setUrl(`/events/joined?&page=${nextPage}&limit=100&where[status]=completed`);
        setOrderType('DESC');
      } else if (param === 'my-hosted-events') {
        setUrl(`/events/hosting?&page=${nextPage}&limit=100${myHostedFilter}&adminId=${user?.id}`);
        setOrderType('ASC');
      } else if (param === 'my-interested-events') {
        setUrl(`/events/user?&page=${nextPage}&limit=100&where[status]=active&where[isInterested]=1`);
        setOrderType('ASC');
      }

      setShowDiscoverSection(true);
      setDateSelected([]);
      setFormatSelected('');
    } else {
      setUrl(undefined);
      setShowDiscoverSection(false);
    }
  }, [param, user, nextPage, myHostedTab, upcommingTab]);

  useEffect(() => {
    getEvents(
      url,
      debouncedSearchInput,
      formatSelected,
      dateSelected,
      industrySelected,
      companySelected,
      jobTitleSelected,
      jobFunctionSelected,
      regionSelected,
      orderType,
      nextPage,
      categoryParam ?? ''
    );
  }, [
    url,
    debouncedSearchInput,
    formatSelected,
    dateSelected,
    industrySelected,
    companySelected,
    jobTitleSelected,
    jobFunctionSelected,
    regionSelected,
    orderType,
    nextPage,
    categoryParam
  ]);

  const handleSeeMoreButton = () => {
    handleSeeMore();
  };

  const reset = () => {
    setFormatSelected('');
    setDateSelected([]);
    setIndustrySelected([]);
    setCompanySelected([]);
    setJobTitleSelected([]);
    setJobFunctionSelected([]);
    setRegionSelected([]);
    setFormatHybridIsSelected(false);
    setFormatLiveIsSelected(false);
    setEmpty(true);
    setSearchParams();
    setIsShowFilter(false);
  };

  const handleResult = (selectedItems: any[] = []) => {
    selectedItems.forEach(item => {
      // eslint-disable-next-line default-case
      switch (item.type) {
        case 'Date':
          // eslint-disable-next-line no-lone-blocks
          {
            let from: string = '';
            let to: string = '';
            if (item.name === 'Today') {
              from = getCurrentDate('-');
              to = getCurrentDate('-');
            } else if (item.name === 'Tomorrow') {
              from = getDateOfNextDay();
              to = getDateOfNextDay();
            } else if (item.name === 'This week') {
              from = getFirstAndLastDayOfWeek().firstdayOfWeek;
              to = getFirstAndLastDayOfWeek().lastdayOfWeek;
            } else if (item.name === 'Next week') {
              from = getFirstAndLastDayOfNextWeek().firstdayOfNextWeek;
              to = getFirstAndLastDayOfNextWeek().lastdayOfNextWeek;
            } else if (item.name === 'This month') {
              from = getFirstAndLastDayOfMonth().firstdayOfMonth;
              to = getFirstAndLastDayOfMonth().lastdayOfMonth;
            } else if (item.name === 'Next month') {
              from = getFirstAndLastDayOfNextMonth().firstdayOfNextMonth;
              to = getFirstAndLastDayOfNextMonth().lastdayOfNextMonth;
            }
            setDateSelected([...dateSelected, { from, to }]);
          }
          break;
        case 'industry':
          setIndustrySelected([...industrySelected, item.id]);
          break;
        case 'company':
          setCompanySelected([...companySelected, item.id]);
          break;
        case 'jobTitle':
          setJobTitleSelected([...jobTitleSelected, item.id]);
          break;
        case 'jobFunction':
          setJobFunctionSelected([...jobFunctionSelected, item.id]);
          break;
        case 'region':
          setRegionSelected([...regionSelected, item.name]);
          break;
      }
    });
  };

  const handleCancel = (e: any) => {
    // eslint-disable-next-line default-case
    switch (e) {
      case 'Date':
        setDateSelected([]);
        break;
      case 'Industry':
        setIndustrySelected([]);
        break;
      case 'Company':
      case 'Host':
        setCompanySelected([]);
        break;
      case 'JobTitle':
        setJobTitleSelected([]);
        break;
      case 'Function':
        setJobFunctionSelected([]);
        break;
      case 'Location':
        setRegionSelected([]);
        break;
    }
  };

  const handleReset = () => {
    reset();
  };

  const showResultsClick = (e: any, selectedItems: any[] = []) => {
    setIsShowFilter(true);
    if (e && selectedItems.length === 0 && e.currentTarget) {
      setSearchInput(e.currentTarget?.value);
    } else if (e && selectedItems.length === 0 && !e.currentTarget) {
      handleCancel(e);
    }
    handleResult(selectedItems);
  };

  // eslint-disable-next-line no-shadow
  const showResultInputSearch = (query: string) => {
    setQuery(query);
  };

  const hybridClick = () => {
    setFormatHybridIsSelected(true);
    setFormatLiveIsSelected(false);
    setFormatSelected('hybrid');
    setIsShowFilter(true);
  };
  const liveClick = () => {
    setFormatLiveIsSelected(true);
    setFormatHybridIsSelected(false);
    setFormatSelected('live');
    setIsShowFilter(true);
  };

  const sortClick = (sortType: 'Latest to oldest' | 'Oldest to Latest') => {
    if (sortType === 'Latest to oldest') setOrderType('DESC');
    else setOrderType('ASC');
  };

  const handleSelected = (IsSelected: boolean) => {
    if (IsSelected) setEmpty(false);
  };

  const handleMobileEventFilter = () => {
    setIsShowEventFilter(!isShowEventFilter);
  };

  const handleMobileUpcomingFilter = () => {
    setIsShowUpcomingFilter(!isShowUpcomingFilter);
  };

  // eslint-disable-next-line prefer-const
  menuItemsFormat = [
    {
      id: '1',
      name: 'Hybrid',
      url: '',
      onClick: hybridClick,
      isSelected: formatHybridIsSelected,
    },
    {
      id: '2',
      name: 'Live',
      url: '',
      onClick: liveClick,
      isSelected: formatLiveIsSelected,
    },
  ];

  // eslint-disable-next-line prefer-const
  menuItemsDate = [
    {
      id: '1',
      name: 'Today',
      url: '',
      type: 'Date',
    },
    {
      id: '2',
      name: 'Tomorrow',
      url: '',
      type: 'Date',
    },
    {
      id: '3',
      name: 'This week',
      url: '',
      type: 'Date',
    },
    {
      id: '4',
      name: 'Next week',
      url: '',
      type: 'Date',
    },
    {
      id: '5',
      name: 'This month',
      url: '',
      type: 'Date',
    },
    {
      id: '6',
      name: 'Next month',
      url: '',
      type: 'Date',
    },
  ];

  const cardItems = [
    { text: 'Pending invitations', url: '/events/pending-invitations', hide: !eventSummary?.pendingInvitations },
    { text: 'My upcoming events', url: '/events/upcoming-events', hide: !eventSummary?.upcommingEvents },
    { text: 'Past events', url: '/events/past-events', hide: !eventSummary?.pastEvents },
    { text: "Events I'm hosting", url: '/events/my-hosted-events', hide: !eventSummary?.hostingEvents },
    { text: 'Events I’m interested in', url: '/events/my-interested-events', hide: !eventSummary?.eventInterested },
  ];

  return eventSummary && user ? (
    <SecondLayout
      leftPanel={(
        <div
          className={classNames('my-events max-h-[calc(100vh-54px)] overflow-y-scroll overflow-x-hidden no-scrollbar', {
            '!max-h-[calc(100vh-101px)]': !isEmailVerified && !hideEmailVerification,
            'pt-[58px]':
              ((locationPathArr?.length === 1 && locationPathArr?.[0]) === '/events')
              || ((locationPathArr?.length === 2 && locationPathArr?.[1]) === '/upcoming')
              || (location?.pathname === '/events/my-hosted-events')
              || (param === 'past-events'),
          })}
        >
          <div className="rounded-lg md:w-full ml-auto mt-0">
            <div className="mt-2">
              {eventSummary ? cardItems && cardItems.map((cardItem, i) => (
                <Link hidden={cardItem.hide} to={cardItem.url} key={i}>
                  <NavMenuCard
                    cardItemText={cardItem.text}
                    className={classNames('text-[15px] font-[400] leading-6', {
                      'bg-[#E8EBED]': ((param === 'upcoming-events' && cardItem.text === 'My upcoming events')
                        || (param === 'pending-invitations' && cardItem.text === 'Pending invitations')
                        || (param === 'past-events' && cardItem.text === 'Past events')
                        || (param === 'my-hosted-events' && cardItem.text === "Events I'm hosting")
                        || (param === 'my-interested-events' && cardItem.text === 'Events I’m interested in')),
                    })}
                  />
                </Link>
              )) : <Loader className="pt-5" key="loader" />}
            </div>
          </div>
          <div>
            <Button
              text="Create an event"
              size="medium"
              className="bg-[#0049EF] w-full mt-[30px] mb-[20px] h-[40px] text-[16px] !font-normal leading-[25px] text-[#ffffff] tracking-[0.3px]"
              onClick={() => window.open(`${process.env.REACT_APP_EVENT_PORTAL_URL}/events`)}
            />
          </div>
          <div>
            <div className="text-[15px] leading-[26px] font-medium text-[#3C3C3C]">Categories</div>
            <div className="pt-[10px] pb-[5px]">
              <svg xmlns="http://www.w3.org/2000/svg" width="285" height="1" viewBox="0 0 285 1">
                <path id="Path_14371" data-name="Path 14371" d="M0,0H285" transform="translate(0 0.5)" fill="none" stroke="#707070" strokeWidth="1" opacity="0.28" />
              </svg>
            </div>
            {EVENT_CATEGORY.map(category => (
              <div className="flex flex-row items-center py-1 hover:bg-[#E8EBED] rounded-10 cursor-pointer h-[50px]" onClick={() => navigate(`/events?category=${category.shortName}`)}>
                <div className="w-[44px] justify-center h-[38px] flex items-center">
                  <category.icon />
                </div>
                <div className="text-[15px] font-normal leading-[26px]  text-[#3C3C3C]">
                  {`${category.label}`}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    >
      <div
        className={classNames('xl:w-[958px] w-[100%] pt-6 pl-4 md:pr-[2px] pr-[16px] pb-5 max-h-[calc(100vh-54px)] h-[calc(100vh-54px)] overflow-y-scroll overflow-x-hidden no-scrollbar', {
          '!max-h-[calc(100vh-101px)]': !isEmailVerified && !hideEmailVerification,
          'md:pt-[78px] pt-[65px]':
            ((locationPathArr?.length === 1 && locationPathArr?.[0]) === '/events')
            || ((locationPathArr?.length === 2 && locationPathArr?.[1]) === '/upcoming')
            || (location?.pathname === '/events/my-hosted-events')
            || (param === 'past-events')
        })}
      >
        <div
          // eslint-disable-next-line max-len
          className={classNames('my-events w-full no-scrollbar md:hidden block delay-400 duration-500 ease-in-out transition-all transform', {
            '!max-h-[calc(100vh-101px)]': !isEmailVerified && !hideEmailVerification,
            'pt-[0px]':
              ((locationPathArr?.length === 1 && locationPathArr?.[0]) === '/events')
              || ((locationPathArr?.length === 2 && locationPathArr?.[1]) === '/upcoming')
              || (location?.pathname === '/events/my-hosted-events')
              || (param === 'past-events'),
          })}
        >
          {/* <div
            className="absolute -right-[118px] top-[33px] text-white text-[11px] bg-opacity-80 font-medium bg-[#0049EF] py-1 px-3 rounded-r-10 cursor-pointer"
            onClick={handleMobileEventCategories}
          >
            Event Categories
          </div> */}
          <div className="w-full">
            <div className="text-[15px] leading-[26px] font-medium text-[#3C3C3C]">Categories</div>
            <div className="pt-[5px] pb-[5px] w-full h-[1px] border-b-1 border-[#dcdcdc]" />
            <div className="flex gap-x-[10px] whitespace-nowrap mb-3 overflow-y-hidden overflow-x-scroll mt-2">
              {EVENT_CATEGORY.map(category => (
                <div className="flex flex-row items-center px-2.5 hover:bg-[#E8EBED] bg-white rounded-10 cursor-pointer h-[40px]" onClick={() => navigate(`/events?category=${category.shortName}`)}>
                  <div className="w-[34px] justify-center h-[28px] flex items-center">
                    <category.icon />
                  </div>
                  <div className="text-[12px] font-normal leading-[26px]  text-[#3C3C3C]">
                    {`${category.label}`}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="rounded-lg md:w-full ml-auto mt-0 mb-3 overflow-y-scroll">
            <div className="mt-0 flex gap-x-[10px] whitespace-nowrap">
              {eventSummary ? cardItems && cardItems.map((cardItem, i) => (
                <Link hidden={cardItem.hide} to={cardItem.url} key={i}>
                  <NavMenuCard
                    cardItemText={cardItem.text}
                    className={classNames('text-[12px] font-[400] leading-6 !h-[30px] bg-[#E8EBED]', {
                      'bg-[#E8EBED]': ((param === 'upcoming-events' && cardItem.text === 'My upcoming events')
                        || (param === 'pending-invitations' && cardItem.text === 'Pending invitations')
                        || (param === 'past-events' && cardItem.text === 'Past events')
                        || (param === 'my-hosted-events' && cardItem.text === "Events I'm hosting")
                        || (param === 'my-interested-events' && cardItem.text === 'Events I’m interested in')),
                    })}
                  />
                </Link>
              )) : <Loader className="pt-5" key="loader" />}
            </div>
          </div>
        </div>
        {!showDiscoverSection && !searchInput && !isShowFilter && <HeroEvents />}
        {
          ((param === 'past-events') || !param)
          && (
            <div
              // eslint-disable-next-line max-len
              className={classNames('flex flex-col md:px-0 px-4 md:items-center justify-center lg:w-full w-full h-[45px] fixed  left-0 bg-white z-10 md:top-[54px] top-[39px] border-t-1 border-[#c9c9c9] shadow-md delay-400 duration-500 ease-in-out transition-all transform lg:hidden', {
                '!top-[102px]': !isEmailVerified && !hideEmailVerification,
                '!translate-x-0': isShowEventFilter
              })}
            >
              <div className="flex flex-row space-x-[10px] relative">
                <DropDownEventButton
                  menuItems={industry}
                  className="p-[10px] h-[270px]"
                  dropdownClass="card-shadow h-[320px] min-w-[230px] max-w-[230px]"
                  buttonClass="xl:w-[141px] lg:w-[117px] w-full"
                  title="Industry"
                  inputPlaceholder="Add an industry"
                  iconUrl="/images/Polygon-dropdown.svg"
                  type="checkbox"
                  IsReset={empty}
                  handleSelected={handleSelected}
                  showResultsClick={showResultsClick}
                  showResultInputSearch={showResultInputSearch}
                  setIsShowFilter={setIsShowFilter}
                />
                <DropDownEventButton
                  menuItems={company}
                  title="Host"
                  className="p-[10px] h-[270px]"
                  inputPlaceholder="Add a host"
                  dropdownClass={classNames('card-shadow h-[320px] min-w-[230px] max-w-[230px]', {
                    'min-w-[190px] max-w-[190px]': innerWidth < 400,
                  })}
                  buttonClass="xl:w-[138px] lg:w-[95px] w-full"
                  iconUrl="/images/Polygon-dropdown.svg"
                  type="checkbox"
                  IsReset={empty}
                  handleSelected={handleSelected}
                  showResultsClick={showResultsClick}
                  showResultInputSearch={showResultInputSearch}
                  setIsShowFilter={setIsShowFilter}
                />
                <DropDownEventButton
                  menuItems={jobFunction}
                  className="p-[10px] h-[270px]"
                  dropdownClass="card-shadow h-[320px] min-w-[230px] max-w-[230px]"
                  buttonClass="xl:w-[149px] lg:w-[120px] w-full"
                  title="Function"
                  inputPlaceholder="Add an function"
                  iconUrl="/images/Polygon-dropdown.svg"
                  type="checkbox"
                  IsReset={empty}
                  handleSelected={handleSelected}
                  showResultsClick={showResultsClick}
                  showResultInputSearch={showResultInputSearch}
                  setIsShowFilter={setIsShowFilter}
                  containerClass={classNames({
                    hidden: innerWidth < 490,
                  })}
                />
                <DropDownEventButton
                  menuItems={region}
                  title="Location"
                  className="p-[10px] h-[270px]"
                  dropdownClass="card-shadow h-[320px] min-w-[230px] max-w-[230px]"
                  buttonClass="xl:w-[152px] lg:w-[120px] w-full"
                  inputPlaceholder="Add an location"
                  iconUrl="/images/Polygon-dropdown.svg"
                  type="checkbox"
                  IsReset={empty}
                  handleSelected={handleSelected}
                  showResultsClick={showResultsClick}
                  showResultInputSearch={showResultInputSearch}
                  setIsShowFilter={setIsShowFilter}
                  containerClass={classNames({
                    hidden: innerWidth < 620,
                  })}
                />
                <DropDownEventButton
                  menuItems={menuItemsFormat}
                  className="overflow-y-hidden"
                  dropdownClass="card-shadow min-w-[131px] max-w-[131px] p-[5px]"
                  buttonClass="xl:w-[112px] lg:w-[90px] w-full"
                  title="Format"
                  iconUrl="/images/Polygon-dropdown.svg"
                  type="button"
                  IsReset={empty}
                  handleSelected={handleSelected}
                  setIsShowFilter={setIsShowFilter}
                  containerClass={classNames({
                    hidden: innerWidth < 750,
                  })}
                />
                <DropDownEventButton
                  menuItems={menuItemsDate}
                  className="pt-[21px] px-[15px] h-[180px]"
                  dropdownClass="card-shadow h-[230px] min-w-[192px] max-w-[192px]"
                  buttonClass="xl:w-[132px] lg:w-[105px] w-full"
                  title="Date"
                  iconUrl="/images/Polygon-dropdown.svg"
                  type="checkbox"
                  IsReset={empty}
                  handleSelected={handleSelected}
                  showResultsClick={showResultsClick}
                  setIsShowFilter={setIsShowFilter}
                  containerClass={classNames({
                    hidden: innerWidth < 850,
                  })}
                />
                <Button
                  text="All Filters"
                  onClick={handleMobileEventFilter}
                  size="small"
                  className={classNames('rounded-[5px] inline-block text-sm font-[600]', {
                    'bg-[#0049EF] hover:bg-[#244E93] text-white': upcommingTab === 'all',
                    'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': upcommingTab !== 'all',
                  })}
                />
              </div>
            </div>
          )
        }
        {
          ((param === 'past-events') || !param)
          && (
            <div
              // eslint-disable-next-line max-len
              className={classNames('flex flex-col lg:w-full w-[100%] lg:translate-x-[0%] -translate-x-[100%] lg:h-auto h-[100vh] fixed  left-0 bg-white z-10 md:top-[54px] top-[39px] border-t-1 border-[#c9c9c9] shadow-md delay-400 duration-500 ease-in-out transition-all transform lg:pt-0 pt-[40px]', {
                '!top-[102px]': !isEmailVerified && !hideEmailVerification,
                '!translate-x-0': isShowEventFilter
              })}
            >
              <div
                // eslint-disable-next-line max-len
                className="absolute right-[20px] top-[10px] text-white text-[11px] font-medium bg-[#edf2f5] bg-opacity-80 w-[25px] h-[25px] rounded-full cursor-pointer lg:hidden flex items-center justify-center"
                onClick={handleMobileEventFilter}
              >
                <div>
                  <span className="cursor-pointer">
                    {' '}
                    <CloseIcon />
                    {' '}
                  </span>
                </div>
              </div>
              <div className="container mx-auto xl:w-[1258px] xl:max-w-none max-w-[100%] xl:pr-[12px] px-[20px] xl:px-0">
                <div className="flex flex-row space-x-24 mt-2.5 mb-[12px]">
                  <div className="lg:flex grid gap-y-3 items-center gap-x-[10px] w-full">
                    <div className="flex flex-row space-x-24 relative">
                      <div className="flex lg:w-auto w-full">
                        <div className="px-3 flex items-center rounded-[5px] focus:outline-none xl:w-[256px] w-full h-[30px] bg-gray-1 hover:bg-[#DFE5EB] active:bg-[#DFE5EB]">
                          <SearchIcon />
                          <input
                            name="search"
                            value={searchInput}
                            className="xl:w-[202px] w-full ml-[8.8px] text-sm5 focus:outline-none block font-normal text-[#3C3C3C] placeholder:text-[#3C3C3C] bg-transparent"
                            placeholder="Keywords"
                            onChange={e => showResultsClick(e, [])}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <DropDownEventButton
                      menuItems={industry}
                      className="p-[10px] h-[270px]"
                      dropdownClass="card-shadow h-[320px] min-w-[230px] max-w-[230px]"
                      buttonClass="xl:w-[141px] lg:w-[117px] w-full"
                      title="Industry"
                      inputPlaceholder="Add an industry"
                      iconUrl="/images/Polygon-dropdown.svg"
                      type="checkbox"
                      IsReset={empty}
                      handleSelected={handleSelected}
                      showResultsClick={showResultsClick}
                      showResultInputSearch={showResultInputSearch}
                      setIsShowFilter={setIsShowFilter}
                    />
                    <DropDownEventButton
                      menuItems={company}
                      title="Host"
                      className="p-[10px] h-[270px]"
                      inputPlaceholder="Add a host"
                      dropdownClass="card-shadow h-[320px] min-w-[230px] max-w-[230px]"
                      buttonClass="xl:w-[138px] lg:w-[95px] w-full"
                      iconUrl="/images/Polygon-dropdown.svg"
                      type="checkbox"
                      IsReset={empty}
                      handleSelected={handleSelected}
                      showResultsClick={showResultsClick}
                      showResultInputSearch={showResultInputSearch}
                      setIsShowFilter={setIsShowFilter}
                    />
                    <DropDownEventButton
                      menuItems={jobFunction}
                      className="p-[10px] h-[270px]"
                      dropdownClass="card-shadow h-[320px] min-w-[230px] max-w-[230px]"
                      buttonClass="xl:w-[149px] lg:w-[120px] w-full"
                      title="Function"
                      inputPlaceholder="Add an function"
                      iconUrl="/images/Polygon-dropdown.svg"
                      type="checkbox"
                      IsReset={empty}
                      handleSelected={handleSelected}
                      showResultsClick={showResultsClick}
                      showResultInputSearch={showResultInputSearch}
                      setIsShowFilter={setIsShowFilter}
                    />
                    <DropDownEventButton
                      menuItems={region}
                      title="Location"
                      className="p-[10px] h-[270px]"
                      dropdownClass="card-shadow h-[320px] min-w-[230px] max-w-[230px]"
                      buttonClass="xl:w-[152px] lg:w-[120px] w-full"
                      inputPlaceholder="Add an location"
                      iconUrl="/images/Polygon-dropdown.svg"
                      type="checkbox"
                      IsReset={empty}
                      handleSelected={handleSelected}
                      showResultsClick={showResultsClick}
                      showResultInputSearch={showResultInputSearch}
                      setIsShowFilter={setIsShowFilter}
                    />
                    <DropDownEventButton
                      menuItems={menuItemsFormat}
                      className="overflow-y-hidden"
                      dropdownClass="card-shadow min-w-[131px] max-w-[131px] p-[5px]"
                      buttonClass="xl:w-[112px] lg:w-[90px] w-full"
                      title="Format"
                      iconUrl="/images/Polygon-dropdown.svg"
                      type="button"
                      IsReset={empty}
                      handleSelected={handleSelected}
                      setIsShowFilter={setIsShowFilter}
                    />
                    <DropDownEventButton
                      menuItems={menuItemsDate}
                      className="pt-[21px] px-[15px] h-[180px]"
                      dropdownClass="card-shadow h-[230px] min-w-[192px] max-w-[192px]"
                      buttonClass="xl:w-[132px] lg:w-[105px] w-full"
                      title="Date"
                      iconUrl="/images/Polygon-dropdown.svg"
                      type="checkbox"
                      IsReset={empty}
                      handleSelected={handleSelected}
                      showResultsClick={showResultsClick}
                      setIsShowFilter={setIsShowFilter}
                    />
                    <div className="flex lg:justify-end justify-start">
                      <Button
                        text="Reset"
                        onClick={handleReset}
                        size="small"
                        className="bg-[#0049EF] hover:bg-[#2e6eff] text-white lg:w-[100px] w-full h-[30px] tracking-wide"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <div>
          {!showDiscoverSection && !categoryParam && !searchInput && !isShowFilter && (
            <>
              <div className="flex flex-col justify-center bg-white create-post-card w-full">
                <CardEventInterestedLiveContainer
                  className="flex-wrap"
                  title="Happening Now"
                  user={user}
                />
              </div>
              <div className="flex flex-col my-4 mb-[12px] bg-white w-full create-post-card h-auto">
                <CardEventInterestedContainer
                  className="flex-wrap"
                  title="Upcoming Now"
                  user={user}
                />
              </div>
            </>
          )}
          {param === 'upcoming-events' && (
            <div
              // eslint-disable-next-line max-len
              className={classNames('flex flex-col md:items-center justify-center lg:w-full w-full h-[45px] fixed  left-0 bg-white z-10 md:top-[54px] top-[39px] border-t-1 border-[#c9c9c9] shadow-md delay-400 duration-500 ease-in-out transition-all transform', {
                '!top-[102px]': !isEmailVerified && !hideEmailVerification,
                '!translate-x-0': isShowEventFilter
              })}
            >
              <div className="container mx-auto xl:w-[1258px] xl:max-w-none max-w-[100%] xl:pr-[12px] px-[20px] xl:px-0">
                <div className="flex flex-row space-x-24 mt-2.5 mb-[12px]">
                  <div className="flex items-center gap-x-[10px] w-full">
                    <div className="flex flex-row space-x-24 relative">
                      <div className="flex">
                        <div className="px-3 flex items-center rounded-[5px] focus:outline-none xl:w-[256px] w-full h-[30px] bg-gray-1 hover:bg-[#DFE5EB] active:bg-[#DFE5EB]">
                          <SearchIcon />
                          <input
                            name="search"
                            className="xl:w-[202px] w-full ml-[8.8px] text-sm5 focus:outline-none block font-normal text-[#3C3C3C] placeholder:text-[#3C3C3C] bg-transparent"
                            placeholder="Keywords"
                            onChange={e => showResultsClick(e, [])}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-x-[10px]">
                      <Button
                        text="All"
                        onClick={() => setUpcommingTab('all')}
                        size="small"
                        className={classNames('rounded-[5px] inline-block text-sm font-[600]', {
                          'bg-[#0049EF] hover:bg-[#244E93] text-white': upcommingTab === 'all',
                          'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': upcommingTab !== 'all',
                          hidden: innerWidth < 464,
                        })}
                      />
                      <Button
                        text="Attending"
                        onClick={() => setUpcommingTab('attending')}
                        size="small"
                        className={classNames('rounded-[5px] inline-block text-sm font-[600]', {
                          'bg-[#0049EF] hover:bg-[#244E93] text-white': upcommingTab === 'attending',
                          'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': upcommingTab !== 'attending',
                          hidden: innerWidth < 513,
                        })}
                      />
                      <Button
                        text="Speaking"
                        onClick={() => setUpcommingTab('speaking')}
                        size="small"
                        className={classNames('rounded-[5px] inline-block text-sm font-[600]', {
                          'bg-[#0049EF] hover:bg-[#244E93] text-white': upcommingTab === 'speaking',
                          'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': upcommingTab !== 'speaking',
                          hidden: innerWidth < 617,
                        })}
                      />
                      <Button
                        text="Sponsoring"
                        onClick={() => setUpcommingTab('sponsoring')}
                        size="small"
                        className={classNames('rounded-[5px] inline-block text-sm font-[600]', {
                          'bg-[#0049EF] hover:bg-[#244E93] text-white': upcommingTab === 'sponsoring',
                          'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': upcommingTab !== 'sponsoring',
                          hidden: innerWidth < 636,
                        })}
                      />
                      <Button
                        text="All Filters"
                        onClick={handleMobileUpcomingFilter}
                        size="small"
                        className={classNames('rounded-[5px] inline-block text-sm font-[600] bg-[#0049EF] hover:bg-[#244E93] text-white', {
                          // 'bg-[#0049EF] hover:bg-[#244E93] text-white': upcommingTab === 'all',
                          // 'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': upcommingTab !== 'all',
                          hidden: innerWidth > 636,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {param === 'upcoming-events' && (
            <div
              // eslint-disable-next-line max-len
              className={classNames('flex flex-col lg:w-full w-[100%] -translate-x-[100%] lg:h-auto h-[100vh] fixed  left-0 bg-white z-10 md:top-[54px] top-[39px] border-t-1 border-[#c9c9c9] shadow-md delay-400 duration-500 ease-in-out transition-all transform lg:pt-0 pt-[40px]', {
                '!top-[102px]': !isEmailVerified && !hideEmailVerification,
                '!translate-x-0': isShowUpcomingFilter
              })}
            >
              <div
                // eslint-disable-next-line max-len
                className="absolute right-[20px] top-[10px] text-white text-[11px] font-medium bg-[#edf2f5] bg-opacity-80 w-[25px] h-[25px] rounded-full cursor-pointer lg:hidden flex items-center justify-center"
                onClick={handleMobileUpcomingFilter}
              >
                <div>
                  <span className="cursor-pointer">
                    {' '}
                    <CloseIcon />
                    {' '}
                  </span>
                </div>
              </div>
              <div className="container mx-auto xl:w-[1258px] xl:max-w-none max-w-[100%] xl:pr-[12px] px-[20px] xl:px-0">
                <div className="flex flex-row space-x-24 mt-2.5 mb-[12px]">
                  <div className="grid items-center gap-y-[10px] w-full">
                    <div className="flex flex-row space-x-24 relative">
                      <div className="flex w-full">
                        <div className="px-3 flex items-center rounded-[5px] focus:outline-none xl:w-full w-full h-[30px] bg-gray-1 hover:bg-[#DFE5EB] active:bg-[#DFE5EB]">
                          <SearchIcon />
                          <input
                            name="search"
                            className="xl:w-full w-full ml-[8.8px] text-sm5 focus:outline-none block font-light text-[#3C3C3C] placeholder:text-[#3C3C3C] bg-transparent"
                            placeholder="Keywords"
                            onChange={e => showResultsClick(e, [])}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid items-center gap-y-[10px]">
                      <Button
                        text="All"
                        onClick={() => setUpcommingTab('all')}
                        size="small"
                        className={classNames('rounded-[5px] inline-block text-sm font-[600]', {
                          'bg-[#0049EF] hover:bg-[#244E93] text-white': upcommingTab === 'all',
                          'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': upcommingTab !== 'all',
                        })}
                      />
                      <Button
                        text="Attending"
                        onClick={() => setUpcommingTab('attending')}
                        size="small"
                        className={classNames('rounded-[5px] inline-block text-sm font-[600]', {
                          'bg-[#0049EF] hover:bg-[#244E93] text-white': upcommingTab === 'attending',
                          'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': upcommingTab !== 'attending',
                        })}
                      />
                      <Button
                        text="Speaking"
                        onClick={() => setUpcommingTab('speaking')}
                        size="small"
                        className={classNames('rounded-[5px] inline-block text-sm font-[600]', {
                          'bg-[#0049EF] hover:bg-[#244E93] text-white': upcommingTab === 'speaking',
                          'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': upcommingTab !== 'speaking',
                        })}
                      />
                      <Button
                        text="Sponsoring"
                        onClick={() => setUpcommingTab('sponsoring')}
                        size="small"
                        className={classNames('rounded-[5px] inline-block text-sm font-[600]', {
                          'bg-[#0049EF] hover:bg-[#244E93] text-white': upcommingTab === 'sponsoring',
                          'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': upcommingTab !== 'sponsoring',
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {param === 'my-hosted-events' && (
            <div
              // eslint-disable-next-line max-len
              className={classNames('flex flex-col items-center justify-center lg:w-full w-full h-[45px] fixed  left-0 bg-white z-10 md:top-[54px] top-[39px] border-t-1 border-[#c9c9c9] shadow-md delay-400 duration-500 ease-in-out transition-all transform', {
                '!top-[102px]': !isEmailVerified && !hideEmailVerification,
                '!translate-x-0': isShowEventFilter
              })}
            >
              <div className="container mx-auto xl:w-[1258px] xl:max-w-none max-w-[100%] xl:pr-[12px] px-[20px] xl:px-0">
                <div className="flex flex-row space-x-24 mt-2.5 mb-[12px]">
                  <div className="flex items-center gap-x-[10px] w-full">
                    <div className="flex flex-row space-x-24 relative">
                      <div className="flex">
                        <div className="px-3 flex items-center rounded-[5px] focus:outline-none xl:w-[256px] w-full h-[30px] bg-gray-1 hover:bg-[#DFE5EB] active:bg-[#DFE5EB]">
                          <SearchIcon />
                          <input
                            name="search"
                            className="xl:w-[202px] w-full ml-[8.8px] text-sm5 focus:outline-none block font-normal text-[#3C3C3C] placeholder:text-[#3C3C3C] bg-transparent"
                            placeholder="Keywords"
                            onChange={e => showResultsClick(e, [])}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <Button
                      text="All"
                      onClick={() => setMyHostedTab('all')}
                      size="small"
                      className={classNames('rounded-[5px] inline-block text-sm font-[600]', {
                        'bg-[#0049EF] hover:bg-[#244E93] text-white': myHostedTab === 'all',
                        'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': myHostedTab !== 'all',
                      })}
                    />
                    <Button
                      text="Upcoming"
                      onClick={() => setMyHostedTab('upcoming')}
                      size="small"
                      className={classNames('rounded-[5px] inline-block text-sm font-[600]', {
                        'bg-[#0049EF] hover:bg-[#244E93] text-white': myHostedTab === 'upcoming',
                        'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': myHostedTab !== 'upcoming',
                      })}
                    />
                    <Button
                      text="Past"
                      onClick={() => setMyHostedTab('past')}
                      size="small"
                      className={classNames('rounded-[5px] inline-block text-sm font-[600]', {
                        'bg-[#0049EF] hover:bg-[#244E93] text-white': myHostedTab === 'past',
                        'hover:bg-[#244E93] text-[#203C6E] hover:text-white border border-[#065DB8]': myHostedTab !== 'past',
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col bg-white w-full create-post-card discover-more">
            <div className="mt-4 ml-5">
              {
                !param
                && (
                  <div className="text-[15px] leading-[18px] font-normal text-[#172746]">Discover more events</div>
                )
              }
            </div>
            {
              ((param === 'past-events') || (param === 'my-hosted-events') || (param === 'my-interested-events') || (!param))
              && (
                <div className="xl:w-[904px] w-[calc(100%-35px)] pb-2.5 mx-5">
                  <SortBy
                    sortClick={sortClick}
                    ascOrderText="Oldest to latest"
                    descOrderText="Latest to oldest"
                  />
                </div>
              )
            }
            {moreCards && moreCards.length > 0 ? (
              <div className="pb-4 h-auto xl:w-full ml-[18.5px] xl:mr-0 mr-[18.5px]">
                <div className="flex flex-row flex-wrap xl:gap-x-0 gap-x-[14px]">
                  {moreCards
                    && moreCards.map(event => (
                      <div
                        key={`card-${event?.id}`}
                        className="xl:mr-2.5 mb-[14px] xl:w-[218px] lg:w-[calc(33.333%-9.4px)] md:w-[calc(50%-9.4px)] w-full"
                      >
                        <CardEventsInterested
                          coverUrl={`${event?.cover}`}
                          avatarUrl={`${event?.company?.logo ? event?.company?.logo : ''}`}
                          title={event?.name}
                          startTime={event?.startTime}
                          endTime={event?.endTime}
                          subTitle=""
                          regions={`${event?.eventRegions?.map((reg: any) => reg.name).join(', ')} • ${event?.company?.name
                          }`}
                          eventType={event?.format}
                          eventLink={`/event/${event?.slug}/discussion`}
                          event={event}
                          user={user}
                          isDropdown="true"
                          param={param}
                        />
                      </div>
                    ))}
                  {displayBtn && (
                    <button
                      type="button"
                      onClick={handleSeeMoreButton}
                      className="bg-[#EDF2F5] hover:bg-[#DFE6EA] h-[31px] w-[904px] rounded-[5px] text-[#203C6E] text-sm1 font-semibold transition-all"
                    >
                      Show more
                    </button>
                  )}
                </div>
              </div>
            ) : (
              moreCards && moreCards.length === 0 && <NoEvents />
            )}
          </div>
        </div>
      </div>
    </SecondLayout>
  ) : <Loader isLoaderLogo className="h-[100vh]" key="loader" />;
};

export default Events;
