import { Transition } from '@tailwindui/react';
import { Link } from 'react-router-dom';
import { CreateMenuSvgIcon } from './../../../shared-components/V2/IconSvg';
import { ReactComponent as EventIcon } from '../../../assets/images/create-menu-icons/event.svg';
import { ReactComponent as CompanyIcon } from '../../../assets/images/create-menu-icons/company.svg';
import { ReactComponent as LoungeIcon } from '../../../assets/images/create-menu-icons/lounge.svg';

import { ReactComponent as HybirdEvent } from '../../../assets/images/panelist-solution-icons/hybird-event.svg';
import { ReactComponent as VirtualEvent } from '../../../assets/images/panelist-solution-icons/virtual-event.svg';
import { ReactComponent as PersonEvent } from '../../../assets/images/panelist-solution-icons/person-event.svg';
import { ReactComponent as VisualSolutions } from '../../../assets/images/panelist-solution-icons/audio.svg';
import { ReactComponent as MobileApp } from '../../../assets/images/panelist-solution-icons/mobile-app.svg';
import { ReactComponent as PackagesAndFeatures } from '../../../assets/images/panelist-solution-icons/features.svg';

import CreateMenuItemBox from './create-menu/create-menu-item-box';
import SolutionItem from './create-menu/solution-item';

import useCloseMenuWithClickOutside from '../../../utils/hooks/closeMenuWithClickOutside';
import { useRef } from 'react';
import { Fade } from '@mui/material';

const eventCreate = {
  icon: EventIcon,
  title: "Event"
}

const companyCreate = {
  icon: CompanyIcon,
  title: "Company",
  link: "/wall/company/create"
}

const createLouge = {
  icon: LoungeIcon,
  title: "Lounge"
}

const panelistSolutions = [
  {
    title: "Hybrid Events",
    text: "Run fully hybrid events with a 360º view for physical and virtual attendees.",
    icon: HybirdEvent,
    link: "https://business.panelist.com/hybrid"
  },
  {
    title: "Virtual Events",
    text: "e more than just webinars by giving your attendees an active role.",
    icon: VirtualEvent,
    link: "https://business.panelist.com/virtual"
  },
  {
    title: "In-person Events",
    text: "Elevate your in-person events with an all around technology to optimise your costs.",
    icon: PersonEvent,
    link: "https://business.panelist.com/in-person"
  },
  {
    title: "Audio Visual Solutions",
    text: "A plug and play solution to run your AV operations. Our technology, your people.",
    icon: VisualSolutions,
    link: "https://business.panelist.com/audio-visual"
  },
  {
    title: "Event Mobile App",
    text: "The perfect digital assistant to fuel engagement, traffic and your customer experience.",
    icon: MobileApp,
    link: "https://business.panelist.com/event-app"
  },
  {
    title: "Packages & Features",
    text: "Learn more about Panelist Solutions and how you can get onboard today.",
    icon: PackagesAndFeatures,
    link: "https://business.panelist.com/features-and-packages"
  }
]

const CreateMenu = () => {
  const ref = useRef<any>(null);
  const { showDropdown, handleDropdown } = useCloseMenuWithClickOutside(ref);

  const handleCreateMenuDropdown = () => {
    handleDropdown(showDropdown);
  };

  const eventManagerHandler = () => {
    window.open(`${process.env.REACT_APP_EVENT_PORTAL_URL}/events`);
  };

  const handleRedirection = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <div className="h-[27px]" ref={ref}>
      <button
        className="menu focus:outline-none focus:shadow-solid "
        onClick={() => handleCreateMenuDropdown()}>
        <CreateMenuSvgIcon />
      </button>
      {showDropdown &&
        <Fade in={true}>
          {/* <Transition
            show={showDropdown}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"> */}
          <div className="origin-top-right absolute right-0 md:top-[47px] top-[39px] w-[326px] h-[857px] opacity-100 bg-white create-menu-dropdown profile-avatar-shadow p-[20px] md:pb-0 pb-[40px] z-[10000]">
            <div className="text-md2 tracking-wide font-normal text-[#172746] mb-2.5 h-[21px]">Create</div>
            <div className="mb-[14.8px] flex">
              <div className="w-[88px] mr-2.5"><CreateMenuItemBox Icon={eventCreate.icon} title={eventCreate.title} onClick={eventManagerHandler} /></div>
              <Link to="/wall/company/create"><div className="w-[88px] mr-2.5"><CreateMenuItemBox Icon={companyCreate.icon} title={companyCreate.title} /></div></Link>
              <div className="w-[88px]"><CreateMenuItemBox Icon={createLouge.icon} title={createLouge.title} /></div>
            </div>
            <svg className="mb-[10.5px]" xmlns="http://www.w3.org/2000/svg" width="284" height="1" viewBox="0 0 284 1">
              <line id="Line_1476" data-name="Line 1476" x2="284" transform="translate(0 0.5)" fill="none" stroke="#d6d6d6" strokeWidth="1" />
            </svg>
            <div className="text-[13px] h-[19px] tracking-wide text-[#285CB2] font-medium mb-[9px] hover:underline hover:cursor-pointer" onClick={() => handleRedirection("https://business.panelist.com/")}>Learn more about Panelist Solutions</div>
            <div className="rounded-20 border-1 border-[#EDEDED] p-2.5">
              {
                panelistSolutions && panelistSolutions.length > 0 &&
                panelistSolutions.map((item) => (
                  <SolutionItem Icon={item.icon} title={item.title} text={item.text} onClick={() => handleRedirection(item.link)} />
                ))
              }
            </div>
          </div>
          {/* </Transition> */}
        </Fade>
      }

    </div>
  );
};

export default CreateMenu;
