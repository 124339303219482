export interface ClockSvgIconProps {
    fillColor?: string;
}
export const PublicSvgIcon = ({ fillColor }: ClockSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#203c6e"
    return <svg id="earth-svgrepo-com" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path id="earth-svgrepo-com-2" data-name="earth-svgrepo-com" d="M8.132,5.384c.222-.282.282-.443.181-1.209a1.544,1.544,0,0,1,.441-1.147A4.115,4.115,0,0,0,7.474,1.922a.749.749,0,0,1-.189.229c-.242.194-1.351-.214-1.693-.214s-.242.625-1.068.705c-.691.067-.649-.667-.617-.91A4.1,4.1,0,0,0,1.477,6.3c.163-.033.823-.142,1.051.21.262.4.121.806.323.988.184.165.566.333.236,1.322A4.1,4.1,0,0,0,9.477,6.477C7.95,6.533,7.915,5.66,8.132,5.384ZM6.822,7A1.7,1.7,0,0,1,5.73,8.629s-.642.484-.783-.967S3.959,7,3.892,5.485A1.991,1.991,0,0,1,4.766,3.51c.544-.323.867.726,1.391.847s1.431-.02,1.532.443S6.862,5.686,6.822,7ZM9.389,1.611A5.5,5.5,0,0,0,1.611,9.389,5.5,5.5,0,0,0,9.389,1.611ZM5.5,10.307A4.807,4.807,0,1,1,10.307,5.5,4.807,4.807,0,0,1,5.5,10.307Z" fill={iconColor}/>
  </svg>
  
  
};