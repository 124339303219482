import { FC } from "react"
import classNames from 'classnames';
import { Avatar } from "../Avatar";
import userDefault from '../../../assets/images/default-profile.svg';
import companyDefault from '../../../assets/images/company-default.svg';

export interface SelectDropdownComponentProps {
  name?: string;
  avatar?: string;
  designation?: string;
  industry?: string;
  className?: string;
  ref: any;
  defaultAvatar?:boolean
  isCompanydropdown?: boolean;
}

export const SelectDropdownComponent: FC<SelectDropdownComponentProps> = ({ name, avatar, designation, industry, className, ref,defaultAvatar, isCompanydropdown }) => {

  return (
    <div 
      className={classNames("w-full flex items-center hover:bg-[#edf2f5] rounded-[5px]",{
        [`${className}`]: className,
      })}
      ref={ref}
    >

      {
        defaultAvatar && (
          isCompanydropdown ? (
            <Avatar size="XSMALL" src={ avatar ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${avatar}` : companyDefault} className=" border-1 border-[#edf2f5] !rounded-[7px]" /> 
        ) : (
            <Avatar size="XSMALL" src={ avatar ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${avatar}` : userDefault} className=" border-1 border-blue-900" /> 
        )
        )
      }
      <span
         className={classNames("font-normal text-[15px] text-black mr-1 ml-2 ",{
        "whitespace-nowrap": designation,
        "ellipsis-one-line": !designation,
        })}>{name}</span>
      {isCompanydropdown && <span className="font-normal text-sm7 text-gray-900 ellipsis-one-line"><span className="font-semibold text-base text-blue-3 mr-1">•</span>Company </span>}
      {isCompanydropdown && industry && <span className="font-normal text-sm7 text-gray-900 ellipsis-one-line"><span className="font-semibold text-base text-blue-3 mr-1 ml-1">•</span>{industry}</span>}
      {designation && !isCompanydropdown && <span className="font-normal text-sm7 text-gray-900 ellipsis-one-line"><span className="font-semibold text-base text-blue-3 mr-1">•</span>{designation}</span>}
    </div>
  );
};
