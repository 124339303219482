/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import Logo from '../../assets/images/homepage/anaplan.svg';
import sitdownLogo from '../../assets/images/homepage/sitdown_logo.png';
import accentureLogo from '../../assets/images/homepage/logo-accenture.png';

const data = [
  {
    companyId: 1,
    companyLogo: Logo,
    companyDescription: 'Informative and business connect rich! We’ve been using Panelist for over 3 years and it keeps getting better. Feature and information rich… easy to network and connect. We love it.'
  },
  {
    companyId: 2,
    companyLogo: sitdownLogo,
    companyDescription: 'Handling the ins and outs of event management and with ongoing audience engagement, Panelist has helped Sit-Down to expand globally. Their insights into our audience is essential for us to deliver a better product for all parties involved.'
  },
  {
    companyId: 3,
    companyLogo: accentureLogo,
    companyDescription: 'Utilising Panelist and their network enables us to engage with audiences in a more impactful and targeted way. The data and understanding we obtain from their platform are priceless and quantifiable.'
  },
];

export const TestimonialSection = () => {
  const [selectedTestimonial, setSelectedTestimonial] = useState(data[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % data.length;
      setSelectedTestimonial(data[nextIndex]);
      setCurrentIndex(nextIndex);
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, data]);

  const handleSelectTestimonial = (selectedCompany: any) => {
    setSelectedTestimonial(selectedCompany);
  };

  return (
    <div className="w-full bg-gradient-to-r from-[#2E3CE6] to-[#4D8AF7] 2xl:h-[555px] lg:h-[600px] h-auto lg:py-0 py-[40px]">
      <div className="flex lg:flex-row flex-col justify-between items-center container max-w-full h-full mx-auto xl:pl-[179px] xl:pr-[200px] lg:pl-[100px] lg:pr-[100px] pl-[30px] pr-[30px]">
        <div className="w-full lg:max-w-[275px] max-w-[275px] h-full flex items-center lg:justify-end justify-center">
          <img src={selectedTestimonial?.companyLogo} className="lg:w-[100%] max-w-[270px] lg:h-auto max-h-[77px]" alt="Panelist" />
        </div>
        <div className="flex lg:flex-col flex-row lg:gap-[26px] gap-[12px] lg:h-[317px] h-2 mx-[54px] lg:py-0 py-4">
          {data?.map((company) => (
            <div
              style={{
                opacity: company?.companyId === selectedTestimonial?.companyId ? '100%' : '22%'
              }}
              className="lg:w-2.5 w-[30px] bg-white lg:h-full h-2 cursor-pointer"
              onClick={() => handleSelectTestimonial(company)}
            />
          ))}
        </div>
        <div className="w-full h-full flex items-center">
          <div className="flex flex-col items-end">
            <div className="flex flex-col">
              <p className="2xl:text-[39px] lg:text-[35px] text-[22px] font-normal lg:leading-[55px] leading-[30px] text-white lg:text-left text-center lg:pt-0 pt-2">
                {selectedTestimonial?.companyDescription}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
