import classNames from 'classnames';
import { CompanyTab } from './company-enum';

type Props = {
  activeTab: string;
  handleTabCahnge: (item: string) => void;
  companyId?:string
};

const CompanyTabs = ({ activeTab, handleTabCahnge, companyId }: Props) => (
  <div className="flex flex-row mt-2 border-b border-[#707070] border-opacity-[28%] mb-[17px]">
    <div
      className={classNames('text-[#0B1221] text-[16px] px-[13px] cursor-pointer leading-[21px] pb-[10px]', {
        'font-medium border-b-[3px] border-[#0049EF]': activeTab === CompanyTab.BASICDETAILS,
        'font-normal': activeTab !== CompanyTab.BASICDETAILS
      })}
      onClick={() => handleTabCahnge(CompanyTab.BASICDETAILS)}
    >
      Basic details
    </div>
    <div
      className={classNames('text-[#0B1221] text-[16px] px-[25px] cursor-pointer leading-[21px] pb-[10px]', {
        'font-medium border-b-[3px] border-[#0049EF]': activeTab === CompanyTab.CAPABILITIES,
        'font-normal': activeTab !== CompanyTab.CAPABILITIES,
        'cursor-not-allowed opacity-50': !companyId,
      })}
      onClick={() => companyId && handleTabCahnge(CompanyTab.CAPABILITIES)}
    >
      <span className="flex items-center">
        Capabilities
      </span>
    </div>
    <div
      className={classNames('text-[#0B1221] text-[16px] px-[25px] cursor-pointer leading-[21px] pb-[10px]', {
        'font-medium border-b-[3px] border-[#0049EF]': activeTab === CompanyTab.TARGETS,
        'font-normal': activeTab !== CompanyTab.TARGETS,
        'cursor-not-allowed opacity-50': !companyId,

      })}
      onClick={() => companyId && handleTabCahnge(CompanyTab.TARGETS)}
    >
      <span className="flex items-center">
        Targets
      </span>
    </div>
    <div
      className={classNames('text-[#0B1221] text-[16px] px-[25px] cursor-pointer leading-[21px] pb-[10px]', {
        'font-medium border-b-[3px] border-[#0049EF]': activeTab === CompanyTab.PRODUCTS,
        'font-normal': activeTab !== CompanyTab.PRODUCTS,
        'cursor-not-allowed opacity-50': !companyId,

      })}
      onClick={() => companyId && handleTabCahnge(CompanyTab.PRODUCTS)}
    >
      <span className="flex items-center">
        Products
      </span>
    </div>
  </div>
);

export default CompanyTabs;
