/* eslint-disable no-console */
import {
  TableRow, TableCell, Typography, Stack,
  Checkbox,
  Button,
  TextField,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
// import DeleteIcon from 'src/shared-components/V2/IconSvg/DeleteIcon';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { Logo } from 'src/shared-components/V2/Logo';
import classNames from 'classnames';
import { Star, StarBorder } from '@mui/icons-material';
import { SearchIcon } from 'src/shared-components/V2/IconSvg';
import { Avatar } from 'src/shared-components/V2';
import { ReactComponent as EditSVG } from '../../../assets/images/company/edit.svg';
import defaultLogo from '../../../assets/images/company-default.svg';
import defaultProfileLogo from '../../../assets/images/default-profile.svg';
import CompanyEventListCard from './CompanyEventListCard';
import { CompanyLeadListCard } from './CompanyLeadListCard';

type Props = {
  selected: boolean;
  accountData: any; // updated to non-optional to ensure data is passed
  onCompanyClick: (value: any) => void;
  onEventClick: (value: any) => void;
  onLeadClick: (value: any) => void;
  // eslint-disable-next-line no-undef
  onSelectRow: VoidFunction;
  fetchAccountFiles:Function
  onStarClick: (id: string, isStarred?: boolean, notes?: string) => void;
  onAccountDelete: () => void;
  selectedRows: string[];
};

const CompaniesTableRow = ({
  selected,
  accountData,
  onCompanyClick,
  onSelectRow,
  onStarClick,
  onEventClick,
  onLeadClick,
  fetchAccountFiles,
}: Props) => {
  // const [deleteAccount, setDeleteAccount] = useState({ open: false, accountId: null });
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [notes, setNotes] = useState(accountData?.notes || '');
  const [isEventsOpen, setIsEventsOpen] = useState(false);
  const [filteredCompanyEvents, setFilteredCompanyEvents] = useState<any>([]);
  const [searchEvent, setSearchEvent] = useState('');
  const [selectedCompanyEvents, setSelectedCompanyEvents] = useState<any>(accountData?.events || []);
  const [isLeadOpen, setIsLeadOpen] = useState(false);
  const [filteredCompanyLeads, setFilteredCompanyLeads] = useState<any>([]);
  const [searchLead, setSearchLead] = useState('');
  const [selectedCompanyLeads, setSelectedCompanyLeads] = useState<any>(accountData?.employees || []);

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
  };

  const handleSaveOrUpdate = () => {
    // eslint-disable-next-line no-param-reassign
    accountData.notes = notes;
    onStarClick(accountData.id, undefined, notes);
    setIsNoteOpen(false);
    fetchAccountFiles();
  };

  useEffect(() => {
    if (selectedCompanyEvents?.length) {
      if (searchEvent) {
        const filteredList = selectedCompanyEvents?.filter((e: { event: { name: string; }; }) => e?.event?.name?.toLowerCase()?.includes(searchEvent));
        setFilteredCompanyEvents(filteredList);
      } else {
        setFilteredCompanyEvents(selectedCompanyEvents);
      }
    }
  }, [selectedCompanyEvents, searchEvent]);

  useEffect(() => {
    if (selectedCompanyLeads?.length) {
      if (searchLead) {
        const filteredList = selectedCompanyLeads.filter((c: { firstName: any; lastName: any; }) => `${c.firstName} ${c.lastName}`?.toLowerCase()?.includes(searchLead));
        setFilteredCompanyLeads(filteredList);
      } else {
        setFilteredCompanyLeads(selectedCompanyLeads);
      }
    }
  }, [selectedCompanyLeads, searchLead]);

  // const onCloseDeleteProduct = () => {
  //   setDeleteAccount({ open: false, accountId: null });
  // };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const renderCompanyInfo = (company: any) => (
    <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      <Stack sx={{ flexDirection: 'row' }} alignItems="center">
        <div
          className={classNames('w-[46px] max-w-[46px] min-w-[46px] h-[46px] max-h-[46px] min-h-[46px] flex items-center justify-center bg-white bg-center small-radius', {
            'bg-[#edf2f5]': !company?.logo,
          })}
        >
          <Logo
            alt="avatar"
            className="w-[44px] max-w-[44px] min-w-[44px] h-[44px] max-h-[44px] min-h-[44px] object-contain bg-center small-radius"
            src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${company?.company?.logo}`}
            defaultLogo="company"
          />
        </div>
        <Stack sx={{ ml: '10px' }}>
          <Typography
            onClick={() => onCompanyClick(company)}
            variant="subtitle2"
            sx={{
              fontFamily: 'Poppins-700', fontWeight: '700', fontSize: '14px', color: 'blue', lineHeight: '21px', cursor: 'pointer',
            }}
          >
            {company?.name || ''}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Poppins-400', fontWeight: '400', fontSize: '11px', color: 'grey',
            }}
          >
            {company?.industry?.name || ''}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ flexDirection: 'row' }}>
        <Checkbox
          sx={{ marginRight: '5px' }}
          {...label}
          icon={<StarBorder sx={{ color: 'gray' }} />}
          onClick={() => onStarClick(company?.id, company?.isStarred)}
          checked={company?.isStarred}
          checkedIcon={<Star sx={{ color: '#FFD700' }} />}
        />
      </Stack>
    </Stack>
  );

  const renderLeadInfo = (lead: any) => (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap="8px">
      <div
        className={classNames('w-[46px] max-w-[46px] min-w-[46px] h-[46px] max-h-[46px] min-h-[46px] flex items-center justify-center bg-white bg-center rounded-[20px]', {
          'bg-[#edf2f5]': !lead?.avatar,
        })}
      >
        <Avatar
          size="MEDIUM"
          src={
            lead?.avatar
              ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${lead?.avatar}`
              : defaultProfileLogo
                    }
          alt=""
        />
      </div>
      <Stack direction="row" alignItems="center">
        <Stack direction="column" onClick={() => onLeadClick(lead)} sx={{ width: '170px' }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Poppins-700', fontWeight: '700', fontSize: '14px', cursor: 'pointer'
            }}
            className="ellipsis-one-line"
          >
            {lead?.firstName}
            {' '}
            {lead?.lastName}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontFamily: 'Poppins-400', fontWeight: '400', fontSize: '11px' }} className="ellipsis-one-line">
            {lead?.jobtitle}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontFamily: 'Poppins-400', fontWeight: '400', fontSize: '11px' }} className="ellipsis-one-line">
            {accountData?.company?.name}
          </Typography>
        </Stack>
        {selectedCompanyLeads?.length > 0 && selectedCompanyLeads?.slice(1)?.length > 0 && (
          <Stack ml="25px">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                pl: '10px', pr: '13px', pb: '13px', cursor: 'pointer'
              }}
              onClick={() => {
                setIsLeadOpen(true);
                setSelectedCompanyLeads(accountData?.employees || []);
              }}
            >
              {selectedCompanyLeads?.slice(1)?.length > 0 && (
              <Stack direction="row">
                <Typography
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 'auto',
                    backgroundColor: '#F9FAFB',
                    borderRadius: '50%',
                    fontSize: '12px',
                    padding: '3px',
                    ml: '-10px',
                    width: '22px',
                    height: '22px',
                    position: 'relative',
                    zIndex: 10,
                  }}
                  variant="subtitle2"
                >
                  +
                  {selectedCompanyLeads?.slice(1)?.length}
                </Typography>
              </Stack>
              )}

              {/* <IconButton
              onClick={() => setShowDialog(true)}
            >
              <Avatar src={ProductRepresentative} alt="add btn" sx={{ width: '35px', height: '35px' }} />
            </IconButton> */}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );

  const renderEventInfo = (event: any) => {
    const darkenColor = (rgbArray: number[], factor = 0.6) => {
      const [r, g, b] = rgbArray || [255, 255, 255]; // Default to white if no color
      const darken = (value: number) => Math.max(0, Math.floor(value * factor)); // Darkens by a factor
      return `rgb(${darken(r)}, ${darken(g)}, ${darken(b)})`;
    };
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <div
          className={classNames(
            'w-[46px] max-w-[46px] min-w-[46px] h-[46px] max-h-[46px] min-h-[46px] flex items-center justify-center bg-center small-radius',
            { 'bg-[#edf2f5]': !event?.logo }
          )}
          style={{
            backgroundColor: event?.coverDominantColor
              ? darkenColor(event?.coverDominantColor)
              : '#edf2f5',
          }}
        >
          <Logo
            alt="event-logo"
            className="w-[44px] max-w-[44px] min-w-[44px] h-[44px] max-h-[44px] min-h-[44px] object-contain bg-center small-radius"
            src={event?.logo ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event?.logo}` : defaultLogo}
            defaultLogo="company"
          />
        </div>
        <Typography
          variant="subtitle2"
          sx={{
            fontFamily: 'Poppins-700', fontWeight: '700', fontSize: '14px', color: 'black', lineHeight: '21px', cursor: 'pointer',
          }}
          onClick={() => onEventClick(event)}
        >
          {event?.name || ''}
        </Typography>
        {selectedCompanyEvents?.length > 0 && selectedCompanyEvents?.slice(1)?.length > 0 && (
        <Stack ml="25px">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              pl: '10px', pr: '13px', pb: '13px', cursor: 'pointer'
            }}
            onClick={() => {
              setIsEventsOpen(true);
              setSelectedCompanyEvents(accountData?.events || []);
            }}
          >
            {selectedCompanyEvents?.slice(1)?.length > 0 && (
              <Stack direction="row">
                <Typography
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 'auto',
                    backgroundColor: '#F9FAFB',
                    borderRadius: '50%',
                    fontSize: '12px',
                    padding: '3px',
                    ml: '-10px',
                    width: '22px',
                    height: '22px',
                    position: 'relative',
                    zIndex: 10,
                  }}
                  variant="subtitle2"
                >
                  +
                  {selectedCompanyEvents?.slice(1)?.length}
                </Typography>
              </Stack>
            )}

            {/* <IconButton
              onClick={() => setShowDialog(true)}
            >
              <Avatar src={ProductRepresentative} alt="add btn" sx={{ width: '35px', height: '35px' }} />
            </IconButton> */}
          </Stack>
        </Stack>
        )}
      </Stack>
    );
  };

  return (
    <>
      <TableRow hover selected={selected} key={accountData.id}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
        <TableCell>
          <Stack>
            {renderCompanyInfo(accountData)}
          </Stack>
        </TableCell>
        <TableCell align="left">
          <Button
            onClick={() => setIsNoteOpen(true)}
            sx={{
              backgroundColor: 'transparent', textTransform: 'capitalize', fontFamily: 'Poppins-600', fontWeight: '600', fontSize: '12px', color: 'black'
            }}
            variant="text"
            startIcon={<EditSVG />}
          >
            Add note
          </Button>
        </TableCell>
        <TableCell align="left">
          {accountData?.employees?.length > 0 && (
            <div>
              {renderLeadInfo(accountData?.employees?.[0] || {})}
            </div>
          )}
        </TableCell>
        <TableCell align="left">
          {accountData?.events?.length > 0 && (
            <div>
              {renderEventInfo(accountData?.events[0]?.event)}
            </div>
          )}
        </TableCell>
        {/* <TableCell align="right">
          <Stack>
            <IconButton disabled={!selectedRows.includes(accountData?.id) || selectedRows?.length === 0} onClick={() => setDeleteAccount({ open: true, accountId: accountData?.id })}>
              <DeleteIcon style={{ cursor: 'pointer', width: '30px', height: '30px' }} />
            </IconButton>
          </Stack>
        </TableCell> */}
      </TableRow>

      {isNoteOpen && (
        <AlertModal
          className="w-576 p-2 container"
          closeIconClass="top-[18px] right-[11px]"
          isCloseIcon
          isOpen={isNoteOpen}
          onClose={() => setIsNoteOpen(false)}
        >
          <div className="px-[10px]">
            <div className="text-md px-2 py-1 pb-2">{accountData?.notes ? 'Update Notes' : 'Add Notes'}</div>
            <div className="px-2" />
            <div className="border-b-1 border-gray-300 my-2" />
            <div className="w-full h-340 overflow-auto">
              <TextField
                name="notes"
                type="text"
                value={notes}
                onChange={handleNotesChange}
                inputProps={{ maxLength: 250 }}
                rows={4}
                fullWidth
                multiline
                placeholder="Add your notes here..."
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: 'white',
                    marginTop: '10px',
                    padding: '10px'
                  },
                }}
              />
            </div>
            <Box display="flex" mt="8px" alignItems="end" justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: accountData.notes || notes ? '#0031FF' : '',
                  '&:hover': {
                    backgroundColor: '#0031FF',
                    '& .MuiTypography-root': {
                      color: '#ffffff',
                    }
                  }
                }}
                onClick={handleSaveOrUpdate}
              >
                {accountData?.notes ? 'Update' : 'Save'}
              </Button>
            </Box>
          </div>
        </AlertModal>
      )}
      {isEventsOpen && (
        <AlertModal
          isOpen={isEventsOpen}
          onClose={() => {
            setIsEventsOpen(false);
            setSearchEvent('');
          }}
          className="w-576 p-2 container"
        >
          <>
            <div className="text-md px-2 py-1 pb-4">More Events</div>
            <div className="px-2">
              <div className="px-3 py-1.5 flex items-end text-sm rounded-15 focus:outline-none w-[186px] h-[30px] border-1 border-blue-900 font-extralight text-gray-1 bg-gray-1">
                <SearchIcon />
                <input
                  name="search"
                  className="w-full ml-2 text-sm5 focus:outline-none block font-light text-gray-900 placeholder:text-gray-900 bg-transparent"
                  placeholder="Search"
                  onChange={e => { setSearchEvent(e.target.value.toLowerCase()); }}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="border-b-1 border-gray-300 my-2" />
            <div className="w-full h-420 overflow-auto px-4">
              <CompanyEventListCard
                onClick={(user: any) => {
                  setIsEventsOpen(false);
                  setSearchEvent('');
                  console.log('user', user);
                  // navigate(`/wall/${user.slug}`);
                }}
                events={filteredCompanyEvents || []}
                key="compnay-events"
              />
            </div>
          </>

        </AlertModal>
      )}
      {isLeadOpen && (
        <AlertModal
          isOpen={isLeadOpen}
          onClose={() => {
            setIsLeadOpen(false);
            setSearchLead('');
          }}
          className="w-576 p-2 container"
        >
          <>
            <div className="text-md px-2 py-1 pb-4">More Leads</div>
            <div className="px-2">
              <div className="px-3 py-1.5 flex items-end text-sm rounded-15 focus:outline-none w-[186px] h-[30px] border-1 border-blue-900 font-extralight text-gray-1 bg-gray-1">
                <SearchIcon />
                <input
                  name="search"
                  className="w-full ml-2 text-sm5 focus:outline-none block font-light text-gray-900 placeholder:text-gray-900 bg-transparent"
                  placeholder="Search"
                  onChange={e => { setSearchLead(e.target.value.toLowerCase()); }}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="border-b-1 border-gray-300 my-2" />
            <div className="w-full h-420 overflow-auto px-4">
              <CompanyLeadListCard
                onClick={(user: any) => {
                  setIsEventsOpen(false);
                  setSearchEvent('');
                  console.log('user', user);
                  // navigate(`/wall/${user.slug}`);
                }}
                leads={filteredCompanyLeads || []}
                company={accountData?.company?.name}
                key="compnay-leads"
              />
            </div>
          </>

        </AlertModal>
      )}
    </>
  );
};

export default CompaniesTableRow;
