/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { SVGProps } from 'react';

const ExportIcon = (props: SVGProps<SVGSVGElement>) => {
  const { style, ...restProps } = props;
  return (
    <svg
      id="Group_21695"
      data-name="Group 21695"
      xmlns="http://www.w3.org/2000/svg"
      width="19.025"
      height="19.917"
      viewBox="0 0 19.025 19.917"
      style={{ ...style }}
    >
      <g id="export-svgrepo-com" transform="translate(0 0)">
        <path
          id="Path_14893"
          data-name="Path 14893"
          d="M8.886,12.21a.892.892,0,0,1,1.261,0l2.045,2.045V4.517a.892.892,0,0,1,1.784,0v9.737l2.045-2.045a.892.892,0,1,1,1.261,1.261l-3.567,3.567a.892.892,0,0,1-1.261,0L8.886,13.471A.892.892,0,0,1,8.886,12.21Z"
          transform="translate(-3.572 2.617)"
          fill="#7281a4"
          fillRule="evenodd"
        />
        <path
          id="Path_14894"
          data-name="Path 14894"
          d="M13.887,12.375A9.512,9.512,0,0,1,23.4,21.887H18.941c-1.121,0-1.682,0-2.03-.348s-.348-.909-.348-2.03a2.675,2.675,0,1,0-5.351,0c0,1.121,0,1.682-.348,2.03s-.909.348-2.03.348H4.375A9.512,9.512,0,0,1,13.887,12.375Z"
          transform="translate(-4.375 -12.375)"
          fill="#7281a4"
        />
      </g>
    </svg>
  );
};

export default ExportIcon;
