import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { ReactTinyLink } from "react-tiny-link";
import documentIcon from "../../assets/images/attached-document.svg";
import { IConversationDetailProps } from "../../models/messages/message";
import { Tooltip } from "../../shared-components/Tooltip";
import { ChatTooltip } from "../../shared-components/Tooltip";
import MissCallIcon from "../../assets/images/miss-call.svg";
import { IAppState } from "../../store";
import { extractUrls } from "../../utils/extract-urls";
import { useCurrentUser, useWebSocket } from "../../utils/hooks";
import { ParseContent } from "../../utils/parse-html";
import classNames from "classnames";
import {
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Stack,
} from "@mui/material";
import { useMessagesScroll } from "src/mui/components/chat/hooks";
import { handleFailedMessages } from "src/store/privateChat";

import { ReactComponent as LikedSvg } from "src/assets/images/post-reactions/like-emoji.svg";
import { ReactComponent as LoveSVG } from "src/assets/images/post-reactions/love-emoji.svg";
import { ReactComponent as SmileSVG } from "src/assets/images/post-reactions/smile-emoji.svg";
import { ReactComponent as WellDoneSVG } from "src/assets/images/post-reactions/well-done-emoji.svg";
import { ReactComponent as HahaSVG } from "src/assets/images/post-reactions/haha-emoji.svg";
import Iconify from "src/mui/components/iconify";
import ReactionButtonsChat from "src/mui/components/chat/reaction-buttons-chat";
import { Avatar } from "src/shared-components/V2";
import { minifiedImage } from "src/utils";
import {
  checkForReplyFileType,
  fileThumb,
} from "src/mui/components/file-thumbnail";

export const ConversationDetail = (props: IConversationDetailProps) => {
  const dispatch = useDispatch();
  const { privateChat } = useSelector((state: IAppState) => state);
  const messages =
    privateChat?.oneToOneMessages?.[props.conversationId]?.data || [];
  const lastEvaluateKey =
    privateChat?.oneToOneMessages?.[props.conversationId]?.lastEvaluateKey ||
    null;
  const isNewMessage =
    privateChat?.oneToOneMessages?.[props.conversationId]?.isNewMessage || null;
  const { webSocket, refreshConnection } = useWebSocket();
  const [hoveredMessageIndex, setHoveredMessageIndex] = useState<any | null>(
    null
  );

  const bottomRef = useRef<any>(null);

  const user = useCurrentUser();
  const avatarURL = privateChat?.conversations?.Items?.filter(
    (item: any) => item.conversationId === props?.conversationId
  )[0]?.participantProfiles?.filter((item: any) => item.id === user?.id)[0]
    ?.avatar;
  const [groupedMessages, setGroupedMessages] = useState<any[]>([]);
  const { messagesEndRef, preserveScrollPosition } = useMessagesScroll(
    messages as any[],
    true
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);

  const handleEmojiClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClick = (event: any) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMoreAnchorEl(null);
  };

  const openEmoji = Boolean(anchorEl);
  const openMore = Boolean(moreAnchorEl);
  const emojiId = openEmoji ? "emoji-popover" : undefined;
  const moreId = openMore ? "more-popover" : undefined;

  const groupMessagesByDate = (array: any[], key: any) => {
    return array.reduce((result, currentItem) => {
      const resultKey = format(currentItem[key], "dd MMM yyyy");
      if (result[resultKey]) result[resultKey].push(currentItem);
      else {
        result[resultKey] = [];
        result[resultKey].push(currentItem);
      }
      return result;
    }, {});
  };

  const iconMapChat = {
    likes: LikedSvg,
    hahas: HahaSVG,
    loves: LoveSVG,
    claps: WellDoneSVG,
    smiles: SmileSVG,
  };

  type ReactionTypeChat = "likes" | "hahas" | "loves" | "claps" | "smiles";

  const iconMap = {
    likes: LikedSvg,
    hahas: HahaSVG,
    loves: LoveSVG,
    claps: WellDoneSVG,
    smiles: SmileSVG,
  };
  const typedIconMap: any = iconMap;

  enum ReactionType {
    PRIVATE_CHAT_LIKE_MESSAGE = "private-chat-like-message",
    PRIVATE_CHAT_LOVE_MESSAGE = "private-chat-love-message",
    PRIVATE_CHAT_HAHA_MESSAGE = "private-chat-haha-message",
    PRIVATE_CHAT_CLAP_MESSAGE = "private-chat-clap-message",
    PRIVATE_CHAT_SMILE_MESSAGE = "private-chat-smile-message",
  }

  const iconS = ["likes", "hahas", "loves", "claps", "smiles"];

  const handleDeleteMessage = (messageItem: any) => {
    webSocket.then((ws) => {
      if (ws.readyState === ws.OPEN) {
        ws.send(
          JSON.stringify({
            action: "remove-message",
            data: {
              messageId: messageItem.messageId,
              conversationId: messageItem.conversationId,
            },
          })
        );
      }
    });
  };

  const onMessageReact = async (_sender: string, type: string) => {
    const ws = await webSocket;
    let wsMessage = "";
    let action = "";
    switch (type) {
      case "like":
        action = ReactionType.PRIVATE_CHAT_LIKE_MESSAGE;
        break;
      case "haha":
        action = ReactionType.PRIVATE_CHAT_HAHA_MESSAGE;
        break;
      case "love":
        action = ReactionType.PRIVATE_CHAT_LOVE_MESSAGE;
        break;
      case "clap":
        action = ReactionType.PRIVATE_CHAT_CLAP_MESSAGE;
        break;
      case "smile":
        action = ReactionType.PRIVATE_CHAT_SMILE_MESSAGE;
        break;
      default:
        break;
    }
    wsMessage = JSON.stringify({
      action,
      data: {
        messageId: _sender,
        conversationId: props.conversationId,
      },
    });
    preserveScrollPosition();
    if (ws.readyState !== ws.OPEN) {
      dispatch(handleFailedMessages(wsMessage));
      return refreshConnection({});
    }
    ws.send(wsMessage!);
  };

  const renderActions = (messageItem: any) => (
    <>
      <Stack
        direction="row"
        className="message-actions"
        sx={{
          marginBottom: "15px",
          borderRadius: "30px",
          zIndex: "100",
          background: "snow",
          position: "absolute",
          transition: (theme) =>
            theme.transitions.create(["opacity"], {
              duration: theme.transitions.duration.shorter,
            }),
          ...(user?.id === messageItem.sender
            ? {
                left: "unset",
                top: "40%",
                right: "100%",
                opacity: 1,
              }
            : {
                right: "unset",
                top: "40%",
                left: "100%",
                opacity: 1,
              }),
        }}
      >
        <IconButton
          size="small"
          style={{ padding: "1px !important", zIndex: 0 }}
          onClick={handleEmojiClick}
        >
          <Iconify
            icon="emojione:smiling-face-with-smiling-eyes"
            width={14}
            height={14}
          />
        </IconButton>
        <Popover
          id={emojiId}
          open={openEmoji}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              borderRadius: "25px",
              backgroundColor: "#424242",
              boxShadow: "unset",
            },
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Stack direction="row" p="1px">
            {iconS.map((item) => {
              const IconComponent = typedIconMap[item];
              return (
                <IconButton
                  key={item}
                  size="small"
                  style={{ padding: "0px !important", zIndex: 0 }}
                  onClick={() => {
                    onMessageReact(messageItem?.messageId, item.slice(0, -1));
                    handleClose();
                  }}
                >
                  <IconComponent width={30} height={30} />
                </IconButton>
              );
            })}
          </Stack>
        </Popover>

        <IconButton
          size="small"
          style={{ padding: "1px !important", zIndex: 0 }}
          onClick={() => {
            props.onMessageReply(messageItem);
          }}
        >
          <Iconify
            color={"black"}
            icon="fa6-solid:reply"
            width={15}
            height={15}
          />
        </IconButton>

        {messageItem.sender === user?.id && (
          <IconButton
            size="small"
            style={{ padding: "1px !important", zIndex: 0 }}
            onClick={handleMoreClick}
          >
            <Iconify
              color={"black"}
              icon="eva:more-vertical-fill"
              width={16}
              height={16}
            />
          </IconButton>
        )}
        <Popover
          id={moreId}
          open={openMore}
          anchorEl={moreAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              backgroundColor: "gray",
              color: "white",
              borderRadius: "8px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Paper
              sx={{
                backgroundColor: "inherit",
                boxShadow: "none",
              }}
            >
              <MenuList>
                {messageItem?.type === "text" &&
                  messageItem.sender === user?.id && (
                    <MenuItem
                      onClick={() => {
                        if (!props.editMessage)
                          props.onMessageUpdate(messageItem);
                        handleClose();
                      }}
                      sx={{
                        color: "white",
                      }}
                    >
                      Edit
                    </MenuItem>
                  )}
                <MenuItem
                  onClick={() => {
                    if (!props.editMessage) handleDeleteMessage(messageItem);
                    handleClose();
                  }}
                  sx={{
                    color: "white",
                  }}
                >
                  Remove
                </MenuItem>
              </MenuList>
            </Paper>
          </Box>
        </Popover>
      </Stack>
    </>
  );

  useEffect(() => {
    if (
      props.conversationId &&
      !privateChat?.oneToOneMessages?.[props.conversationId]
    ) {
      webSocket.then((ws) =>
        ws.send(
          JSON.stringify({
            action: "messages",
            data: {
              conversationId: props.conversationId,
              option: { limit: 10, sorted: false, exclusiveKey: null },
            },
          })
        )
      );
      setTimeout(() => {
        bottomRef.current.scrollIntoView({ bottom: 0, behavior: "smooth" });
      }, 800);
    }
  }, [props.conversationId, webSocket]);

  useEffect(() => {
    if (messages?.length) {
      setGroupedMessages(groupMessagesByDate(messages, "replyDate"));
      if (isNewMessage) {
        setTimeout(() => {
          bottomRef?.current?.scrollIntoView({ bottom: 0, behavior: "smooth" });
        }, 500);
      }
    }
  }, [messages]);

  const handleMouseEnter = (index: any) => {
    setHoveredMessageIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredMessageIndex(null);
  };

  const renderMessage = (
    messageItem: any,
    index: number,
    groupLength: number
  ) => {
    const isLastMessage = index === groupLength - 1;
    const reactions = (
      Object.keys(messageItem?.reactions!) as ReactionTypeChat[]
    ).map((type) => ({
      type: type.slice(0, -1),
      count: messageItem?.reactions![type].length,
      Icon: iconMapChat[type],
      users: messageItem?.reactions![type],
    }));
    if (messageItem.type === "text") {
      let urls = extractUrls(messageItem.message);
      return (
        <Box sx={{ position: "relative", marginTop: "20px" }}>
          <div
            className="hover-div"
            style={{ position: "relative", marginTop: "20px" }}
            onMouseEnter={() => handleMouseEnter(messageItem.messageId)}
            onMouseLeave={handleMouseLeave}
          >
            <ChatTooltip
              message={""}
              containerClass={classNames({
                "!items-start": messageItem.sender !== user?.id,
              })}
              className="shadow-none"
            >
              <div style={{ position: "relative" }}>
                {(messageItem?.replyToMessage?.message ||
                  messageItem?.replyToMessage?.source) && (
                  <div
                    className={`text-xs font-light px-2 py-3 rounded-lg rounded-r-[4px] rounded-l-[4px] -mb-2 w-max max-w-full min-w-[10px] overflow-hidden bg-gray-300 text-gray-1 justify-end ${
                      messageItem.sender !== user?.id ? "mr-auto" : "ml-auto"
                    }`}
                  >
                    {messageItem?.replyToMessage?.message}
                    {!!messageItem?.replyToMessage?.source &&
                      messageItem?.replyToMessage?.type === "image" && (
                        <a
                          href={`${process.env.REACT_APP_S3_IMAGE_BUCKET}/${messageItem?.replyToMessage?.source}`}
                          target="_blank"
                        >
                          <img
                            src={minifiedImage(
                              messageItem?.replyToMessage?.source
                            )}
                            className={`${
                              messageItem.sender !== user?.id
                                ? "justify-end"
                                : "justify-start"
                            } object-fit h-[100px] rounded-lg`}
                            alt=""
                          />
                        </a>
                      )}
                    {!!messageItem?.replyToMessage?.source &&
                      messageItem?.replyToMessage?.type === "video" && (
                        <video
                          id="video"
                          className="rounded-[10px] max-w-[210px]"
                          src={`${process.env.REACT_APP_S3_MEDIA_BUCKET}/${messageItem?.source}`}
                          controls={true}
                        />
                      )}
                    {!!messageItem?.replyToMessage?.source &&
                      messageItem?.replyToMessage?.type === "document" && (
                        <Stack
                          sx={{
                            fontSize: "13px",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            fontFamily: "Poppins-400",
                            lineHeight: "18px",
                            color: "#626262",
                            borderRadius: "5px 5px 5px 0px",
                            typography: "body2",
                            position: "relative",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            src={fileThumb(
                              checkForReplyFileType(
                                messageItem?.replyToMessage?.source?.split(
                                  "/"
                                )[3]
                              )
                            )}
                            alt={fileThumb(
                              checkForReplyFileType(
                                messageItem?.replyToMessage?.source?.split(
                                  "/"
                                )[3]
                              )
                            )}
                            sx={{
                              width: 27,
                              height: 30,
                              flexShrink: 0,
                            }}
                          />
                          <div className="text-sm font-light ellipsis-one-line text-white ml-2">
                            {messageItem?.replyToMessage?.source?.split("/")[3]}
                          </div>
                        </Stack>
                      )}
                  </div>
                )}
                <div
                  className={`${
                    messageItem.sender !== user?.id
                      ? "bg-[#EDF2F5] float-left text-gray-1 justify-end ml-auto relative rounded-l-[4px] rounded-r-[12px]"
                      : "bg-[#0049EF] float-right text-white rounded-r-[4px] relative rounded-l-[12px]"
                  } text-sm px-2.5 py-2.5 mb-0 rounded-lg break-words overflow-hidden max-w-max`}
                  key={`message-${index}`}
                >
                  <div className="w-max max-w-[168px] min-w-[10px] overflow-hidden">
                    <ParseContent content={messageItem.message} />
                  </div>
                  {urls.length > 0 && (
                    <ReactTinyLink
                      cardSize="large"
                      showGraphic={true}
                      maxLine={2}
                      minLine={1}
                      url={urls[0]}
                    />
                  )}
                </div>
                {hoveredMessageIndex === messageItem.messageId &&
                  renderActions(messageItem)}
              </div>
            </ChatTooltip>
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                zIndex: "0",
                bottom: "-12px",
                right: `${messageItem.sender !== user?.id ? "unset" : "4px"}`,
                left: `${messageItem.sender === user?.id ? "unset" : "4px"}`,
              }}
            >
              {reactions.map(
                ({ type, count, Icon, users }) =>
                  count > 0 && (
                    <ReactionButtonsChat
                      key={type}
                      type={type}
                      count={count}
                      Icon={Icon}
                      users={users}
                      userId={user.id}
                      onClick={() =>
                        onMessageReact(messageItem?.messageId, type)
                      }
                    />
                  )
              )}
            </Box>
          </div>
          {messageItem.sender === user?.id &&
            messageItem.seen &&
            isLastMessage && (
              <Avatar
                src={
                  `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${messageItem?.seenBy?.avatar}` ||
                  `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${avatarURL}`
                }
                size="XXSMALLCHAT"
                className={`${
                  reactions.length > 0 ? "mt-[15px]" : "mt-1"
                } absolute right-[0px]`}
              />
            )}
        </Box>
      );
    }

    if (messageItem.type === "image") {
      return (
          <div
            className="hover-div"
            style={{ position: "relative", marginTop: "20px" }}
            onMouseEnter={() => handleMouseEnter(messageItem.messageId)}
            onMouseLeave={handleMouseLeave}
          >
            <ChatTooltip
              message={""}
              containerClass={classNames({
                "!items-start": messageItem.sender !== user?.id,
              })}
              className="shadow-none"
            >
              <div style={{ position: "relative" }}>
                {!!messageItem?.replyToMessage?.message && (
                  <div
                    className={`text-xs font-light px-2 py-3 rounded-lg rounded-r-[4px] rounded-l-[4px]  -mb-2 w-max max-w-full min-w-[10px] overflow-hidden bg-gray-300 text-gray-1 justify-end ${
                      messageItem.sender !== user?.id ? "mr-auto" : "ml-auto"
                    }`}
                  >
                    {messageItem?.replyToMessage?.message}
                  </div>
                )}
                <div
                  className={`${
                    messageItem.sender !== user?.id
                      ? "bg-[#EDF2F5] float-left text-gray-1 justify-end ml-auto relative rounded-l-[4px] rounded-r-[12px]"
                      : "bg-[#0049EF] float-right text-white rounded-r-[4px] relative rounded-l-[12px]"
                  } text-sm px-2.5 py-2.5 mb-0 rounded-lg break-words overflow-hidden max-w-max`}
                  key={`message-${index}`}
                >
                  {messageItem.message && messageItem.source && (
                    <div className="w-max max-w-[168px] min-w-[10px] overflow-hidden">
                      <ParseContent content={messageItem.message} />
                    </div>
                  )}
                  <a
                    href={`${process.env.REACT_APP_S3_IMAGE_BUCKET}/${messageItem?.source}`}
                    target="_blank"
                  >
                    <img
                      src={minifiedImage(messageItem?.source)}
                      className={`${
                        messageItem.sender !== user?.id
                          ? "justify-end float-left"
                          : "justify-start float-right"
                      } object-fit h-[150px] rounded-lg`}
                      alt=""
                    />
                  </a>
                </div>
                {hoveredMessageIndex === messageItem.messageId &&
                  renderActions(messageItem)}
              </div>
            </ChatTooltip>
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                zIndex: "0",
                bottom: `${
                  messageItem.sender === user?.id &&
                  messageItem?.seen &&
                  isLastMessage
                    ? "10px"
                    : "-12px"
                }`,
                right: `${messageItem.sender !== user?.id ? "unset" : "4px"}`,
                left: `${messageItem.sender === user?.id ? "unset" : "4px"}`,
              }}
            >
              {reactions.map(
                ({ type, count, Icon, users }) =>
                  count > 0 && (
                    <ReactionButtonsChat
                      key={type}
                      type={type}
                      count={count}
                      Icon={Icon}
                      users={users}
                      userId={user.id}
                      onClick={() =>
                        onMessageReact(messageItem?.messageId, type)
                      }
                    />
                  )
              )}
            </Box>
            {messageItem.sender === user?.id &&
              messageItem?.seen &&
              isLastMessage && (
                <Avatar
                  src={
                    `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${messageItem?.seenBy?.avatar}` ||
                    `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${avatarURL}`
                  }
                  size="XXSMALLCHAT"
                  className={`${
                    reactions.length > 0 ? "mt-[16px]" : "mt-1"
                  } absolute right-[0px]`}
                />
              )}
          </div>
      );
    }

    if (messageItem.type === "video") {
      return (
          <div
            className="hover-div"
            style={{ position: "relative", marginTop: "20px" }}
            onMouseEnter={() => handleMouseEnter(messageItem.messageId)}
            onMouseLeave={handleMouseLeave}
          >
            <ChatTooltip
              message={""}
              containerClass={classNames({
                "!items-start": messageItem.sender !== user?.id,
              })}
              className="shadow-none"
            >
              <div style={{ position: "relative" }}>
                {!!messageItem?.replyToMessage?.message && (
                  <div
                    className={`text-xs font-light px-2 py-3 rounded-lg rounded-r-[4px] rounded-l-[4px]  -mb-2 w-max max-w-full min-w-[10px] overflow-hidden bg-gray-300 text-gray-1 justify-end ${
                      messageItem.sender !== user?.id ? "mr-auto" : "ml-auto"
                    }`}
                  >
                    {messageItem?.replyToMessage?.message}
                  </div>
                )}
                <div
                  className={`${
                    messageItem.sender !== user?.id
                      ? "bg-[#EDF2F5] float-left text-gray-1 justify-end ml-auto relative rounded-l-[4px] rounded-r-[12px]"
                      : "bg-[#0049EF] float-right text-white rounded-r-[4px] relative rounded-l-[12px]"
                  } text-sm px-2.5 py-2.5 mb-0 rounded-lg break-words overflow-hidden max-w-[230px]`}
                  key={`message-${index}`}
                >
                  {messageItem.message && messageItem.source && (
                    <div className="w-max max-w-[168px] min-w-[10px] overflow-hidden">
                      <ParseContent content={messageItem.message} />
                    </div>
                  )}
                  <video
                    id="video"
                    className="rounded-[10px]"
                    src={`${process.env.REACT_APP_S3_MEDIA_BUCKET}/${messageItem?.source}`}
                    controls={true}
                  />
                </div>
                {hoveredMessageIndex === messageItem.messageId &&
                  renderActions(messageItem)}
              </div>
            </ChatTooltip>
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                zIndex: "0",
                bottom: `${
                  messageItem.sender === user?.id &&
                  messageItem?.seen &&
                  isLastMessage
                    ? "10px"
                    : "-12px"
                }`,
                right: `${messageItem.sender !== user?.id ? "unset" : "4px"}`,
                left: `${messageItem.sender === user?.id ? "unset" : "4px"}`,
              }}
            >
              {reactions.map(
                ({ type, count, Icon, users }) =>
                  count > 0 && (
                    <ReactionButtonsChat
                      key={type}
                      type={type}
                      count={count}
                      Icon={Icon}
                      users={users}
                      userId={user.id}
                      onClick={() =>
                        onMessageReact(messageItem?.messageId, type)
                      }
                    />
                  )
              )}
            </Box>
            {messageItem.sender === user?.id &&
              messageItem.seen &&
              isLastMessage && (
                <Avatar
                  src={
                    `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${messageItem?.seenBy?.avatar}` ||
                    `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${avatarURL}`
                  }
                  size="XXSMALLCHAT"
                  className={`${
                    reactions.length > 0 ? "mt-[16px]" : "mt-1"
                  } absolute right-[0px]`}
                />
              )}
          </div>
      );
    }

    if (messageItem.type === "document") {
      return (
        <div
          className="hover-div"
          style={{ position: "relative", marginTop: "20px" }}
          onMouseEnter={() => {
            handleMouseEnter(messageItem.messageId);
          }}
          onMouseLeave={handleMouseLeave}
        >
          <ChatTooltip
            message={""}
            containerClass={classNames({
              "!items-start": messageItem.sender !== user?.id,
            })}
            className="shadow-none"
          >
            <div style={{ position: "relative" }}>
              {!!messageItem?.replyToMessage?.message && (
                <div
                  className={`text-xs font-light px-2 py-3 rounded-lg rounded-r-[4px] rounded-l-[4px] -mb-2 w-max max-w-full min-w-[10px] overflow-hidden bg-gray-300 text-gray-1 justify-end ${
                    messageItem.sender !== user?.id ? "mr-auto" : "ml-auto"
                  }`}
                >
                  {messageItem?.replyToMessage?.message}
                </div>
              )}
              <div
                className={`${
                  messageItem.sender !== user?.id
                    ? "bg-[#EDF2F5] float-left text-gray-1 justify-end ml-auto relative rounded-l-[4px] rounded-r-[12px]"
                    : "bg-[#0049EF] float-right text-white rounded-r-[4px] relative rounded-l-[12px]"
                } text-sm px-2.5 py-2.5 mb-0 rounded-lg break-words overflow-hidden max-w-max`}
                key={`message-${index}`}
              >
                {messageItem.message && messageItem.source && (
                  <div className="w-max max-w-[168px] min-w-[10px] overflow-hidden">
                    <ParseContent content={messageItem.message} />
                  </div>
                )}

                <a
                  href={
                    `${process.env.REACT_APP_S3_MEDIA_BUCKET}/${messageItem?.source}` ||
                    `${process.env.REACT_APP_S3_MEDIA_BUCKET}/${messageItem?.message}`
                  }
                  target="_blank"
                >
                  <Stack
                    sx={{
                      fontSize: "13px",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      fontFamily: "Poppins-400",
                      lineHeight: "18px",
                      color: "#626262",
                      borderRadius: "5px 5px 5px 0px",
                      typography: "body2",
                      position: "relative",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={fileThumb(
                        checkForReplyFileType(
                          messageItem?.source?.split("/")[3]
                        )
                      )}
                      alt={fileThumb(
                        checkForReplyFileType(
                          messageItem?.source?.split("/")[3]
                        )
                      )}
                      sx={{
                        width: 27,
                        height: 30,
                        flexShrink: 0,
                      }}
                    />
                    <div
                      className={`text-sm font-light ${
                        messageItem.sender !== user?.id
                          ? "text-[#454545]"
                          : "text-white"
                      } max-w-[100px] ellipsis-one-line ml-2`}
                    >
                      {messageItem.source?.split("/")[3]}
                    </div>
                  </Stack>
                </a>
              </div>
              {hoveredMessageIndex === messageItem.messageId &&
                renderActions(messageItem)}
            </div>
          </ChatTooltip>
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              zIndex: "0",
              bottom: `${
                messageItem.sender === user?.id &&
                messageItem?.seen &&
                isLastMessage
                  ? "10px"
                  : "-12px"
              }`,
              right: `${messageItem.sender !== user?.id ? "unset" : "4px"}`,
              left: `${messageItem.sender === user?.id ? "unset" : "4px"}`,
            }}
          >
            {reactions.map(
              ({ type, count, Icon, users }) =>
                count > 0 && (
                  <ReactionButtonsChat
                    key={type}
                    type={type}
                    count={count}
                    Icon={Icon}
                    users={users}
                    userId={user.id}
                    onClick={() => onMessageReact(messageItem?.messageId, type)}
                  />
                )
            )}
          </Box>
          {messageItem.sender === user?.id &&
            messageItem.seen &&
            isLastMessage && (
              <Avatar
                src={
                  `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${messageItem?.seenBy?.avatar}` ||
                  `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${avatarURL}`
                }
                size="XXSMALLCHAT"
                className={`${
                  reactions.length > 0 ? "mt-[16px]" : "mt-1"
                } absolute right-[0px]`}
              />
            )}
        </div>
      );
    }

    if (messageItem.type === "miss-call") {
      return (
        <Tooltip
          className="shadow-none"
          position="top-[50%] -mt-[20px]"
          message={format(messageItem.replyDate, "dd MMM yyyy h:mm a")}
        >
          <div className="flex flex-row rounded-10 bg-gray-1 p-2 w-[167px]">
            <img src={MissCallIcon} alt="" />
            <div className="p-1">
              <p className="px-3 text-xs font-medium">Call</p>
              <p className="px-3 text-xs">
                {format(messageItem.replyDate, "h:mm a")}
              </p>
            </div>
          </div>
        </Tooltip>
      );
    }
  };

  const renderDateOfMessages = (dateOfMessages: any) => (
    <div className="flex justify-center font-semibold mt-2 text-blue-2 text-x-sm text-center mx-auto">
      {dateOfMessages}
    </div>
  );

  useEffect(() => {
    const handleScroll = () => {
      if (messagesEndRef.current) {
        const { scrollTop } = messagesEndRef.current;
        if (scrollTop === 0 && lastEvaluateKey) {
          preserveScrollPosition();
          if (!lastEvaluateKey) return;
          webSocket.then((ws) => {
            ws.send(
              JSON.stringify({
                action: "messages",
                data: {
                  conversationId: props.conversationId,
                  option: {
                    limit: 10,
                    sorted: false,
                    exclusiveStartKey: lastEvaluateKey,
                  },
                },
              })
            );
          });
        }
      }
    };

    const currentRef = messagesEndRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [lastEvaluateKey, webSocket, preserveScrollPosition]);

  return (
    <div className="flex flex-col">
      <div
        ref={messagesEndRef}
        className={`${
          props.editMessage
            ? "h-[340px]"
            : props?.isDocument &&
              !props?.isDocument[0]?.type?.includes("image") &&
              !props?.isDocument[0]?.type?.includes("video")
            ? "h-[333px]"
            : props?.isDocument && props?.isDocument[0]?.type?.includes("image")
            ? "h-[270px]"
            : props?.isDocument && props?.isDocument[0]?.type?.includes("video")
            ? "h-[265px]"
            : props?.isReply?.type === "image"
            ? "h-[280px]"
            : props?.isReply?.type === "document"
            ? "h-[300px]"
            : props?.isReply?.type === "video"
            ? "h-[250px]"
            : props?.isReply?.source === null || props?.isReply?.type === "text"
            ? "h-[340px]"
            : "h-[374px]"
        } overflow-y-scroll px-4 py-2 bg-white shadow-xs7 overscroll-y-contain`}
        // className="h-[374px] overflow-y-scroll px-4 py-2 bg-white shadow-xs7 overscroll-y-contain"
      >
        {Object.keys(groupedMessages).map((entry: any) => (
          <>
            {renderDateOfMessages(entry)}
            {groupedMessages[entry].map((item: any, index: number) =>
              renderMessage(item, index, groupedMessages[entry].length)
            )}
          </>
        ))}
        <div ref={bottomRef}></div>
      </div>
    </div>
  );
};
