import { useCallback, useMemo } from 'react';
import "./MobileToolBar.css"
import { Box, Button, IconButton, Link, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { NavbarProps } from './ConstantsandTypes';
import useEventIsPassed from '../../survey/useEventIsPassed';
import useStyling from 'src/mui/components/hook-form/useStyling';
import NextIcon from './NextIcon';


function BottomBanner({ pageItems, lastSurveyorRegistrationPage, isSuveryorRegSkipable, handleSkip, stepName, submitPage, user, onLogout, isLoading, onPreviousStep, onNextStep, setOpenForm, showBackButton, showNextButton, requiredFieldsFilled, surveySubPage, bgColor, step, currentPage }: NavbarProps) {
    const { isPastEvent, eventType, canAttendeesRequestMeeting } = useEventIsPassed()

    const mobileScreen = useMediaQuery('(max-width:800px)');
    const { primaryBackgroundColor, secondaryBackgroundColor, headerColor, secondaryFontColor } = useStyling(null, null)
    const showNextButtonText = useMemo(() => {
        if (currentPage === "underReview") {
            return "Complete"
        }
        else if (currentPage === "rejected") {
            return "Rejected"
        }

        else if (currentPage === "forgotPassowrd") {
            return "Continue"
        }

        else if (currentPage === "signupRegistrationForm") {
            return "Next"
        }

        else if (currentPage === "registrationForm" && lastSurveyorRegistrationPage) {
            if (eventType === "invitation") return "Submit";
            else return "Continue"
        }

        else if (currentPage === "surveyForm") {
            if (surveySubPage === "surveyForm" && lastSurveyorRegistrationPage) {
                if (isPastEvent) return "COMPLETE"
                if (!canAttendeesRequestMeeting) return "SUBMIT"
                else return mobileScreen ? "CONFIRM" : "CONFIRM & SEE ATTENDEES"

            }

            else if (surveySubPage === "attendeesListView") {
                if (isPastEvent) return "COMPLETE"
                return "COMPLETE"
            }

            else if (surveySubPage === "meetingListView") {
                if (isPastEvent) return "COMPLETE"
                return "Confirm"
            }
            else return "Next"
        }

        else if (stepName?.includes("Login")) {
            return "Continue"
        }
        else return "Next"

    }, [showBackButton, isPastEvent, lastSurveyorRegistrationPage, showNextButton, surveySubPage, currentPage, mobileScreen])

    const showSkipButton = useMemo(() => {
        if (currentPage === "underReview" || currentPage === "signupRegistrationForm" || currentPage === "rejected") {
            return false;
        }

        else if (stepName?.includes("Login")) {
            return false;
        }

        else if (currentPage === "forgotPassowrd" || currentPage === "passwordProtected") {
            return false;
        }

        else if (currentPage === "surveyForm") {
            if (surveySubPage === "attendeesListView" || surveySubPage === "meetingListView") {
                return false;
            }
        }
        return "Skip"
    }, [showBackButton, isSuveryorRegSkipable, showNextButton, surveySubPage, currentPage])

    const showActionButton = useMemo(() => {
        if (currentPage === "surveyForm" && surveySubPage === "attendeesListView") {
            return false
        }
        return true
    }, [surveySubPage, currentPage])

    const disableSkipButton = useMemo(() => {
        if (currentPage === "surveyForm") {
            if (surveySubPage === "surveyForm" && !isSuveryorRegSkipable) {
                return true
            }
        }
        else if (currentPage === "registrationForm" && !isSuveryorRegSkipable) {
            return true
        }
        return false
    }, [showBackButton, isSuveryorRegSkipable, showNextButton, surveySubPage, currentPage])

    const skiporCloseModal = () => {
        if (isSuveryorRegSkipable && handleSkip) handleSkip()
        else setOpenForm(false)
    }

    const handleCloseorSkip = useCallback(() => {
        if (currentPage === "surveyForm") {
            if (surveySubPage === "attendeesListView" || surveySubPage === "meetingListView") {
                // if (isPastEvent) return "Close"
                return setOpenForm(false)
            }
            else {
                skiporCloseModal()
            }
        }
        else if (currentPage === "registrationForm") {
            skiporCloseModal()
        }
        else setOpenForm(false)
    }, [isSuveryorRegSkipable, showBackButton, showNextButton, surveySubPage, currentPage])


    const actionButtonBackgroundColor = useMemo(() => {
        return pageItems?.bgColor || primaryBackgroundColor
    }, [pageItems, primaryBackgroundColor])

    const background = pageItems?.headerColor || headerColor

    return (
        <nav className={`bottom-banner ${navigator.userAgent.indexOf("Chrome") !== -1 && window.innerWidth >= 768 ? 'chrome-style' : ''}`} style={{
            paddingLeft: 8,
            backgroundColor: background,
            height: "50px"
        }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{
                height: "100%",
                "@media (max-width: 768px)": {
                    display: 'flex',
                    flexDirection: "row",
                },

            }}>
                <Box>
                    {user &&
                        <Button onClick={onLogout} sx={{ color: pageItems?.secondaryFontColor || secondaryFontColor, textAlign: "start", fontFamily: 'poppins-500', fontSize: '12px', textTransform: "none", marginLeft: "6px", fontWeight: 500 }}>
                            {mobileScreen ? "" :
                                "Register as someone else?"
                            }

                            <Link
                                onClick={onLogout}
                                sx={{
                                    color: pageItems?.bgColor || primaryBackgroundColor, cursor: "pointer", ml: 1, fontFamily: 'poppins-800', textDecoration: 'none',
                                    "@media (max-width: 768px)": {
                                        ml: "0px",
                                        pl: "0px"
                                    },
                                }}
                            >
                                Log out
                            </Link>

                        </Button>
                    }
                </Box>

                <Stack spacing={1} direction="row" alignItems="center" sx={{ mr: '10px' }} >
                    {/* {showSkipButton && !disableSkipButton && showActionButton &&
                        <Button
                            sx={{ color: !disableSkipButton ? "white" : null }} disabled={disableSkipButton}>
                            <Link
                                onClick={handleCloseorSkip}
                                sx={{ cursor: !disableSkipButton ? "pointer" : "no-drop", color: !disableSkipButton ? pageItems?.primaryBackgroundColor || primaryBackgroundColor : "#dfdddd", fontFamily: 'Poppins-400' }}
                            >
                                {showSkipButton}
                            </Link>
                        </Button>} */}

                    {showActionButton && showBackButton ?
                        <Stack spacing={0} direction="row" alignItems="center" sx={{ mr: "2px" }} >
                            <Button
                                key={"back and forward button 1"}
                                variant="contained"
                                disableElevation
                                sx={{
                                    height: "35px",
                                    color: pageItems?.headerColor || headerColor,
                                    borderRadius: "5px 0 0 5px",
                                    fontSize: "12px",
                                    background: showBackButton ? "white" : actionButtonBackgroundColor,
                                    padding: "0px",
                                    borderRight: "1 px slid #a2cdc5",
                                    // minWidth: showBackButton ? '40px' : "40px",
                                    minWidth: "40px",
                                    "&:hover": {
                                        backgroundColor: showBackButton ? "white" : actionButtonBackgroundColor,
                                        // opacity: 0.9
                                    },
                                }}
                            >
                                {showBackButton ? <IconButton
                                    onClick={(e) => { e.stopPropagation(); if (onPreviousStep) onPreviousStep() }}
                                    disabled={!showBackButton}
                                    sx={{
                                        transform: 'rotate(180deg)'
                                    }}
                                >
                                    <NextIcon />
                                </IconButton> : null}
                            </Button>
                            <Button
                                key={"back and forward button 2"}
                                variant="contained"
                                disableElevation
                                sx={{
                                    height: "35px",
                                    color: pageItems?.headerColor || headerColor,
                                    // borderRadius: showBackButton ? "0px 5px 5px 0px" : "5px",
                                    borderRadius: "0px 5px 5px 0px",
                                    fontSize: "12px",
                                    backgroundColor: actionButtonBackgroundColor,
                                    padding: "0px 8px 0px 0px",
                                    "&:hover": {
                                        backgroundColor: actionButtonBackgroundColor,
                                        opacity: 0.9
                                    },
                                }}
                                onClick={submitPage}
                            >
                                <Tooltip title={showNextButtonText}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: pageItems?.headerColor || headerColor,
                                            p: '0 15px 0 25px',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            fontSize: "16px",
                                            lineHieght: "28px",
                                            fontFamily: 'Poppins-500'
                                        }}
                                    // onClick={onNextStep}
                                    >
                                        {showNextButtonText}
                                    </Typography>

                                </Tooltip>


                                {/* <IconButton onClick={onNextStep} disabled={!showNextButton} > */}
                                {/* <IconButton disabled={!showNextButton} >
                                    <NextIcon style={{ fill: pageItems?.headerColor || headerColor }} />
                                </IconButton> */}
                            </Button>
                        </Stack> :

                        <Button
                            key={"back and forward button 3"}
                            variant="contained"
                            disableElevation
                            sx={{
                                position: "relative",
                                height: "35px",
                                color: pageItems?.headerColor || headerColor,
                                // borderRadius: showBackButton ? "0px 5px 5px 0px" : "5px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                // width: "240px",

                                backgroundColor: actionButtonBackgroundColor,
                                padding: "0px 20px",
                                "&:hover": {
                                    backgroundColor: actionButtonBackgroundColor,
                                    opacity: 0.9
                                },
                            }}
                            onClick={submitPage}
                        >
                            <Tooltip title={showNextButtonText}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: pageItems?.headerColor || headerColor,
                                        pt: '2px',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        fontSize: "16px",
                                        lineHieght: "28px",
                                        fontFamily: 'Poppins-500'
                                    }}

                                >
                                    {showNextButtonText}
                                </Typography>

                            </Tooltip>


                            {/* <IconButton disabled={!showNextButton} sx={{ position: "absolute", right: 15 }}>
                                <NextIcon style={{ fill: pageItems?.headerColor || headerColor }} />
                            </IconButton> */}
                        </Button>



                    }
                </Stack>
            </Stack>
        </nav >
    );
}

export default BottomBanner;