/* eslint-disable */
export enum ErrorReason {
  Unknown = 'Unknown',
  NotFound = 'NotFound',
  AlreadyExists = 'AlreadyExists',
  Invalid = 'Invalid',
  Failure = 'Failure',
  NotSupported = 'NotSupported',
  AccessDenied = 'AccessDenied',
  InternalError = 'InternalError'
}
