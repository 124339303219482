/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-console */
import {
  Button,
  IconButton, Stack, Table, TableBody, TableContainer,
  Tooltip,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { TablePaginationCustom, TableSelectedAction, useTable } from 'src/mui/components/table';
import { CompanyService } from 'src/services';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { useNavigate, useParams } from 'react-router';
import { Loader } from 'src/shared-components/Loader';
import { NoEvents } from 'src/screens/events/event-tab/components/no-events';
import { ConfirmDialog } from 'src/mui/components/custom-dialog';
import { EventsService } from 'src/services/events';
import { IEvent } from 'src/models/event/event';
import { ReactComponent as AssignSVG } from '../../../assets/images/Plus.svg';
import TableHeadCustom from './TableHeadCustom';
import EventsTableRow from './EventsTableRow';
import CompanyAccountListModal from './CompanyAccountListModal';
import AssignModal from './AssignModal';

const CompanyAccounts = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();

  const [accountFilesData, setAccountFilesData] = useState<any[]>([]);
  const [accountFileList, setAccountFileList] = useState<any[]>([]);
  const [totalRow, setTotalRow] = useState<number>(0);
  const [showCompaniesModal, setShowCompaniesModal] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  // const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [events, setEvents] = useState<IEvent[]>([]);
  const [assignAccount, setAssignAccount] = useState({ open: false, accountId: null });

  const {
    dense,
    page,
    rowsPerPage,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    selected,
    onSelectRow,
    onSelectAllRows,
  } = useTable({ defaultOrderBy: 'startTime', defaultOrder: 'asc', defaultRowsPerPage: 10 });

  const fetchAccountFiles = useCallback(async () => {
    try {
      setIsLoader(true);
      const companyService = new CompanyService();
      const response: any = await companyService.getCompanyAccountFiles(page + 1, rowsPerPage, companyId!);
      setAccountFilesData(response?.data);
      setTotalRow(response.totalRow);
      setIsLoader(false);
    } catch (err) {
      console.error('Failed to fetch account files:', err);
      setIsLoader(false);
    } finally {
      setIsLoader(false);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchAccountFiles();
  }, [fetchAccountFiles]);

  const fetchCompanyEvents = (id: string | null) => {
    if (id) {
      new EventsService().getCompanyEvents(id)
        .then((res) => {
          setEvents(res.data);
        })
        .catch((err) => {
          console.error('Error fetching company events:', err);
        });
    } else {
      console.error('No companyId found to fetch events');
    }
  };

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'accounts', label: 'Accounts', align: 'left' },
    { id: 'owner', label: 'Owner', align: 'left' },
    { id: 'lastUpdate', label: 'Last Update', align: 'left' },
    { id: 'assignedAccounts', label: 'Assigned Events', align: 'left' },
    { id: '', label: '', align: 'left' },
  ];

  const showCompaniesAccountsList = (id: string) => {
    if (id) {
      navigate(`/wall/company/${companyId}/accounts/${id}`);
    }
  };

  const companyAccountDelete = async (id: string) => {
    try {
      const companyService = new CompanyService();
      const response: any = await companyService.companyAccountDelete(id);
      if (response?.message === 'SUCCESS') {
        fetchAccountFiles();
      }
    } catch (err) {
      console.error('Failed to fetch account files:', err);
    }
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleAssignClick = (id: any) => {
    setAssignAccount({ open: true, accountId: null });
    fetchCompanyEvents(id);
  };

  const onCloseAssignModal = () => {
    setAssignAccount({ open: false, accountId: null });
    onSelectAllRows(false, []);
  };

  const isSelected = selected?.map((item: any) => item.id);

  return !isLoader ? (
    <>
      <Helmet>
        <title> Company Accounts | Panelist </title>
      </Helmet>

      <Stack sx={{ width: '100%', position: 'relative' }}>
        {isLoader && (
        <div className="absolute left-
        0 top-0 w-full h-[calc(100%--26px)] bg-gray-1 bg-opacity-80 flex items-center justify-center z-20 rounded-20 -mt-[14px]"
        >
          <Loader className="pt-5" key="loader" />
        </div>
        )}
        {accountFilesData && accountFilesData.length > 0 ? (
          <>
            <TableContainer
              sx={{
                position: 'relative',
                overflowX: 'clip',
                borderRadius: '10px',
                border: '1px solid #DEDEDE',
                backgroundColor: '#ffffff',
                // maxHeight: 'calc(100vh - 250px)',
              }}
            >
              <TableSelectedAction
                sx={{
                  position: 'relative !important',
                  height: 'full',
                  pl: '2px',
                  pr: 1
                }}
                dense={dense}
                rowCount={accountFilesData?.length}
                numSelected={isSelected?.length}
                onSelectAllRows={(checked) => onSelectAllRows(
                  checked,
                  accountFilesData?.map((row) => row.id),
                )}
                addToAssign={(
                  <Tooltip title="Assign">
                    <Button
                      onClick={() => handleAssignClick(companyId)}
                      sx={{
                        textTransform: 'capitalize', fontFamily: 'Poppins-500', fontSize: '12px', display: 'flex', flexDirection: 'row', gap: '5px'
                      }}
                    >
                      Assign
                      <IconButton sx={{ p: 0 }} color="primary" onClick={() => handleAssignClick(companyId)}>
                        <AssignSVG />
                      </IconButton>
                    </Button>
                  </Tooltip>
              )}
              />
              {/* <Scrollbar> */}
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  headLabel={TABLE_HEAD}
                  rowCount={accountFilesData?.length}
                  numSelected={isSelected?.length}
                  onSelectAllRows={(checked) => onSelectAllRows(
                    checked,
                    accountFilesData?.map((row) => row),
                  )}
                  sx={{ borderBottom: '1px solid', borderColor: '#DEDEDE', backgroundColor: '#f4f6f8' }}
                />

                <TableBody>

                  {accountFilesData.map((rowData, index) => (
                    <EventsTableRow
                      setIsLoader={setIsLoader}
                      fetchAccountFiles={fetchAccountFiles}
                      accountData={rowData}
                      index={index}
                      onCompanyClick={showCompaniesAccountsList}
                      onAccountDelete={companyAccountDelete}
                      onSelectRow={() => onSelectRow(rowData)}
                      selected={isSelected.includes(rowData.id)}
                    />
                  ))}
                </TableBody>
              </Table>
              {/* </Scrollbar> */}
            </TableContainer>
            <TablePaginationCustom
              count={totalRow}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              dense={dense}
              onChangeDense={onChangeDense}
            />
            <AlertModal
              className="w-[632px] !py-0 bg-[#ffffff]"
              closeIconClass="top-[18px] right-[11px]"
              isCloseIcon
              isOpen={showCompaniesModal}
              onClose={() => { setShowCompaniesModal(false); setAccountFileList([]); }}
            >
              <CompanyAccountListModal companyList={accountFileList} />
            </AlertModal>
            <ConfirmDialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              title="Remove Company(s)"
              content={(
                <>
                  Are you sure want to remove
                  {' '}
                  <strong>{isSelected.length}</strong>
                  {' '}
                  Company(s)
                  {' '}
                  from this event?
                </>
        )}
              action={(
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    handleCloseConfirm();
                  }}
                >
                  Remove
                </Button>
        )}
            />
            {/* Assign Account Modal */}
            {assignAccount?.open && (
              <AssignModal
                isOpen={assignAccount?.open}
                onClose={onCloseAssignModal}
                fetchAccountFiles={fetchAccountFiles}
                events={events}
                selected={selected}
              />
            )}
          </>
        ) : (
          <NoEvents />
        )}
      </Stack>
    </>
  ) : (
    <Loader isLoaderLogo className="h-[100vh]" key="loader" />
  );
};

export default CompanyAccounts;
