export const AdsSvgIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
    <g id="ad-svgrepo-com" transform="translate(-2 -4)">
      <path id="Path_27137" data-name="Path 27137" d="M5,5H19a2,2,0,0,1,2,2V17a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V7A2,2,0,0,1,5,5Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Path_27138" data-name="Path 27138" d="M7,15V11A2,2,0,0,1,9,9H9a2,2,0,0,1,2,2v4" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Path_27139" data-name="Path 27139" d="M17,9v6" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Path_27140" data-name="Path 27140" d="M17,12H15.5a1.5,1.5,0,0,0,0,3H17" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Path_27141" data-name="Path 27141" d="M7,13h4" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg>
  };