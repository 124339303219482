/* eslint-disable no-unused-vars */
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { Button } from '../../../shared-components/V2';

interface Props {
  submitRef: any;
  closeModal: () => void;
  isCompanyUpdate: boolean;
  isValid?: boolean,
  onStay: () => void;
  onSave: () => void;
}

// eslint-disable-next-line no-unused-vars
export const ComapnyVerifyChanges = ({
  submitRef, closeModal, isCompanyUpdate, isValid, onStay, onSave
}: Props) => (
  <div className="w-full px-[20px] pb-[17px] pt-[17px]">
    <div className="text-[#0B1221] px-3 border-b-1 border-[#CDDCF2] text-center text-[16px] font-medium leading-[22px] pb-2">
      Unsaved Changes
    </div>
    <div className="flex justify-start h-[23pxpx] mt-3 mb-[18px]">
      <div className="text-sm text-[#0B1221] font-light leading-[24px] w-full">
        You have unsaved changes on this page. If you navigate away, your changes will be lost
      </div>
    </div>
    <div className="flex flex-row justify-end">
      <Button
        type="button"
        // onClick={closeModal}
        onClick={onStay}
        text="Cancel"
        className="rounded-[5px] inline-block text-sm font-semibold tracking-wide h-[30px] px-6 py-1.5 bg-[#EDF2F5] text-[#203C6E] w-28 mr-2 hover:bg-[#CEDCF2]"
      />
      <LoadingButton
        type="submit"
        variant="contained"
        loading={isCompanyUpdate}
        onClick={onSave}
        sx={{
          height: '30px', color: 'white', borderRadius: '5px', backgroundColor: '#0049EF', px: '24px', py: '6px', boxShadow: 'none'
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: '13px', lineHeight: '30px', fontFamily: 'Poppins-600', textTransform: 'capitalize'
          }}
        >
          Save &amp; Continue
        </Typography>
      </LoadingButton>
    </div>
  </div>
);
export default ComapnyVerifyChanges;
