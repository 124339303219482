import React, { useEffect, useState } from 'react';
import SearchIcon from '../../../assets/images/event-landing-pre-event/Icon-feather-search.svg';
import { ReactComponent as Clock } from '../../../assets/images/clock.svg';
import { DataTable } from '../../../shared-components/DataTable';
import { UserService } from '../../../services';

export const TransactionPurchaseHistory = () => {
  const [filteredData, setFilteredData] = React.useState<any[]>([]);
  const [searchText, setSearchText] = React.useState<string>('');
  const [transactions, setTransactions] = React.useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState<number>(0);
  const PAGE_SIZE = 10;
  const headerData = [
    {
      name: 'paymentAt',
      label: 'DATE',
      icon: Clock,
      isSearchable: false,
      sortable: true,
      canSortASC: false,
      canSortDESC: true,
      width: '15%'
    },
    {
      name: 'chargeId',
      label: 'Description',
      icon: null,
      isSearchable: true,
      sortable: true,
      canSortASC: true,
      canSortDESC: true,
      width: '40%'
    },
    {
      name: 'paymentType',
      label: 'By',
      icon: null,
      isSearchable: true,
      sortable: true,
      canSortASC: true,
      canSortDESC: true,
      width: '15%px'
    },
    {
      name: 'amount',
      label: 'Total',
      icon: null,
      isSearchable: true,
      sortable: true,
      canSortASC: true,
      canSortDESC: true,
      width: '15%'
    },
    {
      name: 'status',
      label: 'Reciept',
      icon: null,
      isSearchable: false,
      sortable: false,
      canSortASC: true,
      canSortDESC: false,
      width: '15%'
    }
  ];

  useEffect(() => {
    (async () => {
      const userService = new UserService();
      const result = await userService.getTransactions(PAGE_SIZE, currentPage);
      setTotalRow(result.totalRow);
      setTransactions(result.data);
      setFilteredData(result.data);
    })();
  }, [currentPage]);

  useEffect(() => {
    function getFilteredArray() {
      if (searchText.length === 0) {
        return transactions;
      }

      return transactions.filter((item: any) => item.amount.toLowerCase().includes(searchText.toLowerCase()));
    }

    if (transactions) {
      setFilteredData(getFilteredArray());
    }
  }, [transactions, searchText]);

  const onChangePageHandler = (page: number) => {
    setCurrentPage(page);
  };

  const onNextClickHandler = () => {
    setCurrentPage(currentPage + 1);
  };

  const onBackClickHandler = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <div className="max-w-[886.5px] ml-[40px] mt-[20px]" id="transaction-history">
      <div className="flex flex-col w-full">
        <span className="font-semibold text-[15px] leading-[21px] mb-5 text-[#0B1221]">Transaction and Purchase History</span>
        <div className="border-t border-[#707070] w-full pt-[20px]">
          <div className="bg-white p-4 rounded-10">
            <div className="flex flex-row w-full pb-2.5 ">
              <div
                className="border-b border-[#CDDCF2] px-2 pl-1 w-full z-10 relative pb-[11px]"
              >
                <img className="absolute top-[5px]" src={SearchIcon} alt="" />
                <input
                  type="text"
                  className="pl-6 pr-2 w-full text-[15px] font-light text-[#3C3C3C] placeholder:text-[#3C3C3C] focus-visible:outline-none"
                  placeholder="Search for keywords"
                  onChange={e => setSearchText(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
            <DataTable
              rows={[...filteredData]}
              headers={[...headerData]}
              onPageChange={onChangePageHandler}
              onNextClick={onNextClickHandler}
              onBackClick={onBackClickHandler}
              totalRow={totalRow}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionPurchaseHistory;
