import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginOrJoinForm from '../../../loginorjoin/loginorjoin-form';
import WebsiteEventQuickLinks from '../website-event-quicklinks';

export const UserLoginOrJoinForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [backStep, setBackStep] = useState(true);

  const eventSlug = location?.pathname?.split('/')[2];

  useEffect(() => {
    if (!backStep) navigate(`/event/${eventSlug}/spectre/vipreg`);
  }, [backStep, eventSlug, navigate]);

  const changePage = (page: number) => {
    if (page === 1) {
      navigate(`/event/${eventSlug}/spectre/login`);
    }
    if (page === 2) {
      navigate(`/event/${eventSlug}/spectre/signup`);
    }
  };

  const onBackEvent = () => {
    navigate(`/event/${eventSlug}/spectre`);
  };
  return (
    <div className="w-full">
      <div className="flex -mb-[30px] h-[100vh]">
        <div className="bg-[#2d2d2d] pt-[20px] pr-[40px] pb-[30px] pl-[40px] w-full md:max-w-[60%] flex items-center">
          <div className="w-full">
            <div
              className="w-[122px] h-[32px] rounded-full bg-white text-black text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer mb-[40px]"
              onClick={onBackEvent}
            >
              Back to event
            </div>
            <div className="mt-[100px]">
              <div className="text-white text-center text-[25px] leading-[30px] helvetica-neue-65-medium mb-[40px]">Login or Join Now</div>
              <LoginOrJoinForm isEventRegister from="event" isSpectre isSpectreForm changePage={changePage} setShowModal={setBackStep} />
            </div>
          </div>
        </div>
        <div className="reg-bg md:w-[40%]" />
      </div>
      <WebsiteEventQuickLinks />
    </div>
  );
};
export default UserLoginOrJoinForm;
