import React from "react";
import { IProfile } from "../../../models/profile";
import classNames from "classnames";
import { Avatar } from "../Avatar";
interface User {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
}

export interface IGroupUserIconsProps {
  users: User[] | IProfile[] | undefined;
  baseUrl?: string;
  className?: string;
  containerClass?: string;
  userQty?: number;
  avtarQty?: number;
}

export const GroupUserIcons = ({
  users,
  baseUrl,
  className,
  containerClass,
  userQty,
  avtarQty,
}: IGroupUserIconsProps) => {
  const getAttendeesHeader = () => {
    let _names: string = "";
    const firstThreeAttendees =
      users && users.slice(0, userQty).map((user) => `${user.firstName}`);
    _names = firstThreeAttendees!.join(", ");

    const usersLength = users && users!.length;

    if (usersLength === userQty) {
      return <>{_names}</>;
    } else {
      return (
        <div className="text-[10px] leading-[15px] font-normal text-[#000000]">
          {_names}{" "}
          {usersLength! > userQty!
            ? ` and ${usersLength! - userQty!} others`
            : ""}
        </div>
      );
    }
  };
  const avtarQuantity = avtarQty ? avtarQty : 3;
  return (
    <div 
      className={classNames(
        "flex flex-row items-center",
        {
          [`${containerClass}`]: containerClass,
        }
      )}
    >
      <div className="flex flex-row items-center h-[18px] -space-x-2">
        {users &&
          users.slice(0, avtarQuantity).map(
            (user, key) =>
              user && (
                <div
                  title={`${user.firstName} ${user.lastName}`}
                  key={`Group-user-icons-${key}`}
                >
                  <Avatar
                    src={baseUrl ? `${baseUrl}/${user.avatar}` : user.avatar}
                    size="XXSMALL"
                    alt={`${user.firstName} ${user.lastName}`}
                    className="border-white border-1 rounded-full"
                  />
                </div>
              )
          )}
      </div>
      <div className="flex items-center flex-row px-2">
        <div
          className={classNames(
            "cursor-pointer hover:text-blue-900 hover:underline flex",
            {
              [" text-[10px] leading-[15px] font-normal text-[#000000]"]: !className,
              [`${className}`]: className,
            }
          )}
          aria-label="user-target"
        >
          {getAttendeesHeader()}
        </div>
      </div>
    </div>
  );
};
