import classNames from 'classnames';
import {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';
import { useOnClickOutside } from 'usehooks-ts';
import { IAppState } from 'src/store';
import { useCurrentUser } from 'src/utils/hooks';
import SurveyForm from './SurveyForm';

const DefaultSurveyButton = (props: { isBanner?: boolean}) => {
  const ref = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const survey = useSelector(
    (state: IAppState) => state.website.survey
  );
  const registered = useSelector(
    (state: IAppState) => state.website.registered
  );
  const upcomingEvent = useSelector(
    (state: IAppState) => state.upcomingEvent
  );

  const loggedInUser = useCurrentUser();
  const [user, setUser] = useState<any>();
  const audienceStatus = upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus;

  useEffect(() => {
    setUser(loggedInUser);
  }, [loggedInUser]);

  const handleRegistration = () => {
    setShowModal(true);
  };

  const handleClickOutside = () => {
    setShowModal(true);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <>
      <button
        type="button"
        className={classNames('bg-[#0049EF] md:px-[18px] px-[12px] h-[29px] rounded-[5px] text-[12px] leading-[18px] text-white tracking-wide mobile-nav-btn', {
          'text-[16px] h-[34px] rounded-[5px] !px-[45px] hover:opacity-90 mobile-nav-banner-btn': props.isBanner,
          'rounded-[6px] w-[220px] h-[36px] text-[16px] hover:bg-opacity-30 helvetica-neue-65-medium': survey?.websiteTemplate === 'spectre',
          'rounded-[5px] font-semibold': survey?.websiteTemplate !== 'spectre',
        })}
        onClick={() => handleRegistration()}
        style={{
          background: survey?.websiteTemplate === 'spectre' ? 'white' : survey?.colorStyle?.values?.backgroundButton,
          color: survey?.websiteTemplate === 'spectre' ? 'black' : survey?.colorStyle?.values?.colorButton,
        }}
      >
        Complete Survey
      </button>

      {showModal && (
        <SurveyForm
          eventId={survey?.id!}
          user={user}
          open={showModal}
          registered={registered!}
          eventInvitationStatus={audienceStatus!}
          eventType={survey?.type!}
          setOpenForm={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default DefaultSurveyButton;
