/* eslint-disable */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import EventsLiveLayout from '../../../layouts/events-live-layout';
import { IEvent } from '../../../models/event/event';
import { IAttendee } from '../../../models/events-live/attendee';
import { IEventResource } from '../../../models/events-live/resources';
import { Loader } from '../../../shared-components/Loader';
import { IAppState } from '../../../store';
import { InteractiveAttendeeJoin } from '../../../store/interactive-event/attendee/actions';
import { InteractiveModeratorCallJoin, InteractiveModeratorStartBroadCast } from '../../../store/interactive-event/moderator/actions';
import { init, resetLiveEvent, updateCurrentSessionInformation } from '../../../store/liveEvent';
import { unsetInteractiveEvent } from '../../../store/interactive-event';
import { useCurrentUser, useExpoWebSocket } from '../../../utils/hooks';
import AdsAndResources from '../ads-and-resources';
import Agenda from '../agenda';
import Chat from '../chat';
import EventPartners from '../event-partners';
import { Hero } from './hero';
import { InteractiveModeratorSpeakerActiveList, InteractiveModeratorSpeakerQueueList, InteractiveModeratorSpeakerUserExit } from '../../../store/interactive-event/moderator-speaker';
import { InteractiveSpeakerJoin } from '../../../store/interactive-event/speaker/actions';
import { Participants, QueueParticipants } from '../../../store/interactive-event/types';
import { MiniSpotlight } from '../../spotlight/mini-spotlight';
import { getCompanyName } from 'src/utils';

export const InteractiveEvent = () => {
  const { slug, roomId, role } = useParams();
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const { eventModerators } = useSelector((state: IAppState) => state.liveEvent);
  const interactiveEvent = useSelector((state: IAppState) => state.interactiveEvent);
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const expoWebsocket = useExpoWebSocket();
  const navigate = useNavigate();

  const [event, setEvent] = useState<IEvent>();
  const [queue, setQueue] = useState<QueueParticipants>();
  const [attendees, setAttendees] = useState<IAttendee[]>([]);
  const [resources, setResources] = useState<IEventResource[]>([]);
  const [ads, setAds] = useState<any[]>([]);
  const [stage, setStage] = useState<Participants>();

  /**
   * Redirect user to live page if role doesn't match
   * Role: 'session' | 'speaker' | 'moderator'
   */
  useEffect(() => {
    if (event?.rooms?.length! > 0) {
      const rooms: any = {};
      const currentUserModerator: any = eventModerators.map(event => event?.user).find(mod => mod.id === user.id)
      const currentUserSpeaker: any = liveEvent.speakers.find(speaker => speaker.id === user.id);

      event?.rooms.map(room => room.sessions.map(session => session.eventSpeakers.map((speaker: any) => {
        if (speaker.userId === user?.id) {
          rooms[room.id] = room;
        }
        return speaker;
      })));
      if (!!currentUserModerator) {
        if (role !== 'moderator') {
          navigate(`/${getCompanyName(event)}/${slug}/live`);
        }
      } else if (!!currentUserSpeaker) {
        if (role !== 'speaker') {
          navigate(`/${getCompanyName(event)}/${slug}/live`);
        }
      } else if (role !== 'session') {
        navigate(`/${getCompanyName(event)}/${slug}/live`);
      }
    }
  }, [event, eventModerators]);

  useEffect(() => {
    if (slug) dispatch(init(slug));
    /**
     * Cleanup
     */
    return () => {
      dispatch(unsetInteractiveEvent())
      dispatch(resetLiveEvent());
      if (role === 'speaker' || role === 'moderator' || role === 'session') {
        dispatch(InteractiveModeratorSpeakerUserExit(slug!, roomId!, user?.id!));
      }
      dispatch(unsetInteractiveEvent());
    };
  }, [slug, roomId, dispatch]);

  useEffect(() => {
    // If event is live we keep updating the current session information.
    const timerId = setInterval(() => {
      if (liveEvent?.isLive) {
        dispatch(updateCurrentSessionInformation());
      } else if (!liveEvent?.isLive) {
        clearInterval(timerId);
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [dispatch, liveEvent?.isLive]);

  /**
   * Initialize WebSocket based on role
   */
  useEffect(() => {
    if (slug && roomId) {
      if (role === "moderator") dispatch(InteractiveModeratorCallJoin(slug, roomId));
      if (role === "moderator") dispatch(InteractiveModeratorStartBroadCast(slug!, roomId!));
      if (role === "speaker") dispatch(InteractiveSpeakerJoin(slug, roomId));
      if (role === "session") dispatch(InteractiveAttendeeJoin(slug, roomId));

      dispatch(InteractiveModeratorSpeakerActiveList(slug, roomId))

      if (["speaker", "moderator"].includes(role!)) dispatch(InteractiveModeratorSpeakerQueueList(slug, roomId));
    }
  }, [slug, roomId]);


  useEffect(() => {
    const { participants } = interactiveEvent;
    if (!!participants) {
      const {
        event, attendees, resources, ads, speakers
      } = liveEvent;

      if (event?.rooms?.[0]?.isInteractive) {
        setStage(participants);
      } else {
        setStage({
          speakers: speakers, 
          moderators: [],
          virtualSpeakers: [],
          guests: [],
        });
      }

      setAttendees(attendees);
      setResources(resources);
      setAds(ads);
      if (interactiveEvent.queue) setQueue(interactiveEvent.queue)
      setEvent(event);
    }
    if (!!interactiveEvent.redirect?.length) {
      navigate(interactiveEvent.redirect);
    }
  }, [liveEvent, interactiveEvent]);

  // if (!event || !user) return null;
  return (
    <>
      {
        !event ? <Loader className="h-[100vh]" key="loader" isLoaderLogo /> : <EventsLiveLayout
          hero={<Hero event={event} stage={stage!} attendees={attendees} queue={queue!} />}
          leftPanel={(
            <Agenda
              rooms={event?.rooms.find((i: any) => i.id)!}
            />
          )}
          rightPanel={<>
            <div className="h-full w-full">
              <AdsAndResources ads={ads} />
              <MiniSpotlight companyId={event?.companyId} slug={event?.company?.slug} eventId={event?.id} />
            </div>
          </>}
          footer={<EventPartners className="w-1/1" eventPartners={event?.eventSponsors!} />}
        >
          <Chat event={event} attendees={attendees} rooms={event?.rooms!} user={user} id={event?.id!} />

        </EventsLiveLayout>
      }
    </>
  );
};
