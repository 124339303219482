import CommunityContentTwo from './community-content-two';
import CommunityContentOne from './community-content-one';

const CommunityTerms = () => (
  <div className="bg-gray-0 w-full">
    <div className="block mb-8 w-9/12 mx-auto text-md2 font-normal text-gray-1">
      <div className="font-normal text-gray-1 text-md2">
        <CommunityContentOne />
        <CommunityContentTwo />
      </div>
    </div>
  </div>
);

export default CommunityTerms;
