import naplanlogo from '../../../assets/images/overview/naplan-logo.svg';
import talendlogo from '../../../assets/images/overview/talend-logo.svg';
import sitdownlogo from '../../../assets/images/overview/sitdown-logo.svg';
import sugarcrmlogo from '../../../assets/images/overview/sugarcrm-logo.svg';

export const PanelistUsers = () => (
  <div className="2xl:container 2xl:mx-auto">
    <div className="w-full">
      <div className="w-10/12 block md:flex flex-col md:ml-36 md:pl-1">
        <div className="md:mt-11 ">
          <p className="lg:text-xl5 font-medium text-xl text-blue-4 md:mt-6w-2/3">
            Some organisations using Panelist Event Manager
          </p>
        </div>
        <div className="inline-flex md:flex mt-14 pt-1 mb-24 justify-center">
          <div className="mr-4 lg:mr-0 w-2/4 md:w-1/4">
            <img src={naplanlogo} alt="" />
          </div>
          <div className="mr-4 -mt-1 lg:mr-0 w-2/4 md:w-1/4">
            <img src={talendlogo} alt="" />
          </div>
          <div className="mr-4 lg:mr-0 w-2/4 md:w-1/4">
            <img src={sitdownlogo} alt="" />
          </div>
          <div className="mr-4 lg:mr-0 w-2/4 md:w-1/4">
            <img src={sugarcrmlogo} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PanelistUsers;
