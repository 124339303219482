export const UserWorkIcon = ( ) => (<svg xmlns="http://www.w3.org/2000/svg" width="22.944" height="21.498" viewBox="0 0 22.944 21.498">
<g id="Group_21029" data-name="Group 21029" transform="translate(-8478 -5376.5)">
  <g id="Group_18805" data-name="Group 18805" transform="translate(8478 5377)">
    <rect id="Rectangle_7907" data-name="Rectangle 7907" width="12.132" height="13.345" rx="1" transform="translate(0 1.213)" fill="#7283a4"/>
    <circle id="Ellipse_1160" data-name="Ellipse 1160" cx="7.886" cy="7.886" r="7.886" transform="translate(6.672 0)" fill="#7283a4" stroke="#edf2f5" stroke-width="1"/>
    <path id="Path_14806" data-name="Path 14806" d="M1.755,4.5A.547.547,0,0,0,2.2,4.263a5.046,5.046,0,0,1,8.218,0,.549.549,0,0,0,.446.238h1.2a.555.555,0,0,0,.483-.821A7.126,7.126,0,0,0,.068,3.686.552.552,0,0,0,.554,4.5Z" transform="translate(8.252 16.498)" fill="#7283a4"/>
  </g>
</g>
</svg>

);

