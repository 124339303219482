import bulb from '../../../assets/images/compliance/bulb-icon.png';

const HostTermsIntro = () => (
  <div className="bg-gray-0 w-full">
    <div className="block mb-8 w-9/12 mx-auto">
      <div className="block lg:flex flex-col  mx-auto mt-7 text-dark-blue text-md">
        <div className="text-blue-1">Last updated 9 April 2021</div>
        <div className="mt-5">
          These terms and conditions
          {' '}
          <span className="font-medium">(“Terms”, “Agreement”)</span>
          {' '}
          are
          an agreement between Panelist Pty Limited
          {' '}
          <span className="font-medium">(“Panelist”, “us”, “we” or “our”)</span>
          {' '}
          and you, the
          organiser of
          <span className="font-medium">EVENTS (“Host”, “you” or “your”)</span>
          .
        </div>
      </div>
      <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
        <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
        <div className="">
          These User Terms set out how you use, and we use, Panelist, in its varying online forms.
        </div>
      </div>
      <div className="mt-7">
        This Agreement sets forth the general terms and conditions of you, as the Host of
        {' '}
        <span className="font-medium">events</span>
        {' '}
        on
        the Panelist website and any of its its properties “off platform” or through mobile
        applications, webpages, programming interfaces and subdomains (collectively, “
        <span className="font-medium">the Platform</span>
        ”).
      </div>
      <div className="mt-7">
        The material, including without limitation, information, data text editorial content, design
        elements, look and fee, formatting, graphics, images, photographs, videos, music, sounds and
        other contained on the Platform or made otherwise by Panelist will be referred to as “the
        Content”. Any material that you contribute, provide, post or make available using the
        Platform is referred to as “Your Content”.
      </div>
      <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
        <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
        <div className="">
          When reading these User Terms, here are some important terms and understandings for you to
          be aware of when referring to ‘Content’.
          {' '}
        </div>
      </div>
    </div>
  </div>
);

export default HostTermsIntro;
