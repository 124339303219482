export interface ImageEditSvgIconProps {
    fillColor?: string;
}
export const ImageEditSvgIcon = ({ fillColor }: ImageEditSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#bdcce8"
    return <svg xmlns="http://www.w3.org/2000/svg" width="34.355" height="22.767" viewBox="0 0 34.355 22.767">
    <g id="Group_21334" data-name="Group 21334" transform="translate(-81.592 -63)">
      <path id="Subtraction_6" data-name="Subtraction 6" d="M-1787.594-70.556h-2.645V-76.4a4.244,4.244,0,0,0-4.232-4.232h-12.167a4.245,4.245,0,0,0-1.363.224,4.233,4.233,0,0,1,4.008-2.869h12.167a4.243,4.243,0,0,1,4.232,4.232v8.49Z" transform="translate(1895.18 146.278)" fill="#7280a4"/>
      <path id="Subtraction_7" data-name="Subtraction 7" d="M-1794-64.651h-9a5.006,5.006,0,0,1-5-5v-8.626a5.006,5.006,0,0,1,5-5h10.586a5.006,5.006,0,0,1,5,5v8.626a5.046,5.046,0,0,1-.091.956H-1794v4.044Z" transform="translate(1891 150.418)"/>
      <g id="Group_19780" data-name="Group 19780" transform="translate(83 63)">
        <g id="Group_18901" data-name="Group 18901" transform="translate(0 13.189)">
          <line id="Line_1335" data-name="Line 1335" y1="4.792" x2="3.956" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
          <line id="Line_1336" data-name="Line 1336" x1="3.956" y1="4.792" transform="translate(4.312)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
          <line id="Line_1337" data-name="Line 1337" y1="2.995" x2="2.826" transform="translate(8.574 1.663)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
          <line id="Line_1338" data-name="Line 1338" x1="3.391" y1="3.594" transform="translate(11.668 1.636)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
          <line id="Line_1339" data-name="Line 1339" y1="1.797" x2="1.695" transform="translate(15.234 3.393)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
          <line id="Line_1340" data-name="Line 1340" x1="3.391" y1="2.995" transform="translate(17.195 3.326)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
        </g>
      </g>
      <g id="Group_21333" data-name="Group 21333" transform="translate(96.293 65.822)">
        <path id="edit-svgrepo-com" d="M3.99,16.854l-1.314,3.5a.75.75,0,0,0,.966.965l3.5-1.314a3,3,0,0,0,1.068-.687L18.36,9.175a6.078,6.078,0,0,0-1.414-2.122,6.068,6.068,0,0,0-2.122-1.414L4.677,15.786A3,3,0,0,0,3.99,16.854ZM16.239,4.224l1.383-1.383a1.075,1.075,0,0,1,.925-.348,3.543,3.543,0,0,1,1.934,1.025,3.546,3.546,0,0,1,1.025,1.934,1.075,1.075,0,0,1-.348.925L19.774,7.76A6.054,6.054,0,0,0,18.36,5.64a6.047,6.047,0,0,0-2.121-1.415Z" transform="translate(-2.628 -1.426)" fill="#7280a4" fillRule="evenodd"/>
        <path id="edit-svgrepo-com-2" data-name="edit-svgrepo-com" d="M3.99,16.854l-1.314,3.5a.75.75,0,0,0,.966.965l3.5-1.314a3,3,0,0,0,1.068-.687L18.36,9.175a6.078,6.078,0,0,0-1.414-2.122,6.068,6.068,0,0,0-2.122-1.414L4.677,15.786A3,3,0,0,0,3.99,16.854ZM16.239,4.224l1.383-1.383a1.075,1.075,0,0,1,.925-.348,3.543,3.543,0,0,1,1.934,1.025,3.546,3.546,0,0,1,1.025,1.934,1.075,1.075,0,0,1-.348.925L19.774,7.76A6.054,6.054,0,0,0,18.36,5.64a6.047,6.047,0,0,0-2.121-1.415Z" transform="translate(-1.864 -2.481)" fillRule="evenodd"/>
      </g>
    </g>
  </svg>
  
};