import { Reducer } from 'redux';

import { ICompanyCoverPhotoState } from '../../../models/company/CoverPhotoState';
import { EventPostDocumentActions } from './actions';
import { EventPostDocumentActionTypes } from './types';

const initialState: ICompanyCoverPhotoState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const SetPostDocumentReducer: Reducer<ICompanyCoverPhotoState, EventPostDocumentActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case EventPostDocumentActionTypes.SET_POST_DOCUMENT: {
      return {
        ...state,
        value: action.url
      };
    }

    case EventPostDocumentActionTypes.POST_DOCUMENT_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
