import {
  useEffect,
  useState,
} from 'react';

import { UseFormRegister } from 'react-hook-form';
import { SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';

import { IReactSelectOption } from '../../models/react-select-option';

interface MonthSelectorProps {
  onChange: Function;
  register: UseFormRegister<any>;
  selectedMonthStateToStore: IReactSelectOption | undefined;
}

interface Month {
  id: string,
  name: string
}
export const MonthSelector = ({
  onChange,
  register,
  selectedMonthStateToStore,
}: MonthSelectorProps) => {
  const [monthsListState, setMonthsListState] = useState<IReactSelectOption[]>(
    []
  );

  const months: Month[] = [
    { id: '1', name: 'January' },
    { id: '2', name: 'February' },
    { id: '3', name: 'March' },
    { id: '4', name: 'April' },
    { id: '5', name: 'May' },
    { id: '6', name: 'June' },
    { id: '7', name: 'July' },
    { id: '8', name: 'August' },
    { id: '9', name: 'September' },
    { id: '10', name: 'October' },
    { id: '11', name: 'November' },
    { id: '12', name: 'December' },
  ];

  useEffect(() => {
    const monthsList: IReactSelectOption[] = [];
    months.forEach((month) =>
      monthsList.push({
        key: month.id,
        value: month.id,
        label: month.name,
      })
    );
    setMonthsListState(monthsList);
  }, []);

  const onChangeLocal = (event: SingleValue<IReactSelectOption>) => {
    onChange(event);
  };

  return (
    <Creatable
      id='monthSelector'
      placeholder={'Enter a month'}
      className="dropdown-style"
      // menuIsOpen 
      options={monthsListState}
      isClearable={false}
      isSearchable={false}
      openMenuOnClick={true}
      {...register('jobTitle', {
        required: { value: true, message: 'Month is required' },
      })}
      value={selectedMonthStateToStore}
      onChange={(e) => onChangeLocal(e)}
    />
  );
};
