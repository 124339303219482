import { FC, useState } from 'react';
import { CardCompanyAdmins } from '../../../shared-components';
import { imageUrlPrefix } from '../../../utils';
import { closeMessageWindow, openMessageWindow } from '../../../store/newMessage';
import { setConnectSuggestion } from '../../../store/user/connect-suggestions/actions';
import hahaEmoji from '../../../assets/images/post-reactions/haha-emoji.svg';
import likeEmoji from '../../../assets/images/post-reactions/like-emoji.svg';
import loveEmoji from '../../../assets/images/post-reactions/love-emoji.svg';
import smileEmoji from '../../../assets/images/post-reactions/smile-emoji.svg';
import clapEmoji from '../../../assets/images/post-reactions/well-done-emoji.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectionsState } from '../../../models/ConnectionsState';
import { IAppState } from '../../../store';
import classNames from 'classnames';
import PeopleListCard from '../../../screens/search-results/peopleListCard';
import { IUserProfile } from '../../../models/user-profile';
import { useNavigate } from 'react-router-dom';
import { UserService } from '../../../services';


interface IPostLikeReactionsProps {
  totalClaps: number;
  totalHahas: number;
  totalLikes: number;
  totalLoves: number;
  totalSmiles: number;
  totalLikeUsers: any;
  onClickTab: (p: any) => void;
  setIsOpenReactions: Function;
}

export const PostLikeReactions = ({
  totalClaps,
  totalHahas,
  totalLikes,
  totalLoves,
  totalSmiles,
  totalLikeUsers,
  onClickTab,
  setIsOpenReactions
}: IPostLikeReactionsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userService = new UserService();
  const connectsSelector: ConnectionsState = useSelector((state: IAppState) => state.connections);
  const [openTab, setOpenTab] = useState(1);
  const tabNames = [
    [1, 'All'],
    [2, 'Likes'],
    [3, 'Loves'],
    [4, 'Claps'],
    [5, 'Smiles'],
    [6, 'Hahas']
  ];

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full create-post-card">
          <div className="mx-2.5">
            <ul className="flex list-none flex-row bg-white rounded-t-lg pt-[10px] gap-x-[5px]">
              {tabNames.map(([idx, name]) => {
                const isHideLikeButton = (
                  name === 'Likes'  && totalLikes === 0
                  || name === 'Loves' && totalLoves === 0
                  || name === 'Claps' && totalClaps === 0
                  || name === 'Smiles' && totalSmiles === 0
                  || name === 'Hahas' && totalHahas === 0
                );
                const totalLikesQty = totalLikes + totalLoves + totalClaps + totalSmiles + totalHahas;
                return (
                  <li
                    className={classNames('flex text-start cursor-pointer', {
                      hidden: isHideLikeButton,
                    })}
                  >
                    <button
                      className={classNames('md:w-[74px] w-[60px] h-[34px] rounded-[10px]', {
                        'md:w-[74px] w-[60px] h-[34px] bg-[#EDF2F5] rounded-[10px]': openTab === idx,
                        hidden: isHideLikeButton,
                      })}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(idx as number);
                      }}>
                      {(name === 'All' && (
                        <div className="flex items-center justify-center" onClick={() => onClickTab('All')}>
                          <span className="test-[16px] text-[#203C6E] leading-[18px] font-semibold">All</span>
                          <span className="test-[14px] text-[#203C6E] leading-[18px] font-semibold pl-[7px]">{totalLikesQty}</span>
                        </div>
                      ))
                      || (name === 'Likes'  && totalLikes > 0 &&  (
                        <div className="flex items-center justify-center" onClick={() => onClickTab('Likes')}>
                          <span className="max-w-[24px]">{<img className="" src={likeEmoji} alt="" />}</span>
                          <span className="test-[14px] text-[#203C6E] leading-[18px] font-semibold pl-[7px] mt-[1px]">{totalLikes}</span>
                        </div>
                      ))
                      || (name === 'Loves' && totalLoves > 0 && (
                        <div className="flex items-center justify-center" onClick={() => onClickTab('Loves')}>
                          <span className="max-w-[24px]">{<img className="" src={loveEmoji} alt="" />}</span>
                          <span className="test-[14px] text-[#203C6E] leading-[18px] font-semibold pl-[7px] mt-[1px]">{totalLoves}</span>
                        </div>
                      ))
                      || (name === 'Claps' && totalClaps > 0 && (
                        <div className="flex items-center justify-center" onClick={() => onClickTab('Claps')}>
                          <span className="max-w-[24px]">{<img className="" src={clapEmoji} alt="" />}</span>
                          <span className="test-[14px] text-[#203C6E] leading-[18px] font-semibold pl-[7px] mt-[1px]">{totalClaps}</span>
                        </div>
                      ))
                      || (name === 'Smiles' && totalSmiles > 0 && (
                        <div className="flex items-center justify-center" onClick={() => onClickTab('Smiles')}>
                          <span className="max-w-[24px]">{<img className="" src={smileEmoji} alt="" />}</span>
                          <span className="test-[14px] text-[#203C6E] leading-[18px] font-semibold pl-[7px] mt-[1px]">{totalSmiles}</span>
                        </div>
                      ))
                      || (name === 'Hahas' && totalHahas > 0 && (
                        <div className="flex items-center justify-center" onClick={() => onClickTab('Hahas')}>
                          <span className="max-w-[24px]">{<img className="" src={hahaEmoji} alt="" />}</span>
                          <span className="test-[14px] text-[#203C6E] leading-[18px] font-semibold pl-[7px] mt-[1px]">{totalHahas}</span>
                        </div>
                      ))}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded-b-[15px]">
            <div className="px-[24px] pb-3 mb-3 overflow-x-auto h-[490px] no-scrollbar">
              <div className="">
                <div>
                  <div className="w-full h-[490px] overflow-auto">
                    <PeopleListCard
                      onClick={(user: IUserProfile) => {
                        navigate(`/wall/${user.slug}`);
                      }}
                      people={totalLikeUsers}
                      onConnect={async (userId: string) => {
                        await userService.sendConnectionRequest({
                          connectionUserId: userId
                        });
                      }}
                      onMessage={async (user: IUserProfile) => {
                        await dispatch(closeMessageWindow());
                        await dispatch(
                          openMessageWindow({
                            avatar: user.avatar!,
                            userDisplayName: `${user.firstName} ${user.lastName}`,
                            userId: user.id
                          })
                        );
                        setIsOpenReactions(false)
                      }}
                      key="user-connections"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b"></div>
      </div>
    </>
  );
};

export default PostLikeReactions;


