import { IAppState, store } from '../../store';
import { emptyFailedMessages, handleMessage } from '../../store/privateChat';
import { handleExpoMessage } from '../../store/expo';
import { getAuthToken } from '../';
import { useCurrentUser } from './';
import { handleVideoCall } from '../../store/1-1-video-call';
import { handleInteractiveEvent } from '../../store/interactive-event';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setJoinMeeting } from "src/store/liveEvent";

const janusTokenName = process.env.REACT_APP_JANUS_TOKEN as string;

const privateChatUrl = (userId: string, janusToken: string) =>
  process.env.REACT_APP_PRIVATE_CHAT_URL +
  `?connectionType=PRIVATECHAT&connectionUsername=${userId}&Authorizer=${janusToken}`;

const handler = (event: MessageEvent<any>) => {
  const message = JSON.parse(event.data);
  console.log('message11111111111', message)

  store.dispatch(handleMessage(message));

  // handling video call in a separate redux state.
  store.dispatch(handleVideoCall(message));

  if (
    message.action === "notification" &&
    message?.data?.instance?.actionMessage?.toLowerCase()?.trim() ===
      "join meeting"
  ) {
    const notificationMessage = message?.data?.instance?.message;
    const cond: string[] = notificationMessage?.match(/@{{(.*?)}}/g) ?? [];
    const condLinks: string[] = cond.map((c) => c.replace(/@{{|}}/g, ""));
    let meetingId;
    const compiled: any[] = condLinks.map((cL) => {
      const parseList = cL.split("||");
      if (parseList?.[0] === "EventSessionMeeting") {
        if (parseList?.[2]?.toLocaleLowerCase().includes("join")) {
          meetingId = parseList?.[1];
        }
      }
    });
    store.dispatch(setJoinMeeting({ meetingId, isOpen: true }));
  }
};

const expoHandler = (event: MessageEvent<any>) => {
  const message = JSON.parse(event.data);
  // const navigate = useNavigate();

  // if (message.action === 'expo-call') {
  //   toast.success('Incoming call');
  //   navigate(`/event/${slug}/calling`);
  // }

  store.dispatch(handleExpoMessage(message));
  store.dispatch(handleInteractiveEvent(message));
  // Handling chime funcitons here, Or handling on useEffect of commponent?
};

export let _webSocket: Promise<WebSocket>;
export const useWebSocket = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const failedMessages = useSelector(
    (state: IAppState) => state.privateChat.failedMessages
  );
  const [connection, refreshConnection] = useState({});
  useEffect(() => {}, [connection]);

  if (!_webSocket) {
    _webSocket = new Promise(async (resolve, reject) => {
      const token = getAuthToken(janusTokenName);
      if (!user || !token)
        reject(
          `insufficient authentication - authToken='${!!token}' user='${!!user}'`
        );
      const url = privateChatUrl(user.id, token!);
      const ws = new WebSocket(url && url);
      let clear: NodeJS.Timeout;
      // set ping-pong interval to makes Websocket Alive.
      ws.onopen = () => {
        if (failedMessages?.length) {
          for (const message of failedMessages) {
            ws.send(message);
          }
          dispatch(emptyFailedMessages());
        }

        clear = setInterval(() => {
          ws.send('{"action": "ping","data": { "data": "PONG"}}');
        }, parseInt(process.env.REACT_APP_WEBSOCKET_PING_INTERVAL || "30000"));
        resolve(ws);
      };
      ws.onmessage = handler;
      // Remove the Interval when websocket closed.
      ws.onclose = () => {
        clearInterval(clear);
        _webSocket = undefined!;
      };
    });
  }
  return { webSocket: _webSocket, refreshConnection };
};

export const expoUrl = (userId: string, token: string) =>
  process.env.REACT_APP_EXPO_MEETING_AWS +
  `?connectionType=PRIVATECHAT&connectionUsername=${userId}&auth=${token}`;

export let _expoWebSocket: Promise<WebSocket>;

export const useExpoWebSocket = () => {
  const user = useCurrentUser();
  if (!_expoWebSocket) {
    _expoWebSocket = new Promise(async (resolve, reject) => {
      const token = getAuthToken();
      if (!user || !token) {
        reject(
          `insufficient authentication - authToken='${!!token}' user='${!!user}'`
        );
      }
      const url = expoUrl(user.id, token!);
      const ws = new WebSocket(url && url);
      let clear: NodeJS.Timeout;
      // set ping-pong interval to makes Websocket Alive.
      ws.onopen = () => {
        clear = setInterval(() => {
          ws.send('{"action": "ping","data": { "data": "PONG"}}');
        }, parseInt(process.env.REACT_APP_WEBSOCKET_PING_INTERVAL || "30000"));
        resolve(ws);
      };
      ws.onmessage = expoHandler;
      // Remove the Interval when websocket closed.
      ws.onclose = () => {
        clearInterval(clear);
        _webSocket = undefined!;
      };
    });
  }
  return { expoWebSocket: _expoWebSocket };
};
