import { useEffect, useRef, useState } from "react";

import Picker from "emoji-picker-react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import documentIcon from "../../assets/images/attached-document.svg";
import attachmentIcon from "../../assets/images/nav-menu/attachment-icon-gray.svg";
import emojiIcon from "../../assets/images/nav-menu/emoji-icon-gray.svg";
import deleteIcon from "../../assets/images/remove-attachment.svg";
import { TextInput } from "../../shared-components";
import { IAppState } from "../../store";
import { resetUploadFile, uploadFile } from "../../store/fileupload";
import { useWebSocket } from "../../utils/hooks";
import { handleFailedMessages } from "src/store/privateChat";
import { Box, IconButton, LinearProgress, Stack } from "@mui/material";
import MinimizeIcon from "src/mui/pages/audience/MinimizeIcon";
import {
  checkForFileType,
  checkForReplyFileType,
  fileThumb,
} from "src/mui/components/file-thumbnail";
import ReactPlayer from "react-player";
import ReactHlsPlayer from "@panelist/react-hls-player";

type MessageInputProps = {
  recepientId: string;
  conversationId: string;
  messageText?: string;
  userName?: string;
  onFocusHandler: () => void;
  editMessage?: any;
  replyMessage?: any;
  setEditMessage: (item: any) => void;
  onCancelReplyMessage: () => void;
  setFile: (item: any) => void;
};

interface IMessageInputForm {
  messageText: string;
}

const MessageInput = (props: MessageInputProps) => {
  const { register, handleSubmit, setValue, getValues, setFocus, watch } =
    useForm<IMessageInputForm>();
  const { webSocket, refreshConnection } = useWebSocket();
  const [showEmoji, setShowEmoji] = useState<boolean>(false);
  const [wsError, setWSError] = useState(false);
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { fileUpload, privateChat, fileUploadState } = useSelector(
    (state: IAppState) => state
  );
  const [fileTypeCheck, setFileTypeCheck] = useState<any>(null);
  const [disableTextField, setDisableTextField] = useState(false);

  const conversations = useSelector(
    (state: IAppState) => state.oneToOneConversation.openConversations
  );

  const isTyping: any = privateChat.isTyping;

  const userIsTyping =
    isTyping?.id === props?.conversationId && isTyping?.status;

  const resetChat = () => {
    setSelectedFile("");
    setSelectedFileType("");
    setSelectedFileName("");
    props.setFile(null);
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    resetUploadFile();
  };

  // WS for start typing
  const onStartTyping = () => {
    webSocket.then((ws) => {
      if (ws.readyState !== ws.OPEN) return refreshConnection({});
      ws.send(
        JSON.stringify({
          action: "start-typing",
          data: {
            conversationId: props.conversationId,
            conversations,
          },
        })
      );
    });
  };

  useEffect(() => {
    if (props?.editMessage?.message !== "") {
      setValue("messageText", props?.editMessage?.message);
    }
  }, [props?.editMessage]);

  // WS for stop typing
  const onStopTyping = () => {
    webSocket.then((ws) => {
      if (ws.readyState !== ws.OPEN) return refreshConnection({});
      ws.send(
        JSON.stringify({
          action: "stop-typing",
          data: {
            conversationId: props.conversationId,
          },
        })
      );
    });
  };

  // useEffect(() => {
  //   webSocket.then((ws) =>
  //     ws.send(
  //       JSON.stringify({
  //         action: "messages",
  //         data: {
  //           conversationId: props.conversationId,
  //           option: { limit: 500, sorted: true, exclusiveKey: null },
  //         },
  //       })
  //     )
  //   );
  // }, [props.conversationId, webSocket]);

  // This useEffect for check the mouse and keyboard activity
  useEffect(() => {
    let inactivityTimer: string | number | NodeJS.Timeout | undefined;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => onStopTyping(), 2000);
    };

    const handleMouseMove = () => {
      resetTimer();
    };

    const handleKeyDown = () => {
      resetTimer();
    };

    resetTimer();

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const typingMessage = watch("messageText");

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      // Inport debounce for start typing input
      if (typingMessage) {
        onStartTyping();
      } else {
        onStopTyping();
      }
    }, 400); // Debounce delay time (in milliseconds)

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [typingMessage]);

  const initConversation = (
    messageText: string,
    fileUrl?: string,
    messageType: string = "text"
  ) => {
    webSocket
      .then((ws) => {
        if (ws.readyState === ws.OPEN) {
          ws.send(
            JSON.stringify({
              action: "init-conversation",
              data: {
                message: messageText,
                metadata: "",
                receiverId: props.recepientId,
                type: messageType,
                ...(messageType !== "text" && fileUrl
                  ? { source: fileUrl }
                  : {}),
              },
            })
          );
        } else {
          setWSError(true);
        }
      })
      .finally(() => setTimeout(refreshChatList, 2000));
  };

  const refreshChatList = () => {
    webSocket.then((ws) =>
      ws.send(
        JSON.stringify({
          action: "conversations",
          data: { limit: 25, first: 20, exclusiveStartKey: null },
        })
      )
    );
  };

  const sendMessage = (
    messageText: string,
    fileUrl?: string,
    messageType: string = "text"
  ) => {
    const action = props?.replyMessage
      ? "private-chat-reply-message"
      : props.editMessage
      ? "update-message"
      : "send-message";
    onStopTyping();
    webSocket
      .then((ws) => {
        const wsMessage = JSON.stringify({
          action,
          data: {
            conversationId: props.conversationId,
            message: messageText,
            metadata: "",
            type: messageType,
            replyToMessageId: props?.replyMessage?.messageId,
            messageId: props?.editMessage?.messageId,
            ...(messageType !== "text" && fileUrl ? { source: fileUrl } : {}),
          },
        });
        if (ws.readyState === ws.OPEN) {
          if (messageType === "text" && messageText === "") {
            return;
          } else {
            ws.send(wsMessage);
            props.onCancelReplyMessage();
            props.setFile(null);
          }
        } else {
          setWSError(true);
          dispatch(handleFailedMessages(wsMessage));
          refreshConnection({});
        }
      })
      .finally(() => {
        // setTimeout(refreshChatList, 2000)
      });
  };

  const handleFileSelected = (files: FileList) => {
    const data = new FormData();
    setFileTypeCheck(files[0] && files[0]);
    data.append("file", files[0]);
    const fileType = files[0].type?.split("/")[0];

    if (fileType === "image") setSelectedFileType("image");
    else if (fileType.includes("video")) {
      setSelectedFileType("video");
      setSelectedFileName(files[0].name);
    } else {
      setSelectedFileType("document");
      setSelectedFileName(files[0].name);
    }

    dispatch(
      uploadFile(
        data,
        fileType === "image"
          ? "image"
          : fileType === "video"
          ? "video"
          : "document"
      )
    );
    if (!fileUpload.isUploading) {
      setSelectedFile(URL.createObjectURL(files[0]));
      props.setFile(files);
    } else if (fileUpload.isUploading) {
      props.setFile(null);
    }
    setFocus("messageText");
  };

  useEffect(() => {
    if (fileUploadState.isUploading) {
      setDisableTextField(true);
    } else {
      setDisableTextField(false);
    }
  }, [fileUploadState.isUploading]);

  const values = watch();

  const onSubmit = (data: IMessageInputForm) => {
    if (props.conversationId && props.conversationId !== "") {
      sendMessage(
        data?.messageText?.trim(),
        fileUpload.data.Key ? fileUpload.data.Key : undefined,
        fileUpload.data.Key ? selectedFileType : undefined
      );
      resetChat();
      dispatch(resetUploadFile());
    } else
      initConversation(
        data?.messageText?.trim(),
        fileUpload.data.Key ? fileUpload.data.Key : undefined,
        fileUpload.data.Key ? selectedFileType : undefined
      );
    setValue("messageText", "");
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    event.preventDefault();
    const fields = getValues();
    setValue("messageText", fields?.messageText + emojiObject.emoji);
    setShowEmoji(false);
    setFocus("messageText");
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {wsError && (
        <div className="sticky flex justify-center items-center text-xs bg-red-600 mx-12 rounded-xl h-5 text-white mb-1">
          Reload the chat
        </div>
      )}
      {props.editMessage && (
        <Stack
          sx={{
            fontSize: "13px",
            flexDirection: "row",
            justifyContent: "space-between",
            fontFamily: "Poppins-400",
            lineHeight: "18px",
            color: "#626262",
            p: "5px",
            borderRadius: "5px 5px 5px 0px",
            typography: "body2",
            position: "relative",
          }}
        >
          <div className="font-light">Edit Message</div>
          <IconButton
            onClick={() => {
              setValue("messageText", "");
              setSelectedFile("");
              setSelectedFileType("");
              if (props.onCancelReplyMessage) props.onCancelReplyMessage();
            }}
            sx={{ p: 0 }}
          >
            <MinimizeIcon />
          </IconButton>
        </Stack>
      )}
      <div className="flex flex-col bg-[#EDF2F5] rounded-10 relative">
        {userIsTyping && (
          <div className="text-[11px] font-extralight text-gray-1 absolute left-0 -top-[16px]">
            {props?.userName} typing...
          </div>
        )}
        {selectedFile !== "" && selectedFileType === "image" && (
          <>
            {fileUploadState.isUploading && (
              <LinearProgress
                variant="determinate"
                value={fileUploadState.progress}
              />
            )}
            <div className="flex flex-row ml-3">
              <div>
                <img
                  src={selectedFile}
                  alt=""
                  className="w-[100px] h-[100px] object-contain mt-1"
                />
              </div>
              <div
                className="-ml-2 cursor-pointer bg-[#EDF2F5]"
                onClick={() => resetChat()}
              >
                <img
                  src={deleteIcon}
                  alt="Remove"
                  className="w-4 h-4 mt-2 mr-1"
                />
              </div>
            </div>
          </>
        )}
        {selectedFile !== "" && selectedFileType === "video" && (
          <>
            {fileUploadState.isUploading && (
              <LinearProgress
                variant="determinate"
                value={fileUploadState.progress}
              />
            )}
            <div className="flex flex-row ml-3">
              <div className="w-[185px] h-[105px] object-contain mt-1 ">
                <video
                  id="video"
                  className="rounded-[10px] w-[185px] h-[105px]"
                  src={selectedFile}
                  controls={true}
                  muted={false}
                />
              </div>
              <div
                className="-ml-2 cursor-pointer bg-[#EDF2F5]"
                onClick={() => resetChat()}
              >
                <img
                  src={deleteIcon}
                  alt="Remove"
                  className="w-4 h-4 mt-2 mr-1"
                />
              </div>
            </div>
          </>
        )}
        {selectedFile !== "" && selectedFileType === "document" && (
          <>
            {fileUploadState.isUploading && (
              <LinearProgress
                variant="determinate"
                value={fileUploadState.progress}
              />
            )}
            <Stack
              sx={{
                fontSize: "13px",
                flexDirection: "row",
                justifyContent: "space-between",
                fontFamily: "Poppins-400",
                lineHeight: "18px",
                color: "#626262",
                p: "5px",
                borderRadius: "5px 5px 5px 0px",
                typography: "body2",
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={fileThumb(checkForFileType(fileTypeCheck))}
                  alt={checkForFileType(fileTypeCheck)}
                  sx={{
                    width: 27,
                    height: 30,
                    flexShrink: 0,
                  }}
                />
                <div className="text-sm font-light ml-2">
                  {selectedFileName}
                </div>
              </div>
              <IconButton onClick={() => resetChat()} sx={{ p: 0 }}>
                <MinimizeIcon />
              </IconButton>
            </Stack>
          </>
        )}
        {props.replyMessage && (
          <>
            <Stack
              sx={{
                fontSize: "13px",
                flexDirection: "row",
                justifyContent: "space-between",
                fontFamily: "Poppins-400",
                lineHeight: "18px",
                color: "#626262",
                p: "5px",
                borderRadius: "5px 5px 5px 0px",
                typography: "body2",
                position: "relative",
              }}
            >
              {props?.replyMessage?.message && (
                <div className="font-light">
                  {props.replyMessage?.message?.replace(/<\/?[^>]+(>|$)/g, "")}
                </div>
              )}

              <IconButton
                onClick={() => {
                  setValue("messageText", "");
                  setSelectedFile("");
                  setSelectedFileType("");
                  if (props.onCancelReplyMessage) props.onCancelReplyMessage();
                }}
                sx={{ p: 0 }}
              >
                <MinimizeIcon />
              </IconButton>
            </Stack>
            <>
              {props?.replyMessage?.source &&
                props?.replyMessage?.type !== "text" &&
                props?.replyMessage?.type === "image" && (
                  <Box
                    component="img"
                    alt="attachment"
                    src={`${process.env.REACT_APP_S3_MEDIA_BUCKET}/${props.replyMessage?.source}`}
                    sx={{
                      height: 60,
                      width: 80,
                      marginLeft: "5px",
                      borderRadius: 1.5,
                      cursor: "pointer",
                      objectFit: "cover",
                      "&:hover": {
                        opacity: 0.9,
                      },
                    }}
                  />
                )}
            </>
            <>
              {props.replyMessage?.source &&
                props.replyMessage?.type === "document" && (
                  <Stack
                    sx={{
                      fontSize: "13px",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      fontFamily: "Poppins-400",
                      lineHeight: "18px",
                      color: "#626262",
                      p: "5px",
                      borderRadius: "5px 5px 5px 0px",
                      typography: "body2",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={fileThumb(
                          checkForReplyFileType(
                            props?.replyMessage?.source?.split("/")[3]
                          )
                        )}
                        alt={fileThumb(
                          checkForReplyFileType(
                            props?.replyMessage?.source?.split("/")[3]
                          )
                        )}
                        sx={{
                          width: 27,
                          height: 30,
                          flexShrink: 0,
                        }}
                      />
                      <div className="text-sm font-light ml-2">
                        {props?.replyMessage?.source?.split("/")[3]}
                      </div>
                    </div>
                  </Stack>
                )}
            </>
            <>
              {props.replyMessage?.source &&
                props.replyMessage?.type === "video" && (
                  <video
                    id="video"
                    className="rounded-[10px] w-[160px] h-[90px] ml-[5px]"
                    src={`${process.env.REACT_APP_S3_MEDIA_BUCKET}/${props.replyMessage?.source}`}
                    controls={true}
                    muted={false}
                  />
                )}
            </>
          </>
        )}
        <div className="flex text-sm1 font-extralight text-gray-1 pl-2 rounded-10 bg-[#EDF2F5] w-full">
          <TextInput
            type="text"
            id="sendMessage"
            textinputsize="small"
            className="w-[220px] h-[38px] text-sm1 font-light text-gray-1 rounded bg-[#EDF2F5] outline-none ml-1"
            placeholder={!wsError ? "Type here..." : "Disconnected..."}
            value={props.messageText}
            autoFocus={true}
            tabIndex={1}
            disabled={
              !(props.recepientId || props.conversationId) ||
              wsError ||
              disableTextField
            }
            onFocus={props.onFocusHandler}
            {...register("messageText")}
          />

          <button
            type="button"
            onClick={(e) => {
              if (wsError) return;
              e.preventDefault();
              setShowEmoji(!showEmoji);
            }}
          >
            <img
              tabIndex={2}
              src={emojiIcon}
              className="mx-2"
              alt="emoji-icon"
            />
          </button>
          {showEmoji && (
            <Picker
              pickerStyle={{
                position: "absolute",
                zIndex: "99999",
                flex: "none",
                right: "0px",
                bottom: "60px",
              }}
              onEmojiClick={onEmojiClick}
            />
          )}
          <img
            tabIndex={3}
            height={20}
            width={20}
            src={attachmentIcon}
            className="cursor-pointer"
            alt="attachment-icon"
            onClick={() => !wsError && fileInputRef?.current?.click()}
          />
          <input
            type="file"
            id="fileToUpload"
            ref={fileInputRef}
            className="hidden"
            accept=".doc,video/*,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/*,.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
            onChange={(e) => !wsError && handleFileSelected(e.target?.files!)}
          />
        </div>
      </div>
    </form>
  );
};

export default MessageInput;
