/* eslint-disable react/no-unused-prop-types */
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { EventWebsiteTemplateType } from 'src/mui/types/survey';
import { IEvent } from 'src/models/event/event';
import { IAppState } from 'src/store';
import { useSelector } from 'react-redux';
import { IEventSession } from '../../../models/event/event-session';
import { Logo } from '../../../shared-components/V2/Logo';
import { ParseContent } from '../../../utils/parse-html';
import WebsiteEventPartnerList from './website-event-day-partnerlist';
import WebsiteEventSpeakerList from './website-event-day-speakerlist';

interface IAgendaBody {
  eventOverview: string;
  event?: IEvent;
  roomQty?: number;
}
export const AgendaBody = ({ eventOverview, roomQty, event }: IAgendaBody) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div
      className={classNames('w-[723px]', {
        '!p-0': roomQty === 2,
      })}
    >
      <div
        className={classNames('text-[13px] font-light leading-5 mb-5', {
          'text-[#3C3C3C]':
            event?.websiteTemplate !== EventWebsiteTemplateType.AFTER_HOURS,
          'text-[#FFFFFF]':
            event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
        })}
      >
        {showMore ? (
          <ParseContent content={eventOverview} />
        ) : (
          <ParseContent content={eventOverview?.substring(0, 350)} />
        )}
        {eventOverview?.length > 350 && (
          <button
            className={classNames('font-semibold', {
              'text-[#0049ef]':
                event?.websiteTemplate !== EventWebsiteTemplateType.AFTER_HOURS,
              'text-[#FFFFFF]':
                event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
            })}
            type="button"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? 'Show less' : 'Show more'}
          </button>
        )}
      </div>
    </div>
  );
};

export const AgendaBodyLobby = ({
  eventOverview,
  roomQty,
  event,
}: IAgendaBody) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div
      className={classNames('w-full', {
        '!p-0': roomQty === 2,
      })}
    >
      <div
        className={classNames('text-[13px] font-light leading-5 mb-5', {
          'text-[#3C3C3C]':
            event?.websiteTemplate !== EventWebsiteTemplateType.AFTER_HOURS,
          'text-[#FFFFFF]':
            event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
        })}
      >
        {showMore ? (
          <ParseContent content={eventOverview} />
        ) : (
          <ParseContent content={eventOverview?.substring(0, 350)} />
        )}
        {eventOverview?.length > 350 && (
          <button
            className={classNames('font-semibold', {
              'text-[#0049ef]':
                event?.websiteTemplate !== EventWebsiteTemplateType.AFTER_HOURS,
              'text-[#FFFFFF]':
                event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
            })}
            type="button"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? 'Show less' : 'Show more'}
          </button>
        )}
      </div>
    </div>
  );
};

interface WebsiteEventAgendaProps {
  session: IEventSession;
  roomQty?: number;
  eventOverview?: string;
  isTitleLobby?: boolean;
  isLobbbyPage?: boolean;
  event: IEvent;
}

export const AgendaTitle = ({
  event,
  session,
  roomQty,
  eventOverview,
  isTitleLobby,
  isLobbbyPage,
}: WebsiteEventAgendaProps) => (
  <div
    className={classNames('w-full flex items-center', {
      'table-column': isTitleLobby,
    })}
  >
    <div className="flex">
      <div
        className={classNames(
          'pl-[19px] flex items-start md:flex-row flex-col',
          {
            'md:!block': roomQty === 2,
            'pl-[16px]': isTitleLobby,
            'w-[calc(100%-25px)]': isLobbbyPage,
            'close-collapse-with': !isLobbbyPage,
          }
        )}
      >
        <span
          className={classNames(
            'text-[14px] leading-5 font-semibold flex items-center',
            {
              'float-left mb-[6px]': roomQty === 2,
              'min-w-[85px] max-w-[84px]': roomQty !== 2,
              'text-[13px] leading-[16px] items-baseline flex-col':
                isTitleLobby,
              'text-[#0B1221]':
                event?.websiteTemplate !== EventWebsiteTemplateType.AFTER_HOURS,
              'text-[#FFFFFF]':
                event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
            }
          )}
        >
          {format(parseISO(session.startTime), 'HH:mm')}
          {' '}
          -
          {format(parseISO(session.endTime), 'HH:mm')}
          <div
            className={classNames(
              'text-[12px] leading-[17px] font-normal ml-[0px] capitalize flex items-center',
              {
                hidden: !isTitleLobby,
                'text-[#0B1221]':
                  event?.websiteTemplate
                  !== EventWebsiteTemplateType.AFTER_HOURS,
                'text-[#FFFFFF]':
                  event?.websiteTemplate
                  === EventWebsiteTemplateType.AFTER_HOURS,
              }
            )}
          >
            {session.type}
          </div>
        </span>
        <div
          className={classNames(
            'text-[14px] leading-5 font-normal  ml-[29px] capitalize flex items-center',
            {
              '!flex !-mt-[0px] float-left mb-[6px]': roomQty === 2,
              hidden: isTitleLobby,
              'text-[#0B1221]':
                event?.websiteTemplate !== EventWebsiteTemplateType.AFTER_HOURS,
              'text-[#FFFFFF]':
                event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
            }
          )}
        >
          {session.type}
        </div>
        <div
          className={classNames('grid sm:pl-[30px]', {
            '!pl-0 float-left w-full': roomQty === 2,
            'min-w-[723px] max-w-[723px]': !isTitleLobby && roomQty !== 2,
            'sm:!min-w-[500px] !min-w-[280px] sm:!max-w-[500px] !max-w-[282px] sm:pl-[16px] mt-[2px]':
              isTitleLobby,
          })}
        >
          <span
            className={classNames(
              'text-[14px] leading-5 font-medium flex items-center -mt-[2px] pr-[40px]',
              {
                '!leading-5': roomQty === 2,
                'text-[13px] leading-5 font-medium': isTitleLobby,
                'text-[#0B1221]':
                  event?.websiteTemplate
                  !== EventWebsiteTemplateType.AFTER_HOURS,
                'text-[#FFFFFF]':
                  event?.websiteTemplate
                  === EventWebsiteTemplateType.AFTER_HOURS,
              }
            )}
          >
            {session.title}
          </span>
          <div className="hide-on-tab-open">
            <div className="sm:flex ml-0">
              {session && session?.eventSpeakers[0]?.firstName && (
                <div className="w-auto mt-2 min-w-[165px]">
                  <div className="text-left flex">
                    {session && session?.eventSpeakers[0]?.firstName && (
                      <div className="w-[49px] min-w-[49px] h-[49px] max-h-[49px] rounded-[20px] overflow-hidden mr-[10px] flex items-center justify-center">
                        <Logo
                          src={`${
                            process.env.REACT_APP_IMAGE_URL_PREFIX
                          }/${session?.eventSpeakers[0]?.photo!}`}
                          className="w-full h-full object-cover medium"
                        />
                      </div>
                    )}
                    <div className="flex flex-col justify-center">
                      <div
                        className={classNames(
                          'text-[13px] leading-[16px] font-semibold line-clamp-1',
                          {
                            'text-[#172746]':
                              event?.websiteTemplate
                              !== EventWebsiteTemplateType.AFTER_HOURS,
                            'text-[#FFFFFF]':
                              event?.websiteTemplate
                              === EventWebsiteTemplateType.AFTER_HOURS,
                          }
                        )}
                      >
                        {session?.eventSpeakers[0]?.firstName}
                        {' '}
                        {session?.eventSpeakers[0]?.lastName}
                      </div>
                      <div
                        className={classNames(
                          'text-[11px] leading-[13px] font-normal line-clamp-1',
                          {
                            'text-[#18161C]':
                              event?.websiteTemplate
                              !== EventWebsiteTemplateType.AFTER_HOURS,
                            'text-[#FFFFFF]':
                              event?.websiteTemplate
                              === EventWebsiteTemplateType.AFTER_HOURS,
                          }
                        )}
                      >
                        {session?.eventSpeakers[0]?.jobTitle}
                      </div>
                      <div
                        className={classNames(
                          'text-[11px] leading-[13px] font-normal line-clamp-1',
                          {
                            'text-[#18161C]':
                              event?.websiteTemplate
                              !== EventWebsiteTemplateType.AFTER_HOURS,
                            'text-[#FFFFFF]':
                              event?.websiteTemplate
                              === EventWebsiteTemplateType.AFTER_HOURS,
                          }
                        )}
                      >
                        {session?.eventSpeakers[0]?.company.name}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {session?.eventSponsors[0]?.company.name && (
                <div
                  className={classNames('flex sm:ml-[182px] mt-2', {
                    'sm:ml-[30px] max-w-[190px] sm:mt-2 mt-3': isTitleLobby,
                  })}
                >
                  <div className="w-full">
                    <div className="mr-10 text-left flex min-w-[200px]">
                      {(session?.eventSponsors[0]?.logo
                        || session?.eventSponsors[0]?.company?.logo) && (
                        // eslint-disable-next-line max-len
                        <div className="w-[49px] min-w-[49px] max-w-[49px] h-[49px] min-h-[49px] max-h-[49px] rounded-[4px] bg-white mr-[10px] p-[1px] flex items-center justify-center">
                          <Logo
                            src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${
                              session?.eventSponsors[0]?.logo
                              ?? session?.eventSponsors[0]?.company?.logo
                            }`}
                            className="w-full h-full object-contain rounded-[4px]"
                          />
                        </div>
                      )}
                      <div className="flex flex-col justify-start">
                        <div
                          className={classNames(
                            'text-[13px] leading-[16px] font-semibold pt-[3px] line-clamp-1',
                            {
                              'text-[#172746]':
                                event?.websiteTemplate
                                !== EventWebsiteTemplateType.AFTER_HOURS,
                              'text-[#FFFFFF]':
                                event?.websiteTemplate
                                === EventWebsiteTemplateType.AFTER_HOURS,
                            }
                          )}
                        >
                          {session?.eventSponsors[0]?.company.name}
                        </div>
                        <div
                          className={classNames(
                            'text-[11px] leading-[13px] font-normal line-clamp-1',
                            {
                              'text-[#18161C]':
                                event?.websiteTemplate
                                !== EventWebsiteTemplateType.AFTER_HOURS,
                              'text-[#FFFFFF]':
                                event?.websiteTemplate
                                === EventWebsiteTemplateType.AFTER_HOURS,
                            }
                          )}
                        >
                          {session?.eventSponsors[0]?.sponsorship}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames('hide-on-tab-close mt-2', {
              hidden: isTitleLobby,
            })}
          >
            <AgendaBody eventOverview={eventOverview!} event={event} />
            <div
              className={classNames('flex items-start', {
                '!block': roomQty === 2,
              })}
            >
              <WebsiteEventSpeakerList
                speakers={session.eventSpeakers}
                roomQty={roomQty}
              />
              <WebsiteEventPartnerList
                sponsors={session.eventSponsors}
                roomQty={roomQty}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className={classNames('hide-on-tab-close mt-[20px] pl-[17px]', {
        hidden: !isTitleLobby,
      })}
    >
      <AgendaBodyLobby eventOverview={eventOverview!} event={event} />
      <div
        className={classNames('sm:flex items-start', {
          '!block': roomQty === 2,
        })}
      >
        <WebsiteEventSpeakerList
          speakers={session.eventSpeakers}
          roomQty={roomQty}
        />
        <WebsiteEventPartnerList
          isTitleLobby
          sponsors={session.eventSponsors}
          roomQty={roomQty}
        />
      </div>
    </div>
  </div>
);

interface IAgendaBodyTwo {
  eventOverview: string;
  event: IEvent;
  roomQty?: number;
}
export const AgendaBodyTwo = ({
  eventOverview,
  roomQty,
  event,
}: IAgendaBodyTwo) => (
  <div
    className={classNames({
      '!p-0': roomQty === 2,
    })}
  >
    <div
      className={classNames('text-[13px] font-light leading-5 mb-5', {
        'text-[#3C3C3C]':
          event?.websiteTemplate !== EventWebsiteTemplateType.AFTER_HOURS,
        'text-[#FFFFFF]':
          event?.websiteTemplate === EventWebsiteTemplateType.AFTER_HOURS,
      })}
    >
      <ParseContent content={eventOverview} />
    </div>
  </div>
);

interface AgendaTitleBodyProps {
  event: IEvent;
  session: IEventSession;
  roomQty?: number;
  eventOverview?: string;
}

export const AgendaTitleBody = ({
  event,
  session,
  roomQty,
  eventOverview,
}: AgendaTitleBodyProps) => {
  const partners = useSelector((state: IAppState) => state.website.event!);
  const isOraclePartnerOfEvent = !!partners?.eventSponsors?.find((p) => p?.company?.name?.toLocaleLowerCase()?.includes('oracle'));
  const isOracleEvent = event?.slug.includes('oracle') || isOraclePartnerOfEvent || false;
  return (
    <div className="w-full flex items-center">
      <div className="flex">
        <div
          className={classNames(
            'pl-[19px] flex items-start md:flex-row flex-col md:!w-full ',
            {
              'md:!block': roomQty === 2,
            }
          )}
        >
          <span
            className={classNames(
              'text-[14px] w-[101px] leading-5 font-semibold flex items-center',
              {
                'float-left mb-[6px]': roomQty === 2,
                'min-w-[86px] max-w-[84px]': roomQty !== 2,
                'text-[#0B1221]':
                  event?.websiteTemplate
                  !== EventWebsiteTemplateType.AFTER_HOURS,
                'text-[#FFFFFF]':
                  event?.websiteTemplate
                  === EventWebsiteTemplateType.AFTER_HOURS,
              }
            )}
          >
            {format(parseISO(session.startTime), 'HH:mm')}
            {' '}
            -
            {format(parseISO(session.endTime), 'HH:mm')}
          </span>
          {!isOracleEvent && (
            <div
              className={classNames(
                'text-[14px] w-[75px] leading-5 font-normal md:ml-[29px] capitalize flex items-center',
                {
                  '!flex !-mt-[0px] float-left mb-[6px]': roomQty === 2,
                  'text-[#0B1221]':
                    event?.websiteTemplate
                    !== EventWebsiteTemplateType.AFTER_HOURS,
                  'text-[#FFFFFF]':
                    event?.websiteTemplate
                    === EventWebsiteTemplateType.AFTER_HOURS,
                }
              )}
            >
              {session.type}
            </div>
          )}
          <div
            className={classNames(
              'md:grid flex flex-col md:flex-row md:pl-[30px] md:w-[700px] float-left md:mt-[3px] md:ml-[30px]',
              {
                '!pl-0 float-left w-full': roomQty === 2,
                'min-w-[723px] max-w-[723px]': roomQty !== 2,
              }
            )}
          >
            <span
              className={classNames(
                'text-[14px] leading-5 font-medium flex items-center -mt-[2px]',
                {
                  '!leading-5': roomQty === 2,
                  'text-[#0B1221]':
                    event?.websiteTemplate
                    !== EventWebsiteTemplateType.AFTER_HOURS,
                  'text-[#FFFFFF]':
                    event?.websiteTemplate
                    === EventWebsiteTemplateType.AFTER_HOURS,
                }
              )}
            >
              {session.title}
            </span>
            <div className="hide-on-tab-open">
              <div className="flex ml-0 flex-col md:flex-row md:gap-x-[70px]">
                {session && session?.eventSpeakers[0]?.firstName && (
                  <div className="w-auto mt-2 max-w-[300px] min-w-[300px]">
                    <div className="text-left flex">
                      {session && session?.eventSpeakers[0]?.firstName && (
                        // eslint-disable-next-line max-len
                        <Logo
                          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${
                            session && session?.eventSpeakers[0]?.photo!
                          }`}
                          className="w-[49px] h-[49px] object-cover rounded-[20px] bg-gray-1 mr-[10px]"
                        />
                      )}
                      <div className="flex flex-col justify-center">
                        <div
                          className={classNames(
                            'text-[13px] leading-[16px] font-semibold line-clamp-1',
                            {
                              'text-[#172746]':
                                event?.websiteTemplate
                                !== EventWebsiteTemplateType.AFTER_HOURS,
                              'text-[#FFFFFF]':
                                event?.websiteTemplate
                                === EventWebsiteTemplateType.AFTER_HOURS,
                            }
                          )}
                        >
                          {session && session?.eventSpeakers[0]?.firstName}
                          {' '}
                          {session && session?.eventSpeakers[0]?.lastName}
                        </div>
                        <div
                          className={classNames(
                            'text-[11px] leading-[13px] text-[#18161C] font-normal line-clamp-1',
                            {
                              'text-[#18161C]':
                                event?.websiteTemplate
                                !== EventWebsiteTemplateType.AFTER_HOURS,
                              'text-[#FFFFFF]':
                                event?.websiteTemplate
                                === EventWebsiteTemplateType.AFTER_HOURS,
                            }
                          )}
                        >
                          {session && session?.eventSpeakers[0]?.jobTitle}
                        </div>
                        <div
                          className={classNames(
                            'text-[11px] leading-[13px] font-normal line-clamp-1',
                            {
                              'text-[#18161C]':
                                event?.websiteTemplate
                                !== EventWebsiteTemplateType.AFTER_HOURS,
                              'text-[#FFFFFF]':
                                event?.websiteTemplate
                                === EventWebsiteTemplateType.AFTER_HOURS,
                            }
                          )}
                        >
                          {session && session?.eventSpeakers[0]?.company.name}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {session && session?.eventSponsors[0]?.company.name && (
                  <div className="flex mt-2 max-w-[275px] min-w-[215px]">
                    <div className="w-full">
                      <div className="mr-10 text-left flex">
                        {session
                          && (session?.eventSponsors[0]?.logo
                            || session?.eventSponsors[0]?.company?.logo) && (
                            <div
                              // eslint-disable-next-line max-len
                              className={classNames(
                                ' bg-white rounded-[4px] w-[49px] max-w-[49px] min-w-[49px] h-[49px] max-h-[49px] min-h-[49px] object-contain flex items-center justify-center mr-[10px]',
                                {
                                  'p-[1px] !bg-white':
                                    session?.eventSponsors[0]?.logo
                                    ?? session?.eventSponsors[0]?.company?.logo,
                                  'create-post-card !rounded-[4px] bg-[#edf2f5]':
                                    !session?.eventSponsors[0]?.company?.logo,
                                }
                              )}
                            >
                              <Logo
                                className={classNames(
                                  'rounded-[4px] w-[48px] max-w-[48px] min-w-[48px] h-[48px] max-h-[48px] min-h-[48px] object-contain'
                                )}
                                src={`${
                                  process.env.REACT_APP_IMAGE_URL_PREFIX
                                }/${
                                  session?.eventSponsors[0]?.logo
                                  ?? session?.eventSponsors[0]?.company?.logo
                                }`}
                                alt=""
                                defaultLogo="company"
                              />
                            </div>
                        )}
                        <div className="flex flex-col justify-start min-w-[200px]">
                          <div
                            className={classNames(
                              'text-[13px] leading-[16px] font-semibold pt-[3px] line-clamp-1',
                              {
                                'text-[#172746]':
                                  event?.websiteTemplate
                                  !== EventWebsiteTemplateType.AFTER_HOURS,
                                'text-[#FFFFFF]':
                                  event?.websiteTemplate
                                  === EventWebsiteTemplateType.AFTER_HOURS,
                              }
                            )}
                          >
                            {session && session?.eventSponsors[0]?.company.name}
                          </div>
                          <div
                            className={classNames(
                              'text-[11px] leading-[13px] font-normal line-clamp-1',
                              {
                                'text-[#18161C]':
                                  event?.websiteTemplate
                                  !== EventWebsiteTemplateType.AFTER_HOURS,
                                'text-[#FFFFFF]':
                                  event?.websiteTemplate
                                  === EventWebsiteTemplateType.AFTER_HOURS,
                              }
                            )}
                          >
                            {session && session?.eventSponsors[0]?.sponsorship}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="hide-on-tab-close mt-2">
              <AgendaBodyTwo eventOverview={eventOverview!} event={event} />
              <div
                className={classNames(
                  'flex items-start md:flex-row flex-col md:gap-x-[125px]',
                  {
                    // '!block': roomQty === 2
                  }
                )}
              >
                <WebsiteEventSpeakerList
                  isOpen
                  speakers={session.eventSpeakers}
                  roomQty={roomQty}
                />
                <WebsiteEventPartnerList
                  isOpen
                  sponsors={session.eventSponsors}
                  roomQty={roomQty}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
