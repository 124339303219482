import {
  useSelector,
} from 'react-redux';
import classNames from 'classnames';
// import { EventsSvgIcon } from '../../../../shared-components/V2/IconSvg';
import verifiedIcon from '../../../../assets/images/verified-icon.svg';
import { ICompanyHeader } from '../../../../models/company/company-header';
import { IAppState } from '../../../../store';
import { OverviewSvgIcon } from '../company-icons/OverviewSvgIcon';
import { PostSvgIcon } from '../company-icons/PostSvgIcon';
import { SpotlightSvgIcon } from '../company-icons/SpotlightSvgIcon';
import { EventsSvgIcon } from '../company-icons/EventsSvgIcon';
import { ProductsSvgIcon } from '../company-icons/ProductsSvgIcon';

export const CompanyHeaderInfo = (props: ICompanyHeader) => {
  const { companyProfilePreview } = useSelector((state: IAppState) => state);

  const onOverviewClick = () => {
    if (props.getActiveTab) props.getActiveTab('overview');
  };

  const onPostsClick = () => {
    if (props.getActiveTab) props.getActiveTab('post');
  };

  const onSpotlightClick = () => {
    if (props.getActiveTab) props.getActiveTab('spotlight');
  };

  const onEventsClick = () => {
    if (props.getActiveTab) props.getActiveTab('event');
  };

  const onProductsClick = () => {
    if (props.getActiveTab) props.getActiveTab('products');
  };

  return (
    <>
      {props?.id && (
        <>
          <div className="mx-auto flex flex-row mt-[22px] relative">
            <div className="text-lg text-black font-semibold leading-[26px]">
              {props.name}
            </div>
            <div className="ml-0.5 -mt-[6px] absolute top-[3px] -right-[16px]">
              <img className="w-[15px]" src={verifiedIcon} alt="" />
            </div>

          </div>
          <div className="text-center text-md2 font-medium mt-2.5 leading-[21px] mb-[3px] sm:w-[50%] w-full sm:px-0 px-4 mx-auto">
            {companyProfilePreview.value?.tagline}
          </div>
          <div className="text-center text-sm7 mb-[3px]">
            {companyProfilePreview.value?.industry?.name}
          </div>
          <div className="flex justify-center mb-2.5 gap-1">
            <div className="text-center text-sm7">
              {companyProfilePreview.value?.headquarter}
            </div>
            <div className="text-center text-sm7">
              {companyProfilePreview.value?.headquarter && '•'}
            </div>
            <div className="text-center text-sm7">
              {/* {companyProfilePreview.value?.totalFollowing} */}
              1000+
              {' '}
              followers
            </div>
          </div>
        </>
      )}

      {!props.isEditCoverPhoto ? (
        <div className="flex flex-row mt-2 gap-[7px] mb-4 mx-auto">
          {props.showOverview && (
            <div
              onClick={onOverviewClick}
              className={classNames('flex flex-col items-center justify-center hover:cursor-pointer bg-[#EDF2F5] rounded-[5px] h-[80px] w-[80px]', {
                '!bg-[#0049EF]': props.activeTab === 'overview'
              })}
            >
              <div className="flex justify-center">
                {
                    props.activeTab === 'overview'
                      ? <OverviewSvgIcon className="" isActive />
                      : <OverviewSvgIcon className="" />
                  }
              </div>
              <div
                className={classNames('text-sm mt-[5px] font-medium', {
                  'text-white': props.activeTab === 'overview',
                  'font-normal text-[#7283A4]': props.activeTab !== 'overview'
                })}
              >
                Overview
              </div>
            </div>
          )}
          {props.showPosts && (
          <div
            onClick={onPostsClick}
            className={classNames('flex flex-col items-center justify-center hover:cursor-pointer bg-[#EDF2F5] rounded-[5px] h-[80px] w-[80px]', {
              '!bg-[#0049EF]': props.activeTab === 'post'
            })}
          >
            <div className="flex justify-center">
              {
                    props.activeTab === 'post'
                      ? <PostSvgIcon className="" isActive />
                      : <PostSvgIcon className="" />
                  }
            </div>
            <div
              className={classNames('text-sm mt-[5px] font-medium', {
                'text-white': props.activeTab === 'post',
                'font-normal text-[#7283A4]': props.activeTab !== 'post'
              })}
            >
              Posts
            </div>
          </div>
          )}
          {props.showTechShows && (
          <div
            onClick={onSpotlightClick}
            className={classNames('flex flex-col items-center justify-center hover:cursor-pointer bg-[#EDF2F5] rounded-[5px] h-[80px] w-[80px]', {
              '!bg-[#0049EF]': props.activeTab === 'spotlight'
            })}
          >
            <div className="flex justify-center">
              {
                    props.activeTab === 'spotlight'
                      ? <SpotlightSvgIcon className="" isActive />
                      : <SpotlightSvgIcon className="" />
                  }
            </div>
            <div
              className={classNames('text-sm mt-[5px] font-medium', {
                'text-white': props.activeTab === 'spotlight',
                'font-normal text-[#7283A4]': props.activeTab !== 'spotlight'
              })}
            >
              Spotlight
            </div>
          </div>
          )}

          {props.showEvents && (
          <div
            onClick={onEventsClick}
            className={classNames('flex flex-col items-center justify-center hover:cursor-pointer bg-[#EDF2F5] rounded-[5px] h-[80px] w-[80px]', {
              '!bg-[#0049EF]': props.activeTab === 'event'
            })}
          >
            <div className="flex justify-center">
              {
                    props.activeTab === 'event'
                      ? <EventsSvgIcon className="" isActive />
                      : <EventsSvgIcon className="" />
                  }
            </div>
            <div
              className={classNames('text-sm mt-[5px] font-medium', {
                'text-white': props.activeTab === 'event',
                'font-normal text-[#7283A4]': props.activeTab !== 'event'
              })}
            >
              Events
            </div>
          </div>
          )}
          {props.showProducts && (
          <div
            onClick={onProductsClick}
            className={classNames('flex flex-col items-center justify-center hover:cursor-pointer bg-[#EDF2F5] rounded-[5px] h-[80px] w-[80px]', {
              '!bg-[#0049EF]': props.activeTab === 'products'
            })}
          >
            <div className="flex justify-center">
              {
                    props.activeTab === 'products'
                      ? <ProductsSvgIcon className="" isActive />
                      : <ProductsSvgIcon className="" />
                  }
            </div>
            <div
              className={classNames('text-sm mt-[5px] font-medium', {
                'text-white': props.activeTab === 'products',
                'font-normal text-[#7283A4]': props.activeTab !== 'products'
              })}
            >
              Products
            </div>
          </div>
          )}
        </div>
      ) : null}

    </>
  );
};

export default CompanyHeaderInfo;
