import { useCallback, useEffect, useRef } from "react";
import { IChatMessage } from "src/mui/types/chat";
export default function useMessagesScroll(
  messages: IChatMessage[],
  isReverse: boolean = false
) {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const shouldScrollToBottom = useRef(true);

  const scrollMessagesToBottom = useCallback(() => {
    if (!messages) {
      return;
    }
    if (!messagesEndRef?.current) return;
    if (messagesEndRef.current && shouldScrollToBottom.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    scrollMessagesToBottom();
  }, [messages, scrollMessagesToBottom]);

  const preserveScrollPosition = () => {
    if (messagesEndRef.current) {
      const { scrollTop, scrollHeight } = messagesEndRef.current;
      shouldScrollToBottom.current =
        scrollTop === scrollHeight - messagesEndRef.current.clientHeight;
    }
  };

  return {
    messagesEndRef,
    preserveScrollPosition,
  };
}
