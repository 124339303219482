import classNames from 'classnames';
import { ImagePreviewTab } from '../../company-enum';
import DesktopIcon from '../../../../../assets/images/desktop-preview.svg';
import MobileIcon from '../../../../../assets/images/phone-preview.svg';
import TabletIcon from '../../../../../assets/images/tablet-preview.svg';

type Props = {
  activeTab: string;
  handleTabCahnge: (item: string) => void;
  isScreenshots?: boolean;
  showDesktopTab?: boolean;
  showPhoneTab?: boolean;
  showTabletTab?: boolean;
};

const CompanyTabs = ({
  activeTab, handleTabCahnge, isScreenshots, showDesktopTab,
  showPhoneTab,
  showTabletTab
}: Props) => (
  <div
    className={classNames('flex flex-row border-b border-[#707070] border-opacity-[28%] mb-[10px]', {
      'border-none gap-x-[15px] !mb-0': isScreenshots,
    })}
  >
    <div
      className={classNames('flex items-start text-[#0B1221] text-[13px] font-600 cursor-pointer leading-[17px] pb-[7px]', {
        'font-medium border-b-[3px] border-[#0049EF]': activeTab === ImagePreviewTab.DESKTOP,
        'font-normal': activeTab !== ImagePreviewTab.DESKTOP,
        'text-[#0131FF] !font-semibold !pb-[3px]': isScreenshots,
        hidden: !showDesktopTab
      })}
      onClick={() => handleTabCahnge(ImagePreviewTab.DESKTOP)}
    >
      {!isScreenshots && (<img className="mr-[5px]" src={DesktopIcon} alt="tab images" />)}
      Desktop
      {
        !isScreenshots && (
          <>
            {' '}
            <br />
            {' '}
            1440x900
          </>
        )
      }

    </div>
    {!isScreenshots && (<div className="w-[2px] h-[45px] bg-[#B7B7B7] mx-[14px] opacity-[28%]" />)}
    <div
      className={classNames('flex items-start text-[#0B1221] text-[13px] font-600 cursor-pointer leading-[17px] pb-[7px]', {
        'font-medium border-b-[3px] border-[#0049EF]': activeTab === ImagePreviewTab.PHONE,
        'font-normal': activeTab !== ImagePreviewTab.PHONE,
        'text-[#0131FF] !font-semibold !pb-[3px]': isScreenshots,
        hidden: !showPhoneTab
      })}
      onClick={() => handleTabCahnge(ImagePreviewTab.PHONE)}
    >
      {!isScreenshots && (<img className="mr-[5px]" src={MobileIcon} alt="tab images" />)}

      <span className="flex items-center">
        Phone
        {!isScreenshots && (
          <>
            {' '}
            <br />
            {' '}
            275x540
          </>
        )}

      </span>
    </div>
    {!isScreenshots && (<div className="w-[2px] h-[45px] bg-[#B7B7B7] mx-[14px] opacity-[28%]" />)}
    <div
      className={classNames('flex items-start text-[#0B1221] text-[13px] font-600 cursor-pointer leading-[17px] pb-[7px]', {
        'font-medium border-b-[3px] border-[#0049EF]': activeTab === ImagePreviewTab.TABLET,
        'font-normal': activeTab !== ImagePreviewTab.TABLET,
        'text-[#0131FF] !font-semibold !pb-[3px]': isScreenshots,
        hidden: !showTabletTab
      })}
      onClick={() => handleTabCahnge(ImagePreviewTab.TABLET)}
    >
      {!isScreenshots && (<img className="mr-[5px]" src={TabletIcon} alt="tab images" />)}

      <span className="flex items-center">
        Tablet
        {!isScreenshots && (
          <>
            {' '}
            <br />
            {' '}
            400x530
          </>
        )}
      </span>
    </div>
  </div>
);

export default CompanyTabs;
