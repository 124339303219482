import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'src/shared-components/Loader';
import { ParseContent } from 'src/utils/parse-html';
import { Logo } from 'src/shared-components/V2/Logo';
import { dateAndTimeWithTz } from 'src/utils/date-timezone';
import { getTimeZone } from 'src/utils/timezones';
import { IAppState } from 'src/store';
import { rgbToHex } from 'src/utils/color-transformation';
import EventRegistration from './event-registration';
// import EventRegistrationButton from '../registration/event-registration-button/event-registration-button';

export const Banner = () => {
  const event = useSelector((state: IAppState) => state.website.event!);

  const checkLumination = () => {
    if (!event?.backgroundDominantColor?.length) return false;
    const luma = 0.2126 * event?.backgroundDominantColor[0]
      + 0.7152 * event?.backgroundDominantColor[1]
      + 0.0722 * event?.backgroundDominantColor[2];
    return luma <= 139.731;
  };

  const getBackgroundGradient = () => {
    if (!event?.backgroundDominantColor?.length) return '#0D4552';
    return `${rgbToHex(event?.backgroundDominantColor)}`;
  };

  const getTextColor = () => {
    if (checkLumination()) return '#FFFFFF';
    return '#ffffff';
  };

  const getTimeZoneName = (zone: string) => {
    const shortTimeZone = getTimeZone(zone)?.[0]?.abbr ? getTimeZone(zone)?.[0]?.abbr : zone;
    return shortTimeZone;
  };

  const isTimeZoneDifferent = () => {
    const eventTimeZone = getTimeZone(event.timezone);
    if(!eventTimeZone) return false;
    const localTimeZoneOffset = new Date().getTimezoneOffset()/60;
    return eventTimeZone[0]?.offset !== -localTimeZoneOffset; // new Date().getTimezoneOffset() return negatibe for ahead  of UTC
  }

  const eventDateTimeWithTz = useMemo(() => {
    const startTime = dateAndTimeWithTz(event?.startTime, event?.timezone);
    const endTime = dateAndTimeWithTz(event?.endTime, event?.timezone);
    return {
      startTime,
      endTime
    };
  }, [event?.startTime, event?.endTime, event?.timezone]);

  const eventDateTimeLocalTz = useMemo(() => {
    const startTime = format(parseISO(event?.startTime), 'hh:mm aa')?.replace('.', '')?.replace(' ', '')?.replace('.', '');
    const endTime = format(parseISO(event?.endTime), 'hh:mm aa')?.replace('.', '')?.replace(' ', '')?.replace('.', '');
    return {
      startTime,
      endTime
    };
  }, [event?.startTime, event?.endTime]);

  return (
    <div id="banner" className="w-full">
      <div className="block md:flex">
        <div
          className="w-screen bg-gradient-to-b px-[20px] xl:px-0"
          style={{
            background: `linear-gradient(${getBackgroundGradient()}, transparent)`,
          }}
        >
          <div
            className="website-event-cover z-10 relative overflow-hidden"
            style={{
              backgroundImage:
                event?.cover
                && `url("${`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event.cover}`}")`,
            }}
          >
            <div
              style={{
                backgroundColor: event?.isCoverImageOverlayColor
                  ? event?.coverImageOverlayColour
                  : 'none',
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                opacity: event?.isCoverImageOverlayColor
                  ? `${event?.coverImageOverlayOpacity}%`
                  : '0%',
              }}
            />
            <div className="w-full text-white flex flex-col items-center rounded-b-[20px] h-[100%] justify-center relative z-10 overflow-hidden">
              <div className=" md:max-w-[771px] max-w-full md:px-0 px-3">
                <div
                  className="flex justify-center items-center w-full sm:w-[699px] h-[150px] mb-5 mx-auto md:px-0 px-7"
                // style={{
                //   background: event?.colorStyle?.values?.backgroundButton,
                // }}
                >
                  {event?.logo && event?.logo !== 'removed' && (
                    <Logo
                      src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event.logo}`}
                      className="flex justify-center h-full object-contain"
                      alt={event?.name}
                      defaultLogo="company"
                    />
                  )}
                </div>
                <div
                  className="text-[21px] leading-[25px] flex justify-center font-[600] mb-[22px] max-w-[600px] mx-auto"
                  style={{
                    color: getTextColor(),
                  }}
                >
                  <ParseContent content={event.theme} className="text-center line-clamp-3 text-[16px]" />
                </div>
                {/* <div
                  className="text-[17px] leading-[24px] flex justify-center font-semibold capitalize mx-auto text-center line-clamp-3 mb-[22px]"
                  style={{
                    color: getTextColor(),
                  }}
                >
                  <ParseContent content={event.overview} />
                </div> */}
                <div
                  className="text-[14px] md:h-[20px] md:flex block text-center justify-center font-bold capitalize mb-[14px]"
                  style={{
                    color: getTextColor(),
                  }}
                >
                  {event.locationName}
                </div>
                <div
                  className="text-[14px] md:h-[20px] md:flex block text-center justify-center font-bold capitalize"
                  style={{
                    color: getTextColor(),
                  }}
                >
                  {eventDateTimeWithTz.startTime.date}
                  <span className="mx-[3px]">•</span>
                  <span className="uppercase">
                    {eventDateTimeWithTz.startTime.time}
                  </span>
                  -
                  <span className="uppercase mr-[3px]">{eventDateTimeWithTz.endTime.time}</span>
                  {getTimeZoneName(event?.timezone)}
                </div>
                {
                  isTimeZoneDifferent() && (
                  <div
                  className="text-[14px] md:h-[20px] md:flex block text-center justify-center font-normal capitalize break-words"
                  style={{
                    color: getTextColor(),
                  }}
                >
                  <span>
                    [
                    {format(parseISO(event.startTime), 'dd MMM yyyy')}
                  </span>
                  <span className="mx-[3px]">•</span>
                  <span className="uppercase">
                    {eventDateTimeLocalTz.startTime}
                  </span>
                  -
                  <span className="uppercase mr-[3px]">{eventDateTimeLocalTz.endTime}</span>
                  <span>Local Time</span>
                  ]
                </div>
                )}
               
                <div className="flex justify-center mt-[20px]">
                  {event ? (
                    <EventRegistration isBanner eventId={event.id} />
                  ) : (
                    <Loader className="pt-5" key="loader" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
