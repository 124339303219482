/* eslint-disable max-len */
import React, { FC } from 'react';
import classNames from 'classnames';
import { Logo } from 'src/shared-components/V2/Logo';
import { IconSvg } from 'src/shared-components/V2';
import { getMinifiedImage } from 'src/mui/utils/image';

export interface ICompanyItemCardProps {
  containerClass?: string;
  avatarClass?: string;
  avatarUrl: string;
  title: string;
  subTitle: string;
  duration: string;
  location: string;
  detailsHref: string;
  icon?: string;
  isEdit?: boolean;
  onCompanyClick?: Function;
  onCompanyEditClick?: Function;
  avatarDefaultClass?: string;
}

export const CompanyItemCard: FC<ICompanyItemCardProps> = ({
  containerClass,
  avatarClass,
  avatarUrl,
  title,
  subTitle,
  duration,
  location,
  detailsHref,
  icon,
  isEdit = false,
  onCompanyClick,
  onCompanyEditClick,
  avatarDefaultClass
}) => (
  <div
    className={classNames('flex items-start justify-between w-full', {
      [`${containerClass}`]: containerClass,
    })}
  >
    <div className="flex justify-between w-full">
      <div className="flex flex-row relative">
        <div className="flex-none cursor-pointer" onClick={() => onCompanyClick && onCompanyClick(detailsHref)}>
          {!avatarUrl?.includes('null') ? (
            <Logo src={getMinifiedImage(avatarUrl)} className={avatarClass} defaultLogo="company" />
          ) : (
            <Logo src={getMinifiedImage(avatarUrl)} className={avatarDefaultClass} defaultLogo="company" />
          )}
        </div>
        <div className="flex flex-col ml-5 w-[320px]">
          <div className="flex items-center justify-between relative">
            {
                title
                && <span aria-label="card-company-target" onClick={() => onCompanyClick && onCompanyClick(detailsHref)} className="cursor-pointer text-[12px] font-[700] leading-4 text-[#000000] mb-[2px]">{title}</span>
              }
          </div>
          {
              subTitle
              && <span aria-label="card-job-title" onClick={() => onCompanyClick && onCompanyClick(detailsHref)} className="cursor-pointer text-[12px] font-[500] leading-[18px] text-[#000000] mb-[2px]">{subTitle}</span>
            }
          {
              duration
              && <span className="text-[12px] text-[#3C3C3C] font-normal leading-[14px] mb-[2px] line-clamp-1">{duration}</span>
            }
          {
              location
              && <span className="text-[12px] text-[#3C3C3C] font-normal leading-[14px]">{location}</span>
            }
        </div>
      </div>
      {
          isEdit
          && (
          <span aria-label="card-company-edit-target" onClick={() => onCompanyEditClick && onCompanyEditClick()} className="cursor-pointer">
            <IconSvg imageUrl={icon || ''} />
          </span>
          )
        }
    </div>
  </div>
);
