import {
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  IEventTicket,
  ISelectedTicket,
} from '../../models/tickets';
import { TicketService } from '../../services/Tickets';
import { init } from '../liveEvent';

export type TicketState = {
  loading: boolean;
  value: IEventTicket[];
  errorMessage: string;
  selectedTickets: ISelectedTicket[];
};

const initialState: TicketState = {
  loading: false,
  value: [],
  errorMessage: "",
  selectedTickets: [],
};

export const ticketSlice = createSlice({
  name: "ticketSlice",
  initialState,
  reducers: {
    setTicketsResult: (state, action: PayloadAction<IEventTicket[] | []>) => {
      state.value = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setSelectedTickets: (state, action: PayloadAction<ISelectedTicket>) => {
      const ticketFound = state.selectedTickets.some(
        (t) => t.ticketId === action.payload.ticketId
      );
      if (ticketFound) {
        state.selectedTickets = state.selectedTickets.map((ticket) => {
          if (ticket.ticketId === action.payload.ticketId)
            return {
              ...ticket,
              numberOfTickets: action.payload.numberOfTickets,
            };

          return ticket;
        });
      } else state.selectedTickets.push(action.payload);
    },
    removeTicket: (state, action: PayloadAction<string>) => {
      state.selectedTickets = state.selectedTickets.filter(
        (ticket) => ticket.ticketId !== action.payload
      );
    },
    updateNumberOfTickets: (state, action: PayloadAction<string>) => {
      let selectedTicket = state.selectedTickets.find(
        (ticket) => ticket.ticketId === action.payload
      );
      if (selectedTicket) {
        state.selectedTickets = state.selectedTickets.map((ticket) => {
          if (ticket.ticketId === selectedTicket?.ticketId) {
            return { ...ticket, numberOfTickets: ticket.numberOfTickets - 1 };
          }

          return ticket;
        });
      }
    },
    resetTickets: (state) => {
      return {
        ...initialState,
        value: state.value
      };
    }
  },
});

const {
  setTicketsResult,
  setLoading,
  setErrorMessage,
  setSelectedTickets,
  removeTicket,
  updateNumberOfTickets,
  resetTickets,
} = ticketSlice.actions;

export const loadingTickets =
  (isLoading: boolean) => async (dispatch: Dispatch) =>
    dispatch(setLoading(isLoading));

export const getTickets = (eventId: string) => async (dispatch: Dispatch) => {
  const ticketService = new TicketService();

  try {
    const result = await ticketService.getTickets(eventId);
    dispatch(setTicketsResult(result?.data));
  } catch (error: any) {
    // console.error(error);
    dispatch(setErrorMessage(error?.message));
  }
};

export const selectTicket =
  (ticket: ISelectedTicket) => async (dispatch: Dispatch) =>
    dispatch(setSelectedTickets(ticket));

export const removeTicketFromCart =
  (ticketId: string) => async (dispatch: Dispatch) =>
    dispatch(removeTicket(ticketId));

export const updateTickets = (ticketId: string) => async (dispatch: Dispatch) =>
  dispatch(updateNumberOfTickets(ticketId));

export const resetCart = () => async (dispatch: Dispatch) => {
  dispatch(resetTickets());
}
