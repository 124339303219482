// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// @mui
// routes
// import Router from 'src/mui/routes/sections';
// theme
import ThemeProvider from './theme';
// hooks
import { useScrollToTop } from './hooks/use-scroll-to-top';
// components
import { SettingsProvider } from './components/settings';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import MotionLazy from './components/animate/motion-lazy';
import Page403 from './pages/403';
import { LiveEvent } from './pages/event/live';
import ComingSoonPage from './pages/coming-soon';
import { SplashScreen } from './components/loading-screen';
import { MUIRouter } from './routers';
import { SurveyContextProvider } from './providers/GeneralContext';
// sections
// ----------------------------------------------------------------------

// Font Family
import './index.css';

export default function AppMui() {

  const { hostName } = useParams();
  const location = useLocation();

  const decodedHostName = hostName && decodeURIComponent(hostName);
  const newHostName =
    decodedHostName?.toLocaleLowerCase() === "sit-down"
      ? "Factor"
      : decodedHostName;
  const newPath = decodeURIComponent(location.pathname).replace(
    `/${decodedHostName}`,
    `/${encodeURIComponent(newHostName!)}`
  );
  if (location.pathname !== newPath) {
    return <Navigate to={newPath} replace />;
  }
  
  useScrollToTop();

  return (
    <HelmetProvider>
      <Suspense fallback={<SplashScreen />}>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'blue', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <SurveyContextProvider>
              <MotionLazy>
                <MUIRouter />
              </MotionLazy>
            </SurveyContextProvider>
          </ThemeProvider>
        </SettingsProvider>
      </Suspense>
    </HelmetProvider>
  );
}
