/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Avatar, IconButton, Stack, Typography
} from '@mui/material';
import { CustomAvatar, CustomAvatarGroup } from 'src/mui/components/hook-form/custom-avatar';
import { UserService } from 'src/services';
import { IUserProfile } from 'src/mui/types/user';
import { useFormContext } from 'react-hook-form';
import ProductRepresentative from '../../../../../assets/images/sales-representative-icon.svg';
import AddSalesRepresentativeDialog from './AddSalesRepresentativeDialog';

const ProductSalesRepresentative = () => {
  // Updated dummy array representing backend data with required fields
  const [users, setUsers] = useState<IUserProfile[]>([]);
  const [selectedRepresentatives, setSelectedRepresentatives] = useState<IUserProfile[]>([]);
  const [search, setSearch] = useState<string>('');
  const { watch } = useFormContext();
  const defaultSelectedRepresentatives = watch('representatives');
  function useDebounce(value: any, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return debouncedValue;
  }
  const debouncedInputValue = useDebounce(search, 500);

  const [showDialog, setShowDialog] = useState(false);

  const mergeUniqueUsers = (existingUsers: IUserProfile[], newUsers: IUserProfile[]): IUserProfile[] => {
    const usersMap = new Map(existingUsers.map(user => [user.id, user]));
    newUsers.forEach(user => usersMap.set(user.id, user));
    return Array.from(usersMap.values());
  };

  const getUsers = useCallback(async () => {
    const userService = new UserService();
    try {
      const result = await userService.getAllUsers();
      if (result) {
        const defaultReps = Array.isArray(defaultSelectedRepresentatives) ? defaultSelectedRepresentatives : [];
        setUsers(prevUsers => mergeUniqueUsers([...prevUsers, ...defaultReps], result.data));
      }
    } catch (err) {
      console.error('getUsers():', err);
    }
  }, [defaultSelectedRepresentatives]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useMemo(() => {
    if (debouncedInputValue) {
      const userService = new UserService();
      userService.getAllUsers(debouncedInputValue).then(({ data }) => {
        if (data?.length > 0) {
          setUsers(prevUsers => mergeUniqueUsers([...prevUsers], data));
        }
      });
    }
  }, [debouncedInputValue]);
  useEffect(() => {
    setSelectedRepresentatives(Array.isArray(defaultSelectedRepresentatives) ? defaultSelectedRepresentatives : []);
  }, [defaultSelectedRepresentatives]);

  const handleSelectedRepresentativesChange = (selected: IUserProfile[]) => {
    setSelectedRepresentatives(selected);
  };

  return (
    <Stack sx={{ background: '#F0F3FF', borderRadius: '5px' }} spacing="0px" marginTop="23px">
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: '13px',
          p: '10px 10px',
        }}
      >
        Product Sales Representatives
      </Typography>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pl: '10px', pr: '13px', pb: '13px' }}
      >
        {selectedRepresentatives?.length > 0 && (
          <Stack direction="row">
            <CustomAvatarGroup spacing={16}>
              {selectedRepresentatives?.map(rep => (
                <CustomAvatar
                  key={rep.id}
                  src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${rep?.avatar}`}
                  alt={`${rep?.firstName || ''} ${rep?.lastName || ''}`}
                  sx={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '15px',
                  }}
                />
              ))}
            </CustomAvatarGroup>
            <Typography
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mt: 'auto',
                backgroundColor: '#F9FAFB',
                borderRadius: '50%',
                fontSize: '12px',
                padding: '3px',
                ml: '-10px',
                width: '22px',
                height: '22px',
                position: 'relative',
                zIndex: 10,
              }}
              variant="subtitle2"
            >
              +
              {selectedRepresentatives?.length}
            </Typography>
          </Stack>
        )}

        <IconButton
          onClick={() => setShowDialog(true)}
        >
          <Avatar src={ProductRepresentative} alt="add btn" sx={{ width: '35px', height: '35px' }} />
        </IconButton>
      </Stack>

      <AddSalesRepresentativeDialog
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        representatives={users}
        search={search}
        setSearch={setSearch}
        onSelectedRepresentativesChange={handleSelectedRepresentativesChange}
      />
    </Stack>
  );
};

export default ProductSalesRepresentative;
