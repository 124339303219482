import {
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit';

import { IPublicLoungeData } from '../../models/public-lounge/public-lounge';
import { PublicLoungeService } from '../../services/PublicLounge';

export type PublicLoungeState = {
  loading: boolean;
  value: IPublicLoungeData[];
  errorMessage: string;
};

const initialState: PublicLoungeState = {
  loading: false,
  value: [],
  errorMessage: "",
};

export const publicLoungeSlice = createSlice({
  name: "public-lounge",
  initialState,
  reducers: {
    setPublicLounges: (state, action: PayloadAction<IPublicLoungeData[]>) => {
      state.value = action.payload;
    },
  },
});

export const { setPublicLounges } = publicLoungeSlice.actions;

export const getPublicLounges = () => async (dispatch: Dispatch) => {
  const loungeService = new PublicLoungeService();
  const result = await loungeService.getPublicLounges();

  dispatch(setPublicLounges(result.data));
};
