import { useSurveyContext } from "src/mui/providers/GeneralContext";
import PAutocomplete from './PAutocomplete';

type OptionType = {
  id: string;
  name: string;
};

type PIndustryProps = {
  name: string;
  label?: any;
  disabled?: boolean;
  defaultValue?: OptionType;
  placeholder?: any;
  multiple?: boolean;
  sx?: any;
  className?: string;
};

const PIndustrySelect = ({ name, label, disabled = false, defaultValue, placeholder, multiple = false, sx ,className}: PIndustryProps) => {
  const { allIndustries } = useSurveyContext();
  return (
    <PAutocomplete
      onlySelectFromDropdown
      className={className}
      sx={sx}
      label={label}
      placeholder={placeholder}
      name={name}
      multiple={multiple}
      disabled={disabled}
      clearOnBlur
      fullWidth
      loading={allIndustries === undefined}
      filterSelectedOptions
      {...(defaultValue && { defaultValue })}
      options={allIndustries ?? []}
      getOptionLabel={(option: OptionType | string) => (option as OptionType).name ?? option}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export default PIndustrySelect;
