import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { IAccountSettings, IChangePassword } from '../../../models/user/account-settings/account-settings';
import { UserService } from '../../../services';
import { TextInput } from '../../../shared-components';
import { Button } from '../../../shared-components/V2';
import { initialise } from '../../../store/auth';
import { useCurrentUser } from '../../../utils/hooks';

export const AccountSettings = () => {
  const [fields, setFields] = useState<any>(null);
  const user = useCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialState = {
      fullName: {
        label: 'Full name',
        value: user?.fullName,
        isEditMode: false
      },
      firstName: {
        label: 'First Name',
        value: user?.firstName,
        isEditMode: false
      },
      lastName: {
        label: 'Last Name',
        value: user?.lastName,
        isEditMode: false
      },
      email: {
        label: 'Email address*',
        value: user?.email,
        isEditMode: false
      },
      mobileNumber: {
        label: 'Mobile phone*',
        value: user?.phone,
        isEditMode: false
      },
      password: {
        label: 'Password*',
        value: '••••••••••',
        isEditMode: false
      },
      currentPassword: {
        label: 'Current Password*',
        value: '',
        isEditMode: false
      },
      newPassword: {
        label: 'New Password*',
        value: '',
        isEditMode: false
      },
      confirmPassword: {
        label: 'Confirm New Password*',
        value: '',
        isEditMode: false
      }

    };
    setFields(initialState);
  }, [user]);

  const editModeHandler = (key: string) => {
    const currentFields = fields;
    currentFields[key].isEditMode = !currentFields[key].isEditMode;
    currentFields.currentPassword.value = '';
    currentFields.newPassword.value = '';
    currentFields.confirmPassword.value = '';
    setFields({
      ...currentFields
    });
  };

  const onChangeInput = (e: any, key: string) => {
    const currentFields = fields;
    if (key === 'firstName') {
      currentFields.firstName.value = e.target.value;
    } else if (key === 'lastName') {
      currentFields.lastName.value = e.target.value;
    } else if (key === 'mobileNumber') {
      currentFields.mobileNumber.value = e.target.value;
    } else if (key === 'currentPassword') {
      currentFields.currentPassword.value = e.target.value;
    } else if (key === 'newPassword') {
      currentFields.newPassword.value = e.target.value;
    } else if (key === 'confirmPassword') {
      currentFields.confirmPassword.value = e.target.value;
    } else currentFields[key] = e.target.value;
    setFields({
      ...currentFields
    });
  };

  const onSaveHandler = async (key: string) => {
    const userService = new UserService();
    if (fields.newPassword) {
      const data: IChangePassword = {
        newPassword: fields.newPassword.value, oldPassword: fields.currentPassword.value
      };
      try {
        const result = await userService.changePassword(data);
        if (result.message === 'SUCCESS') toast.success(result.message);
        else toast.error(result.message);
      } catch (e: any) {
        toast.error(e);
      }
    } else {
      const data: IAccountSettings = {
        email: '', firstName: '', lastName: '', phone: ''
      };
      data.email = fields.email.value;
      data.firstName = fields.firstName.value;
      data.lastName = fields.lastName.value;
      data.phone = fields.mobileNumber.value;
      try {
        await userService.editAccountSetting(data);
      } catch (e: any) {
        toast.error(e);
      }
      editModeHandler(key);
      dispatch(initialise());
    }
  };

  const fieldwithLabel = (key: string, label: string, value: string, isEditMode: boolean) => (
    <div
      className={classNames('w-full', {
        'border-t border-[#707070]': key === 'fullName',
        'border-t border-[#918E8E] border-opacity-80': key !== 'fullName',
      })}
    >
      <div className="flex items-end py-[15px] text-base font-normal">
        <div className="flex-1 flex flex-col whitespace-nowrap">
          <span className="text-[12px] leading-5 font-medium mb-1.5 text-[#3C3C3C]">{label}</span>
          {isEditMode ? (
            <>
              {key !== 'fullName' && key !== 'password' && key !== 'mobileNumber' && (
                <TextInput
                  type="text"
                  textinputsize="medium"
                  borderRadius="10px"
                  classes="w-full text-[16px] !px-4 text-[#3C3C3C] placeholder:text-[#3C3C3C]"
                  value={fields[key].value}
                  onChange={e => onChangeInput(e, key)}
                />
              )}
              {key === 'fullName' && (
                <div className="flex flex-row mt-[7px]">
                  <TextInput
                    type="text"
                    textinputsize="small"
                    placeholder="First name"
                    borderRadius="10px"
                    classes="w-full text-[16px] !px-4 text-[#3C3C3C] placeholder:text-[#3C3C3C] mr-5"
                    value={fields.firstName.value}
                    onChange={e => onChangeInput(e, 'firstName')}
                  />
                  <TextInput
                    type="text"
                    textinputsize="small"
                    placeholder="Last name"
                    borderRadius="10px"
                    classes="w-full text-[16px] !px-4 text-[#3C3C3C] placeholder:text-[#3C3C3C]"
                    value={fields.lastName.value}
                    onChange={e => onChangeInput(e, 'lastName')}
                  />
                </div>
              )}
              {key === 'mobileNumber' && (
                <TextInput
                  type="text"
                  textinputsize="medium"
                  borderRadius="10px"
                  classes="w-full text-[16px] !px-4 text-[#3C3C3C] placeholder:text-[#3C3C3C]"
                  value={fields.mobileNumber.value}
                  onChange={e => onChangeInput(e, key)}
                />
              )}
              {key === 'password' && (
                <div className="flex flex-row mt-[7px]">
                  <TextInput
                    type="password"
                    textinputsize="small"
                    placeholder="Current password"
                    borderRadius="10px"
                    classes="w-full mr-5 text-[16px] !px-4 text-[#3C3C3C] placeholder:text-[#3C3C3C]"
                    value={fields.currentPassword.value}
                    onChange={e => onChangeInput(e, 'currentPassword')}
                  />
                  <TextInput
                    type="password"
                    textinputsize="small"
                    placeholder="New password"
                    borderRadius="10px"
                    classes="w-full mr-5 text-[16px] !px-4 text-[#3C3C3C] placeholder:text-[#3C3C3C]"
                    value={fields.newPassword.value}
                    onChange={e => onChangeInput(e, 'newPassword')}
                  />
                  <TextInput
                    type="password"
                    textinputsize="small"
                    placeholder="Confirm password"
                    borderRadius="10px"
                    classes="w-full text-[16px] !px-4 text-[#3C3C3C] placeholder:text-[#3C3C3C]"
                    value={fields.confirmPassword.value}
                    onChange={e => onChangeInput(e, 'confirmPassword')}
                  />
                </div>
              )}
              <div className="flex flex-row gap-2 pt-[14px] justify-end">
                <Button
                  text="Cancel"
                  size="small"
                  className="bg-white hover:bg-[#CEDCF2] h-[30px] w-[88px] text-[#203C6E] tracking-wide"
                  onClick={() => editModeHandler(key)}
                />
                <Button
                  text="Save"
                  size="small"
                  className="bg-[#0049EF] hover:bg-[#244E93] h-[30px] w-[74px] text-white tracking-wide"
                  onClick={() => onSaveHandler(key)}
                />
              </div>
            </>
          ) : (
            <span className="text-[16px] font-light leading-5 text-[#3C3C3C]">{key === 'mobileNumber' && !value ? '+610444 234 234' : value}</span>
          )}
        </div>
        {(!isEditMode && key !== 'email') && (
          <span
            className="text-[15px] leading-5 inline-flex justify-center font-semibold cursor-pointer text-[#172746] hover:underline"
            onClick={() => editModeHandler(key)}
          >
            Edit
          </span>
        )}
      </div>
    </div>
  );
  return (
    <div className="max-w-[886.5px] ml-[40px] mt-[37px]" id="account-settings">
      <div className="flex flex-col w-full">
        <span className="font-semibold text-[15px] leading-[21px] mb-5 text-[#0B1221]">Account settings</span>
        {fields && (
          <>
            {fieldwithLabel(
              'fullName',
              fields.fullName.label,
              fields.fullName.value,
              fields.fullName.isEditMode
            )}
            {fieldwithLabel(
              'email',
              fields.email.label,
              fields.email.value,
              fields.email.isEditMode
            )}
            {fieldwithLabel(
              'mobileNumber',
              fields.mobileNumber.label,
              fields.mobileNumber.value,
              fields.mobileNumber.isEditMode
            )}
            {fieldwithLabel(
              'password',
              fields.password.label,
              fields.password.value,
              fields.password.isEditMode
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AccountSettings;
