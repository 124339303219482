const AddAgendaIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g id="Group_20479" data-name="Group 20479" transform="translate(-332 -897)">
      <circle id="Ellipse_1174" data-name="Ellipse 1174" cx="9" cy="9" r="9" transform="translate(332 897)" fill="#00ffcd" />
      <g id="Group_20474" data-name="Group 20474">
        <line id="Line_1405" data-name="Line 1405" x2="10" transform="translate(336 906)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
        <line id="Line_1406" data-name="Line 1406" y2="10" transform="translate(341 901)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
      </g>
    </g>
  </svg>

);
export default AddAgendaIcon;
