import { useEffect, useRef, useState } from "react";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { imageUrlPrefix } from "src/utils";
import { useDispatch, useSelector } from "react-redux";
import { getEventSponsors } from "src/store/liveEvent";
import { IAppState } from "src/store";
import companyDefault from "src/assets/images/company-default.svg";
import { getBrandingColors } from '../event/live/liveEventStyling';

type SponsorProps = {
  columnWidth: number | null;
  handleVisitBooth: (value: any) => void;
  columnCardHeight: string;
  activeList?: any;
};

export const SponsorPage = ({
  columnWidth,
  handleVisitBooth,
  columnCardHeight,
  activeList
}: SponsorProps) => {
  const dispatch = useDispatch();

  const columnRef = useRef<HTMLDivElement>(null);
  const [boxWidth, setBoxWidth] = useState<number | null>(null);
  const colors = getBrandingColors();

  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const { event, eventSponsors, booths } = liveEvent;

  useEffect(() => {
    if (columnRef.current) {
      const width = columnRef.current.offsetWidth;
      setBoxWidth(width);
    }
  }, [columnRef.current, columnWidth]);

  useEffect(() => {
    const handleResize = () => {
      if (columnRef.current) {
        const width = columnRef.current.offsetWidth - 27;
        setBoxWidth(width);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getEventSponsors(liveEvent?.event?.id!));
  }, []);

  const showSponsorCard = (sponsor: any, index: number, sponsors: any) => {
    const previousSponsor = sponsors[index - 1];
    const nextSponsor = sponsors[index + 1];
    const shouldApplyMinHeight =
      (previousSponsor &&
      booths?.some((booth) => booth.eventSponsorId === previousSponsor?.id) &&
      index % 2 !== 0) || (
        nextSponsor &&
      booths?.some((booth) => booth.eventSponsorId === nextSponsor?.id) &&
      index % 2 === 0
      )

    return (
      <Box
        ref={columnRef}
        sx={{
          width: "100%",
          display: "flex",
          direction: "column",
          borderRadius: "10px",
          background: colors?.Secondary || "#2F2F2F",
          justifyContent: "center",
          pb: "9px",
          minWidth: "153px",
          maxWidth: "153px",
          minHeight: shouldApplyMinHeight ? "128px" : "128px",
        }}
      >
        <Stack sx={{ mt: "9px" }} alignItems="center">
          <Box
            sx={{
              width: "56px",
              height: "56px",
              background: "#ffffff",
              objectFit: "contain",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
            }}
          >
            <Avatar
              src={
                sponsor?.company?.logo
                  ? `${imageUrlPrefix}/${sponsor?.company?.logo}`
                  : companyDefault
              }
              sx={{
                width: "56px",
                height: "56px",
                "&.MuiAvatar-root, & .MuiAvatar-img": {
                  minHeight: "56px",
                  objectFit: "contain",
                  borderRadius: "4px",
                  maxHeight: "56px",
                  minWidth: "56px",
                  background: !sponsor?.company?.logo ? "#edf2f5" : "",
                },
              }}
            />
          </Box>

          <Stack sx={{ alignItems: "center", marginTop: "0px", mt: "10px" }}>
            <Typography
              className="ellipsis-one-line"
              variant="body2"
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
                color: colors?.Text || "white",
                fontFamily: "Poppins-600",
                px: "10px",
                textAlign: "center",
                width: "160px"

              }}
            >
              {sponsor?.company?.name}
            </Typography>
          </Stack>
          {booths?.some((booth) => booth.eventSponsorId === sponsor?.id) && (
            <Button
              onClick={() => handleVisitBooth(sponsor)}
              sx={{
                borderRadius: "3px",
                height: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                fontFamily: "Poppins-500",
                fontWeight: 500,
                background: colors?.Accent || "#ffffff",
                opacity: "1 !important",
                color: colors?.ButtonTextColor || "#000000",
                mt: "4px",
                textTransform: "none",
                width: "80px",
                padding: "0px",
                // fontWeight: 500,
                lineHeight: "21px",
                "&:hover": { background: colors?.Accent, opacity: "0.5 !important" },
              }}
            >
              Visit Booth
            </Button>
            )}
        </Stack>
      </Box>
    );
  };

  // const isOnlySponsor = Array.isArray(activeList) && activeList.length === 4 && activeList.includes('sponsors');

  return (
    <Stack
      sx={{
        height:
          columnCardHeight === "half-height"
            ? "calc(50vh - 74px)"
            : "calc(100vh - 78px)",
        // height: columnCardHeight 'calc(100vh - 100px)',
        overflowY: "auto",
        alignItems: "start",
        flexFlow: "wrap",
        overflowX: 'hidden',
        marginTop: '19px',
        paddingRight: '14px',
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `${getBrandingColors().Accent}`,
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: `${getBrandingColors().Accent}`,
          opacity: 0.5,
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: colors?.Secondary,
        },
      }}
      className='branding-scroll'
    >

      <Stack
        // justifyContent="center"
        sx={{ flexFlow: "wrap", gap: "5px", pb: '10px', width: '100%' }}
      >
        {eventSponsors?.map((sponsor, index) => (
          <Stack>{showSponsorCard(sponsor, index, eventSponsors)}</Stack>
        ))}

      </Stack>
    </Stack>
  );
};

export default SponsorPage;
