import bulb from '../../../assets/images/compliance/bulb-icon.png';

const TableContentNinetoEleven = () => (
  <>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        Although there may be links to other websites, it does not mean we endorse or are affiliated
        with those websites in any way unless stated. As such, access and use of links and
        corresponding services is your responsibility. We hold no responsibility or liability for
        content on other websites.
      </div>
    </div>
    <div className="mt-3">
      a. Although this Platform may be linked to other websites, we are not, directly or indirectly,
      implying any approval, association, sponsorship, endorsement, or affiliation with any linked
      website, unless specifically stated herein. We are not responsible for examining or
      evaluating, and we do not warrant the offerings of, any businesses or individuals or the
      content of their websites. We do not assume any responsibility or liability for the actions,
      products, services, and content of any other third-parties. You should carefully review the
      legal statements and other conditions of use of any website which you access through a link
      from this Website. Your linking to any other off-site websites is at your own risk.
    </div>

    <hr className="my-10" />
    <div id="disclaimer-warranty" className="mt-7 text-gray-1 text-lg5">
      10. Disclaimer of warranty
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        Use of this website is at your own risk and we disclaim all warranties of any kind.
      </div>
    </div>
    <div className="mt-3">
      a. You agree that your use of our Platform or associated services is solely at your own risk.
      You agree that such Service is provided on an “as is” and “as available” basis. We expressly
      disclaim all warranties of any kind, whether express or implied, including but not limited to
      the implied or statutory warranties of merchantability, fitness for a particular purpose and
      non-infringement. We make no warranty that the Platform will meet your requirements, or that
      the Platform will be uninterrupted, timely, secure, or error-free; nor do we make any warranty
      as to the results that may be obtained from the use of the Platform or as to the accuracy or
      reliability of any information obtained through the Service or that defects in the Platform
      will be corrected. You understand and agree that any material and/or data downloaded or
      otherwise obtained through the use of Platform is done at your own discretion and risk and
      that you will be solely responsible for any damage to your computer system or loss of data
      that results from the download of such material and/or data.
    </div>
    <div className="mt-3">
      b. The scope and duration of this warranty will be the minimum permitted by law.
    </div>
    <div className="mt-3">
      c. We make no warranty regarding any goods or services purchased or obtained through the
      Platform or any transactions entered into through the Platform. No advice or information,
      whether oral or written, obtained by you from us or through the Platform shall create any
      warranty not expressly made herein.
    </div>
    <hr className="my-10" />
    <div id="liability-indemnity" className="mt-7 text-gray-1 text-lg5">
      11. Liability and Indemnity
    </div>
    <div className="w-full flex item-start bg-gray-1 p-4 rounded-5 mt-7">
      <img className="w-[24px] h-[24px] mr-3" src={bulb} alt="" />
      <div className="">
        In the limited circumstances of liability, these Clauses outline the limits to this
        liability.
      </div>
    </div>
    <div className="mt-3">
      a. You acknowledge and agree that, to the maximum extent permitted by law, the entire risk
      arising out of your access to and use of the Platform and Content, Your publishing of any
      information on Platform, use or participation in any Event or use of any other Platform,
      participation in any Third Party Payment Provider, or any other interaction you have with
      other Platform Users whether in person or online
      {' '}
      <span className="font-medium">remains with you</span>
      .
    </div>
    <div className="mt-3">
      b. Neither Panelist nor any other party involved in creating, producing, or delivering the
      Platform or Content will be liable for any incidental, special, exemplary or consequential
      damages, including lost profits, loss of data or loss of goodwill, service interruption,
      computer damage or system failure or the cost of substitute products or services, or for any
      damages for personal or bodily injury or emotional distress arising out of or in connection
      with:
    </div>
    <div className="mt-3 ml-10">i. These Terms;</div>
    <div className="mt-3 ml-10">
      ii. From the use of or inability to use the Platform or Content;
    </div>
    <div className="mt-3 ml-10">
      iii. From any communications, interactions or meetings with other Users or Hosts or other
      persons with whom You communicate, interact or meet with as a result of your use of the
      Platform;
    </div>
    <div className="mt-3 ml-10">
      iv. From your publishing or booking of an Event, including the provision or use of a Services,
      whether based on warranty, contract, tort (including negligence), product liability or any
      other legal theory, and whether or not the Platform has been informed of the possibility of
      such damage, even if a limited remedy set forth herein is found to have failed of its
      essential purpose.
    </div>
    <div className="mt-3">
      c. Except for our obligations to pay amounts to applicable suppliers pursuant to these Terms,
      in no event will the Panelist’s aggregate liability arising out of or in connection with these
      Terms and your use of the Platform including but not limited to (i) From your publishing or
      booking of any Content or holding of Events via the Platform; or (ii) From the use of or
      inability to use the Platform or Content; and (iii) in connection with any agreement, or
      interactions with any other Platform Members, exceed the amounts you have paid or owe for
      bookings via the Platform in the twelve (12) month period prior to the event giving rise to
      the liability, or if You are a User, the amounts paid by Panelist to you in the twelve (12)
      month period prior to the event giving rise to the liability, or one hundred Australian
      dollars (AUD$100), if no such payments have been made, as applicable.
    </div>
    <div className="mt-3">
      d. To the maximum extent permitted by applicable law, you agree to release, defend (at the
      Platform’s option), indemnify, and hold the Platform’s officers, directors, employees and
      agents, harmless from and against any claims, liabilities, damages, losses, and expenses,
      including without limitation, reasonable legal and accounting fees, arising out of or in any
      way connected with (i) your breach of these Terms or our Policies or Guidelines; (ii) your
      improper use of the Platform; (iii) Your interaction with any Member; (iv) participation in
      any Event including without limitation any injuries, losses or damages (whether compensatory,
      direct, incidental, consequential or otherwise) of any kind arising in connection with or as a
      result of such interaction, participation or use; (v) Your breach of any laws, regulations or
      third party rights.
    </div>
    <div className="mt-3">
      e. You will only be permitted to pursue claims against Panelist on an individual basis, not as
      a plaintiff or class member in any class or representative action or proceeding, (2) you will
      only be permitted to seek relief (including monetary, injunctive, and declaratory relief) on
      an individual basis, and (3) you may not be able to have any claims you have against us
      resolved by a jury or in a court of law.
    </div>
    <div className="mt-3">
      f. All provisions of these Terms that by their nature should survive termination of these
      Terms will survive (including, without limitation on releases, liability, indemnification
      obligations, disclaimers of warranties, agreements to arbitrate, choices of law, judicial
      forum and intellectual property protections and licenses).
    </div>
  </>
);

export default TableContentNinetoEleven;
