import classNames from "classnames";
import React, { FC, HTMLAttributes } from "react";
import { getBrandingColors } from '../../../mui/pages/event/live/liveEventStyling';

export interface IButtonWithTextAndImageProps
  extends HTMLAttributes<HTMLButtonElement> {
  imageUrl?: string;
  text: string;
  buttonClasses?: string;
  textClasses?: string;
  arrowIconClass?: string;
  isNotification?: boolean;
  notificationCount?: number;
  isLivePage?: boolean;
  onClick?: () => void;
  isRed?: boolean;
}

export const ButtonWithTextAndImage: FC<IButtonWithTextAndImageProps> = ({
  imageUrl,
  text,
  buttonClasses,
  textClasses,
  arrowIconClass,
  isNotification,
  notificationCount,
  isLivePage,
  isRed,
  onClick,
}) => {
  return (
    <button className={buttonClasses} onClick={onClick} style={{
      backgroundColor: isLivePage ? getBrandingColors()?.Accent : '',
      color: isLivePage ? getBrandingColors()?.ButtonTextColor : ''
    }}>
      <span className="flex flex-row items-center justify-center inset-0 mx-auto">
        <span className="mr-2">
          {isNotification ? (
            <span className={classNames(
              'w-5 h-5 rounded-full text-sm font-semibold flex items-center justify-center',
              {
                '!bg-[#FD2626]' : isRed,
              }
            )}>
              {notificationCount}
            </span>
          ) : (
            <img className={arrowIconClass} src={imageUrl} alt="" />
          )}
        </span>
        <span aria-label="button-target-text" className={textClasses}>
          {text}
        </span>
      </span>
    </button>
  );
};
