import { ReactComponent as LikedSvg } from "src/assets/images/post-reactions/like-emoji.svg";
import { ReactComponent as LoveSVG } from "src/assets/images/post-reactions/love-emoji.svg";
import { ReactComponent as SmileSVG } from "src/assets/images/post-reactions/smile-emoji.svg";
import { ReactComponent as WellDoneSVG } from "src/assets/images/post-reactions/well-done-emoji.svg";
import { ReactComponent as HahaSVG } from "src/assets/images/post-reactions/haha-emoji.svg";

export const VideoActiveBothHalfHeight = "calc(50vh - 11px)"
export const CardActiveBothFullHeight = "calc(100vh - 93px)"
export const CardActiveBoothHalfHeight = "calc(50vh - 92px)"
export const CardFullHeight = "calc(100vh - 22px)"
export const CardHalfHeight = "calc(50vh - 11px)"
export const CoreCapabilityHeight = "calc(57vh - 150px)"

export const Active4CardsHalfHeight = "calc(50vh - 22px)"

export const MatchCardsHalfHeight = "calc(50vh - 66px)"
export const MatchCardsFullHeight = "calc(100vh - 66px)"

export const iconMap: Record<string, React.FC | React.ElementType> = {
    likes: LikedSvg,
    hahas: HahaSVG,
    loves: LoveSVG,
    claps: WellDoneSVG,
    smiles: SmileSVG,
  };
  
export const reactions = ['likes', 'hahas', 'loves', 'claps', 'smiles'];