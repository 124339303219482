/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import { Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { PSlider, RHFAutocomplete, RHFTextField } from 'src/mui/components/hook-form';
import { useSurveyContext } from 'src/mui/providers/GeneralContext';
import PEditor from 'src/mui/components/hook-form/PEditor';
import PInterestSelect, { OptionType } from 'src/mui/components/hook-form/PInterestSelect';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import PButtonGrid from 'src/mui/components/hook-form/PButtonGrid';
import { targetFunctionsOptions } from 'src/constants';
import { useFormContext } from 'react-hook-form';
import PCompanyIndustrySelect from 'src/mui/components/hook-form/PCompanyIndustrySelect';
import companyInfoIcon from '../../../../assets/images/company/info-icon.svg';
import companyTickIcon from '../../../../assets/images/company/tick-icon.svg';
import { timelineScale } from '../company-enum';
import ProductIcon from './add-product-icon';

export const CompanyProductForm = () => {
  const { companyProfilePreview: companyProfile, companyEmployees } = useSelector((state: IAppState) => state);

  const { getAllIndustries } = useSurveyContext();
  const { setValue, watch } = useFormContext();
  const capabilitiesValue = watch('capabilities') || [];
  const industriesValue = watch('industries') || [];

  useEffect(() => { getAllIndustries(); }, []);

  const renderTextFeild = (title: string, name: string, isRequired?: boolean) => (

    <Stack sx={{ width: '100%' }}>
      <Stack direction="row" columnGap="10px" sx={{ width: '100%', mb: '8px' }}>
        <Stack sx={{ width: '18px', height: '18px' }}>
          <img src={companyInfoIcon} alt="" style={{ width: '18px', height: '18px' }} />
        </Stack>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '13px', fontWeight: 600, lineHeight: '20px', fontFamily: 'Poppins-600'
          }}
        >
          {title}
          {isRequired && <span className="text-[#FF0000]">*</span>}
        </Typography>
      </Stack>
      <RHFTextField name={name} className="dropdown-container" />
    </Stack>
  );
  const renderTextUrlFeild = (title: string, name: string, isRequired?: boolean) => (

    <Stack sx={{ width: '100%' }}>
      <Stack direction="row" columnGap="10px" sx={{ width: '100%', mb: '8px' }}>
        <Stack sx={{ width: '18px', height: '18px' }}>
          <img src={companyInfoIcon} alt="" style={{ width: '18px', height: '18px' }} />
        </Stack>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '13px', fontWeight: 600, lineHeight: '20px', fontFamily: 'Poppins-600'
          }}
        >
          {title}
          {isRequired && <span className="text-[#FF0000]">*</span>}
        </Typography>
      </Stack>
      <RHFTextField name={name} className="dropdown-container" />
    </Stack>
  );

  const renderRichTextFeild = (title: string, name: string, isRequired?: boolean, id?: string) => (
    <Stack sx={{ width: '100%' }} className="editor-container" id={id}>
      <Stack direction="row" columnGap="10px" sx={{ width: '100%', mb: '8px' }}>
        <Stack sx={{ width: '18px', height: '18px' }}>
          <img src={companyInfoIcon} alt="" style={{ width: '18px', height: '18px' }} />
        </Stack>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500'
          }}
        >
          {title}
          {isRequired && <span className="text-[#FF0000]">*</span>}
        </Typography>
      </Stack>
      {/* <RHFEditor name={name} /> */}
      {/* <RichTextEditor {...register('description')} value={companyDescription} onChange={handleAboutCompany} label="Description" /> */}
      <PEditor
        label="Description"
        simple
        // simpleExtras
        name={name}
      />
    </Stack>
  );

  const renderIndustryFeild = (title: string, name: string, isRequired?: boolean,) => (
    <Stack sx={{ width: '100%' }}>
      <Stack direction="row" columnGap="10px" sx={{ width: '100%', mb: '8px' }}>
        <Stack sx={{ width: '18px', height: '18px' }}>
          <img src={isRequired ? companyTickIcon : companyInfoIcon} alt="" style={{ width: '18px', height: '18px' }} />
        </Stack>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '13px', fontWeight: 600, lineHeight: '20px', fontFamily: 'Poppins-600'
          }}
        >
          {title}
          {isRequired && <span className="text-[#FF0000]">*</span>}
        </Typography>
      </Stack>
      <PCompanyIndustrySelect name={name} defaultFormValue={industriesValue} className="dropdownRoot" multiple />
    </Stack>
  );

  const renderCoreCapabilities = (title: string, name: string) => (
    <Stack sx={{ width: '100%' }}>
      <Stack direction="row" columnGap="10px" sx={{ width: '100%', mb: '8px' }}>
        <Stack sx={{ width: '18px', height: '18px' }}>
          <img src={companyTickIcon} alt="" style={{ width: '18px', height: '18px' }} />
        </Stack>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '13px', fontWeight: 600, lineHeight: '20px', fontFamily: 'Poppins-600'
          }}
        >
          {title}
        </Typography>
      </Stack>
      <PInterestSelect
        name={name}
        className="dropdownRoot"
        multiple
        clearOnBlur
        noOptionsText="Please try another capability"
        defaultFormValue={capabilitiesValue || []}
        selectedData={companyProfile.value?.companySpecialities!}
        onChange={(value: OptionType[]) => setValue('capabilities', value)}
      />
    </Stack>
  );

  const renderKeywordFeild = (title: string, name: string, isRequired?: boolean,) => (
    <Stack sx={{ width: '100%' }}>
      <Stack direction="row" columnGap="10px" sx={{ width: '100%', mb: '8px' }}>
        <Stack sx={{ width: '18px', height: '18px' }}>
          <img src={isRequired ? companyTickIcon : companyInfoIcon} alt="" style={{ width: '18px', height: '18px' }} />
        </Stack>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '13px', fontWeight: 600, lineHeight: '20px', fontFamily: 'Poppins-600'
          }}
        >
          {title}
          {isRequired && <span className="text-[#FF0000]">*</span>}
        </Typography>
      </Stack>
      <RHFAutocomplete
        name={name}
        multiple
        freeSolo
        options={[]}
        ChipProps={{ size: 'small' }}
        className="dropdownRoot"
      />
    </Stack>
  );

  const renderSliderFeild = (title: string, name: string, isRequired?: boolean) => (
    <Stack sx={{ width: '100%' }}>
      <Stack direction="row" columnGap="10px" sx={{ width: '100%', mb: '8px' }}>
        <Stack sx={{ width: '18px', height: '18px' }}>
          <img src={companyTickIcon} alt="" style={{ width: '18px', height: '18px' }} />
        </Stack>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '13px', fontWeight: 600, lineHeight: '20px', fontFamily: 'Poppins-600'
          }}
        >
          {title}
        </Typography>
      </Stack>
      <Stack
        sx={{
          backgroundColor: '#F9FAFB', height: '64px', borderRadius: '5px', display: 'flex', justifyContent: 'center', px: '15px', pt: '7px'
        }}
        className="slider-container"
      >
        <PSlider
          size="medium"
          name={name}
          min={timelineScale[1].value}
          max={timelineScale[timelineScale.length - 1]?.value}
          step={null}
          marks={timelineScale}
          aria-labelledby="size-input-slider"
          valueLabelDisplay="auto"
        />
      </Stack>
    </Stack>
  );

  const renderPersonaInterest = (title: string, name: string) => (
    <Stack sx={{ width: '100%' }}>
      <Stack direction="row" columnGap="10px" sx={{ width: '100%', mb: '8px' }}>
        <Stack sx={{ width: '18px', height: '18px' }}>
          <img src={companyTickIcon} alt="" style={{ width: '18px', height: '18px' }} />
        </Stack>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '13px', fontWeight: 600, lineHeight: '20px', fontFamily: 'Poppins-600'
          }}
        >
          {title}
        </Typography>
      </Stack>
      <RHFAutocomplete
        name={name}
        multiple
        freeSolo
        options={[]}
        ChipProps={{ size: 'small' }}
        className="dropdownRoot"
      />
    </Stack>
  );

  const renderButtonGrid = (title: string, name: string, options: any[]) => (
    <Stack sx={{ width: '100%' }}>
      <Stack direction="row" columnGap="10px" sx={{ width: '100%', mb: '8px' }}>
        <Stack sx={{ width: '18px', height: '18px' }}>
          <img src={companyTickIcon} alt="" style={{ width: '18px', height: '18px' }} />
        </Stack>
        <Typography
          color="text.primary"
          variant="subtitle2"
          sx={{
            fontSize: '13px', fontWeight: 600, lineHeight: '20px', fontFamily: 'Poppins-600'
          }}
        >
          {title}
        </Typography>
      </Stack>
      <Grid container spacing="5px" sx={{ mt: '0px' }}>
        <PButtonGrid
          name={name}
          options={options}
        />
      </Grid>
    </Stack>
  );

  return (
    <Stack rowGap="15px">
      {renderTextFeild('Product name', 'productName', true)}
      {renderTextFeild('Tagline', 'tagLine')}
      <Stack sx={{ width: '100%' }} id="logo-and-photo">
        <ProductIcon />
      </Stack>
      {renderRichTextFeild('Promotional Text', 'promotionalText', false, 'product-info')}
      {renderRichTextFeild('Description', 'description')}
      {renderKeywordFeild('Keywords', 'keywords')}
      <Stack sx={{
        widht: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', gap: '5px'
      }}
      >
        {renderTextUrlFeild('Support URL', 'supportUrl')}
        {renderTextUrlFeild('Marketing URL', 'marketingUrl')}
        {renderTextFeild('Copyright(Entity name)', 'copyrightName')}
      </Stack>
      {renderCoreCapabilities('Core capabilities', 'companySpecialities')}
      {renderKeywordFeild('Integrations', 'integrations')}
      {renderSliderFeild('I aim for customers to make purchases in the above technologies within the next', 'timeline')}
      {renderIndustryFeild('Industries', 'industries', true)}
      {renderPersonaInterest('Target persona (type keywords for job titles you target, i.e. Chief Information Officer, CIO)', 'targetPersona')}
      {renderButtonGrid('Functions', 'functions', targetFunctionsOptions)}
    </Stack>
  );
};

export default CompanyProductForm;
