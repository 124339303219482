import { SvgIconProps } from '@mui/material/SvgIcon';

export const VolumUpIcon = (props: SvgIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.776" height="16.443" viewBox="0 0 17.776 16.443" {...props}>
      <path id="Path_343" data-name="Path 343" d="M2.222,3.777A2.182,2.182,0,0,0,0,6v4.444a2.182,2.182,0,0,0,2.222,2.222H4.148l4.74,3.777V0L4.148,3.777ZM14.813,8.221A6.129,6.129,0,0,0,13.11,4a.733.733,0,0,0-1.037,1.037,4.658,4.658,0,0,1,0,6.37.716.716,0,0,0,0,1.037.716.716,0,0,0,1.037,0A6.129,6.129,0,0,0,14.813,8.221Zm-1.037-8A.8.8,0,0,0,12.74.37a.8.8,0,0,0,.148,1.037,8.518,8.518,0,0,1,0,13.628.8.8,0,0,0-.148,1.037.674.674,0,0,0,.593.3,1.053,1.053,0,0,0,.444-.148,10,10,0,0,0,0-16Z" fill="#fff"/>
    </svg>

  );
};
