import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import { ReactComponent as ImageIconUrl } from "../../../assets/images/post-attach-image-icon-new.svg";
import { ReactComponent as VideoIconUrl } from "../../../assets/images/post-attach-video-icon-new.svg";
import { ReactComponent as DocumentIconUrl } from "../../../assets/images/post-attach-document-icon-new.svg";
import { ReactComponent as PollIconUrl } from "../../../assets/images/post-attach-poll-icon-new.svg";
import { ReactComponent as EventIconUrl } from "../../../assets/images/post-attach-event-icon.svg";
import AlertModal from "../../../components/alert-modal/alert-modal";
import { ICreatePostProps } from "../../../models/post/create-post-props";
import { Typography } from "../../../shared-components";
import { Avatar, Button } from "../../../shared-components/V2";
import { IAppState } from "../../../store";
import { ConditionalLink } from "../../conditional-link";
import CreatePoll from "../../poll/create-poll";
import { CreatePostModal } from "./create-post-modal";
import classNames from "classnames";

export const CreatePost = (props: ICreatePostProps) => {
  const fileSizeLimit = parseInt(process.env.REACT_APP_FILE_UPLOAD_LIMIT_MB ?? '25');
  const isModalOpen = useSelector((state: IAppState) => state.isModalOpen);
  const [toggleModal, setToggleModal] = useState(false);

  const [togglePollModal, setTogglePollModal] = useState(false);
  const onClosePollModal = () => {
    setTogglePollModal(!togglePollModal);
  };

  const removeHtmlStyle = () => {
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
      htmlElement.removeAttribute('style');
    }
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (isModalOpen?.isOpen === false) {
      timeoutId = setTimeout(removeHtmlStyle, 2000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isModalOpen]);

  useEffect(() => {
    setToggleModal(isModalOpen.isOpen);
    setTogglePollModal(isModalOpen.isOpen);
  }, [isModalOpen]);

  const imageUploadRef = useRef<HTMLInputElement | null>(null);
  const videoUploadRef = useRef<HTMLInputElement | null>(null);
  const [selectedImageFileUrl, setSelectedImageFileUrl] = useState("");

  const documentUploadRef = useRef<HTMLInputElement | null>(null);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState("");

  const [isFileAttached, setIsFileAttached] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FormData>();
  const [selectedFileType, setSelectedFileType] = useState("");
  const [hasFileSizeError, setHasFileSizeError] = useState<boolean>();

  const onCloseModal = () => {
    props?.addNewStory == "story" && toggleModal && props?.setAddNewStory && props.setAddNewStory(prev => null);
    setIsFileAttached(false);
    setToggleModal(!toggleModal);
    setSelectedFile(undefined);
    setSelectedFileType("");
    setSelectedDocumentUrl("");
    setSelectedImageFileUrl("");
  };

  const onCloseStoryModal = () => {
    // props?.addNewStory == "story" && props?.setAddNewStory && props.setAddNewStory(prev => null);
    setIsFileAttached(false);
    setToggleModal(!toggleModal);
    setSelectedFile(undefined);
    setSelectedFileType("");
    setSelectedDocumentUrl("");
    setSelectedImageFileUrl("");
  };

  const handleImageSelected = (files: FileList) => {
    const fileSize = files[0].size / (1024 ** 2);
    if (fileSize > fileSizeLimit) {
      setHasFileSizeError(true);
      return;
    }

    const data = new FormData();
    const fileUrl = URL.createObjectURL(files[0]);
    const fileType = files[0].type.includes("video");

    if (fileType) {
      setSelectedFileType("video");
    } else {
      setSelectedFileType("image");
    }
    data.append("file", files[0], files[0].name);
    setSelectedFile(data);
    setSelectedImageFileUrl(fileUrl);
    setIsFileAttached(true);

    setToggleModal(true);
  };

  const handleDocumentSelected = (files: FileList) => {
    const fileSize = files[0].size / (1024 ** 2);
    if (fileSize > fileSizeLimit) {
      setHasFileSizeError(true);
      return;
    }

    const data = new FormData();
    data.append("file", files[0], files[0].name);
    const fileUrl = URL.createObjectURL(files[0]);

    setSelectedFile(data);
    setSelectedDocumentUrl(fileUrl);
    setSelectedFileType("document");
    setIsFileAttached(true);

    setToggleModal(true);
  };

  useEffect(() => {
    if (props?.addNewStory === "story") onCloseModal();
  }, [props?.addNewStory])

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    const url = `${process.env.REACT_APP_EVENT_PORTAL_URL}/events?createEvent=true`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="bg-white create-post-card w-full">
      <div className="px-[11px] pt-[12px] pb-0">
        <div className="w-full flex items-center">
          <div className="!w-[40px] !h-[40px]">
            <ConditionalLink to={props.detailsHref}>
              <Avatar
                src={props.avatarUrl}
                // size="MEDIUM"
                className={classNames("border border-[#edf2f5] !w-[40px] !h-[40px] !object-contain", {
                  // "!object-contain": !props.isHomeCreatePost,
                  "object-cover": props.isHomeCreatePost,
                  "!p-0 !object-cover !rounded-10": props.location === "profile" || props.location === "event",
                  "!rounded-[6px]": props.location === "company"
                })}
              />
            </ConditionalLink>
          </div>
          <div className="ml-[5px] w-full">
            <Button
              text="Create a post…"
              className="bg-[#EDF2F5] hover:bg-[#DFE5EB] text-[#000000] leading-3 text-[15px] !font-light w-full h-10 pl-[10px] !rounded-[5px] text-left transition-all"
              onClick={() => { onCloseModal(); props?.setAddNewStory && props.setAddNewStory(prev => null); }}
            />
            {toggleModal && (
              <>
                <AlertModal
                  onClose={onCloseModal}
                  isOpen={toggleModal}
                  className={classNames("w-[462px] pt-[14px] pb-[12px]", {
                    [`${props.modalWidth}`]: props.modalWidth,
                  })}
                  closeIconClass=" right-[18px] top-[15px] px-0"
                  isCloseIcon
                >
                  <CreatePostModal
                    wallId={props.wallId}
                    location={props.location}
                    detailsHref={props.detailsHref}
                    avatarUrl={props.avatarUrl}
                    userDisplayName={props.userDisplayName}
                    selectedFile={selectedFile}
                    modalWidth={props.modalWidth}
                    selectedFileType={selectedFileType}
                    selectedFileUrl={
                      selectedFileType === "image" ||
                        selectedFileType === "video"
                        ? selectedImageFileUrl
                        : selectedDocumentUrl
                    }
                    onAfterSave={props.onAfterSave}
                    onClose={onCloseModal}
                    image
                    video
                    document
                    poll
                    addNewStory={props?.addNewStory}
                  />
                </AlertModal>
              </>
            )}
            {togglePollModal && (
              <>
                <AlertModal
                  className="w-576"
                  onClose={onClosePollModal}
                  isOpen={togglePollModal}
                >
                  <CreatePoll
                    isOpenedDirectly
                    toggleCreatePostModal={() => setToggleModal(true)}
                    onClose={onClosePollModal}
                  />
                </AlertModal>
              </>
            )}
          </div>
        </div>
        <div className=" h-[1px] bg-[#CDDCF2] rounded-10 mt-[14px] mb-[9px]" />
        <form className="flex justify-between pb-3 flex-wrap lg:justify-start xl:justify-between">
          <div
            className={`flex items-center justify-center cursor-pointer h-[32px] rounded-[5px] sm:px-[25px] hover:bg-[#EDF2F5] ${!isFileAttached && "hover-box"
              } relative`}
            onClick={() => !isFileAttached && imageUploadRef?.current?.click()}
          >
            <ImageIconUrl className=" sm:max-w-none max-w-[22px]" />
            <Typography variant="SubTitle" text="Image" classes="pl-[5px] text-[13px] leading-[17px] font-medium text-[#414141]" />
            <input
              type="file"
              id="imageFile"
              name="imageFile"
              ref={imageUploadRef}
              className="hidden"
              accept="image/*"
              onChange={(e) => handleImageSelected(e.target?.files!)}
            />
          </div>
          <div
            className={`flex items-center cursor-pointer rounded-[5px] sm:px-[25px] hover:bg-[#EDF2F5] ${!isFileAttached && "hover-box"
              } relative`}
            onClick={() => !isFileAttached && videoUploadRef?.current?.click()}
          >
            <VideoIconUrl className=" sm:max-w-none max-w-[22px]" />
            <Typography variant="SubTitle" text="Video" classes="pl-[5px] text-[13px] leading-[17px] font-medium text-[#414141]" />
            <input
              type="file"
              id="videoFile"
              name="videoFile"
              ref={videoUploadRef}
              className="hidden"
              accept="video/*"
              onChange={(e) => handleImageSelected(e.target?.files!)}
            />
          </div>
          <div
            className={`flex items-center cursor-pointer rounded-[5px] sm:px-[25px] hover:bg-[#EDF2F5] ${!isFileAttached && "hover-box"
              } relative`}
            onClick={() =>
              !isFileAttached && documentUploadRef?.current?.click()
            }
          >
            <DocumentIconUrl className=" sm:max-w-none max-w-[22px]" />
            <Typography variant="SubTitle" text="Document" classes="pl-[5px] text-[13px] leading-[17px] font-medium text-[#414141]" />
            <input
              type="file"
              id="documentFile"
              name="documentFile"
              ref={documentUploadRef}
              className="hidden"
              accept="application/pdf"
              onChange={(e) => handleDocumentSelected(e.target?.files!)}
            />
          </div>
          <div
            className={`flex items-center cursor-pointer rounded-[5px] sm:px-[25px] hover:bg-[#EDF2F5] ${!isFileAttached && "hover-box"
              } relative`}
            onClick={handleClick}
          >
            <EventIconUrl className=" sm:max-w-none max-w-[22px]" />
            <Typography variant="SubTitle" text="Event" classes="pl-[5px] text-[13px] leading-[17px] font-medium text-[#414141]" />
          </div>
          <div
            className="flex items-center cursor-pointer rounded-[5px] sm:px-[25px] hover:bg-[#EDF2F5]"
            onClick={onClosePollModal}
          >
            <PollIconUrl className=" sm:max-w-none max-w-[22px]" />
            <Typography variant="SubTitle" text="Poll" classes="pl-[5px] pr-0 text-[13px] leading-[17px] font-medium text-[#414141] " />
          </div>
        </form>
      </div>
      {
        hasFileSizeError && <AlertModal onClose={() => setHasFileSizeError(false)} isOpen={hasFileSizeError} className="w-576">
          <>
            <div className='w-full h-full my-10 flex justify-center'>
              <div>File size must be less than {fileSizeLimit} mb</div>
            </div>
            <div className='mt-4'>
              <div className='flex flex-row justify-end px-3 pt-3 border-t-1 border-gray-16'>
                <div className=''>
                  <Button
                    text="Ok"
                    type='submit'
                    className='rounded-5 inline-block text-sm font-semibold h-8 px-6 py-1.5 bg-blue hover:bg-blue-3 text-white w-28'
                    onClick={() => setHasFileSizeError(false)}
                  />
                </div>
              </div>
            </div>
          </>
        </AlertModal>
      }
    </div>
  );
};

export default CreatePost;
