export interface ISearchIcon {
    className?: string;
}
export const SearchIcon = ({ className }: ISearchIcon) => {
    return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="14.599" height="14.942" viewBox="0 0 14.599 14.942">
    <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(0.5 0.5)">
      <path id="Path_635" data-name="Path 635" d="M16.4,10.6a6.03,6.03,0,0,1-5.952,6.1A6.03,6.03,0,0,1,4.5,10.6a6.03,6.03,0,0,1,5.952-6.1A6.03,6.03,0,0,1,16.4,10.6Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#203c6e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      <path id="Path_636" data-name="Path 636" d="M28.211,28.294l-3.236-3.319" transform="translate(-14.82 -14.559)" fill="none" stroke="#203c6e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
    </g>
  </svg>
}

