import { ReactComponent as BusinessSVG } from './assets/images/event/category/business-to-business.svg';
import { ReactComponent as CurrentWorldSVG } from './assets/images/event/category/current-world-affairs.svg';
import { ReactComponent as EcommerceSVG } from './assets/images/event/category/ecommerce.svg';
import { ReactComponent as FamilySVG } from './assets/images/event/category/family.svg';
import { ReactComponent as FintechSVG } from './assets/images/event/category/fintech.svg';
import { ReactComponent as HealthSVG } from './assets/images/event/category/health.svg';
import { ReactComponent as InsightSVG } from './assets/images/event/category/insight.svg';
import { ReactComponent as LeadershipSVG } from './assets/images/event/category/leadership.svg';
import { ReactComponent as LifeAndWorkSVG } from './assets/images/event/category/life-and-work.svg';
import { ReactComponent as ProcessSVG } from './assets/images/event/category/process.svg';
import { ReactComponent as StartUpSVG } from './assets/images/event/category/start-up.svg';
import { ReactComponent as TalentSVG } from './assets/images/event/category/talent.svg';
import { ReactComponent as TechSVG } from './assets/images/event/category/tech.svg';
import { ReactComponent as ArtSVG } from './assets/images/event/category/art.svg';

interface IMap {
  [key: string]: string;
}

export const ONE_TIME_LINK: IMap = {
  WE_JUST_EMAILED_A_LINK:
    "We've just emailed a one-time link to j*****@sit-down.com.au",
  CLICK_ON_THE_LINK: 'Click on the link to sign in instantly',
  TO_YOUR_PANELIST_ACCOUNT: 'to your Panelist account.',
};

export const EVENT_ABOUT_CARD: IMap = {
  EVENT_NAME: 'Event name',
  EVENT_THEME: 'Theme',
  EVENT_OVERVIEW: 'Overview',
  EVENT_KEY_DISCUSSION_POINTS: 'Key discussion points',
  EVENT_ATTENDING_JOB_TITLES: 'Attending job titles',
  EVENT_ATTENDING_INSUSTRIES: 'Attending industries',
  // Corry - fix text initial 27/04
};

export const POST_ACTIONS: IMap = {
  POST_HIDDEN: "You won't see this post in your feed anymore.",
  POST_UNFOLLOWED: 'The notifications for this post have been turned off.',
  POST_REPORTED:
    'Thank you for reporting this. <br /> We appreciate you letting us know.',
  SHARE_POST_SUCCESS: 'Your post is now shared on your wall',
};

export const LOGIN_SIGNUP_RECOVERY: IMap = {
  EMAILADDRESS_ISREQUIRED: 'Email address is required',
  VALID_EMAILADDRESS: 'Enter a valid email address',
  PASSWORD_ISREQUIRED: 'Enter your password',
  VALIDPASSWORD: 'Password containing at least 8 characters',
  CONFIRMPASSWORD_ISREQUIRED: 'Confirm your password',
  MATCHPASSWORD: 'password do not match',
  CODE_ISREQUIRED: 'Code is required',
  NEWPASSWORD_ISREQUIRED: 'Enter your new password',
  CONFIRMNEWPASSWORD_ISREQUIRED: 'Confirm your new password',
  FIRSTNAME_ISREQUIRED: 'Enter your first name',
  VALID_FIRSTNAME: 'Enter a valid first name',
  LASTNAME_ISREQUIRED: 'Enter your last name',
  VALID_LASTNAME: 'Enter a valid last name',
  CITY_ISREQUIRED: 'Enter your location',
  POSTCODE_ISREQUIRED: 'Enter your post code',
  VALID_POSTCODE: 'Enter a valid post code',
  COMPANY_ISREQUIRED: 'Enter your most recent company',
  INDUSTRY_ISREQUIRED: 'Enter your industry',
  JOBFUNCTION_ISREQUIRED: 'Enter your job function',
  JOBTITLE_ISREQUIRED: 'Enter your most recent job title',
  FIELD_ISREQUIRED: 'Enter your ',
  TYPE_OF_IMAGE: 'Type of your file is not image',
  LOGIN_TO_CONTINUE: 'Login to continue',
  LOGIN_SUCCESSFULL:
    'Your email is associated with an account, If you do not remember your password, ',
  LOGIN_ERROR: "That's not the right password. Try again. ",
  INVALID_CODE: 'The code you entered is invalid',
  RECAPTCHA_ERROR: 'Please verify that you are a human',
  ALREADY_REGISTERED: 'You have already registered for this event.',
  INTERESTED_OFFER_SEARCHBOX: 'Search for an offering, service or technology',
};

export const EVENT_INVITATION: IMap = {
  INVITATION_SENT_SUCCESSFULLY: 'Invitation sent successfully',
};

export const NAV_MENU: IMap = {
  NO_MESSAGES: "You don't have any messages yet!",
};

export const JOIN_TO_EVENT: IMap = {
  REGISTRATION_ACCEPTED_NOTIFICATION:
    "We'll notify you once your registration has been accepted",
  REGISTERED: 'You are now registered for this event',
};

export const LIVE_EVENT: IMap = {
  EMPTY_MESSAGES: "You don't have any messages yet",
};

export const EVENT: IMap = {
  COPY: 'Link copied to clipboard',
};

export const CREATE_POLL: IMap = {
  QUESTION_REQUIRED: 'Your question is required!',
  OPTION_VALUE_REQUIRED: 'Option is required!',
};

// Configurable constants
export const EVENTS_PER_ROW: number = 4;
export const SIMILAR_EVENTS_PER_ROW: number = 2;
export const COMMENTS_LIMIT: number = 10;

export const NOTIFICATIONS: IMap = {
  'personal-shared-post-and-comment': 'Personal shared post and comment',
  'tagged-post-and-comment': 'Tagged post and comment',
  'own-post-and-comment-activity': 'Own post and comment activity',
  'new-connection': 'New connection',
  'connection-accepted': 'Connection accepted',
  'company-shared-post-and-comment': 'Company shared post and comment',
  'connection-rejected': 'Connection rejected',
  'attending-event-update': 'Attending event update',
  'hosting-event-update': 'Hosting event update',
};

export const PRIVACY_SETTINGS: IMap = {
  see_other_post_my_profile: 'See other post my profile',
  view_contact: 'View contact',
  view_connection: 'View connection',
  view_profile: 'View profile',
  post_my_profile: 'Post my profile',
  comment_post: 'Comment post',
};

export const EVENT_CATEGORY: any[] = [
  { icon: TechSVG, label: 'Tech', shortName: 'tech' },
  { icon: BusinessSVG, label: 'Business-to-Business', shortName: 'b2b' },
  { icon: FintechSVG, label: 'Fintech & Crypto Currency', shortName: 'facc' },
  { icon: EcommerceSVG, label: 'eCommerce', shortName: 'eCommerce' },
  { icon: ProcessSVG, label: 'Process & Workflow', shortName: 'paw' },
  { icon: HealthSVG, label: 'Health & Wellness', shortName: 'haw' },
  { icon: CurrentWorldSVG, label: 'Current World affairs', shortName: 'cwa' },
  { icon: LifeAndWorkSVG, label: 'Life & Work', shortName: 'law' },
  { icon: InsightSVG, label: 'Insights & Knowledge', shortName: 'iak' },
  { icon: StartUpSVG, label: 'Start-up', shortName: 'startup' },
  { icon: FamilySVG, label: 'Family', shortName: 'family' },
  { icon: ArtSVG, label: 'Arts & Craftsmanship', shortName: 'aac' },
  { icon: LeadershipSVG, label: 'Leadership & Management', shortName: 'lam' },
  { icon: TalentSVG, label: 'Talent & Culture', shortName: 'tac' },
];

export const DEFAULT_REGISTRATION_FIELDS: IMap = {
  FIRST_NAME: 'first-name',
  LAST_NAME: 'last-name',
  EMAIL: 'email',
  ADDRESS: 'address',
  JOB_TITLE: 'job-title',
  COMPANY: 'company',
  INDUSTRY: 'industry',
  POSTAL_CODE: 'postal-code',
  BUSINESS_EMAIL: 'business-email',
};

export const targetFunctionsOptions = [
  { id: 'Accounting & Admin', name: 'Accounting & Admin' },
  { id: 'Sales', name: 'Sales' },
  { id: 'Back Office', name: 'Back Office' },
  { id: 'Human Resources', name: 'Human Resources' },
  { id: 'Procurement', name: 'Procurement' },
  { id: 'Marketing & PR', name: 'Marketing & PR' },
  { id: 'Product & Production', name: 'Product & Production' },
  { id: 'Research', name: 'Research' },
  { id: 'IT', name: 'IT' },
  { id: 'Data & Analytics', name: 'Data & Analytics' },
  { id: 'IT Security', name: 'IT Security' },
  { id: 'Service & CX', name: 'Service & CX' },
  { id: 'DevOps', name: 'DevOps' },
  { id: 'Finance', name: 'Finance' },
  { id: 'Legal', name: 'Legal' },
];
