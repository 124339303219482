import React from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface ISponsorSvgIcon {
  isActive?: boolean;
}

export const SponsorSvgIconActive = ({ isActive }: ISponsorSvgIcon) => {
    const colors = getBrandingColors();
    const iconColor = isActive ? (colors?.Accent || '#03FFCD') : (colors?.Accent || '#66686c');

    return isActive ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="29"
            viewBox="0 0 29.03 28.137"
        >
            <g id="Group_20598" data-name="Group 20598" transform="translate(0 0)">
                <path id="Path_14622" d="M16.231,14.378h7.654a1.367,1.367,0,0,0,.517-.1A1.334,1.334,0,0,0,24.825,14a1.282,1.282,0,0,0,.285-.407,1.239,1.239,0,0,0,.1-.5V11.221a1.232,1.232,0,0,0-.1-.5,1.276,1.276,0,0,0-.283-.4,1.328,1.328,0,0,0-.42-.273,1.359,1.359,0,0,0-.514-.1H16.231a2.731,2.731,0,0,1-1.033-.2,2.664,2.664,0,0,1-.844-.549,2.56,2.56,0,0,1-.569-.814,2.476,2.476,0,0,1-.208-1V1.275a1.232,1.232,0,0,0-.1-.5,1.276,1.276,0,0,0-.287-.4A1.328,1.328,0,0,0,12.77.1a1.359,1.359,0,0,0-.514-.1h-.872a1.371,1.371,0,0,0-.519.1,1.34,1.34,0,0,0-.424.276,1.287,1.287,0,0,0-.286.409,1.243,1.243,0,0,0-.1.5V4.322a2.476,2.476,0,0,1-.208,1,2.56,2.56,0,0,1-.569.814,2.664,2.664,0,0,1-.844.549,2.731,2.731,0,0,1-1.033.2H1.327a1.366,1.366,0,0,0-.517.1,1.332,1.332,0,0,0-.422.274A1.28,1.28,0,0,0,.1,7.665a1.238,1.238,0,0,0-.1.5v2.37a1.241,1.241,0,0,0,.1.5,1.287,1.287,0,0,0,.286.409,1.34,1.34,0,0,0,.424.276,1.368,1.368,0,0,0,.518.1H8.983a2.731,2.731,0,0,1,1.033.2,2.664,2.664,0,0,1,.844.549,2.56,2.56,0,0,1,.569.814,2.476,2.476,0,0,1,.208,1v8.079a.567.567,0,0,0,.048.228.586.586,0,0,0,.13.186.61.61,0,0,0,.193.126.625.625,0,0,0,.237.046,1.372,1.372,0,0,0,.519-.1,1.337,1.337,0,0,0,.423-.275,1.284,1.284,0,0,0,.285-.409,1.244,1.244,0,0,0,.1-.5v-4.82a2.476,2.476,0,0,1,.208-1,2.56,2.56,0,0,1,.569-.814,2.664,2.664,0,0,1,.844-.549,2.731,2.731,0,0,1,1.033-.2" transform="translate(1.952 2.984)" fill={iconColor} stroke={colors.Main} strokeWidth="1"/>
                <path id="Path_14623" d="M2.308,0H8.739a2.476,2.476,0,0,1,.9.167,2.332,2.332,0,0,1,.734.455,2.125,2.125,0,0,1,.494.674,1.969,1.969,0,0,1,.181.826V6.764a1.97,1.97,0,0,1-.181.826,2.123,2.123,0,0,1-.494.674,2.331,2.331,0,0,1-.734.454,2.478,2.478,0,0,1-.9.167H2.308a2.476,2.476,0,0,1-.9-.167,2.333,2.333,0,0,1-.734-.454A2.124,2.124,0,0,1,.181,7.59,1.969,1.969,0,0,1,0,6.764V2.122A1.967,1.967,0,0,1,.181,1.3a2.126,2.126,0,0,1,.5-.678A2.334,2.334,0,0,1,1.41.167,2.474,2.474,0,0,1,2.308,0" fill={iconColor}/>
                <path id="Path_14624" d="M2.308,0h7.811a2.374,2.374,0,0,1,.9.175,2.317,2.317,0,0,1,.734.477,2.227,2.227,0,0,1,.494.708,2.152,2.152,0,0,1,.181.866V9.762a2.154,2.154,0,0,1-.181.867,2.226,2.226,0,0,1-.494.708,2.316,2.316,0,0,1-.734.477,2.375,2.375,0,0,1-.9.175H2.308a2.374,2.374,0,0,1-.9-.175,2.318,2.318,0,0,1-.734-.477,2.227,2.227,0,0,1-.495-.708A2.152,2.152,0,0,1,0,9.762V2.226A2.151,2.151,0,0,1,.181,1.36,2.229,2.229,0,0,1,.676.652,2.319,2.319,0,0,1,1.41.175,2.372,2.372,0,0,1,2.308,0" transform="translate(16.602)" fill={iconColor}/>
                <path id="Path_14625" d="M2.308,0h7.811a2.374,2.374,0,0,1,.9.175,2.317,2.317,0,0,1,.734.477,2.227,2.227,0,0,1,.494.708,2.152,2.152,0,0,1,.181.866V7.535a2.154,2.154,0,0,1-.181.867,2.226,2.226,0,0,1-.494.708,2.316,2.316,0,0,1-.734.477,2.375,2.375,0,0,1-.9.175H2.308a2.374,2.374,0,0,1-.9-.175A2.318,2.318,0,0,1,.676,9.11a2.227,2.227,0,0,1-.5-.71A2.152,2.152,0,0,1,0,7.535V2.226A2.151,2.151,0,0,1,.181,1.36,2.229,2.229,0,0,1,.676.652,2.319,2.319,0,0,1,1.41.175,2.372,2.372,0,0,1,2.308,0" transform="translate(16.602 18.373)" fill={iconColor}/>
                <path id="Path_14626" d="M2.308,0h7.811a2.306,2.306,0,0,1,2.126,1.4,2.265,2.265,0,0,1,.181.891v7.746a2.272,2.272,0,0,1-.676,1.619,2.31,2.31,0,0,1-1.632.67H2.308a2.31,2.31,0,0,1-2.127-1.4A2.265,2.265,0,0,1,0,10.034V2.289A2.263,2.263,0,0,1,.181,1.4,2.31,2.31,0,0,1,2.31,0" transform="translate(0 15.814)" fill={iconColor}/>
            </g>
        </svg>
    ) : (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="29"
            viewBox="0 0 30.33 29.437"
        >
            <g id="Group_20598" data-name="Group 20598" transform="translate(0.65 0.65)">
                <path id="Path_14622" d="M16.231,14.378h7.654a1.367,1.367,0,0,0,.517-.1A1.334,1.334,0,0,0,24.825,14a1.282,1.282,0,0,0,.285-.407,1.239,1.239,0,0,0,.1-.5V11.221a1.232,1.232,0,0,0-.1-.5,1.276,1.276,0,0,0-.283-.4,1.328,1.328,0,0,0-.42-.273,1.359,1.359,0,0,0-.514-.1H16.231a2.731,2.731,0,0,1-1.033-.2,2.664,2.664,0,0,1-.844-.549,2.56,2.56,0,0,1-.569-.814,2.476,2.476,0,0,1-.208-1V1.275a1.232,1.232,0,0,0-.1-.5,1.276,1.276,0,0,0-.287-.4A1.328,1.328,0,0,0,12.77.1a1.359,1.359,0,0,0-.514-.1h-.872a1.371,1.371,0,0,0-.519.1,1.34,1.34,0,0,0-.424.276,1.287,1.287,0,0,0-.286.409,1.243,1.243,0,0,0-.1.5V4.322a2.476,2.476,0,0,1-.208,1,2.56,2.56,0,0,1-.569.814,2.664,2.664,0,0,1-.844.549,2.731,2.731,0,0,1-1.033.2H1.327a1.366,1.366,0,0,0-.517.1,1.332,1.332,0,0,0-.422.274A1.28,1.28,0,0,0,.1,7.665a1.238,1.238,0,0,0-.1.5v2.37a1.241,1.241,0,0,0,.1.5,1.287,1.287,0,0,0,.286.409,1.34,1.34,0,0,0,.424.276,1.368,1.368,0,0,0,.518.1H8.983a2.731,2.731,0,0,1,1.033.2,2.664,2.664,0,0,1,.844.549,2.56,2.56,0,0,1,.569.814,2.476,2.476,0,0,1,.208,1v8.079a.567.567,0,0,0,.048.228.586.586,0,0,0,.13.186.61.61,0,0,0,.193.126.625.625,0,0,0,.237.046,1.372,1.372,0,0,0,.519-.1,1.337,1.337,0,0,0,.423-.275,1.284,1.284,0,0,0,.285-.409,1.244,1.244,0,0,0,.1-.5v-4.82a2.476,2.476,0,0,1,.208-1,2.56,2.56,0,0,1,.569-.814,2.664,2.664,0,0,1,.844-.549,2.731,2.731,0,0,1,1.033-.2" transform="translate(1.952 2.984)" fill={colors?.Accent} stroke={colors.Main} strokeWidth="1"/>
                <path id="Path_14623" d="M2.308,0H8.739a2.476,2.476,0,0,1,.9.167,2.332,2.332,0,0,1,.734.455,2.125,2.125,0,0,1,.494.674,1.969,1.969,0,0,1,.181.826V6.764a1.97,1.97,0,0,1-.181.826,2.123,2.123,0,0,1-.494.674,2.331,2.331,0,0,1-.734.454,2.478,2.478,0,0,1-.9.167H2.308a2.476,2.476,0,0,1-.9-.167,2.333,2.333,0,0,1-.734-.454A2.124,2.124,0,0,1,.181,7.59,1.969,1.969,0,0,1,0,6.764V2.122A1.967,1.967,0,0,1,.181,1.3a2.126,2.126,0,0,1,.5-.678A2.334,2.334,0,0,1,1.41.167,2.474,2.474,0,0,1,2.308,0" fill="none" stroke={colors?.Accent} strokeWidth="1.5"/>
                <path id="Path_14624" d="M2.308,0h7.811a2.374,2.374,0,0,1,.9.175,2.317,2.317,0,0,1,.734.477,2.227,2.227,0,0,1,.494.708,2.152,2.152,0,0,1,.181.866V9.762a2.154,2.154,0,0,1-.181.867,2.226,2.226,0,0,1-.494.708,2.316,2.316,0,0,1-.734.477,2.375,2.375,0,0,1-.9.175H2.308a2.374,2.374,0,0,1-.9-.175,2.318,2.318,0,0,1-.734-.477,2.227,2.227,0,0,1-.495-.708A2.152,2.152,0,0,1,0,9.762V2.226A2.151,2.151,0,0,1,.181,1.36,2.229,2.229,0,0,1,.676.652,2.319,2.319,0,0,1,1.41.175,2.372,2.372,0,0,1,2.308,0" transform="translate(16.602)" fill="none" stroke={colors?.Accent} strokeWidth="1.5"/>
                <path id="Path_14625" d="M2.308,0h7.811a2.374,2.374,0,0,1,.9.175,2.317,2.317,0,0,1,.734.477,2.227,2.227,0,0,1,.494.708,2.152,2.152,0,0,1,.181.866V7.535a2.154,2.154,0,0,1-.181.867,2.226,2.226,0,0,1-.494.708,2.316,2.316,0,0,1-.734.477,2.375,2.375,0,0,1-.9.175H2.308a2.374,2.374,0,0,1-.9-.175A2.318,2.318,0,0,1,.676,9.11a2.227,2.227,0,0,1-.5-.71A2.152,2.152,0,0,1,0,7.535V2.226A2.151,2.151,0,0,1,.181,1.36,2.229,2.229,0,0,1,.676.652,2.319,2.319,0,0,1,1.41.175,2.372,2.372,0,0,1,2.308,0" transform="translate(16.602 18.373)" fill="none" stroke={colors?.Accent} strokeWidth="1.5"/>
                <path id="Path_14626" d="M2.308,0h7.811a2.306,2.306,0,0,1,2.126,1.4,2.265,2.265,0,0,1,.181.891v7.746a2.272,2.272,0,0,1-.676,1.619,2.31,2.31,0,0,1-1.632.67H2.308a2.31,2.31,0,0,1-2.127-1.4A2.265,2.265,0,0,1,0,10.034V2.289A2.263,2.263,0,0,1,.181,1.4,2.31,2.31,0,0,1,2.31,0" transform="translate(0 15.814)" fill="none" stroke={colors?.Accent} strokeWidth="1.5"/>
            </g>
        </svg>
    );
};

