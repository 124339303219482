import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { minifiedImage, checkImageRatio } from "../../../utils";
import PDFImageViewer from "src/components/spotlight/pdf-image-viewer";

interface IAttachmentProps {
  format: string;
  source: string;
  content?: string;
  className?: string;
  reSharePost?: boolean;
  post?: any;
  isSpotlightDetailModal?: boolean;
  isCompanyNew?: boolean;
}

interface IFeedMediaProps {
  format: string;
  background: string;
  mediaSource: string;
  reSharePost: boolean;
  className: string;
  mainClass: string;
  mediaClass: string;
}

const FeedMedia = ({
  format,
  background,
  mediaSource,
  reSharePost,
  className,
  mainClass,
  mediaClass,
}: IFeedMediaProps) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const img = event.currentTarget;
    setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
  };

  const getImageRatio = () => {
    if (imageSize.width === 0 || imageSize.height === 0) {
      return;
    }

    const ratio = imageSize.width / imageSize.height;
    return `${ratio.toFixed(2)}`;
  };

  return (
    <div className={mainClass}>
      <div
        style={{ backgroundImage: `url(${background})` }}
        className="feed-bg-blur"
      />
      {format === "image" && (
        <img
          className={classNames(mediaClass, {
            [`${className}`]: className,
            "rounded-b-lg object-cover": reSharePost,
            "!max-h-[594px]": getImageRatio() === "1.00",
          })}
          src={mediaSource}
          onLoad={handleImageLoad}
        />
      )}
      {format === "video" && (
        <video
          className={classNames(mediaClass, {
            [`${className}`]: className,
          })}
          controls
        >
          <source src={mediaSource} />
        </video>
      )}
    </div>
  );
};

export const PostAttachment = ({
  format,
  source,
  content,
  className,
  reSharePost,
  post,
  isSpotlightDetailModal,
  isCompanyNew
}: IAttachmentProps) => {
  const backgroundUrl =
    format === "video"
      ? `${process.env.REACT_APP_DEFAULT_VIDEO_HLS_URL}/${post?.thumbnail}`
      : "" || format === "image"
      ? `${minifiedImage(post?.source ? post?.source : source)}`
      : "";
  const mediaSource = `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${
    post?.source ? post?.source : source
  }`;

  return (
    <>
      {isSpotlightDetailModal !== undefined && format !== "document" && (
        <FeedMedia
          format={format}
          background={backgroundUrl}
          mediaSource={mediaSource}
          reSharePost
          className={className!}
          mainClass={
            format !== "text" && format !== "poll" && format !== "event"
              ? "feed-card feed-media-max-w"
              : ""
          }
          mediaClass={isCompanyNew ? "feed-media-size-new" : "feed-media-size"}
        />
      )}
      {!isSpotlightDetailModal && format === "video" && (
        <FeedMedia
          format={format}
          background={backgroundUrl}
          mediaSource={mediaSource}
          reSharePost
          className={className!}
          mainClass={isCompanyNew ? "feed-card feed-media-max-w-h-new" : "feed-card feed-media-max-w"}
          mediaClass={isCompanyNew ? "feed-media-size-new" : "feed-media-size"}
        />
      )}
      {!isSpotlightDetailModal && format === "image" && (
        <FeedMedia
          format={format}
          background={backgroundUrl}
          mediaSource={mediaSource}
          reSharePost
          className={className!}
          mainClass={isCompanyNew ? "feed-card feed-media-max-w-h-new onlyIMG" : "feed-card feed-media-max-w onlyIMG"}
          mediaClass={isCompanyNew ? "feed-media-size-new" : "feed-media-size"}
        />
      )}
      {/* Display attached PDF files */}
      {format === "document" &&
        post?.pdfImageUrls &&
        post?.pdfImageUrls.length > 0 && (
          <div
            className={classNames('bg-cover w-full h-full max-w-[655px]')}
            >
            <PDFImageViewer
              isSpotlightDetailModal={isSpotlightDetailModal}
              className="pdf-image-viewer pdf-image-main-post"
              pdfImageUrls={post?.pdfImageUrls}
              isSinglePage={post?.pdfImageUrls.length === 1}
              isCompanyNew={isCompanyNew}
            />
          </div>
        )}
      {format === "document" &&
        mediaSource &&
        post?.pdfImageUrls &&
        post?.pdfImageUrls.length === 0 && (
          <div
            className={classNames("w-full", {
              [`${className}`]: className,
              'h-[273px]': isCompanyNew,
            })}
          >
            {/* <PDFImageViewer className="pdf-image-viewer pdf-image-main-post" pdfImageUrls={post?.pdfImageUrls} /> */}
            <iframe
              title={content}
              className={classNames("bg-cover w-full md:min-h-506 md:max-h-full min-h-[230px] max-h-[230px] h-full max-w-[655px]",{
                'md:min-h-[272px]' : isCompanyNew,
              })}
              src={`https://docs.google.com/viewerng/viewer?url=${mediaSource}&embedded=true`}
            />
          </div>
        )}
    </>
  );
};
