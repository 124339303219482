import { Reducer } from 'redux';

import { IUploadDocumentState } from '../../../models/fileupload/UploadDocumentState';
import { DocumentUploadActions } from './actions';
import { DocumentUploadActionTypes } from './types';

const initialState: IUploadDocumentState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const DocumentUploadReducer: Reducer<IUploadDocumentState, DocumentUploadActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DocumentUploadActionTypes.DOCUMENT_UPLOAD_IN_PROGRESS: {
      return {
        ...state,
        uploading: state.loading
      };
    }
    case DocumentUploadActionTypes.DOCUMENT_UPLOAD_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case DocumentUploadActionTypes.DOCUMENT_UPLOAD_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
