/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as Yup from 'yup';
import { useEffect, useMemo, useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    Divider, IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ColorResult } from 'react-color';
import { LoadingButton } from '@mui/lab';
import PColourPicker from 'src/mui/components/hook-form/PColourPicker';
import FormProvider from 'src/mui/components/hook-form/form-provider';
import Iconify from 'src/mui/components/iconify/iconify';
import Box from "@mui/material/Box";
import LoadFavPageIcon from "../../assets/icons/LoadFavPageIcon";
import CtrlPanelRightIcon from "../../assets/icons/CtrlPanelRightIcon";
import CtrlPanelLeftIcon from "../../assets/icons/CtrlPanelLeftIcon";
import CtrlPanelBottomIcon from "../../assets/icons/CtrlPanelBottomIcon";
import LayoutGridIcon from "../../assets/icons/LayoutGridIcon";
import LayoutCustomIcon from "../../assets/icons/LayoutCustomIcon";
import LayoutFocusIcon from "../../assets/icons/LayoutFocusIcon";
import PUploadZone from "../../components/hook-form/PUploadZone";
import PreviewModalCloseIcon from "../../assets/icons/PreviewModalCloseIcon";
import UploadPlusIcon from "../../assets/icons/UploadPlusIcon";
import RegoPageSaveFav from "../../assets/icons/RegoPageSaveFav";
import { debounce } from 'lodash';
import { FilUploadService } from 'src/services/FileUpload';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { createEventBranding, deleteBrandingAsFav, getFavBrandings, markBrandingAsFav } from 'src/store/liveEvent';
import { getBrandingColors, setBranding, setColor } from '../event/live/liveEventStyling';
import ModuleBoxLinesIcon from 'src/mui/assets/icons/ModuleBoxLinesIcon';
import { PMultiCheckbox } from '../../components/hook-form';
import PFavouritePageSelect from '../../components/hook-form/PFavouritePageSelect';
import AddFavourtie from './AddFavourite';

type Props = {
  activeList: string[];
  setActiveList: (value: string[]) => void;
}

const BrandingPage = ({activeList,setActiveList}:Props) => {
  const dispatch = useDispatch();
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const {liveEventBranding, favBrandings,event,favBrandingsLoading} = liveEvent;

  const colors = getBrandingColors();
  const isSelectedFavourite = favBrandings?.find(
    (x: any) =>
      x?.liveEventBrandingId === liveEventBranding?.id || x?.liveEventBrandingId === event?.liveEventBranding?.id
  );

    const [loadPageFromFav, setLoadPageFromFav] = useState<boolean>(false);
    const [primaryBackgroundColorPicker, setPrimaryBackgroundColorPicker] = useState<boolean>(false);
    const [headerColorPicker, setHeaderColorPicker] = useState<boolean>(false);
    const [SecondaryBackgroundColorPicker, setSecondaryBackgroundColorPicker] =
      useState<boolean>(false);
    const [fontColourPicker, seFontColourPicker] = useState<boolean>(false);
    const [mainBgColorPicker, setMainBgColorPicker] = useState<boolean>(false);
    const [btnTextColorPicker, setBtnTextColorPicker] = useState<boolean>(false);
    const [isFavSubmitting, setIsFavSubmitting] = useState(false);
    const [isMediaUpload, setIsMediaUpload] = useState(false);
    const [openAddFavPopup, setOpenAddFavPopup] = useState<boolean>(false);

  const options = [
    { label: 'Formatting', value: 'Formatting' },
  ];
  const NewEventSchema = Yup.object().shape({
       controlPanel: Yup.string().notRequired(),
       layout:Yup.string().notRequired(),
        color: Yup.string().notRequired(),
        button: Yup.string().notRequired(),
        photo: Yup.string().notRequired(),
        logo: Yup.string().notRequired(),
    });

    const defaultValues = useMemo(
      () => ({
         controlPanel:liveEventBranding?.controlPanel || event?.liveEventBranding?.controlPanel || "right",
         layout:liveEventBranding?.layout || event?.liveEventBranding?.layout || "grid",
         moduleDesign:liveEventBranding?.moduleDesign || event?.liveEventBranding?.moduleDesign || "box",
          color: '',
          isCoverImageOverlayColor: '',
          coverImageOverlayOpacity: 0,
          typography:{
            main: liveEventBranding?.typography?.main || event?.liveEventBranding?.typography?.main ||  "#000000",
            Accent:liveEventBranding?.typography?.Accent || event?.liveEventBranding?.typography?.Accent || "#00ffcd",
            Secondary:liveEventBranding?.typography?.Secondary || event?.liveEventBranding?.typography?.Secondary || "#2f2f2f",
            Text:liveEventBranding?.typography?.Text || event?.liveEventBranding?.typography?.Text || "#ffffff",
            ButtonTextColor:liveEventBranding?.typography?.ButtonTextColor ||
            event?.liveEventBranding?.typography?.ButtonTextColor ||
            "#ffffff",
            MainBackgroundColor:liveEventBranding?.typography?.MainBackgroundColor ||
            event?.liveEventBranding?.typography?.MainBackgroundColor ||
            "#ffffff",
          },
          media:liveEventBranding?.media|| event?.liveEventBranding?.media
      }),
      [liveEventBranding,event]
    );

    const methods = useForm<any>({
        resolver: yupResolver(NewEventSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { isDirty },
    } = methods;

    const values = watch();
    const handleDrop = async (acceptedFiles: File[]) => {
      setIsMediaUpload(true);
      const format = acceptedFiles[0].type?.split('/');
      const newFiles = acceptedFiles.map((file: File) =>
        Object.assign(file, {
          preview: URL?.createObjectURL(file),
        }),
      );
      const fileUploadService = new FilUploadService();

      const formData = new FormData();
      formData.append('file', newFiles[0]);
      const result =
        format && format[0] === 'image'
          ? await fileUploadService.uploadImage(formData)
          : await fileUploadService.uploadVideo(formData);
      setValue('media', result?.data?.Key, { shouldDirty: true });
      if (result?.data?.Key) {setIsMediaUpload(false)} else {setIsMediaUpload(false);};
    };

    const handleLoadFromFav = ()=>{
      const {layout,controlPanel,moduleDesign,media,typography} = values?.pageId;
      setBranding('layout', layout)
      setValue('layout', layout, { shouldDirty: true });

      setBranding('controlPanel', controlPanel)
      setValue('controlPanel', controlPanel, { shouldDirty: true });

      setBranding('moduleDesign', moduleDesign)
      setValue('moduleDesign', moduleDesign, { shouldDirty: true });

      setBranding('media', media)
      setValue('media', media, { shouldDirty: true });

      setValue('typography.main', typography.main, {shouldDirty:true});
      setColor('Main', typography.main);

      setValue('typography.Secondary', typography.Secondary, {shouldDirty:true});
      setColor('Secondary', typography.Secondary);

      setValue('typography.Accent', typography.Accent, {shouldDirty:true});
      setColor('Accent', typography.Accent);

      setValue('typography.Text', typography.Text, {shouldDirty:true});
      setColor('Text', typography.Text);

      setValue('typography.ButtonTextColor', typography.ButtonTextColor, {shouldDirty:true});
      setColor('ButtonTextColor', typography.ButtonTextColor);

      setValue('typography.MainBackgroundColor', typography.MainBackgroundColor, {shouldDirty:true});
      setColor('MainBackgroundColor', typography.MainBackgroundColor);
    }

    const onSubmit = async (data: any) => {
      dispatch(createEventBranding(liveEvent?.event?.id!,data))
      reset(data);
    };

    const colourPickerTile = (title: string, detail: string, colour: string, onClick: Function) => (
      <div
        style={{
            backgroundColor: `${colour}`,
            border: `1px solid ${colour === undefined || colour === null || colour === 'black' || colour === '#000000' || colour === colors?.Main ? 'white' : colour}`,
            borderRadius: '10px',
            width: '45px',
            height: '45px',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            position: 'relative',
        }}
        onClick={() => onClick(true)}
      >
          <div
            style={{
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.05)',
                borderRadius: '5px',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                position: 'absolute',
                top: '-5px',
                left: '-5px',
                width: '20px',
                height: '20px',
            }}
          >
              <Iconify icon="material-symbols:brush-sharp"/>
          </div>
      </div>


    );

    const onSubmitter = (data: any) => onSubmit(data);

    const onSuccess = () => {
      dispatch(getFavBrandings(liveEvent?.event?.id!));
      setOpenAddFavPopup(false);
    };


    const debouncedSubmit = useMemo(
      () =>
        debounce((data: any) => {
          onSubmit(data);
        }, 500),
      []
    );

    useEffect(() => {
      if (isDirty) {
        handleSubmit(debouncedSubmit)();
      }
      // Cleanup debounce on unmount
      return () => debouncedSubmit.cancel();
    }, [isDirty, debouncedSubmit]);

    useEffect(()=>{
      if(!liveEventBranding && !liveEvent?.event?.liveEventBranding){
          handleSubmit(debouncedSubmit)();
        return () => debouncedSubmit.cancel();
      }

    },[liveEventBranding,liveEvent?.event?.liveEventBranding]);

    useEffect(()=>{
      dispatch(getFavBrandings(liveEvent?.event?.id!))
    },[]);

    return (
      <Stack
        sx={{
          height: 'calc(100vh - 85px)',
          overflowY: 'scroll',
          overflowX: 'hidden',
          marginTop: '19px',
          paddingRight: '14px',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `${getBrandingColors().Accent}`,
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: `${getBrandingColors().Accent}`,
            opacity: 0.5,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: colors?.Secondary,
          },
        }}
        className='branding-scroll'
      >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitter)}>
              <Stack direction="row" justifyContent="space-between" sx={{marginTop: "0px"}}>
                  <Stack>
                      <Typography
                        sx={{fontFamily: 'Poppins-400', fontSize: '18px', lineHeight: '25px', color: colors?.Text || "white", mb: '3px'}}
                      >
                          Load from saved branding
                      </Typography>
                      <Typography
                        sx={{fontFamily: 'Poppins-400', fontSize: '11px', lineHeight: '16px', color: colors?.Text || "white"}}
                      >
                          Load a form or create a new set
                      </Typography>
                  </Stack>

                  <Box
                    sx={{
                        width: '45px',
                        height: '45px',
                        backgroundColor: getBrandingColors()?.Accent || '#ffffff',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                      dispatch(getFavBrandings(liveEvent?.event?.id!));
                      setLoadPageFromFav(true);
                    }}
                  >
                      <LoadFavPageIcon/>
                  </Box>
              </Stack>

              <hr
                style={{
                    margin: "10px 0",
                    border: "none",
                    opacity: '60%',
                    borderTop: `0.5px solid ${getBrandingColors()?.Accent || '#ffffff'}`,
                }}
              />

              <Stack>
                  <Stack>
                      <Typography
                        sx={{fontFamily: 'Poppins-400', fontSize: '18px', lineHeight: '25px', color: colors?.Text || "white"}}
                      >
                          Control panel placement
                      </Typography>
                  </Stack>


                  <Stack
                    direction="row"
                    sx={{
                        marginTop: "15px",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    spacing={2} // Provides a 20px space between items (spacing is multiplied by 8px)
                  >
                      <Stack>
                          <Box
                            sx={{
                                width: '84px',
                                height: '70px',
                                backgroundColor: colors?.Secondary || 'rgba(255, 255, 255, 0.2)',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                              border: values?.controlPanel === 'right' ? colors?.Accent ? `1px solid ${getBrandingColors().Accent}` : '1px solid #03FFCD'
                                : 'none',
                            }}
                            onClick={() => {
                              setBranding('controlPanel', 'right')
                              setValue('controlPanel', 'right', { shouldDirty: true });
                            }}
                          >
                              <CtrlPanelRightIcon/>
                          </Box>
                          <Typography
                            sx={{
                                fontFamily: 'Poppins-400',
                                fontSize: '13px',
                                lineHeight: '19px',
                                mt: '5px',
                                color: colors?.Text || "white",
                                textAlign: "center"
                            }}
                          >
                              Right
                          </Typography>
                      </Stack>
                      <Stack>
                          <Box
                            sx={{
                                width: '84px',
                                height: '70px',
                                backgroundColor: colors?.Secondary || 'rgba(255, 255, 255, 0.2)',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'not-allowed',
                              border: values?.controlPanel === 'bottom' ? colors?.Accent ? `1px solid ${getBrandingColors().Accent}` : '1px solid #03FFCD'
                                : 'none',
                                opacity:0.5,

                            }}
                            // onClick={() => {
                            //   setBranding('controlPanel', 'bottom')
                            //   setValue('controlPanel', 'bottom', { shouldDirty: true });
                            // }}
                          >
                              <CtrlPanelBottomIcon/>
                          </Box>
                          <Typography
                            sx={{
                                fontFamily: 'Poppins-400',
                                fontSize: '13px',
                                lineHeight: '19px',
                                mt: '5px',
                                color: colors?.Text || "white",
                                textAlign: "center"
                            }}
                          >
                              Bottom
                          </Typography>
                      </Stack>
                      <Stack>
                          <Box
                            sx={{
                                width: '84px',
                                height: '70px',
                                backgroundColor: colors?.Secondary || 'rgba(255, 255, 255, 0.2)',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                              border: values?.controlPanel === 'left' ? colors?.Accent ? `1px solid ${getBrandingColors().Accent}` : '1px solid #03FFCD'
                                : 'none',
                            }}
                            onClick={() => {
                              setBranding('controlPanel', 'left')
                              setValue('controlPanel', 'left', { shouldDirty: true });
                            }}


                          >
                              <CtrlPanelLeftIcon/>
                          </Box>
                          <Typography
                            sx={{
                                fontFamily: 'Poppins-400',
                                fontSize: '13px',
                                lineHeight: '19px',
                                mt: '5px',
                                color: colors?.Text || "white",
                                textAlign: "center"
                            }}
                          >
                              Left
                          </Typography>
                      </Stack>
                  </Stack>

              </Stack>

              <hr
                style={{
                    margin: "10px 0",
                    border: "none",
                    opacity: '60%',
                    borderTop: `0.5px solid ${getBrandingColors()?.Accent || '#ffffff'}`,
                }}
              />

              <Stack>
                  <Stack>
                      <Typography
                        sx={{fontFamily: 'Poppins-400', fontSize: '18px', lineHeight: '25px', color: colors?.Text || "white"}}
                      >
                          Module box design
                      </Typography>
                  </Stack>

                  <Stack
                    sx={{
                        marginTop: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      cursor: "pointer",
                    }}
                    spacing={2} // Provides a 20px space between items (spacing is multiplied by 8px)
                    onClick={() => {
                      setBranding('moduleDesign', 'box')
                      setValue('moduleDesign', 'box', { shouldDirty: true });
                    }}
                  >
                      <Stack
                        direction="row"
                        sx={{
                            height: "65px",
                            width: '100%',
                            backgroundColor: colors?.Secondary || 'rgba(255, 255, 255, 0.2)',
                            borderRadius: '10px',
                            alignItems: "center",
                            gap: "6px",
                          border: values?.moduleDesign === 'box' ? colors?.Accent ? `1px solid ${getBrandingColors().Accent}` : '1px solid #03FFCD' : 'none',
                        }}
                      >
                          <Box
                            sx={{
                                width: '30px',
                                height: '28px',
                                borderRadius: '10px',
                                marginLeft: "9.5px",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                          >
                              <LayoutGridIcon/>
                          </Box>
                          <Stack direction="row">
                              <Stack>
                                  <Typography
                                    sx={{
                                        fontFamily: 'Poppins-400',
                                        fontSize: '18px',
                                        lineHeight: '18px',
                                        color: colors?.Text || "white",
                                        textAlign: "left",
                                    }}
                                  >
                                      Box
                                  </Typography>
                                  <Typography
                                    sx={{
                                        fontFamily: 'Poppins-400',
                                        fontSize: '13px',
                                        lineHeight: '15px',
                                        color: colors?.Text || "white",
                                        textAlign: "left",
                                    }}
                                  >
                                      Each module distinguished by a box
                                  </Typography>
                              </Stack>
                          </Stack>
                      </Stack>


                  </Stack>
                <Stack
                  sx={{
                    marginTop: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  spacing={2} // Provides a 20px space between items (spacing is multiplied by 8px)
                  onClick={() => {
                    setBranding('moduleDesign', 'lines')
                    setValue('moduleDesign', 'lines', { shouldDirty: true });
                  }}

                >
                      <Stack
                        direction="row"
                        sx={{
                            height: "65px",
                            width: '100%',
                            backgroundColor: colors?.Secondary || 'rgba(255, 255, 255, 0.2)',
                            borderRadius: '10px',
                            alignItems: "center",
                            gap: "6px",
                          border: values?.moduleDesign === 'lines' ? colors?.Accent ? `1px solid ${getBrandingColors().Accent}` : '1px solid #03FFCD'
                                : 'none', // Border for selected option
                        }}
                      >
                          <Box
                            sx={{
                                width: '30px',
                                height: '28px',
                                borderRadius: '10px',
                                marginLeft: "9.5px",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                          >
                              <ModuleBoxLinesIcon/>
                          </Box>
                          <Stack direction="row">
                              <Stack>
                                  <Typography
                                    sx={{
                                        fontFamily: 'Poppins-400',
                                        fontSize: '18px',
                                        lineHeight: '18px',
                                        color: colors?.Text || "white",
                                        textAlign: "left",
                                    }}
                                  >
                                      Lines
                                  </Typography>
                                  <Typography
                                    sx={{
                                        fontFamily: 'Poppins-400',
                                        fontSize: '13px',
                                        lineHeight: '15px',
                                        color: colors?.Text || "white",
                                        textAlign: "left",
                                    }}
                                  >
                                      Exposed background with Transparent modules
                                  </Typography>
                              </Stack>
                          </Stack>
                      </Stack>


                  </Stack>
              </Stack>

              <hr
                style={{
                    margin: "10px 0",
                    border: "none",
                    opacity: '60%',
                    borderTop: `0.5px solid ${getBrandingColors()?.Accent || '#ffffff'}`,
                }}
              />

              <Stack>
                  <Stack>
                      <Typography
                        sx={{fontFamily: 'Poppins-400', fontSize: '18px', lineHeight: '25px', color: colors?.Text || "white"}}
                      >
                          Layout & Styling
                      </Typography>
                  </Stack>

                  <Stack
                    sx={{
                        marginTop: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      cursor: "pointer",
                    }}
                    spacing={2}
                    onClick={() => {
                      setBranding('layout', 'grid')
                      setValue('layout', 'grid', { shouldDirty: true });
                    }}
                  >
                      <Stack
                        direction="row"
                        sx={{
                            height: "65px",
                            width: '100%',
                            backgroundColor: colors?.Secondary || 'rgba(255, 255, 255, 0.2)',
                            borderRadius: '10px',
                            alignItems: "center",
                            gap: "6px",
                          border: values?.layout === 'grid' ? colors?.Accent ? `1px solid ${getBrandingColors().Accent}` : '1px solid #03FFCD'
                                : 'none',
                        }}
                      >
                          <Box
                            sx={{
                                width: '30px',
                                height: '28px',
                                borderRadius: '10px',
                                marginLeft: "9.5px",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                          >
                              <LayoutGridIcon/>
                          </Box>
                          <Stack direction="row">
                              <Stack>
                                  <Typography
                                    sx={{
                                        fontFamily: 'Poppins-400',
                                        fontSize: '18px',
                                        lineHeight: '18px',
                                        color: colors?.Text || "white",
                                        textAlign: "left",
                                    }}
                                  >
                                      Grid
                                  </Typography>
                                  <Typography
                                    sx={{
                                        fontFamily: 'Poppins-400',
                                        fontSize: '13px',
                                        lineHeight: '15px',
                                        color: colors?.Text || "white",
                                        textAlign: "left",
                                    }}
                                  >
                                      Best for simultaneous visibility of all modules
                                  </Typography>
                              </Stack>
                          </Stack>
                      </Stack>


                  </Stack>
                <Stack
                  sx={{
                    marginTop: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  spacing={2} // Provides a 20px space between items (spacing is multiplied by 8px)
                  onClick={() => {
                    setBranding('layout', 'focus')
                    setValue('layout', 'focus', { shouldDirty: true });
                    if(activeList?.length > 2) {
                      setActiveList(activeList.slice(0, 2))
                    }
                  }}
                >
                      <Stack
                        direction="row"
                        sx={{
                            height: "65px",
                            width: '100%',
                            backgroundColor: colors?.Secondary || 'rgba(255, 255, 255, 0.2)',
                            borderRadius: '10px',
                            alignItems: "center",
                            gap: "6px",
                          border: values?.layout === 'focus' ? colors?.Accent ? `1px solid ${getBrandingColors().Accent}` : '1px solid #03FFCD'
                                : 'none',
                        }}
                      >
                          <Box
                            sx={{
                                width: '30px',
                                height: '28px',
                                borderRadius: '10px',
                                marginLeft: "9.5px",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                          >
                              <LayoutFocusIcon/>
                          </Box>
                          <Stack direction="row">
                              <Stack>
                                  <Typography
                                    sx={{
                                        fontFamily: 'Poppins-400',
                                        fontSize: '18px',
                                        lineHeight: '18px',
                                        color: colors?.Text || "white",
                                        textAlign: "left",
                                    }}
                                  >
                                      Focus
                                  </Typography>
                                  <Typography
                                    sx={{
                                        fontFamily: 'Poppins-400',
                                        fontSize: '13px',
                                        lineHeight: '15px',
                                        color: colors?.Text || "white",
                                        textAlign: "left",
                                    }}
                                  >
                                      Focused: sessions + one module at any time
                                  </Typography>
                              </Stack>
                          </Stack>
                      </Stack>


                  </Stack>
                  <Stack
                    sx={{
                        marginTop: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      cursor: "not-allowed",
                      opacity:0.6
                    }}
                    spacing={2}
                    // onClick={() => {
                    //   setBranding('layout', 'custom')
                    //   setValue('layout', 'custom', { shouldDirty: true });
                    // }}


                  >
                      <Stack
                        direction="row"
                        sx={{
                            height: "65px",
                            width: '100%',
                            backgroundColor: colors?.Secondary || 'rgba(255, 255, 255, 0.2)',
                            borderRadius: '10px',
                            alignItems: "center",
                            gap: "6px",
                          border: values?.layout === 'custom' ? colors?.Accent ? `1px solid ${getBrandingColors().Accent}` : '1px solid #03FFCD'
                                : 'none',
                        }}
                      >
                          <Box
                            sx={{
                                width: '30px',
                                height: '28px',
                                borderRadius: '10px',
                                marginLeft: "9.5px",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                          >
                              <LayoutCustomIcon/>
                          </Box>
                          <Stack direction="row">
                              <Stack>
                                  <Typography
                                    sx={{
                                        fontFamily: 'Poppins-400',
                                        fontSize: '18px',
                                        lineHeight: '18px',
                                        color: colors?.Text || "white",
                                        textAlign: "left",
                                    }}
                                  >
                                      Custom (comming soon)
                                  </Typography>
                                  <Typography
                                    sx={{
                                        fontFamily: 'Poppins-400',
                                        fontSize: '13px',
                                        lineHeight: '15px',
                                        color: colors?.Text || "white",
                                        textAlign: "left",
                                    }}
                                  >
                                      Drag & Resize any module + place it anywhere
                                  </Typography>
                              </Stack>
                          </Stack>
                      </Stack>
                  </Stack>
              </Stack>
              <hr
                style={{
                    margin: "10px 0",
                    border: "none",
                    opacity: '60%',
                    borderTop: `0.5px solid ${getBrandingColors()?.Accent || '#ffffff'}`,
                }}
              />

              <Stack
                direction="row"
                justifyContent="space-between"
              >
                  <Stack>
                      <Typography
                        sx={{fontFamily: 'Poppins-400', fontSize: '18px', lineHeight: '25px', color: colors?.Text || "white", mb: '5px'}}
                      >
                          Add a background
                      </Typography>
                      <Typography
                        sx={{
                            fontFamily: 'Poppins-400',
                            fontSize: '11px',
                            lineHeight: '16px',
                            color: colors?.Text || "white",
                        }}
                      >
                          Display an image or a video loop
                      </Typography>

                  </Stack>

                  <div style={{position: 'relative'}}>
                      {values?.media && !isMediaUpload ? (
                        <IconButton
                          sx={{
                              position: 'absolute',
                              top: '-6px',
                              right: '-1px',
                              zIndex: 3,
                              border: '1px solid black',
                              borderRadius: '30px',
                              p:0
                          }}
                          onClick={() => {
                              setValue('media', null,{ shouldDirty: true });
                          }}
                        >
                            <PreviewModalCloseIcon
                              style={{
                                  cursor: 'pointer',
                                  width: '17px',
                                  height: '17px',
                              }}
                            />
                        </IconButton>
                       ) : null}
                      <Box
                        sx={{
                            width: '45px',
                            height: '45px',
                            backgroundColor: colors?.Accent || "white",
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                      >
                        {!isMediaUpload ? (
                            <>
                                {/* Upload Zone */}
                                <Box
                                  sx={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      width: '100%',
                                      height: '100%',
                                      zIndex: 2,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                  }}
                                >
                                    <PUploadZone
                                      name="media"
                                      format=".jpg,.jpeg,.png,.gif,.bmp,.webp,.svg,.tiff,.mp4,.mov,.avi,.mkv,.flv,.wmv,.webm,.mpeg"
                                      maxSize={53000000}
                                      onDrop={handleDrop}
                                      pageUploadMedia
                                    />
                                </Box>
                                {/* Upload Plus Icon */}
                                <Box
                                  sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      width: '100%',
                                      height: '100%',
                                      position: 'absolute',
                                      zIndex: 1,
                                  }}
                                >
                                    <UploadPlusIcon/>
                                </Box>
                            </>
                            ) : <CircularProgress color="info" />}
                      </Box>
                  </div>
              </Stack>

              <hr
                style={{
                    margin: "10px 0",
                    border: "none",
                    opacity: '60%',
                    borderTop: `0.5px solid ${getBrandingColors()?.Accent || '#ffffff'}`,
                }}
              />

            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{ fontFamily: 'Poppins-400', fontSize: '20px', lineHeight: '28px', color: colors?.Text || "white" }}
                >
                  Main Background Color
                </Typography>
                <Typography
                  sx={{ fontFamily: 'Poppins-400', fontSize: '13px', lineHeight: '19px', color: colors?.Text || "white" }}
                >
                  Main Background
                </Typography>
              </Stack>

              <div style={{ position: 'relative' }}>
                {colourPickerTile('', '', colors?.MainBackgroundColor || values?.typography?.MainBackgroundColor!, setMainBgColorPicker)}
                <PColourPicker
                  name="typography.MainBackgroundColor"
                  styles={{marginTop: '-252px', zIndex: 30, right: values?.controlPanel === 'right' ? 0 : 'auto', left: values?.controlPanel === 'right' ? 'auto' : '-180px', bottom: '50px'}}
                  color={colors?.MainBackgroundColor || values?.typography?.MainBackgroundColor!}
                  visible={mainBgColorPicker}
                  handleClose={() => {
                    setMainBgColorPicker(false);
                  }}
                  handleChange={(newColor: ColorResult | undefined) => {
                    const colorFormatRgba = `rgba(${newColor?.rgb?.r},${newColor?.rgb?.g},${newColor?.rgb?.b}, ${newColor?.rgb?.a})`;
                    setValue('typography.MainBackgroundColor', colorFormatRgba || '', {shouldDirty:true});
                    setColor('MainBackgroundColor', colorFormatRgba || '');
                  }}
                />
              </div>
            </Stack>

            <Divider sx={{borderColor: getBrandingColors()?.Accent || '#ffffff', my: '10px', opacity: '60%'}}/>
              <Stack direction="row" justifyContent="space-between">
                  <Stack>
                      <Typography
                        sx={{fontFamily: 'Poppins-400', fontSize: '20px', lineHeight: '28px', color: colors?.Text || "white"}}
                      >
                          Main
                      </Typography>
                      <Typography
                        sx={{fontFamily: 'Poppins-400', fontSize: '13px', lineHeight: '19px', color: colors?.Text || "white"}}
                      >
                          Primary color, buttons, hyperlinks
                      </Typography>
                  </Stack>

                  <div className='relative' >
                      {colourPickerTile(
                        '',
                        '',
                        colors.Main || values?.typography?.main!,
                        setPrimaryBackgroundColorPicker,
                      )}
                      <PColourPicker
                        name="typography.main"
                        styles={{marginTop: '-252px', zIndex: 30, right: values?.controlPanel === 'right' ? 0 : 'auto', left: values?.controlPanel === 'right' ? 'auto' : '-180px', bottom: '50px'}}
                        color={colors.Main || values?.primaryBackgroundColor!}
                        visible={primaryBackgroundColorPicker}
                        handleClose={() => {
                            setPrimaryBackgroundColorPicker(false);
                        }}
                        handleChange={(newColor: ColorResult | undefined) => {
                          const colorFormatRgba = `rgba(${newColor?.rgb?.r},${newColor?.rgb?.g},${newColor?.rgb?.b}, ${newColor?.rgb?.a})`;
                          setValue('typography.main', colorFormatRgba || '', {shouldDirty:true});
                          setColor('Main', colorFormatRgba || '');
                        }}
                      />
                  </div>
              </Stack>

              <Divider sx={{borderColor: getBrandingColors()?.Accent || '#ffffff', my: '10px', opacity: '60%'}}/>
              <Stack direction="row" justifyContent="space-between">
                  <Stack>
                      <Typography
                        sx={{fontFamily: 'Poppins-400', fontSize: '20px', lineHeight: '28px', color: colors?.Text || "white"}}
                      >
                          Secondary
                      </Typography>
                      <Typography
                        sx={{fontFamily: 'Poppins-400', fontSize: '13px', lineHeight: '19px', color: colors?.Text || "white"}}
                      >
                          Form backgrounds
                      </Typography>
                  </Stack>

                  <div style={{position: 'relative'}}>
                      {colourPickerTile(
                        '',
                        '',
                        colors.Secondary || values?.typography?.Secondary!,
                        setSecondaryBackgroundColorPicker,
                      )}
                      <PColourPicker
                        name="typography.Secondary"
                        styles={{marginTop: '-252px', zIndex: 30, right: values?.controlPanel === 'right' ? 0 : 'auto', left: values?.controlPanel === 'right' ? 'auto' : '-180px', bottom: '50px'}}
                        color={colors.Secondary || values?.typography?.Secondary!}
                        visible={SecondaryBackgroundColorPicker}
                        handleClose={() => {
                            setSecondaryBackgroundColorPicker(false);
                        }}
                        handleChange={(newColor: ColorResult | undefined) => {
                        const colorFormatRgba = `rgba(${newColor?.rgb?.r},${newColor?.rgb?.g},${newColor?.rgb?.b}, ${newColor?.rgb?.a})`;
                        setValue('typography.Secondary', colorFormatRgba || '', {shouldDirty:true});
                        setColor('Secondary', colorFormatRgba || '');
                        }}
                      />
                  </div>
              </Stack>

              <Divider sx={{ borderColor: getBrandingColors()?.Accent || '#ffffff', my: '10px', opacity: '60%' }} />
              <Stack direction="row" justifyContent="space-between">
                  <Stack>
                      <Typography
                        sx={{ fontFamily: 'Poppins-400', fontSize: '20px', lineHeight: '28px', color: colors?.Text || "white" }}
                      >
                          Accent
                      </Typography>
                      <Typography
                        sx={{ fontFamily: 'Poppins-400', fontSize: '13px', lineHeight: '19px', color: colors?.Text || "white" }}
                      >
                          Form headers
                      </Typography>
                  </Stack>

                  <div style={{ position: 'relative' }}>
                      {colourPickerTile('', '', colors?.Accent || values?.typography?.Accent!, setHeaderColorPicker)}
                      <PColourPicker
                        name="typography.Accent"
                        styles={{marginTop: '-252px', zIndex: 30, right: values?.controlPanel === 'right' ? 0 : 'auto', left: values?.controlPanel === 'right' ? 'auto' : '-180px', bottom: '50px'}}
                        color={colors?.Accent || values?.typography?.Accent!}
                        visible={headerColorPicker}
                        handleClose={() => {
                            setHeaderColorPicker(false);
                        }}
                        handleChange={(newColor: ColorResult | undefined) => {
                        const colorFormatRgba = `rgba(${newColor?.rgb?.r},${newColor?.rgb?.g},${newColor?.rgb?.b}, ${newColor?.rgb?.a})`;
                        setValue('typography.Accent', colorFormatRgba || '', {shouldDirty:true});
                        setColor('Accent', colorFormatRgba || '');
                        }}
                      />
                  </div>
              </Stack>

              <Divider sx={{ borderColor: getBrandingColors()?.Accent || '#ffffff', my: '10px', opacity: '60%' }} />
              <Stack direction="row" justifyContent="space-between">
                  <Stack>
                      <Typography
                        sx={{ fontFamily: 'Poppins-400', fontSize: '20px', lineHeight: '28px', color: colors?.Text || "white" }}
                      >
                          Text
                      </Typography>
                      <Typography
                        sx={{ fontFamily: 'Poppins-400', fontSize: '13px', lineHeight: '19px', color: colors?.Text || "white" }}
                      >
                          Field names, responses
                      </Typography>
                  </Stack>

                  <div style={{ position: 'relative' }}>
                      {colourPickerTile('', '', colors?.Text || values?.typography?.Text!, seFontColourPicker)}
                      <PColourPicker
                        name="typography.Text"
                        styles={{marginTop: '-252px', zIndex: 30, right: values?.controlPanel === 'right' ? 0 : 'auto', left: values?.controlPanel === 'right' ? 'auto' : '-180px', bottom: '50px'}}
                        color={colors?.Text || values?.typography?.Text!}
                        visible={fontColourPicker}
                        handleClose={() => {
                            seFontColourPicker(false);
                        }}
                        handleChange={(newColor: ColorResult | undefined) => {
                        const colorFormatRgba = `rgba(${newColor?.rgb?.r},${newColor?.rgb?.g},${newColor?.rgb?.b}, ${newColor?.rgb?.a})`;
                        setValue('typography.Text', colorFormatRgba || '', {shouldDirty:true});
                        setColor('Text', colorFormatRgba || '');
                        }}
                      />
                  </div>
              </Stack>


            <Divider sx={{borderColor: getBrandingColors()?.Accent || '#ffffff', my: '10px', opacity: '60%'}}/>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{ fontFamily: 'Poppins-400', fontSize: '20px', lineHeight: '28px', color: colors?.Text || "white" }}
                >
                  Button Text Color
                </Typography>
                <Typography
                  sx={{ fontFamily: 'Poppins-400', fontSize: '13px', lineHeight: '19px', color: colors?.Text || "white" }}
                >
                  Text over buttons
                </Typography>
              </Stack>

              <div style={{ position: 'relative' }}>
                {colourPickerTile('', '', colors?.ButtonTextColor || values?.typography?.ButtonTextColor!, setBtnTextColorPicker)}
                <PColourPicker
                  name="typography.ButtonTextColor"
                  styles={{marginTop: '-252px', zIndex: 30, right: values?.controlPanel === 'right' ? 0 : 'auto', left: values?.controlPanel === 'right' ? 'auto' : '-180px', bottom: '50px'}}
                  color={colors?.ButtonTextColor || values?.typography?.ButtonTextColor!}
                  visible={btnTextColorPicker}
                  handleClose={() => {
                    setBtnTextColorPicker(false);
                  }}
                  handleChange={(newColor: ColorResult | undefined) => {
                  const colorFormatRgba = `rgba(${newColor?.rgb?.r},${newColor?.rgb?.g},${newColor?.rgb?.b}, ${newColor?.rgb?.a})`;
                  setValue('typography.ButtonTextColor', colorFormatRgba || '', {shouldDirty:true});
                  setColor('ButtonTextColor', colorFormatRgba || '');
                  }}
                />
              </div>
            </Stack>

            {/* ///// */}

            <Divider sx={{borderColor: getBrandingColors()?.Accent || '#ffffff', my: '10px', opacity: '60%'}}/>
              <Stack direction="row" justifyContent="space-between">
                  <Stack>
                      <Typography
                        sx={{ fontFamily: 'Poppins-400', fontSize: '18px', lineHeight: '28px', color: colors?.Text || "white" }}
                      >
                          Save as a favourite
                      </Typography>
                      <Typography
                        sx={{
                            fontFamily: 'Poppins-400',
                            fontSize: '13px',
                            lineHeight: '19px',
                            color: colors?.Text || "white",
                            mb: '12px',
                        }}
                      >
                          Save this form for other events
                      </Typography>
                  </Stack>

                  <div style={{ position: 'relative' }}>
                      <Box
                        sx={{
                            width: '46px',
                            height: '46px',
                            // eslint-disable-next-line no-nested-ternary
                            backgroundColor: isSelectedFavourite
                              ? `${getBrandingColors().Accent}`
                              : '#ffffff',
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                      >
                        {favBrandingsLoading ? (
                          <IconButton
                            disabled={isFavSubmitting}
                          >
                            <CircularProgress color="info" sx={{color: colors?.Secondary}} />
                          </IconButton>
                        ) : (
                        <IconButton
                          onClick={() => {
                            // if (isFav) {
                            //   dispatch(deleteBrandingAsFav(liveEvent?.event?.id!,isFav?.id, onSuccess));
                            // }
                            // else {
                            //   setOpenAddFavPopup(true);
                            // }
                            setOpenAddFavPopup(true);
                          }}
                          disabled={isFavSubmitting}
                        >
                          <RegoPageSaveFav style={{ fill: isSelectedFavourite ? colors?.Secondary : '#808080' }} />
                        </IconButton>
                        )}


                      </Box>
                  </div>
              </Stack>

            <Dialog
              fullWidth
              maxWidth="sm"
              open={loadPageFromFav}
              sx={{ '& .MuiDialog-paper': { p: 4 } }}
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins-500',
                  fontSize: '20px',
                  lineHeight: '21px',
                  mb: '22px',
                  ml: '3px',
                }}
              >
                Select Favorite Branding
              </Typography>
              <Stack spacing={2}>
                <PFavouritePageSelect
                  aLLFavouritePages={favBrandings}
                  label="Select From Favourites"
                  name="pageId"
                  onChange={(value:any)=> setValue('pageId', value,{shouldDirty:false})}
                />
              </Stack>

              <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2, justifyContent: 'flex-end' }}>
                <Button
                  color="inherit"
                  onClick={() => {
                    setValue('pageId', null);
                    setLoadPageFromFav(false);
                  }}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  onClick={() => {
                    handleLoadFromFav()
                    setLoadPageFromFav(false);
                  }}
                  // type="submit"
                  variant="contained"
                  disabled={!values?.pageId}
                >
                  Load & Save
                </LoadingButton>
              </Box>
            </Dialog>
          </FormProvider>

          <AddFavourtie openAddFavPopup={openAddFavPopup} setOpenAddFavPopup={setOpenAddFavPopup}/>
      </Stack>
    );
};

export default BrandingPage;
