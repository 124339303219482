import classNames from 'classnames';
import {
  FC,
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { toast } from 'react-toastify';

import Edit from '../../assets/images/Icon-edit.svg';
import MenuCopyLink from '../../assets/images/event-landing-pre-event/copy-link-icon.svg';
import MenuForward from '../../assets/images/event-landing-pre-event/paper-airplane-icon.svg';
import MenuReport from '../../assets/images/event-landing-pre-event/report-alert-icon.svg';
import Share from '../../assets/images/event-landing-pre-event/share-arrow-icon.svg';
import LockIcon from '../../assets/images/post/lock.svg';
import UnfollowIcon from '../../assets/images/unfollowicon.svg';
import blockUser from '../../assets/images/block-user.svg';
import Hide from '../../assets/images/Eye.svg';
import Trash from '../../assets/images/trash.svg';
import AlertModal from '../../components/alert-modal/alert-modal';
import { IDropdownMenuItem } from '../../components/dropdown-menu';
import PostActions from '../../components/posts/components/PostActions';
import { PostAttachment } from '../../components/posts/components/PostAttachment';
import PostContent from '../../components/posts/components/PostContent';
import { PostReactions } from '../../components/posts/components/PostReactions';
import CreateComment from '../../components/posts/create-comment';
import { DeletePost } from '../../components/posts/delete-post';
import { EditPost } from '../../components/posts/edit-post';
import { SharePost } from '../../components/posts/share-post';
import ViewComment from '../../components/posts/view-comment';
import ActionsReact from '../../components/reactions-icons/components/actions';
import {
  COMMENTS_LIMIT,
  EVENT,
  POST_ACTIONS,
} from '../../constants';
import { IFlagDTO } from '../../models/flag/flag';
import {
  ICommentProps,
  IPostCardProps,
  IUserProps,
} from '../../models/post/post';
import { PostService, UserService } from '../../services';
import { FlagService } from '../../services/Flag';
import { IAppState } from '../../store';
import { unfollowPost } from '../../store/events/event-post-actions/actions';
import { hideModal } from '../../store/modal/actions';
import { openMessageWindow } from '../../store/newMessage';
import { useCurrentUser } from '../../utils/hooks';
import { ParseContent } from '../../utils/parse-html';
import { Report } from '../report';
import { CommentedConnections } from './commented-connections';
import { PostByInfo } from './components/PostByInfo';
import SharedEvent from './components/SharedEvent';
import SharedCompany from './components/ShareCompany';
import { useNavigate } from 'react-router';
import { getCompanyName } from '../../utils';
import { useSearchParams } from 'react-router-dom';
import CreatePoll from '../poll/create-poll';
import ViewPollPost from '../poll/view-poll-post';
import PostContentCompanyPost from './components/PostContentCompanyPost';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';
import { CommentSvgIcon } from 'src/shared-components/V2/IconSvg/CommentSvgIcon/CommentSvgIcon';
import { ShareSvgIcon } from 'src/shared-components/V2/IconSvg/ShareSvgIcon/ShareSvgIcon';

export const CardMainPost: FC<IPostCardProps> = ({
  commentIcon,
  shareIcon,
  className = "",
  post,
  getPostComments,
  addPostComment,
  onPostLike,
  onCommentLike,
  addReply,
  isSpotlight,
  onDelete,
  onEditPost,
  onAfterShare,
  viewingAsCompanyAdmin,
  isSpotlightDetailModal,
  isPostModal,
  isCompanyNew,
  isLivePage
}) => {

  const userService = new UserService();
  const navigate = useNavigate();
  const colors = getBrandingColors();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showComments, setShowComments] = useState(false);
  const [postComments, setPostComments] = useState<any[]>([]);
  const [nextPage, setNextPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const { isModalOpen, postActions } = useSelector((state: IAppState) => state);
  const [isPostHidden, setIsPostHidden] = useState<boolean>(false);
  const [isPostUnfollowed, setIsPostUnfollowed] = useState<boolean>(false);
  const [toggleReportPostModal, setToggleReportPostModal] =
    useState<boolean>(false);
  const [toggleDeleteConfirmation, setToggleDeleteConfirmation] =
    useState<boolean>(false);
  const [toggleEditPostDialog, setToggleEditPostDialog] =
    useState<boolean>(false);
  const [toggleSharePostDialog, setToggleSharePostDialog] =
    useState<boolean>(false);
  const [togglePollEdit, setTogglePollEdit] =
    useState<boolean>(false);


  const [commentsCount, setCommentsCount] = useState(post.totalComments);

  const [postReactions, setPostReactions] = useState<any>({
    post: post,
    postId: post.id,
    totalClaps: post.totalClaps,
    totalHahas: post.totalHahas,
    totalLikes: post.totalLikes,
    totalLoves: post.totalLoves,
    totalSmiles: post.totalSmiles,
    totalComments: post.totalComments,
  });
  const currentUser = useCurrentUser();
  const postId = searchParams.get('postId');

  const commentAsUser: IUserProps = {
    id: currentUser.id,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    avatar: currentUser.avatar!,
    cover: currentUser.cover!,
    location: currentUser.location,
    slug: currentUser.slug,
    jobTitle: currentUser.jobTitle,
    company: currentUser.company,
  };

  const commentAsCompany = viewingAsCompanyAdmin
    ? currentUser.company
    : undefined;

  const dispatch = useDispatch();

  const getPostUrl = (postId: string) =>
    `${window.location.origin}/home?postId=${postId}`;

  const handleHidePost = async () => {
    const postService = new PostService();
    try {
      await postService.hidePost(post.id);
      setIsPostHidden(true);
    } catch (error) {
      setIsPostHidden(false);
    }
  };

  const handleUnHidePost = async () => {
    const postService = new PostService();
    try {
      await postService.unhidePost(post.id);
      setIsPostHidden(false);
    } catch (error) {
      setIsPostHidden(true);
    }
  };

  const handleUnfollowPost = () => {
    dispatch(unfollowPost(post.id));
    setIsPostUnfollowed(true);
  };

  const onBlockConnection = async () => {
    await userService.blockConnection(post.id);
    navigate('/home');
  }

  const removeHtmlStyle = () => {
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
      htmlElement.removeAttribute('style');
    }
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (toggleEditPostDialog === false) {
      timeoutId = setTimeout(removeHtmlStyle, 1000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [toggleEditPostDialog]);

  const ownPostOptions: IDropdownMenuItem[] = [
    {
      id: "edit",
      type: "icon",
      text: "Edit",
      icon: Edit,
      onClickHandler: () => post.format === 'poll' ? setTogglePollEdit(true) : setToggleEditPostDialog(true),
    },
    {
      id: "delete",
      type: "icon",
      text: "Delete",
      icon: Trash,
      onClickHandler: () => setToggleDeleteConfirmation(true),
    },
    {
      id: "share",
      type: "",
      text: "Share",
      icon: Share,
      onClickHandler: () => setToggleSharePostDialog(true),
    },
    {
      id: "forward",
      type: "icon",
      text: "Forward",
      icon: MenuForward,
      onClickHandler: () =>
        dispatch(
          openMessageWindow({
            avatar: "",
            userDisplayName: "New Message",
            messageText: getPostUrl(post.id),
          })
        ),
    },
    {
      id: "copy-link",
      type: "icon",
      text: "Copy Link",
      icon: MenuCopyLink,
      onClickHandler: () => {
        navigator.clipboard.writeText(getPostUrl(post.id));
        toast.success(EVENT.COPY);
      },
    },
  ];

  const otherUsersPostOptions: IDropdownMenuItem[] = [
    {
      id: "hide",
      type: "icon",
      text: "Hide",
      icon: Hide,
      onClickHandler: () => handleHidePost(),
    },
    {
      id: "report",
      type: "icon",
      text: "Report",
      icon: MenuReport,
      onClickHandler: () => setToggleReportPostModal(true),
    },
    {
      id: "unfollow",
      type: "icon",
      text: "Unfollow",
      icon: UnfollowIcon,
      onClickHandler: () => handleUnfollowPost(),
    },
    {
      id: 'block',
      type: 'icon',
      text: 'Block',
      icon: blockUser,
      onClickHandler: () => onBlockConnection(),
    },
    {
      id: "forward",
      type: "icon",
      text: "Forward",
      icon: MenuForward,
      onClickHandler: () =>
        dispatch(
          openMessageWindow({
            avatar: "",
            userDisplayName: "New Message",
            messageText: getPostUrl(post.id),
          })
        ),
    },
    {
      id: "copy-link",
      type: "icon",
      text: "Copy Link",
      icon: MenuCopyLink,
      onClickHandler: () => {
        navigator.clipboard.writeText(getPostUrl(post.id));
        toast.success(EVENT.COPY);
      },
    },
  ];

  const renderHiddenPostBlock = () =>
    isPostHidden && (
      <div className={"px-4 py-4 flex flex-col bg-white rounded-lg my-2"}>
        <div className={"text-sm text-blue-3 font-bold"}>Post hidden!</div>
        <div className={"text-sm text-gray-1"}>
          {`${POST_ACTIONS.POST_HIDDEN} `}
          <span
            className={"font-bold hover:underline cursor-pointer"}
            onClick={() => handleUnHidePost()}
          >
            Undo
          </span>
        </div>
      </div>
    );

  const renderUnfollowedBlock = () =>
    isPostUnfollowed && (
      <div className={"px-4 py-4 text-sm text-blue-1"}>
        {POST_ACTIONS.POST_UNFOLLOWED}
      </div>
    );

  const renderReportedPostBlock = () =>
    postActions.reportedPostId === post.id && (
      <div className={"px-4 py-4 text-sm text-blue-1"}>
        <ParseContent content={POST_ACTIONS.POST_REPORTED} />
      </div>
    );

  const onCloseModal = () => {
    toggleReportPostModal && setToggleReportPostModal(false);
    toggleDeleteConfirmation && setToggleDeleteConfirmation(false);
    toggleEditPostDialog && setToggleEditPostDialog(false);
    toggleSharePostDialog && setToggleSharePostDialog(false);
    togglePollEdit && setTogglePollEdit(false);
  };

  useEffect(() => {
    if (postId) {
      onClickComments(post.id);
    }
    if (postId && post.id && isPostModal) {
      onClickComments(postId);
    }
    if (isSpotlightDetailModal && post.id && isPostModal) {
      onClickComments(post.id);
    }
  }, [isSpotlightDetailModal, post.id, postId, isPostModal]);

  useEffect(() => {
    if (post.id) {
      onClickComments(post.id, true);
    }
  }, [post]);

  useEffect(() => {
    setToggleReportPostModal(isModalOpen.isOpen);
    setToggleDeleteConfirmation(isModalOpen.isOpen);
    setToggleEditPostDialog(isModalOpen.isOpen);
    setToggleSharePostDialog(isModalOpen.isOpen);
  }, [dispatch, isModalOpen, postActions.reportedPostId]);

  // useEffect(() => {
  //   if (post?.format === "poll" && togglePollEdit) {
  //     dispatch(getPoll(post.source));
  //   }
  // }, [togglePollEdit, post?.format]);

  // useEffect(() => {
  //   if (post.sharePost && post?.sharePost?.format === "poll") {
  //     dispatch(getPoll(post.sharePost.source));
  //   }
  // }, [post?.sharePost, post?.sharePost?.format]);

  const onClickComments = async (postId: any, isInitialLoad = false) => {
    getPostComments &&
      (await getPostComments(postId, nextPage, 6)
        .then((res: any) => {
          const commentsToStore = isInitialLoad ? res.data.slice(0, 2) : res.data;
          setPostComments(commentsToStore);
          setTotalPage(res.totalPage);
        })
        .then(setShowComments(!showComments))
        .catch(setPostComments([])));
  };

  const handleMoreComments = async () => {
    getPostComments &&
      (await getPostComments(post.id, nextPage + 1)
        .then((res: any) => {
          setPostComments(postComments.concat(res.data));
          setNextPage(nextPage + 1);
        })
        .then(setNextPage(nextPage + 1)));
  };

  const onUserClick = (url: string | undefined) => {
    if (!url) return;
    navigate(`../wall/${url}`);
  };

  if (!!post?.libraryContent) { return null; }
  return (
    <div
      className={classNames('w-full', {
        'h-full relative': isSpotlightDetailModal,
      })}
    >
      {renderHiddenPostBlock()}
      {renderUnfollowedBlock()}
      {renderReportedPostBlock()}
      {toggleReportPostModal && (
        <AlertModal
          isOpen={toggleReportPostModal}
          onClose={onCloseModal}
          className="w-576"
          closeIconClass=" right-[18px] top-[15px] px-0"
          isCloseIcon
        >
          <Report
            id={post.id}
            type="post"
            onReport={async (id: string, type: string, reason: string) => {
              const flagService = new FlagService();
              const flagItem: IFlagDTO = {
                flaggableId: id,
                flaggableType: type,
                reason,
              };
              await flagService.flagItem(flagItem);
              dispatch(hideModal());
            }}
            onCloseReport={onCloseModal}
          />
        </AlertModal>
      )}
      {toggleDeleteConfirmation && (
        <AlertModal isCloseIcon closeIconClass='right-[7px] top-[14px]' isOpen={toggleDeleteConfirmation} onClose={onCloseModal} className="w-[374px] bg-[#EDF2F5]">
          <DeletePost
            postId={post.id}
            message={"Are you sure you want to delete this post?"}
            onDelete={async () => {
              onDelete && (await onDelete(post.id));
              onCloseModal();
            }}
            onClose={onCloseModal}
          />
        </AlertModal>
      )}
      {togglePollEdit && (
        <AlertModal
          className="w-576"
          onClose={onCloseModal}
          isOpen={togglePollEdit}
        >
          {post.postedAsUser && (
            <CreatePoll
              isOpenedDirectly
              toggleCreatePostModal={() => setToggleEditPostDialog(true)}
              onClose={onCloseModal}
              isEditPoll
              post={post}
            />
          )}

          {post.postedAsCompany && (
            <CreatePoll
              isOpenedDirectly
              toggleCreatePostModal={() => setToggleEditPostDialog(true)}
              onClose={onCloseModal}
              isEditPoll
              post={post}
            />
          )}
        </AlertModal>
      )}

      {toggleEditPostDialog && (
        <AlertModal isOpen={toggleEditPostDialog} onClose={onCloseModal} className="w-[500px]">
          {post.postedAsUser && (
            <EditPost
              event={post.event}
              postId={post.id}
              content={post.content}
              source={post.source}
              format={post.format!}
              avatarUrl={post.postedAsUser?.avatar  ?  `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${post.postedAsUser?.avatar}`  : ""}
              userDisplayName={`${post.postedAsUser?.firstName} ${post.postedAsUser?.lastName}`}
              onEditPost={(postId: string, content: string, privacy: string) => {
                if (onEditPost) {
                  onEditPost(postId, content, privacy);
                  onCloseModal();
                }
              }}
              location="profile"
              privacy={post.privacy}
              postedAsUser
              postedAsCompany={false}
              shareEvent={post.shareEvent}
              sharePost={post.sharePost}
            />
          )}
          {post.postedAsCompany && (
            <EditPost
              event={post.event}
              postId={post.id}
              content={post.content}
              source={post.source}
              format={post.format!}
              avatarUrl={ post.postedAsCompany?.logo ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${post.postedAsCompany?.logo}` : ""}
              userDisplayName={`${post.postedAsCompany?.name}`}
              onEditPost={(postId: string, content: string, privacy: string) => {
                if (onEditPost) {
                  onEditPost(postId, content, privacy);
                  onCloseModal();
                }
              }
              }
              location="profile"
              privacy={post.privacy}
              postedAsUser={false}
              shareEvent={post.shareEvent}
              sharePost={post.sharePost}
              postedAsCompany
            />
          )}
        </AlertModal>
      )}
      {toggleSharePostDialog && (
        <AlertModal
          isOpen={toggleSharePostDialog}
          onClose={onCloseModal}
          className="w-[500px]"
          closeIconClass=" right-[18px] top-[12px] px-0"
          isCloseIcon
        >
          {(post.postedAsUser || post.type === "recommended") && (
            <SharePost 
              avatarUrl={currentUser?.avatar ?  `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${currentUser?.avatar}` : ""}
              profileUrl={currentUser.slug!}
              userDisplayName={`${currentUser.firstName} ${currentUser.lastName}`}
              userId={currentUser.id!}
              jobTitle={currentUser.jobTitle}
              dateAndTime={post?.format === "shared_event" ? post?.sharePost?.createdAt : post?.createdAt}
              content={post.content}
              postId={post.id}
              originalPostContent={post.content}
              originalPostSource={post.source}
              originalPostFormat={post.format!}
              originalPostedAsUser={post.postedAsUser}
              shareEvent={post.shareEvent || post.event}
              onAfterShare={onAfterShare}
              sharePost={post.sharePost}
              post={post}
              isLivePage={isLivePage}
            />
          )}
          {post.postedAsCompany && (
            <SharePost
              avatarUrl={currentUser?.avatar ?  `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${currentUser?.avatar}` : ''}
              profileUrl={currentUser.slug!}
              userDisplayName={`${currentUser.firstName} ${currentUser.lastName}`}
              userId={currentUser.id!}
              jobTitle={`${post.postedAsCompany?.industry?.name}`}
              dateAndTime={post.createdAt}
              content={post.content}
              postId={post.id}
              originalPostContent={post.content}
              originalPostSource={post.source}
              originalPostFormat={post.format!}
              originalPostedAsCompany={post.postedAsCompany}
              shareEvent={post.shareEvent}
              onAfterShare={onAfterShare}
              sharePost={post.sharePost}
              post={post}
              isLivePage={isLivePage}
            />
          )}
        </AlertModal>
      )}
      {!isPostHidden && postActions.reportedPostId !== post.id && (
        <div
          style={{
            backgroundColor: isLivePage ? colors?.Main : '',
            borderColor: isLivePage ? colors?.Secondary : '',
          }}
            className={classNames('relative', {
            [`${className}`]: className
          })}
        >
          {/* More actions for the post like delete, edit, share, etc. */}
          {!isSpotlight && (
            <div className={"absolute top-[18.8px] right-[9px]"}>
              {post.creator && (
                <PostActions
                  post={post}
                  ownPostOptions={ownPostOptions}
                  otherUsersPostOptions={!!post?.postedAsCompany?.isAdmin ? ownPostOptions : otherUsersPostOptions}
                  isLivePage={isLivePage}
                />
              )}
            </div>
          )}
          {post.type === "recommended" && <div className={"flex flex-row justify-between"}>
            <div className="w-full border-blue p-2 border-b mb-0">
              <span className="text-sm h-20 px-3">
                <span className="text-gray-1 font-light">Recommended</span>
              </span>
            </div>
          </div>
          }

          {post?.isPostSuggestion && (
            <div className={"flex flex-row justify-between"}>
              <div className="w-full border-blue p-2 border-b mb-0">
                <span className="text-sm h-20 px-3">
                  <span className="text-gray-1 font-light">Suggested</span>
                </span>
              </div>
            </div>
          )}
          
          {post.commentedConnections &&
            post.commentedConnections.length > 0 && (
              <CommentedConnections
                connections={post.commentedConnections}
                comment={"commented on a post"}
                onUserClick={onUserClick}
              />
            )}
          {post.interestConnections && post.interestConnections.length > 0 && (
            <CommentedConnections
              connections={post.interestConnections}
              comment={"is interested in an event"}
              onUserClick={onUserClick}
            />
          )}
          <div className={"flex flex-row justify-between"}>
            {/* User or Company information including avatar, name, company name and job title */}
            {post.postedAsUser && (
              <PostByInfo
                name={`${post.postedAsUser?.firstName!} ${post.postedAsUser
                  ?.lastName!}`}
                avatar={post.postedAsUser?.avatar ?  `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${post.postedAsUser?.avatar}` : ''}
                slug={`/wall/${post.postedAsUser?.slug}`}
                companyName={getCompanyName(post.postedAsUser)}
                jobTitle={post.postedAsUser?.jobTitle}
                createdAt={post.createdAt}
                privacy={post.privacy}
                moreInfo={post.postedAsUser}
                companySlug={post?.postedAsUser?.company?.slug}
                isLivePage={isLivePage}
              />
            )}
            {post.postedAsCompany && (
              <PostByInfo
                name={post.postedAsCompany?.name}
                avatar={ post.postedAsCompany?.logo ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${post.postedAsCompany?.logo}` : ''}
                slug={`/wall/company/${post.postedAsCompany?.slug}`}
                companyName={' '}
                jobTitle={post.postedAsCompany?.industry?.name}
                createdAt={post.createdAt}
                byCompany
                privacy={post.privacy}
                moreInfo={post.postedAsCompany}
                isLivePage={isLivePage}
              />
            )}
          </div>

          {/* Content on a Post */}
          {!isCompanyNew && <PostContent isLivePage={isLivePage} content={post.content} />}
          {isCompanyNew && <PostContentCompanyPost isLivePage={isLivePage} isCompanyNew={isCompanyNew} content={post.content} format={post?.format} />}
          {post.format === "shared_company" && !!post.shareCompany && (
            <SharedCompany
              company={post.shareCompany}
            />
          )}
          {
            // Shared posts 
            (post.format === "shared_event" && !post.shareEvent) ||
            // POST only 
            (post.format === "post" && !!post.source && !post.sharePost) && (
              <div className={classNames('m-4 bg-gray-1 rounded-lg p-4 flex flex-row gap-2',{
                'h-[136px]': isCompanyNew,
              })}>
                <div className='pt-1'>
                  <img src={LockIcon} />
                </div>
                <div>
                  <div className='text-sm font-semibold'>This content isn’t available at the moment</div>
                  <div className='text-xs font-normal'>When this happens, it’s usually because the owner only shared it with a small group of people or changed who can see it, or it’s been deleted.</div>
                </div>
              </div>
            )
          }
          {post.sharePost && (
            <div
              className={classNames('bg-white border border-[#CDDCF2] rounded-lg m-auto w-[calc(100%-41px)] mt-[12px] pb-[0px] overflow-hidden rounded-b-lg', {
                'pb-[18px]': post.sharePost.format === "poll",
                'h-[270px]' : isCompanyNew ,
              })}
            >
              {(post.postedAsUser || post.postedAsCompany) &&
                post.sharePost &&
                post.sharePost.postedAsUser && (
                  <PostByInfo
                    name={`${post.sharePost.postedAsUser?.firstName} ${post.sharePost.postedAsUser?.lastName}`}
                    avatar={ post?.sharePost?.postedAsUser?.avatar ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${post?.sharePost?.postedAsUser?.avatar}` : ""}
                    slug={`/wall/${post.sharePost.postedAsUser?.slug}`}
                    companyName={getCompanyName(post.sharePost.postedAsUser)}
                    jobTitle={post.sharePost.postedAsUser?.jobTitle}
                    createdAt={post.sharePost.createdAt}
                    privacy={post.privacy}
                    moreInfo={post.sharePost.postedAsUser}
                    isLivePage={isLivePage}
                  />
                )}
              {(post.postedAsUser || post.postedAsCompany) &&
                post.sharePost &&
                post.sharePost.postedAsCompany && (
                  <PostByInfo
                    name={`${post.sharePost.postedAsCompany?.name}`}
                    avatar={ post?.sharePost?.postedAsCompany?.logo ? `${process.env.REACT_APP_IMAGE_URL_PREFIX}/${post.sharePost.postedAsCompany?.logo}` : ""}
                    slug={`/wall/${post.sharePost.postedAsCompany?.slug}`}
                    companyName={" "}
                    jobTitle={post.sharePost.postedAsCompany?.industry?.name}
                    createdAt={post.sharePost.createdAt}
                    privacy={post.privacy}
                    moreInfo={post.sharePost.postedAsCompany}
                    isLivePage={isLivePage}
                  />
                )}
              {<PostContent content={post.sharePost.content} />}
              {(post.sharePost.format === "image" ||
                post.sharePost.format === "video" ||
                post.sharePost.format === "document") && (
                  <PostAttachment
                    format={post.sharePost.format}
                    source={post.sharePost.source}
                    content={post.sharePost.content}
                    reSharePost
                    isSpotlightDetailModal={isSpotlightDetailModal}
                    isCompanyNew={isCompanyNew}
                  />
                )}
              {post.sharePost.format === "shared_event" && !!post.shareEvent && (
                <SharedEvent
                  slug={post.sharePost.shareEvent?.slug!}
                  cover={post.sharePost.shareEvent?.cover!}
                  logo={post.sharePost.shareEvent?.company?.logo!}
                  name={post.sharePost.shareEvent?.name!}
                  companyName={post.sharePost.shareEvent?.company?.name!}
                  startTime={post.sharePost.shareEvent?.startTime!}
                  endTime={post.sharePost.shareEvent?.endTime!}
                  eventFormat={post.sharePost.shareEvent?.format!}
                  eventRegions={post.sharePost.shareEvent?.eventRegions}
                  isCompanyNew={isCompanyNew}
                  isLivePage={isLivePage}
                />
              )}
              {post.format === "shared_event" && !!post.sharePost && (
                <SharedEvent
                  slug={post.sharePost?.shareEvent?.slug!}
                  cover={post.sharePost?.shareEvent?.cover!}
                  logo={post.sharePost?.shareEvent?.company?.logo!}
                  name={post.sharePost?.shareEvent?.name!}
                  companyName={post.sharePost?.shareEvent?.company?.name!}
                  startTime={post.sharePost?.shareEvent?.startTime!}
                  endTime={post.sharePost?.shareEvent?.endTime!}
                  eventFormat={post.sharePost?.shareEvent?.format!}
                  eventRegions={post.sharePost?.shareEvent?.eventRegions}
                  isCompanyNew={isCompanyNew}
                  isLivePage={isLivePage}
                />
              )}

              {post?.sharePost?.format === "poll" && (
                // <ViewPoll
                <ViewPollPost
                isCompanyNew={isCompanyNew}
                  // id={poll.value?.data?.id!}
                  // question={poll.value?.data?.question!}
                  // status={poll.value?.data?.status!}
                  // expireOption={poll.value?.data?.expireOption!}
                  // expiredAt={poll.value?.data?.expiredAt!}
                  // answers={poll.value?.data?.answers!}
                  {...post?.sharePost?.poll!}
                  isInteractive
                  isLivePage={isLivePage}
                />
              )}
            </div>
          )}
          {/* Display attached Image or Video */}
          {(post.format === "image" || post.format === "video") &&
            !isSpotlight && (
              <PostAttachment format={post.format} source={post.source} post={post} isCompanyNew={isCompanyNew} />
            )}

          {/* Display attached PDF files */}
          {post.format === "document" && post.source && !isSpotlight && (
            <PostAttachment
              format={post?.format}
              source={post?.source}
              content={post?.content}
              post={post}
              isCompanyNew={isCompanyNew}
            />
          )}

          {/* Display Poll on Post */}
          {post.format === "poll" && !post?.sharePost &&
            <ViewPollPost
              {...post?.poll!}
              isInteractive
              post={post}
              isCompanyNew={isCompanyNew}
              isLivePage={isLivePage}
            />

            // <ViewPoll
            //   {...post.poll!}
            //   isInteractive />

          }

          {post.format === "event" && post.event && (
            <SharedEvent
              slug={post.event?.slug!}
              cover={post.event?.cover!}
              logo={post.event?.company?.logo!}
              name={post.event?.name!}
              companyName={post.event?.company?.name!}
              startTime={post.event?.startTime!}
              endTime={post.event?.endTime!}
              eventFormat={post.event?.format!}
              eventRegions={post.event?.eventRegions}
              isCompanyNew={isCompanyNew}
              isLivePage={isLivePage}
            />
          )}
          {/* Display shared event as post */}
          {post.shareEvent && post.format === "shared_event" && (
            <SharedEvent
              slug={post.shareEvent?.slug!}
              cover={post.shareEvent?.cover!}
              logo={post.shareEvent?.company?.logo!}
              name={post.shareEvent?.name!}
              companyName={post.shareEvent?.company?.name!}
              startTime={post.shareEvent?.startTime!}
              endTime={post.shareEvent?.endTime!}
              eventFormat={post.shareEvent?.format!}
              eventRegions={post.shareEvent?.eventRegions}
              isCompanyNew={isCompanyNew}
              isLivePage={isLivePage}
            />
          )}

          {/* Display Likes "Emoji" on a Post */}
          <PostReactions
            post={postReactions.post}
            postId={postReactions.postId}
            totalClaps={postReactions.totalClaps}
            totalHahas={postReactions.totalHahas}
            totalLikes={postReactions.totalLikes}
            totalLoves={postReactions.totalLoves}
            totalSmiles={postReactions.totalSmiles}
            totalComments={postReactions.totalComments}
            onClickComments={() => onClickComments(post.id)}
            isCompanyNew={isCompanyNew}
            isLivePage={isLivePage}
          />

          {/* Display Like, Comments and Share section of a Post */}
          <div
            className={classNames('flex justify-between border-t-1 border-[#E2E2E2] mb-0 m-2 mt-[11px] mx-[20px] py-[5px]', {
              'mb-0': !showComments,
              '!pb-[10px]': commentsCount > 9 && !isCompanyNew,
              'border-b-1 border-[#E2E2E2]': isSpotlightDetailModal,
              '!mx-[9px] !mt-[9px] !py-[6px] !px-[14px]': isLivePage,
            })}
            style={{ borderColor: isLivePage ? colors?.Secondary : undefined }}
          >
            <div className={"flex flex-row cursor-pointer"}>
              <ActionsReact
              isLivePage={isLivePage}
                isCompanyNew={isCompanyNew}
                onClick={async (reaction: string) => {
                  onPostLike && onPostLike(reaction, post.id, "post")
                  setTimeout(async () => {
                    const postService = new PostService();
                    const updatedPost = await postService.getPost(
                      post.id
                    );
                    setPostReactions({
                      post: updatedPost,
                      postId: updatedPost.id,
                      totalClaps: updatedPost.totalClaps,
                      totalHahas: updatedPost.totalHahas,
                      totalLikes: updatedPost.totalLikes,
                      totalLoves: updatedPost.totalLoves,
                      totalSmiles: updatedPost.totalSmiles,
                      totalComments: updatedPost.totalComments,
                    })
                  }, 1000)

                }}
                defaultSelected={post.likes?.[0]?.format}
              />
            </div>
            <div className={classNames("flex flex-row items-center justify-center cursor-pointer hover:bg-[#EDF2F5] rounded-[5px] w-[160px] h-[31px]",{
              "!w-[120px]": isCompanyNew,
              "!w-auto !bg-opacity-[0.1] !px-[5px] !h-[15px] gap-[4px]": isLivePage
            })}>
              {isLivePage ? <CommentSvgIcon fillColor={colors?.Text} /> : <img alt="comment" className="mr-2 w-[22.6] h-[18.39]" src={commentIcon} />}
              
              <div
                className={classNames("text-[14px] leading-[14px] text-[#656565] font-medium",{
                  "text-[9px] !leading-[8px]": isLivePage
                })}
                onClick={() => onClickComments(post.id)}
                style={{color: isLivePage ? colors.Text : undefined}}
              >
                Comment
              </div>
            </div>
            <div
              className={classNames("flex flex-row items-center justify-center cursor-pointer hover:bg-[#EDF2F5] rounded-[5px] w-auto md:w-[160px] h-[31px]",{
                "!w-[100px]": isCompanyNew,
                "!w-auto !bg-opacity-[0.1] !px-[5px] !h-[15px] gap-[4px]": isLivePage
              })}
              onClick={() => setToggleSharePostDialog(true)}
            >
              {isLivePage ? <ShareSvgIcon fillColor={colors?.Text} /> : <img alt="share" className="mr-2 w-[22.9] h-[20.17]" src={shareIcon} />}
              
              <div
                style={{color: isLivePage ? colors.Text : undefined}}
                className={classNames("text-[14px] leading-[14px] text-[#656565] font-medium",{
                  "text-[9px] !leading-[8px]": isLivePage
                })}>

                Share
              </div>
            </div>
          </div>

          {(showComments) && (
            <>
              <div
                className={classNames('px-[20px] pb-[15px]', {
                  '!pb-[0px]': commentsCount < 1,
                  'pt-[4px]': commentsCount > 0,
                  'md:max-h-[353px] md:min-h-0 min-h-[calc(100vh-485px)] max-h-[calc(100vh-485px)] overflow-y-scroll !pt-[10px]': commentsCount > 0 && isSpotlightDetailModal,
                  '!mx-[9px] !pb-[10px] !px-[0px]': isLivePage,
                })}
              >
                {postComments &&
                  postComments?.map((comment: ICommentProps, i) => (
                    <div key={comment.id} className={"mb-[10px]"}>
                      <ViewComment
                        key={comment.id}
                        id={comment.id}
                        postId={comment.postId}
                        content={comment.content}
                        image={comment.image}
                        userId={comment.userId}
                        type={comment.type}
                        commentedAsId={comment.commentedAsId}
                        commentedAsUser={comment.commentedAsUser}
                        commentedAsCompany={comment.commentedAsCompany}
                        createdAt={comment.createdAt}
                        updatedAt={comment.updatedAt}
                        totalClaps={comment.totalClaps}
                        totalHahas={comment.totalHahas}
                        totalLikes={comment.totalLikes}
                        totalLoves={comment.totalLoves}
                        totalSmiles={comment.totalSmiles}
                        totalComments={comment.totalComments}
                        likes={comment.likes}
                        creator={comment.creator}
                        isLivePage={isLivePage}
                        onUpdatePost={async () => {
                          getPostComments && await getPostComments(post.id).then((res: any) => {
                            setPostComments(res.data);
                          })
                        }}
                        onCommentLike={async (
                          likeType: string,
                          commentId: string
                        ) => {
                          onCommentLike &&
                            (await onCommentLike(likeType, commentId));
                          getPostComments &&
                            (await getPostComments(post.id).then((res: any) => {
                              setPostComments(res.data);
                            }));
                        }}
                        addReply={async (
                          postId: string,
                          commentText: string,
                          attachment: any,
                          companyId?: string
                        ) => {
                          addReply &&
                            (await addReply(
                              postId,
                              commentText,
                              comment.id,
                              attachment,
                              companyId
                            ));
                          const updatedPostComments =
                            getPostComments && (await getPostComments(post.id));
                          setPostComments(updatedPostComments.data);
                          const postService = new PostService();
                          const updatedPost = await postService.getPost(
                            post.id
                          );
                          setCommentsCount(updatedPost.totalComments);
                        }}
                        onAfterDeleteComment={async () => {
                          const updatedPostComments =
                            getPostComments && (await getPostComments(post.id));
                          setPostComments(updatedPostComments.data);
                          const postService = new PostService();
                          const updatedPost = await postService.getPost(
                            post.id
                          );
                          setCommentsCount(updatedPost.totalComments);
                          onClickComments(post.id);
                        }}
                        
                      />
                    </div>
                  ))}
                {nextPage < totalPage && (
                  <div
                    className="text-[14px] leading-4 font-medium text-[#172746] cursor-pointer mr-2 hover:underline"
                    onClick={handleMoreComments}
                  >
                    Load more comments
                  </div>
                )}
              </div>
            </>
          )}
          {((commentsCount < COMMENTS_LIMIT && !isCompanyNew) || showComments) && (
            <div
              className={classNames('px-[20px] pb-[10px] pt-0', {
                '!pb-[10px]': commentsCount < 9,
                'fixed bottom-0 right-0 w-[calc(100%-1px)] bg-white !pb-[16px] md:w-5/12': isSpotlightDetailModal,
                '!w-full': isSpotlightDetailModal && post?.format !== "image" && post?.format !== "video" && post?.format !== "document",
                'px-[9px]': isLivePage
              })}
            >
              <CreateComment
                isLivePage={isLivePage}
                user={commentAsUser}
                setShowComments={setShowComments}
                company={commentAsCompany}
                onCommentPost={async (
                  commentText: any,
                  attachment: any,
                  companyId?: string
                ) => {
                  try {
                    if (companyId) {
                      addPostComment &&
                        (await addPostComment(
                          post.id,
                          commentText,
                          attachment,
                          companyId
                        ));
                    } else {
                      addPostComment &&
                        (await addPostComment(
                          post.id,
                          commentText,
                          attachment
                        ));
                    }

                    getPostComments &&
                      (await getPostComments(post.id).then((res: any) => {
                        setPostComments(res.data);
                      }));
                    const postService = new PostService();
                    const updatedPost = await postService.getPost(post.id);
                    setPostReactions({
                      post: updatedPost,
                      postId: updatedPost.id,
                      totalClaps: updatedPost.totalClaps,
                      totalHahas: updatedPost.totalHahas,
                      totalLikes: updatedPost.totalLikes,
                      totalLoves: updatedPost.totalLoves,
                      totalSmiles: updatedPost.totalSmiles,
                      totalComments: updatedPost.totalComments,
                    })
                    setCommentsCount(updatedPost.totalComments);
                    return true;
                  } catch (err) {
                    return false;
                  }
                }}
                isBorderTop
                isSpotlightDetailModal={isSpotlightDetailModal}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
