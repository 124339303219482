import { Badge, Box, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JOIN_TO_EVENT } from "src/constants";
import { CalendarList } from "src/screens/event/registration/event-components/calendar-list";
import { GotoList } from "src/screens/event/registration/event-components/goto-list";
import { QrService } from "src/services/QrService";
import { IAppState } from "src/store";
import { getUpcomingEvent } from "src/store/events/upcoming-event/actions";
import { useCurrentUser } from "src/utils/hooks";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Avatar } from "../../../../shared-components/V2";
import { ReactComponent as CameraIcon } from "../../../../assets/images/camera-icon.svg";
import { Template } from "./pdf-template";
import RegistrationUnderReview from "./RegistrationUnderReview";
import useStyling from "src/mui/components/hook-form/useStyling";
import DefaultAvatar from "../../../../assets/images/avatar-default.svg";
import "./MobileToolBar.css";
import { imageUrlPrefix } from "src/utils";
import { UserService } from "src/services";
import { FilUploadService } from "src/services/FileUpload";
import classNames from "classnames";

interface RegisteredCodeFormNewProps {
  eventId?: string;
  gotoHide?: boolean;
}
export const RegisteredCodeFormNew = ({
  eventId,
  gotoHide,
}: RegisteredCodeFormNewProps) => {
  const dispatch = useDispatch();
  const profilePictureRef = useRef<HTMLInputElement | null>(null);
  const user = useCurrentUser();

  useEffect(() => {
    getCurrentUserProifle();
  }, []);

  const getCurrentUserProifle = async () => {
    try {
      const res: any = await new UserService().getOwnProfile();
      setIsLoading(false);
      const userAvatar = res?.data?.avatar
        ? `${res?.data?.avatar!}`
        : DefaultAvatar;
      setProfileImage(userAvatar);
    } catch (error) {
      console.log("error", error);
    }
  };

  const { secondaryFontColor } = useStyling(null, null);

  const [qrImage, setQrImage] = useState("");
  const [profileImage, setProfileImage] = useState(DefaultAvatar);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingQrImage, setLoadingQrImage] = useState(false);
  const event = useSelector(
    (state: IAppState) =>
      state.website.event || state.upcomingEvent?.value?.data?.event
  );
  const { audienceStatus } = useSelector(
    (state: IAppState) =>
      state.upcomingEvent?.value?.data?.attendeeStatus! ?? ""
  );

  const handleProfilePictureSelected = (files: FileList) => {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    setIsLoading(true);
    let inFile = files[0];
    var form_data = new FormData();
    form_data?.append("file", inFile);

    reader.addEventListener("load", () => {
      onUploadAvatar(form_data).then(() => {
        console.log("profile is updated");
      });
    });
  };

  const onInputProfileCoverClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  const onUploadAvatar = async (data: FormData) => {
    const userService = new UserService();
    const fileUploadService = new FilUploadService();
    const uploadedFile = await fileUploadService.uploadImage(data);

    setProfileImage(uploadedFile.data.Key);
    await userService.editOwnProfile({ avatar: uploadedFile.data.key });
    setIsLoading(false);
    getCurrentUserProifle();
  };

  useEffect(() => {
    if (event?.id && user?.id && audienceStatus === "accepted") {
      setLoadingQrImage(true);
      const qrService = new QrService();
      qrService
        .getQrCode(event.id, user.id)
        .then((res: string) => {
          setQrImage(res);
          setLoadingQrImage(false);
        })
        .catch((error) => setLoadingQrImage(false));
    }
  }, [event, user, audienceStatus]);

  // useEffect(() => {
  //     if (event?.id) {
  //         dispatch(getUpcomingEvent(event?.id));
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [event?.id]);

  return (
    <>
      {((user &&
        audienceStatus === "accepted" &&
        event?.type === "invitation") ||
        (user && event?.type === "open")) && (
          <Box
          sx={{
            height:
              event?.websiteTemplate === "lenovo"
                ? "calc(100vh - 298px)"
                : "calc(100vh - 475px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="standard"
              badgeContent={
                <CameraIcon className="w-[64px] h-[64px] cursor-pointer" />
              }
              overlap="circular"
              sx={
                {
                  // "& .MuiBadge-badge": {
                  //     // backgroundColor: "#F7F7F7",
                  //     minWidth: "40px",
                  //     height: "40px",
                  //     borderRadius: "25px",
                  //     boxShadow: "0px 4px 10px 0px #080D461F",
                  //     position: "absolute",
                  //     top: "42px",
                  //     cursor: "pointer",
                  // },
                }
              }
              onClick={() => {
                profilePictureRef?.current?.click();
              }}
            >
              <Avatar
                src={`${imageUrlPrefix}/${profileImage}`}
                size={"XXLARGE"}
                className="border-[3px] !w-[204px] !h-[204px] border-white bg-[#edf2f5]"
              />

              {isLoading && (
                <div className="loader-overlay">
                  {/* <div className="loader">Loading...</div> */}
                  Loading...
                </div>
              )}
            </Badge>
            <input
              type="file"
              id="profilePicture"
              ref={profilePictureRef}
              className="hidden"
              accept="image/*"
              onChange={(e) => handleProfilePictureSelected(e.target?.files!)}
              onClick={onInputProfileCoverClick}
            />
            <Typography
              sx={{
                width: "400px",
                textAlign: "center",
                color: secondaryFontColor,
              }}
            >
              {event?.confirmationMessage && <>{event?.confirmationMessage}</>}
            </Typography>
            <Stack
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                "@media (max-width: 768px)": {
                  flexDirection: "column",
                },
              }}
            >
              <>
                {!gotoHide && (
                  <GotoList
                    fillColor="white"
                    titleClass="mr-[8px] flex items-center justify-center text-white text-[16px] leading-[40px] helvetica-neue-W05_85-heavy"
                    btnClassName="bg-transparent border-2 border-white text-white rounded-5 h-[40px] px-[9px] flex items-center"
                    title="Go to"
                    dropdownClass="w-[141px] bg-black !top-[35px] border-1 border-white !rounded-5"
                    isRegoForm
                  />
                )}

                <CalendarList
                  fillColor="white"
                  titleClass="mr-[8px] flex items-center justify-center text-white text-[16px] leading-[40px] helvetica-neue-W05_85-heavy"
                  btnClassName="bg-transparent border-2 border-white rounded-5 h-[40px] px-[9px] flex items-center"
                  title="Add to calendar"
                  dropdownClass="w-[225px] bg-black !top-[35px] border-1 border-white !rounded-5"
                  isRegoForm
                />

                <PDFDownloadLink
                  document={
                    <Template
                      pdfData={{
                        qrImage,
                        message: JOIN_TO_EVENT.REGISTERED,
                        organizerMessage: event?.confirmationMessage,
                        eventName: event?.name,
                        location: event?.eventRegions[0]?.name,
                        dateTime: `${`${event?.startTime} ${event?.timezone}`}`,
                      
                      }}
                    />
                  }
                  fileName={`${event?.name}.pdf`}
                >
                  {({ loading }) =>
                    loading ? (
                      "Loading pdf..."
                    ) : (
                      <button
                        type="button"
                        className={classNames(
                          "sm:px-[12px] px-[12px] md:h-[40px] h-[40px] bg-transparent border-2 border-white rounded-5 flex items-center justify-center text-white md:text-[16px] text-[15px] leading-[40px] helvetica-neue-W05_85-heavy"
                        )}
                      >
                        <div className="h-[40px]">Itinerary PDF</div>
                      </button>
                    )
                  }
                </PDFDownloadLink>
              </>
            </Stack>
          </Stack>
        </Box>
      )}
      </>
  );
};

export default RegisteredCodeFormNew;
