import React from 'react';

interface INotificationBadge {
  countUnseenNotifications: number;
}
const NotificationBadge = ({ countUnseenNotifications }: INotificationBadge) => {
  return (
    <>
      {countUnseenNotifications && (
        <div className="w-[20px] h-[20px] rounded-full bg-[#FF0000] text-white absolute top-0 ml-7 z-10 flex justify-center">
          <div className="text-s-10 leading-[20px] font-medium">{countUnseenNotifications}</div>
        </div>
      )}
    </>
  );
};

export default NotificationBadge;
