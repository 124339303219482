import { Reducer } from 'redux';

import { ICompanyCoverPhotoState } from '../../../models/company/CoverPhotoState';
import { CoverPhotoActions } from './actions';
import { CoverPhotoActionTypes } from './types';

const initialState: ICompanyCoverPhotoState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const SetCoverPhotoReducer: Reducer<ICompanyCoverPhotoState, CoverPhotoActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CoverPhotoActionTypes.SET_COMPANY_COVER_PHOTO: {
      return {
        ...state,
        value: action.url
      };
    }

    case CoverPhotoActionTypes.COVER_COMPANY_PHOTO_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    case CoverPhotoActionTypes.RESET_COVER_COMPANY_PHOTO: {
      return {
        ...state,
        value: null
      };
    }

    default:
      return state;
  }
};
