import React from 'react';
import {
  Document, Page, Text, Image,
  StyleSheet, Font
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 5px 5px 10px'
  },
  image: {
    maxWidth: '150px',
    maxHeight: '150px',
  },
  textNormal: {
    fontSize: '13px',
    width: '80%',
    lineHeight: '1px',
    marginVertical: '10px'
  },
  textHeading: {
    fontSize: '18px',
    width: '80%',
    fontFamily: 'Poppins',
    fontWeight: 700,
    marginVertical: '10px'
  },
  textBold: {
    fontSize: '13px',
    marginTop: '15px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    width: '80%',
    marginVertical: '10px'
  },
});

Font.register({
  family: 'Poppins',
  src: ('https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2'),
  fontWeight: 700
});

export const Template = ({ pdfData }: any) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.textNormal}>You are going to</Text>
      <Text style={styles.textHeading}>{pdfData?.eventName}</Text>
      {pdfData?.organizerMessage && (
        <>
          <Text style={styles.textBold}>
            <b>Organizer Message:</b>
            {' '}
          </Text>
          <Text>{pdfData?.organizerMessage}</Text>
        </>
      )}
      <Text style={styles.textNormal}>
        Date Time:
        {pdfData?.dateTime}
      </Text>
      <Text style={styles.textNormal}>
        Location :
        {pdfData?.location}
      </Text>
      <Text style={styles.textBold}>Check In : </Text>
      <Image style={styles.image} src={pdfData?.qrImage} />
      <Text style={styles.textNormal}>Note:</Text>
      <Text style={styles.textNormal}>
        You will shortly receive an email confirming your registration along with event details.
        Check your spam folder if you have not received an email in your inbox within the next 5 minutes.
      </Text>
      <Text style={styles.textNormal}>
        For multiple purchases,
        each attendee will receive a seperate email to complete the information required by the organizer.
      </Text>
    </Page>
  </Document>
);
