import React from "react";

export interface LockPostSvgIconProps {
    fillColor?: string;
}
export const LockPostSvgIcon = ({ fillColor }: LockPostSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#3c3c3c"
    return <svg id="lock" xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 14.247 16.885">
    <path id="XMLID_519_" d="M50.929,16.885H63.318a.86.86,0,0,0,.929-.768v-8.7a.86.86,0,0,0-.929-.767h-.929v-2.3c0-2.4-2.362-4.349-5.265-4.349s-5.265,1.951-5.265,4.349v2.3h-.929A.86.86,0,0,0,50,7.419v8.7A.86.86,0,0,0,50.929,16.885ZM53.717,4.349a3.469,3.469,0,0,1,6.814,0v2.3H53.717Z" transform="translate(-50)" fill={iconColor}/>
  </svg>
  
};