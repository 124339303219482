import { useState } from 'react';
import WebsiteEventQuickLinks from '../website-event-quicklinks';
import RegisterForm from './register-form';

export const RegistrationFormTwo = () => {
  const [isAttendeeForm, setAttendeeForm] = useState(false);
  return (
    <div className="w-full">
      <div className="flex -mb-[30px]">
        <div className="bg-[#2d2d2d] pt-[20px] pr-[40px] pb-[40px] pl-[40px] w-full md:max-w-[60%]">
          <div className="w-[122px] h-[32px] rounded-full bg-white text-black text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer mb-[20px]">Back to event</div>
          <div className="text-white text-[45px] leading-[45px] helvetica-neue-55-roman">Sit-down</div>
          <div className="text-white text-[21px] leading-[25px] helvetica-neue-55-roman">CIO • CDO • CISO</div>
          <div className="text-white text-[20px] leading-[20px] helvetica-neue-45-light mt-[20px] mb-[40px]">Register</div>
          <RegisterForm />
          {isAttendeeForm && (
            <div className="mt-4">
              <div className="text-white text-[13px] leading-[20px] helvetica-neue-45-light mt-[20px] mb-[20px]">ANOTHER ATTENDEE</div>
              <RegisterForm />
            </div>
          )}
          <div className="flex justify-end">
            <div className="mt-[16px] mr-[10px]">
              <button
                type="submit"
                className="px-[20px] h-[32px] rounded-full bg-white text-black text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer mb-[20px]"
                onClick={() => setAttendeeForm(true)}
              >
                Add attendee
              </button>
            </div>
            <div className="mt-[16px]">
              <button
                type="submit"
                className="px-[20px] h-[32px] rounded-full bg-[#0071e3] text-white text-[12px] flex items-center justify-center helvetica-neue-45-light cursor-pointer mb-[20px]"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div className="reg-bg md:w-[40%]" />
      </div>
      <WebsiteEventQuickLinks />
    </div>
  );
};
export default RegistrationFormTwo;
