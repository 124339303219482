import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CardCompanyAdmins } from '../../../shared-components';

import { IAppState } from '../../../store';
import { getUpcomingEventSpeakers } from '../../../store/events/upcominn-event-speakers/actions';
import {
  setConnectSuggestion
} from '../../../store/user/connect-suggestions/actions';
import { openMessageWindow } from '../../../store/newMessage';
import { getOwnConnections } from '../../../store/user/own-connections/actions';
import { ConnectionsState } from '../../../models/ConnectionsState';

interface IEventSpeakersProps {
  eventIdOrSlug: string;
}

export const EventSpeakers = (props: IEventSpeakersProps) => {
  const dispatch = useDispatch();
  const { upcomingEventSpeakers } = useSelector((state: IAppState) => state);
  const connectsSelector: ConnectionsState = useSelector((state: IAppState) => state.connections);
  const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;

  useEffect(() => {
    if(props.eventIdOrSlug)
      dispatch(getUpcomingEventSpeakers(props.eventIdOrSlug));
  }, [dispatch, props.eventIdOrSlug]);

  useEffect(() => {
    dispatch(getOwnConnections());
  }, [dispatch]);

  const handleButtons = async (e: any, speaker: any) => {
    if (e.currentTarget.textContent === 'Message') {
      dispatch(
        openMessageWindow({
          avatar: speaker.avatar,
          userDisplayName: `${speaker.firstName} ${speaker.lastName}`,
          userId: speaker.id
        })
      );
    } else dispatch(setConnectSuggestion(speaker.id));
  };
  
  return (
    <>
      <div className="">
        {upcomingEventSpeakers.value &&
          upcomingEventSpeakers.value.data.length > 0 &&
          upcomingEventSpeakers.value.data.map((speaker, i) => {
            const secondaryButtonText = speaker.sentRequest ? 'Pending' :
              connectsSelector?.value?.data.some((person) => person.id === speaker.id)
                ? 'Connected'
                : 'Connect';
            return (
            <div key={`speaker-${i}`} className="mr-4">
              <CardCompanyAdmins
                containerClass="mt-[21px] sm:mb-0 mb-[20px]"
                avatarUrl={`${imageUrlPrefix}/${speaker.eventSpeakers[0].photo}`}
                title={`${speaker.eventSpeakers[0].firstName} ${speaker.eventSpeakers[0].lastName}`}
                subTitle={speaker.eventSpeakers[0].jobTitle}
                location={speaker.eventSpeakers[0].company.name}
                titleClass="text-[#172746] text-[14px] leading-[17px] font-semibold hover:underline line-clamp-1 w-315"
                subTitleClass="text-[#172746] text-[12px] font-normal leading-[16px] line-clamp-1 w-315"
                locationClass="text-[#172746] text-[12px] font-normal leading-[16px] line-clamp-1 w-315"
                detailsHref={`/wall/${speaker.slug}`}
                {...( speaker.status === 'active' && {
                  primaryButton: "rounded-[5px] h-8 w-24 px-5 py-1.5 bg-gray-1 mr-2 sm:mr-0 hover:bg-gray-7 active:bg-gray-7 text-blue-2 font-semibold text-sm sm:absolute mt-2.5 right-28",
                  primaryButtonText: "Message",
                  secondaryButton: "rounded-[5px] h-8 w-24 px-5 py-1.5 bg-[#0049EF] hover:bg-[#2e6eff] active:bg-blue-3 text-white font-semibold text-sm sm:absolute mt-2.5 right-0",
                  secondaryButtonText
                })}
                
                onClick={(e) => handleButtons(e, speaker)}
              />
              <div className="border-blue border-b mt-2"></div>
            </div>
          )})}
      </div>
    </>
  );
};

export default EventSpeakers;
