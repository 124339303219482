import React from 'react';
import './CircularLoader.css';

const CircularLoader = ({ percentage, isDouble, barColor, circleColor, textColor, matchText }: any) => {
  const radius = 52.81;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  const getCircleValues: any = {
    10: '1060.491,332.267',
    20: '1050.491,316.267',
    30: '1052.491,290.267',
    40: '1055.491,283.267',
    50: '1090.491,265.267',
    60: '1107.491,267',
    70: '1125,283',
    80: '1130.491,302.267',
    90: '1130.491,302.267',
    100: '1130.491,302.267',
  };

  const getDashedArray: any = {
    10: '330',
    20: '315',
    30: '310',
    40: '285',
    50: '295',
    60: '280',
    70: '270',
    80: '365',
    90: '365',
    100: '365',
  };

  const getTextValues: any = {
    10: '1064.491,343.267',
    20: '1053.491,326.267',
    30: '1055.491,301.267',
    40: '1058.491,293.267',
    50: '1093.491,275.267',
    60: '1110.491,277.267',
    70: '1129,295',
    80: '1134,314',
    90: '1134,314',
    100: '1134,314',
  };

  return (
    <div className="circular-loader">
      {isDouble ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="125.732"
          height="125.732"
          viewBox="0 0 125.732 125.732"
        >
          <defs>
            <filter
              id="Path_27128"
              x="0"
              y="0"
              width="125.732"
              height="125.732"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="3" result="blur" />
              <feFlood floodOpacity="0" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g id="Group_21645" data-name="Group 21645" transform="translate(-1036.538 -253.446)">
            <g id="Group_21622" data-name="Group 21622">
              <g
                id="Path_27127"
                data-name="Path 27127"
                transform="translate(1045.69 261.162)"
                fill="none"
              >
                <path
                  d="M52.81,0A52.555,52.555,0,1,1,0,52.554,52.682,52.682,0,0,1,52.81,0Z"
                  stroke="none"
                />
                <path
                  d="M 52.80968856811523 23.99999237060547 C 36.92395782470703 23.99999237060547 24 36.80926513671875 24 52.553955078125 C 24 68.29864501953125 36.92395782470703 81.10791778564453 52.80968856811523 81.10791778564453 C 68.69541931152344 81.10791778564453 81.619384765625 68.29864501953125 81.619384765625 52.553955078125 C 81.619384765625 36.80926513671875 68.69541931152344 23.99999237060547 52.80968856811523 23.99999237060547 M 52.80968856811523 -7.62939453125e-06 C 81.97569274902344 -7.62939453125e-06 105.6193771362305 23.52918243408203 105.6193771362305 52.553955078125 C 105.6193771362305 81.57868957519531 81.97569274902344 105.1079177856445 52.80968856811523 105.1079177856445 C 23.64369201660156 105.1079177856445 0 81.57868957519531 0 52.553955078125 C 0 23.52918243408203 23.64369201660156 -7.62939453125e-06 52.80968856811523 -7.62939453125e-06 Z"
                  stroke="none"
                  fill={circleColor}
                />
              </g>
              <g transform="matrix(1, 0, 0, 1, 1036.54, 253.45)" filter="url(#Path_27128)">
                {percentage !== 0 && (
                <path
                  id="Path_27128-2"
                  data-name="Path 27128"
                  d="M53.866,94.732A40.866,40.866,0,1,1,94.732,53.866"
                  transform="translate(9 6)"
                  fill="none"
                  stroke={barColor}
                  strokeLinecap="round"
                  strokeWidth="26"
                  opacity="0.997"
                  strokeDasharray={getDashedArray[percentage]}
                  strokeDashoffset={offset}
                />
              )}
              </g>
              <g id="Group_21208" data-name="Group 21208" transform="translate(1085 297)">
                <rect
                  id="Rectangle_8400"
                  data-name="Rectangle 8400"
                  width="28.824"
                  height="11.868"
                  rx="3"
                  transform="translate(0 0)"
                  fill={barColor}
                />
                <text id="Match" transform="translate(14.391 8.272)" style={{ color: matchText }} fill={matchText} fontSize="7">
                  <tspan style={{ color: matchText }} x="-9.707" y="0">
                    Match
                  </tspan>
                </text>
              </g>
              <text id="_82_" data-name="82%" transform="translate(1099.641 327.817)" fontSize="18" fill={textColor}>
                <tspan x="-17.068" y="0">
                  {percentage}%
                </tspan>
              </text>
            </g>
            <circle
              id="Ellipse_1348"
              data-name="Ellipse 1348"
              cx="8.933"
              cy="8.933"
              r="8.933"
              transform={`translate(${getCircleValues[percentage]?.split(',')[0]} ${
                getCircleValues[percentage]?.split(',')[1]
              })`}
              fill={textColor}
            />
            <text
              id="_82_2"
              data-name="82%"
              transform={`translate(${getTextValues[percentage]?.split(',')[0]} ${
                getTextValues[percentage]?.split(',')[1]
              })`}
              fill={barColor}
              fontSize="6"
            >
              <tspan x="0" y="0">
                {percentage}%
              </tspan>
            </text>
            <circle
              id="Ellipse_1349"
              data-name="Ellipse 1349"
              cx="8.912"
              cy="8.912"
              r="8.912"
              transform="translate(1090.491 345.267)"
              fill={textColor}
            />
          </g>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="188.569"
          height="188.569"
          viewBox="0 0 188.569 188.569"
        >
          <defs>
            <filter
              id="Path_27137"
              x="0"
              y="0"
              width="188.569"
              height="188.569"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="3" result="blur" />
              <feFlood floodOpacity="0" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g id="Group_21564" data-name="Group 21564" transform="translate(15.018 12.233)">
            <g id="Path_27136" data-name="Path 27136" transform="translate(0 0)" fill="none">
              <path
                d="M79.73,0c44.034,0,79.73,35.523,79.73,79.344s-35.7,79.344-79.73,79.344S0,123.164,0,79.344,35.7,0,79.73,0Z"
                stroke="none"
              />
              <path
                d="M 79.72974395751953 30.99999237060547 C 52.86004638671875 30.99999237060547 31 52.68684387207031 31 79.34364318847656 C 31 106.0004425048828 52.86004638671875 127.6872940063477 79.72974395751953 127.6872940063477 C 106.5994415283203 127.6872940063477 128.4594879150391 106.0004425048828 128.4594879150391 79.34364318847656 C 128.4594879150391 52.68684387207031 106.5994415283203 30.99999237060547 79.72974395751953 30.99999237060547 M 79.72974395751953 0 C 123.7632446289062 0 159.4594879150391 35.52334594726562 159.4594879150391 79.34364318847656 C 159.4594879150391 123.1639404296875 123.7632446289062 158.6872863769531 79.72974395751953 158.6872863769531 C 35.69619750976562 158.6872863769531 0 123.1639404296875 0 79.34364318847656 C 0 35.52334594726562 35.69619750976562 0 79.72974395751953 0 Z"
                stroke="none"
                fill="#fff"
              />
            </g>
            <g transform="matrix(1, 0, 0, 1, -15.02, -12.23)" filter="url(#Path_27137)">
              <path
                id="Path_27137-2"
                data-name="Path 27137"
                d="M79.785,146.569a66.785,66.785,0,1,1,66.785-66.785"
                transform="translate(14.5 11.5)"
                fill="none"
                stroke="#0049ef"
                strokeLinecap="round"
                strokeWidth="37"
                opacity="0.997"
              />
            </g>
            <circle
              id="Ellipse_1346"
              data-name="Ellipse 1346"
              cx="13.408"
              cy="13.408"
              r="13.408"
              transform="translate(132.644 64.889)"
              fill={textColor}
            />
            <text
              id="_82_"
              data-name="82%"
              transform="translate(137.051 82.344)"
              fontSize="9"
              fontFamily="SegoeUI, Segoe UI"
            >
              <tspan x="0" y="0">
                82%
              </tspan>
            </text>
            <text
              id="_82_2"
              data-name="82%"
              transform="translate(79.267 90.797)"
              fill="#203c6e"
              fontSize="35"
              fontFamily="SegoeUI, Segoe UI"
            >
              <tspan x="-33.188" y="0">
                82%
              </tspan>
            </text>
          </g>
        </svg>
      )}
    </div>
  );
};

export default CircularLoader;
