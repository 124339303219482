/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import React from 'react';
import VideoPlayer from '../video-player';

interface FactorPeopleProps {
  videos?: any[];
}

export const FactorPeopleReviewSection: React.FC<FactorPeopleProps> = ({
  videos,
}) => (
  <div className="flex flex-col lg:flex-row gap-x-[53px] md:mt-[155px] mt-10 mb-[109px]">
    <div className="w-full lg:max-w-[375px] lg:min-w-[375px]">
      <div className="text-white font-medium helvetica-neue-65-medium text-[22px] leading-[27px]">
        What People Say
      </div>
      <div className="w-[29px] h-[5px] bg-[#D6244D] mt-[19px] mb-[19px]" />
      <div className="text-white font-medium helvetica-neue-65-medium text-[22px] leading-[27px]">
        Factor
      </div>
      <div className="text-white md:leading-[42px] leading-[34px] md:text-[38px] text-[26px] font-bold helvetica-neue-65-medium text-left">
        Day Experience
      </div>
      <div className="w-[29px] h-[5px] bg-[#D6244D] mt-[30px]" />
      <div className="text-white md:leading-[22px] leading-[19px] md:text-[17px] text-[14px] helvetica-neue-55-roman mt-[23px]">
        Factor Day events are a trusted source of valuable content and relationships for enterprise executives. These events offer a platform for industry leaders to engage in discussions, access cutting-edge research, and network with peers. By fostering collaboration and knowledge sharing, Factor Day events help executives stay ahead of industry trends, drive innovation, and enhance their strategic initiatives, ensuring they leave with actionable insights and strengthened professional connections.
      </div>
    </div>
    {videos && videos.length > 0 && (
      <div className="w-full lg:max-w-[calc(100%-430px)] flex justify-center items-center lg:mt-0 mt-[30px]">
        <div className="w-full max-w-full h-full relative rounded-[10px]">
          <VideoPlayer classes="overviewVideo h-[300px]" singleVideoClass="overview-single-video" videos={videos} isOnMobile />
        </div>
      </div>
    )}
  </div>
);
