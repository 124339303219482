import { DeviceLabels } from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import BaseService from './BaseService';

export const joinExpoMeeting = async (invitation: any, meetingManager: any, autoStart: boolean = true) => {
  // const logger = new ConsoleLogger('Chime Service', LogLevel.OFF);
  const createMeetingResponseData = {
    MeetingId: invitation.MeetingId,
    MediaPlacement: invitation.MediaPlacement || invitation.chimeMeeting?.MediaPlacement
  };
  const createAttendeeResponseData = {
    ExternalUserId: invitation.ExternalUserId || invitation.attendeeData?.ExternalUserId || invitation.attendeeChimeInfo?.ExternalUserId,
    AttendeeId: invitation.AttendeeId || invitation.attendeeData?.AttendeeId || invitation.attendeeChimeInfo?.AttendeeId,
    JoinToken: invitation.JoinToken || invitation.attendeeData?.JoinToken || invitation.attendeeChimeInfo?.JoinToken
  };

  // request to chime to create a meeting
  const configuration = new MeetingSessionConfiguration(
    createMeetingResponseData,
    createAttendeeResponseData
  );

  const options = {
    deviceLabels: DeviceLabels.AudioAndVideo,
  };

  // Join to chime meeting
  await meetingManager.join(configuration, options);
  // Start meeting
  if (autoStart) await meetingManager.start();
};
export class ChimeService extends BaseService {
  async loginToMeeting(invitation: string, meetingManager: any, autoStart: boolean = true) {
    return joinExpoMeeting(invitation, meetingManager, autoStart);
  }
}
