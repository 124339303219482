import { Reducer } from 'redux';
import { SelectedPackageState } from '../../models/SelectedPackageState';
import { SetSelectedPackageAction } from './actions';
import { SetSelectedPackageTypes } from './types';

const initialState: SelectedPackageState = {
  selectedPackage: 'Professional'
};

export const SetSelectedPackageReducer: Reducer<SelectedPackageState, SetSelectedPackageAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SetSelectedPackageTypes.SET_SELECTED_PACKAGE: {
      return {
        selectedPackage: action.selectedPackage.selectedPackage
      };
    }

    default:
      return state;
  }
};
