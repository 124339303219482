/* eslint-disable jsx-a11y/anchor-is-valid */
const CookieTableOne = () => (
  <div className="bg-gray-0 w-full">
    <div className="block w-9/12 mx-auto">
      <hr className="my-10" />
      <div className="mt-3 text-gray-1 table-of-contents text-lg5">
        We use these technologies for:
      </div>

      <div className="mt-8 flex flex-col text-md3">
        <div className="inline-block mx-auto max-w-6xl py-2 align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left  font-semibold sm:pl-6">
                    Purpose
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left  font-semibold text-gray-900">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr>
                  <td className="py-4 pl-4 pr-3 sm:pl-6">Authentication</td>
                  <td className="px-3 py-4 font-light tracking-wide">
                    We use cookies to recognise you when you visit our Services.
                    <br />
                    If you’re signed into Panelist, cookies help us show you the right
                    information and personalise your experience in line with your settings.
                  </td>
                </tr>
                <tr className="bg-gray-1">
                  <td className="py-4 pl-4 pr-3 sm:pl-6">Security</td>
                  <td className="px-3 py-4 font-light tracking-wide">
                    We use cookies to recognise you when you visit our Services.
                    <br />
                    If you’re signed into Panelist, cookies help us show you the right
                    information and personalise your experience in line with your settings.
                  </td>
                </tr>
                <tr className="">
                  <td className="py-4 pl-4 pr-3 sm:pl-6">Preferences, features and services</td>
                  <td className="px-3 py-4 font-light tracking-wide">
                    We use cookies to enable the functionality of our Services, and provide you
                    with features, insights and customised content. We also use these technologies
                    to remember information about your browser and your preferences.
                  </td>
                </tr>
                <tr className="bg-gray-1">
                  <td className="py-4 pl-4 pr-3 sm:pl-6">Functional</td>
                  <td className="px-3 py-4 font-light tracking-wide">
                    We use cookies to improve your experience on our Services.
                  </td>
                </tr>
                <tr className="">
                  <td className="py-4 pl-4 pr-3 sm:pl-6">Browser Controls</td>
                  <td className="px-3 py-4 font-light tracking-wide">
                    Most browsers allow you to control cookies through their settings, which may
                    be adapted to reflect your consent to the use of cookies. Further, most
                    browsers also enable you to review and erase cookies, including Panelist
                    cookies. To learn more about browser controls, please consult the
                    documentation that your browser manufacturer provides.
                  </td>
                </tr>
                <tr className="bg-gray-1">
                  <td className="py-4 pl-4 pr-3 sm:pl-6">Personalised Advertising</td>
                  <td className="px-3 py-4 font-light tracking-wide">
                    Cookies help us show you relevant advertising, both on and off our Services
                    and measure the performance of such ads and report on it. We use cookies to
                    learn whether content has been shown to you or whether someone who was
                    presented with an ad later came back and took an action (e.g., downloaded a
                    document or made a purchase) on another site. Similarly, our partners or
                    service providers may use cookies to determine whether we’ve shown an ad or a
                    post and how it performed or provide us with information about how you
                    interact with ads. We may also work with our customers and partners to show
                    you an ad on or off Panelist, such as after you’ve visited a customer’s or
                    partner’s site or application. For further information regarding the use of
                    cookies for advertising purposes, please see Section 14 of the Privacy Policy.
                  </td>
                </tr>
                <tr className="">
                  <td className="py-4 pl-4 pr-3 sm:pl-6">Manage Cookie Preferences</td>
                  <td className="px-3 py-4 font-light tracking-wide w-10/12">
                    Where required, we enable you to reject the use of certain cookies for
                    purposes such as advertising and analytics and research, by visiting your
                    cookie settings, without affecting the lawfulness of processing based on your
                    consent before its withdrawal. For members, your settings can be found
                    <a
                      className="text-blue-1 underline hover:no-underline ml-1 font-medium"
                      href="#"
                    >
                      here
                    </a>
                    . For guests, your settings can be found
                    <a
                      className="text-blue-1 underline hover:no-underline ml-1 font-medium"
                      href="#"
                    >
                      here
                    </a>
                    . You can change your preferences at any time by visiting these settings.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-5">
        We believe in being open about these technologies we use when our users are operating the
        Platform. This includes the technologies we use to collect and use data provided by you.
        We hope that this Cookie Policy helps you understand, and feel more confident about, our
        use of Cookies.
      </div>

      <div className="mt-5">
        If you have any further queries or requests relating to this Policy, please contact
        [insert your privacy or admin email address].
      </div>

      <div className="mt-5">
        By using the Platform, you agree that we can use these Cookies and other technologies as
        described in this Cookie Policy. We may change this Cookie Policy at any time. Please look
        at the Last Updated date at the start of this policy to see when this Cookie Policy was
        last updated. Any changes in this Cookie Policy will become effective when we make the
        revised Cookie Policy available on or through the Platform
      </div>

      <div className="mt-5">
        This Cookie Policy explains what these technologies are, what we use them for, how they
        are used, how third-party partners may use these technologies on our Platform, and your
        choices regarding these.
      </div>
    </div>
  </div>
);

export default CookieTableOne;
