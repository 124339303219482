/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { useLocation, useNavigate } from 'react-router';
import { useCurrentUser } from 'src/utils/hooks';
import RegistrationStepForm from 'src/mui/pages/event/reg-dialog-flow';
import { getTickets } from 'src/store/ticket';
import { EventQuestionPageTypes, useSurveyContext } from 'src/mui/providers/GeneralContext';
import { dateAndTimeWithTz, formatDateRange, formatDayMonth } from 'src/utils/date-timezone';
import { getTimeZone } from 'src/utils/timezones';
import { format, parseISO } from 'date-fns';
import factorLogo from 'src/assets/images/Factor_logo_wordmark_white.png';
import { GotoList } from '../../registration/event-components/goto-list';
import { CalendarList } from '../../registration/event-components/calendar-list';

interface BannerCaptionProps {
  selectedTab?: string;
  setSelectedTab?: (value: string) => void;
}

const BannerCaption: React.FC<BannerCaptionProps> = ({
  selectedTab,
  setSelectedTab
}) => {
  // hooks
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const { getQuestionPages, setEventIdParam } = useSurveyContext();
  const location = useLocation();

  // component state
  const [showModal, setShowModal] = useState(false);

  // reducx state
  const upcomingEvent = useSelector(
    (state: IAppState) => state.upcomingEvent
  );
  const registered = useSelector(
    (state: IAppState) => state.website.registered
  );
  const event = useSelector((state: IAppState) => state.website.event!);
  const isSurveySubmitted = upcomingEvent?.value?.data?.attendeeStatus?.isSurveySubmitted;
  const audienceStatus = upcomingEvent?.value?.data?.attendeeStatus?.audienceStatus;
  const isRegistered = upcomingEvent?.value?.data?.attendeeStatus?.isRegistered;
  const queryParams = new URLSearchParams(window.location.search);
  const eventFrom = queryParams.get('eventFrom');

  const isCioDaySydneyEvent = event?.id === '839c9658-39db-4595-b9ee-4868036f1fe5';

  // functions
  const handleRegistration = () => {
    if (!registered) setShowModal(true);
    if (user && registered) navigate(`/event/${event?.slug}/discussion`);
    if (
      user
      && registered === false
      && event?.type === 'invitation'
      && audienceStatus === 'accepted'
    ) { navigate(`/event/${event?.slug}/discussion`); }
  };

  const showPortalButton = () => {
    if (event?.type === 'open') {
      if ((registered || isRegistered)) return true;
      return false;
    }

    if (event?.type === 'invitation') {
      if ((audienceStatus === 'accepted' || (audienceStatus === 'pending' && !isSurveySubmitted)) && (registered || isRegistered)) return true;
      return false;
    }
    return false;
  };

  const eventDateTimeWithTz = useMemo(() => {
    const startTime = dateAndTimeWithTz(event?.startTime, event?.timezone);
    const endTime = dateAndTimeWithTz(event?.endTime, event?.timezone);
    const formattedDateRange = formatDateRange(
      new Date(event?.startTime),
      new Date(event?.endTime)
    );
    const formattedStartDate: string = formatDayMonth(event.startTime);
    const formattedEndDate: string = formatDayMonth(event.endTime);
    return {
      startTime,
      endTime,
      formattedDateRange,
      formattedStartDate,
      formattedEndDate,
    };
  }, [event?.startTime, event?.endTime, event?.timezone]);

  const eventDateTimeLocalTz = useMemo(() => {
    const startTime = format(parseISO(event?.startTime), 'hh:mm aa')?.replace('.', '')?.replace(' ', '')?.replace('.', '');
    const endTime = format(parseISO(event?.endTime), 'hh:mm aa')?.replace('.', '')?.replace(' ', '')?.replace('.', '');
    const timeZoneMatched = new Date(eventDateTimeWithTz.startTime.dateAndTime).getMinutes()

      === new Date(parseISO(event?.startTime)).getMinutes()

      && new Date(eventDateTimeWithTz.startTime.dateAndTime).getHours()

      === new Date(parseISO(event?.startTime)).getHours();

    return {
      startTime,
      endTime,
      timeZoneMatched
    };
  }, [event?.startTime, event?.endTime]);

  const getTimeZoneName = (zone: string) => {
    const shortTimeZone = getTimeZone(zone)?.[0]?.abbr ? getTimeZone(zone)?.[0]?.abbr : zone;
    return shortTimeZone;
  };

  const handleProgramPage = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('tab', 'Program');
    navigate({
      pathname: location.pathname,
      search: queryParams.toString()
    });
    if (setSelectedTab) {
      setSelectedTab('Program');
    }
  };

  // methods
  useEffect(() => {
    if (user) {
      dispatch(getTickets(event.id));
    }
  }, [dispatch, event.id, user]);

  useEffect(() => {
    if (event?.id) {
      setEventIdParam(event?.id!);
      if (user) {
        getQuestionPages(EventQuestionPageTypes.REGISTRATION, event?.id!);
        getQuestionPages(EventQuestionPageTypes.SURVEY, event?.id!);
      }
    }
  }, [event?.id, user]);

  useEffect(() => {
    if (eventFrom === 'register') {
      setShowModal(true);
    }
  }, [eventFrom]);

  return (
    <>
      <div className="md:pr-[18px] lg:my-0 my-[36px] flex-1 justify-items-start justify-start items-start md:justify-items-start md:block md:w-auto w-full md:min-w-[503px]">
        <div className="text-white md:text-left text-left md:leading-[17px] leading-[15px] md:text-[15px] max-w-[90px] text-[11px] !font-semibold helvetica-neue-55-roman uppercase mb-[8px]"><img className="object-contain max-w-[90px]" src={factorLogo} alt="factor" /></div>
        <div className="text-white md:text-left text-left md:leading-[70px] leading-[42px] md:text-[65px] text-[37px] font-bold helvetica-neue-65-medium">{event?.name}</div>
        <div
          className={classNames('text-white leading-[19px] md:text-[17px] text-[14px] md:text-left text-center items-left capitalize helvetica-neue-45-light mb-[3px] mt-[15px] !font-semibold', {
            hidden: !eventDateTimeWithTz.startTime.date,
          })}
        >
          {eventDateTimeWithTz?.formattedDateRange}
        </div>
        <div className="text-white md:text-left text-left leading-[19px] md:text-[17px] text-[14px] helvetica-neue-45-light !font-semibold">{event?.locationName || ''}</div>
        {isCioDaySydneyEvent ? (
          <div className="mt-[14px] mb-[14px]">
            <div
              className={classNames('text-white leading-[19px] md:text-[17px] text-[14px] md:text-left text-center items-left capitalize helvetica-neue-45-light mb-[3px] mt-[6px] !font-semibold', {
                hidden: !eventDateTimeWithTz.formattedStartDate,
              })}
            >
              {eventDateTimeWithTz?.formattedStartDate}
              {' '}
              - Pre Conference Dinner (Register interest for approval)
            </div>
            <div
              className={classNames('text-white leading-[19px] md:text-[17px] text-[14px] md:text-left text-center items-left capitalize helvetica-neue-45-light mb-[6px] !font-semibold', {
                hidden: !eventDateTimeWithTz.formattedEndDate,
              })}
            >
              {eventDateTimeWithTz?.formattedEndDate}
              {' '}
              - Conference Day
            </div>
          </div>
        ) : null}

        <div className="flex md:flex-row flex-col md:items-start items-start gap-x-[5px] md:gap-y-[4px] gap-y-[6px] md:mt-[11px] mt-[12px] flex-wrap">
          {(registered === false || isRegistered) && isSurveySubmitted && event?.type === 'invitation' && audienceStatus === 'pending' ? (
            <div className="flex sm:flex-row flex-col md:items-start items-start gap-[4px] flex-wrap">
              <div className="flex-shrink-0">
                <GotoList
                  fillColor="white"
                  titleClass="mr-[8px] flex items-center justify-center text-white text-[16px] leading-[40px] helvetica-neue-W05_85-heavy"
                  btnClassName="bg-transparent border-2 border-white text-white rounded-5 h-[40px] px-[35px] flex items-center"
                  title="Go to"
                  dropdownClass="w-[141px] bg-black !top-[35px] border-1 border-white !rounded-5"
                  isInvitation
                  isFactor
                />
              </div>
              <div className="flex-shrink-0">
                <CalendarList
                  fillColor="white"
                  titleClass="mr-[8px] flex items-center justify-center text-white text-[16px] leading-[40px] helvetica-neue-W05_85-heavy"
                  btnClassName="bg-transparent border-2 border-white rounded-5 h-[40px] px-[35px] flex items-center"
                  title="Registration under review"
                  isInvitation
                  dropdownClass="w-[225px] bg-black !top-[35px] border-1 border-white !rounded-5"
                  isFactor
                />
              </div>
            </div>
          ) : null}
          {showPortalButton() ? (
            <div className="flex md:flex-row flex-col md:items-start items-start gap-[8px] md:gap-[4px] flex-wrap">
              <div className="flex-shrink-0">
                <button
                  type="button"
                  className={classNames(
                    'px-[35px] h-[40px] bg-white rounded-5 flex items-center justify-center text-black text-[16px] leading-[40px] helvetica-neue-W05_85-heavy'
                  )}
                  onClick={() => setShowModal(true)}
                >
                  <div className="h-[40px]">
                    Portal
                  </div>
                </button>
              </div>
              <div className="flex-shrink-0">
                <GotoList
                  fillColor="white"
                  titleClass="mr-[8px] flex items-center justify-center text-white text-[16px] leading-[40px] helvetica-neue-W05_85-heavy"
                  btnClassName="bg-transparent border-2 border-white text-white rounded-5 h-[40px] px-[35px] flex items-center"
                  title="Go to"
                  dropdownClass="w-[141px] bg-black !top-[35px] border-1 border-white !rounded-5"
                  isFactor
                />
              </div>
              <div className="flex-shrink-0">
                <CalendarList
                  fillColor="white"
                  titleClass="mr-[8px] flex items-center justify-center text-white text-[16px] leading-[40px] helvetica-neue-W05_85-heavy"
                  btnClassName="bg-transparent border-2 border-white text-white rounded-5 h-[40px] px-[35px] flex items-center"
                  title="Add to calendar"
                  dropdownClass="w-[225px] bg-black !top-[35px] border-1 border-white !rounded-5"
                  isFactor
                />
              </div>

            </div>
          ) : !registered
                  && audienceStatus !== 'pending'
                  && (
                    <div className="flex items-center gap-x-[4px]">
                      <button
                        type="button"
                        className={classNames(
                          'px-[35px] h-[40px] bg-white rounded-5 flex items-center justify-center text-black text-[16px] leading-[40px] helvetica-neue-W05_85-heavy'
                        )}
                        onClick={() => handleRegistration()}
                      >
                        <div className="h-[40px]">
                          Register
                        </div>
                      </button>
                      <button
                        type="button"
                        className={classNames(
                          'sm:px-[35px] px-[22px] md:h-[40px] h-[40px] bg-transparent border-2 border-white rounded-5 flex items-center justify-center text-white md:text-[16px] text-[15px] leading-[40px] helvetica-neue-W05_85-heavy'
                        )}
                        onClick={handleProgramPage}
                      >
                        <div className="h-[40px]">
                          View Agenda
                        </div>
                      </button>
                    </div>

                  )}
        </div>
      </div>

      {
  showModal && (
    <RegistrationStepForm
      eventId={event?.id!}
      user={user}
      open={showModal}
      registered={registered!}
      eventInvitationStatus={audienceStatus!}
      eventType={event?.type!}
      setOpenForm={() => setShowModal(false)}
    />
  )
}
    </>
  );
};

export default BannerCaption;
