import { useEffect, useState } from 'react';
import {
    Badge,
    BadgeProps,
    CardMedia,
    Stack,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { styled } from '@mui/material/styles';
import Carousel, { CarouselArrows, useCarousel } from 'src/mui/components/carousel';
import { EventsService } from 'src/services/events';
import { SponsorAdsIcon } from 'src/mui/assets/icons/SponsorAdsIcon';
import { getBrandingColors } from '../event/live/liveEventStyling';

type ProductsProps = {
    columnWidth: number | null;
    selectedSponsorDetails: any;
    sponsorAds: any[];
};

const arrowStyle = { width: 26, height: 26, backgroundColor: '#e9eff2', borderRadius: '8px' };

const mediaStyle = { maxWidth: '100%', height: "100%", width: "100%", my: 'auto', margin: 'auto', objectFit: 'contain', };

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: 'lightGray',
        right: 15,
        top: 10,
        borderRadius: 0,
        overflow: 'hidden',
    },
}));

export const SponsorAds = ({
    columnWidth,
    selectedSponsorDetails,
    sponsorAds
}: ProductsProps) => {
    const liveEvent = useSelector((state: IAppState) => state.liveEvent);
    const { eventSponsors } = liveEvent;
    const [ads, setAds] = useState<any[]>();
    const [isHovered, setIsHovered] = useState(false);
    const eventService: EventsService = new EventsService();
    const colors = getBrandingColors();

    const carousel = useCarousel({
        adaptiveHeight: true,
        speed: 800,
        fade: true,
        infinite: true,
        pauseOnHover: false,
        autoplay: false
    });

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (!isHovered) {
            interval = setInterval(() => {
                carousel.onNext(); // Move to the next slide
            }, 2000); // Change slide every 2 seconds
        }

        return () => clearInterval(interval); // Clear interval on hover or unmount
    }, [carousel, isHovered]);

    useEffect(() => {
        if (sponsorAds) {
            setAds(sponsorAds);
        }
    }, [sponsorAds]);

    const onClickAdd = async (eventId: string, id: string, url: string) => {
        if (eventId && id) {
            await eventService.clickOnAdd(eventId, id).then((res) => {
                if (res.message === "SUCCESS") window.open(url, '_blank');
            });
        }
    };

    return (
      <Stack
        sx={{
            position: 'relative',
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            overflow: 'hidden',
            borderRadius: '8px'
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
          <Stack alignItems="flex-end" sx={{ position: "absolute", right: "8px", top: "8px", zIndex: 1 }}>
              <SponsorAdsIcon fillColor={colors.Text || "#ffffff"} />
          </Stack>
          <Stack sx={{ width: '100%', height: '100%', position: 'relative', maxHeight: "100%", maxWidth: "100%", borderRadius: '8px', overFlow: 'hidden', justifyContent: "center" }}
          >
              <Stack>
                  {ads?.length! > 1 && <CarouselArrows
                    onNext={carousel.onNext}
                    onPrev={carousel.onPrev}
                    sx={{
                        position: 'absolute',
                        top: '56%',
                        left: 0,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        zIndex: 10
                    }}
                    leftButtonProps={{
                        sx: {
                            p: 0.5,
                            mt: "-50px",
                            pr: 0.5,
                            left: 0,
                            '& svg': arrowStyle,
                        },
                    }}
                    rightButtonProps={{
                        sx: {
                            p: 0.5,
                            mt: "-50px",
                            pl: 0.5,
                            right: 0,
                            '& svg': arrowStyle,
                        },
                    }}
                  />}
              </Stack>
              {ads?.length! > 0 && (
                <Carousel
                  ref={carousel.carouselRef}
                  {...carousel.carouselSettings}
                >
                    {ads?.map((ad: any) => (
                      <StyledBadge
                        key={carousel.currentIndex} anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                      }}>
                          <Stack
                            onClick={() => onClickAdd(liveEvent?.event?.id!, ads[carousel.currentIndex]?.id, ads[carousel.currentIndex]?.url)}
                          >
                              <CardMedia sx={mediaStyle} component='img' image={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${ad?.image}`} />
                          </Stack>
                      </StyledBadge>
                    ))}
                </Carousel>
              )}
          </Stack>
      </Stack>
    );
};

export default SponsorAds;
