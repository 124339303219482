/* eslint-disable jsx-a11y/img-redundant-alt */
// @mui
import {
  Typography,
  Grid,
  Stack,
  Card,
  CardMedia,
  IconButton,
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import Iconify from "src/mui/components/iconify";
import PanelistEventManager from "./PanelistEventManager";
import "./Card.css";
import { getMinifiedImage, getS3Path } from "src/mui/utils/image";
import { useSelector } from "react-redux";
import { IAppState } from "src/store";

type Props = {
  currentPage?: string;
  step: number;
  onNextStep: () => void;
  onPreviousStep: () => void;
  logoColor: string;
  bgColor: string;
  stepName: string;
  stepDescription: string;
  children: React.ReactNode;
  isLoading: boolean;
  mediaURL: string;
  isMediaExpanded?: boolean;
  pageLength?: number;
  questionLength?: number;
  setOpenSurveyForm: (value: boolean) => void;
  attendeeCard?: boolean;
  onLogout?: () => void;
  audienceStatus?: string;
  requiredFieldsFilled?: boolean;
  primaryFontColor: string | null,
  secondaryBackgroundColor: string | null,
};

export default function NewEventCard({
  step,
  setOpenSurveyForm,
  onNextStep,
  onPreviousStep,
  logoColor,
  bgColor,
  stepName,
  stepDescription,
  children,
  requiredFieldsFilled,
  isLoading,
  mediaURL,
  isMediaExpanded,
  pageLength,
  questionLength,
  attendeeCard,
  currentPage,
  onLogout,
  audienceStatus,
  primaryFontColor,
  secondaryBackgroundColor,
}: Props) {

  const format = mediaURL?.split("/");
  const event = useSelector(
    (state: IAppState) =>
      state.website.event || state.upcomingEvent?.value?.data?.event
  );
  return (
    <Card
      sx={{
        height: "650px",
      }}
    >
      <IconButton
        onClick={() => setOpenSurveyForm(false)}
        sx={{
          position: "absolute",
          zIndex: "100000",
          top: 8,
          left: 8,
          color: bgColor ? "white" : "#BABABA",
        }}
      >
        <Iconify icon="system-uicons:cross-circle" width={24} />
      </IconButton>

      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={5}
          sx={{
            // eslint-disable-next-line no-nested-ternary
            background:
              format && format[0] === "images" && isMediaExpanded
                ? `url(${getMinifiedImage(mediaURL)})`
                : bgColor,
            backgroundColor: bgColor,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {format && format[0] === "videos" && isMediaExpanded && (
            <video
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={getS3Path(mediaURL)}
              autoPlay
              loop
              muted
            >
              <track default kind="captions" />
            </video>
          )}
          <Box
            sx={{
              p: 12,
              pb: 2,
              position: isMediaExpanded ? "absolute" : null,
              top: "0",
            }}
            display="flex"
            flexDirection="column"
            gap={12}
          >
            <PanelistEventManager color={logoColor} />
            <Box>
              <Stack direction="row" display="flex">
                {(step !== 0 || currentPage === "meetingListView") && (
                  <Tooltip
                    title={
                      currentPage === "meetingListView"
                        ? "Back to Attenddees List"
                        : "Previous Page"
                    }
                  >
                    <IconButton
                      onClick={onPreviousStep}
                      sx={{
                        position: "absolute",
                        left: 8,
                        zIndex: "100000",
                        color: bgColor ? "white" : "#BABABA",
                      }}
                    >
                      <Iconify
                        icon="eva:arrow-ios-back-outline"
                        width={35}
                        sx={{ color: bgColor ? "white" : "#BABABA" }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <Box>
                  <Typography
                    variant="h6"
                    noWrap
                    sx={{
                      maxWidth: "350px",
                      color: primaryFontColor || "white",
                    }}
                  >
                    {stepName}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: primaryFontColor || "white", width: "400px" }}
                  >
                    {stepDescription}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: "20px",
                    }}
                  >

                    {attendeeCard && (
                      <Button
                        variant="contained"
                        sx={{
                          ml: 2,
                          display: "flex",
                          direction: "row",
                          alignItems: "center",
                          textAlign: "center",
                          justifyContent: "center",
                          bgcolor: "text.primary",
                          color: "light",
                          "&:hover": {
                            bgcolor: "text.primary",
                            boxShadow: "none",
                          },
                        }}
                        onClick={() => setOpenSurveyForm(false)}
                      >
                        Close
                      </Button>
                    )}
                  </Box>
                </Box>
              </Stack>
              {/* )} */}
            </Box>
          </Box>
          {mediaURL && (
            <Stack
              sx={{
                justifyContent: "center",
              }}
            >
              {format && format[0] === "images" && !isMediaExpanded && (
                <CardMedia
                  component="img"
                  sx={{
                    width: "100%",
                    height: "290px",
                    top: 0,
                    left: 0,
                    // height: '391px',
                    objectFit: "scale-down",
                    objectPosition: "50% 50%",
                  }}
                  image={getMinifiedImage(mediaURL)}
                  alt={mediaURL}
                />
              )}

              {format && format[0] === "documents" && (
                <CardMedia
                  sx={{
                    position: "relative",
                    paddingTop: "56.25%",
                  }}
                  component="div"
                >
                  <img
                    src={getMinifiedImage(mediaURL)}
                    alt="Image"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                    }}
                  />
                </CardMedia>
              )}

              {format && format[0] === "videos" && !isMediaExpanded && (
                <CardMedia
                  component="video"
                  autoPlay
                  loop
                  muted
                  src={getS3Path(mediaURL)}
                  sx={{
                    width: "100%",
                    top: 0,
                    left: 0,
                    // height: '391px',
                    objectFit: "scale-down",
                    objectPosition: "50% 50%",
                  }}
                />
              )}
            </Stack>
          )}

          <div
            style={{
              marginLeft: '10px',
              position: "absolute",
              textAlignLast: 'end',
              bottom: 30,
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={onLogout} sx={{ color: "white" }} >Not You? Logout</Button>
          </div>
        </Grid>
        <Grid
          item
          xs={
            !pageLength || (!step && pageLength === 1 && questionLength === 0)
              ? 7
              : 6
          }
          className="survey-questions"
          sx={{ backgroundColor: secondaryBackgroundColor || "white" }}
        >
          {children}
        </Grid>

        {!pageLength ||
          (!step && pageLength === 1 && questionLength === 0) ||
          (event?.type === "invitation" &&
            audienceStatus === "pending") ? null : (
          <Grid item xs={1} sx={{ backgroundColor: bgColor, opacity: (requiredFieldsFilled || currentPage === "qrCode") ? null : 0.15 }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100%"
            >
              <Tooltip
                title={
                  step || currentPage === "surveyForm"
                    ? "Fill all the required fields"
                    : currentPage === "qrCode" ? "Connect with like-minded attendees" : "Next Page"
                }
              >
                <IconButton
                  onClick={onNextStep}
                  disabled={isLoading}
                  // className="icon-button"
                  sx={{
                    "&:hover": {
                      "& .MuiTouchRipple-root": {
                        backgroundColor: "#5A5A5A", // Your desired ripple hover color
                        opacity: 0.3,
                      },
                      "& svg": {
                        color: "white", // Adjust this to your desired hover icon color
                      },
                    },
                  }}
                >
                  <Iconify
                    icon="eva:arrow-ios-forward-outline"
                    width={70}
                    sx={{
                      color: bgColor ? "white" : "#BABABA",
                      "&:hover": {
                        color: "black", // Adjust this to your desired hover icon color
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
