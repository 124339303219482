// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
//
import { Upload, UploadProps } from '../upload';

// ----------------------------------------------------------------------

interface Props extends Omit<UploadProps, 'file'> {
    name: string;
    multiple?: boolean;
    format?: string | undefined;
    pageUploadMedia?: boolean;
}

const PUploadZone = ({ name, format, multiple, helperText, pageUploadMedia, ...other }: Props) => {
    const { control } = useFormContext();
    if (format) format = `${format}/*`;
    if (format && format === 'document/*') format = 'application/pdf';
    const acceptFile = { [format ? `${format}` : 'image/*']: [] };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) =>
                multiple ? (
                    <Upload
                        multiple
                        accept={acceptFile}
                        files={field.value}
                        error={!!error}
                        helperText={
                            (!!error || helperText) && (
                                <FormHelperText error={!!error} sx={{ px: 2 }}>
                                    {error ? error?.message : helperText}
                                </FormHelperText>
                            )
                        }
                        pageUploadMedia
                        {...other}
                    />
                ) : (
                    <Upload
                        accept={acceptFile}
                        file={field.value}
                        error={!!error}
                        helperText={
                            (!!error || helperText) && (
                                <FormHelperText error={!!error} sx={{ px: 2 }}>
                                    {error ? error?.message : helperText}
                                </FormHelperText>
                            )
                        }
                        pageUploadMedia
                        {...other}
                    />
                )
            }
        />
    );
};

export default PUploadZone;
