/* eslint-disable max-len */
const CookieTableTwo = () => (
  <div className="bg-gray-0 w-full">
    <div className="block w-9/12 mx-auto">
      <hr className="my-10" />
      <div className="mt-3 text-gray-1 table-of-contents text-lg5">What is a Cookie?</div>

      <div className="mt-8 flex flex-col text-md3">
        <div className="inline-block mx-auto max-w-6xl py-2 align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="divide-y divide-gray-300 text-center">
              <tbody className="bg-white leading-6">
                <tr>
                  <td className="py-4 pl-4 pr-3 sm:pl-6 font-bold capitalize w-2/5">
                    IT RECOGNISES YOU WHEN YOU INITIALLY USE OUR PLATFORM.
                  </td>
                  <td className="px-3 py-4 font-light tracking-wide w-3/5">
                    When you arrive at the Platform, cookies will enable us to provide the right
                    information to you. We will be able to personalise your experience upon
                    return. This means the Platform will be easier for you to navigate going
                    forward.
                  </td>
                </tr>
                <tr className="bg-gray-1">
                  <td className="py-4 pl-4 pr-3 sm:pl-6 font-bold capitalize w-2/5">
                    IT GIVES YOU SECURITY WHEN USING THE PLATFORM
                  </td>
                  <td className="px-3 py-4 font-light tracking-wide w-3/5">
                    We need the ability to detect malicious activity if it occurs. Cookies will
                    also enable us to identify violations of our Terms of Service or any other
                    applicable Panelist policies.
                  </td>
                </tr>
                <tr className="">
                  <td className="py-4 pl-4 pr-3 sm:pl-6 font-bold capitalize w-2/5">
                    IT SPEEDS UP YOUR USER EXPERIENCE
                  </td>
                  <td className="px-3 py-4 font-light tracking-wide w-3/5">
                    By using cookies, it will make your interactions with our Platform quicker. it
                    will enable the Platform to operate more effectively and efficiently. It
                    allows us to use plugins both on the Platform and off the Platform, which will
                    improve your user experience
                  </td>
                </tr>
                <tr className="bg-gray-1">
                  <td className="py-4 pl-4 pr-3 sm:pl-6 font-bold capitalize w-2/5">
                    IT ALLOWS US TO REMEMBER YOU EACH TIME YOU VISIT.
                  </td>
                  <td className="px-3 py-4 font-light tracking-wide w-3/5">
                    The use of cookies enables greater functionality by allowing us to learn how
                    you interact with our content and then personalise it to your satisfaction.
                  </td>
                </tr>
                <tr className="">
                  <td className="py-4 pl-4 pr-3 sm:pl-6 font-bold capitalize w-2/5">
                    IT ALLOWS US TO ADVERTISE TO YOU
                  </td>
                  <td className="px-3 py-4 font-light tracking-wide w-3/5">
                    It also allows us to employ the learnings of your behaviour of the Platform to
                    serve you with targeted advertisements on third-party websites to “re-market”
                    our products and services to you. We, or our third-party service providers,
                    use cookies to determine and measure the performance of ads or posts on and
                    off the Platform and to learn whether you have interacted with our or our
                    customers’ websites, content or emails and provide analytics based on those
                    interactions.
                  </td>
                </tr>
                <tr className="bg-gray-1">
                  <td className="py-4 pl-4 pr-3 sm:pl-6 font-bold capitalize w-2/5">
                    IT HELPS US UNDERTAKE RESEARCH TO BETTER UNDERSTAND USER EXPERIENCE.
                  </td>
                  <td className="px-3 py-4 font-light tracking-wide w-3/5">
                    Cookies help us learn more about how well our Services and plugins perform in
                    different locations. We or our service providers use cookies to understand,
                    improve, and research products, features, and services, including as you
                    navigate through our Platform or when you access our Platform from other
                    sites, applications, or devices.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-5 font-light">
        A cookie is a small piece of data that a website asks your browser to store on your
        computer or mobile device. The cookie allows the Platform to "remember" your actions or
        preferences over time. This means that general information about visits to our Platform is
        collected by our computer servers, with small files “cookies” that our Platform transfers
        to your computer’s hard drive through your Web browser (if you allow the delivery of
        “cookies”). Cookie Policy helps you understand, and feel more confident about, our use of
        Cookies.
      </div>
      <div className="text-blue-1 mt-7">Why do we use Cookies?</div>
      <div className="mt-3">
        We use Cookies on our Platform for various reasons. We do our best to explain these various uses in the table below
      </div>

      <div className="text-blue-1 mt-7">What types of Cookies do we use?</div>
      <div className="mt-3">We use a variety of different types of Cookies on our Platform, such as First Party, Third Party, Session Cookies, and Persistent Cookies. Different Cookies have different specific purposes but in general, they are all used so that we can improve your experience in using our Sites and interacting with us. Some of the purposes of the different Cookies we use are described below.</div>

      <div className="text-blue-1 mt-7">How do I opt out of accepting Cookies?</div>
      <div className="mt-3">Most browsers are initially set to accept cookies, but you can change your settings to notify you when a cookie is being set or updated, or to block cookies altogether. Some browsers may include a setting that restricts cookies to "websites that you visit". Be aware that at times, before taking you to a page on our Platform which you have requested to visit, we may momentarily redirect your browser to another of our network sites to place a Cookie onto your browser This is done to enhance your experience across our connected network that forms the Platform and to improve our ability to provide you with more relevant content based on your activity across our Platform. Such local redirects may be included as "websites that you visit". Please note that by blocking any or all cookies you may not have access to certain features, content, or personalisation available through the Platform.</div>

      <div className="text-blue-1 mt-7">Cookies and Advertising</div>
      <div className="mt-3">Cookies and ad technology such as web beacons, pixels, and anonymous ad network tags help us serve relevant ads to you more effectively. They also help us collect aggregated audit data, research, and performance reporting for advertisers. Pixels enable us to understand and improve the delivery of ads to you and know when certain ads have been shown to you. Since your web browser may request advertisements and web beacons directly from ad network servers, these networks can view, edit, or set their own cookies, just as if you had requested a web page from their site. Although we do not use cookies to create a profile of your browsing behavior on third-party sites, we do use aggregate data from third parties to show you relevant, interest-based advertising. We do not provide any personal information that we collect to advertisers. You can opt out of off-site and third party-informed advertising by adjusting your cookie settings. Opting out will not remove advertising from the pages you visit, but, instead, opting out will result in the ads you see not being matched to your interests. This implies that the ad(s) you see will not be matched to your interests by those specific cookies</div>

      <div className="text-blue-1 mt-7">Last Updated: 2 February 2021</div>
      <hr className="my-10" />

    </div>
  </div>
);

export default CookieTableTwo;
