import React, { useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../assets/images/modal-close-icon.svg';

import edit from '../../assets/images/Icon-feather-edit.svg';
import { openMessageWindow } from '../../store/newMessage';
import ChatListWrapper from './chat-list-wrapper';
import classNames from 'classnames';
import { IAppState } from 'src/store';

interface IMessageMenuProps {
  handleChatDropdown?: Function;
}

export default function MessageMenu({ handleChatDropdown }: IMessageMenuProps) {
  const dispatch = useDispatch();
  const { currentUser: { isEmailVerified } = {}, hideEmailVerification } = useSelector((state: IAppState) => state?.auth);


  const handleNewMessage = () => {

    dispatch(openMessageWindow({ avatar: '', userDisplayName: 'New Message' }));
    if (handleChatDropdown) handleChatDropdown()
  };

  return (
    <div
      className={classNames('bg-white rounded-lg md:w-[35%] h-[100vh] md:h-auto w-[100vw] md:min-h-[100vh] min-h-[100vh] md:max-h-[90vh] max-h-[100vh] overflow-auto sm:mt-2 z-10 shadow-xs6 -right-[82px] md:right-[10px] top-[34px] translate-y-[-98.5%] md:translate-y-[0%] fixed', {
        'md:top-[48px]': isEmailVerified || hideEmailVerification,
        'md:top-[98px]': !isEmailVerified && !hideEmailVerification,
      })}
    >
      <div className="flex flex-col bg-white">
        <div className="flex space-x-44 w-[100%] justify-between pr-[25px]">
          <p className="text-blue-2 font-medium tracking-wider text-md2 ml-3 pl-1 mt-3">
            Messages
          </p>
          <div
            // eslint-disable-next-line max-len
            className="absolute right-[20px] top-[10px] text-white text-[11px] font-medium bg-[#edf2f5] bg-opacity-80 w-[25px] h-[25px] rounded-full cursor-pointer md:!hidden flex items-center justify-center"
            onClick={() => handleChatDropdown && handleChatDropdown()}

          >
            <div>
              <span className="cursor-pointer">
                {' '}
                <CloseIcon />
                {' '}
              </span>
            </div>
          </div>
          <div
            className="text-blue-2 font-medium mr-2 new-conversation-button cursor-pointer mt-3"
            onClick={handleNewMessage}>
            <img src={edit} alt="edit" />
          </div>
        </div>
        <div className="max-h-560 overflow-y-auto">
          <ChatListWrapper handleChatDropdown={handleChatDropdown} />
        </div>
      </div>
    </div>
  );
}
