import { Link } from 'react-router-dom';
import { IConnectionRequests } from '../../../../models/nav-bar/connection-requests/connection-requests';
import ConnectionRequestItem from './connection-request-item';
import noNetworkReq from '../../../../assets/images/nav-menu/no-networkreq.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/modal-close-icon.svg';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import classNames from 'classnames';

interface IConnectionRequestsProps {
  connectionRequests: IConnectionRequests[] | undefined;
  fetchItems:(value:string)=>void
}

const ConnectionRequestsMenu = ({ connectionRequests ,fetchItems}: IConnectionRequestsProps) => {
  const { currentUser: { isEmailVerified } = {}, hideEmailVerification } = useSelector((state: IAppState) => state?.auth);

  return (
    <div
      className={classNames('bg-white rounded-lg md:w-[35%] md:min-h-[100vh] md:h-auto h-[100vh] max-h-[100vh] w-[100vw] overflow-auto z-10 shadow-xs6 md:origin-top-right fixed -right-[136px] md:right-[10px] translate-y-[-94.5%] md:translate-y-[0%]', {
        'md:top-[55px]': isEmailVerified || hideEmailVerification,
        'md:top-[104px]': !isEmailVerified && !hideEmailVerification,
      })}
    >
      {/* <div className="bg-white rounded-lg md:w-[297px] md:min-h-[42px] md:h-auto h-[100vh] max-h-[100vh] w-[100vw] overflow-auto z-10 shadow-xs6 md:origin-top-right absolute -right-[136px] top-0 md:right-0 md:top-[46px] translate-y-[-94.5%] md:translate-y-[0%]"> */}
      <div className="flex flex-col">
        <div className="w-full">
          <p className="text-blue-2 font-medium tracking-wider text-md2 ml-3 pl-1 mt-3">Requests</p>
          <div className="pt-2 pb-0">
            <div
              // eslint-disable-next-line max-len
              className="absolute right-[20px] top-[10px] text-white text-[11px] font-medium bg-[#edf2f5] bg-opacity-80 w-[25px] h-[25px] rounded-full cursor-pointer md:hidden flex items-center justify-center"
            >
              <div>
                <span className="cursor-pointer">
                  {' '}
                  <CloseIcon />
                  {' '}
                </span>
              </div>
            </div>
            {connectionRequests &&
              connectionRequests?.map((req: any) => (
                <ConnectionRequestItem connectionRequest={req} key={req.id}  fetchItems={(id:string)=>fetchItems(id)} />
              ))}
            {(connectionRequests?.length === 0 || connectionRequests === undefined) && (
              <div className="flex flex-col justify-center mx-auto w-full">
                <div className="flex justify-center">
                  <div className="text-md text-blue-4 m-5 w-10/12 text-center">
                    You don’t have any network requests for now!
                  </div>
                </div>
                <div className="h-[247px] mx-auto">
                  <img
                    src={noNetworkReq}
                    className="w-[205px]"
                    alt="No network request available"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionRequestsMenu;
