/* eslint-disable */

import * as React from 'react';
import {  ClickAwayListener } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { companyDropdownOptions } from './Common';
import { useDebounce } from 'src/mui/hooks/use-debounce';
import { CompanyService } from 'src/services';
import PCompanyAutocomplete from './PCompanyAutocomplete';

const isCompanyFullName = true;

const LISTBOX_PADDING = 8;

type OptionType = {
  id: string;
  name: string;
};

type PCompanyProps = {
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  defaultValue?: OptionType;
  sx?: any;
  Icon?: any;
  hideEndAdornment?: boolean;
  noOptionsMessage?: string;
  className?: string;
};

export default function PCompanySelectNew({
  name,
  label,
  placeholder,
  disabled = false,
  multiple = false,
  defaultValue,
  Icon,
  sx,
  hideEndAdornment,
  noOptionsMessage,
  className
}: PCompanyProps) {

  const { getValues , watch } = useFormContext();
  const currentValues = watch(name) || [];
  const [inputValue, setInputValue] = React.useState('');
  const debouncedInputValue = useDebounce(inputValue, 500);

  const [filterCompanyData, setFilterCompanyData] = React.useState<OptionType[]>();

  React.useMemo(() => {
    if (debouncedInputValue) {
      const companyService: CompanyService = new CompanyService();
    companyService.getAllCompanies(debouncedInputValue).then(({ data }) => {
        const sortedUsers: OptionType[] = data;
        const filteredCompanies = sortedUsers?.filter(
          (option) =>
            !currentValues.some((selected:any) => selected.name === option.name),
        );
        setFilterCompanyData(filteredCompanies ?? []);
      });
    } 
  }, [debouncedInputValue]);

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          if (!getValues()[name]) setInputValue('');
        }}
      >
        <PCompanyAutocomplete
          sx={{
            ...sx,
          }}
          id="company-select-dropdown"
          className={className}
          popupIcon={Icon}
          label={label}
          placeholder={placeholder}
          name={name}
          multiple={multiple}
          disabled={disabled}
          hideEndAdornment={hideEndAdornment}
          fullWidth
          onInputChange={(event, value) => {
            sessionStorage.setItem('CreateNewCompany', inputValue);
            setInputValue(value);
          }}
          inputValue={inputValue}
          loading={filterCompanyData === undefined}
          {...(defaultValue && { defaultValue })}
          options={filterCompanyData ?? []}
          getOptionLabel={(option: OptionType | string) => (option as OptionType).name ?? option}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderOption={(props, option: any, { index }) => (
              <li {...props} style={{ paddingLeft: '0px !important', paddingBottom: ' !important' }}>
                {companyDropdownOptions(option, { minWidth: '380px' }, isCompanyFullName)}
              </li>
          )}
          noOptionsText={ noOptionsMessage }
        />
      </ClickAwayListener>
    </>
  );
}
