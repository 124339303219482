export const ContactNavbar = () => (
  <nav className="bg-white px-8 pt-2 shadow-md border-solid border-2 z-40">
    <div
      className="-mb-px flex justify-start container lg:ml-28 pl-1 2xl:ml-48 2xl:pl-0"
      style={{ marginLeft: '', height: '45px' }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="181"
        height="35"
        viewBox="0 0 181 35"
      >
        <defs>
          <clipPath id="clip-path">
            <ellipse
              id="Ellipse_649"
              data-name="Ellipse 649"
              cx="18"
              cy="17.5"
              rx="18"
              ry="17.5"
              transform="translate(-0.117 0.174)"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_13552" data-name="Group 13552" transform="translate(0.118 -0.174)">
          <ellipse
            id="Ellipse_643"
            data-name="Ellipse 643"
            cx="18"
            cy="17.5"
            rx="18"
            ry="17.5"
            transform="translate(-0.118 0.174)"
            fill="#203c6e"
          />
          <g id="Group_12132" data-name="Group 12132">
            <g id="Group_12131" data-name="Group 12131" clip-path="url(#clip-path)">
              <g id="Group_12000" data-name="Group 12000" transform="translate(6.904 7.108)">
                <path
                  id="Path_10366"
                  data-name="Path 10366"
                  d="M263.884,182.945a10.9,10.9,0,0,0-10.9,10.9v19.772a.9.9,0,0,0,.894.894h1.792a.894.894,0,0,0,.894-.894V201.922a10.9,10.9,0,1,0,7.321-18.977Zm0,18.228a7.33,7.33,0,1,1,7.324-7.476c0,.05,0,.1,0,.148a7.324,7.324,0,0,1-7.324,7.328Z"
                  transform="translate(-252.981 -182.945)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <text
          id="Event_Solutions"
          data-name="Event Solutions"
          transform="translate(48 24)"
          fill="#3c3c3c"
          font-size="18"
          font-family="Poppins-Light, Poppins"
          font-weight="300"
        >
          <tspan x="0" y="0">
            Event Solutions
          </tspan>
        </text>
      </svg>
    </div>
  </nav>
);

export default ContactNavbar;
