import { useEffect } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
//
import Editor, { EditorProps } from '../editor';

// ----------------------------------------------------------------------

interface Props extends EditorProps {
  name: string;
  initialCheck?: boolean;
  setDirtyState?: () => void;
  label?: string;
  onlyLabel?: boolean;
}

export default function PEditor({
  name,
  initialCheck,
  setDirtyState,
  label,
  helperText,
  onlyLabel,
  ...other
}: Props) {
  const {
    control,
    watch,
    setValue,
    formState: { isSubmitSuccessful },
  } = useFormContext();

  const values = watch();

  useEffect(() => {
    if (values[name] === '<p><br></p>') {
      setValue(name, '', {
        shouldValidate: !isSubmitSuccessful,
      });
    }
  }, [values]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Editor
          id={name}
          value={field.value}
          // label={label}
          onChange={(e) => {
            field.onChange(e);
            if (initialCheck) {
              if (setDirtyState) {
                setDirtyState();
              }
            }
          }}
          error={!!error}
          helperText={
            (!!error || helperText) && (
              <FormHelperText error={!!error} sx={{ px: 0, mt: 0 }}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )
          }
          // onlyLabel={onlyLabel}
          {...other}
        />
      )}
    />
  );
}
