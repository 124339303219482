import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IGeocodeDetailsResult } from '../../models/address/AddressResult';
import { IAddressState } from '../../models/address/AddressState';
import { ThirdPartyServices } from '../../services/ThirdPartyServices';
import { AddressFromGeocodeActionTypes } from './types';

export interface IAddressFromGeocodeLoadingAction {
  type: AddressFromGeocodeActionTypes.ADDRESS_FROM_GEOCODE_LOADING;
  loading: boolean;
}

export interface IAddressFromGeocodeSuccessAction {
  type: AddressFromGeocodeActionTypes.ADDRESS_FROM_GEOCODE_SUCCESS;
  payload: IGeocodeDetailsResult;
}

export interface IAddressFromGeocodeErrorAction {
  type: AddressFromGeocodeActionTypes.ADDRESS_FROM_GEOCODE_ERROR;
  errorMessage: string;
}

export type AddressFromGeocodeActions =
  | IAddressFromGeocodeLoadingAction
  | IAddressFromGeocodeSuccessAction
  | IAddressFromGeocodeErrorAction;

export const getAddressFromGeocode: ActionCreator<
  ThunkAction<Promise<any>, IAddressState, null, IAddressFromGeocodeSuccessAction>
> = (lat: string, lng: string) => {
  return async (dispatch: Dispatch) => {
    const thirdPartyService = new ThirdPartyServices();

    try {
      const result = await thirdPartyService.getAddressFromGeolocation(lat, lng);

      dispatch({
        type: AddressFromGeocodeActionTypes.ADDRESS_FROM_GEOCODE_SUCCESS,
        payload: result
      });
    } catch (error: any) {
      // console.error(error);
      dispatch({
        type: AddressFromGeocodeActionTypes.ADDRESS_FROM_GEOCODE_ERROR,
        errorMessage: error?.message
      });
    } finally {
      dispatch({
        type: AddressFromGeocodeActionTypes.ADDRESS_FROM_GEOCODE_LOADING,
        loading: false
      });
    }
  };
};

export const loadingAddress: ActionCreator<
  ThunkAction<any, IAddressState, null, IAddressFromGeocodeLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: AddressFromGeocodeActionTypes.ADDRESS_FROM_GEOCODE_LOADING,
    loading: shouldLoad
  });
