import * as Yup from "yup";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ILoginOrJoinProps } from "./form.type";
import {
    Box,
    Stack,
    Alert,
    CircularProgress,
    useMediaQuery,
    Typography
} from "@mui/material";
import FormProvider from "src/mui/components/hook-form/form-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFTextField } from "src/mui/components/hook-form";
import { useSelector } from "react-redux";
import useStyling from "src/mui/components/hook-form/useStyling";
import { IAppState } from "src/store";
import { EventsService } from "src/services/events";


function PasswordProtectedForm({
    loginUser,
    onSignup,
    loader,
    showProtectedPasswordScreen
}: ILoginOrJoinProps, ref: any) {
    const event = useSelector(
        (state: IAppState) =>
            state.website.event || state.upcomingEvent?.value?.data?.event
    );

    const tabScreen = useMediaQuery('(min-width:900px)');
    const { secondaryFontColor, styles } = useStyling(null, null)

    const fieldsObject = useMemo(() => {
        let validation: any = {}
        validation["protectedPassword"] = Yup.string().required('Password is required')
        return validation
    }, [showProtectedPasswordScreen])


    const NewEventSchema = Yup.object().shape(fieldsObject);


    const defaultValues = useMemo(
        () => ({
            protectedPassword: ""
        }),
        []
    );


    const methods = useForm<any>({
        resolver: yupResolver(NewEventSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        setValue,
        watch,
        formState: { isSubmitting, isValid, isDirty },
        reset
    } = methods;
    const values = watch()

    //functions
    const [showProtectedPassError, setShowProtectedPassError] = useState('')

    const handleContinueProtectedPassword = () => {
        handleSubmit(submitForm)()
    }

    const eventProtectedPassword = async (data: any) => {
        let body = {
            "passCode": data?.protectedPassword
        }
        try {
            const result: any = await new EventsService().eventProtectedPassword(event?.id!, body);
            if (!result?.result) {

                setShowProtectedPassError("Enter Correct Password!")
            }
            else if (result?.result) {
                setValue("passCode", "")
                setShowProtectedPassError('')
                if (onSignup) onSignup();
            }
        } catch (error: any) {
            setShowProtectedPassError(error?.message)
        }

        // setAllCompanies([...result?.data]);
    };

    const submitForm = (data: any) => {
        if (!isValid) return
        eventProtectedPassword(data)
    }

    useImperativeHandle(ref, () => ({
        handleContinueProtectedPassword,
    }));

    return (
        <>
            {loader ? (
                <Stack
                    sx={{
                        direction: "row",
                        minHeight: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                    {/* <PanelistLogo /> */}
                </Stack>
            ) : (
                <Stack sx={{
                    width: tabScreen ? "60%" : "85%",
                }}>
                    <FormProvider methods={methods} >
                        <Stack
                            sx={{
                                direction: "row",
                                height: "100%",
                                // marginTop: '35%',
                                // justifyContent: (loginUser && userExist) ? "flex-start" : "center",
                                justifyContent: "center",
                                alignItems: "center",// on first login screen justify is center 
                            }}
                            spacing={1}
                            rowGap={1}
                        >
                            <Typography sx={{ color: secondaryFontColor }}>
                                This event is protected with a passcode{" "}
                            </Typography>
                            <Stack sx={{ width: "100%" }}>
                                <RHFTextField
                                    name="protectedPassword"
                                    label="Passcode"
                                    sx={{ color: secondaryFontColor, ...styles }}
                                    autoFocus
                                    autoComplete="off"
                                />
                            </Stack>
                            <Box sx={{ width: "100%", mt: 2 }}>
                                {(showProtectedPassError !== "") && (
                                    <Stack direction="column" spacing={2}>
                                        <Alert severity="error" sx={{ width: "100%" }}>
                                            {showProtectedPassError}
                                        </Alert>
                                    </Stack>
                                )}
                            </Box>
                        </Stack>
                    </FormProvider>
                </Stack>
            )}
        </>
    );
};
export default forwardRef(PasswordProtectedForm);
