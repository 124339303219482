import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EventsService } from 'src/services/events';

export const ShortUrl = () => {
  const { shortUrl } = useParams();

  const getEventWebsiteUrl = async (url: string) => {
    const { data } = await new EventsService().getUTMbyShortUrl(url);
    const redirectUrl = `/${data?.event?.company?.name}/${data?.event?.slug}?${data?.url}`;
    window.location.replace(redirectUrl);
  };
  useEffect(() => {
    if (shortUrl) {
      getEventWebsiteUrl(shortUrl);
    }
  }, []);
  return null;
};
