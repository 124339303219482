import React, { useState } from 'react';

import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import ReportItem from './report-item';

interface IReport {
  content: string[];
}
interface IReportProps {
  id: string;
  type: "company" | "event" | "post" | "comment" | "user";
  onReport: Function;
  onCloseReport?: Function;
}

export const Report = ({ id, type, onReport, onCloseReport }: IReportProps) => {
  const { register, handleSubmit } = useForm<IReport>();

  const [showInappropriateContentOptions, setShowInappropriateContentOptions] =
    useState<boolean>(false);
  const [showSuspiciousOrFakeOptions, setShowSuspiciousOrFakeOptions] =
    useState<boolean>(false);
  const [showHateSpeechOptions, setShowHateSpeechOptions] =
    useState<boolean>(false);
  const [reason, setReason] = useState("");

  const submit = (data: IReport) => {
    let dataToSubmit: string = "";
    if (data.content)
      dataToSubmit = `{"message": "['${reason}', '${data.content}']"}`;
    else dataToSubmit = `{"message": "['${reason}']"}`;
    const jsonData = JSON.stringify(dataToSubmit);

    onReport(id, type, jsonData);
    onCloseReport!();
  };

  return (
    <div className="w-full">
      <div className="text-[#0B1221] font-medium text-[18px] leading-[26px] border-b-1 border-[#CDDCF2] pb-[9px] mx-[18px]">
        Report
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="flex flex-col justify-start">
          <div className="category mt-2">
            <div
              className={classNames("hover:bg-gray-1 rounded-5 p-[18px]", {
                "bg-gray-1": reason === "Inappropriate content",
              })}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShowInappropriateContentOptions(true);
                  setShowSuspiciousOrFakeOptions(false);
                  setShowHateSpeechOptions(false);
                  setReason("Inappropriate content");
                }}
              >
                <ReportItem
                  title="Inappropriate content"
                  description="content that displays nudity, sexual harrassment, is shockingor gory"
                  options={["Nudity", "Shocking or gory", "Sexual Harassment"]}
                  showOptions={showInappropriateContentOptions}
                  {...register("content")}
                />
              </div>
            </div>
            <div
              className={classNames("hover:bg-gray-1 rounded-5 p-3", {
                "bg-gray-1": reason === "Suspicious or fake",
              })}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShowInappropriateContentOptions(false);
                  setShowSuspiciousOrFakeOptions(true);
                  setShowHateSpeechOptions(false);
                  setReason("Suspicious or fake");
                }}
              >
                <ReportItem
                  title="Suspicious or fake"
                  description="Reporting misinformation, fraud, spam, fake or hacked accounts"
                  options={[
                    "Misinformation",
                    "Fraud or spam",
                    "Fake or hacked",
                  ]}
                  showOptions={showSuspiciousOrFakeOptions}
                  {...register("content")}
                />
              </div>
            </div>
            <div
              className={classNames("hover:bg-gray-1 rounded-5 p-3", {
                "bg-gray-1": reason === "Violence or hate speech",
              })}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShowInappropriateContentOptions(false);
                  setShowSuspiciousOrFakeOptions(false);
                  setShowHateSpeechOptions(true);
                  setReason("Violence or hate speech");
                }}
              >
                <ReportItem
                  title="Violence or hate speech"
                  description="Inciting violence or a threat, Self-harm, Terrorism or act of violence"
                  options={["Inciting Violence", "Self-harm", "Terrorism"]}
                  showOptions={showHateSpeechOptions}
                  {...register("content")}
                />
              </div>
            </div>
            <div
              className={classNames(
                "hover:bg-gray-1 rounded-5 p-3 cursor-pointer",
                {
                  "bg-gray-1": reason === "Intellectual property",
                }
              )}
              onClick={() => {
                setShowInappropriateContentOptions(false);
                setShowSuspiciousOrFakeOptions(false);
                setShowHateSpeechOptions(false);
                setReason("Intellectual property");
              }}
            >
              <ReportItem
                title="Intellectual property"
                description="Sharing of important information not available to public without consent"
                options={[]}
                showOptions={false}
                {...register("content")}
              />
            </div>
            <div
              className={classNames(
                "hover:bg-gray-1 rounded-5 p-3 cursor-pointer",
                {
                  "bg-gray-1": reason === "I just don't like seeing this",
                }
              )}
              onClick={() => {
                setShowInappropriateContentOptions(false);
                setShowSuspiciousOrFakeOptions(false);
                setShowHateSpeechOptions(false);
                setReason("I just don't like seeing this");
              }}
            >
              <ReportItem
                title={"I just don't like seeing this"}
                description="Not in line with my interests or views"
                options={[]}
                showOptions={false}
                {...register("content")}
              />
            </div>
          </div>

          <div className="flex flex-row justify-end px-[18px] pt-3">
            <button
              type="button"
              className="rounded-[5px] inline-block text-sm font-semibold h-8 px-6 py-1.5 bg-gray-1 text-blue-2 w-28 mr-2 hover:bg-white"
              onClick={() => onCloseReport && onCloseReport()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-[5px] inline-block text-sm font-semibold h-8 px-6 py-1.5 bg-[#0049EF] hover:bg-[#2e6eff] text-white w-28"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Report;
