import { SVGProps } from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
}

function SearchIcon({ fillColor, fillBgColor, ...props }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.832" height="16.176" viewBox="0 0 15.832 16.176">
            <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(1 1)">
                <path id="Path_635" data-name="Path 635" d="M16.427,10.617a6.042,6.042,0,0,1-5.964,6.117A6.042,6.042,0,0,1,4.5,10.617,6.042,6.042,0,0,1,10.464,4.5a6.042,6.042,0,0,1,5.964,6.117Z" transform="translate(-4.5 -4.5)" fill="none" stroke={fillColor || "#fff"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                <path id="Path_636" data-name="Path 636" d="M28.218,28.3l-3.243-3.326" transform="translate(-14.8 -14.539)" fill="none" stroke={fillColor || "#fff"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </g>
        </svg>


    );
}

export default SearchIcon;