import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { UserNavbar } from '../../components';
import { PostService, UserService } from '../../services';
import { IPost } from '../../models/post/post';
import AlertModal from '../../components/alert-modal/alert-modal';
import { Button } from '../../shared-components/V2';
import { IPostSpotLight, IPostSpotlightResult } from '../../models/post/IPostSpotlight';
import { IAppState } from '../../store';
import CreatePostModal from '../../components/posts/create-post/create-post-modal';
import { useCurrentUser } from '../../utils/hooks';
import { imageUrlPrefix } from '../../utils';

import SpotlightDetails from '../../components/spotlight/spotlight-details';
import { Spotlight } from '../../components/spotlight/spotlight';
import SearchIcon from '../../assets/images/event-landing-pre-event/Icon-feather-search.svg';
import { DebounceInput } from '../../shared-components/DebounceInput';
import MySpotlightList from './my-spotlight-list';
import { Loader } from '../../shared-components/Loader';

export const SpotlightPage = () => {
  const [userSpotlights, setUserSpotlight] = useState<IPostSpotLight[]>([]);
  const { search } = useLocation();
  const searchValue = new URLSearchParams(search).get('search');
  const [searchText, setSearchText] = useState(searchValue || '');
  const [showSpotlightEvent, setshowSpotlightEventEvent] = useState<IPost>();

  const userService: UserService = new UserService();
  const isModalOpen = useSelector((state: IAppState) => state.isModalOpen);
  const [toggleModal, setToggleModal] = useState(false);
  const loggedInUser = useCurrentUser();
  const [nextPage, setNextPage] = useState(2);
  const [totalPage, setTotalPage] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [specificSpotlight, setSpecificSpotlight] = useState<IPostSpotLight[]>([]);
  const hasMoreItems = nextPage <= totalPage;
  const PAGE_SIZE = 20;

  const selector = useSelector((state: IAppState) => state);
  const { currentUser: user } = selector.auth;

  const getMySpotlight = async (id: string) => {
    const result: IPostSpotlightResult = await userService.getUserSpotlights(id, PAGE_SIZE, 1, '');
    setTotalPage(result.totalPage);
    setSpecificSpotlight(result.data);
  };

  useEffect(() => {
    if (user?.id) {
      getMySpotlight(user?.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getSpotlight = async () => {
    const result: IPostSpotlightResult = await userService.getAllSpotlights(PAGE_SIZE, 1, searchText);
    setTotalPage(result.totalPage);
    setUserSpotlight(result.data ?? []);
    setNextPage(2);
  };

  const fetchItems = useCallback(
    async () => {
      if (fetching) {
        return;
      }
      setFetching(true);
      try {
        const result = await userService.getAllSpotlights(PAGE_SIZE, nextPage, searchText);

        setTotalPage(result.totalPage);
        if (result && nextPage <= result.totalPage) {
          setUserSpotlight(userSpotlights.concat(result.data) ?? []);
          setNextPage(nextPage + 1);
        } else {
          setFetching(true);
        }
      } finally {
        setFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSpotlights, fetching, nextPage]
  );

  useEffect(() => {
    getSpotlight();
    // eslint-disable-next-line
  }, [searchText]);

  useEffect(() => {
    setToggleModal(isModalOpen.isOpen);
  }, [isModalOpen]);

  const fetchSpotlight = async (id: string) => {
    const postService: PostService = new PostService();
    try {
      const selectedSpotlight = await postService.getPost(id);
      setshowSpotlightEventEvent(selectedSpotlight);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const postId = queryParams.get('id');
    if (postId) fetchSpotlight(postId);
  }, []);

  const resetToDefaults = () => {
    setNextPage(2);
    setTotalPage(0);
    setFetching(false);
    getSpotlight();
  };

  const onCloseModal = () => setToggleModal(!toggleModal);

  return user && userSpotlights ? (
    <div className="bg-gray-1 h-full">
      <UserNavbar />
      <div className="container mx-auto mt-6 px-3 xl:px-0 max-w-[100%] xl:max-w-[1258px]">
        <div className="md:flex flex-row justify-center gap-[31px]">
          <div className="md:flex flex-col w-[286px] sticky top-0 hidden">
            <Button
              text="Create new content"
              className="text-white text-md2 font-medium py-2 h-10 bg-[#0049EF] hover:bg-[#2e6eff]"
              type="button"
              onClick={onCloseModal}
            />
            {user ? (
              <MySpotlightList title="My spotlight" spotlightList={specificSpotlight} id={user?.id} />
            ) : <Loader className="pt-5" key="loader" />}
            <AlertModal className="md:w-[500px] w-full" onClose={onCloseModal} isOpen={toggleModal}>
              <CreatePostModal
                wallId={loggedInUser.id}
                location="profile"
                avatarUrl={`${imageUrlPrefix}/${loggedInUser.avatar}`}
                userDisplayName={loggedInUser.fullName}
                onAfterSave={() => resetToDefaults()}
                onClose={onCloseModal}
                image
                video
                document
                poll={false}
                isSpotlightCreate
              />
            </AlertModal>
          </div>
          <div className="md:w-[941px] w-full">
            <div className="md:block flex items-center">
              <Button
                text="Create new content"
                className="text-white text-[13px] font-medium !h-[38px] bg-[#0049EF] hover:bg-[#2e6eff] md:hidden block mb-[21px] min-w-[165px] mr-3"
                type="button"
                onClick={onCloseModal}
              />
              <div className="relative w-full items-center pb-[21px]">
                <div className="left-[14px] top-[12px] absolute">
                  <img className="w-4 h-4" src={SearchIcon} alt="" />
                </div>
                <div className="bg-gray-1 rounded-2xl w-full h-[38px] ">
                  <DebounceInput
                    delay={400}
                    InputName="search"
                    initValue={searchText}
                    type="text"
                    placeholder="Enter a keyword"
                    classNames="pl-[42px] py-1 h-[39px] w-full bg-white !text-[15px] placeholder:!text-[15px] placeholder:!text-[#848484] font-normal rounded-[5px] outline-none"
                    // eslint-disable-next-line no-console
                    onChangeText={(e:any) => setSearchText(e)}
                  />
                </div>
              </div>
            </div>
            <Spotlight
              isOwnSpotlight
              getSpotlight={getSpotlight}
              gridSize={3}
              spotlightLists={userSpotlights}
              fetchSpotlight={fetchSpotlight}
              fetchItems={fetchItems}
              hasMoreItems={hasMoreItems}
            />
          </div>
        </div>
      </div>
      <AlertModal isOpen={!!showSpotlightEvent} onClose={() => setshowSpotlightEventEvent(undefined)} className="w-[1124px] py-0 my-0 overflow-hidden">
        <SpotlightDetails isPostModal isSpotlightDetailModal showSpotlightEvent={showSpotlightEvent} getSpotlight={getSpotlight} fetchSpotlight={fetchSpotlight} />
      </AlertModal>
    </div>
  ) : <Loader isLoaderLogo className="h-[100vh]" key="loader" />;
};

export default SpotlightPage;
