import { Link } from 'react-router-dom';
import NotFoundImage from '../../assets/images/not-found.svg';

export const NotFound = () => (
  <div className="bg-gray-1 flex text-center h-[89vh]">
    <div className="mx-auto mt-32">
      <img src={NotFoundImage} alt="" />
      <div className="mt-2">Nothing to see here</div>
      <Link to="/" className="underline">
        Go back
      </Link>
    </div>
  </div>
);
