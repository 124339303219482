import { useRef, useState } from 'react';

import { Transition } from '@tailwindui/react';

import { ArrowDownSvgIcon } from '../IconSvg';
import useCloseMenuWithClickOutside from '../../../utils/hooks/closeMenuWithClickOutside';
import { ArrowDownSortSvgIcon } from '../IconSvg/ArrowDownSvgIcon/ArrowDownSortSvgIcon';

export interface ISortByProps {
  sortClick: Function;
  ascOrderText?: string;
  descOrderText?: string;
  defaultOrder?: "ASC" | "DESC";
  fillColor?: "WHITE" | "GRAY";
}

export const SortBy = ({
  sortClick,
  ascOrderText,
  descOrderText,
  defaultOrder = "DESC",
  fillColor
}: ISortByProps) => {
  const ASC_ORDER_TEXT = ascOrderText!;
  const DESC_ORDER_TEXT = descOrderText!;
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">(defaultOrder);

  const ref = useRef<any>();
  const { showDropdown, handleDropdown } = useCloseMenuWithClickOutside(ref);

  const handleMenuDropdown = () => {
    handleDropdown(showDropdown);
  };

  return (
    <>
      <div className="w-full flex items-center">
        <div className="flex-grow border-b-1 bg-[#707070] opacity-[28%] mr-2"></div>
        <div className="w-auto group">
          <div className="relative cursor-pointer">
            <div
              className="flex items-center justify-evenly"
              onClick={() => handleMenuDropdown()}
            >
              <span aria-label="order-target" className="font-medium text-[12px] leading-[14px] text-[#535353] mr-6px whitespace-nowrap pl-2 pr-[5px] flex gap-1">
              Sort by: <div className='text-[13px] text-black font-semibold'>{`${sortOrder === "ASC"? ASC_ORDER_TEXT : DESC_ORDER_TEXT}`}</div>
              </span>
              <button
                type="button"
                className="text-gray-400 rounded-full pr-[2px] h-6 items-center justify-center inline-flex"
                aria-haspopup="true"
                aria-expanded={showDropdown}
              >
                <ArrowDownSortSvgIcon fillColor={fillColor} />
              </button>
            </div>
            {showDropdown && (
              <div ref={ref} className="relative z-10">
                <Transition
                  show={showDropdown}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute right-0 -top-[4px] origin-top-right rounded-10 min-w-[118px]"
                >
                  <div className="bg-white rounded-10 profile-avatar-shadow px-[6px] py-[6px]">
                    <div
                      className="text-left"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <ul className="w-full">
                        <li className="cursor-pointer rounded-[5px] flex items-center h-[27px] hover:bg-[#EDF2F5]">
                          <span
                            className="text-[#172746] text-[13px] leading-5 font-normal px-[7px] rounded-5 "
                            onClick={() => {
                              sortClick(DESC_ORDER_TEXT);
                              setSortOrder("DESC");
                            }}
                          >
                            {descOrderText}
                          </span>
                        </li>
                        <li className="cursor-pointer rounded-[5px] flex items-center h-[27px] hover:bg-[#EDF2F5]">
                          <span
                            className="text-[#172746] text-[13px] leading-5 font-normal px-[7px] rounded-5"
                            onClick={() => {
                              sortClick(ASC_ORDER_TEXT);
                              setSortOrder("ASC");
                            }}
                          >
                            {ascOrderText}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Transition>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
