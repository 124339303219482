/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useSwiper } from 'swiper/react';
import classNames from 'classnames';
import ArrowRight from '../../../../assets/images/arrow-right-new.svg';
import ArrowLeft from '../../../../assets/images/arrow-left-gray.svg';

type Props = {
  isScreenshots?: boolean;
  currentIndex?: number | undefined;
  lastIndex?: any
};
const SwiperNavButtons = ({ isScreenshots, currentIndex, lastIndex }: Props) => {
  const swiper = useSwiper();
  const isLastSlide = swiper.slides.length - lastIndex === currentIndex;
  return (
    <div
      className={classNames('swiper-nav-btns absolute top-[7px] right-5 flex justify-center items-center gap-2', {
        'right-0': isScreenshots,
      })}
    >
      {currentIndex === 0 ? <img className="cursor-pointer w-[32px] h-[32px]" src={ArrowLeft} onClick={() => swiper.slidePrev()} alt="arrow" /> : <img className="cursor-pointer rotate-180 w-[32px] h-[32px]" src={ArrowRight} onClick={() => swiper.slidePrev()} alt="arrow" />}
      {isLastSlide ? <img className="cursor-pointer rotate-180 w-[32px] h-[32px]" src={ArrowLeft} onClick={() => swiper.slideNext()} alt="arrow" /> : <img className="cursor-pointer w-[32px] h-[32px]" src={ArrowRight} onClick={() => swiper.slideNext()} alt="arrow" />}
    </div>
  );
};

export default SwiperNavButtons;
