import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction
};

export default function FormProvider({ children, onSubmit, methods }: Props) {
  return (
    <Form {...methods} >
      <form onSubmit={(e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        if (onSubmit) onSubmit();
      }}>{children}</form>
    </Form>
  );
}
