import { Grid, Stack, Typography } from '@mui/material';

import Avatar from '@mui/material/Avatar';
import Graymaticslogo from '../../../assets/images/graymaticslogo.png'
import AIVisionLenovo from '../../../assets/images/AIVision-lenovo.png'
import ProfetAIlogoLenovo from '../../../assets/images/Profet AI logo-lenovo.png'
import InvigiloSideTextLogo from '../../../assets/images/Invigilo Side Text Logo.png'
import AMD from '../../../assets/images/AMD_Logo.png'
import NVDABIG from '../../../assets/images/NVDA_BIG.png'
import INTEL from '../../../assets/images/Intel-logo.png'
import Nutanix from '../../../assets/images/Nutanix.png'
import VMware from '../../../assets/images/VMware.png'
import RedHat from '../../../assets/images/Red_Hat_Logo.png'


const images = [
  Graymaticslogo,
  AIVisionLenovo,
  ProfetAIlogoLenovo,
  InvigiloSideTextLogo,
];

const lenovoLogos = [
  AMD,
  NVDABIG,
  VMware,
  INTEL,
  RedHat,
  Nutanix,
];

export const Partners = () => {
  return (
    <Stack sx={{ pb: '3rem', px: '3rem', pt: '1.5rem' }}>
      <Stack sx={{ justifyContent: "center", alignItems: "center", mt: "3rem" }}>

        <Typography
          variant="body2"
          noWrap
          sx={{
            whiteSpace: 'initial',
            fontSize: '1.9rem',
            mb: '2.5rem',
            color: '#4d144a',
            fontFamily: 'Poppins-600',
            lineHeight: '30px'
          }}
        >
          In collaboration with
        </Typography>
        <Grid container direction="row" rowGap="20px" alignItems="center" justifyContent="center">
          {lenovoLogos.map((image, index) => (
            <Grid item xl={2} lg={2} md={3} sm={4} xs={6} key={index} sx={{ position: 'relative', justifyContent: "center", display: "flex", borderRight: index !== lenovoLogos.length - 1 ? '1px solid #adabb3' : "0px", maxHeight: "115px", alignItems: "center" }}>
              <Avatar
                alt={image}
                src={image}
                sx={{
                  maxHeight: '184px',
                  height: '184px',
                  width: '100%',
                  px: "2rem",
                  // maxWidth: '240px',
                  borderRadius: '0px',
                  objectFit: 'contain',
                  "& .MuiAvatar-img": {
                    objectFit: 'contain',
                    objectPosition: 'center'
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>

      <Stack sx={{ justifyContent: "center", alignItems: "center", mt: "3rem" }}>
        <Grid container direction="row" rowGap="20px" justifyContent="center">
          {images.map((image, index) => (
            <Grid item xl={2} lg={2} md={3} sm={4} xs={6} key={index} sx={{ position: 'relative', justifyContent: "center", display: "flex", borderRight: index !== images.length - 1 ? '1px solid #adabb3' : "0px", maxHeight: "115px", alignItems: "center" }}>
              <Avatar
                alt={image}
                src={image}
                sx={{
                  maxHeight: '184px',
                  height: '184px',
                  width: '100%',
                  px: "2rem",
                  // maxWidth: '240px',
                  borderRadius: '0px',
                  objectFit: 'contain',
                  "& .MuiAvatar-img": {
                    objectFit: 'contain',
                    objectPosition: 'center'
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>

    </Stack>
  );
};
