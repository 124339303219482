import React, { FC, useState } from 'react';
import { format, parseISO } from 'date-fns';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ArrowRightIcon from "../../../assets/images/arrow-right.svg";
import ArrowLeftIcon from "../../../assets/images/arrow-left.svg";

import { EventsService } from '../../../services/events';
import { IEvent } from '../../../models/user-events';
import { DropDownEventButton } from '../DropDownEventButton';
import { GroupUserIcons } from '../GroupUserIcons';

import './cardEventsInterestedContainer.css'
import { Logo } from '../Logo';
import { minifiedImage, toBase64 } from '../../../utils';
import { ArrowDownSvgIcon } from '../IconSvg';

export interface ICardEventsInterestedItems {
  events: IEvent[];
  name: string,
  dropdownIcon?: string;
  className?: string;
  onClickEvent?: Function;
}

export const CardEventsInterestedContainer: FC<ICardEventsInterestedItems> = ({
  events,
  name,
  dropdownIcon = '/images/Polygon-dropdown.svg',
  className,
  onClickEvent
}) => {

  const [selectedEvent, setSelectedEvent] = useState(events && events[0]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showArrows, setShowArrows] = useState(false);

  const handleClick = (_: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setSelectedEvent(events && events[index]);
    setSelectedIndex(index);
  };

  const slideNext = (_: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setSelectedEvent(events && events[index]);
    setSelectedIndex(index);
  };
  
  const navigate = useNavigate();

  const join = () => {
    navigate(`/event/${selectedEvent && selectedEvent.slug}/discussion?from=live-register`);
  };

  const interested = () => {
    new EventsService().eventInterested(selectedEvent && selectedEvent.id).then(res => {
      toast.success(res.message);
    });
  };
  
  if (!selectedEvent) return null;

  return (
    <div 
      className={classNames("flex-col relative card-shadow event-card-widget", {
        [`${className}`]: className,
      })}
      onMouseEnter={() => setShowArrows(true)}
      onMouseLeave={() => setShowArrows(false)}
    >
      <div className="text-md15 leading-[18px] text-[#172746] font-normal mb-2">{name}</div>
      <div className="text-sm5">
        <div>
          <div
            style={{
              backgroundImage: `url(${minifiedImage(`${selectedEvent?.cover}`)}`
            }}
            className={classNames("flex items-end justify-start cursor-pointer mx-auto bg-cover bg-no-repeat bg-center rounded-10 w-full px-3 py-3 bg-gray-50 mb-2 h-126")}
            onClick={() => onClickEvent!(`/event/${selectedEvent && selectedEvent.slug}/discussion`)}
          >
            <div
              className={classNames("w-[66px] max-w-[66px] min-w-[66px] h-[66px] max-h-[66px] min-h-[66px] flex items-center justify-center bg-white bg-center small-radius", {
                'bg-[#edf2f5]': !selectedEvent?.company?.logo,
              })}
            >
              <Logo
                alt="avatar"
                className="w-[64px] max-w-[64px] min-w-[64px] h-[64px] max-h-[64px] min-h-[64px] object-contain bg-center small-radius"
                src={minifiedImage(`${selectedEvent?.company?.logo}`)}
                defaultLogo="company"
              />
            </div>
          </div>
        </div>


        <div className="px-4">
          <div className="text-left event-card-widget-text">
            <div className='cursor-pointer' 
              onClick={() => onClickEvent!(`/event/${selectedEvent && selectedEvent.slug}/discussion`)}
            >
              <div className="mb-1 text-sm leading-[17px] text-[#000000] font-medium">{selectedEvent && selectedEvent.name!}</div>
            </div>
            <div className="flex flex-row">
              <div className="mb-1.5 text-[11px] leading-[16px] text-[#000000] font-normal">{`${format(parseISO(selectedEvent && selectedEvent.startTime), "dd MMM yyyy 'at' hh:mm aaaa")}`}</div>
            </div>
            <div className='mb-2'>
              <GroupUserIcons 
                users={selectedEvent.relatedAttendees}
                userQty={2}
              />
            </div>
          </div>
          <div className="text-center mb-[13px]">
            <div className="flex justify-between text-center w-full static">
              <div>
                <DropDownEventButton 
                  text="RSVP"
                  iconRight={<ArrowDownSvgIcon className="ml-[10px]" fillColor="#7283A4" />}
                  className='my-2'
                  menuItems={[
                    {
                      id: '1', name: 'Join', url: '', onClick: join
                    },
                    {
                      id: '2', name: 'Interested', url: '', onClick: interested
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center event-card-bulets">
          {events && events.length > 0 &&
            events.map((item, i) => (
                <button
                  key={`tab-button-${i}`}
                  className={classNames("mr-1 bullets", {
                    [`active`]: i == selectedIndex,
                  })}
                  onClick={(e) => {
                    handleClick(e, i);
                  }}>
                  {selectedIndex === i ? '●' : '○'}
                </button>
            ))}
          <button
            className={classNames("mr-1 absolute -right-[6px] top-[23%]")}
            disabled={events.length === selectedIndex + 1}
            onClick={(e) => {
              slideNext(e, selectedIndex + 1);
            }}>
            <img
              className={classNames("", {
                hidden: !showArrows,
              })}
              src={ArrowRightIcon}
            />
          </button>
          <button
            className={classNames("mr-1 absolute -left-[1px] top-[23%]")}
            disabled={selectedIndex === 0}
            onClick={(e) => {
              slideNext(e, selectedIndex - 1);
            }}>
            <img
              className={classNames("", {
                hidden: !showArrows,
              })}
              src={ArrowLeftIcon}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
