import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IAppState } from '../../../store';

import { Logo } from '../../../shared-components/V2/Logo';
import EventRegistrationButton from '../registration/event-registration-button/event-registration-button';
import WebsiteEventFooter from './website-event-footer';
import { Loader } from '../../../shared-components/Loader';

const WebsiteEventQuickLinks = () => {
  const event = useSelector((state: IAppState) => state.website.event!);

  const quickLinks = event.eventLinks.find(l => l.key === 'link');
  const socialLinks = event.eventLinks.find(l => l.key === 'social');
  const contactLinks = event.eventLinks.find(l => l.key === 'contact');

  return (
    <div id="contact-us" className="w-full mb-[0px] mt-[36px]">
      <div className="flex  mx-auto">
        <div className="w-full event-website-box-new pb-[54px] pt-[40px]">
          <div className="md:max-w-[90%] max-w-[97%] mx-auto ">
            <div className="md:flex w-full items-center justify-between pb-[40px]">
              <div className="flex items-center">
                <div className="rounded-[7px] mr-2 profile-avatar-shadow bg-white p-1 w-[40px] h-[40px] flex items-center justify-center">
                  <Logo
                    src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${event?.company?.logo}`}
                    alt=""
                    className="w-[35px] h-[35px] object-contain rounded-5"
                  />
                </div>
                <div
                  className="font-semibold text-[#0326E5] text-[18px] leading-[25px] flex justify-center"
                  style={{
                    color: event?.colorStyle?.values?.heading,
                  }}
                >
                  {event.name}
                </div>
              </div>
              <div className="text-center">
                <div className="flex justify-center">
                  {event ? (
                    <EventRegistrationButton eventId={event.id} />
                  ) : (
                    <Loader className="pt-5" key="loader" />
                  )}
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col justify-center items-center">
              {/* second row    */}
              <div className="w-full md:flex justify-between items-start md:gap-x-[100px]">
                {quickLinks?.metadata.find(q => q.isShow) && (
                  <div className="flex flex-col">
                    {quickLinks?.metadata?.length! > 0 && (
                      <div className="text-[20px] text-[#0B1221] block w-full font-bold mb-[10px]">
                        Quick Links
                      </div>
                    )}
                    {quickLinks?.metadata.map(
                      link => link.isShow && (
                        <a
                          href={`${link.url}`}
                          className="text-[15px] mb-[3px] font-[400] text-[#757575] hover:underline"
                        >
                          {link.title}
                        </a>
                      )
                    )}
                  </div>
                )}
                {contactLinks?.metadata.find(q => q.isShow) && (
                  <div className="flex flex-col">
                    {contactLinks?.metadata && (
                      <div className="text-[20px] text-[#0B1221] block w-full font-bold mb-[10px]">
                        Contact us
                      </div>
                    )}
                    {contactLinks?.metadata.map(
                      link => link.isShow && (
                        <div className="text-[15px] mb-[3px] font-[400] text-[#757575]">
                          {link.title}
                          <span className="h-[19px] ml-[9px] hover:underline cursor-pointer">
                            {link.body}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                )}
                {socialLinks?.metadata && (
                  <div className="flex flex-col">
                    {socialLinks?.metadata && (
                      <div className="text-[20px] text-[#0B1221] block w-full font-bold mb-[10px]">
                        Social links
                      </div>
                    )}
                    <Link
                      to="/home"
                      className="text-[15px] mb-[3px] font-[400] text-[#757575] hover:underline"
                    >
                      Panelist
                    </Link>
                    {socialLinks?.metadata.find(q => q.isShow)
                      && socialLinks?.metadata.map(
                        link => link.isShow && (
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={`${link.url}`}
                            className="text-[15px] mb-[3px] font-[400] text-[#757575] hover:underline"
                          >
                            {link.icon && (
                              <img src={link.icon} className="w-5 h-5" alt="" />
                            )}
                            {link.title}
                          </a>
                        )
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <WebsiteEventFooter />
        </div>
      </div>
    </div>
  );
};

export default WebsiteEventQuickLinks;
