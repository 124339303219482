/* eslint-disable no-alert */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useState, ChangeEvent } from 'react';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { SearchIcon } from 'src/shared-components/V2/IconSvg';
import { IUserProfile } from 'src/mui/types/user'; // Import the correct type for users
import { useFormContext } from 'react-hook-form';
import PeopleListCard from './peopleListCard';

interface AddSalesRepresentativeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  representatives: IUserProfile[];
  onSelectedRepresentativesChange: (selected: IUserProfile[]) => void;
  search: string;
  setSearch: (search: string) => void;
}

const AddSalesRepresentativeDialog: React.FC<AddSalesRepresentativeDialogProps> = ({
  isOpen,
  onClose,
  representatives,
  onSelectedRepresentativesChange,
  search,
  setSearch
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { setValue, getValues } = useFormContext();
  const formValues = getValues();
  const selectedRepresentatives = formValues?.representatives || [];

  // Filter representatives based on search input
  const filteredConnections = representatives.filter((rep: { firstName: any; lastName: any; }) => (`${rep?.firstName} ${rep?.lastName}`).toLowerCase().includes(search));

  const handleUserSelection = (user: IUserProfile) => {
    const isAlreadySelected = selectedRepresentatives.some((rep: IUserProfile) => rep.id === user.id);

    const updatedRepresentatives = isAlreadySelected
      ? selectedRepresentatives.filter((rep: IUserProfile) => rep.id !== user.id)
      : [...selectedRepresentatives, user];

    setValue('representatives', updatedRepresentatives);
    onSelectedRepresentativesChange(updatedRepresentatives);
  };

  const handleSaveAndAdd = () => {
    if (isChecked) {
      console.log('Save and Add clicked', selectedRepresentatives);
      onClose();
    } else {
      alert('You must verify that you are an authorized representative before proceeding.');
    }
  };

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      className="w-576 p-2 container"
    >
      <>
        <div className="text-md px-2 py-1 pb-4">Add Sales Representative</div>
        <div className="px-2">
          <div className="px-3 flex items-center text-sm rounded-[5px] focus:outline-none w-full h-[35px] border-1 border-b-[#DEDEDE] font-extralight text-gray-1 bg-white">
            <SearchIcon />
            <input
              name="search"
              className="w-full ml-2 text-sm5 focus:outline-none block font-light text-gray-900 placeholder:text-gray-900 bg-transparent"
              placeholder="Search for a name"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value.toLowerCase())}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="w-full h-420 overflow-auto px-4">
          <PeopleListCard
            people={filteredConnections} // Display filtered connections
            selectedIds={selectedRepresentatives.map((rep: IUserProfile) => rep.id)}
            onClick={handleUserSelection}
            key="user-connections"
          />
        </div>
        <div className="flex items-start p-4">
          <input
            type="checkbox"
            id="authorizationCheck"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="authorizationCheck" className="text-sm text-gray-800">
            I verify that I am an authorized representative of this organization and have the right to act on its behalf in the creation and management of this page.
          </label>
        </div>
        <div className="flex justify-end mt-4 px-4 gap-4">
          <button
            className="bg-gray-200 text-blue text-sm font-semibold px-3 py-1 rounded-[5px]"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-[#0049EF] hover:bg-[#2e6eff] text-white text-sm font-semibold px-3 py-1 rounded-[5px]"
            onClick={handleSaveAndAdd}
            disabled={!isChecked}
          >
            Save & Add
          </button>
        </div>
      </>
    </AlertModal>
  );
};

export default AddSalesRepresentativeDialog;
