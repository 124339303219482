import { SVGProps } from 'react';
import { getBrandingColors } from 'src/mui/pages/event/live/liveEventStyling';

function CtrlPanelBottomIcon(props: SVGProps<SVGSVGElement>) {
    const { style, ...restProps } = props;
    const defaultColor = getBrandingColors()?.Text || '#ffffff';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="37.277" height="38.816" viewBox="0 0 37.277 38.816">
            <g id="Page-1" transform="translate(37.277) rotate(90)">
                <g id="Dribbble-Light-Preview">
                    <g id="icons">
                        <path id="grid-_1526_" data-name="grid-[#1526]"
                              d="M21.328,25.852H17.45a3.788,3.788,0,0,0-3.878,3.693v3.693a3.788,3.788,0,0,0,3.878,3.693h3.878a3.788,3.788,0,0,0,3.878-3.693V29.546a3.788,3.788,0,0,0-3.878-3.693m-13.572,0H3.878A3.788,3.788,0,0,0,0,29.546v3.693a3.788,3.788,0,0,0,3.878,3.693H7.756a3.788,3.788,0,0,0,3.878-3.693V29.546a3.788,3.788,0,0,0-3.878-3.693M21.328,12.926H17.45a3.788,3.788,0,0,0-3.878,3.693v3.693a3.788,3.788,0,0,0,3.878,3.693h3.878a3.788,3.788,0,0,0,3.878-3.693V16.619a3.788,3.788,0,0,0-3.878-3.693m-13.572,0H3.878A3.788,3.788,0,0,0,0,16.619v3.693a3.788,3.788,0,0,0,3.878,3.693H7.756a3.788,3.788,0,0,0,3.878-3.693V16.619a3.788,3.788,0,0,0-3.878-3.693M17.45,0a3.788,3.788,0,0,0-3.878,3.693V7.386A3.788,3.788,0,0,0,17.45,11.08h3.878a3.788,3.788,0,0,0,3.878-3.693V3.693A3.788,3.788,0,0,0,21.328,0M7.756,0H3.878A3.788,3.788,0,0,0,0,3.693V7.386A3.788,3.788,0,0,0,3.878,11.08H7.756a3.788,3.788,0,0,0,3.878-3.693V3.693A3.788,3.788,0,0,0,7.756,0"
                              fill={defaultColor} fill-rule="evenodd"/>
                        <rect id="Rectangle_8435" data-name="Rectangle 8435" width="11" height="37" rx="3"
                              transform="translate(27.816 0.277)" fill={defaultColor}/>
                    </g>
                </g>
            </g>
        </svg>


    );
}

export default CtrlPanelBottomIcon;
