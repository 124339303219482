import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IJobTitlesResult } from '../../models/company/JobTitlesResult';
import { IJobTitlesState } from '../../models/company/JobTitlesState';
import { CompanyService } from '../../services';
import { JobTitlesActionTypes } from './types';

export interface IJobTitlesLoadingAction {
  type: JobTitlesActionTypes.JOB_TITLES_LOADING;
  loading: boolean;
}

export interface IJobTitlesSuccessAction {
  type: JobTitlesActionTypes.JOB_TITLES_SUCCESS;
  data: IJobTitlesResult;
}

export interface IJobTitlesErrorAction {
  type: JobTitlesActionTypes.JOB_TITLES_ERROR;
  errorMessage: string;
}

export type JobTitlesActions =
  | IJobTitlesLoadingAction
  | IJobTitlesSuccessAction
  | IJobTitlesErrorAction;

export const getJobTitles: ActionCreator<
  ThunkAction<Promise<any>, IJobTitlesState, null, IJobTitlesSuccessAction>
> = (search: string, limit: number) => {
  return async (dispatch: Dispatch) => {
    const companyService: CompanyService = new CompanyService();

    try {
      const result = await companyService.getJobTitlesWithQuery(search, limit);

      dispatch({
        type: JobTitlesActionTypes.JOB_TITLES_SUCCESS,
        data: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: JobTitlesActionTypes.JOB_TITLES_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: JobTitlesActionTypes.JOB_TITLES_LOADING,
        loading: false
      });
    }
  };
};
