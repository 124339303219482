import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { IEvent } from '../../../models/event/event';
import { IAppState } from '../../../store';

import { Banner } from './banner';
import EventOverview from './event-overview';
import IndustryAudience from './industry-audience';
import WebsiteEventAgenda from './website-event-agenda';
import WebsiteEventKeyDiscussion from './website-event-key';
import WebsiteEventLocation from './website-event-location';
import WebsiteEventPartner from './website-event-partner';
import WebsiteEventQuickLinks from './website-event-quicklinks';
import WebsiteEventSpeaker from './website-event-speaker';
import WhatToExpect from './what-to-expect';

export const WebsiteEventPreview = () => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);

  return (
    <>
      <Helmet>
        <meta name="title" content={event.seoTitle} />
        <meta name="description" content={event.seoDescription} />
      </Helmet>
      <Banner />
      <EventOverview />
      <div className="max-w-[1124px] mx-auto px-[20px] xl:px-0 mt-[50px]">
        <IndustryAudience />
        <WhatToExpect />
        <WebsiteEventKeyDiscussion />
        <WebsiteEventSpeaker />
        <WebsiteEventAgenda />
        <WebsiteEventPartner />
        <WebsiteEventLocation latitude={event?.latitude} longitude={event?.longitude} address={event.location!} />
      </div>
      <div className="w-full">
        <WebsiteEventQuickLinks />
      </div>
    </>
  );
};
