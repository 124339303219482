import { Box, Typography, Stack } from '@mui/material';
import { getBrandingColors } from '../live/liveEventStyling';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader } from 'src/shared-components/Loader';
import { WelcomePost } from 'src/shared-components/V2/WelcomePost';
import { CardMainPost } from 'src/components/posts';
import { IPost } from 'src/models/post/post';
import { useCallback, useEffect, useState } from 'react';
import { shuffleArray, sortByKey } from 'src/utils';
import { IEvent } from 'src/models/user-events';
import { IAppState } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { PostService, UserService } from 'src/services';

import noPostsImage from 'src/assets/images/no-post-new-discussion.svg';
import commentIcon from 'src/assets/images/comment-icon.svg';
import shareIcon from 'src/assets/images/share-icon.svg';
import { getUserPostComments, getUserPosts } from 'src/store/user/posts/actions';
import { ICreateComment } from 'src/models/post/comment';
import { createPostComment } from 'src/store/posts/comments/actions';
import { likeComment, unlikeComment } from 'src/store/events/event-post-comment-like/actions';
import { likePost, unlikePost } from 'src/store/events/upcoming-event-post-like/actions';
import { editPost } from 'src/store/events/event-post-actions/actions';
import { getUpcomingEventPosts } from 'src/store/events/upcoming-event-posts/actions';
import { cleanupUpcomingEvent, getUpcomingEvent } from 'src/store/events/upcoming-event/actions';
import { getTickets } from 'src/store/ticket';
import { getOwnConnections } from 'src/store/user/own-connections/actions';
import { getUserProfile } from 'src/store/user/user-profile/actions';


const PersonPosts = () => {
    const colors = getBrandingColors();
    const userService: UserService = new UserService();
  const [eventFeeds, setEventFeeds] = useState<IPost[]>([]); 
  const [nextPage, setNextPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [orderBy, setOrderBy] = useState<"Most recent" | "Top posts">(
    "Most recent"
  );
  const { slug: userId } = useParams();

  const postsService: PostService = new PostService();
  const params = useParams();
  const liveEvent = useSelector((state: IAppState) => state.liveEvent);
  const eventIdOrSlug: string = liveEvent?.event?.slug!;

  const resetToDefaults = () => {
    setNextPage(1);
    setTotalPage(0);
    setFetching(false);
    setEventFeeds([]);
  };

  const dispatch = useDispatch();
  const { upcomingEvent, upcomingEventPosts, userProfile } = useSelector(
    (state: any) => state
  );
  const { auth } = useSelector((state: IAppState) => state);
  const { currentUser: user } = auth;
  const defaultAvatar = process.env.REACT_APP_NO_COMPANY_LOGO_URL;
  const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;

  useEffect(() => () => {
    dispatch(cleanupUpcomingEvent());
  }, []);

  useEffect(() => {
    if (upcomingEventPosts) setTotalPage(upcomingEventPosts.value?.totalPage!);
  }, [upcomingEventPosts]);

  useEffect(() => {
    setIsOwnProfile(user?.id === userId || user?.slug === userId);
  }, [userId, user]);

  useEffect(() => {
    if (isOwnProfile) {
      dispatch(getOwnConnections());
    } else dispatch(getOwnConnections(userId));
  }, [dispatch, isOwnProfile, userId]);

  useEffect(() => {
    if (upcomingEvent.value?.data.event.id) {
      dispatch(getTickets(upcomingEvent.value?.data.event.id!));
    }
  }, [dispatch, upcomingEvent.value?.data.event.id]);

  const fetchPosts = async () =>
    dispatch(getUpcomingEventPosts(eventIdOrSlug, 1, orderBy));

  const fetchPostComments = async (postId: string, page?: number) => dispatch(getUserPostComments(postId, page));

  const fetchItems = useCallback(async () => {
    if (fetching) {
      return;
    }

    setFetching(true);

    try {
      let sortBy: string = "";
      if (orderBy === "Most recent") sortBy = "&order[createdAt]=DESC";
      else sortBy = "&order[rank]=DESC";

      const { postsResult } = await postsService.getUpcomingEventPosts(
        eventIdOrSlug,
        nextPage,
        sortBy
      );
      setTotalPage(postsResult.totalPage);
      if (postsResult && nextPage <= postsResult.totalPage) {
        setEventFeeds(eventFeeds.concat(postsResult.data));
        setNextPage(nextPage + 1);
      } else {
        setNextPage(nextPage);
        setFetching(true);
      }
    } finally {
      setFetching(false);
    }
  }, [eventFeeds, fetching, nextPage]);

  const hasMoreItems = nextPage <= totalPage;

  const loader = <Loader className="pt-5" key="loader" />;

  const sortClick = async (sortType: "Most recent" | "Top posts") => {
    resetToDefaults();
    setOrderBy(sortType);
    await fetchPosts();
  };

  useEffect(() => {
    if (eventIdOrSlug) {
      resetToDefaults();
      dispatch(getUpcomingEvent(eventIdOrSlug));
      dispatch(getUpcomingEventPosts(eventIdOrSlug));
    }
    dispatch(getUserProfile());
  }, [dispatch, eventIdOrSlug]);

  return (
    <Box sx={{ display: 'flex',width:"310px", height: '100%', mt: '13px', mb: '10px', overflow: 'auto' }}>
      <div className="overflow-visible max-w-[310px] w-[310px]">
            <InfiniteScroll
              pageStart={1}
              loadMore={fetchItems}
              hasMore={hasMoreItems}
              loader={loader}
            >
              {/* No Post Welcome component */}
              {(( upcomingEventPosts.value?.data.length === 0) && !eventFeeds?.length) && (
                  <WelcomePost
                    className="mt-4"
                    title={`Welcome to Panelist, ${user?.firstName}!`}
                    imageSrc={noPostsImage}
                    subTitle="Connect with people you know and join events you're interested in."
                    subTitleClass="max-w-[282px] leading-[25px] text-black"
                  />
              )}
                {eventFeeds &&
                    eventFeeds.map(
                  (post: any, index: number) => post && (
                    <CardMainPost
                      commentIcon={commentIcon}
                      shareIcon={shareIcon}
                      className="mb-[10px] w-full create-post-card !rounded-[9px]"
                      key={`event-${index}`}
                      post={post}
                      isLivePage
                      getPostComments={(id: string, page: number = 1) => fetchPostComments(id, page)}
                      addPostComment={async (
                        postId: string,
                        data: any,
                        attachment: any
                      ) => {
                        const comment: ICreateComment = {
                          content: data,
                          parentId: '',
                          userMentioned: '',
                        };
                        await dispatch(
                          createPostComment(postId, comment, attachment)
                        );
                        await fetchPostComments(postId);
                        // await fetchPosts();
                      }}
                      addReply={async (
                        postId: string,
                        replyText: string,
                        parentId: string,
                        attachment: any
                      ) => {
                        const comment: ICreateComment = {
                          content: replyText,
                          parentId,
                          userMentioned: '',
                        };
                        await dispatch(
                          createPostComment(postId, comment, attachment)
                        );
                        await fetchPostComments(postId);
                      }}
                      onCommentLike={async (
                        likeType: string,
                        commentId: string
                      ) => {
                        if (likeType) {
                          await dispatch(likeComment(commentId, likeType));
                        } else {
                          await dispatch(unlikeComment(commentId));
                        }
                        // await fetchPosts();
                      }}
                      onPostLike={async (
                        likeType: string,
                        postId: string
                      ) => {
                        // If you got like type then set the like on post else its remove like 'undefined'
                        if (likeType) {
                          await dispatch(likePost(postId, likeType));
                        } else {
                          await dispatch(unlikePost(postId));
                        }
                        // await fetchPosts();
                      }}
                      onDelete={async () => {
                        const postService = new PostService();
                        await postService.deletePost(post.id);

                        resetToDefaults();
                        await fetchPosts();
                      }}
                      onEditPost={async (
                        postId: string,
                        content: string,
                        privacy: string
                      ) => {
                        await dispatch(editPost(postId, content, privacy));
                        // resetToDefaults();
                        await fetchPosts();
                      }}
                      onAfterShare={async () => {
                        resetToDefaults();
                        await fetchPosts();
                      }}
                    />
                  )
                )}
            </InfiniteScroll>
        </div>
    </Box>
  );
};

export default PersonPosts;
