/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-useless-fragment */
import {
  TableRow,
  TableCell,
  Typography,
  useTheme,
  Tooltip,
  Stack,
  Box,
  Button,
  TextField,
  Checkbox,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import format from 'date-fns/format';
import { useEffect, useState } from 'react';
import AlertModal from 'src/components/alert-modal/alert-modal';
import { DeletePost } from 'src/components/posts/delete-post';
import { CompanyService } from 'src/services';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { minifiedImage } from 'src/utils';
import { Logo } from 'src/shared-components/V2/Logo';
import Owner from './Owner';
import CompanyAccountDropdown from './CompanyDropdown';

type Props = {
  selected: boolean;
  onSelectRow?: VoidFunction;
  accountData?: any;
  index: number;
  onCompanyClick: (value: any) => void;
  onAccountDelete: (value: any) => void;
  fetchAccountFiles: Function;
  setIsLoader: any;
};

const EventsTableRow = ({
  selected,
  onSelectRow,
  accountData,
  index,
  onCompanyClick,
  onAccountDelete,
  fetchAccountFiles,
  setIsLoader
}: Props) => {
  const [progress, setProgress] = useState(10);
  const [showValue, setShowValue] = useState(false);
  const [title, setTitle] = useState(accountData?.name || '');
  const [description, setDescription] = useState(
    accountData?.description || ''
  );
  const theme = useTheme();
  const formattedDate = format(new Date(accountData?.updatedAt), 'M/d/yyyy');
  const [deleteAccount, setDeleteAccount] = useState({
    open: false,
    accountId: null,
  });
  const [editAccount, setEditAccount] = useState({
    open: false,
    accountId: null,
  });

  const queryParams = new URLSearchParams(window.location.search);
  const isCreating = queryParams.get('creating');

  const onSponserClick = (slug: string) => {
    if (slug) {
      window.open(`/event/${slug}/discussion`, '_blank');
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    if (isCreating) {
      const timer = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(timer);
            setShowValue(true);
            return 100;
          }
          return Math.min(prev + 40, 100);
        });
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setShowValue(true);
      return undefined;
    }
  }, [isCreating]);

  const renderCellContent = (index: number) => {
    if (index === 0) {
      return showValue ? (
        <div>{accountData?.accounts || 0}</div>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: 'auto', display: 'flex', mr: 1 }}>
            <CircularProgress
              size={20}
              variant="determinate"
              value={progress}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">
              {`${Math.round(progress)}%`}
            </Typography>
          </Box>
        </Box>
      );
    }

    return <div>{accountData?.accounts || 0}</div>;
  };

  const onCloseDeleteProduct = () => {
    setDeleteAccount({ open: false, accountId: null });
  };

  const handleUpdate = async (id: string | null) => {
    if (id) {
      try {
        setEditAccount({ open: false, accountId: null });
        setIsLoader(true);
        const data = {
          name: title,
          description,
        };
        const companyService = new CompanyService();
        const response = await companyService.patchCompanyAccountFileId(
          id,
          data
        );
        if (response?.data) {
          fetchAccountFiles();
        }
      } catch (err) {
        console.error('handleUpdate() erroraa', err);
      } finally {
        setIsLoader(false);
      }
    }
  };

  return (
    <>
      <TableRow hover selected={selected} key={accountData.id}>
        {onSelectRow && (
          <TableCell
            padding="checkbox"
            sx={{
              p: 0,
              pl: '2px',
              py: '2px',
              height: '23px',
              weight: '23px',
              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : null,
            }}
          >
            <Checkbox
              checked={selected}
              onClick={onSelectRow}
              sx={{
                '&.MuiCheckbox-root svg': {
                  width: '23px',
                  height: '23px',
                },
                '&.MuiCheckbox-root path': {
                  width: '23px',
                  height: '23px',
                },
              }}
            />
          </TableCell>
        )}
        <TableCell
          align="left"
          sx={{
            p: '10px 16px',
          }}
        >
          <Typography variant="subtitle2">
            <Tooltip
              id="custom-tooltip-class"
              title={accountData?.name}
            >
              <Stack
                className="eventLink ellipsis-one-line"
                style={{
                  maxWidth: '420px',
                  cursor: 'pointer',
                }}
                onClick={() => onCompanyClick(accountData?.id)}
              >
                {accountData?.name}
              </Stack>
            </Tooltip>
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ textTransform: 'capitalize', p: '10px 16px' }}>
          {renderCellContent(index)}
        </TableCell>
        <TableCell align="left">
          <Owner
            name={accountData?.creator?.fullName}
            logo={accountData?.creator?.avatar}
          />
        </TableCell>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {formattedDate}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            p: '10px 16px',
          }}
        >
          {accountData?.assignedEventSponsors?.length > 0 && (
          <>
            {accountData?.assignedEventSponsors?.map((sponsor: any, index: any) => (
              <Stack direction="row" alignItems="center" mb="5px" spacing={1} key={index}>
                <Logo
                  alt={sponsor?.eventSponsor?.event?.name}
                  className="w-[25px] h-[25px] min-w-[25px] min-h-[25px] object-contain bg-center !rounded-[5px] bg-[#edf2f5]"
                  src={minifiedImage(sponsor?.eventSponsor?.company?.logo)}
                  defaultLogo="company"
                />
                <Tooltip
                  id="custom-tooltip-class"
                  title={sponsor?.eventSponsor?.event?.name}
                >
                  <Typography
                    flexDirection="row"
                    gap="7px"
                    maxWidth="150px"
                    variant="subtitle2"
                    className="eventLink ellipsis-one-line"
                    sx={{
                      color: 'blue', textDecoration: 'underline', cursor: 'pointer', textTransform: 'uppercase'
                    }}
                    onClick={() => onSponserClick(sponsor?.eventSponsor?.event?.slug)}
                  >
                    {sponsor?.eventSponsor?.event?.name}
                  </Typography>
                </Tooltip>
                <OpenInNewIcon style={{ fontSize: 16, color: 'blue', marginLeft: '5px' }} />
              </Stack>
            ))}
          </>
          )}
        </TableCell>
        <TableCell align="right">
          <CompanyAccountDropdown
            handleDelete={() => setDeleteAccount({ open: true, accountId: accountData?.id })}
            handleEdit={() => setEditAccount({ open: true, accountId: accountData?.id })}
          />
        </TableCell>
      </TableRow>

      {deleteAccount?.accountId && (
        <AlertModal
          isCloseIcon
          closeIconClass="right-[7px] top-[14px]"
          isOpen={deleteAccount?.open}
          onClose={onCloseDeleteProduct}
          className="w-[374px] bg-[#EDF2F5]"
        >
          <DeletePost
            postId={deleteAccount?.accountId}
            message="Are you sure you want to delete these accounts?"
            onDelete={async () => {
              if (deleteAccount?.accountId) {
                onCloseDeleteProduct();
                setIsLoader(true);
                try {
                  await onAccountDelete(deleteAccount.accountId);
                } catch (err) {
                  console.error('Error deleting account:', err);
                } finally {
                  setIsLoader(false);
                }
              }
            }}
            onClose={onCloseDeleteProduct}
          />
        </AlertModal>
      )}
      {editAccount?.open && (
        <AlertModal
          className="w-576 p-2 container"
          closeIconClass="top-[18px] right-[11px]"
          isCloseIcon
          isOpen={editAccount?.open}
          onClose={() => setEditAccount({ open: false, accountId: null })}
        >
          <div className="px-[10px]">
            <h2 className="text-md py-1 pb-2">Update Accounts</h2>
            <div className="" />
            <div className="border-b-1 border-gray-300 my-2" />
            <div>
              <Typography
                color="text.primary"
                variant="subtitle2"
                sx={{
                  fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500', mt: '20px'
                }}
              >
                Name
              </Typography>
              <TextField
                name="title"
                type="text"
                value={title}
                onChange={handleTitleChange}
                fullWidth
                placeholder="Add your title here..."
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '31px !important',
                    padding: '0',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    fontSize: '13px',
                    // color: '#666666'
                  },
                  '& .MuiOutlinedInput-input': {
                    height: '31px !important',
                    padding: '0 10px !important',
                  },
                }}
              />

            </div>
            <div className="w-full h-340 overflow-auto pt-[20px]">
              <Typography
                color="text.primary"
                variant="subtitle2"
                sx={{
                  fontSize: '15px', fontWeight: 500, lineHeight: '20px', mb: '8px', fontFamily: 'Poppins-500',
                }}
              >
                Description
              </Typography>
              <TextField
                name="description"
                type="text"
                value={description}
                onChange={handleDescriptionChange}
                inputProps={{ maxLength: 250 }}
                rows={4}
                fullWidth
                multiline
                placeholder="Add your description here..."
                sx={{

                  '& .MuiInputBase-root': {
                    backgroundColor: 'white',
                    padding: '10px'
                  },
                }}
              />
            </div>
            <Box display="flex" mt="8px" alignItems="end" justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: '#0031FF',
                  '&:hover': {
                    backgroundColor: '#0031FF',
                    '& .MuiTypography-root': {
                      color: '#ffffff',
                    },
                  },
                }}
                onClick={() => handleUpdate(editAccount?.accountId)}
              >
                Update
              </Button>
            </Box>
          </div>
        </AlertModal>
      )}
    </>
  );
};

export default EventsTableRow;
