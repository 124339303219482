import { AxiosRequestConfig } from 'axios';
import { IBaseAPIPostMethodResult } from '../../models/base-apipostmethod-result';
import { CheckEmailModel } from '../../models/check-email';
import { CheckUserEmailResult } from '../../models/CheckUserEmailResult';
import {
  IConnectionsResult,
  IConnectStatusResult,
} from '../../models/connection';
import { ConnectSuggestionsResult } from '../../models/ConnectSuggestionsResult';
import { IAddExperience } from '../../models/experience';
import { SearchBarResult } from '../../models/nav-bar';
import { IConnectionRequestsResult } from '../../models/nav-bar/connection-requests/connection-requests-result';
import { INotificationsResult } from '../../models/nav-bar/notifications/notifications-result';
import { IOwnExperienceResult } from '../../models/own-experience';
import { IPostSpotlightResult } from '../../models/post/IPostSpotlight';
import { IPostsResult } from '../../models/post/PostsResult';
import { RecommendedEventsResult } from '../../models/RecommendedEventsResult';
import { IUserSignUpModel } from '../../models/signup/user-signup';
import { UserLoginModel } from '../../models/user-login';
import { UserLoginResult } from '../../models/user-login-result';
import { IUserProfileResult } from '../../models/user-profile';
import {
  IAccountPrivacySettings,
  IAccountSettingNotification,
  IAccountSettings,
  ICards,
} from '../../models/user/account-settings/account-settings';
import { IForgotPasswordModel } from '../../models/user/forgotpassword/forgotpassword';
import { IForgotPasswordResult } from '../../models/user/forgotpassword/forgotpassword-result';
import { IOneTimeLinkModel } from '../../models/user/oneTimeLink/oneTimeLink';
import { IOneTimeLinkResult } from '../../models/user/oneTimeLink/oneTimeLink-result';
import { UserEventsResult } from '../../models/UserEventsResult';
import { UserSearchResult } from '../../store/user/search-user';
import BaseService from '../BaseService';

// TODO: Remove ASYNC or use await before returning the response.
export class UserService extends BaseService {
  async getAllUsers(search: string = '') {
    try {
      const extraOptions = this.getAuthToken();
      const url = `/users/search?page=1&limit=15&search=${encodeURIComponent(search)}`;
      return this.get<any>(url, extraOptions);
    } catch (error) {
      console.error('An error occurred:', error);
      throw error;
    }
  }

  async checkEmail(data: CheckEmailModel) {
    return this.post<CheckUserEmailResult>('/auth/email-check', data);
  }

  async login(data: UserLoginModel) {
    return this.post<UserLoginResult>('/auth/login', data);
  }

  async refreshToken(refreshToken: string) {
    return this.post<UserLoginResult>('/auth/refresh', { refreshToken });
  }

  async forgotPassword(data: IForgotPasswordModel) {
    return this.post<IForgotPasswordResult>('/auth/forgot-password', data);
  }

  async resetPassword(data: IForgotPasswordModel) {
    return this.post<IForgotPasswordResult>('/auth/reset-password', data);
  }

  async registerStep1(data: IUserSignUpModel) {
    return this.post<UserLoginResult>('/auth/register-step-1', data);
  }

  async registerStep2(data: IUserSignUpModel) {
    const extraOptions = this.getAuthToken();

    return this.post<IBaseAPIPostMethodResult>(
      '/auth/register-step-2',
      data,
      extraOptions
    );
  }

  async registerStep3(data: IUserSignUpModel) {
    const extraOptions = this.getAuthToken();
    return this.post<IBaseAPIPostMethodResult>(
      '/auth/register-step-3',
      data,
      extraOptions
    );
  }

  async registerStep4(data: IUserSignUpModel) {
    const extraOptions = this.getAuthToken();
    return this.post<IBaseAPIPostMethodResult>(
      '/auth/register-step-4',
      data,
      extraOptions
    );
  }

  async requestAdminAccess(companyId: string) {
    const extraOptions = this.getAuthToken();
    return this.post<any>(
      '/company-admin-requests',
      {
        companyId,
      },
      extraOptions
    );
  }

  async shortRegister(data: IUserSignUpModel) {
    return this.post<UserLoginResult>('/auth/short-register', data);
  }

  async submitRegister(eventId: string, body: {}) {
    const extraOptions = this.getAuthToken();
    const url = `/events/${eventId}/join`;
    return this.post<IBaseAPIPostMethodResult>(url, body, extraOptions);
  }

  async resendEmailVerify() {
    const extraOptions = this.getAuthToken();
    const url = '/auth/resend-verify';
    return this.post<any>(url, {}, extraOptions);
  }

  async registerInterests(data: IUserSignUpModel) {
    const extraOptions = this.getAuthToken();
    const result = await this.put<IBaseAPIPostMethodResult>(
      '/users/me/user-interests',
      data.interestIds,
      extraOptions
    );
    return result;
  }

  async registerOffers(data: IUserSignUpModel) {
    const extraOptions = this.getAuthToken();
    const result = await this.put<IBaseAPIPostMethodResult>(
      '/users/me/user-offerings',
      data.offerIds,
      extraOptions
    );
    return result;
  }

  async oneTimeLink(data: IOneTimeLinkModel) {
    return this.get<IOneTimeLinkResult>(
      `/auth/one-time-sign-in?email=${data.email}`
    );
  }

  async getUserProfile(userIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<IUserProfileResult>(
      `/users/${userIdOrSlug}/profile`,
      extraOptions
    );
  }

  async getUserProfileLite(userIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<IUserProfileResult>(
      `/users/${userIdOrSlug}/profile-light`,
      extraOptions
    );
  }

  async getOwnProfile(token?: string) {
    const extraOptions: AxiosRequestConfig = token
      ? {
        headers: {
          Authorization: `${token}`,
        },
      }
      : this.getAuthToken();

    return this.get<IUserProfileResult>('/users/me/profile', extraOptions);
  }

  async editOwnProfile(dataObj: any) {
    const extraOptions = this.getAuthToken();

    return this.put<IUserProfileResult>(
      '/users/me/profile',
      dataObj,
      extraOptions
    );
  }

  async editAccountSetting(data: IAccountSettings) {
    const extraOptions = this.getAuthToken();
    const result = await this.put<IBaseAPIPostMethodResult>(
      '/users/me/account',
      data,
      extraOptions
    );
    return result;
  }

  async getAccountNotifications() {
    const extraOptions = this.getAuthToken();

    return this.get<any>('/notification-settings', extraOptions);
  }

  async editAccountNotifications(data: IAccountSettingNotification) {
    const extraOptions = this.getAuthToken();

    const result = await this.put<IBaseAPIPostMethodResult>(
      '/notification-settings',
      data,
      extraOptions
    );
    return result;
  }

  async getAccountPrivcaySettings() {
    const extraOptions = this.getAuthToken();

    return this.get<any>('/privacy-settings', extraOptions);
  }

  async editAccountPrivcaySettings(data: IAccountPrivacySettings) {
    const extraOptions = this.getAuthToken();

    const result = await this.put<any>('/privacy-settings', data, extraOptions);

    return result;
  }

  async getBlockedProfile() {
    const extraOptions = this.getAuthToken();

    return this.get<any>('/users/block?page=1&limit=1000', extraOptions);
  }

  async unBlockProfile(userId: string) {
    const extraOptions = this.getAuthToken();

    const result = await this.put<any>(
      `/users/${userId}/unblock`,
      {},
      extraOptions
    );

    return result;
  }

  async getTransactions(pageSize: number = 1000, currentPage: number = 1) {
    const extraOptions = this.getAuthToken();

    return this.get<any>(
      `/transactions?page=${currentPage}&limit=${pageSize}&order[paymentAt]=desc`,
      extraOptions
    );
  }

  async getCards() {
    const extraOptions = this.getAuthToken();

    return this.get<ICards>('/cards', extraOptions);
  }

  async createCard(source: any) {
    const extraOptions = this.getAuthToken();
    const body = { request: '' };
    body.request = source;
    return this.post<any>('/cards', body, extraOptions);
  }

  async createCardToken(data: any) {
    const extraOptions = this.getAuthToken();

    return this.post<any>('/tokens', data, extraOptions);
  }

  async changePassword(data: any) {
    const extraOptions = this.getAuthToken();

    return this.post<any>('/auth/change-password', data, extraOptions);
  }

  async userAccountDeactivate() {
    return this.put<any>('/users/me/deactivate', {});
  }

  async getEventRegistrationForm(eventIdOrSlug: string) {
    return this.get<any>(`/guest/events/${eventIdOrSlug}/registration-form`);
  }

  async getEventSponsors(eventIdOrSlug: string) {
    return this.get<any>(`/events/${eventIdOrSlug}/sponsors?page=1&limit=100`);
  }

  async getEventsJoin(eventIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    let whereCondition = '';
    if (eventIdOrSlug) {
      whereCondition = `where[slug]=${eventIdOrSlug}`;
    }
    return this.get<UserEventsResult>(
      `/events/joined?${whereCondition}&page=1&limit=10`,
      extraOptions
    );
  }

  async getOwnExperience() {
    const extraOptions = this.getAuthToken();

    return this.get<IOwnExperienceResult>(
      '/users/me/experiences',
      extraOptions
    );
  }

  async getUserExperience(userIdOrSlug: string) {
    const extraOptions = this.getAuthToken();

    return this.get<IOwnExperienceResult>(
      `/users/${userIdOrSlug}/experiences`,
      extraOptions
    );
  }

  async addExperience(data: IAddExperience) {
    const extraOptions = this.getAuthToken();

    return this.post<any>('/users/me/experiences', data, extraOptions);
  }

  async editExperience(data: any, experienceId: string) {
    const extraOptions = this.getAuthToken();

    return this.put<any>(
      `/users/me/experiences/${experienceId}`,
      data,
      extraOptions
    );
  }

  async deleteExperience(experienceId: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<any>(
      `/users/me/experiences/${experienceId}`,
      extraOptions
    );
  }

  async getOwnConnections() {
    const extraOptions = this.getAuthToken();

    return this.get<IConnectionsResult>('/users/me/connections', extraOptions);
  }

  async getUserConnections(userIdOrSlug: string, page: number = 1, limit: number = 1000) {
    const extraOptions = this.getAuthToken();

    return this.get<IConnectionsResult>(
      `/users/${userIdOrSlug}/connections?page=${page}&limit=${limit}`,
      extraOptions
    );
  }

  async setConnectSuggestion(connectionUserId: string) {
    const extraOptions = this.getAuthToken();

    return this.post<ConnectSuggestionsResult>(
      '/users/me/connection-requests',
      { connectionUserId },
      extraOptions
    );
  }

  async getConnectSuggestions(page: number = 1, limit: number = 50) {
    const extraOptions = this.getAuthToken();

    return this.get<ConnectSuggestionsResult>(
      `/users/me/connection-suggests?page=${page}&limit=${limit}`,
      extraOptions
    );
  }

  async getConnectRequests(page?: number) {
    const extraOptions = this.getAuthToken();

    return this.get<IConnectionRequestsResult>(
      `/users/me/requests?page=${page || 1}&limit=15`,
      extraOptions
    );
  }

  async getMeetingRequests() {
    const extraOptions = this.getAuthToken();

    return this.get<IConnectionRequestsResult>(
      '/users/me/requests?page=1&limit=100',
      extraOptions
    );
  }

  async acceptConnectRequests(connectionRequestId: string) {
    const extraOptions = this.getAuthToken();

    return this.put<IConnectionRequestsResult>(
      `/users/me/connection-requests/${connectionRequestId}`,
      {},
      extraOptions
    );
  }

  rejectConnectRequests(connectionRequestId: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<IBaseAPIPostMethodResult>(
      `/users/me/connection-requests/${connectionRequestId}`,
      extraOptions
    );
  }

  async acceptOrRejectMeetings(meetingId: string, eventId: string, body: any) {
    const extraOptions = this.getAuthToken();
    return this.patch<IConnectionRequestsResult>(
      `/user/events/${eventId}/meeting/${meetingId}/status
      `,
      body,
      extraOptions
    );
  }

  async getOwnEvents() {
    const extraOptions = this.getAuthToken();

    return this.get<UserEventsResult>(
      '/events/user?page=1&limit=3',
      extraOptions
    );
  }

  async getAllPosts(
    page: number = 1,
    orderBy: string = '&order[createdAt]=DESC'
  ) {
    const extraOptions = this.getAuthToken();

    return this.getV2<IPostsResult>(
      `/posts/feed?page=${page}&limit=10${orderBy}`,
      // `/posts/short-feed?page=${page}&limit=10${orderBy}`,
      extraOptions
    );
  }

  async getAllStories(
    page: number = 1,
    orderBy: string = '&order[createdAt]=DESC'
  ) {
    const extraOptions = this.getAuthToken();

    return this.get<IPostsResult>(
      `/stories?page=${page}&limit=10${orderBy}`,
      extraOptions
    );
  }

  async viewStory(postId:string) {
    const extraOptions = this.getAuthToken();
    const result = await this.post<any>(
      `/posts/${postId}/view`,
      {},
      extraOptions
    );

    return result;
  }

  async getPostsSuggestions(
    page: number = 1,
    orderBy: string = '&order[createdAt]=DESC'
  ) {
    const extraOptions = this.getAuthToken();

    return this.getV2<IPostsResult>(
      `/posts/suggestion?page=${page}&limit=2${orderBy}`,
      // `/posts/short-feed?page=${page}&limit=10${orderBy}`,
      extraOptions
    );
  }

  async getOwnProfilePosts(page: number = 1) {
    const extraOptions = this.getAuthToken();

    return this.get<IPostsResult>(
      `/users/me/posts/profile??page=${page}&limit=10&order[createdAt]=DESC`,
      extraOptions
    );
  }

  async getOtherUserProfilePosts(userIdOrSlug: string, page: number = 1) {
    const extraOptions = this.getAuthToken();

    return this.get<IPostsResult>(
      `/users/${userIdOrSlug}/posts/profile??page=${page}&limit=10&order[createdAt]=DESC`,
      extraOptions
    );
  }

  async getAllSpotlights(limit: number, page: number, searchQuery: string) {
    const extraOptions = this.getAuthToken();
    const searchString = searchQuery ? `&search=${encodeURIComponent(searchQuery)}` : '';

    return this.get<IPostSpotlightResult>(
      `/spotlight-posts?page=${page}&limit=${limit}&order[createdAt]=DESC${searchString}`,
      extraOptions
    );
  }

  async getUserSpotlights(
    userId: string,
    limit: number,
    page: number,
    searchQuery: string
  ) {
    const extraOptions = this.getAuthToken();
    const searchString = searchQuery ? `&search=${encodeURIComponent(searchQuery)}` : '';

    return this.get<IPostSpotlightResult>(
      `/users/${userId}/spotlight-posts?page=${page}&limit=${limit}&order[createdAt]=DESC${searchString}`,
      extraOptions
    );
  }

  async getRecommendedEvents(page: number = 1, limit: number = 5) {
    const extraOptions = this.getAuthToken();

    return this.get<RecommendedEventsResult>(
      `/events/recommend?page=${page}&limit=${limit}`,
      extraOptions
    );
  }

  async getCompanyUpcomingEvents(companyId: string, page: number = 1, limit: number = 10) {
    const extraOptions = this.getAuthToken();

    return this.get<RecommendedEventsResult>(
      // `/events?param=1&page=${page}&limit=${limit}&where[stage]=not-live&where[status]=active`,
      `/events/company?where[status]=active&page=${page}&limit=${limit}&companyId=${companyId}&where[isHosting]=1&where[isSponsoring]=1&order[createdAt]=DESC`,
      extraOptions
    );
  }

  async getConnectStatuses(userIds: string[], limit: number = 5) {
    const extraOptions = this.getAuthToken();

    const ids = userIds.join(',');
    return this.get<IConnectStatusResult>(
      `/users/connect-status?page=1&limit=${encodeURIComponent(
        limit
      )}&userIds=${encodeURIComponent(ids)}`,
      extraOptions
    );
  }

  async navBarSearch(searchEntry: string) {
    const extraOptions = this.getAuthToken();

    return this.get<SearchBarResult>(
      `/search?limit=5&search=${encodeURIComponent(searchEntry)}`,
      extraOptions
    );
  }

  async searchUser(searchString: string) {
    const extraOptions = this.getAuthToken();

    return this.get<UserSearchResult>(
      `/search/user?page=1&limit=10&search=${encodeURIComponent(searchString)}&order[firstName]=ASC&order[lastName]=ASC`,
      extraOptions
    );
  }

  async getNotifications(page: number) {
    const extraOptions = this.getAuthToken();

    const result = await this.get<INotificationsResult>(
      `/user/notifications?page=${page}&limit=15&order[createdAt]=DESC`,
      extraOptions
    );
    return result;
  }

  async seenAllNotifications() {
    const extraOptions = this.getAuthToken();
    const result = await this.post<IBaseAPIPostMethodResult>(
      '/user/notifications/seen-all',
      {},
      extraOptions
    );

    return result;
  }

  async updateAgendaNotifications(data: any) {
    const extraOptions = this.getAuthToken();

    return this.patch<any>('/notification-settings', data, extraOptions);
  }

  async sendConnectionRequest(data: any) {
    const extraOptions = this.getAuthToken();

    return this.post<any>('/users/me/connection-requests', data, extraOptions);
  }

  async removeConnection(connectionId: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<any>(
      `/users/me/connections/${connectionId}`,
      extraOptions
    );
  }

  async withDrawConnection(connectionId: string) {
    const extraOptions = this.getAuthToken();

    return this.delete<any>(
      `/users/me/connection-withdraw/${connectionId}`,
      extraOptions
    );
  }

  async blockConnection(userId: string) {
    const extraOptions = this.getAuthToken();

    return this.post<any>(`/users/${userId}/block`, {}, extraOptions);
  }

  async getSearchUserResults(
    searchEntry: string,
    limit: number = 5,
    page: number = 1
  ) {
    const extraOptions = this.getAuthToken();
    // const url = `/search/user?page=${page}&limit=${limit}&search=${searchEntry}&order[firstName]=ASC&order[lastName]=ASC`;
    const url = `/search/user?page=${page}&limit=${limit}&search=${encodeURIComponent(searchEntry)}`;
    return this.get<UserSearchResult>(url, extraOptions);
  }

  async getFilteredUserResults(page: string, limit: string, search?: string) {
    const extraOptions = this.getAuthToken();
    const url = `/search/user?page=${page}&limit=${limit}&search=${search ? encodeURIComponent(search) : ''}`;

    return this.get<UserSearchResult>(url, extraOptions);
  }

  async getConnections(
    connectionUserId: string,
    page: number = 1,
    limit: number = 1000
  ) {
    const extraOptions = this.getAuthToken();
    const url = `/search/user?where[searchInConnection]=1&where[connectionUserId]=${connectionUserId}&page=${page}&limit=${limit}`;

    return this.get<UserSearchResult>(url, extraOptions);
  }

  async getConnectionsByCompany(
    companyId: string,
    page: number = 1,
    limit: number = 1000
  ) {
    const extraOptions = this.getAuthToken();
    const url = `/search/user?where[companyId]=${companyId}&page=${page}&limit=${limit}`;

    return this.get<UserSearchResult>(url, extraOptions);
  }

  async getProfielMatchResults(id: string) {
    const extraOptions = this.getAuthToken();
    const url = `/users/${id}/matched-compatibility-scores`;

    return this.get<any>(url, extraOptions);
  }

  async getMatchedOfferingResults(id: string) {
    const extraOptions = this.getAuthToken();
    const url = `/users/${id}/matched-offerings`;

    return this.get<any>(url, extraOptions);
  }

  async getMatchedInterestResults(id: string) {
    const extraOptions = this.getAuthToken();
    const url = `/users/${id}/matched-interests`;

    return this.get<any>(url, extraOptions);
  }

  async getMatchedConnectionResults(id: string) {
    const extraOptions = this.getAuthToken();
    const url = `/users/${id}/matched-connections`;

    return this.get<any>(url, extraOptions);
  }

  async getMatchedEventResults(id: string) {
    const extraOptions = this.getAuthToken();
    const url = `/users/${id}/matched-events`;

    return this.get<any>(url, extraOptions);
  }

  async getMatchedCompaniesResults(id: string) {
    const extraOptions = this.getAuthToken();
    const url = `/users/${id}/matched-companies`;

    return this.get<any>(url, extraOptions);
  }

  async getProfileByEmail(email: string) {
    return this.get<IUserProfileResult>(`/auth/profile/${email}`);
  }

  async getMatchedInterestEventResults(id: string, page:number, limit: number) {
    const extraOptions = this.getAuthToken();
    const url = `/events/${id}/user-audience-with-match?page=${page}&limit=${limit}`;

    return this.get<any>(url, extraOptions);
  }

  async matchAudience(data: any, eventId: string) {
    const extraOptions = this.getAuthToken();

    return this.post<any>(
      `/events/${eventId}/user-audience`,
      data,
      extraOptions
    );
  }
}
