import BaseService from './BaseService';

export class QrService extends BaseService {
  async getQrCode(eventId: string, userId: string) {
    const extraOptions = this.getAuthToken();

    return this.get<any>(
      `../../../qr-code?eventId=${eventId}&userId=${userId}`,
      extraOptions
    );
  }
}
