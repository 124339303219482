/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import arrowDownIcon from '../../../../assets/images/website-event/arrow-down.svg';
import connectionIcon from '../../../../assets/images/event-landing-pre-event/connection.svg';
import { POST_ACTIONS } from '../../../../constants';
import { IEditPost } from '../../../../models/post/edit-post';
import { IUserProfile } from '../../../../models/user-profile';
import { createUpcomingEventPost } from '../../../../store/events/upcoming-event-create-post/actions';

import { imageUrlPrefix } from '../../../../utils';
import { Avatar } from '../../../../shared-components/V2';
import { PrivacyDropDown } from '../../../../components/posts/components/PrivacyOptions';
import { useCurrentUser, useUserDetails } from '../../../../utils/hooks';
import { Logo } from '../../../../shared-components/V2/Logo';
import PostContent from '../../../../components/posts/components/PostContent';

interface IShareCompany {
  company: any;
  user: IUserProfile;
  onCloseShare?: () => void;
}
const ShareCompany = ({ company, user, onCloseShare }: IShareCompany) => {
  const [isDisplayName, hasDisplayName] = useState(false);
  const [displayPrivacyStatusMenu, setDisplayPrivacyStatusMenu] = useState(false);
  const [privacyOfPost, setPrivacyOfPost] = useState<string>('Connections');
  const [iconPrivacyOfPost, setIconPrivacyOfPost] = useState<string>(connectionIcon);
  const [name, setName] = useState<string>(user.fullName);
  const [selectedCompanyId, setSelectedCompanyId] = useState(company?.id ?? '');
  const [privacyOptionsFor, setPrivacyOptionsFor] = useState<
    'User' | 'Company'
  >('User');

  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm<IEditPost>();

  // eslint-disable-next-line no-shadow
  const handleNameOfPost = (name: string, isCompany: boolean) => {
    setName(name);
    hasDisplayName(!isDisplayName);
    if (isCompany) {
      setPrivacyOfPost('Followers');
      setIconPrivacyOfPost(connectionIcon);
    } else {
      setPrivacyOfPost('Connections');
      setIconPrivacyOfPost(connectionIcon);
    }
  };

  const handlePrivacyOfPost = (privacy: string, icon: string) => {
    setPrivacyOfPost(privacy);
    setIconPrivacyOfPost(icon);
    setDisplayPrivacyStatusMenu(!displayPrivacyStatusMenu);
  };

  const handleSharePost = (data: IEditPost) => {
    const dataToSubmit = {
      ...data,
      format: 'shared_company',
      location: 'profile',
      privacy: privacyOfPost.toLowerCase() === 'only me' ? 'only-me' : privacyOfPost.toLowerCase(),
      source: selectedCompanyId,
      wallId: user.id
    };

    dispatch(createUpcomingEventPost(dataToSubmit));
    toast.success(POST_ACTIONS.SHARE_POST_SUCCESS);
    onCloseShare!();
  };

  const PostedBy = () => {
    const user = useCurrentUser();
    const userDetails = useUserDetails(user);

    type PostedByOptionTypes = {
      id: string;
      name: string;
      isCompany: boolean;
    };

    const dropdownOptions: PostedByOptionTypes[] = [];
    dropdownOptions.push({
      id: user.id,
      name: user.fullName,
      isCompany: false,
    });

    userDetails.companyAdmin?.map(company => dropdownOptions.push({
      id: company.id,
      name: company.name,
      isCompany: true,
    }));

    return (
      <div className="bg-white dropdown-box w-auto px-[5px] py-[5px]">
        <ul className="text-base">
          {dropdownOptions.map((option, index) => (
            <li
              className="flex items-center justify-left text-[12px] leading-[18px]  text-[#172746] hover:bg-[#EDF2F5] h-[30px] px-2 rounded-[5px] cursor-pointer font-normal tracking-wide"
              key={`option-${index}`}
              onClick={() => {
                handleNameOfPost(option.name, option.isCompany);
                if (option.isCompany) {
                  setSelectedCompanyId(option.id);
                  setPrivacyOptionsFor('Company');
                } else {
                  setPrivacyOptionsFor('User');
                }
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="text-[#0B1221] font-medium text-[18px] leading-[26px] border-b-1 border-[#CDDCF2] pb-[9px] mx-[18px]">Share page</div>
      <div className="border-b-[0.5px] border-[#C5C5C5]" />
      <form onSubmit={handleSubmit(handleSharePost)}>
        <div className="flex flex-col overflow-y-auto">
          <div className="flex flex-row items-center py-2.5 pb-0 px-[20px]">
            <div className="mr-[12.5px] !w-[40px] !h-[40px]">
              <Avatar
                src={`${imageUrlPrefix}/${user.avatar}`}
                size="MEDIUM"
                className="!w-[40px] !h-[40px] !rounded-[15px]"
              />
            </div>
            <div className="mr-2 relative h-[31px]">
              <button
                type="button"
                // eslint-disable-next-line max-len
                className={classNames('menu focus:outline-none focus:shadow-solid flex flex-row border-[#203C6E] border-1 h-[31px] px-[20px] hover:bg-[#DFE5EB] justify-between items-center text-center rounded-[10px] text-[12px] leading-[18px] text-[#172746] font-semibold', {
                  'bg-[#DFE5EB]': isDisplayName,
                  'bg-white': !isDisplayName,
                })}
                onClick={() => hasDisplayName(!isDisplayName)}
              >
                <span className="max-w-[185px] line-clamp-1">{name}</span>
                <img src={arrowDownIcon} className="ml-2" alt="" />
              </button>
              {isDisplayName && <PostedBy />}
            </div>
            <div className="mt-[2]">
              <PrivacyDropDown
                displayPrivacyStatusMenu={displayPrivacyStatusMenu}
                setDisplayPrivacyStatusMenu={setDisplayPrivacyStatusMenu}
                iconPrivacyOfPost={iconPrivacyOfPost}
                privacyOfPost={privacyOfPost}
                privacyOptionsFor={privacyOptionsFor}
                handlePrivacyOfPost={handlePrivacyOfPost}
              />
            </div>
          </div>
          <div className="mt-[15px] px-[20px]">
            <textarea
              className="w-full text-[15px] leading-[17px] font-light outline-none placeholder:text-[#020202]"
              placeholder="Add a title/description to your post"
              {...register('content')}
            />
          </div>
          {/* TODO: Move this code to shared components. Design is duplicated in Home, Events and share post. */}
          <div className="mt-2 block px-[20px]">
            <div className="border-solid border-[0.5px] border-[#989898] rounded-10">
              <div className="flex flex-col">
                <div className="flex items-center px-[17px] pt-[17px] mb-[8px]">
                  <div>
                    <Link to={company?.url}>
                      {company?.logo && (
                        <Logo
                          alt={company?.logo}
                          className={classNames(
                            'object-contain rounded-[5px] w-[49px] min-w-[49px] max-w-[49px] h-[49px] min-h-[49px] max-h-[49px]',
                            {
                              'bg-[#edf2f5]': company?.logo?.includes('null') || !company?.logo,
                            }
                          )}
                          src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${company?.logo}`}
                          defaultLogo="company"
                        />
                      )}
                    </Link>
                  </div>
                  <div className="flex flex-col ml-2">
                    <div className="capitalize leading-[16px]">
                      <span className="text-[13px] font-[600] text-[#172746] leading-[16px] flex hover:underline">{company?.name}</span>
                    </div>
                    <div className="flex leading-[17px]">
                      <span className="text-[11px] font-[400] text-[#172746] leading-[17px]">{company?.industry?.name}</span>
                    </div>
                    <div className="flex leading-[17px]">
                      <span className="text-[11px] font-[300] text-[#3C3C3C] leading-[14px]">
                        {
                          company.headquarter
                          // && format(
                          //   parseISO(company.dateAndTime),
                          //   "dd MMM yyyy 'at' hh:mm aaaa"
                          // )
                        }
                      </span>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="text-h3 font-light text-blue-4">
                    <PostContent content={company?.tagline} />
                  </div>
                </div>
                {company.cover && (
                  <img
                    className="w-full pt-2 max-h-[24rem] min-h-[250px] object-cover rounded-10"
                    src={`${imageUrlPrefix}/${company.cover}`}
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>

          <div className="text-right mt-2 px-[20px]">
            <div className="border-b-[0.5px] border-[#C5C5C5] mb-2.5" />
            <button
              className="bg-[#EDF2F5] text-[#203C6E] text-sm font-semibold w-full h-[30px] rounded-20 hover:bg-gray-2 tracking-wide"
              type="submit"
            >
              Share post
            </button>
          </div>
        </div>

      </form>
    </div>
  );
};

export default ShareCompany;
