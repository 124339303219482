import { ButtonWithTextAndImage } from '../../../shared-components/V2';
import polyicon from '../../../assets/images/polygon-icon.svg';
import bannerImage from '../../../assets/images/feature/features-banner@2x.png';

export const Banner = () => (
  <div className="w-full">
    <div className="block md:flex">
      <div className="w-screen">
        <img src={bannerImage} alt="" className="object-cover w-full" />
        <div className="container mx-auto">
          <div className="2xl:ml-5 lg:-mt-419 flex justify-center lg:justify-end mt-10 lg::mr-10 lg:pl-4 lg:pt-4">
            <div className="block">
              <div className="md:flex md:flex-row justify-end lg:mr-16 lg:pr-8">
                <div className="mt-4 lg:text-xl3 font-extralight text-xl text-blue-3">
                  Discover how Panelist events
                  {' '}
                  <br />
                  can give you the competitive
                  {' '}
                  <br />
                  advantage
                </div>
              </div>
              <div className="mt-6">
                <ButtonWithTextAndImage
                  buttonClasses="bg-transparent hover:bg-gray-7 active:bg-gray-7 border-1 border-blue-3 w-242 h-43 rounded-full"
                  imageUrl={polyicon}
                  text="Create an event"
                  textClasses="font-extralight text-sm3 ml-1 pl-1"
                />
              </div>
              <div className="mt-16 pt-1 lg:text-lg font-extralight text-xl text-blue-3">
                Join the world’s business events community
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Banner;
