/* eslint-disable max-len */
/* eslint-disable no-console */

import AgendaAccordion from '../../components/agenda-accordion';
import { PageTitle } from '../../components/PageTitle';

import { RegisterSectionProps } from '../../types';

export const ProgramPage = ({ onRegister }: RegisterSectionProps) => (
  <div className="mt-[105px] px-[15px] md:px-0">
    <PageTitle title="Agenda" description="Your industry, profession, and role are continually evolving. To stay ahead, you need to uncover new trends and address challenges head-on. Factor’s CIO Day 2024 conference offers actionable insights, cutting-edge technologies, and valuable connections to help you navigate disruptions, leverage AI, and lead your organization with confidence." />
    <AgendaAccordion onRegister={onRegister} />
  </div>

);
