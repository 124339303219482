import React, { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "../../";
import { CardProfileConnect } from "../CardProfileConnect";
import { ICardProfileConnectProps } from "../CardProfileConnect/CardProfileConnect";
import ArrowRightIcon from "../../../assets/images/arrow-right.svg";
import ArrowLeftIcon from "../../../assets/images/arrow-left.svg";
import classNames from "classnames";
import { getCompanyName, getCompanySlug } from "../../../utils";
export interface ICardContainerProps {
  title: string;
  cards: ICardProfileConnectProps[];
  className: string;
  onClick?: Function;
  shouldDisplayCard?: boolean;
  loadMorePeople?: Function
}

const sideScroll = (
  element: HTMLDivElement,
  speed: number,
  distance: number,
  step: number
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

export const CardContainer: FC<ICardContainerProps> = ({
  title,
  cards,
  className = "",
  onClick = () => { },
  shouldDisplayCard,
  loadMorePeople = () => {},
}) => {
  const [showArrows, setShowArrows] = useState(false);
  const contentWrapper = useRef(null);

  const navigate = useNavigate();
  const onUserClick = (url: string | undefined) => {
    navigate(`${url}`);
  };

  const onCompanyClick = (url: string | undefined) => {
    if (!url) return;
    navigate(`${url}`);
  }

  if (!cards?.length) return null;

  return (
    <div
      className={classNames("w-full h-full pt-[0px] relative", {
        [`${className}`]: className,
        "bg-white card-shadow px-[20px]": shouldDisplayCard !== true,
      })}
      onMouseEnter={() => setShowArrows(true)}
      onMouseLeave={() => setShowArrows(false)}
    >
      <div className="flex flex-col">
        {/* <div data-test-id="connect-suggestions-container">
          <Typography variant="ContainerHeading" text={title} />
        </div> */}
        <div className="flex flex-row">
          <div
            className="cursor-pointer"
            aria-label="left-arrow-target"
            onClick={() => sideScroll(contentWrapper.current!, 25, 200, -10)}
          >
            <img
              className={classNames("absolute -left-[18px] bottom-[50px]", {
                hidden: !showArrows,
              })}
              src={ArrowLeftIcon}
            />
          </div>
          <div
            className="flex flex-row overflow-x-auto no-scrollbar pt-[0px] pb-[12px]"
            ref={contentWrapper}
          >
            {cards &&
              cards.length > 0 &&
              cards.map((card, i) => (
                <div key={`card-${i}`}>
                  <CardProfileConnect
                    id={card.id}
                    firstName={card.firstName}
                    lastName={card.lastName}
                    slug={card.slug}
                    companySlug={getCompanySlug(card)}
                    avatar={card.avatar}
                    cover={card.cover}
                    jobTitle={card.jobTitle}
                    location={getCompanyName(card)}
                    onClickConnect={onClick!}
                    onCompanyClick={onCompanyClick}
                    mutualConnections={card?.mutualConnections}
                    onUserClick={onUserClick}
                    className="w-[177px] h-[210px] p-[5px] mr-[12px] border-1 border-[#dee1e3]"
                  />
                </div>
              ))}
          </div>
          <div
            className="cursor-pointer"
            aria-label="right-arrow-target"
            onClick={() => sideScroll(contentWrapper.current!, 25, 190, 10)}
          >
            <img
              className={classNames("absolute bottom-[50px] -right-[18px]", {
                hidden: !showArrows,
              })}
              src={ArrowRightIcon}
              // onClick={() => loadMorePeople()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContainer;
