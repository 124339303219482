import classNames from 'classnames';
import { UseFormRegister } from 'react-hook-form';
import { SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';

import { IReactSelectOption } from '../../models/react-select-option';

interface CustomSelectorProps {
  register: UseFormRegister<any>;
  onChange?: Function;
  selectedStateToStore?: IReactSelectOption | undefined;
  placeholder?: string;
  options?: any;
  customStyle?: string;
  caption?: string;
  labelclass?: string;
  fieldName?: string;
  errorMessage?: string;
  onSearch?: Function;
  isSearchable?: boolean;
  isWebsiteDropdown?: boolean;
}

const customStyles = {
  option: (provided: any, state: { isSelected: any; }) => ({
    ...provided,
    // borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: '100%',
    //border: '1px solid #8f8f8f',
    height: '35px',
    borderRadius: '10px',
    display: 'flex',
  }),
  singleValue: (provided: any, state: { isDisabled: any; }) => {
    const opacity = state.isDisabled ? 1 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

export const CustomSelectorComp = ({
  register,
  onChange,
  selectedStateToStore,
  placeholder,
  options,
  customStyle,
  caption,
  labelclass,
  fieldName,
  errorMessage,
  onSearch,
  isSearchable,
  isWebsiteDropdown,
}: CustomSelectorProps) => {

  const onChangeLocal = (event: SingleValue<IReactSelectOption>) => {
    onChange && onChange(event);
  };

  const formatCreateLabel = (inputValue: any) => {
    return `${inputValue}`;
  }

  return (
    <div

      className={classNames('relative bg-white rounded-[8px]', {
        "!bg-transparent border-1 border-[#8f8f8f]": isWebsiteDropdown,
      })}
    >
      <span>
        <label
          className={classNames({
            [`${labelclass}`]: labelclass,
          })}
        >
          {caption}
        </label>
      </span>
      <Creatable
        id='yearSelector'
        classNamePrefix="custom-class"
        className={classNames('dropdown-style', {
          [`${customStyle}`]: customStyle,
        })}
        placeholder={placeholder}
        formatCreateLabel={formatCreateLabel}
        options={options}
        isClearable={false}
        isSearchable={isSearchable}
        openMenuOnClick={true}
        value={selectedStateToStore}
        onInputChange={(a) => onSearch && onSearch(a)}
        styles={customStyles}
        {...register(fieldName!, {
          required: { value: true, message: errorMessage ? errorMessage : "" }
        })}
        onChange={(e) => onChangeLocal(e)}
      />
    </div>

  );
};
