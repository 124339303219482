import React from "react";
import { IUserProfile } from "src/models/user-profile";
import { ParseContent } from "src/utils/parse-html";
// import { useMediaQuery } from '@mui/material';

export type NavbarProps = {
  pageItems?: any;
  lastSurveyorRegistrationPage?: boolean;
  isSuveryorRegSkipable?: boolean;
  handleSkip?: () => void;
  stepName?: string;
  user?: IUserProfile | undefined;
  onLogout?: () => void;
  submitPage?: () => void;
  showBackButton: boolean;
  showNextButton: boolean;
  requiredFieldsFilled: boolean | undefined;
  surveySubPage?: string | undefined;
  bgColor: string;
  currentPage?: string;
  step: number;
  setOpenForm: (value: boolean) => void;
  onPreviousStep?: () => void;
  onNextStep: () => void;
  isLoading: boolean;
  stepHeader?: any
};

// export const tabScreen = useMediaQuery('(min-width:900px)');
// export const tabScreen = true;

export const rightPaddingForMobile: number = 5;

export const getFieldLabel = (required: boolean, fieldName: string) => {
  return (
    <ParseContent content={fieldName} isRequired={required} className="list-style-bullets"/>
  );
};
