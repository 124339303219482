import { useState } from "react"

export const CreateMenuSvgIcon = () => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const colorBlue = '#3062B5';
    const defaultColor = '#7283A4';
    const fillColor = isHover ? colorBlue : defaultColor;

    return <svg className="cursor-pointer" id="Component_309_2" data-name="Component 309 – 2" xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27"
    onMouseEnter={() => setIsHover(true)}
    onMouseLeave={() => setIsHover(false)}
  >
    <rect id="Rectangle_7709" data-name="Rectangle 7709" width="7" height="7" rx="2" fill={fillColor}/>
    <rect id="Rectangle_7709-2" data-name="Rectangle 7709" width="7" height="7" rx="2" transform="translate(10)" fill={fillColor}/>
    <rect id="Rectangle_7709-3" data-name="Rectangle 7709" width="7" height="7" rx="2" transform="translate(20)" fill={fillColor}/>
    <rect id="Rectangle_7709-4" data-name="Rectangle 7709" width="7" height="7" rx="2" transform="translate(0 10)" fill={fillColor}/>
    <rect id="Rectangle_7709-5" data-name="Rectangle 7709" width="7" height="7" rx="2" transform="translate(10 10)" fill={fillColor}/>
    <rect id="Rectangle_7709-6" data-name="Rectangle 7709" width="7" height="7" rx="2" transform="translate(20 10)" fill={fillColor}/>
    <rect id="Rectangle_7709-7" data-name="Rectangle 7709" width="7" height="7" rx="2" transform="translate(0 20)" fill={fillColor}/>
    <rect id="Rectangle_7709-8" data-name="Rectangle 7709" width="7" height="7" rx="2" transform="translate(10 20)" fill={fillColor}/>
    <rect id="Rectangle_7709-9" data-name="Rectangle 7709" width="7" height="7" rx="2" transform="translate(20 20)" fill={fillColor}/>
  </svg>
};
