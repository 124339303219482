import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ICompanyEmployeesResult } from '../../../models/company/CompanyEmployeesResult';
import { ICompanyEmployeesState } from '../../../models/company/CompanyEmployeesState';
import { CompanyService } from '../../../services';
import { CompanyEmployeesActionTypes } from './types';

export interface ICompanyEmployeesLoadingAction {
  type: CompanyEmployeesActionTypes.COMPANY_EMPLOYEES_LOADING;
  loading: boolean;
}

export interface ICompanyEmployeesSuccessAction {
  type: CompanyEmployeesActionTypes.COMPANY_EMPLOYEES_SUCCESS;
  payload: ICompanyEmployeesResult;
}

export interface ICompanyEmployeesErrorAction {
  type: CompanyEmployeesActionTypes.COMPANY_EMPLOYEES_ERROR;
  errorMessage: string;
}

export type CompanyEmployeesActions =
  | ICompanyEmployeesLoadingAction
  | ICompanyEmployeesSuccessAction
  | ICompanyEmployeesErrorAction;

export const getCompanyEmployees: ActionCreator<
  ThunkAction<Promise<any>, ICompanyEmployeesState, null, ICompanyEmployeesSuccessAction>
> = (companyIdOrSlug: string) => {
  return async (dispatch: Dispatch) => {
    const companyService: CompanyService = new CompanyService();

    try {
      const result = await companyService.getCompanyEmployees(companyIdOrSlug);

      dispatch({
        type: CompanyEmployeesActionTypes.COMPANY_EMPLOYEES_SUCCESS,
        payload: result
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: CompanyEmployeesActionTypes.COMPANY_EMPLOYEES_ERROR,
        errorMessage: error.message
      });
    } finally {
      dispatch({
        type: CompanyEmployeesActionTypes.COMPANY_EMPLOYEES_LOADING,
        loading: false
      });
    }
  };
};

export const loadCompanyProfilePreview: ActionCreator<
  ThunkAction<any, ICompanyEmployeesState, null, ICompanyEmployeesLoadingAction>
> = (shouldLoad: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: CompanyEmployeesActionTypes.COMPANY_EMPLOYEES_LOADING,
    loading: shouldLoad
  });
