import {
  useEffect,
  useState,
} from 'react';

import {
  format,
  parseISO,
} from 'date-fns';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Divider from '../../../assets/images/event-landing-pre-event/divider.svg';
import ClockIcon from '../../../assets/images/event-landing-pre-event/Icon-feather-clock.svg';
import MailIcon from '../../../assets/images/event-landing-pre-event/Icon-feather-mail.svg';
import PersonIcon from '../../../assets/images/event-landing-pre-event/icon-feather-person.svg';
import ArrowDownWhite from '../../../assets/images/event-landing-pre-event/triangle-down-white.svg';
import AlertModal from '../../../components/alert-modal/alert-modal';
import { EventCoverAndLogo } from '../../../components/event-cover-and-logo';
import { IEventHeader } from '../../../models/event/event-header';
import { Button, ButtonWithTextAndImage } from '../../../shared-components/V2';
import { IAppState } from '../../../store';
import { getJobFunction } from '../../../store/company/job-functions/actions';
import { getJobTitles } from '../../../store/company/job-titles/actions';
import { getEventInvitees } from '../../../store/event-invitee';
import { getUnInvitedConnections } from '../../../store/events/invitable-users/actions';
import { getUpcomingEvent } from '../../../store/events/upcoming-event/actions';
import { openMessageWindow } from '../../../store/newMessage';
import { getEventRegions } from '../../../store/regions/actions';
import { useCurrentUser } from '../../../utils/hooks';
import { ParseContent } from '../../../utils/parse-html';
import { CalendarList } from '../../event/registration/event-components/calendar-list';
import EventDetails from '../../event/registration/event-signup/event-details';
import { InviteConnections } from '../../events/event-tab/components/InviteConnections';
import EventTickets from '../event-tickets';
import EventDropdown from './event-dropdown';
import DropDownMenu, { OptionType } from './event-header-dropdown';
import { EventsService } from '../../../services/events';
import { resetCart } from '../../../store/ticket';
import FinalMessageContent from '../../event/registration/event-components/final-message-content';
import DeleteModalPopup from './DeleteModalPopup';
import classNames from 'classnames';
import { getCompanyName } from 'src/utils';
import { formatFullDateTime } from 'src/utils/date-timezone';


export const EventHeader = (props: IEventHeader) => {
  const navigate = useNavigate();
  const [logoutUser, setLogoutUser] = useState(false);
  const [isInviteConnection, setIsInviteConnection] = useState(false);
  const [page, setPage] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();

  const { upcomingEvent: event, eventTickets } = useSelector(
    (state: IAppState) => state
  );

  const signUpToEvent = useSelector(
    (state: IAppState) => state.signupUser.currentStep
  );
  const user = useCurrentUser();

  const userAttendeestatus = props.attendeeStatus;
  const isCioDaySydneyEvent = event?.value?.data?.event?.id === '839c9658-39db-4595-b9ee-4868036f1fe5';

  const onAlertOpen = () => {
    setOpenAlert(!openAlert);

    dispatch(getUpcomingEvent(props.id));
  };

  const onInviteModal = () => {
    setIsInviteConnection(!isInviteConnection);
  };

  const changePage = (page: number) => {
    setPage(page);
  };

  const joinEvent = () => {
    if (event) {
      new EventsService().getEventsAttendeeStatus(event?.value?.data?.event?.id!).then(res => {
        const eventAttendeeStatus = res.data.audienceStatus;
        if (eventAttendeeStatus !== 'accepted') navigate(`/event/${event?.value?.data?.event?.slug}/discussion`);
        onAlertOpen();
      })
    }
  }

  const onEventRegister = () => {
    if (event) {
      const company = event?.value?.data?.event?.company?.name.split(' ').join('-');
      const slug = event?.value?.data?.event?.slug;
      const queryParams: any = {
        eventFrom: 'register',
      };

      const queryString = Object.keys(queryParams)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');

      const utmUrl = event?.value?.data?.event?.eventUtmUrl?.find((utm) => utm.type === 'panelist')?.url;
      const queryParam = utmUrl ? `${utmUrl}&${queryString}` : `${queryString}`
      const url = `/${company}/${slug}?${queryParam}`;

      window?.open(url, '_blank');
    }
  };

  const RenderButton = () => {
    if (userAttendeestatus === "accepted") {
      return (
        <Link to={`/${getCompanyName(event.value?.data?.event)}/${props.slug}/live`}>
          <ButtonWithTextAndImage
            buttonClasses="bg-[#0049EF] hover:bg-[#2e6eff] active:bg-blue-3  md:mx-2 my-1 w-110 h-30 rounded-[5px]"
            imageUrl=""
            text="Live Event"
            textClasses="text-white text-sm1 font-semibold -ml-2 tracking-wide "
          />
        </Link>
      );
    } else if (userAttendeestatus === "pending") {
      return (
        <ButtonWithTextAndImage
          buttonClasses="bg-gray-1 hover:bg-gray-7 active:bg-gray-7  mx-2 my-1 w-[220px] h-30 rounded-[5px]"
          imageUrl=""
          text="Registration under review"
          textClasses="text-blue-2 text-sm1 font-semibold -ml-2 tracking-wide "
        />
      );
    } else {
      return (
        <div
          onClick={() => onEventRegister()}
        >
          <ButtonWithTextAndImage
            buttonClasses="bg-[#0049EF] hover:bg-[#2e6eff] active:bg-blue-3 ml-0 mx-2 my-1 w-110 h-30 rounded-[5px]"
            imageUrl=""
            text="Register"
            textClasses="text-white text-sm1 font-semibold -ml-2 tracking-wide "
          />
        </div>
      );
    }
  };

  // const isModalOpen = useSelector((state: IAppState) => state.isModalOpen);

  // useEffect(() => {
  //   !!props.id && dispatch(getUnInvitedConnections(props.id, ""));
  //   dispatch(getJobFunction());
  //   dispatch(getJobTitles(""));
  //   dispatch(getEventRegions());
  //   !!props?.id && dispatch(getEventInvitees(props?.id));
  // }, [dispatch, props.id, isModalOpen]);

  const removeRegistration = () => {
    // setDeleteModal(true);
    if (props.id) {
      const eventService = new EventsService();
      eventService.removeAttendee(props.id).then(() => {
        // navigate(`/event/${event.value?.data?.event?.slug}/discussion`)
        setDeleteModal(false);
        dispatch(getUpcomingEvent(props.id));
      })
    }
  };

  const showModal = () => {
    setDeleteModal(true);
  }

  const orderConfirmation = () => {
    //ignore temporary
    setShowConfirmation(true);
  };
  const contactHost = () => {
    dispatch(
      openMessageWindow({
        avatar: event?.value?.data.event.creator.avatar!,
        userDisplayName: `${event?.value?.data.event.creator.firstName} ${event?.value?.data.event.creator.lastName}`,
        userId: event?.value?.data.event.creator.id,
      })
    );
  };

  const optionsToSelect: OptionType[] = [
    // (userAttendeestatus === "accepted" && !props.isTicketed && {
    //   id: 1,
    //   text: "Remove registration",
    //   subtext: "You can remove your registration",
    //   onClickHandler: () => showModal(),
    // }) as OptionType,
    (userAttendeestatus === "accepted" && {
      id: 2,
      text: "Order confirmation",
      subtext: "QR code",
      onClickHandler: () => orderConfirmation(),
    }) as OptionType,
    {
      id: 3,
      text: "Contact host",
      subtext: "Contact host on Panelist",
      onClickHandler: () => contactHost(),
    },
  ].filter(x => x.id);

  return (
    <div className="flex flex-col w-full bg-white create-post-card">
      <div
        className={classNames({
          'h-150': !props?.isLobbyPage
        })}
      >
        <EventCoverAndLogo
          cover={`${props.cover}`}
          logo={`${props.logo}`}
          // isCoverImageOverlayColor={props?.isCoverImageOverlayColor}
          // coverImageOverlayOpacity={props?.coverImageOverlayOpacity}
          // coverImageOverlayColour={props?.coverImageOverlayColour}
          coverClassName="mx-auto bg-cover bg-blue bg-center h-[203px] !object-center !object-cover w-full drop-shadow-none rounded-t-15"
          // logoClassName={classNames("mt-[22px] ml-[22px] w-[92px] h-[92px] rounded-[5px] !left-0 !bottom-[20px]", {
          //   'bg-[#edf2f5]': !props?.logo
          // })}
          logoClassName={classNames("w-[92px] h-[92px] rounded-[5px] !right-[20px] !top-[20px]", {
            'bg-[#edf2f5]': !props?.logo
          })}
          logoStyle="w-[90px] h-[90px] medium-radius"
          eventLogo={`${props.logo}`}
        />
      </div>
      <div className="bg-white rounded-b-15  w-full">
        <div className="px-4 pt-4 pb-[10px] rounded-b-lg">
          <div className="text-left border-b-1 pb-3 pl-1">
            <div className="text-lg4 leading-7 text-[#172746] font-medium">
              {props.companyName}
            </div>
            <div className="flex flex-row">
              <div className="text-md text-[#172746] font-medium mt-[2px]">
                {props.eventName}
              </div>
            </div>
            {props?.eventName && (
              <div className="text-md2 leading-[26px] font-normal text-[#0B1221]">
                <ParseContent content={props.theme} />
              </div>
            )}
            <div className="md:flex flex-row mt-1">
              {/* moving location of date to match design - corry 21/04/2022*/}
              <div className="mt-1">
                <img className="" src={ClockIcon} alt="" />
              </div>
              {isCioDaySydneyEvent ? (
                <div className='flex max-w-[400px] flex-col'>
                  <div className="sm:flex text-sm4 leading-[19px] text-[#203C6E] mt-1 ml-1 font-normal gap-2 sm:mb-0 mb-1">
                    {formatFullDateTime(props.startDateTime, true)}
                    {' '}
                    - Pre Conference Dinner (Register interest for approval)
                  </div>
                  <div className="sm:flex text-sm4 leading-[19px] text-[#203C6E] mt-1 ml-1 font-normal gap-2 sm:mb-0 mb-1">
                    {formatFullDateTime(props.endDateTime, true)}
                    {' '}
                    - Conference Day
                  </div>
                </div>
              ) : (
                <div className="sm:flex text-sm4 leading-[19px] text-[#203C6E] mt-1 ml-1 font-normal gap-2 sm:mb-0 mb-1">
                <div className="">
                  {props.startDateTime &&
                    format(
                      parseISO(props.startDateTime),
                      "d MMMM yyyy • hh:mm a"
                    )}
                </div>

                <div className="">
                  {/* {props.endDateTime &&
                    format(
                      parseISO(props.endDateTime),
                      "d MMMM yyyy • hh:mm a"
                    )} */}

                  {props.endDateTime && props.startDateTime &&
                    format(parseISO(props.endDateTime), 'd MMM yyyy') === format(parseISO(props.startDateTime), 'd MMM yyyy')
                    ? format(
                      parseISO(props.endDateTime),
                      "- hh:mm a"
                    )
                    : null}
                  {props.endDateTime && props.startDateTime &&
                    format(parseISO(props.endDateTime), 'd MMM yyyy') !== format(parseISO(props.startDateTime), 'd MMM yyyy')
                    ? format(
                      parseISO(props.endDateTime),
                      "- d MMMM yyyy • hh:mm a"
                    )
                    : null}
                </div>
              </div>
              )}
              
              <div className="flex hover:underline mt-1">
                <CalendarList title="Add to Calendar" btnClassName="flex items-baseline" titleClass="text-sm4 font-semibold md:ml-3 mr-1 text-[#3c3c3c]" />
              </div>
              
            </div>
            
            <div className="flex flex-row mt-1 text-sm1 leading-[19px] font-normal text-[#393939]">
              <img className="" src={PersonIcon} alt=""></img>
              <div className="ml-[2px] capitalize"><div className="ml-1 capitalize">{props.format === 'live' ? 'In-Person' : props.format}</div></div>
              {props.format === "hybrid" && <img className="ml-1" src={Divider} alt=""></img>}
              <div className="ml-1 capitalize">{props.location}</div>
            </div>
            <div className="flex flex-row mt-1 text-sm1 leading-[19px] font-normal text-[#393939]">
              <img className="capitalize" src={MailIcon} alt=""></img>
              <div className="ml-[6px] capitalize">{props.type}</div>
            </div>
          </div>
          <div className="flex row justify-end md:items-center items-start mt-[10px]">
            <div
              className={classNames("w-[calc(100%-38px)] md:flex items-center", {
                [`justify-start`]: user && signUpToEvent === 0
              })}
            >
              {
                (userAttendeestatus == "accepted" || userAttendeestatus === "pending") && (
                  <DropDownMenu
                    options={optionsToSelect}
                    containerClasses="flex md:justify-center items-center relative"
                    menuCardClasses="w-240 absolute top-10 opacity-100 bg-white -ml-48 shadow-xs2 rounded-lg text-left px-3 py-2 z-30"
                    buttonStyles="flex justify-center bg-[#0049EF] hover:bg-[#2e6eff] active:bg-blue-3 rounded-[5px] w-169 h-30  py-1.5 "
                    buttonTitleStyles="text-white text-sm1 font-semibold tracking-wide"
                    buttonTitle="My registration"
                    buttonIcon={ArrowDownWhite}
                    buttonIconStyles="py-1.5 ml-2"
                    titleAndSubtitleGroup="hover:bg-gray-1 rounded-5 p-2 cursor-pointer"
                    listTextStyles="block text-sm1 text-blue-3"
                    listSubtextStyles="block text-s-10 text-blue-3"
                  />
                )
              }
              <div id="handleJoin">
                <RenderButton />
              </div>
              {showConfirmation &&
                <AlertModal
                  onClose={() => { setShowConfirmation(false) }}
                  isOpen={showConfirmation}
                  className="!w-[858px] h-[576px] signUpScroll overflow-visible mobile-h-full padding-y-0"
                  disableClose={true}
                >
                  <FinalMessageContent eventId={event?.value?.data?.event?.id!} gotoHide />
                </AlertModal>
              }
              {openTicketModal && (props.isTicketed || eventTickets?.value?.length !== 0) ? (
                <>
                  <AlertModal
                    // onClose={() => setOpenTicketModal(false)}
                    onClose={() => { }}
                    isOpen={openTicketModal}
                    className="w-[1194px] rounded-10 bg-[#EDF2F5] py-0"
                    disableClose={true}
                  >
                    <EventTickets
                      eventId={props.id}
                      eventTitle={props.eventName}
                      eventDate={props.startDateTime}
                      tickets={eventTickets?.value}
                      cover={props.cover!}
                      logo={props.logo!}
                      location={props.location}
                      onSubmit={() => setOpenPaymentModal(true)}
                      onCancel={() => {
                        setOpenTicketModal(false)
                        dispatch(resetCart());
                      }}
                    />
                  </AlertModal>
                </>
              ) : (
                openAlert && (
                  <>
                    <div className="absolute mt-10">
                      <AlertModal
                        onClose={onAlertOpen}
                        isOpen={openAlert}
                        className="w-[858px] !p-0 bg-[#EDF2F5] rounded-20"
                      >
                        {(userAttendeestatus === "pending") && <FinalMessageContent gotoHide eventId={event?.value?.data?.event?.id!} />}
                        {userAttendeestatus !== "pending" && <>
                          {user && signUpToEvent === 0 ? (
                            <div className="flex flex-col justify-between bg-[#EDF2F5] rounded-[20px] py-[10px]">
                              <div className="flex items-center justify-between w-[391px] mt-[18px] mb-[19px] mx-auto">
                                <div className="text-[13px] text-[#3C3C3C] font-bold">Your profile</div>
                                <svg xmlns="http:www.w3.org/2000/svg" width="141" height="1" viewBox="0 0 141 1">
                                  <line id="Line_1398" data-name="Line 1398" x2="140" transform="translate(0.5 0.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1" />
                                </svg>
                                <div className="text-[13px] text-[#3C3C3C] font-[500]">Confirmation</div>
                              </div>
                              <div className="text-center block border-b-1 border-[#d7d7d7] pb-2">
                                <div className="text-[16px] leading-[25px] text-[#3C3C3C]">Event details</div>
                                <div className="text-[13px] leading-[17px] text-[#3C3C3C]">This host needs the following details to complete your registration</div>
                                <div className="text-xs pt-3">Logged in as <a className="font-semibold">{user?.email}</a>. <a className="cursor-pointer hover:underline text-[#0326E5] font-semibold" onClick={() => setLogoutUser(true)}>Not you?</a></div>
                              </div>
                              <EventDetails
                                eventId={event?.value?.data?.event?.id}
                                changePage={changePage}
                                backButtonVisible={false}
                                isCloseButton
                                onClose={onAlertOpen}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {user && signUpToEvent === 1 ? <FinalMessageContent gotoHide eventId={event?.value?.data?.event?.id!} /> : ""}
                        </>
                        }
                      </AlertModal>
                    </div>
                  </>
                )
              )}
              <ButtonWithTextAndImage
                buttonClasses="bg-gray-1 hover:bg-gray-7 active:bg-gray-7 w-168 h-30 rounded-[5px] tracking-wide"
                imageUrl=""
                text="Invite connections"
                textClasses="text-blue-2 text-sm1 font-semibold"
                onClick={onInviteModal}
              />
              {isInviteConnection && (
                <AlertModal
                  className="sm:w-[595px] w-full"
                  isOpen={isInviteConnection}
                  onClose={onInviteModal}
                  closeIconClass=" right-[18px] top-[15px] px-0"
                  isCloseIcon
                  children={
                    <InviteConnections
                      eventId={props.id}
                      onCloseModal={onInviteModal}
                    />
                  }
                />
              )}
              {deleteModal && (
                <AlertModal className=" w-[374px] bg-[#EDF2F5] rounded-20 !py-[18px]" isCloseIcon closeIconClass='right-[8px] top-[15px]' isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
                  <DeleteModalPopup
                    title='Leave this event?'
                    subTitle='Are you sure you want to
                  remove your registration for this event?'
                    onCancel={() => setDeleteModal(false)}
                    onOkay={removeRegistration}
                    leftButtonText='Cancel'
                    rightButtonText='Remove'
                  />
                </AlertModal>
              )}
            </div>
            <div className='w-[38px]'>
              <div className="mt-3">
                <EventDropdown event={event?.value?.data.event!} user={user} />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center items-center z-40"></div>
        </div>
      </div>
      {logoutUser &&
        <AlertModal className=" w-[520px] bg-[#EDF2F5] rounded-20 !py-[18px]" isCloseIcon closeIconClass="right-[8px] top-[15px]" isOpen={logoutUser} onClose={() => setLogoutUser(false)}>
          <div className="flex flex-col justify-center items-center">
            <div className="text-[16px] leading-[24px] font-medium  mb-[16px] text-[#0B1221]">Sign out?</div>
            <div className="text-[16px] leading-[24px] font-normal  mb-[18px] text-[#0B1221] text-center">Are you sure you want to logout and register as a new user?</div>
            <div className="flex items-center justify-center gap-2">
              <Button type="button" text="Cancel" className="text-sm1 text-blue-2 font-semibold hover:underline w-24" onClick={() => setLogoutUser(false)} />
              <a className="rounded-10 text-sm w-110 mr-[24px] bg-[#0049EF] text-white font-semibold hover:bg-blue-3 h-8 flex justify-center items-center" href="/logout">Sign out</a>
            </div>
          </div>
        </AlertModal>
      }
    </div>
  );
};
