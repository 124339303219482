import { useEffect, useState } from 'react';
interface ICountDownTimer {
  screenMode: 'small' | 'large' | 'hidden' | undefined;
  startTime: any;
}
export const CountDownTimer = ({ screenMode, startTime }: ICountDownTimer) => {
  const calculateTimeLeft = () => {
    let difference = +new Date(startTime) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <>
      <div
        className="relative bg-black text-white text-center justify-center"
        // style={screenMode === 'small' ? { height: '33.8rem' } : { height: 'fit-content' }}
        >
        <div className="text-center w-full sm:py-[20%] py-8 sm:min-w-fit min-h-[515px] md:block flex items-center justify-center flex-col">
          <h3 className="text-xl font-medium text-white w-full sm:mb-14 mb-6">Going live in:</h3>
          <div className="sm:flex flex-wrap justify-center">
            <div className="text-white mx-4 md:mx-8">
              <p className="md:text-3xl text-[35px] font-medium relative sm:mb-14 mb-1 md:leading-[55px] leading-9">{timeLeft.days}</p>
              <p className="md:text-xl text-[20px] leading-5 font-medium sm:mb-0 md:mb-6 mb-4">Day{timeLeft.days > 1 ? 's' : ''}</p>
            </div>
            <div className="text-white mx-4 md:mx-8">
              <p className="md:text-3xl text-[35px] font-medium relative sm:mb-14 mb-1 md:leading-[55px] leading-9">{timeLeft.hours}</p>
              <p className="md:text-xl text-[20px] leading-5 font-medium sm:mb-0 md:mb-6 mb-4">Hour{timeLeft.hours > 1 ? 's' : ''}</p>
            </div>
            <div className="text-white mx-4 md:mx-8">
              <p className="md:text-3xl text-[35px] font-medium relative sm:mb-14 mb-1 md:leading-[55px] leading-9">{timeLeft.minutes}</p>
              <p className="md:text-xl text-[20px] leading-5 font-medium sm:mb-0 md:mb-6 mb-4">Minute{timeLeft.minutes > 1 ? 's' : ''}</p>
            </div>
            <div className="text-white mx-4 md:mx-8">
              <p className="md:text-3xl text-[35px] font-medium relative sm:mb-14 mb-1 md:leading-[55px] leading-9">{timeLeft.seconds}</p>
              <p className="md:text-xl text-[20px] leading-5 font-medium sm:mb-0 md:mb-6 mb-4">Seconds</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
