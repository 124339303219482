import { Reducer } from 'redux';

import { IPostUserActionState } from '../../../models/post/PostUserActionState';
import { PostUserActions } from './actions';
import { PostUserActionTypes } from './types';

const initialState: IPostUserActionState = {
  message: ''
};

export const PostActionsReducer: Reducer<IPostUserActionState, PostUserActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PostUserActionTypes.IN_PROGRESS: {
      return {
        ...state,
        loading: action.inProgress
      };
    }

    case PostUserActionTypes.UNFOLLOW_POST: {
      return {
        ...state,
        value: action.message
      };
    }

    case PostUserActionTypes.REPORT_POST: {
      return {
        ...state,
        value: action.message,
        reportedPostId: action.reportedPostId
      };
    }

    case PostUserActionTypes.EDIT_POST: {
      return {
        ...state,
        value: action.result
      };
    }

    case PostUserActionTypes.DELETE_POST: {
      return {
        ...state,
        value: action.message
      };
    }

    case PostUserActionTypes.ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
