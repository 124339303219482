import React from "react";

interface ArrowIconProps {
  color?: string; // Optional color prop
  width?: number; // Optional width prop
  height?: number; // Optional height prop
  flipX?: boolean; // Optional prop to flip the icon on the x-axis
}

const ArrowIcon: React.FC<ArrowIconProps> = ({
  color = "#fff", // Default color
  width = 7.463,  // Default width
  height = 16.775, // Default height
  flipX = false,   // Default to no flip
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 7.463 16.775"
    style={{
      transform: flipX ? "scaleX(-1)" : undefined, // Apply flipX if true
      transformOrigin: "center", // Ensure flipping occurs around the center
    }}
  >
    <g transform="translate(1.387 1.387)">
      <line
        y1="7"
        x2="4.688"
        transform="translate(0 7)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        x1="4.688"
        y1="7"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default ArrowIcon;
