/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import ProductListCard from 'src/screens/search-results/productListCard';
import { IUserProfile } from 'src/mui/types/user';
import AlertModal from '../../../components/alert-modal/alert-modal';

// import { ICompanyEmployee } from '../../../models/company/company-employee';
import { CompanyService, UserService } from '../../../services';
import { SearchIcon } from '../../../shared-components/V2/IconSvg';
import { ProductItem } from './product-item';
// import { ConnectionItem } from './connection-item';

export const Products = (props: {
  connections: any[];
  title: string;
  userId?: string;
  companyId?: string;
  totalEmployees?: number;
  companySlug: string;
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userService = new UserService();
  const [filteredConnections, setFilteredConnections] = useState<IUserProfile[]>([]);
  const [search, setSearch] = useState<string>();
  const [showMoreConnections, setShowMoreConnections] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userConnections, setUserConnections] = useState<any[]>();

  const updateUserConnections = async () => {
    if (props.companyId) {
      const companyService: CompanyService = new CompanyService();
      await companyService.getCompanyEmployees(props.companyId).then(({ data }) => setUserConnections(data ?? []));
    } else if (props.userId) {
      await userService.getUserConnections(props.userId).then(({ data }) => setUserConnections(data ?? []));
    }
  };

  useEffect(() => {
    if (props.connections?.length) {
      if (search) {
        const filteredList = props.connections?.filter(c => `${c.companyId} ${c.name}`?.toLowerCase()?.includes(search));
        setFilteredConnections(filteredList);
      } else {
        setFilteredConnections(props.connections);
      }
    }
  }, [props.connections, search]);

  useEffect(() => {
    if (showMoreConnections) {
      // eslint-disable-next-line no-console
      updateUserConnections();
    } else {
      setUserConnections([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMoreConnections]);

  if (!props.connections?.length) return null;

  return (
    <div className="bg-white create-post-card !rounded-[20px] h-[100%]">
      <div className="flex flex-col h-[100%] justify-between">
        <div
          className="flex flex-row items-center justify-between w-full rounded-t-10 px-[30px] pt-[13px]"
        >
          <div className=" text-[20px] font-normal leading-[27px] text-[#000000]">{props.title}</div>
          <div className="font-semibold text-[15px] text-[#000000] leading-[18px] hidden">
            {!!props.connections?.length && props?.totalEmployees! > 500 ? '500+' : props?.totalEmployees!}
          </div>
        </div>
        <div className="grid xl:grid-cols-3 grid-cols-2 gap-x-[20px] gap-y-[10px] pt-[10px] px-[30px] pb-[6px] h-[387px] min-h-[387px] max-h-[387px] overflow-y-auto">
          {props.connections?.slice(0, 9).map((connection, index) => (
            <div className="w-[90px]" key={`connection-${index}`}>
              <ProductItem item={connection} companySlug={props.companySlug} />
            </div>
          ))}
        </div>
        <span className="h-[1px] bg-[#E6E6E6] mb-[6px] w-[calc(100%-24px)] mx-auto" />
        <div
          onClick={() => setShowMoreConnections(true)}
          // eslint-disable-next-line max-len
          className="w-[calc(100%-24px)] mx-auto mb-[13px] flex justify-center text-[13px] text-black bg-[#EDF2F5] rounded-[5px] leading-[17px] cursor-pointer font-semibold tracking-wide py-[9px] hover:underline"
        >
          See all
        </div>
      </div>
      <AlertModal
        isOpen={showMoreConnections}
        onClose={() => {
          setShowMoreConnections(false);
          setSearch('');
        }}
        className="w-576 p-2 container"
      >
        <>
          <div className="text-md px-2 py-1 pb-4">All Products</div>
          <div className="px-2">
            <div className="px-3 py-1.5 flex items-end text-sm rounded-15 focus:outline-none w-[186px] h-[30px] border-1 border-blue-900 font-extralight text-gray-1 bg-gray-1">
              <SearchIcon />
              <input
                name="search"
                className="w-full ml-2 text-sm5 focus:outline-none block font-light text-gray-900 placeholder:text-gray-900 bg-transparent"
                placeholder="Search"
                onChange={e => { setSearch(e.target.value.toLowerCase()); }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="border-b-1 border-gray-300 my-2" />
          <div className="w-full h-420 overflow-auto px-4">
            <ProductListCard
              productList={filteredConnections}
              key="user-connections"
              companySlug={props.companySlug}
            />
          </div>
        </>

      </AlertModal>
    </div>
  );
};

export default Products;
