import { Reducer } from 'redux';

import { OwnExperienceState } from '../../../models/OwnExperienceState';
import { OwnExperienceActions } from './actions';
import { OwnExperienceActionTypes } from './types';

const initialState: OwnExperienceState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const OwnExperienceReducer: Reducer<OwnExperienceState, OwnExperienceActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case OwnExperienceActionTypes.OWN_EXPERIENCE_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case OwnExperienceActionTypes.OWN_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        value: action.payload
      };
    }

    case OwnExperienceActionTypes.OWN_EXPERIENCE_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    case OwnExperienceActionTypes.OWN_EXPERIENCE_CLEANUP: {
      return initialState;
    }

    default:
      return state;
  }
};
