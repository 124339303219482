import { Container, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { Loader } from 'src/shared-components/Loader';
import DefaultSurveyButton from 'src/screens/surveys/DefaultSurveyButton';

export const BoxBannerSurveys = () => {
  const survey: any = useSelector((state: IAppState) => state.website.survey!);

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        maxWidth: '100%',
        p: 0,
        pt: '130px',
        pb: '215px',
        pl: '15px !important',
        pr: '15px !important',
        '@media (min-width: 768px)': { maxWidth: '750px' },
        '@media (min-width: 992px)': { maxWidth: '970px' },
        '@media (max-width: 992px)': { pt: '70px', pb: '50px' },
        '@media (min-width: 1200px)': { maxWidth: '1170px' },
        '@media (min-width: 1280px)': { maxWidth: '1260px' },
        '&:before': {
          content: '""',
          backgroundImage: 'url(https://assets.swoogo.com/uploads/full/3088162-6536bb98259bc.png)',
          position: 'absolute',
          right: 0,
          top: 40,
          maxWidth: '720px',
          maxHeight: '600px',
          width: '100%',
          height: '100%',
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          '@media (max-width: 1200px)': { width: '50%' },
          '@media (max-width: 992px)': { display: 'none' },
        }
      }}
    >
      <Stack
        sx={{
          maxWidth: '585px',
          '@media (max-width: 768px)': {
            alignItems: 'center',
            maxWidth: '100%'
          }
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: '18px',
            mb: '15px',
            color: '#ffffffa6',
            lineHeight: '22px',
          }}
          className="bold-lato-font"
        >
          {survey?.slug}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: '60px',
            mb: '20px',
            color: '#ffffff',
            lineHeight: '72px',
          }}
          className="bold-lato-font"
        >
          {survey?.name}
        </Typography>
        <div className="flex justify-start mt-[0px]">
          {survey ? (
            <DefaultSurveyButton isBanner />
          ) : (
            <Loader className="pt-0" key="loader" />
          )}
        </div>
      </Stack>
    </Container>
  );
};
