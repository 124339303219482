import {
  Box,
  Typography,
  Stack,
  Avatar,
  Button,
  CircularProgress,
  TextField,
  Tabs,
  Tab,
  ButtonGroup,
  Alert,
  IconButton,
} from "@mui/material";
import moment from "moment-timezone";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { dateTimeWithMinutesTz } from "src/mui/assets/data/timezones";
import { getMinifiedImage } from "src/mui/utils/image";
import { SessionMeetingStatus } from "src/mui/types/survey";
import { useSurveyContext } from "src/mui/providers/GeneralContext";
import { useSelector } from "react-redux";
import { IAppState } from "src/store";
import { toast } from "react-toastify";
import NextIcon from "../event/reg-dialog-flow/NextIcon";
import { getBrandingColors } from "../event/live/liveEventStyling";
import defaultAvatar from 'src/assets/images/avatar-default.svg';

type Props = {
  personId: string;
  meetingAttendee: any;
  meeting?: any;
  setMeetingAttendee: (data: any) => void;
  setIsMeetingScheduleModal: (data: boolean) => void;
};

function MeetingRequestList({
  // handleRequestMeeting1,
  personId,
  meetingAttendee,
  meeting,
  setMeetingAttendee,
  setIsMeetingScheduleModal
}: Props, ref: any) {
  const colors = getBrandingColors();
  const { getSessionMeetings, sessionMeetings, requestMeeting } =
    useSurveyContext();
  const handleSetMeetingSlot = () => {
    if (requestMeetingTime) {
      onClickRequestMeeting()
    }

  }

  const handleBackToAttendeesList = () => {
    if (requestMeetingTime) {
      handleReset();
      setShowBackButton(false);
    }
    else {
      setMeetingAttendee({})
      setShowBackButton(false);
    }
  }

  useImperativeHandle(ref, () => ({
    handleSetMeetingSlot,
    handleBackToAttendeesList
  }));

  const event = useSelector(
    (state: IAppState) =>
      state.website.event || state.upcomingEvent?.value?.data?.event
  );

  const [requestMeetingDuration, setRequestMeetingDuration] = useState<number>();

  const [requestMeetingTime, setRequestMeetingTime] = useState("");
  const [setupMeetingFlag, setSetupMeetingFlag] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState<string>("");

  const [personalNote, setPersonalNote] = useState("");
  const [eventSessionId, setEventSessionId] = useState("");
  const [summary, setSummary] = useState("");
  const [meetingData, setMeetingData] = useState<any[]>([]);
  const [activeMeetingDay, setActiveMeetingDay] = useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showBackButton, setShowBackButton] = useState<boolean>(false);

  const groupBy = (array: any[], key: string) =>
    array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  const requestMeetingApi = async (data: any) => {
    setIsLoading(true);
    setErrorOccurred("requestMeeting");
    const submitMeeting = async () => requestMeeting(data);
    try {
      await submitMeeting();
      setErrorOccurred("");
      setRequestMeetingTime("");
      setRequestMeetingDuration(undefined);
      setSetupMeetingFlag(true);
      setIsLoading(false);
      setMeetingAttendee({});
      if (setIsMeetingScheduleModal) setIsMeetingScheduleModal(false);
      toast.success('Meeting Requested Successfully!');
    } catch (error: any) {
      setSetupMeetingFlag(false);
      if (error.statusCode === 500) {
        setErrorOccurred('Something went wrong. Please try again!');
      }
      else setErrorOccurred(error?.message);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getSessionMeetings(
      `where[userId]=${meetingAttendee?.id}&where[userId]=${personId}`
    );
  }, []);

  useEffect(() => {
    setIsLoading(sessionMeetings?.data === undefined);
    if (sessionMeetings?.data) {
      setMeetingData(groupBy(sessionMeetings?.data, "date"));
      setActiveMeetingDay(Object.keys(meetingData)[0]);
    }
  }, [sessionMeetings]);

  useEffect(() => {
    if (meetingData) {
      setActiveMeetingDay(Object.keys(meetingData)[0]);
    }
  }, [meetingData]);

  const onRequestMeeting = (e: any, selectedSessionId: string, requestDuration: number) => {
    setRequestMeetingTime(e.target.value?.toUpperCase());
    setShowBackButton(true);
    setRequestMeetingDuration(requestDuration!);

    setEventSessionId(selectedSessionId);
  };

  const handleMeetingDay = (_event: any, newValue: string) => {
    setActiveMeetingDay(newValue);
  };

  const isDisabled = (time: any, approvedMeeting: any) => {
    let returnVal = false;
    // eslint-disable-next-line array-callback-return
    approvedMeeting?.map((approveMeeting: any) => {
      if (
        dateTimeWithMinutesTz(
          approveMeeting.startTime,
          event?.timezone as string,
          0
        ) === time
      ) {
        returnVal = true;
      }
    });
    return returnVal;
  };

  const handleReset = () => {
    setPersonalNote("");
    setSummary("");
    setEventSessionId("");
    setRequestMeetingTime("");
    setRequestMeetingDuration(undefined);
    setErrorOccurred("");
  };

  const onClickRequestMeeting = () => {
    const payload = {
      userId: personId,
      eventSessionId,
      personalNote,
      startTime: requestMeetingTime,
      summary,
      attendeeId: meetingAttendee?.id,
      duration: requestMeetingDuration,
    };
    requestMeetingApi(payload);
  };

  useEffect(() => {
    if (sessionMeetings?.data) setIsLoading(false);
  }, [sessionMeetings]);

  return (
    <>
      {isLoading && (
        <Stack
          sx={{
            direction: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            minHeight: '400px'
          }}
        >
          <CircularProgress color="info" />
        </Stack>
      )}
      {!isLoading && (
        <Box sx={{  minHeight: '400px'}}>
          {personId && (
            <Box>
              {requestMeetingTime ? (
                <Box sx={{ pt: 5, px: '24px' }}>
                  <Stack direction="column">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        alt={meetingAttendee?.["firstName"]}
                        src={meetingAttendee?.avatar ? getMinifiedImage(meetingAttendee?.avatar) : defaultAvatar}
                        sx={{ width: 70, height: 70, borderRadius:"25px", mb: 1 }}
                      />

                      <Typography
                        sx={{ marginBottom: "10px", color: colors?.Text! || "#ffffff" }}
                      >
                        Request Meeting with{" "}
                        <b>
                          {meetingAttendee?.["firstName"]}{" "}
                          {meetingAttendee?.["lastName"]}
                        </b>{" "}
                        at{" "}
                        <b>
                          {dateTimeWithMinutesTz(
                            requestMeetingTime,
                            event?.timezone || "",
                            0
                          )?.toUpperCase()}
                          -
                          {dateTimeWithMinutesTz(
                            requestMeetingTime,
                            event?.timezone || "",
                            requestMeetingDuration && requestMeetingDuration > 900
                              ? 15
                              : requestMeetingDuration! / 60,
                          )?.toUpperCase()}
                        </b>
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      label="Add summary and purpose"
                      onChange={(e) => setSummary(e.target.value)}
                      sx={{ marginTop: "10px", marginBottom: "10px" , "& .MuiOutlinedInput-root" :{ borderRadius:"4px !important", color: colors?.Text! || '#ffffff' }, "& fieldset" :{ borderColor: colors?.Secondary! || '#ffffff !important' },  "& .MuiFormLabel-root" :{ color: colors?.Text! || '#ffffff !important' },}}
                      required
                    />
                    <Box sx={{ mt: '6px', mb: 2 }}>
                      {errorOccurred && errorOccurred !== "requestMeeting" && (
                        <Alert severity="error">
                          <Typography color="error">{errorOccurred}</Typography>
                        </Alert>
                      )}
                    </Box>
                    <Box>
                      <Stack
                        alignItems="flex-end"
                        justifyContent="flex-end"
                        spacing={2}
                        direction="row"
                      >
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              ) : (

                <>
                <Stack
                  sx={{
                    px: '24px'
                  }}
                >
                  {activeMeetingDay && (
                    <Tabs
                      sx={{
                        px: 2,
                        bgcolor: colors?.Secondary! || '#ffffff',
                        borderRadius: '5px',
                        '& .MuiTabs-indicator': {
                          display: 'none'
                        }
                      }}
                      value={activeMeetingDay}
                      onChange={handleMeetingDay}
                    >
                      {Object.keys(meetingData)?.map((date: any, index) => (
                        <Tab

                          label={`Day ${index + 1} | ${date}`}
                          key={date}
                          value={date}
                          sx={{
                            color: colors?.Text! || '#000000',
                            "&.Mui-selected": {
                              color: colors?.Text! || '#000000',
                              lineHeight: '48px'
                            },
                            minWidth:
                              Object.keys(meetingData).length === 1
                                ? "100%"
                                : "45%",
                          }}
                        />
                      ))}
                    </Tabs>
                  )}
                </Stack>
                  {meetingData[activeMeetingDay]?.map(
                    (session: any) =>
                      session.duration && (
                        <Stack
                          key={session?.id}
                          spacing={2}
                          justifyContent="center"
                          textAlign="center"
                          sx={{ p: 3, flexBasis: "100%" }}
                        >
                          <Typography
                            justifyContent="center"
                            sx={{ fontWeight: 500, fontFamily: "Poppins-500", color: colors?.Text! || "#ffffff" }}
                          >
                            {session?.title}
                          </Typography>

                          {[
                            ...Array(
                              session.duration / 900 >= 1 ? Math.ceil(session.duration / 900) : 1
                            ),
                          ].map((x, i) => {
                            const requestDuration =
                              (session.duration - i * 900) / 60 > 15
                                ? 15
                                : (session.duration - i * 900) / 60;

                            return (<Stack
                              key={i}
                              textAlign="center"
                              justifyContent="center"
                              sx={{ padding: "0px !important" }}
                            >
                              <ButtonGroup
                                orientation="vertical"
                                // onClick={(e) => onRequestMeeting(e, session.id)}
                                onClick={(e) =>
                                  onRequestMeeting(
                                    e,
                                    session.id,
                                    session.duration - i * 900 > 900
                                      ? 900
                                      : session.duration - i * 900,
                                  )
                                }
                              >
                                <Button
                                  key={i}
                                  style={{
                                    fontWeight: "400",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    borderRadius: "4px",
                                    color: colors?.Text! || "#ffffff",
                                    borderColor: colors?.Secondary! || "#03FFCD",
                                    opacity:isDisabled(
                                      i === 0
                                        ? dateTimeWithMinutesTz(
                                          session.startTime,
                                          event?.timezone as string,
                                          0
                                        )
                                        : dateTimeWithMinutesTz(
                                          session.startTime,
                                          event?.timezone as string,
                                          15 * i,//requestDuration * i, 
                                        ),
                                      session.eventSessionMeetings?.filter(
                                        (meet: any) => meet.status === SessionMeetingStatus.APPROVED
                                      )
                                    ) ? 0.5 : 1
                                  }}
                                  value={
                                    i === 0
                                      ? moment
                                        .utc(moment(session.startTime).utc())
                                        .tz(
                                          event?.timezone === 'AUS Eastern Standard Time' ||
                                            event?.timezone === 'E. Australia Standard Time'
                                            ? 'Australia/Sydney'
                                            : (event?.timezone as string)
                                        )
                                        ?.format()
                                      : moment
                                        .utc(
                                          moment(session.startTime)
                                            .add(15 * i, 'minutes')
                                            .utc()
                                        )
                                        .tz(
                                          event?.timezone === 'AUS Eastern Standard Time' ||
                                            event?.timezone === 'E. Australia Standard Time'
                                            ? 'Australia/Sydney'
                                            : (event?.timezone as string)
                                        )
                                        ?.format()
                                  }
                                  disabled={isDisabled(
                                    i === 0
                                      ? dateTimeWithMinutesTz(
                                        session.startTime,
                                        event?.timezone as string,
                                        0
                                      )
                                      : dateTimeWithMinutesTz(
                                        session.startTime,
                                        event?.timezone as string,
                                        15 * i,//requestDuration * i, 
                                      ),
                                    session.eventSessionMeetings?.filter(
                                      (meet: any) => meet.status === SessionMeetingStatus.APPROVED
                                    )
                                  )}
                                >
                                  {i === 0
                                    ? dateTimeWithMinutesTz(
                                      session.startTime,
                                      event?.timezone as string,
                                      0
                                    )?.toUpperCase()
                                    : dateTimeWithMinutesTz(
                                      session.startTime,
                                      event?.timezone as string,
                                      15 * i
                                    )?.toUpperCase()}{' '}
                                  -{" "}
                                  {i === 0
                                    ? dateTimeWithMinutesTz(
                                      session.startTime,
                                      event?.timezone as string,
                                      requestDuration
                                    )?.toUpperCase()
                                    : dateTimeWithMinutesTz(
                                      session.startTime,
                                      event?.timezone as string,
                                      15 * i + requestDuration
                                    )?.toUpperCase()}
                                </Button>
                              </ButtonGroup>
                            </Stack>)
                          })}
                        </Stack>
                      )
                  )}
                </>
              )}
            </Box>
          )}
          {personId && Object.keys(meetingData).length === 0 && (
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexBasis: "100%",
                height: "48vh",
                px: 4,
              }}
            >
              <Typography variant="h6" sx={{ color: colors?.Text! || "#ffffff" }}>
                Break session not exist for this event.
              </Typography>
            </Stack>
          )}
        </Box>
      )}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: 'space-between',
          px: '24px',
          pb: '24px'
        }}
      >
        <Button
          disabled={!showBackButton}
          onClick={(e) => { e.stopPropagation(); if (handleBackToAttendeesList) handleBackToAttendeesList() }}
          key={"back and forward button 1"}
          variant="contained"
          disableElevation
          sx={{
              height: "35px",
              width: '100px',
              color: colors?.Text! || "#000000",
              borderRadius: "5px",
              fontSize: "12px",
              background: colors?.Secondary! || "white",
              padding: "0px",
              borderRight: "1 px slid #a2cdc5",
              // minWidth: showBackButton ? '40px' : "40px",
              minWidth: "40px",
              "&:hover": {
                  backgroundColor: colors?.Secondary! || "white",
                  opacity: 0.9
              },
          }}
        >
          <IconButton
            
            disabled={!showBackButton}
            sx={{
                transform: 'rotate(180deg)'
            }}
          >
            <NextIcon />
          </IconButton>
          
        </Button>
        <Button
          disabled={!showBackButton}
          onClick={(e) => { e.stopPropagation(); if (onClickRequestMeeting) onClickRequestMeeting() }}
          sx={{
            height: "35px",
            width: '100px',
            color: colors?.ButtonTextColor! || "#000000",
            borderRadius: "5px",
            fontSize: "12px",
            background: colors?.Accent! || "white",
            padding: "0px",
            // borderRight: "1 px slid #a2cdc5",
            // minWidth: showBackButton ? '40px' : "40px",
            minWidth: "40px",
            "&:hover": {
                backgroundColor: colors?.Accent! || "white",
                opacity: 0.9
            },
        }}
        >
          Confirm
        </Button> 
      </Stack>
    </>
  );
}
export default forwardRef(MeetingRequestList);