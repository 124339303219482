import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../../../components/alert-modal/alert-modal';
import { ICompanyEmployee } from '../../../models/company/company-employee';
import { IUserProfile } from '../../../models/user-profile';
import { UserService } from '../../../services';
import { SearchIcon } from '../../../shared-components/V2/IconSvg';
import { closeMessageWindow, openMessageWindow } from '../../../store/newMessage';
import PeopleListCard from '../../search-results/peopleListCard';

interface IMutualConnectionsProps {
  primaryId: string;
  secondaryId: string;
  className?: string;
}

export const MutualConnections = (props: IMutualConnectionsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userService = new UserService();
  const [connections, setConnections] = useState<any>();
  const [showMoreConnections, setShowMoreConnections] = useState(false);
  const [search, setSearch] = useState<string>();
  const [filteredConnections, setFilteredConnections] = useState<any[]>([]);

  const getConnections = async (id: string) => {
    const connections = await userService.getUserConnections(id);
    return connections;
  };

  useEffect(() => {
    if (props.primaryId && props.secondaryId) {
      getConnections(props.primaryId).then(({ data: primaryData }) => {
        getConnections(props.secondaryId).then(({ data: secondaryData }) => {
          const filteredList: any[] = [];
          primaryData.filter(connection => secondaryData.find(s => s.id === connection.id) && filteredList.push(connection));
          setConnections({
            [props.secondaryId]: filteredList
          });
        });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.primaryId, props.secondaryId]);

  useEffect(() => {
    if (connections?.[props.secondaryId]?.length) {
      if (search) {
        const filteredList = connections?.[props.secondaryId].filter((c: any) => `${c.firstName} ${c.lastName}`?.toLowerCase()?.includes(search));
        setFilteredConnections(filteredList);
      } else {
        setFilteredConnections(connections?.[props.secondaryId]);
      }
    }
  }, [connections, props.secondaryId, search]);

  return connections?.[props.secondaryId]?.length > 0
    ? (
      <>
        <div className={classNames('flex flex-row', {
          [`${props.className}`]: props.className
        })}
        >
          <span
            className=" pr-[3px] font-semibold cursor-pointer hover:underline"
            onClick={() => setShowMoreConnections(true)}
          >
            {connections?.[props.secondaryId].length}
          </span>
          Mutual connections
        </div>
        <AlertModal
          isOpen={showMoreConnections}
          onClose={() => {
            setShowMoreConnections(false);
            setSearch('');
          }}
          className="w-576 p-2 container"
        >
          <>
            <div className="text-md px-2 py-1 pb-4">Mutual connections</div>
            <div className="px-2">
              <div className="px-3 py-1.5 flex items-end text-sm rounded-15 focus:outline-none w-[186px] h-[30px] border-1 border-blue-900 font-extralight text-gray-1 bg-gray-1">
                <SearchIcon />
                <input
                  name="search"
                  className="w-full ml-2 text-sm5 focus:outline-none block font-light text-gray-900 placeholder:text-gray-900 bg-transparent"
                  placeholder="Search"
                  onChange={e => { setSearch(e.target.value.toLowerCase()); }}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="border-b-1 border-gray-300 my-2" />
            <div className="w-full h-420 overflow-auto px-4">
              <PeopleListCard
                onClick={(user: IUserProfile) => {
                  setShowMoreConnections(false);
                  setSearch('');
                  navigate(`/wall/${user.slug}`);
                }}
                people={filteredConnections}
                onMessage={async (user: ICompanyEmployee) => {
                  await dispatch(closeMessageWindow());
                  await dispatch(
                    openMessageWindow({
                      avatar: user.avatar,
                      userDisplayName: `${user.firstName} ${user.lastName}`,
                      userId: user.id
                    })
                  );
                  setShowMoreConnections(false);
                  setSearch('');
                }}
                key="user-connections"
              />
            </div>
          </>
        </AlertModal>
      </>
    )
    : null;
};
