import { CardMedia, Container, Divider, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { IEvent } from 'src/models/event/event';

import LocationImage from 'src/assets/images/box-webiste/location.png';
import classNames from 'classnames';
import { getTimeZone } from 'src/utils/timezones';
import { useMemo } from 'react';
import { dateAndTimeWithTz } from 'src/utils/date-timezone';
import { Loader } from 'src/shared-components/Loader';
import EventRegistration from '../event/website/event-registration';

type BannerProps = {
  coverUrl: string;
}

export const Banner = ({ coverUrl }: BannerProps) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  const getTimeZoneName = (zone: string) => {
    const shortTimeZone = getTimeZone(zone)?.[0]?.abbr ? getTimeZone(zone)?.[0]?.abbr : zone;
    return shortTimeZone;
  };

  const eventDateTimeWithTz = useMemo(() => {
    const startTime = dateAndTimeWithTz(event?.startTime, event?.timezone);
    const endTime = dateAndTimeWithTz(event?.endTime, event?.timezone);
    return {
      startTime,
      endTime
    };
  }, [event?.startTime, event?.endTime, event?.timezone]);

  const eventDateTimeLocalTz = useMemo(() => {
    const startTime = format(parseISO(event?.startTime), 'hh:mm aa')?.replace('.', '')?.replace(' ', '')?.replace('.', '');
    const endTime = format(parseISO(event?.endTime), 'hh:mm aa')?.replace('.', '')?.replace(' ', '')?.replace('.', '');
    const timeZoneMatched = new Date(eventDateTimeWithTz.startTime.dateAndTime).getMinutes()

      === new Date(parseISO(event?.startTime)).getMinutes()

      && new Date(eventDateTimeWithTz.startTime.dateAndTime).getHours()

      === new Date(parseISO(event?.startTime)).getHours();

    return {
      startTime,
      endTime,
      timeZoneMatched
    };
  }, [event?.startTime, event?.endTime]);
  return (
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 1,
          maxWidth: '100%',
          p: 0,
          pt: '130px',
          pb: '215px',
          pl: '15px !important',
          pr: '15px !important',
          '@media (min-width: 768px)': { maxWidth: '750px' },
          '@media (min-width: 992px)': { maxWidth: '970px' },
          '@media (max-width: 992px)': { pt: '70px', pb: '50px' },
          '@media (min-width: 1200px)': { maxWidth: '1170px' },
          '@media (min-width: 1280px)': { maxWidth: '1260px' },
          '&:before': {
            content: '""',
            backgroundImage: 'url(https://assets.swoogo.com/uploads/full/3088162-6536bb98259bc.png)',
            position: 'absolute',
            right: 0,
            top: 40,
            maxWidth: '720px',
            maxHeight: '600px',
            width: '100%',
            height: '100%',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            '@media (max-width: 1200px)': { width: '50%' },
            '@media (max-width: 992px)': { display: 'none' },
          }
        }}
      >
      <Stack
        sx={{
          maxWidth: '585px',
          '@media (max-width: 768px)': {
            alignItems: 'center',
            maxWidth: '100%'
          }
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: '18px',
            mb: '15px',
            color: '#ffffffa6',
            lineHeight: '22px',
          }}
          className='bold-lato-font'
        >
          {event?.slug}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: '60px',
            mb: '20px',
            color: '#ffffff',
            lineHeight: '72px',
          }}
          className='bold-lato-font'
        >
          {event?.name}
        </Typography>
        <Stack direction="row" mb="30px">
          <CardMedia
            src={LocationImage}
            component={'img'}
            sx={{
              width: '28px',
              height: '28px',
              mr: '7px',
              objectFit: 'contain',
              '&.MuiCardMedia-root': {
                objectFit: 'contain'
              }
            }}
          />
          <div>
          <div
            className={classNames('text-[20px] leading-[30px] text-[#ffffffa6] normal-lato-font mb-[6px] flex text-left justify-left items-center font-bold capitalize flex-wrap', {
              hidden: !eventDateTimeWithTz.startTime.date,
            })}
          >
            {eventDateTimeWithTz.startTime.dateLongFormat}
            <span className="mx-[3px] leading-[30px]"> • </span>
            <span className="uppercase leading-[30px]">
              {eventDateTimeWithTz?.startTime?.time}
            </span>
            -
            <span className="text-[20px] leading-[30px] normal-lato-font uppercase mr-[3px]">{eventDateTimeWithTz.endTime.time}</span>
            {getTimeZoneName(event?.timezone)}
          </div>
          {!eventDateTimeLocalTz.timeZoneMatched && (
            <div
              className={classNames('text-[15px] leading-[30px] text-[#ffffffa6] md:flex text-left justify-left font-normal capitalize break-words flex-wrap', {
                hidden: !eventDateTimeLocalTz?.startTime,
              })}
            >
              <span className="text-[20px] leading-[30px] normal-lato-font">
                {format(parseISO(event.startTime), 'MMMM dd, yyyy')}
              </span>
              <span className="mx-[3px] text-[20px] leading-[30px] normal-lato-font"> • </span>
              <span className="uppercase text-[20px] leading-[30px] normal-lato-font">
                {eventDateTimeLocalTz.startTime}
              </span>
              -
              <span className="uppercase mr-[3px] text-[20px] leading-[30px] normal-lato-font">{eventDateTimeLocalTz.endTime}</span>
              <span>(Your Local Time)</span>
            </div>
          )}
          </div>
          {/* <Typography
            variant="body2"
            sx={{
              fontSize: '20px',
              mb: '30px',
              color: '#ffffffa6',
              lineHeight: '34px',
              flexFlow: 'wrap',
              display: 'flex'
            }}
            className='normal-lato-font'
          >
            {event.eventRegions.map((item, index) => (
              <div style={{flexWrap: 'nowrap', display: 'flex'}}>{item.name} {index !== event.eventRegions.length - 1 && <span style={{margin: '0 8px'}}>|</span>}</div>
            ))}
          </Typography> */}
        </Stack>
        {/* <a className='see-event-btn' href='#events'>Register</a> */}
        <div className="flex justify-start mt-[0px]">
          {event ? (
            <EventRegistration isBanner isBoxRegister eventId={event.id} />
          ) : (
            <Loader className="pt-0" key="loader" />
          )}
        </div>
      </Stack>
    </Container>
  );
};
