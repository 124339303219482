import React, { useState } from 'react';
import { getBrandingColors } from '../../event/live/liveEventStyling';

interface IExitSvgIcon {
  isActive?: boolean;
}

export const ExitSvgIconActive = ({ isActive }: IExitSvgIcon) => {
    const [isHovered, setIsHovered] = useState(false);
    const colors = getBrandingColors();

    return (
        <div 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isHovered ? (
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    id="Layer_1" 
                    data-name="Layer 1" 
                    width="29" 
                    height="29" 
                    viewBox="0 0 24 24"
                >
                    <path 
                        d="m24,15c0,.617-.24,1.197-.678,1.634l-2.072,2.073c-.195.195-.451.293-.707.293s-.512-.098-.707-.293c-.391-.391-.391-1.023,0-1.414l1.292-1.293h-6.128c-.553,0-1-.447-1-1s.447-1,1-1h6.128l-1.292-1.293c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l2.073,2.074c.437.436.677,1.016.677,1.633Zm-10-4v-5c0-1.103-.897-2-2-2h0s0,0,0,0v17.999h1c.552,0,1-.448,1-1v-2c0-.553.447-1,1-1s1,.447,1,1v2c0,1.654-1.346,3-3,3H3c-1.654,0-3-1.346-3-3V5.621C0,3.246,1.69,1.184,4.019.718L7.216.079c1.181-.236,2.391.066,3.321.829.375.307.665.685.902,1.092h.561c2.206,0,4,1.794,4,4v5c0,.553-.447,1-1,1s-1-.447-1-1Zm-5,1.5c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5,1.5.672,1.5,1.5,1.5,1.5-.672,1.5-1.5Z"
                        fill={colors?.Accent || '#03FFCD'}
                    />
                </svg>
            ) : (
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    id="Layer_1" 
                    data-name="Layer 1" 
                    width="29" 
                    height="29" 
                    viewBox="0 0 24 24"
                >
                    <path 
                        d="m9,12c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm7,5.5v4c0,1.379-1.121,2.5-2.5,2.5H2.5c-1.378,0-2.5-1.121-2.5-2.5V5.321C0,3.184,1.521,1.327,3.617.908L7.813.069c1.031-.204,2.091.059,2.906.726.406.333.725.745.944,1.205h.837c1.93,0,3.5,1.57,3.5,3.5v6c0,.276-.224.5-.5.5s-.5-.224-.5-.5v-6c0-1.379-1.121-2.5-2.5-2.5h-.555c.024.166.055.33.055.501v19.499h1.5c.827,0,1.5-.673,1.5-1.5v-4c0-.276.224-.5.5-.5s.5.224.5.5ZM11,3.501c0-.752-.333-1.456-.915-1.933-.452-.371-1.01-.567-1.582-.567-.164,0-.33.016-.494.049l-4.196.839c-1.63.326-2.813,1.77-2.813,3.433v16.179c0,.827.673,1.5,1.5,1.5h8.5V3.501Zm13,10.999c0-.5-.277-1.154-.677-1.528l-2.496-2.337c-.201-.188-.518-.179-.707.023-.188.201-.178.519.023.707l2.496,2.337c.093.087.158.191.214.298h-8.354c-.276,0-.5.224-.5.5s.224.5.5.5h8.325c-.052.088-.101.179-.179.253l-2.506,2.385c-.2.19-.208.507-.018.707.099.104.23.155.362.155.124,0,.248-.046.345-.138l2.506-2.385c.372-.354.664-.978.664-1.478Z"
                        fill={colors?.Accent}
                    />
                </svg>
            )}
        </div>
    );
};



