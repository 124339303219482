import {
  ActionCreator,
  Dispatch,
} from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ICreateComment } from '../../../models/post/comment';
import { ICommentsResult } from '../../../models/post/CommentsResult';
import { ICommentsState } from '../../../models/post/CommentsState';
import { ILikeUnlikeCommentState } from '../../../models/post/LikeUnlikeCommentState';
import { FilUploadService } from '../../../services/FileUpload';
import { PostService } from '../../../services/Post';
import {
  CreatePostCommentActionTypes,
  LikeCommentActionTypes,
} from './types';

// Create Comment on a Post
export interface ICreatePostCommentInProgress {
  type: CreatePostCommentActionTypes.CREATE_COMMENT_IN_PROGRESS;
  inProgress: boolean;
}

export interface ICreatePostCommentSuccess {
  type: CreatePostCommentActionTypes.CREATE_COMMENT_SUCCESS;
  data: ICommentsResult;
}

export interface ICreatePostCommentError {
  type: CreatePostCommentActionTypes.CREATE_COMMENT_ERROR;
  errorMessage: string;
}

// Add reaction to comment
export interface ILikeCommentSuccessAction {
  type: LikeCommentActionTypes.LIKE_COMMENT;
  message: string;
}

export type CreatePostCommentActions =
  | ICreatePostCommentInProgress
  | ICreatePostCommentSuccess
  | ICreatePostCommentError;

export const createPostComment: ActionCreator<
  ThunkAction<Promise<any>, ICommentsState, null, ICreatePostCommentSuccess>
> = (
  postId: string,
  data: ICreateComment,
  attachment?: File,
  companyId?: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      const postService = new PostService();
      const fileUploadService = new FilUploadService();
      let uploadedFileUrl = "";
      let result: ICommentsResult | undefined = undefined;

      if (attachment) {
        const uploadResult = await fileUploadService.uploadImage(attachment);
        uploadedFileUrl = uploadResult.data.key;
      }

      if (!companyId) {
        if (uploadedFileUrl !== "") {
          const commentWithAttachment: ICreateComment = {
            content: data.content,
            parentId: data.parentId,
            image: uploadedFileUrl,
          };

          result = await postService.createComment(
            postId,
            commentWithAttachment
          );
        } else {
          result = await postService.createComment(postId, data);
        }
      }

      if (companyId) {
        if (uploadedFileUrl !== "") {
          const companyCommentWithAttachment: ICreateComment = {
            content: data.content,
            parentId: data.parentId,
            image: uploadedFileUrl,
            companyId: companyId,
            type: "company",
          };

          result = await postService.createComment(
            postId,
            companyCommentWithAttachment
          );
        } else {
          const companyComment: ICreateComment = {
            content: data.content,
            parentId: data.parentId,
            companyId: companyId,
            type: "company",
          };

          result = await postService.createComment(postId, companyComment);
        }
      }

      dispatch({
        type: CreatePostCommentActionTypes.CREATE_COMMENT_SUCCESS,
        data: result,
      });
    } catch (error: any) {
      console.error(error);

      dispatch({
        type: CreatePostCommentActionTypes.CREATE_COMMENT_ERROR,
        errorMessage: error.message,
      });
    } finally {
      dispatch({
        type: CreatePostCommentActionTypes.CREATE_COMMENT_IN_PROGRESS,
        inProgress: false,
      });
    }
  };
};

export const setCreateCommentInProgress: ActionCreator<
  ThunkAction<any, ICommentsState, null, ICreatePostCommentInProgress>
> = (isInProgress: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: CreatePostCommentActionTypes.CREATE_COMMENT_IN_PROGRESS,
    inProgress: isInProgress,
  });

// Like reactions

export const likeComment: ActionCreator<
  ThunkAction<
    Promise<any>,
    ILikeUnlikeCommentState,
    null,
    ILikeCommentSuccessAction
  >
> = (commentId: string, reaction: string) => {
  return async (dispatch: Dispatch) => {
    const postService = new PostService();
    try {
      const result = await postService.likeComment(
        commentId,
        reaction.toLowerCase()
      );

      dispatch({
        type: LikeCommentActionTypes.LIKE_COMMENT,
        message: result.message,
      });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: LikeCommentActionTypes.LIKE_COMMENT_ERROR,
        errorMessage: error.message,
      });
    } finally {
      dispatch({
        type: LikeCommentActionTypes.LIKE_IN_PROGRESS,
        loading: false,
      });
    }
  };
};
