import { useState } from 'react';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { ICompanyProfileCardProps } from '../../../models/company/company-profile-card';
import companySizeIcon from '../../../assets/images/company-size-icon.svg';
import descriptionIcon from '../../../assets/images/description-icon.svg';
import specailityIcon from '../../../assets/images/specialties-icon.svg';
import websiteIcon from '../../../assets/images/website-icon.svg';
import { Button } from '../../../shared-components/V2';
import { Logo } from '../../../shared-components/V2/Logo';

export const CompanyProfileCard = (props: ICompanyProfileCardProps) => {
  const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;
  const [seeMoreSpec, setSeeMoreSpec] = useState(false);
  const [seeMoreDesc, setSeeMoreDesc] = useState(false);
  const addHttps = (url: string) => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }
    return url;
  };

  return (
    <div className="flex flex-col p-[20px] pb-[14px]">
      <div className="flex flex-row mb-[20px]">
        <div className="my-auto h-[60px] max-h-[60px] min-h-[60px] w-[60px] max-w-[60px] min-w-[60px] bg-white rounded-[6px] px-[1px] py-[1.5px]">
          <Logo
            src={`${imageUrlPrefix}/${props?.logoUrl}`}
            alt=""
            className={classNames(
              'w-[58px] max-w-[58px] min-w-[58px] h-[58px] max-h-[58px] min-h-[58px] object-contain rounded-[6px]',
              {
                'bg-[#edf2f5]':
                  props?.logoUrl?.includes('null') || !props?.logoUrl,
              }
            )}
            defaultLogo="company"
          />
        </div>
        <div className="flex flex-col ml-2 justify-center">
          <div
            className={classNames(
              'text-[20px] text-black font-semibold leading-[22px]',
              {
                'mb-[0x]': !props.industryName && !props.headquarter,
                'mb-[3px]': props.industryName && props.headquarter,
              }
            )}
          >
            {props.companyName}
          </div>
          <div className="text-sm text-[#3C3C3C] leading-[19px] font-normal line-clamp-1">
            {props.industryName}
          </div>
          <div className="text-sm text-[#3C3C3C] leading-[19px] font-normal line-clamp-1">
            {props.headquarter}
          </div>
        </div>
      </div>
      <div className="flex mb-[15px]">
        <div className="w-[18px] mr-[10px]">
          <img className="w-[18px]" src={descriptionIcon} alt="" />
        </div>
        <div className="w-[calc(100%-28px)]">
          <div className="text-[13px] text-[#172746] leading-[19px] font-semibold">
            About
          </div>
          <div className="text-[12px] text-[#3C3C3C] leading-[17px] font-normal">
            {props.about && parse(DOMPurify.sanitize(props.about?.substring(0, 100)).replace(/&nbsp;/g, ''))}
            {seeMoreDesc && parse(DOMPurify.sanitize(props.about?.substring(100)).replace(/&nbsp;/g, ''))}
            {' '}
            {props.about?.length > 100 && (
              <Button
                type="button"
                text={seeMoreDesc ? 'See less' : '... See more'}
                size="x-small"
                className="h-0 text-blue-1 text-x-sm"
                onClick={() => { setSeeMoreDesc(!seeMoreDesc); }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex mb-[15px]">
        <div className="w-[18px] mr-[10px]">
          <img className="w-[18px]" src={specailityIcon} alt="" />
        </div>
        <div className="w-[calc(100%-28px)]">
          <div className="text-[13px] text-[#172746] leading-[19px] font-semibold">
            Core Capabilities
          </div>
          <div className="text-[12px] text-[#3C3C3C] leading-[17px] font-normal text-justify">
            <ul className="list-disc ml-5">
              {props.specialities
                && props.specialities.map((speciality, index) => {
                  if (index <= 2) {
                    return (
                      <li
                        key={`speciality-${index}`}
                        className="text-xs my-auto"
                      >
                        {speciality.name}
                      </li>
                    );
                  } else {
                    return '';
                  }
                })}
              {seeMoreSpec
                && props.specialities
                && props.specialities.map((speciality, index) => {
                  if (index >= 3) {
                    return (
                      <li
                        key={`speciality-${index}`}
                        className="text-xs my-auto"
                      >
                        {speciality.name}
                      </li>
                    );
                  } else {
                    return '';
                  }
                })}
            </ul>
            {' '}
            {props.specialities && props.specialities.length > 5 && (
              <Button
                type="button"
                text={seeMoreSpec ? 'See less' : '... See more'}
                size="x-small"
                className="h-0 text-blue-1 text-x-sm"
                onClick={() => {
                  setSeeMoreSpec(!seeMoreSpec);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex mb-[15px]">
        <div className="w-[18px] mr-[10px]">
          <img className="w-[18px]" src={companySizeIcon} alt="" />
        </div>
        <div className="w-[calc(100%-28px)]">
          <div className="text-[13px] text-[#172746] leading-[19px] font-semibold">
            Size
          </div>
          {props.size ? (
            <div className="text-[12px] text-[#3C3C3C] leading-[17px] font-normal">
              {props.size}
              {' '}
              Employees
            </div>
          ) : null}
          <div className="text-x-sm text-[#285CB2] leading-4 font-semibold">
            {`${props.employeesOnPanelist} employees on ${props?.companyName}`}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="w-[18px] mr-[10px]">
          <img className="w-[18px]" src={websiteIcon} alt="" />
        </div>
        <div className="w-[calc(100%-28px)]">
          <div className="text-[13px] text-[#172746] leading-[19px] font-semibold">
            Website
          </div>
          <div className="text-[12px] text-[#285CB2] leading-[17px] font-medium break-words hover:underline">
            <a target="_blank" href={addHttps(props.website)} rel="noreferrer">
              {props.website}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileCard;
