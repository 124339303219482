import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
    fillColor?: string;
    fillBgColor?: string;
}

function SponsorsTabIcon({ fillColor, fillBgColor, ...props }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.677" height="22.949" viewBox="0 0 23.677 22.949">
            <g id="Group_21256" data-name="Group 21256" transform="translate(0 0)">
                <path id="Path_14622" data-name="Path 14622" d="M13.238,11.727h6.243a1.115,1.115,0,0,0,.422-.082,1.088,1.088,0,0,0,.345-.227,1.046,1.046,0,0,0,.232-.332,1.011,1.011,0,0,0,.082-.408V9.152a1,1,0,0,0-.082-.408,1.041,1.041,0,0,0-.231-.33,1.083,1.083,0,0,0-.343-.223,1.108,1.108,0,0,0-.419-.082H13.238a2.227,2.227,0,0,1-.843-.163,2.173,2.173,0,0,1-.688-.448,2.088,2.088,0,0,1-.464-.664,2.019,2.019,0,0,1-.17-.816V1.04a1,1,0,0,0-.082-.408,1.041,1.041,0,0,0-.234-.327,1.083,1.083,0,0,0-.343-.223A1.108,1.108,0,0,0,10,0H9.285a1.118,1.118,0,0,0-.423.082,1.093,1.093,0,0,0-.346.225A1.05,1.05,0,0,0,8.282.64a1.014,1.014,0,0,0-.082.408V3.525a2.019,2.019,0,0,1-.17.816A2.088,2.088,0,0,1,7.567,5a2.173,2.173,0,0,1-.688.448,2.227,2.227,0,0,1-.843.163H1.082A1.114,1.114,0,0,0,.661,5.7a1.086,1.086,0,0,0-.344.223,1.044,1.044,0,0,0-.235.331A1.01,1.01,0,0,0,0,6.659V8.592A1.012,1.012,0,0,0,.082,9a1.05,1.05,0,0,0,.233.334,1.093,1.093,0,0,0,.346.225,1.116,1.116,0,0,0,.422.082H7.327a2.227,2.227,0,0,1,.843.163,2.173,2.173,0,0,1,.688.448,2.088,2.088,0,0,1,.464.664,2.019,2.019,0,0,1,.17.816V18.32a.462.462,0,0,0,.039.186.478.478,0,0,0,.106.152.5.5,0,0,0,.157.1.51.51,0,0,0,.193.038,1.119,1.119,0,0,0,.423-.082,1.09,1.09,0,0,0,.345-.224,1.047,1.047,0,0,0,.232-.334,1.015,1.015,0,0,0,.082-.408V13.82a2.019,2.019,0,0,1,.17-.816,2.088,2.088,0,0,1,.464-.664,2.173,2.173,0,0,1,.688-.448,2.227,2.227,0,0,1,.843-.163" transform="translate(1.592 2.434)" fill={fillColor} />
                <path id="Path_14623" data-name="Path 14623" d="M1.882,0H7.128a2.019,2.019,0,0,1,.734.136,1.9,1.9,0,0,1,.6.371,1.733,1.733,0,0,1,.4.55,1.606,1.606,0,0,1,.148.674V5.517a1.607,1.607,0,0,1-.148.674,1.732,1.732,0,0,1-.4.55,1.9,1.9,0,0,1-.6.37,2.021,2.021,0,0,1-.734.136H1.882a2.019,2.019,0,0,1-.734-.136,1.9,1.9,0,0,1-.6-.37,1.732,1.732,0,0,1-.4-.55A1.606,1.606,0,0,1,0,5.517V1.731a1.6,1.6,0,0,1,.148-.67,1.734,1.734,0,0,1,.4-.553,1.9,1.9,0,0,1,.6-.371A2.018,2.018,0,0,1,1.882,0" transform="translate(0)" fill={fillColor} />
                <path id="Path_14624" data-name="Path 14624" d="M1.882,0H8.253a1.936,1.936,0,0,1,.734.143,1.89,1.89,0,0,1,.6.389,1.816,1.816,0,0,1,.4.577,1.755,1.755,0,0,1,.148.706V7.962a1.757,1.757,0,0,1-.148.707,1.816,1.816,0,0,1-.4.577,1.889,1.889,0,0,1-.6.389,1.937,1.937,0,0,1-.734.143H1.882a1.936,1.936,0,0,1-.734-.143,1.891,1.891,0,0,1-.6-.389,1.816,1.816,0,0,1-.4-.577A1.755,1.755,0,0,1,0,7.962V1.816a1.754,1.754,0,0,1,.148-.706,1.818,1.818,0,0,1,.4-.577,1.891,1.891,0,0,1,.6-.389A1.935,1.935,0,0,1,1.882,0" transform="translate(13.541 0)" fill={fillColor} />
                <path id="Path_14625" data-name="Path 14625" d="M1.882,0H8.253a1.936,1.936,0,0,1,.734.143,1.89,1.89,0,0,1,.6.389,1.816,1.816,0,0,1,.4.577,1.755,1.755,0,0,1,.148.706v4.33a1.757,1.757,0,0,1-.148.707,1.816,1.816,0,0,1-.4.577,1.889,1.889,0,0,1-.6.389,1.937,1.937,0,0,1-.734.143H1.882a1.936,1.936,0,0,1-.734-.143,1.891,1.891,0,0,1-.6-.389,1.816,1.816,0,0,1-.4-.579A1.755,1.755,0,0,1,0,6.146V1.816a1.754,1.754,0,0,1,.148-.706,1.818,1.818,0,0,1,.4-.577,1.891,1.891,0,0,1,.6-.389A1.935,1.935,0,0,1,1.882,0" transform="translate(13.541 14.985)" fill={fillColor} />
                <path id="Path_14626" data-name="Path 14626" d="M1.882,0H8.253A1.88,1.88,0,0,1,9.987,1.139a1.847,1.847,0,0,1,.148.727V8.184A1.853,1.853,0,0,1,9.583,9.5a1.884,1.884,0,0,1-1.331.546H1.882A1.884,1.884,0,0,1,.148,8.911,1.847,1.847,0,0,1,0,8.184V1.867a1.846,1.846,0,0,1,.148-.725A1.884,1.884,0,0,1,1.884,0" transform="translate(0 12.898)" fill={fillColor} />
            </g>
        </svg>


    );
}

export default SponsorsTabIcon;
