import classNames from 'classnames';
import { EventWebsiteTemplateType } from 'src/mui/types/survey';
import { IEvent } from 'src/models/event/event';
import { IAppState } from 'src/store';
import { useSelector } from 'react-redux';
import { Logo } from '../../../shared-components/V2/Logo';
import { IEventSponsor } from '../../../models/event/event-sponsor';
import { getBrandingColors } from '../../../mui/pages/event/live/liveEventStyling';

interface IWebsiteEventPartnerList {
  sponsors: IEventSponsor[];
  roomQty?: number;
  isTitleLobby?: boolean;
  isOpen?: boolean;
  isLive?: boolean;
}

const WebsiteEventPartnerList = ({
  sponsors,
  roomQty,
  isTitleLobby,
  isOpen,
  isLive,
}: IWebsiteEventPartnerList) => {
  const event: IEvent = useSelector((state: IAppState) => state.website.event!);
  return (
    <div
      className={classNames('sm:pl-[230px] sm:mt-0 mt-4', {
        '!p-0': roomQty === 2,
        'sm:pl-[100px] sm:mt-0 mt-4': isTitleLobby,
        '!pl-0 max-w-[215px] min-w-[215px]': isOpen,
        '!pl-0 max-w-[215px]': isLive,
      })}
    >
      <div className="">
        {(!!sponsors && sponsors?.length && !isLive) ? (
          <div
            className={classNames('text-[14px] font-medium h-[20px] mb-2.5', {
              'text-white': isLive,
              'text-[#0B1221]': !isLive,
              'text-[#FFFFFF]':
                event?.websiteTemplate
                === EventWebsiteTemplateType.AFTER_HOURS,
            })}
          >
            Partners
          </div>
        ) : null}
        {sponsors
          ? sponsors?.map((item: any) => (
            <div
              className={classNames(
                'flex mb-2.5 last:mb-0',
                {
                  'mb-[5px] last:mb-0': isLive,
                  'mb-2.5 last:mb-0': !isLive,
                }
              )}
            >
              <div className="w-full">
                <div
                  className={classNames(
                    'flex',
                    {
                      'gap-[5px]': isLive,
                      'gap-[10px]': !isLive,
                    }
                  )}

                >
                  <div
                    className={classNames(
                      ' bg-white rounded-[4px] w-[49px] max-w-[49px] min-w-[49px] h-[49px] max-h-[49px] min-h-[49px] object-contain flex items-center justify-center',
                      {
                        'p-[1px] !bg-white': item?.logo ?? item?.company?.logo,
                        'bg-[#edf2f5]': !item?.logo ?? !item?.company?.logo,
                        'create-post-card !rounded-[4px]': !item?.company?.logo,
                      }
                    )}
                  >
                    <Logo
                      className={classNames(
                        'rounded-[4px] w-[49px] max-w-[49px] min-w-[49px] h-[49px] max-h-[49px] min-h-[49px] object-contain'
                      )}
                      src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${item?.logo ?? item?.company?.logo
                      }`}
                      alt=""
                      defaultLogo="company"
                    />
                  </div>
                  <div
                    className={classNames(
                      'flex flex-col',
                      {
                        'justify-center': isLive,
                        'justify-start': !isLive,
                      }
                    )}
                  >
                    <div
                      className={classNames(
                        'font-semibold',
                        {
                          'text-white text-[12px] leading-[14px]': isLive,
                          'text-[#0B1221] text-[13px] leading-[16px] pt-[3px]': !isLive,
                          'text-[#FFFFFF]':
                            event?.websiteTemplate
                            === EventWebsiteTemplateType.AFTER_HOURS,
                        }
                      )}
                      style={{
                        color: getBrandingColors()?.Text || 'ffffff',
                      }}
                    >
                      {item.company.name}
                    </div>
                    <div
                      className={classNames(
                        ' font-normal',
                        {
                          'text-white text-[12px] leading-[20px]': isLive,
                          'text-[#0B1221] text-[11px] leading-[13px]': !isLive,
                          'text-[#FFFFFF]':
                            event?.websiteTemplate
                            === EventWebsiteTemplateType.AFTER_HOURS,
                        }
                      )}
                      style={{
                        color: getBrandingColors()?.Text || 'ffffff',
                      }}
                    >
                      {item.sponsorship}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
          : null}
      </div>
    </div>
  );
};

export default WebsiteEventPartnerList;
