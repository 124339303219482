export interface ArrowRightSvgIconProps {
    fillColor?: string;
}
export const ArrowRightSvgIcon = ({ fillColor }: ArrowRightSvgIconProps) => {
  const iconColor = fillColor ? fillColor : "#bdcce8"
    return <svg xmlns="http://www.w3.org/2000/svg" width="15.918" height="24" viewBox="0 0 15.918 30.067">
    <g id="Component_417" data-name="Component 417" transform="translate(-0.308 30.067) rotate(-90)">
      <path id="Polygon_9" data-name="Polygon 9" d="M14.307.77a1,1,0,0,1,1.454,0L28.474,14.231a1,1,0,0,1-.727,1.687H2.32a1,1,0,0,1-.727-1.687Z" transform="translate(30.067 16.226) rotate(180)" fill={iconColor}/>
    </g>
  </svg>
  
};