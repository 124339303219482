import React from "react";

type ReactionButtonProps = {
  type: string;
  count: any;
  Icon: any;
  users: any;
  onClick: () => void;
  userId: any
};

const ReactionButtonsChat = (props: ReactionButtonProps) => {
  const { count, type, onClick, Icon, users, userId } = props;
  return (
    <>
      {count > 0 && (
        <span
          style={{
            height: "22px",
            width: "35px",
            border: `${users.includes(userId) ? "2px solid #212b36" : "1px solid #2f2f2f"}`,
            borderRadius: "30px",
            textAlign: "center",
            color: "white",
            background: `${users.includes(userId) ? "#203c6e" : "rgb(98, 98, 98)"}`,
            display: "flex",
            cursor: "pointer",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "3px",
          }}
          onClick={onClick}
        >
          <Icon height={15} width={15} />
          <span style={{ fontSize: "12px", marginLeft: "2px" }}>{count}</span>
        </span>
      )}
    </>
  );
};

export default ReactionButtonsChat;
