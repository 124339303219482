/* eslint-disable max-len */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useOnClickOutside } from 'usehooks-ts';
import { ImageEditSvgIcon } from 'src/shared-components/V2/IconSvg/ImageEditSvgIcon/ImageEditSvgIcon';
import { setProfilePicture } from 'src/store/company/profile-picture/actions';
import { getCompanyProfilePreview } from 'src/store/company/profile-preview/actions';
import coverPhotoDefault from '../../../../assets/images/cover-photo-default-md.png';
import { ICompanyHeader } from '../../../../models/company/company-header';
import { IAppState } from '../../../../store';
import { CompanyHeaderDropdown } from './company-header-dropdown';
import { Logo } from '../../../../shared-components/V2/Logo';
import { ButtonWithTextAndImage } from '../../../../shared-components/V2';
import AlertModal from '../../../../components/alert-modal/alert-modal';
import { CompanyAdminsDialog } from '../company-admins-dialog';
import { CompanyService } from '../../../../services';
import UserCoverCrop from '../../../user/user-profile-crop/user-cover-crop';
import { FilUploadService } from '../../../../services/FileUpload';
import { setCoverPhoto } from '../../../../store/company/cover-photo/actions';
import { IReactSelectOption } from '../../../../models/react-select-option';
import { updateProfile } from '../../../../store/company/profile-update/actions';
import { Loader } from '../../../../shared-components/Loader';
import CompanyHeaderInfo from './company-header-info';
// import UserProfileCrop from './user-profile-crop';
import { ReactComponent as CameraIcon } from '../../../../assets/images/change-profile-picture.svg';
import { ReactComponent as SpinnerIcon } from '../../../../assets/images/company/animation-spinner.svg';

export const CompanyHeader = (props: ICompanyHeader) => {
  const ref = React.useRef(null);
  const profilePictureRef = useRef<HTMLInputElement | null>(null);
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const coverPhotoRef = useRef<HTMLInputElement | null>(null);
  const imageUrlPrefix = process.env.REACT_APP_IMAGE_URL_PREFIX;
  const { companyProfilePreview } = useSelector((state: IAppState) => state);
  const [isOwner, setIsOwner] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [toggleAdminModal, setToggleAdminModal] = useState(false);
  const onCloseAdminModal = () => setToggleAdminModal(!toggleAdminModal);
  const onOpenAdminModal = () => setToggleAdminModal(!toggleAdminModal);
  const [companyAdminRequestCount, setCompanyAdminRequestsCount] = useState(0);
  const [coverImage, setCoverImage] = useState('');
  const [isCoverPhotoAttached, setIsCoverPhotoAttached] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isProfileLoader, setIsProfileLoader] = useState(false);
  const [selectedCoverPhotoUrl, setSelectedCoverPhotoUrl] = useState('');
  const [selectedCoverPhotoFile, setSelectedCoverPhotoFile] = useState('');
  const [_innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [selectedProfilePhotoFile, setSelectedProfilePhotoFile] = useState('');
  // const [isProfileModal, setIsProfileModal] = useState(false);
  const locationPathArr = location?.pathname?.match(/\/(\w+)/ig);
  const isCreateCompany = locationPathArr && locationPathArr[2] === '/create';

  const getCompanyAdminRequestCount = async () => {
    const companyService = new CompanyService();

    if (companyProfilePreview.value?.id) {
      const result = await companyService.getCompanyAdminRequests(companyProfilePreview.value?.id);
      setCompanyAdminRequestsCount(result?.data?.length ?? 0);
    }
  };

  useEffect(() => {
    if (params.companyidorslug) {
      setCoverImage(companyProfilePreview.value?.cover!);
    } else {
      setCoverImage('');
    }
  }, [params.companyidorslug, companyProfilePreview]);

  useEffect(() => {
    setIsOwner(Boolean(companyProfilePreview?.value?.isAdmin));
    if (companyProfilePreview?.value?.isAdmin) getCompanyAdminRequestCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfilePreview]);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onSubmitCoverPhoto = (coverPhoto: string) => {
    const cover = coverPhoto;
    const logo = companyProfilePreview.value?.logo;
    const specialitiesToSubmit: string[] = [];
    companyProfilePreview.value?.companySpecialities.map((speciality: any) => specialitiesToSubmit.push(speciality.name));
    const companyAdminToSubmit: IReactSelectOption[] = [];
    companyProfilePreview.value?.admins.map((admin: any) => companyAdminToSubmit.push(admin?.email));
    const dataToSubmit = {
      headquarter: companyProfilePreview.value?.headquarter,
      cover,
      logo,
      name: companyProfilePreview.value?.name,
      specialities: specialitiesToSubmit,
    };
    if (companyProfilePreview.value?.id) {
      dispatch(
        updateProfile(
          companyProfilePreview.value?.id,
          dataToSubmit,
          companyAdminToSubmit
        )
      );
      toast.success('Company cover updated');
    }
  };

  const onSubmitProfilePhoto = (profilePhoto: string) => {
    const cover = companyProfilePreview.value?.cover;
    const logo = profilePhoto;
    const specialitiesToSubmit: string[] = [];
    companyProfilePreview.value?.companySpecialities.map((speciality: any) => specialitiesToSubmit.push(speciality.name));
    const companyAdminToSubmit: IReactSelectOption[] = [];
    companyProfilePreview.value?.admins.map((admin: any) => companyAdminToSubmit.push(admin?.email));
    const dataToSubmit = {
      headquarter: companyProfilePreview.value?.headquarter,
      cover,
      logo,
      name: companyProfilePreview.value?.name,
      specialities: specialitiesToSubmit,
    };
    if (companyProfilePreview.value?.id) {
      dispatch(
        updateProfile(
          companyProfilePreview.value?.id,
          dataToSubmit,
          companyAdminToSubmit
        )
      );
      dispatch(getCompanyProfilePreview(props.id));
      toast.success('Company profile updated');
      setIsProfileLoader(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const uploadProfileCoverFile = async (files: any, type: string) => {
    setIsLoader(true);
    setIsProfileLoader(true);
    const data = new FormData(document.forms[0]);
    data.append('file', files);
    const fileuploadService = new FilUploadService();
    await fileuploadService.uploadImage(data).then(res => {
      if (type === 'cover') {
        setIsLoader(false);
        setIsProfileLoader(false);
        setCoverImage(res.data.key);
        dispatch(setCoverPhoto(res.data.key));
        if (!props?.updatePhotoOnSubmit) onSubmitCoverPhoto(res.data.key);
        setIsCoverPhotoAttached(false);
        setIsDropdown(false);
      } else if (type === 'profile') {
        dispatch(setProfilePicture(res.data.key));
        if (!props?.updatePhotoOnSubmit) onSubmitProfilePhoto(res.data.key);
        setSelectedProfilePhotoFile(res.data.key);
        setIsProfileLoader(false);
        setIsLoader(false);
      }
    });
  };

  const handleProfilePictureSelected = (files: FileList) => {
    uploadProfileCoverFile(files[0], 'profile');
    // setIsProfileModal(true);
    // const reader = new FileReader();
    // reader.addEventListener('load', () => setSelectedProfilePhotoFile(reader.result?.toString() || ''));
    // reader.readAsDataURL(files[0]);
  };

  // const onCloseModal = () => {
  //   setIsProfileModal(false);
  //   setSelectedProfilePhotoFile(companyProfilePicture?.value! || companyProfilePreview.value?.logo!);
  // };

  // const onSaveCropProfile = (profile: any) => {
  //   setIsProfileModal(false);
  //   uploadProfileCoverFile(profile, 'profile');
  // };

  const handleCoverPhotoSelected = (files: FileList) => {
    const data = new FormData();
    data.append('file', files[0]);
    const fileUrl = URL.createObjectURL(files[0]);

    setIsCoverPhotoAttached(true);
    setSelectedCoverPhotoUrl(fileUrl);
  };

  const onCropCoverPicture = (coverPhoto: any) => {
    setSelectedCoverPhotoFile(coverPhoto);
  };

  const handleUploadCoverPhoto = () => {
    uploadProfileCoverFile(selectedCoverPhotoFile, 'cover');
  };

  const onOpenDropdown = () => {
    setIsDropdown(!isDropdown);
  };

  const handleClickOutside = () => {
    setIsDropdown(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  async function s3UrlToFile(url: any) {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Origin: window.location.origin,
      },
    });
    const blob = await response.blob();
    const filename = url.substring(url.lastIndexOf('/') + 1);
    const ext = filename.split('.').pop();
    const file = new File([blob], filename, { type: `image/${ext}` });
    return file;
  }

  const rePositionCover = () => {
    setIsCoverPhotoAttached(true);
    setIsLoader(true);
    if (coverImage) {
      s3UrlToFile(`${imageUrlPrefix}/${coverImage}`)
        .then((file: any) => {
          const data = new FormData();
          data.append('file', file);
          const fileUrl = URL.createObjectURL(file);
          setSelectedCoverPhotoUrl(fileUrl);
          setIsLoader(false);
        })
        .catch((error: any) => console.error(error));
    }
    setIsDropdown(false);
  };

  const onInputProfileClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  return (
    <div className="bg-white mx-auto create-post-card !rounded-t-[25px] !rounded-b-[20px] overflow-hidden">
      <div className="flex flex-col relative">
        {isCoverPhotoAttached && (
          <div className="bg-white w-full h-[347px] rounded-t-0 object-cover website-cover-crop relative">
            {isLoader && (
              <div className="absolute left-0 top-0 w-full h-[calc(100%--0px)] bg-gray-1 bg-opacity-80 flex items-center justify-center z-20">
                <Loader className="pt-5" key="loader" />
              </div>
            )}
            <UserCoverCrop imgUrl={selectedCoverPhotoUrl} onCropCover={onCropCoverPicture} className="w-full h-[347px]" />
          </div>
        )}
        {!isCoverPhotoAttached && (
          <div className="relative h-0" style={{ paddingBottom: '27.83%' }}>
            <Logo
              src={`${imageUrlPrefix}/${coverImage}` ?? coverPhotoDefault}
              alt=""
              className="absolute inset-0 w-full h-full object-cover"
            />
            {
              ((!isCoverPhotoAttached && isOwner) || (isCreateCompany && !isOwner)) && (
                <div
                  className={classNames(
                    'absolute z-10 top-[20px] right-[20px] w-[190px] h-[41px]',
                    {
                      '!w-[45px] z-[100]': _innerWidth <= 768,
                    }
                  )}
                  ref={ref}
                >
                  <div className="relative">
                    <div
                      // eslint-disable-next-line max-len
                      className="hover:bg-[#C4CDD5] bg-[#ffffff]  h-[41px] w-[190px] text-center text-[#000000] text-[15px] font-semibold rounded-[5px] flex items-center justify-center cursor-pointer"
                      onClick={() => {
                        onOpenDropdown();
                      }}
                    >
                      {_innerWidth <= 768 ? <ImageEditSvgIcon /> : 'Edit cover photo'}
                    </div>
                    {
                      isDropdown && (
                        <div className="bg-white opacity-100 z-50 absolute right-0 top-[42px] w-[190px] post-company-logo rounded-[7px] p-[6px]">
                          <button
                            type="button"
                            className={classNames(
                              'rounded-[5px] hover:bg-[#EDF2F5] px-[6px] py-1 text-sm leading-5 text-[#172746] text-left tracking-wide w-full',
                              {
                                hidden: !coverImage,
                              }
                            )}
                            onClick={() => {
                              rePositionCover();
                            }}
                          >
                            Reposition
                          </button>
                          <button
                            type="button"
                            className="rounded-[5px] hover:bg-[#EDF2F5] px-[6px] py-1 text-sm text-[#172746] text-left tracking-wide leading-5 w-full"
                          >
                            Upload new
                            <br />
                            <span className="text-[10px] text-[#050202] font-light text-left tracking-wide w-full block leading-[10px]">1260px x 350px recommended</span>
                            <input
                              type="file"
                              accept="image/*"
                              ref={coverPhotoRef}
                              className="opacity-0 absolute bottom-0 right-0 w-full h-[40px] cursor-pointer "
                              onChange={e => handleCoverPhotoSelected(e.target?.files!)}
                            />
                          </button>
                        </div>
                      )
                    }
                  </div>
                </div>
              )
            }
          </div>
        )}
        {isCoverPhotoAttached && (
          <div className="absolute top-[225px] left-[50%]">
            <div
              // eslint-disable-next-line max-len
              className={classNames('text-[12px] leading-[18px] text-[white] flex items-center justify-center bg-black bg-opacity-[65%] h-[33px] w-[215px] rounded-[10px] -ml-[107.5px] z-10')}
            >
              Drag up and down to reposition
            </div>
          </div>
        )}
        {isCoverPhotoAttached && (
          <div className="flex items-center flex-row justify-end bg-black-rgba w-full h-[57px] rounded-t-0 absolute z-10 top-0">
            <div className="px-3">
              <button
                type="button"
                className="bg-white text-[#203c6e] h-[30px] w-[88px] text-[13px] font-medium rounded-10 mr-2 px-2 py-1"
                onClick={() => {
                  setIsCoverPhotoAttached(false);
                  setSelectedCoverPhotoUrl('');
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="bg-[#0049EF] h-[30px] w-[74px] text-white text-[13px] font-medium rounded-10 px-2 py-1"
                onClick={() => {
                  handleUploadCoverPhoto();
                }}
              >
                Save
              </button>
            </div>
          </div>
        )}
        <div className="flex flex-row-reverse">
          <div
            className={classNames(
              'absolute mx-auto left-0 right-0 h-130 w-130 -mt-[65px] bg-white rounded-[10px] z-10',
              {
                '!bg-[#ffffff]': props?.logo?.includes('null') || !props?.logo,
                '!h-[90px] !w-[90px] mt-[-2.7rem]': _innerWidth <= 768
              }
            )}
          >
            <Logo
              src={`${imageUrlPrefix}/${selectedProfilePhotoFile !== '' || isCreateCompany ? selectedProfilePhotoFile : companyProfilePreview?.value?.logo!}`}
              alt=""
              className={classNames(
                'h-130 w-130 p-[2px] object-contain rounded-[10px] shadow-[0_0px_5px_0px_rgba(0,0,0,0.16)] bg-[#ffffff]',
                {
                  '!bg-[#edf2f5]': selectedProfilePhotoFile?.trim() === '',
                  '!h-[90px] !w-[90px]': _innerWidth <= 768
                }
              )}
              defaultLogo="company"
            />
            {isProfileLoader ? (
              <div className="absolute left-0 top-0 w-full h-full bg-gray-1 bg-opacity-80 rounded-[8px] flex items-center justify-center z-20">
                <SpinnerIcon />
              </div>
            )
              : (isOwner || isCreateCompany) && (
                <>
                  <div className="w-[32px] h-[32px] absolute -right-[2px] -bottom-[2px]">
                    <div
                      className="cursor-pointer"
                      onClick={() => profilePictureRef?.current?.click()}
                    >
                      {isCreateCompany ? <div className="w-[32px] h-[32px] absolute -right-[14px] -bottom-[1px]"><CameraIcon /></div> : <div className="w-[32px] h-[32px] absolute -right-[14px] -bottom-[1px]"><CameraIcon /></div>}
                    </div>
                    <input
                      type="file"
                      id="profilePicture"
                      ref={profilePictureRef}
                      className="hidden"
                      onChange={e => handleProfilePictureSelected(e.target?.files!)}
                      onClick={onInputProfileClick}
                      accept="image/*"
                    />
                  </div>
                  {/* {
                    ((isOwner && isProfileModal) || (isCreateCompany && isProfileModal))
                    && (
                      <AlertModal isOpen={isProfileModal} onClose={onCloseModal} className="w-[459px]">
                        <UserProfileCrop title="" imgUrl={selectedProfilePhotoFile} onCloseModal={onCloseModal} onCropProfile={onSaveCropProfile} />
                      </AlertModal>
                    )
                  } */}
                </>
              )}

          </div>
          {!props.isEditCoverPhoto ? (
            <div className="mr-2 py-4 sm:w-auto w-full sm:ml-0 ml-2">
              <div
                className={classNames(
                  'flex flex-row items-center sm:justify-end',
                  {
                    'justify-end': _innerWidth <= 768 && !isOwner,
                    'justify-between': _innerWidth > 768 && isOwner,
                  }
                )}
              >
                {
                  isOwner && (
                    <div>
                      <ButtonWithTextAndImage
                        text="Admin"
                        isNotification
                        notificationCount={companyAdminRequestCount}
                        buttonClasses="bg-[#EDF2F5] px-[10px] pr-[15px] h-[27px] rounded-[5px]"
                        textClasses="text-sm font-medium text-[#203C6E]"
                        onClick={onOpenAdminModal}
                      />
                    </div>
                  )
                }
                {toggleAdminModal && (
                  <AlertModal onClose={onCloseAdminModal} isOpen={toggleAdminModal} className="md:w-[630px] w-full">
                    <CompanyAdminsDialog
                      companyEmployees={[]}
                      companyID={props.id}
                      onCloseAdminModal={onCloseAdminModal}
                    />
                  </AlertModal>
                )}
                <div className="ml-0">
                  <CompanyHeaderDropdown company={companyProfilePreview?.value} companyId={props.id} isOwner={isOwner} />
                </div>
              </div>
            </div>
          ) : null}

        </div>
        <div
          className={classNames('w-full flex-col flex', {
            'mt-[68px] mb-[20px]': props.isEditCoverPhoto,
          })}
        >
          <CompanyHeaderInfo
            id={props?.id}
            cover={props?.cover}
            logo={props?.logo}
            name={props?.name}
            isVerified={props?.isVerified}
            showPosts={props?.showPosts}
            showTechShows={props?.showTechShows}
            showEvents={props?.showEvents}
            isFollowing={props?.isFollowing}
            isEditCoverPhoto={props?.isEditCoverPhoto}
            getActiveTab={props?.getActiveTab}
            activeTab={props?.activeTab}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyHeader;
