import { Avatar, Button, Divider, Stack } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertModal from "src/components/alert-modal/alert-modal";
import { getUTCTimeZone } from "src/mui/assets/data/timezones";
import { LoadingScreen } from "src/mui/components/loading-screen";
import { EventsService } from "src/services/events";
import { Loader } from "src/shared-components/Loader";
import { IAppState } from "src/store";
// import { callInvite } from "src/store/1-1-video-call/actions";
import { setJoinMeeting } from "src/store/liveEvent";

export const JoinMeetingModal = ({ meetingId }: { meetingId: string }) => {
  const dispatch = useDispatch();
  const [meeting, setMeeting] = useState<any>(null);
  const [currentTime, setCurrentTime] = useState<any>(moment());
  const selector = useSelector((state: IAppState) => state);
  const { currentUser: user } = selector.auth;
  const { joinMeeting } = selector.liveEvent;

  const getMeetingDetail = (meetingId: string) => {
    new EventsService().getMeetingById(meetingId).then((r) => {
      dispatch(
        setJoinMeeting({ ...r.data, meetingId: meetingId, isOpen: true })
      );
      setMeeting(r.data);
      setCurrentTime(moment().tz(getUTCTimeZone(r.data?.event?.timezone!)!));
    });
  };

  const startTime = moment(meeting?.startTime).tz(
    getUTCTimeZone(meeting?.event?.timezone!)!
  );
  const endTime = moment(meeting?.endTime).tz(
    getUTCTimeZone(meeting?.event?.timezone!)!
  );
  const meetingWith =
    user?.id === meeting?.user?.id ? meeting?.attendeeUser : meeting?.user;

  const getMeetingAndInitCall = async () => {
    try {
      // dispatch(
      //   callInvite(meetingWith.id, {
      //     id: meetingWith.id,
      //     firstName: meetingWith?.fullName,
      //     avatar: meetingWith?.avatar,
      //     cover: "",
      //     email: "",
      //     jobTitle: "",
      //     lastName: "",
      //   })
      // );
      dispatch(
        setJoinMeeting({ ...joinMeeting, isOpen: false, isRoomOpen: true })
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const isMeetingOver = currentTime?.isAfter(endTime);
  const isMeetingActive = !isMeetingOver;
  // currentTime?.isBetween(
  //   startTime,
  //   endTime,
  //   null,
  //   "[]"
  // );
  // const isMeetingUpcoming = currentTime?.isBefore(startTime);

  const meetingStatusMessage = meeting
    ? isMeetingActive
      ? `The meeting with ${meetingWith?.fullName} is now active. Please click 'Join Meeting' to participate.`
      : isMeetingOver
      ? `The scheduled meeting with ${meetingWith?.fullName} time has ended.`
      : // : isMeetingUpcoming
        // ? `The meeting with ${meetingWith?.fullName} is scheduled to start soon. Please wait until the designated time.`
        ""
    : "";

  const meetingStartTime = startTime?.format("DD MMM YYYY HH:mm");

  useEffect(() => {
    getMeetingDetail(meetingId);
  }, [meetingId]);

  const MINUTE_MS = 60000;
  useEffect(() => {
    if (!meeting) return;
    const interval = setInterval(() => {
      setCurrentTime(moment().tz(getUTCTimeZone(meeting?.event?.timezone!)!));
    }, MINUTE_MS);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [meeting]);

  return (
    <AlertModal
      isCloseIcon
      closeIconClass="right-[7px] top-[14px]"
      className="w-[374px] h-[258px] bg-[#EDF2F5]"
      isOpen={joinMeeting?.isOpen}
      onClose={() =>
        dispatch(setJoinMeeting({ ...joinMeeting, isOpen: false }))
      }
    >
      {meeting ? (
        <div className="w-full px-4 py-4 rounded-lg">
          <div className="flex items-center mb-4">
            <Avatar
              sx={{
                width: 58,
                height: 58,
                borderRadius: 2,
                marginLeft: 1,
                "&.MuiAvatar-root, & .MuiAvatar-img": {
                  minHeight: 58,
                  maxHeight: 58,
                  minWidth: 58,
                },
              }}
              src={`${process.env.REACT_APP_IMAGE_URL_PREFIX}/${meetingWith?.avatar}`}
            />
            <div className="flex flex-col justify-center pl-4">
              <div className="text-lg w-[85%] text-ellipsis font-semibold text-gray-800 truncate">
                {meetingWith?.fullName}
              </div>
              <div className="text-sm text-gray-600 truncate">
                {meetingWith?.company?.name}
              </div>
            </div>
          </div>
          <div className="w-full mx-auto pt-2 pl-5">
            <p className="text-gray-700">{meetingStatusMessage}</p>
            <Divider />
            <p className="mt-[3px] text-gray-900 font-bold">
              Meeting Time: {meetingStartTime}
            </p>
          </div>
          <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
            <Button
              sx={{
                borderRadius: 1,
                height: 28,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 11,
                lineHeight: 1.4,
                fontFamily: "Poppins",
                backgroundColor: "#ffffff",
                textTransform: "none",
                padding: "0 16px",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
              disabled={isMeetingOver}
              onClick={getMeetingAndInitCall}
            >
              Join Meeting
            </Button>
            <Button
              sx={{
                borderRadius: 1,
                height: 28,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 11,
                lineHeight: 1.4,
                fontFamily: "Poppins",
                textTransform: "none",
                padding: "0 16px",
                fontWeight: 600,
              }}
              onClick={() =>
                dispatch(setJoinMeeting({ ...joinMeeting, isOpen: false }))
              }
            >
              Cancel
            </Button>
          </Stack>
        </div>
      ) : (
        // <div className="absolute left-0 top-0 w-full h-[calc(100%--26px)] bg-gray-1 bg-opacity-80 flex items-center justify-center z-20 rounded-20 -mt-[14px]">
        <div className="flex flex-row items-center justify-center w-full h-full p-2">
          {/* <Loader className="pt-5" key="loader" /> */}
          <LoadingScreen />
        </div>
      )}
    </AlertModal>
  );
};
