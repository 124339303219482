import { useState, useEffect, useMemo } from 'react';
import { Autocomplete, TextField, Grid, Box, SxProps } from '@mui/material';
import { Theme } from "@mui/material/styles";
import { Controller, useFormContext } from 'react-hook-form';
import { debounce } from '@mui/material/utils';


interface Location {
  id: string;
  name: string;
}

interface LocationDropdownProps {
  name: string;
  label?: string;
  onSelectLocation?: (location: Location) => void;
  disabled?: boolean;
  helperText?: React.ReactNode;
  isLenovo?: boolean;
  placeholder?: any;
  sx?: SxProps<Theme>;
}

const autocompleteService = { current: null };

const fieldHelperText = {
  "& .MuiFormHelperText-root": { ml: "1px" }
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
  geometry: any;
}

const LocationDropdown = ({
  name,
  label,
  onSelectLocation,
  disabled,
  helperText,
  isLenovo,
  placeholder,
  sx,
}: LocationDropdownProps) => {
  const { control } = useFormContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);

  const fetchData = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions({ ...request, types: ['(cities)'] }, callback);
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;
    if (
      !autocompleteService.current &&
      (window as any).google &&
      (window as any).google.maps &&
      (window as any).google.maps.places
    ) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue?.trim() === '') {
      setOptions([]);
      return undefined;
    }

    fetchData({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchData]);

  const handleSelectLocation = async (
    _event: any,
    selectedValue: PlaceType | null,
  ) => {
    if (selectedValue) {
      if (onSelectLocation)
        onSelectLocation({
          id: selectedValue?.place_id,
          name: selectedValue?.description,
        });
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Autocomplete
            {...field}
            // open={inputValue?.trim() !== '' && options?.length ? true : false}
            // onBlur={() => setInputValue("")}
            freeSolo
            id="searchTextField"
            options={options}
            disabled={disabled}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option?.name || option?.description || ''
            }
            filterOptions={(x) => x}
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={(event: any, newValue: PlaceType | null) => {
              setInputValue("")
              setOptions(newValue ? [newValue, ...options] : options);
              if (newValue) {
                new (window as any).google.maps.Geocoder().geocode(
                  { address: newValue?.description },
                  (results?: any) => {

                    if (results?.length) {
                      const addressComponents = results[0]?.address_components || [];
                      let city = '';
                      let country = '';

                      // Filter address components to get the city and country
                      addressComponents.forEach((component: any) => {
                        if (component.types.includes('locality')) {
                          city = component.long_name;
                        } else if (component.types.includes('country')) {
                          country = component.long_name;
                        }
                      });

                      const fieldValue = {
                        id: newValue?.place_id,
                        name: newValue?.description,
                        city,
                        country
                      };
                      setValue(newValue);

                      field.onChange(fieldValue);
                      handleSelectLocation(event, newValue);
                    }
                  }
                );
              }
              else {
                field.onChange(null);
              }

            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            clearOnBlur
            clearOnEscape
            renderInput={(params) => (
              <TextField
                {...params}
                id="searchTextField"
                label={label}
                placeholder={placeholder}
                variant="outlined"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                sx={{ ...sx, ...fieldHelperText, "& .MuiAutocomplete-input": { paddingLeft: "0px !important", height: '10px !important' }, }}
                error={!!error}
                helperText={error ? error?.message : helperText}
              />
            )}

          />
        </>
      )}
    />
  );
};

export default LocationDropdown;
