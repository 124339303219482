import { Reducer } from 'redux';

import { ICompaniesState } from '../../models/company/CompaniesState ';
import { CompaniesActions } from './actions';
import { CompaniesActionTypes } from './types';

const initialState: ICompaniesState = {
  value: null,
  loading: false,
  errorMessage: ''
};

export const CompaniesReducer: Reducer<ICompaniesState, CompaniesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CompaniesActionTypes.COMPANIES_LOADING: {
      return {
        ...state,
        loading: state.loading
      };
    }
    case CompaniesActionTypes.COMPANIES_SUCCESS: {
      return {
        ...state,
        value: action.data
      };
    }

    case CompaniesActionTypes.COMPANIES_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        value: null
      };
    }

    default:
      return state;
  }
};
